import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  HierarchySelectConfig,
  HierarchySelectItem,
  HierarchySelectValue
} from '../hierarchy-select/hierarchy-select.types';
import { BudgetObjectSegmentData } from '../../types/budget-object-segment-data.interface';
import { createDeepCopy, createRegExpFromString } from '../../utils/common.utils';
import { ObjectsIconConfig } from 'app/app.constants';

@Component({
  selector: 'hierarchy-menu',
  templateUrl: './hierarchy-menu.component.html',
  styleUrls: ['./hierarchy-menu.component.scss']
})
export class HierarchyMenuComponent implements OnInit {

  @Input() selectedValue: HierarchySelectValue = null;
  @Input() iconsConfig: ObjectsIconConfig = {};
  @Output() onChange = new EventEmitter<HierarchySelectValue>();
  @Output() selectValue = new EventEmitter<BudgetObjectSegmentData>();
  @Output() selectValueWithKeyboard = new EventEmitter<BudgetObjectSegmentData>();

  public menuItems: HierarchySelectItem[] = [];
  public menuConfig: HierarchySelectConfig = {
    fieldLabel: '',
    withSearch: true,
    emptyValueLabel: '',
    searchPlaceholder: ''
  };
  public openSegmentSelect = false;
  public groupsOpenState: Record<string, boolean> = {};
  public activeSegmentTooltip = '';
  public activeSegment: number = null;
  public allSelected = false;

  private copyItems: HierarchySelectItem[] = [];
  public parentItemsLength: number;

  @Input() placeholder = '';
  @Input() set items(value: HierarchySelectItem[]) {
    this.menuItems = value;
    this.copyItems = [...this.menuItems];
    this.parentItemsLength = this.menuItems.filter(i => i.children && i.children.length).length;
  }

  @Input() set config(value: HierarchySelectConfig) {
    this.menuConfig = value;
  }

  @Input() set selectedSegment(value: number) {
    if (value) {
      this.activeSegment = value;
      this.activeSegmentTooltip = this.getActiveSegment(this.activeSegment, this.menuItems);
    }
  }

  public ngOnInit(): void {
    this.activeSegmentTooltip = this.getActiveSegment(this.activeSegment, this.menuItems);
  }

  public handleSelectSegment = (segment: HierarchySelectItem, isKeyboard?: boolean): void => {
    if (!segment) {
      return;
    }

    if (segment.objectId === Number(this.activeSegment)) {
      return;
    }

    if (isKeyboard) {
      this.selectValueWithKeyboard.emit({ budgetSegmentId: segment.objectId });
    } else {
      this.selectValue.emit({ budgetSegmentId: segment.objectId });
    }
  }

  public toggleGroup(collapsed: boolean, id: string): void {
    if (collapsed) {
      delete this.groupsOpenState[id];
    } else {
      this.groupsOpenState[id] = true;
    }
    this.allSelected = Object.keys(this.groupsOpenState).length === this.parentItemsLength;
  }

  public identifyItem(index, item: HierarchySelectItem): string {
    return item.id;
  }

  toggleAllGroupsCollapse(collapsed: boolean) {
    if (!collapsed) {
      this.menuItems.forEach(item => {
        if (item.children && item.children.length) {
          this.groupsOpenState[item.id] = true;
        }
      });
    } else {
      this.groupsOpenState = {};
    }
    this.allSelected = !collapsed;

  }

  public updateActiveItems(filterText: string): void {
    const data = createDeepCopy(this.copyItems);

    if (filterText.length > 2) {
      const pattern = createRegExpFromString(filterText, 'i');
      this.menuItems = this.filterItemsByPattern(data, pattern);
    } else {
      this.menuItems = data;
    }
  }

  private filterItemsByPattern(data: HierarchySelectItem[], pattern: RegExp): HierarchySelectItem[] {
    return data.filter((item) => {
      if (item.children?.length) {
        item.children = this.filterItemsByPattern(item.children, pattern);
      }

      if (item.children?.length) {
        return true;
      }

      return String(item.title).search(pattern) >= 0;
    });
  }

  private getActiveSegment(segmentId: number, menuItems: HierarchySelectItem[]): string {
    if (!segmentId || !menuItems.length) {
      return '';
    }

    const segment = menuItems.reduce((items, acc) => {
      if (acc.children?.length) {
        const children = acc.children.find(child => Number(child.objectId) === Number(segmentId));
        if (children) {
          items.push(children);
        }
      }
      if (Number(acc.objectId) === Number(segmentId)) {
        items.push(acc);
      }
      return items;
    }, []);

    return segment.length ? segment[0].title : '';
  }
}
