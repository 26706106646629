<div class="attachments-container">
  <div>
    <label class="attach-file-btn"
           [class.disabled]="isReadOnlyMode || disableInput || isInvoiceDrawer"
           matTooltip="{{ disableInput | disabledAddToObjectTooltip: objectTypeName : 'attaching files' }}"
           matTooltipClass="dark-theme-tooltip above attachment"
           matTooltipPosition="above">
      <icon-add-objects *ngIf="showAddIcon"></icon-add-objects>
      <span class="attach-file-label">Attach Document</span>
      <input type="file" multiple [attr.accept]="acceptedFileExtensions" (change)="onAttachFiles.emit($event)" [disabled]="(isReadOnlyMode || disableInput || isInvoiceDrawer) ? 'disabled' : ''"/>
    </label>
  </div>
  <div *ngIf="attachments?.length" class="attachments-list-wrapper">
    <ul class="attachments-list" [class.expanded]="showFullList">
      <li *ngFor="let attachment of attachments" class="attachments-list-item"
        [ngClass]="{
          'is-processing': attachment.isUnderReview || attachment.isProcessing
        }"
      >
        <loader [isVisible]="attachment.isUnderReview || attachment.isProcessing" size="16px" [isInline]="true" class="mr-05"></loader>
        <div class="attachment-list-item-name">
          <span class="attachment-name" [matTooltip]="attachment?.name" *ngIf="attachment?.ext !== 'pdf' || isInvoiceDrawer else pdf" matTooltipClass="dark-theme-tooltip above attachment" matTooltipPosition="above">
            {{ attachment?.filename }}
          </span>
          <ng-template #pdf>
            <span class="attachment-name clickable-name" [matTooltip]="attachment?.name" matTooltipClass="dark-theme-tooltip above attachment" matTooltipPosition="above" (click)="viewAttachment(attachment)">
              {{ attachment?.filename }}
            </span>
          </ng-template>
        </div>
        <div class="actions ml-a">
          <button [disabled]="attachment.isProcessing" class="mat-button download" *ngIf="attachment.ext === 'pdf' && !isInvoiceDrawer" (click)="viewAttachment(attachment)" mat-icon-button matTooltip="Show" matTooltipClass="dark-theme-tooltip above" matTooltipPosition="above">
            <fa-duotone-icon [icon]="['fad', 'eye']"></fa-duotone-icon>
          </button>
          <button [disabled]="attachment.isProcessing" class="mat-button download" (click)="onDownloadAttachment.emit(attachment)" mat-icon-button matTooltip="Download" matTooltipClass="dark-theme-tooltip above" matTooltipPosition="above">
            <fa-duotone-icon [icon]="['fad', 'download']"></fa-duotone-icon>
          </button>
          <button [disabled]="isInvoiceDrawer || attachment.isProcessing" class="mat-button delete" (click)="onDeleteAttachment.emit(attachment)" mat-icon-button matTooltip="Delete" matTooltipClass="dark-theme-tooltip above" matTooltipPosition="above">
            <fa-duotone-icon [icon]="['fad', 'trash-alt']"></fa-duotone-icon>
          </button>
        </div>
      </li>
    </ul>
    <button *ngIf="attachments?.length > minAttachmentsToShowMore" (click)="showFullList = !showFullList" class="show-more-btn">
      {{ showFullList ? 'Show Less' : 'Show More'}}
    </button>
  </div>
</div>
