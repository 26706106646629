<div [formGroup]="form" class="container-fluid">

  <div class="row mt-2">
    <div [formGroupName]="'reports'" class="reports">
      <div *ngFor="let report of reportTypes"
           class="mb-05 row-cell">
        <mat-checkbox [formControlName]="report.id" [color]="'primary'">{{ report.title }}</mat-checkbox>
      </div>
    </div>
  </div>

  <div class="select-line" *ngIf="baselines?.length">
    <div class="form-field-wrap">
      <p class="label" [ngClass]="{ 'disabled': rangeFrom.disabled }">Compare:</p>
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Baseline 1</mat-label>
        <mat-select formControlName="reallocation_from">
          <mat-option
            *ngFor="let bl of baselines"
            [value]="bl.id"
            [disabled]="bl.id === rangeTo.value">
            <span *ngIf="bl.name">{{ bl.name }} - </span>
            <span>{{ bl.date | date : 'MMMM d, y' }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-field-wrap">
      <p class="label" [ngClass]="{ 'disabled': rangeTo.disabled }">With:</p>
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Baseline 2</mat-label>
        <mat-select formControlName="reallocation_to">
          <mat-option [value]="todayId">Today</mat-option>
          <mat-option
            *ngFor="let bl of baselines"
            [value]="bl.id"
            [disabled]="bl.id === rangeFrom.value">
            <span *ngIf="bl.name">{{ bl.name }} - </span>
            <span>{{ bl.date | date : 'MMMM d, y' }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div style="max-width: 300px; margin-bottom: 16px">
    <button
      (click)="handleExportReport()"
      [disabled]="form.invalid || disableSubmit"
      class="mt-05 action-btn"
      mat-flat-button
      color="primary">Export Report as XLSX</button>
    <div *ngIf="!!exportReportItem" class="d-flex mt-1">
      <loader [isVisible]="true" class="mr-05" [size]="'24px'" [isInline]="true"></loader>
      <span>Export in progress</span>
    </div>
  </div>

</div>
