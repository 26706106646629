import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DataValidationService } from 'app/shared/services/data-validation.service';
import { REPORT_TYPE, ReportParams } from 'app/dashboard/export-data.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BudgetBaseline, BudgetService } from 'app/shared/services/backend/budget.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AsyncOperationItem } from '../import-data.component';

@Component({
  selector: 'reports-export',
  templateUrl: './reports-export.component.html',
  styleUrls: ['./reports-export.component.scss']
})
export class ReportsExportComponent implements OnInit, OnDestroy {
  protected reportTypes = [
    {
      id: REPORT_TYPE.CAMPAIGN,
      title: 'Campaign Status',
    },
    {
      id: REPORT_TYPE.CAMPAIGN_ALLOCATION,
      title: 'Campaign Allocation',
    },
    {
      id: REPORT_TYPE.RECAPTURED_BUDGET,
      title: 'Recaptured Budget',
    },
    {
      id: REPORT_TYPE.REALLOCATION,
      title: 'Campaign and Segment Reallocation',
    },
  ];

  private oldCEGStructureReportTypes = [...this.reportTypes]
  
  // remove campaign Allocation in export report type from the new world
  private newCEGStructureReportTypes = [...this.reportTypes].filter(rt => rt.id !== REPORT_TYPE.CAMPAIGN_ALLOCATION)

  REPORT_TYPE = REPORT_TYPE;
  form: UntypedFormGroup;
  baselines: BudgetBaseline[];
  todayId = '-1';
  destroy$ = new Subject<void>();
  isReallocationAvailable = false;

  @Output() exportReport = new EventEmitter<Partial<ReportParams>>();
  @Input() disableSubmit: boolean;
  @Input() exportReportItem: AsyncOperationItem;
  // Toggling the allowed report types if new_campaigns_programs_structure flag is enabled
  @Input() set newCEGStructure(value: boolean) {
    this.reportTypes = value ? this.newCEGStructureReportTypes : this.oldCEGStructureReportTypes;
    this.initForm();
  }
  

  @Input() set budgetId(budgetId: number) {
    if (!budgetId) {
      return;
    }
    this.updateBaselines(budgetId);
  };

  constructor(
    public fb: UntypedFormBuilder,
    private budgetService: BudgetService,
    private validationService: DataValidationService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  updateBaselines(id: number): void {
    this.budgetService.getBaselines(id)
      .subscribe(
        bl => {
          this.baselines = bl || [];
          if (!this.baselines.length) {
            this.isReallocationReport.setValue(false);
            this.isReallocationReport.disable();
            return;
          } else {
            this.isReallocationReport.enable();
          }
          this.rangeFrom.setValue(this.baselines[0].id);
          this.rangeTo.setValue(this.todayId);
        },
        error => {
          this.isReallocationReport.disable();
        }
      )
  }

  get rangeFrom(): AbstractControl {
    return this.form.get('reallocation_from');
  }

  get rangeTo(): AbstractControl {
    return this.form.get('reallocation_to');
  }

  get reportsGroup(): UntypedFormGroup {
    return this.form.get('reports') as UntypedFormGroup;
  }

  get isReallocationReport(): AbstractControl {
    return this.reportsGroup.get(REPORT_TYPE.REALLOCATION);
  }

  initForm() {
    const reportControls = this.reportTypes
      .reduce((group, report) => {
        group[report.id] = false;
        return group;
      }, {})
    const formConfig = {
      reports: this.fb.group(reportControls),
      reallocation_from: [{value: '', disabled: true}],
      reallocation_to: [{value: '', disabled: true}],
    };

    this.form = this.fb.group(formConfig);
    this.reportsGroup.setValidators(this.validationService.requireCheckboxesToBeChecked());

    this.isReallocationReport.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(selected => {
        this.isReallocationAvailable = selected;
        if (selected) {
          this.rangeFrom.enable();
          this.rangeTo.enable();
        } else {
          this.rangeFrom.disable();
          this.rangeTo.disable();
        }
      })
  }

  handleExportReport() {
    const reportsGroup = this.reportsGroup.value;
    const rangeTo = this.rangeTo.value;

    const selectedTypes = Object.keys(reportsGroup)
      .filter((reportKey => reportsGroup[reportKey]))
    const qp: Partial<ReportParams> = {
      report_type: selectedTypes.join(','),
    };

    if (this.isReallocationReport.value) {
      qp.reallocation_from = this.rangeFrom.value;
      if (rangeTo !== this.todayId) {
        qp.reallocation_to = rangeTo;
      }
    }
    this.exportReport.emit(qp);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
