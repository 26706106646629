import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { TasksService } from 'app/shared/services/backend/tasks.service';
import { TaskDO, TaskStatusName } from 'app/shared/types/task.interface';
import { Company } from 'app/shared/types/company.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetDataService } from '../../../dashboard/budget-data/budget-data.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { TaskListChangeEvent, TasksListComponent } from 'app/budget-object-details/components/tasks-list/tasks-list.component';
import { UtilityService } from 'app/shared/services/utility.service';
import { HomePageService } from '../../services/home-page.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { getTodayFixedDate } from '@shared/utils/budget.utils';

@Component({
  selector: 'tasks-widget',
  styleUrls: ['./tasks-widget.component.scss'],
  templateUrl: './tasks-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksWidgetComponent implements OnInit, OnDestroy {
  @Input() config: WidgetConfig;
  @Output() onLoaded = new EventEmitter();
  @ViewChild(TasksListComponent) tasksList: TasksListComponent;

  private readonly destroy$ = new Subject<void>();
  public currentBudget: Budget = null;
  public currentCompanyUser: CompanyUserDO = null;
  public company: Company;
  public widgetState = WidgetState;
  public state = WidgetState.INITIAL;
  public tasks: TaskDO[] = [];
  public budgetTodayDate: Date;

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly tasksService: TasksService,
    private readonly budgetDataService: BudgetDataService,
    private readonly userManager: UserManager,
    private readonly companyDataService: CompanyDataService,
    private readonly utilityService: UtilityService,
    private readonly homePageService: HomePageService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.loadContextData();
    this.homePageService.noBudgets$
      .pipe(take(1))
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      );

    this.userManager.currentCompanyUser$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      companyUser => this.currentCompanyUser = companyUser
    );

    this.budgetDataService.selectedBudget$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(budget =>
      this.budgetTodayDate = getTodayFixedDate(budget)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setState(state: WidgetState) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
    this.cdRef.detectChanges();
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private loadTasks() {
    if (!this.company || !this.currentCompanyUser || !this.currentBudget) {
      return;
    }

    this.tasksList.reset();
    this.tasksService.getTasks(this.company.id, {
      owner: this.currentCompanyUser.user,
      budget: this.currentBudget.id,
      status: [
        TaskStatusName.IN_PROGRESS,
        TaskStatusName.BLOCKED,
        TaskStatusName.LATE
      ].join(',')
    }).subscribe(tasks => {
      this.tasks = tasks;
      this.setState(tasks && tasks.length ?
        WidgetState.READY :
        WidgetState.HIDDEN
      );
    });
  }

  public loadContextData() {
    this.homePageService.contextData$
      .pipe(
        filter(data => data != null),
        tap((data) => {
          if (this.state !== WidgetState.HIDDEN) {
            this.setState(WidgetState.LOADING);
          }
          this.currentBudget = data.budget;
          this.company = data.company;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => { this.loadTasks(); },
        (err) => this.handleError(err)
      );
  }

  public handleTasksUpdate($event: TaskListChangeEvent) {
    const { update: { obj } } = $event;
    const taskDO = TasksService.convertToDataObject(obj);
    const isOpen = TasksService.isTaskOpen(obj);

    this.tasksService.updateTask(taskDO.id, {
      status: taskDO.status,
      due_date: taskDO.due_date
    })
      .subscribe(
        () => {
          if (!isOpen) {
            this.utilityService.showCustomToastr('Task resolved!', null, { timeOut: 1500 });
          }
        }
      )
  }
}
