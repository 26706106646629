import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HomePageEvent } from '../types/home-page-event.type';

@Injectable({
  providedIn: 'root'
})
export class HomePageEventService {
  private readonly eventBus = new Subject<HomePageEvent>();
  public readonly eventBus$ = this.eventBus.asObservable();

  public emitEvent(event: HomePageEvent) {
    this.eventBus.next(event);
  }
}
