import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { isTodayDateCell } from '@shared/utils/date.utils';

@Component({
  selector: 'task-date',
  templateUrl: './task-date.component.html',
  styleUrls: ['./task-date.component.scss']
})
export class TaskDateComponent implements OnChanges {
  @Input() value: Date;
  @Input() isLate: boolean;
  @Input() isIncoming: boolean;
  @Input() isReadOnly = false;
  @Input() daysLeft: number;
  @Input() todayDate: Date;
  @Output() onStatusChanged = new EventEmitter();

  public tooltipText = '';

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.daysLeft || changes.isIncoming) {
      this.tooltipText = this.getTooltipText(this.daysLeft);
    }
  }

  private getTooltipText(daysLeft: number): string {
    let dueWording;
    switch (daysLeft) {
      case 0:
        dueWording = 'today';
        break;
      case 1:
        dueWording = 'tomorrow';
        break;
      case 7:
        dueWording = 'in a week';
        break;
      default:
        dueWording = `in ${daysLeft} days`;
    }

    return `The task is due\n${dueWording}`;
  }

  datepickerClassNames = (cellDate: Date, view: 'multi-year' | 'year' | 'month') => {
    const datepickerClasses = [];
    if (isTodayDateCell(cellDate, this.todayDate, view)) {
      datepickerClasses.push('mat-calendar-budget-today');
    }

    return datepickerClasses;
  }

  public handleValueChanged($event) {
    this.onStatusChanged.emit($event.value);
    this.value = $event.value;
  }
}
