import { Injectable } from '@angular/core';
import { ExpenseDetailsState } from '../../budget-object-details/types/budget-object-details-state.interface';
import { BehaviorSubject } from 'rxjs';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { RecognizedField, StateField } from '@spending/types/expense-page-drawer.type';
import { ExtraExpenseEmailBody } from '@shared/types/expense.interface';

@Injectable()
export class ExpensePageDrawerService {
  private _openedExpenseId$ = new BehaviorSubject<number>(null);
  public openedExpenseId$ = this._openedExpenseId$.asObservable();
  private _initialExpenseDetailsFields$ = new BehaviorSubject<Partial<ExpenseDetailsState> | null>(null);
  public readonly initialExpenseDetailsFields$ = this._initialExpenseDetailsFields$.asObservable();
  private _expenseEmailBody$ = new BehaviorSubject<ExtraExpenseEmailBody>(null);
  public readonly expenseEmailBody$ = this._expenseEmailBody$.asObservable();

  private mapFieldsContext = {
    [RecognizedField.ActualAmount]: [StateField.SourceActualAmount],
    [RecognizedField.Amount]: [StateField.Amount],
    [RecognizedField.GlCode]: [StateField.GlCode],
    [RecognizedField.Name]: [StateField.Name],
    [RecognizedField.Mode]: [StateField.Mode],
    [RecognizedField.CurrencyCode]: [StateField.CurrencyCode],
    [RecognizedField.SourceActualAmount]: [StateField.SourceActualAmount],
    [RecognizedField.BudgetAllocationId]: [StateField.BudgetAllocationId],
    [RecognizedField.PoNumber]: [StateField.PoNumber],
    [RecognizedField.VendorName]: [StateField.VendorName],
    [RecognizedField.InvoiceNumber]: [StateField.InvoiceNumber],
    [RecognizedField.OwnerId]: [StateField.OwnerId],
    [RecognizedField.TypeId]: [StateField.TypeId],
    [RecognizedField.DeliveryDate]: [StateField.DeliveryDate],
    [RecognizedField.Notes]: [StateField.Notes],
    [RecognizedField.Campaign]: [StateField.Campaign],
    [RecognizedField.Program]: [StateField.Program]
  }

  constructor(public readonly budgetObjectDetailsManager: BudgetObjectDetailsManager) {}

  public setOpenedExpenseId(id: number): void {
    setTimeout(() => this._openedExpenseId$.next(id));
  }

  public setInitialFields (state: ExpenseDetailsState, RecognizedField: string[]): void {
    const normalizedFieldsList = this.mapFieldsList(RecognizedField);
    this._initialExpenseDetailsFields$.next(this.getFieldsForHighlight(state, normalizedFieldsList));
  }

  public setExpenseEmailBody(body: ExtraExpenseEmailBody): void {
    this._expenseEmailBody$.next(body);
  }

  private getFieldsForHighlight(state: ExpenseDetailsState, fieldsList: string[]): Partial<ExpenseDetailsState> | null {
    if (!state) return null;
    const initialState = this.budgetObjectDetailsManager.getDeepStateCopy(state);
    this.expandNestedObjects(initialState);
    this.transformParentObjectToString(initialState);
    return fieldsList.reduce((acc, val)=> {
      acc[val] = initialState[val];
      return acc;
    }, {})
  }

  private expandNestedObjects(object: Object): void {
    for(const value of Object.values(object)) {
      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        Object.assign(object, value);
      }
    }
  }

  private mapFieldsList(fieldsList: string[]): string[] {
    return fieldsList.flatMap(field => {
      if (field === RecognizedField.Segment) {
        return [StateField.SegmentId, StateField.SharedCostRuleId];
      }
      return this.mapFieldsContext[field];
    })
  }

  private transformParentObjectToString(object: Object): void {
    object["parent"] =
      "id" in object && "type" in object
        ? `${object["type"]}_${object["id"]}`
        : null;
  }

}
