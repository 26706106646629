<div class="exchange-rate-dialog">
  <header mat-dialog-title>Currency exchange rate notification</header>
  <div mat-dialog-content class="dialog-info">
    <div class="text">
      This currency has expenses referencing it and can’t be deleted.
      The only way to delete this currency is to delete all expenses referencing
      this currency or change them to a different currency.
    </div>
  </div>
  <div mat-dialog-actions align="end" class="dialog-actions">
    <button type="button" mat-flat-button mat-dialog-close color="primary">Cancel</button>
  </div>
</div>
