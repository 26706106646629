<div class="object-link" (click)="handleClick($event)" [class.closed]="isClosed" [class.hasProduct]="productName">
  <ng-template [ngIf]="!isClosed" [ngIfElse]="closedIcon">
    <fa-icon *ngIf="withIcon" [icon]="iconByObjectType[objectType]" class="link-icon"></fa-icon>
  </ng-template>
  <ng-template #closedIcon>
    <fa-icon [icon]="[ 'far', 'rocket' ]"></fa-icon>
  </ng-template>
  <span class="product-name">{{ productName }}</span>
  <span class="link-text">{{ objectName + (isClosed ? ' [Closed]' : '')}}</span>
</div>
