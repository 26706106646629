import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { MetricsUtilsService } from 'app/budget-object-details/services/metrics-utils.service';
import { CPOTooltipTemplate, getMetricExplanationTooltip, ROITooltipTemplate, RROITooltipTemplate } from '../constants';

@Component({
  selector: 'metric-total-info',
  templateUrl: './metric-total-info.component.html',
  styleUrls: ['./metric-total-info.component.scss']
})
export class MetricTotalInfoComponent implements OnChanges {
  // TODO: group data to avoid so much inputs
  @Input() metricName = '';
  @Input() currencyCode: string;
  @Input() revenuePerOutcome: number;
  @Input() revenueToProfit: number;
  @Input() totalValue: number;
  @Input() totalTarget: number;
  @Input() costPerOutcome: number;
  @Input() targetCostPerOutcome: number;
  @Input() returnOnInvestment: number;
  @Input() targetReturnOnInvestment: number;
  @Input() objectType: string;
  @Input() lastNegativeMetricValueDate: string;
  @Input() displayDecimal: boolean;

  public CPOTooltip: string;
  public RROITooltip: string;
  public ROITooltip: string;

  totalMetricTooltip = {
    [this.config.OBJECT_TYPES.goal]: 'Total Metric:\n\nThis is the aggregated value of the metrics for this goal.',
    [this.config.OBJECT_TYPES.campaign]: 'Total Metric:\n\nThis is the aggregated value of the metrics for this campaign. It could include metric contributions added manually to this campaign, from expense groups embedded in the campaign, and from metrics automatically imported from external CRM systems.',
    [this.config.OBJECT_TYPES.program]: 'Total Metric:\n\nThis is the value of the metrics for this expense group.',
  };
  negativeTotalTooltip: string;
  progressIndication: string;
  decimalDisplayFormat = '1.0-0';

  @Input() set isCEGMode(isCEGMode: boolean) {
    this.CPOTooltip = getMetricExplanationTooltip(CPOTooltipTemplate, isCEGMode);
    this.RROITooltip = getMetricExplanationTooltip(RROITooltipTemplate, isCEGMode);
    this.ROITooltip = getMetricExplanationTooltip(ROITooltipTemplate, isCEGMode);
  }

  constructor(private utils: MetricsUtilsService, private config: Configuration) {}

  ngOnChanges(changes: SimpleChanges) {
    const costPerOutcomeUpdated = this.costPerOutcome && changes.costPerOutcome || changes.targetCostPerOutcome;
    const returnOnInvestmentUpdated = this.returnOnInvestment && changes.returnOnInvestment || changes.targetReturnOnInvestment;
    const rpoUpdated = this.revenuePerOutcome != null && changes.revenuePerOutcome;

    if (costPerOutcomeUpdated || returnOnInvestmentUpdated || rpoUpdated) {
      this.defineProgressIndication();
    }

    if (changes.lastNegativeMetricValueDate && this.lastNegativeMetricValueDate) {
      this.defineNegativeTotalTooltip();
    }

    if (changes.displayDecimal) {
      this.decimalDisplayFormat = this.displayDecimal ? '1.2-2' : '1.0-0';
    }
  }

  defineProgressIndication() {
    const currentValue = this.revenuePerOutcome ? this.returnOnInvestment : this.costPerOutcome;
    const targetValue = this.revenuePerOutcome ? this.targetReturnOnInvestment : this.targetCostPerOutcome;
    const progressResult = this.utils.getProgressState(targetValue, currentValue, !this.revenuePerOutcome);
    this.progressIndication = progressResult.state;
  }

  defineNegativeTotalTooltip() {
    this.negativeTotalTooltip = 'Your Running Total is less that zero.\nSee the negative Change in Value\non {date}'.replace('{date}', this.lastNegativeMetricValueDate);
  }
}
