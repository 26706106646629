<ng-container *ngIf="disabled">
  <span [ngClass]="'theme-'+theme">
    <ng-template *ngTemplateOutlet="profileCircle"></ng-template>
  </span>
</ng-container>
<ng-container *ngIf="!disabled">
  <button mat-fab [matMenuTriggerFor]="panel.menu" class="profile-menu-trigger" [ngClass]="'theme-'+theme">
    <ng-template *ngTemplateOutlet="profileCircle"></ng-template>
  </button>
  <menu-panel #panel [items]="items"></menu-panel>
</ng-container>

<ng-template #profileCircle>
  <span class="profile-picture">
    <img *ngIf="currentUser?.user_profile_detail?.file != null" [src]="profilePicUrl" onerror="this.src='assets/images/dummy-user.jpg'" title="" alt="">
    <i *ngIf="currentUser?.user_profile_detail?.file == null" getInitials [firstName]="currentUser?.first_name" [lastName]="currentUser?.last_name"></i>
  </span>
</ng-template>
