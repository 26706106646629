import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Validations } from 'app/app.validations';
import { Configuration } from 'app/app.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { CommonService } from 'app/shared/services/backend/common.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { SuperadminService } from '../../shared/superadmin.service';
import { Subscription } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '@shared/services/backend/company.service';

export interface SuperadminModalContext {
  superadmin_id: string;
}

@Component({
  selector: 'modal-content',
  styleUrls: ['./superadmin-modal.component.scss'],
  templateUrl: './superadmin.html'
})
export class SuperadminModalComponent implements OnInit, OnDestroy {
  public readonly configuration = inject(Configuration);
  public readonly validations = inject(Validations);
  public readonly companyService = inject(CompanyService);
  public readonly utilityService = inject(UtilityService);
  public readonly commonService = inject(CommonService);
  public readonly router = inject(Router);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly superadminService = inject(SuperadminService);
  private readonly userManager = inject(UserManager);
  private readonly dialogRef: MatDialogRef<SuperadminModalComponent> = inject(MatDialogRef);
  private readonly data: SuperadminModalContext = inject(MAT_DIALOG_DATA);

  context: SuperadminModalContext;
  subscriptions: Subscription[] = [];
  isPopupLoading = false;
  superadminForm: UntypedFormGroup;
  superadminDetails: any = {};
  title = 'Add Superadmin';
  superadmin_id = '';
  superAdminTypes = [
    { value: '', label: 'Select Type' },
    { value: 'god_mode_user', label: 'God Mode' },
    { value: 'super_admin_user', label: 'Superadmin' },
  ]

  ngOnInit() {
    // Show popup loader
    this.subscriptions.push(
      this.utilityService.popupLoadingPropertyChanged$.subscribe(
        value => setTimeout(() => this.isPopupLoading = value, 0)
      )
    );

    this.createForm();
    this.context = this.data;
    this.superadmin_id = this.context.superadmin_id;
    if (this.superadmin_id) {
      this.getSuperadminDetails();
      this.title = 'Edit Superadmin';
    }
  }

  createForm(): any {
    this.superadminForm = this.formBuilder.group(
      {
        first_name: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(this.configuration.FIRST_LAST_NAME_REGEX)
          ])
        ],
        last_name: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(this.configuration.FIRST_LAST_NAME_REGEX)
          ])
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(this.configuration.EMAIL_REGEX)
          ])
        ],
        user_type: [ '', Validators.required ],
        id : [ '' ]
      }
    );
  }

  get first_name() {
    return this.superadminForm.get('first_name');
  }

  get last_name() {
    return this.superadminForm.get('last_name');
  }

  get email() {
    return this.superadminForm.get('email');
  }

  get user_type() {
    return this.superadminForm.get('user_type');
  }

  getSuperadminDetails() {
    this.utilityService.showPopupLoading(true);
    this.subscriptions.push(
      this.superadminService.getSuperadminDetails(this.superadmin_id).subscribe(
      data => this.success(data, 'getSuperadminDetails'),
      error => this.handleError(error)
      )
    );
  }

  saveSuperadmin() {
    this.dialogRef.close();
    const data = this.superadminForm.getRawValue();
    delete data['id'];
    if (data['email']) {
      data['email'] = data['email'].toLowerCase();
    }

    this.utilityService.showPopupLoading(true);
    this.subscriptions.push(
      this.superadminService.saveSuperadmin(data, this.superadmin_id).subscribe(
      response => this.success(response, 'saveSuperadmin'),
      error => this.handleError(error)
      )
    );
  }

  saveLog() {
    const logs = [];
    const newData = this.superadminForm.getRawValue();
    if (this.superadminDetails.first_name !== newData.first_name) {
      logs.push({ field: 'First Name', action: 'updated', old_amount: this.superadminDetails.first_name, new_amount: newData.first_name });
    }

    if (this.superadminDetails.last_name !== newData.last_name) {
      logs.push({ field: 'Last Name', action: 'updated', old_amount: this.superadminDetails.last_name, new_amount: newData.last_name });
    }

    if (this.superadminDetails.user_type !== newData.user_type) {
      logs.push({ field: 'User Type', action: 'updated', old_amount: this.superadminDetails.user_type, new_amount: newData.user_type });
    }

    const logsData = {};
    logsData['data'] = logs;
    logsData['event_id'] = this.superadmin_id;
    const currentUser = this.userManager.getCurrentUser();
    logsData['user_id'] = currentUser?.id;
    logsData['log_type'] = 'Superadmin';

    this.subscriptions.push(this.commonService.saveLog(logsData).subscribe(
      data => this.success(data, 'saveLog'),
      error => this.handleError(error)
    ));
  }

  success(data, type) {
    if (Number(data?.status) === HttpStatusCode.Ok) {
      if (type === 'saveSuperadmin') {
        if (this.superadmin_id) {
          this.saveLog()
        } else {
          this.utilityService.showToast({ Title: '', Message: data.message, Type: 'success' });
          this.utilityService.showPopupLoading(false);
          this.utilityService.refreshSuperadminList(true);
        }
      } else if (type === 'saveLog') {
          this.utilityService.showToast({ Title: '', Message: data.message, Type: 'success' });
          this.utilityService.showPopupLoading(false);
          this.utilityService.refreshSuperadminList(true);
      } else if (type === 'getSuperadminDetails') {
        this.superadminDetails = data.data;

        this.superadminForm.reset({
          first_name: this.superadminDetails.first_name,
          last_name: this.superadminDetails.last_name,
          email: this.superadminDetails.email,
          id: this.superadminDetails.id,
          user_type: this.superadminDetails.user_type,
        });
      }
    } else {
      this.utilityService.showToast({ Title: '', Message: data.message, Type: 'error' });
    }

    this.utilityService.showPopupLoading(false);
  }

  handleError(error) {
    if (error.hasOwnProperty('message')) {
      this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
      this.utilityService.showPopupLoading(false);
    } else if (error.hasOwnProperty('detail')) {
      this.utilityService.showPopupLoading(false);
      this.utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
