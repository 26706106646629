import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DetailsExpensesData,
  DetailsExpensesTotalRow
} from './details-expenses-table/details-expenses-table.type';

@Component({
  selector: 'details-expenses',
  templateUrl: './details-expenses.component.html',
  styleUrls: ['./details-expenses.component.scss']
})
export class DetailsExpensesComponent {
  @Input() withBreakdown = true;
  @Input() withChildCampaigns = false;
  @Input() isAddNewExpenseAllowed = true;
  @Input() expensesTotals: DetailsExpensesTotalRow;
  @Input() data: DetailsExpensesData;
  @Output() addExpense = new EventEmitter();
  @Output() viewExpenses = new EventEmitter();

  public addNewExpense = () => {
    this.addExpense.emit();
  };
}
