import { Directive, HostListener } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';

@Directive({
  selector: '[blurOnEnterKeydown]'
})
export class BlurOnEnterKeydownDirective {
  @HostListener('keydown', ['$event']) onEnterKeydown($event) {
    if ($event.keyCode === ENTER) {
      ($event.target as HTMLInputElement).blur();
    }
  }
}
