<div class="metric-milestone" [formGroup]="milestoneForm">
  <div class="details-form-field">
    <mat-form-field appearance="fill" class="milestone-amount">
      <mat-label> {{ milestonesCount - 1 === index ? 'Target' : 'Milestone # ' + (index + 1)}}</mat-label>
        <input [selectTextOnFocus]="true" blurOnEnterKeydown matInput currencyInput currencyMask [options]="amountFieldOpt" formControlName="amount" [required]="milestonesCount !== 1" (ngModelChange)="amountChanged()">
        <mat-error *ngIf="milestoneForm.controls['amount'].hasError('required')">
          Amount is required
        </mat-error>
        <mat-error *ngIf="milestoneForm.controls['amount'].hasError('unique')">
          Amount value must be unique
        </mat-error>
        <mat-error *ngIf="milestoneForm.controls['amount'].hasError('match')">
          Amount and date hasn't matched
        </mat-error>
    </mat-form-field>
  </div>
  <div class="details-form-field">
    <mat-form-field appearance="fill" class="date-picker">
      <mat-label>By</mat-label>
      <input matInput [matDatepicker]="datePicker" autocomplete="off" [required]="milestonesCount !== 1" formControlName="date" (dateChange)="dateChanged()"/>
      <mat-datepicker-toggle matSuffix [for]="datePicker">
        <fa-duotone-icon [icon]="['fad', 'calendar-day']" matDatepickerToggleIcon ></fa-duotone-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
      <mat-error *ngIf="milestoneForm.controls['date'].hasError('required')">
        Date is required
      </mat-error>
      <mat-error *ngIf="milestoneForm.controls['date'].hasError('unique')">
        Date field must be unique
      </mat-error>
    </mat-form-field>
  </div>
  <button *ngIf="milestonesCount > 1 && !readOnly" class="mat-button delete" (click)="removeMilestone.emit(index)" mat-icon-button matTooltip="Delete" matTooltipClass="dark-theme-tooltip above" matTooltipPosition="above">
    <fa-duotone-icon [icon]="['fad', 'trash-alt']"></fa-duotone-icon>
  </button>
</div>
