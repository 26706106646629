<article class="auth-page register-screen with-animation">
  <section class="auth-page-content">
    <div class="register-form-container">
      <h1 class="logo">
        <a href="javascript:;" title="Planful" [routerLink]="['/']">
          <img src="assets/images/planful-logo-colored.svg" width="150" alt="logo" />
        </a>
      </h1>
      <h3 class="auth-page-content-title title-big">Let’s get started!</h3>
      <form autocomplete="off" class="auth-page-form register-form" #form="ngForm" novalidate (ngSubmit)="register(form.value, form.valid, form);">
        <div class="form-field">
          <label class="form-field-label">Company Name</label>
          <input class="form-field-input" type="text" name="company_name" [(ngModel)]="form.company_name" #company_name="ngModel" required pattern="^[ A-Za-z0-9_@!,~'\x22*;$?./#&+-]*$" maxlength="128" (blur)="companyExists(company_name);" (keyup)="companyExists(company_name);" trim />
          <div class="md-errors-spacer error" [hidden]="company_name.valid || (company_name.pristine && !form.submitted)">
            <div *ngIf="company_name.errors && company_name.errors.required || company_name_required">
              {{ validations.ValiditionMessages.COMPANY_NAME_REQUIRED }}
            </div>
            <div *ngIf="company_name.errors && company_name.errors.trim">
              {{ validations.ValiditionMessages.COMPANY_NAME_REQUIRED }}
            </div>
            <div *ngIf="company_name.errors && company_name.errors.pattern">
              {{ validations.ValiditionMessages.INVALID_COMPANY_NAME }}
            </div>
            <div *ngIf="company_name.errors && company_name.errors.companyExists">
              {{ validations.ValiditionMessages.COMPANY_EXIST }}
            </div>
          </div>
        </div>
        <div class="form-field currency-input" (clickOutside)="onToggleCurrencyList(false)">
          <label class="form-field-label">Planning Currency</label>
          <input class="form-field-input" type="text" name="currency" (focus)="onToggleCurrencyList(true)" [(ngModel)]="form.currency" #currency="ngModel" required readonly="readonly"/>
          <div *ngIf="currencyListOpened" class="control" >
            <currency-select [baseCurrencyCode]="'USD'" (selectCurrency)="onSelectCurrency($event.currencyItem)"></currency-select>
          </div>
          <div class="md-errors-spacer error" [hidden]="currency.valid || (currency.pristine && !form.submitted)">
            <div *ngIf="currency.errors && currency.errors.required">
              {{ validations.ValiditionMessages.COMPANY_NAME_REQUIRED }}
            </div>
          </div>
        </div>
        <div class="form-field-group">
          <div class="form-field">
            <label class="form-field-label">First Name</label>
            <input class="form-field-input" type="text" name="first_name" [(ngModel)]="form.first_name" #first_name="ngModel" required pattern="[a-zA-Z '-]+" maxlength="128" trim />
            <div class="md-errors-spacer error" [hidden]="first_name.valid || (first_name.pristine && !form.submitted)">
              <div *ngIf="first_name.errors && first_name.errors.required">
                {{ validations.ValiditionMessages.FIRST_NAME_REQUIRED }}
              </div>
              <div *ngIf="first_name.errors && first_name.errors.trim">
                {{ validations.ValiditionMessages.FIRST_NAME_REQUIRED }}
              </div>
              <div *ngIf="first_name.errors && first_name.errors.pattern">
                {{ validations.ValiditionMessages.FIRST_NAME_ONLY_CHARS }}
              </div>
            </div>
          </div>
          <div class="form-field">
            <label class="form-field-label">Last Name</label>
            <input class="form-field-input" type="text" name="last_name" [(ngModel)]="form.last_name" #last_name="ngModel" required pattern="[a-zA-Z '-]+" maxlength="128" trim />
            <div class="md-errors-spacer error" [hidden]="last_name.valid || (last_name.pristine && !form.submitted)">
              <div *ngIf="last_name.errors && last_name.errors.required">
                {{ validations.ValiditionMessages.LAST_NAME_REQUIRED }}
              </div>
              <div *ngIf="last_name.errors && last_name.errors.trim">
                {{ validations.ValiditionMessages.LAST_NAME_REQUIRED }}
              </div>
              <div *ngIf="last_name.errors && last_name.errors.pattern">
                {{ validations.ValiditionMessages.LAST_NAME_ONLY_CHARS }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-field email-field">
          <label class="form-field-label">Work Email</label>
          <input class="form-field-input" type="text" name="email" [(ngModel)]="form.email" #email="ngModel" pattern="^[a-zA-Z0-9._+-]+[a-zA-Z0-9_+-]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$" (blur)="checkEmail(form.email);" required >
          <tooltip-container class="arrow-left" *ngIf="displayEmailTip">
            {{ email_warning }}
          </tooltip-container>
          <div class="md-errors-spacer error" [hidden]="email.valid || (email.pristine && !form.submitted)">
                  <div *ngIf="email.errors && email.errors.required">
                    {{validations.ValiditionMessages.EMAIL_REQUIRED}}
                  </div>
                  <div *ngIf="email.errors && email.errors.pattern">
                    {{validations.ValiditionMessages.INVALID_EMAIL}}
                  </div>
              </div>
        </div>
        <div class="form-field">
          <label class="form-field-label">Salesforce Tenant ID</label>
          <input class="form-field-input" type="text" name="salesforce_tenant_id" [(ngModel)]="form.salesforce_tenant_id" maxlength="128" trim />
        </div>
        <div class="form-field" >
          <label class="form-field-checkbox">
            <input type="checkbox" autocomplete="off" name="acceptTerms" [(ngModel)]="form.acceptTerms" #acceptTerms="ngModel" required/>
            <span class="checkbox-item"></span>
            <span class="form-field-label">Accept Planful terms</span>
          </label>
          <a class="terms-link" href="assets/file/Terms_Conditions.pdf" target="_blank"></a>
          <div class="md-errors-spacer error error-signup" [hidden]="acceptTerms.valid || (acceptTerms.pristine && !form.submitted)">
              <div  *ngIf="acceptTerms.errors && acceptTerms.errors.required">
                {{validations.ValiditionMessages.TC_ACCEPTANCE}}
              </div>
            </div>
        </div>
        <div class="form-actions">
          <button class="full-width"
                  mat-flat-button
                  color="primary"
                  type="submit" [disabled]="form.invalid || company_exists || buttonDisabled">Sign Me Up!</button>
        </div>
        <div class="form-links">
          <a class="form-links-item" href="javascript:;" [routerLink] = "['/login']">Already have an account?</a>
        </div>
      </form>
    </div>
  </section>
  <rights-reserved></rights-reserved>
</article>
