import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamPageComponent } from './team-page.component';
import { TeamFilterPipe } from './team-filter.pipe';
import { SegmentsTooltipPipe } from './segments-tooltip.pipe';
import { InvitationButtonComponent } from './components/invitation-button/invitation-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    TeamPageComponent,
    TeamFilterPipe,
    SegmentsTooltipPipe,
    InvitationButtonComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule
  ],
  exports: [
    TeamPageComponent,
    TeamFilterPipe,
    SegmentsTooltipPipe,
    InvitationButtonComponent
  ]
})
export class TeamPageModule {}
