import { Injectable } from '@angular/core';
import { ChildObjectsData } from './child-objects.type';
import { Configuration } from 'app/app.constants';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';
import { getInitialsFromName } from 'app/shared/utils/users.utils';
import { MetricMappingDO, MetricService } from 'app/shared/services/backend/metric.service';
import { asyncScheduler, of } from 'rxjs';
import { UtilityService } from 'app/shared/services/utility.service';
import { BusinessValueService } from '../business-value/business-value.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { map, observeOn } from 'rxjs/operators';
import { MetricMappingDetailsService } from '../../services/metric-mapping-details.service';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { MetricsUtilsService } from '../../services/metrics-utils.service';
import { BudgetObjectDetailsManager } from '../../services/budget-object-details-manager.service';

export interface ObjectBusinessValue {
  objId: number;
  value: number;
}

@Injectable({
  providedIn: 'root'
})
export class ChildObjectsService {
  ownersInitialsCache = {};
  constructor(
    private config: Configuration,
    private readonly objectAccessManager: ObjectAccessManagerService,
    private readonly metricService: MetricService,
    private readonly utilityService: UtilityService,
    private readonly businessValueService: BusinessValueService,
    private readonly metricMappingDetailsService: MetricMappingDetailsService,
    private readonly metricsUtilsService: MetricsUtilsService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager
  ) { }

  createTableData(objects, users, sharedCostRules, segments, segmentNameById): ChildObjectsData {
    let totalAllocated = 0;
    const rows = objects.map(
      item => {
        const sharedCostRule = sharedCostRules.find(rule => rule.id === item.split_rule);
        const segment = segments.find(seg => seg.id === item.company_budget_segment1);
        const segmentName = segmentNameById ? segmentNameById[item.company_budget_segment1] : segment && segment.name;
        totalAllocated += item.amount;
        return {
          ...this.createChildObjectsTableItem(item, users, segments, sharedCostRules),
          sharedCostRule,
          segmentName
        }
      }
    );
    return { rows, totalAllocated };
  }

  createChildObjectsTableItem(data, users, segments, sharedCostRules)  {
    const isEnded = this.isEnded(data);
    const ownerInitials = this.getOwnerInitials(users, data);
    const isProhibited = !this.objectAccessManager.hasAccessBySegmentData(data, segments, sharedCostRules);
    return {
      id: data.id,
      name: data.name,
      owner: ownerInitials,
      isEnded: isEnded,
      allocated: data.amount,
      isProhibited
    }
  }

  isEnded(object) {
    return object.mode !== ObjectMode.Open;
  }

  private getOwnerInitials(users, item) {
    let ownerInitials = '';
    if (this.ownersInitialsCache[item.owner]) {
      ownerInitials = this.ownersInitialsCache[item.owner]
    } else {
      const owner = users.find(user => user.id === item.owner);
      const profileDetails = owner?.user_profile_detail;
      const name = profileDetails?.first_name + ' ' + profileDetails?.last_name;
      ownerInitials = getInitialsFromName(name);
      this.ownersInitialsCache[item.owner] = ownerInitials;
    }
    return ownerInitials;
  }

  defineBusinessValues(
    company: CompanyDO,
    products: ProductDO[],
    metricTypes: MetricType[],
    objects: any[],
    businessValuesApplier: (bValues: ObjectBusinessValue[]) => void
  ) {
    const keyMetricMappingIds = (objects || []).map(obj => obj.key_metric).filter(metricId => metricId);

    const keyMetricMappingsRequest =
      keyMetricMappingIds.length ?
        this.metricService.getMetricMappings(company.id, {
          ids: keyMetricMappingIds.join(',')
        }) :
        of([]).pipe(
          map(mappings => this.budgetObjectDetailsManager.filterMetricValuesForFixedDate(mappings)),
          observeOn(asyncScheduler) // To avoid 'Expression has changed after it was checked' errors
        );

    keyMetricMappingsRequest.subscribe(
      (metricMappings: MetricMappingDO[]) => {
        const bValues =
          metricMappings.map(metricMapping => ({
            objId: metricMapping.map_id,
            value: this.getBusinessValueForMetricMapping(metricMapping, metricTypes, products)
          }));
        if (typeof businessValuesApplier === 'function') {
          businessValuesApplier(bValues);
        }
      },
      () => this.utilityService.handleError({ message: 'Failed to get key metrics for campaigns' })
    );
  }

  private getBusinessValueForMetricMapping(metricMapping: MetricMappingDO, metricTypes: MetricType[], products: ProductDO[]): number {
    const mostRecentCalc =
      this.metricMappingDetailsService.getMostRecentMetricCalculation(metricMapping.metric_calculations);
    const { revenuePerOutcome, revenueToProfit } =
      MetricsUtilsService.getMetricRPOAndRevenueToProfit(metricMapping.metric_master, metricTypes, products);

    return BusinessValueService.calcBusinessValue(
      mostRecentCalc?.total_value,
      revenuePerOutcome,
      revenueToProfit
    );
  }
}
