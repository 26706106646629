import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions, withAPIV0ErrorHandling } from 'app/shared/utils/http-request.utils';
import { API_V0_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private readonly actionUrl = inject(API_V0_URL);
  private readonly http: HttpClient = inject(HttpClient);

  getFilterCompanies(): Observable<ApiV0Response<any[]>> {
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<any[]>>(this.actionUrl + 'companies/')
    );
  }

  saveLog(data: object): Observable<ApiV0Response<any>> {
    return withAPIV0ErrorHandling(
      this.http.post<any>(this.actionUrl + 'audit/',  JSON.stringify(data))
    );
  }

  getOwnerList(companyId: number, budgetId: number, data: object): Observable<ApiV0Response<any[]>> {
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<any[]>>(
        this.actionUrl + 'users/owners/company/' + companyId + '/budget/' + budgetId,
        getRequestOptions(data)
      )
    );
  }
}
