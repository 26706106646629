<div class="d-flex ai-center">
  <icon-fa-duotone-list-tree
    color="#7084A1"
    class="mr-05"
    size="16"></icon-fa-duotone-list-tree>
  <div class="trigger"
       *ngIf="parent"
       [matMenuTriggerFor]="hierarchy"
       [ngClass]="{ 'active': isOpened }"
       (click)="setOpenState(true)">
    <object-name [name]="parent.name"
                 [objectType]="parent.objectType"
                 [closed]="parent.isClosed"
                 [flipIcon]="parent.flipIcon"></object-name>
  </div>
</div>
<mat-menu #hierarchy="matMenu"
          [class]="'hierarchy-menu'"
          (closed)="setOpenState(false)">
  <div *ngFor="let item of hierarchyItems; let i = index"
       class="nav-item"
       [ngStyle]="{'padding-left': (i * 24 + 16) + 'px'}"
       [ngClass]="{
        'clickable': !!item.onClick,
        'closed': item.isClosed
       }"
       (click)="item.onClick && item.onClick(item.id)">
    <object-name [name]="item.name"
                 [objectType]="item.objectType"
                 [closed]="item.isClosed"
                 [iconSize]="17"
                 [flipIcon]="item.flipIcon"></object-name>
  </div>
</mat-menu>





