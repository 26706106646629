<ng-container *ngIf="iconsConfig[objectType]; else customIcon">
  <fa-icon *ngIf="objectType === OBJECT_TYPES.campaign && isClosed; else defaultIcon"
                   class="rocket-icon"
                   [icon]="[ 'far', 'rocket' ]"></fa-icon>
</ng-container>


<ng-template #defaultIcon>
  <fa-icon
    *ngIf="iconsConfig[objectType]; else customIcon"
    [icon]="iconsConfig[objectType]"
    [flip]="flip && 'horizontal'"></fa-icon>
</ng-template>

<ng-template #customIcon>
  <svg class="custom-icon" *ngIf="objectType === OBJECT_TYPES.sharedCostRule; else noIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0L9 20C3.93 19.5 0 15.21 0 10C0 4.79 3.93 0.5 9 0ZM11.03 0V8.99L20 8.99C19.53 4.25 15.76 0.47 11.03 0ZM11.03 11.01L11.03 20C15.77 19.53 19.53 15.75 20 11.01L11.03 11.01Z" fill="currentColor"/>
  </svg>
</ng-template>

<ng-template #noIcon>
  <fa-icon class="custom-icon" [icon]="['fas', 'ban']"></fa-icon>
</ng-template>
