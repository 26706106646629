<article class="campaign-details details-page-wrapper" [class.is-dragging]="gesturesManager.isDragging">
  <details-header
    [actions]="menuActions"
    [budgetList]="budgets"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedChangesFlag"
    (budgetToMoveSelected)="handleBudgetToMoveSelected($event)"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAction.bind(this))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
    [objectHierarchy]="hierarchy"
    [objectDisplayType]="objectType"
  ></details-header>
  <div class="details-content">
    <div class="details-page-title">
      <page-title
        [nameCtrl]="campaignFormData.get('name')"
        [maxLength]="budgetObjectDetailsManager.maxObjectNameLength"
        [objectType]="objectType?.toLowerCase()"
        [isClosed]="currentState?.mode === ObjectMode.Closed"
      ></page-title>
      <business-value
        [currencySymbol]="companyCurrency?.symbol"
        [objectType]="objectType"
        [showTarget]="true"
        [actualBusinessValue]="actualBusinessValue"
        [targetBusinessValue]="targetBusinessValue"
      ></business-value>
    </div>
    <div class="details-page-content" [formGroup]="campaignFormData">
      <section class="left-side">
        <div class="details-sections">
          <div class="details-form-field start-date">
            <fa-duotone-icon [icon]="['fad', 'calendar-day']"></fa-duotone-icon>
            <mat-form-field appearance="fill" class="date-picker" >
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="pickerStartDate" formControlName="startDate" autocomplete="off" (focus)="$event.target.select()" (mousedown)="pickerStartDate.open()" />
              <mat-datepicker #pickerStartDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="details-form-field right-offset-auto">
            <mat-form-field appearance="fill" class="date-picker">
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="pickerEndDate" formControlName="endDate" autocomplete="off"
                [min]="minEndDate" (focus)="$event.target.select()" (mousedown)="pickerEndDate.open()"/>
              <mat-datepicker #pickerEndDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="details-form-field owner-select">
            <fa-duotone-icon [icon]="['fad', 'user-crown']"></fa-duotone-icon>
              <mat-form-field appearance="fill" class="auto-width">
                <mat-label>Owner</mat-label>
                <mat-select
                  disableOptionCentering
                  formControlName="ownerId"
                  required
                  panelClass="mat-select-fixed-position"
                  [placeholder]="'Owner *'"
                  (selectionChange)="handleOwnerChange($event)"
                >
                  <mat-option *ngFor="let item of ownerOptions" [value]="item.id">
                    {{item.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="campaignFormData.controls['ownerId'].hasError('required')">
                  Owner is required
                </mat-error>
              </mat-form-field>
          </div>
        </div>

        <div class="details-sections justify-content parent-row">
          <fa-duotone-icon [icon]="['fad', 'sitemap']"></fa-duotone-icon>
          <div class="details-sections justify-content">
            <div class="details-form-field parent-field">
              <parent-hierarchy-select
                [items]="locationItems"
                [selectedValue]="campaignFormData.get('location')?.value"
                [config]="{ selectPanelClass: 'details-parent-select' }"
                (onChange)="handleParentSelectionChange($event)"
              >
              </parent-hierarchy-select>
            </div>
            <div class="details-form-field campaign-type">
              <mat-form-field *ngIf="!isCustomTypeEntering" appearance="fill" class="auto-width">
                <mat-label>Campaign Type</mat-label>
                <mat-select disableOptionCentering formControlName="typeId" required panelClass="mat-select-fixed-position" (closed)="handleTypeChange()" [placeholder]="'Campaign Type'">
                  <mat-option *ngFor="let item of campaignTypes" [value]="item.id">
                    {{item.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="campaignFormData.controls['typeId'].invalid">
                  Campaign type is required
                </mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="isCustomTypeEntering" appearance="fill" class="no-validate">
                <mat-label>Campaign Type</mat-label>
                <input matInput [autoFocus]="true" formControlName="customType" (blur)="handleCustomTypeChange()"/>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="details-sections">
          <div class="details-form-field left-offset w-100">
            <mat-form-field appearance="fill" class="no-validate textarea w-100 autosize">
              <mat-label>Target Audience</mat-label>
              <textarea matInput formControlName="targetAudience" textFieldAutosize [minHeight]="40"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="details-sections">
          <div class="details-form-field left-offset w-100">
            <mat-form-field appearance="fill" class="no-validate textarea w-100 autosize">
              <mat-label>Campaign Messaging</mat-label>
              <textarea matInput formControlName="messaging" textFieldAutosize [minHeight]="40"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="details-sections">
          <div class="details-form-field segment-select">
            <fa-duotone-icon [icon]="['fad', 'piggy-bank']"></fa-duotone-icon>
            <hierarchy-select
              [items]="allowedSegmentSelectItems"
              [selectControlExternal]="segmentControl"
              [config]="selectSegmentsConfig"
              [placeholder]="'Segment *'"
              [groupSelectionAllowed]="false"
              (onChange)="handleSegmentChanged($event)"
            ></hierarchy-select>
          </div>
          <div *ngIf="currentState?.externalId">
            <unique-id [uniqueId]="currentState.externalId" [objectType]="objectType"></unique-id>
          </div>
          <integration-mapping-menu
            [salesforceHandler]="connectedIntegrations[MetricIntegrationName.Salesforce]?.length && currentState?.objectId && openSalesforceMappingModal"
            [hubSpotHandler]="connectedIntegrations[MetricIntegrationName.Hubspot]?.length && currentState?.objectId && openHubspotMappingModal"
          ></integration-mapping-menu>
        </div>
        <div class="details-sections budget-allocations">
          <budget-allocations-table
            #allocationsTable
            [budgetTimeframes]="budgetTimeframes"
            [objectAllocations]="currentState?.allocations"
            [expensesByTimeframes]="currentState?.statusTotals?.expenses_by_timeframes || []"
            [suppressedMode]="budget?.suppress_timeframe_allocations"
            [totalAmount]="currentState?.amount"
            [isReadOnlyMode]="isReadOnlyMode"
            [isSegmentlessCampaign]="isSegmentlessCampaign"
            [currency]="companyCurrency?.symbol"
            [externalIntegrationType]="externalIntegration"
            (onChange)="handleAllocationsUpdate($event)"
            (onTotalAllocatedChange)="handleTotalAllocatedUpdate($event)"
          ></budget-allocations-table>
        </div>
        <div class="details-sections">
          <div class="details-form-field">
            <fa-duotone-icon [icon]="['fad', 'tags']"></fa-duotone-icon>
            <tags-control
              #tagsControl
              [tagsList]="currentState?.tagMappings"
              [autocompleteItems]="tagsManager.autocompleteItems"
              [editOnly]="true"
              (onAddTag)="addTag($event)"
              (onCreateTag)="createTag($event)"
              (onRemoveTag)="removeTag($event)"
            >
            </tags-control>
          </div>
        </div>
        <div class="details-sections">
          <div class="details-form-field w-100 attachments">
            <fa-icon [icon]="['far', 'paperclip']"></fa-icon>
            <attachments-list
              [disableInput]="!currentState?.objectId"
              [objectTypeName]="objectType"
              [isReadOnlyMode]="isReadOnlyMode"
              [attachments]="attachmentsManager.attachments"
              (onAttachFiles)="handleFileAttached($event)"
              (onDeleteAttachment)="handleFileDelete($event)"
              (onDownloadAttachment)="handleFileDownload($event)"
            ></attachments-list>
          </div>
        </div>
        <div class="details-sections">
          <div class="details-form-field w-100">
            <fa-duotone-icon [icon]="['fad', 'clipboard']"></fa-duotone-icon>
            <linkified-text formControlName="notes" label="Notes"></linkified-text>
          </div>
        </div>

      </section>
      <section class="right-side">
        <div class="details-sections">
          <details-metrics
            [defaultStartDate]="currentState?.startDate || budget?.budget_from"
            [defaultEndDate]="currentState?.endDate || budget?.fixed_date  || budget?.budget_to"
            [objectType]="objectType"
            [objectId]="currentState?.objectId"
            [contextCurrency]="companyCurrency?.symbol"
            [objectTotals]="currentState?.statusTotals"
            [metricTypes]="metricsManager.types"
            [savedMetricMappings]="currentState?.metricMappings"
            [isReadOnlyMode]="isReadOnlyMode"
            [products]="metricsManager.products"
            [totalMetricData]="currentState?.metricData"
            [keyMetricSelection]="true"
            [keyMetricId]="currentState?.keyMetricId"
            [isPowerUser]="isPowerUser"
            [todayDate]="budgetTodayDate"
            (setKeyMetric)="setKeyMetric($event)"
            (onAddMapping)="addMetricMappings($event)">
          </details-metrics>
        </div>
        <div class="details-sections">
          <details-status-bar
            [graphData]="currentState?.statusTotals"
            [objectDisplayType]="objectType"
            [objectName]="currentState?.name"
            [objectId]="currentState?.objectId"
            [currency]="companyCurrency?.symbol"
            [isObjectClosed]="currentState?.mode === ObjectMode.Closed"
          ></details-status-bar>
        </div>
        <div class="details-sections" *ngIf="currentState && !currentState?.parentCampaignId">
          <child-objects
            [objects]="currentState?.campaigns"
            [objectType]="OBJECT_TYPES.childCampaign"
            [users]="companyUsers"
            [currency]="companyCurrency?.symbol"
            [company]="company"
            [sharedCostRules]="sharedCostRules"
            [segmentList]="segments || []"
            [showSegment]="false"
            [showBusinessValue]="true"
            [products]="products"
            [metricTypes]="metricsManager.types"
            [isReadOnlyMode]="!currentState?.objectId || isReadOnlyMode"
            (addNewObject)="addChildCampaign()"
            (updateTotalBusinessValue)="updateTotalBusinessValue($event)"
          ></child-objects>
        </div>
        <div class="details-sections">
          <child-objects
            [objects]="currentState?.programs"
            [objectType]="OBJECT_TYPES.program"
            [users]="companyUsers"
            [currency]="companyCurrency?.symbol"
            [company]="company"
            [sharedCostRules]="sharedCostRules"
            [segmentList]="segments || []"
            [isReadOnlyMode]="isReadOnlyMode"
            (addNewObject)="addProgram()"
          ></child-objects>
        </div>
        <div class="details-sections">
          <details-expenses
            [withChildCampaigns]="currentState && !currentState?.parentCampaignId"
            [withBreakdown]="true"
            [isAddNewExpenseAllowed]="!isReadOnlyMode && !isSegmentlessCampaign"
            [expensesTotals]="detailsExpensesTotals"
            [data]="detailsExpensesData"
            (addExpense)="addNewExpense()"
            (viewExpenses)="viewExpenses()"
          ></details-expenses>
        </div>
        <div class="details-sections">
          <tasks-list
            [owners]="ownerOptions"
            [currentCompanyUser]="currentCompanyUser"
            [tasks]="currentState?.tasks"
            [budgetTodayDate]="budgetTodayDate"
            [isReadOnlyMode]="isReadOnlyMode"
            (onDataChanged)="handleTasksUpdate($event)"
            #tasksList
          ></tasks-list>
        </div>
      </section>
    </div>
  </div>
</article>
