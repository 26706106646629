<div class="child-objects-list" #overlapContainer>
  <ul>
    <li *ngFor="let object of processedChildObjects">
      <button
        class="pl-btn transparent"
        (click)="handleItemClick($event, object)"
        [title]="object.name"
        [class.disabled]="object.context?.disabled"
        matTooltip="Sorry, you don’t have permission to open this"
        matTooltipClass="dark-theme-tooltip above"
        matTooltipPosition="above"
        [matTooltipDisabled]="!object.context?.disabled"
      >
        <fa-icon
          *ngIf="object.icon && (object.campaign_type || object.object_type) !== ExternalIntegrationObjectTypes.GoogleAds"
          [icon]="object.icon" class="object-icon"></fa-icon>
        <icon-google-ads
          *ngIf="(object.campaign_type || object.object_type) === ExternalIntegrationObjectTypes.GoogleAds"
        ></icon-google-ads>
        <p class="btn-text">{{ object.name }}</p>
      </button>
      <div class="shared-cost-select" *ngIf="!isCompanyCard">
        <segments-rules-select
          *ngIf="sharedCostRuleList.length > 0"
          [segmentList]="segmentList"
          [segmentNameById]="segmentNameById"
          [sharedCostRuleList]="sharedCostRuleList"
          [dropDownTrigger]="true"
          [currentSharedState]="object && object.context.splitRule | sharedCostState: parentObject"
          [overlapContainer]="overlapContainer"
          [readOnly]="object.context?.disabled || !isEditingAllowed || (parentObject | isSharedSubObject)"
          [selectedData]="{budgetSegmentId: object.context.budgetSegmentId, sharedCostRuleId: object.context.splitRule}"
          (selectValue)="changeObjectSplitRule($event, object)"
        ></segments-rules-select>
      </div>
      <div class="shared-cost-select d-flex ai-center" *ngIf="isCompanyCard">
        <div class="segment">
          <hierarchy-menu
            [items]="segmentMenuList"
            [config]="menuConfig"
            [placeholder]="'Search Segments'"
            [selectedSegment]="object.context.budgetSegmentId"
            (selectValue)="changeObjectSplitRule($event, object)"
          >
          </hierarchy-menu>
        </div>
        <div class="shared-cost">
          <cost-rules
            *ngIf="sharedCostRuleList.length > 0"
            [segmentList]="segmentList"
            [sharedCostRuleList]="sharedCostRuleList"
            [dropDownTrigger]="true"
            [currentSharedState]="object && object.context.splitRule | sharedCostState: parentObject"
            [selectedData]="{budgetSegmentId: object.context.budgetSegmentId, sharedCostRuleId: object.context.splitRule}"
            [readOnly]="object.context?.disabled || !isEditingAllowed || (parentObject | isSharedSubObject)"
            (selectValue)="changeObjectSplitRule($event, object)"
          >
          </cost-rules>
        </div>
      </div>
    </li>
  </ul>
</div>
