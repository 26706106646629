export enum ManageTableMenuAction {
  Duplicate = 'Duplicate',
  MoveTo = 'MoveTo',
  Close = 'Close',
  Reopen = 'Reopen',
  CopyId = 'CopyId',
  Delete = 'Delete',
  ChangeType = 'ChangeType',
  AddTags = 'AddTags',
  RemoveTags = 'RemoveTags',
  Export = 'Export',
  AddMetrics = 'AddMetrics',
  ChangeStatus = 'ChangeStatus'
}
