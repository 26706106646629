import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { Company } from 'app/shared/types/company.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';

@Component({
  selector: 'company-select',
  templateUrl: './company-select.component.html'
})
export class CompanySelectComponent implements OnInit {
  companyList$: Observable<Company[]>;
  selectedCompany$: Observable<Company>;

  constructor(public companyDataService: CompanyDataService) { }

  ngOnInit() {
   this.companyList$ = this.companyDataService.companyList$
      .pipe(skipWhile(list => list == null));

    this.selectedCompany$ = this.companyDataService.selectedCompany$
      .pipe(skipWhile(cmp => cmp == null), map(company => !company ? null : company))
  }

  onSelectCompany(item: any) {
    this.companyDataService.updateSelectedCompany(item['id']);
    sessionStorage.setItem(`integration-authPopup`, 'false');
  }
}
