import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { withAPIV0ErrorHandling, getRequestOptions } from 'app/shared/utils/http-request.utils';
import { Observable } from 'rxjs';
import { ProgramTypeDO } from '../../types/program.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

@Injectable({
  providedIn: 'root'
})
export class ExpenseTypeService {
  private readonly actionUrl = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPath = 'expense_type/';
  public newGetapiPath = 'expense_type/list_for_admin/';
  public newDeleteApiPath = '/delete_for_admin_new_world';

  addExpenseType(data) {
    return withAPIV0ErrorHandling(
      this.http.post(`${this.actionUrl}${this.apiPath}`, JSON.stringify(data))
    );
  }

  getExpenseTypes(data): Observable<ProgramTypeDO[]> {
    return withAPIV0ErrorHandling(
      this.http.get<ProgramTypeDO[]>(`${this.actionUrl}${this.apiPath}`, getRequestOptions(data))
    );
  }

  updateExpenseType(id, data) {
    return withAPIV0ErrorHandling(
      this.http.patch(`${this.actionUrl}${this.apiPath}${id}/`,  JSON.stringify(data))
    );
  }

  deleteExpenseType(id) {
    return withAPIV0ErrorHandling(
      this.http.delete(`${this.actionUrl}${this.apiPath}${id}/`)
    );
  }

  deleteNewWorldExpenseType(rowId, data) {
    return withAPIV0ErrorHandling(
      this.http.delete(`${this.actionUrl}${this.apiPath}${rowId}${this.newDeleteApiPath}`,getRequestOptions(data))
    );
  }

  getNewExpenseTypes(data): Observable<ProgramTypeDO[]> {
    return withAPIV0ErrorHandling(
      this.http.get<ProgramTypeDO[]>(`${this.actionUrl}${this.newGetapiPath}`, getRequestOptions(data))
    );
  }

  updateStatusChange(id, data) {
    return withAPIV0ErrorHandling(
      this.http.patch(`${this.actionUrl}${this.apiPath}${id}/statuschange/`,  JSON.stringify(data))
    );
  }
}
