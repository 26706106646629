import { Injectable } from '@angular/core';
import { of, forkJoin } from 'rxjs';
import { BudgetService } from 'app/shared/services/backend/budget.service';
import { CompanyExchangeRateService } from 'app/shared/services/backend/company-exchange-rate.service';
import { MasterExchangeRateService } from 'app/shared/services/backend/master-exchange-rate.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BudgetsManagingService {
  constructor(
    public budgetService: BudgetService,
    public companyExchangeRateService: CompanyExchangeRateService,
    public masterExchangeRateService: MasterExchangeRateService,
  ) {}

  updateExchangeRatesAfterAddingOrUpdatingBudget(companyId: number, companyCurrency: string) {
    return this.getDataAfterAddingOrUpdatingBudget(companyId, companyCurrency)
      .pipe(
        map(([budgets, ratesByCurrencies, baseCurrency, dailyRatesData]) =>
          this.prepareUpdatedRatesAfterAddingOrUpdatingBudget(
            budgets, ratesByCurrencies, baseCurrency, dailyRatesData)),
        switchMap(({ shouldRatesBeUpdated, updatedRatesByCurrencies }) =>
          this.updateExchangeRates(shouldRatesBeUpdated, updatedRatesByCurrencies))
      );
  }

  updateExchangeRatesAfterRemovingBudget(companyId: number) {
    return this.getDataAfterRemovingBudget(companyId)
      .pipe(
        map(([budgets, ratesByCurrencies]) =>
          this.prepareUpdatedRatesAfterRemovingBudget(budgets, ratesByCurrencies)),
        switchMap(({ shouldRatesBeUpdated, updatedRatesByCurrencies }) =>
          this.updateExchangeRates(shouldRatesBeUpdated, updatedRatesByCurrencies))
      );
  }

  getDataAfterAddingOrUpdatingBudget(companyId: number, companyCurrency: string) {
    return forkJoin([
      this.budgetService.getAvailableBudgetsForCompany(companyId),
      this.companyExchangeRateService.getRates(companyId),
    ])
    .pipe(switchMap(([budgets, ratesByCurrencies]) => {
      const targetCurrencies = companyCurrency;
      const sourceCurrencies = ratesByCurrencies.map(r => r.currency);

      return forkJoin([
        of(budgets),
        of(ratesByCurrencies),
        of(companyCurrency),
        this.masterExchangeRateService.getDailyRates(targetCurrencies, sourceCurrencies),
      ]);
    }));
  }

  getDataAfterRemovingBudget(companyId: number) {
    return forkJoin([
      this.budgetService.getAvailableBudgetsForCompany(companyId),
      this.companyExchangeRateService.getRates(companyId),
    ]);
  }

  prepareUpdatedRatesAfterAddingOrUpdatingBudget(budgets, ratesByCurrencies, baseCurrency, dailyRatesData) {
    const monthList = this.getMonthsList(budgets);
    const dailyRates = dailyRatesData ? dailyRatesData.rates : {};

    let shouldRatesBeUpdated = false;
    const updatedRatesByCurrencies = ratesByCurrencies.map(currencyRatesData => {
      const { currency, rates } = currencyRatesData;
      const rate = dailyRates[`${currency}>${baseCurrency}`];

      const updatedRates = monthList.reduce((store, month) => {
        if (!rates[month]) {
          shouldRatesBeUpdated = true;
          store[month] = rate;
        } else {
          store[month] = rates[month];
        }
        return store;
      }, {});

      currencyRatesData.rates = updatedRates;
      return currencyRatesData;
    });

    return {
      shouldRatesBeUpdated,
      updatedRatesByCurrencies
    };
  }

  prepareUpdatedRatesAfterRemovingBudget(budgets, ratesByCurrencies) {
    const monthList = this.getMonthsList(budgets);

    let shouldRatesBeUpdated = false;
    const updatedRatesByCurrencies = ratesByCurrencies.map(currencyRatesData => {
      const { rates } = currencyRatesData;

      const updatedRates = monthList.reduce((store, currentMonth) => {
        store[currentMonth] = rates[currentMonth];
        return store;
      }, {});
      shouldRatesBeUpdated = Object.keys(rates).length > Object.keys(updatedRates).length;

      currencyRatesData.rates = updatedRates;
      return currencyRatesData;
    });

    return {
      shouldRatesBeUpdated,
      updatedRatesByCurrencies
    };
  }

  updateExchangeRates(shouldRatesBeUpdated, updatedRatesByCurrencies) {
    if (!shouldRatesBeUpdated) {
      return of(null);
    }

    const updateRequests = updatedRatesByCurrencies.map(({ id, currency, company, rates }) =>
      this.companyExchangeRateService.updateRate(id, currency, company, rates));
    return forkJoin(updateRequests);
  }

  getMonthsList(budgets): string[] {
    const minBudgetStartDate: Date = this.budgetService.getMinBudgetStartDate(budgets);
    const maxBudgetEndDate: Date = this.budgetService.getMaxBudgetEndDate(budgets);
    if (!minBudgetStartDate || !maxBudgetEndDate) {
      return [];
    }

    // this is done to prevent jumping over February when we increment the month
    minBudgetStartDate.setDate(1);

    const months: string[] = [];
    do {
      const year = minBudgetStartDate.getFullYear();
      const monthStr = ('0' + (minBudgetStartDate.getMonth() + 1)).slice(-2);
      months.push(`${year}-${monthStr}`);

      minBudgetStartDate.setMonth(minBudgetStartDate.getMonth() + 1);
      minBudgetStartDate.setDate(1);
    } while (minBudgetStartDate < maxBudgetEndDate);

    return months;
  }
}
