import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ManageCegTableActionsMenuService } from '@manage-ceg/services/manage-ceg-table-actions-menu.service';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { MenuPanelItem } from '../../../header-navigation/components/menu-panel/menu-panel.type';

@Component({
  selector: 'manage-ceg-action-menu',
  templateUrl: './manage-ceg-action-menu.component.html',
  styleUrls: ['./manage-ceg-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageCegActionMenuComponent implements OnChanges {

  private readonly menuService = inject(ManageCegTableActionsMenuService);

  @Input() budgetObjectTypes: BudgetObjectType[] = [];
  menuItems: MenuPanelItem[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.menuItems.length) {
      this.menuItems = this.menuService.generateMenu();
    }

    if (changes.budgetObjectTypes) {
      this.menuService.setObjectTypes(this.budgetObjectTypes);
    }
  }

}
