import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyDO } from '../../types/company.interface';
import { getRequestOptions, withAPIV0ErrorHandling } from '../../utils/http-request.utils';
import { CompanySetupSsoPayload } from '../../types/company-setup-sso-payload.interface';
import { API_V0_URL, API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';

export interface UpdateCompaniesResponse {
  error: CompanyDO[];
  success: CompanyDO[];
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly actionUrl: string = inject(API_V0_URL);
  private readonly http = inject(HttpClient);

  private apiPaths = {
    company: 'company/',
    multiUpdate: 'company/multi_update/',
    setupSSO: 'setup_sso/',
    disableSSO: 'disable_sso/'
  };

  getCompany(id: number): Observable<CompanyDO> {
    return this.http.get<CompanyDO>(`${this.apiV2Url}${this.apiPaths.company}${id}/`);
  }

  updateCompany(id: number, update: Partial<CompanyDO>): Observable<CompanyDO> {
    return this.http.patch<CompanyDO>(`${this.apiV2Url}${this.apiPaths.company}${id}/`, update);
  }

  updateCompanies(payload): Observable<UpdateCompaniesResponse> {
    return this.http.patch<UpdateCompaniesResponse>(`${this.apiV2Url}${this.apiPaths.multiUpdate}`, JSON.stringify(payload));
  }

  setupSSO(companyID: number, payload: CompanySetupSsoPayload): Observable<void> {
    return this.http.patch<void>(
      `${this.apiV2Url}${this.apiPaths.company}${companyID}/${this.apiPaths.setupSSO}`,
      JSON.stringify(payload)
    );
  }

  disableSSO(companyId: number): Observable<void> {
    return this.http.patch<void>(`${this.apiV2Url}${this.apiPaths.company}${companyId}/${this.apiPaths.disableSSO}`, {});
  }

  companyExists(data: object): Observable<ApiV0Response<void>> {
    return withAPIV0ErrorHandling(this.http.get<ApiV0Response<void>>(this.actionUrl + 'company/unique/',  getRequestOptions(data)));
  }

  register(data: object): Observable<ApiV0Response<any>> {
    return withAPIV0ErrorHandling(this.http.post<ApiV0Response<any>>(this.actionUrl + 'user/signup/', JSON.stringify(data)));
  }

  getCompanyInfo(id: number): Observable<ApiV0Response<any>> {
    return withAPIV0ErrorHandling(this.http.get<ApiV0Response<any>>(this.actionUrl + 'companies/' + id + '/'));
  }

  deleteCompany(id: number): Observable<string> {
    return this.http.delete(`${this.apiV2Url}${this.apiPaths.company}${id}/`, {
      responseType: 'text'
    });
  }
}
