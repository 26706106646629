<div class="table-mode-switch">
  <div
    *ngFor="let option of switchOptions"
    class="switch-option"
    (click)="setViewMode(option.viewMode)"
    [class.is-active]="viewMode === option.viewMode"
  >
    <fa-icon [icon]="option.icon"></fa-icon>
    {{ option.label }}
  </div>
  <div class="divider"></div>
  <ng-container *ngIf="dataMode === ManageTableDataMode.Allocation">
    <mat-slide-toggle
      [checked]="showExpenses"
      [disabled]="disableExpenseToggle"
      (change)="toggleExpenses($event.checked)"
      labelPosition="after"
      disableRipple="true"
    >
      Expenses
    </mat-slide-toggle>
    <div class="divider"></div>
    <div class="toggle-group">
      <div class="toggle">
        <mat-slide-toggle
          [checked]="allocationModeFlags && allocationModeFlags[AllocationModeFlag.Formula]"
          (change)="toggleAllocationModeFlag($event.checked, AllocationModeFlag.Formula)"
          class="magenta-toggle"
          labelPosition="after"
          disableRipple="true"
        >
          Formula
        </mat-slide-toggle>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="dataMode === ManageTableDataMode.Spending">
    <div class="toggle-group">
      <div class="toggle">
        <mat-slide-toggle
          [checked]="spendingModeFlags && spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]"
          (change)="toggleSpendingModeFlag($event.checked, SpendingModeFlag.PlannedExpensesInTotal)"
          labelPosition="after"
          disableRipple="true"
        >
          Includes “Planned” in “Total Expenses”
        </mat-slide-toggle>
      </div>
      <div class="toggle">
        <mat-slide-toggle
          [checked]="spendingModeFlags && spendingModeFlags[SpendingModeFlag.ExpensesAsTotal]"
          (change)="toggleSpendingModeFlag($event.checked, SpendingModeFlag.ExpensesAsTotal)"
          labelPosition="after"
          disableRipple="true"
        >
          Expenses as a Total
        </mat-slide-toggle>
      </div>
    </div>
    <div class="divider"></div>
    <div class="toggle-group">
      <div class="toggle">
        <mat-slide-toggle
          [checked]="spendingModeFlags && spendingModeFlags[SpendingModeFlag.Formula]"
          (change)="toggleSpendingModeFlag($event.checked, SpendingModeFlag.Formula)"
          class="magenta-toggle"
          labelPosition="after"
          disableRipple="true"
        >
          Formula
        </mat-slide-toggle>
      </div>
    </div>
  </ng-container>
</div>
