<div class="maintenance-tooltip">
  <tooltip-container>
    <div class="icon">
      <icon-death-star></icon-death-star>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </tooltip-container>
</div>
