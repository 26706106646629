import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'auth-expiry-modal',
  styleUrls: ['./auth-expiry-modal.component.scss'],
  templateUrl: './auth-expiry-modal.component.html'
})
export class AuthExpiryModalComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<AuthExpiryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AuthExpiryModalContext
  ) {
  }

  onCancel() {
    this.dialogRef.close();
  }

  reAuth() {
    this.dialogRef.close(true);
  }
}


export interface AuthExpiryModalContext {
  title: string;
  content: string;
  iconType: string ; //'hubspot' | 'salesforce' | 'linkedin_ads' | 'facebook_ads' | 'google_ads'
  userType: 'admin' | 'user';
}