import { Injectable } from '@angular/core';
import { BudgetObjectDetailsService } from '../types/budget-object-details-service.interface';
import { GoalDetailsState } from '../types/budget-object-details-state.interface';
import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { BudgetObjectCreationContext } from '../types/details-creation-context.interface';
import { GoalsService } from 'app/shared/services/backend/goals.service';
import { ProgramService } from 'app/shared/services/backend/program.service';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { BudgetObjectDetailsManager } from './budget-object-details-manager.service';
import { Metric } from '../components/details-metrics/details-metrics.type';
import { TagMapping } from 'app/shared/types/tag-mapping.interface';
import { GoalStateMapper } from './state-mappers/goal-state-mapper.service';
import { ProgramDO } from 'app/shared/types/program.interface';
import { ExpenseDO } from 'app/shared/types/expense.interface';
import { BudgetObjectTagsService } from './budget-object-tags.service';
import { BudgetObjectCloneResponse } from 'app/shared/types/budget-object-clone-response.interface';
import { GoalDO } from 'app/shared/types/goal.interface';
import { CampaignDO } from 'app/shared/types/campaign.interface';
import { PendoEventName, PendoManagerService, PendoObjectType } from '@shared/services/pendo-manager.service';

@Injectable()
export class GoalDetailsService implements BudgetObjectDetailsService<GoalDetailsState> {
  /**
   * State properties to check updates for
   */
  private updatableStateProps = [
    'typeId',
    'name',
    'notes',
    'createdBy',
    'budgetId',
  ];
  /**
   * State properties to exclude from state diff checks
   */
  private statePropsToExclude = [
    'metricMappings',
    'attachmentMappings'
  ];

  constructor(
    private readonly configuration: Configuration,
    private readonly goalService: GoalsService,
    private readonly tagsManager: BudgetObjectTagsService,
    private readonly campaignService: CampaignService,
    private readonly programService: ProgramService,
    private readonly expenseService: ExpensesService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly stateMapper: GoalStateMapper,
    private readonly pendoManager: PendoManagerService
  ) { }

  public createGoalDetailsState(
    goal: Partial<GoalDO>,
    tagMappings: TagMapping[],
    metricMappings: Metric[],
    campaigns: CampaignDO[],
    programs: ProgramDO[],
    expenses: ExpenseDO[]
  ): GoalDetailsState {
    this.budgetObjectDetailsManager.applyMappingReducedValues(goal, metricMappings);
    const state = this.stateMapper.dataObjectToState(goal);

    return {
      ...state,
      tagMappings: tagMappings || [],
      metricMappings: metricMappings || [],
      campaigns: campaigns || [],
      programs: programs || [],
      expenses: expenses || []
    } as GoalDetailsState;
  }

  cloneObject(objectId: number): Observable<BudgetObjectCloneResponse> {
    return this.goalService.cloneGoal(objectId);
  }

  deleteObject(objectId: number): Observable<void> {
    return this.goalService.deleteGoal(objectId);
  }

  moveToBudget(objectId: number, budgetId: number, companyId: number): Observable<any> {
    return this.goalService.moveToBudget(objectId, budgetId, companyId);
  }

  loadDetails(companyId: number, budgetId: number, objectId: number, data: { isCEGMode: boolean }): Observable<GoalDetailsState> {
    const isCEGMode = data.isCEGMode;
    const getExpenses$ =
      isCEGMode ?
        this.getGoalExpenses$(companyId, budgetId, objectId) :
        of([]);

    return forkJoin([
      this.goalService.getGoal(objectId),
      this.tagsManager.getTagMappings(companyId, objectId, this.configuration.OBJECT_TYPES.goal),
      this.budgetObjectDetailsManager.getMetricMappings(companyId, objectId, this.configuration.OBJECT_TYPES.goal),
      this.getGoalCampaigns$(companyId, budgetId, objectId),
      this.getGoalPrograms$(companyId, budgetId, objectId),
      getExpenses$
    ]).pipe(map(
      ([goal, tagMappings, metricMappings, campaigns, programs, expenses]) =>
        this.createGoalDetailsState(goal, tagMappings, metricMappings, campaigns, programs, expenses)
    ));
  }

  getGoalCampaigns$(companyId: number, budgetId: number, goalId: number): Observable<CampaignDO[]> {
    return this.campaignService.getCampaigns({ company: companyId, budget: budgetId, goal_ids: goalId.toString() });
  }

  getGoalPrograms$(companyId: number, budgetId: number, goalId: number): Observable<ProgramDO[]> {
    return this.programService.getPrograms({ company: companyId, budget: budgetId, goal_ids: goalId.toString() });
  }

  getGoalExpenses$(companyId: number, budgetId: number, goalId: number): Observable<ExpenseDO[]> {
    return this.expenseService.getExpenses({ company: companyId, budget: budgetId, goal_ids: goalId.toString(), include_nested: true });
  }

  saveDetails(prevObjectDetails: GoalDetailsState, newObjectDetails: GoalDetailsState): Observable<GoalDO> {
    if (prevObjectDetails) {
      return this.updateDetails(prevObjectDetails, newObjectDetails).pipe(
        tap(() =>
          this.pendoManager.track(PendoEventName.ObjectUpdated, {
            type: PendoObjectType.Goal
          })
        )
      );
    } else {
      return this.createDetails(newObjectDetails).pipe(
        tap(() =>
          this.pendoManager.track(PendoEventName.ObjectCreated, {
            type: PendoObjectType.Goal
          })
        )
      );
    }
  }

  initDetails(context: BudgetObjectCreationContext, data: Partial<GoalDO>): Observable<GoalDetailsState> {
    const goal: Partial<GoalDO> = { ...data };
    const state = this.createGoalDetailsState(goal, [], [], [], [], []);

    return of(state);
  }

  private createDetails(state: GoalDetailsState): Observable<GoalDO> {
    const payload = this.stateMapper.stateToDataObject(state);
    return this.goalService.createGoal(payload)
      .pipe(
        map(updatedGoal => {
          this.patchState(state, updatedGoal);
          return updatedGoal;
        })
      );
  }

  private updateDetails(prevState: GoalDetailsState, newState: GoalDetailsState): Observable<GoalDO> {
    const stateDiff =
      this.budgetObjectDetailsManager.getStateDiff(prevState, newState, this.updatableStateProps) as Partial<GoalDetailsState>;
    const goalPayload = this.stateMapper.stateToDataObject(stateDiff);
    return this.goalService.updateGoal(newState.objectId, goalPayload).pipe(
      map(updatedGoal => {
        this.patchState(newState, updatedGoal);
        return updatedGoal;
      })
    );
  }

  private patchState(state: GoalDetailsState, patchObject: GoalDO) {
    this.budgetObjectDetailsManager.patchState(state, patchObject)

    if (patchObject.crd) {
      state.created = patchObject.crd;
    }
    if (patchObject.upd) {
      state.updated = patchObject.upd;
    }
  }

  hasChanges(prevState: GoalDetailsState, currentState: GoalDetailsState) {
    if (!prevState) {
      return true;
    }
    const diff = this.budgetObjectDetailsManager.compareStates(prevState, currentState, this.statePropsToExclude);
    return (diff && Object.keys(diff).length > 0);
  }

  logObjectView(objectId: number): Observable<void> {
    return this.goalService.logGoalView(objectId);
  }

  getGoalDO(goalId: number): Observable<GoalDO> {
    return this.goalService.getGoal(goalId);
  }
}
