<div *ngIf="tooltipContext"
     class="wrapper"
     [class]="positionCssClass"
     [ngClass]="{ 'has-action': tooltipContext.action }"
     [style.width.px]="tooltipContext.width"
>
  <div class="tooltip-header" *ngIf="tooltipContext.header">
    <fa-icon
      class="tooltip-icon"
      *ngIf="tooltipContext.icon"
      [icon]="tooltipContext.icon">
    </fa-icon>
    <strong class="title">{{ tooltipContext.header }}</strong>
  </div>

  <div class="tooltip-body" *ngIf="tooltipContext.body">
    {{ tooltipContext.body }}
  </div>

  <div *ngIf="tooltipContext.action as action" class="tooltip-action">
    <a class="link" (click)="action.callback && action.callback()">{{ action.title }}</a>
  </div>

  <div *ngIf="tooltipContext.footer" class="tooltip-footer">
    {{ tooltipContext.footer }}
  </div>
</div>
