import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BudgetSegmentAccess } from '../../types/segment.interface';
import { SharedCostRule, SharedCostRuleSegment } from '../../types/shared-cost-rule.interface';
import { BudgetObjectSegmentData } from '../../types/budget-object-segment-data.interface';
import { SharedIconState } from '../icons/icon-shared/shared.type';

const TOOLTIP_OFFSETS = {
  x: 6,
  y: -35
}
const TOOLTIP_WIDTH = 200;

@Component({
  selector: 'cost-rules',
  templateUrl: './cost-rules.component.html',
  styleUrls: ['./cost-rules.component.scss'],
})
export class CostRulesComponent {

  @Input() segmentList: BudgetSegmentAccess[] = [];
  @Input() isTooltipAttachedToLeft = false;
  @Input() currentSharedState = SharedIconState.Empty;
  @Input() dropDownTrigger = false;
  @Input() readOnly = false;
  @Input() overlapContainer: Element;

  @Output() selectValue = new EventEmitter<BudgetObjectSegmentData>();
  @Output() selectValueWithKeyboard = new EventEmitter<BudgetObjectSegmentData>();

  @ViewChild('dropDownContainer', { read: ElementRef }) dropDownContainer: ElementRef;
  @ViewChild('sharedCostRulesContainer', { read: ElementRef }) sharedCostRulesContainer: ElementRef;
  @ViewChild('overlapTrigger', { read: ElementRef }) overlapTrigger: ElementRef;

  public costData: BudgetObjectSegmentData = {};
  public costRulesList: SharedCostRule[] = [];
  public sharedIconState = SharedIconState;
  public opened: boolean;

  public isTooltipShown = false;
  public tooltipData: any = {
    id: null,
    name: '',
    segments: [],
    coords: {}
  };
  public ruleFocusIndex = -1;

  @Input() set sharedCostRuleList(value: SharedCostRule[]) {
    this.costRulesList = value;
  }

  @Input() set selectedData(value: BudgetObjectSegmentData) {
    this.costData = value;
  }

  openCostList() {
    if (!this.opened) {
      this.opened = true;
      this.hideTooltip();
      setTimeout(() => this.dropDownContainer.nativeElement.focus(), 0)
    }
  }

  closeCostList() {
    if (this.opened) {
      this.opened = false;
    }
  }

  showTooltip(e: any, ruleId: number) {
    const rule = this.costRulesList.find(el => el.id === ruleId);

    if (rule) {
      this.isTooltipShown = true;
      this.prepareCoordsForTooltip(e);
      this.prepareDataForTooltip(rule);
    }
  }

  hideTooltip() {
    this.isTooltipShown = false;
  }

  handleSelectSharedCostRule = (rule: SharedCostRule, isKeyboard?: boolean) => {
    if (!rule) {
      return;
    }

    if (isKeyboard) {
      this.selectValueWithKeyboard.emit({ sharedCostRuleId: rule.id });
    } else {
      this.selectValue.emit({ sharedCostRuleId: rule.id });
    }
  }

  prepareCoordsForTooltip(e: any) {
    const coords = e.target.getBoundingClientRect();
    const { x, y, width } = coords;
    this.tooltipData.coords.top = y + TOOLTIP_OFFSETS.y;
    if (this.isTooltipAttachedToLeft) {
      this.tooltipData.coords.left = x - TOOLTIP_WIDTH - TOOLTIP_OFFSETS.x;
    } else {
      this.tooltipData.coords.left = x + width + TOOLTIP_OFFSETS.x;
    }
  }

  prepareDataForTooltip(rule: SharedCostRule) {
    this.tooltipData.id = rule.id;
    this.tooltipData.name = rule.name;
    this.tooltipData.segments = rule.segments.map((segment: SharedCostRuleSegment) => {
      const { id, cost } = segment;
      const segmentName = this.segmentList.find((item: BudgetSegmentAccess) => item.id === id);
      const name = segmentName ? segmentName.name : '';

      return {
        id,
        cost,
        name
      };
    });
  }

}
