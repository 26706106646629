import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { CompanyUserService } from 'app/shared/services/backend/company-user.service';
import { UserService } from 'app/shared/services/backend/user.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ActionToConfirm, PermissionFormGroup, PermissionsListFormGroup, TeamMember } from './team-page.type';
import * as utils from './team-page.utils';
import { createDateString, parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { getDiffInDays, getTodaysDate } from 'app/shared/utils/date.utils';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { CompanyService } from 'app/shared/services/backend/company.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { Configuration } from 'app/app.constants';
import { DialogContext } from 'app/shared/types/dialog-context.interface';
import { CompanyUserDO, CompanyUserStatus, CreatedTeamMate, NewTeamMatePayload } from 'app/shared/types/company-user-do.interface';
import { adaptBudgetPermission, createUsersProfilesMap } from 'app/shared/utils/users.utils';
import { ChurnZeroService, EventName, UpdateTeamType } from 'app/shared/services/churn-zero.service';
import { BudgetPermission, UserPermission } from '../../shared/types/user-permission.type';
import { UserProfileDO } from 'app/shared/types/user-profile.interface';

const SNACKBAR_DURATION = 5000;

@Injectable()
export class TeamPageService implements OnDestroy {
  private readonly teamMembers = new BehaviorSubject<TeamMember[]>([]);
  public readonly teamMembers$ = this.teamMembers.asObservable();
  private readonly destroy$ = new Subject<void>();
  private companyId: number;
  public ssoEnabled: boolean;
  public appliedSorting = { column: 'firstName', reverse: false };
  public accountOwnerName = '';
  public memberForDeletingName = '';
  public companyDomainName = '';

  constructor(
    private companyUserService: CompanyUserService,
    private companyService: CompanyService,
    private userManager: UserManager,
    private userService: UserService,
    private utilityService: UtilityService,
    private companyDataService: CompanyDataService,
    private dialogManager: BudgetObjectDialogService,
    private config: Configuration,
    private readonly churnZeroService: ChurnZeroService
  ) {
    this.companyDataService.selectedCompany$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null),
        switchMap(cmp => this.companyService.getCompany(cmp.id))
      )
      .subscribe(this.onCompanyLoaded.bind(this));

      combineLatest([this.userManager.currentCompanyUser$, this.companyDataService.selectedCompany$])
      .pipe(
        takeUntil(this.destroy$),
        filter(([user, company]) => !!user && !!company)
      )
      .subscribe(([user]) => this.initTeamTable(user));
  }

  private onCompanyLoaded(company: CompanyDO) {
    this.companyId = company.id;
    this.ssoEnabled = company.sso;
    if (this.ssoEnabled) {
      this.companyDomainName = `${company.sso_domain}.${this.config.envDomain}`;
    }
  }

  private initTeamTable(currentUser: CompanyUserDO) {
    this.utilityService.showLoading(true);
    this.getTeamMembers$(currentUser)
      .subscribe(
        teamMembers => {
          this.teamMembers.next(teamMembers);
          this.utilityService.showLoading(false);
        },
        () => this.utilityService.handleError({ message: 'Failure to load team list' })
      );
  }

  private getTeamMembers$(currentUser: CompanyUserDO): Observable<TeamMember[]> {
    const usersProfiles$ = this.userService.getUsersProfiles();
    const companyUsers$ = this.companyDataService.companyUsersDO$
      .pipe(
        filter(data => data != null),
        takeUntil(this.destroy$)
      );

    return combineLatest([
      usersProfiles$,
      companyUsers$
    ]).pipe(
      tap(([usersProfiles, companyUsers]) => this.setAccountOwnerName(usersProfiles, companyUsers)),
      map(([usersProfiles, companyUsers]) => this.createTeamData(usersProfiles, companyUsers, currentUser))
    )
  }

  private setAccountOwnerName(usersProfiles: UserProfileDO[], companyUsers: any[]) {
    const accountOwner = companyUsers.find(user => user.is_account_owner);
    if (accountOwner) {
      const accountOwnerProfile = usersProfiles.find(user => user.user === accountOwner.user);
      this.accountOwnerName = accountOwnerProfile.name;
    }
  }

  private createTeamData(usersProfiles, companyUsers: CompanyUserDO[], currentUser: CompanyUserDO): TeamMember[] {
    const usersProfilesById = createUsersProfilesMap(usersProfiles);
    const teamData = companyUsers
      .filter(companyUser => companyUser.status !== CompanyUserStatus.Disabled)
      .map((companyUser: CompanyUserDO) => {
        const userProfile = usersProfilesById[companyUser.user];
        const editable = currentUser.is_admin && userProfile.user !== currentUser.id;
        const permissions = companyUser.permissions.map(budgetPermission => adaptBudgetPermission(budgetPermission, companyUser.is_admin));
        return {
          id: userProfile.user,
          profileId: userProfile.id,
          companyUserId: companyUser.id,
          sso: userProfile.sso_user_id,
          weeklyEmails: companyUser.subscribed_for_mailing,
          withPermission: companyUser.is_admin || permissions && !!permissions.length,
          invitationDate: companyUser.invitation_date ? createDateString(new Date(companyUser.invitation_date)) : '',
          isActive: companyUser.status === CompanyUserStatus.Active,
          inviteAccepted: utils.isInviteAccepted(companyUser),
          isAdmin: companyUser.is_admin,
          editable,
          profileForm: this.createProfileForm(userProfile, companyUser.id, editable),
          permissionsForm: this.createPermissionsForm(permissions, companyUser.id, companyUser.is_admin, editable),
          is_account_owner: companyUser.is_account_owner
        }
      });
    return utils.getSortedData(teamData, this.appliedSorting);
  }

  private createProfileForm = (userProfile, companyUserId: number, editable = true): UntypedFormGroup => {
    const profileForm = utils.createEmptyProfileForm();
    if (userProfile) {
      profileForm.patchValue({
        companyUserId: companyUserId, userProfileId: userProfile.id,
        firstName: userProfile.first_name, lastName: userProfile.last_name, email: userProfile.email
      }, { emitEvent: false })
    }
    if (!editable) {
      profileForm.disable();
    }
    profileForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.handleProfileChange(profileForm));
    return profileForm;
  };

  private handleProfileChange(profileForm: UntypedFormGroup) {
    if (!profileForm.value.companyUserId && !profileForm.value.profileId) {
      const requiredFields = ['firstName', 'lastName', 'email'];
      if (profileForm.valid && requiredFields.every(fieldName => profileForm.value[fieldName].trim())) {
        this.createNewTeammate(profileForm);
      }
    }
  }

  private createPermissionsForm(permissions: BudgetPermission[], companyUserId, isAdmin, editable = true) {
    const permissionsForm = utils.createPermissionsForm(permissions, companyUserId, isAdmin);
    if (!editable) {
      permissionsForm.disable();
    } else {
      permissionsForm.controls.permissions.controls.forEach(
        (fg: PermissionFormGroup) => this.subscribeOnPermissionsChanges(fg)
      )
    }
    return permissionsForm as PermissionsListFormGroup;
  }

  private subscribeOnPermissionsChanges(formGroup: PermissionFormGroup) {
    formGroup.controls.permission.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.handlePermissionChange(formGroup));
    formGroup.controls.budget.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.handleBudgetChange(formGroup));
    formGroup.controls.segments.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.handleSegmentsChange(formGroup));
  }

  private handlePermissionChange = (formData: PermissionFormGroup) => {
    const companyUserId = formData.parent.parent.get('companyUserId').value;
    if (companyUserId) {
      const permissionType = formData.controls.permission.value;
      const isAdmin = permissionType === UserPermission.Admin;
      const rollBackPermission = () => formData.patchValue(
        { permission: formData.get('rollbackPermission').value },
        { emitEvent: false }
      );
      if (isAdmin) {
        this.grantAdminPermission(formData, companyUserId, rollBackPermission)
      } else {
        this.grantUserPermission(formData, companyUserId, rollBackPermission)
      }
    }
    if (formData.controls.budget.disabled) {
      formData.controls.budget.enable({ emitEvent: false });
    }
  };

  private handleBudgetChange = (formData: PermissionFormGroup) => {
    const selectedBudgetId = formData.controls.budget.value.id;
    const permissionsList = formData.parent.controls as PermissionFormGroup[];
    const budgetDuplication = permissionsList.some((fg: PermissionFormGroup) =>
      fg.value.budget?.id === selectedBudgetId
    );
    if (budgetDuplication) {
      formData.patchValue(
        { budget: formData.get('rollbackBudget').value },
        { emitEvent: false }
      )
      this.utilityService.showCustomToastr(`User has permission for selected budget`, 'Close', { timeOut: SNACKBAR_DURATION });
    } else {
      if (formData.controls.segments.disabled) {
        formData.controls.segments.enable({ emitEvent: false });
      }
      formData.controls.segments.reset([], { emitEvent: false });
    }
  };

  private handleSegmentsChange = (formData: UntypedFormGroup) => {
    const companyUserId = formData.parent.parent.get('companyUserId').value;
    this.grantUserPermission(formData, companyUserId, () => {
      formData.patchValue({ segments: null }, { emitEvent: false })
    })
  };

  private grantAdminPermission(formData: UntypedFormGroup, companyUserId: number, onCancel) {
    this.confirmAction(ActionToConfirm.GrantAdminPermission, confirm => {
      if (confirm) {
        this.setAccessPermissions(formData.parent as UntypedFormArray, companyUserId, true)
      } else {
        onCancel();
      }
    })
  }

  private grantUserPermission(formData: UntypedFormGroup, companyUserId: number, onCancel) {
    const teamMember = this.getTeamMemberByCompanyUserId(companyUserId);
    const permissionType = formData.controls.permission.value;
    const budget = formData.controls.budget.value;
    const segments = formData.controls.segments.value;
    const isAdmin = permissionType === UserPermission.Admin;
    if (formData.valid && companyUserId && permissionType && budget && segments) {
      if (teamMember.isActive && teamMember.inviteAccepted) {
        this.confirmAction(ActionToConfirm.GrantPermission, confirm => {
          if (confirm) {
            this.setAccessPermissions(formData.parent as UntypedFormArray, companyUserId, isAdmin)
          } else {
            onCancel();
          }
        })
      } else {
        this.setAccessPermissions(formData.parent as UntypedFormArray, companyUserId, isAdmin)
      }
    }
  }

  private setAccessPermissions(formArray: UntypedFormArray, companyUserId: number, isAdmin: boolean) {
    const permissionsPayload = {
      is_admin: isAdmin,
      permissions: !isAdmin
        ? utils.getPermissionsFormValue(formArray as UntypedFormArray)
        : []
    };
    this.companyUserService.setAccessPermissions(companyUserId, permissionsPayload)
      .subscribe(
        response => this.permissionsUpdateSuccess(response.message, companyUserId, isAdmin),
        error => this.utilityService.handleError({ message: 'Failure to set user permissions' })
      )
  }

  private createNewTeammate(profileForm: UntypedFormGroup) {
    const { firstName, lastName, email } = profileForm.value;
    const snackBarRef = this.utilityService.showCustomToastr(`Creating new user ${email}`, 'UNDO (5)');
    const payload: NewTeamMatePayload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      company: this.companyId,
      is_admin: false
    };
    const timer$ = timer(SNACKBAR_DURATION);
    const cancelEvent = new Subject<void>();
    cancelEvent.subscribe(() => profileForm.patchValue({ email: '' }, { emitEvent: false }))
    snackBarRef.onAction.subscribe(() => cancelEvent.next());
    timer$
      .pipe(takeUntil(cancelEvent),
        switchMap(() => {
          this.utilityService.hideToastr(snackBarRef.toastId);
          return this.companyUserService.createNewTeammate(payload)
        })
      ).subscribe(
        receivedData => this.applyCreatedTeammate(receivedData, payload),
        error => {
          let message = 'Failure to create user';
          if (error?.status === 400 && (error[0] || '').includes('already')) {
            message = 'This email address already exists on this team page and cannot be added again';
          }
          this.utilityService.handleError({ message });
        }
    );
  }

  private applyCreatedTeammate(receivedData: CreatedTeamMate, payload: NewTeamMatePayload) {
    const message = `User has been created successfully`;
    this.utilityService.showToast({ Title: '', Message: message, Type: 'success' });
    this.trackTeamUpdateInChurnZero(UpdateTeamType.AddUser);
    const teamMembers = this.teamMembers.getValue();
    const created = teamMembers.find(user => {
      const emailValue = user.profileForm.value.email;
      return emailValue === payload.email;
    });
    if (created) {
      created.id = receivedData.user;
      created.profileId = receivedData.profile_id;
      created.companyUserId = receivedData.id;
      created.isAdmin = receivedData.is_admin;
      created.weeklyEmails = receivedData.subscribed_for_mailing;
      created.profileForm.patchValue({
          companyUserId: receivedData.id,
          profileId: receivedData.profile_id
        }, { emitEvent: false });
      created.permissionsForm.patchValue(
        { companyUserId: receivedData.id },
        { emitEvent: false }
      );
      this.submitUnsavedPermissions(created);
    }
  }

  private submitUnsavedPermissions(teamMember: TeamMember) {
    const permissionsFormArray = teamMember.permissionsForm.controls.permissions as UntypedFormArray;
    const permissions = permissionsFormArray.controls as PermissionFormGroup[];
    const unsavedPermissions: boolean = permissions.some(fg => fg.controls.segments.value && fg.controls.segments.value.length);
    const isAdmin: boolean = permissions[0].controls.permission.value === UserPermission.Admin;
    if (isAdmin || unsavedPermissions) {
      this.setAccessPermissions(permissionsFormArray as UntypedFormArray, teamMember.companyUserId, isAdmin)
    }
  }

  public deleteTeamMember(companyUserId, teamMemberIndex, teamMemberName) {
    this.memberForDeletingName = teamMemberName.replace(' ', '').length ? teamMemberName : 'this user';
    this.confirmAction(ActionToConfirm.DeleteUser, confirm => {
      if (confirm) {
        if (companyUserId) {
          this.companyUserService.deleteCompanyUser(companyUserId)
            .subscribe(
              () => {
                this.removeTeamMemberItem(companyUserId);
                this.trackTeamUpdateInChurnZero(UpdateTeamType.DeleteUser);
                this.companyDataService.loadCompanyUsers(this.companyId, () => {
                  this.utilityService.handleError({ message: 'Failure to reload company users' })
                })
              },
              () => this.utilityService.handleError({ message: 'Failure to delete user' })
            )
        } else {
          this.removeTeamMemberItem(null, teamMemberIndex);
        }
      }
    })
  }

  private removeTeamMemberItem(companyUserId, teamMemberIndex?) {
    if (companyUserId) {
      const message = `User has been deleted successfully`;
      this.utilityService.showToast({ Title: '', Message: message, Type: 'success' });
    }
    const updTeamMembers = this.teamMembers.getValue().filter(
      (user, index) =>
        companyUserId
          ? user.companyUserId !== companyUserId
          : index !== teamMemberIndex
    );
    this.teamMembers.next(updTeamMembers);
  }

  private createEmptyTeamMemberRow(): TeamMember {
    return {
      ...utils.createEmptyTeamMember(),
      profileForm: this.createProfileForm(null, null),
      permissionsForm: this.createPermissionsForm([], null, false)
    }
  }

  private getTeamMemberByCompanyUserId(companyUserId) {
    return this.teamMembers.getValue().find(user => user.companyUserId === companyUserId)
  }

  public addEmptyTeamMemberRow() {
    const emptyRow = this.createEmptyTeamMemberRow();
    const updMembers = [emptyRow, ...this.teamMembers.getValue()];
    this.teamMembers.next(updMembers)
  }

  public addEmptyPermissionRow(teamMember) {
    const permissionRow = utils.createEmptyPermissionRow();
    this.subscribeOnPermissionsChanges(permissionRow);
    (teamMember.permissionsForm.controls.permissions as UntypedFormArray).push(permissionRow);
  }

  public revokePermission(teamMember, permissionIndex) {
    this.confirmAction(ActionToConfirm.RevokePermission, confirm => {
      if (confirm) {
        const permissionsArray = (teamMember.permissionsForm.controls.permissions as UntypedFormArray);
        const permission = permissionsArray.controls[permissionIndex] && permissionsArray.controls[permissionIndex].value;
        permissionsArray.removeAt(permissionIndex);
        if (permission && permission.segments) {
          this.setAccessPermissions(permissionsArray, teamMember.companyUserId, false);
        }
      }
    })
  }

  public duplicatePermissions(teamMember: TeamMember) {
    const permissionsFormArray = (teamMember.permissionsForm.controls.permissions as UntypedFormArray);
    const permissionsToDuplicate = permissionsFormArray.controls.map(formGroup => ({ ...formGroup.value }))
    const newTeamMemberRow = {
      ...this.createEmptyTeamMemberRow(),
      permissionsForm: this.createPermissionsForm(permissionsToDuplicate, null, teamMember.isAdmin)
    };
    const updTeam = [...this.teamMembers.getValue()];
    const teamMemberIndex = updTeam.indexOf(teamMember);
    updTeam.splice(teamMemberIndex + 1, 0, newTeamMemberRow);
    this.teamMembers.next(updTeam);
  }

  public toggleUserEmailSubscription(companyUserId, subscribed) {
    this.companyUserService.updateCompanyUser(companyUserId, { subscribed_for_mailing: subscribed })
      .subscribe(
        () => {
          const message = `User has been updated successfully`;
          this.utilityService.showToast({ Title: '', Message: message, Type: 'success' });
          this.trackTeamUpdateInChurnZero(UpdateTeamType.UpdateUser);
        },
        () => {
          this.utilityService.handleError({ message: 'Failure to subscribe user for weekly emails.' });
          const teamMember = this.getTeamMemberByCompanyUserId(companyUserId);
          teamMember.weeklyEmails = !subscribed;
        }
      )
  }

  public sendInvitation(teamMember: TeamMember) {
    let ableToSend = !teamMember.invitationDate;
    if (teamMember.invitationDate) {
      const today = getTodaysDate();
      const diffInDays = getDiffInDays(parseDateString(teamMember.invitationDate), today);
      ableToSend = diffInDays >= utils.DAYS_TO_EXPIRE;
    }
    if (ableToSend) {
      this.companyUserService.sendInvitation(teamMember.companyUserId)
        .subscribe(
          () => this.onInvitationSent(teamMember.companyUserId),
          error => this.utilityService.handleError({ message: 'Failure to send invitation' })
        )
    }
  }

  public sortTeamMembers(sortColumn: string) {
    const { column, reverse } = this.appliedSorting;
    const updReverse = sortColumn === column ? !reverse : false;
    this.appliedSorting = { column: sortColumn, reverse: updReverse };
    const updTeamMembers = utils.getSortedData(this.teamMembers.getValue(), this.appliedSorting);
    this.teamMembers.next(updTeamMembers);
  }

  public canLeave(): Observable<boolean> {
    const isValidData = utils.validateTeamPageData(this.teamMembers.getValue());
    if (isValidData) {
      return of(true);
    } else {
      const canLeave = new Subject<boolean>();
      this.confirmAction(ActionToConfirm.LeavePage, confirm => canLeave.next(confirm));
      return canLeave;
    }
  }

  public trackTeamUpdateInChurnZero(updateType: UpdateTeamType) {
    this.churnZeroService.trackEvent(EventName.TeamUpdate, updateType);
  }

  public getTeamMemberIndex(teamMember: TeamMember): number {
    return this.teamMembers.getValue().indexOf(teamMember);
  }

  private onInvitationSent(companyUserId) {
    const message = `Invitation has been sent`;
    this.utilityService.showToast({ Title: '', Message: message, Type: 'success' });
    const teamMember = this.getTeamMemberByCompanyUserId(companyUserId);
    if (teamMember) {
      teamMember.invitationDate = createDateString(getTodaysDate());
    }
  }

  private permissionsUpdateSuccess(message, companyUserId, isAdmin) {
    this.utilityService.showToast({ Title: '', Message: message, Type: 'success' });
    this.trackTeamUpdateInChurnZero(UpdateTeamType.UpdateUser);
    const updatedTeamMember = this.getTeamMemberByCompanyUserId(companyUserId);
    if (updatedTeamMember) {
      updatedTeamMember.isAdmin = isAdmin;
      updatedTeamMember.withPermission = true;
      if (isAdmin) {
        const permissionsFormArray = updatedTeamMember.permissionsForm.controls.permissions;
        if (permissionsFormArray.controls) {
          while (permissionsFormArray.controls.length > 1) {
            permissionsFormArray.removeAt(permissionsFormArray.controls.length - 1);
          }
          permissionsFormArray.controls[0].patchValue(
            { permission: UserPermission.Admin, budget: null, segments: [] }, { emitEvent: false }
          );
        }
      }
    }
  }

  private confirmAction(action: ActionToConfirm, callback: (value: boolean) => void) {
    const dialogData: DialogContext = utils.getConfirmationDialogData(action, this.accountOwnerName, this.memberForDeletingName);

    dialogData.actions = [
      {
        ...dialogData.cancelAction,
        handler: () => callback(false)
      },
      {
        ...dialogData.submitAction,
        handler: () => callback(true)
      }
    ];

    this.dialogManager.openConfirmationDialog(dialogData, {
      width: '574px',
      panelClass: 'extended-confirmation-dialog'
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
