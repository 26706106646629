import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomFieldFilterPageRoute, Filter, FilterName, FilterSet, InnerFilterSet, OnFilterChangeEvent } from '../filters.interface';
import { SelectItem } from 'app/shared/types/select-groups.interface';
import { CustomFieldFiltersManagementService } from '../filter-services/custom-field-filter-management.service';

@Component({
  selector: 'filters-container',
  templateUrl: './filters-container.component.html',
  styleUrls: ['./filters-container.component.scss']
})
export class FiltersContainerComponent implements OnInit, OnDestroy {
  @Input() activeFilters: FilterSet;
  @Input() filtersData: Filter[] = [];
  @Input() isCEGMode = false;
  @Input() customFiltersData: Filter[] = [];
  @Input() activeRouteForCustomFilters: CustomFieldFilterPageRoute;

  @Output() onApplyClick = new EventEmitter<FilterSet>();
  @Output() onSaveClick = new EventEmitter<FilterSet>();
  @Output() onCancelClick = new EventEmitter();

  public customFieldFiltersAllowedPage = [CustomFieldFilterPageRoute.Expense, CustomFieldFilterPageRoute.Manage, CustomFieldFilterPageRoute.Invoice, CustomFieldFilterPageRoute.Calendar];

  private readonly customFieldFiltersManagementService = inject(CustomFieldFiltersManagementService)
  
  public saveBtnDisabledDueToCustomFieldFiltersMsg = 'This functionality is not available at this time. Please deselect all Custom Fields first to save filters.';
  public pickedFiltersDisabledTooltipInfo = 'Combining custom attributes with other filters is currently unavailable at this time'

  private destroy$ = new Subject<void>();
  private innerFiltersSet = new BehaviorSubject<InnerFilterSet>({});
  private activeFiltersSnapshot: string;

  public pickedFiltersCount = 0;
  public unappliedFilters = false;
  public canSaveFilters = false;
  public isCustomFieldFiltersSelected: boolean;
  public isCEGFiltersSelected: boolean;

  ngOnInit() {
    this.innerFiltersSet
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onInnerFilterSetChange());
    this.setFiltersContainerData();
  }

  get pickedFilters(): InnerFilterSet {
    return this.innerFiltersSet.value;
  }

  private setFiltersContainerData() {
    
    const filtersDataReducer = (store: Record<string, any>, filter: Filter) => {
      const selectedOptionsIds = this.activeFilters[filter.fieldName] || [];
        return {
          ...store,
          [filter.fieldName]: selectedOptionsIds.map(optId => {
            let title;
            let groupTitle;
            for (const option of filter.availableItems) {
              if (option.value === optId) {
                title = option.title;
                groupTitle = option.groupTitle;
                break;
              }
              if (option.children?.length) {
                const child = option.children.find(el => el.value === optId);
                if (child) {
                  title = child.title;
                  groupTitle = child.groupTitle;
                  break;
                }
              }
            }
            return {
              title: title || optId?.toString(),
              groupTitle,
              value: optId
            }
          })
      }
    }

    const pickedFilters = this.filtersData?.reduce(filtersDataReducer, {});

    const pickedCustomFilters = this.customFiltersData?.reduce(filtersDataReducer, {});

    const allPickedFilters = { ...pickedFilters, ...pickedCustomFilters}

    this.activeFiltersSnapshot = JSON.stringify(allPickedFilters);
    this.innerFiltersSet.next(allPickedFilters);
  }

  public onSelectionChanged(event: OnFilterChangeEvent) {
    const { fieldName, values } = event;
    this.innerFiltersSet.next({
      ...this.pickedFilters, [fieldName]: [ ...values ]
    });
  }

  public createFilterSet(): FilterSet {
    return Object.entries(this.pickedFilters).reduce(
      (store, [fieldName, options]) => {
        store[fieldName] = options.map(opt => opt.value)
        return store;
      }, {}
    )
  }

  public applyFilters() {
    const filterSet = this.createFilterSet();
    this.onApplyClick.emit(filterSet);
    this.customFieldFiltersManagementService.isCustomFieldFiltersSelected.next(this.isCustomFieldFiltersSelected);
    this.customFieldFiltersManagementService.isCEGFiltersSelected.next(this.isCEGFiltersSelected);
  }

  public saveFilterSet() {
    const filterSet = this.createFilterSet();
    this.onSaveClick.emit(filterSet);
  }

  public trackListBy(_index: number, filter: Filter) {
    return filter.fieldName;
  }

  public clearSingleFilterItem(fieldName: FilterName, filterItem: SelectItem) {
    this.innerFiltersSet.next({
      ...this.pickedFilters,
      [fieldName]: this.pickedFilters[fieldName].filter(
        option => option.value !== filterItem.value
      )
    });
  }

  public clearFilterByFieldName(fieldName: FilterName) {
    this.innerFiltersSet.next({
      ...this.pickedFilters, [fieldName]: []
    });
  }

  public clearAllFilters() {
    const emptyFilterSet = Object.keys(this.pickedFilters).reduce(
      (store, fieldName) => ({...store, [fieldName]: []})
    , {})
    this.innerFiltersSet.next(emptyFilterSet);
  }

  private onInnerFilterSetChange() {
    this.updateFiltersDiff();
    this.updateUserCanSaveFiltersFlag();
    this.calcPickedFilters();
    this.checkUsageOfCustomFieldFilters();
    this.checkUsageOfCEGFilters();
  }

  private checkUsageOfCustomFieldFilters() {
    this.isCustomFieldFiltersSelected = Object.keys(this.pickedFilters).some(fieldName => {
      return this.customFiltersData.map(fd => fd.fieldName).includes(fieldName) && this.pickedFilters[fieldName].length
    })

  }

  private checkUsageOfCEGFilters() {
    const pickedCEGFilterList =  Object.keys(this.pickedFilters).filter(item => !this.customFiltersData.map(fd => fd.fieldName).includes(item))
    this.isCEGFiltersSelected = pickedCEGFilterList.some(fieldName => this.pickedFilters[fieldName].length);
  }

  private updateFiltersDiff() {
    this.unappliedFilters = JSON.stringify(this.pickedFilters) !== this.activeFiltersSnapshot;
  }

  private calcPickedFilters() {
    this.pickedFiltersCount = Object.keys(this.pickedFilters).reduce(
      (count, fieldName) => (
        this.pickedFilters[fieldName]
          ? count + this.pickedFilters[fieldName].length
          : count
      ), 0);
  }

  private updateUserCanSaveFiltersFlag() {
    this.canSaveFilters = Object.keys(this.pickedFilters).some(fieldName => {
      return this.pickedFilters[fieldName].length > 0;
    })
  }

  getFilterItemTitle(title: string){
    let customTitleTag;
    if (title.search("(Disabled)") >= 0) {
      customTitleTag = title.split("(Disabled)");
    } else{
      customTitleTag = [title]
    }
    return customTitleTag;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
