import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TimeZone } from '@vvo/tzdb';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { UtilityService } from 'app/shared/services/utility.service';
import { convertTo12H, convertTo24H } from 'app/shared/utils/time.utils';
import { getLocalTimezone } from 'app/shared/utils/timezone.utils';
import { TIMEPICKER_THEME } from 'app/shared/constants/timepicker.theme';
import { IntegrationScheduleSettings } from '../../types/integration-schedule-settings.interface';

@Component({
  selector: 'integration-schedule-setup-form',
  templateUrl: './integration-schedule-setup-form.component.html',
  styleUrls: ['./integration-schedule-setup-form.component.scss']
})
export class IntegrationScheduleSetupFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() companyId: number;
  @Input() settings: IntegrationScheduleSettings;
  @Input() timezones: TimeZone[] = [];
  @Output() settingsChanged = new EventEmitter<IntegrationScheduleSettings>();

  private destroy$ = new Subject<void>();
  private syncDataChanged$ = new Subject<void>();
  public minutesGap = 15;
  public selectedTime = null;
  public selectedTmz: TimeZone = null;
  public timepickerTheme: NgxMaterialTimepickerTheme = TIMEPICKER_THEME;

  constructor(private readonly utilityService: UtilityService) {}

  ngOnInit(): void {
    this.syncDataChanged$
      .asObservable()
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500)
      )
      .subscribe(() => {
        const localTmz = this.selectedTmz && this.selectedTmz.alternativeName;
        const syncTime = this.getCurrentSyncTime();

        this.settingsChanged.emit({
          localTmz,
          syncTime
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.timezones && this.timezones && this.selectedTmz === null) {
      this.selectedTmz = getLocalTimezone(this.timezones);
    }

    if (changes.settings && this.settings) {
      this.applyScheduleSettings(this.settings);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private applyScheduleSettings(settings) {
    const { syncTime, localTmz } = settings;

    if (localTmz) {
      this.applyScheduleTmz(localTmz);
    }

    if (syncTime) {
      this.applyScheduleTime(syncTime);
    }
  }

  private applyScheduleTime(cronTime: string) {
    try {
      this.selectedTime = convertTo12H(cronTime, this.selectedTmz && this.selectedTmz.rawOffsetInMinutes);
    } catch (e) {
      this.utilityService.handleError(e);
    }
  }

  private applyScheduleTmz(cronTmz: string) {
    const tmz = this.timezones.find(zone => (zone.alternativeName === cronTmz));
    if (tmz) {
      this.selectedTmz = tmz;
    }
  }

  private getCurrentSyncTime() {
    try {
      return convertTo24H(this.selectedTime, this.selectedTmz && this.selectedTmz.rawOffsetInMinutes);
    } catch (e) {
      this.utilityService.handleError(e);
    }
  }

  public handleTimeChanged($event) {
    if ($event === this.selectedTime) {
      return;
    }
    this.selectedTime = $event;
    this.syncDataChanged$.next();
  }

  public handleTmzChanged($event) {
    this.selectedTmz = $event;
    this.syncDataChanged$.next();
  }
}
