import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { QuarterSelection, TimeframeChoiceItem, TimeframeSelection } from '@shared/components/timeframe-select/timeframe-select.types';
import { TIMEFRAME_QUARTER_IDS } from '@shared/components/timeframe-select/timeframe-select.constants';

@Component({
  selector: 'timeframe-select',
  templateUrl: './timeframe-select.component.html',
  styleUrls: ['./timeframe-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tilesAnimation', [
      transition(':enter', [
        query(':enter', [
          style({ opacity: 0, transform: 'translate(10px, 10px)' }),
          stagger(70, [
            animate('0.2s', style({ opacity: 1, transform: 'translate(0, 0)' }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class TimeframeSelectComponent {
  @Input() showLegend: boolean;
  @Input() isRange: boolean;
  @Input() timeframes: TimeframeChoiceItem[];
  @Input() selectedTimeframes: number[] = [];

  @Output() selectionChanged = new EventEmitter<TimeframeSelection>();
  @Output() quarterSelectionChanged = new EventEmitter<QuarterSelection>();

  readonly quarterIds = TIMEFRAME_QUARTER_IDS;
  private selectedQuarterId: number;

  onSelectionChange(isChecked: boolean, item: TimeframeChoiceItem): void {
    this.selectedQuarterId = undefined;
    this.selectionChanged.emit({ isChecked, item });
  }

  isWithinRange(id: number): boolean {
    const index = this.selectedTimeframes.indexOf(id);
    return this.isRange && index > 0 && index < this.selectedTimeframes.length - 1;
  }

  onQuarterClick(id: number): void {
    if (!this.selectedQuarterId || this.selectedQuarterId !== id) {
      this.selectedQuarterId = id;
      this.quarterSelectionChanged.emit({ id, isSelected: true });
      return;
    }
    this.selectedQuarterId = undefined;
    this.quarterSelectionChanged.emit({ id, isSelected: false });
  }
}
