<header class="bar-header">
  <h2 class="bar-title">
    <fa-icon
      *ngIf="barPartsData.isLocked"
      [icon]="['fas', 'lock-keyhole']"
      class="bar-title-lock"></fa-icon>
    {{ barPartsData.title }}
  </h2>
  <h3 *ngIf="barPartsData.overSpend" class="bar-over">
    <span class="font-monospace" *ngIf="!cegFlagEnabled else cegBarOver">{{ barPartsData.overSpend | spendingTimelineFormat }}</span>
    <ng-template #cegBarOver>
      <span class="font-monospace overspend">({{ barPartsData.overSpend | spendingTimelineFormat }})</span>
    </ng-template>
    <icon-flame [hasProblem]="true" *ngIf="!cegFlagEnabled"></icon-flame>
  </h3>
</header>

<div class="spending-bar">
  <div class="spending-bar-tooltip-area"
       [timelineChartTooltip]="summaryTooltipData"
       [totalColumns]="totalColumns"
       [positionInChart]="positionInChart"
       [ngStyle]="{'height': barPartsHeight.budget + overspendPercentHeight + '%'}">
  </div>

  <div class="spending-bar__budget" [ngClass]="{ 'empty': !barPartsData.budget}" [ngStyle]="{'bottom': barPartsHeight.budget + '%'}">
    <span *ngIf="barPartsData.budget" class="sb-budget-label font-monospace">{{ barPartsData.budget | spendingTimelineFormat }}</span>
  </div>

  <div *ngFor="let segment of barPartsData.spending"
       class="spending-bar__segment"
       [ngClass]="{'status-ceg': cegFlagEnabled}"
       [class]="'spending-bar__segment--' + segment.id"
       [ngStyle]="{'height': barPartsHeight[segment.id] + '%'}">
    <span [ngClass]="{ 'sb-segment-label--hidden': barPartsHeight[segment.id] < minHeightForLabels }"
          class="sb-segment-label font-monospace">{{ segment.val | spendingTimelineFormat }}</span>
  </div>
</div>

<span class="bar-total font-monospace">{{ barPartsData.totalSpent | spendingTimelineFormat  }}</span>
