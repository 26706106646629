/**
 * BudgetStatus enum defined as per backend
 * Note:
 *   Reference === 'Read only'
 *   Hidden === 'Private'
 */
export enum BudgetStatus {
  Draft = 'Draft',
  Live = 'Live',
  Test = 'Test',
  Reference = 'Reference',
  Hidden = 'Hidden'
}
