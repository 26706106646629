<header mat-dialog-title>{{ context?.title }}</header>

<hierarchy-single-select
  [optionList]="context.selectItems"
  [selectedOption]="selectedOption"
  [selectConfig]="selectConfig"
  [allowGroupSelection]="context.allowGroupSelection"
  (selectOption)="selectOption($event)">
</hierarchy-single-select>

<div mat-dialog-actions>
  <button
    (click)="onCancel()"
    mat-button
    color="primary"
  >{{ context.cancelAction?.label || 'Cancel' }}</button>
  <button
    (click)="onSubmit()"
    mat-flat-button
    color="primary"
    [disabled]="!selectedOption"
  >{{ context.submitAction?.label || 'Ok' }}</button>
</div>
