<div>
  <page-header pageName="Expense types" [isAdminPage]="true">
    <fa-icon slot="icon" [icon]="['fas', 'coins']"></fa-icon>
    <button slot="custom-items"
            class="btn-add"
            type="button"
            title=""
            [disabled]="!expenseTypeList"
            (click)="addExpenseType()">Add</button>
  </page-header>
  <div class="expense-types">
    <div class="box-glCodeInn">
      <ul class="list-glCode">
        <li>Manage Custom Expense Types supported across all your budgets here</li>
        <li>Custom Expense Types are available when adding expenses manually or via import</li>
        <li>System Expense Types can not be edited or deleted</li>
      </ul>
      <div class="box-scrollTable">
        <table class="table-glCode table-glCodeExpenseType" *ngIf="expenseTypeList">
          <thead>
          <tr>
            <th>Expense Type</th>
            <th>Custom</th>
            <th>Options</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let type of expenseTypeList">
            <td>{{type.name}}</td>
            <td>{{type.is_custom? 'Custom' : ''}}</td>
            <td>
              <ul class="list-btnEditDel" *ngIf="type.is_custom">
                <li>
                  <button class="btn-tableEdit" *ngIf="type.is_custom" type="button" (click)="editExpenseType(type)"></button>
                </li>
                <li>
                  <button class="btn-tableDelete" *ngIf="type.is_custom" type="button" (click)="deleteExpenseType(type)"></button>
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
