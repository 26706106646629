import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BudgetAmountsSummaryComponent, BudgetAmountsSummaryData } from './budget-amounts-summary/budget-amounts-summary.component';

@Directive({
  selector: '[timelineChartTooltip]'
})
export class TimelineChartTooltipDirective implements OnDestroy {
  private overlayRef: OverlayRef;
  @Input('timelineChartTooltip') summaryData: BudgetAmountsSummaryData;
  @Input() positionInChart;
  @Input() totalColumns;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay,
  ) {
  }

  createOverlayRef() {
    const leftSide = this.totalColumns > 1 && this.positionInChart > (this.totalColumns / 2);
    const options: ConnectedPosition = {
      originX: leftSide ? 'start' : 'end',
      overlayX: leftSide ? 'end' : 'start',
      originY: 'center',
      overlayY: 'center',
      offsetX: 15 * (leftSide ? -1 : 1),
      panelClass: leftSide ? 'arrow-right' : 'arrow-left',
    };
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([options]);

    return this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseover')
  show() {
    this.overlayRef = this.createOverlayRef();
    const tooltipPortal = new ComponentPortal(BudgetAmountsSummaryComponent);
    const tooltipRef: ComponentRef<BudgetAmountsSummaryComponent> = this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.data = this.summaryData;
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
