<ng-template #SelectionTemplate>
  <div class="page-content">
    <mat-form-field appearance="outline" class="p-form-field-outline">
      <mat-label>Budget</mat-label>
      <mat-select (selectionChange)="handleBudgetRedirection($event.value)" placeholder="Select Budget">
        <!-- Custom trigger for displaying selected budget -->
        <mat-select-trigger>
          <span class="budget-name">{{ selectedBudget?.budget_name }}</span> - 
          <span class="budget-selection-length-value">[{{ selectedBudget?.usage_count }}]</span>
        </mat-select-trigger>
        
        <!-- Options for budget selection -->
        <mat-option *ngFor="let item of budgetData.groups" [value]="item">
          <span class="budget-name" [title]="item.budget_name">{{ item.budget_name }}</span> - 
          <span class="budget-selection-length-value">[{{ item.usage_count }}]</span>
        </mat-option>
      </mat-select> 
    </mat-form-field>
    
  </div>
</ng-template>