import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { addDaysToDate, getDiffInDays, getTodaysDate } from 'app/shared/utils/date.utils';
import { DAYS_TO_EXPIRE } from '../../team-page.utils';

enum UserActivityStatus {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
  Expired = 'expired'
}

@Component({
  selector: 'invitation-button',
  templateUrl: './invitation-button.component.html',
  styleUrls: ['./invitation-button.component.scss']
})
export class InvitationButtonComponent implements OnChanges {
  public UserActivityStatus = UserActivityStatus;
  public activityStatus = UserActivityStatus.Inactive;
  public expirationText = '';

  @Input() isActive = false;
  @Input() invitationDate: string;
  @Input() inviteAccepted = false;
  @Input() disabled = false;
  @Output() sendInvite = new EventEmitter();
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive || changes.invitationDate || changes.inviteAccepted) {
      this.updateActivityStatus();
    }
  }

  updateActivityStatus() {
    if (this.isActive && this.inviteAccepted) {
      this.activityStatus = UserActivityStatus.Active;
    } else {
      if (this.invitationDate) {
        const today = getTodaysDate();
        const invitationDate = parseDateString(this.invitationDate)
        const diffInDays = getDiffInDays(invitationDate, today);
        const expirationDate = addDaysToDate(invitationDate, DAYS_TO_EXPIRE)
          .toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
        const daysLeft = DAYS_TO_EXPIRE - diffInDays;
        if (daysLeft < 0) {
          this.activityStatus = UserActivityStatus.Expired;
          this.expirationText = `Expired on ${expirationDate}`;
        } else {
          this.activityStatus = UserActivityStatus.Pending;
          this.expirationText = `Expires in ${daysLeft} ${daysLeft > 1 ? 'days' : 'day'}`;
        }
      } else {
        this.activityStatus = UserActivityStatus.Inactive;
      }
    }
  }
}
