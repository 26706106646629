import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { Validations } from '../app.validations';
import { UtilityService } from '@shared/services/utility.service';
import { Configuration } from '../app.constants';
import { UploadDataService } from './upload_data.service';
import { BudgetDataService } from '../dashboard/budget-data/budget-data.service';
import { AppDataLoader } from '../app-data-loader.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { IMPORT_DATA_TYPE } from './upload_data.type';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetService, ExportBudgetParams } from 'app/shared/services/backend/budget.service';
import { EXPORT_TYPES, ExportDataService, REPORT_TYPE, ReportParams } from 'app/dashboard/export-data.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { Company } from 'app/shared/types/company.interface';
import { DialogContext } from 'app/shared/types/dialog-context.interface';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserPermissionLevel } from 'app/shared/utils/common.utils';
import { ImportingInfoDialogComponent } from './importing-info-dialog/importing-info-dialog.component';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { PendoEventName, PendoManagerService } from '@shared/services/pendo-manager.service';

export enum DataImportStatus {
  PENDING,
  PROCESSING,
  SUCCESS,
  FAILED
}

export enum DataExportStatus {
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export interface HookedReportData {
  status: DataExportStatus;
  presign_url: string;
  details: string[];
}

export interface AsyncOperationItem {
  executionArn: string;
  status: DataImportStatus | DataExportStatus;
  errorsList?: string[];
  name?: string;
  dataType?: IMPORT_DATA_TYPE;
}

export interface AsyncOperationStore {
  [key: string]: AsyncOperationItem;
}

export interface ImportingType {
  id: IMPORT_DATA_TYPE;
  permissionLevel: UserPermissionLevel;
  hidden?: boolean;
}

const CHECK_STATUS_DELAY = 2000;
const IMPORT_IN_PROGRESS = 'fileImportInProgress'
const EXPORT_REPORT_IN_PROGRESS = 'exportReportInProgress'

@Component({
  selector: 'app-root',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss'],
  providers: [AppDataLoader],
  animations: [
    trigger('fade', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-out', style({ opacity: 0 }))
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-out', style({ opacity: 1 })),
      ])
    ]),
  ]
})
export class ImportDataComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public selectedBudget: Budget;
  public isCEGMode: boolean;
  public selectedCompany: Company;

  public importingItem: AsyncOperationItem;
  public exportReportItem: AsyncOperationItem;
  public fileSubmitInProgress = false;
  public importingData: AsyncOperationStore;
  public exportReportData: AsyncOperationStore;

  private informationDialog: MatDialogRef<ImportingInfoDialogComponent>;
  protected customizeBudgetExport = false;
  public exportInProgress = false;
  public isObfuscated = false;
  public file: File;
  public acceptType = {
    [EXPORT_TYPES.XLSX]: '.xls, .xlsx',
    [EXPORT_TYPES.JSON]: '.json',
  }
  protected importTypeLabel = {
    [IMPORT_DATA_TYPE.CAMPAIGNS]: 'Campaigns',
    [IMPORT_DATA_TYPE.BUDGETS]: 'Budgets',
    [IMPORT_DATA_TYPE.EXPENSES]: 'Expenses',
    [IMPORT_DATA_TYPE.EXPENSE_GROUPS]: 'Expense Groups',
    [IMPORT_DATA_TYPE.METRICS]: 'Metrics',
    [IMPORT_DATA_TYPE.CAMPAIGN_TYPES]: 'Campaign Types',
    [IMPORT_DATA_TYPE.EXPENSE_GROUP_TYPES]: 'Expense Group Types',
    [IMPORT_DATA_TYPE.OBJECT_TYPES]: 'Campaign & Expense Group Types',
    [IMPORT_DATA_TYPE.EXPENSE_TYPES]: 'Expense Types',
    [IMPORT_DATA_TYPE.GL_CODES]: 'GL Codes',
  }
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('importBtnWrap') importBtnWrap: ElementRef;

  private importTypes: Record<IMPORT_DATA_TYPE, ImportingType> = {
    [IMPORT_DATA_TYPE.BUDGETS]: { id: IMPORT_DATA_TYPE.BUDGETS, permissionLevel: UserPermissionLevel.PartialWholeBudget },
    [IMPORT_DATA_TYPE.CAMPAIGNS]: { id: IMPORT_DATA_TYPE.CAMPAIGNS, permissionLevel: UserPermissionLevel.PartialSomeSegment },
    [IMPORT_DATA_TYPE.EXPENSE_GROUPS]: { id: IMPORT_DATA_TYPE.EXPENSE_GROUPS, permissionLevel: UserPermissionLevel.PartialSomeSegment },
    [IMPORT_DATA_TYPE.EXPENSES]: { id: IMPORT_DATA_TYPE.EXPENSES, permissionLevel: UserPermissionLevel.PartialSomeSegment },
    [IMPORT_DATA_TYPE.METRICS]: { id: IMPORT_DATA_TYPE.METRICS, permissionLevel: UserPermissionLevel.PartialSomeSegment },
    [IMPORT_DATA_TYPE.CAMPAIGN_TYPES]: { id: IMPORT_DATA_TYPE.CAMPAIGN_TYPES, permissionLevel: UserPermissionLevel.PartialWholeBudget },
    [IMPORT_DATA_TYPE.EXPENSE_GROUP_TYPES]: { id: IMPORT_DATA_TYPE.EXPENSE_GROUP_TYPES, permissionLevel: UserPermissionLevel.PartialWholeBudget },
    [IMPORT_DATA_TYPE.OBJECT_TYPES]: { id: IMPORT_DATA_TYPE.OBJECT_TYPES, permissionLevel: UserPermissionLevel.PartialWholeBudget },
    [IMPORT_DATA_TYPE.EXPENSE_TYPES]: { id: IMPORT_DATA_TYPE.EXPENSE_TYPES, permissionLevel: UserPermissionLevel.PartialWholeBudget },
    [IMPORT_DATA_TYPE.GL_CODES]: { id: IMPORT_DATA_TYPE.GL_CODES, permissionLevel: UserPermissionLevel.PartialWholeBudget },
  }
  public supportedImportTypes: ImportingType[] = [
    this.importTypes[IMPORT_DATA_TYPE.BUDGETS],
    this.importTypes[IMPORT_DATA_TYPE.CAMPAIGNS],
    this.importTypes[IMPORT_DATA_TYPE.EXPENSE_GROUPS],
    this.importTypes[IMPORT_DATA_TYPE.EXPENSES],
    this.importTypes[IMPORT_DATA_TYPE.METRICS],
    this.importTypes[IMPORT_DATA_TYPE.CAMPAIGN_TYPES],
    this.importTypes[IMPORT_DATA_TYPE.EXPENSE_GROUP_TYPES],
    this.importTypes[IMPORT_DATA_TYPE.OBJECT_TYPES],
    this.importTypes[IMPORT_DATA_TYPE.EXPENSE_TYPES],
    this.importTypes[IMPORT_DATA_TYPE.GL_CODES],
  ];
  public activeImportDataType: ImportingType;

  public EXPORT_TYPES = EXPORT_TYPES;
  public IMPORT_DATA_TYPE = IMPORT_DATA_TYPE;
  public exportedBudgetType = this.EXPORT_TYPES.JSON;
  public currentUserLevel = UserPermissionLevel.None;
  public permissionLevel = UserPermissionLevel;
  public readonly importDataKnowledgeLink = this.configuration.importDataKnowledgeLink;
  public readonly exportDataKnowledgeLink = this.configuration.exportDataKnowledgeLink;

  private static isValidItem = (storedItem): boolean => {
    return storedItem.status === DataImportStatus.FAILED ||
      (storedItem.status === DataImportStatus.PROCESSING && !!storedItem.executionArn);
  }

  constructor(
    public validations: Validations,
    public configuration: Configuration,
    public _utilityService: UtilityService,
    public uploadDataService: UploadDataService,
    private budgetDataService: BudgetDataService,
    private readonly companyDataService: CompanyDataService,
    private readonly appDataLoader: AppDataLoader,
    private readonly budgetService: BudgetService,
    private readonly utilityService: UtilityService,
    private userManager: UserManager,
    private router: Router,
    private readonly dialog: MatDialog,
    private readonly pendoManager: PendoManagerService,
    private readonly titleCasePipe: TitleCasePipe
  ) {
  }

  ngOnInit(): void {
    this.userManager.userPermissionLevel$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(level => {
      this.onPermissionsChanged(level);
    });

    this.companyDataService.selectedCompany$
      .pipe(
        filter(cmp => cmp != null),
        takeUntil(this.destroy$)
      ).subscribe(company => {
        this.selectedCompany = company;
        this.companyDataService.loadCompanyData(this.selectedCompany.id);
      });

    this.budgetDataService.selectedBudget$
      .pipe(takeUntil(this.destroy$))
      .subscribe(budget => this.onSelectedBudgetChanged(budget));

    this.appDataLoader.init();
  }

  private onPermissionsChanged(level: UserPermissionLevel): void {
    this.currentUserLevel = level;
    if (this.currentUserLevel === UserPermissionLevel.None) {
      this.router.navigate([this.configuration.ROUTING_CONSTANTS.HOME])
      return;
    }
    if (this.activeImportDataType?.permissionLevel > this.currentUserLevel) {
      this.activeImportDataType = null;
    }
    if (this.currentUserLevel < UserPermissionLevel.Full) {
      this.exportedBudgetType = this.EXPORT_TYPES.XLSX;
    }
  }

  public get isBudgetImport(): boolean {
    return this.activeImportDataType?.id === IMPORT_DATA_TYPE.BUDGETS;
  }

  public get dataImportProcessing(): boolean {
    return this.importingItem?.status === DataImportStatus.PROCESSING;
  }

  public get exportReportProcessing(): boolean {
    return this.exportReportItem?.status === DataExportStatus.RUNNING;
  }

  public get dataImportFailed(): boolean {
    return this.importingItem?.status === DataImportStatus.FAILED;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private get budgetKey(): string {
    return this.selectedCompany.id + '_' + this.selectedBudget.id;
  }

  private getBudgetRelatedAsyncOperation(store: AsyncOperationStore, storageKey: string): AsyncOperationItem {
    const storedItem = store[this.budgetKey] || null;
    if (storedItem && !ImportDataComponent.isValidItem(storedItem)) {
      delete store[this.budgetKey];
      this.updateStoredAsyncOperations(store, storageKey);
      return null;
    }
    return storedItem;
  }

  private updateStoredImportData(): void {
    this.updateStoredAsyncOperations(this.importingData, IMPORT_IN_PROGRESS);
  }

  private updateStoredExportReportData(): void {
    this.updateStoredAsyncOperations(this.exportReportData, EXPORT_REPORT_IN_PROGRESS);
  }

  private updateStoredAsyncOperations(store: AsyncOperationStore, storageKey: string): void {
    Object.keys(store).forEach((key) => {
      if (!ImportDataComponent.isValidItem(store[key])) {
        delete store[key];
      }
    });
    localStorage.setItem(storageKey, JSON.stringify(store));
  }

  private onSelectedBudgetChanged(budget: Budget) {
    this.selectedBudget = budget;
    this.uploadDataService.reloadImportingList$.next();

    if (budget != null) {
      this.importingData = LocalStorageService.getFromStorage<AsyncOperationStore>(IMPORT_IN_PROGRESS) || {};
      this.exportReportData = LocalStorageService.getFromStorage<AsyncOperationStore>(EXPORT_REPORT_IN_PROGRESS) || {};
      this.importingItem = this.getBudgetRelatedAsyncOperation(this.importingData, IMPORT_IN_PROGRESS);
      this.exportReportItem = this.getBudgetRelatedAsyncOperation(this.exportReportData, EXPORT_REPORT_IN_PROGRESS);
      if (this.importingItem) {
        this.activeImportDataType = this.supportedImportTypes.find(item => item.id === this.importingItem.dataType);
      }
      if (this.importingItem?.executionArn && this.dataImportProcessing) {
        this.checkImportingState();
      }
      if (this.exportReportItem?.executionArn && this.exportReportProcessing) {
        this.checkExportReportState();
      }

      this.budgetDataService.loadLightCampaigns(
        this.selectedCompany.id,
        this.selectedBudget.id,
        this.configuration.campaignStatusNames.active,
        error => this._utilityService.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.selectedCompany.id,
        this.selectedBudget.id,
        this.configuration.programStatusNames.active,
        error => this._utilityService.handleError(error)
      );

      this.isCEGMode = budget.new_campaigns_programs_structure;
      this.updateImportTypeVisibility();
    }
  }

  private updateImportTypeVisibility(): void {
    this.importTypes[IMPORT_DATA_TYPE.CAMPAIGN_TYPES].hidden = this.isCEGMode;
    this.importTypes[IMPORT_DATA_TYPE.EXPENSE_GROUP_TYPES].hidden = this.isCEGMode;
    this.importTypes[IMPORT_DATA_TYPE.OBJECT_TYPES].hidden = !this.isCEGMode;
  }

  private getFormData() {
    const budgetId = this.selectedBudget?.id;
    const companyId = this.selectedCompany?.id;
    const fd: FormData = new FormData();

    fd.append('file', this.file);
    fd.append('budget_id', String(budgetId));
    fd.append('company_id', String(companyId));

    return fd;
  }

  private onFileImportSuccess(importingItem: AsyncOperationItem): void {
    importingItem.status = DataImportStatus.SUCCESS;
    this.clearFile();

    this.updateStoredImportData();
    const message = `${importingItem.dataType} from "${importingItem.name}" created successfully!`;

    this._utilityService.showToast({ Title: '', Message: message, Type: 'success' });
    if (importingItem.dataType === IMPORT_DATA_TYPE.BUDGETS) {
      this.budgetDataService.loadCompanyBudgetList(
        this.selectedCompany.id,
        (error) => this.utilityService.handleError(error)
      );
    }
  }

  private onFileImportFailed(importingItem: AsyncOperationItem, err): void {
    importingItem.status = DataImportStatus.FAILED;
    const errorsRaw = err.error.detail || err.error;
    importingItem.errorsList = typeof errorsRaw === 'string' ? [ errorsRaw ] :
      Array.isArray(errorsRaw) ?
      this.processErrorsArray(errorsRaw) :
        this.processErrorsObject(errorsRaw);

    this.updateStoredImportData();

    const message = `Failed to process file "${importingItem.name}"`;
    this._utilityService.showToast({ Title: '', Message: message, Type: 'error', action: null });
  }

  private onExportReportSuccess(serverResponse: HookedReportData): void {
    this.clearExportReportItem();
    this.reportExportSuccess(serverResponse);
  }

  private onExportReportFailed(): void {
    this.clearExportReportItem();
    const message = `No reports generated`;
    this._utilityService.showToast({ Title: '', Message: message, Type: 'error' });
  }

  private clearExportReportItem(): void {
    this.exportReportItem = null;
    delete this.exportReportData[this.budgetKey];
    this.updateStoredExportReportData();
  }

  public fileInputClick() {
    if (this.dataImportProcessing) {
      return;
    }
    this.fileInput.nativeElement.click();
  }

  public checkFileType(e) {
    const file = e.target.files[0];
    if (!file || !file.name || !file.type) {
      this.clearFile();
      return;
    }
    const fileName = file.name;
    const arr = fileName.split('.');
    const ext = arr[arr.length - 1];

    const allowedExt = this.isBudgetImport ? EXPORT_TYPES.JSON : EXPORT_TYPES.XLSX
    const validFileExt = allowedExt === ext;
    const validFileName = this.configuration.FILENAME_REGEX.test(fileName);

    if (validFileExt && validFileName) {
      this.file = file;
      this.importingItem = {
        name: fileName,
        dataType: this.activeImportDataType.id,
        executionArn: null,
        status: DataImportStatus.PENDING,
        errorsList: [],
      };
      this.importingData[this.budgetKey] = this.importingItem;
      if (this.importBtnWrap) {
        this.importBtnWrap.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    } else {
      const validationMessage = validFileExt
        ? this.validations.ValiditionMessages.FILE_NAME_VALIDATION_ERROR
        : this.validations.ValiditionMessages.SELECT_VALID_FILE;
      this._utilityService.showToast({ Title: '', Message: validationMessage, Type: 'error' });
      this.clearFile();
    }
  }

  public fileSubmit(): void {
    const fd: FormData = this.getFormData();
    const request = this.isBudgetImport ? this.importBudget(fd) : this.importBudgetRelatedObjects(fd);
    request.subscribe(); // SHOULD NOT UNSUBSCRIBE!!!
  }

  private openImportInformationDialog(): void {
    this.informationDialog = this.dialog.open(ImportingInfoDialogComponent, {
      width: '480px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        title: 'Processing the Budget',
        content: 'Please, do not close this page, or you will break the import progress.',
        fileName: this.importingItem.name,
      },
    });
  }

  private importBudget(fd: FormData): Observable<any> {
    this.importingItem.status = DataImportStatus.PROCESSING;
    this.openImportInformationDialog();
    return this.uploadDataService.importBudget(fd).pipe(
      tap(() => {
        this.onFileImportSuccess(this.importingItem);
        this.pendoManager.track(PendoEventName.Import, {
          type: this.titleCasePipe.transform(this.activeImportDataType.id)
        });
      }),
      finalize(() => {
        this.informationDialog.close();
        this.informationDialog = null;
      }),
      catchError(
        err => {
          if (err.status === 400) {
            this.onFileImportFailed(this.importingItem, err);
          } else {
            if (!err.detail || !err.message) {
              err.message = 'Failed to upload a file';
            }
            this.utilityService.handleError(err);
          }
          return throwError(err);
        }
      ),
    )
  }

  private importBudgetRelatedObjects(fd: FormData): Observable<any> {
    this._utilityService.showLoading(true);
    this.fileSubmitInProgress = true;

    return this.uploadDataService.importBudgetRelatedObjects(fd, this.activeImportDataType.id).pipe(
      tap(
        executionArn => {
          this.importingItem.status = DataImportStatus.PROCESSING;
          this.importingItem.executionArn = executionArn;
          this.updateStoredImportData();
          this.checkImportingState();
          this.pendoManager.track(PendoEventName.Import, {
            type: this.titleCasePipe.transform(this.activeImportDataType.id)
          });
        }
      ),
      finalize(() => {
        this._utilityService.forceHideLoading();
        this.fileSubmitInProgress = false;
      }),
      catchError(
        err => {
          this.utilityService.handleError(err);
          return throwError(err);
        }
      ),
    );
  }

  private showIncompatibleFormatToast(): void {
    this.utilityService.handleError({ detail: 'Incompatible response format'});
  }

  private checkImportingState(): void {
    this.uploadDataService.checkArnStatus(this.importingItem.executionArn).pipe(
      takeUntil(this.uploadDataService.reloadImportingList$),
    ).subscribe({
      next: resp => {
        if (!resp.status) {
          this.clearFile(true);
          this.showIncompatibleFormatToast();
          return;
        }
        switch (resp.status) {
          case DataImportStatus.PENDING || DataImportStatus.PROCESSING:
            timer(CHECK_STATUS_DELAY).pipe(
              takeUntil(this.uploadDataService.reloadImportingList$),
            ).subscribe(() => {
              this.checkImportingState()
            })
            break;
          case DataImportStatus.SUCCESS:
            this.onFileImportSuccess(this.importingItem);
            break;
          case DataImportStatus.FAILED:
            this.onFileImportFailed(this.importingItem, resp);
            break;
        }
      },
      error: err => {
        this.clearFile(true);
        this.utilityService.handleError(err);
      }
    });
  }

  private checkExportReportState(): void {
    this.uploadDataService.checkExportReportStatus(this.exportReportItem.executionArn).pipe(
      takeUntil(this.uploadDataService.reloadImportingList$),
    ).subscribe({
      next: resp => {
        if (!resp.status) {
          this.showIncompatibleFormatToast();
          return;
        }
        switch (resp.status) {
          case DataExportStatus.RUNNING:
            timer(CHECK_STATUS_DELAY).pipe(
              takeUntil(this.uploadDataService.reloadImportingList$),
            ).subscribe(() => {
              this.checkExportReportState()
            })
            break;
          case DataExportStatus.SUCCEEDED:
            this.onExportReportSuccess(resp);
            break;
          default: // include DataExportStatus.FAILED
            this.onExportReportFailed();
        }
      },
      error: err => {
        this.clearExportReportItem();
        this.utilityService.handleError(err);
      }
    });
  }

  processErrorsArray(errorsArr: Record<string, string[]>[]): string[] {
    return errorsArr
      .reduce((finalList: string[], error: Record<string, string[]> | string) => {
        if (typeof error === 'object') {
          return [...finalList, ...this.processErrorsObject(error)]
        } else if (typeof error === 'string') {
          finalList.push(error);
        } else {
          console.error('Not supported error processing for type ' + typeof error);
        }
        return finalList;
      }, [])
  }

  processErrorsObject(errorsObject: Record<string, string[]>): string[] {
    return Object.keys(errorsObject)
      .sort()
      .reduce((list: string[], errorsKey: string) => {
        const errorsArray = errorsObject[errorsKey];
        if (Array.isArray(errorsArray)) {
          return [...list, ...errorsArray];
        } else {
          list.push(errorsArray)
          return list;
        }
      }, []);
  }

  clearFile(updateLocalStorage?: boolean) {
    this.file = null;
    this.importingItem = null;
    this.fileInput.nativeElement.value = null;
    if (updateLocalStorage) {
      delete this.importingData[this.budgetKey];
      this.updateStoredImportData();
    }
  }

  initBudgetExport(): void {
    this.customizeBudgetExport = true;
  }

  public handleExportBudget(propsToExclude?: string[]): void {
    const params: ExportBudgetParams = {
      file_format: this.exportedBudgetType,
      obfuscated: this.isObfuscated,
      save_to_s3: false,
    };
    if (propsToExclude?.length) {
      params.exclusions = propsToExclude.join(',');
    }
    this.handleDataExport(
      this.budgetService.exportBudget(this.selectedBudget.id, params),
      exportedData => this.downloadFile(exportedData as Blob, this.exportedBudgetType, 'budget'),
      error => this.utilityService.handleError(error)
    );
  }

  public handleExportReport(queryParams: Partial<ReportParams>) {
    this._utilityService.showLoading(true);

    this.uploadDataService.initReportExport(this.selectedBudget.id, queryParams).subscribe({
      next: executionArn => {
        this.exportReportItem = {
          executionArn: executionArn,
          status: DataImportStatus.PROCESSING,
        };
        this.exportReportData[this.budgetKey] = this.exportReportItem;
        this.updateStoredExportReportData();
        // setTimeout(() => this.checkExportReportState(), 3000)
        this.checkExportReportState();
        this.trackReportExport(queryParams.report_type);
      },
      complete: () => {
        this._utilityService.forceHideLoading();
      },
      error: error => this.utilityService.handleError(error)
    });
  }

  private trackReportExport(reportType: string): void {
    this.pendoManager.track(PendoEventName.Reports, {
      'Reallocation': reportType.includes(REPORT_TYPE.REALLOCATION),
      'Campaign Status': reportType.includes(REPORT_TYPE.CAMPAIGN),
      'Recaptured Budget': reportType.includes(REPORT_TYPE.RECAPTURED_BUDGET)
    });
  }

  public handleDataExport(
    request$: Observable<any>,
    onSuccessCb: (data: HookedReportData | BufferSource | Blob | string) => void,
    onErrorCb: (error: any) => void
  ) {
    if (!this.selectedBudget || !this.selectedCompany) {
      return;
    }
    this._utilityService.showLoading(true);
    this.exportInProgress = true;
    request$.pipe(
      finalize(() => {
        this.exportInProgress = false;
        this._utilityService.showLoading(false);
      })
    ).subscribe({
      next: data => onSuccessCb(data),
      error: error => onErrorCb(error)
    })
  }

  downloadFile(data: BufferSource | Blob | string, fileType: EXPORT_TYPES, fileNameLabel: string): void {
    const exportFileName = ExportDataService.getExportedFileName(
      this.selectedCompany.name,
      this.selectedBudget.name,
      fileNameLabel
    ) + `.${fileType}`;
    if (typeof data === 'string') {
      ExportDataService.downloadData(data, '', exportFileName);
    } else {
      ExportDataService.downloadDataAsFile(data, exportFileName, fileType);
    }
  }

  private reportExportSuccess(data: HookedReportData): void {
    const downloadFile = () => data.presign_url && this.downloadFile(data.presign_url, EXPORT_TYPES.XLSX, 'report');
    const dialogData: DialogContext = {
      title: 'Export details',
      content: data.details?.join('<br>') || '',
    };
    dialogData.submitAction = {
      label: 'Ok',
      handler: null,
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      autoFocus: false,
      restoreFocus: false,
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(() => downloadFile());
  }

  protected onBudgetSettingsClose(data: string[]): void {
    this.customizeBudgetExport = false;
    if (data) {
      this.handleExportBudget(data);
    }
  }
}
