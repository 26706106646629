import { Component, Input } from '@angular/core';

@Component({
  selector: 'heart-toggle',
  templateUrl: './heart-toggle.component.html',
  styleUrls: ['./heart-toggle.component.scss']
})
export class HeartToggleComponent {
  @Input() isActive: boolean;
  @Input() disabled: boolean;
}
