<ng-container *ngIf="isBudgetMode; else viewModes">
  <div
    *ngFor="let option of budgetModeSwitchOptions"
    class="switch-option budget-mode"
    (click)="setBudgetMode(option.mode)"
    [class.is-active]="viewMode === option.mode"
  >
    <fa-icon [icon]="option.icon"></fa-icon>
    {{ option.label }}
  </div>
</ng-container>
<ng-template #viewModes>
  <span class="label">Organize by</span>
  <div
    *ngFor="let option of viewModeSwitchOptions"
    class="switch-option center-mode"
    (click)="setViewMode(option.mode)"
    [class.is-active]="viewMode === option.mode"
    [class.disabled]="option.mode === ManageCegViewMode.Segments && dataMode === ManageCegDataMode.Performance"
  >
    <fa-icon [icon]="option.icon"></fa-icon>
    {{ option.label }}
  </div>
</ng-template>
