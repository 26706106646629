import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ExchangeRatesComponent } from './exchange-rates.component';
import { ExchangeRatesTableComponent } from './exchange-rates-table/exchange-rates-table.component';
import {
  AddNewCurrencyNotificationComponent
} from './modals/add-new-currency-notification/add-new-currency-notification.component';
import {
  CurrencyHasRelationsNotificationComponent
} from './modals/currency-has-relations-notification/currency-has-relations-notification.component';
import { ExchangeRatesService } from './exchange-rates.service';
import { SharedModule } from 'app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    ExchangeRatesComponent,
    ExchangeRatesTableComponent,
    AddNewCurrencyNotificationComponent,
    CurrencyHasRelationsNotificationComponent
  ],
  providers: [
    ExchangeRatesService
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FontAwesomeModule
  ],
  exports: [
    ExchangeRatesComponent
  ]
})
export class ExchangeRatesModule {}
