import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { columns } from './columns-data';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { AllocationsTableService } from '../allocations-table/allocations-table.service';
import { BudgetAllocationsTableColumn, BudgetAllocationsTableEvent } from './budget-allocations-table.type';
import {
  AllocationsTableDataItem,
  AllocationsTableEvent,
  AllocationsTableSubtitle,
  AllocationsTableSubtitleChangeEvent
} from '../allocations-table/allocations-table.type';
import { messages } from 'app/budget-object-details/messages';

@Component({
  selector: 'budget-allocations-table',
  templateUrl: './budget-allocations-table.component.html',
  styleUrls: ['./budget-allocations-table.component.scss']
})
export class BudgetAllocationsTableComponent implements OnChanges {
  columns = { ...columns };
  tableData;
  initialDataSet = false;
  tableTotals: AllocationsTableSubtitle[] = [];
  budgetTimeframesNames: string[];
  segmentlessAllocationTooltip = messages.SEGMENTLESS_ALLOCATION_TOOLTIP;
  @Input() isSegmentlessCampaign = false;
  @Input() isReadOnlyMode = false;
  @Input() budgetTimeframes: BudgetTimeframe[];
  @Input() objectAllocations: any[];
  @Input() expensesByTimeframes: { [id: number]: number };
  @Input() suppressedMode = false;
  @Input() totalAmount: number;
  @Input() currency: string;
  @Input() externalIntegrationType: { isExternal: boolean, integrationName: string };
  @Output() onChange = new EventEmitter<BudgetAllocationsTableEvent>();
  @Output() onTotalAllocatedChange = new EventEmitter<number>();

  constructor(
    private readonly allocationsTableService: AllocationsTableService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const neededDataProvided = this.budgetTimeframes && this.objectAllocations && this.expensesByTimeframes ;
    if (neededDataProvided && !this.initialDataSet) {
      this.createTableData();
      this.budgetTimeframesNames = this.allocationsTableService.createTimeframesNames(this.budgetTimeframes);
      this.initialDataSet = true;
    }

    if (changes.isReadOnlyMode || changes.isSegmentlessCampaign) {
      if (this.initialDataSet) {
        this.createTableData();
      }

      if (this.tableTotals.length) {
        this.calcTableTotals();
      }
    }

    if (
      this.objectAllocations && changes.objectAllocations ||
      changes.totalAmount
    ) {
      this.calcTableTotals();
    }
  }

  reset() {
    this.initialDataSet = false;
  }

  createTableData() {
    const dataProvider = (objectAllocation, budgetTimeframe) => {
      const allocatedValue = objectAllocation ? objectAllocation.source_amount : 0;
      const expensesValue = this.expensesByTimeframes[budgetTimeframe.id] || 0;
      const isLocked = budgetTimeframe.locked;
      return [
        this.createTableItem(allocatedValue, BudgetAllocationsTableColumn.Allocated, isLocked, this.suppressedMode, expensesValue),
        this.createTableItem(expensesValue, BudgetAllocationsTableColumn.Expenses, isLocked)
      ]
    };
    this.tableData = this.allocationsTableService.createTableData(
      this.budgetTimeframes, this.objectAllocations, dataProvider
    );
  }

  createTableItem(value: number, column: string, disabled: boolean, hidden = false, spentValue = 0): AllocationsTableDataItem {
    return {
      value,
      spentValue,
      column,
      disabled: disabled || this.isReadOnlyMode || this.isSegmentlessCampaign,
      tooltip: disabled ? 'Timeframe is closed.' :
        this.isSegmentlessCampaign && column === BudgetAllocationsTableColumn.Allocated && !this.suppressedMode ?
          this.segmentlessAllocationTooltip : '',
      hidden
    };
  }

  calcTableTotals() {
    const allocatedValue  = this.suppressedMode
      ? this.totalAmount
      : (this.objectAllocations || []).reduce((sum, alloc) => (sum + alloc.amount), 0);
    const expensesValue = Object.keys(this.expensesByTimeframes || {})
      .reduce((sum, id) => (sum + this.expensesByTimeframes[id]), 0);

    this.updateTableContextData(allocatedValue, expensesValue);
  }

  updateTableContextData(allocated, expenses) {
    this.tableTotals = [{
      data: {
        allocated: {
          value: allocated,
          isEditable: this.suppressedMode && !this.isReadOnlyMode && !this.isSegmentlessCampaign,
          spentValue: expenses,
          tooltip: this.isSegmentlessCampaign && this.suppressedMode ? this.segmentlessAllocationTooltip : null,
        },
        expenses: {
          value: expenses,
          isEditable: false
        }
      }
    }];
  }

  onTableDataChanged(event: AllocationsTableEvent) {
    if (event.data.column !== BudgetAllocationsTableColumn.Allocated) {
      return;
    }

    const row = this.tableData[event.rowName];
    const allocationData = row.data && row.data.find(item => item.column === BudgetAllocationsTableColumn.Allocated);
    const update: BudgetAllocationsTableEvent = {
      allocationId: row.allocationId,
      amount: allocationData ? allocationData.value : 0
    };
    this.onChange.emit(update);
    this.calcTableTotals();
  }

  onSubtitleValueChanged($event: AllocationsTableSubtitleChangeEvent) {
    if (!this.suppressedMode || !$event.hasOwnProperty(BudgetAllocationsTableColumn.Allocated)) {
      return;
    }
    const totalAllocatedValue = $event[BudgetAllocationsTableColumn.Allocated];
    this.onTotalAllocatedChange.emit(totalAllocatedValue);
  }
}
