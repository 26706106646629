import { Component, Input } from '@angular/core';
import { faChartPie, IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { MetricBreakdownListRow } from '../metric-breakdown-list/metric-breakdown-list.type';

@Component({
  selector: 'metric-breakdown',
  templateUrl: './metric-breakdown.component.html',
  styleUrls: ['./metric-breakdown.component.scss']
})
export class MetricBreakdownComponent {
  @Input() title = '';
  @Input() data: MetricBreakdownListRow;
  @Input() displayDecimal = false;
  @Input() currentDataIcon: IconDefinition;

  icon = faChartPie;
}
