import { AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { AmchartsService } from 'app/shared/services/amcharts.service';

export interface ProgressChartDataItem {
  name: string;
  color: string;
  value: number;
}

@Component({
  selector: 'progress-chart',
  styleUrls: ['./progress-chart.component.scss'],
  templateUrl: './progress-chart.component.html'
})
export class ProgressChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() radius: number;
  @Input() data: ProgressChartDataItem[] = [];
  @ViewChild('chartRef', { static: true }) chartRef: ElementRef;

  private strokeWidth = 14;
  private container;
  private chart: any;
  private am4core;
  private am4charts;

  constructor(
    private readonly amChartsService: AmchartsService,
    private readonly zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.amChartsService.importModules()
        .then(modules => {
          if (!modules) {
            return;
          }
          this.am4core = modules.am4core;
          this.am4charts = modules.am4charts;
          this.refreshChart();
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data && this.am4charts) {
      this.zone.runOutsideAngular(() => {
        this.refreshChart();
      })
    }
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  private refreshChart() {
    if (!this.data.length) {
      return;
    }
    this.disposeChart();
    this.initChart();
  }

  private createContainer(nativeEl) {
    const container = this.am4core.create(nativeEl, this.am4core.Container);
    container.width = this.am4core.percent(100);
    container.height = this.am4core.percent(100);
    container.align = 'center';

    return container;
  }

  private createChart(container) {
    const chart = container.createChild(this.am4charts.RadarChart);
    chart.svgContainer.autoResize = false;
    chart.radius = this.radius;
    chart.innerRadius = this.radius - this.strokeWidth;
    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.paddingLeft = 0;
    chart.paddingRight = 0;
    chart.width = this.am4core.percent(100);
    chart.height = this.am4core.percent(100);
    chart.zIndex = 1;

    return chart;
  }

  private getChartData(data: ProgressChartDataItem[]) {
    if (!Array.isArray(data)) {
      return [];
    }

    const dataValues = data.reduce((values, series) => ({
      ...values,
      [series.name]: series.value
    }), {});

    return [
      {
        category: 'progress',
        ...dataValues
      }
    ];
  }

  private createAxis(chart) {
    const categoryAxis = chart.yAxes.push(new this.am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.labels.template.disabled = true;

    const valueAxis = chart.xAxes.push(new this.am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
  }

  private createSeries(chart, data: ProgressChartDataItem[]) {
    data.forEach(({ name, color }) => {
      const series = chart.series.push(new this.am4charts.RadarColumnSeries());
      series.dataFields.valueX = name;
      series.dataFields.categoryY = 'category';
      series.stacked = true;
      series.columns.template.strokeWidth = 0;
      series.columns.template.radarColumn.cornerRadius = 20;
      series.columns.template.fill = color;
      series.showOnInit = false;
    });
  }

  private createContainerBg(container) {
    const circle = container.createChild(this.am4core.Circle);
    const circleStrokeWidth = this.strokeWidth - 3;
    const circleRadius = this.radius - Math.ceil(this.strokeWidth / 2);

    circle.fill = null;
    circle.radius = circleRadius;
    circle.fillOpacity = 0;
    circle.align = 'center';
    circle.valign = 'middle';
    circle.horizontalCenter = 'center';
    circle.verticalCenter = 'center';
    circle.stroke = this.am4core.color('rgba(102, 112, 133, 0.1)');
    circle.strokeWidth = circleStrokeWidth;
    circle.strokeOpacity = .75;
    circle.zIndex = 0;
  }

  private initChart() {
    this.container = this.createContainer(this.chartRef.nativeElement);
    this.createContainerBg(this.container);
    this.chart = this.createChart(this.container);
    this.chart.data = this.getChartData(this.data);
    this.createAxis(this.chart);
    this.createSeries(this.chart, this.data);
  }

  private disposeChart() {
    this.container = null;
    this.chart = null;
  }
}
