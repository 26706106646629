import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Output, ViewChild, EventEmitter } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ManagePageService } from 'app/manage-table/services/manage-page.service';
import { Configuration } from 'app/app.constants';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'object-name-input',
  templateUrl: './object-name-input.component.html',
  styleUrls: ['./object-name-input.component.scss']
})
export class ObjectNameInputComponent implements AfterViewInit, OnDestroy {
  @Input() objectType: string;
  @Input() icon: IconProp;
  @ViewChild('inputRef') inputRef: ElementRef;
  @Output() nameChanged = new EventEmitter<string>();
  public maxNameLength = this.configuration.MAX_TEXT_INPUT_LENGTH;
  public name = '';
  public validating = false;
  public placeholder = 'Enter name';
  public errorMessage: string = null;
  private errorMessageByType = {
    unique: 'This name is already used',
  };
  public isHovered: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    private readonly managePageService: ManagePageService,
    private readonly configuration: Configuration,
  ) { }

  ngAfterViewInit(): void {
    this.inputRef.nativeElement.focus();
  }

  public onBlur(): void {
    this.validating = true;
    if (this.name === '') {
      this.nameChanged.emit(this.name);
      return;
    }
    this.managePageService.validateUniqueObjectName(this.objectType, this.name).pipe(
      catchError(err => {
        if (Number(err.status) === HttpStatusCode.BadRequest) {
          // error with 400 means name is unique
          this.nameChanged.emit(this.name);
        } else {
          this.validating = false;
        }
        return throwError(err);
      }),
      takeUntil(this.destroy$)
    ).subscribe(() => {
        this.validating = false;
        this.errorMessage = this.errorMessageByType.unique;
      }
    );
  }

  public onFocus(): void {
    this.errorMessage = null;
  }

  public handleMouseEnter(): void {
    this.isHovered = true;
  }

  public handleMouseLeave(): void {
    this.isHovered = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
