<div class="tags-control" [class.readonly]="editForbidden" [class.empty]="tagsList == null || tagsList.length === 0" >
  <mat-form-field
    [appearance]="fieldAppearance || 'fill'"
    class="no-validate chip-list"
    [ngClass]="{
      'p-form-field-outline': fieldAppearance === 'outline',
      'no-label': fieldAppearance !== 'outline'
    }">
    <mat-label *ngIf="fieldAppearance === 'outline'">Tags</mat-label>
    <mat-chip-list #chipList aria-label="Tags input">
      <mat-chip
        *ngFor="let item of tagsList; let i = index"
        [selectable]="false"
        [removable]="!editForbidden"
        (removed)="onRemoveTagClick(item)">
        {{ item.name }}
        <fa-icon [icon]="['fas', 'xmark']" *ngIf="!editForbidden" matChipRemove>cancel</fa-icon>
      </mat-chip>
      <input
        *ngIf="!removeOnly"
        [readOnly]="editForbidden"
        [placeholder]="fieldAppearance === 'outline' ? '' : 'Tags'"
        #tagInput
        [matAutocompleteDisabled]="editForbidden"
        [formControl]="tagCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="false"
        [maxLength]="tagNameLength"
        (matChipInputTokenEnd)="onTagNameEntered($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTag.bind(this)" [panelWidth]="'auto'" (optionSelected)="onSelectTagFromList($event)">
      <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
        {{ tag.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <span class="hint">Maximum tag length is {{ tagNameLength }} characters</span>
</div>
