<svg width="94" height="40" viewBox="0 0 94 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path opacity="0.4" d="M38.1129 14.6823C35.9556 14.6824 32.7241 16.8477 30 19.8661C32.7347 22.8977 35.964 25.0497 38.1129 25.0495C41.4743 25.0498 43.8231 22.9169 43.8231 19.8658C43.8231 16.8151 41.4743 14.6823 38.1129 14.6823ZM38.693 22.0261C37.7975 22.0262 36.4517 21.1293 35.3121 19.8658C36.4474 18.6083 37.794 17.7061 38.693 17.7061C40.0935 17.7059 41.0723 18.5948 41.0723 19.8661C41.0721 21.1373 40.0935 22.0262 38.693 22.0261ZM47.0352 7.11698L40.8508 9.17821C40.6461 9.25442 40.4585 9.37029 40.2987 9.51917C40.0332 9.7848 39.884 10.145 39.884 10.5205C39.884 10.8961 40.0332 11.2563 40.2987 11.5219L44.1982 15.4214C47.1544 14.4213 51.8913 12.8195 54.0123 12.107C54.0721 12.0905 54.1278 12.075 54.1873 12.0591L49.9293 7.80102C49.2467 7.11975 47.9513 6.814 47.0352 7.11698ZM54.0158 27.6233C51.8892 26.9159 47.1422 25.3125 44.1937 24.3162L40.2899 28.22C40.0302 28.4869 39.8855 28.8451 39.8868 29.2176C39.8881 29.5901 40.0354 29.9472 40.297 30.2123C40.4561 30.3598 40.6426 30.4745 40.846 30.55L47.0259 32.6085C47.9412 32.9136 49.2372 32.6085 49.9198 31.9259L54.1554 27.6903C54.1599 27.6914 54.1643 27.6919 54.1686 27.6928L54.1906 27.6708C54.1311 27.6546 54.0759 27.6397 54.0158 27.6233Z" fill="#4C368D"/>
    <path d="M71.5767 20.3961C71.6751 20.2392 71.7274 20.0578 71.7277 19.8726C71.728 19.6874 71.6761 19.5058 71.5781 19.3487C70.5161 17.7071 69.4375 16.6285 68.399 15.59C64.6795 11.8704 60.1674 10.7476 54.1995 12.0565C54.1354 12.074 54.0765 12.0901 54.0126 12.1077C51.8917 12.8202 47.1548 14.422 44.1985 15.4221L44.6382 15.8619C45.1642 16.3879 45.5815 17.0123 45.8662 17.6996C46.1508 18.3868 46.2973 19.1234 46.2973 19.8673C46.2973 20.6112 46.1508 21.3478 45.8662 22.035C45.5815 22.7223 45.1642 23.3467 44.6382 23.8727L44.194 24.3169C47.1425 25.3132 51.8895 26.9167 54.0161 27.624C54.0791 27.6412 54.1363 27.6567 54.1991 27.6737C60.1779 28.9744 64.6872 27.8551 68.3908 24.1515C69.4409 23.1079 70.5084 22.0459 71.5767 20.3961ZM58.6573 21.87C58.2612 21.4739 57.9914 20.9693 57.8821 20.4198C57.7729 19.8704 57.8289 19.301 58.0433 18.7834C58.2577 18.2659 58.6207 17.8236 59.0864 17.5124C59.5522 17.2011 60.0998 17.035 60.6599 17.035C61.2201 17.035 61.7677 17.2011 62.2335 17.5123C62.6992 17.8235 63.0623 18.2658 63.2766 18.7833C63.491 19.3009 63.5471 19.8703 63.4379 20.4197C63.3286 20.9691 63.0589 21.4738 62.6628 21.8699C62.1315 22.4008 61.4111 22.699 60.66 22.699C59.909 22.699 59.1886 22.4009 58.6573 21.87ZM38.6933 17.7068C37.7943 17.7068 36.4478 18.6091 35.3125 19.8666C36.4521 21.13 37.7978 22.0269 38.6933 22.0268C40.0939 22.0269 41.0724 21.138 41.0727 19.8668C41.0727 18.5956 40.0938 17.7067 38.6933 17.7068Z" fill="#4C368D"/>
    <path d="M0 19.6758L28 19.6758" stroke="url(#paint0_linear)" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear" x1="28.3124" y1="20.1759" x2="-0.312356" y2="20.1759" gradientUnits="userSpaceOnUse">
      <stop stop-color="#4C368D"/>
      <stop offset="1" stop-color="#4C368D" stop-opacity="0"/>
    </linearGradient>
    <clipPath id="clip0">
      <rect width="94" height="40" fill="white"/>
    </clipPath>
  </defs>
</svg>
