import { Component, Output, EventEmitter, Input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'plan-budget-cue',
  templateUrl: './plan-budget-cue.component.html',
  styleUrls: ['./plan-budget-cue.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(10px)' }),
            animate('500ms ease-out',
              style({ opacity: 1, transform: 'translateY(0)' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, transform: 'translateY(0)', transformOrigin: '100% 0' }),
            animate('300ms ease-in',
              style({ opacity: 0, transform: 'scale(.75) translateY(-150px)' }))
          ]
        )
      ]
    )
  ]
})
export class PlanBudgetCueComponent {
  @Input() isVisible;
  @Output() hide = new EventEmitter();

  hideCue() {
    this.hide.emit();
  }
}
