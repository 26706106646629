import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { getRequestOptions, parseErrorBlob } from 'app/shared/utils/http-request.utils';
import { UserBudgetSummaryDO } from '../../types/user-budget-summary.interface';
import { Observable } from 'rxjs';
import { Budget } from '../../types/budget.interface';
import { BudgetEstimatedBusinessValue } from '../../types/budget-estimated-business-value.interface';
import { EXPORT_TYPES } from 'app/dashboard/export-data.service';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { ManagePageExportParams } from '@manage-ceg/types/manage-ceg-page.types';

export interface BudgetBaseline {
  id: string;
  name: string;
  date: string;
}

export interface ExportBudgetParams {
  file_format: string;
  obfuscated: boolean;
  save_to_s3: boolean;
  exclusions?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BudgetService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly configuration = inject(Configuration);
  private readonly dbExportUrl = this.configuration.db_export_service_url;
  private readonly xlsxExportReportUrl = this.configuration.export_report_url;
  private readonly apiPath = {
    newCEGStructureOptIn: 'new_campaigns_programs_structure/opt_in/',
    newCEGStructureOptOut: 'new_campaigns_programs_structure/opt_out/'
  }

  public createBudget(budgetData: Partial<Budget>): Observable<any> {
    return this.http.post<Budget>(
        `${this.apiV2Url}budget/`,
        JSON.stringify(budgetData)
    );
  }

  /**
   * This is a common v2 endpoint that returns budgets regardless of user permissions
   */
  public getBudgetsForCompany(companyId: number): Observable<Budget[]> {
    const params: any = { company: companyId };
    return this.http.get<Budget[]>(`${this.apiV2Url}budget/`, getRequestOptions(params));
  }

  /**
   * This is an endpoint that returns budgets considering user permissions
   */
  public getAvailableBudgetsForCompany(companyId: number): Observable<Budget[]> {
    return this.http.post<Budget[]>(`${this.apiV2Url}budget/available/`, {
      companies: [companyId]
    });
  }

  public getAvailableBudgetsForCompanies(companyIds: number[]): Observable<Budget[]> {
    return this.http.post<Budget[]>(`${this.apiV2Url}budget/available/`, {
      companies: companyIds
    });
  }

  public getUserBudgetSummary(budgetId: number): Observable<UserBudgetSummaryDO> {
    return this.http.get<UserBudgetSummaryDO>(`${this.apiV2Url}budget/${budgetId}/summary_for_user/`);
  }

  public getEstimatedBusinessValue(budgetId: number, params?: object): Observable<BudgetEstimatedBusinessValue> {
    return this.http.get<BudgetEstimatedBusinessValue>(
      `${this.apiV2Url}budget/${budgetId}/business_value/`,
      getRequestOptions(params)
    );
  }

  public updateBudget(id: number, data: object): Observable<Budget> {
    return this.http.patch<Budget>(
      `${this.apiV2Url}budget/${id}/`,
      JSON.stringify(data)
    );
  }

  public deleteBudget(budgetId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiV2Url}budget/${budgetId}/`);
  }

  public cloneBudgetSpecification(budgetId: number, data: { [key: string]: string | boolean }): Observable<{id: string, success: string}> {
    return this.http.post<{id: string, success: string}>(
      `${this.apiV2Url}budget/${budgetId}/clone/`,
      JSON.stringify(data)
    );
  }

  public exportBudget(budgetId: number, params: ExportBudgetParams): Observable<any> {
    const url = `${this.dbExportUrl}budget/${budgetId}/export`;
    const isXlsxExport = params.file_format === EXPORT_TYPES.XLSX;
    const options = isXlsxExport ? { responseType:  'blob' as 'json', params: params as any } : { params: params as any };
    return this.http.get(url, options).pipe(
      catchError(err => {
        if (isXlsxExport) {
          return parseErrorBlob(err);
        }
        throw err;
      })
    );
  }

  public getMinBudgetStartDate(budgets): Date {
    return this.getBudgetDate(budgets, 'budget_from', false);
  }

  public getMaxBudgetEndDate(budgets): Date {
    return this.getBudgetDate(budgets, 'budget_to', true);
  }

  private getBudgetDate(budgets, key, isGettingMaxDate): Date | null {
    if (!budgets || !budgets.length) {
      return null;
    }

    return budgets.reduce((store, budget) => {
      const date = new Date(budget[key]);
      if (!store || isGettingMaxDate ? date > store : date < store) {
        return date;
      }
      return store;
    }, null);
  }


  public getBaselines(budgetId: number): Observable<BudgetBaseline[]> {
    const url = `${this.xlsxExportReportUrl}baselines/${budgetId}`;
    return this.http.get<BudgetBaseline[]>(url);
  }

  public saveBaseline(budgetId: number, baselineName: string): Observable<{[key: string]: string}> {
    const url = `${this.dbExportUrl}budget/${budgetId}/export?file_format=json&obfuscated=false&save_to_s3=true&baseline_name=${baselineName}`;
    return this.http.get<{[key: string]: string}>(url);
  }

  public newCEGStructureOptIn(budgetId: number): Observable<Budget> {
    return this.http.patch<Budget>(`${this.apiV2Url}budget/${budgetId}/${this.apiPath.newCEGStructureOptIn}`, {});
  }

  public newCEGStructureOptOut(budgetId: number): Observable<Budget> {
    return this.http.patch<Budget>(`${this.apiV2Url}budget/${budgetId}/${this.apiPath.newCEGStructureOptOut}`, {});
  }

  public exportManagePage(budgetId: number, params: Partial<ManagePageExportParams>): Observable<any> {
    const url = `${this.apiV2Url}budget/${budgetId}/manage_page_export/`;
    const options = { responseType: 'blob' as 'json', observe: 'response' as 'body', params };

    return this.http.get<any>(url, options)
      .pipe(
        map(resp => ({ file: resp.body })),
        catchError(err => parseErrorBlob(err))
      );
  }
}
