import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, HostBinding } from '@angular/core';
import { ExpensePageDrawerService } from '@spending/services/expense-page-drawer.service';
import { ExtraExpenseEmailBody } from '@shared/types/expense.interface';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { PdfViewerService } from '@shared/services/pdf-viewer.service';

@Component({
  selector: 'invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
  @HostBinding('style.width') width: string;
  @HostBinding('class.tab-panel-hidden') isPanelTabHidden = true;

  private readonly destroy$ = new Subject<void>();
  public expenseEmailBody: ExtraExpenseEmailBody;

  constructor(
    private readonly expensePageDrawerService: ExpensePageDrawerService,
    private readonly pdfViewerService: PdfViewerService
  ) {}

  ngOnInit(): void {
    this.expensePageDrawerService.expenseEmailBody$
      .pipe(takeUntil(this.destroy$))
      .subscribe(emailBody => {
        this.expenseEmailBody = emailBody;
        this.isPanelTabHidden = !emailBody;
      });

    this.pdfViewerService.calculatedContainerWidth$
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe(width => this.width = `${width}px`);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
