import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CegRecordAction, ManageCegTableMenuAction, ManageCegTableRow } from '@manage-ceg/types/manage-ceg-page.types';
import { animate, group, style, transition, trigger } from '@angular/animations';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Component({
  selector: 'record-context-actions',
  templateUrl: './record-context-actions.component.html',
  styleUrls: ['./record-context-actions.component.scss'],
  animations: [
    trigger('animateScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.3)' }),
        group([
          animate('200ms 0s ease', style({ opacity: 1 })),
          animate('150ms 0s ease', style({ transform: 'scale(1)' })),
        ])
      ]),
    ])
  ]
})
export class RecordContextActionsComponent {
  private readonly actionsMap: Record<string, CegRecordAction> = {
    [ManageCegTableMenuAction.Duplicate]: {
      title: 'Duplicate',
      icon: ['fas', 'copy'],
      hidden: false,
      disabled: true,
      handler: () => this.onActionClick.emit(ManageCegTableMenuAction.Duplicate)
    },
    [ManageCegTableMenuAction.MoveTo]: {
      title: 'Move To',
      icon: ['fas', 'folder-open'],
      hidden: false,
      disabled: true,
      handler: () => this.onActionClick.emit(ManageCegTableMenuAction.MoveTo)
    },
    [ManageCegTableMenuAction.Close]: {
      title: 'Close',
      icon: ['fas', 'hourglass-end'],
      hidden: true,
      disabled: false,
      handler: () => this.onActionClick.emit(ManageCegTableMenuAction.Close)
    },
    [ManageCegTableMenuAction.Reopen]: {
      title: 'Reopen',
      icon: ['fas', 'hourglass-start'],
      hidden: true,
      disabled: false,
      handler: () => this.onActionClick.emit(ManageCegTableMenuAction.Reopen)
    },
    [ManageCegTableMenuAction.CopyId]: {
      title: 'Copy ID',
      icon: ['fas', 'fingerprint'],
      hidden: true,
      disabled: false,
      handler: () => this.onActionClick.emit(ManageCegTableMenuAction.CopyId)
    },
    [ManageCegTableMenuAction.Delete]: {
      title: 'Delete',
      icon: ['fas', 'trash'],
      hidden: false,
      disabled: true,
      customCssClass: 'action-danger',
      handler: () => this.onActionClick.emit(ManageCegTableMenuAction.Delete)
    }
  };
  public actions: CegRecordAction[] = [
    this.actionsMap[ManageCegTableMenuAction.Duplicate],
    this.actionsMap[ManageCegTableMenuAction.MoveTo],
    this.actionsMap[ManageCegTableMenuAction.Close],
    this.actionsMap[ManageCegTableMenuAction.Reopen],
    this.actionsMap[ManageCegTableMenuAction.CopyId],
    this.actionsMap[ManageCegTableMenuAction.Delete],
  ];
  @Output() onActionClick = new EventEmitter<ManageCegTableMenuAction>();
  @Output() closeActions = new EventEmitter<void>();
  @Input() set record(record: ManageCegTableRow) {
    this.updateItems(record);
  }

  private updateItems(record: ManageCegTableRow): void {
    this.actionsMap[ManageCegTableMenuAction.Reopen].hidden = !record.isClosed;
    this.actionsMap[ManageCegTableMenuAction.Close].hidden = record.isClosed || record.type === ManageTableRowType.Goal;
    this.actionsMap[ManageCegTableMenuAction.Delete].disabled = record.isClosed;
    this.actionsMap[ManageCegTableMenuAction.Duplicate].disabled = record.isClosed;
    this.actionsMap[ManageCegTableMenuAction.MoveTo].hidden = record.type === ManageTableRowType.Goal;
    this.updateMoveToAction(record.children, record.parentId as ManageTableRowType, record.isClosed);
    this.updateCopyAction(record.type, record.externalId);
  }

  private updateMoveToAction(recordChildren, parentId: ManageTableRowType, isClosed: boolean): void {
    let moveToIsDisabled = isClosed;
    if (!isClosed) {
      const hasChildCampaign = (recordChildren || []).some(child => child.type === ManageTableRowType.Campaign);
      if (hasChildCampaign) {
        moveToIsDisabled = hasChildCampaign && !parentId?.includes(ManageTableRowType.Goal.toLowerCase());
      }
    }
    this.actionsMap[ManageCegTableMenuAction.MoveTo].disabled = moveToIsDisabled;
  }

  private updateCopyAction(recordType: ManageTableRowType, externalId: string): void {
    const allowedTypes = [ManageTableRowType.Campaign, ManageTableRowType.ExpenseGroup];
    this.actionsMap[ManageCegTableMenuAction.CopyId].hidden = !allowedTypes.includes(recordType);
    this.actionsMap[ManageCegTableMenuAction.CopyId].disabled = !externalId;
  }
}
