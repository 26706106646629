import { ExpenseTableColumn, ExpenseTableColumnItem } from '../types/expense-page.type';

export const columnItemsMap: Record<ExpenseTableColumn, ExpenseTableColumnItem> = {
  [ExpenseTableColumn.NAME]: {
    id: ExpenseTableColumn.NAME,
    title: 'Expense Name',
    visible: true,
    draggable: false,
    align: 'flex-start'
  },
  [ExpenseTableColumn.PLANNED]: {
    id: ExpenseTableColumn.PLANNED,
    title: 'Planned',
    visible: true,
    draggable: true,
    align: 'flex-end'
  },
  [ExpenseTableColumn.ACTUAL]: {
    id: ExpenseTableColumn.ACTUAL,
    title: 'Actual',
    visible: true,
    draggable: true,
    align: 'flex-end'
  },
  [ExpenseTableColumn.DIFFERENCE]: {
    id: ExpenseTableColumn.DIFFERENCE,
    title: 'Difference',
    visible: false,
    draggable: true,
    align: 'flex-end'
  },
  [ExpenseTableColumn.STATUS]: {
    id: ExpenseTableColumn.STATUS,
    title: 'Status',
    visible: true,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.TIMEFRAME]: {
    id: ExpenseTableColumn.TIMEFRAME,
    title: 'Timeframe',
    visible: true,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.CURRENCY]: {
    id: ExpenseTableColumn.CURRENCY,
    title: 'Currency',
    visible: false,
    draggable: true,
    align: 'flex-start'
  },
  [ExpenseTableColumn.SEGMENT]: {
    id: ExpenseTableColumn.SEGMENT,
    title: 'Segment',
    visible: true,
    draggable: true,
    align: 'flex-start'
  },
  [ExpenseTableColumn.PARENT]: {
    id: ExpenseTableColumn.PARENT,
    title: 'Parent',
    visible: true,
    draggable: true,
    align: 'flex-start'
  },
  [ExpenseTableColumn.SOURCE]: {
    id: ExpenseTableColumn.SOURCE,
    title: 'Source',
    visible: false,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.GL_CODE]: {
    id: ExpenseTableColumn.GL_CODE,
    title: 'GL Code',
    visible: true,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.VENDOR]: {
    id: ExpenseTableColumn.VENDOR,
    title: 'Vendor',
    visible: true,
    draggable: true,
    align: 'flex-start'
  },
  [ExpenseTableColumn.CREATED_DATE]: {
    id: ExpenseTableColumn.CREATED_DATE,
    title: 'Created Date',
    visible: false,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.UPDATED_DATE]: {
    id: ExpenseTableColumn.UPDATED_DATE,
    title: 'Updated Date',
    visible: false,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.DELIVERY_DATE]: {
    id: ExpenseTableColumn.DELIVERY_DATE,
    title: 'Delivery Date',
    visible: false,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.ATTACHMENT]: {
    id: ExpenseTableColumn.ATTACHMENT,
    title: 'Attachment',
    visible: false,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.TYPE]: {
    id: ExpenseTableColumn.TYPE,
    title: 'Expense Type',
    visible: false,
    draggable: true,
    align: 'flex-start'
  },
  [ExpenseTableColumn.OWNER]: {
    id: ExpenseTableColumn.OWNER,
    title: 'Owner',
    visible: false,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.RELATED]: {
    id: ExpenseTableColumn.RELATED,
    title: 'Related',
    visible: true,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.INVOICE]: {
    id: ExpenseTableColumn.INVOICE,
    title: 'Invoice Number',
    visible: false,
    draggable: true,
    align: 'center'
  },
  [ExpenseTableColumn.PO_NUMBER]: {
    id: ExpenseTableColumn.PO_NUMBER,
    title: 'PO Number',
    visible: false,
    draggable: true,
    align: 'center'
  },
};
