import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

export const utClassesByType = {
  [ManageTableRowType.Goal]: 'ut-goal',
  [ManageTableRowType.Campaign]: 'ut-campaign',
  [ManageTableRowType.ExpenseGroup]: 'ut-program',
  [ManageTableRowType.Segment]: 'ut-segment',
  [ManageTableRowType.SegmentGroup]: 'ut-segment-group',
};

export const utClasses = {
  objectControls: 'ut-object-controls',
  performance: 'ut-performance',
  timeframe: 'ut-timeframe',
  fyTotal: 'ut-fy-total',
  closedExpenses: 'ut-closed-expenses',
  committedExpenses: 'ut-committed-expenses',
  plannedExpenses: 'ut-planned-expenses',
  totalAvailable: 'ut-total-available',
  totalRemaining: 'ut-total-remaining',
  totalExpenses: 'ut-total-expenses',
  allocated: 'ut-allocated',
  allocatedToChildren: 'ut-allocated-to-children',
  grandTotal: 'ut-grand-total'
};
