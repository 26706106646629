<section class="widget-section" [class.no-widgets-visible]="noWidgetsVisible">
  <h6 class="section-title" *ngIf="displayTitle">{{ title }}</h6>
  <div class="section-grid">
    <ng-container *ngFor="let widget of widgets">
      <div [ngSwitch]="widget.type" [ngClass]="widgetClassMap[widget.type]">
        <ng-container *ngSwitchCase="widgetType.OPEN_TASKS">
          <tasks-widget [config]="widget"></tasks-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.OVERDUE_EXPENSES">
          <overdue-expenses-widget [config]="widget"></overdue-expenses-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.UPDATE_METRICS">
          <update-metrics-widget [config]="widget"></update-metrics-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.BUDGET_MINUS_EXPENSES">
          <budget-minus-expenses-widget [config]="widget"></budget-minus-expenses-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.CAMPAIGNS">
          <campaigns-summary-widget [config]="widget"></campaigns-summary-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.HISTORY">
          <history-widget [config]="widget"></history-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.METRICS_PROGRESS">
          <metrics-progress-widget [config]="widget"></metrics-progress-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.OPEN_EXPENSES">
          <open-expenses-widget [config]="widget"></open-expenses-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.SPEND_PROGRESS">
          <spend-progress-widget [config]="widget"></spend-progress-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.CAMPAIGN_SPENT">
          <campaign-spent-widget [config]="widget"></campaign-spent-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.BUDGET_SPENT">
          <budget-spent-widget [config]="widget"></budget-spent-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.ESTIMATED_BUSINESS_VALUE">
          <estimated-business-value-widget [config]="widget"></estimated-business-value-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgetType.ASSIGN_SEGMENTS_GOOGLE_CAMPAIGNS">
          <assign-campaigns-segments-widget [config]="widget"></assign-campaigns-segments-widget>
        </ng-container>
      </div>
    </ng-container>
  </div>
</section>
