<tooltip-container class="arrow-left">
  <p class="shared-rule-name">
    <icon-shared [state]="iconState"></icon-shared>
    {{ tooltipContext.name || '' }}
  </p>
  <ul>
    <li *ngFor="let item of tooltipContext.segments">
      <p class="segment">
        {{ item.name || item.id }}
      </p>
      <span class="shared-value">{{ item.cost | number: '1.2-2' }}%</span>
    </li>
  </ul>
</tooltip-container>
