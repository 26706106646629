import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { UpdateMetricsDataItem } from '../../types/update-metrics-data-item.interface';
import { UpdateMetricsChangeEvent } from '../../types/update-metrics-change-event.interface';
import { PlanPageViewMode } from 'app/shared/enums/plan-page-view-mode.enum';


@Component({
  selector: 'update-metrics-table',
  styleUrls: ['./update-metrics-table.component.scss'],
  templateUrl: './update-metrics-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateMetricsTableComponent {
  @Input() data: UpdateMetricsDataItem[];
  @Input() currencySymbol = '';
  @Input() isReadOnly = true;
  @Output() onCurrentValueChange = new EventEmitter<UpdateMetricsChangeEvent>();

  private routeActionByMetricType = {
    [HistoryObjectLogTypeNames.campaign]: (id: number) => this.appRoutingService.openCampaignMetricDetails(id),
    [HistoryObjectLogTypeNames.program]: (id: number) => this.appRoutingService.openProgramMetricDetails(id),
    [HistoryObjectLogTypeNames.goal]: (id: number) => this.appRoutingService.openGoalMetricDetails(id),
  };

  constructor(
    private readonly appRoutingService: AppRoutingService,
    private readonly configuration: Configuration,
    private readonly filterManagementService: FilterManagementService,
  ) {}

  public trackFn(index, item: UpdateMetricsDataItem) {
    return item.name;
  }

  public navigateByMetricType(item: UpdateMetricsDataItem) {
    const ids = item.campaignIds || [];
    this.filterManagementService.updateCurrentFilterSet({ [FilterName.Campaigns]: ids });
    this.appRoutingService.openPlanDetail([ this.configuration.OBJECT_TYPES.campaign ], {}, PlanPageViewMode.CARD);
  }

  public navigateByMetricMapping($event: { metricType: string; objectId: number }) {
    const { metricType, objectId } = $event;
    const action = this.routeActionByMetricType[metricType];
    if (typeof action === 'function') {
      action(objectId);
    }
  }
}
