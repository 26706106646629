import { Directive, ElementRef, HostListener, Inject, Input, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[floatingTooltip]'
})
export class FloatingTooltipDirective implements OnDestroy {
  defaultCss = {
    position: 'fixed',
    zIndex: 1000,
    opacity: 0,
    minWidth: '80px',
    maxWidth: '160px',
    transform: 'translateX(10px)',
    transition: 'opacity .6s ease, transform .4s ease',
  }
  mouseOut$ = new Subject<void>();
  tooltipRef: HTMLElement;
  timerId;

  @Input() cssClasses = ['dark-theme-tooltip', 'simple'];
  @Input() showDelay = 600;
  @Input('floatingTooltip') text: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) { }

  @HostListener('mouseenter')
  onMouseenter() {
    if (!this.text) {
      return;
    }
    this.initListener();
    this.timerId = setTimeout(() => {
      this.renderer.setStyle(this.tooltipRef, 'opacity', 1);
      this.renderer.setStyle(this.tooltipRef, 'transform', 'translateX(0)');
    }, this.showDelay);
  }

  initListener() {
    this.tooltipRef = this.createTooltip();
    this.zone.runOutsideAngular(() => {
      fromEvent(this.el.nativeElement, 'mousemove').pipe(
        takeUntil(this.mouseOut$),
      ).subscribe((e: MouseEvent) => {
        this.renderer.setStyle(this.tooltipRef, 'top', e.clientY + 'px');
        this.renderer.setStyle(this.tooltipRef, 'left', e.clientX + 'px');
      })
    })
  }

  createTooltip(): HTMLElement {
    const tooltip = this.renderer.createElement('div');
    const textNode = this.renderer.createText(this.text);
    this.renderer.appendChild(tooltip, textNode);
    this.setStyles(tooltip);
    this.applyCssClasses(tooltip, this.cssClasses);
    this.renderer.appendChild(this.document.body, tooltip);
    return tooltip;
  }

  applyCssClasses(tooltip: HTMLElement, classes: string[]) {
    classes.forEach(className => {
      this.renderer.addClass(tooltip, className);
    })
  }

  setStyles(tooltip: HTMLElement) {
    Object.entries(this.defaultCss).forEach(([name, value]) => {
      this.renderer.setStyle(tooltip, name, value);
    })
  }

  @HostListener('mouseleave')
  onMouseleave() {
    if (this.text) {
      this.destroyListener();
    }
  }

  destroyListener() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.mouseOut$.next();
    if (this.tooltipRef) {
      this.renderer.removeChild(this.document.body, this.tooltipRef);
    }
  }

  ngOnDestroy() {
    this.destroyListener();
  }
}
