import { Injectable } from '@angular/core';
import { ExternalMetricTypesService } from '../services/external-metric-types-service';
import { ExternalMetrics } from '../types/external-metrics.types';
import { Observable } from 'rxjs';
import { SalesforceApiService } from './salesforce-api.service';
import { SalesforceDataService } from './salesforce-data.service';

@Injectable({
  providedIn: 'root'
})
export class SalesforceMetricTypesService extends ExternalMetricTypesService {
  externalMetricTypes = null;
  defaultMapping = {
    'NumberOfLeads': 'Leads',
    'NumberOfConvertedLeads': 'Prospects',
    'NumberOfOpportunities': 'Opportunities',
    'NumberOfWonOpportunties': 'Deals',
    'AmountWonOpportunties': 'Revenue'
  };

  constructor(
    private readonly sfApiService: SalesforceApiService,
    private readonly sfDataService: SalesforceDataService
  ) {
    super(sfDataService);
  }

  getMetricTypes(companyId: number, integrationId: string): Observable<ExternalMetrics> {
    return this.sfApiService.getExternalMetrics(companyId, integrationId);
  }
}
