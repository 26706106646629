import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { SharedIconState } from 'app/shared/components/icons/icon-shared/shared.type';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { Configuration } from 'app/app.constants';
import { HierarchySelectConfig, HierarchySelectItem } from '../../shared/components/hierarchy-select/hierarchy-select.types';
import { ExternalIntegrationObjectTypes } from 'app/shared/constants/external-integration-object-types';

@Component({
  selector: 'child-objects-list',
  templateUrl: './child-objects-list.component.html',
  styleUrls: ['./child-objects-list.component.scss']
})
export class ChildObjectsListComponent implements OnChanges {
  @Input() childObjects = [];
  @Input() parentObject: any;
  @Input() segmentList: BudgetSegmentAccess[] = [];
  @Input() segmentNameById: {[key: number]: string} = {};
  @Input() sharedCostRuleList: SharedCostRule[] = [];
  @Input() isEditingAllowed = true;
  @Input() isPowerUser = true;
  @Input() isCompanyCard: boolean;
  @Input() segmentMenuList: HierarchySelectItem[] = [];

  @Output() onItemClick = new EventEmitter();
  @Output() onChangeObjectSplitRule = new EventEmitter<{ item: any, id: number, object: any }>();

  public iconByObjectType = {
    [this.configuration.OBJECT_TYPES.campaign]: ['far', 'rocket-launch'],
    [this.configuration.OBJECT_TYPES.program]: ['fas', 'briefcase'],
    [this.configuration.OBJECT_TYPES.expense]: ['fas', 'coins'],
  };
  SharedIconState = SharedIconState;
  processedChildObjects = [];
  public menuConfig: HierarchySelectConfig = {
    fieldLabel: '',
    withSearch: true,
    emptyValueLabel: 'All Segments',
    searchPlaceholder: 'Search Segments',
    allGroups: true,
    allPlural: 'Segments'
  };
  public ExternalIntegrationObjectTypes = ExternalIntegrationObjectTypes;

  constructor(
    private readonly objectAccessManager: ObjectAccessManagerService,
    private readonly configuration: Configuration,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('childObjects' in changes) {
      this.processChildObjects();
    }
  }

  handleItemClick(event, item) {
    if (item.context.disabled) {
      return;
    }

    const childObject = this.childObjects.find(
      el => el[item.context.idKey] === item.id
    );
    if (childObject) {
      this.onItemClick.emit(childObject)
    }
  }

  processChildObjects() {
    this.processedChildObjects = this.childObjects.map(
      el => {
        const sharedRuleID = el.split_rule || el.expense_split_rule_id || null;
        const segmentId = el.company_budget_segment1;
        const objectAccess = this.isPowerUser || this.objectAccessManager.hasAccessBySegmentData(
          {
            split_rule: sharedRuleID,
            company_budget_segment1: segmentId
          },
          this.segmentList,
          this.sharedCostRuleList
        );

        return ({
          id: el.id || el.expense_id,
          name: el.name || el.expense_name,
          icon: this.iconByObjectType[el.objectType],
          context: {
            idKey: el.id ? 'id' : 'expense_id',
            splitRule: sharedRuleID,
            budgetSegmentId: segmentId,
            disabled: !objectAccess
          },
          rawData: el,
          campaign_type: el.campaign_type || el.object_type
        });
      }
    )
  }

  changeObjectSplitRule(item, object) {
    this.onChangeObjectSplitRule.emit({ item, id: object.id || object.expense_id, object: object.rawData })
  }
}
