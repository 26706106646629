import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ExternalMetricProviderApiService } from '../types/external-metric-provider-api.service';
import { ExternalCampaign, ExternalCampaignData } from '../types/external-campaign.interface';
import { ExternalMetric, ExternalMetrics } from '../types/external-metrics.types';
import { CurrentMappingTargetsRequest, CurrentMappingTargetsResponse, HubspotDataService } from './hubspot-data.service';
import { ExternalMetricTypesMapping } from '../types/external-metric-types-mapping.interface';
import { HubspotMetricTypesService } from './hubspot-metric-types.service';
import {
  HubspotFetchDataAction,
  hubspotMappingTypeNameByCampaignType,
  HubspotMetricTypeApiField
} from './hubspot.types';
import { HubspotApiCampaignObject, HubspotApiEmailDataObject, HubspotApiFormDataObject, HubspotApiMetricValues } from './hubspot-api.types';
import { Budget } from 'app/shared/types/budget.interface';
import { CompareService } from 'app/shared/services/compare.service';
import { CompareDirection } from 'app/shared/types/compare-direction.type';
import { CompareType } from 'app/shared/types/compare-type.type';
import { HubspotApiDataMapper } from './hubspot-api-data-mapper';
import { HubspotCampaignMapping, HubspotMappingTypeName } from './hubspot-campaign-mapping.interface';
import { CampaignBrief, MetricListItem, PlannuhCampaignMetric } from '../types/metric-integration';
import { Campaign } from 'app/shared/types/campaign.interface';

@Injectable()
export class HubspotApiService extends ExternalMetricProviderApiService<HubspotCampaignMapping> {
  private APIDateFormat = 'yyyyMMdd';

  private static getExternalCampaignMetricData(
    metricValues: HubspotApiMetricValues,
    metrics: ExternalMetric[],
    metricTypesMapping: ExternalMetricTypesMapping,
    plannuhCampaignMetrics: PlannuhCampaignMetric[]
  ): MetricListItem[] {
    return plannuhCampaignMetrics.reduce(
      (metricItems, plannuhMetric) => {
        const mappedValue =
          metrics
            .filter(m => metricTypesMapping[m.name].includes(plannuhMetric.metricId))
            .map(m => HubspotMetricTypeApiField[m.name])
            .map(key => metricValues[key] || 0)
            .reduce((sum, value) => sum + value, 0);

        const metricItem: MetricListItem = {
          name: plannuhMetric.name,
          productName: plannuhMetric.productName,
          order: plannuhMetric.order,
          productOrder: plannuhMetric.productOrder,
          value: mappedValue
        };
        return [...metricItems, metricItem];
      },
      [] as MetricListItem[]
    );
  }

  constructor(
    private readonly hubspotDataService: HubspotDataService,
    private readonly hubspotMetricTypesService: HubspotMetricTypesService,
    private readonly datePipe: DatePipe,
  ) {
    super();
  }

  private get comparer() {
    return CompareService.comparerByType[CompareType.Alphabetical];
  }

  private createCampaignsTree(
    campaignId: number,
    rawCampaigns: HubspotApiCampaignObject[],
    externalMetrics: ExternalMetric[],
    metricTypesMapping: ExternalMetricTypesMapping,
    plannuhCampaignMetrics: PlannuhCampaignMetric[],
    mappingTargets: { [groupType in HubspotMappingTypeName]?: CurrentMappingTargetsResponse },
    allCampaigns: Campaign[]
  ): ExternalCampaign[] {
    return rawCampaigns.map(campaign => {
      const getExistingMapping = (hsCampaignType: string, extCampaignId: string): CampaignBrief => {
        const mappingTypeName = hubspotMappingTypeNameByCampaignType[hsCampaignType];
        const mappingTarget = mappingTypeName && mappingTargets?.[mappingTypeName]?.[extCampaignId];
        const plannuhCampaign = mappingTarget && allCampaigns.find(c => c.id === mappingTarget && c.id !== campaignId);
        return plannuhCampaign ? { id: plannuhCampaign.id, name: plannuhCampaign.name } : null;
      };
      const extCampaign = HubspotApiDataMapper.mapDataObjectToCampaign(campaign);
      return {
        ...extCampaign,
        metrics: HubspotApiService.getExternalCampaignMetricData(
          campaign.metricValues,
          externalMetrics,
          metricTypesMapping,
          plannuhCampaignMetrics
        ),
        existingMapping: getExistingMapping(extCampaign.type, extCampaign.id)
      };
    });
  }

  private getEmailObjects(data: { objects: HubspotApiEmailDataObject[] }, mapping: HubspotCampaignMapping): HubspotApiEmailDataObject[] {
    const mappedObjects = mapping?.emails || [];

    return data.objects
      .filter(email => !(email.archived && !mappedObjects[email.id]));
  }

  private getUtmCampaignsParams(budget: Budget) {
    const start = this.datePipe.transform(budget.budget_from, this.APIDateFormat);
    const end = this.datePipe.transform(budget.budget_to, this.APIDateFormat);

    return {
      start,
      end
    }
  }

  public getRawExternalCampaigns(companyId, integrationId, dateFrom): Observable<any> {
    // TODO: implement for Hubspot
    return of([])
  }

  public getExternalCampaigns(params): Observable<ExternalCampaignData> {
    const { companyId, campaignId, plannuhCampaignMetrics, budget, mapping, allCampaigns, integrationId } = params;
    const emails$ = this.hubspotDataService.fetchData<HubspotApiEmailDataObject>(companyId, integrationId, HubspotFetchDataAction.GetEmailCampaigns);
    const forms$ = this.hubspotDataService.fetchData<HubspotApiFormDataObject>(companyId, integrationId, HubspotFetchDataAction.GetForms);
    const utmCampaigns$ = this.hubspotDataService.fetchData<HubspotApiFormDataObject>(
      companyId,
      integrationId,
      HubspotFetchDataAction.GetUtmCampaigns,
      this.getUtmCampaignsParams(budget)
    );
    const typesMapping$ = this.hubspotMetricTypesService.getExternalMetricTypesMapping(companyId);
    const metricTypes$ = this.hubspotMetricTypesService.getMetricTypes(companyId);
    let relevantPlannuhMetrics = [];

    return forkJoin([
      emails$,
      forms$,
      utmCampaigns$,
      typesMapping$.pipe(
        tap(typeMappings => {
          const mappedPlannuhMetricIds = Object.values(typeMappings);
          relevantPlannuhMetrics = (plannuhCampaignMetrics || []).filter(plMetric => mappedPlannuhMetricIds.some(idsArray => idsArray.includes(plMetric.metricId)));
        })
      ),
      metricTypes$
    ])
      .pipe(
        map(([emailsResponse, formsResponse, utmResponse, typeMappings, metricTypes]) => {
          const emails = this.getEmailObjects(emailsResponse, mapping);
          const forms = formsResponse.objects;
          const utmCampaigns = utmResponse.objects;
          const campaigns: HubspotApiCampaignObject[] = [ ...emails, ...forms, ...utmCampaigns ]
            .sort(
            (itemA, itemB) => (
              this.comparer(itemA.name, itemB.name, CompareDirection.Asc)
            )
          );

          return {
            campaigns,
            typeMappings,
            metricTypes
          };
        }),
        switchMap(
          ({ campaigns, typeMappings, metricTypes }) => {
            const requests =
              campaigns.reduce(
                (reqObj, campaign) => {
                  const typeName = hubspotMappingTypeNameByCampaignType[campaign.type];
                  if (!reqObj[typeName].includes(campaign.id)) {
                    reqObj[typeName].push(campaign.id);
                  }
                  return reqObj;
                },
                {
                  [HubspotMappingTypeName.emails]: [],
                  [HubspotMappingTypeName.forms]: [],
                  [HubspotMappingTypeName.utmCampaigns]: []
                }
              );
            return forkJoin(
              Object.entries(requests)
                .filter((([, extCampaignIds]) => extCampaignIds?.length))
                .map(
                   ([groupName, extCampaignIds]: [HubspotMappingTypeName, string[]]) =>
                    this.hubspotDataService.getCurrentMappingTargets<CurrentMappingTargetsRequest, CurrentMappingTargetsResponse>(
                      companyId,
                      integrationId,
                      budget.id,
                      {
                        mappingGroupType: groupName,
                        extCampaignIds: extCampaignIds
                      }
                    ).pipe(map(resp => ({[groupName]: resp})))
                )
            ).pipe(
              map(
                (data: {[key in HubspotMappingTypeName]: CurrentMappingTargetsResponse}[]) => ({
                  campaigns,
                  typeMappings,
                  metricTypes,
                  mappingTargets: data.reduce(
                    (res, resp) => ({...resp, ...res}),
                    {}
                  )
                })
              )
            );
          }
        ),
        map(({ campaigns: extCampaigns, typeMappings, metricTypes, mappingTargets }) => {
          const metricTypeMappings =
            this.getMetricTypeMappings(typeMappings, (relevantPlannuhMetrics || []).map(metric => metric.metricId));
          const filteredMetricNames = metricTypes.filter(metricType => metricTypeMappings.includes(metricType.name));

          return {
            campaigns: this.createCampaignsTree(
              campaignId,
              extCampaigns,
              filteredMetricNames,
              typeMappings,
              relevantPlannuhMetrics,
              mappingTargets,
              allCampaigns
            ),
            metricTypeMappings
          };
        })
      );
  }

  public getExternalMetrics(companyId: number): Observable<ExternalMetrics> {
    return this.hubspotMetricTypesService.getMetricTypes(companyId);
  }
}
