<div class="overdue-expenses-legend">
  <div class="legend-description">
    <fa-duotone-icon [icon]="['fad', 'graduation-cap']"></fa-duotone-icon>
    <div class="message">
      You can free this money by doing one of the following:
    </div>
  </div>
  <div class="legend-list">
    <div class="list-item" *ngFor="let item of legendItems" [innerHTML]="item"></div>
  </div>
</div>
