import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'table-view-mode-switch',
  templateUrl: './table-view-mode-switch.component.html',
  styleUrls: ['./table-view-mode-switch.component.scss']
})
export class TableViewModeSwitchComponent {
  @Output() onShowClassicView: EventEmitter<void> = new EventEmitter<void>();
}
