import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';

export interface ShadowState {
  left?: boolean;
  right?: boolean;
  top?: boolean;
  bottom?: boolean;
}

@Directive({
  selector: '[tableContentShadows]'
})
export class TableContentShadowsDirective implements AfterViewInit, OnDestroy {
  @Input() updateShadows$ = new BehaviorSubject<void>(null);
  @Output() onShadowChanged = new EventEmitter();

  private readonly destroy$ = new Subject<void>();
  private readonly resizeEvent = new Subject<void>();
  private resizeObserver: ResizeObserver = null;
  private updateThrottleTime = 100;
  private shadowState: ShadowState = {
    left: false,
    right: false,
    top: false,
    bottom: false,
  };

  constructor(
    private element: ElementRef,
    private zone: NgZone,
  ) {}

  private shadowStateChanged(
    stateA: ShadowState,
    stateB: ShadowState
  ): boolean {
    return Object.keys(stateA).some(
      key => stateA[key] !== stateB[key]
    );
  }

  private defineShadows(target: HTMLElement) {
    const scrollLeft = target?.scrollLeft;
    const scrollTop = target?.scrollTop;
    const offsetWidth = target?.offsetWidth;
    const offsetHeight = target?.offsetHeight;
    const scrollWidth = target?.scrollWidth;
    const scrollHeight = target?.scrollHeight;
    const shadowState: ShadowState = {
      left: scrollLeft > 0,
      right: (scrollLeft + offsetWidth) < (scrollWidth),
      top: scrollTop > 0,
      bottom: (scrollTop + offsetHeight) < (scrollHeight),
    };

    if (this.shadowStateChanged(this.shadowState, shadowState)) {
      this.shadowState = shadowState;
      this.zone.run(() => setTimeout(() => this.onShadowChanged.emit(shadowState), 0));
    }
  }

  private initListeners() {
    this.zone.runOutsideAngular(() => {
      this.resizeObserver = new ResizeObserver(() => this.resizeEvent.next());
      this.resizeObserver.observe(this.element.nativeElement);

      merge(
        fromEvent(this.element.nativeElement, 'scroll'),
        this.resizeEvent,
        this.updateShadows$
      )
        .pipe(
          throttleTime(this.updateThrottleTime, undefined, { leading: true, trailing: true }),
          takeUntil(this.destroy$)
        )
        .subscribe(
          () => this.defineShadows(this.element.nativeElement)
        );
    })
  }

  ngAfterViewInit(): void {
    this.initListeners();
    setTimeout(() => this.defineShadows(this.element.nativeElement))
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resizeObserver?.unobserve(this.element.nativeElement);
  }
}
