export const COLORS = {
  WHITE: '#fff',
  NAVY_BLUE: '#061F38',
  CORPORATE_MAGENTA: '#E82987',
  CORPORATE_PURPLE: '#4C368D',
  VIOLET: '#6D46E2',
  GREEN_PRIMARY: '#4CB050',
  PRIMARY: '#0063B7',
  RED: '#f00',
  LOW_ALERT: '#FF9901',
  NAVY_VIOLET: '#362370',
  GRAY_DARKEST: '#272727',
  GRAY_MEDIUM: '#B7C1D0'
};

export const COLORS_MAP = {
  'far-below-target': COLORS.RED,
  'below-target': COLORS.LOW_ALERT,
  'in-target': COLORS.GREEN_PRIMARY,
  'above-target': COLORS.GREEN_PRIMARY
}
