<ng-container *ngIf="selectedBudget && companyBudgets.length > 1; else selectPlaceholder">
  <button mat-button [matMenuTriggerFor]="menu" class="header-action-item select-trigger">
    <div [stringTruncate]="selectedBudget?.name"
         [prefix]="'[' + BudgetStatusLabel[selectedBudget?.status] + ']'"
         class="custom-select-trigger"></div>
    <fa-icon [icon]="['far', 'chevron-down']"></fa-icon>
  </button>
  <mat-menu #menu class="mat-pl-menu navigation-menu budget-select" xPosition="before">
    <button
      *ngFor="let item of companyBudgets; let index = index;"
      mat-menu-item
      (click)="onSelectBudget(item.id)"
      [class.active]="item.id === selectedBudget?.id"
      [class.divider]="index === lastLiveIndex"
    >
      <span>
        <b>[{{BudgetStatusLabel[item?.status]}}]</b>
        {{ item.name }}
      </span>
    </button>
  </mat-menu>
</ng-container>
<ng-template #selectPlaceholder>
  <div *ngIf="selectedBudget" [stringTruncate]="selectedBudget?.name"
       [prefix]="'[' + BudgetStatusLabel[selectedBudget?.status] + ']'"
       class="custom-select-trigger placeholder"></div>
  <span *ngIf="!selectedBudget && !companyBudgets.length" class="no-budget">No Budget</span>
</ng-template>
