export enum MetricValueUpdateType {
  campaign = 'campaign',
  program = 'program',
  salesForce = 'salesForce',
  hubSpot = 'hubSpot',
  googleAds = 'googleAds',
  linkedinAds = 'linkedinAds',
  facebookAds = 'facebookAds',
  monthTotal = 'monthTotal'
}

export interface MetricValueUpdateItem {
  id?: number;
  type: MetricValueUpdateType;
  objectId?: number;
  metricMapping?: number;
  date: string;
  changeInValue: number;
  runningTotal: number;
  updatedDate: string;
  notes?: string;
  isEditable: boolean;
  isRemovable: boolean;
  monthChildItems?: MetricValueUpdateItem[];
}

export type MetricValueUpdatesData = MetricValueUpdateItem[];
