import { Injectable } from '@angular/core';
import { UserManager } from 'app/user/services/user-manager.service';

/**
 * Defined in GTM event names
 */
const GTMEvents = {
  PageView: 'pageview',
  UserID: 'userID',
  GAEvent: 'gaEvent'
};

interface EventPayload {
  eventCategory: string,
  eventAction: string,
  eventLabel?: string,
  eventValue?: number,
}

interface DataLayerPayload {
  event: string,
  customUserID: number | null,
  customPagePath?: string,
  eventCategory?: string,
  eventLabel?: string,
  eventAction?: string,
  eventValue?: number,
}

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  constructor(private userManager: UserManager) {}

  private getDataLayer(): any[] | null {
    return (<any>window).dataLayer || null;
  }

  private pushToDataLayer(payload: DataLayerPayload) {
    const dataLayer = this.getDataLayer();

    if (dataLayer == null || !Array.isArray(dataLayer)) {
      return;
    }

    dataLayer.push(payload);
  }

  private getUserID(): number | null {
    const user = this.userManager.getCurrentUser();

    if (user != null) {
      return user.id;
    }

    return null;
  }

  /**
   * Send custom GA event with metrics:
   *  `eventCategory, eventAction, eventLabel, eventValue`
   */
  public sendEvent(payload: EventPayload) {
    this.pushToDataLayer({
      event: GTMEvents.GAEvent,
      customUserID: this.getUserID(),
      eventCategory: payload.eventCategory,
      eventAction: payload.eventAction,
      eventLabel: payload.eventLabel,
      eventValue: payload.eventValue,
    })
  }

  /**
   * Send 'pageview' event to GTM
   * 'customPagePath' param defines current page
   */
  public sendPageView(pagePath: string) {
    this.pushToDataLayer({
      event: GTMEvents.PageView,
      customPagePath: pagePath,
      customUserID: this.getUserID()
    })
  }

  /**
   * Send 'userID' event to GTM
   */
  public sendUserId(userId: number) {
    this.pushToDataLayer({
      event: GTMEvents.UserID,
      customUserID: userId
    })
  }
}
