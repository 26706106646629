import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

export interface GroupToggleOption {
  name: string;
  value: string;
}

@Component({
  selector: 'group-toggle-control',
  styleUrls: ['./group-toggle-control.component.scss'],
  templateUrl: './group-toggle-control.component.html'
})
export class GroupToggleControlComponent {
  @Input() currentValue;
  @Input() options: GroupToggleOption[];
  @Output() onChange = new EventEmitter();

  public handleChange($event: MatButtonToggleChange) {
    this.onChange.emit($event.value);
  }
}
