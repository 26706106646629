import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Ng2CompleterModule } from 'ng2-completer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorsModalComponent } from './components/errors-modal/errors-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { SpendingComponent } from './components/spending/spending.component';
import { SidebarContentComponent } from './components/spending/sidebar-content/sidebar-content.component';
import { SpendingManageMenuComponent } from './components/spending-manage-menu/spending-manage-menu.component';
import { ManageExpenseTableComponent } from './components/spending/expenses/expense-table/expense-table.component';
import { ViewModeSelectBtnComponent } from './components/spending/sidebar-content/view-mode-select-btn/view-mode-select-btn.component';
import { SelectAllBtnComponent } from './components/spending/sidebar-content/select-all-btn/select-all-btn.component';
import { NoExpensesPlaceholderComponent } from './components/spending/expenses/no-expenses-placeholder/no-expenses-placeholder.component';
import { SelectHierarchyItemBannerComponent } from './components/spending/expenses/select-hierarchy-item-banner/select-hierarchy-item-banner.component';
import { SidebarHierarchyOptionsListComponent } from './components/spending/sidebar-content/sidebar-hierarchy-options-list/sidebar-hierarchy-options-list.component';
import { SidebarHierarchyOptionComponent } from './components/spending/sidebar-content/sidebar-hierarchy-option/sidebar-hierarchy-option.component';
import { SidebarFlatOptionsListComponent } from './components/spending/sidebar-content/sidebar-flat-options-list/sidebar-flat-options-list.component';
import { ManageColumnsDialogComponent } from './components/manage-columns-dialog/manage-columns-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExpensePageSortByService } from './services/expense-page-sort-by.service';
import { ExpenseAmountComponent } from './components/spending/expenses/expense-table/expense-amount/expense-amount.component';
import { ExpenseNameCellComponent } from './components/spending/expenses/expense-table/expense-name-cell/expense-name-cell.component';
import { SpendingSidebarOptionsProviderService } from './services/spending-sidebar-options-provider.service';
import { HierarchyBuilder } from './services/hierarchy.builder';
import { ExpensePageSelectionService } from './services/expense-page-selection.service';
import { SelectAllBannerComponent } from './components/spending/expenses/select-all-banner/select-all-banner.component';
import { ExpensesComponent } from './components/spending/expenses/expenses.component';
import { InvoicesComponent } from './components/spending/invoices/invoices.component';
import { ActionMenuComponent } from './components/spending-manage-menu/action-menu/action-menu.component';
import { ExpensePageDrawerService } from '@spending/services/expense-page-drawer.service';
import { SpendingSidebarService } from './services/spending-sidebar.service';
import { InvoiceReviewComponent } from './components/spending/invoices/invoice-review/invoice-review.component';
import { InvoiceDetailsComponent } from './components/spending/invoices/invoice-details/invoice-details.component';
import { InvoiceEmailComponent } from './components/spending/invoices/invoice-email/invoice-email.component';
import { BudgetObjectDetailsModule } from 'app/budget-object-details/budget-object-details.module';
import { InvoiceReviewActionsPanelComponent } from './components/spending/invoices/invoice-review-actions-panel/invoice-review-actions-panel.component';
import { InvoiceTableComponent } from './components/spending/invoices/invoice-table/invoice-table.component';
import { ExpenseTableCellAlignmentDirective } from '@spending/directives/expense-table-cell-alignment.directive';
import { NoInvoicesPlaceholderComponent } from './components/spending/invoices/no-invoices-placeholder/no-invoices-placeholder.component';
import { InvoicePageSortByService } from '@spending/services/invoice-page-sort-by.service';
import { MoveToBudgetDialogComponent } from '@spending/components/move-to-budget-dialog/move-to-budget-dialog.component';
import { InvoiceUploadBtnComponent } from './components/spending/invoices/invoice-upload-btn/invoice-upload-btn.component';
import { UploadStatusBtnComponent } from './components/spending/invoices/upload-status-btn/upload-status-btn.component';
import { UploadStatusDialogComponent } from './components/spending/invoices/upload-status-dialog/upload-status-dialog.component';
import { InvoiceUploadOverlayComponent } from './components/spending/invoices/invoice-upload-overlay/invoice-upload-overlay.component';

@NgModule({
  declarations: [
    ErrorsModalComponent,
    SpendingComponent,
    SidebarContentComponent,
    SpendingManageMenuComponent,
    ManageExpenseTableComponent,
    ViewModeSelectBtnComponent,
    SelectAllBtnComponent,
    NoExpensesPlaceholderComponent,
    NoInvoicesPlaceholderComponent,
    SelectHierarchyItemBannerComponent,
    SelectAllBannerComponent,
    ExpenseAmountComponent,
    ExpenseNameCellComponent,
    SidebarHierarchyOptionsListComponent,
    SidebarHierarchyOptionComponent,
    SidebarFlatOptionsListComponent,
    ManageColumnsDialogComponent,
    ExpenseTableCellAlignmentDirective,
    ExpensesComponent,
    InvoicesComponent,
    ActionMenuComponent,
    InvoiceReviewComponent,
    InvoiceDetailsComponent,
    InvoiceEmailComponent,
    InvoiceReviewActionsPanelComponent,
    InvoiceTableComponent,
    NoInvoicesPlaceholderComponent,
    MoveToBudgetDialogComponent,
    InvoiceUploadBtnComponent,
    UploadStatusBtnComponent,
    UploadStatusDialogComponent,
    InvoiceUploadOverlayComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    Ng2CompleterModule,
    FontAwesomeModule,
    MatTooltipModule,
    DragDropModule,
    BudgetObjectDetailsModule
  ],
  exports: [InvoiceReviewComponent],
  providers: [
    DecimalPipe,
    ExpensePageSortByService,
    InvoicePageSortByService,
    SpendingSidebarOptionsProviderService,
    HierarchyBuilder,
    ExpensePageSelectionService,
    ExpensePageDrawerService,
    SpendingSidebarService
  ]
})
export class ExpensesModule { }
