<loader [isVisible]="isLoading"></loader>
<div class="bulk-update-dialog">
  <header mat-dialog-title><b>{{ context?.title }}</b></header>
  <div mat-dialog-content [formGroup]="formData">
    <div class="controls" *ngIf="!isLoading">
      <ng-container [ngSwitch]="context?.type">
        <ng-container *ngSwitchCase="DIALOG_TYPE.tags">
          <tags-control
            #tagsControl
            [tagsList]="tagMappings"
            [autocompleteItems]="context?.data?.autocompleteItems"
            (onAddTag)="context?.data?.onAddTag($event)"
            (onCreateTag)="context?.data?.onCreateTag($event)"
            (onRemoveTag)="context?.data?.onRemoveTag($event)"
          ></tags-control>
        </ng-container>

        <ng-container *ngSwitchCase="DIALOG_TYPE.tagsRemoval">
          <tags-control
            [tagsList]="tagMappings"
            [autocompleteItems]="context?.data?.autocompleteItems"
            [removeOnly]="true"
            (onRemoveTag)="context?.data?.onRemoveTag($event)"
          ></tags-control>
          <div class="dialog-hint">Click <fa-icon [icon]="['far', 'times']"></fa-icon> to remove a tag</div>
        </ng-container>

        <ng-container *ngSwitchCase="DIALOG_TYPE.text">
          <mat-form-field appearance="outline" class=" p-form-field-outline no-validate">
            <input matInput formControlName="text"/>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="DIALOG_TYPE.textWithAutocomplete">
          <autocomplete-control
            [items]="context?.data?.autocompleteItems"
            [maxItemLength]="context?.data?.maxItemLength"
            (onItemChanged)="handleAutocompleteChange($event)"
          >
          </autocomplete-control>
        </ng-container>
        <ng-container *ngSwitchDefault>{{ context?.data }}</ng-container>
      </ng-container>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      color="primary"
      (click)="callHandler(context?.cancelAction?.handler)" *ngIf="context?.cancelAction"
    >
      {{ context?.cancelAction?.label }}
    </button>
    <button
      mat-flat-button
      color="primary"
      cdkFocusInitial
      (click)="callHandler(context?.submitAction?.handler)" *ngIf="!isLoading && context?.submitAction"
    >
      {{ context?.submitAction?.label }}
    </button>
  </div>
</div>
