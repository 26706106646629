import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Configuration } from '../../app.constants';
import { UserManager } from '../../user/services/user-manager.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class SSOGuard implements CanActivate {
  constructor (
    private userManager: UserManager,
    private router: Router,
    private configuration: Configuration
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userManager.isLoggedIn().pipe(
      switchMap(loggedIn => {
        const extIdpName = this.getExternalIdentityProviderName();
        return !loggedIn && extIdpName ?
          this.userManager.federatedLogin(extIdpName).pipe(map(() => false)) :
          of(true)
      })
    );
  }

  private getExternalIdentityProviderName(): string {
    const customIdps = this.configuration?.customIdentityProviders;
    const customIdp = customIdps && customIdps[window.location.hostname];
    if (customIdp) {
      return customIdp;
    }

    return this.userManager.getCurrentDomainPrefix();
  }
}
