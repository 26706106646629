import { Injectable } from '@angular/core';
import { ExpenseTableColumn } from '@spending/types/expense-page.type';
import { DatePipe } from '@angular/common';
import { ExpenseDO } from '@shared/types/expense.interface';
import { ExtendedUserDO } from '@shared/types/user-do.interface';
import { CompanyDataService } from '@shared/services/company-data.service';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { AmountColumnData } from '@spending/services/expense-table-data.service';

export interface SpendingCommonRowData {
  rowId: number;
  expenseId: number; // is not unique key (can be duplicated for the pseudoObjects)
  expenseObject: ExpenseDO;
}

export interface InvoiceRowData extends SpendingCommonRowData {
  [ExpenseTableColumn.NAME]: string;
  [ExpenseTableColumn.INVOICE]: string;
  [ExpenseTableColumn.ACTUAL]: AmountColumnData;
  [ExpenseTableColumn.TIMEFRAME]: string;
  [ExpenseTableColumn.CREATED_DATE]: string;
  [ExpenseTableColumn.OWNER]: string;
}

@Injectable()
export class InvoiceTableDataService {
  private _rows: InvoiceRowData[];
  private _timeframes: BudgetTimeframe[];

  private static createOwnerCell(userId: number, companyUsersSnapshot: ExtendedUserDO[]): string {
    const profileDetail = companyUsersSnapshot.find(user => user.id === userId)?.user_profile_detail;
    return !profileDetail ?
      userId.toString() :
      (profileDetail.first_name.charAt(0) + profileDetail.last_name.charAt(0)).toUpperCase();
  }

  constructor(
    private readonly companyDataService: CompanyDataService,
    private readonly datePipe: DatePipe,
  ) {
  }

  public set timeframes(tf: BudgetTimeframe[]) {
    this._timeframes = tf;
  }

  public createRows(expenses: ExpenseDO[]): void {
    this._rows = !expenses ? null : expenses.map(expense => this.createTableRow(expense, expenses));
  }

  private createTableRow(expenseDo: ExpenseDO, allExpenses: ExpenseDO[]): InvoiceRowData {
    return {
      rowId: expenseDo.id,
      expenseId: expenseDo.id,
      expenseObject: expenseDo,
      [ExpenseTableColumn.NAME]: expenseDo.name,
      [ExpenseTableColumn.INVOICE]: expenseDo.invoice,
      [ExpenseTableColumn.ACTUAL]: this.getAmount(expenseDo),
      [ExpenseTableColumn.CREATED_DATE]: this.getSubmittedDateString(expenseDo, allExpenses),
      [ExpenseTableColumn.TIMEFRAME]: this.getTimeframeDateString(expenseDo.company_budget_alloc),
      [ExpenseTableColumn.OWNER]:
        InvoiceTableDataService.createOwnerCell(expenseDo.created_by, this.companyDataService.companyUsersSnapshot),
    };
  }

  private getAmount(expense: ExpenseDO): AmountColumnData {
    return {
      sourceAmount: expense.source_actual_amount,
      convertedAmount: expense.source_actual_amount === expense.actual_amount ? null : expense.actual_amount,
      sourceCurrency: expense.source_currency,
      disabled: true,
    }
  }

  private getTimeframeDateString(timeframeId: number): string {
    const timeframe = this._timeframes.find(tf => tf.id === timeframeId);
    return timeframe?.shortName + ' ' + timeframe?.year;
  }

  private getSubmittedDateString(expense: ExpenseDO, shownOnCurrentPage: ExpenseDO[]): string {
    const hasSimilar = InvoiceTableDataService.hasSimilarRecord(expense, shownOnCurrentPage);
    const format = InvoiceTableDataService.getDateFormat(expense.crd, hasSimilar);
    return this.datePipe.transform(expense.crd, format);
  }

  private static hasSimilarRecord(invoice: ExpenseDO, list: ExpenseDO[]): boolean {
    return !!list
      .filter(item => InvoiceTableDataService.isTheSameDay(invoice.crd, item.crd))
      .find(item => InvoiceTableDataService.isTheSameInvoice(invoice, item));
  }

  private static getDateFormat(createdAt: string, hasSimilar: boolean): string {
    return InvoiceTableDataService.isTheSameDay(createdAt) ? 'h:mm a' :
      hasSimilar ? 'dd MMM yyyy, h:mm a' : 'dd MMM yyyy';
  }

  private static isTheSameInvoice(item: ExpenseDO, compareWith: ExpenseDO): boolean {
    return item.id !== compareWith.id &&
      item.name === compareWith.name &&
      item.invoice === compareWith.invoice &&
      item.actual_amount === compareWith.actual_amount;
  }

  private static isTheSameDay(date: string, compareWith?: string): boolean {
    const createdDate = new Date(date);
    const today = compareWith ? new Date(compareWith) : new Date();
    return today.getDate() === createdDate.getDate() &&
      today.getMonth() === createdDate.getMonth() &&
      today.getFullYear() === createdDate.getFullYear();
  }

  public get rows(): InvoiceRowData[] {
    return this._rows;
  }
}
