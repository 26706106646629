import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

const MONTH_NAME_MAPPINGS = new Map([
  ['January', 'J'],
  ['February', 'F'],
  ['March', 'M'],
  ['April', 'A'],
  ['May', 'M'],
  ['June', 'J'],
  ['July', 'J'],
  ['August', 'A'],
  ['September', 'S'],
  ['October', 'O'],
  ['November', 'N'],
  ['December', 'D'],
]);

export interface TimeframeItem {
  id: number;
  itemName: string;
  name: string;
  locked: boolean;
}

@Component({
  selector: 'timeframes',
  templateUrl: './timeframes.component.html',
  styleUrls: ['./timeframes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeframesComponent {
  @Input() timeframesList: TimeframeItem[];
  @Input() selectedTimeframeIds: number[];
  @Input() hasBorders = false;
  @Input() tooltipText: string;

  get preparedItems() {
    return (this.timeframesList || []).map(item => ({
      title: MONTH_NAME_MAPPINGS.get(item.name) || item.name,
      isSelected: (this.selectedTimeframeIds || []).some(selectedItemId => selectedItemId === item.id),
    }));
  }

  trackItemsFn(_index: number, tfItem: TimeframeItem) {
    return tfItem.id;
  }
}
