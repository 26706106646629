import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { TooltipContext } from '@shared/directives/dynamic-portal-tooltip.directive';
import { BudgetSummaryBarViewMode } from '@shared/enums/budget-summary-bar-view.enum';
import { SummaryBarCalculationItem, SummaryBarItem } from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { summaryBarDefaultItems, tooltipContextList } from '@shared/components/budget-summary-bar/budget-summary-bar.helpers';

@Component({
  selector: 'budget-summary-bar',
  templateUrl: './budget-summary-bar.component.html',
  styleUrls: ['./budget-summary-bar.component.scss']
})
export class BudgetSummaryBarComponent {
  public summaryBarItems: SummaryBarItem[][] = summaryBarDefaultItems;
  public tooltipPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -15
  };
  public tooltipContextList = tooltipContextList;

  @Input() calculationItems: SummaryBarCalculationItem[];
  @Input() summaryBarLoading$: BehaviorSubject<boolean>;
  @Input() summaryBarViewMode: BudgetSummaryBarViewMode;
  @Input() set summaryItems(items: SummaryBarItem[][]) {
    if (items) {
      this.summaryBarItems = items;
    }
  };

  getSummaryBarTooltipContext(index: number): TooltipContext {
    if (!this.summaryBarViewMode) {
      return null;
    }
    return this.tooltipContextList[this.summaryBarViewMode][index];
  }
}
