<form id="form-addGoalDetail" class="row-addGoal modal-form" [formGroup]='addGlCodeForm' (ngSubmit)="addUpdateGlCode()" novalidate>
  <header mat-dialog-title>{{ title }}</header>
  <div mat-dialog-content class="dialog-info">
    <div class="row-holder">
      <div class="row-cell">
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>GL Code</mat-label>
          <input type="text" matInput formControlName="name" trim maxlength="30">
        </mat-form-field>
        <mat-error *ngIf="name.touched && !name.valid && name.errors?.required">
          {{ validations.ValiditionMessages.GL_CODE_REQUIRED }}
        </mat-error>
        <mat-error *ngIf="name.errors && name.errors.trim">
          {{ validations.ValiditionMessages.GL_CODE_REQUIRED }}
        </mat-error>
        <mat-error *ngIf="name.dirty && name.errors?.maxlength">
          {{ validations.ValiditionMessages.COMMON_MAX_LENGTH(MAX_TEXT_INPUT_LENGTH) }}
        </mat-error>
        <mat-error *ngIf="name.touched && name.errors?.notUniqueName">
          {{ validations.ValiditionMessages.GL_CODE_ALREADY_EXIST }}
        </mat-error>
      </div>
      <div class="row-cell">
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>Description</mat-label>
          <input type="text" matInput formControlName="description" trim maxlength="128">
        </mat-form-field>
        <mat-error *ngIf="description.touched && !description.valid && description.errors?.required">
          {{ validations.ValiditionMessages.GL_CODE_DESCRIPTION_REQUIRED }}
        </mat-error>
        <mat-error *ngIf="description.errors && description.errors.trim">
          {{ validations.ValiditionMessages.GL_CODE_DESCRIPTION_REQUIRED }}
        </mat-error>
        <mat-error *ngIf="description.dirty && description.errors?.maxlength">
          {{ validations.ValiditionMessages.COMMON_MAX_LENGTH(MAX_TEXT_INPUT_LENGTH) }}
        </mat-error>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="end" class="dialog-actions">
    <button type="button" mat-button color="primary" mat-dialog-close>Close</button>
    <button type="submit" mat-flat-button color="primary" [disabled]="!addGlCodeForm.valid">Finish</button>
  </div>
</form>
