import { Pipe, PipeTransform } from '@angular/core';
import { BudgetTimeframe } from '../types/timeframe.interface';

@Pipe({
  name: 'timeframeShortName'
})
export class TimeframeShortNamePipe implements PipeTransform {

  transform(budgetTimeframe: BudgetTimeframe) {
    return (budgetTimeframe.name.length > 3 && isNaN(+budgetTimeframe.name)
      ? budgetTimeframe.name.substring(0, 3)
      : budgetTimeframe.name).toLowerCase();
  }
}
