import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Configuration } from '../../app.constants';
import { UserManager } from '../../user/services/user-manager.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AlreadyLoggedInGuard implements CanActivate {
  constructor (
    private userManager: UserManager,
    private router: Router,
    private configuration: Configuration
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // If not logged in - return true, otherwise - return an UrlTree to redirect to
    return this.userManager.isLoggedIn().pipe(
      map(loggedIn => !loggedIn || this.router.createUrlTree([this.configuration.DEFAULT_ROUTE]))
    );
  }
}
