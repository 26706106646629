import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BudgetCalculatorData } from '@common-lib/lib/corporate-page/metric-funnels.types';
import { SelectOption } from '@shared/types/select-option.interface';
import { TabSwitchOption } from '@shared/components/tab-switch/tab-switch.types';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ProductBudgetCalculationsContext {
  countMetricList: MetricSelectItem[];
  budgetCalculatorData: BudgetCalculatorData;
  companyCurrency: string;
  targetRevenueAmount: number;
}

export interface MetricSelectItem extends SelectOption {
  count: number;
}

enum BudgetCalculatorMode {
  PrevBudget = 'PrevBudget',
  CostPerMetric = 'CostPerMetric',
}

@Component({
  selector: 'product-budget-dialog',
  templateUrl: './product-budget-dialog.component.html',
  styleUrls: ['./product-budget-dialog.component.scss']
})
export class ProductBudgetDialogComponent implements OnDestroy {
  public form: FormGroup;
  public currencyMaskOptions = {
    prefix: '',
    decimal: '.',
    precision: 2,
    align: 'left',
    allowNegative: false,
  };
  public readonly calculatorModeOptions: TabSwitchOption[] = [
    {
      title: 'I know a previous budget',
      value: BudgetCalculatorMode.PrevBudget,
      icon: null
    },
    {
      title: 'I know my cost per metric',
      value: BudgetCalculatorMode.CostPerMetric,
      icon: null
    }
  ];
  public targetedMetricName: string;
  public calculatorMode: BudgetCalculatorMode;
  public budgetCalculatorMode = BudgetCalculatorMode;
  public budgetAmount: number;
  private destroy$ = new Subject<void>();

  public static getBudgetAmount(formValue: any, mode: BudgetCalculatorMode): number {
    const prevMetricCostGetter = {
      [BudgetCalculatorMode.PrevBudget]: () => formValue.prevBudget && formValue.prevMetricNumber ?
        formValue.prevBudget / formValue.prevMetricNumber : null,
      [BudgetCalculatorMode.CostPerMetric]: () => formValue.prevMetricCost || null,
    };
    const prevMetricCost = prevMetricCostGetter[mode]();
    return prevMetricCost ? prevMetricCost * formValue.targetedMetric.count : null;
  }

  private static getInitialTargetedMetric(context: ProductBudgetCalculationsContext): MetricSelectItem {
    const countMetricList = context.countMetricList;
    const targetedMetricId = context.budgetCalculatorData?.targetedMetricId;
    return targetedMetricId ? countMetricList.find(metric => metric.id === targetedMetricId) : countMetricList[0];
  }

  constructor(
    private readonly fb: FormBuilder,
    public readonly dialogRef: MatDialogRef<ProductBudgetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: ProductBudgetCalculationsContext,
  ) {
    this.setDefaultCalculatorMode();
    const targetedMetric = ProductBudgetDialogComponent.getInitialTargetedMetric(this.context);
    this.targetedMetricName = targetedMetric.name;
    this.initForm(targetedMetric);
    this.setValidators();
    this.updateBudgetAmount();

    this.form.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(changes => {
      this.targetedMetricName = changes.targetedMetric.name;
      this.updateBudgetAmount();
    });
  }

  private initForm(targetedMetric: MetricSelectItem) {
    const data = this.context.budgetCalculatorData;
    const formConfig = {
      targetedMetric,
      prevBudget: [ data?.prevBudget || 0],
      prevMetricNumber: [ data?.prevMetricNumber || 0],
      prevMetricCost: [ data?.prevMetricCost || 0 ],
    };
    this.form = this.fb.group(formConfig);
  }

  private setValidators(): void {
    const controlNames = ['prevBudget', 'prevMetricNumber', 'prevMetricCost'];
    const validators = {
      prevBudget: this.getValueForMode(this.validateAmount(), BudgetCalculatorMode.PrevBudget),
      prevMetricNumber: this.getValueForMode(this.validateAmount(), BudgetCalculatorMode.PrevBudget),
      prevMetricCost: this.getValueForMode(this.validateAmount(), BudgetCalculatorMode.CostPerMetric),
    };
    controlNames.forEach(controlName => {
      const control = this.form.controls[controlName];
      control.setValidators(validators[controlName]);
      control.updateValueAndValidity();
    })
  };

  private validateAmount(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value <= 0;
      return forbidden ? { notAllowed: { value: control.value } } : null;
    };
  }

  private setDefaultCalculatorMode(): void {
    this.calculatorMode = this.context.budgetCalculatorData.prevMetricCost ?
      BudgetCalculatorMode.CostPerMetric :
      BudgetCalculatorMode.PrevBudget;
  }

  public onCalculatorModeChange(mode: string): void {
    this.calculatorMode = mode as BudgetCalculatorMode;
    this.setValidators();
    this.updateBudgetAmount();
  }

  public updateBudgetAmount(): void {
    this.budgetAmount = ProductBudgetDialogComponent.getBudgetAmount(this.form.value, this.calculatorMode);
  }

  public saveData(): void {
    const formValue = this.form.value;
    const data: BudgetCalculatorData = {
      targetedMetricId: formValue.targetedMetric.id,
      budgetAmount: this.budgetAmount,
      prevBudget: this.getValueForMode(formValue.prevBudget, BudgetCalculatorMode.PrevBudget),
      prevMetricNumber: this.getValueForMode(formValue.prevMetricNumber, BudgetCalculatorMode.PrevBudget),
      prevMetricCost: this.getValueForMode(formValue.prevMetricCost, BudgetCalculatorMode.CostPerMetric),
    }
    this.dialogRef.close(data);
  }

  private getValueForMode<ValueType>(value: ValueType, allowedMode: BudgetCalculatorMode): ValueType {
    return this.calculatorMode === allowedMode ? value : null;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
