import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AmchartsService } from 'app/shared/services/amcharts.service';
import { SmallNumber } from 'app/shared/pipes/smallnumber.pipe';
import { COLORS } from 'app/shared/constants/colors.constants';
import {
  MetricsProgressChartField,
  MetricsProgressChartRecord,
  MetricsProgressDataItem,
  MetricsProgressDataValues,
  MetricsProgressOverflowValues,
  MetricsProgressSeries,
  MetricsProgressShareValues
} from '../../types/metrics-progress-chart.type';
import { calculateShareValue } from 'app/shared/utils/common.utils';

@Component({
  selector: 'metrics-progress-chart',
  styleUrls: ['./metrics-progress-chart.component.scss'],
  templateUrl: './metrics-progress-chart.component.html'
})
export class MetricsProgressChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() data: MetricsProgressDataItem[] = [];
  @Output() onCategoryClicked = new EventEmitter<string>();
  @ViewChild('chartRef', { static: true }) chartRef: ElementRef;

  private overflowImagePath = {
    [MetricsProgressChartField.UserContributionYTD]: 'assets/images/charts/overflow-purple.svg',
    [MetricsProgressChartField.TeamContributionYTD]: 'assets/images/charts/overflow-magenta.svg'
  };
  private normalizedValueSuffix = 'Share';
  private maxColumnsInViewport = 5;
  private seriesWidth = 16;
  private am4charts;
  private am4core;
  private container;
  private chart;
  private categoryAxis;
  private numberFormatter;
  public isScrollable = false;
  public shadowHeight = '';

  constructor(
    private readonly amChartsService: AmchartsService,
    private readonly smallNumber: SmallNumber,
    private readonly zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.amChartsService.importModules()
        .then(modules => {
          if (!modules) {
            return;
          }
          this.am4core = modules.am4core;
          this.am4charts = modules.am4charts;
          this.numberFormatter = new this.am4core.NumberFormatter();
          this.refreshChart();
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data && this.am4charts) {
      this.zone.runOutsideAngular(() => {
        this.refreshChart();
      })
    }
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  private formatTooltipValue(value: number, withDecimal = false) {
    if (!value) {
      return 'N/A';
    }

    return this.formatNumericValue(value, withDecimal);
  }

  private formatNumericValue(value: number, withDecimal = false) {
    const decimalFormat = withDecimal ? '.00' : '.';
    return this.numberFormatter.format(value, `#,###${decimalFormat}`);
  }

  private getValuesTooltipText(targetItem) {
    const { dataItem: { currentTargetValueY, totalTargetValueY, teamValueY, userValueY, dataContext } } = targetItem;
    const { metaInfo } = dataContext;
    const isDecimal = metaInfo && metaInfo.isDecimal;
    const labels = [
      'My Current YTD',
      'Total Current YTD',
      'FY Target',
      'Current Target',
    ];
    const plainValues = [
      this.formatTooltipValue(userValueY, isDecimal),
      this.formatTooltipValue(teamValueY, isDecimal),
      this.formatTooltipValue(totalTargetValueY, isDecimal),
      this.formatTooltipValue(currentTargetValueY, isDecimal)
    ];
    const maxLength = plainValues.reduce((max, value) => Math.max(max, value.length), 0);
    const monospacedValues = plainValues.map(value => value.padStart(maxLength, ' '));
    const content = labels
      .map((label, idx) => (`${label}: ${monospacedValues[idx]}`))
      .join('\n');

    return `[font-size: 13px;font-family:monospace;white-space:pre;]${content}`;
  }

  private getCategoryLabelByIndex(index) {
    const dataItem = this.categoryAxis.dataItems.getIndex(index);
    return dataItem && dataItem.label;
  }

  private handleColumnMouseOver(event) {
    const { index } = event.target.dataItem;
    const label = this.getCategoryLabelByIndex(index);
    if (label) {
      label.textDecoration = 'underline';
    }
  }

  private handleColumnMouseOut(event) {
    const { index } = event.target.dataItem;
    const label = this.getCategoryLabelByIndex(index);
    if (label) {
      label.textDecoration = 'none';
    }
  }

  private truncateLabel(categoryName: string, maxLength = 40) {
    return categoryName.length > maxLength ?
      (categoryName.slice(0, maxLength) + '...') :
      categoryName;
  }

  private refreshChart() {
    this.disposeChart();
    this.initChart();
  }

  private createContainer(nativeEl) {
    const container = this.am4core.create(nativeEl, this.am4core.Container);
    container.width = this.am4core.percent(100);
    container.height = this.am4core.percent(100);
    container.align = 'center';

    return container;
  }

  private createChart(container) {
    const chart = container.createChild(this.am4charts.XYChart);
    chart.svgContainer.autoResize = false;
    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.paddingLeft = 0;
    chart.paddingRight = 0;
    chart.marginTop = 0;
    chart.marginLeft = -60;
    chart.width = this.am4core.percent(100);
    chart.height = this.am4core.percent(100);
    chart.zoomOutButton.disabled = true;
    chart.events.on('ready', (event) => {
      const categoryAxis = event.target.xAxes.getIndex(0);
      const parentHeight = categoryAxis.parent.contentHeight;

      this.shadowHeight = `calc(100% - ${parentHeight}px)`;
    });

    return chart;
  }

  private defineShareAndOverflowValues(values: MetricsProgressDataValues) {
    const totalTarget = values[MetricsProgressChartField.TotalTarget] || values[MetricsProgressChartField.TeamContributionYTD];
    const shareValues: Partial<MetricsProgressShareValues> = {};
    const overflowValues: MetricsProgressOverflowValues = {
      [MetricsProgressChartField.UserContributionYTD]: false,
      [MetricsProgressChartField.TeamContributionYTD]: false,
    };
    let shareLimit = 100;

    Object.entries(values)
      .forEach(([key, value]) => {
        shareValues[key + this.normalizedValueSuffix] = calculateShareValue(value, totalTarget);
      });

    // USER CONTRIBUTION
    shareValues[MetricsProgressChartField.TeamContributionYTDShare] -= shareValues[MetricsProgressChartField.UserContributionYTDShare];
    shareValues[MetricsProgressChartField.TotalTargetShare] -= shareValues[MetricsProgressChartField.UserContributionYTDShare];
    shareLimit -= shareValues[MetricsProgressChartField.UserContributionYTDShare];
    if (shareLimit <= 0) {
      overflowValues[MetricsProgressChartField.UserContributionYTD] = true;
      shareValues[MetricsProgressChartField.TotalTargetShare] = 0;
      shareValues[MetricsProgressChartField.TeamContributionYTDShare] = 0;
      return {
        shareValues,
        overflowValues
      };
    }

    // TEAM CONTRIBUTION
    shareValues[MetricsProgressChartField.TotalTargetShare] -= shareValues[MetricsProgressChartField.TeamContributionYTDShare];
    shareLimit -= shareValues[MetricsProgressChartField.TeamContributionYTDShare];
    if (shareLimit <= 0) {
      overflowValues[MetricsProgressChartField.TeamContributionYTD] = true;
      shareValues[MetricsProgressChartField.TotalTargetShare] = 0;
      return {
        shareValues,
        overflowValues
      };
    }
    shareValues[MetricsProgressChartField.TotalTargetShare] = shareLimit;

    return {
      shareValues,
      overflowValues
    };
  }

  private prepareChartRecords(rawData: MetricsProgressDataItem[]): MetricsProgressChartRecord[] {
    return rawData
      .filter(item => {
        const values = item.values;
        return values[MetricsProgressChartField.TotalTarget] || values[MetricsProgressChartField.TeamContributionYTD];
      })
      .map(item => {
        const values = item.values;
        const totalTarget = values[MetricsProgressChartField.TotalTarget];
        const shareData = this.defineShareAndOverflowValues(values);

        return {
          ...values,
          ...shareData.shareValues,
          overflow: shareData.overflowValues,
          category: item.metricId,
          total: totalTarget,
          metricName: item.metricName,
          productName: item.productName,
          metaInfo: item.metaInfo
        } as MetricsProgressChartRecord;
      });
  }

  private getChartRecords() {
    return this.prepareChartRecords(this.data);
  }

  private getSeriesConfig() {
    return [
      {
        label: MetricsProgressChartField.UserContributionYTD,
        seriesValueLabel: MetricsProgressChartField.UserContributionYTD,
        seriesColor: COLORS.CORPORATE_MAGENTA,
        labelColor: COLORS.CORPORATE_MAGENTA
      },
      {
        label: MetricsProgressChartField.TeamContributionYTD,
        seriesValueLabel: MetricsProgressChartField.TeamContributionYTD,
        seriesColor: COLORS.VIOLET,
        labelColor: COLORS.VIOLET
      },
      {
        label: MetricsProgressChartField.TotalTarget,
        seriesValueLabel: MetricsProgressChartField.TotalTarget,
        seriesColor: 'rgb(214, 209, 226)',
        labelColor: COLORS.NAVY_VIOLET
      },
    ];
  }

  private createAxis(chart) {
    const categoryAxis = chart.xAxes.push(new this.am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.tooltipText = '[font-size: 11px]{category}';
    categoryAxis.renderer.labels.template.tooltipText = `[#fff opacity: 0.5]{productName}[/]\n[#fff]{metricName}`;
    categoryAxis.renderer.labels.template.tooltipY = this.am4core.percent(100);
    categoryAxis.renderer.labels.template.maxWidth = 120;
    categoryAxis.renderer.labels.template.fontSize = 11;
    categoryAxis.renderer.labels.template.fontWeight = '500';
    categoryAxis.renderer.labels.template.fill = COLORS.NAVY_BLUE;
    categoryAxis.renderer.labels.template.textAlign = 'middle';
    categoryAxis.renderer.labels.template.textDecoration = 'none';
    categoryAxis.minZoomCount = 1;
    categoryAxis.maxZoomCount = this.maxColumnsInViewport;
    categoryAxis.tooltip.label.maxWidth = 200;
    categoryAxis.tooltip.label.wrap = true;
    categoryAxis.tooltip.label.textAlign = 'middle';
    categoryAxis.tooltip.label.padding(8, 8, 8, 8);
    categoryAxis.tooltip.background.cornerRadius = 6;
    categoryAxis.tooltip.pointerOrientation = 'down';
    categoryAxis.tooltip.adapter.add('dy', (_dy, tooltip) => -Math.round(tooltip.contentHeight));
    categoryAxis.tooltip.adapter.add('pointerOrientation', () => 'down');
    categoryAxis.renderer.labels.template.cursorOverStyle = this.am4core.MouseCursorStyle.pointer;
    categoryAxis.renderer.labels.template.events.on('hit', (event) => {
      const { target: { dataItem }} = event;
      this.onCategoryClicked.emit(dataItem.category);
    });
    categoryAxis.renderer.labels.template.events.on('over', this.handleColumnMouseOver.bind(this));
    categoryAxis.renderer.labels.template.events.on('out', this.handleColumnMouseOut.bind(this));
    categoryAxis.renderer.labels.template.adapter.add('text', (_text, label) => {
      const dataContext = label.dataItem?.dataContext;
      const productName = dataContext?.productName || '';
      const metricName = dataContext?.metricName || '';
      const rowLengthLimit = 12;
      const productNameLimit = rowLengthLimit;
      const metricNameLimit = productName?.length
        ? rowLengthLimit * 2
        : rowLengthLimit * 3;
      const color = productName?.length
        ? COLORS.CORPORATE_PURPLE
        : COLORS.NAVY_BLUE;

      return `[${color} opacity: 0.5 letter-spacing: -0.03em]${this.truncateLabel(productName, productNameLimit)}[/]
        \n[${color} letter-spacing: -0.03em]${this.truncateLabel(metricName, metricNameLimit)}`;
    });

    this.categoryAxis = categoryAxis;

    const valueAxis = chart.yAxes.push(new this.am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 115;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.labels.template.adapter.add('text', (text, label) => {
      return label.dataItem.value <= 100 ? `${label.dataItem.value}%` : '';
    });
    valueAxis.renderer.grid.template.strokeWidth = 0;

    if (chart.data && chart.data.length >= this.maxColumnsInViewport) {
      this.isScrollable = true;
      chart.bottomAxesContainer.layout = 'absolute';
      chart.scrollbarX = new this.am4core.Scrollbar();
      chart.scrollbarX.startGrip.disabled = true;
      chart.scrollbarX.endGrip.disabled = true;
      chart.scrollbarX.y = 50;
      chart.scrollbarX.background.fillOpacity = 0;
      chart.scrollbarX.thumb.background.fill = this.am4core.color(COLORS.GRAY_MEDIUM);
      chart.scrollbarX.thumb.background.fillOpacity = .3;
      chart.scrollbarX.thumb.background.states.getKey('hover').properties.fill = this.am4core.color(COLORS.GRAY_MEDIUM);
      chart.scrollbarX.thumb.background.states.getKey('hover').properties.fillOpacity = .4;
      chart.scrollbarX.thumb.background.states.getKey('down').properties.fill = this.am4core.color(COLORS.GRAY_MEDIUM);
      chart.scrollbarX.thumb.background.states.getKey('down').properties.fillOpacity = .4;
      chart.scrollbarX.thumb.strokeWidth = 0;
      chart.scrollbarX.properties.minHeight = 6;
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      categoryAxis.events.on('datavalidated', () => {
        categoryAxis.zoomToIndexes(0, this.maxColumnsInViewport, true, true);
      });
    } else {
      this.isScrollable = false;
    }
  }

  private addOverflowAnimation(series, seriesLabel) {
    if (seriesLabel !== MetricsProgressChartField.TotalTarget) {
      return;
    }

    const imageBullet = series.bullets.push(new this.am4charts.Bullet());
    const image = imageBullet.createChild(this.am4core.Image);
    const visibleDY = 18;
    image.horizontalCenter = 'middle';
    image.verticalCenter = 'bottom';
    image.dy = -200;
    image.width = 50;
    image.height = 50;
    image.href = this.overflowImagePath[seriesLabel];
    image.zIndex = -1;
    image.interactionsEnabled = false;
    image.trackable = false;
    image.adapter.add('dy', (dy, target) => {
      if (!target.dataItem) {
        return true;
      }

      const { dataItem: { dataContext } } = target;
      const overflowValues = dataContext.overflow;
      const anyOverflows = overflowValues[MetricsProgressChartField.TeamContributionYTD] || overflowValues[MetricsProgressChartField.UserContributionYTD];
      if (anyOverflows) {
        target.href = overflowValues[MetricsProgressChartField.TeamContributionYTD] ?
          this.overflowImagePath[MetricsProgressChartField.TeamContributionYTD] :
          this.overflowImagePath[MetricsProgressChartField.UserContributionYTD];

        return visibleDY;
      }

      return dy;
    });
  }

  private createCurrentTargetSeries(chart) {
    const currentTargetSeries = chart.series.push(new this.am4charts.StepLineSeries());
    currentTargetSeries.dataFields.valueY = MetricsProgressChartField.CurrentTargetShare;
    currentTargetSeries.dataFields.categoryX = 'category';
    currentTargetSeries.strokeWidth = 0;
    currentTargetSeries.noRisers = true;

    const currentTargetBullet = new this.am4charts.Bullet();
    const arrow = currentTargetBullet.createChild(this.am4core.Triangle);
    arrow.horizontalCenter = 'middle';
    arrow.verticalCenter = 'middle';
    arrow.fill = this.am4core.color(COLORS.CORPORATE_PURPLE);
    arrow.stroke = this.am4core.color(COLORS.CORPORATE_PURPLE);
    arrow.direction = 'right';
    arrow.width = 10;
    arrow.height = 10;
    arrow.dx = -10;
    arrow.horizontalCenter = 'right';

    currentTargetSeries.bullets.push(currentTargetBullet);
  }

  private addSeriesLabel(series, seriesValueLabel, labelColor) {
    const bullet = series.bullets.push(new this.am4charts.LabelBullet());
    bullet.label.text = `{${seriesValueLabel}.formatNumber('#.00')}`;
    bullet.label.fill = this.am4core.color(labelColor);
    bullet.label.fontSize = 14;
    bullet.label.fontWeight = 'bold';
    bullet.label.fontStyle = 'italic';
    bullet.label.textAlign = 'start';
    bullet.label.dx = 15;
    bullet.label.dy = -5;
    bullet.label.truncate = false;
    bullet.label.horizontalCenter = 'left';
    bullet.label.verticalCenter = 'top';
    bullet.label.hideOversized = false;

    const minVisibleShare = 4;
    const hiddenDY = -1000;
    const dyOffset = 7;

    const userContributionAdapter = (dy, data) => {
      const { fieldValue, anyOverflow, userOverflow, teamOverflow, teamContributionYTDShare } = data;
      const normalizedValue = fieldValue / minVisibleShare;
      const normalizedOffset = dyOffset - dyOffset * normalizedValue;
      const maxOverflowValue = 91;
      const minFieldValue = 0.25;

      if (
        fieldValue === 0 ||
        (fieldValue < minFieldValue && !anyOverflow) ||
        (teamContributionYTDShare < minVisibleShare && !userOverflow) ||
        (teamOverflow && fieldValue > maxOverflowValue)
      ) {
        return hiddenDY;
      }

      if (fieldValue < minVisibleShare) {
        return dy - normalizedOffset;
      }

      return dy;
    };

    const totalContributionAdapter = (dy, data) => {
      const { anyOverflow, totalTarget } = data;

      if (totalTarget === 0) {
        return hiddenDY;
      }

      if (anyOverflow) {
        return dy + dyOffset * 2;
      }

      return dy;
    };

    const teamContributionAdapter = (dy, data) => {
      const { anyOverflow, userOverflow, userContributionYTDShare, fieldValue, totalTargetShare } = data;
      const normalizedValue = (fieldValue + userContributionYTDShare) / (minVisibleShare * 2);
      const normalizedOffset = dyOffset - dyOffset * normalizedValue;

      if (
        userOverflow ||
        (fieldValue === 0 && userContributionYTDShare === 0) ||
        (!anyOverflow && totalTargetShare < minVisibleShare)
      ) {
        return hiddenDY;
      }

      if (fieldValue < minVisibleShare && userContributionYTDShare < minVisibleShare) {
        return dy - normalizedOffset;
      }

      return dy;
    };

    bullet.label.adapter.add('dy', (dy, target) => {
      if (!target.dataItem) {
        return dy;
      }

      const { dataItem: { dataContext, component: { dataFields } } } = target;
      const fieldKey = dataFields.valueY;
      const fieldValue = dataContext[fieldKey];
      const { totalTargetShare, userContributionYTDShare, overflow, totalTarget, teamContributionYTDShare } = dataContext;
      const anyOverflow = overflow[MetricsProgressChartField.TeamContributionYTD] || overflow[MetricsProgressChartField.UserContributionYTD];
      const userOverflow = overflow[MetricsProgressChartField.UserContributionYTD];
      const teamOverflow = overflow[MetricsProgressChartField.TeamContributionYTD];

      if (fieldKey === MetricsProgressChartField.UserContributionYTDShare) {
        return userContributionAdapter(dy, {
          fieldValue,
          teamContributionYTDShare,
          anyOverflow,
          userOverflow,
          teamOverflow
        });
      }

      if (fieldKey === MetricsProgressChartField.TotalTargetShare) {
        return totalContributionAdapter(dy, {
          totalTarget,
          anyOverflow
        });
      }

      if (fieldKey === MetricsProgressChartField.TeamContributionYTDShare) {
        return teamContributionAdapter(dy, {
          fieldValue,
          anyOverflow,
          userOverflow,
          totalTargetShare,
          userContributionYTDShare
        });
      }

      return dy;
    });

    bullet.label.adapter.add('text', (labelText, target) => {
      const { dataItem: { dataContext, component } } = target;
      const dataFields = component.dataFields;
      const fieldKey = dataFields.valueY.replace(this.normalizedValueSuffix, '');
      const isDecimal = dataContext.metaInfo && dataContext.metaInfo.isDecimal;
      const fieldValue = isDecimal ? dataContext[fieldKey] : Math.round(dataContext[fieldKey]);
      if (fieldValue < 1) {
        return this.formatNumericValue(fieldValue, isDecimal);
      }
      return this.smallNumber.transform(fieldValue);
    });
  }

  private setSeriesDataFields(series, seriesValueLabel) {
    series.dataFields.categoryX = 'category';
    series.dataFields.valueY = seriesValueLabel + this.normalizedValueSuffix;
    series.dataFields.totalTargetValueY = [MetricsProgressChartField.TotalTarget];
    series.dataFields.userValueY = [MetricsProgressChartField.UserContributionYTD];
    series.dataFields.teamValueY = [MetricsProgressChartField.TeamContributionYTD];
    series.dataFields.currentTargetValueY = [MetricsProgressChartField.CurrentTarget];

    series.dataFields.totalTargetValueYShare = [MetricsProgressChartField.TotalTargetShare];
    series.dataFields.userValueYShare = [MetricsProgressChartField.UserContributionYTDShare];
    series.dataFields.teamValueYShare = [MetricsProgressChartField.TeamContributionYTDShare];
    series.dataFields.currentTargetValueYShare = [MetricsProgressChartField.CurrentTargetShare];
  }

  private configureSeriesTooltip(series) {
    series.tooltip.getFillFromObject = false;
    series.tooltip.fontWeight = 600;
    series.tooltip.label.textAlign = 'end';
    series.tooltip.background.fill = this.am4core.color(COLORS.NAVY_BLUE);
    series.tooltip.background.opacity = 1;
    series.tooltip.background.strokeWidth = 0;
    series.tooltip.color = this.am4core.color(COLORS.WHITE);
    series.tooltip.fillOpacity = 1;
  }

  private createSeries(chart, seriesConfig: MetricsProgressSeries[]) {
    seriesConfig.forEach((seriesItem) => {
      const series = chart.series.push(new this.am4charts.ColumnSeries());
      series.name = seriesItem.label;
      series.simplifiedProcessing = true;
      series.stacked = true;
      series.showOnInit = false;
      series.dataItems.template.locations.categoryX = 0.5;
      series.columns.template.width = this.seriesWidth;
      series.columns.template.strokeWidth = 0;
      series.columns.template.fill = this.am4core.color(seriesItem.seriesColor);
      series.columns.template.cursorOverStyle = this.am4core.MouseCursorStyle.pointer;
      series.columns.template.tooltipText = 'tooltipText';
      series.columns.template.adapter.add('tooltipText', (text, target) => {
        return this.getValuesTooltipText(target);
      });

      series.columns.template.events.on('hit', (event) => {
        const { target: { dataItem }} = event;
        this.onCategoryClicked.emit(dataItem.categories.categoryX);
      });
      series.columns.template.events.on('over', this.handleColumnMouseOver.bind(this));
      series.columns.template.events.on('out', this.handleColumnMouseOut.bind(this));

      this.setSeriesDataFields(series, seriesItem.seriesValueLabel);
      this.configureSeriesTooltip(series);
      this.addSeriesLabel(series, seriesItem.seriesValueLabel, seriesItem.labelColor || seriesItem.seriesColor);
      this.addOverflowAnimation(series, seriesItem.seriesValueLabel);
    });

    this.createCurrentTargetSeries(chart);
  }

  private initChart() {
    if (!this.data.length) {
      return;
    }

    this.container = this.createContainer(this.chartRef.nativeElement);
    this.chart = this.createChart(this.container);
    this.chart.data = this.getChartRecords();
    this.createAxis(this.chart);
    this.createSeries(this.chart, this.getSeriesConfig());
    this.chart.deepInvalidate();
  }

  private disposeChart() {
    this.container = null;
    this.chart = null;
  }
}
