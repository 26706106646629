export enum MetricsProgressChartField {
  TotalTarget = 'totalTarget',
  UserContributionYTD = 'userContributionYTD',
  TeamContributionYTD = 'teamContributionYTD',
  CurrentTarget = 'currentTarget',
  TotalTargetShare = 'totalTargetShare',
  UserContributionYTDShare = 'userContributionYTDShare',
  TeamContributionYTDShare = 'teamContributionYTDShare',
  CurrentTargetShare = 'currentTargetShare',
}

export interface MetricsProgressSeries {
  label: string;
  seriesValueLabel: string;
  seriesColor: string;
  labelColor?: string;
}

export interface MetricsProgressDataValues {
  userContributionYTD: number;
  teamContributionYTD: number;
  totalTarget?: number;
  currentTarget?: number;
}

export interface MetricsProgressShareValues {
  userContributionYTDShare: number;
  teamContributionYTDShare: number;
  totalTargetShare: number;
  currentTargetShare: number;
}

export interface MetricsProgressOverflowValues {
  userContributionYTD: boolean;
  teamContributionYTD: boolean;
}

export interface MetricsProgressMetaInfo {
  isCustom: boolean;
  isDecimal: boolean;
  withCurrency: boolean;
}

export interface MetricsProgressChartRecord extends MetricsProgressDataValues, MetricsProgressShareValues {
  category: string;
  total: number;
  overflow: MetricsProgressOverflowValues;
  metaInfo: MetricsProgressMetaInfo;
  productName: string;
  metricName: string;
}

export interface MetricsProgressDataItem {
  metricId: string;
  productName: string;
  metricName: string;
  values: MetricsProgressDataValues;
  metaInfo: MetricsProgressMetaInfo;
}
