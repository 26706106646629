<div class="save-filter-dialog">
  <header mat-dialog-title>{{ data?.title }}</header>
  <div mat-dialog-content class="dialog-info">
    <div *ngIf="activeState === dialogState.Save || activeState === dialogState.Edit" class="holder">
      <form [formGroup]="editFilterForm" class="filter-form">
        <div class="input-holder">
          <mat-form-field appearance="outline" class="outline p-form-field-outline no-validate">
            <mat-label>Filter name</mat-label>
            <input matInput type="text" maxlength="40" placeholder="Filter Name" formControlName="name">
          </mat-form-field>
          <span class="hint">Max name length is 40 symbols</span>
        </div>
        <mat-checkbox
          color="primary"
          class="pl-mat-checkbox primary-ripple"
          formControlName="isPublic"
          [disableRipple]="true">
          Share this filter with my team
        </mat-checkbox>
        <div class="checkbox-holder">
          <mat-checkbox
            color="primary"
            class="pl-mat-checkbox primary-ripple"
            formControlName="isFavourite"
            [disableRipple]="true">
            Apply when I log in
          </mat-checkbox>
          <span class="hint">
            <span *ngIf="activeState === dialogState.Save">{{ applyCheckboxHintMessage }}</span>
            Currently applied: {{ favouriteFilter?.name || 'none' }}
          </span>
        </div>
      </form>
    </div>

    <div *ngIf="activeState === dialogState.Select" class="table-holder">
      <table class="saved-filters">
        <thead>
        <tr class="saved-filters-headers table-headers-row">
          <th>Filter name</th>
          <th>Owner</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let filterSet of data.context.savedFilters">
            <tr *ngIf="data?.context.companyUsers[filterSet.ownerId]" class="table-data-row">
              <td>
                <mat-radio-button
                  color="primary"
                  name="activeFilterSet"
                  [checked]="filterSet.id === selectedFilterId"
                  [value]="filterSet.id"
                  (click)="selectedFilterId = filterSet.id">
                  {{ filterSet.name }}
                </mat-radio-button>
              </td>
              <td>
                <span getInitials [fullName]="data?.context.companyUsers[filterSet.ownerId]"></span>
              </td>
              <td>
                <button
                  class="edit-btn"
                  [matTooltip]="'Edit filter'"
                  [matTooltipShowDelay]="500"
                  matTooltipClass="dark-theme-tooltip above"
                  matTooltipPosition="above"
                  (click)="editFilter(filterSet)">
                  <fa-icon [icon]="['far', 'pen']"></fa-icon>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div mat-dialog-actions align="end" class="dialog-actions">
    <button
      *ngIf="activeState === dialogState.Edit"
      class="delete-btn"
      type="button"
      mat-stroked-button
      mat-dialog-close
      color="primary"
      [disabled]="!isFilterOwner"
      (click)="removeFilterSet()">
      Delete Filter
    </button>
    <button type="button" mat-dialog-close mat-button color="primary">
      {{ data?.cancelAction.label }}
    </button>
    <button *ngIf="activeState === dialogState.Save || activeState === dialogState.Edit" type="button" mat-flat-button color="primary"
      [disabled]="!editFilterForm.valid"
      (click)="onSubmit($event)">
      {{ data?.submitAction.label }}
    </button>
    <button *ngIf="activeState === dialogState.Select" type="button" mat-flat-button color="primary"
      [disabled]="!selectedFilterId"
      (click)="applySelectedFilterSet()">
      {{ data?.submitAction.label }}
    </button>
  </div>
</div>
