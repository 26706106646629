import { SortByLabel, SortByValue } from '../types/expense-page.type';
import { MenuPanelItem } from '../../header-navigation/components/menu-panel/menu-panel.type';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { BehaviorSubject } from 'rxjs';

export class SpendingPageSortByBaseService {
  private _sortByField = new BehaviorSubject<string>(null);
  public sortByField$ = this._sortByField.asObservable();

  protected menuValues: Partial<Record<SortByLabel, SortByValue>>;
  protected menuItems: MenuPanelItem[] = [];
  private activeSortFields: string;
  private _isStatuslessExpenses;

  protected readonly STORAGE_KEY: string;
  protected DEFAULT_SORT_FIELDS: Partial<Record<SortByLabel, SortByValue>>;

  public get sortByParams(): string {
    return this.activeSortFields || this.getStoredSortFields();
  }

  public get sortByFieldValue(): string {
    return this._sortByField.getValue();
  }

  public prepareSortByMenuItems(): MenuPanelItem[] {
    this.menuItems = [];
    const sortParamsFromStorage = LocalStorageService.getFromStorage(this.STORAGE_KEY);
    const checkStoredFields = (storageParams: any, key: string) => {
      if (!storageParams) {
        return false;
      }
      return storageParams[key] != null;
    };

    for (const [key, value] of Object.entries(this.menuValues)) {
      this.menuItems.push({
        label: key,
        faIcon: null,
        isSelected: checkStoredFields(sortParamsFromStorage, key),
        value: value,
        action: (item: MenuPanelItem) => this.onHandleSortClick(item),
        selectIcon: ['far', 'check']
      });
    }
    if (this._isStatuslessExpenses !== undefined) {
      this.updateItemsForStatuslessChanges(this._isStatuslessExpenses);
    }
    return this.menuItems;
  }

  public updateItemsForStatuslessChanges(isStatusless: boolean): void {
    this._isStatuslessExpenses = isStatusless;
    const targetValues = [SortByValue.Status, SortByValue.PlannedAmount];
    const sortItems = this.menuItems.filter(item => targetValues.includes(item.value as SortByValue));
    sortItems.forEach(sortItem => {
      sortItem.hidden = this._isStatuslessExpenses;
    });
  }

  protected onHandleSortClick(menuItem: MenuPanelItem): void {
    if (menuItem.isSelected) {
      return;
    }
    const isDirectionItem = menuItem.label === SortByLabel.Ascending || menuItem.label === SortByLabel.Descending;
    this.unselectItems(isDirectionItem);
    menuItem.isSelected = !menuItem.isSelected;
    this.saveLastSortParams(this.menuItems);
    this.sendOrderingParam(this.menuItems);
  }

  private sendOrderingParam(items: MenuPanelItem[]): void {
    let ordering = '';
    items.forEach(item => {
      if (item.isSelected) {
        ordering += item.value;
      }
    });
    this.activeSortFields = ordering;
    this._sortByField.next(ordering);
  }

  private unselectItems(isDirection: boolean): void {
    const directionSortItems: string[] = [SortByLabel.Ascending, SortByLabel.Descending];
    if (isDirection) {
      this.menuItems.filter(item => directionSortItems.includes(item.label)).forEach(item => item.isSelected = false);
      return;
    }
    this.menuItems.filter(item => !directionSortItems.includes(item.label)).forEach(item => item.isSelected = false);
  }

  protected saveDefaultSortFields(): void {
    let sortFields = LocalStorageService.getFromStorage(this.STORAGE_KEY);
    if (!sortFields) {
      sortFields = this.DEFAULT_SORT_FIELDS;
      LocalStorageService.addToStorage(this.STORAGE_KEY, sortFields);
    }
    this._sortByField.next(Object.values(sortFields).join(''));
  }

  private saveLastSortParams(items: MenuPanelItem[]): void {
    const selectedFields = {};
    items.filter(item => item.isSelected).forEach(item => (selectedFields[item.label] = item.value));
    LocalStorageService.addToStorage(this.STORAGE_KEY, selectedFields);
  }

  private getStoredSortFields(): string {
    const sortFields = LocalStorageService.getFromStorage(this.STORAGE_KEY);
    if (sortFields) {
      return Object.values(sortFields).join('');
    }
    return SortByValue.Descending + SortByValue.UpdatedDate;
  }
}
