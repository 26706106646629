import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import {
  CampaignBrief,
  CampaignMappingData,
  IntegrationChildrenMappingState,
  IntegrationMappingState,
  MetricIntegrationName
} from '../../types/metric-integration';
import { ExternalCampaign } from '../../types/external-campaign.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RemapCampaignsContext, RemapCampaignsDialogComponent } from '../remaping-campaigns-dialog/remap-campaigns-dialog.component';
import { merge, Observable, of, Subject } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { compareExternalCampaigns, getChildrenMappingState, updateChildrenMappingStates } from '../metric-mapping-dialog/utils';
import { HierarchySelectConfig, HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayPositionBuilder, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { HierarchySingleSelectComponent } from '@shared/components/hierarchy-select/hierarchy-single-select/hierarchy-single-select.component';
import { findItemInHierarchy } from '@shared/components/hierarchy-select/hierarchy-select.utils';
import { takeUntil } from 'rxjs/operators';

/** Flat node with expandable and level information */
interface FlatNode {
  level: number;
  expandable: boolean;
  data: ExternalCampaign;
}

enum SwitcherTitles {
  MAPPED = 'Mapped',
  NOT_MAPPED = 'Not Mapped',
  PARTIALLY_MAPPED = 'Partially Mapped',
  MAPPED_ELSEWHERE = 'Mapped Elsewhere',
}

const MAX_NUMBER_TO_TOGGLE_ALL = 200;

@Component({
  selector: 'metric-mapping-management',
  templateUrl: './metric-mapping-management.component.html',
  styleUrls: ['./metric-mapping-management.component.scss']
})
export class MetricMappingManagementComponent implements OnChanges, OnDestroy {
  @Input() integrationCampaigns: ExternalCampaign[] = [];
  @Input() placeholderText = '';
  @Input() readOnly = true;
  @Input() integration: { name: MetricIntegrationName, label: string };
  @Input() campaignName: string;
  @Input() budgetMappingMode = false;
  @Input() locationItems: HierarchySelectItem[];
  @Input() locationNamesMap: Record<number, string> = {};
  @Output() updateMappingState = new EventEmitter<ExternalCampaign[]>();
  @Output() navigateToCampaign = new EventEmitter<number>();
  @Output() updateMappingPair = new EventEmitter<CampaignMappingData>();

  MetricIntegrationName = MetricIntegrationName;
  switchAllValue = false;
  treeControl = new FlatTreeControl<FlatNode>(node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    MetricMappingManagementComponent._transformer,
    node => node.level, node => node.expandable, node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  dataSourceLoaded = false;
  IntegrationMappingState = IntegrationMappingState;
  IntegrationChildrenMappingState = IntegrationChildrenMappingState;
  ableToSelectAll = false;
  protected hierarchySelectConfig: HierarchySelectConfig = {
    withSearch: true,
    allGroups: false,
    searchPlaceholder: 'Search Campaign',
  };
  private selectOpenedForNode: string;
  private overlayRef: OverlayRef;
  private closeHierarchySelect$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  private static _transformer = (node: ExternalCampaign, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      data: node,
      level: level,
    };
  };

  @Input() set sorting(sortValue) {
    if (!this.dataSource.data) {
      return;
    }
    this.dataSource.data = [...this.dataSource.data.sort(compareExternalCampaigns(sortValue))];
  }

  constructor(
    public dialog: MatDialog,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly overlay: Overlay,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.integrationCampaigns && changes.integrationCampaigns) {
      if (!this.dataSourceLoaded) {
        this.dataSourceLoaded = true;
      }
      this.dataSource.data = this.integrationCampaigns.map(campaign => this.calcEmbeddedCampaigns(campaign));

      if (!this.budgetMappingMode) {
        this.ableToSelectAll = this.integrationCampaigns.length < MAX_NUMBER_TO_TOGGLE_ALL;
        this.setSwitchAllValue(this.defineSwitchAllValue());
        this.dataSource.data.forEach(camp => {
          this.updateSwitcherTitles(camp);
        });
      }
    }
  }

  calcEmbeddedCampaigns = (obj: ExternalCampaign) => {
    const getEmbeddedCampaignsCount = (children: ExternalCampaign[]) => {
       return children.length + children.reduce((sum, item) => {
          return sum + item.embeddedCampaignsCount;
        }, 0);
    }

    if (obj.children && obj.children.length) {
      const embeddedCampaigns = obj.children.map(el => this.calcEmbeddedCampaigns(el));
      return {
        ...obj,
        children: embeddedCampaigns,
        embeddedCampaignsCount: getEmbeddedCampaignsCount(embeddedCampaigns)
      }
    } else {
      return { ...obj, embeddedCampaignsCount: 0 }
    }
  }

  checkExistingMappings(event: MatSlideToggleChange, node?: FlatNode) {
    const config = event.checked ? this.getRemapConfig(node?.data) : null;
    this.createRemapSubscription(config).subscribe(confirmed => {
      if (confirmed) {
        this.handleSwitchChange(event.checked, node);
        return;
      }
      event.source.checked = false;
    })
  }

  getRemapConfig(campaign: ExternalCampaign): RemapCampaignsContext {
    const itemsForCheck = campaign ? [campaign] : this.integrationCampaigns;
    const existingMappingStore = {
      campaignsInternal: [],
      campaignsExternal: [],
      metrics: [],
    };

    const updateExistingMappingStore = (extCampaign) => {
      if (!extCampaign.existingMapping || extCampaign.mappingState === IntegrationMappingState.Mapped) {
        return
      }
      existingMappingStore.campaignsExternal.push(extCampaign.name);

      extCampaign.metrics.forEach(campaignMetric => {
        const storedMetric = existingMappingStore.metrics.find(metric => metric.title === campaignMetric.title);
        if (storedMetric) {
          storedMetric.value += campaignMetric.value;
        } else {
          existingMappingStore.metrics.push({...campaignMetric});
        }
      })

      const storedInternal = existingMappingStore.campaignsInternal.find(stored => stored.id === extCampaign.existingMapping.id);
      if (!storedInternal) {
        existingMappingStore.campaignsInternal.push(extCampaign.existingMapping);
      }
    }

    const updateWithAllChildren = (itemForCheck) => {
      updateExistingMappingStore(itemForCheck);
      if (itemForCheck.children.length) {
        itemForCheck.children.forEach(childCampaign => {
          updateWithAllChildren(childCampaign);
        })
      }
    }

    itemsForCheck.forEach(itemForCheck => {
      updateWithAllChildren(itemForCheck);
    })

    if (!existingMappingStore.campaignsExternal.length) {
      return null;
    }

    const configData: RemapCampaignsContext = {
      isMultiple: existingMappingStore.campaignsExternal.length > 1,
      integrationType: this.integration.name,
      campaignTargetName: this.campaignName,
      campaignExternalName: existingMappingStore.campaignsExternal.length === 1
        ? existingMappingStore.campaignsExternal[0]
        : existingMappingStore.campaignsExternal.length + ' ' + this.integration.label + ' Campaigns',
      campaignCurrentName: existingMappingStore.campaignsInternal.length === 1
        ? existingMappingStore.campaignsInternal[0].name
        : existingMappingStore.campaignsInternal.length + ' Planful Campaigns',
      metrics: existingMappingStore.metrics,
    };
    return configData;
  }

  createRemapSubscription(configData: RemapCampaignsContext): Observable<boolean> {
    if (!configData) {
      return of(true);
    }
    const config: MatDialogConfig = {
      width: '620px',
      autoFocus: false,
      restoreFocus: false,
      data: configData,
    };
    const dialogRef = this.dialog.open(RemapCampaignsDialogComponent, config);
    return dialogRef.afterClosed();
  }

  handleSwitchChange(checked, node?: FlatNode) {
    const updateAll = !node && this.integrationCampaigns.length;
    if (!node && !updateAll) {
      return;
    }
    const state = checked ? IntegrationMappingState.Mapped : IntegrationMappingState.NotMapped;
    if (node) {
      const topLevelNode = this.getTopLevelCampaign(node);
      this.setMappingState(state, node);
      this.updateChildrenMappingState(topLevelNode.data);
      this.updateSwitcherTitles(topLevelNode.data);
    } else {
      this.treeControl.dataNodes.forEach(
        nodeEl => {
          this.setMappingState(state, nodeEl);
          this.updateChildrenMappingState(nodeEl.data);
          this.updateSwitcherTitles(nodeEl.data);
        }
      )
    }

    this.updateMappingState.emit(this.dataSource.data);
    if (this.switchAllValue && !checked) {
      this.setSwitchAllValue(false);
    } else if (checked) {
      this.setSwitchAllValue(this.defineSwitchAllValue())
    }
  }

  private static updateMappingChildrenState(dataNodes, targetId) {
    const parent = dataNodes.find(item => item.data.id === targetId)?.data;
    if (!parent) {
      // parent was filtered out
      return;
    }
    const children = dataNodes
      .filter(item => item.data.parentId === targetId)
      .map(item => item.data);
    updateChildrenMappingStates(parent, children);
    if (parent.parentId) {
      MetricMappingManagementComponent.updateMappingChildrenState(dataNodes, parent.parentId);
    }
  }

  private static getUnmappedChildren(childrenList: ExternalCampaign[]): ExternalCampaign[] {
    return childrenList.reduce((store, child) => {
      if (!child.existingMapping) {
        store.push(child);
      }
      if (child.children.length) {
        store.push(...MetricMappingManagementComponent.getUnmappedChildren(child.children));
      }
      return store;
    }, []);
  }

  private closeHierarchySelect(): void {
    this.closeHierarchySelect$.next();
    this.selectOpenedForNode = null;
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.detach();
    this.overlayRef?.dispose();
    this.overlayRef = null;
  }

  private createPositionStrategy(elementRef: HTMLElement): PositionStrategy {
    return this.overlayPositionBuilder
      .flexibleConnectedTo(elementRef)
      .withPositions([{
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
      }]);
  }

  protected getSelectTriggerTitle(node: FlatNode): string {
    if (node.data.existingMapping) {
      return this.locationNamesMap[node.data.existingMapping as number] || '';
    }
    return node.data.mappingChildrenState === IntegrationChildrenMappingState.MappedSome ? 'Partially Mapped' : 'Unmapped';
  }

  protected setActiveNodeSelection(node: FlatNode, event: MouseEvent): void {
    this.closeHierarchySelect();
    if (this.readOnly) {
      return;
    }
    this.overlayRef = this.overlay.create({
      positionStrategy: this.createPositionStrategy(event.target as HTMLElement),
    });

    this.selectOpenedForNode = node.data.id;
    const hierarchySelectPortal = new ComponentPortal(HierarchySingleSelectComponent);
    const hierarchySelectRef: ComponentRef<HierarchySingleSelectComponent> = this.overlayRef.attach(hierarchySelectPortal);

    this.setSelectRefProperties(hierarchySelectRef, node.data.existingMapping as number);
    this.subscribeToSelectRefEvents(hierarchySelectRef, node.data);
  }

  private setSelectRefProperties(selectRef: ComponentRef<HierarchySingleSelectComponent>, existingMapping: number): void {
    const selectOptionId = existingMapping ? 'Campaign_' + existingMapping : null;
    const selectOption = findItemInHierarchy(selectOptionId, this.locationItems);
    selectRef.instance.optionList = this.locationItems;
    selectRef.instance.selectedOption = selectOption;
    selectRef.instance.allowGroupSelection = true;
    selectRef.instance.showBackdrop = true;
    selectRef.instance.allowUnselect = false;
    selectRef.instance.selectConfig = this.hierarchySelectConfig;
    selectRef.instance.panelCssClass = 'pseudo-select';
  }

  private subscribeToSelectRefEvents(selectRef: ComponentRef<HierarchySingleSelectComponent>, externalCampaign: ExternalCampaign): void {
    selectRef.instance.backdropClick.pipe(
      takeUntil(merge(this.destroy$, this.closeHierarchySelect$))
    ).subscribe(() => {
      this.closeHierarchySelect();
    });

    selectRef.instance.selectOption.pipe(
      takeUntil(merge(this.destroy$, this.closeHierarchySelect$))
    ).subscribe(option => {
      this.closeHierarchySelect();
      this.handleCampaignSelectionChange(externalCampaign, option);
    });
  }

  private handleCampaignSelectionChange(campaign: ExternalCampaign, selected: HierarchySelectItem) {
    const internalId = selected ? selected.objectId : null;
    const unmappedChildren = MetricMappingManagementComponent.getUnmappedChildren(campaign.children);
    const unmappedChildrenIds = unmappedChildren.map(child => child.id);

    if (unmappedChildren.length && internalId) {
      // children extend only mapping (and ignore unmapping)
      unmappedChildren.forEach(child => {
        child.existingMapping = internalId;
        child.mappingState = IntegrationMappingState.Mapped;
      });
    }

    campaign.existingMapping = internalId;
    campaign.mappingState = internalId ? IntegrationMappingState.Mapped : IntegrationMappingState.NotMapped;
    if (campaign.parentId) {
      MetricMappingManagementComponent.updateMappingChildrenState(this.treeControl.dataNodes, campaign.parentId);
    }
    this.updateMappingPair.emit({
      externalId: campaign.id,
      internalId,
      unmappedChildrenIds,
    });
  }

  setMappingState(state: IntegrationMappingState, node: FlatNode) {
    node.data.mappingState = state;
    const childNodes = this.treeControl.getDescendants(node);
    if (childNodes.length) {
      childNodes.forEach(childNode => this.setMappingState(state, childNode));
    }
  }

  updateChildrenMappingState(campaign: ExternalCampaign) {
    campaign.mappingChildrenState = getChildrenMappingState(campaign, this.updateChildrenMappingState.bind(this));
    return {
      existingMapping: campaign.existingMapping,
      mappingState: campaign.mappingState,
      mappingChildrenState: campaign.mappingChildrenState,
    };
  }

  getTopLevelCampaign(node: FlatNode) {
    if (node.level === 0) {
      return node;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level === 0) {
        return currentNode;
      }
    }
  }

  trackTreeNode(index: number, node: FlatNode) {
    return node.data.id;
  }

  defineSwitchAllValue() {
    return this.ableToSelectAll && !this.treeControl.dataNodes.some(
      node => node.data.mappingState !== IntegrationMappingState.Mapped ||
              node.data.mappingChildrenState !== IntegrationChildrenMappingState.MappedAll
    )
  }

  setSwitchAllValue(value) {
    this.switchAllValue = value;
  }

  updateSwitcherTitles(campaign: ExternalCampaign) {
    campaign.switcherTitle = this.getSwitcherTitle(campaign);
    if (campaign.children.length) {
      campaign.children.forEach(childCampaign => this.updateSwitcherTitles(childCampaign))
    }
  }

  getSwitcherTitle(nodeData: ExternalCampaign) {
    if (!nodeData.children.length) {
      return nodeData.mappingState === IntegrationMappingState.Mapped ? SwitcherTitles.MAPPED : nodeData.existingMapping ? SwitcherTitles.MAPPED_ELSEWHERE : SwitcherTitles.NOT_MAPPED
    }

    // has children and mapped
    if (nodeData.mappingState === IntegrationMappingState.Mapped) {
      return nodeData.mappingChildrenState === IntegrationChildrenMappingState.MappedAll ? SwitcherTitles.MAPPED : SwitcherTitles.PARTIALLY_MAPPED;
    }

    // has children and NOT mapped
    if (!nodeData.existingMapping) {
      return nodeData.mappingChildrenState === IntegrationChildrenMappingState.NotMapped ? SwitcherTitles.NOT_MAPPED : SwitcherTitles.PARTIALLY_MAPPED;
    }

    // has children, NOT mapped, has existingMapping
    return nodeData.mappingChildrenState === IntegrationChildrenMappingState.MappedSome ? SwitcherTitles.PARTIALLY_MAPPED : SwitcherTitles.MAPPED_ELSEWHERE;
  }

  openCampaign(e: MouseEvent, data: ExternalCampaign) {
    e.preventDefault();
    const existingMapping = data.existingMapping as CampaignBrief;
    if (existingMapping?.id) {
      this.navigateToCampaign.emit(existingMapping.id);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
