import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'segmentsTooltip'
})
export class SegmentsTooltipPipe implements PipeTransform {
  transform(segmentsArr): string {
    const MAX_TOOLTIP_LENGTH = 65;
    if (!segmentsArr) {
      return '';
    }
    const str = segmentsArr.map(s => s.name).join(', ');
    return str.length > MAX_TOOLTIP_LENGTH ? str.substring(0, MAX_TOOLTIP_LENGTH) + '...' : str;
  }
}
