export const utClasses = {
  utPlanned: 'ut-planned',
  utActual: 'ut-actual',
  utStatus: 'ut-status',
  utTimeframe: 'ut-timeframe',
  utSegment: 'ut-segment',
  utParent: 'ut-parent',
  utGl: 'ut-gl',
  utVendor: 'ut-vendor',
  utRelated: 'ut-related',
}
