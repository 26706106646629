import { ManageTableDataService } from './manage-table-data.service';
import { Injectable, OnDestroy } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ManageTableFullRowValues } from '../components/manage-table/manage-table.types';
import { ManagePageService } from './manage-page.service';
import { ManageTableViewMode } from '../types/manage-table-view-mode.type';
import { ManagePageModeService } from './manage-page-mode.service';
import { ManageTableDataMode } from '../types/manage-table-data-mode.type';
import { SpendingModeFlag } from '../types/spending-mode-flags.type';
import { BudgetSummaryBarHelpers } from '@shared/components/budget-summary-bar/budget-summary-bar.helpers';
import { SummaryBarCalculationItem, SummaryBarCalculationItemList } from '@shared/components/budget-summary-bar/budget-summary-bar.types';

@Injectable()
export class MiniDashCalculationService implements OnDestroy {
  private confetti = {
    trigger: new Subject<void>(),
    options: { spread: 110, decay: .85 },
  };
  private calculationItemsDefault: SummaryBarCalculationItemList = BudgetSummaryBarHelpers.summaryBarDefaultCalculationItems;
  private _calculationItems: SummaryBarCalculationItemList = {
    ...this.calculationItemsDefault,
    available: {
      ...this.calculationItemsDefault.available,
      confetti: this.confetti
    }
  };
  public readonly calculationItems: SummaryBarCalculationItem[] = Object.values(this._calculationItems);
  private lastCalculatedRemaining: number;
  private destroy$ = new Subject<void>();

  constructor(
    private readonly dataService: ManageTableDataService,
    private readonly managePageModeService: ManagePageModeService,
    private readonly managePageService: ManagePageService,
  ) {
    merge(
      this.managePageModeService.updateMiniDashData$,
      this.dataService.grandTotal$
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.recalculateValues();
    });
  }

  private recalculateValues(): void {
    const grandTotal: ManageTableFullRowValues = this.dataService.grandTotal;
    if (!grandTotal) {
      return;
    }
    const allocated = this.calcAllocated(grandTotal);
    const expenses = this.calcExpenses(grandTotal);
    const remainingAllocated = this.calcRemainingAllocated(grandTotal);

    const remaining = BudgetSummaryBarHelpers.calcRemaining(allocated, expenses, remainingAllocated);
    if (this.showAnimation(remaining)) {
      this.confetti.trigger.next();
    }
    this.lastCalculatedRemaining = remaining;

    BudgetSummaryBarHelpers.updateCalculationItems(this._calculationItems, {
      allocated,
      expenses,
      remainingAllocated,
      remaining,
      isSegmentView: this.isSegmentView,
      companyCurrencyCode: this.managePageService.budgetCurrency.code,
    });
  }

  private calcAllocated(grandTotal: ManageTableFullRowValues): number {
    return this.isSegmentView ? grandTotal.segment.spending?.allocated : grandTotal.total.allocated;
  }

  private calcExpenses(grandTotal: ManageTableFullRowValues): number {
    let val;
    if (this.isSegmentView) {
      const segmentExpenses = grandTotal.segment.spending;
      const planned = this.includePlannedInTotal ? segmentExpenses.planned : 0
      val = segmentExpenses.closed + segmentExpenses.committed + planned;
    } else {
      val = this.includePlannedInTotal ? grandTotal.spending.totalExpensesWithPlanned : grandTotal.spending.totalExpenses;
    }
    return val;
  }

  private calcRemainingAllocated(grandTotal: ManageTableFullRowValues): number {
    const totalRemainingWithPlanned = this.isSegmentView ?
      grandTotal.segment.spending?.allocatedToChildren :
      grandTotal.spending.allocatedToChildren;
    const spendingPlanned = (this.isSegmentView ?
      grandTotal.segment.spending?.planned :
      grandTotal.spending?.planned) || 0;

    return this.includePlannedInTotal || spendingPlanned <= 0 ?
      totalRemainingWithPlanned :
      totalRemainingWithPlanned + spendingPlanned;
  }

  private get isSegmentView(): boolean {
    return this.managePageModeService.viewMode === ManageTableViewMode.Segments;
  }

  private get includePlannedInTotal(): boolean {
    // for "Allocations Mode" planned should be also included in total
    return this.managePageModeService.spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal] ||
      this.managePageModeService.dataMode !== ManageTableDataMode.Spending;
  }

  private showAnimation(newRemaining: number): boolean {
    return !this.managePageService.summaryBarLoading$.getValue() &&
      ((this.lastCalculatedRemaining < 0 && newRemaining > 0) || (this.lastCalculatedRemaining !== 0 && newRemaining === 0));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
