import { padStart } from './common.utils';

const ERROR_MESSAGE = {
  FAILED_TO_PARSE_TIME: 'Failed to parse selected time'
};

export interface ParsedTime {
  hours: number;
  minutes: number;
}

export const getTotalMinutes = (data: ParsedTime): number => {
  return data.hours * 60 + data.minutes;
};

export const getParsedTime = (totalMinutes: number): ParsedTime => {
  return {
    hours: Math.floor(totalMinutes / 60) % 24,
    minutes: totalMinutes % 60
  }
};

/**
 * Convert 24H (cron) formatted time string to 12H formatted
 * '1345' -> '01:45 PM'
 */
export const convertTo12H = (timeString: string, localMinutesOffset = 0): string => {
  const pattern = /^(\d{2})(\d{2})$/;
  const match = timeString.match(pattern);
  if (!Array.isArray(match)) {
    throw new Error(ERROR_MESSAGE.FAILED_TO_PARSE_TIME);
  }

  const minutes = parseInt(match[2], 10);
  const hours = parseInt(match[1], 10);
  const UTC = convertFromUTC(hours, minutes, localMinutesOffset);

  const hoursString = padStart((UTC.hours % 12).toString(10), 2);
  const minutesString = padStart(UTC.minutes.toString(10), 2);

  return `${hoursString}:${minutesString} ${UTC.hours < 12 ? 'AM' : 'PM'}`;
};

/**
 * Convert 12H formatted time string to 24H (cron) formatted
 * '01:45 PM' -> '1345'
 */
export const convertTo24H = (timeString: string, localMinutesOffset = 0): string => {
  const pattern = /^(\d{1,2}):(\d{2})\s(AM|PM)$/;
  const match = timeString.match(pattern);
  if (!Array.isArray(match)) {
    throw new Error(ERROR_MESSAGE.FAILED_TO_PARSE_TIME);
  }

  const mode = match[3];
  const minutes = parseInt(match[2], 10);
  let hours = parseInt(match[1], 10);

  if (mode === 'AM' && hours === 12) {
    hours = 0;
  }
  if (mode === 'PM') {
    hours += 12;
  }

  const UTC = convertToUTC(hours, minutes, localMinutesOffset);

  return `${padStart(UTC.hours.toString(10), 2)}${padStart(UTC.minutes.toString(10), 2)}`;
};

/**
 * Convert time to UTC based on local timezone offset (in minutes)
 */
export const convertToUTC = (hours: number, minutes: number, localMinutesOffset: number): ParsedTime => {
  const totalMinutes = getTotalMinutes({ hours, minutes });
  let totalUTCMinutes = totalMinutes - localMinutesOffset;

  if (totalUTCMinutes < 0) {
    totalUTCMinutes += 24 * 60;
  }

  return getParsedTime(totalUTCMinutes);
};

/**
 * Convert time from UTC based on local timezone offset (in minutes)
 */
export const convertFromUTC = (hours: number, minutes: number, localMinutesOffset: number): ParsedTime => {
  const totalMinutes = getTotalMinutes({ hours, minutes });
  let totalUTCMinutes = totalMinutes + localMinutesOffset;

  if (totalUTCMinutes < 0) {
    totalUTCMinutes += 24 * 60;
  }

  return getParsedTime(totalUTCMinutes);
};
