import { Injectable } from '@angular/core';
import { addMonthsToDate, getDiffInMonth, ONE_DAY_IN_MS } from '../utils/date.utils';
import { MetricsUtilsService } from '../../budget-object-details/services/metrics-utils.service';
import { MetricMilestones } from '../../budget-object-details/types/metric-milestone.interface';

// Should we move it elsewhere?
const AMCHARTS_LICENCE_CODE = 'CH240111210';

export const CHART_COLORS = {
  GRID: '#bababa',
  CURSOR: '#0063B7',
  TOOLTIP_FILL: '#061F38',
  TOOLTIP_TEXT: '#fff',
  SERIES: '#E82987'
};

interface AmchartsCoreConfig {
  autoDispose: boolean;
  queue: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AmchartsService {
  public am4core;
  public am4charts;
  public am4theme;
  public dateFormatter;
  private dateAxisFormat = 'M/d';
  private defaultCoreConfig: Partial<AmchartsCoreConfig> = {
    autoDispose: true,
    queue: false
  };

  constructor(private readonly metricsUtilsService: MetricsUtilsService) {}

  private configureCoreOptions(am4core, config: Partial<AmchartsCoreConfig>) {
    if (config.hasOwnProperty('autoDispose')) {
      am4core.options.autoDispose = config.autoDispose;
    }
    if (config.hasOwnProperty('queue')) {
      am4core.options.queue = config.queue;
    }
  }

  public importModules(config: Partial<AmchartsCoreConfig> = this.defaultCoreConfig) {
    return Promise.all([
      import('@amcharts/amcharts4/core'),
      import('@amcharts/amcharts4/charts'),
      import('@amcharts/amcharts4/themes/animated')
    ])
      .then(modules => {
        this.am4core = modules[0];
        this.am4charts = modules[1];
        this.am4theme = modules[2].default;
        this.am4core.addLicense(AMCHARTS_LICENCE_CODE);
        this.am4core.useTheme(this.am4theme);
        this.dateFormatter = new this.am4core.DateFormatter();
        this.configureCoreOptions(this.am4core, config);

        return {
          am4core: this.am4core,
          am4charts: this.am4charts,
          am4theme: this.am4theme,
        };
      })
      .catch((err) => {
        console.error('Failed to load amCharts modules', err);
        return null;
      });
  }

  public configureChart(chart) {
    chart.padding = 0;
    chart.seriesContainer.zIndex = -1;
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;
    chart.dragGrip.disabled = false;
    chart.maxZoomLevel = 1;
    chart.maxZoomFactor = 1;
    chart.zoomOutButton.disabled = true;
  }

  public configureTicks(ticksSprite, vertical = false) {
    if (!this.am4core) {
      console.error('Failed to configure amCharts ticks');
      return;
    }

    ticksSprite.stroke = this.am4core.color(CHART_COLORS.GRID);
    ticksSprite.disabled = false;
    ticksSprite.strokeOpacity = 0.7;
    ticksSprite.strokeWidth = 1;
    ticksSprite.length = 6;
    ticksSprite.zIndex = 1;
    if (vertical) {
      ticksSprite.dy = -3;
    } else {
      ticksSprite.dx = 3;
    }
  }

  public configureTooltip(series, orientation = 'down', hideForZero = false) {
    if (!this.am4core) {
      console.error('Failed to configure amCharts tooltip');
      return;
    }

    series.tooltip.background.fill = this.am4core.color(CHART_COLORS.TOOLTIP_FILL);
    series.tooltip.label.fill = this.am4core.color(CHART_COLORS.TOOLTIP_TEXT);
    series.tooltip.getFillFromObject = false;
    series.tooltip.pointerOrientation = orientation;
    series.tooltip.label.fontSize = 10;
    series.tooltip.label.textAlign = 'middle';
    series.tooltip.background.cornerRadius = 0;
    series.tooltip.background.strokeWidth = 0;
    series.tooltip.dx = -1;
    if (hideForZero) {
      series.tooltip.label.adapter.add('text', (text, target) => {
        if (target.dataItem && target.dataItem.valueY === 0) {
          return '';
        }
        return text;
      });
    }
  }

  public configureTrendLine(trendLine) {
    if (!this.am4core) {
      console.error('Failed to configure amCharts trend line');
      return;
    }

    trendLine.strokeWidth = 2;
    trendLine.strokeDasharray = '5,5';
    trendLine.hiddenInLegend = true;
    trendLine.stroke = trendLine.fill = this.am4core.color(CHART_COLORS.GRID);
  }

  public configureMainSeries(series) {
    if (!this.am4core) {
      console.error('Failed to configure amCharts main series');
      return;
    }

    series.dataFields.dateX = 'timestamp';
    series.dataFields.valueY = 'value';
    series.strokeWidth = 4;
    series.stroke = this.am4core.color(CHART_COLORS.SERIES);
    series.fill = this.am4core.color(CHART_COLORS.SERIES);
    series.strokeLinecap = 'round';
    series.strokeLinejoin = 'round';
    series.zIndex = 10;
  }

  public createBullet(series, radius = 3) {
    if (!this.am4charts) {
      console.error('Failed to create amCharts bullet');
      return;
    }
    const bullet = new this.am4charts.CircleBullet();

    bullet.strokeWidth = 0;
    bullet.circle.radius = radius;
    bullet.align = 'center';
    bullet.valign = 'middle';
    bullet.circle.fill = series.stroke;
    bullet.zIndex = 0;
    bullet.tooltipText = `[bold]{valueY} on {dateX}`;
    // Hide bullet for zero values
    bullet.circle.adapter.add('radius', (radius, target) => {
      const values = target.dataItem.values;
      const currentValue = values.valueY.value;
      return currentValue > 0 ? radius : 0;
    });

    return bullet;
  }

  public createCursor(series = null) {
    if (!this.am4charts || !this.am4core) {
      console.error('Failed to create amCharts cursor');
      return;
    }
    const cursor = new this.am4charts.XYCursor();

    cursor.fullWidthLineX = true;
    cursor.lineY.disabled = true;
    cursor.lineX.strokeWidth = 12;
    cursor.lineX.fillOpacity = 0;
    cursor.lineX.strokeOpacity = 0.1;
    cursor.lineX.stroke = this.am4core.color(CHART_COLORS.CURSOR);
    cursor.lineX.strokeDasharray = '';
    cursor.behavior = 'none';

    if (series) {
      cursor.snapToSeries = series;
    }

    return cursor;
  }

  public configureDateAxis(dateAxis, startDate: Date, endDate: Date) {
    dateAxis.renderer.minGridDistance = 100;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.baseInterval = {
      timeUnit: 'day',
      count: 1
    };
    dateAxis.gridIntervals.setAll([
      { timeUnit: 'day', count: 1 },
      { timeUnit: 'month', count: 1 }
    ]);
    dateAxis.dateFormats.setKey('day', this.dateAxisFormat);
    dateAxis.dateFormats.setKey('month', this.dateAxisFormat);
    dateAxis.tooltip.disabled = true;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.disabled = true;
    if (startDate instanceof Date && endDate instanceof Date) {
      dateAxis.min = startDate.getTime();
      dateAxis.max = endDate.getTime();
      dateAxis.strictMinMax = true;
    }
  }

  public createDateAxisRanges(dateAxis, startDate: Date, endDate: Date) {
    if (!this.am4core) {
      console.error('Failed to create amCharts ranges');
      return;
    }
    const maxRangeLimit = 15;
    const diffInMonths = getDiffInMonth(startDate, endDate);
    const rangeStep = Math.max(Math.ceil(diffInMonths / maxRangeLimit), 1);
    let date = startDate;
    let index = 0;

    while (date < endDate) {
      const range = dateAxis.axisRanges.create();
      range.value = date;
      range.label.text = this.dateFormatter.format(date, this.dateAxisFormat);
      range.label.fontWeight = '600';
      if (index > 0) {
        range.grid.strokeOpacity = 0;
      }
      index += rangeStep;
      date = addMonthsToDate(startDate, index);
      this.configureTicks(range.tick, true);
    }
  }

  public configureValueAxis(valueAxis, restrictNegative = true) {
    if (restrictNegative) {
      valueAxis.min = 0;
      valueAxis.renderer.labels.template.adapter.add('text', (text, target) => {
        return target.dataItem.getValue('value') !== 0 ? text : null;
      });
      valueAxis.strictMinMax = true;
    }
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.grid.template.opacity = 0.2;
    valueAxis.renderer.labels.template.fontWeight = '600';
  }

  public addCurrencyToAxisLabels(currency: string, axis) {
    axis.renderer.labels.template.adapter.add('text', (text) => {
      return text && `${currency}${text}`;
    });
  }

  public getChartEndDate(milestones: MetricMilestones) {
    const today = new Date();
    const targetDate = this.metricsUtilsService.getEndDate(milestones);
    const targetTime = targetDate ? targetDate.getTime() : today.getTime();
    const endDateTime = Math.max(today.getTime(), targetTime);

    return new Date(endDateTime + ONE_DAY_IN_MS);
  }
}
