<div class="group-toggle-control">
  <mat-button-toggle-group [ngModel]="currentValue" (change)="handleChange($event)">
    <mat-button-toggle
      *ngFor="let option of options"
      [disableRipple]="true"
      [value]="option.value"
    >
      {{ option.name }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
