import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CurrencyService} from "../../services/backend/currency.service";
import {Currency} from "../../types/currency.interface";

@Component({
  selector: 'currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss']
})
export class CurrencySelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() baseCurrencyCode = 'USD';
  @Input() selectedCurrency;
  @Input() filteredCurrencyCodes: string[];
  @Output() selectCurrency = new EventEmitter<{currencyItem: { value: string; title: string }; selectedByKeyboard: boolean}>();

  public subscriptions: any[] = [];
  public currencyList: { value: string; title: string }[] = [];

  constructor(public currencyService: CurrencyService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.currencyService.currencyList$.subscribe(currList => this.updateCurrencyList(currList))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('baseCurrencyCode' in changes) {
      this.sortCurrencyList();
    } else if ('filteredCurrencyCodes' in changes) {
      this.filterCurrencyList();
    }
  }

  updateCurrencyList(sourceCurrencyList?: Currency[]) {
    if (sourceCurrencyList && sourceCurrencyList.length) {
      this.currencyList =
        sourceCurrencyList.map(curr => ({
          value: curr.code,
          title: `${curr.code} | ${curr.name}`,
          disabled: false
        }));
      this.filterCurrencyList();
      this.sortCurrencyList();
    }
  }

  sortCurrencyList() {
    this.currencyList =
      this.currencyList.sort((a,b) => {
        if(a.title < b.title) { return -1; }
        if(a.title > b.title) { return 1; }
        return 0;
      });
    if (this.baseCurrencyCode) {
      const baseCurrencyIndex = this.currencyList.findIndex(curr => curr.value === this.baseCurrencyCode);
      const baseCurrency = this.currencyList[baseCurrencyIndex];
      if (baseCurrency) {
        this.currencyList.splice(baseCurrencyIndex, 1);
        this.currencyList.unshift(baseCurrency);
      }
    }
  }

  filterCurrencyList() {
    if (this.filteredCurrencyCodes && this.filteredCurrencyCodes.length) {
      this.currencyList =
        this.currencyList.filter(currencyItem => this.filteredCurrencyCodes.includes(currencyItem.value));
    }
  }
}
