<div
  #tableContainer
  class="manage-table-container"
>
  <div class="top-bar">
    <table-mode-switch
      [viewMode]="viewMode"
      [dataMode]="dataMode"
      [showExpenses]="showExpenses"
      [spendingModeFlags]="spendingModeFlags"
      [allocationModeFlags]="allocationModeFlags"
      [disableExpenseToggle]="budget?.suppress_timeframe_allocations"
      (onViewModeChange)="handleViewModeChange($event)"
      (onShowExpensesChange)="handleShowExpensesChange($event)"
      (onSpendingModeFlagsChange)="handleSpendingModeFlagsChange($event)"
      (onAllocationModeFlagsChange)="handleAllocationModeFlagsChange($event)"
    ></table-mode-switch>
    <table-view-mode-switch (onShowClassicView)="switchClassicView()" ></table-view-mode-switch>
  </div>
  <div class="secondary-bar">
    <div class="actions">
      <button
        class="action-item"
        type="button"
        mat-flat-button
        color="primary"
        [disabled]="!editPermission"
        [matMenuTriggerFor]="createMenu"
      >
        Create...
      </button>
      <mat-menu #createMenu="matMenu" class="mat-pl-menu manage-plan">
        <button mat-menu-item
                *ngFor="let item of createMenuOptions"
                (click)="item.action()">
          <fa-duotone-icon *ngIf="item.icon" [icon]="item.icon"></fa-duotone-icon>
          <span>{{ item.label }}</span>
        </button>
      </mat-menu>
      <manage-plan-menu
        class="action-item"
        [campaignTypes]="campaignTypes"
        [expGroupTypes]="expGroupTypes"
      ></manage-plan-menu>
    </div>
    <div class="undo-action">
      <button
        class="undo-button"
        mat-icon-button
        [disableRipple]="true"
        [disabled]="!historySize || isDataLoading"
        (click)="handleUndoClick()"
      >
        <fa-icon [icon]="['fas', 'undo']"></fa-icon>
        Undo
      </button>
    </div>
    <div class="search">
      <mat-form-field appearance="outline" class="outline no-label no-validate">
        <input type="text" matInput placeholder="Search campaigns">
      </mat-form-field>
    </div>
  </div>
  <manage-table
    [timeframes]="timeframes"
    [budget]="budget"
    [data]="tableData"
    [dataMode]="dataMode"
    [spendingModeFlags]="spendingModeFlags"
    [allocationModeFlags]="allocationModeFlags"
    [grandTotal]="grandTotal"
    [showExpenses]="showExpenses"
    [appliedSorting]="appliedSorting"
    [isLoading]="isDataLoading"
    [isFilteredMode]="isFilteredMode"
    [hasHiddenHierarchy]="hasHiddenHierarchy"
    [segments]="segments"
    [sharedCostRules]="sharedCostRules"
    [editPermission]="editPermission"
    [isAdmin]="isAdmin"
    [performanceColumnData]="performanceColumnData"
    [segmentBreakdownAllowed]="!segmentBreakdownRestrictedByFilters"
    (onSortingChanged)="applySorting($event)"
    (onAllocationChanged)="handleAllocationChange($event)"
    (onSegmentAllocationChanged)="handleSegmentAllocationChange($event)"
    (onDoubleClick)="handleDoubleClickAction($event)"
    (onDragStart)="handleDragStartAction($event)"
    (onDrop)="handleDropAction($event)"
    (onDragEnd)="handleDragEndAction()"
    (onClone)="handleCloneAction($event)"
    (onCreateNewItemTemplate)="handleCreateNewItemTemplate($event)"
    (onCreateNewItemFromTemplate)="handleCreateNewItemFromTemplate($event)"
    (onDelete)="handleDeleteAction($event)"
    (onClose)="handleCloseAction($event)"
    (onReopen)="handleReopenAction($event)"
    (onTableWidthChange)="handleTableWidthChange($event)"
    (onMoveTo)="handleMoveToAction($event)"
  ></manage-table>
  <budget-summary-bar
    [summaryItems]="summaryBarItems"
    [calculationItems]="calculationItems"
    [summaryBarLoading$]="summaryBarLoading"
  ></budget-summary-bar>
</div>
