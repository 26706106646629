import { BehaviorSubject } from 'rxjs';
import { PDFSource } from 'ng2-pdf-viewer';

const MIN_WIDTH = 570;
const OFFSET_WIDTH = 700;
export class PdfViewerService {

  private readonly page = new BehaviorSubject<number>(1);
  private readonly src = new BehaviorSubject<PDFSource>('');
  private readonly totalPages = new BehaviorSubject<number>(0);
  private readonly isLoaded = new BehaviorSubject<boolean>(false);
  private readonly calculatedContainerWidth = new BehaviorSubject<number>(0);
  private readonly zoom = new BehaviorSubject<number>(1);
  private readonly isPageRendered = new BehaviorSubject<boolean>(false);
  private readonly maxWidth = 1200;
  private _screenWidth = 0;
  private _offsetWidth = OFFSET_WIDTH;
  private _minWidth = MIN_WIDTH;

  public readonly page$ = this.page.asObservable();
  public readonly totalPages$ = this.totalPages.asObservable();
  public readonly isLoaded$ = this.isLoaded.asObservable();
  public readonly zoom$ = this.zoom.asObservable();
  public readonly src$ = this.src.asObservable();
  public readonly calculatedContainerWidth$ = this.calculatedContainerWidth.asObservable();
  public readonly isPageRendered$ = this.isPageRendered.asObservable();

  public setPage(page: number): void {
    this.page.next(page);
  }

  public setSource (src: Partial<string>, isBase64Format: boolean): void {
    if (!src) return;
    this.isPageRendered.next(false);
    const source = isBase64Format ? this.convertBase64Data(src) : src;
    this.src.next(source);
  }

  public setTotal (total: number): void {
    this.totalPages.next(total);
  }

  public setZoom(zoom: number): void {
    this.calculateContainerWidth(this._screenWidth, zoom);
    //it should for more smooth animation transition after zoomIn/zoomOut
    setTimeout(()=> this.zoom.next(zoom), 200);
  }

  public setIsLoaded(isLoaded: boolean): void {
    this.isLoaded.next(isLoaded);
  }

  public setRenderedState(state: boolean): void {
    this.isPageRendered.next(state);
  }

  public resetProperties(): void {
    this.page.next(1);
    this.src.next('');
    this.totalPages.next(0);
    this.calculatedContainerWidth.next(0);
    this._screenWidth = 0;
    this._offsetWidth = OFFSET_WIDTH;
    this._minWidth = MIN_WIDTH;
  }

  public set screenWidth(width: number) {
    this._screenWidth = width;
  }

  public set offsetWidth(width: number) {
    this._offsetWidth = width;
  }

  public set minWidth(width: number) {
    this._offsetWidth = width;
  }

  private calculateLimit(screenWidth: number, offset: number): number {
    const offsetIsAcceptable = screenWidth - offset - this.maxWidth - 10 <= 0;
    return offsetIsAcceptable ? (screenWidth - offset - 10) : this.maxWidth;
  }

  private calculateContainerWidth(screenWidth: number, zoom: number): void {
    const limit = this.calculateLimit(screenWidth, this._offsetWidth);
    const containerWidth = zoom > 1 ? limit : this._minWidth;
    this.calculatedContainerWidth.next(containerWidth);
  }

  private convertBase64Data(value: string): PDFSource {
    return {
      data: atob(value)
    }
  }
}
