import { Injectable } from '@angular/core';
import { forkJoin, isObservable, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ActionInterceptor {
  actionType: string;
  handler: () => boolean | Observable<boolean>;
}

export type ActionInterceptorDestructor = () => void;

@Injectable({
  providedIn: 'root'
})
export class ActionInterceptorsService {
  private readonly interceptors: ActionInterceptor[] = [];

  public registerInterceptor(actionInterceptor: ActionInterceptor): ActionInterceptorDestructor {
    if (!actionInterceptor) {
      return null;
    }

    this.interceptors.push(actionInterceptor);
    return () => {
      const index = this.interceptors.indexOf(actionInterceptor);
      if (index >= 0) {
        this.interceptors.splice(index, 1);
      }
    }
  }

  public callInterceptors(actionType: string): Observable<boolean> {
    const interceptors = this.interceptors.filter(item => item.actionType === actionType);
    const interceptorCalls$ =
      interceptors.map(item => {
        if (typeof item.handler !== 'function') {
          return of(true);
        }
        const callRes = item.handler();
        return isObservable(callRes) ? callRes : of(Boolean(callRes));
      });
    return interceptorCalls$.length ?
      forkJoin(interceptorCalls$).pipe(
        map((results: Boolean[]) => results.every(res => res))
      ) :
      of(true);
  }
}
