import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeframeChoiceItem } from '@shared/components/timeframe-select/timeframe-select.types';

export interface TimeframeDialogData {
  showLegend: boolean;
  timeframes: TimeframeChoiceItem[];
}

@Component({
  selector: 'choose-timeframe-dialog',
  templateUrl: './choose-timeframe-dialog.component.html',
  styleUrls: ['./choose-timeframe-dialog.component.scss'],
})
export class ChooseTimeframeDialogComponent implements AfterViewInit {
  public selectedTimeframes: number[] = [];
  public availableForSelectionLength: number;

  private static canBeSelected(tf: TimeframeChoiceItem): boolean {
    return !tf.locked && !tf.selected;
  }

  constructor(
    public dialogRef: MatDialogRef<ChooseTimeframeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly context: TimeframeDialogData
  ) { }

  ngAfterViewInit() {
    this.availableForSelectionLength = this.context.timeframes.filter(ChooseTimeframeDialogComponent.canBeSelected).length;
  }

  public onTfSelectionChange(state: boolean, id: number, skipChanges: boolean): void {
    if (skipChanges) {
      return;
    }
    if (state) {
      this.selectedTimeframes.push(id);
    } else {
      this.selectedTimeframes = this.selectedTimeframes.filter(el => el !== id);
    }
  }

  public selectionAllChanged(state: boolean) {
    this.selectedTimeframes = !state ? [] :
      this.context.timeframes
        .filter(ChooseTimeframeDialogComponent.canBeSelected)
        .map(tf => +tf.id);
  }

  public get isAllSelected(): boolean {
    return this.availableForSelectionLength === this.selectedTimeframes.length;
  }

}
