<div class="home-page" [class.is-initializing]="isInitializing">
  <div class="home-background">
    <div class="home-container">
      <home-header [budget]="currentBudget" [isLoading]="isInitializing"></home-header>
      <div class="sections">
        <priority-actions></priority-actions>
        <plan-highlights></plan-highlights>
      </div>
    </div>
  </div>
</div>
