import { Injectable } from '@angular/core';
import { DetailsAction, DetailsActionType } from '../components/details-header/details-header.type';
import { faClone, faHourglassEnd, faHourglassStart, faListTree, faPiggyBank, faTrash } from '@fortawesome/pro-solid-svg-icons';

@Injectable()
export class BudgetObjectActionsBuilder {
  private actions;

  constructor() {
    this.actions = [];
  }

  public reset() {
    this.actions = [];

    return this;
  }

  public addCloneAction(objectType: string, handler: Function, disabled = false) {
    this.actions.push({
      name: `Duplicate`,
      handler,
      disabled,
      faIcon: faClone,
      type: DetailsActionType.CLONE
    });

    return this;
  }

  public addCloseAction(objectType: string, handler: Function, disabled = false) {
    this.actions.push({
      name: `Close`,
      handler,
      disabled,
      faIcon: faHourglassEnd,
      type: DetailsActionType.UPDATE_STATUS
    });

    return this;
  }

  public addOpenAction(objectType: string, handler: Function, disabled = false) {
    this.actions.push({
      name: `Restart`,
      handler,
      disabled,
      faIcon: faHourglassStart,
      type: DetailsActionType.UPDATE_STATUS
    });

    return this;
  }

  public addChangeBudgetAction(disabled = false) {
    // Temporary disabled by default
    this.actions.push({
      name: 'Move to Budget',
      faIcon: faPiggyBank,
      disabled,
      type: DetailsActionType.CHANGE_BUDGET
    });

    return this;
  }

  public addDeleteAction(objectType: string, handler: Function, disabled = false) {
    this.actions.push({
      name: `Delete`,
      handler,
      disabled,
      faIcon: faTrash,
      type: DetailsActionType.DELETE
    });

    return this;
  }

  public addShowParentAction(objectType: string, disabled = false): BudgetObjectActionsBuilder {
    this.actions.push({
      name: 'Show Parents',
      disabled,
      faIcon: faListTree,
      type: DetailsActionType.SHOW_PARENT,
      hasChild: true
    });

    return this;
  }

  public addShowChildAction(objectType: string, disabled = false): BudgetObjectActionsBuilder {
    this.actions.push({
      name: 'Show Children',
      disabled,
      faIcon: faListTree,
      type: DetailsActionType.SHOW_CHILD,
      hasChild: true
    });

    return this;
  }

  public getActions(): DetailsAction[] {
    return this.actions;
  }
}
