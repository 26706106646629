<svg [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 132 187.5" enable-background="new 0 0 132 187.5" xml:space="preserve">
<path [attr.fill]="color" d="M21.5,38.2c10.9,0,20.4,6.4,24.8,15.7c4-6.6,11.3-11.1,19.7-11.1l0,0h0.1c11.8,0,21.5-9.6,21.5-21.4S78,0,66.2,0h-0.1H66
	l0,0C36.9,0,12.3,18.9,3.5,45.1C8.2,40.7,14.5,38.2,21.5,38.2z"/>
  <path [attr.fill]="color" d="M93.5,21.3c0,10.9-6.4,20.4-15.7,24.8c6.6,4,11.1,11.3,11.1,19.7l0,0c0.1,11.8,9.7,21.3,21.4,21.3
	c11.8,0,21.6-9.5,21.7-21.3l0,0c0-26.7-16.3-51.8-42.8-61.7c-0.9-0.3-1.8-0.7-2.7-1C90.9,8,93.5,14.4,93.5,21.3z"/>
  <path [attr.fill]="color" d="M110.4,93.1c-10.8,0-20.2-6.3-24.7-15.5c-4,6.7-11.4,11.2-19.8,11.2c-12.7,0-23-10.3-23-23l0,0c0-0.1,0-0.1,0-0.2
	c0-11.8-9.6-21.4-21.5-21.4C9.7,44.2,0.1,53.7,0,65.4c0,0.1,0,0.2,0,0.3v0.1l0,0V166c0,11.9,9.6,21.5,21.5,21.5S43,177.9,43,166
	v-38.5c34.1,12.8,72.1-4.5,84.8-38.6c0.4-1,0.8-2.1,1.1-3.2C124.1,90.3,117.5,93.1,110.4,93.1z"/>
</svg>
