import { inject, Injectable } from '@angular/core';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import {
  BudgetObjectEventContext,
  BudgetObjectEventType,
  MainBudgetObject
} from 'app/budget-object-details/types/budget-object-event.interface';
import { sortObjectsByName } from '@shared/utils/budget.utils';
import { Configuration } from 'app/app.constants';
import { LightCampaign } from '@shared/types/campaign.interface';
import { parseDateString } from '../components/containers/campaign-details/date-operations';
import { LightProgram } from '@shared/types/program.interface';
import { Goal } from '@shared/types/goal.interface';
import { resetParentForObjects } from '../utils/object-details.utils';

@Injectable()
export class BudgetDataStateMutationService {
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly config = inject(Configuration);

  private readonly campaignListMutations = {
    [BudgetObjectEventType.Created]: data => this.onObjectAdded(this.getLightCampaign(data), this.getCampaignsContainer()),
    [BudgetObjectEventType.Updated]: data => this.onObjectUpdated(this.getLightCampaign(data), this.getCampaignsContainer()),
    [BudgetObjectEventType.Deleted]: data => this.onCampaignDeleted(data.objectId),
    [BudgetObjectEventType.Moved]: data => this.onCampaignDeleted(data.objectId)
  };

  private readonly programListMutations = {
    [BudgetObjectEventType.Created]: data => this.onObjectAdded(this.getLightProgram(data), this.getProgramsContainer()),
    [BudgetObjectEventType.Updated]: data => this.onObjectUpdated(this.getLightProgram(data), this.getProgramsContainer()),
    [BudgetObjectEventType.Deleted]: data => this.onObjectDeleted(data.objectId, this.getProgramsContainer()),
    [BudgetObjectEventType.Moved]: data => this.onObjectDeleted(data.objectId, this.getProgramsContainer()),
  };

  private readonly goalListMutations = {
    [BudgetObjectEventType.Created]: data => this.onObjectAdded(this.getGoal(data), this.budgetDataService.goalsSnapshot),
    [BudgetObjectEventType.Updated]: data => this.onObjectUpdated(this.getGoal(data), this.budgetDataService.goalsSnapshot),
    [BudgetObjectEventType.Deleted]: data => this.onGoalDeleted(data.objectId),
    [BudgetObjectEventType.Moved]: data => this.onGoalDeleted(data.objectId),
  };

  updateCampaigns(updateType: BudgetObjectEventType, data: BudgetObjectEventContext): LightCampaign {
    return this.campaignListMutations[updateType]?.(data) || null;
  }

  updatePrograms(updateType: BudgetObjectEventType, data: BudgetObjectEventContext): LightProgram {
    return this.programListMutations[updateType]?.(data) || null;
  }

  updateGoals(updateType: BudgetObjectEventType, data: BudgetObjectEventContext): Goal {
    return this.goalListMutations[updateType]?.(data) || null;
  }

  getProgramsContainer(): LightProgram[] {
    return this.budgetDataService.lightProgramsSnapshot || this.budgetDataService.programsSnapshot;
  }

  getCampaignsContainer(): LightCampaign[] {
    return this.budgetDataService.lightCampaignsSnapshot || this.budgetDataService.campaignsSnapshot;
  }

  private onObjectAdded<TBudgetObject extends MainBudgetObject>(
    addedObject: TBudgetObject,
    budgetDataObjectsContainer: TBudgetObject[]
  ): void {
    if (!budgetDataObjectsContainer) {
      return;
    }

    if (!budgetDataObjectsContainer.find(item => item.id === addedObject.id)) { // Make sure the object has not been added yet!
      budgetDataObjectsContainer.push(addedObject);
    }
    sortObjectsByName(budgetDataObjectsContainer);
  }

  private onObjectUpdated<TBudgetObject extends MainBudgetObject>(
    updatedObject: TBudgetObject,
    budgetDataObjectsContainer: TBudgetObject[]
  ): TBudgetObject {
    if (!budgetDataObjectsContainer) {
      return;
    }

    const targetObjectIndex = budgetDataObjectsContainer.findIndex(obj => obj.id === updatedObject.id);

    if (targetObjectIndex > -1) {
      const prevObject = budgetDataObjectsContainer[targetObjectIndex];
      const prevObjectCopy = JSON.parse(JSON.stringify(prevObject));
      const updatedObjectKeys = Object.keys(updatedObject);
      // Loop through the keys of the updated object
      updatedObjectKeys.forEach(key => {
          // Update the corresponding key in the previous object
          prevObject[key] = updatedObject[key];
      });
      sortObjectsByName(budgetDataObjectsContainer);

      return prevObjectCopy;
    }

    return null;
  }

  private onObjectDeleted<TBudgetObject extends MainBudgetObject>(
    objectId: number,
    budgetDataObjectsContainer: TBudgetObject[],
    resetParentForAffectedChildObjects?: (obj: TBudgetObject) => void
  ): TBudgetObject {
    if (!budgetDataObjectsContainer) {
      return;
    }

    const removedCampaignIndex = budgetDataObjectsContainer.findIndex(obj => obj.id === objectId);

    if (removedCampaignIndex > -1) {
      const removedCampaign = budgetDataObjectsContainer[removedCampaignIndex];
      budgetDataObjectsContainer.splice(removedCampaignIndex, 1);
      resetParentForAffectedChildObjects?.(removedCampaign);
      return removedCampaign;
    }

    return null;
  }

  private getLightCampaign(data: BudgetObjectEventContext): LightCampaign {
    return {
      id: data.objectId,
      campaignTypeId: data.objectTypeId,
      name: data.objectName,
      objectId: data.objectId,
      budgetSegmentId: data.segmentId,
      isPseudoObject: false,
      splitRuleId: data.sharedCostRuleId,
      parentCampaign: data.parentObject?.type === this.config.OBJECT_TYPES.campaign ? data.parentObject?.id : null,
      goalId: data.parentObject?.type === this.config.OBJECT_TYPES.goal ? data.parentObject?.id : null,
      isShort: true,
      mode: data.objectMode,
      startDate: data.startDate && parseDateString(data.startDate),
      endDate: data.endDate && parseDateString(data.endDate),
      keyMetric: data.keyMetricId,
      amountStatus: data.amountStatus,
      createdDate: data.createdDate,
      externalId: data.externalId
    };
  }

  private onCampaignDeleted(campaignId: number): LightCampaign {
    return this.onObjectDeleted(
      campaignId,
      this.getCampaignsContainer(),
      removedCampaign => {
        resetParentForObjects(
          this.getCampaignsContainer(),
          campaign => campaign.parentCampaign === removedCampaign.id,
          campaign => campaign.parentCampaign = null
        );

        resetParentForObjects(
          this.getProgramsContainer(),
          program => program.campaignId === removedCampaign.id,
          program => program.campaignId = null
        );
      }
    );
  }

  private getLightProgram(data: BudgetObjectEventContext): LightProgram {
    return {
      id: data.objectId,
      programTypeId: data.objectTypeId,
      name: data.objectName,
      objectId: data.objectId,
      budgetSegmentId: data.segmentId,
      isPseudoObject: false,
      splitRuleId: data.sharedCostRuleId,
      campaignId: data.parentObject?.type === this.config.OBJECT_TYPES.campaign ? data.parentObject?.id : null,
      goalId: data.parentObject?.type === this.config.OBJECT_TYPES.goal ? data.parentObject?.id : null,
      isShort: true,
      mode: data.objectMode,
      amountStatus: data.amountStatus,
      createdDate: data.createdDate,
      externalId: data.externalId
    };
  }

  private getGoal(data: BudgetObjectEventContext): Goal {
    const { company: companyId, id: budgetId} = this.budgetDataService.selectedBudgetSnapshot;
    return {
      id: data.objectId,
      name: data.objectName,
      goalTypeId: data.objectId,
      companyId,
      budgetId,
      status: this.config.goalStatusNames.active,
      timeframes: [],
      createdDate: data.createdDate
    };
  }

  private onGoalDeleted(goalId: number): Goal {
    return this.onObjectDeleted(
      goalId,
      this.budgetDataService.goalsSnapshot,
      removedGoal => {
        resetParentForObjects(
          this.getCampaignsContainer(),
          campaign => campaign.goalId === removedGoal.id,
          campaign => campaign.goalId = null
        );

        resetParentForObjects(
          this.getProgramsContainer(),
          program => program.goalId === removedGoal.id,
          program => program.goalId = null
        );
      });
  }
}
