import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { Configuration } from 'app/app.constants';
import { BudgetObjectCreationContext } from '../../../types/details-creation-context.interface';
import { BudgetObjectDetailsComponent } from '../../../types/budget-object-details-component.interface';
import { CampaignDetailsForm, CampaignDetailsService } from '../../../services/campaign-details.service';
import { CampaignDetailsState, ObjectDetailsCommonState } from '../../../types/budget-object-details-state.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { BudgetObjectDetailsManager } from '../../../services/budget-object-details-manager.service';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { Goal } from 'app/shared/types/goal.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { Metric } from '../../details-metrics/details-metrics.type';
import { DetailsAction } from '../../details-header/details-header.type';
import { BudgetObjectActionsBuilder } from '../../../services/budget-object-actions-builder.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserManager } from 'app/user/services/user-manager.service';
import { UserDataService } from 'app/shared/services/user-data.service';
import { BudgetAllocationsTableEvent } from '../../budget-allocations-table/budget-allocations-table.type';
import { BudgetObjectMetricsService } from '../../../services/budget-object-metrics.service';
import { DetailsExpensesData, DetailsExpensesTotalRow, } from '../../details-expenses/details-expenses-table/details-expenses-table.type';
import { TagControlEvent, TagsControlComponent } from 'app/shared/components/tags-control/tags-control.component';
import { BudgetObjectTagsService } from '../../../services/budget-object-tags.service';
import { ObjectHierarchy } from '../../object-hierarchy-nav/object-hierarchy-nav.type';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { messages, objectPlaceholderName } from '../../../messages';
import { DataValidationService } from 'app/budget-object-details/services/data-validation.service';
import { createDateString, parseDateString } from './date-operations';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { Attachment } from 'app/shared/types/attachment.interface';
import { BudgetObjectAttachmentsService } from '../../../services/budget-object-attachments.service';
import { ObjectHierarchyService } from '../../../services/object-hierarchy.service';
import { CompanyService } from 'app/shared/services/backend/company.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { BudgetObjectTasksService } from '../../../services/budget-object-tasks.service';
import { TaskListChangeEvent, TasksListComponent } from 'app/budget-object-details/components/tasks-list/tasks-list.component';
import { CampaignDO, LightCampaign } from 'app/shared/types/campaign.interface';
import { SegmentDataInheritanceService } from 'app/shared/services/segment-data-inheritance.service';
import { SegmentDataInheritanceAction } from 'app/shared/types/segment-data-inheritance.interface';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { LocationService } from '../../../services/location.service';
import { BudgetAllocationsTableComponent } from '../../budget-allocations-table/budget-allocations-table.component';
import { BusinessValueService } from '../../business-value/business-value.service';
import { MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { SalesforceMappingDialogComponent } from 'app/metric-integrations/salesforce/salesforce-mapping-dialog/salesforce-mapping-dialog.component';
import { SalesforceCampaignMapping } from 'app/metric-integrations/salesforce/salesforce-campaign-mapping.interface';
import { HubspotCampaignMapping } from 'app/metric-integrations/hubspot/hubspot-campaign-mapping.interface';
import { MatDialog } from '@angular/material/dialog';
import { MetricMappingDetailsService } from '../../../services/metric-mapping-details.service';
import { HierarchySelectConfig, HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { getParentFromLocation } from 'app/shared/utils/location.utils';
import { DetailsExpensesService } from '../../details-expenses/details-expenses.service';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { ExtendedUserDO } from 'app/shared/types/user-do.interface';
import { Integration } from 'app/metric-integrations/types/metrics-provider-data-service.types';
import {
  AllocationCheckResult,
  AllocationCheckResultData,
  BudgetObjectAllocationService
} from '../../../services/budget-object-allocation.service';
import { LightProgram, Program } from 'app/shared/types/program.interface';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { LAST_CREATED_OBJECT_ID } from 'app/shared/constants/storage.constants';
import { MetricIntegrationsProviderService } from 'app/metric-integrations/services/metric-integrations-provider.service';
import { SegmentMenuHierarchyService } from 'app/shared/services/segment-menu-hierarchy.service';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { SelectOption } from 'app/shared/types/select-option.interface';
import { BudgetObjectOwnersService } from '../../../services/budget-object-owners.service';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { MetricMappingDO } from 'app/shared/services/backend/metric.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { getObjectTypeKey, getTodayFixedDate } from '@shared/utils/budget.utils';
import { BudgetObjectActionsShared } from 'app/budget-object-details/services/budget-object-actions-shared';
import { HubspotMappingDialogComponent } from 'app/metric-integrations/hubspot/hubspot-mapping-dialog/hubspot-mapping-dialog.component';


@Component({
  selector: 'campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['../details-container.scss', './campaign-details.component.scss'],
  providers: [
    BudgetObjectActionsShared,
    BudgetObjectTagsService,
    BudgetObjectMetricsService,
    BudgetObjectAttachmentsService,
    BudgetAllocationActionsService
  ]
})
export class CampaignDetailsComponent implements OnInit, OnDestroy, BudgetObjectDetailsComponent {
  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);

  @ViewChild('allocationsTable') allocationsTable: BudgetAllocationsTableComponent;
  @ViewChild('tagsControl') tagsControl: TagsControlComponent;

  private readonly destroy$ = new Subject<void>();
  private readonly reset$ = new Subject<void>();
  public campaignFormData: UntypedFormGroup;
  private prevState: CampaignDetailsState = null;
  public currentState: CampaignDetailsState;
  private contextData: BudgetObjectCreationContext;
  public isPowerUser = false;

  public currentCompanyUser: CompanyUserDO;
  public companyId: number;
  public budget: Budget;
  public companyUsers: ExtendedUserDO[] = [];
  public campaignTypes: BudgetObjectType[] = [];
  public products: ProductDO[] = [];
  public company: CompanyDO;

  public budgets: Budget[] = [];
  public budgetTimeframes: BudgetTimeframe[] = [];
  public goals: Goal[] = [];
  public campaigns: LightCampaign[] = [];
  public programs: Program[] | LightProgram[] = [];
  public regularCampaigns: LightCampaign[] = [];
  public ownChildCampaigns: LightCampaign[] = [];
  public segments: BudgetSegmentAccess[];
  public segmentGroups: SegmentGroup[];
  public sharedCostRules: SharedCostRule[] = [];
  public allowedSharedCostRules: SharedCostRule[];
  public ownerOptions: SelectOption[] = [];
  public locationItems: HierarchySelectItem[] = [];
  public hasExternalIntegrationType = false;
  public isSegmentlessCampaign = false;

  public readonly objectType = this.configuration.OBJECT_TYPES.campaign;
  public readonly ObjectMode = ObjectMode;
  public readonly OBJECT_TYPES = this.configuration.OBJECT_TYPES;
  public companyCurrency: {
    symbol: string;
    code: string;
  };
  public menuActions: DetailsAction[] = [];
  public isLoading = true;
  public isReadOnlyMode = true;
  public isCustomTypeEntering = false;
  public editPermission = false;
  public unsavedChangesFlag = false;

  public detailsExpensesData: DetailsExpensesData;
  public detailsExpensesTotals: DetailsExpensesTotalRow;
  public hierarchy: ObjectHierarchy = {
    Goal: null,
    Program: null,
    ChildCampaign: null,
    Campaign: null,
    Expense: null
  };

  public minEndDate = null;
  public actualBusinessValue: number = null;
  public actualChildBusinessValue = 0;
  public targetBusinessValue: number = null;
  public MetricIntegrationName = MetricIntegrationName;
  public connectedIntegrations: Record<string, Integration[]> = {};
  @ViewChild(TasksListComponent) tasksList: TasksListComponent;
  public segmentSelectItems: HierarchySelectItem[] = [];
  public allowedSegmentSelectItems: HierarchySelectItem[] = [];
  private isDeleteActionObject = { value: false };
  public selectSegmentsConfig: HierarchySelectConfig = {
    fieldLabel: 'Segment *',
    withSearch: true,
    emptyValueLabel: null,
    searchPlaceholder: 'Search Segments',
    allGroups: false,
    allPlural: 'Segments',
    errorMsg: 'Segment is required',
  };
  public externalIntegration = {
    isExternal: false,
    integrationName: ''
  };

  protected budgetTodayDate: Date;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly configuration: Configuration,
    public readonly campaignDetailsService: CampaignDetailsService,
    public readonly appRoutingService: AppRoutingService,
    private readonly companyDataService: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    public readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly menuActionsBuilder: BudgetObjectActionsBuilder,
    private readonly utilityService: UtilityService,
    private readonly fb: UntypedFormBuilder,
    private readonly userManager: UserManager,
    private readonly userDataService: UserDataService,
    public readonly metricsManager: BudgetObjectMetricsService,
    public readonly tagsManager: BudgetObjectTagsService,
    private readonly dialogManager: BudgetObjectDialogService,
    public readonly dataValidation: DataValidationService,
    public readonly attachmentsManager: BudgetObjectAttachmentsService,
    public readonly hierarchyService: ObjectHierarchyService,
    private readonly companyService: CompanyService,
    private readonly tasksManager: BudgetObjectTasksService,
    private readonly segmentDataInheritanceService: SegmentDataInheritanceService,
    private readonly budgetObjectService: BudgetObjectService,
    private readonly locationService: LocationService,
    private readonly businessValueService: BusinessValueService,
    public dialog: MatDialog,
    private readonly metricMappingDetailsService: MetricMappingDetailsService,
    private readonly detailsExpensesService: DetailsExpensesService,
    private readonly integrationProvidersService: MetricIntegrationsProviderService,
    public readonly budgetObjectAllocationService: BudgetObjectAllocationService,
    public readonly gesturesManager: BudgetAllocationActionsService<any>,
    private readonly segmentMenuService: SegmentMenuHierarchyService,
  ) {
    this._createForm();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => this.init(params));

    this.companyDataService.metricIntegrations$
      .pipe(takeUntil(this.destroy$))
      .subscribe(integrations => BudgetObjectActionsShared.setConnectedIntegrations(integrations, this.connectedIntegrations));

    this.budgetObjectDetailsManager.budgetObjectChanged$
      .pipe(
        filter(change => change.objectType === this.configuration.OBJECT_TYPES.expense),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.updateExpenses());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadInitialBudgetData() {
    return combineLatest([
      this.budgetObjectDetailsManager.getCompanyId().pipe(tap(companyId => this.companyId = companyId)),
      this.budgetObjectDetailsManager.getCurrentBudget().pipe(map(budget => {
        this.budget = budget;
        this.budgetTodayDate = getTodayFixedDate(budget);
        return budget.id;
      }))]
    );
  }

  private syncUnsavedChangesFlag(flag?: boolean) {
    this.unsavedChangesFlag = typeof flag === 'boolean' ? flag : this.hasUnsavedChanges();
  }

  init(routeParams: Params) {
    this.reset$.next();
    if (this.tasksList) {
      this.tasksList.reset();
    }

    if (this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot)) {
      this.initCampaignCreation();
    } else {
      const campaignIdParam = routeParams && routeParams[AppRoutingService.DETAILS_OBJECT_ID_PARAM_NAME];
      const campaignId = campaignIdParam && Number.parseInt(campaignIdParam, 10);
      if (campaignId) {
        this.loadCampaign(campaignId);
      } else {
        this.onError(
          '[Campaign Details]: init(): incorrect campaign id provided: ' + campaignIdParam,
          messages.UNABLE_TO_LOAD_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
          true
        )
      }
    }

    this.userDataService.editPermission$
      .pipe(takeUntil(merge(this.destroy$, this.reset$)))
      .subscribe((editPermission: boolean) => {
        this.editPermission = editPermission;
        this.updateReadOnlyModeState();
      });
  }

  initCampaignCreation(reset: boolean = false, contextData?: BudgetObjectCreationContext, afterInitCb: Function = null) {
    this.showLoader();
    if (reset && contextData) {
      this.contextData = contextData;
    } else if (!reset) {
      this.contextData = AppRoutingService.getHistoryStateProperty<BudgetObjectCreationContext>('data');
    }
    const budgetData$ = reset ?
      of([this.companyId, this.budget.id]) :
      this.loadInitialBudgetData();

    budgetData$.pipe(
      switchMap(([companyId, budgetId]) =>
        combineLatest([
          this.campaignDetailsService.initDetails(this.contextData, {
            company: companyId,
            budget: budgetId
          }),
          this.loadDetailsContextData(companyId)
        ])
      ),
      map(([objectDetailsState]) => {
        this.goals = this.budgetDataService.goalsSnapshot;
        this.initHierarchy(objectDetailsState);
        this.detectAddedParentObject(objectDetailsState);
        const fillAllocationsOptions = {
          suppressMode: this.budget.suppress_timeframe_allocations,
          CEGMode: false,
        };
        this.campaignDetailsService.preFillStateAllocations(objectDetailsState, this.budgetTimeframes, fillAllocationsOptions);
        this.campaignTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.campaignTypes);
        if (this.currentCompanyUser?.user) {
          objectDetailsState.createdBy = this.currentCompanyUser.user;
        }
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => {
        this.onCampaignLoaded(state, false);
        if (afterInitCb) {
          afterInitCb();
        }
      },
      error => this.onError(
        error,
        messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
        true
      )
    );
  }

  private detectAddedParentObject(objectDetailsState) {
    this.budgetDataService.goalList$.pipe(
      tap(goals => this.goals = goals),
      takeUntil(merge(this.destroy$, this.reset$)),
      take(1)
    ).subscribe(() => {
      this.initHierarchy(objectDetailsState);
      this.updateLocationOptions();
    });
  }

  private initHierarchy(objectDetailsState: CampaignDetailsState): void {
    this.hierarchy = this.budgetObjectActionsShared.buildObjectHierarchy(objectDetailsState, this.goals, this.campaigns, this.programs);
  }

  private setCampaignLists() {
    const { objectId } = this.currentState;

    this.regularCampaigns = this.campaigns.filter(campaign => campaign.id !== objectId && !campaign.parentCampaign);
    this.ownChildCampaigns = this.campaigns.filter(campaign => objectId && campaign.parentCampaign === objectId);
  }

  loadCampaign(campaignId: number) {
    this.showLoader();
    this.loadInitialBudgetData().pipe(
      switchMap(([companyId, budgetId]) =>
        combineLatest([
          this.campaignDetailsService.loadDetails(companyId, budgetId, campaignId, { isCEGMode: false }),
          this.loadDetailsContextData(companyId)
        ])
      ),
      tap(([state]) => {
        this.budgetObjectDetailsManager.checkObjectBudgetStateConsistency({
          state,
          budgetId: this.budget?.id
        });
        this.budgetObjectDetailsManager.checkObjectAccess({
          segmentDO: {
            split_rule: state.segment && state.segment.sharedCostRuleId,
            company_budget_segment1: state.segment && state.segment.segmentId,
          },
          segments: this.segments,
          rules: this.sharedCostRules,
          onDenyCb: () => {
            this.destroy$.next();
            this.destroy$.complete();
          },
          objectTypeLabel: this.objectType.toLowerCase()
        });
      }),
      map(([objectDetailsState]) => {
        this.initHierarchy(objectDetailsState);
        this.defineParent(objectDetailsState);
        const fillAllocationsOptions = {
          suppressMode: false,
          CEGMode: false,
        };
        this.campaignDetailsService.preFillStateAllocations(objectDetailsState, this.budgetTimeframes, fillAllocationsOptions);
        this.campaignTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.campaignTypes, objectDetailsState.typeId);

        this.loadAttachments(objectDetailsState);
        objectDetailsState.campaigns.forEach(campaign => {
          const campaignType = this.campaignTypes.find(item => item.id === campaign[getObjectTypeKey(this.budget, 'campaign_type')]);
          if (campaignType) {
            campaign['campaignSource'] = campaignType.name;
          }
          return campaign;
        });
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => {
        this.onCampaignLoaded(state);
        this.budgetObjectDetailsManager.logObjectView(
          state.objectId,
          this.companyId,
          this.budget.id,
          this.currentCompanyUser.user,
          HistoryObjectLogTypeNames.campaign,
          this.campaignDetailsService);
      },
      error => this.onError(
        error,
        messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
        true
      )
    );
  }

  private resetDetails(contextData) {
    const afterResetCb = () => {
      if (this.allocationsTable) {
        this.allocationsTable.reset();
      }
    };
    this.reset$.next();
    this.campaignFormData.reset();
    this.prevState = null;
    this.currentState = null;
    this.initCampaignCreation(true, contextData, afterResetCb);
  }

  updateReadOnlyModeState() {
    this.isReadOnlyMode = !this.hasEditPermissions || !this.isCampaignOpen;
    this.updateMenuActions();
  }

  onCampaignLoaded(state: CampaignDetailsState, syncState: boolean = true) {
    this.actualChildBusinessValue = 0;
    this.currentState = state;
    if (syncState) {
      this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(state);
    }
    this.resetFormData();
    this.applyExternalIntegrationRestrictions();
    this.setCampaignLists();
    this.updateDetailsExpensesData();
    this.updateDetailsExpensesTotals();
    this.updateOwnerOptions(this.currentState.segment.segmentId, this.currentState.segment.sharedCostRuleId);
    this.defineAllowedSegments(this.currentState.ownerId);
    this.updateReadOnlyModeState();
    this.defineCurrency();
    this.setFormData();
    this.setBusinessValue();
    this.updateLocationOptions();
    this.hideLoader();
    this.isSegmentlessCampaign = !this.segmentControl.value;
    this.updateSegmentSelectConfig();

    this.campaignFormData.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(merge(this.destroy$, this.reset$))
      ).subscribe(() => this.syncUnsavedChangesFlag());

    this.campaignFormData.get('location').valueChanges
      .pipe(
        takeUntil(merge(this.destroy$, this.reset$))
      ).subscribe(() => this.updateSegmentSelectConfig());
  }

  private updateDetailsExpensesData(): void {
    this.detailsExpensesData = this.budgetObjectActionsShared.updateDetailsExpensesData(
      this.currentState.campaigns,
      this.currentState.programs,
      this.currentState.expenses,
      this.currentState.objectId,
      this.objectType
    );
  }

  updateExpenses() {
    this.campaignDetailsService.updateExpenses(this.companyId, this.currentState).subscribe(
      () => {
        this.updateDetailsExpensesData();
        this.updateDetailsExpensesTotals();
      }
    );
  }

  updateDetailsExpensesTotals() {
    this.detailsExpensesTotals = this.detailsExpensesService.prepareTotalsRow(this.currentState.statusTotals);
  }

  defineParent(objectDetailsState: CampaignDetailsState) {
    const hierarchyObjectType = this.budgetObjectActionsShared.getHierarchyObjectType(objectDetailsState);
    objectDetailsState.parentObject = this.hierarchyService.getParentFromHierarchy(this.hierarchy, hierarchyObjectType);
  }

  defineCurrency() {
    this.companyCurrency = {
      symbol: this.company.currency_symbol,
      code: this.company.currency
    }
  }

  handleError(message) {
    this.utilityService.handleError({ message })
  }

  loadDetailsContextData(companyId: number) {
    const typesErrorCb = error => this.onError(
      error,
      messages.UNABLE_TO_LOAD_OBJECT_TYPES_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
      true);
    if (this.budgetDataService.isCurrentBudgetWithNewCEGStructure) {
      this.companyDataService.loadObjectTypes(companyId, typesErrorCb);
    } else {
      this.companyDataService.loadCampaignTypes(companyId, typesErrorCb);
    }

    this.companyDataService.loadMetrics(
      companyId,
      error => this.onError(error, messages.UNABLE_TO_LOAD_METRICS_ERROR_MSG, true)
    );

    this.companyDataService.loadTags(
      companyId,
      error => this.onError(error, messages.UNABLE_TO_LOAD_TAGS_ERROR_MSG, true));

    return combineLatest(
      [
        this.companyDataService.selectedCompanyDO$.pipe(tap(company => this.company = company)),
        this.budgetObjectDetailsManager.getCompanyUsers().pipe(tap(users => this.companyUsers = users)),
        this.budgetObjectDetailsManager.getTags().pipe(tap(tags => this.tagsManager.setTags(tags))),
        this.budgetObjectDetailsManager.getCampaignTypes().pipe(tap(types => this.campaignTypes = [
          ...types,
          this.budgetObjectDetailsManager.enterCustomTypeOption
        ])),
        this.budgetObjectDetailsManager.getProducts$().pipe(
          mergeMap(
            products => this.budgetObjectDetailsManager.getMetricTypes().pipe(
              tap(mTypes => {
                this.metricsManager.setProducts(products);
                this.metricsManager.setTypes(mTypes);
              })
            ),
          )
        ),
        this.budgetObjectDetailsManager.getBudgets(this.budget?.id).pipe(tap(budgets => this.budgets = budgets)),
        this.budgetObjectDetailsManager.getTimeframes().pipe(tap(tfs => this.budgetTimeframes = tfs)),
        this.budgetObjectDetailsManager.getSegments().pipe(
          tap(segments => {
            this.segments = segments;
            this.updateSegmentSelectItems();
          })
        ),
        this.budgetObjectDetailsManager.getSegmentGroups().pipe(
          tap(items => {
            this.segmentGroups = items;
            this.updateSegmentSelectItems();
          })
        ),
        this.budgetObjectDetailsManager.getSharedCostRules().pipe(tap(rules => this.sharedCostRules = rules)),
        this.budgetObjectDetailsManager.getAllowedSharedCostRules().pipe(
          tap(rules => {
            this.allowedSharedCostRules = rules;
            this.updateSegmentSelectItems();
          })
        ),
        this.budgetObjectDetailsManager.getGoals().pipe(tap(goals => this.goals = goals)),
        this.budgetObjectDetailsManager.getLightCampaigns().pipe(tap(campaigns => this.campaigns = campaigns)),
        this.budgetObjectDetailsManager.getLightPrograms().pipe(tap(programs => this.programs = programs)),
        this.userManager.currentCompanyUser$.pipe(
          filter(user => !!user),
          tap(user => {
            this.currentCompanyUser = user;
            this.isPowerUser = this.userDataService.isPowerUser(user);
          })
        )
      ]
    );
  }

  hasUnsavedChanges(): boolean {
    if (!this.currentState || this.isDeleteActionObject.value) {
      return false;
    }
    // TODO: WE SHOULDN'T SAVE FORM DATA DURING CHECK UNSAVED CHANGES
    this.saveFormData();
    return this.campaignDetailsService.hasChanges(this.prevState, this.currentState);
  }

  private saveDetailsData$(): Observable<CampaignDO> {
    const isNewObject = !this.currentState.objectId;
    this.saveFormData();

    return this.campaignDetailsService.saveDetails(
      this.prevState,
      this.currentState,
      { suppressMode: this.budget.suppress_timeframe_allocations }
    ).pipe(
      tap((campaign: CampaignDO) => {
        this.currentState.spreadSegmentToChildren = false;
        this.currentState.externalId = campaign.external_id;
        if (isNewObject) {
          this.campaigns.push(this.budgetDataService.convertCampaignDO(campaign));
          LocalStorageService.addToStorage(LAST_CREATED_OBJECT_ID, campaign.id);
        }
      })
    );
  }

  saveChanges(onSavedCb: Function = null): void {
    const isNewObject = !this.currentState.objectId;
    const initiallyWasSegmentless = CampaignDetailsService.isSegmentless(this.prevState);

    const segmentlessToRegularConfirmation$ =
      initiallyWasSegmentless && !this.isSegmentlessCampaign ?
        BudgetObjectActionsShared.openSegmentlessToRegularConfirmation(this.dialogManager) :
        of(true);

    const save$ = allocationsCheckResult => this.budgetObjectActionsShared.saveContextData$(
      this.companyId,
      this.objectType,
      this.campaignFormData.get('typeId').value,
      this.campaignTypes,
      this.currentState.tagMappings,
      this.campaignFormData
    ).pipe(
      switchMap(() => this.saveDetailsData$()),
      switchMap((data: CampaignDO) => this.budgetObjectActionsShared.saveMappings(
        data,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((campaign: CampaignDO) => BudgetObjectActionsShared.saveTasks$(
        this.tasksManager,
        campaign,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((campaign: CampaignDO) => this.budgetObjectActionsShared.updateTagMappings$(
        campaign,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((campaign: CampaignDO) =>
        this.budgetObjectAllocationService.updateObjectRelatedAllocations$(
          allocationsCheckResult,
          this.prevState,
          this.currentState,
          this.budgetTimeframes,
          this.budget.suppress_timeframe_allocations
        )
      )
    );

    segmentlessToRegularConfirmation$.pipe(
      switchMap(confirmed =>
        !confirmed ?
          of(false) :
          this.budgetObjectAllocationService.checkAllocationAmountForObjectDetails$(
            this.prevState,
            this.currentState,
            this.programs,
            this.campaigns,
            this.budget.suppress_timeframe_allocations,
            this.objectType,
            this.isSegmentlessCampaign
          ).pipe(
            switchMap((res: AllocationCheckResultData) => {
              if (res.result !== AllocationCheckResult.NeedOwnAllocationUpdate) {
                this.showLoader();
                return save$(res).pipe(map(_ => true));
              }
              if (res.message) {
                this.budgetObjectAllocationService.openParentAmountShortageDialog(res.message);
              }
              return of(false);
            })
          )
      ),
      filter(saved => saved)
    ).subscribe({
      next: () => {
        this.onSavedSuccessfully(isNewObject);
        onSavedCb?.();
      },
      error: error => this.onError(
        error,
        messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
      )
    });
  }

  validateChanges(): boolean {
    this.segmentControl.updateValueAndValidity();
    if (this.campaignFormData.valid) {
      return true;
    }

    this.dataValidation.validateFormFields(this.campaignFormData);
    return false;
  }

  submitChanges(submitCallback) {
    const isFormValid = this.validateChanges();
    if (CampaignDetailsService.isSegmentless(this.currentState)) {
      const ownExpenses = this.currentState.expenses.filter(exp => exp.campaign === this.currentState.objectId)
      if (!!ownExpenses.length) {
        BudgetObjectActionsShared.showSaveWithoutSegmentDialog(this.dialogManager);
        return;
      }
    }
    if (isFormValid && submitCallback) {
      submitCallback();
    }
  }

  onSavedSuccessfully(isNewObject: boolean) {
    if (!this.prevState) {
      this.attachmentsManager.setObjectContext({
        objectId: this.currentState.objectId,
        objectType: this.objectType,
        companyId: this.companyId
      });
      this.budgetObjectDetailsManager.logObjectView(
        this.currentState.objectId,
        this.companyId,
        this.budget.id,
        this.currentCompanyUser.user,
        HistoryObjectLogTypeNames.campaign,
        this.campaignDetailsService
      );
      this.budgetObjectDetailsManager.refreshRecentlyAddedObjects(
        this.budget.id,
        HistoryObjectLogTypeNames.campaign,
        this.segments
      );
      this.updateMenuActions();
    }
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    const message = this.budgetObjectDetailsManager.defineSuccessMessage(this.objectType, isNewObject);
    this.onSuccess(message);
    this.budgetObjectDetailsManager.reportDetailsChange(this);
    this.syncUnsavedChangesFlag(false);
    this.setNameValidator(this.currentState.name);

    this.hierarchyService.setHierarchyObjectName(
      this.hierarchy,
      this.budgetObjectActionsShared.getHierarchyObjectType(this.currentState),
      this.currentState.name
    );
  }

  private showLoader() {
    this.isLoading = true;
    this.utilityService.showLoading(true);
  }

  private hideLoader() {
    // A workaround needed for e2e tests driver to catch loader's disappearing
    setTimeout(() => {
      this.isLoading = false;
      this.utilityService.showLoading(false);
    });
  }

  /* METRICS */
  addMetricMappings(metricMappings: MetricMappingDO[]) {
    const newMappings = metricMappings.map(mappingDO => this.metricsManager.convertDataObjectToMapping(mappingDO))
    this.currentState.metricMappings = [ ...this.currentState.metricMappings, ...newMappings ];
  }

  setKeyMetric(metric: Metric) {
    const newKeyMetricId = metric.id;
    const currentKeyMetricId = this.currentState.keyMetricId;
    const savedKeyMetricId = this.prevState.keyMetricId;

    if (currentKeyMetricId === newKeyMetricId) {
      this.setKeyMetricState(null);
      this.utilityService.showToast({ action: null, Message: messages.NO_KEY_METRIC_SELECTED });
      return;
    }

    if (savedKeyMetricId !== newKeyMetricId) {
      this.utilityService.showToast({ action: null, Message: messages.METRIC_BECOME_KEY_AFTER_SAVE });
    }
    this.setKeyMetricState(newKeyMetricId);
  }

  setKeyMetricState(id: number) {
    this.currentState.keyMetricId = id;
    this.setBusinessValue();
    this.syncUnsavedChangesFlag();
  }

  setBusinessValue() {
    const businessValueForecast =
      this.budgetObjectActionsShared.getBusinessValueForecast(this.currentState.keyMetricId, this.currentState.metricMappings, this.metricsManager.types, this.metricsManager.products);
    this.actualBusinessValue = businessValueForecast.actualBusinessValue;
    this.targetBusinessValue = businessValueForecast.targetBusinessValue;
    if (!businessValueForecast.hasKeyMetric) {
      this.actualBusinessValue = this.actualChildBusinessValue;
    }
  }

  updateTotalBusinessValue(childValue) {
    this.actualChildBusinessValue = childValue;
    this.setBusinessValue();
  }

  /* TAGS */
  protected createTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.createTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  protected addTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.addTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  protected removeTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.removeTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  /* ALLOCATIONS */
  handleAllocationsUpdate($event: BudgetAllocationsTableEvent) {
    const { allocationId, amount } = $event;
    const updatedAllocation = this.currentState.allocations.find(alloc => alloc.company_budget_alloc === allocationId);
    if (updatedAllocation) {
      updatedAllocation.source_amount = amount as number; // TODO: recalculate source_amount based on exchange rate (currency scope)
      updatedAllocation.amount = amount as number;
      this.syncUnsavedChangesFlag();
    }
  }

  handleTotalAllocatedUpdate($event: number) {
    this.currentState.amount = $event;
    this.currentState.lockForIntegrations = true;
    this.syncUnsavedChangesFlag();
  }

  get isCampaignOpen(): boolean {
    if (!this.currentState) {
      return false;
    }

    const { mode } = this.currentState;
    return mode === ObjectMode.Open || mode === ObjectMode.Planned;
  }

  get hasEditPermissions(): boolean {
    return this.editPermission;
  }

  updateSegmentSelectItems() {
    if (!this.segments || !this.allowedSharedCostRules || !this.segmentGroups) {
      return;
    }
    this.segmentSelectItems = this.segmentMenuService.prepareDataForSegmentMenu({
      segments: this.segments,
      groups: this.segmentGroups,
      rules: this.allowedSharedCostRules,
    });
    this.allowedSegmentSelectItems = [...this.segmentSelectItems];
  }

  get segmentControl(): AbstractControl {
    return this.campaignFormData.get('segment');
  }

  handleSegmentChanged(selectedItem: HierarchySelectItem) {
    const { segmentId, sharedCostRuleId } = this.budgetObjectDetailsManager.hierarchyItemToState(selectedItem);
    const prevSegment = this.budgetObjectDetailsManager.segmentedValueToSelectItem(this.currentState.segment, this.segmentSelectItems);
    const hasChildren = BudgetObjectActionsShared.objectHasChildren(this.currentState as ObjectDetailsCommonState);

    const confirmSegmentChange$ = !hasChildren || (!segmentId && !sharedCostRuleId) ?
      of(null) :
      this.segmentDataInheritanceService
        .confirmSegmentChange(this.objectType, hasChildren, false)
        .pipe(takeUntil(this.destroy$));

    confirmSegmentChange$.subscribe(
      action => this.onChangeSegment(segmentId, sharedCostRuleId, action, prevSegment)
    );
  }

  private onChangeSegment(
    segmentId: number,
    sharedCostRuleId: number,
    action: SegmentDataInheritanceAction,
    prevSegment: HierarchySelectItem
  ): void {
    if (action === SegmentDataInheritanceAction.None) {
      this.campaignFormData.patchValue({ segment: prevSegment });
      this.isSegmentlessCampaign = !prevSegment && !this.currentState.parentCampaignId;
      return;
    }

    this.updateOwnerOptions(segmentId, sharedCostRuleId);
    this.isSegmentlessCampaign = !segmentId && !sharedCostRuleId  && !this.currentState.parentCampaignId;
    if (this.isSegmentlessCampaign) {
      this.updateCampaignAllocations();
    }

    BudgetObjectActionsShared.processSegmentChangeAction(
      this.currentState as ObjectDetailsCommonState,
      action,
      segmentId,
      sharedCostRuleId,
      false
    );
  }

  private updateCampaignAllocations() {
    if (!this.budget.suppress_timeframe_allocations) {
      const childrenTotalAllocations = BudgetObjectActionsShared.sumChildrenAllocations([
        ...this.currentState.campaigns.map(camp => camp.campaign_allocations),
        ...this.currentState.programs.map(program => program.program_allocations),
      ]);
      this.currentState.allocations.forEach(alloc => {
        alloc.amount = childrenTotalAllocations[alloc.company_budget_alloc];
      })
      this.prevState.allocations = this.currentState.allocations;
    }

    this.prevState.amount = this.currentState.amount = [
      ...this.currentState.campaigns, ...this.currentState.programs
    ].reduce((sum, item) => {
        sum += item.amount;
        return sum;
      }, 0);
  }

  private updateOwnerOptions(segmentId: number, sharedCostRuleId?: number) {
    this.ownerOptions = BudgetObjectOwnersService.getOwnerOptions(
      this.budget.id,
      segmentId,
      sharedCostRuleId,
      this.currentState.ownerId,
      this.companyUsers,
      this.sharedCostRules,
    );
  }

  private defineAllowedSegments(ownerId: number) {
    this.allowedSegmentSelectItems = BudgetObjectOwnersService.getAllowedSegmentOptions({
      ownerId,
      budgetId: this.budget.id,
      sharedCostRules: this.sharedCostRules,
      segmentSelectItems: this.segmentSelectItems,
      companyUsers: this.companyUsers
    }, this.configuration.OBJECT_TYPES);
  }

  /* ACTIONS MENU */
  private updateMenuActions() {
    if (!(this.currentState && this.currentState.objectId)) {
      this.menuActions = [];
      return;
    }

    this.menuActionsBuilder
      .reset()
      .addCloneAction(this.objectType, this.handleClone.bind(this), this.isReadOnlyMode);

    if (this.isCampaignOpen) {
      this.menuActionsBuilder.addCloseAction(this.objectType, this.handleClose.bind(this), !this.hasEditPermissions);
    } else {
      this.menuActionsBuilder.addOpenAction(this.objectType, this.handleOpen.bind(this), !this.hasEditPermissions);
    }
    const integrationExpenseTypeIds = BudgetObjectService.getExternalIntegrationTypeIds(this.campaignTypes);
    const isBudgetActionDisabled = integrationExpenseTypeIds.includes(this.currentState.typeId) || this.isReadOnlyMode;

    this.menuActionsBuilder
      .addChangeBudgetAction(isBudgetActionDisabled)
      .addDeleteAction(this.objectType, this.handleDelete.bind(this), this.isReadOnlyMode);

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  private onObjectModeUpdated(campaign: CampaignDO) {
    this.currentState.mode = campaign.mode;
    this.currentState.statusTotals = campaign.status_totals;
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    this.updateReadOnlyModeState();
    this.budgetObjectDetailsManager.reportDetailsChange(this);
    this.campaignDetailsService.reloadChildObjects(this.companyId, this.currentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.updateDetailsExpensesData();
          this.updateDetailsExpensesTotals();
        }
      );
  }

  handleClone() {
    const clone$ =
      this.campaignDetailsService.cloneObject(this.currentState.objectId)
        .pipe(
          tap(data => {
            this.budgetObjectDetailsManager.reportDetailsChange(this);
          })
        );

    const checkAndClone$ =
      this.campaignDetailsService.getCampaign(this.currentState.objectId, this.campaigns).pipe(
        switchMap(campaign =>
          this.budgetObjectAllocationService.checkAllocationAmountAndCloneSegmentedObject(
            campaign,
            this.objectType,
            campaign.parentCampaign,
            clone$,
            this.campaigns,
            this.programs,
            this.budget.suppress_timeframe_allocations
          )
        )
      );

    this.budgetObjectDetailsManager.cloneObject(checkAndClone$, this.objectType)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => this.appRoutingService.openCampaignDetails(data && data.id),
        err => this.onError(
          err,
          messages.UNABLE_TO_CLONE_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
        )
      );
  }

  handleOpen() {
    this.showLoader();
    this.campaignDetailsService.reopenObject(this.currentState.objectId)
      .subscribe(
        (campaign: CampaignDO) => this.onObjectModeUpdated(campaign),
        error => this.onError(
          error,
          messages.UNABLE_TO_REOPEN_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
        ),
        () => this.hideLoader()
      );
  }

  handleClose() {
    const closeSubmitHandler = () => {
      this.showLoader();
      this.campaignDetailsService.closeObject(this.currentState.objectId)
        .subscribe(
          (campaign: CampaignDO) => this.onObjectModeUpdated(campaign),
          error => this.onError(
            error,
            messages.UNABLE_TO_CLOSE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
          )
        );
    };

    this.dialogManager.openConfirmationDialog({
      title: messages.CLOSE_CAMPAIGN_TITLE,
      content: messages.CLOSE_OBJECT_MSG,
      submitAction: {
        label: 'OK',
        handler: closeSubmitHandler
      },
      cancelAction: {
        label: 'Cancel',
        handler: null
      }
    },
      {
        width: '480px'
      });
  }

  private handleDelete(): void {
    this.budgetObjectActionsShared.handleDeleteCampaign(
      this.currentState as ObjectDetailsCommonState,
      this.objectType,
      this.companyId,
      this.isDeleteActionObject,
      (message: string, close = false) => this.onSuccess(message, close),
      this.onError
    );
  }

  handleBudgetToMoveSelected(budget: Budget) {
    this.budgetObjectActionsShared.handleBudgetToMoveSelected(
      budget,
      this.companyId,
      this.currentState.objectId,
      this.objectType,
      false
    );
  }

  handleCancelAction() {
    this.appRoutingService.closeDetailsPage();
  }

  handleSaveAction() {
    this.checkTagsLeftover();
    if (this.hasUnsavedChanges()) {
      this.saveChanges();
    }
  }

  handleSaveAndNewAction() {
    this.checkTagsLeftover();
    const contextData = this.getContextForNewObjectCreation();
    const onSaved = this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot)
      ? this.resetDetails.bind(this, contextData)
      : this.appRoutingService.openCampaignCreation.bind(this.appRoutingService, contextData);

    if (this.hasUnsavedChanges()) {
      this.saveChanges(onSaved);
      return;
    }

    onSaved();
  }

  handleSaveAndCloseAction() {
    this.checkTagsLeftover();

    if (this.hasUnsavedChanges()) {
      const onSaved = this.appRoutingService.closeDetailsPage.bind(this.appRoutingService);
      this.saveChanges(onSaved);
      return;
    }
    this.appRoutingService.closeDetailsPage();
  }

  private checkTagsLeftover(): void {
    this.tagsManager.checkInputLeftover(this.tagsControl, this.currentState.tagMappings);
  }

  handleTypeChange() {
    const { typeId } = this.campaignFormData.value;
    this.isCustomTypeEntering = typeId === this.budgetObjectDetailsManager.enterCustomTypeOption.id;
    this.resetUnsavedCustomTypes();
  }

  handleCustomTypeChange() {
    this.budgetObjectActionsShared.handleCustomTypeChange(this.campaignFormData, this.campaignTypes, this.companyId, this.prevState?.typeId);
    this.isCustomTypeEntering = false;
  }

  resetUnsavedCustomTypes() {
    this.campaignTypes = this.campaignTypes.filter(
      cType => !cType.isCustom || cType.id !== this.budgetObjectDetailsManager.unsavedCustomTypeId
    );
    this.campaignFormData.patchValue({ customType: '' });
  }

  /* FORM DATA */
  private _createForm() {
    const formData = {
      name: ['', {
        validators: [Validators.required, Validators.maxLength(this.budgetObjectDetailsManager.maxObjectNameLength)],
        updateOn: 'blur'
      }],
      segment: [null, this.dataValidation.campaignSegmentValidator(this.externalIntegrationStatusGetter.bind(this))],
      ownerId: [null, Validators.required],
      startDate: null,
      endDate: null,
      location: null,
      typeId: [null, [Validators.required, Validators.min(0)]],
      customType: '',
      targetAudience: '',
      messaging: '',
      notes: ''
    };
    this.campaignFormData = this.fb.group(formData);
  }

  private setNameValidator(nameValue: string) {
    const nameControl = this.campaignFormData.get('name');
    if (nameControl) {
      nameControl.asyncValidator = this.dataValidation.uniqueNameValidator(
        this.companyId, this.budget.id, nameValue, this.objectType
      );
    }
  }

  private setDateValidator(startDateValue: Date) {
    const startDateControl = this.campaignFormData.get('startDate');
    const endDateControl = this.campaignFormData.get('endDate');
    if (!startDateControl.value) {
      endDateControl.disable();
    }
    this.minEndDate = BudgetObjectActionsShared.getMinEndDate(startDateValue);

    startDateControl.valueChanges
      .pipe(takeUntil(merge(this.destroy$, this.reset$)))
      .subscribe(updatedStartDate => {
        this.minEndDate = BudgetObjectActionsShared.getMinEndDate(updatedStartDate);
        endDateControl.enable();
        if (endDateControl.value < updatedStartDate) {
          endDateControl.setValue(null);
        }
      });
  }

  private setFormValidators(nameValue: string, startDateValue: Date) {
    this.setNameValidator(nameValue);
    this.setDateValidator(startDateValue);
  }

  private formDataToState(): Partial<CampaignDetailsState> {
    const { OBJECT_TYPES } = this.configuration;
    const formData = this.campaignFormData.getRawValue() as CampaignDetailsForm;
    const stateSegment = this.budgetObjectDetailsManager.hierarchyItemToState(formData.segment);
    const startDate = createDateString(formData.startDate);
    const endDate = createDateString(formData.endDate);
    const state: Partial<CampaignDetailsState> = {
      name: formData.name,
      targetAudience: formData.targetAudience,
      messaging: formData.messaging,
      notes: formData.notes,
      typeId: formData.typeId,
      ownerId: formData.ownerId,
      startDate: startDate,
      endDate: endDate,
      segment: stateSegment,
    };
    const parentObject = getParentFromLocation(formData.location);

    state.goalId = parentObject?.type === OBJECT_TYPES.goal ? parentObject.id : null;
    state.parentCampaignId = parentObject?.type === OBJECT_TYPES.campaign ? parentObject.id : null;
    state.parentObject = parentObject;

    return state;
  }

  private saveFormData() {
    const formDataState = this.formDataToState();
    Object.keys(formDataState).forEach(key => {
      this.currentState[key] = formDataState[key];
    })
  }

  private resetFormData() {
    const nameControl = this.campaignFormData.get('name');
    if (nameControl) {
      nameControl.asyncValidator = null;
      nameControl.setErrors(null);
    }
    if (this.allocationsTable) {
      this.allocationsTable.reset();
    }
  }

  private updateLocationOptions() {
    const currentLocationValue = this.campaignFormData.get('location').value;
    const allowedParentCampaigns = this.ownChildCampaigns.length ? [] : this.regularCampaigns;
    const { flatItemIdsList, items } = this.locationService.createLocationHierarchyItems({
      goals: this.goals,
      campaigns: allowedParentCampaigns,
      programs: [],
      currentLocation: currentLocationValue,
      segments: this.segments,
      rules: this.sharedCostRules,
      isPowerUser: this.isPowerUser
    });

    this.locationItems = items;
    if (currentLocationValue && !flatItemIdsList.includes(currentLocationValue)) {
      this.campaignFormData.get('location').setValue(null);
    }
  }

  private setFormData() {
    const {
      segment, ownerId, parentObject, typeId, targetAudience = '', messaging = '',
      notes = '', name, startDate, endDate
    } = this.currentState;
    const location = this.locationService.defineLocationValue(parentObject);
    const segmentedValue = this.budgetObjectDetailsManager.segmentedValueToSelectItem(segment, this.segmentSelectItems);
    const owner = ownerId || this.currentCompanyUser?.user;
    const startDateObj = parseDateString(startDate);
    const endDateObj = parseDateString(endDate);
    const formData: CampaignDetailsForm = {
      name,
      segment: segmentedValue,
      ownerId: owner,
      typeId,
      customType: '',
      targetAudience,
      messaging,
      notes,
      startDate: startDateObj,
      endDate: endDateObj,
      location
    };
    this.campaignFormData.setValue(formData);
    this.setFormValidators(name, startDateObj);
    this.segmentControl.updateValueAndValidity();
    this.performAutofill();
  }

  private performAutofill() {
    if (!this.currentState.objectId) {
      this.budgetObjectDetailsManager.autofillSegmentValue(
        this.segmentControl,
        this.segmentSelectItems
      )
    }
    this.budgetObjectDetailsManager.autofillTypeSelectValue(
      this.campaignFormData.get('typeId'),
      this.campaignTypes.filter(type => !!type.id)
    );
  }

  private onSuccess(message: string, close = false) {
    this.hideLoader();
    this.utilityService.showToast({ Type: 'success', Message: message });
    if (close) {
      this.appRoutingService.closeDetailsPage();
    }
  }

  private onError(error, message, close = false) {
    this.hideLoader();
    this.budgetObjectDetailsManager.handleError(error, message, close);
  }

  protected getContextForNewObjectCreation(): BudgetObjectCreationContext {
    return BudgetObjectActionsShared.getContextForNewObjectCreation(this.currentState as ObjectDetailsCommonState);
  }

  getContextForChildObjectCreation(): BudgetObjectCreationContext {
    let context: BudgetObjectCreationContext = {
      parent: {
        id: this.currentState.objectId,
        type: this.objectType,
      },
      segmentId: this.currentState.segment && this.currentState.segment.segmentId,
      sharedCostRuleId: this.currentState.segment && this.currentState.segment.sharedCostRuleId
    }
    if (CampaignDetailsService.isSegmentless(this.currentState)) {
      context = {
        ...context,
        messaging: this.currentState.messaging,
        targetAudience: this.currentState.targetAudience
      }
    }
    return context;
  }

  addNewExpense() {
    if (!this.currentState.objectId) {
      this.utilityService.showToast({ Message: messages.SAVING_BEFORE_ADD_EXPENSE.replace(objectPlaceholderName, this.objectType) });
      this.submitChanges(
        () => this.saveChanges(
          () => this.appRoutingService.openExpenseCreation(this.getContextForChildObjectCreation())
        )
      );
    } else {
      this.appRoutingService.openExpenseCreation(this.getContextForChildObjectCreation());
    }
  }

  addProgram() {
    this.appRoutingService.openProgramCreation(this.getContextForChildObjectCreation());
  }

  addChildCampaign() {
    const contextData = this.getContextForChildObjectCreation();
    const isCreateDetailsRoute = this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot)

    if (isCreateDetailsRoute) {
      this.resetDetails(contextData);
    } else {
      this.appRoutingService.openCampaignCreation(contextData);
    }
  }

  viewExpenses() {
    if (this.currentState.objectId != null) {
      this.budgetObjectDetailsManager.viewExpenses({
        [FilterName.Campaigns]: [this.currentState.objectId]
      });
    }
  }

  loadAttachments(state: CampaignDetailsState) {
    this.attachmentsManager.setObjectContext({
      objectId: state.objectId,
      objectType: this.objectType,
      companyId: this.companyId
    });
    this.attachmentsManager.loadAttachments(state.attachmentMappings)
      .subscribe({
        error: (err) => this.onError(err, '')
      });
  }

  handleFileAttached($event) {
    this.attachmentsManager.uploadFiles($event.target.files)
      .subscribe({
        error: (err) => this.onError(err, ''),
        complete: () => $event.target.value = null
      });
  }

  handleFileDelete(attachment: Attachment) {
    this.dialogManager.openDeleteEntityDialog(() => {
      this.attachmentsManager.deleteFile(attachment)
        .subscribe({
          error: (err) => this.onError(err, '')
        });
    }, 'file');
  }

  handleFileDownload(attachment: Attachment) {
    this.attachmentsManager.downloadFile(attachment)
      .subscribe({
        error: (err) => this.onError(err, '')
      });
  }

  protected handleTasksUpdate($event: TaskListChangeEvent): void {
    BudgetObjectActionsShared.handleTasksUpdate(
      $event,
      this.currentState as ObjectDetailsCommonState,
      this.prevState as ObjectDetailsCommonState
    );
    this.syncUnsavedChangesFlag();
  }

  protected openSalesforceMappingModal = () => {
    this.budgetObjectActionsShared.openMetricIntegrationMappingDialog(
      this.company,
      this.budget,
      this.currentState as ObjectDetailsCommonState,
      MetricIntegrationName.Salesforce,
      this.connectedIntegrations[MetricIntegrationName.Salesforce][0].integrationId,
      this.isReadOnlyMode,
      SalesforceMappingDialogComponent,
      (mapping: SalesforceCampaignMapping, campaignsForSync: number[]) => this.updateSalesforceData(mapping, campaignsForSync)
    );
  }

  protected openHubspotMappingModal = () => {
    this.budgetObjectActionsShared.openMetricIntegrationMappingDialog(
      this.company,
      this.budget,
      this.currentState as ObjectDetailsCommonState,
      MetricIntegrationName.Hubspot,
      this.connectedIntegrations[MetricIntegrationName.Hubspot][0].integrationId,
      this.isReadOnlyMode,
      HubspotMappingDialogComponent,
      (mapping: HubspotCampaignMapping, campaignsForSync: number[]) => this.updateHubspotData(mapping, campaignsForSync)
    );
  }

  private updateSalesforceData(mapping: SalesforceCampaignMapping, campaignsForSync: number[]): void {
    if (!mapping) {
      return;
    }

    this.updateIntegrationData(
      (chain$: Observable<any>) =>
        this.metricMappingDetailsService.syncSalesforceObject(
          this.companyId,
          this.connectedIntegrations[MetricIntegrationName.Salesforce][0].integrationId,
          [this.currentState.objectId, ...campaignsForSync],
          chain$),
      messages.UNABLE_TO_UPDATE_SF_CAMPAIGNS_ERROR_MSG
    );
  }

  private updateHubspotData(mapping: HubspotCampaignMapping, campaignsForSync: number[]): void {
    if (!mapping) {
      return;
    }

    this.updateIntegrationData(
      (chain$: Observable<any>) =>
        this.metricMappingDetailsService.syncHubspotObject(
          this.companyId,
          this.connectedIntegrations[MetricIntegrationName.Hubspot][0].integrationId,
          [this.currentState.objectId, ...campaignsForSync],
          chain$),
      messages.UNABLE_TO_UPDATE_HS_CAMPAIGNS_ERROR_MSG
    );
  }

  private updateIntegrationData(
    sync: (chain$: Observable<any>) => Observable<any>,
    errorMessage: string
  ) {
    const updateChain$ = this.budgetObjectActionsShared.getUpdateIntegrationChain$(
      this.companyId,
      this.currentState as ObjectDetailsCommonState
    ).pipe(tap(() => this.setBusinessValue()));

    sync(updateChain$)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (err) => this.onError(err, errorMessage),
        complete: () => this.utilityService.showLoading(false)
      })
  }

  private applyExternalIntegrationRestrictions() {
    const fieldsToDisable = ['name', 'typeId', 'startDate'];
    const { filteredObjectTypes, integrationTypeSelected } = BudgetObjectService.processIntegrationObjectTypes(
      this.campaignTypes,
      this.currentState.typeId
    );

    this.hasExternalIntegrationType = integrationTypeSelected;
    this.campaignTypes = filteredObjectTypes;
    if (this.hasExternalIntegrationType) {
      fieldsToDisable.forEach(fieldName => this.campaignFormData.get(fieldName)?.disable({ emitEvent: false }));
      this.externalIntegration = {
        isExternal: integrationTypeSelected,
        integrationName: filteredObjectTypes.find(objectType => objectType.id === this.currentState.typeId)?.name
      };
    }
    this.updateSegmentSelectConfig();
  }

  private updateSegmentSelectConfig(): void {
    const hasParentCampaign = this.campaignFormData.get('location').value?.split('_')[0] === this.configuration.OBJECT_TYPES.campaign;
    const isSegmentFieldRequired = this.hasExternalIntegrationType || hasParentCampaign;
    this.selectSegmentsConfig = BudgetObjectActionsShared.getSegmentSelectConfig(this.selectSegmentsConfig, isSegmentFieldRequired);
    if (!isSegmentFieldRequired) {
      this.segmentControl.updateValueAndValidity();
    }
  }

  public externalIntegrationStatusGetter(): boolean {
    return this.hasExternalIntegrationType;
  }

  private handleSegmentOnLocationChange(location: string, onCancel: () => void) {
    const formData = this.campaignFormData.value as CampaignDetailsForm;
    const onReplace = (parentSegmentData) => {
      const segmentSelectItem = this.budgetObjectDetailsManager.segmentedValueToSelectItem(parentSegmentData, this.segmentSelectItems);
      this.campaignFormData.patchValue({ segment: segmentSelectItem });
      // If we apply new parent's segment -> then we should 'spreadSegmentToChildren' implicitly
      this.currentState.spreadSegmentToChildren = true;
      this.handleSegmentChanged(segmentSelectItem);
    };

    this.budgetObjectDetailsManager.syncSegmentsOnLocationUpdate({
      objectType: this.objectType,
      campaigns: this.campaigns,
      segment: formData.segment,
      location,
      onCancel,
      onReplace
    });
  }

  public handleParentSelectionChange(value: string) {
    const prevLocation = this.locationService.defineLocationValue(this.currentState.parentObject);

    this.campaignFormData.get('location').setValue(value);
    this.handleSegmentOnLocationChange(
      value,
      () => this.campaignFormData.patchValue({ location: prevLocation })
    );
  }

  public handleOwnerChange(change: MatSelectChange) {
    const ownerValue = change.value;
    this.defineAllowedSegments(ownerValue);
  }
}
