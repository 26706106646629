<search-control
  *ngIf="config.withSearch"
  [placeholder]="config.searchPlaceholder"
  (textChanged)="filterHierarchyItems($event)"
></search-control>

<div class="select-all option-content" *ngIf="config.allGroups">
  <arrow-toggler
    [active]="!allSelected"
    (change)="toggleAllGroups.emit($event)"></arrow-toggler>
  All {{config.allPlural}}
</div>
