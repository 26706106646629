import {
  ElementRef, HostListener, Directive, Input, NgZone, OnDestroy, OnChanges, AfterViewChecked
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';

@Directive({
  selector: '[textFieldAutosize]'
})

export class TextfieldAutosizeDirective implements OnDestroy, OnChanges, AfterViewChecked {
  @Input() maxRows: number;
  @Input() minHeight: number;
  @Input() maxHeight: number;

  private _minRows: number;
  private textAreaEl: HTMLTextAreaElement;
  private _oldContent: string;
  private _oldWidth: number;
  private destroy$ = new Subject<void>();
  private adjust$ = new Subject<void>();
  private sizeObserver: ResizeObserver;

  @Input() set minRows(value) {
    this._minRows = value;
    if (this.textAreaEl) {
      this.textAreaEl.rows = value;
    }
  };

  @HostListener('input')
  onInput(): void {
    this.adjust$.next();
  }

  constructor(public element: ElementRef, private _zone: NgZone) {
    this._initTextarea();
    this._zone.runOutsideAngular(() => {
      this.sizeObserver = new ResizeObserver(entries => {
        if (this._oldWidth !== entries[0].contentRect.width) {
          this.adjust$.next();
        }
      });

      this.sizeObserver.observe(this.element.nativeElement);
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.sizeObserver.unobserve(this.element.nativeElement);
  }

  ngOnChanges(changes) {
    this.adjust$.next();
  }

  ngAfterViewChecked() {
    this._zone.runOutsideAngular(() => {
      this.adjust$.next();
    })
  }

  adjust(): void {
    const currentText = this.textAreaEl.value;
    this._oldContent = currentText;
    this._oldWidth = this.textAreaEl.offsetWidth;

    const clone: any = this._getCloneTextarea();
    const computedStyle = window.getComputedStyle(clone, null);
    const parent = this.textAreaEl.parentNode;
    parent.appendChild(clone);

    let height = clone.scrollHeight;
    // add into height top and bottom borders' width
    height += parseInt(computedStyle.getPropertyValue('border-top-width'));
    height += parseInt(computedStyle.getPropertyValue('border-bottom-width'));

    const oldHeight = this.textAreaEl.offsetHeight;
    const maxHeightLimit = this.maxHeight != null && this.maxHeight < height;
    if (maxHeightLimit) {
      height = this.maxHeight;
    }
    if (height !== oldHeight) {
      this._updateTextareaHeight(height);
    }
    parent.removeChild(clone);
  }

  private _getLineHeight() {
    let lineHeight = parseInt(this.textAreaEl.style.lineHeight, 10);
    if (isNaN(lineHeight) && window.getComputedStyle) {
      const styles = window.getComputedStyle(this.textAreaEl);
      lineHeight = parseInt(styles.lineHeight, 10);
    }
    if (isNaN(lineHeight)) {
      const fontSize = window.getComputedStyle(this.textAreaEl, null).getPropertyValue('font-size');
      lineHeight = Math.floor(parseInt(fontSize.replace('px', ''), 10) * 1.5);
    }
    return lineHeight;
  }

 private _initTextarea() {
    if (this.element.nativeElement.tagName !== 'TEXTAREA') {
      return;
    }
   this.textAreaEl = this.element.nativeElement;
   this.textAreaEl.style.overflow = 'hidden';
   this.textAreaEl.style.paddingBottom = '6px';
   this.adjust$
     .pipe(
       takeUntil(this.destroy$),
       debounceTime(200),
       filter(() => this._shouldAdjust())
     ).subscribe(() => this.adjust())
  }

 private _shouldAdjust() {
   return this.textAreaEl && this.textAreaEl.parentNode &&
      this.textAreaEl.value !== this._oldContent ||
      this.textAreaEl.offsetWidth !== this._oldWidth
  }

 private _getCloneTextarea() {
    const clone: any = this.textAreaEl.cloneNode(true);
    clone.style.position = 'absolute';
    clone.style.visibility = 'hidden';
    clone.textContent = this.textAreaEl.value;
    clone.style.width = this.textAreaEl.offsetWidth + 'px';
    clone.style.overflow = 'hidden';
    clone.style.height = 'auto';
    return clone;
  }

 private _updateTextareaHeight(height) {
    const lineHeight = this._getLineHeight();
    const rowsCount = Math.round(height / lineHeight);
    if (this._minRows && this._minRows > rowsCount) {
      height = this._minRows * lineHeight;
    } else if (this.maxRows && this.maxRows <= rowsCount) {
      const maxHeight = this.maxRows * lineHeight;
      height = maxHeight;
      this.textAreaEl.style.overflow = 'auto';
    } else {
      this.textAreaEl.style.overflow = 'hidden';
    }
    const isActive = document.activeElement === this.textAreaEl;
    const heightStyle = (height > this.minHeight && !isActive ? height + lineHeight : height) + 'px';
    this.textAreaEl.style.setProperty('height', heightStyle);
  }
}
