import { Metric } from '../../budget-object-details/components/details-metrics/details-metrics.type';
import { MetricListItem } from '../../metric-integrations/types/metric-integration';
import { Observable, of } from 'rxjs';
import { Budget } from '@shared/types/budget.interface';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { CompanyUserDO, CompanyUserPermissionDO } from '@shared/types/company-user-do.interface';
import { UserDO } from '@shared/types/user-do.interface';

export const capitalizeString = (input: string = '') => {
  if (!input) {
    return '';
  }

  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export const padStart = (target: string, len, filler = '0') => {
  return (filler.repeat(len) + target).slice(-len);
};

/**
 * Split filename into basename and extension
 */
export const getBaseNameAndExtension = (filename: string) => {
  const chunks = filename.split('.');
  let ext = chunks.pop();
  let basename = chunks.join('.');

  const noExtCase = chunks.length === 0;
  const noBasenameCase = basename.length === 0;
  if (noExtCase || noBasenameCase) {
    ext = '';
    basename = filename;
  }

  return {
    ext,
    basename
  };
};

export const createDeepCopy = <T> (source: T | []): T => {
  return JSON.parse(JSON.stringify(source)) as T;
};

export const deepSearch = <T extends { id: string }>(data: T[], targetID, nestedPropGetter: (v: T) => T[]) => {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (!item) {
      continue;
    }

    if (item.id === targetID) {
      return item;
    }

    const childValues = nestedPropGetter?.(item);
    if (childValues?.length) {
      const result = deepSearch(childValues, targetID, nestedPropGetter);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

export const roundDecimal = (value: number, precision = 0): number => {
  const factor = Math.pow(10, precision);

  return Math.round(value * factor) / factor;
};

export const getPercentage = (value: number, total: number): number => {
  return value / total * 100;
};

/**
 * Calculate 'share' (current / total) value in a range of [0, 100]
 */
export const calculateShareValue = (current: number, total: number, precision = 0) => {
  const value = roundDecimal(current / total * 100, precision);

  return value > 0 ? Math.min(value, 100) : 0;
};

export const sumUpNumericValues = (data: number[]): number => {
  return data.reduce((acc: number, item: number) => (
    acc + Number(item)
  ), 0);
};

export const sumUpByNumericKey = <T>(data: T[], key: string): number => {
  return data.reduce((acc: number, item: T) => (
    acc + Number(item[key])
  ), 0);
};

export const getNonNegativeValue = (value: number): number => {
  return Math.max(0, value);
};

/**
 * Parse provided by API - '<OBJECT_ID><DELIMITER><OBJECT_NAME>' like string key
 *  for ex.: - '1089:CustomMetric' -> objectID: 1089, objectName: CustomMetric
 *  Object's name may contain delimiters:
 *           - '1089:CustomMetric:Name:ContainsDelimiters' -> objectID: 1089, objectName: CustomMetric:Name:ContainsDelimiters
 */
export const parseCompositeObjectKey = (key: string, delimiter = ':'): { objectID: string, objectName: string } => {
  const progressKeyParts = key.split(delimiter);
  return {
    objectID: progressKeyParts.shift(),
    objectName: progressKeyParts.join(delimiter)
  };
};

export const generateGUID = () => {
  const hex = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (hex() + hex() + '-' + hex() + '-' + hex() + '-' + hex() + '-' + hex() + hex() + hex());
};

export const escapeRegExpPattern = (pattern: string): string => {
  // $& means the whole matched string
  return pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const createRegExpFromString = (pattern: string, flags: string): RegExp => {
  return new RegExp(escapeRegExpPattern(pattern), flags);
};

export const getTakenNames = (objectsList: Record<string, any>[], currentName: string): string[] => {
  let takenNames = objectsList.map(code => code.name?.toLowerCase());
  if (currentName) {
    takenNames = takenNames.filter(name => name !== currentName)
  }
  return takenNames;
};

export const getIdToNameMap = (objects: { id: number, name: string }[]): Record<number, string> => {
  return objects.reduce((result, objectType) => ({
    ...result,
    [objectType.id]: objectType.name
  }), {});
};

export const getNumericValue = (value: number): number => {
  return value || 0;
};

export const sumAndRound = (valueA: number, valueB: number, precision = 2): number => {
  return roundDecimal(
    getNumericValue(valueA) + getNumericValue(valueB),
    precision
  );
};

export function defaultMetricSorting(a: Metric | MetricListItem, b: Metric | MetricListItem): number {
  const byOrder = a.order - b.order;
  return byOrder !== 0 ? byOrder : a.name.localeCompare(b.name);
}

export function sortMetricsList(metricsList: Metric[] | MetricListItem[]): void {
  metricsList.sort((a, b) => {
    if (!a.productName && !b.productName) {
      // both are STANDALONE
      return defaultMetricSorting(a, b);
    }
    if (a.productName && b.productName) {
      // both are from PRODUCT
      const byProductOrder = a.productOrder - b.productOrder;
      return byProductOrder !== 0 ?
        byProductOrder :
        defaultMetricSorting(a, b); // both Metrics are related to the same Product
    }
    // only one is STANDALONE
    return a.productName ? 1 : -1;
  });
}


export function createRecordsTree<TRecord>(
  records: any[],
  lookupItemMapper: (item: any) => TRecord,
  idKey = 'id',
  parentIdKey = 'parentId'
): TRecord[] {
  const lookupTable = records.reduce((store: Record<string, TRecord>, rawRecord) => {
    store[rawRecord[idKey]] = lookupItemMapper(rawRecord);
    return store;
  }, {});

  return records.reduce( (campaignsTree: TRecord[], record: TRecord) => {
    const recordId = record[idKey];
    const parentId = record[parentIdKey];
    if (parentId && lookupTable[parentId]) {
      lookupTable[parentId].children.push(lookupTable[recordId]);
    } else {
      campaignsTree.push(lookupTable[recordId]);
    }
    return campaignsTree;
  }, []);
}

export function isCustomerSupportMember(email: string): boolean {
  const allowedDomains = [
    'plannuh.com',
    'planful.com',
    'cfosolutions.com',
    'bakerfieldsolutions.com',
    'kvcfo.com',
    'tolaram.com'
  ];
  const domain = email?.split('@')[1];
  return allowedDomains.includes(domain);
}

export function getUserPermissionLevel(user: UserDO, companyUser: CompanyUserDO, budgetId: number): UserPermissionLevel {
  if (!user || !companyUser || !budgetId) {
    console.error('Wrong parameters for "getUserPermissionLevel"')
    return UserPermissionLevel.None;
  }
  if (companyUser.is_admin) {
    return isCustomerSupportMember(user.email) ?
      UserPermissionLevel.Full : UserPermissionLevel.PartialWholeBudget
  } else {
    const allowedSegments = getAllowedSegments(companyUser.is_admin, companyUser.permissions, budgetId);
    return allowedSegments?.length ?
      UserPermissionLevel.PartialSomeSegment :
      UserPermissionLevel.None
  }
}

export function getAllowedSegments(isAdmin: boolean, permissions: CompanyUserPermissionDO[], budgetId: number): number[] | null {
  if (isAdmin) {
    return null;
  } else {
    const permissionForBudget = permissions.find(permissionItem => permissionItem.budget_id === budgetId);
    return permissionForBudget?.segments && permissionForBudget.read_write ?
      permissionForBudget.segments.map(seg => seg.id) : [];
  }
}

export function getCurrentBudget$(budgetDataService: BudgetDataService): Observable<Budget> {
  return budgetDataService.selectedBudgetSnapshot ?
    of(budgetDataService.selectedBudgetSnapshot) :
    budgetDataService.selectedBudget$;
}

export enum UserPermissionLevel {
  None,
  PartialSomeSegment,
  PartialWholeBudget,
  Full,
}

export function sortByField(field: string): any {
  return (firstValue: any, secondValue: any) => {
    if (firstValue[field] < secondValue[field]) {
      return -1;
    }
    if (firstValue[field] > secondValue[field]) {
      return 1;
    }
    return 0;
  }
}

export function getPseudoObjectId(objectId: number, segmentId: number): string {
  return `sub_${objectId}_${segmentId}`;
}
