import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogContext } from '@shared/types/dialog-context.interface';

export interface ImportingInfoContext extends DialogContext {
  fileName: string;
}

@Component({
  selector: 'importing-info-dialog',
  templateUrl: './importing-info-dialog.component.html',
  styleUrls: ['./importing-info-dialog.component.scss']
})
export class ImportingInfoDialogComponent {
  public context: ImportingInfoContext;

  constructor(
    @Inject(MAT_DIALOG_DATA) context: ImportingInfoContext
  ) {
    this.context = context;
  }
}
