import { Component, Input } from '@angular/core';

@Component({
  selector: 'expand-collapse',
  templateUrl: './expand-collapse.component.html',
  styleUrls: ['./expand-collapse.component.scss']
})

/**
* @class - ExpandCollapseComponent
*/
export class ExpandCollapseComponent {
  @Input() size = '13px';
  @Input() isExpanded = true;
}
