<div class="metric-progress-table">
  <div class="table-wrapper">
    <div class="sticky-columns">
      <table>
        <tr><th></th></tr>
        <tr *ngFor="let row of rows" class="data-row" [class.out-of-date]="row.outOfDate">
          <td>{{ row.label }}</td>
        </tr>
      </table>
    </div>
    <div class="table-content" [ngClass]="{'left-gradient': showLeftGradient, 'right-gradient': showRightGradient }">
      <div class="scroll-container" #scrollContainer scrollDetector (scrolled)="handleScroll();" [scrollContainerElement]="scrollContainer">
        <table>
          <tr *ngIf="columns && columns.length">
            <th *ngFor="let column of columns; let i = index;" [ngClass]="column.className">
              {{ column.label }}
            </th>
          </tr>
          <tr class="data-row" *ngFor="let row of rows" [class.out-of-date]="row.outOfDate">
            <td  *ngFor="let column of columns; let i = index;" [ngClass]="column.className">
              <ng-container *ngIf="tableData[row.name][column.name] != null">
                {{tableData[row.name][column.name] | number: row.numberFormat}}
              </ng-container>
              <ng-container *ngIf="tableData[row.name][column.name] === null">
                'N/A'
              </ng-container>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
