<widget-card [isLoading]="state === widgetState.LOADING">
  <div class="campaigns-summary">
    <details-widget-title [showCount]="false" text="Campaigns">
      <fa-duotone-icon [icon]="['fad', 'rocket-launch']" slot="icon"></fa-duotone-icon>
    </details-widget-title>
    <div class="content" *ngIf="state === widgetState.READY">
      <div class="top-row">
        <campaign-summary-card
          [value]="launched?.length"
          [action]="openLaunchedCampaigns.bind(this)"
          label="Launched YTD"
        >
          <icon-launched-campaign></icon-launched-campaign>
        </campaign-summary-card>
        <campaign-summary-card
          [value]="underWay?.length"
          [action]="openUnderWayCampaigns.bind(this)"
          label="Under way"
        >
          <icon-underway-campaign></icon-underway-campaign>
        </campaign-summary-card>
      </div>
      <div class="bottom-row">
        <campaign-summary-card
          [value]="preparing?.length"
          [action]="openPreparingToLaunchCampaigns.bind(this)"
          [label]="'Preparing to launch in ' + (currentTimeframe?.name | slice:0:3)"
        >
          <icon-preparing-campaign></icon-preparing-campaign>
        </campaign-summary-card>
        <campaign-summary-card
          [value]="concluded?.length"
          [action]="openConcludedCampaigns.bind(this)"
          label="Concluded"
        >
          <icon-concluded-campaign></icon-concluded-campaign>
        </campaign-summary-card>
      </div>
    </div>
  </div>
</widget-card>
