import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { Observable } from 'rxjs';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

export interface GLCode {
  id: number;
  name: string;
  description: string;
  company: number;
}

@Injectable({
  providedIn: 'root'
})
export class GlCodeService {
  private readonly actionV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPath = 'gl_code/';
  public apiPathAdmin = 'gl_code/list_for_admin/'

  getGlCodeList(data: object): Observable<GLCode[]> {
    return this.http.get<GLCode[]>(`${this.actionV2Url}${this.apiPath}`, getRequestOptions(data));
  }

  getGLCodesForAdmin(data: object): Observable<GLCode[]> {
    return this.http.get<GLCode[]>(`${this.actionV2Url}${this.apiPathAdmin}`, getRequestOptions(data))
  }

  deleteGlCode(id: number): Observable<void> {
    return this.http.delete<void>(`${this.actionV2Url}${this.apiPath}${id}/`);
  }

  addGlCode(data: object): Observable<GLCode> {
    return this.http.post<GLCode>(`${this.actionV2Url}${this.apiPath}`,  JSON.stringify(data));
  }

  editGlCode(data: object, id: number): Observable<GLCode> {
    return this.http.put<GLCode>(`${this.actionV2Url}${this.apiPath}${id}/`,  JSON.stringify(data));
  }
}
