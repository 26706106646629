import { ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'new-entity-label',
  templateUrl: './new-entity-label.component.html',
  styleUrls: ['./new-entity-label.component.scss']
})
export class NewEntityLabelComponent {
  private timerId: any;
  private activeTime = 60 * 60 * 1000;

  @HostBinding('class.left-side')
  @Input() leftSide: boolean;

  @HostBinding('class.show-label') showLabel = false;

  @Input() set createdDate(createdDate: Date) {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    if (!createdDate) {
      this.setLabelState(false);
      return;
    }
    const leftTime = this.getLeftTime(createdDate);

    if (leftTime > 0) {
      this.setLabelState(true);
      this.timerId = setTimeout(() => {
        this.setLabelState(false);
      }, leftTime);
    } else {
      this.setLabelState(false);
    }
  };

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  setLabelState(isVisible: boolean): void {
    this.showLabel = isVisible;
    this.cdr.markForCheck();
  }

  getLeftTime(value: Date): number {
    const today = new Date();
    const objectAge = today.getTime() - value.getTime();
    return this.activeTime - objectAge;
  }

}
