import { Injectable } from '@angular/core';
import {
  BudgetTableExpenseTotals,
  BudgetTableRecord,
  BudgetTableRecordType,
  BudgetTableRecordValues
} from '../components/budget-table/budget-table.types';
import { BudgetSegment } from 'app/shared/types/segment.interface';
import { SortParams } from 'app/shared/types/sort-params.interface';
import { UserOwner } from '../components/budget-settings-page/budget-settings-page.type';
import { ExpenseTotalsBySegments } from 'app/shared/types/expense-totals-by-segments.type';
import { createDeepCopy, generateGUID, roundDecimal } from 'app/shared/utils/common.utils';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { BudgetTableHelpers } from './budget-table-helpers.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTableValidationService } from './budget-table-validation.service';

interface UpdateRecordValuesPayload {
  fields: {
    projected?: boolean;
    total?: boolean;
    allocated?: number[];
  };
  rawValue: number;
  diffValue: number;
}

@Injectable({
  providedIn: 'root'
})
export class BudgetTableService {
  public readonly sortableFields = {
    segmentName: 'name'
  };
  private defaultSorting: SortParams = {
    column: this.sortableFields.segmentName,
    reverse: false
  };
  private groupRecordsByKey = new Map<string, BudgetTableRecord>();
  private groupsByKey = new Map<string, SegmentGroup>();
  private segmentsByKey = new Map<string, BudgetSegment>();
  private _grandTotal: BudgetTableRecordValues;
  private _remainingBudget: number;
  private _tableData: BudgetTableRecord[];
  private _appliedSorting: SortParams = this.defaultSorting;
  private _ownersByID: Record<number, UserOwner> = {};
  private _segmentsList: BudgetSegment[] = [];
  private _groupsList: SegmentGroup[] = [];
  private _objectToFocus: string;

  public currentBudget: Budget;
  public rawExpenseTotals: ExpenseTotalsBySegments = {};
  public tableExpenseTotals: BudgetTableExpenseTotals = {
    segments: {},
    groups: {},
    grandTotal: {
      allocated: {},
      projected: 0,
      total: 0
    }
  };

  constructor(
    private readonly budgetTableValidationService: BudgetTableValidationService
  ) {}

  get grandTotal() {
    return this._grandTotal;
  }

  get remainingBudget() {
    return this._remainingBudget;
  }

  get tableData() {
    return this._tableData;
  }

  get appliedSorting() {
    return this._appliedSorting;
  }

  get segmentsList() {
    return this._segmentsList;
  }

  get groupsList() {
    return this._groupsList;
  }

  get objectToFocus() {
    return this._objectToFocus;
  }

  public setSegmentsList(segments: BudgetSegment[]) {
    this._segmentsList = segments;
    this.segmentsByKey.clear();
    segments.forEach(segment => {
      this.segmentsByKey.set(segment.key, segment);
    });
  }

  public setGroupsList(groups: SegmentGroup[]) {
    this._groupsList = groups;
    this.groupsByKey.clear();
    groups.forEach(group => {
      this.groupsByKey.set(group.key, group);
    });
  }

  public setObjectToFocus(key: string) {
    this._objectToFocus = key;
  }

  public getSegmentByKey(key: string): BudgetSegment {
    const targetSegment = this.segmentsByKey.get(key);
    if (targetSegment) {
      return targetSegment;
    }

    return this.segmentsList.find(segment => segment.key === key);
  }

  public getGroupByKey(key: string): SegmentGroup {
    const targetGroup = this.groupsByKey.get(key);
    if (targetGroup) {
      return targetGroup;
    }

    return this.groupsList.find(group => group.key === key);
  }

  public getGroupRecordByKey(key: string): BudgetTableRecord {
    const targetRecord = this.groupRecordsByKey.get(key);
    if (targetRecord) {
      return targetRecord;
    }

    return this.tableData.find(record => record.key === key);
  }

  private prepareGrandTotal(data: BudgetTableRecord[] = []): BudgetTableRecordValues {
    let grandTotal: BudgetTableRecordValues = {
      allocated: {},
      projected: 0,
      total: 0
    };

    data.forEach((record) => {
      grandTotal = BudgetTableHelpers.sumRecordValues(grandTotal, record.values);
    });

    return grandTotal;
  }

  private processSegmentRecords(budgetSegments: BudgetSegment[] = []): BudgetTableRecord[] {
    return budgetSegments.map(budgetSegment => BudgetTableHelpers.mapSegmentToRecord(budgetSegment));
  }

  private groupRecords(rawRecords: BudgetTableRecord[], groups: SegmentGroup[]): BudgetTableRecord[] {
    const singleRecords: BudgetTableRecord[] = [];
    const recordsByGroup: Record<number, BudgetTableRecord[]> = {};

    rawRecords.forEach(record => {
      const { segmentGroupKey } = record;
      if (!segmentGroupKey) {
        singleRecords.push(record);
        return;
      }

      if (!Array.isArray(recordsByGroup[segmentGroupKey])) {
        recordsByGroup[segmentGroupKey] = [];
      }

      recordsByGroup[segmentGroupKey] = [
        ...recordsByGroup[segmentGroupKey],
        record
      ];
    });
    this.groupRecordsByKey.clear();

    const groupRecords: BudgetTableRecord[] = groups
      // There shouldn't be empty groups
      .filter(group => recordsByGroup[group.key] && recordsByGroup[group.key].length)
      .map(group => {
        const nestedRecords = recordsByGroup[group.key] || [];
        const groupRecord = BudgetTableHelpers.mapGroupToRecord(group, nestedRecords);

        this.groupRecordsByKey.set(groupRecord.key, groupRecord);

        return groupRecord;
      });

    return [
      ...singleRecords,
      ...groupRecords
    ];
  }

  private provideSegmentGroupKeys() {
    this._segmentsList.map(budgetSegment => {
      const segmentGroup = this._groupsList.find(group => group.id === budgetSegment.segmentGroup);
      if (segmentGroup) {
        budgetSegment.segmentGroupKey = segmentGroup.key;
      }
    });
  }

  private updateExpenseTotalsGrandTotal(grandTotal: BudgetTableRecordValues, segmentValues: Record<number, number>, segmentTotal: number) {
    Object.entries(segmentValues).forEach(entry => {
      const [ timeframeId, value ] = entry;
      if (!grandTotal.allocated.hasOwnProperty(timeframeId)) {
        grandTotal.allocated[timeframeId] = 0;
      }
      grandTotal.allocated[timeframeId] = roundDecimal(grandTotal.allocated[timeframeId] + value, 2);
    });

    grandTotal.total = roundDecimal(grandTotal.total + segmentTotal, 2);
  }

  private initGrandTotal() {
    this._grandTotal = this.prepareGrandTotal(this._tableData);
  }

  private initTableData() {
    const processedRecords = this.processSegmentRecords(this.segmentsList);

    this._tableData = this.groupRecords(processedRecords, this.groupsList);
    BudgetTableHelpers.provideWithOwnerDetails(this._tableData, this._ownersByID);
  }

  private getRecordOriginObject(record: BudgetTableRecord): BudgetSegment | SegmentGroup {
    if (record.type === BudgetTableRecordType.Group) {
      return this.getGroupByKey(record.key);
    }

    return this.getSegmentByKey(record.key);
  }

  private getRelevantGroupsList(): SegmentGroup[] {
    const relevantGroupKeys = new Set();
    this.segmentsList.forEach(segment => {
      if (segment.segmentGroupKey) {
        relevantGroupKeys.add(segment.segmentGroupKey);
      }
    });

    return this.groupsList.filter(group => relevantGroupKeys.has(group.key));
  }

  public sortData() {
    BudgetTableHelpers.sortData(this._appliedSorting, this._tableData);
  }

  public processOwnersList(owners: UserOwner[]) {
    owners.forEach(owner => this._ownersByID[owner.id] = owner);
    if (this._tableData) {
      BudgetTableHelpers.provideWithOwnerDetails(this._tableData, this._ownersByID);
    }
  }

  public reloadState() {
    this.initTableData();
    this.prepareExpenseTotals();
    this.initGrandTotal();
    this.sortData();
  }

  public initState() {
    this.provideSegmentGroupKeys();
    this.reloadState();
  }

  public prepareExpenseTotals() {
    const segments: Record<number, BudgetTableRecordValues> = {};
    const groups: Record<number, BudgetTableRecordValues> = {};
    const grandTotal = {
      allocated: {},
      total: 0
    };

    this.segmentsList
      .filter(segment => this.rawExpenseTotals[segment.id])
      .forEach(segment => {
        const segmentId = segment.id;
        const segmentValues = this.rawExpenseTotals[segmentId];
        const segmentTotal = Object.values(segmentValues)
          .reduce((acc, value) => roundDecimal(acc + value, 2), 0);

        this.updateExpenseTotalsGrandTotal(grandTotal, segmentValues, segmentTotal);
        segments[segmentId] = {
          allocated: segmentValues,
          total: segmentTotal
        };

        if (!segment.segmentGroupKey) {
          return;
        }

        if (!groups[segment.segmentGroupKey]) {
          groups[segment.segmentGroupKey] = {
            allocated: {},
            total: 0
          };
        }

        groups[segment.segmentGroupKey] = BudgetTableHelpers.sumRecordValues(
          groups[segment.segmentGroupKey],
          { allocated: segmentValues, total: segmentTotal }
        );
      });

    this.tableExpenseTotals = {
      grandTotal,
      groups,
      segments
    };
  }

  public updateRecordOriginObject(record: BudgetTableRecord, update: { owner?: number; name?: string; }) {
    const target = this.getRecordOriginObject(record);
    if (!target) {
      throw new Error('Failed to get record origin object');
    }

    Object.keys(update).forEach(key => { target[key] = update[key]; });
    return target;
  }

  public updateTableRecord(record: BudgetTableRecord, update: Partial<BudgetTableRecord>) {
    Object.keys(update).forEach(key => { record[key] = update[key]; });
  }

  public updateGrandTotal(payload: Partial<UpdateRecordValuesPayload>) {
    const { fields, diffValue } = payload;

    if (fields.total) {
      this._grandTotal.total += diffValue;
    }

    if (fields.projected) {
      this._grandTotal.projected += diffValue;
    }

    if (fields.allocated) {
      fields.allocated.forEach((allocId) => {
        this._grandTotal.allocated[allocId] += diffValue;
      });
    }
  }

  public updateTableRecordValues(record: BudgetTableRecord, payload: Partial<UpdateRecordValuesPayload>) {
    const { fields, rawValue, diffValue } = payload;
    const targetGroup = record.segmentGroupKey
      ? this.getGroupRecordByKey(record.segmentGroupKey)
      : null;

    if (fields.projected) {
      record.values.projected = rawValue;

      if (targetGroup) {
        targetGroup.values.projected += diffValue;
      }
    }

    if (fields.total) {
      record.values.total += diffValue;

      if (targetGroup) {
        targetGroup.values.total += diffValue;
      }
    }

    if (fields.allocated) {
      fields.allocated.forEach((allocId) => {
        record.values.allocated[allocId] = rawValue;
        if (targetGroup) {
          targetGroup.values.allocated[allocId] += diffValue;
        }
      });
    }
  }

  public applySorting(columnName: string) {
    if (this.appliedSorting.column === columnName) {
      this.appliedSorting.reverse = !this.appliedSorting.reverse;
    } else {
      this.appliedSorting.column = columnName;
      this.appliedSorting.reverse = false;
    }
    this.sortData();
  }

  public setRemainingBudget(remaining: number) {
    this._remainingBudget = remaining;
  }

  /* ACTION HANDLERS */
  public restoreRemovedSegments(segments: BudgetSegment[]) {
    this.setSegmentsList([ ...segments, ...this.segmentsList ]);
    this.reloadState();
  }

  public addNewSegment(budgetAllocations: BudgetTimeframe[]) {
    const newSegment = BudgetTableHelpers.createSegment(budgetAllocations, this.currentBudget?.id);
    const createdRecord = BudgetTableHelpers.mapSegmentToRecord(newSegment);

    this.segmentsByKey.set(newSegment.key, newSegment);
    this.setObjectToFocus(newSegment.key);
    this.segmentsList.unshift(newSegment);
    this.tableData.unshift(createdRecord);
  }

  public addGroupSegment(budgetAllocations: BudgetTimeframe[], group: BudgetTableRecord) {
    const newSegment = BudgetTableHelpers.createSegment(budgetAllocations, this.currentBudget?.id);

    newSegment.segmentGroup = group.id;
    newSegment.segmentGroupKey = group.key;
    newSegment.owner = group.owner?.id;
    this.segmentsByKey.set(newSegment.key, newSegment);
    this.setObjectToFocus(newSegment.key);
    this.segmentsList.unshift(newSegment);
    this.reloadState();
  }

  public duplicateSegments(selectedSegmentKeys: string[]) {
    const createdSegments = createDeepCopy(selectedSegmentKeys.map(key => {
      const sourceSegment = this.getSegmentByKey(key);
      if (sourceSegment) {
        return BudgetTableHelpers.duplicateSegment(sourceSegment, {
          name: this.budgetTableValidationService.createUniqueNameCopy(sourceSegment.name)
        });
      }
    })).filter(segment => !!segment);

    this.setSegmentsList([ ...createdSegments, ...this.segmentsList ]);
    this.reloadState();
  }

  public removeSegments(selectedSegmentKeys: string[]) {
    const deletedSegments = [];
    const restSegments = [];

    this.segmentsList.forEach((segment) => {
      selectedSegmentKeys.includes(segment.key)
        ? deletedSegments.push(segment)
        : restSegments.push(segment)
    });
    this.setSegmentsList(restSegments);

    const updatedGroupsList = this.getRelevantGroupsList();

    this.setGroupsList(updatedGroupsList);
    this.reloadState();

    return {
      deletedSegments,
      restSegments
    };
  }

  public groupSegments(selectedSegmentKeys: string[]) {
    const nestedRecords = this._tableData.filter(record => (
      record.type === BudgetTableRecordType.Segment && selectedSegmentKeys.includes(record.key)
    ));
    const newGroup: SegmentGroup = BudgetTableHelpers.createGroup({
      key: generateGUID(),
      budget: this.currentBudget?.id
    });
    const newGroupRecord: BudgetTableRecord = BudgetTableHelpers.mapGroupToRecord(newGroup, nestedRecords);
    const changedSegments = selectedSegmentKeys.map(key => {
      const segment = this.getSegmentByKey(key);

      segment.segmentGroupKey = newGroupRecord.key;

      return segment;
    });

    this.groupsByKey.set(newGroup.key, newGroup);
    this.groupsList.unshift(newGroup);
    this.reloadState();
    this.setObjectToFocus(newGroup.key);

    return {
      group: newGroupRecord,
      segments: changedSegments
    };
  }

  public duplicateGroups(selectedSegmentKeys: string[]) {
    const duplicatedSegments: BudgetSegment[] = [];
    const duplicatedGroups = selectedSegmentKeys
      .map(key => this.getGroupByKey(key))
      .map(sourceGroup => {
        const segmentsToDuplicate = this._segmentsList.filter(segment => segment.segmentGroupKey === sourceGroup.key);
        const duplicateGroupKey = generateGUID();

        segmentsToDuplicate.forEach(sourceSegment => {
          const duplicatedSegment = BudgetTableHelpers.duplicateSegment(sourceSegment, {
            name: this.budgetTableValidationService.createUniqueNameCopy(sourceSegment.name),
            segmentGroup: null,
            segmentGroupKey: duplicateGroupKey
          });
          duplicatedSegments.push(duplicatedSegment);
        });

        return BudgetTableHelpers.duplicateGroup(sourceGroup, {
          key: duplicateGroupKey,
          name: this.budgetTableValidationService.createUniqueNameCopy(sourceGroup.name),
        });
      });

    this.setGroupsList([ ...duplicatedGroups, ...this._groupsList ]);
    this.setSegmentsList([ ...duplicatedSegments, ...this._segmentsList ]);
    this.reloadState();
  }

  public ungroupSegments(selectedSegmentKeys: string[]) {
    const selectedSegments = selectedSegmentKeys.map(key => {
      const segment = this.getSegmentByKey(key);

      segment.segmentGroup = null;
      segment.segmentGroupKey = null;

      return segment;
    });
    const updatedGroupsList = this.getRelevantGroupsList();

    this.setGroupsList(updatedGroupsList);
    this.reloadState();

    return {
      segments: selectedSegments
    };
  }

  public moveSegments(selectedSegmentKeys: string[], targetGroup: SegmentGroup) {
    const selectedSegments = selectedSegmentKeys.map(key => {
      const segment = this.getSegmentByKey(key);

      segment.segmentGroup = targetGroup.id;
      segment.segmentGroupKey = targetGroup.key;

      return segment;
    });
    const updatedGroupsList = this.getRelevantGroupsList();

    this.setGroupsList(updatedGroupsList);
    this.reloadState();

    return {
      segments: selectedSegments,
      group: targetGroup
    };
  }
}
