<widget-card [isLoading]="state === widgetState.LOADING">
  <div class="update-metrics">
    <details-widget-title [count]="tableData.length" text="Update my metrics">
      <fa-duotone-icon slot="icon" [fixedWidth]="true" [icon]="['fad', 'chart-line']"></fa-duotone-icon>
    </details-widget-title>
    <div class="content" [ngClass]="{
      'is-loading': state === widgetState.LOADING,
      'is-ready': state === widgetState.READY
    }">
      <update-metrics-table
        [data]="tableData"
        [currencySymbol]="currencySymbol"
        [isReadOnly]="isReadOnly"
        (onCurrentValueChange)="handleCurrentValueChange($event)"
      ></update-metrics-table>
      <widget-chart-legend [items]="legendItems"></widget-chart-legend>
    </div>
  </div>
</widget-card>
