import { ChangeDetectorRef, Component, inject, OnInit, Renderer2 } from '@angular/core';
import { UtilityService } from 'app/shared/services/utility.service';

@Component({
  selector: 'root-loader',
  templateUrl: './root-loader.component.html',
  styleUrls: ['./root-loader.component.scss'],
})
export class RootLoaderComponent implements OnInit {
  public loadingState = false;
  private r2 = inject(Renderer2);

  constructor(
    private _utilityService: UtilityService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.cdr.detach();
    this._utilityService.loadingPropertyChanged$.subscribe(state => {
      if (this.loadingState === state) {
        return;
      }
      this.loadingState = state;
      this.r2[this.loadingState ? 'addClass' : 'removeClass'](document.body, 'root-loading');
      this.cdr.detectChanges();
    })
  }
}
