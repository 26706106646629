import { Component, Input } from '@angular/core';

@Component({
  selector: 'float-error-message',
  templateUrl: './float-error-message.component.html',
  styleUrls: ['./float-error-message.component.scss']
})
export class FloatErrorMessageComponent {
  @Input() errorMessage: string;
}
