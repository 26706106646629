import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { ActualSpendsData } from '../../../budget-object-details/components/budget-allocations-table/budget-allocations-table.type';

@Injectable({
  providedIn: 'root'
})
export class BudgetAllocationService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPath = 'company_budget_allocation/';

  getBudgetAllocations(budgetId: number): Observable<BudgetTimeframe[]> {
    return this.http.get<BudgetTimeframe[]>(`${this.apiV2Url}${this.apiPath}`, getRequestOptions({ budget: budgetId }));
  }

  updateBudgetAllocation(allocationId: number, data: Partial<BudgetTimeframe>): Observable<BudgetTimeframe> {
    return this.http.patch<BudgetTimeframe>(`${this.apiV2Url}${this.apiPath}${allocationId}/`, JSON.stringify(data));
  }

  updateListOfBudgetAllocations(data: { id: number; amount: number; locked: boolean }[]): Observable<any> {
    return this.http.patch(`${this.apiV2Url}${this.apiPath}multi_update/`, JSON.stringify(data));
  }

  getExpenses(campId:number, timeframeId:number, companyId, budgetId ,isGoal:boolean): Observable<ActualSpendsData[]> {
    const url = this.apiV2Url + 'expense/getactualspends/';
    const params = {
      "period": timeframeId,
      "cgid": campId,
      "company": companyId,
      "budget": budgetId,
      "iscamp":!isGoal
    };
    return this.http.get<ActualSpendsData[]>(url, getRequestOptions(params));    
  }

  getRemainingAmount(campId:number, allocationId:number, companyId, budgetId ,isGoal:boolean, isCommit:boolean): Observable<ActualSpendsData[]> {
    const url = this.apiV2Url + 'budget/get_planned_and_commited_summary/';
    const params = {
      "company_budget_allocation_ids": allocationId,
      "cgid": campId,
      "company": companyId,
      "budget": budgetId,
      "iscamp":!isGoal,
      "calc_commit":isCommit
    };
    return this.http.get<ActualSpendsData[]>(url, getRequestOptions(params));    
  }
}
