import {
  ManageTableData,
  ManageTableRow,
} from '../components/manage-table/manage-table.types';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { Goal } from '@shared/types/goal.interface';
import { Program } from '@shared/types/program.interface';
import { Campaign } from '@shared/types/campaign.interface';
import { ParentChildMap } from '@shared/types/parent-child-map.type';
import { ObjectMode } from '@shared/enums/object-mode.enum';
import { ManageTableHelpers } from './manage-table-helpers';
import { ManageTableDataBuilderInputs } from '../types/manage-table-data-inputs.type';
import { ManageTableViewMode } from '../types/manage-table-view-mode.type';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SegmentGroup } from '@shared/types/segment-group.interface';
import { Budget } from '@shared/types/budget.interface';
import { ListTreeItem } from '@shared/components/full-hierarchy-tree/full-hierarchy-tree.component';
import { PlanObjectExpensesData } from '@shared/types/plan-object-expenses-data.type';
import { Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LightCampaign } from '@shared/types/campaign.interface';
import { LightProgram } from '@shared/types/program.interface';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { getPseudoObjectId } from '@shared/utils/common.utils';

export class ManageTableFastDataBuilder {
  private parentChildMap: ParentChildMap<ManageTableRow> = {};
  private data: ManageTableData = [];
  private segmentGroupsData: ManageTableData = [];
  private segmentsData: ManageTableData = [];
  private goalsData: ManageTableData = [];
  private campaignsData: ManageTableData = [];
  private expGroupsData: ManageTableData = [];
  private dataInputs: ManageTableDataBuilderInputs;
  private flatDataMap: Record<string, ManageTableRow> = {};
  private allObjectIdsByType: Record<string, Record<number | string, number>>;
  private currentObjectIdsByType: Record<string, Record<number | string, number>>;
  private cellDataLoaded = false;
  private cellDataLoading = false;

  public static createRow(
    object,
    objectType: ManageTableRowType,
    timeframes: BudgetTimeframe[],
    budget: Budget,
    viewMode?: ManageTableViewMode,
    objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean,
    expensesData?: PlanObjectExpensesData,
    sharedCostPercent?: number
  ): ManageTableRow {
    switch (objectType) {
      case ManageTableRowType.Campaign:
        return ManageTableFastDataBuilder.createCampaignRow(
          object,
          timeframes,
          budget,
          viewMode,
          objectExists,
          expensesData,
          sharedCostPercent
        );

      case ManageTableRowType.ExpenseGroup:
        return ManageTableFastDataBuilder.createExpGroupRow(
          object,
          timeframes,
          budget,
          viewMode,
          objectExists,
          expensesData,
          sharedCostPercent
        );

      case ManageTableRowType.Goal:
        return ManageTableFastDataBuilder.createGoalRow(object, timeframes);

      default:
        return null;
    }
  }

  private static getParentSubIdForObject<T extends LightProgram | LightCampaign>(
    obj: T,
    campaignId: number,
    customSegmentId?: number
  ): string {
    return campaignId ? getPseudoObjectId(campaignId, customSegmentId || obj.budgetSegmentId) : null;
  }

  private static createInitialExpGroupRow(
    expGroup: LightProgram,
    viewMode?: ManageTableViewMode,
    objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean,
  ): ManageTableRow {
    return {
      name: expGroup.name,
      type: ManageTableRowType.ExpenseGroup,
      objectId: expGroup.id,
      itemId: expGroup.objectId,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.ExpenseGroup, expGroup.objectId),
      parentId: ManageTableHelpers.getParentId({
        goal: expGroup.goalId,
        campaign: expGroup.campaignId,
        segment: expGroup.budgetSegmentId,
        viewMode,
        objectExists
      }),
      segmentId: expGroup.budgetSegmentId,
      sharedCostRuleId: expGroup.splitRuleId,
      isEditable: !expGroup.isPseudoObject,
      isSelectable: !expGroup.isPseudoObject,
      isClosed: expGroup.mode === ObjectMode.Closed,
      children: [],
      shownChildren: [],
      objectTypeId: expGroup.programTypeId,
      segmentRelated: false,
      isOwnDataReady: false,
      isChildDataReady: false,
    };
  }

  public static createExpGroupRow(
    expGroup: Program,
    timeframes: BudgetTimeframe[],
    budget: Budget,
    viewMode?: ManageTableViewMode,
    objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean,
    expensesData?: PlanObjectExpensesData,
    sharedCostPercent?: number,
    selectedTimeframes?: BudgetTimeframe[]
  ): ManageTableRow {
    const { values, total, spending } = ManageTableHelpers.mapObjectValues(
      expGroup,
      timeframes,
      budget,
      expensesData,
      viewMode,
      sharedCostPercent,
      selectedTimeframes
    );

    return {
      ...ManageTableFastDataBuilder.createInitialExpGroupRow(expGroup, viewMode, objectExists),
      externalId: expGroup.externalId,
      values,
      total,
      spending,
      allocations: expGroup.timeframes,
      createdDate: expGroup.createdDate,
      isOwnDataReady: true
    };
  }

  public static createInitialCampaignRow(
    campaign: LightCampaign,
    viewMode?: ManageTableViewMode,
    objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean
  ): ManageTableRow {
    const campaignRow = {
      name: campaign.name,
      type: ManageTableRowType.Campaign,
      itemId: campaign.objectId,
      objectId: campaign.id,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.Campaign, campaign.objectId),
      parentId: ManageTableHelpers.getParentId({
        goal: campaign.goalId,
        segment: campaign.budgetSegmentId,
        campaign: campaign.parentCampaign,
        viewMode,
        objectExists
      }),
      segmentId: campaign.budgetSegmentId,
      sharedCostRuleId: campaign.splitRuleId,
      isEditable: !campaign.isPseudoObject,
      isSelectable: !campaign.isPseudoObject,
      isClosed: campaign.mode === ObjectMode.Closed,
      children: [],
      shownChildren: [],
      objectTypeId: campaign.campaignTypeId,
      segmentRelated: false,
      isOwnDataReady: false,
      isChildDataReady: false
    };

    campaignRow.isEditable &&= !ManageTableHelpers.isSegmentlessObject(campaignRow);
    return campaignRow;
  }

  public static createCampaignRow(
    campaign: Campaign,
    timeframes: BudgetTimeframe[],
    budget: Budget,
    viewMode?: ManageTableViewMode,
    objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean,
    expensesData?: PlanObjectExpensesData,
    sharedCostPercent?: number,
    selectedTimeframes?: BudgetTimeframe[]
  ): ManageTableRow {
    const { values, total, spending } = ManageTableHelpers.mapObjectValues(
      campaign,
      timeframes,
      budget,
      expensesData,
      viewMode,
      sharedCostPercent,
      selectedTimeframes
    );

    return {
      ...ManageTableFastDataBuilder.createInitialCampaignRow(campaign, viewMode, objectExists),
      values,
      total,
      spending,
      allocations: campaign.timeframes,
      createdDate: campaign.createdDate,
    };
  }

  public static createInitialGoalRow(goal: Goal): ManageTableRow {
    return {
      name: goal.name,
      type: ManageTableRowType.Goal,
      objectId: goal.id,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.Goal, goal.id),
      itemId: goal.id,
      parentId: null,
      isEditable: false,
      isSelectable: true,
      children: [],
      shownChildren: [],
      createdDate: goal.createdDate,
      objectTypeId: goal.goalTypeId,
      segmentRelated: false,
      isOwnDataReady: true,
      isChildDataReady: false
    };
  }

  public static createGoalRow(goal: Goal, timeframes: BudgetTimeframe[]): ManageTableRow {
    return {
      ...ManageTableFastDataBuilder.createInitialGoalRow(goal),
      values: ManageTableHelpers.initTimeframeValues(timeframes),
      total: {
        allocated: 0,
        spent: 0
      },
      isChildDataReady: true
    };
  }

  private static createInitialSegmentRow(segment: BudgetSegmentAccess, viewMode?: ManageTableViewMode): ManageTableRow {
    return {
      name: segment.name,
      type: ManageTableRowType.Segment,
      objectId: segment.id,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.Segment, segment.id),
      itemId: segment.id,
      parentId: ManageTableHelpers.getParentId({
        segmentGroup: segment.segment_group,
        viewMode,
      }),
      isEditable: false,
      isSelectable: true,
      children: [],
      shownChildren: [],
      segmentRelated: true,
      isOwnDataReady: true,
      isChildDataReady: false
    };
  }

  private static createInitialSegmentGroupRow(segmentGroup: SegmentGroup): ManageTableRow {
    return {
      name: segmentGroup.name,
      type: ManageTableRowType.SegmentGroup,
      objectId: segmentGroup.id,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.SegmentGroup, segmentGroup.id),
      itemId: segmentGroup.id,
      parentId: null,
      isEditable: false,
      isSelectable: true,
      children: [],
      shownChildren: [],
      segmentRelated: true,
      isOwnDataReady: true,
      isChildDataReady: false
    };
  }

  private static hasItemsForDisplay(tableRow: ManageTableRow): boolean {
    return !tableRow.isFilteredOut || tableRow.children.some(children => ManageTableFastDataBuilder.hasItemsForDisplay(children));
  }

  public objectExists(objectType: ManageTableRowType, objectId: number | string, segmentId?: number): boolean {
    const objTypeRecord = objectId && this.allObjectIdsByType[objectType];
    return segmentId ? objTypeRecord?.[objectId] === segmentId : Boolean(objTypeRecord?.hasOwnProperty(objectId));
  }

  private getChildItems(parentType: string, parentId: number | string, childType: string): ManageTableRow[] {
    return this.parentChildMap[parentType]?.[parentId]?.[childType] || [];
  }

  private accessChildList(parentType: string, parentId: number | string, childType: string): ManageTableRow[] {
    if (!this.parentChildMap[parentType]?.[parentId]) {
      this.parentChildMap[parentType][parentId] = {
        [ManageTableRowType.Segment]: [],
        [ManageTableRowType.Campaign]: [],
        [ManageTableRowType.ExpenseGroup]: []
      };
    }

    return this.parentChildMap[parentType][parentId][childType];
  }

  private addChildItem(
    parent: { objectType: ManageTableRowType; id: number | string },
    child: { objectType: ManageTableRowType; item: ManageTableRow }
  ) {
    const childList = this.accessChildList(parent.objectType, parent.id, child.objectType);
    if (!Array.isArray(childList)) {
      console.warn('Failed to add child item', child);
      return;
    }

    childList.push(child.item);
  }

  private addFlatDataRow(row: ManageTableRow) {
    this.flatDataMap[row.id] = row;
  }

  private isRowFilteredOut(row: ManageTableRow) {
    const alwaysShownTypes = [ManageTableRowType.SegmentGroup, ManageTableRowType.Segment];
    return this.dataInputs.isFilterMode &&
      !alwaysShownTypes.includes(row.type) &&
      this.currentObjectIdsByType[row.type] &&
      !(row.itemId in this.currentObjectIdsByType[row.type]);
  }

  private processObjects<TObjectType>(
    allObjects: TObjectType[],
    rowCreator: (
      obj: TObjectType,
      viewMode?: ManageTableViewMode,
      objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean
    ) => ManageTableRow,
    rowAssigner: (obj: TObjectType, row: ManageTableRow) => void,
    viewMode?: ManageTableViewMode
  ) {
    for (const obj of allObjects) {
      const row = rowCreator(obj, viewMode, this.objectExists.bind(this));

      if (viewMode === ManageTableViewMode.Segments) {
        const composedIdData = row.parentId?.split('_');
        const parentObject =
          (composedIdData?.[0] === ManageTableRowType.Campaign.toLowerCase() && composedIdData.length === 2) ?
            this.dataInputs.campaigns.find(camp => camp.id === +composedIdData[1]) :
            null;

        if (parentObject && !parentObject?.budgetSegmentId) {
          row.hierarchyInfo = [
            {
              id: parentObject.objectId,
              name: parentObject.name,
              type: ManageTableRowType.Campaign,
              highlighted: true,
            },
            {
              id: row.objectId,
              name: row.name,
              type: row.type,
              highlighted: false,
            },
          ]
        }
      }

      row.isFilteredOut = this.isRowFilteredOut(row);
      rowAssigner(obj, row);
    }
  }

  private tryToAssignSegmentedObjectRow(
    row: ManageTableRow,
    segmentId: number,
    parentType: ManageTableRowType,
    parentId: number | string,
    parentSubObjectId: string
  ): boolean {
    if (this.objectExists(parentType, parentId, segmentId)) {
      this.addChildItem(
        { objectType: parentType, id: parentId },
        { objectType: row.type, item: row }
      );
      return true;
    }

    if (this.objectExists(parentType, parentSubObjectId, segmentId)) {
      this.addChildItem(
        { objectType: parentType, id: parentSubObjectId },
        { objectType: row.type, item: row }
      );
      return true;
    }

    return false;
  }

  private assignExpenseGroupRowInBySegmentMode(expGroup: LightProgram, expGroupRow: ManageTableRow): void {
    const parentSubObjectId = ManageTableFastDataBuilder.getParentSubIdForObject(expGroup, expGroup.campaignId);

    const assignToSegment = () => {
      if (this.objectExists(ManageTableRowType.Segment, expGroup.budgetSegmentId)) {
        this.addChildItem(
          { objectType: ManageTableRowType.Segment, id: expGroup.budgetSegmentId },
          { objectType: ManageTableRowType.ExpenseGroup, item: expGroupRow }
        );
      } else {
        console.warn(
          `[ManageTableFastDataBuilder]: assignExpenseGroupRow(): Expense group with id ${expGroup.id} does not have a segment`
        );
      }
    };

    if (this.tryToAssignSegmentedObjectRow(
      expGroupRow,
      expGroup.budgetSegmentId,
      ManageTableRowType.Campaign,
      expGroup.campaignId,
      parentSubObjectId
    )) {
      return;
    }

    let campaign: LightCampaign;
    if (this.objectExists(ManageTableRowType.Campaign, expGroup.campaignId)) {
      campaign = this.dataInputs.campaigns.find(c => c.id === expGroup.campaignId);
    } else if (this.objectExists(ManageTableRowType.Campaign, parentSubObjectId)) {
      campaign = this.dataInputs.campaigns.find(c => c.objectId === parentSubObjectId);
    }

    if (campaign) {
      const parentCampaignSubObjectId =
        ManageTableFastDataBuilder.getParentSubIdForObject(campaign, campaign.parentCampaign, expGroup.budgetSegmentId);

      if (this.tryToAssignSegmentedObjectRow(
        expGroupRow,
        expGroup.budgetSegmentId,
        ManageTableRowType.Campaign,
        campaign.parentCampaign,
        parentCampaignSubObjectId
      )) {
        return;
      }
      assignToSegment();
    } else {
      assignToSegment();
    }
  }

  private assignExpenseGroupRow(expGroup: LightProgram, expGroupRow: ManageTableRow) {
    if (this.dataInputs.viewMode === ManageTableViewMode.Segments) {
      this.assignExpenseGroupRowInBySegmentMode(expGroup, expGroupRow);
    } else if (this.objectExists(ManageTableRowType.Campaign, expGroup.campaignId)) {
      this.addChildItem(
        { objectType: ManageTableRowType.Campaign, id: expGroup.campaignId },
        { objectType: ManageTableRowType.ExpenseGroup, item: expGroupRow }
      );
    } else if (this.dataInputs.viewMode === ManageTableViewMode.Goals) {
      if (this.objectExists(ManageTableRowType.Goal, expGroup.goalId)) {
        this.addChildItem(
          { objectType: ManageTableRowType.Goal, id: expGroup.goalId },
          { objectType: ManageTableRowType.ExpenseGroup, item: expGroupRow }
        );
      } else {
        this.expGroupsData.push(expGroupRow);
      }
    } else {
      this.expGroupsData.push(expGroupRow);
    }

    this.addFlatDataRow(expGroupRow);
  }

  private processExpGroups() {
    this.processObjects(
      this.dataInputs.expGroups,
      (
        expGroup: Program,
        viewMode?: ManageTableViewMode,
        objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean
      ) => ManageTableFastDataBuilder.createInitialExpGroupRow(expGroup, viewMode, objectExists),
      this.assignExpenseGroupRow.bind(this),
      this.dataInputs.viewMode
    );
  }

  private assignCampaignRowInBySegmentMode(campaign: LightCampaign, campaignRow: ManageTableRow, isChildCampaign: boolean) {
    const assignToSegment = () => {
      if (this.objectExists(ManageTableRowType.Segment, campaign.budgetSegmentId)) {
        this.addChildItem(
          { objectType: ManageTableRowType.Segment, id: campaign.budgetSegmentId },
          { objectType: ManageTableRowType.Campaign, item: campaignRow }
        );
      } else {
        console.warn(
          `[ManageTableFastDataBuilder]: assignExpenseCampaignRowInBySegmentMode(): Campaign with id ${campaign.objectId} does not have a segment`
        );
      }
    };

    if (!isChildCampaign) {
      assignToSegment();
      return;
    }

    const parentSubObjectId = ManageTableFastDataBuilder.getParentSubIdForObject(campaign, campaign.parentCampaign);

    if (this.tryToAssignSegmentedObjectRow(
      campaignRow,
      campaign.budgetSegmentId,
      ManageTableRowType.Campaign,
      campaign.parentCampaign,
      parentSubObjectId
    )) {
      return;
    }

    assignToSegment();
  }

  private assignCampaignRow(campaign: LightCampaign, campaignRow: ManageTableRow) {
    const { viewMode } = this.dataInputs;
    const isChildCampaign = campaign.parentCampaign != null;

    const childCampaigns =
      isChildCampaign ?
        [] :
        this.getChildItems(ManageTableRowType.Campaign, campaign.objectId, ManageTableRowType.Campaign);

    const childPrograms = this.getChildItems(ManageTableRowType.Campaign, campaign.objectId, ManageTableRowType.ExpenseGroup);
    campaignRow.children = [ ...childCampaigns, ...childPrograms ];
    campaignRow.children.forEach(childRow => childRow.parentId = campaignRow.id);
    campaignRow.shownChildren =
      this.dataInputs.isFilterMode ? campaignRow.children.filter(row => !row.isFilteredOut) : campaignRow.children;

    const parentSubObjectId =
      isChildCampaign ?
        ManageTableFastDataBuilder.getParentSubIdForObject(campaign, campaign.parentCampaign) :
        null;

    if (viewMode === ManageTableViewMode.Segments) {
      this.assignCampaignRowInBySegmentMode(campaign, campaignRow, isChildCampaign);
    } else if (isChildCampaign && this.objectExists(ManageTableRowType.Campaign, campaign.parentCampaign)) {
      this.addChildItem(
        { objectType: ManageTableRowType.Campaign, id: campaign.parentCampaign },
        { objectType: ManageTableRowType.Campaign, item: campaignRow }
      );
    } else if (isChildCampaign && this.objectExists(ManageTableRowType.Campaign, parentSubObjectId)) {
      this.addChildItem(
        { objectType: ManageTableRowType.Campaign, id: parentSubObjectId },
        { objectType: ManageTableRowType.Campaign, item: campaignRow }
      );
    } else if (!isChildCampaign && viewMode === ManageTableViewMode.Goals) {
      if (this.objectExists(ManageTableRowType.Goal, campaign.goalId)) {
        this.addChildItem(
          { objectType: ManageTableRowType.Goal, id: campaign.goalId },
          { objectType: ManageTableRowType.Campaign, item: campaignRow }
        );
      } else {
        this.campaignsData.push(campaignRow);
      }
    } else {
      this.campaignsData.push(campaignRow);
    }

    this.addFlatDataRow(campaignRow);
  }

  private processCampaigns() {
    const rowCreator =
      (
        campaign: LightCampaign,
        viewMode?: ManageTableViewMode,
        objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean
      ) => ManageTableFastDataBuilder.createInitialCampaignRow(
        campaign,
        viewMode,
        objectExists
      );

    this.processObjects(
      this.getChildCampaigns(),
      rowCreator,
      this.assignCampaignRow.bind(this),
      this.dataInputs.viewMode
    );

    this.processObjects(
      this.getRegularCampaigns(),
      rowCreator,
      this.assignCampaignRow.bind(this),
      this.dataInputs.viewMode
    );
  }

  private getRegularCampaigns(): LightCampaign[] {
    return this.dataInputs.campaigns.filter(c => !c.parentCampaign);
  }

  private getChildCampaigns(): LightCampaign[] {
    return this.dataInputs.campaigns.filter(c => c.parentCampaign);
  }

  private assignGoalRow(goal: Goal, goalRow: ManageTableRow) {
    goalRow.children = [
      ...this.getChildItems(ManageTableRowType.Goal, goal.id, ManageTableRowType.Campaign),
      ...this.getChildItems(ManageTableRowType.Goal, goal.id, ManageTableRowType.ExpenseGroup)
    ];
    goalRow.children.forEach(childRow => childRow.parentId = goalRow.id);
    goalRow.shownChildren =
      this.dataInputs.isFilterMode ? goalRow.children.filter(ManageTableFastDataBuilder.hasItemsForDisplay) : goalRow.children;

    if (!this.dataInputs.isFilterMode || goalRow.shownChildren.length) {
      this.goalsData.push(goalRow)
      this.addFlatDataRow(goalRow);
    }
  }

  private processGoals() {
    this.processObjects(
      this.dataInputs.goals,
      ManageTableFastDataBuilder.createInitialGoalRow,
      this.assignGoalRow.bind(this)
    );
  }

  private assignSegmentRow(segment: BudgetSegmentAccess, segmentRow: ManageTableRow) {
    segmentRow.children = [
      ...this.getChildItems(ManageTableRowType.Segment, segment.id, ManageTableRowType.Campaign),
      ...this.getChildItems(ManageTableRowType.Segment, segment.id, ManageTableRowType.ExpenseGroup)
    ];

    segmentRow.children.forEach(childRow => childRow.parentId = segmentRow.id);

    segmentRow.shownChildren =
      this.dataInputs.isFilterMode ?
        segmentRow.children.filter(ManageTableFastDataBuilder.hasItemsForDisplay) :
        segmentRow.children;

    if (this.dataInputs.isFilterMode && !segmentRow.shownChildren.length) {
      return;
    }

    if (this.objectExists(ManageTableRowType.SegmentGroup, segment.segment_group)) {
      this.addChildItem(
        { objectType: ManageTableRowType.SegmentGroup, id: segment.segment_group },
        { objectType: ManageTableRowType.Segment, item: segmentRow }
      );
    } else {
      this.segmentsData.push(segmentRow);
    }

    this.addFlatDataRow(segmentRow);
  }

  private processSegments() {
    this.processObjects(
      this.dataInputs.segments,
      ManageTableFastDataBuilder.createInitialSegmentRow,
      this.assignSegmentRow.bind(this),
      this.dataInputs.viewMode
    );
  }

  private assignSegmentGroupRow(segmentGroup: SegmentGroup, segmentGroupRow: ManageTableRow) {
    segmentGroupRow.shownChildren = segmentGroupRow.children =
      this.getChildItems(ManageTableRowType.SegmentGroup, segmentGroup.id, ManageTableRowType.Segment);

    segmentGroupRow.children.forEach(childRow => childRow.parentId = segmentGroupRow.id);

    if ((this.dataInputs.isPowerUser && !this.dataInputs.isFilterMode) || segmentGroupRow.children.length > 0) {
      this.segmentGroupsData.push(segmentGroupRow);
      this.addFlatDataRow(segmentGroupRow);
    }
  }

  private processSegmentGroups() {
    this.processObjects(
      this.dataInputs.segmentGroups,
      ManageTableFastDataBuilder.createInitialSegmentGroupRow,
      this.assignSegmentGroupRow.bind(this)
    );
  }

  private setObjectIds() {
    const { segmentGroups, segments: allSegments, goals: allGoals, campaigns: allCampaigns, expGroups: allExpGroups } = this.dataInputs;
    const { campaigns, expGroups } = this.dataInputs.planObjects;

    const initIdSet = (
      container: Record<string, Record<number | string, number>>,
      type: ManageTableRowType,
      objects: { objectId?: number | string; id: number; budgetSegmentId?: number }[] = []
    ) => {
      for (const item of objects) {
        container[type][item.objectId || item.id] = item.budgetSegmentId || null;
      }
    };

    initIdSet(this.allObjectIdsByType, ManageTableRowType.Goal, allGoals);
    initIdSet(this.allObjectIdsByType, ManageTableRowType.Campaign, allCampaigns);
    initIdSet(this.allObjectIdsByType, ManageTableRowType.ExpenseGroup, allExpGroups);
    initIdSet(this.allObjectIdsByType, ManageTableRowType.Segment, allSegments);
    initIdSet(this.allObjectIdsByType, ManageTableRowType.SegmentGroup, segmentGroups);

    if (this.dataInputs.isFilterMode || this.dataInputs.viewMode === ManageTableViewMode.Segments) {
      initIdSet(this.currentObjectIdsByType, ManageTableRowType.Campaign, campaigns);
      initIdSet(this.currentObjectIdsByType, ManageTableRowType.ExpenseGroup, expGroups);
    }
  }

  private groupData() {
    const { viewMode} = this.dataInputs;

    this.data = [];
    if (viewMode === ManageTableViewMode.Goals) {
      this.data.push(...this.goalsData);
    }
    if (viewMode === ManageTableViewMode.Segments) {
      this.data.push(...this.segmentGroupsData);
      this.data.push(...this.segmentsData);
    }
    this.data.push(...this.campaignsData);
    this.data.push(...this.expGroupsData);
  }

  private buildFilteredRowsHierarchy(item: ManageTableRow): ListTreeItem[] {
    const breadcrumbs = [];

    const mapTableRowToHierarchyItem = (rowItem: ManageTableRow): ListTreeItem => {
      return {
        id: rowItem.objectId,
        name: rowItem.name,
        type: rowItem.type === ManageTableRowType.SegmentGroup ? 'SegmentsGroup' : rowItem.type,
        highlighted: rowItem.isFilteredOut,
      }
    }

    const writeBcItem = (el: ManageTableRow, bc) => {
      const parent = this.flatDataMap[el.parentId];
      bc.push(mapTableRowToHierarchyItem(el));
      if (parent) {
        writeBcItem(parent, bc);
      }
    }
    writeBcItem(item, breadcrumbs);
    breadcrumbs.reverse();
    return breadcrumbs;
  }

  private buildFilteredOutHierarchy() {
    const hasNotFilteredChildren = (children: ManageTableRow[]): boolean => {
      return children.some(child => !child.isFilteredOut);
    }

    const checkFilteringState = (items: ManageTableRow[] = []): void => {
      items.forEach(obj => {
        if (obj.isFilteredOut) {
          if (hasNotFilteredChildren(obj.children)) {
            obj.children
              .filter(c => !c.isFilteredOut)
              .forEach((childRow, index) => {
                if (index === 0) {
                  childRow.hierarchyBreakLine = true;
                }
                childRow.hierarchyInfo = this.buildFilteredRowsHierarchy(childRow);
              })
          }
        }
        checkFilteringState(obj.children);
      })
    }

    checkFilteringState(this.data);
  }

  private init() {
    this.parentChildMap = {
      [ManageTableRowType.SegmentGroup]: {},
      [ManageTableRowType.Segment]: {},
      [ManageTableRowType.Goal]: {},
      [ManageTableRowType.Campaign]: {},
    };
    this.data = [];
    this.allObjectIdsByType = {
      [ManageTableRowType.Goal]: {},
      [ManageTableRowType.Campaign]: {},
      [ManageTableRowType.ExpenseGroup]: {},
      [ManageTableRowType.Segment]: {},
      [ManageTableRowType.SegmentGroup]: {},
    };
    this.currentObjectIdsByType = {
      [ManageTableRowType.Campaign]: {},
      [ManageTableRowType.ExpenseGroup]: {}
    };
    this.setObjectIds();
    this.cellDataLoaded = false;
    this.cellDataLoading = false;
  }

  private buildTableHierarchyDataFromInputs() {
    const { viewMode } = this.dataInputs;
    this.processExpGroups();
    this.processCampaigns();
    if (viewMode === ManageTableViewMode.Goals) {
      this.processGoals();
    }
    if (viewMode === ManageTableViewMode.Segments) {
      this.processSegments();
      this.processSegmentGroups();
    }
    this.groupData();
    this.buildFilteredOutHierarchy();
  }

  public buildHierarchyData(dataInputs: ManageTableDataBuilderInputs) {
    this.dataInputs = dataInputs;
    this.init();
    this.buildTableHierarchyDataFromInputs();

    return {
      data: this.data,
      flatDataMap: this.flatDataMap
    };
  }

  public startCellDataLoading(): Observable<void> {
    if (this.cellDataLoading) {
      return of(null);
    }

    this.cellDataLoading = true;

    return of(null).pipe(
      tap(() => this.cellDataLoaded = true ),
      finalize(() => this.cellDataLoading = false )
    );
  }
}
