import { Component, inject, OnInit } from '@angular/core';
import { Validations } from 'app/app.validations';
import { Configuration } from 'app/app.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { ChangePasswordRoutingStateData } from 'app/user/types/change-password-routing-state-data.type';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserManager } from 'app/user/services/user-manager.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { ChangePasswordMode } from '../../types/change-password-mode.enum';

type LinkParamProp = 'u' | 'p' | 'code';

const PARAMETER_DELIMITER = '&';
const INVITATION_PARAMETER_NAME = 'invitation';
const CODE_PARAMETER_NAME = 'code';
const PASSWORD_PATTERN = /^(?!.* )(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^A-Za-z0-9]).{8,}$/;

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public readonly validations = inject(Validations);
  private readonly utilityService = inject(UtilityService);
  private readonly configuration = inject(Configuration);
  public readonly userManager = inject(UserManager);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);

  form = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  };
  email = '';
  code = '';
  user: CognitoUser;
  successRoutePath: string = this.configuration.ROUTING_CONSTANTS.USER;
  cancelRoutePath: string = this.configuration.ROUTING_CONSTANTS.USER;
  mode = ChangePasswordMode.ChangePassword;
  actionsByModes = {
    [ChangePasswordMode.ChangePassword]: this.changePassword.bind(this),
    [ChangePasswordMode.CompleteNewPassword]: this.completeNewPassword.bind(this),
    [ChangePasswordMode.ForgotPassword]: this.forgotPasswordSubmit.bind(this),
  };
  passwordPattern = PASSWORD_PATTERN;

  get isChangePasswordMode(): boolean {
    return this.mode === ChangePasswordMode.ChangePassword;
  }

  ngOnInit() {
    const routingState = AppRoutingService.getHistoryStateProperty<ChangePasswordRoutingStateData>('data');
    if (routingState) {
      this.processRoutingState(routingState);
    } else {
      this.processQueryParams(this.route.snapshot.queryParamMap);
    }
  }

  processRoutingState(routingState: ChangePasswordRoutingStateData) {
    this.successRoutePath = routingState.successRoutePath;
    this.cancelRoutePath = routingState.cancelRoutePath;
    this.email = routingState.email;
    this.form.oldPassword = routingState.oldPassword;
    this.mode = routingState.mode;
  }

  processQueryParams(queryParamMap: ParamMap) {
    if (queryParamMap.has(INVITATION_PARAMETER_NAME)) {
      const [email, oldPassword] = this.getDecryptedDataFromUrl(INVITATION_PARAMETER_NAME, ['u', 'p']);
      this.email = email;
      this.form.oldPassword = oldPassword;
      this.successRoutePath = this.configuration.ROUTING_CONSTANTS.QUICK_START;
      this.mode = ChangePasswordMode.CompleteNewPassword;
    } else if (queryParamMap.has(CODE_PARAMETER_NAME)) {
      const [email, code] = this.getDecryptedDataFromUrl(CODE_PARAMETER_NAME, ['u', 'code' ]);
      this.email = email;
      this.code = code;
      this.successRoutePath = this.configuration.ROUTING_CONSTANTS.LOGIN;
      this.mode = ChangePasswordMode.ForgotPassword;
    }
  }

  getDecryptedDataFromUrl(paramName: string, dataPropNames: LinkParamProp[]): string[] {
    const encodedData = this.route.snapshot.queryParamMap.get(paramName);
    const data = atob(encodedData);
    const params = data.split(PARAMETER_DELIMITER);
    const paramItems =
      params.map(item => {
        const parts = item.split('=');
        return [parts[0], parts.slice(1).join('=')];
      });
    const paramsObj = Object.fromEntries(paramItems);
    return (dataPropNames || []).map(
      propName => paramsObj[propName]
    );
  }

  public handleSubmitForm() {
    const { newPassword, confirmNewPassword } = this.form;
    if (newPassword !== confirmNewPassword) {
      this.utilityService.showToast({ Title: '', Message: 'Passwords do not match.', Type: 'error' });
      return;
    }
    this.utilityService.showLoading(true);

    const updatePasswordAction = this.actionsByModes[this.mode];
    if (!updatePasswordAction) {
      return;
    }

    const updatePassword$ = updatePasswordAction();

    updatePassword$.subscribe(
      () => this.handleSuccess(),
      error => this.handleError(error, 'Failed to change password'),
      () => this.utilityService.showLoading(false)
    );
  }

  completeNewPassword() {
    // we should do login in order to get the CognitoUser with not empty property Session
    return this.userManager.login(this.email.toLowerCase(), this.form.oldPassword).pipe(
      switchMap((user: CognitoUser) => this.userManager.completeNewPassword(user, this.form.newPassword)),
      tap(() => this.userManager.setLoggedIn())
    );
  }

  changePassword() {
    const { oldPassword, newPassword } = this.form;
    return this.userManager.getCurrentAuthenticatedUser().pipe(
      switchMap((user: CognitoUser) => this.userManager.changePassword(user, oldPassword, newPassword))
    );
  }

  forgotPasswordSubmit() {
    return this.userManager.forgotPasswordSubmit(this.email.toLowerCase(), this.code, this.form.newPassword);
  }

  handleError(error, message) {
    this.utilityService.showToast({ Title: '', Message: message, Type: 'error' });
    if (error) {
      console.log(message);
      console.log(error);
    }
  }

  handleSuccess() {
    this.utilityService.showToast({ Title: '', action: null, Message: 'Password successfully changed', Type: 'success' });
    if (this.successRoutePath) {
      this.router.navigate([this.successRoutePath]);
    }
  }

  handleCancelClick(e: Event) {
    e.preventDefault();
    if (this.cancelRoutePath) {
      this.router.navigate([this.cancelRoutePath]);
    }
  }
}
