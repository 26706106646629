import { ComponentRef, Directive, Input } from '@angular/core';
import { BaseComponentPortalTooltipDirective } from './base-component-portal-tooltip.directive';
import { MetricPerformanceTooltipComponent } from '../components/metric-performance-tooltip/metric-performance-tooltip.component';
import { MetricProgressState } from '../types/metric-progress-state.type';


@Directive({
  selector: '[metricPerformanceTooltip]'
})
export class MetricPerformanceTooltipDirective extends BaseComponentPortalTooltipDirective {
  @Input() name = '';
  @Input() progressState: MetricProgressState = MetricProgressState.InTarget;
  @Input() estimatedDiffPercentage = 0;
  @Input() current = 0;
  @Input() target = 0;

  protected componentClassRef = MetricPerformanceTooltipComponent;

  public onComponentRefCreated(ref: ComponentRef<MetricPerformanceTooltipComponent>): void {
    ref.instance.name = this.name;
    ref.instance.progressState = this.progressState;
    ref.instance.estimatedDiffPercentage = this.estimatedDiffPercentage;
    ref.instance.current = this.current;
    ref.instance.target = this.target;
  }
}
