import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'plc-numeric-input',
  templateUrl: './plc-numeric-input.component.html',
  styleUrls: ['./plc-numeric-input.component.scss']
})
export class PlcNumericInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value = 0;
  @Input() prefix: string = null;
  @Input() suffix: string = null;
  @Input() maxValue: number = null;
  @Input() alignment: 'left' | 'right' = 'left';
  @Input() modelChangeDebounceTime = 300;
  @Input() tabIndex = -1;

  @Output() onValueChange = new EventEmitter<number>();

  private modelChange$ = new Subject<void>();
  private destroy$ = new Subject<void>();
  public inputValue = 0;
  public currencyMaskOptions = {
    prefix: '',
    decimal: '.',
    precision: 2,
    align: 'left'
  };

  ngOnInit(): void {
    this.modelChange$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.modelChangeDebounceTime)
      )
      .subscribe(() => this.onValueChange.emit(this.inputValue));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alignment) {
      this.currencyMaskOptions.align = this.alignment;
    }

    if (changes.value) {
      this.setInputValue(this.value || 0);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setInputValue(value: number) {
    this.inputValue = value;
  }

  private prepareInputValue(value: number = 0): number {
    return this.maxValue != null ? Math.min(value, this.maxValue) : value;
  }

  public handleModelChange($event: number) {
    setTimeout(() => {
      this.setInputValue(this.prepareInputValue($event));
      this.modelChange$.next();
    })
  }

  public handleBlur() {
    this.onValueChange.emit(
      this.prepareInputValue(this.inputValue)
    );
  }
}
