import { TimeframeExpensesAmount, TimeframeExpensesData } from 'app/shared/types/plan-object-expenses-data.type';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ManageTableRow, ManageTableRowValues, ManageTableSpendingValues } from '../components/manage-table/manage-table.types';
import { ManageTableHelpers } from './manage-table-helpers';
import { getNumericValue, sumAndRound } from 'app/shared/utils/common.utils';

export class ManageTableSpendingHelpers {
  public static initSpendingValues(values: Partial<ManageTableSpendingValues> = {}): ManageTableSpendingValues {
    return {
      allocated: 0,
      closed: 0,
      committed: 0,
      planned: 0,
      allocatedToChildren: 0,
      totalRemaining: 0,
      totalAvailable: 0,
      totalExpenses: 0,
      totalRemainingWithPlanned: 0,
      totalAvailableWithPlanned: 0,
      totalExpensesWithPlanned: 0,
      ...values
    };
  }

  public static calcSpendingValues(
    currentSpending: ManageTableSpendingValues,
    allocated: number,
    allocatedToChildren: number,
    remainingAllocated: number
  ): ManageTableSpendingValues {
    const totalExpenses = sumAndRound(currentSpending.committed, currentSpending.closed);
    const totalExpensesWithPlanned = sumAndRound(totalExpenses, currentSpending.planned);
    const totalRemainingWithPlanned = remainingAllocated;
    const totalRemaining = remainingAllocated + currentSpending.planned;
    const totalAvailable = sumAndRound(totalRemaining, -(allocatedToChildren + currentSpending.planned));
    const totalAvailableWithPlanned = sumAndRound(totalRemainingWithPlanned, -allocatedToChildren);

    return {
      ...currentSpending,
      allocated,
      allocatedToChildren,
      totalRemaining,
      totalAvailable,
      totalExpenses,
      totalRemainingWithPlanned,
      totalAvailableWithPlanned,
      totalExpensesWithPlanned
    };
  }

  public static sumSpendingValues(
    target: ManageTableSpendingValues,
    source: ManageTableSpendingValues
  ): ManageTableSpendingValues {
    return Object.entries(source || {}).reduce((result, entry) => {
      const [ key, value ] = entry;
      return {
        ...result,
        [key]: sumAndRound(getNumericValue(value), getNumericValue(target[key])),
      };
    }, { ...source });
  }

  public static calcSegmentSpending(
    record: ManageTableRow,
    timeframeExpensesData: TimeframeExpensesData,
    filteredTimeframes: BudgetTimeframe[] = []
  ) {
    if (!record.segment) {
      return;
    }

    const filteredTimeframeIds = filteredTimeframes.map(tf => tf.id);
    const expensesTotalAmount: TimeframeExpensesAmount = ManageTableHelpers.getTimeframeExpensesDataTotals(
      timeframeExpensesData,
      filteredTimeframeIds
    );
    const allocated = record.segment.total.allocated;
    const planned = expensesTotalAmount.planned;
    const committed = expensesTotalAmount.committed;
    const closed = expensesTotalAmount.closed;
    const totalExpensesWithPlanned = expensesTotalAmount.total;
    const allocatedToChildren = ManageTableSpendingHelpers.getSegmentAllocatedToChildren(record);
    const remainingAllocated = sumAndRound(allocated, -totalExpensesWithPlanned);

    record.segment.spending = ManageTableSpendingHelpers.calcSpendingValues(
      {
        ...record.segment.spending,
        planned,
        committed,
        closed
      },
      allocated,
      allocatedToChildren,
      remainingAllocated
    );
  }

  public static syncSegmentSpending(record: ManageTableRow) {
    if (!record.segment) {
      return;
    }
    const allocatedToChildren = ManageTableSpendingHelpers.getSegmentAllocatedToChildren(record);

    record.segment.spending = ManageTableSpendingHelpers.updateAllocatedToChildrenSpendingValue(
      record.segment.spending,
      allocatedToChildren
    );
  }

  public static calcUnallocatedSpending(record: ManageTableRow) {
    if (!record.unallocated) {
      return;
    }

    record.unallocated.spending = Object.entries(record.segment.spending || {})
      .reduce(
        (res, [ key, value ]) => ({
          ...res,
          [key]: sumAndRound(value, -record.spending[key])
        }),
        ManageTableSpendingHelpers.initSpendingValues()
      );

    ManageTableSpendingHelpers.syncUnallocatedSpending(record.unallocated);
  }

  public static syncUnallocatedSpending(
    values: ManageTableRowValues
  ) {
    if (!values) {
      return;
    }

    const allocated = values.total.allocated;
    const remainingAllocated = sumAndRound(allocated, -values.spending.totalExpensesWithPlanned);

    values.spending.allocated = allocated;
    values.spending.totalRemainingWithPlanned = remainingAllocated;
    values.spending.totalRemaining = sumAndRound(remainingAllocated, values.spending.planned);
  }

  public static calcBreakdownSpendingValues(
    record: ManageTableRow,
    timeframeExpensesData: TimeframeExpensesData,
    filteredTimeframes: BudgetTimeframe[] = []
  ) {
    ManageTableSpendingHelpers.calcSegmentSpending(record, timeframeExpensesData, filteredTimeframes);
    ManageTableSpendingHelpers.calcUnallocatedSpending(record);
  }

  public static updateAllocatedToChildrenSpendingValue(
    spending: ManageTableSpendingValues,
    allocatedToChildren: number
  ): ManageTableSpendingValues {
    const remainingAllocatedWithPlanned = sumAndRound(allocatedToChildren, spending.planned);

    return {
      ...spending,
      allocatedToChildren,
      totalAvailable: sumAndRound(spending.totalRemaining, -remainingAllocatedWithPlanned),
      totalAvailableWithPlanned: sumAndRound(spending.totalRemainingWithPlanned, -allocatedToChildren)
    };
  }

  public static getAllocatedToChildren(record: ManageTableRow): number {
    const childValues = ManageTableHelpers.sumUpChildValues(record);

    return ManageTableHelpers.getChildRemainingAllocated(
      getNumericValue(childValues.total?.remainingAllocatedAbs)
    );
  }

  public static getSegmentAllocatedToChildren(record: ManageTableRow): number {
    return ManageTableHelpers.getChildRemainingAllocated(
      getNumericValue(record.spending.totalRemainingWithPlanned)
    );
  }

  /**
   * Update parent's spending values depending on child allocation change
   */
  public static updateAllocatedToChildrenSpendingValuesDiff(target: ManageTableRowValues, diffValue: number) {
    if (target?.spending) {
      target.spending.allocatedToChildren += diffValue;
      target.spending.totalAvailable -= diffValue;
      target.spending.totalAvailableWithPlanned -= diffValue;
    }
  }
}
