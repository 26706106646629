<header mat-dialog-title>
  <p class="header-title">Calculate Marketing Budget</p>
</header>

<div mat-dialog-content [formGroup]="form">

  <mat-form-field appearance="outline" class="p-form-field-outline">
    <mat-label>Targeted metric</mat-label>
    <mat-select formControlName="targetedMetric">
      <mat-option *ngFor="let item of context.countMetricList" [value]="item">
        {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <tab-switch
    class="tab-switch"
    [options]="calculatorModeOptions"
    [selectedValue]="calculatorMode"
    (onChange)="onCalculatorModeChange($event)"
  ></tab-switch>

  <div class="input-wrap">
    <ng-container *ngIf="calculatorMode === budgetCalculatorMode.PrevBudget">
      <mat-form-field appearance="outline" class="p-form-field-outline currency-field">
        <mat-label>Previous budget</mat-label>
        <span matPrefix>{{ context.companyCurrency }}</span>
        <input matInput
               [selectTextOnFocus]="true"
               currencyMask
               [options]="currencyMaskOptions"
               formControlName="prevBudget"/>
      </mat-form-field>

      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Previous number of [{{ targetedMetricName }}]</mat-label>
        <input matInput
               [selectTextOnFocus]="true"
               type="number"
               class="hide-arrows"
               formControlName="prevMetricNumber"/>
      </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="calculatorMode === budgetCalculatorMode.CostPerMetric" appearance="outline" class="p-form-field-outline currency-field">
      <mat-label>Cost per [{{ targetedMetricName }}]</mat-label>
      <span matPrefix>{{ context.companyCurrency }}</span>
      <input matInput
             [selectTextOnFocus]="true"
             currencyMask
             [options]="currencyMaskOptions"
             formControlName="prevMetricCost"/>
    </mat-form-field>
  </div>

  <product-budget-summary
    [revenueTarget]="context.targetRevenueAmount"
    [budget]="budgetAmount"
    [currency]="context.companyCurrency"></product-budget-summary>
</div>

<footer mat-dialog-actions>
  <button mat-button
          color="primary"
          (click)="dialogRef.close()">Cancel</button>
  <button mat-flat-button
          color="primary"
          [disabled]="form.invalid"
          (click)="saveData()">Save</button>
</footer>
