import { Component, OnDestroy, OnInit } from '@angular/core';
import { Budget } from 'app/shared/types/budget.interface';
import { CompanyDO } from 'app/shared/types/company.interface';
import {
  CampaignMappingData,
  IntegrationMappingState,
  MetricIntegrationDisplayName,
  MetricIntegrationName,
  MetricListItem
} from '../../types/metric-integration';
import { ExternalMetricTypesMappingTableSettings } from '../external-metric-types-mapping-table/external-metric-types-mapping-table.type';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { UtilityService } from 'app/shared/services/utility.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { AppDataLoader } from 'app/app-data-loader.service';
import { Configuration } from 'app/app.constants';
import { IntegrationSetupProviderService } from '../../services/integration-setup-provider.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ExternalCampaign } from '../../types/external-campaign.interface';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { BudgetObjectMetricsService } from 'app/budget-object-details/services/budget-object-metrics.service';
import { MetricIntegrations } from '../../types/metric-integrations-status.interface';
import { SalesforceApiService } from '../../salesforce/salesforce-api.service';
import { HubspotApiService } from '../../hubspot/hubspot-api.service';
import { ExternalMetricProviderApiService } from '../../types/external-metric-provider-api.service';
import { HubspotCampaignMapping } from '../../hubspot/hubspot-campaign-mapping.interface';
import { SalesforceCampaignMapping } from '../../salesforce/salesforce-campaign-mapping.interface';
import { ActionMenuItem } from 'app/shared/components/actions-menu/actions-menu.component';
import { LocationService } from 'app/budget-object-details/services/location.service';
import { HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { ExternalCampaignsFilterPipe } from '../../pipes/external-campaigns-filter.pipe';
import { createDeepCopy, createRecordsTree } from 'app/shared/utils/common.utils';
import { updateChildrenMappingStates } from '../metric-mapping-dialog/utils';
import { CurrentMappingTargets } from '../../salesforce/salesforce-data.service';

export enum CampaignViewMode {
  ALL = 'all',
  MAPPED = 'mapped',
  UNMAPPED = 'unmapped',
}

@Component({
  selector: 'campaigns-mapping',
  templateUrl: './campaigns-mapping.component.html',
  styleUrls: ['./campaigns-mapping.component.scss'],
  providers: [
    AppDataLoader,
  ]
})
export class CampaignsMappingComponent implements OnInit, OnDestroy {
  public filterText = '';
  public viewMode = CampaignViewMode.ALL;
  public viewFilterItems: ActionMenuItem[];

  public budget: Budget = null;
  public company: CompanyDO;
  public integrationSource: MetricIntegrationName;
  public metricIntegrationName = MetricIntegrationName;
  public integrationName: string;

  private integrationId: string;
  private budgetIntegrations: MetricIntegrations;
  public mappingTableSettings: ExternalMetricTypesMappingTableSettings;

  private readonly destroy$ = new Subject<void>();
  private readonly pageReload$ = new Subject<void>();

  public campaignsTree: ExternalCampaign[];
  private externalRawCampaigns$ = new BehaviorSubject<any[]>(null);
  private externalCampaignsFlatList: ExternalCampaign[];
  private plannuhCampaignsList: LightCampaign[];
  public locationItems: HierarchySelectItem[];
  public locationNamesMap: Record<number, string>;

  private products: ProductDO[];
  private metricTypes: MetricType[];
  private externalMetricTypesMappings: Record<string, number[]>;
  private externalCampaignsMappings: Record<string, number>;
  protected settingInProgress = false;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly companyDataService: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    public readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    public readonly metricsManager: BudgetObjectMetricsService,
    private readonly appDataLoader: AppDataLoader,
    private readonly configuration: Configuration,
    private readonly integrationSetupProvider: IntegrationSetupProviderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly salesforceApiService: SalesforceApiService,
    private readonly hubspotApiService: HubspotApiService,
    private readonly locationService: LocationService,
  ) {
    this.route.paramMap.subscribe(data => {
      this.onPageReloaded(data);
    })
  }

  ngOnInit(): void {
    this.appDataLoader.init();

    this.companyDataService.selectedCompanyDO$.pipe(
      filter(company => company != null),
      switchMap(company => this.onCompanyChanged$(company)),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.processData();
    });

    this.budgetDataService.selectedBudget$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(selectedBudget => {
      this.utilityService.showLoading(true);
      this.budget = selectedBudget;
      this.integrationSetupProvider.getCurrentIntegrations();
      this.loadBudgetObjects();
    });

    this.budgetObjectDetailsManager.campaignMappingsChanged$.pipe(
      switchMap(() => this.currentMappingTargetCampaigns$()),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      // listening for mapping changes from Campaign's details page
      this.updateCampaignsTree();
    })
  }

  onCompanyChanged$(company: CompanyDO) {
    this.utilityService.showLoading(true);
    if (!company[this.integrationSource]) {
      console.error(`"${this.integrationName}" integration is disabled for "${company.name}"`);
      this.redirectToDefaultRoute();
    }
    this.company = company;
    this.companyDataService.loadCompanyData(this.company.id);

    const metricIntegrations$ = this.budgetDataService.selectedBudget$.pipe(
      switchMap(() => {
        return this.companyDataService.metricIntegrations$.pipe(
          filter(integrations => Object.values(integrations).some(data => data !== null)), // filter initial values
          take(1),
        )
      })
    )

    return combineLatest([
      combineLatest([
        this.budgetDataService.lightCampaignList$.pipe(
          tap(campaigns => this.plannuhCampaignsList = campaigns),
        ),
        this.externalRawCampaigns$.pipe(
          filter(val => !!val),
        ),
      ]).pipe(
        switchMap(() => this.currentMappingTargetCampaigns$())
      ),
      combineLatest([
        this.budgetObjectDetailsManager.getProducts$().pipe(
          filter(items => items !== this.products)
        ),
        this.budgetObjectDetailsManager.getMetricTypes().pipe(
          filter(items => items !== this.metricTypes)
        ),
      ]).pipe(
        tap( ([products, mTypes]) => {
          this.metricsManager.setProducts(products);
          this.metricsManager.setTypes(mTypes);
          this.products = products;
          this.metricTypes = mTypes;
        }),
      ),
      metricIntegrations$.pipe(
        tap(metricIntegrations => this.budgetIntegrations = metricIntegrations),
        switchMap(() => this.updateExternalMetricTypesMappings$()),
        switchMap(() => this.loadExternalCampaigns$())
      )
    ]);
  }

  currentMappingTargetCampaigns$(): Observable<CurrentMappingTargets> {
    const payload = this.integrationSetupProvider.getExternalCampaignsPayload(this.externalRawCampaigns);
    return this.integrationSetupProvider.getCurrentMappingTargets(
      this.company.id, this.integrationId, this.budget.id, payload
    ).pipe(
      tap(externalCampaignsMappings => {
        this.externalCampaignsMappings = externalCampaignsMappings;
        this.externalCampaignsFlatList = this.mapRawCampaigns(this.externalRawCampaigns);
      })
    );
  }

  private get externalRawCampaigns() {
    return this.externalRawCampaigns$.getValue();
  }

  private processData() {
    this.updateLocationOptions();
    this.forceHideLoading();
    this.resetFilter();
    this.updateCampaignsTree();
  }

  private forceHideLoading() {
    setTimeout(() => {
      this.utilityService.forceHideLoading();
    }, 300);
  }

  private resetFilter() {
    if (this.filterText) {
      this.filterText = '';
    }
    this.viewMode = CampaignViewMode.ALL;
  }

  updateExternalMetricTypesMappings$() {
    this.integrationId = this.budgetIntegrations[this.integrationSource][0]?.integrationId; // for SF/HS we can take first
    if (!this.integrationId) {
      this.redirectToDefaultRoute();
    }
    const integrationId = this.integrationSource === MetricIntegrationName.Salesforce ? this.integrationId : null;
    return this.integrationSetupProvider.getExternalMetricTypesMapping(this.company.id, integrationId).pipe(
      tap(externalMetricTypesMappings => this.externalMetricTypesMappings = externalMetricTypesMappings)
    );
  }

  private loadBudgetObjects() {
    if (this.budget && this.company) {
      this.budgetDataService.loadLightCampaigns(
        this.company.id,
        this.budget.id,
        this.configuration.campaignStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.company.id,
        this.budget.id,
        this.configuration.programStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }
  }

  onPageReloaded(data: ParamMap) {
    this.utilityService.showLoading(true);
    this.integrationSource = data.get('integrationSource') as MetricIntegrationName;
    this.integrationName = MetricIntegrationDisplayName[this.integrationSource];
    this.createViewFilterItems(this.integrationName);
    if (!this.integrationName) {
      console.error('Wrong "integrationSource" in the route params')
      this.redirectToDefaultRoute();
      return;
    }
    this.pageReload$.next();
    this.integrationSetupProvider.initProvider(this.integrationSource);
    if (this.company) { // skip page loading
      this.updateExternalMetricTypesMappings$().pipe(
        switchMap(() => this.loadExternalCampaigns$()),
        takeUntil(
          merge(this.pageReload$, this.destroy$)
        )
      ).subscribe();
    }
  }

  redirectToDefaultRoute() {
    this.router.navigate([this.configuration.DEFAULT_ROUTE]);
  }

  loadExternalCampaigns$() {
    return this.integrationApiService(this.integrationSource).getRawExternalCampaigns(
      this.company.id, this.integrationId, this.budget.budget_from
    ).pipe(
      tap(externalCampaigns => this.externalRawCampaigns$.next(externalCampaigns))
    );
  }

  integrationApiService(
    integrationSource: MetricIntegrationName
  ): ExternalMetricProviderApiService<HubspotCampaignMapping> | ExternalMetricProviderApiService<SalesforceCampaignMapping> {
    switch (integrationSource) {
      case MetricIntegrationName.Salesforce:
        return this.salesforceApiService;
      case MetricIntegrationName.Hubspot:
        return this.hubspotApiService;
    }
  }

  onUpdateCampaignsMapping(newMapping: CampaignMappingData) {
    const { externalId, internalId } = newMapping;
    const newlyCreatedMappings = [ externalId ]; // later we can add unmapped children to this array

    const prevCampaignId = this.externalCampaignsMappings[externalId];
    let removeMapping$ = of(true);
    let addMapping$ = of(true);
    if (prevCampaignId) {
      // remove externalId from mappings for previous plannuh campaign
      const mappings = this.getMappingsForCampaign(prevCampaignId).filter(id => id !== externalId);
      removeMapping$ = this.updateMappings(prevCampaignId, { mappings }).pipe(
        tap(() => {
          this.integrationSetupProvider.syncMappings(this.company.id, this.integrationId, prevCampaignId).subscribe();
        })
      );
    }
    if (internalId) {
      // add new externalId for plannuh campaign mappings
      const existingMappings = this.getMappingsForCampaign(internalId); // external campaigns already mapped to selected Plannuh campaign

      newlyCreatedMappings.push(...newMapping.unmappedChildrenIds);
      const mappings = [ ...existingMappings, ...newlyCreatedMappings ];

      addMapping$ = this.updateMappings(internalId, { mappings }).pipe(
        tap(() => {
          this.integrationSetupProvider.syncMappings(this.company.id, this.integrationId, internalId).subscribe();
        })
      );
    }

    const updateFlatListAndMappingsStore = () => {
      // set internalId OR null ("newlyCreatedMappings" contains only one item in case setting null);
      newlyCreatedMappings.forEach(externalCampId => {
        const targetCampaign = this.externalCampaignsFlatList.find(camp => camp.id === externalCampId);
        targetCampaign.existingMapping = this.externalCampaignsMappings[externalId] = internalId;
      });
    }

    this.utilityService.showLoading(true);
    this.settingInProgress = true;
    removeMapping$.pipe(
      switchMap(() => addMapping$)
    ).pipe(
      finalize(() => {
        this.forceHideLoading();
        this.settingInProgress = false;
      })
    ).subscribe(() => {
      updateFlatListAndMappingsStore();
    }, error => {
      this.utilityService.handleError(error);
    });
  }

  getMappingsForCampaign(id: number): string[] {
    return Object.keys(this.externalCampaignsMappings).filter(key => this.externalCampaignsMappings[key] === id);
  }

  updateMappings(internalId, mapping) {
    return this.integrationSetupProvider.setCampaignMapping(
      this.company.id,  this.budget.id, internalId, this.integrationId, mapping
    );
  }

  private updateLocationOptions() {
    this.locationItems = [
      { title: 'Unmapped', id: null, level: 0 },
      ...this.locationService.createHierarchy(null, this.plannuhCampaignsList, null, true).items
    ];
    this.locationNamesMap = this.plannuhCampaignsList.reduce((store, campaign) => {
      store[campaign.id] = campaign.name;
      return store;
    }, {});
  }

  createViewFilterItems(integrationType: string): void {
    this.viewFilterItems = [
      { text: `All ${integrationType} Campaigns`, value: CampaignViewMode.ALL },
      { text: `Mapped ${integrationType} Campaigns`, value: CampaignViewMode.MAPPED },
      { text: `Unmapped ${integrationType} Campaigns`, value: CampaignViewMode.UNMAPPED },
    ];
  }

  changeViewMode(mode: string) {
    if (this.viewMode === mode) {
      return;
    }
    this.viewMode = mode as CampaignViewMode;
    this.updateCampaignsTree();
  }

  changeFilterText(filterText: string) {
    if (this.filterText === filterText) {
      return;
    }
    this.filterText = filterText;
    this.updateCampaignsTree();
  }

  getFilteredCampaigns(): ExternalCampaign[] {
    return this.externalCampaignsFlatList.filter(campaign => {
      const byMappingState = this.viewMode === CampaignViewMode.ALL ?
        true : this.viewMode === CampaignViewMode.MAPPED ?
          !!campaign.existingMapping : !campaign.existingMapping;
      const bySearchText = !this.filterText ?
        true : ExternalCampaignsFilterPipe.campaignContainsFilterTerm(campaign, this.filterText?.toLowerCase());
      return byMappingState && bySearchText;
    });
  }

  updateCampaignsTree() {
    if (!this.externalRawCampaigns) {
      return;
    }
    const lookupItemMapper = item => createDeepCopy(item);
    this.campaignsTree = createRecordsTree<ExternalCampaign>(this.getFilteredCampaigns(), lookupItemMapper);
    // update states ONLY after tree creation, as hierarchy has impact to the state
    this.updateMappingStates(this.campaignsTree);
  }

  updateMappingStates(campaignsTree: ExternalCampaign[]) {
    campaignsTree.forEach(campaign => {
      campaign.mappingState = campaign.existingMapping ? IntegrationMappingState.Mapped : IntegrationMappingState.NotMapped;
      if (campaign.children?.length) {
        this.updateMappingStates(campaign.children);
      }
      updateChildrenMappingStates(campaign, campaign.children);
    })
  }

  mapRawCampaigns(campaigns: any[]): ExternalCampaign[] {
    return campaigns.map( camp => ({
      id: camp.Id,
      name: camp.Name,
      type: camp.Type,
      description: camp.Description,
      status: camp.Status,
      owner: camp.Owner?.Name,
      startDate: camp.StartDate,
      endDate: camp.EndDate,
      mappingState: null,
      mappingChildrenState: null,
      existingMapping: this.externalCampaignsMappings[camp.Id] || null,
      metrics: this.getRelevantMetricsList(camp),
      children: [],
      parentId: camp.ParentId,
    }));
  }

  getRelevantMetricsList(campaign): MetricListItem[] {
    const combinedMetricValues = {};
    Object.keys(campaign).forEach(metricName => {
      const mappedTypeIds = this.externalMetricTypesMappings[metricName];
      if (mappedTypeIds?.length) {
        mappedTypeIds.forEach(metricId => {
          combinedMetricValues[metricId] = (combinedMetricValues[metricId] || 0) + campaign[metricName];
        });
      }
    });

    return Object.keys(combinedMetricValues).reduce((list, metricId) => {
      const targetMetric = this.metricTypes.find(metric => metric.id === +metricId);
      if (targetMetric) {
        const product = targetMetric.productId ? this.products.find(prod => prod.id === targetMetric.productId) : null;
        list.push({
          name: targetMetric.name,
          productName: product?.name || null,
          value: combinedMetricValues[metricId],
          order: targetMetric.order,
          productOrder: product?.order || null,
        });
      }
      return list;
    }, []);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
