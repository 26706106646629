import { DIALOG_ACTION_TYPE } from 'app/shared/types/dialog-context.interface'
import { ActionToConfirm, UnsavedChanges } from './budget-settings-page.type';

const confirmationDialogData = {
  [ActionToConfirm.DeleteBudget]: (budgetName) => ({
    submitAction: { label: 'Delete Budget', handler: null, type: DIALOG_ACTION_TYPE.WARNING },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Delete Budget',
    content: `Are you sure you want to delete <b>${budgetName}</b>?<br/>If you continue then <b>everything</b> related to this budget will
              be permanently removed. That includes budget information, expenses, campaigns, metrics ... everything.<br/><br/>
              You cannot undo this action, so please be careful.`,
    width: '480px',
  }),
  [ActionToConfirm.DuplicateBudget]: () => ({
    submitAction: { label: 'Identical Copy', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Create a plan budget copy?',
    content: `An  <b>identical</b> copy duplicates all goals, campaigns, expense groups, and expenses along with their access permissions and ownership.`,
    width: '450px',
  }),
  [ActionToConfirm.DuplicateBudgetIntegrations]: () => ({
    submitAction: { label: 'Duplicate', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Duplicate budget',
    content: `Please note: Your integrations will not carry over. <br/>
              This means the metric values and expenses amounts that were provided by a third party integration like Google Ads,
              Facebook Ads or LinkedIn Ads will be duplicated, but the duplicated campaigns and expenses will not be updated
              automatically in the duplicated budget.`,
    width: '480px',
  }),
  [ActionToConfirm.DuplicateBudgetSpecification]: () => ({
    submitAction: { label: 'Duplicate Budget', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Choose Data to be Duplicated',
    width: '680px',
  }),
};

export const getConfirmationDialogData = (actionType: ActionToConfirm, budgetName) => {
  return confirmationDialogData[actionType](budgetName);
};

export const defaultUnsavedChanges: UnsavedChanges = {
  createdSegmentsCount: 0,
  deletedSegments: {},
  changedSegmentsData: {},
  changedSegmentsAmounts: {},
  allocationsLockState: {},
  changedGroupsData: {},
  createdGroupsCount: 0,
};

export const messages = {
  lastBudgetWarning: 'You cannot delete last budget of the account',
  lastSegmentWarning: 'You cannot delete last segment of the budget',
  createBudgetSuccess: 'Budget created successfully.',
  duplicateBudgetSuccess: 'Budget duplicated successfully.',
  deleteBudgetStart: 'Budget deletion is underway and we’ll notify when it completes.',
  deleteBudgetSuccess: 'Budget deleted successfully.',
  updateBudgetSuccess: 'Budget has been updated successfully.',
  updateBudgetStatusSuccess: 'Budget status updated successfully.',
  updateDataSuccess: 'Data has been updated successfully.',
  updateDataFailure: 'Failure to update data. Please try again.',
  loadUsersFailure: 'Failure to load users list',
  loadExpenseTotalsFailure: 'Failure to load expense totals',
  createSegmentsFailure: 'Failed to create the segment(s)',
  createGroupsFailure: 'Failed to create the group(s)',
  deleteGroupsFailure: 'Failed to delete the group(s)',
  updateSegmentsFailure: 'Failed to update segment data',
  updateSegmentAmountsFailure: 'Failed to update segment amounts',
  updateBudgetAllocationsFailure: 'Failed to update budget allocations',
  updateGroupsFailure: 'Failed to update segment groups data',
};

export const BudgetDataSpecification = {
  budgetData: [
    {
      title: 'Hierarchy',
      id: null,
      value: null,
      children: [{
        title: 'Segments',
        id: 'segments',
        value: false
      }, {
        title: 'Shared Cost Rules',
        id: 'shared_cost_rules',
        value: false,
      }, {
        title: 'Goals',
        id: 'goals',
        value: false,
        hasGroup: true
      }, {
        title: 'Campaigns',
        id: 'campaigns',
        value: false,
        hasGroup: true
      }, {
        title: 'Expense Groups',
        id: 'programs',
        value: false
      }, {
        title: 'Expenses',
        id: 'expenses',
        value: false
      }]
    }, {
      title: 'Include Budgets',
      id: 'include_budgets',
      value: false
    }, {
      title: 'Attachments',
      id: 'attachments',
      value: false
    }
  ],
  hierarchyGroup: [
    {
      title: 'Goal Metric Mappings',
      id: 'goal_metric_mappings',
      value: false,
      parent: 'goals',
      hasGroup: true,
      alt: 'Enable Goals to activate'
    }, {
      title: 'Goal Metric Targets',
      id: 'goal_metric_targets',
      value: false,
      parent: 'goal_metric_mappings',
      alt: 'Enable Goal Metric Mappings to activate'
    }, {
      title: 'Campaign Metric Mappings',
      id: 'campaign_metric_mappings',
      value: false,
      parent: 'campaigns',
      hasGroup: true,
      alt: 'Enable Campaigns to activate',
      class: 'campaign-metric'
    }, {
      title: 'Campaign Metric Targets',
      id: 'campaign_metric_targets',
      value: false,
      parent: 'campaign_metric_mappings',
      alt: 'Enable Campaign Metric Mappings to activate',
      class: 'campaign-metric'
    }, {
      title: 'Campaign Metric Values',
      id: 'campaign_metric_values',
      value: false,
      parent: 'campaign_metric_mappings',
      alt: 'Enable Campaign Metric Mappings to activate',
      class: 'campaign-metric'
    }
  ],
  permissionLevel: [
    {
      title: 'Everyone with access to the original has access to the duplicate (default)',
      id: 'restrict_permissions',
      value: false
    }, {
      title: 'Only the admins have access to the duplicate',
      id: 'restrict_permissions',
      value: true,
    }
  ]
}
