import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { CustomFieldStatusAndCountDO, CustomFieldsService } from '../../budget-object-details/components/custom-fields/custom-field.service';
import { CompanyDataService } from '@shared/services/company-data.service';

@Injectable()
export class CustomFieldGuard implements CanActivate {
  constructor(
    private readonly customFieldsService: CustomFieldsService,
    private readonly companyDataService: CompanyDataService,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.companyDataService.selectedCompany$.pipe(
      switchMap(company => {
        if (!company) {
          // adding timer because during hard refresh, company is not selected immediately
          return timer(2000).pipe(
            take(1),
            switchMap(() => of(null))
          );
        }
        return of(company);
      }),
      switchMap(company => {
        if (!company) {
          console.error('Company is not selected');
          return of(this.router.createUrlTree(['/home']));
        }

        const companyId = company.id;
        return this.customFieldsService.getCFStatusForActivationGuard(companyId).pipe(
          map((status: CustomFieldStatusAndCountDO | null) => {
            if (status && status.isCustomFieldEnabled) {
              return true;
            } else {
              return this.router.createUrlTree(['/home']);
            }
          }),
          catchError(() => of(this.router.createUrlTree(['/home'])))
        );
      })
    );
  }
}
