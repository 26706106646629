<div *ngIf="dropDownTrigger" class="selected-item">
  <button #overlapTrigger class="selected-item-btn" [class.read-only]="readOnly" [class.active]="opened" (mousedown)="!readOnly && openSelect()" (mouseenter)="showTooltip($event, selectedData.sharedCostRuleId)" (mouseleave)="hideTooltip()">
    <span class="shared-cost-selected-item" *ngIf="!readOnly || currentSharedState !== sharedIconState.Empty">
      <icon-shared [state]="currentSharedState"></icon-shared>
      <span>{{ currentSharedState !== sharedIconState.Empty ? 'Shared' : 'Share cost'}}</span>
    </span>
    <span *ngIf="!readOnly" class="arrow-icon">
      <drop-down-arrow [opened]="opened"></drop-down-arrow>
    </span>
  </button>
</div>
<div *ngIf="!dropDownTrigger || opened" #dropDownContainer class="segments-rules-select" scrollOverlap [container]="overlapContainer" [trigger]="overlapTrigger" [tabIndex]="0" (blur)="closeSelect()">
  <div class="item header">Segments</div>
  <div class="container-wrapper">
    <div class="gradient-down" *ngIf="isSegmentsContainerOverflowed && !isSegmentsContainerScrolledToTop"></div>
    <div class="gradient-up" *ngIf="isSegmentsContainerOverflowed && !isSegmentsContainerScrolledToBottom"></div>
    <div #segmentsContainer class="segments-container" scrollDetector [scrollContainerElement]="segmentsContainer">
      <div *ngFor="let segment of segmentList; let i = index" #segmentItem class="item selectable" [ngClass]="{'active': segment.id === selectedData?.budgetSegmentId, 'focused': i === segmentFocusIndex}" (click)="handleSelectSegment(segment)">{{segment.name}}</div>
    </div>
  </div>
  <div class="separator"></div>
  <div class="item header">
    <icon-shared></icon-shared>
    <div class="text">Shared Cost Rules</div>
  </div>
  <div class="container-wrapper">
    <div class="gradient-down" *ngIf="isSharedCostRulesContainerOverflowed && !isSharedCostRulesContainerScrolledToTop"></div>
    <div class="gradient-up" *ngIf="isSharedCostRulesContainerOverflowed && !isSharedCostRulesContainerScrolledToBottom"></div>
    <div #sharedCostRulesContainer class="shared-cost-rules-container" scrollDetector [scrollContainerElement]="sharedCostRulesContainer">
      <div *ngFor="let rule of sharedCostRuleList; let i = index" #ruleItem class="item selectable" [ngClass]="{'active': rule.id === selectedData?.sharedCostRuleId, 'focused': i === ruleFocusIndex}" (mouseenter)="showTooltip($event, rule.id)" (mouseleave)="hideTooltip()" (click)="handleSelectSharedCostRule(rule)">
        <div class="shared-icon">
          <icon-shared [state]="rule.id === selectedData?.sharedCostRuleId ? sharedIconState.Default : sharedIconState.Empty"></icon-shared>
        </div>
        <div class="shared-icon-hovered">
          <icon-shared></icon-shared>
        </div>
        <div class="text">{{rule.name}}</div>
      </div>
    </div>
  </div>
</div>
<shared-rule-tooltip *ngIf="isTooltipShown" [className]="isTooltipAttachedToLeft ? 'fixed-position arrow-right' : ' fixed-position arrow-left'" [name]="tooltipData.name" [segments]="tooltipData.segments" [coords]="tooltipData.coords"></shared-rule-tooltip>
