<div class="details-metrics">
  <div class="header">
    <details-widget-title [count]="metrics.length" text="Metrics">
      <icon-metrics slot="icon"></icon-metrics>
    </details-widget-title>
    <div *ngIf="!isReadOnlyMode"
         matTooltip="{{ !objectId | disabledAddToObjectTooltip: objectType : 'adding metrics' }}"
         matTooltipClass="dark-theme-tooltip above attachment"
         matTooltipPosition="above">
      <add-object-link
        #addMetricsDropdownTrigger="matMenuTrigger"
        [matMenuTriggerFor]="metricsDropdown"
        [ngClass]="{ 'is-disabled': !objectId }"
        text="Add metrics"
        [skipHandler]="true"
        [disabled]="!objectId"
      ></add-object-link>
    </div>
    <mat-menu #metricsDropdown="matMenu" [class]="'metric-masters-list'">
      <metric-masters-list
        [options]="masterMetricsSelectItems"
        [isPowerUser]="isPowerUser"
        (click)="$event.stopPropagation();"
        (closeDropdown)="closeAddMetricsDropdown()"
        (addMetrics)="addMetrics($event)"
        (navigateToFunnelsPage)="navigateToFunnelsPage()"
      ></metric-masters-list>
    </mat-menu>
  </div>
  <div class="table" *ngIf="metrics?.length">
    <table>
      <thead>
        <tr>
          <th class="name-col">Name</th>
          <th *ngIf="objectType === configuration?.OBJECT_TYPES.campaign">Source</th>
          <th class="target-col">Target</th>
          <th class="current-col highlighted-value">Current</th>
          <th class="progress-col"></th>
          <th class="cpo-col highlighted-value">
            <span
              [matTooltip]="CPOTooltip"
              matTooltipPosition="above"
              matTooltipClass="dark-theme-tooltip above multi-line text-left regular-text auto-width"
            >CPO</span>
          </th>
          <th class="roi-col highlighted-value">
            <span
              [matTooltip]="allMetricsHaveRevenueToProfit ? ROITooltip : RROITooltip"
              matTooltipPosition="above"
              matTooltipClass="dark-theme-tooltip above multi-line text-left regular-text auto-width roi-col-tooltip"
            >
              {{allMetricsHaveRevenueToProfit ? 'ROI' : 'RROI'}}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let metric of metrics; trackBy: trackByFn" (click)="openMetricDetails(metric.id)">
          <td class="name-col">
            <heart-toggle
              *ngIf="keyMetricSelection"
              class="key-metric-toggler"
              [isActive]="metric.id === keyMetricId"
              (click)="onSetKeyMetric(metric, $event)"
              [matTooltip]="hasRPO(metric) ? keyMetricTooltipNoRPO : keyMetricTooltip"
              matTooltipPosition="left"
              matTooltipClass="dark-theme-tooltip left multi-line text-left auto-width regular-text"></heart-toggle>
            <ng-container *ngIf="metric.productName">
              <span class="opacity-04">{{ metric.productName }}</span><br>
            </ng-container>
            <span>{{ metric.name }}</span>
          </td>
          <td *ngIf="objectType === configuration?.OBJECT_TYPES.campaign">
            <metrics-source [sourcesIds]="metric.sources"></metrics-source>
          </td>
          <td class="target-col">
            {{ calculatedData.get(metric.id)?.targetValue | number: numberDisplayFormat }}
          </td>
          <td class="current-col">
            <div class="highlighted-value">{{ calculatedData.get(metric.id)?.currentValue | number: numberDisplayFormat }}</div>
          </td>
          <td class="progress-col">
            <metric-performance-arrow
              [progressState]="performanceColumnData[metric.id]?.progressState"
              metricPerformanceTooltip
              [showTooltip]="true"
              [estimatedDiffPercentage]="performanceColumnData[metric.id]?.estimatedDiffPercentage"
              [current]="performanceColumnData[metric.id]?.current"
              [target]="performanceColumnData[metric.id]?.estimatedTarget"
              [name]="performanceColumnData[metric.id]?.name"
            ></metric-performance-arrow>
          </td>
          <td class="cpo-col">
            <span [ngClass]="utClasses.targetCPO">
              <ng-container *ngIf="calculatedData.get(metric.id)?.targetValue; else naTemplate">
                {{ contextCurrency }}{{ calculatedData.get(metric.id)?.targetCPO | number: decimalDisplayFormat }}
              </ng-container>
            </span>
            <span class="delimiter-value">/</span>
            <span [ngClass]="utClasses.currentCPO" class="highlighted-value">
              <ng-container *ngIf="calculatedData.get(metric.id)?.currentValue; else naTemplate">
                {{ contextCurrency }}{{ calculatedData.get(metric.id)?.currentCPO | number: decimalDisplayFormat }}
              </ng-container>
            </span>
          </td>
          <td class="roi-col">
            <span [ngClass]="utClasses.targetROI">
              <ng-container *ngIf="objectTotals?.total; else naTemplate">
                {{ calculatedData.get(metric.id)?.targetROI | precisionNumberFormat }}x
              </ng-container>
            </span>
            <span class="delimiter-value">/</span>
            <span [ngClass]="utClasses.currentROI" class="highlighted-value">
              <ng-container *ngIf="contextTotalSpent; else naTemplate">
                {{ calculatedData.get(metric.id)?.currentROI | precisionNumberFormat }}x
              </ng-container>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <details-metrics-hint *ngIf="!metrics?.length"></details-metrics-hint>
</div>
<ng-template #naTemplate>N/A</ng-template>
