import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SortParams } from 'app/shared/types/sort-params.interface';
import { ActionToConfirm, TeamMember, PermissionFormGroup, PermissionsListFormGroup } from './team-page.type';
import { DIALOG_ACTION_TYPE } from 'app/shared/types/dialog-context.interface';
import { CompanyUserDO, CompanyUserStatus } from 'app/shared/types/company-user-do.interface';
import { BudgetPermission, UserPermission } from 'app/shared/types/user-permission.type';
import { PermissionType } from '../../app.constants';

const fb = new UntypedFormBuilder();
export const DAYS_TO_EXPIRE = 30;

export const permissionOptions = [
  {
    label: 'Admin',
    value: UserPermission.Admin
  }, {
    label: 'Read-Write',
    value: UserPermission.ReadWrite
  }, {
    label: 'Read-Only',
    value: UserPermission.ReadOnly
  }
];

export const permissionLabelByType = permissionOptions.reduce((res, permission) => {
  res[permission.value] = permission.label;
  return res;
}, {});

const confirmationDialogData = {
  [ActionToConfirm.GrantAdminPermission]: () => ({
    submitAction: { label: 'Promote to Admin', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Give this user unlimited access?',
    content: `If you promote this user to Admin status they will have <b>full edit access</b> to all budgets and all segments.`
  }),
  [ActionToConfirm.GrantPermission]: () => ({
    submitAction: { label: 'Grant New Permissions', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Are you sure?',
    content: `As the user is active new permissions will start working immediately. Notification email will be sent automatically.`
  }),
  [ActionToConfirm.RevokePermission]: (accountOwnerName) => ({
    submitAction: { label: 'Revoke Permission', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Are you sure?',
    content: `This user will lose access to all items in this budget and be marked as Inactive.`
  }),
  [ActionToConfirm.DeleteUser]: (accountOwnerName, teamMemberName) => ({
    submitAction: { label: 'Remove User', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Remove this User?',
    content: `If you remove <b>${teamMemberName}</b> then all of their access permissions will be revoked,
      and budget items they own (such as campaigns and expenses) will be marked as <b>${teamMemberName} (Inactive)</b>.<br>
    You will be able to filter to this user’s budget items to change ownership later.`
  }),
  [ActionToConfirm.LeavePage]: () => ({
    submitAction: { label: 'Leave', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Are you sure?',
    content: `You will lose unsaved changes`
  })
};

export const getConfirmationDialogData = (actionType: ActionToConfirm, accountOwnerName, teamMemberName?) => {
  return confirmationDialogData[actionType](accountOwnerName, teamMemberName);
};

export const createEmptyProfileForm = (): UntypedFormGroup => {
  const profileForm = fb.group(
    {
      companyUserId: '',
      userProfileId: '',
      firstName: [ '', [ Validators.required ] ],
      lastName: [ '', [ Validators.required ] ],
      email: [ '', [ Validators.required, Validators.email ] ]
    }, { updateOn: 'blur' }
  );

  return profileForm;
};

export const createEmptyPermissionRow = (): PermissionFormGroup => {
  const permissionRow = fb.group(
    {
      permission: '',
      budget: [ null, permissionFieldValidator() ],
      segments: [ [], permissionFieldValidator() ],
      rollbackPermission: '',
      rollbackBudget: null
    }, { updateOn: 'blur' }
  ) as PermissionFormGroup;
  permissionRow.controls.budget.disable({ emitEvent: false });
  permissionRow.controls.segments.disable({ emitEvent: false });
  return permissionRow;
};

export const createAdminPermissionRow = (): PermissionFormGroup => {
  const createdForm = createEmptyPermissionRow();
  createdForm.patchValue({ permission: UserPermission.Admin });
  createdForm.enable({ emitEvent: false });
  return createdForm;
};

export const createPermissionRow = (permissionForm: BudgetPermission): PermissionFormGroup => {
  const createdForm = createEmptyPermissionRow();
  createdForm.patchValue(permissionForm);
  createdForm.enable({ emitEvent: false });
  return createdForm;
};

export const createPermissionsForm = (permissions: BudgetPermission[], companyUserId, isAdmin): PermissionsListFormGroup => {
  let permissionsArray = [createEmptyPermissionRow()];
  if (isAdmin) {
    permissionsArray = [createAdminPermissionRow()]
  } else if (permissions && permissions.length) {
    permissionsArray = permissions.map(
      permissionItem => createPermissionRow(permissionItem)
    );
  }
  return fb.group({ permissions: fb.array(permissionsArray), companyUserId: companyUserId }) as PermissionsListFormGroup;
};

export const createEmptyTeamMember = (): TeamMember => {
  return {
    id: null,
    profileId: null,
    companyUserId: null,
    sso: null,
    weeklyEmails: false,
    invitationDate: '',
    isActive: false,
    isAdmin: false,
    withPermission: false,
    inviteAccepted: false,
    editable: true,
    profileForm: null,
    permissionsForm: null
  }
};

export const getSortedData = (teamMembers: TeamMember[], sortParams?: SortParams) => {
  const compareFormField = (rowA: TeamMember, rowB: TeamMember) => {
    const formDataA = rowA.profileForm.value;
    const formDataB = rowB.profileForm.value;
    return formDataA[sortParams.column].localeCompare(formDataB[sortParams.column])
  };
  const compareTeamMemberField = (rowA: TeamMember, rowB: TeamMember) => {
    (rowA[sortParams.column] || '').localeCompare(rowB[sortParams.column])
  };
  const compareFunc = sortParams && sortParams.column !== 'sso'
    ? compareFormField
    : compareTeamMemberField;

  const sortedData = [...teamMembers].sort(compareFunc);
  if (sortParams && sortParams.reverse) {
    sortedData.reverse();
  }
  return sortedData;
};

export const getPermissionsFormValue = (formArray: UntypedFormArray) => {
  const formGroups = formArray.controls as PermissionFormGroup[] || [];
  const permissionMap = {
    [UserPermission.Admin]: PermissionType.ADMIN,
    [UserPermission.ReadWrite]: PermissionType.READ_AND_WRITE,
    [UserPermission.ReadOnly]: PermissionType.READ
  };
  return formGroups.map(
    formGroup => {
      const permissionType = formGroup.controls.permission.value;
      return {
        budget_id: formGroup.controls.budget.value.id,
        permission_type: permissionMap[permissionType],
        segments: formGroup.controls.segments.value.map(value => value.id)
      }
    }
  )
};

export const permissionFieldValidator = (): ValidatorFn => {
  const validator: ValidatorFn = (control: AbstractControl) => {
    const permissionForm = control.parent as PermissionFormGroup;
    const isAdmin = permissionForm &&  permissionForm.controls.permission.value === UserPermission.Admin;
    const validValue = Array.isArray(control.value) ? control.value.length > 0 : control.value;
    const isValid = isAdmin || validValue;
    return isValid ? null : { required: true };
  };
  return validator;
};

export const validateTeamPageData = (teamMembers: TeamMember[]): boolean => {
  return !teamMembers.some(
    teamMember => {
      teamMember.profileForm.markAllAsTouched();
      const profileFormValid = teamMember.profileForm.disabled || teamMember.profileForm.valid;
      const permissionsArray = teamMember.permissionsForm.controls.permissions.controls as PermissionFormGroup[];
      const permissionsAreValid = permissionsArray.every((fg: PermissionFormGroup) => {
        fg.markAllAsTouched();
        return fg.disabled || fg.valid;
      });
      return !permissionsAreValid || !profileFormValid;
    }
  )
};

export const isInviteAccepted = (companyUser: CompanyUserDO): boolean => {
  return (companyUser.status === CompanyUserStatus.Active && !companyUser.invitation_date) || !!companyUser.first_login;
};
