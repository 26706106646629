import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const SKIP_COMMON_HEADERS = new HttpContextToken<boolean>(() => false);

@Injectable()
export class CommonHeadersInterceptor implements HttpInterceptor {
  private readonly commonHeaders = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': new Date().getTime().toString()
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipCommonHeaders = req.context.get(SKIP_COMMON_HEADERS);
    if (skipCommonHeaders) {
      return next.handle(req);
    }

    const cloned = req.clone({ setHeaders: this.commonHeaders });
    return next.handle(cloned);
  }
}
