import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DynamicPortalTooltipComponent } from '../components/dynamic-portal-tooltip/dynamic-portal-tooltip.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { debounceTime, filter, skip, takeUntil } from 'rxjs/operators';

export interface TooltipContext {
  header?: string;
  body?: string;
  action?: {
    title: string;
    callback: () => void;
  };
  footer?: string;
  icon?: IconProp;
  width?: number;
}

@Directive({
  selector: '[dynamicPortalTooltip]'
})
export class DynamicPortalTooltipDirective implements OnInit, OnDestroy {
  @Input() tooltipShowDelay = 300;
  @Input() tooltipContext: TooltipContext;
  @Input() tooltipPositionCssClass: 'top'| 'right'| 'above'| 'left';
  @Input() tooltipPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'bottom',
  };

  private overlayRef: OverlayRef;
  private positionStrategy: PositionStrategy;
  private showDelayTimeout = null;
  public readonly tooltipIsHovered$ = new BehaviorSubject<boolean>(false);
  public readonly triggerIsHovered$ = new BehaviorSubject<boolean>(false);
  private readonly onTooltipClosed$ = new Subject<void>();

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  public ngOnInit(): void {
    this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.tooltipPosition]);
  }

  private onTooltipAttached(): void {
    merge(this.triggerIsHovered$, this.tooltipIsHovered$).pipe(
      takeUntil(this.onTooltipClosed$),
      skip(1),
      debounceTime(250),
      filter(() => {
        return !this.triggerIsHovered$.getValue() && !this.tooltipIsHovered$.getValue();
      })
    ).subscribe(() => this.hide());
  }

  public ngOnDestroy(): void {
    this.hide();
  }

  @HostListener('mouseenter')
  private mouseEnter(): void {
    if (!this.tooltipContext) {
      return;
    }
    this.triggerIsHovered$.next(true);
    if (!this.overlayRef && !this.showDelayTimeout) {
      this.showDelayTimeout = setTimeout(() => this.attachTooltip(), this.tooltipShowDelay);
    }
  }

  @HostListener('mouseleave')
  private mouseLeave(): void {
    this.triggerIsHovered$.next(false);
    if (this.showDelayTimeout) {
      clearTimeout(this.showDelayTimeout);
      this.showDelayTimeout = null;
    }
  }

  private hide(): void {
    this.onTooltipClosed$.next();
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
    if (this.showDelayTimeout) {
      clearTimeout(this.showDelayTimeout);
    }
  }

  private attachTooltip(): void {
    this.showDelayTimeout = null;
    this.overlayRef = this.overlay.create({
      positionStrategy: this.positionStrategy
    });

    const tooltipPortal = new ComponentPortal(DynamicPortalTooltipComponent);
    const tooltipRef: ComponentRef<DynamicPortalTooltipComponent> = this.overlayRef.attach(tooltipPortal);

    tooltipRef.instance.tooltipContext = this.tooltipContext;
    tooltipRef.instance.positionCssClass = this.tooltipPositionCssClass;
    tooltipRef.instance.tooltipIsHovered$ = this.tooltipIsHovered$;

    this.onTooltipAttached();
  }
}
