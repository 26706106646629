import { ChildPlanObjectDO, PlanObjectDO } from 'app/shared/types/plan.type';
import { PlanObject } from '../dashboard.types';
import { ObjectLocalFilter } from 'app/header-navigation/components/filters/filters.interface';
import { Configuration } from 'app/app.constants';
import { PlanObjectTransformer } from './plan-object-transformer.interface';
import { prepareBudgetObjectTotals } from 'app/shared/utils/budget.utils';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagMappingsPipe } from 'app/shared/pipes/tag-mappings.pipe';
import { BudgetAmountsSummaryService } from 'app/shared/services/budget-amounts-summary.service';
import { PlanProgramExpense } from 'app/shared/types/program.interface';
import { UserDO } from 'app/shared/types/user-do.interface';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';

export abstract class PlanObjectsBuilder<TDataObject extends PlanObjectDO> {
  private readonly sourceDataObjects: TDataObject[];
  private readonly objectTransformers: PlanObjectTransformer<TDataObject>[] = [];

  private localFilter: ObjectLocalFilter<PlanObjectDO>;
  protected configuration: Configuration;

  protected static compareObjectsByName =
    (itemA: ChildPlanObjectDO | PlanProgramExpense, itemB: ChildPlanObjectDO | PlanProgramExpense) =>
      (itemA.name?.localeCompare(itemB.name));

  protected constructor(sourceDataObjects: TDataObject[], configuration: Configuration) {
    this.sourceDataObjects = sourceDataObjects;
    this.configuration = configuration;
  }

  public getResultPlanObjects(): (PlanObject & TDataObject)[] {
    if (!this.sourceDataObjects?.length) {
      return this.sourceDataObjects;
    }

    return this.applyLocalFilters(this.sourceDataObjects)?.map(
      planObject => this.transformObject(planObject)
    );
  }

  public withLocalFilter(filter: ObjectLocalFilter<PlanObjectDO>): PlanObjectsBuilder<TDataObject> {
    this.localFilter = filter;
    return this;
  }

  public prepareBudgetObjectTotals(): PlanObjectsBuilder<TDataObject> {
    this.addObjectTransformer(
      planObject => {
        planObject.status_totals =
          prepareBudgetObjectTotals(planObject.status_totals, planObject.mode === ObjectMode.Closed);
        return planObject;
      }
    );
    return this;
  }

  public setOwnerPicture(
    currentProfilePictures: { [userId: number]: Observable<string> },
    userImageLoader: (userId: number, loadedCb: (url: string) => void) => void
  ): PlanObjectsBuilder<TDataObject> {
    return this.addObjectTransformer(
      planObject => {
        if (planObject?.owner) {
          if (!currentProfilePictures[planObject.owner]) {
            const imageUrlSubject = new BehaviorSubject<string>(null);
            currentProfilePictures[planObject.owner] = imageUrlSubject.asObservable();
            userImageLoader(planObject.owner, url => imageUrlSubject.next(url));
          }

          planObject.userImageCssUrl =
            currentProfilePictures[planObject.owner].pipe(
              map(url => url && 'url(' + url + ')')
            );
        }
        return planObject;
      }
    );
  }

  public prepareTagsData(tagMappingsPipe: TagMappingsPipe): PlanObjectsBuilder<TDataObject> {
    return this.addObjectTransformer(
      planObject => {
        planObject.parsedTagsData = tagMappingsPipe.transform(planObject.tags_data);
        return planObject;
      }
    );
  }

  public addTooltipData(budgetAmountsSummaryService: BudgetAmountsSummaryService, currencyCode: string): PlanObjectsBuilder<TDataObject> {
    return this.addObjectTransformer(
      planObject => {
        budgetAmountsSummaryService.provideWithTooltipData(planObject, currencyCode);
        return planObject;
      }
    );
  }

  public addOwnerData(companyUsers: UserDO[]): PlanObjectsBuilder<TDataObject> {
    return this.addObjectTransformer(
      planObject => {
        if (planObject.owner) {
          const owner = companyUsers.find(user => user.id === planObject.owner);
          if (owner) {
            planObject.ownerFirstName = owner.first_name;
            planObject.ownerLastName = owner.last_name;
            planObject.ownerFullName = owner.name;
            planObject.ownerProfileImage = owner.user_profile_detail.file;
          }
        }
        return planObject;
      }
    );
  }

  public setBudgetObjectTypeName(
    budgetObjectTypes: BudgetObjectType[],
    typeIdSelector: (obj: TDataObject) => number
  ): PlanObjectsBuilder<TDataObject> {
    return this.addObjectTransformer(
      planCampaign => {
        planCampaign.typeName =
          budgetObjectTypes.find(budgetObjectType => budgetObjectType.id === typeIdSelector(planCampaign))?.name || '';
        return planCampaign;
      }
    );
  }

  protected provideWithObjectType<TChildObject extends ChildPlanObjectDO | PlanProgramExpense> (
    objects: TChildObject[],
    objectType: string
  ): TChildObject[] {
    objects?.forEach(obj => obj.objectType = objectType);
    return objects;
  }

  protected setObjectType(objectType: string): PlanObjectsBuilder<TDataObject> {
    this.addObjectTransformer(planObject => {
      planObject.objectType = objectType;
      return planObject;
    });
    return this;
  }

  protected addObjectTransformer(transformer: PlanObjectTransformer<TDataObject>) {
    this.objectTransformers.push(transformer);
    return this;
  }

  private transformObject(sourceObject: PlanObjectDO & TDataObject): PlanObjectDO & TDataObject {
    return this.objectTransformers.reduce(
      (source, transform) => transform(source),
      sourceObject
    );
  }

  private applyLocalFilters<T extends PlanObjectDO>(planObjects: T[]): T[] {
    return this.localFilter ? planObjects?.filter(obj => this.localFilter.satisfies(obj)) : planObjects;
  }
}
