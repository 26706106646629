import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-attributes',
  templateUrl: './icon-attributes.component.html'
})
export class IconAttributesComponent {
  @Input() size = '20px';
  @Input() darkIcon = false;
}
