<page-header pageName="Manage" class="add-shadow bottom-shadow">
  <fa-icon slot="icon" [icon]="['far', 'rocket-launch']"></fa-icon>
</page-header>
<section class="dashboard-content" scrollOnDrag>
  <section [ngClass]="{'section-removeBlue' : !budgetTimeframeList.length && showEmptyMsg }">
    <div class="box-vHeight" *ngIf="budgetTimeframeList.length == 0 && showEmptyMsg">
      <div class="box-vHeightInn box-planSettingMSG">
        <p>{{ validations.ValiditionMessages.NO_BUDGET_FOUND }}</p>
      </div>
    </div>
    <div class="box-rightSideInn box-newDashboardRightInn bg-whiteScroll" *ngIf="budgetTimeframeList.length > 0 && !showEmptyMsg">
      <div class="dashboard-content" scrollOnDrag>
        <mat-accordion [multi]="true" [displayMode]="'flat'">
          <mat-expansion-panel
            [class.is-loading]="viewSectionsLoadingState[viewSectionName.goals] === loadingState.Loading"
            [expanded]="viewSectionsData[viewSectionName.goals]"
            (opened)="openViewSection(viewSectionName.goals)"
            (closed)="closeViewSection(viewSectionName.goals)">
            <loader [isVisible]="viewSectionsLoadingState[viewSectionName.goals] === loadingState.Loading"></loader>
            <mat-expansion-panel-header>
              <h5 class="panel-title">
                Goals
                <a title="Add Goal" *ngIf="editPermission" class="btn-addCards" (click)="addGoalModal($event)"></a>
                <ul class="view-mode-icons ml-1">
                  <li class="table-view" (click)="goToManagePage(manageTableViewMode.Goals)">
                    <fa-icon [icon]="['fad', 'table']"></fa-icon>
                    Table View
                  </li>
                </ul>
              </h5>
            </mat-expansion-panel-header>
            <ul class="list-campCards" *ngIf="goalList.length > 0">
              <li *ngFor="let goal of goalList; trackBy: getObjectId;">
                <div class="pl-object-card">
                  <div class="box-campCardsInn box-goalCardsInn goal-card" [ngClass]="utClasses.goal">

                    <div *ngIf="goalTagsControlStates.get(goal)" class="tags-control-container">
                      <tags-control
                        [tagsList]="goal.parsedTagsData"
                        [autocompleteItems]="allCompanyTags"
                        [editOnly]="true"
                        (onAddTag)="handleAddingTagForGoal($event, goal)"
                        (onCreateTag)="handleCreatingNewTagForGoal($event, goal)"
                        (onRemoveTag)="handleRemovingTagOfGoal($event, goal)"
                        (clickOutside)="handleHideGoalTagControl(goal)">
                      </tags-control>
                    </div>

                    <div class="panel-heading panel-camCardHead" (click)="openViewGoal(goal.id)">
                      <div class="frow-camCardRight title" [ngClass]="{'small-letters': goal.name?.length > configuration.goal_small}">
                        <span>{{goal.name}}</span>
                      </div>
                      <div class="box-goalConTooltip">
                        <h6>{{goal.name}}</h6>
                        <b>{{goal.goalTypeName}}</b>
                        <i>Updated {{goal.upd | isoDate | date : 'MM/dd/yyyy'}}</i>
                        <a class="link-click4More" href="javascript:" title="">Click for more</a>
                      </div>
                    </div>

                    <div class="panel-body" (click)="getGoal(goal.id)">
                      <div class="box-progressBar box-progressBarGoal"
                           [ngClass]="{'box-noMetric': goal.metric_data.length == 0}"
                           generateMetricsGraph [graphData]="goal.metric_data" [goalData]="goal" [graphAt]="graphAt"
                      ></div>
                    </div>

                    <div class="panel-footer">
                      <div class="tags" (click)="handleTogglingGoalTagsControl(goal, $event)">
                        <icon-tags color="#116AB7" [tagsCount]="goal.tags_data?.length"></icon-tags>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="box-noContent td-expenseNotFound"  *ngIf="goalList.length <= 0">
              <span *ngIf="goalContentExists">{{validations.ValiditionMessages.GOALS_NOT_AVAILABLE_FILTER}}</span>
              <span *ngIf="!goalContentExists">{{validations.ValiditionMessages.GOALS_NOT_AVAILABLE}}</span>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            [class.is-loading]="viewSectionsLoadingState[viewSectionName.campaigns] === loadingState.Loading"
            [expanded]="viewSectionsData[viewSectionName.campaigns]"
            (opened)="openViewSection(viewSectionName.campaigns)"
            (closed)="closeViewSection(viewSectionName.campaigns)">
            <loader [isVisible]="viewSectionsLoadingState[viewSectionName.campaigns] === loadingState.Loading"></loader>
            <mat-expansion-panel-header>
              <h5 class="panel-title">
                Campaigns<a title="Add Campaign" *ngIf="editPermission" class="btn-addCards" (click)="openAddCampaign($event)"></a>
                <ul class="view-mode-icons ml-1">
                  <li class="table-view" (click)="goToManagePage(manageTableViewMode.Campaigns)">
                    <fa-icon [icon]="['fad', 'table']"></fa-icon>
                    Table View
                  </li>
                </ul>
              </h5>
            </mat-expansion-panel-header>
            <div class="sortingDropdownMenu">
              <dropdown-menu
                [items]="sortDropdownMenuItems"
                [selectedValue]="campaignsSortingMode"
                (onSelectValue)="handleCampaignsSortModeChange($event)"
              ></dropdown-menu>
            </div>
            <ul class="list-campCards" *ngIf="planCampaigns?.length > 0">
              <li
                *ngFor="let planCampaign of planCampaigns; let i = index; trackBy: getObjectId">
                <div class="pl-object-card" [class.read-only]="planCampaign | isSharedSubObject"
                     [plDroppable]="allowDrop"
                     (dropOn)="dropDataOnCampaign(planCampaign)"
                >
                    <span class="no-campaign-drag" *ngIf="activeDragEntity?.id === planCampaign.id && forbidDrag">
                      <fa-icon [icon]="['fas', 'ban']"></fa-icon>
                      Cannot drag <br /> Parent Campaign
                    </span>
                  <div class="box-campCardsInn campaign-card"
                       [className]="utClasses.campaign"
                       [ngClass]="{
                        'box-closedCard': planCampaign.mode == 'Closed',
                        'allowDrop': dropTargetCampaignIds.length && dropTargetCampaignIds[i] === planCampaign?.id,
                        'disallowDrop': dropTargetCampaignIds.length && dropTargetCampaignIds[i] !== planCampaign?.id
                      }"
                       #dragCard
                       dragImage
                       [nodeElement]="dragCard"
                       [plDraggable]="isEditingAllowed(planCampaign)
                         && !isObjectClosed(planCampaign)
                         && !isSegmentlessCampaign(planCampaign)"
                       (dragStart)="onCampaignDragStart(planCampaign)"
                       (dragEnd)="onCampaignDragEnd()"
                  >
                    <div *ngIf="campaignTagsControlStates.get(planCampaign)" class="tags-control-container">
                      <tags-control
                        [tagsList]="planCampaign.parsedTagsData"
                        [autocompleteItems]="allCompanyTags"
                        [editOnly]="!(planCampaign | isSharedSubObject)"
                        [editForbidden]="planCampaign | isSharedSubObject"
                        (onAddTag)="handleAddingTagForCampaign($event, planCampaign)"
                        (onCreateTag)="handleCreatingNewTagForCampaign($event, planCampaign)"
                        (onRemoveTag)="handleRemovingTagOfCampaign($event, planCampaign)"
                        (clickOutside)="handleHideCampaignTagsControl(planCampaign)">
                      </tags-control>
                    </div>
                    <div class="panel-heading panel-camCardHead" (click)="openViewCampaign(planCampaign)">
                      <div
                        class="frow-camCardRight title"
                        matTooltipClass="dark-theme-tooltip above"
                        matTooltipPosition="above"
                        [matTooltip]="'Campaign Type: ' + planCampaign.typeName"
                        [ngClass]="{'small-letters': planCampaign.name?.length > configuration.camp_small }"
                      >
                        <div class="object-type-icon" [class.closed]="planCampaign.mode === objectMode.Closed">
                          <ng-template [ngIf]="planCampaign.mode !== objectMode.Closed" [ngIfElse]="closedIcon">
                            <fa-duotone-icon
                              *ngIf="planCampaign.typeName !== ExternalIntegrationObjectTypes.GoogleAds
                                     && planCampaign.typeName !== ExternalIntegrationObjectTypes.LinkedinAds
                                     && planCampaign.typeName !== ExternalIntegrationObjectTypes.FacebookAds"
                              [icon]="['fad', 'rocket-launch']"
                            ></fa-duotone-icon>
                            <icon-google-ads
                              *ngIf="planCampaign.typeName === ExternalIntegrationObjectTypes.GoogleAds"
                            ></icon-google-ads>
                            <icon-linkedin
                              *ngIf="planCampaign.typeName === ExternalIntegrationObjectTypes.LinkedinAds"
                            ></icon-linkedin>
                            <icon-facebook
                              *ngIf="planCampaign.typeName === ExternalIntegrationObjectTypes.FacebookAds"
                            ></icon-facebook>
                          </ng-template>
                          <ng-template #closedIcon>
                            <fa-duotone-icon [icon]="[ 'fad', 'rocket' ]"></fa-duotone-icon>
                          </ng-template>
                        </div>
                        <span>{{planCampaign.name}}</span>
                      </div>
                      <div
                        *ngIf="editPermission && !(planCampaign | isSharedSubObject)"
                        class="icon-grip-container drag-handle">
                        <icon-grip size="16px" title="Add this to a Goal by dragging it there"></icon-grip>
                      </div>
                      <tooltip-container *ngIf="planCampaign | isSharedSubObject" class="arrow-up bottom-position read-only-card-tooltip">
                        {{ validations.ValiditionMessages.SHARED_COST_CAMPAIGN_FILTERED_BY_SEGMENT }}
                      </tooltip-container>
                    </div>

                    <div class="panel-body" (click)="openViewCampaign(planCampaign)">
                      <div class="box-camCardAmt amount" title="{{companyCurrency?.symbol}} {{planCampaign.amount | number}}">
                          <span [class.overspend]="planCampaign.status_totals.overspend > 0" (mouseenter)="displayAmountsTooltip($event, planCampaign['tooltipData'], 3)" (mouseleave)="hideAmountsTooltip()">
                            <strong [className]="'currency-' + companyCurrency.code | lowercase"></strong>
                            {{planCampaign.amount | smallNumber}}
                            <div *ngIf="planCampaign.status_totals.overspend > 0">
                              <icon-flame [hasProblem]="true"></icon-flame>
                              {{ planCampaign.status_totals.overspend | smallNumber }}
                            </div>
                          </span>
                      </div>
                      <div class="box-progressBar box-progressBarCam"
                           (mouseenter)="displayAmountsTooltip($event, planCampaign['tooltipData'], 18)"
                           (mouseleave)="hideAmountsTooltip()"
                      >
                        <bar-graph
                          [graphData]="planCampaign.status_totals"
                          [currencySymbol]="companyCurrency?.symbol"
                          [selectedStatusValues]="currentFilters[filterNames.Statuses] || []"
                          [excludedValues]="excludedGraphValues"
                        ></bar-graph>
                      </div>
                      <div class="bottom-container">
                        <div *ngIf="planCampaign.parsedTagsData" class="tags" (click)="handleTogglingCampaignTagsControl(planCampaign, $event)">
                          <icon-tags color="#116AB7" [tagsCount]="planCampaign.parsedTagsData.length"></icon-tags>
                        </div>
                        <div class="timeframes">
                          <timeframes
                            [timeframesList]="budgetTimeframeList"
                            [selectedTimeframeIds]="planCampaign.active_timeframe_ids"
                            [tooltipText]="'These timeframes represent expected or actual expenses – please see the Calendar tab to see when campaigns are active'"
                          ></timeframes>
                          <div class="timeframe-status" *ngIf="planCampaign.mode === objectMode.Closed">
                            {{planCampaign.mode}}
                          </div>
                        </div>
                        <div class="profile" (click)="$event.stopPropagation()">
                          <figure class="owner-avatar">
                            <span *ngIf="planCampaign.ownerProfileImage" [style.background-image]="planCampaign.userImageCssUrl | async"></span>
                            <span
                              *ngIf="!planCampaign.ownerProfileImage"
                              getInitials
                              [firstName]="planCampaign.ownerFirstName"
                              [lastName]="planCampaign.ownerLastName"
                              [fullName]="planCampaign.ownerFullName"
                            ></span>
                          </figure>
                          <div class="graph-tooltip">
                            <p>{{planCampaign.ownerFullName}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel-footer" [ngClass]="{'empty-campCardsProg': planCampaign.children?.length <= 0}">
                      <div class="show-expense-bucket-list" >
                        <button *ngIf="planCampaign.children?.length > 0"  class="pl-btn transparent" (click)="toggleNestedProgramsShowingState(planCampaign)">
                          Children ({{planCampaign.children?.length}})
                          <span>
                              <drop-down-arrow [opened]="!!nestedProgramsShowingStates.get(planCampaign)"></drop-down-arrow>
                            </span>
                        </button>
                        <div class="shared-cost-select d-flex ai-center"
                             *ngIf="!isSegmentlessCampaign(planCampaign)"
                        >
                          <div class="segment">
                            <hierarchy-menu
                              *ngIf="isEditingAllowed(planCampaign)"
                              [class.closed]="isObjectClosed(planCampaign)"
                              [items]="campaignCardSegmentList"
                              [config]="menuConfig"
                              [placeholder]="'Search Segments'"
                              (selectValue)="updateObjectSplitRule(planCampaign.id, $event, configuration.OBJECT_TYPES.campaign, campaignHasChildObjects(planCampaign))"
                              [selectedSegment]="planCampaign.company_budget_segment1"
                            >
                            </hierarchy-menu>
                          </div>
                          <div class="shared-cost">
                            <cost-rules
                              *ngIf="allowedSharedCostRules.length > 0 && isEditingAllowed(planCampaign)"
                              [class.closed]="isObjectClosed(planCampaign)"
                              [segmentList]="budgetSegmentList"
                              [sharedCostRuleList]="allowedSharedCostRules"
                              [dropDownTrigger]="true"
                              [currentSharedState]="planCampaign.split_rule | sharedCostState: null: planCampaign.children"
                              [selectedData]="{budgetSegmentId: planCampaign.company_budget_segment1, sharedCostRuleId: planCampaign.split_rule}"
                              [readOnly]="planCampaign | isSharedSubObject"
                              (selectValue)="updateObjectSplitRule(planCampaign.id, $event, configuration.OBJECT_TYPES.campaign, campaignHasChildObjects(planCampaign))"
                            >
                            </cost-rules>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <child-objects-list
                    *ngIf="nestedProgramsShowingStates.get(planCampaign)"
                    [isEditingAllowed]="isEditingAllowed(planCampaign)"
                    [isPowerUser]="isPowerUser"
                    [parentObject]="planCampaign"
                    [childObjects]="planCampaign.children"
                    [segmentList]="budgetSegmentList"
                    [segmentMenuList]="campaignCardSegmentList"
                    [segmentNameById]="segmentNameById"
                    [sharedCostRuleList]="allowedSharedCostRules"
                    [isCompanyCard]="true"
                    (onItemClick)="openCampaignChild($event)"
                    (onChangeObjectSplitRule)="updateObjectSplitRule($event.id, $event.item, $event.object?.objectType, campaignChildHasChildObjects($event.object))"
                  >
                  </child-objects-list>
                </div>
              </li>
            </ul>
            <div class="box-noContent td-expenseNotFound" *ngIf="!planCampaigns?.length">
              <span *ngIf="planCampaignsExist">{{validations.ValiditionMessages.CAMPAIGNS_NOT_AVAILABLE_FILTER}}</span>
              <span *ngIf="!planCampaignsExist">{{validations.ValiditionMessages.CAMPAIGNS_NOT_AVAILABLE}}</span>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            [class.is-loading]="viewSectionsLoadingState[viewSectionName.buckets] === loadingState.Loading"
            [expanded]="viewSectionsData[viewSectionName.buckets]"
            (opened)="openViewSection(viewSectionName.buckets)"
            (closed)="closeViewSection(viewSectionName.buckets)">
            <loader [isVisible]="viewSectionsLoadingState[viewSectionName.buckets] === loadingState.Loading"></loader>
            <mat-expansion-panel-header>
              <h5 class="panel-title">
                Expense Groups<a title="Add Expense Group" *ngIf="editPermission" class="btn-addCards" (click)="openAddProgram($event)"></a>
                <ul class="view-mode-icons ml-1">
                  <li class="table-view" (click)="goToManagePage(manageTableViewMode.CampaignsExpenses)">
                    <fa-icon [icon]="['fad', 'table']"></fa-icon>
                    Table View
                  </li>
                </ul>
              </h5>
            </mat-expansion-panel-header>
            <div class="sortingDropdownMenu">
              <dropdown-menu
                [items]="sortDropdownMenuItems"
                [selectedValue]="expenseBucketsSortingMode"
                (onSelectValue)="handleExpenseBucketsSortModeChange($event)"
              ></dropdown-menu>
            </div>
            <ul class="list-programCard" *ngIf="planPrograms?.length">
              <li *ngFor="let planProgram of planPrograms; let i = index; trackBy: getObjectId;">
                <div class="pl-object-card" [class.read-only]="planProgram | isSharedSubObject">
                  <div class="box-campCardsInn program-card"
                       [className]="utClasses.expenseGroup"
                       [ngClass]="{
                        'box-closedCard': planProgram.mode == 'Closed',
                        'allowDrop': dropTargetCampaignIds.length && dropTargetCampaignIds[i] === activeDragEntity?.id
                      }"
                       #dragCard
                       dragImage
                       [nodeElement]="dragCard"
                       [plDraggable]="isEditingAllowed(planProgram) && !isObjectClosed(planProgram)"
                       (dragStart)="onCampaignDragStart(planProgram)"
                       (dragEnd)="onCampaignDragEnd()"
                  >
                    <div *ngIf="programTagsControlStates.get(planProgram)" class="tags-control-container">
                      <tags-control
                        [tagsList]="planProgram.parsedTagsData | toTagMappings"
                        [autocompleteItems]="allCompanyTags"
                        [editOnly]="!(planProgram | isSharedSubObject)"
                        [editForbidden]="planProgram | isSharedSubObject"
                        (onAddTag)="handleAddingTagForProgram($event, planProgram)"
                        (onCreateTag)="handleCreatingNewTagForProgram($event, planProgram)"
                        (onRemoveTag)="handleRemovingTagOfProgram($event, planProgram)"
                        (clickOutside)="handleHideProgramTagsControl(planProgram)">
                      </tags-control>
                    </div>

                    <div class="panel-heading panel-camCardHead" (click)="openViewProgram(planProgram)">
                      <div
                        class="frow-camCardRight title"
                        matTooltipClass="dark-theme-tooltip above"
                        matTooltipPosition="above"
                        [matTooltip]="'Program Type: ' + planProgram.typeName"
                        [ngClass]="{'small-letters': planProgram.name?.length > configuration.prog_small }"
                      >
                        <div class="object-type-icon">
                          <fa-duotone-icon
                            [icon]="['fad', 'briefcase']"
                          ></fa-duotone-icon>
                        </div>
                        <span>{{ planProgram.name }}</span>
                      </div>

                      <div *ngIf="editPermission && !(planProgram | isSharedSubObject)"
                           class="icon-grip-container drag-handle">
                        <icon-grip size="16px" title="Add this to a Goal or Campaign by dragging it there"></icon-grip>
                      </div>

                      <tooltip-container *ngIf="planProgram | isSharedSubObject" class="arrow-up bottom-position read-only-card-tooltip">
                        {{ validations.ValiditionMessages.SHARED_COST_PROGRAM_FILTERED_BY_SEGMENT }}
                      </tooltip-container>
                    </div>

                    <div class="panel-body" (click)="openViewProgram(planProgram)">
                      <div class="box-camCardAmt amount" title="{{companyCurrency?.symbol}} {{planProgram.amount | number}}">
                          <span [class.overspend]="planProgram.status_totals.overspend > 0" (mouseenter)="displayAmountsTooltip($event, planProgram['tooltipData'], 3)" (mouseleave)="hideAmountsTooltip()">
                            <strong [className]="'currency-' + companyCurrency.code | lowercase"></strong>
                            {{planProgram.amount | smallNumber}}
                            <div *ngIf="planProgram.status_totals.overspend > 0">
                              <icon-flame [hasProblem]="true"></icon-flame>
                              {{ planProgram.status_totals.overspend | smallNumber }}
                            </div>
                          </span>
                      </div>
                      <div class="box-progressBar box-progressBarCam"
                           (mouseenter)="displayAmountsTooltip($event, planProgram['tooltipData'], 18)"
                           (mouseleave)="hideAmountsTooltip()"
                      >
                        <bar-graph
                          [graphData]="planProgram.status_totals"
                          [currencySymbol]="companyCurrency?.symbol"
                          [selectedStatusValues]="currentFilters[filterNames.Statuses] || []"
                          [excludedValues]="excludedGraphValues"
                        ></bar-graph>
                      </div>

                      <div class="bottom-container">
                        <div *ngIf="planProgram.tags_data" class="tags" (click)="handleTogglingProgramTagsControl(planProgram, $event)">
                          <icon-tags color="#116AB7" [tagsCount]="planProgram.tags_data.length"></icon-tags>
                        </div>
                        <div class="timeframes">
                          <timeframes
                            [timeframesList]="budgetTimeframeList"
                            [selectedTimeframeIds]="planProgram.active_timeframe_ids"
                          ></timeframes>
                          <div class="timeframe-status" *ngIf="planProgram.mode == 'Closed'">
                            {{planProgram.mode}}
                          </div>
                        </div>
                        <div class="profile" (click)="$event.stopPropagation()">
                          <figure class="owner-avatar">
                            <span *ngIf="planProgram.ownerProfileImage" [style.background-image]="planProgram.userImageCssUrl | async"></span>
                            <span
                              *ngIf="!planProgram.ownerProfileImage"
                              getInitials
                              [firstName]="planProgram.ownerFirstName"
                              [lastName]="planProgram.ownerLastName"
                              [fullName]="planProgram.ownerFullName"
                            ></span>
                          </figure>
                          <div class="graph-tooltip">
                            <p>{{planProgram.ownerFullName}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel-footer" [ngClass]="{'empty-campCardsProg': planProgram.expense_list.length <= 0 }">
                      <div class="show-expenses-list">
                        <button *ngIf="planProgram.expense_list.length > 0" class="pl-btn transparent" (click)="toggleNestedExpensesShowingState(planProgram)">
                          Expenses ({{planProgram.expense_list.length}})
                          <span>
                                  <drop-down-arrow [opened]="!!nestedExpensesShowingStates.get(planProgram)"></drop-down-arrow>
                                </span>
                        </button>
                        <div class="shared-cost-select">
                          <segments-rules-select
                            *ngIf="allowedSharedCostRules.length > 0 && isEditingAllowed(planProgram)"
                            [class.closed]="isObjectClosed(planProgram)"
                            [segmentList]="budgetSegmentList"
                            [segmentNameById]="segmentNameById"
                            [sharedCostRuleList]="allowedSharedCostRules"
                            [dropDownTrigger]="true"
                            [currentSharedState]="planProgram.split_rule | sharedCostState: null: planProgram.expense_list"
                            [selectedData]="{budgetSegmentId: planProgram.company_budget_segment1, sharedCostRuleId: planProgram.split_rule}"
                            [readOnly]="planProgram | isSharedSubObject"
                            (selectValue)="updateObjectSplitRule(planProgram.id, $event, configuration.OBJECT_TYPES.program, programHasChildObjects(planProgram))"
                          ></segments-rules-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <child-objects-list
                    *ngIf="nestedExpensesShowingStates.get(planProgram)"
                    [isEditingAllowed]="isEditingAllowed(planProgram)"
                    [isPowerUser]="isPowerUser"
                    [parentObject]="planProgram"
                    [childObjects]="planProgram.expense_list"
                    [segmentList]="budgetSegmentList"
                    [segmentNameById]="segmentNameById"
                    [sharedCostRuleList]="allowedSharedCostRules"
                    (onItemClick)="viewExpense($event)"
                    (onChangeObjectSplitRule)="updateObjectSplitRule($event.id, $event.item, configuration.OBJECT_TYPES.expense, false)"
                  >
                  </child-objects-list>
                </div>
              </li>
            </ul>
            <div class="box-noContent td-expenseNotFound" *ngIf="!planPrograms?.length">
              <span *ngIf="planProgramsExist">{{validations.ValiditionMessages.PROGRAMS_NOT_AVAILABLE_FILTER}}</span>
              <span *ngIf="!planProgramsExist">{{validations.ValiditionMessages.PROGRAMS_NOT_AVAILABLE}}</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </section>
</section>

<budget-summary-bar
  class="add-shadow top-shadow"
  [summaryItems]="summaryBarItems"
  [calculationItems]="summaryBarCalculationItems"
  [summaryBarLoading$]="miniDashLoading$"
></budget-summary-bar>

<div class="budget-amounts-tooltip"
     [ngStyle]="budgetAmountsTooltipStyles"
     [ngClass]="budgetAmountsTooltipClass">
  <ng-template #budgetAmountsTooltip></ng-template>
</div>
