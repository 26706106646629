import { HubspotApiCampaignObject, HubspotApiEmailDataObject, HubspotApiFormDataObject } from './hubspot-api.types';
import { HubspotCampaignStatus, HubspotCampaignType } from './hubspot.types';
import { ExternalCampaign } from '../types/external-campaign.interface';
import { IntegrationChildrenMappingState, IntegrationMappingState } from '../types/metric-integration';
import { capitalizeString } from 'app/shared/utils/common.utils';

const emptyStringFieldValue = 'N/A';

export class HubspotApiDataMapper {
  private static campaignTypeLabel = {
    [HubspotCampaignType.WebForm]: 'Web Form',
    [HubspotCampaignType.MarketingEmail]: 'Marketing Email',
    [HubspotCampaignType.UtmCampaign]: 'HubSpot Campaign',
  };

  private static mapperByCampaignType = {
    [HubspotCampaignType.WebForm]: (DO: HubspotApiCampaignObject) => {
      return HubspotApiDataMapper.mapFormObjectToCampaign(DO as HubspotApiFormDataObject);
    },
    [HubspotCampaignType.MarketingEmail]: (DO: HubspotApiCampaignObject) => {
      return HubspotApiDataMapper.mapEmailObjectToCampaign(DO as HubspotApiEmailDataObject);
    },
    [HubspotCampaignType.UtmCampaign]: (DO: HubspotApiCampaignObject) => {
      return HubspotApiDataMapper.mapUtmCampaignObjectToCampaign(DO);
    },
  };

  private static _mapDataObjectToCampaign(campaign: HubspotApiCampaignObject): ExternalCampaign {
    return {
      id: campaign.id.toString(),
      parentId: null,
      name: campaign.name,
      owner: campaign.author ? campaign.author.name : emptyStringFieldValue,
      description: emptyStringFieldValue,
      type: campaign.type,
      typeLabel: HubspotApiDataMapper.campaignTypeLabel[campaign.type],
      status: emptyStringFieldValue,
      startDate: null,
      endDate: null,
      currencyIsoCode: null,
      mappingState: IntegrationMappingState.NotMapped,
      mappingChildrenState: IntegrationChildrenMappingState.NotMapped,
      children: [],
    };
  }

  private static mapEmailObjectToCampaign(dataObject: HubspotApiEmailDataObject): ExternalCampaign {
    const status = dataObject.archived ? HubspotCampaignStatus.Archived : capitalizeString(dataObject.processingStatus);
    const emailCampaign: Partial<ExternalCampaign> = {
      description: dataObject.subject || emptyStringFieldValue,
      status: status,
      startDate: new Date(dataObject.publishDate)
    };
    const dataObjectCampaign = HubspotApiDataMapper._mapDataObjectToCampaign(dataObject);

    return {
      ...dataObjectCampaign,
      ...emailCampaign
    };
  }

  private static mapFormObjectToCampaign(dataObject: HubspotApiFormDataObject): ExternalCampaign {
    const status = dataObject.isPublished ? HubspotCampaignStatus.Published : HubspotCampaignStatus.NotPublished;
    const formCampaign: Partial<ExternalCampaign> = {
      status,
      description: emptyStringFieldValue,
      startDate: new Date(dataObject.updatedAt)
    };
    const dataObjectCampaign = HubspotApiDataMapper._mapDataObjectToCampaign(dataObject);

    return {
      ...dataObjectCampaign,
      ...formCampaign
    };
  }

  private static mapUtmCampaignObjectToCampaign(dataObject: HubspotApiCampaignObject): ExternalCampaign {
    return HubspotApiDataMapper._mapDataObjectToCampaign(dataObject);
  }

  public static mapDataObjectToCampaign(dataObject: HubspotApiCampaignObject): ExternalCampaign {
    const mapper = HubspotApiDataMapper.mapperByCampaignType[dataObject.type];
    if (!mapper) {
      console.error(`Failed to map '${dataObject.type}' object type`);
      return null;
    }

    return mapper(dataObject);
  }
}
