import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { SalesforceMappingDialogComponent } from './salesforce/salesforce-mapping-dialog/salesforce-mapping-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeModule } from '@angular/material/tree';
import { IntegrationScheduleSetupFormComponent } from './components/integration-schedule-setup-form/integration-schedule-setup-form.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SalesforceApiService } from './salesforce/salesforce-api.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { TimezoneStringifyPipe } from './pipes/timezone-stringify.pipe';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SalesforceMetricTypesService } from './salesforce/salesforce-metric-types.service';
import { MetricMappingDialogComponent } from './components/metric-mapping-dialog/metric-mapping-dialog.component';
import { MetricMappingManagementComponent } from './components/metric-mapping-management/metric-mapping-management.component';
import {
  ExternalMetricTypesMappingTableComponent
} from './components/external-metric-types-mapping-table/external-metric-types-mapping-table.component';
import { ExternalMetricTypesMappingComponent } from './components/external-metric-types-mapping/external-metric-types-mapping.component';
import { ExternalCampaignsFilterPipe } from './pipes/external-campaigns-filter.pipe';
import { IntegrationSetupDialogComponent } from './components/integration-setup-dialog/integration-setup-dialog.component';
import { IntegrationSetupLogoComponent } from './components/integration-setup-logo/integration-setup-logo.component';
import { HubspotMappingDialogComponent } from './hubspot/hubspot-mapping-dialog/hubspot-mapping-dialog.component';
import { HubspotApiService } from './hubspot/hubspot-api.service';
import { HubspotMetricTypesService } from './hubspot/hubspot-metric-types.service';
import { SalesforceMappingDialogService } from './salesforce/salesforce-mapping-dialog/salesforce-mapping-dialog.service';
import { HubspotMappingDialogService } from './hubspot/hubspot-mapping-dialog/hubspot-mapping-dialog.service';
import { MetricsListComponent } from './components/metrics-list/metrics-list.component';
import { RemapCampaignsDialogComponent } from './components/remaping-campaigns-dialog/remap-campaigns-dialog.component';
import { CampaignNameComponent } from './components/campaign-name/campaign-name.component';
import { IntegrationMappingsComponent } from './components/integration-mappings/integration-mappings.component';
import { MetricIntegrationsProviderService } from './services/metric-integrations-provider.service';
import { IntegratedObjectsService } from './services/integrated-objects.service';
import { IntegrationSetupProviderService } from './services/integration-setup-provider.service';
import { BtnContentGoogleComponent } from './components/btn-content-google/btn-content-google.component';
import { BtnContentLinkedinComponent } from './components/btn-content-linkedin/btn-content-linkedin.component';
import { BtnContentFacebookComponent } from './components/btn-content-facebook/btn-content-facebook.component';
import { ExternalMetricTypesProviderService } from './services/external-metric-types-provider.service';
import { CampaignsMappingComponent } from './components/campaigns-mapping/campaigns-mapping.component';

@NgModule({
  declarations: [
    SalesforceMappingDialogComponent,
    IntegrationScheduleSetupFormComponent,
    TimezoneStringifyPipe,
    MetricMappingDialogComponent,
    MetricMappingManagementComponent,
    ExternalMetricTypesMappingTableComponent,
    ExternalMetricTypesMappingComponent,
    ExternalCampaignsFilterPipe,
    IntegrationSetupDialogComponent,
    IntegrationSetupLogoComponent,
    HubspotMappingDialogComponent,
    MetricsListComponent,
    RemapCampaignsDialogComponent,
    CampaignNameComponent,
    IntegrationMappingsComponent,
    BtnContentGoogleComponent,
    BtnContentLinkedinComponent,
    BtnContentFacebookComponent,
    CampaignsMappingComponent,
  ],
  providers: [
    SalesforceApiService,
    SalesforceMetricTypesService,
    SalesforceMappingDialogService,
    HubspotApiService,
    HubspotMetricTypesService,
    HubspotMappingDialogService,
    MetricIntegrationsProviderService,
    IntegratedObjectsService,
    IntegrationSetupProviderService,
    ExternalMetricTypesProviderService
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FontAwesomeModule,
    MatButtonModule,
    MatTreeModule,
    ScrollingModule,
    MatTooltipModule,
    NgxMaterialTimepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    AutoSizeInputModule
  ],
  exports: [
    SalesforceMappingDialogComponent,
    IntegrationScheduleSetupFormComponent,
    ExternalMetricTypesMappingTableComponent,
  ]
})
export class MetricIntegrationsModule {}
