export interface BudgetAllocationsTableEvent {
  allocationId: number;
  budgetTimeframeId?: number;
  amount: string | number;
}

export enum BudgetAllocationsTableColumn {
  Allocated = 'allocated',
  Expenses = 'expenses'
}

export interface ActualSpendsData {
  id: number;
  value?: number;
  label: string;
  type: string;
}