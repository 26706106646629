import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms a plural noun string into singular one
 *  'opportunities' -> 'opportunity'
 *  'leads' -> 'lead'
 *  'revenue' -> 'revenue'
 */
@Pipe({
  name: 'plcSingularNoun'
})
export class SingularNounPipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value !== 'string') {
      return '';
    }

    if (value.endsWith('ies')) {
      return value.replace(/ies$/, 'y');
    }

    if (value.endsWith('s')) {
      return value.replace(/s$/, '');
    }

    return value;
  }
}
