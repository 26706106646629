import { ComponentRef, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

/**
 * Base class for ComponentPortal's based tooltip directive
 */
@Directive()
export abstract class BaseComponentPortalTooltipDirective implements OnInit, OnChanges, OnDestroy {
  @Input() showTooltip = false;
  @Input() tooltipShowDelay = 300;

  /* Ref to tooltip's component class */
  protected abstract componentClassRef;
  protected overlayRef: OverlayRef;
  protected positionStrategy: PositionStrategy;
  protected showDelayTimeout = null;
  protected overlayPositions: ConnectedPosition[] = [{
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
  }];

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  ngOnInit() {
    this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.overlayPositions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showTooltip) {
      if (!this.showTooltip) {
        this.hide();
      }
    }
  }

  ngOnDestroy(): void {
    this.hide();
  }

  abstract onComponentRefCreated(ref: ComponentRef<any>): void;

  protected attachTooltip() {
    if (!this.overlayRef || !this.showTooltip) {
      return;
    }

    const tooltipPortal = new ComponentPortal(this.componentClassRef);
    const tooltipRef: ComponentRef<any> = this.overlayRef.attach(tooltipPortal);

    this.onComponentRefCreated(tooltipRef);
  }

  @HostListener('mouseenter')
  private show() {
    if (!this.showTooltip) {
      return;
    }

    this.overlayRef = this.overlay.create({
      positionStrategy: this.positionStrategy
    });
    this.showDelayTimeout = setTimeout(() => this.attachTooltip(), this.tooltipShowDelay);
  }

  @HostListener('mouseout')
  private hide() {
    clearTimeout(this.showDelayTimeout);
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
