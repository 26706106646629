import { BudgetObjectParent } from '../../budget-object-details/types/budget-object-details-state.interface';

export const getParentFromLocation = (location = ''): BudgetObjectParent => {
  const [objectType, objectId] = (location || '').split('_');

  if (!objectType || !objectId) {
    return null;
  }

  return { id: Number(objectId), type: objectType };
};
