<button mat-button class="view-mode-btn" [matMenuTriggerFor]="panel.menu" [disabled]="disabled">
  <span class="view-mode-label">View by&nbsp;</span>
  <span class="view-mode-title">{{ hierarchyViewModeTitle[selectedMode] }}</span>
  <span class="arrow"></span>
</button>

<menu-panel #panel [items]="menuPanelItems" panelClass="expenses-view-mode-list">
  <ng-template #iconsTemplate let-item>
    <fa-duotone-icon
      *ngIf="item.customIcon === viewModeCustomIcons.PLUG"
      [icon]="['fad', viewModeCustomIcons.PLUG]"
      [rotate]="90"
      class="custom-icon"
    ></fa-duotone-icon>
    <fa-icon
      *ngIf="item.customIcon === viewModeCustomIcons.HEART_RATE"
      [icon]="['fas', viewModeCustomIcons.HEART_RATE]"
      class="custom-icon"
    ></fa-icon>
    <icon-user-helmet-safety
      *ngIf="item.customIcon === viewModeCustomIcons.USER_HELMET_SAFETY"
      class="custom-icon"
      [color]="item.isSelected ? '#061F38' : '#0063B7'"
    ></icon-user-helmet-safety>
  </ng-template>
</menu-panel>
