import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { DetailsDrawerBaseComponent } from 'app/budget-object-details/components/containers/details-drawer-base';
import { DrawerFormFields } from 'app/budget-object-details/components/containers/details-drawer-form';
import { BudgetObjectActionsShared } from 'app/budget-object-details/services/budget-object-actions-shared';
import { BudgetObjectAttachmentsService } from 'app/budget-object-details/services/budget-object-attachments.service';
import { BudgetObjectMetricsService } from 'app/budget-object-details/services/budget-object-metrics.service';
import { BudgetObjectTagsService } from 'app/budget-object-details/services/budget-object-tags.service';
import { GoalDetailsService } from 'app/budget-object-details/services/goal-details.service';
import { GoalDetailsState, MetricDetailsState, ObjectDetailsCommonState } from 'app/budget-object-details/types/budget-object-details-state.interface';
import { ObjectDetailsTabsDataService } from 'app/budget-object-details/services/object-details-tab-data.service';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { DrawerTabItem } from 'app/budget-object-details/types/object-details-tab-control-type.interface';
import { MetricDrawerService } from './metric-details-drawer.service';
import { MetricEstimatedPerformanceData, MetricMappingDO, MetricMappingGoalUpdateHistoryDO, MetricParentChildHierarchyDO, MetricProgressTowardsTargetDO } from '@shared/services/backend/metric.service';
import { Observable, combineLatest, forkJoin, iif, merge, of } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { ProductDO } from '@shared/services/backend/product.service';
import { MetricDetailsForm, MetricMappingDetailsState, MetricMappingThirdPartyAmount } from 'app/budget-object-details/types/metric-mapping-details-state.interface';
import { MetricType } from '@shared/types/budget-object-metric.interface';
import { UserDO } from '@shared/types/user-do.interface';
import { CampaignDO } from '@shared/types/campaign.interface';
import { createDeepCopy, roundDecimal } from '@shared/utils/common.utils';

import { faPlug, faRocketLaunch } from '@fortawesome/pro-duotone-svg-icons';
import { MetricBreakdown, MetricBreakdownSectionItem } from 'app/budget-object-details/types/metric-breakdown-data.interface';
import { MetricsUtilsService } from 'app/budget-object-details/services/metrics-utils.service';
import { MetricValueUpdateType } from 'app/budget-object-details/types/metric-value-update-item.interface';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { createDateString, parseDateString } from '../campaign-details/date-operations';
import { getParentFromLocation } from '@shared/utils/location.utils';
import { BudgetObjectActionsBuilder } from 'app/budget-object-details/services/budget-object-actions-builder.service';
import { MetricMappingCalculationService } from 'app/budget-object-details/services/metric-mapping-calculation.service';
import { messages, objectPlaceholderName } from 'app/budget-object-details/messages';
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { faRectangleHistory } from "@fortawesome/pro-regular-svg-icons"
import { MetricUnit } from 'app/budget-object-details/types/metric-unit.interface';
import { getObjectTypeKey } from '@shared/utils/budget.utils';
import { Metric } from '../../details-metrics/details-metrics.type';
import { MetricProgressState } from '@shared/types/metric-progress-state.type';
import { getTodaysDate } from '@shared/utils/date.utils';
import { MetricGraphData } from 'app/budget-object-details/types/metric-graph-data.interface';
import { BusinessValueService } from '../../business-value/business-value.service';
import { CompanyDO } from '@shared/types/company.interface';
import { CampaignService } from '@shared/services/backend/campaign.service';
import { Goal, GoalDO } from '@shared/types/goal.interface';
import { MetricUpdateService } from 'app/budget-object-details/services/metric-update.service';
import { MetricUpdateAction } from 'app/budget-object-details/types/metric-update-action.enum';
import { MetricMappingDetailsComponent } from 'app/budget-object-details/types/metric-mapping-details-component.interface';
import { DetailsCreationContext } from 'app/budget-object-details/types/details-creation-context.interface';
import { HistoryObjectLogTypeNames } from '@shared/types/history-object-log-type.type';
import { HistoryService, HistoryItem } from '@shared/services/history.service';
import { HierarchyItem } from '../../object-hierarchy-menu/object-hierarchy-menu.component';

const UPDATE_SUMMARY_ERROR_MSG = 'Failed to update campaign metric summary';

@Component({
  selector: 'metric-details-drawer',
  templateUrl: './metric-details-drawer.component.html',
  styleUrls: ['./metric-details-drawer.component.scss'],
  providers: [
    BudgetObjectActionsShared,
    BudgetObjectMetricsService,
    BudgetObjectTagsService,
    BudgetObjectAttachmentsService,
    BudgetAllocationActionsService,
    ObjectDetailsTabsDataService,
  ]
})
export class MetricDetailsDrawerComponent extends DetailsDrawerBaseComponent<GoalDetailsState> implements MetricMappingDetailsComponent {
  public readonly mappingType;
  private readonly menuActionsBuilder = inject(BudgetObjectActionsBuilder);
  private readonly metricUpdateService = inject(MetricUpdateService);
  private companyData: CompanyDO;
  currentMetricMapping: MetricMappingDO;
  currentMetricState: MetricMappingDetailsState;
  graphData: MetricGraphData;
  targetBusinessValue: number = null;
  actualBusinessValue: number = null;
  prevMetricState: MetricMappingDetailsState = null
  products: ProductDO[];
  product: ProductDO;
  metricProgressTowardsTarget: MetricProgressTowardsTargetDO;
  metrics: MetricType[];
  currentUser: UserDO;
  campaign: CampaignDO;
  childCampaigns: CampaignDO[];
  metricBreakdownData: MetricBreakdown;
  hasActiveThirdPartyAmounts: boolean;
  displayDecimal = false;
  isMetricFormValid = false;
  isMilestonesFormInvalid = false;
  defaultStartDate: string;
  defaultEndDate: string;
  performanceEstimateData: MetricEstimatedPerformanceData

  private mappedThirdPartyCampaignNumbers = {
    [MetricIntegrationName.Salesforce]: 0,
    [MetricIntegrationName.Hubspot]: 0
  };

  private integrationRowIconTemplateGetters: { [integrationName: string]: Partial<MetricBreakdownSectionItem> } = {
    [MetricIntegrationName.Salesforce]: { customIcon: 'salesforce' },
    [MetricIntegrationName.Hubspot]: { faIcon: faHubspot, faDuotone: false }
  };

  private metricBreakdownDataTemplate: MetricBreakdown = {
    currentMetric: {
      title: 'Campaign',
      data: null,
      icon: faRocketLaunch
    },
    integrationsMetric: {
      title: 'Integrations',
      data: [],
      totalRow: null,
      icon: faPlug
    },
    childCampaignMetrics: {
      title: 'Child Campaigns',
      icon: faRocketLaunch,
      data: [],
      totalRow: null
    },
    grandTotal: null
  }

  private metricBreakdownDataTemplateForGoal: MetricBreakdown = {
    campaignMetrics: {
      title: 'Campaign Metrics',
      icon: faRocketLaunch,
      data: [],
      totalRow: null
    },
    grandTotal: null
  };
  goalCampaigns: CampaignDO[];
  goalCampaignsMap = new Map<number, CampaignDO>();
  goalChildCampaignsMap = new Map<number, CampaignDO>();
  isCampaignMetricDrawer = true;
  unsavedMetricChangesFlag = false;
  disableSaveAndCloseButton: boolean = false;
  formDataInit = false;
  parentName = '';
  goal: GoalDO;
  metricGoal: Goal;
  metricGoalUpdateHistory: MetricMappingGoalUpdateHistoryDO[];
  historyLogTypes: HistoryItem[] = [];
  objectHierarchy: HierarchyItem[];
  childMetricHierarchy: HierarchyItem[];
  metricHierarchy: MetricParentChildHierarchyDO;

  protected initObjectCreation(): void { }

  private logMetricViewHistory() {
    this.budgetObjectDetailsManager.logObjectView(
      this.currentMetricState.objectId,
      this.companyId,
      this.budget.id,
      this.currentUser.id,
      HistoryObjectLogTypeNames.metricMapping,
      this.metricMappingDetailsService
    );
  }

  protected loadObjectDetails(id: number): void {
    this.resetExistingMetricDrawerStateContext();
    this.showLoader();
    this.companyDataService.selectedCompanyDO$
      .pipe(takeUntil(this.destroy$))
      .subscribe(company => {
        this.companyData = company;
      });
    this.loadDetailsContextData().pipe(
      switchMap(() => {
        return forkJoin([
          this.metricDrawerService.loadMetricDetails(id)
            .pipe(
              tap(metricMapping => this.currentMetricMapping = metricMapping),
              map(mapping => this.budgetObjectDetailsManager.filterMetricValuesForFixedDate([mapping])[0]),
              map(metricMapping => this.metricDrawerService.createMetricMappingDetailsState(metricMapping, false)),
              tap(metricMappingDetailsState => this.currentMetricState = metricMappingDetailsState),
              switchMap(() => iif(
                () => this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign,
                this.metricDrawerService.loadCampaignAndChildCampaigns$(this.currentMetricState.parentId, this.budget.id, this.segments, this.sharedCostRules).pipe(tap(data => {
                  this.campaign = data[0]
                  this.childCampaigns = data[1]
                  this.tabsDataService.setTabIcon(this.ObjectDetailsTabControl.Update, faPen)
                  this.tabsDataService.setTabTitle(this.ObjectDetailsTabControl.Update, this.ObjectDetailsTabControl.Update[0].toUpperCase() + this.ObjectDetailsTabControl.Update.slice(1))
                  this.parentName = this.campaign.name;
                  this.isCampaignMetricDrawer = true;
                })),
                this.metricDrawerService.loadGoalCampaignsAndChildCampaigns$(this.currentMetricState.parentId, this.budget.id).pipe(tap(data => {
                  this.goalCampaigns = data[0],
                    this.childCampaigns = data[1],
                    this.isCampaignMetricDrawer = false;
                  this.metricDrawerService.loadGoal(this.currentMetricState.parentId).subscribe(goal => {
                    this.goal = goal;
                    this.parentName = goal.name;
                  });

                  this.goalCampaigns.forEach(campaign => this.goalCampaignsMap.set(campaign.id, campaign))
                  this.childCampaigns.forEach(campaign => this.goalChildCampaignsMap.set(campaign.id, campaign))
                  this.tabsDataService.setTabIcon(this.ObjectDetailsTabControl.Update, faRectangleHistory)
                  this.tabsDataService.setTabTitle(this.ObjectDetailsTabControl.Update, this.ObjectDetailsTabControl.UpdateHistory)
                })))
              ),
              switchMap(() => this.metricDrawerService.loadMetricMappingsForChildCampaigns(
                this.currentMetricState,
                this.companyId,
                this.childCampaigns,
                this.currentMetricState.mappingType,
                this.goalCampaigns
              )),
              switchMap(() => {
                return this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign 
                ? of(null)
                : this.metricMappingDetailsService.loadMetricGoal(this.currentMetricState.parentId).pipe(tap( mGoal => {
                  this.metricGoal = mGoal;
                }))
              }),
              switchMap(() => {
                return this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign 
                ? of(null)
                : this.metricDrawerService.loadUpdateHistoryForGoalMetric(id).pipe(tap( updateHistory => {
                  updateHistory.sort((m1, m2) => Number(new Date(m1.date)) - Number(new Date(m2.date)))
                  this.metricGoalUpdateHistory = updateHistory;
                  }))
              }),
              switchMap(() => {
                return this.metricDrawerService.getMetricHierarchy(id).pipe(
                  tap(hierarchy => {
                    this.metricHierarchy = hierarchy
                  })
                )
              })

            ),
          this.metricDrawerService.getAllProductsDetails(),
          this.metricDrawerService.getProgressTowardsMetricTarget(id)
        ])
      }),
      takeUntil(this.destroy$)
    )

      .subscribe({

        next: ([_, products, metricProgressTowardsTarget]) => {
          this.logMetricViewHistory(); // updates the history actions

          this.currentState = this.currentMetricState as ObjectDetailsCommonState;
          if(!this.currentState.startDate) {
            this.currentState.startDate = this.metricMappingDetailsService.getDefaultStartDate(this.budget?.budget_from, this.campaign?.start_date);
            this.defaultStartDate = this.currentState.startDate;
          }
          this.products = products;
          this.metricProgressTowardsTarget = metricProgressTowardsTarget
          this.product = this.products.find(prod => prod.id === this.currentMetricMapping.metric_detail.product);

          this.createMetricParentChildrenMenuHierarchy(id);

          if (this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign) {
            if (this.currentMetricState.milestones && this.currentMetricState.milestones.length === 0) {
              this.currentMetricState.milestones.push({
                date: this.campaign?.end_date ? new Date(this.campaign?.end_date) : new Date(this.budget?.budget_to),
                targetValue: 0
              });
            } else if (this.currentMetricState.milestones && this.currentMetricState.milestones.length === 1 && !this.currentMetricState.milestones[0].date) {
              this.currentMetricState.milestones[0].date = this.campaign?.end_date ? new Date(this.campaign?.end_date) : new Date(this.budget?.budget_to);
            }
            this.currentMetricState.metricValueUpdates = this.getRefreshedMetricValueUpdates();
            this.buildMetricBreakDownData();
          } else {
            if (this.currentMetricState.milestones && this.currentMetricState.milestones.length === 0) {
              this.currentMetricState.milestones.push({
                date: new Date(this.budget?.budget_to),
                targetValue: 0
              });
            } else if (this.currentMetricState.milestones && this.currentMetricState.milestones.length === 1 && !this.currentMetricState.milestones[0].date) {
              this.currentMetricState.milestones[0].date = new Date(this.budget?.budget_to);
            }
            this.buildMetricBreakDownDataForGoal();
          }

          this.setFormData();
          this.updateDetailsTabData();
          this.setFormDataSubscriptions();
          this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentMetricState) as ObjectDetailsCommonState;
          this.userDataService.editPermission$
            .pipe(takeUntil(merge(this.destroy$, this.reset$)))
            .subscribe((editPermission: boolean) => {
              this.editPermission = editPermission;
              this.updateReadOnlyModeState();
            });
          this.applyMetricType(this.currentState).subscribe(() => {
          })
          this.metricMappingCalculationService.updateMetricStateRecords(this.currentState);
          this.updateCurrentSummary();
          this.updateSummary(isError => {
            if (!isError) {
              this.actualBusinessValue = this.calcBusinessValue(this.currentState.summary.totalValue);
              this.initGraphData();
            }
          });

          this.loadBudgetAndCompanyData$().subscribe(data => {
             this.applyPerformanceTabData();
          });

          this.hideLoader();
        },
        error: error => {
          const filteredMetricLog = this.historyLogTypes.filter(
            log => Number(log.object.id) === id && log.object.type === HistoryObjectLogTypeNames.metricMapping
          );

          let mappingType = filteredMetricLog.length !== 0 ? filteredMetricLog[0].parent.type : '';

          this.onError(
            error,
            messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, mappingType.toLowerCase() + ' metric'),
            false
          )
          
        }
      }
      )
  }

  setMetricValueLastUpdatedDate(event) {
    if (event.length === 0) {
      this.tabsDataService.setUpdateData(null)
    } else {
      this.tabsDataService.setUpdateData(`Last metric date: ${this.datePipe.transform(event, 'd LLL yyyy')}`)
    }
  }


  /**
   * Setting Performance tab data {Table data and tab sub header}
   */

  private getCurrentSpent(): number {
    if (this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign) {
    return (this.campaign?.status_totals?.committed + this.campaign?.status_totals?.closed) || 0;
    }else {
      return this.metricGoal && this.metricGoal.statusTotals &&
      (this.metricGoal.statusTotals.committed + this.metricGoal.statusTotals.closed) || 0;
    }
    } 

  private updateCurrentSummary() {
    if(!this.currentState) { return; }
    this.metricMappingDetailsService.updateCurrentSummary(this.currentState);
    if(this.currentMetricState.mappingType !== this.configuration.OBJECT_TYPES.campaign){
      this.currentState.currentValue = this.currentState?.summary?.totalValue || 0;
    }
  }

  private initGraphData() {
    this.graphData = {
      startDate: this.currentState.startDate,
      milestones: this.currentState.milestones
    };
    this.setGraphDataRecords();
  }

  private setGraphDataRecords() {
    this.graphData.CPORecords = this.currentState.CPORecords;
    this.graphData.ROIRecords = this.currentState.ROIRecords;
    this.graphData.metricValueRecords = this.currentState.metricValueRecords;
    this.graphData.targetROI = this.currentState.summary.targetROI || 0;
    this.graphData.targetCPO = this.currentState.summary.targetCPO || 0;
  }

  private resetExistingMetricDrawerStateContext() {
    this.currentMetricState = null;
    this.prevMetricState = null;
    this.currentMetricMapping = null;
    this.performanceEstimateData = null;
    this.graphData = null;
    this.metricProgressTowardsTarget = null;
    this.metricGoalUpdateHistory = [];
    this.formData.reset();
    this.formDataInit = false;
  }

  updateSummary(onReady?: (isError?: boolean) => void) {
    if(!this.currentState) { return; }
    forkJoin([
      this.metricMappingDetailsService.updateTargetValue$(this.currentState, this.getTargetCost()).pipe(
        tap(
          ([targetValue]) => this.targetBusinessValue = this.calcBusinessValue(targetValue)
        )
      ),
      this.metricMappingDetailsService.setActualMetricCalculations$(this.currentState, this.getCurrentSpent()).pipe(
        tap(
          () => this.updateCurrentSummary()
        )
      )
    ]).subscribe(
      () => onReady?.(false),
      () => {
        onReady?.(true);
        console.log(UPDATE_SUMMARY_ERROR_MSG);
      }
    );
  }

  private applyPerformanceTabData(metricData?: Partial<Metric>): void {
    const metric = metricData ? metricData : this.BudgetObjectMetricsService.convertDataObjectToMapping(this.currentMetricMapping);
    const totalMetricValue = this.currentState.summary ? this.currentState.summary.totalValue : this.currentState.currentValue;
    const { progressState, diffShare } = this.preparePerformanceData(metric, totalMetricValue, this.budgetTodayDate);
    const performanceData = {
      estimatedDiffPercentage: roundDecimal(diffShare * 100, 2),
      progressState
    }
    this.tabsDataService.setPerformanceData(performanceData);
    this.performanceEstimateData = performanceData

  }

  private preparePerformanceData(metric: Partial<Metric>, currentValue: number, todayDate?: Date): {
    progressState: MetricProgressState;
    diffShare: number;
    estimatedTarget: number
  } {

    let startDate = metric.startDate ? metric.startDate : this.defaultStartDate
    let milestones = metric.milestones && metric.milestones.length ? metric.milestones : [
      { targetValue: metric.legacyTarget, date: parseDateString(this.defaultEndDate) }
    ];



    const estimatedTarget = this.metricsUtilsService.getEstimatedTarget(
      getTodaysDate(todayDate),
      parseDateString(startDate),
      milestones
    );
    const { state: progressState, diffShare } = this.metricsUtilsService.getProgressState(estimatedTarget, currentValue);

    return { progressState, diffShare, estimatedTarget };
  }

  private applyMetricType(state: MetricMappingDetailsState): Observable<MetricMappingDetailsState> {
    return this.metricMappingDetailsService.applyMetricType$(state, this.companyData, this.metrics, this.products).pipe(
      map(([metricMappingState, displayDecimal]) => {
        this.displayDecimal = displayDecimal;
        return metricMappingState;
      })
    );
  }

  private calcBusinessValue(value: number): number {
    return this.currentState.isKeyMetric ?
      BusinessValueService.calcBusinessValue(
        value,
        this.currentState.revenuePerOutcome,
        this.currentState.revenueToProfit
      ) :
      null;
  }

  private getTargetCost() {
    if (this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign) {
    return this.campaign?.status_totals?.total || 0;
    }else {
      return this.metricGoal && this.metricGoal.statusTotals && this.metricGoal.statusTotals.total || 0;
    }
  }

  /**
   * Setting Performance tab data {Table data and tab sub header}
   */

  private setFormData() {
    const funnelName = this.product ? this.product.name : '';
    let isKeyMetric = null;
    if (this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign) {
      isKeyMetric = this.campaign.key_metric === this.currentMetricState.objectId;
      this.metricsManager.isKeyMetricSubject.next(isKeyMetric);
    }
    const metricUnit = this.currentMetricMapping.metric_detail.with_currency ? MetricUnit.Quality : MetricUnit.Quantity;
    const mappingType = this.currentMetricState.mappingType && this.currentMetricState.mappingType;
    const startDateObj = parseDateString(this.currentMetricState.startDate);
    const updatedDateObj = parseDateString(this.currentMetricMapping.upd);
    const metricName = funnelName ? `${funnelName} ${this.currentMetricMapping.metric_detail.name}` : this.currentMetricMapping.metric_detail.name;
    const formData: MetricDetailsForm = {
      name: metricName,
      startDate: startDateObj,
      notes: this.currentMetricState.notes,
      updated: updatedDateObj,
      funnelName,
      isKeyMetric,
      metricUnit,
      mappingType,
      milestones: this.currentMetricState.milestones
    };
    this.formData.setValue(formData);
    // Flag indicates that form Data is Initialized and Binded for the Metric Drawer
    this.formDataInit = true;
  }

  private setFormDataSubscriptions() {
    this.formData.valueChanges.subscribe(data => {
      if (!this.isMilestonesFormInvalid) {
        this.syncUnsavedMetricChanges();
      }
    })
  }

  private getMetricNameWithProduct(){
    const funnelName = this.product ? this.product.name : '';
    return funnelName ? `${funnelName} ${this.currentMetricMapping.metric_detail.name}` : this.currentMetricMapping.metric_detail.name;
    
  }
  
  private createMetricParentChildrenMenuHierarchy(id: number){
    const [ showParentHierarchy, showChildrenHierarchy ] = this.metricDrawerService.prepareParentAndChildrenMetricHierarchy(id, this.getMetricNameWithProduct(),this.metricHierarchy);
    this.objectHierarchy = showParentHierarchy;
    this.childMetricHierarchy = showChildrenHierarchy;
    console.log([showParentHierarchy, showChildrenHierarchy])
  }

  protected updateMenuActions(): void {
    if (!(this.currentState && this.currentState.objectId)) {
      this.menuActions = [];
      return;
    }

    this.menuActionsBuilder.reset();
    this.menuActionsBuilder
    .addShowParentAction(this.objectLabel, false)
    .addShowChildAction(this.objectLabel, false)
    .addDeleteAction(this.objectType, this.handleDelete.bind(this), this.isReadOnlyMode);

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  handleDelete(): void {
    if (!(this.currentState && this.currentState.objectId)) {
      return;
    }

    this.dialogManager.openDeleteEntityDialog(() => {
      this.utilityService.showLoading(true);
      this.metricMappingDetailsService.deleteObject(this.currentState.objectId).subscribe(
        () => {
          this.onSuccess(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, this.currentMetricState.mappingType + ' metric'), true);
          this.utilityService.showLoading(false);
          const metricParentId = this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign ? this.campaign.id : this.goal.id;
          this.metricMappingDetailsService.reportChange(this);
          this.metricUpdateService.triggerMetricUpdate({
            action: MetricUpdateAction.DELETE,
            objectId: metricParentId,
            objectType: this.currentMetricState.mappingType,
            metricMappingId: this.currentState.objectId,
            metricId: this.currentState.metricId,
            productId: this.metricMappingDetailsService.getProductByName(this.currentState.productName, this.products)?.id
          });
        },
        error => this.onError(
          error,
          messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.currentMetricState.mappingType.toLowerCase() + ' metric'),
          true
        )
      );
    }, this.objectType.toLowerCase());
  }

  protected formDataToState() {
    const formData = this.formData.getRawValue() as MetricDetailsForm;
    const state: Partial<MetricDetailsState> = {
      name: formData.name,
      startDate: createDateString(formData.startDate),
      notes: formData.notes,
      updated: createDateString(formData.updated),
      funnelName: formData.funnelName,
      isKeyMetric: formData.isKeyMetric,
      metricUnit: formData.metricUnit,
      mappingType: formData.mappingType,
      milestones: formData.milestones
    };
    const parentObject = getParentFromLocation(formData[DrawerFormFields.location]);
    state.parentObject = parentObject;
    return state;
  }
  startDateChange(event) {
    this.formData.controls['startDate'].setValue(event);
  }
  public saveChanges(onSavedCb: () => void, runInBackground?: boolean): void {
    // Handles save & go action on Discard Popup
    this.handleSaveAndCloseAction(false, onSavedCb);
  }

  protected getContextForNewObjectCreation(): any {
    console.error('Method not implemented.');
  }
  protected initHierarchy(state: ObjectDetailsCommonState): void {
    console.error('Method not implemented.');
  }
  protected checkTagsLeftover(): void {
    console.error('Method not implemented.');
  }

  protected readonly objectDetailsService = inject(GoalDetailsService);
  protected isCustomTypeEntering = false;

  protected tabsData: DrawerTabItem[] = this.tabsDataService.createTabList([
    this.ObjectDetailsTabControl.Details,
    this.ObjectDetailsTabControl.Update,
    this.ObjectDetailsTabControl.Performance
  ]);

  protected childHierarchy: HierarchySelectItem[] = [];
  protected formConfig = {
    [DrawerFormFields.name]: ['', {
      validators: [Validators.required, Validators.maxLength(this.budgetObjectDetailsManager.maxObjectNameLength)],
      updateOn: 'blur'
    }],
    [DrawerFormFields.startDate]: null,
    [DrawerFormFields.notes]: '',
    [DrawerFormFields.updated]: '',
    [DrawerFormFields.funnelName]: '',
    [DrawerFormFields.isKeyMetric]: '',
    [DrawerFormFields.metricUnit]: '',
    [DrawerFormFields.mappingType]: '',
    [DrawerFormFields.milestones]: null,
  };


  constructor(
    private readonly metricDrawerService: MetricDrawerService,
    private readonly metricsUtilsService: MetricsUtilsService,
    private readonly metricMappingDetailsService: MetricMappingDetailsService,
    private readonly metricMappingCalculationService: MetricMappingCalculationService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly BudgetObjectMetricsService: BudgetObjectMetricsService,
    private readonly campaignService: CampaignService,
    private readonly historyService: HistoryService,
  ) {
    super();
    this.setObjectType(this.configuration.OBJECT_TYPES.metric);
    this.historyService.history$.subscribe(items => {
      this.historyLogTypes = items;
    });

  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  private loadDetailsContextData() {
    return combineLatest([
      this.companyDataService.selectedCompanyDO$,
      this.budgetObjectDetailsManager.getMetricTypes().pipe(tap(mTypes => this.metrics = mTypes)),
      this.budgetObjectDetailsManager.getGoals().pipe(tap(goals => this.goals = goals)),
      this.budgetObjectDetailsManager.getLightCampaigns().pipe(tap(campaigns => this.campaigns = campaigns)),
      this.budgetObjectDetailsManager.getSegments().pipe(tap(segments => this.segments = segments)),
      this.budgetObjectDetailsManager.getSharedCostRules().pipe(tap(rules => this.sharedCostRules = rules)),
      this.budgetObjectDetailsManager.getCompanyId().pipe(tap(companyId => this.companyId = companyId)),
      this.budgetObjectDetailsManager.getCurrentBudget().pipe(tap(budget => {
        this.budget = budget;
        // this.defaultStartDate = budget.budget_from;

        this.defaultEndDate = budget.budget_to
      })),
      this.userManager.currentUser$.pipe(
        filter(user => !!user),
        tap(user => this.currentUser = user)
      )
    ]);
  }


  private buildMetricBreakDownData() {
    this.metricBreakdownData = createDeepCopy(this.metricBreakdownDataTemplate);
    const targetValue = this.metricsUtilsService.getTargetValue(this.currentMetricState.milestones) || this.currentMetricState.targetValue;

    this.metricBreakdownData.currentMetric.data = {
      objectId: this.currentMetricState.parentId,
      mappingId: this.currentMetricState.objectId,
      name: this.campaign && this.campaign.name,
      lastUpdated: this.metricDrawerService.getLastUpdatedDate(
        this.currentMetricState.metricValueUpdates,
        MetricValueUpdateType.campaign,
        this.currentMetricState.parentId
      ),
      current: this.currentMetricState.currentValue,
      target: targetValue
    };

    this.metricBreakdownData.grandTotal = {
      name: 'Grand Total',
      current: this.currentMetricState.currentValue,
      target: targetValue
    };

    const activeThirdPartyAmounts = this.currentMetricState.thirdPartyAmounts?.amounts?.filter(a => !!a.amount) || [];

    this.metricBreakdownData =
      activeThirdPartyAmounts.reduce(
        (metricBreakdownData, thirdPartyAmount) => this.addThirdPartyAmountToBreakdown(metricBreakdownData, thirdPartyAmount),
        this.metricBreakdownData
      );

    this.metricBreakdownData =
      (this.currentMetricState.childMetricMappings || []).reduce(
        (metricBreakdownData, childMetricMapping) => this.addChildCampaignMetricToBreakdown(metricBreakdownData, childMetricMapping),
        this.metricBreakdownData
      );

    this.hasActiveThirdPartyAmounts = activeThirdPartyAmounts.length > 0;
  }

  private addThirdPartyAmountToBreakdown(
    metricBreakdownData: MetricBreakdown,
    thirdPartyMetricAmount: MetricMappingThirdPartyAmount,
  ) {
    this.metricMappingDetailsService.addThirdPartyAmountToBreakdownSection(
      thirdPartyMetricAmount,
      metricBreakdownData,
      metricBreakdownData.integrationsMetric,
      this.currentMetricState.metricValueUpdates,
      this.mappedThirdPartyCampaignNumbers,
      this.integrationRowIconTemplateGetters
    );
    return metricBreakdownData;
  }


  private addChildCampaignMetricToBreakdown(metricBreakdownData: MetricBreakdown, childMetricMapping: MetricMappingDO) {
    const campaign = this.childCampaigns.find(c => c.id === childMetricMapping.map_id);
    if (campaign) {
      this.metricMappingDetailsService.addMetricToBreakdownSection(
        campaign,
        metricBreakdownData,
        metricBreakdownData.childCampaignMetrics,
        childMetricMapping,
        this.currentMetricState.metricName,
        false
      );
    }
    return metricBreakdownData;
  }

  private buildMetricBreakDownDataForGoal() {
    this.metricBreakdownData =
      (this.currentMetricState.childMetricMappings || []).reduce(
        (metricBreakdownData, childMetricMapping) =>
          this.addChildMetricMappingToBreakdownForGoal(metricBreakdownData, childMetricMapping),
        createDeepCopy(this.metricBreakdownDataTemplateForGoal)
      );
  }

  private addChildMetricMappingToBreakdownForGoal(metricBreakdownData: MetricBreakdown, childMetricMapping: MetricMappingDO) {
    const { OBJECT_TYPES } = this.configuration;
    const isCampaignMapping = childMetricMapping.mapping_type === OBJECT_TYPES.campaign;
    const regularCampaign = this.goalCampaignsMap.get(childMetricMapping.map_id);
    const childCampaign = this.goalChildCampaignsMap.get(childMetricMapping.map_id);

    if (isCampaignMapping && regularCampaign) {
      this.addChildCampaignMetricToBreakdownForGoal(metricBreakdownData, childMetricMapping, regularCampaign);
    } else if (isCampaignMapping && childCampaign) {
      this.rollupChildCampaignMetricMappingForGoal(metricBreakdownData, childMetricMapping, childCampaign);
    }

    return metricBreakdownData;
  }

  private addChildCampaignMetricToBreakdownForGoal(
    metricBreakdownData: MetricBreakdown,
    childMetricMapping: MetricMappingDO,
    campaign: CampaignDO
  ) {
    this.metricMappingDetailsService.addMetricToBreakdownSection(
      campaign,
      metricBreakdownData,
      metricBreakdownData.campaignMetrics,
      childMetricMapping,
      this.currentMetricState.metricName,
      false
    );
  }

  private rollupChildCampaignMetricMappingForGoal(
    metricBreakdownData: MetricBreakdown,
    childMetricMapping: MetricMappingDO,
    campaign: CampaignDO
  ) {
    const parentCampaignId = campaign.parent_campaign;
    const breakdownDataItem = metricBreakdownData.campaignMetrics.data.find(item => item.objectId === parentCampaignId);
    const totalValue = MetricMappingDetailsService.getTotalMappingCurrentValue(childMetricMapping, false);

    if (breakdownDataItem && totalValue > 0) {
      // We should not sum up target for rolled up objects
      const targetValue = 0;

      breakdownDataItem.current += totalValue;
      MetricMappingDetailsService.updateMetricBreakdownGrandTotal(
        metricBreakdownData,
        totalValue,
        targetValue
      );
    }
  }
  private getRefreshedMetricValueUpdates() {
    return this.metricMappingCalculationService.buildMetricValueUpdatesData(
      { objId: this.campaign.id, type: this.currentMetricState.mappingType },
      this.currentMetricState.metricCalculations,
      this.currentMetricState.childMetricMappings,
      this.isReadOnlyMode
    );
  }

  private syncMetricDrawerStatewithFormData() {
    const formDataState = this.formDataToState();
    this.currentMetricState.notes = formDataState.notes || "";
    this.currentMetricState.startDate = formDataState.startDate || null;
    this.currentMetricState.milestones = formDataState.milestones || [];
  }

  updateTargetValueInBreakdown() {
    if (this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign) {
      this.buildMetricBreakDownData();
    } else {
      this.buildMetricBreakDownDataForGoal();
    }
  }


  onSuccess(message: string, closeDrawer: boolean): void {
    this.hideLoader();
    this.utilityService.showToast({ Type: 'success', Message: message });
    if (closeDrawer) {
      this.appRoutingService.closeActiveDrawer();
    }
  }

  onError(error: Error, message: string, closeDrawer: boolean) {
    this.hideLoader();
    if (error) {
      console.error(error);
    }
    if (message && !closeDrawer) {
      this.utilityService.handleError({ message });
    }
    if (closeDrawer) {
      this.appRoutingService.closeActiveDrawer();
    }
  }

  getMetricMappingUpdatePayload() {
    this.syncMetricDrawerStatewithFormData();
    const { updatableStateProps } = this.metricMappingDetailsService;
    return this.metricMappingDetailsService.getMetricMappingDataObjectFromMetricState(
      this.metricMappingDetailsService.getStateDiff(this.prevState, this.currentMetricState, updatableStateProps)
    );

  }

  hasUnSavedMetricChanges() {
    const diff = this.getMetricMappingUpdatePayload();
    return Object.keys(diff).length > 0;
  }

  syncUnsavedMetricChanges(flag?: boolean) {
    if(!this.currentState) { return; }
    if (this.isMetricFormValid) {
      this.updateCurrentSummary();
      this.updateSummary(isError => {
        if (!isError) {
          this.actualBusinessValue = this.calcBusinessValue(this.currentState.summary.totalValue);
          this.initGraphData();
          const metricData = { startDate: this.currentMetricState.startDate, milestones: this.currentMetricState.milestones, legacyTarget: this.currentMetricMapping.projection_amount }
          this.applyPerformanceTabData(metricData)
        }
      });
              this.updateTargetValueInBreakdown();
          }
    this.unsavedMetricChangesFlag = (typeof flag === 'boolean') ? flag : this.hasUnSavedMetricChanges();
    this.disableSaveAndCloseButton = (typeof flag === 'boolean') ? flag : this.hasUnSavedMetricChanges();
  }

  syncMetricFormValidity(event) {
    this.isMetricFormValid = event;
  }

  syncIsMilestonesDataInvalid(event) {
    this.isMilestonesFormInvalid = event;
  }

  toggleLoader(event) {
    if (event) {
      this.showLoader()
    } else {
      this.hideLoader()
    }
  }

  afterSaveActions(data, closeDrawer, onSavedCb?: () => void) {
    if(onSavedCb){
      // After save & go open the parent drawer if it was triggered in navigation flow
      this.onSuccess(messages.SAVE_CHANGES_SUCCESS_MSG, closeDrawer);
      return onSavedCb();
    }

    this.currentMetricMapping = data;
    let newMetricValueUpdates = [];
    if (this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign) {
      newMetricValueUpdates = JSON.parse(JSON.stringify(this.getRefreshedMetricValueUpdates()));
    }
    const currentChildMetricMappings = this.currentMetricState.childMetricMappings;
    const milestones = this.currentMetricState.milestones;
    this.currentMetricState = this.metricDrawerService.createMetricMappingDetailsState(data, false);
    this.currentMetricState.childMetricMappings = currentChildMetricMappings; // retain children metric mapping from prev metric state
    if(Object.keys(data.milestones).length === 0) { 
      this.currentMetricState.milestones = milestones; // retain milestones from prev metric state
    }    
    this.currentState = this.currentMetricState as ObjectDetailsCommonState;
    if (!this.currentState.startDate) {
      this.currentState.startDate = this.metricMappingDetailsService.getDefaultStartDate(this.budget?.budget_from, this.campaign?.start_date)
    }
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentMetricState) as ObjectDetailsCommonState;
    this.applyMetricType(this.currentState).subscribe(() => { });
    this.setFormData();
    this.updateDetailsTabData();
    this.prevState.metricCalculations = JSON.parse(JSON.stringify(this.currentState.metricCalculations))
    this.syncUnsavedMetricChanges(false);
    this.syncUnsavedChangesFlag(false);
    this.syncMetricFormValidity(false);
    if(this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.campaign) {  
      this.currentMetricState.metricValueUpdates = newMetricValueUpdates;
    }
    this.updateTargetValueInBreakdown();
    this.onSuccess(messages.SAVE_CHANGES_SUCCESS_MSG, closeDrawer)
  }

  syncFormDataValuesWithState(milestonesFormData) {
    const metricFormDataValues = milestonesFormData.map(form => {
      const values = form.getRawValue();
      const obj = {};
      obj['targetValue'] = values['amount'];
      obj['date'] = values['date'];
      return obj;
    });
    metricFormDataValues.forEach((item, index) => {
      this.currentMetricState.milestones.splice(index, 1, item);
      this.currentState.milestones.splice(index, 1, item);
    })
    this.syncUnsavedMetricChanges();
  }

  // handles both save and save-close action
  handleSaveAndCloseAction(closeDrawer = true, onSavedCb?: () => void){
    if(!this.disableSaveAndCloseButton) {
      return;
    }
    const metricMappingPayload = this.getMetricMappingUpdatePayload();
    // Fixes the issue of empty string as date in metric milestone data
    const milestonesPayload = metricMappingPayload.milestones;
    if(milestonesPayload && Object.keys(milestonesPayload).some(date => date === '')) {
      metricMappingPayload.milestones = {};
    }

    const allstate = this.metricMappingDetailsService.getMetricMappingDataObjectFromMetricState(this.currentMetricState)
    if (Object.keys(metricMappingPayload).length === 0) {
      return closeDrawer ? this.appRoutingService.closeActiveDrawer() : null;
    }
    this.showLoader();

    this.metricMappingDetailsService.saveDetails(this.prevState, this.currentState).pipe(
      switchMap(createdMetricMapping =>
        this.metricMappingDetailsService.saveMetricCalculations(
          this.prevState ? this.prevState.metricCalculations : createdMetricMapping.metric_calculations,
          this.currentState?.metricCalculations
        )
      ),
      switchMap(() =>
        this.metricMappingDetailsService.getMetricProgressTowardsTarget(this.currentState.objectId)
          .pipe(tap(updates => this.metricProgressTowardsTarget = updates))
      )
    ).subscribe(metricCalculationsData => {
      this.metricDrawerService.updateMetricDetails(this.currentMetricState.objectId, metricMappingPayload).subscribe(
        {
          next: (data) => {
            this.afterSaveActions(data, closeDrawer, onSavedCb);
          },

          error: (err: Error) => {
            this.onError(
              err,
              messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.currentMetricState.mappingType.toLowerCase() + ' metric'),
              closeDrawer
            )
          }
        }
      )
    })
  }


  getContextForChildObjectCreation(): DetailsCreationContext {
    return null;
  }
}
