import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { HistoryItem } from 'app/shared/services/history.service';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface HistoryWidgetTableItem {
  name: string;
  objectId: number;
  type: string;
  metricOrigin?: {
    name: string;
    type: string;
  };
  createdDate: Date;
  updatedDate: Date;
}

export const IconByHistoryObjectType = {
  [HistoryObjectLogTypeNames.campaign]: ['far', 'rocket-launch'] as IconProp,
  [HistoryObjectLogTypeNames.program]: ['fas', 'briefcase'] as IconProp,
  [HistoryObjectLogTypeNames.expense]: ['fas', 'coins'] as IconProp,
  [HistoryObjectLogTypeNames.goal]: ['fas', 'bullseye-arrow'] as IconProp,
  [HistoryObjectLogTypeNames.metricMapping]: ['fas', 'chart-line-up'] as IconProp
};

@Component({
  selector: 'history-widget-table',
  styleUrls: ['./history-widget-table.component.scss'],
  templateUrl: './history-widget-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryWidgetTableComponent implements OnChanges, OnDestroy {
  @Input() data: HistoryItem[] = [];
  @Input() updatedDates = new Map<string, Date>();

  private readonly destroy$ = new Subject<void>();

  public isBodyScrolled = false;
  public VISIBLE_ROWS_LIMIT = 9;
  public tableData: HistoryWidgetTableItem[] = [];
  public iconByObjectType = IconByHistoryObjectType;
  private routeActionByObjectType = {
    [HistoryObjectLogTypeNames.campaign]: (id: number) => this.appRoutingService.openCampaignDetails(id),
    [HistoryObjectLogTypeNames.program]: (id: number) => this.appRoutingService.openProgramDetails(id),
    [HistoryObjectLogTypeNames.expense]: (id: number) => this.appRoutingService.openExpenseDetails(id),
    [HistoryObjectLogTypeNames.goal]: (id: number) => this.appRoutingService.openGoalDetails(id),
  };
  private routeActionByMetricType = {
    [HistoryObjectLogTypeNames.campaign]: (id: number) => this.appRoutingService.openCampaignMetricDetails(id),
    [HistoryObjectLogTypeNames.program]: (id: number) => this.appRoutingService.openProgramMetricDetails(id),
    [HistoryObjectLogTypeNames.goal]: (id: number) => this.appRoutingService.openGoalMetricDetails(id),
  };

  public date = new Date();

  constructor(private readonly appRoutingService: AppRoutingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.prepareTableData();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private prepareTableData() {
    this.tableData = this.data
      .filter(item => item.object != null)
      .map(item => {
        const type = item.object.type;
        const tableItem: HistoryWidgetTableItem = {
          name: item.object.name,
          objectId: item.object.id,
          createdDate: new Date(item.createdDate),
          updatedDate: null,
          type,
        };

        if (type === HistoryObjectLogTypeNames.metricMapping && item.parent) {
          tableItem.metricOrigin = { ...item.parent };
        }

        return tableItem;
      });
  }

  public trackFn(index, item: HistoryWidgetTableItem) {
    return item.objectId;
  }

  public handleBodyScrolledChange($event: boolean) {
    this.isBodyScrolled = $event;
  }

  public openDetails(item: HistoryWidgetTableItem) {
    const action = item.metricOrigin ? this.routeActionByMetricType[item.metricOrigin.type] : this.routeActionByObjectType[item.type];
    if (action) {
      action(item.objectId);
    }
  }
}
