<div class="invoice-review-container"
  [@fadeIn]="isOverlayShown ? 'open' : 'close'"
  (@fadeIn.done)="onFadeInEnd($event)"
>
  <div class="invoice-review-wrapper">
    <invoice-details [@slideLeftPanel]="isPanelsShown"></invoice-details>
    <expense-details
      [@slideRightPanel]="isPanelsShown"
      [objectId]="invoiceId"
      [isInvoiceDrawer]="true"
      [isActive]="true"
      (dataLoaded)="handleIsDataLoaded($event)"
    ></expense-details>
    <invoice-review-actions-panel [@slideBottomPanel]="isPanelsShown"></invoice-review-actions-panel>
  </div>
</div>

