<header class="header">
  <search-control
    [disabled]="!options?.length"
    placeholder="Search metrics"
    (textChanged)="filterItemsByText($event)"
  ></search-control>

  <select-option
    *ngIf="options?.length && !singleSelectMode"
    class="select-all"
    [title]="'All metrics'"
    [isGroupOption]="hasGroups"
    [isCollapsed]="groupsCollapseState.allCollapsed"
    [isChecked]="allSelectionState === CheckboxValue.Active"
    [indeterminate]="allSelectionState === CheckboxValue.Indeterminate"
    (selectionChanged)="toggleAllSelection($event)"
    (toggleGroupCollapse)="toggleAllGroupsCollapse(!$event, options)"
  ></select-option>

</header>

<div *ngIf="options?.length; else noOptions"
     class="filter-select"
     [ngClass]="{ 'group-mode': hasGroups }">
  <ng-container *ngFor="let option of options">

    <!--  product -->
    <ng-container *ngIf="option.children?.length">

      <select-option
        *ngIf="option.children?.length"
        class="select-group"
        [isHidden]="option.hidden"
        [title]="option.title"
        [isGroupOption]="true"
        [highlightedOption]="true"
        [singleSelectMode]="singleSelectMode"
        [isCollapsed]="groupsCollapseState.collapsed[option.value]"
        [isChecked]="isSelected(option.value)"
        [indeterminate]="selectionState[option.value] === CheckboxValue.Indeterminate"
        (selectionChanged)="toggleGroupSelection($event, option)"
        (toggleGroupCollapse)="toggleGroupCollapse($event, option.value)"
      ></select-option>

      <select-option
        *ngFor="let childOption of option.children"
        class="group-option"
        [ngClass]="{ 'active': childOption.value === selectedOptionId }"
        [isHidden]="childOption.hidden || !groupsCollapseState.collapsed[option.value]"
        [prefix]="option.title"
        [title]="childOption.title"
        [singleSelectMode]="singleSelectMode"
        [isChecked]="isSelected(childOption.value)"
        (selectionChanged)="toggleItemSelection($event, childOption, option)"
      ></select-option>

    </ng-container>

    <!--  standalone -->
    <select-option
      *ngIf="!option.children?.length"
      [ngClass]="{ 'active': option.value === selectedOptionId }"
      [isHidden]="option.hidden"
      [title]="option.title"
      [isChecked]="isSelected(option.value)"
      [singleSelectMode]="singleSelectMode"
      (selectionChanged)="toggleItemSelection($event, option)"
    ></select-option>

  </ng-container>
</div>

<footer class="footer" *ngIf="!singleSelectMode">
  <button mat-button
          color="primary"
          (click)="onCancel()">
    Cancel
  </button>
  <button mat-flat-button
          color="primary"
          class="ml-1"
          [disabled]="allSelectionState === CheckboxValue.NotActive"
          (click)="onConfirm()">
    Add
  </button>
</footer>

<ng-template #noOptions>
  <p class="no-items-msg">
    <fa-duotone-icon slot="icon" [fixedWidth]="true" [icon]="['fad', 'chart-line']"></fa-duotone-icon>
    No Metrics defined. <ng-container *ngIf="!isPowerUser">Ask your admin to set them up.</ng-container>
    <a *ngIf="isPowerUser" (click)="navigateToFunnels()">Go to set them up.</a>
  </p>
</ng-template>
