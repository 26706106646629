import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionMenuItem } from 'app/shared/components/actions-menu/actions-menu.component';

@Component({
  selector: 'widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss']
})
export class WidgetContainerComponent {
  @Input() actionItems: ActionMenuItem[];
  @Input() allowSwitchViews = false;
  @Input() tableView = false;
  @Output() isTableViewChanged = new EventEmitter<boolean>();

  changeTableView(newViewState) {
    if (this.tableView === newViewState) {
      return;
    }
    this.isTableViewChanged.emit(newViewState);
  }

  hasActionItems() {
    return this.actionItems != null && this.actionItems.length > 0;
  }

  get currentViewActionItems () {
    if (this.hasActionItems()) {
      return this.actionItems.filter(ai => this.tableView && ai.forTableView || !this.tableView && ai.forChartView);
    } else {
      return [];
    }
  }
}
