import { Component, Inject, OnInit } from '@angular/core';
import { FilterDialogConfig, FilterDialogContext, FilterDialogState, FilterSetData } from '../filters.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'save-filter-modal',
  templateUrl: './save-filter-modal.component.html',
  styleUrls: ['./save-filter-modal.component.scss']
})
export class SaveFilterModalComponent implements OnInit {
  private savedFilters: FilterSetData[] = [];

  public dialogState = FilterDialogState;
  public activeState: FilterDialogState;
  public selectedFilterId: number = null;
  public favouriteFilter: FilterSetData;
  public isFilterOwner = false;
  public readonly applyCheckboxHintMessage = 'Only 1 filter may be applied.';
  public editFilterForm = new FormGroup({
    id: new FormControl<number | null>(null),
    name: new FormControl<string | null>('', { validators: Validators.required }),
    isPublic: new FormControl<boolean>(false),
    isFavourite: new FormControl<boolean>(false)
  })

  constructor(
    private readonly dialogRef: MatDialogRef<SaveFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilterDialogContext
  ) {}

  ngOnInit(): void {
    this.activeState = this.data.state;
    this.selectedFilterId = this.data.context.selectedFilterId;
    this.favouriteFilter = this.data.context.favouriteFilter;
    this.savedFilters = this.data.context.savedFilters || [];
  }

  onSubmit(event: MouseEvent): void {
    event.stopPropagation();
    const editedFilterId = this.editFilterForm.value.id;
    const editedFilterOwnerId = this.savedFilters.find(filter => filter.id === editedFilterId)?.ownerId;
    const isFilterOwner = this.data.context.owner.id === editedFilterOwnerId;
    const ownerId = isFilterOwner || this.activeState === FilterDialogState.Save ? this.data.context.owner.id : editedFilterOwnerId;

    let filterData: FilterSetData = {
      id: null,
      budgetId: this.data.context.budgetData.id,
      name: this.editFilterForm.value.name,
      ownerId,
      isPublic: this.editFilterForm.value.isPublic,
      isFavourite: this.editFilterForm.value.isFavourite,
      selectedFilters: {}
    }
    let resetFavouriteFilterId = null;
    if (this.favouriteFilter && (filterData.isFavourite && filterData.id !== this.favouriteFilter?.id)) {
      resetFavouriteFilterId = this.favouriteFilter.id;
    }

    if (this.activeState === FilterDialogState.Edit) {
      const selectedFilters = this.savedFilters.filter(item => item.id === this.selectedFilterId)[0]?.selectedFilters;
      filterData = { ...filterData, selectedFilters: selectedFilters, id: this.selectedFilterId };
      this.data.context.onUpdateFilterSet(filterData, resetFavouriteFilterId);
    } else {
      this.data.submitAction.handler(filterData, resetFavouriteFilterId);
    }
    this.dialogRef.close();
  }

  editFilter(filterData: FilterSetData): void {
    const isPublicFormControl =  this.editFilterForm.controls['isPublic'];
    const nameFormControl = this.editFilterForm.controls['name'];
    this.isFilterOwner = this.data.context.owner.id === filterData.ownerId;
    this.activeState = FilterDialogState.Edit;
    this.data = { ...FilterDialogConfig[FilterDialogState.Edit], context: this.data.context };
    this.selectedFilterId = filterData.id;
    this.editFilterForm.setValue({
      id: filterData.id,
      name: filterData.name,
      isPublic: filterData.isPublic,
      isFavourite: filterData.isFavourite
    });
    if (this.isFilterOwner) {
      isPublicFormControl.enable();
      nameFormControl.enable();
    } else {
      isPublicFormControl.disable();
      nameFormControl.disable();
    }
  }

  removeFilterSet(): void {
    const filterId = this.editFilterForm.get('id').value;
    this.data.context.onRemove(filterId);
    this.savedFilters = this.savedFilters.filter(el => el.id !== filterId);
  }

  applySelectedFilterSet(): void {
    if (this.selectedFilterId !== this.data.context.selectedFilterId) {
      this.data.context.onSelectActive(this.selectedFilterId);
    }
    this.dialogRef.close();
  }
}
