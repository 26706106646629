import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { Configuration, ObjectsIconConfig } from 'app/app.constants';

@Component({
  selector: 'icon-by-object-type',
  templateUrl: './icon-by-object-type.component.html',
  styleUrls: ['./icon-by-object-type.component.scss']
})
export class IconByObjectTypeComponent {
  @HostBinding('class.closed')
  @Input() isClosed = false;
  @Input() objectType;
  @Input() flip = false;
  public OBJECT_TYPES = this.configuration.OBJECT_TYPES;
  public iconsConfig: ObjectsIconConfig = this.configuration.iconsConfig;

  @Input() set size(value: number) {
    this.elementRef.nativeElement.style.setProperty('--icon-size', value + 'px');
  };

  constructor(
    private readonly configuration: Configuration,
    private elementRef: ElementRef
  ) {}
}
