<external-metric-types-mapping-table
  *ngIf="isExternalServiceAvailable"
  [rows]="externalMetricTypes"
  [isPowerUser]="isPowerUser"
  [plannuhMetricTypes]="plannuhMetrics"
  [products]="products"
  [settings]="mappingTableSettings"
  [disabled]="disabled || loading"
  (changeMapping)="handleMetricMappingChange($event)"
  (goToFunnelsPage)="goToFunnelsPage()">
</external-metric-types-mapping-table>

