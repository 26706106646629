<confirmation-dialog>
  <div class="content">
    <p>If you proceed then you will disable all Single Sign On users, and they will lose all access to Planful.</p>
    <fa-duotone-icon [icon]="['fad', 'skull']"></fa-duotone-icon>
    <div class="checkbox-container">
      <mat-checkbox color="primary" [disableRipple]="true" (change)="handleAgreementToggle($event)">
        I take responsibility for removing access from all SSO users,
        understanding that this action cannot be undone.
      </mat-checkbox>
    </div>
  </div>
</confirmation-dialog>
