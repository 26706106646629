import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'app/shared/shared.module';
import { BudgetSettingsPageComponent } from './components/budget-settings-page/budget-settings-page.component';
import { BudgetOwnerComponent } from './components/budget-owner/budget-owner.component';
import { BudgetNameComponent } from './components/budget-name/budget-name.component';
import { BudgetSettingsSummaryBarComponent } from './components/budget-settings-summary-bar/budget-settings-summary-bar.component';
import { BudgetSettingsSummaryChartComponent } from './components/budget-settings-summary-chart/budget-settings-summary-chart.component';
import { SaveBudgetButtonComponent } from './components/save-budget-button/save-budget-button.component';
import { PlanBudgetCueComponent } from './components/plan-budget-cue/plan-budget-cue.component';
import { BudgetTableComponent } from './components/budget-table/budget-table.component';
import { BudgetTableControlsComponent } from './components/budget-table-controls/budget-table-controls.component';
import { TimeframeLockButtonComponent } from './components/timeframe-lock-button/timeframe-lock-button.component';
import { IncreaseBudgetAmountDialogComponent } from './components/increase-budget-amount-dialog/increase-budget-amount-dialog.component';
import { BudgetEditModalComponent } from './components/budget-edit-modal/budget-edit-modal.component';
import { BudgetSegmentNameComponent } from './components/budget-segment-name/budget-segment-name.component';
import { BudgetManageMenuComponent } from './components/budget-manage-menu/budget-manage-menu.component';
import { BudgetSettingsDatesRangeComponent } from './components/budget-settings-dates-range/budget-settings-dates-range.component';
import { BudgetAllocationModule } from '../budget-allocation/budget-allocation.module';
import { BudgetBaselineModalComponent } from './components/budget-baseline-modal/budget-baseline-modal.component';
import { BudgetDataDuplicateSpecificationDialogComponent } from './components/budget-data-duplicate-specification-dialog/budget-data-duplicate-specification-dialog.component';


@NgModule({
  declarations: [
    BudgetSettingsPageComponent,
    BudgetOwnerComponent,
    BudgetNameComponent,
    BudgetSettingsSummaryBarComponent,
    BudgetSettingsSummaryChartComponent,
    SaveBudgetButtonComponent,
    PlanBudgetCueComponent,
    BudgetTableComponent,
    BudgetTableControlsComponent,
    TimeframeLockButtonComponent,
    IncreaseBudgetAmountDialogComponent,
    BudgetSegmentNameComponent,
    BudgetEditModalComponent,
    BudgetManageMenuComponent,
    BudgetSettingsDatesRangeComponent,
    BudgetBaselineModalComponent,
    BudgetDataDuplicateSpecificationDialogComponent,
  ],
  exports: [
    TimeframeLockButtonComponent,
    BudgetNameComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    MatButtonModule,
    MatRippleModule,
    MatMenuModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatDatepickerModule,
    DragDropModule,
    BudgetAllocationModule,
  ]
})
export class BudgetSettingsModule { }
