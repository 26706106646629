import { Stack, StackParams } from '../types/stack.interface';

export class ArrayStack<T> implements Stack<T> {
  private params: StackParams;
  private storage: T[] = [];

  constructor(params: StackParams = { capacity: 10 }) {
    this.params = params;
  }

  push(item: T): void {
    if (this.size() === this.params.capacity) {
      if (this.params.overwriteOnLimit) {
        this.storage.shift();
      } else {
        throw new Error('Stack has reached max capacity, you cannot add more items');
      }
    }
    this.storage.push(item);
  }

  pop(): T {
    return this.storage.pop();
  }

  peek(): T {
    return this.storage[this.size() - 1];
  }

  size(): number {
    return this.storage.length;
  }
}
