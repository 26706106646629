import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, interval, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BudgetObjectDialogService } from '@shared/services/budget-object-dialog.service';
import { InvoiceUploadService, TempLink, UploadStatusResponse } from './invoice-upload.service';
import { UtilityService } from '@shared/services/utility.service';

export interface InvoiceUploadContext {
  userId: number;
  companyId: number;
  budgetId: number;
}

export interface InvoiceFile {
  trackId: string;
  file: File;
  errorMessage?: string;
}

export enum UploadStatus {
  UPLOADING = 'UPLOADING',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
  ABORTED = 'ABORTED'
}

export const ERROR_STATUSES = [UploadStatus.ABORTED, UploadStatus.FAILED, UploadStatus.TIMED_OUT];
export const ACCEPTED_EXTENSIONS = ['.pdf', '.png', '.jpg', '.jpeg', '.tiff', '.tif'];
export const ERROR_MESSAGES = {
  GENERIC_ERROR: 'Oops. Something went wrong.',
  UNSUPPORTED_FORMAT: 'Unsupported file format.',
  UNSUPPORTED_FORMAT_PARTIALLY: 'Some of the files rejected. Unsupported file format.',
  UPLOAD_SUCCESS: 'Files uploaded successfully'
};

const GET_UPLOAD_STATUS_INTERVAL_MS = 2000;

@Injectable({
  providedIn: 'root'
})
export class InvoiceUploadManagerService {
  private readonly watchedFiles = new BehaviorSubject<InvoiceFile[]>([]);
  private readonly uploadStatusMap = new BehaviorSubject<Record<string, UploadStatus>>({});
  private readonly stopUploadTracking = new Subject<void>();
  private readonly updatePageData = new Subject<void>();
  private readonly isUploadInProgress = new BehaviorSubject<boolean>(false);
  private readonly uploadingFilesNumber = new BehaviorSubject<number>(0);
  private readonly failedFilesNumber = new BehaviorSubject<number>(0);

  public readonly watchedFiles$ = this.watchedFiles.asObservable();
  public readonly uploadStatusMap$ = this.uploadStatusMap.asObservable();
  public readonly updatePageData$ = this.updatePageData.asObservable();
  public readonly isUploadInProgress$ = this.isUploadInProgress.asObservable();
  public readonly uploadingFilesNumber$ = this.uploadingFilesNumber.asObservable();
  public readonly failedFilesNumber$ = this.failedFilesNumber.asObservable();

  private context: InvoiceUploadContext = {
    userId: null,
    companyId: null,
    budgetId: null
  };

  constructor(
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly invoiceUploadService: InvoiceUploadService,
    private readonly utilityService: UtilityService
  ) { }

  set invoiceUploadContext(context: InvoiceUploadContext) {
    this.context = context;
  }

  get statusMapValue(): Record<string, UploadStatus> {
    return this.uploadStatusMap.getValue();
  }

  get watchedFilesValue(): InvoiceFile[] {
    return this.watchedFiles.getValue();
  }

  uploadFiles(files: File[], shouldReset?: boolean): Observable<File[]> {
    if (shouldReset) {
      this.resetState(false);
    }
    this.isUploadInProgress.next(true);

    return this.invoiceUploadService.getTemporaryLinks(files, this.context).pipe(
      switchMap(linkObjects => this.putFilesToS3(linkObjects, files)),
      tap(() => this.startTracking())
    );
  }

  retryProcessing(invoiceFile: InvoiceFile): void {
    const statusMap = this.statusMapValue;
    statusMap[invoiceFile.trackId] = UploadStatus.UPLOADING;
    this.uploadStatusMap.next(statusMap);

    this.invoiceUploadService.getTemporaryLinks([invoiceFile.file], this.context).pipe(
      switchMap(linkObjects => this.retryPutToS3(linkObjects[0], invoiceFile)),
      tap(() => {
        if (!this.isUploadInProgress.getValue()) {
          this.startTracking();
        }
      })
    ).subscribe({ error: () => this.stopTracking() });
  }

  canLeavePage(): Observable<boolean> {
    const hasActiveErrors = this.failedFilesNumber.getValue() > 0;

    if (hasActiveErrors) {
      const canLeave$ = new Subject<boolean>();
      this.dialogManager.openConfirmationDialog({
        noTitle: true,
        content: 'If you leave the page, all upload errors will be cleared',
        submitAction: {
          handler: () => {
            this.resetState();
            canLeave$.next(true);
            canLeave$.complete();
          }
        },
        cancelAction: {
          handler: () => canLeave$.next(false)
        }
      }, { width: '480px' });
      return canLeave$.asObservable();
    } else {
      return of(true);
    }
  }

  startTracking(): void {
    interval(GET_UPLOAD_STATUS_INTERVAL_MS)
      .pipe(
        switchMap(() => {
          const trackIds = Object.keys(this.statusMapValue).filter(trackId =>
            this.statusMapValue[trackId] === UploadStatus.UPLOADING || this.statusMapValue[trackId] === UploadStatus.RUNNING
          );
          if (trackIds.length) {
            return this.invoiceUploadService.getStatus(trackIds);
          } else {
            return of([]);
          }
        }),
        takeUntil(this.stopUploadTracking),
        catchError(error => {
          this.resetState();
          throw error;
        })
      ).subscribe(statuses => this.applyStatuses(statuses));
  }

  stopTracking(): void {
    this.stopUploadTracking.next();
  }

  removeFile(trackId: string): void {
    const watchedFiles = this.watchedFilesValue;
    const index = watchedFiles.findIndex(file => file.trackId === trackId);
    if (index > -1) {
      watchedFiles.splice(index, 1);
      this.updateFailedFilesNumber(watchedFiles);
      this.watchedFiles.next(watchedFiles);
    }
  }

  resetState(stopTracking = true): void {
    if (stopTracking) {
      this.stopTracking();
    }

    this.isUploadInProgress.next(false);
    this.failedFilesNumber.next(0);
    this.uploadingFilesNumber.next(0);
    this.watchedFiles.next([]);
    this.uploadStatusMap.next({});
  }

  private putFilesToS3(linkObjects: TempLink[], files: File[]): Observable<File[]> {
    if (!linkObjects.length) {
      this.resetState();
      return throwError(() => new Error(ERROR_MESSAGES.GENERIC_ERROR));
    }

    const requests = [];
    const watchedFiles = this.watchedFilesValue;
    const statusMap = this.statusMapValue;
    for (const [i, file] of files.entries()) {
      watchedFiles.push({ trackId: linkObjects[i].track_id, file });
      statusMap[linkObjects[i].track_id] = UploadStatus.UPLOADING;
      requests.push(this.invoiceUploadService.putFileToS3(file, linkObjects[i].url, linkObjects[i].track_id));
    }
    this.uploadingFilesNumber.next(watchedFiles.length);
    this.watchedFiles.next(watchedFiles);
    this.uploadStatusMap.next(statusMap);

    return forkJoin(requests).pipe(
      catchError(error => {
        this.resetState();
        throw error;
      })
    );
  }

  private retryPutToS3(linkObject: TempLink, invoiceFile: InvoiceFile): Observable<File> {
    const watchedFiles = this.watchedFilesValue;
    const fileIndex = watchedFiles.findIndex(file => file.trackId === invoiceFile.trackId);
    watchedFiles.splice(fileIndex, 1, { trackId: linkObject.track_id, file: invoiceFile.file });

    const statusMap = this.statusMapValue;
    delete statusMap[invoiceFile.trackId];
    statusMap[linkObject.track_id] = UploadStatus.UPLOADING;

    this.updateUploadingFilesNumber(watchedFiles);
    this.watchedFiles.next(watchedFiles);
    this.uploadStatusMap.next(statusMap);

    return this.invoiceUploadService.putFileToS3(invoiceFile.file, linkObject.url, linkObject.track_id);
  }

  private applyStatuses(statuses: UploadStatusResponse[]): void {
    const currentStatus = this.statusMapValue;
    const watchedFiles = this.watchedFilesValue;
    statuses.forEach(status => {
      currentStatus[status.key] = status.status;
      if (status.error) {
        watchedFiles.find(file => file.trackId === status.key).errorMessage = status.error;
      }
    });
    this.uploadStatusMap.next(currentStatus);

    this.updateUploadingFilesNumber(watchedFiles);
    this.updateFailedFilesNumber(watchedFiles);

    if (this.uploadingFilesNumber.getValue() === 0) {
      this.stopTracking();
      this.isUploadInProgress.next(false);
      this.updatePageData.next();
      this.showUploadResultMessage();
    }

    this.watchedFiles.next(watchedFiles);
  }

  private showUploadResultMessage(): void {
    const failedNumber = this.failedFilesNumber.getValue();
    if (failedNumber === 0) {
      this.utilityService.showToast({ Message: ERROR_MESSAGES.UPLOAD_SUCCESS });
    }
  }

  private updateUploadingFilesNumber(watchedFiles: InvoiceFile[]): void {
    const uploadingNumber = watchedFiles.filter(file =>
      this.statusMapValue[file.trackId] === UploadStatus.RUNNING || this.statusMapValue[file.trackId] === UploadStatus.UPLOADING
    ).length;
    this.uploadingFilesNumber.next(uploadingNumber);
  }

  private updateFailedFilesNumber(watchedFiles: InvoiceFile[]): void {
    const failedNumber = watchedFiles.filter(file => ERROR_STATUSES.includes(this.statusMapValue[file.trackId])).length;
    this.failedFilesNumber.next(failedNumber);
  }
}
