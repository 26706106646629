import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BudgetStatus } from 'app/shared/types/budget-status.type';
import { BudgetStatusLabel } from 'app/shared/constants/budget-status.constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


@Component({
  selector: 'save-budget-button',
  templateUrl: './save-budget-button.component.html',
  styleUrls: ['./save-budget-button.component.scss']
})
export class SaveBudgetButtonComponent implements OnChanges {
  @Input() status: BudgetStatus;
  @Input() disabled = true;
  @Input() userIsBudgetOwner = false;
  @Output() onStatusChanged = new EventEmitter<BudgetStatus>();
  @Output() onSave = new EventEmitter();

  public selectedStatus = BudgetStatus.Draft;
  public defaultStatuses = [
    BudgetStatus.Draft,
    BudgetStatus.Live,
    BudgetStatus.Reference,
    BudgetStatus.Hidden,
    BudgetStatus.Test
  ];
  public iconConfigByStatus = {
    [BudgetStatus.Draft]: ['fad', 'drafting-compass'] as IconProp,
    [BudgetStatus.Live]: ['fad', 'signal-stream'] as IconProp,
    [BudgetStatus.Test]: ['fad', 'vial'] as IconProp,
    [BudgetStatus.Hidden]: ['fad', 'lock-alt'] as IconProp,
    [BudgetStatus.Reference]: ['fad', 'eye'] as IconProp,
  };
  public statusLabel = BudgetStatusLabel;
  public statuses = [ ...this.defaultStatuses ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status && this.status) {
      this.selectedStatus = this.status;
    }
    if (changes.userIsBudgetOwner) {
      this.refreshStatusesList();
    }
  }

  private refreshStatusesList() {
    this.statuses = this.defaultStatuses.filter(
      status => status !== BudgetStatus.Hidden || this.userIsBudgetOwner
    )
  }

  public selectStatus(status: BudgetStatus) {
    if (status === this.selectedStatus) {
      return;
    }
    this.onStatusChanged.emit(status);
  }

  public save() {
    this.onSave.emit(this.selectedStatus);
  }
}
