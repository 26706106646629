import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'icon-add-objects',
  templateUrl: './icon-add-objects.component.html',
  styleUrls: ['./icon-add-objects.component.scss']
})

/**
* @class - IconAddObjectsComponent
*/
export class IconAddObjectsComponent {
  @Input() isDisabled = false;
  @Input() textTooltip = '';
  @Input() tooltipPosition: 'left' | 'right' | 'above' | 'below' = 'above';

  @Output() click: EventEmitter<void> = new EventEmitter<void>();

  handleClick() {
    this.click.next();
  }
}
