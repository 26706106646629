import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { Observable } from 'rxjs';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { BudgetDataService } from './budget-data/budget-data.service';
import { UserManager } from 'app/user/services/user-manager.service';

export interface BudgetTimeframeData {
  data: BudgetTimeframeDataItem[];
  message: string;
  status: string;
}

export interface BudgetTimeframeDataItem {
  remaining_allocated: number;
  budget_remaining_amount: number;
  remaining_child_allocated: number;
  closed: number;
  committed: number;
  company_budget_alloc_id: number;
  company_budget_alloc_name: string;
  locked: boolean;
  overdue: number;
  planned: number;
  total: number;
  under_budget: number;
  spent?: number;
}

@Injectable()
export class WidgetService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly userManager = inject(UserManager);

  getBudgetHierarchyGraphData(data) {
    return this.http.get(this.apiV2Url + 'graph_hierarchy/', getRequestOptions(data));
  }

  getBudgetHierarchyCsvData(data) {
    const options = getRequestOptions(data);
    options['responseType'] = 'text';
    return this.http.get(this.apiV2Url + 'graph_hierarchy_csv/', options);
  }

  getTimeframeGraphDetails(data): Observable<BudgetTimeframeData> {
    
    const userInfo = this.userManager.getCurrentUser();
    let companyId = null;
    if(userInfo) {
      companyId = this.budgetDataService.selectedBudgetStorage.getSelectedCompany(userInfo.id);
    }

    let graphTimelineFiltersPayload = { ...data, company: companyId };

    if(data['custom_fields'] && !Object.keys(data['custom_fields']).length) {
      delete graphTimelineFiltersPayload['custom_fields']
    }

    return this.http.post<BudgetTimeframeData>(this.apiV2Url + 'graph_timeline/dashboard/', graphTimelineFiltersPayload);
  }

  getTimeframeCsvData(data) {
    const options = getRequestOptions(data);
    options['responseType'] = 'text';
    return this.http.get(this.apiV2Url + 'graph_timeline_csv/', options);
  }

  getSpendByTypeGraphDetails(data) {

    const userInfo = this.userManager.getCurrentUser();
    let companyId = null;
    if(userInfo) {
      companyId = this.budgetDataService.selectedBudgetStorage.getSelectedCompany(userInfo.id);
    }

    let graphTypeFiltersPayload = { ...data, company: companyId };

    if(data['custom_fields'] && !Object.keys(data['custom_fields']).length) {
      delete graphTypeFiltersPayload['custom_fields']
    }

    return this.http.post(this.apiV2Url + 'graph_type/dashboard/', graphTypeFiltersPayload);
  }

  getSpendByTypeCsvData(data) {
    const options = getRequestOptions(data);
    options['responseType'] = 'text';
    return this.http.get(this.apiV2Url + 'graph_type_csv/', options);
  }
}
