import { Component } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-fa-arrow-right',
  templateUrl: './icon-fa-arrow-right.component.html'
})
export class IconFaArrowRightComponent extends IconBaseComponent {
  constructor() {
    super();
  }
}
