<ng-container *ngIf="salesforceHandler && hubSpotHandler">
  <button mat-button [matMenuTriggerFor]="menu" class="trigger-button">
    <fa-duotone-icon class="plug-icon" [icon]="['fad', 'plug']" [rotate]="90"></fa-duotone-icon>
    <span class="button-text">Manage Integration Sources</span>
    <fa-icon class="chevron-icon" [icon]="['fas', 'chevron-down']"></fa-icon>
  </button>
  <mat-menu #menu class="mat-pl-menu integration-mapping-menu" xPosition="before">
    <button mat-menu-item (click)="hubSpotHandler()">
      <fa-icon class="menu-item-icon" [icon]="['fab', 'hubspot']"></fa-icon>
      <span>HubSpot</span>
    </button>
    <button mat-menu-item (click)="salesforceHandler()">
      <icon-salesforce class="menu-item-icon"></icon-salesforce>
      <span>Salesforce</span>
    </button>
  </mat-menu>
</ng-container>
<button *ngIf="!salesforceHandler && hubSpotHandler" mat-button class="trigger-button" (click)="hubSpotHandler()">
  <fa-icon [icon]="['fab', 'hubspot']"></fa-icon>
  <span class="button-text">Manage HubSpot Sources</span>
</button>
<button *ngIf="salesforceHandler && !hubSpotHandler" mat-button class="trigger-button" (click)="salesforceHandler()">
  <icon-salesforce></icon-salesforce>
  <span class="button-text">Manage Salesforce Sources</span>
</button>
