import { Component, Input, EventEmitter, Output } from '@angular/core';
import { HierarchySelectConfig } from '../hierarchy-select.types';

@Component({
  selector: 'hierarchy-head',
  templateUrl: './hierarchy-head.component.html',
  styleUrls: ['./hierarchy-head.component.scss']
})
export class HierarchyHeadComponent {
  @Input() config: HierarchySelectConfig;
  @Input() allSelected: boolean;
  @Output() toggleAllGroups = new EventEmitter<boolean>();
  @Output() searchChanged = new EventEmitter<string>();

  public filterHierarchyItems(filterText: string) {
    this.searchChanged.emit(filterText)
  }
}
