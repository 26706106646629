<mat-form-field
  [hideRequiredMarker]="isSegmentControl && isChildCampaignCreation"
  [appearance]="config.fieldAppearance || 'fill'"
  [ngClass]="{
    'no-validate': !config.errorMsg,
    'p-form-field-outline': config.fieldAppearance === 'outline',
    'auto-width':  !fixedSelectWidth
  }"
>
  <mat-label>{{ config.fieldLabel }}</mat-label>
  <mat-select
    #matSelect
    [formControl]="selectControl"
    [placeholder]="placeholder"
    [compareWith]="compareSelectItems"
    (openedChange)="!$event && handleSelectClosed()"
    (selectionChange)="handleSelectionChange($event)"
    [disableOptionCentering]="disableOptionCentering"
    [panelClass]="basePanelClass + ' ' + config.selectPanelClass"
    [id]="id ? 'select-' + id : null"
  >
    <mat-select-trigger class="d-flex ai-center">
      <object-name [name]="selectControl.value?.title"
                   [linesTotal]="1"
                   [showIcon]="showTriggerIcon"
                   [objectType]="selectControl.value?.objectType"></object-name>
    </mat-select-trigger>

    <hierarchy-head
      [config]="config"
      [allSelected]="allSelected"
      (toggleAllGroups)="toggleAllGroupsCollapse($event)"
      (searchChanged)="updateActiveItems($event)"
    ></hierarchy-head>

    <mat-option
      *ngIf="config.emptyValueLabel"
      class="top-level-option"
      [value]="null">
      <ng-container
        [ngTemplateOutlet]="singleOptionTemplate"
        [ngTemplateOutletContext]="{ $implicit: { title: config.emptyValueLabel, objectType: null } }"
      ></ng-container>
    </mat-option>

    <ng-container *ngFor="let option of activeItems; trackBy: identifyItem">
      <!-- LEVEL 0 -->
      <div *ngIf="option.children?.length" class="group">
        <mat-option [value]="option" class="group-option" [disabled]="!groupSelectionAllowed || option.notClickable">
          <ng-container
            [ngTemplateOutlet]="groupOptionTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-container>
        </mat-option>

        <div class="group-content group-level-1" [class.is-group-open]="groupsOpenState[option.id]">
          <ng-container *ngFor="let childLevelOne of option.children; trackBy: identifyItem">
            <!-- LEVEL 1 -->
            <div *ngIf="childLevelOne.children?.length" class="group">
              <mat-option [value]="childLevelOne" class="group-option" [disabled]="!groupSelectionAllowed">
                <ng-container
                  [ngTemplateOutlet]="groupOptionTemplate"
                  [ngTemplateOutletContext]="{ $implicit: childLevelOne }"
                ></ng-container>
              </mat-option>

              <div class="group-content group-level-2" [class.is-group-open]="groupsOpenState[childLevelOne.id]">
                <ng-container *ngFor="let childLevelTwo of childLevelOne.children; trackBy: identifyItem">
                  <!-- LEVEL 2 -->
                  <div *ngIf="childLevelTwo.children?.length" class="group">
                    <mat-option [value]="childLevelTwo" class="group-option" [disabled]="!groupSelectionAllowed">
                      <ng-container
                        [ngTemplateOutlet]="groupOptionTemplate"
                        [ngTemplateOutletContext]="{ $implicit: childLevelTwo }"
                      ></ng-container>
                    </mat-option>

                    <div class="group-content group-level-3" [class.is-group-open]="groupsOpenState[childLevelTwo.id]">
                      <ng-container *ngFor="let childLevelThree of childLevelTwo.children; trackBy: identifyItem">
                        <!-- LEVEL 3 -->
                        <mat-option [value]="childLevelThree">
                          <ng-container
                            [ngTemplateOutlet]="singleOptionTemplate"
                            [ngTemplateOutletContext]="{ $implicit: childLevelThree }"
                          ></ng-container>
                        </mat-option>
                      </ng-container>
                    </div>
                  </div>

                  <mat-option [value]="childLevelTwo" *ngIf="!childLevelTwo.children?.length">
                    <ng-container
                      [ngTemplateOutlet]="singleOptionTemplate"
                      [ngTemplateOutletContext]="{ $implicit: childLevelTwo }"
                    ></ng-container>
                  </mat-option>
                </ng-container>
              </div>

            </div>

            <mat-option [value]="childLevelOne" *ngIf="!childLevelOne.children?.length">
              <ng-container
                [ngTemplateOutlet]="singleOptionTemplate"
                [ngTemplateOutletContext]="{ $implicit: childLevelOne }"
              ></ng-container>
            </mat-option>
          </ng-container>
        </div>
      </div>

      <mat-option class="top-level-option" [value]="option" *ngIf="!option.children?.length">
        <ng-container
          [ngTemplateOutlet]="singleOptionTemplate"
          [ngTemplateOutletContext]="{ $implicit: option }"
        ></ng-container>
      </mat-option>
    </ng-container>

    <!-- Workaround to prevent reset of selected items using search field filtration -->
    <mat-option [value]="selectControl.value" class="hidden"></mat-option>
  </mat-select>
  <mat-error *ngIf="config.errorMsg && selectControl.touched && selectControl.hasError('required')">
    {{ config.errorMsg }}
  </mat-error>
</mat-form-field>

<ng-template #groupOptionTemplate let-option>
  <hierarchy-option-content
    [className]="'is-group level-' + option.level"
    [ngClass]="{ 'not-clickable': option.notClickable }"
    [isSingleItem]="false"
    [groupsOpenState]="groupsOpenState"
    [option]="option"
    (toggleGroup)="toggleGroup($event, option.id)"
  ></hierarchy-option-content>
</ng-template>

<ng-template #singleOptionTemplate let-option>
  <hierarchy-option-content
    [className]="'level-' + option.level"
    [isSingleItem]="true"
    [option]="option"
  ></hierarchy-option-content>
</ng-template>
