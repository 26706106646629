<div class="timeframes" [class.with-borders] = "hasBorders">
  <div
    class="item"
    *ngFor="let item of preparedItems; trackBy: trackItemsFn"
    [ngClass]="{selected: item.isSelected}"
    [matTooltip]="tooltipText"
    matTooltipClass="dark-theme-tooltip above"
    matTooltipPosition="above"
  >
    {{item.title}}
  </div>
</div>
