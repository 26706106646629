import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spendingTimelineFormat'
})
export class SpendingTimelineFormatPipe implements PipeTransform {

  transform(value: number | string): string | number {
    if (value) {
      const thousand = Math.pow( 10, 3 );
      const million = Math.pow( 10, 6 );
      const billion = Math.pow( 10, 9 );
      const abs = Math.abs( value as number );

      if ( abs >= billion ) {   // billion
        const coefficient = Math.pow( 10, 8 );
        value = Math.round(value as number / coefficient) * coefficient / billion + 'B';
      } else if ( abs < billion && abs >= million ) {    // million
        const coefficient = Math.pow( 10, 5 );
        // 1M for 999 500 - 1 049 000
        // 1.1M for 1 050 000 - 1 149 000
        // 99.9M for 99 850 000 - 99 949 999
        value = Math.round(value as number / coefficient) * coefficient / million + 'M';
      } else if ( abs < million && abs >= thousand ) {  // thousand
        value = Math.round(value as number / thousand );
        // if value will be greater than 999 499 show 1M
        value = value === thousand ? value / thousand + 'M' : value + 'K';
      }
      else{ // less than thousand
        if (value as number % 1 !== 0) {
            value = (value as number).toFixed(2);
        }
      }
      return value;
    }

    return value;
  }

}
