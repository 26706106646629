import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { COLORS } from './colors.constants';

export const TIMEPICKER_THEME: NgxMaterialTimepickerTheme = {
  container: {
    bodyBackgroundColor: '#fff',
    buttonColor: COLORS.PRIMARY
  },
  dial: {
    dialBackgroundColor: COLORS.PRIMARY,
    dialInactiveColor: '#88accd',
    dialActiveColor: '#fff'
  },
  clockFace: {
    clockFaceBackgroundColor: '#eee',
    clockHandColor: COLORS.PRIMARY,
    clockFaceTimeInactiveColor: '#5f5f5f',
    clockFaceTimeActiveColor: '#fff'
  }
};
