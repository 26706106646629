<widget-card [isLoading]="state === widgetState.LOADING">
  <div class="history-widget">
    <details-widget-title [showCount]="false" text="History">
      <fa-icon slot="icon" [fixedWidth]="true" [icon]="['fas', 'history']"></fa-icon>
    </details-widget-title>
    <div class="content" [class.is-not-ready]="state !== widgetState.READY">
      <widget-card-placeholder *ngIf="state === widgetState.EMPTY || historyData?.length === 0">
        <fa-icon [icon]="['fas', 'history']" slot="icon"></fa-icon>
        <ng-container slot="content">
          You will see the history of Campaigns, Expenses, Goals,<br>
          Expense Groups and Metrics you have visited here
        </ng-container>
      </widget-card-placeholder>
      <history-widget-table
        *ngIf="historyData?.length"
        [data]="historyData"
        [updatedDates]="updatedDates"
      ></history-widget-table>
    </div>
  </div>
</widget-card>
