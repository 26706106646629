import { Pipe, PipeTransform } from '@angular/core';
import { createRegExpFromString } from 'app/shared/utils/common.utils';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(wholeText: string, searchQuery: string): string {
    if (!searchQuery || searchQuery.length < 3) {
      return wholeText;
    }
    const regEx = createRegExpFromString(searchQuery, 'i');
    return wholeText.replace(regEx, '<mark>$&</mark>');
  }

}
