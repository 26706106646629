<div *ngIf="opened"
  #dropDownContainer
  class="segments-rules-select"
  scrollOverlap
  [container]="overlapContainer"
  [trigger]="overlapTrigger"
  [tabIndex]="0"
  (blur)="closeCostList()"
  >
  <div class="container-wrapper">
    <div #sharedCostRulesContainer class="shared-cost-rules-container" scrollDetector [scrollContainerElement]="sharedCostRulesContainer">
      <div *ngFor="let rule of costRulesList; let i = index"
       class="item selectable d-flex"
       [ngClass]="{'active': rule.id === costData?.sharedCostRuleId, 'focused': i === ruleFocusIndex}"
       (click)="handleSelectSharedCostRule(rule)">
        <div class="shared-icon mr-2">
          <icon-shared [state]="rule.id === costData?.sharedCostRuleId ? sharedIconState.Default : sharedIconState.Empty"></icon-shared>
        </div>
        <div class="text">{{ rule.name }}</div>
      </div>
    </div>
  </div>
</div>
<icon-shared
  [state]="currentSharedState"
  (mouseenter)="showTooltip($event, costData.sharedCostRuleId)"
  (mouseleave)="hideTooltip()"
  (mousedown)="!readOnly && openCostList()">
</icon-shared>
<shared-rule-tooltip
  *ngIf="isTooltipShown"
  [className]="isTooltipAttachedToLeft ? 'fixed-position arrow-right' : 'fixed-position arrow-left'"
  [name]="tooltipData.name"
  [segments]="tooltipData.segments"
  [coords]="tooltipData.coords">
</shared-rule-tooltip>
