<ng-container *ngIf="editMode">
  <add-new-metric-btn
    *ngIf="index === 0"
    [top]="true"
    [flipped]="isFlipped"
    (click)="addRowAtIndex.emit(index)"
  ></add-new-metric-btn>
  <add-new-metric-btn
    *ngIf="!hideBottomBtn"
    (click)="addRowAtIndex.emit(index + 1)"
    [flipped]="isFlipped"
  ></add-new-metric-btn>
</ng-container>
