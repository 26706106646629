<div class="details-widget-title">
  <div class="title-icon" *ngIf="!hideIcon">
    <ng-content select="[slot=icon]"></ng-content>
  </div>
  <div class="title-text" [ngClass]="type"
    [matTooltip]="text"
    matTooltipClass="dark-theme-tooltip above multi-line"
    matTooltipPosition="above"
    [matTooltipDisabled]="!isProgressTargetWidget"
    matTooltipShowDelay="1000"
  >
    {{ text }}
    <fa-icon class="tooltip" *ngIf="showTooltip" [icon]="['fas', 'info-circle']" [matTooltip]="tooltipValue" matTooltipClass="dark-theme-tooltip above multi-line" matTooltipPosition="above"></fa-icon>
    <ng-content select="[slot=title-content]"></ng-content>
  </div>
  <div class="title-secondary" *ngIf="secondaryText">&nbsp;({{ secondaryText }})</div>
  <div *ngIf="showCount" class="title-count">({{ count }})</div>
</div>
