import { Component, Input } from '@angular/core';

@Component({
  template: ''
})
export abstract class IconBaseComponent {
  @Input() color = 'currentColor';
  @Input() size = '19px';
  @Input() width: string;
  @Input() height: string;
}
