import { Injectable } from '@angular/core';
import { MetricMappingDialogService } from '../../components/metric-mapping-dialog/metric-mapping-dialog.service';
import { HubspotCampaignMapping, HubspotMappingTypeName } from '../hubspot-campaign-mapping.interface';
import { ExternalCampaign } from '../../types/external-campaign.interface';
import { HubspotCampaignType } from '../hubspot.types';

@Injectable()
export class HubspotMappingDialogService extends MetricMappingDialogService<HubspotCampaignMapping> {
  private readonly _failedToLoadCampaignsErrorMsg = 'Failed to load Hubspot campaigns data';
  private readonly defaultMapping: HubspotCampaignMapping = {
      emails: [],
      forms: [],
      utmCampaigns: []
  };

  private getCampaignTypeMappings(campaign: ExternalCampaign, mappings: HubspotCampaignMapping): string[] {
    if (!mappings) {
      return null;
    }

    switch (campaign.type) {
      case HubspotCampaignType.WebForm:
        return mappings.forms;

      case HubspotCampaignType.MarketingEmail:
        return mappings.emails;

      case HubspotCampaignType.UtmCampaign:
        return mappings.utmCampaigns;

      default:
        return null;
    }
  }

  private filterExistingMappings(existingMappings: string[], campaignType: HubspotCampaignType): string[] {
    return (existingMappings || []).filter(
      mapping => this._externalCampaigns.find(item => item.id === mapping && item.type === campaignType)
    );
  }

  protected get failedToLoadCampaignsErrorMsg(): string {
    return this._failedToLoadCampaignsErrorMsg;
  }

  protected addCampaignMapping(campaign: ExternalCampaign, contextData: any): void {
    const campaignMapping = this._campaignMapping || this.defaultMapping;
    const mappings = this.getCampaignTypeMappings(campaign, campaignMapping);
    if (mappings && !mappings.includes(campaign.id)) {
      mappings.push(campaign.id);
      this._campaignMapping = campaignMapping;
    }
  }

  protected deleteCampaignMapping(campaign: ExternalCampaign): void {
    const mappings = this.getCampaignTypeMappings(campaign, this._campaignMapping);
    if (!mappings) {
      return;
    }

    const mappedCampaignIndex = mappings.findIndex(mapping => mapping === campaign.id);
    if (mappedCampaignIndex >= 0) {
      mappings.splice(mappedCampaignIndex, 1);
    }
  }

  protected filterIrrelevantMappings(campaignMapping: HubspotCampaignMapping): void {
    campaignMapping[HubspotMappingTypeName.forms] =
      this.filterExistingMappings(campaignMapping.forms, HubspotCampaignType.WebForm);
    campaignMapping[HubspotMappingTypeName.emails] =
      this.filterExistingMappings(campaignMapping.emails, HubspotCampaignType.MarketingEmail);
    campaignMapping[HubspotMappingTypeName.utmCampaigns] =
      this.filterExistingMappings(campaignMapping.utmCampaigns, HubspotCampaignType.UtmCampaign);
  }

  protected isCampaignMetricMapped(campaign: ExternalCampaign, campaignMapping: HubspotCampaignMapping): boolean {
    const mappings = this.getCampaignTypeMappings(campaign, campaignMapping);
    return mappings ? mappings.includes(campaign.id) : false;
  }
}
