import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ButtonStyle } from './button.types';

@Component({
  selector: 'plc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() isDisabled = false;
  @Input() buttonStyle: ButtonStyle = ButtonStyle.Basic;
  @Output() onClick = new EventEmitter<void>();

  public handleClick() {
    if (!this.isDisabled) {
      this.onClick.emit();
    }
  }
}
