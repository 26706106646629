<div class="menuItemsContainer" *ngIf="actionsMenuItems.length > 0 && isActionsMenuActivated">
  <ul #scrollContainer (clickOutside)="closeActionsMenu()">
    <li *ngFor="let menuItem of actionsMenuItems" class="menuItem" [class]="menuItem.cssClass" [class.active]="menuItem.active" [ngClass]="{selected: menuItem.value === selectedMenuItemValue, indented: menuItem.isIndented, unselectable: menuItem.isUnselectable}" #opt>
      <button tabindex="-1" type="button" (click)="activateAction(menuItem);" (keydown.enter)="activateAction(menuItem);$event.stopPropagation();">
        <div *ngIf="menuItem.cssClass" class="icon"></div>
        <span>{{menuItem.text}}</span>
      </button>
      <maintenance-tooltip *ngIf="menuItem.tooltipText" class="arrow-right">
        {{ menuItem.tooltipText }}
      </maintenance-tooltip>
    </li>
  </ul>
</div>
