import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { Validations } from 'app/app.validations';
import { UpdateProfilePayload, UpdateProfileResponse, UpdateProfileResponseData, UserService } from '../../services/user.service';
import { UserService as UserDetailsService } from 'app/shared/services/backend/user.service';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ChangePasswordMode } from '../../types/change-password-mode.enum';
import { HttpStatusCode } from '@angular/common/http';
import { UserDO } from '@shared/types/user-do.interface';

interface UserData {
  image_data?: File | null;
  first_name?: string;
  last_name?: string;
  email?: string;
  file?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './userProfile.html',
  styleUrls: ['./user-profile.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  subscriptions = [];
  userData: UserData = { image_data: null };
  company_id: number;
  currentUser: UserDO;
  profilePicUrl: string;
  isSSO: boolean;

  constructor(
    public configuration: Configuration,
    public _utilityService: UtilityService,
    public router: Router,
    public validations: Validations,
    public modal: Modal,
    public _userService: UserService,
    public companyDataService: CompanyDataService,
    public userManager: UserManager,
    private userDetailsService: UserDetailsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.userManager.currentUser$
        .pipe(
          filter(user => user != null),
          map((user: UserDO) => [user, !this.currentUser || user.user_profile_detail?.file !== this.currentUser.user_profile_detail?.file]),
          tap(([user]: [UserDO, boolean]) => {
            this.currentUser = user;
            if (Object.keys(this.userData).filter(key => key !== 'image_data').length === 0) {
              this.userData = {
                first_name:  user.user_profile_detail?.first_name,
                last_name: user.user_profile_detail?.last_name,
                email: user.email,
                file: user.user_profile_detail?.file
              };
            }
          }),
          filter(([, profilePicChanged]: [UserDO, boolean]) => profilePicChanged),
          switchMap(([user]) => this.userDetailsService.getUserProfilePictureUrl(user.id))
        )
        .subscribe(userProfilePicUrl => this.profilePicUrl = userProfilePicUrl)
    );

    this.subscriptions.push(
      this.companyDataService.selectedCompany$
        .pipe(filter(cmp => cmp != null))
        .subscribe(company => this.company_id = company.id)
    );

    this.subscriptions.push(
      this.userManager.isSSOUser$.subscribe(isSSO => this.isSSO = isSSO)
    );

  }

  fileEvent(event: any) {
    const file = event.target.files[0];
    if (file?.['type'] === 'image/jpeg' || file?.['type'] === 'image/png' || file?.['type'] === 'image/jpg') {
      if (file?.['size'] > 2000000) {
        this.userData['image_data'] = null;
        this._utilityService.showToast({ Title: '', Message: this.validations.ValiditionMessages.INVALID_IMAGE_SIZE, Type: 'error' });
      } else {
        this.userData['image_data'] = file;
        const selectedFile = file;
        const reader = new FileReader();
        reader.onload = (loadEvent) => {
          this.profilePicUrl = loadEvent.target['result'] as string;
        };
        reader.readAsDataURL(selectedFile);
      }
    } else if (file != null) {
      this.userData['image_data'] = null;
      this._utilityService.showToast({ Title: '', Message: this.validations.ValiditionMessages.INVALID_IMAGE, Type: 'error' });
    }
  }

  updateProfile(form, formValid: boolean) {
    if (formValid) {
      const payload: UpdateProfilePayload = {
        first_name: (form['first_name'].replace(/ +/g, ' ')).trim(),
        last_name: (form['last_name'].replace(/ +/g, ' ')).trim(),
        file: this.userData['image_data'],
        company: this.company_id.toString()
      };

      this._utilityService.showLoading(true);
      this.subscriptions.push(this._userService.updateUserProfile(payload, this.currentUser.id).subscribe(
        data => this.success(data, 'updateProfile'),
        error => this.handleError(error)
      ));
    }
  }

  changePassword() {
    this.userManager.navigateToChangePassword({
      successRoutePath: this.configuration.ROUTING_CONSTANTS.USER,
      cancelRoutePath: this.configuration.ROUTING_CONSTANTS.USER,
      mode: ChangePasswordMode.ChangePassword
    });
  }

  updateCurrentSession(data: UpdateProfileResponseData) {
    if (data.file) {
      this.userData.file = data.file;
    }

    this.userData.first_name = data.first_name;
    this.userData.last_name = data.last_name;

    this.userManager.setCurrentUser({
      ...this.currentUser,
      user_profile_detail: {
        ...this.currentUser.user_profile_detail,
        first_name: data.first_name,
        last_name: data.last_name,
        file: data.file
      }
    });
  }

  success(data: UpdateProfileResponse, type: string) {
    if (Number(data?.status) === HttpStatusCode.Ok) {
      if (type === 'updateProfile') {
        this.updateCurrentSession(data.data);
        this._utilityService.showToast({ Title: '', Message: data.message, Type: 'success' });
      }
    } else {
      if (data.message) {
        this._utilityService.showToast({ Title: '', Message: data.message, Type: 'error' });
      }
    }
    this._utilityService.showLoading(false);
  }

  handleError(error) {
    if (error.hasOwnProperty('message')) {
      this._utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
      this._utilityService.showLoading(false);
    } else if (error.hasOwnProperty('detail')) {
      this._utilityService.showLoading(false);
      this._utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
