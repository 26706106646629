import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[trim][formControlName],[trim][formControl],[trim][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => TrimValidator), multi: true }
    ]
})
export class TrimValidator implements Validator {
    constructor( @Attribute('trim') public trim: string) {

    }

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        let v = c.value;

        if(v && v != undefined && v.trim() === ''){
            return {
                trim: true
            }
        }else{
            return null;
        }
    }
}