import { Injectable } from '@angular/core';
import { TableViewRow, TableDataBuilder } from './table-data.type';
import { Configuration } from 'app/app.constants';
import { SortParams } from 'app/shared/types/sort-params.interface';

@Injectable({
  providedIn: 'root'
})
export class TableDataBuilderService {
  constructor(private config: Configuration) {}

  create(): TableDataBuilder {
    let tableData = [];
    let tableColumns = [];
    let columnClasses = {};
    let withTotalRow = false;
    let withTotalColumn = false;
    let hasGroupedData = false;

    const createTotalColumn = (columns) => {
      return {
        fieldName: 'total',
        className: 'boldColumn fy-total',
        value: columns.reduce((sum, column) => sum += column.value, 0)
      }
    };

    const getColumns = (columnsData: {[key: string]: number}) => {
      const columns = tableColumns.map(fieldName => {
        return {
          fieldName,
          className: columnClasses[fieldName],
          value: columnsData[fieldName] || 0,
        }
      });
      if (withTotalColumn) {
        const total = createTotalColumn(columns);
        columns.push(total);
      }
      return columns;
    };

    const createTotalRow = (): TableViewRow => {
      const columnsData = tableData.reduce(
        (total, row) => {
          tableColumns.forEach(key => {
            const value = row.data[key];
            total[key] = total[key] ? total[key] + value : value;
          });
          return total;
        }, {}
      );
      return {
        title: 'Total',
        fieldName: 'total',
        className: 'boldRow total',
        columns: getColumns(columnsData)
      }
    };

    const getSortedData = (sortParams: SortParams) => {
      const { column, reverse } = sortParams;
      const compareRowNames = (rowA, rowB) => {
        return reverse
          ? rowB.name.localeCompare(rowA.name)
          : rowA.name.localeCompare(rowB.name);
      };
      const compareNumbers = (rowA, rowB) => {
        return reverse
          ? rowA.data[column] - rowB.data[column]
          : rowB.data[column] - rowA.data[column];
      };
      const compareFunc = column === 'rowTitle' ? compareRowNames : compareNumbers;

      const sortedData = [...tableData].sort((itemA, itemB) => {
        if (!!itemA.items === !!itemB.items) {
          return compareFunc(itemA, itemB);
        }

        if (!itemA.items) {
          return -1;
        }

        if (!itemB.type) {
          return 1;
        }

        return 0;
        }
      )
      if (hasGroupedData) {
        sortedData.forEach(row => {
          if (!row.items) {
            return;
          }
          row.items = [...row.items].sort(compareFunc);
        })
      }
      return sortedData;
    }

    const getRows = (sortedData): TableViewRow[] => {
      if (hasGroupedData) {
        const unpackedGroups = [];
        sortedData.forEach(row => {
          if (!row.items) {
            row.isSingle = true;
            unpackedGroups.push(row);
            return;
          }
          unpackedGroups.push({
            id: row.id,
            name: row.name,
            data: row.data,
            itemsInGroup: row.items.length,
            className: 'groupedRow',
          });
          row.items.forEach(child => {
            child.groupId = row.id;
            unpackedGroups.push(child);
          })
        })

        sortedData = unpackedGroups;
      }
      const rows: TableViewRow[] = sortedData
        .map(dataItem => {
          return {
            id: dataItem.id,
            groupId: dataItem.groupId,
            title: dataItem.name,
            className: dataItem.className,
            itemsInGroup: dataItem.itemsInGroup,
            fieldName: dataItem.fieldName || '',
            columns: getColumns(dataItem.data)
          }
        });
      if (withTotalRow) {
        const total = createTotalRow();
        rows.push(total);
      }
      return rows;
    };

    return {

      setTableData(tableDataInput) {
        tableData = tableDataInput;
        return this;
      },

      setTableColumns(tableColumnsInput) {
        tableColumns = tableColumnsInput;
        return this;
      },

      setColumnClasses(columnClassesInput) {
        columnClasses = columnClassesInput;
        return this;
      },

      getTableRows(includeTotalRow, includeTotalColumn, groupedData, sortParams?) {
        withTotalRow = includeTotalRow || false;
        withTotalColumn = includeTotalColumn || false;
        hasGroupedData = groupedData;
        const sortedData = sortParams ? getSortedData(sortParams) : [...tableData];
        return getRows(sortedData)
      }
    }
  }
}
