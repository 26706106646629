import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GroupToggleOption } from '../group-toggle-control/group-toggle-control.component';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ExpenseAllocationMode } from 'app/shared/types/expense-allocation-mode.type';
import { AllocationMode } from 'app/shared/types/budget-object-allocation.interface';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { Currency } from 'app/shared/types/currency.interface';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserDataService } from 'app/shared/services/user-data.service';

export type OverdueTableViewMode = ExpenseAllocationMode.Committed | ExpenseAllocationMode.Planned;

export enum OverdueExpensesChangeAction {
  AmountChanged,
  CloseAtZero,
  CloseAtPlanned,
  CloseAtCommitted,
  MoveItem,
  MoveAll
}

export interface OverdueExpensesTableItem {
  id: string;
  expenseId: number;
  name: string;
  allocationName: string;
  mode: AllocationMode,
  amount: number;
  actualAmount: number;
  sourceAmount: number;
  sourceActualAmount: number;
  companyBudgetAlloc: number;
  currency: string,
  freeTimeframes: BudgetTimeframe[];
  isReadOnly: boolean;
}

export interface OverdueExpensesTableChange {
  items: OverdueExpensesTableItem[];
  action: OverdueExpensesChangeAction;
  newValue?: number | string | BudgetTimeframe;
  viewMode?: OverdueTableViewMode;
}

@Component({
  selector: 'overdue-expenses-table',
  styleUrls: ['./overdue-expenses-table.component.scss'],
  templateUrl: './overdue-expenses-table.component.html'
})
export class OverdueExpensesTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currencyMap: Map<string, Currency>;
  @Input() data: OverdueExpensesTableItem[] = [];
  @Input() timeframes: BudgetTimeframe[];
  @Input() currentTimeframe: BudgetTimeframe;
  @Input() overdueTimeframes: BudgetTimeframe[];
  @Output() onChange = new EventEmitter<OverdueExpensesTableChange>();
  @Output() onCounterUpdated = new EventEmitter<number>();
  @Output() setLoadingState = new EventEmitter<boolean>();

  @ViewChild('menu') trigger: MatMenuTrigger;

  public closeItem: OverdueExpensesTableItem = null;

  private readonly destroy$ = new Subject<void>();
  private readonly changeApplied$ = new Subject<OverdueExpensesTableChange>();

  public tfWarningTooltip = 'Please open the expense\ndetails to move this expense\nto a new timeframe';
  public currentViewMode: OverdueTableViewMode = ExpenseAllocationMode.Committed;
  public viewModeOptions: GroupToggleOption[] = [
    {
      name: ExpenseAllocationMode.Committed,
      value: ExpenseAllocationMode.Committed
    },
    {
      name: ExpenseAllocationMode.Planned,
      value: ExpenseAllocationMode.Planned
    }
  ];
  public committedExpenses: OverdueExpensesTableItem[] = [];
  public plannedExpenses: OverdueExpensesTableItem[] = [];
  public currentList: OverdueExpensesTableItem[] = [];
  public overdueTimeframeIds = [];
  public selectedMoveMap = {
    [ExpenseAllocationMode.Planned]: new Map<string, BudgetTimeframe>(),
    [ExpenseAllocationMode.Committed]: new Map<string, BudgetTimeframe>()
  };
  public timeframeToMoveAll = null;
  public isBodyScrolled = false;
  public timeframePickerOpenId = null;
  public selectedMoveAllTf = null;
  public allocationMode = ExpenseAllocationMode;
  public VISIBLE_ROWS_LIMIT = 7;
  public HEADER_ROW_ID = 'header';
  public isReadOnly = true;

  constructor(
    private readonly appRoutingService: AppRoutingService,
    private readonly userDataService: UserDataService,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.userDataService.editPermission$
      .pipe(takeUntil(this.destroy$))
      .subscribe(editPermission => this.isReadOnly = !editPermission);
  }

  ngOnInit(): void {
    this.changeApplied$.asObservable()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (event) => this.onChange.emit(event)
      )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.resetData();
      this.data.forEach((item) => {
        if (item.mode === ExpenseAllocationMode.Planned) {
          this.plannedExpenses.push(item);
        }
        if (item.mode === ExpenseAllocationMode.Committed) {
          this.committedExpenses.push(item);
        }
      });
      this.setCurrentList();
      this.setLoadingState.emit(false);
    }

    if (changes.overdueTimeframes && this.overdueTimeframes) {
      this.overdueTimeframeIds = this.overdueTimeframes.map(tf => tf.id);
    }

    if (this.data && this.data.length && this.overdueTimeframes && this.currentTimeframe && this.timeframes) {
      this.defineDefaultMoveTimeframes();
      this.defineMoveAllTimeframe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private resetData() {
    this.plannedExpenses = [];
    this.committedExpenses = [];
    this.selectedMoveMap[ExpenseAllocationMode.Planned].clear();
    this.selectedMoveMap[ExpenseAllocationMode.Committed].clear();
  }

  private syncCounter() {
    this.onCounterUpdated.emit(this.currentList.length);
  }

  private setCurrentList() {
    if (!this.plannedExpenses.length) {
      this.currentViewMode = ExpenseAllocationMode.Committed;
    }

    if (!this.committedExpenses.length) {
      this.currentViewMode = ExpenseAllocationMode.Planned;
    }

    this.currentList = this.currentViewMode === ExpenseAllocationMode.Planned ?
      this.plannedExpenses :
      this.committedExpenses;
    this.syncCounter();
  }

  private defineDefaultMoveTimeframes() {
    this.data.forEach(item => {
      const firstFreeAlloc = item.freeTimeframes.length ? item.freeTimeframes[0] : null;
      const moveMap = this.selectedMoveMap[item.mode];
      if (moveMap) {
        moveMap.set(item.id, firstFreeAlloc);
      }
    });
  }

  private getAmountKey(item: OverdueExpensesTableItem) {
    return item.mode === ExpenseAllocationMode.Committed ? 'sourceActualAmount' : 'sourceAmount';
  }

  private patchItemAmount(item: OverdueExpensesTableItem, amount: number) {
    const amountKey = this.getAmountKey(item);
    item[amountKey] = amount;
  }

  private defineMoveAllTimeframe() {
    const moveValues = Array.from(this.selectedMoveMap[this.currentViewMode].values());
    if (!moveValues.length) {
      this.timeframeToMoveAll = null;
      return;
    }

    const moveExpensesIds = new Set<number>();
    this.currentList.forEach(v => moveExpensesIds.add(v.expenseId));
    if (!(this.currentList.length === moveExpensesIds.size)) {
      this.timeframeToMoveAll = null;
      return;
    }

    const firstMoveValue = moveValues[0];
    const moveValuesAreEqual = firstMoveValue != null ? moveValues.every(v => v && v.id === firstMoveValue.id) : false;
    if (moveValuesAreEqual) {
      this.timeframeToMoveAll = firstMoveValue;
    } else {
      this.timeframeToMoveAll = null;
    }
  }

  private defineCloseActionType(closeAtZero: boolean, viewMode: ExpenseAllocationMode): OverdueExpensesChangeAction {
    return closeAtZero ?
      OverdueExpensesChangeAction.CloseAtZero :
      viewMode === ExpenseAllocationMode.Committed ?
        OverdueExpensesChangeAction.CloseAtCommitted :
        OverdueExpensesChangeAction.CloseAtPlanned;
  }

  private getUpdatableItems() {
    const itemsToUpdate = [];
    const readOnlyItems = [];

    this.currentList.forEach(item => item.isReadOnly ? readOnlyItems.push(item) : itemsToUpdate.push(item));

    return {
      itemsToUpdate,
      readOnlyItems
    };
  }

  public trackFn(index, item) {
    return item.id;
  }

  public handleBodyScrolledChange($event: boolean) {
    this.isBodyScrolled = $event;
  }

  public handleToggleChange(value: OverdueTableViewMode) {
    this.currentViewMode = value;
    this.timeframePickerOpenId = null;
    this.setCurrentList();
    this.defineMoveAllTimeframe();
    this.cdRef.detectChanges();
  }

  public handleSelectedTF(timeframe: BudgetTimeframe, itemId: string) {
    this.timeframePickerOpenId = null;
    if (itemId === this.HEADER_ROW_ID) {
      this.selectedMoveAllTf = timeframe;
      this.currentList.forEach(item => {
        const freeTimeframe = item.freeTimeframes.find(ft => ft.id === timeframe.id);
        if (freeTimeframe) {
          this.selectedMoveMap[this.currentViewMode].set(item.id, freeTimeframe);
        }
      });
      this.defineMoveAllTimeframe();
      return;
    }

    this.selectedMoveMap[this.currentViewMode].set(itemId, timeframe);
    this.defineMoveAllTimeframe();
  }

  public toggleTimeframePicker(id: string) {
    if (!this.isReadOnly) {
      this.timeframePickerOpenId = this.timeframePickerOpenId === id ? null : id;
    }
  }

  public openDetails(expenseId: number) {
    this.appRoutingService.openExpenseDetails(expenseId);
  }

  public setCloseItem(item: OverdueExpensesTableItem) {
    if (!this.isReadOnly && !item.isReadOnly) {
      this.closeItem = item;
    }
  }

  public handleAmountChange(amount: number, item: OverdueExpensesTableItem) {
    const amountKey = this.getAmountKey(item);
    const amountChanged = item[amountKey] !== amount;

    if (amountChanged) {
      this.patchItemAmount(item, amount);
      this.changeApplied$.next({
        items: [item],
        action: OverdueExpensesChangeAction.AmountChanged,
        newValue: amount
      });
    }
  }

  public handleCloseAction(closeAtZero = false) {
    if (!this.closeItem || this.isReadOnly) {
      return;
    }

    const action = this.defineCloseActionType(closeAtZero, this.currentViewMode);
    const closedIndex = this.currentList.indexOf(this.closeItem);
    this.currentList.splice(closedIndex, 1);
    this.syncCounter();
    this.defineMoveAllTimeframe();
    this.changeApplied$.next({
      items: [this.closeItem],
      action,
      viewMode: this.currentViewMode
    });
  }

  public handleCloseAllAction(closeAtZero = false) {
    if (!this.isReadOnly) {
      const action = this.defineCloseActionType(closeAtZero, this.currentViewMode);
      const { itemsToUpdate, readOnlyItems } = this.getUpdatableItems();

      if (!itemsToUpdate.length) {
        return;
      }
      this.currentList = [...readOnlyItems];
      this.syncCounter();
      this.setLoadingState.emit(true);
      this.changeApplied$.next({
        items: itemsToUpdate,
        action,
        viewMode: this.currentViewMode
      });
    }
  }

  public handleMoveAction(item: OverdueExpensesTableItem, timeframe: BudgetTimeframe) {
    if (!this.isReadOnly && !item.isReadOnly) {
      const closedIndex = this.currentList.indexOf(item);
      this.currentList.splice(closedIndex, 1);
      this.syncCounter();
      this.defineMoveAllTimeframe();
      this.changeApplied$.next({
        items: [item],
        action: OverdueExpensesChangeAction.MoveItem,
        newValue: timeframe,
        viewMode: this.currentViewMode
      });
    }
  }

  public handleMoveAllAction() {
    if (!this.timeframeToMoveAll || this.isReadOnly) {
      return;
    }
    const { itemsToUpdate, readOnlyItems } = this.getUpdatableItems();

    if (!itemsToUpdate.length) {
      return;
    }
    this.currentList = [...readOnlyItems];
    this.syncCounter();
    this.setLoadingState.emit(true);
    this.changeApplied$.next({
      items: itemsToUpdate,
      action: OverdueExpensesChangeAction.MoveAll,
      viewMode: this.currentViewMode,
      newValue: this.timeframeToMoveAll
    });
  }
}
