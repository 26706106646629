import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[plDroppable]'
})
export class PlDroppableDirective implements OnInit, OnDestroy {
  @Input() plDroppable = false;
  @Output() dropOn = new EventEmitter<DragEvent>();
  @Output() dragEnter = new EventEmitter<DragEvent>();
  @Output() dragLeave = new EventEmitter<DragEvent>();

  private listeners: Record<string, Function>;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly zone: NgZone
  ) {
    this.listeners = {
      drop: this._onDrop.bind(this),
      dragend: this._onDragEnd.bind(this),
      dragover: this._onDragOver.bind(this),
      dragenter: this._onDragEnter.bind(this),
      dragleave: this._onDragLeave.bind(this),
    };
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      Object.entries(this.listeners).forEach(([event, listener]) => {
        this.elementRef.nativeElement.addEventListener(event, listener);
      });
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      Object.entries(this.listeners).forEach(([event, listener]) => {
        this.elementRef.nativeElement.removeEventListener(event, listener);
      });
    });
  }

  private _onDrop($event: Event) {
    if (!this.plDroppable) {
      return;
    }
    $event.preventDefault();
    this.dropOn.emit($event as DragEvent);
  }

  private _onDragEnd($event: DragEvent) {
    if (!this.plDroppable) {
      return;
    }
    $event.preventDefault();
    this.dragLeave.emit($event);
  }

  private _onDragOver($event: Event) {
    if (!this.plDroppable) {
      return;
    }
    $event.preventDefault();
  }

  private _onDragEnter($event: DragEvent) {
    if (!this.plDroppable) {
      return;
    }
    this.dragEnter.emit($event);
  }

  private _onDragLeave($event: DragEvent) {
    if (!this.plDroppable) {
      return;
    }
    this.dragLeave.emit($event);
  }
}
