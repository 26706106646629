import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TagsManagementComponent } from './tags-management.component';
import { SharedModule } from 'app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CreateTagModalComponent } from './create-tag-modal/create-tag-modal.component';

@NgModule({
  declarations: [
    TagsManagementComponent,
    CreateTagModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FontAwesomeModule
  ],
  exports: [
    TagsManagementComponent,
    CreateTagModalComponent
  ],
})
export class TagsManagementModule {}
