import { MatDialog } from '@angular/material/dialog';
import { Component, Input, Output, EventEmitter, OnDestroy, inject, ChangeDetectorRef, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { Attachment } from 'app/shared/types/attachment.interface';
import { BudgetObjectAttachmentsService } from 'app/budget-object-details/services/budget-object-attachments.service';
import { AttachmentViewerComponent } from 'app/budget-object-details/components/attachment-viewer/attachment-viewer.component';
import { PdfViewerService } from '@shared/services/pdf-viewer.service';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsListComponent implements OnDestroy, OnInit {
  @Input() disableInput = true;
  @Input() objectTypeName = '';
  @Input() attachments: Attachment[] = [];
  @Input() isReadOnlyMode = false;
  @Input() showAddIcon = true;
  @Input() isInvoiceDrawer = false;
  @Output() onAttachFiles = new EventEmitter();
  @Output() onDeleteAttachment = new EventEmitter<Attachment>();
  @Output() onDownloadAttachment = new EventEmitter<Attachment>();

  public readonly attachmentsManager = inject(BudgetObjectAttachmentsService);
  private readonly pdfViewerService = inject(PdfViewerService);
  private readonly dialog = inject(MatDialog);
  private readonly config = inject(Configuration);
  private readonly _cdr = inject(ChangeDetectorRef);

  public acceptedFileExtensions: string;
  public showFullList = false;
  public minAttachmentsToShowMore = 4;

  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.acceptedFileExtensions = this.config.acceptedAttachmentFileExtensions;
  }
  ngOnInit(): void {
    this.attachmentsManager.cdrTrigger$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this._cdr.markForCheck())
      ).subscribe();
  }
  ngOnDestroy(): void {
    this.closeDialog();
    this.destroy$.next();
    this.destroy$.complete();
  }
  dialogRef = this.dialog;

  public viewAttachment(attachment: Attachment): void {
    this.attachmentsManager.getFileUrl(attachment)
    .pipe(
      take(1),
      takeUntil(this.destroy$)
    )
    .subscribe(url => this.openDialog(this.dialogRef, url));
  }

  private closeDialog(): void {
    this.dialogRef.closeAll();
  }

  private openDialog(dialog: MatDialog, url): void {
    this.pdfViewerService.setSource(url, false);
    dialog.open(AttachmentViewerComponent, {
      panelClass: 'viewer-modalbox',
      backdropClass: 'viewer-popup-backdrop',
      hasBackdrop: true
    });
  }
}
