<section class="business-value-info">
  <div class="bv-container">
    <p class="actual-bv" [class.unset]="actualBusinessValue == null">
      <fa-duotone-icon [icon]="['fad', 'heart']"></fa-duotone-icon>
      <span>Estimated {{ bvObjectType }} Value: {{ actualBusinessValue != null ? currencySymbol + ' ' + (actualBusinessValue | number: decimalDisplayFormat) : 'N/A' }}</span>
      <fa-duotone-icon
        [icon]="['fad', 'graduation-cap']"
        [matTooltip]="tooltipText"
        matTooltipClass="dark-theme-tooltip multi-line text-left left"
        matTooltipPosition="left"
        [matTooltipDisabled]="!tooltipText"
      ></fa-duotone-icon>
    </p>
    <p class="target" *ngIf="showTarget" [class.unset]="targetBusinessValue == null">Target: {{ targetBusinessValue != null ? currencySymbol + ' ' + (targetBusinessValue | number: decimalDisplayFormat) : 'N/A'}}</p>
  </div>
</section>
