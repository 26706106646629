import { Component, Input } from '@angular/core';
import { MetricIntegrationDisplayName, MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';

@Component({
  selector: 'metrics-source',
  templateUrl: './metrics-source.component.html',
  styleUrls: ['./metrics-source.component.scss']
})
export class MetricsSourceComponent {
  sourcesForDisplay: Record<string, boolean>;
  sourcesNames: string;
  ICONS_FOR_DISPLAY = 3;
  showDots: boolean;
  metricIntegrationDisplayName = MetricIntegrationDisplayName;
  metricIntegrationName = MetricIntegrationName;

  @Input() set sourcesIds(sourcesIdArray: string[]) {
    if (!sourcesIdArray?.length) {
      this.resetData();
      return;
    }
    this.fillNames(sourcesIdArray);
    this.fillIconsForDisplay(sourcesIdArray);
    this.showDots = sourcesIdArray.length > this.ICONS_FOR_DISPLAY;
  };

  fillIconsForDisplay(ids) {
    this.sourcesForDisplay = {};
    ids.slice(0, this.ICONS_FOR_DISPLAY).forEach(id => {
      this.sourcesForDisplay[id] = true;
    })
  }

  fillNames(ids) {
    this.sourcesNames = ids.map(id => MetricIntegrationDisplayName[id]).join(' ');
  }

  resetData() {
    this.sourcesForDisplay = {};
    this.sourcesNames = null;
    this.showDots = false;
  }
}
