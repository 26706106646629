import { ComponentRef, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FloatErrorMessageComponent } from '@common-lib/lib/corporate-page/components/float-error-message/float-error-message.component';


@Directive({
  selector: '[floatingErrorMessage]'
})
export class FloatingErrorMessageDirective implements OnInit, OnChanges, OnDestroy {
  @Input() errorMessage: string;
  @Input() errorShowDelay = 0;

  private overlayRef: OverlayRef;
  private positionStrategy: PositionStrategy;
  private showDelayTimeout = null;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  ngOnInit() {
    this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.errorMessage) {
      if (!this.errorMessage) {
        this.hide();
      } else {
        this.show();
      }
    }
  }

  ngOnDestroy(): void {
    this.hide();
  }

  private attachErrorComponent() {
    if (!this.overlayRef) {
      return;
    }

    this.overlayRef.detach();
    const componentPortal = new ComponentPortal(FloatErrorMessageComponent);
    const componentRef: ComponentRef<FloatErrorMessageComponent> = this.overlayRef.attach(componentPortal);
    componentRef.instance.errorMessage = this.errorMessage;
  }

  @HostListener('mouseenter')
  private show() {
    if (!this.errorMessage) {
      return;
    }

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.positionStrategy
      });
    }
    this.showDelayTimeout = setTimeout(() => this.attachErrorComponent(), this.errorShowDelay);
  }

  @HostListener('mouseleave')
  private hide() {
    clearTimeout(this.showDelayTimeout);
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
