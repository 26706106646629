<header mat-dialog-title>
  <div class="text-center">Switch budget?</div>
</header>

<div class="p-1">
  <div *ngIf="singleExternalBudget" class="text-block">
    This metric is also being used in this budget:<br>
    <b>{{ selectedBudget.budgetName }}</b>
    <br>
    <br>
    Would you like us to take you there?
  </div>

  <ng-conteiner *ngIf="!singleExternalBudget">
    <p class="text-block">
      This metric is tracked in multiple budgets.<br>
      Choose the budget you would like to see this metric in:
    </p>

    <mat-form-field appearance="outline" class="p-form-field-outline mt-1">
      <mat-label>Budget</mat-label>
      <mat-select [(ngModel)]="selectedBudget">
        <mat-option *ngFor="let budget of context.groups" [value]="budget">
          <span>{{ budget.budgetName }} ({{ budget.campaignIds.length }})</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-conteiner>

</div>

<footer mat-dialog-actions>
  <button mat-button
          color="primary"
          (click)="dialogRef.close()">Cancel</button>
  <button mat-flat-button
          color="primary"
          [disabled]="!selectedBudget"
          (click)="dialogRef.close(selectedBudget)">Ok</button>
</footer>

