export enum PageTitle {
    LoginPage = 'Plannuh - a Planful Company',
    ChangePassword = 'Change Password | Planful Marketing',
    Register = 'Register | Planful Marketing',
    RegisterSuccess = 'Confirm Registration | Planful Marketing',
    ForgotPassword = 'Password Reset | Planful Marketing',
    Onboarding = 'Quick Start | Planful Marketing',
    Widget =  'Dashboard | Planful Marketing',
    Dashboard=  'Plan | Planful Marketing',
    Expenses =  'Expenses | Planful Marketing',
    Invoices =  'Invoices | Planful Marketing',
    Calendar =  'Calendar | Planful Marketing',
    Upload =  'Import Expenses | Planful Marketing',
    Team = 'Account | Planful Marketing',
    ManageCompany = 'Manage Company | Planful Marketing',
    Profile = 'My Profile | Planful Marketing',
    ManageSuperadmin = 'Manage Superadmins | Planful Marketing',
    Search = 'Search | Planful Marketing',
    Budget = 'Budget | Planful Marketing',
    Currencies = 'Currencies | Planful Marketing',
    ExpenseType = 'Expense Types | Planful Marketing',
    GLCodes = 'GL Codes | Planful Marketing',
    Metrics = 'Metrics | Planful Marketing',
    CostRule = 'Rules | Planful Marketing',
    Tags = 'Tags | Planful Marketing',
    Manage = 'Manage | Planful Marketing',
    Home = 'Home | Planful Marketing',
    Export = 'Import / Export | Planful Marketing',
    Skilljar = 'Academy | Planful Marketing',
    AttributesAndTags = 'Attributes & Tags',
    CustomFields = 'Custom Fields',
}
