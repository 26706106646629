import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CheckboxValue } from '@shared/enums/checkbox-value.enum';
import { ManageCegTableRow, ManageCegTableSelectionState } from '@manage-ceg/types/manage-ceg-page.types';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Injectable()
export class RecordInteractionService {
  private readonly tableDataService = inject(ManageCegTableDataService);
  private _togglingState: Record<string, boolean> = {};
  private _selectionState: ManageCegTableSelectionState;
  public readonly selectionChanged$ = new Subject<ManageCegTableSelectionState>();

  constructor() {
    this.resetSelection();
    this.resetToggling();
  }

  private areAllRecordsSelected(): boolean {
    return Object.values(this.tableDataService.flatDataMap).length === Object.values(this.selectionState.records).length;
  }

  private isAnyRecordSelected(): boolean {
    return Object.keys(this.selectionState.records).length > 0;
  }

  public handleSelection(item: ManageCegTableRow, value: boolean): void {
    if (value) {
      this.selectRecordRecursively(item);
      this.selectionState.selectAllValue = this.areAllRecordsSelected()
        ? CheckboxValue.Active
        : CheckboxValue.Indeterminate;
    } else {
      this.deselectRecordRecursively(item);
      this.selectionState.selectAllValue = this.isAnyRecordSelected()
        ? CheckboxValue.Indeterminate
        : CheckboxValue.NotActive;
    }
    this.selectionChanged$.next(this.selectionState);
  }

  public handleSelectAllChange(data: ManageCegTableRow[], value: boolean): void {
    if (value) {
      data.forEach(item => this.selectRecordRecursively(item));
      this.selectionState.selectAllValue = CheckboxValue.Active;
    } else {
      this.resetSelection(false);
    }
    this.selectionChanged$.next(this.selectionState);
  }

  private selectRecordRecursively(item: ManageCegTableRow): void {
    item.children?.forEach(child => this.selectRecordRecursively(child));
    this.selectRecord(item);
  }

  private deselectRecordRecursively(item: ManageCegTableRow): void {
    item.children?.forEach(child => this.deselectRecordRecursively(child));
    this.deselectRecord(item);
  }

  private selectRecord(item: ManageCegTableRow): void {
    if (!item.objectId || item.isFilteredOut) {
      return;
    }
    const selectionRecord = this.getSelectionRecord(item.id);
    const activeSelectionSet =  this.getActiveSelectionSet(item);
    selectionRecord.value = CheckboxValue.Active;
    activeSelectionSet.add(item.objectId);
  }

  private removeSelectionRecord(item: ManageCegTableRow): void {
    Reflect.deleteProperty(this.selectionState.records, item.id);

    const activeSelectionSet = this.getActiveSelectionSet(item);
    activeSelectionSet.delete(item.objectId);
  }

  private getActiveSelectionSet(item: ManageCegTableRow): Set<number> {
    switch (item.type) {
      case ManageTableRowType.Campaign:
        return this.selectionState.campaigns;

      case ManageTableRowType.ExpenseGroup:
        return this.selectionState.expGroups;

      case ManageTableRowType.Goal:
        return this.selectionState.goals;

      case ManageTableRowType.Segment:
        return this.selectionState.segments;

      case ManageTableRowType.SegmentGroup:
        return this.selectionState.segmentGroups;

      default:
        return new Set();
    }
  }

  private getSelectionRecord(id: string, lazyInit = true): any {
    if (!this.selectionState.records[id] && lazyInit) {
      this.selectionState.records[id] = {
        selectedChildren: new Set<string>(),
        value: null
      };
    }
    return this.selectionState.records[id];
  }

  public deselectRecord(item: ManageCegTableRow): void {
    this.removeSelectionRecord(item);
  }

  public handleToggleChange(item: ManageCegTableRow, value: boolean): void {
    if (value) {
      Reflect.deleteProperty(this.togglingState, item.id);
    } else {
      if (item.loadedChildren.length === 0) {
        this.loadNextChunk(item);
      }
      this.togglingState[item.id] = true;
    }
  }

  public loadNextChunk(item: ManageCegTableRow): void {
    this.tableDataService.loadChunkOfRowsData(item);
  }

  public get selectionState(): ManageCegTableSelectionState {
    return this._selectionState;
  }

  public get togglingState(): Record<string, boolean> {
    return this._togglingState;
  }

  public resetToggling(): void {
    this._togglingState = {};
  }

  public resetSelection(emitEvent = true): void {
    this._selectionState = {
      records: {},
      goals: new Set<number>(),
      campaigns: new Set<number>(),
      expGroups: new Set<number>(),
      segments: new Set<number>(),
      segmentGroups: new Set<number>(),
      selectAllValue: CheckboxValue.NotActive
    };
    if (emitEvent) {
      this.selectionChanged$.next(this._selectionState);
    }
  }

  public implicitSelect(item: ManageCegTableRow) {
    const activeSelectionSet = this.getActiveSelectionSet(item);
    activeSelectionSet.add(item.objectId);
  }
}
