import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, Router } from '@angular/router';
import { merge, Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { Configuration } from 'app/app.constants';
import { DrawerStackComponent } from '../components/containers/drawer-stack/drawer-stack.component';
import { DetailsDrawerBaseComponent } from '../components/containers/details-drawer-base';

@Injectable()
export class UnsavedDrawerChangesGuard implements CanDeactivate<DrawerStackComponent> {
  constructor(
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly router: Router,
    private readonly configuration: Configuration,
  ) { }

  canDeactivate(
    component: DrawerStackComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const activeDrawer = component.getActiveDrawer();
    if (!activeDrawer) {
      console.error('[Stack] No active drawer was found.');
    }

    const unsavedCustomFieldChanges = activeDrawer['hasCustomFieldChanges'] || activeDrawer.unsavedChangesFlag;
    const nextUrl = nextState.url.replace('/', '');
    if (nextUrl === this.configuration.ROUTING_CONSTANTS.LOGIN || (!activeDrawer.hasUnsavedChanges() && !unsavedCustomFieldChanges) || activeDrawer.isReadOnlyMode) {
      return true;
    }

    const saveFinished = new Subject<void>();
    const changesDiscarded = new Subject<void>();
    const changesAreNotValid = new Subject<void>();

    const closedOutsideStack = component.closedOutsideStack;
    let isValid = activeDrawer.validateChanges();
    isValid =  this._isDrawerStateValidForArtifactWithCFEnabled(activeDrawer, isValid);
    
    if (activeDrawer.drawerType != 'metric') {
      if (closedOutsideStack && !isValid) {
        component.closedOutsideStack = false;

        activeDrawer['validateCustomFormFields']?.();
        activeDrawer.highlightNotValidFields();
        changesAreNotValid.next();
      } else {
        this.dialogManager.openUnsavedChangesDialog(
          () => {
            if (isValid) {
              activeDrawer.saveChanges(() => saveFinished.next());
            } else {
              activeDrawer['validateCustomFormFields']?.();
              activeDrawer.highlightNotValidFields();
              changesAreNotValid.next();
            }
          },
          () => changesDiscarded.next()
        );
      }
    } else {
      if (!activeDrawer['isMetricFormValid']) {
        return true;
      } else {
        this.dialogManager.openUnsavedChangesDialog(
          () => {
            if (isValid) {
              activeDrawer.saveChanges(() => saveFinished.next());
            } else {
              activeDrawer.highlightNotValidFields();
              changesAreNotValid.next();
            }
          },
          () => changesDiscarded.next()
        );
      }
    }

    return merge(
      changesAreNotValid.pipe(map(() => false)),
      changesDiscarded.pipe(map(() => true)),
      saveFinished.pipe(map(() => true))
    ).pipe(
      first()
    );
  }

  private _isDrawerStateValidForArtifactWithCFEnabled(activeDrawer: DetailsDrawerBaseComponent<any>, existingValidity: boolean) {
    let customFieldsValidity = true;
    let customFieldsArtifactFlags = ['isCustomFieldsEnabledForCG', 'isCustomFieldsEnabledForProgram', 'isCustomFieldsEnabledForExpense'] as const;

    if (customFieldsArtifactFlags.some(flag => activeDrawer[flag])) {
      customFieldsValidity = activeDrawer['isCustomFieldsFormValid'];
    }

    return customFieldsValidity && existingValidity;
  }
}
