import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'formatBudget'
})
export class FormatBudgetPipe implements PipeTransform {

  transform(value: number, format = '1.2-10'): unknown {
    if (!value) {
      return '0';
    }
    const formatValue = (val) => formatNumber(val, 'en-US', format);
    return value > 0 ? formatValue(value) : '(' + formatValue(value * -1) + ')';
  }

}
