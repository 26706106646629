<nav class="object-hierarchy-nav">
  <ul class="hierarchy-nav-list">
    <ng-template ngFor let-objectType [ngForOf]="orderedTypes">
      <li *ngIf="data && data[objectType]"
        class="hierarchy-nav-item"
        [class.inactive]="!data[objectType].isActive"
        [class.closed]="data[objectType]?.mode === objectMode.Closed">
        <fa-duotone-icon size="lg"
          *ngIf="data[objectType]?.mode !== objectMode.Closed else closedIcon"
          [fixedWidth]="true" pull="left"
          [icon]="['fad', iconByObjectType[objectType].icon]"
          [flip]="iconByObjectType[objectType].flip && 'horizontal'">
        </fa-duotone-icon>
        <ng-template #closedIcon>
          <fa-duotone-icon [icon]="[ 'fad', 'rocket' ]"></fa-duotone-icon>
        </ng-template>
        <span *ngIf="!data[objectType].isActive; else navLink" class="nav-text">
          {{ data[objectType].name }}
        </span>
        <ng-template #navLink>
          <a class="nav-link" (click)="handleNameClick(objectType, data[objectType].id)">
            {{ data[objectType].name }}
          </a>
          <span class="separator">/</span>
        </ng-template>
      </li>
    </ng-template>
  </ul>
</nav>
