<div
  *ngIf="budgetColumns && timeframes"
  #tableContainer
  class="table-wrap"
  scrollOnDrag
  tableContentShadows
  [updateShadows$]="updateShadows$"
  (onShadowChanged)="handleShadowUpdate($event)"
  [ngClass]="{
    'is-loading': isLoading,
    'filtered-mode': isFilteredMode,
    'performance-table-wrap': performanceMode
  }"
>
    <table
      elementResizeObserver
      (onElementResize)="handleTableResize($event)"
      >
      <thead class="table-head">
        <tr class="table-row"
            manage-table-ceg-head-row
            [withFormula]="showColumnsFormula"
            [timeframes]="timeframes"
            [performanceMode]="performanceMode"
            [budgetMode]="budgetMode"
            [budgetColumns]="budgetColumns"
            [budgetColumnsVisibility]="budgetColumnsVisibility"
            [performanceColumns]="performanceColumns"
            [totalsSidebarState]="totalsSidebarState"
            [selectAllValue]="selectionState.selectAllValue"
            (onSelectAllChanged)="handleSelectAllChange($event)"
            (totalsSidebarStateChanged)="totalsSidebarStateChanged.emit($event)"
        ></tr>
        <tr class="shadow-tr">
          <td [colSpan]="timeframes.length + 2"></td>
        </tr>
      </thead>
      <tbody #tableBodyRef class="table-body" [ngClass]="{ 'element-selected': !!selectedRecords.length }">
        <ng-container
          *ngTemplateOutlet="recursiveRowTemplate; context: { $implicit: rows, level: 0 }"
        ></ng-container>
      </tbody>
      <tfoot>
        <tr
          class="grand-total-row"
          manage-table-ceg-foot-row
          [grandTotalBudget]="grandTotalBudget"
          [grandTotalPerformance]="grandTotalPerformance"
          [timeframes]="timeframes"
          [budgetMode]="budgetMode"
          [performanceMode]="performanceMode"
          [budgetColumns]="budgetColumns"
          [totalsSidebarState]="totalsSidebarState"
          [budgetColumnsVisibility]="budgetColumnsVisibility"
          [performanceColumns]="performanceColumns"
          [grandTotalForActiveTimeframes]="grandTotalForActiveTimeframes"
        >
        </tr>
      </tfoot>
    </table>
</div>

<ng-template #recursiveRowTemplate let-list let-level="level" let-parent="parent">
  <ng-container *ngFor="let record of list; index as i">
    <tr
      manage-table-ceg-row
      [timeframes]="timeframes"
      [isAdmin]="isAdmin"
      [newItemCreationActive]="newItemCreationActive"
      [budgetColumns]="budgetColumns"
      [budgetColumnsVisibility]="budgetColumnsVisibility"
      [performanceColumns]="performanceColumns"
      [performanceColumnData]="performanceColumnData[record.objectId]"
      [hasHiddenHierarchy]="hasHiddenHierarchy"
      [performanceMode]="performanceMode"
      [budgetMode]="budgetMode"
      [editPermission]="editPermission"
      [iconByRowType]="iconByRowType"
      [routeActionByRowType]="routeActionByRowType"
      [objectTypeNameMap]="objectTypeNameMap"
      [togglingState]="togglingState"
      [segments]="segments"
      [selectionState]="selectionState"
      [sharedCostRules]="sharedCostRules"
      [totalsSidebarState]="totalsSidebarState"
      [showDataCells]="cellsVisibilityState[record.id]"
      [freezeTotalsSidebarAnimations]="freezeTotalsSidebarAnimations"
      [record]="record"
      [allocations]="record.allocations"
      [presentationAllocations]="record.presentationAllocations"
      [level]="level"
      [parent]="parent"
      [segmentBreakdownConfig]="segmentBreakdownConfig"
      [contextMenuTargetId]="contextMenuTargetId"
      [selectedRecords]="selectedRecords"
      class="table-row"
      [className]="'level-' + level"
      [ngClass]="{
          'is-closed': record.isClosed,
          'filtered-out-row': (isFilteredMode && record.isFilteredOut),
          'show-hidden-hierarchy-line': record.hierarchyBreakLine,
          'is-selected': selectionState.records[record.id]?.value === SelectionValue.Active,
          'highlight': record.type === ManageTableRowType.SegmentGroup,
          'highlight-light': record.type === ManageTableRowType.Segment || record.type === ManageTableRowType.Goal,
          'drop-allow': this.droppableItem[record.objectId] === dropState.ALLOW && checkDragEntity(record.objectId),
          'drop-forbid': this.droppableItem[record.objectId] === dropState.FORBID && checkDragEntity(record.objectId)
        }"
      [attr.data-id]="record.id"
      [plDroppable]="!record.isClosed"
      [restrictedFromDrop]="dataValidationService.restrictedFromDrop"
      (createNewItemTemplate)="createNewItemTemplate(record, i)"
      (handlePerformanceClick)="handlePerformanceClick($event)"
      (handleToggleChange)="handleToggleChange(record, $event)"
      (selectionChange)="handleSelectionChange(record, $event)"
      (onNameClick)="handleNameClick(record)"
      (onEntityDragStart)="handleEntityOnDragStart(record)"
      (onEntityDragEnd)="handleEntityOnDragEnd()"
      (onAllocationChange)="handleAllocationChange($event)"
      (onSegmentAllocationChange)="handleSegmentAllocationChange($event)"
      (onDoubleClick)="handleDoubleClickAction($event)"
      (onDragStart)="handleDragStartAction($event)"
      (onDrop)="handleDropAction($event)"
      (dropOn)="handleEntityOnDrop(record)"
      (onDragEnd)="handleDragEndAction()"
      (createItemFromTemplate)="onCreateItemFromTemplate.emit($event)"
      (onOpenContextMenu)="openContextMenu($event)"
      (onShowHierarchyTooltip)="showHierarchyTooltip($event)"
      (onHideHierarchyTooltip)="hideHierarchyTooltip()"
      (dragenter)="handleDragOver($event, record)"
      (openExpenses)="openExpenses(record, $event?.timeframeId, $event?.withoutParent)"
      [id]="'record-row-' + i"
    ></tr>

    <ng-container *ngIf="record.loadedChildren?.length > 0 && (togglingState[record.id] || record.isFilteredOut)">
      <ng-container
        *ngTemplateOutlet="recursiveRowTemplate; context: { $implicit: record.loadedChildren, level: level + 1, parent: record }"
      ></ng-container>
      <tr *ngIf="record.children?.length && !record.allChildrenLoaded">
        <td *ngIf="record.loadedChildren.length !== 0">
          <div class="load-more-content" [class.read-only]="!editPermission">
            <button mat-flat-button
                    [matTooltip]="'Show more from ' + record.name"
                    [matTooltipDisabled]="record.name.length <= maxShowMoreButtonTextLength"
                    matTooltipPosition="above"
                    matTooltipClass="dark-theme-tooltip simple multi-line"
                    [ngStyle]="{ 'margin-left': (level + 1) * 26 + 'px' }"
                    (click)="loadNextChunk(record)">
              Show more from {{ record.name.length > maxShowMoreButtonTextLength ? (record.name | slice : 0 : maxShowMoreButtonTextLength) + '...' : record.name }}
            </button>
          </div>
        </td>
        <td [colSpan]="timeframes.length + 2"></td>
      </tr>
    </ng-container>
  </ng-container>

  <tr *ngIf="!parent && !allRootRowsLoaded">
    <td>
      <div class="load-more-content" [class.read-only]="!editPermission">
        <button mat-flat-button (click)="loadNextChunk(null)">Show more</button>
      </div>
    </td>
  </tr>
</ng-template>


