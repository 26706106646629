import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ExchangeRatesService } from './exchange-rates.service';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import {
  CurrencyHasRelationsNotificationComponent
} from './modals/currency-has-relations-notification/currency-has-relations-notification.component';
import {
  AddNewCurrencyNotificationComponent, NewCurrencyNotificationDialogContext
} from './modals/add-new-currency-notification/add-new-currency-notification.component';
import { ExchangeRatesTableComponent } from './exchange-rates-table/exchange-rates-table.component';
import { CurrencyService } from 'app/shared/services/backend/currency.service';
import { MasterExchangeRateService } from 'app/shared/services/backend/master-exchange-rate.service';
import { Configuration } from 'app/app.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { Validations } from 'app/app.validations';
import { Router } from '@angular/router';
import {
  ExchangeRatesTableData,
  ExchangeRatesTableChangeRateEvent,
  ExchangeRatesTableCurrency,
  ExchangeRatesTableRatePerMonth
} from './exchange-rates-table/exchange-rates-table.interface';
import { Currency } from 'app/shared/types/currency.interface';
import { switchMap, filter, tap, take, catchError } from 'rxjs/operators';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { AppDataLoader } from 'app/app-data-loader.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { AuditLogDO } from '@shared/services/backend/audit-log.service';
import { MatDialog } from '@angular/material/dialog';

const AUDIT_LOGS_CHUNK_SIZE = 10;

@Component({
  selector: 'exchange-rates',
  styleUrls: ['./exchange-rates.component.scss'],
  templateUrl: './exchange-rates.component.html',
  providers: [AppDataLoader]
})
export class ExchangeRatesComponent implements OnInit, OnDestroy {
  @ViewChild(ExchangeRatesTableComponent, { static: true }) exchangeRatesTable: ExchangeRatesTableComponent;

  private companyCurrencyCode;
  private selectedBudget: Budget = null;
  companyId: number;
  subscriptions = [];
  private auditLogsNextChunkExists = false;
  protected auditLogs: AuditLogDO[] = [];
  auditLogsChunkOffset = 0;
  exchangeRatesTableData: ExchangeRatesTableData = { currencies: [], months: [] };
  currencyList = [];
  baseCurrency: { [key: string]: string };

  constructor(
    private exchangeRatesService: ExchangeRatesService,
    private expensesService: ExpensesService,
    private currencyService: CurrencyService,
    private masterExchangeRateService: MasterExchangeRateService,
    public configuration: Configuration,
    public utilityService: UtilityService,
    public router: Router,
    public validations: Validations,
    private readonly companyDataService: CompanyDataService,
    private readonly appDataLoader: AppDataLoader,
    private readonly budgetDataService: BudgetDataService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadData();
    this.subscribeCurrencyList();
    this.appDataLoader.init();
  }

  private resetAuditLogsData() {
    this.auditLogsChunkOffset = 0;
    this.auditLogs = [];
    this.auditLogsNextChunkExists = false;
  }

  loadData() {
    const subscription =
      this.companyDataService.selectedCompany$
        .pipe(
          filter(cmp => cmp != null),
          tap(cmp => this.companyId = cmp.id),
          tap(() => this.resetAuditLogsData()),
          switchMap(() => this.loadBaseCurrency()),
          switchMap(() => this.loadAuditLogs()),
          switchMap(logs => {
            this.onAuditLogsLoaded(logs);
            this.onBaseCurrencyLoaded(this.companyCurrencyCode);
            return this.loadTableData(this.companyCurrencyCode);
          }),
          catchError(err => {
            this.utilityService.handleError(err);
            return throwError(() => err);
          })
        ).subscribe(
          tableData => {
            this.onTableDataLoaded(tableData);
            this.loadDailyRates();
            this.companyDataService.loadCompanyData(this.companyId);
          }
        );

    this.subscriptions.push(subscription);

    this.subscriptions.push(
      this.budgetDataService.selectedBudget$
        .pipe(
          tap(newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)),
        )
        .subscribe({
          error: (error) => this.utilityService.handleError(error)
        })
    );
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    this.selectedBudget = newSelectedBudget;
    if (newSelectedBudget != null) {
      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.campaignStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.programStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }
  }

  loadBaseCurrency(): Observable<CompanyDO> {
    return this.companyDataService.selectedCompanyDO$
      .pipe(
        filter(data => data !== null),
        take(1),
        tap(data => { this.companyCurrencyCode = data.currency; })
      );
  }

  loadDailyRates() {
    const { currencies } = this.exchangeRatesTableData;
    const currencyCodes = currencies.map((c: ExchangeRatesTableCurrency) => c.code);
    this.masterExchangeRateService.getDailyRates(this.baseCurrency.code, currencyCodes)
      .subscribe(dailyRatesData => this.onDailyRatesLoaded(dailyRatesData));
  }

  loadTableData(baseCurrencyCode: string) {
    return this.exchangeRatesService.getTableData(this.companyId, baseCurrencyCode);
  }

  loadAuditLogs(): Observable<AuditLogDO[]> {
    return this.exchangeRatesService.getLogs(
      AUDIT_LOGS_CHUNK_SIZE,
      this.auditLogsChunkOffset,
      this.companyId
    ).pipe(
      catchError(err => {
        this.utilityService.handleError(err);
        return throwError(() => err);
      })
    )
  }

  onAuditLogsLoaded(auditLogs: AuditLogDO[]) {
    this.auditLogsNextChunkExists = auditLogs.length === AUDIT_LOGS_CHUNK_SIZE;
    this.auditLogsChunkOffset += AUDIT_LOGS_CHUNK_SIZE;
    this.auditLogs.push(...auditLogs);
  }

  onBaseCurrencyLoaded(value: string) {
    this.baseCurrency = this.currencyList.find(item => item.code === value);
  }

  onTableDataLoaded(data: ExchangeRatesTableData) {
    this.exchangeRatesTableData = data;
  }

  onDailyRatesLoaded(dailyRatesData) {
    if (!this.baseCurrency) {
      return;
    }

    const dailyRates = dailyRatesData && dailyRatesData.rates;
    const { currencies } = this.exchangeRatesTableData;
    currencies.forEach((currency: ExchangeRatesTableCurrency) => {
      currency.dailyRate = dailyRates ? dailyRates[`${currency.code}>${this.baseCurrency.code}`] : ''
    });
  }

  handleLoadNewChunk() {
    if (this.auditLogsNextChunkExists) {
      this.loadAuditLogs().subscribe(data => {
        this.onAuditLogsLoaded(data);
      })
    }
  }

  subscribeCurrencyList() {
    const subscription = this.currencyService.currencyList$.subscribe(list => {
      this.currencyList = list;
    });
    this.subscriptions.push(subscription);
  }

  handleChangeRate(changeRateData: ExchangeRatesTableChangeRateEvent) {
    this.exchangeRatesService.updateRate(changeRateData, this.companyId)
      .subscribe(response => {
        this.updateTableAfterChangeRate(response);
        this.updateAuditLogs();
      });
  }

  updateTableAfterChangeRate(response: any) {
    const { currency, rates } = response;
    const { currencies, months } = this.exchangeRatesTableData;
    const currencyToUpdate = currencies.find(c => c.code === currency);
    if (!currencyToUpdate) {
      return;
    }

    currencyToUpdate.ratesPerMonth = this.exchangeRatesService.prepareRatesPerMonth(months, new Date(), false, rates);
  }

  handleAddCurrency(currencyCode: string) {
    this.utilityService.showLoading(true);
    this.exchangeRatesTable.handleAddCurrencyCancel();
    this.masterExchangeRateService.getDailyRates(this.baseCurrency.code, [currencyCode])
      .pipe(switchMap(dailyRatesData => {
        const dailyRates = dailyRatesData && dailyRatesData.rates;
        const rate = dailyRates ? dailyRates[`${currencyCode}>${this.baseCurrency.code}`] : 1;

        return this.exchangeRatesService.addCurrency(this.companyId, currencyCode, rate, this.exchangeRatesTableData.months);
      }))
      .subscribe(responseData => {
        this.processAddCurrencyResponse(responseData);
        this.updateAuditLogs();
        this.utilityService.showLoading(false);
      });
  }

  processAddCurrencyResponse(responseData: any) {
    const [currency, currencyRates, dailyRate] = responseData;
    const { id, currency: currencyCode, is_new: isNew } = currency;
    const { months } = this.exchangeRatesTableData;
    const allCurrencies = this.currencyService.currencyList$.getValue();
    const currencyData = allCurrencies.find(c => c.code === currencyCode);
    const ratesPerMonth = this.exchangeRatesService.prepareRatesPerMonth(months, new Date(), false, currencyRates.rates);

    this.updateTableAfterAddCurrency(currencyData, dailyRate, id, currencyRates.id, isNew, ratesPerMonth);
    this.showAddNewCurrencyNotificationModal(currencyData.name, dailyRate);
  }

  updateTableAfterAddCurrency(
    currencyData: Currency,
    dailyRate: number,
    id: number,
    rateId: number,
    isNew: boolean,
    ratesPerMonth: ExchangeRatesTableRatePerMonth[]
  ) {
    const newCurrencyObj: ExchangeRatesTableCurrency = {
      code: currencyData.code,
      dailyRate,
      id,
      isBase: false,
      isNew,
      isEditMode: false,
      name: currencyData.name,
      rateId: rateId,
      ratesPerMonth,
      symbol: currencyData.symbol
    };

    this.exchangeRatesTableData.currencies.push(newCurrencyObj);
    this.exchangeRatesTableData.currencies.sort(this.exchangeRatesService.compareCurrencies);
  }

  handleRemoveCurrency(currency: ExchangeRatesTableCurrency) {
    const shouldProceedRemovingCurrency = confirm(this.validations.ValiditionMessages.CONFIRM_REMOVING_COMPANY_CURRENCY);
    if (!shouldProceedRemovingCurrency || !this.selectedBudget) {
      return;
    }

    this.expensesService.getExpenses({
      company: this.companyId,
      budget: this.selectedBudget.id,
      source_currency: currency.code,
      limit: 1,
      status: this.configuration.expenseStatusNames.active
    })
    .subscribe(
      (data) => {
        this.processCompanyCurrencyExpensesAmount(data && data.length, currency);
      },
      (err) => this.utilityService.handleError(err)
    );
  }

  processCompanyCurrencyExpensesAmount(expensesAmount: number, currency: ExchangeRatesTableCurrency) {
    if (expensesAmount) {
      this.showCurrencyHasRelationsNotificationModal();
    } else {
      const { id, rateId } = currency;
      this.utilityService.showLoading(true);
      this.exchangeRatesService.removeCurrency(id, rateId)
        .subscribe(() => {
          this.updateTableAfterRemoveCurrency(id);
          this.updateAuditLogs();
          this.utilityService.showLoading(false);
        });
    }
  }

  updateTableAfterRemoveCurrency(currencyId: number) {
    const { currencies } = this.exchangeRatesTableData;
    this.exchangeRatesTableData.currencies = currencies.filter((c: ExchangeRatesTableCurrency) => c.id !== currencyId);
  }

  updateAuditLogs() {
    this.resetAuditLogsData();
    this.loadAuditLogs().subscribe(logs => this.onAuditLogsLoaded(logs));
  }

  showCurrencyHasRelationsNotificationModal(): void {
    this.dialog.open(CurrencyHasRelationsNotificationComponent, {
      width: '600px',
      autoFocus: false
    });
  }

  showAddNewCurrencyNotificationModal(currencyName: string, currencyRate: number): void {
    if (localStorage.getItem(this.configuration.DISABLE_SHOWING_ADD_NEW_CURRENCY_NOTIFICATION)) {
      return;
    }

    const dialogContext: NewCurrencyNotificationDialogContext = { currencyName, currencyRate };
    this.dialog.open(AddNewCurrencyNotificationComponent, {
      width: '600px',
      data: dialogContext,
      autoFocus: false
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
