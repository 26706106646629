<div class="metric-target-progress">
  <div class="header">
    <details-widget-title [hideIcon]="hideIcon" [text]="sectionTitle" [showCount]="false" [secondaryText]="lastUpdatedInfo" [type]="type" [isProgressTargetWidget]="type === 'metric-drawer-charts'">
      <fa-duotone-icon slot="icon" [icon]="['fad', 'analytics']"></fa-duotone-icon>
    </details-widget-title>
    <span class="toggle-view">
      <button mat-icon-button (click)="toggleView()" >
        <fa-duotone-icon *ngIf="!chartViewActive" [icon]="['fad', 'analytics']"></fa-duotone-icon>
        <fa-duotone-icon *ngIf="chartViewActive" [icon]="['fad', 'table']"></fa-duotone-icon>
      </button>
    </span>
  </div>
  <div class="chart-view" [class.is-active]="chartViewActive">
    <ng-content select="[chart]"></ng-content>
  </div>
  <div *ngIf="!chartViewActive" class="table-view">
    <ng-content select="[table]"></ng-content>
  </div>
</div>
