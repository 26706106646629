import { inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Configuration } from '../../app.constants';
import { TabSwitchOption } from '@shared/components/tab-switch/tab-switch.types';
import {
  ManageCegBudgetMode,
  ManageCegDataMode,
  ManageCegViewModeChange,
  ManageCegViewMode,
  TotalsSidebarState,
  ManageCegPageModes
} from '@manage-ceg/types/manage-ceg-page.types';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { CEG_TIMEFRAME_CONFIG } from '@shared/constants/modes.constant';
import { SpendingModeFlag, SpendingModeFlags } from '../../manage-table/types/spending-mode-flags.type';
import { AllocationModeFlag, AllocationModeFlags } from '../../manage-table/types/allocation-mode-flags.type';

interface StorageConfig {
  budgetId: number;
  mode: BudgetTimeframesType;
  ids: (string | number)[];
}

@Injectable()
export class ManageCegPageModeService implements OnDestroy {
  private readonly configuration = inject(Configuration);
  private readonly activatedRoute = inject(ActivatedRoute);

  private _dataModeOptions: TabSwitchOption[] = [];

  private _dataMode: ManageCegDataMode;
  public _viewMode: ManageCegViewMode;
  private _budgetDisplayMode: ManageCegBudgetMode = ManageCegBudgetMode.Table;
  private _totalsSidebarState = TotalsSidebarState.Hidden;
  private readonly _presentationTimeframeMode$ = new BehaviorSubject<BudgetTimeframesType>(null);
  private readonly _presentationTimeframeIds$ = new BehaviorSubject<(string | number)[]>(null);
  private readonly _viewModeChange = new BehaviorSubject<ManageCegViewModeChange>(null);
  private readonly _showDataModeSwitch = new BehaviorSubject<boolean>(false);

  private readonly _pageModes = new BehaviorSubject<ManageCegPageModes>(null);

  public readonly viewModeChange = this._viewModeChange.asObservable();
  public readonly pageModes$ = this._pageModes.asObservable();
  public readonly showDataModeSwitch$ = this._showDataModeSwitch.asObservable();

  public presentationTimeframeMode$ = this._presentationTimeframeMode$.asObservable().pipe(
    filter(mode => !!mode)
  );
  public presentationTimeframeIds$ = this._presentationTimeframeIds$.asObservable().pipe(
    filter(value => !!value)
  );

  private _presentationTimeframeOptions: Record<BudgetTimeframesType, TabSwitchOption> = {
    [BudgetTimeframesType.Month]: { title: 'M', value: BudgetTimeframesType.Month, hidden: false, tooltip: 'Month' },
    [BudgetTimeframesType.Quarter]: { title: 'Q', value: BudgetTimeframesType.Quarter, hidden: false, tooltip: 'Quarter' },
    [BudgetTimeframesType.Year]: { title: 'Y', value: BudgetTimeframesType.Year, hidden: false, tooltip: 'Year' },
  };
  public presentationTimeframeOptions: TabSwitchOption[];

  private readonly destroy$ = new Subject<void>();
  private _spendingModeFlags: SpendingModeFlags = {
    [SpendingModeFlag.PlannedExpensesInTotal]: true,
    [SpendingModeFlag.ExpensesAsTotal]: false,
    [SpendingModeFlag.Formula]: false
  };
  private _allocationModeFlags: AllocationModeFlags = {
    [AllocationModeFlag.Formula]: false,
    [AllocationModeFlag.Expenses]: false
  };

  constructor() {
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.applyRouteParamChange(params);
        this._dataModeOptions = [
          {
            title: 'Budget',
            value: ManageCegDataMode.Budget,
            icon: ['fas', 'table'],
            disabled: false
          },
          {
            title: 'Performance',
            value: ManageCegDataMode.Performance,
            icon: ['fas', 'chart-line'],
            disabled: this.isSegmentViewMode
          }
        ];
      });
  }

  get dataModeOptions(): TabSwitchOption[] {
    return this._dataModeOptions;
  }

  get presentationTimeframeMode(): BudgetTimeframesType {
    return this._presentationTimeframeMode$.getValue();
  }

  get dataMode(): ManageCegDataMode {
    return this._dataMode;
  }

  get viewMode(): ManageCegViewMode {
    return this._viewMode;
  }

  get viewModeChangeValue(): ManageCegViewModeChange {
    return this._viewModeChange.getValue();
  }

  get budgetDisplayMode(): ManageCegBudgetMode {
    return this._budgetDisplayMode;
  }

  set dataMode(mode: ManageCegDataMode) {
    let viewModeChange: ManageCegViewModeChange;
    if (this._totalsSidebarState !== TotalsSidebarState.Hidden) {
      this.totalsSidebarState = TotalsSidebarState.Hidden;
    }
    this._dataMode = mode;
    if (this._dataMode === ManageCegDataMode.Performance) {
      this.budgetDisplayMode = ManageCegBudgetMode.Table;
      if (this.isSegmentViewMode) {
        viewModeChange = this.setViewMode(ManageCegViewMode.Goals);
      }
    }
    this.updatePageUrl();
    this._pageModes.next({ dataMode: this.dataMode, viewModeChange: viewModeChange || this._pageModes.value?.viewModeChange });
  }

  set showDataModeSwitch(isShow: boolean) {
    this._showDataModeSwitch.next(isShow);
    if (!isShow && this.dataMode !== ManageCegDataMode.Budget) {
      this.dataMode = ManageCegDataMode.Budget;
    }
  }

  set viewMode(mode: ManageCegViewMode) {
    const viewModeChange = this.setViewMode(mode);
    this._viewModeChange.next(viewModeChange);
    this._dataModeOptions =
      this._dataModeOptions.map((option, index) => index === 1 ? { ...option, disabled: this.isSegmentViewMode } : option);
    this._pageModes.next({ viewModeChange, dataMode: this._dataMode });
  }

  private setViewMode(mode: ManageCegViewMode): ManageCegViewModeChange {
    const prevMode = this._viewMode;
    this._viewMode = mode;
    this.updatePageUrl();
    return  { prevValue: prevMode, value: this._viewMode };
  }

  set budgetDisplayMode(mode: ManageCegBudgetMode) {
    this._budgetDisplayMode = mode;
  }

  get totalsSidebarState(): TotalsSidebarState {
    return this._totalsSidebarState;
  }

  set totalsSidebarState(state: TotalsSidebarState) {
    this._totalsSidebarState = state;
  }

  get storageTimeframeConfig(): StorageConfig {
    return LocalStorageService.getFromStorage(CEG_TIMEFRAME_CONFIG);
  }

  public get spendingModeFlags(): SpendingModeFlags {
    return this._spendingModeFlags;
  }

  public get allocationModeFlags(): AllocationModeFlags {
    return this._allocationModeFlags;
  }

  public updatePresentationTimeframeOptions(budgetMode: BudgetTimeframesType): void {
    this._presentationTimeframeOptions[BudgetTimeframesType.Month].hidden = budgetMode !== BudgetTimeframesType.Month;
    this._presentationTimeframeOptions[BudgetTimeframesType.Quarter].hidden = budgetMode === BudgetTimeframesType.Year;

    this.presentationTimeframeOptions = Object.values(this._presentationTimeframeOptions).filter(option => !option.hidden);
  }

  public setPresentationTimeframeMode(timeframeMode: BudgetTimeframesType, defaultIds: (string | number)[]): void {
    this._presentationTimeframeMode$.next(timeframeMode);
    this.setPresentationTimeframeIds(defaultIds);
  }

  public setPresentationTimeframeIds(ids: (string | number)[]): void {
    this._presentationTimeframeIds$.next(ids);
  }

  public saveTimeframeMode(budgetId: number): void {
    LocalStorageService.addToStorage<StorageConfig>(CEG_TIMEFRAME_CONFIG, {
      budgetId,
      mode: this._presentationTimeframeMode$.getValue(),
      ids: this._presentationTimeframeIds$.getValue()
    });
  }

  public removeTimeframeConfig(): void {
    LocalStorageService.removeFromStorage(CEG_TIMEFRAME_CONFIG);
  }

  public getCurrentLocation(withQueryParams: boolean): string {
    const queryParams = withQueryParams ? window.location.search : '';
    return `${this.configuration.ROUTING_CONSTANTS.MANAGE_CEG_PAGE}/${this.dataMode}/${this.viewMode}${queryParams}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updatePageUrl(): void {
    const url = this.getCurrentLocation(true);
    history.pushState({}, '', url);
  }

  private applyRouteParamChange(params: ParamMap): void {
    const viewModeParam = params.get('viewMode') || '';
    const dataModeParam = params.get('dataMode') || '';

    this._dataMode = this.getManageTableDataMode(dataModeParam);
    const prevMode = this._viewMode;
    this._viewMode = this.getManageTableViewMode(viewModeParam);
    const viewModeChange = { prevValue: prevMode, value: this._viewMode };
    this._viewModeChange.next(viewModeChange);
    this._pageModes.next({ viewModeChange, dataMode: this._dataMode });
  }

  private getManageTableDataMode(dataMode: string): ManageCegDataMode {
    return Object.values(ManageCegDataMode).includes(dataMode as ManageCegDataMode)
      ? dataMode as ManageCegDataMode
      : ManageCegDataMode.Budget;
  }

  private getManageTableViewMode(viewMode: string): ManageCegViewMode {
    return Object.values(ManageCegViewMode).includes(viewMode as ManageCegViewMode)
      ? viewMode as ManageCegViewMode
      : ManageCegViewMode.Segments;
  }

  private get isSegmentViewMode(): boolean {
    return this.viewMode === ManageCegViewMode.Segments;
  }
}
