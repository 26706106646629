export interface TaskDO {
  id: number;
  name: string;
  owner: number;
  company?: number;
  due_date: string;
  status: string;
  campaign: number;
  campaign_name: string;
  program: number;
  program_name: string;
}

export enum TaskStatusName {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  BLOCKED = 'blocked',
  LATE = 'late',
  POSTPONED = 'postponed',
  CANCELED = 'canceled',
}

export interface TaskStatus {
  id: number;
  name: TaskStatusName;
}

export interface Task {
  id?: number;
  name: string;
  owner: {
    id: number;
    name: string;
  };
  campaign?: {
    id: number;
    name?: string;
  };
  program?: {
    id: number;
    name?: string;
  };
  dueDate: Date;
  status: TaskStatus;
  isIncoming?: boolean;
  isLate?: boolean;
  daysLeft?: number;
}

