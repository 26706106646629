import { inject, Injectable } from '@angular/core';
import { ManageTableRow } from '../../components/manage-table/manage-table.types';
import { ManageTableDataService } from '../manage-table-data.service';
import { TableData } from '../manage-page.service';
import { SegmentExpensesData } from '@shared/types/plan-object-expenses-data.type';
import { ManageTableSpendingHelpers } from '../manage-table-spending-helpers';
import { Budget } from '@shared/types/budget.interface';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { ObjectDataApplier } from './object-data-applier.interface';
import { ManageTableHelpers } from '../manage-table-helpers';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Injectable()
export class ManageTableRowDataManager {
  private readonly tableDataService = inject(ManageTableDataService);

  public applyObjectDataToRow<T>(
    row: ManageTableRow,
    dataApplier: ObjectDataApplier<T>,
    segmentExpenses: SegmentExpensesData,
    budget: Budget,
    filteredTimeframes: BudgetTimeframe[],
    contextData: TableData
  ): void {
    dataApplier.applyObjectData(row);
    row.isOwnDataReady = true;

    if (!row.children?.length) {
      row.isChildDataReady = true;
    }

   if (!row.isChildDataReady) {
     return;
   }

   // All data - own and child - is ready. Calc self and notify parent.
    this.calculateRow(row, contextData, segmentExpenses, budget, filteredTimeframes);
    this.updateParentRow(row, contextData, segmentExpenses, budget, filteredTimeframes);
  }

  public updateParentRow(
    sourceRow: ManageTableRow,
    contextData: TableData,
    segmentExpenses: SegmentExpensesData,
    budget: Budget,
    filteredTimeframes: BudgetTimeframe[]
  ): void {
    const parentRow = this.tableDataService.getRecordById(sourceRow.parentId);
    if (!parentRow) {
      return;
    }

    parentRow.isChildDataReady = parentRow.children.every(childRow => childRow.isOwnDataReady && childRow.isChildDataReady);

    if (parentRow.isChildDataReady && parentRow.isOwnDataReady) {
      this.calculateRow(parentRow, contextData, segmentExpenses, budget, filteredTimeframes);
      this.updateParentRow(parentRow, contextData, segmentExpenses, budget, filteredTimeframes);
    }
  }

  private calculateRow(
    row: ManageTableRow,
    contextData: TableData,
    segmentExpenses: SegmentExpensesData,
    budget: Budget,
    filteredTimeframes: BudgetTimeframe[]
  ): void {
    if (row.type === ManageTableRowType.Campaign || row.type === ManageTableRowType.ExpenseGroup) {
      const allocatedToChildren = ManageTableSpendingHelpers.getAllocatedToChildren(row);
      row.spending =
        ManageTableSpendingHelpers.calcSpendingValues(
          row.spending,
          row.total.allocated,
          allocatedToChildren,
          row.total.remainingAllocated
        );
    } else {
      const { values, total, spending } = ManageTableHelpers.sumUpChildValues(row);
      row.values = values;
      row.total = total;
      row.spending = spending;

      if (row.type === ManageTableRowType.Segment) {
        ManageTableHelpers.calcBreakdownValues(
          row,
          contextData.budgetSegments.find(bs => bs.id === row.objectId),
          segmentExpenses[row.objectId],
          budget.suppress_timeframe_allocations,
          filteredTimeframes
        );
      } else if (row.type === ManageTableRowType.SegmentGroup) {
        const { segment, unallocated } = ManageTableHelpers.sumUpChildBreakdownValues(row);
        row.segment = segment;
        row.unallocated = unallocated;
        ManageTableSpendingHelpers.syncSegmentSpending(row);
      }
    }
  }
}
