<div>
  <div
    class="floating-notes-panel"
    *ngIf="selectedMetricNotesInput"
    [ngStyle]="{ top: metricUpdateNotesPosition }"
  >
    <div class="notes-container">
      <textarea
        class="notes-textarea"
        textFieldAutosize
        minRows="2"
        [(ngModel)]="selectedMetricNotesInput.notes"
        blurOnEnterKeydown
        [minHeight]="50"
        [maxHeight]="50"
        maxlength="1000"
      ></textarea>
      <p class="notes-length-text">
        {{ selectedMetricNotesInput?.notes?.length }} / 1000
      </p>
    </div>
    <div class="notes-button-container">
      <button
        class="notes-popup-buttons"
        (click)="closeNotesWitoutSaving()"
        mat-button
        color="primary"
      >
        Cancel
      </button>
      <button
        class="notes-popup-buttons"
        (click)="saveUpdateMetricNotes()"
        mat-flat-button
        color="primary"
      >
        Save
      </button>
    </div>
  </div>
  <div class="details-panel" [ngClass]="{ 'update-history-goal': currentMetricState?.mappingType === configuration.OBJECT_TYPES.goal }">
    <span
      class="update-form-panel"
      *ngIf="
        currentMetricState?.mappingType === configuration.OBJECT_TYPES.campaign
      "
    >
      <div class="details-form-field update-form-field">
        <pfm-datepicker
          [maxDate]="budgetTodayDate"
          iconType="fas"
          size="small"
          icon="chart-line"
          [label]="'Date'"
          (valueChange)="onHandleStartDateChange($event)"
          [value]="newMetricUpdateDate"
          [disabledDates]="datepickerDisabledDates"
        ></pfm-datepicker>
      </div>
      <div class="details-form-field update-form-field">
        <mat-form-field
          *ngIf="isChangeInValueFocused"
          appearance="outline"
          class="p-form-field-outline"
        >
          <mat-label class="pfm-label">Change in value</mat-label>
          <input
            class="number-font"
            *ngIf="isChangeInValueFocused"
            blurOnEnterKeydown
            [selectTextOnFocus]="true"
            matInput
            currencyInput
            currencyMask
            [options]="newAmountFieldOpt"
            placeholder="-"
            [(ngModel)]="changeInValue"
            (ngModelChange)="setChangeInValue($event)"
            id="changeInValueInput"
          />
        </mat-form-field>
        <mat-form-field
          *ngIf="!isChangeInValueFocused"
          appearance="outline"
          class="p-form-field-outline"
        >
          <mat-label class="pfm-label">Change in value</mat-label>
          <input
            class="number-font"
            blurOnEnterKeydown
            [selectTextOnFocus]="true"
            matInput
            (focus)="onFocusChange('changeInValue')"
            [options]="newAmountFieldOpt"
            placeholder="-"
          />
        </mat-form-field>
      </div>
      <div class="details-form-field update-form-field running-total-value-field">
        <mat-form-field
          *ngIf="isTotalFocused"
          appearance="outline"
          class="p-form-field-outline"
        >
          <mat-label class="pfm-label">Total</mat-label>
          <input
            class="number-font"
            blurOnEnterKeydown
            [selectTextOnFocus]="true"
            matInput
            currencyInput
            currencyMask
            [options]="newAmountFieldOpt"
            placeholder="-"
            [(ngModel)]="runningTotal"
            (ngModelChange)="setRunningTotalValue($event)"
            id="totalValueInput"
          />
        </mat-form-field>
        <mat-form-field
          *ngIf="!isTotalFocused"
          appearance="outline"
          class="p-form-field-outline"
        >
          <mat-label class="pfm-label">Total</mat-label>
          <input
            class="number-font"
            *ngIf="!isTotalFocused"
            blurOnEnterKeydown
            [selectTextOnFocus]="true"
            matInput
            (focus)="onFocusChange('totalValue')"
            [options]="newAmountFieldOpt"
            placeholder="-"
          />
        </mat-form-field>
      </div>
      <div class="enter-btn-container">
        <button
          class="enter-btn"
          [disabled]="!toggleCanEnterRecord()"
          mat-flat-button
          color="primary"
          (click)="enterNewRecord()"
        >
          Enter
        </button>
      </div>
    </span>
  </div>
  <div class="details-panel">
    <h2 class="panel-floating-title">History</h2>
    <div
      class="history-accordion-container"
      *ngIf="currentMetricState?.metricValueUpdates"
    >
      <div class="history-accordion-header">
        <span></span>
        <span>Date</span>
        <span class="align-right">Change in value</span>
        <span class="align-right">Running total</span>
        <span>Updated on</span>
        <span></span>
      </div>
      <mat-accordion [multi]="true">
        <mat-expansion-panel
          class="new-accordion-expansion-panel"
          #monthRoot
          hideToggle
          *ngFor="let item of editorStructuredData; let parentIndex = index"
        >
          <mat-expansion-panel-header
            *ngIf="item.monthChildItems.length > 0"
            class="new-accordion-expansion-panel-header"
          >
            <span class="p-l-12 align-left">
              <fa-icon
                [icon]="['far', 'chevron-down']"
                [flip]="monthRoot.expanded && 'vertical'"
              ></fa-icon>
            </span>
            <span class="align-left metric-value-cell metric-header-month-title"
              >{{ item.date | date : "MMMM" }} Total</span
            >
            <span
              class="align-right metric-value-cell"
              [class.isNegativeValue]="item.changeInValue < 0"
            >
              <span
                class="value-overflow"
                *ngIf="formData.controls.metricUnit.value === 'Quality'"
              >
                {{ item.changeInValue | number : "1.2-5" }}
              </span>
              <span
                class="value-overflow"
                *ngIf="formData.controls.metricUnit.value != 'Quality'"
              >
                {{ item.changeInValue | number }}
              </span>
            </span>
            <span
              class="align-right metric-value-cell"
              [class.isNegativeValue]="item.runningTotal < 0"
            >
              <span
                class="value-overflow"
                *ngIf="formData.controls.metricUnit.value === 'Quality'"
              >
                {{ item.runningTotal | number : "1.2-5" }}
              </span>
              <span
                class="value-overflow"
                *ngIf="formData.controls.metricUnit.value != 'Quality'"
              >
                {{ item.runningTotal | number }}
              </span>
            </span>
            <span class="updated-date">{{
              item.updatedDate | date : "dd MMM YYYY"
            }}</span>
            <span></span>
          </mat-expansion-panel-header>
          <div
            class="accordion-values"
            (click)="
              toggleMonthEditMode(
                $event,
                childMetricItem,
                parentIndex,
                childIndex
              )
            "
            *ngFor="
              let childMetricItem of item?.monthChildItems;
              let childIndex = index
            "
          >
            <span class="metric-icon p-l-12 align-left">
              <fa-icon
                *ngIf="checkFaExpenseIcon(childMetricItem.type)"
                [icon]="getExpenseIcon(childMetricItem.type)"
              ></fa-icon>
              <icon-facebook
                *ngIf="
                  childMetricItem.type === MetricValueUpdateType.facebookAds
                "
                [isMonoChrome]="false"
              ></icon-facebook>
              <icon-linkedin
                *ngIf="
                  childMetricItem.type === MetricValueUpdateType.linkedinAds
                "
                [isMonoChrome]="false"
              ></icon-linkedin>
              <icon-google-ads
                *ngIf="childMetricItem.type === MetricValueUpdateType.googleAds"
                [isMonoChrome]="false"
              ></icon-google-ads>
              <icon-salesforce
                *ngIf="
                  childMetricItem.type === MetricValueUpdateType.salesForce
                "
                [isMonoChrome]="false"
              ></icon-salesforce>
            </span>
            <span
              class="metric-update-edit-fields"
              *ngIf="isEditingRow[parentIndex] === childIndex"
            >
              <pfm-datepicker
                [disabled]="true"
                iconType="fas"
                icon="chart-line"
                [withIcon]="false"
                [label]="''"
                size="small"
                (valueChange)="onHandleRecordDateChange($event)"
                [value]="childMetricItem.date"
              ></pfm-datepicker>
            </span>
            <span
              class="metric-update-edit-fields"
              *ngIf="isEditingRow[parentIndex] === childIndex"
            >
              <input
                id="change-in-value-edit-input"
                class="metric-history-input"
                [class.isNegativeValue]="childMetricItem.changeInValue < 0"
                blurOnEnterKeydown
                matInput
                currencyInput
                currencyMask
                [selectTextOnFocus]="true"
                (click)="onValueEditInputClick($event)"
                [options]="newAmountFieldOpt"
                [(ngModel)]="childMetricItem.changeInValue"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="
                  onRecordChangeInValue(
                    childMetricItem,
                    parentIndex,
                    childIndex
                  )
                "
              />
            </span>
            <span
              class="metric-update-edit-fields"
              *ngIf="isEditingRow[parentIndex] === childIndex"
            >
              <input
                id="running-total-edit-input"
                class="metric-history-input"
                [class.isNegativeValue]="childMetricItem.runningTotal < 0"
                blurOnEnterKeydown
                matInput
                currencyInput
                currencyMask
                (keydown.Tab)="removeMonthEditMode()"
                (click)="onValueEditInputClick($event)"
                [selectTextOnFocus]="true"
                [options]="newAmountFieldOpt"
                [(ngModel)]="childMetricItem.runningTotal"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="onRecordChangeRunningTotal(childMetricItem)"
              />
            </span>
            <span
              class="align-left metric-value-cell date-static-value"
              [class.metric-value-cell-on-hover]="
                isRecordEditable(childMetricItem)
              "
              *ngIf="isEditingRow[parentIndex] != childIndex"
            >
              {{ childMetricItem.date | date : "MMM d, YYYY" }}
            </span>
            <span
              (click)="onValueClicked('change-in-value-edit-input')"
              class="align-right metric-value-cell"
              [class.metric-value-cell-on-hover]="
                isRecordEditable(childMetricItem)
              "
              [class.isNegativeValue]="childMetricItem.changeInValue < 0"
              *ngIf="isEditingRow[parentIndex] != childIndex"
            >
              <span
                class="value-overflow"
                *ngIf="formData.controls.metricUnit.value === 'Quality'"
              >
                {{ childMetricItem.changeInValue | number : "1.2-5" }}
              </span>
              <span
                class="value-overflow"
                *ngIf="formData.controls.metricUnit.value != 'Quality'"
              >
                {{ childMetricItem.changeInValue | number }}
              </span>
            </span>
            <span
              (click)="onValueClicked('running-total-edit-input')"
              class="align-right metric-value-cell"
              [class.metric-value-cell-on-hover]="
                isRecordEditable(childMetricItem)
              "
              [class.isNegativeValue]="childMetricItem.runningTotal < 0"
              *ngIf="isEditingRow[parentIndex] != childIndex"
            >
              <span
                class="value-overflow"
                *ngIf="formData.controls.metricUnit.value === 'Quality'"
              >
                {{ childMetricItem.runningTotal | number : "1.2-5" }}
              </span>
              <span
                class="value-overflow"
                *ngIf="formData.controls.metricUnit.value != 'Quality'"
              >
                {{ childMetricItem.runningTotal | number }}
              </span>
            </span>
            <span class="updated-date">
              {{ childMetricItem.updatedDate | date : "dd MMM YYYY" }}
            </span>
            <span class="metric-icon metric-actions">
              <span>
                <button
                  *ngIf="
                    childMetricItem.type === MetricValueUpdateType.campaign &&
                    !isReadOnlyMode &&
                    childMetricItem.notes
                  "
                  mat-icon-button
                  (click)="
                    toggleNotesInput(
                      $event,
                      childMetricItem,
                      parentIndex,
                      childIndex
                    )
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    width="18"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H288V368c0-26.5 21.5-48 48-48H448V96c0-35.3-28.7-64-64-64H64zM448 352H402.7 336c-8.8 0-16 7.2-16 16v66.7V480l32-32 64-64 32-32z"
                      fill="#0063B7"
                    />
                  </svg>
                </button>
                <button
                  class="show-icon-on-hover"
                  *ngIf="
                    childMetricItem.type === MetricValueUpdateType.campaign &&
                    !isReadOnlyMode &&
                    !childMetricItem.notes
                  "
                  mat-icon-button
                  (click)="
                    toggleNotesInput(
                      $event,
                      childMetricItem,
                      parentIndex,
                      childIndex
                    )
                  "
                >
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 2.625C2.64844 2.625 2.375 2.9375 2.375 3.25V15.75C2.375 16.1016 2.64844 16.375 3 16.375H11.75V13.25C11.75 12.5859 12.2969 12 13 12H16.125V3.25C16.125 2.9375 15.8125 2.625 15.5 2.625H3ZM11.75 18.25H3C1.59375 18.25 0.5 17.1562 0.5 15.75V3.25C0.5 1.88281 1.59375 0.75 3 0.75H15.5C16.8672 0.75 18 1.88281 18 3.25V12V12.2344C18 12.8984 17.7266 13.5234 17.2578 13.9922L13.7031 17.5469C13.2344 18.0156 12.6094 18.25 11.9453 18.25H11.75Z"
                      fill="#0063B7"
                    />
                  </svg>
                </button>
              </span>
              <span>
                <button
                  mat-icon-button
                  (click)="
                    deleteMetricRecord(
                      $event,
                      childMetricItem,
                      parentIndex,
                      childIndex
                    )
                  "
                  class="delete-icon show-icon-on-hover"
                  *ngIf="childMetricItem.isRemovable && !isReadOnlyMode"
                >
                  <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                </button>
              </span>
            </span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
