import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { ManageCegPageComponent } from './components/manage-ceg-page/manage-ceg-page.component';
import { BudgetCegTimelineContainerComponent } from './components/budget-ceg-timeline-container/budget-ceg-timeline-container.component';
import { BudgetChartActionsMenuComponent } from './components/budget-chart-actions-menu/budget-chart-actions-menu.component';
import { ManageCegTableContainerComponent } from './components/manage-ceg-table-container/manage-ceg-table-container.component';
import { TableModeSwitchComponent } from './components/table-mode-switch/table-mode-switch.component';
import { ManageTableCegComponent } from '@manage-ceg/components/manage-table-ceg/manage-table-ceg.component';
import { ManageTableCegHeadRowComponent } from '@manage-ceg/components/manage-table-ceg/components/manage-table-ceg-head-row/manage-table-ceg-head-row.component';
import { ManageTableCegRowComponent } from '@manage-ceg/components/manage-table-ceg/components/manage-table-ceg-row/manage-table-ceg-row.component';
import { ManageTableCegFootRowComponent } from '@manage-ceg/components/manage-table-ceg/components/manage-table-ceg-foot-row/manage-table-ceg-foot-row.component';
import { BudgetAllocationModule } from '../budget-allocation/budget-allocation.module';
import { TotalsTogglerComponent } from './components/manage-table-ceg/components/totals-toggler/totals-toggler.component';
import { ExpandTogglerComponent } from './components/manage-table-ceg/components/expand-toggler/expand-toggler.component';
import { TableConfigurationComponent } from './components/table-configuration/table-configuration.component';
import { TableConfigDialogComponent } from './dialogs/table-config-dialog/table-config-dialog.component';
import { ManageCegActionMenuComponent } from './components/manage-ceg-action-menu/manage-ceg-action-menu.component';
import { TimeframeConfigurationComponent } from './components/timeframe-configuration/timeframe-configuration.component';
import { TimeframeConfigDialogComponent } from './dialogs/timeframe-config-dialog/timeframe-config-dialog.component';
import { AppDataLoader } from '../app-data-loader.service';
import { RecordContextActionsComponent } from './components/manage-table-ceg/components/record-context-actions/record-context-actions.component';
import { SegmentAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/segment-amounts-loader';
import { SegmentGroupAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/segment-group-amounts-loader';
import { ExpenseGroupAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/expense-group-amounts-loader';
import { GoalAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/goal-amounts-loader';
import { CampaignAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/campaign-amounts-loader';
import { ObjectCegNameInputComponent } from '@manage-ceg/components/object-ceg-name-input/object-ceg-name-input.component';
import { CampaignPerformanceAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/campaign-performance-amounts-loader';
import { GoalPerformanceAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/goal-performance-amounts-loader';
import { RowTotalsComponent } from './components/manage-table-ceg/components/row-totals/row-totals.component';
import { CampaignPerformanceService } from '@manage-ceg/services/campaign-performance.service';

@NgModule({
  declarations: [
    ManageCegPageComponent,
    BudgetCegTimelineContainerComponent,
    BudgetChartActionsMenuComponent,
    ManageCegTableContainerComponent,
    TableModeSwitchComponent,
    ManageTableCegComponent,
    ManageTableCegHeadRowComponent,
    ManageTableCegRowComponent,
    ManageTableCegFootRowComponent,
    TotalsTogglerComponent,
    ExpandTogglerComponent,
    TableConfigurationComponent,
    TableConfigDialogComponent,
    ManageCegActionMenuComponent,
    TimeframeConfigurationComponent,
    TimeframeConfigDialogComponent,
    RecordContextActionsComponent,
    ObjectCegNameInputComponent,
    RowTotalsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    BudgetAllocationModule,
  ],
  providers: [
    ExpenseGroupAmountsLoader,
    CampaignAmountsLoader,
    GoalAmountsLoader,
    SegmentAmountsLoader,
    SegmentGroupAmountsLoader,
    CampaignPerformanceAmountsLoader,
    GoalPerformanceAmountsLoader,
    CampaignPerformanceService
  ]
})
export class ManageCegTableModule { }
