import { LightObject, SegmentedBudgetObject } from './segmented-budget-object';
import { SegmentedObjectTimeframe } from './object-timeframe.interface';
import { AllocationMode } from './budget-object-allocation.interface';
import { BudgetObjectTotals } from './budget-object-totals.interface';
import { AttachmentMappingDO } from './attachment.interface';
import { ChildPlanObjectDO, PlanObjectDO } from './plan.type';
import { MetricDataDO } from '../services/backend/metric.service';
import { BudgetObjectSource } from '../../budget-object-details/types/budget-object-details-state.interface';

export interface CampaignHierarchyData {
  goalId?: number;
  parentCampaign?: number;
  subCampaignId?: number; // Id of a source campaign for pseudo-campaign
}

export interface ScheduledObject {
  startDate?: Date;
  endDate?: Date;
}

export interface Campaign extends SegmentedBudgetObject, CampaignHierarchyData, ScheduledObject {
  campaignTypeId: number;
  timeframes: SegmentedObjectTimeframe[];
  campaignSource?: string;
  keyMetric?: number;
}

export interface LightCampaign extends LightObject, CampaignHierarchyData, ScheduledObject {
  campaignTypeId: number;
  keyMetric?: number;
}

export interface CampaignTypeDO {
  id: number;
  name: string;
  is_custom: boolean;
  crd: string;
  upd: string;
  company: number;
  is_enabled?: boolean;
}

export interface CampaignAllocationDO {
  id: number;
  mode: AllocationMode;
  amount: number;
  source_amount: number;

  source_actual?: number;
  source_remaining_planned?: number;
  source_remaining_committed?: number;
  available?: number;

  crd: string;
  upd: string;
  campaign: number;
  company_budget_alloc: number;
  company: number;
}

export interface CampaignDO {
  id?: number;
  campaign_id?: number;
  campaign_allocations: CampaignAllocationDO[];
  budget: number;
  company: number;
  company_budget_segment1?: number;
  created_by: number;
  owner: number;
  campaign_type: number;
  name: string;
  note: string;
  amount: number;
  source_amount: number;
  amount_status: string;
  mode: string;
  crd: string;
  upd: string;
  split_rule?: number;
  start_date: string;
  end_date: string;
  target_audience: string;
  key_messages: string;
  status_totals: BudgetObjectTotals;
  metric_data: MetricDataDO[];
  attachments: AttachmentMappingDO[];
  goal?: number;
  parent_campaign?: number;
  // Special flag for the API to decide whether we should sync children segments or not
  process_nested?: boolean;
  key_metric?: number;
  campaignSource?: string;
  lock_for_integrations?: boolean;
  external_id?: string;
  object_type?: number;
  gl_code: number;
  source_currency: string;
  vendor: number;
  source: BudgetObjectSource;
  campaign_integrated_source?: boolean;
  custom_fields?: Record<number, string[]>;
}

export interface LightCampaignDO {
  id: number;
  name: string;
  parent_campaign?: number;
  goal?: number;
  company_budget_segment1?: number;
  split_rule?: number;
}

export interface PlanCampaignDO extends PlanObjectDO {
  campaign_type: number;
  program_list: ChildPlanObjectDO[];
  child_campaign_list: ChildPlanCampaignDO[];
  children?: (ChildPlanCampaignDO | ChildPlanObjectDO) [];
}

export interface ChildPlanCampaignDO extends ChildPlanObjectDO {
  has_programs: boolean;
  campaign_type: string;
}
