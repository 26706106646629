import { Injectable } from '@angular/core';

@Injectable()
export class BusinessValueService {
  private static defaultRevenueToProfitPercent = 100;

  public static calcBusinessValue(
    metricValue: number,
    revenuePerOutcome: number,
    revenueToProfitRate: number
  ): number {
    if (metricValue == null) {
      return null;
    }

    return revenuePerOutcome ?
      metricValue * revenuePerOutcome * (revenueToProfitRate || BusinessValueService.defaultRevenueToProfitPercent) / 100 :
      null;
  }
}
