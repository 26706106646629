<div class="content" [ngClass]="{ 'visible': showContent }" [formGroup]="settingsForm">
  <header class="content-header">
    <button mat-button
            color="primary"
            class="mr-1"
            [disabled]="!isActive"
            (click)="closeComponent(null)">Cancel</button>
    <button mat-flat-button
            color="primary"
            [disabled]="!isActive"
            (click)="saveData()">Export Budget</button>
  </header>
  <div class="content-description">
    <h2 class="title">Choose Data to be Exported</h2>
  </div>

  <div class="group">
    <h3 class="group-title">
      <fa-icon [icon]="['fas', 'sack-dollar']"></fa-icon>
      <span>Budget Level Data</span>
    </h3>
    <div class="group-header">
      <mat-checkbox
        color="primary"
        class="pl-mat-checkbox"
        [checked]="budgetLevelGroupState.allChecked"
        [indeterminate]="budgetLevelGroupState.someChecked"
        (change)="budgetLevelSelectionChange($event.checked)">
          Select all
      </mat-checkbox>
    </div>

    <div class="regular-list" [formGroupName]="'budgetLevel'">
      <mat-checkbox
        color="primary"
        class="pl-mat-checkbox"
        [checked]="hierarchyState.allChecked"
        [indeterminate]="hierarchyState.someChecked"
        (change)="hierarchySelectionChange($event.checked)">
          Hierarchy
      </mat-checkbox>

      <mat-checkbox
        *ngFor="let option of hierarchyOptions"
        color="primary"
        class="pl-mat-checkbox child-checkbox"
        [formControl]="budgetHierarchyGroup.controls[option.id]">
          {{ option.title }}
      </mat-checkbox>

      <mat-checkbox
        color="primary"
        class="pl-mat-checkbox"
        [formControlName]="BudgetLevelControls.INCLUDE_BUDGETS">
          Include Budgets (amounts for Objects)
      </mat-checkbox>

      <div class="metrics-block">
        <div [matTooltip]="campaignsControl.value ? null : enableItemTooltip.campaigns"
             matTooltipClass="dark-theme-tooltip simple"
             matTooltipPosition="above"
             matTooltipShowDelay="600">
          <mat-checkbox
            color="primary"
            class="pl-mat-checkbox"
            [formControlName]="BudgetLevelControls.CAMPAIGN_METRIC_MAPPINGS">
            Campaign Metric Mappings
          </mat-checkbox>
        </div>
        <div [matTooltip]="budgetLevelControls.get(BudgetLevelControls.CAMPAIGN_METRIC_MAPPINGS).value ? null : enableItemTooltip.campaignMapping"
             matTooltipClass="dark-theme-tooltip simple"
             matTooltipPosition="above"
             matTooltipShowDelay="600">
          <mat-checkbox
            color="primary"
            class="pl-mat-checkbox child-checkbox"
            [formControlName]="BudgetLevelControls.CAMPAIGN_METRIC_VALUES">
            Campaign Metric Values
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
