import { inject, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'absoluteNumber'
})
export class AbsoluteNumberPipe implements PipeTransform {
  private readonly decimalPipe = inject(DecimalPipe);

  transform<InitialType>(value: InitialType, defaultValue = '-', digitsInfo = '1.2-2'): string | InitialType {
    if (value === null || value === undefined) {
      return defaultValue;
    }

    return typeof value !== 'number' ? value : this.processNumber(value, digitsInfo);
  }

  private processNumber(val: number, digitsInfo: string): string {
    const formattedValue = this.decimalPipe.transform(Math.abs(val), digitsInfo);
    return val < 0 ? `[${formattedValue}]` : formattedValue;
  }
}
