import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';


@Directive({
  selector: '[elementResizeObserver]'
})
export class ElementResizeObserverDirective implements OnInit, OnDestroy, AfterViewInit {
  private resizeObserver: ResizeObserver;
  private readonly resizeTrigger = new Subject<DOMRectReadOnly>();
  private readonly destroy$ = new Subject<void>();

  @Input() resizeEventThrottleTime = 300;
  @Output() onElementResize = new EventEmitter<DOMRectReadOnly>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      entries.forEach(resizeEntry => {
        if (resizeEntry.target === this.el?.nativeElement) {
          this.resizeTrigger.next(resizeEntry.contentRect);
        }
      });
    });

    this.resizeTrigger
      .pipe(
        throttleTime(this.resizeEventThrottleTime, undefined, { leading: true, trailing: true }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        rect => this.onElementResize.emit(rect)
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resizeObserver.unobserve(this.el?.nativeElement);
  }

  ngAfterViewInit(): void {
    this.resizeObserver.observe(this.el?.nativeElement);
  }
}
