import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { Budget } from 'app/shared/types/budget.interface';
import { Configuration } from 'app/app.constants';
import { UserManager } from '../../../user/services/user-manager.service';

@Component({
  selector: 'home-header',
  styleUrls: ['./home-header.component.scss'],
  templateUrl: './home-header.component.html'
})
export class HomeHeaderComponent implements OnChanges {
  private readonly router = inject(Router);
  private readonly configuration = inject(Configuration);
  protected readonly userManager = inject(UserManager);

  @Input() budget: Budget;
  @Input() isLoading;

  public today = new Date();
  public startDate = null;
  public endDate = null;
  public budgetDateFormat = 'd LLL yyyy';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.budget && this.budget) {
      this.startDate = parseDateString(this.budget.budget_from);
      this.endDate = parseDateString(this.budget.budget_to);
    }
  }

  public setupBudget() {
    this.router.navigate([this.configuration.ROUTING_CONSTANTS.BUDGET_SETTINGS]);
  }
}
