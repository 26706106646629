import { Component, OnDestroy } from '@angular/core';
import { SpendingModeService } from '@spending/services/spending-mode.service';
import { ExpenseTableColumnItem, PatchExpenseData, SpendingManagementMode } from '@spending/types/expense-page.type';
import { SpendingService } from '@spending/services/spending.service';
import { SpendingChildComponent } from '../spending-child.component';
import { ExpenseRowData, ExpenseSubtotalData, ExpenseTableDataService } from '@spending/services/expense-table-data.service';
import { ExpensePageSelectionService } from '@spending/services/expense-page-selection.service';
import { ExpenseTableColumnsService } from '@spending/services/expense-table-columns.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ExpensePageService } from '@spending/services/expense-page-service';
import { SpendingManageMenuService, MenuItem } from '@spending/services/spending-manage-menu.service';
import { MenuPanelItem } from '../../../../header-navigation/components/menu-panel/menu-panel.type';
import { ExpensePageSortByService } from '@spending/services/expense-page-sort-by.service';
import { SpendingMiniDashSummaryService } from '@spending/services/spending-mini-dash-summary.service';
import { SpendingLocalFiltersService } from '@spending/services/spending-local-filters.service';

@Component({
  selector: 'expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss'],
  animations: [
    trigger('fadeAndScale', [
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('0.4s ease-out', style({ opacity: 0, transform: 'scale(.35)' }))
      ]),
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.35)' }),
        animate('0.3s ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ])
    ]),
  ],
  providers: [
    ExpensePageService,
  ]
})
export class ExpensesComponent extends SpendingChildComponent implements OnDestroy {
  public noExpenseText = 'No expenses';
  public noExpenseIcon = ['fas', 'inbox'];

  private spendingManagementMode = SpendingManagementMode.Expenses;
  private manageMenuActionsMap: Record<MenuItem, MenuPanelItem> = {
    [MenuItem.ACTIONS]: {
      label: 'Actions',
      faIcon: ['fas', 'pencil-ruler'],
      panelClass: 'expenses-manage-menu-panel',
      hasCustomTemplate: true,
      disabled: true,
    },
    [MenuItem.SORT]: {
      label: 'Sort by',
      faIcon: ['fas', 'sort'],
      panelClass: 'expenses-manage-menu-panel sorting-panel has-sticky has-scroll',
      children: this.expenseSortByService.prepareSortByMenuItems(),
    },
    [MenuItem.MANAGE_COLUMNS]: {
      label: 'Manage columns',
      faIcon: ['fas', 'line-columns'],
      action: () => this.expenseTableColumnsService.openManageColumnsDialog(),
      customCssClass: 'ut-expenses-manage-columns'
    },
    [MenuItem.EXPORT]: {
      label: 'Export data',
      faIcon: ['fas', 'cloud-download'],
      action: () => this.expensePageService.exportExpensesToCSV(false),
    },
  };

  constructor(
    private readonly spendingService: SpendingService,
    private readonly expensePageSelectionService: ExpensePageSelectionService,
    private readonly spendingMiniDashSummaryService: SpendingMiniDashSummaryService,
    private readonly spendingLocalFiltersService: SpendingLocalFiltersService,

    private readonly spendingModeService: SpendingModeService,
    private readonly expensePageService: ExpensePageService,
    private readonly spendingManageMenuService: SpendingManageMenuService,
    private readonly expenseTableColumnsService: ExpenseTableColumnsService,
    private readonly expenseTableDataService: ExpenseTableDataService,
    private readonly expenseSortByService: ExpensePageSortByService,
  ) {
    super(
      spendingService,
      expensePageSelectionService,
      spendingLocalFiltersService,
      spendingMiniDashSummaryService,
      expensePageService,
    );
    this.spendingModeService.spendingManagementMode = this.spendingManagementMode;
    this.spendingManageMenuService.setMenuMap = this.manageMenuActionsMap;
    this.spendingLocalFiltersService.sortBy = this.expenseSortByService.sortByFieldValue;
  }

  public get subtotalData(): ExpenseSubtotalData {
    return this.expenseTableDataService.subtotalData;
  }

  public activateRelatedExpensesMode(relationGroupId: string) {
    this.expensePageService.activateRelatedExpensesMode(relationGroupId);
  }

  public openExpenseDetails(id: number): void {
    this.spendingService.openExpenseDetails(id);
  }

  public get tableColumns(): ExpenseTableColumnItem[] {
    return this.expenseTableColumnsService.expenseTableColumns;
  }

  public get tableRows(): ExpenseRowData[] {
    return this.expenseTableDataService.rows;
  }

  public patchExpense(data: PatchExpenseData): void {
    this.expensePageService.patchExpense(data);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
