export enum ManageTableViewMode {
  Segments = 'segments',
  Goals = 'goals',
  Campaigns = 'campaigns',
  Expenses = 'expenses',
  CampaignsExpenses = 'campaigns_expenses'
}

export interface ManageTableViewModeChange {
  value: ManageTableViewMode;
  prevValue: ManageTableViewMode;
}
