import { Injectable, OnDestroy } from '@angular/core';
import { ExpensesSummaryGroup } from 'app/shared/types/expenses-summary.type';
import { ExpenseAllocationMode } from 'app/shared/types/expense-allocation-mode.type';
import { SummaryBarItem } from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { SelectableSpendingRow } from '@spending/services/expense-page-selection.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SpendingManagementMode } from '@spending/types/expense-page.type';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface ExpenseMiniDashSummaryGroup {
  count: SummaryBarItem,
  amount: SummaryBarItem,
}

@Injectable()
export class SpendingMiniDashSummaryService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private summary: Record<string, ExpenseMiniDashSummaryGroup> = {
    [ExpensesSummaryGroup.Grand]: {
      count: {
        title: 'All expenses',
        value: null,
        icon: ['fas', 'coins'],
      },
      amount: {
        title: 'Grand Total',
        label: 'USD',
        value: null,
      }
    },
    [ExpensesSummaryGroup.Filtered]: {
      count: {
        title: 'Matching Expenses',
        value: null,
        icon: ['fas', 'sliders'],
      },
      amount: {
        title: 'Matching Total',
        label: 'USD',
        value: null,
      }
    },
    [ExpensesSummaryGroup.Selected]: {
      count: {
        title: 'Selected Expenses',
        value: 0,
        icon: ['fas', 'check-square'],
      },
      amount: {
        title: 'Selected Total',
        label: 'USD',
        value: null,
      }
    },
  };
  private countTitle = {
    [SpendingManagementMode.Expenses]: {
      [ExpensesSummaryGroup.Grand]: 'All Expenses',
      [ExpensesSummaryGroup.Filtered]: 'Matching Expenses',
      [ExpensesSummaryGroup.Selected]: 'Selected Expenses',
    },
    [SpendingManagementMode.Invoices]: {
      [ExpensesSummaryGroup.Grand]: 'All Invoices',
      [ExpensesSummaryGroup.Filtered]: 'Matching Invoices',
      [ExpensesSummaryGroup.Selected]: 'Selected Invoices',
    },
  }
  private countIcon: Record<SpendingManagementMode, IconProp> = {
    [SpendingManagementMode.Expenses]: ['fas', 'coins'],
    [SpendingManagementMode.Invoices]: ['fas', 'file-invoice-dollar'],
  }
  public summaryBarItems: SummaryBarItem[][];

  public grandTotalsLoading$ = new BehaviorSubject<boolean>(true);
  public viewedTotalsLoading$ = new BehaviorSubject<boolean>(false);
  public summaryBarLoading$ = combineLatest([this.grandTotalsLoading$, this.viewedTotalsLoading$]).pipe(
    takeUntil(this.destroy$),
    map(([grandTotalsLoading, viewedTotalsLoading]) => {
      return grandTotalsLoading || viewedTotalsLoading
    })
  ) as BehaviorSubject<boolean>;


  constructor() {
    this.initSummaryBarItems();
  }

  private initSummaryBarItems() {
    this.summaryBarItems = Object.values(this.summary).reduce((groups, data) => {
      groups.push([
        data.count,
        data.amount,
      ]);
      return groups;
    }, []);
  }

  public updateCurrency(currency: string): void {
    Object.values(this.summary).forEach((groups, data) => {
      groups.amount.label = currency;
    });
  }

  public updateGroupsForActiveMode(mode: SpendingManagementMode): void {
    this.updateIcon(mode);
    this.updateTitles(mode);
  }

  public updateIcon(mode: SpendingManagementMode): void {
    this.summary[ExpensesSummaryGroup.Grand].count.icon = this.countIcon[mode];
  }

  public updateTitles(mode: SpendingManagementMode): void {
    Object.entries(this.summary).forEach(([groupKey, groups]) => {
      groups.count.title = this.countTitle[mode][groupKey];
    });
  }

  public static getExpenseAmount(amount: number, actualAmount: number, mode: string): number {
    return (mode === ExpenseAllocationMode.Planned ? amount : actualAmount) || 0;
  }

  public setExpensesSummaryForGroup(groupType: ExpensesSummaryGroup, count: number, amount: number) {
    const summaryGroup = this.summary[groupType];
    summaryGroup.count.value = count;
    summaryGroup.amount.value = amount;
  }

  public updateSelectedExpensesSummary(rowsSelection: Record<string, SelectableSpendingRow>, isTotalSelected?: boolean): void {
    const summaryGroup = this.summary[ExpensesSummaryGroup.Selected];
    if (isTotalSelected) {
      summaryGroup.amount.value = this.summary[ExpensesSummaryGroup.Filtered].amount.value;
      summaryGroup.count.value = this.summary[ExpensesSummaryGroup.Filtered].count.value;
      return;
    }

    summaryGroup.count.value = Object.keys(rowsSelection).length;

    if (!summaryGroup.count.value) {
      summaryGroup.amount.value = null;
      return;
    }
    summaryGroup.amount.value = Object.values(rowsSelection).reduce(
      (total, row) => {
        const mode = row.expenseObject.mode;
        return total + SpendingMiniDashSummaryService.getExpenseAmount(row.expenseObject.amount, row.expenseObject.actual_amount, mode);
      }, 0);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
