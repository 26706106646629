import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationStateService {
  private isProcessingMap = new Map<string, BehaviorSubject<boolean>>();

  setIsProcessing(integrationId: string, value: boolean): void {
    if (!this.isProcessingMap.has(integrationId)) {
      this.isProcessingMap.set(integrationId, new BehaviorSubject<boolean>(false));
    }
    this.isProcessingMap.get(integrationId).next(value);
  }

  getIsProcessing(integrationId: string) {
    if (!this.isProcessingMap.has(integrationId)) {
      this.isProcessingMap.set(integrationId, new BehaviorSubject<boolean>(false));
    }
    return this.isProcessingMap.get(integrationId).asObservable();
  }
}