<article class="auth-page">
  <div class="auth-page-content">
    <h1 class="logo">
      <a href="javascript:;" title="Planful" [routerLink]="['/']">
        <img src="assets/images/planful-logo-colored.svg" width="150" alt="logo" />
      </a>
    </h1>
    <div class="register-success-container">
      <h3 class="auth-page-content-title title-big">Thank you!</h3>
      <div class="auth-page-form">
        <p class="auth-page-text">Please check your email for confirmation message.</p>
        <div class="form-actions">
          <button class="full-width" mat-flat-button color="primary" type="button" (click)="loginRedirect()">Sign in</button>
        </div>
      </div>
    </div>
  </div>
  <rights-reserved></rights-reserved>
</article>
