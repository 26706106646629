import { Component, Input } from '@angular/core';

@Component({
  selector: 'widget-card',
  styleUrls: ['./widget-card.component.scss'],
  templateUrl: './widget-card.component.html'
})
export class WidgetCardComponent {
  @Input() isLoading = false;
  @Input() overlayText: string;
  @Input() overlayAction: Function;
}
