import { Injectable, OnDestroy } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuPanelItem } from 'app/header-navigation/components/menu-panel/menu-panel.type';
import { ManagePageService } from './manage-page.service';
import { ManageTableRecordInteractionsService } from './manage-table-record-interactions.service';
import { ManageTableSelectionState } from '../types/manage-table-selection-state.types';
import { ManageTableDataService } from './manage-table-data.service';
import { ManageTableMenuAction } from '../types/manage-table-menu-action.type';
import { ManageTableViewMode } from '../types/manage-table-view-mode.type';
import { ManagePageModeService } from './manage-page-mode.service';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { ManagePageExportService } from './manage-page-export.service';
import { ManageTableHelpers } from './manage-table-helpers';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';


@Injectable()
export class ManagePageActionsMenuService implements OnDestroy {
  private actionsMap: Record<string, MenuPanelItem> = {};
  private readonly destroy$ = new Subject<void>();
  private campaignTypes: BudgetObjectType[] = [];
  private campaignTypeItems: MenuPanelItem[] = [];
  private externalIntegrationCampaignTypes: number[] = [];
  private expGroupTypes: BudgetObjectType[] = [];
  private expGroupTypeItems: MenuPanelItem[] = [];
  private externalIntegrationExpGroupTypes: number[] = [];

  constructor(
    private readonly managePageService: ManagePageService,
    private readonly dataService: ManageTableDataService,
    private readonly interactionsManager: ManageTableRecordInteractionsService,
    private readonly exportService: ManagePageExportService,
    private readonly modeService: ManagePageModeService,
  ) {
    this.interactionsManager.selectionChanged$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(selection => this.handleSelectionChange(selection))

    merge(this.exportService.isLoading$, this.managePageService.summaryBarLoading$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLoading => {
        if (this.actionsMap[ManageTableMenuAction.Export]) {
          this.actionsMap[ManageTableMenuAction.Export].disabled = isLoading;
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private generateChangeTypeMenuItems() {
    this.externalIntegrationCampaignTypes = BudgetObjectService.getExternalIntegrationTypeIds(this.campaignTypes);
    this.externalIntegrationExpGroupTypes = BudgetObjectService.getExternalIntegrationTypeIds(this.expGroupTypes);

    const actionHandler = (target: MenuPanelItem) => this.managePageService.changeSelectedItemsObjectType(
      Number(target.value),
      target.label
    );
    const mapTypeToMenuItem = (objectType: BudgetObjectType) => ({
      label: objectType.name,
      action: actionHandler,
      value: objectType.id,
      faIcon: null,
      customCssClass: 'ut-mp-change-type-action'
    });
    const filterExternalIntegrationTypes = (objectType: BudgetObjectType, restrictedTypes: number[]) =>
      !restrictedTypes.includes(objectType.id);

    this.campaignTypeItems = this.campaignTypes
      .filter(objectType => filterExternalIntegrationTypes(objectType, this.externalIntegrationCampaignTypes))
      .map(mapTypeToMenuItem);
    this.expGroupTypeItems = this.expGroupTypes
      .filter(objectType => filterExternalIntegrationTypes(objectType, this.externalIntegrationExpGroupTypes))
      .map(mapTypeToMenuItem);
  }

  private defineSelectionPredicates(selection: ManageTableSelectionState) {
    const { goals, campaigns, expGroups, segments, segmentGroups } = selection;
    let anyCampaignClosed = false;
    let anyCampaignOpen = false;
    let anyExpGroupClosed = false;
    let anyExpGroupOpen = false;
    let anyParentCampaignSelected = false;
    let anyExternalIntegrationObjectSelected = false;
    let isSegmentlessObject = false;

    for (const campaignId of campaigns) {
      const campaignRecord = this.dataService.getRecordByIdAndType(ManageTableRowType.Campaign, campaignId);
      if (campaignRecord && !anyParentCampaignSelected) {
        anyParentCampaignSelected = campaignRecord.children.some(child => child.type === ManageTableRowType.Campaign);
      }
      if (campaignRecord?.isClosed) {
        anyCampaignClosed = true;
      } else {
        anyCampaignOpen = true;
      }
      if (!anyExternalIntegrationObjectSelected && this.externalIntegrationCampaignTypes.includes(campaignRecord.objectTypeId)) {
        anyExternalIntegrationObjectSelected = true;
      }
      if (ManageTableHelpers.isSegmentlessObject(campaignRecord)) {
        isSegmentlessObject = true;
      }
    }
    for (const expGroupId of expGroups) {
      const expGroupRecord = this.dataService.getRecordByIdAndType(ManageTableRowType.ExpenseGroup, expGroupId);

      if (expGroupRecord?.isClosed) {
        anyExpGroupClosed = true;
      } else {
        anyExpGroupOpen = true;
      }
      if (!anyExternalIntegrationObjectSelected && this.externalIntegrationExpGroupTypes.includes(expGroupRecord.objectTypeId)) {
        anyExternalIntegrationObjectSelected = true;
      }
    }

    const selectedCount = goals.size + campaigns.size + expGroups.size;
    const anyBudgetObjectSelected = selectedCount > 0;
    const noSegmentedObjectSelected = (campaigns.size + expGroups.size) === 0;
    const goalsOrSegmentsSelected = (goals.size + segments.size + segmentGroups.size) > 0;
    const anyClosedObjectSelected = anyCampaignClosed || anyExpGroupClosed;
    const anyOpenObjectSelected = anyCampaignOpen || anyExpGroupOpen;
    const nothingOrClosedObjectsSelected = !anyBudgetObjectSelected || anyClosedObjectSelected;
    const singleOpenObjectSelected = selectedCount === 1 && !anyClosedObjectSelected;
    const allSelectedAreClosed = anyBudgetObjectSelected && !anyOpenObjectSelected;
    const allSelectedAreOpen = anyBudgetObjectSelected && !anyClosedObjectSelected;
    const campaignsAndGroupsSelected = campaigns.size > 0 && expGroups.size > 0;
    const onlyCampaignsSelected = campaigns.size > 0 && expGroups.size === 0;
    const onlyExpGroupsSelected = expGroups.size > 0 && campaigns.size === 0;

    return {
      anyBudgetObjectSelected,
      nothingOrClosedObjectsSelected,
      singleOpenObjectSelected,
      anyClosedObjectSelected,
      anyOpenObjectSelected,
      allSelectedAreClosed,
      allSelectedAreOpen,
      noSegmentedObjectSelected,
      anyParentCampaignSelected,
      goalsOrSegmentsSelected,
      onlyCampaignsSelected,
      onlyExpGroupsSelected,
      campaignsAndGroupsSelected,
      anyExternalIntegrationObjectSelected,
      isSegmentlessObject
    };
  }

  private validateActions(selection: ManageTableSelectionState) {
    const {
      anyBudgetObjectSelected,
      anyClosedObjectSelected,
      anyOpenObjectSelected,
      nothingOrClosedObjectsSelected,
      singleOpenObjectSelected,
      allSelectedAreClosed,
      allSelectedAreOpen,
      noSegmentedObjectSelected,
      anyParentCampaignSelected,
      goalsOrSegmentsSelected,
      campaignsAndGroupsSelected,
      onlyExpGroupsSelected,
      onlyCampaignsSelected,
      anyExternalIntegrationObjectSelected,
      isSegmentlessObject
    } = this.defineSelectionPredicates(selection);
    const currentMode = this.modeService.viewMode;

    this.actionsMap[ManageTableMenuAction.Export].disabled = anyBudgetObjectSelected || this.managePageService.summaryBarLoading$.getValue();
    this.actionsMap[ManageTableMenuAction.Delete].disabled = nothingOrClosedObjectsSelected;
    this.actionsMap[ManageTableMenuAction.AddTags].disabled = nothingOrClosedObjectsSelected;
    this.actionsMap[ManageTableMenuAction.RemoveTags].disabled = nothingOrClosedObjectsSelected;
    this.actionsMap[ManageTableMenuAction.Duplicate].disabled = !singleOpenObjectSelected;
    this.actionsMap[ManageTableMenuAction.MoveTo].disabled = nothingOrClosedObjectsSelected
      || noSegmentedObjectSelected
      || (anyParentCampaignSelected && currentMode === ManageTableViewMode.Campaigns)
      || goalsOrSegmentsSelected
      || (currentMode !== ManageTableViewMode.Goals && isSegmentlessObject);

    this.actionsMap[ManageTableMenuAction.Close].disabled = nothingOrClosedObjectsSelected;
    this.actionsMap[ManageTableMenuAction.Close].hidden = allSelectedAreClosed;

    this.actionsMap[ManageTableMenuAction.Reopen].disabled = !allSelectedAreClosed;
    this.actionsMap[ManageTableMenuAction.Reopen].hidden = !anyBudgetObjectSelected || allSelectedAreOpen ||
      (anyClosedObjectSelected && anyOpenObjectSelected);

    this.actionsMap[ManageTableMenuAction.ChangeType].disabled =
      anyExternalIntegrationObjectSelected || nothingOrClosedObjectsSelected || campaignsAndGroupsSelected || noSegmentedObjectSelected;
    this.actionsMap[ManageTableMenuAction.ChangeType].children =
      onlyCampaignsSelected ?
        this.campaignTypeItems :
        onlyExpGroupsSelected ? this.expGroupTypeItems : [];
    this.actionsMap[ManageTableMenuAction.AddMetrics].disabled = !onlyCampaignsSelected || anyClosedObjectSelected;
  }

  private handleSelectionChange(selection: ManageTableSelectionState) {
    this.validateActions(selection);
  }

  private initActionsMap() {
    this.actionsMap = {
      [ManageTableMenuAction.Duplicate]: {
        label: 'Duplicate',
        faIcon: ['fad', 'copy'],
        action: () => this.managePageService.duplicateSelectedItems(),
        disabled: true,
        customCssClass: 'ut-mp-duplicate-action'
      },
      [ManageTableMenuAction.MoveTo]: {
        label: 'Move to',
        faIcon: ['fad', 'folder-open'],
        action: () => this.managePageService.openHierarchySelection(),
        disabled: true,
        customCssClass: 'ut-mp-move-to-action'
      },
      [ManageTableMenuAction.Close]: {
        label: 'Close',
        faIcon: ['fad', 'hourglass-end'],
        action: () => this.managePageService.closeSelectedItems(),
        disabled: true,
        customCssClass: 'ut-mp-close-action'
      },
      [ManageTableMenuAction.Reopen]: {
        label: 'Reopen',
        faIcon: ['fad', 'hourglass-start'],
        action: () => this.managePageService.reopenSelectedItems(),
        disabled: true,
        hidden: true,
        customCssClass: 'ut-mp-reopen-action'
      },
      [ManageTableMenuAction.ChangeType]: {
        label: 'Change Type',
        faIcon: ['fad', 'copy'],
        action: null,
        isDivider: false,
        disabled: true,
        children: []
      },
      [ManageTableMenuAction.AddTags]: {
        label: 'Add Tags',
        faIcon: ['fad', 'tags'],
        action: () => this.managePageService.addTagsToSelectedItems(),
        isDivider: false,
        disabled: true,
        customCssClass: 'ut-mp-add-tags-action'
      },
      [ManageTableMenuAction.RemoveTags]: {
        label: 'Remove Tags',
        faIcon: ['fad', 'tags'],
        action: () => this.managePageService.removeTagsFromSelectedItems(),
        isDivider: false,
        disabled: true,
        customCssClass: 'ut-mp-remove-tags-action'
      },
      [ManageTableMenuAction.AddMetrics]: {
        label: 'Add metrics',
        faIcon: ['fad', 'chart-line'],
        action: () => this.managePageService.openMetricList(),
        isDivider: false,
        disabled: true,
        customCssClass: 'ut-mp-add-metrics-action'
      },
      [ManageTableMenuAction.Delete]: {
        label: 'Delete',
        faIcon: ['fad', 'trash-alt'],
        action: () => this.managePageService.deleteSelectedItems(),
        isDivider: true,
        disabled: true,
        customCssClass: 'item-danger ut-mp-delete-action',
      },
      [ManageTableMenuAction.Export]: {
        label: 'Export',
        faIcon: ['fad', 'cloud-download'],
        action: () => this.managePageService.exportData(),
        isDivider: true,
        disabled: true,
        customCssClass: 'ut-mp-export-action'
      },
    };
  }

  public setObjectTypes(
    campaignTypes: BudgetObjectType[],
    expGroupTypes: BudgetObjectType[],
  ) {
    this.campaignTypes = [...campaignTypes];
    this.expGroupTypes = [...expGroupTypes];
    this.generateChangeTypeMenuItems();
  }

  public generateMenu(): MenuPanelItem[] {
    this.initActionsMap();

    return [
      this.actionsMap[ManageTableMenuAction.Duplicate],
      this.actionsMap[ManageTableMenuAction.MoveTo],
      this.actionsMap[ManageTableMenuAction.Close],
      this.actionsMap[ManageTableMenuAction.Reopen],
      this.actionsMap[ManageTableMenuAction.ChangeType],
      this.actionsMap[ManageTableMenuAction.AddTags],
      this.actionsMap[ManageTableMenuAction.RemoveTags],
      this.actionsMap[ManageTableMenuAction.AddMetrics],
      this.actionsMap[ManageTableMenuAction.Delete],
      this.actionsMap[ManageTableMenuAction.Export],
    ];
  }
}
