import { PlanChildObjectBrief, PlanChildObjectsBrief } from 'app/dashboard/dashboard.component';
import { BudgetSummaryBarViewMode } from '@shared/enums/budget-summary-bar-view.enum';
import {
  SummaryBarCalculationItem,
  SummaryBarCalculationItemList,
  SummaryBarCegCalculationItemList,
  SummaryBarItem,
  SummaryBarItemCalculationConfig, SummaryBarItemCegCalculationConfig
} from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { ManageTableBudgetColumnName } from '@manage-ceg/types/manage-ceg-page.types';
import { TooltipContext } from '@shared/directives/dynamic-portal-tooltip.directive';

export const summaryBarDefaultItems: SummaryBarItem[][] = [
  [
    {
      title: 'Segments',
      value: 0,
      valueSecondary: 0,
      icon: ['fad', 'chart-pie-alt']
    },
    {
      title: 'Campaigns',
      value: 0,
      valueSecondary: 0,
      icon: ['fad', 'rocket-launch']
    },
  ]
];

export const tooltipContextList = {
  [BudgetSummaryBarViewMode.Expenses]: {
    0: {
      header: 'All Expenses & Grand Total',
      body: 'The sum of your expenses adds up to contribute to your total budget actual spend.',
      icon: ['fas', 'coins'],
      width: 230
    },
    1: {
      header: 'Matching Expenses',
      body: `Matching Expenses are expenses, that you see when you pick sidebar item.
            They can be filtered by filters and text search.`,
      icon: ['fas', 'sliders'],
      width: 230
    }
  },
  [BudgetSummaryBarViewMode.Invoices]: {
    0: {
      header: 'All Invoices & Grand Total',
      body: 'The sum of your invoices adds up to contribute to the total actual spend of your budget.',
      icon: ['fas', 'file-invoice-dollar'],
      width: 230
    }
  }
};

export class BudgetSummaryBarHelpers {
  static summaryBarDefaultCalculationItems: SummaryBarCalculationItemList = {
    budget: BudgetSummaryBarHelpers.createCalculationItem('Allocated', 0, null, '', null),
    expenses: BudgetSummaryBarHelpers.createCalculationItem('Expenses', 0, '-', '', null),
    remainingAllocated: BudgetSummaryBarHelpers.createCalculationItem('Remaining Child Allocations', 0, '-', '', null),
    available: BudgetSummaryBarHelpers.createCalculationItem(
      'Available',
      0,
      '=',
      'success',
      null
    ),
  };

  static createTooltipsForManageBudgetDefinitions(tutorialsUrl: string): Record<string, TooltipContext> {
    const action = {
      title: 'Read more',
      callback: () => { window.open(tutorialsUrl, '_blank'); }
    };
    return {
      [ManageTableBudgetColumnName.CommittedAndPlanned]: { body: 'Remaining Committed + Planned Budget', width: 270, action },
      [ManageTableBudgetColumnName.Committed]: { body: 'Remaining Committed Budget', width: 200, action },
      [ManageTableBudgetColumnName.Planned]: { body: 'Remaining Planned Budget', width: 190, action },
    };
  }

  static summaryBarCegDefaultCalculationItems(tutorialsUrl: string): SummaryBarCegCalculationItemList {
    const tooltips = BudgetSummaryBarHelpers.createTooltipsForManageBudgetDefinitions(tutorialsUrl);
    return {
      budget: BudgetSummaryBarHelpers.createCalculationItem('Total Budget', 0, null, '', null),
      actual: BudgetSummaryBarHelpers.createCalculationItem('Actual Spend', 0, '-', '', null),
      committed: BudgetSummaryBarHelpers.createCalculationItem('Rmng Committed', 0, '-', '', tooltips[ManageTableBudgetColumnName.Committed]),
      planned: BudgetSummaryBarHelpers.createCalculationItem('Rmng Planned', 0, '-', '', tooltips[ManageTableBudgetColumnName.Planned]),
      committedAndPlanned: BudgetSummaryBarHelpers.createCalculationItem('Rmng Committed + Planned', 0, '-', '', tooltips[ManageTableBudgetColumnName.CommittedAndPlanned]),
      available: BudgetSummaryBarHelpers.createCalculationItem(
        'Available',
        0,
        '=',
        'success',
        null
      ),
    };
  }

  static getShownObjectIds(campaigns: PlanChildObjectBrief[] = [], expGroups: PlanChildObjectBrief[] = []): Record<string, number> {
    const segmentsSet = new Set();
    const goalsSet = new Set();

    [...campaigns, ...expGroups].forEach(obj => {
      if (obj.budgetSegmentId) {
        segmentsSet.add(obj.budgetSegmentId);
      }
      if (obj.goalId) {
        goalsSet.add(obj.goalId);
      }
    })

    return {
      segments: segmentsSet.size,
      goals: goalsSet.size,
      campaigns: campaigns.length,
      expGroups: expGroups.length,
    };
  }

  public static getSummaryBarItems(params: {
    totalObjects: Record<string, number>;
    filteredObjects: PlanChildObjectsBrief;
    isFilterMode: boolean;
    configuration
  }): SummaryBarItem[][] {
    const summaryBarItems: SummaryBarItem[] = [];
    const { totalObjects, filteredObjects, isFilterMode } = params;

    const uniqueObjectsIds = BudgetSummaryBarHelpers.getShownObjectIds(filteredObjects?.campaigns, filteredObjects?.expGroups);
    const iconsConfig = params.configuration.iconsConfig
    const summaryItemTemplate = {
      segments: {
        title: 'Segments',
        icon: iconsConfig[params.configuration.OBJECT_TYPES.segment],
      },
      goals: {
        title: 'Goals',
        icon: iconsConfig[params.configuration.OBJECT_TYPES.goal],
      },
      campaigns: {
        title: 'Campaigns',
        icon: iconsConfig[params.configuration.OBJECT_TYPES.campaign],
      },
      expGroups: {
        title: 'Expense Groups',
        icon: iconsConfig[params.configuration.OBJECT_TYPES.expenseGroup],
      },
    };

    Object.entries(uniqueObjectsIds).forEach(([objectKey, itemsShown]) => {
      if (itemsShown || (!isFilterMode && totalObjects[objectKey])) {
        summaryBarItems.push({
          title: summaryItemTemplate[objectKey].title,
          value: isFilterMode ? itemsShown : totalObjects[objectKey],
          valueSecondary: totalObjects[objectKey],
          icon: summaryItemTemplate[objectKey].icon,
        })
      }
    });
    return [ summaryBarItems ];
  }

  public static calcRemaining(allocated: number, expenses: number, remainingAllocated: number): number {
    return allocated - expenses - remainingAllocated;
  }

  public static updateCalculationItems(optionsObject: SummaryBarCalculationItemList, config: SummaryBarItemCalculationConfig): void {
    const { allocated, expenses, remainingAllocated, remaining, isSegmentView, companyCurrencyCode } = config;
    const updateItem = (name: string, value: number, cssClassFlag: boolean): void => {
      BudgetSummaryBarHelpers.updateItem(optionsObject, name, value, cssClassFlag);
    };

    optionsObject.budget.label = isSegmentView ? 'Segment Budget' : 'Allocated';
    optionsObject.available.label = 'Available ' + companyCurrencyCode;

    updateItem('budget', allocated, null);
    updateItem('expenses', expenses, true);
    updateItem('remainingAllocated', remainingAllocated, true);
    updateItem('available', remaining, false);
  }

  public static applyCssClass(value: number, dangerOnly: boolean): string {
    const successClass = dangerOnly ? '' : 'success';
    return value < 0 ? 'danger' : successClass;
  }

  private static updateItem(optionsObject: Record<string, any>, name: string, value: number, cssClassFlag: boolean): void {
    optionsObject[name].value = value;
    if (cssClassFlag !== null) {
      optionsObject[name].cssClass = BudgetSummaryBarHelpers.applyCssClass(value, cssClassFlag);
    }
  }

  public static updateCegCalculationItems(optionsObject: SummaryBarCegCalculationItemList, config: SummaryBarItemCegCalculationConfig): void {
    const { budget, actual, committed, planned, committedAndPlanned, available, companyCurrencyCode, separateCommittedAndPlanned } = config;

    const updateItem = (name: ManageTableBudgetColumnName, value: number, cssClassFlag: boolean): void => {
      BudgetSummaryBarHelpers.updateItem(optionsObject, name, value, cssClassFlag);
    };

    optionsObject.committed.isHidden = !separateCommittedAndPlanned;
    optionsObject.planned.isHidden = !separateCommittedAndPlanned;
    optionsObject.committedAndPlanned.isHidden = separateCommittedAndPlanned;

    updateItem(ManageTableBudgetColumnName.Budget, budget, null);
    updateItem(ManageTableBudgetColumnName.Actual, actual, true);
    updateItem(ManageTableBudgetColumnName.Committed, committed, true);
    updateItem(ManageTableBudgetColumnName.Planned, planned, true);
    updateItem(ManageTableBudgetColumnName.CommittedAndPlanned, committedAndPlanned, true);
    updateItem(ManageTableBudgetColumnName.Available, available, false);

    optionsObject.available.label = 'Available ' + companyCurrencyCode;
  }

  public static createCalculationItem(label: string, value: number, operator: string, cssClass, tooltipContext): SummaryBarCalculationItem {
    return {
      label,
      value,
      operator,
      cssClass,
      tooltipContext,
    };
  }
}
