import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ExternalMetricTypesMappingTableRow, ExternalMetricTypesMappingTableChangeEvent, ExternalMetricTypesMappingTableSettings, MappedMetricType } from './external-metric-types-mapping-table.type';
import { MetricIntegrationName } from '../../types/metric-integration';
import { MetricDO } from 'app/shared/services/backend/metric.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { SelectedValue, SelectItem } from 'app/shared/types/select-groups.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { getMetricSelectItems } from 'app/budget-object-details/components/details-metrics/metric-masters-list/metric-masters-list.component';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';

@Component({
  selector: 'external-metric-types-mapping-table',
  templateUrl: './external-metric-types-mapping-table.component.html',
  styleUrls: ['./external-metric-types-mapping-table.component.scss'],
})
export class ExternalMetricTypesMappingTableComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() rows: ExternalMetricTypesMappingTableRow[];
  @Input() plannuhMetricTypes: MetricDO[];
  @Input() products: ProductDO[];
  @Input() settings: ExternalMetricTypesMappingTableSettings;
  @Input() isPowerUser: boolean;
  @Output() changeMapping = new EventEmitter<ExternalMetricTypesMappingTableChangeEvent>();
  @Output() goToFunnelsPage = new EventEmitter<void>();

  masterMetricsSelectItems: Record<string, SelectItem[]> = {};
  metricNames = MetricIntegrationName;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this.rows.forEach(row => {
        this.processSelectItems(row);
      })
    }
  }

  processSelectItems(row: ExternalMetricTypesMappingTableRow) {
    const savedTypeIds = row.mappedPlannuhMetricTypes.map(mapped => mapped.id);
    const unusedMetrics: MetricType[] = this.plannuhMetricTypes
      .filter(metric => !savedTypeIds.includes(metric.id))
      .filter(metric => metric.with_currency === row.withCurrency)
      .map(CompanyDataService.convertMetricObject);
    this.masterMetricsSelectItems[row.name] = getMetricSelectItems(this.products, unusedMetrics);
  }

  public closeAddMappingsDropdown(dropdownTrigger: MatMenuTrigger) {
    dropdownTrigger.closeMenu();
  }

  public onRemoveMapping(metric: MappedMetricType, row: ExternalMetricTypesMappingTableRow) {
    this.changeMapping.emit({
      externalMetricTypeName: row.name,
      plannuhMetricIds: row.mappedPlannuhMetricTypes
        .filter(mapping => mapping.id !== metric.id)
        .map(mapping => mapping.id)
    });
  }

  public addMappings(selectedIds: SelectedValue[], dropdownTrigger: MatMenuTrigger, row: ExternalMetricTypesMappingTableRow) {
    const savedIds = row.mappedPlannuhMetricTypes.map(mapping => mapping.id);
    this.changeMapping.emit({
      externalMetricTypeName: row.name,
      plannuhMetricIds: [...savedIds, ...selectedIds]
    });
    dropdownTrigger.closeMenu();
  }

  navigateToFunnelsPage(dropdownTrigger: MatMenuTrigger) {
    dropdownTrigger.closeMenu();
    this.goToFunnelsPage.emit();
  }
}
