import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExpenseTableColumnItem } from '@spending/types/expense-page.type';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { getDiff } from 'recursive-diff';

@Component({
  selector: 'manage-columns-dialog',
  templateUrl: './manage-columns-dialog.component.html',
  styleUrls: ['./manage-columns-dialog.component.scss']
})
export class ManageColumnsDialogComponent {
  public notDraggableColumns: ExpenseTableColumnItem[] = [];
  public isStateChanged = false;
  private readonly defaultState;

  constructor(
    public dialogRef: MatDialogRef<ManageColumnsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any
  ) {
    this.notDraggableColumns = this.data?.columnItems.filter(item => !item.draggable);
    this.defaultState = createDeepCopy(this.data?.columnItems);
  }

  public onSelectionChange(item: ExpenseTableColumnItem, value: boolean): void {
    item.visible = value;
    this.isStateChanged = this.checkColumnsUpdate();
  }

  public dropItem(event: CdkDragDrop<ExpenseTableColumnItem[], any>): void {
    moveItemInArray(this.data.columnItems, event.previousIndex, event.currentIndex);
    this.isStateChanged = this.checkColumnsUpdate();
  }

  private checkColumnsUpdate(): boolean {
    return !!getDiff(this.defaultState, this.data.columnItems).length;
  }

}
