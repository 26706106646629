import { Injectable } from '@angular/core';
import { GoogleAdsDataService } from '../google-ads/google-ads-data.service';
import { HubspotDataService } from '../hubspot/hubspot-data.service';
import { SalesforceDataService } from '../salesforce/salesforce-data.service';
import { LinkedInDataService } from '../linkedin/linkedin-data.service';
import { MetricIntegrationName } from '../types/metric-integration';
import { FacebookDataService } from '../facebook-ads/facebook-data.service';

@Injectable()
export class MetricIntegrationsProviderService {
  private providersList = {
    [MetricIntegrationName.Salesforce]: this.salesforceDataService,
    [MetricIntegrationName.Hubspot]: this.hubspotDataService,
    [MetricIntegrationName.GoogleAds]: this.googleAdsDataService,
    [MetricIntegrationName.LinkedinAds]: this.linkedInDataService,
    [MetricIntegrationName.FacebookAds]: this.facebookDataService,
  };

  constructor(
    private readonly googleAdsDataService: GoogleAdsDataService,
    private readonly hubspotDataService: HubspotDataService,
    private readonly salesforceDataService: SalesforceDataService,
    private readonly linkedInDataService: LinkedInDataService,
    private readonly facebookDataService: FacebookDataService,
  ) {
  }

  metricIntegrationProviderByType(integrationType: MetricIntegrationName) {
    const provider = this.providersList[integrationType]
    if (!provider) {
      console.error( `Missing provider for "${integrationType}" in MetricIntegrationsProviderService`)
      return;
    }
    return provider;
  }
}
