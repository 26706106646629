<div class="amount-control" [class.disabled]="disabled" [class.is-focused]="isFocused">
  <div class="prefix" (click)="setFocus(true)" *ngIf="currency">{{ currency }}</div>
  <input
    type="text"
    [ngModel]="value"
    currencyInput
    currencyMask
    blurOnEnterKeydown
    [options]="currencyMaskOptions"
    [disabled]="disabled"
    (focus)="setFocus(true)"
    (blur)="setFocus(false); handleChange()"
    #input
  >
</div>
