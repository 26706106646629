import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { SegmentGroupDO } from '../../types/segment-group.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

@Injectable({
  providedIn: 'root'
})
export class SegmentGroupService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    segmentGroup: 'segment_group/',
  };

  getSegmentGroups(budgetId: number): Observable<SegmentGroupDO[]> {
    return this.http.get<SegmentGroupDO[]>(
      `${this.apiV2Url}${this.apiPaths.segmentGroup}`,
      getRequestOptions({ budget: budgetId })
    );
  }

  getSegmentGroup(groupId: number): Observable<SegmentGroupDO> {
    return this.http.get<SegmentGroupDO>(`${this.apiV2Url}${this.apiPaths.segmentGroup}${groupId}/`);
  }

  createSegmentGroup(group: Partial<SegmentGroupDO>): Observable<SegmentGroupDO> {
    return this.http.post<SegmentGroupDO>(
      `${this.apiV2Url}${this.apiPaths.segmentGroup}`,
      JSON.stringify(group)
    );
  }

  updateSegmentGroup(groupId: number, group: Partial<SegmentGroupDO>): Observable<SegmentGroupDO> {
    return this.http.patch<SegmentGroupDO>(
      `${this.apiV2Url}${this.apiPaths.segmentGroup}${groupId}/`,
      JSON.stringify(group)
    );
  }

  deleteSegmentGroup(groupId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiV2Url}${this.apiPaths.segmentGroup}${groupId}/`);
  }
}
