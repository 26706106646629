export interface ExpensesSummary {
  totalCount: number,
  totalAmount: number,
  filteredCount: number,
  filteredAmount: number,
  selectedCount: number,
  selectedAmount: number,
}

export enum ExpensesSummaryGroup {
  Grand,
  Filtered,
  Selected,
  Search
}
