<div class="budget-table-controls">
  <button *ngIf="editableBudget" class="add-segment" (click)="addSegment.emit()">
    <fa-duotone-icon [icon]="['fad', 'plus-circle']"></fa-duotone-icon>
    Add Segment
  </button>

  <button class="toggle-projected" (click)="toggleProjected()">
    <fa-duotone-icon [icon]="['fad', showProjected ? 'eye-slash' : 'eye']"></fa-duotone-icon>
    {{ showProjected ? 'Hide' : 'Show' }} Projected Budget
  </button>
</div>
