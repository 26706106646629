<article class="shared-cost-rule" (clickOutside)="handleClickOutsideRule()" [ngClass]="{
  'is-not-editable': !this.permissions.editRule
}">
  <div class="scr-rule-actions">
    <div class="scr-delete-rule" *ngIf="permissions.editRule && permissions.deleteRule" (click)="handleRuleDelete()">
      Delete this rule <icon-cancel></icon-cancel>
    </div>
  </div>
  <section class="scr-table">
    <!-- TOOLTIPS -->
    <simple-tooltip customClassNames="scr-tooltip-name" [visible]="activeTooltip === tooltipTypes.name">
      You need to give this rule a name before you can activate it
    </simple-tooltip>
    <simple-tooltip [position]="TooltipPosition.Right" customClassNames="scr-tooltip-add-segment" [visible]="activeTooltip === tooltipTypes.addSegment">
      Add a Segment
    </simple-tooltip>
    <simple-tooltip customClassNames="scr-tooltip-budget" [visible]="activeTooltip === tooltipTypes.budget">
      You need to select a budget before you can activate this rule
    </simple-tooltip>
    <simple-tooltip [position]="TooltipPosition.Left" customClassNames="scr-tooltip-select-all-segments" [visible]="activeTooltip === tooltipTypes.selectAllSegments">
      You need to select all the segments before you can activate this rule
    </simple-tooltip>
    <simple-tooltip [position]="TooltipPosition.Left" customClassNames="scr-tooltip-total" [visible]="activeTooltip === tooltipTypes.total">
      Segment allocations must sum to exactly 100% in order to activate this rule
    </simple-tooltip>
    <simple-tooltip [position]="TooltipPosition.Left" customClassNames="scr-tooltip-retire" [visible]="activeTooltip === tooltipTypes.retire">
      All expenses, expense groups and campaigns must be detached from this rule before it can be retired
    </simple-tooltip>
    <simple-tooltip [position]="TooltipPosition.Right" [offsetX]="activeTooltipCoords.x" [offsetY]="activeTooltipCoords.y" customClassNames="scr-tooltip-rule-activated" [visible]="activeTooltip === tooltipTypes.ruleActivated">
      An Activated Rule cannot be modified.
    </simple-tooltip>
    <header class="scr-table-header scr-row">
      <div class="scr-col scr-budget dropdown" [ngClass]="{
        'is-open': activeDropdown && activeDropdown.type === fieldTypes.Budget,
        'is-not-allowed': !permissions.editRule
      }">
        <div
          #budgetElement
          class="scr-cell"
          tabindex="0"
          [ngClass]="{ 'focused': focusedFieldType === fieldTypes.Budget }"
          (mouseenter)="displayTooltip([tooltipTypes.ruleActivated], $event)"
          (mouseleave)="hideTooltip()"
        >
          <div class="dropdown-label">
            <div class="dropdown-value">{{ currentBudgetName || 'Select budget' }}</div>
          </div>
          <div class="scr-budget-list dropdown-list" *ngIf="activeDropdown && activeDropdown.type === fieldTypes.Budget">
            <options-select
              [availableOptions]="budgetOptions"
              (selectValue)="handleBudgetSelect($event)"
              (selectValueWithKeyboard)="handleBudgetSelect($event)"
            ></options-select>
          </div>
        </div>
      </div>
      <div class="scr-col scr-title" [ngClass]="{ 'is-not-allowed': !permissions.editRule }">
        <div
          class="scr-cell"
          tabindex="0"
          [ngClass]="{ 'focused': focusedFieldType === fieldTypes.Name }"
          (mouseenter)="displayTooltip([tooltipTypes.ruleActivated], $event)"
          (mouseleave)="hideTooltip()"
        >
          <div class="input">
            <textarea
              #nameElement
              placeholder="Name your Shared Cost Rule Here"
              [disabled]="!permissions.editRule"
              [ngModel]="rule.name"
              (focus)="handleRuleNameFocus()"
              (blur)="handleRuleNameUpdate($event)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="scr-col scr-status dropdown" [ngClass]="{
        'is-open': activeDropdown && activeDropdown.type === fieldTypes.Status,
        'is-inactive': currentStatus === statusTypes.Inactive,
        'is-not-allowed': !permissions.updateStatus
      }">
        <div #statusElement class="scr-cell"
           tabindex="0"
           [ngClass]="{ 'focused': focusedFieldType === fieldTypes.Status }"
           (mouseenter)="displayTooltip([tooltipTypes.budget, tooltipTypes.name, tooltipTypes.selectAllSegments, tooltipTypes.total, tooltipTypes.retire])"
           (mouseleave)="hideTooltip()"
        >
          <div class="dropdown-label" (click)="permissions.updateStatus && toggleActiveDropdown(fieldTypes.Status)">
            <div class="scr-status-icon"></div>
            {{ currentStatus }}
            <drop-down-arrow></drop-down-arrow>
          </div>
          <div class="dropdown-list" *ngIf="activeDropdown && activeDropdown.type === fieldTypes.Status">
            <options-select
              [availableOptions]="statusOptions"
              (selectValue)="handleStatusSelect($event)"
              (selectValueWithKeyboard)="handleStatusSelect($event)"
            ></options-select>
          </div>
        </div>
      </div>
      <div #addSegmentElement class="scr-add-button"
         tabindex="0"
         (click)="handleSegmentAdd()"
         (mouseenter)="displayTooltip([tooltipTypes.addSegment])"
         (mouseleave)="hideTooltip()"
         [ngClass]="{ 'is-not-allowed': !(permissions.editRule && permissions.addSegments), 'focused': focusedFieldType === fieldTypes.AddSegment }"
      >
        <icon-add-objects [isDisabled]="!(permissions.editRule && permissions.addSegments)"></icon-add-objects>
      </div>
    </header>

    <div class="scr-table-body" [ngClass]="{ 'has-overflow': rule.segments.length > maxVisibleSegments }" @blockInitialTransition>
      <div *ngFor="let segment of rule.segments; let i = index" class="scr-row" @rowTransition>
        <div class="scr-col scr-segment dropdown" [ngClass]="{
          'is-open': activeDropdown && activeDropdown.segment === segment && activeDropdown.type === fieldTypes.SegmentId,
          'is-not-allowed': !(permissions.editRule && permissions.editSegments)
        }">
          <div
            #segmentIdElement
            class="scr-cell"
            tabindex="0"
            [ngClass]="{ 'focused': focusedFieldType === fieldTypes.SegmentId && focusedSegmentIndex === i }"
            (mouseenter)="displayTooltip([tooltipTypes.ruleActivated], $event)"
            (mouseleave)="hideTooltip()"
          >
            <div class="dropdown-label" (click)="(permissions.editRule && permissions.editSegments) && toggleActiveDropdown(fieldTypes.SegmentId, segment)">
              <div class="dropdown-value">{{ segmentNamesMap[segment.id] || 'Select segment' }}</div>
              <drop-down-arrow></drop-down-arrow>
            </div>
            <div class="dropdown-list" *ngIf="activeDropdown && activeDropdown.segment === segment && activeDropdown.type === fieldTypes.SegmentId">
              <options-select
                [availableOptions]="segmentOptions"
                (selectValue)="handleSegmentSelect($event, segment);"
                (selectValueWithKeyboard)="handleSegmentSelect($event, segment)"
              ></options-select>
            </div>
          </div>
        </div>
        <div class="scr-col scr-value" [ngClass]="{ 'is-not-allowed': !permissions.editRule }">
          <div
            class="scr-cell"
            tabindex="0"
            [ngClass]="{ 'focused': focusedFieldType === fieldTypes.Cost && focusedSegmentIndex === i }"
            (mouseenter)="displayTooltip([tooltipTypes.ruleActivated], $event)"
            (mouseleave)="hideTooltip()"
          >
            <div class="input" [ngClass]="{ 'is-focused': (focusedField && focusedField.segment === segment && focusedField.type === fieldTypes.Cost) }">
              <input
                #costElement
                type="text"
                placeholder="00.00"
                currencyMask
                [disabled]="!permissions.editRule"
                [options]="currencyMaskOptions"
                [(ngModel)]="segment.cost"
                (focus)="handleFieldFocus({ focus: true, type: fieldTypes.Cost, segment: segment })"
                (blur)="handleSegmentValueChange(segment.cost, segment); handleFieldFocus({ focus: false, type: fieldTypes.Cost, segment: segment })"
              />
            </div>
          </div>
        </div>
        <div class="scr-col scr-actions" [ngClass]="{ 'is-not-allowed': !(permissions.editRule && permissions.deleteSegments) }">
          <div class="scr-action" *ngIf="permissions.editRule && permissions.deleteSegments" (click)="handleSegmentDelete(segment)">
            <icon-trash></icon-trash>
          </div>
        </div>
      </div>
    </div>

    <footer class="scr-table-footer scr-row">
      <div class="scr-col scr-segment">Active segments ({{ summary.activeSegments }})</div>
      <div class="scr-col scr-total" [ngClass]="{ 'is-correct': summary.isTotalCorrect }">
        <span class="scr-total-value">{{ summary.totalText }}%</span>
        <span class="scr-total-overflow" *ngIf="!summary.isTotalCorrect">
          {{ summary.overflowText }}%
        </span>
      </div>
      <div class="scr-col scr-instances" [ngClass]="{ 'has-instances': rule.instancesNumber > 0 }">
        Instances <span class="scr-instances-count">{{ rule.instancesNumber }}</span>
      </div>
    </footer>
  </section>
</article>
