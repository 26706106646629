import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';
import { ManageTableData, ManageTableRow } from '../components/manage-table/manage-table.types';
import { ManageTableDataService } from './manage-table-data.service';
import { ManageTableSelectionRecord, ManageTableSelectionState } from '../types/manage-table-selection-state.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Injectable()
export class ManageTableRecordInteractionsService {
  private _selectionState: ManageTableSelectionState;
  private _togglingState: Record<string, boolean> = {};
  private ERROR_MESSAGE = {
    NO_PARENT_OBJECT_FOUND: 'No parent object found'
  };
  public readonly selectionChanged$ = new Subject<ManageTableSelectionState>();

  constructor(
    private readonly tableDataService: ManageTableDataService
  ) {
    this.resetSelection();
    this.resetToggling();
  }

  private getActiveSelectionSet(item: ManageTableRow): Set<number> {
    switch (item.type) {
      case ManageTableRowType.Campaign:
        return this.selectionState.campaigns;

      case ManageTableRowType.ExpenseGroup:
        return this.selectionState.expGroups;

      case ManageTableRowType.Goal:
        return this.selectionState.goals;

      case ManageTableRowType.Segment:
        return this.selectionState.segments;

      case ManageTableRowType.SegmentGroup:
        return this.selectionState.segmentGroups;

      default:
        return new Set();
    }
  }

  private getSelectionRecord(id: string, lazyInit = true): any {
    if (!this.selectionState.records[id] && lazyInit) {
      this.selectionState.records[id] = {
        selectedChildren: new Set<string>(),
        value: null
      };
    }

    return this.selectionState.records[id];
  }

  private removeSelectionRecord(item: ManageTableRow) {
    Reflect.deleteProperty(this.selectionState.records, item.id);

    const activeSelectionSet = this.getActiveSelectionSet(item);
    activeSelectionSet.delete(item.objectId);
  }

  private defineParentSelectionValue(parentObject: ManageTableRow, parentSelection: ManageTableSelectionRecord): CheckboxValue {
    const selectedChildrenLengthMatch = parentObject.children?.length === parentSelection.selectedChildren?.size;

    if (!selectedChildrenLengthMatch) {
      return CheckboxValue.Indeterminate;
    }

    const selectedChildren = Array.from(parentSelection.selectedChildren);
    const everySelectedChildIsActive = selectedChildren.every((id) => {
      const nestedRecord = this.getSelectionRecord(id);
      return nestedRecord.value === CheckboxValue.Active;
    });

    return everySelectedChildIsActive
      ? CheckboxValue.Active
      : CheckboxValue.Indeterminate;
  }

  private propagateSelectionUp(item: ManageTableRow, recursive = true) {
    if (!item.parentId) {
      return;
    }

    const parentObject = this.tableDataService.getRecordById(item.parentId);
    if (!parentObject) {
      console.warn(this.ERROR_MESSAGE.NO_PARENT_OBJECT_FOUND, item.id);
      return;
    }

    const parentSelection = this.getSelectionRecord(parentObject.id);
    const activeSelectionSet = this.getActiveSelectionSet(parentObject);
    parentSelection.selectedChildren.add(item.id);

    const parentSelectionValue = this.defineParentSelectionValue(parentObject, parentSelection);
    parentSelection.value = parentSelectionValue;
    if (parentSelectionValue === CheckboxValue.Active) {
      activeSelectionSet.add(parentObject.objectId);
    } else {
      activeSelectionSet.delete(parentObject.objectId);
    }

    if (recursive) {
      this.propagateSelectionUp(parentObject);
    }
  }

  private propagateDeselectionUp(item: ManageTableRow, recursive = true) {
    if (!item.parentId) {
      return;
    }

    const parentObject = this.tableDataService.getRecordById(item.parentId);
    if (!parentObject) {
      console.warn(this.ERROR_MESSAGE.NO_PARENT_OBJECT_FOUND, item.id);
      return;
    }

    const parentRecord = this.getSelectionRecord(parentObject.id);
    const activeSelectionSet = this.getActiveSelectionSet(item);
    if (!this.getSelectionRecord(item.id, false)) {
      parentRecord.selectedChildren.delete(item.id);
    }

    activeSelectionSet.delete(parentObject.objectId);
    if (parentRecord.selectedChildren.size === 0) {
      this.removeSelectionRecord(parentObject);
    } else {
      parentRecord.value = CheckboxValue.Indeterminate;
    }

    if (recursive) {
      this.propagateDeselectionUp(parentObject);
    }
  }

  private selectRecord(item: ManageTableRow) {
    if (!item.objectId || item.isFilteredOut) {
      return;
    }

    const selectionRecord = this.getSelectionRecord(item.id);
    const activeSelectionSet =  this.getActiveSelectionSet(item);

    selectionRecord.value = CheckboxValue.Active;
    activeSelectionSet.add(item.objectId);

  }

  private deselectRecord(item: ManageTableRow) {
    this.removeSelectionRecord(item);
  }

  private selectRecordRecursively(item: ManageTableRow) {
    if (!item) {
      return;
    }

    item.children?.forEach(child => this.selectRecordRecursively(child));
    this.selectRecord(item);
  }

  private deselectRecordRecursively(item: ManageTableRow) {
    if (!item) {
      return;
    }

    item.children?.forEach(child => this.deselectRecordRecursively(child));
    this.deselectRecord(item);
  }

  private areAllRecordsSelected(): boolean {
    return Object.values(this.tableDataService.flatDataMap).length === Object.values(this.selectionState.records).length;
  }

  private isAnyRecordSelected(): boolean {
    return Object.keys(this.selectionState.records).length > 0;
  }

  public handleSelection(item: ManageTableRow, value: boolean) {
    if (value) {
      this.selectRecordRecursively(item);
      this.selectionState.selectAllValue = this.areAllRecordsSelected()
        ? CheckboxValue.Active
        : CheckboxValue.Indeterminate;
    } else {
      this.deselectRecordRecursively(item);
      this.selectionState.selectAllValue = this.isAnyRecordSelected()
        ? CheckboxValue.Indeterminate
        : CheckboxValue.NotActive;
    }
    this.selectionChanged$.next(this.selectionState);
  }

  public handleSelectAllChange(data: ManageTableData, value: boolean) {
    if (value) {
      data.forEach(item => this.selectRecordRecursively(item));
      this.selectionState.selectAllValue = CheckboxValue.Active;
    } else {
      this.resetSelection(false);
    }
    this.selectionChanged$.next(this.selectionState);
  }

  public handleToggleChange(item: ManageTableRow, value: boolean) {
    if (value) {
      Reflect.deleteProperty(this.togglingState, item.id);
    } else {
      this.togglingState[item.id] = true;
    }
  }

  public get selectionState() {
    return this._selectionState;
  }

  public get togglingState() {
    return this._togglingState;
  }

  public resetToggling() {
    this._togglingState = {};
  }

  public resetSelection(emitEvent = true) {
    this._selectionState = {
      records: {},
      goals: new Set<number>(),
      campaigns: new Set<number>(),
      expGroups: new Set<number>(),
      segments: new Set<number>(),
      segmentGroups: new Set<number>(),
      selectAllValue: CheckboxValue.NotActive
    };
    if (emitEvent) {
      this.selectionChanged$.next(this._selectionState);
    }
  }

  public implicitSelect(item: ManageTableRow) {
    const activeSelectionSet = this.getActiveSelectionSet(item);
    activeSelectionSet.add(item.objectId);
  }

  public implicitUnSelect(item: ManageTableRow) {
    const activeSelectionSet = this.getActiveSelectionSet(item);
    activeSelectionSet.delete(item.objectId);
  }
}
