import { inject, Injectable } from '@angular/core';
import { ProgramService } from '@shared/services/backend/program.service';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { BaseBudgetObjectAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/base-budget-object-amounts-loader';

@Injectable()
export class ExpenseGroupAmountsLoader extends BaseBudgetObjectAmountsLoader {
  readonly rowType = ManageTableRowType.Segment;

  constructor() {
    super(inject(ProgramService));
  }
}
