import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent {
  @Input() placeholder = '';
  @Output() changeText: EventEmitter<string> = new EventEmitter<string>();
  value = '';
  lastEmittedValue = '';

  updateText() {
    this.value = this.value.trim();
    if (this.lastEmittedValue !== this.value) {
      this.lastEmittedValue = this.value;
      this.changeText.emit(this.value);
    }
  }

  handleClearValue() {
    this.value = '';
    this.updateText();
  }
}
