<header mat-dialog-title>Set the SaaSOptics Customer ID</header>
<div mat-dialog-content>
  <div class="notification">This is the unique customer ID from SaaSOptics that is used to identify the customer when
    logging events to ChurnZero. Please enter the ID here:
  </div>

  <input type="text"
         [(ngModel)]="saaSOpticsId"
         [autoFocus]="true"
         placeholder="SaaSOptics ID"
  />
</div>
<div mat-dialog-actions class="dialog-actions">
  <button
    (click)="dialogRef.close();"
    mat-stroked-button
    color="primary"
  >
    Cancel
  </button>
  <button
    (click)="enable()"
    mat-flat-button
    color="primary"
  >
    OK
  </button>
</div>
