<confirmation-dialog>
  <div class="budget-settings-summary">
    <budget-settings-summary-bar
      [budgetTotal]="context.budgetTotal"
      [allocatedTotal]="context.allocatedTotal"
      [currency]="context.currency"
      [minimized]="true"
      [clickable]="false"
      [withConfetti]="false"
    ></budget-settings-summary-bar>
  </div>
  <p class="dialog-info">
    Your Grand Total of planned budget is greater than
    the total budget amount defined.  Do you want to
    <b>increase</b> the Grand Total by
    {{ context.currency?.symbol }} {{ -context.remainingBudget | number: '1.2-2' }}
    or <b>keep editing</b>?
  </p>
</confirmation-dialog>
