import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { calculateShareValue } from 'app/shared/utils/common.utils';
import { UpdateMetricsValuesRecord } from '../../types/update-metrics-data-item.interface';

@Component({
  selector: 'metric-progress-bar',
  templateUrl: './metric-progress-bar.component.html',
  styleUrls: ['./metric-progress-bar.component.scss']
})
export class MetricProgressBarComponent implements OnChanges {
  @Input() data: UpdateMetricsValuesRecord;
  public totalShare = 0;
  public currentTargetShare = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data != null && changes.data) {
      this.calculateShares();
    }
  }

  private calculateShares() {
    const { total, currentTarget, target } = this.data;
    const totalValue = target || total;

    this.totalShare = calculateShareValue(total, totalValue);
    if (currentTarget != null) {
      this.currentTargetShare = calculateShareValue(currentTarget, totalValue);
    }
  }
}
