<div class="enable-sso-dialog">
  <header mat-dialog-title>Custom Domain Name for SSO</header>
  <div mat-dialog-content>
    <div class="notification">Please enter the custom domain (vanity domain) that we should use for this customer.
      It is critical that this be globally unique among Planful customers, and it should be intuitive to remember for the customer’s team.
      As such, please take the domain from the customer’s website, such as ‘webfleet’ from <b><i>webfleet.com</i></b> , or ‘bmoharris’ from <b><i>bmoharris.com</i></b>.
      This will then be used to log into Planful with SSO, such as ‘<b><i>webfleet.planful.com</i></b>’.
    </div>
    <div class="domain">
      <input type="text"
             [(ngModel)]="customDomain"
             #customDomainCtrl="ngModel"
             [autoFocus]="true"
             required
             [minlength]="MIN_DOMAIN_LENGTH"
             [uniqueDomainPrefix]="context?.existingDomains"
             placeholder="companyname"
      />
      <span>{{domainSuffix}}</span>
    </div>
    <div class="validation-errors"
         *ngIf="customDomainCtrl.touched && customDomainCtrl.errors">
      <div *ngIf="customDomainCtrl.errors.minlength">* Domain prefix should be minimum {{ MIN_DOMAIN_LENGTH }} characters length</div>
      <div *ngIf="customDomainCtrl.errors.required">* Domain prefix is required</div>
      <div *ngIf="customDomainCtrl.errors[uniqueDomainPrefixErrorName]">* Domain prefix must be globally unique among Planful customers</div>
    </div>

  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button
      (click)="dialogRef.close();"
      mat-stroked-button
      color="primary"
    >
      Cancel
    </button>
    <button
      (click)="enableSSO(!customDomainCtrl.invalid)"
      mat-flat-button
      color="primary"
      [disabled]="customDomainCtrl.invalid"
    >
      OK
    </button>
  </div>
</div>
