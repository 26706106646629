<div class="integration-setup-dialog" [class.is-enabled]="integrationEnabled">
  <div class="dialog-header">
    <integration-setup-logo [source]="integrationSource"></integration-setup-logo>
    <button
      class="ml-a"
      *ngIf="integrationEnabled && !integrationActive && !waitingForIntegrationAuth"
      color="primary"
      mat-stroked-button
      (click)="openDisableChoiceDialog()"
      [disabled]="isProcessing"
    >
      Disable
    </button>
    <loader *ngIf="integrationActive || waitingForIntegrationAuth" [isVisible]="true" [isInline]="true"></loader>
    <div class="dialog-header-info">
      <div>
        <div [ngClass]="{'integration-title': true, 'reauth-error': isReauthRequired}">{{ integrationEnabled && isReauthRequired ? 'Authentication Expired' : (integrationEnabled ? 'Integration Enabled' : 'Enable Integration')}}</div>
        <div class="integration-subtitle" *ngIf="integrationAccountEmail">for <b>{{ integrationAccountEmail }}</b></div>
      </div>
      <div *ngIf="integrationEnabled && !integrationActive && !waitingForIntegrationAuth" class="refresh-auth">
        <ng-container *ngIf="isGoogleIntegration; else defaultAuthRefresh">
          <p class="refresh-auth-label">Refresh authentication with</p>
          <button mat-flat-button
                  class="google-sign-btn"
                  (click)="onAuthRefresh()">
            <btn-content-google>Google</btn-content-google>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="dialog-content">
    <div class="integration-info">

      <div class="integration-control" *ngIf="isAdsIntegration">
        <p class="integration-control-label">Please give your integration a name</p>
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <input matInput
                 [maxlength]="MAX_NAME_LENGTH"
                 [formControl]="integrationEntityName">
          <span matSuffix>{{ integrationEntityName.value?.length || 0 }}/{{ MAX_NAME_LENGTH }}</span>
          <mat-error *ngIf="integrationEntityName.hasError('required')">Name is required</mat-error>
        </mat-form-field>
      </div>

      <div class="description-warning" *ngIf="warningMessage">
        <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
        <p>{{ warningMessage }}</p>
        <fa-icon class="close-btn" [icon]="['far', 'times']" (click)="warningMessage = null"></fa-icon>
      </div>

      <div class="integration-control" *ngIf="integrationAccounts?.length">
        <p class="integration-control-label mb-05">Accounts for integration *</p>
        <div *ngIf="selectedAccountNames?.length" class="account-names">
          <div *ngFor="let name of selectedAccountNames">{{ name }}</div>
        </div>
        <div *ngIf="!hasSelectedAccountIds">
          <mat-form-field appearance="outline" class="p-form-field-outline">
            <mat-select multiple (valueChange)="setSelectedAccountIds($event)" [formControl]="selectedAccountsControl">
              <div class="mat-option select-all">
                <mat-checkbox color="primary"
                              [checked]="allAccountsSelectionState === CheckboxValue.Active"
                              [indeterminate]="allAccountsSelectionState === CheckboxValue.Indeterminate"
                              (change)="toggleAllAccountsSelection($event.checked)"
                              [disableRipple]="true">
                  All accounts
                </mat-checkbox>
              </div>
              <mat-option *ngFor="let account of availableIntegrationAccounts" [value]="account.id">
                {{ account.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="selectedAccountsControl.hasError('required')">At least one account is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="integration-control mb-05" *ngIf="isAdsIntegration && hierarchyItems">
        <p class="integration-control-label">Parent</p>
        <hierarchy-select
          [items]="hierarchyItems"
          [selectControlExternal]="segmentControl"
          [config]="selectSegmentsConfig"
          [placeholder]="selectSegmentsConfig.emptyValueLabel"
        ></hierarchy-select>
      </div>

      <div class="integration-control" *ngIf="isAdsIntegration">
        <p class="integration-control-label mb-05">Import expenses starting the</p>
        <div class="mb-1">
          <mat-radio-group
            class="column mb-1"
            *ngIf="!integrationEnabled; else selectedTimeframe"
            [formControl]="integrationImportStart">
            <mat-radio-button
              color="primary"
              [disabled]="disableCurrentTimeframeIntegration"
              [value]="importStart.CURRENT_TF">
              Beginning of this {{ selectedBudget?.type }}
            </mat-radio-button>
            <mat-radio-button
              color="primary"
              [value]="importStart.BUDGET">
              Beginning of my Fiscal Year
            </mat-radio-button>
          </mat-radio-group>
          <ng-template #selectedTimeframe>
            <span *ngIf="importDataFrom">1st of <span class="capitalize">{{ importDataFrom }}</span></span>
            <span *ngIf="!importDataFrom">{{ selectedBudget?.budget_from | date: 'mediumDate' }}</span>
          </ng-template>
        </div>
      </div>

      <div class="integration-control" *ngIf="isLinkedinIntegration">
        <p class="integration-control-label mb-05">Map LinkedIn...</p>
        <div class="mb-1">
          <mat-radio-group
            class="column mb-1"
            [formControl]="integrationCampaignsGrouping">
            <mat-radio-button
              color="primary"
              [value]="false">
              Campaigns
            </mat-radio-button>
            <mat-radio-button
              color="primary"
              [value]="true">
              Campaign Groups
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="integration-control" *ngIf="isAdsIntegration">
        <div class="d-flex ai-center mb-2 mt-1">
          <mat-slide-toggle
            [(ngModel)]="syncAllocatedAmounts"
          ></mat-slide-toggle>
          <p class="integration-control-option ml-05 mr-1">Set budget based on daily limit</p>
          <fa-icon
            class="info-icon"
            [icon]="['fas', 'info-circle']"
            [matTooltip]="amountsSyncTooltip"
            matTooltipPosition="above"
            matTooltipClass="dark-theme-tooltip simple multi-line"
            matTooltipShowDelay="800"
          ></fa-icon>
        </div>
      </div>

      <div>
        <span class="integration-control-label">Define the best time to sync {{ integrationLabel }} data with Planful</span>
      </div>
      <div class="integration-form">
        <loader [isVisible]="isLoading" size="20px"></loader>
        <integration-schedule-setup-form
          [companyId]="companyId"
          [timezones]="timezones"
          [settings]="integrationScheduleSettings"
          (settingsChanged)="handleSettingsChange($event)"
        ></integration-schedule-setup-form>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="closeDialog()">Cancel</button>
    <button mat-flat-button
            color="primary"
            [ngClass]="{ 'reset-btn-styles': isAdsIntegration && !integrationEnabled }"
            [disabled]="isSubmitDisabled"
            (click)="onConfirm()">
      <ng-container *ngTemplateOutlet="isAdsIntegration && !integrationEnabled ? adsSignIn : defaultText;"></ng-container>
    </button>
  </div>
</div>

<ng-template #defaultText>
  {{ integrationEnabled ? 'Save' : 'Next' }}
</ng-template>

<ng-template #adsSignIn>
  <btn-content-google *ngIf="onGoogleIntegrating">Sign in with Google</btn-content-google>
  <btn-content-linkedin *ngIf="onLinkedinIntegrating">Sign in with LinkedIn</btn-content-linkedin>
  <btn-content-facebook *ngIf="onFacebookIntegrating">Sign in with Facebook</btn-content-facebook>
</ng-template>

<ng-template #defaultAuthRefresh>
  <button mat-button
          color="primary"
          class="refresh-auth-btn"
          (click)="onAuthRefresh()">
    Refresh authentication
  </button>
</ng-template>
