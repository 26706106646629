import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  BudgetAllocationAction,
  OnExecuteHandler,
  OnUndoHandler
} from '../budget-allocation-gestures-actions/budget-allocation-action.types';
import { BudgetAllocationBinaryActionTarget } from '../types/budget-allocation-binary-action-target.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { BudgetAllocationCellGesturesEvent } from '../components/budget-allocation-cell/budget-allocation-cell.types';
import { ActiveToast } from 'ngx-toastr';


@Injectable()
export class BudgetAllocationActionsService<TDataSource> implements OnDestroy {
  private activeToastRef: ActiveToast<any>;
  private snackBarDuration = 3000;
  private readonly destroy$ = new Subject<void>();
  private readonly onDrop = new BehaviorSubject(null);
  private dndTargets: BudgetAllocationBinaryActionTarget<TDataSource> = {
    from: null,
    to: null
  };
  public readonly onDrop$ = this.onDrop.asObservable();
  public isDragging = false;

  constructor(
    private readonly utilityService: UtilityService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private showNotification(message: string, undoCallback: Function) {
    if (this.activeToastRef) {
      this.utilityService.hideToastr(this.activeToastRef.toastId);
      this.activeToastRef = null;
    }

    this.activeToastRef = this.utilityService.showCustomToastr(
      message,
      'Undo',
      { timeOut: this.snackBarDuration }
    );

    if (undoCallback) {
      this.activeToastRef.onAction
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(() => {
        undoCallback();
      });
    }
  }

  public trackDragStart(dataSource: TDataSource, gesturesEvent: BudgetAllocationCellGesturesEvent) {
    this.dndTargets.from = {
      dataSource,
      ...gesturesEvent
    };
    this.dndTargets.to = null;
    this.isDragging = true;
    if (this.activeToastRef) {
      this.utilityService.hideToastr(this.activeToastRef.toastId);
      this.activeToastRef = null;
    }
  }

  public trackDragEnd() {
    this.isDragging = false;
    this.dndTargets.from = null;
    this.dndTargets.to = null;
  }

  public trackDrop(dataSource: TDataSource, gesturesEvent: BudgetAllocationCellGesturesEvent) {
    this.dndTargets.to = {
      dataSource,
      ...gesturesEvent
    };
    this.isDragging = false;
    this.onDrop.next(true);
  }

  public executeAction(
    action: BudgetAllocationAction<any>,
    executedCb?: OnExecuteHandler,
    undoneCb?: OnUndoHandler,
    messageTrigger?: Subject<void>
  ) {
    if (!action.execute) {
      return;
    }

    if (undoneCb) {
      action.setOnUndone(undoneCb);
    }

    if (messageTrigger) {
      messageTrigger
        .pipe(take(1))
        .subscribe(
          () => {
            this.showNotification(action.getOnExecutedMessage(), () => action.undo(true));
          }
        );
    }

    action.setOnExecuted((message, undoCb) => {
      if (message && !messageTrigger) {
        this.showNotification(message, undoCb);
      }
      if (executedCb) {
        executedCb(message, undoCb);
      }
    });
    action.execute();
  }

  public getDndTargets(): BudgetAllocationBinaryActionTarget<TDataSource> {
    return { ...this.dndTargets };
  }
}
