import { Observable } from 'rxjs';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';

export const observeFromHttpEvents = (events: Observable<HttpEvent<any>>, observeWhat: string) => {
  const observeByType = {
    response: res$ => res$,
    body: res$ => res$.pipe(map((res: HttpResponse<any>) => res.body))
  };
  const observe = observeByType[observeWhat];
  return observe ?
    observe(events.pipe(filter((event: HttpEvent<any>) => event instanceof HttpResponse))) :
    events;
}
