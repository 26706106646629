import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'revenue-to-profit-input',
  templateUrl: './revenue-to-profit-input.component.html',
  styleUrls: ['./revenue-to-profit-input.component.scss']
})
export class RevenueToProfitInputComponent implements AfterViewInit, OnDestroy {
  public step = 0.01;
  public minVal = 0;
  public maxVal = 100;
  public control = new UntypedFormControl('', );
  private readonly destroy$ = new Subject<void>();

  @Output() valueChange = new EventEmitter();
  @Output() focusChanged = new EventEmitter<boolean>();
  @ViewChild('input') private input: ElementRef;
  @Input() set disabled(value: boolean) {
    value ? this.control.disable() : this.control.enable();
  }
  @Input() set value(val: number) {
    this.control.setValue(val);
    this.updateValidators(val);
  }

  constructor() {
    this.control.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(val => {
      this.updateValidators(val);
    });
  }

  ngAfterViewInit(): void {
    if (this.control.value == null) {
      this.setFocus();
    }
  }

  updateValue(): void {
    if (this.control.value !== null) {
      this.prepareValue();
    }
    this.focusChanged.emit(false);
    this.valueChange.emit(this.control.value);
  }

  onLabelClick(e: MouseEvent): void {
    e.stopPropagation();
    this.setFocus();
  }

  setFocus(): void {
    this.input?.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateValidators(val: number): void {
    const validators = [Validators.min(this.minVal), Validators.max(this.maxVal)];
    val != null ? this.control.setValidators(validators) : this.control.removeValidators(validators);
  }

  private prepareValue(): void {
    let value = Math.round(this.control.value * 100) / 100;
    value = value > this.maxVal ? this.maxVal : value < this.minVal ? this.minVal : value;
    this.control.setValue(value);
  }
}
