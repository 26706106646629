import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import { SpendingTableBaseComponent } from '@spending/components/spending/spending-table-base';
import { InvoiceRowData } from '@spending/services/invoice-table-data.service';
import { ExpensePageSelectionService } from '@spending/services/expense-page-selection.service';
import { SpendingSidebarService } from '@spending/services/spending-sidebar.service';
import { SpendingService } from '@spending/services/spending.service';
import { Router } from '@angular/router';
import { ExpenseTableColumn, ExpenseTableColumnItem } from '@spending/types/expense-page.type';

@Component({
  selector: 'invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceTableComponent extends SpendingTableBaseComponent<InvoiceRowData> {
  private invoiceColumns: ExpenseTableColumnItem[] = [
    { id: ExpenseTableColumn.NAME, title: 'Invoice Name', align: 'flex-start' },
    { id: ExpenseTableColumn.INVOICE, title: 'Invoice #', align: 'flex-start' },
    { id: ExpenseTableColumn.ACTUAL, title: 'Amount', align: 'flex-end' },
    { id: ExpenseTableColumn.TIMEFRAME, title: 'Month', align: 'center' },
    { id: ExpenseTableColumn.CREATED_DATE, title: 'Submitted', align: 'center' },
    { id: ExpenseTableColumn.OWNER, title: 'By', align: 'center' },
  ];

  protected readonly isDefaultCell = {
    [ExpenseTableColumn.INVOICE]: true,
    [ExpenseTableColumn.TIMEFRAME]: true,
    [ExpenseTableColumn.CREATED_DATE]: true,
    [ExpenseTableColumn.OWNER]: true,
  };

  @Input() set rowsData(rows: InvoiceRowData[]) {
    this.rows = rows || [];
    this.selectableRows = this.rows.filter(row => this.isRowSelectable(row));
  }

  constructor(
    private readonly expensePageSelectionService: ExpensePageSelectionService,
    private readonly spendingSidebarService: SpendingSidebarService,
    private readonly spendingService: SpendingService,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef
  ) {
    super(
      router,
      zone,
      cdr,
      spendingService,
      spendingSidebarService,
      expensePageSelectionService,
    );
    this.visibleColumns = this.invoiceColumns;
  }

  protected isRowSelectable(row: InvoiceRowData): boolean {
    return true;
  }

  protected onReviewBtnClick(row: InvoiceRowData, e: MouseEvent): void {
    e.stopPropagation();
    this.onReviewInvoice(row);
  }

  protected onReviewInvoice(row: InvoiceRowData): void {
    this.openDetails.emit(row.rowId);
  }
}
