import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import {TableData, TableViewRow} from 'app/shared/components/table-view/table-data.type';
import { TableDataBuilderService } from 'app/shared/components/table-view/table-data-builder.service';
import { Configuration } from 'app/app.constants';

const STATUS_FIELD_NAME_TOTAL = 'total';
const BUDGET_ROW_TITLE = 'Budget';
const BUDGET_REMAINING_TITLE = 'Remaining /';
const BUDGET_REMAINING_SUBTITLE = 'Over Budget';

@Component({
  selector: 'budget-timeline-table',
  templateUrl: './budget-timeline-table.component.html',
  styleUrls: ['./budget-timeline-table.component.scss'],
})
export class BudgetTimelineTableComponent implements OnInit, OnChanges {
  @Input() bars: {key: string, value: string}[];
  @Input() timeframeGraphData: any[];
  @Input() selectedTimeframeNames: string[] = [];
  @Input() selectedStatusNames: string[] = [];

  rowTitlesByFieldName: {[key: string]: string};

  columnTitles: string[];
  columnTitleByFieldName: {[key: string]: string};
  columnSubtitleByFieldName: {[key: string]: string};
  overBudgetColumns: {[key: string]: string};
  columnsFields: string[] = [];
  rows: TableViewRow[] = [];
  expenseStatusFieldNames: string[] = [];

  constructor(private _configuration: Configuration, private tableDataBuilder: TableDataBuilderService) {
    const { statusNames, statusFields } = this._configuration;

    this.rowTitlesByFieldName = {
      [STATUS_FIELD_NAME_TOTAL]: BUDGET_ROW_TITLE,
      [statusFields.planned]: statusNames.planned,
      [statusFields.committed]: statusNames.committed,
      [statusFields.closed]: statusNames.closed,
      [statusFields.underBudget]: statusNames.underBudget,
      [statusFields.budgetRemainingAmount]: BUDGET_REMAINING_TITLE
    };

    this.columnSubtitleByFieldName = {
      [statusFields.budgetRemainingAmount]: BUDGET_REMAINING_SUBTITLE
    }

    this.expenseStatusFieldNames = [statusFields.planned, statusFields.committed, statusFields.closed];
  }

  ngOnInit() {
    this.initTableData()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      'bars' in changes ||
      'selectedTimeframeNames' in changes ||
      'timeframeGraphData' in changes ||
      'selectedStatusNames' in changes
      ) {
      this.initTableData()
    }
  }

  get classNameByStatusField() {
    const { budgetRemainingAmount, planned, committed, closed } = this._configuration.statusFields;
    return {
      [budgetRemainingAmount]: 'over-budget boldRow',
      [planned]: 'left-offset planned-row',
      [committed]: 'left-offset committed-row',
      [closed]: 'left-offset closed-row'
    }
  }

  setOverBudgetColumns(tableData) {
    const { budgetRemainingAmount } = this._configuration.statusFields;
    const overBudgetRow = tableData.find(td => td.fieldName === budgetRemainingAmount);
    if (overBudgetRow) {
      this.overBudgetColumns = {};
      Object.keys(overBudgetRow.data).forEach(columnName => {
        if (overBudgetRow.data[columnName] < 0) {
          this.overBudgetColumns[columnName] = overBudgetRow.data[columnName];
        }
      })
    }
  }

  prepareData() {
    const filteredRows = this.getFilteredRows();
    const tableData = filteredRows.map(statusName => ({
        name: this.rowTitlesByFieldName[statusName],
        fieldName: statusName,
        className: this.classNameByStatusField[statusName],
        data: this.timeframeGraphData.reduce((store, data) => {
          const columnKey = (data.company_budget_alloc_name as string).toLowerCase();
          store[columnKey] = store[columnKey] ? store[columnKey] + data[statusName] : data[statusName];
          return store;
        }, {})
      })
    );
    this.addExpensesTotalRow(tableData);
    this.setOverBudgetColumns(tableData);
    return tableData;
  }

  addExpensesTotalRow(tableData: TableData[]) {
    const expenseRows = tableData.filter(dataRow => this.expenseStatusFieldNames.includes(dataRow.fieldName));
    if (expenseRows.length > 0) {
      const expenseTableRowData =
        expenseRows
          .reduce((store, dataRow) => {
            Object.keys(dataRow.data).forEach(key => {
              store[key] = store[key] ? store[key] + dataRow.data[key] : dataRow.data[key];
            });
            return store;
          }, {});

      const expenseTableRow = {
        name: 'Total Expenses',
        fieldName: 'totalExpenses',
        className: 'boldRow',
        data: expenseTableRowData
      };
      const lastExpenseRowIndex = Math.max(...expenseRows.map(expenseRow => tableData.indexOf(expenseRow))) + 1;
      tableData.splice(lastExpenseRowIndex, 0, expenseTableRow);
    }
  }

  getFilteredRows() {
    const { underBudget } = this._configuration.statusFields;
    const rows =
      Object.keys(this.rowTitlesByFieldName)
        .filter(fieldName => fieldName === STATUS_FIELD_NAME_TOTAL || !this.selectedStatusNames.length || this.selectedStatusNames.includes(fieldName));
    return rows
      .filter(status =>
        status !== underBudget || this.timeframeGraphData.some(tfData => tfData[status] > 0)
      )
  }

  getColumnsFields() {
    if (!this.selectedTimeframeNames.length) {
      return Object.keys(this.columnTitleByFieldName);
    }
    const filtered = this.timeframeGraphData.filter(tfData => this.selectedTimeframeNames.includes(tfData.company_budget_alloc_name));
    return filtered.map(tfData => tfData.company_budget_alloc_name.toLowerCase())
  }

  initTableData() {
    this.columnTitleByFieldName = this.bars.reduce((res, bar) => {
      res[bar.value.toLowerCase()] = bar.key;
      return res;
    }, {});

    this.columnsFields = this.getColumnsFields();
    this.columnTitles = ['', ...this.columnsFields.map(fieldName => this.columnTitleByFieldName[fieldName]), 'Total'];
    const data = this.prepareData();
    this.rows = this.tableDataBuilder
      .create()
      .setTableColumns(this.columnsFields)
      .setTableData(data)
      .getTableRows(false, true, false);
  }
}
