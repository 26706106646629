import { Component } from '@angular/core';
import { Configuration } from 'app/app.constants';

@Component({
  selector: 'custom-domain',
  templateUrl: './custom-domain.component.html',
  styleUrls: ['./custom-domain.component.scss']
})
export class CustomDomainComponent {
  public readonly envDomain: string;
  public readonly protocol = 'https://';
  private readonly devModeHostName = 'localhost';

  constructor(
    private readonly configuration: Configuration
  ) {
    this.envDomain = configuration.envDomain;
  }

  goToCustomDomain(domainPrefix: string, valid: boolean) {
    if (valid) {
      const customUrl = `${this.protocol}${domainPrefix}.${this.envDomain}`;
      const devMode = window.location.hostname === this.devModeHostName;
      window.open(devMode ? window.location.origin : customUrl, '_self');
    }
  }
}
