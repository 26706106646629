import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { UserManager } from 'app/user/services/user-manager.service';
import { UserService } from 'app/shared/services/backend/user.service';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MenuPanelItem } from '../menu-panel/menu-panel.type';
import { AppConfigService } from '@common-lib/services/app-config.service';
import { AppConfig } from 'app/app-config.interface';
import { UserDO } from '@shared/types/user-do.interface';

type ProfileTheme = 'purple' | 'blue';

@Component({
  selector: 'profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  currentUser: UserDO;
  profilePicUrl = '';
  @Input() theme: ProfileTheme = 'purple';
  @Input() disabled = false;

  items: MenuPanelItem[] = [];
  constructor(
    private readonly userManager: UserManager,
    private readonly userService: UserService,
    private readonly config: Configuration,
    private readonly router: Router,
    private readonly appConfig: AppConfigService<AppConfig>,
  ) {
    this.generateMenu();
  }

  ngOnInit() {
    this.userManager.currentUser$
      .pipe(
        filter(user => user != null),
        map(user => [user, !this.currentUser || user.user_profile_detail.file !== this.currentUser.user_profile_detail.file]),
        tap(([user]: [UserDO, boolean]) => this.currentUser = user),
        filter(([, profilePicChanged]: [UserDO, boolean]) => profilePicChanged),
        switchMap(([user]) => this.userService.getUserProfilePictureUrl(user.id)),
        takeUntil(this.destroy$)
      )
      .subscribe(userProfilePicUrl => this.profilePicUrl = userProfilePicUrl)
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private generateMenu() {
    this.items = [
      {
        label: 'My Profile',
        faIcon: ['fas', 'user'],
        action: () => this.router.navigate(
          [this.config.ROUTING_CONSTANTS.USER], { queryParamsHandling: 'preserve' }
        )
      },
      {
        label: 'Help Center',
        faIcon: ['fas', 'circle-question'],
        action: () => window.open(this.appConfig.config.help_url, '_blank'),
        hidden: !this.appConfig.config?.help_url
      },
      {
        label: 'Training',
        faIcon: ['fad', 'graduation-cap'],
        action: () => this.redirectToSsoForm('skilljar')
      },
      {
        label: 'Planful Support',
        faIcon: ['fas', 'comments'],
        action: () => window.open(this.appConfig.config.support_url, '_blank'),
        hidden: !this.appConfig.config?.support_url
      },
      {
        label: 'Sign Out',
        isDivider: true,
        faIcon: ['fas', 'person-from-portal'],
        action: () => this.userManager.logout()
      }
    ];
  }

  redirectToSsoForm(federation:string): void{
    const url = this.router.serializeUrl(
      this.router.createUrlTree([this.config.ROUTING_CONSTANTS.SSO], { queryParams: { 'federation': federation }})
    );
    window.open(url, '_blank');
  }
}
