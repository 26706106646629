<ng-container *ngIf="{value: zoom$ | async} as zoom">
    <div *ngIf="{value: page$ | async} as page" class="pdf-container">
        <pdf-viewer
          [src]="pdfSrc$ | async"
          [page]="page.value"
          [original-size]="isOriginalSize"
          [show-all]="true"
          [fit-to-page]="isFitToPage"
          [zoom]="zoom.value"
          [zoom-scale]="zoomScale"
          [stick-to-page]="false"
          (after-load-complete)="afterLoadComplete($event)"
          (pageChange)="pageChanged($event)"
          (pages-initialized)="pageRendered()"
          (error)="onError($event)"
          [autoresize]="true"
          [show-borders]="false"
          style="width: 100%; height: 100%;"
        ></pdf-viewer>
        <ng-container *ngIf="isLoaded$ | async">
            <div class="manage-panel-wrap">
                <div class="manage-panel-inner">
                    <div class="current-page-section">
                        <div class="current">{{page.value}}</div>
                        <div class="total">/ {{totalPages$ | async}}</div>
                    </div>
                    <div class="manage-zoom-section">
                        <button (click)="zoomOut(zoom.value, zoomStep)" class="manage-panel-btn">-</button>
                        <div class="zoom-value">{{(zoom.value * 100) | number: '1.0-0'}}%</div>
                        <button (click)="zoomIn(zoom.value, zoomStep)" class="manage-panel-btn">+</button>
                    </div>
                    <div class="fit-section">
                        <button (click)="fitView()" class="manage-panel-btn">Fit</button>
                    </div>
                </div>
            </div>
        </ng-container>
      </div>
</ng-container>