import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'pfm-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']

})
export class DatePickerComponent {
    @Input() label: string = 'Select Date';
    @Input() clearTooltipValue: string = 'Clear date';
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() minDate : Date;
    @Input() maxDate: Date;
    @Input() withIcon : boolean = true;
    @Input() autocomplete : ('on' | 'off')= 'off';
    @Input() icon: String = 'calendar-alt';
    @Input() iconType: String = 'fas';
    @Input() isRequired: boolean = false;

    @Input() size : ('normal' | 'small')= 'normal';
    @Input() disabledDates: string[] = [];
    @Output() valueChange = new EventEmitter<Date>();
    @Input() id: string;

    _value: Date;
    
    @Input() 
    get value(): Date {
        return this._value;
    }
    set value(value: Date) {
        this._value = value;
        this.valueChange.emit(this._value);
    }

    clearDate() {
        this._value = null;
        this.valueChange.emit(this._value);
    }

    createDateString = (dateObj: Date) => {
        if (!dateObj) {
            return '';
        }
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const date = dateObj.getDate();
        
        const monthString = month < 10 ? `0${month}` : month.toString();
        const dateString = date < 10 ? `0${date}` : date.toString();
        
        return `${year}-${monthString}-${dateString}`;
    };

    checkIfDateAvailable = (date: Date) => {
        const dateString = this.createDateString(date);
        return !this.disabledDates.some(el => el === dateString);
    }
}