import { Component, Input } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-facebook',
  templateUrl: './icon-facebook.component.html',
  styleUrls: ['./icon-facebook.component.scss']
})
export class IconFacebookComponent extends IconBaseComponent {
  @Input() isMonoChrome: boolean = true;
  constructor() {
    super();
  }
}
