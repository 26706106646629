import { CompanyUserStatus } from 'app/shared/types/company-user-do.interface';
import { BudgetSegment } from 'app/shared/types/segment.interface';
import { BudgetPermission } from 'app/shared/types/user-permission.type';

export interface UserOwner {
  id: number;
  companyUserId: number;
  name: string;
  initials: string;
  permissions: BudgetPermission[]
  isAdmin: boolean;
  status: CompanyUserStatus;
}

export interface OwnerSelectOption {
  id: number;
  name: string;
  initials: string;
  hidden: boolean;
  status: string;
}

export enum ActionToConfirm {
  DeleteBudget = 'deleteBudget',
  DuplicateBudget = 'duplicateBudget',
  DuplicateBudgetIntegrations = 'duplicateBudgetIntegrations',
  DuplicateBudgetSpecification = 'duplicateBudgetSpecification'
}

export interface UnsavedChanges {
  createdSegmentsCount: number;
  allocationsLockState: { [id: number]: boolean };
  deletedSegments: { [id: number]: BudgetSegment };
  changedSegmentsData: { [id: number]: SegmentOperation };
  changedSegmentsAmounts: {
    [segmentId: number]: {
      id: number;
      amount: number;
    }[]
  };
  changedGroupsData: Record<number, boolean>;
  createdGroupsCount: number;
}

export enum SegmentOperation {
  Updated = 'updated',
  Deleted = 'deleted'
}
