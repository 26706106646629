import { Component, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SharedCostRulesService } from 'app/shared/services/backend/shared-cost-rules.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { SharedCostRule, SharedCostRuleSegment, SharedCostRuleChangeSegmentEvent } from 'app/shared/types/shared-cost-rule.interface';
import { Budget } from 'app/shared/types/budget.interface'
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { AppDataLoader } from 'app/app-data-loader.service';
import { Configuration } from 'app/app.constants';

const TEMP_SHARED_COST_RULES_KEY = 'tempSharedCostRules';
const NOT_FILLED_SHARED_COST_RULES_KEY = 'notFilledSharedCostRules';
const SEGMENTS_COST_SUM_REQUIRED_FOR_SAVING = 100;
const EMPTY_SEGMENT_COST = 0;
const BUDGET_UPDATING_CONFIRMATION_MESSAGE = 'Changing the budget of this rule will remove all of its segments'
  + ' and their corresponding percentages.'
  + '\nAre you sure you want to do this?';
const RULE_UPDATE_FAILURE_MESSAGE = `Couldn't perform rule update`;

interface SegmentListPerBudgetStructure {
  number?: BudgetSegmentAccess[]
}

@Component({
  selector: 'shared-cost-rules-management',
  templateUrl: './shared-cost-rules-management.component.html',
  styleUrls: ['./shared-cost-rules-management.component.scss'],
  providers: [AppDataLoader]
})
export class SharedCostRulesManagementComponent implements OnInit, OnDestroy {
  selectedBudget: Budget = null;
  companyId: number;

  budgetList: Budget[] = [];
  segmentListPerBudget: SegmentListPerBudgetStructure = {};
  rules: SharedCostRule[] = [];
  filteredSCRs: SharedCostRule[] = [];
  notFilledRules: { number?: SharedCostRule } = {};
  ruleProperties = {
    name: 'name',
    budget: 'budget',
    segment: 'segment',
    segmentCost: 'segmentCost',
    isActive: 'isActive'
  };
  ruleUpdaters = {
    [this.ruleProperties.name]: this.updateRuleName.bind(this),
    [this.ruleProperties.budget]: this.updateRuleBudget.bind(this),
    [this.ruleProperties.segment]: this.updateRuleSegment.bind(this),
    [this.ruleProperties.segmentCost]: this.updateRuleSegmentCost.bind(this),
    [this.ruleProperties.isActive]: this.updateRuleActivityStatus.bind(this),
  };
  subscriptions = [];
  filteredBudgetId: number | '*' = this.selectedBudget?.id;
  budgetListFilterOptions: { id: number|string, name: string }[] = []

  static roundPercentage(value: number): number {
    return Math.round(value * 100) / 100;
  }

  constructor(
    public sharedCostRulesService: SharedCostRulesService,
    public budgetDataService: BudgetDataService,
    public utilityService: UtilityService,
    private readonly companyDataService: CompanyDataService,
    private readonly userManager: UserManager,
    private readonly appDataLoader: AppDataLoader,
    private readonly configuration: Configuration,
  ) {}

  get tempRules(): SharedCostRule[] {
    return this.rules.filter((rule: SharedCostRule) => !rule.id);
  }

  ngOnInit() {
    this.budgetList = this.budgetDataService.budgetListSnapshot;
    this.updateBudgetListFilterOptions();
    this.subscribeToBudgetList();
    this.loadNotFilledRules();
    this.subscriptions.push(
      this.companyDataService.selectedCompany$.subscribe(
        cmp => {
          this.companyId = cmp.id;
          this.loadRules(cmp.id);
          this.companyDataService.loadCompanyData(this.companyId);
        }
      )
    );

    this.subscriptions.push(
      this.budgetDataService.selectedBudget$
        .pipe(
          tap(newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)),
        )
        .subscribe({
          error: (error) => this.utilityService.handleError(error)
        })
    );

    this.appDataLoader.init();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    this.selectedBudget = newSelectedBudget;
    this.filteredBudgetId = newSelectedBudget?.id;
    this.updateSCRFilter(this.filteredBudgetId);

    if (newSelectedBudget != null) {
      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.campaignStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.programStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }
  }

  subscribeToBudgetList() {
    const subscription = this.budgetDataService.companyBudgetList$
      .subscribe((budgetList: Budget[]) => { 
        this.budgetList = budgetList;
        this.updateBudgetListFilterOptions();
       });
    this.subscriptions.push(subscription);
  }

  loadSegmentListForBudget(budgetId: number) {
    const subscription = this.getAvailableBudgetSegments(budgetId)
      .subscribe((segmentList: BudgetSegmentAccess[]) => this.segmentListPerBudget[budgetId] = segmentList);
    this.subscriptions.push(subscription);
  }

  loadSegmentListForBudgets(budgetIds: number[]) {
    const requests = budgetIds.map((id: number) => this.getAvailableBudgetSegments(id));
    const subscription = forkJoin(requests).pipe(
      map((data: BudgetSegmentAccess[][]) => this.parseBudgetSegmentsResponse(budgetIds, data))
    ).subscribe((segmentListPerBudget: SegmentListPerBudgetStructure) => this.segmentListPerBudget = segmentListPerBudget);
    this.subscriptions.push(subscription);
  }

  private getAvailableBudgetSegments(budgetId: number): Observable<BudgetSegmentAccess[]> {
    return this.budgetDataService.getAvailableBudgetSegments(budgetId).pipe(
      map(data => data.available_segments)
    )
  }

  loadNotFilledRules() {
    const notFilledRulesJson = localStorage.getItem(NOT_FILLED_SHARED_COST_RULES_KEY);
    if (notFilledRulesJson) {
      this.notFilledRules = JSON.parse(notFilledRulesJson);
    }
  }

  loadRules(companyId: number) {
    this.utilityService.showLoading(true);
    const subscription = this.sharedCostRulesService.getAllRules({ company: companyId }, true)
      .subscribe((savedRules: SharedCostRule[]) => this.onRulesLoaded(savedRules),
      (error) => this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' }),
      () => this.utilityService.showLoading(false));
    this.subscriptions.push(subscription);
  }

  onRulesLoaded(savedRules: SharedCostRule[]) {
    this.fillRulesWithEmptySegments(savedRules);

    const tempRules: SharedCostRule[] = this.getTempRules();
    this.rules = [...tempRules, ...savedRules];

    if (!this.rules.length) {
      this.addNewEmptyRule();
    }

    const budgetIds: number[] = this.getBudgetIdsFromRules(this.rules);
    this.loadSegmentListForBudgets(budgetIds);
    if(this.filteredBudgetId) this.updateSCRFilter(this.filteredBudgetId);
  }

  fillRulesWithEmptySegments(rules: SharedCostRule[]) {
    rules.forEach((rule: SharedCostRule) => {
      const { id } = rule;
      const notFilledRule = this.notFilledRules[id];
      if (notFilledRule) {
        const notFilledSegments: SharedCostRuleSegment[] = this.getNotFilledSegments(notFilledRule.segments);
        rule.segments = [...rule.segments, ...notFilledSegments];
      }
    });
  }

  parseBudgetSegmentsResponse(budgetIds: number[], data: BudgetSegmentAccess[][]): SegmentListPerBudgetStructure {
    return budgetIds.reduce((store, id: number, i: number) => {
      store[id] = data[i];
      return store;
    }, {});
  }

  getTempRules(): SharedCostRule[] {
    const tempRulesJson = localStorage.getItem(TEMP_SHARED_COST_RULES_KEY);
    if (!tempRulesJson) {
      return [];
    }

    return JSON.parse(tempRulesJson);
  }

  getBudgetIdsFromRules(rules: SharedCostRule[]): number[] {
    return rules.reduce((store, rule: SharedCostRule) => {
      const { budgetId } = rule;
      if (budgetId && !store.includes(budgetId)) {
        store.push(budgetId);
      }
      return store;
    }, []);
  }

  saveTempRules() {
    this.saveDataIntoLocalStorage(TEMP_SHARED_COST_RULES_KEY, this.tempRules);
  }

  saveNotFilledRules() {
    this.saveDataIntoLocalStorage(NOT_FILLED_SHARED_COST_RULES_KEY, this.notFilledRules);
  }

  saveDataIntoLocalStorage(key, data) {
    const dataJson = JSON.stringify(data);
    localStorage.setItem(key, dataJson);
  }

  createNewEmptyRule(): SharedCostRule {
    return {
      id: null,
      name: '',
      budgetId: this.filteredBudgetId as number,
      isActive: false,
      instancesNumber: 0,
      segments: this.sharedCostRulesService.createDefaultSegments()
    };
  }

  addNewEmptyRule() {
    const newRule = this.createNewEmptyRule();
    this.rules.unshift(newRule);
    this.updateSCRFilter(this.filteredBudgetId);
  }

  addNotFilledRule(rule: SharedCostRule) {
    this.notFilledRules[rule.id] = rule;
    this.saveNotFilledRules();
  }

  addNewEmptySegment(rule: SharedCostRule) {
    rule.segments.unshift(this.sharedCostRulesService.createEmptySegment(EMPTY_SEGMENT_COST));
  }

  updateCostsForNotFilledSegments(segments: SharedCostRuleSegment[]) {
    if (!segments) {
      return;
    }

    // if there are no not filled segments then we need not to proceed
    const notFilledSegments = this.getNotFilledSegments(segments);
    if (!notFilledSegments.length) {
      return;
    }

    // get sum for filled segments and calculate remaining amount
    const filledSegments = this.getFilledSegments(segments);
    const filledSegmentsCostSum = this.getSegmentsCostSum(filledSegments);
    const remainingAmount = SEGMENTS_COST_SUM_REQUIRED_FOR_SAVING - filledSegmentsCostSum;
    if (remainingAmount <= 0) {
      return;
    }

    // update all the not filled segments with rounded equal value
    const valueForUpdate = remainingAmount / notFilledSegments.length;
    const roundedValueForUpdate = SharedCostRulesManagementComponent.roundPercentage(valueForUpdate);
    notFilledSegments.forEach((segment: SharedCostRuleSegment) => segment.cost = roundedValueForUpdate);

    // if we can't divide equally then just add remaining amount to the last segment
    const notFilledSegmnentsCostSum = this.getSegmentsCostSum(notFilledSegments);
    const errorValue = SharedCostRulesManagementComponent.roundPercentage(remainingAmount - notFilledSegmnentsCostSum);
    if (!errorValue) {
      return;
    }

    const lastNotFilledSegment = notFilledSegments[notFilledSegments.length - 1];
    lastNotFilledSegment.cost = SharedCostRulesManagementComponent.roundPercentage(lastNotFilledSegment.cost + errorValue);
  }

  updateRuleProperty(rule: SharedCostRule, property: string, value: any, segment?: SharedCostRuleSegment) {
    const modifyRule = this.ruleUpdaters[property];
    if (!modifyRule) {
      return;
    }

    modifyRule(rule, value, segment);

    if (rule.id) {
      this.processSavedRulePropertyChange(rule);
    } else {
      this.processTempRulePropertyChange(rule);
    }
  }

  updateRuleName(rule: SharedCostRule, value: any) {
    rule.name = value;
  }

  updateRuleBudget(rule: SharedCostRule, value: any) {
    rule.budgetId = value;
  }

  updateRuleSegment(rule: SharedCostRule, value: any, segment: SharedCostRuleSegment) {
    if (segment) {
      segment.id = value;
    } else {
      rule.segments.push({ id: value });
    }
  }

  updateRuleSegmentCost(rule: SharedCostRule, value: any, segment: SharedCostRuleSegment) {
    if (segment) {
      segment.cost = value;
    } else {
      rule.segments.push({ cost: value });
    }
  }

  updateRuleActivityStatus(rule: SharedCostRule, value: boolean) {
    rule.isActive = value;
  }

  updateSCRFilter(budgetId: number | '*') {
    if(budgetId === '*') {
      this.filteredBudgetId = budgetId;
      this.filteredSCRs = [...this.rules];
    }else {
      this.filteredBudgetId = budgetId;
      this.filteredSCRs = this.rules.filter(r => r.budgetId === budgetId)
      
      if (!this.segmentListPerBudget[budgetId]) {
        this.loadSegmentListForBudget(budgetId);
      }
    }
  }

  updateBudgetListFilterOptions() {
    this.budgetListFilterOptions = [...this.budgetList, { id: '*', name: 'All Budgets' }];
  }

  processSavedRulePropertyChange(rule: SharedCostRule) {
    this.sharedCostRulesService.updateRule(rule).subscribe({
      error: () => {
        this.utilityService.showToast({ Title: '', Message: RULE_UPDATE_FAILURE_MESSAGE, Type: 'error' });
      }
    });
    const isRuleFilledCompletely = this.isRuleFilledCompletely(rule);
    if (isRuleFilledCompletely) {
      this.deleteNotFilledRule(rule);
    } else {
      this.addNotFilledRule(rule);
    }
  }

  deleteRule(rule: SharedCostRule) {
    const tempRuleIndex = this.rules.indexOf(rule);
    this.rules.splice(tempRuleIndex, 1);
    this.updateSCRFilter(this.filteredBudgetId)
  }

  deleteSavedRule(rule: SharedCostRule) {
    const { id } = rule;
    this.sharedCostRulesService.deleteRule(id)
      .subscribe(() => this.deleteRule(rule));
    if (this.notFilledRules[id]) {
      this.deleteNotFilledRule(rule);
    }
  }

  deleteTempRule(rule: SharedCostRule) {
    this.deleteRule(rule);
    this.saveTempRules();
  }

  deleteNotFilledRule(rule: SharedCostRule) {
    const { id } = rule;
    delete this.notFilledRules[id]
    this.saveNotFilledRules();
  }

  deleteSegment(rule: SharedCostRule, segment: SharedCostRuleSegment) {
    const segmentIndex = rule.segments.indexOf(segment);
    if (segmentIndex === -1) {
      return;
    }

    rule.segments.splice(segmentIndex, 1);
  }

  processTempRulePropertyChange(rule: SharedCostRule) {
    const isAllRequiredDataForSavingPushed = this.isAllRequiredDataForSavingPushed(rule);
    if (isAllRequiredDataForSavingPushed) {
      this.saveTempRuleIntoDB(rule);
    } else {
      this.saveTempRules();
    }
  }

  saveTempRuleIntoDB(rule: SharedCostRule) {
    this.sharedCostRulesService.saveRule(this.companyId, rule)
      .subscribe((savedRule: SharedCostRule) => {
        // replace the overdue rule
        const ruleIndex = this.rules.indexOf(rule);
        this.rules[ruleIndex].id = savedRule.id;
        this.saveTempRules();
        const isRuleFilledCompletely = this.isRuleFilledCompletely(rule);
        if (!isRuleFilledCompletely) {
          this.notFilledRules[savedRule.id] = rule;
          this.saveNotFilledRules();
        }
      })
  }

  saveRule(rule: SharedCostRule) {
    if (rule.id) {
      this.processSavedRulePropertyChange(rule);
    } else {
      this.saveTempRules();
    }
  }

  isAllRequiredDataForSavingPushed(rule: SharedCostRule) {
    return rule.name && rule.budgetId;
  }

  isRequiredSegmentsSumPushed(rule: SharedCostRule) {
    const filledSegments: SharedCostRuleSegment[] = this.getFilledSegments(rule.segments);
    const segmentsCostSum = this.getSegmentsCostSum(filledSegments);
    return segmentsCostSum === SEGMENTS_COST_SUM_REQUIRED_FOR_SAVING;
  }

  isAllRequiredDataForActivationPushed(rule: SharedCostRule) {
    const isRequiredSegmentsSumPushed = this.isRequiredSegmentsSumPushed(rule);
    return rule.name && rule.budgetId && isRequiredSegmentsSumPushed;
  }

  isRuleFilledCompletely(rule: SharedCostRule): boolean {
    if (!rule.segments) {
      return false;
    }
    return !rule.segments.some((segment: SharedCostRuleSegment) => !segment.id);
  }

  doesRuleHaveSelectedSegments(rule: SharedCostRule) {
    return rule && rule.segments && rule.segments.some((segment: SharedCostRuleSegment) => !!segment.id);
  }

  getFilledSegments(segments: SharedCostRuleSegment[]): SharedCostRuleSegment[] {
    return segments.filter((segment: SharedCostRuleSegment) => !!segment.id)
  }

  getNotFilledSegments(segments: SharedCostRuleSegment[]): SharedCostRuleSegment[] {
    return segments.filter((segment: SharedCostRuleSegment) => !segment.id)
  }

  getSegmentsCostSum(segments: SharedCostRuleSegment[]): number {
    if (!segments) {
      return 0;
    }

    const totalValue = segments.reduce((store, current: SharedCostRuleSegment) => store + current.cost, 0);

    return SharedCostRulesManagementComponent.roundPercentage(totalValue);
  }

  handleAddNewRule() {
    this.addNewEmptyRule();
    this.saveTempRules();
  }

  handleUpdateRuleName(rule: SharedCostRule, value: string) {
    this.updateRuleProperty(rule, this.ruleProperties.name, value);
  }

  handleUpdateRuleBudget(rule: SharedCostRule, value: number) {
    if (!this.segmentListPerBudget[value]) {
      this.loadSegmentListForBudget(value);
    }

    const doesRuleHaveSelectedSegments = this.doesRuleHaveSelectedSegments(rule);
    if (!doesRuleHaveSelectedSegments) {
      this.updateRuleProperty(rule, this.ruleProperties.budget, value);
      return;
    }

    // if the rule has already had selected segments then we should ask a user about removing them
    const budgetUpdatingConfirmation = confirm(BUDGET_UPDATING_CONFIRMATION_MESSAGE);
    if (!budgetUpdatingConfirmation) {
      return;
    }

    // if confirmed then set rule segments to default state
    rule.segments = this.sharedCostRulesService.createDefaultSegments();
    this.updateRuleProperty(rule, this.ruleProperties.budget, value);
  }

  handleUpdateRuleSegment(rule: SharedCostRule, event: SharedCostRuleChangeSegmentEvent) {
    const { segment, value } = event;
    this.updateRuleProperty(rule, this.ruleProperties.segment, value, segment);
  }

  handleUpdateRuleSegmentCost(rule: SharedCostRule, event: SharedCostRuleChangeSegmentEvent) {
    const { segment, value } = event;
    this.updateRuleProperty(rule, this.ruleProperties.segmentCost, value, segment);
  }

  handleUpdateRuleActivityStatus(rule: SharedCostRule, isActive: boolean) {
    const isAllRequiredDataForActivationPushed = this.isAllRequiredDataForActivationPushed(rule);
    if (isAllRequiredDataForActivationPushed || !isActive) {
      this.updateRuleProperty(rule, this.ruleProperties.isActive, isActive);
    }
  }

  handleDeleteRule(rule: SharedCostRule) {
    if (rule.id) {
      this.deleteSavedRule(rule);
    } else {
      this.deleteTempRule(rule);
    }
  }

  handleAddNewSegment(rule: SharedCostRule) {
    this.addNewEmptySegment(rule);
    this.updateCostsForNotFilledSegments(rule.segments);
    this.saveRule(rule);
  }

  handleDeleteSegment(rule: SharedCostRule, segment: SharedCostRuleSegment) {
    this.deleteSegment(rule, segment);
    this.updateCostsForNotFilledSegments(rule.segments);
    this.saveRule(rule);
  }
}
