import { inject, Injectable } from '@angular/core';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { ProgramService } from 'app/shared/services/backend/program.service';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { MatDialog } from '@angular/material/dialog';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { Configuration } from 'app/app.constants';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { DialogContext } from 'app/shared/types/dialog-context.interface';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { map, switchMap, tap } from 'rxjs/operators';
import { BulkOperationResponse } from 'app/shared/types/bulk-operation-response.interface';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { CompanyDataService } from '@shared/services/company-data.service';

@Injectable()
export class IntegratedObjectsService {
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly campaignService = inject(CampaignService);
  private readonly programService = inject(ProgramService);
  private readonly expenseService = inject(ExpensesService);
  private readonly dialog = inject(MatDialog);
  private readonly budgetObjectService = inject(BudgetObjectService);
  private readonly configuration = inject(Configuration);

  private openConfirmCampaignsRemovalDialog(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const resolve = value => {
        observer.next(value);
        observer.complete();
      }
      const dialogData: DialogContext = {
        title: 'Delete campaigns and expenses',
        headerIcon: ['fad', 'raygun'],
        content: `By disabling this integration, you will no longer receive updates to the associated campaigns and expense groups.
         Should you reenable the integration in the future, new campaigns and expenses will be created,
          possibly creating duplicates of everything you have today.
           Would you like to delete the current campaigns and expenses now?`,
        cancelAction: {
          label: 'Keep',
          handler: () => resolve(false)
        },
        submitAction: {
          label: 'Delete All',
          handler: () => resolve(true)
        }
      };

      this.dialog.open(ConfirmationDialogComponent, {
        width: '480px',
        data: dialogData
      });
    });
  }

  private getAllCampaignIds$(sourceCampaignIds: number[], commonParams: object): Observable<number[]> {
    const childCampaignIds$ =
      sourceCampaignIds?.length ?
        this.campaignService.getCampaigns({ ...commonParams, parent_campaign_ids: sourceCampaignIds.join(',') }) :
        of([]);
    return childCampaignIds$.pipe(
      map(childCampaigns => [...childCampaigns.map(campaign => campaign.id), ...sourceCampaignIds])
    )
  }

  private getProgramIds$(
    allCampaignIds: number[],
    costAdjustmentProgramIds: number[],
    commonParams: object
  ): Observable<[number[], number[]]> {
    const programs$ =
      allCampaignIds?.length ?
        this.programService.getPrograms({ ...commonParams, campaign_ids: allCampaignIds.join(',') }) :
        of([]);
    return programs$.pipe(
      map(programs => programs.map(program => program.id)),
      map(programIds => costAdjustmentProgramIds?.length ? [...programIds, ...costAdjustmentProgramIds] : programIds),
      map(programIds => [allCampaignIds, programIds])
    );
  }

  public getExpenseIds$(
    allCampaignIds: number[],
    allProgramIds: number[],
    commonParams: object
  ): Observable<number[]> {
    return forkJoin([
      allCampaignIds?.length ?
        this.expenseService.getExpenses({...commonParams, campaign_ids: allCampaignIds.join(',')}) :
        of([]),
      allProgramIds?.length ?
        this.expenseService.getExpenses({...commonParams, program_ids: allProgramIds.join(',')}) :
        of([])
    ]).pipe(
      map(([campaignExpenses, programExpenses]) => [...campaignExpenses, ...programExpenses].map(exp => exp.id))
    );
  }

  private deleteObjects(
    objectIds: number[],
    remover: (ids: number[]) => Observable<BulkOperationResponse<number>>,
    objectType: string
  ): Observable<any> {
    const objectTypeName = this.budgetObjectService.getObjectTypeName(objectType);
    const removeObjects$ =
      remover(objectIds).pipe(
        switchMap(res =>
          res.error?.length ?
            throwError({ message: `Failed to delete ${res.error?.length} ${objectTypeName}(s)`}) :
            of(null)
        )
      );
    return objectIds?.length ? removeObjects$ : of(null);
  }

  private deleteAllObjects$(allCampaignIds: number[], allProgramIds: number[], allExpenseIds: number[]): Observable<any> {
    const deleteExpenses$ = this.deleteObjects(
      allExpenseIds,
      this.expenseService.deleteMultiExpenses.bind(this.expenseService),
      this.configuration.OBJECT_TYPES.expense
    );
    const deletePrograms$ = this.deleteObjects(
      allProgramIds,
      this.programService.deleteMultiPrograms.bind(this.programService),
      this.configuration.OBJECT_TYPES.program
    );
    const deleteCampaigns$ = this.deleteObjects(
      allCampaignIds,
      this.campaignService.deleteMultiCampaigns.bind(this.campaignService),
      this.configuration.OBJECT_TYPES.campaign
    );
    const budgetId = this.budgetDataService.selectedBudgetSnapshot?.id || null;
    const companyId = this.companyDataService.selectedCompanySnapshot?.id || null;
    return deleteExpenses$.pipe(
      switchMap(() => deletePrograms$),
      switchMap(() => deleteCampaigns$),
      tap(() => {
        this.budgetDataService.loadCampaigns(companyId, budgetId, this.configuration.campaignStatusNames.active);
        this.budgetDataService.loadPrograms(companyId, budgetId, this.configuration.campaignStatusNames.active)
      })
    );
  }

  public deleteCampaignsWithExpenses(
    companyId: number,
    budgetId: number,
    campaignIds: number[],
    costAdjustmentProgramIds: number[]
  ): Observable<any> {
    if (!campaignIds?.length) {
      return of(null);
    }

    const commonParams = { company: companyId, budget: budgetId };

    const deleteCampaignsWithHierarchy$ =
      this.getAllCampaignIds$(campaignIds, commonParams).pipe(
        switchMap(allCampaignIds => this.getProgramIds$(allCampaignIds, costAdjustmentProgramIds, commonParams)),
        switchMap(([allCampaignIds, allProgramIds]) =>
          this.getExpenseIds$(allCampaignIds, allProgramIds, commonParams).pipe(
            map(expenseIds => [allCampaignIds, allProgramIds, expenseIds])
          )
        ),
        switchMap(([allCampaignIds, allProgramIds, allExpenseIds]) =>
          this.deleteAllObjects$(allCampaignIds, allProgramIds, allExpenseIds)
        )
      );

    return this.openConfirmCampaignsRemovalDialog().pipe(
      switchMap(
        shouldDeleteCampaigns => shouldDeleteCampaigns ? deleteCampaignsWithHierarchy$ : of(null)
      )
    );
  }
}
