<tooltip-container class="shared-cost-info"
  [ngClass]="className"
  [ngStyle]="{top: coords?.top+'px', left: coords?.left+'px'}">
  <p class="shared-rule-name">
    <icon-shared [state]="sharedIconState"></icon-shared>
    {{ name || ''}}
  </p>
  <ul>
    <li *ngFor="let item of segments">
      <p class="segment">
        {{ item.name || item.id }}
      </p>
      <span class="shared-value">{{item.cost | number: '1.2-2'}}%</span>
    </li>
  </ul>
</tooltip-container>
