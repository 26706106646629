import { inject, Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { MetricsProviderWithImplicitMappingsDataService } from '../../metric-integrations/services/metrics-provider-with-implicit-mappings-data.service';
import { MetricIntegrationName } from '../../metric-integrations/types/metric-integration';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { MetricIntegrationsProviderService } from '../../metric-integrations/services/metric-integrations-provider.service';
import { BulkDeleteResponse, BulkOperationResponse } from 'app/shared/types/bulk-operation-response.interface';
import { Configuration } from 'app/app.constants';
import { ExpenseDO } from '@shared/types/expense.interface';
import { ObjectMode } from '@shared/enums/object-mode.enum';
import { ExternalIntegrationExpenseSource } from '@shared/constants/external-integration-object-types';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { BudgetObjectDialogService } from '@shared/services/budget-object-dialog.service';
import { TagMapping } from '@shared/types/tag-mapping.interface';
import { TagsControlService } from '@shared/services/tags-control.service';
import { TagService } from '@shared/services/backend/tag.service';
import { BudgetObjectTagsService } from '../../budget-object-details/services/budget-object-tags.service';
import { TagDO } from '@shared/types/tag-do.interface';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { BudgetObjectService } from '@shared/services/budget-object.service';
import { BulkUpdateDialogType, DialogContext } from '@shared/types/dialog-context.interface';
import { AutocompleteControlItem } from '@shared/components/autocomplete-control/autocomplete-control.component';
import {
  ActionHandlingContext,
  ActionSelectOption,
  DeleteActionContext,
  DeleteExpenseHandlersData,
  ExpenseAction,
  ExpenseActionConfirmation,
  ExpenseActionHandlingType,
  ExpenseActionType,
  ExpenseActionValueUpdate,
  ExpensesTimeframeOrder,
  IntegratedExpensesData,
  SingleSelectActionContext,
  TagsActionContext,
  TextValueActionContext
} from '@spending/types/expense-action.type';
import { UtilityService } from '@shared/services/utility.service';
import { getAllowedSegments } from '@shared/utils/common.utils';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { BudgetObjectCreationContext } from '../../budget-object-details/types/details-creation-context.interface';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { ProgramService } from '@shared/services/backend/program.service';
import { CampaignTypeNames } from '@shared/enums/campaign-types.enum';
import { SidebarActionMessage } from '@spending/types/expense-page.type';
import { Budget } from '../../shared/types/budget.interface';

@Injectable()
export class ExpenseActionsService {
  private readonly expenseService = inject(ExpensesService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly metricIntegrationsProvider = inject(MetricIntegrationsProviderService);
  private readonly configuration = inject(Configuration);
  private readonly budgetObjectService = inject(BudgetObjectService);
  private readonly dialogManager = inject(BudgetObjectDialogService);
  private readonly tagsControlService = inject(TagsControlService);
  private readonly tagService = inject(TagService);
  private readonly utilityService = inject(UtilityService);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly programService = inject(ProgramService);

  public isCurrentBudgetWithNewCEGStructure: boolean;

  public contextProviders = {
    [ExpenseActionType.AddTag]: this.createContextForAddTags.bind(this),
    [ExpenseActionType.AddPONumber]: this.createContextForAddPoNumber.bind(this),
    [ExpenseActionType.RemoveTag]: this.createContextForRemoveTags.bind(this),
    [ExpenseActionType.Delete]: this.createContextForDeleteAction.bind(this),
    [ExpenseActionType.ChangeSegment]: this.createContextForChangeSegment.bind(this),
    [ExpenseActionType.ChangeParent]: this.createContextForChangeParent.bind(this),
    [ExpenseActionType.MoveToBudget]: this.createContextForMoveToBudget.bind(this)
  };
  private destroy$ = new Subject<void>();

  constructor(private budgetDataService: BudgetDataService) { 
    this.budgetDataService.selectedBudget$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (budget: Budget) => this.onSelectedBudget(budget),
        (error) => this.utilityService.handleError(error)
      );
  }

  onSelectedBudget(budget: Budget) {
    this.isCurrentBudgetWithNewCEGStructure = !!budget.new_campaigns_programs_structure;

    if (!this.isCurrentBudgetWithNewCEGStructure) {
      this.actions[ExpenseActionType.AddVendor].handlingConfig = {
        handlingType: ExpenseActionHandlingType.TextValue,
        updatePropName: 'vendor'  
      };
      this.actions[ExpenseActionType.AddVendor].executeAction = (action: ExpenseAction, context: TextValueActionContext) => this.applyTextValue(action, context);
      this.contextProviders[ExpenseActionType.AddVendor] = this.createContextForAddVendor.bind(this);
    }
  }

  private _isActionInProgress$ = new BehaviorSubject<boolean>(false);
  private _programsCreated$ = new Subject<void>();

  public integrationExpenseTypeIds: number[] = [];
  public isActionInProgress$: Observable<boolean> = this._isActionInProgress$.asObservable();
  public programsCreated$ = this._programsCreated$.asObservable();
  
  private createContextForAddVendor(selectedExpenses: ExpenseDO[]): Observable<TextValueActionContext> {
    const companyId = this.companyDataService.selectedCompanySnapshot.id;

    return this.expenseService.getVendors({ company: companyId }).pipe(
      switchMap(vendors =>
        this.openUpdateDialog({
          title: 'Add Vendor',
          type: BulkUpdateDialogType.textWithAutocomplete,
          autocompleteItems: vendors
        })
      ),
      switchMap((vendor: AutocompleteControlItem) =>
        vendor.isNew ? this.companyDataService.createVendor({
          company: companyId,
          name: vendor.name
        }) : of(vendor)
      ),
      map(vendor => ({
        textValue: String(vendor.id),
        ...ExpenseActionsService.createDefaultContext(selectedExpenses)
      }))
    );
  }

  private readonly actions: Record<ExpenseActionType, ExpenseAction> = {
    [ExpenseActionType.MoveToBudget]: {
      name: 'Move to Budget',
      actionType: ExpenseActionType.MoveToBudget,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.DirectAction,
      },
      customCssClass: 'ut-move-to-budget-action',
      checkHidden: (expense: ExpenseDO) => expense.is_verified,
      executeAction: (action: ExpenseAction, context: SingleSelectActionContext) => this.applyMoveToBudget(context)
    },
    [ExpenseActionType.ChangeStatus]: {
      name: 'Change Status',
      actionType: ExpenseActionType.ChangeStatus,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.SingleSelect,
        selectOptions: [],
        updatePropName: 'mode',
        getConfirmationData: (action: ExpenseAction, context: SingleSelectActionContext) =>
          this.getSingleSelectConfirmationData(action.name, 'status', context.selectedOption?.value?.toString())
      },
      customCssClass: 'ut-change-status-action',
      checkHidden: (expense: ExpenseDO) => this.checkStatusVisibility(expense),
      executeAction: (action: ExpenseAction, context: SingleSelectActionContext) => this.applySingleSelect(action, context)
    },
    [ExpenseActionType.ChangeType]: {
      name: 'Change Type',
      actionType: ExpenseActionType.ChangeType,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.SingleSelect,
        selectOptions: [],
        updatePropName: 'expense_type',
        getConfirmationData: (action: ExpenseAction, context: SingleSelectActionContext) =>
          this.getSingleSelectConfirmationData(action.name, 'type', context.selectedOption?.title)
      },
      customCssClass: 'ut-change-type-action',
      checkHidden: (expense: ExpenseDO) => this.hasIntegrated(expense),
      executeAction: (action: ExpenseAction, context: SingleSelectActionContext) => this.applySingleSelect(action, context)
    },
    [ExpenseActionType.ChangeOwner]: {
      name: 'Change Owner',
      actionType: ExpenseActionType.ChangeOwner,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.SingleSelect,
        selectOptions: [],
        updatePropName: 'owner',
        getConfirmationData: (action: ExpenseAction, context: SingleSelectActionContext) =>
          this.getChangeOwnerConfirmationData(action, context)
      },
      customCssClass: 'ut-change-owner-action',
      executeAction: (action: ExpenseAction, context: SingleSelectActionContext) => this.applySingleSelect(action, context)
    },
    [ExpenseActionType.ChangeSegment]: {
      name: 'Change Segment or Rule',
      actionType: ExpenseActionType.ChangeSegment,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.DirectAction,
      },
      customCssClass: 'ut-change-segment-action',
      executeAction: (action: ExpenseAction, context: SingleSelectActionContext) => this.applySingleSelect(action, context)
    },
    [ExpenseActionType.ChangeTimeframe]: {
      name: 'Change Timeframe',
      actionType: ExpenseActionType.ChangeTimeframe,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.SingleSelect,
        selectOptions: [],
        updatePropName: 'company_budget_alloc',
        getConfirmationData: (action: ExpenseAction, context: SingleSelectActionContext) =>
          this.getSingleSelectConfirmationData(action.name, 'timeframe', context.selectedOption?.title)
      },
      customCssClass: 'ut-change-timeframe-action',
      checkHidden: (expense: ExpenseDO, timeframeId: number) =>
        this.hasIntegrated(expense) || this.hasBudgetTimeframeClosed(expense) || this.hasNotSameTimeframe(expense, timeframeId),
      executeAction: (action: ExpenseAction, context: SingleSelectActionContext) => this.applySingleSelect(action, context)
    },
    [ExpenseActionType.ChangeParent]: {
      name: 'Change Parent',
      actionType: ExpenseActionType.ChangeParent,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.DirectAction,
        updatePropName: 'parent'
      },
      customCssClass: 'ut-change-parent-action',
      checkHidden: (expense: ExpenseDO) => this.hasParentClosed(expense) || this.hasBudgetTimeframeClosed(expense),
      executeAction: (action: ExpenseAction, context: SingleSelectActionContext) => this.applySingleSelect(action, context)
    },
    [ExpenseActionType.AddTag]: {
      name: 'Add a Tag',
      actionType: ExpenseActionType.AddTag,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.DirectAction,
        updatePropName: 'tag_ids'
      },
      customCssClass: 'ut-add-tag-action',
      executeAction: (action: ExpenseAction, context: TagsActionContext) => this.applyAddTags(action, context)
    },
    [ExpenseActionType.AddPONumber]: {
      name: 'Add PO#',
      actionType: ExpenseActionType.AddPONumber,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.TextValue,
        updatePropName: 'expense_po_no'
      },
      customCssClass: 'ut-po-number-action',
      executeAction: (action: ExpenseAction, context: TextValueActionContext) => this.applyTextValue(action, context)
    },
    [ExpenseActionType.AddGLCode]: {
      name: 'Add GL Code',
      actionType: ExpenseActionType.AddGLCode,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.SingleSelect,
        updatePropName: 'gl_code',
        getConfirmationData: (action: ExpenseAction, context: SingleSelectActionContext) =>
          this.getSingleSelectConfirmationData(action.name, 'GL code', context.selectedOption?.title)
      },
      customCssClass: 'ut-add-gl-code-action',
      executeAction: (action: ExpenseAction, context: SingleSelectActionContext) => this.applySingleSelect(action, context)
    },
    [ExpenseActionType.AddVendor]: {
      name: 'Add Vendor',
      actionType: ExpenseActionType.AddVendor,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.SingleSelect,
        updatePropName: 'vendor',        
        getConfirmationData: (action: ExpenseAction, context: SingleSelectActionContext) =>
          this.getSingleSelectConfirmationData(action.name, 'vendor', context.selectedOption?.title)
      },
      customCssClass: 'ut-add-vendor-action',
      executeAction: (action: ExpenseAction, context: SingleSelectActionContext) => this.applySingleSelect(action, context)
    },
    [ExpenseActionType.RemoveTag]: {
      name: 'Remove a Tag',
      actionType: ExpenseActionType.RemoveTag,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.DirectAction,
      },
      customCssClass: 'ut-remove-tag-action',
      executeAction: (action: ExpenseAction, context: TagsActionContext) => this.applyRemoveTags(context)
    },
    [ExpenseActionType.CreateExpenseGroup]: {
      name: 'Create Expense Group',
      actionType: ExpenseActionType.CreateExpenseGroup,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.DirectAction,
      },
      customCssClass: 'ut-create-expense-group-action',
      executeAction: (action: ExpenseAction, context: ActionHandlingContext) => this.createExpenseGroup(context)
    },
    [ExpenseActionType.Delete]: {
      name: 'Delete',
      actionType: ExpenseActionType.Delete,
      handlingConfig: {
        handlingType: ExpenseActionHandlingType.DirectAction,
        getConfirmationData: (action: ExpenseAction, context: DeleteActionContext) => this.getDeleteConfirmationData(context)
      },
      customCssClass: 'ut-delete-action',
      executeAction: (action: ExpenseAction, context: DeleteActionContext) =>
        this.deleteExpenses(context.totalExpenseIds || context.selectedExpenses?.map(exp => exp.id), context.deleteExpenseHandlersData)
    },
  };
  private _isStatuslessExpenses: boolean;

  private readonly expenseModeOptions: ActionSelectOption[] = this.configuration.modes
    .map(mode => ({
      value: mode,
      title: mode,
      cssClass: mode.toLowerCase(),
    }));

  private readonly forbiddenActionConfirmationData: ExpenseActionConfirmation = {
    title: 'Forbidden bulk action!',
    message: 'Selected user doesn\'t have write permissions for the selected expenses',
    isActionForbidden: true,
  };

  private static getDefaultActionConfirmationMessage(confirmPropertyName: string, value: string | number) {
    return `Change ${confirmPropertyName} of selected expense(s) to "<b>${value}</b>"?`;
  }

  private static getEqualChunks<T>(data: T[], maxChunkSize = 100): Array<T[]> {
    const chunks = [];
    const chunksCount = Math.ceil(data.length / maxChunkSize);
    const chunkSize = Math.ceil(data.length / chunksCount);

    for (let i = 0; i < chunksCount; i++) {
      const offset = i * chunkSize;
      chunks.push(data.slice(offset, offset + chunkSize));
    }

    return chunks;
  }

  private static getStatusAvailableOption(option, value, updateActualAmount, currencyCode?): ActionSelectOption {
    const text = option.value === ObjectMode.Closed ? 'Close' : 'Commit';
    const currencyText = currencyCode ? currencyCode + ' ' : '';

    return {
      ...option,
      title: `${text} at ${currencyText}${value}`,
      context: { customUpdateProps: { 'copy_actual_amount': updateActualAmount } }
    };
  }

  private static createDefaultContext(selectedExpenses: ExpenseDO[]): ActionHandlingContext {
    return { selectedExpenses };
  }

  public getActions(onlyActive = true): ExpenseAction[] {
    const actionList = Object.values(this.actions);
    return onlyActive ?
      actionList.filter(action => !action.hidden) :
      actionList;
  }

  public setOptionsForSelect(actionType: ExpenseActionType, options?: ActionSelectOption[]): void {
    const action = this.actions[actionType];
    if (action) {
      const config = action.handlingConfig;
      if (config.handlingType === ExpenseActionHandlingType.SingleSelect || action.actionType === ExpenseActionType.MoveToBudget) {
        config.selectOptions = options || [];
        action.hidden = !config.selectOptions.length;
      }
    }
  }

  public setHierarchySelectOptions(actionType: ExpenseActionType, options: HierarchySelectItem[]): void {
    const action = this.actions[actionType];
    if (!action) {
      console.error(`Missing action for type "${actionType}"`);
      return;
    }
    const config = action.handlingConfig;
    config.hierarchyOptions = options || [];
    if (actionType === ExpenseActionType.ChangeParent) {
      const emptyOption = { id: null, title: 'No Parent' };
      config.hierarchyOptions.unshift(emptyOption);
    }
    action.hidden = !config.hierarchyOptions.length;
  }

  public onSelectedExpensesUpdate(selectedExpenses: ExpenseDO[]) {
    const options = this.getStatusAvailableOptions(selectedExpenses);
    this.setOptionsForSelect(ExpenseActionType.ChangeStatus, options);

    const actionList = Object.values(this.actions);
    actionList.forEach(action => {
      if (action.checkHidden) {
        action.hidden = selectedExpenses.some(expense => action.checkHidden(expense, selectedExpenses[0].company_budget_alloc));
      }
    });
  }

  public executeAction<Context extends ActionHandlingContext>(actionType: ExpenseActionType, context: Context): Observable<any> {
    const action = this.actions[actionType];
    if (!action) {
      return throwError('No action found or there is no group for action');
    }

    const handler = action.executeAction;
    if (!handler) {
      return throwError(`Handler for action ${action.actionType} was not found. Handling type: ${action.handlingConfig?.handlingType}`);
    }

    const confirmationData = action.handlingConfig?.getConfirmationData?.(action, context);

    const callHandler = () => {
      this._isActionInProgress$.next(true);
      return handler(action, context);
    };

    return (confirmationData ? this.confirmAction(confirmationData, callHandler) : callHandler()).pipe(
      finalize(() => this._isActionInProgress$.next(false))
    );
  }

  private getIntegratedExpensesInfo(
    selectedExpenses: ExpenseDO[]
  ): { integratedExpLength: number; integratedExpenses: IntegratedExpensesData } {
    const externalIntegrationSources = Object.values(ExternalIntegrationExpenseSource);
    let integratedExpLength = 0;

    const integratedExpenses = selectedExpenses
      .reduce((idsObj, exp: ExpenseDO) => {
        if (externalIntegrationSources.includes(exp.source) && exp.campaign) {
          integratedExpLength += 1;
          const tfOrder: ExpensesTimeframeOrder = {
            orderId: ExpensesService.getTimeframeOrder(exp.company_budget_alloc, this.budgetDataService.timeframesSnapshot),
            expenseId: exp.id,
          };
          const integratedBySource = idsObj[exp.source];
          if (!integratedBySource) {
            idsObj[exp.source] = {
              [exp.campaign]: [tfOrder]
            };
          } else {
            const campaignTf: ExpensesTimeframeOrder[] = idsObj[exp.source][exp.campaign];
            campaignTf ? campaignTf.push(tfOrder) : integratedBySource[exp.campaign] = [tfOrder];
          }
        }
        return idsObj;
      }, {} as IntegratedExpensesData);
    return { integratedExpLength, integratedExpenses };
  }

  private getStatusAvailableOptions(selectedExpenses: ExpenseDO[]): ActionSelectOption[] {
    const allTimeframes = this.budgetDataService.timeframesSnapshot || [];
    const updateActualAmount =
      selectedExpenses.every(expense => {
        const timeframe = allTimeframes.find(alloc => alloc.id === expense.company_budget_alloc);
        return expense.mode === ObjectMode.Planned && !expense.source_actual_amount && !timeframe?.locked;
      });

    const resetPlanned =
      selectedExpenses.every(expense => {
        const timeframe = allTimeframes.find(alloc => alloc.id === expense.company_budget_alloc);
        return expense.mode !== ObjectMode.Planned && !timeframe?.locked;
      });

    const plannedOption = this.expenseModeOptions.find(option => option.value === ObjectMode.Planned);
    plannedOption.title = ObjectMode.Planned;
    if (resetPlanned) {
      plannedOption.title += ' (set Actual to 0.00)';
    }

    if (updateActualAmount) {
      const options = [];
      this.expenseModeOptions.forEach(option => {
        if (option.value !== ObjectMode.Planned) {
          options.push(
            ExpenseActionsService.getStatusAvailableOption(option, 'Planned Amount', true),
            ExpenseActionsService.getStatusAvailableOption(option, '0.00', false),
          );
        } else {
          options.push(option);
        }
      });
      return options;
    }
    return this.expenseModeOptions;
  }

  private createExpenseGroup(context: ActionHandlingContext): Observable<any> {
    const expenses = context.selectedExpenses;
    const expenseGroupingId = expenses[0].company_budget_segment1 || expenses[0].split_rule;
    const isSameGroup = expenses.every(exp => exp.company_budget_segment1 === expenseGroupingId || exp.split_rule === expenseGroupingId);

    if (expenses.length > 1 && !isSameGroup) {
      return this.programService.multiCreateFromExpenses(expenses.map(exp => exp.id)).pipe(
        tap(_ => this._programsCreated$.next()),
        tap(programs =>
          this.utilityService.showCustomToastr('The expense groups were created.', 'Open them')
            .onAction
            .pipe(take(1))
            .subscribe(() => {
              this.filterManagementService.updateCurrentFilterSet({ [FilterName.ExpenseBuckets]: programs.map(program => program.id) });
              this.appRoutingService.openPlanDetail([ this.configuration.OBJECT_TYPES.segment ], {});
            })
        )
      );
    }

    const firstExpense = expenses.length === 1
      ? expenses[0]
      : expenses.sort((exp1, exp2) => new Date(exp2.crd).getTime() - new Date(exp2.crd).getTime())[0];

    let parentCampaignId = firstExpense.campaign;
    const parentSegmentOrRuleName = firstExpense.company_budget_segment1
      ? this.budgetDataService.segmentsSnapshot
        .find(segment => segment.id === firstExpense.company_budget_segment1)?.name
      : this.budgetDataService.sharedCostRulesSnapshot
        .find(rule => rule.id === firstExpense.split_rule)?.name;

    let parentCampaignName;
    if (parentCampaignId) {
      parentCampaignName = this.budgetDataService.lightCampaignsSnapshot
        .find(campaign => campaign.id === parentCampaignId)?.name;
      if (expenses.some(expense => expense.campaign !== parentCampaignId)) {
        parentCampaignId = null;
      }
    }

    const defaultExpenseType = this.companyDataService.programTypesSnapshot.find(objectType => objectType.name === CampaignTypeNames.OTHER);

    const suggestedName = `${parentCampaignName || parentSegmentOrRuleName} expenses`;
    const creationContext: BudgetObjectCreationContext = {
      segmentId: firstExpense.company_budget_segment1,
      sharedCostRuleId: firstExpense.split_rule,
      objectTypeId: defaultExpenseType.id,
      ownerId: firstExpense.owner,
      selectedExpenses: expenses
    };

    if (parentCampaignId) {
      creationContext.parent = {
        id: parentCampaignId,
        type: this.configuration.OBJECT_TYPES.campaign
      };
    }

    return this.programService.validateUniqueName(firstExpense.company, firstExpense.budget, suggestedName).pipe(
      catchError(err => {
        if (Number(err.status) === HttpStatusCode.BadRequest) {
          creationContext.suggestedName = suggestedName;
        }
        return of({});
      }),
      tap(() => this.appRoutingService.openProgramCreation(creationContext))
    );
  }

  private deleteExpenses(expenseIds: number[], data: DeleteExpenseHandlersData): Observable<any> {
    const expenseIdChunks = ExpenseActionsService.getEqualChunks(expenseIds);
    const deleteChunks$: Observable<BulkDeleteResponse>[] =
      expenseIdChunks.map(expenseIdChunk => this.expenseService.deleteMultiExpenses(expenseIdChunk));

    return this.processChunkedResponse(deleteChunks$).pipe(
      tap(responses => this.lockTimeframesForIntegration(data, responses.success))
    );
  }

  private lockTimeframesForIntegration(data: DeleteExpenseHandlersData, deletedExpensesIds: number[]) {
    if (data?.integratedExpenses) {
      const companyId = this.companyDataService.selectedCompanySnapshot.id;
      const lockTimeframeOrderRequests$ = this.getLockTimeframeOrderRequests(data, deletedExpensesIds, companyId);

      if (lockTimeframeOrderRequests$.length) {
        forkJoin(lockTimeframeOrderRequests$).subscribe(
          { error: err => console.log(err) }
        );
      }
    }
  }

  private getLockTimeframeOrderRequests(data: DeleteExpenseHandlersData, deletedExpensesIds: number[], companyId: number) {
    return Object.entries(data.integratedExpenses)
      .reduce((requestsArr, [integrationType, campaignsObj]) => {
        const provider: MetricsProviderWithImplicitMappingsDataService =
          this.metricIntegrationsProvider.metricIntegrationProviderByType(integrationType as MetricIntegrationName);

        if (provider) {
          Object.entries(campaignsObj).forEach(([campaignId, orders]) => {
            const ordersArr = orders
              .filter(order => deletedExpensesIds.includes(order.expenseId))
              .map(order => order.orderId);
            if (ordersArr.length) {
              const request = provider.lockTimeframesForIntegration(ordersArr, companyId, campaignId);
              requestsArr.push(request);
            }
          });
        }
        return requestsArr;
      }, []);
  }

  private processChunkedResponse<T>(chunks$: Observable<BulkOperationResponse<T>>[]) {
    const emptyResponse: BulkOperationResponse<T> = {
      error: [],
      success: []
    };

    return forkJoin(chunks$)
      .pipe(map((responses: BulkOperationResponse<T>[]) =>
        responses.reduce((resultExpenses, response) => ({
          error: [
            ...resultExpenses.error,
            ...response.error
          ],
          success: [
            ...resultExpenses.success,
            ...response.success
          ]
        }), emptyResponse)
      ));
  }

  private updateMultiExpenses<TValue>(
    action: ExpenseAction,
    selectedExpenseIds: number[],
    value: TValue,
    customUpdateProps?: Record<string, any>
  ): Observable<BulkOperationResponse<ExpenseDO>> {
    const expenseIdChunks = ExpenseActionsService.getEqualChunks(selectedExpenseIds);
    const updateChunks$: Observable<BulkOperationResponse<ExpenseDO>>[] =
      expenseIdChunks.map(expenseIdChunk =>
        this.expenseService.updateMultiExpenses({
          ...customUpdateProps,
          'ids': expenseIdChunk,
          [action.handlingConfig.updatePropName]: value
        })
      );

    return this.processChunkedResponse(updateChunks$);
  }

  public undoMultiExpenses(payloads: Record<string, any>[]): Observable<BulkOperationResponse<ExpenseDO>> {
    this._isActionInProgress$.next(true);
    const updateChunks$: Observable<BulkOperationResponse<ExpenseDO>>[] =
      payloads.map(payload => this.expenseService.updateMultiExpenses(payload));

    return this.processChunkedResponse(updateChunks$).pipe(
      tap(() => this._isActionInProgress$.next(false))
    );
  }

  private confirmAction(confirmationData: ExpenseActionConfirmation, okHandler: () => Observable<any>): Observable<void> {
    const { message, title, isActionForbidden } = confirmationData;

    return new Observable((subscriber) => {
      let dialogContext: Partial<DialogContext> = {
        title,
        content: message,
        cancelAction: {
          label: 'Ok',
          handler: () => subscriber.complete()
        }
      };

      if (isActionForbidden) {
        dialogContext = {
          ...dialogContext,
          cancelAction: { label: 'Ok', handler: () => subscriber.complete() }
        };
      } else {
        dialogContext = {
          ...dialogContext,
          cancelAction: { handler: () => subscriber.complete() },
          submitAction: { handler: () => { okHandler().subscribe(subscriber); } },
        };
      }

      this.dialogManager.openConfirmationDialog(dialogContext);
    });
  }

  public createContextForSingleSelect(
    selectedExpenses: ExpenseDO[],
    selectedOption: ActionSelectOption
  ): Observable<SingleSelectActionContext> {
    return of ({
      selectedOption,
      ...ExpenseActionsService.createDefaultContext(selectedExpenses),
    });
  }

  public createContextForDirectAction(
    actionType: ExpenseActionType,
    selectedExpenses: ExpenseDO[]
  ): Observable<ActionHandlingContext> {
    
    const contextProvider = this.contextProviders[actionType];
    return contextProvider?.(selectedExpenses) || of(ExpenseActionsService.createDefaultContext(selectedExpenses));
  }

  public createContextForChangeSegment(
    selectedExpenses: ExpenseDO[]
  ): Observable<SingleSelectActionContext> {
    const hierarchyOptions = this.actions[ExpenseActionType.ChangeSegment].handlingConfig.hierarchyOptions;
    return this.openHierarchyDialog(hierarchyOptions, 'Change Segment', false).pipe(
      map((selectedValue: HierarchySelectItem) => {
        const customUpdateProps = this.budgetObjectService.prepareDataForUpdatingSegment(
          selectedExpenses,
          selectedValue.objectType,
          selectedValue.objectId,
          this._isStatuslessExpenses
        );
        let toastMessage;
        let undoPayloads;
        if (
          this._isStatuslessExpenses &&
          (customUpdateProps.hasOwnProperty('campaign') || customUpdateProps.hasOwnProperty('program'))
        ) {
          undoPayloads = this.budgetObjectService.prepareUndoPayloads(selectedExpenses);
          toastMessage = SidebarActionMessage.NoParentSet;
        }

        return {
          selectedExpenses,
          selectedOption: {
            context: { customUpdateProps }
          },
          toastMessage,
          undoPayloads
        };
      })
    );
  }

  public createContextForChangeParent(
    selectedExpenses: ExpenseDO[]
  ): Observable<SingleSelectActionContext> {
    const hierarchyOptions = this.actions[ExpenseActionType.ChangeParent].handlingConfig.hierarchyOptions;
    return this.openHierarchyDialog(hierarchyOptions, 'Change Parent', true).pipe(
      map((selectedValue: HierarchySelectItem) => {
        const customUpdateProps = this.budgetObjectService.prepareDataForUpdatingParent(selectedExpenses, selectedValue);
        let toastMessage;
        let undoPayloads;
        if (this._isStatuslessExpenses) {
          toastMessage = SidebarActionMessage.ParentUpdated;

          if (customUpdateProps.hasOwnProperty('company_budget_segment1') || customUpdateProps.hasOwnProperty('split_rule')) {
            undoPayloads = this.budgetObjectService.prepareUndoPayloads(selectedExpenses);
            toastMessage = SidebarActionMessage.SegmentUpdated;
          }
        }

        return {
          selectedExpenses,
          selectedOption: {
            context: { customUpdateProps }
          },
          toastMessage,
          undoPayloads
        };
      })
    );
  }

  public createContextForMoveToBudget(selectedExpenses: ExpenseDO[]): Observable<SingleSelectActionContext> {
    const selectOptions = this.actions[ExpenseActionType.MoveToBudget].handlingConfig.selectOptions;
    return this.openMoveToBudgetDialog(selectOptions).pipe(
      map((selectedOption: ActionSelectOption) => {
        return {
          selectedExpenses,
          selectedOption
        };
      })
    );
  }

  private createContextForAddTags(selectedExpenses: ExpenseDO[]): Observable<TagsActionContext> {
    const companyId = this.companyDataService.selectedCompanySnapshot.id;
    return this.tagService.getTags({ company: companyId }).pipe(
      switchMap(
        tags => this.tagsControlService.openAddTagsDialog(tags)
      ),
      map(tagMappings => ({
        tagMappings,
        companyId,
        ...ExpenseActionsService.createDefaultContext(selectedExpenses),
      }))
    );
  }

  private createContextForAddPoNumber(selectedExpenses: ExpenseDO[]): Observable<TextValueActionContext> {
    const updateData = { title: 'Add PO #', type: BulkUpdateDialogType.text };

    return this.openUpdateDialog(updateData).pipe(
      map((textValue: string) => ({
        textValue,
        ...ExpenseActionsService.createDefaultContext(selectedExpenses)
      }))
    );
  }

  
  private createContextForRemoveTags(selectedExpenses: ExpenseDO[]): Observable<TagsActionContext> {
    const companyId = this.companyDataService.selectedCompanySnapshot.id;
    const expenseIds = selectedExpenses.map(exp => exp.id);

    const tagMappingsToRemove$ =
      this.tagService.getTagMappings(
        companyId,
      {
          mapping_type: this.configuration.OBJECT_TYPES.expense,
          map_ids: expenseIds.join(',')
        }
      ).pipe(
        map(
          tagMappings => {
            const filteredTms = tagMappings.filter(item => expenseIds.includes(item.map_id));
            const uniqueTags = new Map();

            filteredTms.forEach(item => {
              const tagMapping = BudgetObjectTagsService.fromTagMappingDO(item);
              if (!uniqueTags.has(item.tags)) {
                uniqueTags.set(item.tags, tagMapping);
              }
            });

            return [ ...uniqueTags.values() ];
          }
        )
      );

    return this.tagsControlService.openRemoveTagsDialog(
      tagMappingsToRemove$
    ).pipe(
      map(tagMappings => ({
        companyId,
        tagMappings,
        ...ExpenseActionsService.createDefaultContext(selectedExpenses)
      }))
    );
  }

  private createContextForDeleteAction(selectedExpenses: ExpenseDO[]): Observable<DeleteActionContext> {
    const { integratedExpenses, integratedExpLength } = this.getIntegratedExpensesInfo(selectedExpenses);
    const data = integratedExpLength ? { integratedExpenses, groupWithIntegratedExp: selectedExpenses?.length > 1 } : null;
    return of({
      deleteExpenseHandlersData: data,
      ...ExpenseActionsService.createDefaultContext(selectedExpenses),
    });
  }

  private getDeleteConfirmationData(context: DeleteActionContext): ExpenseActionConfirmation {
    let message = 'Delete selected expense/s?';
    let title = 'Delete';
    const data = context?.deleteExpenseHandlersData;
    if (data?.integratedExpenses) {
      message = data?.groupWithIntegratedExp ?
        this.expenseService.messages.CONFIRM_AUTOMATED_EXPENSE_DELETION_PLURAL :
        this.expenseService.messages.CONFIRM_AUTOMATED_EXPENSE_DELETION_SINGULAR;
      title = data?.groupWithIntegratedExp ?
        this.expenseService.messages.CONFIRM_AUTOMATED_EXPENSE_DELETION_TITLE_PLURAL :
        this.expenseService.messages.CONFIRM_AUTOMATED_EXPENSE_DELETION_TITLE_SINGULAR;
    }
    return { message, title };
  }

  private getSingleSelectConfirmationData(title: string, propDisplayName: string, value: string): ExpenseActionConfirmation {
    return {
      title,
      message: ExpenseActionsService.getDefaultActionConfirmationMessage(propDisplayName, value)
    };
  }

  private getChangeOwnerConfirmationData(action: ExpenseAction, context: SingleSelectActionContext): ExpenseActionConfirmation {
    const canWriteToAllSegments = this.canWriteToAllSegments(context.selectedOption.value as number, context.selectedExpenses);
    return canWriteToAllSegments ?
      this.getSingleSelectConfirmationData(action.name, 'owner', context.selectedOption?.title) :
      this.forbiddenActionConfirmationData;
  }

  private canWriteToAllSegments(selectedUserId: number, selectedExpenses: ExpenseDO[]): boolean {
    const companyUser = this.companyDataService.companyUsersSnapshot.find(user => user.id === selectedUserId);
    if (companyUser.is_admin) {
      return true;
    }
    const budgetId = this.budgetDataService.selectedBudgetSnapshot.id;
    const allowedSegments = getAllowedSegments(companyUser.is_admin, companyUser.permissions, budgetId) || [];

    const SCRToSegmentIds = this.budgetDataService.sharedCostRulesSnapshot.reduce((store, scr) => {
      store[scr.id] = scr.segments.map(SCRSegment => SCRSegment.id);
      return store;
    }, {});

    for (const selectedExpense of selectedExpenses) {
      if (selectedExpense.company_budget_segment1 && !allowedSegments.includes(selectedExpense.company_budget_segment1)) {
        return false;
      }
      if (selectedExpense.split_rule) {
        const relatedSegments = SCRToSegmentIds[selectedExpense.split_rule];
        const hasAccess = relatedSegments.some(segmentId => allowedSegments.includes(segmentId));
        if (!hasAccess) {
          return false;
        }
      }
    }

    return true;
  }

  private applySingleSelect(action: ExpenseAction, context: SingleSelectActionContext): Observable<BulkOperationResponse<ExpenseDO>> {
    const option = context.selectedOption;
    return this.updateMultiExpenses(
      action, context.totalExpenseIds || context.selectedExpenses.map(exp => exp.id), option.value, option.context?.customUpdateProps
    );
  }

  private applyTextValue(action: ExpenseAction, context: TextValueActionContext): Observable<BulkOperationResponse<ExpenseDO>> {
    return this.updateMultiExpenses(action, context.totalExpenseIds || context.selectedExpenses.map(exp => exp.id), context.textValue);
  }

  private applyAddTags(action: ExpenseAction, context: TagsActionContext): Observable<BulkOperationResponse<ExpenseDO>> {
    const filteredData = context.tagMappings.reduce((store, addedTag) => {
      if (addedTag.existed) {
        store.existingTagIds.push(addedTag.tagId);
      } else {
        store.newItems.push(addedTag);
      }
      return store;
    }, { existingTagIds: [] as number[], newItems: [] as TagMapping[] });

    const addNewTags$: Observable<number[]> =
      filteredData.newItems.length ?
        this.addNewTags(context.companyId, filteredData.newItems).pipe(
          map(result => [...filteredData.existingTagIds, ...result.map((r: any) => r.id)])
        ) :
        of(filteredData.existingTagIds);

    return addNewTags$.pipe(
      switchMap(tagsIds =>
        this.updateMultiExpenses(action, context.totalExpenseIds || context.selectedExpenses.map(exp => exp.id), tagsIds)
      )
    );
  }

  private applyRemoveTags(context: TagsActionContext): Observable<BulkOperationResponse<number>> {
    const removedTags = context.tagMappings;
    if (!removedTags?.length) {
      return of(null);
    }

    const tagIds = removedTags.map(item => item.tagId);
    const mappingIds = context.totalExpenseIds || context.selectedExpenses.map(expense => expense.id);
    const mappingIdChunks = ExpenseActionsService.getEqualChunks(mappingIds);
    const deleteTagMappingChunks$: Observable<BulkOperationResponse<number>>[] = mappingIdChunks.map(
      mappingIdChunk => this.tagService.multiDeleteTagMapping(mappingIdChunk, this.configuration.OBJECT_TYPES.expense, tagIds)
    );

    return this.processChunkedResponse(deleteTagMappingChunks$);
  }

  private applyMoveToBudget(context: SingleSelectActionContext): Observable<{ message: string }> {
    const selectedBudgetId = +context.selectedOption?.value;
    if (!selectedBudgetId) {
      return of(null);
    }

    const selectedExpenseIds = context.totalExpenseIds || context.selectedExpenses.map(expense => expense.id);
    const expenseIdChunks = ExpenseActionsService.getEqualChunks(selectedExpenseIds);
    const moveToBudgetChunks$: Observable<{ message: string }>[] =
      expenseIdChunks.map(expenseIdChunk => this.expenseService.multiMoveToBudget(expenseIdChunk, selectedBudgetId));

    return forkJoin(moveToBudgetChunks$).pipe(
      map(responses => responses[0]),
      tap(() => {
        this.utilityService.showCustomToastr(`Invoices were moved to the budget ${ context.selectedOption?.title } successfully`);
      }),
      catchError(() => {
        this.utilityService.handleError({ message: 'Something went wrong.' });
        return of(null);
      })
    );
  }

  private addNewTags(companyId: number, newItems: TagMapping[]): Observable<TagDO[]> {
    return forkJoin([
      ...newItems.map(item => this.expenseService.createTag({
        name: item['name'],
        is_custom: true,
        company: companyId
      }))
    ]);
  }

  private openUpdateDialog(updateData: ExpenseActionValueUpdate): Observable<string | AutocompleteControlItem> {
    const { title, type, autocompleteItems } = updateData;

    return new Observable((subscriber) => {
      this.dialogManager.openBulkUpdateDialog({
        submitAction: {
          handler: value => subscriber.next(value),
        },
        cancelAction: {
          handler: () => subscriber.complete()
        },
        title,
        data: { autocompleteItems, maxItemLength: this.configuration.MAX_VENDOR_NAME_LENGTH },
        type
      });
    });
  }

  private openHierarchyDialog(selectItems: HierarchySelectItem[], title: string, allowGroupSelection: boolean): Observable<string | any> {
    return new Observable((subscriber) => {
      this.dialogManager.openMoveToDialog({
        submitAction: {
          handler: value => subscriber.next(value),
        },
        cancelAction: {
          handler: () => subscriber.complete()
        },
        selectItems,
        title,
        allowGroupSelection
      });
    });
  }

  private openMoveToBudgetDialog(selectItems: ActionSelectOption[]): Observable<string | any> {
    return new Observable((subscriber) => {
      this.dialogManager.openMoveToBudgetDialog({
        submitAction: {
          handler: value => subscriber.next(value),
        },
        cancelAction: {
          handler: () => subscriber.complete()
        },
        selectItems
      });
    });
  }

  private hasIntegrated(expense: ExpenseDO): boolean {
    return this.integrationExpenseTypeIds.includes(expense.expense_type);
  }

  public setStatuslessExpensesFlag(statuslessExpenses: boolean): void {
    this._isStatuslessExpenses = statuslessExpenses;
  }

  private checkStatusVisibility(expense: ExpenseDO): boolean {
    return this._isStatuslessExpenses || this.hasIntegrated(expense);
  }

  private hasBudgetTimeframeClosed(expense: ExpenseDO): boolean {
    const lockedTFIds = this.budgetDataService.timeframesSnapshot.filter(tf => tf.locked).map(tf => tf.id);
    return lockedTFIds.includes(expense.company_budget_alloc);
  }

  private hasNotSameTimeframe(expense: ExpenseDO, timeframeId: number): boolean {
    return expense.company_budget_alloc !== timeframeId;
  }

  private hasParentClosed(expense: ExpenseDO): boolean {
    return this.budgetObjectService.doesObjectHaveClosedParent(
      expense,
      this.budgetDataService.programsSnapshot,
      this.budgetDataService.lightCampaignsSnapshot || this.budgetDataService.campaignsSnapshot
    );
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
