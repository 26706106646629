<div
  class="plc-numeric-input"
  [class.with-prefix]="prefix"
  [class.with-suffix]="suffix"
>
  <div class="prefix" *ngIf="prefix">{{ prefix }}</div>
  <input
    type="text"
    class="input"
    currencyMask
    [tabIndex]="tabIndex"
    [selectTextOnFocus]="true"
    [options]="currencyMaskOptions"
    [(ngModel)]="inputValue"
    (blur)="handleBlur()"
    (ngModelChange)="handleModelChange($event)"
  />
  <div class="suffix" *ngIf="suffix">{{ suffix }}</div>
</div>
