import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TooltipType } from './budget-hierarchy-table.types'
import { TableDataBuilder, TableViewRow } from 'app/shared/components/table-view/table-data.type';
import { TableDataBuilderService } from 'app/shared/components/table-view/table-data-builder.service';
import { Configuration } from 'app/app.constants';

const SEGMENT_BUDGET_FIELD = 'segmentBudget';
const REMAINING_ALLOCATED_TITLE = 'Remaining Allocated';
const SEGMENT_BUDGET_TITLE = 'Segment Budget';
const BUDGET_REMAINING_TITLE = 'Remaining /';
const BUDGET_REMAINING_SUBTITLE = 'Over Budget';
const TOTAL_EXPENSES_FIELD = 'totalExpenses';
const TOTAL_EXPENSES_TITLE = 'Total Expenses';

@Component({
  selector: 'budget-hierarchy-table',
  templateUrl: './budget-hierarchy-table.component.html',
  styleUrls: ['./budget-hierarchy-table.component.scss'],
})

/**
* @class - BudgetHierarchyTableComponent
*/
export class BudgetHierarchyTableComponent implements OnChanges {
  @Input() budgetGraphData: any;
  @Input() selectedStatusNames: string[] = [];
  @Input() selectedSegment1Names: string[] = [];

  tableDataBuilder: TableDataBuilder;
  filteredSegments: any[] = [];
  columnTitles: string[];
  columnTitleByFieldName: {[key: string]: string};
  columnSubtitleByFieldName: {[key: string]: string};
  tooltipDescriptionByType: {[key: string]: string};
  columnsFields: string[] = [];
  rows: TableViewRow[] = [];
  appliedSorting = { column: 'rowTitle', reverse: false };
  public tooltipTypeByStatusField: Record<string, TooltipType>;
  public TooltipType = TooltipType;
  SEGMENT_NAME_TITLE = 'Segment Name';
  availableGroups = [];
  groupsExpandState = {};
  expandedGroupsLength = 0;
  columnClasses: {[key: string]: string};

  constructor(private _configuration: Configuration, private tableDataBuilderService: TableDataBuilderService) {
    const { statusNames, statusFields } = this._configuration;
    this.columnTitleByFieldName = {
      [SEGMENT_BUDGET_FIELD]: SEGMENT_BUDGET_TITLE,
      [statusFields.closed]: statusNames.closed,
      [statusFields.committed]: statusNames.committed,
      [statusFields.planned]: statusNames.planned,
      [TOTAL_EXPENSES_FIELD]: TOTAL_EXPENSES_TITLE,
      [statusFields.underBudget]: statusNames.underBudget,
      [statusFields.budgetRemainingAmount]: BUDGET_REMAINING_TITLE,
      [statusFields.remainingAllocated]: REMAINING_ALLOCATED_TITLE,
      [statusFields.available]: statusNames.available,
    };
    this.columnSubtitleByFieldName = {
      [statusFields.budgetRemainingAmount]: BUDGET_REMAINING_SUBTITLE
    };
    this.tooltipDescriptionByType = {
      [TooltipType.Available]: _configuration.budgetStatusesDescriptions.available,
      [TooltipType.NegativeAvailable]: _configuration.budgetStatusesDescriptions.negativeAvailable
    };
    this.tooltipTypeByStatusField = {
      [statusFields.available]: TooltipType.Available,
    }
    this.columnClasses = {
      [TOTAL_EXPENSES_FIELD]: 'boldColumn ut-total-expenses',
      [statusFields.budgetRemainingAmount]: 'boldColumn over-budget ut-remaining',
      [statusFields.closed]: 'ut-closed-expenses',
      [statusFields.committed]: 'ut-committed-expenses',
      [statusFields.planned]: 'ut-planned-expenses',
      [statusFields.underBudget]: 'ut-under-budget-expenses',
      [statusFields.available]: 'ut-available-expenses',
      [statusFields.remainingAllocated]: 'ut-remaining-allocated-expenses',
      [SEGMENT_BUDGET_FIELD]: 'ut-segment-budget',
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.budgetGraphData.segments1) {
      return;
    }

    if ('budgetGraphData' in changes || 'selectedStatusNames' in changes) {
      this.initTableData()
    }

  }

  sortRows(sortColumn: string) {
    const { column, reverse } = this.appliedSorting;
    const updReverse = sortColumn === column ? !reverse : false;
    this.appliedSorting = { column: sortColumn, reverse: updReverse };
    this.rows = this.tableDataBuilder
      .getTableRows(true, false, true, this.appliedSorting);
  }

  public tooltipTypeProvider(fieldType: string, fieldValue: number | null): string {
    const { statusFields } = this._configuration;

    if (fieldType === statusFields.available && fieldValue < 0) {
      return TooltipType.NegativeAvailable;
    }

    return fieldValue == null
      ? this.tooltipTypeByStatusField[fieldType]
      : null;
  }

  initTableData() {
    const data = this.getFilteredSegments();
    this.extendTableData(data); // fill Budget and total values
    this.columnsFields = this.getColumnsFields(data);
    this.columnTitles = [ this.SEGMENT_NAME_TITLE, ...this.columnsFields.map(fieldName => this.columnTitleByFieldName[fieldName])];
    this.tableDataBuilder = this.tableDataBuilderService.create();
    this.rows = this.tableDataBuilder
      .setTableColumns(this.columnsFields)
      .setTableData(this.groupBySegmentGroup(data))
      .setColumnClasses(this.columnClasses)
      .getTableRows(true, false, true, this.appliedSorting);
  }

  extendTableData(rows: any) {
    const { statusFields } = this._configuration;
    rows.forEach(row => {
      const segment = this.budgetGraphData.segments1.find(seg => seg.id === row.id);
      const { data } = row;
      data[SEGMENT_BUDGET_FIELD] = segment && segment.data && segment.data.total || 0;
      data[TOTAL_EXPENSES_FIELD] = data && (data[statusFields.closed] + data[statusFields.committed] + data[statusFields.planned]) || 0;
    });
  }

  groupBySegmentGroup(rows) {
    this.availableGroups = [];
    const grouped = [];
    rows.forEach(dataItem => {
      if (!dataItem.segment_group?.id) {
        // dataItem without parent group
        grouped.push(dataItem);
        return;
      }
      const exisingGroup = grouped.find(g => g.id === dataItem.segment_group.id);
      if (exisingGroup) {
        exisingGroup.items.push(dataItem);
        return;
      }
      grouped.push({
        id: dataItem.segment_group.id,
        name: dataItem.segment_group.name,
        items: [dataItem],
      })
      this.groupsExpandState[dataItem.segment_group.id] = false; // all collapsed by default
      this.availableGroups.push(dataItem.segment_group.id);
    });

    this.calculateGroupValues(grouped);
    return grouped;
  }

  calculateGroupValues(grouped) {
    grouped.forEach((row) => {
      if (!row.items) {
        // single item
        return;
      }
      row.data = row.items.reduce((data, child, i) => {
        if (i === 0) {
          data = { ...child.data };
          return data;
        }
        Object.keys(data).forEach(k => {
          const initValue = data[k] && typeof data[k] === 'number' ? data[k] : 0;
          const addValue = child.data[k] && typeof child.data[k] === 'number' ? child.data[k] : 0;
          data[k] = initValue + addValue;
        })
        return data;
      }, {})
    })
  }

  getFilteredSegments() {
    return this.selectedSegment1Names.length
      ? this.budgetGraphData.segments1.filter(segment => this.selectedSegment1Names.includes(segment.name))
      : this.budgetGraphData.segments1;
  }

  getFilteredStatusNames() {
    const { planned, committed, closed } = this._configuration.statusFields;
    const selected = [ ...this.selectedStatusNames ];
    const showTotal = [planned, committed, closed].every(
      statusName => selected.includes(statusName)
    );
    showTotal && selected.push(TOTAL_EXPENSES_FIELD);
    return selected;
  }

  getColumnsFields(tableData) {
    const { statusFields } = this._configuration;
    const filteredStatusNames = this.getFilteredStatusNames();
    const filteredColumnNames = Object.keys(this.columnTitleByFieldName).filter(field => filteredStatusNames.includes(field));

    return (this.selectedStatusNames.length
      ? [ SEGMENT_BUDGET_FIELD, ...filteredColumnNames ]
      : Object.keys(this.columnTitleByFieldName))
      .filter(field =>
        field !== statusFields.underBudget || tableData.some(row => row.data[field] > 0)
      )
  }

  toggleGroup(group, isCollapsed) {
    this.groupsExpandState[group.id] = !isCollapsed;
    !isCollapsed ? this.expandedGroupsLength++ : this.expandedGroupsLength--;
  }

  toggleAllGroups(collapseAll) {
    Object.keys(this.groupsExpandState).forEach(id => {
      this.groupsExpandState[id] = !collapseAll;
    })
    this.expandedGroupsLength = !collapseAll ? Object.keys(this.groupsExpandState).length : 0;
  }
}
