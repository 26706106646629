<div
  class="metric-performance-arrow"
  matRipple
  [matRippleAnimation]="{ enterDuration: 300, exitDuration: 300 }"
  [matRippleCentered]="true"
  [matRippleUnbounded]="false"
  [matRippleRadius]="rippleRadius"
  [matRippleColor]="rippleColor"
  [matRippleDisabled]="!isClickable"
  (click)="handleClick()"
>
  <icon-fa-arrow-right
    *ngIf="progressState === ProgressState.InTarget"
    [size]="19"
    [color]="COLORS.GREEN_PRIMARY"
    class="icon"
  ></icon-fa-arrow-right>

  <icon-fa-arrow-up-right
    *ngIf="progressState === ProgressState.AboveTarget"
    [size]="14"
    [color]="COLORS.GREEN_PRIMARY"
    class="icon"
  ></icon-fa-arrow-up-right>

  <icon-fa-arrow-down-right
    *ngIf="progressState === ProgressState.BelowTarget"
    [size]="14"
    [color]="COLORS.LOW_ALERT"
    class="icon"
  ></icon-fa-arrow-down-right>

  <icon-fa-arrow-down-right
    *ngIf="progressState === ProgressState.FarBelowTarget"
    [size]="14"
    [color]="COLORS.RED"
    class="icon"
  ></icon-fa-arrow-down-right>
</div>
