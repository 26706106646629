import { Injectable } from '@angular/core';
import { TagRawData, Tag } from './tags-management.interface';
import { BehaviorSubject } from 'rxjs';
import { TagService } from 'app/shared/services/backend/tag.service';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';

@Injectable()
export class TagsManagementService {
  readonly tagsList$ = new BehaviorSubject<Tag[]>([]);
  public fullListLoaded = false;
  private chunkSize = 50;
  private offset = 0;
  private tagsOrdering = { sortBy: 'name', reversed: false };
  private orderIterators = {
    'name': this.orderByName,
    'usage_count': this.orderByReferencesCount
  };

  constructor(public tagService: TagService) {}

  changeListOrdering(sortBy: string) {
    const reversed = sortBy === this.tagsOrdering.sortBy && !this.tagsOrdering.reversed;
    this.tagsOrdering = { sortBy, reversed };
    this.resetListPagination();
  }

  getChunkOfTags(company: number) {
    if (!this.fullListLoaded) {
      const limit = this.chunkSize;
      const offset = this.offset;
      const ordering = this.tagsOrdering.reversed ? `-${this.tagsOrdering.sortBy}` : this.tagsOrdering.sortBy;
      const subscription =
        this.tagService.getTags({ limit, offset, ordering, company, detailed: true })
          .subscribe(parsedData => {
            this.onGetChunkOfTagsSuccess(parsedData);
            subscription.unsubscribe()
          })
    }
  }

  getCompanyTagsFullList(company: number) {
    const ordering = this.tagsOrdering.reversed ? `-${this.tagsOrdering.sortBy}` : this.tagsOrdering.sortBy;
    const subscription =
      this.tagService.getTags({ ordering, company, detailed: true })
        .subscribe(rawTagsList => {
          this.onGetCompanyTagsFullListSuccess(rawTagsList);
          subscription.unsubscribe();
        });
  }

  updateTag(value: string, tagId: number, companyId: number): void {
    const subscription =
      this.tagService.updateTag(value, tagId, companyId).subscribe(
        (updatedTag: TagRawData) => {
          this.onUpdateTagSuccess(updatedTag);
          subscription.unsubscribe();
        }
      );
  }

  removeTagById(tagId: number) {
    const subscription =
    this.tagService.removeTagById(tagId)
        .subscribe(() => {
          this.onRemoveTagByIdSuccess(tagId);
          subscription.unsubscribe();
        })
  }

  checkTagName(company: number, tagName: string) {
    return this.tagService.getTags({name: tagName, company})
      .pipe(map((rawTagsList: any[]) => {
        return rawTagsList.length === 0;
      }));
  }

  createNewTag(tagName: string, companyId: number, cb?) {
    const subscription = this.tagService.createTag({ name: tagName, company: companyId})
      .subscribe((createdTag) => {
        this.onCreateTagSuccess(createdTag, cb);
        subscription.unsubscribe();
      })
  }

  onGetChunkOfTagsSuccess(tagsChunkData) {
    this.updateListPagination(!tagsChunkData.next, true);
    const isFirstChunk = this.offset === this.chunkSize;
    const receivedTags = tagsChunkData.results.map(rawTag => this.adaptTagData(rawTag));
    const tagsList = isFirstChunk
      ? receivedTags
      : [ ...this.tagsList$.getValue(), ...receivedTags ];
    this.tagsList$.next(tagsList);
  }

  onGetCompanyTagsFullListSuccess(rawTagsList) {
    this.updateListPagination(true, false);
    const list = rawTagsList.map(rawTag => this.adaptTagData(rawTag));
    this.tagsList$.next(list);
  }

  onUpdateTagSuccess(updatedTag: TagRawData) {
    const tagsList = this.tagsList$.getValue();
    const index = tagsList.findIndex(tagItem => tagItem.id === updatedTag.id);
    if (index !== -1) {
      tagsList[index] = this.adaptTagData(updatedTag);
    }
    this.tagsList$.next(tagsList);
  }

  onRemoveTagByIdSuccess(tagId) {
    const tagsList = this.tagsList$.getValue().filter(tag => tag.id !== tagId);
    this.tagsList$.next(tagsList);
  }

  onCreateTagSuccess(createdTag: TagRawData, cb?) {
    const tagsList = this.sortTagsList(
      [...this.tagsList$.getValue(), this.adaptTagData(createdTag)]
    );
    // prevent tags list item duplication
    if(tagsList.length > this.offset) {
      tagsList.length = this.offset
    }
    cb(createdTag);
    this.tagsList$.next(tagsList);
  }

  updateListPagination(fullListLoaded = false, increaseOffset = false) {
    this.fullListLoaded = fullListLoaded;
    if(increaseOffset) {
      this.offset = this.offset + this.chunkSize;
    }
  }

  resetListPagination() {
    this.fullListLoaded = false;
    this.offset = 0;
  }

  orderByName(tag, reversed) {
    return tag.name.toUpperCase().charCodeAt() * (reversed ? -1 : 1);
  }

  orderByReferencesCount(tag, reversed) {
    return tag.usageCount * (reversed ? -1 : 1);
  }

  sortTagsList(tags: Tag[]): Tag[] {
    const { sortBy, reversed } = this.tagsOrdering;
    const iterator = this.orderIterators[sortBy];
    return _.sortBy(tags, (tag) => iterator(tag, reversed))
  }

  adaptTagData(tag: TagRawData): Tag {
    return {
      id: tag.id,
      company: tag.company,
      usageCount: tag.usage_count,
      name: tag.name
    }
  }
}
