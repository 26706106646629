import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TagControlEvent, TagsControlComponent } from '@shared/components/tags-control/tags-control.component';
import { Attachment } from '@shared/types/attachment.interface';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { BudgetObjectAttachmentsService } from 'app/budget-object-details/services/budget-object-attachments.service';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { GoalDetailsState } from 'app/budget-object-details/types/budget-object-details-state.interface';
import { BudgetObjectActionsShared } from 'app/budget-object-details/services/budget-object-actions-shared';
import { AutocompleteItem } from '@shared/types/autocomplete-item.type';

@Component({
  selector: 'goal-details-form',
  templateUrl: './goal-details-form.component.html',
  styleUrls: ['./goal-details-form.component.scss']
})
export class GoalDetailsFormComponent {

  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);

  @Input() goalFormData: FormGroup;
  @Input() currentState: GoalDetailsState;
  @Input() objectType: string;
  @Input() isReadOnlyMode = false;
  @Input() goalTypes: BudgetObjectType[] = [];
  @Input() isCustomTypeEntering: boolean;
  @Input() unsavedCustomTypeId: number;
  @Input() tagsAutocompleteItems: AutocompleteItem[];

  @Input() attachmentsManager: BudgetObjectAttachmentsService;
  @Output() handleFileAttached = new EventEmitter<Attachment>();
  @Output() handleFileDelete = new EventEmitter<Attachment>();
  @Output() handleFileDownload = new EventEmitter<Attachment>();

  @Output() syncUnsavedChangesFlag = new EventEmitter<void>();

  @Output() handleTypeChange = new EventEmitter<void>();
  @Output() handleCustomTypeChange = new EventEmitter<void>();

  @ViewChild('tagsControl') tagsControl: TagsControlComponent;

  /* TAGS */
  protected createTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.createTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag.emit();
  }

  protected addTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.addTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag.emit();
  }

  protected removeTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.removeTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag.emit();
  }
}
