import { NgModule } from '@angular/core';
import { ManageSuperadminsComponent } from './manage-superadmins/manage-superadmins.component';
import { SharedModule } from 'app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderNavigationModule } from 'app/header-navigation/header-navigation.module';
import { ManageCompanyComponent } from './manage_company/manage_company.component';
import { UniqueDomainPrefixDirective } from './manage_company/enable-sso-dialog/unique-domain-prefix.directive';
import { EnableSsoDialogComponent } from './manage_company/enable-sso-dialog/enable-sso-dialog.component';
import { MetricIntegrationsModule } from 'app/metric-integrations/metric-integrations.module';
import { SuperadminModalComponent } from './modals/super-admin/superadmin-modal.component';
import { SuperadminLogsModal } from './modals/super-admin-logs/superadmin-logs-modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SuperadminService } from './shared/superadmin.service';
import { EnableSaasOpticsIdComponent } from './manage_company/enable-saas-optics-id/enable-saas-optics-id.component';
import { ActionsDropdownComponent } from './shared/actions-dropdown/actions-dropdown.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    ManageSuperadminsComponent,
    ManageCompanyComponent,
    EnableSsoDialogComponent,
    UniqueDomainPrefixDirective,
    SuperadminModalComponent,
    SuperadminLogsModal,
    EnableSaasOpticsIdComponent,
    ActionsDropdownComponent,
  ],
  imports: [
    ScrollingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    HeaderNavigationModule,
    MetricIntegrationsModule,
    FontAwesomeModule,
  ],
  providers: [
    SuperadminService,
  ]
})
export class SuperAdminModule { }
