import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from 'app/shared/services/utility.service';
import { Validations } from 'app/app.validations';
import { Configuration } from 'app/app.constants';
import { UserManager } from 'app/user/services/user-manager.service';

const SUCCESS_MESSAGE = 'Check your email for password reset link';

@Component({
  selector: 'app-root',
  templateUrl: './forgot-password.component.html',
  providers: []
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public form: any;
  subscriptions: any[] = [];

  constructor(
    private readonly configuration: Configuration,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    protected readonly validations: Validations,
    private readonly userManager: UserManager
  ) {
    this.form = {
      email: ''
    };
  }

  ngOnInit() {
    this.subscriptions.push(
      this.userManager.isLoggedIn().subscribe(loggedIn => {
        if (loggedIn) {
          this.router.navigate([this.configuration.ROUTING_CONSTANTS.PLAN_DETAIL]);
        }
      })
    );
  }

  forgot_password(form, isValid: boolean, ngForm: any) {
    this.utilityService.showLoading(true);
    if (isValid) {
      this.userManager.forgotPassword(form.email.toLowerCase()).subscribe(
        () => this.success(ngForm),
        () => this.success(form)
      );
    }
  }

  success(ngForm) {
    ngForm.resetForm();
    this.utilityService.showToast({ Title: '', Message: SUCCESS_MESSAGE, Type: 'success' });
    this.utilityService.showLoading(false);
  }

  HandleError(error) {
    this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
    this.utilityService.showLoading(false);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
