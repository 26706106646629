import { DecimalPipe } from '@angular/common';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export type OnExecuteHandler = (message: string, undoCb: Function) => void;
export type OnUndoHandler = (viaSnackbar?: boolean) => void;
export type UndoCallback = () => Observable<any>;

export interface BudgetAllocationActionParams {
  currency?: string;
  decimalPipe?: DecimalPipe;
  decimalPipeFormat?: string;
}

export abstract class BudgetAllocationAction<TActionParams extends BudgetAllocationActionParams> {
  protected onExecuted: OnExecuteHandler;
  protected onUndone: OnUndoHandler;
  protected actionParams: TActionParams;
  protected defaultDecimalPipeFormat = '1.2-2';
  protected onExecutedMessage = '';

  public setOnExecuted(handler: OnExecuteHandler) {
    this.onExecuted = handler;
  }
  public setOnUndone(handler: OnUndoHandler) {
    this.onUndone = handler;
  }
  public getOnExecutedMessage(): string {
    return this.onExecutedMessage;
  }
  public abstract execute(): void;
  public abstract undo(viaSnackbar?: boolean): void;

  protected formatValue(value: number): string {
    const { decimalPipe, decimalPipeFormat } = this.actionParams;

    if (decimalPipe) {
      return decimalPipe.transform(value, decimalPipeFormat || this.defaultDecimalPipeFormat);
    }

    return value.toFixed(2);
  }

  protected runCallbacks(callbacks: UndoCallback[]): Observable<any> {
    if (!callbacks?.length) {
      return of(null);
    }

    return forkJoin(
      callbacks.map(callback => callback())
    ).pipe(
      catchError(err => {
        console.warn('Failed to run action callbacks', err.message);
        return of(null);
      })
    )
  }
}
