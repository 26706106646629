<div class="filters-management-wrapper" [class.filters-applied]="filtersApplied">
  <div class="container-controls" >
    <div>
      <button
        mat-button
        [filtersTrigger]="filtersContainer"
        class="header-action-item toggler"
        (click)="closeDrawer()"
        [disabled]="disabled"
      >
        <fa-icon class="primary-icon" *ngIf="!selectedFiltersCount" [icon]="['fas', 'sliders']"></fa-icon>
        <span *ngIf="selectedFiltersCount" class="custom-badge">{{ selectedFiltersCount }}</span>
        <span class="btn-text">
          Filters
        </span>
        <fa-icon class="chevron" [icon]="['far', 'chevron-down']"></fa-icon>
      </button>
    </div>
    <span class="separator"></span>
    <div class="saved-filters-control">
      <button mat-button class="header-action-item" [disabled]="!filtersApplied || isDrawerOpen" (click)="clearFiltersImmediately()">
        <fa-icon class="secondary-icon" [icon]="['fas', 'circle-xmark']"></fa-icon>
        <span class="btn-text">Clear Filters</span>
      </button>
      <button mat-button class="header-action-item" [disabled]="!(savedFilters?.length > 0) || isDrawerOpen" (click)="openSavedFiltersModal()">
        <fa-icon class="secondary-icon" [icon]="['fas', 'star']"></fa-icon>
        <span class="btn-text">Saved Filters</span>
      </button>
    </div>
  </div>
</div>

<ng-template #filtersContainer>
  <filters-container
    [activeFilters]="activeFilters"
    [filtersData]="filtersData"
    [customFiltersData]="customFiltersData"
    [activeRouteForCustomFilters]="activeRouteForCustomFilters"
    (onApplyClick)="applyFilters($event)"
    (onSaveClick)="openSaveFilterModal($event)"
    (onCancelClick)="onCloseFilterContainer()"
    [isCEGMode]="isCEGMode"
    >
  </filters-container>
</ng-template>
