<button
  #menuTrigger="matMenuTrigger"
  class="trigger"
  [disabled]="!menuItems?.length || disabled"
  mat-icon-button
  [matMenuTriggerFor]="panel.menu"
  aria-label="Expense table actions"
  matTooltip="Manage"
  matTooltipShowDelay="1000"
  matTooltipClass="dark-theme-tooltip simple"
  matTooltipPosition="above"
>
  <fa-icon [icon]="['fas', 'screwdriver-wrench']"></fa-icon>
</button>

<menu-panel #panel [items]="menuItems" panelClass="expenses-manage-menu-panel">
  <action-menu
    [actionItems]="actionItems"
    (onSelect)="menuTrigger.closeMenu()"
    (onActionComplete)="updateData()"
  ></action-menu>
</menu-panel>

