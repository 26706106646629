import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ManageTableRow } from '../manage-table/manage-table.types';
import { ManageTableMenuAction } from '../../types/manage-table-menu-action.type';
import { messages, objectPlaceholderName } from 'app/budget-object-details/messages';
import { UtilityService } from 'app/shared/services/utility.service';
import { ManageTableRowTypeLabel } from '../manage-table/manage-table.constants';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

interface RecordAction {
  title: string;
  tooltip?: string;
  icon: IconProp;
  handler: Function;
  hidden: boolean;
  disabled: boolean;
  customCssClass?: string;
}

@Component({
  selector: 'manage-table-record-actions',
  templateUrl: './manage-table-record-actions.component.html',
  styleUrls: ['./manage-table-record-actions.component.scss']
})
export class ManageTableRecordActionsComponent implements OnInit, OnChanges {
  @Input() isClosed: boolean;
  @Input() record: ManageTableRow;
  @Output() onActionClick = new EventEmitter<ManageTableMenuAction>();

  @Input() set externalId(externalId: string) {
    this.checkCopyAction();
  }
  private readonly actionsMap: Record<string, RecordAction> = {
    [ManageTableMenuAction.Duplicate]: {
      title: 'Duplicate',
      icon: ['fad', 'copy'],
      hidden: false,
      disabled: false,
      handler: () => this.onActionClick.emit(ManageTableMenuAction.Duplicate)
    },
    [ManageTableMenuAction.MoveTo]: {
      title: 'Move To',
      icon: ['fad', 'folder-open'],
      hidden: false,
      disabled: false,
      handler: () => this.onActionClick.emit(ManageTableMenuAction.MoveTo)
    },
    [ManageTableMenuAction.Close]: {
      title: 'Close',
      icon: ['fad', 'hourglass-end'],
      hidden: false,
      disabled: false,
      handler: () => this.onActionClick.emit(ManageTableMenuAction.Close)
    },
    [ManageTableMenuAction.Reopen]: {
      title: 'Reopen',
      icon: ['fad', 'hourglass-start'],
      hidden: true,
      disabled: false,
      handler: () => this.onActionClick.emit(ManageTableMenuAction.Reopen)
    },
    [ManageTableMenuAction.CopyId]: {
      title: 'Copy ID',
      icon: ['fad', 'fingerprint'],
      hidden: false,
      disabled: false,
      handler: () => this.copyId()
    },
    [ManageTableMenuAction.Delete]: {
      title: 'Delete',
      icon: ['fad', 'trash-alt'],
      handler: () => this.onActionClick.emit(ManageTableMenuAction.Delete),
      hidden: false,
      disabled: false,
      customCssClass: 'action-danger'
    }
  };
  public actions: RecordAction[] = [];

  constructor(private readonly utilityService: UtilityService) {}

  ngOnInit(): void {
    this.actions = this.generateActions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isClosed) {
      this.onClosedFlagChange();
    }

    if (changes.record) {
      this.onMoveToFlagChange();
      this.onDuplicateFlagChange();
      this.actionsMap[ManageTableMenuAction.MoveTo].hidden = this.record.type === ManageTableRowType.Goal;
    }
  }

  private onClosedFlagChange(): void {
    this.actionsMap[ManageTableMenuAction.Reopen].hidden = !this.isClosed;
    this.actionsMap[ManageTableMenuAction.MoveTo].disabled = this.isClosed;
    this.actionsMap[ManageTableMenuAction.Close].hidden = this.isClosed || this.record.type === ManageTableRowType.Goal;
    this.actionsMap[ManageTableMenuAction.Duplicate].disabled = this.isClosed;
    this.actionsMap[ManageTableMenuAction.Delete].disabled = this.isClosed;
  }

  private onMoveToFlagChange(): void {
    const hasChildCampaign = (this.record.children || []).some(child => child.type === ManageTableRowType.Campaign);
    if (hasChildCampaign) {
      this.actionsMap[ManageTableMenuAction.MoveTo].disabled = hasChildCampaign
        && !this.record.parentId?.includes(ManageTableRowType.Goal.toLowerCase());
    }
  }

  private onDuplicateFlagChange(): void {
    this.actionsMap[ManageTableMenuAction.Duplicate].disabled = this.isClosed;
  }

  private generateActions(): RecordAction[] {
    return [
      this.actionsMap[ManageTableMenuAction.Duplicate],
      this.actionsMap[ManageTableMenuAction.MoveTo],
      this.actionsMap[ManageTableMenuAction.Close],
      this.actionsMap[ManageTableMenuAction.Reopen],
      this.actionsMap[ManageTableMenuAction.CopyId],
      this.actionsMap[ManageTableMenuAction.Delete],
    ];
  }

  private checkCopyAction(): void {
    this.actionsMap[ManageTableMenuAction.CopyId].hidden = this.record.type !== ManageTableRowType.Campaign && this.record.type !== ManageTableRowType.ExpenseGroup;
    this.actionsMap[ManageTableMenuAction.CopyId].disabled = !this.record.externalId;
  }

  private copyId(): void {
    navigator.clipboard.writeText(this.record.externalId).then(() =>
      this.utilityService.showToast({
        Message: messages.COPY_OBJECT_ID_SUCCESS_MSG.replace(objectPlaceholderName, ManageTableRowTypeLabel[this.record.type] + ' ID')
      }),
      () => this.utilityService.showToast({ Message: messages.COPY_OBJECT_ID_ERROR_MSG })
    );
  }
}
