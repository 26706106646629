<div class="performance-summary-wrap" [ngClass]="{'tab-data': isTabData }">
  <div class="metric-arrow">
    <metric-performance-arrow
      [isClickable]="false"
      [progressState]="progressState"
    ></metric-performance-arrow>
    <span [ngStyle]="{ 'color':colorsMap[progressState] }" *ngIf="!isTabData">{{ estimatedDiffPercentage < 0 ? (-estimatedDiffPercentage) : estimatedDiffPercentage }}%</span>
  </div>
  <div class="metric-estimations" [ngStyle]="{ 'color':colorsMap[progressState] }">
    <ng-container *ngIf="estimatedDiffPercentage != null">
      <span *ngIf="isTabData">{{ estimatedDiffPercentage < 0 ? (-estimatedDiffPercentage) : estimatedDiffPercentage }}%</span>
      {{ estimatedDiffPercentage < 0 ? 'behind' : 'above' }} today{{ isTabData ? '' : " 's target" }}
    </ng-container>
  </div>
</div>