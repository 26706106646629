import { Component, Input } from '@angular/core';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { HistoryItem, HistoryService } from 'app/shared/services/history.service';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { MenuPanelItem } from '../menu-panel/menu-panel.type';
import { IconByHistoryObjectType } from 'app/home/components/history-widget-table/history-widget-table.component';

interface HistoryMenuItem extends MenuPanelItem {
  metricOrigin?: MenuPanelItem
}

@Component({
  selector: 'history-menu',
  templateUrl: './history-menu.component.html',
  styleUrls: ['./history-menu.component.scss']
})
export class HistoryMenuComponent {
  private readonly HISTORY_ITEMS_LIMIT = 10;
  @Input() disabled = false;

  items: HistoryMenuItem[] = [];
  faIconByObjectType = IconByHistoryObjectType;
  elongatedContainer = false;
  constructor(private historyService: HistoryService, private appRouting: AppRoutingService) {
    this.historyService.history$.subscribe(items => items && this.generateHistoryMenu(items.slice(0, this.HISTORY_ITEMS_LIMIT)));
  }

  generateHistoryMenu(items: HistoryItem[]) {
    this.elongatedContainer = false;
    this.items = items.map(
      entry => {
        const { type, name, id } = entry.object;
        const item = this.createMenuItem(type, name);
        if (type === HistoryObjectLogTypeNames.metricMapping) {
          const originType = entry.parent?.type;
          item.metricOrigin = this.createMenuItem(originType, entry.parent?.name);
          item.action = event => this.showMetricMappingDetails(originType, id);
          this.elongatedContainer = true;
        } else {
          item.action = event => this.showObjectDetails(type, id);
        }
        return item;
      }
    )
  }

  createMenuItem(objType, label): HistoryMenuItem {
    const faIcon = this.faIconByObjectType[objType];
    return { label, faIcon }
  }

  showObjectDetails(objType, objId) {
    switch (objType) {
      case HistoryObjectLogTypeNames.campaign: {
        return this.appRouting.openCampaignDetails(objId)
      }
      case HistoryObjectLogTypeNames.program: {
        return this.appRouting.openProgramDetails(objId)
      }
      case HistoryObjectLogTypeNames.goal: {
        return this.appRouting.openGoalDetails(objId)
      }
      case HistoryObjectLogTypeNames.expense: {
        return this.appRouting.openExpenseDetails(objId)
      }
    }
  }

  showMetricMappingDetails(originType, objId) {
    switch (originType) {
      case HistoryObjectLogTypeNames.campaign: {
        return this.appRouting.openCampaignMetricDetails(objId)
      }
      case HistoryObjectLogTypeNames.program: {
        return this.appRouting.openProgramMetricDetails(objId)
      }
      case HistoryObjectLogTypeNames.goal: {
        return this.appRouting.openGoalMetricDetails(objId)
      }
    }
  }
}
