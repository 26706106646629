import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSharedSubObject'
})
export class IsSharedSubObjectPipe implements PipeTransform {
  transform(object: {
    split_rule?: number | null;
    company_budget_segment1?: { id: number; name: string; } | number | null;
  }): boolean {
    return object.split_rule != null && object.company_budget_segment1 != null;
  }
}
