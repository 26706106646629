<ng-container *ngFor="let option of items;">
  <hierarchy-option-content
    [ngClass]="{
      'not-clickable': option.notClickable,
      'selected': selectedId === option.id,
      'empty-item': option.id === null,
      'top-level-item': !option.level,
      'type-campaign': option.objectType === OBJECT_TYPES.campaign,
      'has-children': option.children?.length
    }"
    [className]="'level-' + option.level || 0"
    [groupsOpenState]="groupsOpenState"
    [isSingleItem]="!option.children?.length"
    [option]="option"
    (toggleGroup)="toggleGroup($event, option.id)"
    (click)="selectId(option)"
  ></hierarchy-option-content>

  <div *ngIf="option.children?.length"
       class="group-options"
       [class.is-group-open]="groupsOpenState[option.id]">
    <hierarchy-options-list
      [items]="option.children"
      [selectedId]="selectedId"
      [groupsOpenState]="groupsOpenState"
      [allowGroupSelection]="allowGroupSelection"
      (selectOption)="selectOption.emit($event)"
      (toggleGroupState)="toggleGroupState.emit($event)"></hierarchy-options-list>
  </div>
</ng-container>

<div *ngIf="!items?.length" class="color-accent mt-1">
  No search results.
</div>

