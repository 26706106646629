import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { withAPIV0ErrorHandling, getRequestOptions } from 'app/shared/utils/http-request.utils';
import { Observable } from 'rxjs';
import { ProgramTypeDO } from '../../types/program.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {
  private readonly actionUrl = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPath = 'vendor/';

  addVendors(data) {
    return withAPIV0ErrorHandling(
      this.http.post(`${this.actionUrl}${this.apiPath}`, JSON.stringify(data))
    );
  }

  getVendors(data): Observable<ProgramTypeDO[]> {
    return withAPIV0ErrorHandling(
      this.http.get<ProgramTypeDO[]>(`${this.actionUrl}${this.apiPath}list_for_admin/`, getRequestOptions(data))
    );
  }

  updateVendors(id, data) {
    return withAPIV0ErrorHandling(
      this.http.patch(`${this.actionUrl}${this.apiPath}${id}/`,  JSON.stringify(data))
    );
  }

  deleteVendors(id) {
    return withAPIV0ErrorHandling(
      this.http.delete(`${this.actionUrl}${this.apiPath}${id}/`)
    );
  }

}
