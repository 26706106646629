import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'app/app.constants';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { BulkOperationResponse } from '../../types/bulk-operation-response.interface';
import { TagDO } from '../../types/tag-do.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { TagRawData } from '../../../account/tags-management/tags-management.interface';

export interface TagDetailDO {
  id: number;
  company: number;
  usage_count: number;
  name: string;
  is_custom: boolean;
  crd: string;
  upd: string;
}

export interface TagMappingDO {
  id: number;
  tags_detail: TagDetailDO;
  map_id: number;
  mapping_type: string;
  is_dynamic: boolean;
  crd: string;
  upd: string;
  tags: number;
}

@Injectable({
  providedIn: 'root'
})
export class TagService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);
  private readonly configuration = inject(Configuration);

  public apiPaths = {
    tag: 'tag',
    tagMapping: 'tag_mapping',
    multiCreate: 'multi_create',
    multiDelete: 'multi_delete',
    getNewTag: 'tag/list_for_admin',
  };

  getTags(params = null): Observable<TagDO[]> {
    return this.http.get<TagDO[]>(this.apiV2Url + this.apiPaths.tag + '/', getRequestOptions(params));
  }

  getNewTags(params = null): Observable<TagDO[]> {
    return this.http.get<TagDO[]>(this.apiV2Url + this.apiPaths.getNewTag + '/', getRequestOptions(params));
  }

  createTag(data: Partial<TagDO>): Observable<TagDO> {
    return this.http.post<TagDO>(this.apiV2Url + this.apiPaths.tag + '/', JSON.stringify(data));
  }

  updateTag(value: string, tagId: number, companyId: number): Observable<TagRawData> {
    const url = this.apiV2Url + this.apiPaths.tag + '/' + tagId + '/';
    return this.http.put<TagRawData>(url, JSON.stringify({ company: companyId, name: value }));
  }

  removeTagById(tagId: number): Observable<void> {
    const url = this.apiV2Url + this.apiPaths.tag + '/' + tagId + '/';
    return this.http.delete<void>(url);
  }

  getTagMappings(companyId: number, paramsObj?: object): Observable<TagMappingDO[]> {
    const params = {
      company: companyId,
      ...paramsObj
    };

    return this.http.get<TagMappingDO[]>(
      this.apiV2Url + this.apiPaths.tagMapping + '/',
      getRequestOptions(params)
    );
  }

  createTagMapping(data: object): Observable<TagMappingDO> {
    return this.http.post<TagMappingDO>(
      this.apiV2Url + this.apiPaths.tagMapping + '/', JSON.stringify(data)
    );
  }

  deleteTagMapping(tagMappingId: number, isDymamic = true): Observable<void> {
    return this.http.delete<void>(
      this.apiV2Url + this.apiPaths.tagMapping + '/' + tagMappingId + '/',
      getRequestOptions({is_dynamic: isDymamic})
    );
  }

  multiCreateTagMapping(
    mapIds: number[],
    tagIds: number[],
    mappingType: string = this.configuration.OBJECT_TYPES.expense,
  ): Observable<BulkOperationResponse<TagMappingDO>> {
    return this.http.post<BulkOperationResponse<TagMappingDO>>(
      this.apiV2Url + this.apiPaths.tagMapping + '/' + this.apiPaths.multiCreate + '/',
      JSON.stringify({ tag_ids: tagIds, map_ids: mapIds, mapping_type: mappingType.toLowerCase() })
    );
  }

  multiDeleteTagMapping(
    tagMappingIds: number[],
    tagMappingType: string = this.configuration.OBJECT_TYPES.expense,
    tagsIds: number[] = []
  ): Observable<BulkOperationResponse<number>> {
    const tagMappingIdsQuery = tagMappingIds.join(',');
    const payload: {
      map_ids: string;
      mapping_type: string;
      tags_ids?: string;
    } = {
      map_ids: tagMappingIdsQuery,
      mapping_type: tagMappingType
    };

    if (tagsIds.length) {
      payload.tags_ids = tagsIds.join(',');
    }

    return this.http.delete<BulkOperationResponse<number>>(
      this.apiV2Url + this.apiPaths.tagMapping + '/' + this.apiPaths.multiDelete + '/',
      getRequestOptions(payload)
    );
  }

  validateUniqueTagName(tagName, onValid, onInvalid) {
    this.getTags({name: tagName}).subscribe(
      (tagList) => {
        tagList.length === 0
          ? onValid()
          : onInvalid()
      }
    )
  }
}
