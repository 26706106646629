import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { SharedCostRule, SharedCostRuleSegment } from 'app/shared/types/shared-cost-rule.interface';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { BudgetSegmentService } from './budget-segment.service';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { Observable } from 'rxjs';

const DEFAULT_SEGMENT_PERCENT = 50;

@Injectable({
  providedIn: 'root'
})
export class SharedCostRulesService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);
  private readonly budgetSegmentService = inject(BudgetSegmentService);

  public apiPaths = {
    shareCostRule: 'split_rule',
    sharedCostRuleDetailed: 'split_rule/details'
  };
  public createEmptySegment = (cost: number): SharedCostRuleSegment => ({ cost });
  public createDefaultSegments = (): SharedCostRuleSegment[] => [
    this.createEmptySegment(DEFAULT_SEGMENT_PERCENT),
    this.createEmptySegment(DEFAULT_SEGMENT_PERCENT)
  ];

  getAllRules(queryOptions: object, includeDetails = false): Observable<SharedCostRule[]> {
    const url =
      includeDetails ?
        `${this.apiV2Url}${this.apiPaths.sharedCostRuleDetailed}/` :
        `${this.apiV2Url}${this.apiPaths.shareCostRule}/`;

    return this.http.get(url, getRequestOptions(queryOptions)).pipe(
      map(
        (rules: any[]) => rules.map((rule: any) => this.convertResponseObjToSharedCostRule(rule))
      )
    );
  }

  getRuleById(id: number): Observable<SharedCostRule> {
    return this.http.get(this.apiV2Url + this.apiPaths.shareCostRule + '/' + id + '/').pipe(
      map(
        (rule: any) => this.convertResponseObjToSharedCostRule(rule)
      )
    );
  }

  saveRule(companyId: number, rule: SharedCostRule): Observable<SharedCostRule> {
    const data = this.convertSharedCostRuleToBackendData(rule);
    data.company = companyId;

    return this.http.post(
      this.apiV2Url + this.apiPaths.shareCostRule + '/',
      JSON.stringify(data)
    ).pipe(
      map((responseRule: any) => this.convertResponseObjToSharedCostRule(responseRule))
    );
  }

  updateRule(rule: SharedCostRule): Observable<SharedCostRule> {
    const { id } = rule;
    const data = this.convertSharedCostRuleToBackendData(rule);

    return this.http.patch(
      this.apiV2Url + this.apiPaths.shareCostRule + '/' + id + '/',
      JSON.stringify(data)
    ).pipe(
      map((responseRule: any) => this.convertResponseObjToSharedCostRule(responseRule))
    );
  }

  deleteRule(id: number): Observable<void> {
    return this.http.delete<void>(this.apiV2Url + this.apiPaths.shareCostRule + '/' + id);
  }
  // !!! This segments list contain all the budget segments even for restricted users.
  // Restricted users should have access only to allowed segments.
  getSegmentsMapByBudgetId(budgetId) {
    return this.budgetSegmentService.getBudgetSegments(budgetId).pipe(
      map((segments: any[]) => segments.reduce((mapping, segment) => {
        mapping[segment.id] = segment.name;
        return mapping;
      }, {}))
    );
  }

  private convertResponseObjToSharedCostRule(obj: any): SharedCostRule {
    if (!obj) {
      return null;
    }

    return {
      id: obj.id,
      budgetId: obj.budget,
      name: obj.name,
      isActive: obj.is_active,
      instancesNumber: obj.instances_number,
      segments: this.convertRuleContentToSegments(obj.rule_content)
    };
  }

  private convertRuleContentToSegments(ruleContent: any): SharedCostRuleSegment[] {
    if (!ruleContent) {
      return [];
    }

    return Object.keys(ruleContent)
      .map((key: string) => ({
        id: Number.parseInt(key, 10),
        cost: Number.parseFloat(ruleContent[key])
      }));
  }

  private convertSharedCostRuleToBackendData(rule: SharedCostRule): any {
    const { name, budgetId: budget, segments, isActive } = rule;
    const ruleContent = this.convertSegmentsToRuleContent(segments);
    return { name, budget, rule_content: ruleContent, is_active: isActive };
  }

  private convertSegmentsToRuleContent(segments: SharedCostRuleSegment[]): any {
    return segments.reduce((store: any, segment: SharedCostRuleSegment) => {
      const { id, cost } = segment;
      if (id) {
        store[id] = cost || 0;
      }
      return store;
    }, {});
  }
}
