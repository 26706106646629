import { Component, Input } from '@angular/core';

@Component({
  selector: 'integration-mapping-menu',
  templateUrl: './integration-mapping-menu.component.html',
  styleUrls: ['./integration-mapping-menu.component.scss']
})
export class IntegrationMappingMenuComponent {
  @Input() salesforceHandler: () => void;
  @Input() hubSpotHandler: () => void;
}
