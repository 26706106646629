import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MetricValueRecords } from '../../../types/metric-value-records.interface';
import { MetricMilestones } from '../../../types/metric-milestone.interface';
import { MetricsUtilsService } from '../../../services/metrics-utils.service';
import { AmchartsService } from 'app/shared/services/amcharts.service';
import { parseDateString } from '../../containers/campaign-details/date-operations';

@Component({
  selector: 'metric-roi-chart',
  templateUrl: './metric-roi-chart.component.html',
  styleUrls: ['./metric-roi-chart.component.scss']
})
export class MetricRoiChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() data: MetricValueRecords<number> = [];
  @Input() milestones: MetricMilestones;
  @Input() startDate: string;
  @Input() targetROI: number;
  @Input() isRevenueToProfitDefined = false;
  @Input() hideIcon: boolean = false;
  @Input() showTooltip: boolean = false;
  @Input() tooltipValue: string = '';
  @Input() type: string = '';
  @Input() public lastUpdatedInfo: string;
  @ViewChild('chartRef', { static: true }) chartRef: ElementRef;

  private chart: any;
  private trendSeries: any;
  private am4core;
  private am4charts;

  constructor(
    private readonly zone: NgZone,
    private readonly amChartsService: AmchartsService,
    private readonly metricsUtilsService: MetricsUtilsService
  ) { }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.amChartsService.importModules()
        .then(modules => {
          if (!modules) {
            return;
          }
          this.am4core = modules.am4core;
          this.am4charts = modules.am4charts;
          this.disposeChart();
          this.initChart();
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataAvailable = this.data && this.milestones && this.startDate;
    const amChartsAvailable = this.am4core && this.am4charts;

    if (!(dataAvailable && amChartsAvailable)) {
      return;
    }

    if (changes.data || changes.milestones || changes.startDate || changes.targetROI) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.disposeChart();
          this.initChart();
        }, 0)
      });
    }
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  private getStartDate() {
    return this.metricsUtilsService.getChartStartDate(this.data, parseDateString(this.startDate));
  }

  private getChartData(): MetricValueRecords<number> {
    if (!Array.isArray(this.data) || this.data.length === 0) {
      return [];
    }

    return this.data.map(item => {
      const targetShare = Math.round(item.value / this.targetROI * 100);

      return {
        ...item,
        targetShare: isNaN(targetShare) || targetShare === Infinity ? '0%' : `${targetShare}%`
      };
    });
  }

  private getTrendData() {
    return [
      {
        targetValue: this.targetROI,
        date: this.getStartDate()
      },
      {
        targetValue: this.targetROI,
        date: this.amChartsService.getChartEndDate(this.milestones)
      }
    ];
  }

  private createDateAxis() {
    const dateAxis = this.chart.xAxes.push(new this.am4charts.DateAxis());
    const startDate = this.getStartDate();
    const endDate = this.amChartsService.getChartEndDate(this.milestones);

    this.amChartsService.configureDateAxis(dateAxis, startDate, endDate);
    this.amChartsService.createDateAxisRanges(dateAxis, startDate, endDate);

    return dateAxis;
  }

  private createValueAxis() {
    const valueAxis = this.chart.yAxes.push(new this.am4charts.ValueAxis());
    const minValue = this.metricsUtilsService.getMinValue(this.data);
    const onlyPositives = minValue >= 0 && this.targetROI >= 0;

    this.amChartsService.configureValueAxis(valueAxis, onlyPositives);
    this.amChartsService.configureTicks(valueAxis.renderer.ticks.template);

    return valueAxis;
  }

  private createSeries() {
    const series = new this.am4charts.StepLineSeries();
    this.amChartsService.configureMainSeries(series);
    this.amChartsService.configureTooltip(series);
    series.dx = -1;
    series.tooltipText = `[bold]{value.formatNumber('#,###.0')}x ({targetShare})
      on {timestamp}`;

    this.chart.series.push(series);

    return series;
  }

  private createTrendLine() {
    const trend = this.chart.series.push(new this.am4charts.LineSeries());

    this.amChartsService.configureTrendLine(trend);
    trend.dataFields.valueY = 'targetValue';
    trend.dataFields.dateX = 'date';
    trend.data = this.getTrendData();
    trend.zIndex = 1;
    this.trendSeries = trend;

    return trend;
  };

  disposeChart() {
    this.chart = null;
  }

  initChart() {
    if (!this.chart) {
      this.chart = this.am4core.create(this.chartRef.nativeElement, this.am4charts.XYChart);
    }
    this.chart.data = this.getChartData();
    this.amChartsService.configureChart(this.chart);
    this.createDateAxis();
    this.createValueAxis();
    this.createSeries();
    this.createTrendLine();
    this.chart.cursor = this.amChartsService.createCursor();
  }

  public refreshChart() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
      this.initChart();
    })
  }
}
