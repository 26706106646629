<article class="campaign-details details-page-wrapper">
  <details-header
    [actions]="menuActions"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedChangesFlag"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAction.bind(this))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
    [objectHierarchy]="hierarchy"
    [showSaveAndNewButton]="false">
  </details-header>
  <div class="details-content">
    <div class="details-page-content">
      <section class="left-side" [formGroup]="formData">
        <div class="details-sections">
          <metric-type
            [currentMetricId]="currentState?.metricId"
            [productName]="currentState?.productName"
            [metricName]="currentState?.metricName"
            [masterMetricsSelectItems]="masterMetricsSelectItems"
            [isReadonly]="isReadOnlyMode || !!campaign?.parent_campaign || !!campaign?.goal || campaignHasChildren"
            (selectMetric)="selectMetric($event)"
          ></metric-type>
        </div>
        <div class="details-sections">
          <div class="details-form-field start-date">
            <fa-duotone-icon [icon]="['fad', 'calendar-day']"></fa-duotone-icon>
            <mat-form-field appearance="fill" class="date-picker">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="pickerStartDate" formControlName="startDate" autocomplete="off"
                     (focus)="$event.target.select()" (mousedown)="pickerStartDate.open()" />
              <mat-datepicker #pickerStartDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="details-form-field metric-unit">
            <mat-form-field appearance="fill" class="mat-form-field-disabled" >
              <mat-label>Metric Unit</mat-label>
              <input matInput disabled [value]="currentState?.metricUnit"/>
            </mat-form-field>
          </div>
        </div>
        <div class="details-sections" *ngIf="currentState?.milestones">
          <fa-duotone-icon [icon]="['fad', 'pennant']"></fa-duotone-icon>
          <metric-milestones-list
            #milestonesControl
            [readOnly]="isReadOnlyMode"
            [milestonesList]="currentState?.milestones"
            [displayDecimal]="displayDecimal"
            [originObjectId]="currentState?.parentId"
            (patchMilestonesList)="updateMilestones($event)"
          ></metric-milestones-list>
        </div>

        <div class="details-sections" *ngIf="currentState">
          <fa-duotone-icon slot="icon" [icon]="['fad', 'heart']"></fa-duotone-icon>
          <div>
            <mat-checkbox
              class="checkbox-wrap"
              color="primary"
              [checked]="currentState.isKeyMetric"
              (change)="toggleKeyMetric($event.checked)">
              Key Metric
            </mat-checkbox>
            <p class="checkbox-hint" *ngIf="currentKeyMetricName && !currentState.isKeyMetric">Current Key: {{ currentKeyMetricName }}</p>
          </div>
          <fa-duotone-icon
            [icon]="['fad', 'graduation-cap']"
            class="primary"
            [matTooltip]="keyMetricTooltip"
            matTooltipPosition="right"
            matTooltipClass="dark-theme-tooltip right multi-line text-left"
          ></fa-duotone-icon>
        </div>

        <div class="details-sections">
          <div class="details-form-field w-100">
            <fa-duotone-icon [icon]="['fad', 'clipboard']"></fa-duotone-icon>
            <linkified-text formControlName="notes" label="Notes"></linkified-text>
          </div>
          </div>
      </section>
      <section class="right-side">
        <business-value
          [currencySymbol]="company?.currency_symbol"
          [objectType]="parentObjectType"
          [showTarget]="true"
          [actualBusinessValue]="actualBusinessValue"
          [targetBusinessValue]="targetBusinessValue"
        ></business-value>
        <div class="details-sections total-info-section">
          <metric-total-info
            [metricName]="currentState?.metricName"
            [objectType]="parentObjectType"
            [isCEGMode]="budget?.new_campaigns_programs_structure"
            [currencyCode]="company?.currency"
            [revenuePerOutcome]="currentState?.revenuePerOutcome"
            [revenueToProfit]="currentState?.revenueToProfit"
            [totalValue]="currentState?.summary?.totalValue"
            [totalTarget]="currentState?.summary?.targetValue"
            [costPerOutcome]="currentState?.summary?.currentCPO"
            [targetCostPerOutcome]="currentState?.summary?.targetCPO"
            [returnOnInvestment]="currentState?.summary?.currentROI"
            [targetReturnOnInvestment]="currentState?.summary?.targetROI"
            [lastNegativeMetricValueDate]="lastNegativeMetricValueDate"
            [displayDecimal]="displayDecimal"
          ></metric-total-info>
        </div>
        <div class="details-sections">
          <metric-value-editor
            [isReadOnly]="isReadOnlyMode"
            [originUpdateType]="parentObjectType | lowercase"
            [originObjectId]="currentState?.parentId"
            [historyRecords]="currentState?.metricValueUpdates || []"
            [lastUpdatedInfo]="metricValueLastUpdated"
            [displayDecimal]="displayDecimal"
            [budgetTodayDate]="budgetTodayDate"
            [todayDateString]="todayDateString"
            [currentMonthKey]="currentMonthKey"
            (addRecord)="addNewValueUpdateItem($event)"
            (updateRecord)="changeValueUpdateItem($event)"
            (removeRecord)="removeValueUpdateItem($event)"
          >
            <integration-mapping-menu
              integration-menu
              [salesforceHandler]="sfIntegrations?.length && currentState?.objectId && openSalesforceMappingModal"
              [hubSpotHandler]="hubspotIntegrations?.length && currentState?.objectId && openHubspotMappingModal"
            ></integration-mapping-menu>
          </metric-value-editor>
        </div>
        <div class="details-sections details-chart-section">
          <metric-progress-section
            [metricName]="currentState?.metricName"
            [lastUpdatedInfo]="metricValueLastUpdated"
            (viewModeChanged)="handleProgressChartModeChanged($event)"
          >
            <metric-target-progress-chart
              chart
              class="details-metric-chart"
              [startDate]="graphData?.startDate"
              [data]="graphData?.metricValueRecords"
              [milestones]="graphData?.milestones"
              [metricName]="currentState?.metricName"
            >
            </metric-target-progress-chart>
            <metric-progress-table
              table
              [metricName]="currentState?.metricName"
              [metricProgressData]="metricProgressTowardsTarget"
              [metricValueRecords]="graphData?.metricValueRecords"
              [displayDecimal]="displayDecimal"
              [outdatedData]="unsavedChangesFlag"
              [budget]="budget"
            >
            </metric-progress-table>
          </metric-progress-section>
        </div>
        <div class="details-sections details-chart-section" *ngIf="!currentState?.revenuePerOutcome">
          <metric-cpo-chart
            [startDate]="graphData?.startDate"
            [data]="graphData?.CPORecords"
            [milestones]="graphData?.milestones"
            [currency]="'$'"
            [targetCPO]="graphData?.targetCPO"
            [lastUpdatedInfo]="metricValueLastUpdated"
            #cpoChartComponent
            class="details-metric-chart"
          >
          </metric-cpo-chart>
        </div>
        <div class="details-sections details-chart-section" *ngIf="currentState?.revenuePerOutcome">
          <metric-roi-chart
            [startDate]="graphData?.startDate"
            [data]="graphData?.ROIRecords"
            [milestones]="graphData?.milestones"
            [targetROI]="graphData?.targetROI"
            [isRevenueToProfitDefined]="!!currentState?.revenueToProfit"
            [lastUpdatedInfo]="metricValueLastUpdated"
            #roiChartComponent
            class="details-metric-chart"
          >
          </metric-roi-chart>
        </div>
        <div class="details-sections">
          <metric-breakdown
            [data]="metricBreakdownData.currentMetric.data"
            [title]="metricBreakdownData.currentMetric.title"
            [displayDecimal]="displayDecimal"
            [currentDataIcon]="metricBreakdownData.currentMetric.icon"
          >
            <div class="metrics-list" *ngIf="metricBreakdownData.childCampaignMetrics?.data?.length">
              <metric-breakdown-list
                [rows]="metricBreakdownData.childCampaignMetrics?.data"
                [nameColumnTitle]="metricBreakdownData.childCampaignMetrics?.title"
                [displayDecimal]="displayDecimal"
                [icon]="metricBreakdownData.childCampaignMetrics?.icon"
                [grandTotal]="!hasActiveThirdPartyAmounts ? metricBreakdownData.grandTotal : null"
                [withGrandTotalTarget]="false"
                (rowClick)="navigateToCampaignMetricDetails($event.mappingId)"
              ></metric-breakdown-list>
            </div>
            <div class="metrics-list" *ngIf="hasActiveThirdPartyAmounts">
              <metric-breakdown-list
                [nameColumnTitle]="metricBreakdownData.integrationsMetric.title"
                [rows]="metricBreakdownData.integrationsMetric.data"
                [icon]="metricBreakdownData.integrationsMetric.icon"
                [iconRotate]="90"
                [displayDecimal]="displayDecimal"
                [grandTotal]="metricBreakdownData.grandTotal"
                [withTarget]="false"
                [withGrandTotalTarget]="false"
                (rowClick)="openMetricIntegrationModalByName($event.integration)"
              ></metric-breakdown-list>
            </div>
          </metric-breakdown>
        </div>
      </section>
    </div>
  </div>
</article>
