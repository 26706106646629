import { SpendingService } from '@spending/services/spending.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ExpensePageSelectionService } from '@spending/services/expense-page-selection.service';
import { Injectable } from '@angular/core';
import { forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SpendingMiniDashSummaryService } from '@spending/services/spending-mini-dash-summary.service';
import { SpendingLocalFiltersService } from '@spending/services/spending-local-filters.service';
import { ExpenseDO } from '@shared/types/expense.interface';

@Injectable()
export abstract class SpendingChildComponent {
  protected destroy$ = new Subject<void>();
  filteredExpenses: any[];

  protected constructor(
    private readonly spendingCommonService: SpendingService,
    private readonly pageSelectionService: ExpensePageSelectionService,
    private readonly localFiltersService: SpendingLocalFiltersService,
    private readonly miniDashSummaryService: SpendingMiniDashSummaryService,
    private readonly pageService,
  ) {
    this.dataLoadingSubscription();
  }

  private dataLoadingSubscription(): void {
    const reloadGrandTotalValues$ = this.spendingCommonService.reloadGrandTotalValues$;
    const sidebarSelection$ = this.spendingCommonService.sidebarSelectionQuery$;
    let lastSidebarSelection: Record<string, string>;
    const reloadTable$ = this.spendingCommonService.reloadTable$;
    const localFilters$ = this.localFiltersService.localFilters$;

    const isEqual = (obj1, obj2) => {
      if ((!obj1 && obj2) || (obj1 && !obj2)) {
        return false;
      }
      const keys1 = Object.keys(obj1 || {});
      const keys2 = Object.keys(obj2 || {});
      return keys1.length === keys2.length && keys1.every(key => obj1[key] === obj2[key]);
    };

    merge(
      reloadTable$,
      reloadGrandTotalValues$,
    ).pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.spendingCommonService.getGrandTotal())
      ).subscribe();

    sidebarSelection$
      .pipe(takeUntil(this.destroy$))
      .subscribe((sidebarSelection) => {
        if (isEqual(lastSidebarSelection, sidebarSelection)) {
          return;
        }
        lastSidebarSelection = sidebarSelection;
        this.localFiltersService.resetPagination();
    });

    merge(
      reloadTable$,
      localFilters$,
    ).pipe(
      takeUntil(this.destroy$),
      switchMap(() => {
        const sidebarSelection = sidebarSelection$.getValue();
        let request$;
        if (!sidebarSelection) {
          this.resetData();
          request$ = of(null);
        } else {
          const params = this.spendingCommonService.getExpenseBasePayload(false);
          if(params['custom_fields']) {
            request$ = this.loadData().pipe(
              tap((data) => { this.filteredExpenses = data }),
              switchMap(() => this.spendingCommonService.getViewedTotal()),
              map((total) => [ this.filteredExpenses, total ])
            )
          }
          else {
            request$ = forkJoin([
              this.loadData(),
              this.spendingCommonService.getViewedTotal()
            ]);
          }
        }
        return request$;
      })
    ).subscribe((response) => {
      // if(param)
      const viewedTotalsData = response?.[1];
      this.localFiltersService.totalRecordsCount = !viewedTotalsData ? null : viewedTotalsData.total_count;
    });

  }

  get sidebarHasSelection(): boolean {
    return !!this.spendingCommonService.sidebarSelection.length;
  }

  public get timeframesList(): BudgetTimeframe[] {
    return this.spendingCommonService.timeframeList;
  }

  public get readOnlyMode(): boolean {
    return this.spendingCommonService.readOnlyMode;
  }

  protected get openedInDrawerId$(): Observable<number> {
    return this.spendingCommonService.openedInDrawerId$;
  }

  protected loadData(): Observable<ExpenseDO[]> {
    return this.pageService?.getExpenseChunk();
  }

  protected resetData(): void {
    this.pageService?.resetData();
  }
}
