import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Configuration } from 'app/app.constants';
import {
  createEmptyRecordData,
  createMonthTotalRecordData,
  getMonthKey,
} from 'app/budget-object-details/components/metrics/metric-value-editor/metric-value-editor.utils';
import { MetricMappingCalculationService } from 'app/budget-object-details/services/metric-mapping-calculation.service';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { MetricMappingDetailsState } from 'app/budget-object-details/types/metric-mapping-details-state.interface';
import { MetricValueUpdateItem, MetricValueUpdateType, MetricValueUpdatesData } from 'app/budget-object-details/types/metric-value-update-item.interface';
import { faNoteSticky } from "@fortawesome/pro-solid-svg-icons";
import { createDateString } from '../../campaign-details/date-operations';
import { CampaignDO } from '@shared/types/campaign.interface';
import { MetricUnit } from 'app/budget-object-details/types/metric-unit.interface';
import { Subject } from 'rxjs';
import { BusinessValueService } from 'app/budget-object-details/components/business-value/business-value.service';
import { MetricMappingGoalUpdateHistoryDO } from '@shared/services/backend/metric.service';


@Component({
  selector: 'metric-details-update-form',
  templateUrl: './metric-details-update-form.component.html',
  styleUrls: ['./metric-details-update-form.component.scss']
})
export class MetricDetailsUpdateFormComponent implements OnInit, OnChanges {

  editChangeInvalue = new Subject<string>();
  @Input() currentMetricState: MetricMappingDetailsState;
  @Input() metricUpdates: MetricValueUpdatesData;
  @Input() isReadOnlyMode: boolean;
  @Input() campaign: CampaignDO;
  @Input() formData;
  @Input() currentState;
  @Input() isMilestonesFormInvalid: boolean;
  @Input() metricGoalUpdateHistory: MetricMappingGoalUpdateHistoryDO[];
  @Output() syncUnsavedMetricChanges = new EventEmitter();
  @Output() syncMetricFormValidity = new EventEmitter();
  @Output() setMetricValueLastUpdatedDate = new EventEmitter();
  newAmountFieldOpt = { prefix: '', precision: 0, decimal: '.', align: 'right', allowNegative: true };
  newUIImplementation: boolean = true;
  maxAlloweDateSelection = new Date();
  @Input() set budgetTodayDate(date: Date) {
    if(date) { this.maxAlloweDateSelection = date }
  }
  get budgetTodayDate() {
    return this.maxAlloweDateSelection;
  }

  expenseIcon: IconProp;
  changeInValue: number;
  runningTotal: number;
  isEditingRow = {};
  currentlyEditingRow: MetricValueUpdateItem;
  newMetricUpdateDate: string;
  public readonly parentObjectType = 'Campaign';
  public displayDecimal = false;
  public metricValueLastUpdated = '';
  protected todayDateString: Date = new Date();
  protected currentMonthKey: string = getMonthKey(JSON.stringify(new Date()));
  public recordOnCreation: MetricValueUpdateItem;
  public currentMonthTotal: MetricValueUpdateItem;
  public datepickerDisabledDates: string[] = [];
  public editorStructuredData: MetricValueUpdatesData = [];
  public editorStructuredDataUpdate: MetricValueUpdatesData = [];
  public MetricValueUpdateType = MetricValueUpdateType;
  nonArrayIcon = faNoteSticky;
  metricUpdateNotesPosition: string;
  selectedMetricNotesInput = undefined;
  selectedMetricNotesInputPrevState = undefined;
  selectedMetricNotesChildIndex = undefined;
  selectedMetricNotesParentIndex = undefined;
  lastNegativeMetricValueDate = '';
  isChangeInValueFocused: boolean = false;
  isTotalFocused: boolean = false;
  public actualBusinessValue: number = null;
  public targetBusinessValue: number = null;
  isInitialLoaded: boolean = false;
  faIconByType = {
    [MetricValueUpdateType.campaign]: ['fad', 'rocket-launch'],
    [MetricValueUpdateType.program]: ['fad', 'briefcase'],
    [MetricValueUpdateType.monthTotal]: ['fad', 'hand-point-right'],
    [MetricValueUpdateType.hubSpot]: ['fab', 'hubspot']
  };
  editValueInputID: string;

  constructor(readonly configuration: Configuration,
    private readonly metricMappingCalculationService: MetricMappingCalculationService,
    private readonly metricMappingDetailsService: MetricMappingDetailsService) { }

  ngOnInit(): void {
    window.addEventListener('click', (event: any) => {
      const excludedContainers = ["notes-textarea", "notes-container", "notes-length-text", "notes-button-container", "floating-notes-panel", "ng-star-inserted", "ng-untouched", "ng-pristine", "ng-valid"];
      const classList = event?.target?.classList;
      classList.value.split(" ").forEach(element => {
        if (!(excludedContainers.findIndex(item => item == element) != -1 && this.selectedMetricNotesInput != undefined)) {
          return this.selectedMetricNotesInput = undefined;
        }
      });
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formData.controls.metricUnit.value === MetricUnit.Quality) {
      this.newAmountFieldOpt.precision = 2;
    }

    let goalMetricUpdateHistoryRecords: MetricMappingGoalUpdateHistoryDO[] = changes?.metricGoalUpdateHistory?.currentValue
    if(this.currentMetricState?.mappingType === this.configuration.OBJECT_TYPES.goal && goalMetricUpdateHistoryRecords) {
      const goalMetricUpdates: MetricValueUpdatesData = goalMetricUpdateHistoryRecords.map(
        m => ({ 
        runningTotal: 0,
        isRemovable: false,
        isEditable: false,
        type: MetricValueUpdateType.campaign,
        changeInValue: m.change_in_value,
        updatedDate: m.upd,
        monthChildItems: [],
        date: m.date
        })
      );
      
      goalMetricUpdates.reduce((acc, curr) => {
        let total = acc + curr.changeInValue;
        curr.runningTotal = total;
        curr.monthChildItems = [];
        return total
        }, 0);

      this.processFlatMetricUpdates(goalMetricUpdates);
      this.isInitialLoaded = true;
      return;
    }

    if (changes && changes.metricUpdates) {
      const change = changes.metricUpdates;
      this.processFlatMetricUpdates(change.currentValue);
      if (change.currentValue.length > 0) {
        this.isInitialLoaded = true;
      }
    }
  }

  private processFlatMetricUpdates(flatMetricUpdates: MetricValueUpdatesData) {
    if (!this.todayDateString || !flatMetricUpdates) {
      return;
    }
    const { endedMonths, disabledDates } = this.processRecordsDates(flatMetricUpdates);
    this.datepickerDisabledDates = disabledDates;
    if (this.isInitialLoaded) {
      this.editorStructuredDataUpdate = this.createStructuredData(flatMetricUpdates, endedMonths).reverse();
    }
    else {
      this.editorStructuredData = this.createStructuredData(flatMetricUpdates, endedMonths).reverse();
    }
    this.editorStructuredDataUpdate.forEach(editorItem => {
      if (this.editorStructuredData.findIndex(item => item.date === editorItem.date) >= 0) {
        this.updateEditorData(editorItem);
      } else {
        this.editorStructuredData.push(editorItem)
        this.editorStructuredData.sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)))
      }
    })
      this.setMetricValueLastUpdated(flatMetricUpdates);
  }

  toggleCanEnterRecord(): boolean {
    return Boolean(this.newMetricUpdateDate && (this.changeInValue || this.runningTotal))
  }

  onFocusChange(inputType) {
    let ele;
    switch (inputType) {
      case 'changeInValue':
        this.isChangeInValueFocused = true;
        setTimeout(() => {
          ele = document.getElementById("changeInValueInput")
          ele.select();
        }, 100);
        break;
      case 'totalValue':
        this.isTotalFocused = true;
        setTimeout(() => {
          ele = document.getElementById("totalValueInput");
          ele.select();
        }, 100);
        break;
    }
  }

  public changeValueUpdateItem(updatedItem: MetricValueUpdateItem): any {
    this.metricMappingCalculationService.changeValueUpdateItem(
      this.currentMetricState,
      updatedItem,
      MetricValueUpdateType.campaign
    ).subscribe(
      (data) => {
        this.removeMonthEditMode();
        this.applyCurrentValueUpdate();
        this.selectedMetricNotesInput = undefined;
        if (!this.isMilestonesFormInvalid) {
          this.syncUnsavedMetricChanges.emit();
        }
        this.syncMetricFormValidity.emit(true);
      }
    );
  }

  public removeValueUpdateItem(itemToDelete: MetricValueUpdateItem, parentIndex, childIndex) {
    this.editorStructuredData[parentIndex].monthChildItems.splice(childIndex, 1);
    this.editorStructuredDataUpdate = JSON.parse(JSON.stringify(this.editorStructuredData));
    this.metricMappingCalculationService.removeValueUpdateItem
      (this.currentMetricState, itemToDelete)
      .subscribe(data => { 
        if (!this.isMilestonesFormInvalid) {
        this.syncUnsavedMetricChanges.emit();
      } 
    })
    this.metricMappingCalculationService.updateRunningTotal(this.currentMetricState.metricValueUpdates);
    this.processFlatMetricUpdates(this.currentMetricState.metricValueUpdates);
    if (!this.isMilestonesFormInvalid) {
      this.syncUnsavedMetricChanges.emit();
    }
    this.syncMetricFormValidity.emit(true);
  }

  private applyCurrentValueUpdate() {
    if (!this.isMilestonesFormInvalid) {
      this.syncUnsavedMetricChanges.emit();
    }
    this.syncMetricFormValidity.emit(true);
  }

  createOwnCalculations() {
    this.metricMappingCalculationService.createOwnCalculations(
      this.currentMetricState,
      this.getCurrentSpent(),
      MetricValueUpdateType.campaign
    )
  }

  private calcBusinessValue(value: number): number {
    return this.currentMetricState.isKeyMetric ?
      BusinessValueService.calcBusinessValue(
        value,
        this.currentMetricState.revenuePerOutcome,
        this.currentMetricState.revenueToProfit
      ) :
      null;
  }

  updateCurrentSummary() {
    this.metricMappingDetailsService.updateCurrentSummary(this.currentMetricState);
  }

  private setMetricValueLastUpdated(calculations) {
    // Early exit condition for the Last metric date for Goal Update History Tab
    if(this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.goal && this.isInitialLoaded){
      return;
    }
    const calculationsForUpdatedDate = calculations.map(item => {
      const newItem = { ...item };
      newItem.upd = item.updatedDate;
      return newItem;
    });
    this.metricValueLastUpdated =
      this.metricMappingDetailsService.getLastUpdatedDateInfo(calculationsForUpdatedDate, true);      
      this.setMetricValueLastUpdatedDate.emit(this.metricValueLastUpdated)
  }

  private setLastNegativeMetricValueDate() {
    this.lastNegativeMetricValueDate =
      this.metricMappingDetailsService.getLastNegativeMetricValueDate(this.currentMetricState.metricCalculations);
  }

  enterNewRecord(): void {
    if (this.toggleCanEnterRecord()) {
      this.removeMonthEditMode();
      const emptyRecordData: MetricValueUpdateItem = createEmptyRecordData(this.currentMetricState.mappingType);
      emptyRecordData.date = this.newMetricUpdateDate;
      emptyRecordData.changeInValue = this.changeInValue;
      emptyRecordData.runningTotal = this.runningTotal;
      this.metricMappingCalculationService.addNewValueUpdateItem(
        this.currentMetricState,
        MetricValueUpdateType.campaign,
        emptyRecordData.date,
        this.getCurrentSpent(),
        emptyRecordData.changeInValue,
        emptyRecordData.runningTotal,
        emptyRecordData.notes
      ).subscribe(
        (data) => {
          this.runningTotal = 0;
          this.changeInValue = 0;
          this.isTotalFocused = false;
          this.isChangeInValueFocused = false;
          this.newMetricUpdateDate = undefined;
          this.applyCurrentValueUpdate();

        }
      );
    }
  }

  private updateEditorData(editorItem): void {
    this.editorStructuredData[this.editorStructuredData.findIndex(item => item.date === editorItem.date)].monthChildItems = JSON.parse(JSON.stringify(editorItem.monthChildItems))
    this.editorStructuredData[this.editorStructuredData.findIndex(item => item.date === editorItem.date)].changeInValue = editorItem.changeInValue;
    this.editorStructuredData[this.editorStructuredData.findIndex(item => item.date === editorItem.date)].runningTotal = editorItem.runningTotal;
    this.editorStructuredData[this.editorStructuredData.findIndex(item => item.date === editorItem.date)].updatedDate = editorItem.updatedDate ? createDateString(new Date(editorItem.updatedDate)) : null;
    
  }

  private getCurrentSpent(): number {
    return (this.campaign?.status_totals?.committed + this.campaign?.status_totals?.closed) || 0;
  }

  saveUpdateMetricNotes(): void {
    this.currentMetricState.metricCalculations[this.currentMetricState.metricCalculations.findIndex(item => item.date === this.selectedMetricNotesInput.date)].notes = this.selectedMetricNotesInput.notes;
    this.currentMetricState.metricValueUpdates[this.currentMetricState.metricCalculations.findIndex(item => item.date === this.selectedMetricNotesInput.date)].notes = this.selectedMetricNotesInput.notes;
    if (!this.isMilestonesFormInvalid) {
      this.syncUnsavedMetricChanges.emit();
    }
    this.syncMetricFormValidity.emit(true);
    // this.changeValueUpdateItem(this.selectedMetricNotesInput);
  }

  deleteMetricRecord(event,
    childMetricItem,
    parentIndex,
    childIndex) {
    event.stopPropagation();
    this.removeValueUpdateItem(childMetricItem,
      parentIndex,
      childIndex);
  }

  closeNotesWitoutSaving() {
    this.editorStructuredData[this.selectedMetricNotesParentIndex].monthChildItems[this.selectedMetricNotesChildIndex].notes = this.selectedMetricNotesInputPrevState.notes;
    this.editorStructuredDataUpdate = JSON.parse(JSON.stringify(this.editorStructuredData));
    if (this.currentMetricState.metricCalculations.findIndex(item => item.id === this.selectedMetricNotesInput.id) >= 0) {
      this.currentMetricState.metricCalculations[this.currentMetricState.metricCalculations.findIndex(item => item.id === this.selectedMetricNotesInput.id)].notes = this.selectedMetricNotesInputPrevState.notes;
      this.currentMetricState.metricValueUpdates[this.currentMetricState.metricValueUpdates.findIndex(item => item.id === this.selectedMetricNotesInput.id)].notes = this.selectedMetricNotesInputPrevState.notes;
    }
    this.selectedMetricNotesInputPrevState = undefined;
    this.selectedMetricNotesInput = undefined;
    this.selectedMetricNotesParentIndex = undefined;
    this.selectedMetricNotesChildIndex = undefined;
  }

  toggleNotesInput(event, childMetricItem, parentIndex, childIndex) {
    event.stopPropagation();
    this.selectedMetricNotesInputPrevState = { ...childMetricItem };
    this.selectedMetricNotesInput = childMetricItem;
    this.selectedMetricNotesParentIndex = parentIndex;
    this.selectedMetricNotesChildIndex = childIndex;
    this.metricUpdateNotesPosition = event.y + 150 > window.innerHeight ? `calc(${event.y}px - 170px)` : `${event.y}px`;
  }

  checkFaExpenseIcon(iconType) {
    const availableFaIcons = Object.keys(this.faIconByType);
    const index = availableFaIcons.findIndex(iconName => iconName === iconType)
    if (index >= 0) {
      return true;
    }
    return false;
  }

  getExpenseIcon(iconType) {
    return this.faIconByType[iconType];
  }

  isRecordEditable(childMetricItem): boolean {
    return !this.isReadOnlyMode && childMetricItem.isEditable
  }

  toggleMonthEditMode(event, childMetricItem: MetricValueUpdateItem, parentIndex, childIndex) {
    event.stopPropagation();
    const nonEditableItems =
      childMetricItem.type.toLowerCase() != MetricValueUpdateType.salesForce.toLowerCase() &&
      childMetricItem.type.toLowerCase() != MetricValueUpdateType.facebookAds.toLowerCase() &&
      childMetricItem.type.toLowerCase() != MetricValueUpdateType.googleAds.toLowerCase() &&
      childMetricItem.type.toLowerCase() != MetricValueUpdateType.linkedinAds.toLowerCase()
    if (this.isRecordEditable(childMetricItem) && nonEditableItems) {
      this.isEditingRow = {};
      this.currentlyEditingRow = childMetricItem;
      this.isEditingRow[parentIndex] = childIndex;
      let changeInValueInput;
      setTimeout(() => {
        if (this.editValueInputID) {
          changeInValueInput = document.getElementById(this.editValueInputID);
        } else {
          changeInValueInput = document.getElementById('change-in-value-edit-input');
        }
        changeInValueInput.select();
      }, 100);
    }
  }

  onValueClicked(tagValue) {
    this.editValueInputID = tagValue;
  }

  onValueEditInputClick($event) {
    $event.stopPropagation();
  }

  removeMonthEditMode() {
    this.isEditingRow = {};
  }

  private createStructuredData(records: MetricValueUpdatesData, endedMonths): MetricValueUpdatesData {
    const structuredData = [];

    records.forEach((record, index) => {
      const { runningTotal, date } = record,
        isLastItem = index === records.length - 1,
        monthKey = getMonthKey(date);
      if (this.currentMonthKey !== monthKey) {
        const monthItems = endedMonths[monthKey];
        const switchMonth = isLastItem || monthKey !== getMonthKey(records[index + 1].date);
        monthItems.push({ ...record })
        if (switchMonth) {
          const monthChangeInValue = this.calcMonthChangeInValue(monthItems);
          const monthTotal = {
            ...createMonthTotalRecordData(monthChangeInValue, runningTotal, monthKey),
            updatedDate: MetricMappingDetailsService.getLastUpdatedDate(monthItems, MetricValueUpdateType.campaign, this.currentMetricState.parentId),
            monthChildItems: [...monthItems].reverse() // show cumulation in desc order
          }
          if(this.currentMetricState.mappingType === this.configuration.OBJECT_TYPES.goal) {
            monthTotal.updatedDate = this.getLastUpdatedDateForGoalMetricsUpdateHistory(monthTotal.monthChildItems);
          }
          structuredData.push(monthTotal);
        }
      } else {
        structuredData.push({ ...record });
      }
    })
    return structuredData;
  }

  private getLastUpdatedDateForGoalMetricsUpdateHistory(monthChildItems: MetricValueUpdatesData ) {
    const lastUpdatedDate = monthChildItems.reduce((acc, curr) => {
      if (curr.updatedDate && new Date(curr.updatedDate) > new Date(acc)) {
        return curr.updatedDate;
      }
      return acc;
    }, new Date(0).toDateString());
    return createDateString(new Date(lastUpdatedDate));
  }

  private processRecordsDates(records: MetricValueUpdatesData) {
    const endedMonths = {}, disabledDates = [];
    records.forEach((item) => {
      const monthKey = getMonthKey(item.date);
      endedMonths[monthKey] = [];
      if (MetricMappingCalculationService.doesUpdateItemMatchOriginObject(
        item,
        { id: this.currentMetricState.parentId, updateType: MetricValueUpdateType.campaign })
      ) {
        disabledDates.push(item.date);
      }
    })
    return { endedMonths, disabledDates }
  }

  private calcMonthChangeInValue(monthItems: MetricValueUpdatesData) {
    if (!monthItems.length) {
      return 0;
    }
    const firstChildChangeIn = monthItems[0].changeInValue || 0;
    if (monthItems.length === 1) {
      return firstChildChangeIn;
    } else {
      const lastIndex = monthItems.length - 1;
      return firstChildChangeIn + (monthItems[lastIndex].runningTotal - monthItems[0].runningTotal)
    }
  }

  setChangeInValue(ev) {
    this.changeInValue = ev;
  }

  setRunningTotalValue(ev) {
    this.runningTotal = ev;
  }

  onHandleStartDateChange(event: Date) {
    if (event instanceof Date || event == null) {
      this.newMetricUpdateDate = createDateString(event);
    }
  }

  onHandleRecordDateChange() { }

  onRecordChangeInValue(childMetricItem) {
    setTimeout(() => {
      this.changeValueUpdateItem(childMetricItem);
      if (!this.isMilestonesFormInvalid) {
        this.syncUnsavedMetricChanges.emit();
      }
    }, 100);
  }

  onRecordChangeRunningTotal(childMetricItem) {
    setTimeout(() => {
      this.changeValueUpdateItem(childMetricItem);
      if (!this.isMilestonesFormInvalid) {
        this.syncUnsavedMetricChanges.emit();
      }
    }, 100);
  }
}
