import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CompanyDO } from 'app/shared/types/company.interface';
import { CompanyService, UpdateCompaniesResponse } from 'app/shared/services/backend/company.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { SalesforceDataService } from 'app/metric-integrations/salesforce/salesforce-data.service';
import { MetricIntegrationDisplayName, MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { HubspotDataService } from 'app/metric-integrations/hubspot/hubspot-data.service';
import { DisableCompaniesIntegrationResponse } from 'app/metric-integrations/types/metrics-provider-data-service.types';

@Injectable({
  providedIn: 'root'
})
export class ManageCompanyService {
  private errorMessages = {
    FAILED_TO_UPDATE_COMPANIES: 'Failed to update companies',
    FAILED_TO_UPDATE_SOME_COMPANIES: 'Failed to update some of the companies',
  };
  private errorMessageGetters = {
    FAILED_TO_DISABLE_METRIC_INTEGRATION: (integrationName) => `Failed to disable ${integrationName} for some of the companies`,
  };

  private integrationDataService = {
    [MetricIntegrationName.Salesforce]: this.salesforceService,
    [MetricIntegrationName.Hubspot]: this.hubspotService,
  };

  constructor(
    public _utilityService: UtilityService,
    private companyDataService: CompanyDataService,
    private readonly companyService: CompanyService,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly salesforceService: SalesforceDataService,
    private readonly hubspotService: HubspotDataService,
  ) {}

  private patchDataItem(updatedItem: CompanyDO, payload: Partial<CompanyDO>, data: CompanyDO[]) {
    const target = data.find(company => company.id === updatedItem.id);
    if (target) {
      Object.keys(payload).forEach(key => {
        target[key] = updatedItem[key];
      });
    }
  }

  private processMultiUpdate(result: UpdateCompaniesResponse, payload: Partial<CompanyDO>, data: CompanyDO[]) {
    const { success = [], error = [] } = result;
    if (error.length) {
      const errorIds = error.map(item => item.id);
      this._utilityService.handleError(new Error(this.errorMessages.FAILED_TO_UPDATE_SOME_COMPANIES));
      console.error(`Failed to update companies: [ ${errorIds.join(',')} ]`);
    }

    success.forEach(updatedItem => { this.patchDataItem(updatedItem, payload, data); });
  }

  public requestMultiUpdate(ids: number[], payload: Partial<CompanyDO>, data: CompanyDO[]) {
    return this.companyService.updateCompanies({ ids, ...payload })
      .pipe(
        tap(result => {
          this.processMultiUpdate(result, payload, data);
        }),
        catchError((err) => {
          this._utilityService.handleError(new Error(this.errorMessages.FAILED_TO_UPDATE_COMPANIES));
          return of(err.message);
        })
      );
  }

  public disableMetricIntegration(selectedIds: number[], data: CompanyDO[], integrationSource: MetricIntegrationName) {
    const integrationLabel = MetricIntegrationDisplayName[integrationSource];
    const dialogTitle = `Disable ${integrationLabel} Integration`;
    const dialogContent = `If you disable your ${integrationLabel} integration then you will remove your saved
       ${integrationLabel} credentials and stop synchronizing metrics with ${integrationLabel}.
       Current metric values will remain unchanged.
       <br><br>
       Are you sure that you want to continue?`;

    const integrationDataService = this.integrationDataService[integrationSource];
    if (!integrationDataService) {
      return;
    }

    return new Observable((subscriber) => {
      this.dialogManager.openConfirmationDialog({
        submitAction: {
          label: 'Yes',
          handler: () => {
            const payload = { [integrationSource]: false };
            this._utilityService.showLoading(true);
            integrationDataService.disableCompanies(selectedIds)
              .pipe(
                switchMap((res: DisableCompaniesIntegrationResponse) => {
                  const { error = [] } = res;
                  if (error.length) {
                    this._utilityService.handleError(new Error(
                      this.errorMessageGetters.FAILED_TO_DISABLE_METRIC_INTEGRATION(integrationLabel)
                    ));
                    console.error(`Failed to disable ${integrationLabel} companies: [ ${error.join(',')} ]`);
                  }

                  return !error.length ?
                    this.requestMultiUpdate(selectedIds, payload, data) :
                    of(null);
                })
              )
              .subscribe(subscriber)
          },
        },
        cancelAction: {
          handler: () => subscriber.complete()
        },
        content: dialogContent,
        title: dialogTitle,
      });
    });
  }

  public confirmDelete(ids: number[], data: CompanyDO[], okHandler: () => Observable<any>): Observable<void> {
    let dialogContent = `Are you sure you want to delete ${ids.length} companies?`;
    if (ids.length === 1) {
      const selectedCompany = data.find(companyDo => companyDo.id === ids[0]);
      dialogContent = `Are you sure you want to delete the company <b>${selectedCompany.name}</b>?`;
    }

    return new Observable((subscriber) => {
      this.dialogManager.openConfirmationDialog({
        noTitle: true,
        submitAction: {
          label: 'Yes',
          handler: () => {
            okHandler().subscribe(subscriber);
          },
        },
        cancelAction: {
          handler: () => subscriber.complete()
        },
        content: dialogContent
      });
    });
  }
}
