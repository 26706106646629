import { NgModule } from '@angular/core';
import { FunnelPresentationService } from './funnel-presentation.service';

@NgModule({
  declarations: [],
  providers: [
    FunnelPresentationService
  ]
})
export class FunnelPresentationModule {}
