<article class="details-page-wrapper">
  <details-header
    [actions]="menuActions"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedChangesFlag"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAction.bind(this))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
    [objectHierarchy]="hierarchy">
  </details-header>
  <div class="details-content">
    <div class="details-page-content">
      <section class="left-side" [formGroup]="formData">
        <div class="details-sections">
          <metric-type
            [currentMetricId]="currentState?.metricId"
            [productName]="currentState?.productName"
            [metricName]="currentState?.metricName"
            [masterMetricsSelectItems]="masterMetricsSelectItems"
            [isReadonly]="isReadOnlyMode || hasChildCampaigns"
            (selectMetric)="selectMetric($event)"
          ></metric-type>
        </div>
        <div class="details-sections">
          <div class="details-form-field start-date">
            <fa-duotone-icon [icon]="['fad', 'calendar-day']"></fa-duotone-icon>
            <mat-form-field appearance="fill" class="date-picker">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="pickerStartDate" formControlName="startDate" autocomplete="off"
                     (focus)="$event.target.select()" (mousedown)="pickerStartDate.open()"/>
              <mat-datepicker #pickerStartDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="details-form-field metric-unit">
            <mat-form-field appearance="fill" class="mat-form-field-disabled">
              <mat-label>Metric Unit</mat-label>
              <input matInput disabled [value]="currentState?.metricUnit"/>
            </mat-form-field>
          </div>
        </div>
        <div class="details-sections" *ngIf="currentState?.milestones">
          <fa-duotone-icon [icon]="['fad', 'pennant']"></fa-duotone-icon>
          <metric-milestones-list
            #milestonesControl
            [readOnly]="isReadOnlyMode"
            [milestonesList]="currentState?.milestones"
            [displayDecimal]="displayDecimal"
            [originObjectId]="currentState?.parentId"
            (patchMilestonesList)="updateMilestones($event)"
          ></metric-milestones-list>
        </div>
        <div class="details-sections">
          <div class="details-form-field w-100">
            <fa-duotone-icon [icon]="['fad', 'clipboard']"></fa-duotone-icon>
            <linkified-text formControlName="notes" label="Notes"></linkified-text>
          </div>
        </div>
      </section>
      <section class="right-side">
        <metric-total-info
          [metricName]="currentState?.metricName"
          [objectType]="parentObjectType"
          [isCEGMode]="budget?.new_campaigns_programs_structure"
          [currencyCode]="company?.currency"
          [revenuePerOutcome]="currentState?.revenuePerOutcome"
          [revenueToProfit]="currentState?.revenueToProfit"
          [totalValue]="currentState?.summary?.totalValue"
          [totalTarget]="currentState?.summary?.targetValue"
          [costPerOutcome]="currentState?.summary?.currentCPO"
          [targetCostPerOutcome]="currentState?.summary?.targetCPO"
          [returnOnInvestment]="currentState?.summary?.currentROI"
          [targetReturnOnInvestment]="currentState?.summary?.targetROI"
          [displayDecimal]="displayDecimal"
        ></metric-total-info>
        <div class="details-sections details-chart-section">
          <metric-progress-section [metricName]="currentState?.metricName" [lastUpdatedInfo]="metricValueLastUpdated">
            <metric-target-progress-chart
              chart
              class="details-metric-chart"
              [startDate]="graphData?.startDate"
              [data]="graphData?.metricValueRecords"
              [milestones]="graphData?.milestones"
              [metricName]="currentState?.metricName"
            >
            </metric-target-progress-chart>
            <metric-progress-table
              table
              [metricName]="currentState?.metricName"
              [metricProgressData]="metricProgressTowardsTarget"
              [metricValueRecords]="graphData?.metricValueRecords"
              [displayDecimal]="displayDecimal"
              [outdatedData]="unsavedChangesFlag"
              [budget]="budget"
            >
            </metric-progress-table>
          </metric-progress-section>
        </div>
        <div class="details-sections details-chart-section" *ngIf="!currentState?.revenuePerOutcome">
          <metric-cpo-chart
            [startDate]="graphData?.startDate"
            [data]="graphData?.CPORecords"
            [milestones]="graphData?.milestones"
            [currency]="'$'"
            [targetCPO]="graphData?.targetCPO"
            [lastUpdatedInfo]="metricValueLastUpdated"
            class="details-metric-chart"
          >
          </metric-cpo-chart>
        </div>
        <div class="details-sections details-chart-section" *ngIf="currentState?.revenuePerOutcome">
          <metric-roi-chart
            [startDate]="graphData?.startDate"
            [data]="graphData?.ROIRecords"
            [milestones]="graphData?.milestones"
            [targetROI]="graphData?.targetROI"
            [isRevenueToProfitDefined]="!!currentState?.revenueToProfit"
            [lastUpdatedInfo]="metricValueLastUpdated"
            class="details-metric-chart"
          >
          </metric-roi-chart>
        </div>
        <div class="details-sections">
          <metric-breakdown [displayDecimal]="displayDecimal">
            <div class="metrics-list">
              <metric-breakdown-list
                [rows]="metricBreakdownData.campaignMetrics.data"
                [nameColumnTitle]="metricBreakdownData.campaignMetrics.title"
                [displayDecimal]="displayDecimal"
                [icon]="metricBreakdownData.campaignMetrics.icon"
                (rowClick)="navigateToCampaignMetricDetails($event.mappingId)"
              ></metric-breakdown-list>
            </div>
          </metric-breakdown>
        </div>
      </section>
    </div>
  </div>
</article>
