<page-header pageName="My Profile" [isAdminPage]="true">
  <fa-icon slot="icon" [icon]="['fas', 'user']"></fa-icon>
</page-header>

<form #formElem="ngForm" novalidate (ngSubmit)="handleSubmitForm()">
  <mat-form-field *ngIf="isChangePasswordMode" appearance="outline" class="p-form-field-outline">
    <mat-label>Old Password</mat-label>
    <input matInput
           autocomplete="off"
           type="password"
           name="oldPassword"
           [(ngModel)]="form.oldPassword"
           #oldPassword="ngModel"
           required/>
    <mat-error *ngIf="oldPassword.errors && oldPassword.errors.required">{{ validations.ValiditionMessages.INVALID_PASSWORD }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="p-form-field-outline two-line-hint">
    <mat-label>New Password</mat-label>
    <input matInput
           autocomplete="off"
           type="password"
           name="newPassword"
           [(ngModel)]="form.newPassword"
           #newPassword="ngModel"
           required minlength="8"
           [pattern]="passwordPattern" />
    <mat-hint align="start">{{ validations.ValiditionMessages.PASSWORD_PATTERN }}</mat-hint>
    <mat-error *ngIf="newPassword.errors && newPassword.errors.required">{{ validations.ValiditionMessages.INVALID_PASSWORD }}</mat-error>
    <mat-error *ngIf="newPassword.errors && (newPassword.errors.pattern || newPassword.errors.minlength)">{{ validations.ValiditionMessages.PASSWORD_PATTERN }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="p-form-field-outline">
    <mat-label>Confirm New Password</mat-label>
    <input matInput
           autocomplete="off"
           type="password"
           name="confirmNewPassword"
           [(ngModel)]="form.confirmNewPassword"
           #confirmNewPassword="ngModel"
           validateEqual="newPassword"
           required
           minlength="8"
           [pattern]="passwordPattern" />
    <mat-error *ngIf="confirmNewPassword.errors && confirmNewPassword.errors.required">{{ validations.ValiditionMessages.INVALID_PASSWORD }}</mat-error>
    <mat-error *ngIf="confirmNewPassword.errors && !confirmNewPassword.errors.validateEqual">{{ validations.ValiditionMessages.PASSWORD_NOT_MATCH }}</mat-error>
  </mat-form-field>

  <div class="double-buttons-box">
    <button mat-stroked-button
            color="primary"
            type="button"
            *ngIf="isChangePasswordMode"
            (click)="handleCancelClick($event)">Cancel</button>
    <button mat-flat-button
            color="primary"
            type="submit"
            [disabled]="formElem.invalid">Save</button>
  </div>
</form>
