<div class="icon" *ngIf="icon">
  <fa-icon [fixedWidth]="true" [icon]="icon"></fa-icon>
</div>
<div>
  <h3 class="title">{{ title }}</h3>
  <div class="values">
    <ng-container *ngIf="!label; else withLabelTemplate">
      <span class="value-main">{{ value != null ? value : 'N/A' }}</span>
      <span class="value-secondary" *ngIf="valueSecondary != null">/{{ valueSecondary }}</span>
    </ng-container>
  </div>
</div>


<ng-template #withLabelTemplate>
  <span class="value-secondary mr-05">{{ label }}</span>
  <span class="value-main">
    {{ value | absoluteNumber }}
  </span>
</ng-template>

