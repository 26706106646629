import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Output, ViewChild, EventEmitter, inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { HttpStatusCode } from '@angular/common/http';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';

@Component({
  selector: 'object-ceg-name-input',
  templateUrl: './object-ceg-name-input.component.html',
  styleUrls: ['./object-ceg-name-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectCegNameInputComponent implements AfterViewInit, OnDestroy {
  @Input() objectType: string;
  @Input() icon: IconProp;
  @ViewChild('inputRef') inputRef: ElementRef;
  @Output() nameChanged = new EventEmitter<string>();

  private readonly managePageService = inject(ManageCegPageService);
  private readonly configuration = inject(Configuration);
  private readonly cdr = inject(ChangeDetectorRef);
  private destroy$ = new Subject<void>();
  private errorMessageByType = {
    unique: 'This name is already used',
  };

  public maxNameLength = this.configuration.MAX_TEXT_INPUT_LENGTH;
  public name = '';
  public validating = false;
  public placeholder = 'Enter name';
  public errorMessage: string = null;
  public isHovered: boolean;

  ngAfterViewInit(): void {
    this.inputRef.nativeElement.focus();
  }

  public onBlur(): void {
    this.validating = true;
    if (this.name === '') {
      this.nameChanged.emit(this.name);
      return;
    }
    this.managePageService.validateUniqueObjectName(this.objectType, this.name).pipe(
      catchError(err => {
        if (Number(err.status) === HttpStatusCode.BadRequest) {
          // error with 400 means name is unique
          this.nameChanged.emit(this.name);
        } else {
          this.validating = false;
        }
        return throwError(err);
      }),
      takeUntil(this.destroy$)
    ).subscribe(() => {
        this.validating = false;
        this.errorMessage = this.errorMessageByType.unique;
        this.cdr.markForCheck();
      }
    );
  }

  public onFocus(): void {
    this.errorMessage = null;
  }

  public handleMouseEnter(): void {
    this.isHovered = true;
  }

  public handleMouseLeave(): void {
    this.isHovered = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
