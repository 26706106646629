import { Pipe, PipeTransform } from '@angular/core';
import { ExternalCampaign } from '../types/external-campaign.interface';

@Pipe({
  name: 'externalCampaignsFilter'
})
export class ExternalCampaignsFilterPipe implements PipeTransform {

  transform(campaigns: ExternalCampaign[], filterText: string): ExternalCampaign[] {
    if (!campaigns) {
      return [];
    }
    if (!filterText) {
      return campaigns;
    }

    return campaigns.filter(campaign =>
      this.campaignHierarchyContainsFilterText(campaign, filterText)
    );
  }

  private campaignHierarchyContainsFilterText(campaign: ExternalCampaign, filterText): boolean {
    filterText = filterText.toLocaleLowerCase();
    const hasFilterTerm = ExternalCampaignsFilterPipe.campaignContainsFilterTerm(campaign, filterText);
    if (!hasFilterTerm) {
      return !!campaign.children &&
        campaign.children.some(childCampaign => this.campaignHierarchyContainsFilterText(childCampaign, filterText))
    }
    return hasFilterTerm;
  }

  public static campaignContainsFilterTerm(campaign: ExternalCampaign, filterTerm: string): boolean {
    const includesFields = ['name', 'type', 'metricName', 'description', 'status', 'owner'];
    return includesFields.some(fieldName => {
      const campaignValue = campaign[fieldName] as string;
      return campaignValue && campaignValue.toLowerCase().includes(filterTerm)
    })
  }

}
