<ng-template let-record let-nested="nested" #recordTemplate>
  <div class="body-row" [class.nested]="nested">
    <div class="body-cell name-cell">
      <object-link
        [objectType]="record.objectType"
        [objectName]="record.name"
        [objectId]="record.mappingId"
        [actionHandler]="objectLinkHandler.bind(this, record)"
        [isClosed]="record.closed"
      ></object-link>
    </div>
    <div class="body-cell edit-cell">
      <amount-control
        [value]="record.current"
        [currency]="isDecimal ? currencySymbol : null"
        [disabled]="isReadOnly"
        (onChange)="handleCurrentValueChange($event, record)"
        [isDecimal]="isDecimal"
        [allowNegative]="true"
      ></amount-control>
      <span
        *ngIf="record.withIntegrations"
        [matTooltip]="maskTooltip"
        matTooltipPosition="above"
        matTooltipClass="dark-theme-tooltip above"
        class="mask-icon"
      >
        <fa-icon [icon]="['fas', 'mask']"></fa-icon>
      </span>
    </div>
    <div class="body-cell values-cell">
      <total-target-values
        [total]="record.total | number: decimalPipeFormat"
        [target]="record.target ? (record.target | number: decimalPipeFormat) : 'N/A'"
      >
      </total-target-values>
    </div>
  </div>

  <div class="body-nested-group">
    <ng-container
      *ngFor="let nestedRecord of record.objects | keyvalue"
      [ngTemplateOutlet]="recordTemplate"
      [ngTemplateOutletContext]="{ $implicit: nestedRecord.value, nested: true }"
    >
    </ng-container>
  </div>
</ng-template>

<div class="update-metrics-panel">
  <mat-accordion>
    <mat-expansion-panel (opened)="isExpanded = true" (closed)="isExpanded = false" hideToggle [expanded]="isExpanded">
      <mat-expansion-panel-header [expandedHeight]="panelHeaderHeight" [collapsedHeight]="panelHeaderHeight">
        <div class="header-row">
          <div class="header-cell name-cell">
            <object-link
              [objectName]="data?.name"
              [withIcon]="false"
              [actionHandler]="handleHeaderAction.bind(this)"
              [productName]="data?.productName"
            ></object-link>
          </div>
          <div class="header-cell edit-cell">
            <metric-progress-bar [data]="data?.summary"></metric-progress-bar>
          </div>
          <div class="header-cell values-cell">
            <total-target-values
              [total]="data?.summary?.total | number: decimalPipeFormat"
              [target]="data?.summary?.target | number: decimalPipeFormat"
            >
            </total-target-values>
          </div>
        </div>
        <widget-toggle-button [isActive]="isExpanded"></widget-toggle-button>
      </mat-expansion-panel-header>

      <div class="panel-body">
        <div class="body-group" *ngFor="let record of data?.objects | keyvalue">
          <ng-container
            [ngTemplateOutlet]="recordTemplate"
            [ngTemplateOutletContext]="{ $implicit: record.value }"
          >
          </ng-container>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
