import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import html2canvas from 'html2canvas';

export enum EXPORT_TYPES {
  JSON = 'json',
  XLSX = 'xlsx',
}

export enum REPORT_TYPE {
  CAMPAIGN = 'campaign',
  RECAPTURED_BUDGET = 'recaptured_budget',
  CAMPAIGN_ALLOCATION = 'campaign_allocation',
  REALLOCATION = 'reallocation',
}

export interface ReportParams {
  report_type: string;
  reallocation_from: string;
  reallocation_to: string;
}

export const BLOB_TYPES = {
  [EXPORT_TYPES.JSON]: 'text/json;charset=utf-8',
  [EXPORT_TYPES.XLSX]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

@Injectable()
export class ExportDataService {
  static downloadData(data, type, fileName) {
    const dataUrl = type + data.replace(/#/g, '%23');
    this.openDownloadWindow(dataUrl, fileName);
  }

  public static downloadDataAsFile(data: BufferSource | Blob | string, fileName: string, fileType: EXPORT_TYPES) {
    const blobPart = fileType === EXPORT_TYPES.JSON ? JSON.stringify(data, null, 2) : data;
    const blobData = new Blob([blobPart], { type: BLOB_TYPES[fileType] });
    const hrefData = (window.URL || window.webkitURL).createObjectURL(blobData);
    this.openDownloadWindow(hrefData, fileName);
  }

  static openDownloadWindow(hrefData: string, fileName: string) {
    const aTag = window.document.createElement('a');
    aTag.style.display = 'none';
    window.document.body.appendChild(aTag);
    aTag.href = hrefData;
    aTag.target = '_blank';
    aTag.download = fileName;
    aTag.click();
    window.document.body.removeChild(aTag);
  }

  public static getExportedFileName(companyName: string, budgetName: string, label: string): string {
    const sanitizeExportChunkName = (chunk: string) => {
      if (!chunk.length) {
        return '';
      }
      const notAllowedSymbolsRegExp = /[^0-9a-zA-Z_.\-\[\]()\s]/g;

      return chunk
        .replace(/'/g, '')
        .replace(notAllowedSymbolsRegExp, '-')
        .replace(/-+/g, '-')
        .replace(/\s+/g, ' ')
        .trim();
    };

    const exportCompany = sanitizeExportChunkName(companyName);
    const exportBudget = sanitizeExportChunkName(budgetName);
    const exportDate = new Date();
    const exportFormattedDate = exportDate.toISOString().slice(0, 10);

    return `${exportCompany}-${exportBudget}-${label}--${exportFormattedDate}`;
  }

  static downloadCsv(data, fileName) {
    ExportDataService.downloadData(encodeURI(data), 'data:attachment/csv;charset=utf-8,', fileName);
  }

  static downloadWidgetGraphAsImage(widget, imageSize, imageType, imageFileName, titleText, subTitleText,
                                    segmentsText, timeframeText, beforeGraphCaptureHandler, shouldDrawLegend = true) {
    let externalWidget;
    let widgetId;
    let clonedWidgetEl;
    if (typeof widget === 'string') {
      widgetId = widget;
    } else {
      externalWidget = widget;
    }

    if (widgetId) {
      const widgetEl = document.getElementById(widgetId);
      const widgetResolution = {width: 1800, height: 1000};
      clonedWidgetEl = <HTMLElement>widgetEl.cloneNode(true);
      clonedWidgetEl.id = widgetId + 'Clone';
      clonedWidgetEl.style.position = 'absolute';
      clonedWidgetEl.style.top = '200%';
      clonedWidgetEl.style.left = '200%';

      clonedWidgetEl.style.width = widgetResolution.width + 'px';
      clonedWidgetEl.style.height = widgetResolution.height + 'px';
      window.document.body.appendChild(clonedWidgetEl);
    }

    let widgetGraphEl = externalWidget || clonedWidgetEl.querySelector('.widget-content-graph');

    let legendEl = window.document.querySelector('div.statuses-legend');
    let clonedLegendEl = <HTMLElement>legendEl.cloneNode(true);
    clonedLegendEl.style.background = 'transparent';
    clonedLegendEl.style.width = '800px';

    let clonedLegendContainerEl = window.document.createElement('div');
    clonedLegendContainerEl.style.position = 'absolute';
    clonedLegendContainerEl.style.top = '200%';
    clonedLegendContainerEl.style.left = '250%';
    clonedLegendContainerEl.style.transform = 'translateX(-50%) scale(1.5)';
    clonedLegendContainerEl.id = 'statusColorListClone';
    clonedLegendContainerEl.appendChild(clonedLegendEl);
    window.document.body.appendChild(clonedLegendContainerEl);

    if (typeof beforeGraphCaptureHandler === 'function') {
      beforeGraphCaptureHandler(widgetGraphEl);
    }

    return Promise.all([html2canvas(widgetGraphEl, { scale: 1 }), html2canvas(clonedLegendEl, { scale: 1 })]).then(resultCanvases => {
      return ExportDataService.composeImageForWidgetGraph(
        imageSize,
        resultCanvases[0],
        resultCanvases[1],
        titleText,
        subTitleText,
        segmentsText,
        timeframeText,
        shouldDrawLegend,
        ExportDataService.getExportedDateText(),
        '../../../assets/images/planful-logo-colored.svg');
    }).then(composedCanvas => {
      ExportDataService.downloadData(composedCanvas.toDataURL(imageType), '', imageFileName || 'image.png');
    }).catch(error => {
      console.log('Error during image rendering or downloading: ' + JSON.stringify(error));
    }).then(() => {
      if (!externalWidget) {
        window.document.body.removeChild(clonedWidgetEl);
      }
      window.document.body.removeChild(clonedLegendContainerEl);
      clonedWidgetEl = null;
      widgetGraphEl = null;
      clonedLegendContainerEl = null;
      clonedLegendEl = null;
      externalWidget = null;
    });
  }

  static composeImageForWidgetGraph(imageSize, graphCanvas, legendCanvas, titleText, subtitleText, segmentsText, timeFrameText, shouldDrawLegend,
                                    exportedDateText, logoUrl): Promise<HTMLCanvasElement> {
    let result: Promise<HTMLCanvasElement>;

    const paddingVertical = 80;
    const paddingHorizontal = 150;
    const titleFontSize = 48;
    const subtitleFontSize = 40;
    const metadataPaddingLeft = 50;
    const metadataLineHeight = 25;
    const metadataFontSize = 22;
    const logoHeight = 55;
    const logoWidth = 164;
    const scaleFactor = 1;

    let currentTop = paddingVertical;

    const resultCanvas = document.createElement('canvas');
    resultCanvas.width = imageSize.width;
    resultCanvas.height = imageSize.height;
    const resultCtx = resultCanvas.getContext('2d');

    // Fill white background:
    resultCtx.fillStyle = '#FFF';
    resultCtx.fillRect(0,0, resultCanvas.width, resultCanvas.height);

    // Render headers:
    resultCtx.font = 'bold ' + titleFontSize + 'px DM Sans';
    resultCtx.fillStyle = '#000';
    resultCtx.fillText(titleText, paddingHorizontal, currentTop);
    currentTop += titleFontSize + 5;

    resultCtx.font = subtitleFontSize + 'px DM Sans';
    resultCtx.fillText(subtitleText, paddingHorizontal, currentTop);
    currentTop += subtitleFontSize + 20;

    // Render graph and legend graph:
    resultCtx.drawImage(
      graphCanvas,
      Math.max(paddingHorizontal, (resultCanvas.width - graphCanvas.width * scaleFactor) / 2),
      currentTop,
      graphCanvas.width * scaleFactor,
      graphCanvas.height * scaleFactor);

    currentTop += graphCanvas.height * scaleFactor + 40;

    if (shouldDrawLegend) {
      resultCtx.drawImage(
        legendCanvas,
        (resultCanvas.width - legendCanvas.width * scaleFactor) / 2,
        currentTop,
        legendCanvas.width * scaleFactor,
        legendCanvas.height * scaleFactor);
    }

    // Render metadata:
    currentTop = resultCanvas.height - paddingVertical/2;
    resultCtx.font = metadataFontSize + 'px DM Sans';
    resultCtx.fillText(exportedDateText, metadataPaddingLeft, currentTop);

    currentTop -= metadataLineHeight;
    resultCtx.fillText(timeFrameText, metadataPaddingLeft, currentTop);

    currentTop -= metadataLineHeight;
    resultCtx.fillText(segmentsText, metadataPaddingLeft, currentTop);

    if (!logoUrl) {
      // No logo required - ready
      result = Promise.resolve(resultCanvas);
    } else {
      // Logo required - download logo image and then draw it to the result canvas
      result = ExportDataService.loadImage(logoUrl).then(img => {
        resultCtx.drawImage(img, resultCanvas.width - logoWidth - paddingHorizontal, currentTop, logoWidth, logoHeight);
        return resultCanvas;
      });
    }

    return result;
  }

  static loadImage(url): Promise<HTMLImageElement> {
    const logoImg = new Image();
    const resultPromise = new Promise<HTMLImageElement>((resolve, reject) => {
      logoImg.onload = function () {
        resolve(logoImg as HTMLImageElement);
      };
      logoImg.onerror = function () {
        reject('Failed to load the logo for image');
      };
    });
    logoImg.src = url;
    return resultPromise;
  }

  static getExportedDateText() {
    const now = new Date();
    const datePipe = new DatePipe('en-US');
    return 'Exported on ' + datePipe.transform(now, 'longDate') + ' at ' + datePipe.transform(now, 'h:mma')
  }

  static showHiddenTexts(budgetTimelineGraph: Element) {
    const minHeightToShowText = 30;
    const barsWithHiddenText = budgetTimelineGraph.querySelectorAll('.sb-segment-label.sb-segment-label--hidden');
    Array.prototype.forEach.call(barsWithHiddenText, barElLabel => {
      if (barElLabel.parentNode.clientHeight > minHeightToShowText) {
        barElLabel.classList.remove('sb-segment-label--hidden');
      }
    });
  }

  static convertAllSvgsToCanvases(budgetHierarchyGraph) {
    const svgEls = budgetHierarchyGraph.querySelectorAll('svg');
    const horizontalPaddings = 20;
    const verticalPaddings = 25;
    const size = Math.min(budgetHierarchyGraph.clientWidth / svgEls.length - horizontalPaddings, budgetHierarchyGraph.clientHeight / 2 - verticalPaddings);
    Array.prototype.forEach.call(svgEls, (svgEl, index) => {
      ExportDataService.convertSvgToCanvas(svgEl, index === 0, size);
    });
  }

  private static convertSvgToCanvas(svgEl, firstSvg, size) {
    const innerCircleEls = svgEl.querySelectorAll('circle');

    const sectors = Array.prototype.map.call(innerCircleEls, circle => {
      const styles = window.getComputedStyle(circle);
      const strokeDashArrayStyle = styles.getPropertyValue('stroke-dasharray');
      const strokeOpacity = styles.getPropertyValue('opacity');
      const strokeColor = styles.getPropertyValue('stroke');

      return {
        color: strokeColor,
        opacity: parseFloat(strokeOpacity),
        percent: parseFloat(strokeDashArrayStyle.split(',')[0])
      }
    }).filter(sector => sector.percent > 0);

    const drawingPadding = firstSvg ? 20 : 50;
    const canvas = ExportDataService.getPieChartCanvas(size, sectors,  drawingPadding);

    const parentEl = svgEl.parentElement;
    const titleEl = parentEl.querySelector('span.node-span');
    titleEl.style.width = size + 'px';
    parentEl.style.marginLeft = parentEl.style.marginRight = '0';
    if (firstSvg) {
      parentEl.insertBefore(canvas, titleEl.nextSibling);
    } else {
      parentEl.appendChild(canvas);
    }
    parentEl.removeChild(svgEl);
  }

  private static getPieChartCanvas(size, sectors, padding) {
    const canvas = window.document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const constDrawSize = size - padding;
    const ctx = canvas.getContext("2d");
    ctx.translate(padding/2, padding/2);

    let beginAngle = 0;
    let endAngle = 0;

    sectors.forEach(sector => {
      beginAngle = endAngle;
      endAngle = endAngle + sector.percent * 2 * Math.PI / 100;

      ctx.beginPath();
      ctx.fillStyle = sector.color;
      ctx.strokeStyle = sector.color;

      // Same code as before
      ctx.moveTo(constDrawSize/2, constDrawSize/2);
      ctx.arc(constDrawSize/2, constDrawSize/2, constDrawSize/2, beginAngle, endAngle);
      ctx.lineTo(constDrawSize/2, constDrawSize/2);
      ctx.save();
      ctx.globalAlpha = sector.opacity;
      ctx.stroke();
      ctx.fill();
      ctx.restore();
    });
    return canvas;
  }

  static getDataTableForSpendByTypeDownloadableImage(preparedData, transformDataCb, colors, getOpacityCb) {
    const dataTable = [];
    const getSumValues = (spendByTypeItem) => {
      let result = 0;
      result += spendByTypeItem.data.closed || 0;
      result += spendByTypeItem.data.committed || 0;
      result += spendByTypeItem.data.planned || 0;
      result += spendByTypeItem.data.reserved || 0;
      result += spendByTypeItem.data.available || 0;
      return result;
    };
    const maxSumValues = Math.max(...preparedData.map(getSumValues));
    const getAnnotationValue = value => value && maxSumValues > 0 && value/maxSumValues > 0.03 ? transformDataCb(parseFloat(value)) : ' ';

    if (preparedData.length > 0) {
      dataTable.push([
        'Category',
        'Closed',
        {role: 'style'},
        {role: 'annotation'},
        'Committed',
        {role: 'style'},
        {role: 'annotation'},
        'Planned',
        {role: 'style'},
        {role: 'annotation'},
        'Reserved',
        {role: 'style'},
        {role: 'annotation'},
        'Available',
        {role: 'style'},
        {role: 'annotation'},
        {role: 'annotation'}
      ]);

      preparedData.forEach(function(graph: any) {
        const dataRow = [
          graph.name,
          parseFloat(graph.data.closed),
          `stroke-width: 1;stroke-color: ${ colors.closed };${ getOpacityCb('closed') }`,
          getAnnotationValue(graph.data.closed),
          parseFloat(graph.data.committed),
          `stroke-width: 1;stroke-color: ${ colors.committed };${ getOpacityCb('committed') }`,
          getAnnotationValue(graph.data.committed),
          parseFloat(graph.data.planned),
          `stroke-width: 1;stroke-color: ${ colors.planned };${ getOpacityCb('planned') }`,
          getAnnotationValue(graph.data.planned),
          parseFloat(graph.data.reserved),
          `stroke-width: 1;stroke-color: ${ colors.reserved };${ getOpacityCb('reserved') }`,
          getAnnotationValue(graph.data.reserved),
          parseFloat(graph.data.available),
          `stroke-width: 1;stroke-color: ${ colors.available };${ getOpacityCb('available') }`,
          getAnnotationValue(graph.data.available),
          "" ];
        dataTable.push(dataRow);
      });
    }

    return dataTable;
  }
}
