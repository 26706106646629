<div class="control font-monospace"
     (click)="setInputFocus()"
     [ngClass]="{
        'text-s': longNumber
     }">
  <div class="spacer">
    <span class="hidden-text cloned-text">{{ rateControl.value || 0 }}</span>
  </div>
  <input class="input hide-arrows cloned-text ut-conversion-rate"
         type="number"
         #input
         [selectTextOnFocus]="selectOnFocus"
         [readOnly]="!editModeActive"
         [formControl]="rateControl"
         (focus)="onFocus()"
         (blur)="onBlur()"
         (keydown.enter)="input.blur()"
         (ngModelChange)="setLongNumberFlag($event)">
</div>

<svg width="60" height="42" viewBox="0 0 60 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_4302_229)">
    <path id="path-background" d="M2.47423 5.42613C2.22092 3.06189 4.07369 1 6.45147 1H53.5485C55.9263 1 57.7791 3.06189 57.5258 5.42613L55.2565 26.6063C55.0987 28.079 54.14 29.344 52.7648 29.8941L31.4856 38.4058C30.5319 38.7872 29.4681 38.7872 28.5144 38.4058L7.2352 29.8941C5.85999 29.344 4.90132 28.079 4.74353 26.6063L2.47423 5.42613Z" fill="white"/>
    <path *ngIf="!editModeActive || !outlineStyle" d="M6.45147 1.5H53.5485C55.6291 1.5 57.2503 3.30415 57.0286 5.37287L54.7593 26.553C54.6212 27.8417 53.7824 28.9485 52.5791 29.4298L31.2999 37.9415C30.4654 38.2753 29.5346 38.2753 28.7001 37.9415L7.4209 29.4298C6.21759 28.9485 5.37875 27.8417 5.24069 26.553L2.97138 5.37286C2.74973 3.30415 4.37091 1.5 6.45147 1.5Z" stroke="currentColor"/>
    <path *ngIf="editModeActive && outlineStyle === 1" d="M2.97138 5.37286C2.74973 3.30415 4.37091 1.5 6.45147 1.5H53.5485C55.6291 1.5 57.2503 3.30415 57.0286 5.37287L54.7593 26.553C54.6212 27.8417 53.7824 28.9485 52.5791 29.4298L31.2999 37.9415C30.4654 38.2753 29.5346 38.2753 28.7001 37.9415L7.4209 29.4298C6.21759 28.9485 5.37876 27.8417 5.24069 26.553L2.97138 5.37286Z" stroke="#0FB3FF"/>
    <path *ngIf="editModeActive && outlineStyle === 2" d="M3.96569 5.26633C3.80737 3.78868 4.96535 2.5 6.45147 2.5H53.5485C55.0346 2.5 56.1926 3.78868 56.0343 5.26633L53.765 26.4465C53.6664 27.367 53.0672 28.1576 52.2077 28.5014L30.9285 37.0131C30.3324 37.2515 29.6676 37.2515 29.0715 37.0131L7.79229 28.5014C6.93279 28.1576 6.33362 27.367 6.235 26.4465L3.96569 5.26633Z" stroke="#0FB3FF" stroke-width="3"/>
  </g>
  <defs>
    <filter id="filter0_d_4302_229" x="0.451172" y="0" width="59.0977" height="41.6919" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.506979 0 0 0 0 0.6261 0 0 0 0 0.775 0 0 0 0.2 0"/>
      <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_4302_229"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4302_229" result="shape"/>
    </filter>
  </defs>
</svg>
