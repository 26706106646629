import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rounded-box-radio-item',
  templateUrl: './radio-item.component.html',
  styleUrls: ['./radio-item.component.scss']
})
export class RoundedBoxRadioItemComponent {
  @Input() value: string;
  @Input() label: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Output() selectionChange = new EventEmitter<string>();

  onChange() {
    this.checked = !this.checked;
    this.selectionChange.emit(this.value);
  }
}
