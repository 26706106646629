<header mat-dialog-title>{{ data?.title }}</header>
<div mat-dialog-content class="dialog-info">
  <metric-masters-list
    *ngIf="data.metrics$ | async as metrics; else loading"
    [options]="metrics"
    [isPowerUser]="data.isAdmin"
    (click)="$event.stopPropagation();"
    (closeDropdown)="dialogRef.close()"
    (addMetrics)="dialogRef.close($event)"
  ></metric-masters-list>
</div>
<ng-template #loading>
  <loader [isVisible]="true" size="36px"></loader>
</ng-template>
