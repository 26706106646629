import { Injectable } from '@angular/core';
import { MetricProgressState } from '@shared/types/metric-progress-state.type';
import { ObjectDetailsTabControl, AllocationTabData, DetailsTabData, DrawerTabItem, PerformanceTabData } from 'app/budget-object-details/types/object-details-tab-control-type.interface';
import { PerformanceData } from '../../manage-table/types/performance-column-data.type';
import { faPen } from "@fortawesome/pro-solid-svg-icons";


@Injectable()
export class ObjectDetailsTabsDataService {
  private readonly defaultTabList = [
    ObjectDetailsTabControl.Details,
    ObjectDetailsTabControl.Allocation,
    ObjectDetailsTabControl.Performance
  ];
  private readonly tabsData: Record<string, DrawerTabItem> = {
    [ObjectDetailsTabControl.Details]: {
      id: ObjectDetailsTabControl.Details,
      title: 'Details',
      icon: ['fad', 'info-circle'],
      data: null
    },
    [ObjectDetailsTabControl.Allocation]: {
      id: ObjectDetailsTabControl.Allocation,
      title: 'Allocations',
      icon: ['fas', 'sack-dollar'],
      data: null
    },
    [ObjectDetailsTabControl.Performance]: {
      id: ObjectDetailsTabControl.Performance,
      title: 'Performance',
      icon: ['fas', 'chart-line'],
      data: {
        performance: {
          estimatedDiffPercentage: 0,
          progressState: MetricProgressState.BelowTarget
        }
      }
    },
    [ObjectDetailsTabControl.Update]: {
      id: ObjectDetailsTabControl.Update,
      title: 'Update',
      icon: faPen,
      data: null
    },
  }

  public createTabList(tabNames: ObjectDetailsTabControl[] = this.defaultTabList): DrawerTabItem[] {
    return tabNames.reduce((list, tabName) => {
      const tab = this.tabsData[tabName];
      if (tab) {
        list.push(tab);
      } else {
        console.warn('Wrong parameters for "createTabList"');
      }
      return list;
    }, []);
  }

  public static createDetailsTabText(overdueTasksLength: number, updatedDate: string, isKeyMetric: Boolean = false, isNonCampaignMetric: Boolean = true): string {
    return overdueTasksLength ?
      `${overdueTasksLength} task${overdueTasksLength > 1 ? 's' : ''} is overdue` :
      updatedDate ? (isNonCampaignMetric ? `Updated: ${updatedDate}` : isKeyMetric ? 'Key metric' : `Updated: ${updatedDate}`) : '';
  }

  private setTabDetails(tabId: ObjectDetailsTabControl, data: DetailsTabData | AllocationTabData | PerformanceTabData): void {
    this.tabsData[tabId].data = data;
  }

  public setTabIcon(tabId: ObjectDetailsTabControl, icon: any): any {
    this.tabsData[tabId].icon = icon;
  }

  public setTabTitle(tabId: ObjectDetailsTabControl, title: string): any {
    this.tabsData[tabId].title = title;
  }

  public setDetailsData(simpleText: string): void {
    this.setTabDetails(ObjectDetailsTabControl.Details, { simpleText });
  }

  public setUpdateData(simpleText: string): void {
    this.setTabDetails(ObjectDetailsTabControl.Update, { simpleText });
  }

  public setAllocationsData(currency: string, allocated: number, available: number): void {
    const allocationTabData: AllocationTabData = {
      budget: { currency, allocated, available }
    }
    this.setTabDetails(ObjectDetailsTabControl.Allocation, allocationTabData);
  }

  public setPerformanceData(keyMetricPerformanceData: PerformanceData): void {
    const performanceTabData: PerformanceTabData = {
      performance: {
        estimatedDiffPercentage: keyMetricPerformanceData?.estimatedDiffPercentage ?? null,
        progressState: keyMetricPerformanceData?.progressState || null,
      }
    };
    this.setTabDetails(ObjectDetailsTabControl.Performance, performanceTabData);
  }
}
