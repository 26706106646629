import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'title-description-tooltip',
  templateUrl: './title-description-tooltip.component.html',
  styleUrls: ['./title-description-tooltip.component.scss']
})
export class TitleDescriptionTooltipComponent {
  @ContentChild('titleTemplate', { static: false }) titleTemplate: TemplateRef<any>;
  @ContentChild('descriptionTemplate', { static: false }) descriptionTemplate: TemplateRef<any>;
  @Input() title = '';
  @Input() description = '';
  @Input() tooltipContainerClass = 'arrow-down';
}
