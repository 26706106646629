<article class="auth-page login-screen signin">
  <section class="auth-page-content split-view">
    <div class="login-form-container">
      <h1 class="logo">
        <a href="javascript:;" title="Planful" [routerLink]="['/']">
          <img src="assets/images/planful-logo-colored.svg" width="190" alt="logo" />
        </a>
      </h1>
      <form class="auth-page-form login-form" #form="ngForm" novalidate (ngSubmit)="login(form.value, form.valid)">
        <div class="form-field">
          <label class="form-field-label">Email</label>
          <input autocomplete="off" class="form-field-input" type="text" name="email" [(ngModel)]="form.email" #email="ngModel" pattern="^[a-zA-Z0-9._+-]+[a-zA-Z0-9_+-]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$" required  />
          <div class="md-errors-spacer error form-field-error" [hidden]="email.valid || (email.pristine && !form.submitted)">
            <div *ngIf="email.errors && email.errors.required">
              {{ validations.ValiditionMessages.EMAIL_REQUIRED }}
            </div>
            <div *ngIf="email.errors && email.errors.pattern">
              {{ validations.ValiditionMessages.INVALID_EMAIL }}
            </div>
          </div>
        </div>
        <div class="form-field password-form-field">
          <label class="form-field-label">Password</label>
          <input
            autocomplete="off"
            class="form-field-input"
            [type]="isPasswordHidden ? 'password' : 'text'"
            name="password"
            [(ngModel)]="form.password"
            #password="ngModel"
            required
          />
          <button type="button" class="toggle-visibility-btn" (click)="togglePasswordVisibility($event)">
            <fa-icon [icon]="['fas', isPasswordHidden ? 'eye-slash' : 'eye']"></fa-icon>
          </button>
          <div class="md-errors-spacer error form-field-error" [hidden]="password.valid || (password.pristine && !form.submitted)">
            <div *ngIf="password.errors && password.errors.required">
              {{ validations.ValiditionMessages.INVALID_PASSWORD }}
            </div>
          </div>
          <div class="form-links forgot-password">
            <a class="form-links-item" href="javascript:;" [routerLink] = "['/forgot_password']">Forgot password?</a>
          </div>
        </div>
        <div class="form-actions login">
          <button mat-flat-button color="primary" class="full-width" type="submit"  [disabled]="form.invalid">Log In</button>
        </div>
        <div class="text-separator">Or</div>
        <div class="form-actions custom-login">
          <button mat-stroked-button color="primary" [routerLink] = "['/custom_domain']">Use Custom Domain</button>
        </div>
      </form>
    </div>
    <rights-reserved></rights-reserved>
  </section>
  <statement-text></statement-text>
</article>
