<div class="view-container" tabindex="0" [attr.autoFocus]="useAutofocus ? '' : null">
  <div class="selection">
    <div #scrollContainer>
      <div class="selection-container">
        <div class="items-list" *ngIf="showSelectAll && isMultipleSelect">
          <label [class.selected]="allSelected" class="checkmark-all">
            <input  type="checkbox" [(ngModel)]="allSelected">
            <span class="checkmark"></span>
            <span>Select All</span>
          </label>
        </div>
        <div *ngFor="let valueItem of valueItems; let i = index; trackBy: getItemIdentity" #opt (click)="handleSelectValue(valueItem); ">
          <label [attr.title]="valueItem.title" [ngClass]="{
            'is-divider': valueItem.isDivider,
            'is-disabled': valueItem.disabled,
            'is-indented': valueItem.isIndented,
            'is-unselectable': valueItem.isUnselectable,
            'selected': valueItem.isSelected,
            'focused': i === focusItemIndex
          }">
              <input *ngIf="isMultipleSelect" type="checkbox" [(ngModel)]="valueItem.isSelected" (change)="updateSelection(valueItem)" [disabled]="valueItem.disabled">
              <span *ngIf="isMultipleSelect" class="checkmark"></span>
              <span>{{valueItem.title}}</span>
            <icon-lock
              *ngIf="valueItem.disabled"
              class="lock-icon-wrap"
              [textTooltip]="lockedItemTooltip"
              ></icon-lock>
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="isMultipleSelect" class="selection-actions">
      <span (click)="clearSelection(); $event.stopPropagation();" *ngIf="hasSelectedFilterValues()">Clear</span>
      <button [ngClass]="{'disabled': !isApplyButtonAvailable}" (click)="handleApplySelectionChanges();">OK</button>
    </div>
  </div>
</div>
