<div class="grid-component">
  <section class="top-panel">
    <search-control
      #searchControl
      [placeholder]="SearchPlaceHolder"
      (textChanged)="filterGridData($event)"
      [style.width]="searchControlWidth"
    ></search-control>

    <button
      (click)="createNew()"
      [disabled]="disabledCreateButton"
      class="create-new-user"
      mat-flat-button
      color="primary"
      *ngIf="filteredData.length || searchedValue.length"
    >
      <fa-icon class="icon default" [icon]="['fas', 'plus']"></fa-icon>
      {{ AddButtonText }}
    </button>
  </section>
  <section class="grid-section">
    <table class="grid-table" [ngClass]="{ 'no-data-table': !filteredData.length }">
      <thead>
        <tr>
          <th
            *ngFor="let column of gridColumns"
            class="grid-column"
            [style.width]="column.width"
            [class.description-column-title]="column.name === 'description'"
          >
            <span class="columns">
              <fa-icon
                *ngIf="column.name === 'is_enabled'"
                [icon]="['fas', 'info-circle']"
                [matTooltip]="enableSwitchTooltip"
                matTooltipClass="dark-theme-tooltip above multi-line"
                matTooltipPosition="above"
              ></fa-icon>
              <span class="column-title">{{ column.label }}</span>
              <sort-button
                *ngIf="column.sortable"
                [columnName]="column.name"
                [appliedSorting]="appliedSorting"
                (onClick)="sortLabels(column.name)"
              ></sort-button>
            </span>
          </th>
          <th
            class="action-column"
            *ngIf="gridWithAction"
            [style.width]="lastColumnwidth"
          >
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="filteredData.length">
          <tr *ngFor="let row of filteredData">

              <td
              *ngFor="let column of gridColumns"
              class="grid-column"
              [style.width]="column.width"
            >
              <span *ngIf="!hasDescriptionColumn">
                <span *ngIf="column.name === 'name'">
                  <editable-text
                    *ngIf="!customActions || row[customActionLabel]"
                    [existingNames]="existingNames"
                    (valueChanged)="handleValueChange($event, row, column.name)"
                    [value]="row[column.name]"
                    [isEditing]="!row.id"
                    [inputId]="'name'"
                    [valueId]="'name' + '-' + row.id"
                    [validationErrorMessage]="
                      config.expenseTypesDescriptions.error
                    "
                    (validationError)="handleValidationError($event)"
                    [maxLength]="editableMaxLength"
                    [isNameCaseSensitive]="isNameCaseSensitive"
                  >
                  </editable-text>
                  <span
                    *ngIf="customActions && !row[customActionLabel]"
                    class="column-title"
                    >{{ row[column.name] }}</span
                  >
                </span>
                <span
                  *ngIf="column.name !== 'is_enabled' && column.name !== 'name'"
                  class="column-title" [ngClass]="{'count': column.name === 'usage_count'}">
                  <ng-container *ngIf="actionOnCount && column.name === 'usage_count' && row[column.name] ; else displayValue">
                    <span class="clickable" (click)="handleActionOnCount(row)">
                      {{ row[column.name] }}
                    </span>
                  </ng-container>
                  <ng-template #displayValue>
                    {{ row[column.name] }}
                  </ng-template>                  
                </span>

              </span>
              <span *ngIf="hasDescriptionColumn">
                <editable-text
                  *ngIf="column.name === 'name'"
                  [hasDescriptionColumn]="hasDescriptionColumn"
                  [maxLength]="30"
                  [existingNames]="existingNames"
                  (valueChanged)="handleValueChange($event, row, column.name)"
                  [value]="row[column.name]"
                  [isEditing]="!row.id"
                  [inputId]="'name'"
                  [valueId]="'name' + '-' + row.id"
                  [validationErrorMessage]="config.glCodesDescriptions.nameError"
                  (validationError)="handleValidationError($event, row)"
                >
                </editable-text>
                <editable-text
                  *ngIf="column.name === 'description'"
                  [hasDescriptionColumn]="hasDescriptionColumn"
                  (valueChanged)="handleValueChange($event, row, column.name)"
                  [value]="row[column.name]"
                  [isEditing]="!row.id"
                  [inputId]="'description'"
                  [forceValidation]="forceValidation"
                  [valueId]="'description' + '-' + row.id"
                  [validationErrorMessage]="
                    config.glCodesDescriptions.descriptionError
                  "
                  (validationError)="handleValidationError($event, row)"
                >
                </editable-text>
                <span
                  *ngIf="
                    column.name !== 'is_enabled' &&
                    column.name !== 'name' &&
                    column.name !== 'description'
                  "
                  class="column-title"
                  >{{ row[column.name] }}
                </span>
              </span>
              <mat-slide-toggle
                [class.disable-toggle]="row.id === null"
                *ngIf="
                  column.name === 'is_enabled' &&
                  (!customActions || row[customActionLabel])
                "
                [checked]="row.is_enabled"
                (change)="toggleEnabled($event, row)"
                labelPosition="before"
                disableRipple="true"
              >
              </mat-slide-toggle>
              <span
                class="enable-text"
                *ngIf="
                  column.name === 'is_enabled' &&
                  customActions &&
                  !row[customActionLabel]
                "
                >Always Enabled</span
              >
            </td>
            <td
              class="action-column grid-column"
              *ngIf="gridWithAction"
              [style.width]="lastColumnwidth"
            >
              <button
                mat-icon-button
                (click)="deleteUser(row)"
                *ngIf="!customActions || row[customActionLabel]"
              >
                <fa-icon
                  class="icon default"
                  [icon]="['fas', 'trash']"
                ></fa-icon>
              </button>
            </td>

          </tr>
        </ng-container>
        <ng-container *ngIf="!filteredData.length">
          <tr>
            <td class="no-data" [attr.colspan]="gridColumns.length + 1">
              <div class="no-data-container">
              <ng-container *ngIf="searchedValue.length === 0; else searchText">
                <span class="icon-placeholder empty-list-icon"></span>
                <span class="no-data-text">{{ NoDataText }}</span>
              <button
                (click)="createNew()"
                [disabled]="disabledCreateButton"
                class="create-new-user no-data-btn"
                mat-flat-button
                color="primary"
              >
              <fa-icon class="icon default" [icon]="['fas', 'plus']"></fa-icon>
                {{ AddButtonText }}
              </button>
            </ng-container>
            
            <ng-template #searchText>
              <span class="icon-placeholder"></span>
              <span class="no-data-text">{{ primaryNoSearchDataText }}</span>
              <p class="no-data-text secondary-text">{{ secondaryNoSearchDataText }}</p>
            </ng-template>
          </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </section>
</div>
