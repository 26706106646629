import { Injectable } from '@angular/core';
import { Metric } from '../components/details-metrics/details-metrics.type';
import { MetricDetailsStateMapper } from './state-mappers/metric-mapping-state-mapper.service';
import { MetricMappingDO, MetricService } from 'app/shared/services/backend/metric.service';
import { MetricsUtilsService } from './metrics-utils.service';
import { MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { BehaviorSubject } from 'rxjs';


/**
 * Shared service for handling common metrics related logic on details pages
 */
@Injectable({
  providedIn: 'root'
})
export class BudgetObjectMetricsService {
  private _types: MetricType[] = [];
  private _products: ProductDO[] = [];

  isKeyMetricSubject = new BehaviorSubject(false);

  /**
   * Check metrics content for equality
   */
  public static areEqual(metricA: Metric, metricB: Metric) {
    return (
      metricA.typeId === metricB.typeId &&
      metricA.current === metricB.current &&
      metricA.target === metricB.target
    );
  }

  public static compareTypesByName(typeA: MetricType, typeB: MetricType) {
    const nameA = typeA.name.toUpperCase();
    const nameB = typeB.name.toUpperCase();

    return nameA.localeCompare(nameB);
  }

  private static sortTypes(types: MetricType[] = []): MetricType[] {
    return types.sort(BudgetObjectMetricsService.compareTypesByName);
  }

  constructor(
    private readonly metricService: MetricService,
    private readonly metricsUtilsService: MetricsUtilsService
  ) {}

  get types() {
    return this._types;
  }

  get products() {
    return this._products;
  }

  public setTypes(types: MetricType[]) {
    this._types = BudgetObjectMetricsService.sortTypes(types);
  }

  public setProducts(product: ProductDO[]) {
    this._products = product;
  }

  private findByType(targetType: number, metrics: Metric[]) {
    return metrics.find((mapping) => (
      mapping.typeId === targetType
    ));
  }

  /**
   * Get locally created types
   */
  public getLocalTypes(currentMappings: Metric[] = []) {
    return this._types
      .filter(mt => mt.isCustom && !mt.id)
      .filter(mt =>
        currentMappings.find(mapping => mapping.name === mt.name)
      );
  }

  public convertDataObjectToMapping(dataObject: MetricMappingDO): Metric {
    const sources = [];
    Object.entries(dataObject.third_party_amounts)
      .forEach(([integrationId, value]) => {
        if (value) {
          sources.push(integrationId);
        }
      })
    if (dataObject.actual_amount > 0) {
      sources.push(MetricIntegrationName.Plannuh);
    }

    const milestones = MetricDetailsStateMapper.convertMetricDOMilestones(dataObject.milestones);
    const calculations = MetricDetailsStateMapper.convertMetricCalculations(dataObject.metric_calculations);

    return {
      id: dataObject.id,
      name: dataObject.metric_detail.name,
      target: this.metricsUtilsService.getTargetValue(milestones),
      legacyTarget: dataObject.projection_amount,
      current: MetricDetailsStateMapper.getCurrentValue(dataObject, true),
      typeId: dataObject.metric_detail.id,
      productId: dataObject.metric_detail.product,
      startDate: dataObject.start_date,
      order: dataObject.metric_detail.order,
      milestones,
      sources,
      ...calculations
    };
  }
}
