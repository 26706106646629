import { AnalyticsConfig } from '@common-lib/services/analytics-config.type';

declare const MktoForms2: any;
const marketoFormId = 'mktoForm_3626';
const marketoScriptUrl = '//marketing.planful.com/js/forms2/js/forms2.min.js';

export class AnalyticsService {
  static addGoogleTagManager() {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s) as HTMLScriptElement,dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PDJPQR2');
  }

  static addChurnZero() {
    (<any>window).ChurnZero = (<any>window).ChurnZero || [];
    AnalyticsService.insertScript('https://analytics.churnzero.net/churnzero.js', 'churnzero-script');
  }

  static addLinkedinAnalytic(linkedinPartnerId: string) {
    const windowObj = (<any>window);
    windowObj._linkedin_data_partner_ids = windowObj._linkedin_data_partner_ids || [];
    windowObj._linkedin_data_partner_ids.push(linkedinPartnerId);

    if (!windowObj.lintrk) {
      windowObj.lintrk = function(a, b) {
        windowObj.lintrk.q.push([a, b]);
      };
      windowObj.lintrk.q = [];
    }
    AnalyticsService.insertScript('https://snap.licdn.com/li.lms-analytics/insight.min.js', 'linkedin-tracking-script');
  }

  static addPendoAnalytics(pendoApiKey: string): void {
    (function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    })(pendoApiKey);
  }

  private static insertScript(url: string, id?: string | null, onload?: () => void) {
    const scriptEl = window.document.createElement('script');
    scriptEl.src = url;
    scriptEl.type = 'text/javascript';
    if (id) {
      scriptEl.id = id;
    }
    if (onload) {
      scriptEl.onload = onload;
    }
    const headEl = window.document.getElementsByTagName('head')[0];
    headEl.insertBefore(scriptEl, null);
  }

  private static onloadMarketoFormScript(): void {
    MktoForms2.loadForm('//marketing.planful.com', '323-LTC-321', 3626);
  }

  private static addMarketoScript(): void {
    const formEl = window.document.createElement('form');
    formEl.id = marketoFormId;
    formEl.style.display = 'none';
    window.document.body.appendChild(formEl);

    AnalyticsService.insertScript(marketoScriptUrl, null, AnalyticsService.onloadMarketoFormScript);
  }

  public static initAnalytics(config: AnalyticsConfig) {
    if (config.enable_ga) {
      AnalyticsService.addGoogleTagManager();
    }
    if (config.churn_zero_app_key) {
      AnalyticsService.addChurnZero();
    }
    if (config.linkedin_partner_id) {
      AnalyticsService.addLinkedinAnalytic(config.linkedin_partner_id);
    }
    if (config.pendo_api_key) {
      AnalyticsService.addPendoAnalytics(config.pendo_api_key);
    }
    if (config.enable_marketo_form) {
      AnalyticsService.addMarketoScript();
    }
  }
}
