import { Injectable } from '@angular/core';
import { ExtendedUserDO } from 'app/shared/types/user-do.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { SelectOption } from 'app/shared/types/select-option.interface';
import { CompanyUserPermissionDO } from 'app/shared/types/company-user-do.interface';


@Injectable({
  providedIn: 'root'
})
export class BudgetObjectOwnersService {
  private static mapUserToOwnerOption(user: ExtendedUserDO): SelectOption {
    return {
      id: user.id,
      name: user.name
    };
  }

  private static isActiveUser(user: ExtendedUserDO): boolean {
    return user.active_in_company && !user.disabled_in_company;
  }

  private static isPowerUser(user: ExtendedUserDO): boolean {
    return user.is_admin || user.is_account_owner;
  }

  private static getBudgetPermission(user: ExtendedUserDO, budgetId: number): CompanyUserPermissionDO {
    return user.permissions.find(permission => permission.budget_id === budgetId);
  }

  private static getSharedCostRuleSegmentIds(sharedCostRules: SharedCostRule[], ruleId: number): number[] {
    const targetSCR = sharedCostRules.find(item => item.id === ruleId);

    return (targetSCR?.segments || []).map(item => item.id);
  }

  public static filterUserBySegmentAccess(
    user: ExtendedUserDO,
    budgetId: number,
    targetSegmentIds?: number[]
  ): boolean {
    if (!BudgetObjectOwnersService.isActiveUser(user)) {
      return false;
    }
    if (BudgetObjectOwnersService.isPowerUser(user)) {
      return true;
    }

    const budgetPermission = BudgetObjectOwnersService.getBudgetPermission(user, budgetId);
    const readonlyPermission = !budgetPermission?.read_write;

    if (readonlyPermission) {
      return false;
    }
    if (!targetSegmentIds?.length) {
      return true;
    }

    return budgetPermission.segments.some(allowedSegment => targetSegmentIds.includes(allowedSegment.id));
  }

  private static filterAllowedSegments(
    item: HierarchySelectItem,
    sharedCostRules: SharedCostRule[],
    allowedSegmentIds: number[],
    configObjectTypes: Record<string, string>,
  ): boolean {
    if (item.objectType === configObjectTypes.segment) {
      return allowedSegmentIds.includes(item.objectId);
    }

    if (item.objectType === configObjectTypes.sharedCostRule) {
      const targetSegments = BudgetObjectOwnersService.getSharedCostRuleSegmentIds(sharedCostRules, item.objectId);
      return allowedSegmentIds.some(allowedSegment => targetSegments.includes(allowedSegment));
    }

    return false;
  }

  public static getOwnerOptions(
    budgetId: number,
    segmentId: number,
    sharedCostRuleId: number,
    ownerId: number,
    companyUsers: ExtendedUserDO[],
    sharedCostRules: SharedCostRule[],
  ): SelectOption[] {
    const allowedOwners = BudgetObjectOwnersService.getAllowedOwnerOptions({
      sharedCostRuleId,
      segmentId,
      companyUsers,
      sharedCostRules,
      budgetId
    });

    const included = allowedOwners.find(user => user.id === ownerId);

    if (!ownerId || included) {
      return [...allowedOwners];
    } else {
      // TODO: may be we need additional owner validation
      const userOwner = companyUsers.find(user => user.id === ownerId);
      return  userOwner ? [userOwner, ...allowedOwners] : [...allowedOwners];
    }
  }

  public static getAllowedOwnerOptions(
    params: {
      segmentId: number;
      sharedCostRuleId: number;
      budgetId: number;
      companyUsers: ExtendedUserDO[];
      sharedCostRules: SharedCostRule[];
    }
  ): SelectOption[] {
    const { segmentId, sharedCostRuleId, budgetId, companyUsers, sharedCostRules } = params;
    let targetSegments: number[] = [];

    if (sharedCostRuleId) {
      targetSegments = BudgetObjectOwnersService.getSharedCostRuleSegmentIds(sharedCostRules, sharedCostRuleId);
    }
    if (segmentId) {
      targetSegments = [segmentId];
    }

    return companyUsers
      .filter(user => BudgetObjectOwnersService.filterUserBySegmentAccess(user, budgetId, targetSegments))
      .map(user => BudgetObjectOwnersService.mapUserToOwnerOption(user));
  }

  public static getAllowedSegmentOptions(
    params: {
      ownerId: number;
      budgetId: number;
      segmentSelectItems: HierarchySelectItem[];
      sharedCostRules: SharedCostRule[];
      companyUsers: ExtendedUserDO[];
    },
    configObjectTypes: Record<string, string>,
  ): HierarchySelectItem[] {
    const { ownerId, budgetId, segmentSelectItems, sharedCostRules, companyUsers } = params;
    const companyUser = companyUsers.find(user => user.id === ownerId);

    if (!ownerId || BudgetObjectOwnersService.isPowerUser(companyUser)) {
      return segmentSelectItems;
    }

    const budgetPermission = BudgetObjectOwnersService.getBudgetPermission(companyUser, budgetId);
    const allowedSegmentIds = (budgetPermission?.segments || []).map(segment => segment.id);
    const filteredSegmentSelectItems: HierarchySelectItem[] = [];

    segmentSelectItems.forEach(item => {
      const selectItem = { ...item };
      if (selectItem.children?.length) {
        selectItem.children = selectItem.children.filter(
          childItem => BudgetObjectOwnersService.filterAllowedSegments(childItem, sharedCostRules, allowedSegmentIds, configObjectTypes)
        );
      }

      if (selectItem.objectType === configObjectTypes.segmentsGroup) {
        if (!selectItem.children?.length) {
          return;
        }

        filteredSegmentSelectItems.push(selectItem);
        return;
      }

      if (BudgetObjectOwnersService.filterAllowedSegments(selectItem, sharedCostRules, allowedSegmentIds, configObjectTypes)) {
        filteredSegmentSelectItems.push(selectItem);
      }
    });

    return filteredSegmentSelectItems;
  }
}
