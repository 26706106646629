import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

export interface MetricMappingCalculationThirdPartyValue {
  value: number;
  change_in_value: number;
}

export interface MetricMappingCalculationThirdPartyValues {
  [thirdPartyProvider: string]: MetricMappingCalculationThirdPartyValue
}

export interface MetricMappingCalculationDO {
  id?: number;
  date: string;
  metric_value?: number;
  salesforce_value?: number;
  total_value?: number;
  roi_value?: number;
  cpo_value?: number;
  metric_mapping: number;
  cost_value?: number;
  change_in_value: number;
  third_party_total_value?: number;
  third_party_values?: MetricMappingCalculationThirdPartyValues
  upd?: string;
  notes?: string;
  dependent_value?: number;
}

export interface UpdateableMetricMappingCalculationDO {
  change_in_value: number;
  notes: string;
}

export interface CreateMetricMappingCalculationPayload {
  date: string;
  metric_mapping: number;
  change_in_value: number;
  notes?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MetricCalculationService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    metricCalculation: 'metric_calculation'
  };

  deleteCalculation(metricCalculationId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiV2Url}${this.apiPaths.metricCalculation}/${metricCalculationId}/`);
  }

  patchCalculation(
    metricCalculationId: number,
    payload: Partial<UpdateableMetricMappingCalculationDO>
  ): Observable<MetricMappingCalculationDO> {
    return this.http.patch<MetricMappingCalculationDO>(
      `${this.apiV2Url}${this.apiPaths.metricCalculation}/${metricCalculationId}/`,
      payload
    );
  }

  createCalculation(payload: CreateMetricMappingCalculationPayload): Observable<MetricMappingCalculationDO> {
    return this.http.post<MetricMappingCalculationDO>(
      `${this.apiV2Url}${this.apiPaths.metricCalculation}/`,
      payload
    );
  }
}
