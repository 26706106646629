import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UserComponent } from './components/user-profile/user.component';
import { SharedModule } from 'app/shared/shared.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterConfirmationComponent } from './components/register/register-confirmation.component';
import { CustomDomainComponent } from './components/custom-domain/custom-domain.component';
import { LogoutComponent } from './components/custom-domain/logout.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatementTextComponent } from './components/statement-text/statement-text.component';
import { RightsReservedComponent } from './components/rights-reserved/rights-reserved.component';
import { SuperAdminGuard } from './guards/superadmin.guard';

@NgModule({
  declarations: [
    UserComponent,
    ForgotPasswordComponent,
    LoginComponent,
    ChangePasswordComponent,
    RegisterComponent,
    RegisterConfirmationComponent,
    CustomDomainComponent,
    LogoutComponent,
    StatementTextComponent,
    RightsReservedComponent,
  ],
  providers: [
    SuperAdminGuard
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    FontAwesomeModule,
    MatTooltipModule,
  ],
  exports: [
    UserComponent,
    ForgotPasswordComponent,
    LoginComponent,
    ChangePasswordComponent,
    RegisterComponent,
    RegisterConfirmationComponent,
    CustomDomainComponent,
    LogoutComponent,
  ]
})
export class UserModule {}
