import { Component, Inject, OnInit } from '@angular/core';
import { ExternalMetricProviderApiService } from '../../types/external-metric-provider-api.service';
import { MetricsProviderDataService } from '../../services/metrics-provider-data.service';
import { HubspotMappingDialogService } from './hubspot-mapping-dialog.service';
import { HubspotApiService } from '../hubspot-api.service';
import { HubspotDataService } from '../hubspot-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExternalCampaign } from '../../types/external-campaign.interface';
import { MetricMappingDialogData } from '../../components/metric-mapping-dialog/dialog-data.type';
import { BudgetDataService } from '../../../dashboard/budget-data/budget-data.service';

@Component({
  selector: 'hubspot-mapping-dialog',
  templateUrl: './hubspot-mapping-dialog.component.html',
  providers: [
    HubspotMappingDialogService,
    { provide: ExternalMetricProviderApiService, useClass: HubspotApiService },
    { provide: MetricsProviderDataService, useClass: HubspotDataService}
  ]
})
export class HubspotMappingDialogComponent implements OnInit {
  placeholderText = '';

  private noMatchingCampaignsPlaceholder = `No Hubspot campaigns match this Planful metric.
    This happens when there are no Hubspot campaigns with an end date after this Planful metric start date.`;
  private noMappingsSetPlaceholder = `No Hubspot campaigns match this Planful metric.
    Please ask your Planful admin to map Hubspot metrics to this Planful metric.`;
  hubspotCampaignsList: ExternalCampaign[];

  constructor(
    public dialogRef: MatDialogRef<HubspotMappingDialogComponent>,
    private metricMappingDialogService: HubspotMappingDialogService,
    private budgetDataService: BudgetDataService,
    @Inject(MAT_DIALOG_DATA) public data: MetricMappingDialogData) {}

  ngOnInit() {
    this.placeholderText = 'Loading data';
    this.metricMappingDialogService.loadExternalCampaigns(
      {
        companyId: this.data.companyId,
        campaignMetrics: this.data.campaignMetrics,
        campaignId: this.data.campaignId,
        integrationId: this.data.integrationId,
        dateFrom: this.data.dateFrom,
        budget: this.data.budget,
        allCampaigns: this.budgetDataService.lightCampaignsSnapshot || this.budgetDataService.campaignsSnapshot || []
      },
      this.onCampaignsLoaded.bind(this),
      this.onCampaignsLoadFailed.bind(this)
    );
  }

  onSave(campaigns: ExternalCampaign[]) {
    this.metricMappingDialogService.updateCampaignsMapping(campaigns, this.data);
    this.metricMappingDialogService.saveCampaignMapping(
      this.data.companyId,
      this.data.budget.id,
      this.data.campaignId,
      this.data.integrationId,
      this.data.onSavedCb);
    this.dialogRef.close();
  }

  private onCampaignsLoaded(campaigns: ExternalCampaign[], metricTypeMappings: string[]) {
    this.hubspotCampaignsList = campaigns;
    if (!campaigns || !campaigns.length) {
      this.placeholderText = metricTypeMappings && metricTypeMappings.length ?
        this.noMatchingCampaignsPlaceholder :
        this.noMappingsSetPlaceholder;
    }
  }

  private onCampaignsLoadFailed() {
    this.placeholderText = this.noMappingsSetPlaceholder;
  }
}
