import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BusinessValueTableData, BusinessValueTableRecord } from './business-value-table.types';
import { AppRoutingService } from '../../services/app-routing.service';
import { SortParams } from '../../types/sort-params.interface';
import { CompareService } from '../../services/compare.service';
import { CompareDirection } from '../../types/compare-direction.type';
import { CompareType } from '../../types/compare-type.type';

@Component({
  selector: 'business-value-table',
  templateUrl: './business-value-table.component.html',
  styleUrls: ['./business-value-table.component.scss']
})
export class BusinessValueTableComponent implements OnChanges {
  @Input() currency;
  @Input() data: BusinessValueTableData;

  public isBodyScrolled = false;
  public readonly ColumnName = {
    Name: 'name',
    TargetReturn: 'targetReturn',
    CurrentReturn: 'currentReturn',
    LowForecast: 'lowForecast',
    HighForecast: 'highForecast',
    TargetROI: 'targetROI',
    CurrentROI: 'currentROI',
    Owner: 'owner',
  };
  public readonly VISIBLE_ROWS_LIMIT = 10;
  public readonly OWNER_NAME_TRUNCATE_LIMIT = 30;
  public readonly decimalFormat = '1.2-2';
  public appliedSorting: SortParams = {
    column: this.ColumnName.Name,
    reverse: false
  };

  constructor(
    private readonly appRoutingService: AppRoutingService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.sortData();
    }
  }

  private getCompareType(columnName: string): CompareType {
    const textColumns = [ this.ColumnName.Name, this.ColumnName.Owner ];
    if (textColumns.includes(columnName)) {
      return CompareType.Alphabetical;
    }

    return CompareType.Numerical;
  }

  private sortData() {
    const { column, reverse } = this.appliedSorting;
    const compareType = this.getCompareType(this.appliedSorting.column);
    const comparer = CompareService.comparerByType[compareType];
    const sortDirection = reverse ? CompareDirection.Desc : CompareDirection.Asc;

    this.data?.campaigns?.sort((itemA, itemB) => (
      comparer(itemA[column], itemB[column], sortDirection)
    ));
  }

  public handleBodyScrolledChange($event: boolean) {
    this.isBodyScrolled = $event;
  }

  public handleNameClick(record: BusinessValueTableRecord) {
    if (record.campaignId) {
      this.appRoutingService.openCampaignDetails(record.campaignId);
    }
  }

  public applySorting(columnName: string) {
    if (this.appliedSorting.column === columnName) {
      this.appliedSorting.reverse = !this.appliedSorting.reverse;
    } else {
      this.appliedSorting.column = columnName;
      this.appliedSorting.reverse = false;
    }
    this.sortData();
  }

  public identifyRecord(record: BusinessValueTableRecord) {
    return record.campaignId;
  }
}
