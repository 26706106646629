<svg
  *ngIf="isMonoChrome; else nonMonoChrome"
  viewBox="0 0 105 106"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="width || size"
  [attr.height]="height || size"
>
  <path
    d="M97.5 0.5H7.26562C3.28125 0.5 0 4.01562 0 8.23438V98C0 102.219 3.28125 105.5 7.26562 105.5H97.5C101.484 105.5 105 102.219 105 98V8.23438C105 4.01562 101.484 0.5 97.5 0.5ZM31.6406 90.5H16.1719V40.5781H31.6406V90.5ZM23.9062 33.5469C18.75 33.5469 14.7656 29.5625 14.7656 24.6406C14.7656 19.7188 18.75 15.5 23.9062 15.5C28.8281 15.5 32.8125 19.7188 32.8125 24.6406C32.8125 29.5625 28.8281 33.5469 23.9062 33.5469ZM90 90.5H74.2969V66.125C74.2969 60.5 74.2969 53 66.3281 53C58.125 53 56.9531 59.3281 56.9531 65.8906V90.5H41.4844V40.5781H56.25V47.375H56.4844C58.5938 43.3906 63.75 39.1719 71.25 39.1719C86.9531 39.1719 90 49.7188 90 63.0781V90.5Z"
    [attr.fill]="color"
  />
</svg>
<ng-template #nonMonoChrome>
  <svg
    viewBox="0 0 105 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width || size"
    [attr.height]="height || size"
  >
    <path
      style="fill: #0063b7"
      d="M97.5 0.5H7.26562C3.28125 0.5 0 4.01562 0 8.23438V98C0 102.219 3.28125 105.5 7.26562 105.5H97.5C101.484 105.5 105 102.219 105 98V8.23438C105 4.01562 101.484 0.5 97.5 0.5ZM31.6406 90.5H16.1719V40.5781H31.6406V90.5ZM23.9062 33.5469C18.75 33.5469 14.7656 29.5625 14.7656 24.6406C14.7656 19.7188 18.75 15.5 23.9062 15.5C28.8281 15.5 32.8125 19.7188 32.8125 24.6406C32.8125 29.5625 28.8281 33.5469 23.9062 33.5469ZM90 90.5H74.2969V66.125C74.2969 60.5 74.2969 53 66.3281 53C58.125 53 56.9531 59.3281 56.9531 65.8906V90.5H41.4844V40.5781H56.25V47.375H56.4844C58.5938 43.3906 63.75 39.1719 71.25 39.1719C86.9531 39.1719 90 49.7188 90 63.0781V90.5Z"
      [attr.fill]="color"
    />
  </svg>
</ng-template>
