<div
  class="manage-table-wrapper"
  [ngClass]="{
    'is-loading': isLoading,
    'shadow-left': shadowState?.left,
    'shadow-right': shadowState?.right,
    'with-expenses': allocationDataMode && showExpenses,
    'filtered-mode': isFilteredMode,
    'spending-data-mode': spendingDataMode,
    'include-planned-flag': spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal],
    'expense-total-flag': spendingModeFlags[SpendingModeFlag.ExpensesAsTotal],
    'with-segment-breakdown': segmentBreakdown
  }"
>
  <loader [isVisible]="isLoading"></loader>
  <div
    class="manage-table"
    scrollOnDrag
    tableContentShadows
    (onShadowChanged)="handleShadowUpdate($event)"
  >
    <table
      elementResizeObserver
      (onElementResize)="handleTableResize($event)"
    >
      <thead class="table-head">
        <tr class="table-row"
          manage-table-head-row
          [class.with-formula]="spendingModeFlags[SpendingModeFlag.Formula]"
          [spendingModeFlags]="spendingModeFlags"
          [budget]="budget"
          [timeframes]="timeframes"
          [highlightedTimeframes]="highlightedTimeframes"
          [currentTimeframe]="currentTimeframe"
          [appliedSorting]="appliedSorting"
          [selectAllValue]="selectionState.selectAllValue"
          [spendingMode]="spendingDataMode"
          [allocationMode]="allocationDataMode"
          (onSortingChanged)="applySorting($event)"
          (onSelectAllChanged)="handleSelectAllChange($event)"
        ></tr>
      </thead>
      <tbody class="table-body" [ngClass]="{'element-selected': !!selectedRecords.length}">
        <ng-container
          *ngTemplateOutlet="recursiveRowTemplate; context: { $implicit: data, level: 0 }"
        ></ng-container>
      </tbody>
      <tfoot class="table-foot">
        <tr
          class="table-row grand-total-row"
          manage-table-foot-row
          [ngClass]="utClasses.grandTotal"
          [data]="grandTotal"
          [budget]="budget"
          [spendingModeFlags]="spendingModeFlags"
          [timeframes]="timeframes"
          [highlightedTimeframes]="highlightedTimeframes"
          [spendingMode]="spendingDataMode"
          [allocationMode]="allocationDataMode"
          [segmentBreakdown]="segmentBreakdown"
        >
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<ng-template #recursiveRowTemplate let-list let-level="level" let-parent="parent">
  <ng-container *ngFor="let record of list; trackBy: identifyRecord; index as i">
    <ng-container *ngIf="(record.type !== RowType.UnassignedExpenses || showExpenses)">
      <tr
        class="table-row"
        [className]="utClassesByType[record.type] + ' level-' + level"
        [ngClass]="{
          'topmost-node-row': record.type === RowType.SegmentGroup,
          'top-node-row': [RowType.Segment, RowType.Goal].includes(record.type),
          'secondary-node-row': record.type === RowType.Campaign && record.children?.length &&
            record.children[0].type === RowType.Campaign,
          'is-closed': record.isClosed,
          'is-selected': selectionState.records[record.id]?.value === SelectionValue.Active,
          'is-toggled': togglingState[record.id],
          'filtered-out-row': isFilteredMode && record.isFilteredOut,
          'show-hidden-hierarchy-line': record.hierarchyBreakLine,
          'segment-breakdown-row': segmentBreakdown && record.segmentRelated,
          'not-segment-related-row': !record.segmentRelated
        }"
        [attr.data-id]="record.id"
      >
        <td
          class="table-col object-controls-col"
          [ngClass]="utClasses.objectControls"
          [colSpan]="!segmentBreakdown || record.segmentRelated ? 1 : 2"
        >
          <div class="create-item-line"
               *ngIf="editPermission && !newItemCreationActive && (record.type === RowType.Campaign || record.type === RowType.ExpenseGroup)"
               (click)="createNewItemTemplate(record, i)"></div>
          <div class="table-cell"
            [ngClass]="{
              'drop-allow': this.droppableItem[record.objectId] === dropState.ALLOW && checkDragEntity(record.objectId),
              'drop-forbid': this.droppableItem[record.objectId] === dropState.FORBID && checkDragEntity(record.objectId)
            }"
            [plDroppable]="!record.isClosed"
            (dragenter)="handleDragOver($event, record)"
            (dropOn)="handleEntityOnDrop(record)">
            <ng-container
              *ngTemplateOutlet="controlsCellTemplate; context: { $implicit: record, level: level, parent: parent }"
            ></ng-container>
          </div>
        </td>
        <td class="table-col segment-breakdown-col" *ngIf="record.segmentRelated">
          <div
            class="table-cell"
            [class.with-formula]="allocationModeFlags[AllocationModeFlag.Formula] && (!level && !i)"
          >
            <manage-table-segment-breakdown
              [allocatedOnly]="!segmentBreakdown"
              [withSubtitles]="allocationDataMode"
            >
              <ng-template #segment>{{ labelByRowType[record.type] }}</ng-template>
            </manage-table-segment-breakdown>
          </div>
        </td>
        <td class="table-col performance-col left-scroll-anchor" [ngClass]="utClasses.performance">
          <div class="table-cell">
            <manage-table-segment-breakdown
              [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
            >
              <ng-template #segment></ng-template>
              <ng-template #allocated>
                <metric-performance-arrow
                  *ngIf="record.type === RowType.Campaign && performanceColumnData[record.objectId]"
                  metricPerformanceTooltip
                  [showTooltip]="true"
                  [progressState]="performanceColumnData[record.objectId]?.progressState"
                  [estimatedDiffPercentage]="performanceColumnData[record.objectId]?.estimatedDiffPercentage"
                  [current]="performanceColumnData[record.objectId]?.current"
                  [target]="performanceColumnData[record.objectId]?.estimatedTarget"
                  [name]="performanceColumnData[record.objectId]?.name"
                  (onClick)="handlePerformanceClick(performanceColumnData[record.objectId]?.mappingId)"
                ></metric-performance-arrow>
              </ng-template>
              <ng-template #unallocated></ng-template>
            </manage-table-segment-breakdown>
          </div>
        </td>

        <ng-container *ngIf="allocationDataMode">
          <td
            class="table-col timeframe-col"
            *ngFor="let timeframe of timeframes; trackBy: identifyTimeframe"
            [class.highlighted-timeframe]="highlightedTimeframes.includes(timeframe.id)"
            [class.only-expense]="budget?.suppress_timeframe_allocations"
          >
            <div class="table-cell difference-amount-cell">
              <manage-table-segment-breakdown
                [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
              >
                <ng-template #segment>
                  <budget-allocation-cell
                    *ngIf="record.type !== RowType.UnassignedExpenses && !budget?.suppress_timeframe_allocations && record.isOwnDataReady && record.isChildDataReady"
                    [allocated]="record.segment?.values[timeframe.id]?.allocated"
                    [spent]="record.segment?.values[timeframe.id]?.spent"
                    [disabled]="record.isClosed || timeframe.locked"
                    [editable]="isAdmin && record.type === RowType.Segment"
                    [showDifference]="isAdmin && record.type === RowType.Segment"
                    [allowGestures]="!record.isClosed && !timeframe.locked"
                    [hasRemainingBudget]="remainingBudget > 0"
                    [actionTooltipContext]="segmentTooltipContext"
                    (onChange)="handleSegmentAllocationChange($event, { record: record, timeframe: timeframe })"
                    (onDoubleClick)="handleDoubleClick($event, { record: record, timeframe: timeframe })"
                    (onDrop)="handleOnDrop($event, { record: record, timeframe: timeframe })"
                    (onDragStart)="handleOnDragStart($event, { record: record, timeframe: timeframe })"
                    (onDragEnd)="handleOnDragEnd()"
                  >
                  </budget-allocation-cell>
                  <manage-table-plain-value
                    *ngIf="record.isOwnDataReady && record.isChildDataReady"
                    [value]="record.segment?.values[timeframe.id]?.spent"
                    [isClickable]="record.segmentRelated
                      && record.segment?.values[timeframe.id]?.spent > 0"
                    [isDisabled]="record.isClosed || timeframe.locked"
                    (onClick)="onExpenseClick(record, timeframe)"
                  ></manage-table-plain-value>
                </ng-template>
                <ng-template #allocated>
                  <budget-allocation-cell
                    *ngIf="record.type !== RowType.UnassignedExpenses && !budget?.suppress_timeframe_allocations && (record.isOwnDataReady && record.isChildDataReady)"
                    [allocated]="record.values[timeframe.id]?.allocated"
                    [spent]="null"
                    [remaining]="record.values[timeframe.id]?.remainingAllocated"
                    [editable]="editPermission && !record.isClosed && record.isEditable"
                    [disabled]="record.isClosed || timeframe.locked"
                    [showDifference]="true"
                    [allowGestures]="editPermission && !record.isClosed && record.isEditable"
                    [globalDragStarted]="false"
                    [droppable]="!restrictedFromDrop?.includes(record.id)"
                    [hasRemainingBudget]="true"
                    [actionTooltipContext]="tooltipContext"
                    (onChange)="handleAllocationChange($event, { record: record, timeframe: timeframe })"
                    (onDoubleClick)="handleDoubleClick($event, { record: record, timeframe: timeframe })"
                    (onDrop)="handleOnDrop($event, { record: record, timeframe: timeframe })"
                    (onDragStart)="handleOnDragStart($event, { record: record, timeframe: timeframe })"
                    (onDragEnd)="handleOnDragEnd()"
                  >
                  </budget-allocation-cell>
                  <manage-table-plain-value
                    *ngIf="record.isOwnDataReady && record.isChildDataReady"
                    [value]="record.values[timeframe.id]?.spent"
                    [isClickable]="!record.segmentRelated && !record.isClosed && record.values[timeframe.id]?.spent > 0"
                    [isDisabled]="record.isClosed || timeframe.locked"
                    (onClick)="onExpenseClick(record, timeframe)"
                  ></manage-table-plain-value>
                </ng-template>
                <ng-template #unallocated>
                  <budget-allocation-cell
                    *ngIf="record.type !== RowType.UnassignedExpenses && !budget?.suppress_timeframe_allocations && record.isOwnDataReady && record.isChildDataReady"
                    [allocated]="record.unallocated?.values[timeframe.id]?.allocated"
                    [spent]="record.unallocated?.values[timeframe.id]?.spent"
                    [disabled]="record.isClosed || timeframe.locked"
                    [showDifference]="true"
                    [editable]="false"
                    [allowGestures]="false"
                    [droppable]="false"
                    [allowNegative]="true"
                    [actionTooltipContext]="tooltipContext"
                  >
                  </budget-allocation-cell>
                  <manage-table-plain-value
                    *ngIf="record.isOwnDataReady && record.isChildDataReady"
                    [value]="record.unallocated?.values[timeframe.id]?.spent"
                    [isDisabled]="record.isClosed || timeframe.locked"
                    [isClickable]="record.segmentRelated
                      && record.unallocated?.values[timeframe.id]?.spent > 0"
                    (onClick)="onExpenseClick(record, timeframe, true)"
                  ></manage-table-plain-value>
                </ng-template>
              </manage-table-segment-breakdown>
            </div>
          </td>
          <td class="table-col fy-total-col right-scroll-anchor">
            <div class="table-cell difference-amount-cell total-amount-cell">
              <manage-table-segment-breakdown
                [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
              >
                <ng-template #segment>
                  <budget-allocation-cell
                    [allocated]="record.segment?.total?.allocated"
                    [actionTooltipContext]="tooltipContext"
                    [disabled]="record.isClosed"
                    [editable]="false"
                    [showDifference]="false"
                    [allowGestures]="false"
                    [droppable]="false"
                  >
                  </budget-allocation-cell>
                  <manage-table-plain-value
                    [value]="record.segment?.total?.spent"
                    [isDisabled]="record.isClosed"
                    (onClick)="onExpenseClick(record)"
                  ></manage-table-plain-value>
                </ng-template>
                <ng-template #allocated>
                  <budget-allocation-cell
                    [allocated]="record.total?.allocated"
                    [spent]="null"
                    [remaining]="record.total?.remainingAllocated"
                    [actionTooltipContext]="tooltipContext"
                    [editable]="editPermission && record.isEditable && budget?.suppress_timeframe_allocations"
                    [disabled]="record.isClosed"
                    [showDifference]="true"
                    [hasRemainingBudget]="true"
                    [droppable]="!restrictedFromDrop?.includes(record.id)"
                    [allowGestures]="editPermission && record.isEditable && budget?.suppress_timeframe_allocations"
                    (onChange)="handleTotalAllocationChange($event, record)"
                    (onDoubleClick)="handleDoubleClick($event, { record: record, timeframe: null })"
                    (onDrop)="handleOnDrop($event, { record: record, timeframe: null })"
                    (onDragStart)="handleOnDragStart($event, { record: record, timeframe: null })"
                    (onDragEnd)="handleOnDragEnd()"
                  >
                  </budget-allocation-cell>
                  <manage-table-plain-value
                    [value]="record.total?.spent"
                    [isDisabled]="record.isClosed"
                    (onClick)="onExpenseClick(record)"
                  ></manage-table-plain-value>
                </ng-template>
                <ng-template #unallocated>
                  <budget-allocation-cell
                    [allocated]="record.unallocated?.total?.allocated"
                    [spent]="record.unallocated?.total?.spent"
                    [actionTooltipContext]="tooltipContext"
                    [disabled]="record.isClosed"
                    [showDifference]="true"
                    [editable]="false"
                    [allowGestures]="false"
                    [allowNegative]="true"
                    [droppable]="false"
                  >
                  </budget-allocation-cell>
                  <manage-table-plain-value
                    [value]="record.unallocated?.total?.spent"
                    [isDisabled]="record.isClosed"
                    (onClick)="onExpenseClick(record)"
                  ></manage-table-plain-value>
                </ng-template>
              </manage-table-segment-breakdown>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="spendingDataMode">
          <ng-container
            *ngTemplateOutlet="spendingCellsTemplate; context: { $implicit: record, level: level }"
          ></ng-container>
        </ng-container>
      </tr>
    </ng-container>

    <ng-container *ngIf="record.children?.length > 0 && (togglingState[record.id] || record.isFilteredOut)">
      <ng-container
        *ngTemplateOutlet="recursiveRowTemplate; context: { $implicit: record.children, level: level + 1, parent: record }"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #controlsCellTemplate let-record let-level="level" let-parent="parent">
  <div class="selection">
    <mat-checkbox
      *ngIf="editPermission && record.isSelectable"
      color="primary"
      class="control checkbox-control pl-mat-checkbox"
      (change)="handleSelection($event, record)"
      [checked]="selectionState.records[record.id]?.value === SelectionValue.Active"
      [indeterminate]="selectionState.records[record.id]?.value === SelectionValue.Indeterminate"
    ></mat-checkbox>
  </div>
  <div class="hierarchy-info" *ngIf="hasHiddenHierarchy">
    <ng-container *ngIf="record.hierarchyInfo">
      <icon-fa-duotone-list-tree
        (mouseleave)="hideHierarchyTooltip()"
        (mouseenter)="showHierarchyTooltip(record, $event)"
        [size]="'16'"></icon-fa-duotone-list-tree>
    </ng-container>
  </div>
  <div class="level">
    <arrow-toggler
      *ngIf="record.shownChildren?.length"
      [active]="!togglingState[record.id]"
      (change)="handleToggleChange($event, record)"
    ></arrow-toggler>
    <div class="level-indentation" *ngIf="!record.children?.length"></div>
  </div>
  <object-name-cell
    *ngIf="record.id"
    [class.dragging]="draggedEntity?.objectId === record.objectId"
    [name]="record.name + (record.isClosed ? ' [Closed]' : '')"
    [isClickable]="routeActionByRowType[record.type]"
    dragImage
    [plDraggable]="allowDrag(record)"
    [icon]="iconByRowType[record.type]"
    [objectTypeName]="objectTypeNameMap[record.type] ? objectTypeNameMap[record.type][record.objectTypeId] : null"
    [imageName]="record.name + (record.isClosed ? ' [Closed]' : '')"
    [selectedRecordsCount]="selectedRecords.length"
    [dragging]="!!draggedEntity"
    [isClosed]="record.isClosed"
    [rowType]="record.type"
    (onClick)="handleNameClick(record)"
    (dragStart)="handleEntityOnDragStart(record)"
    (dragEnd)="handleEntityOnDragEnd()"
  >
  </object-name-cell>
  <object-name-input
    *ngIf="record.id === null"
    [icon]="iconByRowType[record.type]"
    [objectType]="record.type"
    (nameChanged)="createItemFromTemplate($event)"></object-name-input>
  <div class="scr-info" *ngIf="record.sharedCostRuleId">
    <icon-shared
      class="icon-shared"
      matTooltipPosition="above"
      matTooltipClass="dark-theme-tooltip above multi-line"
      sharedCostRulesTooltip
      [actionTooltipContext]="record.sharedCostRuleId"
      [segments]="segments"
      [sharedCostRules]="sharedCostRules"
      [state]="record.sharedCostRuleId | sharedCostState:
        (!level || parent.type !== RowType.Campaign ? null : parent) : (!level || parent.type !== RowType.Campaign ? record.children : []) : 'sharedCostRuleId'"
      [showTooltip]="true">
    </icon-shared>
  </div>
  <manage-table-record-actions
    *ngIf="editPermission && !record.segmentRelated && record.isSelectable"
    [isClosed]="record.isClosed"
    [record]="record"
    [externalId]="record.externalId"
    (onActionClick)="handleRecordActionClick($event, record)"
  ></manage-table-record-actions>
  <new-entity-label [createdDate]="record.createdDate">
    <span #lastCreatedElementAnchor *ngIf="lastCreatedObjectId === record.objectId"></span>
  </new-entity-label>
</ng-template>

<ng-template #spendingCellsTemplate let-record let-level="level">
  <td class="table-col allocated-col spending-col" [ngClass]="utClasses.allocated">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
        [segmentValue]="record.segment?.spending?.allocated"
        [allocatedValue]="record.spending?.allocated"
        [unallocatedValue]="record.unallocated?.spending?.allocated"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <th class="table-col spending-col total-expenses-col" [ngClass]="utClasses.totalExpenses" [ngClass]="'level-' + level">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
        [segmentValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? record.segment?.spending?.totalExpensesWithPlanned
          : record.segment?.spending?.totalExpenses"
        [allocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? record.spending?.totalExpensesWithPlanned
          : record.spending?.totalExpenses"
        [unallocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? record.unallocated?.spending?.totalExpensesWithPlanned
          : record.unallocated?.spending?.totalExpenses"
      >
      </plain-breakdown-value>
    </div>
  </th>
  <td class="table-col closed-col spending-col" [ngClass]="utClasses.closedExpenses" [ngClass]="'level-' + level">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
        [segmentValue]="record.segment?.spending?.closed"
        [allocatedValue]="record.spending?.closed"
        [isAllocatedClickable]="!record.segmentRelated && record.spending?.closed > 0"
        [unallocatedValue]="record.unallocated?.spending?.closed"
        (onAllocatedClick)="handleExpenseStatusClick(record, statusNames.closed)"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col committed-col spending-col" [ngClass]="utClasses.committedExpenses" [ngClass]="'level-' + level">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
        [segmentValue]="record.segment?.spending?.committed"
        [allocatedValue]="record.spending?.committed"
        [isAllocatedClickable]="!record.segmentRelated && record.spending?.committed > 0"
        [unallocatedValue]="record.unallocated?.spending?.committed"
        (onAllocatedClick)="handleExpenseStatusClick(record, statusNames.committed)"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col total-remaining-col spending-col" [ngClass]="utClasses.totalRemaining">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
        [segmentValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? record.segment?.spending?.totalRemainingWithPlanned
          : record.segment?.spending?.totalRemaining"
        [allocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? record.spending?.totalRemainingWithPlanned
          : record.spending?.totalRemaining"
        [unallocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? record.unallocated?.spending?.totalRemainingWithPlanned
          : record.unallocated?.spending?.totalRemaining"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col planned-col spending-col" [ngClass]="utClasses.plannedExpenses" [ngClass]="'level-' + level">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
        [segmentValue]="record.segment?.spending?.planned"
        [allocatedValue]="record.spending?.planned"
        [isAllocatedClickable]="!record.segmentRelated && record.spending?.planned > 0"
        [unallocatedValue]="record.unallocated?.spending?.planned"
        (onAllocatedClick)="handleExpenseStatusClick(record, statusNames.planned)"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col children-allocated-col spending-col" [ngClass]="utClasses.allocatedToChildren">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
        [segmentValue]="record.segment?.spending?.allocatedToChildren"
        [allocatedValue]="record.spending?.allocatedToChildren"
        [unallocatedValue]="null"
        [withNullishPlaceholder]="true"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col total-available-col spending-col" [ngClass]="utClasses.totalAvailable">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!(segmentBreakdown && record.segmentRelated)"
        [segmentValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? record.segment?.spending?.totalAvailableWithPlanned
          : record.segment?.spending?.totalAvailable"
        [allocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? record.spending?.totalAvailableWithPlanned
          : record.spending?.totalAvailable"
        [unallocatedValue]="null"
        [withNullishPlaceholder]="true"
      >
      </plain-breakdown-value>
    </div>
  </td>
</ng-template>
