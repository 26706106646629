import { Component, ComponentFactoryResolver, HostBinding, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-toastr',
  templateUrl: './custom-toastr.component.html',
  styleUrls: ['./custom-toastr.component.scss']
})
export class CustomToastrComponent extends Toast implements OnInit, OnDestroy {
  @Input() toastID;
  @HostBinding('class.inverted')
  @Input() inverted: boolean;
  @Input() hideButton = false;
  @Input() buttonText;
  @Input() customComponent;
  @Input() customComponentData;
  @ViewChild('customComponentHost', { read: ViewContainerRef, static: true }) customComponentHost;

  private closeSubject$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(
    public toastPackage: ToastPackage,
    protected toastrService: ToastrService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super(toastrService, toastPackage)
  }

  ngOnInit() {
    this.closeSubject$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.toastrService.remove(this.toastID);
    })
    if (this.customComponent) {
      this.injectComponent(this.customComponent);
    }
  }

  injectComponent(component) {
    this.customComponentHost.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = this.customComponentHost.createComponent(factory);
    componentRef.instance.closeSubject$ = this.closeSubject$;

    if (this.customComponentData) {
      Object.entries(this.customComponentData).forEach(([propName, propValue]) => {
        componentRef.instance[propName] = propValue;
      })
    }
  }

  onAction() {
    this.toastPackage.triggerAction();
    this.closeSubject$.next();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
