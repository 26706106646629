<h3 class="page-title"
    [class.drawer]="isDrawer"
    [class.ceg-budget]="isCEGBudget"
    [ngClass]="settings[objectType].class">
  <ng-container *ngIf="isDrawer; else regularTitle">
    <div class="subtitle" [class.closed]="isClosed">
      <div class="metric-parent-drawer-container" *ngIf="objectType === 'metric'; else titleTemplate">
        <fa-icon [icon]="drawerSubtitleIcon" ></fa-icon>
        <div class="metric-parent-drawer-title" *ngIf="objectType === 'metric'; else titleTemplate" (click)="handleParentNavigation($event)"
        matTooltipPosition="below"
        matTooltipClass="dark-theme-tooltip simple multi-line"
        [matTooltip]="parentName"
        [matTooltipShowDelay]="600"
        >{{parentName}}</div>
      </div>
      
      <ng-template #titleTemplate>
        {{ settings[objectType].title }}
      </ng-template>
    </div>
    <div class="name-holder"
          highlightField
          [values]="[nameCtrl.value]"
          [fields]="[StateField.Name]">
      <div class="icon-box">
        <span class="icon-content" [class.closed]="isClosed">
          <fa-icon
            *ngIf="!isClosed else closedIcon"
            [icon]="[settings[objectType].iconType, settings[objectType].icon]"
            [flip]="settings[objectType].flipIcon && 'horizontal'"
          ></fa-icon>
          <ng-template #closedIcon>
            <fa-duotone-icon [icon]="[ 'fad', 'rocket' ]"></fa-duotone-icon>
          </ng-template>
        </span>
      </div>
      <div class="form-element">
        <mat-form-field appearance="standard" class="no-label">
          <textarea *ngIf="isCEGBudget else notCEGBudget"
            matInput
            #titleText
            [formControl]="nameCtrl"
            [placeholder]="settings[objectType].placeholder + ' *'" required="true"
            [maxLength]="maxLength"
            [rows]="1"
            (input)="autoGrow()"
            (keyup)="onKeyup($event)"
            [readonly]="objectType === 'metric'"
            ></textarea>
          <ng-template #notCEGBudget>
            <textarea matInput
              [formControl]="nameCtrl"
              [placeholder]="settings[objectType].placeholder + ' *'" required="true"
              [maxLength]="maxLength">
            </textarea>
          </ng-template>
          <div class="max-length-hint">
            <span>{{ nameLengthCounter }}/{{ maxLength }}</span>
          </div>
          <mat-error *ngIf="nameCtrl.hasError('required')">
            Name is required
          </mat-error>
          <mat-error *ngIf="nameCtrl.hasError('nameExist')">
            This {{ settings[objectType].placeholder | lowercase }} is already taken!
          </mat-error>
          <mat-error *ngIf="nameCtrl.hasError('maxlength')">
            Name can't be longer than {{ maxLength }} characters!
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <ng-template #regularTitle>
    <div class="icon-box">
      <span class="subtitle" [class.closed]="isClosed">
        {{ settings[objectType].title + 's' }}
      </span>
      <span class="icon-content" [class.closed]="isClosed">
        <fa-duotone-icon
          *ngIf="!isClosed else closedIcon"
          [icon]="['fad', settings[objectType].icon]"
          [flip]="settings[objectType].flipIcon && 'horizontal'"
        ></fa-duotone-icon>
        <ng-template #closedIcon>
          <fa-duotone-icon [icon]="[ 'fad', 'rocket' ]"></fa-duotone-icon>
        </ng-template>
      </span>
    </div>
    <p
      class="object-name-input"
      [class.is-disabled]="nameCtrl.disabled"
      [matTooltip]="nameCtrl.value"
      [matTooltipDisabled]="!nameCtrl.disabled"
      matTooltipClass="dark-theme-tooltip above"
      matTooltipPosition="above"
    >
      <mat-form-field appearance="standard" class="no-label text-input">
        <input matInput
               [formControl]="nameCtrl"
               [maxLength]="maxLength"
               [placeholder]="settings[objectType].placeholder + ' *'"
               required="true"
               (keyup)="onKeyup($event)"/>
        <span class="max-length-hint mt-05">
          <span>{{ nameLengthCounter }}/{{ maxLength }}</span>
        </span>
        <mat-error *ngIf="nameCtrl.hasError('required')">
          Name is required
        </mat-error>
        <mat-error *ngIf="nameCtrl.hasError('nameExist')">
          This {{ settings[objectType].placeholder | lowercase }} is already taken!
        </mat-error>
        <mat-error *ngIf="nameCtrl.hasError('maxlength')">
          Name can't be longer than {{ maxLength }} characters!
        </mat-error>
      </mat-form-field>
    </p>
  </ng-template>
</h3>
