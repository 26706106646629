<ng-container *ngIf="!calculatorData?.targetedMetricId; else budgetInfo">
  <button
    *ngIf="editMode"
    (click)="showCalculationsDialog()"
    [disabled]="disableCalculations"
    class="mt-1"
    mat-stroked-button
    color="primary">Calculate Marketing Budget</button>
</ng-container>

<ng-template #budgetInfo>
  <div class="d-flex mt-1">
    <product-budget-summary
      [revenueTarget]="actualRevenue"
      [budget]="calculatorData.budgetAmount"
      [currency]="companyCurrency"></product-budget-summary>
    <button *ngIf="editMode"
            class="mat-button ml-1"
            matTooltip="Edit Budget Calculation"
            matTooltipShowDelay="1000"
            matTooltipClass="dark-theme-tooltip simple"
            matTooltipPosition="above"
            (click)="showCalculationsDialog()"
            mat-icon-button>
      <fa-duotone-icon [icon]="['fad', 'pencil-alt']"></fa-duotone-icon>
    </button>
  </div>
</ng-template>
