export const COLORS = {
  MAGENTA: '#E82987',
  WHITE: '#FFFFFF',
  GRAY: '#414141',
  LIGHT_GRAY: '#7E7E7E',
  WARM_YELLOW: '#fef9f1'
};

export const FONT_FAMILY = {
  AVENIR: 'Avenir',
  ARIAL: 'Arial',
  SANS: 'DM Sans'
};

export const ASSETS = {
  FUNNEL_SAMPLE: 'assets/images/presentation/funnel-sample.png',
  TITLE_BG: 'assets/images/presentation/title-slide-bg.png',
  GRADIENT: 'assets/images/presentation/gradient.png',
  LOGO: 'assets/images/presentation/logo.svg',
  LOGO_SMALL: 'assets/images/presentation/planful_logo.svg'
};

// 16x9 ratio by default
export const SLIDE_HEIGHT_TO_WIDTH_RATIO = 0.5625;
