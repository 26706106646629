import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Configuration } from '../../app.constants';
import { MetricsProviderDataService } from '../services/metrics-provider-data.service';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';
import { LightProgram, Program } from 'app/shared/types/program.interface';
import { ExternalIntegrationObjectTypes } from 'app/shared/constants/external-integration-object-types';


@Injectable()
export class ExpenseCostAdjustmentDataService extends MetricsProviderDataService<any> {
  private integratedPrograms: LightProgram[] = [];

  constructor(
    private readonly configuration: Configuration,
    private readonly httpClient: HttpClient
  ) {
    super(httpClient, configuration.google_ads_service_url);
  }

  public setIntegrationExpenses(programs: LightProgram[], expGroupTypes: BudgetObjectType[]): void {
    this.integratedPrograms =
      programs.filter(
        program => expGroupTypes.some(
          groupType => (
            program.programTypeId === groupType.id
            && !groupType.isCustom
            && groupType.name === ExternalIntegrationObjectTypes.GoogleAds
          )
        )
      );
  }

  public disableExpenseCostAdjustment(deletedExpenses: number[], companyId: number): Observable<void> {
    if (!this.integratedPrograms.length || !deletedExpenses.length) {
      return of(null);
    }

    const programIds = this.integratedPrograms.reduce((result, expense ) => {
      if (deletedExpenses.includes(expense.id)) {
        result.push(expense.id);
      }
      return result;
    }, []);

    return this.http.post<void>(`${this.serviceBaseUrl}${
      this.apiPath.disableCostAdjustment.replace(this.apiPathParam.companyId, String(companyId))
    }`, programIds);
  }
}
