<div class="editable-content-control"
     (click)="handleFocus();"
     (mouseenter)="handleMouseEnter()"
     (mouseleave)="handleMouseLeave()"
     [ngClass]="{
    'edit-mode': editMode && !isReadOnly,
    'is-readonly': isReadOnly,
    'is-empty': !value?.length,
    'has-errors': hasErrors,
    'has-prefix': prefix,
    'is-number': isNumber,
    'highlighted': highlighted
  }"
     floatingErrorMessage
     [errorMessage]="isHovered && hasErrors ? errorMessage : null"
>
  <span *ngIf="prefix" class="prefix">{{ prefix }}</span>
  <ng-container *ngIf="isNumber; else textInput">
    <input
      class="hide-arrows"
      blurOnEnterKeydown
      currencyMask
      [options]="currencyMaskOptions"
      [selectTextOnFocus]="selectOnFocus"
      [placeholder]="placeholder"
      [formControl]="control"
      [ngModel]="modelValue"
      (blur)="handleBlur()"
      #controlInput
    >
  </ng-container>
  <div
    class="read-value"
    *ngIf="isReadOnly || !editMode"
  >
    <plc-animated-number [ngClass]="utClasses[placeholder]" *ngIf="numberFormat" [value]="value !== null ? +value : null" [numberFormat]="numberFormat"></plc-animated-number>
    <span *ngIf="!numberFormat">{{ value || placeholder }}</span>
  </div>
</div>

<ng-template #textInput>
   <textarea
     textFieldAutosize
     minRows="1"
     blurOnEnterKeydown
     [placeholder]="placeholder"
     [maxlength]="maxLength"
     [maxHeight]="28"
     [minHeight]="14"
     [formControl]="control"
     (blur)="handleBlur()"
     #controlInput
   ></textarea>
</ng-template>
