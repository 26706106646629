<div class="modal-window">
    <header class="modal-window-header">
        <h3 class="modal-window-title">{{ title }}</h3>
        <button class="close-modal" (click)="close.emit()">&times;</button>
    </header>

    <section class="modal-window-content">
      <ng-content></ng-content>
    </section>
  </div>
