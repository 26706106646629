import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions, withAPIV0ErrorHandling } from 'app/shared/utils/http-request.utils';
import { API_V0_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { Observable } from 'rxjs';
import { SuperAdminCompaniesResponse } from '@shared/types/company.interface';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';

@Injectable()
export class SuperadminService {
  private readonly actionUrl = inject(API_V0_URL);
  private readonly http = inject(HttpClient);

  getSuperadminList(): Observable<ApiV0Response<object[]>> {
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<object[]>>(this.actionUrl + 'super-admin-users/')
    );
  }

  getSuperadminDetails(id: string): Observable<ApiV0Response<object>> {
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<object>>(this.actionUrl + 'super-admin-users/' + id + '/')
    );
  }

  saveSuperadmin(data: object, id: string): Observable<ApiV0Response<object>> {
    const request =
      id ?
        this.http.put<ApiV0Response<object>>(this.actionUrl + 'super-admin-users/' + id + '/', JSON.stringify(data)) :
        this.http.post<ApiV0Response<object>>(this.actionUrl + 'super-admin-users/', JSON.stringify(data));

    return withAPIV0ErrorHandling(request);
  }

  deleteSuperadmin(id): Observable<ApiV0Response<void>> {
    return withAPIV0ErrorHandling(
      this.http.delete<ApiV0Response<void>>(this.actionUrl + 'super-admin-users/' + id + '/')
    );
  }

  getSuperadminLogs(): Observable<ApiV0Response<object[]>> {
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<object[]>>(this.actionUrl + 'audit/superadmin/')
    );
  }

  getCompaniesListForSuperAdmin(data: object, offset: number, batchSize: number): Observable<SuperAdminCompaniesResponse> {
    const params = { ...data, offset, limit: batchSize };
    return withAPIV0ErrorHandling(
      this.http.get<SuperAdminCompaniesResponse>(this.actionUrl + 'company/superadmin/', getRequestOptions(params))
    );
  }
}
