import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const routeAnimation =
  trigger('routeAnimations', [
    transition('* => openDetails', openDetailsPage() ),
    transition('openDetails => *', closeDetailsPage() ),
    transition('openInvoiceReview => *', closeInvoicePage())
  ]);

function openDetailsPage() {
  const optional = { optional: true };
  return [
    query(':enter', [
      style({
        transform: 'translateX(100%)',
        opacity: '0'
      })
    ], optional),
    group([
      query(':enter', [
        animate('350ms 450ms cubic-bezier(.6, 1, .6, 1)', style({ transform: 'translateX(0%)', opacity: '1' }))
      ])
    ]),
  ];
}

function closeDetailsPage() {
  const optional = { optional: true };
  return [
    query(':leave', [
      style({
        transform: 'translateX(0%)',
        opacity: '1'
      })
    ], optional),
    group([
      query(':leave', [
        animate('350ms cubic-bezier(.6, 1, .6, 1)', style({ transform: 'translateX(100%)', opacity: '.4' }))
      ])
    ]),
  ];
}

function closeInvoicePage() {
  const optional = { optional: true };
  return [
    query(':leave', [
      style({
        zIndex: '300',
        position: 'absolute',
        top: '0',
        bottom: '0',
        right: '0',
        opacity: '1',
        left: '0'
      })
    ], optional),
    group([
      query(':leave', [
        animate('.3s linear', style({ opacity: '0'}))
      ])
    ]),
  ];
}
