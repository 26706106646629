import { ChangeDetectorRef } from '@angular/core';
import { finalize, map, takeUntil, tap } from 'rxjs/operators';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { ExpensesService } from '@shared/services/backend/expenses.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ExpenseDO } from '@shared/types/expense.interface';
import { ExpensesSummaryGroup } from '@shared/types/expenses-summary.type';
import { SpendingMiniDashSummaryService } from '@spending/services/spending-mini-dash-summary.service';
import { HierarchyViewMode, LocalSpendingPageFilters, SpendingManagementMode } from '@spending/types/expense-page.type';
import { SpendingLocalFiltersService } from '@spending/services/spending-local-filters.service';
import { SpendingService } from '@spending/services/spending.service';
import { ExpensesQueryService } from '@spending/services/expenses-query.service';
import { ExpensePageSelectionService } from '@spending/services/expense-page-selection.service';
import { ExportDataService } from '../../dashboard/export-data.service';
import { UtilityService } from '@shared/services/utility.service';

export abstract class SpendingPageBaseService {
  protected destroy$ = new Subject<void>();

  protected constructor(
    private readonly _budgetDataService: BudgetDataService,
    public readonly _expensesService: ExpensesService,
    public readonly _spendingService: SpendingService,
    private readonly _spendingLocalFiltersService: SpendingLocalFiltersService,
    private readonly _spendingMiniDashSummaryService: SpendingMiniDashSummaryService,
    private readonly _expensesQueryService: ExpensesQueryService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly tableDataService,
    private readonly _expensePageSelectionService: ExpensePageSelectionService,
    public readonly utilityService: UtilityService
  ) {
  }

  public getExpenseChunk(): Observable<ExpenseDO[]> {
    const localParams = this._spendingLocalFiltersService.localFilters;
    this._spendingService.tableDataLoading$.next(true);
    return this._expensesService.loadExpensesChunk(
      this._spendingService.getExpenseBasePayload(this.invoiceMode),
      localParams.limit,
      localParams.offset
    ).pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this._spendingService.tableDataLoading$.next(false);
        }),
        map(expenseList => expenseList.map(expense => ({
          ...expense,
          source_actual_amount: Math.round(expense.source_actual_amount * 100) / 100,
          source_amount: Math.round(expense.source_amount * 100) / 100,
        }))),
        tap(expenses => {
          this.checkPagination(expenses.length, localParams);
          this.tableDataService.createRows(expenses);
          this._expensePageSelectionService.checkSelection(this.tableDataService.rows);
          this._cdr.markForCheck();
        })
      );
  }

  protected checkPagination(recordsLength: number, localParams: LocalSpendingPageFilters): void {
    if (!recordsLength && localParams.offset > 0) {
      const updatedPageParams = {
        offset: localParams.offset >= localParams.limit ? localParams.offset - localParams.limit : 0,
        page: localParams.page >= 1 ? localParams.page - 1 : 0,
      };
      this._spendingLocalFiltersService.updateParams(updatedPageParams);
    }
  }

  public resetData(): void {
    this._spendingService.onExpensesAmountLoaded(ExpensesSummaryGroup.Filtered, null);
    this.tableDataService.createRows(null);
    this._cdr.markForCheck();
  }

  protected updateMiniDashTotals(): void {
    forkJoin([
      this._spendingService.getGrandTotal(),
      this._spendingService.getViewedTotal()
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this._cdr.markForCheck();
      });
  }

  protected get isViewBySegment(): boolean {
    return this._spendingService.sideBarHierarchyMode === HierarchyViewMode.Segment;
  }

  exportExpensesToCSV(invoicesOnly: boolean): void {
    this.utilityService.showLoading(true);
    this._expensesService.exportBudgetExpensesCSV(this._spendingService.getExpenseBasePayload(invoicesOnly)).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (res: Response) => ExportDataService.downloadCsv(res, invoicesOnly ? 'invoices.csv' : 'expenses.csv'),
      error => this.utilityService.handleError(error),
      () => this.utilityService.showLoading(false)
    );
  }

  private get invoiceMode(): boolean {
    return this._spendingService.spendingManagementMode === SpendingManagementMode.Invoices;
  }
}
