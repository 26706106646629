<widget-card [isLoading]="state === widgetState.LOADING">
  <tasks-list
    [owners]="[currentCompanyUser]"
    [currentCompanyUser]="currentCompanyUser"
    [tasks]="tasks"
    [budgetTodayDate]="budgetTodayDate"
    [isLoading]="state === widgetState.LOADING"
    [isReadOnlyMode]="false"
    [isPersonalMode]="true"
    (onDataChanged)="handleTasksUpdate($event)"
    title="Manage my open tasks"
    #tasksList
  ></tasks-list>
</widget-card>
