import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-lock',
  templateUrl: './icon-lock.component.html',
  styleUrls: ['./icon-lock.component.scss']
})

/**
* @class - IconLockComponent
*/
export class IconLockComponent {
  @Input() size = 20;
  @Input() color = '#c6c6c6';
  @Input() textTooltip: string;
}
