import {
  BudgetAllocationAction,
  BudgetAllocationActionParams
} from '../../budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';
import { BulkActionTargets } from '@shared/types/bulk-action-targets.type';
import { BudgetObjectSegmentData } from '@shared/types/budget-object-segment-data.interface';
import { ManageTableDataService } from '../../manage-table/services/manage-table-data.service';
import { ManageTableBulkSegmentData } from '../../manage-table/types/manage-table-bulk-data-map.types';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageTableDataMutationService } from '../../manage-table/services/manage-table-data-mutation.service';
import { ManageCegTableDataMutationService } from '@manage-ceg/services/manage-ceg-table-data-mutation.service';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';


interface BasicActionParams<TDataService, TMutationService> extends BudgetAllocationActionParams {
  executeResultHandler: (results: any, childrenSegmentInheritance: boolean) => void;
  undoResultHandler: (results: any) => void;
  bulkTargets: BulkActionTargets;
  dataService: TDataService;
  mutationService: TMutationService;
  segmentData: BudgetObjectSegmentData;
}

export class ManageTableChangeSegmentAction<
    TDataService extends ManageTableDataService | ManageCegTableDataService,
    TMutationService extends ManageTableDataMutationService | ManageCegTableDataMutationService>
    extends BudgetAllocationAction<BasicActionParams<TDataService, TMutationService>
  > {
  private initialSegments: ManageTableBulkSegmentData = {
    campaigns: [],
    expGroups: []
  };

  constructor(params: BasicActionParams<TDataService, TMutationService>) {
    super();
    this.actionParams = params;
    this.prepareActionData();
  }

  private prepareActionData() {
    const { bulkTargets, dataService } = this.actionParams;
    const { campaigns, expGroups } = bulkTargets;

    const getSegmentData = (objectId: number, objectType: ManageTableRowType) => {
      const targetObject = dataService.getRecordByIdAndType(objectType, objectId);

      return {
        budgetSegmentId: targetObject?.segmentId || null,
        sharedCostRuleId: targetObject?.sharedCostRuleId || null,
      };
    };

    this.initialSegments = {
      campaigns: (campaigns || []).map(campaignId => ({
        id: campaignId,
        data: getSegmentData(campaignId, ManageTableRowType.Campaign)
      })),
      expGroups: (expGroups || []).map(expGroupId => ({
        id: expGroupId,
        data: getSegmentData(expGroupId, ManageTableRowType.ExpenseGroup)
      })),
    };
  }

  public execute(): void {
    const { mutationService, segmentData, bulkTargets, executeResultHandler } = this.actionParams;

    mutationService.updateSegmentData(bulkTargets, segmentData, (results, childrenSegmentInheritance = false) => {
      if (executeResultHandler) {
        executeResultHandler(results, childrenSegmentInheritance);
      }
    });
  }

  public undo(): void {
    const { undoResultHandler, mutationService } = this.actionParams;
    mutationService.undoSegmentDataUpdate(this.initialSegments, results => undoResultHandler?.(results));
  }
}
