<section class="allocations-table-container">
  <div class="allocations-table">
    <div class="table-head">
      <div class="table-row title-row">
        <div class="table-cell"></div>
        <div class="table-cell" *ngFor="let columnName of columnsOrdering" [ngClass]="columns[columnName]?.className">
          <span class="column-title">
            {{ columns[columnName]?.title }}
          </span>
        </div>
      </div>
      <ng-template [ngIf]="subtitles && subtitles.length">
        <div class="table-row subtitle-row" *ngFor="let subtitle of subtitles">
          <div class="table-cell"></div>
          <div class="table-cell" *ngFor="let columnName of columnsOrdering" [ngClass]="columns[columnName]?.className">
            <div
              [matTooltip]="subtitle.data[columnName]?.tooltip"
              [matTooltipDisabled]="!subtitle.data[columnName]?.tooltip"
              matTooltipClass="dark-theme-tooltip simple"
              matTooltipPosition="above">
              <span class="column-subtitle" [attr.data-subtitle]="subtitle.title || ''" [ngClass]="subtitle.data[columnName]?.utClassName">
                <budget-allocation-cell
                *ngIf="columns[columnName].type === columnTypes.AllocatedBudget; else defaultSubtitle"
                class="dense"
                [allocated]="subtitle.data[columnName].value"
                [spent]="subtitle.data[columnName].spentValue || 0"
                [editable]="subtitle.data[columnName].isEditable"
                [allowGestures]="subtitle.data[columnName].isEditable"
                [disabled]="false"
                [showDifference]="true"
                [globalDragStarted]="false"
                [hasRemainingBudget]="true"
                [allowDnd]="false"
                (onChange)="handleSubtitleValueChange(columnName, $event)"
                (onDoubleClick)="handleSubtitleDoubleClick(columnName, subtitle.data[columnName].value, $event)"
                (onDrop)="null"
                (onDragStart)="null"
                (onDragEnd)="null"
                (onFocus)="handleOnFocus($event)"
              >
              </budget-allocation-cell>
                <ng-template #defaultSubtitle>
                  {{ subtitle.data[columnName]?.value | number:'1.2-2' || '' }}
                </ng-template>
              </span>
            </div>
          </div>
        </div>
     </ng-template>
    </div>
    <ng-template ngFor let-rowName [ngForOf]="rows" [ngForTrackBy]="trackByAllocationName">
      <div *ngIf="allocationsData[rowName]" class="table-row table-content">
        <div class="table-cell row-title">
          <span>{{ rowName }}</span>
        </div>
        <div
          [matTooltip]="cell.tooltip"
          [matTooltipDisabled]="!cell.tooltip"
          matTooltipClass="dark-theme-tooltip simple"
          matTooltipPosition="above"
          *ngFor="let cell of allocationsData[rowName].data; trackBy: trackByColumnName">
          <div class="table-cell"
               [ngClass]="columns[cell.column].className"
               [class.is-disabled]="cell.disabled">
            <span *ngIf="columns[cell.column].type === columnTypes.PlainText; else editableField" class="cell-text d-flex ai-center jc-end" [ngClass]="cell.utClassName">
            {{ cell.value | number:'1.2-2' }}
              <span *ngIf="cell.value < 0" class="warning-icon">
                <icon-flame></icon-flame>
              </span>
            </span>
            <ng-template #editableField>
              <ng-container *ngIf="!cell.hidden">
                <budget-allocation-cell
                  *ngIf="columns[cell.column].type === columnTypes.AllocatedBudget; else notAllocatedBudgetField"
                  [allocated]="cell.value"
                  [spent]="cell.spentValue"
                  [editable]="true"
                  [disabled]="cell.disabled"
                  [showDifference]="true"
                  [allowGestures]="!cell.disabled"
                  [globalDragStarted]="isGlobalDragStarted"
                  [hasRemainingBudget]="true"
                  (onChange)="handleAllocatedAmountChange($event, { rowName: rowName, cell: cell })"
                  (onDoubleClick)="handleDoubleClick({ rowName: rowName, cell: cell }, $event)"
                  (onDrop)="handleOnDrop({ rowName: rowName, cell: cell }, $event)"
                  (onDragStart)="handleOnDragStart({ rowName: rowName, cell: cell }, $event)"
                  (onDragEnd)="handleOnDragEnd()"
                  (onFocus)="handleOnFocus($event)"
                >
                </budget-allocation-cell>
                <ng-template #notAllocatedBudgetField>
                  <mat-form-field appearance="fill" class="no-validate no-label">
                    <input
                      *ngIf="columns[cell.column].type === columnTypes.Money"
                      matInput
                      maxlength="15"
                      currencyInput
                      currencyMask
                      firstZeroHide
                      [selectTextOnFocus]="true"
                      blurOnEnterKeydown
                      [ngClass]="cell.utClassName"
                      [disabled]="cell.disabled"
                      [options]="currencyMaskOptions"
                      [(ngModel)]="cell.value"
                      (blur)="onDataChange(rowName, cell)"
                    >

                    <mat-select
                      *ngIf="columns[cell.column].type === columnTypes.Select"
                      disableOptionCentering
                      [ngClass]="cell.utClassName"
                      [disabled]="cell.disabled"
                      [(ngModel)]="cell.value"
                      (ngModelChange)="onDataChange(rowName, cell)"
                      panelClass="mat-alloc-select-fixed-position"
                    >
                      <ng-template [ngIf]="cell.providedOptions">
                        <mat-option *ngFor="let item of cell.providedOptions" [value]="item.value" [class.hidden]="item.hidden">
                          {{item.title}}
                        </mat-option>
                      </ng-template>
                      <ng-template [ngIf]="!cell.providedOptions">
                        <mat-option *ngFor="let item of columns[cell.column].options" [value]="item.value">
                          {{item.title}}
                        </mat-option>
                      </ng-template>
                    </mat-select>
                  </mat-form-field>
                </ng-template>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</section>
