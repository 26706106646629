<mat-menu #menu class="mat-pl-menu navigation-menu action-menu-panel">
  <ng-container *ngFor="let action of actionItems; let i = index">
    <ng-container *ngIf="!action.hidden">
      <button
        *ngIf="isSingleSelect(action); else directAction"
        mat-menu-item
        [disabled]="action.disabled"
        [matMenuTriggerFor]="subMenu"
      >
        <span class="mr-1">{{ action.name }}</span>
        <fa-icon class="ml-a" [icon]="['far', 'chevron-right']"></fa-icon>
      </button>
      <ng-template #directAction>
        <button
          mat-menu-item
          [disabled]="action.disabled"
          (click)="applyDirectAction(action)"
          [ngClass]="action.customCssClass"
        >
          <span>{{ action.name }}</span>
        </button>
      </ng-template>

      <mat-menu #subMenu xPosition="before" class="mat-pl-menu navigation-menu action-submenu-panel">
        <button
          *ngFor="let selectOption of action.handlingConfig?.selectOptions"
          mat-menu-item
          [ngClass]="action.customCssClass"
          (click)="selectOptionValue(action, selectOption)"
        >
          <span *ngIf="action.actionType === ExpenseActionType.ChangeStatus"
                [class]="'status-dot ' + selectOption.cssClass"></span>
          <span>{{ selectOption.title }}</span>
        </button>
      </mat-menu>

    </ng-container>
  </ng-container>
</mat-menu>
