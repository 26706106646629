import { Injectable } from '@angular/core';
import { ExternalMetricTypesService } from '../services/external-metric-types-service';
import { ExternalMetricType } from '../types/external-metrics.types';
import { HubspotDataService } from './hubspot-data.service';
import { HubspotMetricTypeNames } from './hubspot.types';

@Injectable({
  providedIn: 'root'
})
export class HubspotMetricTypesService extends ExternalMetricTypesService {
  externalMetricTypes = [
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.EmailsBounced,
      label: 'Emails Bounced',
      description: 'Number of emails that bounced from this campaign'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.EmailsOpened,
      label: 'Emails Opened',
      description: 'Number of emails opened in this campaign'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.EmailsReportedAsSpam,
      label: 'Emails Reported as Spam',
      description: 'Number of emails reported as spam in this campaign'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.EmailsSent,
      label: 'Emails Sent',
      description: 'Number of emails sent in this campaign'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.EmailsUnsubscribed,
      label: 'Emails Unsubscribed',
      description: 'Number of emails sent that resulted in an unsubscribe request in this campaign'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.EmailsWithClickThrough,
      label: 'Emails with Click-Through',
      description: 'Number of emails with a click-through in this campaign'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.FormInteractions,
      label: 'Form Interactions',
      description: 'Number of interactions with the form'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.FormSubmissions,
      label: 'Form Submissions',
      description: 'Number of times the form was submitted'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.FormViews,
      label: 'Form Views',
      description: 'Number of times the form was visible on the web page'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignContacts,
      label: 'UTM-Campaign Contacts'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignCustomers,
      label: 'UTM-Campaign Customers'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignLeads,
      label: 'UTM-Campaign Leads'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignMarketingQualifiedLeads,
      label: 'UTM-Campaign Marketing Qualified Leads'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignVisitors,
      label: 'UTM-Campaign New Visitor Sessions'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignOpportunities,
      label: 'UTM-Campaign Opportunities'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignSalesQualifiedLeads,
      label: 'UTM-Campaign Sales Qualified Leads'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignVisits,
      label: 'UTM-Campaign Sessions'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignSubscribers,
      label: 'UTM-Campaign Subscribers'
    },
    {
      type: ExternalMetricType.int,
      name: HubspotMetricTypeNames.UtmCampaignRawViews,
      label: 'UTM-Campaign Views'
    },
  ];
  defaultMapping = {
    [HubspotMetricTypeNames.EmailsWithClickThrough]: 'Leads',
    [HubspotMetricTypeNames.FormSubmissions]: 'Leads',
    [HubspotMetricTypeNames.UtmCampaignLeads]: 'Leads',
    [HubspotMetricTypeNames.UtmCampaignOpportunities]: 'Opportunities'
  };

  constructor(
    private readonly hubspotDataService: HubspotDataService
  ) {
    super(hubspotDataService);
  }
}
