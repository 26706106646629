import { Component, Input } from '@angular/core';

@Component({
  selector: 'product-budget-summary',
  templateUrl: './product-budget-summary.component.html',
  styleUrls: ['./product-budget-summary.component.scss']
})
export class ProductBudgetSummaryComponent {
  @Input() currency: string;
  @Input() revenueTarget = 0;
  @Input() budget = 0;
  public numberFormat = '1.2-2';
}
