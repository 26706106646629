import { Pipe, PipeTransform } from '@angular/core';
import { SharedIconState } from '../components/icons/icon-shared/shared.type';

@Pipe({
  name: 'sharedCostState'
})
export class SharedCostStateValuePipe implements PipeTransform {
  transform(ruleId: number = null, parentObject: any, childObjects: any[], field: string = 'split_rule'): any {
    const differentSplitRule = parentObject
      ? parentObject[field] !== ruleId
      : childObjects.some(object => {
        // Needed to avoid undefined value
        const objectSplitRuleId = object[field] || object.expense_split_rule_id || null;
        return objectSplitRuleId !== ruleId;
      });
    let state = SharedIconState.Empty;
    if (ruleId && differentSplitRule) {
      state = parentObject ? SharedIconState.Exception : SharedIconState.Mixed;
    } else if (ruleId) {
      state = SharedIconState.Shared;
    }
    return state;
  }
}
