import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { CurrencyMaskDirective, CurrencyMaskModule } from 'ngx-currency-mask';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from 'ngx-currency-mask/src/currency-mask.config';
import { ConversionRateComponent } from './corporate-page/components/conversion-rate/conversion-rate.component';
import { MetricMasterComponent } from './corporate-page/components/metric-master/metric-master.component';
import { EditableValueComponent } from './corporate-page/components/editable-value/editable-value.component';
import { AnimatedNumberComponent } from './corporate-page/components/animated-number/animated-number.component';
import { BlurOnEnterKeydownDirective } from './directives/blur-on-enter-keydown.directive';
import { TextfieldAutosizeDirective } from './directives/textfield-autosize.directive';
import { FloatingErrorMessageDirective } from './directives/floating-error-message.directive';
import { ConnectedArrowComponent } from './corporate-page/components/connected-arrow/connected-arrow.component';
import { FloatErrorMessageComponent } from './corporate-page/components/float-error-message/float-error-message.component';
import { SelectTextOnFocusDirective } from './directives/select-text-on-focus.directive';
import { ButtonComponent } from './corporate-page/components/button/button.component';
import { LogoComponent } from './corporate-page/components/logo/logo.component';
import { PlcPageHeaderComponent } from './corporate-page/components/plc-page-header/plc-page-header.component';
import { PlcNumericInputComponent } from './corporate-page/components/plc-numeric-input/plc-numeric-input.component';
import { CustomToastrComponent } from './corporate-page/components/custom-toastr/custom-toastr.component';
import { SingularNounPipe } from './pipes/singular-noun.pipe';
import { IconCrossComponent } from './corporate-page/components/icon-cross/icon-cross.component';
import { LandingPageLogoComponent } from './corporate-page/components/landing-page-logo/landing-page-logo.component';
import { DatePickerComponent } from './common-components/datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

/**
 *@desc - Overwrite HandleKeyPress Method from CurrencyMask Directive for Mac OS
 */
CurrencyMaskDirective.prototype.handleKeypress = function(event) {
  if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
    if ((event.keyCode === 99 || event.keyCode === 118 || event.keyCode === 120) && (event.ctrlKey || event.metaKey)) {
      this.inputHandler.handleKeydown(event);
    } else {
      this.inputHandler.handleKeypress(event);
    }
  } else if (!this.isChromeAndroid()) {
    this.inputHandler.handleKeypress(event);
  }
};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  prefix: '',
  thousands: ',',
  decimal: '',
  allowZero: false,
  precision: 0,
  allowNegative: false,
  suffix: ''
};

const requiredComponents = [
  MetricMasterComponent,
  ConversionRateComponent,
  EditableValueComponent,
  AnimatedNumberComponent,
  ConnectedArrowComponent,
  FloatErrorMessageComponent,
  ButtonComponent,
  LogoComponent,
  LandingPageLogoComponent,
  PlcPageHeaderComponent,
  PlcNumericInputComponent,
  CustomToastrComponent,
  IconCrossComponent,
  DatePickerComponent
];

const requiredDirectives = [
  BlurOnEnterKeydownDirective,
  TextfieldAutosizeDirective,
  FloatingErrorMessageDirective,
  SelectTextOnFocusDirective,
];

const requiredPipes = [
  SingularNounPipe
];

const requiredModules = [
  FormsModule,
  ReactiveFormsModule,
  MatTooltipModule,
  FontAwesomeModule,
  MatButtonModule,
  MatMenuModule,
  CurrencyMaskModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule ,
  MatInputModule 
];

@NgModule({
  declarations: [
    ...requiredComponents,
    ...requiredDirectives,
    ...requiredPipes,
    LandingPageLogoComponent,
  ],
  imports: [
    CommonModule,
    ...requiredModules,
  ],
  exports: [
    ...requiredModules,
    ...requiredComponents,
    ...requiredDirectives,
    ...requiredPipes,
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ]
})

export class PlcLibraryModule { }
