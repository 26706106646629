<div class="data-specification">
  <header mat-dialog-title>{{ data?.title }}</header>
  <div mat-dialog-content>
    <select-option
      class="select-all"
      [title]="'Select all'"
      [isGroupOption]="true"
      [isChecked]="allSelectionState === CheckboxValue.Active"
      [indeterminate]="allSelectionState === CheckboxValue.Indeterminate"
      (selectionChanged)="toggleAllSelection($event)"
    ></select-option>
    <hr />
    <ng-container *ngFor="let option of specificationData.budgetData">
      <!--  hierarchy -->
      <ng-container *ngIf="option.children?.length">
        <select-option
          class="select-group"
          [title]="option.title"
          [isGroupOption]="true"
          [isChecked]="allHierarchySelectionState === CheckboxValue.Active"
          [indeterminate]="allHierarchySelectionState === CheckboxValue.Indeterminate"
          (selectionChanged)="toggleGroupSelection($event, option.children)"
        ></select-option>
        <ul class="budget-hierarchy-list">
          <li *ngFor="let childOption of option.children">
            <select-option
              class="group-option"
              [ngClass]="{ 'active': childOption.value }"
              [title]="childOption.title"
              [isChecked]="childOption.value"
              (selectionChanged)="toggleItemSelection($event, childOption, option.children)"
            ></select-option>
          </li>
        </ul>
      </ng-container>

      <select-option
        class="option-item"
        *ngIf="!option.children?.length"
        [isHidden]="option.hidden"
        [title]="option.title"
        [isChecked]="option.value"
        [disabled]="option.disabled"
        (selectionChanged)="toggleItemSelection($event, option)"
      ></select-option>

    </ng-container>

    <div class="group">
      <ng-container *ngFor="let option of specificationData.hierarchyGroup">
        <select-option
          class="option-item"
          [class.has-indent]="!option?.hasGroup"
          [ngClass]="option?.class"
          [attr.title]="option?.alt"
          [isHidden]="option.hidden"
          [title]="option.title"
          [isChecked]="option.value"
          [disabled]="isParentChecked(option.parent)"
          (selectionChanged)="toggleItemSelection($event, option)"
        ></select-option>
      </ng-container>
    </div>

    <p>Setup budget-level permissions</p>

    <mat-radio-group
      class="column mb-1"
      [(ngModel)]="budgetPermission">
      <mat-radio-button
        color="primary"
        *ngFor="let permission of specificationData?.permissionLevel"
        [value]="permission.value">
        {{ permission.title }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button
      class="cancel-button"
      [mat-dialog-close]="false"
      mat-button
      color="primary"
    >
      {{ data?.cancelAction.label }}
    </button>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      [disabled]="!selectedOptionsCount"
      (click)="data.actions[1].handler(getSelectedOptions())"
    >
      {{ data?.submitAction.label }}
    </button>
  </div>
</div>
