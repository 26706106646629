import { Component, Input } from '@angular/core';

@Component({
  selector: 'object-name',
  templateUrl: './object-name.component.html',
  styleUrls: ['./object-name.component.scss']
})
export class ObjectNameComponent {
  @Input() name: string;
  @Input() linesTotal = 2;
  @Input() showIcon = true;
  @Input() closed = false;
  @Input() objectType: string;
  @Input() iconSize = 17;
  @Input() flipIcon = false;
}
