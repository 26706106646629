import { Component } from '@angular/core';

@Component({
  selector: 'icon-search',
  templateUrl: './icon-search.component.html',
  styleUrls: ['./icon-search.component.scss']
})

export class IconSearchComponent {
}
