import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'app/shared/shared.module';
import { BudgetAllocationCellComponent } from './components/budget-allocation-cell/budget-allocation-cell.component';
import { BudgetAllocationActionTooltipComponent } from './components/budget-allocation-action-tooltip/budget-allocation-action-tooltip.component';
import { BudgetAllocationActionTooltipDirective } from './directives/budget-allocation-action-tooltip.directive';
import { ObjectNameCellComponent } from './components/object-name-cell/object-name-cell.component';
import { ObjectNameInputComponent } from './components/object-name-input/object-name-input.component';

@NgModule({
  declarations: [
    BudgetAllocationCellComponent,
    BudgetAllocationActionTooltipComponent,
    BudgetAllocationActionTooltipDirective,
    ObjectNameCellComponent,
    ObjectNameInputComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatTooltipModule,
    FontAwesomeModule
  ],
  exports: [
    BudgetAllocationCellComponent,
    BudgetAllocationActionTooltipComponent,
    BudgetAllocationActionTooltipDirective,
    ObjectNameCellComponent,
    ObjectNameInputComponent
  ]
})
export class BudgetAllocationModule {}
