import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { SharedCostRulesManagementComponent } from './shared-cost-rules-management/shared-cost-rules-management.component';
import { SharedCostRuleComponent } from './shared-cost-rule/shared-cost-rule.component';
import { SharedCostRuleTipsComponent } from './shared-cost-rule-tips/shared-cost-rule-tips.component';


@NgModule({
  declarations: [
    SharedCostRulesManagementComponent,
    SharedCostRuleComponent,
    SharedCostRuleTipsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
  ],
  exports: [
    SharedCostRulesManagementComponent
  ],
})
export class SharedCostRulesManagementModule {}
