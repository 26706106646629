<div class="task-status">
  <flat-select
    [options]="processedOptions"
    [value]="value"
    [isReadOnly]="isReadOnly"
    panelClass="task-status"
    (onValueChanged)="handleValueChanged($event)"
  >
    <ng-container slot="trigger" #triggerChild>
      <span class="status-value" [ngClass]="('state--' + value?.name)">
        {{ labelMap[value?.name] }}
      </span>
    </ng-container>
  </flat-select>
</div>
