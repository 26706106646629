import { Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { MenuPanelItem } from './menu-panel.type';
import { AbstractCustomMenuComponent } from './abstract-custom-menu.component';

@Component({
  selector: 'menu-panel',
  styleUrls: ['./menu-panel.component.scss'],
  templateUrl: './menu-panel.component.html',
})
export class MenuPanelComponent {
  @ViewChild('menu', { static: true }) menu: MatMenu;
  @ContentChild('iconsTemplate', { static: false }) iconsTemplateRef: TemplateRef<any>;
  @ContentChild(AbstractCustomMenuComponent) customMenuRef!: AbstractCustomMenuComponent;

  @Input() items: MenuPanelItem[];
  @Input() backdropClass = '';
  @Input() panelClass = '';
}
