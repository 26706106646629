<mat-form-field
  appearance="outline"
  [ngClass]="{'filled': searchFilterControl.value.length}"
  class="outline no-label no-validate">
  <fa-duotone-icon
    matPrefix
    [icon]="['fad', 'search']"></fa-duotone-icon>
  <input type="text"
         matInput
         [formControl]="searchFilterControl"
         [placeholder]="placeholder"
         (keydown.space)="$event.stopPropagation()"
         (keydown.enter)="$event.stopPropagation()">
  <button *ngIf="searchFilterControl.value.length"
          mat-icon-button
          matSuffix
          (click)="clearSearchText($event)">
    <fa-duotone-icon  [icon]="['fad', 'times-circle']"></fa-duotone-icon>
  </button>
</mat-form-field>
