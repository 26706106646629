import { Directive, Input, OnInit, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[scrollOverlap]'
})
export class ScrollOverlapDirective implements OnInit {
  @Input() container: Element;
  @Input() trigger: ElementRef;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }
  ngOnInit() {
    if (this.container && this.trigger) {
      const { left: containerLeft, top: containerTop } = this.container.getBoundingClientRect();
      const { left, bottom } = this.trigger.nativeElement.getBoundingClientRect();
      const topOffset = 2;
      const coords = {
        top: Math.round(bottom - containerTop) + topOffset + 'px',
        left: Math.round(left - containerLeft) + 'px'
      };
      this.renderer.setStyle(this.el.nativeElement, 'top', coords.top)
      this.renderer.setStyle(this.el.nativeElement, 'left', coords.left)
    }
  }
}
