<div class="actions">
  <button mat-button color="primary" (click)="cancel.emit()">{{isReadOnlyMode ? 'Close' : 'Cancel'}}</button>
  <ng-container *ngIf="isDrawer; else commonButtons">
    <ng-container *ngIf="!isReadOnlyMode">
      <button mat-flat-button
              class="menu-button"
              color="primary"
              [disabled]="disableSaveAndCloseAction || disableSaveAndCloseButton"
              (click)="onHandleMenuClick($event)"
            >
        Save & Close
        <div class="chevron-holder"
          [matMenuTriggerFor]="buttonsMenu">
          <fa-icon class="chevron-icon" [icon]="['fas', 'chevron-down']"></fa-icon>
        </div>
      </button>
    </ng-container>
  </ng-container>
  <ng-template #commonButtons>
    <button mat-stroked-button color="primary" [disabled]="isReadOnlyMode || !hasUnsavedChanges" (click)="save.emit()">Save</button>
    <button *ngIf="showSaveAndNewButton" mat-stroked-button color="primary" [disabled]="isReadOnlyMode" (click)="saveAndNew.emit()">Save & New</button>
    <button mat-flat-button color="primary" [disabled]="isReadOnlyMode" (click)="saveAndClose.emit()">Save & Close</button>
  </ng-template>
</div>
<mat-menu #buttonsMenu="matMenu" class="mat-pl-menu no-padding action-buttons-menu">
  <button *ngIf="showSaveAndCreateButton" mat-menu-item color="primary" [disabled]="isReadOnlyMode" (click)="saveAndNew.emit()">Save & Create New {{ objectLabel }}</button>
  <button mat-menu-item color="primary" [disabled]="(isReadOnlyMode || !hasUnsavedChanges)" (click)="save.emit()">Save Only</button>
</mat-menu>
