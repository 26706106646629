import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Configuration } from 'app/app.constants';
import { MetricValueUpdateItem, MetricValueUpdateType } from 'app/budget-object-details/types/metric-value-update-item.interface';
import { createDateString } from '../../../containers/campaign-details/date-operations';
import { MetricUpdateEvent } from './metric-value-record.type';
import { MetricMappingCalculationService } from '../../../../services/metric-mapping-calculation.service';
import { isTodayDateCell } from '@shared/utils/date.utils';

@Component({
  selector: 'metric-value-record',
  templateUrl: './metric-value-record.component.html',
  styleUrls: ['./metric-value-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricValueRecordComponent implements OnInit, OnChanges {
  public MetricValueUpdateType = MetricValueUpdateType;
  public amountFieldOpt = { prefix: '', precision: 0, decimal: '.', align: 'right', allowNegative: true };
  public activeCell = '';
  public todayDate = new Date();
  public recordFormData = this.createFormGroup();
  public faActualIcon: IconProp;
  public nameOfEndedMonth: string;
  public hasClickAction = false;

  @Input() recordData: MetricValueUpdateItem;
  @Input() monthExpanded = false;
  @Input() isCreation = false;
  @Input() disabledDates: string[] = [];
  @Input() tooltipText: string;
  @Input() originUpdateType: MetricValueUpdateType;
  @Input() originObjectId: number;
  @Input() isReadOnly = true;
  @Input() displayDecimal = false;
  @Input() budgetTodayDate: Date;
  @Input() showHistoryExpanded: boolean = false;

  @Output() removeRecord = new EventEmitter<MetricValueUpdateItem>();
  @Output() updateRecord = new EventEmitter<MetricUpdateEvent>();
  @Output() toggleMonth = new EventEmitter();
  @Output() onOpenObjectDetails = new EventEmitter<MetricValueUpdateItem>();

  constructor(private fb: UntypedFormBuilder, private config: Configuration, private _ngZone: NgZone) { }

  ngOnInit(): void {
    if (!!this.budgetTodayDate) {
      this.todayDate = this.budgetTodayDate;
    }

    if (!this.isCreation) {
      this.recordFormData.valueChanges.subscribe((eventData) => {
        this.onUpdateRecord(eventData);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const isEditableFlagChanged = changes.recordData && this.recordData &&
      changes.recordData?.previousValue?.isEditable !== this.recordData?.isEditable;

    if (isEditableFlagChanged || changes.isReadOnly) {
      this.setReadonlyMode();
    }
    if (changes.recordData && this.recordData) {
      this.setDataToForm();
      this.setFaActualIcon();
      this.setEndedMonthName();
      this.hasClickAction = !this.isOriginObjectRecord;
    }
    if (changes.displayDecimal) {
      const precision = this.displayDecimal ? 2 : 0;
      this.amountFieldOpt.precision = precision;
    }
  }

  private isRecordEditable(): boolean {
    return !this.isReadOnly && this.recordData.isEditable;
  }

  submitRecordCreation() {
    if (this.isCreation) {
      this.activeCell = '';
      this.onUpdateRecord(this.recordFormData.value)
    }
  }

  onFocusAmountField(field) {
    if (field && this.recordFormData.value[field] == null) {
      this.recordFormData.patchValue({ [field]: 0 })
    }
    this.refreshActiveCell(field, true)
  }

  refreshActiveCell(name, active) {
    if (this.isCreation) {
      if (active) {
        this.activeCell = name;
      } else {
        this._ngZone.runOutsideAngular(() => {
          setTimeout(() =>
            this.activeCell === name
              ? this.submitRecordCreation()
              : null
            , 500)
        })
      }
    }
  }

  createFormGroup() {
    const date = null, changeInValue = null, runningTotal = null, notes = '';
    return this.fb.group({
      date, changeInValue, runningTotal, notes
    }, { updateOn: 'blur' })
  }

  setDataToForm() {
    const { date, changeInValue, runningTotal, notes } = this.recordData;
    this.recordFormData.patchValue(
      { date, changeInValue, runningTotal, notes },
      { onlySelf: true, emitEvent: false }
    );
  }

  setFaActualIcon() {
    if (this.isCreation) {
      this.faActualIcon = ['fad', 'plus-circle'];
    } else {
      const faIconByType = {
        [MetricValueUpdateType.campaign]: ['fad', 'rocket-launch'],
        [MetricValueUpdateType.program]: ['fad', 'briefcase'],
        [MetricValueUpdateType.monthTotal]: ['fad', 'hand-point-right'],
        [MetricValueUpdateType.hubSpot]: ['fab', 'hubspot'],
      };
      this.faActualIcon = faIconByType[this.recordData.type];
    }
  }

  get useDuotoneIcon() {
    const integrationTypes = [
      MetricValueUpdateType.hubSpot,
      MetricValueUpdateType.salesForce,
      MetricValueUpdateType.googleAds,
      MetricValueUpdateType.linkedinAds,
      MetricValueUpdateType.facebookAds,
    ];
    return !integrationTypes.includes(this.recordData.type);
  }

  setEndedMonthName() {
    const monthIndex = new Date(this.recordData.date).getMonth();
    this.nameOfEndedMonth = this.config.full_month[monthIndex];
  }

  onUpdateRecord(eventData) {
    if (eventData.date instanceof Date) {
      eventData.date = createDateString(eventData.date);
    }
    const { date, changeInValue, runningTotal, notes } = this.recordData,
      dateChanged = date !== eventData.date,
      changeInValueChanged = changeInValue !== eventData.changeInValue,
      runningTotalChanged = runningTotal !== eventData.runningTotal,
      notesChanged = notes !== eventData.notes;

    if (!this.isRecordEditable()) {
      return;
    }

    if (dateChanged || changeInValueChanged || runningTotalChanged || notesChanged) {
      this.updateRecord.emit({
        value: { ...this.recordData, ...eventData },
        prevValue: { ...this.recordData }
      })
    }
  }

  openObjectDetailsClick() {
    this.onOpenObjectDetails.emit(this.recordData);
  }

  checkIfDateAvailable = (date: Date) => {
    const dateString = createDateString(date);
    return !this.disabledDates.some(el => el === dateString);
  }

  datepickerClassNames = (cellDate: Date, view: 'multi-year' | 'year' | 'month') => {
    const datepickerClasses = [];
    if (isTodayDateCell(cellDate, this.todayDate, view)) {
      datepickerClasses.push('mat-calendar-budget-today');
    }
    if (!this.checkIfDateAvailable(cellDate)) {
      datepickerClasses.push('visible');
    }

    return datepickerClasses;
  }

  setReadonlyMode() {
    const { changeInValue, runningTotal } = this.recordFormData.controls;
    if (this.isRecordEditable()) {
      changeInValue.enable({ emitEvent: false });
      runningTotal.enable({ emitEvent: false });
    } else {
      changeInValue.disable({ emitEvent: false });
      runningTotal.disable({ emitEvent: false });
    }
  }

  get isMonthTotal() {
    return this.recordData && this.recordData.type === MetricValueUpdateType.monthTotal;
  }

  get isOriginObjectRecord() {
    return this.originObjectId &&
      MetricMappingCalculationService.doesUpdateItemMatchOriginObject(this.recordData, {
        id: this.originObjectId,
        updateType: this.originUpdateType
      });
  }

  get changeInValue() {
    return this.recordFormData.get('changeInValue');
  }

  get runningTotal() {
    return this.recordFormData.get('runningTotal');
  }
}
