import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MetricMilestone, MetricMilestones } from '../../../types/metric-milestone.interface';
import { MetricValueRecords } from '../../../types/metric-value-records.interface';
import { AmchartsService } from 'app/shared/services/amcharts.service';
import { MetricsUtilsService } from '../../../services/metrics-utils.service';
import { parseDateString } from '../../containers/campaign-details/date-operations';

@Component({
  selector: 'metric-target-progress-chart',
  templateUrl: './metric-target-progress-chart.component.html'
})
export class MetricTargetProgressChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() data: MetricValueRecords<number> = [];
  @Input() milestones: MetricMilestones;
  @Input() startDate: string;
  @Input() metricName = '';
  @ViewChild('chartRef', { static: true }) chartRef: ElementRef;

  private chart: any;
  private am4core;
  private am4charts;

  constructor(
    private readonly zone: NgZone,
    private readonly amChartsService: AmchartsService,
    private readonly metricsUtilsService: MetricsUtilsService
  ) {}

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.amChartsService.importModules()
        .then(modules => {
          if (!modules) {
            return;
          }
          this.am4core = modules.am4core;
          this.am4charts = modules.am4charts;
          this.disposeChart();
          this.initChart();
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataAvailable = this.data && this.milestones && this.startDate;
    const amChartsAvailable = this.am4core && this.am4charts;

    if (!(dataAvailable && amChartsAvailable)) {
      return;
    }

    if (changes.data || changes.milestones || changes.startDate) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.disposeChart();
          this.initChart();
        }, 0)
      });
    }
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.disposeChart();
    });
  }

  private getStartMilestone(): MetricMilestone {
    return {
      date: parseDateString(this.startDate),
      targetValue: 0
    };
  }

  private getStartDate() {
    const startDateObject = parseDateString(this.startDate);
    startDateObject && startDateObject.setDate(1);
    return this.metricsUtilsService.getChartStartDate(this.data, startDateObject);
  }

  private getMilestones() {
    return this.milestones || [];
  }

  private getChartData(): MetricValueRecords<number> {
    if (!Array.isArray(this.data) || this.data.length === 0) {
      return [];
    }

    let startPointExists = false;
    const startDate = this.getStartDate();
    const milestones = this.getMilestones();
    const data = this.data.map(item => {
      const estimatedTarget = this.metricsUtilsService.getEstimatedTarget(
        item.timestamp,
        startDate,
        milestones
      );

      if (item.timestamp.getTime() === startDate.getTime()) {
        startPointExists = true;
      }

      return {
        ...item,
        estimatedTarget
      };
    });

    if (!startPointExists) {
      data.unshift({
        timestamp: startDate,
        value: 0,
        estimatedTarget: 0
      })
    }

    return data;
  }

  private getTrendData(): MetricMilestone[] {
    return [
      this.getStartMilestone(),
      ...this.getMilestones()
    ];
  }

  private createDateAxis(ranges = true) {
    const dateAxis = this.chart.xAxes.push(new this.am4charts.DateAxis());
    const startDate = this.getStartDate();
    const endDate = this.amChartsService.getChartEndDate(this.milestones);

    this.amChartsService.configureDateAxis(dateAxis, startDate, endDate);
    if (ranges) {
      this.amChartsService.createDateAxisRanges(dateAxis, startDate, endDate);
    }

    return dateAxis;
  }

  generateMonthsSummaryData() {
    const summaryData = [];
    let lastValue = 0;
    (this.data || []).forEach((record, index) => {
      const lastItem = index === this.data.length - 1;
      let monthNumber = record.timestamp.getMonth();
      const switchMonth = lastItem || monthNumber !== this.data[index + 1].timestamp.getMonth();
      if (switchMonth) {
        ++monthNumber
        const monthKey = monthNumber > 9 ? monthNumber : '0' + monthNumber;
        let date = record.timestamp.getFullYear() + '-' + monthKey;
        const value = lastValue ? record.value - lastValue : record.value;
        summaryData.push({date, value })
        lastValue = record.value;
      }
    })
    return summaryData;
  }

  createMonthSummaryColumns() {
    const dateAxis = this.createDateAxis(false);
    dateAxis.baseInterval = { timeUnit: 'month', count: 1 };

    var series = new this.am4charts.ColumnSeries();
    series.name = 'Monthly ' + this.metricName;
    series.data = this.generateMonthsSummaryData();
    series.xAxis = dateAxis;
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.dateFormatter.dateFormat = "YYYY-MM";
    series.fill = this.am4core.color("rgba(76, 54, 141, 0.25)");
    series.strokeWidth = 0;
    series.tooltip.disabled = true;

    this.chart.series.push(series);
  }

  private createValueAxis() {
    const valueAxis = this.chart.yAxes.push(new this.am4charts.ValueAxis());

    this.amChartsService.configureValueAxis(valueAxis);
    this.amChartsService.configureTicks(valueAxis.renderer.ticks.template);

    return valueAxis;
  }

  private createCumulativeLine() {
    const dateAxis = this.createDateAxis();

    const series = new this.am4charts.StepLineSeries();
    series.name = 'Cumulative ' + this.metricName;
    series.xAxis = dateAxis;
    this.amChartsService.configureMainSeries(series);
    this.amChartsService.configureTooltip(series);
    series.dx = -1;
    series.tooltipText = `[bold]{value} (vs. ~{estimatedTarget} target)
      on {timestamp}`;

    this.chart.series.push(series);

    return series;
  }

  private createTrendLine() {
    const trend = this.chart.series.push(new this.am4charts.LineSeries());
    this.amChartsService.configureTrendLine(trend);
    trend.data = this.getTrendData();
    trend.dataFields.valueY = 'targetValue';
    trend.dataFields.dateX = 'date';
    trend.zIndex = 1;

    const bullet = this.amChartsService.createBullet(trend);
    trend.bullets.push(bullet);

    this.amChartsService.configureTooltip(trend, 'up', true);
    trend.tooltip.dx = 0;
    trend.bulletsContainer.parent = this.chart.seriesContainer;

    return trend;
  };

  createChartLegend() {
    this.chart.legend = new this.am4charts.Legend();
    const legend = this.chart.legend;
    legend.position = 'bottom';
    legend.fontFamily = 'DM Sans';
    legend.fontSize = '14px';
    legend.fontWeight = '500';
    legend.labels.template.fill = this.am4core.color("#061F38");
    legend.valueLabels.template.fill = this.am4core.color("#061F38");
    legend.markers.template.width = 10;
    legend.markers.template.height = 10;
    legend.markers.template.children.getIndex(0).cornerRadius(0, 0, 0, 0);
    legend.useDefaultMarker = true;
  }

  disposeChart() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }

  initChart() {
    if (!this.chart) {
      this.chart = this.am4core.create(this.chartRef.nativeElement, this.am4charts.XYChart);
    }

    this.chart.data = this.getChartData();
    this.amChartsService.configureChart(this.chart);
    this.createValueAxis();
    this.createCumulativeLine();
    this.createMonthSummaryColumns();
    this.createTrendLine();
    this.createChartLegend();
    this.chart.cursor = this.amChartsService.createCursor();
  }
}
