import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Budget } from 'app/shared/types/budget.interface';
import { DetailsAction } from './details-header.type';
import { ObjectHierarchy } from '../object-hierarchy-nav/object-hierarchy-nav.type';

@Component({
  selector: 'details-header',
  templateUrl: './details-header.component.html',
  styleUrls: ['./details-header.component.scss']
})
export class DetailsHeaderComponent {
  @Input() budgetList: Budget[] = [];
  @Input() actions: DetailsAction[] = [];
  @Input() objectHierarchy: ObjectHierarchy;
  @Input() isReadOnlyMode = false;
  @Input() hasUnsavedChanges = false;
  @Input() objectDisplayType = 'Object';
  @Input() showSaveAndNewButton = true;
  @Output() budgetToMoveSelected = new EventEmitter<Budget>();
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() saveAndNew = new EventEmitter<void>();
  @Output() saveAndClose = new EventEmitter<void>();

  onMoveToBudgetSelected(budget: Budget) {
    this.budgetToMoveSelected.emit(budget);
  }

  onCancel() {
    this.cancel.emit();
  }

  onSave() {
    this.save.emit();
  }

  onSaveAndNew() {
    this.saveAndNew.emit();
  }

  onSaveAndClose() {
    this.saveAndClose.emit();
  }
}
