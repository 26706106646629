import { Component, Input, Output, EventEmitter, HostBinding, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'expense-amount',
  templateUrl: './expense-amount.component.html',
  styleUrls: ['./expense-amount.component.scss']
})
export class ExpenseAmountComponent {
  public isEditMode: boolean;
  public displayValue: string;
  public initialValue: number;
  public controlValue: string;
  public maxLength = 14;
  public currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', allowNegative: true, prefix: '' };

  @Input() disabled: boolean;
  @Input() numberFormat: string;
  @Output() onChange = new EventEmitter<number>();
  @ViewChild('input') input: ElementRef;

  @HostBinding('class.is-negative')
  public isNegative: boolean;

  @HostBinding('class.has-converted')
  @Input() convertedValue: number;

  @Input() set sourceValue(val: number) {
    this.value = val;
  };

  private set value(val: number) {
    this.initialValue = val;
    this.controlValue = String(val || 0);
    this.isNegative = val < 0;
    this.displayValue = this.isNegative ? String(-1 * val) : this.controlValue;
  }

  public handleFocus() {
    if (this.disabled) {
      return;
    }
    this.isEditMode = true;
    this.input.nativeElement.select();
    this.input.nativeElement.focus();
  }

  public updateValue() {
    this.isEditMode = false;
    const numericValue = +this.controlValue;
    if (this.initialValue !== numericValue) {
      this.value = numericValue;
      this.onChange.emit(numericValue);
    }
  }
}
