import { Injectable, OnDestroy } from '@angular/core';
import { ManagePageApiService } from './manage-page-api.service';
import { BaseManagePageTagsService } from '@shared/services/base-manage-page-tags.service';

@Injectable()
export class ManagePageTagsService extends BaseManagePageTagsService implements OnDestroy {

  constructor(
    protected readonly apiService: ManagePageApiService
  ) {
    super(apiService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.tagMappingsLoading$.complete();
  }
}
