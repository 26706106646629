import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpXhrBackend } from '@angular/common/http';
import { Configuration } from 'app/app.constants';
import { AuthInterceptor } from '../user/services/http-auth.interceptor';
import { observeFromHttpEvents } from '@common-lib/lib/http/utils';
import { HttpEventsObserveType } from '@common-lib/lib/http/types/http-events-observe-type.enum';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { IMPORT_DATA_TYPE } from './upload_data.type';
import { Observable, Subject } from 'rxjs';
import { ReportParams } from '../dashboard/export-data.service';
import { map } from 'rxjs/operators';
import { HookedReportData } from './import-data.component';

@Injectable({
  providedIn: 'root',
})
export class UploadDataService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly configuration = inject(Configuration);
  private readonly backend = inject(HttpXhrBackend);
  private readonly authInterceptor = inject(AuthInterceptor);
  private readonly http = inject(HttpClient);

  private readonly xlsxImportUrl = this.configuration.xlsx_import_service_url;
  private readonly exportReportUrl = this.configuration.export_report_url;
  public readonly reloadImportingList$ = new Subject<void>();

  importBudget(fd: FormData): Observable<any> {
    const url = `${this.apiV2Url}budget/import/`;
    return this.rawPostRequest(url, fd);
  }

  importBudgetRelatedObjects(fd: FormData, dataType: IMPORT_DATA_TYPE): Observable<string> {
    fd.append('bulk_add_type', dataType);
    return this.rawPostRequest(this.xlsxImportUrl + 'lambda-start-db-import', fd);
  }

  public initReportExport(budgetId: number, exportReportParams: Partial<ReportParams>): Observable<string> {
    const queryParams = Object.entries(exportReportParams)
      .reduce((qp, [key, value]) => qp + '&' + key + '=' + value, '');

    return this.rawGetRequest(`${this.exportReportUrl}start_lambda?budget_id=${budgetId}${queryParams}`).pipe(
      map(resp => (resp as Record<string, string>).execution_arn)
    );
  }

  private rawPostRequest(url, body) {
    const req = new HttpRequest('POST', url, body);
    return observeFromHttpEvents(
      this.authInterceptor.intercept(req, this.backend),
      HttpEventsObserveType.Body
    );
  }

  private rawGetRequest(url) {
    const req = new HttpRequest('GET', url);
    return observeFromHttpEvents(
      this.authInterceptor.intercept(req, this.backend),
      HttpEventsObserveType.Body
    );
  }

  checkArnStatus(arnId: string): Observable<any> {
    return this.http.get(this.xlsxImportUrl + 'lambda-status-db-import/' + arnId);
  }

  checkExportReportStatus(arnId: string): Observable<HookedReportData> {
    return this.http.get<HookedReportData>(this.exportReportUrl + 'status_lambda/' + arnId);
  }
}
