import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Configuration } from 'app/app.constants';
import { MetricFunnelsValidationService } from '@common-lib/lib/utils/metric-funnels-validation.service';

@Component({
  selector: 'funnel-name-input',
  templateUrl: './funnel-name-input.component.html',
  styleUrls: ['./funnel-name-input.component.scss']
})
export class FunnelNameInputComponent implements OnChanges {
  maxLength = 20;
  nameControl = new UntypedFormControl(null, {
    validators: [
      Validators.required,
      Validators.maxLength(this.maxLength),
    ]
  });
  errorMsg: string;
  clonedText: string;
  errors = {
    required: 'Name is required',
    unique: 'This name is already used',
  }
  @Input() editModeActive = false;
  @Output() nameChanged = new EventEmitter();
  @HostBinding('class.focused') isActive = false;

  @Input() set name (name: string) {
    this.setName(name);
  }
  @Input() set funnelId (id: number) {
    this.nameControl.addValidators(
      this.funnelsValidationService.uniqueFunnelNameValidator(id)
    )
  }

  constructor(
    public configuration: Configuration,
    public funnelsValidationService: MetricFunnelsValidationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editModeActive) {
      if (changes.editModeActive.previousValue && !changes.editModeActive.currentValue) {
        this.errorMsg = null;
      }
    }
  }

  setName(name: string): void {
    this.nameControl.setValue(name);
    this.updateClonedText(name);
  }

  updateClonedText(val: string): void {
    this.clonedText = val?.replaceAll(' ', '-') || ' ';
  }

  onModelChange(val: string): void {
    this.updateClonedText(val);
  }

  onFocus() {
    this.isActive = true;
    if (this.errorMsg) {
      this.errorMsg = null;
    }
  }

  onBlur() {
    this.isActive = false;
    const trimmed = (this.nameControl.value || '').trim();
    this.setName(trimmed);

    this.funnelsValidationService.funnelNameErrors = this.nameControl.invalid;
    this.setErrorMsg();

    this.nameChanged.emit(this.nameControl.value);
  }

  setErrorMsg(): void {
    const errors = this.nameControl.errors;
    if (!errors) {
      this.errorMsg = null;
      return;
    }
    const errorKey = Object.keys(errors)[0];
    this.errorMsg = this.errors[errorKey] || 'Wrong field!';
  }
}
