import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

export interface CurrencyDO {
  id: number;
  active: boolean;
  company: number;
  currency: string;
  is_new: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyCurrencyService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    companyCurrency: 'company_currency/',
  };

  getCompanyCurrencies(company?: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiV2Url}${this.apiPaths.companyCurrency}`, getRequestOptions({
      company
    }));
  }

  addCurrency(company: number, currency: string) {
    return this.http.post(`${this.apiV2Url}${this.apiPaths.companyCurrency}`, JSON.stringify({
      active: true,
      currency,
      company
    }));
  }

  removeCurrency(currencyId: number) {
    return this.http.delete(`${this.apiV2Url}${this.apiPaths.companyCurrency}${currencyId}/`);
  }

  updateCurrency(currencyId: number, data: {is_new?: boolean, active?: boolean}) {
    return this.http.patch(
      `${this.apiV2Url}${this.apiPaths.companyCurrency}${currencyId}/`,
      JSON.stringify(data)
    );
  }
}
