import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ERROR_STATUSES, InvoiceFile, InvoiceUploadManagerService, UploadStatus } from '@spending/services/invoice-upload-manager.service';
import { DialogContext } from '@shared/types/dialog-context.interface';

@Component({
  selector: 'upload-status-dialog',
  templateUrl: './upload-status-dialog.component.html',
  styleUrls: ['./upload-status-dialog.component.scss']
})
export class UploadStatusDialogComponent implements OnDestroy {
  protected readonly uploadStatuses = UploadStatus;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<UploadStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogContext,
    public readonly invoiceUploadManager: InvoiceUploadManagerService,
  ) {
    this.invoiceUploadManager.watchedFiles$
      .pipe(
        takeUntil(this.destroy$),
        filter(watchedFiles => !watchedFiles.length)
      ).subscribe(() => {
        this.dialogRef.close();
      });
  }

  isUploading(watchedFile: InvoiceFile, statusMap: Record<string, string>): boolean {
    return statusMap[watchedFile.trackId] === UploadStatus.UPLOADING;
  }

  isProcessing(watchedFile: InvoiceFile, statusMap: Record<string, string>): boolean {
    return statusMap[watchedFile.trackId] === UploadStatus.RUNNING;
  }

  isFailed(watchedFile: InvoiceFile, statusMap: Record<string, UploadStatus>): boolean {
    return ERROR_STATUSES.includes(statusMap[watchedFile.trackId]);
  }

  retryProcessing(file: InvoiceFile): void {
    this.invoiceUploadManager.retryProcessing(file);
  }

  clearError(trackId: string): void {
    this.invoiceUploadManager.removeFile(trackId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
