<grid-with-search
  [gridData]="tagsList"
  [gridColumns]="gridColumns"
  (deleteGridItem)="deleteTag($event)"
  (addNewGridItem)="addTag($event)"
  (editGridItem)="updateTag($event)"
  [enableSwitchTooltip]="enableSwitchTooltip"
  [SearchPlaceHolder]="SearchPlaceHolder"
  [editableMaxLength]="45"
  [NoDataText]="NoDataText"
  [isNameCaseSensitive]="true"
></grid-with-search>