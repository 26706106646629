import { Component, Input } from '@angular/core';

@Component({
  selector: 'summary-value',
  templateUrl: './summary-value.component.html',
  styleUrls: ['./summary-value.component.scss']
})
export class SummaryValueComponent {
  @Input() label: string;
  @Input() value: number;
}
