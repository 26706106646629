import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  ManageTableBudgetAllocationValue,
  ManageTableRow,
} from '../components/manage-table/manage-table.types';
import { ManageTableHelpers } from './manage-table-helpers';
import {
  AllocatedToChildrenAmounts,
  AllocationCheckResult,
  AllocationCheckResultData,
  BudgetObjectAllocationService,
  ParentDialogActionSource
} from 'app/budget-object-details/services/budget-object-allocation.service';
import { Configuration } from 'app/app.constants';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { ManageTableDataService } from './manage-table-data.service';
import { SegmentedBudgetObject } from 'app/shared/types/segmented-budget-object';
import { getNumericValue, sumAndRound } from 'app/shared/utils/common.utils';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

type ManageTableAllocatedToChildrenAmounts = AllocatedToChildrenAmounts<ManageTableBudgetAllocationValue>

@Injectable()
export class ManageTableDataValidationService {
  private _draggedRecord: ManageTableRow = null;
  private _restrictedFromDrop: string[] = [];

  public get draggedRecord() {
    return this._draggedRecord;
  }

  public get restrictedFromDrop() {
    return this._restrictedFromDrop;
  }

  constructor(
    private readonly configuration: Configuration,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly budgetObjectAllocationService: BudgetObjectAllocationService,
    private readonly tableDataService: ManageTableDataService,
  ) {}

  private updateRestrictedFromDrop() {
    this._restrictedFromDrop = ManageTableHelpers.getChildRecordIds(this.draggedRecord);
  };

  private getAllocatedToChildrenAmounts(
    parentRecord: ManageTableRow,
    targetRecord?: ManageTableRow,
    includeImplicit = true
  ): ManageTableAllocatedToChildrenAmounts {
    const initialValues: ManageTableAllocatedToChildrenAmounts = { total: 0, values: {} };
    const explicitChildrenValues: ManageTableAllocatedToChildrenAmounts = parentRecord.children.reduce((result, child) => {
      if (targetRecord && child.id === targetRecord.id) {
        return result;
      }

      const sourceObject = this.tableDataService.getSourceObjectForRecord(child);

      return {
        total: sumAndRound(
          result.total,
          this.tableDataService.getRecordTotalAllocated(child, sourceObject)
        ),
        values: ManageTableHelpers.sumRecordValues(result.values, child.values)
      };
    }, initialValues);

    if (!includeImplicit) {
      return explicitChildrenValues;
    }

    const implicitChildren = this.tableDataService.getImplicitRecordChildren(parentRecord, targetRecord);

    return implicitChildren.reduce((result, child) => ({
      total: sumAndRound(
        result.total,
        child.amount
      ),
      values: ManageTableHelpers.sumRecordValues(result.values, ManageTableHelpers.mapAllocatedTimeframeValues(child))
    }), explicitChildrenValues);
  }

  private shouldCheckGrandParent(grandParentRecord: ManageTableRow): boolean {
    return grandParentRecord?.type === ManageTableRowType.Campaign && !ManageTableHelpers.isSegmentlessObject(grandParentRecord);
  }

  private getGrandParentAllocationDiff(
    grandParentRecord: ManageTableRow,
    parentAllocationDiff: number,
    sourceObject?: SegmentedBudgetObject
  ): number {
    const grandParentAllocated = this.tableDataService.getRecordTotalAllocated(grandParentRecord, sourceObject);
    const grandParentAllocatedToChildren = sumAndRound(
      this.getAllocatedToChildrenAmounts(grandParentRecord).total,
      Math.abs(parentAllocationDiff)
    );

    return sumAndRound(grandParentAllocated, -grandParentAllocatedToChildren);
  }

  private getParentRecordTimeframeValue(
    record: ManageTableRow,
    timeframeId: number
  ): number {
    const sourceGP = record.sharedCostRuleId && record.segmentId
      ? this.tableDataService.getSourceObjectForRecord(record)
      : null;

    return sourceGP
      ? getNumericValue(sourceGP.timeframes.find(tf => tf.company_budget_alloc === Number(timeframeId))?.amount)
      : record.values[timeframeId]?.allocated;
  }

  public setDraggedRecord(record: ManageTableRow) {
    this._draggedRecord = record;
    this._restrictedFromDrop = [];
    this.updateRestrictedFromDrop();
  }

  public validateRecordAllocationLimits(
    targetRecord: ManageTableRow,
    parentRecord: ManageTableRow,
    grandParentRecord?: ManageTableRow
  ): Observable<AllocationCheckResultData> {
    const targetSourceObject = this.tableDataService.getSourceObjectForRecord(targetRecord);
    const parentSourceObject = this.tableDataService.getSourceObjectForRecord(parentRecord);
    const grandParentSourceObject = this.tableDataService.getSourceObjectForRecord(grandParentRecord);
    const targetTotalAllocated = this.tableDataService.getRecordTotalAllocated(targetRecord, targetSourceObject);
    const allocatedToOwnChildren =
      targetRecord.type === ManageTableRowType.Campaign ?
        this.getAllocatedToChildrenAmounts(targetRecord).total :
        0;

    if (allocatedToOwnChildren > targetTotalAllocated) {
      return of({
        result: AllocationCheckResult.NeedOwnAllocationUpdate,
        message: this.budgetObjectAllocationService.createWarningMessage(allocatedToOwnChildren, targetTotalAllocated),
      });
    }

    if (!parentRecord || parentRecord.type !== ManageTableRowType.Campaign || ManageTableHelpers.isSegmentlessObject(parentRecord)) {
      return of({ result: AllocationCheckResult.Ok });
    }

    const parentTotalAllocated = this.tableDataService.getRecordTotalAllocated(parentRecord, parentSourceObject);
    const parentAllocatedToChildren = this.getAllocatedToChildrenAmounts(parentRecord, targetRecord).total;
    const availableParentAllocation = sumAndRound(parentTotalAllocated, -parentAllocatedToChildren);
    const parentAllocationDiff = sumAndRound(availableParentAllocation, -targetTotalAllocated);

    if (parentAllocationDiff >= 0) {
      return of({ result: AllocationCheckResult.Ok });
    }

    const grandParentAllocationDiff =
      this.shouldCheckGrandParent(grandParentRecord) ?
        this.getGrandParentAllocationDiff(grandParentRecord, parentAllocationDiff, grandParentSourceObject) :
        0;
    const grandParentUpdateNeeded = grandParentAllocationDiff < 0;

    return this.budgetObjectAllocationService.openParentAllocationUpdateDialog({
      targetObjName: targetRecord.name,
      parentObjName: parentRecord.name,
      grandParentObjName: grandParentRecord?.name,
      grandParentUpdateNeeded,
      parentTotalAllocated,
      targetTotalAllocated,
      parentAllocationDiff,
      grandParentAllocationDiff
    });
  }

  public validateParentAllocationLimits(
    parentRecord: ManageTableRow,
    grandParentRecord: ManageTableRow,
    dialogActionSource: ParentDialogActionSource
  ): Observable<AllocationCheckResultData> {
    if (!parentRecord || parentRecord.type !== ManageTableRowType.Campaign || ManageTableHelpers.isSegmentlessObject(parentRecord)) {
      return of({ result: AllocationCheckResult.Ok });
    }

    const parentSourceObject = this.tableDataService.getSourceObjectForRecord(parentRecord);
    const grandParentSourceObject = this.tableDataService.getSourceObjectForRecord(grandParentRecord);
    const parentTotalAllocated = this.tableDataService.getRecordTotalAllocated(parentRecord, parentSourceObject);
    const parentChildrenAmounts = this.getAllocatedToChildrenAmounts(parentRecord);
    const parentAllocatedToChildren = parentChildrenAmounts.total;
    const targetTotalAllocated = parentAllocatedToChildren;
    const parentAllocationDiff = sumAndRound(parentTotalAllocated, -parentAllocatedToChildren);
    const parentAllocationsDiff = this.budgetObjectAllocationService.getParentAllocationsDiff<ManageTableBudgetAllocationValue>(
      parentAllocationDiff,
      parentChildrenAmounts,
      timeframeId => this.getParentRecordTimeframeValue(parentRecord, timeframeId),
      value => value.allocated,
    );
    let grandParentChildrenAmounts = null;
    let grandParentAllocationDiff = 0;
    let grandParentAllocationsDiff = null;
    let grandParentUpdateNeeded = false;
    let grandParentObjName = '';

    if (this.shouldCheckGrandParent(grandParentRecord)) {
      grandParentChildrenAmounts = this.getAllocatedToChildrenAmounts(grandParentRecord);
      grandParentAllocationDiff = this.getGrandParentAllocationDiff(grandParentRecord, parentAllocationDiff, grandParentSourceObject);
      grandParentAllocationsDiff = this.budgetObjectAllocationService.getParentAllocationsDiff<ManageTableBudgetAllocationValue>(
        grandParentAllocationDiff,
        grandParentChildrenAmounts,
        timeframeId => this.getParentRecordTimeframeValue(grandParentRecord, timeframeId),
        value => value.allocated,
        parentAllocationsDiff
      );
      grandParentUpdateNeeded = grandParentAllocationDiff < 0;
      grandParentObjName = grandParentRecord.name;
    }

    if (parentAllocationDiff < 0) {
      return this.budgetObjectAllocationService.openParentAllocationUpdateDialog({
        grandParentUpdateNeeded,
        targetObjName: 'moved objects',
        parentObjName: parentRecord.name,
        parentTotalAllocated,
        targetTotalAllocated,
        parentAllocationDiff,
        grandParentAllocationDiff,
        dialogActionSource,
        parentAllocationsDiff,
        grandParentAllocationsDiff,
        grandParentObjName
      });
    }

    return of({
      result: AllocationCheckResult.Ok
    });
  }
}
