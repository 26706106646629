<svg
  *ngIf="isMonoChrome; else nonMonoChrome"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 14"
  fill="none"
  [attr.width]="width || size"
  [attr.height]="height || size"
>
  <path
    d="M15.2087 1.99992C14.657 1.99992 14.1295 2.10742 13.6428 2.29283C13.3421 1.82017 12.927 1.43098 12.436 1.16129C11.945 0.891597 11.3939 0.75011 10.8337 0.749919C9.95657 0.749919 9.15866 1.09159 8.56491 1.64659C8.19482 1.23329 7.74172 0.902691 7.23519 0.676388C6.72867 0.450085 6.18011 0.333161 5.62532 0.333252C3.43949 0.333252 1.66699 2.10575 1.66699 4.29158C1.66699 4.622 1.71283 4.94117 1.78783 5.247C1.36094 5.57705 1.01539 6.00058 0.777753 6.48504C0.540112 6.96949 0.416697 7.50198 0.416993 8.04158C0.416993 9.99783 2.00241 11.5833 3.95866 11.5833C4.13116 11.5833 4.29866 11.5666 4.46491 11.5424C5.01157 12.7924 6.25658 13.6666 7.70866 13.6666C9.02532 13.6666 10.1728 12.9458 10.7832 11.8778C11.0709 11.9583 11.3682 11.9994 11.667 11.9999C12.7591 11.9999 13.7245 11.4724 14.3328 10.6624C14.6162 10.7191 14.9091 10.7499 15.2087 10.7499C17.6253 10.7499 19.5837 8.79158 19.5837 6.37492C19.5837 3.95825 17.6253 1.99992 15.2087 1.99992Z"
    [attr.fill]="color"
  />
</svg>
<ng-template #nonMonoChrome>
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="surface1">
      <path
        id="Vector"
        d="M15.8515 2.07507C15.2759 2.07507 14.7254 2.18724 14.2176 2.38072C13.9038 1.8875 13.4707 1.4814 12.9583 1.19998C12.446 0.918559 11.8709 0.77092 11.2863 0.77072C10.3711 0.77072 9.53849 1.12724 8.91892 1.70637C8.53274 1.2751 8.05994 0.930135 7.53139 0.693993C7.00285 0.45785 6.43043 0.335843 5.85153 0.335938C3.57066 0.335938 1.7211 2.1855 1.7211 4.46637C1.7211 4.81115 1.76892 5.1442 1.84718 5.46333C1.40173 5.80773 1.04117 6.24968 0.793194 6.75519C0.545221 7.26071 0.41644 7.81635 0.416749 8.37942C0.416749 10.4207 2.0711 12.0751 4.1124 12.0751C4.2924 12.0751 4.46718 12.0577 4.64066 12.0325C5.2111 13.3368 6.51023 14.249 8.02544 14.249C9.39936 14.249 10.5967 13.4968 11.2337 12.3825C11.5339 12.4665 11.8441 12.5093 12.1559 12.5099C13.2954 12.5099 14.3028 11.9594 14.9376 11.1142C15.2333 11.1733 15.5389 11.2055 15.8515 11.2055C18.3733 11.2055 20.4167 9.16202 20.4167 6.64029C20.4167 4.11855 18.3733 2.07507 15.8515 2.07507Z"
        fill="#0063B7"
      />
    </g>
  </svg>
</ng-template>
