import { Component, Input } from '@angular/core';
import { BudgetStatusLabel } from 'app/shared/constants/budget-status.constants';

@Component({
  selector: 'budget-name',
  templateUrl: './budget-name.component.html',
  styleUrls: ['./budget-name.component.scss']
})
export class BudgetNameComponent {
  @Input() budgetName: string;
  @Input() budgetStatus: string;

  public BudgetStatusLabel = BudgetStatusLabel;
}
