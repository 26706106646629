import { Injectable } from '@angular/core';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { LightProgram, Program } from 'app/shared/types/program.interface';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { Goal } from '@shared/types/goal.interface';
import { BudgetObjectsData, BudgetSegmentsData } from '../types/expense-page.type';

export type OptionItem =
  HierarchyExpenseGroupItem | HierarchyCampaignItem | HierarchySegmentItem | HierarchySegmentGroupItem | HierarchyGoalItem;

export interface HierarchyExpenseGroupItem {
  data: LightProgram;
}

export interface HierarchyCampaignItem {
  data?: LightCampaign;
  childCampaigns?: HierarchyCampaignItem[];
  childExpGroups?: HierarchyExpenseGroupItem[];
}

export interface HierarchyGoalItem {
  data?: Goal;
  childCampaigns?: HierarchyCampaignItem[];
  childExpGroups?: HierarchyExpenseGroupItem[];
}

export interface HierarchySegmentItem {
  data?: BudgetSegmentAccess;
  childCampaigns?: HierarchyCampaignItem[];
  childExpGroups?: HierarchyExpenseGroupItem[];
}

export interface HierarchySegmentGroupItem {
  data?: SegmentGroup;
  childSegments?: HierarchySegmentItem[];
}

export interface SegmentHierarchyMap {
  segments: { [key: number]: HierarchySegmentItem };
  segmentGroups: { [key: number]: HierarchySegmentGroupItem };
}

export interface GoalHierarchyMap {
  goals: { [goalId: number]: HierarchyGoalItem };
  campaigns: { [campaignId: number]: HierarchyCampaignItem };
  expGroups: { [expGroupId: number]: HierarchyExpenseGroupItem };
}

export interface ObjectsHierarchyMap {
  campaigns: { [campaignId: number]: HierarchyCampaignItem };
  expGroups: { [expGroupId: number]: HierarchyExpenseGroupItem };
}

export interface CampaignHierarchyMap {
  [campaignId: number]: HierarchyCampaignItem;
}

@Injectable()
export class HierarchyBuilder {

  private static assignExpenseGroupInSegmentHierarchy(
    expGroup: LightProgram,
    campaignMap: CampaignHierarchyMap,
    segmentHierarchyMap: SegmentHierarchyMap,
    filteredCampaignIds: number[]
  ): void {
    const expGroupHierarchyItem = { data: expGroup };
    if (expGroup.campaignId && filteredCampaignIds.includes(expGroup.campaignId)) {
      const parentCampaignItem = campaignMap[expGroup.campaignId] || {};
      parentCampaignItem.childExpGroups = (parentCampaignItem.childExpGroups || []).concat(expGroupHierarchyItem);
      campaignMap[expGroup.campaignId] = parentCampaignItem;
    } else {
      if (!expGroup.budgetSegmentId) {
        console.warn('Missing budgetSegmentId for ', expGroup);
        return;
      }
      const segmentItem = segmentHierarchyMap.segments[expGroup.budgetSegmentId] || {};
      segmentItem.childExpGroups = (segmentItem.childExpGroups || []).concat(expGroupHierarchyItem);
      segmentHierarchyMap.segments[expGroup.budgetSegmentId] = segmentItem;
    }
  }

  private static assignExpenseGroupInGoalHierarchy(
    expGroup: LightProgram,
    campaignMap: CampaignHierarchyMap,
    goalHierarchyMap: GoalHierarchyMap,
    filteredCampaignIds: number[]
  ): void {
    const expGroupHierarchyItem = { data: expGroup };
    if (expGroup.campaignId && filteredCampaignIds.includes(expGroup.campaignId)) {
      const parentCampaignItem = campaignMap[expGroup.campaignId] || {};
      parentCampaignItem.childExpGroups = (parentCampaignItem.childExpGroups || []).concat(expGroupHierarchyItem);
      campaignMap[expGroup.campaignId] = parentCampaignItem;
    } else if (expGroup.goalId) {
      const goalItem = goalHierarchyMap.goals[expGroup.goalId] || {};
      goalItem.childExpGroups = (goalItem.childExpGroups || []).concat(expGroupHierarchyItem);
      goalHierarchyMap.goals[expGroup.goalId] = goalItem;
    } else {
      goalHierarchyMap.expGroups[expGroup.id] = expGroupHierarchyItem;
    }
  }

  private static assignChildCampaign(
    campaign: LightCampaign,
    campaignMap: CampaignHierarchyMap,
    segmentHierarchyMap?: SegmentHierarchyMap,
    filteredCampaignIds?: number[]
  ): void {
    const childCampaignHierarchyItem = campaignMap[campaign.id] || {};
    childCampaignHierarchyItem.data = campaign;

    if (filteredCampaignIds?.includes(campaign.parentCampaign) || !segmentHierarchyMap) {
      const parentCampaignItem = campaignMap[campaign.parentCampaign] || {};
      parentCampaignItem.childCampaigns = (parentCampaignItem.childCampaigns || []).concat(childCampaignHierarchyItem);
      campaignMap[campaign.parentCampaign] = parentCampaignItem;
    } else {
      const segmentItem = segmentHierarchyMap.segments[campaign.budgetSegmentId] || {};
      segmentItem.childCampaigns = (segmentItem.childCampaigns || []).concat(childCampaignHierarchyItem);
      segmentHierarchyMap.segments[campaign.budgetSegmentId] = segmentItem;
    }
    Reflect.deleteProperty(campaignMap, campaign.id);
  }

  private static assignChildCampaignInGoalHierarchy(
    campaign: LightCampaign,
    campaignMap: CampaignHierarchyMap,
    goalHierarchyMap: GoalHierarchyMap,
    filteredCampaignIds: number[]
  ): void {
    const childCampaignHierarchyItem = campaignMap[campaign.id] || {};
    childCampaignHierarchyItem.data = campaign;

    if (filteredCampaignIds.includes(campaign.parentCampaign)) {
      const parentCampaignItem = campaignMap[campaign.parentCampaign] || {};
      parentCampaignItem.childCampaigns = (parentCampaignItem.childCampaigns || []).concat(childCampaignHierarchyItem);
      campaignMap[campaign.parentCampaign] = parentCampaignItem;
    } else if (campaign.goalId) {
      const goalItem = goalHierarchyMap.goals[campaign.goalId] || {};
      goalItem.childCampaigns = (goalItem.childCampaigns || []).concat(childCampaignHierarchyItem);
      goalHierarchyMap.goals[campaign.goalId] = goalItem;
    } else {
      goalHierarchyMap.campaigns[campaign.id] = childCampaignHierarchyItem;
    }
    Reflect.deleteProperty(campaignMap, campaign.id);
  }

  private static assignParentCampaignInSegmentHierarchy(
    campaign: LightCampaign,
    campaignMap: CampaignHierarchyMap,
    segmentHierarchyMap: SegmentHierarchyMap
  ): void {
    if (!campaign.budgetSegmentId) {
      // skip for segmentless campaigns
      return;
    }
    const campaignHierarchyItem = campaignMap[campaign.id] || {};
    campaignHierarchyItem.data = campaign;
    const segmentItem = segmentHierarchyMap.segments[campaign.budgetSegmentId] || {};
    segmentItem.childCampaigns = (segmentItem.childCampaigns || []).concat(campaignHierarchyItem);
    segmentHierarchyMap.segments[campaign.budgetSegmentId] = segmentItem;
  }

  private static assignSegment(segment: BudgetSegmentAccess, segmentHierarchyMap: SegmentHierarchyMap): void {
    const segmentHierarchyItem = segmentHierarchyMap.segments[segment.id] || {};
    segmentHierarchyItem.data = segment;

    if (segment.segment_group) {
      const segmentGroupItem = segmentHierarchyMap.segmentGroups[segment.segment_group] || {};
      segmentGroupItem.childSegments = (segmentGroupItem.childSegments || []).concat(segmentHierarchyItem);
      segmentHierarchyMap.segmentGroups[segment.segment_group] = segmentGroupItem;
      Reflect.deleteProperty(segmentHierarchyMap.segments, segment.id);
    } else {
      segmentHierarchyMap.segments[segment.id] = segmentHierarchyItem;
    }
  }

  private static assignGoal(goal: Goal, goalHierarchyMap: GoalHierarchyMap): void {
    const goalHierarchyItem = goalHierarchyMap.goals[goal.id] || {};
    goalHierarchyItem.data = goal;
    goalHierarchyMap.goals[goal.id] = goalHierarchyItem;
  }

  private static assignParentCampaignInGoalHierarchy(
    campaign: LightCampaign,
    campaignMap: CampaignHierarchyMap,
    goalHierarchyMap: GoalHierarchyMap
  ): void {
    const campaignHierarchyItem = campaignMap[campaign.id] || {};
    campaignHierarchyItem.data = campaign;

    if (campaign.goalId) {
      const goalItem = goalHierarchyMap.goals[campaign.goalId] || {};
      goalItem.childCampaigns = (goalItem.childCampaigns || []).concat(campaignHierarchyItem);
      goalHierarchyMap.goals[campaign.goalId] = goalItem;
    } else {
      goalHierarchyMap.campaigns[campaign.id] = campaignHierarchyItem;
    }
  }

  private static assignSegmentGroup(segmentGroup: SegmentGroup, segmentHierarchyMap: SegmentHierarchyMap): void {
    const segmentGroupItem = segmentHierarchyMap.segmentGroups[segmentGroup.id];
    if (!segmentGroupItem) {
      // Skip assigning in case a segment group doesn't have segments
      return;
    }
    segmentGroupItem.data = segmentGroup;
    segmentHierarchyMap.segmentGroups[segmentGroup.id] = segmentGroupItem;
  }

  private static assignExpenseGroupInCampaignsHierarchy(
    expGroup: LightProgram,
    objectsMap: ObjectsHierarchyMap,
    filteredCampaignIds: number[]
  ): void {
    const expGroupHierarchyItem = { data: expGroup };
    if (expGroup.campaignId && filteredCampaignIds.includes(expGroup.campaignId)) {
      const parentCampaignItem = objectsMap.campaigns[expGroup.campaignId] || {};
      parentCampaignItem.childExpGroups = (parentCampaignItem.childExpGroups || []).concat(expGroupHierarchyItem);
      objectsMap.campaigns[expGroup.campaignId] = parentCampaignItem;
    } else {
      objectsMap.expGroups[expGroup.id] = expGroupHierarchyItem;
    }
  }

  private static assignParentCampaignInCampaignHierarchy(campaign: LightCampaign, campaignMap: CampaignHierarchyMap): void {
    const campaignHierarchyItem = campaignMap[campaign.id] || {};
    campaignHierarchyItem.data = campaign;
    campaignMap[campaign.id] = campaignHierarchyItem;
  }

  buildSegmentHierarchy(segmentsData: BudgetSegmentsData, budgetObjects: BudgetObjectsData): SegmentHierarchyMap {
    const segmentHierarchyMap: SegmentHierarchyMap = {
      segmentGroups: {},
      segments: {}
    };
    const campaignMap: CampaignHierarchyMap = {};
    const campaignIds: number[] = budgetObjects.campaigns.map(camp => camp.id);

    (budgetObjects?.expGroups || []).forEach(
      expGroup => HierarchyBuilder.assignExpenseGroupInSegmentHierarchy(expGroup, campaignMap, segmentHierarchyMap, campaignIds)
    );

    const childCampaigns = budgetObjects?.campaigns?.filter(campaign => campaign.parentCampaign);
    (childCampaigns || []).forEach(
      campaign => HierarchyBuilder.assignChildCampaign(campaign, campaignMap, segmentHierarchyMap, campaignIds)
    );

    const parentCampaigns = budgetObjects?.campaigns?.filter(campaign => !campaign.parentCampaign);
    (parentCampaigns || []).forEach(
      campaign => HierarchyBuilder.assignParentCampaignInSegmentHierarchy(campaign, campaignMap, segmentHierarchyMap)
    );

    segmentsData?.segments?.forEach(
      segment => HierarchyBuilder.assignSegment(segment, segmentHierarchyMap)
    );

    segmentsData?.segmentGroups?.forEach(
      segmentGroup => HierarchyBuilder.assignSegmentGroup(segmentGroup, segmentHierarchyMap)
    );

    return segmentHierarchyMap;
  }

  buildCampaignHierarchy(budgetObjects: BudgetObjectsData, filteredCampaignIds: number[]): ObjectsHierarchyMap {
    const objectsMap: ObjectsHierarchyMap = {
      campaigns: {},
      expGroups: {}
    };

    (budgetObjects?.expGroups || []).forEach(
      expGroup => HierarchyBuilder.assignExpenseGroupInCampaignsHierarchy(expGroup, objectsMap, filteredCampaignIds)
    );

    const childCampaigns = budgetObjects?.campaigns?.filter(campaign =>
      campaign.parentCampaign && filteredCampaignIds.includes(campaign.parentCampaign)
    );
    (childCampaigns || []).forEach(
      campaign => HierarchyBuilder.assignChildCampaign(campaign, objectsMap.campaigns)
    );

    const parentCampaigns = budgetObjects?.campaigns?.filter(campaign =>
      !campaign.parentCampaign || (campaign.parentCampaign && !filteredCampaignIds.includes(campaign.parentCampaign))
    );
    (parentCampaigns || []).forEach(
      campaign => HierarchyBuilder.assignParentCampaignInCampaignHierarchy(campaign, objectsMap.campaigns)
    );

    return objectsMap;
  }

  buildGoalHierarchy(budgetObjects: BudgetObjectsData): GoalHierarchyMap {
    const goalHierarchyMap: GoalHierarchyMap = {
      goals: {},
      campaigns: {},
      expGroups: {}
    };
    const campaignMap: CampaignHierarchyMap = {};
    const filteredCampaignIds: number[] = budgetObjects.campaigns?.map(camp => camp.id);

    (budgetObjects?.expGroups || []).forEach(
      expGroup => HierarchyBuilder.assignExpenseGroupInGoalHierarchy(expGroup, campaignMap, goalHierarchyMap, filteredCampaignIds)
    );

    const childCampaigns = budgetObjects?.campaigns?.filter(campaign => campaign.parentCampaign);
    (childCampaigns || []).forEach(
      campaign => HierarchyBuilder.assignChildCampaignInGoalHierarchy(campaign, campaignMap, goalHierarchyMap, filteredCampaignIds)
    );

    const parentCampaigns = budgetObjects?.campaigns?.filter(campaign => !campaign.parentCampaign);
    (parentCampaigns || []).forEach(
      campaign => HierarchyBuilder.assignParentCampaignInGoalHierarchy(campaign, campaignMap, goalHierarchyMap)
    );

    (budgetObjects?.goals || []).forEach(
      goal => HierarchyBuilder.assignGoal(goal, goalHierarchyMap)
    );

    return goalHierarchyMap;
  }
}
