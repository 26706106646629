<span class="spacer">{{ this.clonedText }}</span>
<input type="text"
       class="control"
       [ngClass]="{
         'has-errors': this.nameControl.invalid,
         'edit-mode': editModeActive
       }"
       [maxLength]="maxLength"
       [readonly]="!editModeActive"
       [formControl]="nameControl"
       (ngModelChange)="onModelChange($event)"
       (keydown.Space)="$event.stopPropagation()"
       (focus)="onFocus()"
       (blur)="onBlur()"
       data-html2canvas-ignore>
<span class="count">{{ (nameControl.value?.length || 0) + '/' + maxLength }}</span>
<span *ngIf="errorMsg" class="error">{{ errorMsg }}</span>
