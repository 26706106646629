<div class="goals-details-tab-container">
  <form class="details-content" [formGroup]="goalFormData">
    <details-timestamps
      [created]="currentState?.created"
      [updated]="currentState?.updated"></details-timestamps>
    <div class="details-panel">
      <div class="details-form-field w-50">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
        </div>
      
        <disabled-flag-mat-select
        class="p-form-field-outline no-validate"
        [labelName]="'Goal Type'"
        [isRequired]="false"
        [formData]="goalFormData"
        [isRequired]="true"
        [optionTitle]="['name']"
        [control]="'typeId'"
        [value]="goalFormData.controls['typeId'].value"
        [itemList]="goalTypes"
        [id]="'goalType'"
      ></disabled-flag-mat-select>

      </div>
    </div>

    <div class="details-panel">
      <!-- tags -->
      <div class="details-form-field w-100">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'tags']" class="size-17"></fa-icon>
        </div>
        <tags-control
          #tagsControl
          class="mb-1"
          fieldAppearance="outline"
          [tagsList]="currentState?.tagMappings"
          [autocompleteItems]="tagsAutocompleteItems"
          [editOnly]="true"
          (onAddTag)="addTag($event)"
          (onCreateTag)="createTag($event)"
          (onRemoveTag)="removeTag($event)"
        >
        </tags-control>
      </div>
      <!-- attachments -->
      <div class="details-form-field attachments w-100 mb-1">
        <div class="icon-container">
          <fa-icon [icon]="['far', 'paperclip']"></fa-icon>
        </div>
        <attachments-list
          fieldAppearance="outline"
          [disableInput]="!currentState?.objectId"
          [objectTypeName]="objectType"
          [isReadOnlyMode]="isReadOnlyMode"
          [showAddIcon]="false"
          [attachments]="attachmentsManager.attachments"
          (onAttachFiles)="handleFileAttached.emit($event)"
          (onDeleteAttachment)="handleFileDelete.emit($event)"
          (onDownloadAttachment)="handleFileDownload.emit($event)"
        ></attachments-list>
      </div>
      <!-- notes -->
      <div class="details-form-field w-100">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'clipboard']"></fa-icon>
        </div>
        <linkified-text formControlName="notes" label="Notes" appearance="outline"></linkified-text>
      </div>
    </div>
  </form>
</div>
