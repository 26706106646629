import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COLORS } from 'app/shared/constants/colors.constants';
import { MetricProgressState } from 'app/shared/types/metric-progress-state.type';


@Component({
  selector: 'metric-performance-arrow',
  templateUrl: './metric-performance-arrow.component.html',
  styleUrls: ['./metric-performance-arrow.component.scss']
})
export class MetricPerformanceArrowComponent {
  @Input() progressState: MetricProgressState = MetricProgressState.InTarget;
  @Input() rippleColor = `${COLORS.PRIMARY}40`;
  @Input() rippleRadius = 40;
  @Input() isClickable = true;

  @Output() onClick = new EventEmitter<void>();

  public readonly COLORS = COLORS;
  public readonly ProgressState = MetricProgressState;

  public handleClick() {
    if (this.isClickable) {
      this.onClick.emit();
    }
  }
}
