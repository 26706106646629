import { Configuration } from 'app/app.constants';
import { BudgetObjectParent } from '../types/budget-object-details-state.interface';
import { LightCampaign } from '@shared/types/campaign.interface';
import { LightProgram } from '@shared/types/program.interface';

export const getParentObject = (
  config: Configuration,
  parentGoalId?: number,
  parentCampaignId?: number,
  parentProgramId?: number
): BudgetObjectParent => {
  if (parentProgramId) {
    return { id: parentProgramId, type: config.OBJECT_TYPES.program };
  }

  if (parentCampaignId) {
    return { id: parentCampaignId, type: config.OBJECT_TYPES.campaign };
  }

  if (parentGoalId) {
    return { id: parentGoalId, type: config.OBJECT_TYPES.goal };
  }
};

export const resetParentForObjects = <T extends LightCampaign | LightProgram>(
  objects: T[],
  hasParentToResetPredicate: (obj: T) => boolean,
  objectParentResetter: (obj: T) => void,
  applyNewObjects?: (objects: T[]) => void
): boolean => {
  const affectedObjects = objects.filter(obj => hasParentToResetPredicate(obj));
  if (affectedObjects.length) {
    for (const affectedProgram of affectedObjects) {
      objectParentResetter(affectedProgram);
    }
    applyNewObjects?.(objects);
  }
  return !!affectedObjects.length;
};
