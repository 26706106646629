<button mat-flat-button
        color="primary"
        class="mr-1"
        [disabled]="disabled"
        (click)="addNewFunnel(false)">
  <svg class="mr-05" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M1.11719 3.9375C1.35156 3.39062 1.9375 3 2.52344 3H13.1875C13.7734 3 14.3594 3.39062 14.5938 3.9375C14.8281 4.52344 14.75 5.14844 14.3203 5.61719L10.375 10.0312V16.75C10.375 17.2578 10.1016 17.6875 9.67188 17.8828C9.24219 18.0781 8.73438 18.0391 8.34375 17.7656L5.84375 15.8906C5.53125 15.6562 5.375 15.3047 5.375 14.875V10.0312L1.39062 5.61719C0.960938 5.14844 0.882812 4.52344 1.11719 3.9375Z" fill="white"/>
    <path d="M19.75 3C20.4141 3 21 3.58594 21 4.25C21 4.95312 20.4141 5.5 19.75 5.5H17.25C16.5469 5.5 16 4.95312 16 4.25C16 3.58594 16.5469 3 17.25 3H19.75ZM13.5 10.5C13.5 9.83594 14.0469 9.25 14.75 9.25H19.75C20.4141 9.25 21 9.83594 21 10.5C21 11.2031 20.4141 11.75 19.75 11.75H14.75C14.0469 11.75 13.5 11.2031 13.5 10.5ZM13.5 16.75C13.5 16.0859 14.0469 15.5 14.75 15.5H19.75C20.4141 15.5 21 16.0859 21 16.75C21 17.4531 20.4141 18 19.75 18H14.75C14.0469 18 13.5 17.4531 13.5 16.75Z" fill="white"/>
  </svg>
  Add Empty Funnel
</button>
<button mat-stroked-button
        color="primary"
        [disabled]="disabled"
        (click)="addNewFunnel(true)">
  <svg class="mr-05" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M1.11719 3.9375C1.35156 3.39062 1.9375 3 2.52344 3H13.1875C13.7734 3 14.3594 3.39062 14.5938 3.9375C14.8281 4.52344 14.75 5.14844 14.3203 5.61719L10.375 10.0312V16.75C10.375 17.2578 10.1016 17.6875 9.67188 17.8828C9.24219 18.0781 8.73438 18.0391 8.34375 17.7656L5.84375 15.8906C5.53125 15.6562 5.375 15.3047 5.375 14.875V10.0312L1.39062 5.61719C0.960938 5.14844 0.882812 4.52344 1.11719 3.9375Z" fill="#0063B7"/>
    <path d="M19.75 3C20.4141 3 21 3.58594 21 4.25C21 4.95312 20.4141 5.5 19.75 5.5H17.25C16.5469 5.5 16 4.95312 16 4.25C16 3.58594 16.5469 3 17.25 3H19.75ZM13.5 10.5C13.5 9.83594 14.0469 9.25 14.75 9.25H19.75C20.4141 9.25 21 9.83594 21 10.5C21 11.2031 20.4141 11.75 19.75 11.75H14.75C14.0469 11.75 13.5 11.2031 13.5 10.5ZM13.5 16.75C13.5 16.0859 14.0469 15.5 14.75 15.5H19.75C20.4141 15.5 21 16.0859 21 16.75C21 17.4531 20.4141 18 19.75 18H14.75C14.0469 18 13.5 17.4531 13.5 16.75Z" fill="#0063B7"/>
  </svg>
  Add Example Funnel
</button>
