import { Injectable } from '@angular/core';
import { SortByLabel, SortByValue } from '../types/expense-page.type';
import { SpendingPageSortByBaseService } from '@spending/services/spending-page-sort-by-base-service';
import { EXPENSE_PAGE_SORT_BY } from '@shared/constants/modes.constant';

@Injectable()
export class ExpensePageSortByService extends SpendingPageSortByBaseService {

  protected menuValues = {
    [SortByLabel.Ascending]: SortByValue.Ascending,
    [SortByLabel.Descending]: SortByValue.Descending,
    [SortByLabel.ActualAmount]: SortByValue.ActualAmount,
    [SortByLabel.CreatedDate]: SortByValue.CreatedDate,
    [SortByLabel.Currency]: SortByValue.Currency,
    [SortByLabel.ExpenseType]: SortByValue.ExpenseType,
    [SortByLabel.InvoiceNumber]: SortByValue.InvoiceNumber,
    [SortByLabel.Name]: SortByValue.Name,
    [SortByLabel.Owner]: SortByValue.Owner,
    [SortByLabel.Parent]: SortByValue.Parent,
    [SortByLabel.PONumber]: SortByValue.PONumber,
    [SortByLabel.PlannedAmount]: SortByValue.PlannedAmount,
    [SortByLabel.Segment]: SortByValue.Segment,
    [SortByLabel.Status]: SortByValue.Status,
    [SortByLabel.Timeframe]: SortByValue.Timeframe,
    [SortByLabel.UpdatedDate]: SortByValue.UpdatedDate,
    [SortByLabel.Vendor]: SortByValue.Vendor
  };

  protected DEFAULT_SORT_FIELDS = {
    [SortByLabel.Descending]: SortByValue.Descending,
    [SortByLabel.UpdatedDate]: SortByValue.UpdatedDate
  };
  protected STORAGE_KEY = EXPENSE_PAGE_SORT_BY;

  constructor() {
    super();

    this.saveDefaultSortFields();
  }
}
