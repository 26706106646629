import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { SsoFormInput } from './sso-form.interfaces';
import { SsoFormService } from './sso-form.service';
import { UserManager } from '../../../user/services/user-manager.service';
@Component({
  selector: 'sso-form',
  templateUrl: './sso-form.component.html',
  providers: [SsoFormService]
})
export class SsoFormComponent implements OnInit {
  @ViewChild('ssoFormTag') ssoFormElement;

  public formInputs: SsoFormInput[] = [];
  public actionUrl: string;
  public ssoForm!: FormGroup;

  constructor(
    private hfs: SsoFormService,
    private route: ActivatedRoute,
    private readonly userManager: UserManager) {
  }

  ngOnInit(): void {
    this.formInputs = [];
    if(this.route.snapshot.queryParamMap.get('federation')==='skilljar'){
      this.getSkilljarConfig();
    }
  }

  getSkilljarConfig():  void{
    this.userManager.getCurrentUserObj().subscribe(userId => {
      if(userId){
        this.hfs.getSkilljarAuthDetails().subscribe(event => {
          const skilljarDetails = event["data"];
          this.actionUrl = skilljarDetails["url"];
          this.formInputs.push({
            key: 'SAMLResponse',
            value: skilljarDetails["SAMLResponse"]
          });
          this.formInputs.push({
            key: 'RelayState',
            value: ''
          });
          this.ssoForm = this.hfs.toFormGroup(this.formInputs);
          setTimeout(()=>{
            this.submitForm();
          }, 0)
        });
      }
    });
  }

  submitForm(): void {
    this.ssoFormElement.nativeElement.submit();
  }
}
