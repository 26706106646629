<div class="metric-value-editor">
  <div class="editor-header" *ngIf="!showHistoryExpanded">
    <details-widget-title text="Updated Metric" [showCount]="false" [secondaryText]="lastUpdatedInfo">
      <fa-duotone-icon slot="icon" [icon]="['fad', 'pencil']"></fa-duotone-icon>
    </details-widget-title>
    <ng-content select="[integration-menu]"></ng-content>
  </div>
  <div class="editor-body">
    <div class="editor-records">
      <mat-accordion>
        <mat-expansion-panel #metricHistory hideToggle [disabled]="true">
          <mat-expansion-panel-header class="editor-head-row" collapsedHeight="54px" expandedHeight="54px">
            <div [class.short-record-grid]="showHistoryExpanded" class="record-grid grid-header" [ngStyle]="{'column-gap': showHistoryExpanded ? '0' : '16px'}">
              <span></span>
              <span *ngIf="showHistoryExpanded"></span>
              <span>Date</span>
              <span class="numeric">Change in Value</span>
              <span class="numeric">Running Total</span>
              <span>Updated On</span>
              <span *ngIf="showHistoryExpanded"></span>
              <span></span>
            </div>
            <link-button *ngIf="!showHistoryExpanded" class="history-toggle" (onClick)="metricHistory.toggle()" [disabled]="!editorStructuredData?.length" [matTooltip]="!editorStructuredData?.length ? disabledHistoryTooltip : ''" matTooltipClass="dark-theme-tooltip above" matTooltipPosition="above">
              <fa-icon [icon]="['far', 'chevron-down']" [flip]="metricHistory.expanded && 'vertical'"></fa-icon>
              {{ metricHistory.expanded ? 'Close' : 'View' }} history
            </link-button>
          </mat-expansion-panel-header>
          <div class="history-container scroll-content-wrap">
            <div [class.scroll-content]="!showHistoryExpanded">
              <ng-container *ngFor="let record of editorStructuredData; trackBy: trackByObjectData">
                <mat-accordion *ngIf="record.type === metricValueUpdateType.monthTotal && record.monthChildItems?.length; else defaultItem">
                  <mat-expansion-panel #monthRoot hideToggle [disabled]="true">
                    <div class="month-items">
                      <ng-container *ngFor="let childItem of record.monthChildItems; trackBy: trackByObjectData">
                        <metric-value-record
                          [showHistoryExpanded]="showHistoryExpanded"
                          [recordData]="childItem"
                          [isReadOnly]="isReadOnly"
                          [originUpdateType]="originUpdateType"
                          [originObjectId]="originObjectId"
                          [displayDecimal]="displayDecimal"
                          [tooltipText]="originUpdateType === childItem.type &&
                            originObjectId === childItem.objectId ? originObjectTooltip : ''"
                          (updateRecord)="receiveUpdates($event)"
                          (removeRecord)="removeRecord.emit(childItem)"
                          (onOpenObjectDetails)="openObjectDetailsPage($event)"
                        >
                        </metric-value-record>
                      </ng-container>
                    </div>
                    <mat-expansion-panel-header collapsedHeight="54px" expandedHeight="54px" [class.month-header]="!showHistoryExpanded">
                      <metric-value-record
                        [showHistoryExpanded]="showHistoryExpanded"
                        [recordData]="record"
                        [isReadOnly]="isReadOnly"
                        [monthExpanded]="monthRoot.expanded"
                        [displayDecimal]="displayDecimal"
                        (updateRecord)="receiveUpdates($event)"
                        (toggleMonth)="monthRoot.toggle()">
                      </metric-value-record>
                    </mat-expansion-panel-header>
                  </mat-expansion-panel>
                </mat-accordion>
                <ng-template #defaultItem>
                  <metric-value-record
                    [showHistoryExpanded]="showHistoryExpanded"
                    [recordData]="record"
                    [isReadOnly]="isReadOnly"
                    [tooltipText]="originUpdateType === record.type &&
                      originObjectId === record.objectId ? originObjectTooltip : ''"
                    [originUpdateType]="originUpdateType"
                    [originObjectId]="originObjectId"
                    [displayDecimal]="displayDecimal"
                    (removeRecord)="removeRecord.emit(record)"
                    (updateRecord)="receiveUpdates($event)"
                    (onOpenObjectDetails)="openObjectDetailsPage($event)"
                  >
                  </metric-value-record>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="editor-footer">
    <metric-value-record
    [showHistoryExpanded]="showHistoryExpanded"
      [recordData]="currentMonthTotal"
      [isReadOnly]="isReadOnly"
      [originUpdateType]="originUpdateType"
      [originObjectId]="originObjectId"
      [displayDecimal]="displayDecimal"
      [budgetTodayDate]="budgetTodayDate"
      tooltipText="Current Month Total"
      (updateRecord)="receiveUpdates($event)"
      (removeRecord)="removeRecord.emit(currentMonthTotal)"
      (onOpenObjectDetails)="openObjectDetailsPage($event)"
    ></metric-value-record>
    <metric-value-record
    [showHistoryExpanded]="showHistoryExpanded"
      *ngIf="!isReadOnly"
      [isReadOnly]="isReadOnly"
      [recordData]="recordOnCreation"
      [isCreation]="true"
      [displayDecimal]="displayDecimal"
      [budgetTodayDate]="budgetTodayDate"
      tooltipText="New Metric Value"
      (updateRecord)="receiveUpdates($event, true)"
      [disabledDates]="datepickerDisabledDates"
    ></metric-value-record>
  </div>
</div>
