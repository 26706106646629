import { Injectable } from '@angular/core';
import { WidgetType } from '../types/widget.interface';

@Injectable({
  providedIn: 'root'
})
export class WidgetConfigService {
  public readonly CONFIG_BY_TYPE = {
    [WidgetType.OPEN_TASKS]: {
      type: WidgetType.OPEN_TASKS,
      grid: {
        width: 4,
        height: 2
      },
      hideIfEmpty: true
    },
    [WidgetType.OVERDUE_EXPENSES]: {
      type: WidgetType.OVERDUE_EXPENSES,
      grid: {
        width: 4,
        minWidth: 4,
        height: 3,
        minHeight: 2,
      },
      gridMd: {
        offsetX: 2
      },
      hideIfEmpty: true
    },
    [WidgetType.OPEN_EXPENSES]: {
      type: WidgetType.OPEN_EXPENSES,
      grid: {
        width: 4,
        height: 3,
        offsetX: 3
      }
    },
    [WidgetType.HISTORY]: {
      id: WidgetType.HISTORY,
      type: WidgetType.HISTORY,
      grid: {
        width: 3,
        height: 3,
        minHeight: 2
      }
    },
    [WidgetType.UPDATE_METRICS]: {
      type: WidgetType.UPDATE_METRICS,
      grid: {
        width: 4,
        height: 4,
        offsetX: 2
      },
      hideIfEmpty: true
    },
    [WidgetType.BUDGET_MINUS_EXPENSES]: {
      type: WidgetType.BUDGET_MINUS_EXPENSES,
      grid: {
        width: 2,
        height: 2
      }
    },
    [WidgetType.CAMPAIGNS]: {
      type: WidgetType.CAMPAIGNS,
      grid: {
        width: 3,
        height: 2
      },
      gridMd: {
        offsetX: 2
      },
    },
    [WidgetType.METRICS_PROGRESS]: {
      type: WidgetType.METRICS_PROGRESS,
      grid: {
        width: 3,
        height: 3,
        minHeight: 2,
        offsetX: 4,
         offsetY: 5,
      },
      gridMd: {
        offsetX: 3,
        offsetY: 7,
      },
    },
    [WidgetType.BUDGET_SPENT]: {
      type: WidgetType.BUDGET_SPENT,
      grid: {
        width: 1,
        height: 2,
        offsetX: 1
      },
      gridMd: {
        offsetX: 3
      },
    },
    [WidgetType.SPEND_PROGRESS]: {
      type: WidgetType.SPEND_PROGRESS,
      grid: {
        width: 1,
        height: 2
      }
    },
    [WidgetType.CAMPAIGN_SPENT]: {
      type: WidgetType.CAMPAIGN_SPENT,
      grid: {
        width: 1,
        height: 2
      }
    },
    [WidgetType.ESTIMATED_BUSINESS_VALUE]: {
      type: WidgetType.ESTIMATED_BUSINESS_VALUE,
      grid: {
        width: 5,
        height: 4,
      },
      hideIfEmpty: true
    },
    [WidgetType.ASSIGN_SEGMENTS_GOOGLE_CAMPAIGNS]: {
      type: WidgetType.ASSIGN_SEGMENTS_GOOGLE_CAMPAIGNS,
      grid: {
        width: 3,
        height: 3,
        minHeight: 2
      },
      hideIfEmpty: true
    }
  };
}
