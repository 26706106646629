import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { filter, switchMap } from 'rxjs/operators';
import { overlayConfigFactory } from 'ngx-modialog-7';
import { Modal, BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { Validations } from 'app/app.validations';
import { CommonService } from 'app/shared/services/backend/common.service'
import { CurrencyService } from 'app/shared/services/backend/currency.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { UserService } from 'app/shared/services/backend/user.service';
import { SuperadminModalComponent } from '../modals/super-admin/superadmin-modal.component';
import { SuperadminLogsModal } from '../modals/super-admin-logs/superadmin-logs-modal';
import { SuperadminService } from '../shared/superadmin.service';
import { HttpStatusCode } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ProfileUserRole } from '../../app/user/types/profile-user-role.enum';

@Component({
  selector: 'app-root',
  templateUrl: './manage-superadmins.component.html',
  styleUrls: ['./manage-superadmins.component.scss']
})
export class ManageSuperadminsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  superadminsList = [];
  isGodMode = false;
  godModeId: number;
  godModeUserName: string;

  currentUserDetails: any;
  superadminModalConfig = {
    width: '760px',
    data: { superadmin_id : '' }
  }

  constructor(
    public readonly configuration: Configuration,
    public readonly utilityService: UtilityService,
    public readonly router: Router,
    public readonly validations: Validations,
    public readonly commonService: CommonService,
    public readonly modal: Modal,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly superadminService: SuperadminService,
    public readonly currencyService: CurrencyService,
    private readonly companyDataService: CompanyDataService,
    private readonly userManager: UserManager,
    private readonly userService: UserService,
    public dialog: MatDialog
  ) {
    this.subscriptions.push(
      this.utilityService.superadminPropertyChanged$.subscribe(
      () => this.getSuperadminList()
      )
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.userManager.currentUserId$
        .pipe(
          filter(userId => userId != null),
          switchMap(userId => this.userService.getUser(userId))
        )
        .subscribe(userDetails => {
          this.currentUserDetails = userDetails;
          if (userDetails) {
            this.setIsGodMode();
          }
        })
    );

    this.getSuperadminList();
  }

  private setIsGodMode(): void {
    this.isGodMode = this.currentUserDetails?.user_profile_detail?.role === ProfileUserRole.GodMode;
  }

  getSuperadminList() {
    this.superadminsList = [];
    this.utilityService.showLoading(true);
    this.subscriptions.push(
      this.superadminService.getSuperadminList().subscribe(
      data => this.success(data, 'getSuperadminList'),
      error => this.handleError(error)
      )
    );
  }

  addSuperadmin(): void {
    this.dialog.open(SuperadminModalComponent, this.superadminModalConfig);
  }

  editSuperadmin(id: string): void {
    this.superadminModalConfig.data.superadmin_id = id;
    this.dialog.open(SuperadminModalComponent, this.superadminModalConfig);
  }

  deleteSuperadmin(id) {
    if (confirm(this.validations.ValiditionMessages.DELETE_SUPERADMIN_CONFIRM)) {
      this.utilityService.showLoading(true);
      this.subscriptions.push(
        this.superadminService.deleteSuperadmin(id).subscribe(
        data => this.success(data, 'deleteSuperadmin'),
        error => this.handleError(error)
        )
      );
    }
  }

  openSuperadminLogs() {
    return this.modal.open(
      SuperadminLogsModal,
      overlayConfigFactory({}, BSModalContext)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  success(data, type) {
    if (Number(data?.status) === HttpStatusCode.Ok) {
      if (type === 'deleteSuperadmin') {
        this.utilityService.showToast({ Title: '', Message: data.message, Type: 'success' });
        this.getSuperadminList();
      } else if (type === 'getSuperadminList') {
        this.superadminsList = this.userService.addProfilePictures(data.data, 'user_image', 'id');
        const godModeUser = (this.superadminsList || []).find(user => user.user_type === 'god_mode_user');
        this.godModeUserName = `${godModeUser?.first_name || ''} ${godModeUser?.last_name || ''}`;
      }
    } else {
      this.utilityService.showToast({ Title: '', Message: data.message, Type: 'error' });
    }
    this.utilityService.showLoading(false);
  }

  handleError(error) {
    if (error.hasOwnProperty('message')) {
      this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
      this.utilityService.showLoading(false);
    } else if (error.hasOwnProperty('detail')) {
      this.utilityService.showLoading(false);
      this.utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
    }
  }
}
