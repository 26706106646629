<div class="auth-container">
  <header>
    <i class="top-row"></i>
    <span class="auth-icon">
      <i [ngClass]="['icon', data.iconType]"></i>
      <i class="error-icon"></i>
    </span>
  </header>
  <div class="auth-content">
    <h1 class="auth-title">{{data.title}}</h1>
    <p class="auth-message" [innerHTML]="data.content"></p>
  </div>
  <footer>
    <a *ngIf="data.userType === 'admin'" class="left" (click)="onCancel()">Cancel</a>
    <button *ngIf="data.userType === 'admin'" mat-flat-button color="primary" class="right" (click)="reAuth()">Reauthenticate</button>    
    <button *ngIf="data.userType === 'user'" mat-flat-button color="primary" (click)="onCancel()">OK</button>
  </footer>
</div>