import { ComponentRef, Directive, Input } from '@angular/core';
import { BaseComponentPortalTooltipDirective } from 'app/shared/directives/base-component-portal-tooltip.directive';
import { BudgetAllocationActionTooltipComponent } from '../components/budget-allocation-action-tooltip/budget-allocation-action-tooltip.component';


@Directive({
  selector: '[budgetAllocationActionTooltip]'
})
export class BudgetAllocationActionTooltipDirective extends BaseComponentPortalTooltipDirective {
  @Input() withDnd = false;
  @Input() actionTooltipContext: string;

  protected componentClassRef = BudgetAllocationActionTooltipComponent;

  public onComponentRefCreated(ref: ComponentRef<any>): void {
    ref.instance.withDnd = this.withDnd;
    ref.instance.tooltipContext = this.actionTooltipContext;
  }
}
