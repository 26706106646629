import { Component, Input } from '@angular/core';

@Component({
  selector: 'drop-down-arrow',
  templateUrl: './drop-down-arrow.component.html',
  styleUrls: ['./drop-down-arrow.component.scss']
})
export class DropDownArrowComponent {
  @Input() opened = false;
}
