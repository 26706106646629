import { inject, Injectable, NgZone } from '@angular/core';
import PptxGen from 'pptxgenjs';
import html2canvas from 'html2canvas';
import { MetricFunnelRow } from '@common-lib/lib/corporate-page/metric-funnels.types';
import { FONT_FAMILY, SLIDE_HEIGHT_TO_WIDTH_RATIO, COLORS, ASSETS } from './funnel-presentation.constants';
import { AppConfigService } from '../../services/app-config.service';

export interface FunnelPresentationConfig {
  beforeFunnelSnapshotCreation?: (funnel: HTMLElement) => void;
  funnelElementSelector: string;
  data: {
    targetMetric?: string;
    targetValue?: string;
    targetMetricWithCurrency?: boolean;
    budgetValue?: string;
    currency?: string;
    funnelRows: MetricFunnelRow[];
  };
  funnelOnly: boolean;
  excludeDemoSlide?: boolean;
}

@Injectable()
export class FunnelPresentationService {
  private readonly appConfigService = inject(AppConfigService);
  private readonly outputFileName = 'Operational Marketing Metric Funnel & Builder - Powerpoint Slide Output';
  private config: FunnelPresentationConfig;
  private today: Date;
  private presentation: PptxGen;

  constructor(private readonly zone: NgZone) {}

  private getImageDimensions(dataUrl: string): Promise<{ width: number; height: number; }> {
    return new Promise(resolved => {
      const image = new Image();
      image.onload = () => resolved({
        width: image.width,
        height: image.height
      });
      image.src = dataUrl;
    });
  }

  private async calculateFunnelSnapshotParams(imageData: string, maxHeight: number): Promise<{
    slideImageWidth: number;
    slideImageHeight: number;
    slideImageX: number;
  }> {
    const dimensions = await this.getImageDimensions(imageData);
    const imageHeightToWidthRatio = dimensions.height / dimensions.width;
    const maxSlideWidth = 100;
    let slideImageHeight = imageHeightToWidthRatio * 100 / SLIDE_HEIGHT_TO_WIDTH_RATIO;
    let slideImageWidth = maxSlideWidth;

    if (slideImageHeight > maxHeight) {
      slideImageHeight = maxHeight;
      slideImageWidth = maxHeight / imageHeightToWidthRatio * SLIDE_HEIGHT_TO_WIDTH_RATIO;
    }

    const slideImageX = (maxSlideWidth - slideImageWidth) / 2;

    return {
      slideImageWidth,
      slideImageHeight,
      slideImageX
    };
  }

  private normalizeFunnelPadding(funnel: HTMLElement) {
    const { data: { funnelRows }} = this.config;
    let baseRowReached = false;
    let rowsAboveBase = 0;
    let rowsBelowBase = 0;

    funnelRows.forEach(row => {
      if (row.isBaseRow) {
        baseRowReached = true;
        rowsAboveBase++;
        rowsBelowBase++;
        return;
      }

      baseRowReached
        ? rowsBelowBase++
        : rowsAboveBase++;
    });

    const baseRowHeight = Math.max(rowsAboveBase, rowsBelowBase);
    const padding = 30 + baseRowHeight * 15;

    funnel.style.padding = `0 ${padding}px`;
  }

  private createFunnelSnapshot(): Promise<string> {
    const { funnelElementSelector, beforeFunnelSnapshotCreation } = this.config;
    const funnel = document.querySelector(funnelElementSelector).cloneNode(true) as HTMLElement;
    if (typeof beforeFunnelSnapshotCreation === 'function') {
      beforeFunnelSnapshotCreation(funnel);
    }
    funnel.style.position = 'absolute';
    funnel.style.top = '-100%';
    funnel.style.left = '-100%';
    funnel.style.zIndex = '-9999';
    this.normalizeFunnelPadding(funnel);
    window.document.body.appendChild(funnel);

    return html2canvas(funnel, { scale: 2 })
      .then(canvas => {
        window.document.body.removeChild(funnel);
        return canvas.toDataURL('image/png');
      });
  }

  private addGenericSlideContent(slide: PptxGen.Slide, titleSlide = false) {
    const logoPositioning: PptxGen.ImageProps = titleSlide ?
      {
        x: '3.1%',
        y: '7.6%',
        w: '12.6%',
        h: '8.3%',
      } :
      {
        x: '2.2%',
        y: '90%',
        w: '9%',
        h: '6.3%',
      };
    slide.addImage({
      ...logoPositioning,
      path: ASSETS.LOGO_SMALL
    });

    if (!titleSlide) {
      slide.slideNumber = {
        x: '97%',
        y: '93%',
        fontFace: FONT_FAMILY.ARIAL,
        fontSize: 8,
        color: COLORS.LIGHT_GRAY
      };
    }
  }

  private initPresentation(config: FunnelPresentationConfig) {
    this.today = new Date();
    this.presentation = new PptxGen();
    this.config = config;
  }

  private addTitleSlide() {
    const slide = this.presentation.addSlide();
    const currentDateString = this.getCurrentDateString();

    slide.addImage({
      x: 0,
      y: 0,
      w: '100%',
      h: '100%',
      path: ASSETS.TITLE_BG
    });
    this.addGenericSlideContent(slide, true);

    slide.addText([
      {
        text: 'Operational Marketing',
        options: { breakLine: true }
      },
      { text: 'Metric Funnel & Budget' },
    ], {
      x: '5.3%',
      y: '47%',
      w: '100%',
      align: 'left',
      color: COLORS.WHITE,
      fontFace: FONT_FAMILY.AVENIR,
      fontSize: 40,
      lineSpacing: 48,
      bold: false
    });
    slide.addText(`Created ${currentDateString}`, {
      x: '5.3%',
      y: '64%',
      w: '100%',
      h: '0.02%',
      align: 'left',
      color: COLORS.WHITE,
      fontFace: FONT_FAMILY.AVENIR,
      fontSize: 18,
      bold: false
    });
  }

  private async addFunnelSlide() {
    const slide = this.presentation.addSlide();
    const slideTitle = 'Operational Marketing Metric Funnel';
    slide.background = {color: COLORS.WARM_YELLOW};
    this.addGenericSlideContent(slide);

    const maxFunnelHeightPercentage = 68;
    const funnelImageData = await this.createFunnelSnapshot();
    const funnelImageParams = await this.calculateFunnelSnapshotParams(funnelImageData, maxFunnelHeightPercentage);

    slide.addText(slideTitle, {
      x: '5%',
      y: '12%',
      w: '100%',
      align: 'left',
      color: COLORS.GRAY,
      fontFace: FONT_FAMILY.AVENIR,
      fontSize: 27,
      bold: true
    });
    slide.addImage({
      x: `${funnelImageParams.slideImageX}%`,
      y: '22%',
      w: `${funnelImageParams.slideImageWidth}%`,
      h: `${funnelImageParams.slideImageHeight}%`,
      data: funnelImageData
    });
  }

  private addBudgetSlide() {
    const { data } = this.config;
    const slide = this.presentation.addSlide();
    const slideTitle = 'Required Marketing Budget';
    const currentPrefixText = data.targetMetricWithCurrency ? `${data.currency} ` : '';
    slide.background = {color: COLORS.WARM_YELLOW};

    this.addGenericSlideContent(slide);
    slide.addText(slideTitle, {
      x: '5%',
      y: '12%',
      w: '100%',
      align: 'left',
      color: COLORS.GRAY,
      fontFace: FONT_FAMILY.AVENIR,
      fontSize: 27,
      bold: true
    });
    slide.addText([
      { text: `In order to achieve your ${data.targetMetric?.toLowerCase()} target of ` },
      {
        text: `${currentPrefixText}${data.targetValue}`,
        options: { color: COLORS.MAGENTA, breakLine: true }
      },
      { text: 'you need a marketing budget of' },
      { text: ` ${data.currency} ${data.budgetValue}`, options: { color: COLORS.MAGENTA } }
    ], {
      x: '5%',
      y: '45%',
      w: '100%',
      align: 'left',
      lineSpacing: 30,
      color: COLORS.GRAY,
      fontFace: FONT_FAMILY.ARIAL,
      fontSize: 23,
      bold: false
    });
  }

  private addScheduleDemoSlide() {
    const slide = this.presentation.addSlide();
    slide.background = {color: COLORS.WARM_YELLOW};

    this.addGenericSlideContent(slide);
    slide.addText([
      {
        text: 'Schedule a demo',
        options: {
          underline: { style: 'heavy', color: COLORS.MAGENTA },
          hyperlink: { url: this.appConfigService.config.schedule_demo_url }
        }
      },
      { text: ' with',
        options: { bold: false } },
      { text: ' Planful',
        options: { bold: true }
      },
      { text: ' to Manage Your Marketing Goals & Budget' },
    ], {
      x: '3%',
      y: '12%',
      w: '100%',
      align: 'left',
      color: COLORS.GRAY,
      fontFace: FONT_FAMILY.ARIAL,
      fontSize: 20,
      bold: false
    });
    slide.addImage({
      x: '5%',
      y: '22.4%',
      w: '90%',
      h: '42%',
      path: ASSETS.FUNNEL_SAMPLE
    });
    slide.addText([
      {
        text: `Upgrade to`
      },
      {
        text: ' Planful for Marketing',
        options: { bold: true }
      },
      {
        text: ' to unlock advanced capabilities for managing your marketing ',
        options: { breakLine: true }
      },
      {
        text: 'performance against your funnel metrics and your budget throughout the year.',
      },
    ], {
      x: '0%',
      y: '77%',
      w: '100%',
      align: 'center',
      color: COLORS.GRAY,
      fontFace: FONT_FAMILY.ARIAL,
      fontSize: 14,
      bold: false
    });
  }

  private downloadAsFile() {
    return this.presentation.writeFile({ fileName: this.outputFileName });
  }

  public generate(config: FunnelPresentationConfig, callback: Function = null) {
    this.zone.runOutsideAngular(async () => {
      this.initPresentation(config);
      this.addTitleSlide();
      await this.addFunnelSlide();

      if (!config.funnelOnly) {
        this.addBudgetSlide();
      }

      if (!config.excludeDemoSlide) {
        this.addScheduleDemoSlide();
      }

      await this.downloadAsFile();

      if (callback) {
        this.zone.run(() => callback());
      }
    });
  }

  private getCurrentDateString(): string {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = today.getMonth();
    const month = monthNames[monthIndex];
    const year = today.getFullYear();

    return `${day} ${month} ${year}`;
  }
}
