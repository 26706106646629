import { Injectable } from '@angular/core';
import { BudgetSegmentAccess } from '../types/segment.interface';
import { SharedCostRule } from '../types/shared-cost-rule.interface';
import { BudgetObjectSegmentDO } from '../types/budget-object-segment-do.interface';

@Injectable({
  providedIn: 'root'
})
export class ObjectAccessManagerService {
  private getSegmentIDs(segments: BudgetSegmentAccess[] = []): number[] {
    return segments.map(segment => Number(segment.id));
  }

  private hasSegmentAccess(segmentIDs: number[], targetSegmentID: number): boolean {
    return targetSegmentID != null && segmentIDs.includes(Number(targetSegmentID));
  }

  private hasRuleAccess(rule: SharedCostRule, segmentIDs: number[]): boolean {
    return rule.segments.some(ruleSegment => segmentIDs.includes(ruleSegment.id));
  }

  private hasStrictRuleAccess(rule: SharedCostRule, segmentIDs: number[]): boolean {
    return rule.segments.every(ruleSegment => segmentIDs.includes(ruleSegment.id));
  }

  /**
   * strictRuleCheck {boolean} - param to define SharedRule access logic
   *   if {true} - all rule's segments should be accessible
   *   if {false} - any rule's segments should be accessible
   */
  private hasSharedRuleAccess(
    targetRuleID: number,
    sharedCostRules: SharedCostRule[] = [],
    segmentIDs: number[] = [],
    strictRuleCheck = false
  ): boolean {
    const targetRule = targetRuleID != null && sharedCostRules.find(scr => Number(scr.id) === Number(targetRuleID));
    if (!targetRule) {
      return false;
    }

    const ruleAccess = this.hasRuleAccess(targetRule, segmentIDs);
    const strictRuleAccess = this.hasStrictRuleAccess(targetRule, segmentIDs);

    return strictRuleCheck ? strictRuleAccess : ruleAccess;
  }

  /**
   * strictRuleCheck {boolean} - param to define SharedRule access logic
   *   if {true} - all rule's segments should be accessible
   *   if {false} - any rule's segments should be accessible
   */
  public hasAccessBySegmentData(
    targetObject: BudgetObjectSegmentDO,
    segments: BudgetSegmentAccess[] = [],
    sharedCostRules: SharedCostRule[] = [],
    strictRuleCheck = false
  ): boolean {
    const { company_budget_segment1: targetSegmentID, split_rule: targetSharedRuleID } = targetObject;
    if (!targetSegmentID && !targetSharedRuleID) {
      return true;
    }
    const segmentIDs = this.getSegmentIDs(segments);
    return this.hasSegmentAccess(segmentIDs, targetSegmentID) ||
      this.hasSharedRuleAccess(targetSharedRuleID, sharedCostRules, segmentIDs, strictRuleCheck);
  }

  /**
   * Filter out 'restricted' rules based on allowed segments list
   */
  public getAllowedSharedCostRules(
    sharedCostRules: SharedCostRule[] = [],
    allowedSegments: BudgetSegmentAccess[] = [],
    strictRuleCheck = false
  ): SharedCostRule[] {
    const segmentIDs = this.getSegmentIDs(allowedSegments);

    return sharedCostRules.filter(scr => (
      strictRuleCheck ?
        this.hasStrictRuleAccess(scr, segmentIDs) :
        this.hasRuleAccess(scr, segmentIDs)
    ));
  }
}
