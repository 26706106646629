import { ObjectLocalFilter } from '../filters.interface';
import { PlanObjectDO } from 'app/shared/types/plan.type';

export class PlanObjectByStatusFilter implements ObjectLocalFilter<PlanObjectDO> {
  private allowedStatuses: string[];

  private static adaptStatusNames(sourceStatuses: string[]): string[] {
    return sourceStatuses && sourceStatuses.map(
      status => status.split(' ').map(namePart => namePart.toLowerCase()).join('_')
    );
  }

  constructor(statuses: string[]) {
    this.allowedStatuses = PlanObjectByStatusFilter.adaptStatusNames(statuses);
  }

  satisfies(object: PlanObjectDO): boolean {
    if (!object?.status_totals) {
      return false;
    }

    if (!this.allowedStatuses?.length) { // No status restrictions!
      return true;
    }

    return this.allowedStatuses.some(status => object.status_totals[status]);
  }
}
