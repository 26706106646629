export enum TableRowName {
  CumulativeValue = 'cumulative_value',
  MonthlyValue = 'monthly_value',
  MonthlyCPO = 'monthly_cpo',
  MonthlyROI = 'monthly_roi',
  QuarterlyCPO = 'quarterly_cpo',
  QuarterlyROI = 'quarterly_roi',
}

export interface TableRow {
  name: TableRowName;
  label: string;
  numberFormat: string;
  outOfDate: boolean;
}

export interface TableColumn {
  name: string;
  label: string;
  className?: string;
}
