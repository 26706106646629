import { inject, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest, concat, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { catchError, delay, filter, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { CompanyDataService, GLCode, Vendor } from 'app/shared/services/company-data.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { Goal } from 'app/shared/types/goal.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';
import { Metric } from '../components/details-metrics/details-metrics.type';
import { MetricMappingDO, MetricService } from 'app/shared/services/backend/metric.service';
import { Campaign, CampaignDO, CampaignTypeDO, LightCampaign } from 'app/shared/types/campaign.interface';
import { LightProgram, Program, ProgramDO, ProgramTypeDO } from 'app/shared/types/program.interface';
import { AllocationMode, BudgetObjectAllocation } from 'app/shared/types/budget-object-allocation.interface';
import {
  AllocatableObject,
  AllocatableObjectState,
  BudgetDataObject,
  BudgetObjectDetailsState,
  BudgetObjectParent,
  GroupingObject
} from '../types/budget-object-details-state.interface';
import { getDiff } from 'recursive-diff';
import { CompanyCurrencyService } from 'app/shared/services/backend/company-currency.service';
import { CompanyExchangeRateService } from 'app/shared/services/backend/company-exchange-rate.service';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { SegmentedOptionValue } from '../types/budget-object-segment-options.interface';
import { TagMapping } from 'app/shared/types/tag-mapping.interface';
import { TagMappingDO } from 'app/shared/services/backend/tag.service';
import { Configuration } from 'app/app.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { BudgetObjectChange, BudgetObjectChangeEvent } from '../types/budget-object-change.interface';
import { BudgetObjectDetailsComponent } from '../types/budget-object-details-component.interface';
import { FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { Router } from '@angular/router';
import { BudgetObjectTotals } from 'app/shared/types/budget-object-totals.interface';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { adsExpenseSources, ExpenseDO } from 'app/shared/types/expense.interface';
import { ProgramService } from 'app/shared/services/backend/program.service';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { DetailsState } from '../types/details-state.interface';
import { messages, objectPlaceholderName } from '../messages';
import { BudgetObjectCloneResponse } from 'app/shared/types/budget-object-clone-response.interface';
import { createDeepCopy, getCurrentBudget$, sumAndRound } from 'app/shared/utils/common.utils';
import { BudgetObjectMetricsService } from './budget-object-metrics.service';
import { DetailsService } from '../types/budget-object-details-service.interface';
import { HistoryObjectLogType } from 'app/shared/types/history-object-log-type.type';
import { HistoryService } from 'app/shared/services/history.service';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';
import { BudgetObjectSegmentDO } from 'app/shared/types/budget-object-segment-do.interface';
import { ExtendedUserDO } from 'app/shared/types/user-do.interface';
import { HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { LocationService } from './location.service';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { HierarchyViewMode } from '@spending/types/expense-page.type';
import { getTodayFixedDate } from '@shared/utils/budget.utils';
import { MetricMappingCalculationDO } from '@shared/services/backend/metric-calculation.service';
import { findItemInHierarchy } from '@shared/components/hierarchy-select/hierarchy-select.utils';
import { CompanyService } from '@shared/services/backend/company.service';
import { parseDateString } from '../components/containers/campaign-details/date-operations';
import { ObjectTypeService } from '@shared/services/backend/object-type.service';
import { BudgetObjectEvent } from '../types/budget-object-event.interface';
import { getMetricSelectItems } from '../components/details-metrics/metric-masters-list/metric-masters-list.component';
import { SelectItem } from '@shared/types/select-groups.interface';

export const CEGObjectKey = 'CEGObjectKey';

@Injectable()
export class BudgetObjectDetailsManager {
  private readonly companyDataService = inject(CompanyDataService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly companyService = inject(CompanyService);
  private readonly metricService = inject(MetricService);
  public readonly companyCurrencyService = inject(CompanyCurrencyService);
  public readonly companyExchangeRateService = inject(CompanyExchangeRateService);
  private readonly configuration = inject(Configuration);
  private readonly budgetObjectService = inject(BudgetObjectService);
  private readonly utilityService = inject(UtilityService);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly campaignService = inject(CampaignService);
  private readonly programService = inject(ProgramService);
  private readonly expenseService = inject(ExpensesService);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly router = inject(Router);
  private readonly metricsManager = inject(BudgetObjectMetricsService);
  private readonly historyService = inject(HistoryService);
  private readonly objectAccessManager = inject(ObjectAccessManagerService);
  private readonly locationService = inject(LocationService);
  private readonly objectTypeService = inject(ObjectTypeService);

  private readonly budgetObjectChanged = new Subject<BudgetObjectChange>();
  private readonly _campaignMappingsChanged$ = new Subject<void>();
  private readonly budgetObjectEvent = new Subject<BudgetObjectEvent>();
  private snackbarDuration = 7000;
  public readonly budgetObjectChanged$ = this.budgetObjectChanged.asObservable();
  public readonly campaignMappingsChanged$ = this._campaignMappingsChanged$.asObservable();
  public readonly budgetObjectEvent$ = this.budgetObjectEvent.asObservable();
  public readonly unsavedCustomTypeId = 0;
  public readonly MESSAGE_GETTERS = {
    NO_OBJECT_ACCESS: (objectType) => `Sorry, you do not have permission to open this ${objectType}`
  };

  public readonly enterCustomTypeOption: BudgetObjectType = {
    id: -1,
    name: '<Enter Custom Type>',
    isCustom: false,
    createdDate: null,
    updatedDate: null,
    companyId: null
  };
  public maxObjectNameLength = this.configuration.MAX_TEXT_INPUT_LENGTH;
  public maxObjectNameLengthForExternalIntegration = this.configuration.MAX_TEXT_INPUT_LENGTH_EXTERNAL_INTEGRATION;

  public static getTotalSpend(objectTotals: BudgetObjectTotals) {
    let totalSpend = 0;
    const expenses = objectTotals && objectTotals.expenses_by_timeframes;
    if (expenses) {
      totalSpend = Object.keys(expenses).reduce((sum, id) => sum + expenses[id], 0);
    }
    return totalSpend;
  }

  getCompanyId(): Observable<number> {
    return this.companyDataService.selectedCompanySnapshot ?
      of(this.companyDataService.selectedCompanySnapshot.id) :
      this.companyDataService.selectedCompany$.pipe(
        skipWhile(cmp => cmp == null),
        map(company => company?.id)
      );
  }

  getCurrentBudget(): Observable<Budget> {
    return getCurrentBudget$(this.budgetDataService);
  }

  getBudgets(currentBudgetId?: number): Observable<Budget[]> {
    const budgets$ =
      this.budgetDataService.budgetListSnapshot?.length ?
      concat(of(this.budgetDataService.budgetListSnapshot), this.budgetDataService.companyBudgetList$) :
      this.budgetDataService.companyBudgetList$;

    return budgets$.pipe(
      map(budgets => budgets ? budgets.filter(budget => budget.id !== currentBudgetId) : [])
    );
  }

  getTimeframes(): Observable<BudgetTimeframe[]> {
    return this.budgetDataService.timeframesSnapshot ?
      concat(of(this.budgetDataService.timeframesSnapshot), this.budgetDataService.timeframeList$) :
      this.budgetDataService.timeframeList$;
  }

  getSegmentGroups(): Observable<SegmentGroup[]> {
    return this.budgetDataService.segmentGroupsSnapshot ?
      concat(of(this.budgetDataService.segmentGroupsSnapshot), this.budgetDataService.segmentGroupList$) :
      this.budgetDataService.segmentGroupList$;
  }

  getSegments(): Observable<BudgetSegmentAccess[]> {
    return this.budgetDataService.segmentsSnapshot ?
      concat(of(this.budgetDataService.segmentsSnapshot), this.budgetDataService.segmentList$) :
      this.budgetDataService.segmentList$;
  }

  getGoals(): Observable<Goal[]> {
    return this.budgetDataService.goalsSnapshot ?
      concat(of(this.budgetDataService.goalsSnapshot), this.budgetDataService.goalList$) :
      this.budgetDataService.goalList$;
  }

  getCampaigns(): Observable<Campaign[]> {
    return this.budgetDataService.campaignsSnapshot ?
      concat(of(this.budgetDataService.campaignsSnapshot), this.budgetDataService.campaignList$) :
      this.budgetDataService.campaignList$;
  }

  getLightCampaigns(fullCampaignsAllowed = true): Observable<LightCampaign[]> {
    const lightCampaigns$ =
      this.budgetDataService.lightCampaignsSnapshot ?
        concat(of(this.budgetDataService.lightCampaignsSnapshot), this.budgetDataService.lightCampaignList$) :
        this.budgetDataService.lightCampaignList$;

    return fullCampaignsAllowed ? merge(lightCampaigns$, this.getCampaigns()) : lightCampaigns$;
  }

  getPrograms(): Observable<Program[]> {
    return this.budgetDataService.programsSnapshot ?
      concat(of(this.budgetDataService.programsSnapshot), this.budgetDataService.programList$) :
      this.budgetDataService.programList$;
  }

  getLightPrograms(fullProgramsAllowed = true): Observable<LightProgram[]> {
    const lightPrograms$ =
      this.budgetDataService.lightProgramsSnapshot ?
        concat(of(this.budgetDataService.lightProgramsSnapshot), this.budgetDataService.lightProgramList$) :
        this.budgetDataService.lightProgramList$;

    return fullProgramsAllowed ? merge(lightPrograms$, this.getPrograms()) : lightPrograms$;
  }

  getSharedCostRules(): Observable<SharedCostRule[]> {
    return this.budgetDataService.sharedCostRulesSnapshot ?
      concat(of(this.budgetDataService.sharedCostRulesSnapshot), this.budgetDataService.sharedCostRuleList$) :
      this.budgetDataService.sharedCostRuleList$;
  }

  getAllowedSharedCostRules(): Observable<SharedCostRule[]> {
    const rules$ = this.getSharedCostRules();
    const segments$ = this.getSegments();

    return combineLatest([
      rules$,
      segments$
    ])
      .pipe(
        map(([rules, segments]) => (
          this.objectAccessManager.getAllowedSharedCostRules(
            rules,
            segments
          )
        ))
      );
  }

  getCompanyUsers(): Observable<ExtendedUserDO[]> {
    return this.companyDataService.companyUsersSnapshot ?
      concat(of(this.companyDataService.companyUsersSnapshot), this.companyDataService.userList$) :
      this.companyDataService.userList$;
  }

  getTags(): Observable<{id: number; title: string; isCustom: boolean}[]> {
    const tags$ = this.companyDataService.tagsSnapshot ?
      concat(of(this.companyDataService.tagsSnapshot), this.companyDataService.tagList$) :
      this.companyDataService.tagList$;

    return tags$.pipe(
      map(tags => tags && tags.map(tag => ({ title: tag.name, id: tag.id, isCustom: tag.is_custom })))
    );
  }

  getVendors(): Observable<Vendor[]> {
    return this.companyDataService.vendorList$;
  }

  getGlCodes(): Observable<GLCode[]> {
    return this.companyDataService.glCodesSnapshot ?
      concat(of(this.companyDataService.glCodesSnapshot), this.companyDataService.glCodeList$) :
      this.companyDataService.glCodeList$;
  }

  getGoalTypes(latestOnly = false):  Observable<BudgetObjectType[]> {
    return this.companyDataService.goalTypesSnapshot && !latestOnly ?
      concat(of(this.companyDataService.goalTypesSnapshot), this.companyDataService.goalTypes$) :
      this.companyDataService.goalTypes$;
  }

  getCampaignTypes(): Observable<BudgetObjectType[]> {
    return this.companyDataService.campaignTypesList$;
  }

  getProgramTypes(): Observable<BudgetObjectType[]> {
    return this.companyDataService.programTypes$;
  }

  getExpenseTypes(): Observable<BudgetObjectType[]> {
    return this.companyDataService.expenseTypesSnapshot ?
      concat(of(this.companyDataService.expenseTypesSnapshot), this.companyDataService.expenseTypeList$) :
      this.companyDataService.expenseTypeList$;
  }

  getMetricTypes(): Observable<MetricType[]> {
    return (this.companyDataService.metricsSnapshot ?
      concat(of(this.companyDataService.metricsSnapshot), this.companyDataService.metrics$) :
      this.companyDataService.metrics$
    ).pipe(
      filter(metrics => metrics != null)
    );
  }

  getProducts$(): Observable<ProductDO[]> {
    return this.companyDataService.products$.pipe(
      filter(products => products != null)
    );
  }

  getMetricMappings(companyId: number, objectId: number, mappingType: string): Observable<Metric[]> {
    return this.getMetricMappingsForObjects(companyId, [objectId], mappingType);
  }

  public filterMetricValuesForFixedDate(mappings: MetricMappingDO[]): MetricMappingDO[] {
    const todayFixedDate = getTodayFixedDate(this.budgetDataService.selectedBudgetSnapshot);
    if (todayFixedDate && mappings?.length) {
      mappings.forEach(mapping => {
        mapping.metric_calculations = BudgetObjectDetailsManager.filterCalculations(mapping.metric_calculations, todayFixedDate);
        mapping.third_party_amounts = BudgetObjectDetailsManager.getUpdatedThirdPartyData(mapping.metric_calculations);
        mapping.lastCalculationData = BudgetObjectDetailsManager.getLastCalculation(mapping.metric_calculations);
      })
    }
    return mappings;
  }

  private static filterCalculations(metricCalculations: MetricMappingCalculationDO[], todayFixedDate: Date) {
    return metricCalculations.filter(calculation => parseDateString(calculation.date) <= todayFixedDate);
  }

  private static getLastCalculation(calculations: MetricMappingCalculationDO[]): MetricMappingCalculationDO {
    let lastCalculation = calculations[0];
    calculations.forEach(calculation => {
      if (new Date(calculation.date) > new Date(lastCalculation.date)) {
        lastCalculation = calculation;
      }
    });
    const emptyCalculation = {
      date: null,
      metric_value: 0,
      salesforce_value: 0,
      total_value: 0,
      roi_value: 0,
      cpo_value: 0,
      metric_mapping: null,
      cost_value: 0,
      change_in_value: 0,
      third_party_total_value: 0,
      dependent_value: 0,
    };
    return lastCalculation || emptyCalculation;
  }

  private static getUpdatedThirdPartyData(calculations: MetricMappingCalculationDO[]): Record<string, number> {
    return calculations.reduce((store, calculation) => {
      Object.keys(calculation.third_party_values).forEach(integrationName => {
        if (!store[integrationName]) {
          store[integrationName] = 0;
        }
        store[integrationName] += calculation.third_party_values[integrationName].change_in_value;
      });
      return store;
    }, {});
  }

  public applyMappingReducedValues(dataObject: Partial<CampaignDO>, metricMappings: Metric[]) {
    if (getTodayFixedDate(this.budgetDataService.selectedBudgetSnapshot)) {
      dataObject.metric_data?.forEach(metricItem => {
        const targetMapping = metricMappings.find(mapping => mapping.id === metricItem.metric_mapping_id);
        metricItem.total_metric_actual = targetMapping.current;
      });
    }
  }

  createMappingFromMetricMaster(metric: MetricType, objectType: string): Metric {
    const mappingDO: MetricMappingDO = {
      id: 0,
      map_id: 0,
      mapping_type: objectType,
      projection_amount: 0,
      actual_amount: 0,
      notes: '',
      metric_master: metric.id,
      metric_detail: {
        id: metric.id,
        name: metric.name,
        product: metric.productId,
        company: metric.companyId,
      },
      metric_calculations: [],
      is_inherited: false,
      third_party_amounts: {},
      third_party_total_amount: 0,
      order: metric.order,
    }
    return this.metricsManager.convertDataObjectToMapping(mappingDO);
  }

  getMetricMappingsForObjects(companyId: number, objectIds: number[], mappingType: string): Observable<Metric[]> {
    if (!objectIds || !objectIds.length) {
      return of([]);
    }
    const targetIds = objectIds.length === 1 ?
      { map_id: objectIds[0] } :
      { map_ids: objectIds.join(',') };

    return this.metricService.getMetricMappings(
      companyId,
      {
        ...targetIds,
        mapping_type: mappingType,
      }
    ).pipe(
      map(mappings => this.filterMetricValuesForFixedDate(mappings)),
      map(
        mappings => (mappings || []).map(mappingDO => this.metricsManager.convertDataObjectToMapping(mappingDO))
      )
    );
  }

  public getMetricMappingById(id: number): Observable<Metric> {
    return this.metricService.getMetricMapping(id)
      .pipe(
        map(mapping => this.filterMetricValuesForFixedDate([mapping])),
        map(
          mappings => (mappings || [])
            .map(mappingDO => this.metricsManager.convertDataObjectToMapping(mappingDO))?.[0]
        )
      )
  }

  public inheritParentMetrics(
    companyId: number,
    ownMetricMappings: Metric[],
    objectId: number,
    objectType: string,
    parentObjectId: number,
    parentObjectType: string
  ): Observable<Metric[]> {
    const createMetricMapping =
      metricTypeId =>
        this.metricService.createMetricMapping({
          mapping_type: objectType,
          map_id: objectId,
          metric_master: metricTypeId,
        }).pipe(
          map(mappingDO => this.metricsManager.convertDataObjectToMapping(mappingDO))
        );

    return parentObjectId && parentObjectType ?
      this.getMetricMappings(companyId, parentObjectId, parentObjectType).pipe(
        map(parentMetrics => parentMetrics.filter(pm => !(ownMetricMappings || []).some(om => om.typeId === pm.typeId))),
        switchMap(parentMetricsToInherit =>
          parentMetricsToInherit.length
            ? forkJoin(parentMetricsToInherit.map(metricToInherit => createMetricMapping(metricToInherit.typeId)))
            : of([])
        ),
        map(inheritedMetrics => [...ownMetricMappings, ...inheritedMetrics])
      ) :
      of (ownMetricMappings);
  }

  getCompanyCurrency(companyId: number, errorCb?: (error) => void): Observable<string> {
    return this.companyService.getCompanyInfo(companyId).pipe(
      map((company: any) => {
        return company?.data?.currency;
      }),
      catchError(error => {
        errorCb?.(error);
        return of(null);
      })
    );
  }

  createObjectCustomType(companyId, objectType, objectCustomType): Observable<CampaignTypeDO | ProgramTypeDO> {
    const objectKey = this.budgetDataService.isCurrentBudgetWithNewCEGStructure ? CEGObjectKey : objectType;
    const apiCallsByObjectType = {
      [this.configuration.OBJECT_TYPES.campaign]: this.campaignService.createCampaignType.bind(this.campaignService),
      [this.configuration.OBJECT_TYPES.program]: this.programService.createProgramType.bind(this.programService),
      [CEGObjectKey]: this.objectTypeService.createObjectType.bind(this.objectTypeService),
    };

    return apiCallsByObjectType[objectKey] && objectCustomType ?
      apiCallsByObjectType[objectKey]({
        name: objectCustomType,
        is_custom: true,
        company: companyId
      }) :
      of(null);
  }

  deleteMetricMappings(metrics: Partial<Metric>[] = []) {
    return metrics && metrics.length ?
      forkJoin(
        metrics.map((mt) => this.metricService.deleteMetricMapping(mt.id))
      ) :
      of(null);
  }

  deleteIntegratedExpenses(allExpenses: ExpenseDO[]): Observable<any> {
    const integratedExpenses =
      (allExpenses || []).filter(expense => adsExpenseSources.includes(expense.source));
    return integratedExpenses.length ?
      this.expenseService.deleteMultiExpenses(integratedExpenses.map(expense => expense.id)) :
      of(null);
  }

  hierarchyItemToState(value: HierarchySelectItem): { segmentId: number, sharedCostRuleId: number } {
    return {
      sharedCostRuleId: value?.objectType === this.configuration.OBJECT_TYPES.sharedCostRule ? value.objectId : null,
      segmentId: value?.objectType === this.configuration.OBJECT_TYPES.segment ? value.objectId : null,
    };
  }

  segmentedValueToSelectItem(
    value: { segmentId?: number, sharedCostRuleId?: number },
    selectItems: HierarchySelectItem[]
  ): HierarchySelectItem {
    let selectedId = null;
    if (value && value.segmentId && !value.sharedCostRuleId) {
      selectedId = this.configuration.OBJECT_TYPES.segment + '_' + value.segmentId;
    } else if (value && value.sharedCostRuleId && !value.segmentId) {
      selectedId = this.configuration.OBJECT_TYPES.sharedCostRule + '_' + value.sharedCostRuleId;
    }

    return findItemInHierarchy(selectedId, selectItems);
  }

  compareSegmentedOptions(o1: SegmentedOptionValue, o2: SegmentedOptionValue): boolean {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id && o1.type === o2.type;
  }

  /* STATE MANAGEMENT */
  initStatusTotals(groupingState: GroupingObject, amount: number) {
    groupingState.statusTotals = {
      planned: 0,
      committed: 0,
      closed: 0,
      overdue: 0,
      under_budget: 0,
      expenses_number: 0,
      expenses_by_timeframes: {},
      total: amount,
      available: amount,
      remaining_allocated: amount
    };
  }

  initBudgetObjectAllocations(
    allocations: BudgetObjectAllocation[],
    budgetTimeframes: BudgetTimeframe[] = [],
    isCEGMode: boolean
  ): BudgetObjectAllocation[] {
    const filledAllocations = [...allocations];

    budgetTimeframes.forEach(tf => {
      const allocExists = filledAllocations.find(alloc => alloc.company_budget_alloc === tf.id);
      if (allocExists) { return; }

      const emptyAllocation: BudgetObjectAllocation = {
        amount: 0,
        source_amount: 0,
        actual_amount: 0,
        company_budget_alloc: tf.id,
        company: null,
        mode: 'Open'
      };

      if (isCEGMode) {
        emptyAllocation.source_actual = 0;
        emptyAllocation.source_remaining_committed = 0;
        emptyAllocation.source_remaining_planned = 0;
        emptyAllocation.available = 0;
      }

      filledAllocations.push(emptyAllocation);
    });

    return filledAllocations;
  }

  getCreateAllocationsPayload(state: AllocatableObject & BudgetObjectDetailsState): Partial<BudgetObjectAllocation>[] {
    return (state.allocations || []).map(allocation => ({
      company: state.companyId,
      company_budget_alloc: allocation.company_budget_alloc,
      source_amount: allocation.source_amount,
      mode: allocation.mode
    }));
  }

  patchState(state: BudgetObjectDetailsState, patchObject: BudgetDataObject) {
    if (patchObject.id) {
      state.objectId = patchObject.id;
    }
    if (patchObject.external_id) {
      state.externalId = patchObject.external_id;
    }
  }

  patchAllocations(stateAllocs: BudgetObjectAllocation[], updatedAllocs: Partial<BudgetObjectAllocation>[]) {
    if (!Array.isArray(updatedAllocs)) {
      return;
    }

    const propsToUpdate = ['id'];
    updatedAllocs
      .filter(item => item != null)
      .forEach((updatedItem) => {
        const allocation = stateAllocs.find(
          stateItem => stateItem.company_budget_alloc === updatedItem.company_budget_alloc
        );

        if (!allocation) {
          return;
        }

        propsToUpdate.forEach(prop => {
          if (updatedItem[prop] !== undefined) {
            allocation[prop] = updatedItem[prop];
          }
        });
      });
  }

  getDeepStateCopy<T extends DetailsState>(state: T): T {
    if (state == null) {
      return null;
    }

    return createDeepCopy(state);
  }

  public hasChanges<ObjectDetailsState extends BudgetObjectDetailsState>(prevState: ObjectDetailsState, currentState: ObjectDetailsState, statePropsToExclude: string[]): boolean {
    if (!prevState) {
      return true;
    }
    const isCurrentBudgetWithNewCEGStructure = this.budgetDataService.isCurrentBudgetWithNewCEGStructure;
    if (isCurrentBudgetWithNewCEGStructure) {
      if(!statePropsToExclude.includes('vendorName')){
        statePropsToExclude.push('vendorName');
      }      
    }
    else {
      if(statePropsToExclude.includes('vendorName')) {
        statePropsToExclude.splice(statePropsToExclude.indexOf('vendorName'), 1);
      }
    }
    const diff = this.compareStates(prevState, currentState, statePropsToExclude);
    return diff && Object.keys(diff).length > 0;
  }

  compareStates(
    prevState: BudgetObjectDetailsState,
    currentState: BudgetObjectDetailsState,
    excludeKeys: string[] = []
  ): { [key: string]: any } {
    const diffResults = getDiff(prevState, currentState);
    const diffMap = {};

    diffResults.forEach(({ op, path, val }) => {
      const stateKey = path[0] as string;
      if (!excludeKeys.includes(stateKey)) {
        diffMap[stateKey] = currentState[stateKey];
      }
    });

    return diffMap;
  }

  getAllocatableStateDiff(
    prevState: AllocatableObjectState,
    newState: AllocatableObjectState,
    objectProps: string[] = []
  ): Partial<AllocatableObjectState> {
    const getAllocationsDiff = (state: Partial<AllocatableObjectState>, updatedAllocation: BudgetObjectAllocation) => {
      const allocations = state.allocations || [];
      const payloadAlloc = allocations.findIndex(alloc =>
        !!updatedAllocation.company_budget_alloc &&
        alloc.company_budget_alloc === updatedAllocation.company_budget_alloc
      );

      if (payloadAlloc >= 0) {
        allocations[payloadAlloc] = { ...updatedAllocation };
      } else {
        allocations.push({ ...updatedAllocation });
      }

      return allocations;
    };

    const allocationsHandler = (keyName: string, keyIndex: number, stateDiff: Partial<AllocatableObjectState>) => {
      if (keyName === 'allocations') {
        const updatedAllocation = newState.allocations[keyIndex];
        stateDiff['allocations'] = getAllocationsDiff(stateDiff, updatedAllocation);
      }
    }

    return this.getStateDiff(prevState, newState, objectProps, allocationsHandler);
  }

  getStateDiff(
    prevState: BudgetObjectDetailsState,
    newState: BudgetObjectDetailsState,
    objectProps: string[] = [],
    customPropDiffHandler: (keyName, keyIndex, stateDiff) => void = null
  ): Partial<BudgetObjectDetailsState> {
    const diffResults = getDiff(prevState, newState);
    const stateDiff: Partial<AllocatableObjectState> = {};

    diffResults.forEach(({ op, path, val }) => {
      const stateKey = path[0] as string;
      const stateValue = newState[stateKey];

      if (objectProps.includes(stateKey)) {
        stateDiff[stateKey] = stateValue;
        return;
      }

      if (typeof customPropDiffHandler === 'function') {
        const keyIndex = path[1];
        customPropDiffHandler(stateKey, keyIndex, stateDiff);
      }
    });

    return stateDiff;
  }

  getCompanyCurrencies(companyId) {
    return this.companyCurrencyService.getCompanyCurrencies(companyId);
  }

  private _exchangeRatesStore: Record<string, Record<number, number>> = {};

  resetBudgetExchangeRates(): void {
    this._exchangeRatesStore = {};
  }

  public loadBudgetCurrencyExchangeRates(): void {
    this._exchangeRatesStore = {};
    this.getTimeframes().pipe(
      switchMap(timeframes => {
        const companyId = this.companyDataService.selectedCompanySnapshot.id;
        const budgetAllocIds = timeframes.map(tf => tf.id);
        return this.companyCurrencyService.getCompanyCurrencies(companyId).pipe(
          switchMap(currencies => {
            return forkJoin(currencies.map(currItem => {
              return this.updateExchangeRatesForCurrency(companyId, currItem.currency, budgetAllocIds);
            }))
          })
        )
      })
    ).subscribe();
  }

  public isRateLoaded(currencyCode: string): boolean {
    return !!this._exchangeRatesStore[currencyCode];
  }

  updateExchangeRatesForCurrency(companyId, currencyCode, budgetAllocIds) {
    return this.companyExchangeRateService.getCompanyBudgetTimeframeCurrencyRates(
      this.companyDataService.selectedCompanySnapshot.id, currencyCode, budgetAllocIds
    ).pipe(
      tap(ratesByTimeframes => this._exchangeRatesStore[currencyCode] = ratesByTimeframes)
    )
  }

  public getConvertedAmount(amount: number, currencyCode: string, budgetAllocId: number, companyCurrencyToSource = false): number {
    const allocExchangeRate = this._exchangeRatesStore[currencyCode]?.[budgetAllocId];
    if (!allocExchangeRate) {
      console.warn(`Missing exchange rate! Currency: ${currencyCode}, timeframeId: ${budgetAllocId}`);
      return amount;
    }
    const convertedValue = companyCurrencyToSource ?
      amount / allocExchangeRate :
      amount * allocExchangeRate;
    return Math.round(convertedValue * 100) / 100;
  }

  processDynamicTagMappings(
      companyId: number,
      objectId: number,
      ownTagMappings: TagMapping[],
      mappingType: string,
      prevParent: BudgetObjectParent,
      newParent: BudgetObjectParent
  ): Observable<{detached: number[]; attached: TagMappingDO[]}> {

    if (prevParent == null && newParent == null ||
      prevParent != null && newParent != null && prevParent.id === newParent.id && prevParent.type === newParent.type) {
      return of(null);
    }

    const prevParentTagMappings$ =
      prevParent ?
        this.budgetObjectService.getDynamicTagMappingsForBudgetObject(
          companyId,
          prevParent.id,
          prevParent.type
        ) :
        of([]);

    const newParentTagMappings$ =
      newParent ?
        this.budgetObjectService.getDynamicTagMappingsForBudgetObject(
          companyId,
          newParent.id,
          newParent.type
        ) :
        of([]);

    const loadParentTagMapping$ = forkJoin([prevParentTagMappings$, newParentTagMappings$]);

    return loadParentTagMapping$.pipe(
      switchMap(([prevParentDynamicTags, newParentDynamicTags]) =>
        this.budgetObjectService.processDynamicTags(
          objectId,
          mappingType,
          (ownTagMappings || []).map(tm => ({id: tm.id, tag_id: tm.tagId})),
          prevParentDynamicTags,
          newParentDynamicTags
        )
      )
    );
  }

  handleError(error: any, message?: string, close?: boolean) {
    if (error) {
      console.error(error);
    }
    if (message && !close) {
      this.utilityService.handleError({message});
    }
    if (close) {
      this.appRoutingService.closeDetailsPage(message);
    }
  }

  public reportDetailsChange(
    detailsComponent: BudgetObjectDetailsComponent,
    eventType: BudgetObjectChangeEvent = BudgetObjectChangeEvent.DetailsChanged
  ) {
    if (detailsComponent) {
      this.budgetObjectChanged.next({
        objectType: detailsComponent.objectType,
        objectId: detailsComponent.currentState.objectId,
        eventType
      });
    }
  }

  public reportDrawerDetailsChange(
    objectId: number,
    objectType: string,
    eventType: BudgetObjectChangeEvent = BudgetObjectChangeEvent.DetailsChanged
  ) {
    if (objectId && objectType) {
      this.budgetObjectChanged.next({ objectType, objectId, eventType });
    }
  }

  public reportObjectChange(objectType: string, objectId?: number) {
    this.budgetObjectChanged.next({ objectType, objectId });
  }

  public reportCampaignMappingsChange() {
    this._campaignMappingsChanged$.next();
  }

  public filterObjectTypes(objectTypes: BudgetObjectType[] = [], currentObjectTypeId?: number): BudgetObjectType[] {
    return objectTypes.filter(
      objectType => objectType.name || objectType.id === currentObjectTypeId
    );
  }

  public detachDirectChildObjects(
      childObjects: { campaigns?: CampaignDO[], programs?: ProgramDO[], expenses?: ExpenseDO[] },
      parentPropName: string): Observable<boolean> {

    const detachRequests = [
      ... (childObjects && childObjects.campaigns || []).map(
        campaign => this.campaignService.updateCampaign(campaign.id, {[parentPropName]: null})),
      ... (childObjects && childObjects.programs || []).map(
        program => this.programService.updateProgram(program.id, {[parentPropName]: null})),
      ... (childObjects && childObjects.expenses || []).map(
        expense => this.expenseService.updateExpense(expense.id, {[parentPropName]: null}))
    ];

    return detachRequests.length ? forkJoin(detachRequests).pipe(map(() => true)) : of(true);
  }

  public viewExpenses(filterSet: FilterSet, viewMode = HierarchyViewMode.Campaign) {
    this.filterManagementService.updateCurrentFilterSet(filterSet);

    return this.router.navigate([this.configuration.ROUTING_CONSTANTS.SPENDING_MANAGEMENT],
      { state: { viewMode }}
    ).then(() => this.appRoutingService.closeDetailsPage());
  }

  public cloneObject(clone$: Observable<BudgetObjectCloneResponse>, objectType: string) {
    return clone$
      .pipe(
        filter(data => data != null),
        delay(3000),
        switchMap(data => {
          if (!data || !data.id) {
            throw new Error(messages.UNABLE_TO_CLONE_ERROR_MSG.replace(objectPlaceholderName, objectType.toLowerCase));
          }

          return this.utilityService.showCustomToastr(
            `${objectType} has been successfully duplicated`,
            'View details',
            { timeOut: this.snackbarDuration }
          )
            .onAction
            .pipe(
              map(() => data)
            );
        })
      )
  }

  public moveObject(options: {
    moveChain$: Observable<Object>;
    budgetId: number;
    objectId: number;
    objectType: string;
    successMessage: string;
    errorMessage: string;
  }) {
    const { moveChain$, budgetId, objectId, objectType, successMessage, errorMessage } = options;

    return moveChain$
      .pipe(
        switchMap(() => (
          this.utilityService.showCustomToastr(
            successMessage,
            'View details',
            { timeOut: this.snackbarDuration }
          ).onAction
        )),
        tap(() => this.budgetDataService.selectBudget(budgetId))
      ).subscribe({
        next: () => {
          this.appRoutingService.closeDetailsPage();
          this.appRoutingService.openDetailsForObject(objectType.toLowerCase(), objectId);
        },
        error: error => this.handleError(error, errorMessage)
      });
  }

  public logObjectView(
    objectId: number,
    companyId: number,
    budgetId: number,
    userId: number,
    logType: HistoryObjectLogType,
    objectDetailsService: DetailsService<DetailsState>
  ) {
    objectDetailsService.logObjectView(objectId).subscribe(
      () => {
        this.historyService.refreshRecentlyViewed(userId, budgetId, logType);
        this.historyService.loadHistory(companyId, userId, budgetId);
      },
      err => this.handleError(err)
    );
  }

  public refreshRecentlyAddedObjects(
    budgetId: number,
    logType: HistoryObjectLogType,
    segments: BudgetSegmentAccess[] = []
  ) {
    const segmentIds = segments.map(segment => segment.id);

    this.historyService.refreshRecentlyAdded(budgetId, logType, segmentIds);
  }

  /**
   * Check segments access to prevent opening details of 'restricted' objects
   */
  public checkObjectAccess(params: {
    segmentDO: BudgetObjectSegmentDO;
    segments: BudgetSegmentAccess[];
    rules: SharedCostRule[];
    onDenyCb: Function;
    objectTypeLabel?: string;
    closeDetails?: boolean;
  }) {
    const { segmentDO, segments, rules, onDenyCb, objectTypeLabel = '', closeDetails = true } = params;
    const hasAccess = this.objectAccessManager.hasAccessBySegmentData(segmentDO, segments, rules);
    if (!hasAccess) {
      if (closeDetails) {
        this.appRoutingService.closeDetailsPage(this.MESSAGE_GETTERS.NO_OBJECT_ACCESS(objectTypeLabel));
      }
      if (typeof onDenyCb === 'function') {
        onDenyCb();
      }
    }
  }

  /**
   * Throws an error if the current budget and the object's state budget mismatch
   * To prevent access to a recently moved object via history logs
   */
  public checkObjectBudgetStateConsistency(options: {
    state: BudgetObjectDetailsState;
    budgetId: number;
  }) {
    const { state, budgetId } = options;

    if (state.budgetId !== budgetId) {
      throw new Error(`Selected budget and object's state budget mismatch`);
    }
  }

  public autofillSegmentValue(control: AbstractControl, options: HierarchySelectItem[]) {
    let singleOption = null;
    if (options.length === 1) {
      const option = options[0];
      const childrenLength = option.children?.length;
      singleOption = !childrenLength ? option : childrenLength === 1 ? option.children[0] : null;
    }
    const emptyValue = !control.value || !control.value.id;
    if (singleOption && emptyValue) {
      control.patchValue(singleOption);
    }
  }

  public autofillTypeSelectValue(control: AbstractControl, options: any[]) {
    const singleOption = options.length === 1;
    const emptyValue = !control.value;
    if (singleOption && emptyValue) {
      control.patchValue(options[0].id);
    }
  }

  /**
   * Show snackbar with test 'Goal created. Switched to the ...' only for Goal object (reg. to Manage page)
   * Show snackbar with test '{ Object } created.' for other object types (reg. to Manage page)
   * Show regular success created text for other cases
   * @param objectType
   * @param state
   */
  public defineSuccessMessage(objectType: string, isNewObject: boolean, displayObjectType?: string, isCloseDetailsPage?: boolean): string {
    const isManagePage = this.router.url.includes('/manage/');
    const updatedObjectType = displayObjectType || objectType;
    const successMessageWithSwitch = isNewObject ? 'NEW_OBJECT_CREATED_MSG' : 'OBJECT_UPDATED_MSG';
    const successMessage = isNewObject ? 'NEW_OBJECT_CREATED_SUCCESS_MSG' : 'OBJECT_UPDATED_SUCCESS_MSG';

    return isManagePage && isCloseDetailsPage
      ? messages[successMessageWithSwitch].replaceAll(objectPlaceholderName, objectType)
      : isManagePage && !isCloseDetailsPage
        ? messages[successMessage].replaceAll(objectPlaceholderName, updatedObjectType)
        : messages.SAVE_CHANGES_SUCCESS_MSG;
  }

  public syncSegmentsOnLocationUpdate(params: {
    location: string,
    objectType: string,
    campaigns: Campaign[] | LightCampaign[],
    segment: HierarchySelectItem,
    onCancel: () => void,
    onReplace: (parentSegmentData) => void
  }): void {
    const { location, objectType, campaigns, segment, onCancel, onReplace } = params;

    if (!location) {
      return;
    }
    const currentSegment = this.hierarchyItemToState(segment);
    const parentLists = {
      campaign: { name: 'campaign', objectList: campaigns }
    };

    this.locationService.syncSegmentsOnLocationUpdate(
      objectType,
      location,
      currentSegment,
      parentLists,
      onCancel,
      onReplace
    );
  }

  public triggerBudgetObjectEvent(event: BudgetObjectEvent): void {
    this.budgetObjectEvent.next(event);
  }

  getUnassignedCampaignMetrics(
    companyId: number,
    campaignIds: Array<number>,
    products: ProductDO[],
    metrics: MetricType[],
    mappingType: string
  ): Observable<SelectItem[]> {
  return campaignIds.length > 1 // Check mapped metrics only for single selected campaign
    ? of(getMetricSelectItems(products, metrics))
    : this.getMetricMappingsForObjects(companyId, campaignIds, mappingType)
      .pipe(
        map(
          (mappedMetrics: Metric[]) => getMetricSelectItems(products, metrics.filter(metric => !mappedMetrics.some(m => m.typeId === metric.id)))
        ),
        catchError(err => {
          this.utilityService.handleError({message: 'Failed to load mapped campaign metrics.'});
          return of(getMetricSelectItems(products, metrics))
        })
      );
  }
}
