import { Injectable } from '@angular/core';
import { BudgetObjectDetailsService } from '../types/budget-object-details-service.interface';
import { ExpenseDetailsState } from '../types/budget-object-details-state.interface';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { BudgetObjectCreationContext } from '../types/details-creation-context.interface';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { BudgetObjectDetailsManager } from './budget-object-details-manager.service';
import { ExpenseDO } from 'app/shared/types/expense.interface';
import { ExpenseStateMapper } from './state-mappers/expense-state-mapper.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { TagMapping } from 'app/shared/types/tag-mapping.interface';
import { BudgetObjectTagsService } from './budget-object-tags.service';
import { BudgetObjectCloneResponse } from 'app/shared/types/budget-object-clone-response.interface';
import { ChurnZeroService, EventName } from 'app/shared/services/churn-zero.service';
import { PendoEventName, PendoManagerService, PendoObjectType } from '@shared/services/pendo-manager.service';
import { CFExpenseDetailsContext } from '../components/custom-fields/custom-field.service';
import { SaveDetailsContext } from '../types/save-details-context.interface';

@Injectable()
export class ExpenseDetailsService implements BudgetObjectDetailsService<ExpenseDetailsState> {

  public handleSaveAndCloseAction = new Subject<void>();
  public handleCancelAction = new Subject<void>();
  /**
   * State properties to check updates for
   */
  private updatableStateProps = [
    'sourceAmount',
    'sourceActualAmount',
    'mode',
    'budgetAllocationId',
    'typeId',
    'name',
    'notes',
    'createdBy',
    'budgetId',
    'ownerId',
    'segment',
    'glCode',
    'poNumber',
    'invoiceNumber',
    'vendor',
    'vendorName',
    'currencyCode',
    'parentObject',
    'deliveryDate',
    'isVerified'
  ];
  /**
   * State properties to exclude from state diff checks
   */
  private statePropsToExclude = [
    'pdfUrl',
    'attachmentMappings'
  ];

  constructor(
    private readonly configuration: Configuration,
    private readonly expenseService: ExpensesService,
    private readonly tagsManager: BudgetObjectTagsService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly stateMapper: ExpenseStateMapper,
    private readonly churnZeroService: ChurnZeroService,
    private readonly pendoManager: PendoManagerService
  ) { }

  cloneObject(objectId: number): Observable<BudgetObjectCloneResponse> {
    return this.expenseService.cloneExpenseV2(objectId);
  }

  deleteObject(objectId: number): Observable<void> {
    return this.expenseService.deleteExpense(objectId);
  }

  moveToBudget(objectId: number, budgetId: number, companyId: number): Observable<any> {
    return this.expenseService.moveToBudget(objectId, budgetId, companyId);
  }

  loadDetails(
    companyId: number,
    _budgetId: number,
    objectId: number,
    data?: { generateExtraUrls: boolean }
  ): Observable<ExpenseDetailsState> {
    return forkJoin([
      this.expenseService.getExpenseById(objectId, data?.generateExtraUrls),
      this.tagsManager.getTagMappings(companyId, objectId, this.configuration.OBJECT_TYPES.expense)
    ]).pipe(map(
      ([expense, tagMappings]) => this.createExpenseDetailsState(expense, tagMappings)
    ));
  }

  private createExpenseDetailsState(expense: Partial<ExpenseDO>, tagMappings: TagMapping[]): ExpenseDetailsState {
    const state = this.stateMapper.dataObjectToState(expense);

    return {
      ...state,
      tagMappings: tagMappings || []
    } as ExpenseDetailsState;
  }

  saveDetails(prevObjectDetails: ExpenseDetailsState, newObjectDetails: ExpenseDetailsState,_saveDetailsCtx?: SaveDetailsContext , CFDetailsCtx?: CFExpenseDetailsContext): Observable<ExpenseDO> {
    if (prevObjectDetails) {
      return this.updateDetails(prevObjectDetails, newObjectDetails, CFDetailsCtx).pipe(
        tap(() => {
          this.churnZeroService.trackEvent(EventName.ExpenseUpdate);
          this.pendoManager.track(PendoEventName.ObjectUpdated, {
            type: PendoObjectType.Expense
          });
        })
      );
    } else {
      return this.createDetails(newObjectDetails, CFDetailsCtx).pipe(
        tap(() => {
          this.churnZeroService.trackEvent(EventName.ExpenseCreate);
          this.pendoManager.track(PendoEventName.ObjectCreated, {
            type: PendoObjectType.Expense
          });
        })
      );
    }
  }

  initDetails(context: BudgetObjectCreationContext, data: Partial<ExpenseDO>): Observable<ExpenseDetailsState> {
    const expense = { ...data };
    const state = this.createExpenseDetailsState(expense, []);
    const contextParent = context?.parent;
    const { OBJECT_TYPES } = this.configuration;

    state.typeId = context && context.objectTypeId;
    state.segment =
      context && (context.segmentId || context.sharedCostRuleId) ?
        { segmentId: context.segmentId, sharedCostRuleId: context.sharedCostRuleId } :
        { segmentId: null, sharedCostRuleId: null };
    state.glCode = context && context.glCodeId || null;
    state.poNumber = context && context.poNumber || '';
    state.vendor = context && context.vendorId || null;
    state.budgetAllocationId = context && context.budgetAllocationId || null;
    state.mode = context && context.mode || null;
    state.ownerId = context && context.ownerId || null;

    if (contextParent) {
      state.parentObject = contextParent;
      state.goalId = contextParent?.type === OBJECT_TYPES.goal ? contextParent.id : null;
      state.campaignId = contextParent?.type === OBJECT_TYPES.campaign ? contextParent.id : null;
      state.programId = contextParent?.type === OBJECT_TYPES.program ? contextParent.id : null;
    }

    return of(state);
  }

  initExpenseAllocations(allocations, budgetTimeframes: BudgetTimeframe[] = []) {
    const filledAllocations = [...allocations];
    budgetTimeframes.forEach(tf => {
      const allocExists = filledAllocations.find(alloc => alloc.company_budget_alloc === tf.id);
      if (allocExists) { return; }

      filledAllocations.push({
        source_amount: 0,
        source_actual_amount: 0,
        company_budget_alloc: tf.id,
        company: null,
        mode: ''
      })
    });

    return filledAllocations;
  }

  private updateDetails(prevState: ExpenseDetailsState, newState: ExpenseDetailsState, CFDetailsCtx?:CFExpenseDetailsContext): Observable<ExpenseDO> {
    const stateDiff =
      this.budgetObjectDetailsManager.getStateDiff(
        prevState,
        newState,
        this.updatableStateProps
      ) as Partial<ExpenseDetailsState>;
    let expensePayload = this.stateMapper.stateToDataObject(stateDiff);

    // Adding custom fields payload to the request if there are any CF changes
    let customFieldsPayload = CFDetailsCtx?.isCFEnabledForExpense && CFDetailsCtx?.customFieldsStateDiff;

    if(customFieldsPayload && Object.keys(customFieldsPayload).length) {
      expensePayload =  { ...expensePayload, custom_fields: customFieldsPayload };
    }

    return this.expenseService.updateExpense(newState.objectId, expensePayload).pipe(
      map((updatedExpense) => {
        this.patchState(newState, updatedExpense);
        return updatedExpense;
      })
    );
  }

  private createDetails(state: ExpenseDetailsState, CFDetailsCtx ?: CFExpenseDetailsContext): Observable<ExpenseDO> {
    let expensePayload = this.stateMapper.stateToDataObject(state);
    
    // Adding custom fields payload to the request if there are any CF changes
    let customFieldsPayload = CFDetailsCtx?.isCFEnabledForExpense && CFDetailsCtx?.customFieldsStateDiff;

    if(customFieldsPayload && Object.keys(customFieldsPayload).length) {
      expensePayload =  { ...expensePayload, custom_fields: customFieldsPayload };
    }
    
    return this.expenseService.addExpense(expensePayload)
      .pipe(
        map(expense => {
          this.patchState(state, expense);
          return expense;
        })
      )
  }

  private patchState(state: ExpenseDetailsState, patchObject: ExpenseDO) {
    this.budgetObjectDetailsManager.patchState(state, patchObject);

    if (patchObject.crd) {
      state.created = patchObject.crd;
    }
    if (patchObject.upd) {
      state.updated = patchObject.upd;
    }
  }

  public hasChanges(prevState: ExpenseDetailsState, currentState: ExpenseDetailsState): boolean {
    return this.budgetObjectDetailsManager.hasChanges(prevState, currentState, this.statePropsToExclude);
  }

  logObjectView(objectId: number): Observable<void> {
    return this.expenseService.logExpenseView(objectId);
  }
}
