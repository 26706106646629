<div class="content-wrap">
  <page-header [pageName]="'Import / Export Data'">
    <fa-duotone-icon slot="icon" [icon]="['fad', 'file-chart-line']"></fa-duotone-icon>
  </page-header>
  <div class="container" *ngIf="currentUserLevel !== permissionLevel.None">
    <div class="section" *ngIf="currentUserLevel >= permissionLevel.PartialSomeSegment">
      <h2 class="section-title">Export <a [href]="exportDataKnowledgeLink" target="_blank">Learn more</a></h2>
      <mat-tab-group animationDuration="0">
        <mat-tab label="Report">
          <reports-export
            [budgetId]="selectedBudget?.id"
            [newCEGStructure]="selectedBudget?.new_campaigns_programs_structure"
            [exportReportItem]="exportReportItem"
            [disableSubmit]="!!exportReportItem || !selectedBudget || !selectedCompany"
            (exportReport)="handleExportReport($event)"
          ></reports-export>
        </mat-tab>
        <mat-tab [label]="currentUserLevel > permissionLevel.PartialSomeSegment ? 'Budget' : 'My Budget'">
          <div class="d-i-flex fd-column mt-2">
            <ng-container *ngIf="currentUserLevel === permissionLevel.Full">
              <div class="radio-group mb-1">
                <b>Export as</b>
                <mat-radio-group [(ngModel)]="exportedBudgetType" aria-label="Export Budget as..." color="primary">
                  <mat-radio-button [value]="EXPORT_TYPES.JSON">JSON</mat-radio-button>
                  <mat-radio-button [value]="EXPORT_TYPES.XLSX">XLSX</mat-radio-button>
                </mat-radio-group>
              </div>
              <mat-slide-toggle class="mb-1" [(ngModel)]="isObfuscated">Obfuscate sensitive data</mat-slide-toggle>
            </ng-container>
            <button
              (click)="initBudgetExport()"
              [disabled]="exportInProgress || !selectedBudget || !selectedCompany"
              class="mb-05 action-btn"
              mat-flat-button
              color="primary">Export Budget as <span class="uppercase ml-05">{{ exportedBudgetType }}</span></button>
            <p class="warning-msg">
              <fa-icon style="color: green" [icon]="['fas', 'exclamation-triangle']"></fa-icon>
              Attachments are not exported
            </p>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="section">
      <h2 class="section-title">Import <a [href]="importDataKnowledgeLink" target="_blank">Learn more</a></h2>
      <div class="d-i-flex fd-column">
        <div class="d-i-flex fd-column as-start">
          <mat-form-field [appearance]="'outline'" class="p-form-field-outline no-validate import-type-select mb-05">
            <mat-select
              [disabled]="dataImportProcessing"
              [(ngModel)]="activeImportDataType"
              (selectionChange)="clearFile()"
              placeholder="Choose what to import"
            >
              <ng-container *ngFor="let option of supportedImportTypes">
                <mat-option *ngIf="!option.hidden && (currentUserLevel >= option.permissionLevel)" [value]="option">
                  {{ importTypeLabel[option.id] }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <input type="file"
                 #fileInput
                 accept="{{ isBudgetImport ? acceptType[EXPORT_TYPES.JSON] : acceptType[EXPORT_TYPES.XLSX] }}"
                 name="file"
                 [(ngModel)]="file"
                 [disabled]="!activeImportDataType || dataImportProcessing"
                 (change)="checkFileType($event)"
                 style="display:none;"
          />
          <button
            mat-stroked-button
            color="primary"
            class="action-btn"
            (click)="fileInputClick()"
            [disabled]="!activeImportDataType || fileSubmitInProgress || dataImportProcessing">Upload</button>
          <p *ngIf="activeImportDataType" class="hint">Upload {{ activeImportDataType.id | titlecase }} here. We support {{ isBudgetImport ? EXPORT_TYPES.JSON : EXPORT_TYPES.XLSX }} file type.</p>
        </div>

        <div *ngIf="importingItem" class="mt-1 importing-item">
          <div class="d-flex">
            <loader [isVisible]="dataImportProcessing && !isBudgetImport" class="mr-05" [size]="'24px'" [isInline]="true"></loader>
            <fa-icon *ngIf="dataImportFailed" class="mr-05 status-icon failed" [icon]="['fas', 'exclamation-circle']"></fa-icon>
            <span>{{ importingItem.name }}</span>
            <a *ngIf="importingItem.errorsList.length" (click)="clearFile(true)" class="btn-link">Clear</a>
          </div>
          <ul *ngIf="importingItem.errorsList.length" class="errors-list">
            <li *ngFor="let error of importingItem.errorsList">- {{ error }}</li>
          </ul>
        </div>
        <div class="mt-05" #importBtnWrap>
          <button
            *ngIf="importingItem && !dataImportFailed"
            [hidden]="dataImportProcessing && !isBudgetImport"
            mat-flat-button
            color="primary"
            class="action-btn"
            (click)="fileSubmit()"
            [disabled]="!selectedBudget || fileSubmitInProgress || dataImportProcessing"
          >
            Import
          </button>
        </div>
        <p class="hint" *ngIf="dataImportProcessing && !isBudgetImport">Processing your data on the server. You may now close this page.</p>
      </div>
    </div>
  </div>
</div>

<export-budget-settings
  [@fade]
  *ngIf="customizeBudgetExport"
  (close)="onBudgetSettingsClose($event)">
</export-budget-settings>
