import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TabSwitchOption } from './tab-switch.types';


@Component({
  selector: 'tab-switch',
  templateUrl: './tab-switch.component.html',
  styleUrls: ['./tab-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabSwitchComponent {
  @Input() squareMode: boolean;
  @Input() options: TabSwitchOption[] = [];
  @Input() selectedValue: string;
  @Input() customHandle: boolean;
  @Input() autoMinWidth: boolean = false;
  @Output() onChange = new EventEmitter<string>();

  public handleOptionClick(option: TabSwitchOption) {
    if (this.selectedValue !== option.value) {
      if (!this.customHandle) {
        this.selectedValue = option.value;
      }
      this.onChange.emit(option.value);
    }
  }
}
