import { Component, ElementRef, inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { StateField } from '@spending/types/expense-page-drawer.type';
import { Configuration, ObjectsIconConfig } from 'app/app.constants';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  private readonly renderer2 = inject(Renderer2);
  protected readonly appRoutingService = inject(AppRoutingService);
  drawerSubtitleIcon: IconProp;
  navigateToParentCampaign: boolean;
  
  
  settings = {
    goal: { icon: 'bullseye-arrow', iconType: 'fas', flipIcon: true, title: 'Goal', placeholder: 'Goal name', class: 'goal' },
    campaign: { icon: 'rocket-launch', iconType: 'far', title: 'Campaign', placeholder: 'Campaign name', class: 'campaign' },
    program: { icon: 'briefcase', iconType: 'fas', title: 'Expense Group', placeholder: 'Expense Group name', class: 'program' },
    'expense': { icon: 'coins', iconType: 'fas', title: 'Expense', placeholder: 'Expense name', class: 'expense' },
    'metric': { icon: 'chart-line', iconType: 'fas', title: 'Metric', placeholder: 'Metric name', class: 'goal' }
  };
  @Input() nameCtrl: UntypedFormControl;
  @Input() objectType = '';
  @Input() isClosed = false;
  @Input() isDrawer = false;
  @Input() maxLength: number;
  @Input() isCEGBudget: boolean;
  @Input() parentId: number;
  @Input() parentName: string;
  @Input() set isCampaignMetricDrawer(val: boolean) {
      this.drawerSubtitleIcon = val ? this.iconsConfig[this.OBJECT_TYPES.campaign]: this.iconsConfig[this.OBJECT_TYPES.goal];
      this.navigateToParentCampaign = val;
  }
  // getter to navigate to parent campaign/goal drawer
  get isCampaignMetricDrawer() {
    return this.navigateToParentCampaign;
  }
  
  @ViewChild('titleText') public titleText: ElementRef<HTMLElement>;

  public StateField = StateField;
  public nameLengthCounter = 0;


  public OBJECT_TYPES = this.configuration.OBJECT_TYPES;
  public iconsConfig: ObjectsIconConfig = this.configuration.iconsConfig;

  constructor(private readonly configuration: Configuration) {}

  autoGrow(): void {
    if (this.titleText) {
      const { nativeElement } = this.titleText;

      if (this.nameLengthCounter > 76) {
        this.renderer2.setStyle(nativeElement, 'fontSize', '21px');
      } else {
        this.renderer2.setStyle(nativeElement, 'fontSize', '28px');
      }

      this.renderer2.setStyle(nativeElement, 'height', 'auto');
      this.renderer2.setStyle(nativeElement, 'height', `${nativeElement.scrollHeight}px`);
    }
  }

  onKeyup(event: Event): void {
    this.nameLengthCounter = (event?.target as HTMLTextAreaElement).value.length;
  }

  handleParentNavigation(e: Event) {
    e.preventDefault();
    if(this.isCampaignMetricDrawer) {
      this.appRoutingService.openCampaignDetails(this.parentId)
    }else {
      this.appRoutingService.openGoalDetails(this.parentId)
    }
  }

  public ngOnInit(): void {
    this.nameCtrl.valueChanges
      .pipe(
        filter(value => value),
        take(1)
      ).subscribe((value: string) => {
        this.nameLengthCounter = value.length || 0;
        this.autoGrow();
      });
  }
}
