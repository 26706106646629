import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding, HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[plDraggable]'
})
export class PlDraggableDirective implements OnInit, OnDestroy, OnChanges {
  @Input() plDraggable = false;
  @Output() dragStart = new EventEmitter<DragEvent>();
  @Output() dragEnd = new EventEmitter<DragEvent>();
  @HostBinding('draggable') isDraggable: boolean;

  private listeners: Record<string, Function>;
  private handleElement: HTMLElement;
  @HostListener('mousedown', ['$event'])
  mousedown(event) {
    this.handleElement = event.target.closest('.drag-handle');
    if (this.elementRef.nativeElement.querySelector('.drag-handle')) {
      this.isDraggable = !!this.handleElement;
    }
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly zone: NgZone
  ) {
    this.listeners = {
      dragstart: this._onDragStart.bind(this),
      dragend: this._onDragEnd.bind(this),
    };
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      Object.entries(this.listeners).forEach(([event, listener]) => {
        this.elementRef.nativeElement.addEventListener(event, listener);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.plDraggable) {
      this.isDraggable = this.plDraggable;
    }
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      Object.entries(this.listeners).forEach(([event, listener]) => {
        this.elementRef.nativeElement.removeEventListener(event, listener);
      });
    });
  }

  private _onDragStart($event: DragEvent) {
    if (!this.plDraggable) {
      return;
    }
    this.dragStart.emit($event);
  }

  private _onDragEnd($event: DragEvent) {
    if (!this.plDraggable) {
      return;
    }
    this.dragEnd.emit($event);
  }
}
