import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { CEGStatus, CEGStatusLabels } from '@shared/enums/ceg-status.enum';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'amount-status-switch',
  templateUrl: './amount-status-switch.component.html',
  styleUrls: ['./amount-status-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmountStatusSwitchComponent {
  @Input() isReadOnlyMode: boolean;
  @Input() parentCampaignIsCommitted: boolean;
  @Input() statusFormControl: AbstractControl;
  @Output() onChangeAmountState = new EventEmitter<CEGStatus>();
  @Input() id: string;

  protected readonly campaignAmountStatus = [
    { value: CEGStatus.PLANNED, title: CEGStatusLabels[CEGStatus.PLANNED] },
    { value: CEGStatus.COMMITTED, title: CEGStatusLabels[CEGStatus.COMMITTED] }
  ];
}
