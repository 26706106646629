import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'task-name',
  templateUrl: './task-name.component.html',
  styleUrls: ['./task-name.component.scss']
})
export class TaskNameComponent {
  @Input() value;
  @Input() isReadOnly = false;
  @Output() onNameChanged = new EventEmitter();
  @ViewChild('textarea') textarea: ElementRef;

  public editMode = false;

  public handleValueChanged($event) {
    const value = $event.target.value;
    this.onNameChanged.emit(value);
    this.value = value;
  }

  public handleFocus() {
    if (this.isReadOnly) {
      return;
    }

    this.editMode = true;
    setTimeout(() => {
      if (this.textarea) {
        this.textarea.nativeElement.focus();
      }
    });
  }

  public handleBlur() {
    this.editMode = false;
  }
}
