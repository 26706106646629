import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import {
  Attachment,
  AttachmentLambda,
  AttachmentMappingDO,
  AttachmentMetaData,
  AttachmentsObjectContext
} from '../../types/attachment.interface';
import { getBaseNameAndExtension } from '../../utils/common.utils';
import { UtilityService } from '../utility.service';
import { SKIP_COMMON_HEADERS } from '@common-lib/lib/http/http-common-headers.interceptor';
import { SKIP_AUTH } from '../../../user/services/http-auth.interceptor';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

interface PreSignedAttachment {
  key: string;
  url: string;
  companyId: number;
  meta: AttachmentMetaData;
}

const ERROR_MESSAGES = {
  UPLOAD_FAILED: `Oh no, uploading didn't work!\nPlease try again later.`,
  DOWNLOAD_FAILED: `Sorry, couldn't download that attachment for you.\nPlease try again later.`,
  DELETE_FAILED: `It's not you, it's me.\nI couldn't make that attachment go away.\nPlease try to delete it later.`,
  DELETE_PERMISSIONS: `Sorry, you're not allowed to delete this attachment.\nIf that doesn't seem right to you then please talk to your Planful admin.`,
  GENERIC_ERROR: 'Oops. Something went wrong.',
  COMPANY_ID_MISSING: 'No company defined',
  OBJECT_DATA_MISSING: 'No object data defined'
};
const ERROR_MESSAGE_DURATION = 3000;
const INFO_MESSAGE_DURATION = 2000;

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {
  private readonly apiUrl = inject(API_V2_URL);
  private readonly configuration = inject(Configuration);
  private readonly http = inject(HttpClient);
  private readonly utilityService = inject(UtilityService);

  private readonly attachmentsApiUrl = this.configuration.attachments_service_url + 'api/';
  private readonly uploadHeadersS3 = {
    'Content-Type': 'application/octet-stream',
    'x-amz-server-side-encryption': 'AES256'
  };
  private readonly attachmentsApiEndpoints = {
    [this.configuration.OBJECT_TYPES.goal]: 'goal_attachment/',
    [this.configuration.OBJECT_TYPES.campaign]: 'campaign_attachment/',
    [this.configuration.OBJECT_TYPES.program]: 'program_attachment/',
    [this.configuration.OBJECT_TYPES.expense]: 'expense_attachment/',
  };

  private static createAttachmentObject(mapping: AttachmentMappingDO, attachment?: AttachmentLambda, file?: File): Attachment {
    const metaData: Partial<Attachment> = {};
    if (attachment) {
      metaData.size = attachment.size;
      metaData.eTag = attachment.eTag;
      metaData.filename = attachment.meta && attachment.meta.filename;
      metaData.type = attachment.meta && attachment.meta.type;
    } else if (file) {
      metaData.filename = file.name;
      metaData.size = file.size;
      metaData.type = file.type;
    }

    const extensionInfo = getBaseNameAndExtension(metaData.filename);
    metaData.ext = extensionInfo.ext;
    metaData.name = extensionInfo.basename;

    return {
      ...mapping,
      ...metaData
    } as Attachment;
  }

  private handleError(msg: string, action: string = '') {
    this.utilityService.showCustomToastr(msg, action, { timeOut: ERROR_MESSAGE_DURATION });
    console.error(msg);
  }

  private createUploadRequest(file, url): Observable<any> {
    return this.http.put(url, file, {
      headers: new HttpHeaders(this.uploadHeadersS3),
      context: new HttpContext().set(SKIP_COMMON_HEADERS, true).set(SKIP_AUTH, true)
    });
  }

  private createPreSignRequest(file: File, companyId: number): Observable<PreSignedAttachment>  {
    const metaData: AttachmentMetaData = {
      filename: file.name,
      type: file.type
    };

    return this.http.put<PreSignedAttachment>(`${this.attachmentsApiUrl}${companyId}`, metaData);
  }

  private getMappingEndpoint(context: AttachmentsObjectContext): Observable<string> {
    const { objectId, objectType } = context;
    if (!objectId || !objectType) {
      return throwError(ERROR_MESSAGES.OBJECT_DATA_MISSING);
    }

    const apiEndpoint = this.attachmentsApiEndpoints[objectType];
    if (!apiEndpoint) {
      return throwError(ERROR_MESSAGES.GENERIC_ERROR);
    }

    return of(apiEndpoint);
  }

  private createAttachmentMapping(key: string, context: AttachmentsObjectContext): Observable<AttachmentMappingDO> {
    const objectTypeKey = context.objectType && context.objectType.toLowerCase();

    return this.getMappingEndpoint(context)
      .pipe(
        switchMap(endpoint =>
          this.http.post<AttachmentMappingDO>(`${this.apiUrl}${endpoint}`, {
            key,
            [objectTypeKey]: context.objectId
          })
        )
      );
  }

  public deleteAttachmentMapping(mappingId: number, context: AttachmentsObjectContext) {
    return this.getMappingEndpoint(context)
      .pipe(
        switchMap(endpoint =>
          this.http.delete(`${this.apiUrl}${endpoint}${mappingId}/`)
        )
      );
  }

  private uploadFile(file: File, context: AttachmentsObjectContext): Observable<Attachment> {
    return this.createPreSignRequest(file, context.companyId)
      .pipe(
        switchMap(result => {
          const { url, key } = result;
          if (!url) {
            return throwError(ERROR_MESSAGES.GENERIC_ERROR);
          }

          return this.createUploadRequest(file, url)
            .pipe(map(() => key));
        }),
        switchMap((key: string) => this.createAttachmentMapping(key, context)),
        map((mapping: AttachmentMappingDO) => AttachmentsService.createAttachmentObject(mapping, null, file)),
        catchError(() => {
          return of(null);
        })
      )
  }

  public uploadFiles(fileList: FileList, context: AttachmentsObjectContext): Observable<Attachment[]> {
    if (!context || !context.companyId) {
      return throwError(ERROR_MESSAGES.COMPANY_ID_MISSING);
    }
    this.utilityService.showCustomToastr('Loading files');

    const uploadRequests: Observable<Attachment>[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      uploadRequests.push(this.uploadFile(file, context));
    }

    return forkJoin(uploadRequests)
      .pipe(
        map(attachments => {
          const successfulUploads = attachments.filter((file) => !!file);
          const successfulUploadsCount = successfulUploads.length;
          const failedUploadsCount = attachments.length - successfulUploads.length;
          let message = `${successfulUploadsCount} ${successfulUploadsCount > 1 ? 'files' : 'file'} uploaded`;
          if (failedUploadsCount > 0) {
            message += `\n${failedUploadsCount} ${failedUploadsCount > 1 ? 'uploads' : 'upload'} failed`;
          }

          this.utilityService.showCustomToastr(message, null, { timeOut: INFO_MESSAGE_DURATION });

          return successfulUploads;
        })
      )
  }

  private getFileMetaData(key: string, companyId: number): Observable<AttachmentLambda> {
    if (!companyId) {
      return throwError(ERROR_MESSAGES.COMPANY_ID_MISSING);
    }

    return this.http.get<AttachmentLambda>(`${this.attachmentsApiUrl}${companyId}/${key}`)
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
  }

  public getFilesMetaData(fileMappings: AttachmentMappingDO[], companyId: number): Observable<Attachment[]> {
    if (!companyId) {
      return throwError(ERROR_MESSAGES.COMPANY_ID_MISSING);
    }

    const mappingByKey = fileMappings.reduce((result, mapping) => ({
      ...result,
      [mapping.key]: mapping
    }), {});

    return this.http.post<any>(
      `${this.attachmentsApiUrl}get-objects/${companyId}`,
      { keys: fileMappings.map(fm => fm.key) }
    )
      .pipe(
        catchError(() => of([])),
        map(data => (
          (data || []).map(item => {
            const mapping = mappingByKey[item.key];
            return mapping ? AttachmentsService.createAttachmentObject(mapping, item) : null;
          }))
        ),
        filter(attachments => attachments.filter(attachment => !!attachment))
      );
  }

  public downloadFile(attachment: Attachment, companyId: number, isUrlReturn: boolean): Observable<any> {
    if (!companyId) {
      return throwError(ERROR_MESSAGES.COMPANY_ID_MISSING);
    }
    const paramsObject = isUrlReturn ? { getLink: true } : { downloadAs: attachment.filename };
    return this.http.get(`${this.attachmentsApiUrl}${companyId}/${attachment.key}`, {
      params: paramsObject
    })
      .pipe(
        tap((result: { url: string }) => {
          const { url } = result;
          if (!url) {
            throw new Error(ERROR_MESSAGES.DOWNLOAD_FAILED);
          }
          if (isUrlReturn) {
            return url;
          }
          window.open(url);
        }),
        catchError(() => {
          this.handleError(ERROR_MESSAGES.DOWNLOAD_FAILED);
          return of(null);
        })
      )
  }

  public deleteFile(file: Attachment, context: AttachmentsObjectContext): Observable<any> {
    if (!context || !context.companyId) {
      return throwError(()=> new Error(ERROR_MESSAGES.COMPANY_ID_MISSING));
    }

    return this.deleteAttachmentMapping(file.id, context)
      .pipe(
        map(() => {
          this.utilityService.showCustomToastr(`${file.filename} Deleted`, null, { timeOut: INFO_MESSAGE_DURATION });
          return file.key;
        }),
        catchError((err) => {
          const errorMessage = (err.status && err.status === 403) ?
            ERROR_MESSAGES.DELETE_PERMISSIONS :
            ERROR_MESSAGES.DELETE_FAILED;
          this.handleError(errorMessage);
          return of(null);
        })
      );
  }

  public getScanResults(keys: string[], companyId: number): Observable<AttachmentLambda[]> {
    if (!companyId) {
      return throwError(ERROR_MESSAGES.COMPANY_ID_MISSING);
    }

    return this.http.post<AttachmentLambda[]>(`${this.attachmentsApiUrl}scan-status/${companyId}`, { keys })
      .pipe(
        catchError(() => {
          return of([]);
        })
      )
  }
}
