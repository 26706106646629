<widget-card [isLoading]="state === widgetState.LOADING"
  [overlayText]="campaigns?.length ? overlayText : null"
  [overlayAction]="overlayAction.bind(this)"
>
  <div class="campaign-spent" [class.is-not-ready]="state !== widgetState.READY">
    <simple-widget-header>
      Campaign<br>
      allocation
    </simple-widget-header>
    <div class="content">
      <ng-container *ngIf="!campaigns?.length">
        <spend-chart-placeholder>
          You have not created<br>
          any campaigns yet.
        </spend-chart-placeholder>
        <button mat-flat-button color="primary" (click)="openCampaignCreation()" *ngIf="editPermission">
          Create a Campaign
        </button>
      </ng-container>
      <ng-container *ngIf="campaigns?.length">
        <div class="charts">
          <progress-chart
            radius="80"
            class="year-progress"
            [data]="allocatedData"
          >
          </progress-chart>
          <progress-chart
            radius="69"
            class="budget-progress"
            [data]="spendData"
          >
          </progress-chart>
          <spend-chart-value
            [value]="spendProgress"
            [cegFlagEnabled]="cegFlagEnabled"
          >
            <ng-container slot="value">Campaign spend</ng-container>
            <ng-container *ngIf="!cegFlagEnabled" slot="remark">+ {{ plannedProgress }}% Planned expenses</ng-container>
          </spend-chart-value>
        </div>
        <spend-chart-summary>
          {{ allocatedProgress }}% allocated to<br>
          campaigns
        </spend-chart-summary>
      </ng-container>
    </div>
  </div>
</widget-card>
