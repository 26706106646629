import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface EnableSaasOpticsIdContext {
  okHandler: (customDomain: string) => void;
  saaSOpticsId: string;
}

@Component({
  selector: 'app-enable-saas-optics-id',
  templateUrl: './enable-saas-optics-id.component.html',
  styleUrls: ['./enable-saas-optics-id.component.scss']
})
export class EnableSaasOpticsIdComponent {
  public context: EnableSaasOpticsIdContext;
  public saaSOpticsId: string;

  constructor(
    public dialogRef: MatDialogRef<EnableSaasOpticsIdContext>,
    @Inject(MAT_DIALOG_DATA) context: EnableSaasOpticsIdContext,
  ) {
    this.context = context;
    this.saaSOpticsId = context.saaSOpticsId;
  }

  enable() {
    if (this.context?.okHandler) {
      this.context.okHandler(this.saaSOpticsId);
    }
    this.dialogRef.close();
  }

}
