import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'arrow-toggler',
  templateUrl: './arrow-toggler.component.html',
  styleUrls: ['./arrow-toggler.component.scss']
})
export class ArrowTogglerComponent {
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Output() change = new EventEmitter();

  onClick(e) {
    e.stopPropagation();
    this.change.emit(!this.active);
  }
}
