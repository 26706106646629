<h1 class="box-logo">
  <a [routerLink]="['/manage_company']">
    <img src="assets/images/planful-logo-simple-colored.svg" title="" alt="" />
  </a>
</h1>
<ul class="list-menu-bar">
  <li>
    <a href="javascript:;" [routerLink]="['/manage_company']">
      <img src="assets/images/icon-account.svg" title="" alt="">
    </a>
    <div class="menu-item-tooltip">SuperAdmin | Accounts</div>
  </li>
  <li>
    <a href="javascript:;" [routerLink]="['/manage_superadmins']">
      <img src="assets/images/icon-systemAdmin.svg" title="" alt="">
    </a>
    <div class="menu-item-tooltip">SuperAdmin | Manage Superadmins</div>
  </li>
</ul>
