import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BudgetEditDialogData, BudgetEditDialogFormData, } from './budget-edit-modal.types';
import { BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { CUSTOM_DATE_FORMATS } from 'app/shared/constants/date-picker-format';
import { Configuration } from 'app/app.constants';

@Component({
  selector: 'budget-edit-modal',
  templateUrl: './budget-edit-modal.component.html',
  styleUrls: ['./budget-edit-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class BudgetEditModalComponent implements OnInit {
  form: UntypedFormGroup;
  editMode = false;
  timeframeTypes = [
    {
      id: BudgetTimeframesType.Month,
      title: 'Monthly',
    },
    {
      id: BudgetTimeframesType.Quarter,
      title: 'Quarterly',
    }
  ]
  MAX_TEXT_INPUT_LENGTH = this.configuration.MAX_TEXT_INPUT_LENGTH;
  currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', allowNegative: false, prefix: '' };
  
  constructor(
    public dialogRef: MatDialogRef<BudgetEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BudgetEditDialogData,
    public fb: UntypedFormBuilder,
    private readonly configuration: Configuration
  ) {
  }

  ngOnInit(): void {
    this.editMode = !!this.data.budgetData;
    // Add yearly timeframe type if it's an edit mode and Yearly Budget was selected
    if(this.editMode && this.data.budgetData?.type === BudgetTimeframesType.Year) {
      this.timeframeTypes.push({ id: BudgetTimeframesType.Year, title: 'Yearly' });
    }
    this.initForm(this.data.budgetData);
  }

  private initForm(data: BudgetEditDialogFormData): void {
    const defaultDate = new Date();
    defaultDate.setMonth(0); // January is month 0
    defaultDate.setDate(1); // Set the day to 1
    const formConfig = {
      name: [data?.name || '', Validators.compose([Validators.required, Validators.maxLength(this.MAX_TEXT_INPUT_LENGTH)])],
      owner: [data?.owner || this.data.accountOwnerId, Validators.required],
      amount: [data?.amount || 0],
      type: [{ value: data?.type || this.timeframeTypes[0].id, disabled: this.editMode }, Validators.required],
      startDate: [data?.startDate || defaultDate, Validators.required],
      cegStatusesEnabled: [this.setCEGStatusToggleValue(data?.cegStatusesEnabled, this.data.isDefaultCEGCompany)]
    };
    this.form = this.fb.group(formConfig);
  }

  private setCEGStatusToggleValue(budgetCEGStatus: boolean, companyCEGStatus: boolean): { value: boolean, disabled: boolean } | boolean {
    const getToggleStatus = (budgetStatus: boolean, companyStatus: boolean): boolean => {
      return typeof budgetStatus === 'boolean' ? budgetStatus : companyStatus;
    }
    return {
      value: getToggleStatus(budgetCEGStatus, companyCEGStatus),
      disabled: getToggleStatus(budgetCEGStatus, companyCEGStatus)
    };
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close(this.form.getRawValue());
  }

}
