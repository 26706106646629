<div class="manage-page app-page">
  <page-header pageName="Manage">
    <fa-icon slot="icon" [icon]="['far', 'rocket-launch']"></fa-icon>
    <div slot="name">
      <tab-switch
        [options]="dataModeOptions"
        [selectedValue]="dataMode"
        (onChange)="handleDataModeChange($event)"
      ></tab-switch>
    </div>
  </page-header>
  <section class="page-content">
    <div class="page-container">
      <manage-table-container
        [timeframes]="filteredTimeframes"
        [budget]="budget"
        [currency]="budgetCurrency"
       ></manage-table-container>
    </div>
  </section>
</div>
