<ng-container *ngIf="stackManager.stackConfig as config">
  <ng-container *ngFor="let type of drawerTypeValues">
    <div *ngIf="config[type]" [@routeAnimations]="'openDetails'">
      <ng-container
        [ngTemplateOutlet]="drawerTemplate"
        [ngTemplateOutletContext]="{
          $implicit: type,
          drawerConfig: config[type]
        }"
      ></ng-container>
    </div>
  </ng-container>
  <div class="stack-curtain" (click)="closeStack()"></div>
</ng-container>

<ng-template #drawerTemplate let-drawerType let-drawerConfig="drawerConfig">
  <ng-container [ngSwitch]="drawerType">
    <campaign-details-drawer
      #campaign
      *ngSwitchCase="drawerType === drawerTypes.Campaign || drawerType === drawerTypes.ChildCampaign ? drawerType : ''"
      [ngStyle]="{ 'margin-right': getDrawerMargin(drawerConfig.level) }"
      [objectId]="drawerConfig.id"
      [drawerType]="drawerType"
      [isActive]="isDrawerActive(drawerConfig.level)"
    ></campaign-details-drawer>

    <program-details-drawer
      #program
      *ngSwitchCase="drawerTypes.Program"
      [ngStyle]="{ 'margin-right': getDrawerMargin(drawerConfig.level) }"
      [objectId]="drawerConfig.id"
      [drawerType]="drawerType"
      [isActive]="isDrawerActive(drawerConfig.level)"
    ></program-details-drawer>

    <goal-details-drawer
      #goal
      *ngSwitchCase="drawerTypes.Goal"
      [ngStyle]="{ 'margin-right': getDrawerMargin(drawerConfig.level) }"
      [objectId]="drawerConfig.id"
      [drawerType]="drawerType"
      [isActive]="isDrawerActive(drawerConfig.level)"
    ></goal-details-drawer>


    <metric-details-drawer
      #goal
      *ngSwitchCase="drawerTypes.Metric"
      [ngStyle]="{ 'margin-right': getDrawerMargin(drawerConfig.level) }"
      [objectId]="drawerConfig.id"
      [drawerType]="drawerType"
      [isActive]="isDrawerActive(drawerConfig.level)"
    ></metric-details-drawer>

    <expense-details
      #expense
      *ngSwitchCase="drawerTypes.Expense"
      [ngStyle]="{ 'margin-right': getDrawerMargin(drawerConfig.level) }"
      [objectId]="drawerConfig.id"
      [drawerType]="drawerType"
      [isActive]="isDrawerActive(drawerConfig.level)"
    ></expense-details>
  </ng-container>
</ng-template>
