import { Injectable } from '@angular/core';
import { TagService } from './backend/tag.service';
import { CompanyDataService } from './company-data.service';
import { tap } from 'rxjs/operators';

export interface TagManager {
  attachExistingTagToObject(tagId: number, objectId: number, inherit: boolean): void;
  createNewTagAndAttachToObject(title: string, objectId: number, inherit: boolean): void;
  detachTag(tagMappingId: number, inherit: boolean): void;
  closeSubscriptions(): void;
}

@Injectable({
  providedIn: 'root'
})
export class TagManagerFactory {
  constructor(public tagService: TagService, public companyDataService: CompanyDataService) {}

  public createTagManager(
    companyId: number,
    tagMappingType: string,
    objectTagsContainer: any,
    objectTagsPropName: string,
    allCompanyTagsContainer: any,
    allCompanyTagsPropName: string,
    errorHandler?: (error) => void,
    successHandler?: ({ inherit: boolean }) => void): TagManager {

    const subscriptions = [];
    const tagManager = {
      attachExistingTagToObject: (tagId: number, objectId: number, inherit: boolean) => {
        const addMappingData = {
          tags: tagId,
          status: 'Active',
          mapping_type: tagMappingType,
          map_id: objectId,
          is_dynamic: inherit,
          value: null
        };
        subscriptions.push(
          this.tagService.createTagMapping(addMappingData)
            .pipe(tap(() => this.companyDataService.loadTags(companyId, errorHandler)))
            .subscribe(
              responseData => {
                const addedtagMapping = {
                  display: responseData.tags_detail.name,
                  id: responseData.id,
                  tags_mapping_id: responseData.id,
                  name: responseData.tags_detail.name,
                  tag_id: responseData.tags_detail.id,
                  is_custom: responseData.tags_detail.is_custom,
                  is_dynamic: responseData.is_dynamic
                };
                const tagsContainer =
                  typeof objectTagsContainer === 'function' ?
                    objectTagsContainer() :
                    objectTagsContainer;
                tagsContainer[objectTagsPropName] =
                  [...tagsContainer[objectTagsPropName], addedtagMapping];
                  successHandler?.({ inherit });
              },
              errorHandler
          )
        );
      },
      createNewTagAndAttachToObject: (title: string, objectId: number, inherit: boolean) => {
        const addTagData = {
          name: title,
          is_custom: true,
          company: companyId
        };
        subscriptions.push(
          this.tagService.createTag(addTagData).subscribe(responseData => {
            const allTagsContainerObj =
              typeof allCompanyTagsContainer === 'function' ?
                allCompanyTagsContainer() :
                allCompanyTagsContainer;
            allTagsContainerObj[allCompanyTagsPropName] =
              [...allTagsContainerObj[allCompanyTagsPropName], {title: responseData.name, id: responseData.id}];
            tagManager.attachExistingTagToObject(responseData.id, objectId, inherit);
          }, errorHandler)
        );
      },
      detachTag: (tagMappingId: number, inherit: boolean) => {
        subscriptions.push(
          this.tagService.deleteTagMapping(tagMappingId, inherit).subscribe(() => {
            const tagsContainer =
              typeof objectTagsContainer === 'function' ?
                objectTagsContainer() :
                objectTagsContainer;
            tagsContainer[objectTagsPropName] =
              tagsContainer[objectTagsPropName].filter(tagMapping => tagMapping.id !== tagMappingId);
              successHandler?.({ inherit });
          }, errorHandler)
        );
      },
      closeSubscriptions: () => {
        subscriptions.forEach(s => s.unsubscribe());
      }
    };
    return tagManager;
  }
}
