import { Component, Input } from '@angular/core';
import { MetricListItem } from '../../types/metric-integration';
import { sortMetricsList } from 'app/shared/utils/common.utils';

@Component({
  selector: 'metrics-list',
  templateUrl: './metrics-list.component.html',
  styleUrls: ['./metrics-list.component.scss']
})
export class MetricsListComponent {
  MAX_VISIBLE_ITEMS = 3;
  allMetrics: MetricListItem[] = [];
  metricsForDisplay: MetricListItem[] = [];
  expanded = false;

  @Input() set metrics(metrics: MetricListItem[]) {
    this.allMetrics = metrics || [];
    sortMetricsList(this.allMetrics);
    this.metricsForDisplay = this.allMetrics.slice(0, this.MAX_VISIBLE_ITEMS);
    this.expanded = false;
  }

  toggleState() {
    this.metricsForDisplay = this.expanded ? this.allMetrics.slice(0, this.MAX_VISIBLE_ITEMS) : this.allMetrics;
    this.expanded = !this.expanded;
  }
}
