<ul *ngIf="!!allMetrics.length; else emptyMsg" class="metrics-list">
  <li class="heading metric-line">
    <span>
      Metric Name
      <span class="total" *ngIf="allMetrics.length > MAX_VISIBLE_ITEMS">({{ allMetrics.length }})</span></span>
    <span>Metric Value</span>
  </li>
  <li *ngFor="let metric of metricsForDisplay" class="metric-line">
    <span class="name">
      <span *ngIf="metric.productName" class="prefix">{{ metric.productName }} </span>
      <span>{{ metric.name }}</span>
    </span>
    <span class="value">{{ metric.value }}</span>
  </li>
</ul>

<ng-template #emptyMsg>
  <h5 class="text-center p-1">No metrics for this campaign</h5>
</ng-template>

<div class="text-center" *ngIf="allMetrics.length > MAX_VISIBLE_ITEMS">
  <button class="show-more"
          mat-button
          color="primary"
          (click)="toggleState()">Show {{ expanded ? 'Less' : 'More' }}</button>
</div>
