<header class="dialog-title">
  {{ dialogData?.integration?.name }} Campaign Mapping<br/>
  to Planful <span class="highlight">"{{ dialogData?.campaignName }}"</span> Campaign
</header>
<div class="dialog-content">
  <div class="filter-campaigns">
    <input class="filter-input" [formControl]="filterControl" placeholder="Filter by text"/>
    <div class="sort-campaigns">
      <dropdown-menu
        [items]="sortDropdownMenuItems"
        [selectedValue]="campaignsSortingMode"
        (onSelectValue)="changeSorting($event)"
      ></dropdown-menu>
    </div>
  </div>
  <metric-mapping-management
    [sorting]="campaignsSortingMode"
    [readOnly]="dialogData.readOnly"
    [integration]="dialogData.integration"
    [campaignName]="dialogData.campaignName"
    [integrationCampaigns]="campaigns | externalCampaignsFilter: filterText"
    [placeholderText]="placeholderText"
    (updateMappingState)="updateCampaignsSnapshot($event)"
    (navigateToCampaign)="navigateToCampaign($event)"
  >
  </metric-mapping-management>
</div>
<footer mat-dialog-actions>
  <button mat-button color="primary" (click)="closeClick.emit()">Cancel</button>
  <button [disabled]="dialogData.readOnly || !campaignsSnapshot"
          mat-flat-button
          color="primary"
          (click)="saveMappingState.emit(campaignsSnapshot)">Save</button>
</footer>
