import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SegmentDataInheritanceAction } from '../types/segment-data-inheritance.interface';
import { BudgetObjectService } from './budget-object.service';
import { Configuration } from 'app/app.constants';
import { BudgetObjectDialogService } from './budget-object-dialog.service';
import { DIALOG_ACTION_TYPE, DialogAction } from '../types/dialog-context.interface';

@Injectable({
  providedIn: 'root'
})
export class SegmentDataInheritanceService {
  private static getActionConfirmationQuestionText(objectName: string, isProgram: boolean, isNewCEGStructure: boolean): string {
    const childObjectsStr = isNewCEGStructure ? 'expense groups' : `all expenses ${isProgram ? '' : 'and expense groups'}`;
    return `Would you like to <b>keep</b> the ${childObjectsStr}
        and just change the segment for this ${objectName} or <b>replace</b> them with this new segment?`;
  }

  private static getChangeSegmentStatementText(
    fullObjectName: string,
    hasChildren: boolean,
    isProgram: boolean,
    isNewCEGStructure: boolean
  ): string {
    const ifChangeStr = `If you change the segment for ${fullObjectName}`;
    const childObjectsStr = isNewCEGStructure ? 'expense groups' : `all expenses ${isProgram ? '' : 'and expense groups'}`;
    const moveChildrenStr = ` then you can either <b>keep</b> ${childObjectsStr} associated with
    it in their current segments or else <b>replace</b> them all with the new segment`;

    return `${ifChangeStr}${hasChildren ? moveChildrenStr : ''}`;
  }

  private static getMultiUpdateConfirmationMessage(isPlural = false): string {
    const updateTarget = isPlural ? 'these objects' : 'this object';

    return `If you change the segment for ${updateTarget} then
      you can either <b>keep</b> all child objects associated with
      ${isPlural ? 'them' : 'it'} in their current segments or else <b>replace</b> them all with the new segment<br><br>
      Would you like to <b>keep</b> the child objects and just change the segment
      for ${updateTarget} or <b>replace</b> them with this new segment?.<br>
      <b>This action can not be undone.</b>
    `;
  }

  constructor(
    private configuration: Configuration,
    private budgetObjectService: BudgetObjectService,
    private readonly dialogManager: BudgetObjectDialogService
  ) {}

  private getConfirmationMessage(objectType: string, isNewCEGStructure: boolean, hasChildren = false): string {
    const isProgram = objectType === this.configuration.OBJECT_TYPES.program;
    const isExpense = objectType === this.configuration.OBJECT_TYPES.expense;
    const objectNameArticle = (isProgram || isExpense) ? 'an' : 'a';
    const objectName = this.budgetObjectService.getObjectTypeName(objectType);
    const fullObjectName = `${objectNameArticle} ${objectName}`;
    return `${SegmentDataInheritanceService.getChangeSegmentStatementText(
      fullObjectName,
      hasChildren,
      isProgram,
      isNewCEGStructure
    )}<br><br>${SegmentDataInheritanceService.getActionConfirmationQuestionText(objectName, hasChildren, isNewCEGStructure)}`;
  }

  public confirmSegmentChange(
    objectType: string,
    hasChildren: boolean,
    isNewCEGStructure: boolean,
    multiUpdate = false,
    isPlural = false
  ): Observable<SegmentDataInheritanceAction> {
    return new Observable(observer => {
      const cancelAction: DialogAction = {
        label: 'Cancel',
        type: DIALOG_ACTION_TYPE.STROKED,
        handler: () => {
          observer.next(SegmentDataInheritanceAction.None);
          observer.complete();
        }
      };
      const keepAction: DialogAction = {
        label: 'Keep',
        type: DIALOG_ACTION_TYPE.STROKED,
        handler: () => {
          observer.next(SegmentDataInheritanceAction.Keep);
          observer.complete();
        }
      };
      const updateAction: DialogAction = {
        label: 'Replace',
        type: DIALOG_ACTION_TYPE.FLAT,
        handler: () => {
          observer.next(SegmentDataInheritanceAction.Replace);
          observer.complete();
        }
      };

      const modalActions = [cancelAction, keepAction, updateAction];
      const modalText = multiUpdate
       ? SegmentDataInheritanceService.getMultiUpdateConfirmationMessage(isPlural)
       : this.getConfirmationMessage(objectType, isNewCEGStructure, hasChildren);

      this.dialogManager.openConfirmationDialog({
        title: 'Change Segment',
        content: modalText,
        actions: modalActions
      });
    });
  }
}
