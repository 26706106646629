import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthExpiryModalComponent, AuthExpiryModalContext } from '../modals/auth-expiry-modal/auth-expiry-modal.component';
import { DIALOG_CONFIG } from '../../metric-integrations/components/integration-setup-dialog/integration-setup-dialog.config';
import { IntegrationSetupDialogData } from '../../metric-integrations/types/integration-setup-dialog-data.interface';
import { IntegrationSetupDialogComponent } from '../../metric-integrations/components/integration-setup-dialog/integration-setup-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AuthModalService {
  constructor(public dialog: MatDialog) {}

  openInitialUpdatePopup(data: AuthExpiryModalContext): Observable<any> {
    const dialogRef = this.dialog.open(AuthExpiryModalComponent, {
      width: '480px',
      data: data
    });

    return dialogRef.afterClosed();
  }

  closePopup() {
    this.dialog.closeAll();
  }

  openSubsequentUpdatePopup(data : IntegrationSetupDialogData): Observable<any> {
    const dialogRef = this.dialog.open(IntegrationSetupDialogComponent, {
      ...DIALOG_CONFIG,
      panelClass: 'reset-paddings',
      data: data
    });

    return dialogRef.afterClosed();
  }
}
