import { Component, Input, Output, ViewChild, ViewChildren, ElementRef, EventEmitter, HostListener, OnInit } from '@angular/core';

import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule, SharedCostRuleSegment } from 'app/shared/types/shared-cost-rule.interface';
import { BudgetObjectSegmentData } from 'app/shared/types/budget-object-segment-data.interface';
import { SharedRuleTooltipData } from 'app/shared/components/shared-rule-tooltip/shared-rule-tooltip.type';
import { SharedIconState } from 'app/shared/components/icons/icon-shared/shared.type';

const TOOLTIP_OFFSETS = {
  x: 6,
  y: -30
}
const TOOLTIP_WIDTH = 200;

@Component({
  selector: 'segments-rules-select',
  templateUrl: './segments-rules-select.component.html',
  styleUrls: ['./segments-rules-select.component.scss']
})
export class SegmentsRulesSelectComponent implements OnInit {
  @Input() segmentList: BudgetSegmentAccess[] = [];
  @Input() sharedCostRuleList: SharedCostRule[] = [];
  @Input() selectedData: BudgetObjectSegmentData = {};
  @Input() isTooltipAttachedToLeft = false;
  @Input() currentSharedState = SharedIconState.Empty;
  @Input() dropDownTrigger = false;
  @Input() readOnly = false;
  @Input() overlapContainer: Element;
  @Input() segmentNameById: {[key: number]: string} = {};

  @Output() selectValue = new EventEmitter<BudgetObjectSegmentData>();
  @Output() selectValueWithKeyboard = new EventEmitter<BudgetObjectSegmentData>();

  @ViewChild('dropDownContainer', { read: ElementRef }) dropDownContainer: ElementRef;
  @ViewChild('segmentsContainer', { read: ElementRef }) segmentsContainer: ElementRef;
  @ViewChild('sharedCostRulesContainer', { read: ElementRef }) sharedCostRulesContainer: ElementRef;
  @ViewChildren('segmentItem') segmentElements;
  @ViewChildren('ruleItem') ruleElements;
  @ViewChild('overlapTrigger', { read: ElementRef }) overlapTrigger: ElementRef;

  isTooltipShown = false;
  tooltipData: SharedRuleTooltipData = {
    id: null,
    name: '',
    segments: [],
    coords: {}
  };
  sharedIconState = SharedIconState;
  opened: boolean;
  segmentFocusIndex = -1;
  ruleFocusIndex = -1;
  moveFocus: Function = () => {};
  selectItemWithKeyboard: Function = () => {};
  scrollToFocusedElement: Function = () => {};

  ngOnInit() {
    this.createSegmentsNavigationStrategy(-1);
  }

  get isSegmentsContainerOverflowed() {
    if (this.segmentsContainer) {
      const { scrollHeight, offsetHeight } = this.segmentsContainer.nativeElement;
      return scrollHeight > offsetHeight;
    }
    return false;
  }

  get isSegmentsContainerScrolledToTop() {
    if (this.segmentsContainer) {
      const { scrollTop } = this.segmentsContainer.nativeElement;
      return scrollTop === 0;
    }
    return false;
  }

  get isSegmentsContainerScrolledToBottom() {
    if (this.segmentsContainer) {
      const { scrollTop, offsetHeight, scrollHeight } = this.segmentsContainer.nativeElement;
      return scrollTop + offsetHeight === scrollHeight;
    }
    return false;
  }

  get isSharedCostRulesContainerOverflowed() {
    if (this.sharedCostRulesContainer) {
      const {scrollHeight, offsetHeight} = this.sharedCostRulesContainer.nativeElement
      return scrollHeight > offsetHeight;
    }
    return false;
  }

  get isSharedCostRulesContainerScrolledToTop() {
    if (this.sharedCostRulesContainer) {
      return this.sharedCostRulesContainer.nativeElement.scrollTop === 0;
    }
    return false;
  }

  get isSharedCostRulesContainerScrolledToBottom() {
    if (this.sharedCostRulesContainer) {
      const { scrollTop, offsetHeight, scrollHeight } = this.sharedCostRulesContainer.nativeElement;
      return scrollTop + offsetHeight === scrollHeight;
    }
    return false;
  }

  openSelect() {
    if (!this.opened) {
      this.opened = true;
      setTimeout(() => this.dropDownContainer.nativeElement.focus(), 0)
    }
  }

  closeSelect() {
    if (this.opened) {
      this.opened = false;
      this.hideTooltip();
    }
  }

  showTooltip(e: any, ruleId: number) {
    const rule = this.sharedCostRuleList.find(el => el.id === ruleId);
    if (rule) {
      this.isTooltipShown = true;
      this.prepareCoordsForTooltip(e);
      this.prepareDataForTooltip(rule);
    }
  }

  hideTooltip() {
    this.isTooltipShown = false;
  }

  prepareCoordsForTooltip(e: any) {
    const coords = e.target.getBoundingClientRect();
    const { x, y, width } = coords;
    this.tooltipData.coords.top = y + TOOLTIP_OFFSETS.y;
    if (this.isTooltipAttachedToLeft) {
      this.tooltipData.coords.left = x - TOOLTIP_WIDTH - TOOLTIP_OFFSETS.x;
    } else {
      this.tooltipData.coords.left = x + width + TOOLTIP_OFFSETS.x;
    }
  }

  prepareDataForTooltip(rule: SharedCostRule) {
    this.tooltipData.id = rule.id;
    this.tooltipData.name = rule.name;
    this.tooltipData.segments = rule.segments.map((segment: SharedCostRuleSegment) => {
      const { id, cost } = segment;
      const budgetSegment: BudgetSegmentAccess = this.segmentList.find((s: BudgetSegmentAccess) => s.id === id);
      return {
        id,
        cost,
        name: this.segmentNameById[id]
      };
    });
  }

  handleSelectSegment = (segment: BudgetSegmentAccess, isKeyboard?: boolean) => {
    if (!segment) {
      return;
    }

    if (isKeyboard) {
      this.selectValueWithKeyboard.emit({ budgetSegmentId: segment.id });
    } else {
      this.selectValue.emit({ budgetSegmentId: segment.id });
    }
    this.closeSelect();
  }

  handleSelectSharedCostRule = (rule: SharedCostRule, isKeyboard?: boolean) => {
    if (!rule) {
      return;
    }

    if (isKeyboard) {
      this.selectValueWithKeyboard.emit({ sharedCostRuleId: rule.id });
    } else {
      this.selectValue.emit({ sharedCostRuleId: rule.id });
    }
    this.closeSelect();
  }

  moveFocusAmongSegments(isMovingDown: boolean) {
    const isGoingOutside = this.checkGoingOutsideList(
      this.segmentFocusIndex,
      this.segmentList,
      this.sharedCostRuleList,
      isMovingDown,
      this.createRulesNavigationStrategy
    );
    if (isGoingOutside) {
      return;
    }

    if (isMovingDown) {
      this.segmentFocusIndex++;
    } else {
      this.segmentFocusIndex--;
    }
  }

  moveFocusAmongRules(isMovingDown: boolean) {
    const isGoingOutside = this.checkGoingOutsideList(
      this.ruleFocusIndex,
      this.sharedCostRuleList,
      this.segmentList,
      isMovingDown,
      this.createSegmentsNavigationStrategy
    );
    if (isGoingOutside) {
      return;
    }

    if (isMovingDown) {
      this.ruleFocusIndex++;
    } else {
      this.ruleFocusIndex--;
    }
  }

  selectSegmentWithKeyboard() {
    this.selectWithKeyboard(this.segmentFocusIndex, this.segmentList, this.handleSelectSegment);
  }

  selectRuleWithKeyboard() {
    this.selectWithKeyboard(this.ruleFocusIndex, this.sharedCostRuleList, this.handleSelectSharedCostRule);
  }

  selectWithKeyboard(focusedItemIndex: number, list: BudgetSegmentAccess[] | SharedCostRule[], selectItem: Function) {
    if (focusedItemIndex < 0) {
      return;
    }
    const focusedItem: BudgetSegmentAccess | SharedCostRule = list[focusedItemIndex];
    if (!focusedItem) {
      return;
    }

    // true means select value with keyboard
    selectItem(focusedItem, true);
  }

  scrollToFocusedSegment() {
    this.scrollToElement(this.segmentElements, this.segmentFocusIndex, this.segmentsContainer);
  }

  scrollToFocusedRule() {
    this.scrollToElement(this.ruleElements, this.ruleFocusIndex, this.sharedCostRulesContainer);
  }

  scrollToElement(elements: any, elementIndex: number, elementsContainer: any) {
    const focusedItem = elements._results[elementIndex];
    if (!focusedItem) {
      return;
    }

    const focusedElement = focusedItem.nativeElement;
    const focusedElementTopPosition = focusedElement.offsetTop;
    elementsContainer.nativeElement.scrollTop = focusedElementTopPosition;
  }

  createSegmentsNavigationStrategy = (startIndex: number) => {
    this.ruleFocusIndex = -1;
    this.segmentFocusIndex = startIndex;
    this.moveFocus = this.moveFocusAmongSegments;
    this.selectItemWithKeyboard = this.selectSegmentWithKeyboard;
    this.scrollToFocusedElement = this.scrollToFocusedSegment;
  }

  createRulesNavigationStrategy = (startIndex: number) => {
    this.segmentFocusIndex = -1;
    this.ruleFocusIndex = startIndex;
    this.moveFocus = this.moveFocusAmongRules;
    this.selectItemWithKeyboard = this.selectRuleWithKeyboard;
    this.scrollToFocusedElement = this.scrollToFocusedRule;
  }

  checkGoingOutsideList(
    currentListIndex: number,
    currentList: BudgetSegmentAccess[] | SharedCostRule[],
    otherList: BudgetSegmentAccess[] | SharedCostRule[],
    isMovingDown: boolean,
    createNewStrategyFunc: Function
  ): boolean {
    if (isMovingDown && currentListIndex >= currentList.length - 1) {
      createNewStrategyFunc(0);
      return true;
    }

    if (!isMovingDown && currentListIndex <= 0) {
      createNewStrategyFunc(otherList.length - 1);
      return true;
    }

    return false;
  }

  @HostListener('document:keydown.arrowup', ['$event'])
  moveFocusUp(e: Event) {
    e.preventDefault();
    const isMovingDown = false;
    this.moveFocus(isMovingDown);
    this.scrollToFocusedElement();
  }

  @HostListener('document:keydown.arrowdown', ['$event'])
  moveFocusDown(e: Event) {
    e.preventDefault();
    const isMovingDown = true;
    this.moveFocus(isMovingDown);
    this.scrollToFocusedElement();
  }

  @HostListener('document:keydown.space', ['$event'])
  handleSelectValueWithKeyboard(e: Event) {
    this.selectItemWithKeyboard();
  }
}
