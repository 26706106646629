<invoice-table
  [rowsData]="tableRows"
  [readOnlyMode]="readOnlyMode"
  [openedInDrawerId]="openedInDrawerId$ | async"
  (openDetails)="openReviewDialog($event)"></invoice-table>

<no-invoices-placeholder
  *ngIf="sidebarHasSelection && tableRows && !tableRows.length"
  [budgiEmail]="budgiEmail"
  [congratulationEnabled]="invoiceLiveTracking.isLastInvoice$ | async"
  [uploadDisabled]="invoiceUploadManager.isUploadInProgress$ | async" (changeMode)="changeMode()">
</no-invoices-placeholder>
