import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalContext } from '../components/modal-with-icon-header/modal-with-icon-header.component';

@Injectable({
  providedIn: 'root',
})
export class BudgetSelectionModalService {
  constructor() {}

  openDialogSource = new BehaviorSubject<BudgetSelectionContext | null>(null);
  openDialog$ = this.openDialogSource.asObservable();

  openBudgetSelectionDialog(data: BudgetSelectionContext) {
    this.openDialogSource.next(data);
  }

}

export interface SelectionBudgetDialogContext {
  currentBudgetId: number;
  groups: BudgetGroup[];
  selectedItemId?: number;
  selectedItemName?: string;
  selectedTab?: string;
}

export interface BudgetGroup {
  budget_id: number;
  budget_name: string;
  usage_count: number;
}

export interface BudgetSelectionContext extends ModalContext, SelectionBudgetDialogContext {}