<table-view
  [rows]="rows"
  [columnTitles]="columnTitles"
  [warningIcon]="true"
  [fullWidth]="columnTitles?.length > 6"
  [appliedSorting]="appliedSorting"
  [groupsExpandState]="groupsExpandState"
  (onSortRows)="sortRows($event)"
  [tooltipTypeProvider]="tooltipTypeProvider.bind(this)"
>
  <ng-template #tooltipsTemplate let-tooltipType>
    <title-description-tooltip
      [description]="tooltipDescriptionByType[tooltipType]"
      [ngClass]="tooltipType"
    >
      <ng-template #titleTemplate>
        <ng-container *ngIf="tooltipType === TooltipType.Available">
          <span class="font-monospace">Available Budget</span>
        </ng-container>
        <ng-container *ngIf="tooltipType === TooltipType.NegativeAvailable">
          <span class="font-monospace">(Negative) Available</span>
          <icon-flame class="title-icon-flame"></icon-flame>
        </ng-container>
      </ng-template>
    </title-description-tooltip>
  </ng-template>

  <ng-template #thContentTemplate let-columnTitle let-column="column">
    <div class="th-content">
      <arrow-toggler
        [matTooltip]="
          (expandedGroupsLength && availableGroups.length !== expandedGroupsLength)
          ? 'Expand All'
          : expandedGroupsLength !== 0 ? 'Collapse All' : 'Expand All'"
        [matTooltipShowDelay]="500"
        matTooltipClass="dark-theme-tooltip above"
        matTooltipPosition="above"
        [active]="expandedGroupsLength !== availableGroups.length"
        (change)="toggleAllGroups($event)"
        *ngIf="columnTitle === SEGMENT_NAME_TITLE && availableGroups.length"></arrow-toggler>
      <span *ngIf="tooltipTypeByStatusField[column.fieldName]" class="info-icon" [ngClass]="columnTitle === SEGMENT_NAME_TITLE ? 'ml-1' : 'mr-05'">
        <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
      </span>
      <div [ngClass]="{ 'group-title': columnTitle === SEGMENT_NAME_TITLE && availableGroups.length, 'single-item': columnTitle === SEGMENT_NAME_TITLE && !availableGroups.length }">
        {{ columnTitle }}
        <ng-container *ngIf="columnSubtitleByFieldName[column.fieldName]">
          <br/>
          <span class="subtitle">
            {{columnSubtitleByFieldName[column.fieldName]}}
          </span>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #rowTitleTemplate let-row>
    <div class="d-flex ai-center">
      <ng-container *ngIf="row.itemsInGroup; else singleItem">
        <arrow-toggler
          [matTooltip]="groupsExpandState[row.id] ? 'Collapse' : 'Expand'"
          [matTooltipShowDelay]="500"
          matTooltipClass="dark-theme-tooltip above"
          matTooltipPosition="above"
          [active]="!groupsExpandState[row.id]"
          (change)="toggleGroup(row, $event)">
        </arrow-toggler>
        <span class="group-title d-flex">
          <b [stringTruncate]="row.title"></b> <span class="font-w-500 ml-05">({{ row.itemsInGroup }})</span>
        </span>
      </ng-container>

      <ng-template #singleItem>
        <span [stringTruncate]="row.title"
              class="single-item-title"
              [ngClass]="row.groupId ? 'single-child-item' : 'single-item'"></span>
      </ng-template>
    </div>
  </ng-template>

</table-view>
