import { Component, Inject } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from '@common-lib/services/local-storage.service';

export interface NewCurrencyNotificationDialogContext {
  currencyName: string;
  currencyRate: number;
}

@Component({
  selector: 'add-new-currency-notification',
  styleUrls: ['./add-new-currency-notification.component.scss'],
  templateUrl: './add-new-currency-notification.component.html'
})
export class AddNewCurrencyNotificationComponent {
  currencyName = 'United States Dollar';
  currencyRate = '1.00';
  constructor(
    private readonly dialogRef: MatDialogRef<AddNewCurrencyNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewCurrencyNotificationDialogContext,
    private readonly configuration: Configuration
  ) {}

  handleDontShowMessageClick() {
    LocalStorageService.addToStorage(this.configuration.DISABLE_SHOWING_ADD_NEW_CURRENCY_NOTIFICATION, 'true');
    this.dialogRef.close();
  }
}
