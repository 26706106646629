import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { OptionsDropdownItem } from '../../common.interface';

@Component({
  selector: 'actions-dropdown',
  templateUrl: './actions-dropdown.component.html',
  styleUrls: ['./actions-dropdown.component.scss']
})
export class ActionsDropdownComponent implements OnChanges {
  @Input() title: string;
  @Input() disableDropdown: boolean;
  @Input() optionsList: OptionsDropdownItem[];
  @Output() toggleActionsDropDown = new EventEmitter<void>();

  activeOptionsItem: string;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.optionsList?.currentValue) {
      this.activeOptionsItem = null;
    }
  }

  onTriggerClick() {
    this.toggleActionsDropDown.emit();
  }

  toggleActiveGroup(id: string) {
    this.activeOptionsItem = this.activeOptionsItem === id ? null : id;
  }
}
