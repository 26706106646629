<ng-container *ngIf="{ companyList: companyList$ | async,
                       selectedCompany: selectedCompany$ | async } as data">
  <ng-container *ngIf="data.selectedCompany && data.companyList.length > 1; else selectPlaceholder">
    <button mat-button [matMenuTriggerFor]="menu" class="header-action-item select-trigger">
      <div [stringTruncate]="data.selectedCompany?.name"
           class="custom-select-trigger"></div>
      <fa-icon [icon]="['far', 'chevron-down']"></fa-icon>
    </button>
    <mat-menu #menu class="mat-pl-menu navigation-menu navigation-select" xPosition="before">
      <button *ngFor="let item of data.companyList" mat-menu-item (click)="onSelectCompany(item)" [class.active]="item.id === data.selectedCompany?.id">
        <span >{{ item.name }}</span>
      </button>
    </mat-menu>
  </ng-container>
  <ng-template #selectPlaceholder>
    <div [stringTruncate]="data.selectedCompany?.name"
    class="custom-select-trigger placeholder"></div>
  </ng-template>
</ng-container>
