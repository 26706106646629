import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { COLORS, COLORS_MAP } from '@shared/constants/colors.constants';
import { MetricProgressState } from '@shared/types/metric-progress-state.type';

@Component({
  selector: 'metric-performance-data',
  templateUrl: './metric-performance-data.component.html',
  styleUrls: ['./metric-performance-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricPerformanceDataComponent {

  @Input() isTabData = false;
  @Input() estimatedDiffPercentage = 0;
  @Input() progressState = MetricProgressState.InTarget;

  public readonly MetricProgress = MetricProgressState;
  public readonly colorsMap = COLORS_MAP;
}
