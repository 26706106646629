import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { BudgetPermission, UserPermission } from '../../shared/types/user-permission.type';

export enum ActionToConfirm {
  GrantAdminPermission = 'grantAdminPermission',
  GrantPermission = 'grantPermission',
  RevokePermission = 'revokePermission',
  DeleteUser = 'deleteUser',
  LeavePage = 'leavePage'
}

export interface TeamMember {
  id: number;
  profileId: number;
  companyUserId: number;
  sso: string;
  weeklyEmails: boolean;
  isActive: boolean;
  isAdmin: boolean;
  withPermission: boolean;
  inviteAccepted: boolean;
  invitationDate: string;
  editable: boolean;
  profileForm: UntypedFormGroup;
  permissionsForm: PermissionsListFormGroup;
  is_account_owner?: boolean;
}

export interface PermissionForm extends BudgetPermission {
  rollbackPermission: UserPermission;
  rollbackBudget: { id: number; name: string; };
}

export interface PermissionListForm {
  permissions: any;
}

type PermissionFormControls = { [key in keyof PermissionForm]: AbstractControl };
type PermissionListFormControls = { [key in keyof PermissionListForm]: UntypedFormArray };
export type PermissionFormGroup = UntypedFormGroup & { value: PermissionForm, controls: PermissionFormControls };
export type PermissionsListFormGroup = UntypedFormGroup & { value: PermissionListForm, controls: PermissionListFormControls };
