import { MetricUnit } from '../../types/metric-unit.interface';

export const metricUnitOptions = [
  {
    title: 'Quantity',
    value: MetricUnit.Quantity
  },
  {
    title: 'Amount',
    value: MetricUnit.Amount
  }
];

export const revenuePerOutcomeTooltip = 'Revenue per Outcome: \n\n \
  Revenue per Outcome (RPO) is the average revenue \
  for one instance of this outcome. If your metric is \
  “Deals”, then your RPO is the average revenue for a \
  deal.  If your metric is “Leads”, then the RPO is a \
  fraction of the revenue of a deal based on the percentage \
  of leads that become deals.  When planning, it is critical \
  to decide whether to standardize on one-time revenue (e.g. \
  the purchase price for a laptop) or lifetime revenue (e.g. \
  the total revenue over multiple years for a SaaS subscription). \n\n \
  This is fully defined in the book The Next CMO.';

export const revenuePerOutcomePredefined = 'This value is read-only. \n\n \
  It was set by your Planful admin.'

export const keyMetricTooltipDisabledLong = `You cannot select this metric to be
  the campaign value key metric
  because there is no average
  revenue per outcome defined for it,
  so campaign value cannot be
  calculated.`;

export const keyMetricTooltip = `The campaign value key metric is the
  single metric that best measures
  success for a given campaign. If
  selected, then the target and current
  values of this metric will be used to
  calculate estimated campaign value.`;

export const tooltip = `The campaign value key metric is the single metric that best measures success for a given campaign.  If selected, then the target and current values of this metric will be used to calculate estimated campaign value. `

const expenseDefinitionPlaceholder = '{expenses}';

export const CPOTooltipTemplate = `Current Cost Per Outcome is calculated:
  ${expenseDefinitionPlaceholder} /
  Current Outcome\n
  Target Cost per Outcome (CPO) is calculated:
  Planned Investment /
  Target Outcome`;

export const RROITooltipTemplate = `RROI = Revenue Return On Investment\n
  Current RROI is calculated:
  (Current Margin Return  –  ${expenseDefinitionPlaceholder}) / ${expenseDefinitionPlaceholder}\n
  Target RROI is calculated:
  (Target Return  –  Planned Investment) /
  Planned Investment`;

export const ROITooltipTemplate = `ROI = Margin Return On Investment\n
  Current ROI is calculated:
  (Current Return  –  ${expenseDefinitionPlaceholder}) / ${expenseDefinitionPlaceholder}\n
  Target ROI is calculated:
  (Target Return  –  Planned Investment) /
  Planned Investment`;

export const getMetricExplanationTooltip = (template: string, isCEGMode: boolean): string => {
  const regex = new RegExp(expenseDefinitionPlaceholder, 'g');
  const expenseDefinition = isCEGMode ? 'Expenses' : 'Closed or Committed Expenses';
  return template.replace(regex, expenseDefinition);
}
