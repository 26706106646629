import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MetricBreakdownListRow } from 'app/budget-object-details/components/metrics/metric-breakdown-list/metric-breakdown-list.type';
import { MetricBreakdownSectionItem } from 'app/budget-object-details/types/metric-breakdown-data.interface';
import { MetricIntegrationName, MetricIntegrationDisplayName } from 'app/metric-integrations/types/metric-integration';

@Component({
  selector: 'metric-details-breakdown',
  templateUrl: './metric-details-breakdown.component.html',
  styleUrls: ['./metric-details-breakdown.component.scss']
})
export class MetricDetailsBreakdownComponent implements OnInit {

  @Input() hasActiveThirdPartyAmounts: boolean;
  @Input() displayDecimal = false;
  @Input() isCampaignMetricDrawer = false;
  @Input() currentMetricData: MetricBreakdownSectionItem;
  @Input() integrationsMetricData: MetricBreakdownSectionItem[];
  @Input() childCampaignMetricsData: MetricBreakdownSectionItem[]; 
  @Input() goalCampaignMetricsData: MetricBreakdownSectionItem[];
  @Input() grandTotalData: MetricBreakdownListRow;
  @Input() metricName: string = '';
  @Input() funnelName: string = '';
  metricIntegrationDisplayNamesMap = MetricIntegrationDisplayName;
  metricIntegrationNames = MetricIntegrationName;

  decimalDisplayFormat = '1.0-0';
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.displayDecimal) {
      this.decimalDisplayFormat = this.displayDecimal ? '1.2-2' : '1.0-0';
    }
  }

}
