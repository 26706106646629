import { DIALOG_ACTION_TYPE } from '../types/dialog-context.interface';

export enum ConfirmType {
  DeleteGLCode = 'deleteGLCode',
  DeleteExpenseType = 'deleteExpenseType',
  DeleteGroupType = 'deleteGroupType'
}

const confirmationDialogData = {
  [ConfirmType.DeleteGLCode]: (codeName: string) => ({
    submitAction: { label: 'Ok', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: '', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'GL in Use',
    content: `The GL code ${codeName} you tried to delete is in use. Please switch the GL on the affected expenses and/or expense groups and try again.
        <br/><br/>
        You can identify expenses with GL ${codeName} by navigating to <br/> Manage > Expenses > All Expenses and then filtering on ${codeName}.<br/>
        You can identify expense groups with GL ${codeName} by navigating to Manage > Expense Groups > All Expense Groups and filtering on ${codeName}.
        Keep in mind that this GL code may be in use in other budgets in your account.`,
    width: '480px',
  }),
  [ConfirmType.DeleteExpenseType]: (expenseTypeName: string) => ({
    submitAction: { label: 'Ok', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: '', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Expense Type in Use',
    content: `The Expense Type ${expenseTypeName} you tried to delete is in use. Please switch the expense type on the affected expenses and try again.
        <br/><br/>
        You can identify expenses with type ${expenseTypeName} by navigating to Manage > Expenses > All Expenses and then filtering
        on ${expenseTypeName}. Keep in mind that this expense type may be in use in other budgets in your account.`,
    width: '480px',
  }),
  [ConfirmType.DeleteGroupType]: (typeName: string) => ({
    submitAction: { label: 'Ok', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: '', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    title: 'Campaign/Expense Group Type in Use',
    content: `The campaign/expense group ${typeName} you tried to delete is in use. Please switch the campaign/expense group type on the affected campaign/expense group type and try again.
        <br/><br/>
        You can identify campaign/expense group type ${typeName} by navigating to Manage > Campaigns > All Campaigns and then filtering
        on ${typeName}. Keep in mind that this campaign/expense group type may be in use in other budgets in your account.`,
    width: '480px',
  }),
};

export const getConfirmationDialogData = (actionType: ConfirmType, param?: any) => {
  return confirmationDialogData[actionType](param);
};
