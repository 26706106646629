import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProfileDO } from '../../types/user-profile.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { UserDO } from '@shared/types/user-do.interface';
import { ProfileUserRole } from '../../../user/types/profile-user-role.enum';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    user: 'user',
    userDraft: 'user_draft',
    userProfile: 'user_profile',
    profilePic: 'avatar'
  };

  getUser(userId: number): Observable<UserDO> {
    return this.http.get<UserDO>(`${this.apiV2Url}${this.apiPaths.user}/${userId}/`).pipe(
      map(
        res => ({
          ...res,
          is_superadmin: res?.user_profile_detail?.role !== ProfileUserRole.Regular,
          is_godmode: res?.user_profile_detail?.role === ProfileUserRole.GodMode
        })
      )
    );
  }

  getUsersProfiles(): Observable<UserProfileDO[]> {
    return this.http.get<UserProfileDO[]>(`${this.apiV2Url}${this.apiPaths.userProfile}/`);
  }

  getUserProfileById(userProfileId: number): Observable<UserProfileDO> {
    return this.http.get<UserProfileDO>(`${this.apiV2Url}${this.apiPaths.userProfile}/${userProfileId}/`);
  }

  getUserProfilePictureUrl(userId: number): Observable<string> {
    const requestUrl = `${this.apiV2Url}${this.apiPaths.user}/${userId}/${this.apiPaths.profilePic}/`;
    return this.http.get<{success: boolean; user_image_url: string}>(requestUrl).pipe(
      map(response => response.success ? response.user_image_url : null)
    );
  }

  addProfilePictures(
    sourceItems: any[],
    imagePropName = 'file',
    userIdPropName = 'user_id',
    mapper?: (url: string) => string
  ): any[] {
    const items = sourceItems || [];
    items
      .filter(item => item[imagePropName] != null)
      .forEach(item => {
        const getImageUrl$ = this.getUserProfilePictureUrl(item[userIdPropName]);
        item.profilePicUrl = mapper ? getImageUrl$.pipe(map(mapper)) : getImageUrl$;
      });
    return items;
  }

  addDraftUser(): Observable<any> {
    return this.http.post(`${this.apiV2Url}${this.apiPaths.userDraft}/`, null);
  }
}
