<div
  class="calendar-event"
  #eventRef
  [class.is-closed]="eventObject?.mode === 'Closed'"
  [class.overflow-start]="eventStartOverflow"
  [class.overflow-end]="eventEndOverflow"
  (click)="openCampaign()"
>
  <div class="header-area" [ngStyle]="headerAreaStyles">
    <header class="event-header" [ngStyle]="eventHeaderStyles" #eventHeaderRef>
      <div class="event-date" #eventDateRef>
        <span *ngIf="isNew" class="new-label">new</span>
        <span *ngIf="eventObject?.startDate">{{ eventObject?.startDate | ordinalDate: datePipeFormat }}</span>
        <span *ngIf="!eventObject?.startDate">Undefined Date</span>
      </div>
      <div class="event-title">{{ eventObject?.name }}</div>
    </header>
  </div>
  <div class="event-area" [ngStyle]="eventStyles">
    <div class="event-bar"></div>
  </div>
</div>
