import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'plain-breakdown-value',
  templateUrl: './plain-breakdown-value.component.html',
  styleUrls: ['./plain-breakdown-value.component.scss']
})
export class PlainBreakdownValueComponent {
  @Input() segmentValue: number;
  @Input() allocatedValue: number;
  @Input() unallocatedValue: number;
  @Input() withNullishPlaceholder = false;
  @Input() isBold = true;
  @Input() allocatedOnly = false;
  @Input() isAllocatedClickable = false;

  @Output() onAllocatedClick = new EventEmitter<void>();
}
