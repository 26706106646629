import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Validations } from 'app/app.validations';
import { withAPIV0ErrorHandling, getRequestOptions, handleBulkRequestError } from 'app/shared/utils/http-request.utils';
import { PlanProgramDO, ProgramDO, ProgramTypeDO } from 'app/shared/types/program.interface';
import { ProgramAllocation } from 'app/shared/types/budget-object-allocation.interface';
import { BudgetObjectCloneResponse } from 'app/shared/types/budget-object-clone-response.interface';
import { BulkOperationResponse } from 'app/shared/types/bulk-operation-response.interface';
import { DeepPartial } from 'app/shared/types/deep-partial.type';
import { API_V0_URL, API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';
import { AssumedAmountsDO, ObjectAmountsByTimeframes } from '@shared/types/object-amounts.interface';
import { ObjectStatusPayload } from '@manage-ceg/types/manage-ceg-page.types';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly actionUrl = inject(API_V0_URL);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly validation = inject(Validations);

  public apiPaths = {
    program: 'program/',
    programPlan: 'program/plan/',
    programType: 'program_type/',
    programAllocation: 'program_allocation/',
    split: 'split/',
    moveToBudget: 'move_to_other_budget/',
    multiDelete: 'multi_delete/',
    multiUpdate: 'multi_update/',
    moveProgramToCampaign: '/move_program_to_campaign/',
    poNumbers: 'po_numbers/',
    amountsByTimeframes: 'amounts_by_timeframes/',
    programAmountsByTimeframes: 'assumed_program_amounts_with_currency/',
    updateAllocationByTimeframe: 'update_allocation_by_timeframe/',
    updateAmountStatusForPrograms: 'update_amount_status_for_programs/',
    multiCreateFromExpenses: 'multi_create_from_expenses/',
    programCustomFieldFilters: 'program/program_filter/',
  };

  getProgramTypes(companyId: number): Observable<ProgramTypeDO[]> {
    return this.http.get<ProgramTypeDO[]>(
      this.apiV2Url + this.apiPaths.programType,
      getRequestOptions({ company: companyId })
    );
  }

  createProgramType(programType: Partial<ProgramTypeDO>) {
    return this.http.post<ProgramTypeDO>(
      `${this.apiV2Url}${this.apiPaths.programType}`,
      JSON.stringify(programType)
    );
  }

  getPrograms(data: object): Observable<ProgramDO[]> {

    // API endpoint if Custom Field Expense Filters are applied
    let params = data;
      

      let customFiledFiltersPayload = { ...params };

      if( params['custom_fields'] && !Object.keys(params['custom_fields']).length) {
        delete customFiledFiltersPayload['custom_fields']
      }

      let programCustomFieldFilterPayload = { ...customFiledFiltersPayload };

      if('short' in params) {
        programCustomFieldFilterPayload['short'] = String(params['short']);
      }

      if('include_nested' in params) {
        programCustomFieldFilterPayload['include_nested'] = String(params['include_nested']);
      }

      if('include_pseudo_objects' in params) {
        programCustomFieldFilterPayload['include_pseudo_objects'] = String(params['include_pseudo_objects']);
      }
          
      return this.http.post<ProgramDO[]>(
        this.apiV2Url + this.apiPaths.programCustomFieldFilters,
        programCustomFieldFilterPayload
      ).pipe(
        map((response) => {
          return response;
        })
      );

  }

  getPlanPrograms(data: object): Observable<PlanProgramDO[]> {
    return this.http.get<PlanProgramDO[]>(`${this.apiV2Url}${this.apiPaths.programPlan}`, getRequestOptions(data));
  }

  getProgram(programId: number): Observable<ProgramDO> {
    return this.http.get<ProgramDO>(`${this.apiV2Url}${this.apiPaths.program}${programId}/`);
  }

  createProgram(program: DeepPartial<ProgramDO>, customFieldsPayload?: Record<number,string[]>): Observable<ProgramDO> {
    let programCreationPayload = { ...program };
    if(customFieldsPayload && Object.keys(customFieldsPayload).length) {
      programCreationPayload = { ...programCreationPayload, custom_fields: customFieldsPayload };
    }
    return this.http.post<ProgramDO>(
      this.apiV2Url + this.apiPaths.program,
      programCreationPayload
    );
  }

  deleteProgram(programId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiV2Url}${this.apiPaths.program}${programId}/`);
  }

  cloneProgram(programId: number): Observable<BudgetObjectCloneResponse> {
    return this.http.post<BudgetObjectCloneResponse>(`${this.apiV2Url}${this.apiPaths.program}${programId}/clone/`, {});
  }

  updateProgram(programId, data): Observable<ProgramDO> {
    return this.http.patch<ProgramDO>(
      this.apiV2Url + this.apiPaths.program + `${programId}/`,
      JSON.stringify(data)
    );
  }

  // It needed just to get calculated status_total
  // witch is possible only by GET request
  updateProgramAndFetchResult(programId: number, data: Partial<ProgramDO>,  customFieldsPayload?: Record<number,string[]>): Observable<ProgramDO> {
    const detailsDiff = Object.keys(data).length;
    let updateRequest$: Observable<any> = detailsDiff ? this.updateProgram(programId, data) : of(true);

    // Adding custom fields payload to the request if there are any CF changes
    if(customFieldsPayload && Object.keys(customFieldsPayload).length) {
        console.log("Saving CF For Program ==> ", customFieldsPayload)
        updateRequest$ = this.updateProgram(programId, { ...data, custom_fields: customFieldsPayload });
      }

    return updateRequest$.pipe(
      switchMap(() => this.getProgram(programId))
    );
  }

  addProgramAllocation(data: Partial<ProgramAllocation>): Observable<ProgramAllocation> {
    return this.http.post<ProgramAllocation>(
      `${this.apiV2Url}${this.apiPaths.programAllocation}`,
      JSON.stringify(data)
    );
  }

  updateProgramAllocation(allocId: number, data: Partial<ProgramAllocation>): Observable<ProgramAllocation> {
    return this.http.patch<ProgramAllocation>(
      `${this.apiV2Url}${this.apiPaths.programAllocation}${allocId}/`,
      JSON.stringify(data)
    );
  }

  validateUniqueName(companyId: number, budgetId: number, name: string): Observable<ApiV0Response<void>> {
    const requestData = { name, company_id: companyId, budget_id: budgetId };
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<void>>(this.actionUrl + 'programs/unique/', getRequestOptions(requestData))
    );
  }

  split(programId: number): Observable<void> {
    return this.http.post<void>(`${this.apiV2Url}${this.apiPaths.program}${programId}/${this.apiPaths.split}`, null);
  }

  logProgramView(programId: number): Observable<void> {
    return this.http.post<any>(`${this.apiV2Url}${this.apiPaths.program}${programId}/log_view/`, {});
  }

  moveToBudget(programId: number, budgetId: number, companyId: number): Observable<void> {
    return this.http.patch<void>(
      `${this.apiV2Url}${this.apiPaths.program}${programId}/${this.apiPaths.moveToBudget}`,
      {
        budget: budgetId,
        company: companyId
      }
    );
  }

  deleteMultiPrograms(programIds: number[]): Observable<BulkOperationResponse<number>> {
    const { ValiditionMessages: MESSAGES } = this.validation;
    return this.http.delete<BulkOperationResponse<number>>(
      `${this.apiV2Url}${this.apiPaths.program}${this.apiPaths.multiDelete}`,
      {
        body: { ids: programIds }
      }
    ).pipe(
      catchError(err => handleBulkRequestError(err, programIds, MESSAGES.SOMETHING_WENT_WRONG))
    );
  }

  updateMultiPrograms(data: Partial<ProgramDO>[]): Observable<BulkOperationResponse<Partial<ProgramDO>>> {
    const { ValiditionMessages: MESSAGES } = this.validation;
    return this.http.patch<BulkOperationResponse<Partial<ProgramDO>>>(
      `${this.apiV2Url}${this.apiPaths.program}${this.apiPaths.multiUpdate}`,
      data
    ).pipe(
      catchError(err => handleBulkRequestError(err, data.map(item => item.id), MESSAGES.SOMETHING_WENT_WRONG))
    );
  }

  moveProgramToCampaign(campaignId: number, programId: number): Observable<any> {
    const url = `${this.apiV2Url}${this.apiPaths.program}${programId}${this.apiPaths.moveProgramToCampaign}`;
    return this.http.patch( url, { campaign: campaignId });
  }

  getPoNumbers(budgetId: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.apiV2Url}${this.apiPaths.program}${this.apiPaths.poNumbers}`,
      getRequestOptions({ budget: budgetId })
    );
  }

  getAmountsByTimeframes(budgetId: number, companyId: number, ids: number[], params?: object): Observable<ObjectAmountsByTimeframes> {
    
     // Combining Normal and Custom Field Filters with POST request
    
    let payload = { ...params, budget: budgetId, ids: ids.join(','),  company: companyId };
    
    if(payload['custom_fields'] && !Object.keys(payload['custom_fields']).length) {
      delete payload['custom_fields']
    }

    return this.http.post<ObjectAmountsByTimeframes>(
      `${this.apiV2Url}${this.apiPaths.program}${this.apiPaths.amountsByTimeframes}`,
      payload
    )

  }

  getProgramAmountsByTimeframes(budgetId: number, programId: number, currencyCode: string): Observable<Record<string, AssumedAmountsDO>> {
    return this.http.get<Record<string, AssumedAmountsDO>>(
      `${this.apiV2Url}${this.apiPaths.program}${programId}/${this.apiPaths.programAmountsByTimeframes}`,
      getRequestOptions({ currency_code: currencyCode, budget: budgetId })
    );
  }

  updateAmountByTimeframe(programId: number, data: Partial<ProgramAllocation>): Observable<ProgramAllocation> {
    return this.http.patch<ProgramAllocation>(
      `${this.apiV2Url}${this.apiPaths.program}${programId}/${this.apiPaths.updateAllocationByTimeframe}`,
      JSON.stringify(data)
    );
  }

  updateAmountStatusForPrograms(payload: ObjectStatusPayload): Observable<BulkOperationResponse<number>> {
    const { ValiditionMessages: MESSAGES } = this.validation;

    return this.http.patch<BulkOperationResponse<number>>(
      `${this.apiV2Url}${this.apiPaths.program}${this.apiPaths.updateAmountStatusForPrograms}`,
      payload
    ).pipe(
      catchError(err => handleBulkRequestError(err, payload.ids.map(itemId => itemId), MESSAGES.SOMETHING_WENT_WRONG))
    );
  }

  multiCreateFromExpenses(expenseIds: number[]): Observable<ProgramDO[]> {
    return this.http.post<ProgramDO[]>(`
      ${this.apiV2Url}${this.apiPaths.program}${this.apiPaths.multiCreateFromExpenses}`,
      { ids: expenseIds }
    );
  }
}
