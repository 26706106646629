import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-tune',
  templateUrl: './icon-tune.component.html',
  styleUrls: ['./icon-tune.component.scss']
})
export class IconTuneComponent {
  @Input() size = 24;
  @Input() color = '';
}
