import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Budget } from 'app/shared/types/budget.interface';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';

@Component({
  selector: 'budget-settings-dates-range',
  templateUrl: './budget-settings-dates-range.component.html',
  styleUrls: ['./budget-settings-dates-range.component.scss']
})
export class BudgetSettingsDatesRangeComponent implements OnChanges {
  @Input() budget: Budget;

  public budgetDateFormat = 'd LLL yyyy';
  public startDate = null;
  public endDate = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.budget && this.budget) {
      this.startDate = parseDateString(this.budget.budget_from);
      this.endDate = parseDateString(this.budget.budget_to);
    }
  }
}
