import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject, Input } from '@angular/core';

@Component({
  selector: 'loader',
  styleUrls: ['./loader.component.scss'],
  templateUrl: './loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  private elementRef = inject(ElementRef);
  @Input() color = '#7C4FFF';

  @HostBinding('class.root-loader')
  @Input() isRootLoader = false;

  @HostBinding('class.is-inline')
  @Input() isInline = false;

  @HostBinding('class.is-visible')
  @Input() isVisible = false;

  @Input() set size(propValue: string) {
    if (typeof propValue === 'string') {
      this.elementRef.nativeElement.style.setProperty('--loader-size', propValue);
    }
  }
}
