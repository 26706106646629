import { Component, inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DrawerStackService, DrawerType } from '../../../services/drawer-stack.service';
import { routeAnimation } from '@shared/utils/route-animation.utils';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { DetailsDrawerBaseComponent } from '../details-drawer-base';

@Component({
  selector: 'drawer-stack',
  templateUrl: './drawer-stack.component.html',
  styleUrls: ['./drawer-stack.component.scss'],
  animations: [ routeAnimation ]
})
export class DrawerStackComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  protected readonly stackManager = inject(DrawerStackService);
  private readonly appRoutingService = inject(AppRoutingService);

  @ViewChildren('goal,campaign,program,expense,metric') drawers!: QueryList<DetailsDrawerBaseComponent<any>>;

  private readonly destroy$ = new Subject<void>();

  protected readonly drawerTypes = DrawerType;
  protected readonly drawerTypeValues = Object.values(DrawerType);

  public closedOutsideStack: boolean; // Auxiliary field for the drawer guard

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => this.setConfig(params.config));
  }

  getDrawerMargin(level: number): string {
    const currentConfig = this.stackManager.stackConfig;
    return ((Object.values(currentConfig).length - level) * 72) + 'px';
  }

  isDrawerActive(level: number): boolean {
    const currentConfig = this.stackManager.stackConfig;
    return Object.values(currentConfig).length === level;
  }

  getActiveDrawer(): DetailsDrawerBaseComponent<any> {
    return this.drawers.find(drawer => drawer.isActive);
  }

  closeStack(): void {
    this.closedOutsideStack = true;
    this.appRoutingService.closeStack();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setConfig(routeConfig: string): void {
    try {
      const config = this.stackManager.getConfigFromRouteParam(routeConfig);
      this.stackManager.setStackConfig(config);
    } catch {
      console.error('[Stack] Invalid config.');
    }
  }
}
