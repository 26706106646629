import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { TimeframeConfigDialog } from '@manage-ceg/types/manage-ceg-page.types';
import { TimeframeConfigDialogComponent } from '@manage-ceg/dialogs/timeframe-config-dialog/timeframe-config-dialog.component';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';

@Component({
  selector: 'timeframe-configuration',
  templateUrl: './timeframe-configuration.component.html',
  styleUrls: ['./timeframe-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeframeConfigurationComponent {
  @Input() selectedTimeframeMode: BudgetTimeframesType;
  @Input() budgetEndDate: string;
  @Input() budgetTimeframes: BudgetTimeframe[];
  @Input() selectedTimeframes: (number | string)[];

  @Output() onTimeframeConfigChange = new EventEmitter<(number | string)[]>();

  @ViewChild('configBtn', { read: ElementRef, static: true }) configBtn: ElementRef;

  readonly budgetTimeframesType = BudgetTimeframesType;
  private readonly dialog = inject(MatDialog);

  showConfigDialog(): void {
    const dialogData: TimeframeConfigDialog = {
      timeframeMode: this.selectedTimeframeMode,
      fiscalYearLabel: `FY'${new Date(this.budgetEndDate).getUTCFullYear().toString().substring(2)}`,
      budgetTimeframes: this.budgetTimeframes,
      selectedTimeframes: this.selectedTimeframes,
      onSubmit: selectedTimeframes => this.onTimeframeConfigChange.emit(selectedTimeframes)
    };
    const { bottom, left, width } = this.configBtn.nativeElement.getBoundingClientRect();
    const isMonthMode = this.selectedTimeframeMode === BudgetTimeframesType.Month;
    const dialogWidth = isMonthMode ? 326 : 368;
    const offset = (left + (width / 2)) - dialogWidth / 2 - (isMonthMode ? 5 : 25);

    this.dialog.open(TimeframeConfigDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'ceg-timeframe-config-dialog',
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      data: dialogData,
      width: `${dialogWidth}px`,
      position: {
        top: `${bottom}px`,
        left: `${offset}px`
      }
    });
  }
}
