import { Injectable } from '@angular/core';
import { MetricUpdateAction } from 'app/budget-object-details/types/metric-update-action.enum';
import { Subject } from 'rxjs';

export interface UpdateMetricData {
  action: MetricUpdateAction;
  keyMetricId?: number;
  objectId: number;
  objectType: string;
  metricMappingId: number;
  isKeyMetricUpdated?: boolean;
  metricId: number;
  productId?: number;
}

@Injectable()
export class MetricUpdateService {
  private readonly _metricUpdated$ = new Subject<UpdateMetricData>();
  public readonly metricUpdated$ = this._metricUpdated$.asObservable();

  public triggerMetricUpdate(updateMetricData: UpdateMetricData): void {
    this._metricUpdated$.next(updateMetricData);
  }
}
