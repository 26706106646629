<article class="program-details details-page-wrapper" [class.is-dragging]="gesturesManager.isDragging">
  <details-header
    [actions]="menuActions"
    [budgetList]="budgets"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedChangesFlag"
    (budgetToMoveSelected)="handleBudgetToMoveSelected($event)"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAction.bind(this))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
    [objectDisplayType]="displayObjectType"
    [objectHierarchy]="hierarchy"
  ></details-header>
  <div class="details-content">
    <div class="details-page-title">
      <page-title
        [nameCtrl]="programFormData.get('name')"
        [maxLength]="budgetObjectDetailsManager.maxObjectNameLength"
        [objectType]="objectType?.toLowerCase()"
      ></page-title>
    </div>
    <div class="details-page-content" [formGroup]="programFormData">
      <section class="left-side">
        <div class="details-sections justify-content type-owner-row">
          <div class="details-form-field expense-bucket-type-select right-offset-auto">
            <fa-duotone-icon [icon]="['fad', 'briefcase']"></fa-duotone-icon>
            <mat-form-field *ngIf="!isCustomTypeEntering" appearance="fill" class="auto-width">
              <mat-label>Expense Group Type</mat-label>
              <mat-select disableOptionCentering formControlName="typeId" panelClass="mat-select-fixed-position" [placeholder]="'Expense Group Type *'" required (closed)="handleTypeChange()">
                <mat-option *ngFor="let item of programTypes" [value]="item.id">
                  {{item.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="programFormData.controls['typeId'].invalid">
                Expense Group type is required
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="isCustomTypeEntering" appearance="fill" class="no-validate">
              <mat-label>Expense Group Type</mat-label>
              <input matInput [autoFocus]="true" formControlName="customType" (blur)="handleCustomTypeChange()"/>
            </mat-form-field>
          </div>
          <div class="details-form-field owner-select">
            <fa-duotone-icon [icon]="['fad', 'user-crown']"></fa-duotone-icon>
              <mat-form-field appearance="fill" class="auto-width">
                <mat-label>Owner</mat-label>
                <mat-select
                  disableOptionCentering
                  formControlName="ownerId"
                  panelClass="mat-select-fixed-position"
                  [placeholder]="'Owner *'"
                  required
                  (selectionChange)="handleOwnerChange($event)"
                >
                  <mat-option *ngFor="let item of ownerOptions" [value]="item.id">
                    {{item.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="programFormData.controls['ownerId'].hasError('required')">
                  Owner is required
                </mat-error>
              </mat-form-field>
          </div>
        </div>
        <div class="details-sections segment-and-parent">
          <div class="details-form-field segment-select">
            <fa-duotone-icon [icon]="['fad', 'piggy-bank']"></fa-duotone-icon>
            <hierarchy-select
              [items]="allowedSegmentSelectItems"
              [selectControlExternal]="segmentControl"
              [config]="selectSegmentsConfig"
              [placeholder]="'Segment *'"
              [groupSelectionAllowed]="false"
              (onChange)="handleSegmentChanged($event)"
            ></hierarchy-select>
          </div>
          <div class="details-form-field location-select">
            <fa-duotone-icon [icon]="['fad', 'sitemap']"></fa-duotone-icon>
            <parent-hierarchy-select
              [items]="locationItems"
              [selectedValue]="programFormData.get('location')?.value"
              [config]="{ selectPanelClass: 'details-parent-select' }"
              (onChange)="handleParentSelectionChange($event)"
            >
            </parent-hierarchy-select>
          </div>
        </div>
        <div class="details-sections gl-code">
          <div class="details-form-field gl-code-select">
            <fa-duotone-icon [icon]="['fad', 'barcode-alt']"></fa-duotone-icon>
            <mat-form-field appearance="fill" class="no-validate auto-width">
              <mat-label>GL Code</mat-label>
              <mat-select [placeholder]="'GL Code'" (valueChange)="handleGLCodeChanged($event)" disableOptionCentering formControlName="glCode" panelClass="mat-select-fixed-position">
                <mat-select-trigger> {{ glCodeActiveName }}</mat-select-trigger>
                <mat-option [value]="null">No GL code</mat-option>
                <mat-option *ngFor="let item of glCodes" [value]="item.id">
                  {{ item.name + ' - ' + item.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="details-sections po-number">
          <div class="details-form-field po-field w-100">
            <fa-duotone-icon [icon]="['fad', 'file-alt']"></fa-duotone-icon>
            <mat-form-field appearance="fill" class="textarea w-100 autosize minimized">
              <mat-label>PO #</mat-label>
              <textarea matInput formControlName="poNumber" rows="1" (change)="handlePONumberChanged($event.target.value)" blurOnEnterKeydown textFieldAutosize [minHeight]="24"></textarea>
              <mat-error *ngIf="programFormData.controls['poNumber'].hasError('maxlength')">
                Po # should be less 500 symbols
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="mb-2" *ngIf="currentState?.externalId">
          <unique-id [uniqueId]="currentState.externalId" [objectType]="objectType"></unique-id>
        </div>
        <div class="details-sections budget-allocations">
          <budget-allocations-table
            #allocationsTable
            [budgetTimeframes]="budgetTimeframes"
            [objectAllocations]="currentState?.allocations"
            [expensesByTimeframes]="currentState?.statusTotals?.expenses_by_timeframes || []"
            [suppressedMode]="budget?.suppress_timeframe_allocations"
            [totalAmount]="currentState?.amount"
            [currency]="companyCurrency?.symbol"
            (onChange)="handleAllocationsUpdate($event)"
            (onTotalAllocatedChange)="handleTotalAllocatedUpdate($event)"
          ></budget-allocations-table>
        </div>
        <div class="details-sections">
          <div class="details-form-field">
            <fa-duotone-icon [icon]="['fad', 'tags']"></fa-duotone-icon>
            <tags-control
              #tagsControl
              [tagsList]="currentState?.tagMappings"
              [autocompleteItems]="tagsManager.autocompleteItems"
              [editOnly]="true"
              (onAddTag)="addTag($event)"
              (onCreateTag)="createTag($event)"
              (onRemoveTag)="removeTag($event)"
            >
            </tags-control>
          </div>
        </div>
        <div class="details-sections">
          <div class="details-form-field w-100 attachments">
            <fa-icon [icon]="['far', 'paperclip']"></fa-icon>
            <attachments-list
              [disableInput]="!currentState?.objectId"
              [objectTypeName]="displayObjectType"
              [isReadOnlyMode]="isReadOnlyMode"
              [attachments]="attachmentsManager.attachments"
              (onAttachFiles)="handleFileAttached($event)"
              (onDeleteAttachment)="handleFileDelete($event)"
              (onDownloadAttachment)="handleFileDownload($event)"
            ></attachments-list>
          </div>
        </div>
        <div class="details-sections">
          <div class="details-form-field w-100">
            <fa-duotone-icon [icon]="['fad', 'clipboard']"></fa-duotone-icon>
            <linkified-text formControlName="notes" label="Notes"></linkified-text>
          </div>
        </div>

      </section>
      <section class="right-side">
        <div class="details-sections">
          <details-status-bar
            [graphData]="currentState?.statusTotals"
            [objectDisplayType]="displayObjectType"
            [objectName]="currentState?.name"
            [objectId]="currentState?.objectId"
            [currency]="companyCurrency?.symbol"
            [isObjectClosed]="currentState?.mode === ObjectMode.Closed"
          ></details-status-bar>
        </div>
        <div class="details-sections">
          <details-expenses
            [withBreakdown]="true"
            [isAddNewExpenseAllowed]="!isReadOnlyMode"
            [expensesTotals]="detailsExpensesTotals"
            [data]="detailsExpensesData"
            (addExpense)="addNewExpense()"
            (viewExpenses)="viewExpenses()"
          ></details-expenses>
        </div>
        <div class="details-sections">
          <tasks-list
            [owners]="ownerOptions"
            [currentCompanyUser]="currentCompanyUser"
            [tasks]="currentState?.tasks"
            [budgetTodayDate]="budgetTodayDate"
            [isReadOnlyMode]="isReadOnlyMode"
            (onDataChanged)="handleTasksUpdate($event)"
          ></tasks-list>
        </div>
      </section>
    </div>
  </div>
</article>
