import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { GlCodeService } from '@shared/services/backend/gl-code.service';
import { BudgetObjectDialogService } from '@shared/services/budget-object-dialog.service';
import { GLCode } from '@shared/services/company-data.service';
import { UtilityService } from '@shared/services/utility.service';
import { DialogContext } from '@shared/types/dialog-context.interface';
import { Configuration } from 'app/app.constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as utils from 'app/shared/utils/notification-dialog.utils';
import { ConfirmType } from '../../shared/utils/notification-dialog.utils';
import { MatDialog } from '@angular/material/dialog';
import { CompanyDataService } from '../../shared/services/company-data.service';

interface GridColumnsType {
  name: string,
  label: string,
  sortable: boolean,
  is_updatable?: boolean,
  width: string
}

@Component({
  selector: 'tab-gl-codes',
  templateUrl: './tab-gl-codes.component.html',
  styleUrls: ['./tab-gl-codes.component.scss']
})
export class TabGlCodesComponent implements OnChanges {
  @Input() companyId: number;
  private readonly destroy$ = new Subject<void>();
  glCodesList: GLCode[];
  NoDataText = '';

  public gridColumns: GridColumnsType[] = [
    { name: 'name', label: 'GL Code', sortable: true, is_updatable: true, width: '25%' },
    { name: 'description', label: 'Description', sortable: false, is_updatable: true, width: '25%' },
    { name: 'is_enabled', label: 'Enabled', sortable: true, width: '20%' },
    { name: 'usage_count', label: 'Count', sortable: true, width: '20%' }
  ];

  enableSwitchTooltip = this.config.glCodesDescriptions.enableTooltip;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly dialogService: BudgetObjectDialogService,
    private config: Configuration,
    private readonly dialog: MatDialog,
    private companyDataService : CompanyDataService,
    private readonly glCodeService: GlCodeService) { 
      this.NoDataText = this.config.attributesAndTagsLabel.GL_CODE;
    }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId) {
      this.getGLCodes(this.companyId);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Gets all the glcodes
   * @param companyId
   */
  getGLCodes(companyId: number) {
    this.utilityService.showLoading(true);
    this.glCodeService.getGLCodesForAdmin({ company: companyId })
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        data => this.onGLCodesLoaded(data),
        error => this.utilityService.handleError(error)
      );
  }

  onGLCodesLoaded(data): void {
    this.glCodesList = data;
    const modifiedData = data.map(obj => {
      const { is_enabled, ...rest } = obj;
      return { ...rest, isEnabled: is_enabled };
    });
    this.companyDataService.glCodes.next(this.companyDataService.currentGlCodes = modifiedData);
    this.utilityService.showLoading(false);
  }

  /**
   * Adds new glcode items to the table
   * @param glCodeRowItem
   */
  addGlCode({ name, description }): void {
    if (name.trim() !== '' && description.trim() !== '') {
      const payload = { company: this.companyId, name, description }
      this.utilityService.showLoading(true);
      this.glCodeService.addGlCode(payload)
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(
          () => this.onSuccess(this.config.glCodesDescriptions.created),
          error => this.utilityService.handleError(error)
        );
    }
  }

  /**
   * Updates the existing glCode's name and description
   * @param glCodeRowItem 
   */
  updateGlCode({ id, description, name, is_enabled }): void {
    if (description.trim() === "" || name.trim() === "") {
      return;
    }
    this.utilityService.showLoading(true);
    const payload = { company: this.companyId, description, name, is_enabled }
    this.glCodeService.editGlCode(payload, id)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => this.onSuccess(this.config.glCodesDescriptions.updated),
        error => this.utilityService.handleError(error)
      );
  }

  /**
   * This method deletes gl code item
   * @param row
   */
  deleteGlCode(row): void {
    const deleteHandler = () => {
      this.utilityService.showLoading(true);
      this.glCodeService.deleteGlCode(row.id)
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(
          () => this.onSuccess(this.config.glCodesDescriptions.deleted),
        );
    };
    const dialogMessage = `You will permanently lose '${row.name}' wherever it has been used.`;
    this.dialogService.openDeleteEntityDialog(deleteHandler, null, { title: 'Delete GL Code?', message: dialogMessage });
  }

  showDeleteNotificationDialog(glcodeName: string) {
    const dialogData: DialogContext = utils.getConfirmationDialogData(ConfirmType.DeleteGLCode, glcodeName);
    this.dialog.open(ConfirmationDialogComponent, {
      width: dialogData.width,
      data: dialogData
    });
  }

  onSuccess(msg: string) {
    this.utilityService.showToast({ Title: '', Message: msg, Type: 'success' });
    this.utilityService.showLoading(false);
    this.getGLCodes(this.companyId);
  }

}
