<fa-icon class="icon" [icon]="icon"></fa-icon>
<div class="input-wrap"
     (mouseenter)="handleMouseEnter()"
     (mouseleave)="handleMouseLeave()">
  <input #inputRef
         [(ngModel)]="name"
         [disabled]="validating"
         [class.has-error]="!!errorMessage"
         [placeholder]="placeholder"
         (keyup.enter)="inputRef.blur()"
         (blur)="onBlur()"
         (focus)="onFocus()"
         floatingErrorMessage
         [errorMessage]="isHovered && errorMessage">
  <span class="counter">{{ name.length }}/{{ maxNameLength }}</span>
</div>
