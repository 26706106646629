<mat-menu #menu [class]="'mat-pl-menu navigation-menu ' + (panelClass || '')" [backdropClass]="backdropClass">
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="!item.hidden">
      <button *ngIf="!item.children && !item.hasCustomTemplate; else branch"
              mat-menu-item
              (click)="item.action && item.action(item)"
              [class.divider]="item.isDivider"
              [class.selected-option]="item.isSelected"
              [disabled]="item.disabled"
              [ngClass]="item.customCssClass">
        <ng-container
          *ngIf="item.faIcon"
          [ngTemplateOutlet]="awesomeIcon"
          [ngTemplateOutletContext]="{ $implicit: item.faIcon }"
        ></ng-container>
        <ng-container
          *ngIf="iconsTemplateRef"
          [ngTemplateOutlet]="iconsTemplateRef"
          [ngTemplateOutletContext]="{ $implicit: item }"
        >
        </ng-container>
        <span>{{ item.label }}</span><span class="badge" *ngIf="item?.asyncBadge | async as badge">{{ badge }}</span>
        <loader *ngIf="item.showLoading" [isVisible]="true" [isInline]="true" size="16px" class="ml-05"></loader>
        <fa-icon *ngIf="item.isSelected && item.selectIcon" [icon]="item.selectIcon"></fa-icon>
      </button>

      <ng-template #branch>
        <button mat-menu-item
                [matMenuTriggerFor]="item.hasCustomTemplate ? customMenuRef.menu : innerPanel.menu"
                [ngClass]="item.customCssClass"
                [disabled]="item.disabled">
          <ng-container
            *ngIf="item.faIcon"
            [ngTemplateOutlet]="awesomeIcon"
            [ngTemplateOutletContext]="{ $implicit: item.faIcon }"
          ></ng-container>
          <ng-container
            *ngIf="iconsTemplateRef"
            [ngTemplateOutlet]="iconsTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-container>
          <span>{{item.label}}</span><span class="badge" *ngIf="item?.asyncBadge | async as badge">{{ badge }}</span>
          <fa-icon class="chevron-icon" [icon]="['far', 'chevron-right']"></fa-icon>
        </button>
        <menu-panel #innerPanel [items]="item.children" [panelClass]="item.panelClass">
          <ng-template #iconsTemplate let-item>
            <ng-container
              *ngIf="iconsTemplateRef"
              [ngTemplateOutlet]="iconsTemplateRef"
              [ngTemplateOutletContext]="{ $implicit: item }"
            >
            </ng-container>
          </ng-template>
        </menu-panel>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-menu>

<ng-template #awesomeIcon let-faIcon>
  <ng-container *ngIf="faIcon[0] === 'fad'">
    <fa-duotone-icon class="primary-icon" [icon]="faIcon"></fa-duotone-icon>
  </ng-container>
  <ng-container *ngIf="faIcon[0] !== 'fad'">
    <fa-icon class="primary-icon" [icon]="faIcon"></fa-icon>
  </ng-container>
</ng-template>
