import { Program } from '@shared/types/program.interface';
import { ManageTableRow, ManageTableRowValues } from '../../components/manage-table/manage-table.types';
import { PlanObjectDataApplier } from './plan-object-data-applier';

export class ExpenseGroupDataApplier extends PlanObjectDataApplier {
  private readonly _program: Program;

  constructor(program: Program, rowValues: ManageTableRowValues) {
    super(rowValues);
    this._program = program;
  }

  get sourceObject(): Program {
    return this._program;
  }

  applyObjectData(row: ManageTableRow): void {
    super.applyObjectData(row);
    row.objectTypeId = this._program.programTypeId;
  }
}
