<svg viewBox="0 0 567 188" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_3477_2004)">
    <path d="M270.25 185.591V151.504H276.288L287.734 174.145L299.18 151.504H305.218V185.591H300.061V160.434L289.746 180.685H285.721L275.281 160.434V185.591H270.25Z" fill="white"/>
    <path d="M313.393 185.591L325.846 151.504H331.506L343.959 185.591H338.55L335.657 177.289H321.821L318.928 185.591H313.393ZM323.204 173.264H334.148L328.739 157.793L323.204 173.264Z" fill="white"/>
    <path d="M352.135 185.592V151.505H363.833C366.132 151.447 368.413 151.921 370.5 152.889C372.133 153.69 373.491 154.96 374.399 156.536C375.272 158.112 375.707 159.893 375.657 161.693C375.698 163.742 375.129 165.756 374.022 167.479C372.782 169.261 370.957 170.552 368.865 171.127L376.034 185.592H370.122L363.582 171.882H357.544V185.592H352.135ZM357.292 167.983H363.582C364.517 168.073 365.461 167.963 366.35 167.659C367.24 167.355 368.054 166.865 368.739 166.222C369.794 164.998 370.375 163.435 370.375 161.819C370.375 160.203 369.794 158.641 368.739 157.417C367.732 156.285 365.972 155.782 363.582 155.782H357.418L357.292 167.983Z" fill="white"/>
    <path d="M385.091 185.591V151.504H390.248V166.095L403.581 151.504H409.87L397.292 165.088L410.121 185.591H403.832L393.518 169.113L389.996 172.887V185.591H385.091Z" fill="white"/>
    <path d="M418.171 185.591V151.504H439.68V155.655H423.203V166.22H438.171V170.245H423.203V181.188H439.68V185.339L418.171 185.591Z" fill="white"/>
    <path d="M457.291 185.591V155.78H447.228V151.629H472.385V155.78H462.448V185.591H457.291Z" fill="white"/>
    <path d="M480.686 185.591V151.504H485.843V185.591H480.686Z" fill="white"/>
    <path d="M496.158 185.591V151.504H501.315L518.296 177.163V151.504H523.453V185.591H518.296L501.315 159.931V185.591H496.158Z" fill="white"/>
    <path d="M548.481 186.221C545.532 186.261 542.626 185.524 540.053 184.082C537.652 182.605 535.73 180.464 534.519 177.919C533.193 175.039 532.506 171.907 532.506 168.737C532.506 165.566 533.193 162.434 534.519 159.554C535.821 156.958 537.826 154.779 540.305 153.265C543.013 151.695 546.106 150.911 549.235 151.001C552.677 150.859 556.074 151.829 558.921 153.768C561.52 155.642 563.316 158.427 563.952 161.567H558.166C557.702 159.827 556.632 158.31 555.147 157.29C553.368 156.218 551.312 155.693 549.235 155.781C547.67 155.714 546.108 155.982 544.654 156.568C543.201 157.153 541.889 158.043 540.808 159.177C538.795 161.441 537.789 164.712 537.789 168.737C537.789 172.762 538.795 175.906 540.808 178.17C541.813 179.299 543.058 180.189 544.45 180.776C545.843 181.364 547.348 181.634 548.858 181.567C550.25 181.658 551.646 181.447 552.949 180.947C554.252 180.448 555.431 179.672 556.405 178.674C558.173 176.485 559.226 173.807 559.424 171.001H550.242V166.976H564.581V185.466H559.927L559.55 180.434C558.392 182.302 556.754 183.824 554.807 184.842C552.86 185.86 550.675 186.336 548.481 186.221Z" fill="white"/>
    <path d="M230.3 59.2C221.4 59.2 213.3 62.8999 208 70.3999H207.8V60.8H197V144H208.8V114.2H209C213.1 120.9 221.6 124.8 231.1 124.8C235.285 124.819 239.432 124.005 243.299 122.406C247.167 120.807 250.678 118.455 253.628 115.486C256.578 112.517 258.908 108.992 260.483 105.114C262.057 101.237 262.845 97.0847 262.8 92.8998C262.8 73.5999 249 59.2 230.3 59.2ZM229.9 114.1C217.7 114.1 208.6 104.1 208.6 91.9998C208.6 80.3999 217.7 69.9999 229.4 69.9999C242 69.9999 251 80.8999 251 92.2998C251 104.1 242 114.1 229.9 114.1Z" fill="white"/>
    <path d="M284.3 40H272.5V123.2H284.3V40Z" fill="white"/>
    <path d="M350.999 69.6999H350.799C345.899 62.5999 338.199 59.2 329 59.2C310.5 59.2 296.1 73.0999 296.1 92.9998C296.1 111 311.1 124.7 329.4 124.7C338.299 124.7 346.299 120.9 350.699 113.5H350.899V123.2H362.699V60.8999H350.999V69.6999ZM329.299 114.1C317.5 114.1 307.9 103.9 307.9 92.1998C307.9 79.8999 317.5 69.8999 329.299 69.8999C342.299 69.8999 351.199 79.9999 351.199 92.1998C351.173 97.9999 348.857 103.555 344.756 107.656C340.655 111.758 335.1 114.073 329.299 114.1Z" fill="white"/>
    <path d="M407.399 59.2C400.099 59.2 393.699 61.5999 388.999 67.4999H388.799V60.8999H377.799V123.2H389.599V89.0998C389.599 77.3999 395.799 69.8999 406.099 69.8999C416.399 69.8999 421.699 76.0999 421.699 89.1998V123.2H433.499V85.8999C433.499 68.6999 422.399 59.2 407.399 59.2Z" fill="white"/>
    <path d="M451.199 58.0999V60.8999H445.599V71.5999H451.199V123.2H462.999V71.5999H473.999V60.8999H462.999V57.9999C462.999 53.4 466.299 50.7 473.999 50.7V40H471.399C459.699 40 451.199 46.2 451.199 58.0999Z" fill="white"/>
    <path d="M528.299 94.4998C528.299 106.5 522.699 114.1 511.899 114.1C501.099 114.1 496.299 107.8 496.299 94.7998V60.8999H484.499V95.5998C484.499 117.5 497.199 124.7 510.699 124.7C518.999 124.7 526.099 121.7 529.199 116.4H529.399V123.1H539.999V60.8999H528.199V94.4998H528.299Z" fill="white"/>
    <path d="M566.999 40H555.199V123.2H566.999V40Z" fill="white"/>
    <path d="M22.5 38.3019C33.4 38.3019 42.9 44.7189 47.3 54.0437C51.3 47.4261 58.6 42.9141 67 42.9141H67.1C78.9 42.9141 88.6 33.2885 88.6 21.4571C88.6 9.6256 79 0 67.2 0H67.1H67C37.9 0 13.3 18.9504 4.5 45.2203C9.2 40.8085 15.5 38.3019 22.5 38.3019Z" fill="white"/>
    <path d="M94.5 21.3567C94.5 32.2858 88.1 41.8111 78.8 46.2229C85.4 50.2335 89.9 57.553 89.9 65.9754C90 77.8069 99.6 87.3322 111.3 87.3322C123.1 87.3322 132.9 77.8069 133 65.9754C133 39.2042 116.7 14.0373 90.2 4.11088C89.3 3.81008 88.4 3.40902 87.5 3.10822C91.9 8.02128 94.5 14.4383 94.5 21.3567Z" fill="white"/>
    <path d="M111.4 93.3483C100.6 93.3483 91.2 87.0315 86.7 77.8069C82.7 84.5248 75.3 89.0368 66.9 89.0368C54.2 89.0368 43.9 78.7093 43.9 65.9755C43.9 65.8752 43.9 65.8752 43.9 65.7749C43.9 53.9435 34.3 44.3179 22.4 44.3179C10.7 44.3179 1.1 53.8432 1 65.5744C1 65.6747 1 65.7749 1 65.8752V65.9755V166.443C1 178.374 10.6 188 22.5 188C34.4 188 44 178.374 44 166.443V127.84C78.1 140.674 116.1 123.328 128.8 89.1371C129.2 88.1344 129.6 87.0315 129.9 85.9285C125.1 90.5408 118.5 93.3483 111.4 93.3483Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_3477_2004">
      <rect width="566.998" height="188" fill="white"/>
    </clipPath>
  </defs>
</svg>
