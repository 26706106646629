import { Component, Input } from '@angular/core';
import { SharedIconState } from 'app/shared/components/icons/icon-shared/shared.type';
import { SharedRuleTooltipData, SharedRuleTooltipSegment } from './shared-rule-tooltip.type';
import { SharedCostRule, SharedCostRuleSegment } from '../../types/shared-cost-rule.interface';

@Component({
  selector: 'shared-rule-tooltip',
  templateUrl: './shared-rule-tooltip.component.html',
  styleUrls: ['./shared-rule-tooltip.component.scss']
})
export class SharedRuleTooltipComponent {
  @Input() name: string;
  @Input() segments: SharedRuleTooltipSegment[];
  @Input() coords: any;
  @Input() className = 'arrow-left';

  sharedIconState = SharedIconState.Default;

  static prepareTooltipData(rule: SharedCostRule, segmentNameById: {[key: number]: string} = {}): SharedRuleTooltipData {
    const segmentsData = rule.segments.map((segment: SharedCostRuleSegment) => ({
      id: segment.id,
      cost: segment.cost,
      name: segmentNameById[segment.id] || ''
    }));

    return {
      id: rule.id,
      name: rule.name,
      segments: segmentsData
    };
  }
}
