import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-tags',
  templateUrl: './icon-tags.component.html',
  styleUrls: ['./icon-tags.component.scss']
})
export class IconTagsComponent {
  @Input() size = '15px';
  @Input() color = '#000';
  @Input() tagsCount = 0;
}
