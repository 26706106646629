<section class="scr-tips">
  <div class="tips-list">
    <div class="tips-item">
      <div class="tips-label">TIP #1:</div>
      <div class="tips-text">
        The sum of all budget allocations must equal exactly 100% before a rule can be activated
      </div>
    </div>
    <div class="tips-item">
      <div class="tips-label">TIP #2:</div>
      <div class="tips-text">
        Once a rule is activated, any read/write or admin user can apply the rule to any expense, expense group or campaign
      </div>
    </div>
    <div class="tips-item">
      <div class="tips-label">TIP #3:</div>
      <div class="tips-text">
        You can only “Retire” a rule after having removed all “Instances” where the rule is applied. <br>
        <a [href]="knowledgeLink" target="_blank">View here</a> to learn how to do this.
      </div>
    </div>
  </div>
</section>
