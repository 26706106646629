<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="22"
  viewBox="0 0 24 22"
  fill="none"
>
  <g clip-path="url(#clip0_1935_93330)">
    <path
      opacity="0.4"
      d="M5.33331 15.3722V5.02637C5.33214 4.93744 5.349 4.84919 5.38288 4.76695C5.41675 4.68472 5.46694 4.6102 5.53042 4.54789C5.59389 4.48559 5.66933 4.43679 5.75218 4.40445C5.83503 4.3721 5.92358 4.35688 6.01247 4.35971C7.81247 4.44304 9.9833 4.90971 12.4791 6.12637C18.3375 8.98054 21.8 8.74721 23.0166 8.50971C23.7666 8.35137 24.3208 9.26387 23.7916 9.85971C20.5375 13.5347 16.3 14.393 16.1208 14.4264C10.5041 15.5139 9.7333 14.7389 6.24164 15.9889C5.79998 16.1472 5.33331 15.8389 5.33331 15.3722Z"
      fill="#7084A1"
    />
    <path
      d="M1.43368e-05 2.66964C-0.00145045 2.25172 0.109353 1.84109 0.320845 1.48064C0.532337 1.12019 0.836756 0.823156 1.20229 0.620579C1.56783 0.418002 1.98106 0.317317 2.39881 0.329044C2.81656 0.340771 3.22349 0.464481 3.57709 0.687245C3.93068 0.910009 4.21796 1.22365 4.4089 1.5954C4.59983 1.96715 4.68743 2.38335 4.66252 2.80052C4.63761 3.21769 4.50112 3.62052 4.2673 3.96691C4.03349 4.31329 3.71094 4.59053 3.33335 4.76964V21.3363C3.33335 21.4247 3.29823 21.5095 3.23572 21.572C3.1732 21.6345 3.08842 21.6696 3.00001 21.6696H1.66668C1.57828 21.6696 1.49349 21.6345 1.43098 21.572C1.36847 21.5095 1.33335 21.4247 1.33335 21.3363V4.76964C0.935657 4.581 0.599433 4.28372 0.363503 3.91213C0.127573 3.54054 0.00155967 3.1098 1.43368e-05 2.66964Z"
      fill="#7084A1"
    />
  </g>
  <defs>
    <clipPath id="clip0_1935_93330">
      <rect
        width="24"
        height="21.3333"
        fill="white"
        transform="translate(0 0.335938)"
      />
    </clipPath>
  </defs>
</svg>
