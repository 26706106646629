import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Budget } from 'app/shared/types/budget.interface';
import { DetailsAction } from '../details-header.type';
import { ObjectHierarchy } from '../../object-hierarchy-nav/object-hierarchy-nav.type';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { HierarchyItem } from '../../object-hierarchy-menu/object-hierarchy-menu.component';

@Component({
  selector: 'drawer-header',
  templateUrl: './drawer-header.component.html',
  styleUrls: ['./drawer-header.component.scss']
})
export class DrawerHeaderComponent {
  @Input() budgetList: Budget[] = [];
  @Input() actions: DetailsAction[] = [];
  @Input() objectHierarchy: ObjectHierarchy;
  @Input() childMetricHierarchy:  HierarchyItem [];
  @Input() isReadOnlyMode = false;
  @Input() disableSaveAndCloseButton: boolean = false;
  @Input() hasUnsavedChanges = false;
  @Input() nameFormControl: UntypedFormControl;
  @Input() objectType: string;
  @Input() objectLabel: string;
  @Input() createdDate: string;
  @Input() updatedDate: string;
  @Input() isInvoiceDrawer = false;
  @Input() maxObjectNameLength: number;
  @Input() headerBackgroundClass: string;
  @Input() isCEGBudget = false;
  @Input() childHierarchy: HierarchySelectItem[];
  @Input() detailsFormGroupData: UntypedFormGroup;
  @Input() parentId: number;
  @Input() parentName: string;
  @Input() isCampaignMetricDrawer: boolean;

  @Output() budgetToMoveSelected = new EventEmitter<Budget>();
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() saveAndNew = new EventEmitter<void>();
  @Output() saveAndClose = new EventEmitter<void>();
  @Output() onChildHierarchyItemClick = new EventEmitter<{objectId: number, objectType: string}>();
}
