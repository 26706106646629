import { BudgetSegmentAccess } from '../types/segment.interface';
import { SegmentGroup } from '../types/segment-group.interface';
import { SharedCostRule } from '../types/shared-cost-rule.interface';
import { Campaign, LightCampaign } from '../types/campaign.interface';
import { HierarchySelectItem } from '../components/hierarchy-select/hierarchy-select.types';
import { setChildLevel } from '../components/hierarchy-select/hierarchy-select.utils';
import { Injectable } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { sortByField } from '@shared/utils/common.utils';

enum TREE_KEYS {
  SCR = 'scr',
  SEGMENTS = 'segments',
  SEGMENTLESS = 'segmentless',
}

@Injectable({
  providedIn: 'root'
})
export class SegmentMenuHierarchyService {
  private OBJECT_TYPES = this.configuration.OBJECT_TYPES;

  private static createSingleItem(itemRaw, objectType): HierarchySelectItem {
    return {
      id: objectType + '_' + itemRaw.id,
      objectId: itemRaw.id,
      title: itemRaw.name,
      objectType: objectType,
      segmentData: {
        budgetSegmentId: itemRaw.budgetSegmentId || null,
        sharedCostRuleId: itemRaw.splitRuleId || null,
      }
    };
  }

  private static fillCampaignsTree = (store, camp, parentKey) => {
    if (!store[parentKey]) {
      store[parentKey] = [];
    }
    store[parentKey].push(camp);
  }

  private static writeCampaignsToHierarchy = (list: HierarchySelectItem[], store) => {
    if (!Object.keys(store).length) {
      return;
    }
    list.forEach(item => {
      if (store[item.objectId]) {
        item.children = store[item.objectId];
      }
    })
  }

  constructor(
    private readonly configuration: Configuration,
  ) {
  }

  private createSegmentGroup(itemRaw: Partial<BudgetSegmentAccess>): HierarchySelectItem {
    return SegmentMenuHierarchyService.createSingleItem(itemRaw, this.OBJECT_TYPES.segmentsGroup);
  }

  private createSingleSegment(itemRaw: Partial<BudgetSegmentAccess>): HierarchySelectItem {
    return SegmentMenuHierarchyService.createSingleItem(itemRaw, this.OBJECT_TYPES.segment);
  }

  private createSingleCampaign(itemRaw: Partial<BudgetSegmentAccess>): HierarchySelectItem {
    return SegmentMenuHierarchyService.createSingleItem(itemRaw, this.OBJECT_TYPES.campaign);
  }

  private createSingleSharedRule(itemRaw: SharedCostRule): HierarchySelectItem {
    return SegmentMenuHierarchyService.createSingleItem(itemRaw, this.OBJECT_TYPES.sharedCostRule);
  }

  public prepareDataForSegmentMenu(
    params: {
      segments: BudgetSegmentAccess[],
      groups: SegmentGroup[],
      rules?: SharedCostRule[],
      campaigns?: Campaign[] | LightCampaign[],
    }
  ): Array<HierarchySelectItem> {
    const campaignsTree = {
      [TREE_KEYS.SCR]: {},
      [TREE_KEYS.SEGMENTS]: {},
      [TREE_KEYS.SEGMENTLESS]: [],
    };

    if (params.campaigns?.length) {
      params.campaigns.forEach(camp => {
        if (camp.splitRuleId) {
          SegmentMenuHierarchyService.fillCampaignsTree(campaignsTree[TREE_KEYS.SCR], camp, camp.splitRuleId)
        } else if (camp.budgetSegmentId) {
          SegmentMenuHierarchyService.fillCampaignsTree(campaignsTree[TREE_KEYS.SEGMENTS], camp, camp.budgetSegmentId)
        } else {
          campaignsTree[TREE_KEYS.SEGMENTLESS].push(this.createSingleCampaign(camp));
        }
      });
      this.processCampaignsStore(campaignsTree[TREE_KEYS.SEGMENTS]);
      this.processCampaignsStore(campaignsTree[TREE_KEYS.SCR]);
    }

    const singleSegmentsList = params.segments
      .filter(s => !s.segment_group)
      .map(segmentRaw => this.createSingleSegment(segmentRaw))
      .sort(sortByField('name'));
    SegmentMenuHierarchyService.writeCampaignsToHierarchy(singleSegmentsList, campaignsTree[TREE_KEYS.SEGMENTS]);

    let hierarchy: HierarchySelectItem[];
    let groups = [];
    if (params.groups.length) {
      groups = params.groups
        .map((grp: SegmentGroup) => this.createSegmentGroup(grp))
        .sort(sortByField('title'));

      const segmentsGroupedListRaw = params.segments.filter(s => s.segment_group);
      segmentsGroupedListRaw.forEach(segment => {
        const group = groups.find(g => +g.objectId === segment.segment_group);
        const segmentItem = this.createSingleSegment(segment);
        SegmentMenuHierarchyService.writeCampaignsToHierarchy([segmentItem], campaignsTree[TREE_KEYS.SEGMENTS])
        if (group) {
          if (!group.children) {
            group.children = [];
          }
          group.children.push(segmentItem);
        }
      });

      // Filter out groups that don't have children
      groups = groups.filter(group => !!group.children?.length);
      groups.sort(sortByField('name'));
      groups.forEach(group => group.children.sort(sortByField('title')));
    }

    let scr = [];
    if (params.rules) {
      scr = params.rules
        .map(item => this.createSingleSharedRule(item))
        .sort(sortByField('title'));
      SegmentMenuHierarchyService.writeCampaignsToHierarchy(scr, campaignsTree[TREE_KEYS.SCR]);
    }

    hierarchy = [...groups, ...singleSegmentsList, ...scr, ...campaignsTree[TREE_KEYS.SEGMENTLESS]];
    hierarchy.forEach(item => setChildLevel(item, 0))
    return hierarchy;
  }

  private processCampaignsStore(store: Record<string, Campaign[]>): void {
    Object.entries(store).forEach(([segmentId, campArray]) => {
      const localParentCampaigns = {};
      const singleCampaigns = [];
      campArray.forEach(camp => {
        const parentCampId = camp.parentCampaign;
        const localParent = campArray.find(p => p.id === parentCampId);
        const childItem = this.createSingleCampaign(camp);

        if (localParent) {
          if (!localParentCampaigns[parentCampId] ) {
            const localParentItem = this.createSingleCampaign(localParent);
            localParentItem.children = [childItem];
            localParentCampaigns[parentCampId] = localParentItem;
          } else {
            localParentCampaigns[parentCampId].children.push(childItem);
          }
        } else {
          singleCampaigns.push(childItem);
        }
      })
      store[segmentId] = [
        ...Object.values(localParentCampaigns),
        ...singleCampaigns.filter(c => !Object.keys(localParentCampaigns).includes(c.id))
      ]
    });
  }
}
