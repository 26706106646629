import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'expense-name-cell',
  templateUrl: './expense-name-cell.component.html',
  styleUrls: ['./expense-name-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpenseNameCellComponent {
  @Input() createdDate: Date;
  @Input() timeframeLocked: boolean;
  @Input() selectionDisabled: boolean;
  @Input() itemSelected: boolean;
  @Input() isReadOnlyMode: boolean;
  @Input() name: string;
  @Input() dragAllowed: boolean;
  @Output() select = new EventEmitter<boolean>();
  @Output() nameClick = new EventEmitter<void>();

  protected onCheckboxClick(e: MouseEvent): void {
    e.stopPropagation();
  }

  onSelect(e: MatCheckboxChange) {
    this.select.emit(e.checked);
  }

  onNameClick() {
    this.nameClick.emit();
  }
}
