import { Component, ElementRef, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { forkJoin, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { Metric } from 'app/budget-object-details/components/details-metrics/details-metrics.type';
import { CalendarEvent } from '../calendar-event/calendar-event.type';
import { EventTooltipData } from './event-tooltip.type';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { Campaign } from '@shared/types/campaign.interface';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { CompanyDataService } from '@shared/services/company-data.service';

@Component({
  selector: 'event-tooltip',
  templateUrl: './event-tooltip.component.html',
  styleUrls: ['./event-tooltip.component.scss']
})
export class EventTooltipComponent implements OnDestroy, OnInit, OnChanges {
  private readonly configuration = inject(Configuration);
  private readonly filterManager = inject(FilterManagementService);
  private readonly companyDataService = inject(CompanyDataService);

  @ViewChild('tooltipRef') tooltipRef: ElementRef;
  @Input() currencySymbol = '$';
  @Input() metricTypes: MetricType[] = [];
  @Input() objectType = this.configuration.OBJECT_TYPES.campaign;
  @Input() trigger$: Subject<EventTooltipData>;

  private destroy$ = new Subject<void>();
  private reset$ = new Subject<void>();
  private fadeInDelay = 100;
  protected readonly ObjectMode = ObjectMode;
  protected event: CalendarEvent;
  protected metricsList: Metric[] = [];
  protected fullCampaign: Campaign;
  protected campaignAmount: number;
  protected campaignType: string;
  protected numberFormat = '1.0-0';
  protected dateFormat = 'LLL, ORD yyyy';
  protected metricsDisplayThreshold = 3;

  protected tooltipContainerClass = 'arrow-down';
  protected tooltipStyles: any = {};
  protected tipStyles: any = {};
  protected isVisible = false;
  protected withCurrencySet = new Set<number>();

  ngOnInit(): void {
    this.trigger$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => this.handleTriggerEvent(e))
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metricTypes) {
      this.updateWithCurrencySet();
    }
  }

  private updateWithCurrencySet(): void {
    this.withCurrencySet.clear();
    this.metricTypes.forEach(mt => {
      if (mt.withCurrency) { this.withCurrencySet.add(mt.id); }
    });
  }

  private getTooltipRect(): DOMRect {
    return this.tooltipRef.nativeElement.getBoundingClientRect();
  }

  private defineVerticalPosition(eventRect: ClientRect | DOMRect, containerRect: ClientRect | DOMRect): void {
    const VERTICAL_OFFSET = 5;
    const tooltipRect = this.getTooltipRect();
    const distanceToTopBorder = eventRect.top - containerRect.top - VERTICAL_OFFSET;
    let top = eventRect.top - tooltipRect.height - VERTICAL_OFFSET;

    if (distanceToTopBorder < tooltipRect.height) {
      // Overflow from the top
      top = eventRect.bottom + 2 * VERTICAL_OFFSET;
      this.tooltipContainerClass = 'arrow-up';
    } else {
      this.tooltipContainerClass = 'arrow-down';
    }

    this.tooltipStyles.top = `${top}px`;
  }

  private defineHorizontalPosition(targetElement: Element, containerRect: ClientRect | DOMRect): void {
    const eventAreaEl = targetElement.getElementsByClassName('event-area');
    if (!eventAreaEl.length) {
      return;
    }

    const HORIZONTAL_OFFSET = 5;
    const MIN_OFFSET = containerRect.left + HORIZONTAL_OFFSET;
    const tooltipRect = this.getTooltipRect();
    const tooltipHalfWidth = tooltipRect.width / 2;
    const eventAreaRect = eventAreaEl[0].getBoundingClientRect();

    const distanceToLeftBorder = eventAreaRect.left - containerRect.left + HORIZONTAL_OFFSET;
    const distanceToRightBorder = containerRect.right - (eventAreaRect.left + HORIZONTAL_OFFSET);
    let tooltipLeft = eventAreaRect.left - tooltipHalfWidth;

    const MIN_TIP_OFFSET = 25;
    const MAX_TIP_OFFSET = tooltipRect.width - MIN_TIP_OFFSET;
    this.tipStyles = { left: '50%' };

    if (distanceToLeftBorder < tooltipHalfWidth) {
      // Overflow from the left
      this.tipStyles.left = `${MIN_TIP_OFFSET}px`;
      tooltipLeft = eventAreaRect.left;
    } else if (distanceToRightBorder < tooltipHalfWidth) {
      // Overflow from the right
      const tipOffset = Math.min(tooltipRect.width - distanceToRightBorder, MAX_TIP_OFFSET);
      this.tipStyles.left = `${tipOffset}px`;
      tooltipLeft = containerRect.right - tooltipRect.width - HORIZONTAL_OFFSET;
    }
    this.tooltipStyles.left = `${Math.max(tooltipLeft, MIN_OFFSET)}px`;
  }

  private updatePosition(targetElement: Element, containerRef: ElementRef): void {
    const eventRect = targetElement.getBoundingClientRect();
    const containerRect = containerRef.nativeElement.getBoundingClientRect();

    this.defineVerticalPosition(eventRect, containerRect);
    this.defineHorizontalPosition(targetElement, containerRect);
  }

  private handleTriggerEvent(data: EventTooltipData): void {
    const { show, event, targetElement, containerRef } = data;

    const updateAndDisplay = () => {
      this.updatePosition(targetElement, containerRef);
      this.isVisible = true;
    };

    if (show) {
      this.event = event;
      setTimeout(() => {
        if (this.event) {
          this.tooltipStyles.display = 'block';

          forkJoin([
            this.event.metrics$,
            this.event.fullCampaign$
          ]).pipe(
            takeUntil(
              merge(this.destroy$, this.reset$)
            )
          ).subscribe(([metrics, fullCampaign]) => {
            this.metricsList = metrics;
            this.fullCampaign = fullCampaign;
            this.campaignAmount = this.getCampaignAmount(fullCampaign);
            this.campaignType = this.getCampaignType(fullCampaign);
            setTimeout(updateAndDisplay, this.fadeInDelay);
          });
        }
      });
    } else {
      this.tooltipStyles.display = 'none';
      this.event = null;
      this.metricsList = [];
      this.fullCampaign = null;
      this.campaignAmount = null;
      this.campaignType = null;
      this.isVisible = false;
      this.reset$.next();
    }
  }

  private getCampaignAmount(campaign: Campaign): number {
    const filteredTimeframes = this.filterManager.currentFilterSetValue[FilterName.Timeframes];
    return filteredTimeframes == null || filteredTimeframes.length === 0 ?
      campaign.amount :
      campaign.timeframes
        .filter(tf => filteredTimeframes.includes(tf.company_budget_alloc))
        .reduce((sum, tf) => sum + tf.amount , 0);
  }

  private getCampaignType(campaign: Campaign): string {
    return this.companyDataService.campaignTypesSnapshot
      ?.find(type => type.id === campaign.campaignTypeId)
      ?.name;
  }
}
