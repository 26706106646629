import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.scss']
})
export class SummaryItemComponent {
  @Input() title: string;
  @Input() label: string;
  @Input() value: number;
  @Input() valueSecondary: number;
  @Input() icon: IconProp;
}
