export enum selectionTypes {
    Single = 'Single-select',
    Multi = 'Multi-select'
}

export interface appliedList {
    items: ("Campaign" | "Expense" | "ExpenseGroup")[];
} 

export interface appliedSelection {
    type: 'Single' | 'Multi';
}

export interface customFieldList {
    id?: number;
    company?: number;
    name: string;
    description: string;
    for_campaign : boolean;
    for_program : boolean;
    for_expense : boolean;
    is_mandatory : boolean;
    is_draft : boolean;
    type: appliedSelection['type'];
    strict_inheritance?: boolean;
    sort_order? : number;
}

export interface customFieldOptions {
    id?: number;
    cf_id?: number;
    option_value: string;
    is_default: boolean;
    sort_order?: number;
}

export interface customFieldDetails extends customFieldList{
    customfieldoptions: customFieldOptions[];
}