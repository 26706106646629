import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { UtilityService } from '@shared/services/utility.service';
import { takeUntil } from 'rxjs/operators';
import { CustomFilterModeType, FilterManagementService } from '../../header-navigation/components/filters/filter-services/filter-management.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { ExpensesService } from '@shared/services/backend/expenses.service';
import { ExpenseDO } from '@shared/types/expense.interface';
import { ExpenseTableDataService } from '@spending/services/expense-table-data.service';
import { SpendingMiniDashSummaryService } from '@spending/services/spending-mini-dash-summary.service';
import { Configuration } from '../../app.constants';
import { PatchExpenseData } from '@spending/types/expense-page.type';
import { ExpensePageSelectionService } from '@spending/services/expense-page-selection.service';
import { SpendingLocalFiltersService } from '@spending/services/spending-local-filters.service';
import { SpendingService } from '@spending/services/spending.service';
import { ExpensesQueryService } from '@spending/services/expenses-query.service';
import { FilterName } from '../../header-navigation/components/filters/filters.interface';
import { ExpenseTableColumnsService } from '@spending/services/expense-table-columns.service';
import { ExpenseActionsService } from '@spending/services/expense-actions.service';
import { SpendingPageBaseService } from '@spending/services/spending-page-base-service';

@Injectable()
export class ExpensePageService extends SpendingPageBaseService implements OnDestroy {

  constructor(
    public readonly utilityService: UtilityService,
    private readonly budgetDataService: BudgetDataService,
    public readonly expensesService: ExpensesService,
    public readonly spendingService: SpendingService,
    private readonly spendingLocalFiltersService: SpendingLocalFiltersService,
    private readonly expensePageSelectionService: ExpensePageSelectionService,
    private readonly expenseActionsService: ExpenseActionsService,
    private readonly filterManagementService: FilterManagementService,
    private readonly expenseTableDataService: ExpenseTableDataService,
    private readonly spendingMiniDashSummaryService: SpendingMiniDashSummaryService,
    private readonly configuration: Configuration,
    private readonly expensesQueryService: ExpensesQueryService,
    private readonly expenseTableColumnsService: ExpenseTableColumnsService,
    private cdr: ChangeDetectorRef,
  ) {
    super(
      budgetDataService,
      expensesService,
      spendingService,
      spendingLocalFiltersService,
      spendingMiniDashSummaryService,
      expensesQueryService,
      cdr,
      expenseTableDataService,
      expensePageSelectionService,
      utilityService
    );
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.expenseTableColumnsService.columnsUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.expenseTableDataService.onVisibleColumnsChanged();
        setTimeout(() => {
          this.cdr.markForCheck();
        });
      });
  }

  public activateRelatedExpensesMode(relationGroupId: string) {
    this.filterManagementService.customFilterMode = {
      modeType: CustomFilterModeType.RelatedExpenses,
      context: { relationGroupId }
    };
  }

  public patchExpense(data: PatchExpenseData): void {
    this.expensesService.updateExpense(data.id, data.body)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(updatedExpense => {
      this.onExpenseUpdated(updatedExpense);
    });
  }

  private onExpenseUpdated(updatedExpense: ExpenseDO): void {
    if (this.shouldBeRemovedFromView(updatedExpense)) {
      this.spendingService.refreshTableDataAndTotals();
      return;
    }
    this.updateMiniDashTotals();
    this.expenseTableDataService.updateRow(updatedExpense);
    setTimeout(() => {
      this.cdr.markForCheck();
    });
  }

  private shouldBeRemovedFromView(expense: ExpenseDO): boolean {
    const currentFilterSetValue = this.filterManagementService.currentFilterSetValue;
    const sidebarSelection = this.spendingService.sidebarSelectionQuery$.getValue();
    const allowedStatuses = currentFilterSetValue[FilterName.Statuses] ? [...currentFilterSetValue[FilterName.Statuses]] : [];
    const allowedTimeframes = currentFilterSetValue[FilterName.Timeframes] ? [...currentFilterSetValue[FilterName.Timeframes]] : [];
    if (sidebarSelection.modes) {
      allowedStatuses.push(sidebarSelection.modes);
    }
    if (sidebarSelection.company_budget_allocation_ids) {
      allowedTimeframes.push(+sidebarSelection.company_budget_allocation_ids);
    }
    return (allowedStatuses.length && !allowedStatuses.includes(expense.mode)) ||
      (allowedTimeframes.length && !allowedTimeframes.includes(expense.company_budget_alloc));
  }

  ngOnDestroy(): void {
    this.expenseTableDataService.createRows(null);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
