import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Validations } from 'app/app.validations';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UtilityService } from 'app/shared/services/utility.service';
import { GLCode, GlCodeService } from 'app/shared/services/backend/gl-code.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { AddGlCodeDialogContext, GlCodeDialogComponent } from '@shared/modals/gl_code/gl-code-dialog.component';
import { AppDataLoader } from 'app/app-data-loader.service';
import { Budget } from 'app/shared/types/budget.interface';
import { Configuration } from 'app/app.constants';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { getTakenNames } from 'app/shared/utils/common.utils';
import { MatDialog } from '@angular/material/dialog';
import { DialogContext } from 'app/shared/types/dialog-context.interface';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import * as utils from 'app/shared/utils/notification-dialog.utils';
import { ConfirmType } from 'app/shared/utils/notification-dialog.utils';

@Component({
  selector: 'gl-codes',
  templateUrl: './gl-codes.component.html',
  styleUrls: ['./gl-codes.component.scss'],
  providers: [AppDataLoader]
})
export class GlCodesComponent implements OnInit, OnDestroy {
  protected readonly validations = inject(Validations);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly utilityService = inject(UtilityService);
  private readonly glCodeService = inject(GlCodeService);
  private readonly appDataLoader = inject(AppDataLoader);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly configuration = inject(Configuration);
  private readonly dialog = inject(MatDialog);

  private readonly destroy$ = new Subject<void>();
  private selectedBudget: Budget = null;

  glSortBy = 'desc';
  glOrderBy = '';
  glCodeList: GLCode[];
  companyId: number;

  ngOnInit(): void {
    this.companyDataService.selectedCompany$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null),
        switchMap(cmp => this.utilityService.loadGlCodePropertyChanged$.pipe(map(() => cmp.id)))
      )
      .subscribe(companyId => this.getGlCodeList(companyId));

    this.companyDataService.selectedCompany$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null)
      )
      .subscribe(cmp => {
        this.getGlCodeList(this.companyId = cmp.id);
        this.companyDataService.loadCompanyData(this.companyId);
      });

    this.budgetDataService.selectedBudget$
      .pipe(
        tap(newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: (error) => this.utilityService.handleError(error)
      });

    this.appDataLoader.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    this.selectedBudget = newSelectedBudget;
    if (newSelectedBudget != null) {
      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.campaignStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.programStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }
  }

  getGlCodeList(companyId: number) {
    const dataObj = {};
    if (this.glSortBy === 'asc' && this.glOrderBy !== '') {
      dataObj['ordering'] = this.glOrderBy;
    } else if (this.glSortBy === 'desc' && this.glOrderBy !== '') {
      dataObj['ordering'] = '-' + this.glOrderBy;
    }
    dataObj['company'] = companyId;

    this.utilityService.showLoading(true);
    this.glCodeService.getGlCodeList(dataObj).subscribe(
      glCodeList => this.onGlCodeListLoaded(glCodeList),
      error => this.utilityService.handleError(error)
    );
  }

  onGlCodeListLoaded(glCodeList) {
    this.glCodeList = glCodeList;
    this.utilityService.showLoading(false);
  }

  glSort(orderBy: string) {
    this.glOrderBy = orderBy;
    if (this.glSortBy === 'asc') {
      this.glSortBy = 'desc';
    } else {
      this.glSortBy = 'asc';
    }
    if (this.glCodeList.length > 0 && this.companyId != null) {
      this.getGlCodeList(this.companyId);
    }
  }

  openGlCodeModal(data) {
    const dialogData: AddGlCodeDialogContext = {
      company_id: this.companyId,
      gl_detail: data,
      glNames: getTakenNames(this.glCodeList, data?.name)
    };
    this.dialog.open(GlCodeDialogComponent, {
      width: '720px',
      data: dialogData,
      autoFocus: false
    });
  }

  deleteGlCode(glc: GLCode) {
    if (confirm(this.validations.ValiditionMessages.DELETE_GL_CODE)) {
      this.utilityService.showPopupLoading(true);
      this.glCodeService.deleteGlCode(glc.id).subscribe(
        () => this.onGlCodeDeleted(),
        () => this.showDeleteNotificationDialog(glc.name)
      );
    } else {
      return false;
    }
  }

  onGlCodeDeleted() {
    this.utilityService.showToast({ Title: '', Message: 'GL code deleted successfully.', Type: 'success' });
    this.utilityService.loadGlCode(true);
  }

  showDeleteNotificationDialog(glcName: string) {
    const dialogData: DialogContext = utils.getConfirmationDialogData(ConfirmType.DeleteGLCode, glcName);
    this.dialog.open(ConfirmationDialogComponent, {
      width: dialogData.width,
      data: dialogData
    });
  }

}
