<article class="auth-page login-screen signin">
  <section class="auth-page-content">
    <div class="login-form-container">
      <h1 class="logo">
        <a href="javascript:;" title="Planful" [routerLink]="['/']">
          <img src="assets/images/planful-logo-colored.svg" width="150" alt="logo" />
        </a>
      </h1>
      <form class="auth-page-form login-form">
        <div class="custom-url">
          <div>{{ protocol }}</div>
          <div class="highlighted">{{ domain }}</div>
          <div>{{ '.' + envDomain }}</div>
        </div>
        <div class="form-actions">
          <button class="full-width" mat-flat-button color="primary" type="button" [routerLink] = "['/']">Log In</button>
        </div>
        <div class="form-links switch-user">
          <a class="form-links-item" (click)="navigateToLogin()">Switch user</a>
        </div>
      </form>
    </div>
  </section>
  <rights-reserved></rights-reserved>
</article>
