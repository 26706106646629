import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { CompanyDataService } from '@shared/services/company-data.service';
import { UserManager } from '../../user/services/user-manager.service';
import { Budget } from '@shared/types/budget.interface';
import { AppDataLoader } from 'app/app-data-loader.service';

@Injectable()
export class CegStatusEnabledGuard implements CanActivate {
  private readonly router = inject(Router);
  private readonly configuration = inject(Configuration);
  private readonly userManager = inject(UserManager);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly budgetDataService = inject(BudgetDataService);

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const pageCegStatusEnabled = route?.data?.cegStatusEnabled;

    return this.getSelectedBudget().pipe(
      map((budget: Budget) => {
        const budgetCegStatusEnabled = budget?.new_campaigns_programs_structure || false;
        if (pageCegStatusEnabled !== budgetCegStatusEnabled) {
          const pageRoute = this.getPageRoutePath(budgetCegStatusEnabled);
          return this.router.createUrlTree([pageRoute], { queryParamsHandling: 'preserve'});
        } else {
          return true;
        }
      })
    );
  }

  private getSelectedBudget(): Observable<Budget> {
    const selectedBudget$ =
      combineLatest([this.companyDataService.selectedCompany$, this.userManager.currentUserId$]).pipe(
        filter(([company]) => !!company),
        take(1),
        switchMap(([company, userId]) =>
          this.budgetDataService.getCompanyAvailableBudgetList(company.id).pipe(map(budgets => [budgets, userId] as const))
        ),
        map(([budgets, userId]) => AppDataLoader.getBudgetToSelect(this.budgetDataService, this.companyDataService,userId, budgets))
      );

    return !!this.budgetDataService.selectedBudgetSnapshot
      ? of(this.budgetDataService.selectedBudgetSnapshot)
      : selectedBudget$;
  }

  private getPageRoutePath(budgetCegStatusEnabled: boolean): string {
    return budgetCegStatusEnabled
      ? this.configuration.ROUTING_CONSTANTS.MANAGE_CEG_PAGE
      : this.configuration.ROUTING_CONSTANTS.MANAGE_PAGE;
  }
}
