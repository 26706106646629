import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, NgZone, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { filter, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ExpenseTableColumnItem, HierarchyViewMode } from '@spending/types/expense-page.type';
import { ShadowState } from '@shared/directives/table-content-shadows.directive';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SpendingCommonRowData } from '@spending/services/invoice-table-data.service';
import { ExpenseTableColumn } from '../../types/expense-page.type';
import { ExpensePageSelectionService, SelectableSpendingRow } from '@spending/services/expense-page-selection.service';
import { SpendingSidebarService } from '@spending/services/spending-sidebar.service';
import { SpendingService } from '@spending/services/spending.service';
import { TableContentShadowsBaseComponent } from '@shared/directives/TableContentShadowsBase';

@Component({
  template: ''
})
export abstract class SpendingTableBaseComponent<RowData extends SpendingCommonRowData> extends TableContentShadowsBaseComponent {
  protected visibleColumns: ExpenseTableColumnItem[] = [];

  protected rows: RowData[];
  protected selectableRows: SelectableSpendingRow[];
  protected selectedRows: Record<string, SelectableSpendingRow>;
  protected numberFormatLong = '1.2-2';
  protected ExpenseTableColumn = ExpenseTableColumn;

  protected dragAllowed = true;
  protected rowSelectedNumber: number;
  protected dragIcon: IconProp = [ 'fas', 'coins' ];
  protected destroy$ = new Subject<void>();

  @Input() readOnlyMode: boolean;
  @Input() openedInDrawerId: number;

  @Output() openDetails = new EventEmitter<number>();
  @HostBinding('class.spending-table-container') addHostCustomClass = true;

  protected abstract isRowSelectable(row: RowData): boolean;

  protected constructor(
    private readonly _router: Router,
    private readonly _zone: NgZone,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _spendingService: SpendingService,
    private readonly _spendingSidebarService: SpendingSidebarService,
    private readonly pageSelectionService: ExpensePageSelectionService,
  ) {
    super();
    const hierarchyMode$ = this._spendingSidebarService.hierarchyMode$;
    const routerEvents$ = this._router.events
      .pipe(filter(event => event instanceof NavigationEnd));

    combineLatest([hierarchyMode$, routerEvents$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.dragAllowed = data[0] !== HierarchyViewMode.Source
          && data[0] !== HierarchyViewMode.Timeframe
          && !this._router.url.includes('drawer');
      });

    this.pageSelectionService.rowSelection$
      .pipe(takeUntil(this.destroy$))
      .subscribe(rows => {
        this.selectedRows = rows;
        this.rowSelectedNumber = Object.keys(rows).length;
        this._cdr.markForCheck();
      });
  }

  protected onNameClick(id: number) {
    this.openDetails.emit(id);
  }

  protected onRowSelectionChange(isSelected: boolean, row: SelectableSpendingRow): void {
    this.pageSelectionService.toggleRowSelection(isSelected, row, this.selectableRows.length);
  }

  protected onHandleDragStart(row: SelectableSpendingRow): void {
    this._zone.run(() => {
      this._spendingService.dragExpenses = Object.keys(this.selectedRows).length ? Object.values(this.selectedRows) : [ row ];
    });
  }

  protected onHandleDragEnd(): void {
    this._zone.run(() => {
      this._spendingSidebarService.onDragEndAction(null);
    });
  }

  public isRowSelected(row: RowData): boolean {
    return this.isRowSelectable(row) && !!this.pageSelectionService.itemSelectionValue[row.rowId];
  }

  protected get isAllSelected(): boolean {
    return this.pageSelectionService.isAllSelectedValue;
  }

  public onSelectAllChange(e: MatCheckboxChange): void {
    this.pageSelectionService.toggleCurrentPageSelection(e.checked, this.selectableRows);
  }
}
