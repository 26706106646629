import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { Tag } from './company-data.service';
import { BulkUpdateDialogType } from '../types/dialog-context.interface';
import { BudgetObjectDialogService } from './budget-object-dialog.service';
import { TagControlEvent } from '../components/tags-control/tags-control.component';
import { TagMapping } from '../types/tag-mapping.interface';
import { AutocompleteItem } from '../types/autocomplete-item.type';

/**
 * Service to isolate and share legacy <tags-control> component logic
 */
@Injectable({
  providedIn: 'root'
})
export class TagsControlService {
  constructor(
    private readonly dialogManager: BudgetObjectDialogService
  ) {}

  private onAddTag(event: TagControlEvent, tags: TagMapping[]) {
    const alreadyAttachedTag = tags.find(tag => tag.tagId === event.id);
    if (!alreadyAttachedTag) {
      tags.push({ name: event.title, id: null, tagId: event.id, existed: true });
    }
  }

  private onCreateTag(event: TagControlEvent, tags: TagMapping[]) {
    tags.push({ name: event.title, id: null, tagId: event.id, existed: false });
  }

  private onRemoveTag(event: TagControlEvent, existingTags: TagMapping[], removedTags: TagMapping[] = []) {
    const index = existingTags.findIndex(tm => {
      return event.id ?
        (tm.tagId === event.id) :
        (tm.name === event.title);
    });
    if (index !== -1) {
      removedTags.push(existingTags[index]);
      existingTags.splice(index, 1);
    }
  }

  public openRemoveTagsDialog(
    tagMappings$: Observable<TagMapping[]> = of([]),
    submitHandler?: (tags: TagMapping[], observer: Observer<any>) => void,
    title = 'Remove tag(s)',
    completeOnSubmit = true,
  ): Observable<TagMapping[]> {
    const tagMappingsToRemove: TagMapping[] = [];
    const tagMappingsContainer: TagMapping[] = [];
    const autocompleteItems = [];

    return new Observable((observer) => {
      this.dialogManager.openBulkUpdateDialog({
        submitAction: {
          handler: () => {
            submitHandler?.(tagMappingsToRemove, observer);
            if (completeOnSubmit) {
              observer.next(tagMappingsToRemove);
              observer.complete();
            }
          }
        },
        cancelAction: {
          handler: () => observer.complete()
        },
        title,
        data: {
          autocompleteItems,
          tagMappingsContainer,
          tagMappings$: tagMappings$,
          onRemoveTag: (event) => this.onRemoveTag(event, tagMappingsContainer, tagMappingsToRemove),
        },
        type: BulkUpdateDialogType.tagsRemoval
      });
    });
  }

  public openAddTagsDialog(
    tags: Tag[],
    submitHandler?: (tags: TagMapping[], observer: Observer<any>) => void,
    title = 'Add tag(s)',
    completeOnSubmit = true
  ): Observable<TagMapping[]> {
    const tagMappingsContainer: TagMapping[] = [];
    const autocompleteItems: AutocompleteItem[] = tags.map(tag => ({
      id: tag.id,
      name: tag.name
    }));

    return new Observable((observer) => {
      this.dialogManager.openBulkUpdateDialog({
        submitAction: {
          handler: () => {
            submitHandler?.(tagMappingsContainer, observer);
            if (completeOnSubmit) {
              observer.next(tagMappingsContainer);
              observer.complete();
            }
          },
        },
        cancelAction: {
          handler: () => observer.complete()
        },
        title,
        data: {
          autocompleteItems,
          tagMappingsContainer,
          onAddTag: event => this.onAddTag(event, tagMappingsContainer),
          onCreateTag: event => this.onCreateTag(event, tagMappingsContainer),
          onRemoveTag: event => this.onRemoveTag(event, tagMappingsContainer),
        },
        type: BulkUpdateDialogType.tags
      });
    });
  }
}
