import { Observable, of } from 'rxjs';
import { ExternalMetrics } from '../types/external-metrics.types';
import { ExternalMetricTypesMapping } from '../types/external-metric-types-mapping.interface';
import { MetricsProviderDataService } from './metrics-provider-data.service';

export abstract class ExternalMetricTypesService {
  protected abstract readonly externalMetricTypes: ExternalMetrics;
  protected abstract readonly defaultMapping: { [externalTypeName: string]: string };

  protected constructor(
    protected dataService: MetricsProviderDataService<any>
  ) {}

  get defaultMetricTypesMapping(): { [externalTypeName: string]: string } {
    return this.defaultMapping;
  }

  getMetricTypes(companyId: number, integrationId?: string): Observable<ExternalMetrics> {
    return of(this.externalMetricTypes);
  }

  getExternalMetricTypesMapping(companyId: number, integrationId?: string): Observable<ExternalMetricTypesMapping> {
    return this.dataService.getExternalMetricTypesMapping(companyId, integrationId);
  }

  setExternalMetricTypesMapping(companyId: number, mapping: ExternalMetricTypesMapping, integrationId?: string): Observable<any> {
    return this.dataService.setExternalMetricTypesMapping(companyId, mapping, integrationId);
  }
}
