import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import {
  ManageCegViewMode,
  ManageTableDataConfig,
  ManageTableViewConfig, TableConfigDialog
} from '@manage-ceg/types/manage-ceg-page.types';
import { MatDialog } from '@angular/material/dialog';
import { TableConfigDialogComponent } from '@manage-ceg/dialogs/table-config-dialog/table-config-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { createDeepCopy } from '@shared/utils/common.utils';

@Component({
  selector: 'table-configuration',
  templateUrl: './table-configuration.component.html',
  styleUrls: ['./table-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableConfigurationComponent {
  private readonly dialog = inject(MatDialog);
  private _tableConfig: ManageTableDataConfig;
  @ViewChild('configBtn', { read: ElementRef, static: true }) configBtn: ElementRef;

  @Input() viewMode: ManageCegViewMode;
  @Input() tableConfig: ManageTableDataConfig;
  @Output() onTableConfigChange = new EventEmitter<ManageTableViewConfig>();

  private onHandleCancel(): void {
    this.tableConfig = this._tableConfig;
  }

  showConfigDialog(): void {
    this._tableConfig = createDeepCopy(this.tableConfig);
    const dialogData: TableConfigDialog = {
      viewMode: this.viewMode,
      config: this.tableConfig,
      onSubmit: (config: ManageTableViewConfig) => this.onTableConfigChange.emit(config),
      onCancel: () => this.onHandleCancel()
    }
    const { bottom, left, width } = this.configBtn.nativeElement.getBoundingClientRect();
    const dialogWidth = 390;

    this.dialog.open(TableConfigDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'ceg-config-dialog',
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      data: dialogData,
      width: `${dialogWidth}px`,
      position: {
        top: `${bottom}px`,
        left: `1.8rem`
      }
    })
  }
}
