import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { ManageTableViewMode, ManageTableViewModeChange } from '../types/manage-table-view-mode.type';
import { ManageTableDataMode } from '../types/manage-table-data-mode.type';
import { TabSwitchOption } from 'app/shared/components/tab-switch/tab-switch.types';
import { SpendingModeFlag, SpendingModeFlags } from '../types/spending-mode-flags.type';
import { AllocationModeFlag, AllocationModeFlags } from '../types/allocation-mode-flags.type';
import { ManagePageHistoryModeService } from './manage-page-history-mode.service';


@Injectable()
export class ManagePageModeService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly viewModeParamDelimiter = '_';
  private _viewMode: ManageTableViewMode;
  private _dataMode: ManageTableDataMode;
  private _showExpenses = false;
  private _spendingModeFlags: SpendingModeFlags = {
    [SpendingModeFlag.PlannedExpensesInTotal]: true,
    [SpendingModeFlag.ExpensesAsTotal]: false,
    [SpendingModeFlag.Formula]: false
  };
  private _allocationModeFlags: AllocationModeFlags = {
    [AllocationModeFlag.Formula]: false,
    [AllocationModeFlag.Expenses]: false
  };

  public readonly dataModeOptions: TabSwitchOption[] = [
    {
      title: 'Allocation',
      value: ManageTableDataMode.Allocation,
      icon: ['fad', 'piggy-bank']
    },
    // TODO: To be implemented later
    // {
    //   title: 'Performance',
    //   value: ManageTableDataMode.Performance,
    //   icon: ['fad', 'chart-line']
    // },
    {
      title: 'Spending',
      value: ManageTableDataMode.Spending,
      icon: ['fas', 'coins']
    }
  ];

  public viewModeChange = new BehaviorSubject<ManageTableViewModeChange>(null);
  public updateMiniDashData$ = new Subject<void>();
  private static getManageTableDataMode(dataModeParam: string) {
    return Object.values(ManageTableDataMode).includes(dataModeParam as ManageTableDataMode) ?
      dataModeParam as ManageTableDataMode :
      ManageTableDataMode.Allocation;
  }

  public get viewMode() {
    return this._viewMode;
  }

  public get dataMode() {
    return this._dataMode;
  }

  public get showExpenses() {
    return this._showExpenses;
  }

  public get spendingModeFlags() {
    return this._spendingModeFlags;
  }

  public get allocationModeFlags() {
    return this._allocationModeFlags;
  }

  constructor(
    private readonly config: Configuration,
    private readonly router: Router,
    private readonly location: Location,
    private readonly activatedRoute: ActivatedRoute,
    private historyModeService: ManagePageHistoryModeService
  ) {
    const savedMode = this.historyModeService.getState();

    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        params => this.applyRouteParamsChange(params, savedMode)
      );

    this.setInitialMode(savedMode);
  }

  private setInitialMode(savedMode: string) {
    if (!savedMode) {
      const defaultMode = {
        [ManageTableDataMode.Spending]: this._spendingModeFlags,
        [ManageTableDataMode.Allocation]: this._allocationModeFlags,
        dataMode: this._dataMode
      };
      this.historyModeService.saveDefaultState(defaultMode);
    } else {
      const spending = savedMode[ManageTableDataMode.Spending];
      const allocation = savedMode[ManageTableDataMode.Allocation];
      allocation[AllocationModeFlag.Expenses] ||= this._showExpenses;
      this.setActualMode(allocation, spending);

      if (allocation[AllocationModeFlag.Expenses]) {
        setTimeout(() => this.updateUrlState(), 200);
      }
    }
  }

  private applyRouteParamsChange(params: ParamMap, savedMode: string) {
    const viewModeParam = params.get('viewMode') || '';
    const dataModeParam = params.get('dataMode') || '';
    const [ viewModeValue, showExpensesValue ] = viewModeParam.split(this.viewModeParamDelimiter);
    const prevViewMode = this._viewMode;

    this._viewMode =
      Object.values(ManageTableViewMode).includes(viewModeValue as ManageTableViewMode) ?
        viewModeValue as ManageTableViewMode :
        ManageTableViewMode.Segments;

    this._dataMode =
      savedMode?.['dataMode'] ?
        savedMode['dataMode'] :
        ManagePageModeService.getManageTableDataMode(dataModeParam);

    this._showExpenses =
      showExpensesValue === ManageTableViewMode.Expenses ||
      savedMode?.[ManageTableDataMode.Allocation][AllocationModeFlag.Expenses];

    this.viewModeChange.next({ value: this.viewMode, prevValue: prevViewMode });
  }

  private updateUrlState() {
    const url = this.getCurrentLocation(true);
    history.pushState({}, '', url);
  }

  public getCurrentLocation(withQueryParams: boolean): string {
    const { ROUTING_CONSTANTS } = this.config;
    const viewModeParts = [ this.viewMode ];
    if (this.showExpenses) {
      viewModeParts.push(ManageTableViewMode.Expenses);
    }
    const dataModeURL = this.dataMode.toString().toLowerCase();
    const viewModeURL = viewModeParts.join(this.viewModeParamDelimiter);
    const queryParams = withQueryParams ? window.location.search : '';
    return `${ROUTING_CONSTANTS.MANAGE_PAGE}/${dataModeURL}/${viewModeURL}${queryParams}`;
  }

  public setViewMode(mode: ManageTableViewMode) {
    const prevViewMode = this._viewMode;
    this._viewMode = mode;
    this.viewModeChange.next({ value: this.viewMode, prevValue: prevViewMode });
    this.historyModeService.saveViewMode(mode);
    this.updateUrlState();
  }

  public setDataMode(mode: ManageTableDataMode) {
    this._dataMode = mode;
    this.updateUrlState();
    this.historyModeService.saveDataMode(mode);
    this.updateMiniDashData$.next();
  }

  public setShowExpenses(showExpenses: boolean) {
    this._showExpenses = showExpenses;
    this.setAllocationModeFlags({ [AllocationModeFlag.Expenses]: showExpenses });
    this.updateUrlState();
  }

  public setSpendingModeFlags(flags: Partial<SpendingModeFlags>) {
    this._spendingModeFlags = {
      ...this._spendingModeFlags,
      ...flags
    };
    if (flags[SpendingModeFlag.PlannedExpensesInTotal] !== undefined) {
      this.updateMiniDashData$.next();
    }
    this.historyModeService.saveActualState(ManageTableDataMode.Spending, this._spendingModeFlags, this._dataMode);
  }

  public setAllocationModeFlags(flags: Partial<AllocationModeFlags>) {
    this._allocationModeFlags = {
      ...this._allocationModeFlags,
      ...flags
    };
    this.historyModeService.saveActualState(ManageTableDataMode.Allocation, this._allocationModeFlags, this._dataMode);
  }

  private setActualMode(allocationMode: AllocationModeFlags, spendingMode: SpendingModeFlags): void {
    if (JSON.stringify(allocationMode) !== JSON.stringify(this._allocationModeFlags)) {
      this.setAllocationModeFlags(allocationMode);
    }
    if (JSON.stringify(spendingMode) !== JSON.stringify(this._spendingModeFlags)) {
      this.setSpendingModeFlags(spendingMode);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
