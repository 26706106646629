<div *ngIf="sourcesNames; else emptyMsg"
     class="metrics-list"
     [matTooltip]="sourcesNames"
     matTooltipClass="dark-theme-tooltip above metric-sources-tooltip"
     matTooltipPosition="above">
  <icon-plannuh *ngIf="sourcesForDisplay[metricIntegrationName.Plannuh]"
                [size]="'20px'"
                style="top: 2px; margin-right: 0;"></icon-plannuh>
  <icon-google-ads *ngIf="sourcesForDisplay[metricIntegrationName.GoogleAds]"
                   [size]="'20px'"
                   style="top: 2px"></icon-google-ads>
  <icon-facebook *ngIf="sourcesForDisplay[metricIntegrationName.FacebookAds]"
                   [size]="'20px'"
                   style="top: 2px"></icon-facebook>
  <icon-linkedin *ngIf="sourcesForDisplay[metricIntegrationName.LinkedinAds]"
                   [size]="'20px'"
                   style="top: 1px"></icon-linkedin>
  <icon-salesforce *ngIf="sourcesForDisplay[metricIntegrationName.Salesforce]"
                   [size]="'22px'"
                   style="top: 1px"></icon-salesforce>
  <fa-icon *ngIf="sourcesForDisplay[metricIntegrationName.Hubspot]"
           [icon]="['fab', 'hubspot']"></fa-icon>

  <span *ngIf="showDots" class="dots">...</span>
</div>
<ng-template #emptyMsg>
  <span class="na-text">N/A</span>
</ng-template>

