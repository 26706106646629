import { Component, Input } from '@angular/core';
import WidgetLegendItem from '../../types/widget-legend-item.interface';

@Component({
  selector: 'widget-chart-legend',
  styleUrls: ['./widget-chart-legend.component.scss'],
  templateUrl: './widget-chart-legend.component.html'
})
export class WidgetChartLegendComponent {
  @Input() items: WidgetLegendItem[] = [];
}
