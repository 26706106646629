import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, DoCheck } from '@angular/core';

import { CurrencyService } from 'app/shared/services/backend/currency.service';
import { Currency } from 'app/shared/types/currency.interface';
import {
  ExchangeRatesTableData,
  ExchangeRatesTableRatePerMonth,
  ExchangeRatesTableCurrency,
  ExchangeRatesTableChangeRateEvent
} from './exchange-rates-table.interface';
import { CompanyCurrencyService } from 'app/shared/services/backend/company-currency.service';

@Component({
  selector: 'exchange-rates-table',
  templateUrl: './exchange-rates-table.component.html',
  styleUrls: ['./exchange-rates-table.component.scss']
})
export class ExchangeRatesTableComponent implements DoCheck {
  @Input() data: ExchangeRatesTableData = { currencies: [], months: [] };
  @Output() changeRate = new EventEmitter<ExchangeRatesTableChangeRateEvent>();
  @Output() addCurrency = new EventEmitter<string>();
  @Output() removeCurrency = new EventEmitter<ExchangeRatesTableCurrency>();
  @ViewChild('scrollContainer', { read: ElementRef }) scrollContainer;

  showLeftGradient = false;
  showRightGradient = true;
  isAddCurrencyRowShown = false;
  editRateData: ExchangeRatesTableRatePerMonth = null;
  editCurrency: ExchangeRatesTableCurrency = null;
  currencySuggestions = [];
  prevCurrenciesLength = 0;

  constructor(private currencyService: CurrencyService, public companyCurrencyService: CompanyCurrencyService) {}

  ngDoCheck() {
    this.updateEditModeAfterRendering();
    this.updateCurrencySuggestions();
  }

  handleScroll() {
    this.setGradient();
  }

  handleCellClick(rateData: ExchangeRatesTableRatePerMonth, currency: ExchangeRatesTableCurrency) {
    if (!rateData.isEditAllowed) {
      return;
    }
    setTimeout(() => {
      // this timeout is needed to postpone new edit cell setting until click outside event is executed
      this.setEditMode(currency, rateData, rateData.rate.toString());
    });
  }

  handleRatePerMonthChange() {
    if (!this.editCurrency || !this.editRateData) {
      return;
    }

    this.submitChangeRate();
    this.cancelEditMode();
  }

  handleLeftArrowClick() {
    this.submitChangeRateAndMoveFocus(false);
  }

  handleRightArrowClick() {
    this.submitChangeRateAndMoveFocus(true);
  }

  handleAddCurrencyClick() {
    this.isAddCurrencyRowShown = true;
  }

  handleNewCurrencySelect(data) {
    const { subtitle: currencyCode } = data;
    this.addCurrency.emit(currencyCode);
  }

  handleAddCurrencyCancel() {
    this.isAddCurrencyRowShown = false;
  }

  handleRemoveCurrency(currency: ExchangeRatesTableCurrency) {
    this.removeCurrency.emit(currency);
  }

  updateCurrencySuggestions() {
    const { currencies: companyCurrencies } = this.data;
    if (this.prevCurrenciesLength === companyCurrencies.length) {
      // optimization: as we can just add or remove a currency
      // then autocomplete suggestions list should be changed only when currency list length has been changed
      return;
    }

    const currencies = this.currencyService.currencyList$.getValue();
    if (!currencies) {
      return;
    }

    this.prevCurrenciesLength = companyCurrencies.length;
    this.currencySuggestions = currencies.filter((currency: Currency) => {
      return !companyCurrencies.some((companyCurrency: ExchangeRatesTableCurrency) => companyCurrency.code === currency.code);
    })
    .map((currency: Currency) => ({ title: currency.name, subtitle: currency.code }));
  }

  submitChangeRate() {
    if (!this.editRateData) {
      return;
    }

    const { editableValue, rate } = this.editRateData;
    if (Number.parseFloat(editableValue.replace(/\,/g, '')) === rate) {
      return;
    }

    this.changeRate.emit({
      currency: this.editCurrency,
      rateData: this.editRateData
    });
  }

  submitChangeRateAndMoveFocus(isRightDirection: boolean) {
    if (!this.editCurrency || !this.editRateData) {
      return;
    }

    this.submitChangeRate();

    const editCurrency = this.getCurrencyByCode(this.editCurrency.code);
    if (!editCurrency) {
      return;
    }

    const editRateData = this.getRateDataByMonth(editCurrency, this.editRateData.month);
    const newEditRateData = this.getMovedEditRateData(editCurrency, editRateData, isRightDirection);
    if (!newEditRateData) {
      return;
    }

    this.cancelEditMode();
    this.setEditMode(editCurrency, newEditRateData, newEditRateData.rate.toString());
  }

  getMovedEditRateData(
    currency: ExchangeRatesTableCurrency,
    rateData: ExchangeRatesTableRatePerMonth,
    isRightDirection: boolean
  ): ExchangeRatesTableRatePerMonth {
    const { ratesPerMonth } = currency;
    let editRateIndex = ratesPerMonth.indexOf(rateData);
    if (editRateIndex < 0) {
      return null;
    }

    if (isRightDirection) {
      editRateIndex >= ratesPerMonth.length - 1 ? editRateIndex = 0 : editRateIndex++;
    } else {
      !editRateIndex ? editRateIndex = ratesPerMonth.length - 1 : editRateIndex--;
    }
    return ratesPerMonth[editRateIndex];
  }

  setGradient() {
    if (!this.scrollContainer || !this.scrollContainer.nativeElement) {
      return;
    }

    const { scrollLeft, scrollWidth, clientWidth } = this.scrollContainer.nativeElement;
    if (scrollLeft > 0) {
      this.showLeftGradient = true;
    } else {
      this.showLeftGradient = false;
    }

    if (scrollLeft + clientWidth < scrollWidth) {
      this.showRightGradient = true;
    } else {
      this.showRightGradient = false;
    }
  }

  updateEditModeAfterRendering() {
    if (!this.editRateData || !this.editCurrency) {
      return;
    }

    const editCurrency = this.getCurrencyByCode(this.editCurrency.code);
    if (!editCurrency) {
      return;
    }

    const editRateData = this.getRateDataByMonth(editCurrency, this.editRateData.month);
    if (!editRateData) {
      return;
    }

    this.setEditMode(editCurrency, editRateData, this.editRateData.editableValue);
  }

  getCurrencyByCode(code: string): ExchangeRatesTableCurrency {
    const { currencies } = this.data;
    return currencies.find((c: ExchangeRatesTableCurrency) => c.code === code);
  }

  getRateDataByMonth(currency: ExchangeRatesTableCurrency, month: string): ExchangeRatesTableRatePerMonth {
    const { ratesPerMonth } = currency;
    return ratesPerMonth.find((r: ExchangeRatesTableRatePerMonth) => r.month === month);
  }

  setEditMode(currency: ExchangeRatesTableCurrency, rateDate: ExchangeRatesTableRatePerMonth, editableValue: string) {
    this.editCurrency = currency;
    this.editRateData = rateDate;
    this.editCurrency.isEditMode = true;
    this.editRateData.isEditMode = true;
    this.editRateData.editableValue = editableValue;
  }

  cancelEditMode() {
    this.editRateData.isEditMode = false;
    this.editCurrency.isEditMode = false;
    this.editRateData = null;
    this.editCurrency = null;
  }

  removeCurrencyNewStatus(currencyId) {
    const data = {is_new: false};
    const subsctiption = this.companyCurrencyService.updateCurrency(currencyId, data).subscribe(
      () => {
        this.data.currencies = this.data.currencies.map(currencyEl => {
          if (currencyEl.id === currencyId) {
            return { ...currencyEl, isNew: false }
          }
          return currencyEl;
        })
        subsctiption.unsubscribe();
      }
    )
  }
}
