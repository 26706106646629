import { Injectable, inject } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserManager } from './user-manager.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';


export const SKIP_AUTH = new HttpContextToken<boolean>(() => false);

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  
  private readonly budgetDataService = inject(BudgetDataService);

  constructor(
    private readonly userManager: UserManager,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipAuth = req.context.get(SKIP_AUTH);
    if (skipAuth) {
      return next.handle(req);
    }

    let budgetId: number | string = null;
    let companyId: number | string = null;
    const userInfo = this.userManager.getCurrentUser();
    if(userInfo) {
      const storedBudgets = this.budgetDataService.selectedBudgetStorage.getStoredCompanyBudgetMap(userInfo.id) || {};
      companyId = this.budgetDataService.selectedBudgetStorage.getSelectedCompany(userInfo.id);
      budgetId = storedBudgets[companyId];
    }


    return this.userManager.getToken().pipe(
      switchMap(token => {
        let headers: Record<string, string> = {'Authorization': 'Bearer ' + token};
        let isAPIEndpoint = req.url.includes('api');
        let isAttachmentServiceEndpoint = req.url.includes('attachments');
        if(budgetId && companyId && isAPIEndpoint && !isAttachmentServiceEndpoint) {
              headers = { 
                ...headers,
                "x-c": `${companyId}`,
                "x-b": `${budgetId}`,
                "x-ts": `${Date.now()}`
              };    
          }
      return  next.handle(token != null ? req.clone({ setHeaders: headers}) : req);
  }),
      catchError(error => {
        if (error && error.status === HttpStatusCode.Unauthorized) {
          setTimeout(() => this.userManager.logout());
        }
        return throwError(error);
      })
    );
  }
}

