import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AllocationCheckResultData,
  BudgetObjectAllocationService
} from '../../budget-object-details/services/budget-object-allocation.service';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageCegTableRow, ManageTableBudgetColumnName } from '@manage-ceg/types/manage-ceg-page.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { ManageCegTableHelpers } from '@manage-ceg/services/manage-ceg-table-helpers';
import { Configuration } from 'app/app.constants';

@Injectable()
export class ManageCegDataValidationService {
  private readonly configuration = inject(Configuration);
  private readonly tableDataService = inject(ManageCegTableDataService);
  private readonly budgetObjectAllocationService = inject(BudgetObjectAllocationService);

  private _draggedRecord: ManageCegTableRow = null;
  private _restrictedFromDrop: Record<string, boolean> = {};

  get draggedRecord(): ManageCegTableRow {
    return this._draggedRecord;
  }

  get restrictedFromDrop(): Record<string, boolean> {
    return this._restrictedFromDrop;
  }

  setDraggedRecord(record: ManageCegTableRow): void {
    this._draggedRecord = record;
    this._restrictedFromDrop = {};
    this.updateRestrictedFromDrop();
  }

  validateRecordAllocationLimits(
    targetRecord: ManageCegTableRow,
    parentRecord: ManageCegTableRow
  ): Observable<AllocationCheckResultData> {
    const targetAllocations = Object.entries(targetRecord.allocations)
      .map(([timeframe, amounts]) => {
        return {
          company_budget_alloc: Number(timeframe),
          amount: amounts[ManageTableBudgetColumnName.Budget]?.ownAmount || 0
        };
      });
    const targetTotalAllocated = this.tableDataService.getRecordAllocationsTotal(targetAllocations);

    const objectAllocationData = {
      totalAmount: targetTotalAllocated,
      allocationAmounts: this.budgetObjectAllocationService.getAllocationAmountsMap(targetAllocations),
      objectId: targetRecord.objectId,
      objectType: targetRecord.type === ManageTableRowType.ExpenseGroup
        ? this.configuration.OBJECT_TYPES.program
        : this.configuration.OBJECT_TYPES.campaign
    };

    return this.budgetObjectAllocationService.checkObjectAllocationAmount(
      objectAllocationData,
      { id: parentRecord?.objectId, type: this.configuration.OBJECT_TYPES.campaign },
      this.tableDataService.tableDataInputs.expGroups,
      this.tableDataService.tableDataInputs.campaigns,
    );
  }

  private updateRestrictedFromDrop(): void {
    const childRecordIds = ManageCegTableHelpers.getChildRecordIds(this.draggedRecord);
    if (!childRecordIds.length) {
      return;
    }

    this._restrictedFromDrop = childRecordIds.reduce(
      (acc, id) => ({ ...acc, [id]: true }), {}
    );
  }
}
