<button
  mat-flat-button
  [color]="type === 'flat' ? 'primary' : undefined"
  [ngClass]="{ 'basic-button': type === 'basic', 'filled-button': type === 'flat' }"
  (click)="fileInputClick()"
  [disabled]="disabled"
>
  <fa-icon [icon]="['fas', 'cloud-arrow-up']"></fa-icon>
  Upload invoices
</button>
<input #fileInput type="file" multiple [accept]="acceptedExtensions" (change)="onFileUpload($event)" />
