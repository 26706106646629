import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[selectTextOnFocus]'
})
export class SelectTextOnFocusDirective {
  @Input() public selectTextOnFocus = true;

  @HostListener('focus', ['$event']) onFocus($event) {
    if (this.selectTextOnFocus) {
      ($event.target as HTMLInputElement).select();
    }
  }
}
