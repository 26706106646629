import { ManageCegTableDataService } from '../manage-ceg-table-data.service';
import { Configuration } from 'app/app.constants';
import { BudgetObjectEventContext } from 'app/budget-object-details/types/budget-object-event.interface';
import { Observable, of, switchMap } from 'rxjs';
import { BaseBudgetObjectEventHandler } from './base-budget-object-event-handler';
import { BudgetPlanObjects } from '@shared/types/budget-plan-objects.type';
import { map, tap } from 'rxjs/operators';
import { LightCampaign } from '@shared/types/campaign.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { ManageCegDataMode, ManageCegTableRow } from '@manage-ceg/types/manage-ceg-page.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { getParentObject, resetParentForObjects } from 'app/budget-object-details/utils/object-details.utils';
import { sortObjectsByName } from '@shared/utils/budget.utils';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { ObjectMode } from '@shared/enums/object-mode.enum';

export class CampaignEventHandler extends BaseBudgetObjectEventHandler {
  private readonly budgetDataService: BudgetDataService;
  private readonly planObjectsLoader: () => Observable<BudgetPlanObjects>;

  public constructor(
    manageCegTableDataService: ManageCegTableDataService,
    config: Configuration,
    budgetDataService: BudgetDataService,
    planObjectsLoader: () => Observable<BudgetPlanObjects>
  ) {
    super(manageCegTableDataService, config);
    this.budgetDataService = budgetDataService;
    this.planObjectsLoader = planObjectsLoader;
  }

  protected onCreate(data: BudgetObjectEventContext): Observable<boolean> {
    this.notifyLightCampaignsChange();
    const createdCampaign = this.budgetDataService.lightCampaignsSnapshot.find(campaign => campaign.id === data.objectId);

    if (!createdCampaign) {
      return of(false);
    }

    return this.addCampaignsToTableDataInputs([createdCampaign]).pipe(
      switchMap(() => this.updateAffectedRowsAfterCampaignAdded(createdCampaign))
    );
  }

  protected onDelete(data: BudgetObjectEventContext): Observable<boolean> {
    return this.onCampaignRemoved(data);
  }

  protected onMoved(data: BudgetObjectEventContext): Observable<boolean> {
    return this.onCampaignRemoved(data);
  }

  protected onUpdate(data: BudgetObjectEventContext): Observable<boolean> {
    return this.applyUpdatedCampaign(data).pipe(
      switchMap(({ updatedCampaign, prevCampaign }) => {
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate = this.getRowsToUpdateForUpdatedCampaigns([updatedCampaign], [prevCampaign]);
        return this.updateParentRows(rowsToUpdate).pipe(
          map(() => true)
        );
      })
    );
  }

  public handleMetricMappingChange(targetCampaign: LightCampaign): Observable<boolean> {
    if (this.dataMode === ManageCegDataMode.Performance && targetCampaign != null) {
      return this.addCampaignsToTableDataInputs([targetCampaign]).pipe(
        switchMap(
          () => {
            const targetCampaignRow = this.getTableRowByRowTypeAndObjectId(ManageTableRowType.Campaign, targetCampaign.objectId);
            const nearestLoadedParentRow = this.getNearestLoadedParentRow(targetCampaignRow);
            return this.updateParentRows([nearestLoadedParentRow]);
          }
        ),
      );
    }

    return of(false);
  }

  public handleBulkDelete(campaignIds: number[]): Observable<boolean> {
    return of(null).pipe(
      switchMap(() => {
        const removedCampaigns = this.applyRemovedCampaigns(campaignIds);
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate =
          this.getRowsToUpdateForRemovedObjects(
            removedCampaigns,
              campaign => this.getParentObject(campaign?.parentCampaign, campaign?.goalId)
          );
        return this.updateParentRows(rowsToUpdate).pipe(
          map(() => true)
        );
      })
    );
  }

  public handleBulkUpdate(updatedCampaigns: LightCampaign[]): Observable<boolean> {
    return this.applyUpdatedCampaigns(updatedCampaigns).pipe(
      switchMap(({ prevCampaigns}) => {
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate = this.getRowsToUpdateForUpdatedCampaigns(updatedCampaigns, prevCampaigns);
        return this.updateParentRows(rowsToUpdate).pipe(
          map(() => true)
        );
      })
    );
  }

  public handleCampaignCreated(createdCampaign: LightCampaign): Observable<boolean> {
    return this.applyCreatedCampaign(createdCampaign).pipe(
      switchMap(() => this.updateAffectedRowsAfterCampaignAdded(createdCampaign))
    );
  }

  public handleBulkAmountStatusChanged(campaignIds: number[], newAmountStatus: CEGStatus): Observable<boolean> {
    const allRootCampaigns: LightCampaign[] = [];

    campaignIds.forEach(campaignId => {
      const rootCampaign = this.budgetDataService.lightCampaignsSnapshot.find(campaign => campaign.id === campaignId);
      if (rootCampaign) {
        rootCampaign.amountStatus = newAmountStatus;
      }

      const rootDataInputsCampaigns =
        this.manageCegTableDataService.tableDataInputs.campaigns.filter(
          campaign => [campaign.id, campaign.subCampaignId].includes(campaignId)
        );
      rootDataInputsCampaigns.forEach(campaign => campaign.amountStatus = newAmountStatus);
      allRootCampaigns.push(...rootDataInputsCampaigns);

      if (newAmountStatus === CEGStatus.COMMITTED) {
        this.applyAmountStatusToChildBudgetObjects(rootCampaign, newAmountStatus);
        this.applyAmountStatusToTableDataInputsChildObjects(campaignId, newAmountStatus);
      }
    });

    this.manageCegTableDataService.updateTable();

    return this.updateParentRows(this.getRowsToUpdateForBulkAmountStatusChange(allRootCampaigns)).pipe(
      map(() => true)
    );
  }

  private getRowsToUpdateForBulkAmountStatusChange(campaigns: LightCampaign[]): ManageCegTableRow[] {
    const campaignRows = campaigns.map(campaign => this.getTableRowByRowTypeAndObjectId(ManageTableRowType.Campaign, campaign.objectId));

    const getChildProcessedRows = (row: ManageCegTableRow): ManageCegTableRow[] => {
      const resultRows = row.processed ? [row] : [];
      return [...resultRows, ...(row.children || []).flatMap(childRow => getChildProcessedRows(childRow))];
    };

    return campaignRows.flatMap(row => getChildProcessedRows(row));
  }

  private updateAffectedRowsAfterCampaignAdded(targetCampaign: LightCampaign): Observable<boolean> {
    this.manageCegTableDataService.updateTable();
    const rowsToUpdate = this.getRowsToUpdateForAddedCampaign(targetCampaign.id, targetCampaign.splitRuleId);
    return this.updateParentRows(rowsToUpdate).pipe(
      map(() => true)
    );
  }

  private applyCreatedCampaign(createdCampaign: LightCampaign): Observable<any> {
    this.budgetDataService.lightCampaignsSnapshot.push(createdCampaign);
    sortObjectsByName(this.budgetDataService.lightCampaignsSnapshot);
    this.budgetDataService.setLightCampaigns(this.budgetDataService.lightCampaignsSnapshot); // To notify all subscribers!
    return this.addCampaignsToTableDataInputs([createdCampaign]);
  }

  private addCampaignsToTableDataInputs(targetCampaigns: LightCampaign[]): Observable<any> {
    return this.updateTableDataInputCampaigns(targetCampaigns).pipe(
      switchMap(() => {
        const { campaigns, expGroups } = this.manageCegTableDataService.tableDataInputs;
        return this.manageCegTableDataService.isFilteredMode ? this.planObjectsLoader() : of ({ campaigns, expGroups });
      }),
      tap(planObjects => this.manageCegTableDataService.updatePlanObjects(planObjects))
    );
  }

  private updateTableDataInputCampaigns(targetCampaigns: LightCampaign[]): Observable<any> {
    return this.updateTableDataInputObjects(
      targetCampaigns,
      this.manageCegTableDataService.tableDataInputs.campaigns,
      this.budgetDataService.getLightCampaigns.bind(this.budgetDataService),
      this.config.campaignStatusNames.active,
      this.budgetDataService.selectedBudgetSnapshot
    );
  }

  private getRowsToUpdateForAddedCampaign(objectId: number, sharedCostRuleId: number | null): ManageCegTableRow[] {
    return this.getRowsToUpdateForAddedObject(objectId, sharedCostRuleId, ManageTableRowType.Campaign);
  }

  private onCampaignRemoved(data: BudgetObjectEventContext): Observable<boolean> {
    return of(null).pipe(
      switchMap(() => {
        this.applyRemovedCampaign(data);
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate = this.getRowsToUpdateForRemovedCampaign(data);
        return this.updateParentRows(rowsToUpdate).pipe(
          map(() => true)
        );
      })
    );
  }

  private applyRemovedCampaign(data: BudgetObjectEventContext): void {
    this.notifyLightCampaignsChange();
    // Removed campaign might have been a parent for one of programs!
    this.budgetDataService.setLightPrograms(this.budgetDataService.lightProgramsSnapshot);
    const removedCampaign = data.extras?.prevObject as LightCampaign;
    this.removeCampaignFromTableDataInputs(removedCampaign);
  }

  private applyRemovedCampaigns(campaignIds: number[]): LightCampaign[] {
    const currentAllLightCampaigns = this.budgetDataService.lightCampaignsSnapshot;
    const removedCampaigns = this.applyRemovedObjects(campaignIds, currentAllLightCampaigns);

    const updatedAffectedCampaigns = resetParentForObjects(
      currentAllLightCampaigns,
      campaign => campaignIds.includes(campaign.parentCampaign),
      campaign => campaign.parentCampaign = null,
      campaigns => this.budgetDataService.setLightCampaigns(campaigns)
    );

    if (!updatedAffectedCampaigns) {
      this.budgetDataService.setLightCampaigns(currentAllLightCampaigns);
    }

    resetParentForObjects(
      this.budgetDataService.lightProgramsSnapshot,
      program => campaignIds.includes(program.campaignId),
      program => program.campaignId = null,
      program => this.budgetDataService.setLightPrograms(program)
    );

    this.removeCampaignsFromTableDataInputs(removedCampaigns);
    return removedCampaigns;
  }

  private removeCampaignsFromTableDataInputs(removedCampaigns: LightCampaign[], resetParentForChildDataInputObjects = true): void {
    for (const removedCampaign of removedCampaigns) {
      this.removeObjectFromTableDataInputs(removedCampaign, this.manageCegTableDataService.tableDataInputs.campaigns);
      if (resetParentForChildDataInputObjects) {
        const parentId =
          this.isSegmentViewMode && removedCampaign.splitRuleId ?
            removedCampaign.subCampaignId :
            removedCampaign.id;
        this.resetParentForAffectedDataInputsObjects(parentId);
      }
    }
  }

  private removeCampaignFromTableDataInputs(removedCampaign: LightCampaign, resetParentForChildDataInputObjects = true): void {
    this.removeCampaignsFromTableDataInputs([removedCampaign], resetParentForChildDataInputObjects);
  }

  private resetParentForAffectedDataInputsObjects(parentCampaignId: number): void {
    const { campaigns: dataInputsCampaigns, expGroups: dataInputsPrograms } = this.manageCegTableDataService.tableDataInputs;

    resetParentForObjects(
      dataInputsCampaigns,
      campaign => campaign.parentCampaign === parentCampaignId,
      campaign => campaign.parentCampaign = null
    );

    resetParentForObjects(
      dataInputsPrograms,
      program => program.campaignId === parentCampaignId,
      program => program.campaignId = null
    );
  }

  private getRowsToUpdateForRemovedCampaign(data: BudgetObjectEventContext): ManageCegTableRow[] {
    return this.getNearestLoadedParentRows(data.parentObject, data.segmentId, data.sharedCostRuleId);
  }

  private applyUpdatedCampaigns(
    updatedCampaigns: LightCampaign[]
  ): Observable<{ updatedCampaigns: LightCampaign[]; prevCampaigns: LightCampaign[] }> {
    const currentAllLightCampaigns = this.budgetDataService.lightCampaignsSnapshot;
    const prevCampaigns: LightCampaign[] = [];

    for (const updatedCampaign of updatedCampaigns) {
      const targetCampaignIndex = currentAllLightCampaigns.findIndex(campaign => campaign.id === updatedCampaign.id);
      prevCampaigns.push(currentAllLightCampaigns[targetCampaignIndex]);
      currentAllLightCampaigns[targetCampaignIndex] = updatedCampaign;
    }

    this.budgetDataService.setLightCampaigns(currentAllLightCampaigns);

    this.removeCampaignsFromTableDataInputs(prevCampaigns, false);
    return this.addCampaignsToTableDataInputs(updatedCampaigns).pipe(
      map(() => ({ updatedCampaigns, prevCampaigns }))
    );
  }

  private applyUpdatedCampaign(
    data: BudgetObjectEventContext
  ): Observable<{ updatedCampaign: LightCampaign; prevCampaign: LightCampaign }> {
    const updatedCampaign = this.budgetDataService.lightCampaignsSnapshot.find(campaign => campaign.id === data.objectId);
    const prevCampaign = data.extras?.prevObject as LightCampaign;
    const propagateAmountStatus = data.amountStatus === CEGStatus.COMMITTED && data.prevAmountStatus !== CEGStatus.PLANNED;
    const propagateModeStatus = data.objectMode === ObjectMode.Closed;
    if (propagateAmountStatus) {
      this.applyAmountStatusToChildBudgetObjects(updatedCampaign, data.amountStatus);
    }
    if (propagateModeStatus) {
      this.applyModeStatusToChildBudgetObjects(updatedCampaign, data.objectMode);
    }
    this.notifyLightCampaignsChange();
    this.removeCampaignFromTableDataInputs(prevCampaign, false);
    return this.addCampaignsToTableDataInputs([updatedCampaign]).pipe(
      map(() => {
        if (propagateAmountStatus) {
          this.applyAmountStatusToTableDataInputsChildObjects(updatedCampaign.id, data.amountStatus);
        }
        return { updatedCampaign, prevCampaign };
      })
    );
  }

  private getRowsToUpdateForUpdatedCampaigns(updatedCampaigns: LightCampaign[], prevCampaigns: LightCampaign[]): ManageCegTableRow[] {
    return this.getRowsToUpdateForUpdatedObjects(
      updatedCampaigns,
      prevCampaigns,
      ManageTableRowType.Campaign,
      (campaign: LightCampaign) => getParentObject(this.config, campaign.parentCampaign, campaign.goalId)
    );
  }

  private notifyLightCampaignsChange(): void {
    this.budgetDataService.setLightCampaigns(this.budgetDataService.lightCampaignsSnapshot); // To notify all subscribers!
  }

  private applyAmountStatusToChildBudgetObjects(updatedCampaign: LightCampaign, amountStatus: CEGStatus): void {
    const childCampaigns =
      this.budgetDataService.lightCampaignsSnapshot.filter(campaign => campaign.parentCampaign === updatedCampaign?.id);
    const childPrograms =
      this.budgetDataService.lightProgramsSnapshot.filter(program => program.campaignId === updatedCampaign?.id);
    [...childCampaigns, ...childPrograms].forEach(obj => obj.amountStatus = amountStatus);
    childCampaigns.forEach(campaign => this.applyAmountStatusToChildBudgetObjects(campaign, amountStatus));
  }

  private applyModeStatusToChildBudgetObjects(updatedCampaign: LightCampaign, modeStatus: string): void {
    const childCampaigns =
      this.budgetDataService.lightCampaignsSnapshot.filter(campaign => campaign.parentCampaign === updatedCampaign?.id);
    const childPrograms =
      this.budgetDataService.lightProgramsSnapshot.filter(program => program.campaignId === updatedCampaign?.id);
    [...childCampaigns, ...childPrograms].forEach(obj => obj.mode = modeStatus);
    childCampaigns.forEach(campaign => this.applyModeStatusToChildBudgetObjects(campaign, modeStatus));
  }

  private applyAmountStatusToTableDataInputsChildObjects(campaignId: number, amountStatus: CEGStatus): void {
    const { campaigns, expGroups } = this.manageCegTableDataService.tableDataInputs;
    const childCampaigns = campaigns.filter(campaign => campaign.parentCampaign === campaignId);
    expGroups
      .filter(program => program.campaignId === campaignId)
      .forEach(program => program.amountStatus = amountStatus);
    childCampaigns.forEach(campaign => campaign.amountStatus = amountStatus);
    childCampaigns.forEach(
      campaign => this.applyAmountStatusToTableDataInputsChildObjects(campaign.id || campaign.subCampaignId, amountStatus)
    );
  }
}
