<div class="w-100" *ngIf="metricFormData">
  <div class="w-100" *ngFor="let milestoneForm of metricFormData; index as i">
    <div class="details-form-field w-100" [formGroup]="milestoneForm">
      <div class="details-form-field w-50">
        <div class="icon-container">
          <pennant-icon></pennant-icon>
        </div>
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>{{ getMilestoneTitle(i + 1) }}</mat-label>
          <input
            [selectTextOnFocus]="true"
            (keydown.Tab)="(false)"
            blurOnEnterKeydown
            matInput
            currencyInput
            currencyMask
            [options]="newAmountFieldOpt"
            formControlName="amount"
            [required]="milestonesData.length > 1"
            (ngModelChange)="setMilestoneValue($event, i)"
          />
          <mat-error
            *ngIf="milestoneForm.controls['amount'].hasError('required')"
          >
            Amount is required
          </mat-error>
          <mat-error
            *ngIf="milestoneForm.controls['amount'].hasError('unique')"
          >
            Amount value must be unique
          </mat-error>
          <mat-error *ngIf="milestoneForm.controls['amount'].hasError('match')">
            Amount and date hasn't matched
          </mat-error>
        </mat-form-field>
      </div>
      <div class="details-form-field w-50">
        <div class="icon-container">
          <svg
            width="20"
            height="23"
            viewBox="0 0 20 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.25 0.75C6.98047 0.75 7.625 1.39453 7.625 2.125V3.5H13.125V2.125C13.125 1.39453 13.7266 0.75 14.5 0.75C15.2305 0.75 15.875 1.39453 15.875 2.125V3.5H17.9375C19.0547 3.5 20 4.44531 20 5.5625V7.625H0.75V5.5625C0.75 4.44531 1.65234 3.5 2.8125 3.5H4.875V2.125C4.875 1.39453 5.47656 0.75 6.25 0.75ZM0.75 9H20V20.6875C20 21.8477 19.0547 22.75 17.9375 22.75H2.8125C1.65234 22.75 0.75 21.8477 0.75 20.6875V9ZM3.5 12.4375V13.8125C3.5 14.1992 3.80078 14.5 4.1875 14.5H5.5625C5.90625 14.5 6.25 14.1992 6.25 13.8125V12.4375C6.25 12.0938 5.90625 11.75 5.5625 11.75H4.1875C3.80078 11.75 3.5 12.0938 3.5 12.4375ZM9 12.4375V13.8125C9 14.1992 9.30078 14.5 9.6875 14.5H11.0625C11.4062 14.5 11.75 14.1992 11.75 13.8125V12.4375C11.75 12.0938 11.4062 11.75 11.0625 11.75H9.6875C9.30078 11.75 9 12.0938 9 12.4375ZM15.1875 11.75C14.8008 11.75 14.5 12.0938 14.5 12.4375V13.8125C14.5 14.1992 14.8008 14.5 15.1875 14.5H16.5625C16.9062 14.5 17.25 14.1992 17.25 13.8125V12.4375C17.25 12.0938 16.9062 11.75 16.5625 11.75H15.1875ZM3.5 17.9375V19.3125C3.5 19.6992 3.80078 20 4.1875 20H5.5625C5.90625 20 6.25 19.6992 6.25 19.3125V17.9375C6.25 17.5938 5.90625 17.25 5.5625 17.25H4.1875C3.80078 17.25 3.5 17.5938 3.5 17.9375ZM9.6875 17.25C9.30078 17.25 9 17.5938 9 17.9375V19.3125C9 19.6992 9.30078 20 9.6875 20H11.0625C11.4062 20 11.75 19.6992 11.75 19.3125V17.9375C11.75 17.5938 11.4062 17.25 11.0625 17.25H9.6875ZM14.5 17.9375V19.3125C14.5 19.6992 14.8008 20 15.1875 20H16.5625C16.9062 20 17.25 19.6992 17.25 19.3125V17.9375C17.25 17.5938 16.9062 17.25 16.5625 17.25H15.1875C14.8008 17.25 14.5 17.5938 14.5 17.9375Z"
              fill="#B7C1D0"
            />
          </svg>
        </div>
        <mat-form-field
          appearance="outline"
          class="p-form-field-outline"
          style="pointer-events: none"
        >
          <mat-label>By</mat-label>
          <input
            matInput
            [matDatepicker]="datePicker"
            autocomplete="off"
            [required]="milestonesData.length > 1"
            formControlName="date"
            (mousedown)="openDateicker($event, datePicker)"
            (dateChange)="onHandleTargetDateChange($event, i)"
            style="pointer-events: all"
          />
          <mat-datepicker #datePicker></mat-datepicker>
          <button
            mat-button
            matSuffix
            class="clear-icon"
            style="pointer-events: all"
            (click)="clearDate($event, i, datePicker)"
          >
            <fa-duotone-icon
              *ngIf="milestoneForm.controls['date'].value"
              matTooltip="Clear date"
              matTooltipClass="dark-theme-tooltip simple multi-line"
              matTooltipPosition="above"
              [icon]="['fad', 'times-circle']"
            ></fa-duotone-icon>
          </button>
          <mat-error
            *ngIf="milestoneForm.controls['date'].hasError('required')"
          >
            Date is required
          </mat-error>
          <mat-error *ngIf="milestoneForm.controls['date'].hasError('unique')">
            Date field must be unique
          </mat-error>
        </mat-form-field>
      </div>
      <div class="details-form-field icon-container delete-button w-10">
        <button
          *ngIf="metricFormData.length > 1"
          mat-icon-button
          color="primary"
          (click)="deleteMilestoneByIndex($event, i)"
        >
          <fa-icon [icon]="['fas', 'trash']"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>
