import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[autoFocus]'
})
export class AutofocusDirective implements AfterContentInit {
  @Input() public autoFocus = true;
  @Input() public focusDelay?: number;

  public constructor(private el: ElementRef) {}

  public ngAfterContentInit() {
    if (this.autoFocus) {
      if (this.focusDelay) {
        setTimeout(() => {
          this.el.nativeElement.focus();
        }, this.focusDelay);
      } else {
        this.el.nativeElement.focus();
      }
    }
  }
}
