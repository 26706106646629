import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'details-timestamps',
  templateUrl: './details-timestamps.component.html',
  styleUrls: ['./details-timestamps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsTimestampsComponent {
  @Input() created: Date | string;
  @Input() updated: Date | string;
  @Input() timestampFormat = 'd LLL yyyy';
}
