import { Directive, HostListener } from '@angular/core';

import { KeyCodesConstants } from '../constants/key-codes.constants';

@Directive({
  selector: '[decimalInput]'
})
export class DecimalInputDirective {
  constructor(public keyCodesConstants: KeyCodesConstants) {}

  @HostListener('keydown', ['$event']) onKeyDown(e) {
    const { remove, backspace, tab, esc, enter, charDotNumLock, charComma, charDot } = this.keyCodesConstants;

    // backspace, delete, tab, escape, enter, comma and dot
    if ([remove, backspace, tab, esc, enter, charDotNumLock, charComma, charDot].includes(e.keyCode)) {
      return;
    }

    const { charA, charC, charX, charV } = this.keyCodesConstants;

    // Ctrl/cmd+A, Ctrl/cmd+C, Ctrl/cmd+X
    if ([charA, charC, charX, charV].includes(e.keyCode) && (e.ctrlKey === true || e.metaKey === true)) {
      return;
    }

    const { end, arrowRight } = this.keyCodesConstants;

    // home, end, left, right
    if (e.keyCode >= end && e.keyCode <= arrowRight) {
      return;
    }

    const { digit0, digit9, digit0NumLock, digit9NumLock } = this.keyCodesConstants;

    // block any non-number
    if ((e.shiftKey || (e.keyCode < digit0 || e.keyCode > digit9)) && (e.keyCode < digit0NumLock || e.keyCode > digit9NumLock)) {
        e.preventDefault();
    }
  }
}
