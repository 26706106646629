import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivationEnd, Router } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';

@Component({
  selector: 'search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  animations: [
    trigger('containerAnimation', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-out', style({ opacity: 0 }))
      ]),
      transition(':enter', [
        style({ opacity: 0.3}),
        group([
          animate('0.3s ease-out', style({ opacity: 1 })),
          query('@searchFieldAnimation', animateChild()),
        ]),
      ])
    ]),
    trigger('searchFieldAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        group([
          animate('0.5s 0.4s ease-out', style({ opacity: 1 })),
        ]),
      ])
    ]),
  ]
})
export class SearchFieldComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('searchField') searchField: ElementRef;

  fieldExpanded = false;
  searchInput = new UntypedFormControl('');
  searchPageUrl = this.config.ROUTING_CONSTANTS.SEARCH;
  returnUrl: string;
  budgetName = '';

  constructor(
    private readonly router: Router,
    private readonly config: Configuration,
    private readonly budgetDataService: BudgetDataService
  ) { }

  ngOnInit() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof ActivationEnd),
        map(event => event as ActivationEnd)
      )
      .subscribe(event => {
        if (event.snapshot.outlet !== this.config.ROUTER_OUTLETS.DETAILS &&
          event.snapshot.routeConfig.path !== this.config.ROUTING_CONSTANTS.SEARCH
        ) {
          this.collapseSearchField();
        }
      });

    this.budgetDataService.selectedBudget$
      .pipe(takeUntil(this.destroy$))
      .subscribe(budget => this.budgetName = budget ? budget.name : '');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAnimationDone() {
    this.searchField?.nativeElement?.focus();
  }

  toggleFieldView() {
    this.fieldExpanded = !this.fieldExpanded;
    this.searchInput.setValue('');
  }

  onSubmit() {
    const value = this.searchInput.value.trim();
    this.returnUrl = this.router.url;

    if (value) {
      this.router.navigate([this.searchPageUrl], { queryParams: { term: value }})
    }
  }

  closeSearch() {
    this.collapseSearchField();
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
      this.returnUrl = null;
    }
  }

  collapseSearchField() {
    this.fieldExpanded = false;
    this.searchInput.setValue('');
  }
}
