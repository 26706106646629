import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import ResizeObserver from 'resize-observer-polyfill';
import { fromEvent, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[scrollShadow]'
})
export class ScrollShadowDirective implements AfterViewInit, OnDestroy {
  private outerContainer: HTMLElement;
  private innerContent: HTMLElement;
  private header: HTMLElement;
  private scrollContainer: HTMLElement;
  private outerContainerObserver: ResizeObserver;
  private innerContainerObserver: ResizeObserver;
  private checkShadows$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  @Input('scrollShadow') scrollShadowOptions: {
    inner: HTMLDivElement;
    header: HTMLDivElement;
    scroll: CdkVirtualScrollViewport;
  }
  constructor(
    private element: ElementRef,
    private renderer2: Renderer2,
    private _zone: NgZone,
  ) {
  }

  ngAfterViewInit() {
    this.initSizeListeners();
  }

  initContainers() {
    this.outerContainer = this.element?.nativeElement;
    this.innerContent = this.scrollShadowOptions.inner;
    this.header = this.scrollShadowOptions.header;
    this.scrollContainer = this.scrollShadowOptions.scroll.elementRef?.nativeElement;
  }

  initSizeListeners() {
    this.initContainers();
    this._zone.runOutsideAngular(() => {
      this.outerContainerObserver = new ResizeObserver(() => {
        this.checkShadows$.next();
      });
      this.innerContainerObserver = new ResizeObserver(() => {
        this.checkShadows$.next();
      });

      setTimeout(() => {
        this.innerContainerObserver.observe(this.innerContent);
        this.outerContainerObserver.observe(this.element.nativeElement);
        fromEvent(this.scrollContainer, 'scroll' ).pipe(
          map(e => (e.target as HTMLElement).scrollTop),
          distinctUntilChanged(),
        ).subscribe(() => {
          this.checkShadows$.next();
        })
      }, 0);

      this.checkShadows$.pipe(
        takeUntil(this.destroy$),
      ).subscribe(() => this.updateShadows())
    })
  }

  updateShadows() {
    const cssClass = 'has-shadow';
    if (!this.outerContainer || !this.innerContent || !this.scrollContainer || !this.header) {
      return;
    }
    const outerHeight = this.outerContainer.offsetHeight;
    const innerHeight = this.innerContent.offsetHeight + this.header.offsetHeight;
    const scrollTop = this.scrollContainer.scrollTop;

    const diff = innerHeight - outerHeight;

    if (diff < 0) {
      this.renderer2.removeClass(this.header, cssClass);
      this.renderer2.removeClass(this.outerContainer, cssClass);
    } else {
      if (scrollTop === 0) {
        this.renderer2.removeClass(this.header, cssClass);
        this.renderer2.addClass(this.outerContainer, cssClass);
      } else if (scrollTop === diff) {
        this.renderer2.addClass(this.header, cssClass);
        this.renderer2.removeClass(this.outerContainer, cssClass);
      } else {
        this.renderer2.addClass(this.header, cssClass);
        this.renderer2.addClass(this.outerContainer, cssClass);
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    if (this.innerContainerObserver) {
      this.innerContainerObserver.unobserve(this.innerContent);
    }
    if (this.outerContainerObserver) {
      this.outerContainerObserver.unobserve(this.element.nativeElement);
    }
  }
}
