<div class="top-row">
  <span class="fiscal-year-label">{{ fiscalYearLabel }}</span>
  <link-button [disabled]="!selectedTimeframeIds?.length" (onClick)="clearSelection()">
    <span class="link-button-content">Clear selection</span>
  </link-button>
</div>
<timeframe-select
  [showLegend]="timeframeMode === budgetTimeframesType.Month"
  [isRange]="true"
  [selectedTimeframes]="selectedTimeframeIds"
  [timeframes]="timeframes"
  (selectionChanged)="onSelectionChange($event.item)"
  (quarterSelectionChanged)="onQuarterSelectionChange($event.id, $event.isSelected)"
></timeframe-select>
<div class="action-wrapper">
  <button class="mr-1" mat-button [mat-dialog-close]="[]" color="primary">
    Cancel
  </button>
  <button mat-flat-button color="primary" [disabled]="!selectedTimeframeIds?.length" (click)="submitConfig()">
    Apply
  </button>
</div>
