import { Component } from '@angular/core';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { DialogRef, ModalComponent } from 'ngx-modialog-7';

export class BulkUpdateModalContext extends BSModalContext {
  public message: string;
  public errors: { name: string; details: string }[];
}

@Component({
  selector: 'modal-content',
  templateUrl: './errors-modal.component.html',
  styleUrls: ['./errors-modal.component.scss']
})
export class ErrorsModalComponent implements ModalComponent<BulkUpdateModalContext> {
  context: any;

  constructor(public readonly dialog: DialogRef<BulkUpdateModalContext>) {
    this.context = this.dialog.context;
  }

  onClose() {
    this.dialog.close();
  }
}
