import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class handleMetricEventService {
  private methodCampaignSaveCallSource = new Subject<void>();
  private methodGoalSaveCallSource = new Subject<void>();
  handleSaveCampaignCalled$ = this.methodCampaignSaveCallSource.asObservable();
  handleSaveGoalCalled$ = this.methodGoalSaveCallSource.asObservable();

  callSaveCampaignMethod() {
    this.methodCampaignSaveCallSource.next();
  }
  callSaveGoalMethod() {
    this.methodGoalSaveCallSource.next();
  }
}