import { Injectable } from '@angular/core';
import { DetailsObjectStateMapper } from '../../types/budget-object-state-mapper.interface';
import { ProgramDetailsState } from '../../types/budget-object-details-state.interface';
import { ProgramDO } from 'app/shared/types/program.interface';
import { Configuration } from 'app/app.constants';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { CEGStatus } from '@shared/enums/ceg-status.enum';

@Injectable()
export class ProgramStateMapper implements DetailsObjectStateMapper {
  constructor(private configuration: Configuration) {}

  stateToDataObject(state: Partial<ProgramDetailsState>, isCurrentBudgetWithNewCEGStructure?: boolean): Partial<ProgramDO> {
    const dataObject: Partial<ProgramDO> = {};

    if (state.budgetId) {
      dataObject.budget = state.budgetId;
    }
    if (state.companyId) {
      dataObject.company = state.companyId;
    }
    if (state.segment && state.segment.segmentId) {
      dataObject.company_budget_segment1 = state.segment.segmentId;
      dataObject.split_rule = null;
      if (state.spreadSegmentToChildren) {
        dataObject.process_nested = state.spreadSegmentToChildren;
      }
    }
    if (state.segment && state.segment.sharedCostRuleId) {
      dataObject.split_rule = state.segment.sharedCostRuleId;
      dataObject.company_budget_segment1 = null;
      if (state.spreadSegmentToChildren) {
        dataObject.process_nested = state.spreadSegmentToChildren;
      }
    }

    if (state.hasOwnProperty('parentObject')) {
      dataObject.goal =
        state.parentObject && state.parentObject.type === this.configuration.OBJECT_TYPES.goal ?
          state.parentObject.id :
          null;

      dataObject.campaign =
        state.parentObject && state.parentObject.type === this.configuration.OBJECT_TYPES.campaign ?
          state.parentObject.id :
          null;
    }

    if (state.createdBy) {
      dataObject.created_by = state.createdBy;
    }
    if (state.ownerId) {
      dataObject.owner = state.ownerId;
    }
    if (state.typeId) {
      dataObject[isCurrentBudgetWithNewCEGStructure ? 'object_type' : 'program_type'] = state.typeId;
    }
    if (state.name) {
      dataObject.name = state.name;
    }
    if (state.hasOwnProperty('glCode')) {
      dataObject.gl_code = state.glCode;
    }
    if (state.hasOwnProperty('poNumber')) {
      dataObject.po_number = state.poNumber;
    }
    if (state.hasOwnProperty('notes')) {
      dataObject.note = state.notes;
    }
    if (state.hasOwnProperty('amount')) {
      dataObject.amount = state.amount;
    }
    if (state.hasOwnProperty('vendor')) {
      dataObject.vendor = state.vendor;
    }
    if (state.hasOwnProperty('amountStatus')) {
      dataObject.amount_status = state.amountStatus;
    }
    if (state.hasOwnProperty('currencyCode')) {
      dataObject.source_currency = state.currencyCode;
    }

    return dataObject;
  }

  dataObjectToState(dataObject: Partial<ProgramDO>): Partial<ProgramDetailsState> {
    return {
      objectId: dataObject.id || null,
      campaignId: dataObject.campaign || null,
      goalId: dataObject.goal || null,
      name: dataObject.name || null,
      amount: dataObject.amount || 0,
      allocations: dataObject.program_allocations || [],
      budgetId: dataObject.budget || null,
      companyId: dataObject.company || null,
      typeId: dataObject.program_type || dataObject.object_type || null,
      createdBy: dataObject.created_by || null,
      ownerId: dataObject.owner || null,
      glCode: dataObject.gl_code || null,
      poNumber: dataObject.po_number || '',
      notes: dataObject.note || '',
      mode: dataObject.mode || ObjectMode.Open,
      segment: {
        segmentId: dataObject.company_budget_segment1 || null,
        sharedCostRuleId: dataObject.split_rule || null
      },
      statusTotals: { ...dataObject.status_totals },
      attachmentMappings: dataObject.attachments || [],
      externalId: dataObject.external_id || '',
      created: dataObject.crd || '',
      updated: dataObject.upd || '',
      vendor: dataObject.vendor || null,
      sourceAmount: dataObject.program_allocations?.reduce((sum, alloc) => sum + alloc.source_amount, 0) || 0,
      currencyCode: dataObject.source_currency,
      amountStatus: dataObject.amount_status as CEGStatus,
      source: dataObject.source || '',
    }
  }
}
