<arrow-toggler
  *ngIf="!isSingleItem; else dot"
  [active]="!groupsOpenState[option.id]"
  (change)="toggleGroupView($event)"
  (click)="$event.stopPropagation()"
></arrow-toggler>
<ng-template #dot>
  <span class="dot-icon">
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.2" width="6" height="6" rx="3" fill="#0063B7"/>
    </svg>
  </span>
</ng-template>

<object-name
  [name]="option.title"
  [objectType]="option.objectType"
  [closed]="option.isClosed"
  [iconSize]="20"
></object-name>
