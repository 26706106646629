import { inject, Injectable } from '@angular/core';
import { ExpensesService } from '@shared/services/backend/expenses.service';
import { LightProgram } from '@shared/types/program.interface';
import { LightCampaign } from '@shared/types/campaign.interface';
import { forkJoin, Observable, of } from 'rxjs';
import { PlanObjectExpenses } from '../types/plan-object-expenses.interface';
import { map } from 'rxjs/operators';
import { PlanObjectExpensesData, SegmentExpensesData } from '@shared/types/plan-object-expenses-data.type';
import { ManageTableHelpers } from './manage-table-helpers';
import { BudgetPlanObjects } from '@shared/types/budget-plan-objects.type';


@Injectable()
export class ManagePageExpensesService {
  private readonly expensesService = inject(ExpensesService);

  public getPlanObjectExpenses$(
    budgetId: number,
    planObjects: BudgetPlanObjects,
    allPrograms, // A list of all budget programs (not split into pseudo objects if has SCR)
    allCampaigns // A list of all budget campaigns (not split into pseudo objects if has SCR)
  ): Observable<PlanObjectExpenses> {
    const programExpenses$ =
      planObjects.expGroups?.length ?
        this.expensesService.getTotalsByPrograms(budgetId) :
        of(null);

    const campaignExpenses$ =
      planObjects.campaigns?.length ?
        this.expensesService.getTotalsByCampaigns(budgetId) :
        of(null);

    return forkJoin([ programExpenses$, campaignExpenses$ ]).pipe(
      map(([programExpenses, campaignExpenses]) =>
        this.getGroupedExpenses(programExpenses || [], campaignExpenses || [], allPrograms, allCampaigns)
      )
    );
  }

  public loadSegmentExpensesData(budgetId: number): Observable<SegmentExpensesData> {
    return this.expensesService.getTotalsBySegmentsWithStatuses(budgetId);
  }

  private getGroupedExpenses(
    programExpenses: PlanObjectExpensesData,
    campaignExpenses: PlanObjectExpensesData,
    programs: LightProgram[],
    campaigns: LightCampaign[]
  ): PlanObjectExpenses {
    (programs || []).forEach(
      program => this.applyChildExpensesToParent(program.id, program.campaignId, programExpenses, campaignExpenses)
    );

    (campaigns || []).forEach(
      campaign => this.applyChildExpensesToParent(campaign.id, campaign.parentCampaign, campaignExpenses, campaignExpenses)
    );

    return { programs: programExpenses, campaigns: campaignExpenses };
  }

  private applyChildExpensesToParent(
    childId: number,
    parentId: number,
    childObjectExpenses: PlanObjectExpensesData,
    parentObjectExpenses: PlanObjectExpensesData
  ) {
    if (parentId && childObjectExpenses[childId]) {
      if (!parentObjectExpenses[parentId]) {
        parentObjectExpenses[parentId] = {};
      }
      const parentExpenses = parentObjectExpenses[parentId];
      const childExpenses = childObjectExpenses[childId];
      for (const segmentId of Object.keys(childExpenses)) {
        if (segmentId in parentExpenses) {
          this.addChildSegmentExpensesToParent(parentExpenses[segmentId], childExpenses[segmentId])
        } else {
          parentExpenses[segmentId] = { ...childExpenses[segmentId] };
        }
      }
    }
  }

  private addChildSegmentExpensesToParent(parentSegmentExpenses: SegmentExpensesData, childSegmentExpenses: SegmentExpensesData) {
    Object.keys(childSegmentExpenses).forEach(budgetAllocId => {
      parentSegmentExpenses[budgetAllocId] =
        ManageTableHelpers.sumTimeframeExpensesAmount(
          childSegmentExpenses[budgetAllocId],
          parentSegmentExpenses[budgetAllocId]
        );
    });
  }
}
