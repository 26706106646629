<sidebar-hierarchy-option
  class="hierarchy-parent-option"
  *ngFor="let option of hierarchyOptions; let i = index"
  [ngClass]="{
    'drop-allow': onDragOverItem[option?.id] === dropState.ALLOW,
    'drop-forbid': onDragOverItem[option?.id] === dropState.FORBID
  }"
  [option]="option"
  [segments]="segments"
  [sharedCostRules]="sharedCostRules"
  [searchQuery]="searchQuery"
  [isAllSelected]="isAllSelected"
  [hideGroupCount]="hideGroupCount"
  [expenseCount]="getOptionExpenseCount(option)"
  [plDroppable]="true"
  (dragover)="onHandleDragOver($event, option, i)"
  (dragleave)="onHandleDragLeave($event, option.id)"
  (dropOn)="onHandleDrop(option)"
  (toggleCollapsed)="onToggleCollapsed(i)"
  (toggleSelected)="onToggleSelected($event, i)"
></sidebar-hierarchy-option>
