<div class="card-container"
  [ngClass]="{ 'key-metric-container': isKeyMetric }"
  *ngIf="metric">
  <div class="card-inner">
    <heart-toggle
      *ngIf="keyMetricSelection"
      [isActive]="isKeyMetric"
      (click)="handleMetricChange(isKeyMetric, $event)"
      [matTooltip]="isKeyMetric ? 'Remove Key Metric' : 'Make a Key Metric' "
      matTooltipClass="dark-theme-tooltip above simple"
      matTooltipPosition="above"></heart-toggle>
    <div class="metric-performance-info">
      <metric-performance-data
        [isTabData]="false"
        [estimatedDiffPercentage]="estimatedDiffPercentage"
        [progressState]="progressState"></metric-performance-data>
    </div>
    <div class="metric-info">
      <div class="product-name-wrap">
        <span class="product-name"
          *ngIf="metric?.productName"
          [matTooltip]="metric.productName"
          matTooltipClass="dark-theme-tooltip above simple"
          [matTooltipDisabled]="metric.productName.length <= 20"
          matTooltipPosition="above">
          {{ metric.productName | truncate:20:'...' }}
        </span>
        <span
          *ngIf="isKeyMetric"
          class="key-metric-label"
          dynamicPortalTooltip
          [tooltipContext]="tooltipContext"
          [tooltipPosition]="tooltipPosition"
          [tooltipPositionCssClass]="'above'">Key metric</span>
      </div>
      <span class="metric-name"
        matTooltipClass="dark-theme-tooltip above multi-line"
        matTooltipPosition="above"
        [matTooltip]="metric.name"
        [matTooltipShowDelay]="500"
        >
        {{ metric.name | truncate:40:'...' }}
      </span>
    </div>
    <div class="metric-data">
      <ul class="metric-data-list" *ngIf="!isKeyMetric; else keyMetric">
        <ng-container *ngTemplateOutlet="defaultMetricParamsList"></ng-container>
      </ul>
    </div>
  </div>
</div>

<ng-template #keyMetric>
  <div class="key-metric-data">
    <div class="left-side">
      <ul class="metric-data-list">
        <li>
          <span class="name">Est. Campaign Value:</span>
          <span class="value" [ngStyle]="{ 'color': colorsMap[progressState] }"><span class="currency text-opacity" *ngIf="estimatedCampaignValue != null; else naTemplate">{{ currency }}</span> {{ estimatedCampaignValue | number: decimalDisplayFormat }}</span>
        </li>
        <li>
          <span class="name">Target Campaign Value:</span>
          <span class="value"><span class="currency" *ngIf="targetCampaignValue !== null; else naTemplate">{{ currency }}</span> {{ targetCampaignValue | number: decimalDisplayFormat }}</span>
        </li>
        <li>
          <span class="name">Target CPO:</span>
          <span class="value" *ngIf="targetValue; else naTemplate"><span class="currency text-opacity">{{ currency }}</span> {{ targetCPO | number: decimalDisplayFormat }}</span>
        </li>
        <li>
          <span class="name">Target {{ metric?.productRevenueToProfit ? 'ROI' : 'RROI' }}:</span>
          <span class="value" *ngIf="objectBudget; else naTemplate">{{ targetROI | precisionNumberFormat }}<span class="text-opacity"> x</span></span>
        </li>
      </ul>
    </div>
    <div class="right-side">
      <ul class="metric-data-list">
        <ng-container *ngTemplateOutlet="defaultMetricParamsList"></ng-container>
      </ul>
    </div>
  </div>
</ng-template>
<ng-template #naTemplate>N/A</ng-template>
<ng-template #defaultMetricParamsList>
  <li>
    <span class="name">Value:</span>
    <span class="value" [ngStyle]="{ 'color':colorsMap[progressState] }"><span class="currency text-opacity" *ngIf="metric.withCurrency">{{ currency }}</span> {{ currentValue | number: numberDisplayFormat }}</span>
  </li>
  <li>
    <span class="name">Target:</span>
    <span class="value"><span class="currency" *ngIf="metric.withCurrency">{{ currency }}</span> {{ targetValue | number: numberDisplayFormat }}</span>
  </li>
  <li>
    <span class="name">CPO:</span>
    <span class="value" *ngIf="currentValue; else naTemplate"><span class="currency text-opacity">{{ currency }}</span> {{ currentCPO | number: decimalDisplayFormat }}</span>
  </li>
  <li>
    <span class="name">{{ metric?.productRevenueToProfit ? 'ROI' : 'RROI' }}:</span>
    <span class="value"  *ngIf="actualSpend; else naTemplate">{{ currentROI | precisionNumberFormat }}<span class="text-opacity"> x</span></span>
  </li>
</ng-template>
