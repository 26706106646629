<article class="marketing-calendar box-wapper box-wapperWidget box-newTopNav new-nav">
  <page-header pageName="Marketing Calendar">
    <fa-icon slot="icon" [icon]="['fas', 'calendar-days']"></fa-icon>
    <div slot="name">
      <div class="fiscal-year-label">{{ budgetFiscalYearLabel }}</div>
      <div class="today-label">{{ todayDateLabel }}</div>
    </div>
    <div slot="custom-items">
      <div class="calendar-actions">
        <add-object-link *ngIf="editPermission" text="Add Campaign" [handler]="addCampaign"></add-object-link>
        <mat-form-field appearance="fill" class="no-label no-validate">
          <mat-select disableOptionCentering panelClass="mat-select-fixed-position" (valueChange)="onChangeSortingType($event)" [value]="sortingType">
            <mat-option *ngFor="let item of sortingTypeSelectOptions" [value]="item.value">
              {{item.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </page-header>
  <div class="box-rightSide" [ngClass]="{'section-removeBlue' : !budgetTimeframeList.length && showEmptyMsg}" #containerRef>
    <section class="calendar-main-view">
      <calendar-header [labels]="monthsLabels" [active]="currentMonth"></calendar-header>
      <section class="calendar-grid">
        <div class="highlighting allocations-grid">
          <div *ngFor="let item of monthsLabels; let i = index" class="highlight-item" [ngClass]="{'active': item.id === currentMonth, 'lighter':i < 3 || i > 5 && i < 9 }">
            <span *ngIf="i > 0" class="separator"></span>
            <span *ngIf="item.id === currentMonth" class="timeline" [style.left.%]="todayPercentage"></span>
            <span *ngIf="item.year < monthsLabels[i+1]?.year" class="year-separator"></span>
          </div>
        </div>
        <div class="calendar-events">
          <app-calendar-event
            *ngFor="let eventObject of calendarEvents"
            [eventObject]="eventObject"
            [budgetStartDate]="budgetStartDate"
            [budgetEndDate]="budgetEndDate"
            (mouseenter)="displayTooltip($event, eventObject)"
            (mouseleave)="hideTooltip()"
          ></app-calendar-event>
          <app-calendar-placeholder *ngIf="calendarEvents && calendarEvents.length === 0" [editPermission]="editPermission"></app-calendar-placeholder>
        </div>
      </section>
      <event-tooltip
        [metricTypes]="metricTypes"
        [currencySymbol]="currencySymbol"
        [trigger$]="tooltipTrigger"
      ></event-tooltip>
    </section>
  </div>
</article>

