import { LocationHierarchyParams, LocationService } from 'app/budget-object-details/services/location.service';
import { Component, ElementRef, EventEmitter, HostBinding, HostListener, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { messages, objectPlaceholderName } from 'app/budget-object-details/messages';
import { Configuration } from 'app/app.constants';
import { UtilityService } from '@shared/services/utility.service';
import { UserDataService } from '@shared/services/user-data.service';
import { DetailsAction } from '../details-header/details-header.type';
import { BudgetObjectCloneResponse } from '@shared/types/budget-object-clone-response.interface';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { Goal } from '@shared/types/goal.interface';
import { CampaignDO, LightCampaign } from '@shared/types/campaign.interface';
import { LightProgram, ProgramDO } from '@shared/types/program.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SegmentGroup } from '@shared/types/segment-group.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { CompanyDataService, GLCode, Vendor } from '@shared/services/company-data.service';
import { Budget } from '@shared/types/budget.interface';
import { ObjectMode } from '@shared/enums/object-mode.enum';
import { BudgetObjectDialogService } from '@shared/services/budget-object-dialog.service';
import { getTodayFixedDate } from '@shared/utils/budget.utils';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { CompanyDO } from '@shared/types/company.interface';
import { ExtendedUserDO } from '@shared/types/user-do.interface';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { HierarchySelectConfig, HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { UserManager } from 'app/user/services/user-manager.service';
import { SegmentMenuHierarchyService } from '@shared/services/segment-menu-hierarchy.service';
import { DetailsDrawerFormComponent, DrawerFormFields } from './details-drawer-form';
import { Attachment } from '@shared/types/attachment.interface';
import { SelectOption } from '@shared/types/select-option.interface';
import { DataValidationService } from 'app/budget-object-details/services/data-validation.service';
import { BudgetObjectCreationContext } from 'app/budget-object-details/types/details-creation-context.interface';
import { MatSelectChange } from '@angular/material/select';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { CurrencyDO } from '@shared/services/backend/company-currency.service';
import { BudgetObjectChangeEvent } from 'app/budget-object-details/types/budget-object-change.interface';
import { BudgetObjectDetailsService } from 'app/budget-object-details/types/budget-object-details-service.interface';
import { BudgetObjectTagsService } from 'app/budget-object-details/services/budget-object-tags.service';
import { BudgetObjectMetricsService } from 'app/budget-object-details/services/budget-object-metrics.service';
import { BudgetObjectAttachmentsService } from 'app/budget-object-details/services/budget-object-attachments.service';
import { ObjectHierarchyService } from 'app/budget-object-details/services/object-hierarchy.service';
import { BudgetObjectOwnersService } from 'app/budget-object-details/services/budget-object-owners.service';
import {
  BudgetObjectDetailsState,
  BudgetObjectParent,
  ObjectDetailsCommonState
} from 'app/budget-object-details/types/budget-object-details-state.interface';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { MetricMappingDO } from '@shared/services/backend/metric.service';
import { ObjectDetailsTabsDataService } from 'app/budget-object-details/services/object-details-tab-data.service';
import { ObjectDetailsTabControl } from 'app/budget-object-details/types/object-details-tab-control-type.interface';
import { ManageTableBudgetColumnName, ManageTableTotalValues } from '@manage-ceg/types/manage-ceg-page.types';
import { CampaignAllocation, ProgramAllocation } from '@shared/types/budget-object-allocation.interface';
import { TaskStatusName } from '@shared/types/task.interface';
import { DatePipe } from '@angular/common';
import { PerformanceData } from 'app/manage-table/types/performance-column-data.type';
import { DrawerStackService, DrawerType } from '../../services/drawer-stack.service';
import { BudgetObjectActionsShared } from '../../services/budget-object-actions-shared';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { getParentFromLocation } from '@shared/utils/location.utils';
import { SegmentDataInheritanceService } from '@shared/services/segment-data-inheritance.service';
import { SegmentDataInheritanceAction } from '@shared/types/segment-data-inheritance.interface';
import { BudgetAllocationsTableEvent } from '../budget-allocations-table/budget-allocations-table.type';
import { getParentObject } from '../../utils/object-details.utils';
import { MetricUpdateAction } from '../../types/metric-update-action.enum';
import { UpdateMetricData } from '../../services/metric-update.service';
import { Metric } from '../details-metrics/details-metrics.type';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { handleMetricEventService } from '../../services/handle-metric-event.service';
import { MetricDetailsDrawerComponent } from './metric-details-drawer/metric-details-drawer.component';

@Component({
  template: ''
})
export abstract class DetailsDrawerBaseComponent<ObjectDetailsState extends BudgetObjectDetailsState>
  extends DetailsDrawerFormComponent implements OnInit, OnChanges, OnDestroy {

  protected readonly activatedRoute = inject(ActivatedRoute);
  protected readonly appRoutingService = inject(AppRoutingService);
  protected readonly configuration = inject(Configuration);
  protected readonly companyDataService = inject(CompanyDataService);
  protected readonly budgetDataService = inject(BudgetDataService);
  protected readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  protected readonly utilityService = inject(UtilityService);
  protected readonly userDataService = inject(UserDataService);
  protected readonly dialogManager = inject(BudgetObjectDialogService);
  protected readonly tagsManager = inject(BudgetObjectTagsService);
  protected readonly metricsManager = inject(BudgetObjectMetricsService);
  protected readonly attachmentsManager = inject(BudgetObjectAttachmentsService);
  protected readonly userManager = inject(UserManager);
  protected readonly segmentMenuService = inject(SegmentMenuHierarchyService);
  protected readonly locationService = inject(LocationService);
  protected readonly hierarchyService = inject(ObjectHierarchyService);
  protected readonly tabsDataService = inject(ObjectDetailsTabsDataService);
  protected readonly segmentDataInheritanceService = inject(SegmentDataInheritanceService);
  protected readonly elementRef = inject(ElementRef);
  public readonly datePipe = inject(DatePipe);
  private readonly drawerStackService = inject(DrawerStackService);
  readonly handleDrawerSaveEvent = inject(handleMetricEventService);
  readonly router = inject(Router);

  protected abstract objectDetailsService: BudgetObjectDetailsService<ObjectDetailsState>;

  protected isPowerUser = false;
  protected currentCompanyUser: CompanyUserDO;

  public isReadOnlyMode = true;
  public editPermission = false;
  public objectType: string;
  protected objectLabel: string;
  public prevState: ObjectDetailsCommonState = null;
  public currentState: ObjectDetailsCommonState;

  protected menuActions: DetailsAction[] = [];
  protected parentCampaignIsCommitted: boolean;

  @Input() objectId: number;
  @Input() drawerType: DrawerType;

  @HostBinding('class.active')
  @Input() isActive: boolean;

  private _isLoading = false;

  protected company: CompanyDO;
  protected companyId: number;
  protected budget: Budget;
  protected budgetId: number;
  protected budgetTodayDate: Date;

  protected currencyList: CurrencyDO[];

  protected isDeleteActionObject = { value: false };

  protected companyCurrency: {
    symbol: string;
    code: string;
  };

  protected readonly objectLabels = {
    [this.configuration.OBJECT_TYPES.goal]: 'Goal',
    [this.configuration.OBJECT_TYPES.campaign]: 'Campaign',
    [this.configuration.OBJECT_TYPES.expense]: 'Expense',
    [this.configuration.OBJECT_TYPES.program]: 'Expense Group',
    [this.configuration.OBJECT_TYPES.metric]: 'Metric'
  };

  protected cegStatusEnabled: boolean;
  public unsavedChangesFlag = false;

  protected hasExternalIntegrationType = false;
  /* objects store links */
  protected budgets: Budget[] = [];

  protected companyUsers: ExtendedUserDO[] = [];
  protected budgetTimeframes: BudgetTimeframe[] = [];
  protected goals: Goal[] = [];
  protected campaigns: LightCampaign[] = [];
  protected programs: LightProgram[] = [];
  protected segments: BudgetSegmentAccess[];
  protected segmentGroups: SegmentGroup[];
  protected sharedCostRules: SharedCostRule[] = [];
  protected allowedSharedCostRules: SharedCostRule[];
  protected glCodes: GLCode[] = [];
  protected vendors: Vendor[] = [];
  protected autocompleteVendors: Observable<Vendor[]>;
  protected creationContext: BudgetObjectCreationContext;
  /* objects store links */

  protected ownerOptions: SelectOption[] = [];

  protected locationItems: HierarchySelectItem[] = [];
  protected segmentSelectItems: HierarchySelectItem[] = [];
  protected allowedSegmentSelectItems: HierarchySelectItem[] = [];

  protected readonly reset$ = new Subject<void>();
  protected readonly destroy$ = new Subject<void>();

  protected readonly ObjectMode = ObjectMode;
  protected readonly DrawerType = DrawerType;


  protected headerBackgroundClass = this.configuration.detailsHeaderClass;

  protected selectSegmentsConfig: HierarchySelectConfig = {
    fieldLabel: 'Segment *',
    withSearch: true,
    emptyValueLabel: null,
    searchPlaceholder: 'Search Segments',
    allGroups: false,
    allPlural: 'Segments',
    errorMsg: 'Segment is required',
    fieldAppearance: 'outline'
  };

  ObjectDetailsTabControl = ObjectDetailsTabControl;
  protected activeTabId: ObjectDetailsTabControl;
  protected allocationTotals: Partial<ManageTableTotalValues>;

  private readonly parentObjectProviders = {
    [this.configuration.OBJECT_TYPES.program]:
      (objectId: number) =>
        this.budgetObjectDetailsManager.getLightPrograms().pipe(
          take(1),
          map(programs => {
            const targetProgram = programs.find(program => program.id === objectId);
            return targetProgram ? getParentObject(this.configuration, targetProgram.goalId, targetProgram.campaignId) : null;
          })
        ),
    [this.configuration.OBJECT_TYPES.campaign]:
      (objectId: number) =>
        this.budgetObjectDetailsManager.getLightCampaigns().pipe(
          take(1),
          map(campaigns => {
            const targetCampaign = campaigns.find(campaign => campaign.id === objectId);
            return targetCampaign ? getParentObject(this.configuration, targetCampaign.goalId, targetCampaign.parentCampaign) : null;
          })
        )
  };

  @Output() dataLoaded = new EventEmitter<boolean>(); // TODO: move dataLoaded subject to Drawers state service (should be created later)
  resetFormAndFetchCustomField: boolean;

  private static tabStorageKey(objectType: string): string {
    return 'last_opened_drawer_tab_for_' + objectType;
  }

  public static getStoredActiveTab(objectType: string): ObjectDetailsTabControl {
    return LocalStorageService.getFromStorage(DetailsDrawerBaseComponent.tabStorageKey(objectType))
      || ObjectDetailsTabControl.Details;
  }

  protected abstract initObjectCreation(): void;
  protected abstract loadObjectDetails(id: number): void;
  protected abstract updateMenuActions(): void;
  protected onObjectModeUpdated(item: ProgramDO | CampaignDO): void {}
  protected abstract formDataToState(): Partial<ObjectDetailsState>;
  public abstract saveChanges(onSavedCb: () => void, runInBackground?: boolean): void;
  protected abstract getContextForNewObjectCreation(): BudgetObjectCreationContext;
  protected abstract initHierarchy(state: ObjectDetailsCommonState): void;
  protected abstract checkTagsLeftover(): void;

  @HostListener('click', ['$event'])
  onDrawerClick(event): void {
    if (!this.isActive) {
      event.preventDefault();
      event.stopPropagation();
      this.appRoutingService.goToParentDrawer(this.drawerType, this.objectId);
    }
  }

  protected constructor() {
    super();
  }

  ngOnInit() {
    this.activeTabId = DetailsDrawerBaseComponent.getStoredActiveTab(this.objectType);
    this.createForm();
    this.initDrawer(false);
    this.onInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.objectId !== undefined && !changes.objectId.firstChange) {
      // objectId was changed for current drawer from DrawerStackService:
      //  1) null --> number - for creating new object; (skip resetDetails and initDrawer) save only
      //  2) number --> null - for creating new object; (reset) save&new for existing object
      //  3) number --> another number - for showing details of another object; (reset) show details after cloning
      if (changes.objectId.previousValue !== null) {
        this.initDrawer(true);
      }
    }
  }

  protected setObjectType(objectType: string): void {
    this.objectType = objectType;
    this.objectLabel = this.objectLabels[objectType] || '';
    if (!this.objectLabel) {
      console.warn('Missing label for', this.objectType);
    }
  }

  protected get objectTypeAsText(): string {
    return (this.objectLabel || this.objectType).toLowerCase();
  }

  private initDrawer(resetDetails: boolean): void {
    if (resetDetails) {
      this.resetDetails();
      this.resetFormAndFetchCustomField = true
    }
    if (!this.objectId) {
      this.showDetailsTab();
      this.initObjectCreation();
    } else {
      this.loadObjectDetails(this.objectId);
    }

    this.userDataService.editPermission$
      .pipe(takeUntil(this.destroy$))
      .subscribe((editPermission: boolean) => {
        this.editPermission = editPermission;
        this.updateReadOnlyModeState();
      });
  }

  protected loadBudgetAndCompanyData$(): Observable<[number, number]> {
    return combineLatest([
        this.budgetObjectDetailsManager.getCompanyId().pipe(
          tap(companyId => this.companyId = companyId)
        ),
        this.budgetObjectDetailsManager.getCurrentBudget().pipe(
          tap(budget => {
            this.budget = budget;
            this.budgetId = budget.id;
            this.budgetTodayDate = getTodayFixedDate(budget);
            this.cegStatusEnabled = !!budget.new_campaigns_programs_structure;
            this.onBudgetChanged(budget);
          }),
          map(budget => this.budgetId = budget.id))
      ]);
  }

  protected checkStateConsistencyAndAccess(state: ObjectDetailsCommonState): void {
    this.budgetObjectDetailsManager.checkObjectBudgetStateConsistency({
      state,
      budgetId: this.budgetId
    });
    this.budgetObjectDetailsManager.checkObjectAccess({
      segmentDO: {
        split_rule: state.segment && state.segment.sharedCostRuleId,
        company_budget_segment1: state.segment && state.segment.segmentId,
      },
      segments: this.segments,
      rules: this.sharedCostRules,
      onDenyCb: () => {
        this.appRoutingService.closeActiveDrawer();
        this.destroy$.next();
        this.destroy$.complete();
      },
      objectTypeLabel: this.objectLabel,
      closeDetails: false
    });
  }

  protected onBudgetChanged(budget: Budget): void {
    // default implementation
  }

  protected setCreatedBy(state: ObjectDetailsCommonState): void {
    if (this.currentCompanyUser?.user) {
      state.createdBy = this.currentCompanyUser.user;
    }
  }

  protected detectAddedParentObject(objectDetailsState: ObjectDetailsCommonState): void {
    merge(
      this.budgetDataService.goalList$.pipe(tap(goals => this.goals = goals)),
      merge(this.budgetDataService.lightCampaignList$, this.budgetDataService.campaignList$).pipe(
        tap(campaigns => this.campaigns = campaigns)
      ),
      merge(this.budgetDataService.lightProgramList$, this.budgetDataService.programList$).pipe(
        tap(programs => this.programs = programs)
      )
    ).pipe(
      takeUntil(merge(this.destroy$, this.reset$)),
      take(1)
    ).subscribe(() => this.applyParentObjects(objectDetailsState));
  }

  protected applyParentObjects(objectDetailsState: ObjectDetailsCommonState): void {
    this.initHierarchy(objectDetailsState);
    this.defineParent(objectDetailsState);
    this.updateLocationOptions();
  }

  protected defineParent(state: ObjectDetailsCommonState): void {
    // default implementation
  }

  protected updateLocationOptions(): void {
    // default implementation
  }

  protected resetDetails(): void {
    this.reset$.next();
    this.formData.reset();
    this.prevState = null;
    this.currentState = null;
  }

  protected onCreateObject(drawerType: DrawerType): void {
    this.syncUnsavedChangesFlag();

    setTimeout(() => {
      const contextData = this.getContextForChildObjectCreation();
      this.appRoutingService.openChildDrawerCreation(drawerType, contextData);
    });
  }

  protected openMetric(metric: number): void {
    this.syncUnsavedChangesFlag();

    setTimeout(() => {
      let navigate = this.appRoutingService.metricDetailsByObjectType[this.objectType];
      if (navigate) {
        const changesDiscarded = new Subject<void>();
        if (this.unsavedChangesFlag && !(this.cegStatusEnabled)) {
          this.dialogManager.openUnsavedChangesDialog(
            () => {
              if(this.objectType.toLowerCase() === 'campaign') {
                this.handleDrawerSaveEvent.callSaveCampaignMethod();
              }
              else if(this.objectType.toLowerCase() === 'goal') {
                this.handleDrawerSaveEvent.callSaveGoalMethod();
              }
              navigate(metric, this.router.url);
            },
            () => {
              changesDiscarded.next();
              navigate(metric, this.router.url);
            }
            );
          } else {
            navigate(metric, this.router.url);
          }
        }
      });
  }

  protected getContextForChildObjectCreation(): BudgetObjectCreationContext {
    return BudgetObjectActionsShared.getContextForChildObjectCreation(this.objectType, this.currentState);
  }

  protected setActiveTab(id: ObjectDetailsTabControl): void {
    LocalStorageService.addToStorage(DetailsDrawerBaseComponent.tabStorageKey(this.objectType), id);
    this.activeTabId = id;
  }

  protected updateReadOnlyModeState(): void {
    this.isReadOnlyMode = !this.hasEditPermissions || !this.isObjectOpen;
    this.updateMenuActions();
    if (this.isReadOnlyMode) {
      this.formData.disable();
    } else {
      this.formData.enable();
    }
    this.onEditPermissionsChanged();
  }

  protected handleParentSelectionChange(location: string) {
    const prevLocation = this.locationService.defineLocationValue(this.currentState.parentObject);
    const prevLocationId = this.currentState.parentObject?.id;

    this.fdLocationControl.setValue(location);
    this.inheritParentAmountStatus(getParentFromLocation(location)?.id);
    this.handleSegmentOnLocationChange(
      location,
      () => {
        this.formData.patchValue({ [DrawerFormFields.location]: prevLocation });
        this.inheritParentAmountStatus(prevLocationId);
      }
    );
  }

  private handleSegmentOnLocationChange(location: string, onCancel: () => void): void {
    const formData = this.formData.value;
    const onReplace = (parentSegmentData) => {
      const segmentSelectItem = this.budgetObjectDetailsManager.segmentedValueToSelectItem(parentSegmentData, this.segmentSelectItems);
      this.formData.patchValue({ segment: segmentSelectItem });
      // If we apply new parent's segment -> then we should 'spreadSegmentToChildren' implicitly
      this.currentState.spreadSegmentToChildren = true;
      this.handleSegmentChanged(segmentSelectItem);
    };

    this.budgetObjectDetailsManager.syncSegmentsOnLocationUpdate({
      objectType: this.objectType,
      campaigns: this.campaigns,
      segment: formData.segment,
      location,
      onCancel,
      onReplace
    });
  }

  protected handleSegmentChanged(selectedItem: HierarchySelectItem): void {
    // default implementation
  }

  protected inheritParentAmountStatus(parentId: number): void {
    const parent = parentId ? this.campaigns.find(camp => camp.id === parentId) : null;
    this.parentCampaignIsCommitted = parent?.amountStatus === CEGStatus.COMMITTED;
    if (this.parentCampaignIsCommitted && this.fdAmountStatus !== CEGStatus.COMMITTED) {
      this.fdAmountStatusControl.setValue(CEGStatus.COMMITTED);
    }
  }

  /* METRICS */
  protected addMetricMappings(metricMappings: MetricMappingDO[]): void {
    const newMappings = metricMappings.map(mappingDO => this.metricsManager.convertDataObjectToMapping(mappingDO));
    this.currentState.metricMappings = [...this.currentState.metricMappings, ...newMappings];
  }

  get hasEditPermissions(): boolean {
    return this.editPermission;
  }

  protected get isObjectOpen(): boolean {
    // default implementation
    return true;
  }

  protected onEditPermissionsChanged(): void {
    // default implementation
  }

  protected defineCompanyCurrency(): void {
    this.companyCurrency = {
      symbol: this.company.currency_symbol,
      code: this.company.currency
    };
  }

  protected objectCurrencyChanged(currencyCode: string): void {
    this.currentState.currencyCode = currencyCode;
    this.loadCurrencyExchangeRates(currencyCode, this.onCurrencyExchangeRatesUpdated.bind(this));
  }

  protected loadCurrencyExchangeRates(currencyCode: string, callback?: () => void): void {
     const updateRates$ = this.budgetObjectDetailsManager.isRateLoaded(currencyCode) ?
       of(null) :
       this.budgetObjectDetailsManager.updateExchangeRatesForCurrency(this.companyId, currencyCode, this.budgetTimeframes.map(tf => tf.id));

    updateRates$.subscribe(() => callback && callback());
  }

  protected onCurrencyExchangeRatesUpdated(): void {
    // default implementation
  }

  protected onSuccess(message: string, close: boolean, ranInBackground = false) {
    if (!ranInBackground) {
      this.hideLoader();
      this.utilityService.showToast({ Type: 'success', Message: message });
    }
    if (close) {
      this.handleCancelAction();
    }
  }

  protected handleCancelAction(): void {
    this.appRoutingService.closeActiveDrawer();
  }

  protected onError(error, message, close = false) {
    this.hideLoader();
    this.budgetObjectDetailsManager.handleError(error, message, close);
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  protected showLoader(): void {
    this._isLoading = true;
  }

  protected hideLoader(): void {
    // A workaround needed for e2e tests driver to catch loader's disappearing
    setTimeout(() => {
      this._isLoading = false;
      // TODO: move dataLoaded subject to Drawers state service (should be created later)
      this.dataLoaded.emit(true);
    });
  }

  protected syncUnsavedChangesFlag(flag?: boolean): void {
    this.unsavedChangesFlag = typeof flag === 'boolean' ? flag : this.hasUnsavedChanges();
  }

  public hasUnsavedChanges(): boolean {
    if (!this.currentState || this.isDeleteActionObject.value) {
      return false;
    }
    // TODO: WE SHOULDN'T SAVE FORM DATA DURING CHECK UNSAVED CHANGES
    if(this.currentState.metricId) {
      // We are in metric Drawer so check only for Unsaved Metric Changes
      return (this as unknown as MetricDetailsDrawerComponent).hasUnSavedMetricChanges();

    }else {
      this.saveFormData();
      return this.objectDetailsService.hasChanges(
        this.prevState as unknown as ObjectDetailsState,
        this.currentState as unknown as ObjectDetailsState,
      );
    }
  }

  protected saveFormData(): void {
    const formDataState = this.formDataToState();
    Object.keys(formDataState).forEach(key => {
      this.currentState[key] = formDataState[key];
    });
  }

  protected onObjectClone(onSuccess: (id: number) => void, checkAndClone$?: Observable<BudgetObjectCloneResponse>): void {
    const clone$ = checkAndClone$ ? checkAndClone$ : this.createCloneObjectRequest$();

    this.budgetObjectDetailsManager.cloneObject(clone$, this.objectLabels[this.objectType])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => onSuccess(data?.id),
        error: err => this.onError(err, messages.UNABLE_TO_CLONE_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText))
      });
  }

  protected createCloneObjectRequest$(): Observable<BudgetObjectCloneResponse> {
    return this.objectDetailsService.cloneObject(this.objectId)
      .pipe(
        tap(() =>
          this.budgetObjectDetailsManager.reportDrawerDetailsChange(
            this.objectId,
            this.objectType,
            BudgetObjectChangeEvent.AttachmentsChanged
          )
        )
      );
  }

  protected handleClose(title: string): void {
    this.dialogManager.openConfirmationDialog({
        title,
        content: messages.CLOSE_OBJECT_MSG,
        submitAction: {
          label: 'OK',
          handler: this.closeObject.bind(this),
        },
        cancelAction: {
          label: 'Cancel',
          handler: null
        }
      },
      {
        width: '480px'
      });
  }

  private closeObject(): void {
    this.showLoader();
    this.objectDetailsService.closeObject(this.objectId).subscribe({
      next: item => this.onObjectModeUpdated(item),
      error: error => this.onError(error, messages.UNABLE_TO_REOPEN_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText)),
      complete: () => this.hideLoader()
    });
  }

  protected handleOpen(): void {
    this.showLoader();
    this.objectDetailsService.reopenObject(this.objectId).subscribe({
      next: item => this.onObjectModeUpdated(item),
      error: error => this.onError(error, messages.UNABLE_TO_REOPEN_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText)),
      complete: () => this.hideLoader()
    });
  }

  protected loadTypesErrorCb(error) {
    this.onError(
      error,
      messages.UNABLE_TO_LOAD_OBJECT_TYPES_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText),
      true);
  }

  protected updateSegmentSelectItems(): void {
    if (!this.segments || !this.allowedSharedCostRules || !this.segmentGroups) {
      return;
    }
    this.segmentSelectItems = this.segmentMenuService.prepareDataForSegmentMenu({
      segments: this.segments,
      groups: this.segmentGroups,
      rules: this.allowedSharedCostRules,
    });
  }
  public filterIntegratedItems(items) {
    return items.map(obj => {
        if (obj.children && obj.children.length > 0) {
            obj.children = this.filterIntegratedItems(obj.children);
        }
        if (!('campaign_integrated_source' in obj) || !obj.campaign_integrated_source) {
            return obj;
        }
    }).filter(Boolean);
}

  protected setLocationOptions(
    params: LocationHierarchyParams,
    currentLocationValue: string,
    singleLast: boolean,
    disableSegmentlessCampaigns: boolean
  ): void {
    const { flatItemIdsList, items } = this.locationService.createLocationHierarchyItems(params, singleLast, disableSegmentlessCampaigns);
    // to remove items with campaign_integrated_source = true
    if(this.drawerType==="expense"){
      this.locationItems = items.filter(item => !item.notClickable || item.children.length);
    }
    else{      
      let filteredArray = this.filterIntegratedItems(items);
      this.locationItems = filteredArray.filter(item => !item.notClickable || item.children.length);
    }

    // Reset current location if it's out of the list of locations now:
    if (currentLocationValue && !flatItemIdsList.includes(currentLocationValue)) {
      this.fdLocationControl.setValue(null);
    }
  }

  protected loadAttachments(state: ObjectDetailsCommonState): void {
    this.attachmentsManager.setObjectContext({
      objectId: state.objectId,
      objectType: this.objectType,
      companyId: this.companyId
    });
    this.attachmentsManager.loadAttachments(state.attachmentMappings)
      .subscribe({
        error: (err) => this.onError(err, '')
      });
  }

  protected handleFileAttached($event): void {
    this.attachmentsManager.uploadFiles($event.target.files)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.budgetObjectDetailsManager.reportDrawerDetailsChange(
            this.objectId,
            this.objectType,
            BudgetObjectChangeEvent.AttachmentsChanged
          );
          this.syncAttachments();
        },
        error: (err) => this.onError(err, ''),
        complete: () => $event.target.value = null
      });
  }

  protected handleFileDelete(attachment: Attachment): void {
    this.dialogManager.openDeleteEntityDialog(() => {
      this.attachmentsManager.deleteFile(attachment)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.budgetObjectDetailsManager.reportDrawerDetailsChange(
              this.objectId,
              this.objectType,
              BudgetObjectChangeEvent.AttachmentsChanged
            );
            this.syncAttachments();
          },
          error: (err) => this.onError(err, '')
        });
    }, 'file');
  }

  protected handleFileDownload(attachment: Attachment): void {
    this.attachmentsManager.downloadFile(attachment)
      .pipe(take(1))
      .subscribe({
        error: (err) => this.onError(err, '')
      });
  }

  private syncAttachments(): void {
    this.currentState.attachmentMappings = [...this.attachmentsManager.attachments];
  }

  protected updateOwnerOptions(segmentId: number, sharedCostRuleId: number): void {
    this.ownerOptions = BudgetObjectOwnersService.getOwnerOptions(
      this.budgetId,
      segmentId,
      sharedCostRuleId,
      this.currentState.ownerId,
      this.companyUsers,
      this.sharedCostRules,
    );
  }

  protected defineAllowedSegments(ownerId: number): void {
    this.allowedSegmentSelectItems = BudgetObjectOwnersService.getAllowedSegmentOptions({
      ownerId,
      budgetId: this.budgetId,
      sharedCostRules: this.sharedCostRules,
      segmentSelectItems: this.segmentSelectItems,
      companyUsers: this.companyUsers
    }, this.configuration.OBJECT_TYPES);
  }

  protected handleOwnerChange(change: MatSelectChange): void {
    const ownerValue = change.value;
    this.defineAllowedSegments(ownerValue);
  }

  protected submitChanges(submitCallback: () => void): void {
    this.submitForm(submitCallback, this.highlightNotValidFields.bind(this));
  }

  protected submitForm(submitCallback: () => void, notValidCallback?: () => void): void {
    const isFormValid = this.validateChanges();
    const cb = isFormValid ? submitCallback : notValidCallback;
    if (cb && typeof cb === 'function') {
      cb();
    }
  }

  protected showDetailsTab(): void {
    this.activeTabId = ObjectDetailsTabControl.Details;
  }

  protected showAllocationTab(): void {
    this.activeTabId = ObjectDetailsTabControl.Allocation;
  }

  public highlightNotValidFields(): void {
    this.showDetailsTab();
    setTimeout(() => {
      this.shakeInvalidField();
    }, 700);
  }

  private shakeInvalidField(): void {
    const invalidField: HTMLElement = this.elementRef.nativeElement.getElementsByClassName('mat-form-field-invalid')?.[0];
    if (!invalidField) {
      return;
    }
    invalidField.scrollIntoView({ block: 'center' });
    const animationClass = 'form-control-shake';
    invalidField.classList.add(animationClass);
    setTimeout(() => {
      invalidField.classList.remove(animationClass);
    }, 1000);
  }

  protected checkParentChange(): void {
    const shouldCloseStack = !!this.prevState && this.prevState?.parentObject?.id !== this.currentState?.parentObject?.id;

    if (shouldCloseStack) {
      this.appRoutingService.openDetailsForObject(this.objectType, this.objectId, true);
    }
  }

  protected setNewObjectIdToDrawerStack(objectId: number): void {
    this.drawerStackService.updateStackConfig(this.drawerType, { id: objectId });
  }

  protected handleSaveAction(): void {
    this.checkTagsLeftover();
    if ( this.unsavedChangesFlag || this.hasUnsavedChanges()) {
      const wasObjectCreation = !this.objectId;
      const onSaved = wasObjectCreation ?
        () => this.setNewObjectIdToDrawerStack(this.currentState.objectId) :
        null;

      this.saveChanges(onSaved, false);
    }
  }

  protected handleSaveAndNewAction(): void {
    this.checkTagsLeftover();
    const wasObjectCreation = !this.objectId;
    const onSaved = wasObjectCreation ?
      () => this.initDrawer(true) : // we shouldn't update drawer stack (there is objectId == null)
      () => this.setNewObjectIdToDrawerStack(null);

      if(wasObjectCreation){
        this.resetFormAndFetchCustomField = false;
      }

    if (this.unsavedChangesFlag || this.hasUnsavedChanges()) {
      this.saveChanges(onSaved, false);
      return;
    }

    onSaved();
  }

  protected handleSaveAndCloseAction(markAsVerified: boolean): void {
    this.checkTagsLeftover();

    if ( this.unsavedChangesFlag || this.hasUnsavedChanges() || markAsVerified) {
      const onSaved = this.appRoutingService.closeActiveDrawer.bind(this.appRoutingService, markAsVerified);
      if (markAsVerified) { // for invoices
        this.formData.patchValue({ [DrawerFormFields.isVerified]: true });
      }
      this.saveChanges(onSaved, false);
      return;
    }
    this.appRoutingService.closeActiveDrawer();
  }

  protected resetFormData(): void {
    const nameControl = this.fdNameControl;
    if (nameControl) {
      nameControl.asyncValidator = null;
      nameControl.setErrors(null);
    }
  }

  protected setNameValidator(nameValue: string, dataValidation: DataValidationService): void {
    const nameControl = this.fdNameControl;
    if (nameControl) {
      nameControl.asyncValidator = dataValidation.uniqueNameValidator(
        this.companyId, this.budget.id, nameValue, this.objectType
      );
    }
  }

  protected defineVendorName(state: ObjectDetailsCommonState): void {
    const { vendor: vendorId } = state;
    const vendor = vendorId && this.vendors.find(item => item.id === vendorId);
    state.vendorName = vendor ? vendor.name : '';
  }

  protected validateContextObjectType(types: BudgetObjectType[] = [], state: ObjectDetailsCommonState): void {
    if (!types.find(type => type.id === state.typeId)) {
      state.typeId = null;
    }
  }

  protected get getSegmentRelatedData$(): Observable<any> {
    return combineLatest([
      this.budgetObjectDetailsManager.getSegments().pipe(tap(segments => this.segments = segments)),
      this.budgetObjectDetailsManager.getSegmentGroups().pipe(tap(items =>  this.segmentGroups = items)),
      this.budgetObjectDetailsManager.getSharedCostRules().pipe(tap(rules => this.sharedCostRules = rules)),
      this.budgetObjectDetailsManager.getAllowedSharedCostRules().pipe(tap(rules => this.allowedSharedCostRules = rules)),
    ]).pipe(
      tap(() => this.updateSegmentSelectItems())
    );
  }

  protected get getVendors$(): Observable<Vendor[]> {
    return this.budgetObjectDetailsManager.getVendors().pipe(
      tap(vendors => this.vendors = vendors),
      take(1)
    );
  }

  protected get getGLCodes$(): Observable<GLCode[]> {
    return this.budgetObjectDetailsManager.getGlCodes().pipe(
      tap(glCodes => this.glCodes = glCodes)
    );
  }

  protected get currentCompanyUser$(): Observable<CompanyUserDO> {
    return this.userManager.currentCompanyUser$.pipe(
      filter(user => !!user),
      tap(user => {
        this.currentCompanyUser = user;
        this.isPowerUser = this.userDataService.isPowerUser(user);
      })
    );
  }

  protected applyCreatedVendor(vendor: Vendor): void {
    if (vendor?.id) {
      this.currentState.vendor = vendor.id;
      this.formData.patchValue({ [DrawerFormFields.vendorId]: vendor.id }, { emitEvent: false });
      this.getVendors$.subscribe();
    }
  }

  protected handleVendorChange(): void {
    const vendorName: string = this.getVendorName(this.fdVendorName);
    this.setVendorValue(vendorName);
  }

  protected subscribeToVendorsAutocomplete(): void {
    this.autocompleteVendors = this.formData.controls.vendorName.valueChanges
      .pipe(
        startWith(''),
        map(name => this._filterVendors(name))
      );
  }

  private _filterVendors(vendor: Vendor): Vendor[] {
    if (!vendor) {
      return this.vendors.slice();
    }

    const filterValue = this.getVendorName(vendor);
    const filteredVendors = this.vendors.filter(
      option => option.name.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
    );

    if (filteredVendors.length === 0 && typeof name === 'string') {
      return [{
        id: null,
        name: filterValue,
        isNew: true
      }];
    }
    return filteredVendors;
  }

  protected setVendorValue(vendorName: string): void {
    const existingVendor = vendorName && this.vendors.find(
        vendor => vendor.name.toLowerCase() === vendorName.trim().toLowerCase()
      ),
      vendorId = existingVendor ? existingVendor.id : null;
    this.formData.patchValue({ [DrawerFormFields.vendorId]: vendorId, [DrawerFormFields.vendorName]: vendorName }, { emitEvent: false });
  }

  protected getVendorName = (vendor: Vendor | string): string => {
    if (!vendor) {
      return '';
    }
    return typeof vendor === 'string' ? vendor : vendor.name;
  }

  protected createVendor$(): Observable<Vendor> {
    const { vendor, vendorName } = this.currentState;
    if (vendor != null || !vendorName) {
      return of(null);
    }
    return this.companyDataService.createVendor({ company: this.companyId, name: vendorName });
  }

  protected updateTabsData(): void {
    this.updateDetailsTabData();
    this.updateAllocationsTabData();
    // updatePerformanceTabData is calling from the template
  }

  protected updateDetailsTabData(): void {
    const overdueTaskTypes = [TaskStatusName.LATE, TaskStatusName.BLOCKED];
    let overdueTasksLength, updatedDate, isNonCampaignMetric, isKeyMetric;
    if (this.currentState.tasks?.length) {
      overdueTasksLength = this.currentState.tasks.filter(task => overdueTaskTypes.includes(task.status as TaskStatusName)).length;
    }
    if (this.objectId) {
      updatedDate = this.datePipe.transform(this.currentState.updated, 'd LLL yyyy');
    }
    if (this.currentState.mappingType) {
      isNonCampaignMetric = Boolean(this.currentState.mappingType.toLowerCase() != 'campaign')
    }

    this.metricsManager.isKeyMetricSubject.subscribe(isKeyMetricValue => {
      isKeyMetric = isKeyMetricValue;
    })

    this.tabsDataService.setDetailsData(
      ObjectDetailsTabsDataService.createDetailsTabText(overdueTasksLength, updatedDate, isKeyMetric, isNonCampaignMetric)
    );
  }

  protected updateAllocationsTabData(): void {
    this.tabsDataService.setAllocationsData(
      this.currentState.currencyCode || this.companyCurrency.code, // use companyCurrency for Goals
      this.allocationTotals[ManageTableBudgetColumnName.Budget],
      this.allocationTotals[ManageTableBudgetColumnName.Available],
    );
  }

  protected updatePerformanceTabData(keyMetricPerformanceData: PerformanceData): void {
    this.tabsDataService.setPerformanceData(keyMetricPerformanceData);
  }

  protected handleAllocationsUpdate(events: BudgetAllocationsTableEvent[]) {
    events.forEach(event => {
      const { budgetTimeframeId, amount } = event;
      const targetAllocation: CampaignAllocation = this.currentState.allocations
        .find(allocation => allocation.company_budget_alloc === budgetTimeframeId);
      const diff = +amount - targetAllocation.source_amount;

      targetAllocation.source_amount = amount as number;
      targetAllocation.amount = this.budgetObjectDetailsManager.getConvertedAmount(
        amount as number, this.currentState.currencyCode, budgetTimeframeId
      );
      targetAllocation.available = targetAllocation.available + diff;
    });
    this.currentState.allocations = [ ...this.currentState.allocations ];
    this.onAllocationsUpdated();
    this.syncUnsavedChangesFlag();
  }

  protected onAllocationsUpdated(): void {
    this.calculateAllocationTotals(this.currentState.allocations);
    this.currentState.sourceAmount = this.allocationTotals[ManageTableBudgetColumnName.Budget];
    this.currentState.amount = this.allocationTotals[ManageTableBudgetColumnName.ConvertedBudget];
    this.updateAllocationsTabData();
  }

  protected refreshStateAllocations(loadAllocationAmounts$: Observable<CampaignAllocation[]>) {
    this.showLoader();
    loadAllocationAmounts$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.currentState.allocations = this.prevState.allocations = [...this.currentState.allocations];
      this.calculateAllocationTotals(this.currentState.allocations);
      this.currentState.amount = this.prevState.amount = this.allocationTotals[ManageTableBudgetColumnName.ConvertedBudget];
      this.onAllocationsUpdated();
      this.hideLoader();
    });
  }

  protected calculateAllocationTotals(allocations: CampaignAllocation[] & ProgramAllocation[]): void {
    const emptyTotalValues = {
      [ManageTableBudgetColumnName.Budget]: 0,
      [ManageTableBudgetColumnName.ConvertedBudget]: 0,
      [ManageTableBudgetColumnName.Actual]: 0,
      [ManageTableBudgetColumnName.Committed]: 0,
      [ManageTableBudgetColumnName.Planned]: 0,
      [ManageTableBudgetColumnName.Available]: 0,
    };

    this.allocationTotals = allocations
      .reduce((totals, allocation) => {
        totals[ManageTableBudgetColumnName.Budget] += allocation.source_amount;
        totals[ManageTableBudgetColumnName.ConvertedBudget] += allocation.amount;
        totals[ManageTableBudgetColumnName.Actual] += allocation.source_actual;
        totals[ManageTableBudgetColumnName.Committed] += allocation.source_remaining_committed;
        totals[ManageTableBudgetColumnName.Planned] += allocation.source_remaining_planned;
        totals[ManageTableBudgetColumnName.Available] += allocation.available;
        return totals;
      }, emptyTotalValues);
  }

  protected getObjectCreatedDate(currentState: ObjectDetailsState): Date {
    return currentState.created ? new Date(currentState.created) : new Date();
  }

  protected getParentObject(objectType: string, objectId: number): Observable<BudgetObjectParent> {
    const parentObjectProvider = this.parentObjectProviders[objectType];
    return parentObjectProvider?.(objectId);
  }

  protected hasParentInHierarchy(targetObject: BudgetObjectParent, parentObject: BudgetObjectParent): Observable<boolean> {
    if (targetObject?.type === parentObject?.type && targetObject?.id === parentObject?.id) {
      return of(true);
    }

    if (targetObject?.type === this.configuration.OBJECT_TYPES.goal) {
      return of(false);
    }

    return targetObject ?
      this.getParentObject(targetObject.type, targetObject.id).pipe(
        switchMap(
          parentOfTargetObject => parentOfTargetObject ? this.hasParentInHierarchy(parentOfTargetObject, parentObject) : of(false)
        )
      ) :
      of(false);
  }

  protected getUpdatedPrograms$(
    objectProgramsLoader: () => Observable<ProgramDO[]>
  ): Observable<{ allPrograms: LightProgram[]; objectPrograms: ProgramDO[] }> {
    return forkJoin({
      allPrograms: this.budgetObjectDetailsManager.getLightPrograms().pipe(
        tap(programs => this.programs = programs),
        take(1)
      ),
      objectPrograms: objectProgramsLoader().pipe(
        tap(programs => this.currentState.programs = programs)
      )
    });
  }

  protected getUpdatedCampaignsAndPrograms$(
    objectCampaignsLoader: () => Observable<CampaignDO[]>
  ): Observable<{ allCampaigns: LightCampaign[], allPrograms: LightProgram[]; objectCampaigns: CampaignDO[] }> {
    return forkJoin({
      allCampaigns: this.budgetObjectDetailsManager.getLightCampaigns().pipe(
        tap(campaigns => this.campaigns = campaigns),
        take(1)
      ),
      allPrograms: this.budgetObjectDetailsManager.getLightPrograms().pipe(
        tap(programs => this.programs = programs),
        take(1)
      ),
      objectCampaigns: objectCampaignsLoader().pipe(
        tap(campaigns => this.currentState.campaigns = campaigns)
      )
    });
  }

  protected handleMetricMappingUpdate(data: UpdateMetricData): Observable<{ updateData: UpdateMetricData, metricMapping?: Metric }> {
    return this.hasParentInHierarchy(
      { type: data.objectType, id: data.objectId },
      { type: this.objectType, id: this.currentState.objectId }
    ).pipe(
      filter(hasParentInHierarchy => hasParentInHierarchy),
      switchMap(() => {
        // If it's an event of a child metric - for its parent metric it's always UPDATE!
        if (data.objectId !== this.currentState.objectId) {
          data.action = MetricUpdateAction.UPDATE;
        }

        return data.action === MetricUpdateAction.UPDATE ?
          this.getMetricMapping(data).pipe(
            map(metricMapping => ({ updateData: data, metricMapping }))
          ) :
          of({ updateData: data });
      })
    );
  }

  protected getMetricMapping(data: UpdateMetricData): Observable<Metric> {
    const currentMetricMapping =
      this.currentState.metricMappings?.find(
        metricMapping => (metricMapping.productId || null) === (data.productId || null) && metricMapping.typeId === data.metricId
      );

    return currentMetricMapping ?
      this.budgetObjectDetailsManager.getMetricMappingById(currentMetricMapping.id) :
      of(null);
  }

  protected updateMetric (metricMappings: Metric[], metricId: number, metric: Metric): Metric[]  {
    const metricMappingsCopy = structuredClone(metricMappings);
    const metricIndex = metricMappingsCopy.findIndex(m => m.id === metricId);

    if (metricIndex !== -1) {
      metricMappingsCopy[metricIndex] = metric;
    }

    return metricMappingsCopy;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.onDestroy();
  }

  protected onInit(): void {
    // default implementation
  }
  protected onDestroy(): void {
    // default implementation
  }
}
