<button
  [class.hidden]="selectedTimeframeMode === budgetTimeframesType.Year"
  mat-icon-button
  #configBtn
  class="mat-button timeframe-config-btn"
  matTooltip="Select Timeframe"
  [matTooltipShowDelay]="1000"
  matTooltipClass="dark-theme-tooltip simple"
  matTooltipPosition="above"
  (click)="showConfigDialog()"
>
  <fa-icon [icon]="['fas', 'calendar-range']"></fa-icon>
</button>
