<div
  class="flat-select"
  [ngClass]="{ 'is-open': select.panelOpen, 'is-readonly': isReadOnly }"
>
  <mat-select
    disableOptionCentering
    [disableRipple]="true"
    [panelClass]="'flat-select-panel ' + panelClass"
    [placeholder]="placeholder"
    [ngModel]="value"
    (valueChange)="handleValueChange($event)"
    [compareWith]="compareOptions"
    [disabled]="isReadOnly"
    #select
  >
    <mat-select-trigger>
      <ng-content select="[slot=trigger]"></ng-content>
      <ng-container *ngIf="!triggerContent.length">
        <span class="trigger-value">{{ value?.label || value?.name }}</span>
      </ng-container>
    </mat-select-trigger>
    <ng-container *ngFor="let item of options">
      <mat-option
        [value]="item"
        [class.with-indicator]="item.indicator"
        [class.hidden]="item.hidden"
      >
        <div
          class="option-indicator"
          *ngIf="item.indicator"
          [style.background]="'rgb(' + item.indicator + ')'"
        ></div>
        {{ item.label || item.name }}
      </mat-option>
    </ng-container>
  </mat-select>
  <drop-down-arrow [opened]="select.panelOpen"></drop-down-arrow>
</div>
