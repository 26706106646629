import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UtilityService } from 'app/shared/services/utility.service';
import { Subject } from 'rxjs';
import { ExpenseTypeService } from 'app/shared/services/backend/expense-type.service';
import { takeUntil } from 'rxjs/operators';
import { ProgramTypeDO } from 'app/shared/types/program.interface';
import { BudgetObjectDialogService } from '../../shared/services/budget-object-dialog.service';
import * as utils from 'app/shared/utils/notification-dialog.utils';
import { DialogContext } from '../../shared/types/dialog-context.interface';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmType } from '../../shared/utils/notification-dialog.utils';
import { Configuration } from 'app/app.constants';
import { CompanyDataService } from '../../shared/services/company-data.service';
import { BudgetSelectionModalService } from '../../shared/services/budget-selection-modal.service';
import { BudgetSelectionUsageCountService } from '../budget-selection-attributes-tags/budget-selection-attributes-tags.service';

@Component({
  selector: 'tab-expense-types',
  templateUrl: './tab-expense-types.component.html',
  styleUrls: ['./tab-expense-types.component.scss']
})
export class ExpenseTypesTabComponent implements OnChanges, OnDestroy {
  @Input() companyId: number;
  @Input() budgetId: number;
  readonly destroy$ = new Subject<void>();
  expenseTypeList: ProgramTypeDO[];
  appliedSorting = true;
  NoDataText = '';
  selectedItemId: number;
  selectedItemName: string;

  public gridColumns = [
    { name: 'name', label: 'Expense Type', sortable: true, width: '40%' },
    { name: 'is_enabled', label: 'Enabled', sortable: true, width: '25%' },
    { name: 'usage_count', label: 'Count', sortable: true, width: '20%' }
  ];

  enableSwitchTooltip = this.config.expenseTypesDescriptions.enableTooltip;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly expenseTypeService: ExpenseTypeService,
    private readonly dialogService: BudgetObjectDialogService,
    private readonly dialog: MatDialog,
    private config: Configuration,
    private companyDataService : CompanyDataService,
    private budgetSelectionModalService: BudgetSelectionModalService,
    private usageService: BudgetSelectionUsageCountService
  ) { 
    this.NoDataText = this.config.attributesAndTagsLabel.EXPENSE_TYPE;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId) {
      this.getExpenseTypes(this.companyId);
    }
  }

  getExpenseTypes(companyId: number) {
    this.utilityService.showLoading(true);
    this.expenseTypeService.getNewExpenseTypes({ company: companyId })
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        data => this.onExpenseTypesLoaded(data),
        error => this.utilityService.handleError(error)
      );
  }

  addExpenseType(data) {
    this.utilityService.showLoading(true);
    data.is_custom = true;
    data.company = this.companyId;
    this.expenseTypeService.addExpenseType(data)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => this.onExpenseTypeEvent(this.config.expenseTypesDescriptions.created),
        error => this.utilityService.handleError(error)
      );
  }

  updateExpenseType(data) {
    this.utilityService.showLoading(true);
    this.expenseTypeService.updateExpenseType(data.id, data)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => this.onExpenseTypeEvent(this.config.expenseTypesDescriptions.updated),
        error => this.utilityService.handleError(error)
      );
  }

  updateStatusChange(data) {
    this.utilityService.showLoading(true);
    this.expenseTypeService.updateStatusChange(data.id, data)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onExpenseTypeEvent(this.config.expenseTypesDescriptions.updated),
      error => this.utilityService.handleError(error)
    );
  }

  deleteExpenseType(row) {
    const deleteHandler = () => {
      this.utilityService.showLoading(true);
      this.expenseTypeService.deleteNewWorldExpenseType(row.id, {company:this.companyId})
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(
          () => this.onExpenseTypeEvent(this.config.expenseTypesDescriptions.deleted),
          () => this.showDeleteNotificationDialog(row.name)
        );
    };
    const dialogMessage = `You will permanently lose the '${row.name}' expense type from everywhere it is used. '${row.name}' will be replaced with 'Other'. `;

    this.dialogService.openDeleteEntityDialog(deleteHandler, null, { title: 'Permanently Delete Expense Type?', message: dialogMessage });
  }

  onExpenseTypeEvent(msg: string) {
    this.utilityService.showToast({ Title: '', Message: msg, Type: 'success' });
    this.utilityService.showLoading(false);
    this.getExpenseTypes(this.companyId);
  }

  showDeleteNotificationDialog(expenseTypeName: string) {
    const dialogData: DialogContext = utils.getConfirmationDialogData(ConfirmType.DeleteExpenseType, expenseTypeName);
    this.dialog.open(ConfirmationDialogComponent, {
      width: dialogData.width,
      data: dialogData
    });
  }

  onExpenseTypesLoaded(data) {
    this.expenseTypeList = data;
    const updatedData = data.map(rawExpenseType => ({
      id: rawExpenseType.id,
      name: rawExpenseType.name,
      isCustom: rawExpenseType.is_custom,
      isEnabled: rawExpenseType.is_enabled,
      createdDate: rawExpenseType.crd && new Date(rawExpenseType.crd),
      updatedDate: rawExpenseType.upd && new Date(rawExpenseType.upd),
      status: rawExpenseType.status,
      companyId: rawExpenseType.company
    }));

    this.companyDataService.expenseTypes.next(this.companyDataService.currentExpenseTypes = updatedData)
    this.utilityService.showLoading(false);
  }

  actionOnCountEvent(event) {
    this.selectedItemId = event.id;
    this.selectedItemName = event.name;
    this.usageService.getBudgetSelectionUsageCount('expense_type', event.id, { company: this.companyId })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => this.onBudgetSelectionUsageCountLoaded(data),
        error: error => this.utilityService.handleError(error),
        complete: () => console.log('Budget selection usage count loaded.')
      });
  }
  

  onBudgetSelectionUsageCountLoaded(groups) {
    const data = {
      title: 'View Budget',
      content: `This Expense Type is included in multiple budgets. <br/> Select the specific budget from the drop-down menu to view it.`,
      icon: {
        prefix: 'fas',
        name: 'sack-dollar'
      },
      buttons : [
        { text: 'View', color: 'primary', disabled: true }
      ],
      currentBudgetId : this.budgetId,
      selectedItemId : this.selectedItemId,
      selectedItemName : this.selectedItemName,
      selectedTab : 'expenseTypes',
      groups : [
        ...groups
      ]
    }
    if (groups.length === 1) {
      data.content = `This Expense Type is included in <b> ${groups[0].budget_name}</b> budget.`;
      data.buttons[0].disabled = false;
    }
    this.budgetSelectionModalService.openBudgetSelectionDialog(data);
  }


}
