import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadStatusDialogComponent } from '@spending/components/spending/invoices/upload-status-dialog/upload-status-dialog.component';

@Component({
  selector: 'upload-status-btn',
  templateUrl: './upload-status-btn.component.html',
  styleUrls: ['./upload-status-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadStatusBtnComponent {
  @Input() uploadingFilesNumber: number;
  @Input() failedFilesNumber: number;

  @Output() viewInvoices = new EventEmitter<void>();

  constructor(private readonly dialog: MatDialog) { }

  openStatusDialog(): void {
    this.dialog.open(UploadStatusDialogComponent, {
      width: '690px',
      panelClass: 'reset-paddings',
      autoFocus: false,
      data: {
        submitAction: {
          handler: () => this.viewInvoices.emit()
        }
      }
    });
  }
}
