import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalNumber'
})
export class OrdinalNumberPipe implements PipeTransform {
  private suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th'
  };
  private ordinalRules;

  constructor() {
    if (Intl.PluralRules) {
      this.ordinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
    }
  }

  transform(value: number): string {
    const suffix = this.suffixes[this.ordinalRules.select(value)];

    return (value + suffix);
  }
}
