import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormattedBudgetValueConfig } from './formatted-budget-value.types';

@Component({
  selector: 'formatted-budget-value',
  templateUrl: './formatted-budget-value.component.html',
  styleUrls: ['./formatted-budget-value.component.scss']
})
export class FormattedBudgetValueComponent implements OnChanges {
  @Input() config: Partial<FormattedBudgetValueConfig>;
  private defaultNumericFormat = '1.2-2';
  public displayValue = '';

  constructor(
    private readonly transformPipe: DecimalPipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && this.config) {
      this.displayValue = this.getDisplayValue();
    }
  }

  private getDisplayValue(): string {
    const { value, label, allowNegative = true, numericValueFormat = this.defaultNumericFormat } = this.config;

    if (value != null) {
      return this.transformPipe.transform(allowNegative ? value : Math.abs(value), numericValueFormat);
    }

    if (label) {
      return label;
    }
  }
}
