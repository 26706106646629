import { CalculatedMetricData, Metric } from './../../details-metrics/details-metrics.type';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { MetricCalculationsDO, MetricMappingDO, MetricService } from 'app/shared/services/backend/metric.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { Configuration } from 'app/app.constants';
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { PerformanceData, PerformanceMetricData } from 'app/manage-table/types/performance-column-data.type';
import { roundDecimal } from 'app/shared/utils/common.utils';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { SelectedValue, SelectItem } from 'app/shared/types/select-groups.interface';
import { MatMenuTrigger } from '@angular/material/menu';
import { MetricProgressState } from '@shared/types/metric-progress-state.type';
import { getTodaysDate } from '@shared/utils/date.utils';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { BudgetObjectMetricsService } from 'app/budget-object-details/services/budget-object-metrics.service';
import { MetricsUtilsService } from 'app/budget-object-details/services/metrics-utils.service';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { getMetricSelectItems } from 'app/budget-object-details/components/details-metrics/metric-masters-list/metric-masters-list.component';


@Component({
  selector: 'details-metric-control',
  templateUrl: './details-metric-control.component.html',
  styleUrls: ['./details-metric-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsMetricControlComponent implements OnDestroy, OnChanges {
  @Input() defaultStartDate: string;
  @Input() defaultEndDate: string;
  @Input() savedMetricMappings: Metric[] = [];
  @Input() metricTypes: MetricType[] = [];
  @Input() products: ProductDO[] = [];
  @Input() objectBudget = 0;
  @Input() totalSpend = 0;
  @Input() companyCurrency = '';
  @Input() isReadOnlyMode = false;
  @Input() objectType: string;
  @Input() objectId: number | null;
  @Input() keyMetricSelection = true;
  @Input() keyMetricId: number | string;
  @Input() actualBusinessValue: number | null;
  @Input() targetBusinessValue: number | null;
  @Input() isPowerUser: boolean;
  @Input() todayDate: Date;

  @Output() onAddMapping = new EventEmitter<MetricMappingDO[]>();
  @Output() onOpenMetric = new EventEmitter<number>();
  @Output() setKeyMetric = new EventEmitter<Metric>();
  @Output() keyMetricPerformanceData = new EventEmitter<PerformanceData>();
  @ViewChild('addMetricsDropdownTrigger') addMetricsDropdownTrigger: MatMenuTrigger;

  private readonly router = inject(Router);
  public readonly configuration = inject(Configuration);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly metricsService = inject(MetricService);
  private readonly metricsUtilsService = inject(MetricsUtilsService);
  private readonly metricMappingDetailsService = inject(MetricMappingDetailsService);
  public readonly budgetObjectMetricsService = inject(BudgetObjectMetricsService);
  private readonly cdr = inject(ChangeDetectorRef);

  private readonly _selectedMetric = new BehaviorSubject<Metric | null>(null);

  public readonly selectedMetric$ = this._selectedMetric.asObservable();

  private readonly destroy$ = new Subject<void>();

  public isLoading = false;
  public metrics: Metric[] = [];
  public masterMetricsSelectItems: SelectItem[] = [];

  public numberDisplayFormat = '1.0-2';
  public decimalDisplayFormat = '1.2-2';
  public calculatedData = new Map<number, CalculatedMetricData>();
  public utClasses = {
    targetCPO: 'ut-target-cpo',
    currentCPO: 'ut-current-cpo',
    targetROI: 'ut-target-roi',
    currentROI: 'ut-current-roi',
  };

  public performanceMetricData: PerformanceMetricData = {};
  public allMetricsHaveRevenueToProfit = false;

  private resetMetricData(): void {
    this.calculatedData.clear();
    this.performanceMetricData = {};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metricTypes) {
      if (this.metricTypes?.length && this.savedMetricMappings?.length) {
        this.processMasterMetrics(this.savedMetricMappings);
      }
    }

    if (changes.savedMetricMappings) {
      let updatedMetrics = changes.savedMetricMappings.currentValue || [];

      if (updatedMetrics && this.metricTypes?.length) {
        this.processMasterMetrics(updatedMetrics);
        updatedMetrics = this.mapMetricCurrencyExist(updatedMetrics, this.metricTypes);
      }
      this.syncMetrics(updatedMetrics);
    }

    if (changes.objectBudget && !changes.objectBudget.firstChange && this.objectBudget != null) {
      this.loadTargetROIAndCPOForMetrics();
    }

    if (changes.totalSpend && !changes.totalSpend.firstChange && this.totalSpend != null) {
      this.loadCurrentROIAndCPOForMetrics();
    }

    if (changes.keyMetricId || changes.objectBudget != null) {
      this.applySelectedMetric(this.keyMetricId);
    }
  }

  public addMetrics(metricIds: SelectedValue[]): void {
    if (!this.objectId) {
      return;
    }
    this.addMetricsDropdownTrigger.closeMenu();
    this.isLoading = true;

    forkJoin(
      metricIds.map(
        metricId => this.metricsService.createMetricMapping({
          mapping_type: this.objectType,
          map_id: this.objectId,
          metric_master: metricId as number,
        })
      )
    )
    .pipe(
      takeUntil(this.destroy$),
      finalize(() => this.isLoading = false)
    )
    .subscribe(mappings => {
        this.onAddMapping.emit(mappings);
        this.metricMappingDetailsService.reportMappingsListChange();
    });
  }

 public navigateToFunnelsPage(): void {
    this.addMetricsDropdownTrigger.closeMenu();
    this.appRoutingService.closeAllDetailsWindows();
    this.appRoutingService.safeNavigateTo([this.configuration.ROUTING_CONSTANTS.METRIC_FUNNELS]);
  }

  public openMetricDetails(id: number, event: Event): void {
    event.stopPropagation();
    this.onOpenMetric.emit(id);
  }

  public closeAddMetricsDropdown(): void {
    this.addMetricsDropdownTrigger.closeMenu();
  }

  public handleKeyMetricChange(isKeyMetric: boolean, metric: Metric): void {
    !isKeyMetric ? this.applySelectedMetric(metric.id) : this.applySelectedMetric(null);
    this.setKeyMetric.emit(metric);
  }

  private processMasterMetrics(savedMetrics: Metric[]): void {
    const savedTypeIds: SelectedValue[] = savedMetrics.map(metric => metric.typeId);
    const unusedMetrics = this.metricTypes
      .filter(metric => !savedTypeIds.includes(metric.id));

    this.masterMetricsSelectItems = getMetricSelectItems(this.products, unusedMetrics);
  }

  private calculateROIAndCPOForMetrics$(
    metrics: Metric[],
    cost: number,
    valueGetter: (metric: Metric) => number
  ): Observable<MetricCalculationsDO[]> {
    return metrics?.length ?
      this.metricsService.calculateValues(
        metrics.map(metric => {
          const { revenuePerOutcome, revenueToProfit } =
            MetricsUtilsService.getMetricRPOAndRevenueToProfit(metric.typeId, this.metricTypes, this.products);
          return {
            rpo: revenuePerOutcome,
            cost,
            value: valueGetter(metric),
            revenue_to_profit: revenuePerOutcome && revenueToProfit
          };
        })
      ) :
      of([]);
  }

  private loadCurrentROIAndCPOForMetrics(): void {
    this.calculateROIAndCPOForMetrics$(
      this.metrics,
      this.totalSpend,
        metric => MetricsUtilsService.getMetricCurrentValue(metric)
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      calcs => {
        calcs?.forEach((calc, index) => {
          this.applyMetricCalculation(
            this.metrics[index].id,
            { currentCPO: calc.CPO || 0, currentROI: calc.ROI || 0 }
          );
        });
        this.cdr.markForCheck();
      }
    )
  }

  private loadTargetROIAndCPOForMetrics(): void {
    this.calculateROIAndCPOForMetrics$(
      this.metrics,
      this.objectBudget,
      metric => metric.legacyTarget
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      calcs => {
        calcs?.forEach((calc, index) => {
          this.applyMetricCalculation(
            this.metrics[index].id,
            { targetCPO: calc.CPO || 0, targetROI: calc.ROI || 0 }
          );
        });
        this.cdr.markForCheck();
      }
    );
  }

  private applyMetricCalculation(metricId: number, data: CalculatedMetricData, ): void {
    const dataItem = this.calculatedData.has(metricId) ? this.calculatedData.get(metricId) : {};
    this.calculatedData.set(metricId, {
      ...dataItem,
      ...data
    });
  }

  private patchMetricWithDefaults(metric: Metric) {
    if (!this.defaultStartDate || !this.defaultEndDate) {
      return;
    }

    if (!metric.startDate) {
      metric.startDate = this.defaultStartDate;
    }

    if (!metric.milestones || !metric.milestones.length) {
      metric.milestones = [
        {
          targetValue: metric.legacyTarget,
          date: parseDateString(this.defaultEndDate)
        }
      ];
    }
  }

  private applyProductsData(): void {
    if (!this.metrics?.length || !this.products?.length) {
      return;
    }
    this.metrics.forEach(metric => {
      if (metric.productId) {
        const product = this.products.find(prod => prod.id === metric.productId);
        metric.productName = product?.name;
        metric.productOrder = product?.order;
        metric.productRevenueToProfit = product?.revenue_to_profit;
      }
    });
  }

  private syncMetrics(metrics: Metric[]): void {
    this.resetMetricData();
    this.metrics = metrics;
    this.applyProductsData();

    this.metrics.forEach((metric) => {
      const dataItem: CalculatedMetricData = {
        targetCPO: 0,
        currentCPO: 0,
        targetROI: 0,
        currentROI: 0,
        targetValue: metric.legacyTarget,
        currentValue: MetricsUtilsService.getMetricCurrentValue(metric)
      };
      this.calculatedData.set(metric.id, dataItem);
      this.patchMetricWithDefaults(metric);
      this.preparePerformanceData(metric);
    });

    this.reloadROIAndCPOCalculations();

    this.keyMetricPerformanceData.emit(this.keyMetricId ? this.performanceMetricData[this.keyMetricId] : null);
  }

  private reloadROIAndCPOCalculations(): void {
    this.loadCurrentROIAndCPOForMetrics();
    this.loadTargetROIAndCPOForMetrics();
  }

  private applySelectedMetric(keyMetricId: number | string): void {
    this.metrics.forEach(metricItem => metricItem.isKeyMetric = false);
    const selectedMetricLink = this.metrics.find(metric => metric.id === keyMetricId);
    this._selectedMetric.next(selectedMetricLink);
    if (selectedMetricLink) {
      selectedMetricLink.isKeyMetric = true;
    }
    this.keyMetricPerformanceData.emit(keyMetricId ? this.performanceMetricData[keyMetricId] : null);
  }

  private preparePerformanceData(metric: Metric): void {
    const currentValue = this.calculatedData.get(metric.id)?.currentValue;
    const { progressState, diffShare } = this.defineState(metric, currentValue, this.todayDate);

    this.performanceMetricData[metric.id] = {
      estimatedDiffPercentage: roundDecimal(diffShare * 100, 2),
      progressState
    };
  }

  private defineState(metric: Metric, currentValue: number, todayDate?: Date): {
    progressState: MetricProgressState;
    diffShare: number;
    estimatedTarget: number
  } {
    if (!metric.milestones || !metric.startDate) {
      return;
    }

    const estimatedTarget = this.metricsUtilsService.getEstimatedTarget(
      getTodaysDate(todayDate),
      parseDateString(metric.startDate),
      metric.milestones
    );
    const { state: progressState, diffShare } = this.metricsUtilsService.getProgressState(estimatedTarget, currentValue);

    return { progressState, diffShare, estimatedTarget };
  }

  private mapMetricCurrencyExist(metrics: Metric[], metricTypes: MetricType[]): Metric[] {
    return metrics.map(metric => {
       const matchingObject = metricTypes.find(metricType => metricType.id === metric.typeId);
       return matchingObject ? { ...metric, withCurrency: matchingObject?.withCurrency } : metric;
     })
   }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
