import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'manage-table-plain-value',
  templateUrl: './manage-table-plain-value.component.html',
  styleUrls: ['./manage-table-plain-value.component.scss']
})
export class ManageTablePlainValueComponent {
  @Input() value: number | null = 0;
  @Input() isBold = true;
  @Input() isDisabled = false;
  @Input() decimalPipeFormat = '1.2-2';
  @Input() isClickable = false;
  @Input() withNullishPlaceholder = false;

  @Output() onClick = new EventEmitter<void>();

  public handleClick() {
    if (this.isClickable) {
      this.onClick.emit();
    }
  }
}
