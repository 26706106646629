export enum BudgetObjectChangeEvent {
  DetailsChanged = 'DetailsChanged',
  AttachmentsChanged = 'AttachmentsChanged'
}

export interface BudgetObjectChange {
  objectId?: number;
  objectType: string;
  eventType?: BudgetObjectChangeEvent;
}

export interface MetricMappingChange {
  mappingType: string;
  mappingId: number;
  metricId?: number;
  mapId?: number;
  isKeyMetric?: boolean;
}
