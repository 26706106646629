<ng-container *ngIf="!isEditMode; else editMode">
  <div class="name" [ngClass]="{ 'highlighted': metric.valueType === metricValueType.Currency }">
    {{ metric?.name }}
  </div>
  <a *ngIf="metric.usageCount"
     class="link"
     [ngClass]="{ 'disabled': funnelDisabled }"
     (click)="clickUsageCount()">({{ metric.usageCount <= 99 ? metric.usageCount : '99+' }})</a>
  <div class="metric-value">
    <span class="currency" *ngIf="metric?.valueType === metricValueType.Currency">{{ prefix }} </span>
    <span class="number ut-target">{{ metric.target | number: numberFormat }}</span>
  </div>
</ng-container>

<ng-template #editMode>
  <ng-container [ngTemplateOutlet]="hasSimpleActions ? metricActions : isConnectedMetric ? hideButton : deleteButton"></ng-container>
  <plc-editable-value
    class="editable-value-name"
    [ngClass]="{ 'highlighted': metric.valueType === metricValueType.Currency }"
    [maxLength]="maxMetricNameLength"
    [controlValue]="metric?.name"
    [isReadOnly]="isConnectedMetric && metric.valueType === metricValueType.Currency"
    [metric]="metric"
    [autoFocus]="!metric.id"
    [checkValidity$]="checkValidity$"
    [placeholder]="placeholderByValueType[metric.valueType]"
    (onNameChanged)="handleNameChange($event)"
    (onErrorStateChanged)="handleErrorState($event)"
  ></plc-editable-value>
  <plc-editable-value
    class="editable-value-target"
    [class]="targetOutlineStyle ? 'outline-style--' + targetOutlineStyle : ''"
    [ngClass]="{ 'long': metric.valueType === metricValueType.Currency }"
    [prefix]="prefix"
    [controlValue]="metric?.target"
    [isReadOnly]="false"
    [align]="isFlipped ? 'left' : 'right'"
    [numberFormat]="numberFormat"
    [metric]="metric"
    [isNumber]="true"
    [precision]="precision"
    [selectOnFocus]="selectTargetOnFocus"
    [highlighted]="highlightNumber"
    (focusIn)="focusChanged.emit(true)"
    (focusOut)="focusChanged.emit(false)"
    [placeholder]="'Target'"
    (onNameChanged)="handleTargetChange($event)"
  ></plc-editable-value>
</ng-template>

<ng-template #metricActions>
  <div class="actions-block">
    <button *ngIf="simpleActionsConfig.addAbove"
            [matTooltip]="'Add a metric \n above this one'"
            [matTooltipShowDelay]="600"
            matTooltipClass="dark-theme-tooltip simple multi-line"
            matTooltipPosition="above"
            mat-icon-button
            class="mat-button add-item"
            tabindex="-1"
            (click)="addMetric(-1)">
      +
    </button>
    <button *ngIf="simpleActionsConfig.canDelete"
            [matTooltip]="'Delete this \n metric'"
            [matTooltipShowDelay]="600"
            matTooltipClass="dark-theme-tooltip simple multi-line"
            [matTooltipPosition]="simpleActionsConfig.addAbove ? 'below' : 'above'"
            mat-icon-button
            class="mat-button delete-item"
            tabindex="-1"
            (click)="deactivate()">
          <fa-duotone-icon [icon]="['fad', 'trash-alt']"></fa-duotone-icon>
    </button>
    <button *ngIf="simpleActionsConfig.addBelow"
            [matTooltip]="'Add a metric \n below this one'"
            [matTooltipShowDelay]="600"
            matTooltipClass="dark-theme-tooltip simple multi-line"
            matTooltipPosition="below"
            mat-icon-button
            class="mat-button add-item"
            tabindex="-1"
            (click)="addMetric(1)">
      +
    </button>
  </div>
</ng-template>

<ng-template #deleteButton>
  <button mat-icon-button
          class="mat-button"
          [matTooltip]="'Used ' + (metric.usageCount || 0) + ' times'"
          [matTooltipShowDelay]="1000"
          matTooltipClass="dark-theme-tooltip simple"
          matTooltipPosition="above"
          (click)="deactivate()">
    <fa-duotone-icon [icon]="['fad', 'trash-alt']"></fa-duotone-icon>
  </button>
</ng-template>

<ng-template #hideButton>
  <button mat-icon-button
          class="mat-button"
          [disabled]="false"
          [matTooltip]="(metric.isHidden ? 'Show this metric for' : 'Hide this metric from') + ' your team'"
          [matTooltipShowDelay]="1000"
          matTooltipClass="dark-theme-tooltip simple"
          matTooltipPosition="above"
          (click)="deactivate()">
    <fa-duotone-icon [icon]="['fad', metric.isHidden ? 'eye-slash' : 'eye']"></fa-duotone-icon>
  </button>
</ng-template>
