import { Injectable, OnDestroy } from '@angular/core';
import { PlanCampaign, PlanProgram } from '../../dashboard/dashboard.types';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CampaignService } from './backend/campaign.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  AllocationCheckResult,
  AllocationCheckResultData,
  BudgetObjectAllocationService
} from '../../budget-object-details/services/budget-object-allocation.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { Configuration } from 'app/app.constants';
import { SegmentedBudgetObject } from '../types/segmented-budget-object';
import { UtilityService } from './utility.service';
import { BudgetObjectService } from './budget-object.service';
import { CampaignDO } from '../types/campaign.interface';
import { ProgramService } from './backend/program.service';


export interface DragAndDropInterface {
  id: number;
  segmentName?: string;
  segmentId?: number;
  tags_data?: Array<any>;
}

@Injectable()
export class DragAndDropService implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(
    private readonly budgetDataService: BudgetDataService,
    private readonly programService: ProgramService,
    private readonly configuration: Configuration,
    private readonly campaignService: CampaignService,
    private readonly budgetObjectAllocationService: BudgetObjectAllocationService,
    private readonly utilityService: UtilityService,
    private readonly budgetObjectService: BudgetObjectService,
  ) { }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public moveExpenseGroupToCampaign(
    campaign: PlanCampaign,
    program: PlanProgram
  ): Observable<any> {
    const updateProgramInSnapshot = (programId) => {
      const childProgram = this.budgetDataService.programsSnapshot.find(c => c.id === programId);
      if (childProgram) {
        childProgram.campaignId = campaign.id;
        childProgram.goalId = null;
      }
    };

    return this.moveObjectToCampaign(
      campaign,
      program,
      this.configuration.OBJECT_TYPES.program,
      this.programService.moveProgramToCampaign(campaign.id, program.id).pipe(
        tap(() => updateProgramInSnapshot(program.id)),
      )
    );
  }

  public moveCampaignToCampaign(
    parentCampaign: PlanCampaign,
    childCampaign: PlanCampaign
  ): Observable<any> {
    const { OBJECT_TYPES, defaultSegmentName } = this.configuration;
    const allowedSegments = this.budgetDataService.segmentsSnapshot;
    const hasChildCampaigns = (childCampaign?.children || []).some(child => child.objectType === OBJECT_TYPES.campaign);
    if (hasChildCampaigns || childCampaign?.id === parentCampaign.id) {
      return of();
    }

    const childSegmentName = allowedSegments.find(segment => segment.id === childCampaign.company_budget_segment1)?.name || '';
    const params: Partial<CampaignDO> = { parent_campaign: parentCampaign.id, goal: null };
    const isSegmentlessCampaign = (activeCampaign: PlanCampaign) => !activeCampaign.company_budget_segment1 && !activeCampaign.split_rule;

    if (childSegmentName === defaultSegmentName && !isSegmentlessCampaign(parentCampaign)) {
      params.company_budget_segment1 = Number(parentCampaign.company_budget_segment1);
      params.process_nested = true;
    }

    const updateCampaignInSnapshot = (campaignId) => {
      const _childCampaign = this.budgetDataService.campaignsSnapshot.find(c => c.id === campaignId);
      if (_childCampaign) {
        _childCampaign.parentCampaign = parentCampaign.id;
        _childCampaign.goalId = null;
      }
    };

    return this.moveObjectToCampaign(
      parentCampaign,
      childCampaign,
      OBJECT_TYPES.campaign,
      this.campaignService.updateCampaign(childCampaign.id, params).pipe(
        tap(() => updateCampaignInSnapshot(childCampaign.id)),
      )
    );
  }

  public dragAndDropSuccess(): void {
    this.utilityService.dragNdrop(true);
    this.utilityService.showLoading(false);
  }

  public handleError(error) {
    if (error.hasOwnProperty('message')) {
      this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
      this.utilityService.showLoading(false);
    } else if (error.hasOwnProperty('detail')) {
      this.utilityService.showLoading(false);
      this.utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
    }
  }

  private moveObjectToCampaign(
    campaign: DragAndDropInterface,
    object: DragAndDropInterface,
    objectType: string,
    moveObjectAction$: Observable<any>
  ): Observable<any> {
    return this.checkAllocationAmounts(campaign, object, objectType)
      .pipe(
        switchMap(checkResult => {
          if (checkResult.result !== AllocationCheckResult.NeedOwnAllocationUpdate) {
            this.utilityService.showLoading(true);
            return moveObjectAction$.pipe(
              switchMap(() => forkJoin([
                this.attachDynamicTagsToChildObjects(campaign, object, objectType),
                this.updateParentAmount(campaign.id, checkResult)
              ]))
            )
          }
          return of(false);
        }),
        takeUntil(this.destroy$)
      );
  }

  private checkAllocationAmounts(
    parent: DragAndDropInterface,
    child: DragAndDropInterface,
    childType: string
  ): Observable<AllocationCheckResultData> {
    const { OBJECT_TYPES } = this.configuration;
    const campaigns = this.budgetDataService.campaignsSnapshot;
    const programs = this.budgetDataService.programsSnapshot;
    const objects: SegmentedBudgetObject[] = childType === OBJECT_TYPES.program ? programs : campaigns;
    const childObject = objects.find(item => item.id === child.id);

    return this.budgetObjectAllocationService.checkObjectAllocationAmount(
      {
        objectId: child.id,
        objectType: childType,
        totalAmount: childObject?.amount,
        allocationAmounts: this.budgetObjectAllocationService.getAllocationAmountsMap(childObject.timeframes)
      },
      { id: parent.id, type: OBJECT_TYPES.campaign },
      programs,
      campaigns,
      false
    );
  }

  private attachDynamicTagsToChildObjects(dropObject, dragObject, tagMappingType) {
    if (!dropObject || !dragObject || !dropObject.tags_data?.length || !tagMappingType) {
      return of(null);
    }
    return this.budgetObjectService.attachDynamicTagsToChildObjects(
      dragObject['id'],
      tagMappingType,
      dragObject.tags_data,
      dropObject.tags_data
    );
  }

  private updateParentAmount(campaignId: number, allocationsCheckResult: AllocationCheckResultData): Observable<void> {
    const campaigns = this.budgetDataService.campaignsSnapshot;
    const campaign = campaigns.find(cmpn => cmpn.id === campaignId);
    const { allocationDiff, parentAllocationsDiff, grandParentAllocationsDiff, grandParentAllocationDiff } = allocationsCheckResult;

    if (!campaign || allocationDiff >= 0) {
      return of(null);
    }

    return this.budgetObjectAllocationService.updateCampaignAllocationAmount(
      campaign.timeframes,
      Math.abs(allocationDiff),
      { parent: allocationsCheckResult.parentCampaign, grandParent: allocationsCheckResult.grandParentCampaign },
      this.budgetDataService.selectedBudgetSnapshot.suppress_timeframe_allocations,
      parentAllocationsDiff,
      grandParentAllocationsDiff,
      Math.abs(grandParentAllocationDiff)
    );
  }
}
