import { Component, Input } from '@angular/core';

@Component({
  selector: 'details-budget-amount',
  templateUrl: './details-budget-amount.component.html',
  styleUrls: ['./details-budget-amount.component.scss']
})
export class DetailsBudgetAmountComponent {
  @Input() title = '';
  @Input() amount = 0;
  @Input() currency = '';
  @Input() isOverspend = false;
}
