<icon-by-object-type
  *ngIf="showIcon"
  [objectType]="objectType"
  [isClosed]="closed"
  [flip]="flipIcon"
  [size]="iconSize"
></icon-by-object-type>
<span class="wrapping-text"
      [ngClass]="{ 'leading-icon': showIcon }"
      [linesTotal]="linesTotal"
      [stringTruncate]="name"
      [prefix]="(closed ? ' [Closed]' : '')"></span>
