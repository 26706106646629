export interface CompanyUserPermissionSegmentDO {
  id: number;
  name: string;
  user_access_permissions_id: number;
}

export interface CompanyUserPermissionDO {
  budget_id: number;
  budget_name: string;
  read: boolean;
  read_write: boolean;
  segments: CompanyUserPermissionSegmentDO[];
}

export enum CompanyUserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
  Disabled = 'Disabled'
}

export interface CompanyUserDO {
  id: number;
  company: number;
  user: number;
  is_account_owner: boolean;
  is_admin: boolean;
  non_reporting: boolean;
  subscribed_for_mailing: boolean;
  permissions: CompanyUserPermissionDO[];
  status: CompanyUserStatus;
  first_login: string;
  user_company_code: string;
  invitation_date: string;
  crd: string;
  upd: string;
}

export interface CreatedTeamMate extends CompanyUserDO {
  profile_id: number;
}

export interface NewTeamMatePayload {
  first_name: string;
  last_name: string;
  email: string;
  company: number;
  is_admin: boolean;
}

export interface GetCompanyUsersPayload {
  company; number;
  user: number;
  status: string;
  is_admin: boolean;
  is_account_owner: boolean;
  invitation_date: string;
  first_login: string;
}
