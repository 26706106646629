
<div *ngIf="!customComponent; else customComponentHost">
  <span class="success-icon" *ngIf="toastClasses.split(' ').includes('success')"></span>
  <span [innerHTML]="toastPackage.message"></span>
</div>
<ng-template #customComponentHost></ng-template>

<button
  *ngIf="!hideButton"
  mat-button
  class="close-button"
  [class.icon-only]="!buttonText"
  (click)="onAction()">
  <span *ngIf="buttonText; else defaultCross">{{ buttonText }}</span>
</button>

<ng-template #defaultCross>
  <icon-cross></icon-cross>
</ng-template>

