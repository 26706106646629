import { Component, ViewChild, Input, Output, EventEmitter, HostListener, OnInit } from '@angular/core';

import { ActionsMenuComponent, ActionMenuItem } from '../actions-menu/actions-menu.component';

@Component({
  selector: 'dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})

/**
 * @class - DropdownMenuComponent
 */
export class DropdownMenuComponent {
  @ViewChild('actionsMenu', { static: true }) actionsMenu: ActionsMenuComponent;
  @Input() items: ActionMenuItem[] = [];
  @Input() selectedValue: string;
  @Input() arrowNav = false;
  @Input() disabled = false;
  @Output() onSelectValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() onMenuOpenStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  isActionsMenuOpened = false;

  get selectedValueText(): string {
    const selectedValue: ActionMenuItem = this.items.find((item: ActionMenuItem) => item.value === this.selectedValue);
    return selectedValue && (selectedValue.menuLabel || selectedValue.text);
  }
  @HostListener("keydown.arrowup", ["$event"])
  @HostListener("keydown.arrowdown", ["$event"])
  onKeydown(e) {
    if (!this.arrowNav) {
      return;
    }
    const arrowDownCode = 40;

    const selectableItems = this.items.filter((item: ActionMenuItem) => !item.isUnselectable);
    const currentIndex = selectableItems.findIndex((item: ActionMenuItem) => item.active);

    let nextIndex;
    if (e.keyCode === arrowDownCode) {
      nextIndex = currentIndex === selectableItems.length - 1 ? 0 : currentIndex + 1;
    } else {
      nextIndex = currentIndex === 0 ? selectableItems.length - 1 : currentIndex - 1
    }
    const nextSelectableItem = selectableItems[nextIndex];

    this.items = this.items.map((item: ActionMenuItem) => {
      item.active = item === nextSelectableItem;
      return item;
    });

    this.actionsMenu.scrollToActiveElement();

    e.preventDefault();
    e.stopPropagation();
  }

  @HostListener("keydown.tab", ["$event"])
  @HostListener("keydown.esc", ["$event"])
  onTabClick(e) {
    if(this.isActionsMenuOpened) {
      this.actionsMenu.closeActionsMenu();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @HostListener("keydown.space", ["$event"])
  @HostListener("keydown.enter", ["$event"])
  onSubmitByKeyDown(e) {
    e.preventDefault();
    e.stopPropagation();
    if(this.isActionsMenuOpened) {
      if(this.arrowNav) {
        const activeItemIndex = this.items.findIndex(item => item.active);
        activeItemIndex !== -1 && this.onSelectValue.next(this.items[activeItemIndex].value);
        this.actionsMenu.closeActionsMenu();
      }
    } else {
      this.handleDropdownMenuClick();
    }
  }

  handleDropdownMenuClick = () => {
    if(!this.disabled) {
      if (this.isActionsMenuOpened) {
        this.actionsMenu.closeActionsMenu();
      } else {
        if(this.arrowNav) {
          this.items = this.items.map((item, index) => {
            item.selected = item.value === this.selectedValue;
            item.active = item.value === this.selectedValue;
            return item;
          })
        }
        this.actionsMenu.openActionsMenu();
      }
    }
  };

  handleActionsMenuStateChange = (isOpened: boolean) => {
    this.isActionsMenuOpened = isOpened;
    this.onMenuOpenStateChange.emit(isOpened);
  };

  handleSelectValue = (value: string) => {
    this.onSelectValue.next(value);
  };
}
