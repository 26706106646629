<tooltip-container
  class="arrow-left metric-performance-tooltip"
  [ngClass]="progressState"
>
  <div class="metric-name">{{ name }}</div>
  <div class="performance-summary">
    <metric-performance-arrow
      [progressState]="progressState"
    ></metric-performance-arrow>
    <ng-container *ngIf="estimatedDiffPercentage != null">
      {{ estimatedDiffPercentage < 0 ? (-estimatedDiffPercentage) : estimatedDiffPercentage }}%
      {{ estimatedDiffPercentage < 0 ? 'behind' : 'above' }} today's target
    </ng-container>
  </div>
  <div class="metric-values">
    <div class="key-column">
      <div class="column-item">Value:</div>
      <div class="column-item">Target:</div>
    </div>
    <div class="value-column">
      <div class="column-item is-highlighted">{{ current | number: decimalPipeFormat }}</div>
      <div class="column-item">{{ target | number: decimalPipeFormat }}</div>
    </div>
  </div>
</tooltip-container>
