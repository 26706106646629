import { Component, Input, OnChanges } from '@angular/core';
import { MonthLabel } from './calendar-header.type';

@Component({
  selector: 'calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent implements OnChanges {
  @Input() labels: MonthLabel[];
  @Input() active: string; // format: [year]-[month]; example '2020-apr'
  isOneFullYear = false;

  ngOnChanges() {
    if (this.labels) {
      this.isOneFullYear = this.labels[0].year === this.labels[this.labels.length - 1].year;
    }
  }

}
