import { Component, ContentChildren, QueryList, AfterContentInit, EventEmitter, Output } from '@angular/core';
import { RoundedBoxRadioItemComponent } from '../radio-button-item/radio-item.component';
@Component({
  selector: 'rounded-box-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RoundedBoxRadioGroupComponent implements AfterContentInit {
  @ContentChildren(RoundedBoxRadioItemComponent) radioItems: QueryList<RoundedBoxRadioItemComponent>;
  @Output() selectionChange = new EventEmitter<string>();

  ngAfterContentInit() {
    this.radioItems.toArray().forEach(item => {
      item.selectionChange.subscribe((selectedValue: string) => {
        this.radioItems.toArray().forEach(radio => {
          if (radio.value !== selectedValue) {
            radio.checked = false;
          }
        });
        this.selectionChange.emit(selectedValue);
      });
    });
  }
}
