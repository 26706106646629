import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { InvoiceLiveTracking } from '@shared/services/invoice-live-tracking';
import { ExpenseDetailsService } from 'app/budget-object-details/services/expense-details.service';

@Component({
  selector: 'invoice-review-actions-panel',
  templateUrl: './invoice-review-actions-panel.component.html',
  styleUrls: ['./invoice-review-actions-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceReviewActionsPanelComponent implements OnInit {

  constructor(private readonly expenseDetailsService: ExpenseDetailsService,
              public readonly invoiceLiveTracking: InvoiceLiveTracking) { }

  cancel(): void {
    this.invoiceLiveTracking.setIsLastInvoice(false);
    this.expenseDetailsService.handleCancelAction.next();
  }

  verify(): void {
    this.expenseDetailsService.handleSaveAndCloseAction.next();
  }

  ngOnInit(): void {
  }

}
