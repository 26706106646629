import { MetricFunnelRow, MetricValueType } from '@common-lib/lib/corporate-page/metric-funnels.types';

export class MetricFunnelsCalculationsService {
  public emptyParams;
  getMetricKeyByType(metricType: MetricValueType, inverse = false) {
    const compareType = inverse ? MetricValueType.Currency : MetricValueType.Count;
    return metricType === compareType ? 'countMetric' : 'currencyMetric';
  }

  public getBaseRowIndex(rows: MetricFunnelRow[]): number {
    return rows.findIndex(row => row.isBaseRow);
  }

  public isBaseRPODefined(baseRow: MetricFunnelRow): boolean {
    return !!baseRow?.countMetric?.revenuePerOutcome;
  }

  public updateMetricsRowIndex(rows: MetricFunnelRow[]) {
    rows.forEach((row, index) => {
      const countMetric = row.countMetric;
      const currencyMetric = row.currencyMetric;
      if (countMetric) {
        countMetric.rowIndex = index;
      }
      if (currencyMetric) {
        currencyMetric.rowIndex = index;
      }
    })
  }

  calculateValues(rows: MetricFunnelRow[], baseRowIndex: number, startFormIndex: number, changedType: MetricValueType) {
    this.emptyParams = this.checkEmptyParams(rows);
    if (this.emptyParams.missingBaseRowData) {
      return;
    }

    const startIndex = startFormIndex !== null ? startFormIndex : baseRowIndex;
    const startedFromBaseRow = startFormIndex === baseRowIndex;

    if (changedType !== MetricValueType.Currency) {
      // null or Count metric
      this.loopUpFromIndex(startIndex, (i) => {
        this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Count));
      });

      if (!startedFromBaseRow && changedType === MetricValueType.Count) {
        this.loopDownFromToIndex(startIndex, rows.length - 1, (i) => {
          this.calcBelowTarget(rows, i, this.getMetricKeyByType(MetricValueType.Count));
        });
      }
    }

    if (changedType !== MetricValueType.Count) {
      this.loopUpFromIndex(startIndex, (i) => {
        this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
      });
      this.loopDownFromToIndex(startIndex, rows.length - 1, (i) => {
        this.calcBelowTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
      });
    } else {
      // update BaseRow and then Currency (up/down) from Base row
      this.recalculateBaseCurrencyTarget(rows[baseRowIndex]);
      this.loopUpFromIndex(baseRowIndex, (i) => {
        this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
      });
      this.loopDownFromToIndex(baseRowIndex, rows.length - 1, (i) => {
        this.calcBelowTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
      });
    }

    if (changedType === MetricValueType.Currency) {
      // one of Currency was changed, so now we need to update all Count targets
      this.recalculateBaseCountTarget(rows[baseRowIndex]);
      this.loopUpFromIndex(baseRowIndex, (i) => {
        this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Count));
      });
    }

    this.loopUpFromIndex(baseRowIndex, (i) => {
      this.calcRevenuePerOutcome(rows, baseRowIndex, i);
    });
  }

  recalculateBaseCountTarget(baseRow: MetricFunnelRow): void {
    baseRow.countMetric.target =
      this.formattedNumber(baseRow.currencyMetric.target / baseRow.countMetric.revenuePerOutcome);
  }

  recalculateBaseCurrencyTarget(baseRow: MetricFunnelRow): void {
    baseRow.currencyMetric.target =
      this.formattedNumber(baseRow.countMetric.target * baseRow.countMetric.revenuePerOutcome);
  }

  public afterConversionChanged(rows: MetricFunnelRow[], rowIndex, baseRowIndex: number) {
    this.emptyParams = this.checkEmptyParams(rows);
    if (this.emptyParams?.missingBaseRowData) {
      return;
    }
    if (rowIndex < baseRowIndex) {
      this.loopUpFromIndex(rowIndex + 1, (i) => {
        this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Count));
        this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
      });
      this.loopUpFromIndex(baseRowIndex, (i) => {
        this.calcRevenuePerOutcome(rows, baseRowIndex, i);
      });
    } else {
      this.loopDownFromToIndex(rowIndex, rows.length - 1, (i) => {
        // below Base row we have Currency metrics only
        this.calcBelowTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency), false);
      });
    }
  }

  getRowTargetForAsc(rows: MetricFunnelRow[], currentIndex: number, metricKey: string): number {
    const currentRow = rows[currentIndex];
    if (!currentRow) {
      return null;
    }
    const currentTarget = currentRow[metricKey]?.target;
    return currentTarget != null ? currentTarget : currentRow.conversionRate ?
      this.getRowTargetForAsc(rows, currentIndex + 1, metricKey) * 100 / currentRow.conversionRate : null;
  }

  getRowTargetForDesc(rows: MetricFunnelRow[], currentIndex: number, metricKey: string): number {
    const currentRow = rows[currentIndex];
    const currentTarget = currentRow[metricKey]?.target;

    if (currentTarget != null) {
      return currentTarget;
    }

    return rows[currentIndex - 1] ?
      this.getRowTargetForDesc(rows, currentIndex - 1, metricKey) * currentRow.conversionRate / 100 :
      0;
  }

  setRowConversion(row: MetricFunnelRow, value: number) {
    row.conversionRate = value;
    if (row.countMetric) {
      row.countMetric.conversionRate = value;
    }
    if (row.currencyMetric) {
      row.currencyMetric.conversionRate = value;
    }
  }

  calcAboveTarget(rows: MetricFunnelRow[], rowIndex: number, metricKey: string) {
    const aboveRow = rows[rowIndex - 1];

    if (aboveRow?.[metricKey]) {
      const currentTarget = this.getRowTargetForAsc(rows, rowIndex, metricKey);

      aboveRow[metricKey].target = currentTarget === null || !aboveRow.conversionRate ?
        0 : this.getAboveTargetFromConversion(currentTarget, aboveRow.conversionRate);
    }
  }

  calcBelowTarget(
    rows: MetricFunnelRow[],
    rowIndex: number,
    metricKey: string,
    tryCalcConversion = true,
    allowZeroConversion = false
  ) {
    const currentRow = rows[rowIndex];
    const belowRow = rows[rowIndex + 1];

    if (belowRow?.[metricKey]) {
      const currentActualTarget = currentRow[metricKey]?.target || null;
      const currentCalculatedTarget = this.getRowTargetForDesc(rows, rowIndex, metricKey);

      if (!currentRow.conversionRate && tryCalcConversion) {
        const belowActualTarget = belowRow[metricKey]?.target || null;
        if (belowActualTarget && currentActualTarget) {
          const conversionRate = 100 * belowActualTarget / currentActualTarget;
          this.setRowConversion(currentRow, conversionRate);
        } else {
          return;
        }
      }

      const isConversionIrrelevant = allowZeroConversion
        ? currentRow.conversionRate == null
        : !currentRow.conversionRate;

      if (currentCalculatedTarget === null || isConversionIrrelevant) {
        return;
      }

      belowRow[metricKey].target = this.getBelowTargetFromConversion(currentCalculatedTarget, currentRow.conversionRate);
    }
  }

  calcRevenuePerOutcome(rows: MetricFunnelRow[], baseRowIndex: number, i: number) {
    const revenue = rows[baseRowIndex].currencyMetric.target;
    const currentRow = rows[i];
    if (currentRow.countMetric) {
      const target = currentRow.countMetric.target;
      currentRow.countMetric.revenuePerOutcome = target ? this.formattedNumber(revenue / target) : 0;
    }
  }

  loopUpFromIndex(startIndex, cb) {
    for (let i = startIndex; i >= 0; i--) {
      cb(i);
    }
  }

  loopDownFromToIndex(startIndex, endIndex, cb) {
    for (let i = startIndex; i <= endIndex; i++) {
      cb(i);
    }
  }

  public getBelowTargetFromConversion(target: number, conversion: number): number {
    return this.formattedNumber(target * conversion / 100);
  }

  public getAboveTargetFromConversion(target: number, conversion: number): number {
    return this.formattedNumber(100 * target / conversion);
  }

  public formattedNumber(rawNum: number): number {
    const round = rawNum < 0.01 ? 1000000 : 100;
    return Math.round(rawNum * round) / round;
  }

  checkEmptyParams(rows: MetricFunnelRow[]): Record<string, any> {
    const emptyParams = {
      emptyConversionsRows: [],
      missingBaseRowData: false,
    };
    rows.forEach((row, i) => {
      if (!row.conversionRate) {
        emptyParams.emptyConversionsRows.push(i);
      }
      if (row.isBaseRow) {
        const enoughData =
          (row.countMetric.target && row.currencyMetric.target) ||
          (row.countMetric.revenuePerOutcome && row.currencyMetric.target) ||
          (row.countMetric.target && row.countMetric.revenuePerOutcome);
        emptyParams.missingBaseRowData = !enoughData;
      }
    })
    return emptyParams;
  }

  public calculateBuilderValues(
    rows: MetricFunnelRow[],
    baseRowIndex: number,
    startFromIndex: number,
    changedType: MetricValueType
  ) {
    const startIndex = startFromIndex !== null ? startFromIndex : baseRowIndex;
    const baseRow = rows[baseRowIndex];
    const isBaseRPODefined = this.isBaseRPODefined(baseRow);
    const calcCountOnly = changedType === MetricValueType.Count;
    const calcCurrencyOnly = changedType === MetricValueType.Currency;
    const calcBoth = changedType === null;

    if (calcBoth || calcCountOnly) {
      this.loopDownFromToIndex(startIndex, rows.length - 1, (i) => {
        this.calcBelowTarget(rows, i, this.getMetricKeyByType(MetricValueType.Count));
      });
      this.loopUpFromIndex(startIndex, (i) => {
        this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Count));
      });

      if (calcCountOnly && isBaseRPODefined) {
        this.recalculateBaseCurrencyTarget(baseRow);
        this.loopUpFromIndex(baseRowIndex, (i) => {
          this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
        });
        this.loopDownFromToIndex(baseRowIndex, rows.length - 1, (i) => {
          this.calcBelowTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
        });
      }
    }

    if (calcBoth || calcCurrencyOnly) {
      this.loopDownFromToIndex(startIndex, rows.length - 1, (i) => {
        this.calcBelowTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
      });
      this.loopUpFromIndex(startIndex, (i) => {
        this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
      });

      if (calcCurrencyOnly && isBaseRPODefined) {
        this.recalculateBaseCountTarget(baseRow);
        this.loopUpFromIndex(baseRowIndex, (i) => {
          this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Count));
        });
      }
    }
  }

  public afterBuilderConversionChanged(
    rows: MetricFunnelRow[],
    baseRowIndex: number,
    targetMetricId: number,
    targetMetricRowIndex: number,
    targetMetricValueType: MetricValueType
  ) {
    const baseRow = rows[baseRowIndex];
    const isBaseRPODefined = this.isBaseRPODefined(baseRow);

    this.loopUpFromIndex(targetMetricRowIndex, (i) => {
      this.calcAboveTarget(rows, i, this.getMetricKeyByType(targetMetricValueType));
    });
    this.loopDownFromToIndex(targetMetricRowIndex, rows.length - 1, (i) => {
      this.calcBelowTarget(rows, i, this.getMetricKeyByType(targetMetricValueType), false, true);
    });

    if (isBaseRPODefined) {
      if (targetMetricValueType === MetricValueType.Count) {
        this.recalculateBaseCurrencyTarget(baseRow);
        this.loopUpFromIndex(baseRowIndex, (i) => {
          this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency));
        });
        this.loopDownFromToIndex(baseRowIndex, rows.length - 1, (i) => {
          this.calcBelowTarget(rows, i, this.getMetricKeyByType(MetricValueType.Currency), false, true);
        });
      } else {
        this.recalculateBaseCountTarget(baseRow);
        this.loopUpFromIndex(baseRowIndex, (i) => {
          this.calcAboveTarget(rows, i, this.getMetricKeyByType(MetricValueType.Count));
        });
      }
    }
  }
}
