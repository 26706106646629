import { HubspotMappingTypeName } from './hubspot-campaign-mapping.interface';

export enum HubspotMetricTypeNames {
  FormViews = 'FormViews',
  FormInteractions = 'FormInteractions',
  FormSubmissions = 'FormSubmissions',
  EmailsSent = 'EmailsSent',
  EmailsBounced = 'EmailsBounced',
  EmailsReportedAsSpam = 'EmailsReportedAsSpam',
  EmailsUnsubscribed = 'EmailsUnsubscribed',
  EmailsOpened = 'EmailsOpened',
  EmailsWithClickThrough = 'EmailsWithClickThrough',
  UtmCampaignRawViews = 'UtmCampaignRawViews',
  UtmCampaignVisits = 'UtmCampaignVisits',
  UtmCampaignVisitors = 'UtmCampaignVisitors',
  UtmCampaignLeads = 'UtmCampaignLeads',
  UtmCampaignContacts = 'UtmCampaignContacts',
  UtmCampaignSubscribers = 'UtmCampaignSubscribers',
  UtmCampaignMarketingQualifiedLeads = 'UtmCampaignMarketingQualifiedLeads',
  UtmCampaignSalesQualifiedLeads = 'UtmCampaignSalesQualifiedLeads',
  UtmCampaignOpportunities = 'UtmCampaignOpportunities',
  UtmCampaignCustomers = 'UtmCampaignCustomers',
}

export enum HubspotCampaignType {
  WebForm = 'Web Form',
  MarketingEmail = 'Marketing Email',
  UtmCampaign = 'Utm Campaign'
}

export enum HubspotCampaignStatus {
  Archived = 'Archived',
  Published = 'Published',
  NotPublished = 'Not Published'
}

export enum HubspotFetchDataAction {
  GetEmailCampaigns = 'getEmailCampaigns',
  GetForms = 'getForms',
  GetUtmCampaigns = 'getUtmCampaigns'
}

export const hubspotMappingTypeNameByCampaignType = {
  [HubspotCampaignType.MarketingEmail]: HubspotMappingTypeName.emails,
  [HubspotCampaignType.WebForm]: HubspotMappingTypeName.forms,
  [HubspotCampaignType.UtmCampaign]: HubspotMappingTypeName.utmCampaigns
};

export const HubspotMetricTypeApiField = {
  [HubspotMetricTypeNames.FormViews]: 'formViews',
  [HubspotMetricTypeNames.FormInteractions]: 'interactions',
  [HubspotMetricTypeNames.FormSubmissions]: 'submissions',
  [HubspotMetricTypeNames.EmailsSent]: 'sent',
  [HubspotMetricTypeNames.EmailsBounced]: 'bounce',
  [HubspotMetricTypeNames.EmailsReportedAsSpam]: 'spamreport',
  [HubspotMetricTypeNames.EmailsUnsubscribed]: 'unsubscribed',
  [HubspotMetricTypeNames.EmailsOpened]: 'open',
  [HubspotMetricTypeNames.EmailsWithClickThrough]: 'click',
  [HubspotMetricTypeNames.UtmCampaignRawViews]: 'rawViews',
  [HubspotMetricTypeNames.UtmCampaignVisits]: 'visits',
  [HubspotMetricTypeNames.UtmCampaignVisitors]: 'visitors',
  [HubspotMetricTypeNames.UtmCampaignLeads]: 'leads',
  [HubspotMetricTypeNames.UtmCampaignContacts]: 'contacts',
  [HubspotMetricTypeNames.UtmCampaignSubscribers]: 'subscribers',
  [HubspotMetricTypeNames.UtmCampaignMarketingQualifiedLeads]: 'marketingQualifiedLeads',
  [HubspotMetricTypeNames.UtmCampaignSalesQualifiedLeads]: 'salesQualifiedLeads',
  [HubspotMetricTypeNames.UtmCampaignOpportunities]: 'opportunities',
  [HubspotMetricTypeNames.UtmCampaignCustomers]: 'customers',
};
