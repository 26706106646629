import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[expenseTableCellAlignment]'
})
export class ExpenseTableCellAlignmentDirective {
  @Input() set expenseTableCellAlignment(propValue: string) {
    if (typeof propValue === 'string') {
      this.elementRef.nativeElement.style.setProperty('--text-align', propValue);
    }
  }
  constructor(
    private elementRef: ElementRef
  ) { }
}
