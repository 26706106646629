<grid-with-search
  [gridData]="vendorsList"
  [gridColumns]="gridColumns"
  (deleteGridItem)="deleteVendor($event)"
  (addNewGridItem)="addVendor($event)"
  (editGridItem)="updateVendor($event)"
  (updateStatusChange)="updateStatusChange($event)"
  [enableSwitchTooltip]="enableSwitchTooltip"
  [SearchPlaceHolder]="SearchPlaceHolder"
  [editableMaxLength]="45"
  [NoDataText]="NoDataText"
></grid-with-search>