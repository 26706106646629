<section class="metric-total-info">
  <div class="metric-total-info-container">
    <div class="metric-total">
      <div>
        <p class="title">Total {{ metricName }}</p>
        <p class="current-value">
          <span *ngIf="totalValue >= 0">
            {{ totalValue || 0 | number: decimalDisplayFormat }}
            <fa-duotone-icon [icon]="['fad', 'graduation-cap']" [matTooltip]="totalMetricTooltip[objectType]" matTooltipClass="dark-theme-tooltip multi-line text-left right metric-total" matTooltipPosition="right">
            </fa-duotone-icon>
          </span>
          <span *ngIf="totalValue < 0" class="highlight-negative">
            0
            <fa-icon [icon]="['fas', 'exclamation-triangle']" [matTooltip]="negativeTotalTooltip" matTooltipClass="dark-theme-tooltip multi-line above" matTooltipPosition="above">
            </fa-icon>
          </span>
        </p>
        <p class="target">Target: {{ totalTarget || 0 | number: decimalDisplayFormat}}</p>
      </div>
    </div>
    <div class="cost-per-outcome" >
      <div>
        <p class="title">{{ revenuePerOutcome ? (revenueToProfit ? 'ROI' : 'RROI') : 'Cost per Outcome'}}</p>
        <p class="current-value" [ngClass]="progressIndication">
          <span>
            <fa-duotone-icon [icon]="['fad', 'graduation-cap']" [matTooltip]="revenuePerOutcome ? (revenueToProfit ? ROITooltip : RROITooltip) : CPOTooltip" matTooltipClass="dark-theme-tooltip multi-line text-left left metric-total" matTooltipPosition="left"></fa-duotone-icon>
            <ng-template [ngIf]="revenuePerOutcome" [ngIfElse]="costPerOutcomeTemplate">
              {{ returnOnInvestment || 0 | precisionNumberFormat }}x
            </ng-template>
            <ng-template #costPerOutcomeTemplate>
              {{ costPerOutcome || 0 | precisionNumberFormat | currency: currencyCode: 'symbol' }}
            </ng-template>
          </span>
        </p>
        <p class="target">
          Target
          <ng-template [ngIf]="revenuePerOutcome" [ngIfElse]="costPerOutcomeTargetTemplate">
            {{ targetReturnOnInvestment || 0 | precisionNumberFormat }}x
          </ng-template>
          <ng-template #costPerOutcomeTargetTemplate>
            {{ targetCostPerOutcome || 0 | precisionNumberFormat | currency: currencyCode: 'symbol' }}
          </ng-template>
        </p>
      </div>
    </div>
  </div>
</section>
