import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { MetricDO, MetricMappingDO, MetricService } from 'app/shared/services/backend/metric.service';
import { ProductDO, ProductService } from 'app/shared/services/backend/product.service';
import { baseCurrencyMetricName, DELETE_OBJECT, MetricUnit, objectPlaceholderName } from '../metric-funnel.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { DeleteProductDialogContext } from '../../shared/types/dialog-context.interface';
import { DeleteMetricModalComponent } from '../modals/delete-metric-modal/delete-metric-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {
  BudgetCalculatorData,
  FunnelStateDifferences,
  MetricFunnelRow,
  MetricMaster,
  MetricValueType,
  ProductMetricFunnel
} from '@common-lib/lib/corporate-page/metric-funnels.types';
import { Budget } from '@shared/types/budget.interface';
import { MetricMappingDetailsService } from '../../budget-object-details/services/metric-mapping-details.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { UserManager } from '../../user/services/user-manager.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { LS_KEY_ACTIVE_METRIC_MASTER_ID } from '../components/metrics-page/metrics-page.component';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { ChooseBudgetDialogComponent, MetricMappingGroup } from '../modals/choose-budget-dialog/choose-budget-dialog.component';
import { FunnelPresentationConfig, FunnelPresentationService} from '@common-lib/lib/funnel-presentation/funnel-presentation.service';
import { BackNavigationContext } from '../../manage-table/services/manage-page.service';
import { PendoEventName, PendoManagerService } from '@shared/services/pendo-manager.service';


@Injectable()
export class MetricFunnelsPageService implements OnDestroy {
  private _standaloneMetrics$ = new BehaviorSubject<MetricMaster[]>(null);
  private _metricFunnels$ = new BehaviorSubject<ProductMetricFunnel[]>(null);
  public standaloneMetrics$ = this._standaloneMetrics$.asObservable();
  public metricFunnels$ = this._metricFunnels$.asObservable();
  public companyId: number;
  public currencySymbol: string;
  public budgetId: number;
  private destroy$ = new Subject<void>();

  private static createMetricMaster(metric: MetricDO): MetricMaster {
    return {
      id: metric.id,
      name: metric.name,
      target: metric.target,
      revenuePerOutcome: metric.revenue_per_outcome,
      valueType: metric.with_currency ? MetricValueType.Currency : MetricValueType.Count,
      isHidden: metric.is_hidden,
      usageCount: metric.usage_count,
      rowIndex: metric.order,
    };
  }

  private static buildMetricFunnelRows(metrics: MetricDO[]): MetricFunnelRow[] {
    return (metrics || []).reduce(
      (funnelRows, metric) => {
        const metricMaster = MetricFunnelsPageService.createMetricMaster(metric);
        let row =
          funnelRows.find(
            fRow => fRow.countMetric?.rowIndex === metricMaster.rowIndex || fRow.currencyMetric?.rowIndex === metricMaster.rowIndex
          );

        if (!row) {
          row = {
            conversionRate: metric.conversion
          };
          funnelRows.push(row);
        }

        if (metricMaster.valueType === MetricValueType.Count) {
          row.countMetric = metricMaster;
        } else {
          row.currencyMetric = metricMaster;
        }

        row.isBaseRow = row.currencyMetric?.name === baseCurrencyMetricName;
        return funnelRows;
      },
      [] as MetricFunnelRow[]
    ).sort(
      (row1, row2) => {
        const rowOrder = (row: MetricFunnelRow) => (row.countMetric || row.currencyMetric)?.rowIndex || 0;
        return rowOrder(row1) - rowOrder(row2);
      }
    );
  }

  private static getUpdateBudgetCalculatorData(product: ProductDO, funnelRows: MetricFunnelRow[]): BudgetCalculatorData {
    const budgetCalculatorData = product.budget_calculator_data;
    return MetricFunnelsPageService.updateBudgetCalculatorData(budgetCalculatorData, funnelRows);
  }

  public static updateBudgetCalculatorData(
    budgetCalculatorData: BudgetCalculatorData,
    funnelRows: MetricFunnelRow[]
  ): BudgetCalculatorData {
    const calculatorData = { ...budgetCalculatorData };
    const targetedMetricId = calculatorData.targetedMetricId;
    const targetedMetric = funnelRows.find(row => row.countMetric?.id === targetedMetricId)?.countMetric;

    if (!targetedMetric) {
      // reset metricId, as targeted metric was removed from DB without frontend
      calculatorData.targetedMetricId = null;
    }
    calculatorData.budgetAmount = MetricFunnelsPageService.getBudgetAmount(calculatorData, targetedMetric?.target);
    return calculatorData;
  }

  public static getBudgetAmount(calculatorData: BudgetCalculatorData, actualCount: number): number {
    const prevMetricCost = calculatorData.prevMetricCost ?
      calculatorData.prevMetricCost :
      calculatorData.prevBudget && calculatorData.prevMetricNumber ?
        calculatorData.prevBudget / calculatorData.prevMetricNumber :
        null;
    return prevMetricCost && actualCount ? prevMetricCost * actualCount : null;
  }

  private static createMetricFunnel(product: ProductDO, metrics: MetricDO[]): ProductMetricFunnel {
    const productData = MetricFunnelsPageService.getFunnel(product);
    const metricFunnelRows = MetricFunnelsPageService.buildMetricFunnelRows(metrics);
    const budgetCalculatorData = MetricFunnelsPageService.getUpdateBudgetCalculatorData(product, metricFunnelRows);

    return {
      ...productData,
      metricFunnelRows,
      budgetCalculatorData
    };
  }

  private static getProductDO(funnel: Partial<ProductMetricFunnel>): Partial<ProductDO> {
    const productDO: Partial<ProductDO> = {
      name: funnel.productName,
      activated: funnel.activated,
      revenue_to_profit: funnel.revenueToProfit,
      order: funnel.order
    };

    if (funnel.budgetCalculatorData) {
      productDO.budget_calculator_data = funnel.budgetCalculatorData;
    }
    const entries = Object.entries(productDO).filter(([, value]) => value !== undefined);
    return Object.fromEntries(entries);
  }

  private static getFunnel(productDO: Partial<ProductDO>): ProductMetricFunnel {
    return {
      id: productDO.id,
      order: productDO.order,
      productName: productDO.name,
      activated: productDO.activated,
      revenueToProfit: productDO.revenue_to_profit,
      budgetCalculatorData: Object.values(productDO.budget_calculator_data).length ? productDO.budget_calculator_data : null,
    };
  }

  private static getFunnelsNamesList(funnels: ProductMetricFunnel[]): string[] {
    return funnels.map(funnel => funnel.productName);
  }

  private static getNewFunnelName = (originalName: string, existingNames: string[]): string => {
    const nameChunks = originalName.split(' ');
    const lastChunk = nameChunks.pop();
    let newName = isNaN(+lastChunk) ?
      originalName + ' 1' :
      nameChunks.join(' ') + ' ' + (+lastChunk + 1);

    while (existingNames.includes(newName)) {
      newName = MetricFunnelsPageService.getNewFunnelName(newName, existingNames);
    }
    return newName;
  }


  private static getRPOForCurrencyMetric(funnelRows: MetricFunnelRow[], index: number, baseRowIndex: number): number {
    let pathToRevenue = [];
    if (index < baseRowIndex) { // Metric 'before' Revenue
      pathToRevenue = funnelRows.slice(index, baseRowIndex);
    } else if (index > baseRowIndex) { // Metric 'after' Revenue
      pathToRevenue = funnelRows.slice(baseRowIndex + 1, index + 1);
    }
    return pathToRevenue.reduce((res, row) => res * row.conversionRate / 100, 1);
  }

  private static groupMappingsByBudget(allMappings: MetricMappingDO[], budgetListSnapshot: Budget[]): Record<string, MetricMappingGroup> {
    return allMappings.reduce((data, mapping: MetricMappingDO) => {
      const budgetId = mapping.budget;
      if (!data[budgetId]) {
        const currentBudget = budgetListSnapshot.find(budget => budget.id === budgetId);
        data[budgetId] = {
          budgetId: budgetId,
          budgetName: currentBudget?.name || budgetId,
          campaignIds: [],
        }
      }
      data[budgetId].campaignIds.push(mapping.map_id);
      return data;
    }, {});
  }

  private static createFunnelCopy(funnel: ProductMetricFunnel, allFunnelsNames: string[], order: number): ProductMetricFunnel {
    const copy: ProductMetricFunnel = createDeepCopy(funnel);
    copy.id = 0;
    copy.activated = false;
    copy.order = order;
    copy.productName = MetricFunnelsPageService.getNewFunnelName(copy.productName, allFunnelsNames);
    copy.metricFunnelRows.forEach(row => {
      if (row.countMetric) {
        row.countMetric.id = 0;
        row.countMetric.usageCount = 0;
      }
      if (row.currencyMetric) {
        row.currencyMetric.id = 0;
        row.currencyMetric.usageCount = 0;
      }
    });
    return copy;
  }

  constructor(
    private readonly companyDataService: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly metricService: MetricService,
    private readonly productService: ProductService,
    private readonly utilityService: UtilityService,
    private readonly configuration: Configuration,
    private readonly dialog: MatDialog,
    private readonly metricMappingDetailsService: MetricMappingDetailsService,
    private userManager: UserManager,
    private router: Router,
    public campaignService: CampaignService,
    private readonly appRoutingService: AppRoutingService,
    private readonly funnelPresentationService: FunnelPresentationService,
    private readonly pendoManager: PendoManagerService
  ) {
    merge(
      this.metricMappingDetailsService.metricMappingChanged$,
      this.metricMappingDetailsService.mappingsListChanged$
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.loadMetricsForCompany();
    })

    this.userManager.currentCompanyUser$
      .pipe(
        filter(user => user != null),
        takeUntil(this.destroy$)
      ).subscribe(user => {
        const accessAllowed = user.is_admin;
        if (!accessAllowed) {
          return this.router.navigate([this.configuration.ROUTING_CONSTANTS.HOME])
        }
      });

    this.companyDataService.selectedCompanyDO$
      .pipe(
        filter(cmp => cmp != null),
        takeUntil(this.destroy$)
      )
      .subscribe(company => {
        this.companyId = company.id;
        this.currencySymbol = company.currency_symbol;
        this.loadMetricsForCompany();
        this.companyDataService.loadCompanyData(this.companyId);
      });

    this.budgetDataService.selectedBudget$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(newSelectedBudget => this.onSelectNewBudget(newSelectedBudget))
  }

  standaloneMetricsSnapshot(): MetricMaster[] {
    return this._standaloneMetrics$.getValue();
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    if (newSelectedBudget != null) {
      this.budgetId = newSelectedBudget.id;
      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        newSelectedBudget.id
      );

      this.budgetDataService.loadLightPrograms(
        this.companyId,
        newSelectedBudget.id
      );
    }
  }

  loadMetricsForCompany(): void {
    forkJoin([
      this.productService.getProducts(this.companyId),
      this.metricService.getMetrics(this.companyId, { detailed: true })
    ]).pipe(
      map(([products, metrics]) => this.getMetricsData(products, metrics)),
      takeUntil(this.destroy$)
    ).subscribe(
      ([standaloneMetrics, metricFunnels]) => {
        this._standaloneMetrics$.next(standaloneMetrics);
        this.metricFunnels = metricFunnels;
      },
      () => this.utilityService.handleError({ message: 'Failed to load metrics data'})
    );
  }

  removeFunnelExample() {
    this.metricFunnels = this.metricFunnels.filter(funnel => funnel.id !== 0);
  }

  createFunnelExample(fillData: boolean): number {
    const currentList = this.metricFunnels;
    const allFunnelsNames = MetricFunnelsPageService.getFunnelsNamesList(currentList);
    const newFunnel = {
      id: 0,
      order: 0,
      activated: false,
      productName: MetricFunnelsPageService.getNewFunnelName('Funnel', allFunnelsNames),
      revenueToProfit: fillData ? 0 : null, // change default to 0 for RTP %
      metricFunnelRows: [
        {
          countMetric: {
            id: null,
            name: 'Leads',
            target: fillData ? 2000 : null,
            isHidden: false,
            revenuePerOutcome: fillData ? 50 : null,
            valueType: MetricValueType.Count,
            rowIndex: 0,
            conversionRate: fillData ? 5 : null,
          },
          currencyMetric: null,
          conversionRate: fillData ? 5 : null,
        },
        {
          countMetric: {
            id: null,
            name: 'Opportunities',
            target: fillData ? 100 : null,
            isHidden: false,
            revenuePerOutcome: fillData ? 1000 : null,
            valueType: MetricValueType.Count,
            usageCount: 0,
            rowIndex: 1,
            conversionRate: fillData ? 10 : null,
          },
          currencyMetric: {
            id: null,
            name: 'Pipeline',
            target: fillData ? 1000000 : null,
            isHidden: false,
            valueType: MetricValueType.Currency,
            usageCount: 0,
            rowIndex: 1,
            conversionRate: fillData ? 10 : null,
          },
          conversionRate: fillData ? 10 : null,
        },
        {
          isBaseRow: true,
          countMetric: {
            id: null,
            name: 'Deals',
            target: fillData ? 10 : null,
            isHidden: false,
            revenuePerOutcome: fillData ? 10000 : null,
            valueType: MetricValueType.Count,
            usageCount: 0,
            rowIndex: 2,
            conversionRate: 100,
          },
          currencyMetric: {
            id: null,
            name: 'Revenue',
            target: fillData ? 100000 : null,
            isHidden: false,
            valueType: MetricValueType.Currency,
            usageCount: 0,
            rowIndex: 2,
            conversionRate: 100,
          },
          conversionRate: 100,
        }
      ]
    };
    currentList.unshift(newFunnel);
    this.metricFunnels = currentList;
    return newFunnel.id;
  }

  duplicateFunnel(index: number): Observable<any> {
    const currentList = this.metricFunnels;
    const allFunnelsNames = MetricFunnelsPageService.getFunnelsNamesList(currentList);
    const copyOrder = index + 1;
    const funnelCopy = MetricFunnelsPageService.createFunnelCopy(currentList[index], allFunnelsNames, copyOrder);
    currentList.splice(copyOrder, 0, funnelCopy);
    this.metricFunnels = currentList;
    return this.createFunnelData$({ ...funnelCopy }, currentList);
  }

  downloadFunnel(id: number, funnelRows: MetricFunnelRow[], callback: Function = null): void {
    const config: FunnelPresentationConfig = {
      funnelElementSelector: `product-funnel[data-funnel-id="funnel-${id}"]`,
      beforeFunnelSnapshotCreation: (funnel) => {
        const metricBlocks = funnel.querySelectorAll<HTMLElement>('.metric-block.has-metric');
        const metricMasterBlocks = funnel.querySelectorAll<HTMLElement>('plc-metric-master');
        metricBlocks.forEach(el => {
          el.style.border = '1px solid #DEE6F1';
        });
        metricMasterBlocks.forEach(el => {
          el.style.borderRight = '1px solid #DEE6F1';
        });

        const productName = funnel.querySelector<HTMLElement>('funnel-name-input .spacer');
        const productNameInput = funnel.querySelector<HTMLInputElement>('funnel-name-input input.control');
        productName.style.opacity = '1';
        productName.textContent = productNameInput.value;

        const panelBody = funnel.querySelector<HTMLElement>('.mat-expansion-panel-body');
        const panelContent = funnel.querySelector<HTMLElement>('.mat-expansion-panel-content');
        const panelHeader = funnel.querySelector<HTMLElement>('.mat-expansion-panel-header');
        panelBody.style.padding = '10px 60px 30px';
        panelHeader.style.padding = '40px 60px 30px 60px';
        panelContent.style.visibility = 'visible';
        panelContent.style.height = 'auto';

        const viewTogglers = funnel.querySelector<HTMLElement>('view-togglers');
        viewTogglers.hidden = false;
      },
      data: {
        funnelRows,
      },
      funnelOnly: true,
      excludeDemoSlide: true
    };

    setTimeout(() => {
      this.funnelPresentationService.generate(config, callback);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createFunnelData$(funnel: ProductMetricFunnel, allFunnels: ProductMetricFunnel[]): Observable<any> {
    const productDO: Omit<ProductDO, 'id'> = {
      company: this.companyId,
      name: funnel.productName,
      order: funnel.order,
      activated: funnel.activated,
    };

    if (funnel.revenueToProfit != null) {
      productDO.revenue_to_profit = funnel.revenueToProfit;
    }

    if (funnel.budgetCalculatorData?.targetedMetricId) {
      productDO.budget_calculator_data = funnel.budgetCalculatorData;
    }

    return this.productService.createProduct(productDO).pipe(
      tap(createdProductDO => {
        funnel.id = createdProductDO.id;
        allFunnels.forEach((targetFunnel, index) => {
          targetFunnel.order = index;
        });
      }),
      switchMap(() =>
        forkJoin([
          this.metricService.createMultiMetrics(this.getMetricDOs(funnel)),
          this.updateFunnelsOrder$(allFunnels.filter(targetFunnel => targetFunnel.order > funnel.order))
        ])
      ),
      tap(([createdMetricDOs]) => {
        this.applyCreatedFunnelMetrics(createdMetricDOs, funnel);
        this.updateFunnelsSubject(0, funnel);

        this.pendoManager.track(PendoEventName.MetricFunnelCreated, {});
      }),
    );
  }

  updateFunnelData(diff: Partial<FunnelStateDifferences>, funnel: ProductMetricFunnel): Observable<any> {
    const funnel$ = Object.values(diff.funnel).length ? this.updateFunnelOwnProps$(diff.funnel, funnel) : of(true);
    const create$ = diff.metrics?.create?.length ? this.createMetrics(diff.metrics.create, funnel) : of([]);
    const update$ = diff.metrics?.update?.length ? this.updateMetrics$(diff.metrics.update, funnel) : of([]);
    const delete$ = diff.metrics?.delete?.length ? this.deleteMetrics$(diff.metrics.delete) : of([]);

    return forkJoin([ funnel$, update$, create$, delete$ ]).pipe(
      tap(() => {
        this.updateFunnelsSubject(funnel.id, funnel);
        this.pendoManager.track(PendoEventName.MetricFunnelUpdated, {});
      })
    );
  }

  createMetrics(metrics: MetricMaster[], funnel: ProductMetricFunnel): Observable<MetricDO[]> {
    return this.metricService.createMultiMetrics(this.getMetricDOs(funnel, metrics)).pipe(
      tap(createdMetricDOs => this.applyCreatedFunnelMetrics(createdMetricDOs, funnel))
    );
  }

  updateMetrics$(metrics: MetricMaster[], funnel: ProductMetricFunnel): Observable<MetricDO[]> {
    return this.metricService.updateMultiMetrics(this.getMetricDOs(funnel, metrics));
  }

  deleteMetrics$(metricIds: number[]): Observable<any> {
    return this.metricService.deleteMultiMetrics(metricIds);
  }

  updateFunnelOwnProps$(
    props: Partial<ProductMetricFunnel>,
    funnel: ProductMetricFunnel,
    skipSubjectUpdate: boolean = false
  ): Observable<ProductMetricFunnel> {
    return this.productService.updateProduct(funnel.id, MetricFunnelsPageService.getProductDO(props)).pipe(
      map(productDO => ({ ...MetricFunnelsPageService.getFunnel(productDO), metricFunnelRows: funnel.metricFunnelRows })),
      tap(updatedFunnel => {
        if (!skipSubjectUpdate) {
          this.updateFunnelsSubject(funnel.id, updatedFunnel);
        }
      })
    );
  }

  updateFunnelsSubject(targetId, updatedFunnel) {
    const currentList = this._metricFunnels$.getValue();
    const index = currentList.findIndex(item => item.id === targetId);
    currentList[index] = updatedFunnel;
    this.metricFunnels = currentList;
  }

  set metricFunnels(funnels: ProductMetricFunnel[]) {
    this._metricFunnels$.next(funnels);
  }

  get metricFunnels(): ProductMetricFunnel[] {
    return this._metricFunnels$.getValue();
  }

  getCampaignsUsingMetric$(metricId: number): Observable<MetricMappingDO[]> {
    const { OBJECT_TYPES: objectTypes } = this.configuration;
    return this.metricService.getMetricMappings(this.companyId, { metric_master: metricId, mapping_type: objectTypes.campaign });
  }

  removeFunnel(funnel: ProductMetricFunnel): Observable<void> {
    const metricIds = funnel.metricFunnelRows.reduce((ids, row: MetricFunnelRow) => {
      Object.values(row).forEach(value => {
        if (typeof value === 'object' && value?.id) {
          ids.push(value.id)
        }
      })
      return ids;
    }, []);
    const deleteProduct$ = this.productService.deleteProduct(funnel.id);
    const deleteMetrics$ = metricIds.length ? this.metricService.deleteMultiMetrics(metricIds) : of(null);
    return deleteMetrics$.pipe(switchMap(() => deleteProduct$));
  }

  private getMetricsData(products: ProductDO[], metrics: MetricDO[]): [MetricMaster[], ProductMetricFunnel[]] {
    const funnelMetric = (productId: number) => {
      return productId && products.find(prod => prod.id === productId);
    }
    const standaloneMetrics =
      (metrics || []).filter(metric => !funnelMetric(metric.product))
        .map(metric => MetricFunnelsPageService.createMetricMaster(metric))
        .sort((m1, m2) => m1.rowIndex - m2.rowIndex);

    const metricFunnels =
      (products || []).map(
        product => MetricFunnelsPageService.createMetricFunnel(product, (metrics || []).filter(metric => metric.product === product.id))
      );

    return [standaloneMetrics, metricFunnels];
  }

  private getMetricDOs(funnel: ProductMetricFunnel, metricsToGet: MetricMaster[] = null): MetricDO[] {
    const baseRowIndex = (funnel.metricFunnelRows || []).findIndex(row => row.isBaseRow);
    return (funnel?.metricFunnelRows || []).reduce(
      (metricDOs, funnelRow, index) => {
        if (funnelRow.countMetric && (!metricsToGet || metricsToGet.some(metric => metric.name === funnelRow.countMetric.name))) {
          metricDOs.push(
            this.getMetricDO(funnelRow.countMetric, false, index, funnelRow.conversionRate, funnel.id)
          );
        }
        if (funnelRow.currencyMetric && (!metricsToGet || metricsToGet.some(metric => metric.name === funnelRow.currencyMetric.name))) {
          metricDOs.push(
            this.getMetricDO(
              {
                ...funnelRow.currencyMetric,
                revenuePerOutcome: MetricFunnelsPageService.getRPOForCurrencyMetric(funnel.metricFunnelRows, index, baseRowIndex)
              },
              true,
              index,
              funnelRow.conversionRate,
              funnel.id
            )
          );
        }
        return metricDOs;
      },
      [] as MetricDO[]
    );
  }

  private getMetricDO(metric: MetricMaster, isCurrency: boolean, order: number, conversionRate: number, productId?: number): MetricDO {
    const metricDO: MetricDO = {
      id: metric.id,
      conversion: conversionRate,
      name: metric.name,
      order,
      revenue_per_outcome: metric.revenuePerOutcome,
      target: metric.target,
      with_currency: isCurrency,
      company: this.companyId,
      is_hidden: metric.isHidden,
    };
    if (productId) {
      metricDO.product = productId;
    }
    return metricDO;
  }

  private applyCreatedFunnelMetrics(createdMetricDOs: MetricDO[], funnel: ProductMetricFunnel) {
    const applyCreatedMetricToFunnel = (metricDO: MetricDO) => {
      for (const row of funnel.metricFunnelRows) {
        if (row.countMetric?.name === metricDO.name) {
          row.countMetric.id = metricDO.id;
          break;
        } else if (row.currencyMetric?.name === metricDO.name) {
          row.currencyMetric.id = metricDO.id;
          break;
        }
      }
    };
    createdMetricDOs.forEach(metricDO => applyCreatedMetricToFunnel(metricDO));
  }

  private updateFunnelsOrder$(funnels: ProductMetricFunnel[]): Observable<any> {
    return funnels?.length ?
      forkJoin(
        funnels.map(funnel => this.productService.updateProduct(funnel.id, { order: funnel.order }))
      ) :
      of([]);
  }

  showConfirmDialog(
    isUsed: boolean,
    cbFunction: () => void,
    unitName: MetricUnit,
    message: string,
  ): void {
    const dialogData: DeleteProductDialogContext = {
      title: DELETE_OBJECT.TITLE.replace(objectPlaceholderName, unitName),
      content: isUsed
        ? message
        : DELETE_OBJECT.CONFIRM_QUESTION.replace(objectPlaceholderName, unitName),
      isMetricUsed: isUsed,
      headerIcon: ['fad', 'trash-alt'],
      cancelAction: {
        label: 'Cancel',
        handler: () => null
      },
      submitAction: {
        label: DELETE_OBJECT.SUBMIT_BUTTON_LABEL.replace(objectPlaceholderName, unitName),
        handler: () => cbFunction(),
        disabled: isUsed
      }
    };

    this.dialog.open(DeleteMetricModalComponent, {
      width: '480px',
      data: dialogData,
      autoFocus: false
    });
  }

  showProductConfirmDialog(
    hasUsageMetric: boolean,
    cbFunction: () => void
  ): void {
    this.showConfirmDialog(hasUsageMetric, cbFunction, MetricUnit.FUNNEL, DELETE_OBJECT.PRODUCT_CONTENT);
  }

  showMetricConfirmDialog(
    isMetricUsed: boolean,
    cbFunction: () => void
  ): void {
    this.showConfirmDialog(isMetricUsed, cbFunction, MetricUnit.METRIC, DELETE_OBJECT.METRIC_CONTENT);
  }

  openManagePageForObjects(metricId: number): void {
    this.utilityService.showLoading(true);
    this.getCampaignsUsingMetric$(metricId).pipe(
      catchError(err => {
        this.utilityService.handleError({ message: 'Failed to load campaigns or mappings'});
        return throwError(err);
      }),
      mergeMap(allMappings => {
        this.utilityService.showLoading(false);
        const groupedByBudget = MetricFunnelsPageService.groupMappingsByBudget(allMappings, this.budgetDataService.budgetListSnapshot)
        const usedBudgets = Object.keys(groupedByBudget);

        let selectedGroup$: Observable<MetricMappingGroup>;
        if (usedBudgets.length === 1 && +usedBudgets[0] === this.budgetId) {
          // all mappings related to currently selected budget
          selectedGroup$ = of(groupedByBudget[this.budgetId]);
        } else {
          const dialogRef = this.dialog.open(ChooseBudgetDialogComponent, {
            width: '480px',
            data: {
              currentBudgetId: this.budgetId,
              groups: Object.values(groupedByBudget),
            },
            autoFocus: false
          });
          selectedGroup$ = dialogRef.afterClosed() as Observable<MetricMappingGroup>;
        }

        return selectedGroup$
          .pipe(
            mergeMap((group: MetricMappingGroup) => {
              if (!group) {
                // canceled in modal
                return of(null);
              }
              const switchBudget$ = new BehaviorSubject(null);
              if (group.budgetId !== this.budgetId) {
                this.budgetDataService.selectBudget(group.budgetId, () => switchBudget$.next(group))
              } else {
                switchBudget$.next(group);
              }
              return switchBudget$
            })
          )
      }),
      take(1),
      takeUntil(this.destroy$),
    ).subscribe( (group: MetricMappingGroup) => {
        if (group?.campaignIds) {
          const pageContext: BackNavigationContext = {
            pageName: 'Funnels page',
            route: this.router.routerState.snapshot.url.split('?')[0],
          }
          this.appRoutingService.openManagePageForObjects(metricId, pageContext);
        }
      }
    )
  }

  public readState(): number {
    let restoredActiveMetricMasterId = null;
    const state = LocalStorageService.getFromStorage<string>(LS_KEY_ACTIVE_METRIC_MASTER_ID);
    if (state) {
      if (this.appRoutingService.lastNavigationStartTrigger === 'popstate' || this.appRoutingService.shouldRestoreState) {
        restoredActiveMetricMasterId = Number(state);
        this.appRoutingService.closeAllDetailsWindows();
      }
      LocalStorageService.removeFromStorage(LS_KEY_ACTIVE_METRIC_MASTER_ID);
    }
    return restoredActiveMetricMasterId;
  }
}
