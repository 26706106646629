import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { messageGetters, messages, objectPlaceholderName } from '../messages';
import { inject, Injectable } from '@angular/core';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { BudgetObjectDetailsManager } from './budget-object-details-manager.service';
import { CampaignDetailsService } from './campaign-details.service';
import { MetricIntegrationsProviderService } from '../../metric-integrations/services/metric-integrations-provider.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetObjectService } from '@shared/services/budget-object.service';
import { BudgetObjectActionsBuilder } from './budget-object-actions-builder.service';
import { LightProgram, ProgramDO, ProgramTypeDO } from '@shared/types/program.interface';
import { CampaignAllocationDO, CampaignDO, CampaignTypeDO, LightCampaign } from '@shared/types/campaign.interface';
import { ExpenseDO } from '@shared/types/expense.interface';
import { DetailsExpensesService } from '../components/details-expenses/details-expenses.service';
import { DetailsExpensesData } from '../components/details-expenses/details-expenses-table/details-expenses-table.type';
import { Configuration } from 'app/app.constants';
import { CampaignDetailsState, ObjectDetailsCommonState } from '../types/budget-object-details-state.interface';
import { ObjectHierarchyService } from './object-hierarchy.service';
import { ObjectHierarchy } from '../components/object-hierarchy-nav/object-hierarchy-nav.type';
import { Goal } from '@shared/types/goal.interface';
import { BusinessValueService } from '../components/business-value/business-value.service';
import { MetricsUtilsService } from './metrics-utils.service';
import { Metric } from '../components/details-metrics/details-metrics.type';
import { MetricDataDO } from '@shared/services/backend/metric.service';
import { MetricType } from '@shared/types/budget-object-metric.interface';
import { ProductDO } from '@shared/services/backend/product.service';
import { SegmentDataInheritanceAction } from '@shared/types/segment-data-inheritance.interface';
import { Budget } from '@shared/types/budget.interface';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { Tag } from '@shared/types/tag.interface';
import { BudgetObjectTagsService } from './budget-object-tags.service';
import { TagMapping } from '@shared/types/tag-mapping.interface';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { FormGroup } from '@angular/forms';
import { DrawerFormFields } from '../components/containers/details-drawer-form';
import { BudgetObjectTasksService } from './budget-object-tasks.service';
import { BudgetObjectCreationContext } from '../types/details-creation-context.interface';
import { Integration } from '../../metric-integrations/types/metrics-provider-data-service.types';
import { MetricIntegrations } from '../../metric-integrations/types/metric-integrations-status.interface';
import { ComponentType } from '@angular/cdk/portal';
import { MetricMappingDialogData } from '../../metric-integrations/components/metric-mapping-dialog/dialog-data.type';
import { MetricIntegrationDisplayName, MetricIntegrationName } from '../../metric-integrations/types/metric-integration';
import { dialogConfig } from '../../metric-integrations/components/metric-mapping-dialog/dialog-window.config';
import { CompanyDO } from '@shared/types/company.interface';
import { TagControlEvent } from '@shared/components/tags-control/tags-control.component';
import { TaskListChangeEvent } from '../components/tasks-list/tasks-list.component';
import { createDeepCopy } from '@shared/utils/common.utils';
import { GoalDetailsService } from 'app/budget-object-details/services/goal-details.service';
import { SegmentGroup } from '@shared/types/segment-group.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { CommonObject } from '@shared/types/budget-object.interface';
import { HierarchySelectConfig, HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { ProgramAllocation } from '@shared/types/budget-object-allocation.interface';
import { ProgramDetailsService } from './program-details.service';
import { ExpenseDetailsService } from './expense-details.service';
import { CampaignService } from '@shared/services/backend/campaign.service';
import { GoalsService } from '@shared/services/backend/goals.service';

@Injectable()
export class BudgetObjectActionsShared {
  protected readonly dialogManager = inject(BudgetObjectDialogService);
  protected readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  protected readonly appRoutingService = inject(AppRoutingService);
  protected readonly campaignDetailsService = inject(CampaignDetailsService);
  protected readonly goalDetailsService = inject(GoalDetailsService);
  protected readonly programDetailsService = inject(ProgramDetailsService);
  protected readonly expenseDetailsService = inject(ExpenseDetailsService)
  protected readonly tagsManager = inject(BudgetObjectTagsService);
  protected readonly integrationProvidersService = inject(MetricIntegrationsProviderService);
  protected readonly companyDataService = inject(CompanyDataService);
  protected readonly budgetObjectService = inject(BudgetObjectService);
  protected readonly menuActionsBuilder = inject(BudgetObjectActionsBuilder);
  protected readonly detailsExpensesService = inject(DetailsExpensesService);
  protected readonly configuration = inject(Configuration);
  protected readonly hierarchyService = inject(ObjectHierarchyService);
  protected readonly metricsUtilsService = inject(MetricsUtilsService);
  private readonly campaignService = inject(CampaignService);
  private readonly goalService = inject(GoalsService);

  private static isDirectCampaignExpense(expense: ExpenseDO, campaignId: number) {
    return expense.campaign === campaignId && expense.program == null;
  }

  public static getDefaultTypeId(types: BudgetObjectType[]): number | null {
    return types.find(cType => cType.name === Configuration.defaultObjectTypeName)?.id || null;
  }

  public static processSegmentChangeAction(
    currentState: ObjectDetailsCommonState,
    action: SegmentDataInheritanceAction,
    segmentId: number,
    sharedCostRuleId: number,
    isNewCEGStructure: boolean
  ): void {
    if (!action) {
      // segment changed without confirmation dialog
      return;
    }

    if (action === SegmentDataInheritanceAction.Keep) {
      currentState.spreadSegmentToChildren = false;
      return;
    }

    if (action === SegmentDataInheritanceAction.Replace) {
      BudgetObjectActionsShared.processSegmentReplace(currentState, segmentId, sharedCostRuleId, isNewCEGStructure);
    }
  }

  public static processSegmentReplace(
    currentState: ObjectDetailsCommonState,
    segmentId: number,
    sharedCostRuleId: number,
    isNewCEGStructure: boolean
  ): void {
    const applySegmentData = (objectsKey: 'expenses' | 'campaigns' | 'programs'): void => {
      if (currentState[objectsKey]?.length > 0) {
        currentState[objectsKey] =
          currentState[objectsKey].map(item => ({
            ...item,
            company_budget_segment1: segmentId,
            split_rule: sharedCostRuleId
          }));
      }
    };
    if (!isNewCEGStructure) {
      applySegmentData('expenses');
    }
    applySegmentData('campaigns');
    applySegmentData('programs');

    currentState.spreadSegmentToChildren = true;
  }

  public static showSaveWithoutSegmentDialog(dialogManager: BudgetObjectDialogService) {
    const context = {
      title: 'Saving without a Segment',
      content: `In order to save this campaign without specifying it to a segment,
                you must first move the expenses attributed to this campaign into
                child campaigns or expense groups. The cost of this campaign will
                then be inherited from its children campaigns and expense groups.`,
      submitAction: { label: 'Ok', handler: null }
    };
    dialogManager.openConfirmationDialog(context, { width: '480px' })
  }

  public static openSegmentlessToRegularConfirmation(dialogManager: BudgetObjectDialogService): Observable<boolean> {
    return dialogManager.openAsyncConfirmationDialog<boolean>(
      true,
      false,
      'By assigning this campaign to a segment, the budget for this campaign will now be manually set rather than the sum of its child campaigns and expense groups.',
      'Change Campaign',
    );
  }

  public static saveTasks$<TargetObject>(
    tasksManager: BudgetObjectTasksService,
    targetObject: TargetObject,
    prevState: ObjectDetailsCommonState,
    currentState: ObjectDetailsCommonState,
    companyId: number,
    objectType: string,
    ): Observable<TargetObject > {
    return tasksManager.saveTasks({
      prevState: prevState?.tasks,
      nextState: currentState.tasks,
      objectId: currentState.objectId,
      objectType: objectType,
      companyId: companyId
    }).pipe(map(() => targetObject));
  }

  public static getContextForNewObjectCreation(currentState: ObjectDetailsCommonState): BudgetObjectCreationContext {
    return {
      parent: currentState.parentObject,
      segmentId: currentState.segment && currentState.segment.segmentId,
      sharedCostRuleId: currentState.segment && currentState.segment.sharedCostRuleId
    };
  }

  public static getContextForChildObjectCreation(objectType: string, currentState: ObjectDetailsCommonState): BudgetObjectCreationContext {
    return {
      parent: {
        id: currentState.objectId,
        type: objectType
      },
      segmentId: currentState.segment && currentState.segment.segmentId,
      sharedCostRuleId: currentState.segment && currentState.segment.sharedCostRuleId,
      objectTypeId: currentState.typeId,
      vendorId: currentState.vendor,
      glCodeId: currentState.glCode,
      poNumber: currentState.poNumber,
      ownerId: currentState.ownerId
    };
  }

  public static getMinEndDate(startDate: Date): Date {
    return startDate instanceof Date ? new Date(startDate.getTime()) : null;
  }

  private static createCustomObjectType(name: string, companyId: number, unsavedCustomTypeId: number): BudgetObjectType {
    return {
      id: unsavedCustomTypeId,
      name,
      isCustom: true,
      companyId,
      createdDate: null,
      updatedDate: null
    };
  }

  public static setConnectedIntegrations(
    allIntegrations: MetricIntegrations,
    connectedIntegrationsStore: Record<string, Integration[]>
  ): void {
    Object.entries(allIntegrations).forEach(([integrationType, integrationsBySource]) => {
      connectedIntegrationsStore[integrationType] = integrationsBySource || [];
    });
  }

  public static handleTasksUpdate(
    $event: TaskListChangeEvent,
    currentState: ObjectDetailsCommonState,
    prevState: ObjectDetailsCommonState,
    ) {
    currentState.tasks = $event.data;
    if ($event.saveState) {
      prevState.tasks = createDeepCopy(currentState.tasks);
    }
  }

  public static sumChildrenAllocations(allocations: (CampaignAllocationDO[] | ProgramAllocation[])[]): Record<string, number> {
    return allocations.reduce((sum, alloc) => {
      alloc.forEach(val => {
        if (!sum[val.company_budget_alloc]) {
          sum[val.company_budget_alloc] = 0;
        }
        sum[val.company_budget_alloc] += val.amount;
      });
      return sum;
    }, {});
  }

  public static objectHasChildren(currentState: ObjectDetailsCommonState): boolean {
    return currentState.expenses?.length > 0 ||
           currentState.campaigns?.length > 0 ||
           currentState.programs?.length > 0;
  }

  public static getSegmentSelectConfig(initeilConfig: HierarchySelectConfig, isSegmentFieldRequired: boolean): HierarchySelectConfig {
    return {
      ...initeilConfig,
      emptyValueLabel: isSegmentFieldRequired ? null : 'No Segment',
      fieldLabel: isSegmentFieldRequired ? 'Segment *' : 'Segment'
    };
  }

  public handleDeleteCampaign(
    currentState: ObjectDetailsCommonState,
    objectType: string,
    companyId: number,
    isDeleteAction,
    onSuccessCb: (message: string, close: boolean, ranInBackground?: boolean) => void,
    onErrorCb: (error, message, close?: boolean) => void
  ): void {
    this.dialogManager.openDeleteEntityDialog(() => {
      // this.budgetObjectDetailsManager.detachDirectChildObjects({
      //   programs: currentState.programs,
      //   expenses: currentState.expenses
      // }, objectType.toLowerCase())
      //   .pipe(
      //     switchMap(() => forkJoin([
      //       this.budgetObjectDetailsManager.deleteMetricMappings(currentState.metricMappings),
      //       this.budgetObjectDetailsManager.deleteIntegratedExpenses(currentState.expenses)
      //     ])),
      //     switchMap(() => this.campaignDetailsService.deleteObject(currentState.objectId)),
      //     tap(() => isDeleteAction.value = true),
      //     switchMap(() => {
      //       const enabledMetricIntegrationsNames = this.companyDataService.enabledMetricIntegrationsNames;
      //       return !enabledMetricIntegrationsNames.length ? of(null) : forkJoin(
      //         enabledMetricIntegrationsNames.map(integrationName => {
      //           return this.integrationProvidersService.metricIntegrationProviderByType(integrationName)
      //             ?.deleteCampaignsMappings(
      //               companyId, [currentState.objectId]
      //             ).pipe(
      //               catchError(() => of(null))
      //             ) || of(null);
      //         })
      //       );
      //     }),
      //   ).subscribe({
      //     next: () => {
      //       onSuccessCb(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, objectType), true);
      //       this.budgetObjectDetailsManager.reportDrawerDetailsChange(currentState.objectId, objectType);
      //     },
      //     error: error =>
      //       onErrorCb(error, messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, objectType.toLowerCase()))
      //   });

      // Commenting the above single object delete code and replacing it with multi_delete api call
      this.campaignService.deleteMultiCampaigns([currentState.objectId]).subscribe({
            next: () => {
              onSuccessCb(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, objectType), true);
              this.budgetObjectDetailsManager.reportDrawerDetailsChange(currentState.objectId, objectType);
            },
            error: error =>
              onErrorCb(error, messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, objectType.toLowerCase()))
          }) 
    }, this.budgetObjectService.getObjectTypeName(objectType));
  }

  public handleDeleteGoal(
    currentState: ObjectDetailsCommonState,
    objectType: string,
    isDeleteAction,
    onSuccessCb: (message: string, close: boolean, ranInBackground?: boolean) => void,
    onErrorCb: (error, message, close?: boolean) => void
  ): void {
    this.dialogManager.openDeleteEntityDialog(() => {
    
    //   this.budgetObjectDetailsManager.detachDirectChildObjects({
    //     campaigns: currentState.campaigns,
    //     programs: currentState.programs,
    //     expenses: currentState.expenses
    //   }, objectType.toLowerCase())
    //   .pipe(
    //     switchMap(() => this.budgetObjectDetailsManager.deleteMetricMappings(currentState.metricMappings)),
    //     tap(() => isDeleteAction.value = true),
    //     switchMap(() => this.goalDetailsService.deleteObject(currentState.objectId))
    //   )
    //   .subscribe({
    //     next: () => {
    //       onSuccessCb(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, objectType), true);
    //       this.budgetObjectDetailsManager.reportDrawerDetailsChange(currentState.objectId, objectType);
    //     },
    //     error: error =>
    //       onErrorCb(error,  messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, objectType.toLowerCase()))
    //   });

    // Commenting the above single object delete code and replacing it with multi_delete api call
    this.goalService.deleteMultiGoals([currentState.objectId]).subscribe({
          next: () => {
            onSuccessCb(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, objectType), true);
            this.budgetObjectDetailsManager.reportDrawerDetailsChange(currentState.objectId, objectType);
          },
          error: error => onErrorCb(error,  messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, objectType.toLowerCase()))
        })
    }, this.budgetObjectService.getObjectTypeName(objectType));
  }

  public updateDetailsExpensesData(
    campaigns: CampaignDO[],
    programs: ProgramDO[],
    expenses: ExpenseDO[],
    objectId: number,
    objectType: string,
  ): DetailsExpensesData {
    if (objectType === this.configuration.OBJECT_TYPES.campaign) {
      expenses = (expenses || []).filter(expense => BudgetObjectActionsShared.isDirectCampaignExpense(expense, objectId));
    }
    return this.detailsExpensesService.prepareData({
      campaigns,
      programs,
      expenses
    });
  }

  public getHierarchyObjectType(state: CampaignDetailsState): string {
    const { OBJECT_TYPES } = this.configuration;
    return state.parentCampaignId ? OBJECT_TYPES.childCampaign : OBJECT_TYPES.campaign;
  }

  public buildObjectHierarchy(
    objectDetailsState: CampaignDetailsState,
    goals: Goal[],
    campaigns: LightCampaign[],
    programs: LightProgram[],
    segmentGroups?: SegmentGroup[],
    segments?: BudgetSegmentAccess[]
  ): ObjectHierarchy {
    const hierarchyObjectType = this.getHierarchyObjectType(objectDetailsState);
    const fullSegment = segments?.find(item => item.id === objectDetailsState.segment.segmentId);
    return this.hierarchyService.buildObjectHierarchy(
      {
        id: objectDetailsState.objectId,
        name: objectDetailsState.name,
        parentCampaign: objectDetailsState.parentCampaignId,
        campaignId: objectDetailsState.parentCampaignId, // TODO: check if 'campaignId' should be excluded in some cases
        goalId: objectDetailsState.goalId,
        mode: objectDetailsState?.mode,
        segmentId: objectDetailsState.segment.segmentId,
        segmentGroupId: segmentGroups?.find(item => item.id === fullSegment?.segment_group)?.id,
        campaigns: objectDetailsState.campaigns as CommonObject[],
        programs: objectDetailsState.programs
      },
      hierarchyObjectType,
      { goals, campaigns, programs, segmentGroups, segments }
    );
  }

  public buildChildHierarchy(
    objectDetailsState: CampaignDetailsState,
    campaigns: LightCampaign[],
    programs: LightProgram[],
  ): HierarchySelectItem[] {
    const hierarchyObjectType = this.getHierarchyObjectType(objectDetailsState);

    return this.hierarchyService.buildChildHierarchy(
      {
        id: objectDetailsState.objectId,
        name: objectDetailsState.name,
        mode: objectDetailsState?.mode,
        campaigns: objectDetailsState.campaigns as CommonObject[],
        programs: objectDetailsState.programs
      },
      hierarchyObjectType,
      { campaigns, programs }
    );
  }

  public handleBudgetToMoveSelected(
    budget: Budget,
    companyId: number,
    objectId: number,
    objectType: string,
    isCurrentCEGBudget: boolean,
    onSuccess?: () => void
  ): void {
    const typeToService = {
      [this.configuration.OBJECT_TYPES.goal]: this.goalDetailsService,
      [this.configuration.OBJECT_TYPES.campaign]: this.campaignDetailsService,
      [this.configuration.OBJECT_TYPES.program]: this.programDetailsService,
      [this.configuration.OBJECT_TYPES.expense]: this.expenseDetailsService
    };
    const displayedObjectType = objectType === this.configuration.OBJECT_TYPES.program ? 'Expense Group' : objectType;

    const errorMessage = messages.UNABLE_TO_MOVE_TO_BUDGET_ERROR_MSG.replace(objectPlaceholderName, displayedObjectType.toLowerCase());
    const successMessage = messageGetters.MOVE_OBJECT_SUCCESS(displayedObjectType, { budgetName: budget.name });
    const moveChain$ = typeToService[objectType].moveToBudget(objectId, budget.id, companyId)
      .pipe(
        tap(() => {
          if (isCurrentCEGBudget || objectType === this.configuration.OBJECT_TYPES.expense) {
            this.appRoutingService.closeActiveDrawer();
          } else {
            this.appRoutingService.closeDetailsPage();
          }
          this.budgetObjectDetailsManager.reportDrawerDetailsChange(objectId, objectType);
          onSuccess?.();
        })
      );

    this.budgetObjectDetailsManager.moveObject({
      moveChain$,
      budgetId: budget.id,
      objectId,
      objectType,
      successMessage,
      errorMessage
    });
  }

  public saveContextData$(
    companyId: number,
    objectType: string,
    currentTypeId: number,
    objectTypes: BudgetObjectType[],
    tagMappings: TagMapping[],
    objectForm: FormGroup
  ): Observable<[Tag[], CampaignTypeDO | ProgramTypeDO]> {
    let campaignCustomTypeName = '';
    if (currentTypeId === this.budgetObjectDetailsManager.unsavedCustomTypeId) {
      const campaignCustomType = objectTypes.find(
        cType => cType.isCustom && cType.id === this.budgetObjectDetailsManager.unsavedCustomTypeId
      );
      campaignCustomTypeName = campaignCustomType ? campaignCustomType.name : '';
    }

    return forkJoin([
      this.tagsManager.createTags(companyId, this.tagsManager.getLocalTags()),
      this.budgetObjectDetailsManager.createObjectCustomType(companyId, objectType, campaignCustomTypeName)
    ]).pipe(
      tap(([addedTags, addedCustomType]) => {
        this.applyCreatedTags(addedTags, companyId, tagMappings);
        this.applyCreatedCustomType(addedCustomType, objectTypes, objectForm, companyId);
      })
    );
  }

  private applyCreatedTags(addedTags: Tag[], companyId: number, tagMappings: TagMapping[]): void {
    if (addedTags?.length) {
      this.companyDataService.loadTags(companyId);
      this.tagsManager.applyAddedTags(addedTags, tagMappings);
    }
  }

  private applyCreatedCustomType(
    addedCustomType: CampaignTypeDO,
    objectTypes: BudgetObjectType[],
    objectForm: FormGroup,
    companyId: number
  ): void {
    if (addedCustomType) {
      this.companyDataService.loadObjectTypes(companyId);
      const newCustomTypeIndex = objectTypes.findIndex(
        cType => cType.isCustom && cType.id === this.budgetObjectDetailsManager.unsavedCustomTypeId
      );
      if (newCustomTypeIndex) {
        objectTypes[newCustomTypeIndex] = CompanyDataService.convertBudgetObjectType(addedCustomType);
      }
      objectForm.patchValue({ [DrawerFormFields.typeId]: addedCustomType.id });
    }
  }

  public saveMappings<TargetObject>(
    targetObject: TargetObject,
    prevState: ObjectDetailsCommonState,
    currentState: ObjectDetailsCommonState,
    companyId: number,
    objectType: string,
    ): Observable<TargetObject> {
    const tagInc = this.tagsManager.getTagsIncrement(prevState?.tagMappings, currentState?.tagMappings);
    const objectId = currentState.objectId;

    let inheritParentMetrics$: Observable<Metric[]> = of(null);
    if (objectType === this.configuration.OBJECT_TYPES.campaign) {
      inheritParentMetrics$ =
        currentState.goalId === prevState?.goalId && currentState.parentCampaignId === prevState.parentCampaignId ?
          of(currentState.metricMappings) :
          this.budgetObjectDetailsManager.inheritParentMetrics(
            companyId,
            currentState.metricMappings,
            objectId,
            objectType,
            currentState.parentObject?.id,
            currentState.parentObject?.type
          );
    }

    return forkJoin([
      this.tagsManager.deleteTagMappings(tagInc.deleted),
      this.tagsManager.createTagMappings(objectId, objectType, tagInc.created),
      inheritParentMetrics$
    ]).pipe(
      tap(([, createdTags, allMetricMappings]) => {
        this.tagsManager.applyCreatedMappings(currentState.tagMappings, createdTags);
        if (allMetricMappings) {
          currentState.metricMappings = allMetricMappings;
        }
      }),
      map(() => targetObject)
    );
  }


  public updateTagMappings$(
    targetObject: CampaignDO | ProgramDO,
    prevState: ObjectDetailsCommonState,
    currentState: ObjectDetailsCommonState,
    companyId: number,
    objectType: string,
  ): Observable<CampaignDO | ProgramDO> {
    return this.budgetObjectDetailsManager.processDynamicTagMappings(
      companyId,
      targetObject.id,
      currentState.tagMappings,
      objectType,
      prevState?.parentObject,
      currentState.parentObject
    ).pipe(
      tap(result => {
        this.tagsManager.applyDeletedMappings(currentState.tagMappings, result?.detached);
        this.tagsManager.applyAttachedDynamicMappings(currentState.tagMappings, result?.attached);
      }),
      map(() => targetObject)
    );
  }

  public handleCustomTypeChange(
    formData: FormGroup,
    objectTypes: BudgetObjectType[],
    companyId: number,
    prevTypeId: number
  ): void {
    const { unsavedCustomTypeId } = this.budgetObjectDetailsManager; // -1
    const customTypeName = formData.value[DrawerFormFields.customType];

    let typeId = prevTypeId || null;
    if (customTypeName) {
      const existingType = objectTypes.find( cType => cType.name.toLowerCase() === customTypeName.toLowerCase());
      if (existingType) {
        typeId = existingType.id;
      } else {
        const customType = BudgetObjectActionsShared.createCustomObjectType(customTypeName, companyId, unsavedCustomTypeId);
        objectTypes.push(customType);
        typeId = customType.id;
      }
    }

    formData.patchValue({
      [DrawerFormFields.typeId]: typeId,
      [DrawerFormFields.customType]: ''
    });
  }

  openMetricIntegrationMappingDialog<TComp, TObjMapping>(
    company: CompanyDO,
    budget: Budget,
    currentState: ObjectDetailsCommonState,
    integrationName: MetricIntegrationName,
    integrationId: string,
    readOnly: boolean,
    DialogComponent: ComponentType<TComp>,
    onSavedCb: (mapping: TObjMapping, campaignsForSync: number[]) => void
  ) {
    const data: MetricMappingDialogData = {
      companyId: company.id,
      campaignName: currentState.name,
      campaignMetrics: (currentState.metricMappings || []).map(mm => ({
        metricId: mm.typeId,
        productName: mm.productName,
        order: mm.order,
        productOrder: mm.productOrder,
        name: mm.name
      })),
      campaignId: currentState.objectId,
      dateFrom: currentState.startDate || budget.budget_from,
      budgetCurrencyCode: company.currency,
      budget,
      readOnly,
      integration: {
        name: integrationName,
        label: MetricIntegrationDisplayName[integrationName],
      },
      integrationId,
      onSavedCb
    };
    const config = { ...dialogConfig, data };
    this.dialogManager.openDialog<TComp>(DialogComponent, config);
  }


  public getUpdateIntegrationChain$(
    companyId: number,
    currentState: ObjectDetailsCommonState
  ): Observable<[MetricDataDO[], Metric[]]> {
    this.budgetObjectDetailsManager.reportCampaignMappingsChange();

    return this.campaignDetailsService.getCampaignMetricsData(
      companyId,
      currentState.objectId,
      currentState.campaigns.map(c => c.id),
    ).pipe(
      tap(([metricData, metricMappings]) => {
        currentState.metricData = metricData;
        currentState.metricMappings = metricMappings;
      })
    );
  }

  public createTag(tag: TagControlEvent, tagMappings: TagMapping[]): void {
    this.tagsManager.addLocalTag(tag);
    this.tagsManager.addLocalMapping(tag, tagMappings);
  }

  public addTag(tag: TagControlEvent, tagMappings: TagMapping[]): void {
    this.tagsManager.addLocalMapping(tag, tagMappings);
  }

  public removeTag(tag: TagControlEvent, tagMappings: TagMapping[]): void {
    this.tagsManager.deleteLocalTag(tag);
    this.tagsManager.deleteLocalMapping(tag, tagMappings);
  }

  public getBusinessValueForecast(
    keyMetricId: number,
    stateMetricMappings: Metric[],
    metricTypes: MetricType[],
    metricProducts: ProductDO[],
  ): { actualBusinessValue: number; targetBusinessValue: number; hasKeyMetric: boolean } {
    const metric = stateMetricMappings?.find(mm => mm.id === keyMetricId);
    let actualBusinessValue = null;
    let targetBusinessValue = null;

    if (metric) {
      const { revenuePerOutcome, revenueToProfit } =
        MetricsUtilsService.getMetricRPOAndRevenueToProfit(metric.typeId, metricTypes, metricProducts);

      actualBusinessValue =
        BusinessValueService.calcBusinessValue(
          MetricsUtilsService.getMetricCurrentValue(metric),
          revenuePerOutcome,
          revenueToProfit
        );

      targetBusinessValue =
        BusinessValueService.calcBusinessValue(
          this.metricsUtilsService.getTargetValue(metric.milestones),
          revenuePerOutcome,
          revenueToProfit
        );
    }
    return { actualBusinessValue, targetBusinessValue, hasKeyMetric: !!metric };
  }
}
