import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-metrics-done',
  templateUrl: './icon-metrics-done.component.html',
  styleUrls: ['./icon-metrics-done.component.scss']
})
export class IconMetricsDoneComponent {
  @Input() size = '24px';
}
