import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-flame',
  templateUrl: './icon-flame.component.html',
  styleUrls: ['./icon-flame.component.scss']
})

/**
* @class - IconFlameComponent
*/
export class IconFlameComponent {
  @Input() hasProblem: boolean;
}
