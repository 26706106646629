import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ManageCegBudgetMode, ManageCegDataMode, ManageCegViewMode } from '@manage-ceg/types/manage-ceg-page.types';

interface SwitchOption {
  mode: ManageCegViewMode | ManageCegBudgetMode;
  icon: IconProp;
  label: string;
}

@Component({
  selector: 'table-mode-switch',
  templateUrl: './table-mode-switch.component.html',
  styleUrls: ['./table-mode-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableModeSwitchComponent {
  @Input() isBudgetMode: boolean;
  @Input() viewMode: ManageCegViewMode;
  @Input() budgetMode: ManageCegBudgetMode;
  @Input() dataMode: ManageCegDataMode;

  @Output() onViewModeChange = new EventEmitter<ManageCegViewMode>();
  @Output() onBudgetModeChange = new EventEmitter<ManageCegBudgetMode>();

  public readonly ManageCegViewMode = ManageCegViewMode;
  public readonly ManageCegDataMode = ManageCegDataMode;

  protected readonly viewModeSwitchOptions: SwitchOption[] = [
    {
      mode: ManageCegViewMode.Segments,
      icon: ['far', 'chart-pie-simple'] as IconProp,
      label: 'Segment'
    },
    {
      mode: ManageCegViewMode.Goals,
      icon: ['fas', 'bullseye-arrow'] as IconProp,
      label: 'Goal'
    },
    {
      mode: ManageCegViewMode.Campaigns,
      icon: ['far', 'rocket-launch'] as IconProp,
      label: 'Campaign'
    }
  ];

  protected readonly budgetModeSwitchOptions: SwitchOption[] = [
    {
      mode: ManageCegBudgetMode.Table,
      icon: ['fas', 'table'] as IconProp,
      label: 'Table'
    },
    {
      mode: ManageCegBudgetMode.Chart,
      icon: ['fas', 'chart-column'] as IconProp,
      label: 'Chart'
    }
  ];

  setViewMode(mode: ManageCegViewMode): void {
    if (mode !== this.viewMode) {
      this.viewMode = mode;
      this.onViewModeChange.emit(mode);
    }
  }

  setBudgetMode(mode: ManageCegBudgetMode): void {
    if (mode !== this.budgetMode) {
      this.budgetMode = mode;
      this.onBudgetModeChange.emit(mode);
    }
  }
}
