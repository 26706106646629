<section class="budget-allocations" [class.suppressed-mode]="suppressedMode">
  <allocations-table
    [rows]="budgetTimeframesNames"
    [columns]="columns"
    [allocationsData]="tableData"
    [subtitles]="tableTotals"
    [currency]="currency"
    [externalIntegrationType]="externalIntegrationType"
    (onChange)="onTableDataChanged($event)"
    (onSubtitleValueChange)="onSubtitleValueChanged($event)"
  >
  </allocations-table>
</section>
