<div class="currencies-wrapper">
  <page-header pageName="Currencies" [isAdminPage]="true">
    <fa-icon slot="icon" [icon]="['fas', 'euro-sign']"></fa-icon>
  </page-header>
  <div class="base-currency-wrapper">
    <p class="base-currency-label">Planning currency:</p>
    <p class="base-currency">
    <span class="base-currency-value">
      {{ baseCurrency?.name }} ({{baseCurrency?.symbol}} {{baseCurrency?.code}})
    </span>
    </p>
  </div>
  <exchange-rates-table
    [data]="exchangeRatesTableData"
    (changeRate)="handleChangeRate($event);"
    (addCurrency)="handleAddCurrency($event);"
    (removeCurrency)="handleRemoveCurrency($event)">
  </exchange-rates-table>
  <show-hide-toggle *ngIf="auditLogs && auditLogs.length" title="View Change Log">
    <audit-logs [data]="auditLogs" (loadNewChunk)="handleLoadNewChunk();"></audit-logs>
  </show-hide-toggle>
</div>

