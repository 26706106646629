import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './user/components/login/login.component';
import { RegisterComponent } from './user/components/register/register.component';
import { RegisterConfirmationComponent } from './user/components/register/register-confirmation.component';
import { ForgotPasswordComponent } from './user/components/forgot-password/forgot-password.component';
import { Dashboard } from './dashboard/dashboard.component';
import { Widget } from './dashboard/widget.component';
import { PlanSetting } from './quick-start/plansetting.component';
import { UserComponent } from './user/components/user-profile/user.component';
import { SearchComponent } from './search/search.component';
import { UserRolesGuard } from './shared/guards/user-roles.guard';
import { AuthRequiredGuard } from './shared/guards/auth-required.guard';
import { AlreadyLoggedInGuard } from './shared/guards/already-logged-in.guard';
import { GoalDetailsComponent } from './budget-object-details/components/containers/goal-details/goal-details.component';
import { CampaignDetailsComponent } from './budget-object-details/components/containers/campaign-details/campaign-details.component';
import { ProgramDetailsComponent } from './budget-object-details/components/containers/program-details/program-details.component';
import { ExpenseDetailsComponent } from './budget-object-details/components/containers/expense-details/expense-details.component';
import { MarketingCalendarComponent } from './marketing-calendar/components/marketing-calendar/marketing-calendar.component';
import { ChangePasswordComponent } from './user/components/change-password/change-password.component';
import { GoalMetricDetailsComponent } from './budget-object-details/components/containers/goal-metric-details/goal-metric-details.component';
import { CampaignMetricDetailsComponent } from './budget-object-details/components/containers/campaign-metric-details/campaign-metric-details.component';
import { UnsubscribePageComponent } from './unsubscribe-page/unsubscribe-page.component';
import { UserRole } from './shared/types/user-role.interface';
import { HomePageComponent } from './home/components/home-page/home-page.component';
import { ExchangeRatesComponent } from './account/exchange-rates/exchange-rates.component';
import { SharedCostRulesManagementComponent } from './account/shared-cost-rule/shared-cost-rules-management/shared-cost-rules-management.component';
import { TagsManagementComponent } from './account/tags-management/tags-management.component';
import { GlCodesComponent } from './account/gl-codes/gl-codes.component';
import { ExpenseTypesComponent } from './account/expense-types/expense-types.component';
import { TeamPageComponent } from './account/team-page/team-page.component';
import { SSOGuard } from './shared/guards/sso.guard';
import { LeavePageGuard } from 'app/shared/guards/leave-page.guard';
import { BudgetSettingsPageComponent } from './budget-settings/components/budget-settings-page/budget-settings-page.component';
import { ManageSuperadminsComponent } from '../super-admin-app/manage-superadmins/manage-superadmins.component';
import { ManageCompanyComponent } from '../super-admin-app/manage_company/manage_company.component';
import { CustomDomainComponent } from './user/components/custom-domain/custom-domain.component';
import { LogoutComponent } from './user/components/custom-domain/logout.component';
import { NoAccessComponent } from './user/components/custom-domain/no-access.component';
import { ImportDataComponent } from './import-data/import-data.component';
import { ManagePageComponent } from './manage-table/components/manage-page/manage-page.component';
import { IntegrationMappingsComponent } from './metric-integrations/components/integration-mappings/integration-mappings.component';
import { MetricsPageComponent } from './metric-funnels/components/metrics-page/metrics-page.component';
import { SpendingComponent } from '@spending/components/spending/spending.component';
import { CampaignsMappingComponent } from './metric-integrations/components/campaigns-mapping/campaigns-mapping.component';
import { InvoicesComponent } from '@spending/components/spending/invoices/invoices.component';
import { ExpensesComponent } from '@spending/components/spending/expenses/expenses.component';
import { PageTitle } from '@shared/enums/page-title.enum';
import { InvoiceReviewComponent } from '@spending/components/spending/invoices/invoice-review/invoice-review.component';
import { SuperAdminGuard } from './user/guards/superadmin.guard';
import { ManageCegPageComponent } from '@manage-ceg/components/manage-ceg-page/manage-ceg-page.component';
import { CegStatusEnabledGuard } from '@shared/guards/ceg-status-enabled-guard.service';
import { DrawerStackComponent } from './budget-object-details/components/containers/drawer-stack/drawer-stack.component';
import { SsoFormComponent } from './shared/components/sso-form/sso-form.component';
import { AttributesAndTagsComponent } from './account/attributes-tags/attributes-tags.component';
import { CustomFieldsComponent } from './account/custom-fields/custom-fields.component';
import { CustomFieldGuard } from './shared/guards/custom-field.guard';

const superAdminsRedirectRoute = 'manage_company';
const managePageFallbackRoute = 'manage/budget/segments';
const manageCegPageFallbackRoute = 'manage_ceg/budget/segments';

const detailsRouteData = {
  animation: 'openDetails',
  superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute, closeDetails: true }
};

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AlreadyLoggedInGuard, SSOGuard], title: PageTitle.LoginPage },
  { path: 'login', component: LoginComponent, canActivate: [AlreadyLoggedInGuard], title: PageTitle.LoginPage  },
  { path: 'custom_domain', component: CustomDomainComponent, canActivate: [AlreadyLoggedInGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [AlreadyLoggedInGuard, SSOGuard], title: PageTitle.Register },
  { path: 'register_success', component: RegisterConfirmationComponent, canActivate: [AlreadyLoggedInGuard, SSOGuard], title: PageTitle.RegisterSuccess },
  { path: 'change_password', component: ChangePasswordComponent, title: PageTitle.ChangePassword},
  { path: 'forgot_password', component: ForgotPasswordComponent, canActivate: [AlreadyLoggedInGuard, SSOGuard], title: PageTitle.ForgotPassword},
  { path: 'logout', component: LogoutComponent, canActivate: [AlreadyLoggedInGuard] },
  { path: 'sso_no_access', component: NoAccessComponent, canActivate: [AuthRequiredGuard] },
  {
    path: 'plan_detail/:openSections',
    component: Dashboard,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    title: PageTitle.Dashboard,
    data: { superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute } }
  },
  {
    path: 'plan_detail',
    redirectTo: 'plan_detail/goals,campaigns,buckets'
  },
  {
    path: 'manage/:dataMode/:viewMode',
    component: ManagePageComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, CegStatusEnabledGuard],
    title: PageTitle.Manage,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      cegStatusEnabled: false
    }
  },
  {
    path: 'manage/:viewMode',
    redirectTo: managePageFallbackRoute
  },
  {
    path: 'manage',
    redirectTo: managePageFallbackRoute
  },
  {
    path: 'manage_ceg/:dataMode/:viewMode',
    component: ManageCegPageComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, CegStatusEnabledGuard],
    title: PageTitle.Manage,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      cegStatusEnabled: true
    }
  },
  {
    path: 'manage_ceg/:viewMode',
    redirectTo: manageCegPageFallbackRoute
  },
  {
    path: 'manage_ceg',
    redirectTo: manageCegPageFallbackRoute
  },
  { path: 'quick_start', component: PlanSetting, canActivate: [AuthRequiredGuard], title: PageTitle.Onboarding},
  { path: 'unsubscribe/:emailType', component: UnsubscribePageComponent },
  {
    path: 'budget_settings',
    component: BudgetSettingsPageComponent,
    canDeactivate: [ LeavePageGuard ],
    title: PageTitle.Budget,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'team',
    component: TeamPageComponent,
    canActivate: [ AuthRequiredGuard, SuperAdminGuard, UserRolesGuard ],
    canDeactivate: [ LeavePageGuard ],
    title: PageTitle.Team,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'custom_fields',
    component: CustomFieldsComponent,
    canActivate: [ AuthRequiredGuard, SuperAdminGuard, UserRolesGuard, CustomFieldGuard ],
    canDeactivate: [ LeavePageGuard ],
    title: PageTitle.CustomFields,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  { path: 'user', component: UserComponent, canActivate: [AuthRequiredGuard], title: PageTitle.Profile },
  {
    path: 'currencies',
    component: ExchangeRatesComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    title: PageTitle.Currencies,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'integration_settings/:integrationSource',
    component: IntegrationMappingsComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'campaign_mappings/:integrationSource',
    component: CampaignsMappingComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'shared_cost_rules',
    component: SharedCostRulesManagementComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    title: PageTitle.CostRule,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'metric_funnels',
    component: MetricsPageComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    title: PageTitle.Metrics,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'tags',
    component: TagsManagementComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    title: PageTitle.Tags,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'gl_codes',
    component: GlCodesComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    title: PageTitle.GLCodes,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'expense_types',
    component: ExpenseTypesComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    title: PageTitle.ExpenseType,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'attributes-tags',
    component: AttributesAndTagsComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard, UserRolesGuard],
    title: PageTitle.AttributesAndTags,
    data: {
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute },
      userRolesGuard : { roles: [ UserRole.ADMIN, UserRole.OWNER ] }
    }
  },
  {
    path: 'import_data',
    component: ImportDataComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    title: PageTitle.Export,
    data: { superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute } }
  },
  {
    path: 'dashboard',
    component: Widget,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    title: PageTitle.Dashboard,
    data: { superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute } }
  },
  {
    path: 'spending',
    redirectTo: 'spending/expenses',
  },
  {
    path: 'spending',
    component: SpendingComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    data: { superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute } },
    children: [
      {
        path: 'expenses',
        component: ExpensesComponent,
        title: PageTitle.Expenses
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        title: PageTitle.Invoices,
        canDeactivate: [LeavePageGuard],
      },
    ]
  },
  {
    path: 'calendar',
    component: MarketingCalendarComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    title: PageTitle.Calendar,
    data: { superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute } },
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    title: PageTitle.Search,
    data: { superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute } },
  },
  {
    path: 'search/:term',
    component: SearchComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    title: PageTitle.Search,
    data: { superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute } },
  },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    title: PageTitle.Home,
    data: { superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute } },
  },

  {
    path: 'goal/create',
    outlet: 'details',
    component: GoalDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateGoalDetails'],
    data: detailsRouteData
  },
  {
    path: 'goal/:id',
    outlet: 'details',
    component: GoalDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateGoalDetails'],
    data: detailsRouteData
  },
  {
    path: 'campaign/create',
    outlet: 'details',
    component: CampaignDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateCampaignDetails'],
    data: detailsRouteData
  },
  {
    path: 'campaign/:id',
    outlet: 'details',
    component: CampaignDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateCampaignDetails'],
    data: detailsRouteData
  },
  {
    path: 'program/create',
    outlet: 'details',
    component: ProgramDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateProgramDetails'],
    data: detailsRouteData
  },
  {
    path: 'program/:id',
    outlet: 'details',
    component: ProgramDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateProgramDetails'],
    data: detailsRouteData
  },
  {
    path: 'goal_metric/create',
    outlet: 'details',
    component: GoalMetricDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateGoalMetricDetails'],
    data: detailsRouteData
  },
  {
    path: 'goal_metric/:id',
    outlet: 'details',
    component: GoalMetricDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateGoalMetricDetails'],
    data: detailsRouteData
  },
  {
    path: 'campaign_metric/create',
    outlet: 'details',
    component: CampaignMetricDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateCampaignMetricDetails'],
    data: detailsRouteData
  },
  {
    path: 'campaign_metric/:id',
    outlet: 'details',
    component: CampaignMetricDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateCampaignMetricDetails'],
    data: detailsRouteData
  },
  {
    path: 'expense/create',
    outlet: 'drawer',
    component: ExpenseDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateExpenseDetails'],
    data: detailsRouteData
  },
  {
    path: 'expense/:id',
    outlet: 'drawer',
    component: ExpenseDetailsComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateExpenseDetails'],
    data: detailsRouteData
  },
  {
    path: 'invoice/:id',
    outlet: 'drawer',
    component: InvoiceReviewComponent,
    canActivate: [SuperAdminGuard],
    data: {
      animation: 'openInvoiceReview',
      superAdminGuard: { superAdminAccess: false, redirectTo: superAdminsRedirectRoute, closeDetails: true }
    }
  },
  {
    path: 'stack/:config',
    outlet: 'drawerStack',
    component: DrawerStackComponent,
    canActivate: [SuperAdminGuard],
    canDeactivate: ['CanDeactivateActiveDrawer'],
    data: detailsRouteData
  },
  {
    path: 'manage_company',
    component: ManageCompanyComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    title: PageTitle.ManageCompany,
    data: { superAdminGuard: { superAdminAccess: true } },
  },
  {
    path: 'manage_superadmins',
    component: ManageSuperadminsComponent,
    canActivate: [AuthRequiredGuard, SuperAdminGuard],
    title: PageTitle.ManageSuperadmin,
    data: { superAdminGuard: { superAdminAccess: true } },
  },
  {
    path: 'enable_sso',
    component: SsoFormComponent,
    title: PageTitle.Skilljar,
  },

  { path: '**',    redirectTo: '/' }
];

export const Routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
