import { Component } from '@angular/core';

@Component({
  selector: 'plc-logo',
  styles: [`
    svg {
      display: block;
    }
  `],
  templateUrl: './logo.component.html'
})
export class LogoComponent {}

