<button mat-icon-button
  [matMenuTriggerFor]="menu">
  <fa-icon [icon]="['far', 'ellipsis-v']"></fa-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let action of actions">
    <button mat-menu-item
      class="action"
      *ngIf="!action?.hidden"
      [ngClass]="action.customCssClass"
      [class.is-disabled]="action.disabled"
      [disabled]="action.disabled"
      (click)="!action.disabled && action.handler()"
    >
      <fa-duotone-icon [icon]="action.icon"></fa-duotone-icon>
      <span class="text">{{action.title}}</span>
    </button>
  </ng-container>
</mat-menu>
