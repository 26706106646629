import { Injectable } from '@angular/core';

export enum ChurnZeroAction {
  SetContact = 'setContact',
  SetAppKey = 'setAppKey',
  TrackEvent = 'trackEvent',
  Stop = 'stop'
}

export enum EventName {
  Login = 'Login',
  MetricValueUpdate = 'MetricValueUpdate',
  BudgetUpdate = 'BudgetUpdate',
  ExpenseCreate = 'ExpenseCreate',
  ExpenseUpdate = 'ExpenseUpdate',
  TeamUpdate = 'TeamUpdate',
  CampaignUpdate = 'CampaignUpdate',
  ExpenseBucketUpdate = 'ExpenseBucketUpdate'
}

export enum UpdateTeamType {
  AddUser = 'AddUser',
  DeleteUser = 'DeleteUser',
  UpdateUser = 'UpdateUser'
}

@Injectable({
  providedIn: 'root'
})
export class ChurnZeroService {
  private get cz() {
    return (<any>window).ChurnZero;
  }

  private addChurnZeroAction(action: ChurnZeroAction, ...data: string[]): void {
    if (this.cz != null) {
      this.cz.push([action, ...data]);
    }
  }

  public setAppKey(appKey: string) {
    this.addChurnZeroAction(ChurnZeroAction.SetAppKey, appKey);
  }

  public setContact(accountExternalId: string, contactExternalId: string): void {
    this.addChurnZeroAction(ChurnZeroAction.SetContact, accountExternalId, contactExternalId);
  }

  public trackEvent(eventName: string, ...eventData: string[]) {
    this.addChurnZeroAction(ChurnZeroAction.TrackEvent, eventName, ...eventData);
  }

  public stop() {
    this.addChurnZeroAction(ChurnZeroAction.Stop);
  }
}
