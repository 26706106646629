<table-view
  [rows]="rows"
  [columnTitles]="columnTitles"
  [fullWidth]="columnTitles?.length > 6"
  [warningIcon]="true"
>
  <ng-template #thContentTemplate let-columnTitle let-column="column">
    <span class="th-content">
      <span *ngIf="overBudgetColumns[column.fieldName]">
        <icon-flame [hasProblem]="true"></icon-flame>
      </span>
      {{ columnTitle }}
    </span>
  </ng-template>
  <ng-template #rowTitleTemplate let-row >
    <span>
      {{ row.title }}
      <ng-container *ngIf="columnSubtitleByFieldName[row.fieldName]">
        <br/>
        <span  class="subtitle">
          {{ columnSubtitleByFieldName[row.fieldName] }}
        </span>
      </ng-container>
    </span>
  </ng-template>
</table-view>
