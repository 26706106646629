<div
  class="wrapper"
  [class.is-focused]="isFocused"
  [ngClass]="'appearance-' + appearance"
  (click)="setFocus()"
>
  <div class="inner-wrapper">
    <div *ngIf="appearance === 'outline'" class="content-outline"></div>
    <div class="mat-form-field-infix content-infix">
      <div
        #content
        class="content"
        contenteditable
        (input)="onEdit()"
        (click)="onClick($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (keydown)="onKeyDown($event)"
        (paste)="onPaste()"
      ></div>
      <div class="mat-form-field-label-wrapper label-wrapper">
        <div class="mat-form-field-label mat-form-field-empty content-label">{{ label }}</div>
      </div>
    </div>
  </div>
</div>
