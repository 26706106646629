import { Pipe, PipeTransform } from '@angular/core';
import { OrdinalNumberPipe } from './ordinal-number.pipe';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'ordinalDate'
})
export class OrdinalDatePipe implements PipeTransform {
  private ORDINAL_PLACEHOLDER = 'ORD';

  constructor(
    private readonly ordinalNumberPipe: OrdinalNumberPipe,
    private readonly datePipe: DatePipe
  ) {}

  transform(value: Date, format): string {
    const day = value.getDate();
    const ordinalDay = this.ordinalNumberPipe.transform(day);

    format = format.replace(this.ORDINAL_PLACEHOLDER, `\'${ordinalDay}\'`);

    return this.datePipe.transform(value, format);
  }
}
