
<div class="checkbox-container" (click)="onChange()" [class.checked]="checked" [class.disabled]="disabled">
    <mat-checkbox class="checkbox" [checked]="checked"></mat-checkbox>
    <div class="check-icon">
        <ng-content></ng-content>
    </div>
    <div class="checkbox-label">{{label}}</div>
    <div class="checkbox-count" *ngIf="showCounts">({{consumedLength}}/{{maxlength}})</div>
    <span class="overlay"></span>
</div>
