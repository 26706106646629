<div class="page-header" [class.admin-page]="isAdminPage">
  <div class="page-header-col"
       [ngClass]="{ 'wide': !description.children.length || !customItems.children.length }">
    <div class="page-icon" *ngIf="!noIcon">
      <ng-content select="[slot=icon]"></ng-content>
    </div>
    <div class="page-name">{{pageName}}</div>
  </div>
  <div class="page-header-col" #description [ngClass]="{ 'hidden': !description.children.length }">
    <ng-content select="[slot=status]"></ng-content>
    <ng-content select="[slot=name]"></ng-content>
  </div>
  <div class="custom-items page-header-col" #customItems [ngClass]="{ 'hidden': !customItems.children.length }">
    <ng-content select="[slot=custom-items]"></ng-content>
  </div>
</div>
