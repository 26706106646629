import { BudgetCegTimelineService } from '@manage-ceg/services/budget-ceg-timeline.service';
import { Component, inject, OnInit } from '@angular/core';
import { AppDataLoader } from 'app/app-data-loader.service';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';
import { ManageCegDataMode } from '@manage-ceg/types/manage-ceg-page.types';
import { ManageCegPageModeService } from '@manage-ceg/services/manage-ceg-page-mode.service';
import { TabSwitchOption } from '@shared/components/tab-switch/tab-switch.types';
import { ManageCegTableConfigurationService } from '@manage-ceg/services/manage-ceg-table-configuration.service';
import { RecordInteractionService } from '@manage-ceg/services/record-interaction.service';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { ManageCegTableActionsMenuService } from '@manage-ceg/services/manage-ceg-table-actions-menu.service';
import { BehaviorSubject } from 'rxjs';
import { SummaryBarCalculationItem, SummaryBarItem } from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { MiniDashCegCalculationService } from '@manage-ceg/services/mini-dash-calculation.service';
import { ManageCegTableDataMutationService } from '@manage-ceg/services/manage-ceg-table-data-mutation.service';
import { ManageCegAllocationService } from '@manage-ceg/services/manage-ceg-allocation.service';
import { ManageTableActionHistoryService } from '@shared/services/manage-table-action-history.service';
import { ManageCegDataValidationService } from '@manage-ceg/services/manage-ceg-data-validation.service';
import {
  ExpenseCostAdjustmentDataService
} from '../../../metric-integrations/expense-cost-adjustment/expense-cost-adjustment-data.service';
import { ManageCegPageRowIntersectionService } from '@manage-ceg/services/manage-ceg-page-row-intersection.service';

@Component({
  selector: 'manage-ceg-page',
  templateUrl: './manage-ceg-page.component.html',
  styleUrls: ['./manage-ceg-page.component.scss'],
  providers: [
    AppDataLoader,
    ManageCegPageService,
    ManageCegTableDataService,
    ManageCegPageModeService,
    BudgetCegTimelineService,
    ManageCegTableConfigurationService,
    RecordInteractionService,
    BudgetAllocationActionsService,
    ManageCegTableActionsMenuService,
    MiniDashCegCalculationService,
    ManageCegTableDataMutationService,
    ManageCegAllocationService,
    ManageTableActionHistoryService,
    ManageCegDataValidationService,
    ExpenseCostAdjustmentDataService,
    ManageCegPageRowIntersectionService,
  ]
})
export class ManageCegPageComponent implements OnInit {
  private readonly appDataLoader = inject(AppDataLoader);
  private readonly managePageService = inject(ManageCegPageService);
  private readonly miniDashCegCalculationService = inject(MiniDashCegCalculationService);
  protected readonly manageCegDataMode = ManageCegDataMode;
  public readonly managePageModeService = inject(ManageCegPageModeService);

  ngOnInit(): void {
    this.appDataLoader.init(false, false);
  }

  protected get dataModeOptions(): TabSwitchOption[] {
    return this.managePageModeService.dataModeOptions;
  }

  protected get dataMode(): ManageCegDataMode {
    return this.managePageModeService.dataMode;
  }

  protected setDataMode(dataMode: ManageCegDataMode): void {
    this.managePageModeService.dataMode = dataMode;
  }

  protected get summaryBarLoading(): BehaviorSubject<boolean> {
    return this.managePageService.summaryBarLoading$;
  }

  protected get summaryBarItems(): SummaryBarItem[][] {
    return this.managePageService.summaryBarItems;
  }

  protected get calculationItems(): SummaryBarCalculationItem[] {
    let calculationItems = this.miniDashCegCalculationService.calculationItems;
    const viewMode = this.managePageModeService._viewMode;
    if(viewMode === 'segments') {
      calculationItems[0].label = 'Total Segment Budget';
    }
    else if(viewMode === 'campaigns') {
      calculationItems[0].label = 'Total Campaign Budget';
    }
    else{
      calculationItems[0].label = 'Total Budget';
    }
    return calculationItems;
  }
}
