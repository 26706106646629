export const createDateString = (dateObj: Date) => {
  if (!dateObj) {
    return '';
  }
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();

  const monthString = month < 10 ? `0${month}` : month.toString();
  const dateString = date < 10 ? `0${date}` : date.toString();

  return `${year}-${monthString}-${dateString}`;
};

export const parseDateString = (dateString: string): Date => {
  if (!dateString) {
    return null;
  }

  const dateChunks = dateString.split('-');
  if (dateChunks.length < 3) {
    console.warn(`Failed to parse dateString: ${dateString}`);
    return null;
  }

  const date = new Date(parseInt(dateChunks[0], 10), parseInt(dateChunks[1], 10) - 1, parseInt(dateChunks[2], 10));
  date.setHours(0, 0, 0, 0);

  return date;
};
