<div class="metric-cpo-chart">
  <div class="header">
    <details-widget-title
      [text]="type ==='metric-drawer-charts' ?'Cost Per Outcome' : 'CPO Achievement'"
      [hideIcon]="hideIcon"
      [type]="type"
      [showCount]="false"
      [secondaryText]="lastUpdatedInfo"
      [showTooltip]="showTooltip"
      [tooltipValue]="tooltipValue"
    >
      <fa-duotone-icon
        slot="icon"
        [icon]="['fad', 'money-bill-wave']"
      ></fa-duotone-icon>
    </details-widget-title>
  </div>
  <div class="chart" #chartRef></div>
</div>
