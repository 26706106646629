import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Configuration } from 'app/app.constants';
import { FederatedLoginSetupDialogContext } from './federated-login-setup-dialog.type';
import { DIALOG_ACTION_TYPE } from 'app/shared/types/dialog-context.interface';
import { CompanyService } from 'app/shared/services/backend/company.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { CompanySetupSsoPayload } from 'app/shared/types/company-setup-sso-payload.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';

@Component({
  selector: 'federated-login-setup-dialog',
  templateUrl: './federated-login-setup-dialog.component.html',
  styleUrls: ['./federated-login-setup-dialog.component.scss'],
})
export class FederatedLoginSetupDialogComponent implements OnInit {
  public protocol = 'https://';
  public clientDomain = '';
  public regionDomain = '';
  public selectedFile: File = null;
  private readonly FAILED_TO_UPLOAD_SAML_ERROR = 'Failed to upload SAML document';

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly context: FederatedLoginSetupDialogContext,
    private readonly configuration: Configuration,
    private readonly companyService: CompanyService,
    private readonly utilityService: UtilityService,
    private readonly companyDataService: CompanyDataService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.clientDomain = this.context.ssoDomain;
    this.regionDomain = this.configuration.envDomain;
    this.context.actions = [
      {
        label: 'Cancel',
        type: DIALOG_ACTION_TYPE.DEFAULT,
        handler: () => {}
      },
      {
        label: 'OK',
        type: DIALOG_ACTION_TYPE.FLAT,
        handler: () => { this.initSetup(); },
        disabled: true
      }
    ];
  }

  private async prepareSetupPayload(): Promise<CompanySetupSsoPayload> {
    const fileContent = await this.selectedFile.text();
    const callbackURL = `${this.protocol}${this.clientDomain}.${this.regionDomain}`;
    const logoutCallbackURL = `${callbackURL}/logout`;

    return {
      metadata_file: fileContent,
      callback_url: callbackURL,
      logout_url: logoutCallbackURL
    };
  }

  private async initSetup() {
    if (!this.selectedFile) {
      return;
    }

    const { companyId } = this.context;
    try {
      const payload = await this.prepareSetupPayload();
      this.companyService.setupSSO(companyId, payload)
        .subscribe(
          () => {
            this.companyDataService.loadSelectedCompanyDetails(companyId);
            this.router.navigate([this.configuration.ROUTING_CONSTANTS.TEAM]);
          },
          (err) => this.utilityService.handleError(err)
        );
    } catch (e) {
      this.utilityService.handleError(new Error(this.FAILED_TO_UPLOAD_SAML_ERROR));
    }
  }

  public handleFileChange($event) {
    const fileList: FileList = $event.target.files;
    if (!fileList.length) {
      return;
    }
    this.selectedFile = fileList[0];
    this.context.actions.forEach(action => {
      if (action.label === 'OK') {
        action.disabled = false;
      }
    });
  }
}
