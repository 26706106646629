<div>
  <div class="performance-tab-switch">
    <div
      class="tab"
      [class.active-tab]="currentPerformanceChartSelected === 'metric-values'"
      (click)="onPerformanceTabClick('metric-values')"
    >
      <div class="tab-content">
        <div class="w-100 metric-name-card">
          <p class="title fs-20" 
            matTooltipPosition="below"
            matTooltipClass="dark-theme-tooltip simple multi-line"
            [matTooltip]="currentMetricState?.metricName"
            [matTooltipShowDelay]="600"
          >{{ currentMetricState?.metricName }}</p>
        </div>
        <div class="w-100 value-container">
          <!-- <div > -->
          <p class="fs-20" [ngStyle]="{ 'color':colorsMap[performanceEstimateData?.progressState] }">{{ totalValue || 0 | number: decimalDisplayFormat }}</p>
          <p class="target-comparison" [ngStyle]="{ 'color':colorsMap[performanceEstimateData?.progressState] }">{{ performanceEstimateData?.estimatedDiffPercentage < 0 ? (-performanceEstimateData?.estimatedDiffPercentage) : performanceEstimateData?.estimatedDiffPercentage }}% {{ performanceEstimateData?.estimatedDiffPercentage < 0 ?  'behind' : 'ahead' }} of today's target</p>
          <!-- </div> -->
          <p>Target: {{ totalTarget || 0 | number: decimalDisplayFormat }}</p>
        </div>
      </div>
    </div>
    <div
      class="tab"
      [class.active-tab]="currentPerformanceChartSelected === 'cpo'"
      [class.inactive]="false"
      (click)="onPerformanceTabClick('cpo')"
    >
      <div class="tab-content">
        <div class="w-100">
          <p class="title fs-20">CPO</p>
        </div>
        <div class="w-100 value-container">
          <p class="fs-20" [ngClass]="progressIndication">{{ costPerOutcome || 0 | precisionNumberFormat | currency: currencyCode: 'symbol' }}</p>
          <p>Target: {{ targetCostPerOutcome || 0 | precisionNumberFormat | currency: currencyCode: 'symbol' }}</p>
        </div>
      </div>
    </div>
    <div
      class="tab"
      [class.active-tab]="currentPerformanceChartSelected === 'rroi'"
      [class.inactive]="false"
      (click)="onPerformanceTabClick('rroi')"
    >
      <div class="tab-content">
        <div class="w-100">
          <p class="title fs-20">{{ currentMetricState?.revenueToProfit ? 'ROI': 'RROI' }}</p>
        </div>
        <div class="w-100 value-container">
          <p class="fs-20" [ngClass]="progressIndication">{{ returnOnInvestment || 0 | precisionNumberFormat }}x</p>
          <p>Target: {{ targetReturnOnInvestment || 0 | precisionNumberFormat }}x</p>
        </div>
      </div>
    </div>
  </div>
  <div class="performance-chart-container">
    <div
      class="performance-chart"
      *ngIf="currentPerformanceChartSelected === 'metric-values' && graphData?.milestones"
    >
      <metric-progress-section
        type="metric-drawer-charts"
        [hideIcon]="true"
        [metricName]="currentMetricState?.metricName"
        [lastUpdatedInfo]="metricValueLastUpdated"
        (viewModeChanged)="handleProgressChartModeChanged($event)"
      >
        <metric-target-progress-chart
          chart
          class="details-metric-chart"
          [startDate]="graphData?.startDate"
          [data]="graphData?.metricValueRecords"
          [milestones]="graphData?.milestones"
          [metricName]="currentMetricState?.metricName"
        >
        </metric-target-progress-chart>
        <metric-progress-table
          table
          [metricName]="currentMetricState?.metricName"
          [metricProgressData]="metricProgressTowardsTarget"
          [metricValueRecords]="graphData?.metricValueRecords"
          [displayDecimal]="displayDecimal"
          [budget]="budget"
        >
        </metric-progress-table>
      </metric-progress-section>
    </div>
    <div
      class="performance-chart"
      *ngIf="currentPerformanceChartSelected === 'cpo'"
    >
      <metric-cpo-chart 
        type="metric-drawer-charts"
        [hideIcon]="true"
        [showTooltip]="true"
        [tooltipValue]="CPOTooltip"
        [startDate]="graphData?.startDate"
        [data]="graphData?.CPORecords"
        [milestones]="graphData?.milestones"
        [currency]="'$'"
        [targetCPO]="graphData?.targetCPO"
        [lastUpdatedInfo]="metricValueLastUpdated"
        #cpoChartComponent
        class="details-metric-chart"
      >
      </metric-cpo-chart>
    </div>
    <div
      class="performance-chart"
      *ngIf="currentPerformanceChartSelected === 'rroi'"
    >
      <metric-roi-chart
        type="metric-drawer-charts"
        [hideIcon]="true"
        [showTooltip]="true"
        [tooltipValue]="currentMetricState?.revenueToProfit ? ROITooltip: RROITooltip"
        [startDate]="graphData?.startDate"
        [data]="graphData?.ROIRecords"
        [milestones]="graphData?.milestones"
        [targetROI]="graphData?.targetROI"
        [isRevenueToProfitDefined]="!!currentMetricState?.revenueToProfit"
        [lastUpdatedInfo]="metricValueLastUpdated"
        #roiChartComponent
        class="details-metric-chart"
      >
      </metric-roi-chart>
    </div>
  </div>
</div>
