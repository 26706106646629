import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-cancel',
  templateUrl: './icon-cancel.component.html',
  styleUrls: ['./icon-cancel.component.scss']
})

/**
* @class - IconCancelComponent
*/
export class IconCancelComponent {
  @Input() size = 24;
  @Input() color = '#272727';
  @Input() textTooltip = '';
}
