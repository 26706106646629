import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive, Input } from '@angular/core';

export const UNIQUE_DOMAIN_PREFIX_VALIDATION_ERROR_NAME = 'uniqueDomainPrefix';

export function uniqueDomainPrefixValidator(existingDomainPrefixes: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = (<string>control.value || '').toLowerCase();
    const exists =
      existingDomainPrefixes != null &&
      existingDomainPrefixes.length > 0 &&
      existingDomainPrefixes.includes(value);
    return exists ? { [UNIQUE_DOMAIN_PREFIX_VALIDATION_ERROR_NAME]: {value} } : null;
  };
}

@Directive({
  selector: '[uniqueDomainPrefix]',
  providers: [{provide: NG_VALIDATORS, useExisting: UniqueDomainPrefixDirective, multi: true}]
})
export class UniqueDomainPrefixDirective implements Validator {
  @Input('uniqueDomainPrefix') existingDomainPrefixes = [];

  validate(control: AbstractControl): ValidationErrors | null {
    return uniqueDomainPrefixValidator(this.existingDomainPrefixes)(control);
  }
}
