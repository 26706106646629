import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ExpenseTableColumn, ExpenseTableColumnItem } from '../types/expense-page.type';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { EXPENSE_PAGE_TABLE_COLUMNS } from 'app/shared/constants/modes.constant';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { ManageColumnsDialogComponent } from '../components/manage-columns-dialog/manage-columns-dialog.component';
import { columnItemsMap } from '../constants/expense-table-column.constants';
import { Subject } from 'rxjs';

@Injectable()
export class ExpenseTableColumnsService {
  private columnItemsMap: Record<ExpenseTableColumn, ExpenseTableColumnItem> = createDeepCopy(columnItemsMap);
  private _columns: ExpenseTableColumnItem[];
  private storedColumnMap: Record<ExpenseTableColumn, ExpenseTableColumnItem> = this.getColumnsFromStorage(columnItemsMap);
  public columnsUpdated$ = new Subject<void>();
  private hiddenForStatuslessExpenses = [ExpenseTableColumn.STATUS, ExpenseTableColumn.PLANNED, ExpenseTableColumn.DIFFERENCE];
  private _isStatuslessExpenses;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this._columns = Object.values(this.storedColumnMap || this.columnItemsMap);
  }

  public get expenseTableColumns(): ExpenseTableColumnItem[] {
    return [...this._columns];
  }

  private get expenseTableColumnsForDialog(): ExpenseTableColumnItem[] {
    const expenseTableColumns = createDeepCopy(Object.values(this.expenseTableColumnMap));
    return !this._isStatuslessExpenses ? expenseTableColumns : expenseTableColumns.filter(column => !this.hiddenForStatuslessExpenses.includes(column.id));
  }

  public get expenseTableColumnMap(): Record<ExpenseTableColumn, ExpenseTableColumnItem> {
    return this.storedColumnMap || this.columnItemsMap;
  }

  public updateVisibilityForStatuslessChanges(isStatusless: boolean): void {
    this._isStatuslessExpenses = isStatusless;
    this._columns.forEach(column => {
      if (this.hiddenForStatuslessExpenses.includes(column.id)) {
        column.visible = !isStatusless;
      }
    });
    this.expenseTableColumns = [...this._columns];
  }

  private set expenseTableColumns(columns: ExpenseTableColumnItem[]) {
    this._columns = this.removeDuplicates(columns);
    this.columnsUpdated$.next();
    this.cdr.markForCheck();
  }

  private removeDuplicates(columns: ExpenseTableColumnItem[]) {
    return columns.filter((item, index) => {
      return index === columns.findIndex(filterItem => item.id === filterItem.id)
    })
  }

  public openManageColumnsDialog(): void {
    const dialogContextData = {
      title: 'Manage columns',
      columnItems: this.removeDuplicates(this.expenseTableColumnsForDialog),
      cancelAction: {
        label: 'Cancel'
      },
      submitAction: {
        label: 'Apply',
        handler: (items: ExpenseTableColumnItem[]) => this.onApplyHandler(items)
      }
    };
    const dialogConfig: MatDialogConfig = {
      width: '450px',
      panelClass: ['no-padding'],
      data: dialogContextData,
      autoFocus: false
    };

    this.matDialog.open(ManageColumnsDialogComponent, dialogConfig);
  }

  private onApplyHandler(items: ExpenseTableColumnItem[]): void {
    const updatedColumnsMap = this.updateColumnMap(this.columnItemsMap, items);
    this.storedColumnMap = updatedColumnsMap;
    this.expenseTableColumns = Object.values(updatedColumnsMap);
    this.saveColumnIdsToStorage(items);
  }

  private updateColumnMap(
    columnMap: Record<ExpenseTableColumn, ExpenseTableColumnItem>,
    items: ExpenseTableColumnItem[]
  ): Record<ExpenseTableColumn, ExpenseTableColumnItem> {
    items.forEach((item: ExpenseTableColumnItem, index: number) => {
      if (columnMap[index].id !== item.id || columnMap[index].visible !== item.visible) {
        columnMap[index] = item;
      }
    });
    return columnMap;
  }

  private saveColumnIdsToStorage(items: ExpenseTableColumnItem[]): void {
    const visibleColumns = items.map(item => ({id: item.id, visible: item.visible}));
    LocalStorageService.addToStorage(EXPENSE_PAGE_TABLE_COLUMNS, visibleColumns);
  }

  private getColumnsFromStorage(
    columnMap: Record<ExpenseTableColumn, ExpenseTableColumnItem>
  ): Record<ExpenseTableColumn, ExpenseTableColumnItem>  {
    const storedColumns: any[] = LocalStorageService.getFromStorage(EXPENSE_PAGE_TABLE_COLUMNS);
    if (!storedColumns || !Object.values(storedColumns).length) {
      return columnMap;
    }
    const restoredMap = {} as Record<ExpenseTableColumn, ExpenseTableColumnItem>;
    storedColumns.forEach((column, index) => {
      const defaultColumn = columnMap[column.id];
      defaultColumn.visible = column.visible;
      restoredMap[index] = defaultColumn;
    });

    return restoredMap;
  }
}
