import { SharedCostRuleComponent } from '../shared-cost-rule.component';
import { SCRFocusStrategy } from './scr-focus-strategy.interface';
import { SharedCostRuleFieldType } from 'app/shared/types/shared-cost-rule.interface';

export class SCRStatusFocusStrategy implements SCRFocusStrategy {
  componentInstance: SharedCostRuleComponent;

  constructor(componentInstance: SharedCostRuleComponent) {
    this.componentInstance = componentInstance;
  }

  activate() {
    const isCurrentDropdownActive = this.componentInstance.isActiveDropdown(SharedCostRuleFieldType.Status);
    if (!isCurrentDropdownActive) {
      this.componentInstance.resetActiveDropdown();
    }
    this.componentInstance.currentFocusStrategy = this;
    this.componentInstance.focusedFieldType = SharedCostRuleFieldType.Status;
    this.componentInstance.focusedSegmentIndex = -1;
    this.componentInstance.focusElement(this.componentInstance.statusElement);
  }

  moveFocus(isForward: boolean) {
    const { focusStrategies } = this.componentInstance;
    if (isForward) {
      focusStrategies[SharedCostRuleFieldType.Budget].activate();
    } else {
      const focusedSegmentIndex = this.componentInstance.rule.segments.length - 1;
      focusStrategies[SharedCostRuleFieldType.Cost].activate(focusedSegmentIndex);
    }
  }

  selectElement() {
    if (!this.componentInstance.permissions.updateStatus) {
      return;
    }
    this.componentInstance.toggleActiveDropdown(SharedCostRuleFieldType.Status);
  }

  unselectElement() {
    this.componentInstance.resetActiveDropdown();
  }
}
