import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ExternalCampaign } from '../../types/external-campaign.interface';
import { MetricMappingDialogData } from './dialog-data.type';
import { CampaignsSorting, sortDropdownMenuItems } from './utils';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'metric-mapping-dialog',
  templateUrl: './metric-mapping-dialog.component.html',
  styleUrls: ['./metric-mapping-dialog.component.scss']
})
export class MetricMappingDialogComponent implements OnDestroy {
  @Input() dialogData: MetricMappingDialogData;
  @Input() campaigns: ExternalCampaign[];
  @Input() placeholderText: string;
  @Output() saveMappingState = new EventEmitter<ExternalCampaign[]>();
  @Output() closeClick = new EventEmitter();
  campaignsSnapshot: ExternalCampaign[]; // the same list as this.campaigns but with actual switchers state

  public sortDropdownMenuItems = sortDropdownMenuItems;
  public campaignsSortingMode = CampaignsSorting.SORT_BY_NAME;
  public filterControl = new UntypedFormControl('');
  public filterText = '';
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
  ) {
    this.filterControl.valueChanges
      .pipe(
        debounceTime(350),
        takeUntil(this.destroy$),
      ).subscribe(
        value => this.filterText = value && value.length > 2 ? value : ''
      )
  }

  updateCampaignsSnapshot(campaigns: ExternalCampaign[]) {
    this.campaignsSnapshot = campaigns;
  }

  changeSorting(value: CampaignsSorting) {
    this.campaignsSortingMode = value;
  }

  navigateToCampaign(campaignId: number) {
    this.closeClick.emit();
    this.router.navigate([{ outlets: { details: 'campaign/' + campaignId } }]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
