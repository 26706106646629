<mat-form-field appearance="fill" class="no-validate"
                [matTooltip]="isDisabled ? rpoPredefined : ''"
                matTooltipClass="dark-theme-tooltip above multi-line text-left metric-disabled-rpo"
                matTooltipPosition="above"
>
  <mat-label>Revenue per Outcome</mat-label>
  <input matInput [formControl]="rpoFormControl"
         currencyInput [selectTextOnFocus]="true" blurOnEnterKeydown currencyMask [options]="currencyMaskOpt"
  />
</mat-form-field>
<fa-duotone-icon [icon]="['fad', 'graduation-cap']" class="primary"
                 [matTooltip]="rpoDescription"
                 matTooltipClass="dark-theme-tooltip right multi-line text-left metric-rpo"
                 matTooltipPosition="right"
></fa-duotone-icon>
