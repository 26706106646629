import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { Configuration } from '../../../app.constants';

@Component({
  selector: 'editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.scss']
})
export class EditableTextComponent implements OnInit, OnChanges {
  @Input() value: string = '';
  @Input() existingNames: Set<string> = new Set();
  @Input() hasDescriptionColumn: boolean = false;
  @Input() validationErrorMessage: string = '';
  @Input() valueId: string;
  @Input() forceValidation: boolean;
  @Input() isEditing: boolean = false;
  @Input() maxLength: number = 128;
  @Input() charCount: number = 0;
  @Input() inputId: string;
  @Input() isNameCaseSensitive: boolean = false;
  @Output() valueChanged = new EventEmitter<string>();
  @Output() validationError = new EventEmitter<boolean>();
  error: string = '';
  originalValue: string = '';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private config: Configuration) { }

  ngOnInit() {
    this.charCount = this.value.length;
    this.originalValue = this.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forceValidation) {
      if (this.forceValidation) {
        this.error = this.validationErrorMessage;
      } else {
        this.error = "";
      }
    }
  }

  ngAfterViewInit() {
    if (this.isEditing) {
      this.setFocusOnInput(true);
    }
  }

  onMouseOver() {
    this.isEditing = true;
  }

  onMouseOut() {
    this.isEditing = false;
  }

  onEditClick() {
    this.isEditing = true;
    this.error = '';
    this.setFocusOnInput(false);
  }

  onBlur() {
    if (this.originalValue !== this.value || this.value.trim() === '') {
      if (this.value.trim() === '' || this.existingNames.has(this.getTypeNameWithCase(this.value))) {
        this.error = this.validationErrorMessage;
        this.validationError.emit(true);
      } else {
        this.isEditing = false;
        this.valueChanged.emit(this.value);
        this.validationError.emit(false);
      }
    }
    else {
      this.validationError.emit(false);
      this.isEditing = false;
    }
  }

  private setFocusOnInput(isCreate) {
    setTimeout(() => {
      let inputElement;
      if (isCreate) {
        inputElement = document.querySelector(".editable-input");
      } else {
        inputElement = this.el.nativeElement.querySelector(`#${this.inputId}`);
      }
      if (inputElement) {
        isCreate ? inputElement.focus() : this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  getTypeNameWithCase(value) {
    return this.isNameCaseSensitive ? value.trim() : value.trim().toLowerCase();
  }

  updateCharCount() {
    this.charCount = this.value.length;
    if (this.existingNames.has(this.getTypeNameWithCase(this.value)) && (this.getTypeNameWithCase(this.value) !== this.getTypeNameWithCase(this.originalValue)) ) {
      this.error = this.validationErrorMessage;
    } else {
      this.error = '';
    }
  }

}