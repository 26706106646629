<header mat-dialog-title>{{ data?.title }}</header>
<div mat-dialog-content class="dialog-info">
  <div class="dialog-content">
    <div class="not-draggable">
      <div class="select-item"
         *ngFor="let item of notDraggableColumns"
         [class.visible]="item.visible"
      >
        <mat-checkbox
          color="primary"
          class="pl-mat-checkbox primary-ripple"
          [checked]="item.visible"
          [disabled]="!item.draggable"
          (change)="onSelectionChange(item, $event.checked)"
          [disableRipple]="true"
        >
          {{ item.title }}
        </mat-checkbox>
      </div>
    </div>
    <div class="drag-container"
     cdkDropList
     [cdkDropListData]="data?.columnItems"
     (cdkDropListDropped)="dropItem($event)"
    >
      <ng-container *ngFor="let item of data?.columnItems">
        <div class="select-item"
          cdkDrag
          [cdkDragData]="item"
          [cdkDragDisabled]="!item.visible"
          [class.visible]="item.visible"
          [class.not-active]="!item.draggable"
        >
          <mat-checkbox
            color="primary"
            class="pl-mat-checkbox primary-ripple"
            [checked]="item.visible"
            [disabled]="!item.draggable"
            (change)="onSelectionChange(item, $event.checked)"
            [disableRipple]="true"
          >
            {{ item.title }}
          </mat-checkbox>
          <div class="drag-icon" cdkDragHandle>
            <span>&#8214;</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" class="dialog-actions">
  <button
    color="primary"
    mat-button
    [mat-dialog-close]="true">
    {{data.cancelAction.label }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="ml-1"
    [disabled]="!isStateChanged"
    [mat-dialog-close]="true"
    (click)="data.submitAction.handler(data?.columnItems)">
    {{ data.submitAction.label }}
  </button>
</div>
