import { Component, Input } from '@angular/core';

@Component({
  selector: 'spend-chart-value',
  styleUrls: ['./spend-chart-value.component.scss'],
  templateUrl: './spend-chart-value.component.html'
})
export class SpendChartValueComponent {
  @Input() value: number;
  @Input() cegFlagEnabled = false;
  public numberPipeFormat = '1.0-0';
}
