<tooltip-container class="arrow-left">
  <div class="action-hint">
    <img src="assets/images/double-click-action.svg"/>
    <span [innerHTML]="contentByContext[tooltipContext]?.doubleClick"></span>
  </div>

  <div class="action-hint" *ngIf="withDnd">
    <img src="assets/images/dnd-action.svg"/>
    <span [innerHTML]="contentByContext[tooltipContext]?.dnd"></span>
  </div>
</tooltip-container>
