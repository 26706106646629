import { Component } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-user-helmet-safety',
  templateUrl: './icon-user-helmet-safety.component.html'
})
export class IconUserHelmetSafetyComponent extends IconBaseComponent {
  constructor() {
    super();
  }
}
