import { Component } from '@angular/core';

@Component({
  selector: 'overdue-expenses-legend',
  styleUrls: ['./overdue-expenses-legend.component.scss'],
  templateUrl: './overdue-expenses-legend.component.html'
})
export class OverdueExpensesLegendComponent {
  public legendItems = [
    '<b>Enter new Amount</b> – update the amount of the expense.',
    '<b>Close</b> – change the status of the expense to "Closed" at the amount shown.',
    '<b>Move</b> – shift the timeframe to current timeframe so that it is no longer overdue.',
    'Or choose a different timeframe by clicking on the calendar icon.'
  ];
}
