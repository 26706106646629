<header class="details-header">
  <nav class="app-navigation">
    <a class="home-page-link" href="javascript:;" [routerLink]="['', { outlets: { primary: 'home', details: null, drawer: null } }]" queryParamsHandling="preserve">
      <img src="assets/images/planful-logo-colored.svg" alt="Planful" />
    </a>
    <div class="location">
      <object-hierarchy-nav [data]="objectHierarchy"></object-hierarchy-nav>
    </div>
    <div class="profile">
      <profile-menu [disabled]="true" ></profile-menu>
    </div>
  </nav>
  <nav class="object-navigation">
    <div class="left-side"></div>
    <div class="right-side">
      <manage-object-actions
        [objectDisplayType]="objectDisplayType"
        [actions]="actions"
        [budgetList]="budgetList"
        (budgetToMoveSelected)="onMoveToBudgetSelected($event)"
      ></manage-object-actions>
      <details-page-actions
        [showSaveAndNewButton]="showSaveAndNewButton"
        [objectLabel]="objectDisplayType"
        [isReadOnlyMode]="isReadOnlyMode"
        [hasUnsavedChanges]="hasUnsavedChanges"
        (cancel)="onCancel()"
        (save)="onSave()"
        (saveAndClose)="onSaveAndClose()"
        (saveAndNew)="onSaveAndNew()"
      ></details-page-actions>
    </div>
  </nav>
</header>
