import { Injectable } from '@angular/core';
import { MetricMappingDialogService } from '../../components/metric-mapping-dialog/metric-mapping-dialog.service';
import { SalesforceCampaignMapping } from '../salesforce-campaign-mapping.interface';
import { ExternalCampaign } from '../../types/external-campaign.interface';
import { deepSearch } from 'app/shared/utils/common.utils';
import { IntegrationMappingState } from '../../types/metric-integration';

@Injectable()
export class SalesforceMappingDialogService extends MetricMappingDialogService<SalesforceCampaignMapping> {
  private readonly _failedToLoadCampaignsErrorMsg = 'Failed to load Salesforce campaigns data';

  protected get failedToLoadCampaignsErrorMsg() {
    return this._failedToLoadCampaignsErrorMsg;
  }

  protected addCampaignMapping(campaign: ExternalCampaign, contextData: any) {
    const campaignMapping: SalesforceCampaignMapping =
      this._campaignMapping || { currencyCode: contextData.budgetCurrencyCode, mappings: [] } ;

    if (!campaignMapping.mappings.includes(campaign.id)) {
      campaignMapping.mappings.push(campaign.id);
    }

    this._campaignMapping = campaignMapping;
  }

  protected deleteCampaignMapping(campaign: ExternalCampaign) {
    if (!this._campaignMapping?.mappings) {
      return;
    }
    const mappedCampaignIndex = this._campaignMapping.mappings.findIndex(mapping => mapping === campaign.id);
    if (mappedCampaignIndex >= 0) {
      this._campaignMapping.mappings.splice(mappedCampaignIndex, 1);
    }
  }

  protected filterIrrelevantMappings(campaignMapping: SalesforceCampaignMapping) {
    const relevantMappings =
      campaignMapping.mappings.filter(
        mapping => {
          const target = deepSearch(this._externalCampaigns, mapping, cmp => cmp.children);
          return target?.mappingState === IntegrationMappingState.Mapped;
        }
      );
    campaignMapping.mappings = relevantMappings;
  }

  protected isCampaignMetricMapped(campaign: ExternalCampaign, campaignMapping: SalesforceCampaignMapping) {
    return (campaignMapping?.mappings || []).includes(campaign.id)
  }
}
