import { Injectable } from '@angular/core';
import { BudgetTableService } from './budget-table.service';
import { MenuPanelItem } from '../../header-navigation/components/menu-panel/menu-panel.type';
import { BudgetTableActionType } from '../components/budget-table/budget-table.types';
import { BudgetTableSelectionState } from '../types/budget-table-selection.types';
import { BudgetSegment } from 'app/shared/types/segment.interface';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';

interface SelectionPredicates {
  anyGroupSelected: boolean;
  anyRecordSelected: boolean;
  allSegmentsSelected: boolean;
  onlyFullGroupsSelected: boolean;
  allSelectedGroupsAreFull: boolean;
  noSingleSegmentsSelected: boolean;
  onlyOneGroupSelected: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BudgetTableContextMenuService {
  private menuLabels = {
    [BudgetTableActionType.SegmentsGroup]: 'Group Segment(s)',
    [BudgetTableActionType.SegmentsUngroup]: 'Ungroup Segment(s)',
    [BudgetTableActionType.SegmentsMove]: 'Move Segment(s)',
    [BudgetTableActionType.SegmentDuplicate]: 'Duplicate Segment(s)',
    [BudgetTableActionType.SegmentDelete]: 'Delete Segment(s)',
    [BudgetTableActionType.GroupDuplicate]: 'Duplicate Group(s)',
  };
  private _segmentContextMenu: MenuPanelItem[] = [];
  private _groupContextMenu: MenuPanelItem[] = [];
  private _manageBudgetMenu: MenuPanelItem[] = [];
  private tableActionsList: Record<string, MenuPanelItem> = {};
  private manageActionsList: Record<string, MenuPanelItem> = {};
  private tableMenuHandlers: Record<string, Function> = {};
  private manageMenuHandlers: Record<string, Function> = {};

  constructor(
    private readonly budgetTableService: BudgetTableService,
  ) {}

  private runAction(handler: Function, payload?: any) {
    if (handler) {
      handler(payload);
    }
  }

  private getSelectionPredicates(selection: BudgetTableSelectionState, existingSegments: BudgetSegment[]): SelectionPredicates {
    const { records, groups, segments } = selection;
    const selectedGroupsCount = Object.keys(groups).length;
    const selectedRecordsCount = Object.keys(records).length;
    const selectedSegmentsCount = Object.keys(segments).length;

    const anyGroupSelected = selectedGroupsCount > 0;
    const anyRecordSelected = selectedRecordsCount > 0;
    const allSegmentsSelected = selectedSegmentsCount === existingSegments.length;
    const onlyOneGroupSelected = selectedGroupsCount === 1;

    const groupSelections = Object.values(groups);
    const allSelectedGroupsAreFull = anyGroupSelected && groupSelections.every(gs => gs.value === CheckboxValue.Active);
    const selectedSegmentsInGroupCount = groupSelections.reduce((count, gs) => (
      count + gs.nestedValues.size
    ), 0);
    const noSingleSegmentsSelected = selectedSegmentsInGroupCount === selectedSegmentsCount;
    const onlyFullGroupsSelected = anyGroupSelected && allSelectedGroupsAreFull && noSingleSegmentsSelected;

    return {
      anyGroupSelected,
      anyRecordSelected,
      allSegmentsSelected,
      onlyFullGroupsSelected,
      noSingleSegmentsSelected,
      onlyOneGroupSelected,
      allSelectedGroupsAreFull
    };
  }

  private generateMoveSegmentItems(
    handlersList: Record<string, Function>,
    predicates: SelectionPredicates,
    selection: BudgetTableSelectionState
  ): MenuPanelItem[] {
    const { noSingleSegmentsSelected, onlyOneGroupSelected } = predicates;
    const groupKeysToExclude: string[] = [];

    if (noSingleSegmentsSelected && onlyOneGroupSelected) {
      const selectedGroupKeys = Object.keys(selection.groups);
      if (selectedGroupKeys.length) {
        groupKeysToExclude.push(selectedGroupKeys[0]);
      }
    }

    return this.budgetTableService.groupsList
      .filter(group => group.key && !groupKeysToExclude.includes(group.key))
      .map(group => ({
        label: `${group.name}`,
        faIcon: null,
        action: () => this.runAction(handlersList[BudgetTableActionType.SegmentsMove], group)
      }));
  }

  private validateManageMenu(selection: BudgetTableSelectionState, predicates: SelectionPredicates) {
    const {
      anyGroupSelected,
      anyRecordSelected,
      allSegmentsSelected,
      allSelectedGroupsAreFull
    } = predicates;

    Object.values(this.manageActionsList).forEach(actionItem => actionItem.disabled = !anyRecordSelected);

    if (!anyRecordSelected) {
      return;
    }

    const moveSegmentItems = this.generateMoveSegmentItems(this.manageMenuHandlers, predicates, selection);

    this.manageActionsList[BudgetTableActionType.SegmentsMove].children = moveSegmentItems;
    this.manageActionsList[BudgetTableActionType.SegmentsMove].disabled = !moveSegmentItems.length;
    this.manageActionsList[BudgetTableActionType.SegmentsUngroup].disabled = !anyGroupSelected;
    this.manageActionsList[BudgetTableActionType.SegmentsGroup].disabled = anyGroupSelected;
    this.manageActionsList[BudgetTableActionType.GroupDuplicate].hidden = !allSelectedGroupsAreFull;
    this.manageActionsList[BudgetTableActionType.SegmentDuplicate].hidden = allSelectedGroupsAreFull;
    this.manageActionsList[BudgetTableActionType.SegmentDelete].disabled = allSegmentsSelected;
  }

  private validateTableMenus(selection: BudgetTableSelectionState, predicates: SelectionPredicates) {
    const { anyGroupSelected, allSelectedGroupsAreFull, allSegmentsSelected } = predicates;
    const moveSegmentItems = this.generateMoveSegmentItems(this.tableMenuHandlers, predicates, selection);

    this.tableActionsList[BudgetTableActionType.SegmentsMove].children = moveSegmentItems;
    this.tableActionsList[BudgetTableActionType.SegmentsMove].disabled = !moveSegmentItems.length;
    this.tableActionsList[BudgetTableActionType.SegmentsUngroup].hidden = !anyGroupSelected;
    this.tableActionsList[BudgetTableActionType.SegmentsGroup].hidden = anyGroupSelected;
    this.tableActionsList[BudgetTableActionType.GroupDuplicate].disabled = !allSelectedGroupsAreFull;
    this.tableActionsList[BudgetTableActionType.SegmentDelete].disabled = allSegmentsSelected;
  }

  private generateActionsList(handlersList: Record<string, Function>, disabled = false): Record<string, MenuPanelItem> {
    const menuActions = [
      BudgetTableActionType.SegmentsGroup,
      BudgetTableActionType.SegmentsUngroup,
      BudgetTableActionType.SegmentsMove,
      BudgetTableActionType.GroupDuplicate,
      BudgetTableActionType.SegmentDuplicate,
      BudgetTableActionType.SegmentDelete
    ];

    return menuActions.reduce((actionsList, actionType) => ({
      ...actionsList,
      [actionType]: {
        label: this.menuLabels[actionType],
        faIcon: null,
        disabled,
        action: () => this.runAction(handlersList[actionType])
      }
    }), {});
  }

  public get segmentContextMenu() {
    return this._segmentContextMenu;
  }

  public get groupContextMenu() {
    return this._groupContextMenu;
  }

  public get manageBudgetMenu() {
    return this._manageBudgetMenu;
  }

  public generateTableMenus(handlersList: Record<string, Function>) {
    this.tableMenuHandlers = handlersList;
    this.tableActionsList = this.generateActionsList(handlersList);
    this._segmentContextMenu = [
      this.tableActionsList[BudgetTableActionType.SegmentsGroup],
      this.tableActionsList[BudgetTableActionType.SegmentsUngroup],
      this.tableActionsList[BudgetTableActionType.SegmentsMove],
      this.tableActionsList[BudgetTableActionType.SegmentDuplicate],
      this.tableActionsList[BudgetTableActionType.SegmentDelete],
    ];
    this._groupContextMenu = [
      this.tableActionsList[BudgetTableActionType.SegmentsUngroup],
      this.tableActionsList[BudgetTableActionType.SegmentsMove],
      this.tableActionsList[BudgetTableActionType.GroupDuplicate],
    ];
  }

  public generateManageMenu(handlersList: Record<string, Function>): MenuPanelItem[] {
    this.manageMenuHandlers = handlersList;
    this.manageActionsList = this.generateActionsList(handlersList, true);
    this._manageBudgetMenu = [
      this.manageActionsList[BudgetTableActionType.SegmentsGroup],
      this.manageActionsList[BudgetTableActionType.SegmentsUngroup],
      this.manageActionsList[BudgetTableActionType.SegmentsMove],
      this.manageActionsList[BudgetTableActionType.SegmentDuplicate],
      this.manageActionsList[BudgetTableActionType.GroupDuplicate],
      this.manageActionsList[BudgetTableActionType.SegmentDelete],
    ];

    return this._manageBudgetMenu;
  }

  public validateMenus(selection: BudgetTableSelectionState, existingSegments: BudgetSegment[]) {
    const predicates = this.getSelectionPredicates(selection, existingSegments);

    this.validateManageMenu(selection, predicates);
    this.validateTableMenus(selection, predicates);
  }
}
