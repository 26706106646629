import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ManageTableDataService } from './manage-table-data.service';
import { ManagePageModeService } from './manage-page-mode.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { Budget } from 'app/shared/types/budget.interface';
import { ManagePageApiService } from './manage-page-api.service';
import { Configuration } from 'app/app.constants';
import { EXPORT_TYPES, ExportDataService } from 'app/dashboard/export-data.service';
import { parseErrorBlob } from 'app/shared/utils/http-request.utils';
import { UtilityService } from 'app/shared/services/utility.service';
import { ManageTableData, ManageTableFullRowValues } from '../components/manage-table/manage-table.types';
import { PerformanceColumnData } from '../types/performance-column-data.type';
import { ManageTableViewMode } from '../types/manage-table-view-mode.type';
import { ManageTableDataMode } from '../types/manage-table-data-mode.type';
import { SpendingModeFlags } from '../types/spending-mode-flags.type';
import { AllocationModeFlags } from '../types/allocation-mode-flags.type';


interface ManagePageExportPayload {
  data: ManageTableData;
  grandTotal: ManageTableFullRowValues;
  performance: PerformanceColumnData;
  viewMode: ManageTableViewMode;
  dataMode: ManageTableDataMode;
  spendingFlags: SpendingModeFlags;
  allocationFlags: AllocationModeFlags;
  includedTimeframeIds: number[];
  timeframes: Record<number, Partial<BudgetTimeframe>>;
  sharedCostRules: Record<number, Partial<SharedCostRule>>;
  segments: Record<number, Partial<BudgetSegmentAccess>>;
  segmentGroups: Record<number, Partial<BudgetSegmentAccess>>;
  budget: Partial<Budget>;
}


@Injectable()
export class ManagePageExportService {
  private dbExportBaseUrl = this.configuration.db_export_service_url;
  private exportUrl = `${this.dbExportBaseUrl}manage_page/export`;
  private fieldsToOmit = ['crd', 'upd'];
  private exportFileName = 'manage-table';
  private errorMessages = {
    FAILED_TO_EXPORT: 'Failed to export data'
  };
  private readonly isLoading = new BehaviorSubject(false);

  public readonly isLoading$ = this.isLoading.asObservable();

  constructor(
    private readonly dataService: ManageTableDataService,
    private readonly modeService: ManagePageModeService,
    private readonly apiService: ManagePageApiService,
    private readonly configuration: Configuration,
    private readonly http: HttpClient,
    private readonly utilityService: UtilityService,
  ) {}

  private omitFields<T extends Object>(target: T, fields: string[] = []): Partial<T> {
    const result = { ...target };

    fields.forEach(field => delete result[field]);

    return result as Partial<T>;
  }

  private prepareObjectsMap<T extends { id: number }>(list: T[]): Record<number, T> {
    return list.reduce((map, item) => ({
      ...map,
      [item.id]: this.omitFields(item, this.fieldsToOmit)
    }), {});
  }

  private processTableData(sourceData: ManageTableData): ManageTableData {
    const excludeFilteredRowsRecursively = (data: ManageTableData, result: ManageTableData = []): ManageTableData => {
      data.forEach(row => {
        if (!row.isFilteredOut) {
          result.push(row);
          return;
        }

        if (row.children?.length) {
          excludeFilteredRowsRecursively(row.children, result);
        }
      });

      return result;
    };

    return excludeFilteredRowsRecursively(sourceData);
  }

  public exportData(
    params: {
      timeframes: BudgetTimeframe[];
      filteredTimeframes: BudgetTimeframe[];
      sharedCostRules: SharedCostRule[];
      segments: BudgetSegmentAccess[];
      segmentGroups: SegmentGroup[];
      budget: Budget;
    }
  ) {
    const { timeframes, filteredTimeframes, sharedCostRules, segments, segmentGroups, budget } = params;
    const exportPayload: ManagePageExportPayload = createDeepCopy({
      data: this.processTableData(this.dataService.data),
      grandTotal: this.dataService.grandTotal,
      performance: this.dataService.performanceColumnData,
      viewMode: this.modeService.viewMode,
      dataMode: this.modeService.dataMode,
      spendingFlags: this.modeService.spendingModeFlags,
      allocationFlags: this.modeService.allocationModeFlags,
      timeframes: this.prepareObjectsMap(timeframes),
      sharedCostRules: this.prepareObjectsMap(sharedCostRules),
      segments: this.prepareObjectsMap(segments),
      segmentGroups: this.prepareObjectsMap(segmentGroups),
      budget: this.omitFields(budget),
      includedTimeframeIds: filteredTimeframes.map(tf => tf.id),
    });

    this.isLoading.next(true);

    return this.http.post(this.exportUrl, exportPayload, { responseType: 'blob' })
      .pipe(
        tap(data => ExportDataService.downloadDataAsFile(data, this.exportFileName, EXPORT_TYPES.XLSX)),
        catchError(err => parseErrorBlob(err))
      )
      .subscribe({
        error: () => this.utilityService.showCustomToastr(this.errorMessages.FAILED_TO_EXPORT),
        complete: () => this.isLoading.next(false)
      });
  }

}
