export const objectPlaceholderName = '{object}';
export const objectCounter = '{object}';

export const messages = {
  NO_OBJECT_FOUND_ERROR_MSG: `Hmm, that ${objectPlaceholderName} may have been deleted`,
  UNABLE_TO_LOAD_OBJECT_ERROR_MSG: `Unable to load ${objectPlaceholderName} details`,
  UNABLE_TO_CREATE_OBJECT_ERROR_MSG: `Unable to create ${objectPlaceholderName}`,
  UNABLE_TO_SAVE_OBJECT_ERROR_MSG: `Failed to save ${objectPlaceholderName} changes, please try again`,
  UNABLE_TO_DELETE_OBJECT_ERROR_MSG: `Failed to delete ${objectPlaceholderName}`,
  UNABLE_TO_MOVE_TO_BUDGET_ERROR_MSG: `Failed to move ${objectPlaceholderName} to a new budget`,
  DELETE_OBJECT_SUCCESS_MSG: `${objectPlaceholderName} was deleted successfully`,
  UNABLE_TO_LOAD_OBJECT_TYPES_ERROR_MSG: `Failed to load ${objectPlaceholderName} types`,
  UNABLE_TO_REOPEN_OBJECT_ERROR_MSG: `Failed to open ${objectPlaceholderName}`,
  UNABLE_TO_CLOSE_OBJECT_ERROR_MSG: `Failed to close ${objectPlaceholderName}`,
  UNABLE_TO_CLONE_ERROR_MSG: `Failed to duplicate ${objectPlaceholderName}`,
  NEW_OBJECT_CREATED_MSG: `${objectPlaceholderName} created. Switched to the ${objectPlaceholderName} view`,
  OBJECT_UPDATED_MSG: `${objectPlaceholderName} updated. Switched to the ${objectPlaceholderName} view`,
  NEW_OBJECT_CREATED_SUCCESS_MSG: `${objectPlaceholderName} created.`,
  OBJECT_UPDATED_SUCCESS_MSG: `${objectPlaceholderName} updated.`,
  CLOSE_MULTIPLE_OBJECTS_TITLE: `Closing ${objectCounter} Campaign(s) and/or Expense Group(s)`,
  CLOSE_CAMPAIGN_TITLE: 'Closing Campaign',
  CLOSE_EXPENSE_GROUP_TITLE: 'Closing Expense Group',
  CLOSE_OBJECT_MSG: 'Closing campaigns or expense groups will also close any children underneath them - campaigns, expense groups, and/or expenses.',

  SAVE_CHANGES_SUCCESS_MSG: 'Changes have been saved',
  SAVING_BEFORE_ADD_EXPENSE: `Saving ${objectPlaceholderName} before adding expense`,
  UNABLE_TO_LOAD_METRICS_ERROR_MSG: 'Failed to load metrics',
  UNABLE_TO_LOAD_VENDORS_ERROR_MSG: 'Failed to load vendors',
  UNABLE_TO_LOAD_TAGS_ERROR_MSG: 'Failed to load tags',
  UNABLE_TO_LOAD_ACCOUNT_CURRENCY_ERROR_MSG: 'Failed to get account currency',
  UNABLE_TO_PROCESS_METRICS_INHERITANCE_ERROR_MSG: 'Failed to process metrics inheritance',
  UNABLE_TO_SYNC_INTEGRATION_VALUES_ERROR_MSG: 'Failed to sync metrics integration values',
  UNABLE_TO_UPDATE_SF_CAMPAIGNS_ERROR_MSG: 'Failed to update Salesforce campaigns',
  UNABLE_TO_UPDATE_HS_CAMPAIGNS_ERROR_MSG: 'Failed to update Hubspot campaigns',
  METRIC_BECOME_KEY_AFTER_SAVE: 'This metric will become the key metric when you save this campaign',
  NO_KEY_METRIC_SELECTED: 'No key metric will be selected when you save',
  KEY_METRIC_TOOLTIP_DISABLED: 'Must have a Revenue Per Outcome > 0 to set as key metric',
  KEY_METRIC_TOOLTIP_NO_RPO: `The campaign value key metric is the single metric \n that best measures success for a given campaign.
   If selected, the target and current values of this metric \n will be used to calculate estimated campaign value.
   However, if there is no Revenue per Outcome value set for \n the key metric, the Estimated Campaign Value and ROI will be 0.
   To set the Revenue per Outcome, navigate \n to the settings icon and click on Metric Funnel.`,
  COPY_OBJECT_ID_SUCCESS_MSG: `${objectPlaceholderName} copied successfully`,
  COPY_OBJECT_ID_ERROR_MSG: 'Some error has occurred. Please try again.',
  ADD_RELATED_EXPENSES_ERROR_MSG: 'Failed to add related expenses.',
  VERIFY_INVOICE_SUCCESS_MSG: 'Expense verified',
  SEGMENTLESS_ALLOCATION_TOOLTIP: 'In order to edit an allocation for this thematic campaign directly, you must assign this campaign to a segment',
  SEGMENTLESS_ALLOCATION_CEG_TOOLTIP: 'You can’t enter allocations until you enter the Segment in the Details tab',
};

type MessageGetter = (objectType: string, data?: Record<string, string>) => string;

export const messageGetters: Record<string, MessageGetter> = {
  MOVE_OBJECT_SUCCESS: (objectType, data) =>
    `${objectType} was moved to '${data.budgetName}' budget successfully. Please find it there.`,
};
