<widget-card [isLoading]="state === widgetState.LOADING || innerDataLoading">
  <div class="overdue-expenses">
    <details-widget-title [count]="activeCounter" text="Update my overdue expenses">
      <fa-icon slot="icon" [fixedWidth]="true" [icon]="['fas', 'alarm-exclamation']"></fa-icon>
    </details-widget-title>
    <div class="content" [class.is-not-ready]="state !== widgetState.READY">
      <widget-card-placeholder *ngIf="!overdueExpenses.length">
        <fa-duotone-icon [icon]="['fad', 'socks']" slot="icon"></fa-duotone-icon>
        <ng-container slot="content">
          Be footloose and fancy free,<br>
          none of your expenses are overdue!
        </ng-container>
      </widget-card-placeholder>
      <ng-container *ngIf="overdueExpenses.length">
        <overdue-expenses-table
          [data]="tableData"
          [currencyMap]="currencyMap"
          [timeframes]="timeframesList"
          [currentTimeframe]="currentTimeframe"
          [overdueTimeframes]="overdueTimeframes"
          (onChange)="handleDataChange($event)"
          (onCounterUpdated)="handleCounterUpdate($event)"
          (setLoadingState)="innerDataLoading = $event"
        ></overdue-expenses-table>
        <overdue-expenses-legend></overdue-expenses-legend>
      </ng-container>
    </div>
  </div>
</widget-card>
