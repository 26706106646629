import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'add-new-metric-btn',
  templateUrl: './add-new-metric-btn.component.html',
  styleUrls: ['./add-new-metric-btn.component.scss']
})
export class AddNewMetricBtnComponent {
  @HostBinding('class.flipped')
  @Input() flipped = false;

  @HostBinding('class.top')
  @Input() top = false;
}
