import { Component, Input } from '@angular/core';
import { MetricProgressState } from '../../types/metric-progress-state.type';


@Component({
  selector: 'metric-performance-tooltip',
  templateUrl: './metric-performance-tooltip.component.html',
  styleUrls: ['./metric-performance-tooltip.component.scss']
})
export class MetricPerformanceTooltipComponent {
  @Input() name = '';
  @Input() progressState: MetricProgressState = MetricProgressState.InTarget;
  @Input() estimatedDiffPercentage = 0;
  @Input() current = 0;
  @Input() target = 0;

  public readonly decimalPipeFormat = '1.2-2';
}
