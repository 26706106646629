<button
  *ngIf="actions.length === 1"
  [disabled]="actions[0].disabled"
  class="single-action-btn"
  mat-button
  color="primary"
  (click)="actions[0].handler && actions[0].handler()"
>
  <fa-icon [icon]="actions[0].faIcon"></fa-icon>
  <span>{{ actions[0].name }}</span>
</button>
<div class="menu" [ngClass]="{'drawer': isDrawer, 'ceg-budget-menu': isCEGBudget}">
  <ng-container *ngIf="actions.length > 1">
    <button
            mat-stroked-button
            color="primary"
            [matMenuTriggerFor]="objectMenu" [disabled]="!actions || !actions.length">
      <ng-container *ngIf="isDrawer; else commonBtn">
        <fa-icon *ngIf="isCEGBudget else notCEGBudgetMenu" [icon]="['far', 'ellipsis-v']"></fa-icon>
        <ng-template #notCEGBudgetMenu>
          <fa-duotone-icon class="finger-icon" [icon]="['fad', 'tools']"></fa-duotone-icon>
          Manage
          <fa-icon class="chevron-icon" [icon]="['fas', 'chevron-down']"></fa-icon>
        </ng-template>
      </ng-container>
      <ng-template #commonBtn>
        Manage {{ objectDisplayType }}
        <span class="mat-select-arrow-wrapper">
              <span class="mat-select-arrow"></span>
      </span>
      </ng-template>
    </button>
    <mat-menu #objectMenu="matMenu" class="mat-pl-menu no-padding">
      <ng-container *ngFor="let action of actions;">
        <button
          mat-menu-item
          *ngIf="action.type === ACTION_TYPE.CHANGE_BUDGET"
          [matMenuTriggerFor]="budgetMenu"
          [disabled]="action.disabled || !allowedBudgetList || !allowedBudgetList.length"
        >
          <fa-icon [icon]="action.faIcon"></fa-icon>
          <span>{{ action.name }}</span>
        </button>
        <button
          mat-menu-item
          *ngIf="regularMenuItems.includes(action.type)"
          (click)="action.handler && action.handler()"
          [ngClass]="{ 'mat-pl-divider': action.type === ACTION_TYPE.DELETE }"
          [class.red-trash]="isDrawer && isCEGBudget && action.type === ACTION_TYPE.DELETE"
          [disabled]="action.disabled"
        >
          <fa-icon [icon]="action.faIcon"></fa-icon>
          <span>{{ action.name }}</span>
        </button>
        <button
          mat-menu-item
          *ngIf="action.type === ACTION_TYPE.SHOW_PARENT"
          [matMenuTriggerFor]="parentHierarchy"
          [disabled]="isMetricDrawer ? false : (action.disabled  || !budgetList || !budgetList.length)"
        >
          <fa-icon [icon]="action.faIcon"></fa-icon>
          <span>{{ action.name }}</span>
          <fa-icon class="chevron-icon" [icon]="['fas', 'chevron-right']"></fa-icon>
        </button>
        <button
          mat-menu-item
          *ngIf="action.type === ACTION_TYPE.SHOW_CHILD"
          [matMenuTriggerFor]="childHierarchySelect"
          [disabled]="isMetricDrawer ? !childMetricHierarchyItems.length : (action.disabled || !budgetList || !budgetList.length)"
        >
          <fa-icon [icon]="action.faIcon"></fa-icon>
          <span>{{ action.name }}</span>
          <fa-icon class="chevron-icon" [icon]="['fas', 'chevron-right']"></fa-icon>
        </button>
      </ng-container>
    </mat-menu>
    <mat-menu #budgetMenu="matMenu" class="mat-pl-menu no-padding">
      <button mat-menu-item *ngFor="let budget of allowedBudgetList" (click)="budgetToMoveSelected.emit(budget)">
        <span>{{ budget.name }}</span>
      </button>
    </mat-menu>

    <mat-menu #parentHierarchy="matMenu"
      [class]="'hierarchy-menu'"
      >
      <div *ngFor="let item of hierarchyItems; let i = index"
        class="nav-item"
        [ngStyle]="isMetricDrawer ? item.objectType !== OBJECT_TYPES.metric ? {'padding-left': ((i * 8) + 20) + 'px'}: {'padding-left': ((i * 8) + 16) + 'px'}: {'padding-left': (i * 24 + 16) + 'px'}"
        [ngClass]="{
          'clickable': !!item.onClick,
          'closed': item.isClosed,
          'metric': item.objectType === OBJECT_TYPES.metric
       }"
       (click)="item.onClick && item.onClick(item.id)">
        <object-name
          [name]="item.name"
          [objectType]="item.objectType"
          [closed]="item.isClosed"
          [iconSize]="17"
          [flipIcon]="item.flipIcon">
        </object-name>
      </div>
    </mat-menu>

    <mat-menu #childHierarchySelect >
      <hierarchy-single-select
        *ngIf="!isMetricDrawer"
        class="child-hierarchy-select"
        [optionList]="childHierarchy"
        [selectConfig]="selectConfig"
        [allowGroupSelection]="true"
        (selectOption)="onHandleHierarchyClick($event)">
      </hierarchy-single-select>

      <div *ngIf="isMetricDrawer">
      <div *ngFor="let item of childMetricHierarchyItems; let i = index"
        class="nav-item"
        [ngStyle]="item.objectType !== OBJECT_TYPES.metric ? {'padding-left': ((item.level * 8) + 20) + 'px'}: {'padding-left': ((item.level * 8) + 16) + 'px'}"
        [ngClass]="{
          'clickable': !!item.onClick,
          'closed': item.isClosed,
          'metric': item.objectType === OBJECT_TYPES.metric
       }"
       (click)="item.onClick && item.onClick(item.id)">
        <object-name
          [name]="item.name"
          [objectType]="item.objectType"
          [closed]="item.isClosed"
          [iconSize]="17"
          [flipIcon]="item.flipIcon">
        </object-name>
      </div>
    </div>
    </mat-menu>
  </ng-container>
</div>
