<mat-expansion-panel
  #panel
  hideToggle
  [disabled]="editMode || disabled"
  [expanded]="panelsStates[newState?.id]"
  (opened)="setExpansionState(true)"
  (closed)="setExpansionState(false)"
  class="metrics-panel product-funnel-panel mb-2">
  <mat-expansion-panel-header>
    <div class="d-flex ai-center">
      <fa-icon
        class="expansion-panel-chevron"
        [class.expanded]="panel.expanded"
        [icon]="['fas', 'chevron-down']"
        data-html2canvas-ignore
      ></fa-icon>
      <div class="d-flex">
        <funnel-name-input
          class="ml-1 mr-1"
          [ngClass]="{ 'not-active': !newState.activated && !editMode }"
          [editModeActive]="editMode"
          [funnelId]="newState.id"
          [name]="newState.productName"
          (nameChanged)="funnelNameChanged($event)"
        ></funnel-name-input>
        <view-togglers
          [hidden]="!panelsStates[newState?.id]"
          class="mr-1"
          [activeView]="activeView"
          [disabled]="disabled"
          (viewChanged)="viewChanged($event)">
        </view-togglers>
        <revenue-to-profit-input
          *ngIf="editMode && panelsStates[newState?.id] && activeView === productFunnelView.PROFIT"
          [value]="newState.revenueToProfit"
          [disabled]="disabled"
          (focusChanged)="inputFocusChanged($event, funnelInputsType.RevenueToProfit, null)"
          (valueChange)="revenueToProfitChanged($event)"></revenue-to-profit-input>
      </div>
    </div>

    <div class="d-flex" data-html2canvas-ignore>
      <div class="d-flex" *ngIf="!editMode">
        <button *ngFor="let action of actions"
                mat-icon-button
                class="mat-button mr-05"
                [matTooltip]="action.tooltip"
                [matTooltipShowDelay]="1000"
                matTooltipClass="dark-theme-tooltip simple"
                matTooltipPosition="above"
                [disabled]="disabled || (action.id === productFunnelAction.DOWNLOAD && isDownloadInProgress)"
                (click)="action.cb($event)">
          <fa-duotone-icon [icon]="action.icon"></fa-duotone-icon>
        </button>
      </div>

      <div *ngIf="editMode" class="d-flex">
        <button mat-button
                color="primary"
                class="mr-05"
                (click)="cancel($event)">Cancel</button>
        <button mat-flat-button
                color="primary"
                [disabled]="!funnelValid || (newState.id !== 0 && !funnelChanges && !funnelRowsChanges && !budgetCalculatorChanges)"
                (click)="save($event)">Save</button>
      </div>
      <div class="d-flex ai-center" *ngIf="!editMode">
        <div class="sort-controls">
          <button mat-icon-button
                  class="mat-button"
                  [disabled]="disabled"
                  matTooltip="Move up"
                  [matTooltipShowDelay]="1000"
                  matTooltipClass="dark-theme-tooltip simple"
                  matTooltipPosition="above"
                  (click)="changeOrder($event, true)">
            <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
          </button>
          <button mat-icon-button
                  class="mat-button"
                  [disabled]="disabled"
                  matTooltip="Move down"
                  [matTooltipShowDelay]="1000"
                  matTooltipClass="dark-theme-tooltip simple"
                  matTooltipPosition="above"
                  (click)="changeOrder($event, false)">
            <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
          </button>
        </div>
        <mat-slide-toggle
          class="slide-toggle"
          [checked]="newState.activated"
          [disabled]="disabled"
          [matTooltip]="'Make the metrics from this funnel ' + (newState.activated ? 'not ' :  '') + 'available to your team'"
          [matTooltipShowDelay]="1000"
          matTooltipClass="dark-theme-tooltip simple"
          matTooltipPosition="above"
          (click)="activateFunnelClick($event)"
          disableRipple="true">Activated</mat-slide-toggle>
      </div>

    </div>
  </mat-expansion-panel-header>

  <div class="animated-columns funnels-list"
       [ngClass]="{ 'freeze-animations': freezeAnimations }">
    <div class="funnels-header" [ngClass]="{ 'edit-mode': editMode }">
      <div class="count-metric">
        <span>Count metric</span>
        <span>Target</span>
      </div>
      <div class="arpo animate-width"
           [ngClass]="{ 'collapsed': activeView < productFunnelView.ARPO }">
        <span>Average Revenue <br> Per Outcome</span>
      </div>
      <div class="profit animate-width"
           [ngClass]="{ 'collapsed': activeView < productFunnelView.PROFIT }">
        <span>Average Profit <br> Per Outcome</span>
      </div>
      <div class="conversion-rate">Conversion</div>
      <div class="currency-metric">
        <span>Target</span>
        <span>Currency metric</span>
      </div>
      <div class="profit highlighted animate-width"
           [ngClass]="{ 'collapsed': activeView < productFunnelView.PROFIT }">Profit
      </div>
    </div>
    <div *ngFor="let row of newState.metricFunnelRows; let i = index;" class="funnel-row">

      <div class="metric-block ut-count-metric"
         [ngClass]="{
            'has-metric': row.countMetric,
            'hidden-from-team': row.countMetric?.isHidden
         }"
         [attr.order]="getOrderAttr(i)">
        <ng-container
          [ngTemplateOutlet]="row.countMetric ? metricMaster : addMetricButton"
          [ngTemplateOutletContext]="{
            $implicit: row.countMetric,
            metricType: metricValueType.Count,
            parentRow: row,
            index: i
        }">
        </ng-container>
        <div class="arpo animate-width"
             *ngIf="row.countMetric"
             [ngClass]="{ 'collapsed': activeView < productFunnelView.ARPO }">
          <span class="metric-value"
            [ngClass]="{
              'highlighted': focusedInput === funnelInputsType.BaseRevenue || highlightFromConversion(i),
              'p-0': row.isBaseRow && editMode
             }">
              <plc-editable-value
                *ngIf="row.isBaseRow && editMode"
                class="editable-value-target"
                [class]="emptyFieldsOutline[outlinedFieldType.COUNT_ARPO] ? 'outline-style--' + emptyFieldsOutline[outlinedFieldType.COUNT_ARPO] : ''"
                [prefix]="defaultPrefix"
                [controlValue]="row.countMetric.revenuePerOutcome"
                [highlighted]="false"
                [isReadOnly]="false"
                [numberFormat]="numberFormatLong"
                [metric]="row.countMetric"
                [isNumber]="true"
                [selectOnFocus]="true"
                [placeholder]="'ARPO'"
                (focusIn)="inputFocusChanged(true, funnelInputsType.BaseRevenue, i)"
                (focusOut)="inputFocusChanged(false, funnelInputsType.BaseRevenue, i)"
                (onNameChanged)="baseRevenuePerOutcomeChanged($event, row)"
              ></plc-editable-value>
            <ng-container *ngIf="!row.isBaseRow || !editMode">
              <span class="currency">$ </span>
              <plc-animated-number class="number ut-rpo"
                               [value]="row.countMetric.revenuePerOutcome"
                               [numberFormat]="numberFormatLong"></plc-animated-number>
            </ng-container>
          </span>
        </div>
        <div class="profit animate-width"
             *ngIf="row.countMetric"
             [ngClass]="{ 'collapsed': activeView < productFunnelView.PROFIT }">
          <span class="profit-value"
                [ngClass]="{
                  'highlighted': focusedInput === funnelInputsType.RevenueToProfit
                }">
            <span>{{ newState.revenueToProfit }}%</span>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464466C7.97631 0.269204 7.65973 0.269204 7.46447 0.464466C7.2692 0.659728 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM1 4.5H11V3.5H1V4.5Z"
                fill="currentColor"/>
            </svg>
          </span>
          <span *ngIf="newState.revenueToProfit !== null && row.countMetric?.revenuePerOutcome !== null"
                class="metric-value"
                [ngClass]="{
                  'highlighted': highlightFromConversion(i) ||
                   focusedInput === funnelInputsType.BaseRevenue ||
                   focusedInput === funnelInputsType.RevenueToProfit
                }">
            <span class="currency">$</span>
            <plc-animated-number class="number"
                             [value]="(newState.revenueToProfit / 100) * row.countMetric.revenuePerOutcome"
                             [numberFormat]="numberFormatLong"></plc-animated-number>
          </span>
        </div>
        <add-new-metrics-row
          [editMode]="editMode"
          [index]="i"
          [hideBottomBtn]="i >= baseRowIndex"
          (addRowAtIndex)="addNewRow($event, metricValueType.Count)"></add-new-metrics-row>
      </div>

      <div class="conversion-rate" [attr.order]="getOrderAttr(i)">
        <plc-conversion-rate
          *ngIf="row.conversionRate !== undefined && (i + 1) !== newState.metricFunnelRows.length"
          [editModeActive]="editMode"
          [rate]="row.conversionRate"
          [selectOnFocus]="true"
          [setFocus]="emptyFieldFocus === (outlinedFieldType.CONVERSION + 0) && i === 0"
          [outlineStyle]="emptyFieldsOutline[outlinedFieldType.CONVERSION + i]"
          (focusChanged)="inputFocusChanged($event, funnelInputsType.Conversion, i)"
          (rateChanged)="conversionRateChanged($event, row, i)"></plc-conversion-rate>

        <plc-connected-arrow
          *ngIf="row.isBaseRow"
          dynamicPortalTooltip
          [tooltipShowDelay]="1000"
          [tooltipContext]="joinerTooltipContext"
          [tooltipPositionCssClass]="'above'"
          [tooltipPosition]="joinerTooltipPosition">
        </plc-connected-arrow>
      </div>

      <div class="metric-block ut-currency-metric"
           [ngClass]="{
              'has-metric': row.currencyMetric,
              'hidden-from-team': row.currencyMetric?.isHidden
            }"
           [attr.order]="getOrderAttr(i)">
        <ng-container
          [ngTemplateOutlet]="row.currencyMetric ? metricMaster : addMetricButton"
          [ngTemplateOutletContext]="{
            $implicit: row.currencyMetric,
            metricType: metricValueType.Currency,
            parentRow: row,
            index: i
           }">
        </ng-container>
        <div class="profit animate-width"
             *ngIf="row.currencyMetric && i >= baseRowIndex"
             [ngClass]="{ 'collapsed': activeView < productFunnelView.PROFIT }">
          <span class="profit-value ml-1"
                [ngClass]="{
                  'highlighted': focusedInput === funnelInputsType.RevenueToProfit
                }">
            <span>{{ newState.revenueToProfit }}%</span>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464466C7.97631 0.269204 7.65973 0.269204 7.46447 0.464466C7.2692 0.659728 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM1 4.5H11V3.5H1V4.5Z"
                fill="currentColor"/>
            </svg>
          </span>
          <span *ngIf="newState.revenueToProfit !== null && row.currencyMetric?.target !== null"
                class="metric-value"
                [ngClass]="{ 'highlighted': highlightFromConversion(i) ||
                   focusedInput === funnelInputsType.Target ||
                   focusedInput === funnelInputsType.RevenueToProfit
                }">
            <span class="currency">$</span>
            <plc-animated-number class="number"
                             [value]="(newState.revenueToProfit / 100) * row.currencyMetric.target"
                             [numberFormat]="numberFormatLong"></plc-animated-number>
          </span>
        </div>
        <add-new-metrics-row
          [editMode]="editMode"
          [index]="i"
          [isFlipped]="true"
          (addRowAtIndex)="addNewRow($event, metricValueType.Currency)"></add-new-metrics-row>
      </div>

    </div>
  </div>

  <product-budget-calculations
    *ngIf="activeView === productFunnelView.ARPO"
    [productId]="newState.id"
    [baseRowIndex]="baseRowIndex"
    [rows]="newState.metricFunnelRows"
    [calculatorData]="newState.budgetCalculatorData"
    [editMode]="editMode"
    [companyCurrency]="currencySymbol"
    (calculatorDataUpdate)="updateBudgetCalculatorData($event)"
    [@scaleVertical]
  ></product-budget-calculations>
</mat-expansion-panel>

<ng-template #metricMaster let-metric let-parentRow="parentRow" let-index="index">
  <plc-metric-master
    [ngClass]="{
      'count-metric': metric.valueType === metricValueType.Count,
      'currency-metric': metric.valueType === metricValueType.Currency
    }"
    [metric]="metric"
    [funnelDisabled]="disabled"
    [highlightNumber]="
      focusedInput === funnelInputsType.Target ||
      (focusedInput === funnelInputsType.BaseRevenue && metric.valueType === metricValueType.Count) ||
      highlightFromConversion(index)
    "
    [targetOutlineStyle]="getMetricOutlineStyle(index, metric.valueType)"
    [numberFormat]="metric.valueType === metricValueType.Currency ? numberFormatLong : numberFormatShort"
    [isConnectedMetric]="parentRow.isBaseRow"
    [flipped]="metric.valueType === metricValueType.Currency"
    [editMode]="editMode"
    [selectTargetOnFocus]="true"
    [checkValidity$]="checkMetricsValidity$"
    [prefix]="metric.valueType === metricValueType.Currency ? defaultPrefix : null"
    [precision]="metric.valueType === metricValueType.Currency ? 2 : 0"
    [matTooltipDisabled]="!metric.isHidden || editMode"
    [matTooltip]="'This metric is hidden from your team'"
    [matTooltipShowDelay]="600"
    matTooltipClass="dark-theme-tooltip simple"
    matTooltipPosition="above"
    (deactivateMetric)="deactivateMetric(metric)"
    (deleteMetric)="onDeleteMetricClick(metric)"
    (nameChanged)="nameChanged($event, metric)"
    (targetChanged)="targetChanged($event, metric, parentRow, index)"
    (errorStateChanged)="metricErrorStateChanged($event, metric)"
    (focusChanged)="inputFocusChanged($event, funnelInputsType.Target, index)"
    (usageCountClick)="openObjectsUsingMetric.emit($event)"
  ></plc-metric-master>
</ng-template>

<ng-template #addMetricButton let-metricType="metricType" let-parentRow="parentRow" let-index="index">
  <add-metric-btn
    [ngClass]="{
      'visually-hidden': !editMode || (index > baseRowIndex && metricType === metricValueType.Count),
      'above-connected-line': index < baseRowIndex,
      'is-count': metricType === metricValueType.Count,
      'is-currency': metricType === metricValueType.Currency,
      'add-metric-btn-simple': activeView === productFunnelView.SIMPLE,
      'add-metric-btn-arpo': activeView === productFunnelView.ARPO,
      'add-metric-btn-profit': activeView === productFunnelView.PROFIT
    }"
    (addNewMetric)="addNewMetric(parentRow, metricType, index)"
  ></add-metric-btn>
</ng-template>
