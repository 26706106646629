import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { COLORS_MAP } from '@shared/constants/colors.constants';
import { MetricEstimatedPerformanceData } from '@shared/services/backend/metric.service';
import { CPOTooltipTemplate, ROITooltipTemplate, RROITooltipTemplate, getMetricExplanationTooltip } from 'app/budget-object-details/components/metrics/constants';
import { MetricsUtilsService } from 'app/budget-object-details/services/metrics-utils.service';
import { MetricGraphData } from 'app/budget-object-details/types/metric-graph-data.interface';
import { MetricMappingDetailsState } from 'app/budget-object-details/types/metric-mapping-details-state.interface';
import { throws } from 'assert';

@Component({
  selector: 'metric-details-performance',
  templateUrl: './metric-details-performance.component.html',
  styleUrls: ['./metric-details-performance.component.scss']
})
export class MetricDetailsPerformanceComponent implements OnInit, OnChanges {

  @Input() currentMetricState: MetricMappingDetailsState;
  @Input() metricValueLastUpdated: Date;
  performanceGraphData: MetricGraphData;
  @Input() 
  set graphData(gd: MetricGraphData) {
    if(this.isGraphDataValid) {
    this.performanceGraphData = gd;
    if(gd) {
      this.performanceGraphData.milestones = [...gd.milestones];
      this.performanceGraphData.CPORecords = [...gd.CPORecords];
      this.performanceGraphData.ROIRecords = [...gd.ROIRecords];
      this.performanceGraphData.metricValueRecords = [...gd.metricValueRecords];
    }
  }
  }

  get graphData() {
    return this.performanceGraphData;
  }
  @Input() metricProgressTowardsTarget: any;
  @Input() currentState;
  @Input() displayDecimal: boolean;
  @Input() unsavedChangesFlag: any;
  @Input() budget: any;
  @Input() performanceEstimateData: MetricEstimatedPerformanceData;
  @Input() isGraphDataValid: boolean = true;
  showRoiChart: boolean = false;
  currentPerformanceChartSelected: string = 'metric-values';
  decimalDisplayFormat = '1.0-0';
// ===========
@Input() currencyCode: string;
@Input() revenuePerOutcome: number;
@Input() totalValue: number;
@Input() totalTarget: number;
@Input() costPerOutcome: number;
@Input() targetCostPerOutcome: number;
@Input() returnOnInvestment: number;
@Input() targetReturnOnInvestment: number;
graphDataMilestones: any[];
public readonly colorsMap = COLORS_MAP;
progressIndication: string;
CPOTooltip = '';
ROITooltip = '';
RROITooltip = '';

constructor(private utils: MetricsUtilsService) {}

  ngOnInit(): void {
    this.CPOTooltip = getMetricExplanationTooltip(CPOTooltipTemplate, true);
    this.RROITooltip = getMetricExplanationTooltip(RROITooltipTemplate, true);
    this.ROITooltip = getMetricExplanationTooltip(ROITooltipTemplate, true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.currentMetricState?.revenuePerOutcome) {
        this.showRoiChart = true
      }

      if (changes.displayDecimal) {
        this.decimalDisplayFormat = this.displayDecimal ? '1.2-2' : '1.0-0';
      }

      const costPerOutcomeUpdated = this.costPerOutcome && changes.costPerOutcome || changes.targetCostPerOutcome;
      const returnOnInvestmentUpdated = this.returnOnInvestment && changes.returnOnInvestment || changes.targetReturnOnInvestment;
      const rpoUpdated = this.revenuePerOutcome != null && changes.revenuePerOutcome;

      if (costPerOutcomeUpdated || returnOnInvestmentUpdated || rpoUpdated) {
        this.defineProgressIndication();
      }
    }
  }

  onPerformanceTabClick(tabName: string) {
    this.currentPerformanceChartSelected = tabName;
  }

  handleProgressChartModeChanged(event) {

  }

  defineProgressIndication() {
    const currentValue = this.revenuePerOutcome ? this.returnOnInvestment : this.costPerOutcome;
    const targetValue = this.revenuePerOutcome ? this.targetReturnOnInvestment : this.targetCostPerOutcome;
    const progressResult = this.utils.getProgressState(targetValue, currentValue, !this.revenuePerOutcome);
    this.progressIndication = progressResult.state;
  }

}
