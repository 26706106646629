<div class="budget-settings-summary-bar" [class.minimized]="minimized">
  <div class="bar-group allocated-group">
    <div class="budget-settings-amount">
      <div class="amount-label">Allocated</div>
      <div class="amount-value">{{ currency?.symbol }} {{ allocatedTotal || 0 | number: decimalPipeFormat }}</div>
    </div>
  </div>
  <div class="bar-group total-group">
    <div
      class="total-amount"
      (click)="clickable && onEditClicked.emit()"
      #rippleTrigger
    >
      {{ currency?.code }} {{ currency?.symbol }} {{ budgetTotal || 0 | number: decimalPipeFormat }}
      <div class="edit"
        *ngIf="clickable"
        matRipple
        [matRippleAnimation]="{ enterDuration: 300, exitDuration: 300 }"
        [matRippleCentered]="true"
        [matRippleUnbounded]="true"
        [matRippleRadius]="15"
        [matRippleColor]="rippleColor"
        [matRippleTrigger]="rippleTrigger"
      >
        <fa-duotone-icon slot="icon" [icon]="['fad', 'pencil']"></fa-duotone-icon>
      </div>
    </div>
    <budget-settings-summary-chart
      [isOverBudget]="isOverBudget"
      [allocatedPercentage]="allocatedPercentage"
    ></budget-settings-summary-chart>
  </div>
  <div class="bar-group right-group">
    <div class="remaining-group" [class.is-overbudget]="isOverBudget">
      <div class="budget-settings-amount">
        <div class="confetti-container"
             *ngIf="withConfetti"
             [confettiAnimation]="animateRemainingValue$"
             [confettiCustomOptions]="confettiDefaultOptions"
             [width]="400"
             [height]="400"
        ></div>
        <div class="amount-label">{{ isOverBudget ? 'Over Budget' : 'Remaining' }}</div>
        <div class="amount-value">{{ currency?.symbol }} <span>{{ remainingAmount || 0 | number: decimalPipeFormat }}</span></div>
      </div>
    </div>
  </div>
</div>
