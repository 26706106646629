<div class="email-holder">
  <div class="title">
    {{ emailBody?.subject }}
  </div>
  <div class="subject">
    <span class="user-icon"></span>
    <div class="from-holder">
      <span class="from">From: <strong>{{ emailBody?.from[0][0] }}</strong>&nbsp;<{{ emailBody?.from[0][1] }}></span>
      <span class="to">to: {{ emailBody?.to[0][0] }} <{{ emailBody?.to[0][1] }}></span>
      <span class="date">date: {{ emailBody?.date | date: 'medium'}}</span>
    </div>
  </div>
  <div class="email-body-holder">
    <div class="email-body" [innerHTML]="getEmailBody(emailBody?.body)"></div>
    <hr>
  </div>
</div>
