<div class="integration-mapping-management"
     [scrollShadow]="{ inner: matTree, header: headers, scroll: scrollContainer }"
     *ngIf="dataSourceLoaded && dataSource.data?.length; else placeholder">
  <div class="header-titles-wrap integration-row" #headers>
    <div class="header-titles integration-row-grid">
      <span>{{ integration.label || '' }} Campaign Name</span>
      <span>Owner</span>
      <span>Status</span>
      <span>Start Date</span>
      <span>End Date</span>
    </div>
    <div class="switch-all-mappings" [ngClass]="{ 'budget-mapping-mode': budgetMappingMode }">
      <span>Mapped to<br/>Planful Campaign</span>
      <span *ngIf="!budgetMappingMode">
            <mat-slide-toggle
              *ngIf="ableToSelectAll"
              labelPosition="before"
              [(ngModel)]="switchAllValue"
              [disabled]="readOnly"
              (change)="checkExistingMappings($event)"
              disableRipple="true"
            ></mat-slide-toggle>
          </span>
    </div>
  </div>
  <cdk-virtual-scroll-viewport
      #scrollContainer
      class="virtual-scroll-container"
      [ngClass]="{ 'full-page-mode': budgetMappingMode }"
      itemSize="44"
      [maxBufferPx]="600"
      [minBufferPx]="500">
      <div class="mat-tree" #matTree>
        <ng-container *cdkVirtualFor="let node of dataSource; trackBy: trackTreeNode;">
          <div class="mat-tree-node"
               [style.padding-left]="node.level * 10 + 'px'"
               [ngClass]="{ 'expanded': treeControl.isExpanded(node) }"
               [class]="'level-' + node.level">
            <ng-template *ngTemplateOutlet="rowTemplate; context: {node: node, treeControl: treeControl}">
            </ng-template>
          </div>
        </ng-container>
      </div>
    </cdk-virtual-scroll-viewport>
</div>

<ng-template #placeholder>
  <div class="placeholder">
    {{ placeholderText }}
  </div>
</ng-template>

<ng-template #rowTemplate let-node="node">
  <div class="row-header">
    <div class="tree-row-inner">
      <plus-toggler
        [active]="!treeControl.isExpanded(node)"
        (change)="treeControl.toggle(node)"
      ></plus-toggler>
      <div class="mapping-details integration-row-grid">
          <span class="campaign-name" [attr.title]="node.data.name">
            <span class="mapping-details-text">
              {{ node.data.name }}
            </span>
            <span class="embedded-campaigns-count"
                  [matTooltip]="'Embedded Campaigns'"
                  matTooltipClass="dark-theme-tooltip above"
                  matTooltipPosition="above">
                {{ node.data.embeddedCampaignsCount > 0 ? '(' + node.data.embeddedCampaignsCount + ')' : '' }}
              </span>
          </span>
        <span class="mapping-details-text">{{ node.data.owner }}</span>
        <span class="mapping-details-text">{{ node.data.status }}</span>
        <span class="mapping-details-text">{{ node.data.startDate ? (node.data.startDate | date) : 'N/A' }}</span>
        <span class="mapping-details-text">{{ node.data.endDate ? (node.data.endDate | date) : 'N/A' }}</span>
      </div>
    </div>
    <div
      *ngIf="!budgetMappingMode"
      class="integration-mapping-switch tree-row-inner bold-italic"
      [ngClass]="{
       'inactive': node.data.mappingState === IntegrationMappingState.NotMapped,
       'mapped': node.data.mappingState === IntegrationMappingState.Mapped,
       'mapped-elsewhere': node.data.existingMapping && node.data.mappingState !== IntegrationMappingState.Mapped
      }">
      <mat-slide-toggle
        disableRipple="true"
        labelPosition="before"
        [checked]="node.data.mappingState === IntegrationMappingState.Mapped"
        [disabled]="readOnly"
        (change)="checkExistingMappings($event, node)"
        >

        <span>{{ node.data.switcherTitle }}</span>
        <span *ngIf="node.data.existingMapping && node.data.mappingState !== IntegrationMappingState.Mapped"
              class="mapped-elsewhere-name" (click)="openCampaign($event, node.data)">
          {{ node.data.existingMapping.name }}
        </span>
      </mat-slide-toggle>
    </div>
    <div *ngIf="budgetMappingMode && locationItems"
         class="hierarchy-select-trigger"
         [ngClass]="{ 'selected': node.data.existingMapping }"
         (click)="setActiveNodeSelection(node, $event)"
    >
      <span>{{ getSelectTriggerTitle(node) }}</span>
      <fa-icon *ngIf="selectOpenedForNode !== node.data.id; else activeSelectIcon" [icon]="['fas', 'chevron-down']"></fa-icon>
    </div>
  </div>
  <div *ngIf="treeControl.isExpanded(node)" class="integration-data">
    <div class="integration-data-item">
      <b>Description</b>
      <p>{{ node.data.description || '-' }}</p>
    </div>
    <div class="integration-data-item">
      <b>Type</b>
      <p>{{ node.data.typeLabel || node.data.type }}</p>
    </div>
    <metrics-list
      class="integration-data-item"
      [metrics]="node.data.metrics"
    ></metrics-list>
  </div>
</ng-template>

<ng-template #activeSelectIcon>
  <fa-icon [icon]="['fas', 'chevron-up']"></fa-icon>
</ng-template>
