<div class="icon-container" [id]="id">
  <div *ngIf="isArrayIcon; else notAnArrayIcon">
    <div *ngIf="isDuotoneIcon; else elseBlock">
      <fa-duotone-icon
        class="icon-size-20"
        [icon]="[iconType, icon]"
        [rotate]="iconRotation"
      ></fa-duotone-icon>
    </div>
    <ng-template #elseBlock>
      <fa-icon
        class="icon-size-20"
        [icon]="[iconType, icon]"
        [rotate]="iconRotation"
      ></fa-icon
    ></ng-template>
  </div>
  <ng-template #notAnArrayIcon>
    <fa-icon
      class="icon-size-20"
      [icon]="nonArrayIcon"
      [rotate]="iconRotation"
    ></fa-icon>
  </ng-template>
</div>
<mat-form-field appearance="outline" class="p-form-field-outline readonly">
  <mat-label class="pfm-label">{{ inputTitle }}</mat-label>
  <input
    matInput
    type="text"
    [value]="
      budgetObjectSourceLabels[objectSource] || objectValue || defaultSource
    "
    readonly
    tabindex="-1"
  />
</mat-form-field>
