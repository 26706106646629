import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'show-hide-toggle',
  templateUrl: './show-hide-toggle.component.html',
  styleUrls: ['./show-hide-toggle.component.scss']
})
export class ShowHideToggleComponent {
  @Input() title = '';
  @Output() stateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  isClosed = true;

  handleButtonClick() {
    this.isClosed = !this.isClosed;
    this.stateChange.next(this.isClosed);
  }
}
