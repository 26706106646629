<div>
  <details-timestamps
    [created]="currentMetricState?.created"
    [updated]="currentMetricState?.updated"
  ></details-timestamps>

  <div class="details-panel">
    <!-- Start Date -->
    <div class="details-form-field w-50">
      <pfm-datepicker
        [label]="'Start Date'"
        (valueChange)="onHandleStartDateChange($event)"
        [value]="formData.controls.startDate.value"
      ></pfm-datepicker>
    </div>

    <drawer-read-only-input
      class="details-form-field w-50"
      inputTitle="Key Metric"
      icon="heart"
      [matTooltip]="'Key metric is set at the campaign level'"
      [matTooltipShowDelay]="600"
      matTooltipClass="dark-theme-tooltip simple multi-line"
      matTooltipPosition="above"
      iconType="fad"
      [isDuotoneIcon]="true"
      [objectValue]="
        formData.controls.mappingType.value?.toLowerCase() === 'campaign'
          ? formData.controls.isKeyMetric.value
            ? 'Yes'
            : 'No'
          : 'N/A'
      "
    ></drawer-read-only-input>

    <drawer-read-only-input
      class="details-form-field w-50"
      inputTitle="Funnel"
      icon="faFilterList"
      [matTooltip]="'Standalone metrics are not part of a funnel'"
      [matTooltipShowDelay]="600"
      matTooltipClass="dark-theme-tooltip simple multi-line"
      matTooltipPosition="above"
      [isArrayIcon]="false"
      [objectValue]="formData.controls.funnelName.value || 'N/A'"
    ></drawer-read-only-input>

    <drawer-read-only-input
      class="details-form-field w-50"
      inputTitle="Metric unit"
      icon="faRuler"
      [isArrayIcon]="false"
      [nonManualEntry]="true"
      [objectValue]="formData.controls.metricUnit.value || 'N/A'"
    ></drawer-read-only-input>
  </div>

  <div class="details-panel">
    <h2 class="panel-floating-title">Target & Milestones</h2>
    <div class="details-form-field w-100 add-milestone-btn-container">
      <button mat-stroked-button color="primary" (click)="addMilestone($event)">
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        Add milestone
      </button>
    </div>
    <metric-details-milestones
      *ngIf="currentMetricState?.milestones"
      [displayDecimal]="displayDecimal"
      [milestonesData]="currentMetricState.milestones"
      [metricFormData]="metricFormData"
      [currentMetricState]="currentMetricState"
      (deleteMilestone)="deleteMilestone($event)"
      (patchMilestonesList)="handleMilestonesUpdate($event)"
      (handleMilestoneValueUpdate)="handleMilestoneValueUpdate($event)"
    ></metric-details-milestones>
  </div>

  <div class="details-panel">
    <h2 class="panel-floating-title">Breakdown</h2>

    <div *ngIf="metricBreakdownData" class="metric-drawer-breakdown-container">
      <metric-details-breakdown
        *ngIf="isCampaignMetricDrawer"
         [isCampaignMetricDrawer]="isCampaignMetricDrawer"
         [metricName]="currentMetricState?.metricName || ''"
         [funnelName]="currentMetricState?.productName || ''"
         [currentMetricData]="metricBreakdownData.currentMetric?.data"
         [integrationsMetricData]="metricBreakdownData.integrationsMetric?.data"
         [childCampaignMetricsData]="metricBreakdownData.childCampaignMetrics?.data"
         [displayDecimal]="displayDecimal"
         [grandTotalData]="metricBreakdownData.grandTotal"
         >
      </metric-details-breakdown>

      <!-- Goal Metric Breakdown table -->

      <metric-details-breakdown
        *ngIf="!isCampaignMetricDrawer && metricBreakdownData.campaignMetrics"
         [isCampaignMetricDrawer]="isCampaignMetricDrawer"
         [metricName]="currentMetricState?.metricName || ''"
         [funnelName]="currentMetricState?.productName || ''"
         [goalCampaignMetricsData]="metricBreakdownData.campaignMetrics.data"
         [displayDecimal]="displayDecimal"
         [grandTotalData]="metricBreakdownData.grandTotal"
        >
      </metric-details-breakdown>

    </div>
  </div>

  <div class="details-panel" [formGroup]="formData">
    <div class="details-form-field w-100 mb-1">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'clipboard']"></fa-icon>
      </div>
      <linkified-text
        label="Notes"
        formControlName="notes"
        appearance="outline"
      ></linkified-text>
    </div>
  </div>
</div>
