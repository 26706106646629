import { Pipe, PipeTransform } from '@angular/core';
import { PlanObjectTagDataDO } from '../types/plan.type';
import { TagMapping } from '../types/tag-mapping.interface';

@Pipe({
  name: 'toTagMappings'
})
export class TagMappingsPipe implements PipeTransform {
  transform(tagsData: PlanObjectTagDataDO[]): TagMapping[] {
    return tagsData?.map(
      tag => ({
        name: tag.tags_name,
        display: tag.tags_name,
        id: tag.id,
        tags_mapping_id: tag.id,
        tagId: tag.tags_id
      })
    );
  }
}
