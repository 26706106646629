import {Directive, ElementRef, EventEmitter, Output, Input, AfterViewInit, Renderer2, OnDestroy} from '@angular/core';

@Directive({
  selector: '[scrollDetector]'
})
export class ScrollDetectorDirective implements AfterViewInit, OnDestroy {
  @Output() scrolledToTop = new EventEmitter();
  @Output() scrolledToBottom = new EventEmitter();
  @Output() scrolled = new EventEmitter();
  @Input() scrollContainerElement: any = null;
  @Input() set disableScrollDetector(value) {
    if (!value && !this.unlistenScroll) {
      this.unlistenScroll = this.renderer.listen(this.el.nativeElement, 'scroll', event => this.onScroll(event));
    } else if (value && this.unlistenScroll) {
      this.unlistenScroll();
      this.unlistenScroll = null;
    }
  }

  scrollContainer: any = null;
  unlistenScroll: () => void = null;

  detectTreshold = 50; // px
  public isScrollToBottomActive = false;
  public isScrollToTopActive = false;

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {
    this.scrollContainer = el.nativeElement.parentElement && el.nativeElement.parentElement.parentElement;
    this.disableScrollDetector = false;
  }

  ngAfterViewInit() {
    if (this.scrollContainerElement) {
      this.scrollContainer = this.scrollContainerElement;
    }
  }

  ngOnDestroy() {
    if (this.unlistenScroll) {
      this.unlistenScroll();
      this.unlistenScroll = null;
    }
  }

  onScroll(event) {
    this.scrolled.emit(event);

    if ((event.target.scrollHeight - this.detectTreshold) < (event.target.scrollTop + this.scrollContainer.clientHeight)) {
      if (!this.isScrollToBottomActive) {
        this.isScrollToBottomActive = true;
        this.scrolledToBottom.emit();
      }
    } else {
      if (this.isScrollToBottomActive) {
        this.isScrollToBottomActive = false;
      }
    }

    if (event.target.scrollTop < this.detectTreshold) {
      if (!this.isScrollToTopActive) {
        this.isScrollToTopActive = true;
        this.scrolledToTop.emit();
      }
    } else {
      this.isScrollToTopActive = false;
    }
  }
}
