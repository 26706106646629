import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'no-invoices-placeholder',
  templateUrl: './no-invoices-placeholder.component.html',
  styleUrls: ['./no-invoices-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoInvoicesPlaceholderComponent {
  @Input() congratulationEnabled: boolean;
  @Input() uploadDisabled: boolean;
  @Input() budgiEmail: string;
  @Output() changeMode = new EventEmitter<void>();
}
