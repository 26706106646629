import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MetricSelectItem, ProductBudgetCalculationsContext, ProductBudgetDialogComponent } from '../product-budget-dialog/product-budget-dialog.component';
import { BudgetCalculatorData, MetricFunnelRow } from '@common-lib/lib/corporate-page/metric-funnels.types';
import { baseCurrencyMetricName } from 'app/metric-funnels/metric-funnel.constants';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'product-budget-calculations',
  templateUrl: './product-budget-calculations.component.html',
  styleUrls: ['./product-budget-calculations.component.scss'],
})
export class ProductBudgetCalculationsComponent {
  @Input() productId: number;
  @Input() baseRowIndex: number;
  @Input() rows: MetricFunnelRow[];
  @Input() calculatorData: BudgetCalculatorData;
  @Input() editMode: boolean;
  @Input() companyCurrency: string;
  @Output() calculatorDataUpdate = new EventEmitter<BudgetCalculatorData>();

  private static getActiveCountMetrics(rows: MetricFunnelRow[]): MetricSelectItem[] {
    return rows.reduce((list: MetricSelectItem[], row) => {
      const metric = row.countMetric;
      if (metric?.id) {
        list.push({
          id: metric.id,
          name: metric.name,
          count: metric.target,
        });
      }
      return list;
    }, []);
  }

  constructor(
      private readonly dialog: MatDialog,
  ) { }

  public get disableCalculations(): boolean {
    const baseRow = this.rows[this.baseRowIndex];
    return !this.productId || !baseRow.currencyMetric.target || !baseRow.countMetric.target;
  }

  public showCalculationsDialog(): void {
    const dialogData: ProductBudgetCalculationsContext = {
      countMetricList: ProductBudgetCalculationsComponent.getActiveCountMetrics(this.rows),
      budgetCalculatorData: this.calculatorData,
      companyCurrency: this.companyCurrency,
      targetRevenueAmount: this.actualRevenue,
    };
    const dialogRef = this.dialog.open(ProductBudgetDialogComponent, {
      width: '530px',
      data: dialogData,
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(filter(data => !!data))
      .subscribe(data => {
        this.calculatorDataUpdate.next(data);
      });
  }

  public get actualRevenue(): number {
    return this.rows.find(row => row.currencyMetric?.name === baseCurrencyMetricName)?.currencyMetric.target || null;
  }
}
