import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ProductFunnelView } from '@common-lib/lib/corporate-page/metric-funnels.types';

@Component({
  selector: 'view-togglers',
  templateUrl: './view-togglers.component.html',
  styleUrls: ['./view-togglers.component.scss']
})
export class ViewTogglersComponent {
  @Input() activeView: ProductFunnelView;
  @Input() disabled: boolean;
  @Output() viewChanged = new EventEmitter<ProductFunnelView>();
  productFunnelView = ProductFunnelView;

  hoveredView: ProductFunnelView;
  togglers = [
    {
      id: ProductFunnelView.SIMPLE,
      title: 'Simple',
      tooltip: 'Conversion rates only',
    },
    {
      id: ProductFunnelView.ARPO,
      title: 'ARPO',
      tooltip: 'Average Revenue \nPer Outcome',
    },
    {
      id: ProductFunnelView.PROFIT,
      title: 'Profit',
      tooltip: null,
    },
  ];

  setView(e: MouseEvent, view: ProductFunnelView): void {
    e.stopPropagation();
    if (view === this.activeView) {
      return;
    }
    this.viewChanged.next(view);
  }

  onMouseenter(view: ProductFunnelView) {
    this.hoveredView = view;
  }

  onMouseleave() {
    this.hoveredView = null;
  }
}
