<div class="input-wrap"
     [ngClass]="{
      'edit-mode': isEditMode,
      'is-disabled': disabled
     }"
     (click)="handleFocus()">
  <span class="display-value">
    <span class="text">{{ displayValue | number: numberFormat }}</span>
  </span>

  <input #input
         type="text"
         blurOnEnterKeydown
         currencyMask
         [(ngModel)]="controlValue"
         [options]="currencyMaskOptions"
         [maxLength]="maxLength"
         [disabled]="disabled"
         (blur)="updateValue()">
</div>
<span class="converted">{{ convertedValue | number: numberFormat }}</span>
