import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, inject, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';
import { TotalsSidebarState } from '@manage-ceg/types/manage-ceg-page.types';
import { slideFromRightColumnAnimations } from '@manage-ceg/constants/manage-ceg-page.constants';
import { ManageTableCegRowBaseComponent } from '@manage-ceg/components/manage-table-ceg/components/manage-table-ceg-row-base';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { BudgetSummaryBarHelpers } from '@shared/components/budget-summary-bar/budget-summary-bar.helpers';
import { Configuration } from 'app/app.constants';


@Component({
  selector: '[manage-table-ceg-head-row]',
  templateUrl: './manage-table-ceg-head-row.component.html',
  styleUrls: ['./manage-table-ceg-head-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideFromRightColumnAnimations,
  ],
})
export class ManageTableCegHeadRowComponent extends ManageTableCegRowBaseComponent {
  private readonly configuration = inject(Configuration);

  @Input() selectAllValue = CheckboxValue.NotActive;
  @Output() onSelectAllChanged = new EventEmitter<MatCheckboxChange>();
  @Output() totalsSidebarStateChanged = new EventEmitter<TotalsSidebarState>();

  @HostBinding('class.with-formula')
  @Input() withFormula: boolean;

  public tooltipPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -15
  };

  protected tooltipContext = BudgetSummaryBarHelpers.createTooltipsForManageBudgetDefinitions(this.configuration.managePageKnowledgeLink);

  protected handleSelectAllChange($event: MatCheckboxChange): void {
    this.onSelectAllChanged.emit($event);
  }
}
