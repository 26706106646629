import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-subgroup-arrow',
  templateUrl: './icon-subgroup-arrow.component.html'
})

/**
* @class - IconCancelComponent
*/
export class IconSubgroupArrowComponent {
  @Input() color = '#061F38';
}
