<div class="box-modalContent box-changeLogModal">
  <div class="box-modalContentInn">
    <div class="box-modalContentPara">
      <button type="button" class="close" (click)="closeModal()"></button>
      <div class="box-modalContentParaInn">
        <figure id="box-loader" class="new-loader" [hidden]="!isPopupLoading">
          <span></span>
        </figure>
        <div class="modal-header border-topRadius">
          <h4 class="modal-title">Change Log</h4>
        </div>
        <div class="modal-body border-bottomRadius">
          <div class="table-dashboardEx">
            <table class="table-viewAdd table-evenOdd">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>Field</th>
                  <th>Action</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let log of logs; let i = index;">
                  <td><span class="table-contOverflow" title="{{log.date | date : 'MM-dd-yyyy, h:mm a'}}">{{log.date | date : 'MM-dd-yyyy, h:mm a'}}</span></td>
                  <td><span class="table-contOverflow" title="{{log.user.full_name}}">{{log.user.full_name}}</span></td>
                  <td><span class="table-contOverflow" title="{{log.field}}">{{log.field}}</span></td>
                  <td><span class="table-contOverflow" title="{{log.operation}}">{{log.operation}}</span></td>
                  <td><span class="table-contOverflow" title="{{log.change}}">{{log.change}}</span></td>
                </tr>
              </tbody>
              <tfoot *ngIf="logs.length < 1">
                <tr>
                  <td colspan="5" class="td-expenseNotFound">
                    <span>No content found</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
