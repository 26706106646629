import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription, fromEvent }  from 'rxjs'
import { DrawerType } from 'app/budget-object-details/services/drawer-stack.service';

@Component({
  selector: 'details-page-actions',
  templateUrl: './details-page-actions.component.html',
  styleUrls: ['./details-page-actions.component.scss']
})
export class DetailsPageActionsComponent implements OnDestroy {
  @Input() isReadOnlyMode = false;
  @Input() hasUnsavedChanges = false;
  @Input() isDrawer = false;
  @Input() objectLabel: string;
  @Input() detailsFormGroupData: UntypedFormGroup;
  @Input() showSaveAndNewButton = true;
  @Input() showSaveAndCreateButton = true;
  @Input() objectType: DrawerType;
  @Input() disableSaveAndCloseButton: boolean;


  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() saveAndNew = new EventEmitter<void>();
  @Output() saveAndClose = new EventEmitter<void>();

  public disableSaveAndCloseAction: boolean = false;
  private _nameControlFocusSubscription: Subscription = null;
  private _nameControlBlurSubscription: Subscription = null;
  private _formGroupStatusSubscription: Subscription = null;

  ngAfterViewInit() {    
    this._formGroupStatusSubscription = 
        this.detailsFormGroupData?.statusChanges
          .subscribe(status => {
            const nameControlEl: HTMLTextAreaElement = window.document.querySelector(".page-title textarea");
            if(this.objectType.toLowerCase() !== DrawerType.Expense && this.objectType.toLowerCase() !== DrawerType.Metric){
              this._handleFocusAndBlurEvent(nameControlEl);
            }

            this.disableSaveAndCloseAction = status !== "VALID"
          })
  }

  private _handleFocusAndBlurEvent(nameControlEl: HTMLTextAreaElement){
    if(!this._nameControlFocusSubscription) {
      this._nameControlFocusSubscription = fromEvent(nameControlEl, "focus").subscribe(
        _event => this.disableSaveAndCloseAction = true  
      )
    }
    
    if(!this._nameControlBlurSubscription) {
      this._nameControlBlurSubscription = fromEvent(nameControlEl, "blur").subscribe(
        _event => this.disableSaveAndCloseAction = !this.detailsFormGroupData.valid
      )
  }
}

  onHandleMenuClick(event): void {
    if (event.target.closest('.chevron-holder') === null) {
      this.saveAndClose.emit();
    }
  }

  ngOnDestroy(){
    // Removing the subscriptions
    this._nameControlFocusSubscription?.unsubscribe();
    this._nameControlBlurSubscription?.unsubscribe();
    this._formGroupStatusSubscription?.unsubscribe();
  }
}
