import { inject, Injectable } from '@angular/core';
import { ObjectHierarchy, ObjectHierarchyItem } from '../components/object-hierarchy-nav/object-hierarchy-nav.type';
import { CommonObject } from 'app/shared/types/budget-object.interface';
import { Configuration } from 'app/app.constants';
import { BudgetObjectParent } from '../types/budget-object-details-state.interface';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { HierarchyItem } from '../components/object-hierarchy-menu/object-hierarchy-menu.component';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { LightCampaign } from '@shared/types/campaign.interface';
import { LightProgram } from '@shared/types/program.interface';

export interface HierarchyTargetObject {
  id: number;
  name: string;
  sharedCostRuleId?: number;
  segmentGroupId?: number;
  segmentId?: number;
  goalId?: number;
  campaignId?: number;
  parentCampaign?: number;
  programId?: number;
  expenseId?: number;
  mode?: string;
  campaigns?: CommonObject[];
  programs?: CommonObject[];
}

export interface HierarchyObjects {
  sharedCostRules?: SharedCostRule[],
  segmentGroups?: SegmentGroup[],
  segments?: BudgetSegmentAccess[],
  goals?: CommonObject[],
  campaigns?: CommonObject[],
  programs?: CommonObject[],
}

@Injectable({
  providedIn: 'root'
})
export class ObjectHierarchyService {
  private readonly routingService = inject(AppRoutingService);
  private readonly configuration = inject(Configuration);

  private objectTypesList = [
    this.configuration.OBJECT_TYPES.expense,
    this.configuration.OBJECT_TYPES.program,
    this.configuration.OBJECT_TYPES.childCampaign,
    this.configuration.OBJECT_TYPES.campaign,
    this.configuration.OBJECT_TYPES.goal
  ];

  private objectGetterByType = {
    [this.configuration.OBJECT_TYPES.program]: (list, id: number) => this.getHierarchyItem(list.programs, id),
    [this.configuration.OBJECT_TYPES.campaign]: (list, id: number) => this.getHierarchyItem(list.campaigns, id),
    [this.configuration.OBJECT_TYPES.childCampaign]: (list, id: number) => this.getHierarchyItem(list.campaigns, id),
    [this.configuration.OBJECT_TYPES.goal]: (list, id: number) => this.getHierarchyItem(list.goals, id),
    [this.configuration.OBJECT_TYPES.sharedCostRule]: (list, id: number) => this.getHierarchyItem(list.sharedCostRules, id),
    [this.configuration.OBJECT_TYPES.segmentsGroup]: (list, id: number) => this.getHierarchyItem(list.segmentGroups, id),
    [this.configuration.OBJECT_TYPES.segment]: (list, id: number) => this.getHierarchyItem(list.segments, id),
  };
  private OBJECT_TYPES = this.configuration.OBJECT_TYPES;

  private getEmptyHierarchy(): ObjectHierarchy {
    return {
      Goal: null,
      Campaign: null,
      ChildCampaign: null,
      Program: null,
      Expense: null,
      Metric: null
    };
  }

  private getHierarchyItem(list: CommonObject[], id: number) {
    if (!Array.isArray(list)) {
      return null;
    }
    const item = list.find(listItem => listItem.id === id);
    return item ? item : null;
  }

  private buildHierarchy(
    targetObject: HierarchyTargetObject,
    targetType: string,
    objects: HierarchyObjects,
    parentObject?: BudgetObjectParent
  ): ObjectHierarchy {
    if (!targetObject || !targetType) {
      return;
    }

    const hierarchy = this.getEmptyHierarchy();
    hierarchy[targetType] = {
      id: targetObject.id,
      name: targetObject.name,
      mode: targetObject?.mode,
      isClosed: targetObject?.mode === ObjectMode.Closed,
      isActive: false
    };

    const currentTypeIndex = this.objectTypesList.indexOf(targetType);
    if (currentTypeIndex === -1) {
      return hierarchy;
    }

    let currentParentInfo = parentObject || this.getObjectParentInfo(targetObject, currentTypeIndex);

    this.objectTypesList
      .filter((type, index) => index > currentTypeIndex)
      .forEach(type => {
        if (!currentParentInfo || currentParentInfo.type !== type) {
          return;
        }

        const objectGetter = this.objectGetterByType[type];
        if (!objectGetter) {
          return;
        }

        const dataObject = objectGetter(objects, currentParentInfo.id);
        if (!dataObject) {
          return;
        }

        hierarchy[type] = {
          id: dataObject.id,
          name: dataObject.name,
          isClosed: dataObject.mode === ObjectMode.Closed,
          isActive: true
        };
        currentParentInfo = this.getObjectParentInfo(dataObject, this.objectTypesList.indexOf(type));
      });

    return hierarchy;
  }

  private getObjectParentInfo(obj: HierarchyTargetObject, objectTypeIndex: number): BudgetObjectParent {
    const { OBJECT_TYPES } = this.configuration;
    const parents = this.objectTypesList
      .filter((type, index) => index > objectTypeIndex)
      .map(parentType => {
        let parentObjId = obj[parentType.toLowerCase() + 'Id'];
        if (parentType === OBJECT_TYPES.childCampaign) {
          parentObjId = obj.campaignId;
        }
        if (!parentObjId && parentType === OBJECT_TYPES.campaign) {
          parentObjId = obj.parentCampaign;
        }

        return parentObjId && { id: parentObjId, type: parentType };
      })
      .filter(info => info != null);
    return parents[0];
  }

  public buildChildHierarchy(
    targetObject: HierarchyTargetObject,
    targetType: string,
    objects: { campaigns: LightCampaign[], programs: LightProgram[] }
  ): HierarchySelectItem[] {
    const { OBJECT_TYPES } = this.configuration;
    const objectCore = (type: string, item: CommonObject, level: number): HierarchySelectItem => {
      return {
        id: `${type}_${item.id}`,
        title: item.name,
        objectId: item.id,
        objectType: type,
        level,
        isClosed: item?.mode === ObjectMode.Closed,
      }
    }
    const buildHierarchy = (
      currentLevelType: string,
      item: CommonObject,
      campaigns: LightCampaign[],
      programs?: LightProgram[]
    ): HierarchySelectItem[] => {
      let children = [];
      /**
       * For Goal level we directly have child campaign(s) and program(s) in targetObject.
       * We should check does this child campaign(s) has it own child campaign(s) and program(s)
       */
      if (targetType === OBJECT_TYPES.goal && currentLevelType === OBJECT_TYPES.campaign) {
        children = campaigns
          .filter(campaign => campaign.parentCampaign === item.id)
          .map(_item => ({
            ...objectCore(currentLevelType, _item, 1),
            children: convertPlanObjectToHierarchyItem(
              programs.filter(campaign => campaign.campaignId === _item.id),
              OBJECT_TYPES.program,
              2
            )
          }));
      }
      /**
       * On Campaign level we just check child program(s)
       */
      if (targetType === OBJECT_TYPES.campaign) {
        children = programs
          .filter(campaign => campaign.campaignId === item.id)
          .map(_item => ({
            ...objectCore(OBJECT_TYPES.program, _item, 1),
            children: []
          }));
      }

      return children;
    };

    const convertPlanObjectToHierarchyItem = (_object: CommonObject[], type: string, level: number): HierarchySelectItem[] => {
      return _object.map(item => {
        return {
          ...objectCore(type, item, level),
          children: buildHierarchy(type, item, objects.campaigns, objects.programs)
        }
      })
    };

    const childrenHierarchy: HierarchySelectItem[] = [
      ...convertPlanObjectToHierarchyItem(targetObject.campaigns, OBJECT_TYPES.campaign, 0),
      ...convertPlanObjectToHierarchyItem(targetObject.programs, OBJECT_TYPES.program, 0)
    ];

    return childrenHierarchy;
  }

  public buildMetricHierarchy(
    metricId: number,
    metricName: string,
    targetObject: HierarchyTargetObject,
    parentType: string,
    objects: {
      goals?: CommonObject[],
      campaigns?: CommonObject[],
      programs?: CommonObject[],
    }
  ) {
    const hierarchy = this.buildHierarchy(
      targetObject,
      parentType,
      objects
    );

    const objectHierachy = [hierarchy.Campaign, hierarchy.ChildCampaign, hierarchy.Goal];

    if (!hierarchy) {
      return this.getEmptyHierarchy();
    }

    hierarchy.Metric = {
      id: metricId,
      isActive: false,
      name: metricName
    };

    objectHierachy
      .filter(object => !!object)
      .forEach(object => object.isActive = true);

    return hierarchy;
  }

  public buildObjectHierarchy(
    targetObject: HierarchyTargetObject,
    objectType: string,
    objects: {
      sharedCostRules?: SharedCostRule[],
      segmentGroups?: SegmentGroup[],
      segments?: BudgetSegmentAccess[],
      goals?: CommonObject[],
      campaigns?: CommonObject[],
      programs?: CommonObject[],
    },
    parentObject?: BudgetObjectParent
  ): ObjectHierarchy {
    let hierarchy = this.buildHierarchy(
      targetObject,
      objectType,
      objects,
      parentObject
    );

    if (!hierarchy) {
      hierarchy = this.getEmptyHierarchy();
    }

    const segmentTypes = {
      segmentGroupId: this.configuration.OBJECT_TYPES.segmentsGroup,
      segmentId: this.configuration.OBJECT_TYPES.segment,
      sharedCostRuleId: this.configuration.OBJECT_TYPES.sharedCostRule,
    };
    Object.entries(segmentTypes).forEach(([key, objType]) => {
      const objId = targetObject[key];
      if (objId) {
        const targetObg = this.objectGetterByType[objType](objects, objId);
        if (targetObg) {
          hierarchy[objType] = {
            id: targetObg.id,
            name: targetObg.name,
          }
        }
      }
    })

    const activeItem = hierarchy[objectType];

    if (activeItem && targetObject.id !== activeItem.id) {
      activeItem.isActive = true;
    }

    return hierarchy;
  }

  public getParentFromHierarchy(hierarchy: ObjectHierarchy, objectType: string): BudgetObjectParent {
    const currentTypeIndex = this.objectTypesList.indexOf(objectType);
    const { OBJECT_TYPES } = this.configuration;
    let parent: BudgetObjectParent = null;

    this.objectTypesList
      .filter((type, index) => index > currentTypeIndex)
      .forEach(type => {
        const hierarchyItem: ObjectHierarchyItem = hierarchy[type];
        const parentType = type === OBJECT_TYPES.childCampaign ? OBJECT_TYPES.campaign : type;

        if (hierarchyItem && parent == null) {
          parent = { id: hierarchyItem.id, type: parentType };
        }
      });

    return parent;
  }

  public setHierarchyObjectName(hierarchy: ObjectHierarchy, objectType: string, objectName: string) {
    if (hierarchy[objectType]) {
      hierarchy[objectType].name = objectName;
    }
  }

  public createMenuHierarchy(items: ObjectHierarchy): HierarchyItem[] {
    const { segmentsGroup, segment, sharedCostRule, goal, campaign, childCampaign, program, expense } = this.OBJECT_TYPES;
    const orderedTypes = [segmentsGroup, segment, sharedCostRule, goal, campaign, childCampaign, program, expense];

    return orderedTypes.reduce((hierarchy, rawType) => {
      const objectData = items?.[rawType];
      if (objectData) {
        const objectType = rawType === this.OBJECT_TYPES.childCampaign ? this.OBJECT_TYPES.campaign : rawType;
        const hierarchyItem = {
          id: objectData.id,
          name: objectData.name,
          objectType,
          onClick: objectData.isActive ? this.routingService.routeActionByObjectType[objectType] : null,
          flipIcon: objectType === this.OBJECT_TYPES.goal,
          isClosed: objectData.isClosed,
        }
        hierarchy.push(hierarchyItem);
      }
      return hierarchy;
    }, [])
  }

  public createMetricDrawerMenuHierarchy(items: HierarchyItem[], isChildHierarchy = false): HierarchyItem[] {
    return items.map((item, i) => {
      if(!isChildHierarchy && i >= items.length - 2)  {
        return { ...item, onClick: null }
      }

      let mappingType = this.OBJECT_TYPES.campaign
      if(i > 0){
       mappingType = items[i-1].objectType
      }
      return {
        ...item,
        onClick: item.objectType === this.OBJECT_TYPES.metric ? this.routingService.metricDetailsByObjectType[mappingType] : this.routingService.routeActionByObjectType[item.objectType]
      }
    }) as HierarchyItem[];
  }
}
