import { Pipe, PipeTransform } from '@angular/core';
import { TeamMember } from './team-page.type';
import { BudgetPermission } from 'app/shared/types/user-permission.type';

@Pipe({
  name: 'teamFilter'
})
export class TeamFilterPipe implements PipeTransform {
  transform(teamMembers: TeamMember[], filterText: string): TeamMember[] {
    if (!teamMembers) {
      return [];
    }
    if (!filterText) {
      return teamMembers;
    }

    return teamMembers.filter(user =>
      !user.companyUserId || this.teamMemberContainsFilterTerm(user, filterText)
    );
  }

  private teamMemberContainsFilterTerm(teamMember: TeamMember, filterText): boolean {
    filterText = filterText.toLocaleLowerCase();
    const hasFilterTerm = this.userProfileContainsFilterTerm(teamMember, filterText);
    if (!hasFilterTerm && !teamMember.isAdmin) {
      return this.budgetsOrSegmentsContainFilterTerm(teamMember, filterText);
    }
    return hasFilterTerm;
  }

  private budgetsOrSegmentsContainFilterTerm(teamMember: TeamMember, filterText): boolean {
    const budgetPermissions = teamMember.permissionsForm.get('permissions').value as BudgetPermission[];
    return budgetPermissions.some(
      permission => {
        let matched = permission.budget && permission.budget.name.toLowerCase().includes(filterText);
        if (!matched) {
          matched = permission.segments && permission.segments.some(
            segment => segment.name.toLowerCase().includes(filterText)
          )
        }
        return matched;
      }
    )
  }

  private userProfileContainsFilterTerm(teamMember: TeamMember, filterTerm: string): boolean {
    const userData = { ...teamMember, ...teamMember.profileForm.value }
    const includesFields = ['firstName', 'lastName', 'email', 'sso'];
    return includesFields.some(fieldName => {
      const fieldValue = userData[fieldName] as string;
      return fieldValue && fieldValue.toLowerCase().includes(filterTerm)
    })
  }
}
