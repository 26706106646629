import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ChildObjectsData, ChildObjectsRowData } from './child-objects.type';
import { Configuration } from 'app/app.constants';
import { SharedCostRule, SharedCostRuleSegment } from 'app/shared/types/shared-cost-rule.interface';
import { SharedRuleTooltipData } from 'app/shared/components/shared-rule-tooltip/shared-rule-tooltip.type';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedIconState } from 'app/shared/components/icons/icon-shared/shared.type';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { faRocketLaunch, faBriefcase } from '@fortawesome/pro-duotone-svg-icons';
import { ChildObjectsService, ObjectBusinessValue } from './child-objects.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';

const TOOLTIP_OFFSETS = {
  x: 10,
  y: -35
};
const TOOLTIP_WIDTH = 200;

@Component({
  selector: 'child-objects',
  templateUrl: './child-objects.component.html',
  styleUrls: ['./child-objects.component.scss']
})
export class ChildObjectsComponent implements OnChanges {
  data: ChildObjectsData;
  @Input() objects: any[];
  @Input() users: any[];
  @Input() objectType: string;
  @Input() currency: string;
  @Input() company: CompanyDO;
  @Input() showSharedCostRules = true;
  @Input() segmentList: BudgetSegmentAccess[];
  @Input() sharedCostRules: SharedCostRule[];
  @Input() isReadOnlyMode = false;
  @Input() segmentNameById: {[key: number]: string};
  @Input() showSegment = true;
  @Input() showBusinessValue = false;
  @Input() products: ProductDO[] = [];
  @Input() metricTypes: MetricType[] = [];
  @Output() addNewObject = new EventEmitter();
  @Output() updateTotalBusinessValue = new EventEmitter<number>();

  decimalPipeFormat = '1.0-0';
  isTooltipShown = false;
  tooltipData: SharedRuleTooltipData = {
    id: null,
    name: '',
    segments: [],
    coords: {}
  };
  SharedIconState = SharedIconState;
  titleByType = {
    [this.configuration.OBJECT_TYPES.childCampaign]: 'Child Campaigns',
    [this.configuration.OBJECT_TYPES.campaign]: 'Campaigns',
    [this.configuration.OBJECT_TYPES.program]: 'Expense Groups',
  };
  addBtnTitleByType = {
    [this.configuration.OBJECT_TYPES.childCampaign]: 'Add Child Campaign',
    [this.configuration.OBJECT_TYPES.campaign]: 'Add Campaign',
    [this.configuration.OBJECT_TYPES.program]: 'Add Exp. Group',
  };
  rowClickActionByType = {
    [this.configuration.OBJECT_TYPES.childCampaign]: this.appRoutingService.openCampaignDetails.bind(this.appRoutingService),
    [this.configuration.OBJECT_TYPES.campaign]: this.appRoutingService.openCampaignDetails.bind(this.appRoutingService),
    [this.configuration.OBJECT_TYPES.program]: this.appRoutingService.openProgramDetails.bind(this.appRoutingService),
  };
  zeroMessageByType = {
    [this.configuration.OBJECT_TYPES.childCampaign]: 'No Child Campaigns',
    [this.configuration.OBJECT_TYPES.campaign]: 'No Campaigns',
    [this.configuration.OBJECT_TYPES.program]: 'No Expense Groups',
  };
  iconByType = {
    [this.configuration.OBJECT_TYPES.childCampaign]: faRocketLaunch,
    [this.configuration.OBJECT_TYPES.campaign]: faRocketLaunch,
    [this.configuration.OBJECT_TYPES.program]: faBriefcase,
  };

  constructor(
    private configuration: Configuration,
    private appRoutingService: AppRoutingService,
    private childObjectsService: ChildObjectsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.objects || changes.users) {
      this.updateTableData();
      if (changes.objects && this.showBusinessValue) {
        this.childObjectsService.defineBusinessValues(
          this.company,
          this.products,
          this.metricTypes,
          this.objects,
          (bValues: ObjectBusinessValue[]) => this.applyBusinessValues(bValues));
      }
    }
  }

  updateTableData() {
    if (this.objects && this.users) {
      this.data = this.childObjectsService.createTableData(
        this.objects,
        this.users,
        this.sharedCostRules,
        this.segmentList,
        this.segmentNameById
      );
    }
  }

  showTooltip(e, sharedCostRule: SharedCostRule) {
    if (!sharedCostRule) {
      return;
    }

    this.isTooltipShown = true;
    this.prepareCoordsForTooltip(e);
    this.prepareDataForTooltip(sharedCostRule);
  }

  hideTooltip() {
    this.isTooltipShown = false;
  }

  prepareCoordsForTooltip(e: any) {
    const coords = e.target.getBoundingClientRect();
    const { x, y } = coords;
    this.tooltipData.coords.top = y + TOOLTIP_OFFSETS.y;
    this.tooltipData.coords.left = x - TOOLTIP_WIDTH - TOOLTIP_OFFSETS.x;
  }

  prepareDataForTooltip(rule: SharedCostRule) {
    if (!this.data) {
      return;
    }

    this.tooltipData.id = rule.id;
    this.tooltipData.name = rule.name;
    this.tooltipData.segments = rule.segments.map((segment: SharedCostRuleSegment) => {
      const { id, cost } = segment;
      const budgetSegment: BudgetSegmentAccess = this.segmentList.find((s: BudgetSegmentAccess) => s.id === id);
      const name = this.segmentNameById ? this.segmentNameById[id] : budgetSegment.name;
      return { id, cost, name };
    });
  }

  navigateToCreationPage = () => {
    this.addNewObject.emit();
  };

  navigateToDetailsPage(rowData: ChildObjectsRowData) {
    if (rowData.isProhibited) {
      return;
    }

    const navigateToDetailsPage = this.rowClickActionByType[this.objectType];
    if (navigateToDetailsPage) {
      navigateToDetailsPage(rowData.id);
    }
  }

  private applyBusinessValues(bValues: ObjectBusinessValue[]) {
    let totalBusinessValue = 0;
    this.data.rows.forEach(row => {
      const bValue = bValues.find(bv => bv.objId === row.id);
      row.businessValue = bValue?.value;
      totalBusinessValue += (bValue?.value || 0);
    });
    this.updateTotalBusinessValue.emit(totalBusinessValue);
  }
}
