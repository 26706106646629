import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteProductDialogContext } from 'app/shared/types/dialog-context.interface';
import { DELETE_OBJECT } from '../../metric-funnel.constants';

@Component({
  selector: 'delete-metric-modal',
  templateUrl: './delete-metric-modal.component.html',
  styleUrls: ['./delete-metric-modal.component.scss']
})
export class DeleteMetricModalComponent {

  public context: DeleteProductDialogContext;
  public isVerify = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteMetricModalComponent>,
    @Inject(MAT_DIALOG_DATA) context: DeleteProductDialogContext
  ) {
    this.context = context;
  }

  verify(value: string): void {
    this.context.submitAction.disabled = value !== DELETE_OBJECT.CHECK_WORD;
  }
}
