import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'campaign-summary-card',
  styleUrls: ['./campaign-summary-card.component.scss'],
  templateUrl: './campaign-summary-card.component.html'
})
export class CampaignSummaryCardComponent implements OnChanges {
  @Input() value: number;
  @Input() label: string;
  @Input() action: Function;

  public isClickable = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.isClickable = this.value > 0;
    }
  }
}
