import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuPanelItem } from 'app/header-navigation/components/menu-panel/menu-panel.type';
import { SpendingManageMenuService } from '@spending/services/spending-manage-menu.service';
import { ExpenseActionsService } from '@spending/services/expense-actions.service';
import { SpendingService } from '@spending/services/spending.service';
import { ExpenseAction } from '@spending/types/expense-action.type';

@Component({
  selector: 'spending-manage-menu',
  templateUrl: './spending-manage-menu.component.html',
  styleUrls: ['./spending-manage-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpendingManageMenuComponent {
  @Input() disabled: boolean;
  @Input() set editPermission(canEdit: boolean) {
    this.spendingManageMenuService.onEditPermissionChanged(canEdit);
  }
  @Input() set hasSelectedExpenses(selected: boolean) {
    this.spendingManageMenuService.updateActionsItemState(selected);
  }

  constructor(
    private readonly spendingService: SpendingService,
    private readonly spendingManageMenuService: SpendingManageMenuService,
    private readonly expenseActionsService: ExpenseActionsService
  ) { }

  public get menuItems(): MenuPanelItem[] {
    return this.spendingManageMenuService.getMenuItems();
  }

  public get actionItems(): ExpenseAction[] {
    return this.expenseActionsService.getActions();
  }

  public updateData(): void {
    this.spendingService.updateInvoiceCount();
    this.spendingService.refreshTableDataAndTotals();
  }
}
