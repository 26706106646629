import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { DetailsAction, DetailsActionType } from '../details-header.type';
import { Budget } from 'app/shared/types/budget.interface';
import { ObjectHierarchy } from '../../object-hierarchy-nav/object-hierarchy-nav.type';
import { ObjectHierarchyService } from '../../../services/object-hierarchy.service';
import { HierarchyItem } from '../../object-hierarchy-menu/object-hierarchy-menu.component';
import { HierarchySelectConfig, HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { Configuration } from 'app/app.constants';

@Component({
  selector: 'manage-object-actions',
  templateUrl: './manage-object-actions.component.html',
  styleUrls: ['./manage-object-actions.component.scss']
})
export class ManageObjectActionsComponent {
  private readonly objectHierarchyService = inject(ObjectHierarchyService);
  private readonly configuration = inject(Configuration);
  public OBJECT_TYPES = this.configuration.OBJECT_TYPES;

  public hierarchyItems: HierarchyItem[];
  public childMetricHierarchyItems: HierarchyItem[];
  public childHierarchyItems: HierarchySelectItem[];
  public ACTION_TYPE = DetailsActionType;
  public regularMenuItems = [
    this.ACTION_TYPE.CLONE,
    this.ACTION_TYPE.DELETE,
    this.ACTION_TYPE.UPDATE_STATUS
  ];
  public selectConfig: HierarchySelectConfig = {
    withSearch: true,
    allGroups: false,
    searchPlaceholder: 'Search children',
  };
  allowedBudgetList: Budget[] = [];

  @Input() actions: DetailsAction[] = [];
  @Input() objectDisplayType = 'Object';
  @Input() budgetList: Budget[] = [];
  @Input() isDrawer = false;
  @Input() isCEGBudget = false;
  @Input() childHierarchy: HierarchySelectItem[] = [];
  @Input() isMetricDrawer = false;

  @Output() budgetToMoveSelected = new EventEmitter<Budget>();
  @Output() onChildHierarchyItemClick = new EventEmitter<{objectId: number, objectType: string}>();
  @Input() set parentHierarchy(items: ObjectHierarchy) {
    if(this.isMetricDrawer){
      this.hierarchyItems = this.objectHierarchyService.createMetricDrawerMenuHierarchy(items as unknown as HierarchyItem[]);
    }else {
      this.hierarchyItems = this.objectHierarchyService.createMenuHierarchy(items);
    }
  }

  @Input() set childMetricHierarchy(items: ObjectHierarchy) {
    if(this.isMetricDrawer) {
      this.childMetricHierarchyItems = this.objectHierarchyService.createMetricDrawerMenuHierarchy(items as unknown as HierarchyItem[], true);
    }
  }

  private allowedToMoveBudgetList(): Budget[] {
    const predicateFn = (budget: Budget) => this.isCEGBudget ? budget.new_campaigns_programs_structure : !budget.new_campaigns_programs_structure;
    const allowedBudgetList = this.budgetList.filter(predicateFn);
    return allowedBudgetList;
  }

  onHandleHierarchyClick(event: Partial<HierarchySelectItem>): void {
    this.onChildHierarchyItemClick.emit({objectId: event.objectId, objectType: event.objectType})
  }

  ngOnInit() {
    this.allowedBudgetList = this.allowedToMoveBudgetList();
  }
  
}
