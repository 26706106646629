export interface Attachment {
  goal?: number;
  campaign?: number;
  program?: number;
  expense?: number;
  crd: string;
  id: number;
  key: string;
  filename: string;
  name?: string;
  ext?: string;
  eTag?: string;
  size?: number;
  type?: string;
  // Scanning results are not ready yet
  isUnderReview?: boolean;
  // File is being downloaded/deleted
  isProcessing?: boolean;
}

export interface AttachmentMappingDO {
  goal?: number;
  campaign?: number;
  program?: number;
  expense?: number;
  crd: string;
  id: number;
  key: string;
}

export interface AttachmentMetaData {
  type?: string;
  filename?: string;
}

export enum AttachmentScanStatus {
  MalwareDetected = 'MALWARE_DETECTED',
  SafeScanning = 'SAFE_SCANNING',
  ScanningFailed = 'SCANNING_FAILED'
}

export interface AttachmentLambda {
  companyId: number;
  eTag: string;
  key: string;
  meta: AttachmentMetaData;
  size: number;
  scanStatus?: AttachmentScanStatus;
  scanningStarted?: number;
}

export interface AttachmentsObjectContext {
  objectId: number;
  objectType: string;
  companyId: number;
}
