<div class="alt-view" *ngIf="congratulationEnabled; else regularView">
  <span class="icon">🎉</span>
  <h2>Well done!</h2>
  <p>You have located a home for all the invoices.<br>
    Now you can check them out at the <a (click)="changeMode.emit()">expense page</a><br>
    or have some tea with cookies</p>

  <svg class="background" width="487" height="347" viewBox="0 0 487 347" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.3" d="M243.046 62.7804C207.275 88.5279 184.918 99 138.418 99C100.418 99 80.418 84 33.5588 112.024C-14.7331 140.904 -2.14253 211.671 17.2238 259.594C28.4087 287.272 49.8131 310.203 71.4038 323.794C118.387 353.368 180.758 348.442 231.076 342.489C275.926 337.182 319.788 325.371 361.756 304.462C384.759 293.002 408.32 280.888 429.091 263.572C485.65 216.423 502.671 157.185 469.742 81.5549C451.772 40.2835 421.707 14.5359 385.698 4.54763C321.335 -13.3053 296.096 24.5967 243.046 62.7804Z" fill="#DEE6F1"/>
  </svg>
</div>

<ng-template #regularView>
  <div class="regular-view">
    <p>Easily import your invoices</p>
    <invoice-upload-btn type="flat" [disabled]="uploadDisabled"></invoice-upload-btn>
    <p>or email them as attachments {{ !!budgiEmail ? 'to' : '...' }}</p>
    <a *ngIf="budgiEmail; else noEmail" href="mailto:{{ budgiEmail }}">{{ budgiEmail }}</a>
    <ng-template #noEmail>ask your customer success manager for your dedicated email address</ng-template>
  </div>
</ng-template>


