import { Component, OnDestroy, OnInit, AfterViewChecked, inject } from '@angular/core';
import { AppDataLoader } from 'app/app-data-loader.service';
import { ManagePageService } from '../../services/manage-page.service';
import { ManagePageModeService } from '../../services/manage-page-mode.service';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { PlanPageViewMode } from 'app/shared/enums/plan-page-view-mode.enum';
import { CARD_TABLE_VIEW_MODE } from 'app/shared/constants/modes.constant';
import { ManageTableDataService } from '../../services/manage-table-data.service';
import { ManageTableRecordInteractionsService } from '../../services/manage-table-record-interactions.service';
import { ManageTableDataMutationService } from '../../services/manage-table-data-mutation.service';
import { ManagePageActionsMenuService } from '../../services/manage-page-actions-menu.service';
import { ManageTableDataValidationService } from '../../services/manage-table-data-validation.service';
import { LAST_CREATED_OBJECT_ID } from 'app/shared/constants/storage.constants';
import { ManageTableDataMode } from '../../types/manage-table-data-mode.type';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { ManageTableActionHistoryService } from '@shared/services/manage-table-action-history.service';
import { ManagePageHistoryModeService } from '../../services/manage-page-history-mode.service';
import { ManagePageExportService } from '../../services/manage-page-export.service';
import { ManagePageTagsService } from '../../services/manage-page-tags.service';
import { ManagePageExpensesService } from '../../services/manage-page-expenses.service';
import { ManageTableDataLoader } from '../../services/manage-table-data-loader/manage-table-data-loader';
import { ManageTableRowDataManager } from '../../services/manage-table-row-data-manager/manage-table-row-data-manager.service';
import { ExpenseCostAdjustmentDataService } from 'app/metric-integrations/expense-cost-adjustment/expense-cost-adjustment-data.service';
import { MiniDashCalculationService } from 'app/manage-table/services/mini-dash-calculation.service';


@Component({
  selector: 'manage-page',
  templateUrl: './manage-page.component.html',
  styleUrls: ['./manage-page.component.scss'],
  providers: [
    AppDataLoader,
    ManagePageService,
    ManagePageModeService,
    ManageTableDataService,
    ManagePageExportService,
    ManageTableRecordInteractionsService,
    ManageTableDataMutationService,
    ManagePageActionsMenuService,
    ManageTableDataValidationService,
    BudgetAllocationActionsService,
    ManageTableActionHistoryService,
    ManagePageHistoryModeService,
    ManagePageTagsService,
    ExpenseCostAdjustmentDataService,
    MiniDashCalculationService,
    ManagePageExpensesService,
    ManageTableRowDataManager,
    ManageTableDataLoader
  ]
})
export class ManagePageComponent implements OnInit, OnDestroy, AfterViewChecked {
  private readonly appDataLoader = inject(AppDataLoader);
  private readonly managePageService = inject(ManagePageService);
  private readonly modeService = inject(ManagePageModeService);

  public get dataModeOptions() {
    return this.modeService.dataModeOptions;
  }

  public get dataMode() {
    return this.modeService.dataMode;
  }

  public get budget() {
    return this.managePageService.budget;
  }

  public get company() {
    return this.managePageService.company;
  }

  public get timeframes() {
    return this.managePageService.timeframes;
  }

  public get filteredTimeframes() {
    return this.managePageService.filteredTimeframes;
  }

  public get budgetCurrency() {
    return this.managePageService.budgetCurrency;
  }

  ngOnInit(): void {
    this.appDataLoader.init(false);
    LocalStorageService.addToStorage(CARD_TABLE_VIEW_MODE, PlanPageViewMode.TABLE);
  }

  ngAfterViewChecked(): void {
    this.managePageService.showRestoredActiveRow();
  }

  ngOnDestroy(): void {
    LocalStorageService.removeFromStorage(LAST_CREATED_OBJECT_ID);
  }

  public handleDataModeChange(dataMode: string) {
    this.modeService.setDataMode(dataMode as ManageTableDataMode);
  }
}
