import { Component } from '@angular/core';

@Component({
  selector: 'details-metrics-hint',
  templateUrl: './details-metrics-hint.component.html',
  styleUrls: ['./details-metrics-hint.component.scss']
})
export class DetailsMetricsHintComponent {

}
