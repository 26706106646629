import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WidgetConfig, WidgetState, WidgetType } from '../../types/widget.interface';
import { WidgetConfigService } from '../../services/widget-config.service';
import { WidgetStateService } from '../../services/widget-state.service';

@Component({
  selector: 'plan-highlights',
  styleUrls: ['./plan-highlights.component.scss'],
  templateUrl: './plan-highlights.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanHighlightsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  public displayTitle = false;
  public title = 'Summary plan highlights';
  public widgets: WidgetConfig[] = [
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.ESTIMATED_BUSINESS_VALUE],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.BUDGET_MINUS_EXPENSES],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.BUDGET_SPENT],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.SPEND_PROGRESS],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.CAMPAIGN_SPENT],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.METRICS_PROGRESS],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.CAMPAIGNS],
  ];

  constructor(
    private readonly widgetConfig: WidgetConfigService,
    private readonly widgetStateService: WidgetStateService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.widgetStateService.stateChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        const { values } = data;
        const notAvailableStates = [WidgetState.EMPTY, WidgetState.HIDDEN];
        const noTasksAndExpensesAvailable = (
          notAvailableStates.includes(values[WidgetType.OPEN_TASKS]) &&
          notAvailableStates.includes(values[WidgetType.OVERDUE_EXPENSES])
        );
        this.displayTitle = !noTasksAndExpensesAvailable;
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}


