import { Component } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-plannuh',
  templateUrl: './icon-plannuh.component.html'
})

/**
* @class - IconPlannuhComponent
*/
export class IconPlannuhComponent extends IconBaseComponent {
  constructor() {
    super();
  }
}
