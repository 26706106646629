import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BulkUpdateDialogContext, BulkUpdateDialogType } from 'app/shared/types/dialog-context.interface';
import { TagMapping } from 'app/shared/types/tag-mapping.interface';
import { TagsControlComponent } from '@shared/components/tags-control/tags-control.component';

@Component({
  selector: 'bulk-update-dialog',
  templateUrl: './bulk-update-dialog.component.html',
  styleUrls: ['./bulk-update-dialog.component.scss']
})
export class BulkUpdateDialogComponent implements OnDestroy {
  @ViewChild('tagsControl') tagsControl: TagsControlComponent;

  private readonly destroy$ = new Subject<void>();
  public DIALOG_TYPE = BulkUpdateDialogType;
  public context: BulkUpdateDialogContext;
  public value;
  public formData;
  public isLoading = false;
  public tagMappings: TagMapping[] = [];

  constructor(
    public dialogRef: MatDialogRef<BulkUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) context: BulkUpdateDialogContext,
    private readonly fb: UntypedFormBuilder,
  ) {
    const formData = { text: '' };
    this.formData = this.fb.group(formData);
    this.context = context;
    this.tagMappings = this.context.data?.tagMappingsContainer;

    if (this.context.data?.tagMappings$) {
      this.isLoading = true;
      this.context.data?.tagMappings$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(
          data => {
            this.isLoading = false;
            this.tagMappings.push(...data);
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public callHandler(handler: Function): void {
    if (this.context.type === BulkUpdateDialogType.tags) {
      this.checkInputValue();
    }

    let value = this.value;

    switch (this.context.type) {
      case BulkUpdateDialogType.text:
        value = this.formData.get('text').value;
        break;
      case BulkUpdateDialogType.tags:
      case BulkUpdateDialogType.tagsRemoval:
        value = null;
        break;
    }

    if (handler) {
      handler(value, this.context);
    }
    this.dialogRef.close();
  }

  public handleAutocompleteChange($event) {
    this.value = $event;
  }

  private checkInputValue(): void {
    const contextData = this.context?.data;
    const inputValue = this.tagsControl.tagCtrl.value;
    const tagName = (inputValue || '').trim();

    if (tagName && tagName.length < 40) {
      const isApplied = this.tagsControl.tagsList.some(tag => tag.name === tagName);
      if (isApplied) {
        return;
      }

      const existingTag = contextData?.autocompleteItems.find(tag => tag.name === tagName);
      if (existingTag) {
        contextData?.onAddTag({ id: existingTag.id, title: existingTag.name, inherit: true });
      } else {
        contextData?.onCreateTag({ id: null, title: tagName, inherit: true });
      }
    }
  }
}
