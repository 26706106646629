import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { Validations } from 'app/app.validations';
import { UtilityService } from 'app/shared/services/utility.service';
import { GoogleTagManagerService } from 'app/shared/services/google-tag-manager.service';
import { ActionMenuItem } from 'app/shared/components/actions-menu/actions-menu.component';
import { CompareService } from 'app/shared/services/compare.service';
import { TagManagerFactory } from 'app/shared/services/tag-manager-factory.service';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetDataService } from './budget-data/budget-data.service';
import { FilterName, FilterSet, ObjectLocalFilter } from 'app/header-navigation/components/filters/filters.interface';
import { FilterManagementService, ParamsDef } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { Goal, PlanGoalDO } from 'app/shared/types/goal.interface';

import { UserDataService } from 'app/shared/services/user-data.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BehaviorSubject, combineLatest, concat, Observable, of, Subject, Subscription } from 'rxjs';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { ProgramService } from 'app/shared/services/backend/program.service';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { SharedCostRulesService } from 'app/shared/services/backend/shared-cost-rules.service';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { catchError, filter, finalize, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SharedIconState } from 'app/shared/components/icons/icon-shared/shared.type';
import { IsSharedSubObjectPipe } from './is-shared-subobject.pipe';
import { BudgetObjectSegmentData } from 'app/shared/types/budget-object-segment-data.interface';
import { UserManager } from 'app/user/services/user-manager.service';
import { BudgetAmountsSummaryService } from 'app/shared/services/budget-amounts-summary.service';
import { BudgetAmountsSummaryComponent } from './budget-amounts-summary/budget-amounts-summary.component';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { UserService } from 'app/shared/services/backend/user.service';
import { SegmentDataInheritanceService } from 'app/shared/services/segment-data-inheritance.service';
import { SegmentDataInheritanceAction } from 'app/shared/types/segment-data-inheritance.interface';
import { AppDataLoader } from 'app/app-data-loader.service';
import { PlanCampaign, PlanGoal, PlanProgram, ViewSectionName } from './dashboard.types';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';
import { PlanDetailData } from 'app/shared/types/budget-plan.type';
import { ObjectLocalFilterGroup } from 'app/header-navigation/components/filters/object-local-filter/object-local-filter-group';
import { PlanObjectByStatusFilter } from 'app/header-navigation/components/filters/object-local-filter/filter-by-status';
import { LightCampaign, PlanCampaignDO } from 'app/shared/types/campaign.interface';
import { HierarchySelectConfig, HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { ExternalIntegrationObjectTypes } from 'app/shared/constants/external-integration-object-types';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { PlanPageViewMode } from 'app/shared/enums/plan-page-view-mode.enum';
import { CARD_TABLE_VIEW_MODE } from 'app/shared/constants/modes.constant';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { SegmentMenuHierarchyService } from 'app/shared/services/segment-menu-hierarchy.service';
import { ManageTableViewMode } from 'app/manage-table/types/manage-table-view-mode.type';
import { DragAndDropService } from 'app/shared/services/drag-and-drop.service';
import { ManageTableDataMode } from 'app/manage-table/types/manage-table-data-mode.type';
import { HttpStatusCode } from '@angular/common/http';
import { GoalsService } from 'app/shared/services/backend/goals.service';
import { PlanObjectDO } from 'app/shared/types/plan.type';
import { PlanCampaignsBuilder } from './plan-object-builder/plan-campaigns-builder';
import { TagMappingsPipe } from 'app/shared/pipes/tag-mappings.pipe';
import { PlanProgramsBuilder } from './plan-object-builder/plan-programs-builder';
import { LightProgram, PlanProgramDO } from 'app/shared/types/program.interface';
import { PlanObjectsBuilder } from './plan-object-builder/plan-objects-builder';
import { LoadingState } from 'app/shared/types/loading-state.type';
import { BudgetSummaryBarHelpers } from '@shared/components/budget-summary-bar/budget-summary-bar.helpers';
import { SummaryBarCalculationItemList, SummaryBarItem } from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { CompanyService } from '@shared/services/backend/company.service';
import { ExpenseMiniDashTotalsDO } from '@shared/types/expense.interface';
import { getObjectTypeKey } from '@shared/utils/budget.utils';

const SORT_BY_INVESTMENT = 'sortByInvestment';
const SORT_BY_TIMEFRAME = 'sortByTimeframe';

type ProfileImageLoader = (userId: number, loadedCb: (url: string) => void) => void;

export interface PlanChildObjectBrief {
  budgetSegmentId?: number;
  goalId?: number;
}

export interface PlanChildObjectsBrief {
  expGroups: PlanChildObjectBrief[];
  campaigns: PlanChildObjectBrief[];
}

@Component({
  selector: 'app-root',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [AppDataLoader, DragAndDropService]
})
export class Dashboard implements OnInit, OnDestroy {
  SharedIconState = SharedIconState;
  filterNames = FilterName; // For data binding in the template
  sortDropdownMenuItems: ActionMenuItem[] = [
    { text: 'Sort by Investment', value: SORT_BY_INVESTMENT },
    { text: 'Sort by Timeframe', value: SORT_BY_TIMEFRAME },
  ];
  campaignsSortingMode: string = SORT_BY_TIMEFRAME;
  expenseBucketsSortingMode: string = SORT_BY_TIMEFRAME;
  companyId: number;
  companyCode: string;
  companyCurrency: { code: string; symbol: string };
  companyBudgetList: Budget[] = [];
  selectedBudget: Budget;
  budgetTimeframeList: BudgetTimeframe[] = [];
  budgetSegmentList: BudgetSegmentAccess[] = [];
  allCampaigns: LightCampaign[];
  allExpenseGroups: LightProgram[];
  segmentNameById: {[key: number]: string};
  allGoalsList: Goal[] = [];
  selectedTimeframes: BudgetTimeframe[] = [];
  selectedSegments: BudgetSegmentAccess[] = [];
  selectedSegmentInTab: BudgetSegmentAccess = null;

  showEmptyMsg = false;
  showGoal = false;
  changeTrigger = 0;

  planCampaigns: PlanCampaign[] = [];
  planCampaignsExist = false;
  planPrograms: PlanProgram[] = [];
  planProgramsExist = false;

  planDetailData: PlanDetailData = null;
  subscriptions: any[] = [];
  editPermission = false;
  isLoading = false;
  isAdmin = false;
  isPowerUser = false;
  openDropDown = false;
  goalList: PlanGoal[] = [];
  graphAt: any;
  goalContentExists = false;
  allCompanyTags = [];
  goalTagsControlStates = new WeakMap();
  campaignTagsControlStates = new WeakMap();
  programTagsControlStates = new WeakMap();
  nestedProgramsShowingStates = new WeakMap();
  nestedExpensesShowingStates = new WeakMap();
  currentFilters: FilterSet = {};
  isFilteredMode = false;
  profilePictures: { [userId: number]: Observable<string> } = {};
  public menuConfig: HierarchySelectConfig = {
    fieldLabel: '',
    withSearch: true,
    emptyValueLabel: 'All Segments',
    searchPlaceholder: 'Search Segments',
    allGroups: true,
    allPlural: 'Segments'
  };
  public readonly utClasses = {
    goal: 'ut-goal',
    campaign: 'ut-campaign',
    expenseGroup: 'ut-program',
  };

  sharedCostRules: SharedCostRule[] = [];
  allowedSharedCostRules: SharedCostRule[] = [];
  @ViewChild('budgetAmountsTooltip', { read: ViewContainerRef, static: true }) budgetAmountsTooltipContainer;
  budgetAmountsTooltipRef: ComponentRef<BudgetAmountsSummaryComponent> = null;
  budgetAmountsTooltipStyles = {};
  budgetAmountsTooltipClass = null;

  private initialDashboardDataLoaded = false;
  private planObjectLoaderByType = {
    [this.configuration.OBJECT_TYPES.goal]: force => this.loadPlanGoals(force),
    [this.configuration.OBJECT_TYPES.campaign]: force => this.loadPlanCampaigns(force),
    [this.configuration.OBJECT_TYPES.program]: force => this.loadPlanPrograms(force),
  };
  private planObjectLoaderBySection = {
    [ViewSectionName.goals]: force => this.loadPlanGoals(force),
    [ViewSectionName.campaigns]: force => this.loadPlanCampaigns(force),
    [ViewSectionName.buckets]: force => this.loadPlanPrograms(force),
  };
  private errorMessages = {
    FAILED_TO_LOAD_CAMPAIGNS: 'Failed to load campaigns',
    FAILED_TO_LOAD_PROGRAMS: 'Failed to load expense groups',
    FAILED_TO_LOAD_GOALS: 'Failed to load goals',
  };
  public viewSectionName = ViewSectionName;
  public loadingState = LoadingState;
  public viewSectionsData = {
    [ViewSectionName.goals]: false,
    [ViewSectionName.campaigns]: false,
    [ViewSectionName.buckets]: false,
  };
  public viewSectionsLoadingState = {
    [ViewSectionName.goals]: LoadingState.NotLoaded,
    [ViewSectionName.campaigns]: LoadingState.NotLoaded,
    [ViewSectionName.buckets]: LoadingState.NotLoaded,
  };

  public FilterName = FilterName;
  public ExternalIntegrationObjectTypes = ExternalIntegrationObjectTypes;
  public excludedGraphValues = [
    this.configuration.statusFields.overBudget,
    this.configuration.statusFields.overSpend
  ];
  public activeDragEntity: PlanCampaign | PlanProgram;
  public dropTargetCampaignIds: number[] = [];
  public campaignCardSegmentList: HierarchySelectItem[] = [];
  public manageTableViewMode = ManageTableViewMode;
  public allowDrop = true;
  public allowDrag = true;
  public forbidDrag = false;
  public readonly objectMode = ObjectMode;
  private segmentGroups: SegmentGroup[] = [];

  private goalsSubscription: Subscription;
  private planObjectsSubscription: Subscription;
  private companyTagsSubscription: Subscription;
  private planTableSubscription: Subscription;

  public budgetTotalsLoading$ = new BehaviorSubject(true);
  public budgetSummaryLoading$ = new BehaviorSubject(true);
  public miniDashLoading$ = new BehaviorSubject(false);
  public summaryBarItems: SummaryBarItem[][] = null;

  private _summaryBarCalculationItems: SummaryBarCalculationItemList = BudgetSummaryBarHelpers.summaryBarDefaultCalculationItems;
  public summaryBarCalculationItems: SummaryBarCalculationItemList[] = Object.values(this._summaryBarCalculationItems);
  private readonly destroy$ = new Subject<void>();

  constructor(
    protected readonly configuration: Configuration,
    protected readonly companyService: CompanyService,
    private readonly utilityService: UtilityService,
    protected readonly validations: Validations,
    private readonly gtmService: GoogleTagManagerService,
    private readonly compareService: CompareService,
    private readonly tagManagerFactory: TagManagerFactory,
    private readonly budgetDataService: BudgetDataService,
    private readonly filterManagementService: FilterManagementService,
    private readonly userDataService: UserDataService,
    private readonly companyDataService: CompanyDataService,
    private readonly campaignService: CampaignService,
    private readonly programService: ProgramService,
    private readonly expensesService: ExpensesService,
    private readonly sharedCostRulesService: SharedCostRulesService,
    private readonly isSharedSubObjectPipe: IsSharedSubObjectPipe,
    private readonly userManager: UserManager,
    private readonly budgetAmountsSummaryService: BudgetAmountsSummaryService,
    private readonly componentResolver: ComponentFactoryResolver,
    private readonly appRoutingService: AppRoutingService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly metricMappingDetailsService: MetricMappingDetailsService,
    private readonly segmentDataInheritanceService: SegmentDataInheritanceService,
    private readonly appDataLoader: AppDataLoader,
    private readonly objectAccessManager: ObjectAccessManagerService,
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly segmentMenuService: SegmentMenuHierarchyService,
    private readonly dragAndDropService: DragAndDropService,
    private readonly goalService: GoalsService,
    private readonly tagMappingsPipe: TagMappingsPipe,
  ) {

    this.subscriptions.push(
      this.userManager.currentCompanyUser$.pipe(filter(user => user != null))
        .subscribe(user => this.setCurrentUser(user))
    );

    this.subscriptions.push(this.utilityService.onDragNdropItems$.subscribe(
      () => this.loadDashboardData()
    ));

    const company$ =
      this.companyDataService.selectedCompanyDO$.pipe(
        filter(cmp => cmp != null),
        tap(company => {
          this.companyId = company.id;
          this.companyCurrency = {
            code: company.currency,
            symbol: company.currency_symbol,
          };
          this.companyDataService.loadCompanyData(this.companyId, error => this.handleError(error, null));
          this.companyDataService.loadGoalTypes(this.companyId, error => this.handleError(error, null));
          if (this.budgetDataService.isCurrentBudgetWithNewCEGStructure) {
            this.companyDataService.loadObjectTypes(this.companyId, error => this.handleError(error, null));
          } else {
            this.companyDataService.loadProgramTypes(this.companyId, error => this.handleError(error, null));
          }
        }),
        switchMap(company => this.companyService.getCompanyInfo(company.id))
      );

    this.subscriptions.push(
      company$.pipe(catchError(error => {
        this.utilityService.handleError(error);
        return company$;
      })).subscribe(response => this.success(response, 'getCompanyInfo'))
    );

    this.subscriptions.push(this.budgetDataService.companyBudgetList$.subscribe(
      budgetList => this.companyBudgetList = budgetList
    ));

    this.subscriptions.push(this.budgetDataService.selectedBudget$.subscribe(
      newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)
    ));

    this.subscriptions.push(this.userDataService.editPermission$.subscribe(
      editPermission => this.editPermission = editPermission
    ));

    const activatedRoute$ = this.activatedRoute.paramMap
      .pipe(
        tap((params: ParamMap) => {
          this.applySectionsFromRouter(params.get('openSections'));
          this.appRoutingService.updateCurrentFiltersInRouting(
            this.companyId,
            this.selectedBudget?.id,
            this.currentFilters);
        })
      );

    const budgetData$ = combineLatest([
      this.budgetDataService.timeframeList$.pipe(
        filter(tfList => this.selectedBudget?.id && tfList.every(tf => tf.budget === this.selectedBudget?.id)),
        tap(tfList => {
          this.budgetTimeframeList = tfList;
          this.updateSelectedTimeframes();
        })
      ),
      this.budgetDataService.segmentList$.pipe(
        tap(segmentList => {
          this.budgetSegmentList = segmentList;
          this.updateSelectedSegments();
        })
      ),
      this.budgetDataService.segmentGroupList$.pipe(
        tap(segmentGroups => this.segmentGroups = segmentGroups)
      ),
      this.budgetDataService.goalList$.pipe(
        tap(goalList => {
          this.allGoalsList = goalList;
          this.goalContentExists = goalList?.length > 0;
        })
      ),
      this.budgetDataService.lightCampaignList$.pipe(
        tap(campaignList => {
          this.allCampaigns = campaignList;
          this.planCampaignsExist = campaignList?.length > 0;
        })
      ),
      this.filterManagementService.budgetFiltersList$.pipe(
        switchMap(() => this.filterManagementService.budgetFiltersInit$),
        switchMap(() => this.filterManagementService.currentFilterSet$),
        skip(1),
        tap(filterSet => {
          this.currentFilters = {...filterSet};
          this.updateSelectedTimeframes();
          this.updateSelectedSegments();
          this.isFilteredMode = Object.values(this.currentFilters).some(fv => fv.length);
          this.appRoutingService.updateCurrentFiltersInRouting(
            this.companyId,
            this.selectedBudget.id,
            this.currentFilters
          );
        })
      ),
      this.budgetDataService.sharedCostRuleList$.pipe(
        tap(data => this.sharedCostRules = data)
      ),
      activatedRoute$
    ]).pipe(tap(() => {
      this.allowedSharedCostRules = this.objectAccessManager.getAllowedSharedCostRules(this.sharedCostRules, this.budgetSegmentList);
      this.updateShowGoal();
    }));

    this.subscriptions.push(
      this.budgetDataService.selectedBudget$
        .pipe(
          tap(() => this.planDetailData = null),
          switchMap(() => budgetData$)
        )
        .subscribe(
          () => this.loadDashboardData(),
          error => this.handleError(error, null))
    );

    combineLatest([
      this.budgetSummaryLoading$,
      this.budgetTotalsLoading$
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([summaryLoading, totalsLoading]) => {
      const loadingInProgress = summaryLoading || totalsLoading;
      this.miniDashLoading$.next(loadingInProgress);
    });
  }

  ngOnInit() {
    this.graphAt = 'plan_detail';

    const changeTypesForReloadDashboard =
      [
        this.configuration.OBJECT_TYPES.campaign,
        this.configuration.OBJECT_TYPES.program,
        this.configuration.OBJECT_TYPES.expense,
      ];

    const changeMetricMappingTypesForReloadDashboard = [
      this.configuration.OBJECT_TYPES.goal,
      this.configuration.OBJECT_TYPES.program,
      this.configuration.OBJECT_TYPES.campaign,
    ];

    // Reload plan view on expense changes - to make sure all total are up-to-date
    this.subscriptions.push(
      this.budgetObjectDetailsManager.budgetObjectChanged$.subscribe(
        (change) => {
          if (change && changeTypesForReloadDashboard.includes(change.objectType)) {
            this.loadDashboardData();
          }
        }
      )
    );

    this.subscriptions.push(
      this.metricMappingDetailsService.metricMappingChanged$.subscribe(change =>
        change && changeMetricMappingTypesForReloadDashboard.includes(change.mappingType) && this.loadDashboardData()
      )
    );

    this.subscriptions.push(
      concat(of(this.budgetDataService.lightProgramsSnapshot), this.budgetDataService.lightProgramList$).subscribe(
        programs => {
          this.allExpenseGroups = programs;
          this.planProgramsExist = programs?.length > 0;
        }
      )
    );

    this.appDataLoader.init(false);
    LocalStorageService.addToStorage(CARD_TABLE_VIEW_MODE, PlanPageViewMode.CARD);
  }

  public isSegmentlessCampaign(campaign: PlanCampaign): boolean {
    return !campaign?.company_budget_segment1 && !campaign?.split_rule;
  }

  public isObjectClosed(budgetObject: PlanObjectDO): boolean {
    return budgetObject.mode === ObjectMode.Closed;
  }

  private setCurrentUser(companyUser: CompanyUserDO) {
    this.isAdmin = this.userDataService.isAdmin(companyUser);
    this.isPowerUser = this.userDataService.isPowerUser(companyUser);
  }

  public updateObjectSplitRule(
    objectId: number,
    selectedValue: BudgetObjectSegmentData,
    objectType: string,
    hasChildren: boolean
  ) {
    const objectPayload: any = {
      split_rule: selectedValue.sharedCostRuleId || null,
      company_budget_segment1: selectedValue.budgetSegmentId || null
    };
    const { OBJECT_TYPES } = this.configuration;
    const updateObject = (_objectType: string, _objectId: number, _objectPayload: object): Observable<any> => {
      switch (_objectType) {
        case OBJECT_TYPES.campaign:
          return this.campaignService.updateCampaign(_objectId, _objectPayload);

        case OBJECT_TYPES.program:
          return this.programService.updateProgram(_objectId, _objectPayload);

        case OBJECT_TYPES.expense:
          return this.expensesService.updateExpense(_objectId, _objectPayload);

        default:
          of(null);
      }
    };

    const updateObjectSegmentAction$: Observable<SegmentDataInheritanceAction> =
      hasChildren ?
        this.segmentDataInheritanceService.confirmSegmentChange(objectType, hasChildren, false) :
        of(SegmentDataInheritanceAction.Replace);

    this.subscriptions.push(
      updateObjectSegmentAction$
        .pipe(
          filter(action => action !== SegmentDataInheritanceAction.None),
          tap(action => {
            if (action === SegmentDataInheritanceAction.Replace) {
              objectPayload.process_nested = true;
            }
          }),
          switchMap(() => updateObject(objectType, objectId, objectPayload))
        )
        .subscribe(
          () => this.planObjectLoaderByType[objectType]?.(true),
          error => this.handleError(error, null)
        )
    );
  }

  private updateShowGoal() {
    this.showGoal = false;
    const selectedGoalIds = this.currentFilters[FilterName.Goals];
    if (selectedGoalIds && selectedGoalIds.length === 1) {
      const selectedGoal = this.allGoalsList.find(goal => goal.id === selectedGoalIds[0]);
      if (selectedGoal) {
        this.showGoal = true;
      }
    }
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    if (newSelectedBudget.new_campaigns_programs_structure) {
      this.appRoutingService.openPlanDetail(
        [ this.configuration.OBJECT_TYPES.segment ], { queryParamsHandling: 'preserve' }
      );
    }

    this.selectedBudget = newSelectedBudget;
    if (newSelectedBudget != null) {
      this.showEmptyMsg = false;

      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.campaignStatusNames.active,
        error => this.handleError(error, null)
      );

      this.budgetDataService.loadLightPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.programStatusNames.active,
        error => this.handleError(error, null)
      );

      this.utilityService.showLoading(true);
      this.loadSegmentsMapping()
    } else {
      this.showEmptyMsg = true;
    }
  }

  handleCampaignsSortModeChange = (sortingMode: string) => {
    this.campaignsSortingMode = sortingMode;
    this.sortObjects(this.planCampaigns, this.campaignsSortingMode);
  };

  handleExpenseBucketsSortModeChange = (sortingMode: string) => {
    this.expenseBucketsSortingMode = sortingMode;
    this.sortObjects(this.planPrograms, this.expenseBucketsSortingMode);
  };

  handleAddingTagForGoal(event, goal) {
    const tagManager = this.createTagManagerForGoal(goal);
    tagManager.attachExistingTagToObject(event.id, goal.id, event.inherit);
  }

  handleAddingTagForCampaign(event, campaign) {
    const tagManager = this.createTagManagerForCampaign(campaign);
    tagManager.attachExistingTagToObject(event.id, campaign.id, event.inherit);
  }

  handleAddingTagForProgram(event, program) {
    const tagManager = this.createTagManagerForProgram(program);
    tagManager.attachExistingTagToObject(event.id, program.id, event.inherit);
  }

  handleCreatingNewTagForGoal(event, goal) {
    const tagManager = this.createTagManagerForGoal(goal);
    tagManager.createNewTagAndAttachToObject(event.title, goal.id, event.inherit);
  }

  handleCreatingNewTagForCampaign(event, campaign) {
    const tagManager = this.createTagManagerForCampaign(campaign);
    tagManager.createNewTagAndAttachToObject(event.title, campaign.id, event.inherit);
  }

  handleCreatingNewTagForProgram(event, program) {
    const tagManager = this.createTagManagerForProgram(program);
    tagManager.createNewTagAndAttachToObject(event.title, program.id, event.inherit);
  }

  handleRemovingTagOfGoal(event, goal) {
    const tagManager = this.createTagManagerForGoal(goal);
    tagManager.detachTag(event.id, event.inherit);
  }

  handleRemovingTagOfCampaign(event, campaign) {
    const tagManager = this.createTagManagerForCampaign(campaign);
    tagManager.detachTag(event.id, event.inherit);
  }

  handleRemovingTagOfProgram(event, program) {
    const tagManager = this.createTagManagerForProgram(program);
    tagManager.detachTag(event.id, event.inherit);
  }

  handleTogglingGoalTagsControl(goal, e) {
    e.stopPropagation();
    this.goalTagsControlStates.set(goal, !this.goalTagsControlStates.get(goal));
  }

  handleHideGoalTagControl(goal) {
    this.goalTagsControlStates.set(goal, false);
  }

  handleTogglingCampaignTagsControl(campaign, e) {
    e.stopPropagation();
    if (!this.editPermission) {
      return;
    }
    this.campaignTagsControlStates.set(campaign, !this.campaignTagsControlStates.get(campaign));
  }

  handleHideCampaignTagsControl(campaign) {
    this.campaignTagsControlStates.set(campaign, false);
  }

  handleTogglingProgramTagsControl(program, e) {
    e.stopPropagation();
    this.programTagsControlStates.set(program, !this.programTagsControlStates.get(program));
  }

  handleHideProgramTagsControl(program) {
    this.programTagsControlStates.set(program, false);
  }

  createTagManagerForEntity(entityType, entity) {
    const tagManager = this.tagManagerFactory.createTagManager(
      this.companyId,
      entityType,
      () => entity,
      'parsedTagsData',
      this,
      'allCompanyTags',
      error => this.handleError(error, null),
      ({ inherit }) => {
        tagManager.closeSubscriptions();
        if (inherit) {
          this.planObjectLoaderByType[entityType]?.(true);
        }
      });
    return tagManager;
  }

  createTagManagerForGoal(goal) {
    return this.createTagManagerForEntity(this.configuration.OBJECT_TYPES.goal, goal);
  }

  createTagManagerForCampaign(campaign) {
    return this.createTagManagerForEntity(this.configuration.OBJECT_TYPES.campaign, campaign);
  }

  createTagManagerForProgram(program) {
    return this.createTagManagerForEntity(this.configuration.OBJECT_TYPES.program, program);
  }

  updateGoalsWithParsedTagsData() {
    this.goalList.forEach(goal =>
      goal.parsedTagsData = this.tagMappingsPipe.transform(goal.tags_data)
    );
  }

  prepareEntityTagsControlStates(entities) {
    return entities.reduce((state, entity) => {
      state.set(entity, false);
      return state;
    }, new WeakMap());
  }

  initGoalTagsControlStates() {
    this.goalTagsControlStates = this.prepareEntityTagsControlStates(this.goalList);
  }

  initCampaignTagsControlStates() {
    this.campaignTagsControlStates = this.prepareEntityTagsControlStates(this.planCampaigns);
  }

  initProgramTagsControlStates() {
    this.programTagsControlStates = this.prepareEntityTagsControlStates(this.planPrograms);
  }

  goToManagePage(viewMode: string) {
    this.router.navigate([this.configuration.ROUTING_CONSTANTS.MANAGE_PAGE, ManageTableDataMode.Allocation, viewMode]);
  }

  sortObjects(sortArray: PlanObjectDO[], sortingMode) {
    sortArray.sort((objectA, objectB) => {
      if (sortingMode === SORT_BY_INVESTMENT) {
        return objectB.amount - objectA.amount;
      }

      if (sortingMode === SORT_BY_TIMEFRAME) {
        return this.compareService.compareTimeframes(objectA.active_timeframe_ids, objectB.active_timeframe_ids);
      }

      return 0;
    })
  }

  private resetData() {
    this.viewSectionsLoadingState = {
      [ViewSectionName.goals]: LoadingState.NotLoaded,
      [ViewSectionName.campaigns]: LoadingState.NotLoaded,
      [ViewSectionName.buckets]: LoadingState.NotLoaded,
    };
    this.goalList = [];
    this.planPrograms = [];
    this.planCampaigns = [];
    this.updateMiniDashCalculationItems({
      allocated: 0,
      expenses: 0,
      remaining: 0,
    });
    this.planDetailData = null;
  }

  private loadPlanObjectsByOpenSections() {
    Object.entries(this.viewSectionsData).forEach(([ sectionName, isOpen ]) => {
      if (isOpen) {
        this.planObjectLoaderBySection[sectionName]?.();
      }
    });
  }

  loadDashboardData() {
    this.utilityService.showLoading(true);
    this.resetData();
    this.loadPlanObjectsByOpenSections();
    this.getMiniDashTotals();
    this.loadAllCompanyTags();
    this.campaignCardSegmentList = this.segmentMenuService.prepareDataForSegmentMenu({
      segments: this.budgetSegmentList,
      groups: this.segmentGroups,
    });
    this.initialDashboardDataLoaded = true;
    this.budgetDataService.loadCampaigns(this.companyId, this.selectedBudget.id);
    this.budgetDataService.loadPrograms(this.companyId, this.selectedBudget.id);
  }

  loadSegmentsMapping() {
    this.sharedCostRulesService.getSegmentsMapByBudgetId(this.selectedBudget.id)
      .subscribe(
        mapping => this.segmentNameById = mapping,
        error => this.handleError(error, 'loadSegmentsMapping')
        );
  }

  sortMetricData(goal: PlanGoalDO) {
    const metricData = goal.metric_data;
    if (metricData) {
      metricData.sort((a, b) => {
        const byOrder = a.order - b.order;
        return byOrder !== 0 ? byOrder : a.metric_name.localeCompare(b.metric_name);
      });
    }
  }

  private loadPlanGoals(force = false) {
    if (!force && this.viewSectionsLoadingState[ViewSectionName.goals] !== LoadingState.NotLoaded) {
      return;
    }

    const requestParamsDef: ParamsDef = {
      'budget': { defaultValue: () => this.selectedBudget.id  },
      'ids': { filterName: FilterName.Goals },
      'company_budget_allocation_ids': { filterName: FilterName.Timeframes },
      'campaign_ids': {
        filterName: FilterName.Campaigns,
        defaultValue: () => this.filterManagementService.getFilterCampaignIds(this.allCampaigns)
      },
      'program_ids': { filterName: FilterName.ExpenseBuckets },
      'campaign_type_ids': { filterName: FilterName.CampaignTypes },
      'tag_ids': { filterName: FilterName.Tags }
    };
    const reqData = {};

    this.setSectionLoadingState(ViewSectionName.goals, LoadingState.Loading);
    this.filterManagementService.setParamsFromFilters(reqData, requestParamsDef, true);
    this.budgetSummaryLoading$.next(true);
    this.goalService.getPlanGoals(reqData).subscribe(
      data => this.onPlanGoalsLoaded(data),
      () => this.utilityService.handleError({ message: this.errorMessages.FAILED_TO_LOAD_GOALS })
    );
  }

  private onPlanGoalsLoaded(data: PlanGoal[]) {
    this.goalList = data || [];
    this.goalList.forEach(goal => {
      this.sortMetricData(goal);
      goal.goalTypeName =
        (this.companyDataService.goalTypesSnapshot || []).find(goalType => goalType.id === goal.goal_type)?.name;
    });
    this.updateGoalsWithParsedTagsData();
    this.initGoalTagsControlStates();
    this.updateMiniDashSummaryItems();
    this.setSectionLoadingState(ViewSectionName.goals, LoadingState.Loaded);
  }

  private loadPlanCampaigns(force = false) {
    if (!force && this.viewSectionsLoadingState[ViewSectionName.campaigns] !== LoadingState.NotLoaded) {
      return;
    }

    this.setSectionLoadingState(ViewSectionName.campaigns, LoadingState.Loading);
    this.loadPlanObjects(
      params => this.campaignService.getPlanCampaigns(params),
      (campaigns, localFilter, imageLoader) =>
        this.onCampaignsLoaded(campaigns as PlanCampaignDO[], localFilter, imageLoader),
      () => this.utilityService.handleError({ message: this.errorMessages.FAILED_TO_LOAD_CAMPAIGNS })
    );
  }

  private loadPlanPrograms(force = false) {
    if (!force && this.viewSectionsLoadingState[ViewSectionName.buckets] !== LoadingState.NotLoaded) {
      return;
    }

    this.setSectionLoadingState(ViewSectionName.buckets, LoadingState.Loading);
    this.loadPlanObjects(
      params => this.programService.getPlanPrograms(params),
      (programs, localFilter, imageLoader) =>
        this.onProgramsLoaded(programs as PlanProgramDO[], localFilter, imageLoader),
      () => this.utilityService.handleError({ message: this.errorMessages.FAILED_TO_LOAD_PROGRAMS })
    );
  }

  private loadPlanObjects(
    load: (params: object) => Observable<PlanProgramDO[] | PlanCampaignDO[]>,
    onLoaded: (
      objects: PlanCampaignDO[] | PlanProgramDO[],
      localFilter: ObjectLocalFilter<PlanObjectDO>,
      imageLoader: ProfileImageLoader
    ) => void,
    onError?: Function
  ) {
    const profileImageLoader: ProfileImageLoader = (userId: number, loadedCb: (url: string) => void) => {
      this.subscriptions.push(
        this.userService.getUserProfilePictureUrl(userId)
          .subscribe(loadedCb)
      );
    };
    const localObjectsFilter = this.createObjectLocalFilter();
    const paramsData = this.getParamsForAllGoalsDataRequest();

    this.budgetSummaryLoading$.next(true);
    load(paramsData)
      .pipe(
        tap(planObjects => {
          onLoaded(planObjects, localObjectsFilter, profileImageLoader);
          this.updateMiniDashSummaryItems();
        })
      ).subscribe({
        error: error => {
          if (error) {
            console.error(error);
          }
          onError?.();
        }
      });
  }

  private onProgramsLoaded(
    planPrograms: PlanProgramDO[],
    localObjectsFilter: ObjectLocalFilter<PlanObjectDO>,
    profileImageLoader: ProfileImageLoader
  ): void {
    const builder = new PlanProgramsBuilder(planPrograms, this.configuration);
    this.planPrograms =
        this.buildCommonPlanObjects(builder, localObjectsFilter, profileImageLoader)
          .setBudgetObjectTypeName(
            this.companyDataService.programTypesSnapshot,
              campaign => campaign[getObjectTypeKey(this.budgetDataService.selectedBudgetSnapshot, 'program_type')])
          .getResultPlanObjects();

    this.sortObjects(this.planPrograms, this.expenseBucketsSortingMode);
    this.initProgramTagsControlStates();
    this.setSectionLoadingState(ViewSectionName.buckets, LoadingState.Loaded);
  }

  private onCampaignsLoaded(
    planCampaigns: PlanCampaignDO[],
    localObjectsFilter: ObjectLocalFilter<PlanObjectDO>,
    profileImageLoader: ProfileImageLoader
  ): void {
    const builder = new PlanCampaignsBuilder(planCampaigns, this.configuration);
    this.planCampaigns =
      this.buildCommonPlanObjects(builder, localObjectsFilter, profileImageLoader)
        .setBudgetObjectTypeName(
          this.companyDataService.campaignTypesSnapshot,
            campaign => campaign[getObjectTypeKey(this.budgetDataService.selectedBudgetSnapshot, 'campaign_type')])
        .getResultPlanObjects();

    this.sortObjects(this.planCampaigns, this.campaignsSortingMode);
    this.initCampaignTagsControlStates();
    this.setSectionLoadingState(ViewSectionName.campaigns, LoadingState.Loaded);
  }

  private buildCommonPlanObjects<TDataObject extends PlanObjectDO>(
    sourceBuilder: PlanObjectsBuilder<TDataObject>,
    localObjectsFilter: ObjectLocalFilter<PlanObjectDO>,
    profileImageLoader: (userId: number, loadedCb: (url: string) => void) => void
  ): PlanObjectsBuilder<TDataObject> {
    return sourceBuilder
      .withLocalFilter(localObjectsFilter)
      .setOwnerPicture(this.profilePictures, profileImageLoader)
      .prepareTagsData(this.tagMappingsPipe)
      .addTooltipData(this.budgetAmountsSummaryService, this.companyCurrency.code)
      .addOwnerData(this.companyDataService.companyUsersSnapshot);
  }

  private loadAllCompanyTags() {
    this.companyTagsSubscription?.unsubscribe();
    this.companyTagsSubscription =
      this.tagManagerFactory.tagService.getTags({company: this.companyId}).subscribe(
        tags => this.allCompanyTags = tags ? tags.map(tag => ({title: tag.name, id: tag.id})) : []
      );
  }

  private getParamsForAllGoalsDataRequest() {
    const isSegmentFilteringApplied = !!this.currentFilters[FilterName.Segments]?.length;
    const requestParamsDef: ParamsDef = this.getDefaultFilters(isSegmentFilteringApplied);
    const data = {};
    this.filterManagementService.setParamsFromFilters(data, requestParamsDef, true);
    return data;
  }

  getDefaultFilters(includePseudoObjects: boolean): ParamsDef {
    return {
      'budget': { defaultValue: () => this.selectedBudget.id },
      'company_budget_allocation_ids': { filterName: FilterName.Timeframes },
      'company_budget_segment1_ids': {
        filterName: FilterName.Segments,
        defaultValue: () => this.filterManagementService.getDefaultSegments(this.budgetSegmentList)
      },
      'include_pseudo_objects': { defaultValue: () => includePseudoObjects },
      'goal_ids': { filterName: FilterName.Goals },
      'tag_ids': { filterName: FilterName.Tags },
      'owner_ids': { filterName: FilterName.Owners },
      'campaign_ids': {
        filterName: FilterName.Campaigns,
        defaultValue: () => this.filterManagementService.getFilterCampaignIds(this.allCampaigns)
      },
      'campaign_type_ids': { filterName: FilterName.CampaignTypes },
      'program_ids': { filterName: FilterName.ExpenseBuckets },
      'expense_type_ids': { filterName: FilterName.ExpenseTypes },
      'gl_code_ids': { filterName: FilterName.GlCodes },
      'vendor_ids': { filterName: FilterName.Vendors },
      'split_rule_ids': { filterName: FilterName.SharedCostRules },
      'source': { filterName: FilterName.ExpenseSource },
      'modes': { filterName: FilterName.Statuses },
      'metric_ids': { filterName: FilterName.Metrics },
      'po_numbers': { filterName: FilterName.PONumber },
      'amount_status': { filterName: FilterName.CEGStatus }
    }
  }

  onSelectGraphStatus(status) {
    this.closeOptionDropdown();
    this.filterManagementService.updateCurrentFilterSet({...this.currentFilters, [FilterName.Statuses]: [status]});
    this.loadDashboardData();
    this.gtmService.sendEvent({
      eventCategory: 'Right Bar Graph',
      eventAction: 'Click Graph',
      eventLabel: 'Click',
      eventValue: 1
    });
    this.changeTrigger++;
  }

  getMiniDashTotals() {
    const requestParamsDef: ParamsDef = this.getDefaultFilters(false);
    const filters = {};
    this.filterManagementService.setParamsFromFilters(filters, requestParamsDef, true);

    if (!filters['company_budget_segment1_ids']?.length) {
      filters['company_budget_segment1_ids'] = this.budgetSegmentList.map(segment => segment.id).join(',');
    }
    this.budgetTotalsLoading$.next(true);
    this.expensesService.getMiniDashExpensesTotalAmount(filters)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.budgetTotalsLoading$.next(false)),
      )
      .subscribe({
          next: totalsData => this.updateMiniDashCalculationItems(totalsData),
          error: error => this.handleError(error, 'getBudgetGraphDetails'),
        }
    );
  }

  private updateMiniDashCalculationItems(totalsData: ExpenseMiniDashTotalsDO): void {
    const allocated = totalsData.allocated || 0;
    const expenses = totalsData.expenses || 0;
    const remainingAllocated = totalsData.remaining || 0;
    const remaining = BudgetSummaryBarHelpers.calcRemaining(allocated, expenses, remainingAllocated);
    BudgetSummaryBarHelpers.updateCalculationItems(this._summaryBarCalculationItems, {
      allocated,
      expenses,
      remainingAllocated,
      remaining,
      isSegmentView: false,
      companyCurrencyCode: this.companyCurrency.code,
    });
  }

  private updateMiniDashSummaryItems(): void {
    const expGroupsFiltered = [
      ...this.planCampaigns
        .filter(camp => camp.program_list?.length)
        .reduce((arr, camp) => [...arr, ...camp.program_list], []),
      ...this.planPrograms,
    ];

    this.summaryBarItems = BudgetSummaryBarHelpers.getSummaryBarItems({
      totalObjects: {
        goals: this.allGoalsList?.length || 0,
        segments: this.budgetSegmentList?.length || 0,
        campaigns: this.allCampaigns?.length || 0,
        expGroups: this.allExpenseGroups?.length || 0,
      },
      filteredObjects: {
        expGroups: this.getFlatExpGroupBrief(expGroupsFiltered),
        campaigns: this.getFlatCampaignsBrief(this.planCampaigns),
      },
      isFilterMode: this.isFilteredMode,
      configuration: this.configuration,
    });
    this.budgetSummaryLoading$.next(false);
  }

  getFlatExpGroupBrief(items): PlanChildObjectBrief[] {
    return items.reduce((list, item) => {
      list.push({
        budgetSegmentId: item.company_budget_segment1,
      });
      return list;
    }, []);
  }

  getFlatCampaignsBrief(items): PlanChildObjectBrief[] {
    return items.reduce((list, planCamp) => {
      const campaign = this.allCampaigns.find(camp => camp.id === (planCamp.id || planCamp.campaign_id));
      list.push({
        budgetSegmentId: campaign.budgetSegmentId,
        goalId: campaign.goalId,
      });
      if (planCamp.child_campaign_list) {
        list = [...list, ...this.getFlatCampaignsBrief(planCamp.child_campaign_list)];
      }
      return list;
    }, []);
  }

  openAddProgram(e) {
    e.stopPropagation();
    return this.appRoutingService.openProgramCreation();
  }

  openAddCampaign(e) {
    e.stopPropagation();
    return this.appRoutingService.openCampaignCreation();
  }

  addGoalModal(e) {
    e.stopPropagation();
    return this.appRoutingService.openGoalCreation();
  }

  openViewGoal(id) {
    this.closeOptionDropdown();
    return this.appRoutingService.openGoalDetails(id);
  }

  getGoal(id) {
    return this.appRoutingService.openGoalDetails(id);
  }

  openViewCampaign(campaign) {
    if (this.isSharedSubObjectPipe.transform(campaign)) {
      return;
    }

    this.closeOptionDropdown();
    return this.appRoutingService.openCampaignDetails(campaign.id);
  }

  openViewProgram(program) {
    if (this.isSharedSubObjectPipe.transform(program)) {
      return;
    }

    this.closeOptionDropdown();
    this.appRoutingService.openProgramDetails(program.id)
  }

  openCampaignChild(childObject) {
    const { OBJECT_TYPES } = this.configuration;

    if (childObject.objectType === OBJECT_TYPES.campaign) {
      return this.openViewCampaign(childObject);
    }

    this.openViewProgram(childObject);
  }

  viewExpense(expense) {
    if (this.isSharedSubObjectPipe.transform(expense)) {
      return;
    }

    return this.appRoutingService.openExpenseDetails(expense.id);
  }

  /**
  * @method - closeOptionDropdown
  * @desc - on outside click close options dropdwon
  */
  closeOptionDropdown() {
    this.openDropDown = false;
  }

  toggleNestedProgramsShowingState(campaign) {
    this.nestedProgramsShowingStates.set(campaign, !this.nestedProgramsShowingStates.get(campaign));
  }

  toggleNestedExpensesShowingState(program) {
    this.nestedExpensesShowingStates.set(program, !this.nestedExpensesShowingStates.get(program));
  }

  dropDataOnCampaign(campaign: PlanCampaign) {
    const { OBJECT_TYPES } = this.configuration;
    this.zone.run(() => {
      // if plDraggable is false - activeDragEntity === undefined
      if (!this.activeDragEntity) {
        return;
      }
      if (
        campaign.id === this.activeDragEntity?.id
        || (this.activeDragEntity.objectType !== OBJECT_TYPES.program && this.activeDragEntity.objectType !== OBJECT_TYPES.campaign)
        || (campaign.mode === ObjectMode.Closed || this.activeDragEntity.mode === ObjectMode.Closed)
        || this.checkExistChildCampaign(this.activeDragEntity)
      ) {
        this.activeDragEntity = null;
        this.dropTargetCampaignIds = [];
        return;
      }

      if (this.activeDragEntity.objectType === OBJECT_TYPES.program) {
        const program = this.activeDragEntity as PlanProgram;
        // Remove program from program list
        this.planPrograms = this.planPrograms.filter(p => p.id !== program.id);
        this.dragAndDropService.moveExpenseGroupToCampaign(campaign, program)
          .subscribe({
            next: () => this.dragAndDropSuccess(),
            error: error => this.handleError(error, 'dragNdrop')
          });
        return;
      }

      if (this.activeDragEntity.objectType === OBJECT_TYPES.campaign) {
        this.dragAndDropService.moveCampaignToCampaign(campaign, this.activeDragEntity as PlanCampaign)
          .subscribe(
            () => this.dragAndDropSuccess(),
            error => this.handleError(error, 'dragNdrop')
          )
      }
    });
  }

  rightSideGraphHover() {
    this.gtmService.sendEvent({
      eventCategory: 'Right Bar Graph',
      eventAction: 'Hover Graph',
      eventLabel: 'Hover',
      eventValue: 1
    });
  }

  success(data, type) {
    if (Number(data.status) === HttpStatusCode.Ok) {
      if (type === 'getCompanyInfo') {
        this.companyCode = data.data.company_code;
        this.utilityService.showLoading(false);
      }
    } else {
      this.utilityService.showToast({ Title: '', Message: data.message, Type: 'error' });
      this.utilityService.showLoading(false);
    }
  }

  updateSelectedTimeframes() {
    this.selectedTimeframes =
      this.budgetTimeframeList.filter(
        tf => this.currentFilters[FilterName.Timeframes] != null && this.currentFilters[FilterName.Timeframes].includes(tf.id)
      );
  }

  updateSelectedSegments() {
    const currentSegmentFilters = this.currentFilters[FilterName.Segments];
    this.selectedSegments = !currentSegmentFilters?.length ?
      this.budgetSegmentList :
      this.budgetSegmentList.filter(
        seg => this.currentFilters[FilterName.Segments].includes(seg.id)
      );
    let alreadySelectedSegment = null;
    if (this.selectedSegmentInTab) {
      alreadySelectedSegment = this.selectedSegments.find(seg => seg.id === this.selectedSegmentInTab.id);
    }
    this.selectedSegmentInTab = alreadySelectedSegment || this.selectedSegments[0];
  }

  isEditingAllowed(budgetObject: PlanObjectDO) {
    return this.editPermission && !(this.isSharedSubObjectPipe.transform(budgetObject));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    [
      ...this.subscriptions,
      this.goalsSubscription,
      this.planObjectsSubscription,
      this.companyTagsSubscription,
      this.planTableSubscription
    ].forEach(s => s?.unsubscribe());

    this.subscriptions = [];
    this.resetData();
    this.budgetAmountsTooltipRef?.destroy();
  }

  handleError(error, type) {
    if (error.hasOwnProperty('message')) {
      this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
      this.utilityService.showLoading(false);
    } else if (error.hasOwnProperty('detail')) {
      this.utilityService.showLoading(false);
      this.utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
    }
    if (type === 'dragNdrop') {
      this.activeDragEntity = null;
      this.dropTargetCampaignIds = [];
    }
  }

  displayAmountsTooltip(e, data, offsetY) {
    const factory: ComponentFactory<BudgetAmountsSummaryComponent> =
      this.componentResolver.resolveComponentFactory(BudgetAmountsSummaryComponent);
    const tooltipPosition = this.budgetAmountsSummaryService.calculateTooltipPosition(e, {
      containerWidth: document.documentElement.clientWidth,
      thresholdWidth: 310,
      offsetX: 5,
      offsetY: offsetY,
    });
    this.budgetAmountsTooltipContainer.clear();
    const componentRef = factory.create(this.budgetAmountsTooltipContainer.injector);
    const instance = componentRef.instance as BudgetAmountsSummaryComponent;
    this.budgetAmountsTooltipContainer.insert(componentRef.hostView);
    this.budgetAmountsTooltipClass = tooltipPosition.position === 'right' ? 'arrow-left' : 'arrow-right';
    this.budgetAmountsTooltipStyles = {
      display: 'block',
      ...tooltipPosition.styles
    };
    instance.containerWidth = 310;
    instance.data = data;
  }

  hideAmountsTooltip() {
    this.budgetAmountsTooltipRef.destroy();
    this.budgetAmountsTooltipStyles = { display: 'none' };
    this.budgetAmountsTooltipClass = null;
  }

  campaignChildHasChildObjects(child): boolean {
    return child.program_list?.length > 0 || child.expense_list?.length > 0;
  }

  campaignHasChildObjects(campaign: any): boolean {
    return campaign.children?.length > 0 || campaign.expense_list?.length > 0;
  }

  programHasChildObjects(program: any): boolean {
    return program.expense_list?.length > 0;
  }

  applySectionsFromRouter(sectionsParam: string): void {
    const sectionNames = sectionsParam.split(',');
    const availableSections = Object.keys(ViewSectionName);

    availableSections
      .map(section => (<any> ViewSectionName)[section])
      .forEach(section => this.setSectionState(section, sectionNames.includes(section)));
  }

  private setSectionState(sectionName: ViewSectionName, isExpanded: boolean): void {
    this.viewSectionsData[sectionName] = isExpanded;
  }

  private setSectionLoadingState(sectionName: ViewSectionName, state: LoadingState): void {
    this.viewSectionsLoadingState[sectionName] = state;
  }

  private createObjectLocalFilter(): ObjectLocalFilter<PlanObjectDO> {
    return new ObjectLocalFilterGroup([ // This is the right place to add another local filters when required!
      new PlanObjectByStatusFilter(this.currentFilters[FilterName.Statuses] as string[])
    ]);
  }

  getObjectId(_index: number, object: { id: number }): number {
    return object.id;
  }

  onCampaignDragStart(draggingEntity: PlanCampaign | PlanProgram): void {
    this.zone.run(() => {
      const allEntities = [...this.planCampaigns, ...this.planPrograms];
      this.activeDragEntity = draggingEntity;
      this.forbidDrag = this.checkExistChildCampaign(draggingEntity) || this.isSegmentlessCampaign(draggingEntity as PlanCampaign);
      this.dropTargetCampaignIds = this.defineDropTargetCampaigns(allEntities, draggingEntity);
    });
  }

  onCampaignDragEnd(): void {
    this.zone.run(() => {
      this.activeDragEntity = null;
      this.dropTargetCampaignIds = [];
      this.allowDrag = true;
    });
  }

  defineDropTargetCampaigns(planObjects: (PlanCampaign | PlanProgram)[], draggingEntity: PlanCampaign | PlanProgram): Array<number> {
    if (this.checkExistChildCampaign(draggingEntity) || this.isSegmentlessCampaign(draggingEntity as PlanCampaign)) {
      return [draggingEntity.id];
    }
    return planObjects.filter(item => item.mode !== ObjectMode.Closed).map(item => item.id);
  }

  dragAndDropSuccess(): void {
    this.closeOptionDropdown();
    this.utilityService.dragNdrop(true);
    this.utilityService.showLoading(false);
    this.activeDragEntity = null;
    this.dropTargetCampaignIds = [];
  }

  checkExistChildCampaign(obj: PlanCampaign | PlanProgram): boolean {
    if (obj.objectType === this.configuration.OBJECT_TYPES.campaign) {
      return ((obj as PlanCampaign)?.children || []).some(child => child.objectType === this.configuration.OBJECT_TYPES.campaign);
    }
    return false;
  }

  public openViewSection(sectionName: ViewSectionName) {
    this.viewSectionsData[sectionName] = true;
    if (this.initialDashboardDataLoaded) {
      this.planObjectLoaderBySection[sectionName]?.();
    }
  }

  public closeViewSection(sectionName: ViewSectionName) {
    this.viewSectionsData[sectionName] = false;
  }
}
