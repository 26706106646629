import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExpenseRowData } from './expense-table-data.service';
import { InvoiceRowData } from '@spending/services/invoice-table-data.service';

export type SelectableSpendingRow = ExpenseRowData | InvoiceRowData;

@Injectable()
export class ExpensePageSelectionService {
  private _rowSelection$ = new BehaviorSubject<Record<string, SelectableSpendingRow>>({});
  private _isAllSelected$ = new BehaviorSubject<boolean>(false);
  private _isTotalSelected$ = new BehaviorSubject<boolean>(false);

  public rowSelection$ = this._rowSelection$.asObservable();
  public isTotalSelected$ = this._isTotalSelected$.asObservable();

  get itemSelectionValue(): Record<string, SelectableSpendingRow> {
    return this._rowSelection$.value;
  }

  get isAllSelectedValue(): boolean {
    return this._isAllSelected$.value;
  }

  set isTotalSelected(value: boolean) {
    this._isTotalSelected$.next(value);
  }

  get isTotalSelectedValue(): boolean {
    return this._isTotalSelected$.value;
  }

  toggleRowSelection(isSelected: boolean, row: SelectableSpendingRow, rowsLength: number): void {
    const currentSelection = this.itemSelectionValue;
    if (isSelected) {
      currentSelection[row.rowId] = row;
    } else {
      delete currentSelection[row.rowId];

      if (this.isTotalSelectedValue) {
        this.isTotalSelected = false;
      }
    }
    const isAllSelected = Object.keys(currentSelection).length === rowsLength;
    this._isAllSelected$.next(isAllSelected);
    this._rowSelection$.next(currentSelection);
  }

  toggleCurrentPageSelection(isSelected: boolean, rows: SelectableSpendingRow[]): void {
    let currentSelection = this.itemSelectionValue;
    if (isSelected) {
      rows.forEach(row => currentSelection[row.rowId] = row);
    } else {
      currentSelection = {};
      this.isTotalSelected = false;
    }
    this._isAllSelected$.next(isSelected);
    this._rowSelection$.next(currentSelection);
  }

  toggleTotalSelection(isSelected: boolean): void {
    if (isSelected) {
      this._isTotalSelected$.next(isSelected);
    } else {
      this.clearSelection();
    }
  }

  checkSelection(rows: SelectableSpendingRow[]): void {
    const selectionValues = Object.values(this.itemSelectionValue);
    if (!selectionValues.length) {
      return;
    }

    const upcomingSelectedRows = rows.filter(row =>
      selectionValues.findIndex(value => row.expenseId === value.expenseId) > -1
    );
    if (!upcomingSelectedRows.length) {
      this.clearSelection();
      return;
    }
    const newSelection = {};
    upcomingSelectedRows.forEach(row => newSelection[row.rowId] = row);
    this._rowSelection$.next(newSelection);
  }

  clearSelection(): void {
    this._rowSelection$.next({});
    this._isAllSelected$.next(false);
    this._isTotalSelected$.next(false);
  }
}
