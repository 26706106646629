import { Directive, ElementRef, Input } from '@angular/core';
import * as _ from 'underscore';
import { Validations } from '../../app.validations';
import { SmallNumber } from '../pipes/smallnumber.pipe';
import { DecimalPipe } from '@angular/common';
@Directive({
    selector: '[generateMetricsGraph]'
})
export class GenerateMetricsGraph {
    @Input('graphData') graphData;
    @Input('graphAt') graphAt;
    @Input('goalData') goalData;
    @Input('currencySymbol') currencySymbol;
    currency_symbol_length = 1;

    constructor(private el: ElementRef, private validations: Validations, private smallNumber: SmallNumber, private decimalPipe: DecimalPipe) { }

    ngOnChanges() {
        let self = this;
        let graphHtml = "";

        if(this.graphAt != 'view_goal'){
            if(this.graphData.length > 3){
                this.graphData.length = 3;
            }
        }

        setTimeout(() => {
            if(this.graphData.length > 0){
                _.each(this.graphData, function(graph){
                    let percentage : any = 0;
                    let projectionAmt : any = 0;
                    let actualAmt : any = 0;

                    percentage = self.getGraphPercentage(graph['total_metric_projection'], graph['total_metric_actual']);

                    projectionAmt = graph['total_metric_projection'];
                    actualAmt = graph['total_metric_actual'];

                    let className = ""; let width : any = 0;

                    if(percentage >= 100){
                        width = 100;
                        className = "orange"
                    }else{
                        width = percentage;
                    }

                    if(self.graphAt == 'view_goal'){
                        graphHtml += '<div class="box-progressBar box-progressBarGoal"><div class="progress"><div class="progress-bar progress-barHor '+className+'"  role="progressbar" style="width:'+width+'%;"></div><span><i>' + self.currencySymbol+ " " +self.decimalPipe.transform(actualAmt)+' of '+ self.currencySymbol+ " "+self.decimalPipe.transform(projectionAmt)+' </i>&nbsp;| '+self.smallNumber.transform(parseInt(percentage))+'%</span></div></div>'
                    } else {
                        graphHtml += '<div class="box-tooltipWapper"><div class="progress"><div class="progress-bar progress-barHor '+className+'"  role="progressbar" style="width:'+width+'%;"></div><span><i>'+graph['metric_name']+'</i>: '+self.smallNumber.transform(parseInt(percentage))+'%</span></div><div class="box-goalConTooltip"><h6>'+self.goalData.name+'</h6><b>'+graph['metric_name']+'</b><i>'+self.decimalPipe.transform(actualAmt)+' of '+self.decimalPipe.transform(projectionAmt)+'</i><a class="link-click4More" href="javascript:;" title="">Click to update</a></div></div>'
                    }
                });
            }else{
                if(self.graphAt == 'view_goal'){
                    graphHtml = this.validations.ValiditionMessages.METRICS_NOT_FOUND_MODAL;
                }else{
                    graphHtml = this.validations.ValiditionMessages.METRICS_NOT_FOUND;
                }
            }
            self.el.nativeElement.innerHTML = graphHtml;
        }, 300);
    }

    getGraphPercentage(total, actual) {
        if (total == 0 && actual == 0) {
            return 0;
        } else if(total == 0 && actual > 0){
            return 100;
        }else{
            return (parseFloat(actual) / parseFloat(total) * 100).toFixed(2);
        }
    }
}
