import { Component, ContentChildren, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';

export interface FlatSelectOption {
  id: number;
  name: string;
  hidden?: boolean;
  label?: string;
  indicator?: string;
}

@Component({
  selector: 'flat-select',
  templateUrl: './flat-select.component.html',
  styleUrls: ['./flat-select.component.scss']
})
export class FlatSelectComponent {
  @Input() options: FlatSelectOption[] = [];
  @Input() value: FlatSelectOption;
  @Input() placeholder = 'Select value';
  @Input() panelClass = '';
  @Input() isReadOnly = false;
  @Output() onValueChanged = new EventEmitter<FlatSelectOption>();

  @ViewChild(MatSelect) select: MatSelect;
  @ContentChildren('triggerChild') triggerContent: QueryList<ElementRef>;

  public handleValueChange($event) {
    this.value = $event;
    this.onValueChanged.emit($event);
  }

  public compareOptions(o1: FlatSelectOption, o2: FlatSelectOption) {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }
}
