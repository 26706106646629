<button
  mat-button
  class="header-action-item ut-manage"
  [matMenuTriggerFor]="panel.menu"
  [disabled]="disabled"
  [class.has-invoice]="hasInvoices"
>
  <span>{{ editPermission ? 'Manage' : 'View' }}</span>
  <fa-icon class="chevron" [icon]="['far', 'chevron-down']"></fa-icon>
</button>
<menu-panel #panel [items]="items"></menu-panel>
