<ceg-budget-allocations-table
  [objectId]="objectId"
  [objectType]="objectType"
  [campaignIsClosed]="campaignIsClosed"
  [isReadOnlyMode]="isReadOnlyMode"
  [isSegmentlessCampaign]="isSegmentlessCampaign"
  [externalIntegrationType]="externalIntegrationType"
  [tableColumns]="tableColumns"
  [tableRows]="tableRows"
  [allocationTotals]="allocationTotals"
  (onAllocationChange)="onChange.emit($event)"
  (openExpenses)="openExpensePage($event?.timeframeId)"
  (openBudgetAllocationDetails)="openManageCEGPage($event?.timeframeId)"
  [companyId]="companyId"
  [budgetId]="budgetId"
></ceg-budget-allocations-table>
