import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BudgetTableRecord, BudgetTableRecordType } from '../budget-table/budget-table.types';
import { BudgetSettingsPageService } from '../budget-settings-page/budget-settings-page.service';
import { BudgetTableValidationService } from '../../services/budget-table-validation.service';
import { EditableContentComponent } from '@common-lib/lib/utils/editable-content';


@Component({
  selector: 'budget-segment-name',
  templateUrl: './budget-segment-name.component.html',
  styleUrls: ['./budget-segment-name.component.scss'],
})
export class BudgetSegmentNameComponent extends EditableContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() record: BudgetTableRecord;
  public RecordType = BudgetTableRecordType;

  constructor(
    private readonly budgetSettingsPageService: BudgetSettingsPageService,
    private readonly budgetTableValidationService: BudgetTableValidationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.valueChangesSubscribe();
    this.budgetSettingsPageService.onSave$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.validateControl();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.record && this.record) {
      this.control.setValidators([
        Validators.required,
        this.budgetTableValidationService.uniqueNameValidator(this.record),
        this.budgetTableValidationService.reservedNameValidator(this.record)
      ]);
      this.control.updateValueAndValidity();
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
