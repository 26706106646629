import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DataValidationService } from '../../services/data-validation.service';
import { MetricMilestone, MetricMilestones } from '../../types/metric-milestone.interface';

@Component({
  selector: 'metric-milestones-list',
  templateUrl: './metric-milestones-list.component.html',
  styleUrls: ['./metric-milestones-list.component.scss']
})
export class MetricMilestonesListComponent implements OnChanges {
  @Input() milestonesList: MetricMilestone[] = [];
  @Input() readOnly = true;
  @Input() displayDecimal = false;
  @Input() originObjectId;
  @Output() patchMilestonesList = new EventEmitter<MetricMilestones>();

  formData: UntypedFormGroup[] = [];

  constructor(private fb: UntypedFormBuilder, private dataValidation: DataValidationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.originObjectId && this.originObjectId) {
      this.initFormData();
    }
  }

  private initFormData() {
    this.formData = (this.milestonesList || []).map(
      ml => this.createFormGroup(ml.targetValue, ml.date)
    );
  }

  addMilestoneField() {
    const index = this.formData.length - 1;
    const copyArray = [...this.formData];
    copyArray.splice(index, 0, this.createFormGroup(null, null));
    this.formData = [...copyArray];
  }

  onRemoveMilestone(index) {
    this.formData = this.formData.filter((el, i) => i !== index);
    this.patchMilestonesList.emit(this.getMilestonesSnapshot());
  }

  pushMilestonesUpdates(index) {
    this.sortFormData();
    const milestones = this.getMilestonesSnapshot(true);
    this.patchMilestonesList.emit(milestones)
  }

  getMilestonesSnapshot(onlyValid?: boolean): MetricMilestone[] {
    return (onlyValid
      ? this.formData.filter(fd => fd.valid && fd.value['date'])
      : this.formData)
        .map(fd => {
          const { amount, date } = fd.controls;
          return {
            targetValue: amount.value,
            date: date.value
          }
        })
  }

  sortByDate(milestones: UntypedFormGroup[]) {
    const filledItems = [];
    const emptyItems = [];
    milestones.forEach(fd => {
      fd.controls['amount'].value != null && fd.controls['date'].value != null
        ? filledItems.push(fd) : emptyItems.push(fd)
      }
    )
    const index = filledItems.length - 1 || 0;
    const sortedItems = filledItems.sort((a, b) => a.controls['date'].value.getTime() - b.controls['date'].value.getTime());
    sortedItems.splice(index, 0, ...emptyItems);
    return [...sortedItems]
  }

  sortFormData() {
    this.formData = this.sortByDate(this.formData);
  }

  createFormGroup(amount, date) {
    const formGroup = this.fb.group({
      amount: [amount || 0, {
        validators: [this.createAmountValidator()],
        updateOn: 'blur'
      }],
      date: [date, {
        validators: [this.createDateValidator()],
        updateOn: 'blur'
      }]
    })
    if (this.readOnly) {
      formGroup.disable();
    }
    return formGroup;
  }

  createAmountValidator(): ValidatorFn {
    return (amount: UntypedFormControl): ValidationErrors => {
      const formGroup = amount.parent as UntypedFormGroup;
      const date = formGroup ? formGroup.value['date'] : null;
      if (this.formData.length < 2 || !date ) {
        return null;
      }

      if (!amount.value) {
        return { required: true }
      }

      if (!this.isUniqueAmountValue(formGroup)) {
        return { unique: true }
      }

      if (!this.isAmountAndDateMatch(formGroup)) {
        return { match: true }
      }

      return null
    }
  }

  createDateValidator(): ValidatorFn {
    return (date: UntypedFormControl): ValidationErrors => {
      const formGroup = date.parent as UntypedFormGroup;

      if (this.formData.length < 2) {
        return null;
      }

      if (!date.value) {
        return { required: true }
      }

      if (!this.isUniqueDateValue(formGroup)) {
        return { unique: true }
      }

      if (!this.isAmountAndDateMatch(formGroup)) {
        return { match: true }
      }

      return null
    }
  }

  isAmountAndDateMatch(formGroup: UntypedFormGroup): boolean {
    if (!formGroup) {
      return false;
    }
    const { date, amount } = formGroup.controls;
    const milestones: MetricMilestones = this.getMilestonesSnapshot(true).filter(el => el.targetValue !== amount.value);
    let isMatch = true;
    if (!milestones.length) {
      return isMatch;
    }
    const lastIndex = milestones.length - 1;
    const biggerDateIndex = milestones.findIndex((fd) => fd.date.getTime() > date.value.getTime());
    const expectedIndex = biggerDateIndex < 0 ? milestones.length : biggerDateIndex;

    if (expectedIndex === 0) {
      isMatch = amount.value < milestones[0].targetValue;
    } else if (expectedIndex > lastIndex) {
      isMatch = amount.value > milestones[lastIndex].targetValue
    } else {
      isMatch = amount.value > milestones[expectedIndex - 1].targetValue && amount.value < milestones[biggerDateIndex].targetValue
    }
    return isMatch;
  }

  isUniqueAmountValue(formGroup: UntypedFormGroup): boolean {
    return !this.formData.some(
      fd => fd !== formGroup && +fd.controls['amount'].value === +formGroup.controls['amount'].value
    )
  }

  isUniqueDateValue(formGroup: UntypedFormGroup): boolean {
    return !this.formData.some(
      fd => {
        const currentDate = fd.value['date'];
        if (!currentDate) {
          return false;
        }
        const selectedDate = formGroup?.controls['date']?.value?.toLocaleDateString();
        return fd !== formGroup && currentDate.toLocaleDateString() === selectedDate;
      }
    )
  }

  validate = () => {
    this.formData.forEach(
      fd => this.dataValidation.validateFormFields(fd)
    )
    return this.formData.every(fd => fd.valid);
  }
}
