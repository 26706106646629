import { BudgetTimeframe } from '../types/timeframe.interface';
import { Budget, BudgetTimeframesType } from '../types/budget.interface';
import { addMonthsToDate, getDiffInDays, getTodaysDateUTC, isLeapYear } from './date.utils';
import { BudgetObjectTotals } from '../types/budget-object-totals.interface';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';

export const defineTimeframe = (timeframes: BudgetTimeframe[], budget: Budget, targetDate: Date = null): BudgetTimeframe => {
  const { type, budget_from } = budget;
  const budgetFromDate = new Date(budget_from);
  const currentDate = targetDate || getTodaysDateUTC();
  budgetFromDate.setUTCDate(1);

  if (type === BudgetTimeframesType.Year && timeframes && timeframes.length === 1) {
    return timeframes[0];
  }

  if (type === BudgetTimeframesType.Quarter && timeframes && timeframes.length === 4) {
    for (let i = 0; i < 4; i++) {
      const nextTf = addMonthsToDate(budgetFromDate, 3 * (i + 1));
      if (nextTf > currentDate) {
        return timeframes[i];
      }
    }
  }

  if (type === BudgetTimeframesType.Month && timeframes && timeframes.length === 12) {
    for (let i = 0; i < 12; i++) {
      const nextTf = addMonthsToDate(budgetFromDate, i + 1);
      if (nextTf > currentDate) {
        return timeframes[i];
      }
    }
  }

  return null;
};

export function getTodayFixedDate(budget: Budget): Date | null {
  return budget?.fixed_date ? parseDateString(budget.fixed_date) : null;
}

/**
 * Get percentage of passed time for the budget year
 */
export const getBudgetYearProgress = (budget: Budget): number => {
  const maxLimit = 100;
  const todayUTC = getTodaysDateUTC();
  const budgetFromDate = new Date(budget.budget_from);
  if (budgetFromDate > todayUTC) {
    return 0;
  }

  const daysInBudgetYear = isLeapYear(budgetFromDate.getUTCFullYear()) ? 366 : 365;
  const daysFromStart = getDiffInDays(todayUTC, budgetFromDate);

  return Math.min(Math.floor(daysFromStart / daysInBudgetYear * 100), maxLimit);
};

/**
 * Check if budget is 'fresh' (started less than 6 weeks ago)
 */
export const isFreshBudget = (budget: Budget): boolean => {
  const todayUTC = getTodaysDateUTC();
  const budgetFromDate = new Date(budget.budget_from);
  const weeksFromStart = getDiffInDays(budgetFromDate, todayUTC, false) / 7;

  return weeksFromStart < 6;
};

export const prepareBudgetObjectTotals = (data: BudgetObjectTotals, isObjectClosed: boolean): BudgetObjectTotals => {
  const preparedTotals = {
    ...data,
    overspend: data.overdue
  };

  if (isObjectClosed) {
    preparedTotals.remaining_allocated = null;
  }

  return preparedTotals;
};

export const getObjectTypeKey = (budget: Budget, key: string): string => {
  return budget?.new_campaigns_programs_structure ? 'object_type' : key;
}

export const sortObjectsByName = (objList: { name: string }[]): void => {
  objList.sort(
    (obj1, obj2) => obj1.name.localeCompare(obj2.name)
  );
}
