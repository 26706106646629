import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DataValidationService {
  constructor(
  ) { }

  uniqueNameValidator(namesArr: string[] = []): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const alreadyTaken = namesArr.includes(control.value?.toLowerCase().trim());
      return alreadyTaken ? { notUniqueName: true } : null;
    }
  }

  requireCheckboxesToBeChecked(minRequired = 1): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      let checked = 0;
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];
        if (control.value === true) {
          checked++;
        }
      });
      return checked < minRequired ? { requireCheckboxesToBeChecked: true } : null;
    };
  }
}
