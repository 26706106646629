import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getRequestOptions } from '../../utils/http-request.utils';
import {
  CompanyUserDO,
  CompanyUserStatus,
  CreatedTeamMate,
  GetCompanyUsersPayload,
  NewTeamMatePayload
} from '../../types/company-user-do.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    companyUser: 'user_company',
    unsubscribeEmail: 'mailing_unsubscribe',
    newTeammate: 'new_teammate',
    sendInvite: 'send_invite',
    setPermissions: 'set_permissions',
    firstLogin: 'first_login',
  };

  createNewTeammate(data: NewTeamMatePayload): Observable<CreatedTeamMate> {
    return this.http.post<CreatedTeamMate>(
      `${this.apiV2Url}${this.apiPaths.companyUser}/${this.apiPaths.newTeammate}/`,
      JSON.stringify(data)
    );
  }

  getCompanyUsers(data: Partial<GetCompanyUsersPayload>): Observable<CompanyUserDO[]> {
    return this.http.get<CompanyUserDO[]>(`${this.apiV2Url}${this.apiPaths.companyUser}/`, getRequestOptions(data));
  }

  updateCompanyUser(userId: number, data: object): Observable<any> {
    return this.http.patch(
      `${this.apiV2Url}${this.apiPaths.companyUser}/${userId}/`,
      JSON.stringify(data)
    );
  }

  unsubscribeUserToEmail(userId: number, hash: string): Observable<any> {
    return this.http.patch(
      `${this.apiV2Url}${this.apiPaths.companyUser}/${userId}/${this.apiPaths.unsubscribeEmail}/`,
      JSON.stringify({hash})
    );
  }

  public trackFirstLogin(companyId: number): Observable<void> {
    return this.http.post<void>(
      `${this.apiV2Url}${this.apiPaths.companyUser}/${this.apiPaths.firstLogin}/`,
      JSON.stringify({ company: companyId })
    );
  }

  /**
   * As per PUP-4282 - we should set 'CompanyUser' as disabled instead of actual deleting
   */
  deleteCompanyUser(userId: number): Observable<any> {
    return this.http.patch(
      `${this.apiV2Url}${this.apiPaths.companyUser}/${userId}/`,
      { status: CompanyUserStatus.Disabled }
    );
  }

  sendInvitation(userId: number): Observable<void> {
    return this.http.post<void>(
      `${this.apiV2Url}${this.apiPaths.companyUser}/${userId}/${this.apiPaths.sendInvite}/`,
      {}
    );
  }

  setAccessPermissions(userId: number, permissions: object): Observable<any> {
    return this.http.post(
      `${this.apiV2Url}${this.apiPaths.companyUser}/${userId}/${this.apiPaths.setPermissions}/`,
      JSON.stringify(permissions)
    );
  }

  switchAccountOwner(currentOwnerCompanyId: number, newOwnerId: number): Observable<{user: number}> {
    return this.http.patch<{user: number}>(
      `${this.apiV2Url}${this.apiPaths.companyUser}/${currentOwnerCompanyId}/switch_account_owner/`,
      JSON.stringify({user: newOwnerId})
    );
  }
}
