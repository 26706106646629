import { Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BudgetAllocationAction, BudgetAllocationActionParams, UndoCallback } from './budget-allocation-action.types';
import { roundDecimal } from 'app/shared/utils/common.utils';
import { BudgetAllocationActionTarget } from '../types/budget-allocation-action-target.interface';

interface TopUpActionParams<TActionDataSource> extends BudgetAllocationActionParams {
  actionTarget: BudgetAllocationActionTarget<TActionDataSource>;
  setAmount: (value: number, dataSource: TActionDataSource) => Observable<boolean> | void;
  getAmount: (dataSource: TActionDataSource) => number;
  remainingBudget?: number;
  compensateFromRemaining?: boolean;
  undoCallbacks?: UndoCallback[];
}

export class BudgetAllocationTopupAction<TGesturesDataSource> extends BudgetAllocationAction<TopUpActionParams<TGesturesDataSource>> {
  private initialValue: number;
  private resultValue: number;

  constructor(params: TopUpActionParams<TGesturesDataSource>) {
    super();
    this.actionParams = params;
    this.prepareActionData();
  }

  private prepareExecuteMessage(surplus: boolean, value: number, currency: string): string {
    if (surplus) {
      return `You have returned <b>${currency} ${this.formatValue(value)}</b> to the remaining budget`;
    }

    return `You have taken <b>[${currency} ${this.formatValue(value)}]</b> from the remaining budget`;
  }

  private prepareActionData() {
    const { actionTarget, currency, getAmount, compensateFromRemaining = false, remainingBudget = 0 } = this.actionParams;
    const { difference, surplus, dataSource } = actionTarget;
    const allowedCompensation = compensateFromRemaining
      ? Math.min(difference, remainingBudget)
      : difference;

    this.initialValue = getAmount(dataSource);
    if (surplus) {
      this.resultValue = roundDecimal(this.initialValue - difference, 2);
      this.onExecutedMessage = this.prepareExecuteMessage(surplus, difference, currency);
    } else {
      this.resultValue = roundDecimal(this.initialValue + allowedCompensation, 2);
      this.onExecutedMessage = this.prepareExecuteMessage(surplus, allowedCompensation, currency);
    }
  }

  public execute(): void {
    const { setAmount, actionTarget: { dataSource } } = this.actionParams;

    if (!setAmount || dataSource == null) {
      return;
    }

    const setAmountResult = setAmount(this.resultValue, dataSource) || of(true);

    setAmountResult
      .pipe(
        filter(value => value != null),
        take(1)
      )
      .subscribe(
        result => {
          if (!result) {
            return;
          }
          this.onExecuted?.(
            this.onExecutedMessage,
            this.undo.bind(this)
          );
        }
      );
  }

  public undo(viaSnackbar?: boolean): void {
    const { setAmount, actionTarget: { dataSource }, undoCallbacks } = this.actionParams;

    if (!setAmount || dataSource == null) {
      return;
    }

    const setAmountResult = setAmount(this.initialValue, dataSource) || of(true);

    setAmountResult
      .pipe(
        filter(value => value != null),
        take(1)
      )
      .subscribe(
        result => {
          if (!result) {
            return;
          }
          if (this.onUndone) {
            this.onUndone(viaSnackbar);
          }
          this.runCallbacks(undoCallbacks).subscribe();
        }
      );
  }
}
