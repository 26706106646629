import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HierarchySelectConfig, HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { createDeepCopy, createRegExpFromString } from '@shared/utils/common.utils';
import { filterItemsByPattern } from '@shared/components/hierarchy-select/hierarchy-select.utils';
import { GroupToggleEvent } from '@shared/components/hierarchy-select/hierarchy-options-list/hierarchy-options-list.component';

@Component({
  selector: 'hierarchy-single-select',
  templateUrl: './hierarchy-single-select.component.html',
  styleUrls: ['./hierarchy-single-select.component.scss']
})
export class HierarchySingleSelectComponent {
  protected groupsOpenState: Record<string, boolean> = {};
  protected groupsTotalAmount: number;
  protected allSelected = false;
  public allItems: HierarchySelectItem[] = [];
  public activeItems: HierarchySelectItem[] = [];

  @Input() selectedOption: HierarchySelectItem;
  @Input() selectConfig: HierarchySelectConfig;
  @Input() allowGroupSelection: boolean;
  @Input() allowUnselect = true;
  @Input() showBackdrop: boolean;
  @Input() panelCssClass = '';
  @Output() selectOption = new EventEmitter<HierarchySelectItem>();
  @Output() backdropClick = new EventEmitter<void>();

  @Input() set optionList (options: HierarchySelectItem[]) {
    this.allItems = options || [];
    this.activeItems = this.allItems;
  };

  protected onSelectOption(option: HierarchySelectItem): void {
    const activeItemClicked = option.id === this.selectedOption?.id;
    if (activeItemClicked && !this.allowUnselect) {
      this.backdropClick.emit();
      return;
    }
    this.selectOption.emit(activeItemClicked ? null : option);
  }

  protected onBackdropClick(): void {
    this.backdropClick.emit();
  }

  protected toggleGroup(event: GroupToggleEvent): void {
    const { collapsed, id } = event;
    if (collapsed) {
      delete this.groupsOpenState[id];
    } else {
      this.groupsOpenState[id] = true;
    }
    this.allSelected = this.groupsTotalAmount === Object.keys(this.groupsOpenState).length;
  }

  protected updateActiveItems(filterText: string): void {
    if (filterText.length > 2) {
      const pattern = createRegExpFromString(filterText, 'i');
      const itemsCopy = createDeepCopy(this.allItems);
      this.activeItems = filterItemsByPattern(itemsCopy, pattern);
    } else {
      this.activeItems = this.allItems;
    }
  }
}
