import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DIALOG_ACTION_TYPE } from 'app/shared/types/dialog-context.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { CompanyService } from 'app/shared/services/backend/company.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';

@Component({
  selector: 'disable-sso-dialog',
  templateUrl: './disable-sso-dialog.component.html',
  styleUrls: ['./disable-sso-dialog.component.scss']
})
export class DisableSsoDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly context: any,
    private readonly utilityService: UtilityService,
    private readonly companyService: CompanyService,
    private readonly companyDataService: CompanyDataService,
  ) {}

  ngOnInit(): void {
    this.context.actions = [
      {
        label: 'Disable All SSO Users',
        type: DIALOG_ACTION_TYPE.STROKED,
        handler: () => {
          this.disableSSO();
        },
        disabled: true
      },
      {
        label: 'Cancel',
        type: DIALOG_ACTION_TYPE.FLAT,
        handler: () => {},
      }
    ];
  }

  private disableSSO() {
    const { companyId } = this.context;

    this.companyService.disableSSO(companyId)
      .subscribe({
        next: () => this.companyDataService.loadSelectedCompanyDetails(companyId),
        error: (err) => this.utilityService.handleError(err)
      });
  }

  public handleAgreementToggle($event: MatCheckboxChange) {
    this.context.actions.forEach(action => {
      if (action.label === 'Disable All SSO Users') {
        action.disabled = !$event.checked;
      }
    });
  }
}
