import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnInit
} from "@angular/core";
import { ExpensePageDrawerService } from "@spending/services/expense-page-drawer.service";
import { ExpenseDetailsState } from "app/budget-object-details/types/budget-object-details-state.interface";
import { takeUntil } from 'rxjs/operators';
import { createDateString } from '../components/containers/campaign-details/date-operations';
import { Subject } from 'rxjs';
import { OnDestroy, AfterViewInit } from '@angular/core';

@Directive({
  selector: "[highlightField]",
})
export class HighlightFieldDirective implements AfterViewInit, OnDestroy, OnChanges {
  @HostBinding("class.highlight-field") hasHighlight = false;

  @Input() values: (number | string)[];
  @Input() fields: string[];
  @Input() isSplitedInput = false;

  initialExpenseDetailsFields: Partial<ExpenseDetailsState> | null;
  private readonly destroy$ = new Subject<void>();

  constructor(private expensePageDrawerService: ExpensePageDrawerService) {}

  arraysToObject(
    fields: string[],
    values: (number | string)[]
  ): Partial<ExpenseDetailsState> {
    return fields.reduce((acc, key, index) => {
      return { ...acc, [key]: values[index] };
    }, {});
  }

  ngOnChanges(): void {
    if (this.initialExpenseDetailsFields) {
      const normalizedValues = [...this.values].map(this.transformDate);
      const currentValueMap = this.arraysToObject(this.fields, normalizedValues);
      this.hasHighlight = this.isSplitedInput
        ? Object.entries(currentValueMap).some(
            this.compareHandler.bind(null, this.initialExpenseDetailsFields)
          )
        : Object.entries(currentValueMap).every(
            this.compareHandler.bind(null, this.initialExpenseDetailsFields)
          );
    }
  }

  compareHandler(initialFields: Partial<ExpenseDetailsState>, pair: [string, string | number]): boolean {
    return (pair[0] in initialFields) && initialFields[pair[0]] == pair[1];
  }

  transformDate(value: Date | number | string): string | number {
    if (!(value instanceof Date)) {
      return value;
    }
    return createDateString(value);
  }

  ngAfterViewInit(): void {
    this.expensePageDrawerService.initialExpenseDetailsFields$
      .pipe(takeUntil(this.destroy$))
      .subscribe((fields) => (this.initialExpenseDetailsFields = fields));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
