import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import {
  MetricValueUpdateItem,
  MetricValueUpdatesData,
  MetricValueUpdateType
} from 'app/budget-object-details/types/metric-value-update-item.interface';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { createDateString } from '../../containers/campaign-details/date-operations';
import {
  createEmptyRecordData,
  createMonthTotalRecordData,
  getMonthKey,
  getOriginObjectTooltip,
  trackByObjectData
} from './metric-value-editor.utils';
import { MetricUpdateEvent } from './metric-value-record/metric-value-record.type';
import { MetricMappingCalculationService } from '../../../services/metric-mapping-calculation.service';

@Component({
  selector: 'metric-value-editor',
  templateUrl: './metric-value-editor.component.html',
  styleUrls: ['./metric-value-editor.component.scss']
})
export class MetricValueEditorComponent implements OnInit, OnChanges {
  public metricValueUpdateType = MetricValueUpdateType;
  public trackByObjectData = trackByObjectData;
  public recordOnCreation: MetricValueUpdateItem;
  public currentMonthTotal: MetricValueUpdateItem;
  public datepickerDisabledDates: string[] = [];
  public editorStructuredData: MetricValueUpdatesData = [];
  public readonly disabledHistoryTooltip = 'At least 2 records required';

  @Input() historyRecords: MetricValueUpdatesData;
  @Input() originUpdateType: MetricValueUpdateType;
  @Input() originObjectId: number;
  @Input() lastUpdatedInfo: string;
  @Input() isReadOnly;
  @Input() displayDecimal = false;
  @Input() budgetTodayDate: Date;
  @Input() todayDateString: string;
  @Input() currentMonthKey: string;
  @Input() showHistoryExpanded: boolean = false;

  @Output() addRecord = new EventEmitter<MetricValueUpdateItem>();
  @Output() updateRecord = new EventEmitter<MetricValueUpdateItem>();
  @Output() removeRecord = new EventEmitter<MetricValueUpdateItem>();

  @ViewChild('metricHistory', { static: true }) metricHistory: MatExpansionPanel;

  constructor(private appRoutingService: AppRoutingService) { }

  get originObjectTooltip() {
    return getOriginObjectTooltip(this.originUpdateType);
  }

  ngOnInit() {
    if (this.showHistoryExpanded) {
      this.metricHistory.open();
    }
    this.recordOnCreation = createEmptyRecordData(this.originUpdateType);
    this.currentMonthTotal = createEmptyRecordData(MetricValueUpdateType.monthTotal);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.historyRecords || changes.currentMonthKey) {
      this.processFlatMetricUpdates(this.historyRecords);
    }
  }

  public receiveUpdates(updateEvent: MetricUpdateEvent, isCreation = false) {
    const record = updateEvent.value;
    if (isCreation) {
      this.onCreateRecord(record)
    } else {
      if (record.type === MetricValueUpdateType.monthTotal) {
        this.updateMonthTotal(updateEvent);
      } else {
        this.updateRecord.emit(record)
      }
    }
  }

  private updateMonthTotal(updateEvent: MetricUpdateEvent) {
    const record = updateEvent.value;
    const prevValue = updateEvent.prevValue;
    let changeInValueDiff = record.changeInValue - prevValue.changeInValue;
    let actualRunningTotal = record.runningTotal + changeInValueDiff;

    this.updateMonthLastDayRecord({
      ...record, runningTotal: actualRunningTotal
    })
  }

  private updateMonthLastDayRecord(monthTotal: MetricValueUpdateItem) {
    const isCurrentMonth = getMonthKey(monthTotal.date) === this.currentMonthKey,
      dataArray = isCurrentMonth ? this.editorStructuredData : monthTotal.monthChildItems,
      recordToUpdDate = isCurrentMonth ? this.todayDateString : monthTotal.date;
    const lastItem = dataArray.length ? dataArray[dataArray.length - 1] : null;
    const doesLastItemMatchOrigin = MetricMappingCalculationService.doesUpdateItemMatchOriginObject(lastItem, {
      id: this.originObjectId,
      updateType: this.originUpdateType
    });
    const doesLastItemMatchTargetDate = lastItem && lastItem.date === recordToUpdDate;

    if (doesLastItemMatchTargetDate && doesLastItemMatchOrigin) {
      this.updateRecord.emit({ ...lastItem, runningTotal: monthTotal.runningTotal })
    } else {
      const recordToCrt = {
        ...createEmptyRecordData(this.originUpdateType),
        date: recordToUpdDate,
        runningTotal: monthTotal.runningTotal
      };
      this.addRecord.emit(recordToCrt);
    }
  }

  private onCreateRecord(eventData) {
    const { date, changeInValue, runningTotal } = eventData;
    if (date && (changeInValue || runningTotal)) {
      this.addRecord.emit(eventData);
      this.recordOnCreation = createEmptyRecordData(this.originUpdateType);
    }
  }

  public openObjectDetailsPage(record: MetricValueUpdateItem) {
    if (record.type === MetricValueUpdateType.campaign) {
      this.appRoutingService.openCampaignMetricDetails(record.metricMapping);
    }
  }

  private processFlatMetricUpdates(flatMetricUpdates: MetricValueUpdatesData) {
    if (!this.todayDateString || !flatMetricUpdates) {
      return;
    }
    const { endedMonths, disabledDates } = this.processRecordsDates(flatMetricUpdates);
    this.datepickerDisabledDates = disabledDates;
    this.editorStructuredData = this.createStructuredData(flatMetricUpdates, endedMonths);
    this.setCurrentMonthTotal();
    if (!this.editorStructuredData.length && this.metricHistory.expanded) {
      this.metricHistory.close();
    }
  }

  private setCurrentMonthTotal() {
    let monthChangeInValue = 0, currentRunningTotal = 0;
    const records = this.editorStructuredData,
      lastIndex = records.length ? records.length - 1 : null,
      monthItems = records.filter(item => item.type !== MetricValueUpdateType.monthTotal);
    if (lastIndex != null) {
      currentRunningTotal = records[lastIndex].runningTotal;
      monthChangeInValue = this.calcMonthChangeInValue(monthItems);
    }
    this.currentMonthTotal = {
      ...createMonthTotalRecordData(monthChangeInValue, currentRunningTotal, this.currentMonthKey),
      updatedDate: MetricMappingDetailsService.getLastUpdatedDate(monthItems, this.originUpdateType, this.originObjectId)
    };
  }

  private processRecordsDates(records: MetricValueUpdatesData) {
    const endedMonths = {}, disabledDates = [];
    records.forEach((item) => {
      const monthKey = getMonthKey(item.date);
      endedMonths[monthKey] = [];
      if (MetricMappingCalculationService.doesUpdateItemMatchOriginObject(
        item,
        { id: this.originObjectId, updateType: this.originUpdateType })
      ) {
        disabledDates.push(item.date);
      }
    })
    return { endedMonths, disabledDates }
  }

  private createStructuredData(records: MetricValueUpdatesData, endedMonths): MetricValueUpdatesData {
    const structuredData = [];

    records.forEach((record, index) => {
      const { runningTotal, date } = record,
        isLastItem = index === records.length - 1,
        monthKey = getMonthKey(date);
      if (this.currentMonthKey !== monthKey) {
        const monthItems = endedMonths[monthKey];
        const switchMonth = isLastItem || monthKey !== getMonthKey(records[index + 1].date);
        monthItems.push({ ...record })
        if (switchMonth) {
          const monthChangeInValue = this.calcMonthChangeInValue(monthItems);
          const monthTotal = {
            ...createMonthTotalRecordData(monthChangeInValue, runningTotal, monthKey),
            updatedDate: MetricMappingDetailsService.getLastUpdatedDate(monthItems, this.originUpdateType, this.originObjectId),
            monthChildItems: monthItems
          }
          structuredData.push(monthTotal);
        }
      } else {
        structuredData.push({ ...record });
      }
    })
    return structuredData;
  }

  private calcMonthChangeInValue(monthItems: MetricValueUpdatesData) {
    if (!monthItems.length) {
      return 0;
    }
    const firstChildChangeIn = monthItems[0].changeInValue || 0;
    if (monthItems.length === 1) {
      return firstChildChangeIn;
    } else {
      const lastIndex = monthItems.length - 1;
      return firstChildChangeIn + (monthItems[lastIndex].runningTotal - monthItems[0].runningTotal)
    }
  }
}
