import { Component, Inject, OnDestroy } from '@angular/core';
import { Validations } from '../../../app.validations';
import { UtilityService } from '../../services/utility.service';
import { ExpenseTypeService } from 'app/shared/services/backend/expense-type.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Configuration } from 'app/app.constants';
import { DataValidationService } from 'app/shared/services/data-validation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ExpenseTypeDialogContext {
  company_id: number;
  expenseType: any;
  expenseNames: string[];
}

@Component({
  selector: 'modal-content',
  styleUrls: ['./expense-type-dialog.component.scss'],
  templateUrl: './expense-type-dialog.component.html'
})
export class ExpenseTypeDialogComponent implements OnDestroy {
  subscriptions: any[] = [];
  company_id: any;
  isPopupLoading = false;
  expenseTypeData = <any>{};
  expenseTypeControl: UntypedFormControl;
  pageTitle: string;
  MAX_TEXT_INPUT_LENGTH = this.config.MAX_TEXT_INPUT_LENGTH;

  constructor(
    protected readonly validations: Validations,
    private readonly utilityService: UtilityService,
    private readonly expenseTypeService: ExpenseTypeService,
    private readonly config: Configuration,
    private readonly dataValidation: DataValidationService,
    private readonly dialogRef: MatDialogRef<ExpenseTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: ExpenseTypeDialogContext
  ) {
    this.expenseTypeData = data.expenseType;
    this.pageTitle = (this.expenseTypeData ? 'Edit' : 'Add New') + ' Expense Type';
    this.company_id = this.data?.company_id;

    this.initNameControl(this.expenseTypeData?.name);
    // Show popup loader
    this.subscriptions.push(this.utilityService.popupLoadingPropertyChanged$.subscribe(
      value => setTimeout(() => this.isPopupLoading = value, 0)
    ));
  }

  initNameControl(name: string = null) {
    const validators = [
      Validators.required,
      Validators.maxLength(this.MAX_TEXT_INPUT_LENGTH),
      this.dataValidation.uniqueNameValidator(this.data?.expenseNames),
    ];
    this.expenseTypeControl = new UntypedFormControl(name, { validators });
  }

  onSubmit() {
    if (this.expenseTypeControl.invalid) {
      return;
    }
    const data = {
      name: this.expenseTypeControl.value,
      company: this.company_id
    };
    this.expenseTypeData ? this.updateExpenseType(data) : this.addExpenseType(data);
  }

  updateExpenseType(data) {
    this.utilityService.showPopupLoading(true);
    this.subscriptions.push(this.expenseTypeService.updateExpenseType(this.expenseTypeData.id, data).subscribe(
      () => this.onExpenseTypeUpdated('Expense Type Updated Successfully.'),
      error => this.onHandleError(error)
    ));
  }

  addExpenseType(data) {
    this.utilityService.showPopupLoading(true);
    data.is_custom = true;
    this.subscriptions.push(this.expenseTypeService.addExpenseType(data).subscribe(
      () => this.onExpenseTypeUpdated('Expense Type Added Successfully.'),
      error => this.onHandleError(error)
    ));
  }

  onExpenseTypeUpdated(msg: string) {
    this.utilityService.showToast({ Title: '', Message: msg, Type: 'success' });
    this.dialogRef.close();
    this.utilityService.showExpenseTypeList(true);
    this.utilityService.showPopupLoading(false);
  }

  onHandleError(error) {
    if (error.hasOwnProperty('message') || error.hasOwnProperty('detail')) {
      this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
      this.utilityService.showPopupLoading(false);
      this.utilityService.showLoading(false);
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
