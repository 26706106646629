import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  HostListener, inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { Validations } from 'app/app.validations';
import { BudgetTimeframeDataItem, WidgetService } from './widget.service';
import { SmallNumber } from 'app/shared/pipes/smallnumber.pipe';
import { ExportDataService } from './export-data.service';
import { FilterManagementService, ParamsDef } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { BudgetDataService } from './budget-data/budget-data.service';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { Goal } from 'app/shared/types/goal.interface';
import { UserDataService } from 'app/shared/services/user-data.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { combineLatest, Subscription } from 'rxjs';
import { BudgetAmountsSummaryComponent } from './budget-amounts-summary/budget-amounts-summary.component';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { UserManager } from 'app/user/services/user-manager.service';
import { BudgetAmountsSummaryService } from 'app/shared/services/budget-amounts-summary.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { AppDataLoader } from 'app/app-data-loader.service';
import { ActionMenuItem } from 'app/shared/components/actions-menu/actions-menu.component';
import { DashboardWidgetType, DashboardWidgetViewMode } from './widget.types';
import { LightCampaign } from '@shared/types/campaign.interface';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { HttpStatusCode } from '@angular/common/http';
import { CompanyService } from '@shared/services/backend/company.service';
import { BudgetCegTimelineService } from '@manage-ceg/services/budget-ceg-timeline.service';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';
import { ManageCegPageModeService } from '@manage-ceg/services/manage-ceg-page-mode.service';
import { ManageCegTableConfigurationService } from '@manage-ceg/services/manage-ceg-table-configuration.service';
import { RecordInteractionService } from '@manage-ceg/services/record-interaction.service';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { ManageCegTableActionsMenuService } from '@manage-ceg/services/manage-ceg-table-actions-menu.service';
import { MiniDashCegCalculationService } from '@manage-ceg/services/mini-dash-calculation.service';
import { ManageCegTableDataMutationService } from '@manage-ceg/services/manage-ceg-table-data-mutation.service';
import { ManageCegAllocationService } from '@manage-ceg/services/manage-ceg-allocation.service';
import { ManageTableActionHistoryService } from '@shared/services/manage-table-action-history.service';
import { ManageCegDataValidationService } from '@manage-ceg/services/manage-ceg-data-validation.service';
import { ExpenseCostAdjustmentDataService } from 'app/metric-integrations/expense-cost-adjustment/expense-cost-adjustment-data.service';
import { ManageCegPageRowIntersectionService } from '@manage-ceg/services/manage-ceg-page-row-intersection.service';

@Component({
  selector: 'app-root',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  providers: [
    AppDataLoader,
    ManageCegPageService,
    ManageCegTableDataService,
    ManageCegPageModeService,
    BudgetCegTimelineService,
    ManageCegTableConfigurationService,
    RecordInteractionService,
    BudgetAllocationActionsService,
    ManageCegTableActionsMenuService,
    MiniDashCegCalculationService,
    ManageCegTableDataMutationService,
    ManageCegAllocationService,
    ManageTableActionHistoryService,
    ManageCegDataValidationService,
    ExpenseCostAdjustmentDataService,
    ManageCegPageRowIntersectionService,
  ]
})
export class Widget implements OnInit, OnDestroy {
  private readonly configuration = inject(Configuration);
  private readonly utilityService = inject(UtilityService);
  private readonly widgetService = inject(WidgetService);
  private readonly router = inject(Router);
  protected readonly validations = inject(Validations);
  private readonly smallNumber = inject(SmallNumber);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly userDataService = inject(UserDataService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly componentResolver = inject(ComponentFactoryResolver);
  private readonly userManager = inject(UserManager);
  private readonly budgetAmountsSummaryService = inject(BudgetAmountsSummaryService);
  private readonly titleCasePipe = inject(TitleCasePipe);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly appDataLoader = inject(AppDataLoader);
  private readonly companyService = inject(CompanyService);

  private full_months = this.configuration.full_month || [];

  @ViewChild('spendByType') spendByType;
  @ViewChild('budgetAmountsTooltip', { read: ViewContainerRef, static: true }) budgetAmountsTooltipContainer;
  budgetAmountsTooltipRef: ComponentRef<BudgetAmountsSummaryComponent> = null;
  budgetAmountsTooltipStyles = {};
  budgetAmountsTooltipClass = null;
  colors = {
    'available': '#A6D7A7',
    'closed': '#323232',
    'committed': '#4392e4',
    'planned': '#7c4dff',
    'overdue': '#FF5722',
    'under_budget': '#FFCB7F',
    'reserved': '#BABABA'
  };
  companyId: number;
  companyCurrency: { code: string; symbol: string };
  companyCode: string;
  selectedBudget: Budget;
  budgetTimeframeList: BudgetTimeframe[] = [];
  budgetSegmentList: BudgetSegmentAccess[] = [];
  goalList: Goal[] = [];
  allCampaigns: LightCampaign[];
  selectedTimeframeNames: string[] = [];
  selectedSegmentNames: string[] = [];
  selectedGoals: Goal[] = [];
  selectedGoalNames: string[] = [];

  selectedStatusNames = [];

  showEmptyMsg = false;
  changeTrigger = 0;
  timeframeGraphData: BudgetTimeframeDataItem[] = [];
  subscriptions: any[] = [];
  editPermission = false;
  isLoading = false;
  isAdmin = false;
  budgetDates = <any>{};
  bars = [];
  timelineGraphPercentage = [];
  todayPercentage = 0;
  todayLineShow = false;
  currentMonth = '';
  currentQuarter = '';
  spendTypeGraphData = [];
  columnChartData: any = {
    chartType: 'ColumnChart',
    dataTable: [],
    options: {
      series: [
        { color: this.colors.closed },
        { color: this.colors.committed },
        { color: this.colors.planned },
        { color: this.colors.reserved },
        { color: this.colors.available }
      ],
      backgroundColor: { fill: 'transparent' },
      legend: { position: 'none' },
      height: 150,
      vAxis: {
          gridlines: {
              color: 'transparent'
          },
          baselineColor: 'transparent',
          format: 'short',
          textPosition: 'out'
      },
      animation: {
        duration: 300,
        easing: 'out',
        startup: true
      },
      hAxis: {
        gridlines: {
              color: 'transparent'
          },
          textPosition: 'none'
      },
          isStacked: true,
          chartArea: { left: 60, top: 20, width: '90%', height: '80%' }
    }
  };
  budgetGraphData = <any>{};
  timelineBarPercentage = [];
  showSpendByType = true;
  timelineBarLockedStatus: any = [];
  expandRow = [];
  currentFilters: FilterSet = {};

  timelineGraphDataSubscription: Subscription;
  hierarchyGraphDataSubscription: Subscription;
  spendByTypeGraphDataSubscription: Subscription;

  timelineWidgetActionItems: ActionMenuItem[] = [
    {
      text: 'Edit Budget',
      value: 'editBudget',
      cssClass: 'edit',
      onClick: this.redirectToSetting.bind(this),
      forTableView: true,
      forChartView: true
    },
    {
      text: 'Export Data',
      value: 'exportData',
      cssClass: 'export',
      onClick: this.exportBudgetTimeline.bind(this),
      forTableView: true,
      forChartView: false
    },
    {
      text: 'Download Image',
      value: 'downloadImage',
      cssClass: 'export',
      onClick: this.downloadBudgetTimelineImage.bind(this),
      forTableView: false,
      forChartView: true
    },
  ];

  hierarchyWidgetActionItems: ActionMenuItem[] = [
    {
      text: 'Export Data',
      value: 'exportData',
      cssClass: 'export',
      onClick: this.exportBudgetHierarchy.bind(this),
      forTableView: true,
      forChartView: false
    }
  ];

  spendByTypeWidgetActionItems: ActionMenuItem[] = [
    {
      text: 'Export Data',
      value: 'exportData',
      cssClass: 'export',
      onClick: this.exportSpendByType.bind(this),
      forTableView: true,
      forChartView: false
    },
    {
      text: 'Download Image',
      value: 'downloadImage',
      cssClass: 'export',
      onClick: this.downloadSpendByTypeImage.bind(this),
      forTableView: false,
      forChartView: true
    }
  ];

  public widgetViewModeByType = {
    [DashboardWidgetType.SpendingTimeline]: DashboardWidgetViewMode.Chart,
    [DashboardWidgetType.SpendByType]: DashboardWidgetViewMode.Table,
    [DashboardWidgetType.BudgetHierarchy]: DashboardWidgetViewMode.Table
  };
  public WidgetViewMode = DashboardWidgetViewMode;
  public WidgetType = DashboardWidgetType;
  public FilterName = FilterName;
  public cegStatusEnabled: boolean;

  static deepCopyJSONObject(sourceObj) {
    return JSON.parse(JSON.stringify(sourceObj));
  }

  ngOnInit() {
    this.initWidgetViewModes();

    this.subscriptions.push(
      this.userManager.currentCompanyUser$
        .pipe(filter(user => user != null))
        .subscribe(user => this.setUserData(user))
    );

    const company$ =
      this.companyDataService.selectedCompanyDO$.pipe(
        filter(cmp => cmp != null),
        tap(company => {
          this.companyId = company.id;
          this.companyCurrency = {
            code: company.currency,
            symbol: company.currency_symbol,
          }
          this.companyDataService.loadCompanyData(this.companyId, error => this.handleError(error));
        }),
        switchMap(company => this.companyService.getCompanyInfo(company.id))
      );

    this.subscriptions.push(
      company$.pipe(catchError(error => {
        this.utilityService.handleError(error);
        return company$;
      })).subscribe(response => this.success(response, 'getCompanyInfo'))
    );

    this.subscriptions.push(this.budgetDataService.selectedBudget$.subscribe(
      newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)
    ));

    this.subscriptions.push(this.userDataService.editPermission$.subscribe(
      editPermission => this.editPermission = editPermission
    ));

    const budgetData$ = combineLatest([
      this.budgetDataService.timeframeList$.pipe(
        tap(tfList => {
          this.budgetTimeframeList = tfList;
          this.updateSelectedTimeframeNames();
        })
      ),
      this.budgetDataService.segmentList$.pipe(
        tap(segmentList => {
          this.budgetSegmentList = segmentList;
          this.updateSelectedSegmentNames();
        })
      ),
      this.budgetDataService.goalList$.pipe(
        tap(
          goalList => {
            this.goalList = goalList;
            this.updateSelectedGoals();
          })
      ),
      this.budgetDataService.lightCampaignList$.pipe(
        tap(campaignList => this.allCampaigns = campaignList)
      ),
      this.filterManagementService.budgetFiltersList$.pipe(
        switchMap(() => this.filterManagementService.budgetFiltersInit$),
        switchMap(() => this.filterManagementService.currentFilterSet$),
        tap(filterSet => {
          this.currentFilters = { ...filterSet };
          this.updateSelectedStatusNames();
          this.updateSelectedTimeframeNames();
          this.updateSelectedSegmentNames();
          this.updateSelectedGoals();
          this.appRoutingService.updateCurrentFiltersInRouting(
            this.companyId,
            this.selectedBudget.id,
            this.currentFilters);
        })
      )]);

    this.subscriptions.push(
      this.budgetDataService.selectedBudget$.pipe(
        switchMap(() => budgetData$))
        .subscribe(
          {
            next: () => this.loadDashboardData(),
            error: (error) => this.handleError(error)
          }
        )
    );

    // Reload dashboard on expense changes - to make sure all total are up-to-date
    this.subscriptions.push(
      this.budgetObjectDetailsManager.budgetObjectChanged$.subscribe(
        (change) =>
          change && change.objectType === this.configuration.OBJECT_TYPES.expense && this.loadDashboardData()
      )
    );

    this.appDataLoader.init(false);
  }

  private setUserData(currentCompanyUser: CompanyUserDO) {
    this.isAdmin = currentCompanyUser.is_admin;
  }

  updateSelectedTimeframeNames() {
    this.selectedTimeframeNames =
      (this.currentFilters[FilterName.Timeframes] || [])
        .map(tfId => {
          const timeframe = this.budgetTimeframeList.find(tf => tf.id === tfId);
          return timeframe && timeframe.name;
        });
  }

  updateSelectedSegmentNames() {
    this.selectedSegmentNames =
      (this.currentFilters[FilterName.Segments] || [])
        .map(segId => {
          const segment = this.budgetSegmentList.find(seg => seg.id === segId);
          return segment && segment.name;
        });
  }

  updateSelectedGoals() {
    this.selectedGoals =
      this.goalList.filter(
        goal => this.currentFilters[FilterName.Goals] != null && this.currentFilters[FilterName.Goals].includes(goal.id)
      );
    this.selectedGoalNames = this.selectedGoals.map(goal => goal.name);
  }

  private updateSelectedStatusNames() {
    const statusNames = this.currentFilters[FilterName.Statuses] || [];
    this.selectedStatusNames = statusNames.map(
      status => (status as string).toLowerCase().replace(/\s+/g, '_')
    );
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    const prevBudgetId = this.selectedBudget ? this.selectedBudget.id : null;
    const newBudgetId = newSelectedBudget ? newSelectedBudget.id : null;
    if (prevBudgetId && newBudgetId && prevBudgetId !== newBudgetId) {
      this.resetDashboardData();
      this.filterManagementService.updateCurrentFilterSet();
    }
    this.selectedBudget = newSelectedBudget;
    this.cegStatusEnabled = !!this.selectedBudget?.new_campaigns_programs_structure;
    this.setWidgetViewMode(DashboardWidgetType.SpendingTimeline, !this.cegStatusEnabled);
    if (newSelectedBudget != null) {
      this.showEmptyMsg = false;

      this.budgetDates = {
        from: this.selectedBudget.budget_from,
        to: this.selectedBudget.budget_to
      };

      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.campaignStatusNames.active,
        error => this.handleError(error)
      );

      this.budgetDataService.loadPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.programStatusNames.active,
        error => this.handleError(error)
      );

      this.utilityService.showLoading(true);
    } else {
      this.showEmptyMsg = true;
    }
  }

  loadDashboardData() {
    this.expandRow = [];
    this.utilityService.showLoading(true);
    this.resetDashboardData();

    this.getTimeframeGraphDetails();
    this.getSpendByTypeGraphDetails();
    if (!this.cegStatusEnabled) {
      this.getBudgetGraphData();
    }
  }

  getTimeframeGraphDetails() {
     this.utilityService.showLoading(true);

    this.timelineGraphDataSubscription?.unsubscribe();

    const params = this.getTimelineRequestParams(false);
    this.timelineGraphDataSubscription =
      this.widgetService.getTimeframeGraphDetails(params).subscribe(
        data => this.success(data, 'getTimeframeGraphDetails'),
        error => this.handleError(error)
      );
    this.subscriptions.push(this.timelineGraphDataSubscription);
  }

  downloadTimelineCsvData() {
    const params = this.getTimelineRequestParams(true);
    this.subscriptions.push(this.widgetService.getTimeframeCsvData(params).subscribe(
      data => ExportDataService.downloadCsv(data, 'spending_timeline.csv'),
      error => this.handleError(error)
    ));
  }

  private getTimelineRequestParams(isCsv: boolean) {
    const allBudgetTfIds = this.budgetTimeframeList.map(tf => tf.id);
    const requestParamsDef: ParamsDef = {
      'budget': { defaultValue: () => this.selectedBudget.id },
      'allocations':
        isCsv ?
          {filterName: FilterName.Timeframes, defaultValue: () => allBudgetTfIds} :
          {defaultValue: () => allBudgetTfIds},
      'statuses': isCsv ? {filterName: FilterName.Statuses} : null,
      'segment1': {
        filterName: FilterName.Segments,
        defaultValue: () => this.filterManagementService.getDefaultSegments(this.budgetSegmentList)
      },

      'goal_ids': {filterName: FilterName.Goals},
      'tag_ids': {filterName: FilterName.Tags},
      'owner_ids': {filterName: FilterName.Owners},
      'campaign_ids': {defaultValue: () => this.filterManagementService.getFilterCampaignIds(this.allCampaigns)},
      'campaign_type_ids': {filterName: FilterName.CampaignTypes},
      'program_ids': {filterName: FilterName.ExpenseBuckets},
      'expense_type_ids': {filterName: FilterName.ExpenseTypes},
      'gl_code_ids': {filterName: FilterName.GlCodes},
      'vendor_ids': {filterName: FilterName.Vendors},
      'split_rule_ids': {filterName: FilterName.SharedCostRules},
      'source': {filterName: FilterName.ExpenseSource},
      'metric_ids': { filterName: FilterName.Metrics },
      'po_numbers': { filterName: FilterName.PONumber },
      'amount_status': { filterName: FilterName.CEGStatus }
    };

    const data = {};
    this.filterManagementService.setParamsFromFilters(data, requestParamsDef, true, undefined, true);
    return data;
  }

  getSpendByTypeGraphDetails() {
    this.utilityService.showLoading(true);

    this.spendByTypeGraphDataSubscription?.unsubscribe();

    const params = this.getSpendByTypeRequestParams(false);
    this.spendByTypeGraphDataSubscription =
      this.widgetService.getSpendByTypeGraphDetails(params).subscribe(
        data => this.success(data, 'getSpendByTypeGraphDetails'),
        error => this.handleError(error)
      );
    this.subscriptions.push(this.spendByTypeGraphDataSubscription);
  }

  downloadSpendByTypeCsvData() {
    const params = this.getSpendByTypeRequestParams(true);
    this.subscriptions.push(this.widgetService.getSpendByTypeCsvData(params).subscribe(
      data => ExportDataService.downloadCsv(data, 'spend_by_type.csv'),
      error => this.handleError(error)
    ));
  }

  private getSpendByTypeRequestParams(isCsv: boolean) {
    const requestParamsDef: ParamsDef = {
      'budget': { defaultValue: () => this.selectedBudget.id },
      'allocations': { filterName: FilterName.Timeframes, defaultValue: () => this.budgetTimeframeList.map(tf => tf.id) },
      'segment1': {
        filterName: FilterName.Segments,
        defaultValue: () => this.filterManagementService.getDefaultSegments(this.budgetSegmentList)
      },
      'goal_ids': { filterName: FilterName.Goals },
      'statuses': isCsv ? { filterName: FilterName.Statuses } : null,

      'tag_ids': { filterName: FilterName.Tags },
      'owner_ids': { filterName: FilterName.Owners },
      'campaign_ids': { defaultValue: () => this.filterManagementService.getFilterCampaignIds(this.allCampaigns) },
      'campaign_type_ids': { filterName: FilterName.CampaignTypes },
      'program_ids': { filterName: FilterName.ExpenseBuckets },
      'expense_type_ids': { filterName: FilterName.ExpenseTypes},
      'gl_code_ids': { filterName: FilterName.GlCodes },
      'vendor_ids': { filterName: FilterName.Vendors },
      'split_rule_ids': { filterName: FilterName.SharedCostRules} ,
      'source': { filterName: FilterName.ExpenseSource },
      'metric_ids': { filterName: FilterName.Metrics },
      'po_numbers': { filterName: FilterName.PONumber },
      'amount_status': { filterName: FilterName.CEGStatus }
    };

    const data = {};
    this.filterManagementService.setParamsFromFilters(data, requestParamsDef, true, undefined, true);
    return data;
  }

  getBudgetGraphData() {
    this.utilityService.showLoading(true);
    this.budgetGraphData = {};
    this.hierarchyGraphDataSubscription?.unsubscribe();
    const params = this.getBudgetHierarchyRequestParams(false);

    this.hierarchyGraphDataSubscription = this.widgetService.getBudgetHierarchyGraphData(params).subscribe(
      data => this.success(data, 'getBudgetGraphData'),
      error => this.handleError(error)
    );

    this.subscriptions.push(this.hierarchyGraphDataSubscription);
  }

  downloadBudgetHierarchyCsvData() {
    const params = this.getBudgetHierarchyRequestParams(true);
    this.subscriptions.push(this.widgetService.getBudgetHierarchyCsvData(params).subscribe(
      data => ExportDataService.downloadCsv(data, 'budget_hierarchy.csv'),
      error => this.handleError(error)
    ));
  }

  private getBudgetHierarchyRequestParams(isCsv: boolean): { [key: string]: string } {
    const requestParamsDef: ParamsDef = {
      'budget': { defaultValue: () => this.selectedBudget.id },
      'allocations': {filterName: FilterName.Timeframes, defaultValue: () => this.budgetTimeframeList.map(tf => tf.id)},
      'segment1':
        !isCsv ?
          { defaultValue: () => this.budgetSegmentList.map(segment => segment.id) } :
          {
            filterName: FilterName.Segments,
            defaultValue: () => this.filterManagementService.getDefaultSegments(this.budgetSegmentList)
          },
      'statuses': isCsv ? {filterName: FilterName.Statuses} : null,

      'tag_ids': {filterName: FilterName.Tags},
      'owner_ids': {filterName: FilterName.Owners},
      'goal_ids': {filterName: FilterName.Goals},
      'campaign_ids': {defaultValue: () => this.filterManagementService.getFilterCampaignIds(this.allCampaigns)},
      'campaign_type_ids': {filterName: FilterName.CampaignTypes},
      'program_ids': {filterName: FilterName.ExpenseBuckets},
      'expense_type_ids': {filterName: FilterName.ExpenseTypes},
      'gl_code_ids': {filterName: FilterName.GlCodes},
      'vendor_ids': {filterName: FilterName.Vendors},
      'split_rule_ids': {filterName: FilterName.SharedCostRules},
      'source': {filterName: FilterName.ExpenseSource},
      'metric_ids': { filterName: FilterName.Metrics },
      'po_numbers': { filterName: FilterName.PONumber },
      'amount_status': { filterName: FilterName.CEGStatus }
    };

    const data = {};
    this.filterManagementService.setParamsFromFilters(data, requestParamsDef, true);
    return data;
  }

  /**
  * @method - onResize
  * @desc - calculate graph height on resize browser window
  */
  @HostListener('window:resize', ['$event']) onResize(_event) {
    if (this.showSpendByType) {
      this.calculateSpendTypeGraph(-1, 300);
    }
  }

  public get isNewCampaignsProgramsStructure(): boolean {
    return this.budgetDataService.isCurrentBudgetWithNewCEGStructure;
  }

  resetDashboardData () {
    this.timeframeGraphData = [];
    this.budgetGraphData = {};
    this.spendTypeGraphData = [];
    this.hideAmountsTooltip();
  }

  sortMonthArray(date) {
    const arr = [];
    date = date.split('-');
    const month = date[1] - 1;
      const firstDate = new Date(date[0], month, date[2], 0, 0, 0);
      for (let i = 0; i < 12; i++) {
          const monthIndex = (i + firstDate.getMonth()) % 12;
          arr.push(this.configuration.month[monthIndex]);
      }
      return arr;
  }

  calculateSpendTypeGraph(limit: any, timeout: number) {
    const self = this;
    const hPadding = 10;
    const preparedData = this.prepareSpendByTypeData(self.spendTypeGraphData);
    if (preparedData.length > 0) {
      setTimeout(() => {
        self.columnChartData.dataTable = [];
        self.columnChartData.dataTable.push(
          [
            'Category',
            { label: 'Closed', type: 'number' },
            { role: 'style' },
            { label: 'Committed', type: 'number' },
            { role: 'style' },
            { label: 'Planned', type: 'number' },
            { role: 'style' },
            { label: 'Reserved', type: 'number' },
            { role: 'style' },
            { label: 'Available', type: 'number' },
            { role: 'style' },
            { role: 'annotation' }
          ]);

        (preparedData || []).forEach((graph: any, i) => {
          if ((i < limit && limit !== -1) || limit === -1) {
            const dataRow = [
              graph.name,
              parseFloat(graph.data.closed),
              `stroke-width: 1;stroke-color: ${ this.colors.closed };${this.getColumnPartOpacity(this.configuration.statusNames.closed)}`,
              parseFloat(graph.data.committed),
              `stroke-width: 1;stroke-color: ${ this.colors.committed };${this.getColumnPartOpacity(this.configuration.statusNames.committed)}`,
              parseFloat(graph.data.planned),
              `stroke-width: 1;stroke-color: ${ this.colors.planned };${this.getColumnPartOpacity(this.configuration.statusNames.planned)}`,
              parseFloat(graph.data.reserved),
              `stroke-width: 1;stroke-color: ${ this.colors.reserved };${this.getColumnPartOpacity(this.configuration.statusNames.reserved)}`,
              parseFloat(graph.data.available),
              `stroke-width: 1;stroke-color: ${ this.colors.available };${this.getColumnPartOpacity(this.configuration.statusNames.available)}`,
              ''
            ];
            this.columnChartData.dataTable.push(dataRow);
          }
        });

        const boxSpendByTypeChart = document.querySelector('#box-spendByTypeChart');
        if (boxSpendByTypeChart && boxSpendByTypeChart.clientHeight && boxSpendByTypeChart.clientWidth) {
          self.columnChartData.options.height = boxSpendByTypeChart.clientHeight;
          self.columnChartData.options.width = boxSpendByTypeChart.clientWidth - hPadding;
        }

        const formattedColumnIndexes = [1, 2, 3, 4, 5, 6, 7, 8, 9];

        self.columnChartData.formatters = [
          {
            columns: formattedColumnIndexes,
            type: 'NumberFormat',
            options: {
              prefix: self.companyCurrency.symbol + ' ', fractionDigits: 0, pattern: 'short'
            }
          }
        ];

        const newObj = {
          'data': self.columnChartData
        };
        self.spendByType.ngOnChanges(newObj);
        }, timeout);
    }
  }

  getColumnPartOpacity(expenseStatus) {
    const selectedStatuses = this.currentFilters[FilterName.Statuses];
    return !selectedStatuses || selectedStatuses.length === 0 || selectedStatuses.includes(expenseStatus) ? '' : 'opacity: 0.2';
  }

  prepareSpendByTypeData(sourceData) {
    const itemsWithAtLeastOneNonZeroValue =
      sourceData.filter(dataObj => Object.keys(dataObj.data).some(prop => dataObj.data[prop] > 0));

    // Replace zero values by null values:
    itemsWithAtLeastOneNonZeroValue.forEach(dataObj => {
      Object.keys(dataObj.data).forEach(prop => {
        if (dataObj.data[prop] === 0) {
          dataObj.data[prop] = null;
        }
      });
    });
    return itemsWithAtLeastOneNonZeroValue;
  }

  getKeysSum(obj) {
    return Object.keys(obj).reduce((sum, key) => {
      const val = (key === 'planned' || key === 'closed' || key === 'committed' || key === 'reserved') ? parseFloat(obj[key]) : 0;
      return sum + val;
    }, 0);
  }

  getMetricPercentage(total, actual) {
    if (total === 0 && actual === 0) {
        return 0;
    } else if (total === 0 && actual > 0) {
        return 100;
    } else {
        return (parseFloat(actual) / parseFloat(total) * 100).toFixed(2);
    }
  }

  selectTimelineChart(status, index) {
    this.utilityService.showLoading(true);
    this.filterManagementService.updateCurrentFilterSet(
      {
        ...this.currentFilters,
        [FilterName.Timeframes]: [this.budgetTimeframeList[index].id],
        [FilterName.Statuses]: [this.titleCasePipe.transform(status)]
      });
  }

  selectSpendTypeChart(e) {
    const categoryName = e.selectedRowValues[0];
    const status =
      e.column === 1 ?
        this.configuration.statusNames.closed :
        e.column === 3 ? this.configuration.statusNames.committed : this.configuration.statusNames.planned;
    const catObj = this.spendTypeGraphData.find(data => data.name === categoryName);

    this.filterManagementService.updateCurrentFilterSet(
      {
        ...this.currentFilters,
        [FilterName.Statuses]: [status],
        [FilterName.ExpenseTypes]: [catObj.id]
      });

    this.router.navigate([this.configuration.ROUTING_CONSTANTS.SPENDING_MANAGEMENT]);
  }

  displayAmountsTooltip(e, data) {
    const factory: ComponentFactory<BudgetAmountsSummaryComponent> =
      this.componentResolver.resolveComponentFactory(BudgetAmountsSummaryComponent);
    const tooltipPosition = this.budgetAmountsSummaryService.calculateTooltipPosition(e, {
      containerWidth: document.documentElement.clientWidth,
      thresholdWidth: 320,
      offsetX: 5
    });
    this.budgetAmountsTooltipContainer.clear();
    this.budgetAmountsTooltipRef = this.budgetAmountsTooltipContainer.createComponent(factory);
    this.budgetAmountsTooltipClass = tooltipPosition.position === 'right' ? 'arrow-left' : 'arrow-right';
    this.budgetAmountsTooltipStyles = {
      display: 'block',
      ...tooltipPosition.styles
    };
    this.budgetAmountsTooltipRef.instance.data = data;
  }

  hideAmountsTooltip() {
    if (this.budgetAmountsTooltipRef) {
      this.budgetAmountsTooltipRef.destroy();
    }
    this.budgetAmountsTooltipStyles = { display: 'none' };
    this.budgetAmountsTooltipClass = null;
  }

  success(data, type) {
    if (Number(data?.status) === HttpStatusCode.Ok) {
      if (type === 'getTimeframeGraphDetails') {
        const itemsLength  = data.data.length;
        if (itemsLength === 12) {
          this.bars = this.sortMonthArray(this.budgetDates['from']).map(
            item => ({key: item, value: this.configuration.full_month_object[item]})
          );
          this.full_months = [];
          this.bars.forEach(b => {
            this.full_months.push(this.configuration.full_month_object[b.key]);
          });
        } else if (itemsLength === 4) {
          this.bars = this.configuration.quarter.map(item => ({key: item, value: item}));
        } else {
          this.bars = this.configuration.year.map(item => ({key: item, value: item}));
        }

        this.timeframeGraphData = this.sortTimelineData(data.data);
      } else if (type === 'getSpendByTypeGraphDetails') {
        this.spendTypeGraphData = data.data;
        if (this.spendTypeGraphData.length > 0 && this.showSpendByType) {
          this.calculateSpendTypeGraph(10, 0);
        }
      } else if (type === 'getBudgetGraphData') {
        this.budgetGraphData = data.data;
      } else if (type === 'getCompanyInfo') {
          this.companyCode = data.data.company_code;
      }
    } else {
      this.utilityService.showToast({ Title: '', Message: data.message, Type: 'error' });
    }
    this.utilityService.showLoading(false);
  }

  sortTimelineData(data) {
    const monthsSorted = this.bars.map(bar => bar.value);
    return data.sort((a, b) => monthsSorted.indexOf(a.company_budget_alloc_name) - monthsSorted.indexOf(b.company_budget_alloc_name))
  }

  redirectToSetting() {
    this.router.navigate(
      [this.configuration.ROUTING_CONSTANTS.BUDGET_SETTINGS, {budget_id: this.selectedBudget.id}],
      { skipLocationChange: true }
    );
  }

  exportBudgetTimeline() {
    this.downloadTimelineCsvData();
  }

  exportBudgetHierarchy() {
    this.downloadBudgetHierarchyCsvData();
  }

  exportSpendByType() {
    this.downloadSpendByTypeCsvData();
  }

  downloadBudgetTimelineImage() {
    this.utilityService.showLoading(true);
    window.setTimeout(() => { // To allow the menu to be closed
      ExportDataService.downloadWidgetGraphAsImage(
        'budgetTimeLineGraph',
        { width: 2000, height: 1500 },
        'image/png',
        'spending_timeline.png',
        'SPENDING TIMELINE',
        this.companyDataService.selectedCompanySnapshot.name + ' | ' + this.selectedBudget.name,
        this.getSelectedSegmentsText(),
        this.getTimeframeText(),
        ExportDataService.showHiddenTexts).then(
          () => {
            this.utilityService.showLoading(false);
          });
    }, 50);
  }

  downloadSpendByTypeImage() {
    this.utilityService.showLoading(true);

    // Backup columnChart data - to be able to restore it after image rendering.
    // This is required because cloning the chart and adjusting it affects the source graph as well.
    const initColumnChartData = Widget.deepCopyJSONObject(this.columnChartData);

    const spendByTypeCloneEl = document.createElement('div');
    spendByTypeCloneEl.id = 'spendByTypeClone';
    window.document.body.appendChild(spendByTypeCloneEl);

    const chartWrapper = this.spendByType.wrapper;
    const spendByTypeClone = chartWrapper.clone();
    spendByTypeClone.setContainerId('spendByTypeClone');

    const preparedData = this.prepareSpendByTypeData(this.spendTypeGraphData);
    const dataTable =
      ExportDataService.getDataTableForSpendByTypeDownloadableImage(
        preparedData,
        this.smallNumber.transform.bind(this.smallNumber),
        this.colors,
        this.getColumnPartOpacity.bind(this)
      );

    spendByTypeClone.setDataTable(dataTable);
    spendByTypeClone.setOption('height', 1000);
    spendByTypeClone.setOption('width', 1900);
    spendByTypeClone.setOption('hAxis.textPosition', 'out');
    spendByTypeClone.setOption('hAxis.slantedText', true);
    spendByTypeClone.setOption('hAxis.slantedTextAngle', 45);
    spendByTypeClone.setOption('hAxis.maxTextLines', 3);
    spendByTypeClone.setOption('hAxis.textStyle', { fontSize: 16});
    spendByTypeClone.setOption('animation.startup', false);
    spendByTypeClone.setOption('chartArea', { left: 120, top: 20, width: '80%' });
    spendByTypeClone.setOption('annotations.textStyle', { fontSize: 14 });

    spendByTypeClone.draw();

    window.setTimeout(() => { // To allow the menu to be closed
      ExportDataService.downloadWidgetGraphAsImage(
        spendByTypeCloneEl,
        { width: 2000, height: 1500 },
        'image/png',
        'spend_by_type.png',
        'SPEND BY TYPE',
        this.companyDataService.selectedCompanySnapshot.name + ' | ' + this.selectedBudget.name,
        this.getSelectedSegmentsText(),
        this.getTimeframeText(),
        null).then(
        () => {
          window.document.body.removeChild(spendByTypeCloneEl);
          // Restore columnChart data:
          this.columnChartData = Widget.deepCopyJSONObject(initColumnChartData);
          if (this.showSpendByType) {
            this.calculateSpendTypeGraph(-1, 300);
          }
          this.utilityService.showLoading(false);
        });
    }, 50);
  }

  getSelectedSegmentsText() {
    const selectedSegmentNames =
      this.currentFilters[FilterName.Segments] && this.currentFilters[FilterName.Segments].length > 0 ?
        this.budgetSegmentList
          .filter(seg => this.currentFilters[FilterName.Segments].includes(seg.id))
          .map(seg => seg.name) :
        this.budgetSegmentList.map(seg => seg.name);
    return 'Segments included: ' + selectedSegmentNames.join(', ');
  }

  getTimeframeText() {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(this.budgetDates['from'], 'MMMM yyyy') + ' - ' +
      datePipe.transform(this.budgetDates['to'], 'MMMM yyyy');
  }

  private getViewModeStorageKey(widgetType: DashboardWidgetType) {
    return `dashboard_widgetView_${widgetType}`;
  }

  private initWidgetViewModes() {
    const spendByType =
      localStorage.getItem(this.getViewModeStorageKey(DashboardWidgetType.SpendByType)) as DashboardWidgetViewMode;
    const spendingTimeline =
      localStorage.getItem(this.getViewModeStorageKey(DashboardWidgetType.SpendingTimeline)) as DashboardWidgetViewMode;

    this.widgetViewModeByType[DashboardWidgetType.SpendByType] = spendByType || DashboardWidgetViewMode.Table;
    this.widgetViewModeByType[DashboardWidgetType.SpendingTimeline] = spendingTimeline || DashboardWidgetViewMode.Chart;
  }

  private setWidgetViewMode(widgetType: DashboardWidgetType, tableView: boolean) {
    const viewMode = tableView ? DashboardWidgetViewMode.Table : DashboardWidgetViewMode.Chart;

    this.widgetViewModeByType[widgetType] = viewMode;
    localStorage.setItem(this.getViewModeStorageKey(widgetType), viewMode);
  }

  public handleSpendByTypeViewChange(tableView: boolean) {
    if (!tableView) {
      this.calculateSpendTypeGraph(-1, 0);
    }

    this.setWidgetViewMode(DashboardWidgetType.SpendByType, tableView);
  }

  public handleSpendingTimelineViewChange(tableView: boolean) {
    this.setWidgetViewMode(DashboardWidgetType.SpendingTimeline, tableView);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.budgetAmountsTooltipRef?.destroy();
  }

  handleError(error) {
    if (error.hasOwnProperty('message')) {
        this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
        this.utilityService.showLoading(false);
    } else if (error.hasOwnProperty('detail')) {
        this.utilityService.showLoading(false);
        this.utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
    }
  }
}
