import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { Configuration } from 'app/app.constants';
import { MenuPanelItem } from '../menu-panel/menu-panel.type';

@Component({
  selector: 'creation-menu',
  templateUrl: './creation-menu.component.html',
})
export class CreationMenuComponent {
  @Input() disabled = false;

  protected items: MenuPanelItem[] = [
    {
      label: 'New Expense',
      faIcon: ['fas', 'coins'],
      action: () => this.appRouting.openExpenseCreation(),
      customCssClass: 'ut-new-expense'
    },
    {
      label: 'New Campaign',
      faIcon: ['far', 'rocket-launch'],
      action: () => this.appRouting.openCampaignCreation(),
      customCssClass: 'ut-new-campaign'
    },
    {
      label: 'New Expense Group',
      faIcon: ['fas', 'briefcase'],
      action: () => this.appRouting.openProgramCreation(),
      customCssClass: 'ut-new-program'
    },
    {
      label: 'New Goal',
      faIcon: ['fas', 'bullseye-arrow'],
      action: () => this.appRouting.openGoalCreation(),
      customCssClass: 'ut-new-goal'
    },
    {
      label: 'Import / Export Data',
      faIcon: ['fas', 'file-chart-column'],
      action: () => this.router.navigate([ this.configuration.ROUTING_CONSTANTS.IMPORT_DATA ]),
      customCssClass: 'ut-import-export'
    }
  ];

  constructor(
    private readonly appRouting: AppRoutingService,
    private readonly router: Router,
    private readonly configuration: Configuration,
  ) {}
}
