<header class="page-header" [class.with-gradient]="withGradient">
  <div [ngClass]="containerClassName">
    <div class="top-bar">
      <a [attr.href]="logoLink" target="_blank">
        <plc-logo></plc-logo>
      </a>
      <ng-content select="[slot=navigation]"></ng-content>
    </div>
    <div class="content">
      <ng-content select="[slot=content]"></ng-content>
    </div>
  </div>
</header>
