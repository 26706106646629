<span class="status-indicator" [ngClass]="activityStatus">
  <p *ngIf="activityStatus === UserActivityStatus.Inactive || activityStatus === UserActivityStatus.Expired">
    <button class="send-invite-btn" [disabled]="disabled" (click)="sendInvite.emit()">
      {{ activityStatus === UserActivityStatus.Inactive ? "Send Invite" : "Resend"}}
    </button>
    <br />
    <span *ngIf="expirationText" class="expiration">
      {{ expirationText }}
    </span>
  </p>
  <p *ngIf="activityStatus === UserActivityStatus.Active || activityStatus === UserActivityStatus.Pending">
    <span class="status-value">
      {{ activityStatus === UserActivityStatus.Active ? "Active" : "Pending" }}
    </span>
    <span *ngIf="expirationText" class="expiration">
      {{ expirationText }}
    </span>
  </p>
</span>
