export enum MetricValueType {
  Count = 'count',
  Currency = 'currency'
}

export enum FunnelInputsType {
  Target = 'target',
  BaseRevenue = 'arpo',
  RevenueToProfit = 'revenueToProfit',
  Conversion = 'conversion',
}

export enum OutlinedFieldType {
  CONVERSION = 'conversion_',
  COUNT_ARPO = 'count_arpo',
  CURRENCY_TARGET = 'currency_target',
}

export interface MetricMaster {
  id: number;
  name: string;
  target: number;
  revenuePerOutcome?: number;
  valueType: MetricValueType;
  isHidden: boolean;
  usageCount?: number;
  rowIndex?: number;
  conversionRate?: number;
}

export interface MetricFunnelRow {
  isBaseRow?: boolean;
  countMetric?: MetricMaster;
  currencyMetric?: MetricMaster;
  conversionRate?: number;
}

export interface ProductMetricFunnel {
  id: number;
  order: number;
  productName: string;
  activated: boolean;
  revenueToProfit?: number;
  metricFunnelRows?: MetricFunnelRow[];
  budgetCalculatorData?: BudgetCalculatorData;
}

export interface BudgetCalculatorData {
  targetedMetricId: number;
  budgetAmount: number;
  prevBudget: number;
  prevMetricNumber: number;
  prevMetricCost: number;
}

export enum ProductFunnelAction {
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  DOWNLOAD = 'download',
  DELETE = 'delete'
}

export enum ProductFunnelView {
  SIMPLE = 1,
  ARPO = 2,
  PROFIT = 3,
}

export interface FunnelStateDifferences {
  funnel: {
    productName?: string;
    revenueToProfit?: number;
    budgetCalculatorData?: BudgetCalculatorData;
  };
  metrics: MetricRowsDifferences;
  originalFunnel: ProductMetricFunnel;
}

export interface MetricRowsDifferences {
  create?: MetricMaster[];
  update?: MetricMaster[];
  delete?: number[];
}

export enum MetricActions {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

