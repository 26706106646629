<grid-with-search
  [gridColumns]="gridColumns"
  [gridData]="glCodesList"
  [SearchPlaceHolder]="'Search GL Codes'"
  [hasDescriptionColumn]="true"
  (addNewGridItem)="addGlCode($event)"
  (editGridItem)="updateGlCode($event)"
  (updateStatusChange)="updateGlCode($event)"
  (deleteGridItem)="deleteGlCode($event)"
  [enableSwitchTooltip]="enableSwitchTooltip"
  [NoDataText]="NoDataText"
></grid-with-search>
