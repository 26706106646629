<td class="name-col">
  <div class="cell-content p-0">
    <div class="total-labels">
      <div class="sub-row-item">
        Grand Total
      </div>
      <div class="sub-row-item" *ngIf="performanceMode">
        Return on Marketing Plan
      </div>
    </div>
    <div class="performance-cell">
    </div>
  </div>
</td>

<td *ngFor="let timeframe of timeframes; trackBy: identifyTimeframe"
    class="timeframe-col"
    [ngClass]="{
      'locked': timeframe.locked,
      'is-hidden': !budgetMode || !timeframe.isVisible
    }"
>
  <div class="cell-content" *ngIf="grandTotalBudget">
    <div class="sub-columns">
      <ng-container *ngFor="let column of budgetColumns; trackBy: identifyColumn">
        <div *ngIf="budgetColumnsVisibility[column.id]" class="default-value">
          {{ (grandTotalBudget[timeframe.id] && grandTotalBudget[timeframe.id][column.id]) | absoluteNumber }}
        </div>
      </ng-container>
    </div>
  </div>
</td>

<td *ngIf="performanceMode && grandTotalPerformance" class="performance-column" @slideFromRightColumn>
  <div class="cell-content sub-columns">
    <div class="segment-breakdown" *ngFor="let column of performanceColumns">
      <div class="sub-row-item default-value">
        {{ grandTotalPerformance[column.id][0] | absoluteNumber: '-' }}
      </div>
      <div class="sub-row-item default-value">
        {{ grandTotalPerformance[column.id][1] | absoluteNumber: '-' }}
      </div>
    </div>
  </div>
</td>

<td class="total-column" *ngIf="totalsSidebarState !== sidebarState.Hidden" @slideFromRightColumn>
  <div class="cell-content sub-columns">
    <ng-container *ngFor="let column of budgetColumns; trackBy: identifyColumn">
      <div *ngIf="column.id === budgetColumnName.Budget || (budgetColumnsVisibility[column.id] && totalsSidebarState === sidebarState.FullWidth)"
           class="sub-row-item default-value">
        {{ grandTotalForActiveTimeframes[column.id] | absoluteNumber }}
      </div>
    </ng-container>
  </div>
</td>
