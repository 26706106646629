import { Injectable } from '@angular/core';
import { MetricIntegrationName } from '../types/metric-integration';
import { GoogleAdsMetricTypesService } from '../google-ads/google-ads-metric-types.service';
import { LinkedinMetricTypesService } from '../linkedin/linkedin-metric-types.service';
import { FacebookMetricTypesService } from '../facebook-ads/facebook-metric-types.service';
import { HubspotMetricTypesService } from '../hubspot/hubspot-metric-types.service';
import { SalesforceMetricTypesService } from '../salesforce/salesforce-metric-types.service';
import { ExternalMetricTypesService } from './external-metric-types-service';
import { ExternalMetric } from '../types/external-metrics.types';
import { MetricDO } from '../../shared/services/backend/metric.service';
import { ExternalMetricTypesMapping } from '../types/external-metric-types-mapping.interface';

@Injectable()
export class ExternalMetricTypesProviderService {
  private services = {
    [MetricIntegrationName.Hubspot]: this.hubspotMetricTypesService,
    [MetricIntegrationName.Salesforce]: this.salesforceMetricTypesService,
    [MetricIntegrationName.GoogleAds]: this.googleAdsMetricTypesService,
    [MetricIntegrationName.LinkedinAds]: this.linkedinMetricTypesService,
    [MetricIntegrationName.FacebookAds]: this.facebookMetricTypesService,
  };

  constructor(
    private readonly googleAdsMetricTypesService: GoogleAdsMetricTypesService,
    private readonly linkedinMetricTypesService: LinkedinMetricTypesService,
    private readonly facebookMetricTypesService: FacebookMetricTypesService,
    private readonly hubspotMetricTypesService: HubspotMetricTypesService,
    private readonly salesforceMetricTypesService: SalesforceMetricTypesService,
  ) {
  }

  public getExternalMetricTypesServiceByIntegrationSource(integrationType: MetricIntegrationName): ExternalMetricTypesService {
    const provider = this.services[integrationType]
    if (!provider) {
      console.error( `Missing service for "${integrationType}" in ExternalMetricTypesProviderService`)
      return;
    }
    return provider;
  }

  public getDefaultInitialMapping(
    externalMetricTypesService: ExternalMetricTypesService,
    externalMetrics: ExternalMetric[],
    plannuhMetrics: MetricDO[]
  ): ExternalMetricTypesMapping {
    const mapping =
      (externalMetrics || []).reduce((currentMapping, externalMetric) => {
        const plannuhMetric =
          (plannuhMetrics || []).find(
            plMetric => plMetric.name === externalMetricTypesService.defaultMetricTypesMapping[externalMetric.name] && !plMetric.product
          );
        if (plannuhMetric) {
          currentMapping[externalMetric.name] = [plannuhMetric.id];
        }
        return currentMapping;
      }, {});
    return Object.keys(mapping).length > 0 ? mapping : null;
  }
}
