<div
  class="icon-sorting"
  [ngClass]="{
    'sorted': isSorted,
    'direction-asc': direction === sortDirectionsConstants.asc,
    'direction-desc': direction === sortDirectionsConstants.desc
  }"
  (click)="handleClick()">
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10">
    <path id="Forma_1_copy" data-name="Forma 1 copy" class="cls-1" d="M0.422,4.093H6.578a0.416,0.416,0,0,0,.309-0.135,0.454,0.454,0,0,0,0-.638L3.809,0.147a0.423,0.423,0,0,0-.618,0L0.112,3.321a0.455,0.455,0,0,0,0,.638A0.417,0.417,0,0,0,.422,4.093ZM6.578,5.907H0.422a0.417,0.417,0,0,0-.309.135,0.455,0.455,0,0,0,0,.638L3.191,9.853a0.422,0.422,0,0,0,.618,0L6.887,6.679a0.454,0.454,0,0,0,0-.638A0.416,0.416,0,0,0,6.578,5.907Z"/>
    <path class="cls-2" d="M0,3.478"/>
    <path class="cls-3" d="M0,3.391l3.2-3.3a0.609,0.609,0,0,1,.59,0C4.071,0.267,7,3.478,7,3.478s0.09,0.594-.337.609-6.41,0-6.41,0A0.6,0.6,0,0,1,0,3.391Z"/>
  </svg>
</div>