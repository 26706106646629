<div
  class="budget-table-wrapper"
  [ngClass]="{
    'is-loading': isLoading,
    'is-scrollable-x': tableDomState?.scrollableX,
    'is-scrollable-y': tableDomState?.scrollableY
  }"
>
  <div
    [style.max-width.px]="tableDomState?.rootWidth"
    class="pseudo-container"
  >
    <budget-table-controls
      *ngIf="budget"
      [showProjected]="showProjected"
      [editableBudget]="editableBudget"
      (setProjected)="showProjected = $event;"
      (addSegment)="handleAddSegment()"
    ></budget-table-controls>
    <div class="table-head-shadow"></div>
    <plan-budget-cue [isVisible]="showBudgetCue" (hide)="hideBudgetCue()"></plan-budget-cue>
  </div>

  <div class="budget-table" #tableParent>
    <loader [isVisible]="isLoading"></loader>
    <table class="table-section" [class.with-projected]="showProjected" #tableRoot>
      <thead class="table-head">
      <tr class="table-row">
        <td class="table-col controls-col">
          <div class="table-cell">
            <arrow-toggler
              *ngIf="groupsList.length"
              [active]="!togglingState.global.active"
              (change)="handleToggleAllChange($event)"
              [matTooltip]="togglingState.global.active
                ? 'Collapse All Groups'
                : 'Expand All Groups'
              "
              matTooltipClass="dark-theme-tooltip above"
              matTooltipPosition="above"
            ></arrow-toggler>
          </div>
        </td>
        <td class="table-col segment-col">
          <div class="table-cell">
            Segment Name
            <sort-button
              [columnName]="sortableFields.segmentName"
              [appliedSorting]="appliedSorting"
              (onClick)="applySorting(sortableFields.segmentName)"
            >
            </sort-button>
          </div>
        </td>
        <td class="table-col owner-col">
          <div class="table-cell">Owner</div>
        </td>
        <td class="table-col timeframe-col" *ngFor="let timeframe of timeframes; trackBy: identifyTimeframe">
          <div class="table-cell">
            <timeframe-lock-button
              [timeframe]="timeframe"
              [disabled]="!editableBudget"
              [isCurrent]="timeframe.id === currentTimeframe?.id"
              [budget]="budget"
              (onLockChange)="handleTimeframeLockChange($event, timeframe)"
            ></timeframe-lock-button>
          </div>
        </td>
        <td class="table-col fy-total-col">
          <div class="table-cell">FY Total</div>
        </td>
        <td class="table-col projected-col">
          <div class="table-cell">
            <div
              class="icon"
              [matTooltip]="projectedTooltip"
              matTooltipPosition="left"
              matTooltipClass="dark-theme-tooltip left multi-line text-left projected-budget"
            >
              <fa-duotone-icon [icon]="['fad', 'info-circle']"></fa-duotone-icon>
            </div>
            <span>Projected<br/>Budget</span>
          </div>
        </td>
      </tr>
      </thead>
      <tbody class="table-body">
      <ng-container *ngFor="let record of tableData; trackBy: identifyRecord">
        <tr
          class="table-row"
          [ngClass]="{
            'group-row': record.type === RecordType.Group,
            'is-selected': selectionState.records[record.key] === SelectionValue.Active
          }"
        >
          <ng-container
            [ngTemplateOutlet]="metaSectionRow"
            [ngTemplateOutletContext]="{
              $implicit: record,
              isGroup: record.type === RecordType.Group,
              isSingle: record.type === RecordType.Segment && !record.segmentGroupKey
            }"
          ></ng-container>
          <ng-container
            [ngTemplateOutlet]="timeframesSectionRow"
            [ngTemplateOutletContext]="{
              $implicit: record,
              isGroup: record.type === RecordType.Group,
              isSingle: record.type === RecordType.Segment && !record.segmentGroupKey
            }"
          ></ng-container>
          <ng-container
            [ngTemplateOutlet]="budgetSectionRow"
            [ngTemplateOutletContext]="{
              $implicit: record,
              isGroup: record.type === RecordType.Group,
              isSingle: record.type === RecordType.Segment && !record.segmentGroupKey
            }"
          ></ng-container>
        </tr>

        <ng-container *ngIf="record.nestedRecords?.length && togglingState.groups[record.key]">
          <tr
            *ngFor="let childRecord of record.nestedRecords; trackBy: identifyRecord"
            class="table-row"
            [ngClass]="{
              'is-selected': selectionState.records[childRecord.key] === SelectionValue.Active
            }"
          >
            <ng-container
              [ngTemplateOutlet]="metaSectionRow"
              [ngTemplateOutletContext]="{ $implicit: childRecord, isGroup: false, isSingle: false }"
            ></ng-container>

            <ng-container
              [ngTemplateOutlet]="timeframesSectionRow"
              [ngTemplateOutletContext]="{ $implicit: childRecord, isGroup: false, isSingle: false }"
            ></ng-container>

            <ng-container
              [ngTemplateOutlet]="budgetSectionRow"
              [ngTemplateOutletContext]="{ $implicit: childRecord, isGroup: false, isSingle: false }"
            ></ng-container>
          </tr>
        </ng-container>
      </ng-container>
      </tbody>
      <tfoot class="table-footer" [ngClass]="{'isCegBudget': isCegBudget}">
        <tr class="table-row grand-total-row">
          <td class="table-col grand-total-col" colspan="3">
            <div class="table-cell">Grand Total</div>
          </td>
          <td
            *ngFor="let timeframe of timeframes"
            class="table-col timeframe-col"
            [class.highlighted-timeframe]="highlightedTimeframes.includes(timeframe.id)"
          >
            <div class="table-cell difference-amount-cell">
              <budget-allocation-cell
                [allocated]="grandTotal?.allocated[timeframe.id]"
                [spent]="tableExpenseTotals?.grandTotal?.allocated[timeframe.id]"
                [editable]="false"
                [showDifference]="true"
                [disabled]="timeframe.locked"
                [actionTooltipContext]="tooltipContext"
              >
              </budget-allocation-cell>
            </div>
          </td>
          <td class="table-col fy-total-col">
            <div class="table-cell difference-amount-cell">
              <budget-allocation-cell
                [allocated]="grandTotal?.total"
                [spent]="tableExpenseTotals?.grandTotal?.total"
                [editable]="false"
                [showDifference]="true"
                [actionTooltipContext]="tooltipContext"
              >
              </budget-allocation-cell>
            </div>
          </td>
          <td class="table-col projected-col">
            <div class="table-cell">
              <budget-allocation-cell
                [allocated]="grandTotal?.projected"
                [actionTooltipContext]="tooltipContext"
              >
              </budget-allocation-cell>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <menu-panel #segmentMenuPanel [items]="segmentContextMenu"></menu-panel>
  <menu-panel #groupMenuPanel [items]="groupContextMenu"></menu-panel>
</div>

<ng-template #metaSectionRow let-record let-isGroup="isGroup" let-isSingle="isSingle">
  <td class="table-col controls-col">
    <div class="table-cell">
      <div
        *ngIf="isGroup"
        [matTooltip]="'Add Segment\nto the Group'"
        matTooltipPosition="right"
        matTooltipClass="dark-theme-tooltip right multi-line"
        class="add-group-segment-icon"
        (click)="handleAddGroupSegment(record)"
      >
        <fa-duotone-icon [icon]="['fad', 'plus-circle']"></fa-duotone-icon>
      </div>
      <div class="controls-cell">
        <mat-checkbox
          color="primary"
          class="control checkbox-control pl-mat-checkbox"
          [matTooltip]="selectionState.records[record.key] === SelectionValue.Active
            ? 'Deselect Segments\nof this Group'
            : 'Select Segments\nof this Group'
          "
          [matTooltipDisabled]="!isGroup"
          matTooltipPosition="right"
          matTooltipClass="dark-theme-tooltip right multi-line"
          (change)="handleSelection($event, record)"
          [checked]="selectionState.records[record.key] === SelectionValue.Active"
          [indeterminate]="selectionState.records[record.key] === SelectionValue.Indeterminate"
          [disabled]="!editableBudget"
        ></mat-checkbox>
        <ng-container *ngIf="isGroup">
          <arrow-toggler
            [matTooltip]="togglingState.groups[record.key]
              ? 'Collapse Group'
              : 'Expand Group'
            "
            matTooltipClass="dark-theme-tooltip above"
            matTooltipPosition="above"
            [active]="!togglingState.groups[record.key]"
            (change)="handleToggleChange($event, record)"
          ></arrow-toggler>
        </ng-container>
      </div>
    </div>
  </td>
  <td class="table-col segment-col">
    <div class="table-cell segment-cell">
      <icon-subgroup-arrow *ngIf="!isGroup && !isSingle"></icon-subgroup-arrow>
      <div class="icon">
        <fa-duotone-icon [icon]="['fad', 'piggy-bank']"></fa-duotone-icon>
      </div>
      <budget-segment-name
        [controlValue]="record.name"
        [isReadOnly]="!editableBudget"
        [record]="record"
        [placeholder]="isGroup ? 'Name your Group' : 'Name your Segment'"
        (onNameChanged)="handleNameChange($event, record)"
        (onErrorStateChanged)="handleErrorState($event, 'name', record)"
        #segmentNameCmp
      ></budget-segment-name>
    </div>
  </td>
  <td class="table-col owner-col">
    <div class="table-cell owner-cell">
      <flat-select
        [class.inactive-owner-col]="isInactiveUser(record.owner)"
        [options]="selectOwners$ | async"
        [value]="record.owner"
        [isReadOnly]="!editableBudget"
        [matTooltip]="returnOwnerLabelByType(record.owner, 'name')"
        (onValueChanged)="handleOwnerChange($event, record)"
        (click)="handleOwnerSelectOpen(record)"
        [placeholder]="returnOwnerLabelByType(record.owner, 'initial') || 'N/A'"
        panelClass="flat-select-panel-extended"
        matTooltipPosition="above"
        matTooltipClass="dark-theme-tooltip above"
      >
        <ng-container slot="trigger" #triggerChild>
          <span class="owner-value">{{ record.owner?.initials }}</span>
        </ng-container>
      </flat-select>

      <div
        *ngIf="editableBudget && !isGroup"
        class="segment-menu-trigger"
        [class.is-disabled]="!selectionState.records[record.key]"
        matRipple
        #segmentMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="segmentMenuPanel.menu"
        [matRippleAnimation]="{ enterDuration: 300, exitDuration: 300 }"
        [matRippleCentered]="true"
        [matRippleUnbounded]="true"
        [matRippleRadius]="15"
        [matRippleColor]="rippleColor"
        [class.is-active]="segmentMenuTrigger.menuOpen"
      >
        <fa-icon [icon]="['far', 'ellipsis-v']"></fa-icon>
      </div>
      <div
        *ngIf="editableBudget && isGroup"
        class="segment-menu-trigger"
        [class.is-disabled]="!selectionState.records[record.key]"
        matRipple
        #groupMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="groupMenuPanel.menu"
        [matRippleAnimation]="{ enterDuration: 300, exitDuration: 300 }"
        [matRippleCentered]="true"
        [matRippleUnbounded]="true"
        [matRippleRadius]="15"
        [matRippleColor]="rippleColor"
        [class.is-active]="groupMenuTrigger.menuOpen"
      >
        <fa-icon [icon]="['far', 'ellipsis-v']"></fa-icon>
      </div>
    </div>
  </td>
</ng-template>

<ng-template #timeframesSectionRow let-record let-isGroup="isGroup" let-isSingle="isSingle">
  <td
    *ngFor="let timeframe of timeframes; trackBy: identifyTimeframe"
    class="table-col timeframe-col"
    [class.highlighted-timeframe]="highlightedTimeframes.includes(timeframe.id)"
  >
    <div class="table-cell difference-amount-cell">
      <budget-allocation-cell
        [allocated]="record?.values?.allocated[timeframe.id]"
        [spent]="isGroup ?
          tableExpenseTotals?.groups[record?.key]?.allocated[timeframe.id] :
          tableExpenseTotals?.segments[record?.id]?.allocated[timeframe.id]
        "
        [editable]="editableBudget && !isGroup"
        [disabled]="timeframe.locked"
        [showDifference]="true"
        [allowGestures]="true"
        [globalDragStarted]="globalDragStarted"
        [hasRemainingBudget]="remainingBudget > 0"
        [actionTooltipContext]="tooltipContext"
        (onChange)="handleAllocatedAmountChange($event, { record: record, timeframe: timeframe })"
        (onDoubleClick)="handleDoubleClick($event, { record: record, timeframe: timeframe })"
        (onDrop)="handleOnDrop($event, { record: record, timeframe: timeframe })"
        (onDragStart)="handleOnDragStart($event, { record: record, timeframe: timeframe })"
        (onDragEnd)="handleOnDragEnd()"
      >
      </budget-allocation-cell>
    </div>
  </td>
</ng-template>

<ng-template #budgetSectionRow let-record let-isGroup="isGroup" let-isSingle="isSingle">
  <td class="table-col fy-total-col">
    <div class="table-cell difference-amount-cell">
      <budget-allocation-cell
        [allocated]="record?.values?.total"
        [spent]="isGroup ?
          tableExpenseTotals?.groups[record?.key]?.total :
          tableExpenseTotals?.segments[record?.id]?.total
        "
        [actionTooltipContext]="tooltipContext"
        [editable]="false"
        [showDifference]="true"
      >
      </budget-allocation-cell>
    </div>
  </td>
  <td class="table-col projected-col">
    <div class="table-cell">
      <budget-allocation-cell
        [allocated]="record?.values?.projected"
        [editable]="editableBudget && !isGroup"
        (onChange)="handleProjectedAmountChange($event, record)"
        [actionTooltipContext]="tooltipContext"
      >
      </budget-allocation-cell>
    </div>
  </td>
</ng-template>
