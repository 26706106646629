<div class="search-field-wrapper">
  <div *ngIf="fieldExpanded" [@containerAnimation] class="search-field-container" >
    <span class="icon-holder"><img src="assets/images/planful_navbar_logo_white.svg"/></span>
    <div class="search-field" [@searchFieldAnimation] (@searchFieldAnimation.done)="onAnimationDone()">
      <span class="magnifying-icon">
        <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
      </span>
      <input matInput [formControl]="searchInput" #searchField [placeholder]="'Search ' + '“' + budgetName + '”'" (keydown.enter)="onSubmit()"/>
      <button mat-icon-button (click)="closeSearch()">
        <fa-duotone-icon [icon]="['fad', 'times-circle']"></fa-duotone-icon>
      </button>
    </div>
  </div>
  <button mat-icon-button class="header-action-item ut-header-search" (click)="toggleFieldView()">
    <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
  </button>
</div>
