import { PlanObjectsBuilder } from './plan-objects-builder';
import { PlanObject, PlanProgram } from '../dashboard.types';
import { Configuration } from 'app/app.constants';
import { PlanProgramDO } from 'app/shared/types/program.interface';

export class PlanProgramsBuilder extends PlanObjectsBuilder<PlanProgramDO> {
  constructor(sourceDataObjects: PlanProgramDO[], configuration: Configuration) {
    super(sourceDataObjects, configuration);
  }

  getResultPlanObjects(): PlanProgram[] {
    this.setObjectType(this.configuration.OBJECT_TYPES.program);
    this.prepareBudgetObjectTotals();
    this.addObjectTransformer(
      planProgram => this.prepareChildren(planProgram)
    );
    return super.getResultPlanObjects();
  }

  private prepareChildren(planProgram: PlanProgramDO & PlanObject): PlanProgramDO & PlanObject {
    planProgram.expense_list =
      this.provideWithObjectType(planProgram.expense_list || [], this.configuration.OBJECT_TYPES.expense)
        .sort(PlanObjectsBuilder.compareObjectsByName);
    return planProgram;
  }
}
