<div class="summary-bar" [ngClass]="{'loading': summaryBarLoading$ | async }">
  <div class="summary-bar-groups">
    <div *ngFor="let group of summaryBarItems; index as i" class="group"
         dynamicPortalTooltip
         [tooltipContext]="getSummaryBarTooltipContext(i)"
         [tooltipPosition]="tooltipPosition"
         [tooltipPositionCssClass]="'above'"
    >
      <summary-item
        *ngFor="let item of group"
        [title]="item.title"
        [label]="item.label"
        [icon]="item.icon"
        [value]="item.value"
        [valueSecondary]="item.valueSecondary"></summary-item>
    </div>
  </div>

  <div class="calculations" *ngIf="calculationItems">
    <ng-container *ngFor="let item of calculationItems">
      <ng-container *ngIf="!item.isHidden">
        <span *ngIf="item.operator" class="operator">{{ item.operator }}</span>
        <summary-value
          dynamicPortalTooltip
          [ngClass]="{ 'has-tooltip': item.tooltipContext }"
          [tooltipContext]="item.tooltipContext"
          [tooltipPosition]="tooltipPosition"
          [tooltipPositionCssClass]="'above'"
          [label]="item.label"
          [value]="item.value"
          [class]="item.cssClass"
          [confettiAnimation]="item.confetti ? item.confetti.trigger : null"
          [confettiCustomOptions]="item.confetti ? item.confetti.options : null"
        ></summary-value>
      </ng-container>
    </ng-container>
  </div>
</div>


