export enum DetailsActionType {
  CLONE,
  UPDATE_STATUS,
  CHANGE_BUDGET,
  DELETE,
  SHOW_PARENT,
  SHOW_CHILD
}

export interface DetailsAction {
  name: string;
  faIcon: any;
  disabled?: boolean;
  type?: DetailsActionType;
  hasChild?: boolean;
  handler?: () => void;
}
