import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UpdateMetricsDataItem, UpdateMetricsObjectRecord } from '../../types/update-metrics-data-item.interface';
import { UpdateMetricsChangeEvent } from '../../types/update-metrics-change-event.interface';


@Component({
  selector: 'update-metrics-panel',
  styleUrls: ['./update-metrics-panel.component.scss'],
  templateUrl: './update-metrics-panel.component.html'
})
export class UpdateMetricsPanelComponent implements OnChanges {
  @Input() data: UpdateMetricsDataItem;
  @Input() isExpanded = false;
  @Input() isReadOnly = true;
  @Input() currencySymbol = '';
  @Output() onHeaderClick = new EventEmitter();
  @Output() onCurrentValueChange = new EventEmitter<UpdateMetricsChangeEvent>();
  @Output() onObjectLinkClick = new EventEmitter<{ metricType: string; objectId: number }>();

  public isDecimal = false;
  public decimalPipeFormat = '1.2-2';
  public panelHeaderHeight = '48px';
  public maskTooltip = `We've masked the part of the metric value that is coming from your third party integration(s)`;

  public handleHeaderAction() {
    this.onHeaderClick.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.isDecimal = this.data.isDecimal;
      this.decimalPipeFormat = this.isDecimal ? '1.2-2' : '1.0-0';
    }
  }

  public objectLinkHandler(record: UpdateMetricsObjectRecord) {
    this.onObjectLinkClick.emit({
      metricType: record.objectType,
      objectId: record.mappingId
    });
  }

  public handleCurrentValueChange(value: number, record: UpdateMetricsObjectRecord) {
    this.onCurrentValueChange.emit({
      value,
      record,
      item: this.data
    });
  }
}
