<table
  *ngIf="rows && rows.length"
  [class.single-column]="columnTitles?.length === 1">
  <thead>
  <tr>
    <th class="title-column">
                <span class="table-header-cell">
                  <span>
                    <ng-container
                      [ngTemplateOutlet]="thContentTemplateRef || defaultThContent"
                      [ngTemplateOutletContext]="{ $implicit: columnTitles[0], column: { columnName: 'rowTitle'} }"
                    ></ng-container>
                    <sort-button *ngIf="!!appliedSorting" columnName="rowTitle" [appliedSorting]="appliedSorting" (onClick)="onSortRows.emit('rowTitle')"></sort-button>
                  </span>
                </span>
    </th>
    <ng-container *ngFor="let columnTitle of columnTitles; let i = index;">
      <th *ngIf="i > 0" [ngClass]="rows[0].columns[i - 1]?.className">
                  <span class="table-header-cell">
                    <span
                      (mouseenter)="displayTooltip($event, { fieldName: rows[0].columns[i - 1]?.fieldName, value: null })"
                      (mouseleave)="hideTooltip()"
                    >
                      <ng-container
                        [ngTemplateOutlet]="thContentTemplateRef || defaultThContent"
                        [ngTemplateOutletContext]="{ $implicit: columnTitle, column: rows[0].columns[i - 1] }"
                      ></ng-container>
                      <sort-button *ngIf="!!appliedSorting" [columnName]="rows[0]?.columns[i - 1]?.fieldName" [appliedSorting]="appliedSorting" (onClick)="onSortRows.emit(rows[0]?.columns[i - 1]?.fieldName)"></sort-button>
                    </span>
                  </span>
      </th>
    </ng-container>
  </tr>
  </thead>
  <tr *ngFor="let row of rows"
      class="{{ row.className }}"
      [ngClass]="{
                'hidden': row.groupId && !groupsExpandState[row.groupId]
              }">
    <td class="title-column">
              <span class="table-data-cell">
                <ng-container
                  [ngTemplateOutlet]="rowTitleTemplateRef || defaultRowTitle"
                  [ngTemplateOutletContext]="{ $implicit: row }"
                ></ng-container>
              </span>
    </td>
    <td *ngFor="let item of row.columns" [ngClass]="item.className">
              <span class="table-data-cell">
                <span class="data-cell-content"
                      (mouseenter)="displayTooltip($event, item)"
                      (mouseleave)="hideTooltip()"
                >
                  <ng-template [ngIf]="item.value < 0" [ngIfElse]="positiveNumberTemplate">
                    <span class="negative-value">
                      ({{ -item.value | number }})
                    </span>
                  </ng-template>
                  <ng-template #positiveNumberTemplate>
                    {{ item.value | number }}
                  </ng-template>
                  <span class="flame-icon" *ngIf="warningIcon && row.fieldName | isWarningCell: item.fieldName : item.value">
                    <icon-flame></icon-flame>
                  </span>
                </span>
              </span>
    </td>
  </tr>
</table>

<ng-template #defaultRowTitle let-row>
  <span
    [matTooltip]="row.title.length > 36 ? row.title : ''"
    matTooltipPosition="above"
    matTooltipClass="dark-theme-tooltip above"
    matTooltipShowDelay="800">
    {{ row.title || '' }}
  </span>
</ng-template>
<ng-template #defaultThContent let-columnTitle>
  {{ columnTitle }}
</ng-template>

<div class="table-view-tooltip"
 [class.is-visible]="tooltipState.isVisible"
 [ngStyle]="tooltipState.styles"
 [ngClass]="tooltipState.className"
 #tooltipContainer
>
  <ng-container
    [ngTemplateOutlet]="tooltipsTemplate"
    [ngTemplateOutletContext]="{ $implicit: tooltipState.type }"
  ></ng-container>
</div>
