import { Component, inject } from '@angular/core';
import { Configuration } from 'app/app.constants';

@Component({
  selector: 'shared-cost-rule-tips',
  templateUrl: './shared-cost-rule-tips.component.html',
  styleUrls: ['./shared-cost-rule-tips.component.scss']
})
export class SharedCostRuleTipsComponent {
  private readonly configuration = inject(Configuration);
  public readonly knowledgeLink: string = this.configuration.costSharingRulesKnowledgeLink;
}
