import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';

@Component({
  selector: 'open-expenses-widget',
  styleUrls: ['./open-expenses-widget.component.scss'],
  templateUrl: './open-expenses-widget.component.html'
})
export class OpenExpensesWidgetComponent implements OnInit {
  @Input() config: WidgetConfig;
  @Output() onLoaded = new EventEmitter();
  public state = WidgetState.INITIAL;

  constructor(private readonly widgetStateManager: WidgetStateService) {}

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);

    setTimeout(() => {
      this.setState(WidgetState.READY);
    }, 3000);
  }

  private setState(state) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
  }
}
