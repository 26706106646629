import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { overlayConfigFactory } from 'ngx-modialog-7';
import { BSModalContext, Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { ActionHandlingContext, ActionSelectOption, ExpenseAction, ExpenseActionHandlingType, ExpenseActionType } from '@spending/types/expense-action.type';
import { AbstractCustomMenuComponent } from 'app/header-navigation/components/menu-panel/abstract-custom-menu.component';
import { ExpensePageSelectionService } from '@spending/services/expense-page-selection.service';
import { UtilityService } from '@shared/services/utility.service';
import { ExpenseDO } from '@shared/types/expense.interface';
import { ErrorsModalComponent } from '@spending/components/errors-modal/errors-modal.component';
import { SpendingSidebarService } from '@spending/services/spending-sidebar.service';
import { ExpenseActionsService } from '@spending/services/expense-actions.service';
import { SpendingService } from '@spending/services/spending.service';

@Component({
  selector: 'action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
  providers: [{ provide: AbstractCustomMenuComponent, useExisting: ActionMenuComponent }]
})
export class ActionMenuComponent extends AbstractCustomMenuComponent {
  @Input() actionItems: ExpenseAction[];
  @Output() onSelect = new EventEmitter<void>();
  @Output() onActionComplete = new EventEmitter<void>();

  public ExpenseActionType = ExpenseActionType;
  constructor(
    private readonly modal: Modal,
    private readonly utilityService: UtilityService,
    private readonly spendingSidebarService: SpendingSidebarService,
    private readonly expenseActionsService: ExpenseActionsService,
    private readonly expensePageSelectionService: ExpensePageSelectionService,
    private readonly spendingService: SpendingService
  ) {
    super();
  }

  isSingleSelect(action: ExpenseAction): boolean {
    const handlingConfig = action.handlingConfig;
    return handlingConfig.handlingType === ExpenseActionHandlingType.SingleSelect && !!handlingConfig.selectOptions?.length;
  }

  selectOptionValue(action: ExpenseAction, selectedOption: ActionSelectOption): void {
    const selectedExpenses = this.selectedExpenses;
    this.executeAction(
      action.actionType,
      this.expenseActionsService.createContextForSingleSelect(selectedExpenses, selectedOption)
    );
  }

  applyDirectAction(action: ExpenseAction): void {
    const selectedExpenses = this.selectedExpenses;
    this.executeAction(
      action.actionType,
      this.expenseActionsService.createContextForDirectAction(action.actionType, selectedExpenses)
    );
  }

  private executeAction(actionType: ExpenseActionType, context$: Observable<ActionHandlingContext>): void {
    context$.pipe(
      switchMap(context => {
        return this.isTotalSelected
          ? this.spendingService.getFilteredExpenseIds().pipe(
            map(ids => {
              context.totalExpenseIds = ids;
              return context;
            }))
          : of(context);
      }),
      switchMap(context => this.expenseActionsService.executeAction(actionType, context).pipe(
        tap(() => {
          if (context.toastMessage) {
            this.spendingService.showActionToastr(context.toastMessage, context.undoPayloads);
          }
        })
      ))
    ).subscribe({
      next: result => this.handleResult(result),
      error: error => this.handleError(error)
    });
  }

  private get selectedExpenses(): ExpenseDO[] {
    return Object.values(this.expensePageSelectionService.itemSelectionValue).map(row => row.expenseObject);
  }

  private get isTotalSelected(): boolean {
    return this.expensePageSelectionService.isTotalSelectedValue;
  }

  private handleResult(result: { error: { [key: number]: string }[] }): void {
    this.onActionComplete.emit();

    if (result.error?.length) {
      this.notifyAboutFailed(result.error);
    }
    if (this.isTotalSelected) {
      this.expensePageSelectionService.clearSelection();
    }
  }

  private handleError(error): void {
    if (typeof error !== 'string' && error?.type !== 'userCancel') {
      const defaultMessage = 'Request has failed';
      if (!error.message) {
        error.message = defaultMessage;
      }
      this.utilityService.handleError(error);
    }
  }

  private notifyAboutFailed(errors: { [key: number]: string }[]): void {
    this.showErrorModal(
      errors.map(error => {
        const expenseId = Object.keys(error)[0];
        const expense = this.selectedExpenses.find(exp => exp.id?.toString() === expenseId?.toString());
        return {
          expense,
          message: error[expenseId]
        };
      })
    );
  }

  private showErrorModal(expenseErrors: { expense: ExpenseDO, message: string }[]): void {
    this.modal.open(
      ErrorsModalComponent,
      overlayConfigFactory({
        message: `Update for ${expenseErrors.length} expenses failed. Please try again.`,
        errors: expenseErrors.map(error => ({
          name: error.expense && error.expense.name || '',
          details: error.message
        }))
      }, BSModalContext)
    );
  }
}
