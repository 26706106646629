<svg
  *ngIf="isMonoChrome; else nonMonoChrome"
  viewBox="0 0 118 117"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="width || size"
  [attr.height]="height || size"
>
  <path
    d="M117.125 59C117.125 26.8906 91.1094 0.875 59 0.875C26.8906 0.875 0.875 26.8906 0.875 59C0.875 88.0625 21.9688 112.203 49.8594 116.422V75.875H35.0938V59H49.8594V46.3438C49.8594 31.8125 58.5312 23.6094 71.6562 23.6094C78.2188 23.6094 84.7812 24.7812 84.7812 24.7812V39.0781H77.5156C70.25 39.0781 67.9062 43.5312 67.9062 48.2188V59H84.0781L81.5 75.875H67.9062V116.422C95.7969 112.203 117.125 88.0625 117.125 59Z"
    [attr.fill]="color"
  />
</svg>
<ng-template #nonMonoChrome>
  <svg
    viewBox="0 0 118 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width || size"
    [attr.height]="height || size"
  >
    <path
      style="fill: #0063b7"
      d="M117.125 59C117.125 26.8906 91.1094 0.875 59 0.875C26.8906 0.875 0.875 26.8906 0.875 59C0.875 88.0625 21.9688 112.203 49.8594 116.422V75.875H35.0938V59H49.8594V46.3438C49.8594 31.8125 58.5312 23.6094 71.6562 23.6094C78.2188 23.6094 84.7812 24.7812 84.7812 24.7812V39.0781H77.5156C70.25 39.0781 67.9062 43.5312 67.9062 48.2188V59H84.0781L81.5 75.875H67.9062V116.422C95.7969 112.203 117.125 88.0625 117.125 59Z"
      [attr.fill]="color"
    />
  </svg>
</ng-template>
