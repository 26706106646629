<div
  scrollOnDrag
  tableContentShadows
  (onShadowChanged)="handleShadowUpdate($event)"
  class="table-wrap">
  <table>
    <thead>
    <tr>
      <th *ngFor="let column of visibleColumns; let i = index">
        <div class="cell-content"
             [expenseTableCellAlignment]="column.align"
             [@cellContent]
             [@.disabled]="i === 0">
          <mat-checkbox
            *ngIf="i === 0 && rows?.length > 1"
            class="mr-05"
            color="primary"
            [checked]="isAllSelected"
            (change)="onSelectAllChange($event)"
          >
          </mat-checkbox>
          {{ column.title }}
        </div>
      </th>
    </tr>
    <tr class="subtotal-row" *ngIf="rows?.length && subtotalData">
      <th *ngFor="let column of visibleColumns; let i = index">
        <div class="cell-content"
             [expenseTableCellAlignment]="column.align"
             [@cellContent]
             [@.disabled]="i === 0">
          <span *ngIf="i === 0">Page Subtotal:</span>
          <span *ngIf="i !== 0" class="font-monospace">{{ subtotalData[column.id] | number : numberFormatLong }}</span>
        </div>
      </th>
    </tr>
    <tr class="shadow-tr">
      <td [colSpan]="visibleColumns.length"></td>
    </tr>
    </thead>
    <tbody>
      <ng-container #rowContainer></ng-container>
    </tbody>
  </table>
</div>

<mat-menu #timeframeMenu="matMenu">
  <button
    *ngFor="let item of timeframeList"
    class="mat-menu-panel-btn"
    [class.active]="timeframeSelectOptions?.timeframeId === item.id"
    [class.disabled]="item.locked"
    (click)="selectTimeframe(item.id)">
    <fa-duotone-icon [icon]="['fad', 'lock-alt']" *ngIf="item.locked" class="lock-icon"></fa-duotone-icon>
    {{ item.name }}<span *ngIf="item.year">, {{ item.year }}</span>
  </button>
</mat-menu>

<mat-menu #statusMenu="matMenu">
  <expense-status-select
    [statusSelectOptions]="statusSelectOptions"
    (statusUpdate)="onStatusUpdate($event)"
  ></expense-status-select>
</mat-menu>

<ng-template #rowTemplate let-row>
  <tr [ngClass]="{
          'opened-in-drawer': openedInDrawerId === row.expenseId,
          'is-selected': isRowSelected(row)
        }">
    <td *ngFor="let column of visibleColumns">
      <div class="cell-content p-0"
           *ngIf="column.id === ExpenseTableColumn.NAME"
           [expenseTableCellAlignment]="column.align"
           dragImage
           [plDraggable]="dragAllowed && !row.isPseudoObject"
           [imageName]="row[ExpenseTableColumn.NAME]?.name"
           [icon]="dragIcon"
           [groupIcon]="dragIcon"
           [selectedRecordsCount]="rowSelectedNumber"
           (dragStart)="onHandleDragStart(row)"
           (dragEnd)="onHandleDragEnd()">
        <expense-name-cell
          [name]="row[ExpenseTableColumn.NAME]?.name"
          [isReadOnlyMode]="readOnlyMode"
          [itemSelected]="isRowSelected(row)"
          [selectionDisabled]="row.isPseudoObject"
          [createdDate]="row[ExpenseTableColumn.NAME]?.createdDate"
          [timeframeLocked]="timeframeIsLocked(row)"
          [dragAllowed]="!row.isPseudoObject && dragAllowed"
          (select)="onRowSelectionChange($event, row)"
          (nameClick)="onNameClick(row.expenseId)"
        ></expense-name-cell>
        <expense-verified-icon
          *ngIf="isExpenseUnverified(row)"
        ></expense-verified-icon>
      </div>

      <div class="cell-content"
           *ngIf="column.id === ExpenseTableColumn.PLANNED ||
                    column.id === ExpenseTableColumn.ACTUAL ||
                    (column.id === ExpenseTableColumn.DIFFERENCE && row[ExpenseTableColumn.STATUS]?.value !== expenseAllocationMode.Planned)"
           [expenseTableCellAlignment]="column.align"
           [@cellContent]>
        <expense-amount
          [class]="row[column.id].cssClass"
          [disabled]="disabledCellEdit(row, column.id)"
          [convertedValue]="row[column.id].convertedAmount"
          [sourceValue]="row[column.id].sourceAmount"
          [numberFormat]="numberFormatLong"
          (onChange)="onAmountUpdate(column.id, row, $event)"
        ></expense-amount>
      </div>

      <div class="cell-content"
           *ngIf="column.id === ExpenseTableColumn.STATUS"
           [expenseTableCellAlignment]="column.align"
           [@cellContent]>
        <div *ngIf="row[ExpenseTableColumn.STATUS]?.value as currentStatus"
             class="select-trigger select-trigger-status"
             [matMenuTriggerFor]="statusMenu"
             [class.disabled]="disabledRowEdit(row)"
             [class]="row[column.id].cssClass"
             (click)="setStatusSelectOptions(row)">
          <span [class]="'status-trigger-text ' + currentStatus.toLowerCase()">{{ currentStatus }}</span>
          <fa-icon class="chevron" [icon]="['far', 'chevron-down']"></fa-icon>
        </div>
      </div>

      <div class="cell-content"
           *ngIf="column.id === ExpenseTableColumn.TIMEFRAME"
           [expenseTableCellAlignment]="column.align"
           [@cellContent]>
        <div
          *ngIf="row[ExpenseTableColumn.TIMEFRAME]?.value as timeframeId"
          class="select-trigger select-trigger-timeframe"
          [class]="row[column.id].cssClass"
          [matMenuTriggerFor]="timeframeMenu"
          [class.disabled]="disabledRowEdit(row)"
          (click)="setTimeframeSelectOptions(row, timeframeId)">
          <fa-duotone-icon [icon]="['fad', 'lock-alt']" *ngIf="timeframeMap[timeframeId]?.locked" class="lock"></fa-duotone-icon>
          {{ timeframeMap[timeframeId]?.name }}
          <fa-icon class="chevron" [icon]="['far', 'chevron-down']"></fa-icon>
        </div>
      </div>

      <div class="cell-content parent-cell"
           *ngIf="column.id === ExpenseTableColumn.SEGMENT || column.id === ExpenseTableColumn.PARENT"
           [expenseTableCellAlignment]="column.align"
           [@cellContent]
           [ngClass]="{ 'clickable': column.id === ExpenseTableColumn.PARENT && row[column.id].valueName !== 'No Parent' }"
           [disabled]="column.id === ExpenseTableColumn.SEGMENT"
           (click)="onParentNameClick(row[column.id].valueType, row.expenseObject)">
        <object-name
          [class]="row[column.id].cssClass"
          [matTooltip]="row[column.id].valueName"
          [matTooltipShowDelay]="1000"
          matTooltipPosition="above"
          matTooltipClass="dark-theme-tooltip simple multi-line"
          [name]="row[column.id].valueName"
          [objectType]="row[column.id].valueType"></object-name>
      </div>

      <div class="cell-content"
           *ngIf="column.id === ExpenseTableColumn.OWNER"
           [expenseTableCellAlignment]="column.align"
           [@cellContent]>
        <object-name
          [ngStyle]="{'justify-content': column.align}"
          [name]="row[ExpenseTableColumn.OWNER]?.value"
          [showIcon]="false"></object-name>
      </div>

      <div class="cell-content"
           *ngIf="column.id === ExpenseTableColumn.RELATED"
           [expenseTableCellAlignment]="column.align"
           [@cellContent]>
        <button *ngIf="row[ExpenseTableColumn.RELATED]?.value as relationGroup"
                class="cell-button primary"
                [class]="row[ExpenseTableColumn.RELATED].cssClass"
                mat-icon-button
                (click)="handleRelatedClick(relationGroup)">
          <fa-icon [icon]="['fad', 'link']"></fa-icon>
        </button>
      </div>

      <div class="cell-content"
           *ngIf="column.id === ExpenseTableColumn.ATTACHMENT"
           [expenseTableCellAlignment]="column.align"
           [@cellContent]>
        <button *ngIf="row[ExpenseTableColumn.ATTACHMENT]?.value as attachmentAmount"
                [matTooltip]="attachmentAmount + ' attachment' + (attachmentAmount > 1 ? 's' : '')"
                [matTooltipShowDelay]="1000"
                matTooltipPosition="above"
                matTooltipClass="dark-theme-tooltip simple"
                class="cell-button dark"
                mat-icon-button
                (click)="onAttachmentsClick(row.expenseId)">
          <fa-icon [icon]="['far', 'paperclip']"></fa-icon>
        </button>
      </div>

      <div class="cell-content"
           *ngIf="isDefaultCell[column.id]"
           [expenseTableCellAlignment]="column.align"
           [@cellContent]>
        <span class="cell-content-default" [class]="row[column.id].cssClass">{{ row[column.id].value }}</span>
      </div>

    </td>
  </tr>
</ng-template>
