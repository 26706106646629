import { inject, Injectable } from '@angular/core';
import { BaseBudgetObjectAmountsLoader } from './base-budget-object-amounts-loader';
import { GoalsService } from '@shared/services/backend/goals.service';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Injectable()
export class GoalAmountsLoader extends BaseBudgetObjectAmountsLoader {
  readonly rowType = ManageTableRowType.Goal;

  constructor() {
    super(inject(GoalsService));
  }
}
