import { Injectable } from '@angular/core';
import { MenuPanelItem } from '../../header-navigation/components/menu-panel/menu-panel.type';

export enum MenuItem {
  ACTIONS = 'actions',
  SORT = 'sort',
  MANAGE_COLUMNS = 'manage_columns',
  EXPORT = 'export',
}

@Injectable()
export class SpendingManageMenuService {
  private editPermissions: boolean;
  private actionsMap: Partial<Record<MenuItem, MenuPanelItem>> = null;
  private permissionsRequiredItems = [
    MenuItem.ACTIONS,
  ];

  public getMenuItems(): MenuPanelItem[] {
    return this.actionsMap ? Object.values(this.actionsMap) : [];
  }

  public set setMenuMap(actionsMap: Partial<Record<MenuItem, MenuPanelItem>>) {
    this.actionsMap = actionsMap;
    this.updateActionsItemPermissions();
  }

  public onEditPermissionChanged(editPermissions: boolean): void {
    this.editPermissions = editPermissions;
    this.updateActionsItemPermissions();
  }

  private updateActionsItemPermissions(): void {
    if (!this.actionsMap) {
      return;
    }
    this.permissionsRequiredItems.forEach(key => {
      this.actionsMap[key].hidden = !this.editPermissions;
    });
  }

  public updateActionsItemState(hasSelection: boolean) {
    if (!this.actionsMap) {
      return;
    }
    this.actionsMap[MenuItem.ACTIONS].disabled = !hasSelection;
  }

}
