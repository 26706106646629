import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef, ModalComponent } from 'ngx-modialog-7';
import { Modal, BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { Validations } from 'app/app.validations';
import { Configuration } from 'app/app.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { SuperadminService } from '../../shared/superadmin.service';
import { HttpStatusCode } from '@angular/common/http';

export class SuperadminLogsModalContext extends BSModalContext {}

@Component({
  selector: 'modal-content',
  styles: [],
  templateUrl: './superadmin-logs.html'
})
export class SuperadminLogsModal implements ModalComponent<SuperadminLogsModalContext>, OnInit, OnDestroy {
  subscriptions = [];
  context: SuperadminLogsModalContext;
  isPopupLoading = false;
  logs = [];

  constructor(
    public readonly dialog: DialogRef<SuperadminLogsModalContext>,
    public readonly configuration: Configuration,
    public readonly validations: Validations,
    public readonly utilityService: UtilityService,
    public readonly router: Router,
    public readonly modal: Modal,
    public readonly superadminService: SuperadminService
  ) {
    this.context = dialog.context;
    this.context.keyboard = [];

    // Show popup loader
    this.subscriptions.push(
      this.utilityService.popupLoadingPropertyChanged$.subscribe(
      value => setTimeout(() => this.isPopupLoading = value, 0)
      )
    );
  }

  ngOnInit() {
    this.getSuperadminLogs();
  }

  getSuperadminLogs() {
    this.utilityService.showPopupLoading(true);
    this.subscriptions.push(this.superadminService.getSuperadminLogs().subscribe(
      data => this.success(data, 'getSuperadminLogs'),
      error => this.handleError(error)
    ));
  }

  closeModal() {
    this.dialog.close();
  }

  success(data, type) {
    if (Number(data?.status) === HttpStatusCode.Ok) {
      if (type === 'getSuperadminLogs') {
        this.logs = data.data;
        this.utilityService.showPopupLoading(false);
      }
    } else {
      this.utilityService.showToast({ Title: '', Message: data.message, Type: 'error' });
      this.utilityService.showPopupLoading(false);
      this.utilityService.showLoading(false);
    }
  }

  handleError(error) {
    if (error.hasOwnProperty('message')) {
      this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
    } else if (error.hasOwnProperty('detail')) {
      this.utilityService.showLoading(false);
      this.utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
      this.dialog.close();
    }
    this.utilityService.showPopupLoading(false);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
