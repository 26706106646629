import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, concat, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Configuration, ObjectsIconConfig } from 'app/app.constants';
import { Budget } from 'app/shared/types/budget.interface';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { FilterManagementService, ParamsDef } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { Currency } from 'app/shared/types/currency.interface';
import { ManageTableDataService } from './manage-table-data.service';
import { ManagePageModeService } from './manage-page-mode.service';
import { Goal } from 'app/shared/types/goal.interface';
import { Campaign, LightCampaign } from 'app/shared/types/campaign.interface';
import { LightProgram, Program } from 'app/shared/types/program.interface';
import { BudgetSegmentAccess, BudgetSegmentDO } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { ManageTableRecordInteractionsService } from './manage-table-record-interactions.service';
import { ManageTableViewMode, ManageTableViewModeChange } from '../types/manage-table-view-mode.type';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HierarchySelectModalComponent } from '../components/hierarchy-select-modal/hierarchy-select-modal.component';
import { HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { HierarchyDialogContext } from '../components/hierarchy-select-modal/hierarchy-select-modal.types';
import { createDeepCopy, getIdToNameMap, roundDecimal } from 'app/shared/utils/common.utils';
import { LocationService } from 'app/budget-object-details/services/location.service';
import { ManageTableDataBuilderInputs } from '../types/manage-table-data-inputs.type';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { ManageTableDataMutationService } from './manage-table-data-mutation.service';
import { ManageTableSelectionState } from '../types/manage-table-selection-state.types';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import {
  CreateItemTemplateEvent,
  ManageTableActionDataSource,
  ManageTableActionEvent,
  ManageTableAllocationsUpdatePayload,
  ManageTableRow
} from '../components/manage-table/manage-table.types';
import { BulkActionTargets } from '@shared/types/bulk-action-targets.type';
import { ManageTableHelpers } from './manage-table-helpers';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';
import { CloneableRowTypes } from '../components/manage-table/manage-table.constants';
import { BudgetObjectSegmentData } from 'app/shared/types/budget-object-segment-data.interface';
import { SegmentMenuHierarchyService } from 'app/shared/services/segment-menu-hierarchy.service';
import { BudgetObjectDetailsManager } from '../../budget-object-details/services/budget-object-details-manager.service';
import { PlanObjectExpenses } from '../types/plan-object-expenses.interface';
import { UserManager } from 'app/user/services/user-manager.service';
import { messages, objectCounter } from 'app/budget-object-details/messages';
import { UserDataService } from 'app/shared/services/user-data.service';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';
import { SegmentExpensesData } from 'app/shared/types/plan-object-expenses-data.type';
import { SummaryBarItem } from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { BudgetSummaryBarHelpers } from '@shared/components/budget-summary-bar/budget-summary-bar.helpers';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { MetricMappingChange } from 'app/budget-object-details/types/budget-object-change.interface';
import { MetricMappingDO, MetricService } from 'app/shared/services/backend/metric.service';
import { ManageTableBasicAction } from '@shared/manage-table-actions/manage-table-basic-action';
import { BudgetAllocationActionsService } from '../../budget-allocation/services/budget-allocation-actions.service';
import { ManageTableActionHistoryService } from '@shared/services/manage-table-action-history.service';
import { BudgetAllocationAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';
import { ManageTableChangeSegmentAction } from '@shared/manage-table-actions/manage-table-change-segment.action';
import { ManageTableChangeParentAction } from '@shared/manage-table-actions/manage-table-change-parent.action';
import { BudgetSegmentService } from 'app/shared/services/backend/budget-segment.service';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';
import { ManagePageTagsService } from './manage-page-tags.service';
import { ManageTableUpdateAction } from '@shared/types/manage-table-update-action';
import { ManagePageExportService } from './manage-page-export.service';
import { Router } from '@angular/router';
import { ActiveToast } from 'ngx-toastr';
import { ExpenseCostAdjustmentDataService } from '../../metric-integrations/expense-cost-adjustment/expense-cost-adjustment-data.service';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { AddMetricDialogComponent } from '../components/add-metric-dialog/add-metric-dialog.component';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { getMetricSelectItems } from '../../budget-object-details/components/details-metrics/metric-masters-list/metric-masters-list.component';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { HierarchyViewMode } from '@spending/types/expense-page.type';
import { BudgetAllocationTopupAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-topup-action';
import { BudgetAllocationMoveAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-move-action';
import { CampaignService } from '@shared/services/backend/campaign.service';
import { ProgramService } from '@shared/services/backend/program.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { ManageTableDataLoader } from './manage-table-data-loader/manage-table-data-loader';
import { BudgetPlanObjects } from '@shared/types/budget-plan-objects.type';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

export interface TableData {
  timeframes: BudgetTimeframe[];
  viewModeChange: ManageTableViewModeChange;
  campaigns: LightCampaign[];
  expGroups: LightProgram[];
  goals: Goal[];
  segments: BudgetSegmentAccess[];
  segmentGroups: SegmentGroup[];
  sharedCostRules: SharedCostRule[];
  currentCompanyUser: CompanyUserDO;
  budgetSegments: BudgetSegmentDO[];
}

export interface ManageTableState {
  togglingState: Record<string, boolean>;
  activeRowId: string;
}

export interface BackNavigationContext {
  pageName: string;
  route: string;
}

export const LS_KEY_MANAGE_TABLE_STATE = 'manage_table_state';

@Injectable()
export class ManagePageService implements OnDestroy {
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly utilityService = inject(UtilityService);
  private readonly configuration = inject(Configuration);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly tableDataService = inject(ManageTableDataService);
  private readonly modeService = inject(ManagePageModeService);
  private readonly recordInteractionsService = inject(ManageTableRecordInteractionsService);
  private readonly matDialog = inject(MatDialog);
  private readonly locationService = inject(LocationService);
  private readonly dataMutationService = inject(ManageTableDataMutationService);
  private readonly dialogService = inject(BudgetObjectDialogService);
  private readonly segmentMenuService = inject(SegmentMenuHierarchyService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly userManager = inject(UserManager);
  private readonly userDataService = inject(UserDataService);
  private readonly objectAccessManager = inject(ObjectAccessManagerService);
  private readonly metricMappingDetailsService = inject(MetricMappingDetailsService);
  private readonly actionsManager: BudgetAllocationActionsService<ManageTableActionDataSource> = inject(BudgetAllocationActionsService);
  private readonly historyManager: ManageTableActionHistoryService<BudgetAllocationAction<any>> = inject(ManageTableActionHistoryService);
  private readonly budgetSegmentService = inject(BudgetSegmentService);
  private readonly routingService = inject(AppRoutingService);
  private readonly manageTableRecordInteractionsService = inject(ManageTableRecordInteractionsService);
  private readonly exportService = inject(ManagePageExportService);
  private readonly tagsManager = inject(ManagePageTagsService);
  private readonly router = inject(Router);
  private readonly expenseCostAdjustmentDataService = inject(ExpenseCostAdjustmentDataService);
  private readonly metricService = inject(MetricService);
  private readonly campaignService = inject(CampaignService);
  private readonly programService = inject(ProgramService);
  private readonly manageTableDataLoader = inject(ManageTableDataLoader);

  private readonly destroy$ = new Subject<void>();
  private currentFilters: FilterSet;
  private budgetSegments: BudgetSegmentDO[] = [];
  private segmentExpensesData: SegmentExpensesData = {};
  private campaigns: Campaign[] = [];
  private expenseGroups: Program[] = [];
  private lightCampaigns: LightCampaign[] = [];
  private lightExpenseGroups: LightProgram[] = [];
  private goals: Goal[] = [];
  private budgetPlanObjects: BudgetPlanObjects;
  private expenses: PlanObjectExpenses;
  private segmentGroups: SegmentGroup[] = [];
  private currentCompanyUser: CompanyUserDO = null;
  private budgetObjectsLoadingState = {
    goals: false,
    campaigns: false,
    expGroups: false,
  };
  private objectsContainerByRowType = {};
  private allowedSegmentBreakdownFilters = [FilterName.Segments, FilterName.Timeframes];
  private readonly dataResetTrigger = new Subject<void>();

  public budgetCurrency: Currency;
  public budget: Budget;
  public company: CompanyDO;
  public timeframes: BudgetTimeframe[] = [];
  public filteredTimeframes: BudgetTimeframe[] = [];
  public hierarchyItems: HierarchySelectItem[];
  public segments: BudgetSegmentAccess[] = [];
  public sharedCostRules: SharedCostRule[] = [];
  public editPermission = false;
  public isAdmin = false;
  public segmentBreakdownRestrictedByFilters = false;
  public reflectFiltersInLocation = true;
  public campaignTypes: BudgetObjectType[] = [];
  public expGroupTypes: BudgetObjectType[] = [];
  public objectTypeNameMap: Record<string, Record<number, string>> = {
    [ManageTableRowType.Campaign]: {},
    [ManageTableRowType.ExpenseGroup]: {}
  };
  private OBJECT_TYPES = this.configuration.OBJECT_TYPES;

  public readonly iconsConfig: ObjectsIconConfig = this.configuration.iconsConfig;
  public summaryBarItems: SummaryBarItem[][] = null;
  public summaryBarLoading$ = new BehaviorSubject<boolean>(true);
  public restoredTogglingState: Record<string, boolean>;
  public restoredActiveRowId: string;
  public remainingBudget: number;
  private backNavToast: ActiveToast<any>;
  private metrics: MetricType[] = [];
  private products: ProductDO[] = [];
  public externalGoalChangeActive = false;

  private static containsSegmentlessObject(campaigns: LightCampaign[]): boolean {
    return campaigns != null && campaigns.some(campaign => !campaign.budgetSegmentId && !campaign.splitRuleId);
  }

  constructor() {
    this.appRoutingService.setInitFiltersForHistoryNavigation();
    this.readState();

    const prevLocation = this.router.getCurrentNavigation().extras.state?.backNavToastFor;
    if (prevLocation) {
      this.backNavToast = this.utilityService.showToastrNav(prevLocation);
    }

    this.companyDataService.selectedCompanyDO$
      .pipe(
        filter(cmp => cmp != null),
        takeUntil(this.destroy$)
      )
      .subscribe(company => this.onSelectedCompanyChanged(company));

    this.userDataService.editPermission$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        editPermission => this.editPermission = editPermission
      );

    this.metricMappingDetailsService.metricMappingChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(change => this.updateCampaignOnMetricMappingChange(change));

    this.companyDataService.campaignTypesList$
      .pipe(
        takeUntil(this.destroy$),
        tap(campaignTypes => {
          this.campaignTypes = campaignTypes;
          this.objectTypeNameMap[ManageTableRowType.Campaign] = getIdToNameMap(campaignTypes);
        })
      )
      .subscribe();

    this.companyDataService.programTypes$
      .pipe(
        takeUntil(this.destroy$),
        tap(expGroupTypes => {
          this.expGroupTypes = expGroupTypes;
          this.objectTypeNameMap[ManageTableRowType.ExpenseGroup] = getIdToNameMap(expGroupTypes);
        })
      )
      .subscribe();

    this.initBudgetDataListeners();

    this.budgetObjectDetailsManager.getMetricTypes()
      .pipe(
        takeUntil(this.destroy$),
        tap(metrics => this.metrics = metrics)
      )
      .subscribe();

    this.companyDataService.products$
      .pipe(
        takeUntil(this.destroy$),
        tap(products => this.products = products)
      )
      .subscribe();
  }

  private initBudgetDataListeners() {
    const currentFilters$ =
      this.filterManagementService.budgetFiltersInit$.pipe(
        switchMap(() => this.filterManagementService.currentFilterSet$),
        tap(filterSet => this.onCurrentFiltersChange(filterSet))
      );

    let prevFilters = this.currentFilters;

    this.budgetDataService.selectedBudget$.pipe(
      tap(budget => this.onSelectedBudgetChanged(budget)),
      switchMap(
        () => combineLatest([this.initTableData$(), currentFilters$])
      ),
      filter(() => {
        const proceed = !this.externalGoalChangeActive;
        this.externalGoalChangeActive = false;
        return proceed;
      }),
      switchMap((result: [TableData, FilterSet]) => {
        this.summaryBarLoading$.next(true);
        const [data, filterSet] = result;
        const filtersChanged =
          prevFilters == null && filterSet != null && Object.keys(filterSet).length > 0 ||
          prevFilters != null && prevFilters !== filterSet;

        prevFilters = filterSet;

        return this.getPlanObjectsList$(data, filtersChanged).pipe(
          map(([planObjects, expGroups, campaigns]) => [{ ...data, campaigns, expGroups }, planObjects])
        );
      }),
      tap(([data, planObjects]: [TableData, BudgetPlanObjects]) => this.setInitialTableData(data, planObjects)),
      switchMap(
        (data: [TableData, BudgetPlanObjects]) => concat(
          of(null), // For the first trigger
          this.budgetObjectDetailsManager.budgetObjectChanged$.pipe(
            filter(change => change.objectType === this.configuration.OBJECT_TYPES.expense)
          )
        ).pipe(
          map(() => data)
        )
      ),
      takeUntil(this.destroy$)
    ).subscribe({
      next: ([data, planObjects]: [TableData, BudgetPlanObjects]) => this.loadTableInnerData(data, planObjects),
      error: error => this.utilityService.handleError(error)
    });
  }

  private loadTableInnerData(data: TableData, planObjects: BudgetPlanObjects): void {
    this.manageTableDataLoader.loadFullPlanObjects$(
      this.budget,
      this.filteredTimeframes,
      data,
      planObjects,
      this.budgetDataService.lightProgramsSnapshot,
      this.budgetDataService.lightCampaignsSnapshot
    ).pipe(
      tap(([planObjectExpenses, segmentExpenses]) => {
        this.expenses = planObjectExpenses;
        this.segmentExpensesData = segmentExpenses;
      }),
      tap(() => this.loadTagsData()),
      tap(() => this.onInnerTableDataLoaded())
    ).subscribe({
      error: error => {
        this.utilityService.handleError({ message: 'Failed to load the table data' });
        console.error(error);
      }
    });

    if (data.viewModeChange.value === ManageTableViewMode.Goals) {
      this.manageTableDataLoader.handleGoalRows(this.filteredTimeframes);
    }
  }

  private onInnerTableDataLoaded() {
    this.tableDataService.initGrandTotal(this.filteredTimeframes);
    this.summaryBarLoading$.next(false);

    this.dataResetTrigger.next();
    concat(of(null), this.dataMutationService.grandTotalUpdateTrigger)
      .pipe(
        takeUntil(merge(this.dataResetTrigger, this.destroy$))
      )
      .subscribe(
        () => {
          this.tableDataService.initGrandTotal(this.filteredTimeframes);
          this.updateRemainingBudget();
        }
      );
  }

  ngOnDestroy(): void {
    this.hideBackNavToast();
    this.destroy$.next();
    this.destroy$.complete();
    this.dataResetTrigger.next();
    this.dataResetTrigger.complete();
  }

  private initTableData$(): Observable<TableData> {
    let expGroupChange = false;

    const timeframes$ = this.budgetDataService.timeframeList$.pipe(
      tap(data => this.timeframes = data)
    );
    const viewModeChange$ = this.modeService.viewModeChange.pipe(
      filter(mode => mode?.value != null)
    );
    const campaigns$ = this.budgetDataService.lightCampaignList$.pipe(
      tap(data => {
        this.lightCampaigns = data;
        this.budgetObjectsLoadingState.campaigns = true;
      })
    );
    const expGroups$ = this.budgetDataService.lightProgramList$.pipe(
      tap(data => {
        if (this.expenseGroups?.length > 0) {
          expGroupChange = true;
        }
        this.lightExpenseGroups = data;
        this.budgetObjectsLoadingState.expGroups = true;
      })
    );
    const goals$ = this.budgetDataService.goalList$.pipe(
      tap(data => {
        this.goals = data;
        this.budgetObjectsLoadingState.goals = true;
      })
    );
    const segments$ = this.budgetDataService.segmentList$.pipe(
      tap(data => this.segments = data)
    );
    const segmentGroups$ = this.budgetDataService.segmentGroupList$.pipe(
      tap(data => this.segmentGroups = data)
    );
    const sharedCostRules$ = this.budgetDataService.sharedCostRuleList$.pipe(
      tap(data => this.sharedCostRules = data)
    );
    const currentUser$ = this.userManager.currentCompanyUser$.pipe(
      filter(user => user != null),
      tap(user => {
        this.currentCompanyUser = user;
        this.isAdmin = this.userDataService.isAdmin(user);
      })
    );
    const budgetSegments$ = this.budgetSegmentService.getBudgetSegments(this.budget.id).pipe(
      tap(data => this.budgetSegments = data)
    );

    return combineLatest([
      timeframes$,
      viewModeChange$,
      campaigns$,
      expGroups$,
      goals$,
      segments$,
      segmentGroups$,
      sharedCostRules$,
      currentUser$,
      budgetSegments$,
    ]).pipe(
      filter(() => Object.values(this.budgetObjectsLoadingState).every(state => state)),
      switchMap(
        (data: [
          BudgetTimeframe[],
          ManageTableViewModeChange,
          LightCampaign[],
          LightProgram[],
          Goal[],
          BudgetSegmentAccess[],
          SegmentGroup[],
          SharedCostRule[],
          CompanyUserDO,
          BudgetSegmentDO[],
        ]) => {
          const [
            timeframes,
            viewModeChange,
            campaigns,
            expGroups,
            goals,
            segments,
            segmentGroups,
            sharedCostRules,
            currentCompanyUser,
            budgetSegments,
          ] = data;

          const resData = {
            timeframes,
            viewModeChange,
            campaigns,
            expGroups,
            goals,
            segments,
            segmentGroups,
            sharedCostRules,
            currentCompanyUser,
            budgetSegments,
          };

          // If an exp group was added/updated/removed we should not only reload the exp groups
          //  but also the campaigns (if there are segment-less campaigns - to make sure we get updated by BE campaigns)
          if (expGroupChange && ManagePageService.containsSegmentlessObject(campaigns)) {
            expGroupChange = false;

            return this.budgetDataService.getLightCampaigns(this.company.id, this.budget.id).pipe(
              map(newCampaigns => {
                this.lightCampaigns = newCampaigns;
                resData.campaigns = newCampaigns;
                return resData;
              })
            );
          }

          return of(resData);
        })
    );
  }

  private setInitialTableData(data: TableData, planObjects: BudgetPlanObjects): void {
    const {
      timeframes,
      viewModeChange,
      campaigns,
      goals,
      segments,
      segmentGroups,
      sharedCostRules,
      currentCompanyUser,
      budgetSegments
    } = data;

    const expGroups = this.currentFilters.metrics?.length ? [] : data.expGroups;
    this.setFilteredTimeframes();

    this.tableDataService.setFilteredMode(
      Object.values(this.currentFilters).some(fv => fv.length)
    );

    this.objectsContainerByRowType = {
      [ManageTableRowType.Goal]: this.goals,
      [ManageTableRowType.Campaign]: this.lightCampaigns,
      [ManageTableRowType.ExpenseGroup]: this.lightExpenseGroups,
    };

    this.recordInteractionsService.resetSelection();

    this.tableDataService.clearSourceObjects();
    this.tableDataService.setSourceObjects({
      campaigns: [],
      expenseGroups: [],
      sharedCostRules: this.sharedCostRules
    });

    this.setHierarchyItems({
      viewMode: viewModeChange.value,
      campaigns,
      expGroups,
      goals,
      segmentGroups,
      segments,
      sharedCostRules,
    });

    this.summaryBarItems = BudgetSummaryBarHelpers.getSummaryBarItems({
      totalObjects: {
        goals: goals?.length || 0,
        segments: segments?.length || 0,
        campaigns: this.getCampaignCountForSegmentView(viewModeChange.value, campaigns),
        expGroups: expGroups?.length || 0,
      },
      filteredObjects: planObjects,
      isFilterMode: this.tableDataService.isFilteredMode,
      configuration: this.configuration,
    });

    this.tableDataService.prepareData({
      isFilterMode: this.tableDataService.isFilteredMode,
      timeframes,
      filteredTimeframes: this.filteredTimeframes,
      viewMode: viewModeChange.value,
      campaigns,
      expGroups,
      goals,
      segmentGroups,
      segments,
      sharedCostRules,
      budget: this.budget,
      planObjects,
      budgetSegments,
      isPowerUser: this.userDataService.isPowerUser(currentCompanyUser),
    });

    this.applyRestoredTogglingState();
  }

  private updateRemainingBudget(): void {
    this.remainingBudget = roundDecimal(this.budget.amount - this.tableDataService.grandTotal.segment?.total?.allocated, 2);
  }

  private getPlanObjectsList$(
    tableData: TableData,
    filtersChanged: boolean
  ): Observable<[BudgetPlanObjects, LightProgram[], LightCampaign[]]> {
    this.tableDataService.setLoading(true);

    const { viewModeChange, campaigns, expGroups, segments, sharedCostRules, currentCompanyUser } = tableData;
    const shouldLoadPlanObjects =
      filtersChanged ||
      viewModeChange.value === ManageTableViewMode.Segments ||
      viewModeChange.prevValue === ManageTableViewMode.Segments;

    const needToGetAllPseudoObjects = viewModeChange.value === ManageTableViewMode.Segments && sharedCostRules.length > 0;
    const segmentIds = segments.map(seg => seg.id).join(',');
    const isPowerUser = this.userDataService.isPowerUser(currentCompanyUser);

    const getFilteredByAccess = <T extends LightProgram | LightCampaign>(objects: T[]) => {
      return isPowerUser ?
        objects :
        objects
          .filter(obj =>
            this.objectAccessManager.hasAccessBySegmentData(
              { split_rule: obj.splitRuleId, company_budget_segment1: obj.budgetSegmentId },
              segments,
              sharedCostRules
            )
          );
    };

    const getSegmentedObjects$ = <T extends LightCampaign | LightProgram>(
      objectsLoader: (companyId: number, budgetId: number, status: string, params: object) => Observable<T[]>,
      objectStatus: string,
      currentSegmentedObjects: T[]
    ) => {
      const needToGetAllSegmentedPseudoObjects = needToGetAllPseudoObjects && currentSegmentedObjects.some(obj => obj.splitRuleId != null);
      const filterParams = { company_budget_segment1_ids: segmentIds };

      return needToGetAllSegmentedPseudoObjects || !isPowerUser ?
        objectsLoader(
          this.company.id,
          this.budget.id,
          objectStatus,
          needToGetAllSegmentedPseudoObjects ? { ...filterParams, include_pseudo_objects: true } : filterParams
        ).pipe(
          map(objects => getFilteredByAccess(objects))
        ) :
        of(currentSegmentedObjects);
    };

    const allPlanObjects$ = forkJoin([
      getSegmentedObjects$(
        this.budgetDataService.getLightPrograms.bind(this.budgetDataService),
        this.configuration.programStatusNames.active,
        expGroups
      ),
      getSegmentedObjects$(
        this.budgetDataService.getLightCampaigns.bind(this.budgetDataService),
        this.configuration.campaignStatusNames.active,
        campaigns
      )
    ]);

    let planObjects$: Observable<BudgetPlanObjects>;
    if (shouldLoadPlanObjects) {
      const filterParamsObj = this.getCommonParamsForObjectsRequest(viewModeChange.value, segments, campaigns);

      const programs$ =
        this.budgetDataService.getLightPrograms(
          this.company.id,
          this.budget.id,
          this.configuration.programStatusNames.active,
          filterParamsObj
        );

      const campaigns$ =
        this.budgetDataService.getLightCampaigns(
          this.company.id,
          this.budget.id,
          this.configuration.campaignStatusNames.active,
          filterParamsObj
        ).pipe(
          map(objects => getFilteredByAccess(objects)),
        );

      planObjects$ = forkJoin({
        expGroups: programs$,
        campaigns: campaigns$
      });
    } else {
      planObjects$ = of(this.budgetPlanObjects || { campaigns, expGroups: expGroups });
    }

    return forkJoin([planObjects$, allPlanObjects$]).pipe(
      tap(([planObjects]) => this.budgetPlanObjects = planObjects),
      map(([planObjects, allPlanObjects]) => [planObjects, ...allPlanObjects])
    );
  }

  private getCurrentTableDataSnapshot(): TableData {
    return {
      timeframes: this.timeframes,
      viewModeChange: this.modeService.viewModeChange.getValue(),
      campaigns: this.lightCampaigns,
      expGroups: this.lightExpenseGroups,
      goals: this.goals,
      segments: this.segments,
      segmentGroups: this.segmentGroups,
      sharedCostRules: this.sharedCostRules,
      currentCompanyUser: this.currentCompanyUser,
      budgetSegments: this.budgetSegments,
    };
  }

  private getCommonParamsForObjectsRequest(
    viewMode: ManageTableViewMode,
    allSegments: BudgetSegmentAccess[],
    campaigns: LightCampaign[]
  ): object {
    const isViewBySegment = viewMode === ManageTableViewMode.Segments;
    const requestData = {};
    const requestParamsDef: ParamsDef = {
      'company_budget_allocation_ids': { filterName: FilterName.Timeframes },
      'goal_ids': { filterName: FilterName.Goals },
      'tag_ids': { filterName: FilterName.Tags },
      'owner_ids': { filterName: FilterName.Owners },
      'campaign_ids': {
        filterName: FilterName.Campaigns,
        defaultValue: () => this.filterManagementService.getFilterCampaignIds(campaigns)
      },
      'campaign_type_ids': { filterName: FilterName.CampaignTypes },
      'program_ids': { filterName: FilterName.ExpenseBuckets },
      'expense_type_ids': { filterName: FilterName.ExpenseTypes },
      'gl_code_ids': { filterName: FilterName.GlCodes },
      'vendor_ids': { filterName: FilterName.Vendors },
      'split_rule_ids': { filterName: FilterName.SharedCostRules },
      'source': { filterName: FilterName.ExpenseSource },
      'metric_ids': { filterName: FilterName.Metrics },
      'company_budget_segment1_ids': {
        filterName: FilterName.Segments,
        defaultValue: () => isViewBySegment ?
          allSegments?.map(segment => segment.id) :
          this.filterManagementService.getDefaultSegments(allSegments)
      },
      'include_pseudo_objects': { defaultValue: () => isViewBySegment ? 'true' : null },
      'po_numbers': { filterName: FilterName.PONumber }
    };
    this.filterManagementService.setParamsFromFilters(requestData, requestParamsDef, true);
    return requestData;
  }

  private onSelectedBudgetChanged(budget: Budget) {
    if (budget?.new_campaigns_programs_structure) {
      setTimeout(() => {
        this.appRoutingService.openPlanDetail(
          [ this.configuration.OBJECT_TYPES.segment ], { queryParamsHandling: 'preserve' }
        );
      });

      return;
    }

    if (budget && budget.id !== this.budget?.id) {
      // LOAD BUDGET RELATED DATA
      this.tableDataService.setLoading(true);
      this.loadBudgetObjects(budget.id);
      this.historyManager.reset();
    }
    this.budget = budget;
  }

  private onSelectedCompanyChanged(companyDO: CompanyDO) {
    this.company = companyDO;
    this.companyDataService.loadCompanyData(companyDO.id);
    this.budgetCurrency = {
      code: companyDO.currency,
      name: companyDO.currency,
      symbol: companyDO.currency_symbol
    };
  }

  private loadBudgetObjects(budgetId: number, withGoals = false) {
    if (!budgetId || !this.company?.id) {
      return;
    }

    this.budgetObjectsLoadingState = {
      goals: !withGoals,
      campaigns: false,
      expGroups: false,
    };

    if (withGoals) {
      this.budgetDataService.loadGoals(
        this.company.id,
        this.budget.id,
        this.configuration.goalStatusNames.active,
          error => this.utilityService.handleError(error)
      );
    }

    this.budgetDataService.loadLightCampaigns(
      this.company?.id,
      budgetId,
      this.configuration.campaignStatusNames.active,
      error => this.utilityService.handleError(error)
    );

    this.budgetDataService.loadLightPrograms(
      this.company?.id,
      budgetId,
      this.configuration.programStatusNames.active,
      error => this.utilityService.handleError(error)
    );
  }

  private loadMetricMappings(ids: number[]) {
    return this.metricMappingDetailsService.getMetricMappings(this.company.id, {
      ids: ids.join(','),
      mapping_type: this.configuration.OBJECT_TYPES.campaign
    });
  }

  private getCampaignCountForSegmentView(viewMode: ManageTableViewMode, campaigns: LightCampaign[]): number {
    return viewMode === ManageTableViewMode.Segments && campaigns.length ?
      campaigns.filter(campaign => !!campaign.budgetSegmentId).length :
      campaigns?.length || 0;
  }

  private updatePerformanceColumnData(metricMappings: MetricMappingDO[]) {
    const targetCampaigns = this.lightCampaigns.filter(
      campaign => metricMappings.find(mapping => mapping.map_id === campaign.id)
    );
    this.tableDataService.setPerformanceColumnData(metricMappings, targetCampaigns, this.budget, false);
  }

  private refreshPerformanceColumnData(mappingIds: number[]) {
    this.loadMetricMappings(mappingIds)
      .pipe(
        tap(metricMappings => this.updatePerformanceColumnData(metricMappings))
      )
      .subscribe({
        error: err => this.utilityService.handleError(err)
      });
  }

  private loadTagsData() {
    this.tagsManager.loadTags();
  }

  private updateCampaignOnMetricMappingChange(change: MetricMappingChange) {
    const { mappingId, isKeyMetric, mapId, metricId } = change;
    const targetCampaign = this.campaigns.find(campaign => campaign.id === mapId);
    if (!targetCampaign) {
      return;
    }

    if (isKeyMetric) {
      targetCampaign.keyMetric = mappingId;
      this.refreshPerformanceColumnData([ mappingId ]);
    } else if (targetCampaign?.keyMetric === mappingId) {
      targetCampaign.keyMetric = null;
      this.tableDataService.removePerformanceColumnEntry(targetCampaign.id);
    }

    const parentCampaignId = targetCampaign.parentCampaign;
    const parentCampaign = parentCampaignId ? this.campaigns.find(campaign => campaign.id === parentCampaignId) : null;

    if (!parentCampaign?.keyMetric || !metricId) {
      return;
    }

    // Implicitly reload parent campaign's key metric data if any
    this.loadMetricMappings([ parentCampaign.keyMetric ])
      .pipe(
        map(metricMappings => metricMappings.filter(item => item.metric_master === metricId)),
        tap(metricMappings => {
          if (metricMappings.length) {
            this.updatePerformanceColumnData(metricMappings);
          }
        })
      )
      .subscribe({
        error: err => this.utilityService.handleError(err)
      });
  }

  private setHierarchyItems(params: Partial<ManageTableDataBuilderInputs>): void {
    const { viewMode, campaigns, goals, segmentGroups, segments, sharedCostRules } = params;

    if (viewMode === ManageTableViewMode.Segments) {
      this.hierarchyItems = this.segmentMenuService.prepareDataForSegmentMenu(
        {
          segments,
          groups: segmentGroups,
          rules: sharedCostRules,
          campaigns,
        });
      this.hierarchyItems.forEach(segmentItem => {
        if (segmentItem.objectType === this.OBJECT_TYPES.segmentsGroup) {
          segmentItem.notClickable = true;
        }
      });
    } else {
      const { items: locationHierarchyItems } = this.locationService.createLocationHierarchyItems({
        goals: viewMode === ManageTableViewMode.Campaigns ? [] : goals,
        campaigns,
        programs: [],
        currentLocation: null,
        segments,
        rules: sharedCostRules,
        isPowerUser: false,
      }, true);
      if (viewMode === ManageTableViewMode.Goals) {
        locationHierarchyItems.sort((a, b) => {
          return a.objectType === this.OBJECT_TYPES.goal && b.objectType !== a.objectType ? -1 : 1;
        });
      }
      if (viewMode === ManageTableViewMode.Campaigns) {
        locationHierarchyItems.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      }
      this.hierarchyItems = locationHierarchyItems;
    }
  }

  public openHierarchySelection(record?: ManageTableRow) {
    const selection = this.recordInteractionsService.selectionState;
    const hierarchyItems = this.filterSelectItems(selection);
    const dialogContextData: HierarchyDialogContext = {
      title: 'Move to',
      selectItems: hierarchyItems,
      allowGroupSelection: true,
      submitAction: {
        label: 'Move',
        handler: null,
      },
    };
    const dialogConfig: MatDialogConfig = {
      width: '480px',
      panelClass: ['reset-paddings', 'hierarchy-select-modal'],
      data: dialogContextData
    };
    const dialogRef = this.matDialog.open(HierarchySelectModalComponent, dialogConfig);

    dialogRef.afterClosed()
      .pipe(
        tap(option => {
          if (!option && record) {
            this.recordInteractionsService.implicitUnSelect(record);
          }
        }),
        filter(option => !!option)
      )
      .subscribe((selectedOption: HierarchySelectItem) => {
        this.moveSelectedItems(selectedOption);
      });
  }

  filterSelectItems(selection: ManageTableSelectionState) {
    const currentMode = this.modeService.viewMode;
    let hierarchyItems: HierarchySelectItem[] = createDeepCopy(this.hierarchyItems);

    const someCampaignSelected = !!selection.campaigns.size;
    const parentCampaignSelected = this.hasParentCampaignSelection(selection.campaigns);
    const topLevelParentId = -1;

    const resetChildren = (option: HierarchySelectItem) => {
      option.children = [];
      return option;
    };

    const mapIdsToObjectsArray = (idSet, objectsArray) => {
      return [...idSet].map( id => objectsArray.find(c => c.id === id));
    };

    const disableItemsInTree = (items: HierarchySelectItem[], idsList) => {
      items.forEach(item => {
        if (idsList.includes(item.objectId)) {
          item.notClickable = true;
        }
        if (item.children?.length) {
          disableItemsInTree(item.children, idsList);
        }
      });
    };

    const removeSelectedItemsFromTree = (items: HierarchySelectItem[], idsSet: Set<number>) => {
      items = items.filter(i => !idsSet.has(i.objectId));
      items.forEach(item => {
        if (item.children?.length) {
          item.children = removeSelectedItemsFromTree(item.children, idsSet);
        }
      });
      return items;
    };

    const getParentIds = (objects, key, altKey?): number[] => {
      return objects.reduce((arr, camp) => {
        // campaign can be child for: 1) Campaign 2) Goal 3) Segment
        const parentId = camp?.[key] || camp?.[altKey] || topLevelParentId;
        if (parentId && !arr.includes(parentId)) {
          arr.push(parentId);
        }
        return arr;
      }, []);
    };

    const selectedCampaigns = mapIdsToObjectsArray(selection.campaigns, this.lightCampaigns);
    const selectedExpGroups = mapIdsToObjectsArray(selection.expGroups, this.lightExpenseGroups);
    const hasSegmentlessCampaign = selectedCampaigns.some(campaign => BudgetObjectService.isSegmentlessObject(campaign));

    const altCampParentKey = currentMode === ManageTableViewMode.Goals ? 'goalId' :
      currentMode === ManageTableViewMode.Segments ? 'budgetSegmentId' : null;
    const parentIds = [
      ...getParentIds(selectedCampaigns, 'parentCampaign', altCampParentKey),
      ...getParentIds(selectedExpGroups, 'campaignId'),
      ...getParentIds(selectedCampaigns, 'budgetSegmentId'),
      ...getParentIds(selectedExpGroups, 'budgetSegmentId'),
    ];

    const clearCampaigns = (items: HierarchySelectItem[]) => {
      items = items.filter(el => el.objectType !== this.OBJECT_TYPES.campaign);
      items.forEach(i => {
        i.children = (i.children || []).filter(el => el.objectType !== this.OBJECT_TYPES.campaign);
        i.children = clearCampaigns(i.children);
      });
      return items;
    };

    const clearChildCampaigns = (items: HierarchySelectItem[] = []) => {
      items.forEach(i => {
        if (i.objectType === this.OBJECT_TYPES.campaign) {
          i.children = [];
        } else {
          i.children = clearChildCampaigns(i.children);
        }
      });
      return items;
    };

    if (currentMode === ManageTableViewMode.Segments) {
      if (parentCampaignSelected) {
        // remove all campaigns
        hierarchyItems = clearCampaigns(hierarchyItems);
      } else if (someCampaignSelected) {
        // remove children from campaigns
        hierarchyItems = clearChildCampaigns(hierarchyItems);
      }
    }

    if (currentMode === ManageTableViewMode.Goals) {
      if (parentCampaignSelected || hasSegmentlessCampaign) {
        // remove all campaigns
        hierarchyItems = hierarchyItems
          .filter(el => el.objectType === this.OBJECT_TYPES.goal)
          .map(resetChildren);
      } else if (someCampaignSelected) {
        // remove children from campaigns
        hierarchyItems = clearChildCampaigns(hierarchyItems);
      }
    }

    if (currentMode === ManageTableViewMode.Campaigns) {
      if (someCampaignSelected) {
        hierarchyItems.forEach(resetChildren);
      }
    }

    disableItemsInTree(hierarchyItems, parentIds);
    return removeSelectedItemsFromTree(hierarchyItems, selection.campaigns);
  }

  public hasParentCampaignSelection(campaignIdsSet: Set<number>): boolean {
    return this.lightCampaigns.some(camp => camp.parentCampaign && campaignIdsSet.has(camp.parentCampaign));
  }

  private getSelectedItems() {
    return this.recordInteractionsService.selectionState;
  }

  private closeItems(targets: BulkActionTargets, saveToHistory = true) {
    const itemsToClose = ManageTableHelpers.getBulkTargetsTotalCount(targets);
    const dialogTitle = messages.CLOSE_MULTIPLE_OBJECTS_TITLE.replace(objectCounter, itemsToClose.toString());

    this.dialogService.openConfirmationDialog({
      title: dialogTitle,
      content: messages.CLOSE_OBJECT_MSG,
      submitAction: {
        label: 'OK',
        handler: () => {
          const action = new ManageTableBasicAction({
            execute: () => {
              this.tableDataService.setLoading(true);
              this.recordInteractionsService.resetSelection();
              this.dataMutationService.updateObjectsMode(
                targets,
                ObjectMode.Closed,
                () => this.tableDataService.setLoading(false)
              );
            },
            undo: () => {
              this.reopenItems(targets, false);
            }
          });
          this.actionsManager.executeAction(action);
          if (saveToHistory) {
            this.historyManager.pushAction(action);
          }
        }
      },
      cancelAction: {
        label: 'Cancel',
        handler: null
      }
    },
    {
      width: '480px'
    });
  }

  private reopenItems(targets: BulkActionTargets, saveToHistory = true) {
    const action = new ManageTableBasicAction({
      execute: () => {
        this.tableDataService.setLoading(true);
        this.recordInteractionsService.resetSelection();
        this.dataMutationService.updateObjectsMode(
          {
            ...targets,
            childCampaigns: [],
            childExpGroups: []
          },
          ObjectMode.Open,
          () => this.tableDataService.setLoading(false)
        );
      },
      undo: () => {
        this.closeItems(targets, false);
      }
    });
    this.actionsManager.executeAction(action);
    if (saveToHistory) {
      this.historyManager.pushAction(action);
    }
  }

  private deleteItems(targets: BulkActionTargets) {
    const itemsToDelete = ManageTableHelpers.getBulkTargetsTotalCount(targets);
    this.expenseCostAdjustmentDataService.setIntegrationExpenses(this.expenseGroups, this.expGroupTypes);

    const deleteHandler = () => {
      this.tableDataService.setLoading(true);
      this.dataMutationService.deleteObjects(targets, () => {
        this.loadBudgetObjects(this.budget.id, true);
      });
    };
    const dialogMessage = `Are you sure you want to delete ${itemsToDelete === 1 ? 'this object' : 'these objects'}
      <br>You cannot undo this action.`;

    this.dialogService.openDeleteEntityDialog(deleteHandler, null, { title: '', message: dialogMessage });
  }

  private moveItems(targets: BulkActionTargets, moveTarget: HierarchySelectItem, movedItemsCount?: number) {
    const { segment, campaign, goal, sharedCostRule } = this.OBJECT_TYPES;

    if ([sharedCostRule, segment].includes(moveTarget.objectType)) {
      // MOVING TO SEGMENT/SCR
      const segmentData: BudgetObjectSegmentData = {
        budgetSegmentId: moveTarget.objectType === segment ? moveTarget.objectId : null,
        sharedCostRuleId: moveTarget.objectType === sharedCostRule ? moveTarget.objectId : null
      };
      const action = new ManageTableChangeSegmentAction<ManageTableDataService, ManageTableDataMutationService>({
        executeResultHandler: (results, childrenSegmentInheritance) => {
          if (results) {
            this.loadBudgetObjects(this.budget.id);
            if (!childrenSegmentInheritance) {
              this.historyManager.pushAction(action);
            }
          } else {
            this.tableDataService.setLoading(false);
          }
        },
        undoResultHandler: (results) => {
          if (results) {
            this.loadBudgetObjects(this.budget.id);
          } else {
            this.tableDataService.setLoading(false);
          }
        },
        bulkTargets: targets,
        segmentData,
        dataService: this.tableDataService,
        mutationService: this.dataMutationService,
      });
      this.actionsManager.executeAction(action);
      return;
    }

    if ([campaign, goal].includes(moveTarget.objectType)) {
      // MOVING TO NEW PARENT
      const parentData = {
        objectId: moveTarget.objectId,
        objectType: moveTarget.objectType,
        segmentData: moveTarget.segmentData
      };
      const undoCallbacks = [];
      const action = new ManageTableChangeParentAction<ManageTableDataService, ManageTableDataMutationService>({
        executeResultHandler: (results, childrenSegmentInheritance) => {
          if (results) {
            this.loadBudgetObjects(this.budget.id);
            if (!childrenSegmentInheritance) {
              this.historyManager.pushAction(action);
            }
          } else {
            this.tableDataService.setLoading(false);
          }
        },
        undoResultHandler: (results) => {
          if (results) {
            this.loadBudgetObjects(this.budget.id);
          } else {
            this.tableDataService.setLoading(false);
          }
        },
        dataService: this.tableDataService,
        mutationService: this.dataMutationService,
        bulkTargets: targets,
        parentData,
        timeframes: this.timeframes,
        suppressTfAllocations: this.budget.suppress_timeframe_allocations,
        movedItemsCount,
        undoCallbacks
      });

      this.actionsManager.executeAction(action);
    }
  }

  private onCurrentFiltersChange(filterSet: FilterSet) {
    if (!FilterManagementService.hasSelectedFilters(filterSet)) {
      this.hideBackNavToast();
    }

    this.currentFilters = { ...filterSet };

    if (this.reflectFiltersInLocation) {
      this.appRoutingService.updateCurrentFiltersInRouting(
        this.company?.id,
        this.budget?.id,
        this.currentFilters
      );
    }

    this.segmentBreakdownRestrictedByFilters =
      Object.keys(filterSet)
        .filter(key => !this.allowedSegmentBreakdownFilters.includes(key as FilterName))
        .some(key => filterSet[key]?.length);
  }

  private setFilteredTimeframes() {
    const filteredTimeframeIds = this.currentFilters?.[FilterName.Timeframes] || [];
    this.filteredTimeframes = filteredTimeframeIds.length
      ? this.timeframes.filter(tf => filteredTimeframeIds.includes(tf.id))
      : [...this.timeframes];
  }

  public closeSelectedItems() {
    if (!this.editPermission) {
      return;
    }
    const selection = this.getSelectedItems();

    this.addChildObjectsForClose(selection);
    this.closeItems(ManageTableHelpers.getBulkTargetsFromSelection(selection));
  }

  private addChildObjectsForClose(selection: ManageTableSelectionState): void {
    const { campaigns } = selection;
    const campaignsList = this.lightCampaigns;
    const expGroupsList = this.lightExpenseGroups;

    const addChildIds = (
      selectionField: string,
      selectedIds: Set<number>,
      objectList: LightCampaign[] | LightProgram[],
      parentIdField: string
    ): void => {
      selection[selectionField] = new Set();
      objectList.forEach(object => {
        if (selectedIds.has(object[parentIdField])) {
          selection[selectionField].add(object['objectId']);
        }
      });
    };

    addChildIds('childCampaigns', campaigns, campaignsList, 'parentCampaign');
    addChildIds('childExpGroups', new Set([...campaigns, ...selection.childCampaigns]), expGroupsList, 'campaignId');
  }

  public closeItem(record: ManageTableRow) {
    if (!record || !this.editPermission) {
      return;
    }
    const bulkFromRecord = ManageTableHelpers.getBulkTargetsFromRecord(record);
    const tableSelectionState = {
      goals: new Set(bulkFromRecord.goals),
      campaigns: new Set(bulkFromRecord.campaigns),
      expGroups: new Set(bulkFromRecord.expGroups)
    } as ManageTableSelectionState;

    this.addChildObjectsForClose(tableSelectionState);
    this.closeItems(ManageTableHelpers.getBulkTargetsFromSelection(tableSelectionState));
  }

  public reopenSelectedItems() {
    if (!this.editPermission) {
      return;
    }
    const selection = this.getSelectedItems();

    this.reopenItems(ManageTableHelpers.getBulkTargetsFromSelection(selection));
  }

  public reopenItem(record: ManageTableRow) {
    if (!record || !this.editPermission) {
      return;
    }
    this.reopenItems(ManageTableHelpers.getBulkTargetsFromRecord(record));
  }

  public duplicateSelectedItems() {
    if (!this.editPermission) {
      return;
    }
    // For duplicating - selection must contain one object only
    const { records } = this.getSelectedItems();

    for (const entry of Object.entries(records)) {
      const [ recordId, selection ] = entry;
      const record = this.tableDataService.getRecordById(recordId);

      if (!CloneableRowTypes.includes(record.type) || selection.value !== CheckboxValue.Active) {
        continue;
      }

      this.duplicateItem(record);
      break;
    }
  }

  public duplicateItem(record: ManageTableRow): void {
    if (!record || !this.editPermission) {
      return;
    }

    this.dataMutationService.duplicateObject(
      record,
      this.budget,
      this.timeframes,
      (objType: ManageTableRowType, objId: string | number) => this.tableDataService.objectExists(objType, objId),
      createdObject => this.onObjectDuplicated(record, createdObject),
      ManageTableHelpers.getExpensesData(this.expenses, record.type)
    );
  }

  public createNewItemTemplate(createItemTemplateEvent: CreateItemTemplateEvent): void {
    if (!this.editPermission) {
      return;
    }
    this.dataMutationService.createNewItemTemplate(createItemTemplateEvent);
  }

  public get newItemCreationActive(): boolean {
    return !!this.dataMutationService.newItemTemplate;
  }

  public saveNewItemTemplateWithName(name: string): void {
    this.dataMutationService.saveNewItemTemplate(
      name,
      this.currentCompanyUser.user,
      this.company.id,
      this.budget,
      this.timeframes,
      (objType: ManageTableRowType, objId: string | number) => this.tableDataService.objectExists(objType, objId),
    );
  }

  public validateUniqueObjectName(objectType: string, name: string): Observable<{ status: string; message: string }> {
    const serviceByObjectType = {
      [ManageTableRowType.Campaign]: this.campaignService,
      [ManageTableRowType.ExpenseGroup]: this.programService,
    };
    return serviceByObjectType[objectType].validateUniqueName(this.company.id, this.budget.id, name);
  }

  private onObjectDuplicated(record: ManageTableRow, createdObject: Goal | Campaign | Program) {
    if (!createdObject) {
      return;
    }

    const couldSegmentlessObjectBeAffected =
      ManagePageService.containsSegmentlessObject(this.campaigns) &&
      [ManageTableRowType.Campaign, ManageTableRowType.ExpenseGroup].includes(record.type);

    if (couldSegmentlessObjectBeAffected) {
      if (record.type === ManageTableRowType.Campaign) {
        this.budgetDataService.loadLightCampaigns(this.company.id, this.budget.id);
      } else if (record.type === ManageTableRowType.ExpenseGroup) {
        this.budgetDataService.loadLightPrograms(this.company.id, this.budget.id);
      }
    } else {
      if (record.type === ManageTableRowType.Campaign && (<Campaign>createdObject).keyMetric) {
        this.refreshPerformanceColumnData([ (<Campaign>createdObject).keyMetric ]);
      }
      this.objectsContainerByRowType[record.type]?.push(createdObject);
      const tableDataSnapshot = this.getCurrentTableDataSnapshot();
      this.summaryBarLoading$.next(true);

      this.getPlanObjectsList$(tableDataSnapshot, true)
        .pipe(
          takeUntil(this.destroy$),
          tap(([budgetPlanObjects]) => this.setInitialTableData(tableDataSnapshot, budgetPlanObjects)),
          map(([planObjects, expGroups, campaigns]) => [{ ...tableDataSnapshot, campaigns, expGroups }, planObjects])
        )
        .subscribe(
          ([data, planObjects]: [TableData, BudgetPlanObjects]) => {
            this.loadTableInnerData(data, planObjects);
            this.tableDataService.setLoading(false);
          }
        );
    }
  }

  public exportData() {
    this.exportService.exportData({
      timeframes: this.timeframes,
      filteredTimeframes: this.filteredTimeframes,
      segments: this.segments,
      segmentGroups: this.segmentGroups,
      sharedCostRules: this.sharedCostRules,
      budget: this.budget
    });
  }

  public deleteSelectedItems() {
    if (!this.editPermission) {
      return;
    }
    const selection = this.getSelectedItems();

    this.deleteItems(ManageTableHelpers.getBulkTargetsFromSelection(selection));
  }

  public deleteItem(record: ManageTableRow) {
    if (!record || !this.editPermission) {
      return;
    }
    this.deleteItems(ManageTableHelpers.getBulkTargetsFromRecord(record));
  }

  public moveSelectedItems(targetObject: HierarchySelectItem, movedItemsCount?: number) {
    if (!this.editPermission) {
      return;
    }
    const selection = this.getSelectedItems();
    const bulkTargets = ManageTableHelpers.getBulkTargetsFromSelection(selection);
    const checkParentCampaign = (expenseGroups, entities: BulkActionTargets) => {
      expenseGroups.forEach(group => {
        if (entities.expGroups.includes(group.id) && entities.campaigns.includes(group.campaignId)) {
          const expIndex = entities.expGroups.indexOf(group.id);
          entities.expGroups.splice(expIndex, 1);
        }
      });
      return entities;
    };

    const updatedBulkTargets = checkParentCampaign(this.expenseGroups, bulkTargets);

    this.moveItems(updatedBulkTargets, targetObject, movedItemsCount);
  }

  public moveItem(record: ManageTableRow, moveTarget: HierarchySelectItem, movedItemsCount?: number) {
    if (!record || !this.editPermission) {
      return;
    }
    this.moveItems(ManageTableHelpers.getBulkTargetsFromRecord(record), moveTarget, movedItemsCount);
  }

  public changeSelectedItemsObjectType(objectTypeId: number, objectTypeName: string): void {
    if (!this.editPermission) {
      return;
    }

    const selection = this.getSelectedItems();
    const targets = ManageTableHelpers.getBulkTargetsFromSelection(selection);
    const submitHandler = () => {
      this.tableDataService.setLoading(true);
      this.dataMutationService.updateObjectsType(
        targets,
        objectTypeId,
        () => this.tableDataService.setLoading(false)
      );
    };
    const dialogMessage = `Change type of selected objects to <b>"${objectTypeName}"</b>`;

    this.dialogService.openConfirmationDialog({
      content: dialogMessage,
      title: 'Change Type',
      submitAction: {
        label: 'OK',
        handler: submitHandler
      },
      cancelAction: {
        label: 'Cancel',
        handler: null
      }
    });
  }

  openExpenseList(filters: FilterSet, togglingState: Record<string, boolean>, activeRowId: string, viewMode?: HierarchyViewMode): void {
    this.reflectFiltersInLocation = false;
    const pageContext: BackNavigationContext = {
      pageName: 'Manage page',
      route: this.modeService.getCurrentLocation(false),
    };
    this.routingService.openExpenseListFromManagePage(filters, togglingState, activeRowId, pageContext, viewMode);
  }

  private readState() {
    const state = LocalStorageService.getFromStorage<ManageTableState>(LS_KEY_MANAGE_TABLE_STATE);
    if (state) {
      if (this.appRoutingService.lastNavigationStartTrigger === 'popstate' || this.appRoutingService.shouldRestoreState) {
        this.restoredTogglingState = state.togglingState;
        this.restoredActiveRowId = state.activeRowId;
        this.appRoutingService.closeAllDetailsWindows();
      }
      LocalStorageService.removeFromStorage(LS_KEY_MANAGE_TABLE_STATE);
    }
  }

  private applyRestoredTogglingState() {
    if (this.restoredTogglingState) {
      const currentTogglingState = this.manageTableRecordInteractionsService.togglingState;
      if (currentTogglingState) {
        Object.entries(this.restoredTogglingState).forEach(([id, state]) => currentTogglingState[id] = state);
      }
      this.restoredTogglingState = null;
    }
  }

  public showRestoredActiveRow() {
    if (this.restoredActiveRowId) {
      const rowElement = document.querySelector(`[data-id="${this.restoredActiveRowId}"]`);
      if (rowElement) {
        rowElement.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'start'
        });
        this.restoredActiveRowId = null;
      }
    }
  }

  private updateSegment(payload: ManageTableAllocationsUpdatePayload): Observable<boolean> {
    return this.dataMutationService.updateSegmentAllocation(
      payload,
      this.budget.suppress_timeframe_allocations,
      this.budgetSegments,
      this.timeframes
    );
  }

  public updateSegmentAllocation($event: ManageTableActionEvent) {
    const { dataSource, amount } = $event;
    const action = new ManageTableUpdateAction<ManageTableActionDataSource>({
      context: {
        dataSource,
        amount
      },
      setAmount: (value, ds) => {
        const payload = ManageTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, {});
        return this.updateSegment(payload);
      },
      getAmount: (ds) => this.tableDataService.getDataSourceValue(ds, true)
    });

    this.actionsManager.executeAction(action, () => this.historyManager.pushAction(action));
  }

  public handleSegmentDoubleClick($event: ManageTableActionEvent): void {
    const actionMessageTrigger = new Subject<void>();
    const { gestureEvent, dataSource } = $event;
    const action = new BudgetAllocationTopupAction<ManageTableActionDataSource>({
      actionTarget: {
        dataSource,
        difference: gestureEvent.difference,
        surplus: gestureEvent.surplus,
        overspend: gestureEvent.overspend
      },
      setAmount: (value, ds) => {
        const payload = ManageTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, {});
        return this.updateSegment(payload).pipe(
          filter(result => result != null),
          takeUntil(this.destroy$),
          tap(result => {
            if (result) {
              actionMessageTrigger.next();
            }
          })
        );
      },
      getAmount: (ds) => this.tableDataService.getDataSourceValue(ds, true),
      currency: this.budgetCurrency.symbol
    });

    this.actionsManager.executeAction(
      action,
      () => this.historyManager.pushAction(action),
      viaSnackbar => {
        if (viaSnackbar) {
          this.historyManager.popAction();
        }
      },
      actionMessageTrigger
    );
  }

  public handleSegmentDrop($event: ManageTableActionEvent): void {
    let payload: ManageTableAllocationsUpdatePayload = {};
    const actionMessageTrigger = new Subject<void>();
    const onExecutedTrigger = new BehaviorSubject<boolean>(null);
    this.actionsManager.trackDrop($event.dataSource, $event.gestureEvent);

    const action = new BudgetAllocationMoveAction<ManageTableActionDataSource>({
      actionTarget: this.actionsManager.getDndTargets(),
      setAmount: (value, ds, isLast = false) => {
        payload = ManageTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, payload);
        if (isLast) {
          this.updateSegment(payload).pipe(
            filter(result => result != null),
            takeUntil(this.destroy$)
          ).subscribe(res => {
            if (res) {
              actionMessageTrigger.next();
            }
            onExecutedTrigger.next(res);
          });
          payload = {};
        }
      },
      getAmount: (ds) => this.tableDataService.getDataSourceValue(ds, true),
      currency: this.budgetCurrency?.symbol,
      onExecutedTrigger
    });
    this.actionsManager.executeAction(
      action,
      () => this.historyManager.pushAction(action),
      (viaSnackbar: boolean) => {
        if (viaSnackbar) {
          this.historyManager.popAction();
        }
      },
      actionMessageTrigger
    );
  }

  public addTagsToSelectedItems() {
    if (!this.editPermission) {
      return;
    }

    const selection = this.getSelectedItems();
    this.tagsManager.openAddTagsDialog(selection, this.company.id);
  }

  public removeTagsFromSelectedItems() {
    if (!this.editPermission) {
      return;
    }

    const selection = this.getSelectedItems();
    this.tagsManager.openRemoveTagsDialog(selection, this.company.id);
  }

  public hideBackNavToast() {
    if (this.backNavToast) {
      this.utilityService.hideToastr(this.backNavToast.toastId);
    }
    this.backNavToast = null;
  }

  public openMetricList() {
    const campaigns: any = Array.from(this.recordInteractionsService.selectionState.campaigns);
    const metrics$ = this.budgetObjectDetailsManager.getUnassignedCampaignMetrics(
      this.company.id,
      campaigns,
      this.products,
      this.metrics,
      this.configuration.OBJECT_TYPES.campaign
    );
    const dialogContextData = {
      title: 'Add Metrics',
      isAdmin: this.isAdmin,
      metrics$
    };
    const dialogConfig: MatDialogConfig = {
      width: '480px',
      panelClass: ['add-metric-dialog'],
      data: dialogContextData
    };
    const dialogRef = this.matDialog.open(AddMetricDialogComponent, dialogConfig);

    dialogRef.afterClosed()
      .pipe(
        filter(options => !!options),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedOption: string[]) => {
        const body = { campaigns, metric_masters: selectedOption };
        let metricMappingIds = [];

        const action = new ManageTableBasicAction({
          execute: () => {
            this.tableDataService.setLoading(true);
            this.recordInteractionsService.resetSelection();
            this.metricService.bulkAssignMetricToCampaign(body)
              .pipe(
                finalize(() => {
                  this.tableDataService.setLoading(false);
                }),
                takeUntil(this.destroy$)
                )
              .subscribe((mappingIds: number[]) => {
                metricMappingIds = mappingIds;
                if (mappingIds?.length) {
                  const message = `${campaigns.length} ${campaigns.length > 1 ? 'campaigns' : 'campaign'} updated successfully`;
                  this.utilityService.showCustomToastr(message);
                }
              }, error => this.utilityService.handleError(error));
          },
          undo: () => {
            if (!metricMappingIds.length) {
              return;
            }
            this.metricService.bulkDeleteMetricMapping(metricMappingIds)
              .pipe(takeUntil(this.destroy$))
              .subscribe( {
                error: err => this.utilityService.handleError(err)
              });
          }
        });
        this.actionsManager.executeAction(action, () => this.historyManager.pushAction(action));
      });
  }
}
