import { Component, HostBinding, Input, EventEmitter, Output } from '@angular/core';
import { MetricMaster, MetricValueType } from '@common-lib/lib/corporate-page/metric-funnels.types';
import { Subject } from 'rxjs';

export interface SimpleActionsConfig {
  addAbove: boolean;
  addBelow: boolean;
  canDelete: boolean;
}

@Component({
  selector: 'plc-metric-master',
  templateUrl: './metric-master.component.html',
  styleUrls: ['./metric-master.component.scss']
})

export class MetricMasterComponent {
  public metricValueType = MetricValueType;
  public maxMetricNameLength = 40;
  public simpleActionsConfig: SimpleActionsConfig;

  @Input() placeholderByValueType = {
    [MetricValueType.Currency]: 'Currency name',
    [MetricValueType.Count]: 'Count name'
  };
  @Input() selectTargetOnFocus = false;
  @Input() metric: MetricMaster;
  @Input() funnelDisabled: boolean;
  @Input() numberFormat = '1.0-10';
  @Input() isConnectedMetric: boolean;
  @Input() highlightNumber: boolean;
  @Input() targetOutlineStyle: number;
  @Input() prefix: string;
  @Input() precision: number;
  @Input() checkValidity$: Subject<void>;
  @Output() focusChanged = new EventEmitter<boolean>();
  @Output() nameChanged = new EventEmitter<string>();
  @Output() targetChanged = new EventEmitter<number>();
  @Output() errorStateChanged = new EventEmitter<boolean>();
  @Output() deactivateMetric = new EventEmitter<void>();
  @Output() deleteMetric = new EventEmitter<void>();
  @Output() addNewMetric = new EventEmitter<number>();
  @Output() usageCountClick = new EventEmitter<MetricMaster>();
  @HostBinding('class.flipped') public isFlipped: boolean;
  @HostBinding('class.simple-actions') public hasSimpleActions: boolean;
  @HostBinding('class.edit-mode') public isEditMode: boolean;
  @HostBinding('attr.data-metric-id') get metricId() { return this.metric?.id }
  @Input() set simpleActions(config: SimpleActionsConfig) {
    this.hasSimpleActions = !!config;
    this.simpleActionsConfig = config;
  };
  @Input() set flipped (val: boolean) {
    this.isFlipped = val;
  }
  @Input() set editMode (val: boolean) {
    this.isEditMode = val;
  }

  deactivate() {
    if (this.isConnectedMetric) {
      this.deactivateMetric.next();
    } else {
      this.deleteMetric.next();
    }
  }

  addMetric(diff: number) {
    this.addNewMetric.emit(diff);
  }

  clickUsageCount() {
    if (this.funnelDisabled) {
      return;
    }
    this.usageCountClick.emit(this.metric);
  }

  public handleErrorState(error: string | null) {
    this.errorStateChanged.emit(!!error);
  }

  public handleNameChange(name: string) {
    this.nameChanged.emit(name);
  }

  public handleTargetChange(target: number) {
    this.targetChanged.emit(target);
  }
}

