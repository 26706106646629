<nav mat-tab-nav-bar class="tab-switch" [ngClass]="{ 'square-mode': squareMode, 'no-min-width' : autoMinWidth }" [disableRipple]="true">
  <ng-container *ngFor="let option of options">
    <a
      *ngIf="!option.hidden"
      mat-tab-link
      (click)="handleOptionClick(option)"
      [active]="selectedValue === option.value"
      [matTooltip]="option.tooltip"
      [matTooltipShowDelay]="1000"
      matTooltipClass="dark-theme-tooltip simple"
      matTooltipPosition="above"
      [disabled]="!!option.disabled"
    >
      <fa-icon *ngIf="option.icon" [icon]="option.icon"></fa-icon>
      {{ option.title }}
      <span *ngIf="option.suffix" class="suffix">{{ option.suffix }}</span>
    </a>
  </ng-container>
</nav>
