import { Pipe, PipeTransform } from '@angular/core';
import { Configuration } from 'app/app.constants';

@Pipe({
  name: 'isWarningCell'
})
export class IsWarningCellPipe implements PipeTransform {
  constructor(private config: Configuration) {}

  transform(rowFieldName, columnFieldName, value): boolean {
    const { available } = this.config.statusFields;
    const isNegativeAvailable = (rowFieldName === available || columnFieldName === available) && value < 0;
    return isNegativeAvailable;
  }

}
