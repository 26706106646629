import { Injectable, inject } from '@angular/core';
import { API_V2_URL } from '../../../../../common-lib/src/lib/injection-tokens/url.tokens';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions, withAPIV0ErrorHandling } from '../../shared/utils/http-request.utils';
import { Observable } from 'rxjs';
import { BudgetGroup } from '../../shared/services/budget-selection-modal.service';


@Injectable(
  { providedIn: 'root' }
)
export class BudgetSelectionUsageCountService {
  private readonly actionUrl = inject(API_V2_URL);
  private readonly http = inject(HttpClient);
  public apiPath = '/budget_specfic_usage_count/';

  getBudgetSelectionUsageCount(type: string, id: number, data: Object): Observable<BudgetGroup[]> {
    return withAPIV0ErrorHandling(
      this.http.get<BudgetGroup[]>(`${this.actionUrl}${type}/${id}${this.apiPath}`, getRequestOptions(data))
    );
  }

}