import { Component, Input } from '@angular/core';

export enum BudgetAllocationActionTooltipContext {
  Default = 'Default',
  BudgetPage = 'BudgetPage'
}

@Component({
  selector: 'budget-allocation-action-tooltip',
  templateUrl: './budget-allocation-action-tooltip.component.html',
  styleUrls: ['./budget-allocation-action-tooltip.component.scss']
})
export class BudgetAllocationActionTooltipComponent {
  @Input() withDnd = false;
  @Input() tooltipContext = 'default';

  public contentByContext: Record<string, { doubleClick: string; dnd: string; }> = {
    [BudgetAllocationActionTooltipContext.Default]: {
      doubleClick: `Double-click to set the allocation equal<br/>to your expense total`,
      dnd: 'Drag and drop to cover your expenses'
    },
    [BudgetAllocationActionTooltipContext.BudgetPage]: {
      doubleClick: `Double-click to<br/> "true-up"`,
      dnd: 'Drag and drop to cover your expenses'
    }
  };
}
