<div class="segment-breakdown">
  <div class="breakdown-item segment" *ngIf="!allocatedOnly">
    <ng-container
      [ngTemplateOutlet]="segment || defaultSegment"
    ></ng-container>
  </div>
  <div class="breakdown-item allocated">
    <ng-container
      [ngTemplateOutlet]="allocated || defaultAllocated"
    ></ng-container>
  </div>
  <div class="breakdown-item unallocated" *ngIf="!allocatedOnly">
    <ng-container
      [ngTemplateOutlet]="unallocated || defaultUnallocated"
    ></ng-container>
  </div>
</div>

<ng-template #defaultSegment>
  Segment
</ng-template>
<ng-template #defaultAllocated>
  <div class="subtitle" *ngIf="withSubtitles">Remaining Allocated</div>
  Campaigns & Exp. Groups
</ng-template>
<ng-template #defaultUnallocated>
  <div class="subtitle" *ngIf="withSubtitles">Available</div>
  Unallocated
</ng-template>
