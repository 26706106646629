// Material design components
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { A11yModule } from '@angular/cdk/a11y';
// End Material design components

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CampaignDetailsComponent } from './components/containers/campaign-details/campaign-details.component';
import { GoalDetailsComponent } from './components/containers/goal-details/goal-details.component';
import { ProgramDetailsComponent } from './components/containers/program-details/program-details.component';
import { ExpenseDetailsComponent } from './components/containers/expense-details/expense-details.component';
import { GoalMetricDetailsComponent } from './components/containers/goal-metric-details/goal-metric-details.component';
import { CampaignMetricDetailsComponent } from './components/containers/campaign-metric-details/campaign-metric-details.component';
import { DetailsWidgetTitleComponent } from './components/details-widget-title/details-widget-title.component';
import { DetailsMetricsComponent } from './components/details-metrics/details-metrics.component';
import { AllocationsTableComponent } from './components/allocations-table/allocations-table.component';
import { SharedModule } from 'app/shared/shared.module';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { MetricCPOPipe } from './pipes/metric-cpo.pipe';
import { DetailsExpensesComponent } from './components/details-expenses/details-expenses.component';
import { DetailsExpensesTableComponent } from './components/details-expenses/details-expenses-table/details-expenses-table.component';
import { DetailsDataPlaceholderComponent } from './components/details-data-placeholder/details-data-placeholder.component';
import { DetailsMetricsHintComponent } from './components/details-metrics/details-metrics-hint/details-metrics-hint.component';
import { DetailsStatusBarComponent } from './components/details-status-bar/details-status-bar.component';
import { DetailsBudgetAmountComponent } from './components/details-budget-amount/details-budget-amount.component';
import { ChildObjectsComponent } from './components/child-objects/child-objects.component';
import { DetailsHeaderComponent } from './components/details-header/details-header.component';
import { ObjectHierarchyNavComponent } from './components/object-hierarchy-nav/object-hierarchy-nav.component';
import { BudgetAllocationsTableComponent } from './components/budget-allocations-table/budget-allocations-table.component';
import { CampaignDetailsService } from './services/campaign-details.service';
import { GoalDetailsService } from './services/goal-details.service';
import { ProgramDetailsService } from './services/program-details.service';
import { ExpenseDetailsService} from './services/expense-details.service';
import { BudgetObjectDetailsManager } from './services/budget-object-details-manager.service';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { BudgetObjectActionsBuilder } from './services/budget-object-actions-builder.service';
import { CampaignStateMapper } from './services/state-mappers/campaign-state-mapper.service';
import { ExpenseStateMapper } from './services/state-mappers/expense-state-mapper.service';
import { ProgramStateMapper } from './services/state-mappers/program-state-mapper.service';
import { GoalStateMapper } from './services/state-mappers/goal-state-mapper.service';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { AttachmentsListComponent } from './components/attachments-list/attachments-list.component';
import { DisabledAddToObjectTooltipPipe } from './pipes/disabled-add-to-object-tooltip.pipe';
import { ObjectIdPipe } from 'app/budget-object-details/pipes/object-id.pipe';
import { MetricTotalInfoComponent } from './components/metrics/metric-total-info/metric-total-info.component';
import { MetricBreakdownComponent } from './components/metrics/metric-breakdown/metric-breakdown.component';
import { MetricBreakdownListComponent } from './components/metrics/metric-breakdown-list/metric-breakdown-list.component';
import { MetricMilestonesListComponent } from './components/metric-milestones-list/metric-milestones-list.component';
import { MetricMilestoneComponent } from './components/metric-milestones-list/metric-milestone/metric-milestone.component';
import { MetricTargetProgressChartComponent } from './components/metrics/metric-target-progress-chart/metric-target-progress-chart.component';
import { MetricCpoChartComponent } from './components/metrics/metric-cpo-chart/metric-cpo-chart.component';
import { MetricRoiChartComponent } from './components/metrics/metric-roi-chart/metric-roi-chart.component';
import { MetricIntegrationsModule } from 'app/metric-integrations/metric-integrations.module';
import { LocationService } from './services/location.service';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { TaskStatusComponent } from './components/task-status/task-status.component';
import { TaskDateComponent } from './components/task-date/task-date.component';
import { TaskNameComponent } from './components/task-name/task-name.component';
import { MetricRpoInputComponent } from './components/metrics/metric-rpo-input/metric-rpo-input.component';
import { HeaderNavigationModule } from '../header-navigation/header-navigation.module';
import { MetricValueEditorComponent } from './components/metrics/metric-value-editor/metric-value-editor.component';
import { MetricValueRecordComponent } from './components/metrics/metric-value-editor/metric-value-record/metric-value-record.component';
import { MetricEditorNotesComponent } from './components/metrics/metric-value-editor/metric-editor-notes/metric-editor-notes.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MetricProgressSectionComponent } from './components/metrics/metric-progress-section/metric-progress-section.component';
import { MetricProgressTableComponent } from './components/metrics/metric-progress-table/metric-progress-table.component';
import { IntegrationMappingMenuComponent } from './components/metrics/metric-value-editor/integration-mapping-menu/integration-mapping-menu.component';
import { Configuration } from 'app/app.constants';
import { HeartToggleComponent } from './components/details-metrics/heart-toggle/heart-toggle.component';
import { BusinessValueComponent } from './components/business-value/business-value.component';
import { BusinessValueService } from './components/business-value/business-value.service';
import { MalwareDetectedDialogComponent } from './components/malware-detected-dialog/malware-detected-dialog.component';
import { MetricsSourceComponent } from './components/details-metrics/metrics-source/metrics-source.component';
import { BudgetAllocationModule } from '../budget-allocation/budget-allocation.module';
import { BudgetObjectAllocationService } from './services/budget-object-allocation.service';
import { MetricTypeComponent } from './components/metrics/metric-type/metric-type.component';
import { DrawerHeaderComponent } from './components/details-header/drawer-header/drawer-header.component';
import { ManageObjectActionsComponent } from './components/details-header/manage-object-actions/manage-object-actions.component';
import { DetailsPageActionsComponent } from './components/details-header/details-page-actions/details-page-actions.component';
import { RelatedExpensesService } from './services/related-expenses.service';
import { ChooseTimeframeDialogComponent } from './components/choose-timeframe-dialog/choose-timeframe-dialog.component';
import { ObjectHierarchyMenuComponent } from './components/object-hierarchy-menu/object-hierarchy-menu.component';
import { HighlightFieldDirective } from 'app/budget-object-details/directives/highlight-field.directive';
import { AttachmentViewerComponent } from './components/attachment-viewer/attachment-viewer.component';
import { DetailsMetricControlComponent } from './components/containers/details-metric-control/details-metric-control.component';
import { DetailsMetricControlCardComponent } from './components/containers/details-metric-control/details-metric-control-card/details-metric-control-card.component';
import { CegBudgetAllocationsComponent } from './components/ceg-budget-allocations/ceg-budget-allocations.component';
import { CegBudgetAllocationsTableComponent } from './components/ceg-budget-allocations/ceg-budget-allocations-table/ceg-budget-allocations-table.component';
import { GoalDetailsFormComponent } from './components/containers/goal-details-form/goal-details-form.component';
import { CampaignDetailsFormComponent } from './components/containers/campaign-details-form/campaign-details-form.component';
import { CustomFieldComponent } from './components/custom-fields/custom-field.component'
import { CampaignDetailsDrawerComponent } from './components/containers/campaign-details-drawer/campaign-details-drawer.component';
import { DrawerStackComponent } from './components/containers/drawer-stack/drawer-stack.component';
import { DrawerTabSwitchComponent } from './components/drawer-tab-switch/drawer-tab-switch.component';
import { GoalDetailsDrawerComponent } from './components/containers/goal-details-drawer/goal-details-drawer.component';
import { MetricDetailsDrawerComponent } from './components/containers/metric-details-drawer/metric-details-drawer.component';
import { CreateObjectToolbarComponent } from './components/create-object-toolbar/create-object-toolbar.component';
import { ProgramDetailsFormComponent } from './components/containers/program-details-form/program-details-form.component';
import { ProgramDetailsDrawerComponent } from './components/containers/program-details-drawer/program-details-drawer.component';
import { DetailsTimestampsComponent } from './components/details-timestamps/details-timestamps.component';
import { AmountStatusSwitchComponent } from './components/amount-status-switch/amount-status-switch.component';
import { UnsavedDrawerChangesGuard } from './guards/unsaved-drawer-changes.guard';
import { MetricUpdateService } from 'app/budget-object-details/services/metric-update.service';
import { BudgetDataStateMutationService } from './services/budget-data-state-mutation.service';
import { DrawerReadOnlyInputComponent } from './components/drawer-read-only-input/drawer-read-only-input.component';
import { FormatBudgetPipe } from './pipes/format-budget.pipe';
import { MetricDetailsFormComponent } from './components/containers/metric-details-drawer/metric-details-form/metric-details-form.component';
import { MetricDetailsUpdateFormComponent } from './components/containers/metric-details-drawer/metric-details-update-form/metric-details-update-form.component';
import { MetricDetailsPerformanceComponent } from './components/containers/metric-details-drawer/metric-details-performance/metric-details-performance.component';
import { MetricDetailsMilestonesComponent } from './components/containers/metric-details-drawer/metric-details-milestones/metric-details-milestones.component';
import { MetricDetailsBreakdownComponent } from './components/containers/metric-details-drawer/metric-details-breakdown/metric-details-breakdown.component';
import { PennantIconComponent } from './components/containers/metric-details-drawer/pennant-icon/pennant-icon.component';
import { DisabledFlagMatSelectComponent } from './components/disabled-flag-mat-select/disabled-flag-mat-select.component';

@NgModule({
  declarations: [
    CampaignDetailsComponent,
    GoalDetailsComponent,
    ProgramDetailsComponent,
    ExpenseDetailsComponent,
    GoalMetricDetailsComponent,
    CampaignMetricDetailsComponent,
    DetailsWidgetTitleComponent,
    DetailsMetricsComponent,
    AllocationsTableComponent,
    MetricCPOPipe,
    DetailsExpensesComponent,
    DetailsExpensesTableComponent,
    DetailsDataPlaceholderComponent,
    DetailsMetricsHintComponent,
    ObjectHierarchyNavComponent,
    DetailsStatusBarComponent,
    DetailsBudgetAmountComponent,
    BudgetAllocationsTableComponent,
    CegBudgetAllocationsComponent,
    CegBudgetAllocationsTableComponent,
    ChildObjectsComponent,
    DetailsHeaderComponent,
    ManageObjectActionsComponent,
    DetailsPageActionsComponent,
    PageTitleComponent,
    AttachmentsListComponent,
    DisabledAddToObjectTooltipPipe,
    ObjectIdPipe,
    MetricTotalInfoComponent,
    MetricTypeComponent,
    MetricBreakdownComponent,
    MetricBreakdownListComponent,
    DisabledAddToObjectTooltipPipe,
    MetricMilestonesListComponent,
    MetricMilestoneComponent,
    MetricTargetProgressChartComponent,
    MetricCpoChartComponent,
    MetricRoiChartComponent,
    TasksListComponent,
    TaskStatusComponent,
    TaskDateComponent,
    TaskNameComponent,
    MetricRpoInputComponent,
    MetricValueEditorComponent,
    MetricValueRecordComponent,
    MetricEditorNotesComponent,
    MetricProgressSectionComponent,
    MetricProgressTableComponent,
    IntegrationMappingMenuComponent,
    HeartToggleComponent,
    BusinessValueComponent,
    MalwareDetectedDialogComponent,
    MetricsSourceComponent,
    DrawerHeaderComponent,
    ManageObjectActionsComponent,
    DetailsPageActionsComponent,
    ChooseTimeframeDialogComponent,
    ObjectHierarchyMenuComponent,
    HighlightFieldDirective,
    AttachmentViewerComponent,
    DetailsMetricControlComponent,
    DetailsMetricControlCardComponent,
    GoalDetailsFormComponent,
    CampaignDetailsFormComponent,
    CustomFieldComponent,
    CampaignDetailsDrawerComponent,
    DrawerStackComponent,
    DrawerTabSwitchComponent,
    GoalDetailsDrawerComponent,
    MetricDetailsDrawerComponent,
    CreateObjectToolbarComponent,
    ProgramDetailsDrawerComponent,
    ProgramDetailsFormComponent,
    DetailsTimestampsComponent,
    AmountStatusSwitchComponent,
    DrawerReadOnlyInputComponent,
    FormatBudgetPipe,
    MetricDetailsFormComponent,
    MetricDetailsUpdateFormComponent,
    MetricDetailsPerformanceComponent,
    MetricDetailsMilestonesComponent,
    MetricDetailsBreakdownComponent,
    PennantIconComponent,
    DisabledFlagMatSelectComponent,
  ],
  providers: [
    {
      provide: 'CanDeactivateGoalDetails',
      useFactory: (dialog: BudgetObjectDialogService, router: Router, configuration: Configuration) =>
        new UnsavedChangesGuard<GoalDetailsComponent>(dialog, router, configuration),
      deps: [BudgetObjectDialogService, Router, Configuration]
    },
    {
      provide: 'CanDeactivateCampaignDetails',
      useFactory: (dialog: BudgetObjectDialogService, router: Router, configuration: Configuration) =>
        new UnsavedChangesGuard<CampaignDetailsComponent>(dialog, router, configuration),
      deps: [BudgetObjectDialogService, Router, Configuration]
    },
    {
      provide: 'CanDeactivateProgramDetails',
      useFactory: (dialog: BudgetObjectDialogService, router: Router, configuration: Configuration) =>
        new UnsavedChangesGuard<ProgramDetailsComponent>(dialog, router, configuration),
      deps: [BudgetObjectDialogService, Router, Configuration]
    },
    {
      provide: 'CanDeactivateExpenseDetails',
      useFactory: (dialog: BudgetObjectDialogService, router: Router, configuration: Configuration) =>
        new UnsavedChangesGuard<ExpenseDetailsComponent>(dialog, router, configuration),
      deps: [BudgetObjectDialogService, Router, Configuration]
    },
    {
      provide: 'CanDeactivateGoalMetricDetails',
      useFactory: (dialog: BudgetObjectDialogService, router: Router, configuration: Configuration) =>
        new UnsavedChangesGuard<GoalMetricDetailsComponent>(dialog, router, configuration),
      deps: [BudgetObjectDialogService, Router, Configuration]
    },
    {
      provide: 'CanDeactivateCampaignMetricDetails',
      useFactory: (dialog: BudgetObjectDialogService, router: Router, configuration: Configuration) =>
        new UnsavedChangesGuard<CampaignMetricDetailsComponent>(dialog, router, configuration),
      deps: [BudgetObjectDialogService, Router, Configuration]
    },
    {
      provide: 'CanDeactivateActiveDrawer',
      useFactory: (dialog: BudgetObjectDialogService, router: Router, configuration: Configuration) =>
        new UnsavedDrawerChangesGuard(dialog, router, configuration),
      deps: [BudgetObjectDialogService, Router, Configuration]
    },
    MetricCPOPipe,
    GoalDetailsService,
    CampaignDetailsService,
    ProgramDetailsService,
    ExpenseDetailsService,
    BudgetObjectDetailsManager,
    BudgetObjectActionsBuilder,
    CampaignStateMapper,
    ExpenseStateMapper,
    ProgramStateMapper,
    GoalStateMapper,
    BudgetObjectDialogService,
    LocationService,
    BusinessValueService,
    BudgetObjectAllocationService,
    RelatedExpensesService,
    MetricUpdateService,
    BudgetDataStateMutationService
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    AutoSizeInputModule,
    FontAwesomeModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MetricIntegrationsModule,
    A11yModule,
    HeaderNavigationModule,
    MatExpansionModule,
    OverlayModule,
    BudgetAllocationModule
  ],
  exports: [
    CampaignDetailsComponent,
    GoalDetailsComponent,
    ProgramDetailsComponent,
    ExpenseDetailsComponent,
    GoalMetricDetailsComponent,
    CampaignMetricDetailsComponent,
    AllocationsTableComponent,
    TasksListComponent,
    DetailsWidgetTitleComponent,
    DisabledFlagMatSelectComponent
  ]
})
export class BudgetObjectDetailsModule {}
