<fa-duotone-icon
  matPrefix
  [icon]="['fad', 'chart-pie-alt']"
  [matMenuTriggerFor]="menu"
  [matTooltip]="activeSegmentTooltip"
  matTooltipClass="dark-theme-tooltip right"
  matTooltipPosition="right">
</fa-duotone-icon>
<mat-menu #menu="matMenu" class="hierarchy-menu">
  <div class="top-container">
    <search-control
      *ngIf="menuConfig.withSearch"
      [placeholder]="menuConfig.searchPlaceholder"
      (textChanged)="updateActiveItems($event)"
    ></search-control>
    <div mat-menu-item
         class="select-all option-content"
         *ngIf="menuConfig.allGroups">
      <arrow-toggler
        class="mr-05"
        [active]="!allSelected"
        (click)="$event.stopPropagation()"
        (change)="toggleAllGroupsCollapse($event)"></arrow-toggler>
      All {{menuConfig.allPlural}}
    </div>
  </div>
  <ng-container *ngFor="let item of menuItems; trackBy: identifyItem">
      <div *ngIf="item.children?.length"
        class="group">
        <div mat-menu-item
           class="group-holder"
           [disableRipple]="true"
           [ngClass]="{ active: activeSegment === item.objectId }"
        >
          <ng-container
            [ngTemplateOutlet]="groupOptionTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }"
          ></ng-container>
        </div>
        <div class="group-content group-level-1" *ngIf="groupsOpenState[item.id]">
          <ng-container *ngFor="let childLevelOne of item.children; trackBy: identifyItem">
            <div *ngIf="!childLevelOne.children?.length" class="group">
              <div mat-menu-item
                class="group-item"
                [ngClass]="{ active: activeSegment === childLevelOne.objectId }"
                (click)="handleSelectSegment(childLevelOne)">
                <ng-container
                  [ngTemplateOutlet]="singleOptionTemplate"
                  [ngTemplateOutletContext]="{ $implicit: childLevelOne }"
                ></ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="!item.children?.length" (click)="handleSelectSegment(item)">
        <div mat-menu-item
           class="group-item"
           [disableRipple]="true"
           [ngClass]="{ active: activeSegment === item.objectId }">
          <span>{{ item.title }}</span>
        </div>
      </div>
  </ng-container>
</mat-menu>

<ng-template #groupOptionTemplate let-option>
  <div class="option-content">
    <arrow-toggler
      class="mr-05"
      [active]="!groupsOpenState[option.id]"
      (change)="toggleGroup($event, option.id)"
      (click)="$event.stopPropagation()"
    ></arrow-toggler>
    <fa-duotone-icon
      *ngIf="iconsConfig[option.objectType]"
      class="option-icon mr-05"
      [fixedWidth]="true"
      [icon]="iconsConfig[option.objectType]"
    >
    </fa-duotone-icon>
    <div class="wrapping-text group-header">
      {{ option.title }}
    </div>
  </div>
</ng-template>

<ng-template #singleOptionTemplate let-option>
  <div class="option-content">
    <fa-duotone-icon
      *ngIf="iconsConfig[option.objectType]"
      class="option-icon"
      [fixedWidth]="true"
      [icon]="iconsConfig[option.objectType]"
    >
    </fa-duotone-icon>
    <div class="wrapping-text">
      {{ option.title }}
    </div>
  </div>
</ng-template>
