import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration } from 'app/app.constants';

@Component({
  selector: 'app-root',
  templateUrl: './register-confirmation.component.html',
  styleUrls: ['./register-confirmation.component.scss']
})
export class RegisterConfirmationComponent {
  private readonly configuration = inject(Configuration);
  public readonly router = inject(Router);

  public title: string;

  loginRedirect() {
    this.router.navigate([this.configuration.ROUTING_CONSTANTS.LOGIN]);
  }
}
