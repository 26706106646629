<label>Status</label>
<nav mat-tab-nav-bar
     class="status-tab-switch"
     [ngClass]="{ 'is-disabled': parentCampaignIsCommitted }"
     [disableRipple]="true"
     [matTooltip]="'Blocked by parent’s Committed status'"
     [matTooltipDisabled]="!parentCampaignIsCommitted"
     matTooltipClass="dark-theme-tooltip multi-line simple"
     matTooltipPosition="above"
     [id]="id">
  <ng-container *ngFor="let option of campaignAmountStatus">
    <a
      *ngIf="!option.hidden"
      mat-tab-link
      (click)="onChangeAmountState.emit(option.value)"
      [active]="statusFormControl?.value === option.value"
      [disabled]="isReadOnlyMode || parentCampaignIsCommitted"
    >
      {{ option.title }}
    </a>
  </ng-container>
</nav>
