import { Campaign } from '@shared/types/campaign.interface';
import { Program } from '@shared/types/program.interface';
import { ManageTableRow, ManageTableRowValues } from '../../components/manage-table/manage-table.types';
import { ObjectDataApplier } from './object-data-applier.interface';

export abstract class PlanObjectDataApplier implements ObjectDataApplier<Campaign | Program> {
  private readonly _rowValues: ManageTableRowValues;
  abstract get sourceObject(): Campaign | Program;

  protected constructor(rowValues: ManageTableRowValues) {
    this._rowValues = rowValues;
  }

  applyObjectData(row: ManageTableRow): void {
    row.externalId = this.sourceObject.externalId;
    row.allocations = this.sourceObject.timeframes;
    row.createdDate = this.sourceObject.createdDate;
    const { values, total, spending } = this._rowValues;
    row.values = values;
    row.total = total;
    row.spending = spending;
  }
}
