import { Component, HostListener, Input } from '@angular/core';
import { TooltipContext } from '@shared/directives/dynamic-portal-tooltip.directive';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'dynamic-portal-tooltip',
  templateUrl: './dynamic-portal-tooltip.component.html',
  styleUrls: [ './dynamic-portal-tooltip.component.scss']
})
export class DynamicPortalTooltipComponent {
  @Input() tooltipContext: TooltipContext;
  @Input() positionCssClass: string;
  @Input() tooltipIsHovered$: BehaviorSubject<boolean>;

  @HostListener('mouseenter')
  private mouseEnter() {
    this.tooltipIsHovered$.next(true);
  }

  @HostListener('mouseleave')
  private mouseLeave() {
    this.tooltipIsHovered$.next(false);
  }
}
