<article class="root-layout">
	<navigation></navigation>
	<div class="right-side">
		<div class="box-topBar">
			<h4 class="box-pageHeadingBar">Planful | Account Management</h4>
      <a class="profile-component ml-a" [routerLink]="['', { outlets: { primary: 'user', details: null } }]" queryParamsHandling="preserve">
        <profile-menu theme='blue' [disabled]="true" ></profile-menu>
      </a>
    </div>
		<div class="right-side-content fd-column">
      
      <ng-container *ngIf="!enableCompanySettings">
        <div class="d-flex jc-between full-width">
          <div class="d-flex search-main-block">
            <div class="box-searchManageTeam">
              <input type="text" placeholder="Search" [(ngModel)]="search" (keyup.enter)="searchData()">
              <span class="clear-search" title="Clear Search" *ngIf="itemSearched">
                <fa-icon [icon]="['fas', 'times']" (click)="clearSearch()"></fa-icon>
              </span>
              <button class="btn-searchTeam" type="button" (click)="searchData()"></button>
            </div>
            <ul class="list-allCategory">
              <li class="list-selectCategory">
                <angular2-multiselect
                  id="planTypeDropdown"
                  [data]="filter_data"
                  [settings]="planTypeDropdownSettings"
                  [(ngModel)]="selectedFilter"
                  (onSelect)="onSelectFilter($event, 'click')"
                  (onDeSelect)="onDeSelectFilter($event)">
                </angular2-multiselect>
              </li>
            </ul>
          </div>
          <ul class="search_block">
            <li>
              <button class="btn-addCampaignView" type="button" title="" (click)="utilityService.setNoRegistrationFlag(); userManager.logout(configuration.ROUTING_CONSTANTS.REGISTER);">Register Free Account</button>
            </li>
            <li>
              <actions-dropdown
                [title]="'Account Actions'"
                [optionsList]="actionsOptionsForDisplay"
                [disableDropdown]="disableEditAll"
                (toggleActionsDropDown)="toggleActionsDropDown()"
              ></actions-dropdown>
            </li>
          </ul>
        </div>
        <div class="box-tableManageExpense">
          <div class="box-tableManageExpenseInn setOverflowX" #table>
              <table class="table-superAdminCompany table-evenOdd">
                <thead>
                  <tr>
                    <th class="colA">
                      <mat-checkbox class="select-all-btn"
                        color="primary"
                        [checked]="isAllCheckedCompany"
                        (change)="checkAll($event, companyData.length)">
                      </mat-checkbox>
                    </th>
                    <th class="colB">Account <span class="icon-sortingTable" (click)="companySort('name')"></span></th>
                    <th class="colF">Owner <span class="icon-sortingTable" (click)="companySort('owner')"></span></th>
                    <th class="colD">Active Budgets<span class="icon-sortingTable" (click)="companySort('budget_count')"></span></th>
                    <th class="colC">Created <span class="icon-sortingTable" (click)="companySort('crd')"></span></th>
                    <th class="colC">Updated <span class="icon-sortingTable" (click)="companySort('upd')"></span></th>
                    <th class="colC">Acct Status</th>
                    <th class="adopted colC">Adopted <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.Adopted)"></span></th>
                    <th class="colC">Comp</th>
                    <th class="colD">Non-Reporting</th>
                    <th class="colC">Salesforce <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.Salesforce)"></span></th>
                    <th class="colC">Google Ads <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.GoogleAds)"></span></th>
                    <th class="colD">LinkedIn Ads <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.LinkedinAds)"></span></th>
                    <th class="colD">Facebook Ads <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.FacebookAds)"></span></th>
                    <th class="colC">SSO <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.SSO)"></span></th>
                    <th class="colC">Budgie <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.Budgie)"></span></th>
                    <th class="colC">HubSpot <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.Hubspot)"></span></th>
                    <th class="colE">Default Timeframe Suppression<span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyProperties.DefaultForSuppressTimeframeAllocations)"></span></th>
                    <th class="colD">SaaSOptics ID<span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyProperties.SaasOpticsId)"></span></th>
                    <th class="colB">Salesforce Tenant ID<span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyProperties.SalesforceTenantId, compareTypes.Alphabetical)"></span></th>
                  </tr>
                </thead>
              </table>
            
              <cdk-virtual-scroll-viewport class="scroll-container" itemSize="50" (scroll)="onScroll($event)">
                <table class="table-superAdminCompany table-evenOdd">
                  <tbody>
                    <tr *cdkVirtualFor="let company of companyData; let i = index; trackBy: trackCompanyFn">
                      <td  class="colA">
                        <mat-checkbox
                          name="sizecb[]"
                          color="primary"
                          [checked]="checks[i]"
                          (change)="checkCompany($event.checked, i, companyData.length)">
                        </mat-checkbox>
                      </td>
                      <td class="colB"><span class="table-contOverflow" title="{{company.name}}">{{company.name}}</span></td>
                      <td class="colF"><span class="table-contOverflow" title="{{company.owner}}">{{company.owner}}</span></td>
                      <td class="colD">{{company.budget_count}}</td>
                      <td class="colC">{{company.crd}}</td>
                      <td class="colC">{{company.upd}}</td>
                      <td class="colC">{{ company.status == 'Active' ? 'Active' : '' }}</td>
                      <td class="budgie-cell colC">{{ company.adopted ? 'Yes' : 'No' }}</td>
                      <td class="colC">{{ company.unlimited ? 'True' : '' }}</td>
                      <td class="colD">{{ company.non_reporting ? 'Non-Reporting' : '' }}</td>
                      <td class="salesforce-cell colC">{{ company.salesforce ? 'Yes' : 'No' }}</td>
                      <td class="colC">{{ company.google_ads ? 'Yes' : 'No' }}</td>
                      <td class="colD">{{ company.linkedin_ads ? 'Yes' : 'No' }}</td>
                      <td class="colD">{{ company.facebook_ads ? 'Yes' : 'No' }}</td>
                      <td class="sso-cell colC">{{ company.sso_domain }}</td>
                      <td class="budgie-cell colC">{{ company.budgie ? 'Yes' : 'No' }}</td>
                      <td class="hubspot-cell colC">{{ company.hubspot ? 'Yes' : 'No' }}</td>
                      <td class="colE">{{ company.default_for_suppress_timeframe_allocations ? 'Yes' : 'No' }}</td>
                      <td class="colD">{{ company.saas_optics_customer_id }}</td>
                      <td class="colB">{{ company.salesforce_tenant_id }}</td>
                    </tr>
                  </tbody>
                </table>
              </cdk-virtual-scroll-viewport>
            
              <table *ngIf="companyData.length == 0" class="table-superAdminCompany table-evenOdd">
                <tfoot>
                  <tr>
                    <td colspan="19" class="td-expenseNotFound">
                      <span>{{validations.ValiditionMessages.NO_RESULT_FOUND}}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            
            
            
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="enableCompanySettings">

        <div class="company-settings-area">
          <header>
            <span>Company Settings - {{selectedCompanyName}}</span>

            <button (click)="goBack()">
              <fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon>
            </button>
            <button (click)="enableCompanySettingsFilter()">
              <fa-icon *ngIf="!showFilter" [icon]="['fas', 'filter']"></fa-icon>
              <fa-icon *ngIf="showFilter" [icon]="['fas', 'filter-circle-xmark']"></fa-icon>
            </button>

          </header>
          <div>
            <table class="company-settings-table">
              <thead>
                <tr>
                  <th>Key</th>
                  <th class="value-cell">Value</th>
                  <th>App Component</th>
                  <th>Description</th>
                  <th>Allowed Values</th>
                </tr>
                <tr *ngIf="showFilter">
                  <th><input type="text" class="filter-input" (input)="applyFilter('param_key', $event.target.value)" /></th>
                  <th><input type="text" class="filter-input" (input)="applyFilter('param_value', $event.target.value)" /></th>
                  <th><input type="text" class="filter-input" (input)="applyFilter('component', $event.target.value)" /></th>
                  <th><input type="text" class="filter-input" (input)="applyFilter('description', $event.target.value)" /></th>
                  <th><input type="text" class="filter-input" (input)="applyFilter('allowed_values', $event.target.value)" /></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let setting of filteredCompanySettingsData; let i = index">
                  <td>{{ setting.param_key }}</td>
                  <td class="value-cell">
                    <div class="value-display" *ngIf="editingIndex !== i">
                      <span>{{ setting.param_value }}</span>              
                      <button *ngIf="setting.editable" (click)="editSetting(setting,i)">
                        <fa-icon [icon]="['fas', 'pen-to-square']"></fa-icon>
                      </button>
                    </div>
                    <div class="value-edit" *ngIf="editingIndex === i">
                      <input type="text" [(ngModel)]="setting.param_value" maxlength="2">
                      <button (click)="saveSetting(setting, i)">
                        <fa-icon [icon]="['fas', 'floppy-disk']"></fa-icon>
                      </button>
                    </div>
                  </td>
                  <td>{{setting.component}}</td>
                  <td>{{ setting.description }}</td>
                  <td>{{ setting.allowed_values }}</td>
                </tr>
                <tr *ngIf="filteredCompanySettingsData.length===0">
                  <td colspan="5" class="td-expenseNotFound">
                    <span>{{validations.ValiditionMessages.NO_RESULT_FOUND}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ng-container>
		</div>
	</div>
</article>
