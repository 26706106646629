import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Configuration } from 'app/app.constants';
import { SortParams } from 'app/shared/types/sort-params.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { SortableFields } from '../../constants/sorting.constants';
import { SpendingModeFlag, SpendingModeFlags } from '../../types/spending-mode-flags.type';
import { ManageTableHelpers } from '../../services/manage-table-helpers';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';


@Component({
  selector: '[manage-table-head-row]',
  templateUrl: './manage-table-head-row.component.html',
  styleUrls: ['./manage-table-head-row.component.scss']
})
export class ManageTableHeadRowComponent {
  public readonly SortableFields = SortableFields;
  public readonly SpendingModeFlag = SpendingModeFlag;
  public readonly statusFields = this.configuration.statusFields;

  @Input() spendingMode = false;
  @Input() allocationMode = false;
  @Input() spendingModeFlags: SpendingModeFlags;
  @Input() appliedSorting: SortParams;
  @Input() budget: Budget;
  @Input() timeframes: BudgetTimeframe[];
  @Input() currentTimeframe: BudgetTimeframe;
  @Input() highlightedTimeframes: number[] = [];
  @Input() selectAllValue: CheckboxValue = CheckboxValue.NotActive;
  @Output() onSortingChanged = new EventEmitter<string>();
  @Output() onSelectAllChanged = new EventEmitter<MatCheckboxChange>();

  public CheckboxValue = CheckboxValue;
  public readonly identifyTimeframe = ManageTableHelpers.identifyTimeframe;

  constructor(
    private readonly configuration: Configuration,
  ) {}

  public applySorting(columnName: string) {
    this.onSortingChanged.emit(columnName);
  }

  public handleSelectAllChange($event: MatCheckboxChange) {
    this.onSelectAllChanged.emit($event);
  }
}
