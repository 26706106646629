<div class="drag-container">
  <h3>
    {{label}} ({{getNonEmptyItemCount()}}/{{maxLength}}) <span class="required-item">*</span>
    <span *ngIf="isRequired" [ngClass]="{'default-title': true, 'error': this.checkedIndex === null}">Default</span>
  </h3>
  <div class="drag-container-section" cdkDropList (cdkDropListDropped)="onDrop($event)">
    <mat-radio-group>
      <div *ngFor="let item of items; let i = index; trackBy: trackByIndex" cdkDrag>
        <div class="drag-item">
          <span class="drag-icon">
            <fa-icon [icon]="['fas', 'grip-vertical']"></fa-icon>
          </span>
          <div class="drag-label">
            <input [maxlength]="maxChar" #itemInput class="label-area" type="text" [(ngModel)]="item.value"
              [placeholder]="placeHolderText" (input)="onInputChange(item.value, i)">
          </div>
          <span class="drag-remove" (click)="removeItem(i)">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
          </span>
          <span *ngIf="isRequired" class="default-dragged">
            <mat-radio-button color="primary" [value]="i" [checked]="checkedIndex === i" (change)="onRadioChange(i)">
            </mat-radio-button>
          </span>
        </div>
      </div>
    </mat-radio-group>
  </div>

  <footer>
    <div class="drag-add"
      [class.disabled]="checkForEmptyItem(items) || isDuplicate || checkForMaxLength() || hasSpecialCharErrors()"
      (click)="addItem()">
      <fa-icon [icon]="['fas', 'plus']"></fa-icon>
      {{AddButtonText}}
    </div>
    <div class="error-area">
      <span *ngIf="isDuplicate" class="error-message">{{duplicateErrorMessage}}</span>
      <span *ngIf="showEmptyError" class="error-message">{{emptyErrorMessage}}</span>
      <span *ngIf="hasSpecialCharErrors()" class="error-message">{{specialCharErrorMessage}}</span>
    </div>
  </footer>
</div>