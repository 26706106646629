<widget-card [isLoading]="state === widgetState.LOADING"
  [overlayText]="overlayText"
  [overlayAction]="overlayAction.bind(this)"
>
  <div class="spend-progress" [ngClass]="currentProgressState" [class.is-not-ready]="state !== widgetState.READY">
    <simple-widget-header>{{ titleByProgressState[currentProgressState] }}</simple-widget-header>
    <div class="content">
      <div class="progress" *ngIf="currentProgressState !== PROGRESS_STATE.FRESH">
        <span class="progress-sign">{{ sign }}</span>
        <span class="progress-value">{{ value }}</span>
        <span class="progress-percent">%</span>
      </div>
      <div class="fresh" *ngIf="currentProgressState === PROGRESS_STATE.FRESH">
        <fa-duotone-icon [icon]="['fad', 'glass-cheers']"></fa-duotone-icon>
        <spend-chart-placeholder>
          Happy New Fiscal Year!
          We’ll give you a status when you’re 6 weeks in to the year.
        </spend-chart-placeholder>
      </div>
    </div>
  </div>
</widget-card>
