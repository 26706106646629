import { inject, Injectable } from '@angular/core';
import { UserManager } from '../../user/services/user-manager.service';
import { CompanyDO } from '@shared/types/company.interface';
import { CompanyDataService } from '@shared/services/company-data.service';
import { BudgetService } from '@shared/services/backend/budget.service';
import { UserDO } from '@shared/types/user-do.interface';
import { combineLatest, of, Subject, switchMap, takeUntil } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export enum PendoEventName {
  BudgetSaved = 'Budget Saved',
  ObjectCreated = 'Object Created',
  ObjectUpdated = 'Object Updated',
  Reports = 'Reports',
  Import = 'Import',
  MetricFunnelCreated = 'Metric Funnel Created',
  MetricFunnelUpdated = 'Metric Funnel Updated'
}

export enum PendoObjectType {
  Campaign = 'Campaign',
  Expense = 'Expense',
  Goal = 'Goal',
  ExpenseGroup = 'Expense Group',
  Metric = 'Metric'
}

@Injectable({
  providedIn: 'root'
})
export class PendoManagerService {
  private readonly userManager = inject(UserManager);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly budgetService = inject(BudgetService);

  private readonly stopTracking$ = new Subject<void>();

  private isInitialized: boolean;

  private get pendo() {
    return (<any>window).pendo;
  }

  initSubscriptions(): void {
    if (!this.pendo) { return; }

    combineLatest([
      this.userManager.currentUser$.pipe(filter(userDO => !!userDO)),
      this.companyDataService.selectedCompanyDO$.pipe(filter(companyDO => !!companyDO))
    ]).pipe(
      takeUntil(this.stopTracking$),
      switchMap(([userDO, companyDO]) =>
        (userDO.companies?.length
            ? this.budgetService.getAvailableBudgetsForCompanies(userDO.companies)
            : of([])
        ).pipe(
          map(budgets => ({ userDO, companyDO, budgetIds: budgets.map(budget => budget.id) }))
        )
      )
    ).subscribe(({ userDO, companyDO, budgetIds }) => {
      if (!this.isInitialized) {
        this.initialize(userDO, companyDO, budgetIds);
      } else {
        this.identifyUser(userDO, companyDO, budgetIds);
      }
    });
  }

  track(eventName: string, properties: Record<string, string | boolean>): void {
    if (!this.pendo) { return; }

    this.pendo.track(eventName, properties);
  }

  stopTracking(): void {
    this.stopTracking$.next();
    this.stopTracking$.complete();
  }

  private initialize(userDO: UserDO, companyDO: CompanyDO, budgetIds: number[]): void {
    if (userDO.user_profile_detail?.pendo_visitor_id && companyDO.salesforce_tenant_id) {
      this.isInitialized = true;
      const pendoUserData = this.getUserData(userDO, companyDO, budgetIds);

      this.pendo.initialize(pendoUserData);
    }
  }

  private identifyUser(userDO: UserDO, companyDO: CompanyDO, budgetIds: number[]): void {
    if (userDO.user_profile_detail?.pendo_visitor_id && companyDO.salesforce_tenant_id) {
      const pendoUserData = this.getUserData(userDO, companyDO, budgetIds);

      this.pendo.identify(pendoUserData);
    }
  }

  private getUserData(userDO: UserDO, companyDO: CompanyDO, budgetIds: number[]): Record<string, Object> {
    return {
      visitor: {
        id: userDO.user_profile_detail.pendo_visitor_id,
        full_name: this.getName(userDO),
        budgets: budgetIds
      },
      account: {
        id: companyDO.salesforce_tenant_id,
        language: 'en_US',
        p4m_is_active: companyDO.status === 'Active',
        p4m_integrations: this.getEnabledIntegrations(companyDO)
      }
    };
  }

  private getName(userDO: UserDO): string {
    const userProfile = userDO.user_profile_detail;
    const firstName = userProfile?.first_name ? userProfile.first_name.slice(0, 2) + '***' : '';
    const lastName = userProfile?.last_name ? userProfile.last_name.slice(0, 2) + '***' : '';
    return [firstName, lastName].join(' ').trim();
  }

  private getEnabledIntegrations(companyDO: CompanyDO): string {
    const keyToTitle = {
      salesforce: 'Salesforce',
      google_ads: 'Google Ads',
      linkedin_ads: 'LinkedIn Ads',
      facebook_ads: 'Facebook Ads',
      sso: 'SSO',
      hubspot: 'Hubspot',
      budgie: 'Budgie'
    };

    const enabledIntegrationNames = [];
    for (const key of Object.keys(keyToTitle)) {
      if (companyDO[key]) {
        enabledIntegrationNames.push(keyToTitle[key]);
      }
    }

    return enabledIntegrationNames.join(',');
  }
}
