import { Injectable } from '@angular/core';
import { ProgramDO } from 'app/shared/types/program.interface';
import { ExpenseDO } from 'app/shared/types/expense.interface';
import { BudgetObjectTotals } from 'app/shared/types/budget-object-totals.interface';
import {
  DetailsExpensesData,
  DetailsExpensesDataGroup,
  DetailsExpensesTotalRow,
  DetailsExpensesObjectRow,
  DetailsExpensesRow,
  DetailsExpensesValue,
} from './details-expenses-table/details-expenses-table.type';
import { ExpenseAllocationMode } from 'app/shared/types/expense-allocation-mode.type';
import { CampaignDO } from 'app/shared/types/campaign.interface';

@Injectable({
  providedIn: 'root'
})
export class DetailsExpensesService {
  public static getPlannedAllocationAmount(amount: number, _actualAmount: number, mode: string): number {
    return mode === ExpenseAllocationMode.Planned ? amount || 0 : 0;
  }

  public static getCommittedAllocationAmount(_amount: number, actualAmount: number, mode: string): number {
    return mode === ExpenseAllocationMode.Committed ? actualAmount || 0 : 0;
  }

  public static getClosedAllocationAmount(_amount: number, actualAmount: number, mode: string): number {
    return mode === ExpenseAllocationMode.Closed ? actualAmount || 0 : 0;
  }

  private static getValueObject(value = 0): DetailsExpensesValue {
    return { value };
  }

  private static getEmptyRow(): DetailsExpensesRow {
    return {
      planned: DetailsExpensesService.getValueObject(),
      committed: DetailsExpensesService.getValueObject(),
      closed: DetailsExpensesService.getValueObject(),
      total: DetailsExpensesService.getValueObject(),
    };
  }

  private static getRowFromTotals(totals: BudgetObjectTotals): DetailsExpensesRow {
    const { committed = 0, planned = 0, closed = 0 } = totals;

    return {
      closed: DetailsExpensesService.getValueObject(totals.closed),
      committed: DetailsExpensesService.getValueObject(totals.committed),
      planned: DetailsExpensesService.getValueObject(totals.planned),
      total: DetailsExpensesService.getValueObject(closed + committed + planned)
    };
  }

  private static getExpenseObjectRow(expense: ExpenseDO): DetailsExpensesRow {
    const { amount, actual_amount: actualAmount, mode } = expense;
    const planned =
      DetailsExpensesService.getValueObject(DetailsExpensesService.getPlannedAllocationAmount(amount, actualAmount, mode));
    const committed =
      DetailsExpensesService.getValueObject(DetailsExpensesService.getCommittedAllocationAmount(amount, actualAmount, mode));
    const closed =
      DetailsExpensesService.getValueObject(DetailsExpensesService.getClosedAllocationAmount(amount, actualAmount, mode));
    const total = DetailsExpensesService.getValueObject(planned.value + committed.value + closed.value);
    return { planned, committed, closed, total };
  }

  private prepareExpensesGroup(data: ExpenseDO[]): DetailsExpensesDataGroup {
    const expenses = data || [];
    const groupTotal: DetailsExpensesTotalRow = {
      ...DetailsExpensesService.getEmptyRow(),
      count: expenses.length
    };
    const groupRows: DetailsExpensesObjectRow[] = expenses.map(expense => {
      const { id, name } = expense;
      const objectRow: DetailsExpensesObjectRow = {
        id,
        name,
        ...DetailsExpensesService.getExpenseObjectRow(expense)
      };

      groupTotal.total.value += objectRow.total.value;
      groupTotal.planned.value += objectRow.planned.value;
      groupTotal.committed.value += objectRow.committed.value;
      groupTotal.closed.value += objectRow.closed.value;

      return objectRow;
    });

    return {
      groupTotal,
      rows: groupRows
    };
  }

  private prepareParentObjectsGroup(data: (CampaignDO | ProgramDO)[]): DetailsExpensesDataGroup {
    const objects = data || [];
    const groupTotal: DetailsExpensesTotalRow = {
      ...DetailsExpensesService.getEmptyRow(),
      count: objects.length,
    };
    const groupRows: DetailsExpensesObjectRow[] = objects.map((object: ProgramDO | CampaignDO) => {
      const { status_totals: totals, id, name, campaignSource, mode } = object;
      const objectRow: DetailsExpensesObjectRow = {
        id,
        name,
        campaignSource,
        mode,
        ...DetailsExpensesService.getRowFromTotals(totals)
      };

      groupTotal.total.value += objectRow.total.value;
      groupTotal.planned.value += objectRow.planned.value;
      groupTotal.committed.value += objectRow.committed.value;
      groupTotal.closed.value += objectRow.closed.value;

      return objectRow;
    });

    return {
      groupTotal,
      rows: groupRows
    };
  }

  public prepareTotalsRow(totals: BudgetObjectTotals): DetailsExpensesTotalRow {
    const { expenses_number = 0 } = totals;

    return {
      ...DetailsExpensesService.getRowFromTotals(totals),
      count: expenses_number
    };
  }

  public prepareData(params): DetailsExpensesData {
    const { programs, campaigns, expenses } = params;
    const expensesGroup = this.prepareExpensesGroup(expenses);
    const programsGroup = this.prepareParentObjectsGroup(programs);
    const campaignsGroup = this.prepareParentObjectsGroup(campaigns);

    return {
      campaignsGroup,
      programsGroup,
      expensesGroup
    };
  }
}
