<article
  class="details-page-wrapper"
  [matTooltip]="currentState?.name"
  [matTooltipDisabled]="isActive"
  matTooltipClass="dark-theme-tooltip simple drawer-name-tooltip"
  matTooltipPosition="left"
>
  <loader [isVisible]="isLoading"></loader>
  <drawer-header
    [detailsFormGroupData]="formData"
    [actions]="menuActions"
    [budgetList]="budgets"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedChangesFlag"
    [objectHierarchy]="hierarchy"
    [childHierarchy]="childHierarchy"
    [nameFormControl]="fdNameControl"
    [maxObjectNameLength]="budgetObjectDetailsManager.maxObjectNameLength"
    [objectType]="objectType"
    [objectLabel]="objectLabel"
    [createdDate]="currentState?.created"
    [updatedDate]="currentState?.updated"
    [headerBackgroundClass]="headerBackgroundClass[objectType]"
    [isCEGBudget]="cegStatusEnabled"
    (budgetToMoveSelected)="handleBudgetToMoveSelected($event)"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAction.bind(this))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
    (onChildHierarchyItemClick)="childHierarchyClickHandler($event)"
  ></drawer-header>

  <drawer-tab-switch
    [activeTabId]="activeTabId"
    [tabs]="tabsData"
    [keyMetricSelection]="false"
    (activeTabChanged)="setActiveTab($event)"
  ></drawer-tab-switch>

  <div class="details-content tab-content-wrap">
    <goal-details-form
      #detailsForm
      [ngClass]="{ 'visible': activeTabId === ObjectDetailsTabControl.Details }"
      [isReadOnlyMode]="isReadOnlyMode"
      [objectType]="objectType"
      [goalFormData]="formData"
      [goalTypes]="goalTypes"
      [currentState]="currentState"
      [tagsAutocompleteItems]="tagsManager.autocompleteItems"
      [attachmentsManager]="attachmentsManager"
      [isCustomTypeEntering]="isCustomTypeEntering"
      [unsavedCustomTypeId]="budgetObjectDetailsManager.unsavedCustomTypeId"
      (handleTypeChange)="handleTypeChange()"
      (handleCustomTypeChange)="handleCustomTypeChange()"
      (handleFileAttached)="handleFileAttached($event)"
      (handleFileDelete)="handleFileDelete($event)"
      (handleFileDownload)="handleFileDownload($event)"
      (syncUnsavedChangesFlag)="syncUnsavedChangesFlag()"
    ></goal-details-form>

    <div class="tab-content-container" [ngClass]="{ 'visible': activeTabId === ObjectDetailsTabControl.Allocation }">
      <create-object-toolbar
        *ngIf="!isReadOnlyMode"
        [objectId]="objectId"
        [parentObjectLabel]="objectLabel"
        [objectTypes]="[ DrawerType.Campaign, DrawerType.Program ]"
        (createObject)="onCreateObject($event)"
      ></create-object-toolbar>
      <ceg-budget-allocations
        *ngIf="allocationTotals"
        [objectId]="currentState?.objectId"
        [objectType]="objectType"
        [companyId]="companyId"
        [budgetId]="budget?.id"
        [isReadOnlyMode]="true"
        [budgetTimeframes]="budgetTimeframes"
        [objectAllocations]="goalAllocations"
        [allocationTotals]="allocationTotals"
      ></ceg-budget-allocations>
    </div>

    <div class="tab-content-container" [ngClass]="{ 'visible': activeTabId === ObjectDetailsTabControl.Performance }">
      <details-metric-control
        [defaultStartDate]="budget?.budget_from"
        [defaultEndDate]="budget?.budget_to"
        [todayDate]="budgetTodayDate"
        [objectType]="objectType"
        [objectId]="currentState?.objectId"
        [companyCurrency]="companyCurrency?.symbol"
        [objectBudget]="allocationTotals?.budget || 0"
        [totalSpend]="allocationTotals?.actual || 0"
        [metricTypes]="metricsManager.types"
        [savedMetricMappings]="currentState?.metricMappings"
        [isReadOnlyMode]="isReadOnlyMode"
        [products]="metricsManager.products"
        [keyMetricSelection]="false"
        [isPowerUser]="isPowerUser"
        (onAddMapping)="addMetricMappings($event)"
        (keyMetricPerformanceData)="updatePerformanceTabData($event)"
        (onOpenMetric)="openMetric($event)"
        ></details-metric-control>
    </div>
  </div>
</article>
