import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, forkJoin, merge, of, Subject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { debounceTime, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { BudgetObjectCreationContext } from '../../../types/details-creation-context.interface';
import { BudgetObjectDetailsComponent } from '../../../types/budget-object-details-component.interface';
import { GoalDetailsService } from '../../../services/goal-details.service';
import { GoalDetailsState } from '../../../types/budget-object-details-state.interface';
import { BudgetObjectDetailsManager } from '../../../services/budget-object-details-manager.service';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BudgetObjectTagsService } from '../../../services/budget-object-tags.service';
import { BudgetObjectMetricsService } from '../../../services/budget-object-metrics.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { UserDataService } from 'app/shared/services/user-data.service';
import { GoalTypeService } from 'app/shared/services/backend/goal-type.service';
import { TagControlEvent, TagsControlComponent } from 'app/shared/components/tags-control/tags-control.component';
import { DetailsExpensesTotalRow } from '../../details-expenses/details-expenses-table/details-expenses-table.type';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { ObjectHierarchy } from '../../object-hierarchy-nav/object-hierarchy-nav.type';
import { DetailsAction } from '../../details-header/details-header.type';
import { BudgetObjectActionsBuilder } from '../../../services/budget-object-actions-builder.service';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { messages, objectPlaceholderName } from '../../../messages';
import { DataValidationService } from 'app/budget-object-details/services/data-validation.service';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { Attachment } from 'app/shared/types/attachment.interface';
import { BudgetObjectAttachmentsService } from '../../../services/budget-object-attachments.service';
import { TimeframeShortNamePipe } from 'app/shared/pipes/timeframe-short-name.pipe';
import { ObjectHierarchyService } from '../../../services/object-hierarchy.service';
import { CompanyService } from 'app/shared/services/backend/company.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { ChildObjectsService } from '../../child-objects/child-objects.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedCostRulesService } from 'app/shared/services/backend/shared-cost-rules.service';
import { Tag } from 'app/shared/types/tag.interface';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';
import { DetailsExpensesService } from '../../details-expenses/details-expenses.service';
import { UserDO } from 'app/shared/types/user-do.interface';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { LAST_CREATED_OBJECT_ID } from 'app/shared/constants/storage.constants';
import { GoalDO, GoalTypeDO } from 'app/shared/types/goal.interface';
import { MetricMappingDetailsService } from '../../../services/metric-mapping-details.service';
import { MetricMappingDO } from 'app/shared/services/backend/metric.service';
import { HierarchyViewMode } from '@spending/types/expense-page.type';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { BudgetObjectActionsShared } from '../../../services/budget-object-actions-shared';

interface GoalDetailsForm {
  name: string;
  typeId: number;
  customType: string;
  notes: string;
}

@Component({
  selector: 'goal-details',
  templateUrl: './goal-details.component.html',
  styleUrls: ['../details-container.scss', './goal-details.component.scss'],
  providers: [
    BudgetObjectTagsService,
    BudgetObjectMetricsService,
    BudgetObjectAttachmentsService,
    BudgetObjectActionsShared
  ]
})
export class GoalDetailsComponent implements OnInit, OnDestroy, BudgetObjectDetailsComponent {
  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);

  @ViewChild('tagsControl') tagsControl: TagsControlComponent;

  private readonly destroy$ = new Subject<void>();
  private readonly reset$ = new Subject<void>();
  public goalFormData: UntypedFormGroup;
  private prevState: GoalDetailsState = null;
  public currentState: GoalDetailsState;

  public currentUser: CompanyUserDO;
  public readonly objectType = this.configuration.OBJECT_TYPES.goal;
  public companyId: number;
  public budget: Budget;
  public companyCurrency: {
    symbol: string;
    code: string;
  };
  public menuActions: DetailsAction[] = [];

  public companyUsers: UserDO[] = [];
  public goalTypes: BudgetObjectType[] = [];
  public budgets: Budget[] = [];
  public budgetTimeframes: BudgetTimeframe[] = [];
  public segments: BudgetSegmentAccess[] = [];
  public segmentNameById: {[key: number]: string};
  public sharedCostRules: SharedCostRule[] = [];
  public company: CompanyDO;

  public detailsExpensesTotals: DetailsExpensesTotalRow;
  public hierarchy: ObjectHierarchy = {
    Goal: null,
    Program: null,
    Campaign: null,
    Expense: null
  };
  public isLoading = false;
  public isReadOnlyMode = true;
  public isCustomTypeEntering = false;
  public unsavedChangesFlag = false;
  private transparencyAccessChecked = false;
  private isDeleteActionObject = { value: false };
  public totalCampaignsBusinessValue: number = null;
  public isPowerUser: boolean;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public readonly configuration: Configuration,
    public readonly goalDetailsService: GoalDetailsService,
    public readonly appRoutingService: AppRoutingService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly menuActionsBuilder: BudgetObjectActionsBuilder,
    private readonly companyDataService: CompanyDataService,
    private readonly utilityService: UtilityService,
    private readonly fb: UntypedFormBuilder,
    public readonly tagsManager: BudgetObjectTagsService,
    public readonly metricsManager: BudgetObjectMetricsService,
    private readonly userManager: UserManager,
    private readonly userDataService: UserDataService,
    private readonly dialogManager: BudgetObjectDialogService,
    public readonly dataValidation: DataValidationService,
    public readonly goalTypeService: GoalTypeService,
    public readonly attachmentsManager: BudgetObjectAttachmentsService,
    private timeframeShortName: TimeframeShortNamePipe,
    private readonly hierarchyService: ObjectHierarchyService,
    private readonly companyService: CompanyService,
    private readonly childObjectsService: ChildObjectsService,
    private readonly router: Router,
    private dialog: MatDialog,
    private readonly sharedCostRulesService: SharedCostRulesService,
    private readonly budgetObjectService: BudgetObjectService,
    private readonly objectAccessManager: ObjectAccessManagerService,
    private readonly detailsExpensesService: DetailsExpensesService,
    private readonly metricMappingDetailsService: MetricMappingDetailsService,
  ) {
    this._createForm();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => this.init(params))
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadInitialBudgetData() {
    return combineLatest([
      this.budgetObjectDetailsManager.getCompanyId().pipe(tap(companyId => this.companyId = companyId)),
      this.budgetObjectDetailsManager.getCurrentBudget().pipe(map(budget => {
        this.budget = budget;
        return budget.id;
      }))]
    );
  }

  private syncUnsavedChangesFlag(flag?: boolean) {
    this.unsavedChangesFlag = typeof flag === 'boolean' ? flag : this.hasUnsavedChanges();
  }

  private initHierarchy(objectDetailsState) {
    this.hierarchy = this.hierarchyService.buildObjectHierarchy(
      {
        id: objectDetailsState.id,
        name: objectDetailsState.name
      },
      this.objectType,
      {}
    );
  }

  init(routeParams: Params) {
    this.reset$.next();
    if (this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot)) {
      this.initGoalCreation();
    } else {
      const goalIdParam = routeParams && routeParams[AppRoutingService.DETAILS_OBJECT_ID_PARAM_NAME];
      const goalId = goalIdParam && Number.parseInt(goalIdParam, 10);
      if (goalId) {
        this.loadGoal(goalId);
      } else {
        this.onError(
          '[Goal Details]: init(): incorrect goal id provided: ' + goalIdParam,
          messages.UNABLE_TO_LOAD_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
          true
        );
      }
    }

    this.userDataService.editPermission$
      .pipe(takeUntil(merge(this.destroy$, this.reset$)))
      .subscribe((editPermission: boolean) => this.updateReadOnlyModeState(editPermission));
  }

  initGoalCreation(reset: boolean = false, afterInitCb: Function = null) {
    this.showLoader();
    const contextData = AppRoutingService.getHistoryStateProperty<BudgetObjectCreationContext>('data');
    const budgetData$ = reset ?
      of([this.companyId, this.budget.id]) :
      this.loadInitialBudgetData();

    budgetData$.pipe(
      switchMap(([companyId, budgetId]) =>
        combineLatest([
          this.goalDetailsService.initDetails(contextData, {
            company: companyId,
            budget: budgetId
          }),
          this.loadDetailsContextData(companyId)
        ])
      ),
      map(([objectDetailsState]) => {
        this.initHierarchy(objectDetailsState);
        this.goalTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.goalTypes);
        if (this.currentUser?.user) {
          objectDetailsState.createdBy = this.currentUser.user;
        }
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => {
        this.onGoalLoaded(state, false);
        if (afterInitCb) {
          afterInitCb();
        }
      },
      error => this.onError(
        error,
        messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
        true
      )
    );
  }

  loadGoal(goalId: number) {
    this.showLoader();
    this.loadInitialBudgetData().pipe(
      switchMap(([companyId, budgetId]) =>
        combineLatest([
          this.goalDetailsService.loadDetails(companyId, budgetId, goalId, { isCEGMode: false }),
          this.loadDetailsContextData(companyId)
        ])
      ),
      map(([objectDetailsState]) => {
        this.budgetObjectDetailsManager.checkObjectBudgetStateConsistency({
          state: objectDetailsState,
          budgetId: this.budget?.id
        });
        this.hierarchy =
          this.hierarchyService.buildObjectHierarchy(
            {
              id: objectDetailsState.objectId,
              name: objectDetailsState.name
            },
            this.objectType,
            {}
          );
        this.goalTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.goalTypes, objectDetailsState.typeId);
        this.loadAttachments(objectDetailsState);
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => {
        this.onGoalLoaded(state);
        this.budgetObjectDetailsManager.logObjectView(
          state.objectId,
          this.companyId,
          this.budget.id,
          this.currentUser.user,
          HistoryObjectLogTypeNames.goal,
          this.goalDetailsService);
      },
      error => this.onError(
        error,
        messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
        true)
    );
  }

  defineCurrency() {
    this.companyCurrency = {
      symbol: this.company.currency_symbol,
      code: this.company.currency
    }
  }

  loadSegmentsMapping() {
    this.sharedCostRulesService.getSegmentsMapByBudgetId(this.budget.id)
      .subscribe(
        mapping => this.segmentNameById = mapping,
        error => this.onError(error, 'loadSegmentsMapping')
      );
  }

  private resetDetails() {
    this.reset$.next();
    this.goalFormData.reset();
    this.prevState = null;
    this.currentState = null;
    this.initGoalCreation(true);
  }

  updateReadOnlyModeState(editPermission: boolean) {
    this.isReadOnlyMode = !editPermission;
    this.updateMenuActions();
  }

  onGoalLoaded(state, syncState = true) {
    this.checkAccessToGoal(state);
    this.currentState = state;
    if (syncState) {
      this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(state);
    }
    this.resetFormData();
    this.updateMenuActions();
    this.updateDetailsExpensesTotals();
    this.defineCurrency();
    this.setFormData();
    this.hideLoader();

    this.goalFormData.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.syncUnsavedChangesFlag());
  }

  checkAccessToGoal({ campaigns, programs }) {
    const isRegularUser = this.userDataService.isRegularUser(this.currentUser);
    if (isRegularUser) {
      const childObjects = [...campaigns, ...programs];
      const checkAccess = (object) => (
        this.objectAccessManager.hasAccessBySegmentData(
          object,
          this.segments,
          this.sharedCostRules
        )
      );
      let hasAccess;
      if (this.company.goal_detail_transparency) {
        hasAccess = childObjects.length ? childObjects.some(obj => checkAccess(obj)) : true;
      } else {
        hasAccess = childObjects.length ? childObjects.every(obj => checkAccess(obj)) : true;
      }

      if (!this.transparencyAccessChecked && !hasAccess) {
        this.transparencyAccessChecked = true;
        this.router.navigate([this.configuration.ROUTING_CONSTANTS.PLAN_DETAIL]);
        this.showRestrictedAccessMessage();
        this.appRoutingService.closeDetailsPage();
        return;
      }
    }
  }

  showRestrictedAccessMessage() {
    this.dialogManager.openConfirmationDialog({
      content: 'Your administrator has restricted access to goal details.',
      submitAction: {
        label: 'OK',
        handler: () => null
      }
    });
}

  updateDetailsExpensesTotals() {
    this.detailsExpensesTotals = this.detailsExpensesService.prepareTotalsRow(this.currentState.statusTotals);
  }

  loadDetailsContextData(companyId: number) {
    this.companyDataService.loadGoalTypes(
      companyId,
      error => this.onError(
        error,
        messages.UNABLE_TO_LOAD_OBJECT_TYPES_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
        true
      )
    );

    this.loadSegmentsMapping();

    this.companyDataService.loadMetrics(
      companyId,
      error => this.onError(error, messages.UNABLE_TO_LOAD_METRICS_ERROR_MSG, true)
    );

    this.companyDataService.loadTags(
      companyId,
      error => this.onError(error, messages.UNABLE_TO_LOAD_TAGS_ERROR_MSG, true));

    return combineLatest(
      [
        this.companyDataService.selectedCompanyDO$.pipe(tap(company => this.company = company)),
        this.budgetObjectDetailsManager.getTags().pipe(tap(tags => this.tagsManager.setTags(tags))),
        this.budgetObjectDetailsManager.getGoalTypes().pipe(tap(types => this.goalTypes = [
          ...types,
          this.budgetObjectDetailsManager.enterCustomTypeOption
        ])),
        this.budgetObjectDetailsManager.getCompanyUsers().pipe(tap(users => this.companyUsers = users)),
        this.budgetObjectDetailsManager.getSegments().pipe(tap(segments => this.segments = segments)),
        this.budgetObjectDetailsManager.getSharedCostRules().pipe(tap(rules => this.sharedCostRules = rules)),

        this.budgetObjectDetailsManager.getProducts$().pipe(
          mergeMap(
            products => this.budgetObjectDetailsManager.getMetricTypes().pipe(
              tap(mTypes => {
                this.metricsManager.setProducts(products);
                this.metricsManager.setTypes(mTypes);
              })
            ),
          )
        ),
        this.budgetObjectDetailsManager.getBudgets(this.budget?.id).pipe(tap(budgets => this.budgets = budgets)),
        this.budgetObjectDetailsManager.getTimeframes().pipe(tap(tfs => this.budgetTimeframes = tfs)),
        this.userManager.currentCompanyUser$.pipe(
          filter(user => !!user),
          tap(user => {
            this.currentUser = user;
            this.isPowerUser = this.userDataService.isPowerUser(user);
          })
        )
      ]
    );
  }

  hasUnsavedChanges(): boolean {
    if (!this.currentState || this.isDeleteActionObject.value) {
      return false;
    }
    // TODO: WE SHOULDN'T SAVE FORM DATA DURING CHECK UNSAVED CHANGES
    this.saveFormData();
    return this.goalDetailsService.hasChanges(this.prevState, this.currentState);
  }

  saveChanges(onSavedCb: Function = null, completed: boolean = false): void {
    this.showLoader();
    const isNewObject = !this.currentState.objectId;

    forkJoin([
      this.tagsManager.createTags(
        this.companyId,
        this.tagsManager.getLocalTags()
      )
    ]).pipe(
      tap(([addedTags]) => {
        this.applyCreatedTags(addedTags);
      }),
      mergeMap(() => {
        this.saveFormData();
        return this.goalDetailsService.saveDetails(this.prevState, this.currentState);
      }),
      switchMap((data: GoalDO) => this.saveMappings(data)),
      tap((data: GoalDO) => {
        if (isNewObject) {
          LocalStorageService.addToStorage(LAST_CREATED_OBJECT_ID, data.id);
        }
      })
    ).subscribe(
      () => {
        this.onSavedSuccessfully(completed, isNewObject);
        onSavedCb?.();
      },
      error =>
        this.onError(
          error,
          messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
        )
    );
  }

  saveMappings(goal) {
    const tagInc = this.tagsManager.getTagsIncrement(
      this.prevState && this.prevState.tagMappings,
      this.currentState && this.currentState.tagMappings
    );

    const objectId = this.currentState.objectId;

    return forkJoin([
      this.tagsManager.deleteTagMappings(tagInc.deleted),
      this.tagsManager.createTagMappings(objectId, this.objectType, tagInc.created),
    ]).pipe(
      tap(([
        _deletedTags,
        createdTags,
      ]) => {
        this.tagsManager.applyCreatedMappings(this.currentState.tagMappings, createdTags);
      }),
      map(() => goal)
    )
  }

  onSavedSuccessfully(completedState: boolean, isNewObject: boolean) {
    if (!this.prevState) {
      this.attachmentsManager.setObjectContext({
        objectId: this.currentState.objectId,
        objectType: this.objectType,
        companyId: this.companyId
      });
      this.budgetObjectDetailsManager.logObjectView(
        this.currentState.objectId,
        this.companyId,
        this.budget.id,
        this.currentUser.user,
        HistoryObjectLogTypeNames.goal,
        this.goalDetailsService
      );
      this.updateMenuActions();
    }
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    const message = this.budgetObjectDetailsManager.defineSuccessMessage(this.objectType, isNewObject, '', completedState);
    this.onSuccess(message);
    this.budgetObjectDetailsManager.reportDetailsChange(this);
    this.syncUnsavedChangesFlag(false);
    this.setFormValidators(this.currentState.name);
    this.hierarchyService.setHierarchyObjectName(
      this.hierarchy,
      this.configuration.OBJECT_TYPES.goal,
      this.currentState.name
    );
  }

  private showLoader() {
    this.isLoading = true;
    this.utilityService.showLoading(true);
  }

  private hideLoader() {
    // A workaround needed for e2e tests driver to catch loader's disappearing
    setTimeout(() => {
      this.isLoading = false;
      this.utilityService.showLoading(false);
    });
  }

  /* METRICS */
  addMetricMappings(metricMappings: MetricMappingDO[]) {
    const newMappings = metricMappings.map(mappingDO => this.metricsManager.convertDataObjectToMapping(mappingDO))
    this.currentState.metricMappings = [ ...this.currentState.metricMappings, ...newMappings ];
  }

  /* TAGS */
  createTag(tag: TagControlEvent) {
    this.tagsManager.addLocalTag(tag);
    this.tagsManager.addLocalMapping(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  addTag(tag: TagControlEvent) {
    this.tagsManager.addLocalMapping(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  removeTag(tag: TagControlEvent) {
    this.tagsManager.deleteLocalTag(tag);
    this.tagsManager.deleteLocalMapping(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  /* ACTIONS MENU */
  private updateMenuActions() {
    if (!(this.currentState && this.currentState.objectId)) {
      this.menuActions = [];
      return;
    }

    this.menuActionsBuilder
      .reset()
      .addCloneAction(this.objectType, this.handleClone.bind(this), this.isReadOnlyMode)
      .addDeleteAction(this.objectType, this.handleDelete.bind(this), this.isReadOnlyMode);

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  handleClone() {
    const clone$ = this.goalDetailsService.cloneObject(this.currentState.objectId)
      .pipe(
        tap(() => {
          this.budgetObjectDetailsManager.reportDetailsChange(this);
        })
      );

    this.budgetObjectDetailsManager.cloneObject(clone$, this.objectType)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => this.appRoutingService.openGoalDetails(data && data.id),
        (err) => this.onError(
          err,
          messages.UNABLE_TO_CLONE_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
        )
      );
  }

  handleDelete() {
    this.dialogManager.openDeleteEntityDialog(() => {
      this.budgetObjectDetailsManager.detachDirectChildObjects({
        campaigns: this.currentState.campaigns,
        programs: this.currentState.programs,
        expenses: this.currentState.expenses
      }, this.objectType.toLowerCase())
      .pipe(
        switchMap(() => this.budgetObjectDetailsManager.deleteMetricMappings(this.currentState.metricMappings)),
        switchMap(() => this.goalDetailsService.deleteObject(this.currentState.objectId)),
        tap(() => this.isDeleteActionObject.value = true),
      )
      .subscribe(
        () => {
          this.onSuccess(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, this.objectType), true);
          this.budgetObjectDetailsManager.reportDetailsChange(this);
        },
        error =>
          this.onError(
            error,
            messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
          )
      );
    }, this.budgetObjectService.getObjectTypeName(this.objectType));
  }

  handleBudgetToMoveSelected(budget: Budget): void {
    this.budgetObjectActionsShared.handleBudgetToMoveSelected(
      budget,
      this.companyId,
      this.currentState.objectId,
      this.objectType,
      false
    );
  }

  handleCancelAction() {
    this.appRoutingService.closeDetailsPage();
  }

  handleTypeChange() {
    const { typeId } = this.goalFormData.value;
    this.isCustomTypeEntering = typeId === this.budgetObjectDetailsManager.enterCustomTypeOption.id;
  }

  handleCustomTypeChange() {
    const { customType } = this.goalFormData.value;
    if (customType) {
      const existingType = this.goalTypes.find(
        goalType => goalType.name.toLowerCase() === customType.toLowerCase()
      );
      if (existingType) {
        this.goalFormData.patchValue({ typeId: existingType.id, customType: '' });
      } else {
        this.createCustomGoalType(customType);
      }
    }
    this.isCustomTypeEntering = false;
  }

  createCustomGoalType(customType: string) {
    this.utilityService.showLoading(true);
    let newCreatedTypeId: number;
    this.goalTypeService.createGoalType({
      name: customType,
      is_custom: true,
      company: this.companyId
    }).pipe(
      switchMap((createdType: GoalTypeDO) => {
        newCreatedTypeId = createdType.id;
        this.companyDataService.loadGoalTypes(this.companyId);
        return this.budgetObjectDetailsManager.getGoalTypes(true)
          .pipe(take(1));
      })
    ).subscribe((goalTypes: BudgetObjectType[]) => {
      this.goalTypes = [...goalTypes, this.budgetObjectDetailsManager.enterCustomTypeOption];
      this.goalFormData.patchValue({ typeId: newCreatedTypeId, customType: '' });
      this.isCustomTypeEntering = false;
      this.utilityService.showLoading(false);
    });
  }

  validateChanges(): boolean {
    if (this.goalFormData.valid) {
      return true;
    }

    this.dataValidation.validateFormFields(this.goalFormData);
    return false;
  }

  submitChanges(submitCallback: Function) {
    const isFormValid = this.validateChanges();
    if (isFormValid && submitCallback) {
      return submitCallback();
    }
  }

  handleSaveAction() {
    this.checkTagsLeftover();
    if (this.hasUnsavedChanges()) {
      this.saveChanges();
    }
  }

  handleSaveAndNewAction() {
    this.checkTagsLeftover();
    const onSaved = this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot)
      ? this.resetDetails.bind(this)
      : this.appRoutingService.openGoalCreation.bind(this.appRoutingService);

    if (this.hasUnsavedChanges()) {
      this.saveChanges(onSaved);
      return;
    }

    onSaved();
  }

  handleSaveAndCloseAction() {
    this.checkTagsLeftover();

    if (this.hasUnsavedChanges()) {
      const onSaved = this.appRoutingService.closeDetailsPage.bind(this.appRoutingService);
      this.saveChanges(onSaved, true);
      return;
    }
    this.appRoutingService.closeDetailsPage();
  }

  private checkTagsLeftover(): void {
    this.tagsManager.checkInputLeftover(this.tagsControl, this.currentState.tagMappings);
  }

  applyCreatedTags(addedTags: Tag[]) {
    if (addedTags && addedTags.length) {
      this.companyDataService.loadTags(this.companyId);
      this.tagsManager.applyAddedTags(addedTags, this.currentState.tagMappings);
    }
  }

  /* FORM DATA */
  private _createForm() {
    const formData = {
      name: ['', {
        validators: [Validators.required, Validators.maxLength(this.budgetObjectDetailsManager.maxObjectNameLength)],
        updateOn: 'blur'
      }],
      typeId: [null, [Validators.required, Validators.min(0)] ],
      customType: '',
      notes: '',
    };
    this.goalFormData = this.fb.group(formData);
  }

  private setFormValidators(nameValue: string) {
    const nameControl = this.goalFormData.get('name');
    if (nameControl) {
      nameControl.asyncValidator = this.dataValidation.uniqueNameValidator(
        this.companyId, this.budget.id, nameValue, this.objectType
      );
    }
  }

  private resetFormData() {
    const nameControl = this.goalFormData.get('name');
    if (nameControl) {
      nameControl.asyncValidator = null;
      nameControl.setErrors(null);
    }
  }

  private setFormData() {
    const { typeId, notes = '', name = '' } = this.currentState;
    const formData: GoalDetailsForm = {
      name,
      typeId,
      customType: '',
      notes
    };

    this.goalFormData.patchValue(formData);
    this.setFormValidators(name);
    this.autofillGoalTypeValue();
  }

  private autofillGoalTypeValue() {
    this.budgetObjectDetailsManager.autofillTypeSelectValue(
      this.goalFormData.get('typeId'),
      this.goalTypes.filter(type => !!type.id)
    );
  }

  private formDataToState(): Partial<GoalDetailsState> {
    const formData = this.goalFormData.value as GoalDetailsForm;
    return {
      name: formData.name,
      notes: formData.notes,
      typeId: formData.typeId
    }
  }

  private saveFormData() {
    const formDataState = this.formDataToState();
    Object.keys(formDataState).forEach(key => {
      this.currentState[key] = formDataState[key];
    })
  }

  private onSuccess(message: string, close = false) {
    this.hideLoader();
    this.utilityService.showToast({ Type: 'success', Message: message });
    if (close) {
      this.appRoutingService.closeDetailsPage();
    }
  }

  private onError(error, message, close = false) {
    this.hideLoader();
    this.budgetObjectDetailsManager.handleError(error, message, close);
  }

  public getContextForChildObjectCreation(): BudgetObjectCreationContext {
    return {
      parent: {
        id: this.currentState.objectId,
        type: this.objectType,
      }
    };
  }

  addNewExpense() {
    this.appRoutingService.openExpenseCreation(this.getContextForChildObjectCreation());
  }

  addNewProgram() {
    this.appRoutingService.openProgramCreation(this.getContextForChildObjectCreation());
  }

  addNewCampaign() {
    this.appRoutingService.openCampaignCreation(this.getContextForChildObjectCreation());
  }

  viewExpenses() {
    if (this.currentState.objectId != null) {
      this.budgetObjectDetailsManager.viewExpenses({
        [FilterName.Goals]: [this.currentState.objectId]
      }, HierarchyViewMode.Goal);
    }
  }

  loadAttachments(state: GoalDetailsState) {
    this.attachmentsManager.setObjectContext({
      objectId: state.objectId,
      objectType: this.objectType,
      companyId: this.companyId
    });
    this.attachmentsManager.loadAttachments(state.attachmentMappings)
      .subscribe({
        error: (err) => this.onError(err, '')
      });
  }

  handleFileAttached($event) {
    this.attachmentsManager.uploadFiles($event.target.files)
      .subscribe({
        error: (err) => this.onError(err, ''),
        complete: () => $event.target.value = null
      });
  }

  handleFileDelete(attachment: Attachment) {
    this.dialogManager.openDeleteEntityDialog(() => {
      this.attachmentsManager.deleteFile(attachment)
        .subscribe({
          error: (err) => this.onError(err, '')
        });
    }, 'file');
  }

  handleFileDownload(attachment: Attachment) {
    this.attachmentsManager.downloadFile(attachment)
      .subscribe({
        error: (err) => this.onError(err, '')
      });
  }
}
