<div class="details-form-field">    
    <div class="icon-container">
        <fa-icon [icon]="['fas', 'list-dropdown']"></fa-icon>
    </div>
    <mat-form-field
        appearance="outline" 
        class="p-form-field-outline custom-field-element"
        [matTooltip]="config.description"
        [matTooltipDisabled]="!(config.description && config.description.trim().length > 0)"
        matTooltipShowDelay="600"
        matTooltipClass="dark-theme-tooltip above"
        matTooltipPosition="above"
        >
        <mat-label> {{ config.cfName }} </mat-label>
        <mat-select
        [formControl]="control"
        [required]="config.required"
        [value]="config.selectedValue"
        [multiple]="config.isMultiSelect"
        (selectionChange)="onSelectionChange($event)"
        (closed)="onDropdownClosed()"
        >
        <mat-select-trigger *ngIf="config.isMultiSelect">


            <span *ngIf="(control.value?.length || 0) <= 1" > {{ control.value?.[0] || '' }} </span>
            <span *ngIf="(control.value?.length || 0) > 1">
                {{ (control.value?.length || 0)}} {{ "Selected" }}
            </span>
        </mat-select-trigger>
        <search-control
            [placeholder]="'Search '"
            [currentSearch]="searchText"
            (textChanged)="filterOptionsByText($event)"
        ></search-control>
        <div class="mat-option select-all" *ngIf="config.isMultiSelect">
            <mat-checkbox
                color="primary" 
                [checked]="isAllSelected"
                [indeterminate]="isIndeterminate"
                [disableRipple]="true"
                (change)="toggleSelectAll($event.checked)"
            >
            Select All
            </mat-checkbox>
          </div>
            <mat-option *ngIf="config.allowNoSelection" [value]="null">No Custom Value</mat-option>
            <mat-option class="cf-option" *ngFor="let option of filteredOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
        <mat-error>This field is required</mat-error>
    </mat-form-field>
</div>