import { inject, Injectable } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { BudgetTimeframeDataItem } from 'app/dashboard/widget.service';
import { CompanyDataService } from '@shared/services/company-data.service';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { FilterSet } from 'app/header-navigation/components/filters/filters.interface';

@Injectable()
export class BudgetCegTimelineService {
  private readonly companyDataService = inject(CompanyDataService);
  private readonly tableDataService = inject(ManageCegTableDataService);
  private readonly managePageService = inject(ManageCegPageService);
  private readonly filterManagementService = inject(FilterManagementService);

  public get companyCurrency$ (): Observable<{ code: string; symbol: string }> {
    return this.companyDataService.selectedCompanyDO$.pipe(
        filter((cmp) => cmp != null),
        map((company) => {
          return {
            code: company.currency,
            symbol: company.currency_symbol,
          };
        })
      );
  }

  public get currentFilters$(): Observable<FilterSet> {
    return this.filterManagementService.budgetFiltersList$.pipe(
        switchMap(() => this.filterManagementService.budgetFiltersInit$),
        switchMap(() => this.filterManagementService.currentFilterSet$),
       );
  }

  public get timelineData(): Observable<BudgetTimeframeDataItem[]> {
    return this.tableDataService.grandTotalBudget$.pipe(
      filter(grandTotalData => grandTotalData !== null),
      switchMap(grandTotalBudget => {
        return combineLatest([
          of(grandTotalBudget),
          this.managePageService.budgetTimeframes$
        ]);
      }),
      map(([grandTotalBudget, timeframes]) => {
        const grandTotalData = grandTotalBudget;
        return timeframes
          .filter(timeframe => timeframe.isVisible)
          .reduce((timelineList, timeframeItem) => {
            const {
              actual: spent,
              available: budget_remaining_amount,
              budget: total,
              committed,
              planned
            } = (grandTotalData[timeframeItem.id] || {});

            const timelineDataItem = {
              company_budget_alloc_id: timeframeItem.id,
              spent,
              budget_remaining_amount,
              total,
              committed,
              planned,
              locked: timeframeItem?.locked,
              company_budget_alloc_name: timeframeItem?.name || timeframeItem.shortName
            };

            timelineDataItem['overdue'] = budget_remaining_amount < 0 ? Math.abs(budget_remaining_amount) : 0;
            timelineList.push(timelineDataItem);
            return timelineList;
          }, []);
      })
    )
  }
}
