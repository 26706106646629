import { Component, Inject, OnDestroy } from '@angular/core';
import { Validations } from 'app/app.validations';
import { TagService } from 'app/shared/services/backend/tag.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Configuration } from 'app/app.constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface CreateTagModalContext {
  onSubmit: (tagName, cb) => {}
}

@Component({
  selector: 'create-tag-modal',
  styleUrls: ['./create-tag-modal.component.scss'],
  templateUrl: './create-tag-modal.component.html'
})

export class CreateTagModalComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  uniqueTagName = true;
  tagNameControl: UntypedFormControl;
  MAX_TAG_NAME_LENGTH = this.configuration.MAX_TAG_NAME_LENGTH;

  constructor(
    private readonly dialogRef: MatDialogRef<CreateTagModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateTagModalContext,
    public readonly validations: Validations,
    private readonly tagService: TagService,
    private readonly configuration: Configuration
  ) {
    this.initNameControl();
  }

  initNameControl(): void {
    const validators = [
      Validators.required,
      Validators.maxLength(this.MAX_TAG_NAME_LENGTH)
    ];
    this.tagNameControl = new UntypedFormControl(null, { validators });
    this.tagNameControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.uniqueTagName = true);
  }

  submitForm(formValue) {
    const tagName = formValue.trim();
    this.tagService.validateUniqueTagName(tagName,
      () => this.data.onSubmit(tagName, () => this.dialogRef.close()),
      () => this.uniqueTagName = false
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
