import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WidgetConfig, WidgetType } from '../../types/widget.interface';
import { WidgetConfigService } from '../../services/widget-config.service';

@Component({
  selector: 'other-actions',
  styleUrls: ['./other-actions.component.scss'],
  templateUrl: './other-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherActionsComponent {
  public widgets: WidgetConfig[] = [];

  constructor(
    private readonly widgetConfig: WidgetConfigService,
  ) {}
}


