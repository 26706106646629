<div class="unsubscribe-page-wrapper">
  <div class="unsubscribe-page">
    <header class="header">
      <img class="logo" src="../../assets/images/planful-logo-colored.svg" alt=""/>
    </header>
    <section class="content">
      <ng-container *ngIf="requestState === RequestStates.Success">
        <p class="main-message" >
          You have successfully unsubscribed from Planful’s weekly email updates.
        </p>
        <p>
          If you wish to subscribe again, reach out directly to your Planful administrator.
        </p>
      </ng-container>
      <p *ngIf="requestState === RequestStates.Processing">
        Processing request
      </p>
      <p *ngIf="requestState === RequestStates.Failure">
        Failed to unsubscribe
      </p>
    </section>
    <footer class="footer">
      © {{ currentYear }} Planful, Inc. All rights reserved.
    </footer>
  </div>
</div>
