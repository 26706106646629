import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { FilterManagementService } from '../filter-services/filter-management.service';

@Directive({
  selector: '[filtersTrigger]',
})
export class FiltersContainerOverlayDirective implements OnDestroy, OnInit {
  @Input() filtersTrigger!: TemplateRef<object>;

  private destroy$ = new Subject<void>();
  private overlayRef: OverlayRef;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private filterManagement: FilterManagementService
  ) { }

  ngOnInit(): void {
    this.createOverlay();
    this.filterManagement.displayState$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        displayState => !displayState && this.detachOverlay()
      );
  }

  ngOnDestroy(): void {
    this.detachOverlay();
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click') displayOverlay() {
    this.attachOverlay();
  }

  private createOverlay(): void {
    const scrollStrategy = this.overlay.scrollStrategies.block();

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'filters-overlay',
    });

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.detachOverlay());
  }

  private attachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      const templatePortal = new TemplatePortal(
        this.filtersTrigger,
        this.viewContainerRef
      );
      this.overlayRef.attach(templatePortal);
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
