export enum HubspotMappingTypeName {
  forms = 'forms',
  emails = 'emails',
  utmCampaigns = 'utmCampaigns'
}

export interface HubspotCampaignMapping {
  [HubspotMappingTypeName.forms]: string[];
  [HubspotMappingTypeName.emails]: string[];
  [HubspotMappingTypeName.utmCampaigns]: string[];
}
