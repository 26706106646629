import { ManageCegTableDataService } from '../manage-ceg-table-data.service';
import { Configuration } from 'app/app.constants';
import { BudgetObjectEventContext } from '../../../budget-object-details/types/budget-object-event.interface';
import { Observable, of } from 'rxjs';
import { BaseBudgetObjectEventHandler } from './base-budget-object-event-handler';

export class ExpenseEventHandler extends BaseBudgetObjectEventHandler {
  public constructor(
    manageCegTableDataService: ManageCegTableDataService,
    config: Configuration
  ) {
    super(manageCegTableDataService, config);
  }

  protected onCreate(data: BudgetObjectEventContext): Observable<boolean> {
    return this.updateLoadedParentHierarchy(data);
  }

  protected onDelete(data: BudgetObjectEventContext): Observable<boolean> {
    return this.updateLoadedParentHierarchy(data);
  }

  protected onMoved(data: BudgetObjectEventContext): Observable<boolean> {
    return this.updateLoadedParentHierarchy(data);
  }

  protected onUpdate(data: BudgetObjectEventContext): Observable<boolean> {
    const nearestParentRowsForNewData =
      this.getNearestLoadedParentRows(data.parentObject, data.segmentId, data.sharedCostRuleId);
    const nearestParentRowsForPrevData =
      this.getNearestLoadedParentRows(data.prevParentObject, data.prevSegmentId, data.prevSharedCostRuleId);

    const uniqueAffectedParentRows = [
      ...nearestParentRowsForNewData,
      ...nearestParentRowsForPrevData.filter(prevRow => !nearestParentRowsForNewData.some(newRow => newRow.id === prevRow.id))
    ];

    return uniqueAffectedParentRows?.length ?
      this.updateParentRows(uniqueAffectedParentRows) :
      of(false);
  }

  private updateLoadedParentHierarchy(data: BudgetObjectEventContext): Observable<boolean> {
    const nearestParentRows = this.getNearestLoadedParentRows(data.parentObject, data.segmentId, data.sharedCostRuleId);

    return nearestParentRows?.length ?
      this.updateParentRows(nearestParentRows) :
      of(false);
  }
}
