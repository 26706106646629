<grid-with-search
  [gridData]="goalTypeList"
  [gridColumns]="gridColumns"
  [SearchPlaceHolder]="'Search '+searchString"
  (deleteGridItem)="deleteGoalType($event)"
  (addNewGridItem)="addGoalType($event)"
  (editGridItem)="updateGoalType($event)"
  [enableSwitchTooltip]="enableSwitchTooltip"
  (updateStatusChange)="updateStatusChange($event)"
  [customActions]="true"
  [customActionLabel]="'is_custom'"
  [editableMaxLength]="140"
  [NoDataText]="NoDataText"
></grid-with-search>
