import { Component, Input, inject } from '@angular/core';
import { ObjectHierarchy } from './object-hierarchy-nav.type';
import { Configuration } from 'app/app.constants';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { AppRoutingService } from '@shared/services/app-routing.service';

@Component({
  selector: 'object-hierarchy-nav',
  templateUrl: './object-hierarchy-nav.component.html',
  styleUrls: ['./object-hierarchy-nav.component.scss']
})
export class ObjectHierarchyNavComponent {
  @Input() data: ObjectHierarchy = {
    Goal: null,
    Campaign: null,
    ChildCampaign: null,
    Program: null,
    Expense: null,
    Metric: null
  };

  public orderedTypes = [];
  public iconByObjectType = {};
  public baseRouteByObjectType = {};
  public routeActionByRowType: Record<string, Function>;
  public readonly objectMode = ObjectMode;
  private readonly appRoutingService = inject(AppRoutingService);

  constructor(private readonly configuration: Configuration) {
    this.initData();
  }

  protected handleNameClick(objectType: string, id: number): void {
    this.appRoutingService.closeDetailsPage();
    setTimeout(() => this.routeActionByRowType[objectType]?.(id));
  }

  private initData() {
    const { goal, campaign, childCampaign, program, expense, metric } = this.configuration.OBJECT_TYPES;

    this.orderedTypes = [goal, campaign, childCampaign, program, expense, metric];
    this.iconByObjectType = {
      [goal]: { icon: 'bullseye-arrow', flip: true },
      [campaign]: { icon: 'rocket-launch' },
      [childCampaign]: { icon: 'rocket-launch' },
      [program]: { icon: 'briefcase' },
      [expense]: { icon: 'coins' },
      [metric]: { icon: 'chart-line' },
    };
    this.baseRouteByObjectType = {
      [goal]: 'goal/',
      [campaign]: 'campaign/',
      [childCampaign]: 'campaign/',
      [program]: 'program/',
      [expense]: 'expense/',
    };

    this.routeActionByRowType = {
      [goal]: (id: number) => this.appRoutingService.openGoalDetails(id),
      [campaign]: (id: number) => this.appRoutingService.openCampaignDetails(id),
      [childCampaign]: (id: number) => this.appRoutingService.openCampaignDetails(id)
    };
  }
}
