import { ManageCegTableRow } from '@manage-ceg/types/manage-ceg-page.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { Observable, of } from 'rxjs';
import { TableRowAmountsLoader } from '@manage-ceg/types/manage-ceg-table-row-data.types';
import { ManageCegTableRowAllocations } from '../../types/manage-ceg-page.types';
import { tap } from 'rxjs/operators';

export class CachedSegmentAmountsLoader implements TableRowAmountsLoader {
  public readonly rowType = ManageTableRowType.Segment;
  private readonly segmentRowAllocations: Record<number, ManageCegTableRowAllocations>;

  constructor(segmentRowAllocations: Record<number, ManageCegTableRowAllocations>) {
    this.segmentRowAllocations = segmentRowAllocations;
  }

  public fillRowAmounts(_budgetId: number, rows: ManageCegTableRow[], _params: object): Observable<any> {
    return of(null).pipe(
      tap(
        () => rows.forEach(row => this.fillRowAllocations(row))
      )
    );
  }

  private fillRowAllocations(row: ManageCegTableRow): void {
    const rowAllocations = this.segmentRowAllocations[row.objectId];
    if (rowAllocations) {
      row.allocations = rowAllocations;
    } else {
      console.error('[CachedSegmentAmountsLoader]: missing segment allocation data for segment with id ' + row.objectId);
    }
  }
}
