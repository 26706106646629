<div class="editable-content-control"
  (click)="handleFocus();"
  (mouseenter)="handleMouseEnter()"
  (mouseleave)="handleMouseLeave()"
  [ngClass]="{
    'edit-mode': editMode && !isReadOnly,
    'is-readonly': isReadOnly,
    'is-empty': !value?.length,
    'has-errors': hasErrors
  }"
 floatingErrorMessage
 [errorMessage]="isHovered && hasErrors ? errorMessage : null"
>
  <textarea
    textFieldAutosize
    minRows="1"
    blurOnEnterKeydown
    [placeholder]="placeholder"
    [maxlength]="maxLength"
    [maxHeight]="28"
    [minHeight]="14"
    [formControl]="control"
    (blur)="handleBlur()"
    #controlInput
  ></textarea>
  <div
    class="read-value"
    *ngIf="isReadOnly || !editMode"
    [matTooltip]="value"
    [matTooltipDisabled]="!isReadOnly"
    matTooltipClass="dark-theme-tooltip above multi-line"
    matTooltipPosition="above"
  >
    {{ value || placeholder }}
    <span class="group-counter" *ngIf="record.type === RecordType.Group">({{ record?.nestedRecords?.length }})</span>
  </div>
</div>
