import { ManageCegTableRow, ManageCegViewMode } from '../types/manage-ceg-page.types';
import { LightProgram } from '@shared/types/program.interface';
import { LightCampaign } from '@shared/types/campaign.interface';
import { Goal } from '@shared/types/goal.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SegmentGroup } from '@shared/types/segment-group.interface';
import { ManageTableHelpers } from '../../manage-table/services/manage-table-helpers';
import { ObjectMode } from '@shared/enums/object-mode.enum';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

export class ManageCEGTableRowFactory {
  public static createExpenseGroupRow(
    expGroup: LightProgram,
    viewMode?: ManageCegViewMode,
    objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean
  ): ManageCegTableRow {
    return {
      name: expGroup.name,
      type: ManageTableRowType.ExpenseGroup,
      objectId: expGroup.id || expGroup.subProgramId,
      itemId: expGroup.objectId,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.ExpenseGroup, expGroup.objectId),
      parentId: ManageTableHelpers.getParentId({
        goal: expGroup.goalId,
        campaign: expGroup.campaignId,
        segment: expGroup.budgetSegmentId,
        viewMode,
        objectExists
      }),
      segmentId: expGroup.budgetSegmentId,
      sharedCostRuleId: expGroup.splitRuleId,
      isEditable: !expGroup.isPseudoObject,
      isSelectable: !expGroup.isPseudoObject,
      isClosed: expGroup.mode === ObjectMode.Closed,
      children: [],
      loadedChildren: [],
      shownChildren: [],
      objectTypeId: expGroup.programTypeId,
      segmentRelated: false,
      createdDate: expGroup.createdDate,
      status: expGroup.amountStatus,
      externalId: expGroup.externalId,
      currencyCode: expGroup.currencyCode
    };
  }

  public static createCampaignRow(
    campaign: LightCampaign,
    viewMode?: ManageCegViewMode,
    objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean,
    isSegmentless?: (row: ManageCegTableRow) => boolean
  ): ManageCegTableRow {
    const campaignRow = {
      name: campaign.name,
      type: ManageTableRowType.Campaign,
      itemId: campaign.objectId,
      objectId: campaign.id || campaign.subCampaignId,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.Campaign, campaign.objectId),
      parentId: ManageTableHelpers.getParentId({
        goal: campaign.goalId,
        segment: campaign.budgetSegmentId,
        campaign: campaign.parentCampaign,
        viewMode,
        objectExists
      }),
      segmentId: campaign.budgetSegmentId,
      sharedCostRuleId: campaign.splitRuleId,
      isEditable: !campaign.isPseudoObject,
      isSelectable: !campaign.isPseudoObject,
      isClosed: campaign.mode === ObjectMode.Closed,
      children: [],
      loadedChildren: [],
      shownChildren: [],
      objectTypeId: campaign.campaignTypeId,
      segmentRelated: false,
      createdDate: campaign.createdDate,
      status: campaign.amountStatus,
      externalId: campaign.externalId,
      currencyCode: campaign.currencyCode,
      campaign_integrated_source: campaign.campaign_integrated_source
    };

    campaignRow.isEditable &&= !isSegmentless?.(campaignRow);
    return campaignRow;
  }

  public static createGoalRow(goal: Goal): ManageCegTableRow {
    return {
      name: goal.name,
      type: ManageTableRowType.Goal,
      objectId: goal.id,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.Goal, goal.id),
      itemId: goal.id,
      parentId: null,
      isEditable: false,
      isSelectable: true,
      children: [],
      loadedChildren: [],
      shownChildren: [],
      createdDate: goal.createdDate,
      objectTypeId: goal.goalTypeId,
      segmentRelated: false
    };
  }

  public static createSegmentRow(segment: BudgetSegmentAccess, viewMode?: ManageCegViewMode): ManageCegTableRow {
    return {
      name: segment.name,
      type: ManageTableRowType.Segment,
      objectId: segment.id,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.Segment, segment.id),
      itemId: segment.id,
      parentId: ManageTableHelpers.getParentId({
        segmentGroup: segment.segment_group,
        viewMode,
      }),
      isEditable: false,
      isSelectable: true,
      children: [],
      loadedChildren: [],
      shownChildren: [],
      segmentRelated: true
    };
  }

  public static createSegmentGroupRow(segmentGroup: SegmentGroup): ManageCegTableRow {
    return {
      name: segmentGroup.name,
      type: ManageTableRowType.SegmentGroup,
      objectId: segmentGroup.id,
      id: ManageTableHelpers.getRowObjectId(ManageTableRowType.SegmentGroup, segmentGroup.id),
      itemId: segmentGroup.id,
      parentId: null,
      isEditable: false,
      isSelectable: true,
      children: [],
      loadedChildren: [],
      shownChildren: [],
      segmentRelated: true
    };
  }

  public static createObjectRow(
    objectType: ManageTableRowType,
    object: LightProgram | LightCampaign,
    viewMode?: ManageCegViewMode,
    objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean,
    isSegmentless?: (row: ManageCegTableRow) => boolean
  ): ManageCegTableRow {
    switch (objectType) {
      case ManageTableRowType.ExpenseGroup:
        return ManageCEGTableRowFactory.createExpenseGroupRow(
          object as LightProgram,
          viewMode,
          objectExists
        );
      case ManageTableRowType.Campaign:
        return ManageCEGTableRowFactory.createCampaignRow(
          object as LightCampaign,
          viewMode,
          objectExists,
          isSegmentless
        )
    }
  }
}
