<div class="gl-code-wrapper">
  <page-header pageName="GL Codes" [isAdminPage]="true">
    <fa-icon slot="icon" [icon]="['fas', 'rectangle-barcode']"></fa-icon>
    <button slot="custom-items"
            class="btn-add"
            type="button"
            title=""
            [disabled]="!glCodeList"
            (click)="openGlCodeModal(null)">Add</button>
  </page-header>
  <div class="col-glCode">
    <div class="box-glCodeInn">
    <ul class="list-glCode">
      <li>GL Codes are supported across all your budgets</li>
      <li>Expenses can be assigned any GL Code</li>
      <li>Expense import supports import of GL Codes</li>
    </ul>
    <div class="box-scrollTable">
      <table class="table-glCode" *ngIf="glCodeList">
        <thead>
        <tr>
          <th>GL Code<span class="icon-sortingTable" (click)="glSort('name')"></span></th>
          <th>Description</th>
          <th>Options</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let gl of glCodeList; let i=index;">
          <td>{{gl.name}}</td>
          <td>{{gl.description}}</td>
          <td>
            <ul class="list-btnEditDel">
              <li>
                <button class="btn-tableEdit" type="button" (click)="openGlCodeModal(gl)"></button>
              </li>
              <li>
                <button class="btn-tableDelete" type="button" (click)="deleteGlCode(gl)"></button>
              </li>
            </ul>
          </td>
        </tr>
        </tbody>
        <tfoot *ngIf="glCodeList.length == 0">
        <tr>
          <td colspan="3" class="td-expenseNotFound">
            <span>{{validations.ValiditionMessages.GL_CODE_NOT_AVAILABLE}}</span>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
  </div>
</div>
