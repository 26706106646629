import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'amount-control',
  styleUrls: ['./amount-control.component.scss'],
  templateUrl: './amount-control.component.html'
})
export class AmountControlComponent implements OnChanges {
  @Input() value = null;
  @Input() currency = null;
  @Input() isDecimal = true;
  @Input() allowNegative = false;
  @Input() disabled = true;

  @Output() onChange = new EventEmitter();
  @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;
  public currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', allowNegative: false, prefix: '' };
  public isFocused = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDecimal) {
      this.currencyMaskOptions.precision = this.isDecimal ? 2 : 0;
    }

    if (changes.allowNegative) {
      this.currencyMaskOptions.allowNegative = this.allowNegative;
    }
  }

  public setFocus(focus: boolean) {
    this.isFocused = focus;
    if (focus && this.inputRef) {
      this.inputRef.nativeElement.select();
    }
  }

  public handleChange() {
    if (this.inputRef) {
      const rawValue = this.inputRef.nativeElement.value;
      const preparedValue = parseFloat(rawValue.replace(/,/g, ''));
      this.onChange.emit(preparedValue);
    }
  }
}
