<div class="editable-text">
  <div
    *ngIf="!isEditing"
    class="text-section"
    [id]="valueId"
    (click)="onEditClick()"
  >
    <span>{{ value }}</span>
  </div>
  <div
    *ngIf="isEditing"
    [ngClass]="{ 'editable-area': true, 'error-field': error }"
  >
    <input
      #editable
      [id]="inputId"
      class="editable-input"
      type="text"
      [maxlength]="maxLength"
      [(ngModel)]="value"
      (blur)="onBlur()"
      (keyup.enter)="onBlur()"
      (input)="updateCharCount()"
      autocomplete="off"
    />
    <span class="char-counts">{{ charCount }}/{{ maxLength }}</span>
  </div>
  <span class="error" *ngIf="isEditing && error">{{ error }}</span>
</div>
