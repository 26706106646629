import { Injectable, inject} from '@angular/core';
import { API_V2_URL } from '../../../../../common-lib/src/lib/injection-tokens/url.tokens';
import { HttpClient } from '@angular/common/http';
import { customFieldList, customFieldDetails, customFieldOptions } from './custom-fields.type';
import { getRequestOptions, withAPIV0ErrorHandling } from '../../shared/utils/http-request.utils';
import { Observable } from 'rxjs';


@Injectable(
    {providedIn: 'root'}
)



export class CustomFieldsAdminService  {
    private readonly actionUrl = inject(API_V2_URL);
    private readonly http = inject(HttpClient);

    public apiPath = 'custom_fields/';


    getCFList(data): Observable<customFieldList[]> {
      return withAPIV0ErrorHandling(
        this.http.get<customFieldList[]>(`${this.actionUrl}${this.apiPath}`, getRequestOptions(data))
      );
    }

    getCFDetails(id,data): Observable<customFieldOptions[]> {
      return withAPIV0ErrorHandling(
        this.http.get<customFieldOptions[]>(`${this.actionUrl}${this.apiPath}${id}/customfield_options_list/`, getRequestOptions(data))
      );
    }

    createCF(data): Observable<customFieldDetails> {
      return withAPIV0ErrorHandling(
        this.http.post<customFieldDetails>(`${this.actionUrl}${this.apiPath}`, JSON.stringify(data))
      );
    }

    updateCFStatus(id, data) {
      return withAPIV0ErrorHandling(
        this.http.patch(`${this.actionUrl}${this.apiPath}${id}/update_custom_field_status/`,  JSON.stringify(data))
      );
    }

    deleteCF(id, data) {
      return withAPIV0ErrorHandling(
        this.http.delete(`${this.actionUrl}${this.apiPath}${id}/`, getRequestOptions(data))
      );
    }

    updateCF(id : number, data : customFieldDetails) : Observable<customFieldDetails> {
      return withAPIV0ErrorHandling(
        this.http.put<any>(`${this.actionUrl}${this.apiPath}${id}/`, JSON.stringify(data))
      );
    }

}
