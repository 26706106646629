import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'boxed-checkbox',
  templateUrl: './boxed-checkbox.component.html',
  styleUrls: ['boxed-checkbox.component.scss']
})
export class BoxedCheckboxComponent implements OnInit {
  @Input() checked = false;
  @Input() label : string;
  @Input() disabled = false;
  @Input() consumedLength = 0;
  @Input() maxlength = 4;
  @Input() showCounts = true;
  @Output() value: EventEmitter<boolean> = new EventEmitter();

  onChange() {
    if(!this.disabled) {
      if(this.checked) {
        this.consumedLength--;
      }
      else {
        this.consumedLength++;
      }
      this.checked = !this.checked;
      this.value.emit(this.checked);
      this.updateDisabledState();
    }
  }

  ngOnInit() {
    this.updateDisabledState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('consumedLength' in changes || 'checked' in changes) {
      this.updateDisabledState();
    }
  }

  updateDisabledState() {
    this.disabled = this.consumedLength >= this.maxlength && !this.checked;
  }

}