import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'precisionNumberFormat'
})
export class PrecisionNumberFormatPipe implements PipeTransform {

  transform(value: number): string {
    if (isNaN(Number(value))) {
      return null;
    }
    return Math.trunc(value) < 100 ? value.toFixed(2) : Math.trunc(value).toFixed(0);
  }

}
