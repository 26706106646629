import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MetricMappingCalculationService } from 'app/budget-object-details/services/metric-mapping-calculation.service';
import { MetricValueRecords } from 'app/budget-object-details/types/metric-value-records.interface';
import { MetricProgressTowardsTargetDO } from 'app/shared/services/backend/metric.service';
import { TableColumn, TableRow, TableRowName } from './metric-progress-table.type';
import { parseDateString } from '../../containers/campaign-details/date-operations';
import { Budget, BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { createMonthToQuarterMapping } from 'app/shared/utils/date.utils';

@Component({
  selector: 'metric-progress-table',
  templateUrl: './metric-progress-table.component.html',
  styleUrls: ['./metric-progress-table.component.scss']
})
export class MetricProgressTableComponent implements OnChanges {
  BudgetTimeframesType = BudgetTimeframesType;
  @Input() metricProgressData: MetricProgressTowardsTargetDO;
  @Input() metricValueRecords: MetricValueRecords<number>;
  @Input() metricName: string;
  @Input() outdatedData: boolean;
  @Input() displayDecimal: boolean;
  @Input() budget:  Budget;
  @ViewChild('scrollContainer', { read: ElementRef }) scrollContainer;

  public budgetStartDate: Date;
  public tableData: Partial<MetricProgressTowardsTargetDO> = {
    cumulative_value: {},
    monthly_value: {},
  };
  public columns: TableColumn[];
  public rows: TableRow[];
  public showLeftGradient = false;
  public showRightGradient = false;
  constructor(private metricCalculationService: MetricMappingCalculationService) { }

  public handleScroll() {
    if (!this.scrollContainer || !this.scrollContainer.nativeElement) {
      return;
    }
    const { scrollLeft, scrollWidth, clientWidth } = this.scrollContainer.nativeElement;
    this.showLeftGradient = scrollLeft > 0;
    this.showRightGradient = scrollLeft + clientWidth < scrollWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
    const valueRecordsChanged = this.metricValueRecords && !!changes.metricValueRecords;
    const progressDataChanged = this.metricProgressData && !!changes.metricProgressData;
    if (changes.budget && this.budget) {
      this.budgetStartDate = parseDateString(this.budget.budget_from);
    }
    if (valueRecordsChanged || progressDataChanged) {
      this.setTableData();

    } else if (this.metricName && changes.metricName || changes.displayDecimal || changes.outdatedData) {
      this.setRows();
    }
  }

  private setColumns = () => this.columns = Object.keys(this.tableData.cumulative_value)
    .map(dateString => ({ label: this.transformDateString(`${dateString}-01`), name: dateString }));

  private setColumnsClassNames = () => {
    const budgetStartDateKey = this.metricCalculationService.getDateObjectKey(this.budgetStartDate, false);
    const budgetStartDateIndex = this.columns.findIndex(column => column.name === budgetStartDateKey);
    this.columns = this.columns.map((column, i) => {
      const offset = budgetStartDateIndex / 3;
      const indexWithOffset = i + offset;
      const className = Math.floor(indexWithOffset / 3) % 2 === 0 ? '' : 'quarter-group';
      return { ...column, className }
    })
  }

  private setRows = () => this.rows = this.getFilteredRows();

  private setTableData() {
    const updatedCalculations = this.metricCalculationService.calcMonthlyAndCumulativeValues(this.metricValueRecords);
    let receivedCalculations = this.metricProgressData;
    if (this.budget && this.budget.type === BudgetTimeframesType.Quarter) {
      receivedCalculations = this.adaptQuarterlyValues(this.metricProgressData);
    }
    this.tableData = { ...receivedCalculations, ...updatedCalculations };
    this.setRows();
    this.setColumns();
    this.setColumnsClassNames();
  }

  private transformDateString(value: string): string {
    const date = parseDateString(value);
    const month = date.getMonth() + 1;
    if (!!month) {
      const shortMonthName = date.toLocaleString('en-US', { month: 'short' });
      const year = date.getFullYear().toString().substr(-2);
      return month === 1 || month === 12
        ? `${shortMonthName} ‘${year}`
        : shortMonthName;
    }
    return '';
  }

  private getRequiredRows(): TableRow[] {
    const numberFormat = this.displayDecimal ? '1.2-2' : '1.0-0';
    return [
      { name: TableRowName.CumulativeValue, label: `Cumulative ${this.metricName}`, numberFormat, outOfDate: false },
      { name: TableRowName.MonthlyValue, label: `Monthly ${this.metricName}`, numberFormat, outOfDate: false }
    ]
  }

  private getOptionalRows(): TableRow[] {
    let result = []
    if (this.budget && this.budget.type) {
      const optionsByBudgetType = {
        [BudgetTimeframesType.Year]: [],
        [BudgetTimeframesType.Quarter]: [
          { name: TableRowName.QuarterlyCPO, label: 'Quarterly CPO', numberFormat: '1.2-2', outOfDate: this.outdatedData },
          { name: TableRowName.QuarterlyROI, label: 'Quarterly ROI', numberFormat: '1.2-2', outOfDate: this.outdatedData }
        ],
        [BudgetTimeframesType.Month]: [
          { name: TableRowName.MonthlyCPO, label: 'Monthly CPO', numberFormat: '1.2-2', outOfDate: this.outdatedData },
          { name: TableRowName.MonthlyROI, label: 'Monthly ROI', numberFormat: '1.2-2', outOfDate: this.outdatedData }
        ]
      }

      result = optionsByBudgetType[this.budget.type].filter(
        row => this.tableData[row.name] && Object.keys(this.tableData[row.name]).some(
          dateKey => this.tableData[row.name][dateKey] > 0
        )
      )
    }
    return result;
  }

  private getFilteredRows(): TableRow[] {
    return [ ...this.getRequiredRows(), ...this.getOptionalRows()]
  }

  adaptQuarterlyValues(metricProgressData: MetricProgressTowardsTargetDO): MetricProgressTowardsTargetDO {
    if (this.budget?.type === BudgetTimeframesType.Quarter && metricProgressData) {
      const monthToQuarterMapping = createMonthToQuarterMapping(this.budgetStartDate);
      return {
        [TableRowName.CumulativeValue]: {},
        [TableRowName.MonthlyValue]: {},
        [TableRowName.QuarterlyCPO]: Object.keys(metricProgressData.quarterly_cpo).reduce((res, quarterKey) => {
          res[monthToQuarterMapping[quarterKey]] = metricProgressData.quarterly_cpo[quarterKey]
          return res;
        }, {}),
        [TableRowName.QuarterlyROI]: Object.keys(metricProgressData.quarterly_roi).reduce((res, quarterKey) => {
          res[monthToQuarterMapping[quarterKey]] = metricProgressData.quarterly_roi[quarterKey]
          return res;
        }, {})
      }
    }
  }
}
