import { Component, Input, EventEmitter, Output } from '@angular/core';
import { DrawerTabItem, ObjectDetailsTabControl } from '../../types/object-details-tab-control-type.interface';
import { BudgetObjectMetricsService } from 'app/budget-object-details/services/budget-object-metrics.service';

@Component({
  selector: 'drawer-tab-switch',
  templateUrl: './drawer-tab-switch.component.html',
  styleUrls: ['./drawer-tab-switch.component.scss']
})
export class DrawerTabSwitchComponent {

  private isKeyMetric: boolean;

  constructor(private readonly budgetObjectMetricsService: BudgetObjectMetricsService) {
    this.budgetObjectMetricsService.isKeyMetricSubject.subscribe(isKeyMetricValue => {
      this.isKeyMetric = isKeyMetricValue;
    })
  }

  @Input() tabs: DrawerTabItem[];
  @Input() activeTabId: ObjectDetailsTabControl;
  @Input() keyMetricSelection = true;

  @Output() activeTabChanged = new EventEmitter<ObjectDetailsTabControl>();

  protected setActiveId(tabId: ObjectDetailsTabControl): void {
    if (this.activeTabId !== tabId) {
      this.activeTabChanged.emit(tabId);
    }
  }

}
