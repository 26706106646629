export function decreaseMaxValue(obj: Record<string, number>, diff, minHeight) {
  let maxValue = -Infinity;
  let maxKey = null;
  const keysToIgnore = ['budget'];

  Object.entries(obj)
    .filter(([key]) => !keysToIgnore.includes(key))
    .forEach(([key, value]) => {
      if (value > maxValue) {
        maxValue = value;
        maxKey = key;
      }
    });

  if (maxValue - diff < minHeight) {
    // all values are too small (this case possible for filtered data)
    return;
  }
  obj[maxKey] = obj[maxKey] - diff;
}
