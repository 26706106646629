import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MetricListItem, MetricIntegrationName } from '../../types/metric-integration';

export interface RemapCampaignsContext {
  isMultiple: boolean;
  integrationType: MetricIntegrationName;
  campaignExternalName: string;
  campaignCurrentName: string;
  campaignTargetName: string;
  metrics: MetricListItem[];
}

@Component({
  selector: 'app-remap-campaigns-dialog',
  templateUrl: './remap-campaigns-dialog.component.html',
  styleUrls: ['./remap-campaigns-dialog.component.scss']
})
export class RemapCampaignsDialogComponent {
  context: RemapCampaignsContext;

  constructor(
    public dialogRef: MatDialogRef<RemapCampaignsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) context: RemapCampaignsContext
  ) {
    this.context = context;
  }
}
