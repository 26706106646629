<div class="app-page">
  <page-header [pageName]="integrationName + ' Campaign Mapping'">
    <ng-container [ngSwitch]="integrationSource" slot="icon">
      <icon-salesforce class="header-icon" *ngSwitchCase="metricIntegrationName.Salesforce" size="30px"></icon-salesforce>
      <fa-icon class="header-icon" *ngSwitchCase="metricIntegrationName.Hubspot" [icon]="['fab', 'hubspot']"></fa-icon>
    </ng-container>
  </page-header>
  <section class="page-content">
    <div class="page-container">
      <div class="d-flex jc-between mb-2">
        <div class="view-filter">
          <span class="filter-label">View</span>
          <dropdown-menu
            [disabled]="settingInProgress"
            [items]="viewFilterItems"
            [selectedValue]="viewMode"
            (onSelectValue)="changeViewMode($event)"
          ></dropdown-menu>
        </div>

        <search-control
          [disabled]="!campaignsTree || (!filterText && !campaignsTree?.length) || settingInProgress"
          [placeholder]="'Search campaigns'"
          (textChanged)="changeFilterText($event)"
        ></search-control>
      </div>
      <metric-mapping-management
        [readOnly]="settingInProgress"
        [integration]="{ name: integrationSource, label: integrationName }"
        [budgetMappingMode]="true"
        [locationItems]="locationItems"
        [locationNamesMap]="locationNamesMap"
        [integrationCampaigns]="campaignsTree"
        [placeholderText]="!campaignsTree ? 'loading data' : ('no ' + integrationName +' campaigns')"
        (updateMappingPair)="onUpdateCampaignsMapping($event)"
      >
      </metric-mapping-management>
    </div>
  </section>
</div>


