import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { BudgetAmountsSummaryData } from 'app/dashboard/budget-amounts-summary/budget-amounts-summary.component';
import { BudgetAmountsSummaryService } from 'app/shared/services/budget-amounts-summary.service';
import { BudgetObjectTotals } from 'app/shared/types/budget-object-totals.interface';
import { prepareBudgetObjectTotals } from 'app/shared/utils/budget.utils';
import { Configuration } from 'app/app.constants';
import { BudgetObjectDetailsManager } from '../../services/budget-object-details-manager.service';

@Component({
  selector: 'details-status-bar',
  templateUrl: './details-status-bar.component.html',
  styleUrls: ['./details-status-bar.component.scss']
})
export class DetailsStatusBarComponent implements OnInit, OnChanges {
  @Input() objectId: number;
  @Input() graphData: BudgetObjectTotals;
  @Input() objectDisplayType: string;
  @Input() objectName: string;
  @Input() currency = '$';
  @Input() isObjectClosed: boolean;
  @Input() isGoal = false;

  selectedStatusValues = [];
  allocationAmountTitle = '';
  summaryTooltipData: BudgetAmountsSummaryData;
  totalSpend: number;
  excludedGraphValues = [
    this.configuration.statusFields.overBudget,
    this.configuration.statusFields.overSpend
  ];
  public preparedGraphData: Partial<BudgetObjectTotals>;

  constructor(
    private readonly amountsSummaryService: BudgetAmountsSummaryService,
    private readonly configuration: Configuration,
  ) {}

  ngOnInit(): void {
    this.allocationAmountTitle = this.getAllocationAmountTitle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objectType) {
      this.allocationAmountTitle = this.getAllocationAmountTitle();
    }

    if (this.graphData && changes.graphData) {
      this.preparedGraphData = prepareBudgetObjectTotals(this.graphData, this.isObjectClosed);
      this.summaryTooltipData = this.getTooltipData();
      this.totalSpend = this.getTotalSpendValue();
    }
  }

  private getTotalSpendValue() {
    let totalSpend = 0;
    if (this.graphData) {
      const { expenses_by_timeframes: expenses, closed, committed, planned } = this.graphData;
      if (expenses) {
        totalSpend = BudgetObjectDetailsManager.getTotalSpend(this.graphData);
      } else {
        // TODO: As a temp. fix for PUP-1979, approved by Bridges.
        // We should implement 'expenses_by_timeframes' for v2/Goals as well later
        totalSpend = closed + committed + planned;
      }
    }
    return totalSpend;
  }

  private getTooltipData(): BudgetAmountsSummaryData {
    return this.amountsSummaryService.createTooltipData({
      item: this.preparedGraphData,
      title: this.objectName,
      currency: this.currency
    });
  }

  private getAllocationAmountTitle() {
    if (this.isGoal) {
      return 'Sum of Allocation';
    }

    return `Total ${this.objectDisplayType} Allocation`;
  }
}
