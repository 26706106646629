import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { UtilityService } from '@shared/services/utility.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { ExpensesService } from '@shared/services/backend/expenses.service';
import { SpendingService } from './spending.service';
import { ExpensePageSortByService } from './expense-page-sort-by.service';
import { SpendingLocalFiltersService } from './spending-local-filters.service';
import { ExpensePageSelectionService } from './expense-page-selection.service';
import { ExpenseActionsService } from './expense-actions.service';
import { FilterManagementService } from '../../header-navigation/components/filters/filter-services/filter-management.service';
import { SpendingMiniDashSummaryService } from './spending-mini-dash-summary.service';
import { BudgetObjectDetailsManager } from '../../budget-object-details/services/budget-object-details-manager.service';
import { Configuration } from '../../app.constants';
import { ExpensesQueryService } from './expenses-query.service';
import { InvoiceTableDataService } from '@spending/services/invoice-table-data.service';
import { SpendingPageBaseService } from '@spending/services/spending-page-base-service';

@Injectable()
export class InvoicePageService extends SpendingPageBaseService implements OnDestroy {

  constructor(
    public readonly utilityService: UtilityService,
    private readonly budgetDataService: BudgetDataService,
    public readonly expensesService: ExpensesService,
    public readonly spendingService: SpendingService,
    private readonly expenseSortByService: ExpensePageSortByService,
    private readonly spendingLocalFiltersService: SpendingLocalFiltersService,
    private readonly expensePageSelectionService: ExpensePageSelectionService,
    private readonly expenseActionsService: ExpenseActionsService,
    private readonly filterManagementService: FilterManagementService,
    private readonly invoiceTableDataService: InvoiceTableDataService,
    private readonly spendingMiniDashSummaryService: SpendingMiniDashSummaryService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly configuration: Configuration,
    private readonly expensesQueryService: ExpensesQueryService,
    private cdr: ChangeDetectorRef,
  ) {
    super(
      budgetDataService,
      expensesService,
      spendingService,
      spendingLocalFiltersService,
      spendingMiniDashSummaryService,
      expensesQueryService,
      cdr,
      invoiceTableDataService,
      expensePageSelectionService,
      utilityService
    );
  }

  ngOnDestroy(): void {
    this.invoiceTableDataService.createRows(null);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
