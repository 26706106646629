<div class="timeframe-picker">
  <div class="title">Choose a different timeframe</div>
  <div class="tf-list">
    <div
      *ngFor="let tf of timeframes"
      class="tf-item"
      [class.is-selected]="innerSelectedTimeframe === tf.id"
      [class.is-disabled]="overdueTimeframes.includes(tf.id)"
      (click)="select($event, tf)"
    >
      {{ tf.name | slice:0:3 }}
    </div>
  </div>
</div>
