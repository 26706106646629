<div class="scroll-container">
  <div class="spending-bars" *ngIf="timelineItems" [ngClass]="{'mb-1 manage-table-mode': isManageTableTimeline}">
    <spending-bar
      [ngClass]="{'highlighted': !selectedTimeframeIds?.length || selectedTimeframeIds.includes(item.id)}"
      *ngFor="let item of timelineItems; let i = index"
      [spendingDataItem]="item"
      [summaryTooltipData]="summaryTooltipData[i]"
      [totalColumns]="timelineItems.length"
      [positionInChart]="i + 1"
      [cegFlagEnabled]="cegFlagEnabled"
    ></spending-bar>
  </div>
</div>

<div class="bottom-panel" *ngIf="!isManageTableTimeline else manageTableTimelineLegend">
  Total Expenses
</div>

<ng-template #manageTableTimelineLegend>
  <statuses-legend
  [cegFlagEnabled]="cegFlagEnabled"
  [isManageTableTimeline]="isManageTableTimeline"></statuses-legend>
</ng-template>