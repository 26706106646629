import { ComponentFactoryResolver, Directive, HostListener, Inject, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DynamicIconComponent } from '../components/dynamic-icon/dynamic-icon.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Directive({
  selector: '[dragImage]'
})
export class DragImageDirective {
  @Input() private readonly icon: IconProp;
  @Input() private readonly imageName: string;
  @Input() private readonly selectedRecordsCount: number;
  @Input() private readonly nodeElement: HTMLElement;
  @Input() private readonly groupIcon: IconProp;

  @HostListener('dragstart', ['$event']) onDragStart($event) {
    this.nodeElement ? this.onCreateElementCopy($event, this.nodeElement) : this.onCreateDragImage($event, this.imageName)
  }

  @HostListener('dragend') onDragEnd() {
    this.onRemoveDragImage();
  }

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private resolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
  ) { }

  private onCreateDragImage(event: DragEvent, elementName: string): void {
    const dragImage = this.renderer2.createElement('div');
    const title = this.selectedRecordsCount > 1 ? `Moving ${this.selectedRecordsCount} items` : elementName;
    const imageText = this.renderer2.createText(title);
    const iconComponent = this.selectedRecordsCount > 1 ? this.createIconComponent(this.groupIcon) : this.createIconComponent(this.icon);

    this.renderer2.appendChild(dragImage, iconComponent);
    this.renderer2.setAttribute(dragImage, 'id', 'drag-image');
    this.renderer2.appendChild(dragImage, imageText);
    this.renderer2.setStyle(dragImage, 'position', 'absolute');
    this.renderer2.setStyle(dragImage, 'top', '-9999px');
    this.renderer2.addClass(dragImage, 'container');
    this.renderer2.appendChild(this.document.body, dragImage);
    event.dataTransfer.setDragImage(dragImage, 10, 10);
    event.dataTransfer.effectAllowed = 'link';
  }

  private onCreateElementCopy(event: DragEvent, element: HTMLElement) {
    const clonedElement: HTMLElement = element.cloneNode(true) as HTMLElement;
    const imagePosition = { x: 0, y: 0 };
    const handleElement = element.getElementsByClassName('drag-handle')[0] as HTMLElement;
    if ( handleElement ) {
      const { offsetHeight, offsetWidth } = handleElement;
      imagePosition.x = offsetWidth;
      imagePosition.y = offsetHeight;
    }
    this.renderer2.setAttribute(clonedElement, 'id', 'drag-image');
    this.renderer2.setStyle(clonedElement, 'position', 'absolute');
    this.renderer2.setStyle(clonedElement, 'width', `${element.offsetWidth}px`);
    this.renderer2.setStyle(clonedElement, 'height', `${element.offsetHeight}px`);
    this.renderer2.setStyle(clonedElement, 'top', '-9999px');
    this.renderer2.appendChild(this.document.body, clonedElement);
    event.dataTransfer.setDragImage(clonedElement, ( element.offsetWidth * 2 ) - ( imagePosition.x * 2 ), imagePosition.y );
    event.dataTransfer.effectAllowed = 'link';
  }

  private onRemoveDragImage(): void {
    const dragImage = this.document.getElementById('drag-image');
    if (dragImage.parentNode) {
      dragImage.parentNode.removeChild(dragImage);
    }
  }

  private createIconComponent(icon?: IconProp): HTMLElement {
    const componentFactory = this.resolver.resolveComponentFactory(DynamicIconComponent);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.icon = icon;
    return componentRef.location.nativeElement;
  }

}
