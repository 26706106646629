import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpXhrBackend } from '@angular/common/http';
import { observeFromHttpEvents } from '@common-lib/lib/http/utils';
import { HttpEventsObserveType } from '@common-lib/lib/http/types/http-events-observe-type.enum';
import { AuthInterceptor } from './http-auth.interceptor';
import { API_V0_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';
import { Observable } from 'rxjs';

export interface UpdateProfileResponseData {
  email: string;
  file: string;
  first_name: string;
  last_name: string;
  full_name: string;
}

export type UpdateProfileResponse = ApiV0Response<UpdateProfileResponseData>;

export interface UpdateProfilePayload {
  company: string;
  first_name: string;
  last_name: string;
  file: File;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly actionUrl = inject(API_V0_URL);
  private readonly backend = inject(HttpXhrBackend);
  private readonly authInterceptor = inject(AuthInterceptor);

  updateUserProfile(data: UpdateProfilePayload, userId: number): Observable<UpdateProfileResponse> {
    const fd = new FormData();
    fd.append('company', data.company);
    fd.append('first_name', data.first_name);
    fd.append('last_name', data.last_name);
    if (data.file?.name != null) {
      fd.append('image', data.file);
    } else {
      fd.append('image', '');
    }
    return this.rawPutRequest(this.actionUrl + 'users/' + userId + '/profile/',  fd);
  }

  private rawPutRequest(url, body) {
    return this.rawHttpRequest('PUT', url, body);
  }

  private rawHttpRequest(method, url, body?) {
    const req = new HttpRequest(method, url, body);
    return observeFromHttpEvents(
      this.authInterceptor.intercept(req, this.backend),
      HttpEventsObserveType.Body
    );
  }
}
