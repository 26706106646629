<div class="shared-cost-rules-container">
  <page-header pageName="Shared Cost Rules" [isAdminPage]="true">
    <icon-shared class="custom-icon header-icon" slot="icon" [size]="30"></icon-shared>
  </page-header>
  <div class="shared-cost-rules-management">
    <shared-cost-rule-tips></shared-cost-rule-tips>

    <div class="budget-filter-container">
      <div class="add-rule-btn" [ngClass]="{'disabled': filteredBudgetId === '*'  }" (click)="handleAddNewRule()">
      <icon-add-objects></icon-add-objects>
      <div>Add New Shared Cost Rule</div>
    </div>

    <mat-form-field  *ngIf="budgetListFilterOptions.length > 0"  appearance="outline" class="p-form-field-outline budget-dropdown">
      <mat-label>Budget</mat-label>
      <mat-select
        disableOptionCentering
        placeholder="Budget"
        [value]="filteredBudgetId"
        (selectionChange)="updateSCRFilter($event.value)"
      >
        <mat-option *ngFor="let item of budgetListFilterOptions" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    
    <hr class="scr-horizontal-separator">

    <div class="rules-list">
      <div class="rule-container" *ngFor="let rule of filteredSCRs;">
        <shared-cost-rule
          [rule]="rule"
          [budgetList]="budgetList"
          [segmentList]="segmentListPerBudget[rule.budgetId]"
          (updateBudget)="handleUpdateRuleBudget(rule, $event)"
          (updateSegment)="handleUpdateRuleSegment(rule, $event)"
          (updateSegmentCost)="handleUpdateRuleSegmentCost(rule, $event)"
          (updateRuleName)="handleUpdateRuleName(rule, $event)"
          (deleteRule)="handleDeleteRule(rule)"
          (updateStatus)="handleUpdateRuleActivityStatus(rule, $event)"
          (addNewSegment)="handleAddNewSegment(rule)"
          (deleteSegment)="handleDeleteSegment(rule, $event)"
        ></shared-cost-rule>
      </div>
    </div>
  </div>
</div>
