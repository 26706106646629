export class LocalStorageService {
  public static getFromStorage<T>(storageId: string): T {
    const storageElement = localStorage.getItem(storageId);

    if (storageElement) {
      try {
        return JSON.parse(storageElement);
      } catch (e) {
        console.warn(`Failed to parse from storage id - ${storageId}`);
      }
    }
  }

  public static addToStorage<T>(storageId: string, storageObject: T): void {
    localStorage.setItem(storageId, JSON.stringify(storageObject));
  }

  public static removeFromStorage(storageId: string): void {
    localStorage.removeItem(storageId);
  }
}
