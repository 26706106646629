import { Component, Input, OnDestroy } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { finalize, takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'plc-animated-number',
  templateUrl: './animated-number.component.html',
  styleUrls: ['./animated-number.component.scss']
})
export class AnimatedNumberComponent implements OnDestroy {
  displayValue: number;
  prevValue: number;
  destroy$ = new Subject<void>();

  @Input() numberFormat: string;

  @Input() set value(num: number) {
    if (this.prevValue === undefined || num === null) {
      this.prevValue = this.displayValue = num;
    } else {
      this.changeValueSmooth(this.prevValue, num);
    }
  }

  changeValueSmooth(from, to) {
    const delta = this.roundNumber(to - from);
    const negative = delta < 0;
    const deltaAbs = negative ? -1 * delta : delta;
    const framesCountDef = 10;
    const delayDef = 30;

    let stepAbs: number;
    if (deltaAbs < 1) {
      stepAbs = 0.1;
    } else if (deltaAbs < 10) {
      stepAbs = 1;
    } else {
      stepAbs = Math.round(deltaAbs / framesCountDef);
    }

    const nextStepValue = (): number => {
      return this.roundNumber(this.displayValue + step);
    }

    const shouldAnimate = (): boolean => {
      return negative ? nextStepValue() > to : nextStepValue() < to;
    }

    const framesCountActual = deltaAbs / stepAbs;
    const delay = delayDef * framesCountDef / framesCountActual;
    const step = negative ? -1 * stepAbs : stepAbs;

    interval(delay).pipe(
      takeWhile(() => shouldAnimate()),
      takeUntil(this.destroy$),
      finalize(() => {
        this.displayValue = to;
        this.prevValue = this.displayValue;
      }),
    ).subscribe(() => this.displayValue = nextStepValue())
  }

  roundNumber(rawNum: number): number {
    return Math.round(rawNum * 100) / 100;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
