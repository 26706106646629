<svg
  [attr.width]="width || size"
  [attr.height]="height || size"
  [attr.fill]="color"
  [style.display]="'block'"
  viewBox="0 0 19 16"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M17.6094 9.39844L11.3594 15.6484C11.125 15.8828 10.8125 16 10.5 16C10.1484 16 9.83594 15.8828 9.60156 15.6484C9.09375 15.1797 9.09375 14.3594 9.60156 13.8906L13.7031 9.75H1.75C1.04688 9.75 0.5 9.20312 0.5 8.5C0.5 7.83594 1.04688 7.25 1.75 7.25H13.7031L9.60156 3.14844C9.09375 2.67969 9.09375 1.85938 9.60156 1.39062C10.0703 0.882812 10.8906 0.882812 11.3594 1.39062L17.6094 7.64062C18.1172 8.10938 18.1172 8.92969 17.6094 9.39844Z"/>
</svg>
