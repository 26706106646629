import { MatDialogConfig } from '@angular/material/dialog';

export const dialogConfig: MatDialogConfig = {
  id: 'malware-detected',
  height: 'initial',
  width: '576px',
  disableClose: true,
  autoFocus: false,
  restoreFocus: false,
  panelClass: 'extended-confirmation-dialog',
  data: null
};
