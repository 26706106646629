import { PlanObjectsBuilder } from './plan-objects-builder';
import { PlanCampaignDO } from 'app/shared/types/campaign.interface';
import { PlanCampaign } from '../dashboard.types';
import { Configuration } from 'app/app.constants';

export class PlanCampaignsBuilder extends PlanObjectsBuilder<PlanCampaignDO> {
  constructor(sourceDataObjects: PlanCampaignDO[], configuration: Configuration) {
    super(sourceDataObjects, configuration);
  }

  getResultPlanObjects(): PlanCampaign[] {
    this.setObjectType(this.configuration.OBJECT_TYPES.campaign);
    this.prepareBudgetObjectTotals();
    this.addObjectTransformer(
      planCampaign => this.prepareChildren(planCampaign)
    );
    return super.getResultPlanObjects();
  }

  private prepareChildren(planCampaign: PlanCampaign): PlanCampaign {
    planCampaign.children = [
      ...this.provideWithObjectType(planCampaign.child_campaign_list || [], this.configuration.OBJECT_TYPES.campaign)
        .sort(PlanObjectsBuilder.compareObjectsByName),
      ...this.provideWithObjectType(planCampaign.program_list || [], this.configuration.OBJECT_TYPES.program)
        .sort(PlanObjectsBuilder.compareObjectsByName)
    ];

    return planCampaign;
  }
}
