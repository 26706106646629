import {
  ManageCegDataMode,
  ManageCegTableData,
  ManageCegTableRow,
  ManageCegViewMode,
} from '../types/manage-ceg-page.types';
import { Goal } from '@shared/types/goal.interface';
import { LightProgram } from '@shared/types/program.interface';
import { LightCampaign } from '@shared/types/campaign.interface';
import { ParentChildMap } from '@shared/types/parent-child-map.type';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SegmentGroup } from '@shared/types/segment-group.interface';
import { ListTreeItem } from '@shared/components/full-hierarchy-tree/full-hierarchy-tree.component';
import { ManageTableHelpers } from '../../manage-table/services/manage-table-helpers';
import { ManageCEGTableRowFactory } from '@manage-ceg/services/manage-ceg-table-row-factory';
import { ManageCEGTableDataBuilderInputs, ManageCEGTableDataBuildResult } from '@manage-ceg/types/manage-ceg-table-data-builder.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { getPseudoObjectId } from '@shared/utils/common.utils';

export class ManageCEGTableDataBuilder {
  private parentChildMap: ParentChildMap<ManageCegTableRow> = {};
  private data: ManageCegTableData = [];
  private segmentGroupsData: ManageCegTableData = [];
  private segmentsData: ManageCegTableData = [];
  private goalsData: ManageCegTableData = [];
  private campaignsData: ManageCegTableData = [];
  private expGroupsData: ManageCegTableData = [];
  private dataInputs: ManageCEGTableDataBuilderInputs;
  private flatDataMap: Record<string, ManageCegTableRow> = {};
  private allObjectIdsByType: Record<string, Record<number | string, number>>;
  private currentObjectIdsByType: Record<string, Record<number | string, number>>;

  private static getParentSubIdForObject<T extends LightProgram | LightCampaign>(
    obj: T,
    campaignId: number,
    customSegmentId?: number
  ): string {
    return campaignId ? getPseudoObjectId(campaignId, customSegmentId || obj.budgetSegmentId) : null;
  }

  private static hasItemsForDisplay(tableRow: ManageCegTableRow): boolean {
    return !tableRow.isFilteredOut || tableRow.children.some(children => ManageCEGTableDataBuilder.hasItemsForDisplay(children));
  }

  public objectExists(rowType: ManageTableRowType, objectId: number | string, segmentId?: number): boolean {
    const objTypeRecord = objectId && this.allObjectIdsByType[rowType];
    return segmentId ? objTypeRecord?.[objectId] === segmentId : Boolean(objTypeRecord?.hasOwnProperty(objectId));
  }

  private getChildItems(parentType: string, parentId: number | string, childType: string): ManageCegTableRow[] {
    return this.parentChildMap[parentType]?.[parentId]?.[childType] || [];
  }

  private accessChildList(parentType: string, parentId: number | string, childType: string): ManageCegTableRow[] {
    if (!this.parentChildMap[parentType]?.[parentId]) {
      this.parentChildMap[parentType][parentId] = {
        [ManageTableRowType.Segment]: [],
        [ManageTableRowType.Campaign]: [],
        [ManageTableRowType.ExpenseGroup]: []
      };
    }

    return this.parentChildMap[parentType][parentId][childType];
  }

  private addChildItem(
    parent: { objectType: ManageTableRowType; id: number | string },
    child: { objectType: ManageTableRowType; item: ManageCegTableRow }
  ): void {
    const childList = this.accessChildList(parent.objectType, parent.id, child.objectType);
    if (!Array.isArray(childList)) {
      console.warn('Failed to add child item', child);
      return;
    }

    childList.push(child.item);
  }

  private addFlatDataRow(row: ManageCegTableRow): void {
    this.flatDataMap[row.id] = row;
  }

  private isRowFilteredOut(row: ManageCegTableRow): boolean {
    const alwaysShownTypes = [ManageTableRowType.SegmentGroup, ManageTableRowType.Segment];
    return this.dataInputs.isFilterMode &&
      !alwaysShownTypes.includes(row.type) &&
      this.currentObjectIdsByType[row.type] &&
      !(row.itemId in this.currentObjectIdsByType[row.type]);
  }

  private processObjects<TObjectType>(
    allObjects: TObjectType[],
    rowCreator: (
      obj: TObjectType,
      viewMode?: ManageCegViewMode,
      objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean
    ) => ManageCegTableRow,
    rowAssigner: (obj: TObjectType, row: ManageCegTableRow) => void,
    viewMode?: ManageCegViewMode
  ): void {
    for (const obj of allObjects) {
      const row = rowCreator(obj, viewMode, this.objectExists.bind(this));

      if (viewMode === ManageCegViewMode.Segments) {
        const composedIdData = row.parentId?.split('_');
        const parentObject =
          (composedIdData?.[0] === ManageTableRowType.Campaign.toLowerCase() && composedIdData.length === 2) ?
            this.dataInputs.campaigns.find(camp => camp.id === +composedIdData[1]) :
            null;

        if (parentObject && !parentObject?.budgetSegmentId) {
          row.hierarchyInfo = [
            {
              id: parentObject.objectId,
              name: parentObject.name,
              type: ManageTableRowType.Campaign,
              highlighted: true,
            },
            {
              id: row.objectId,
              name: row.name,
              type: row.type,
              highlighted: false,
            },
          ]
        }
      }

      row.isFilteredOut = this.isRowFilteredOut(row);
      rowAssigner(obj, row);
    }
  }

  private tryToAssignSegmentedObjectRow(
    row: ManageCegTableRow,
    segmentId: number,
    parentType: ManageTableRowType,
    parentId: number | string,
    parentSubObjectId: string
  ): boolean {
    if (this.objectExists(parentType, parentId, segmentId)) {
      this.addChildItem(
        { objectType: parentType, id: parentId },
        { objectType: row.type, item: row }
      );
      return true;
    }

    if (this.objectExists(parentType, parentSubObjectId, segmentId)) {
      this.addChildItem(
        { objectType: parentType, id: parentSubObjectId },
        { objectType: row.type, item: row }
      );
      return true;
    }

    return false;
  }

  private assignExpenseGroupRowInBySegmentMode(expGroup: LightProgram, expGroupRow: ManageCegTableRow): void {
    const parentSubObjectId = ManageCEGTableDataBuilder.getParentSubIdForObject(expGroup, expGroup.campaignId);

    const assignToSegment = () => {
      if (this.objectExists(ManageTableRowType.Segment, expGroup.budgetSegmentId)) {
        this.addChildItem(
          { objectType: ManageTableRowType.Segment, id: expGroup.budgetSegmentId },
          { objectType: ManageTableRowType.ExpenseGroup, item: expGroupRow }
        );
      } else {
        console.warn(
          `[ManageTableFastDataBuilder]: assignExpenseGroupRow(): Expense group with id ${expGroup.id} does not have a segment`
        );
      }
    };

    if (this.tryToAssignSegmentedObjectRow(
      expGroupRow,
      expGroup.budgetSegmentId,
      ManageTableRowType.Campaign,
      expGroup.campaignId,
      parentSubObjectId
    )) {
      return;
    }

    let campaign: LightCampaign;
    if (this.objectExists(ManageTableRowType.Campaign, expGroup.campaignId)) {
      campaign = this.dataInputs.campaigns.find(c => c.id === expGroup.campaignId);
    } else if (this.objectExists(ManageTableRowType.Campaign, parentSubObjectId)) {
      campaign = this.dataInputs.campaigns.find(c => c.objectId === parentSubObjectId);
    }

    if (campaign) {
      const parentCampaignSubObjectId =
        ManageCEGTableDataBuilder.getParentSubIdForObject(campaign, campaign.parentCampaign, expGroup.budgetSegmentId);

      if (this.tryToAssignSegmentedObjectRow(
        expGroupRow,
        expGroup.budgetSegmentId,
        ManageTableRowType.Campaign,
        campaign.parentCampaign,
        parentCampaignSubObjectId
      )) {
        return;
      }
      assignToSegment();
    } else {
      assignToSegment();
    }
  }

  private assignExpenseGroupRow(expGroup: LightProgram, expGroupRow: ManageCegTableRow): void {
    if (this.dataInputs.viewMode === ManageCegViewMode.Segments) {
      this.assignExpenseGroupRowInBySegmentMode(expGroup, expGroupRow);
    } else if (this.objectExists(ManageTableRowType.Campaign, expGroup.campaignId)) {
      this.addChildItem(
        { objectType: ManageTableRowType.Campaign, id: expGroup.campaignId },
        { objectType: ManageTableRowType.ExpenseGroup, item: expGroupRow }
      );
    } else if (this.dataInputs.viewMode === ManageCegViewMode.Goals) {
      if (this.objectExists(ManageTableRowType.Goal, expGroup.goalId)) {
        this.addChildItem(
          { objectType: ManageTableRowType.Goal, id: expGroup.goalId },
          { objectType: ManageTableRowType.ExpenseGroup, item: expGroupRow }
        );
      } else {
        this.expGroupsData.push(expGroupRow);
      }
    } else {
      this.expGroupsData.push(expGroupRow);
    }

    this.addFlatDataRow(expGroupRow);
  }

  private processExpGroups(): void {
    this.processObjects(
      this.dataInputs.expGroups,
      ManageCEGTableRowFactory.createExpenseGroupRow,
      this.assignExpenseGroupRow.bind(this),
      this.dataInputs.viewMode
    );
  }

  private assignCampaignRowInBySegmentMode(campaign: LightCampaign, campaignRow: ManageCegTableRow, isChildCampaign: boolean): void {
    const assignToSegment = () => {
      if (this.objectExists(ManageTableRowType.Segment, campaign.budgetSegmentId)) {
        this.addChildItem(
          { objectType: ManageTableRowType.Segment, id: campaign.budgetSegmentId },
          { objectType: ManageTableRowType.Campaign, item: campaignRow }
        );
      } else {
        console.warn(
          `[ManageTableFastDataBuilder]: assignExpenseCampaignRowInBySegmentMode(): Campaign with id ${campaign.objectId} does not have a segment`
        );
      }
    };

    if (!isChildCampaign) {
      assignToSegment();
      return;
    }

    const parentSubObjectId = ManageCEGTableDataBuilder.getParentSubIdForObject(campaign, campaign.parentCampaign);

    if (this.tryToAssignSegmentedObjectRow(
      campaignRow,
      campaign.budgetSegmentId,
      ManageTableRowType.Campaign,
      campaign.parentCampaign,
      parentSubObjectId
    )) {
      return;
    }

    assignToSegment();
  }

  private assignCampaignRow(campaign: LightCampaign, campaignRow: ManageCegTableRow): void {
    const { viewMode } = this.dataInputs;
    const isChildCampaign = campaign.parentCampaign != null;

    const childCampaigns =
      isChildCampaign ?
        [] :
        this.getChildItems(ManageTableRowType.Campaign, campaign.objectId, ManageTableRowType.Campaign);

    const childPrograms = this.getChildItems(ManageTableRowType.Campaign, campaign.objectId, ManageTableRowType.ExpenseGroup);
    campaignRow.children = [ ...childCampaigns, ...childPrograms ];
    campaignRow.children.forEach(childRow => childRow.parentId = campaignRow.id);
    campaignRow.shownChildren =
      this.dataInputs.isFilterMode ? campaignRow.children.filter(row => !row.isFilteredOut) : campaignRow.children;

    const parentSubObjectId =
      isChildCampaign ?
        ManageCEGTableDataBuilder.getParentSubIdForObject(campaign, campaign.parentCampaign) :
        null;

    if (viewMode === ManageCegViewMode.Segments) {
      this.assignCampaignRowInBySegmentMode(campaign, campaignRow, isChildCampaign);
    } else if (isChildCampaign && this.objectExists(ManageTableRowType.Campaign, campaign.parentCampaign)) {
      this.addChildItem(
        { objectType: ManageTableRowType.Campaign, id: campaign.parentCampaign },
        { objectType: ManageTableRowType.Campaign, item: campaignRow }
      );
    } else if (isChildCampaign && this.objectExists(ManageTableRowType.Campaign, parentSubObjectId)) {
      this.addChildItem(
        { objectType: ManageTableRowType.Campaign, id: parentSubObjectId },
        { objectType: ManageTableRowType.Campaign, item: campaignRow }
      );
    } else if (!isChildCampaign && viewMode === ManageCegViewMode.Goals) {
      if (this.objectExists(ManageTableRowType.Goal, campaign.goalId)) {
        this.addChildItem(
          { objectType: ManageTableRowType.Goal, id: campaign.goalId },
          { objectType: ManageTableRowType.Campaign, item: campaignRow }
        );
      } else {
        this.campaignsData.push(campaignRow);
      }
    } else {
      this.campaignsData.push(campaignRow);
    }

    this.addFlatDataRow(campaignRow);
  }

  private processCampaigns(): void {
    const rowCreator =
      (
        campaign: LightCampaign,
        viewMode?: ManageCegViewMode,
        objectExists?: (rowType: ManageTableRowType, objectId: number | string) => boolean
      ) => ManageCEGTableRowFactory.createCampaignRow(
        campaign,
        viewMode,
        objectExists,
        ManageTableHelpers.isSegmentlessObject
      );

    this.processObjects(
      this.getChildCampaigns(),
      rowCreator,
      this.assignCampaignRow.bind(this),
      this.dataInputs.viewMode
    );

    this.processObjects(
      this.getRegularCampaigns(),
      rowCreator,
      this.assignCampaignRow.bind(this),
      this.dataInputs.viewMode
    );
  }

  private getRegularCampaigns(): LightCampaign[] {
    return this.dataInputs.campaigns.filter(c => !c.parentCampaign);
  }

  private getChildCampaigns(): LightCampaign[] {
    return this.dataInputs.campaigns.filter(c => c.parentCampaign);
  }

  private assignGoalRow(goal: Goal, goalRow: ManageCegTableRow): void {
    goalRow.children = [
      ...this.getChildItems(ManageTableRowType.Goal, goal.id, ManageTableRowType.Campaign),
      ...this.getChildItems(ManageTableRowType.Goal, goal.id, ManageTableRowType.ExpenseGroup)
    ];
    goalRow.children.forEach(childRow => childRow.parentId = goalRow.id);
    goalRow.shownChildren =
      this.dataInputs.isFilterMode ? goalRow.children.filter(ManageCEGTableDataBuilder.hasItemsForDisplay) : goalRow.children;

    if (!this.dataInputs.isFilterMode || goalRow.shownChildren.length) {
      this.goalsData.push(goalRow)
      this.addFlatDataRow(goalRow);
    }
  }

  private processGoals(): void {
    this.processObjects(
      this.dataInputs.goals,
      ManageCEGTableRowFactory.createGoalRow,
      this.assignGoalRow.bind(this)
    );
  }

  private assignSegmentRow(segment: BudgetSegmentAccess, segmentRow: ManageCegTableRow): void {
    segmentRow.children = [
      ...this.getChildItems(ManageTableRowType.Segment, segment.id, ManageTableRowType.Campaign),
      ...this.getChildItems(ManageTableRowType.Segment, segment.id, ManageTableRowType.ExpenseGroup)
    ];

    segmentRow.children.forEach(childRow => childRow.parentId = segmentRow.id);

    segmentRow.shownChildren =
      this.dataInputs.isFilterMode ?
        segmentRow.children.filter(ManageCEGTableDataBuilder.hasItemsForDisplay) :
        segmentRow.children;

    if (this.dataInputs.isFilterMode && !segmentRow.shownChildren.length &&
      (this.dataInputs.filteredSegments?.length && !this.dataInputs.filteredSegments.includes(segmentRow.objectId))) {
      // hide empty segment-row only for filtering by segments. In other case row could contain unassigned expenses
      return;
    }

    if (this.objectExists(ManageTableRowType.SegmentGroup, segment.segment_group)) {
      this.addChildItem(
        { objectType: ManageTableRowType.SegmentGroup, id: segment.segment_group },
        { objectType: ManageTableRowType.Segment, item: segmentRow }
      );
    } else {
      this.segmentsData.push(segmentRow);
    }

    this.addFlatDataRow(segmentRow);
  }

  private processSegments(): void {
    this.processObjects(
      this.dataInputs.segments,
      ManageCEGTableRowFactory.createSegmentRow,
      this.assignSegmentRow.bind(this),
      this.dataInputs.viewMode
    );
  }

  private assignSegmentGroupRow(segmentGroup: SegmentGroup, segmentGroupRow: ManageCegTableRow): void {
    segmentGroupRow.shownChildren = segmentGroupRow.children =
      this.getChildItems(ManageTableRowType.SegmentGroup, segmentGroup.id, ManageTableRowType.Segment);

    segmentGroupRow.children.forEach(childRow => childRow.parentId = segmentGroupRow.id);

    if ((this.dataInputs.isPowerUser && !this.dataInputs.isFilterMode) || segmentGroupRow.children.length > 0) {
      this.segmentGroupsData.push(segmentGroupRow);
      this.addFlatDataRow(segmentGroupRow);
    }
  }

  private processSegmentGroups(): void {
    this.processObjects(
      this.dataInputs.segmentGroups,
      ManageCEGTableRowFactory.createSegmentGroupRow,
      this.assignSegmentGroupRow.bind(this)
    );
  }

  private setObjectIds(): void {
    const { segmentGroups, segments: allSegments, goals: allGoals, campaigns: allCampaigns, expGroups: allExpGroups } = this.dataInputs;
    const { campaigns, expGroups } = this.dataInputs.planObjects;
    const initIdSet = (
      container: Record<string, Record<number | string, number>>,
      type: ManageTableRowType,
      objects: { objectId?: number | string; id: number; budgetSegmentId?: number }[] = []
    ) => {
      for (const item of objects) {
        container[type][item.objectId || item.id] = item.budgetSegmentId || null;
      }
    };

    initIdSet(this.allObjectIdsByType, ManageTableRowType.Goal, allGoals);
    initIdSet(this.allObjectIdsByType, ManageTableRowType.Campaign, allCampaigns);
    initIdSet(this.allObjectIdsByType, ManageTableRowType.ExpenseGroup, allExpGroups);
    initIdSet(this.allObjectIdsByType, ManageTableRowType.Segment, allSegments);
    initIdSet(this.allObjectIdsByType, ManageTableRowType.SegmentGroup, segmentGroups);

    if (this.dataInputs.isFilterMode || this.dataInputs.viewMode === ManageCegViewMode.Segments) {
      initIdSet(this.currentObjectIdsByType, ManageTableRowType.Campaign, campaigns);
      initIdSet(this.currentObjectIdsByType, ManageTableRowType.ExpenseGroup, expGroups);
    }
  }

  private groupData(): void {
    const { viewMode} = this.dataInputs;

    this.data = [];
    if (viewMode === ManageCegViewMode.Goals) {
      this.data.push(...this.goalsData);
    }
    if (viewMode === ManageCegViewMode.Segments) {
      this.data.push(...this.segmentGroupsData);
      this.data.push(...this.segmentsData);
    }
    this.data.push(...this.campaignsData);
    this.data.push(...this.expGroupsData);
  }

  private buildItemHierarchyInfo(item: ManageCegTableRow): ListTreeItem[] {
    const breadcrumbs = [];

    const mapTableRowToHierarchyItem = (rowItem: ManageCegTableRow): ListTreeItem => {
      return {
        id: rowItem.objectId,
        name: rowItem.name,
        type: rowItem.type === ManageTableRowType.SegmentGroup ? 'SegmentsGroup' : rowItem.type,
        highlighted: rowItem.isFilteredOut,
      };
    }

    const writeBcItem = (el: ManageCegTableRow, bc) => {
      const parent = this.flatDataMap[el.parentId];
      bc.push(mapTableRowToHierarchyItem(el));
      if (parent) {
        writeBcItem(parent, bc);
      }
    }
    writeBcItem(item, breadcrumbs);
    breadcrumbs.reverse();
    return breadcrumbs;
  }

  private buildFilteredOutHierarchy(): void {
    const hasChildrenForDisplay = (children: ManageCegTableRow[]): boolean => {
      return children.some(child => !child.isFilteredOut);
    }

    const checkFilteringState = (items: ManageCegTableRow[] = []): void => {
      items.forEach(obj => {
        if (obj.isFilteredOut) {
          if (hasChildrenForDisplay(obj.children)) { // parent is filtered out, but some of children should be shown
            obj.children
              .filter(c => !c.isFilteredOut)
              .forEach((childRow, index) => {
                if (index === 0) {
                  childRow.hierarchyBreakLine = true;
                }
                childRow.hierarchyInfo = this.buildItemHierarchyInfo(childRow);
              });
          }
        }
        checkFilteringState(obj.children);
      })
    }

    checkFilteringState(this.data);
  }

  private init(): void {
    this.parentChildMap = {
      [ManageTableRowType.SegmentGroup]: {},
      [ManageTableRowType.Segment]: {},
      [ManageTableRowType.Goal]: {},
      [ManageTableRowType.Campaign]: {},
    };
    this.data = [];
    this.allObjectIdsByType = {
      [ManageTableRowType.Goal]: {},
      [ManageTableRowType.Campaign]: {},
      [ManageTableRowType.ExpenseGroup]: {},
      [ManageTableRowType.Segment]: {},
      [ManageTableRowType.SegmentGroup]: {},
    };
    this.currentObjectIdsByType = {
      [ManageTableRowType.Campaign]: {},
      [ManageTableRowType.ExpenseGroup]: {}
    };
    this.setObjectIds();
  }

  private buildBudgetTableHierarchyDataFromInputs(): void {
    const { viewMode } = this.dataInputs;

    this.processExpGroups();
    this.processCampaigns();
    if (viewMode === ManageCegViewMode.Goals) {
      this.processGoals();
    }
    if (viewMode === ManageCegViewMode.Segments) {
      this.processSegments();
      this.processSegmentGroups();
    }
    this.groupData();
    this.buildFilteredOutHierarchy();
  }

  private buildPerformanceTableHierarchyDataFromInputs(): void {
    const { viewMode } = this.dataInputs;
    this.processCampaigns();
    if (viewMode === ManageCegViewMode.Goals) {
      this.processGoals();
    }
    this.groupData();
    this.buildFilteredOutHierarchy();
  }

  public buildHierarchyData(dataInputs: ManageCEGTableDataBuilderInputs): ManageCEGTableDataBuildResult {
    this.dataInputs = dataInputs;
    this.init();

    if (dataInputs.dataMode === ManageCegDataMode.Performance) {
      this.buildPerformanceTableHierarchyDataFromInputs();
    } else {
      this.buildBudgetTableHierarchyDataFromInputs();
    }

    return {
      data: this.data,
      flatDataMap: this.flatDataMap
    };
  }

  public createRow(
    object,
    objectRow: ManageCegTableRow,
    objectType: ManageTableRowType
  ): ManageCegTableRow {
    switch (objectType) {
      case ManageTableRowType.Campaign:
        this.assignCampaignRow(
          object,
          objectRow
        );
        break;
      case ManageTableRowType.ExpenseGroup:
        this.assignExpenseGroupRow(
          object,
          objectRow
        );
        break;
      case ManageTableRowType.Goal:
        this.assignGoalRow(object, objectRow);
        break;

      default:
        return null;
    }
  }

}
