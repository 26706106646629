import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ManageCegTableRowPerformance,
  ManageTableDataMode,
  ManageTableTotalValues,
  PresentationTimeframe
} from '@manage-ceg/types/manage-ceg-page.types';
import { slideFromRightColumnAnimations } from '@manage-ceg/constants/manage-ceg-page.constants';
import { ManageTableCegRowBaseComponent } from '@manage-ceg/components/manage-table-ceg/components/manage-table-ceg-row-base';

@Component({
  selector: '[manage-table-ceg-foot-row]',
  templateUrl: './manage-table-ceg-foot-row.component.html',
  styleUrls: ['./manage-table-ceg-foot-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideFromRightColumnAnimations,
  ],
})
export class ManageTableCegFootRowComponent extends ManageTableCegRowBaseComponent {
  @Input() grandTotalBudget: Record<PresentationTimeframe, ManageTableTotalValues>;
  @Input() grandTotalPerformance: ManageCegTableRowPerformance;
  @Input() grandTotalForActiveTimeframes: ManageTableTotalValues;
  protected ManageTableDataMode = ManageTableDataMode;
}
