import { Injectable, NgZone } from '@angular/core';
import { fromEvent, interval, merge, Observable, Subject } from 'rxjs';
import { debounceTime, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InactivityManagerService {
  private activityEvents: Array<any>[] = [
    [window, 'resize'],
    [window, 'scroll'],
    [window, 'mousemove'],
    [document, 'click'],
    [document, 'wheel'],
    [document, 'scroll'],
    [document, 'mousemove'],
    [document, 'keyup']
  ];
  /* default inactivity time limit - 1 hour */
  private TIME_LIMIT = 1000 * 60 * 60;
  /* debug time limit - 20 seconds */
  private DEBUG_TIME_LIMIT = 1000 * 20;
  /* localStorage key to enable 'debug' mode for testing purposes */
  private DEBUG_STORAGE_KEY = '_PL_DEBUG_INACTIVITY';
  private stopTracking$ = new Subject<void>();
  private inactivityChain$;
  private activityEvents$;

  constructor(private readonly ngZone: NgZone) {
    const events$: Observable<any>[] = [];
    this.activityEvents.forEach(([target, eventName]) => {
      events$.push(fromEvent(target, eventName));
    });
    this.activityEvents$ = merge(...events$);
  }

  private initObservable(inactiveCb: Function): void {
    const DEBUG_MODE = localStorage.getItem(this.DEBUG_STORAGE_KEY);
    const TIME_LIMIT = DEBUG_MODE ? this.DEBUG_TIME_LIMIT : this.TIME_LIMIT;

    this.ngZone.runOutsideAngular(() => {
      this.inactivityChain$ = this.activityEvents$
        .pipe(
          startWith(true),
          debounceTime(500),
          switchMap(() => {
            if (DEBUG_MODE) {
              console.info('INACTIVITY TIME STARTED');
            }
            return interval(TIME_LIMIT);
          }),
          takeUntil(this.stopTracking$)
        );

      this.inactivityChain$
        .subscribe(
          () => {
            this.stopTracking();
            this.ngZone.run(() => {
              inactiveCb();
              if (DEBUG_MODE) {
                console.info('INACTIVITY CALLBACK TRIGGERED');
              }
            })
          },
          () => {}
        );
    });
  }

  public startTracking(inactiveCb: Function) {
    this.stopTracking();
    this.initObservable(inactiveCb);
  }

  public stopTracking() {
    this.stopTracking$.next();
    this.stopTracking$.complete();
  }
}
