<page-header [pageName]="integrationName + ' Integration Metric Mapping'" [isAdminPage]="true">
  <fa-icon slot="icon" [icon]="['fas', 'plug']" [rotate]="90"></fa-icon>
  <div slot="custom-items">
    <button *ngIf="integrationsList?.length"
            mat-button
            color="primary"
            class="mt-1"
            [matTooltip]="integrationName + ' data is already loading'"
            [matTooltipDisabled]="allIntegrationsSynchronized"
            matTooltipClass="dark-theme-tooltip left"
            matTooltipPosition="left"
            (click)="syncAllIntegrations()">
      <svg [ngClass]="{ 'rotate': !allIntegrationsSynchronized }" width="20" height="20" class="mr-05" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M18.0469 13.3906C16.9141 16.9062 13.6719 19.25 9.96094 19.25C7.1875 19.25 4.64844 17.9219 3.125 15.7344V18.625C3.125 19.3281 2.53906 19.875 1.875 19.875C1.17188 19.875 0.625 19.3281 0.625 18.625V13C0.625 12.3359 1.17188 11.75 1.875 11.75H6.875C7.53906 11.75 8.125 12.3359 8.125 13C8.125 13.7031 7.53906 14.25 6.875 14.25H5.11719C6.21094 15.8125 8.00781 16.75 10 16.75C12.5781 16.75 14.8828 15.1094 15.6641 12.6484C15.8594 11.9844 16.5625 11.6328 17.2266 11.8281C17.8906 12.0234 18.2422 12.7266 18.0469 13.3906Z" fill="#0063B7"/>
        <path d="M19.375 2.375V8C19.375 8.70312 18.7891 9.25 18.125 9.25H13.125C12.4219 9.25 11.875 8.70312 11.875 8C11.875 7.33594 12.4219 6.75 13.125 6.75H14.8438C13.75 5.22656 11.9531 4.25 10 4.25C7.38281 4.25 5.07812 5.92969 4.29688 8.39062C4.10156 9.05469 3.39844 9.40625 2.73438 9.21094C2.07031 8.97656 1.71875 8.27344 1.91406 7.64844C3.04688 4.13281 6.28906 1.75 10 1.75C12.7734 1.75 15.3125 3.11719 16.875 5.30469V2.375C16.875 1.71094 17.4219 1.125 18.125 1.125C18.7891 1.125 19.375 1.71094 19.375 2.375Z" fill="#0063B7"/>
      </svg>
      Refresh {{ integrationName }} data
    </button>
  </div>
</page-header>

<external-metric-types-mapping
  *ngIf="mappingTableSettings"
  [company]="company"
  [integrationSource]="integrationSource"
  [integrations]="integrationsList"
  [mappingTableSettings]="mappingTableSettings"
  [disabled]="!allIntegrationsSynchronized"
>
</external-metric-types-mapping>

<h4 *ngIf="!integrationsList" class="p-1 text-center mt-2">
  Data loading, please wait...
</h4>
<h4 *ngIf="integrationsList && !integrationsList.length" class="p-1 text-center mt-2">
  You haven't enabled a {{ integrationName }} integration for the selected budget yet. <br>
  <button mat-flat-button color="primary" class="mt-1" (click)="openIntegration()">Click here to set it up</button>
</h4>
