<header mat-dialog-title>
  <div>{{ data?.title || 'Move to Budget' }}</div>
  <search-control placeholder="Search" [disabled]="false" (textChanged)="filterItems($event)"></search-control>
</header>
<div mat-dialog-content>
  <ng-container *ngFor="let option of selectOptions">
    <div
      *ngIf="!option.context?.extraProps?.hidden"
      class="select-item"
      [class.selected]="selectedOption?.value === option.value"
      [class.disabled]="isDisabled(option)"
      (click)="selectOption(option)"
    >
      <fa-icon class="status-icon" [icon]="iconConfigByStatus[option.context?.extraProps?.status]"></fa-icon>
      <div
        class="select-item-title"
        [innerHTML]="(getStatusLabel(option) + ' ' + option.title) | highlight: searchQuery"
      ></div>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions align="end" class="dialog-actions">
  <button
    color="primary"
    mat-button
    [mat-dialog-close]="true"
    (click)="onCancel()"
  >
    {{ data.cancelAction?.label || 'Cancel' }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="ml-1"
    [mat-dialog-close]="true"
    [disabled]="!selectedOption"
    (click)="onSubmit()"
  >
    {{ data.submitAction?.label || 'Move' }}
  </button>
</div>
