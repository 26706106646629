import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionSelectOption, MoveToBudgetDialogContext } from '@spending/types/expense-action.type';
import { BudgetStatus } from '@shared/types/budget-status.type';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BudgetStatusLabel } from '@shared/constants/budget-status.constants';
import { createRegExpFromString } from '@shared/utils/common.utils';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'move-to-budget-dialog',
  templateUrl: './move-to-budget-dialog.component.html',
  styleUrls: ['./move-to-budget-dialog.component.scss']
})
export class MoveToBudgetDialogComponent {

  readonly iconConfigByStatus = {
    [BudgetStatus.Draft]: ['fad', 'drafting-compass'] as IconProp,
    [BudgetStatus.Live]: ['fad', 'signal-stream'] as IconProp,
    [BudgetStatus.Test]: ['fad', 'vial'] as IconProp,
    [BudgetStatus.Hidden]: ['fad', 'lock-alt'] as IconProp,
    [BudgetStatus.Reference]: ['fad', 'eye'] as IconProp,
  };

  selectOptions: ActionSelectOption[];
  selectedOption: ActionSelectOption;
  searchQuery: string;

  constructor(
    public dialogRef: MatDialogRef<MoveToBudgetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: MoveToBudgetDialogContext
  ) {
    this.selectOptions = data?.selectItems;
  }

  selectOption(option: ActionSelectOption): void {
    this.selectedOption = option.value === this.selectedOption?.value ? null : option;
  }

  filterItems(searchQuery: string): void {
    this.searchQuery = searchQuery;
    const isSearchActive = searchQuery.length > 2;
    const regEx = createRegExpFromString(searchQuery, 'i');
    this.selectOptions.forEach(option => {
      option.context.extraProps.hidden = isSearchActive && !regEx.test(option.title);
    });
  }

  getStatusLabel(option: ActionSelectOption): SafeHtml {
    return `<span class="status-label">[${ BudgetStatusLabel[option.context?.extraProps?.status] }]</span>`;
  }

  isDisabled(option: ActionSelectOption): boolean {
    return option.context?.extraProps?.status === BudgetStatus.Reference;
  }

  onCancel(): void {
    this.callHandler(this.data.cancelAction?.handler);
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.callHandler(this.data.submitAction?.handler);
    this.dialogRef.close(this.selectedOption);
  }

  private callHandler(handler: Function): void {
    if (handler) {
      handler(this.selectedOption);
    }
  }
}
