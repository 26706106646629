import { SharedCostRuleComponent } from '../shared-cost-rule.component';
import { SCRFocusStrategy } from './scr-focus-strategy.interface';
import { SharedCostRuleFieldType } from 'app/shared/types/shared-cost-rule.interface';

export class SCRSegmentIdFocusStrategy implements SCRFocusStrategy {
  componentInstance: SharedCostRuleComponent;

  constructor(componentInstance: SharedCostRuleComponent) {
    this.componentInstance = componentInstance;
  }

  activate(focusedSegmentIndex: number) {
    const segment = this.componentInstance.rule.segments[focusedSegmentIndex];
    const isCurrentDropdownActive = this.componentInstance.isActiveDropdown(SharedCostRuleFieldType.SegmentId, segment);
    if (!isCurrentDropdownActive) {
      this.componentInstance.resetActiveDropdown();
    }
    this.componentInstance.currentFocusStrategy = this;
    this.componentInstance.focusedFieldType = SharedCostRuleFieldType.SegmentId;
    this.componentInstance.focusedSegmentIndex = focusedSegmentIndex;
    this.componentInstance.focusElement(this.componentInstance.segmentIdElements._results[focusedSegmentIndex]);
  }

  moveFocus(isForward: boolean) {
    const { focusStrategies } = this.componentInstance;
    if (isForward) {
      focusStrategies[SharedCostRuleFieldType.Cost].activate(this.componentInstance.focusedSegmentIndex);
      return;
    }

    // move to the cost field of the previous segment
    if (this.componentInstance.focusedSegmentIndex > 0) {
      focusStrategies[SharedCostRuleFieldType.Cost].activate(this.componentInstance.focusedSegmentIndex - 1);
      return;
    }

    focusStrategies[SharedCostRuleFieldType.AddSegment].activate();
  }

  selectElement() {
    if (!this.componentInstance.permissions.editRule || !this.componentInstance.permissions.editSegments) {
      return;
    }
    this.componentInstance.toggleActiveDropdown(
      SharedCostRuleFieldType.SegmentId,
      this.componentInstance.rule.segments[this.componentInstance.focusedSegmentIndex]
    );
  }

  unselectElement() {
    this.componentInstance.resetActiveDropdown();
  }
}
