import { getTimeZones, TimeZone } from '@vvo/tzdb';

export const getTimezoneList = (): TimeZone[] => {
  const filterCache = new Set();
  const filteredZones = getTimeZones()
    .filter(tz => {
      if (filterCache.has(tz.alternativeName)) {
        return false;
      }

      filterCache.add(tz.alternativeName);
      return true;
    });
  filterCache.clear();

  return filteredZones;
};

export const getLocalTimezone = (zones: TimeZone[]): TimeZone => {
  const tmzOffset = new Date().getTimezoneOffset();
  const tmzName = Intl && Intl.DateTimeFormat ? Intl.DateTimeFormat().resolvedOptions().timeZone : null;
  const relevantZones = zones.filter(zone => (zone.rawOffsetInMinutes === -tmzOffset));

  if (!relevantZones) {
    return null;
  }

  if (!tmzName) {
    return relevantZones[0];
  }

  return relevantZones.find(zone => tmzName.startsWith(zone.continentName)) || relevantZones[0];
};
