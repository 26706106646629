import { MetricValueUpdateItem, MetricValueUpdateType } from 'app/budget-object-details/types/metric-value-update-item.interface';
import { getDaysInMonth } from 'app/shared/utils/date.utils';

export const createEmptyRecordData = (updateType): MetricValueUpdateItem => {
  return {
    id: undefined,
    type: updateType,
    objectId: undefined,
    date: undefined,
    changeInValue: undefined,
    runningTotal: undefined,
    updatedDate: '',
    notes: '',
    isEditable: true,
    isRemovable: true,
  }
};

export const createMonthTotalRecordData = (changeInValue: number, runningTotal: number, date: string) => {
  const [ year, month ] = date.split('-');
  const lastDay = getDaysInMonth(+year, +month - 1);
  date += `-${lastDay}`;
  return {
    ...createEmptyRecordData(MetricValueUpdateType.monthTotal),
    changeInValue, runningTotal, date, isRemovable: false
  }
};

export const getOriginObjectTooltip = originUpdateType => {
  const tooltipsByType = {
    [MetricValueUpdateType.campaign]: 'This Campaign',
    [MetricValueUpdateType.program]: 'This Expense Group'
  };
  return tooltipsByType[originUpdateType] || '';
};

export const trackByObjectData = (index, item: MetricValueUpdateItem) => {
  return `${item.date}_${item.type}_${item.objectId}`;
};

export const getMonthKey = (date: string) => {
  const splitDate = date.split('-');
  return `${splitDate[0]}-${splitDate[1]}`;
};
