import { ActionMenuItem } from 'app/shared/components/actions-menu/actions-menu.component';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'budget-chart-actions-menu',
  templateUrl: './budget-chart-actions-menu.component.html',
  styleUrls: ['./budget-chart-actions-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetChartActionsMenuComponent {
  @Input() widgetActions: ActionMenuItem[] = [];
}
