import { Component, ContentChild, ElementRef, EventEmitter, HostBinding, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { TableViewRow } from './table-data.type';
import { SortParams } from 'app/shared/types/sort-params.interface';
import { hideTooltip, updateTooltipState } from 'app/shared/utils/tooltip-title.utils';

@Component({
  selector: 'table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
})

/**
* @class - TableViewComponent
*/
export class TableViewComponent {
  @ContentChild('rowTitleTemplate', { static: false }) rowTitleTemplateRef: TemplateRef<any>;
  @ContentChild('thContentTemplate', { static: false }) thContentTemplateRef: TemplateRef<any>;
  @ContentChild('tooltipsTemplate', { static: false }) tooltipsTemplate: TemplateRef<any>;
  @ViewChild('tooltipContainer', { static: false }) tooltipContainer: ElementRef;
  @Input() columnTitles: string[];
  @Input() rows: TableViewRow[];
  @Input() warningIcon = false;
  @Input() appliedSorting: SortParams;
  @Input() groupsExpandState: Record<string, boolean> = {};
  @Input() tooltipTypeProvider: (fieldType: string, fieldValue: number | null) => string;
  @Output() onSortRows = new EventEmitter<string>();
  @HostBinding('class.tableView') tableView = true;
  @HostBinding('class.fullWidth') @Input() fullWidth: boolean;

  public tooltipState = {
    styles: {},
    isVisible: false,
    type: null,
    className: ''
  };

  public displayTooltip($event: MouseEvent, data: { fieldName: string; value: number; }) {
    if (!this.tooltipTypeProvider) {
      return
    }

    const target = $event.target as Element;
    const targetRect = target?.getBoundingClientRect();
    const containerRect = this.tooltipContainer.nativeElement.getBoundingClientRect();
    const tooltipType = this.tooltipTypeProvider(data.fieldName, data.value);
    if (!tooltipType || !targetRect || !containerRect) {
      return;
    }

    this.tooltipState.type = tooltipType;
    setTimeout(() => {
      updateTooltipState(containerRect, targetRect, this.tooltipState);
      this.tooltipState.isVisible = true;
    })
  }

  public hideTooltip() {
    hideTooltip(this.tooltipState);
  }
}
