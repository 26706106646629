<button
  *ngIf="uploadingFilesNumber || failedFilesNumber"
  mat-button
  class="upload-status-btn"
  (click)="openStatusDialog()"
>
  <loader size="12px" [isVisible]="uploadingFilesNumber > 0" [isInline]="true" class="mr-05"></loader>
  <fa-icon
    *ngIf="!uploadingFilesNumber && failedFilesNumber"
    class="error"
    [icon]="['fas', 'circle-exclamation']"
  ></fa-icon>
  <span *ngIf="uploadingFilesNumber">
    {{ uploadingFilesNumber + (uploadingFilesNumber === 1 ? ' file uploading' : ' files uploading') }}
  </span>
  <span *ngIf="!uploadingFilesNumber && failedFilesNumber">
    {{ failedFilesNumber + (failedFilesNumber === 1 ? ' error' : ' errors') }}
  </span>
</button>
