import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BudgetBaselineListContext } from '@shared/types/dialog-context.interface';

@Component({
  selector: 'budget-baseline-modal',
  templateUrl: './budget-baseline-modal.component.html',
  styleUrls: ['./budget-baseline-modal.component.scss']
})
export class BudgetBaselineModalComponent {

  public context: BudgetBaselineListContext;
  public baselineName: string;

  constructor(
    public dialogRef: MatDialogRef<BudgetBaselineModalComponent>,
    @Inject(MAT_DIALOG_DATA) context: BudgetBaselineListContext,
  ) {
    this.context = context;
  }

  protected onSubmit(): void {
    if (this.baselineName?.length) {
      this.dialogRef.close(this.baselineName);
    }
  }
}
