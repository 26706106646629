<page-header pageName="Tags" [isAdminPage]="true">
  <fa-icon slot="icon" [icon]="['fas', 'tags']"></fa-icon>
</page-header>
<div class="tags-management-wrapper">
  <h4 class="title">
    Manage tags
    <button class="btn-add" type="button" (click)="openCreateTagModal()">
      Add
    </button>
  </h4>
  <ul class="tags-management-instruction">
    <li>Tags are used to associate Planful objects</li>
    <li>Planful can be filtered and searched by tags</li>
    <li>Tags can be attached to any Planful object</li>
  </ul>
  <div
    class="tag-list-wrapper"
    #wrapper
    scrollDetector
    (scrolledToBottom)="tagsManagementService.getChunkOfTags(companyId)"
    [scrollContainerElement]="wrapper"
  >
    <table class="tags-list pl-table">
      <thead>
        <tr class="tags-list-headers table-headers-row">
          <th class="tags-list-header-item">
            Tags<span
              class="icon-sortingTable"
              (click)="changeSorting('name')"
            ></span>
          </th>
          <th class="tags-list-header-item">
            # References<span
              class="icon-sortingTable"
              (click)="changeSorting('usage_count')"
            ></span>
          </th>
          <!-- <th class="tags-list-header-item"></th> -->
          <th class="tags-list-header-item"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tag of tagsList" class="table-data-row">
          <td>
            <div class="cell-wrapper edit-item">
              <input
                class="edit-tag-field"
                [(ngModel)]="tag.value"
                [attr.maxlength]="MAX_TAG_NAME_LENGTH"
                (focus)="onFocusTagNameField(tag.id)"
                (blur)="onBlurTagNameField($event, tag.id)"
              />
            </div>
          </td>
          <td>
            <div class="cell-wrapper">
              {{ tag.usageCount }}
            </div>
          </td>
          <!-- Commented because currently we can't support this functionality the way it should be -->
          <!-- <td>
            <div class="cell-wrapper">
              <a href="javascript:void(0)" class="search-tag-link" (click)="redirectToSearchByTag(tag.name)">View all related items</a>
            </div>
          </td> -->
          <td>
            <div class="cell-wrapper remove-item">
              <button
                class="mat-button"
                (click)="onRemoveTag(tag)"
                mat-icon-button
              >
                <fa-duotone-icon
                  [icon]="['fad', 'trash-alt']"
                ></fa-duotone-icon>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot *ngIf="tagsList.length == 0">
        <tr>
          <td colspan="4" class="empty-list-placeholder">
            <span>{{ validations.ValiditionMessages.TAGS_LIST_EMPTY }}</span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
