import { SharedCostRuleComponent } from '../shared-cost-rule.component';
import { SCRFocusStrategy } from './scr-focus-strategy.interface';
import { SharedCostRuleFieldType } from 'app/shared/types/shared-cost-rule.interface';
import { ElementRef } from '@angular/core';

export class SCRCostFocusStrategy implements SCRFocusStrategy {
  componentInstance: SharedCostRuleComponent;
  currentValue = 0;

  constructor(componentInstance: SharedCostRuleComponent) {
    this.componentInstance = componentInstance;
  }

  activate(focusedSegmentIndex: number) {
    this.componentInstance.currentFocusStrategy = this;
    this.componentInstance.focusedFieldType = SharedCostRuleFieldType.Cost;
    this.componentInstance.focusedSegmentIndex = focusedSegmentIndex;
    this.componentInstance.resetActiveDropdown();
    this.currentValue = this.componentInstance.rule.segments[focusedSegmentIndex].cost;

    const costRef = this.getElementRef(focusedSegmentIndex);
    if (costRef) {
      costRef.nativeElement.select();
    }
  }

  moveFocus(isForward: boolean) {
    const { focusStrategies } = this.componentInstance;
    if (!isForward) {
      focusStrategies[SharedCostRuleFieldType.SegmentId].activate(this.componentInstance.focusedSegmentIndex);
      return;
    }

    // if we are on the cost field of the last segment then move to status field
    if (this.componentInstance.focusedSegmentIndex >= this.componentInstance.rule.segments.length - 1) {
      focusStrategies[SharedCostRuleFieldType.Status].activate();
      return;
    }

    // otherwise move to the segment id field of the next segment
    focusStrategies[SharedCostRuleFieldType.SegmentId].activate(this.componentInstance.focusedSegmentIndex + 1);
  }

  selectElement() {
    const { focusedSegmentIndex } = this.componentInstance;
    this.componentInstance.focusElement(this.getElementRef(focusedSegmentIndex));
  }

  unselectElement() {
    const { focusedSegmentIndex } = this.componentInstance;
    this.componentInstance.rule.segments[focusedSegmentIndex].cost = this.currentValue;
    this.componentInstance.focusElement(this.componentInstance.budgetElement);
  }

  private getElementRef(index): ElementRef {
    return this.componentInstance.costElements.toArray()[index];
  }
}
