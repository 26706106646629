import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AfterViewInit, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { DIALOG_ACTION_TYPE, DialogContext } from '../../types/dialog-context.interface';

export const ExtendedDialogClassName = 'extended-confirmation-dialog';

@Component({
  selector: 'confirmation-dialog',
  styleUrls: ['./confirmation-dialog.component.scss'],
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements AfterViewInit {
  @ViewChildren('button') buttons: QueryList<MatButton>;

  public context: DialogContext;
  public ACTION_TYPE = DIALOG_ACTION_TYPE;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) context: DialogContext
  ) {
    this.context = context;
  }

  public callHandler(handler: Function): void {
    if (handler) { handler(); }
    // Passing 'true' to indicate dialog was closed by action handler not implicitly
    this.dialogRef.close(true);
  }

  private setInitialFocus() {
    const idx = this.context.actions?.findIndex(action => action.initialFocus);
    const buttonToFocus = this.buttons?.get(idx);
    if (!buttonToFocus) {
      return;
    }

    setTimeout(() => {
      buttonToFocus.focus();
    })
  }

  ngAfterViewInit(): void {
    this.setInitialFocus();
  }
}
