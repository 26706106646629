import { Injectable } from '@angular/core';
import { ExpenseCustomNameColumn, ExpenseTableColumn } from '../types/expense-page.type';
import { ExpenseDO } from '@shared/types/expense.interface';
import { Configuration } from '../../app.constants';
import { ExpenseAllocationMode } from 'app/shared/types/expense-allocation-mode.type';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { LightProgram } from 'app/shared/types/program.interface';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { DatePipe } from '@angular/common';
import { ExpenseTableColumnsService } from '@spending/services/expense-table-columns.service';
import { SpendingCommonRowData } from '@spending/services/invoice-table-data.service';
import { ExtendedUserDO } from '@shared/types/user-do.interface';
import { utClasses } from '@spending/constants/ut-classes.constants';
import { BudgetObjectSourceLabels } from '../../budget-object-details/types/budget-object-details-state.interface';

export interface DefaultColumnData {
  value: string | number;
  cssClass?: string;
}

export interface StatusColumnData {
  value: ExpenseAllocationMode;
  cssClass: string;
}

export interface NameColumnData {
  createdDate: Date;
  name: string;
}

export interface AmountColumnData {
  sourceAmount: number; // editable value - storing in DB
  convertedAmount: number; // amount in USD (budget currency) - will be calculated on backend side | use it for Subtotal calculations
  sourceCurrency?: string;
  disabled: boolean;
  cssClass?: string;
}

export interface HierarchyTriggerColumnData {
  valueName: string;
  valueType: string;
  cssClass: string;
}

export interface ExpenseRowData extends SpendingCommonRowData {
  isPseudoObject: boolean;
  [ExpenseTableColumn.NAME]?: NameColumnData;
  [ExpenseTableColumn.PLANNED]?: AmountColumnData;
  [ExpenseTableColumn.ACTUAL]?: AmountColumnData;
  [ExpenseTableColumn.DIFFERENCE]?: AmountColumnData;
  [ExpenseTableColumn.STATUS]?: StatusColumnData;
  [ExpenseTableColumn.TIMEFRAME]?: DefaultColumnData;
  [ExpenseTableColumn.CURRENCY]?: DefaultColumnData;
  [ExpenseTableColumn.SEGMENT]?: HierarchyTriggerColumnData;
  [ExpenseTableColumn.PARENT]?: HierarchyTriggerColumnData;
  [ExpenseTableColumn.SOURCE]?: DefaultColumnData;
  [ExpenseTableColumn.GL_CODE]?: DefaultColumnData;
  [ExpenseTableColumn.VENDOR]?: DefaultColumnData;
  [ExpenseTableColumn.CREATED_DATE]?: DefaultColumnData;
  [ExpenseTableColumn.UPDATED_DATE]?: DefaultColumnData;
  [ExpenseTableColumn.DELIVERY_DATE]?: DefaultColumnData;
  [ExpenseTableColumn.ATTACHMENT]?: DefaultColumnData;
  [ExpenseTableColumn.TYPE]?: DefaultColumnData;
  [ExpenseTableColumn.OWNER]?: DefaultColumnData;
  [ExpenseTableColumn.RELATED]?: DefaultColumnData;
  [ExpenseTableColumn.INVOICE]?: DefaultColumnData;
  [ExpenseTableColumn.PO_NUMBER]?: DefaultColumnData;
}

export interface ExpenseSubtotalData {
  [ExpenseTableColumn.PLANNED]: number;
  [ExpenseTableColumn.ACTUAL]: number;
  [ExpenseTableColumn.DIFFERENCE]: number;
}

@Injectable()
export class ExpenseTableDataService {
  private initialSubtotalData: ExpenseSubtotalData = {
    [ExpenseTableColumn.PLANNED]: 0,
    [ExpenseTableColumn.ACTUAL]: 0,
    [ExpenseTableColumn.DIFFERENCE]: 0,
  };
  private _subtotalData: ExpenseSubtotalData = { ...this.initialSubtotalData };
  private _rows: ExpenseRowData[];

  private createdColumnIds: ExpenseTableColumn[] = [];
  private objectNameStore = {
    [ExpenseCustomNameColumn.ExpenseType]: null,
    [ExpenseCustomNameColumn.GlCode]: null,
    [ExpenseCustomNameColumn.Vendor]: null,
  };
  private objectSnapshotGetters = {
    [ExpenseCustomNameColumn.ExpenseType]: () => this.companyDataService.expenseTypesSnapshot,
    [ExpenseCustomNameColumn.GlCode]: () => this.companyDataService.glCodesSnapshot,
    [ExpenseCustomNameColumn.Vendor]: () => this.companyDataService.vendorsSnapshot,
  };
  private columnMapper = {
    [ExpenseTableColumn.NAME]: expense => this.createNameCell(expense),
    [ExpenseTableColumn.PLANNED]: expense => this.createAmountCell(ExpenseTableColumn.PLANNED, expense, expense.source_amount, expense.amount, utClasses.utPlanned),
    [ExpenseTableColumn.ACTUAL]: expense => this.createAmountCell(ExpenseTableColumn.ACTUAL, expense, expense.source_actual_amount, expense.actual_amount, utClasses.utActual),
    [ExpenseTableColumn.DIFFERENCE]: expense => this.createAmountCell(
      ExpenseTableColumn.DIFFERENCE,
      expense,
      Math.round((expense.source_amount - expense.source_actual_amount) * 100) / 100,
      Math.round((expense.amount - expense.actual_amount) * 100) / 100,
      null
    ),
    [ExpenseTableColumn.STATUS]: expense => this.createStatusCell(expense),
    [ExpenseTableColumn.TIMEFRAME]: expense => this.createDefaultCell(expense, 'company_budget_alloc', null, utClasses.utTimeframe),
    [ExpenseTableColumn.CURRENCY]: expense => this.createDefaultCell(expense, 'source_currency'),
    [ExpenseTableColumn.SEGMENT]: expense => this.createSegmentCell(expense),
    [ExpenseTableColumn.PARENT]: expense => this.createParentCell(expense),
    [ExpenseTableColumn.SOURCE]: expense => this.createDefaultCell(expense, 'source', val => this.sourceIdToName(val)),
    [ExpenseTableColumn.GL_CODE]: expense => this.createDefaultCell(expense, 'gl_code', val => this.getItemNameFromStore(ExpenseCustomNameColumn.GlCode, val), utClasses.utGl),
    [ExpenseTableColumn.VENDOR]: expense => this.createDefaultCell(expense, 'vendor', val => this.getItemNameFromStore(ExpenseCustomNameColumn.Vendor, val), utClasses.utVendor),
    [ExpenseTableColumn.CREATED_DATE]: expense => this.createDefaultCell(expense, 'crd', val => this.transformDate(val)),
    [ExpenseTableColumn.UPDATED_DATE]: expense => this.createDefaultCell(expense, 'upd', val => this.transformDate(val)),
    [ExpenseTableColumn.DELIVERY_DATE]: expense => this.createDefaultCell(expense, 'expense_delivery_date', val => this.transformDate(val)),
    [ExpenseTableColumn.ATTACHMENT]: expense => this.createDefaultCell(expense, 'attachments_number'),
    [ExpenseTableColumn.TYPE]: expense => this.createDefaultCell(expense, 'expense_type', val => this.getItemNameFromStore(ExpenseCustomNameColumn.ExpenseType, val)),
    [ExpenseTableColumn.OWNER]: expense => ExpenseTableDataService.createOwnerCell(expense, this.companyDataService.companyUsersSnapshot),
    [ExpenseTableColumn.RELATED]: expense => this.createDefaultCell(expense, 'relation_group', null, utClasses.utRelated),
    [ExpenseTableColumn.INVOICE]: expense => this.createDefaultCell(expense, 'invoice'),
    [ExpenseTableColumn.PO_NUMBER]: expense => this.createDefaultCell(expense, 'expense_po_no'),
  };

  cegStatusEnabled: boolean;

  constructor(
    private readonly configuration: Configuration,
    private readonly budgetDataService: BudgetDataService,
    private readonly companyDataService: CompanyDataService,
    private readonly expenseTableColumnsService: ExpenseTableColumnsService,
    private readonly datePipe: DatePipe,
  ) {
    this.budgetDataService.selectedBudget$.subscribe(data => {
      this.cegStatusEnabled = data.new_campaigns_programs_structure;
    })
  }

  public get subtotalData(): ExpenseSubtotalData {
    return this._subtotalData;
  }

  public calculateSubtotalData(): void {
    const allAmountColumnsIsHidden = (rowExample: ExpenseRowData) => {
      return !rowExample[ExpenseTableColumn.PLANNED] && !rowExample[ExpenseTableColumn.ACTUAL] && !rowExample[ExpenseTableColumn.DIFFERENCE]
    }
    if (!this.rows?.length || allAmountColumnsIsHidden(this.rows[0])) {
      this._subtotalData = this.initialSubtotalData;
      return;
    }
    this._subtotalData = this.rows.reduce((subtotal, row) => {
      Object.keys(subtotal).forEach(key => {
        subtotal[key] += row[key]?.convertedAmount || row[key]?.sourceAmount || 0;
      });
      return subtotal;
    }, { ...this.initialSubtotalData });
  }

  public get rows(): ExpenseRowData[] {
    return this._rows;
  }

  private get visibleColumnNames(): ExpenseTableColumn[] {
    return this.expenseTableColumnsService.expenseTableColumns
      .filter(item => item.visible)
      .map(item => item.id);
  }

  public createRows(expenses: ExpenseDO[]): void {
    if (expenses) {
      this.createdColumnIds = this.visibleColumnNames;
      this._rows = expenses.map((expense, i) => this.createTableRow(expense, i + 1));
    } else {
      this._rows = null;
    }
    this.calculateSubtotalData();
  }

  public updateRow(expense: ExpenseDO): void {
    const targetRow = this._rows.find(row => row.expenseId === expense.id);
    const updatedRow = this.createTableRow(expense, targetRow.rowId);
    Object.entries(updatedRow).forEach(([key, value]) => {
      targetRow[key] = value;
    });
    this.calculateSubtotalData();
  }

  private createTableRow(expense: ExpenseDO, rowId: number): ExpenseRowData {
    const tableRow: ExpenseRowData = {
      rowId,
      expenseId: expense.id || expense.expense_id,
      expenseObject: expense,
      isPseudoObject: !expense.id,
    };
    this.createdColumnIds.forEach(columnName => {
      this.updateColumn(tableRow, columnName, expense);
    })
    return tableRow;
  }

  private updateColumn(tableRow, columnName: ExpenseTableColumn, expense?: ExpenseDO) {
    if (!tableRow[columnName] && this.columnMapper[columnName]) {
      tableRow[columnName] = this.columnMapper[columnName](expense || tableRow.expenseObject);
    }
  }

  public onVisibleColumnsChanged(): void {
    const lastColumnList = this.visibleColumnNames;
    const newlyEnabledColumns = lastColumnList.filter(columnId => !this.createdColumnIds.includes(columnId));
    if (newlyEnabledColumns.length) {
      this.createdColumnIds.push(...newlyEnabledColumns);
    }
    this._rows?.forEach(row => {
      newlyEnabledColumns.forEach(column => {
        this.updateColumn(row, column);
      })
    })
  }

  private getItemNameFromStore(storeKey: string, itemId: number): string {
    if (!itemId) {
      return '';
    }
    if (!this.objectNameStore[storeKey] || !this.objectNameStore[storeKey][itemId]) {
      this.refreshNameStoreItem(storeKey);
    }
    return this.objectNameStore[storeKey][itemId] || itemId.toString();
  }

  private refreshNameStoreItem(storeKey): void {
    this.objectNameStore[storeKey] = this.objectSnapshotGetters[storeKey]().reduce((storeObj, item) => {
      if ((storeKey === ExpenseCustomNameColumn.ExpenseType || storeKey === ExpenseCustomNameColumn.GlCode || storeKey === ExpenseCustomNameColumn.Vendor) && this.cegStatusEnabled) {
        storeObj[item.id] = `${item.name}${(item.isEnabled || item.is_enabled) ? '' : ' (Disabled)'}`;
      } else {
        storeObj[item.id] = item.name;
      }
      return storeObj;
    }, {})
  }

  private transformDate(val: string): string {
    return this.datePipe.transform(val, 'dd MMM yyyy');
  }

  private sourceIdToName(val: string): string {
    return BudgetObjectSourceLabels[val] || val;
  }

  private createNameCell(expense: ExpenseDO): NameColumnData {
    return {
      createdDate: new Date(expense.crd),
      name: expense.name
    };
  }

  private createAmountCell(
    cellType: ExpenseTableColumn,
    expense: ExpenseDO,
    sourceAmount: number,
    convertedValue: number,
    cssClass: string,
  ): AmountColumnData {
    return {
      cssClass: cssClass || '',
      sourceAmount,
      convertedAmount: sourceAmount === convertedValue ? null : convertedValue,
      sourceCurrency: expense.source_currency,
      disabled: cellType === ExpenseTableColumn.DIFFERENCE || expense.mode === ExpenseAllocationMode.Closed,
    };
  }

  private createStatusCell(expense: ExpenseDO): StatusColumnData {
    return {
      cssClass: utClasses.utStatus,
      value: expense.mode as ExpenseAllocationMode,
    };
  }

  private createSegmentCell(expense: ExpenseDO): HierarchyTriggerColumnData {
    const objectTypes = this.configuration.OBJECT_TYPES
    let objectType: string;
    let targetId: number;
    let snapshot: (SharedCostRule | BudgetSegmentAccess)[] = [];

    if (expense.company_budget_segment1) {
      objectType = objectTypes.segment && expense.id ? objectTypes.segment : objectTypes.sharedCostRule;
      targetId = expense.company_budget_segment1;
      snapshot = this.budgetDataService.segmentsSnapshot;
    } else if (expense.split_rule) {
      objectType = objectTypes.sharedCostRule;
      targetId = expense.split_rule;
      snapshot = this.budgetDataService.sharedCostRulesSnapshot;
    }

    return {
      cssClass: utClasses.utSegment,
      valueName: snapshot.find(item => item.id === targetId)?.name || 'No Segment',
      valueType: objectType,
    };
  }

  private createParentCell(expense: ExpenseDO): HierarchyTriggerColumnData {
    const objectTypes = this.configuration.OBJECT_TYPES
    let objectType: string;
    let targetId: number;
    let snapshot: (LightCampaign | LightProgram)[] = [];

    if (expense.campaign) {
      objectType = objectTypes.campaign;
      targetId = expense.campaign;
      snapshot = this.budgetDataService.lightCampaignsSnapshot || this.budgetDataService.campaignsSnapshot;
    } else if (expense.program) {
      objectType = objectTypes.program;
      targetId = expense.program;
      snapshot = this.budgetDataService.lightProgramsSnapshot;
    }

    return {
      cssClass: utClasses.utParent,
      valueName: snapshot?.find(item => item.id === targetId)?.name || 'No Parent',
      valueType: objectType,
    };
  }

  private static createOwnerCell(expense: ExpenseDO, companyUsersSnapshot: ExtendedUserDO[]): DefaultColumnData {
    const profileDetail = companyUsersSnapshot.find(user => user.id === expense.owner)?.user_profile_detail;
    return {
      value: profileDetail ? (profileDetail.first_name.charAt(0) + profileDetail.last_name.charAt(0)) : expense.owner,
    };
  }

  private createDefaultCell(expense: ExpenseDO, field: string, valueFormatter?: (any) => string | number, cssClass?: string): DefaultColumnData {
    return {
      cssClass: cssClass || '',
      value: valueFormatter ? valueFormatter(expense[field]) : expense[field],
    };
  }
}
