<article class="auth-page login-screen signin">
  <section class="auth-page-content split-view">
    <div class="login-form-container">
      <h1 class="logo">
        <a href="javascript:;" title="Planful" [routerLink]="['/']">
          <img src="assets/images/planful-logo-colored.svg" width="190" alt="logo" />
        </a>
      </h1>
      <h3 class="auth-page-content-title">Use Custom Domain</h3>
      <form class="auth-page-form login-form" #form="ngForm" novalidate (ngSubmit)="goToCustomDomain(domain.value, form.valid)">
        <div class="form-field">
          <label class="form-field-label">To go to your company’s login page, enter the custom domain</label>
          <input autocomplete="off" class="form-field-input" type="text" name="domain" [(ngModel)]="form.domain" #domain="ngModel" required  />
          <div class="md-errors-spacer error form-field-error" [hidden]="domain.valid || (domain.pristine && !form.submitted)">
            <div *ngIf="domain.errors?.required">
              Domain is required
            </div>
          </div>
        </div>
        <div class="custom-url">
          <div>{{protocol}}</div>
          <div [class.highlighted]="!domain.value">{{domain.value || 'mydomain'}}</div>
          <div>{{'.' + envDomain}}</div>
        </div>
        <div class="form-actions">
          <button mat-stroked-button color="primary" type="button" [routerLink] = "['/login']">Back</button>
          <button mat-flat-button color="primary" type="submit"  [disabled]="form.invalid">Continue</button>
        </div>
      </form>
    </div>
    <rights-reserved></rights-reserved>
  </section>
  <statement-text></statement-text>
</article>
