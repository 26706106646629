import {
  BudgetTimeframeBrief,
  ManageCegTableCellAllocationAmount,
  ManageCegTableCellAllocations,
  ManageCegTableRowAllocations,
  ManageTableBudgetColumnName, ManageTableTotalValues, PresentationTimeframe
} from '../../types/manage-ceg-page.types';
import { AmountsByTimeframes, AmountsDO, AssumedAmountsDO } from '@shared/types/object-amounts.interface';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { CampaignAllocation, ProgramAllocation } from '@shared/types/budget-object-allocation.interface';

export function convertTfAmountsDOToManageCegTableCellAllocations(tfAmounts: AmountsDO): ManageCegTableCellAllocations {
  return createAmountCegItem<ManageCegTableCellAllocationAmount>(tfAmounts, v => ({ ownAmount: v })) as ManageCegTableCellAllocations;
}

export function convertTfAmountsDOToBudgetTotals(tfAmounts: AmountsDO): ManageTableTotalValues {
  return createAmountCegItem<number>(tfAmounts, v => v) as ManageTableTotalValues;
}

export function createAmountCegItem<ValueType>(
  tfAmounts: AmountsDO, // create empty item if "tfAmounts" wasn't provided
  valueSetter = v => v
): Record<ManageTableBudgetColumnName, any> {
  const committedAndPlanned = sumAmounts(tfAmounts?.remaining_committed, tfAmounts?.remaining_planned);
  return {
    [ManageTableBudgetColumnName.Budget]: valueSetter(tfAmounts?.budget || 0),
    [ManageTableBudgetColumnName.Actual]: valueSetter(tfAmounts?.actual || 0),
    [ManageTableBudgetColumnName.Committed]: valueSetter(tfAmounts?.remaining_committed || null),
    [ManageTableBudgetColumnName.Planned]: valueSetter(tfAmounts?.remaining_planned || null),
    [ManageTableBudgetColumnName.CommittedAndPlanned]: valueSetter(committedAndPlanned),
    [ManageTableBudgetColumnName.Available]: valueSetter(tfAmounts?.available || 0),
  } as Record<ManageTableBudgetColumnName, any>;
}

function isNumber(value: any): boolean {
  return typeof value === 'number' && isFinite(value);
}

export function sumAmounts(value1: number | null, value2: number | null): number | null {
  return (isNumber(value1) || isNumber(value2)) ? (value1 || 0) + (value2 || 0) : null;
}

export function getRowAllocationsFromAmountsData(amountsData: AmountsByTimeframes): ManageCegTableRowAllocations {
  const allocationData =
    Object.entries(amountsData).map(([tfId, tfAmounts]) => [tfId, convertTfAmountsDOToManageCegTableCellAllocations(tfAmounts)])
  return Object.fromEntries(allocationData);
}

export function getRowTotalsFromAmountsData(amountsData: AmountsByTimeframes): ManageCegTableCellAllocations {
  return Object.values(amountsData)
    .map(convertTfAmountsDOToManageCegTableCellAllocations)
    .reduce((totals, tfAllocation) => {
    Object.keys(tfAllocation).forEach(key => {
      if (!totals[key]) {
        totals[key] = {};
      }
      totals[key].ownAmount = totals[key].ownAmount || 0 + tfAllocation[key].ownAmount || 0;
      totals[key].campaignsAndPrograms = totals[key].campaignsAndPrograms || 0 + tfAllocation[key].campaignsAndPrograms || 0;
      totals[key].unallocated = totals[key].unallocated || 0 + tfAllocation[key].unallocated || 0;
    })
    return totals;
  }, {} as ManageCegTableCellAllocations);
}

export function calculatePresentationObjectSum<T>(
  budgetAllocations: Record<string, T>,
  timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>,
): Record<string, T> {
  return calculatePresentationSum(
    budgetAllocations,
    timeframesAll,
    createAmountCegItem(null, v => ({ ownAmount: v })),
  );
}

export function calculatePresentationSegmentSum<T>(
  budgetAllocations: Record<string, T>,
  timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>
): Record<string, T> {
  return calculatePresentationSum(
    budgetAllocations,
    timeframesAll,
    createAmountCegItem(null, v => ({ ownAmount: v, campaignsAndPrograms: v, unallocated: v }))
  );
}

export function calculatePresentationSum<T>(
  budgetAllocationsObj: Record<string, T>,
  timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>,
  emptyAllocationItem = createAmountCegItem(null, v => v)
): Record<string, T> {
  const budgetAllocations = Object.values(budgetAllocationsObj);
  const isMonthlyBudget = budgetAllocations.length  === 12;
  const originalTimeframes = isMonthlyBudget ? timeframesAll[BudgetTimeframesType.Month] : timeframesAll[BudgetTimeframesType.Quarter];
  return originalTimeframes.reduce((presentationData, tf, i: number) => {
    const tfAllocation = budgetAllocationsObj[tf.id];
    const yearCurrentValue = presentationData[PresentationTimeframe.Year]  || emptyAllocationItem;
    presentationData[PresentationTimeframe.Year] = sumObjectValues(yearCurrentValue, tfAllocation);
    if (isMonthlyBudget) {
      const quarterId = timeframesAll[BudgetTimeframesType.Quarter][Math.floor(i / 3)].id;
      const quarterCurrentValue = presentationData[quarterId] || emptyAllocationItem;
      presentationData[quarterId] = sumObjectValues(quarterCurrentValue, tfAllocation);
    }
    return presentationData;
  }, {});
}

export function addSegmentSpecificRowAllocationsData(
  targetAllocationsData: ManageCegTableRowAllocations,
  segmentObjectAmountsData: AmountsByTimeframes
): void {
  Object.entries(targetAllocationsData).forEach(
    ([tfId, cellData]) => {
      const tfAmounts = segmentObjectAmountsData[tfId];

      const budgetData = cellData[ManageTableBudgetColumnName.Budget];
      budgetData.campaignsAndPrograms = tfAmounts.budget || 0;
      budgetData.unallocated = budgetData.ownAmount - budgetData.campaignsAndPrograms;

      const actualSpendData = cellData[ManageTableBudgetColumnName.Actual];
      actualSpendData.campaignsAndPrograms = tfAmounts.actual || 0;
      actualSpendData.unallocated = actualSpendData.ownAmount - actualSpendData.campaignsAndPrograms;

      const remainingPlannedData = cellData[ManageTableBudgetColumnName.Planned];
      remainingPlannedData.campaignsAndPrograms = tfAmounts.remaining_planned || 0;

      const remainingCommittedData = cellData[ManageTableBudgetColumnName.Committed];
      remainingCommittedData.campaignsAndPrograms = tfAmounts.remaining_committed || 0;

      const remainingCommittedAndPlannedData = cellData[ManageTableBudgetColumnName.CommittedAndPlanned];
      remainingCommittedAndPlannedData.campaignsAndPrograms =
        remainingCommittedData.campaignsAndPrograms + remainingPlannedData.campaignsAndPrograms;

      const availableData = cellData[ManageTableBudgetColumnName.Available];
      availableData.campaignsAndPrograms = tfAmounts.available;
    }
  );
}

export function getTotalsForTimeframes(
  timeframeIds: (string | number)[],
  grandTotal: Record<PresentationTimeframe, ManageTableTotalValues>
): ManageTableTotalValues {
  return timeframeIds
    .reduce((allAllocations, timeframeId) => {
      return sumObjectValues(allAllocations, grandTotal[timeframeId]);
    }, createAmountCegItem(null, v => v));
}

export function getAmountsForTimeframes(
  timeframeIds: (string | number)[],
  grandTotal: ManageCegTableRowAllocations
): ManageCegTableCellAllocations {
  return timeframeIds
    .reduce((allAllocations, timeframeId) => {
      return sumObjectValues(allAllocations, grandTotal[timeframeId]);
    }, createAmountCegItem(null, v => ({ ownAmount: v, campaignsAndPrograms: v, unallocated: v })));
}

export function sumObjectValues<T>(obj1: T, obj2: T): T {
  return Object.entries(obj1).reduce((store, [k, currentValue]) => {
    const valueToAdd = obj2 && obj2[k];
    const valueIsObject = typeof currentValue === 'object' && currentValue !== null;
    if (valueIsObject && !store[k]) {
      store[k] = {};
    }
    store[k] = valueToAdd ?
      valueIsObject ?
        sumObjectValues(currentValue, valueToAdd) :
        sumAmounts(currentValue, valueToAdd) :
      currentValue;

    return store;
  }, {} as T);
}


export function injectRemainingAmountsIntoAllocation(
  amounts: Record<string, AssumedAmountsDO> | Record<string, AmountsDO>,
  objectAllocations: CampaignAllocation[] | ProgramAllocation[]
): void {
  objectAllocations.forEach(allocation => {
    const tfAmounts = amounts[allocation.company_budget_alloc];

    allocation.amount = (tfAmounts as AssumedAmountsDO).amount || (tfAmounts as AmountsDO).budget || 0;
    allocation.source_amount = (tfAmounts as AssumedAmountsDO).source_amount || (tfAmounts as AmountsDO).budget || 0; // should be equal (but can be used for Goals)
    allocation.source_actual = tfAmounts.actual;
    allocation.source_remaining_committed = tfAmounts.remaining_committed || null;
    allocation.source_remaining_planned = tfAmounts.remaining_planned || null;
    allocation.available = tfAmounts.available;
  });
}
