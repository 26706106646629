import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import * as confetti from 'canvas-confetti';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[confettiAnimation]'
})
export class ConfettiDirective implements AfterViewInit, OnDestroy {
  destroy$ = new Subject<void>();
  @Input('confettiAnimation') trigger: Subject<void>;
  @Input() confettiCustomOptions: {
    particleCount?: number;
    spread?: number;
    angle?: number;
    startVelocity?: number;
    decay?: number;
    colors?: string[];
    scalar?: number;
  };
  @Input() width = 1000;
  @Input() height = 1000;
  // check https://www.npmjs.com/package/canvas-confetti for description and full options list
  defaultOptions = {
    particleCount: 150,
    startVelocity: 35,
    colors: [
      '#E82987FF',
      '#4894E1FF',
      '#4894E1FF',
      '#7C55FBFF',
      '#FF9901FF',
    ],
  }

  confetti;

  constructor(
    private el: ElementRef,
    private readonly zone: NgZone
  ) {
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      if (this.trigger) {
        this.confetti = this.initConfetti(this.el.nativeElement);
        this.trigger.pipe(
          takeUntil(this.destroy$)
        ).subscribe(() => {
          this.confetti({
            ...this.defaultOptions,
            ...this.confettiCustomOptions || {},
          });
        })
      }
    });
  }

  initConfetti(targetNode: HTMLElement) {
    if (getComputedStyle(targetNode).position === 'static') {
      targetNode.style.position = 'relative';
    }
    const canvas = ConfettiDirective.createCanvas(this.width, this.height);
    targetNode.prepend(canvas);
    return confetti.create(canvas);
  }

  static createCanvas(width: number, height: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    canvas.style.pointerEvents = 'none';
    canvas.style.position = 'absolute';
    canvas.style.top = '50%';
    canvas.style.left = '50%';
    canvas.style.transform = 'translate(-50%, -50%)';

    return canvas;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.confetti) {
      this.confetti.reset();
    }
  }
}
