import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { interval, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { TooltipContext } from '@shared/directives/dynamic-portal-tooltip.directive';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { formatNumber } from '@angular/common';
import { MetricFunnelsCalculationsService } from '@common-lib/lib/utils/metric-funnels-calculations.service';
import { MetricFunnelsValidationService } from '@common-lib/lib/utils/metric-funnels-validation.service';
import { MetricFunnelsPageService } from '../../services/metric-funnels-page.service';
import {
  BudgetCalculatorData,
  FunnelInputsType,
  FunnelStateDifferences, MetricFunnelRow,
  MetricMaster, MetricRowsDifferences, MetricValueType, OutlinedFieldType,
  ProductFunnelAction,
  ProductFunnelView,
  ProductMetricFunnel
} from '@common-lib/lib/corporate-page/metric-funnels.types';
import { createEmptyMetric, defaultCurrencyMetricName } from '@common-lib/lib/corporate-page/metric-funnels.helpers';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'product-funnel',
  templateUrl: './product-funnel.component.html',
  styleUrls: ['./product-funnel.component.scss'],
  animations: [
    trigger('scaleVertical', [
      transition(':enter', [
        style({ opacity: 0, maxHeight: 0 }),
        animate('300ms ease-out', style({ maxHeight: '63px' })),
        animate('250ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ opacity: 0 })),
        animate('200ms ease-out', style({ maxHeight: 0 }))
      ]),
    ])
  ]
})
export class ProductFunnelComponent implements OnChanges, OnDestroy {
  @Input() editMode: boolean;
  @Input() activeView: ProductFunnelView;
  @Input() disabled: boolean;
  @Input() panelsStates: Record<string, boolean>;

  @Output() setFunnelEditId = new EventEmitter<number>();
  @Output() saveData = new EventEmitter<Partial<FunnelStateDifferences>>();
  @Output() duplicateFunnel = new EventEmitter<void>();
  @Output() remove = new EventEmitter();
  @Output() moveItem = new EventEmitter<boolean>();
  @Output() expansionState = new EventEmitter<boolean>();
  @Output() setFunnelState = new EventEmitter<boolean>();
  @Output() setFunnelViewMode = new EventEmitter<ProductFunnelView>();
  @Output() openObjectsUsingMetric = new EventEmitter<MetricMaster>();

  public joinerTooltipPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
  };
  joinerTooltipContext: TooltipContext = {
    header: 'The revenue row',
    body: `This row is where count metric targets
     connect to currency target metrics.
     Changes to count targets cascade to
     this row, then get multiplied by Deals’
     Average Revenue Per Outcome to
     calculate Revenue’s Target and cascade
     from there.  Changes to currency
     metrics cascade to this row, then get
     divided by Deals’ ARPO to Deals’ target,
     then cascade up. `,
  }
  public freezeAnimations = true;
  public funnelValid;
  public newState: ProductMetricFunnel;
  private prevState: ProductMetricFunnel;
  public productFunnelView = ProductFunnelView;
  public metricValueType = MetricValueType;
  public funnelInputsType = FunnelInputsType;
  public outlinedFieldType = OutlinedFieldType;
  public productFunnelAction = ProductFunnelAction;

  public actions = [
    {
      id: ProductFunnelAction.EDIT,
      icon: ['fad', 'pencil-alt'],
      tooltip: 'Edit',
      cb: this.edit.bind(this),
    },
    {
      id: ProductFunnelAction.DUPLICATE,
      icon: ['fad', 'copy'],
      tooltip: 'Duplicate',
      cb: this.duplicate.bind(this),
    },
    {
      id: ProductFunnelAction.DOWNLOAD,
      icon: ['fad', 'file-download'],
      tooltip: 'Download as a slide',
      cb: this.download.bind(this),
    },
    {
      id: ProductFunnelAction.DELETE,
      icon: ['fad', 'trash-alt'],
      tooltip: 'Delete',
      cb: this.removeFunnel.bind(this),
    },
  ];

  public baseRowIndex: number;
  public numberFormatShort = '1.0-2';
  public numberFormatLong = '1.2-2';
  public defaultPrefix = '$';
  public funnelChanges = false;
  public funnelRowsChanges = false;
  public budgetCalculatorChanges = false;
  public focusedInput: FunnelInputsType;
  public focusedRow: number;
  public emptyFieldsOutline = {};
  public emptyFieldFocus: string;
  public checkMetricsValidity$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  public isDownloadInProgress: boolean;

  @Input() set funnel(funnel: ProductMetricFunnel) {
    this.prevState = createDeepCopy(funnel);
    this.newState = createDeepCopy(funnel);
    this.updateBaseRowIndex();
    this.updateJoinerTooltip();
    this.updateValidation();
  }

  constructor(
    public funnelsValidationService: MetricFunnelsValidationService,
    public metricFunnelsCalculationsService: MetricFunnelsCalculationsService,
    private readonly metricFunnelsPageService: MetricFunnelsPageService,
  ) {
    setTimeout(() => {
      this.freezeAnimations = false;
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editMode) {
      if (!changes.editMode.previousValue && changes.editMode.currentValue) {
        this.funnelsValidationService.updateFlatMetrics(this.newState.metricFunnelRows);
        this.metricFunnelsCalculationsService.calculateValues(this.newState.metricFunnelRows, this.baseRowIndex, null, null);
        const emptyParams = this.metricFunnelsCalculationsService.emptyParams;
        if (emptyParams) {
          this.processEmptyFields(emptyParams);
        }
        this.updateJoinerTooltip();
      }
      if (changes.editMode.previousValue && !changes.editMode.currentValue) {
        this.funnelsValidationService.resetFunnelErrors();
      }
    }
  }

  get currencySymbol(): string {
    return this.metricFunnelsPageService.currencySymbol;
  }

  processEmptyFields(emptyParams) {
    const fieldsMapped = this.getFieldsForOutlineAnimation(emptyParams.emptyConversionsRows, emptyParams.missingBaseRowData);
    const firstEmptyRate = OutlinedFieldType.CONVERSION + emptyParams.emptyConversionsRows[0];
    interval(500).pipe(
      take(fieldsMapped.length),
      finalize(() => {
        setTimeout(() => {
          this.emptyFieldsOutline[fieldsMapped[fieldsMapped.length - 1]] = 1;
          this.emptyFieldFocus = firstEmptyRate;
        }, 500)
      }),
      takeUntil(this.destroy$)
    ).subscribe(i => {
      if (i !== 0) {
        // reset state "2" for previous field
        this.emptyFieldsOutline[fieldsMapped[i - 1]] = 1;
      }
      this.emptyFieldsOutline[fieldsMapped[i]] = 2;
    })
  }

  getFieldsForOutlineAnimation(emptyConversionsRows, missingBaseRowData): string[] {
    const fields = [];
    emptyConversionsRows.forEach(rowIndex => {
      fields.push(OutlinedFieldType.CONVERSION + rowIndex);
    })

    if (missingBaseRowData) {
      const baseRow = this.newState.metricFunnelRows[this.baseRowIndex];
      if (!baseRow.countMetric.revenuePerOutcome) {
        fields.push(OutlinedFieldType.COUNT_ARPO);
      }
      if (!baseRow.currencyMetric.target) {
        fields.push(OutlinedFieldType.CURRENCY_TARGET);
      }
    }
    return fields;
  }

  updateValidation(): void {
    this.checkMetricsValidity$.next();
    this.funnelValid = this.funnelsValidationService.isFunnelValid();
  }

  updateBaseRowIndex() {
    this.baseRowIndex = this.newState.metricFunnelRows.findIndex(row => row.isBaseRow);
  }

  updateBudgetCalculatorData(calculatorData?: BudgetCalculatorData): void {
    this.newState.budgetCalculatorData = MetricFunnelsPageService.updateBudgetCalculatorData(calculatorData || this.newState.budgetCalculatorData, this.newState.metricFunnelRows);
    this.checkBudgetCalculatorDifferences();
  }

  updateMetricsRowIndex(rows: MetricFunnelRow[]) {
    rows.forEach((row, index) => {
      const countMetric = row.countMetric;
      const currencyMetric = row.currencyMetric;
      if (countMetric) {
        countMetric.rowIndex = index;
      }
      if (currencyMetric) {
        currencyMetric.rowIndex = index;
      }
    })
  }

  activateFunnelClick(e: MouseEvent) {
    e.stopPropagation();
    this.setFunnelState.emit(!this.newState.activated)
  }

  edit(e: MouseEvent) {
    e.stopPropagation();
    this.updateBaseRowIndex();
    this.setExpansionState(true);
    this.metricFunnelsCalculationsService.emptyParams = null;
    this.setFunnelEditId.emit(this.newState.id);
  }

  removeFunnel(e: MouseEvent) {
    e.stopPropagation();
    const hasUsageMetric = this.getProductMetricsCount(this.newState);
    this.metricFunnelsPageService.showProductConfirmDialog(hasUsageMetric, () => this.remove.emit());
  }

  setExpansionState(state: boolean) {
    this.expansionState.emit(state)
  }

  duplicate(e: MouseEvent): void {
    e.stopPropagation();
    this.duplicateFunnel.emit();
  }

  download(e: MouseEvent): void {
    e.stopPropagation();

    if (this.isDownloadInProgress) {
      return;
    }
    this.isDownloadInProgress = true;
    this.metricFunnelsPageService.downloadFunnel(this.newState.id, this.newState.metricFunnelRows, () => {
      this.isDownloadInProgress = false;
    });
  }

  save(e: MouseEvent): void {
    e.stopPropagation();
    if (!this.funnelsValidationService.isFunnelValid()) {
      return;
    }
    this.saveData.emit(this.getStateDifferences());
  }

  getStateDifferences(): Partial<FunnelStateDifferences> {
    const differences: Partial<FunnelStateDifferences> = {
      funnel: {},
      metrics: {},
      originalFunnel: this.newState,
    };
    if (this.funnelChanges) {
      differences.funnel.productName = this.newState.productName;
      differences.funnel.revenueToProfit = this.newState.revenueToProfit;
    }
    if (this.budgetCalculatorChanges) {
      differences.funnel.budgetCalculatorData = this.newState.budgetCalculatorData;
    }
    if (this.funnelRowsChanges) {
      differences.metrics = this.getMetricRowsDifferences();
    }
    return differences;
  }

  getMetricRowsDifferences(): MetricRowsDifferences {
    const rowDifferences: MetricRowsDifferences = {};
    const newMetricsList = this.funnelsValidationService.createFlatMetricsList(this.newState.metricFunnelRows);
    const prevMetricsList = this.funnelsValidationService.createFlatMetricsList(this.prevState.metricFunnelRows);
    const props = ['name', 'target', 'revenuePerOutcome', 'isHidden', 'rowIndex', 'conversionRate']
    rowDifferences.create = newMetricsList.filter(metric => metric.id === null);
    rowDifferences.update = newMetricsList
      .filter(metric => metric.id !== null)
      .filter(newMetricState => {
        const prevMetricState = prevMetricsList.find(prevStateMetric => prevStateMetric.id === newMetricState.id);
        return props.some(prop => newMetricState[prop] !== prevMetricState[prop])
      });
    rowDifferences.delete = prevMetricsList
      .filter(metric => !newMetricsList.find(newMetric => newMetric.id === metric.id))
      .map(metric => metric.id)

    return rowDifferences;
  }

  cancel(e: MouseEvent) {
    e.stopPropagation();
    this.newState = createDeepCopy(this.prevState);
    this.resetChangesFlags();
    this.setFunnelEditId.emit(null);
  }

  resetChangesFlags() {
    this.funnelChanges = false;
    this.funnelRowsChanges = false;
    this.budgetCalculatorChanges = false;
  }

  viewChanged(view: ProductFunnelView): void {
    this.setFunnelViewMode.next(view);
  }

  changeOrder(e: MouseEvent, moveUp: boolean) {
    e.stopPropagation();
    this.moveItem.emit(moveUp);
  }

  checkFunnelStatesDifferences(): void {
    const props = ['productName', 'revenueToProfit'];
    this.funnelChanges = props.some(propName => {
      return this.newState[propName] !== this.prevState[propName];
    });
  }

  checkBudgetCalculatorDifferences(): void {
    const emptyData = !this.newState.budgetCalculatorData && !this.prevState.budgetCalculatorData;
    const removed = !this.newState.budgetCalculatorData && this.prevState.budgetCalculatorData;
    const newlyCreated = this.newState.budgetCalculatorData && !this.prevState.budgetCalculatorData;
    if (emptyData || removed || newlyCreated) {
      this.budgetCalculatorChanges = !emptyData;
      return;
    }
    this.budgetCalculatorChanges = Object.entries(this.newState.budgetCalculatorData)
      .some(([propName, propValue]) => this.prevState.budgetCalculatorData[propName] !== propValue);
  }

  checkFunnelRowsStatesDifferences(): void {
    this.funnelRowsChanges = JSON.stringify(this.newState.metricFunnelRows) !== JSON.stringify(this.prevState.metricFunnelRows);
  }

  funnelNameChanged(value: string) {
    this.newState.productName = value;
    this.checkFunnelStatesDifferences();
    this.updateValidation();
  }

  revenueToProfitChanged(value: number) {
    this.newState.revenueToProfit = value;
    this.checkFunnelStatesDifferences();
  }

  conversionRateChanged(value: number, row: MetricFunnelRow, rowIndex: number) {
    if (value && this.emptyFieldsOutline[OutlinedFieldType.CONVERSION + rowIndex]) {
      this.emptyFieldsOutline[OutlinedFieldType.CONVERSION + rowIndex] = null;
    }
    this.metricFunnelsCalculationsService.setRowConversion(row, value);
    this.metricFunnelsCalculationsService
      .afterConversionChanged(this.newState.metricFunnelRows, rowIndex, this.baseRowIndex);
    this.updateBudgetCalculatorData();
    this.checkFunnelRowsStatesDifferences();
  }

  baseRevenuePerOutcomeChanged(value: number, baseRow: MetricFunnelRow) {
    if (value && this.emptyFieldsOutline[OutlinedFieldType.COUNT_ARPO]) {
      this.emptyFieldsOutline[OutlinedFieldType.COUNT_ARPO] = null;
    }
    baseRow.countMetric.revenuePerOutcome = value;
    const currencyTarget = baseRow.currencyMetric.target;
    const countTarget = baseRow.countMetric.target;
    if (countTarget && !currencyTarget) {
      // case for empty funnels
      this.metricFunnelsCalculationsService.recalculateBaseCurrencyTarget(baseRow);
      if (value && this.emptyFieldsOutline[OutlinedFieldType.CURRENCY_TARGET]) {
        this.emptyFieldsOutline[OutlinedFieldType.CURRENCY_TARGET] = null;
      }
    } else if (currencyTarget) {
      // case for editing existing values
      this.metricFunnelsCalculationsService.recalculateBaseCountTarget(baseRow);
    }
    this.metricFunnelsCalculationsService.calculateValues(this.newState.metricFunnelRows, this.baseRowIndex, null, null);
    this.updateJoinerTooltip();
    this.updateBudgetCalculatorData();
    this.checkFunnelRowsStatesDifferences();
  }

  nameChanged(name: string, metric: MetricMaster) {
    metric.name = name;
    this.checkFunnelRowsStatesDifferences();
    this.updateValidation();
  }

  targetChanged(target: number, metric: MetricMaster, row: MetricFunnelRow, rowIndex: number) {
    metric.target = target;
    const oppositeMetric = row[this.getMetricKeyByType(metric.valueType, true)];

    if (rowIndex === this.baseRowIndex) {
      if (metric.valueType === MetricValueType.Count) {
        oppositeMetric.target = this.metricFunnelsCalculationsService
          .formattedNumber(metric.target * metric.revenuePerOutcome)
      } else {
        if (target && this.emptyFieldsOutline[OutlinedFieldType.CURRENCY_TARGET]) {
          this.emptyFieldsOutline[OutlinedFieldType.CURRENCY_TARGET] = null;
        }
        oppositeMetric.target = this.metricFunnelsCalculationsService
          .formattedNumber(metric.target / oppositeMetric.revenuePerOutcome)
      }
    }

    this.metricFunnelsCalculationsService
      .calculateValues(this.newState.metricFunnelRows, this.baseRowIndex, rowIndex, metric.valueType);
    this.updateJoinerTooltip();
    this.updateBudgetCalculatorData();
    this.checkFunnelRowsStatesDifferences();
  }

  updateJoinerTooltip() {
    const baseRow = this.newState.metricFunnelRows[this.baseRowIndex];
    const countMetric = baseRow?.countMetric;
    const currencyMetric = baseRow?.currencyMetric;
    if (!countMetric?.target || !countMetric?.revenuePerOutcome || !currencyMetric?.target) {
      return;
    }
    const revenuePerOutcome = formatNumber(countMetric.revenuePerOutcome, 'en-US', this.numberFormatLong);
    const currencyTarget = formatNumber(currencyMetric.target, 'en-US', this.numberFormatLong);
    this.joinerTooltipContext.footer = `${countMetric.target} × $${revenuePerOutcome} = $${currencyTarget}`;
  }

  metricErrorStateChanged(hasError: boolean, metric: MetricMaster) {
    this.funnelsValidationService.setMetricNameErrorState(hasError, metric)
  }

  highlightFromConversion(rowInd: number): boolean {
    if (this.focusedInput !== FunnelInputsType.Conversion || this.focusedRow === null) {
      return false;
    }
    if (this.focusedRow < this.baseRowIndex) {
      return rowInd < this.focusedRow + 1;
    } else {
      return rowInd > this.focusedRow;
    }
  }

  getMetricOutlineStyle(rowInd: number, metricType: MetricValueType): number {
    if (rowInd !== this.baseRowIndex) {
      return null;
    }
    if (metricType === MetricValueType.Currency && this.emptyFieldsOutline[OutlinedFieldType.CURRENCY_TARGET]) {
      return this.emptyFieldsOutline[OutlinedFieldType.CURRENCY_TARGET];
    }
    return null;
  }

  inputFocusChanged(isFocused: boolean, inputType: FunnelInputsType, index: number) {
    if (isFocused) {
      this.focusedInput = inputType;
      this.focusedRow = index;
    } else {
      this.focusedInput = null;
      this.focusedRow = null;
    }
  }

  deactivateMetric(metric: MetricMaster) {
    metric.isHidden = !metric.isHidden;
    this.checkFunnelRowsStatesDifferences();
  }

  onDeleteMetricClick(metric: MetricMaster) {
    const isUsed = !!metric.usageCount;
    if (isUsed) {
      this.metricFunnelsPageService.showMetricConfirmDialog(isUsed, () => this.deleteMetric(metric));
      return;
    }
    this.deleteMetric(metric);
  }

  deleteMetric(metric: MetricMaster) {
    const metricKey = this.getMetricKeyByType(metric.valueType);
    const oppositeMetricKey = this.getMetricKeyByType(metric.valueType, true);
    const rows = this.newState.metricFunnelRows;
    const targetRow = rows.find(row => row[metricKey] === metric);
    if (!targetRow[oppositeMetricKey]) {
      this.newState.metricFunnelRows = rows.filter(row => row !== targetRow);
      this.updateMetricsRowIndex(this.newState.metricFunnelRows);
      this.updateBaseRowIndex();
    } else {
      targetRow[metricKey] = null;
    }
    this.metricFunnelsCalculationsService.calculateValues(this.newState.metricFunnelRows, this.baseRowIndex, null, null);
    this.updateJoinerTooltip();
    this.checkFunnelRowsStatesDifferences();
    this.funnelsValidationService.updateFlatMetrics(this.newState.metricFunnelRows);
    this.updateValidation();
    this.updateBudgetCalculatorData();
  }

  addNewMetric(parentRow: MetricFunnelRow, metricType: MetricValueType, rowIndex: number) {
    const metricName = defaultCurrencyMetricName(rowIndex, this.baseRowIndex);
    const newMetric = createEmptyMetric(metricType, rowIndex, parentRow.conversionRate, null, metricName);
    this.runWithoutAnimation(() => {
      parentRow[this.getMetricKeyByType(metricType)] = newMetric;
    });
    this.funnelsValidationService.pushNewMetric(newMetric);

    setTimeout(() => {
      this.metricFunnelsCalculationsService
        .calculateValues(this.newState.metricFunnelRows, this.baseRowIndex, null, null);
      this.updateJoinerTooltip();
      this.updateValidation();
      this.updateBudgetCalculatorData();
    }, 0)
  }

  addNewRow(index: number, metricType: MetricValueType) {
    const newRow: MetricFunnelRow = {
      conversionRate: 100,
    };
    this.addNewMetric(newRow, metricType, index);
    this.newState.metricFunnelRows.splice(index, 0, newRow);
    this.updateMetricsRowIndex(this.newState.metricFunnelRows);
    this.updateBaseRowIndex();
  }

  runWithoutAnimation(fn: () => void) {
    this.freezeAnimations = true;
    fn();
    setTimeout(() => {
      this.freezeAnimations = false;
    }, 500)
  }

  getMetricKeyByType(metricType: MetricValueType, inverse = false) {
    return this.metricFunnelsCalculationsService.getMetricKeyByType(metricType, inverse);
  }

  getProductMetricsCount(product: ProductMetricFunnel): boolean {
    return product.metricFunnelRows.some(row => row.countMetric?.usageCount || row.currencyMetric?.usageCount)
  }

  getOrderAttr(i: number): number {
    return this.newState.metricFunnelRows.length - (i + 1)
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
