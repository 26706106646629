import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getRequestOptions } from '../../utils/http-request.utils';
import { Task, TaskDO, TaskStatus, TaskStatusName } from '../../types/task.interface';
import { createDateString } from '../../../budget-object-details/components/containers/campaign-details/date-operations';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

@Injectable({ providedIn: 'root' })
export class TasksService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  private readonly apiPaths = {
    task: 'task'
  };

  public static isTaskOpen(task: Task): boolean {
    if (!task || !task.status) {
      return false;
    }

    return [
      TaskStatusName.LATE,
      TaskStatusName.BLOCKED,
      TaskStatusName.IN_PROGRESS
    ].includes(task.status.name);
  }

  public static convertToDataObject(task: Task): TaskDO {
    const taskDO = {
      name: task.name,
      owner: task.owner && task.owner.id,
      status: task.status && task.status.name,
      due_date: createDateString(task.dueDate)
    } as TaskDO;
    if (task.id) {
      taskDO.id = task.id;
    }

    return taskDO;
  }

  /**
   * A list of task statuses with 'constant' IDs
   * needed for generic dropdown component's usage
   * Backend API uses string enum only
   */
  public getStatusList(): TaskStatus[] {
    return [
      {
        id: 1,
        name: TaskStatusName.IN_PROGRESS
      },
      {
        id: 2,
        name: TaskStatusName.COMPLETED
      },
      {
        id: 3,
        name: TaskStatusName.BLOCKED
      },
      {
        id: 4,
        name: TaskStatusName.LATE
      },
      {
        id: 5,
        name: TaskStatusName.POSTPONED
      },
      {
        id: 6,
        name: TaskStatusName.CANCELED
      }
    ];
  }

  public getTasks(companyId: number, params = null): Observable<TaskDO[]> {
    return this.http.get<TaskDO[]>(
      `${this.apiV2Url}${this.apiPaths.task}/`,
      getRequestOptions({ company: companyId, ...params })
    );
  }

  public createTask(data: Partial<TaskDO>): Observable<TaskDO> {
    return this.http.post<TaskDO>(`${this.apiV2Url}${this.apiPaths.task}/`, data);
  }

  public getTask(taskId: number): Observable<TaskDO> {
    return this.http.get<TaskDO>(`${this.apiV2Url}${this.apiPaths.task}/${taskId}/`);
  }

  public updateTask(taskId: number, data: Partial<TaskDO>): Observable<TaskDO> {
    return this.http.patch<TaskDO>(`${this.apiV2Url}${this.apiPaths.task}/${taskId}/`, data);
  }

  public deleteTask(taskId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiV2Url}${this.apiPaths.task}/${taskId}/`);
  }
}
