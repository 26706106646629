<tooltip-container>
  <div class="budget-amounts-summary"
       [style.width]="containerWidth + 'px'"
       [class.no-expenses]="data?.values?.expenses <= 0 && !data.cegFlagEnabled">
    <div class="summary-title">{{ data?.title }}</div>
    <div class="summary-row allocated" [ngClass]="{'allocated-ceg': data.cegFlagEnabled}">
      <div class="summary-name">{{ data.isBudgetView || data.cegFlagEnabled ? 'Budgeted' : 'Allocated' }} ({{ data?.currency | uppercase }})</div>
      <div class="summary-value">{{ data?.values?.total || 0 | number: decimalPipeFormat }}</div>
    </div>

    <div class="summary-group expenses" [ngClass]="{'summary-group-ceg': data.cegFlagEnabled}">
      <div class="summary-row" *ngIf="data?.values?.expenses > 0 && !data.cegFlagEnabled">
        <div class="summary-name bold-text">EXPENSES</div>
      </div>
      <div class="summary-row planned" *ngIf="data?.values?.planned > 0">
        <div class="summary-name">
          <budget-status-icon [status]="configuration.statusFields.planned" [size]="data.cegFlagEnabled ? 10 : 14"></budget-status-icon>
         <span *ngIf="data.cegFlagEnabled && data.isBudgetView">Rmng &nbsp;</span> Planned
        </div>
        <div class="summary-value">{{ data?.values?.planned | number: decimalPipeFormat }}</div>
      </div>
      <div class="summary-row committed" *ngIf="data?.values?.committed > 0">
        <div class="summary-name">
          <budget-status-icon [status]="configuration.statusFields.committed" [size]="data.cegFlagEnabled ? 10 : 14"></budget-status-icon>
          <span *ngIf="data.cegFlagEnabled && data.isBudgetView">Rmng &nbsp;</span> Committed
        </div>
        <div class="summary-value">{{ data?.values?.committed | number: decimalPipeFormat }}</div>
      </div>
      <div class="summary-row closed" *ngIf="data?.values?.closed > 0 && !data.cegFlagEnabled">
        <div class="summary-name">
          <budget-status-icon [status]="configuration.statusFields.closed"></budget-status-icon>
          Closed
        </div>
        <div class="summary-value">{{ data?.values?.closed | number: decimalPipeFormat }}</div>
      </div>
      <div class="summary-row closed" *ngIf="data?.values?.actual > 0 && data.cegFlagEnabled">
        <div class="summary-name">
          <budget-status-icon [status]="configuration.statusFields.actual" [size]="10"></budget-status-icon>
          Actual
        </div>
        <div class="summary-value">{{ data?.values?.actual | number: decimalPipeFormat }}</div>
      </div>
      <div class="summary-row total-expenses" *ngIf="data?.values?.expenses > 0 && !data.cegFlagEnabled">
        <div class="summary-value">{{ data?.values?.expenses | number: decimalPipeFormat }}</div>
      </div>
    </div>

    <div class="summary-group total" *ngIf="!data.cegFlagEnabled else totalCeg">
      <div class="summary-row under-budget" *ngIf="data?.values?.underBudget > 0" [ngClass]="{'budget-view': data.isBudgetView}">
        <div class="summary-name bold-text">{{ data.isBudgetView ? 'Under Budget' : 'Released Allocation' }}</div>
        <div class="summary-value bold-text">{{ data?.values?.underBudget | number: decimalPipeFormat }}</div>
      </div>

      <div class="summary-row overspend" *ngIf="data?.values?.overSpend > 0; else remaining">
        <div class="summary-name bold-text">
          <icon-flame [hasProblem]="true" class="mr-05"></icon-flame>
          {{ data.isBudgetView ? 'Over Budget' : 'Overspend' }}
        </div>
        <div class="summary-value bold-text">{{ data?.values?.overSpend | number: decimalPipeFormat }}</div>
      </div>

      <ng-template #remaining>
        <div class="summary-row remaining-allocated" *ngIf="data?.values?.remainingAllocated !== null">
          <div class="summary-name bold-text">Remaining {{ data.isBudgetView ? 'Budget' : 'Allocated' }}</div>
          <div class="summary-value bold-text">{{ data?.values?.remainingAllocated || 0 | number: decimalPipeFormat }}</div>
        </div>
      </ng-template>
    </div>
    <ng-template #totalCeg>
      <div class="summary-group total total-ceg">
        <div class="summary-row remaining-allocated">
          <div class="summary-name bold-text">Available</div>
          <div class="summary-value bold-text" *ngIf="data?.values?.remainingAllocated > 0 else underBudgetTotal">{{ data?.values?.remainingAllocated || 0 | number: decimalPipeFormat }}</div>
          <ng-template #underBudgetTotal>
            <div class="summary-value bold-text overspend" >({{ data?.values?.overSpend || 0 | number: decimalPipeFormat }})</div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </div>
</tooltip-container>
