import { MetricIntegrationName } from '../../metric-integrations/types/metric-integration';
import { BulkUpdateDialogType, DialogContext } from '@shared/types/dialog-context.interface';
import { AutocompleteItem } from '@shared/types/autocomplete-item.type';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { ExpenseDO } from '@shared/types/expense.interface';
import { TagMapping } from '@shared/types/tag-mapping.interface';
import { Observable } from 'rxjs';

export interface ActionHandlingContext {
  selectedExpenses: ExpenseDO[];
  totalExpenseIds?: number[];
  toastMessage?: string;
  undoPayloads?: Record<string, any>[];
}

export interface DeleteActionContext extends ActionHandlingContext {
  deleteExpenseHandlersData?: DeleteExpenseHandlersData;
}

export interface SingleSelectActionContext extends ActionHandlingContext {
  selectedOption: ActionSelectOption;
}

export interface TagsActionContext extends ActionHandlingContext {
  companyId: number;
  tagMappings: TagMapping[];
}

export interface TextValueActionContext extends ActionHandlingContext {
  textValue: string;
}

export interface ExpensesTimeframeOrder {
  expenseId: number;
  orderId: number;
}

export type IntegratedExpensesData = Record<MetricIntegrationName, Record<string, ExpensesTimeframeOrder[]>>;

export interface DeleteExpenseHandlersData {
  integratedExpenses: IntegratedExpensesData;
  groupWithIntegratedExp: boolean;
}

export enum ExpenseActionType {
  MoveToBudget = 'moveToBudget',
  ChangeStatus = 'changeStatus',
  ChangeType = 'changeType',
  ChangeOwner = 'changeOwner',
  ChangeSegment = 'changeSegment',
  ChangeTimeframe = 'changeTimeframe',
  ChangeParent = 'changeParent',
  AddTag = 'addTag',
  AddPONumber = 'addPONumber',
  AddGLCode = 'addGLCode',
  AddVendor = 'addVendor',
  RemoveTag = 'removeTag',
  CreateExpenseGroup = 'createExpenseGroup',
  Delete = 'delete',
}

export enum ExpenseActionHandlingType {
  SingleSelect = 'singleSelect',
  DirectAction = 'directAction',
  TextValue = 'textValue',
}

export interface ActionSelectOptionContext {
  customUpdateProps?: Record<string, any>;
  extraProps?: Record<string, any>;
}

export interface ActionSelectOption {
  value?: number | string;
  title?: string;
  context?: ActionSelectOptionContext;
}

export interface ExpenseActionConfirmation {
  title: string;
  message: string;
  isActionForbidden?: boolean;
}

export interface ExpenseActionValueUpdate {
  title: string;
  type: BulkUpdateDialogType;
  autocompleteItems?: AutocompleteItem[];
}

interface ExpenseActionHandlingConfig {
  handlingType: ExpenseActionHandlingType;
  selectOptions?: ActionSelectOption[];
  hierarchyOptions?: HierarchySelectItem[];
  updatePropName?: keyof ExpenseDO | 'parent' | 'tag_ids';
  getConfirmationData?: (action: ExpenseAction, context: ActionHandlingContext) => ExpenseActionConfirmation;
}

export interface ExpenseAction {
  name: string;
  disabled?: boolean;
  hidden?: boolean;
  actionType: ExpenseActionType;
  handlingConfig: ExpenseActionHandlingConfig;
  checkHidden?: (expense: ExpenseDO, timeframeId?: number) => boolean;
  executeAction: (action: ExpenseAction, context: ActionHandlingContext) => Observable<any>;
  customCssClass?: string;
}

export interface MoveToBudgetDialogContext extends DialogContext {
  selectItems: ActionSelectOption[];
}
