import { Injectable } from "@angular/core";
import { ExpenseTotalsDO } from "@shared/types/expense.interface";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class CustomFieldFiltersSummaryService {

    public defaultExpenseFilteredTotal: ExpenseTotalsDO = {
        total_amount: 0,
        total_count: 0,
        total_count_with_pseudo_objects: 0,
    } 
    public expenseCustomFieldFiltersSummaryTotal = new BehaviorSubject<ExpenseTotalsDO>(this.defaultExpenseFilteredTotal);
    public expenseCustomFieldFiltersSummaryTotal$ = this.expenseCustomFieldFiltersSummaryTotal.asObservable();

    
  public getExpenseCustomFieldFiltersSummaryTotal(): ExpenseTotalsDO {
    return this.expenseCustomFieldFiltersSummaryTotal.getValue();
  }

  public resetSummaryTotal(): void {
    this.expenseCustomFieldFiltersSummaryTotal.next(this.defaultExpenseFilteredTotal);
  }

}