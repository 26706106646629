<header mat-dialog-title>
  Pick timeframes for additional related expenses
</header>
<div
  mat-dialog-content
  class="dialog-info"
  [ngClass]="{'quarter-view': !context.showLegend}"
>
  <timeframe-select
    [showLegend]="context.showLegend"
    [timeframes]="context.timeframes"
    [selectedTimeframes]="selectedTimeframes"
    (selectionChanged)="onTfSelectionChange($event.isChecked, $event.item?.id, $event.item?.selected)"
  ></timeframe-select>
  <mat-checkbox
    class="select-all-btn"
    color="primary"
    [checked]="isAllSelected"
    (change)="selectionAllChanged($event.checked)"
  >
    {{ isAllSelected ? 'Deselect' : 'Select' }} all
  </mat-checkbox>
</div>

<div mat-dialog-actions class="dialog-actions">
  <button
    (click)="dialogRef.close()"
    mat-button
    color="primary"
  >Cancel
  </button>
  <button
    (click)="dialogRef.close(selectedTimeframes)"
    mat-flat-button
    color="primary"
    [disabled]="!selectedTimeframes.length"
  >Create
  </button>
</div>
