import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { combineLatest, forkJoin, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay as delayStream, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TimeZone } from '@vvo/tzdb';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { ExtendedDialogClassName } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { DIALOG_ACTION_TYPE } from 'app/shared/types/dialog-context.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { getTimezoneList } from 'app/shared/utils/timezone.utils';
import { Configuration } from 'app/app.constants';
import { AdsIntegrations, MetricIntegrationDisplayName, MetricIntegrationName } from '../../types/metric-integration';
import { IntegrationScheduleSettings } from '../../types/integration-schedule-settings.interface';
import { IntegrationSetupDialogData } from '../../types/integration-setup-dialog-data.interface';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { Budget, BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { getMonthNameFromDate, getStartDateOfCurrentTimeframe } from 'app/shared/utils/date.utils';
import {
  importStart,
  IntegrateCompanyResponse,
  Integration,
  IntegrationAccount,
  IntegrationData
} from '../../types/metrics-provider-data-service.types';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { IntegrationSyncProgressService } from 'app/metric-integrations/services/integration-sync-process.service';
import { IntegrationSetupProviderService } from '../../services/integration-setup-provider.service';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';
import { HierarchySelectConfig, HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SegmentGroup } from '@shared/types/segment-group.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { Campaign, LightCampaign } from '@shared/types/campaign.interface';
import { SegmentMenuHierarchyService } from '@shared/services/segment-menu-hierarchy.service';
import { findItemInHierarchy } from '@shared/components/hierarchy-select/hierarchy-select.utils';
import { MetricsProviderWithImplicitMappingsDataService } from 'app/metric-integrations/services/metrics-provider-with-implicit-mappings-data.service';
import { IntegrationStateService } from './integration-state.service';

const ERROR_MESSAGE = {
  FAILED_TO_UPDATE_AUTH_SETTINGS: 'Failed to update Auth settings',
  FAILED_TO_UPDATE_TIME_SETTINGS: 'Failed to update time settings',
  FAILED_TO_PARSE_TIME: 'Failed to parse selected time'
};

const CHECK_INTEGRATION_AUTH_PERIOD_MS = 2000;

interface EnableIntegrationData extends Partial<IntegrationData> {
  cronSettings: IntegrationScheduleSettings
}

@Component({
  selector: 'integration-setup-dialog',
  templateUrl: './integration-setup-dialog.component.html',
  styleUrls: ['./integration-setup-dialog.component.scss']
})
export class IntegrationSetupDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly PLANNUH_INTEGRATION_ORIGIN_PATTERN = /(hubspot|salesforce|google-ads|linkedin|facebook)(-\w*)?(\.\w*)*\.plannuh|.planful\.com/;
  public readonly companyId: number = null;

  public timezones: TimeZone[] = [];
  public integrationEnabled = false;
  public integrationSource: MetricIntegrationName;
  public integration: Integration;
  public isAdsIntegration: boolean;
  public integrationLabel: string;
  public isLoading = true;
  public integrationScheduleSettings: IntegrationScheduleSettings;
  public MetricIntegrationName = MetricIntegrationName;
  public integrationEntityName: UntypedFormControl;
  public integrationImportStart: UntypedFormControl;
  public integrationCampaignsGrouping: UntypedFormControl;
  public integrationAccounts: IntegrationAccount[];
  public availableIntegrationAccounts: IntegrationAccount[];
  public selectedAccountsControl: UntypedFormControl;
  public segmentControl: FormControl;
  public hierarchyItems: HierarchySelectItem[];
  public selectSegmentsConfig: HierarchySelectConfig = {
    fieldAppearance: 'outline',
    fieldLabel: null,
    withSearch: true,
    searchPlaceholder: 'Search Location',
    allGroups: false,
  };
  private emptySegmentValue = { id: null, title: 'No Parent' };
  public selectedAccountIds: string[];
  public hasSelectedAccountIds: boolean;
  public selectedAccountNames: string[];
  public allAccountsSelectionState: CheckboxValue;
  public CheckboxValue = CheckboxValue;
  public warningMessage: string = null;
  public MAX_NAME_LENGTH = 20;
  public integrationAccountEmail: string;
  public integrationInitialBudgetId: number;
  public selectedBudget: Budget;
  public importStart = importStart;
  public disableCurrentTimeframeIntegration: boolean;
  public importDataFrom: string;
  public integrationActive = false;
  public waitingForIntegrationAuth = false;
  private integrationId = '';

  public hsWarning = 'HubSpot requires Super Admin permissions in order to enable this integration.';
  public severalAccountsWarning = 'Several accounts found. Please, specify which ones are required for the integration.';
  public amountsSyncTooltip = 'Campaign budgets are based on your daily spend limit × the number of days in a month.';
  public syncAllocatedAmounts = false;
  private readonly segmentMenuService = inject(SegmentMenuHierarchyService);
  public isReauthRequired : boolean;
  public isProcessing = false;

  constructor(
    private readonly dialogRef: MatDialogRef<IntegrationSetupDialogComponent>,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly companyDataService: CompanyDataService,
    private readonly utilityService: UtilityService,
    private readonly configuration: Configuration,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) data: IntegrationSetupDialogData,
    private readonly budgetDataService: BudgetDataService,
    private readonly integrationSyncProcessService: IntegrationSyncProgressService,
    private readonly integrationSetupProvider: IntegrationSetupProviderService,
    private readonly integrationStateService : IntegrationStateService
  ) {
    this.integration = data.integration;
    this.integrationId = data.integration?.integrationId;
    this.integrationSource = data.integrationSource;
    this.companyId = data.companyId;

    this.integrationSetupProvider.initProvider(this.integrationSource);
    this.isAdsIntegration = AdsIntegrations.includes(this.integrationSource);
    this.integrationLabel = MetricIntegrationDisplayName[this.integrationSource];
  }

  ngOnInit(): void {
    this.getIntegrationData(this.integration);
    this.isReauthRequired = this.integration?.data?.reauthRequired;
    const integrationData = this.integration?.data;

    if (!this.integrationEnabled && this.integrationSource === MetricIntegrationName.Hubspot) {
      this.warningMessage = this.hsWarning;
    }

    if (integrationData && this.isAdsIntegration) {
      this.createOptionalControls(integrationData);
      this.syncAllocatedAmounts = !!integrationData.syncAllocatedAmounts;
      this.integrationAccountEmail = integrationData.email;
      this.integrationInitialBudgetId = integrationData.budgetId;
      this.setAccountData(integrationData);

      const currentBudgetSnapshot = this.budgetDataService.selectedBudgetSnapshot;
      // empty selectedBudgetSnapshot means that Company was changed recently, and we are waiting for the new Selected Budget
      const budget$ = !!currentBudgetSnapshot ?
        of(currentBudgetSnapshot) :
        this.budgetDataService.selectedBudget$.pipe(
          filter(budget => !!budget),
          takeUntil(this.destroy$),
        );

      budget$.subscribe(budget => this.onBudgetLoaded(budget));
    }

    this.integrationSyncProcessService.activeProcess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(activeIntegrations => {
        this.integrationActive = this.integrationId in activeIntegrations;
      });

    this.integrationSyncProcessService.reauthProcess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
          this.isReauthRequired = false;
      });
    
    this.integrationStateService.getIsProcessing(this.integrationId).subscribe(isProcessing => {
      this.isProcessing = isProcessing;
    });
  }

  private isAdAccountUsed(adAccountId: string): boolean {
    const integrations = this.companyDataService.metricIntegrationsValue[this.integrationSource];
    return integrations?.length > 0 &&
      integrations.some(integration => integration.data?.selectedAccountIds?.includes(adAccountId));
  }

  private setAccountData(integrationData: IntegrationData) {
    this.integrationAccounts = integrationData.accounts;
    this.availableIntegrationAccounts = (integrationData.accounts || []).filter(account => !this.isAdAccountUsed(account.id));
    this.selectedAccountIds = integrationData.selectedAccountIds;
    this.hasSelectedAccountIds = this.selectedAccountIds?.length > 0;
    this.selectedAccountNames = this.getSelectedAccountNames();
    this.selectedAccountsControl = new UntypedFormControl(this.selectedAccountIds, { validators: [Validators.required] });
    if (this.integrationAccounts?.length > 0 && !this.hasSelectedAccountIds) {
      this.warningMessage = this.severalAccountsWarning;
    }
  }

  private createSegmentControl(): void {
    const integrationData = this.integration.data;
    let selectedItemId = null;
    if (integrationData.segmentData) {
      selectedItemId = integrationData.segmentData;
    } else if (integrationData.parentCampaignId) {
      selectedItemId = this.configuration.OBJECT_TYPES.campaign + '_' + integrationData.parentCampaignId;
    }
    const selectedItem = findItemInHierarchy(selectedItemId, this.hierarchyItems);
    this.segmentControl = new FormControl(selectedItem || this.emptySegmentValue);
  }

  private setHierarchyItems(
    campaigns: Campaign[] | LightCampaign[],
    segmentGroups: SegmentGroup[],
    segments: BudgetSegmentAccess[],
    sharedCostRules: SharedCostRule[]
  ) {
    this.hierarchyItems = this.segmentMenuService.prepareDataForSegmentMenu({
      segments,
      groups: segmentGroups,
      rules: sharedCostRules,
      campaigns: campaigns.filter(camp => !camp.parentCampaign),
    });
    this.hierarchyItems.forEach(segmentItem => {
      if (segmentItem.objectType === this.configuration.OBJECT_TYPES.segmentsGroup) {
        segmentItem.notClickable = true;
      }
    });
    this.hierarchyItems.unshift( this.emptySegmentValue);
  }

  private getSelectedAccountNames() {
    return this.selectedAccountIds?.map(
      accountId => (this.integrationAccounts || []).find(account => account.id === accountId)?.name
    ).filter(name => name);
  }

  onBudgetLoaded(budget: Budget) {
    this.selectedBudget = budget;
    this.importDataFrom = getMonthNameFromDate(this.integration.data?.importDataFrom);
    this.disableCurrentTimeframeIntegration = parseDateString(this.selectedBudget.budget_to) < new Date();
    if (this.disableCurrentTimeframeIntegration) {
      this.integrationImportStart.setValue(importStart.BUDGET);
    }
    this.loadBudgetRelatedObjects();
  }

  private loadBudgetRelatedObjects() {
    const campaignsSnapshot = this.budgetDataService.lightCampaignsSnapshot || this.budgetDataService.campaignsSnapshot;
    let campaigns$ = campaignsSnapshot ?
      of(campaignsSnapshot) :
      merge(this.budgetDataService.lightCampaignList$, this.budgetDataService.campaignList$);

    if(this.budgetDataService.isCurrentBudgetWithNewCEGStructure){
      campaigns$ = campaigns$.pipe(
        map(campaigns => JSON.parse(JSON.stringify(campaigns))), // Deep copy using JSON methods
        map(camps =>  camps.filter(camp => !camp.campaign_integrated_source))
      );
    }

    const segments$ = this.budgetDataService.segmentsSnapshot ?
      of(this.budgetDataService.segmentsSnapshot) :
      this.budgetDataService.segmentList$;
    const segmentGroups$ = this.budgetDataService.segmentGroupsSnapshot ?
      of(this.budgetDataService.segmentGroupsSnapshot) :
      this.budgetDataService.segmentGroupList$;
    const sharedCostRules$ = this.budgetDataService.sharedCostRulesSnapshot ?
      of(this.budgetDataService.sharedCostRulesSnapshot) :
      this.budgetDataService.sharedCostRuleList$;

    combineLatest([campaigns$, segmentGroups$, segments$, sharedCostRules$]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.setHierarchyItems(...data);
      this.createSegmentControl();
    });
  }

  private createOptionalControls(authData: IntegrationData) {
    this.integrationEntityName = new UntypedFormControl(authData.name, { validators: [Validators.required] });
    this.integrationImportStart = new UntypedFormControl(authData.importDataFrom ? importStart.CURRENT_TF : importStart.BUDGET);

    if (this.isLinkedinIntegration) {
      this.integrationCampaignsGrouping = new UntypedFormControl(
        {
          value: typeof authData.groupCampaigns !== 'boolean' ? true : authData.groupCampaigns,
          disabled: this.integrationEnabled,
        }
      );
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.integrationHandler);
    window.removeEventListener('message', this.integrationRefreshHandler);
    this.destroy$.next();
    this.destroy$.complete();
  }

  private navigateOnSuccessfulIntegration() {
    this.router.navigate([
      `${this.configuration.ROUTING_CONSTANTS.INTEGRATION_SETTINGS}/${this.integrationSource}`
    ]);
  }

  private getIntegrationData(integration: Integration) {
    this.timezones = getTimezoneList();
    const integrationId = integration?.integrationId;
    this.integrationEnabled = !!integrationId;

    this.integrationSetupProvider.getScheduleSettings(this.companyId, integrationId)
      .subscribe(
        (settings) => {
          this.integrationScheduleSettings = settings;
          this.hideLoader();
        }
      );
  }

  private hideLoader(delay = 250) {
    setTimeout(() => {
      this.isLoading = false;
    }, delay)
  }

  private integrationHandler = (event: MessageEvent) => {
    window.removeEventListener('message', this.integrationHandler);

    if (!this.PLANNUH_INTEGRATION_ORIGIN_PATTERN.test(event.origin)) {
      console.log('[IntegrationSetupDialogComponent]: integrationHandler() - skipping event from unsupported origin ' + event.origin);
      return;
    }
    const { data } = event;

    if (data?.error) {
      console.log('[IntegrationSetupDialogComponent]: integrationHandler() - incorrect event data: ' + JSON.stringify(event.data));
      const message = data.error;
      this.utilityService.handleError({ message });
    }
  };

  private integrationRefreshHandler = (event: MessageEvent) => {
    this.utilityService.showLoading(false);
    window.removeEventListener('message', this.integrationRefreshHandler);
    const data = event.data || {};
    let msg = data.error || 'Authentication failed';
    if (data.success) {
      this.integrationAccountEmail = data.email;
      msg = 'Authentication updated';
      this.integrationSetupProvider.setReauthData(this.companyId, this.integrationId, { reauthRequired: false }).pipe(
        catchError(err => {
          this.utilityService.handleError(
            new Error(ERROR_MESSAGE.FAILED_TO_UPDATE_AUTH_SETTINGS)
          )
          return throwError(err);
        })
      ).subscribe(() => {
        this.integrationSyncProcessService.reauthProcessCompleted();
      })
    }
    this.utilityService.showToast({ Title: '', Message: msg, action: null } );
  };

  private saveSettings(integrationId: string, isReauthRequired: boolean) {
    this.isLoading = true;

    return forkJoin([
      this.saveAuthData(integrationId, isReauthRequired).pipe(
        tap(
          updatedData => {
            if (this.isAdsIntegration && updatedData?.selectedAccountIds) {
              this.hasSelectedAccountIds = updatedData?.selectedAccountIds.length > 0;
              this.integrationSetupProvider.startIntegrationSyncProgressTracking(
                this.integrationSyncProcessService,
                this.companyId,
                integrationId
              );
            }
          }
        )
      ),
      this.saveCronSettings(integrationId)
    ]).pipe(
      finalize(() => {
        this.hideLoader();
      })
    );
  }

  private saveCronSettings(integrationId: string) {
    return this.integrationSetupProvider
      .saveScheduleSettings(this.companyId, integrationId, this.integrationScheduleSettings)
      .pipe(
        catchError(err => {
            this.utilityService.handleError(
              new Error(ERROR_MESSAGE.FAILED_TO_UPDATE_TIME_SETTINGS)
            )
            return throwError(err);
          }
        )
      );
  }

  private saveAuthData(integrationId: string, isReauthRequired: boolean): Observable<any> {
    if (!this.isAdsIntegration) {
      // "setAuthData" required for Ads only!
      return of({});
    }

    const integrationData = this.createUpdatedIntegrationData();

    if (!Object.keys(integrationData).length) {
      return of({});
    }

    return this.integrationSetupProvider.setAuthData(this.companyId, integrationId, integrationData).pipe(
      catchError(err => {
        this.utilityService.handleError(
          new Error(ERROR_MESSAGE.FAILED_TO_UPDATE_AUTH_SETTINGS)
        )
        return throwError(err);
      }),
      tap(() => {
        const integrationDataFull: Integration = {
          integrationId,
          data: {
            ...this.integration?.data,
            ...integrationData,
            reauthRequired: isReauthRequired
          }
        };
        this.integrationSetupProvider.setIntegration(integrationDataFull);
      }),
      map(() => integrationData)
    );
  }

  createUpdatedIntegrationData(): Partial<IntegrationData> {
    const updatedFields: Partial<IntegrationData> = {};

    const integration = this.integration.data;
    if (integration.name !== this.integrationEntityName.value) {
      updatedFields.name = this.integrationEntityName.value;
    }
    if (integration.accounts && !integration.selectedAccountIds) {
      updatedFields.selectedAccountIds = this.selectedAccountIds;
    }
    if (integration.syncAllocatedAmounts !== this.syncAllocatedAmounts) {
      updatedFields.syncAllocatedAmounts = this.syncAllocatedAmounts;
    }

    return { ...updatedFields, ...this.createIntegrationParentData() };
  }

  private disableIntegration(integrationId: string, budgetId: number) {
    this.integrationStateService.setIsProcessing(this.integrationId, true);
    this.integrationSetupProvider.removeIntegration(this.companyId, integrationId, budgetId)
      .pipe(
        switchMap(() => {
          this.integrationEnabled = false;
          this.integrationSetupProvider.deleteIntegration(integrationId);
          return forkJoin([
            this.integrationSetupProvider.deleteScheduleSettings(this.companyId, integrationId),
            this.integrationSetupProvider.deleteDataSyncProgressStatus(this.companyId, integrationId)
          ]);
        }),
        finalize(() => this.integrationStateService.setIsProcessing(this.integrationId, false))
      )
      .subscribe(
        () => this.integrationSyncProcessService.stopTrackingIntegration(integrationId),
        err => this.utilityService.handleError(err)
      );
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public openDisableChoiceDialog() {
    this.dialogRef.close();
    const integration = this.integration;

    if (!integration) {
      return;
    }

    const integrationName = integration.data ? integration.data.name : this.integrationLabel;
    const DISABLE_INTEGRATION_TITLE = `Disable "${ integrationName }" Integration`;
    const DISABLE_INTEGRATION_MESSAGE = `If you disable your <b>${ integrationName }</b> integration then you will remove your saved
      <b>${ integrationName }</b> credentials and stop synchronizing metrics with <b>${ integrationName }</b>.
      Current metric values will remain unchanged.
      <br><br>
      Are you sure that you want to continue?
    `;
    const dialogConfig: MatDialogConfig = {
      panelClass: ExtendedDialogClassName,
      width: '594px',
    };
    const dialogActions = [
      {
        label: 'Cancel',
        handler: () => this.closeDialog(),
        type: DIALOG_ACTION_TYPE.DEFAULT
      },
      {
        label: 'Disable',
        handler: () => this.disableIntegration(integration.integrationId, integration.data?.budgetId),
        type: DIALOG_ACTION_TYPE.FLAT
      }
    ];

    this.dialogManager.openConfirmationDialog({
      actions: dialogActions,
      title: DISABLE_INTEGRATION_TITLE,
      content: DISABLE_INTEGRATION_MESSAGE
    }, dialogConfig);
  }

  public get isSubmitDisabled(): boolean {
    return this.isLoading || this.integrationActive || this.waitingForIntegrationAuth
      || (this.isAdsIntegration && (
        this.integrationEntityName.invalid || this.integrationAccounts?.length && this.selectedAccountsControl.invalid
      ));
  }

  public get isGoogleIntegration(): boolean {
    return this.integrationSource === MetricIntegrationName.GoogleAds;
  }

  public get isLinkedinIntegration(): boolean {
    return this.integrationSource === MetricIntegrationName.LinkedinAds;
  }

  public get onGoogleIntegrating(): boolean {
    return !this.integrationEnabled && this.isGoogleIntegration;
  }

  public get onLinkedinIntegrating(): boolean {
    return !this.integrationEnabled && this.isLinkedinIntegration;
  }

  public get onFacebookIntegrating(): boolean {
    return !this.integrationEnabled && this.integrationSource === MetricIntegrationName.FacebookAds;
  }

  public onConfirm() {
    this.utilityService.showLoading(true);
    if (this.integrationEnabled) {
      this.updateIntegration();
    } else {
      this.enableIntegration();
    }
  }

  getIntegrationStartTimeframe() {
    if (this.selectedBudget.type === BudgetTimeframesType.Year || this.integrationImportStart?.value === importStart.BUDGET) {
      return null;
    }
    return getStartDateOfCurrentTimeframe(this.selectedBudget.type, this.selectedBudget.budget_from);
  }

  private enableIntegration() {
    let integrationData: EnableIntegrationData = { cronSettings: this.integrationScheduleSettings };

    if (this.isAdsIntegration) {
      integrationData = {
        ...integrationData,
        ...this.createIntegrationParentData(),
        budgetId: this.selectedBudget?.id,
        importDataFrom: this.getIntegrationStartTimeframe(),
        name: this.integrationEntityName?.value,
        groupCampaigns: this.integrationCampaignsGrouping?.value,
        syncAllocatedAmounts: this.syncAllocatedAmounts,
      };
    }

    this.integrationSetupProvider.initIntegration(this.companyId, integrationData)
      .pipe(
        filter(initResponse => !!initResponse?.location),
        switchMap(initResponse => this.ensureMetricTypeMappings().pipe(map(() => initResponse))),
        finalize(() => this.utilityService.showLoading(false))
      )
      .subscribe(
        initResponse => this.proceedWithIntegrationAuth(initResponse),
        err => this.utilityService.handleError(err)
      )
  }

  private createIntegrationParentData = (): { segmentData: string; parentCampaignId: number; } => {
    const controlValue: HierarchySelectItem = this.segmentControl.value;
    const data = { segmentData: null, parentCampaignId: null };
    if (controlValue) {
      if (controlValue.objectType === this.configuration.OBJECT_TYPES.campaign) {
        data.parentCampaignId = controlValue.objectId;
      } else {
        data.segmentData = controlValue.id;
      }
    }
    return data;
  };

  private ensureMetricTypeMappings(): Observable<any> {
    return this.isAdsIntegration ? this.integrationSetupProvider.addMetricTypeMappingsIfNotExist(this.companyId) : of(null);
  }

  private proceedWithIntegrationAuth(initResponse: IntegrateCompanyResponse) {
    window.open(initResponse.location);
    window.addEventListener('message', this.integrationHandler);
    this.integrationId = initResponse.integrationId;
    this.waitForIntegrationAuthorization();
  }

  private waitForIntegrationAuthorization() {
    this.waitingForIntegrationAuth = true;
    this.checkIntegrationAuthorized$()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.waitingForIntegrationAuth = false)
      )
      .subscribe(
        integration => this.onIntegrationAuthorized(integration),
        () => this.utilityService.handleError({ message: 'Failed to get integration status'})
      );
  }

  private checkIntegrationAuthorized$(): Observable<Integration> {
    return this.integrationSetupProvider.loadIntegrations(
      this.companyId,
      this.isAdsIntegration ? this.selectedBudget?.id : null
    ).pipe(
      switchMap(
        (integrations: Integration[]) => {
          const targetIntegration = (integrations || []).find(integration => integration.integrationId === this.integrationId);
          return targetIntegration ?
            of(targetIntegration) :
            of(null).pipe(
              delayStream(CHECK_INTEGRATION_AUTH_PERIOD_MS),
              switchMap(() => this.checkIntegrationAuthorized$())
            );
        }
      )
    );
  }

  private onIntegrationAuthorized(integration: Integration) {
    this.integrationEnabled = true;
    this.integration = integration;
    this.integrationId = integration.integrationId;
    this.integrationSetupProvider.setIntegration(integration);

    const integrationData = integration.data;

    if (integrationData && this.isAdsIntegration) {
      this.integrationAccountEmail = integrationData.email;
      this.setAccountData(integrationData);
      if (!this.integrationAccounts || this.hasSelectedAccountIds) {
        this.integrationSetupProvider.startIntegrationSyncProgressTracking(
          this.integrationSyncProcessService,
          this.companyId,
          integration.integrationId
        );
      }
    }

    if (!this.integrationAccounts || this.hasSelectedAccountIds) {
      this.closeDialog();
      this.navigateOnSuccessfulIntegration();
    }
  }

  private updateIntegration() {
    this.saveSettings(this.integrationId, this.isReauthRequired).subscribe(() => {
      setTimeout(() => {
        this.closeDialog();
      }, 250)
    })
  }

  public handleSettingsChange(payload: IntegrationScheduleSettings) {
    this.integrationScheduleSettings = payload;
  }

  public onAuthRefresh() {
    this.utilityService.showLoading(true);

    this.integrationSetupProvider.refreshAuth(this.companyId, this.integrationId)
      .subscribe(
        res => {
          if (!res?.location) {
            return;
          }
          window.open(res.location);
          window.addEventListener('message', this.integrationRefreshHandler);
        },
        err => this.utilityService.handleError(err)
      )
  }

  setSelectedAccountIds(newSelectedAccountIds: string[]) {
    this.selectedAccountIds = newSelectedAccountIds;
    this.allAccountsSelectionState =
      this.selectedAccountIds?.length === this.availableIntegrationAccounts?.length ?
        CheckboxValue.Active :
        this.selectedAccountIds?.length === 0 ? CheckboxValue.NotActive : CheckboxValue.Indeterminate;
  }

  toggleAllAccountsSelection(checked: boolean) {
    const newSelectedIds =
      checked ?
        (this.availableIntegrationAccounts || []).map(account => account.id) :
        [];

    this.selectedAccountsControl.setValue(newSelectedIds);
    this.selectedAccountIds = newSelectedIds;

    this.allAccountsSelectionState =
      checked ?
        CheckboxValue.Active :
        CheckboxValue.NotActive;
  }
}
