import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuPanelItem } from 'app/header-navigation/components/menu-panel/menu-panel.type';
import { ManagePageActionsMenuService } from '../../services/manage-page-actions-menu.service';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';


@Component({
  selector: 'manage-plan-menu',
  templateUrl: './manage-plan-menu.component.html',
  styleUrls: ['./manage-plan-menu.component.scss']
})
export class ManagePlanMenuComponent implements OnChanges {
  private readonly menuService = inject(ManagePageActionsMenuService);

  @Input() campaignTypes: BudgetObjectType[] = [];
  @Input() expGroupTypes: BudgetObjectType[] = [];
  menuItems: MenuPanelItem[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.menuItems.length) {
      this.menuItems = this.menuService.generateMenu();
    }

    if (changes.campaignTypes || changes.expGroupTypes) {
      this.menuService.setObjectTypes(
        this.campaignTypes,
        this.expGroupTypes
      );
    }
  }
}
