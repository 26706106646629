import { MatDialogConfig } from '@angular/material/dialog';

export const dialogConfig: MatDialogConfig = {
  id: 'increase-budget-amount',
  height: 'initial',
  width: '450px',
  autoFocus: false,
  restoreFocus: false,
  panelClass: 'extended-confirmation-dialog',
  data: null
};
