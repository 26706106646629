import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'add-object-button',
  templateUrl: './add-object-button.component.html',
  styleUrls: ['./add-object-button.component.scss']
})
export class AddObjectButtonComponent {
  @Input() isDisabled: boolean;
  @Input() iconConfig: IconProp;
  @Output() createObjectClick = new EventEmitter<void>();
}
