import { ComponentRef, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SharedCostRulesTooltipComponent } from 'app/shared/components/shared-cost-rules-tooltip/shared-cost-rules-tooltip.component';
import { SharedIconState } from 'app/shared/components/icons/icon-shared/shared.type';
import { SharedCostRule, SharedCostRuleSegment } from 'app/shared/types/shared-cost-rule.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';

@Directive({
  selector: '[sharedCostRulesTooltip]'
})
export class SharedCostRulesTooltipDirective implements OnInit, OnChanges, OnDestroy {

  @Input() showTooltip = false;
  @Input() tooltipShowDelay = 300;
  @Input() actionTooltipContext: number;
  @Input() segments: BudgetSegmentAccess[];
  @Input() sharedCostRules: SharedCostRule[];

  private overlayRef: OverlayRef;
  private positionStrategy: PositionStrategy;
  private showDelayTimeout = null;
  private iconState = SharedIconState.Default;
  private tooltipData: {
    id: number,
    name: string,
    segments: Array<SharedCostRule | SharedCostRuleSegment>
  } = {id: null, name: '', segments: []};

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  ngOnInit() {
    this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
      }]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showTooltip) {
      if (!this.showTooltip) {
        this.hide();
      }
    }
  }

  ngOnDestroy(): void {
    this.hide();
  }

  private attachTooltip() {
    if (!this.overlayRef || !this.showTooltip) {
      return;
    }

    const tooltipPortal = new ComponentPortal(SharedCostRulesTooltipComponent);
    const tooltipRef: ComponentRef<SharedCostRulesTooltipComponent> = this.overlayRef.attach(tooltipPortal);
    this.prepareDataForTooltip(this.actionTooltipContext, this.segments, this.sharedCostRules);

    tooltipRef.instance.tooltipContext = this.tooltipData;
    tooltipRef.instance.iconState = this.iconState;
  }

  @HostListener('mouseenter')
  private show() {
    if (!this.showTooltip) {
      return;
    }

    this.overlayRef = this.overlay.create({
      positionStrategy: this.positionStrategy
    });

    this.showDelayTimeout = setTimeout(() => this.attachTooltip(), this.tooltipShowDelay);
  }

  @HostListener('mouseleave')
  private hide() {
    clearTimeout(this.showDelayTimeout);
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  prepareDataForTooltip(ruleId: number, segments: BudgetSegmentAccess[], sharedCostRules: SharedCostRule[]) {
    const scr = sharedCostRules.find(item => item.id === ruleId);

    if (scr) {
      this.tooltipData.id = scr.id;
      this.tooltipData.name = scr.name;
      this.tooltipData.segments = scr.segments.map((segment: SharedCostRuleSegment) => {
        const { id, cost } = segment;
        const segmentName = segments.find((item: BudgetSegmentAccess) => item.id === id);
        const name = segmentName ? segmentName.name : '';

        return { id, cost, name };
      });
    }
  }

}
