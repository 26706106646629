import { tap } from 'rxjs/operators';
import {
  BudgetAllocationAction,
  BudgetAllocationActionParams,
  UndoCallback
} from '../../budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';
import { ManageTableParentContext } from '../../manage-table/types/manage-table-parent-context.interface';
import { BulkActionTargets } from '@shared/types/bulk-action-targets.type';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { ManageTableDataService } from '../../manage-table/services/manage-table-data.service';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageTableDataMutationService } from '../../manage-table/services/manage-table-data-mutation.service';
import { ManageCegTableDataMutationService } from '@manage-ceg/services/manage-ceg-table-data-mutation.service';
import { ManageTableBulkParentData } from '../../manage-table/types/manage-table-bulk-data-map.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { UpdateObjectsResult } from '../../manage-table/services/manage-page-api.service';


interface BasicActionParams<TDataService, TMutationService> extends BudgetAllocationActionParams {
  executeResultHandler: (results: any, childrenSegmentInheritance: boolean) => void;
  undoResultHandler: (results: any) => void;
  bulkTargets: BulkActionTargets;
  dataService: TDataService;
  mutationService: TMutationService;
  parentData: ManageTableParentContext;
  undoCallbacks?: UndoCallback[];
  suppressTfAllocations: boolean;
  timeframes: BudgetTimeframe[];
  movedItemsCount: number;
}

export class ManageTableChangeParentAction<
    TDataService extends ManageTableDataService | ManageCegTableDataService,
    TMutationService extends ManageTableDataMutationService | ManageCegTableDataMutationService>
    extends BudgetAllocationAction<BasicActionParams<TDataService, TMutationService>
  > {
  private initialParents: ManageTableBulkParentData = {
    campaigns: [],
    expGroups: []
  };

  constructor(params: BasicActionParams<TDataService, TMutationService>) {
    super();
    this.actionParams = params;
    this.prepareActionData();
  }

  private prepareActionData() {
    const { bulkTargets, dataService } = this.actionParams;
    const { campaigns, expGroups } = bulkTargets;
    const getParentContext = (objectId: number, objectType: ManageTableRowType) => {
      const targetObject = dataService.getRecordByIdAndType(objectType, objectId);
      const targetParent = dataService.getRecordById(targetObject?.parentId);

      return {
        objectId: targetParent?.objectId || null,
        objectType: targetParent?.type || null,
        segmentData: {
          budgetSegmentId: targetParent?.segmentId || null,
          sharedCostRuleId: targetParent?.sharedCostRuleId || null,
        }
      };
    };
    this.initialParents = {
      campaigns: (campaigns || []).map(campaignId => ({
        id: campaignId,
        data: getParentContext(campaignId, ManageTableRowType.Campaign)
      })),
      expGroups: (expGroups || []).map(expGroupId => ({
        id: expGroupId,
        data: getParentContext(expGroupId, ManageTableRowType.ExpenseGroup)
      })),
    };
  }

  public execute(): void {
    const {
      mutationService,
      parentData,
      bulkTargets,
      timeframes,
      suppressTfAllocations,
      undoCallbacks,
      movedItemsCount,
      executeResultHandler
    } = this.actionParams;

    mutationService.updateParentData(
      bulkTargets,
      parentData,
      timeframes,
      suppressTfAllocations,
      (results: UpdateObjectsResult, childrenSegmentInheritance: boolean) => executeResultHandler(results, childrenSegmentInheritance),
      movedItemsCount,
      undoCallbacks
    );
  }

  public undo(): void {
    const { undoResultHandler, undoCallbacks, mutationService, movedItemsCount } = this.actionParams;

    mutationService.undoParentDataUpdate(this.initialParents, (results: UpdateObjectsResult) => {
      if (results) {
        this.runCallbacks(undoCallbacks)
          .pipe(tap(() => undoResultHandler(results)))
          .subscribe();
      }
    }, movedItemsCount);
  }
}
