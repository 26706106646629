<article class="auth-page forgot-password-screen">
  <section class="auth-page-content">
    <div class="forgot-password-form-container">
      <h1 class="logo">
        <a href="javascript:;" title="Planful" [routerLink]="['/']">
          <img src="assets/images/planful-logo-colored.svg" width="150" alt="logo" />
        </a>
      </h1>
      <h3 class="auth-page-content-title title-big">Forgot Password?</h3>
      <form class="auth-page-form" #form="ngForm" novalidate (ngSubmit)="forgot_password(form.value, form.valid, form)">
        <div class="form-field">
          <label class="form-field-label">Email</label>
          <input
            type="text"
            name="email"
            class="form-field-input"
            [(ngModel)]="form.email"
            #email="ngModel"
            pattern="^[a-zA-Z0-9._+-]+[a-zA-Z0-9_+-]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$"
            required
          />
          <div class="md-errors-spacer error form-field-error" [hidden]="email.valid || (email.pristine && !form.submitted)">
            <div *ngIf="email.errors && email.errors.required">
              {{ validations.ValiditionMessages.EMAIL_REQUIRED }}
            </div>
            <div *ngIf="email.errors && email.errors.pattern">
              {{ validations.ValiditionMessages.INVALID_EMAIL }}
            </div>
          </div>
        </div>
        <div class="form-actions">
          <button mat-flat-button color="primary" class="full-width" type="submit" [disabled]="form.invalid">
            Send password reset link
          </button>
        </div>
    </form>
    </div>
  </section>
  <rights-reserved></rights-reserved>
</article>
