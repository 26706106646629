<svg
  [attr.width]="width || size"
  [attr.height]="height || size"
  [attr.fill]="color"
  [style.display]="'block'"
  viewBox="0 0 16 16"
  xmlns="http://www.w3.org/2000/svg"
>
  <path opacity="0.4" d="M5 3.5C5 2.96875 5.4375 2.5 6 2.5H15C15.5312 2.5 16 2.96875 16 3.5C16 4.0625 15.5312 4.5 15 4.5H6C5.4375 4.5 5 4.0625 5 3.5ZM9 8.5C9 7.96875 9.4375 7.5 10 7.5H15C15.5312 7.5 16 7.96875 16 8.5C16 9.0625 15.5312 9.5 15 9.5H10C9.4375 9.5 9 9.0625 9 8.5ZM15 12.5C15.5312 12.5 16 12.9688 16 13.5C16 14.0625 15.5312 14.5 15 14.5H10C9.4375 14.5 9 14.0625 9 13.5C9 12.9688 9.4375 12.5 10 12.5H15Z"/>
  <path d="M2.25 2C2.65625 2 3 2.34375 3 2.75V4.25C3 4.6875 2.65625 5 2.25 5V7.75H4C4 7.34375 4.3125 7 4.75 7H6.25C6.65625 7 7 7.34375 7 7.75V9.25C7 9.6875 6.65625 10 6.25 10H4.75C4.3125 10 4 9.6875 4 9.25H2.25V12.5C2.25 12.6562 2.34375 12.75 2.5 12.75H4C4 12.3438 4.3125 12 4.75 12H6.25C6.65625 12 7 12.3438 7 12.75V14.25C7 14.6875 6.65625 15 6.25 15H4.75C4.3125 15 4 14.6875 4 14.25H2.5C1.53125 14.25 0.75 13.4688 0.75 12.5V5C0.3125 5 0 4.6875 0 4.25L0 2.75C0 2.34375 0.3125 2 0.75 2H2.25Z"/>
</svg>
