import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilityService } from 'app/shared/services/utility.service';
import { Subject } from 'rxjs';
import { ExpenseTypeService } from 'app/shared/services/backend/expense-type.service';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { Validations } from 'app/app.validations';
import { AppDataLoader } from 'app/app-data-loader.service';
import { Budget } from 'app/shared/types/budget.interface';
import { Configuration } from 'app/app.constants';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { ExpenseTypeDialogComponent, ExpenseTypeDialogContext } from '@shared/modals/expense-type-dialog/expense-type-dialog.component';
import { getTakenNames } from 'app/shared/utils/common.utils';
import { ProgramTypeDO } from 'app/shared/types/program.interface';
import { DialogContext } from 'app/shared/types/dialog-context.interface';
import { MatDialog } from '@angular/material/dialog';
import * as utils from 'app/shared/utils/notification-dialog.utils';
import { ConfirmType } from 'app/shared/utils/notification-dialog.utils';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'expense-types',
  templateUrl: './expense-types.component.html',
  styleUrls: ['./expense-types.component.scss'],
  providers: [AppDataLoader]
})
export class ExpenseTypesComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private selectedBudget: Budget = null;
  expenseTypeList: ProgramTypeDO[];
  companyId: number;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly expenseTypeService: ExpenseTypeService,
    private readonly companyDataService: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly validations: Validations,
    public readonly modal: Modal,
    private readonly appDataLoader: AppDataLoader,
    private readonly configuration: Configuration,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.companyDataService.selectedCompany$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null),
        switchMap(cmp => this.utilityService.expenseTypeListPropertyChanged$.pipe(map(() => cmp.id)))
      )
      .subscribe(companyId => this.getExpenseTypes(companyId));

    this.companyDataService.selectedCompany$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null)
      )
      .subscribe(cmp => {
        this.getExpenseTypes(this.companyId = cmp.id);
        this.companyDataService.loadCompanyData(this.companyId);
      });

    this.budgetDataService.selectedBudget$
      .pipe(
        tap(newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: (error) => this.utilityService.handleError(error)
      });

    this.appDataLoader.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    this.selectedBudget = newSelectedBudget;
    if (newSelectedBudget != null) {
      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.campaignStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.programStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }
  }

  getExpenseTypes(companyId: number) {
    this.utilityService.showLoading(true);
    this.expenseTypeService.getExpenseTypes({ company: companyId }).subscribe(
      data => this.onExpenseTypesLoaded(data),
      error => this.utilityService.handleError(error)
    );
  }

  onExpenseTypesLoaded(data) {
    this.expenseTypeList = data;
    this.utilityService.showLoading(false);
  }

  editExpenseType(expType) {
    this.openExpenseTypeModal(expType);
  }

  deleteExpenseType(expenseType: ProgramTypeDO) {
    if (confirm(this.validations.ValiditionMessages.DELETE_EXPENSE_TYPE_WARNING)) {
      this.utilityService.showLoading(true);
      this.expenseTypeService.deleteExpenseType(expenseType.id).subscribe(
        () => this.onExpenseTypeDeleted(),
        () => this.showDeleteNotificationDialog(expenseType.name)
      );
    } else {
      return false;
    }
  }

  onExpenseTypeDeleted() {
    this.utilityService.showToast({ Title: '', Message: 'Expense Type Deleted Successfully.', Type: 'success' });
    this.utilityService.showExpenseTypeList(true);
  }

  addExpenseType() {
    this.openExpenseTypeModal(null);
  }

  openExpenseTypeModal(expenseType) {
    const dialogData: ExpenseTypeDialogContext = {
      company_id: this.companyId,
      expenseType,
      expenseNames: getTakenNames(this.expenseTypeList, expenseType?.name),
    };

    this.dialog.open(ExpenseTypeDialogComponent, {
      width: '700px',
      data: dialogData,
      autoFocus: false
    });
  }

  showDeleteNotificationDialog(expenseTypeName: string) {
    const dialogData: DialogContext = utils.getConfirmationDialogData(ConfirmType.DeleteExpenseType, expenseTypeName);
    this.dialog.open(ConfirmationDialogComponent, {
      width: dialogData.width,
      data: dialogData
    });
  }
}
