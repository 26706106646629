import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { ExternalMetricTypesMappingTableSettings } from '../external-metric-types-mapping-table/external-metric-types-mapping-table.type';
import { AdsIntegrations, MetricIntegrationDisplayName, MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { Budget } from 'app/shared/types/budget.interface';
import { Integration } from 'app/metric-integrations/types/metrics-provider-data-service.types';
import { CompanyDO } from 'app/shared/types/company.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { AppDataLoader } from 'app/app-data-loader.service';
import { Configuration } from 'app/app.constants';
import { IntegrationSetupProviderService } from 'app/metric-integrations/services/integration-setup-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { IntegrationSetupDialogComponent } from '../integration-setup-dialog/integration-setup-dialog.component';
import { DIALOG_CONFIG } from '../integration-setup-dialog/integration-setup-dialog.config';
import { IntegrationSetupDialogData } from '../../types/integration-setup-dialog-data.interface';
import { MatDialog } from '@angular/material/dialog';
import { IntegrationStatus } from 'app/shared/enums/integration-statuses.enum';
import { UserManager } from 'app/user/services/user-manager.service';
import { UserDataService } from 'app/shared/services/user-data.service';


@Component({
  selector: 'integration-mappings',
  templateUrl: './integration-mappings.component.html',
  styleUrls: ['./integration-mappings.component.scss'],
  providers: [
    AppDataLoader,
  ]
})
export class IntegrationMappingsComponent implements OnInit, OnDestroy {
  public budget: Budget = null;
  public company: CompanyDO;
  public integrationSource: MetricIntegrationName;
  public integrationName: string;
  public mappingTableSettings: ExternalMetricTypesMappingTableSettings;

  public integrationsList: Integration[];
  public allIntegrationsSynchronized: boolean;
  private readonly destroy$ = new Subject<void>();
  private readonly pageReload$ = new Subject<void>();
  private syncStatusTimeout;
  public isPowerUser: boolean;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly companyDataService: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly appDataLoader: AppDataLoader,
    private readonly configuration: Configuration,
    private readonly integrationSetupProvider: IntegrationSetupProviderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly matDialog: MatDialog,
    private readonly userManager: UserManager,
    private readonly userDataService: UserDataService,
  ) {
    this.route.paramMap.subscribe(data => {
      const integrationSource = data.get('integrationSource') as MetricIntegrationName;
      const integrationLabel = MetricIntegrationDisplayName[integrationSource];
      if (!integrationLabel) {
        console.error('Wrong "integrationSource" in the route params');
        this.router.navigate([this.configuration.DEFAULT_ROUTE])
        return;
      }
      this.pageReload$.next();
      this.loadMetricsData(integrationSource, integrationLabel);
    })
  }

  ngOnInit(): void {
    this.budgetDataService.selectedBudget$
      .pipe(
        tap(selectedBudget => {
          this.budget = selectedBudget;
          this.loadBudgetObjects();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: (error) => this.utilityService.handleError(error)
      });

    this.appDataLoader.init();
  }

  loadMetricsData(integrationSource: MetricIntegrationName, integrationLabel: string) {
    this.integrationSource = integrationSource;
    this.integrationName = integrationLabel;
    this.mappingTableSettings = this.getMappingTableSettings(integrationLabel);
    this.integrationSetupProvider.initProvider(this.integrationSource);

    combineLatest([
      this.companyDataService.selectedCompanyDO$.pipe(
        filter(company => company != null)
      ),
      this.integrationSetupProvider.getCurrentIntegrations(),
      this.userManager.currentCompanyUser$.pipe(
        filter(user => !!user),
        tap(user => {
          this.isPowerUser = this.userDataService.isPowerUser(user);
        })
      )
    ])
      .pipe(
        takeUntil(this.destroy$),
        takeUntil(this.pageReload$),
      )
      .subscribe(([company, integrations, _]) => this.onIntegrationsData(company, integrations));
  }

  checkCompanySyncStatus() {
    this.stopCompanySyncStatusTracking();
    this.integrationSetupProvider.getCompanyRunningSynchronizations(this.company.id)
      .pipe(
        takeUntil(this.destroy$),
        map(resp => resp.every(integration => integration.status !== IntegrationStatus.RUNNING))
      ).subscribe(
      (allSynchronized: boolean) => {
        this.allIntegrationsSynchronized = allSynchronized;
        if (!allSynchronized) {
          this.syncStatusTimeout = setTimeout(() => this.checkCompanySyncStatus(), 3000)
        }
      }
    )
  }

  stopCompanySyncStatusTracking() {
    if (this.syncStatusTimeout) {
      clearTimeout(this.syncStatusTimeout);
    }
  }

  private loadBudgetObjects() {
    if (this.budget && this.company) {
      this.budgetDataService.loadLightCampaigns(
        this.company.id,
        this.budget.id,
        this.configuration.campaignStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.company.id,
        this.budget.id,
        this.configuration.programStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }
  }

  private onIntegrationsData(company: CompanyDO, integrations: Integration[]) {
    if (!company[this.integrationSource]) {
      this.router.navigate([this.configuration.DEFAULT_ROUTE])
    }
    this.integrationsList = integrations ? [...integrations] : null;
    if (integrations?.length > 0) {
      this.company = company;
      this.companyDataService.loadCompanyData(this.company.id);
      this.loadBudgetObjects();
      this.checkCompanySyncStatus();
    }
  }

  public getMappingTableSettings(integrationLabel: string): ExternalMetricTypesMappingTableSettings {
    return {
      descriptionColumnTitle: 'Description',
      integrationName: integrationLabel,
      integrationSource: this.integrationSource,
    };
  }

  openIntegration() {
    const companyId = this.companyDataService.selectedCompanySnapshot?.id;
    if (!companyId) {
      return
    }
    const emptyIntegration = {
      integrationId: null,
      data: {
        name: null,
        email: null,
        budgetId: null,
        importDataFrom: null,
      },
    }

    const dialogData: IntegrationSetupDialogData = {
      integrationSource: this.integrationSource,
      integration: AdsIntegrations.includes(this.integrationSource) ? emptyIntegration : null,
      companyId,
    };

    this.matDialog.open(IntegrationSetupDialogComponent, {
      ...DIALOG_CONFIG,
      panelClass: 'reset-paddings',
      data: dialogData
    });
  }

  syncAllIntegrations(): void {
    if (!this.allIntegrationsSynchronized) {
      return;
    }
    this.allIntegrationsSynchronized = false;
    this.integrationSetupProvider.syncAllIntegrations(this.company.id)
      .subscribe(() => {
        this.checkCompanySyncStatus();
      }
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stopCompanySyncStatusTracking();
  }

}
