import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ExternalMetricTypesMapping } from '../types/external-metric-types-mapping.interface';
import { messages, integrationProviders } from '../messages';
import { IntegrationScheduleSettings } from '../types/integration-schedule-settings.interface';
import {
  BudgetIntegrationCampaignMappingsResponse,
  DisableCompaniesIntegrationResponse,
  IntegrateCompanyResponse,
  Integration,
  IntegrationData,
  ObjectMappingItem,
  SyncStatusResponse
} from '../types/metrics-provider-data-service.types';
import { MetricIntegrationSyncStatusManager } from '../types/metric-integration-sync-status-manager.interface';

export abstract class MetricsProviderDataService<TObjectMapping> implements MetricIntegrationSyncStatusManager {

  protected apiPathParam = {
    companyId: '{companyId}',
    campaignId: '{campaignId}',
    integrationId: '{integrationId}'
  }

  protected apiPath = {
    auth: 'auth/',
    authData: `auth/${this.apiPathParam.companyId}/${this.apiPathParam.integrationId}`,
    authBudget: 'auth/budget/',
    disableCompanies: 'auth/multi-reset',
    integration: 'integration/',
    proxy: 'proxy-data/',
    fetchData: 'fetch-data/',
    cronSettings: 'cron-settings/',
    metricTypeMapping: 'mapping/types/',
    campaignMetricMapping: 'mapping/campaign/',
    syncMappings: 'mapping/sync/',
    syncAllIntegrations: 'sync/',
    defineMappingTargets: 'mapping/define-targets/',
    campaignsMappingMultiDelete: `mapping/campaign/${this.apiPathParam.companyId}/multi-delete`,
    campaignsMappingMultiDisable: `mapping/campaign/${this.apiPathParam.companyId}/multi-disable`,
    syncStatus: 'sync-status/',
    disableCostAdjustment: `cost-adjustment/${this.apiPathParam.companyId}/multi-disable`
  };

  public static handleGetError(error) {
    return error && String(error.status) === '404' ?
      of(null) :
      throwError(error)
  }

  protected constructor (
    protected readonly http: HttpClient,
    private readonly serviceUrl: string,
  ) {}

  get serviceBaseUrl(): string {
    return this.serviceUrl;
  }

  public getIntegrations(companyId: number, budgetId?: number): Observable<Integration[]> {
    let requestUrl = `${this.serviceBaseUrl}${this.apiPath.integration}${companyId}`;
    if (budgetId) {
      requestUrl += `/${budgetId}`
    }
    return this.http.get<Integration[]>(requestUrl)
      .pipe(
        catchError(error => MetricsProviderDataService.handleGetError(error))
      );
  }

  getCompanyRunningSynchronizations(companyId: number): Observable<SyncStatusResponse[]> {
    return of([]); // default [] for HS and SF
  }

  public syncAllIntegrations(companyId: number): Observable<any> {
    const url = `${this.serviceBaseUrl}${this.apiPath.syncAllIntegrations}${companyId}`;
    return this.http.post(url, null);
  }

  public getExternalMetricTypesMapping(companyId: number, integrationId?: string): Observable<ExternalMetricTypesMapping> {
    return this.http.get(
      `${this.serviceBaseUrl}${this.apiPath.metricTypeMapping}${companyId}` + (integrationId ? `/${integrationId}` : '')
    ).pipe(
      map((response: any): ExternalMetricTypesMapping => {
        const metricTypesMapping = response?.body || {};
        // TODO: remove temporary support for numbers (backend will return arrays only).
        Object.keys(metricTypesMapping).forEach(metricKey => {
          const currentValue = metricTypesMapping[metricKey];
          metricTypesMapping[metricKey] = typeof currentValue === 'number' ? [currentValue] : currentValue;
        })
        return metricTypesMapping;
      }),
      catchError(error => MetricsProviderDataService.handleGetError(error))
    );
  }

  public setExternalMetricTypesMapping(companyId: number, mapping: ExternalMetricTypesMapping, integrationId?: string): Observable<any> {
    return this.http.put<ExternalMetricTypesMapping>(
      `${this.serviceBaseUrl}${this.apiPath.metricTypeMapping}${companyId}` + (integrationId ? `/${integrationId}` : ''),
      mapping
    );
  }

  public getCampaignMapping(companyId: number, budgetId: number, campaignId: number, integrationId: string): Observable<TObjectMapping> {
    return this.http.get(
      `${this.serviceBaseUrl}${this.apiPath.campaignMetricMapping}${companyId}` +
      (integrationId ? `/${integrationId}` : '') +
      `/${budgetId}/${campaignId}`
    ).pipe(
      map((response: ObjectMappingItem<TObjectMapping>) => response && response.body),
      catchError(error => MetricsProviderDataService.handleGetError(error))
    );
  }

  public setCampaignMapping(
    companyId: number,
    budgetId: number,
    campaignId: number,
    integrationId: string,
    mapping: TObjectMapping
  ): Observable<any> {
    return this.http.put<TObjectMapping>(
      `${this.serviceBaseUrl}${this.apiPath.campaignMetricMapping}${companyId}` +
      (integrationId ? `/${integrationId}` : '') +
      `/${budgetId}/${campaignId}`,
      mapping
    );
  }
  
  private findIntegrationProvider(integrationProviderUrl: string): string {
    let company: string;
    Object.keys(integrationProviders).forEach(item => {
      if (integrationProviderUrl.toUpperCase().search(item) > 0) {
        company = integrationProviders[item];
      }
    })
    return company;
  }
  
  public integrateCompany<TIntegrationData>(companyId: number, data: TIntegrationData): Observable<IntegrateCompanyResponse> {
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.auth}${companyId}`;
    return this.http.post(requestUrl, data)
      .pipe(
        catchError(response => {
          // We expect 302 here with new location URL
          if (response.status === 302) {
            return of(response.error);
          }
          throw new Error(`${messages.UNABLE_TO_INTEGRATE_COMPANY} ${this.findIntegrationProvider(this.serviceBaseUrl)} integration`);
        })
      );
  }

  public refreshAuth(companyId: number, integrationId: string): Observable<{ location: string }> {
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.auth}${companyId}/${integrationId}/refresh`;
    return this.http.post<{ location: string }>(requestUrl, {})
      .pipe(
        catchError(err => {
          if (err.status === 302) {
            return of(err.error)
          }
          return throwError(err);
        })
      );
  }

  public removeIntegration(companyId: number, integrationId: string): Observable<string> {
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.auth}${companyId}/${integrationId}`;
    return this.http.delete(requestUrl, {
      responseType: 'text'
    });
  }

  public setCronSettings(companyId: number, integrationId: string, settings: IntegrationScheduleSettings) {
    const { localTmz, syncTime } = settings;
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.cronSettings}${companyId}/${integrationId}`;
    return this.http.put(requestUrl, JSON.stringify({
      localTmz,
      syncTime
    }));
  }

  public getCronSettings(companyId: number, integrationId: string): Observable<IntegrationScheduleSettings> {
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.cronSettings}${companyId}/${integrationId}`;
    return this.http.get<IntegrationScheduleSettings>(requestUrl).pipe(
      catchError(error => MetricsProviderDataService.handleGetError(error))
    );
  }

  public deleteCronSettings(companyId: number, integrationId: string) {
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.cronSettings}${companyId}/${integrationId}`;
    return this.http.delete(requestUrl);
  }

  public syncMappings(companyId: number,  integrationId: string, campaignId: number) {
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.syncMappings}${companyId}` +
      (integrationId ? `/${integrationId}` : '') +
      `/${campaignId}`;
    return this.http.post(requestUrl, null);
  }

  public disableCompanies(ids: number[]): Observable<DisableCompaniesIntegrationResponse> {
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.disableCompanies}`;
    return this.http.post<DisableCompaniesIntegrationResponse>(requestUrl, { ids });
  }

  public getCurrentMappingTargets<TRequestData, TResponseData>(
    companyId: number,
    integrationId: string,
    budgetId: number,
    externalCampaignsData: TRequestData
  ): Observable<TResponseData> {
    return this.http.post<TResponseData>(
      `${this.serviceBaseUrl}${this.apiPath.defineMappingTargets}${companyId}/${integrationId}/${budgetId}`,
      externalCampaignsData
    );
  }

  public deleteCampaignsMappings(companyId: number, campaignIds: number[]): Observable<any> {
    const url = `${this.serviceBaseUrl}${
      this.apiPath.campaignsMappingMultiDelete
        .replace(this.apiPathParam.companyId, String(companyId))
    }`;
    return this.http.post(url, campaignIds);
  }

  public setAuthData(_companyId: number, _integrationId: string, _data: IntegrationData): Observable<void> {
    return of(null); // Default implementation does nothing, concrete implementations can replace this behavior
  }

  public setReauthData(companyId: number, integrationId: string, data: IntegrationData) {
    return this.http.put<IntegrationData>(this.getReauthDataUrl(companyId, integrationId), data);
  }

  private getReauthDataUrl(companyId: number, integrationId: string): string {
    return `${this.serviceBaseUrl}${
      this.apiPath.authData
        .replace(this.apiPathParam.companyId, String(companyId))
        .replace(this.apiPathParam.integrationId, integrationId)
    }`;
  }

  public getAuthData(_companyId: number, _integrationId: string): Observable<IntegrationData> {
    return of(null); // Default implementation does nothing and returns null, concrete implementations can replace this behavior
  }

  public getDataSyncProgressStatus(_companyId: number, _integrationId: string): Observable<SyncStatusResponse> {
    return of(null); // Default implementation
  }

  public deleteDataSyncProgressStatus(_companyId: number, _integrationId: string): Observable<any> {
    return of(null); // Default implementation
  }

  public getBudgetIntegrationCampaignMappings(
    _companyId: number,
    _integrationId: string,
    _budgetId: number
  ): Observable<BudgetIntegrationCampaignMappingsResponse> {
    return of(null); // Default implementation
  }

  public getExternalCampaignsPayload<TRecord>(records: TRecord[], groupName?: string) {
    return of([]); // Default implementation
  };
}
