import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MetricMappingCreationContext } from '../types/details-creation-context.interface';
import { GoalsService } from 'app/shared/services/backend/goals.service';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { BudgetObjectDetailsManager } from './budget-object-details-manager.service';
import { DetailsService } from '../types/budget-object-details-service.interface';
import {
  MetricCalculationRequestData,
  MetricCalculationsDO,
  MetricMappingDO,
  MetricService
} from 'app/shared/services/backend/metric.service';
import { MetricCalculationService, MetricMappingCalculationDO } from 'app/shared/services/backend/metric-calculation.service';
import { MetricMappingDetailsState, MetricMappingThirdPartyAmount } from '../types/metric-mapping-details-state.interface';
import { MetricDetailsStateMapper } from './state-mappers/metric-mapping-state-mapper.service';
import { MetricBreakdown, MetricBreakdownSectionData, MetricBreakdownSectionItem } from '../types/metric-breakdown-data.interface';
import { MetricMilestones } from '../types/metric-milestone.interface';
import { MetricMappingDetailsComponent } from '../types/metric-mapping-details-component.interface';
import { MetricMappingChange } from '../types/budget-object-change.interface';
import { getDiff } from 'recursive-diff';
import { MetricsUtilsService } from './metrics-utils.service';
import { Goal, GoalDO } from 'app/shared/types/goal.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { MetricValueRecords } from '../types/metric-value-records.interface';
import { createDateString, parseDateString } from '../components/containers/campaign-details/date-operations';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { SalesforceDataService } from 'app/metric-integrations/salesforce/salesforce-data.service';
import { MetricValueUpdateItem, MetricValueUpdatesData, MetricValueUpdateType } from '../types/metric-value-update-item.interface';
import { DatePipe } from '@angular/common';
import { createDeepCopy, getNumericValue } from 'app/shared/utils/common.utils';
import { DIALOG_ACTION_TYPE, DialogAction } from 'app/shared/types/dialog-context.interface';
import { MetricIntegrationDisplayName, MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { HubspotDataService } from 'app/metric-integrations/hubspot/hubspot-data.service';
import { ChurnZeroService, EventName } from 'app/shared/services/churn-zero.service';
import { runSequentially } from 'app/shared/utils/rxjs.utils';
import { MetricsProviderDataService } from '../../metric-integrations/services/metrics-provider-data.service';
import { CampaignDO } from 'app/shared/types/campaign.interface';
import { ProductDO, ProductService } from 'app/shared/services/backend/product.service';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { CompanyDO } from 'app/shared/types/company.interface';
import { messages, objectPlaceholderName } from '../messages';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { PendoEventName, PendoManagerService, PendoObjectType } from '@shared/services/pendo-manager.service';

export interface MetricCalculationDiff {
  added: MetricMappingCalculationDO[];
  updated: MetricMappingCalculationDO[];
  deleted: number[];
}

@Injectable({
  providedIn: 'root'
})
export class MetricMappingDetailsService implements DetailsService<MetricMappingDetailsState> {

  public static metricValueUpdateTypeByIntegrationName = {
    [MetricIntegrationName.Salesforce]: MetricValueUpdateType.salesForce,
    [MetricIntegrationName.Hubspot]: MetricValueUpdateType.hubSpot,
    [MetricIntegrationName.GoogleAds]: MetricValueUpdateType.googleAds,
    [MetricIntegrationName.LinkedinAds]: MetricValueUpdateType.linkedinAds,
    [MetricIntegrationName.FacebookAds]: MetricValueUpdateType.facebookAds,
  };
  private readonly metricMappingChanged = new Subject<MetricMappingChange>();
  public readonly metricMappingChanged$ = this.metricMappingChanged.asObservable();
  private readonly mappingsListChanged = new Subject<void>();
  public readonly mappingsListChanged$ = this.mappingsListChanged.asObservable();

  /**
   * State properties to check updates for
   */
  updatableStateProps = [
    'startDate',
    'milestones',
    'notes',
    'metricCalculations',
    'metricId'
  ];

  /**
   * State properties to exclude from state diff checks
   */
  statePropsToExclude = [
    'summary',
    'ROIRecords',
    'CPORecords',
    'metricValueRecords',
    'childMetricMappings',
    'metricValueUpdates',
    'currentValue',
    'thirdPartyAmounts',
    'revenuePerOutcome',
    'revenueToProfit'
  ];

  private static areCalculationsEqual(prevCalc: MetricMappingCalculationDO, currentCalc: MetricMappingCalculationDO): boolean {
    return prevCalc.change_in_value === currentCalc.change_in_value &&
      (prevCalc.notes === currentCalc.notes || prevCalc.notes == null && currentCalc.notes == null);
  }

  private static patchState(state: MetricMappingDetailsState, patchObject: MetricMappingDO) {
    if (patchObject.id) {
      state.objectId = patchObject.id;
      (state.metricCalculations || [])
        .forEach(calc => {
          if (calc.metric_mapping == null) {
            calc.metric_mapping = patchObject.id
          }
          const patchCalc =
            (patchObject.metric_calculations || []).find(createdCalc => calc.date === createdCalc.date);
          if (patchCalc) {
            calc.id = patchCalc.id;
          }
        });
    }
  }

  private static getThirdPartyAmountSectionItemName(
    thirdPartyMetricAmount: MetricMappingThirdPartyAmount,
    mappedCampaignNumbers: { [integrationName: string]: number }
  ): string {
    const campaignNumbers = mappedCampaignNumbers[thirdPartyMetricAmount.integrationName];
    const integrationDisplayName = MetricIntegrationDisplayName[thirdPartyMetricAmount.integrationName];
    const pluralSuffix = campaignNumbers === 1 ? '' : 's';
    return campaignNumbers && integrationDisplayName ? `${campaignNumbers} ${integrationDisplayName} Campaign Metric${pluralSuffix}` : '';
  }

  static updateMetricBreakdownSectionTotal(
    breakdownSection: MetricBreakdownSectionData,
    currentInc: number,
    targetInc: number,
    metricName: string
  ) {
    const sectionTotal = breakdownSection.totalRow || { name: `Total ${metricName}`, current: 0, target: 0 };
    sectionTotal.target += targetInc;
    sectionTotal.current += currentInc;
    breakdownSection.totalRow = sectionTotal;
  }

  static updateMetricBreakdownGrandTotal(metricBreakdownData: MetricBreakdown, currentInc: number, targetInc: number) {
    const grandTotal = metricBreakdownData.grandTotal || { name: 'Grand Total', current: 0, target: 0 };
    grandTotal.target += targetInc;
    grandTotal.current += currentInc;
    metricBreakdownData.grandTotal = grandTotal;
  }

  static getLastUpdatedDate(
    metricValueUpdates: MetricValueUpdatesData,
    originUpdateType: MetricValueUpdateType,
    originObjectId = null
  ): string {
    const compareUpdatesByUpdatedOnDate =
      (upd1: MetricValueUpdateItem, upd2: MetricValueUpdateItem) =>
        new Date(upd2.updatedDate).getTime() - new Date(upd1.updatedDate).getTime();

    const orderedUpdatesByType = metricValueUpdates
      .filter(item => {
        const updateTypeMatches = item.type === originUpdateType;
        const objectIdMatches = originObjectId ? item.objectId === originObjectId : true;

        return updateTypeMatches && objectIdMatches;
      })
      .sort(compareUpdatesByUpdatedOnDate);

    return orderedUpdatesByType.length ? orderedUpdatesByType[0].updatedDate : null;
  }

  public static getFullMetricName(state: MetricMappingDetailsState): string {
    const productNamePrefix = state.productName ? state.productName + ' ' : '';
    return `${productNamePrefix}${state.metricName}`;
  }

  public static getTotalMappingCurrentValue(metricMapping: MetricMappingDO, includeDependent: boolean): number {
    return MetricDetailsStateMapper.getCurrentValue(metricMapping, includeDependent) + getNumericValue(metricMapping.third_party_total_amount);
  }

  constructor(
    private readonly metricService: MetricService,
    private readonly goalService: GoalsService,
    private readonly campaignService: CampaignService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly stateMapper: MetricDetailsStateMapper,
    private readonly metricsUtilsService: MetricsUtilsService,
    private readonly budgetDataService: BudgetDataService,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly utilityService: UtilityService,
    private readonly salesforceDataService: SalesforceDataService,
    private readonly hubspotDataService: HubspotDataService,
    private readonly metricCalculationService: MetricCalculationService,
    private readonly datePipe: DatePipe,
    private readonly churnZeroService: ChurnZeroService,
    private readonly productService: ProductService,
    private readonly companyDataService: CompanyDataService,
    private readonly pendoManager: PendoManagerService
  ) { }

  deleteObject(metricMappingId: number): Observable<void> {
    return this.metricService.deleteMetricMapping(metricMappingId);
  }

  loadDetails(
    _companyId: number,
    _budgetId: number,
    metricMappingId: number,
    data?: { includeDependentMetricValues: boolean }
  ): Observable<MetricMappingDetailsState> {
    return this.metricService.getMetricMapping(metricMappingId).pipe(
      map(mapping => this.budgetObjectDetailsManager.filterMetricValuesForFixedDate([mapping])[0]),
      map(metricMapping => this.createMetricMappingDetailsState(metricMapping, data ? data.includeDependentMetricValues : true))
    );
  }

  saveDetails(prevObjectDetails: MetricMappingDetailsState, newObjectDetails: MetricMappingDetailsState): Observable<MetricMappingDO> {
    if (prevObjectDetails) {
      return this.updateDetails(prevObjectDetails, newObjectDetails).pipe(
        tap(() =>
          this.pendoManager.track(PendoEventName.ObjectUpdated, {
            type: PendoObjectType.Metric
          })
        )
      );
    } else {
      return this.createDetails(newObjectDetails).pipe(
        tap(() =>
          this.pendoManager.track(PendoEventName.ObjectCreated, {
            type: PendoObjectType.Metric
          })
        )
      );
    }
  }

  initDetails(context: MetricMappingCreationContext, data: Partial<MetricMappingDO>): Observable<MetricMappingDetailsState> {
    const metricMapping: Partial<MetricMappingDO> = { ...data };
    const state = this.createMetricMappingDetailsState(metricMapping);
    state.parentId = context?.parent?.id;
    state.metricId = context?.metricId;
    return of(state);
  }

  getMetricMappingDataObjectFromMetricState(state: Partial<MetricMappingDetailsState>) {
    return this.stateMapper.stateToDataObject(state)
  }

  private createDetails(state: MetricMappingDetailsState): Observable<MetricMappingDO> {
    const payload = this.stateMapper.stateToDataObject(state);
    return this.metricService.createMetricMapping(payload).pipe(
      switchMap(metricMapping => this.metricService.getMetricMapping(metricMapping.id)),
      tap(metricMapping => MetricMappingDetailsService.patchState(state, metricMapping))
    );
  }

  private updateDetails(prevState: MetricMappingDetailsState, newState: MetricMappingDetailsState): Observable<MetricMappingDO> {
    const stateDiff = this.getStateDiff(prevState, newState, this.updatableStateProps);
    const metricMappingPayload = this.stateMapper.stateToDataObject(stateDiff);
    return Object.keys(metricMappingPayload).length ?
      this.metricService.updateMetricMapping(newState.objectId, metricMappingPayload) :
      of(null);
  }

  compareStates(
    prevState: MetricMappingDetailsState,
    currentState: MetricMappingDetailsState
  ): { [key: string]: any } {
    const diffResults = getDiff(prevState, currentState);
    const diffMap = {};

    diffResults.forEach(({ op, path, val }) => {
      const stateKey = path[0] as string;
      diffMap[stateKey] = currentState[stateKey];
    });

    return diffMap;
  }

  hasChanges(prevState: MetricMappingDetailsState, currentState: MetricMappingDetailsState) {
    if (!prevState) {
      return true;
    }

    const currentStateCopy = { ...currentState };
    const prevStateCopy = { ...prevState };
    this.statePropsToExclude.forEach((prop) => {
      delete currentStateCopy[prop];
      delete prevStateCopy[prop];
    });
    // We need to compare snapshots, because state object contains Dates which JSON converts to ISO strings
    const currentStateSnapshot = this.budgetObjectDetailsManager.getDeepStateCopy(currentStateCopy);
    const diff = this.compareStates(prevStateCopy, currentStateSnapshot);

    return diff && Object.keys(diff).length > 0;
  }

  getStateDiff(
    prevState: MetricMappingDetailsState,
    newState: MetricMappingDetailsState,
    objectProps: string[] = []
  ): Partial<MetricMappingDetailsState> {
    // We need to compare snapshots, because state object contains Dates which JSON converts to ISO strings
    const newStateSnapshot = this.budgetObjectDetailsManager.getDeepStateCopy(newState);
    const diffResults = getDiff(prevState, newStateSnapshot);

    return diffResults
      .filter(({ op, path, val }) => objectProps.includes(path?.[0] as string))
      .reduce((currentStateDiff, { op, path, val }) => {
        const stateKey = path[0] as string;
        currentStateDiff[stateKey] = newState[stateKey];
        return currentStateDiff;
      }, {});
  }

  createMetricMappingDetailsState(metricMapping: Partial<MetricMappingDO>, includeDependentMetricValues = true): MetricMappingDetailsState {
    const state = this.stateMapper.dataObjectToState(metricMapping, includeDependentMetricValues);
    return {
      metricValueRecords: [],
      ROIRecords: [],
      CPORecords: [],
      childMetricMappings: [],
      metricValueUpdates: [],
      metricCalculations: [],
      ...state,
    } as MetricMappingDetailsState;
  }

  loadGoalCampaigns(goalId: number, budgetId: number): Observable<CampaignDO[]> {
    return this.campaignService.getCampaigns({
      'goal_ids': goalId,
      'budget': budgetId
    });
  }

  loadChildCampaigns(parentCampaignIds: number[], budgetId: number): Observable<CampaignDO[]> {
    return this.campaignService.getCampaigns({
      'parent_campaign_ids': (parentCampaignIds || []).join(','),
      'budget': budgetId
    });
  }

  loadGoal(goalId: number): Observable<GoalDO> {
    return this.goalService.getGoal(goalId);
  }

  loadCampaign(campaignId: number): Observable<CampaignDO> {
    return this.campaignService.getCampaign(campaignId);
  }

  loadMetricMappings(metricTypeId: number, objectIds: number[], companyId: number, mappingType: string): Observable<MetricMappingDO[]> {
    return objectIds && objectIds.length ?
      this.getMetricMappings(
        companyId,
        {
          map_ids: objectIds.join(','),
          mapping_type: mappingType,
          metric_master: metricTypeId
        }) :
      of([]);
  }

  getMetricMappings(companyId, params): Observable<MetricMappingDO[]> {
    return this.metricService.getMetricMappings(companyId, params).pipe(
      map(mappings => this.budgetObjectDetailsManager.filterMetricValuesForFixedDate(mappings))
    );
  }

  addMetricToBreakdownSection(
    object: CampaignDO,
    metricBreakdownData: MetricBreakdown,
    breakdownSection: MetricBreakdownSectionData,
    childMetricMapping: MetricMappingDO,
    metricName: string,
    includeDependent: boolean
  ) {
    const milestones = MetricDetailsStateMapper.convertMetricDOMilestones(childMetricMapping.milestones);
    const current = MetricMappingDetailsService.getTotalMappingCurrentValue(childMetricMapping, includeDependent);
    const breakdownDataItem = {
      objectId: childMetricMapping.map_id,
      mappingId: childMetricMapping.id,
      name: object && object.name,
      lastUpdated: this.getLastUpdatedDateFromCalculations(childMetricMapping.metric_calculations) || null,
      current: current || 0,
      target: this.metricsUtilsService.getTargetValue(milestones) || childMetricMapping.projection_amount || 0
    };

    breakdownSection.data.push(breakdownDataItem);

    MetricMappingDetailsService.updateMetricBreakdownSectionTotal(
      breakdownSection,
      breakdownDataItem.current,
      breakdownDataItem.target,
      metricName
    );

    MetricMappingDetailsService.updateMetricBreakdownGrandTotal(
      metricBreakdownData,
      breakdownDataItem.current,
      breakdownDataItem.target
    );
  }

  addThirdPartyAmountToBreakdownSection(
    thirdPartyMetricAmount: MetricMappingThirdPartyAmount,
    metricBreakdownData: MetricBreakdown,
    breakdownSection: MetricBreakdownSectionData,
    metricValueUpdates: MetricValueUpdatesData,
    mappedCampaignNumbers: { [integrationName: string]: number },
    integrationRowIconTemplateGetters: { [integrationName: string]: Partial<MetricBreakdownSectionItem> }
  ) {
    const metricValueUpdateType =
      MetricMappingDetailsService.metricValueUpdateTypeByIntegrationName[thirdPartyMetricAmount.integrationName];
    const iconPart = integrationRowIconTemplateGetters[thirdPartyMetricAmount.integrationName];
    const breakdownDataItem: MetricBreakdownSectionItem = {
      ...iconPart,
      objectId: null,
      mappingId: null,
      name: MetricMappingDetailsService.getThirdPartyAmountSectionItemName(thirdPartyMetricAmount, mappedCampaignNumbers),
      lastUpdated: MetricMappingDetailsService.getLastUpdatedDate(metricValueUpdates, metricValueUpdateType) || null,
      current: thirdPartyMetricAmount.amount || 0,
      target: null,
      integration: thirdPartyMetricAmount.integrationName
    };

    breakdownSection.data.push(breakdownDataItem);

    MetricMappingDetailsService.updateMetricBreakdownSectionTotal(
      breakdownSection,
      breakdownDataItem.current,
      breakdownDataItem.target,
      'Integrations'
    );

    MetricMappingDetailsService.updateMetricBreakdownGrandTotal(
      metricBreakdownData,
      breakdownDataItem.current,
      breakdownDataItem.target
    );
  }

  initMetricMilestones(budgetEndDate: string, campaignEndDate?: string): MetricMilestones {
    return [{
      date: this.getDefaultTargetDate(budgetEndDate, campaignEndDate),
      targetValue: null
    }];
  }

  reportMappingsListChange() {
    this.mappingsListChanged.next();
  }

  reportChange(detailsComponent: MetricMappingDetailsComponent) {
    if (detailsComponent) {
      this.metricMappingChanged.next({
        mappingType: detailsComponent.mappingType,
        mappingId: detailsComponent.currentState.objectId,
        mapId: detailsComponent.currentState.parentId,
        isKeyMetric: detailsComponent.currentState.isKeyMetric,
        metricId: detailsComponent.currentState.metricId,
      });
    }
  }

  calculateMetricValues(requestData: MetricCalculationRequestData[]): Observable<MetricCalculationsDO[]> {
    return requestData?.length ?
      this.metricService.calculateValues(requestData) :
      of([]);
  }

  loadMetricGoal(goalId: number): Observable<Goal> {
    return this.goalService.getGoal(goalId).pipe(
      map(goal => this.budgetDataService.convertGoal(goal))
    );
  }

  getMostRecentMetricCalculation(calculations: MetricMappingCalculationDO[]): MetricMappingCalculationDO | null {
    const compareCalcByUpdateDates =
      (calc1: MetricMappingCalculationDO, calc2: MetricMappingCalculationDO) =>
        new Date(calc2.upd).getTime() - new Date(calc1.upd).getTime();
    const orderedCalcs = ([...calculations || []]).sort(compareCalcByUpdateDates);

    if (!orderedCalcs.length) {
      return null;
    }

    return orderedCalcs[0];
  }

  getLastUpdatedDateFromCalculations(calculations: MetricMappingCalculationDO[]): string {
    const mostRecentCalc = this.getMostRecentMetricCalculation(calculations);
    return mostRecentCalc && createDateString(new Date(mostRecentCalc.upd));
  }

  getLastUpdatedDateInfo(calculations: MetricMappingCalculationDO[], onlyDate: boolean = false): string {
    const lastUpdDate = this.getLastUpdatedDateFromCalculations(calculations);
    return lastUpdDate ? `${onlyDate ? `${lastUpdDate}` : `Last updated on ${lastUpdDate}`}` : '';
  }

  getLastNegativeMetricValueDate(calculations: MetricMappingCalculationDO[]): string {
    let date = '';
    for (let i = calculations.length - 1; i >= 0; i--) {
      if (calculations[i].change_in_value < 0) {
        date = this.datePipe.transform(calculations[i].date, 'MMM d, yyyy');
        break;
      }
    }
    return date;
  }

  getMetricValueRecords(metricCalculations: MetricMappingCalculationDO[]): MetricValueRecords<number> {
    return this.getMetricCalculationPropertyValues(metricCalculations, calc => calc.total_value);
  }

  getCPORecords(metricCalculations: MetricMappingCalculationDO[]): MetricValueRecords<number> {
    return this.getMetricCalculationPropertyValues(metricCalculations, calc => calc.cpo_value);
  }

  getROIRecords(metricCalculations: MetricMappingCalculationDO[]): MetricValueRecords<number> {
    return this.getMetricCalculationPropertyValues(metricCalculations, calc => calc.roi_value);
  }

  getMetricProgressTowardsTarget(mappingId: number) {
    return this.metricService.getMetricProgressTowardsTarget(mappingId);
  }

  private getMetricCalculationPropertyValues(
    metricCalculations: MetricMappingCalculationDO[],
    valueAccessor: (calc: MetricMappingCalculationDO) => number
  ): MetricValueRecords<number> {
    return (metricCalculations || []).map(
      calc => ({
        timestamp: parseDateString(calc.date),
        value: valueAccessor(calc) || 0
      })
    );
  }

  addMissingDataForLegacyMetricMapping(
    state: MetricMappingDetailsState,
    startDateProvider: () => string,
    targetDateProvider: () => Date
  ) {
    if (state && !state.startDate && startDateProvider) {
      state.startDate = startDateProvider();
    }
    if (state && (!state.milestones || !state.milestones.length)) {
      state.milestones = [{
        date: targetDateProvider && targetDateProvider(),
        targetValue: state.targetValue || 0
      }];
    }
  }

  getDefaultStartDate(budgetFromDate: string, campaignStartDate?: string) {
    return campaignStartDate || budgetFromDate;
  }

  getDefaultTargetDate(budgetEndDate: string, campaignEndDate?: string): Date {
    const endDateStr = campaignEndDate || budgetEndDate;

    return parseDateString(endDateStr);
  }

  public syncSalesforceObject(companyId: number, integrationId: string, campaignsIds: number[], chain$: Observable<any>) {
    return this.syncIntegrationObject(
      companyId,
      integrationId,
      campaignsIds,
      this.salesforceDataService,
      chain$,
      'Your Salesforce campaigns are now loaded'
    );
  }

  public syncHubspotObject(companyId: number, integrationId: string, campaignsIds: number[], chain$: Observable<any>) {
    return this.syncIntegrationObject(
      companyId,
      integrationId,
      campaignsIds,
      this.hubspotDataService,
      chain$,
      'Your Hubspot campaigns are now loaded'
    );
  }

  private syncIntegrationObject<TObjectMapping>(
    companyId: number,
    integrationId: string,
    campaignsIds: number[],
    metricProviderDataService: MetricsProviderDataService<TObjectMapping>,
    chain$: Observable<any>,
    finishedMessage: string
  ) {
    const snackBarDuration = 4000;
    return forkJoin(
      campaignsIds.map(campaignId => metricProviderDataService.syncMappings(companyId, integrationId, campaignId))
    ).pipe(
      switchMap(() => chain$),
      tap(() => {
        setTimeout(() => {
          this.utilityService.showCustomToastr(finishedMessage, null, { timeOut: snackBarDuration });
        }, 100);
      })
    );
  }

  syncIntegrationsOnMetricCreation$<TObjectMapping>(
    metricId: number,
    integrationData: {
      integrationId: string;
      companyId: number;
      budgetId: number;
      campaignId: number;
      isMetricTypeMappingScopedToIntegration: boolean;
    },
    metricsProviderDataService: MetricsProviderDataService<TObjectMapping>,
    mappedCampaignsCountGetter: (objMapping: TObjectMapping) => number,
    updateThirdPartyData$: (objMapping: TObjectMapping) => Observable<any>,
  ) {
    const { integrationId, companyId, budgetId, campaignId, isMetricTypeMappingScopedToIntegration } = integrationData;

    return forkJoin([
      metricsProviderDataService.getCampaignMapping(companyId, budgetId, campaignId, integrationId),
      metricsProviderDataService.getExternalMetricTypesMapping(companyId, isMetricTypeMappingScopedToIntegration ? integrationId : null)
    ]).pipe(
      switchMap(data => {
        const [mapping, externalTypesMapping] = data;
        const mappingsCount = mappedCampaignsCountGetter?.(mapping) || 0;
        const isMetricTypeMapped = Object.values(externalTypesMapping).some(mappedIds => mappedIds.includes(Number(metricId)));
        return mappingsCount > 0 && isMetricTypeMapped ? updateThirdPartyData$(mapping) : of(null);
      })
    );
  }

  logObjectView(objectId: number): Observable<void> {
    return this.metricService.logMetricMappingView(objectId);
  }

  updateCurrentSummary(state: MetricMappingDetailsState) {
    const totalValue =
      state.metricValueRecords.length ?
        state.metricValueRecords[state.metricValueRecords.length - 1].value :
        0;

    const currentCPO =
      state.CPORecords.length ?
        state.CPORecords[state.CPORecords.length - 1].value :
        0;

    const currentROI =
      state.ROIRecords.length ?
        state.ROIRecords[state.ROIRecords.length - 1].value :
        0;

    state.summary = { ...state.summary, totalValue, currentCPO, currentROI };
  }

  updateTargetValue$(state: MetricMappingDetailsState, targetCost: number): Observable<[number, MetricCalculationsDO]> {
    const targetValue = Number(this.metricsUtilsService.getTargetValue(state.milestones)) || 0;
    return this.calculateMetricValues([
      {
        value: targetValue,
        cost: targetCost,
        rpo: state.revenuePerOutcome,
        revenue_to_profit: state.revenuePerOutcome && state.revenueToProfit
      }
    ])
      .pipe(
        tap(([calcResult]) => {
          state.summary.targetValue = targetValue;
          state.summary.targetCPO = calcResult.CPO;
          state.summary.targetROI = calcResult.ROI;
        }),
        map(([calcResult]) => [targetValue, calcResult])
      );
  }

  setActualMetricCalculations$(state: MetricMappingDetailsState, currentSpend: number): Observable<MetricCalculationsDO> {
    // Don't make calculations api call if there is no revenue per outcome
    if(!state.revenuePerOutcome) { 
      return of(null);
    }

    const latestCalcCost =
      state.metricCalculations.length ? state.metricCalculations[state.metricCalculations.length - 1].cost_value : null;

    return latestCalcCost == null || latestCalcCost === currentSpend ?
      of(null) :
      this.calculateMetricValues([
        {
          value: state.summary?.totalValue || state.currentValue,
          cost: currentSpend,
          rpo: state.revenuePerOutcome,
          revenue_to_profit: state.revenuePerOutcome && state.revenueToProfit
        }
      ])
        .pipe(
          tap(([calcResult]) => {
            if (state.ROIRecords.length) {
              state.ROIRecords[state.ROIRecords.length - 1].value = calcResult.ROI;
            }
            if (state.CPORecords.length) {
              state.CPORecords[state.CPORecords.length - 1].value = calcResult.CPO;
            }
          }),
          map(([calcResult]) => calcResult)
        );
  }

  updateROIForMetricCalculations$(state: MetricMappingDetailsState): Observable<MetricCalculationsDO[]> {
    const calcRequests =
      (state.metricCalculations || []).map(
        calc => ({
          value: calc.total_value,
          cost: calc.cost_value,
          rpo: state.revenuePerOutcome,
          revenue_to_profit: state.revenuePerOutcome && state.revenueToProfit
        })
      );

    return calcRequests.length > 0 ?
      this.calculateMetricValues(calcRequests).pipe(
        tap(
          calcResults => {
            calcResults.forEach((calcResult, index) => {
              state.metricCalculations[index].roi_value = calcResult.ROI;
              state.ROIRecords[index].value = calcResult.ROI;
            });
            this.updateCurrentSummary(state);
          }
        )
      ) :
      of(null);
  }

  private getMetricCalculationDiff(
    prevCalcs: MetricMappingCalculationDO[],
    newCalcs: MetricMappingCalculationDO[]
  ): MetricCalculationDiff {
    const diff: MetricCalculationDiff = { added: [], updated: [], deleted: [] };

    if (Array.isArray(prevCalcs)) {
      prevCalcs
        .filter(calc => calc && calc.id)
        .sort((calc1, calc2) => new Date(calc2.date).getTime() - new Date(calc1.date).getTime())
        .forEach(calc => {
          const currentCalc = newCalcs.find(newCalc => newCalc.id === calc.id);
          if (currentCalc) {
            // Calculation was updated
            if (!MetricMappingDetailsService.areCalculationsEqual(calc, currentCalc)) {
              diff.updated.push(currentCalc);
            }
          } else {
            // Calculation was deleted
            diff.deleted.push(calc.id);
          }
        });
    }

    if (Array.isArray(newCalcs)) {
      newCalcs
        .filter(calc => !calc.id)
        .sort((calc1, calc2) => new Date(calc1.date).getTime() - new Date(calc2.date).getTime())
        .forEach((calc) => {
          diff.added.push(calc)
        });
    }

    return diff;
  }

  /**
   * Show confirmation dialog to sync current metric calculations
   * (if there are any diffs in states),
   * before calling the 'proceedAction' callback
   */
  public syncCalculationsOnDemand(prevState: MetricMappingDetailsState, currentState: MetricMappingDetailsState, proceedAction: Function) {
    const prevCalcs = prevState ? prevState.metricCalculations : [];
    const currentCalcs = currentState && currentState.metricCalculations;
    const hasUnsavedCalculations = this.hasUnsavedCalculations(prevCalcs, currentCalcs);
    const dialogContent = `You have pending metric updates. <br>Do you want to save and continue?`;
    const dialogTitle = 'Pending metric updates';

    const runProceedAction = () => {
      if (typeof proceedAction === 'function') {
        proceedAction();
      }
    };

    const cancelAction: DialogAction = {
      label: 'Cancel',
      handler: null,
      type: DIALOG_ACTION_TYPE.STROKED
    };
    const saveAction: DialogAction = {
      label: 'Save & Go',
      handler: () => {
        this.saveMetricCalculations(prevCalcs, currentCalcs)
          .subscribe(
            () => {
              prevState.metricCalculations = createDeepCopy(currentState.metricCalculations);
            });

        runProceedAction();
      },
      type: DIALOG_ACTION_TYPE.FLAT
    };

    if (hasUnsavedCalculations) {
      this.dialogManager.openConfirmationDialog({
        title: dialogTitle,
        content: dialogContent,
        actions: [cancelAction, saveAction]
      });
      return;
    }

    runProceedAction();
  }

  public hasUnsavedCalculations(prevCalcs: MetricMappingCalculationDO[], newCalcs: MetricMappingCalculationDO[]): boolean {
    const diff = this.getMetricCalculationDiff(prevCalcs, newCalcs);

    return Object.values(diff).some(diffList => diffList.length);
  }

  public saveMetricCalculations(
    prevCalcs: MetricMappingCalculationDO[],
    newCalcs: MetricMappingCalculationDO[]
  ): Observable<any> {
    const calcsDiff = this.getMetricCalculationDiff(prevCalcs, newCalcs);

    return this.deleteMetricCalculations(calcsDiff.deleted).pipe(
      switchMap(() => this.updateMetricCalculations(calcsDiff.updated)),
      switchMap(() => this.createMetricCalculations(calcsDiff.added)),
      tap(() => {
        if (calcsDiff.added?.length || calcsDiff.updated?.length || calcsDiff.deleted?.length) {
          this.churnZeroService.trackEvent(EventName.MetricValueUpdate);
        }
      })
    );
  }

  private deleteMetricCalculations(calcIds: number[]): Observable<any[]> {
    return runSequentially(
      calcIds.map(id => this.metricCalculationService.deleteCalculation(id))
    );
  }

  private updateMetricCalculations(calcs: MetricMappingCalculationDO[]): Observable<any[]> {
    return runSequentially(
      calcs.map(calc => this.metricCalculationService.patchCalculation(
        calc.id,
        {
          change_in_value: calc.change_in_value,
          notes: calc.notes
        }
      ))
    );
  }

  private createMetricCalculations(calcs: MetricMappingCalculationDO[]): Observable<any[]> {
    const nonZeroMetricCalculations = calcs.filter(c => c.change_in_value !== 0)

    return runSequentially(
      nonZeroMetricCalculations.map(
        calc => this.metricCalculationService.createCalculation({
          date: calc.date,
          metric_mapping: calc.metric_mapping,
          change_in_value: calc.change_in_value,
          notes: calc.notes
        }).pipe(
          map(resCalc => {
            calc.id = resCalc.id;
            return resCalc;
          })
        )
      )
    );
  }

  public getProduct$(productId: number, products: ProductDO[]): Observable<ProductDO> {
    return products ?
      of(products.find(prod => prod.id === productId)) :
      this.productService.getProduct(productId);
  }

  public applyMetricType$(
    state: MetricMappingDetailsState,
    company: CompanyDO,
    metrics: MetricType[],
    products: ProductDO[],
  ): Observable<[MetricMappingDetailsState, boolean]> {
    const metricType = metrics.find(metric => metric.id === state.metricId);
    if (!metricType) {
      return throwError(new Error(`No metric type with id ${state.metricId} found`));
    }

    state.metricName = metricType.name;
    state.metricUnit = this.metricsUtilsService.getMetricUnit(metricType.withCurrency, company.currency);
    state.revenuePerOutcome = metricType.revenuePerOutcome;

    const product$ = metricType.productId ? this.getProduct$(metricType.productId, products) : of(null);

    return product$.pipe(
      map(product => {
        state.revenueToProfit = product?.revenue_to_profit || 0;
        state.productName = product?.name || '';
        const displayDecimal = this.metricsUtilsService.isMetricTypeDecimal(metricType);

        return [state, displayDecimal];
      })
    );
  }

  public checkMetricCreationPossible$(contextData: MetricMappingCreationContext, mappingType: string): Observable<void> {
    const message =
      messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, mappingType.toLowerCase() + ' metric');

    if (!contextData?.parent?.id) {
      return throwError({ error: `[${mappingType} Metric Details]: missing parent object for metric creation`, message });
    }

    if (!contextData?.metricId) {
      return throwError({ error: `[${mappingType} Metric Details]: missing metric master for metric creation`, message });
    }

    return this.getMetricMappings(
      this.companyDataService.selectedCompanySnapshot.id,
      { map_id: contextData.parent.id, mapping_type: mappingType, metric_master: contextData.metricId }
    ).pipe(
      switchMap(
        metricMappings =>
          metricMappings?.length ?
            throwError({ error: `[${mappingType} Metric Details]: cannot create metric mapping because it already exists`, message }) :
            null
      )
    );
  }

  public getProductByName(productName: string, products: ProductDO[]): ProductDO | null {
    return productName ?
      products.find(product => product.name === productName) :
      null;
  }
}
