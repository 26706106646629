export interface BudgetMinusExpenseCell {
  value: number;
  highlighted?: boolean;
  warning?: { text: string; action?: () => void } ;
  hasProblem?: boolean;
  onClick?: () => void
}

export interface BudgetMinusExpenseRow {
  title: string;
  rowType: BudgetMinusExpenseRowType;
  FYAmountCell: BudgetMinusExpenseCell;
  currentTFAmountCell?: BudgetMinusExpenseCell;
}

export enum BudgetMinusExpenseRowType {
  data = 'data',
  total = 'total'
}
