<div class="root-layout">
  <navigation *ngIf="currentUser?.is_superadmin"></navigation>
  <div class="right-side">
    <div class="box-topBar" *ngIf="currentUser?.is_superadmin">
      <h4 class="box-pageHeadingBar">My Profile</h4>
      <a class="link-sign-out" href="javascript:;" title="" (click)="userManager.logout()">Sign Out</a>
      <a class="profile-component" [routerLink]="['', { outlets: { primary: 'user', details: null } }]"
         queryParamsHandling="preserve">
        <profile-menu theme='blue' [disabled]="true"></profile-menu>
      </a>
    </div>

    <div class="right-side-content">
      <page-header *ngIf="!(currentUser?.is_superadmin)" [isAdminPage]="true" pageName="My Profile">
        <fa-icon slot="icon" [icon]="['fas', 'user']"></fa-icon>
      </page-header>
      <form class="profile-form"
            #form="ngForm"
            enctype="multipart/form-data"
            novalidate
            (ngSubmit)="updateProfile(form.value, form.valid)">

        <div class="profile-form-controls">
          <mat-form-field appearance="outline" class="p-form-field-outline">
            <mat-label>First Name</mat-label>
            <input matInput
                   name="first_name"
                   [(ngModel)]="userData.first_name"
                   #first_name="ngModel"
                   type="text"
                   maxlength="128"
                   pattern="[a-zA-Z '-]+"
                   required
                   trim>
            <mat-error
              *ngIf="first_name.errors && first_name.errors.required">{{ validations.ValiditionMessages.FIRST_NAME_REQUIRED }}</mat-error>
            <mat-error
              *ngIf="first_name.errors && first_name.errors.trim">{{ validations.ValiditionMessages.FIRST_NAME_REQUIRED }}</mat-error>
            <mat-error
              *ngIf="first_name.errors && first_name.errors.pattern">{{ validations.ValiditionMessages.FIRST_NAME_ONLY_CHARS }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="p-form-field-outline">
            <mat-label>Last Name</mat-label>
            <input matInput
                   name="last_name"
                   [(ngModel)]="userData.last_name"
                   #last_name="ngModel"
                   type="text"
                   maxlength="128"
                   pattern="[a-zA-Z '-]+"
                   required
                   trim>
            <mat-error
              *ngIf="last_name.errors && last_name.errors.required">{{ validations.ValiditionMessages.LAST_NAME_REQUIRED }}</mat-error>
            <mat-error
              *ngIf="last_name.errors && last_name.errors.trim">{{ validations.ValiditionMessages.LAST_NAME_REQUIRED }}</mat-error>
            <mat-error
              *ngIf="last_name.errors && last_name.errors.pattern">{{ validations.ValiditionMessages.LAST_NAME_ONLY_CHARS }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="p-form-field-outline has-tooltip"
                          matTooltipPosition="above"
                          matTooltipShowDelay="800"
                          matTooltipClass="dark-theme-tooltip above"
                          matTooltip="{{ userData.email }}">
            <mat-label>Email</mat-label>
            <input matInput
                   name="email"
                   [(ngModel)]="userData.email"
                   #email="ngModel"
                   type="text"
                   disabled>
          </mat-form-field>

          <button *ngIf="isSSO === false"
                  class="full-width"
                  mat-button
                  type="button"
                  color="primary"
                  (click)="changePassword()">Change Password</button>

          <div class="double-buttons-box mt-2">
            <a href="javascript:;" mat-stroked-button color="primary" [routerLink]="['/plan_detail']">Cancel</a>
            <button type="submit" mat-flat-button color="primary" [disabled]="!form.valid">Save</button>
          </div>
        </div>

        <div class="d-flex fd-column ai-center">
          <div class="avatar mb-1">
            <div *ngIf="profilePicUrl" class="preview" [ngStyle]="{ 'background-image': 'url(' + profilePicUrl + ')' }"></div>

            <span class="placeholder"
                  *ngIf="userData.file == null && !profilePicUrl"
                  getInitials
                  [firstName]="userData.first_name"
                  [lastName]="userData.last_name"></span>
          </div>
          <button
            type="button"
            mat-button
            color="primary"
            (click)="file.click()">
            <fa-icon matSuffix [icon]="['fad', 'camera-alt']" class="camera-icon"></fa-icon>
            Change Avatar
          </button>
          <input type="file" accept="image/*" name="image_data" #file (change)="fileEvent($event)" style="display: none;">
        </div>

      </form>
    </div>

  </div>
</div>
