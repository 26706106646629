<div class="metric-cpo-chart">
  <div class="header">
    <details-widget-title
      [type]="type"
      [hideIcon]="hideIcon"
      [text]=" type ==='metric-drawer-charts'
       ? ( isRevenueToProfitDefined ? 'Return on Investment': 'Revenue Return on Investment')
       : (isRevenueToProfitDefined ? 'ROI' : 'RROI') + ' Achievement'"
      [showCount]="false"
      [secondaryText]="lastUpdatedInfo"
      [showTooltip]="showTooltip"
      [tooltipValue]="tooltipValue"
    >
      <fa-duotone-icon
        slot="icon"
        [icon]="['fad', 'alicorn']"
      ></fa-duotone-icon>
    </details-widget-title>
  </div>
  <div class="chart" #chartRef></div>
</div>
