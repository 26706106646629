import { inject, Injectable } from '@angular/core';
import { FilterSetData } from '../filters.interface';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { Observable } from 'rxjs';

@Injectable()
export class FilterService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    filter: 'filter'
  };

  getFilters(params = null): Observable<any[]> {
    return this.http.get<any[]>(this.apiV2Url + this.apiPaths.filter + '/', getRequestOptions(params));
  }

  createFilterSet(data: FilterSetData): Observable<any> {
    const { name, budgetId: budget, ownerId: owner, isPublic: is_public, isFavourite: is_favourite, selectedFilters: filter_data } = data;
    return this.http.post(
      this.apiV2Url + this.apiPaths.filter + '/',
      JSON.stringify({ name, budget, owner, is_public, is_favourite, filter_data })
    );
  }

  updateFilterSet(filterSetData: FilterSetData): Observable<any> {
    const filterToUpdate = {
      id: filterSetData.id,
      name: filterSetData.name,
      owner: filterSetData.ownerId,
      budget: filterSetData.budgetId,
      is_favourite: filterSetData.isFavourite,
      is_public: filterSetData.isPublic,
      filter_data: filterSetData.selectedFilters
    };

    return this.http.put(
      this.apiV2Url + this.apiPaths.filter + '/' + filterSetData.id + '/',
      JSON.stringify(filterToUpdate)
    );
  }

  updateFilterSetField(id: number, field: string, value): Observable<any> {
    return this.http.patch(
      this.apiV2Url + this.apiPaths.filter + '/' + id + '/',
      JSON.stringify({[field]: value})
    );
  }

  deleteFilterSet(id: number): Observable<void> {
    return this.http.delete<void>(this.apiV2Url + this.apiPaths.filter + '/' + id + '/');
  }
}
