<div class="metric-breakdown">
  <div class="header">
    <details-widget-title [showCount]="false" text="Metric Breakdown">
      <fa-duotone-icon [icon]="icon" slot="icon"></fa-duotone-icon>
    </details-widget-title>
  </div>
  <div *ngIf="data" class="data">
    <metric-breakdown-list
      [nameColumnTitle]="title"
      [showTotal]="false"
      [rows]="[data]"
      [displayDecimal]="displayDecimal"
      [icon]="currentDataIcon"
    ></metric-breakdown-list>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
