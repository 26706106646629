import { Component, Input } from '@angular/core';
import { SharedIconState } from './shared.type';

@Component({
  selector: 'icon-shared',
  templateUrl: './icon-shared.component.html',
  styleUrls: ['./icon-shared.component.scss']
})
export class IconSharedComponent {
  @Input() state = SharedIconState.Empty;
  @Input() size: number;
  SharedIconState = SharedIconState;
  classNames;
  constructor() {
    const { Default, Empty, Shared, Mixed, Exception } = SharedIconState;
    this.classNames = {
      [Default]: 'default-icon',
      [Empty]: 'empty-icon',
      [Shared]: 'shared-icon',
      [Mixed]: 'mixed-icon',
      [Exception]: 'exception-icon'
    }
  }
}
