<div class="details-budget-amount" [class.is-overspend]="isOverspend">
  <formatted-budget-value
    [config]="{
      label: title,
      isOverspend: isOverspend,
      withBrackets: isOverspend,
      isBold: true,
      ending: ':'
    }"
  ></formatted-budget-value>
  <formatted-budget-value
    [config]="{
      value: amount,
      currencySymbol: currency,
      isOverspend: isOverspend,
      withBrackets: isOverspend,
      isBold: true
    }"
  ></formatted-budget-value>
</div>
