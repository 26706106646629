<button
  class="create-object-btn"
  mat-stroked-button
  color="primary"
  [disabled]="isDisabled"
  (click)="createObjectClick.emit()"
>
  <fa-icon [icon]="['fas', 'plus']" class="icon-plus"></fa-icon>
  <fa-icon slot="icon" [icon]="iconConfig" class="icon-object"></fa-icon>
</button>
