export const graphCellData = {
  closed: {
    camelCaseName: 'closed',
    className: 'closed-amount',
  },
  committed: {
    camelCaseName: 'committed',
    className: 'committed-amount',
  },
  planned: {
    camelCaseName: 'planned',
    className: 'planned-amount',
  },
  under_budget: {
    camelCaseName: 'underBudget',
    className: 'under-budget-amount',
  },
  remaining_allocated: {
    camelCaseName: 'remainingAllocated',
    className: 'remaining-amount',
  },
  overspend: {
    camelCaseName: 'overSpend',
    className: 'overspend-amount',
  },
};
