import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserManager } from 'app/user/services/user-manager.service';
import { UserService } from 'app/shared/services/backend/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';

@Component({
  selector: 'no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();

  constructor(
    private readonly userManager: UserManager,
    private readonly userService: UserService,
    private readonly config: Configuration,
  ) { }

  goToLogin() {
    localStorage.setItem(this.config.LS_KEY_NAVIGATE_TO_LOGIN, 'true');
    this.userManager.logout();
  }

  ngOnInit(): void {
    this.userService.addDraftUser()
      .pipe(takeUntil(this.destroy))
      .subscribe({
        error: (e) => console.log('Failed to add a draft user')
      })
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
