<button class="mat-button notes-trigger" [ngClass]="{'active': notesShown, 'filled': notesControl.value }"  mat-icon-button cdk-overlay-origin #trigger="cdkOverlayOrigin" (click)="showNotes()">
  <fa-duotone-icon [icon]="['fad', 'sticky-note']" [flip]="'vertical'"></fa-duotone-icon>
</button>
<ng-template #connectedOverlay="cdkConnectedOverlay" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayWidth]="237" [cdkConnectedOverlayOpen]="notesShown" [cdkConnectedOverlayPositions]="overlayCoords" cdkConnectedOverlayBackdropClass="mat-overlay-transparent-backdrop" (attach)="onAttach()" (backdropClick)="notesShown = false" [cdkConnectedOverlayHasBackdrop]="true">
  <div class="metric-editor-notes">
    <div class="field-wrapper">
      <textarea #textfield [formControl]="notesControl" (focus)="onFocus.emit($event)" (blur)="onTextareaBlur($event)" ></textarea>
    </div>
  </div>
</ng-template>
