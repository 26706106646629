import { inject, Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { GoalTypeDO } from "../../types/goal.interface";
import { Observable } from 'rxjs';
import { withAPIV0ErrorHandling, getRequestOptions } from 'app/shared/utils/http-request.utils';

@Injectable()
export class GoalTypeService {
  private readonly actionUrl = inject(API_V2_URL);
  private readonly http: HttpClient = inject(HttpClient);
  public apiPath = 'goal_type/';
  public newGetapiPath = 'goal_type/list_for_admin/';
  public newDeleteApiPath = '/delete_for_admin_new_world';

  createGoalType(goalType: Partial<GoalTypeDO>): Observable<GoalTypeDO> {
    return withAPIV0ErrorHandling(
      this.http.post<GoalTypeDO>(
        `${this.actionUrl}${this.apiPath}`,
        JSON.stringify(goalType)
      )
    );
  }

  getGoalTypes(data): Observable<GoalTypeDO[]> {
    return withAPIV0ErrorHandling(
      this.http.get<GoalTypeDO[]>(`${this.actionUrl}${this.newGetapiPath}`, getRequestOptions(data))
    );
  }

  updateGoalType(id, data) {
    return withAPIV0ErrorHandling(
      this.http.patch(`${this.actionUrl}${this.apiPath}${id}/`,  JSON.stringify(data))
    );
  }

  deleteNewWorldGoalType(rowId, data) {
    return withAPIV0ErrorHandling(
      this.http.delete(`${this.actionUrl}${this.apiPath}${rowId}${this.newDeleteApiPath}`,getRequestOptions(data))
    );
  }
}
