import { Injectable } from '@angular/core';
import Amplify from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';

export interface AmplifyOAuthConfig {
  domain: string;
  scope: string[];
  responseType: string;
  redirectSignIn?: string;
  redirectSignOut?: string;
}

export interface AmplifyAuthConfig {
  identityPoolId: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType: string;
  oauth: AmplifyOAuthConfig;
}

export interface AmplifyConfig {
  Auth: AmplifyAuthConfig
}

@Injectable()
export class AmplifyService {
  public configureAmplify(authData: AmplifyConfig) {
    if (!authData) {
      return;
    }

    const oauth = authData.Auth.oauth;
    if (oauth) {
      oauth.redirectSignIn = location.origin;
      oauth.redirectSignOut = location.origin + '/logout';
    }
    Amplify.configure(authData);
    Auth.configure({
      ...authData,
      storage: window.sessionStorage
    });
  }
}

