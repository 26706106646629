<div class="custom-fields-container">
  <page-header pageName="Custom Fields" [isAdminPage]="true">
    <fa-icon slot="icon" [icon]="['fas', 'list-dropdown']"></fa-icon>
  </page-header>

  <div class="custom-fields-content">
    <div class="page-container">
      <div class="main-section">
        <div class="create-section">
          <div class="top-row">
            <button (click)="createNewCustomField()" [disabled]="disabledCreateButton" class="create-new" mat-flat-button
              color="primary">
              Create New
            </button>
          </div>
          <ul class="cf-list">
            <li *ngFor="let item of listOfCustomFields" (click)="onSelectField(item)">
              <custom-field-list 
                [for_campaign]="item.for_campaign"
                [for_program]="item.for_program"
                [for_expense]="item.for_expense"
                [listTitle]="item.name"
                [selectionType]="item.type"
                [is_draft]="item.is_draft"
                [selected]="item.id === selectedField.id"
                [listId]="item.id"
                (delete)="deleteCustomField($event)">
              </custom-field-list>
            </li>
            <li *ngIf="isCreateNewField">
              <custom-field-list 
                [for_campaign]="selectedField.for_campaign"
                [for_program]="selectedField.for_program"
                [for_expense]="selectedField.for_expense"
                [listTitle]="selectedField.name"
                [selectionType]="selectedField.type"
                [is_draft]="selectedField.is_draft"
                [selected]="isCreateNewField"
                (delete)="deleteCustomField($event)"
                [showActions]="false">
              </custom-field-list>
            </li>
          </ul>
        </div>
        
        <div class="custom-right-section">
          <div class="list-area" *ngIf="!isLoading">
            <div class="field-section">

              <div class="field-name field-row">
                <mat-form-field appearance="outline" class="p-form-field-outline" [ngClass]="{'mat-form-field-invalid': !isFieldNameValid}">
                  <mat-label>Field Name <span class="required-item">*</span></mat-label>
                  <input matInput
                         [maxlength]="maxCharFieldName"
                         [formControl]="fieldNameControl"
                         [(ngModel)]="selectedField.name"
                         (input)="onFieldValueChange()">
                  <span matSuffix>({{ remainingNameCharacters }}/{{ maxCharFieldName }})</span>
                </mat-form-field>
                <div class="error-message" *ngIf="!isFieldNameValid">
                  <ng-container *ngIf="checkForSpecialCharacter">{{ config.customFieldsDescriptions.errorSpecialCharacters }}</ng-container>
                  <ng-container *ngIf="!checkForSpecialCharacter && !isFieldNameEmpty">{{ config.customFieldsDescriptions.errorDuplicateFieldName }}</ng-container>
                  <ng-container *ngIf="!checkForSpecialCharacter && isFieldNameEmpty">{{ config.customFieldsDescriptions.errorEmptyFieldName }}</ng-container>
                </div>
              </div>
  
              <div class="field-row">
                <h4>Type of Custom Field</h4>
  
                <rounded-box-radio-group (selectionChange)="onRadioSelectionChange($event)">
                  <rounded-box-radio-item value="Single" label="Single-select Dropdown" [checked]="selectedField.type === 'Single'" [disabled]="isMultiSelected"></rounded-box-radio-item>
                  <rounded-box-radio-item value="Multi" label="Multi-select Dropdown" [checked]="selectedField.type === 'Multi'"></rounded-box-radio-item>
                </rounded-box-radio-group>
                <div class="type-info" *ngIf="isTypeChanged">
                  <span class="info-icon">
                    <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
                  </span>
                  <span class="info-text">{{this.config.customFieldsDescriptions.typeInfo}}</span>
                  <span class="info-close" (click)="closeTypeInfo()">
                    <fa-icon [icon]="['fas', 'times']"></fa-icon>
                  </span>
                </div>
              </div>
  
              <div class="field-row border-bottom">
                <h4>{{this.config.customFieldsDescriptions.checkboxLabelText}} <span class="required-item">*</span></h4>
                <div class="checkbox-group">
                  <boxed-checkbox label="Campaigns" [consumedLength]="customFieldCountData.cfCamapaignCount" (value)="handleCheckboxChange($event, 'for_campaign')" [checked]="selectedField.for_campaign" [maxlength]="maxCountForCampaign">
                    <fa-icon [icon]="['far', 'rocket-launch']"></fa-icon>
                  </boxed-checkbox>
                  <boxed-checkbox label="Expense Groups" [consumedLength]="customFieldCountData.cfProgramCount" (value)="handleCheckboxChange($event, 'for_program')" [checked]="selectedField.for_program" [maxlength]="maxCountForProgram">
                    <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>
                  </boxed-checkbox>
                  <boxed-checkbox label="Expenses" [consumedLength]="customFieldCountData.cfExpenseCount" (value)="handleCheckboxChange($event, 'for_expense')" [checked]="selectedField.for_expense" [maxlength]="maxCountForExpense">
                    <fa-icon [icon]="['fas', 'coins']"></fa-icon>
                  </boxed-checkbox>
                </div>
                <div class="error-message" *ngIf="!isCheckboxSelected">{{this.config.customFieldsDescriptions.errorCheckbox}}</div>
              </div>
  
              <div class="field-row description-row">
                <mat-form-field appearance="outline" class="p-form-field-outline">
                  <mat-label>Field Description (Optional)</mat-label>
                  <textarea matInput 
                  class="custom-textarea"
                  [maxlength]="maxCharFieldDes"
                  [formControl]="fieldDesControl"
                  [(ngModel)]="selectedField.description" (input)="onDescriptionValueChange()"></textarea>
                  <span matSuffix>({{ remainingDesCharacters }}/{{ maxCharFieldDes }})</span>
                </mat-form-field>
              </div>
  
            </div>
            <div class="list-section">
              <header>
                <mat-slide-toggle
                  (change)="toggleEnabled($event)"
                  labelPosition="before"
                  disableRipple="true"
                  [checked]="isDropdownOptionRequired"
                >
                </mat-slide-toggle>
                <span class="text">Required</span>
  
                <fa-icon
                  [icon]="['fas', 'info-circle']"
                  [matTooltip]="enableSwitchTooltip"
                  matTooltipClass="dark-theme-tooltip above multi-line"
                  matTooltipPosition="above"
                ></fa-icon>
              </header>
  
              <div class="drag-options">
                <draggable-options
                [isRequired]="isDropdownOptionRequired"
                [items]="dropdownOptions"
                (updatedItems)="updateCustomFieldOptions($event)"
                (updatedDefaultItem)="updateDefaultItem($event)"
                [defaultItem]="defaultOption"
                (dropdownErrorStatus)="handleErrorInDropdownValue($event)"
                ></draggable-options>
              </div>
            </div>
          </div>

          <div class="buttons-area"  *ngIf="!isLoading">
            <span class="footer-text">Items marked with ( <span class="required-item">*</span> ) are mandatory</span>
            <div class="left-buttons">
              <button mat-button color="primary" (click)="discardCustomField()" *ngIf="isChangeInField || isCreateNewField && this.listOfCustomFields.length">
                Cancel
              </button>
            <div class="right-buttons">
              <button mat-stroked-button color="primary" [disabled]="!handleValidation() || !isChangeInField" (click)="saveAsDraft()" *ngIf="!inUpdateState">
                Save as Draft
              </button>
              <button mat-flat-button color="primary" [disabled]="!handleValidation()" (click)="publishCustomField()" *ngIf="!inUpdateState">
                Publish
              </button>
              <button mat-flat-button color="primary" [disabled]="!isChangeInField || !handleValidation(true)" (click)="updateCustomField()" *ngIf="inUpdateState">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>