<div class="tabs-list">
  <div *ngFor="let tab of tabs" class="tab-label-container" [ngClass]="{
      'active': tab.id === activeTabId,
      'disabled': tab.disabled
     }" (click)="setActiveId(tab.id)">
    <div class="tab-header">
      <div class="tab-icon">
        <fa-icon [icon]="tab.icon"></fa-icon>
      </div>
      <div class="tab-title">{{ tab.title }}</div>
    </div>
    <div class="tab-info">
      <div *ngIf="tab.data?.simpleText as simpleText" class="details-info regular-default-text">
        <fa-icon class="key-metric-icon" [icon]="['fad', 'heart']" *ngIf="tab.title.toLowerCase() === 'details' && isKeyMetric"></fa-icon>
        {{ simpleText }}
      </div>
      <div *ngIf="tab.data?.budget as budget" class="budget-allocation-container">
        <div class="currency">{{ budget.currency }}</div>
        <div class="budget-allocation-cell">
          <budget-allocation-cell [inputAnimationDisabled]="true"
            [allocated]="budget.allocated || 0" [remaining]="budget.available || 0"
            [showDifference]="true" [allowDnd]="false"></budget-allocation-cell>
        </div>
      </div>
      <ng-container *ngIf="keyMetricSelection && tab.data?.performance as performance">
        <metric-performance-data
          *ngIf="performance.estimatedDiffPercentage !== null; else noKeyMetric" [isTabData]="true"
          [estimatedDiffPercentage]="performance.estimatedDiffPercentage"
          [progressState]="performance.progressState"></metric-performance-data>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noKeyMetric>
  <span class="regular-default-text no-metric">No key metric</span>
</ng-template>