import { inject, Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { LightProgram, Program } from 'app/shared/types/program.interface';
import { Campaign, LightCampaign } from 'app/shared/types/campaign.interface';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  AllocatableObject,
  AllocatableObjectState,
  BudgetObjectParent,
  SegmentedObjectDetailsState
} from '../types/budget-object-details-state.interface';
import { Configuration } from 'app/app.constants';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { getNumericValue, sumAndRound } from 'app/shared/utils/common.utils';
import { map, switchMap, tap } from 'rxjs/operators';
import { SegmentedObjectTimeframe } from 'app/shared/types/object-timeframe.interface';
import { BudgetObjectAllocation } from 'app/shared/types/budget-object-allocation.interface';
import { BudgetObjectDetailsManager } from './budget-object-details-manager.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { BudgetObjectCloneResponse } from 'app/shared/types/budget-object-clone-response.interface';
import { SegmentedBudgetObject } from 'app/shared/types/segmented-budget-object';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { DIALOG_ACTION_TYPE } from '@shared/types/dialog-context.interface';
import { CompanyDataService } from '@shared/services/company-data.service';

export enum AllocationCheckResult {
  Ok,
  NeedParentAllocationUpdate,
  NeedOwnAllocationUpdate,
  NeedParentAndGrandParentAllocationUpdate
}

export type AllocationAmountsMap = Record<number, number>;

export interface AllocatedToChildrenAmounts<T> {
  total: number;
  values: Record<number, T>;
}

export interface AllocationCheckResultData {
  result: AllocationCheckResult;
  allocationDiff?: number;
  grandParentAllocationDiff?: number;
  message?: string;
  parentAllocationsDiff?: AllocationAmountsMap;
  grandParentAllocationsDiff?: AllocationAmountsMap;
  parentCampaign?: Campaign;
  grandParentCampaign?: Campaign;
}

export interface ObjectAllocationData {
  totalAmount: number;
  allocationAmounts: AllocationAmountsMap;
  objectId: number;
  objectType: string;
  duplicateObject?: boolean;
}

export enum ParentDialogActionSource {
  Move = 'Move',
  Clone = 'Clone'
}

enum ParentDialogType {
  ParentAmountShortage = 'ParentAmountShortage',
  ParentNeedsUpdate = 'ParentNeedsUpdate',
  ParentsNeedUpdate = 'ParentsNeedUpdate'
}

interface ParentDialogParams {
  grandParentUpdateNeeded: boolean;
  dueToMoveAction?: boolean;
  dialogActionSource?: ParentDialogActionSource;
  targetObjName: string;
  parentObjName: string;
  parentTotalAllocated: number;
  availableParentAllocation?: number;
  targetTotalAllocated: number;
  parentAllocationDiff: number;
  parentAllocationsDiff?: AllocationAmountsMap;
  grandParentAllocationDiff: number;
  grandParentAllocationsDiff?: AllocationAmountsMap;
  grandParentObjName?: string;
}

interface ParentDialogConfig {
  title?: string;
  submitLabel?: string;
  getContent?: (params?: Partial<ParentDialogParams>) => string;
}

@Injectable()
export class BudgetObjectAllocationService {
  private readonly configuration = inject(Configuration);
  private readonly campaignService = inject(CampaignService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly dialogManager = inject(BudgetObjectDialogService);
  private readonly decimalPipe = inject(DecimalPipe);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly companyDataService = inject(CompanyDataService);

  private readonly decimalPipeFormat = '1.2-2';

  private readonly dialogsConfig: Record<ParentDialogType, ParentDialogConfig> = {
    [ParentDialogType.ParentAmountShortage]: {
      title: 'Allocated amount less than total of child allocations',
      submitLabel: 'Ok'
    },
    [ParentDialogType.ParentNeedsUpdate]: {
      getContent: (params: Partial<ParentDialogParams>) => this.createConfirmMessage(params)
    },
    [ParentDialogType.ParentsNeedUpdate]: {
      getContent: (params: Partial<ParentDialogParams>) => this.getParentsInsufficientAllocationText(params)
    }
  };

  private formatNumericValue(value: number): string {
    return this.decimalPipe.transform(value, this.decimalPipeFormat);
  }

  private findObjectAllocation(
    target: SegmentedBudgetObject,
    timeframeId: number
  ): SegmentedObjectTimeframe {
    return target.timeframes.find(tf => tf.company_budget_alloc === timeframeId);
  }

  private getTimeframeAmount(
    target: SegmentedBudgetObject,
    timeframeId: number
  ): number {
    return getNumericValue(this.findObjectAllocation(target, timeframeId)?.amount);
  }

  private getTotalAllocationDiff(
    targetAllocated: number,
    childrenAllocated: number,
    childrenAllocationDiff?: number
  ): number {
    const childrenTotal = sumAndRound(childrenAllocated, Math.abs(getNumericValue(childrenAllocationDiff)));

    return sumAndRound(targetAllocated, -childrenTotal);
  }

  private findParentObjects(campaigns: LightCampaign[] | Campaign[], parentId: number): {
    parent: LightCampaign | Campaign | null,
    grandParent: LightCampaign | Campaign | null
  } {
    const findById = (id: number) => (campaigns || []).find(campaign => campaign.id === id) || null;
    const parent = findById(parentId);

    return {
      parent,
      grandParent: parent && parent.parentCampaign ? findById(parent.parentCampaign) : null
    };
  }

  private prepareAllocationDiffsRequest(
    target: SegmentedBudgetObject,
    allocationsDiff: AllocationAmountsMap,
    shouldConvertValue: boolean
  ): Observable<any>[] {
    let valueToSave;
    return Object.entries(allocationsDiff).map(([timeframeId, amountDiff]) => {
      const targetAllocation = this.findObjectAllocation(target, Number(timeframeId));
      valueToSave = shouldConvertValue ? this.budgetObjectDetailsManager.getConvertedAmount(
        targetAllocation.amount,
        target.currencyCode,
        Number(timeframeId),
        true
      ) : targetAllocation.amount;
      return targetAllocation
        ? this.campaignService.updateCampaignAllocation(
          targetAllocation.id,
          { source_amount: sumAndRound(valueToSave, -amountDiff) }
        ).pipe(
          tap(updatedAlloc => targetAllocation.amount = updatedAlloc.amount)
        )
        : of(null);
    });
  }

  public getParentAllocationsDiff<T>(
    totalDiff: number,
    childrenAmounts: AllocatedToChildrenAmounts<T>,
    parentAmountGetter: (tf: number) => number,
    childAmountGetter: (value: T) => number,
    childrenAllocationsDiff?: AllocationAmountsMap
  ): AllocationAmountsMap | null {
    const allocationsDiff = {};
    let unallocatedDiff = Math.abs(totalDiff);

    if (totalDiff > 0 || !childrenAmounts) {
      return null;
    }

    Object.entries(childrenAmounts.values).forEach(([timeframeId, childValue]) => {
      const parentAmount = parentAmountGetter(Number(timeframeId));
      const childAmount = childrenAllocationsDiff?.[timeframeId]
        ? sumAndRound(childAmountGetter(childValue), -childrenAllocationsDiff[timeframeId])
        : childAmountGetter(childValue);
      const timeframeDiff = sumAndRound(childAmount, -parentAmount);

      if (unallocatedDiff === 0 || timeframeDiff <= 0) {
        return;
      }

      if (unallocatedDiff >= timeframeDiff) {
        unallocatedDiff = sumAndRound(unallocatedDiff, -timeframeDiff);
        allocationsDiff[timeframeId] = sumAndRound(allocationsDiff[timeframeId], -timeframeDiff);
      } else {
        allocationsDiff[timeframeId] = sumAndRound(allocationsDiff[timeframeId], -unallocatedDiff);
        unallocatedDiff = 0;
      }
    });

    return allocationsDiff;
  }

  shouldCheckAllocationAmount(prevState: AllocatableObjectState, currentState: AllocatableObjectState): boolean {
    if (!prevState) {
      return true;
    }
    const stateDiff = this.budgetObjectDetailsManager.compareStates(prevState, currentState);
    return 'amount' in stateDiff || 'allocations' in stateDiff || 'parentObject' in stateDiff;
  }

  private getParent$(sourceParent: LightCampaign | Campaign): Observable<Campaign> {
    return sourceParent.isShort ?
      this.budgetDataService.getCampaigns(
        this.budgetDataService.selectedBudgetSnapshot.company,
        this.budgetDataService.selectedBudgetSnapshot.id,
        this.configuration.campaignStatusNames.active,
        { ids: sourceParent.id.toString() }
      ).pipe(
        map(resCampaigns => resCampaigns[0])
      ) :
      of(sourceParent as Campaign);
  }

  private checkOwnChildrenAllocations$(
    totalAmount: number,
    objectId: number,
    objectType: string,
    programs: Program[] | LightProgram[],
    campaigns: Campaign[] | LightCampaign[]
  ): Observable<AllocationCheckResultData | null> {
    return this.getChildObjects(objectId, programs, campaigns).pipe(
      map(([childPrograms, childCampaigns]) => {
        return objectId != null && objectType === this.configuration.OBJECT_TYPES.campaign ?
            this.getAllocatedToChildrenAmounts(childPrograms, childCampaigns).total :
            0;
      }),
      switchMap((allocatedToOwnChildren: number) => {
        if (allocatedToOwnChildren <= totalAmount) {
          return of(null);
        }

        const goOverBudgetValue = { result: AllocationCheckResult.Ok };
        const declineValue = { result: AllocationCheckResult.NeedOwnAllocationUpdate };
        const campaignName = campaigns.find(camp => camp.id === objectId).name;
        const dialogContent = BudgetObjectAllocationService.getParentOverBudgetAllocationText(campaignName);

        return this.openAsyncConfirmationDialog<AllocationCheckResultData>(null, goOverBudgetValue, declineValue, dialogContent);
      })
    );
  }

  private checkParentAllocations$(
    objectAllocationData: ObjectAllocationData,
    parentObject: BudgetObjectParent,
    programs: Program[] | LightProgram[],
    campaigns: Campaign[] | LightCampaign[]
  ): Observable<AllocationCheckResultData> {
    const { objectId, objectType, totalAmount, allocationAmounts, duplicateObject } = objectAllocationData || {};
    const { parent, grandParent } = this.findParentObjects(campaigns, parentObject?.id);
    const parentIsSegmentless = BudgetObjectService.isSegmentlessObject(parent);

    if (!parent || parentIsSegmentless || parentObject?.type !== this.configuration.OBJECT_TYPES.campaign) {
      return of({ result: AllocationCheckResult.Ok });
    }

    return forkJoin([
      this.getChildObjects(parent.id, programs, campaigns, duplicateObject ? null : { objectId, objectType }),
      this.getParent$(parent)
    ]).pipe(
      switchMap(([parentOwnChildren, parentCampaign]) => {
        const [parentOwnPrograms, parentOwnCampaigns] = parentOwnChildren;

        const parentChildrenAmounts =
          this.getAllocatedToChildrenAmounts(
            parentOwnPrograms,
            parentOwnCampaigns,
            {
              total: totalAmount,
              allocations: allocationAmounts
            }
          );

        const parentTotalAllocated = parentCampaign.amount;
        const parentAllocatedToChildren = parentChildrenAmounts.total;
        const targetTotalAllocated = parentAllocatedToChildren;
        const parentAllocationDiff = this.getTotalAllocationDiff(parentTotalAllocated, parentAllocatedToChildren);

        if (parentAllocationDiff >= 0) {
          return of({ result: AllocationCheckResult.Ok });
        }

        const targetObjName = campaigns.find(c => c.id === objectAllocationData.objectId)?.name;
        const parentObjName = parent?.name;
        const parentAllocationsDiff = this.getParentAllocationsDiff<number>(
          parentAllocationDiff,
          parentChildrenAmounts,
          timeframeId => this.getTimeframeAmount(parentCampaign, timeframeId),
          childValue => childValue
        );

        let grandParentChildrenAmounts = null;
        let grandParentAllocationDiff = null;
        let grandParentAllocationsDiff = null;
        let grandParentUpdateNeeded = false;
        let grandParentCampaign: Campaign = null;
        let grandParentObjName = '';

        const checkGrandParentAllocations$ =
          !grandParent ?
            of(null) :
            forkJoin([
              this.getChildObjects(grandParent.id, programs, campaigns),
              this.getParent$(grandParent)
            ]).pipe(
              tap(([grandParentOwnChildren, grandParentCmp]) => {
                const [grandParentOwnPrograms, grandParentOwnCampaigns] = grandParentOwnChildren;
                grandParentCampaign = grandParentCmp;
                grandParentObjName = grandParentCampaign.name;

                grandParentChildrenAmounts =
                  this.getAllocatedToChildrenAmounts(grandParentOwnPrograms, grandParentOwnCampaigns);

                grandParentAllocationDiff =
                  this.getTotalAllocationDiff(
                    grandParentCampaign.amount,
                    grandParentChildrenAmounts.total,
                    parentAllocationDiff
                  );

                grandParentAllocationsDiff =
                  this.getParentAllocationsDiff<number>(
                    grandParentAllocationDiff,
                    grandParentChildrenAmounts,
                    timeframeId => this.getTimeframeAmount(grandParentCampaign, timeframeId),
                    childValue => childValue,
                    parentAllocationsDiff
                  );

                grandParentUpdateNeeded = grandParentAllocationDiff < 0;
              })
            );

        return checkGrandParentAllocations$.pipe(
          switchMap(() =>
            this.openParentAllocationUpdateDialog({
              targetObjName,
              parentObjName,
              parentTotalAllocated,
              targetTotalAllocated,
              parentAllocationDiff,
              grandParentAllocationDiff,
              grandParentUpdateNeeded,
              parentAllocationsDiff,
              grandParentAllocationsDiff,
              grandParentObjName
            })
          ),
          map(res => {
            if (res.result !== AllocationCheckResult.Ok) {
              res.parentCampaign = parentCampaign;
              res.grandParentCampaign = grandParentCampaign;
            }
            return res;
          })
        );
      })
    );
  }

  checkObjectAllocationAmount(
    objectAllocationData: ObjectAllocationData,
    parentObject: BudgetObjectParent,
    programs: Program[] | LightProgram[],
    campaigns: Campaign[] | LightCampaign[],
    checkOwnChildrenAllocations = true
  ): Observable<AllocationCheckResultData> {
    const { objectId, objectType, totalAmount } = objectAllocationData || {};

    const checkPipe$: Observable<AllocationCheckResultData | null> =
       checkOwnChildrenAllocations && objectId && objectType === this.configuration.OBJECT_TYPES.campaign ?
        this.checkOwnChildrenAllocations$(totalAmount, objectId, objectType, programs, campaigns) :
        of(null);

    return checkPipe$.pipe(
      switchMap(res => res ? of(res) : this.checkParentAllocations$(objectAllocationData, parentObject, programs, campaigns))
    );
  }

  checkAllocationAmountForObjectDetails$(
    prevDetailsState: AllocatableObjectState,
    currentDetailsState: AllocatableObjectState,
    programs: Program[] | LightProgram[],
    campaigns: Campaign[] | LightCampaign[],
    suppressTimeframeAllocations: boolean,
    objectType: string,
    isSegmentlessObject = false
  ): Observable<AllocationCheckResultData> {
    const shouldCheckAllocation = !isSegmentlessObject && this.shouldCheckAllocationAmount(prevDetailsState, currentDetailsState);

    return shouldCheckAllocation ?
      this.checkObjectAllocationAmount(
        this.getObjectAllocationData(currentDetailsState, suppressTimeframeAllocations, objectType),
        currentDetailsState.parentObject,
        programs,
        campaigns
      ) :
      of({ result: AllocationCheckResult.Ok });
  }

  updateObjectRelatedAllocations$(
    allocationsCheckResult: AllocationCheckResultData,
    prevDetailsState: AllocatableObjectState,
    currentDetailsState: AllocatableObjectState,
    budgetTimeframes: BudgetTimeframe[],
    suppressTimeframeAllocations: boolean
  ): Observable<void> {
    if (allocationsCheckResult.allocationDiff < 0) {
      const updatedAllocations =
        this.getUpdatedAllocations(
          prevDetailsState?.allocations,
          currentDetailsState.allocations,
          budgetTimeframes
        );

      return this.updateCampaignAllocationAmount(
        updatedAllocations,
        Math.abs(allocationsCheckResult.allocationDiff),
        { parent: allocationsCheckResult.parentCampaign, grandParent: allocationsCheckResult.grandParentCampaign },
        suppressTimeframeAllocations,
        allocationsCheckResult.parentAllocationsDiff,
        allocationsCheckResult.grandParentAllocationsDiff,
        Math.abs(allocationsCheckResult.grandParentAllocationDiff)
      );
    }

    return of (null);
  }

  private getObjectAllocationData(currentDetailsState: AllocatableObjectState, suppressTimeframeAllocations: boolean, objectType: string) {
    return {
      totalAmount: this.getCurrentAllocationsAmount(currentDetailsState, suppressTimeframeAllocations),
      allocationAmounts: this.getAllocationAmountsMap(currentDetailsState.allocations),
      objectId: currentDetailsState.objectId,
      objectType
    };
  }

  private getDialogConfig(params: ParentDialogParams): ParentDialogConfig {
    const { grandParentUpdateNeeded } = params;

    return grandParentUpdateNeeded
      ? this.dialogsConfig.ParentsNeedUpdate
      : this.dialogsConfig.ParentNeedsUpdate;
  }

  createWarningMessage(allocatedToOwnChildren: number, allocationAmount: number): string {
    return `You are assigning an allocation amount, ${ this.formatNumericValue(allocationAmount) }, to this campaign that
    is less than the allocation amount of the children campaign(s) and expense group(s) associated with this
    campaign ${ this.formatNumericValue(allocatedToOwnChildren) }.
    <br>
    <br>
    Please adjust the allocation amount(s) of these children before saving a new amount to this campaign.`;
  }

  createConfirmMessage(params: Partial<ParentDialogParams>): string {
    const { parentObjName } = params;
    return `The move you are making would put ${parentObjName} over budget. Increase the budget of ${parentObjName} to accommodate this change or let it go over budget?`;
  }

  private getParentsInsufficientAllocationText(params: Partial<ParentDialogParams>): string {
    const { parentObjName, grandParentObjName } = params;
    return `The move you are making would put ${parentObjName} and its parent campaign, ${grandParentObjName}, over budget. Increase the budget of these campaigns to accommodate this change or let it go over budget?`;
  }

  private static getParentOverBudgetAllocationText(objName: string): string {
    return `The change you are making would put ${objName} over budget. Do you want to let ${objName} go over budget?`;
  }

  private getChildObjects(
    campaignId: number,
    programs: Program[] | LightProgram[],
    campaigns: Campaign[] | LightCampaign[],
    excludeObject?: { objectId: number; objectType: string }
  ): Observable<[Program[], Campaign[]]> {
    const { objectId, objectType } = excludeObject || {};

    const childPrograms = [
      ...programs.filter(
          prg =>
            prg.campaignId === campaignId &&
            !(objectType === this.configuration.OBJECT_TYPES.program && objectId === prg.id)
        )
    ];

    const childCampaigns = [
      ...(campaigns || []).filter(
        campaign =>
          campaign.parentCampaign === campaignId &&
          !(objectType === this.configuration.OBJECT_TYPES.campaign && objectId === campaign.id)
      )
    ];

    const fullChildCampaigns$: Observable<Campaign[]> =
      childCampaigns?.length && childCampaigns[0].isShort ?
        this.budgetDataService.getCampaigns(
          this.budgetDataService.selectedBudgetSnapshot.company,
          this.budgetDataService.selectedBudgetSnapshot.id,
          this.configuration.campaignStatusNames.active,
          { ids: childCampaigns.map(cmp => cmp.id).join(',') }
        ) :
        of (childCampaigns as Campaign[]);

    const fullChildPrograms$: Observable<Program[]> =
      childPrograms?.length && childPrograms[0].isShort ?
        this.budgetDataService.getPrograms(
          this.budgetDataService.selectedBudgetSnapshot.company,
          this.budgetDataService.selectedBudgetSnapshot.id,
          this.configuration.programStatusNames.active,
          { ids: childPrograms.map(prg => prg.id).join(',') }
        ) :
        of (childPrograms as Program[]);

    return forkJoin([fullChildPrograms$, fullChildCampaigns$]);
  }

  getAllocatedToChildrenAmounts(
    childPrograms: Program[],
    childCampaigns: Campaign[],
    extraAmounts?: {
      total: number;
      allocations: AllocationAmountsMap;
    }
  ): AllocatedToChildrenAmounts<number> {
    const initialValues: AllocatedToChildrenAmounts<number> = { total: 0, values: {} };

    const addAllocatedToChildAmounts = (totalValues: AllocationAmountsMap, childObject: SegmentedBudgetObject) => {
      return childObject.timeframes.reduce(
        (result, timeframe) => {
          const { company_budget_alloc: timeframeId, amount } = timeframe;

          return {
            ...result,
            [timeframeId]: sumAndRound(totalValues[timeframeId], amount)
          };
        },
        {}
      );
    };

    const childrenAmounts =
      [...childPrograms, ...childCampaigns].reduce(
        (result, childObj) => ({
          total: sumAndRound(result.total, childObj.amount),
          values: addAllocatedToChildAmounts(result.values, childObj)
        }),
        initialValues
      );

    if (extraAmounts) {
      childrenAmounts.total = sumAndRound(childrenAmounts.total, extraAmounts.total);
      Object.entries(extraAmounts.allocations || {})
        .forEach(([ timeframeId, amount ]) => {
          if (!childrenAmounts.values[timeframeId]) {
            childrenAmounts.values[timeframeId] = 0;
          }
          childrenAmounts.values[timeframeId] = sumAndRound(childrenAmounts.values[timeframeId], amount);
        });
    }

    return childrenAmounts;
  }

  updateCampaignAllocationAmount(
    updatedChildAllocs: Pick<BudgetObjectAllocation, 'amount' | 'company_budget_alloc'>[],
    parentAllocationDiff: number,
    campaigns: { parent: Campaign | null; grandParent: Campaign | null },
    suppressTFAllocs: boolean,
    parentAllocationsDiff?: AllocationAmountsMap,
    grandParentAllocationsDiff?: AllocationAmountsMap,
    grandParentAllocationDiff?: number
  ): Observable<void> {
    const { parent, grandParent } = campaigns || {};

    if (!parent || !parentAllocationDiff || typeof parentAllocationDiff !== 'number' || Number.isNaN(parentAllocationDiff)) {
      return of(null);
    }

    const targetCampaignAllocation =
      suppressTFAllocs ?
        null :
        this.getTargetAllocationForUpdate(updatedChildAllocs, parent.timeframes);

    const updateAllocationAmountForCampaign$ = (
      campaign: Campaign,
      campaignAllocation: SegmentedObjectTimeframe,
      allocDiff: number,
      finalStep?: () => Observable<void>,
      allocationsDiff?: AllocationAmountsMap
    ) => {
      const shouldConvertValue = !!this.budgetDataService.selectedBudgetSnapshot?.new_campaigns_programs_structure && campaign.currencyCode !== this.companyDataService.selectedCompanyDOSnapshot.currency;
      let valueToSave = shouldConvertValue ? this.budgetObjectDetailsManager.getConvertedAmount(
        campaignAllocation.amount,
        campaign.currencyCode,
        Number(campaignAllocation.id),
        true
      ) : campaignAllocation.amount;
        let updateAllocations$: Observable<any> =
          campaignAllocation ?
            this.campaignService.updateCampaignAllocation(
              campaignAllocation.id,
              { source_amount: valueToSave + allocDiff }
            ).pipe(
              tap(updatedAlloc => campaignAllocation.amount = updatedAlloc.amount)
            ) :
            of(null);

        if (allocationsDiff && Object.entries(allocationsDiff).length) {
          updateAllocations$ = forkJoin(this.prepareAllocationDiffsRequest(campaign, allocationsDiff, shouldConvertValue));
        }
        return updateAllocations$.pipe(
          switchMap(
            _ => this.campaignService.updateCampaign(campaign.id, { source_amount: campaign.amount + allocDiff })
          ),
          tap(updatedCampaign => campaign.amount = updatedCampaign.amount),
          switchMap(_ => finalStep?.() || of(null))
        );
      };

    const updateParentCampaign$ = () => {
      if (!grandParent || typeof grandParentAllocationDiff !== 'number' || grandParentAllocationDiff < 0) {
        return of(null);
      }

      const targetParentCampaignAllocation = suppressTFAllocs ?
        null :
        this.findObjectAllocation(grandParent, targetCampaignAllocation.company_budget_alloc);

      return updateAllocationAmountForCampaign$(
        grandParent,
        targetParentCampaignAllocation,
        grandParentAllocationDiff,
        null,
        grandParentAllocationsDiff
      );
    };

    return updateAllocationAmountForCampaign$(
      parent,
      targetCampaignAllocation,
      parentAllocationDiff,
      updateParentCampaign$,
      parentAllocationsDiff
    );
  }

  getTargetAllocationForUpdate(
    updatedChildObjectAllocs: Pick<BudgetObjectAllocation, 'amount' | 'company_budget_alloc'>[],
    parentObjectAllocs: SegmentedObjectTimeframe[]
  ): SegmentedObjectTimeframe {
    if (!parentObjectAllocs?.length) {
      return null;
    }

    const targetAllocData =
      (updatedChildObjectAllocs || []).reduce(
        (res: { alloc: SegmentedObjectTimeframe; diff: number }, objAlloc: BudgetObjectAllocation) => {
          const parentObjAlloc = parentObjectAllocs.find(alloc => alloc.company_budget_alloc === objAlloc.company_budget_alloc);
          const allocDiff = objAlloc.amount - parentObjAlloc.amount;
          return !res || allocDiff > res.diff ? { alloc: parentObjAlloc, diff: allocDiff } : res;
        },
        null
      );

    return targetAllocData?.alloc;
  }

  getUpdatedAllocations(
    prevAllocs: BudgetObjectAllocation[],
    newAllocs: BudgetObjectAllocation[],
    budgetTimeframes: BudgetTimeframe[]
  ): BudgetObjectAllocation[] {
    if (!prevAllocs?.length) {
      return newAllocs;
    }
    const updatedAllocs = (newAllocs || []).filter(
      newAlloc => prevAllocs.find(
        prevAlloc => prevAlloc.id === newAlloc.id && prevAlloc.amount !== newAlloc.amount
      )
    );
    return updatedAllocs.length ?
      updatedAllocs :
      newAllocs.filter(alloc => {
        const budgetTf = budgetTimeframes.find(btf => btf.id === alloc.company_budget_alloc);
        return !budgetTf?.locked;
      });
  }

  getCurrentAllocationsAmount<TState extends AllocatableObject & SegmentedObjectDetailsState>(
    state: TState,
    suppressTimeframeAllocation: boolean
  ) {
    return suppressTimeframeAllocation ?
      state.amount :
      state.allocations.reduce((sum: number, alloc) => sumAndRound(alloc.amount, sum), 0);
  }

  public getAllocationAmountsMap(allocations: {
    company_budget_alloc: number,
    amount?: number
  }[]): AllocationAmountsMap {
    return allocations.reduce((result, alloc) => ({
      ...result,
      [alloc.company_budget_alloc]: getNumericValue(alloc.amount)
    }), {});
  }

  public openParentAmountShortageDialog(content: string) {
    this.dialogManager.openConfirmationDialog({
      title: this.dialogsConfig.ParentAmountShortage.title,
      content,
      submitAction: {
        label: this.dialogsConfig.ParentAmountShortage.submitLabel,
        handler: null
      }
    }, { width: '480px' });
  }

  private executeGoOverBudget<T>(goOverBudgetValue: T): Observable<T> {
    const confirmationResult$ = new Subject<T>();
    Promise.resolve(goOverBudgetValue).then(function resolve() {
      confirmationResult$.next(goOverBudgetValue);
      confirmationResult$.complete();
    });
    return confirmationResult$;
  }

  public openParentAllocationUpdateDialog(params: ParentDialogParams): Observable<AllocationCheckResultData> {
    const {
      grandParentUpdateNeeded,
      parentAllocationDiff,
      grandParentAllocationDiff,
      parentAllocationsDiff,
      grandParentAllocationsDiff
    } = params;
    const dialogConfig = this.getDialogConfig(params);

    const goOverBudgetValue = {
      result: grandParentUpdateNeeded
        ? AllocationCheckResult.NeedParentAndGrandParentAllocationUpdate
        : AllocationCheckResult.NeedParentAllocationUpdate,
    };
    const increaseBudgetValue = {
      ...goOverBudgetValue,
      allocationDiff: parentAllocationDiff,
      grandParentAllocationDiff,
      parentAllocationsDiff,
      grandParentAllocationsDiff
    };
    const declineValue = {
      result: AllocationCheckResult.NeedOwnAllocationUpdate
    };

    let res;
    if (this.budgetDataService.selectedBudgetSnapshot?.new_campaigns_programs_structure) {
      res = this.executeGoOverBudget<AllocationCheckResultData>(goOverBudgetValue);
    } else {
      res = this.openAsyncConfirmationDialog<AllocationCheckResultData>(
        increaseBudgetValue, goOverBudgetValue, declineValue, dialogConfig.getContent(params)
      );
    }
    return res;
  }

  public checkAllocationAmountAndCloneSegmentedObject(
    object: SegmentedBudgetObject,
    objectType: string,
    parentId: number,
    clone$: Observable<BudgetObjectCloneResponse>,
    campaigns: Campaign[] | LightCampaign[],
    programs: Program[] | LightProgram[],
    suppressTFAllocations: boolean
  ): Observable<BudgetObjectCloneResponse> {
    const check$: Observable<AllocationCheckResultData> =
      parentId ?
        this.checkObjectAllocationAmount(
          {
            objectId: object.id,
            objectType: objectType,
            totalAmount: object.amount,
            duplicateObject: true,
            allocationAmounts: this.getAllocationAmountsMap(object.timeframes)
          },
          { id: parentId, type: this.configuration.OBJECT_TYPES.campaign },
          programs,
          campaigns,
          false
        ) :
        of({ result: AllocationCheckResult.Ok });

    return check$.pipe(
      switchMap(res => {
        if (res.result !== AllocationCheckResult.NeedOwnAllocationUpdate) {
          return clone$.pipe(
            switchMap(data =>
              (res.allocationDiff < 0 ?
                  this.updateCampaignAllocationAmount(
                    object.timeframes,
                    Math.abs(res.allocationDiff),
                    { parent: res.parentCampaign, grandParent: res.grandParentCampaign },
                    suppressTFAllocations,
                    res.parentAllocationsDiff,
                    res.grandParentAllocationsDiff,
                    Math.abs(res.grandParentAllocationDiff)
                  ) :
                  of(null)
              ).pipe(
                map(() => data)
              )
            )
          );
        }
        return of(null);
      })
    );
  }

  private openAsyncConfirmationDialog<T>(increaseBudgetValue: T, goOverBudgetValue: T, declineValue: T, content: string): Observable<T> {
    const confirmationResult$ = new Subject<T>();
    const handleFn = value => {
      confirmationResult$.next(value);
      confirmationResult$.complete();
    };

    const actions = [
      {
        label: 'Cancel',
        handler: () => handleFn(declineValue)
      },
      goOverBudgetValue ? {
        label: 'Go Over Budget',
        type: DIALOG_ACTION_TYPE.FLAT,
        handler: () => handleFn(goOverBudgetValue)
      } : null,
      increaseBudgetValue ? {
        label: 'Increase Budget',
        type: DIALOG_ACTION_TYPE.FLAT,
        handler: () => handleFn(increaseBudgetValue)
      } : null,
    ];

    this.dialogManager.openConfirmationDialog({
      title: 'This could put you over budget',
      content,
      actions: actions.filter(action => !!action),
    }, { width: '480px', panelClass: 'allocation-confirmation-dialog' })
      .afterClosed()
      .subscribe(
        confirmed => {
          if (!confirmed) {
            handleFn(declineValue);
          }
        }
      );

    return confirmationResult$;
  }
}
