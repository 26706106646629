<div class="box-widget-header">
  <div class="box-widget-header__controls" >
    <ng-container *ngIf="allowSwitchViews">
      <button [class.active]="!tableView" class="widget-btn widget-btn-toggle" type="button"
              (click)="changeTableView(false)">
        <img src="assets/images/widget-container-settings/icon-chart-view.svg" alt="chart-view icon">
        <span>Chart</span>
      </button>
      <button [class.active]="tableView" class="widget-btn widget-btn-toggle" type="button"
              (click)="changeTableView(true)">
        <img src="assets/images/widget-container-settings/icon-table-view.svg" alt="table-view icon">
        <span>Table</span>
      </button>
    </ng-container>
  </div>
  <ng-content select=".widget-heading"></ng-content>
  <div class="box-widget-header__controls">
    <button *ngIf="currentViewActionItems.length > 0" [class.active]="actionsMenu.isActionsMenuActivated"
            class="widget-btn ml-a"
            (click)="actionsMenu.toggleMenuState()"
            aria-label="Open actions list"
            type="button">
      <span class="d-flex">
        <fa-duotone-icon class="cog-icon" [icon]="['fad', 'cog']"></fa-duotone-icon>
        <fa-icon class="chevron-icon" [icon]="['fas', actionsMenu.isActionsMenuActivated ? 'chevron-up' : 'chevron-down']"></fa-icon>
      </span>
    </button>
  </div>

</div>
<actions-menu #actionsMenu [actionsMenuItems]="currentViewActionItems"></actions-menu>
<ng-content select=".widget-content-graph" *ngIf="!tableView"></ng-content>
<ng-content select=".widget-content-table" *ngIf="tableView"></ng-content>



