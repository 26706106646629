<div class="simple-tooltip"
  [ngClass]="customClassNames"
  [class.is-visible]="visible"
  [class.position-top]="position === TooltipPosition.Top"
  [class.position-right]="position === TooltipPosition.Right"
  [class.position-bottom]="position === TooltipPosition.Bottom"
  [class.position-left]="position === TooltipPosition.Left"
  [ngStyle]="offsetX && offsetY && { top: offsetY + 'px', left: offsetX + 'px' }"
>
  <ng-content></ng-content>
</div>
