import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HierarchyViewMode, ViewModeCustomIcons } from '@spending/types/expense-page.type';
import { MenuPanelItem } from 'app/header-navigation/components/menu-panel/menu-panel.type';

@Component({
  selector: 'view-mode-select-btn',
  templateUrl: './view-mode-select-btn.component.html',
  styleUrls: ['./view-mode-select-btn.component.scss']
})
export class ViewModeSelectBtnComponent implements OnInit, OnChanges {
  @Input() selectedMode: HierarchyViewMode;
  @Input() disabled: boolean;

  @Output() onSelectMode = new EventEmitter<HierarchyViewMode>();

  protected hierarchyViewModeTitle = {
    [HierarchyViewMode.Segment]: 'Segment',
    [HierarchyViewMode.Goal]: 'Goal',
    [HierarchyViewMode.Campaign]: 'Campaign',
    [HierarchyViewMode.GlCode]: 'GL Code',
    [HierarchyViewMode.Source]: 'Source',
    [HierarchyViewMode.Status]: 'Status',
    [HierarchyViewMode.Timeframe]: 'Timeframe',
    [HierarchyViewMode.Vendor]: 'Vendor',
  };
  readonly viewModeCustomIcons = ViewModeCustomIcons;

  // temporary move 'Status' to separate variable for quick access
  private byStatusOption: MenuPanelItem = {
    value: HierarchyViewMode.Status,
    label: this.hierarchyViewModeTitle[HierarchyViewMode.Status],
    customIcon: this.viewModeCustomIcons.HEART_RATE,
    customCssClass: 'ut-view-mode-status'
  };
  protected menuPanelItems: MenuPanelItem[] = [
    {
      value: HierarchyViewMode.Segment,
      label: this.hierarchyViewModeTitle[HierarchyViewMode.Segment],
      faIcon: ['fad', 'chart-pie-alt'],
      isSelected: true,
      customCssClass: 'ut-view-mode-segment'
    },
    {
      value: HierarchyViewMode.Goal,
      label: this.hierarchyViewModeTitle[HierarchyViewMode.Goal],
      faIcon: ['fad', 'bullseye-arrow'],
      customCssClass: 'ut-view-mode-goal'
    },
    {
      value: HierarchyViewMode.Campaign,
      label: this.hierarchyViewModeTitle[HierarchyViewMode.Campaign],
      faIcon: ['fad', 'rocket-launch'],
      customCssClass: 'ut-view-mode-campaign'
    },
    {
      value: HierarchyViewMode.GlCode,
      label: this.hierarchyViewModeTitle[HierarchyViewMode.GlCode],
      faIcon: ['fad', 'barcode-alt'],
      isDivider: true,
      customCssClass: 'ut-view-mode-glcode'
    },
    {
      value: HierarchyViewMode.Source,
      label: this.hierarchyViewModeTitle[HierarchyViewMode.Source],
      customIcon: this.viewModeCustomIcons.PLUG,
      customCssClass: 'ut-view-mode-source'
    },
    this.byStatusOption,
    {
      value: HierarchyViewMode.Timeframe,
      label: this.hierarchyViewModeTitle[HierarchyViewMode.Timeframe],
      faIcon: ['fad', 'calendar-alt'],
      customCssClass: 'ut-view-mode-timeframe'
    },
    {
      value: HierarchyViewMode.Vendor,
      label: this.hierarchyViewModeTitle[HierarchyViewMode.Vendor],
      customIcon: this.viewModeCustomIcons.USER_HELMET_SAFETY,
      customCssClass: 'ut-view-mode-vendor'
    },
  ];
  @Input() set statuslessExpenses(isStatusless: boolean) {
    this.byStatusOption.hidden = isStatusless;
    if (isStatusless && this.selectedMode === HierarchyViewMode.Status) {
      this.onSelectMode.emit(HierarchyViewMode.Segment);
    }
  };

  ngOnInit(): void {
    this.menuPanelItems.forEach(panelItem => {
      panelItem.action = (item) => this.selectMode(item.value as HierarchyViewMode);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedMode) {
      this.menuPanelItems.forEach(panelItem => {
        panelItem.isSelected = this.selectedMode === panelItem.value;
      });
    }
  }

  selectMode(mode: HierarchyViewMode): void {
    if (mode === this.selectedMode) {
      return;
    }
    this.onSelectMode.emit(mode);
  }
}
