import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[isScrolled]'
})
export class IsScrolledDirective implements OnInit, OnDestroy {
  @Input() vertical = true;
  @Output() isScrolledChanged = new EventEmitter<boolean>();

  private readonly targetScrolled = new Subject<boolean>();
  private readonly destroy$ = new Subject<void>();

  ngOnInit() {
    this.targetScrolled.asObservable()
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((isScrolled) => {
        this.isScrolledChanged.emit(isScrolled);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('scroll', ['$event.target'])
  onScroll(target) {
    const value = this.vertical ? target.scrollTop : target.scrollLeft;

    this.targetScrolled.next(value > 0);
  }
}
