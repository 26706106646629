import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppDataLoader } from 'app/app-data-loader.service';
import { MetricFunnelsPageService } from '../../services/metric-funnels-page.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { UtilityService } from 'app/shared/services/utility.service';
import { MetricDO, MetricService } from 'app/shared/services/backend/metric.service';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { MetricFunnelsValidationService } from '@common-lib/lib/utils/metric-funnels-validation.service';
import { MetricFunnelsCalculationsService } from '@common-lib/lib/utils/metric-funnels-calculations.service';
import { FunnelStateDifferences, MetricActions, MetricMaster, MetricRowsDifferences, ProductFunnelView, ProductMetricFunnel } from '@common-lib/lib/corporate-page/metric-funnels.types';
import { CompanyDataService } from '@shared/services/company-data.service';

export const PANELS_LS_KEY = 'funnels_local_view_state';
export const LS_KEY_ACTIVE_METRIC_MASTER_ID = 'active_metric_id';

export interface PanelState {
  expansion: Record<string, boolean>;
  viewMode: Record<string, ProductFunnelView>;
}

export interface PanelStates {
  [companyId: number]: PanelState;
}

@Component({
  selector: 'metrics-page',
  templateUrl: './metrics-page.component.html',
  styleUrls: ['./metrics-page.component.scss'],
  providers: [
    AppDataLoader,
    MetricFunnelsPageService,
    MetricFunnelsCalculationsService,
    MetricFunnelsValidationService,
  ]
})
export class MetricsPageComponent implements OnInit, OnDestroy {
  standaloneMetrics$: Observable<MetricMaster[]> = this.metricFunnelsPageService.standaloneMetrics$;
  metricFunnels: ProductMetricFunnel[];

  standaloneEditing = false;
  standalonePanelExpanded = false;
  funnelOnEditId: number = null;
  creationProcess = false;
  dataUpdating: Record<string, boolean> = {};

  panelsStates: Record<string, boolean> = {};
  panelsViewModes: Record<string, ProductFunnelView> = {};
  prevCompanyId: number = null;
  destroy$ = new Subject<void>();
  productFunnelView = ProductFunnelView;
  restoredActiveMetricMasterId: number;
  multiActions = {
    [MetricActions.CREATE]: this.metricService.createMultiMetrics.bind(this.metricService),
    [MetricActions.UPDATE]: this.metricService.updateMultiMetrics.bind(this.metricService),
    [MetricActions.DELETE]: this.metricService.deleteMultiMetrics.bind(this.metricService)
  }
  @ViewChild('pageContainer') pageContainer: ElementRef;
  @ViewChild('standaloneMetricsWrap') standaloneMetricsWrap: ElementRef;

  constructor(
    private readonly appDataLoader: AppDataLoader,
    public readonly metricFunnelsPageService: MetricFunnelsPageService,
    private readonly metricFunnelsValidationService: MetricFunnelsValidationService,
    private readonly utilityService: UtilityService,
    private readonly metricService: MetricService,
    private readonly companyDataService: CompanyDataService
  ) {
    // should be in constructor for access to router.getCurrentNavigation()
    this.restoredActiveMetricMasterId = this.metricFunnelsPageService.readState();
  }

  ngOnInit(): void {
    this.appDataLoader.init();

    this.metricFunnelsPageService.metricFunnels$.pipe(
      filter(data => !!data),
      takeUntil(this.destroy$),
    ).subscribe(funnels => {
      this.processPanelStates();
      this.metricFunnels = funnels;
      this.metricFunnelsValidationService.updateFunnelsList(this.metricFunnels);
      this.showRestoredActiveMetric();
    });
  }

  get companyId(): number {
    return this.metricFunnelsPageService.companyId;
  }

  public trackFn(index, item: ProductMetricFunnel) {
    return item.id;
  }

  public showRestoredActiveMetric() {
    if (!this.restoredActiveMetricMasterId) {
      return;
    }

    const standaloneMetric = this.metricFunnelsPageService.standaloneMetricsSnapshot()
      .find(metric => metric.id === this.restoredActiveMetricMasterId);
    if (standaloneMetric) {
      this.standalonePanelExpanded = true;
    } else {
      const metricHtmlElement = document.querySelector(`[data-metric-id="${this.restoredActiveMetricMasterId}"]`);
      if (metricHtmlElement) {
        metricHtmlElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'start'
        });
        this.restoredActiveMetricMasterId = null;
      }
    }
  }

  processPanelStates() {
    if (this.prevCompanyId !== this.companyId) {
      if (!!this.prevCompanyId) {
        this.storePanelStates(this.prevCompanyId);
      }
      this.prevCompanyId = this.companyId;
      const viewStates = this.getStoredPanelStates(this.companyId);
      this.panelsStates = viewStates.expansion;
      this.panelsViewModes = viewStates.viewMode;
    }
  }

  storePanelStates(companyId = this.companyId): void {
    const panelsStates = LocalStorageService.getFromStorage<PanelStates>(PANELS_LS_KEY) || {};
    panelsStates[companyId] = {
      expansion: this.panelsStates,
      viewMode: this.panelsViewModes,
    };
    LocalStorageService.addToStorage(PANELS_LS_KEY, panelsStates);
  }

  getStoredPanelStates(companyId: number): PanelState {
    const panelsStates = LocalStorageService.getFromStorage<PanelStates>(PANELS_LS_KEY) || {};
    return panelsStates[companyId] || {
      expansion: {},
      viewMode: {},
    };
  }

  setStandaloneEditMode(state: boolean) {
    this.standaloneEditing = state;
  }

  setFunnelEditId(funnelId: number) {
    if (funnelId === null && this.funnelOnEditId === 0) {
      // "cancel" was pressed on example/empty editing
      this.metricFunnelsPageService.removeFunnelExample();
    }
    this.funnelOnEditId = funnelId;
  }

  setFunnelExpansionState(id: number, state: boolean) {
    this.panelsStates[id] = state;
  }

  setFunnelViewMode(id: number, mode: ProductFunnelView) {
    this.panelsViewModes[id] = mode;
    this.storePanelStates();
  }

  saveStandalone(metricChanges: MetricRowsDifferences) {
    this.setStandaloneEditMode(false);
    this.utilityService.showLoading(true);
    const allActions = this.standaloneMetricActions(metricChanges);
    const cb = () => {
      this.metricFunnelsPageService.loadMetricsForCompany();
      this.utilityService.showLoading(false);
    }
    forkJoin(allActions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(cb);
  }

  saveFunnel(data: Partial<FunnelStateDifferences>, funnelId: number) {
    this.setLoadingState([funnelId], true);
    this.funnelOnEditId = null;
    const saveFunnelData$ = funnelId === 0 ?
      this.metricFunnelsPageService.createFunnelData$(data.originalFunnel, this.metricFunnels) :
      this.metricFunnelsPageService.updateFunnelData(data, data.originalFunnel);

    saveFunnelData$.pipe(
      finalize(() => {
        this.setLoadingState([funnelId], false);
      })
    ).subscribe();
  }

  duplicateFunnel(funnelIndex: number) {
    this.setLoadingState([0], true);
    this.metricFunnelsPageService.duplicateFunnel(funnelIndex).pipe(
      takeUntil(this.destroy$),
      finalize(() => {
        this.setLoadingState([0], true);
      }),
    ).subscribe();
  }

  addNewFunnel(addExampleValues = false) {
    const newFunnelId = this.metricFunnelsPageService.createFunnelExample(addExampleValues);
    this.setFunnelEditId(newFunnelId);
    this.setFunnelExpansionState(newFunnelId, true);
    this.scrollFunnelsIntoView();
  }

  scrollFunnelsIntoView() {
    const container = this.pageContainer.nativeElement;
    const standaloneMetricsWrap = this.standaloneMetricsWrap.nativeElement;
    container.scrollTo({ top: standaloneMetricsWrap.offsetHeight, behavior: 'smooth' });
  }

  setLoadingState(ids: number[], state: boolean): void {
    this.utilityService.showLoading(state);
    if (ids.length) {
      ids.forEach(funnelId => {
        this.dataUpdating[funnelId] = state;
      });
    }
  }

  changeFunnelOrder(moveUp: boolean, currentIndex: number) {
    const targetIndex = moveUp ? currentIndex - 1 : currentIndex + 1;
    if (targetIndex < 0 || targetIndex >= this.metricFunnels.length) {
      return;
    }
    const targetEl = this.metricFunnels[targetIndex];
    const currentEl = this.metricFunnels[currentIndex];
    const targetOrder = targetEl.order;
    const currentOrder = currentEl.order;

    this.setLoadingState([targetEl.id, currentEl.id], true);

    forkJoin([
      this.metricFunnelsPageService.updateFunnelOwnProps$({ order: currentOrder }, targetEl, true),
      this.metricFunnelsPageService.updateFunnelOwnProps$({ order: targetOrder }, currentEl, true),
    ]).pipe(
      finalize(() => {
        this.setLoadingState([targetEl.id, currentEl.id], false);
      })
    ).subscribe(() => {
      currentEl.order = targetOrder;
      targetEl.order = currentOrder;
      this.metricFunnels[targetIndex] = currentEl;
      this.metricFunnels[currentIndex] = targetEl;
      this.metricFunnelsPageService.metricFunnels = this.metricFunnels;
    })
  }

  setFunnelState(newState: boolean, funnel: ProductMetricFunnel) {
    this.setLoadingState([funnel.id], true);
    this.metricFunnelsPageService.updateFunnelOwnProps$({ activated: newState }, funnel).pipe(
      tap(() => this.companyDataService.loadProducts(this.metricFunnelsPageService.companyId)),
      finalize(() => this.setLoadingState([funnel.id], false))
    ).subscribe()
  }

  openObjectsUsingMetric(metric: MetricMaster) {
    this.metricFunnelsPageService.openManagePageForObjects(metric.id);
  }

  removeFunnel(funnel: ProductMetricFunnel): void {
    this.utilityService.showLoading(true);
    this.metricFunnelsPageService.removeFunnel(funnel)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.utilityService.showLoading(false);
        this.metricFunnelsPageService.loadMetricsForCompany();
      })
  }

  standaloneMetricActions(metricChanges: MetricRowsDifferences): Observable<any>[] {
    const getPayload = (action, payload: MetricMaster[]): Partial<MetricDO>[] => {
      return payload.map(param => {
        const { id, name, target } = param;
        const order = param.rowIndex;
        if (action === MetricActions.CREATE) {
          return { company: this.companyId, name, order, target };
        }
        return { id, name, order, target };
      });
    }

    const metricActions = Object.keys(metricChanges).map(action => {
      if (!!metricChanges[action].length) {
        return this.multiActions[action](
          action === MetricActions.DELETE
            ? metricChanges[action]
            : getPayload(action, metricChanges[action])
        );
      }
    }).filter(activeAction => !!activeAction);

    return metricActions;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.storePanelStates();
  }
}
