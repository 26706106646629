import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'add-new-metrics-row',
  templateUrl: './add-new-metrics-row.component.html',
  styleUrls: ['./add-new-metrics-row.component.scss']
})
export class AddNewMetricsRowComponent {
  @Input() index: number;
  @Input() editMode: boolean;
  @Input() isFlipped: boolean;
  @Input() hideBottomBtn: boolean;
  @Output() addRowAtIndex = new EventEmitter<number>();
}
