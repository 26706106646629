export enum DashboardWidgetViewMode {
  Table = 'table',
  Chart = 'chart'
}

export enum DashboardWidgetType {
  SpendingTimeline = 'SpendingTimeline',
  SpendByType = 'SpendByType',
  BudgetHierarchy = 'BudgetHierarchy'
}
