import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

const linkifyHtml = require('linkify-html').default;

interface CaretPosition {
  offset: number;
  done: boolean;
}

@Component({
  selector: 'linkified-text',
  templateUrl: './linkified-text.component.html',
  styleUrls: ['./linkified-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [    {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: LinkifiedTextComponent
  }]
})
export class LinkifiedTextComponent implements ControlValueAccessor {
  @Input() label: String;
  @Input() appearance: 'fill' | 'outline' = 'fill';

  @ViewChild('content', { static: true }) content: ElementRef;

  touched = false;
  disabled = false;
  isFocused = false;

  constructor(
    private readonly renderer: Renderer2,
    private readonly sanitizer: DomSanitizer
  ) { }

  onChange: Function = () => {};
  onTouched: Function = () => {};
  removeDisabledState: Function = () => {};

  writeValue(content: string): void {
    const normalizedContent = content === null ? '' : content;
    this.renderer.setProperty(
      this.content.nativeElement,
      'innerHTML',
      normalizedContent
    );
  }

  registerOnChange(onChange: Function): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onEdit(): void {
    this.markAsTouched();
    if (!this.disabled) {
      this.onChange(this.content.nativeElement.innerHTML);
    }
  }

  onClick(e: MouseEvent): void {
    if ((e.target as HTMLElement).tagName === 'A') {
      window.open(this.sanitizer.sanitize(SecurityContext.URL, (e.target as HTMLLinkElement).href), '_blank').focus();
    }
  }

  onKeyDown(e: KeyboardEvent): void {
    if (e.code === 'Space' || e.code === 'Enter') {
      this.findLinks();
    }
  }

  onPaste(): void {
    setTimeout(() => {
      this.findLinks();
      this.onEdit();
    });
  }

  onFocus(): void {
    this.isFocused = true;
  }

  onBlur(): void {
    const contentEl = this.content.nativeElement;
    if (contentEl.innerHTML === '<br>') {
      contentEl.innerHTML = '';
    }
    this.isFocused = false;
  }

  setFocus(): void {
    this.content.nativeElement.focus();
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    if (disabled) {
      this.renderer.setAttribute(this.content.nativeElement, 'disabled', 'true');
      this.removeDisabledState = this.renderer.listen(this.content.nativeElement, 'keydown', this.disabledStateCallback);
    } else {
      if (this.removeDisabledState) {
        this.renderer.removeAttribute(this.content.nativeElement, 'disabled');
        this.removeDisabledState();
      }
    }
  }

  private disabledStateCallback(e: KeyboardEvent): void {
    e.preventDefault();
  }

  private findLinks(): void {
    const contentEl = this.content.nativeElement;

    // Get a caret position
    const selection = window.getSelection();
    const offset = selection.focusOffset;
    const caretPosition = this.getCaretPosition(contentEl, selection.focusNode, offset, {
      offset: 0,
      done: false
    });
    if (offset === 0) { caretPosition.offset += 0.5; }

    contentEl.innerHTML = linkifyHtml(this.sanitizer.sanitize(SecurityContext.HTML, contentEl.innerHTML));

    // Set a caret position
    selection.removeAllRanges();
    const range = this.setCaretPosition(contentEl, document.createRange(), {
      offset: caretPosition.offset,
      done: false
    });
    range.collapse(true);
    selection.addRange(range);
  }

  private getCaretPosition(parent: HTMLElement, node: Node, offset: number, state: CaretPosition): CaretPosition {
    if (state.done) { return state; }

    let currentNode = null;
    if (parent.childNodes.length === 0) {
      state.offset += parent.textContent.length;
    } else {
      for (let i = 0; i < parent.childNodes.length && !state.done; i++) {
        currentNode = parent.childNodes[i];
        if (currentNode === node) {
          state.offset += offset;
          state.done = true;
          return state;
        } else {
          this.getCaretPosition(currentNode, node, offset, state);
        }
      }
    }
    return state;
  }

  private setCaretPosition(parent: HTMLElement, range: Range, state: CaretPosition): Range {
    if (state.done) { return range; }

    if (parent.childNodes.length === 0) {
      if (parent.textContent.length >= state.offset) {
        range.setStart(parent, state.offset);
        state.done = true;
      } else {
        state.offset = state.offset - parent.textContent.length;
      }
    } else {
      for (let i = 0; i < parent.childNodes.length && !state.done; i++) {
        const currentNode = parent.childNodes[i];
        this.setCaretPosition(currentNode as HTMLElement, range, state);
      }
    }
    return range;
  }

}
