import { Component, Input } from '@angular/core';

@Component({
  selector: 'budget-owner',
  templateUrl: './budget-owner.component.html',
  styleUrls: ['./budget-owner.component.scss']
})
export class BudgetOwnerComponent {
  @Input() ownerName = 'Nicole Doe';
}
