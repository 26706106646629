import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Configuration } from 'app/app.constants';
import { UNIQUE_DOMAIN_PREFIX_VALIDATION_ERROR_NAME } from './unique-domain-prefix.directive';

export interface EnableSsoDialogContext {
  customDomain?: string;
  existingDomains: string[];
  okHandler: (customDomain: string) => void
}

@Component({
  selector: 'app-enable-sso-dialog',
  templateUrl: './enable-sso-dialog.component.html',
  styleUrls: ['./enable-sso-dialog.component.scss']
})
export class EnableSsoDialogComponent {
  public readonly uniqueDomainPrefixErrorName = UNIQUE_DOMAIN_PREFIX_VALIDATION_ERROR_NAME;
  public context: EnableSsoDialogContext;
  public customDomain: string|null = null;
  public domainSuffix: string;
  public MIN_DOMAIN_LENGTH = 3;

  constructor(
    public dialogRef: MatDialogRef<EnableSsoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) context: EnableSsoDialogContext,
    private readonly config: Configuration
  ) {
    this.context = context;
    this.domainSuffix = '.' + config.envDomain;
  }

  enableSSO(isValid: boolean) {
    if (isValid) {
      if (this.context?.okHandler) {
        this.context.okHandler(this.customDomain.toLowerCase());
      }
      this.dialogRef.close();
    }
  }
}
