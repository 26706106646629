<div class="details-form-field w-100">
  <table class="w-100">
    <thead class="breakdown-table-header">
      <tr>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th class="align-right">Last Updated</th>
        <th class="align-right">Current</th>
        <th class="align-right">Target</th>
      </tr>
    </thead>
    <tbody class="metric-breakdown-table-body">
      <ng-container *ngIf="isCampaignMetricDrawer">
      <tr>
        <td colspan="5" class="campaign-type-header">
           Parent Campaign  
        </td>
      </tr>
      <tr>
        <td class="p-t-15 breakdown-metric-icon direct-entry">
          <fa-icon [icon]="['fas', 'chart-line']"></fa-icon>
        </td>
        <td class="p-t-15">Direct Entry</td>
        <td class="p-t-15 align-right last-updated-section">{{ currentMetricData.lastUpdated | date : "dd MMM YYYY" }}</td>
        <td class="p-t-15 align-right decimal-fs current-value-section">{{ currentMetricData.current | number: decimalDisplayFormat }}</td>
        <td class="p-t-15 align-right decimal-fs target-value-section">{{ currentMetricData.target | number: decimalDisplayFormat }}</td>
      </tr>

      <tr
       *ngFor="let integrationMetric of integrationsMetricData"> 
        <td class="p-t-15">

          <icon-plannuh *ngIf="integrationMetric.integration === metricIntegrationNames.Plannuh"
                [size]="'20px'"
                [isMonoChrome]="false"
                style="top: 2px; margin-right: 0;"></icon-plannuh>
          <icon-google-ads *ngIf="integrationMetric.integration === metricIntegrationNames.GoogleAds"
                          [size]="'20px'"
                          [isMonoChrome]="false"
                          style="top: 2px"></icon-google-ads>
          <icon-facebook *ngIf="integrationMetric.integration === metricIntegrationNames.FacebookAds"
                          [size]="'20px'"
                          [isMonoChrome]="false"
                          style="top: 2px"></icon-facebook>
          <icon-linkedin *ngIf="integrationMetric.integration === metricIntegrationNames.LinkedinAds"
                          [size]="'20px'"
                          [isMonoChrome]="false"
                          style="top: 1px"></icon-linkedin>
          <icon-salesforce *ngIf="integrationMetric.integration === metricIntegrationNames.Salesforce"
                          [size]="'22px'"
                          style="top: 1px"></icon-salesforce>
          <fa-icon *ngIf="integrationMetric.integration === metricIntegrationNames.Hubspot"
                  [icon]="['fab', 'hubspot']"
                  [isMonoChrome]="false"></fa-icon>

        </td>
        <td class="p-t-15">{{ metricIntegrationDisplayNamesMap[integrationMetric.integration] }}</td>
        <td class="p-t-15 align-right last-updated-section">{{ integrationMetric.lastUpdated | date : "dd MMM YYYY" }}</td>
        <td class="p-t-15 align-right decimal-fs current-value-section">{{ integrationMetric.current | number: decimalDisplayFormat }}</td>
        <td class="p-t-15 align-right decimal-fs target-value-section">{{ integrationMetric.target || 0 | number: decimalDisplayFormat }}</td>
      </tr>

      <td colspan="5" 
      *ngIf="childCampaignMetricsData.length > 0"
      class="campaign-type-header"
      style="padding-top: 1.5rem"
      >
        Metrics from Child Campaigns  
     </td>
      <tr *ngFor="let metric of childCampaignMetricsData">
        <td class="p-t-15 breakdown-metric-icon">
          <fa-icon [icon]="['fas', 'chart-line']"></fa-icon>
        </td>
        <td class="p-t-15">
        <div>
          <p class="metric-name">{{ funnelName + ' ' + metricName }}</p>
          <p class="metric-campaign-name"
            matTooltipClass="dark-theme-tooltip simple multi-line"
            matTooltipPosition="left"
            [matTooltip]="metric.name"
            [matTooltipShowDelay]="600"
          >{{ metric.name }}</p>
        </div>
      </td>
        <td class="p-t-15 align-right last-updated-section">{{ metric.lastUpdated | date : "dd MMM YYYY" }}</td>
        <td class="p-t-15 align-right decimal-fs current-value-section">{{ metric.current | number: decimalDisplayFormat }}</td>
        <td class="p-t-15 align-right decimal-fs target-value-section">{{ metric.target | number: decimalDisplayFormat}}</td>
      </tr>
      </ng-container>

      <!-- Goal Breakdown Table -->
      <ng-container *ngIf="!isCampaignMetricDrawer">
        <tr>
          <td colspan="5" class="campaign-type-header" 
          *ngIf="goalCampaignMetricsData.length > 0">
             Parent Campaign  
          </td>
        </tr>
        <tr *ngFor="let goalCampaignMetric of goalCampaignMetricsData">
          <td class="p-t-15 breakdown-metric-icon">
            <fa-icon [icon]="['fas', 'chart-line']"></fa-icon>
          </td>
          <td class="p-t-15">
          <div>
            <p class="metric-name">{{ funnelName + ' ' + metricName }}</p>
            <p class="metric-campaign-name"
            matTooltipClass="dark-theme-tooltip simple multi-line"
            matTooltipPosition="left"
            [matTooltip]="goalCampaignMetric.name"
            [matTooltipShowDelay]="600"
            >{{ goalCampaignMetric.name }}</p>
          </div>
        </td>
          <!-- <td class="p-t-15">{{ goalCampaignMetric.name }}</td> -->
          <td class="p-t-15 align-right last-updated-section">{{ goalCampaignMetric.lastUpdated | date : "dd MMM YYYY" }}</td>
          <td class="p-t-15 align-right decimal-fs current-value-section">{{ goalCampaignMetric.current | number: decimalDisplayFormat }}</td>
          <td class="p-t-15 align-right decimal-fs target-value-section">{{ goalCampaignMetric.target | number: decimalDisplayFormat }}</td>
        </tr>
      </ng-container>

      <tr>
        <td class="p-t-15" colspan="5">
          <hr />
        </td>
      </tr>

      <tr>
        <td class="font-weight-700 p-t-10" colspan="3">Total</td>
        <td class="font-weight-700 p-t-10 align-right decimal-fs current-value-section">{{ grandTotalData?.current || 0 | number: decimalDisplayFormat }}</td>
        <td class="font-weight-700 p-t-10 align-right decimal-fs target-value-section">{{ grandTotalData?.target || 0 | number: decimalDisplayFormat }}</td>
      </tr>
    </tbody>
  </table>
</div>
