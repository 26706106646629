import { inject, Injectable } from '@angular/core';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getRequestOptions, handleBulkRequestError, withAPIV0ErrorHandling } from '../../utils/http-request.utils';
import { BulkOperationResponse } from '../../types/bulk-operation-response.interface';
import { catchError } from 'rxjs/operators';
import { Validations } from 'app/app.validations';

export interface ObjectTypeDO {
  id: number;
  name: string;
  is_custom: boolean;
  is_enabled: boolean;
  is_hidden: boolean;
  crd: string,
  upd: string,
  company: number
}

@Injectable({
  providedIn: 'root'
})
export class ObjectTypeService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly validation = inject(Validations);

  public apiPaths = {
    objectType: 'object_type/',
    multiDelete: 'multi_delete/',
    objectTypeListAdmin: 'object_type/list_for_admin/'
  };
  public newDeleteApiPath = '/delete_for_admin_new_world/';

  createObjectType(objectType: Partial<ObjectTypeDO>): Observable<ObjectTypeDO> {
    return this.http.post<ObjectTypeDO>(
      `${this.apiV2Url}${this.apiPaths.objectType}`,
      JSON.stringify(objectType)
    );
  }

  getObjectTypes(companyId: number): Observable<ObjectTypeDO[]> {
    return this.http.get<ObjectTypeDO[]>(
      this.apiV2Url + this.apiPaths.objectType,
      getRequestOptions({company: companyId})
    );
  }

  multiDeleteObjectTypes(ids: number[]): Observable<BulkOperationResponse<number>> {
    const { ValiditionMessages: MESSAGES } = this.validation;
    return this.http.delete<BulkOperationResponse<number>>(
      `${this.apiV2Url}${this.apiPaths.multiDelete}`,
      {
        body: { ids }
      }
    ).pipe(
      catchError(err => handleBulkRequestError(err, ids, MESSAGES.SOMETHING_WENT_WRONG))
    );
  }

  deleteObjectType(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiV2Url}${this.apiPaths.objectType}${id}/`);
  }

  deleteNewWorldObjectType(rowId, data) {
    return withAPIV0ErrorHandling(
      this.http.delete(`${this.apiV2Url}${this.apiPaths.objectType}${rowId}${this.newDeleteApiPath}`,getRequestOptions(data))
    );
  }

  addObjectType(data) {
    return withAPIV0ErrorHandling(
      this.http.post(`${this.apiV2Url}${this.apiPaths.objectType}`, JSON.stringify(data))
    );
  }

  updateObjectType(id, data) {
    return withAPIV0ErrorHandling(
      this.http.patch(`${this.apiV2Url}${this.apiPaths.objectType}${id}/`,  JSON.stringify(data))
    );
  }

  getNewObjectTypes(data): Observable<ObjectTypeDO[]> {
    return withAPIV0ErrorHandling(
      this.http.get<ObjectTypeDO[]>(`${this.apiV2Url}${this.apiPaths.objectTypeListAdmin}`, getRequestOptions(data))
    );
  }
}
