import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[firstZeroHide]'

})
export class FirstZeroHideDirective implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private _model: NgModel,
    private _elementRef: ElementRef
  ) {
    this._model.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (value == undefined) {
          this._elementRef.nativeElement.value = null;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
