import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { SearchResponse, SearchService } from './search.service';
import { Configuration } from 'app/app.constants';
import { AppDataLoader } from 'app/app-data-loader.service';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { UserDataService } from 'app/shared/services/user-data.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './search.component.html',
  providers: [AppDataLoader]
})
export class SearchComponent implements OnInit, OnDestroy {
  public readonly configuration = inject(Configuration);
  public readonly utilityService = inject(UtilityService);
  public readonly searchService = inject(SearchService);
  private readonly location = inject(Location);
  public readonly budgetDataService = inject(BudgetDataService);
  public readonly userDataService = inject(UserDataService);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly appDataLoader = inject(AppDataLoader);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly activatedRoute = inject(ActivatedRoute);

  private readonly destroy$ = new Subject<void>();

  public companyId: number;
  public searchResultSortBy = 'desc';
  public searchResultOrderBy = '';
  public search_value: any;
  public searchedResultList = [];
  public searchPage = 1;
  public searchResultLimit = 30;
  public noResultFound = false;
  public searched_item: any;
  public budgetTimeframeList: BudgetTimeframe[] = [];
  public budgetSegmentList: BudgetSegmentAccess[] = [];
  public selectedTimeframes = [];
  public selectedFirstSegments = [];
  public editPermission = false;
  public balanceAmtClassArray = [];
  public resultCount = 0;
  public selectedBudget: Budget;
  public isListRefreshed = false;
  public isScrolled = false;
  public isSorted = false;
  public isSearched = false;
  public isCegFlagEnabled = false;

  ngOnInit() {
    this.utilityService.showLoading(true);
    this.budgetDataService.selectedBudget$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      newSelectedBudget => this.onBudgetChanged(newSelectedBudget)
    );

    this.budgetDataService.timeframeList$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      timeframes => this.onTimeframeListUpdated(timeframes)
    );

    this.budgetDataService.segmentList$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      segments => this.onSegmentListUpdated(segments)
    );

    this.userDataService.editPermission$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      editPermission => this.editPermission = editPermission
    );

    this.companyDataService.selectedCompany$.pipe(
      takeUntil(this.destroy$),
      tap(cmp => this.companyId = cmp?.id),
      filter(cmp => cmp != null)
    ).subscribe(cmp => {
      this.companyDataService.loadCompanyData(cmp.id, error => this.handleError(error));
      this.getSearchResult();
    });

    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryParamMap: ParamMap) => {
        const term = queryParamMap.get('term');
        this.search_value = term || '';
        if (!this.isSearched) {
          this.getSearchResult()
        }
      });

    this.appDataLoader.init();
  }

  private onBudgetChanged(newSelectedBudget: Budget) {
    this.selectedBudget = newSelectedBudget;
    this.isCegFlagEnabled = !!this.selectedBudget?.new_campaigns_programs_structure;
    if (this.companyId) {
      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.campaignStatusNames.active,
        error => this.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.programStatusNames.active,
        error => this.handleError(error)
      );
    }
    this.getSearchResult()
    this.processSearchTermInLocalStorage();
    this.utilityService.showLoading(false);
  }

  // TODO: processing search term from local storage is not using any more
  processSearchTermInLocalStorage() {
    const searchTerm = localStorage.getItem('searchTerm');
    if (!searchTerm) {
      return;
    }

    localStorage.removeItem('searchTerm');
    this.search_value = searchTerm;
    this.getSearchResult();
  }

  onTimeframeListUpdated(timeframes: BudgetTimeframe[]) {
    this.budgetTimeframeList = timeframes;
    if (this.budgetTimeframeList.length > 0) {
      const selectedTfIds = this.budgetTimeframeList[FilterName.Timeframes];
      this.selectedTimeframes =
        selectedTfIds && selectedTfIds.length > 0 ?
          this.budgetTimeframeList.filter(tf => selectedTfIds.includes(tf.id)) :
          [];
    }
  }

  onSegmentListUpdated(segments: BudgetSegmentAccess[]) {
    this.budgetSegmentList = segments;
    this.selectedFirstSegments =
      this.budgetSegmentList.filter(seg =>
        this.budgetSegmentList[FilterName.Segments] != null &&
        this.budgetSegmentList[FilterName.Segments].includes(seg.id));
  }

  onSearchResultScroll() {
    this.isScrolled = true;
    this.getSearchResult();
  }

  searchResultSort(order_by) {
    this.isSorted = true;
    this.searchPage = 1;
    if (this.searchResultOrderBy === order_by) {
      if (this.searchResultSortBy === 'asc') {
        this.searchResultSortBy = 'desc';
      } else {
        this.searchResultSortBy = 'asc';
      }
    } else {
      this.searchResultOrderBy = order_by;
      this.searchResultSortBy = 'asc';
    }

    if (this.searchedResultList.length > 0) {
      this.getSearchResult();
    }
  }

  getSearchResult() {
    if (!this.selectedBudget || !this.companyId) {
      return;
    }
    if (!this.isSearched) {
      this.isSearched = true;
      this.search_value = (this.search_value.replace(/ +/g, ' ')).trim();
      if (this.search_value != null && this.search_value !== '') {
        if ((this.search_value || this.isListRefreshed) && !this.isScrolled && !this.isSorted) {
          this.searchPage = 1;
          this.searchResultSortBy = 'desc';
          this.searchResultOrderBy = '';
        }
        this.searched_item = this.search_value;
        if (this.searchPage) {
          if (this.searchPage === 1) {
            this.searchedResultList = [];
          }

          const data = {};
          data['name'] = this.search_value;
          data['page'] = this.searchPage;
          data['limit'] = this.searchResultLimit;

          if (this.searchResultSortBy === 'asc' && this.searchResultOrderBy !== '') {
            data['ordering'] = this.searchResultOrderBy;
          } else if (this.searchResultSortBy === 'desc' && this.searchResultOrderBy !== '') {
            data['ordering'] = '-' + this.searchResultOrderBy;
          }

          this.utilityService.showLoading(true);
          this.searchService.getSearchResult(this.companyId, this.selectedBudget.id, data)
            .subscribe(
              res => this.success(res),
              error => this.handleError(error)
            );
        }
      }
    }
  }

  closeSearchResult() {
    this.location.back();
  }

  openViewModal(result) {
    if (result.object_type === 'Goal') {
      this.appRoutingService.openGoalDetails(result.id);
    } else if (result.object_type === 'Campaign') {
      this.appRoutingService.openCampaignDetails(result.id);
    } else if (result.object_type === 'Expense Group') {
      this.appRoutingService.openProgramDetails(result.id);
    } else if (result.object_type === 'Expense') {
      this.appRoutingService.openExpenseDetails(result.id);
    }
  }

  success(data: SearchResponse) {
    if (Number(data?.status) === HttpStatusCode.Ok) {
      this.balanceAmtClassArray = [];
      this.searchedResultList = this.searchedResultList.concat(data.data);
      this.resultCount = data.count;
      this.searchedResultList.forEach((r, i) => {
        if (r.balance.includes('--')) {
          this.balanceAmtClassArray[i] = false;
        } else {
          this.balanceAmtClassArray[i] = r.balance.includes('-');
        }
      });
      this.noResultFound = this.searchedResultList.length <= 0;
      this.searchPage = data.next;
      this.isScrolled = false;
      this.isSorted = false;
      this.utilityService.showLoading(false);
      this.isSearched = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleError(error) {
    if (error.hasOwnProperty('message')) {
      this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
      this.utilityService.showLoading(false);
    } else if (error.hasOwnProperty('detail')) {
      this.utilityService.showLoading(false);
      this.utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
    }
  }
}
