import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricsPageComponent } from './components/metrics-page/metrics-page.component';
import { SharedModule } from '@shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StandaloneMetricsComponent } from './components/standalone-metrics/standalone-metrics.component';
import { ProductFunnelComponent } from './components/product-funnel/product-funnel.component';
import { FunnelPageControlsComponent } from './components/funnel-page-controls/funnel-page-controls.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ViewTogglersComponent } from './components/view-togglers/view-togglers.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RevenueToProfitInputComponent } from './components/revenue-to-profit-input/revenue-to-profit-input.component';
import { FunnelNameInputComponent } from './components/funnel-name-input/funnel-name-input.component';
import { AddNewMetricBtnComponent } from './components/add-new-metric-btn/add-new-metric-btn.component';
import { AddNewMetricsRowComponent } from './components/add-new-metrics-row/add-new-metrics-row.component';
import { MatIconModule } from '@angular/material/icon';
import { AddMetricBtnComponent } from './components/add-metric-btn/add-metric-btn.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DeleteMetricModalComponent } from './modals/delete-metric-modal/delete-metric-modal.component';
import { ChooseBudgetDialogComponent } from './modals/choose-budget-dialog/choose-budget-dialog.component';
import { ProductBudgetCalculationsComponent } from './components/product-funnel/product-budget-calculations/product-budget-calculations.component';
import { ProductBudgetDialogComponent } from './components/product-funnel/product-budget-dialog/product-budget-dialog.component';
import { ProductBudgetSummaryComponent } from './components/product-funnel/product-budget-summary/product-budget-summary.component';

@NgModule({
  declarations: [
    MetricsPageComponent,
    StandaloneMetricsComponent,
    ProductFunnelComponent,
    FunnelPageControlsComponent,
    ViewTogglersComponent,
    RevenueToProfitInputComponent,
    FunnelNameInputComponent,
    AddNewMetricBtnComponent,
    AddNewMetricsRowComponent,
    AddMetricBtnComponent,
    DeleteMetricModalComponent,
    ChooseBudgetDialogComponent,
    ProductBudgetCalculationsComponent,
    ProductBudgetDialogComponent,
    ProductBudgetSummaryComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    DragDropModule
  ]
})
export class MetricFunnelsModule { }
