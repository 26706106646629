import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'app/shared/types/select-groups.interface';

@Component({
  selector: 'metric-type',
  templateUrl: './metric-type.component.html',
  styleUrls: ['../../page-title/page-title.component.scss', './metric-type.component.scss']
})
export class MetricTypeComponent {
  @Input() productName: string;
  @Input() metricName: string;
  @Input() currentMetricId: number;
  @Input() isReadonly: boolean;
  @Input() masterMetricsSelectItems: SelectItem[];

  @Output() selectMetric = new EventEmitter<number>();

  onSelectMetric(metricId: number) {
    this.selectMetric.emit(metricId)
  }
}
