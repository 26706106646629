import { Component, Input, Output, HostListener, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent {
  @Input() useAutofocus = false;
  @Input() inputPlaceholder = 'Type here...';
  @Input() suggestions: {[key: string]: string | number}[] = [];
  @Input() maxItems = -1;
  @Input() ableToCreateItem: boolean;
  @Input() cleanOnSubmit: boolean;
  @Output() onSelect: EventEmitter<{}> = new EventEmitter();
  @Output() onToggle: EventEmitter<{}> = new EventEmitter();
  @Output() onReset: EventEmitter<{}> = new EventEmitter();
  @Output() onSubmitNewItem: EventEmitter<{}> = new EventEmitter();

  @ViewChild('inputEl', { static: true }) inputEl;

  filterInput = '';
  filteredSuggestions: {[key: string]: string | number}[] = [];
  keyboardNavIndex: number;
  suggestionsIsOpened = false;

  ngOnInit() {
    this.keyboardNavIndex = this.ableToCreateItem ? -1 : 0;
  }

  updateFilteredSuggestions() {
    if (this.filterInput) {
      const regEx = new RegExp(this.filterInput, 'i');
      this.filteredSuggestions = this.suggestions.filter((item, index) => {
        const subtitle = item.subtitle || '';
        const textMatch = String(item.title).search(regEx) >= 0 || String(subtitle).search(regEx) >= 0;
        return textMatch;
      });
      if(this.maxItems !== -1) {
        this.filteredSuggestions = this.filteredSuggestions.slice(0, this.maxItems);
      }
    } else {
      this.filteredSuggestions = this.suggestions;
    }

    if(!this.suggestionsIsOpened) {
      this.toggleSuggestionsList(true);
    }

    if(!this.filteredSuggestions.length) {
      this.keyboardNavIndex = -1;
    }

    if (this.keyboardNavIndex > 0) {
      this.keyboardNavIndex = 0;
    }
  }

  toggleSuggestionsList(value) {
    this.onToggle.emit(value);
    this.suggestionsIsOpened = value;
    if(value) {
      this.updateFilteredSuggestions();
    }
  }

  onSuggesctionClick(e: Event, sg) {
    e.preventDefault();
    this.onSelectSuggestion(sg)
  }

  onSelectSuggestion(sg) {
    this.onSelect.emit(sg);
    this.toggleSuggestionsList(false);
    this.filterInput = this.cleanOnSubmit ? '' : sg.title;

  }

  submitNewItem() {
    this.onSubmitNewItem.emit(this.filterInput);
    if(this.cleanOnSubmit) {
      this.filterInput = '';
    }
    this.toggleSuggestionsList(false);
  }

  @HostListener('keydown.arrowdown', ['$event'])
  onArrowDownClick(e: Event) {
    if(this.suggestionsIsOpened) {
      e.preventDefault()
      const maxIndex = this.filteredSuggestions.length - 1;
      this.keyboardNavIndex = this.keyboardNavIndex === maxIndex
        ? 0
        : ++this.keyboardNavIndex;
    }
  }

  @HostListener('keydown.arrowup', ['$event'])
  onArrowUpClick(e: Event) {
    if(this.suggestionsIsOpened) {
      e.preventDefault()
      const maxIndex = this.filteredSuggestions.length - 1;
      this.keyboardNavIndex = this.keyboardNavIndex === 0
        ? maxIndex
        : --this.keyboardNavIndex;
    }
  }

  @HostListener('keydown.arrowleft', ['$event'])
  @HostListener('keydown.arrowright', ['$event'])
  onResetKeyboardNavIndex(e: Event) {
    if(this.suggestionsIsOpened && this.ableToCreateItem && this.keyboardNavIndex !== -1) {
      e.preventDefault()
      this.keyboardNavIndex = -1;
    }
  }

  @HostListener('keydown.enter', ['$event'])
  @HostListener('keydown.tab', ['$event'])
  onEnterClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if(this.keyboardNavIndex !== -1) {
      if(this.suggestionsIsOpened) {
        const sg = this.filteredSuggestions.find((item, i) => i === this.keyboardNavIndex);
        this.onSelectSuggestion(sg);
      } else {
        this.toggleSuggestionsList(true);
      }
    } else if(this.keyboardNavIndex === -1 && this.ableToCreateItem) {
      this.filterInput.trim() ? this.submitNewItem() : this.toggleSuggestionsList(false);
    }
  }

  @HostListener('keydown.escape', ['$event'])
  onEscapeClick(e: Event) {
    e.preventDefault();
    this.onReset.emit();
    this.filterInput = '';
    this.inputEl.nativeElement.blur();
  }
}
