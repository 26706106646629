import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, combineLatest, Observable, Subject } from 'rxjs';
import { UserManager } from 'app/user/services/user-manager.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { AppDataLoader } from 'app/app-data-loader.service';
import { Budget } from 'app/shared/types/budget.interface';
import { permissionLabelByType, permissionOptions } from './team-page.utils';
import { BudgetService } from 'app/shared/services/backend/budget.service';
import { BudgetSegmentService } from 'app/shared/services/backend/budget-segment.service';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { TeamPageService } from './team-page.service';
import { PermissionFormGroup, TeamMember } from './team-page.type';
import { SortParams } from 'app/shared/types/sort-params.interface';
import { ComponentCanDeactivate } from 'app/shared/guards/leave-page.guard';
import { Company } from 'app/shared/types/company.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { UserPermission } from 'app/shared/types/user-permission.type';
import { SelectOption } from 'app/shared/types/select-option.interface';

@Component({
  selector: 'team-page',
  templateUrl: './team-page.component.html',
  styleUrls: ['./team-page.component.scss'],
  providers: [ AppDataLoader, TeamPageService ]
})
export class TeamPageComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  private readonly destroy$ = new Subject<void>();

  companyId: number;
  weeklyEmailsTooltip = `For any 'Live' budget, users will\nreceive a weekly status email`;
  goalTransparencyTooltip = 'If you disable Goal Detail Transparency, users who do not have access to see all segments will not be allowed to view goal details.';

  private currentBudget: Budget;
  private company: Company;
  public permissionOptions = permissionOptions;
  public permissionLabelByType = permissionLabelByType;
  public budgets: SelectOption[];
  public segmentsByBudgetId: {[id: number]: BudgetSegmentAccess} = {};
  public UserPermission = UserPermission;
  public teamMembers$: Observable<TeamMember[]>;
  public adminTeamMembers: TeamMember[];
  public searchFilterText = '';
  segmentsLocalSelection = false;
  segmentsMaterialSelection = false;

  constructor(
    private budgetService: BudgetService,
    private budgetSegmentService: BudgetSegmentService,
    private teamPageService: TeamPageService,
    private readonly userManager: UserManager,
    private readonly companyDataService: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly appDataLoader: AppDataLoader,
    private readonly configuration: Configuration,
    private readonly utilityService: UtilityService,
  ) {
    this.teamMembers$ = this.teamPageService.teamMembers$
      .pipe(
        tap(users => this.adminTeamMembers = users.filter(user => user.isAdmin))
      )
  }

  get appliedSorting(): SortParams {
    return this.teamPageService.appliedSorting;
  }

  get accountOwnerName(): string {
    return this.teamPageService.accountOwnerName;
  }

  get ssoEnabled(): boolean {
    return this.teamPageService.ssoEnabled;
  }

  get companyDomainName(): string {
    return this.teamPageService.companyDomainName;
  }

  ngOnInit(): void {
    this.budgetDataService.selectedBudget$
      .pipe(
        filter(budget => budget != null),
        tap(budget => {
          this.currentBudget = budget;
          this.loadSelectedBudgetData(this.company, this.currentBudget);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    const company$ = this.companyDataService.selectedCompany$
      .pipe(
        filter(cmp => cmp != null),
        tap(company => this.company = company)
      );

    combineLatest([
      this.userManager.currentCompanyUser$,
      company$
    ])
      .pipe(
        filter(([user, company]) => user?.is_admin && !!company?.id),
        tap(([user, company]) => {
          this.onSelectedCompanyChanged(company);
        }),
        switchMap(([user, company]) => this.loadBudgetsAndSegments(company.id)),
        takeUntil(this.destroy$)
      )
      .subscribe((budgetsSegments: BudgetSegmentAccess[][]) => {
        this.segmentsByBudgetId =
          this.budgets.reduce((mapping, el, i) => {
            mapping[el.id] = budgetsSegments[i].map(segm => ({ id: segm.id, name: segm.name }));
            return mapping;
          }, {})
      });

    this.appDataLoader.init();
  }

  filterUsers(value: string) {
    this.searchFilterText = value?.length > 2 ? value : ''
  }

  private loadSelectedBudgetData(company: Company, budget: Budget) {
    if (!company || !budget) {
      return;
    }

    this.budgetDataService.loadLightCampaigns(
      company.id,
      budget.id,
      this.configuration.campaignStatusNames.active,
      error => this.utilityService.handleError(error)
    );
    this.budgetDataService.loadLightPrograms(
      company.id,
      budget.id,
      this.configuration.programStatusNames.active,
      error => this.utilityService.handleError(error)
    );
  }

  private loadBudgetsAndSegments(companyId) {
    return this.budgetService.getAvailableBudgetsForCompany(companyId)
      .pipe(
        tap((budgets: Budget[]) => this.budgets = budgets.map(budget => ({ id: budget.id, name: budget.name }))),
        switchMap((budgets: Budget[]) =>
          forkJoin(budgets.map(
            budget => this.budgetSegmentService.getAvailableBudgetSegments(budget.id).pipe(
              map(data => data.available_segments)
            )
        )))
      )
  }

  addNewUser() {
    this.teamPageService.addEmptyTeamMemberRow();
  }

  addNewPermission(teamMember: TeamMember) {
    this.teamPageService.addEmptyPermissionRow(teamMember);
  }

  revokePermission(teamMember: TeamMember, permissionIndex) {
    this.teamPageService.revokePermission(teamMember, permissionIndex);
  }

  duplicatePermissions(teamMember: TeamMember) {
    this.teamPageService.duplicatePermissions(teamMember);
  }

  toggleUserEmailSubscription(companyUserId, value) {
    this.teamPageService.toggleUserEmailSubscription(companyUserId, value);
  }

  deleteTeamMember(user: TeamMember) {
    const userCompanyId = user.companyUserId;
    const profile = user.profileForm.value;
    const teamMemberName = (profile.firstName || '') + ' ' + (profile.lastName || '');
    const teamMemberIndex = this.teamPageService.getTeamMemberIndex(user);
    this.teamPageService.deleteTeamMember(userCompanyId, teamMemberIndex, teamMemberName);
  }

  sendInvite(teamMember: TeamMember) {
    this.teamPageService.sendInvitation(teamMember);
  }

  sortTeamMembers(sortColumn: string) {
    this.teamPageService.sortTeamMembers(sortColumn);
  }

  objectComparisonFunction = ( option, value ): boolean => {
    return value && option.id === value.id;
  };

  addRollBackPermission(form: PermissionFormGroup) {
    form.patchValue({ rollbackPermission: form.controls.permission.value }, { emitEvent: false })
  }

  addRollBackBudget(form: PermissionFormGroup) {
    form.patchValue({ rollbackBudget: form.controls.budget.value }, { emitEvent: false })
  }

  private onSelectedCompanyChanged(company) {
    this.companyId = company.id;
    this.companyDataService.loadCompanyData(this.companyId);
  }

  onSelectClosed(activePermissionsFormSegments) {
    if (this.segmentsLocalSelection && !this.segmentsMaterialSelection) {
      // emit segments formControl changes only if select-all was checked
      activePermissionsFormSegments.updateValueAndValidity();
    }
    this.segmentsMaterialSelection = false;
    this.segmentsLocalSelection = false;
  }

  onSegmentClick(activePermissionsFormSegments, clickedSegment) {
    this.segmentsMaterialSelection = true;
    const values = [ ...activePermissionsFormSegments?.value || [] ];
    const targetInd = values.findIndex(s => s.id === clickedSegment.id);
    targetInd !== -1 ? values.splice(targetInd, 1) : values.push(clickedSegment);
    activePermissionsFormSegments.setValue(values, { emitEvent: false });
  }

  setAll(activePermissionsFormSegments: UntypedFormControl, allSelected: boolean, available) {
    this.segmentsLocalSelection = true;
    if (allSelected) {
      activePermissionsFormSegments.setValue(available, { emitEvent: false });
    } else {
      activePermissionsFormSegments.setValue([], { emitEvent: false });
    }
  }

  changeAccountOwner(user: TeamMember): void {
    const accountOwner = this.adminTeamMembers.find(member => member.is_account_owner);

    if (!accountOwner || user.id === accountOwner.id) {
      return;
    }

    this.userManager.switchAccountOwner(accountOwner, user)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.companyDataService.loadCompanyUsers(this.companyId);
          const message = `Account ownership has been changed successfully`;
          this.utilityService.showToast({ Title: '', Message: message, Type: 'success' });
        }, e => {
          const message = e?.detail || 'Some error has occurred';
          this.utilityService.showToast({ Title: '', Message: message, Type: 'error' });
        });
  }

  canDeactivate(): Observable<boolean> {
    return this.teamPageService.canLeave();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
