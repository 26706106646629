import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const getRequestOptions = (paramsObj) => {
  const options = {};
  const params = paramsObj && Object.keys(paramsObj).map(key => ({key, value: paramsObj[key]}));
  if (params) {
    options['params'] =
      params.reduce(
        (resultParams, param) => {
          const value = Array.isArray(param.value) ? JSON.stringify(param.value) : param.value;
          return resultParams.append(param.key, value);
        },
        new HttpParams());
  }
  return options;
};

const handleAPIV0Error = (error: any) => {
  const errorToThrow = error.status == null ? 'AuthError' : error.error || error;
  if (typeof errorToThrow === 'object' && !errorToThrow.status && error.status) {
    errorToThrow.status = error.status
  }
  return throwError(() => errorToThrow);
};

export const withAPIV0ErrorHandling = <T>(requestObservable: Observable<T>): Observable<T> => {
  return requestObservable.pipe(
    catchError(handleAPIV0Error)
  );
};

export const handleBulkRequestError = (error: Error, ids: number[], message) => {
  console.warn(error.message);
  return of({
    success: [],
    error: ids.map(id => ({
      id,
      message
    }))
  });
};

export const parseErrorBlob = (errorResponse: HttpErrorResponse): Observable<any> => {
  const reader = new FileReader();
  const obs = new Observable(observer => {
    reader.onloadend = () => {
      let errorObj;
      try {
        errorObj = JSON.parse(reader.result as string)
      } catch (e) {
        errorObj = { message: errorResponse.statusText };
      }
      observer.error(errorObj);
      observer.complete();
    }
  });
  reader.readAsText(errorResponse.error);

  return obs;
};
