<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 30 30"
  [attr.width]="size"
  [attr.height]="size"
  [attr.fill]="color"
>
  <path opacity="0.4" d="M30 23.4375V25.3125C30 25.5611 29.9012 25.7996 29.7254 25.9754C29.5496 26.1512 29.3111 26.25 29.0625 26.25H1.875C1.37772 26.25 0.900806 26.0525 0.549175 25.7008C0.197544 25.3492 0 24.8723 0 24.375V4.6875C0 4.43886 0.0987721 4.2004 0.274587 4.02459C0.450403 3.84877 0.68886 3.75 0.9375 3.75H2.8125C3.06114 3.75 3.2996 3.84877 3.47541 4.02459C3.65123 4.2004 3.75 4.43886 3.75 4.6875V22.5H29.0625C29.3111 22.5 29.5496 22.5988 29.7254 22.7746C29.9012 22.9504 30 23.1889 30 23.4375Z"/>
  <path d="M28.125 6.5625V13.4795C28.125 14.7322 26.6103 15.3598 25.7226 14.4756L23.8242 12.5771L18.1992 18.2021C17.8476 18.5537 17.3707 18.7512 16.8735 18.7512C16.3763 18.7512 15.8995 18.5537 15.5478 18.2021L11.25 13.9014L8.55115 16.6008C8.46409 16.6879 8.36074 16.7569 8.24698 16.8041C8.13322 16.8512 8.0113 16.8754 7.88816 16.8754C7.76503 16.8754 7.64311 16.8512 7.52935 16.8041C7.41559 16.7569 7.31223 16.6879 7.22518 16.6008L5.89979 15.2754C5.81271 15.1883 5.74363 15.085 5.69651 14.9712C5.64938 14.8575 5.62512 14.7355 5.62512 14.6124C5.62512 14.4893 5.64938 14.3673 5.69651 14.2536C5.74363 14.1398 5.81271 14.0365 5.89979 13.9494L9.92459 9.92461C10.2762 9.57307 10.7531 9.37558 11.2503 9.37558C11.7475 9.37558 12.2243 9.57307 12.576 9.92461L16.875 14.2236L21.174 9.92402L19.2773 8.02734C18.3914 7.14141 19.0189 5.625 20.2734 5.625H27.1875C27.4361 5.625 27.6746 5.72377 27.8504 5.89959C28.0262 6.0754 28.125 6.31386 28.125 6.5625Z"/>
</svg>
