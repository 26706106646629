import { Component, Input } from '@angular/core';
import { MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';

@Component({
  selector: 'campaign-name',
  templateUrl: './campaign-name.component.html',
  styleUrls: ['./campaign-name.component.scss']
})
export class CampaignNameComponent {
  @Input() name: string;
  @Input() type: string;

  MetricIntegrationName = MetricIntegrationName;
}
