<svg
  [attr.width]="width || size"
  [attr.height]="height || size"
  [attr.fill]="color"
  [style.display]="'block'"
  viewBox="0 0 14 13"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M13.5 1.5V10.25C13.5 10.9531 12.9141 11.5 12.25 11.5C11.5469 11.5 11 10.9531 11 10.25V4.54688L3.10938 12.3984C2.875 12.6328 2.5625 12.75 2.25 12.75C1.89844 12.75 1.58594 12.6328 1.35156 12.3984C0.84375 11.9297 0.84375 11.1094 1.35156 10.6406L9.20312 2.75H3.5C2.79688 2.75 2.25 2.20312 2.25 1.5C2.25 0.835938 2.79688 0.25 3.5 0.25H12.25C12.9141 0.25 13.5 0.835938 13.5 1.5Z"/>
</svg>
