<div class="details-expenses-table">
  <table
    *ngIf="expensesTotals?.count; else elseBlock"
    [ngClass]="{ 'no-breakdown-mode': !withBreakdown }"
  >
    <thead>
      <tr>
        <th class="title-col" *ngIf="withBreakdown">Children</th>
        <th class="value-col">
          <div class="cell-content">
            <budget-status-icon [status]="statusFields?.closed"></budget-status-icon>
            Closed
          </div>
        </th>
        <th class="value-col">
          <div class="cell-content">
            <budget-status-icon [status]="statusFields?.committed"></budget-status-icon>
            Committed
          </div>
        </th>
        <th class="value-col">
          <div class="cell-content">
            <budget-status-icon [status]="statusFields?.planned"></budget-status-icon>
            Planned
          </div>
        </th>
        <th class="value-col total-col">Total</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="withBreakdown">
        <!-- CAMPAIGNS GROUP -->
        <ng-container *ngIf="withChildCampaigns">
          <tr>
            <ng-container
              [ngTemplateOutlet]="rowTemplate"
              [ngTemplateOutletContext]="{
                $implicit: data?.campaignsGroup?.groupTotal,
                title: 'Child Campaigns',
                objectType: objectTypes.campaign
              }"
            ></ng-container>
          </tr>
          <ng-container *ngIf="groupsOpenState[objectTypes.campaign]">
            <tr
              *ngFor="let item of data?.campaignsGroup?.rows; let index = index; trackBy: identifyObjectRow"
              [class.highlighted-border]="index === 0"
            >
              <ng-container
                [ngTemplateOutlet]="rowTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: item,
                  title: item.name,
                  objectId: item.id,
                  objectType: objectTypes.campaign
                }"
              ></ng-container>
            </tr>
          </ng-container>
        </ng-container>
        <!-- PROGRAMS GROUP -->
        <tr *ngIf="data?.programsGroup.rows.length">
          <ng-container
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{
              $implicit: data?.programsGroup?.groupTotal,
              title: 'Expense Groups',
              objectType: objectTypes.program
            }"
          ></ng-container>
        </tr>
        <ng-container *ngIf="groupsOpenState[objectTypes.program]">
          <tr
            *ngFor="let item of data?.programsGroup?.rows; let index = index; trackBy: identifyObjectRow"
            [class.highlighted-border]="index === 0"
          >
            <ng-container
              [ngTemplateOutlet]="rowTemplate"
              [ngTemplateOutletContext]="{
                $implicit: item,
                title: item.name,
                objectId: item.id,
                objectType: objectTypes.program
              }"
            ></ng-container>
          </tr>
        </ng-container>
        <!-- EXPENSES GROUP -->
        <tr>
          <ng-container
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{
              $implicit: data?.expensesGroup?.groupTotal,
              title: 'Other Expenses',
              objectType: objectTypes.expense
            }"
          ></ng-container>
        </tr>
        <ng-container *ngIf="groupsOpenState[objectTypes.expense]">
          <tr
            *ngFor="let item of data?.expensesGroup?.rows; let index = index; trackBy: identifyObjectRow"
            [class.highlighted-border]="index === 0"
          >
            <ng-container
              [ngTemplateOutlet]="rowTemplate"
              [ngTemplateOutletContext]="{
                $implicit: item,
                title: item.name,
                objectId: item.id,
                objectType: objectTypes.expense
              }"
            ></ng-container>
          </tr>
        </ng-container>
      </ng-container>

      <tr class="total-row">
        <ng-container
          [ngTemplateOutlet]="rowTemplate"
          [ngTemplateOutletContext]="{
            $implicit: expensesTotals,
            title: 'Total'
          }"
        ></ng-container>
      </tr>
    </tbody>
  </table>
  <ng-template #elseBlock>
    <details-data-placeholder>
      No Expenses
    </details-data-placeholder>
  </ng-template>
</div>

<ng-template #numericCellTemplate let-data let-cellClass="cellClass">
  <td class="value-col numeric-value" [ngClass]="cellClass">
    {{ data?.value | number: decimalPipeFormat }}
    <icon-flame
      *ngIf="data?.hasRisk || data?.hasProblem"
      [hasProblem]="data?.hasProblem"
    ></icon-flame>
  </td>
</ng-template>

<ng-template #rowTemplate let-data let-title="title" let-objectId="objectId" let-objectType="objectType">
  <td
    *ngIf="withBreakdown"
    [class.group-title]="!objectId"
    [class.closed]="data?.mode === objectMode.Closed"
    class="title-col"
  >
    <div
      *ngIf="objectType && data?.count"
      class="group-toggle"
    >
      <arrow-toggler
        [active]="!groupsOpenState[objectType]"
        (change)="handleGroupToggleChange($event, objectType)"
      ></arrow-toggler>
    </div>
    <div class="title-content">
      <fa-icon
        *ngIf="objectId && iconByObjectType[objectType] && data.campaignSource !== ExternalIntegrationObjectTypes.GoogleAds
        && data?.mode !== objectMode.Closed"
        [icon]="iconByObjectType[objectType]"></fa-icon>
      <icon-google-ads
        *ngIf="data.campaignSource && data.campaignSource === ExternalIntegrationObjectTypes.GoogleAds
        && data?.mode !== objectMode.Closed"
      ></icon-google-ads>
      <fa-icon *ngIf="data?.mode === objectMode.Closed" [icon]="[ 'far', 'rocket' ]"></fa-icon>
      <span [class.object-name-content]="objectId"
            [matTooltip]="title"
            matTooltipShowDelay="800"
            matTooltipClass="dark-theme-tooltip above"
            matTooltipPosition="above"
            (click)="openObjectDetails(objectId, objectType)">
        {{ title }}
      </span>
      <span class="count" *ngIf="objectType && data?.count">({{ data?.count }})</span>
    </div>
  </td>

  <ng-container
    [ngTemplateOutlet]="numericCellTemplate"
    [ngTemplateOutletContext]="{ $implicit: data?.closed, cellClass: 'ut-closed-expenses' }"
  ></ng-container>

  <ng-container
    [ngTemplateOutlet]="numericCellTemplate"
    [ngTemplateOutletContext]="{ $implicit: data?.committed, cellClass: 'ut-committed-expenses' }"
  ></ng-container>

  <ng-container
    [ngTemplateOutlet]="numericCellTemplate"
    [ngTemplateOutletContext]="{ $implicit: data?.planned, cellClass: 'ut-planned-expenses' }"
  ></ng-container>

  <ng-container
    [ngTemplateOutlet]="numericCellTemplate"
    [ngTemplateOutletContext]="{ $implicit: data?.total, cellClass: 'ut-total-expenses total-col' }"
  ></ng-container>
</ng-template>
