<svg
  width="11"
  height="6"
  viewBox="0 0 11 6"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [ngClass]="{'arrow-up': opened, 'arrow-down': !opened}"
>
  <path
    d="M9.81579 0.462488C9.5 0.162488 9.07895 0.162488 8.76316 0.462488L5.07895 3.96249L1.39474 0.462488C1.07895 0.162488 0.552632 0.162488 0.236842 0.462488C-0.0789474 0.762488 -0.0789474 1.26249 0.236842 1.56249L4.44737 5.56249C4.55263 5.66249 4.76316 5.76249 4.97368 5.76249C5.18421 5.76249 5.39474 5.66249 5.5 5.56249L9.71053 1.56249C10.1316 1.26249 10.1316 0.762488 9.81579 0.462488Z"
    fill="#0063B7"
  />
</svg>
