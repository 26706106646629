<div class="update-metrics-table">
  <div class="table-head">
    <div class="table-row">
      <div class="table-cell name-cell">Name</div>
      <div class="table-cell edit-cell">Edit</div>
      <div class="table-cell values-cell">
        <total-target-values
          total="Total"
          target="Target"
        >
        </total-target-values>
      </div>
    </div>
  </div>
  <div class="table-body">
    <update-metrics-panel *ngFor="let item of data; let idx = index; trackBy: trackFn"
      [data]="item"
      [isExpanded]="idx === 0"
      [currencySymbol]="currencySymbol"
      [isReadOnly]="isReadOnly"
      (onHeaderClick)="navigateByMetricType(item)"
      (onCurrentValueChange)="this.onCurrentValueChange.emit($event)"
      (onObjectLinkClick)="navigateByMetricMapping($event)"
    ></update-metrics-panel>
  </div>
</div>
