import { HierarchySelectItem } from './hierarchy-select.types';

export const filterItemsByPattern = (data: HierarchySelectItem[], pattern: RegExp) => {
  return data.filter((item) => {
    if (item.children?.length) {
      item.children = filterItemsByPattern(item.children, pattern);
    }

    if (item.children?.length) {
      return true;
    }

    return String(item.title).search(pattern) >= 0;
  });
}

export const setChildLevel = (item: HierarchySelectItem, parentLevel: number) => {
  if (parentLevel) {
    item.level = parentLevel;
  }
  if (item.children?.length) {
    item.children.forEach(child => {
      setChildLevel(child, parentLevel + 1)
    })
  }
}

export const findItemInHierarchy = (id: string, itemsArr: HierarchySelectItem[]): HierarchySelectItem => {
  let item = null;
  for (const el of itemsArr) {
    if (el.id === id) {
      item = el;
      break;
    }
    if (el.children?.length) {
      item = findItemInHierarchy(id, el.children);
      if (item) {
        break;
      }
    }
  }
  return item;
}
