<p>
  In order to achieve your revenue target of
  <span class="color-accent no-wrap">{{ currency }} <plc-animated-number [value]="revenueTarget" [numberFormat]="numberFormat"></plc-animated-number></span>,
</p>
<p>
  you need a marketing budget of
  <span class="color-accent no-wrap">
    {{ currency }} <plc-animated-number *ngIf="budget !== null; else emptyValue" [value]="budget" [numberFormat]="numberFormat"></plc-animated-number>
  </span>
</p>

<ng-template #emptyValue>--</ng-template>
