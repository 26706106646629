<div class="budget-baseline">
  <header mat-dialog-title>{{ context?.title }}</header>
  <div mat-dialog-content>
    <div class="new-baseline">
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <input matInput
          [(ngModel)]="baselineName"
          trim
          #baselineNameControl="ngModel"
          type="text"
          maxlength="40">
        <mat-error *ngIf="baselineNameControl.errors && baselineNameControl.errors.trim">
          Name can't be empty!
        </mat-error>
      </mat-form-field>
    </div>
    <div class="baseline-holder">
      <p>Previously saved baselines:</p>
      <ul class="baseline-list">
        <ng-container *ngIf="context?.baselineList | async as baselineList; else loading;">
          <li class="item" *ngFor="let item of baselineList">
            {{ item.name }} &nbsp; {{ item.date | date : 'd MMM, y' }}
          </li>
        </ng-container>
        <ng-template #loading>
          <li class="loader-wrap">
            <loader size="30px" [isVisible]="true"></loader>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button
      class="cancel-button"
      [mat-dialog-close]="false"
      mat-button
      color="primary"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!baselineName"
      (click)="onSubmit()"
    >
      OK
    </button>
  </div>
</div>
