<widget-card [isLoading]="state === widgetState.LOADING">
  <div class="assign-segments">
    <details-widget-title [count]="adsCampaigns.length" text="Assign segments to imported campaigns">
      <fa-icon slot="icon" [icon]="['fas', 'plug']" [rotate]="90"></fa-icon>
    </details-widget-title>
    <span class="notice">{{ ASSIGN_SEGMENT_NOTICE }}</span>
  </div>
  <div class="content" [class.is-not-ready]="state === widgetState.LOADING">
    <div class="campaigns-table">
      <div class="table-header">
        <span>
          <mat-checkbox
            color="primary"
            [disableRipple]="true"
            [checked]="selectedCampaignLength === adsCampaigns.length"
            (change)="selectCampaigns($event)">
          </mat-checkbox>Imported campaigns
        </span>
        <span>Segment</span>
      </div>
      <div class="table-body">
        <div class="table-row" *ngFor="let camp of adsCampaigns">
          <span class="holder">
            <mat-checkbox
              color="primary"
              [disableRipple]="true"
              [checked]="selectedCampaigns[camp.id]"
              (change)="selectCampaign($event, camp.id)">
            </mat-checkbox>
            <span
              class="campaign-name"
              (click)="openCampaignDetails(camp.id)">
              {{ camp.name }}
            </span>
          </span>
          <span class="select-holder">
            <hierarchy-select
              class="regular-select"
              [items]="selectItems"
              [selectedValue]="selectedValue"
              [config]="selectConfig"
              [groupSelectionAllowed]="false"
              [disabled]="!selectedCampaigns[camp.id]"
              (onChange)="assignSegment(camp.id, $event)"
            ></hierarchy-select>
          </span>
        </div>
      </div>
    </div>
  </div>
</widget-card>
