<article
  class="details-page-wrapper"
  [matTooltip]="currentState?.name"
  [matTooltipDisabled]="isActive"
  matTooltipClass="dark-theme-tooltip simple drawer-name-tooltip"
  matTooltipPosition="left"
>
  <loader [isVisible]="isLoading"></loader>
  <drawer-header
    objectType="metric"
    objectLabel="Metric"
    [detailsFormGroupData]="formData"
    [disableSaveAndCloseButton]="!disableSaveAndCloseButton"
    [actions]="menuActions"
    [budgetList]="budgets"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedMetricChangesFlag"
    [nameFormControl]="fdNameControl"
    [maxObjectNameLength]="budgetObjectDetailsManager.maxObjectNameLength"
    [createdDate]="currentState?.created"
    [updatedDate]="currentState?.updated"
    [headerBackgroundClass]="headerBackgroundClass[objectType]"
    [isCEGBudget]="true"
    [parentId]="currentMetricState?.parentId"
    [parentName]="parentName"
    [objectHierarchy]="objectHierarchy"
    [childMetricHierarchy]="childMetricHierarchy"
    [isCampaignMetricDrawer]="isCampaignMetricDrawer"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAndCloseAction.bind(this, false))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
  ></drawer-header>

  <drawer-tab-switch

    [activeTabId]="activeTabId"
    [tabs]="tabsData"
    (activeTabChanged)="setActiveTab($event)"
  ></drawer-tab-switch>

  <div
    class="details-content tab-content-wrap"
    [class.no-scroll]="activeTabId === ObjectDetailsTabControl.Update"
  >
    <metric-details-form
      *ngIf="currentMetricState && formDataInit"
      [formData]="formData"
      [currentMetricState]="currentMetricState"
      [ngClass]="{ visible: activeTabId === ObjectDetailsTabControl.Details }"
      [metricBreakdownData]="metricBreakdownData"
      [displayDecimal]="displayDecimal"
      [isCampaignMetricDrawer]="isCampaignMetricDrawer"
      [hasActiveThirdPartyAmounts]="hasActiveThirdPartyAmounts"
      (onStartDateChange)="startDateChange($event)"
      (syncUnsavedMetricChanges)="syncUnsavedMetricChanges($event)"
      (syncIsMilestonesDataInvalid)="syncIsMilestonesDataInvalid($event)"
      (updateTargetValueInBreakdown)="updateTargetValueInBreakdown()"
      (syncMetricFormValidity)="syncMetricFormValidity($event)"
      (syncFormDataValuesWithState)="syncFormDataValuesWithState($event)"
    ></metric-details-form>

    <div
      
      [ngClass]="{ visible: activeTabId === ObjectDetailsTabControl.Update }"
    >
      <metric-details-update-form
        [isReadOnlyMode]="currentMetricState?.mappingType === configuration.OBJECT_TYPES.goal || isReadOnlyMode"
        [metricGoalUpdateHistory]="metricGoalUpdateHistory"
        *ngIf="currentMetricState && currentMetricState.metricValueUpdates"
        [formData]="formData"
        [campaign]="campaign"
        [metricUpdates]="currentMetricState.metricValueUpdates"
        [budgetTodayDate]="budgetTodayDate"
        [currentMetricState]="currentMetricState"
        [currentState]="currentState"
        [isMilestonesFormInvalid]="isMilestonesFormInvalid"
        (toggleLoader)="toggleLoader($event)"
        (setMetricValueLastUpdatedDate)="setMetricValueLastUpdatedDate($event)"
        (syncUnsavedMetricChanges)="syncUnsavedMetricChanges($event)"
        (syncMetricFormValidity)="syncMetricFormValidity($event)"
      ></metric-details-update-form>
    </div>

    <div
      class="tab-content-container"
      [ngClass]="{
        visible: activeTabId === ObjectDetailsTabControl.Performance
      }"
    >
      <metric-details-performance
        [currentMetricState]="currentMetricState"
        [isGraphDataValid]="isMetricFormValid"
        [graphData]="graphData"
        [metricProgressTowardsTarget]="metricProgressTowardsTarget"
        [budget]="budget"
        [displayDecimal]="displayDecimal"
        [currentState]="currentState"
        [currencyCode]="company?.currency"
        [revenuePerOutcome]="currentState?.revenuePerOutcome"
        [totalValue]="currentState?.summary?.totalValue"
        [totalTarget]="currentState?.summary?.targetValue"
        [costPerOutcome]="currentState?.summary?.currentCPO"
        [targetCostPerOutcome]="currentState?.summary?.targetCPO"
        [returnOnInvestment]="currentState?.summary?.currentROI"
        [targetReturnOnInvestment]="currentState?.summary?.targetROI"
        [performanceEstimateData]="performanceEstimateData"
      ></metric-details-performance>
    </div>
  </div>
</article>
