<button class="btn-addCampaignView btn-option"
        type="button"
        title=""
        [disabled]="disableDropdown"
        (click)="onTriggerClick()"
        [ngClass]="{ 'disabled': disableDropdown }">
  {{ title }}
</button>

<ul class="list-expOption list-acctAction" *ngIf="optionsList">
  <li *ngFor="let option of optionsList"
      [ngClass]="{ 'openMenu': activeOptionsItem === option.id }">
    <button class="btn-acctAction"
            [class.is-select] = "option.values?.length"
            type="button"
            (click)="option.directAction?.() || toggleActiveGroup(option.id)">
      {{ option.title }}
    </button>
    <ul *ngIf="activeOptionsItem === option.id && option.values?.length" class="list-acctActionInn">
      <li *ngFor="let val of option.values">
        <button type="button" (click)="val.cb && val.cb()">{{ val.text }}</button>
      </li>
    </ul>
  </li>
</ul>
