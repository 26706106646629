export interface DisableCompaniesIntegrationResponse {
  message: string;
  success: number[];
  error: number[];
}

export enum importStart {
  CURRENT_TF = 'currentTimeframeStart',
  BUDGET = 'budgetStart',
}

export interface IntegrationAccount {
  id: string;
  name: string;
}

export interface IntegrationData {
  budgetId?: number;
  email?: string;
  importDataFrom?: string;
  groupCampaigns?: boolean;
  name?: string;
  accounts?: IntegrationAccount[];
  selectedAccountIds?: string[];
  segmentData?: string;
  parentCampaignId?: number;
  syncAllocatedAmounts?: boolean;
  reauthRequired?: boolean;
}

export interface Integration {
  integrationId: string;
  data: IntegrationData
}

export interface IntegrateCompanyResponse {
  location: string;
  integrationId: string
}

export interface ObjectMappingItem<TObjectMapping> {
  companyId: number;
  key?: string;
  updatedBy?: {
    sub: string;
    email: string;
    id: number;
  };
  updatedTime?: number;
  body: TObjectMapping;
}

export interface SyncStatusResponse {
  integrationId: string;
  status: string;
}

export interface BudgetIntegrationCampaignMappingsResponse {
  campaignMappingItems: CampaignMappingItem[];
  costAdjustmentExpenseBucketIds?: number[];
}

export interface CampaignMappingItem {
  campaignId: number;
  extCampaignId: string;
}
