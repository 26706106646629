import { inject, Injectable } from '@angular/core';
import { ManageCegTableRow, ManageTablePerformanceColumnName } from '../../types/manage-ceg-page.types';
import { TableRowAmountsLoader } from '../../types/manage-ceg-table-row-data.types';
import { Observable } from 'rxjs';
import { BudgetService } from '@shared/services/backend/budget.service';
import { tap } from 'rxjs/operators';
import {
  BudgetEstimatedBusinessValue,
  BudgetEstimatedBusinessValueCampaign
} from '@shared/types/budget-estimated-business-value.interface';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { ManageCegTableHelpers } from '@manage-ceg/services/manage-ceg-table-helpers';

@Injectable()
export class CampaignPerformanceAmountsLoader implements TableRowAmountsLoader {
  private readonly budgetService = inject(BudgetService);
  public readonly rowType = ManageTableRowType.Campaign;

  private static fillRowPerformanceData(targetRow: ManageCegTableRow, campaignData: BudgetEstimatedBusinessValueCampaign): void {
    targetRow.performanceData = {
      [ManageTablePerformanceColumnName.TargetReturn]: [campaignData.target_return],
      [ManageTablePerformanceColumnName.CurrentReturn]: [campaignData.current_return],
      [ManageTablePerformanceColumnName.LowForecast]: [campaignData.low_forecast],
      [ManageTablePerformanceColumnName.HighForecast]: [campaignData.high_forecast],
      [ManageTablePerformanceColumnName.TargetRoi]: [campaignData.target_roi],
      [ManageTablePerformanceColumnName.CurrentRoi]: [campaignData.current_roi],
      [ManageTablePerformanceColumnName.Owner]: [ManageCegTableHelpers.getInitials(campaignData.campaign_owner_name)]
    };
  }

  fillRowAmounts(budgetId: number, rows: ManageCegTableRow[], _params: object): Observable<any> {
    const campaignIds = rows.map(row => row.objectId);
    return this.budgetService.getEstimatedBusinessValue(budgetId, { campaign_ids: campaignIds.join(',') }).pipe(
      tap(data => this.applyBusinessValueDataToRows(data, rows))
    );
  }

  private applyBusinessValueDataToRows(data: BudgetEstimatedBusinessValue, rows: ManageCegTableRow[]): void {
    data?.results?.campaigns?.forEach(campaignData => {
      const targetRow = rows.find(row => row.objectId === campaignData.campaign_id);
      if (targetRow) {
        CampaignPerformanceAmountsLoader.fillRowPerformanceData(targetRow, campaignData);
      }
    });
  }
}
