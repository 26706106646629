import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyUserService } from 'app/shared/services/backend/company-user.service';

enum RequestStates {
  Processing = 'processing',
  Success = 'success',
  Failure = 'failure'
}

@Component({
  selector: 'unsubscribe-page',
  templateUrl: './unsubscribe-page.component.html',
  styleUrls: ['./unsubscribe-page.component.scss']
})
export class UnsubscribePageComponent implements OnInit {
  public requestState;
  RequestStates = RequestStates;
  currentYear = new Date().getFullYear();

  constructor(private route: ActivatedRoute, private companyUserService: CompanyUserService) { }

  ngOnInit() {
    const { id, hash } = this.route.snapshot.queryParams;
    this.unsubscribeUser(id, hash);
  }

  unsubscribeUser(id: number, hash: string) {
    if (id && hash) {
      this.requestState = RequestStates.Processing;
      this.companyUserService.unsubscribeUserToEmail(id, hash).subscribe(
        () => this.requestState = RequestStates.Success,
        () => this.requestState = RequestStates.Failure,
      );
    } else {
      this.requestState = RequestStates.Failure;
    }
  }
}
