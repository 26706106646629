<page-header pageName="Metrics">
  <fa-icon slot="icon" [icon]="['fas', 'chart-line']"></fa-icon>
</page-header>
<section class="page-content"
         [ngClass]="{ 'show-backdrop': standaloneEditing || funnelOnEditId !== null }">
  <div class="page-container" #pageContainer>
    <div #standaloneMetricsWrap>
      <standalone-metrics
        [ngClass]="{ 'edit-mode-enabled': standaloneEditing }"
        [editMode]="standaloneEditing"
        [metrics]="standaloneMetrics$ | async"
        [panelExpanded]="standalonePanelExpanded"
        [disabled]="funnelOnEditId !== null"
        (saveData)="saveStandalone($event)"
        (setEditMode)="setStandaloneEditMode($event)"
        (openObjectsUsingMetric)="openObjectsUsingMetric($event)"
      ></standalone-metrics>
    </div>

    <funnel-page-controls
      [disabled]="standaloneEditing || funnelOnEditId !== null || creationProcess"
      (addFunnel)="addNewFunnel($event)"
    ></funnel-page-controls>

    <div style="overflow-x: auto; overflow-y: hidden">
      <div class="funnels">
        <product-funnel
          [ngClass]="{ 'edit-mode-enabled': funnelOnEditId === funnel.id }"
          *ngFor="let funnel of metricFunnels; let i = index; trackBy: trackFn"
          [attr.data-funnel-id]="'funnel-' + funnel.id"
          [funnel]="funnel"
          [editMode]="funnelOnEditId === funnel.id"
          [disabled]="(standaloneEditing || (funnelOnEditId && funnelOnEditId !== funnel.id)) || dataUpdating[funnel.id]"
          [panelsStates]="panelsStates"
          [activeView]="panelsViewModes[funnel.id] || productFunnelView.ARPO"
          (setFunnelEditId)="setFunnelEditId($event)"
          (expansionState)="setFunnelExpansionState(funnel.id, $event)"
          (setFunnelViewMode)="setFunnelViewMode(funnel.id, $event)"
          (saveData)="saveFunnel($event, funnel.id)"
          (duplicateFunnel)="duplicateFunnel(i)"
          (remove)="removeFunnel(funnel)"
          (moveItem)="changeFunnelOrder($event, i)"
          (setFunnelState)="setFunnelState($event, funnel)"
          (openObjectsUsingMetric)="openObjectsUsingMetric($event)"
        ></product-funnel>
      </div>
    </div>

  </div>
</section>
