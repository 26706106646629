import { inject, Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { BudgetService } from 'app/shared/services/backend/budget.service';
import { CurrencyService } from 'app/shared/services/backend/currency.service';
import { CompanyCurrencyService } from 'app/shared/services/backend/company-currency.service';
import { CompanyExchangeRateService } from 'app/shared/services/backend/company-exchange-rate.service';
import { Currency } from 'app/shared/types/currency.interface';
import {
  ExchangeRatesTableData,
  ExchangeRatesTableCurrency,
  ExchangeRatesTableRatePerMonth,
  ExchangeRatesTableMonth,
  ExchangeRatesTableChangeRateEvent
} from './exchange-rates-table/exchange-rates-table.interface';
import { map, switchMap } from 'rxjs/operators';
import { AuditLogDO, AuditLogService } from 'app/shared/services/backend/audit-log.service';

const LOG_TYPE = 'CompanyExchangeRate';
const MONTH_MAPPINGS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

@Injectable()
export class ExchangeRatesService {
  private readonly budgetService = inject(BudgetService);
  private readonly currencyService = inject(CurrencyService);
  private readonly companyCurrencyService = inject(CompanyCurrencyService);
  private readonly companyExchangeRateService = inject(CompanyExchangeRateService);
  private readonly auditLogService = inject(AuditLogService);

  getTableData(companyId: number, baseCurrencyCode: string): Observable<ExchangeRatesTableData> {
    return forkJoin([
      this.getTableMonths(companyId),
      this.getTableCurrencies(companyId),
      this.getTableRates(companyId)
    ]).pipe(map(data => {
      const [months, currencies, rates] = data;
      const currenciesExceptBase = currencies.filter(c => c.currency !== baseCurrencyCode);
      return {
        months,
        currencies: this.prepareTableCurrencies(currenciesExceptBase, rates, months, baseCurrencyCode),
      }
    }));
  }

  getTableMonths(companyId: number): Observable<ExchangeRatesTableMonth[]> {
    return this.budgetService.getAvailableBudgetsForCompany(companyId)
      .pipe(map(budgets => {
        const minBudgetStartDate: Date = this.budgetService.getMinBudgetStartDate(budgets);
        const maxBudgetEndDate: Date = this.budgetService.getMaxBudgetEndDate(budgets);
        if (!minBudgetStartDate || !maxBudgetEndDate) {
          return [];
        }

        // this is done to prevent jumping over February when we increment the month
        minBudgetStartDate.setDate(1);

        const months: ExchangeRatesTableMonth[] = [];
        const currentDate = new Date();
        let isFirstMonthOfYear = true;
        do {
          months.push(this.prepareMonth(minBudgetStartDate, isFirstMonthOfYear, currentDate));
          minBudgetStartDate.setMonth(minBudgetStartDate.getMonth() + 1);
          minBudgetStartDate.setDate(1);
          isFirstMonthOfYear = false;
        } while (minBudgetStartDate < maxBudgetEndDate);

        return months;
      }));
  }

  prepareMonth(date: Date, isFirstMonthOfYear: boolean, currentDate: Date): ExchangeRatesTableMonth {
    const year = date.getFullYear();
    const month = date.getMonth();
    const monthStr = ('0' + (date.getMonth() + 1)).slice(-2);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    return {
      year,
      name: MONTH_MAPPINGS[month],
      index: month,
      value: `${year}-${monthStr}`,
      isFirstMonthOfYear: month === 0 || isFirstMonthOfYear,
      isCurrent: currentYear === year && currentMonth === month
    }
  }

  getTableCurrencies(companyId: number): Observable<any[]> {
    return this.companyCurrencyService.getCompanyCurrencies(companyId);
  }

  getTableRates(companyId: number, currencyCode?: string) {
    return this.companyExchangeRateService.getRates(companyId, currencyCode);
  }

  prepareRatesPerMonth(
    months: ExchangeRatesTableMonth[],
    currentDate: Date,
    isBaseCurrency: boolean,
    currencyRates?: any
  ): ExchangeRatesTableRatePerMonth[] {
    return months.map((month: ExchangeRatesTableMonth) => ({
      month: month.value,
      rate: isBaseCurrency ? 1 : currencyRates[month.value] || '',
      isCurrent: month.year === currentDate.getFullYear() && month.index === currentDate.getMonth(),
      isEditAllowed: !isBaseCurrency,
      isEditMode: false,
      isFirstMonthOfYear: month.index === 0
    }));
  }

  prepareTableCurrencies(
    currencies,
    rates,
    months: ExchangeRatesTableMonth[],
    baseCurrencyCode: string
  ): ExchangeRatesTableCurrency[] {
    const tableCurrencies: ExchangeRatesTableCurrency[] = [];
    tableCurrencies.push(this.prepareTableBaseCurrency(months, baseCurrencyCode));

    if (!currencies || !rates) {
      return tableCurrencies;
    }

    const masterCurrencies: Currency[] = this.currencyService.currencyList$.getValue();

    currencies.forEach(currencyData => {
      const { id, currency: currencyCode, is_new } = currencyData;
      const { id: rateId, rates: currencyRates } = rates.find(r => r.currency === currencyCode) || { id: -1, rates: {} };
      const currency: Currency = masterCurrencies.find((c: Currency) => c.code === currencyCode);
      const tableCurrency: ExchangeRatesTableCurrency = {
        id,
        rateId,
        name: currency.name,
        code: currency.code,
        symbol: currency.symbol,
        isNew: is_new,
        isBase: false,
        isEditMode: false,
        ratesPerMonth: this.prepareRatesPerMonth(months, new Date(), false, currencyRates)
      };
      tableCurrencies.push(tableCurrency);
    });

    tableCurrencies.sort(this.compareCurrencies);

    return tableCurrencies;
  }

  compareCurrencies(currency1: ExchangeRatesTableCurrency, currency2: ExchangeRatesTableCurrency) {
    if (currency1.isBase) {
      return -1;
    }

    if (currency2.isBase) {
      return 1;
    }

    if (currency1.name === currency2.name) {
      return 0;
    }

    return currency1.name < currency2.name ? -1 : 1;
  }

  prepareTableBaseCurrency(months: ExchangeRatesTableMonth[], baseCurrencyCode: string): ExchangeRatesTableCurrency {
    const currencies: Currency[] = this.currencyService.currencyList$.getValue();
    const baseCurrency: Currency = currencies.find((currency: Currency) => currency.code === baseCurrencyCode);
    const ratesPerMonth: ExchangeRatesTableRatePerMonth[] = this.prepareRatesPerMonth(months, new Date(), true);

    return {
      id: -1,
      rateId: -1,
      name: baseCurrency.name,
      code: baseCurrency.code,
      symbol: baseCurrency.symbol,
      dailyRate: 1,
      isBase: true,
      isNew: false,
      isEditMode: false,
      ratesPerMonth
    };
  }

  prepareRatesDataForUpdate(changeRateData: ExchangeRatesTableChangeRateEvent) {
    const { currency, rateData } = changeRateData;
    const { ratesPerMonth } = currency;
    const { editableValue, month } = rateData;
    return ratesPerMonth.reduce((store, currentRate) => {
      const { month: currentMonth, rate } = currentRate;

      if (currentMonth === month) {
        store[currentMonth] = Number.parseFloat(editableValue.replace(/\,/g, ''));
      } else {
        store[currentMonth] = rate;
      }

      return store;
    }, {});
  }

  prepareRatesDataForAdd(rate: number, months: ExchangeRatesTableMonth[]) {
    return months.reduce((store, currentMonth: ExchangeRatesTableMonth) => {
      const { value } = currentMonth;
      store[value] = rate;
      return store
    }, {});
  }

  getLogs(limit?: number, offset?: number, companyId?: number): Observable<AuditLogDO[]> {
    return this.auditLogService.getLogs({
      log_type: LOG_TYPE,
      limit,
      offset,
      company: companyId
    });
  }

  updateRate(changeRateData: ExchangeRatesTableChangeRateEvent, companyId: number) {
    const { currency } = changeRateData;
    const { rateId, code } = currency;
    const rates = this.prepareRatesDataForUpdate(changeRateData);

    return this.companyExchangeRateService.updateRate(rateId, code, companyId, rates);
  }

  addOrUpdateRate(companyId: number, currencyCode: string, rates: any) {
    return this.companyExchangeRateService.getRates(companyId, currencyCode)
      .pipe(switchMap(ratesResponse =>
        ratesResponse && ratesResponse.length ?
          this.companyExchangeRateService.updateRate(ratesResponse[0].id, currencyCode, companyId, rates) :
          this.companyExchangeRateService.addRate(companyId, currencyCode, rates)
      ));
  }

  addCurrency(companyId: number, currencyCode: string, rate: number, months: ExchangeRatesTableMonth[]) {
    const rates = this.prepareRatesDataForAdd(rate, months);
    return forkJoin([
      this.companyCurrencyService.addCurrency(companyId, currencyCode),
      this.addOrUpdateRate(companyId, currencyCode, rates),
      of(rate)
    ]);
  }

  removeCurrency(currencyId: number, rateId: number) {
    return forkJoin([
      this.companyCurrencyService.removeCurrency(currencyId),
      this.companyExchangeRateService.removeRates(rateId),
    ])
  }
}
