<div
  class="timeframe-lock"
  (click)="toggleLock()"
  [ngClass]="{
    'disabled': disabled,
    'is-locked': timeframe?.locked,
    'is-current': isCurrent,
    'is-yearly': isYearlyBudget,
    'is-not-editable': !editable
  }"
  [matTooltip]="timeframe?.locked ? lockedTooltip : unlockedTooltip"
  [matTooltipDisabled]="isYearlyBudget || !editable"
  matTooltipPosition="above"
  matTooltipClass="dark-theme-tooltip above timeframe-lock-button"
>
  <div class="icon" *ngIf="!isYearlyBudget">
    <fa-icon [icon]="['far', 'lock-open']" *ngIf="editable && !timeframe?.locked" class="unlocked-icon"></fa-icon>
    <fa-duotone-icon [icon]="['fad', 'lock-alt']" *ngIf="timeframe?.locked" class="locked-icon"></fa-duotone-icon>
  </div>
  {{ isYearlyBudget ? fyColumnName : (timeframe?.name | slice:0:3) }}
</div>
