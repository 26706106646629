import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../../app.constants';
import { SKIP_COMMON_HEADERS } from '@common-lib/lib/http/http-common-headers.interceptor';
import { SKIP_AUTH } from '../../user/services/http-auth.interceptor';
import { InvoiceUploadContext, UploadStatus } from '@spending/services/invoice-upload-manager.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { CompanyDataService } from '@shared/services/company-data.service';
import { UserManager } from '../../user/services/user-manager.service';

export interface TempLink {
  url: string;
  track_id: string;
}

export interface UploadStatusResponse {
  key: string;
  status: UploadStatus;
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class InvoiceUploadService {
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly userManager = inject(UserManager);
  private readonly configuration = inject(Configuration);
  private readonly http = inject(HttpClient);

  private readonly invoiceProcessingApiUrl = this.configuration.invoice_processing_service_url;

  getTemporaryLinks(files: File[], context: InvoiceUploadContext): Observable<TempLink[]> {
    return this.http.post<TempLink[]>(`${this.invoiceProcessingApiUrl}generate_links`, {
      files: files.map(file => ({ name: file.name })),
      user_id: context.userId,
      company_id: context.companyId,
      budget_id: context.budgetId
    });
  }

  putFileToS3(file: File, url: string, trackId: string): Observable<File> {
    return this.http.put<File>(url, file, {
      headers: new HttpHeaders({ ...this.getUploadHeaders(file), ...this.getSignedHeaders(trackId) }),
      context: new HttpContext().set(SKIP_COMMON_HEADERS, true).set(SKIP_AUTH, true),
    });
  }

  getStatus(keys: string[]): Observable<UploadStatusResponse[]> {
    return this.http.post<UploadStatusResponse[]>(`${this.invoiceProcessingApiUrl}status_invoice_processing`, {
      keys
    });
  }

  private getUploadHeaders(file: File): Record<string, string> {
    return {
      'Content-Type': this.getFileContentType(file),
      'x-amz-server-side-encryption': 'AES256'
    };
  }

  private getSignedHeaders(trackId: string): Record<string, string> {
    return {
      'x-amz-meta-company_id': this.companyDataService.selectedCompanySnapshot.id.toString(),
      'x-amz-meta-budget_id': this.budgetDataService.selectedBudgetSnapshot.id.toString(),
      'x-amz-meta-user_id': this.userManager.getCurrentUser().id.toString(),
      'x-amz-meta-track_id': trackId
    };
  }

  private getFileContentType(file: File): string {
    const extension = file.name.split('.').pop().toLowerCase();
    const contentTypeMap = {
      pdf: 'application/pdf',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      tiff: 'image/tiff',
      tif: 'image/tiff'
    };

    return contentTypeMap[extension];
  }
}
