import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { PdfViewerService } from '@shared/services/pdf-viewer.service';
import { PDFSource, PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Observable } from 'rxjs';

const PAGE_WIDTH = 'page-width';
const PAGE_HEIGHT = 'page-height';
@Component({
  selector: 'pdf-document-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfDocumentViewerComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() isBase64Format: boolean;
  @Input() src: PDFSource;

  pdfSrc$: Observable<PDFSource>;
  page$: Observable<number>;
  totalPages$: Observable<number>;
  zoom$: Observable<number>;
  isLoaded$: Observable<boolean>;

  zoomStep: number = 0.05;
  isOriginalSize: boolean = false;
  isFitToPage: boolean = true;
  zoomScale = PAGE_WIDTH;

  constructor(private pdfViewerService: PdfViewerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const isBase64 = !!changes.isBase64Format;
    this.pdfViewerService.setSource(changes.src.currentValue, isBase64);
  }

  afterLoadComplete(pdfData: PDFDocumentProxy): void {
    this.pdfViewerService.setTotal(pdfData.numPages);
    this.pdfViewerService.setIsLoaded(true);
    this.pdfViewerService.setZoom(0.95);
  }

  zoomIn(zoom: number, step: number): void {
    this.pdfViewerService.setZoom(zoom + step);
    this.zoomScale = PAGE_WIDTH;
  }

  zoomOut(zoom: number, step: number): void {
    this.pdfViewerService.setZoom(zoom - step);
    if (zoom < 0) {
      this.pdfViewerService.setZoom(0);
    }
    this.zoomScale = PAGE_WIDTH;
  }

  fitView(): void {
    this.zoomScale = PAGE_HEIGHT;
  }

  pageRendered(): void {
    this.pdfViewerService.setZoom(1);
    this.pdfViewerService.setRenderedState(true);
  }

  pageChanged(pageNumber: number): void {
    this.pdfViewerService.setPage(pageNumber);
  }

  onError(e: any) {
    this.pdfViewerService.setIsLoaded(false);
  }

  ngOnInit(): void {
    this.pdfSrc$ = this.pdfViewerService.src$;
    this.isLoaded$ = this.pdfViewerService.isLoaded$;
    this.totalPages$ = this.pdfViewerService.totalPages$;
    this.zoom$ = this.pdfViewerService.zoom$;
    this.page$ = this.pdfViewerService.page$;
    this.pdfViewerService.screenWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    this.pdfViewerService.resetProperties();
    this.pdfViewerService.setRenderedState(true);
  }
}
