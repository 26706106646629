<div class="form-label">
  Download data <b>daily</b> at
</div>
<div class="form-input">
  <mat-form-field appearance="fill" class="time-field">
    <input matInput [ngxTimepicker]="timepicker" [value]="selectedTime" [readonly]="true">
    <fa-icon matSuffix [icon]="['fad', 'clock']"></fa-icon>
  </mat-form-field>
  <ngx-material-timepicker
    #timepicker
    [minutesGap]="minutesGap"
    [theme]="timepickerTheme"
    (timeSet)="handleTimeChanged($event)"
  ></ngx-material-timepicker>
</div>
<div class="form-input">
  <mat-form-field class="no-label timezone-field">
    <mat-select
      [(ngModel)]="selectedTmz"
      name="timezone"
      panelClass="mat-select-timezone"
      placeholder="Select timezone"
      (ngModelChange)="handleTmzChanged($event)"
      #tmzSelect
    >
      <mat-select-trigger>
        {{ selectedTmz | tmzStringify: { gmtOnly: true } }}
        <drop-down-arrow [opened]="tmzSelect.panelOpen"></drop-down-arrow>
      </mat-select-trigger>
      <mat-option *ngFor="let zone of timezones" [value]="zone">
        {{ zone | tmzStringify }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
