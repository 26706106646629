import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { BudgetTableRecord } from '../components/budget-table/budget-table.types';
import { Configuration } from 'app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class BudgetTableValidationService {
  private formDataErrors: Record<string, Record<string, string>> = {};
  private recordKeysByName = new Map<string, string[]>();

  constructor(
    private readonly config: Configuration,
  ) {
  }

  private addRecordName(name: string, recordKey: string) {
    const existingRecords = this.recordKeysByName.get(name) || [];

    if (!name.length || existingRecords.includes(recordKey)) {
      return;
    }

    existingRecords.push(recordKey);
    this.recordKeysByName.set(name, [...existingRecords]);
  }

  private removeRecordName(name: string, recordKey: string) {
    const existingRecords = this.recordKeysByName.get(name) || [];
    const recordIndex = existingRecords.findIndex(key => key === recordKey);

    if (!name.length || recordIndex < 0) {
      return;
    }
    existingRecords.splice(recordIndex, 1);
    if (!existingRecords.length) {
      this.recordKeysByName.delete(name);
    }
  }

  public setFormDataError(error: string | null, fieldName: string, record: BudgetTableRecord) {
    if (!this.formDataErrors[record.key]) {
      this.formDataErrors[record.key] = {};
    }

    if (error === null) {
      Reflect.deleteProperty(this.formDataErrors[record.key], fieldName);
      return;
    }

    this.formDataErrors[record.key][fieldName] = error;
  }

  public isFormDataValid(): boolean {
    const errorsCount = Object.values(this.formDataErrors)
      .reduce((count, errors) => {
        return count + Object.values(errors).length;
      }, 0);

    return errorsCount === 0;
  }

  public updateExistingName(prevName: string, newName: string, recordKey: string) {
    this.removeRecordName(prevName, recordKey);
    this.addRecordName(newName, recordKey);
  }

  public setExistingNames(records: BudgetTableRecord[] = []) {
    if (!records.length) {
      return;
    }

    const processRecord = (record: BudgetTableRecord) => {
      this.addRecordName(record.name, record.key);
      if (record.nestedRecords?.length) {
        record.nestedRecords.forEach(nestedRecord => processRecord(nestedRecord));
      }
    };

    records.forEach((record) => processRecord(record));
  }

  public uniqueNameValidator(record: BudgetTableRecord): ValidatorFn {
    const validator: ValidatorFn = (control: AbstractControl): { unique: boolean } => {
      const name = control.value;
      const existingRecords = this.recordKeysByName.get(name);

      if (!existingRecords || !name.length) {
        return null;
      }

      return existingRecords.length > 1 || existingRecords[0] !== record.key
        ? { unique: true }
        : null;
    };

    return validator;
  };

  public reservedNameValidator(record: BudgetTableRecord): ValidatorFn {
    const validator: ValidatorFn = (): { reserved: boolean } => {
      if (record.name === this.config.defaultSegmentName && this.recordKeysByName.size === 1) {
        return { reserved: true };
      }
      return null;
    };

    return validator;
  };

  public resetState() {
    this.recordKeysByName.clear();
    this.formDataErrors = {};
  }

  public createUniqueNameCopy(source: string, iterationLimit = 1000) {
    const suffix = 'Copy';
    let i = 0;
    let copyName = `${source} ${suffix}`;
    while (this.recordKeysByName.has(copyName) && i < iterationLimit) {
      copyName = `${source} ${suffix} ${++i}`;
    }

    return copyName;
  }
}
