<svg
  *ngIf="isMonoChrome; else nonMonoChrome"
  viewBox="0 0 20 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="width || size"
  [attr.height]="height || size"
>
  <path
    opacity="0.4"
    d="M0.41272 13.3367L7.063 1.81738L12.8255 5.14449L6.17611 16.6638L0.41272 13.3367Z"
    [attr.fill]="color"
  />
  <path
    d="M19.5314 13.2965L12.8772 1.77294C12.0181 0.148197 10.0057 -0.472461 8.38064 0.386551C6.75561 1.24556 6.13598 3.25806 6.99424 4.88309C7.03178 4.95431 7.07204 5.02385 7.11464 5.09273L13.7689 16.6163C14.6664 18.2196 16.6939 18.7921 18.2974 17.8946C19.9008 16.9971 20.4731 14.9695 19.5756 13.3661C19.5609 13.34 19.5462 13.3145 19.5314 13.2892V13.2965V13.2965Z"
    [attr.fill]="color"
  />
  <path
    d="M3.3272 18.3019C5.16477 18.3019 6.65441 16.8122 6.65441 14.9747C6.65441 13.1371 5.16477 11.6475 3.3272 11.6475C1.48964 11.6475 0 13.1371 0 14.9747C0 16.8122 1.48964 18.3019 3.3272 18.3019Z"
    [attr.fill]="color"
  />
</svg>
<ng-template #nonMonoChrome>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2585_119440)">
      <path
        opacity="0.4"
        d="M0.412598 14.3397L7.06287 2.82031L12.8254 6.14742L6.17599 17.6668L0.412598 14.3397Z"
        fill="#0063B7"
      />
      <path
        d="M19.5314 14.2965L12.8771 2.77294C12.018 1.1482 10.0056 0.527539 8.38057 1.38655C6.75555 2.24556 6.13592 4.25806 6.99418 5.88309C7.03172 5.95431 7.07198 6.02385 7.11458 6.09273L13.7689 17.6163C14.6663 19.2196 16.6939 19.7921 18.2973 18.8946C19.9007 17.9971 20.4731 15.9695 19.5756 14.3661C19.5609 14.34 19.5461 14.3145 19.5314 14.2892V14.2965Z"
        fill="#0063B7"
      />
      <path
        d="M3.3272 19.3028C5.16477 19.3028 6.65441 17.8132 6.65441 15.9756C6.65441 14.1381 5.16477 12.6484 3.3272 12.6484C1.48964 12.6484 0 14.1381 0 15.9756C0 17.8132 1.48964 19.3028 3.3272 19.3028Z"
        fill="#0063B7"
      />
    </g>
    <defs>
      <clipPath id="clip0_2585_119440">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</ng-template>
