<article
   class="details-page-wrapper"
   [matTooltip]="currentState?.name"
   [matTooltipDisabled]="isActive"
   matTooltipClass="dark-theme-tooltip simple drawer-name-tooltip"
   matTooltipPosition="left"
>
  <loader [isVisible]="isLoading"></loader>
  <drawer-header
    [detailsFormGroupData]="formData"
    [actions]="menuActions"
    [budgetList]="budgets"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedChangesFlag"
    [objectHierarchy]="hierarchy"
    [nameFormControl]="fdNameControl"
    [maxObjectNameLength]="budgetObjectDetailsManager.maxObjectNameLength"
    [objectType]="objectType"
    [objectLabel]="objectLabel"
    [createdDate]="currentState?.created"
    [updatedDate]="currentState?.updated"
    [headerBackgroundClass]="headerBackgroundClass[objectType]"
    [disableSaveAndCloseButton]="isCustomFieldsEnabledForProgram && !isCustomFieldsFormValid"
    [isCEGBudget]="cegStatusEnabled"
    (budgetToMoveSelected)="handleBudgetToMoveSelected($event)"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAction.bind(this))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
  ></drawer-header>

  <drawer-tab-switch
    [activeTabId]="activeTabId"
    [tabs]="tabsData"
    (activeTabChanged)="setActiveTab($event)"
  ></drawer-tab-switch>

  <div class="details-content tab-content-wrap">

    <program-details-form
      #detailsForm
      [ngClass]="{ 'visible': activeTabId === ObjectDetailsTabControl.Details }"
      [currentCompanyUser]="currentCompanyUser"
      [budgetTodayDate]="budgetTodayDate"
      [isReadOnlyMode]="isReadOnlyMode"
      [objectType]="objectType"
      [formData]="formData"
      [currentState]="currentState"
      [companyCurrencyCode]="companyCurrency?.code"
      [parentCampaignIsCommitted]="parentCampaignIsCommitted"
      [currencyList]="currencyList"
      (onAmountClick)="showAllocationTab()"
      (objectCurrencyChanged)="objectCurrencyChanged($event)"
      [locationItems]="locationItems"
      [selectSegmentsConfig]="selectSegmentsConfig"
      [allowedSegmentSelectItems]="allowedSegmentSelectItems"
      (handleSegmentChanged)="handleSegmentChanged($event)"
      (handleParentSelectionChange)="handleParentSelectionChange($event)"
      [ownerOptions]="ownerOptions"
      (handleOwnerChange)="handleOwnerChange($event)"
      [objectTypes]="programTypes"
      [isCustomTypeEntering]="isCustomTypeEntering"
      [unsavedCustomTypeId]="budgetObjectDetailsManager.unsavedCustomTypeId"
      (handleTypeChange)="handleTypeChange()"
      (handleCustomTypeChange)="handleCustomTypeChange()"
      [getVendorName]="getVendorName"
      [maxVendorNameLength]="configuration.MAX_VENDOR_NAME_LENGTH"
      (handleVendorChange)="handleVendorChange()"
      (PONumberChanged)="handlePONumberChanged()"
      [glCodes]="glCodes"
      [vendors]="vendors"
      [companyId]="companyId"
      [objectId]="objectId"
      (GLCodeChanged)="handleGLCodeChanged()"
      [tagsAutocompleteItems]="tagsManager.autocompleteItems"
      [isCustomFieldsEnabledForProgram]="isCustomFieldsEnabledForProgram"
      [resetCustomFieldsFormGroup]="resetCustomFieldsFormGroups"
      [attachmentsManager]="attachmentsManager"
      [resetFormAndFetchCustomField]="resetFormAndFetchCustomField"
      (handleFileAttached)="handleFileAttached($event)"
      (handleFileDelete)="handleFileDelete($event)"
      (handleFileDownload)="handleFileDownload($event)"
      (handleTasksUpdate)="handleTasksUpdate($event)"
      (syncUnsavedChangesFlag)="syncUnsavedChangesFlag($event)"
      (syncCustomFieldsFormValidity)="syncCustomFieldsFormValidity($event)"
      (syncCustomFieldsUsageChanges)="syncCustomFieldsUsageChanges($event)"
      (syncCustomFieldsFormGroup)="syncCustomFieldsFormGroup($event)"
      (customFieldsStateDiff)="customFieldsStateDiff($event)"
    >
    </program-details-form>

    <div class="tab-content-container"
         [ngClass]="{ 'visible': activeTabId === ObjectDetailsTabControl.Allocation }">
      <create-object-toolbar
        *ngIf="!isReadOnlyMode"
        [objectId]="objectId"
        [parentObjectLabel]="objectLabel"
        [objectTypes]="[ DrawerType.Expense ]"
        (createObject)="onCreateObject($event)"
      ></create-object-toolbar>
      <ceg-budget-allocations
        [tableColumns]="tableAllocationsColumns"
        [objectId]="currentState?.objectId"
        [objectType]="objectType"
        [companyId]="companyId"
        [budgetId]="budget?.id"
        [isReadOnlyMode]="isReadOnlyMode"
        [campaignIsClosed]="currentState?.mode === 'Closed'"
        [budgetTimeframes]="budgetTimeframes"
        [objectAllocations]="currentState?.allocations"
        [allocationTotals]="allocationTotals"
        [externalIntegrationType]="externalIntegration"
        (onChange)="handleAllocationsUpdate($event)"
      ></ceg-budget-allocations>
    </div>

    <div class="tab-content-container mt-1"
         [ngClass]="{ 'visible': activeTabId === ObjectDetailsTabControl.Performance }">
      remove
    </div>
  </div>
</article>
