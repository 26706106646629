import { SortParams } from '@shared/types/sort-params.interface';
import {
  ManageTableBreakdownValues,
  ManageTableBudgetAllocationValue,
  ManageTableData,
  ManageTableRow,
  ManageTableRowValues,
  ManageTableSpendingValues,
  ManageTableTimeframeValues
} from '../components/manage-table/manage-table.types';
import {
  getNonNegativeValue,
  getNumericValue,
  getPseudoObjectId,
  roundDecimal,
  sumAndRound,
  sumUpNumericValues
} from '@shared/utils/common.utils';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { SegmentedObjectTimeframe } from '@shared/types/object-timeframe.interface';
import { Budget, BudgetTimeframesType } from '@shared/types/budget.interface';
import { BulkActionTargets } from '@shared/types/bulk-action-targets.type';
import { ManageTableSelectionState } from '../types/manage-table-selection-state.types';
import { ManageTableViewMode } from '../types/manage-table-view-mode.type';
import { PlanObjectExpensesData, TimeframeExpensesAmount, TimeframeExpensesData } from '@shared/types/plan-object-expenses-data.type';
import { PlanObjectExpenses } from '../types/plan-object-expenses.interface';
import { BudgetSegmentDO } from '@shared/types/segment.interface';
import { ManageTableSpendingHelpers } from './manage-table-spending-helpers';
import { SegmentedBudgetObject } from '@shared/types/segmented-budget-object';
import { Campaign } from '@shared/types/campaign.interface';
import { Program } from '@shared/types/program.interface';
import { ManageCegTableRow, ManageCegViewMode } from '@manage-ceg/types/manage-ceg-page.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

export class ManageTableHelpers {
  public static getRowObjectId(objectType: ManageTableRowType, objectId: number | string): string {
    return `${objectType.toLowerCase()}_${objectId}`;
  }

  public static getObjectId(itemId: number | string): number {
    if (typeof itemId === 'number') {
      return itemId;
    }
    const [, objectIdPart] = itemId?.split('_') || [];
    const objectId = Number.parseInt(objectIdPart, 10);
    return objectId || null;
  }

  public static getParentId(context: {
    goal?: number;
    campaign?: number;
    segment?: number;
    segmentGroup?: number;
    viewMode?: ManageTableViewMode | ManageCegViewMode,
    objectExists?: (rowType: ManageTableRowType, objectId: number | string, segmentId?: number) => boolean
  }) {
    const { goal, campaign, segment, segmentGroup, viewMode, objectExists } = context;
    if (goal && viewMode === ManageTableViewMode.Goals) {
      return ManageTableHelpers.getRowObjectId(ManageTableRowType.Goal, goal);
    }

    if (campaign) {
      const segmentId = viewMode === ManageTableViewMode.Segments ? segment : null;
      if (objectExists?.(ManageTableRowType.Campaign, campaign, segmentId)) {
        return ManageTableHelpers.getRowObjectId(ManageTableRowType.Campaign, campaign);
      }
      const pseudoObjectId = getPseudoObjectId(campaign, segment);
      if (objectExists?.(ManageTableRowType.Campaign, pseudoObjectId, segmentId)) {
        return ManageTableHelpers.getRowObjectId(ManageTableRowType.Campaign, pseudoObjectId);
      }
    }

    if (segment && viewMode === ManageTableViewMode.Segments) {
      return ManageTableHelpers.getRowObjectId(ManageTableRowType.Segment, segment);
    }

    if (segmentGroup && viewMode === ManageTableViewMode.Segments) {
      return ManageTableHelpers.getRowObjectId(ManageTableRowType.SegmentGroup, segmentGroup);
    }
  }

  public static sumRecordValues(target: ManageTableTimeframeValues, source: ManageTableTimeframeValues): ManageTableTimeframeValues {
    const initialValues: ManageTableTimeframeValues = { ...target };

    return Object.entries(source || {}).reduce((result, entry) => {
      const [ timeframeId, sourceValue ] = entry;
      const targetValue: ManageTableBudgetAllocationValue = result[timeframeId];

      return {
        ...result,
        [timeframeId]: ManageTableHelpers.sumBudgetAllocationValues(sourceValue, targetValue)
      };
    }, initialValues);
  };

  public static sumBudgetAllocationValues(
    target: ManageTableBudgetAllocationValue,
    source: ManageTableBudgetAllocationValue
  ): ManageTableBudgetAllocationValue {
    return {
      allocated: sumAndRound(source?.allocated, target?.allocated),
      spent: sumAndRound(source?.spent, target?.spent),
      remainingAllocated: sumAndRound(source?.remainingAllocated, target?.remainingAllocated),
      remainingAllocatedAbs: sumAndRound(
        ManageTableHelpers.getChildRemainingAllocated(source?.remainingAllocated),
        ManageTableHelpers.getChildRemainingAllocated(target?.remainingAllocatedAbs)
      ),
    };
  };

  public static initTimeframeValues(timeframes: BudgetTimeframe[]): ManageTableTimeframeValues {
    return timeframes.reduce((values, timeframe) => ({
      ...values,
      [timeframe.id]: {
        allocated: 0,
        spent: 0,
        remainingAllocated: 0
      }
    }), {});
  }

  public static initBreakdownValues(): ManageTableBreakdownValues {
    return {
      segment: {
        total: {
          allocated: 0,
          spent: 0
        },
        values: {},
        spending: ManageTableSpendingHelpers.initSpendingValues()
      },
      unallocated: {
        total: {
          allocated: 0,
          spent: 0
        },
        values: {},
        spending: ManageTableSpendingHelpers.initSpendingValues()
      }
    };
  }

  public static mapObjectValues<T extends Program | Campaign>(
    target: T,
    timeframes: BudgetTimeframe[],
    budget: Budget,
    expensesData: PlanObjectExpensesData,
    viewMode: ManageTableViewMode,
    sharedCostPercent: number,
    selectedTimeframes?: BudgetTimeframe[]
  ): ManageTableRowValues {
    const { timeframes: targetTimeframes, amount } = target;
    const fullObjectAmount = target.isPseudoObject ? ManageTableHelpers.getFullAmount(amount, sharedCostPercent) : amount;
    const fullObjectTimeframes =
      target.isPseudoObject ? ManageTableHelpers.getFullObjectTimeframes(targetTimeframes, sharedCostPercent) : targetTimeframes;
    const targetObjectId = ManageTableHelpers.getObjectId(target.objectId);
    const targetExpensesData = ManageTableHelpers.getObjectExpenseData(viewMode, expensesData, targetObjectId, target.budgetSegmentId);
    const targetExpensesTotals = Object.values(targetExpensesData || {}).map(item => item.total);

    const allObjectExpensesData =
      viewMode === ManageTableViewMode.Segments ?
        ManageTableHelpers.getObjectExpenseData(null, expensesData, targetObjectId, null) :
        targetExpensesData;

    const allObjectExpensesTotals = Object.values(allObjectExpensesData || {}).map(item => item.total);
    const selectedTimeframeIds = (selectedTimeframes || []).map(tf => tf.id);

    const expensesTotalAmount: TimeframeExpensesAmount =
      ManageTableHelpers.getTimeframeExpensesDataTotals(
        targetExpensesData,
        selectedTimeframeIds
      );

    const spending: ManageTableSpendingValues =
      ManageTableSpendingHelpers.initSpendingValues({
        planned: expensesTotalAmount.planned,
        committed: expensesTotalAmount.committed,
        closed: expensesTotalAmount.closed,
      });

    if (budget?.suppress_timeframe_allocations) {
      const getExpenseByTimeframe = (timeFrames, targetExpData) => {
        return timeFrames.reduce((values, frame) => {
          values[frame.id] = { spent: 0 }
          if (targetExpData?.[frame.id]) {
            values[frame.id].spent = targetExpData[frame.id].total;
          }
          return values;
        }, {});
      }

      const fullRemainingAllocated = fullObjectAmount - sumUpNumericValues(allObjectExpensesTotals);
      return {
        values: getExpenseByTimeframe(timeframes, targetExpensesData),
        total: {
          allocated: amount,
          spent: sumUpNumericValues(targetExpensesTotals),
          remainingAllocated: sharedCostPercent != null ? roundDecimal(sharedCostPercent * fullRemainingAllocated) : fullRemainingAllocated
        },
        spending
      };
    }

    return budget.type === BudgetTimeframesType.Year
      ? {
        ...ManageTableHelpers.mapYearlyTimeframeValues(
          timeframes[0].id,
          targetExpensesData,
          amount,
          fullObjectAmount,
          allObjectExpensesData,
          sharedCostPercent
        ),
        spending
      }
      : {
        ...ManageTableHelpers.mapTimeframeValues(
          timeframes,
          targetTimeframes,
          targetExpensesData,
          fullObjectTimeframes,
          allObjectExpensesData,
          sharedCostPercent
        ),
        spending
      };
  }

  private static getFullAmount(sharedAmount: number, scrPercent: number): number {
    if (!scrPercent) {
      throw new Error('Shared cost rule percent cannot be zero or undefined');
    }

    return sharedAmount / scrPercent;
  }

  private static getFullObjectTimeframes(sharedTimeframes: SegmentedObjectTimeframe[], scrPercent: number): SegmentedObjectTimeframe[] {
    return sharedTimeframes.map(
      tf => ({
        ...tf,
        amount: ManageTableHelpers.getFullAmount(tf.amount, scrPercent)
      })
    );
  }

  public static mapYearlyTimeframeValues(
    budgetTimeframeId: number,
    timeframeExpensesData: TimeframeExpensesData,
    amount: number,
    totalAmount: number,
    allObjectExpensesData: TimeframeExpensesData,
    scrPercent: number
  ): ManageTableRowValues {
    const allSpent = getNumericValue(allObjectExpensesData?.[budgetTimeframeId]?.total);
    const fullRemainingAlloc = totalAmount - allSpent;
    const value: ManageTableBudgetAllocationValue = {
      allocated: amount,
      spent: getNumericValue(timeframeExpensesData?.[budgetTimeframeId]?.total),
      remainingAllocated: scrPercent != null ? roundDecimal(scrPercent * fullRemainingAlloc) : fullRemainingAlloc
    };

    return {
      values: {
        [budgetTimeframeId]: { ...value }
      },
      total: { ...value }
    };
  }

  public static mapTimeframeValues(
    budgetTimeframes: BudgetTimeframe[],
    objectTimeframes: SegmentedObjectTimeframe[],
    timeframeExpensesData: TimeframeExpensesData,
    fullObjectTimeframes: SegmentedObjectTimeframe[],
    fullTimeframeExpensesData: TimeframeExpensesData,
    scrPercent: number
  ): ManageTableRowValues {
    const initialValues = {
      values: this.initTimeframeValues(budgetTimeframes),
      total: { allocated: 0, spent: 0, remainingAllocated: 0 }
    };

    return budgetTimeframes.reduce((result, budgetTimeframe) => {
      const objectTimeframe = objectTimeframes.find(tf => tf.company_budget_alloc === budgetTimeframe.id);
      const spent = getNumericValue(timeframeExpensesData?.[budgetTimeframe.id]?.total);
      const fullSpent = getNumericValue(fullTimeframeExpensesData?.[budgetTimeframe.id]?.total);

      const allocated = getNumericValue(objectTimeframe?.amount);
      const fullAllocated =
        fullObjectTimeframes != null ?
          getNumericValue(
            fullObjectTimeframes.find(otf => otf.company_budget_alloc === budgetTimeframe.id)?.amount
          ) :
          allocated;
      const fullRemainingAlloc = fullAllocated - fullSpent;
      const remainingAllocated = scrPercent != null ? roundDecimal(scrPercent * fullRemainingAlloc) : fullRemainingAlloc;

      return {
        values: {
          ...result.values,
          [budgetTimeframe.id]: { allocated, spent, remainingAllocated }
        },
        total: {
          allocated: sumAndRound(result.total.allocated, allocated),
          spent: sumAndRound(result.total.spent, spent),
          remainingAllocated: sumAndRound(result.total.remainingAllocated, remainingAllocated),
        }
      };
    }, initialValues);
  }

  public static mapAllocatedTimeframeValues(
    budgetObject: SegmentedBudgetObject
  ): ManageTableTimeframeValues {
    return budgetObject.timeframes.reduce((result, tf) => ({
      ...result,
      [tf.company_budget_alloc]: {
        allocated: tf.amount,
        spent: null
      }
    }), {});
  }

  public static sumUpChildValues(target: ManageTableRow): ManageTableRowValues {
    const initialValues: ManageTableRowValues = {
      values: {...target.values},
      total: {
        allocated: 0,
        spent: 0,
        remainingAllocated: 0,
        remainingAllocatedAbs: 0
      },
      spending: ManageTableSpendingHelpers.initSpendingValues()
    };

    return target.shownChildren.reduce((result, child) => ({
      values: ManageTableHelpers.sumRecordValues(result.values, child.values),
      total: ManageTableHelpers.sumBudgetAllocationValues(result.total, child.total),
      spending: ManageTableSpendingHelpers.sumSpendingValues(result.spending, child.spending)
    }), initialValues);
  }

  public static sumUpChildBreakdownValues(target: ManageTableRow): ManageTableBreakdownValues {
    const initialValues: ManageTableBreakdownValues = ManageTableHelpers.initBreakdownValues();

    return target.shownChildren.reduce((result, child) => ({
      segment: {
        values: ManageTableHelpers.sumRecordValues(result.segment.values, child.segment.values),
        total: ManageTableHelpers.sumBudgetAllocationValues(result.segment.total, child.segment.total),
        spending: ManageTableSpendingHelpers.sumSpendingValues(result.segment.spending, child.segment.spending)
      },
      unallocated: {
        values: ManageTableHelpers.sumRecordValues(result.unallocated.values, child.unallocated.values),
        total: ManageTableHelpers.sumBudgetAllocationValues(result.unallocated.total, child.unallocated.total),
        spending: ManageTableSpendingHelpers.sumSpendingValues(result.unallocated.spending, child.unallocated.spending)
      }
    }), initialValues);
  }

  public static calcParentRecordValues(parentRecord: ManageTableRow, timeframes: BudgetTimeframe[]) {
    parentRecord.values = ManageTableHelpers.initTimeframeValues(timeframes);
    const { values, total } = ManageTableHelpers.sumUpChildValues(parentRecord);

    parentRecord.values = values;
    parentRecord.total = total;
  }

  public static sortData(appliedSorting: SortParams, data: ManageTableData) {
    const { column, reverse } = appliedSorting;
    const compareFn = (itemA, itemB) => (
      reverse
        ? itemB[column].localeCompare(itemA[column])
        : itemA[column].localeCompare(itemB[column])
    );

    // TODO: Implement the full algo of 'deep' sorting when the reqs are defined in PUP-4848
    data.sort((itemA, itemB) => {
      if (itemA.type === itemB.type) {
        return compareFn(itemA, itemB);
      }

      return 0;
    });
  }

  public static getBulkOperationMessage(
    count: { success: number; error: number; },
    successEnding = '',
    errorEnding = '',
    movedItemsCount: number = null,
    objectNoun = 'Object'
  ) {
    const successMovedItems = !count.error && movedItemsCount !== null ? movedItemsCount : count.success;
    const getObjectNoun = (itemsCount) => itemsCount === 1 ? objectNoun : `${objectNoun}s`;
    const messageChunks = [
      `${successMovedItems} ${getObjectNoun(successMovedItems)} ${successEnding}`
    ];

    if (count.error) {
      messageChunks.push(`${count.error} ${getObjectNoun(count.error)} ${errorEnding}`);
    }
    return messageChunks.join(' ');
  }

  public static getBulkTargetsTotalCount(targets: BulkActionTargets) {
    return (targets.campaigns?.length || 0) + (targets.expGroups?.length || 0) + (targets.goals?.length || 0);
  }

  public static getBulkTargetsFromSelection(selection: ManageTableSelectionState): BulkActionTargets {
    return {
      goals: Array.from(selection.goals),
      campaigns: Array.from(selection.campaigns),
      expGroups: Array.from(selection.expGroups),
      childCampaigns: Array.from(selection.childCampaigns ?? []),
      childExpGroups: Array.from(selection.childExpGroups ?? [])
    };
  }

  public static getBulkTargetsFromRecord(record: ManageTableRow | ManageCegTableRow): BulkActionTargets {
    return {
      campaigns: record.type === ManageTableRowType.Campaign ? [record.objectId] : [],
      expGroups: record.type === ManageTableRowType.ExpenseGroup ? [record.objectId] : [],
      goals: record.type === ManageTableRowType.Goal ? [record.objectId] : [],
    };
  }

  public static getChildRecordIds(target: ManageTableRow, includeTarget = false): string[] {
    const result: string[] = [];

    if (includeTarget) {
      result.push(target.id);
    }
    target.children.forEach(child => {
      result.push(child.id);
      if (child.children?.length) {
        result.push(...ManageTableHelpers.getChildRecordIds(child));
      }
    });

    return result;
  }

  public static getExpensesData(expenses: PlanObjectExpenses, rowType: ManageTableRowType): PlanObjectExpensesData {
    return rowType === ManageTableRowType.Campaign ?
        expenses?.campaigns :
        rowType === ManageTableRowType.ExpenseGroup ? expenses?.programs : null;
  }

  public static sumTimeframeExpensesAmount(
    sourceA: TimeframeExpensesAmount,
    sourceB: TimeframeExpensesAmount
  ): TimeframeExpensesAmount {
    const initialExpensesAmount: TimeframeExpensesAmount = {
      total: 0,
      planned: 0,
      committed: 0,
      closed: 0,
    };

    return Object.keys(sourceA || {}).reduce((result, key) => {
      return {
        ...result,
        [key]: sumAndRound(
          getNumericValue(sourceA?.[key]),
          getNumericValue(sourceB?.[key])
        )
      };
    }, initialExpensesAmount);
  }

  private static getObjectExpenseData(
    viewMode: ManageTableViewMode,
    expensesData: PlanObjectExpensesData,
    targetObjectId: number,
    segmentId: number
  ): TimeframeExpensesData {
    const objExpensesData = expensesData[targetObjectId];
    return viewMode === ManageTableViewMode.Segments ?
      objExpensesData?.[segmentId] :
      Object.keys(objExpensesData || {}).reduce(
        (expData, objSegmentId) => {
          const segmentExpensesData = objExpensesData[objSegmentId];
          Object.keys(segmentExpensesData).reduce(
            (resExpData, budgetAllocId) => {
              resExpData[budgetAllocId] = ManageTableHelpers.sumTimeframeExpensesAmount(
                segmentExpensesData[budgetAllocId],
                resExpData[budgetAllocId]
              );
              return resExpData;
            },
            expData
          );
          return expData;
        },
        {}
      );
  }

  public static identifyRecord(index, item: ManageTableRow) {
    return item.id;
  }

  public static identifyTimeframe(index, item: BudgetTimeframe) {
    return item.id;
  }

  public static getTimeframeExpensesDataTotals(
    data: TimeframeExpensesData,
    selectedTimeframeIds: number[] = []
  ): TimeframeExpensesAmount {
    const initialValues: TimeframeExpensesAmount = {
      total: 0,
      planned: 0,
      committed: 0,
      closed: 0,
    };

    return Object.entries(data || {})
      .filter(([key]) => !selectedTimeframeIds.length || selectedTimeframeIds.includes(Number(key)))
      .reduce((result: TimeframeExpensesAmount, [ _key, value]) => ({
        total: sumAndRound(result.total, value.total),
        planned: sumAndRound(result.planned, value.planned),
        committed: sumAndRound(result.committed, value.committed),
        closed: sumAndRound(result.closed, value.closed),
      }), initialValues);
  }

  /**
   * Child remaining allocated can't be negative for the calculations consistency
   */
  public static getChildRemainingAllocated(value: number): number {
    return getNonNegativeValue(value);
  }

  public static calcBreakdownValues(
    record: ManageTableRow,
    budgetSegment: BudgetSegmentDO,
    timeframeExpensesData: TimeframeExpensesData,
    suppressedMode = false,
    filteredTimeframes: BudgetTimeframe[] = []
  ) {
    const values = record.values;
    const total = record.total;
    const breakdownValues = ManageTableHelpers.initBreakdownValues();

    Object.entries(values).forEach(entry => {
      const [ timeframeId, value ] = entry;
      const budgetSegmentAmount = budgetSegment?.amounts.find(amount => amount.company_budget_alloc === Number(timeframeId));
      const segmentAllocated = getNumericValue(budgetSegmentAmount?.amount);
      const timeframeExpenses: TimeframeExpensesAmount = timeframeExpensesData?.[timeframeId];
      const segmentSpent = getNumericValue(timeframeExpenses?.total);
      const segmentValues: ManageTableBudgetAllocationValue = {
        allocated: segmentAllocated,
        spent: segmentSpent,
        remainingAllocated: segmentAllocated - segmentSpent
      };
      const unallocatedValues: ManageTableBudgetAllocationValue = {
        allocated: sumAndRound(segmentValues.allocated, -value.allocated),
        spent: sumAndRound(segmentValues.spent, -value.spent)
      };

      breakdownValues.segment.total.allocated = sumAndRound(
        breakdownValues.segment.total.allocated,
        segmentValues.allocated
      );
      breakdownValues.segment.total.spent = sumAndRound(
        breakdownValues.segment.total.spent,
        segmentValues.spent
      );
      breakdownValues.unallocated.total.allocated = sumAndRound(
        breakdownValues.unallocated.total.allocated,
        unallocatedValues.allocated
      );
      breakdownValues.unallocated.total.spent = sumAndRound(
        breakdownValues.unallocated.total.spent,
        unallocatedValues.spent
      );
      breakdownValues.segment.values[timeframeId] = segmentValues;
      breakdownValues.unallocated.values[timeframeId] = unallocatedValues;
    });

    if (suppressedMode) {
      breakdownValues.unallocated.total.allocated = sumAndRound(
        breakdownValues.segment.total.allocated,
        -total.allocated
      );
      breakdownValues.unallocated.total.spent = sumAndRound(
        breakdownValues.segment.total.spent,
        -total.spent
      );
    }

    record.segment = breakdownValues.segment;
    record.unallocated = breakdownValues.unallocated;

    ManageTableSpendingHelpers.calcBreakdownSpendingValues(
      record,
      timeframeExpensesData,
      filteredTimeframes
    );
  }

  public static isSegmentlessObject(row: ManageTableRow | ManageCegTableRow): boolean {
    if (!row) {
      return false;
    }
    return !row.segmentId && !row.sharedCostRuleId;
  }
}
