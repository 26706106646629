export enum CEGStatus {
  PLANNED = 'Planned',
  COMMITTED = 'Committed'
}

export const CEGStatusLabels = {
  [CEGStatus.PLANNED]: 'Planned',
  [CEGStatus.COMMITTED]: 'Committed'
}

export const CEGStatusValue = {
  [CEGStatus.PLANNED]: 'planned',
  [CEGStatus.COMMITTED]: 'committed'
}
