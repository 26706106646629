import { Injectable } from '@angular/core';
import { TableRowAmountsLoader } from '../../types/manage-ceg-table-row-data.types';
import { ManageCegTableRow } from '../../types/manage-ceg-page.types';
import { Observable, of } from 'rxjs';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Injectable()
export class GoalPerformanceAmountsLoader implements TableRowAmountsLoader {
  readonly rowType = ManageTableRowType.Goal;

  fillRowAmounts(budgetId: number, rows: ManageCegTableRow[], params: object): Observable<void> {
    return of(null); // For goal rows in performance mode we show empty amount cells
  }
}
