import { DetailsObjectStateMapper } from '../../types/budget-object-state-mapper.interface';
import { Injectable } from '@angular/core';
import { MetricMappingDetailsState, MetricMappingThirdPartyAmounts } from '../../types/metric-mapping-details-state.interface';
import { MetricMappingDO } from 'app/shared/services/backend/metric.service';
import { MetricMappingCalculationDO } from 'app/shared/services/backend/metric-calculation.service';
import { MetricValueRecords } from '../../types/metric-value-records.interface';
import { MetricMilestones } from '../../types/metric-milestone.interface';
import { createDateString, parseDateString } from '../../components/containers/campaign-details/date-operations';
import { MetricsUtilsService } from '../metrics-utils.service';
import { MetricValueUpdatesData } from '../../types/metric-value-update-item.interface';
import { getNumericValue } from '@shared/utils/common.utils';

export interface MetricRecords {
  value: MetricValueRecords<number>;
  CPO: MetricValueRecords<number>;
  ROI: MetricValueRecords<number>;
}

@Injectable({
  providedIn: 'root'
})
export class MetricDetailsStateMapper implements DetailsObjectStateMapper {
  public static convertMetricDOMilestones(milestonesDO: object): MetricMilestones {
    if (!milestonesDO) {
      return null;
    }
    const dates = Object.keys(milestonesDO);
    return (dates || []).map(date => ({ date: parseDateString(date), targetValue: milestonesDO[date] }));
  }

  public static convertMetricCalculations(metricMappingCalculation: MetricMappingCalculationDO[]): MetricRecords {
    const records = {
      ROI: [] as MetricValueRecords<number>,
      CPO: [] as MetricValueRecords<number>,
      value: [] as MetricValueRecords<number>
    };

    return (metricMappingCalculation || [])
      .sort((calcA, calcB) => {
        if (!calcA.date || !calcB.date) {
          return 0;
        }
        const dateA = parseDateString(calcA.date);
        const dateB = parseDateString(calcB.date);

        return dateA.getTime() - dateB.getTime();
      })
      .reduce(
        (currentState, calc) => {
          const timestamp = parseDateString(calc.date);
          currentState.CPO.push(
            { timestamp, value: calc.cpo_value || 0 }
          );
          currentState.ROI.push(
            { timestamp, value: calc.roi_value || 0 }
          );
          currentState.value.push(
            { timestamp, value: calc.total_value || calc.metric_value || 0 }
          );
          return currentState;
        },
        records
      );
  }

  private static convertThirdPartyAmounts(dataObject: Partial<MetricMappingDO>): MetricMappingThirdPartyAmounts {
    return {
      total: dataObject.third_party_total_amount || 0,
      amounts: dataObject.third_party_amounts && Object.entries(dataObject.third_party_amounts).map(
        ([key, value]) => ({
          integrationName: key,
          amount: value
        })
      ) || []
    };
  }

  public static getCurrentValue(metricMapping: Partial<MetricMappingDO>, includeDependent: boolean): number {
    if (!metricMapping.lastCalculationData) {
      return getNumericValue(metricMapping.actual_amount);
    }
    return includeDependent ? metricMapping.lastCalculationData.total_value : metricMapping.lastCalculationData.metric_value;
  }

  constructor(private readonly metricsUtilsService: MetricsUtilsService) { }

  stateToDataObject(state: Partial<MetricMappingDetailsState>): Partial<MetricMappingDO> {
    const dataObject: Partial<MetricMappingDO> = {};

    if (state.metricId) {
      dataObject.metric_master = state.metricId;
    }
    if (state.hasOwnProperty('startDate')) {
      dataObject.start_date = state.startDate;
    }
    if (state.milestones) {
      dataObject.milestones = (state.milestones || []).reduce((result, milestone) => {
        result[createDateString(milestone.date)] = milestone.targetValue;
        return result;
      }, {});
      dataObject.projection_amount = this.metricsUtilsService.getTargetValue(state.milestones);
    }
    if (state.hasOwnProperty('notes')) {
      dataObject.notes = state.notes;
    }
    if (state.parentId) {
      dataObject.map_id = state.parentId;
    }
    if (state.mappingType) {
      dataObject.mapping_type = state.mappingType;
    }

    if (state.metricCalculations) {
      dataObject.metric_calculations = state.metricCalculations;
    }

    return dataObject;
  }

  dataObjectToState(dataObject: Partial<MetricMappingDO>, includeDependentMetricValues = true): Partial<MetricMappingDetailsState> {
    const state = {
      objectId: dataObject.id || null,
      parentId: dataObject.map_id,
      notes: dataObject.notes || '',
      metricId: dataObject.metric_master,
      mappingType: dataObject.mapping_type,
      startDate: dataObject.start_date || null,
      updated: dataObject.upd || null,
      created: dataObject.crd || null,
      milestones: MetricDetailsStateMapper.convertMetricDOMilestones(dataObject.milestones),
      thirdPartyAmounts: MetricDetailsStateMapper.convertThirdPartyAmounts(dataObject),
      currentValue: MetricDetailsStateMapper.getCurrentValue(dataObject, includeDependentMetricValues),
      targetValue: dataObject.projection_amount,
      ROIRecords: [] as MetricValueRecords<number>,
      CPORecords: [] as MetricValueRecords<number>,
      metricValueRecords: [] as MetricValueRecords<number>,
      metricCalculations: (dataObject.metric_calculations || []).sort(
        (c1, c2) => new Date(c1.date).getTime() - new Date(c2.date).getTime()
      ),
      metricValueUpdates: [] as MetricValueUpdatesData,
      isInherited: dataObject.is_inherited
    };

    const metricRecords = MetricDetailsStateMapper.convertMetricCalculations(state.metricCalculations);
    state.metricValueRecords = metricRecords.value;
    state.CPORecords = metricRecords.CPO;
    state.ROIRecords = metricRecords.ROI;

    return state;
  }
}
