/**
 * A list of defined object types (campaigns, expenses) that represent objects created by external integrations
 *
 * Might be extended later
 */
export const ExternalIntegrationObjectTypes = {
  GoogleAds: 'Google Ads',
  LinkedinAds: 'LinkedIn Ads',
  FacebookAds: 'Facebook Ads',
};

export const ExternalIntegrationExpenseSource = {
  GoogleAds: 'google_ads',
  LinkedinAds: 'linkedin_ads',
  FacebookAds: 'facebook_ads',
};
