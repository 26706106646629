import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MetricMappingDetailsComponent } from '../../../types/metric-mapping-details-component.interface';
import { MetricMappingDetailsState, MetricMappingThirdPartyAmount } from '../../../types/metric-mapping-details-state.interface';
import { DetailsCreationContext, MetricMappingCreationContext } from '../../../types/details-creation-context.interface';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { BudgetObjectDetailsManager } from '../../../services/budget-object-details-manager.service';
import { BudgetObjectActionsBuilder } from '../../../services/budget-object-actions-builder.service';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { UserDataService } from 'app/shared/services/user-data.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { ObjectHierarchy } from '../../object-hierarchy-nav/object-hierarchy-nav.type';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { messages, objectPlaceholderName } from '../../../messages';
import { MetricMappingDetailsService } from '../../../services/metric-mapping-details.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { faPlug, faRocketLaunch } from '@fortawesome/pro-duotone-svg-icons';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { MetricMappingDO, MetricProgressTowardsTargetDO } from 'app/shared/services/backend/metric.service';
import { MetricBreakdown, MetricBreakdownSectionItem } from '../../../types/metric-breakdown-data.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { DetailsAction } from '../../details-header/details-header.type';
import { MetricMilestones } from '../../../types/metric-milestone.interface';
import { Goal } from 'app/shared/types/goal.interface';
import { ObjectHierarchyService } from '../../../services/object-hierarchy.service';
import { MetricsUtilsService } from '../../../services/metrics-utils.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { keyMetricTooltip } from '../../metrics/constants';
import { MetricMilestonesListComponent } from '../../metric-milestones-list/metric-milestones-list.component';
import { DataValidationService } from 'app/budget-object-details/services/data-validation.service';
import { createDateString, parseDateString } from '../campaign-details/date-operations';
import { dialogConfig } from 'app/metric-integrations/components/metric-mapping-dialog/dialog-window.config';
import { SalesforceMappingDialogComponent } from 'app/metric-integrations/salesforce/salesforce-mapping-dialog/salesforce-mapping-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CompanyDO } from 'app/shared/types/company.interface';
import { SalesforceDataService } from 'app/metric-integrations/salesforce/salesforce-data.service';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { MetricGraphData } from '../../../types/metric-graph-data.interface';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { MetricMappingCalculationService } from '../../../services/metric-mapping-calculation.service';
import { MetricValueUpdateItem, MetricValueUpdateType } from '../../../types/metric-value-update-item.interface';
import { MetricIntegrationDisplayName, MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { MetricMappingDialogData } from 'app/metric-integrations/components/metric-mapping-dialog/dialog-data.type';
import { ComponentType } from '@angular/cdk/portal';
import { HubspotMappingDialogComponent } from 'app/metric-integrations/hubspot/hubspot-mapping-dialog/hubspot-mapping-dialog.component';
import { HubspotCampaignMapping } from 'app/metric-integrations/hubspot/hubspot-campaign-mapping.interface';
import { HubspotDataService } from 'app/metric-integrations/hubspot/hubspot-data.service';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { MetricCpoChartComponent } from '../../metrics/metric-cpo-chart/metric-cpo-chart.component';
import { MetricRoiChartComponent } from '../../metrics/metric-roi-chart/metric-roi-chart.component';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { BusinessValueService } from '../../business-value/business-value.service';
import { CampaignDO, LightCampaign } from 'app/shared/types/campaign.interface';
import { SalesforceCampaignMapping } from 'app/metric-integrations/salesforce/salesforce-campaign-mapping.interface';
import { Integration } from 'app/metric-integrations/types/metrics-provider-data-service.types';
import { MetricsProviderDataService } from 'app/metric-integrations/services/metrics-provider-data.service';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { SelectItem } from 'app/shared/types/select-groups.interface';
import { getMetricSelectItems } from '../../details-metrics/metric-masters-list/metric-masters-list.component';
import { UserDO } from '@shared/types/user-do.interface';
import { getTodayFixedDate } from '@shared/utils/budget.utils';
import { getMonthKey } from 'app/budget-object-details/components/metrics/metric-value-editor/metric-value-editor.utils';
import { MetricUpdateService } from 'app/budget-object-details/services/metric-update.service';
import { MetricUpdateAction } from 'app/budget-object-details/types/metric-update-action.enum';

const UPDATE_SUMMARY_ERROR_MSG = 'Failed to update campaign metric summary';

@Component({
  selector: 'campaign-metric-details',
  templateUrl: './campaign-metric-details.component.html',
  styleUrls: ['../details-container.scss', './campaign-metric-details.component.scss']
})
export class CampaignMetricDetailsComponent implements MetricMappingDetailsComponent, OnInit, OnDestroy {
  public readonly mappingType = this.configuration.OBJECT_TYPES.campaign;
  public currentState: MetricMappingDetailsState;
  private prevState: MetricMappingDetailsState = null;
  public isReadOnlyMode = true;

  private readonly destroy$ = new Subject<void>();
  private readonly reset$ = new Subject<void>();
  private isLoading = false;

  public editPermission = false;
  public unsavedChangesFlag = false;
  public currentUser: UserDO;

  public companyId: number;
  public budget: Budget;
  public currentKeyMetricName: string;
  public menuActions: DetailsAction[] = [];

  public readonly objectType = this.configuration.OBJECT_TYPES.metric;
  public readonly parentObjectType = this.configuration.OBJECT_TYPES.campaign;
  public metricProgressTowardsTarget: MetricProgressTowardsTargetDO;

  public company: CompanyDO;
  public metrics: MetricType[] = [];
  public masterMetricsSelectItems: SelectItem[] = [];

  public goals: Goal[] = [];
  public campaigns: LightCampaign[] = [];
  public campaign: CampaignDO;
  public campaignHasChildren: boolean;
  private products: ProductDO[];
  private childCampaigns: CampaignDO[] = [];
  private campaignMetricMappings: MetricMappingDO[] = [];
  private segments: BudgetSegmentAccess[] = [];
  private sharedCostRules: SharedCostRule[] = [];

  private mappedThirdPartyCampaignNumbers = {
    [MetricIntegrationName.Salesforce]: 0,
    [MetricIntegrationName.Hubspot]: 0
  };

  public hierarchy: ObjectHierarchy = {
    Goal: null,
    Program: null,
    Campaign: null,
    Expense: null,
    Metric: null
  };

  private metricBreakdownDataTemplate: MetricBreakdown = {
    currentMetric: {
      title: 'Campaign',
      data: null,
      icon: faRocketLaunch
    },
    integrationsMetric: {
      title: 'Integrations',
      data: [],
      totalRow: null,
      icon: faPlug
    },
    childCampaignMetrics: {
      title: 'Child Campaigns',
      icon: faRocketLaunch,
      data: [],
      totalRow: null
    },
    grandTotal: null
  };

  public graphData: MetricGraphData;

  public metricBreakdownData: MetricBreakdown = this.metricBreakdownDataTemplate;
  public hasActiveThirdPartyAmounts = false;
  public sfIntegrations: Integration[];
  public hubspotIntegrations: Integration[];
  public googleAdsIntegrations: Integration[];
  public budgetCurrencySymbol = '';
  protected budgetTodayDate: Date;
  protected todayDateString: string;
  protected currentMonthKey: string;

  public displayDecimal = false;
  public metricValueLastUpdated = '';
  public lastNegativeMetricValueDate = '';
  public actualBusinessValue: number = null;
  public targetBusinessValue: number = null;

  formData: UntypedFormGroup;
  keyMetricTooltip = keyMetricTooltip;
  keyMetricTooltipDisabled = messages.KEY_METRIC_TOOLTIP_DISABLED;
  currentMetricName: string;

  private readonly backUrl: string;
  private readonly metricUpdateService = inject(MetricUpdateService);


  @ViewChild('milestonesControl') milestonesControl: MetricMilestonesListComponent;
  @ViewChild('cpoChartComponent') cpoChart: MetricCpoChartComponent;
  @ViewChild('roiChartComponent') roiChart: MetricRoiChartComponent;

  private integrationRowIconTemplateGetters: {[integrationName: string]: Partial<MetricBreakdownSectionItem>} = {
    [MetricIntegrationName.Salesforce]: { customIcon: 'salesforce' },
    [MetricIntegrationName.Hubspot]: { faIcon: faHubspot, faDuotone: false }
  };

  private static getStartDateStr (formStartDate: Date | string) {
    return (typeof formStartDate === 'object' ? createDateString(formStartDate) : formStartDate) || null;
  }

  private static getSalesforceMappedCampaignsCount(salesforceMetrics: SalesforceCampaignMapping): number {
    return salesforceMetrics?.mappings?.length || 0;
  }

  private static getHubspotMappedCampaignsCount(hubspotMetrics: HubspotCampaignMapping): number {
    const formsCount = hubspotMetrics?.forms?.length || 0;
    const emailCampaignsCount = hubspotMetrics?.emails?.length  || 0;
    const utmCampaignsCount = hubspotMetrics?.utmCampaigns?.length  || 0;

    return formsCount + emailCampaignsCount + utmCampaignsCount;
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly configuration: Configuration,
    private readonly utilityService: UtilityService,
    public readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly userDataService: UserDataService,
    public readonly appRoutingService: AppRoutingService,
    private readonly metricMappingDetailsService: MetricMappingDetailsService,
    private readonly companyDataService: CompanyDataService,
    private readonly userManager: UserManager,
    private readonly menuActionsBuilder: BudgetObjectActionsBuilder,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly hierarchyService: ObjectHierarchyService,
    private readonly metricsUtilsService: MetricsUtilsService,
    private readonly fb: UntypedFormBuilder,
    private readonly dataValidation: DataValidationService,
    private readonly salesforceDataService: SalesforceDataService,
    private readonly hubspotDataService: HubspotDataService,
    public dialog: MatDialog,
    private readonly metricMappingCalculationService: MetricMappingCalculationService,
    private readonly campaignService: CampaignService
  ) {
    this._createForm();

    this.backUrl = this.router.getCurrentNavigation().extras?.state?.data?.backUrl;

    this.companyDataService.selectedCompanyDO$
      .pipe(takeUntil(this.destroy$))
      .subscribe(company => {
        this.company = company;
        this.budgetCurrencySymbol = this.company?.currency_symbol;
      });

    this.companyDataService.metricIntegrations$
      .pipe(takeUntil(this.destroy$))
      .subscribe(allIntegrations => {
        this.sfIntegrations = allIntegrations[MetricIntegrationName.Salesforce] || [];
        this.hubspotIntegrations = allIntegrations[MetricIntegrationName.Hubspot] || [];
        this.googleAdsIntegrations = allIntegrations[MetricIntegrationName.GoogleAds] || [];
      });
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => this.init(params));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasUnsavedChanges(): boolean {
    if (!this.currentState) {
      return false;
    }
    this.saveFormData();
    return this.metricMappingDetailsService.hasChanges(this.prevState, this.currentState);
  }

  saveChanges(onSavedCb?: Function): void {
    this.showLoader();

    this.metricMappingDetailsService.saveDetails(this.prevState, this.currentState).pipe(
      switchMap(createdMetricMapping =>
        this.metricMappingDetailsService.saveMetricCalculations(
          this.prevState ? this.prevState.metricCalculations : createdMetricMapping.metric_calculations,
          this.currentState?.metricCalculations
        )
      ),
      switchMap(() =>
        this.metricMappingDetailsService.getMetricProgressTowardsTarget(this.currentState.objectId)
          .pipe(tap(updates => this.metricProgressTowardsTarget = updates))
      ),
      switchMap(() => {
          const keyMetricId = this.currentState.isKeyMetric ? this.currentState.objectId : null;
          return this.currentState.isKeyMetric === Boolean(this.prevState?.isKeyMetric) ?
            of(true) :
            this.campaignService.updateCampaign(this.campaign.id, { key_metric: keyMetricId })
              .pipe(
                tap(() => this.campaign.key_metric = keyMetricId),
              );
        }
      )
    ).subscribe(
      () => {
        this.onSavedSuccessfully();
        onSavedCb?.();
      },
      error => this.onError(
        error,
        messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric')
      )
    );
  }

  getContextForChildObjectCreation(): DetailsCreationContext {
    return null;
  }

  private getHierarchyParentType(parentCampaign: CampaignDO) {
    const { OBJECT_TYPES } = this.configuration;

    return parentCampaign.parent_campaign ? OBJECT_TYPES.childCampaign : OBJECT_TYPES.campaign;
  }

  private buildHierarchy(state: MetricMappingDetailsState) {
    const targetObject = {
      id: this.campaign.id,
      name: this.campaign.name,
      goalId: this.campaign.goal,
      parentCampaign: this.campaign.parent_campaign,
      mode: this.campaign?.mode
    };
    const parentType = this.getHierarchyParentType(this.campaign);
    this.hierarchy = this.hierarchyService.buildMetricHierarchy(
      state.objectId,
      MetricMappingDetailsService.getFullMetricName(state),
      targetObject,
      parentType,
      { goals: this.goals, campaigns: this.campaigns }
    );
  }

  init(routeParams: Params) {
    this.reset$.next();

    this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot) ?
      this.initCampaignMetricCreation() :
      this.initCampaignMetricLoading(routeParams);

    this.userDataService.editPermission$
      .pipe(takeUntil(merge(this.destroy$, this.reset$)))
      .subscribe((editPermission: boolean) => {
        this.editPermission = editPermission;
        this.updateReadOnlyModeState();
      });
  }

  private initCampaignMetricLoading(routeParams: Params) {
    const metricMappingIdParam = routeParams?.[AppRoutingService.DETAILS_OBJECT_ID_PARAM_NAME];
    const metricMappingId = metricMappingIdParam && Number.parseInt(metricMappingIdParam, 10);
    if (metricMappingId) {
      this.loadCampaignMetric(metricMappingId);
    } else {
      this.onError(
        '[Campaign Metric Details]: init(): incorrect metric mapping id provided: ' + metricMappingId,
        messages.UNABLE_TO_LOAD_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric'),
        true
      )
    }
  }

  private initCampaignMetricCreation(reset: boolean = false) {
    this.showLoader();
    const contextData = AppRoutingService.getHistoryStateProperty<MetricMappingCreationContext>('data');
    this.metricMappingDetailsService.checkMetricCreationPossible$(contextData, this.mappingType).pipe(
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      () => this.prepareDataForCampaignMetricCreation(reset, contextData),
      error => this.onError(error.error, error.message, true)
    )
  }

  private prepareDataForCampaignMetricCreation(reset: boolean, contextData: MetricMappingCreationContext) {
    const budgetData$: Observable<[number, Budget]> = reset ? of([this.companyId, this.budget]) : this.loadInitialBudgetData();

    budgetData$.pipe(
      switchMap(([companyId]) =>
        combineLatest([
          this.metricMappingDetailsService.initDetails(contextData, { mapping_type: this.mappingType }).pipe(
            switchMap(state => this.loadChildObjectsAndMetricMappings$(state))
          ),
          this.loadDetailsContextData(companyId)
        ])
      ),
      switchMap(([metricMappingDetailsState]) => this.applyMetricType$(metricMappingDetailsState)),
      map(metricMappingDetailsState => {
        this.buildHierarchy(metricMappingDetailsState);
        return metricMappingDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => this.onDataForMetricCreationPrepared(state),
      error => this.onError(
        error,
        messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric'),
        true
      )
    );
  }

  private onDataForMetricCreationPrepared(state) {
    state.milestones = this.metricMappingDetailsService.initMetricMilestones(this.budget?.budget_to, this.campaign?.end_date);
    state.startDate = this.metricMappingDetailsService.getDefaultStartDate(this.budget?.budget_from, this.campaign?.start_date);
    this.onCampaignMetricLoaded(state, false);
  }

  private loadChildObjectsAndMetricMappings$(metricMappingState: MetricMappingDetailsState): Observable<MetricMappingDetailsState> {
    return this.loadCampaignAndChildCampaigns$(metricMappingState.parentId).pipe(
      map(([campaign, childCampaigns]) => [campaign, childCampaigns] as [CampaignDO, CampaignDO[]]),
      switchMap(([campaign, childCampaigns]) =>
        forkJoin([
          this.metricMappingDetailsService.getMetricMappings(
            this.companyId,
            { map_id: campaign.id, mapping_type: this.mappingType }
          ).pipe(
            tap(mappings => this.campaignMetricMappings = mappings)
          ),
          this.loadChildMetricMappings$(metricMappingState, childCampaigns),
          this.budgetObjectDetailsManager.getProducts$()
            .pipe(
              take(1),
              tap(products => this.products = products)
            ),
        ]).pipe(
          map(() => {
            metricMappingState.isKeyMetric = !!campaign.key_metric && campaign.key_metric === metricMappingState.objectId;
            return metricMappingState;
          })
        )
      )
    )
  }

  private loadCampaignMetric(metricMappingId: number) {
    this.showLoader();
    this.loadInitialBudgetData().pipe(
      switchMap(([companyId, budget]) =>
        combineLatest([
          this.metricMappingDetailsService.loadDetails(companyId, budget.id, metricMappingId, { includeDependentMetricValues: false }).pipe(
            switchMap(state => this.loadChildObjectsAndMetricMappings$(state)),
            switchMap(metricMappingDetailsState => this.setMetricIntegrationData(metricMappingDetailsState))
          ),
          this.metricMappingDetailsService.getMetricProgressTowardsTarget(metricMappingId).pipe(
            tap(progressData => this.metricProgressTowardsTarget = progressData )
          ),
          this.loadDetailsContextData(companyId)
        ])
      ),
      switchMap(([metricMappingDetailsState]) => this.applyMetricType$(metricMappingDetailsState)),
      switchMap((metricMappingDetailsState) => this.applyParentObjectMetric$(metricMappingDetailsState)),
      map(metricMappingDetailsState => {
        this.buildHierarchy(metricMappingDetailsState);
        return metricMappingDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => this.onDataForMetricLoadingPrepared(state),
      error => this.onError(
        error,
        messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric'),
        true
      )
    );
  }

  private onDataForMetricLoadingPrepared(state: MetricMappingDetailsState) {
    this.currentState = state;
    this.addMissingData();
    if (!this.campaign.parent_campaign) {
      this.campaignHasChildren = this.campaigns.some(campaign => campaign.parentCampaign === this.campaign.id);
    }
    const alreadyMappedIds = this.campaignMetricMappings.map(mapping => mapping.metric_detail.id);
    const availableMetrics = this.metrics
      .filter(metric => metric.id === this.currentState.metricId || !alreadyMappedIds.includes(metric.id));
    this.masterMetricsSelectItems = getMetricSelectItems(this.products, availableMetrics);

    this.onCampaignMetricLoaded(state);
    this.budgetObjectDetailsManager.logObjectView(
      state.objectId,
      this.companyId,
      this.budget.id,
      this.currentUser.id,
      HistoryObjectLogTypeNames.metricMapping,
      this.metricMappingDetailsService
    );
  }

  selectMetric(metricId: number) {
    this.currentState.metricId = metricId;
    const prevRevenuePerOutcome = this.currentState.revenuePerOutcome;
    const prevRevenueToProfit = this.currentState.revenueToProfit;

    this.applyMetricType$(this.currentState).subscribe(
      () => {
        if (prevRevenuePerOutcome !== this.currentState.revenuePerOutcome || prevRevenueToProfit !== this.currentState.revenueToProfit) {
          this.actualBusinessValue = this.calcBusinessValue(this.currentState.summary.totalValue);
          this.targetBusinessValue = this.calcBusinessValue(this.currentState.summary.targetValue);

          forkJoin([
            this.metricMappingDetailsService.updateTargetValue$(this.currentState, this.getTargetCost()),
            this.metricMappingDetailsService.updateROIForMetricCalculations$(this.currentState)
          ]).subscribe(() => this.setGraphDataRecords());
        }
      }
    );

    this.syncUnsavedChangesFlag();
  }

  private setMetricIntegrationData(metricMappingDetailsState: MetricMappingDetailsState): Observable<MetricMappingDetailsState> {
    const salesforceCampaignMapping$ = this.sfIntegrations[0] ?
      this.salesforceDataService.getCampaignMapping(
        this.companyId,
        this.budget.id,
        metricMappingDetailsState.parentId,
        this.sfIntegrations[0].integrationId
      ) : of(null);

    const hubspotCampaignMapping$ = this.hubspotIntegrations[0] ?
      this.hubspotDataService.getCampaignMapping(
        this.companyId,
        this.budget.id,
        metricMappingDetailsState.parentId,
        this.hubspotIntegrations[0].integrationId
      ) : of(null);

    return combineLatest([
      salesforceCampaignMapping$.pipe(
        tap(salesforceCampaignMapping => this.setSalesforceMappedCampaignsNumber(salesforceCampaignMapping))
      ),
      hubspotCampaignMapping$.pipe(
        tap(hubspotCampaignMapping => this.setHubspotMappedCampaignsNumber(hubspotCampaignMapping))
      )
    ]).pipe(map(() => metricMappingDetailsState))
  }

  private setSalesforceMappedCampaignsNumber(salesforceMetrics: SalesforceCampaignMapping): void {
    if (salesforceMetrics) {
      this.mappedThirdPartyCampaignNumbers[MetricIntegrationName.Salesforce] =
        CampaignMetricDetailsComponent.getSalesforceMappedCampaignsCount(salesforceMetrics);
    }
  }

  private setHubspotMappedCampaignsNumber(hubspotMetrics: HubspotCampaignMapping): void {
    if (hubspotMetrics) {
      this.mappedThirdPartyCampaignNumbers[MetricIntegrationName.Hubspot] =
        CampaignMetricDetailsComponent.getHubspotMappedCampaignsCount(hubspotMetrics);
    }
  }

  private onError(error, message, close = false) {
    this.hideLoader();
    this.budgetObjectDetailsManager.handleError(error, message, close);
  }

  private loadDetailsContextData(companyId: number) {
    this.companyDataService.loadMetrics(
      companyId,
      error => this.onError(error, messages.UNABLE_TO_LOAD_METRICS_ERROR_MSG, true)
    );

    return combineLatest([
      this.companyDataService.selectedCompanyDO$,
      this.budgetObjectDetailsManager.getMetricTypes().pipe(tap(mTypes => this.metrics = mTypes)),
      this.budgetObjectDetailsManager.getGoals().pipe(tap(goals => this.goals = goals)),
      this.budgetObjectDetailsManager.getLightCampaigns().pipe(tap(campaigns => this.campaigns = campaigns)),
      this.budgetObjectDetailsManager.getSegments().pipe(tap(segments => this.segments = segments)),
      this.budgetObjectDetailsManager.getSharedCostRules().pipe(tap(rules => this.sharedCostRules = rules)),
      this.userManager.currentUser$.pipe(
        filter(user => !!user),
        tap(user => this.currentUser = user)
      )
    ]);
  }

  private loadInitialBudgetData() {
    return combineLatest([
      this.budgetObjectDetailsManager.getCompanyId().pipe(tap(companyId => this.companyId = companyId)),
      this.budgetObjectDetailsManager.getCurrentBudget().pipe(tap(budget => {
        this.budget = budget;
        this.budgetTodayDate = getTodayFixedDate(budget);
        this.todayDateString = budget.fixed_date ? budget.fixed_date : createDateString(new Date());
        this.currentMonthKey = getMonthKey(this.todayDateString);
      }))
    ]);
  }

  private showLoader() {
    this.isLoading = true;
    this.utilityService.showLoading(true);
  }

  private hideLoader() {
    // A workaround needed for e2e tests driver to catch loader's disappearing
    setTimeout(() => {
      this.isLoading = false;
      this.utilityService.showLoading(false);
    });
  }

  private updateReadOnlyModeState() {
    this.isReadOnlyMode = !this.editPermission;
    this.updateMenuActions();
  }

  private syncUnsavedChangesFlag(flag?: boolean) {
    this.unsavedChangesFlag = typeof flag === 'boolean' ? flag : this.hasUnsavedChanges();
  }

  private onCampaignMetricLoaded(state: MetricMappingDetailsState, syncState = true) {
    this.currentState = state;
    if (syncState) {
      this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(state);
    }

    this.refreshMetricValueUpdates();
    this.buildMetricBreakDownData();
    this.setFormData();
    this.updateMenuActions();
    this.setMetricValueLastUpdated();
    this.setLastNegativeMetricValueDate();
    this.setCurrentKeyMetricName();
    this.metricMappingCalculationService.updateMetricStateRecords(this.currentState);
    this.updateCurrentSummary();
    this.updateSummary(isError => {
      if (!isError) {
        this.actualBusinessValue = this.calcBusinessValue(this.currentState.summary.totalValue);
        this.initGraphData();
        this.hideLoader();
      }
    });
    this.formData.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.syncUnsavedChangesFlag());
  }

  private setMetricValueLastUpdated() {
    this.metricValueLastUpdated =
      this.metricMappingDetailsService.getLastUpdatedDateInfo(this.currentState.metricCalculations);
  }

  private setLastNegativeMetricValueDate() {
    this.lastNegativeMetricValueDate =
      this.metricMappingDetailsService.getLastNegativeMetricValueDate(this.currentState.metricCalculations);
  }

  private refreshMetricValueUpdates(): void {
    this.currentState.metricValueUpdates =
      this.metricMappingCalculationService.buildMetricValueUpdatesData(
        { objId: this.campaign.id, type: this.mappingType },
        this.currentState.metricCalculations,
        this.currentState.childMetricMappings,
        this.isReadOnlyMode
      );
  }

  private initGraphData() {
    this.graphData = {
      startDate: this.currentState.startDate,
      milestones: this.currentState.milestones
    };
    this.setGraphDataRecords();
  }

  private setGraphDataRecords() {
    this.graphData.CPORecords = this.currentState.CPORecords;
    this.graphData.ROIRecords = this.currentState.ROIRecords;
    this.graphData.metricValueRecords = this.currentState.metricValueRecords;
    this.graphData.targetROI = this.currentState.summary.targetROI;
    this.graphData.targetCPO = this.currentState.summary.targetCPO;
  }

  public submitChanges(submitCallback) {
    const isFormValid = this.validateChanges();
    if (isFormValid && submitCallback) {
      submitCallback();
    }
  }

  public handleCancelAction() {
    this.appRoutingService.closeDetailsPage();
  }

  public handleSaveAction() {
    if (this.hasUnsavedChanges()) {
      this.saveChanges();
    }
  }

  public handleSaveAndNewAction() {
    const onSaved = this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot)
      ? this.resetDetails.bind(this)
      : () => this.appRoutingService.openCampaignMetricCreation(this.getContextForNewMappingCreation());

    if (this.hasUnsavedChanges()) {
      this.saveChanges(onSaved);
      return;
    }

    onSaved();
  }

  private getContextForNewMappingCreation(): DetailsCreationContext {
    return {
      parent: {
        id: this.currentState.parentId,
        type: this.mappingType
      }
    }
  }

  public handleSaveAndCloseAction() {
    if (this.hasUnsavedChanges()) {
      const onSaved = this.appRoutingService.closeDetailsPage.bind(this.appRoutingService);
      this.saveChanges(onSaved);
      return;
    }
    this.appRoutingService.closeDetailsPage();
  }

  public handleDelete() {
    if (!(this.currentState && this.currentState.objectId)) {
      return;
    }

    this.dialogManager.openDeleteEntityDialog(() => {
      this.metricMappingDetailsService.deleteObject(this.currentState.objectId).subscribe(
        () => {
          this.onSuccess(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, this.mappingType + ' metric'), true);
          this.metricMappingDetailsService.reportChange(this);
          this.metricUpdateService.triggerMetricUpdate({
            action: MetricUpdateAction.DELETE,
            objectId: this.campaign.id,
            objectType: this.mappingType,
            metricMappingId: this.currentState.objectId,
            metricId: this.currentState.metricId,
            productId: this.metricMappingDetailsService.getProductByName(this.currentState.productName, this.products)?.id
          });
        },
        error => this.onError(
          error,
          messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric'),
          true
        )
      );
    }, this.objectType.toLowerCase());
  }

  private resetDetails() {
    this.reset$.next();
    this.prevState = null;
    this.currentState = null;
    this.initCampaignMetricCreation(true);
  }

  private buildMetricBreakDownData() {
    this.metricBreakdownData = createDeepCopy(this.metricBreakdownDataTemplate);
    const targetValue = this.metricsUtilsService.getTargetValue(this.currentState.milestones) || this.currentState.targetValue;

    this.metricBreakdownData.currentMetric.data = {
      objectId: this.currentState.parentId,
      mappingId: this.currentState.objectId,
      name: this.campaign && this.campaign.name,
      lastUpdated: MetricMappingDetailsService.getLastUpdatedDate(
        this.currentState.metricValueUpdates,
        MetricValueUpdateType.campaign,
        this.currentState.parentId
      ),
      current: this.currentState.currentValue,
      target: targetValue
    };

    this.metricBreakdownData.grandTotal = {
      name: 'Grand Total',
      current: this.currentState.currentValue,
      target: targetValue
    };

    const activeThirdPartyAmounts = this.currentState.thirdPartyAmounts?.amounts?.filter(a => !!a.amount) || [];

    this.metricBreakdownData =
      activeThirdPartyAmounts.reduce(
        (metricBreakdownData, thirdPartyAmount) => this.addThirdPartyAmountToBreakdown(metricBreakdownData, thirdPartyAmount),
        this.metricBreakdownData
      );

    this.metricBreakdownData =
      (this.currentState.childMetricMappings || []).reduce(
        (metricBreakdownData, childMetricMapping) => this.addChildCampaignMetricToBreakdown(metricBreakdownData, childMetricMapping),
        this.metricBreakdownData
      );

    this.hasActiveThirdPartyAmounts = activeThirdPartyAmounts.length > 0;
  }

  private addChildCampaignMetricToBreakdown(metricBreakdownData: MetricBreakdown, childMetricMapping: MetricMappingDO) {
    const campaign = this.childCampaigns.find(c => c.id === childMetricMapping.map_id);
    if (campaign) {
      this.metricMappingDetailsService.addMetricToBreakdownSection(
        campaign,
        metricBreakdownData,
        metricBreakdownData.childCampaignMetrics,
        childMetricMapping,
        this.currentState.metricName,
        false
      );
    }
    return metricBreakdownData;
  }

  private addThirdPartyAmountToBreakdown(
    metricBreakdownData: MetricBreakdown,
    thirdPartyMetricAmount: MetricMappingThirdPartyAmount,
    ) {
    this.metricMappingDetailsService.addThirdPartyAmountToBreakdownSection(
      thirdPartyMetricAmount,
      metricBreakdownData,
      metricBreakdownData.integrationsMetric,
      this.currentState.metricValueUpdates,
      this.mappedThirdPartyCampaignNumbers,
      this.integrationRowIconTemplateGetters
    );
    return metricBreakdownData;
  }

  private loadCampaignAndChildCampaigns$(campaignId: number): Observable<[CampaignDO, CampaignDO[]]> {
    const childCampaigns$ =
      this.metricMappingDetailsService.loadChildCampaigns([campaignId], this.budget.id).pipe(
        tap(campaigns => this.childCampaigns = campaigns)
      );

    const campaign$ =
      this.metricMappingDetailsService.loadCampaign(campaignId).pipe(
        tap(campaign => this.campaign = campaign),
        tap(campaign => {
          this.budgetObjectDetailsManager.checkObjectAccess({
            segmentDO: {
              split_rule: campaign.split_rule,
              company_budget_segment1: campaign.company_budget_segment1
            },
            segments: this.segments,
            rules: this.sharedCostRules,
            onDenyCb: () => {
              this.destroy$.next();
              this.destroy$.complete();
            },
            objectTypeLabel: this.objectType.toLowerCase()
          });
        }),
      );

    return forkJoin([campaign$, childCampaigns$]);
  }

  private loadChildMetricMappings$(metricMappingState: MetricMappingDetailsState, childCampaigns: CampaignDO[]) {
    if (!metricMappingState.metricId) {
      return of([]);
    }
    return this.metricMappingDetailsService.loadMetricMappings(
      metricMappingState.metricId,
      childCampaigns.map(campaign => campaign.id),
      this.companyId,
      this.configuration.OBJECT_TYPES.campaign
    ).pipe(
      map(campaignsMetrics => {
        metricMappingState.childMetricMappings = campaignsMetrics;
        return metricMappingState;
      })
    );
  }

  /* ACTIONS MENU */
  private updateMenuActions() {
    const isDisabled = !(this.currentState && this.currentState.objectId) || this.isReadOnlyMode;
    this.menuActionsBuilder
      .reset()
      .addDeleteAction(this.objectType, this.handleDelete.bind(this), isDisabled);

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  updateMilestones(milestones: MetricMilestones) {
    const prevTargetValue = this.metricsUtilsService.getTargetValue(this.currentState.milestones);
    this.currentState.milestones = milestones;
    const newTargetValue = this.metricsUtilsService.getTargetValue(milestones);
    if (prevTargetValue !== newTargetValue) {
      this.buildMetricBreakDownData();
      this.updateTargetSummary(isError => {
        if (!isError && this.graphData) {
          this.graphData.milestones = this.currentState.milestones;
          this.setGraphDataRecords();
        }
      });
    } else {
      if (this.graphData) {
        this.graphData.milestones = this.currentState.milestones;
      }
    }
    this.syncUnsavedChangesFlag();
  }

  updateMetricBreakdownSection() {
    this.showLoader();
    this.loadChildObjectsAndMetricMappings$(this.currentState).pipe(
      map((state: MetricMappingDetailsState) => {
        this.currentState.childMetricMappings = state.childMetricMappings;
        this.refreshMetricValueUpdates();
        this.buildMetricBreakDownData();
      }),
      switchMap(() =>
        this.metricMappingCalculationService.createOwnCalculations(
          this.currentState,
          this.getCurrentSpent(),
          MetricValueUpdateType.campaign
        )
      )
    ).subscribe(() => {
      this.updateCurrentSummary();
      this.actualBusinessValue = this.calcBusinessValue(this.currentState.summary.totalValue);
      this.setGraphDataRecords();
      this.hideLoader();
    });
  }

  onSavedSuccessfully() {
    if (!this.prevState) {
      this.budgetObjectDetailsManager.logObjectView(
        this.currentState.objectId,
        this.companyId,
        this.budget.id,
        this.currentUser.id,
        HistoryObjectLogTypeNames.metricMapping,
        this.metricMappingDetailsService
      );
      this.updateMenuActions();
      this.updateMetricBreakdownSection();
      this.syncIntegrationsOnMetricCreation();
    }
    this.setCurrentKeyMetricName();
    this.hierarchyService.setHierarchyObjectName(
      this.hierarchy,
      this.configuration.OBJECT_TYPES.metric,
      MetricMappingDetailsService.getFullMetricName(this.currentState)
    );
    const keyMetricId = this.currentState.isKeyMetric ? this.currentState.objectId : null;
    const isKeyMetricUpdated = this.currentState.isKeyMetric !== this.prevState.isKeyMetric;
    this.metricUpdateService.triggerMetricUpdate({
      action: MetricUpdateAction.UPDATE,
      objectId: this.campaign.id,
      objectType: this.mappingType,
      keyMetricId,
      metricMappingId: this.currentState.objectId,
      isKeyMetricUpdated,
      metricId: this.currentState.metricId,
      productId: this.metricMappingDetailsService.getProductByName(this.currentState.productName, this.products)?.id
    });
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    this.onSuccess(messages.SAVE_CHANGES_SUCCESS_MSG);
    this.metricMappingDetailsService.reportChange(this);
    this.syncUnsavedChangesFlag(false);
  }

  private onSuccess(message: string, close = false): void {
    this.hideLoader();
    this.utilityService.showToast({ Type: 'success', Message: message });
    if (close) {
      if (this.backUrl) {
        this.router.navigateByUrl(this.backUrl);
      } else {
        this.appRoutingService.closeDetailsPage();
      }
    }
  }

  private getCurrentSpent(): number {
    return (this.campaign?.status_totals?.committed + this.campaign?.status_totals?.closed) || 0;
  }

  private getTargetCost() {
    return this.campaign?.status_totals?.total || 0;
  }

  private addMissingData() {
    this.metricMappingDetailsService.addMissingDataForLegacyMetricMapping(
      this.currentState,
      this.metricMappingDetailsService.getDefaultStartDate.bind(
        this.metricMappingDetailsService,
        this.budget?.budget_from,
        this.campaign?.start_date
      ),
      this.metricMappingDetailsService.getDefaultTargetDate.bind(
        this.metricMappingDetailsService,
        this.budget?.budget_to,
        this.campaign?.end_date
      )
    );
  }

  /* FORM DATA */
  private _createForm() {
    const formData = {
      startDate: null,
      revenuePerOutcome: null,
      milestones: [null],
      notes: ''
    };

    this.formData = this.fb.group(formData);
    this.formData.controls['revenuePerOutcome'].disable();
    this.formData.controls['startDate'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.onChangedStartDate(value));
  }

  private setFormData() {
    const { notes, startDate, revenuePerOutcome } = this.currentState;
    const formData = { notes, startDate: parseDateString(startDate), revenuePerOutcome: revenuePerOutcome || 0 };
    this.formData.patchValue(formData);

    if (this.isReadOnlyMode) {
      this.formData.disable();
    }
  }

  validateChanges(): boolean {
    const milestonesValid = this.milestonesControl.validate();
    if (this.formData.valid && milestonesValid) {
      return true;
    }

    this.dataValidation.validateFormFields(this.formData);
    return false;
  }

  private applyCurrentValueUpdate() {
    this.setMetricValueLastUpdated();
    this.setLastNegativeMetricValueDate();
    this.buildMetricBreakDownData();
    this.updateCurrentSummary();
    this.actualBusinessValue = this.calcBusinessValue(this.currentState.summary.totalValue);
    if (!this.graphData) {
      this.initGraphData();
    } else {
      this.setGraphDataRecords();
    }
    this.syncUnsavedChangesFlag();
  }

  private calcBusinessValue(value: number): number {
    return this.currentState.isKeyMetric ?
      BusinessValueService.calcBusinessValue(
        value,
        this.currentState.revenuePerOutcome,
        this.currentState.revenueToProfit
      ) :
      null;
  }

  private saveFormData() {
    const formDataState = this.formDataToState();
    Object.keys(formDataState).forEach(key => {
      this.currentState[key] = formDataState[key];
    });
  }

  private formDataToState() {
    const formData = this.formData.value;
    const startDate = formData.startDate;

    return {
      startDate: CampaignMetricDetailsComponent.getStartDateStr(startDate),
      notes: formData.notes
    };
  }

  private applyMetricType$(state: MetricMappingDetailsState): Observable<MetricMappingDetailsState> {
    return this.metricMappingDetailsService.applyMetricType$(state, this.company, this.metrics, this.products).pipe(
      map(([metricMappingState, displayDecimal]) => {
        this.displayDecimal = displayDecimal;
        return metricMappingState;
      })
    );
  }

  private applyParentObjectMetric$(state: MetricMappingDetailsState): Observable<MetricMappingDetailsState> {
    if (!state.metricId) {
      return of(state);
    }
    const parent = this.getParentObject();
    return parent ?
      this.metricMappingDetailsService.loadMetricMappings(state.metricId, [parent.id], this.companyId, parent.type).pipe(
        map(mappings => {
          state.isInherited = mappings?.[0] != null;
          return state;
        })
      ) :
      of(state);
  }

  private getParentObject(): { id: number; type: string; } {
    if (!this.campaign) {
      return null;
    }

    const { OBJECT_TYPES } = this.configuration;
    const { goal: goalId, parent_campaign: parentCampaignId } = this.campaign;

    if (parentCampaignId) {
      return { id: parentCampaignId, type: OBJECT_TYPES.campaign };
    }

    if (goalId) {
      return { id: goalId, type: OBJECT_TYPES.goal };
    }

    return null;
  }

  updateCurrentSummary() {
    this.metricMappingDetailsService.updateCurrentSummary(this.currentState);
  }

  updateTargetSummary(onReady?: (isError?: boolean) => void) {
    this.metricMappingDetailsService.updateTargetValue$(this.currentState, this.getTargetCost()).pipe(
      tap(
        ([targetValue]) => this.targetBusinessValue = this.calcBusinessValue(targetValue)
      )
    ).subscribe(
      () => onReady?.(false),
      () => {
        onReady?.(true);
        console.log(UPDATE_SUMMARY_ERROR_MSG);
      }
    );
  }

  updateSummary(onReady?: (isError?: boolean) => void) {
    forkJoin([
      this.metricMappingDetailsService.updateTargetValue$(this.currentState, this.getTargetCost()).pipe(
        tap(
          ([targetValue]) => this.targetBusinessValue = this.calcBusinessValue(targetValue)
        )
      ),
      this.metricMappingDetailsService.setActualMetricCalculations$(this.currentState, this.getCurrentSpent()).pipe(
        tap(
          () => this.updateCurrentSummary()
        )
      )
    ]).subscribe(
      () => onReady?.(false),
      () => {
        onReady?.(true);
        console.log(UPDATE_SUMMARY_ERROR_MSG);
      }
    );
  }

  navigateToCampaignMetricDetails(id: number) {
    this.appRoutingService.openCampaignMetricDetails(id);
  }

  private updateSalesforceData(mapping: SalesforceCampaignMapping, campaignsForSync: number[]): void {
    if (!mapping) {
      return;
    }

    this.updateIntegrationData(
      () => this.setSalesforceMappedCampaignsNumber(mapping),
      (chain$: Observable<any>) =>
        this.metricMappingDetailsService.syncSalesforceObject(
          this.companyId,
          this.sfIntegrations[0].integrationId,
          [this.campaign.id, ...campaignsForSync],
          chain$),
      messages.UNABLE_TO_UPDATE_SF_CAMPAIGNS_ERROR_MSG
    );
  }

  private updateHubspotData(mapping: HubspotCampaignMapping, campaignsForSync: number[]): void {
    if (!mapping) {
      return;
    }

    this.updateIntegrationData(
      () => this.setHubspotMappedCampaignsNumber(mapping),
      (chain$: Observable<any>) =>
        this.metricMappingDetailsService.syncHubspotObject(
          this.companyId,
          this.hubspotIntegrations[0].integrationId,
          [this.campaign.id, ...campaignsForSync],
          chain$),
      messages.UNABLE_TO_UPDATE_HS_CAMPAIGNS_ERROR_MSG
    );
  }

  private updateIntegrationData(
    applyMapping: () => void,
    sync: (chain$: Observable<any>) => Observable<any>,
    errorMessage: string
  ) {
    this.getUpdateIntegrationData$(applyMapping, sync)
      .subscribe({
        error: (err) => this.onError(err, errorMessage),
        complete: () => this.utilityService.showLoading(false)
      });
  }

  private getUpdateIntegrationData$ (
    applyMapping: () => void,
    sync: (chain$: Observable<any>) => Observable<any>
  ): Observable<any> {
    const updateChain$ = this.metricMappingDetailsService.loadDetails(
      this.companyId,
      this.budget.id,
      this.currentState.objectId
    ).pipe(
      tap((state) => {
        this.prevState.metricCalculations = createDeepCopy(state.metricCalculations);
        this.currentState.metricCalculations = state.metricCalculations;
        this.currentState.thirdPartyAmounts = state.thirdPartyAmounts;
        this.refreshMetricValueUpdates();
        if (applyMapping) {
          applyMapping();
        }
        this.buildMetricBreakDownData();
        this.setMetricValueLastUpdated();
        this.setLastNegativeMetricValueDate();
        this.metricMappingCalculationService.updateMetricStateRecords(this.currentState);
        this.updateCurrentSummary();
        this.actualBusinessValue = this.calcBusinessValue(this.currentState.summary.totalValue);
        this.initGraphData();
      })
    );

    return sync(updateChain$).pipe(takeUntil(this.destroy$));
  }

  openMetricIntegrationModalByName(integrationName: string) {
    const handlers = {
      [MetricIntegrationName.Salesforce]: this.openSalesforceMappingModal.bind(this),
      [MetricIntegrationName.Hubspot]: this.openHubspotMappingModal.bind(this)
    };
    handlers[integrationName]?.();
  }

  openSalesforceMappingModal = () => {
    this.openMetricIntegrationMappingDialog(
      MetricIntegrationName.Salesforce,
      SalesforceMappingDialogComponent,
      this.sfIntegrations[0].integrationId,
      (mapping: SalesforceCampaignMapping, campaignsForSync: number[]) => this.updateSalesforceData(mapping, campaignsForSync)
    );
  };

  openHubspotMappingModal = () => {
    this.openMetricIntegrationMappingDialog(
      MetricIntegrationName.Hubspot,
      HubspotMappingDialogComponent,
      this.hubspotIntegrations[0].integrationId,
      (mapping: HubspotCampaignMapping, campaignsForSync: number[]) => this.updateHubspotData(mapping, campaignsForSync));
  };

  openMetricIntegrationMappingDialog<TComp, TObjMapping>(
    integrationName: MetricIntegrationName,
    DialogComponent: ComponentType<TComp>,
    integrationId,
    onSavedCb: (mapping: TObjMapping, campaignsForSync: number[]) => void
  ) {
    const data: MetricMappingDialogData = {
      companyId: this.companyId,
      campaignName: this.campaign.name,
      campaignMetrics:
        (this.campaign?.metric_data || [])
          .map(mappingData => {
            const metricDetail = this.campaignMetricMappings.find(mapping => mapping.id === mappingData.metric_mapping_id)?.metric_detail;
            const product = metricDetail?.product ? this.products.find(prod => prod.id === metricDetail.product) : null;
            return {
              name: mappingData.metric_name,
              order: mappingData.order,
              productOrder: product?.order || null,
              productName: product?.name || null,
              metricId: metricDetail?.id
            }
          })
          .filter(m => m.metricId),
      campaignId: this.campaign.id,
      dateFrom: this.campaign.start_date || this.budget.budget_from,
      budgetCurrencyCode: this.company.currency,
      budget: this.budget,
      readOnly: this.isReadOnlyMode,
      integration: {
        name: integrationName,
        label: MetricIntegrationDisplayName[integrationName],
      },
      integrationId,
      onSavedCb
    };
    const config = { ...dialogConfig, data };
    const proceedAction = () => {
      this.dialog.open(DialogComponent, config);
    };

    this.metricMappingDetailsService.syncCalculationsOnDemand(
      this.prevState,
      this.currentState,
      proceedAction
    );
  }

  private onChangedStartDate(startDate: Date | string) {
    if (this.graphData) {
      this.graphData.startDate = CampaignMetricDetailsComponent.getStartDateStr(startDate);
    }
  }

  private syncIntegrationsOnMetricCreation() {
    const syncIntegration$ =
      <TObjectMapping>(
        integrationId: string,
        metricsProviderDataService: MetricsProviderDataService<TObjectMapping>,
        mappedCampaignsCountGetter: (objMapping: TObjectMapping) => number,
        mappedCampaignsCountSetter: (objMapping: TObjectMapping) => number,
        syncIntegratedObj: (companyId: number, integrationId: string, campaignsIds: number[], chain$: Observable<any>) => Observable<any>,
        isMetricTypeMappingScopedToIntegration = true
      ) => {
      const integrationData = {
        companyId: this.companyId,
        budgetId: this.budget.id,
        campaignId: this.campaign.id,
        integrationId,
        isMetricTypeMappingScopedToIntegration
      };
      return this.metricMappingDetailsService.syncIntegrationsOnMetricCreation$(
        this.currentState.metricId,
        integrationData,
        metricsProviderDataService,
        mappedCampaignsCountGetter,
        mapping =>
          this.getUpdateIntegrationData$(
            () => mappedCampaignsCountSetter(mapping),
            chain$ => syncIntegratedObj(this.companyId, integrationId, [this.campaign.id], chain$)
          ),
      );
    };


    const syncIntegrations$ = [
      ...(this.sfIntegrations || []).map(
        sfIntegration =>
          syncIntegration$(
            sfIntegration.integrationId,
            this.salesforceDataService,
            CampaignMetricDetailsComponent.getSalesforceMappedCampaignsCount,
            this.setSalesforceMappedCampaignsNumber.bind(this),
            this.metricMappingDetailsService.syncSalesforceObject.bind(this.metricMappingDetailsService)
          )
      ),
      ...(this.hubspotIntegrations || []).map(
        hsIntegration =>
          syncIntegration$(
            hsIntegration.integrationId,
            this.hubspotDataService,
            CampaignMetricDetailsComponent.getHubspotMappedCampaignsCount,
            this.setHubspotMappedCampaignsNumber.bind(this),
            this.metricMappingDetailsService.syncHubspotObject.bind(this.metricMappingDetailsService),
            false
          )
      )
    ];

    if (syncIntegrations$.length) {
      this.showLoader();
      forkJoin(syncIntegrations$).subscribe({
        complete: () => this.hideLoader(),
        error: (err) => this.onError(err, messages.UNABLE_TO_SYNC_INTEGRATION_VALUES_ERROR_MSG)
      });
    }
  }

  public addNewValueUpdateItem(item: MetricValueUpdateItem) {
   this.metricMappingCalculationService.addNewValueUpdateItem(
     this.currentState,
     MetricValueUpdateType.campaign,
     item.date,
     this.getCurrentSpent(),
     item.changeInValue,
     item.runningTotal,
     item.notes
   ).pipe(
     takeUntil(this.destroy$)
   ).subscribe(
     () => this.applyCurrentValueUpdate()
   );
  }

  public removeValueUpdateItem(itemToDelete: MetricValueUpdateItem) {
    this.metricMappingCalculationService.removeValueUpdateItem(
      this.currentState,
      itemToDelete
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.applyCurrentValueUpdate()
    );
  }

  public changeValueUpdateItem(updatedItem: MetricValueUpdateItem) {
    this.metricMappingCalculationService.changeValueUpdateItem(
      this.currentState,
      updatedItem,
      MetricValueUpdateType.campaign
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.applyCurrentValueUpdate()
    );
  }

  public handleProgressChartModeChanged(isChartView: boolean) {
    if (!isChartView) {
      return;
    }

    setTimeout(() => {
      if (this.cpoChart) {
        this.cpoChart.refreshChart();
      }
      if (this.roiChart) {
        this.roiChart.refreshChart();
      }
    })
  }

  public toggleKeyMetric(checked: boolean) {
    this.currentState.isKeyMetric = checked;
    this.actualBusinessValue = this.calcBusinessValue(this.currentState.summary.totalValue);
    this.targetBusinessValue = this.calcBusinessValue(this.currentState.summary.targetValue);
    this.syncUnsavedChangesFlag();
  }

  public setCurrentKeyMetricName() {
    const keyMetricId = this.campaign.key_metric;
    const metric = this.campaignMetricMappings.find(m => m.id === keyMetricId);
    if (!metric) {
      this.currentKeyMetricName = null;
      return;
    }

    let prefix = '';
    if (metric.metric_detail.product) {
      const product = this.products.find(prod => prod.id === metric.metric_detail.product)
      prefix = product ? (product.name + ' ') : '';
    }
    this.currentKeyMetricName = prefix + metric.metric_detail.name;
  }
}
