import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validations } from 'app/app.validations';
import { Configuration } from 'app/app.constants';
import { UtilityService } from '../../services/utility.service';
import { GlCodeService } from 'app/shared/services/backend/gl-code.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserManager } from 'app/user/services/user-manager.service';
import { DataValidationService } from 'app/shared/services/data-validation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface AddGlCodeDialogContext {
  company_id: any;
  gl_detail: any;
  glNames: string[];
}

@Component({
  selector: 'modal-content',
  styleUrls: ['./gl-code-dialog.component.scss'],
  templateUrl: './gl-code-dialog.component.html'
})
export class GlCodeDialogComponent implements OnInit, OnDestroy {
  subscriptions = [];
  isPopupLoading = false;
  company_id: any;
  addGlCodeForm: UntypedFormGroup;
  gl_detail: any;
  title: any;
  MAX_TEXT_INPUT_LENGTH = this.configuration.MAX_TEXT_INPUT_LENGTH;

  constructor(
    private readonly configuration: Configuration,
    protected readonly validations: Validations,
    private readonly glCodeService: GlCodeService,
    private readonly utilityService: UtilityService,
    private readonly fb: UntypedFormBuilder,
    private userManager: UserManager,
    private readonly dataValidation: DataValidationService,
    private readonly dialogRef: MatDialogRef<GlCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: AddGlCodeDialogContext
  ) {
    this.company_id = this.data.company_id;
    this.gl_detail = this.data.gl_detail;

    if (this.gl_detail) {
      this.title = 'Edit Gl Code';
    } else {
      this.title = 'Add Gl Code';
    }

    // Show popup loader
    this.subscriptions.push(this.utilityService.popupLoadingPropertyChanged$.subscribe(
        value => setTimeout(() => this.isPopupLoading = value, 0)
    ));
  }

  ngOnInit() {
    this.createForm();
  }

  createForm(): any {
    this.addGlCodeForm = this.fb.group(
      {
        name: [
          this.gl_detail != null ? this.gl_detail.name : '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(this.configuration.MAX_TEXT_INPUT_LENGTH),
            this.dataValidation.uniqueNameValidator(this.data.glNames),
          ])
        ],
        description: [
          this.gl_detail != null ? this.gl_detail.description : '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(this.configuration.MAX_TEXT_INPUT_LENGTH)
          ])
        ]
      }
    );
  }

  addUpdateGlCode() {
    if (this.addGlCodeForm.valid) {
      const formVal = this.addGlCodeForm.value;
      const formData = { company: this.company_id };

      for (const key in formVal) {
        if (formVal.hasOwnProperty(key)) {
          {
            formData[key] = (formVal[key].replace(/ +/g, ' ')).trim();
          }
        }
      }
      this.utilityService.showPopupLoading(true);
      if (this.gl_detail && this.gl_detail.id) {
        this.subscriptions.push(this.glCodeService.editGlCode(formData, this.gl_detail.id).subscribe(
          () => this.onGlCodeEdited(),
          error => this.onHandleError(error)
        ));
      } else {
        this.subscriptions.push(this.glCodeService.addGlCode(formData).subscribe(
          () => this.onGlCodeAdded(),
          error => this.onHandleError(error)
        ));
      }
    }
  }

  onGlCodeAdded() {
    this.utilityService.showToast({ Title: '', Message: 'GL code created successfully.', Type: 'success' });
    this.closeDialogAndUpdate();
  }

  onGlCodeEdited() {
    this.utilityService.showToast({ Title: '', Message: 'GL code updated successfully.', Type: 'success' });
    this.closeDialogAndUpdate();
  }

  closeDialogAndUpdate() {
    this.utilityService.showPopupLoading(false);
    this.utilityService.loadGlCode(true);
    this.dialogRef.close();
  }

  closeModal() {
    if (confirm(this.validations.ValiditionMessages.LOSE_PROGRESS)) {
      this.dialogRef.close();
    }
  }

  onHandleError(error) {
    if (error.hasOwnProperty('message')) {
      this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
      this.utilityService.showPopupLoading(false);
    } else if (error.hasOwnProperty('detail')) {
      this.utilityService.showPopupLoading(false);
      this.utilityService.showLoading(false);
      this.utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /*
  * getter method for controls
  */
  get name() {
    return this.addGlCodeForm.get('name');
  }

  get description() {
    return this.addGlCodeForm.get('description');
  }
}
