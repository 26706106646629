<div class="delete-dialog">
  <header mat-dialog-title [class.centered]="context?.headerIcon">
    <p class="header-icon" *ngIf="context?.headerIcon"><fa-duotone-icon [icon]="context.headerIcon"></fa-duotone-icon></p>
    <p class="header-title">{{ context?.title }}</p>
  </header>
  <mat-dialog-content [class.sm-content]="!context?.isMetricUsed">
    <div class="content">{{ context?.content }}</div>
    <div class="verification-box" *ngIf="context?.isMetricUsed">
      <span class="hint">Type in DELETE first:</span>
      <mat-form-field appearance="outline">
        <input matInput
         #verifyInput
         (keyup)="verify(verifyInput.value)"
         [ngModel]="verifyInput.value | uppercase">
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="close-button" mat-flat-button mat-dialog-close>
      {{ context?.cancelAction?.label }}
    </button>
    <button mat-flat-button mat-dialog-close color="primary"
      [disabled]="context?.submitAction?.disabled"
      (click)="context?.submitAction.handler()">
      {{ context?.submitAction?.label }}
    </button>
  </mat-dialog-actions>
</div>
