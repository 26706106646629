import { HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { LightProgram, Program } from 'app/shared/types/program.interface';
import { Goal } from 'app/shared/types/goal.interface';
import { Configuration } from 'app/app.constants';
import { setChildLevel } from 'app/shared/components/hierarchy-select/hierarchy-select.utils';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { ObjectMode } from '@shared/enums/object-mode.enum';
import { SegmentedObject } from '@shared/types/segmented-budget-object';
import { CommonObject } from '@shared/types/budget-object.interface';

interface ChildItemsMap {
  [objectType: string]: {
    [objectId: string]: {
      [childObjectType: string]: HierarchySelectItem[];
    }
  }
}

export class LocationItemsBuilder {
  private childItemsMap: ChildItemsMap;
  private singleItems: HierarchySelectItem[];
  private goalItems: HierarchySelectItem[];
  private campaignItems: HierarchySelectItem[];
  private flatItemIdsList: Set<string>;
  private existingIdsByType: Record<string, Set<number>>;

  constructor(
    private readonly configuration: Configuration
  ) {}

  private initAuxiliaryData() {
    const { OBJECT_TYPES } = this.configuration;

    this.childItemsMap = {
      [OBJECT_TYPES.goal]: {},
      [OBJECT_TYPES.campaign]: {},
    };
    this.singleItems = [];
    this.goalItems = [];
    this.campaignItems = [];
    this.flatItemIdsList = new Set();
    this.existingIdsByType = {
      [OBJECT_TYPES.goal]: new Set(),
      [OBJECT_TYPES.campaign]: new Set(),
      [OBJECT_TYPES.program]: new Set(),
    };
  }

  private createLocationHierarchyItem(object: CommonObject & Partial<SegmentedObject>, objectType: string): HierarchySelectItem {
    return {
      title: object.name,
      id: `${objectType}_${object.id}`,
      objectId: object.id,
      objectType,
      segmentData: {
        budgetSegmentId: object.budgetSegmentId || null,
        sharedCostRuleId: object.splitRuleId || null,
      }
    };
  }

  private setExistingIds(objects: { campaigns: LightCampaign[]; programs: LightProgram[]; goals: Goal[]; }) {
    const { goals, campaigns, programs } = objects;
    const { OBJECT_TYPES } = this.configuration;

    (goals || []).forEach(item => this.existingIdsByType[OBJECT_TYPES.goal].add(item.id));
    (campaigns || []).forEach(item => this.existingIdsByType[OBJECT_TYPES.campaign].add(item.id));
    (programs || []).forEach(item => this.existingIdsByType[OBJECT_TYPES.program].add(item.id));
  }

  private doesObjectExist(objectType: string, objectId: number): boolean {
    return objectId && this.existingIdsByType[objectType]?.has(objectId);
  }

  private accessChildMapList(parentType: string, parentId: number, childType: string): HierarchySelectItem[] {
    const { OBJECT_TYPES } = this.configuration;

    if (!this.childItemsMap[parentType][parentId]) {
      this.childItemsMap[parentType][parentId] = {
        [OBJECT_TYPES.campaign]: [],
        [OBJECT_TYPES.program]: []
      };
    }

    return this.childItemsMap[parentType][parentId][childType];
  }

  private addChildItem(
    parent: { objectType: string; id: number },
    child: { objectType: string; item: HierarchySelectItem }
  ) {
    const childList = this.accessChildMapList(parent.objectType, parent.id, child.objectType);
    if (!Array.isArray(childList)) {
      console.warn('Failed to add child item', child);
      return;
    }

    childList.push(child.item);
  }

  private getChildItems(parentType: string, parentId: number, childType: string): HierarchySelectItem[] {
    return this.childItemsMap[parentType]?.[parentId]?.[childType] || [];
  }

  private processPrograms(data: LightProgram[]) {
    if (!data?.length) {
      return;
    }

    const { OBJECT_TYPES } = this.configuration;
    const singlePrograms: HierarchySelectItem[] = [];

    data.forEach(program => {
      const { campaignId, goalId } = program;
      const programItem = this.createLocationHierarchyItem(program, OBJECT_TYPES.program);

      this.flatItemIdsList.add(programItem.id);

      if (this.doesObjectExist(OBJECT_TYPES.goal, goalId)) {
        this.addChildItem(
          { objectType: OBJECT_TYPES.goal, id: goalId },
          { objectType: OBJECT_TYPES.program, item: programItem }
        );
        return;
      }

      if (this.doesObjectExist(OBJECT_TYPES.campaign, campaignId)) {
        this.addChildItem(
          { objectType: OBJECT_TYPES.campaign, id: campaignId },
          { objectType: OBJECT_TYPES.program, item: programItem }
        );
        return;
      }

      singlePrograms.push(programItem);
    });

    this.singleItems.unshift(...singlePrograms);
  }

  private processChildCampaigns(data: LightCampaign[]) {
    if (!data?.length) {
      return;
    }

    const { OBJECT_TYPES } = this.configuration;

    data.forEach(campaign => {
      const childPrograms = this.getChildItems(OBJECT_TYPES.campaign, campaign.id, OBJECT_TYPES.program);
      const childCampaignItem = this.createLocationHierarchyItem(campaign, OBJECT_TYPES.campaign);
      const parentCampaignId = campaign.parentCampaign;
      if(campaign.campaign_integrated_source){
        childCampaignItem['campaign_integrated_source'] = campaign.campaign_integrated_source;
      }
      childCampaignItem.children = childPrograms;
      this.flatItemIdsList.add(childCampaignItem.id);
      this.addChildItem(
        { objectType: OBJECT_TYPES.campaign, id: parentCampaignId },
        { objectType: OBJECT_TYPES.campaign, item: childCampaignItem }
      );
    });
  }

  private processRegularCampaigns(data: LightCampaign[], disableSegmentlessCampaigns) {
    if (!data?.length) {
      return;
    }

    const { OBJECT_TYPES } = this.configuration;
    const singleCampaigns: HierarchySelectItem[] = [];

    data.forEach(campaign => {
      const { goalId } = campaign;
      const campaignCampaigns = this.getChildItems(OBJECT_TYPES.campaign, campaign.id, OBJECT_TYPES.campaign);
      const campaignPrograms = this.getChildItems(OBJECT_TYPES.campaign, campaign.id, OBJECT_TYPES.program);
      const campaignItem = this.createLocationHierarchyItem(campaign, OBJECT_TYPES.campaign);

      const isSegmentless = BudgetObjectService.isSegmentlessObject(campaign);
      campaignItem.isClosed = campaign.mode === ObjectMode.Closed;
      campaignItem.notClickable = disableSegmentlessCampaigns && isSegmentless;
      campaignItem.campaign_integrated_source = campaign.campaign_integrated_source ? campaign.campaign_integrated_source : false;
      campaignItem.children = [ ...campaignCampaigns, ...campaignPrograms ];
      this.flatItemIdsList.add(campaignItem.id);

      if (this.doesObjectExist(OBJECT_TYPES.goal, goalId)) {
        this.addChildItem(
          { objectType: OBJECT_TYPES.goal, id: goalId },
          { objectType: OBJECT_TYPES.campaign, item: campaignItem }
        );
        return;
      }

      if (campaignItem.children.length) {
        this.campaignItems.push(campaignItem);
      } else {
        singleCampaigns.push(campaignItem);
      }
    });

    this.singleItems.unshift(...singleCampaigns);
  }

  private processCampaigns(data: LightCampaign[], disableSegmentlessCampaigns: boolean) {
    if (!data?.length) {
      return;
    }

    const childCampaigns = data.filter(fc => !!fc.parentCampaign);
    const regularCampaigns = data.filter(fc => !fc.parentCampaign);

    this.processChildCampaigns(childCampaigns);
    this.processRegularCampaigns(regularCampaigns, disableSegmentlessCampaigns);
  }

  private processGoals(data: Goal[]) {
    if (!data?.length) {
      return;
    }

    const { OBJECT_TYPES } = this.configuration;
    const singleGoals: HierarchySelectItem[] = [];

    data.forEach(goal => {
      const goalItem = this.createLocationHierarchyItem(goal, OBJECT_TYPES.goal);
      const goalCampaigns = this.getChildItems(OBJECT_TYPES.goal, goal.id, OBJECT_TYPES.campaign);
      const goalPrograms = this.getChildItems(OBJECT_TYPES.goal, goal.id, OBJECT_TYPES.program);

      goalItem.children = [ ...goalCampaigns, ...goalPrograms ];
      this.flatItemIdsList.add(goalItem.id);

      if (goalItem.children.length) {
        this.goalItems.push(goalItem);
      } else {
        singleGoals.push(goalItem);
      }
    });

    this.singleItems.unshift(...singleGoals);
  }

  public prepareItems(
    objects: { campaigns: LightCampaign[]; programs: LightProgram[]; goals: Goal[]; },
    singleLast: boolean,
    disableSegmentlessCampaigns: boolean
  ): {
    flatItemIdsList: string[];
    items: HierarchySelectItem[];
  } {
    const { goals, campaigns, programs } = objects;

    this.initAuxiliaryData();
    this.setExistingIds(objects);
    this.processPrograms(programs);
    this.processCampaigns(campaigns, disableSegmentlessCampaigns);
    this.processGoals(goals);

    const items = [
      ...this.goalItems,
      ...this.campaignItems,
    ];

    singleLast ? items.push(...this.singleItems) : items.unshift(...this.singleItems);

    items.forEach(item => {
      setChildLevel(item, 0)
    })

    return {
      flatItemIdsList: [ ...this.flatItemIdsList ],
      items,
    };
  }
}
