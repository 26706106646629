import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeframeConfigDialog } from '@manage-ceg/types/manage-ceg-page.types';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { TIMEFRAME_QUARTER_IDS } from '@shared/components/timeframe-select/timeframe-select.constants';
import { TimeframeChoiceItem } from '@shared/components/timeframe-select/timeframe-select.types';

@Component({
  selector: 'timeframe-config-dialog',
  templateUrl: './timeframe-config-dialog.component.html',
  styleUrls: ['./timeframe-config-dialog.component.scss']
})
export class TimeframeConfigDialogComponent {
  selectedTimeframeIds: (number | string)[] = [];
  timeframes: TimeframeChoiceItem[];

  timeframeMode: BudgetTimeframesType;
  fiscalYearLabel: string;

  readonly budgetTimeframesType = BudgetTimeframesType;

  private rangeIndexes: number[] = [];

  constructor(
    private readonly dialogRef: MatDialogRef<TimeframeConfigDialogComponent>,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: TimeframeConfigDialog
  ) {
    this.timeframeMode = data?.timeframeMode;
    this.fiscalYearLabel = data?.fiscalYearLabel;
    this.selectedTimeframeIds = data?.selectedTimeframes;
    this.setTimeframes();
  }

  onSelectionChange(item: TimeframeChoiceItem): void {
    const itemIndex = this.timeframes.indexOf(item);
    const startIndex = this.rangeIndexes[0];
    const endIndex = this.rangeIndexes[1];

    if (itemIndex === startIndex && this.selectedTimeframeIds.length === 1) {
      this.clearSelection();
      return;
    }

    if (typeof startIndex !== 'number' || (startIndex > -1 && endIndex > -1)) {
      this.rangeIndexes[0] = itemIndex;
      this.rangeIndexes[1] = undefined;
      this.selectedTimeframeIds = [];
      this.cdr.detectChanges();
      this.selectedTimeframeIds = [item.id];
    } else if (typeof endIndex !== 'number') {
      this.rangeIndexes[1] = itemIndex;
      this.updateRange();
    }
  }

  clearSelection(): void {
    this.data.onSubmit([]);
    this.dialogRef.close();
  }

  onQuarterSelectionChange(quarterId: number, isSelected: boolean): void {
    this.rangeIndexes = isSelected ? [quarterId * 3 - 3, quarterId * 3 - 1] : [];
    this.updateRange();
  }

  submitConfig(): void {
    this.data.onSubmit(this.selectedTimeframeIds);
    this.dialogRef.close();
  }

  private setTimeframes(): void {
    const budgetTimeframeType = this.data?.budgetTimeframes.length === 12 ? BudgetTimeframesType.Month : BudgetTimeframesType.Quarter;

    this.timeframes = budgetTimeframeType === this.timeframeMode
      ? this.data?.budgetTimeframes.map(timeframe => ({
          id: timeframe.id,
          title: timeframe.name.substring(0, 3)
        }))
      : TIMEFRAME_QUARTER_IDS.map(id => ({
          id: 'q' + id,
          title: 'Q' + id
        }));
  }

  private updateRange(): void {
    const startIndex = Math.min(...this.rangeIndexes);
    const endIndex = Math.max(...this.rangeIndexes) + 1;

    this.selectedTimeframeIds = this.timeframes.slice(startIndex, endIndex).map(item => item.id);
  }
}
