<header class="details-header ceg-budget-details" *ngIf="isCEGBudget else notCEGBudgetHeader">
  <nav class="app-navigation" [ngClass]="headerBackgroundClass">
    <nav *ngIf="!isInvoiceDrawer" class="actions-panel">
      <details-page-actions
        class="ml-a"
        isDrawer="true"
        [objectLabel]="objectLabel"
        [isReadOnlyMode]="isReadOnlyMode"
        [hasUnsavedChanges]="hasUnsavedChanges"
        (cancel)="cancel.emit()"
        (save)="save.emit()"
        (saveAndClose)="saveAndClose.emit()"
        (saveAndNew)="saveAndNew.emit()"
        [detailsFormGroupData]="detailsFormGroupData"
        [objectType]="objectType"
        [showSaveAndCreateButton]="objectType !== 'metric'"
        [disableSaveAndCloseButton]="disableSaveAndCloseButton"
      ></details-page-actions>
    </nav>
    <div class="title-wrapper" [ngClass]="{ 'mt-05': isInvoiceDrawer }">
      <page-title
        isDrawer="true"
        [nameCtrl]="nameFormControl"
        [objectType]="objectType?.toLowerCase()"
        [maxLength]="maxObjectNameLength"
        [isCEGBudget]="isCEGBudget"
        [parentId]="parentId"
        [parentName]="parentName"
        [isCampaignMetricDrawer]="isCampaignMetricDrawer"
      ></page-title>
      <manage-object-actions
        *ngIf="actions?.length && !isInvoiceDrawer"
        [objectDisplayType]="objectLabel || 'Object'"
        isDrawer="true"
        [isMetricDrawer]="objectType === 'metric'"
        [actions]="actions"
        [budgetList]="budgetList"
        [isCEGBudget]="isCEGBudget"
        [parentHierarchy]="objectHierarchy"
        [childMetricHierarchy]="childMetricHierarchy"
        [childHierarchy]="childHierarchy"
        (budgetToMoveSelected)="budgetToMoveSelected.emit($event)"
        (onChildHierarchyItemClick)="onChildHierarchyItemClick.emit($event)"
      ></manage-object-actions>
    </div>
  </nav>
</header>

<ng-template #notCEGBudgetHeader>
  <header class="details-header">
    <nav *ngIf="!isInvoiceDrawer" class="actions-panel">
      <manage-object-actions
        *ngIf="!isReadOnlyMode"
        [objectDisplayType]="objectLabel || 'Object'"
        isDrawer="true"
        [actions]="actions"
        [budgetList]="budgetList"
        [isCEGBudget]="isCEGBudget"
        (budgetToMoveSelected)="budgetToMoveSelected.emit($event)"
      ></manage-object-actions>
      <details-page-actions
        class="ml-a"
        isDrawer="true"
        [detailsFormGroupData]="detailsFormGroupData"
        [objectType]="objectType"
        [objectLabel]="objectLabel"
        [isReadOnlyMode]="isReadOnlyMode"
        [hasUnsavedChanges]="hasUnsavedChanges"
        (cancel)="cancel.emit()"
        (save)="save.emit()"
        (saveAndClose)="saveAndClose.emit()"
        (saveAndNew)="saveAndNew.emit()"
        [disableSaveAndCloseButton]="disableSaveAndCloseButton"
      ></details-page-actions>
    </nav>
    <nav class="app-navigation">
      <div *ngIf="!isInvoiceDrawer" class="location">
        <object-hierarchy-menu [objectHierarchy]="objectHierarchy"></object-hierarchy-menu>
      </div>
      <div class="title-wrapper">
        <page-title
          isDrawer="true"
          [nameCtrl]="nameFormControl"
          [objectType]="objectType?.toLowerCase()"
          [maxLength]="maxObjectNameLength"
          [isCEGBudget]="isCEGBudget"
        ></page-title>
      </div>
      <details-timestamps
        [created]="createdDate"
        [updated]="updatedDate"
      ></details-timestamps>
    </nav>
  </header>
</ng-template>
