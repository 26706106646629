import { SharedCostRuleComponent } from '../shared-cost-rule.component';
import { SCRFocusStrategy } from './scr-focus-strategy.interface';
import { SharedCostRuleFieldType } from 'app/shared/types/shared-cost-rule.interface';

export class SCRAddSegmentFocusStrategy implements SCRFocusStrategy {
  componentInstance: SharedCostRuleComponent;

  constructor(componentInstance: SharedCostRuleComponent) {
    this.componentInstance = componentInstance;
  }

  activate() {
    this.componentInstance.currentFocusStrategy = this;
    this.componentInstance.focusedFieldType = SharedCostRuleFieldType.AddSegment;
    this.componentInstance.focusedSegmentIndex = -1;
    this.componentInstance.resetActiveDropdown();
    this.componentInstance.focusElement(this.componentInstance.addSegmentElement);
  }

  moveFocus(isForward: boolean) {
    const { focusStrategies } = this.componentInstance;
    if (isForward) {
      const focusedSegmentIndex = 0;
      focusStrategies[SharedCostRuleFieldType.SegmentId].activate(focusedSegmentIndex);
    } else {
      focusStrategies[SharedCostRuleFieldType.Name].activate();
    }
  }

  selectElement() {
    this.componentInstance.handleSegmentAdd();
  }

  unselectElement() {
    // shouldn't be implemented
  }
}
