<article class="auth-page login-screen signin">
  <section class="auth-page-content">
    <div class="login-form-container">
      <h1 class="logo">
        <a href="javascript:;" title="Planful" (click)="goToLogin()">
          <img src="assets/images/planful-logo-colored.svg" title="" alt="" />
        </a>
      </h1>
      <div class="message-box">
        <div class="message-title">No Access</div>
        <div class="message-text">You are successfully logged in with SSO, but your Planful admin has yet to give you permission.</div>
        <button class="ok" type="button" (click)="goToLogin()">Ok</button>
      </div>
    </div>
  </section>
</article>
