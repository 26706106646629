import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { getRequestOptions } from '../../utils/http-request.utils';
import { AuditLogParams } from '../../types/audit-log-params.interface';
import { AuditLogObjectsHistoryParams } from '../../types/audit-log-objects-history-params.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { Observable } from 'rxjs';
import { ObjectOperationLogs } from '@shared/services/history.service';

export interface AuditLogDO {
  instance_id: number;
  date: string;
  log_type: string;
  name: string;
  parent?: {
    object_type: string;
    name: string;
  }
  operation: string;
  user: string; // full name or email
}

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    auditLogs: 'audit_logs/',
    objectsHistory: 'objects_history/',
    objectsViewHistory: 'objects_view_history/'
  };

  /**
   * We should use 'budget' (not 'budget_id') param for RECENTLY_ADDED logs per PUP-3423
   */
  getLogs(options: Partial<AuditLogParams>): Observable<AuditLogDO[]> {
    return this.http.get<AuditLogDO[]>(this.apiV2Url + this.apiPaths.auditLogs, getRequestOptions(options));
  }

  getObjectsHistory(options: Partial<AuditLogObjectsHistoryParams>): Observable<ObjectOperationLogs<AuditLogDO>> {
    return this.http.get<ObjectOperationLogs<AuditLogDO>>(
      this.apiV2Url + this.apiPaths.auditLogs + this.apiPaths.objectsHistory,
      getRequestOptions(options)
    );
  }

  objectsViewHistory(options: Partial<AuditLogParams>): Observable<AuditLogDO[]> {
    return this.http.get<AuditLogDO[]>(
      this.apiV2Url + this.apiPaths.auditLogs + this.apiPaths.objectsViewHistory,
      getRequestOptions(options)
    );
  }
}
