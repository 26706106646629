import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TimeZone } from '@vvo/tzdb';
import { IntegrationScheduleSettings } from '../types/integration-schedule-settings.interface';
import { MetricsProviderDataService } from './metrics-provider-data.service';
import { getLocalTimezone, getTimezoneList } from 'app/shared/utils/timezone.utils';
import { convertTo24H } from 'app/shared/utils/time.utils';
import { IntegrateCompanyResponse, Integration, IntegrationData, SyncStatusResponse } from '../types/metrics-provider-data-service.types';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { MetricIntegrationName } from '../types/metric-integration';
import { Injectable } from '@angular/core';
import { IntegrationSyncProgressService } from './integration-sync-process.service';
import { MetricIntegrationsProviderService } from './metric-integrations-provider.service';
import { IntegratedObjectsService } from './integrated-objects.service';
import { ExternalMetricTypesProviderService } from './external-metric-types-provider.service';
import { MetricDO, MetricService } from 'app/shared/services/backend/metric.service';
import { ExternalMetrics } from '../types/external-metrics.types';
import { SalesforceCampaignMapping } from '../salesforce/salesforce-campaign-mapping.interface';
import { CurrentMappingTargets } from '../salesforce/salesforce-data.service';

@Injectable()
export class IntegrationSetupProviderService {
  integrationSource: MetricIntegrationName;
  private integrationDataProvider: MetricsProviderDataService<any>;
  private timezones: TimeZone[] = getTimezoneList();

  constructor(
    private readonly companyDataService: CompanyDataService,
    private readonly integrationProvidersService: MetricIntegrationsProviderService,
    private readonly integratedObjectsService: IntegratedObjectsService,
    private readonly externalMetricTypesProvider: ExternalMetricTypesProviderService,
    private readonly metricService: MetricService,
  ) {
  }

  public initProvider(integrationSource: MetricIntegrationName) {
    this.integrationDataProvider = this.integrationProvidersService.metricIntegrationProviderByType(integrationSource);
    if (!this.integrationDataProvider) {
      return;
    }
    this.integrationSource = integrationSource;
  }

  public addMetricTypeMappingsIfNotExist(companyId: number): Observable<any> {
    return this.integrationDataProvider.getExternalMetricTypesMapping(companyId).pipe(
      catchError(error => MetricsProviderDataService.handleGetError(error)),
      switchMap(mappings =>
        mappings ?
          of(null) :
          this.getDefaultMetricMappings(companyId).pipe(
            switchMap(defaultMappings => this.integrationDataProvider.setExternalMetricTypesMapping(companyId, defaultMappings))
          )
      )
    );
  }

  getExternalMetricTypesMapping(companyId, integrationId): Observable<any> {
    return this.integrationDataProvider.getExternalMetricTypesMapping(companyId, integrationId);
  }

  private getDefaultMetricMappings(companyId: number) {
    const externalMetricTypesService =
      this.externalMetricTypesProvider.getExternalMetricTypesServiceByIntegrationSource(this.integrationSource);

    return forkJoin([
      this.metricService.getMetrics(companyId),
      externalMetricTypesService.getMetricTypes(companyId)
    ]).pipe(
      map(([plannuhMetrics, externalMetricTypes]: [MetricDO[], ExternalMetrics]) =>
        this.externalMetricTypesProvider.getDefaultInitialMapping(externalMetricTypesService, externalMetricTypes, plannuhMetrics)
      )
    );
  }

  public getDefaultScheduleSettings(): IntegrationScheduleSettings {
    const localTimezone = getLocalTimezone(this.timezones);

    return {
      localTmz: localTimezone && localTimezone.alternativeName,
      syncTime: convertTo24H('03:00 AM', localTimezone && localTimezone.rawOffsetInMinutes)
    };
  }

  public getScheduleSettings(companyId: number, integrationId: string): Observable<IntegrationScheduleSettings> {
    if (!integrationId) {
      return of(this.getDefaultScheduleSettings());
    }
    return this.integrationDataProvider.getCronSettings(companyId, integrationId)
      .pipe(
        map((settings) => {
          return settings || this.getDefaultScheduleSettings();
        })
      )
  }

  public saveScheduleSettings(companyId: number, integrationId: string, settings: IntegrationScheduleSettings) {
    return this.integrationDataProvider.setCronSettings(companyId, integrationId, settings);
  }

  public deleteScheduleSettings(companyId: number, integrationId: string) {
    return this.integrationDataProvider.deleteCronSettings(companyId, integrationId);
  }

  public initIntegration<TIntegrationData>(companyId: number, data: TIntegrationData): Observable<IntegrateCompanyResponse> {
    return this.integrationDataProvider.integrateCompany(companyId, data);
  }

  public refreshAuth(companyId: number, integrationId: string): Observable<{ location: string }> {
    return this.integrationDataProvider.refreshAuth(companyId, integrationId);
  }

  private checkAndRemoveExistingCampaigns(companyId: number, integrationId: string, budgetId: number): Observable<any> {
    return this.integrationDataProvider.getBudgetIntegrationCampaignMappings(companyId, integrationId, budgetId)
      .pipe(
        switchMap(mappingItemsResponse =>
          this.integratedObjectsService.deleteCampaignsWithExpenses(
            companyId,
            budgetId,
            mappingItemsResponse?.campaignMappingItems.map(item => item.campaignId),
            mappingItemsResponse?.costAdjustmentExpenseBucketIds
          )
        )
      );
  }

  public removeIntegration(companyId: number, integrationId: string, budgetId: number): Observable<string> {
    return this.checkAndRemoveExistingCampaigns(companyId, integrationId, budgetId).pipe(
      switchMap(
        () => this.integrationDataProvider.removeIntegration(companyId, integrationId)
      )
    );
  }

  public setIntegration(integration: Integration) {
    this.companyDataService.updateMetricIntegrations(this.integrationSource, integration);
  }

  public deleteIntegration(integrationId: string) {
    this.companyDataService.deleteMetricIntegration(this.integrationSource, integrationId);
  }

  public getCurrentIntegrations(): Observable<Integration[]> {
    return this.companyDataService.metricIntegrations$
      .pipe(
        map(integrations => integrations[this.integrationSource])
      );
  }

  public loadIntegrations(companyId: number, budgetId?: number): Observable<Integration[]> {
    return this.integrationDataProvider.getIntegrations(companyId, budgetId);
  }

  public getCompanyRunningSynchronizations(companyId: number): Observable<SyncStatusResponse[]> {
    return this.integrationDataProvider.getCompanyRunningSynchronizations(companyId);
  }

  public syncAllIntegrations(companyId: number): Observable<any> {
    return this.integrationDataProvider.syncAllIntegrations(companyId);
  }

  public syncMappings(companyId: number,  integrationId: string, campaignId: number): Observable<any> {
    return this.integrationDataProvider.syncMappings(companyId, integrationId, campaignId);
  }

  public setCampaignMapping(
    companyId: number,
    budgetId: number,
    campaignId: number,
    integrationId: string,
    mapping: SalesforceCampaignMapping
  ) {
    return this.integrationDataProvider.setCampaignMapping(companyId, budgetId, campaignId, integrationId, mapping)
  }

  public getExternalCampaignsPayload(records: any[]) {
    return this.integrationDataProvider.getExternalCampaignsPayload(records);
  }

  public getCurrentMappingTargets(
    companyId: number,
    integrationId: string,
    budgetId: number,
    externalCampaignsData: any): Observable<CurrentMappingTargets> {
    return this.integrationDataProvider.getCurrentMappingTargets(companyId, integrationId, budgetId, externalCampaignsData);
  }

  public getAuthData(companyId: number, integrationId: string): Observable<IntegrationData> {
    return this.integrationDataProvider.getAuthData(companyId, integrationId);
  }

  public setAuthData(companyId: number, integrationId: string, data: IntegrationData): Observable<void> {
    return this.integrationDataProvider.setAuthData(companyId, integrationId, data);
  }

  public setReauthData(companyId: number, integrationId: string, data: IntegrationData) {
    return this.integrationDataProvider.setReauthData(companyId, integrationId, data);
  }
  public deleteDataSyncProgressStatus(companyId: number, integrationId: string) {
    return this.integrationDataProvider.deleteDataSyncProgressStatus(companyId, integrationId);
  }

  public startIntegrationSyncProgressTracking(
    integrationSyncProcessService: IntegrationSyncProgressService,
    companyId: number,
    integrationId: string
  ): void {
    integrationSyncProcessService.trackIntegration(
      this.integrationDataProvider,
      companyId,
      this.integrationSource,
      integrationId
    );
  }
}
