import { Injectable } from '@angular/core';
import { SpendingItem, SpendingTimelineDataItem } from './spending-timeline.types';
import { BudgetTimeframeDataItem } from '../widget.service';
import { Configuration } from 'app/app.constants';

@Injectable()
export class SpendingTimelineService {

  constructor(private configuration: Configuration) {
  }
  public convertRawDataToToSpendingTimelineData(data: BudgetTimeframeDataItem[]): SpendingTimelineDataItem[] {
    const isMonthlyBudget = data.length === 12;
    const timelineData =
      data && data.map(rawDataItem => this.convertRawDataItemToSpendingTimelineDataItem(rawDataItem, isMonthlyBudget));

    const maxAmount =
      (timelineData || []).reduce(
        (amount, item) => Math.max(amount, Math.max(item.budget, item.totalSpent)),
        0
      );

    (timelineData || []).forEach(item => item.maxAmount = maxAmount);

    return timelineData;
  }

  private convertRawDataItemToSpendingTimelineDataItem(
    rawDataItem: BudgetTimeframeDataItem,
    isMonthlyBudget: boolean
  ): SpendingTimelineDataItem {
    const budget = rawDataItem.total;
    const totalSpent = (rawDataItem.closed || 0) + (rawDataItem.spent || 0) + (rawDataItem.committed || 0) + (rawDataItem.planned || 0) + (rawDataItem.under_budget || 0);
    const spendingData: SpendingItem[] = [
      { id: 'remaining', val: budget > totalSpent ? budget - totalSpent : 0 },
      { id: 'planned', val: rawDataItem.planned || 0 },
      { id: 'committed', val: rawDataItem.committed || 0 },
      { id: 'closed', val: rawDataItem.closed || 0 },
      { id: 'under-budget', val: rawDataItem.under_budget || 0 },
      { id: 'spent', val: rawDataItem.spent || 0 }
    ];

    const title = isMonthlyBudget ?
      this.configuration.getShortMonthName(rawDataItem.company_budget_alloc_name) :
      rawDataItem.company_budget_alloc_name;

    return {
      id: rawDataItem.company_budget_alloc_id,
      title,
      spending: spendingData,
      totalSpent,
      budget,
      overSpend: totalSpent > budget ? totalSpent - budget : 0,
      maxAmount: 0,
      isLocked: rawDataItem.locked
    };
  }
}
