export enum StateField {
    ActualAmount = 'actualAmount',
    Amount = 'amount',
    GlCode = 'glCode',
    Name = 'name',
    CurrencyCode = 'currencyCode',
    SourceActualAmount = 'sourceActualAmount',
    BudgetAllocationId = 'budgetAllocationId',
    Mode = 'mode',
    PoNumber = 'poNumber',
    VendorName = 'vendorName',
    InvoiceNumber = 'invoiceNumber',
    OwnerId = 'ownerId',
    TypeId = 'typeId',
    DeliveryDate = 'deliveryDate',
    SegmentId = 'segmentId',
    SharedCostRuleId = 'sharedCostRuleId',
    Notes = 'notes',
    Campaign = 'campaignId',
    Program = 'programId'
}
//TODO: temporary recognized fields enum for testing
export enum RecognizedField {
    ActualAmount = 'actual_amount',
    Amount = 'Amount',
    GlCode = 'GlCode',
    Name = 'Name',
    CurrencyCode = 'source_currency',
    SourceActualAmount = 'SourceActualAmount',
    BudgetAllocationId = 'company_budget_alloc',
    Mode = 'Mode',
    PoNumber = 'expense_po_no',
    VendorName = 'vendor',
    InvoiceNumber = 'invoice',
    OwnerId = 'OwnerId',
    TypeId = 'TypeId',
    DeliveryDate = 'expense_delivery_date',
    SegmentId = 'SegmentId',
    SharedCostRuleId = 'SharedCostRuleId',
    Notes = 'Notes',
    Segment = 'Segment',
    Campaign = 'campaign_id',
    Program = 'program_id'
}
