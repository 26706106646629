import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, AfterViewInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Subject } from 'rxjs';
import { createDeepCopy, createRegExpFromString } from 'app/shared/utils/common.utils';
import { HierarchySelectConfig, HierarchySelectItem, HierarchySelectValue } from './hierarchy-select.types';
import { filterItemsByPattern } from './hierarchy-select.utils';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hierarchy-select',
  templateUrl: './hierarchy-select.component.html',
  styleUrls: ['./hierarchy-select.component.scss']
})
export class HierarchySelectComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() items: HierarchySelectItem[];
  @Input() selectedValue: HierarchySelectValue = null;
  @Input() config: HierarchySelectConfig;
  @Input() placeholder = '';
  @Input() fixedSelectWidth = false;
  @Input() groupSelectionAllowed = true;
  @Input() disableOptionCentering = true;
  @Input() showTriggerIcon = false;  
  @Input() id: string;
  @Output() onChange = new EventEmitter<HierarchySelectItem>();
  @Output() onClose = new EventEmitter<void>();

  @ViewChild('matSelect') matSelect: MatSelect;

  private destroy$ = new Subject<void>();
  private defaultEmptyItem = { id: null, title: 'None' };
  public activeItems: HierarchySelectItem[] = [];
  public selectControl = new UntypedFormControl(null);
  public groupsOpenState: Record<string, boolean> = {};
  public groupsTotalAmount: number;
  public basePanelClass = 'mat-select-fixed-position mat-select-fixed-groups hierarchy-select-panel';
  public allSelected = false;
  private isExternalControl = false;
  
  @Input() selectedSegmentId: number;
  @Input() isChildCampaignCreation: boolean;
  @Input() isSegmentControl: boolean;
  @Input() set disabled(value: boolean) {
    value ? this.selectControl.disable() : this.selectControl.enable();
  }
  @Input() set selectControlExternal(ctrl: AbstractControl) {
    this.isExternalControl = true;
    this.selectControl = ctrl as UntypedFormControl;
    this.selectControl.valueChanges
      .pipe(
        filter(val => !!val),
        takeUntil(this.destroy$)
      )
      .subscribe((selected: HierarchySelectItem) => {
        // expand hierarchy tree
        this.findItemById(this.items, selected.id);
      })
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && this.items) {
      this.activeItems = this.items;
      this.groupsTotalAmount = this.items.filter(i => i.children && i.children.length).length;
      if(this.selectedSegmentId) {
        let selectedItem = this.findItemById(this.items,`Segment_${this.selectedSegmentId}`);
        if(selectedItem) {
          this.selectControl.setValue(selectedItem);

        }
      }
      this.setSelectControlValue();
    }

    if (changes.selectedValue && this.selectedValue !== this.selectControl.value?.id) {
      this.setSelectControlValue();
    }
  }

  ngAfterViewInit(): void {
    const { alwaysOpen } = this.config;

    setTimeout(() => {
      if (alwaysOpen) {
        this.matSelect?.open();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setSelectControlValue() {
    if (this.isExternalControl) {
      return;
    }
    const selectedItem = this.findItemById(this.items, this.selectedValue);
    const value = this.config.alwaysOpen && !selectedItem ? this.defaultEmptyItem : selectedItem;

    this.selectControl.setValue(value);
  }

  private findItemById(data: HierarchySelectItem[], targetId: string): HierarchySelectItem | null {
    if (!data?.length) {
      return null;
    }

    for (const item of data) {
      if (item.id === targetId) {
        return item;
      }

      if (item.children?.length) {
        const childItem = this.findItemById(item.children, targetId);
        if (childItem) {
          this.groupsOpenState[item.id] = true;
          return childItem;
        }
      }
    }

    return null;
  }

  public updateActiveItems(filterText: string) {
    const data = createDeepCopy(this.items);

    if (filterText.length > 2) {
      const pattern = createRegExpFromString(filterText, 'i');
      this.activeItems = filterItemsByPattern(data, pattern);
    } else {
      this.activeItems = data;
    }
  }

  public handleSelectClosed() {
    this.onClose.emit();
  }

  public handleSelectionChange($event: MatSelectChange) {
    const value = $event.value;
    this.onChange.emit(value);
  }

  public toggleGroup(collapsed: boolean, id: string) {
    if (collapsed) {
      delete this.groupsOpenState[id];
    } else {
      this.groupsOpenState[id] = true;
    }
    this.allSelected = this.groupsTotalAmount === Object.keys(this.groupsOpenState).length;
  }

  public compareSelectItems(valueA: HierarchySelectItem, valueB: HierarchySelectItem): boolean {
    return valueA?.id === valueB?.id;
  }

  public identifyItem(index, item: HierarchySelectItem) {
    return item.id;
  }

  toggleAllGroupsCollapse(collapsed: boolean) {
    if (!collapsed) {
      this.items.forEach(item => {
        if (item.children && item.children.length) {
          this.groupsOpenState[item.id] = true;
        }
      });
    } else {
      this.groupsOpenState = {};
    }
    this.allSelected = !collapsed;
  }
}
