<div
  class="manage-table-plain-value"
  [class.is-clickable]="isClickable"
  [class.is-bold]="isBold"
  [class.is-disabled]="isDisabled"
  (click)="handleClick()"
>
  <formatted-budget-value
    *ngIf="value != null || !withNullishPlaceholder; else NAValue"
    [config]="{
      value: value,
      isOverspend: value < 0,
      withBrackets: value < 0,
      isBold: isBold,
      allowNegative: false
    }"
  ></formatted-budget-value>
  <ng-template #NAValue>
    N/A
  </ng-template>
</div>
