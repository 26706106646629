<button
  mat-button
  class="header-action-item with-icon history-menu ut-history"
  [matMenuTriggerFor]="menu"
  [disabled]="disabled || !items.length"
>
  <fa-icon [icon]="['fas', 'clock-rotate-left']"></fa-icon>
  <span class="btn-text">History</span>
  <fa-icon class="chevron" [icon]="['far', 'chevron-down']"></fa-icon>
</button>
<mat-menu #menu class="mat-pl-menu navigation-menu history-menu" >
  <div class="inner-wrapper" [class.elongated]="elongatedContainer">
    <button *ngFor="let item of items" mat-menu-item [class.metric-mapping]="item.metricOrigin" (click)="item.action && item.action()">
      <fa-icon *ngIf="item.faIcon" class="primary-icon" [icon]="item.faIcon"></fa-icon>
      <span class="item-label">{{ item.label }}</span>

      <ng-container *ngIf="item.metricOrigin">
        <span class="metric-origin-label">from</span>
        <fa-icon *ngIf="item.metricOrigin.faIcon" class="primary-icon" [icon]="item.metricOrigin.faIcon"></fa-icon>
        <span class="item-label">{{ item.metricOrigin.label }}</span>
      </ng-container>
    </button>
  </div>
</mat-menu>

