import { Injectable } from '@angular/core';;

@Injectable({
  providedIn: 'root'
})
export class Validations {
    constructor() { }

    // validations
    public ValiditionMessages = {
    	// login component messages
        "ERROR" : "error",
        "SUCCESS" : "success",
        "INFO": "info",
        "DATA_LOST" : "Careful, you are updating details on this screen. Its changes will be reflected on next screen. Do you wish to continue?",
        "INVALID_EMAIL" : "Please enter a valid email.",
        "INVALID_USER_NAME" : "Please enter a valid user name.",
    	"EMAIL_REQUIRED" : "Please enter your email.",
        "INVALID_PASSWORD" : "Please enter your password.",
        "PASSWORD_PATTERN" : "Must be 8-16 characters include at least one lowercase letter, one uppercase letter, one special character and a number.",
        "INVALID_PASSWORD_MIN_LENGTH" : "Password should be atleast 6 characters in length.",
        "INVALID_IMAGE" : "You can only upload an image file.",
    	"INVALID_IMAGE_SIZE" : "You can upload file size upto 2MB.",

    	// Register component messages
    	"NAME_REQUIRED" : "Please enter name.",
        "INVALID_NAME" : "Please enter a valid name.",
        "COMPANY_NAME_REQUIRED" : "Please enter company name.",
    	"INVALID_COMPANY_NAME" : "Please enter a valid company name.",
      "COMPANY_EXIST" : "This company name has already been used. Please enter another name.",
    	"PASSWORD_NOT_MATCH" : "Passwords do not match.",
        "TC_ACCEPTANCE" : "You must accept the Terms and Conditions.",

        //On cancel modal
        "LOSE_PROGRESS" : "If you close this modal it will lose your progress.",

        // Adjust Budget warning message
        "ADJUST_BUDGET": "Your budget will be slightly altered but you can change it later.",

    	// setup validations
    	"INVALID_COMPANY_STRENGTH" : "Please select  the number of employees in your company.",
        "INVALID_INDUSTRY_TYPE" : "Please select the Industry type for your company.",
        "INVALID_REVENUE_TYPE" : "Please select the Revenue for your company.",
        "SEGMENT_NAME_EMPTY": "Segment name cannot be empty",
        "BUDGET_NAME_REQUIRED" : "Please give your budget a name.",
        "INVALID_CATEGORY_NAME" : "Category name cannot contain special characters.",
        "INVALID_BUDGET_NAME" : "Please enter a valid budget name.",
        "PLEASE_ENTER_A_CATEGORY_NAME" : "Please enter category name.",
        "ENTERED_NAME_EXIST" : "Entered name already exists.",
        "ENTER_VALID_CUSTOM_TYPE" : "Please enter a valid custom type.",
        "ENTER_VALID_CUSTOM_NAME" : "Enter a valid custom name.",
        "CONTENT_TYPE_NOT_BLANK" : "Please enter a valid name.",
        "ENTER_VALID_NAME" : "Please enter a valid name.",
        "CHECK_BUDGET_TYPE" : " Please check budget type",
        "SELECT_VALID_BUDGET_AMOUNT" : "Budget amount can only contain numbers and cannot exceed $999,999,999.",
        "EMPTY_BUDGET_AMOUNT" : "You cannot leave a budget amount empty.",
        "BUDGET_AMOUNT_LESS" : "Total sum does not equal the budget amount. Please update your budget.",
        "BUDGET_AMOUNT_UPDATED" : "Budget amount updated...",
        "INVALID_SEGMENT_NAME" : "Please enter segment name.",

        // Goal validations
        "GOAL_NAME_REQUIRED" : "Please enter goal name.",
        "GOAL_MODE_REQUIRED" : "Please select goal current status.",
        "GOAL_INVALID_NAME" : "Valid names may include: A-z, 0-9, _@!*$,./#&+-'[]():|",
        "SOMETHING_WENT_WRONG" : "Something went wrong.",
        "METRIC_ADD_ERROR" : "Please fill previous metric value.",
        "NO_LOGS_FOUND" : "There has been no update on this Goal.",
        "GOALS_NOT_FOUND" : "There has been no update on goals",
        "GOAL_EXIST" : "This name has already been used. Assign a different name to your Goal.",
        "GOAL_LIST_EMPTY" : "There is no Goal available for the selected timeframe.",
        "CHOOSE_GOAL_TIMEFRAME" : "Choose your goal's timeframe.",
        "METRICS_NOT_FOUND" : "<h6>No Metrics Specified.</h6> Metrics are critical to the ongoing measurement and progress of goals. - You can assign metrics while creating/updating the goal.",
        "METRICS_NOT_FOUND_MODAL" : "<h6>No Metrics Specified.</h6> Metrics are critical to the ongoing measurement and progress of goals. Click UPDATE above to add some.",

        // Campaign validations
        "CAMPAIGN_NAME_REQUIRED" : "Please enter campaign name.",
        "CAMPAIGN_INVALID_NAME" : "Valid names may include: A-z, 0-9, _@!*$,./#&+-'[]():|",
        "CAMPAIGN_MODE_REQUIRED" : "Please select campaign current status.",
        "CAMPAIGN_BUDGET_REQUIRED" : "Please enter your Campaign’s budget.",
        "INAVLID_BUDGET" : "Please enter a valid budget amount.",
        "CAMPAGINS_NOT_FOUND" : "There has been no update on campagins",
        "PROGRAM_NOT_CREATED_FOR_THIS_CAMPAIGN" : "There are no associated Expense Groups.",
        "EXPENSE_NOT_CREATED_FOR_THIS_CAMPAIGN" : "There is no expense created for this campaign.",
        "CAMPAIGN_NAME_EXIST" : "This name has already been used. Assign a different name to your Campaign.",
        "CAMPAIGN_LIST_EMPTY" : "There is no Campaign available for the selected timeframe.",
        "CHOOSE_CAMPAIGN_TIMEFRAME" : "Choose your campaign's timeframe.",

        // Programs validations
        "PROGRAM_NAME_REQUIRED" : "Please enter expense group name.",
        "PROGRAM_INVALID_NAME" : "Valid names may include: A-z, 0-9, _@!*$,./#&+-'[]():|",
        "PROGRAM_MODE_REQUIRED" : "Please select expense group current status.",
        "PROGRAM_BUDGET_REQUIRED" : "Please enter your Expense Group’s budget",
        "PROGRAM_BUDGET_INVALID" : "Please enter a valid budget amount.",
        "PROGRAM_GOAL_REQUIRED" : "Please select goal.",
        "PROGRAM_EXISTS" : "This name has already been used. Assign a different name to your Expense group.",
        "PROGRAMS_NOT_FOUND" : "There has been no update on campagins",
        "PROGRAM_LIST_EMPTY" : "There is no Expense groups available for the selected timeframe.",
        "CHOOSE_PROGRAM_TIMEFRAME" : "Choose your expense group's timeframe.",

        // Expense validations
        "EXPENSE_NOT_FOUND" : "There has been no update on expense",
        "EXPENSE_INVALID_NAME" : "Valid names may include: A-z, 0-9, _@!*$,./#&+-'[]():|",
        "EXPENSE_NOT_CREATED_FOR_PROG" : "There are no associated expenses.",
        "EXPENSE_NAME_REQUIRED" : "Please enter expense name",
        "EXPENSE_MODE_REQUIRED" : "Please enter expense mode",
        "EXPENSE_TYPE_REQUIRED" : "Please enter expense type",
        "VENDOR_NAME_REQUIRED" : "Please enter vendor name",
        "INVALID_VENDOR_NAME" : "Please enter a valid name",
        "PO_NUMBER_REQUIRED" : "Please enter po number",
        "INVALID_PO_NUMBER" : "Please enter a valid PO number",
        "INVALID_EXPENSE_AMOUNT" : "Please enter a valid expense amount",
        "EXPENSE_AMOUNT_REQUIRED" : "Please enter expense amount",
        "EXPENSE_AMOUNT_UNEQUAL" : "Sum of expense budget entered for selected timeframe should equal the expense budget.",
        "AMOUNT_UNEQUAL_CONF_EXPENSE" : "The sum of amounts entered in each timeframe does not add up to your expense budget. Do you wish to continue? It will update your expense budget from",
        "AMOUNT_UNEQUAL_CONF_PROGRAM" : "The sum of amounts entered in each timeframe does not add up to your expense group budget. Do you wish to continue? It will update your expense group budget from",
        "AMOUNT_UNEQUAL_CONF_CAMPAIGN" : "The sum of amounts entered in each timeframe does not add up to your campaign budget. Do you wish to continue? It will update your campaign budget from",
        "CHOOSE_EXPENSE_TIMEFRAME" : "Choose your expense's timeframe.",
        "CHOOSE_EXPENSE_TIMEFRAME_STATUS" : "Choose your expense's timeframe status.",
        "CHOOSE_PROGRAM_TIMEFRAME_STATUS" : "Choose your expense group's timeframe status.",
        "CHOOSE_CAMPAIGN_TIMEFRAME_STATUS" : "Choose your campaign's timeframe status.",
        "EXPENSE_NAME_EXIST" : "This name has already been used. Assign a different name to your Expense.",

        // User
        "USER_NAME_REQUIRED" : "Please enter user name.",
        "USER_NAME_MIN_LENGTH": "Full name should have at least 2 characters in length.",
        "USER_NAME_MAX_LENGTH" : "Full name can't exceed by 45 characters in length",
        "CURRENT_PASSWORD_REQUIRED" : "Please enter current password.",
        "NEW_PASSWORD_REQUIRED" : "Please enter new password.",
        "CONFIRM_PASSWORD_REQUIRED" : "Please enter confirm password.",
        "CONFIRM_PASSWORD_ERROR" : "Password do not match.",

        //Dashboahrd
        "CREATE_BUDGET_FIRST" : "You do not have access to any budgets in this company. Please contact your admin or account owner for access to company budgets.",
        "NO_BUDGET_FOUND": "There is no Budget available in this company.",

        // Commons
        "SAME_CUSTOM_NAME_METRIC" : "You cannot assign the same metric more than once.",
        "GOAL_NOT_AVAILABLE" : "There is no Goal available for the selected timeframe",

        // Manage Plan Setting
        "BUDGET_DELETED" : "Budget Deleted",
        "DELETE_BUDGET_CONFIRMATION" : `
          Are you sure you want to delete this budget? If you continue then <b>everything</b> related to this budget will be permanently removed.
          That includes budget information, expenses, campaigns, metrics ... everything.<br><br>
          You cannot undo this action, so please be very sure.
        `,
        "INVALID_DATE":"Invalid Date",
        "INVALID_SEGMENT":"Invalid segment",

        "BUDGET_PERMISSION_REQUIRED" : "To provide access you need to select permission and budget.",
        "BUDGET_PERMISSION_REQUIRED_REQUEST" : "In order to request permission for a budget, you need to select options from all division tabs in that budget.",
        "SELECT_ANOTHER_BUDGET" : "You have already assigned permission for this budget. Please select another budget.",
        "BUDGET_PERMISSION_EMPTY" : "You cannot leave a budget permission empty.",
        "NOT_AVAILABLE" : "Not Available",

        "DELETE_USER": "Are you sure you want to delete this user from team? This user will not be able to access your company.",
        "CAN_NOT_SELECT_BUDGET": "You cannot assign permission on this budget as it is not complete. Please select another budget ",
        "UPLOAD_EXPENSE_DATA_NOT_FOUND": "There is no record.",

        "TYPE_DELETE": "Are you sure? Removing this segment will delete it from all other divisions of the budget.",
        "CLOSE_CREATE_TAB": "If you close this tab, you will lose your progress.",
        "BACK_WARNING": "Warning: going back will not save any data you entered on this screen. Are you sure you want to go back?",



        "EXPENSE_TYPE_NAME_REQUIRED" : "Please enter expense type name.",
        "EXPENSE_TYPE_ALREADY_EXIST" : "This expense type already exists. Please select another name.",
        "CUSTOM_EXPENSE_TYPE_WARNING" : "There are some 'custom' expense type being created due to Import. If you choose to proceed further, all those expenses will be mapped to type 'Other'. Click 'Ok' to continue and 'Cancel' to cancel 'Import'.",
        "DELETE_EXPENSE_TYPE_WARNING" : "If you delete this custom expense type, all expenses of this type will be mapped to type 'Other'. Do you wish to continue?",
        "IMPORT_CANCEL_WARNING" : "Are you sure you want to cancel import? No expenses will be added.",

        "DELETE_SEGMENT_CONFIRMATION" : "Are you sure, you want to delete this segment from the Budget?",

        // subscription detail
        "ADDITIONAL_USER_MIN_VALUE": "Additional user cannot have value less than 1.",
        "ADDITIONAL_USER_MAX_VALUE": "Additional user cannot have value more than 999.",
        "ADDITIONAL_USER_PATTERN" : "Additional user can have numeric value only.",

        "FIRST_NAME_REQUIRED": "Please enter first name.",
        "FIRST_NAME_MIN_LENGTH": "First name should have at least 2 characters in length.",
        "FIRST_NAME_MAX_LENGTH": "First name can't exceed by 30 characters in length.",
        "FIRST_NAME_ONLY_CHARS": "Valid first name may include: [A-z], ['-]",

        "LAST_NAME_REQUIRED": "Please enter last name.",
        "LAST_NAME_MIN_LENGTH": "Last name should have at least 2 characters in length.",
        "LAST_NAME_MAX_LENGHT": "Last name can't exceed by 30 characters in length.",
        "LAST_NAME_ONLY_CHARS": "Valid last name may include: [A-z], ['-]",

        "EMAIL_ID_REQUIRED": "Please enter email.",
        "EMAIL_INCORRECT_FORMAT": "Please enter a valid email address.",
        "EMAIL_MAX_LENGTH": "Email can't exceed 100 characters.",

        "MOBILE_NUMBER_REQUIRED": "Please enter phone number.",
        "MOBILE_NUMBER_MIN_LENGTH": "Phone number should be minimum 6 characters in length.",
        "MOBILE_NUMBER_MAX_LENGTH": "Phone number can't exceed by 10 numbers in length.",
        "MOBILE_NUMBER_PATTERN": "Phone number should be 10-15 characters in length and includes: [0-9]",

        "ADDRESS_REQUIRED": "Please enter address.",
        "ADDRESS_MAX_LENGTH": "Address can't exceed 50 characters in length.",
        "ADDRESS_PATTERN": "Valid address may include: [A-z], [0-9], [ _.,/-#@() ]",

        "CITY_REQUIRED": "Please enter city.",
        "CITY_MAX_LENGTH": "City can't exceed 20 characters in length.",
        "CITY_PATTERN": "Valid city may include: [A-z], [ _.,/-#@() ]",

        "STATE_REQUIRED": "Please enter state.",
        "STATE_MIN_LENGTH": "State should have at least 2 numbers in length.",
        "STATE_MAX_LENGTH": "State can't exceed by 50 numbers in length.",
        "STATE_PATTERN": "Valid state may include: [A-z], [ _.,/-#@() ]",

        "PIN_CODE_REQUIRED": "Please enter zip code.",
        "PIN_CODE_MIN_LENGTH": "Zipcode should be minimum 3 numbers in length.",
        "PIN_CODE_MAX_LENGTH": "Zipcode can't exceed by 8 numbers in length.",
        "PIN_CODE_PATTERN": "Zip code should be 2-10 characters in length and may include:[A-z], [0-9], [-].",

        "COUNTRY_REQUIRED": "Please select a country.",

        "CARD_NUMBER_REQUIRED": "Please enter card number.",
        "CARD_NUMBER_MIN_LENGTH": "Card number should have at least 14 numbers in length.",
        "CARD_NUMBER_MAX_LENGTH": "Card number can't exceed by 19 numbers in length.",
        "CARD_NUMBER_PATTERN": "Please enter a valid card number. It will be between 13-19 characters in length.",

        "MONTH_REQUIRED": "Please select month.",

        "YEAR_REQUIRED": "Please select year.",

        "CVV_NUMBER_REQUIRED": "Please enter cvv number.",
        "CVV_NUMBER_MIN_LENGTH": "Cvv number should have at least 3 numbers in length.",
        "CVV_NUMBER_MAX_LENGTH": "Cvv number can't exceed by 4 numbers in length.",
        "CVV_NUMBER_PATTERN": "CVV number should be 3-4 characters in length and includes: [0-9].",

        "NAME_ON_ACCOUNT_REQUIRED" : "Please enter a name on the account.",

        "ROUTING_NUMBER_REQUIRED" : "Please enter the routing number.",
        "ROUTING_NUMBER_PATTERN": "Valid routing number may include: [0-9]",

        "ACCOUNT_TYPE_REQUIRED" : "Please select the account type.",

        "ACCOUNT_NUMBER_REQUIRED" : "Please enter the account number.",
        "ACCOUNT_NUMBER_PATTERN" : "Valid state may include: [A-z], [0-9]",
        "ACCOUNT_NUMBER_NOT_MATCH" : "Account number do not match",

        "LOSE_OTHER_PAYMENT_INFO" : "Proceed with this payment information?",

        "BILLING_OPTION_REQUIRED": "Please select billing interval.",
        "CANCEL_SUBSCRIPTION" : "Are you sure, you want to cancel Pro subscription?  Your company will be downgraded to the Basic Plan.",
        "GOAL_TYPE_REQUIRED": "Please select goal type.",
        "CUSTOM_GOAL_TYPE_REQUIRED": "Plesae enter custom goal type",
        "INVALID_CUSTOM_GOAL_TYPE": "Please enter a valid goal type.",
        "CLEAR_FILTER_SUCCESS": "You have cleared all the filter selections.",
        "PROGRAMS_NOT_AVAILABLE": "You don’t have any Expense Groups.",
        "PROGRAMS_NOT_AVAILABLE_FILTER": "There are no expense groups available for the selected filters.",
        "CAMPAIGNS_NOT_AVAILABLE": "You don’t have any Campaigns.",
        "CAMPAIGNS_NOT_AVAILABLE_FILTER": "There are no campaigns available for the selected filters.",
        "GOALS_NOT_AVAILABLE_FILTER": "There are no goals available for the selected filters.",
        "GOALS_NOT_AVAILABLE": "You don’t have any Goals.",
        "EXPENSES_NOT_AVAILABLE": "You don’t have any Expenses.",
        "EXPENSES_NOT_AVAILABLE_FILTER": "There are no expenses available for the selected filters.",
        "UNLOCK_TIMEFRAME_WARNING": "You did not balance out your budget and had locked some timeframes. Reopen timeframes to adjust amounts.",
        "CAMPAIGN_TYPE_REQUIRED": "Please select campaign type",
        "CUSTOM_CAMPAIGN_TYPE_REQUIRED": "Plesae enter custom campaign type",
        "INVALID_CUSTOM_CAMPAIGN_TYPE": "Please enter a valid campaign type.",
        "PROGRAM_TYPE_REQUIRED": "Please select expense group type.",
        "CUSTOM_PROGRAM_TYPE_REQUIRED": "Plesae enter custom expense group type",
        "INVALID_CUSTOM_PROGRAM_TYPE": "Please enter a valid expense group type.",
        "DELETE_LAST_SEGMENT_WARNING": "You cannot delete the last segment from your budget.",
        "CHANGE_TIMEFRAME": "You have changed your budget timeframe. Balance your budget amounts and click on Finish button to commit these changes.",
        "DELETE_SEGMENT_WARNING": "Can't delete segment. You must first zero out its allocated budget.",
        "GL_CODE_NOT_AVAILABLE": "No GL codes have been specified. Click +ADD button to get started.",
        "GL_CODE_REQUIRED": "Plesae enter GL code.",
        "GL_CODE_ALREADY_EXIST": "This GL code already exists. Please select another name.",
        "GL_CODE_DESCRIPTION_REQUIRED": "Plesae enter GL code description.",
        "NO_FILE_SELECTED" : "No file selected",
        "FILE_NAME_VALIDATION_ERROR": "Valid file name may include: [A-z], [0-9], [ _ - . ( ) [ ] ]",
        "NO_BUDGET_EXISTS" : "You do not have access to add read or write user. You need to add a budget in the company in order to do so.",
        "NO_RESULT_FOUND": "No result found.",
        "NO_GLCODE_EXISTS": "You cannot assign a GL Code to this expenses as there are no GL Codes added for this company.",
        "GOALS_NOT_CONFIGURED": "You haven't configured any goals.",
        "NO_METRIC_WITH_CAMPAIGN":"There is no metric associated with this campaign.",
        "NO_CAMPAIGN_FOR_GOAL": "There is no campaign created for this Goal.",
        "NO_EXPENSE_BUCKET_FOR_GOAL": "There are no associated Expense Groups.",
        "NO_EXPENSE_FOR_GOAL": "There is no expense created for this Goal.",
        "LAST_ACTIVE_BUDGET": "You cannot delete last budget of the account.",
        "SELECT_ATLEAST_ONE_EXPENSE" : 'Please select atleast one expense.',
        "DELETE_EXPENSE_WARNING": "Are you sure you want to delete selected expense(s). They will be removed permanently from your budget. ",
        "SERVER_CONNECTION_LOST": "Server Connection Lost",
        "NO_ACCESS_TO_SEAT_PURCHASE" : "You do not have permission to purchase additional user seats in the Pro Plan. Please contact your account owner.",
        "SELECT_VALID_FILE" : "Invalid file! Please select only CSV, XLS & XLSX files.",
        "INVALID_VERIFICATION_CODE": "Verification code invalid.",
        "CANNOT_ASSIGN_CUSTOM_TYPE_AS_CUSTOM" : "You cannot assign custom type name as custom.",
        "CAMPAIGN_TYPE_ALREADY_EXIST" : "This campaign type already exists. Please select another name.",
        "CANNOT_ASSIGN_SAME_METRIC" : "You cannot assign the same metric twice.",
        "GOAL_TYPE_ALREADY_EXIST" : "This goal type already exists. Please select another name.",
        "EXPENSE_BUCKET_TYPE_ALREADY_EXIST" : "This expense groups type already exists. Please select another name.",
        "DELETE_EXPENSE" : "Are you sure you want to delete this expense?",
        "DELETE_GOAL" : "Are you sure, you want to delete this Goal?",
        "DELETE_EXPENSE_BUCKET" : "Are you sure, you want to delete this expense group? ",
        "DELETE_GL_CODE" : "Are you sure, you want to delete this GL Code? ",
        "DELETE_CAMPAIGN" : "Are you sure, you want to delete this Campaign? ",
        "INVALID_COUPON_CODE": "Coupon Code is invalid",
        "HIGHER_DISCOUNT_AMOUNT" : "This coupon code is not applicable as discount amount is greater than total amount.",
        "ALPHANUMERIC_CHARS_ONLY" : "Alphanumeric characters allowed only.",
        "ACCOUNT_CODE_REQUIRED" : "Account code required.",
        "SUBSCRIPTION_CODE_REQUIRED" : "Subscription code required.",
        "COMPANY_REQUIRED" : "Company field required.",
        "CANCEL_TRIAL_CONFIRMATION": "Canceling your trial will immediately disable all additional users and budgets. Are you sure you want to do this?",
        "CLONE_BUDGET_CONFIRMATION": "Create an identical copy of this budget? The resulting copy includes all related Goals, Campaigns, Expense Groups, and Expenses along with its access permissions.",
        "DELETE_SUPERADMIN_CONFIRM": "Are you sure, you want to delete this Superadmin ?",
        "SAME_BUDGET_SELECTED": "Source and destination company cannot be same.",
        "USER_TYPE_REQUIRED": "User type field is required.",
        "NO_BUDGET_IN_COMPANY": "The source account doesn't have any budgets which can be moved.",
        "SOURCE_EXPENSE_DELETE": "Do you want to delete the Source Expense? Click 'OK' to delete the Source Expense or 'Cancel' to keep both objects.",
        "CONFIRM_CLONE_OBJECT": "Create an identical copy of this object?",
        "CANNOT_CLONE_OBJECT": "You cannot clone an object in the locked timeframe.",

        // currency tab
        "CONFIRM_REMOVING_COMPANY_CURRENCY": "Are you sure you want to delete selected currency? It will be removed permanently from your company.",

        // Tags
        "TAG_EXIST" : "This tag name has already been used. Please enter another name.",
        "TAGS_LIST_EMPTY": "No tags have been specified. Click +ADD button to get started.",
        "SAVED_FILTERS_EMPTY": "No saved filters have been specified.",
        "TAG_NAME_REQUIRED": 'Tag name is required',
        "TAG_NAME_MAX_LENGTH_REACHED": 'Tag name length should be less then 45 symbols',

        // plan tab
        "SHARED_COST_CAMPAIGN_FILTERED_BY_SEGMENT": 'This is part of a shared Campaign and can’t be edited in a filtered view.\nIt can only be edited by the Campaign owner with all filters removed',
        "SHARED_COST_PROGRAM_FILTERED_BY_SEGMENT": 'This is part of a shared Expense group and can’t be edited in a filtered view.\nIt can only be edited by the Expense group owner with all filters removed',

        // functionality limited by plan
        "NO_ACCESS_BY_PLAN": 'You need to upgrade your account to access this feature',
        "COMMON_MAX_LENGTH": (maxLength: number) => `This field can't be longer than ${ maxLength } characters!`,
    }
}
