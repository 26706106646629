import { Component, Input } from '@angular/core';

@Component({
  selector: 'details-widget-title',
  templateUrl: './details-widget-title.component.html',
  styleUrls: ['./details-widget-title.component.scss']
})
export class DetailsWidgetTitleComponent {
  @Input() text = '';
  @Input() secondaryText = '';
  @Input() count = 0;
  @Input() showCount = true;
  @Input() hideIcon = false;
  @Input() showTooltip = false;
  @Input() tooltipValue = '';
  @Input() type = '';
  @Input() isProgressTargetWidget = false;
}
