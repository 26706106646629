<manage-table-segment-breakdown
  [allocatedOnly]="allocatedOnly"
>
  <ng-template #segment>
    <manage-table-plain-value
      [value]="segmentValue"
      [isBold]="isBold"
    ></manage-table-plain-value>
  </ng-template>
  <ng-template #allocated>
    <manage-table-plain-value
      [value]="allocatedValue"
      [isBold]="isBold"
      [isClickable]="isAllocatedClickable"
      (onClick)="onAllocatedClick.emit()"
    ></manage-table-plain-value>
  </ng-template>
  <ng-template #unallocated>
    <manage-table-plain-value
      [isBold]="isBold"
      [value]="unallocatedValue"
      [withNullishPlaceholder]="withNullishPlaceholder"
    ></manage-table-plain-value>
  </ng-template>
</manage-table-segment-breakdown>
