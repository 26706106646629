export enum IMPORT_DATA_TYPE {
  CAMPAIGNS = 'campaigns',
  BUDGETS = 'budgets',
  EXPENSES = 'expenses',
  EXPENSE_GROUPS = 'expense groups',
  METRICS = 'metrics',
  CAMPAIGN_TYPES = 'campaign types',
  EXPENSE_GROUP_TYPES = 'expense group types',
  OBJECT_TYPES = 'object types',
  EXPENSE_TYPES = 'expense types',
  GL_CODES = 'gl codes',
}

