export enum ExternalMetricType {
  int = 'int',
  double = 'double',
  currency = 'currency'
}

export interface ExternalMetric {
  type: ExternalMetricType;
  name: string;
  label: string;
  description?: string;
}

export interface ExternalMetricName {
  name: string;
  label: string
}

export type ExternalMetrics = ExternalMetric[];

export enum AdsMetricTypeNames {
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  LEADS = 'leads',
  CONVERSIONS = 'conversions',
  REACH = 'reach',
}
