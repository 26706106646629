import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export function runSequentially(sourceRequests: Observable<any>[]): Observable<any[]> {
  const results = [];
  const sequence = sourceRequests && sourceRequests.length ?
    sourceRequests.reduce(
      (lastRequest: Observable<any>, request: Observable<any>) =>
        lastRequest ?
          lastRequest.pipe(
            switchMap(
              () => request.pipe(tap(data => results.push(data)))
            )
          ) :
          request.pipe(tap(data => results.push(data))),
      null
    ) :
    of([]);
  return sequence.pipe(map(() => results));
}
