<form class="add-tag modal-form" (ngSubmit)="submitForm(tagNameControl.value)" novalidate>
  <header mat-dialog-title>Create new tag</header>
  <div mat-dialog-content class="dialog-info">
    <mat-form-field appearance="outline" class="p-form-field-outline">
      <mat-label>Tag name <span class="required-field">&#42;</span></mat-label>
      <input type="text" matInput [formControl]="tagNameControl" [maxlength]="MAX_TAG_NAME_LENGTH" trim>
    </mat-form-field>
    <mat-error *ngIf="!uniqueTagName">
      {{ validations.ValiditionMessages.TAG_EXIST }}
    </mat-error>
  </div>
  <div mat-dialog-actions align="end" class="dialog-actions">
    <button type="button" mat-button color="primary" mat-dialog-close>Close</button>
    <button type="submit" mat-flat-button color="primary" [disabled]="tagNameControl.invalid || uniqueTagName === false">Finish</button>
  </div>
</form>
