import { ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { COLORS_MAP } from '@shared/constants/colors.constants';
import { TooltipContext } from '@shared/directives/dynamic-portal-tooltip.directive';
import { MetricProgressState } from '@shared/types/metric-progress-state.type';
import { Metric } from 'app/budget-object-details/components/details-metrics/details-metrics.type';
import { keyMetricTooltip } from 'app/budget-object-details/components/metrics/constants';

@Component({
  selector: 'details-metric-control-card',
  templateUrl: './details-metric-control-card.component.html',
  styleUrls: ['./details-metric-control-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsMetricControlCardComponent {
  @Input() isKeyMetric = true;
  @Input() keyMetricSelection = true;
  @Input() metric: Metric;
  @Input() targetValue: number;
  @Input() currentValue: number;
  @Input() currentCPO: number;
  @Input() targetCPO: number;
  @Input() currency: string;
  @Input() targetROI: number;
  @Input() currentROI: number;
  @Input() estimatedCampaignValue: number;
  @Input() targetCampaignValue: number;
  @Input() progressState: MetricProgressState;
  @Input() estimatedDiffPercentage: number;
  @Input() actualSpend: number;
  @Input() objectBudget: number;

  @Output() onKeyMetricChange = new EventEmitter<boolean>();

  public keyMetricTooltip = keyMetricTooltip;
  public readonly colorsMap = COLORS_MAP;
  public decimalDisplayFormat = '1.2-2';
  public numberDisplayFormat = '1.0-2';
  public tooltipContext: TooltipContext = {
    header: 'Key Metric',
    body: keyMetricTooltip,
    icon: ['fas', 'heart']
  };
  public tooltipPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
  }

  public handleMetricChange(isKeyMetric, event: Event): void {
    event.stopPropagation();
    this.onKeyMetricChange.emit(isKeyMetric);
  }
}
