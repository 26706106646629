<div class="manage-table-container">
  <loader [isVisible]="isLoading$ | async"></loader>
  <div class="top-bar">
    <ng-container>
      <table-configuration
        *ngIf="budgetDisplayMode === displayMode.Table && dataMode === dataModes.Budget"
        [viewMode]="viewMode"
        [tableConfig]="manageTableDataConfig"
        (onTableConfigChange)="setConfiguration($event)"
      ></table-configuration>
      <div class="table-mode-switch">
        <table-mode-switch
          [dataMode]="dataMode"
          [viewMode]="viewMode"
          (onViewModeChange)="handleViewModeChange($event)"
        ></table-mode-switch>
      </div>
      <div class="d-flex ml-a mr-1"
        *ngIf="presentationTimeframeOptions?.length > 1 && dataMode === dataModes.Budget">
        <tab-switch
          [squareMode]="true"
          [options]="presentationTimeframeOptions"
          [selectedValue]="presentationTimeframeMode$ | async"
          (onChange)="setPresentationTimeframe($event)"
        ></tab-switch>
        <timeframe-configuration
          class="ml-05 mr-05"
          [selectedTimeframeMode]="presentationTimeframeMode$ | async"
          [budgetEndDate]="budgetEndDate"
          [budgetTimeframes]="budgetDataService.timeframeList$ | async"
          [selectedTimeframes]="presentationTimeframeIds$ | async"
          (onTimeframeConfigChange)="setTimeframeConfiguration($event)"
        ></timeframe-configuration>
      </div>
      <table-mode-switch
        *ngIf="dataMode === dataModes.Budget"
        [isBudgetMode]="true"
        [viewMode]="budgetDisplayMode"
        (onBudgetModeChange)="handleBudgetDisplayModeChange($event)"
      ></table-mode-switch>
    </ng-container>
  </div>

  <div class="table-wrap" [ngClass]="{ 'hidden-table': budgetDisplayMode !== displayMode.Table }">
    <div [ngClass]="(dataMode === dataModes.Budget) ? 'secondary-bar' : 'secondary-bar perf-bar'">
      <div class="actions"  *ngIf="dataMode === dataModes.Budget">
        <button
          class="action-item"
          type="button"
          mat-flat-button
          color="primary"
          [matMenuTriggerFor]="createMenu"
        >
          Create...
        </button>
        <mat-menu
          #createMenu="matMenu"
          class="mat-pl-menu manage-plan">
          <button
            mat-menu-item
            *ngFor="let item of createMenuOptions"
            (click)="item.action()"
          >
            <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
            <span>{{ item.label }}</span>
          </button>
        </mat-menu>

        <manage-ceg-action-menu
          *ngIf="dataMode === dataModes.Budget"
          class="action-item"
          [budgetObjectTypes]="budgetObjectTypes"
        ></manage-ceg-action-menu>
      </div>
      <div class="undo-action"
           *ngIf="dataMode === dataModes.Budget">
        <button
          class="undo-button"
          mat-icon-button
          [disableRipple]="true"
          [disabled]="!historySize"
          (click)="handleUndoClick()"
        >
          <fa-icon [icon]="['fas', 'undo']"></fa-icon>
          Undo
        </button>
      </div>
    </div>
    <totals-toggler
      *ngIf="dataMode === dataModes.Budget"
      [disabled]="isLoading$ | async"
      [openingState]="totalsSidebarState"
      (openingStateChange)="totalsSidebarState = $event"></totals-toggler>
    <manage-table-ceg
    [budgetColumns]="budgetColumns"
      [timeframes]="budgetTimeframes$ | async"
      [isAdmin]="isAdmin"
      [editPermission]="editPermission"
      [rows]="rowsData"
      [performanceColumnData]="performanceColumnData"
      [hasHiddenHierarchy]="hasHiddenHierarchy"
      [isFilteredMode]="isFilteredMode"
      [allRootRowsLoaded]="allRootRowsLoaded"
      [grandTotalBudget]="grandTotalBudget$ | async"
      [grandTotalPerformance]="grandTotalPerformance$ | async"
      [iconByRowType]="iconByRowType"
      [routeActionByRowType]="routeActionByRowType"
      [dataMode]="dataMode"
      [totalsSidebarState]="totalsSidebarState"
      [tableViewConfig]="tableViewConfig$ | async"
      [objectTypeNameMap]="objectTypeNameMap"
      (totalsSidebarStateChanged)="totalsSidebarState = $event"
      (onCreateNewItemTemplate)="createNewItemTemplate($event)"
      (onCreateItemFromTemplate)="createItemFromTemplate($event)"
    ></manage-table-ceg>
  </div>
  <ng-container *ngIf="{
                selectedBudget:budgetDataService.selectedBudget$ | async,
                timelineGraphData: timelineGraphData$ | async } as data">
    <div *ngIf="budgetDisplayMode === displayMode.Chart" @slideFromRightChart>
        <budget-ceg-timeline-container
          [data]="data.timelineGraphData"
          [viewMode]="viewMode"
          [selectedBudget]="data.selectedBudget"
          [companyCurrency]="companyCurrency$ | async">
        </budget-ceg-timeline-container>
    </div>
  </ng-container>
</div>
