import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AttachmentsService } from 'app/shared/services/backend/attachments.service';
import { Attachment, AttachmentMappingDO, AttachmentScanStatus, AttachmentsObjectContext } from 'app/shared/types/attachment.interface';
import { DIALOG_ACTION_TYPE } from 'app/shared/types/dialog-context.interface';
import { dialogConfig as malwareDetectedDialogConfig } from '../components/malware-detected-dialog/dialog-config';
import { MalwareDetectedDialogComponent } from '../components/malware-detected-dialog/malware-detected-dialog.component';
import { MalwareDetectedDialogContext } from '../components/malware-detected-dialog/malware-detected-dialog.type';

/**
 * Shared service for handling common attachments related logic on details pages
 */
@Injectable({
  providedIn: 'root'
})
export class BudgetObjectAttachmentsService {
  private _attachments: Attachment[] = [];
  private pollingIntervalRef;
  private pollingInterval = 5000;
  private maxPollingAttempts = 24;
  private currentPollingAttempt = 0;
  private readonly cdrTrigger = new Subject<void>();
  public cdrTrigger$ = this.cdrTrigger.asObservable();
  private context: AttachmentsObjectContext = {
    companyId: null,
    objectId: null,
    objectType: null
  };
  private itemsUnderReview = new Set<string>();

  get attachments(): Attachment[] {
    return this._attachments;
  }

  set attachments(attachments: Attachment[]) {
    this._attachments = attachments;
  }

  constructor(
    private readonly attachmentsService: AttachmentsService,
    private readonly matDialog: MatDialog,
  ) {}

  private showMalwareFoundDialog(fileNames: string[]) {
    if (!fileNames.length) {
      return;
    }

    const dialogContextData: MalwareDetectedDialogContext = {
      title: 'Virus detected',
      actions: [{
        label: 'OK',
        type: DIALOG_ACTION_TYPE.FLAT,
        handler: null
      }],
      fileNames
    };
    const dialogConfig: MatDialogConfig = {
      ...malwareDetectedDialogConfig,
      data: dialogContextData
    };
    this.matDialog.open(MalwareDetectedDialogComponent, dialogConfig);
  }

  private deleteMapping(attachment: Attachment) {
    this.attachmentsService.deleteAttachmentMapping(attachment.id, this.context)
      .subscribe({
        error: () => console.error('Failed to delete attachment mapping')
      });
  }

  private stopScanPolling() {
    clearInterval(this.pollingIntervalRef);
    this.currentPollingAttempt = 0;
    this.cdrTrigger.next();
  }

  private startScanPolling() {
    const malwares = [];
    const processScanResultItem = (item) => {
      const scanningFailed = item.scanStatus === AttachmentScanStatus.ScanningFailed;
      const isMalware = item.scanStatus === AttachmentScanStatus.MalwareDetected;
      const shouldRemoveAttachment = scanningFailed || isMalware;
      const targetIndex = this.attachments.findIndex(attachment => attachment.key === item.key);
      const targetAttachment = this.attachments[targetIndex];
      if (targetAttachment) {
        targetAttachment.isUnderReview = false;
      }
      if (shouldRemoveAttachment) {
        this.attachments.splice(targetIndex, 1);
        this.deleteMapping(targetAttachment);
      }
      if (isMalware) {
        malwares.push(item.meta?.filename);
      }
      this.itemsUnderReview.delete(item.key);
    };

    this.stopScanPolling();
    this.pollingIntervalRef = setInterval(() => {
      this.attachmentsService.getScanResults(Array.from(this.itemsUnderReview), this.context.companyId)
        .subscribe(
          (data) => {
            if (!data) {
              this.stopScanPolling();
              return;
            }
            this.currentPollingAttempt++;
            data.forEach(processScanResultItem);
            if (!this.itemsUnderReview.size || this.currentPollingAttempt > this.maxPollingAttempts) {
              this.stopScanPolling();
              this.showMalwareFoundDialog(malwares);
            }
          }
        )
    }, this.pollingInterval);
  }

  public setObjectContext(context: AttachmentsObjectContext) {
    this.context = context;
  }

  public loadAttachments(mappings: AttachmentMappingDO[]) {
    return this.attachmentsService.getFilesMetaData(mappings, this.context.companyId)
      .pipe(
        tap(attachments => {
          this.attachments = attachments
          this.cdrTrigger.next();
        })
      );
  }

  public uploadFiles(fileList: FileList): Observable<Attachment[]> {
    return this.attachmentsService.uploadFiles(fileList, this.context)
      .pipe(
        tap((attachments) => {
          if (!Array.isArray(attachments)) {
            return;
          }

          const processedAttachments = attachments.map(item => {
            this.itemsUnderReview.add(item.key);
            return {
              ...item,
              isUnderReview: true
            };
          });
          this.attachments.push(...processedAttachments);
          this.cdrTrigger.next();
          this.startScanPolling();
        }))
  }

  public deleteFile(attachment: Attachment) {
    attachment.isProcessing = true;
    return this.attachmentsService.deleteFile(attachment, this.context)
      .pipe(
        tap((result) => {
          attachment.isProcessing = false;
          if (!result) { return; }
          const deletedIndex = this.attachments.findIndex(item => item.key === attachment.key);
          if (deletedIndex >= 0) {
            this.attachments.splice(deletedIndex, 1);
            this.cdrTrigger.next();
          }
        })
      );
  }

  public downloadFile(attachment: Attachment) {
    attachment.isProcessing = true;
    return this.attachmentsService.downloadFile(attachment, this.context.companyId, false)
      .pipe(
        tap(() => {
          attachment.isProcessing = false;
        })
      );
  }

  public getFileUrl(attachment: Attachment): Observable<string> {
    attachment.isProcessing = true;
    return this.attachmentsService.downloadFile(attachment, this.context.companyId, true)
      .pipe(
        tap(() => {
          attachment.isProcessing = false;
        })
      )
  }
}
