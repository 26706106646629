import { AllocationsTableColumnType } from '../allocations-table/allocations-table.type';
import { BudgetAllocationsTableColumn } from './budget-allocations-table.type';

export const columns = {
  [BudgetAllocationsTableColumn.Allocated]: {
    title: 'Allocated Budget',
    type: AllocationsTableColumnType.AllocatedBudget,
    className: 'allocated',
  },
  [BudgetAllocationsTableColumn.Expenses]: {
    title: 'Expenses',
    type: AllocationsTableColumnType.PlainText,
    className: 'expenses',
  },
};
