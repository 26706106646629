import { Component, Inject } from '@angular/core';
import { HierarchySelectConfig, HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HierarchyDialogContext } from './hierarchy-select-modal.types';

@Component({
  selector: 'app-hierarchy-select-modal',
  templateUrl: './hierarchy-select-modal.component.html',
  styleUrls: ['./hierarchy-select-modal.component.scss']
})
export class HierarchySelectModalComponent {
  protected selectedOption: HierarchySelectItem = null;
  protected selectConfig: HierarchySelectConfig = {
    withSearch: true,
    allGroups: false,
    searchPlaceholder: 'Search Location',
  };

  constructor(
    public dialogRef: MatDialogRef<HierarchySelectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly context: HierarchyDialogContext
  ) { }

  protected selectOption(option: HierarchySelectItem): void {
    this.selectedOption = option;
  }

  public onCancel(): void {
    this.callHandler(this.context.cancelAction?.handler);
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.callHandler(this.context.submitAction?.handler);
    this.dialogRef.close(this.selectedOption);
  }

  private callHandler(handler: Function): void {
    if (handler) {
      handler(this.selectedOption);
    }
  }
}
