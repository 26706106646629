<div
  #wrapper
  (scrolledToBottom)="loadNewChunk.next();"
  [scrollContainerElement]="wrapper"
  class="wrapper"
  scrollDetector
>
  <table>
    <thead>
      <tr>
        <th>Date&#47;Time</th>
        <th>User</th>
        <th>Action</th>
        <th>Field</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of data">
        <td>
          <span class="table-contOverflow" title="{{ log.date | date : 'MM/dd/yyyy' }}">
            {{ log.date | date : 'MM/dd/yyyy' }}
          </span>
        </td>
        <td>
          <span class="table-contOverflow" title="{{ log.user }}">
            {{ log.user }}
          </span>
        </td>
        <td>
          <span class="table-contOverflow" title="{{ log.operation }}">
            {{ log.operation }}
          </span>
        </td>
        <td>
          <span class="table-contOverflow" title="{{ log.name }}">
            {{ log.name }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
