import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getDiffInDays, getTodaysDate } from 'app/shared/utils/date.utils';
import { Task, TaskDO, TaskStatus, TaskStatusName } from 'app/shared/types/task.interface';
import { TasksService } from 'app/shared/services/backend/tasks.service';
import { parseDateString } from '../containers/campaign-details/date-operations';
import { BudgetObjectTasksService } from '../../services/budget-object-tasks.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';

export interface TaskListChangeEvent {
  data: TaskDO[];
  update?: {
    key: string;
    index: number;
    value: any;
    obj: Task;
  };
  saveState: boolean;
}

@Component({
  selector: 'tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent implements OnChanges, OnDestroy {
  @Input() owners = [];
  @Input() currentCompanyUser: CompanyUserDO;
  @Input() tasks: TaskDO[];
  @Input() isReadOnlyMode = false;
  @Input() title = 'Tasks';
  @Input() isLoading = false;
  // Display user related 'open' tasks
  @Input() isPersonalMode = false;
  @Input() budgetTodayDate: Date;
  @Input() isDrawer = false;
  @Input() id: string;
  @Output() onDataChanged = new EventEmitter<TaskListChangeEvent>();

  private readonly destroy$ = new Subject<void>();
  private dataInitialized = false;
  private defaultStatus;
  private defaultOwner;
  public data: Task[] = [];
  public statuses: TaskStatus[] = [];
  public STATUS_NAMES = TaskStatusName;
  public isBodyScrolled = false;
  public todayDate = new Date();

  constructor(
    private readonly tasksService: TasksService,
    private readonly tasksManager: BudgetObjectTasksService,
    private readonly appRoutingService: AppRoutingService
  ) {
    this.statuses = tasksService.getStatusList();
    this.defaultStatus = this.getStatusByName(TaskStatusName.IN_PROGRESS);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const requiredDataAvailable = this.tasks && this.currentCompanyUser && this.owners && this.owners.length;
    const requiredDataChanged = changes.tasks || changes.owners || changes.currentUser;

    if (requiredDataChanged && requiredDataAvailable && !this.dataInitialized) {
      this.dataInitialized = true;
      this.isBodyScrolled = false;
      this.initData();
      this.defaultOwner = this.getOwnerById(this.currentCompanyUser.user);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getStatusByName(statusName: TaskStatusName) {
    return this.statuses.find(s => s.name === statusName);
  }

  private getOwnerById(id: number) {
    return this.owners.find(owner => owner.id === id);
  }

  private prepareDateInfo(dueDate: Date): Partial<Task> {
    const diffInDays = getDiffInDays(getTodaysDate(this.todayDate), dueDate, false);
    const isLate = diffInDays < 0;
    const isIncoming = !isLate && diffInDays <= 7;

    return {
      isLate,
      isIncoming,
      daysLeft: diffInDays
    };
  }

  private initData() {
    if (!!this.budgetTodayDate) {
      this.todayDate = this.budgetTodayDate;
    }

    this.data = this.tasks.map(task => {
      const owner = this.getOwnerById(task.owner);
      const dueDate = parseDateString(task.due_date);
      const dateInfo = this.prepareDateInfo(dueDate);
      const status = this.getTaskStatus(task, dateInfo.isLate);

      return {
        id: task.id,
        name: task.name,
        program: {
          id: task.program,
          name: task.program_name
        },
        campaign: {
          id: task.campaign,
          name: task.campaign_name
        },
        owner,
        status,
        dueDate,
        ...dateInfo
      };
    });
    this.data.sort((taskA, taskB) => (
      taskA.dueDate.getTime() - taskB.dueDate.getTime()
    ));

    if (!this.isReadOnlyMode && !this.isPersonalMode) {
      this.tasksManager.syncExpiredTasks(this.data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => { this.syncChanges(true); },
          () => {}
        );
    }
  }

  private getTaskStatus(task: TaskDO, isLate: boolean): TaskStatus {
    if (this.budgetTodayDate && isLate && task.status === TaskStatusName.IN_PROGRESS) {
      return this.getStatusByName(TaskStatusName.LATE);
    }
    if (this.budgetTodayDate && !isLate && task.status === TaskStatusName.LATE) {
      return this.getStatusByName(TaskStatusName.IN_PROGRESS);
    }
    return this.getStatusByName(task.status as TaskStatusName);
  }

  private syncChanges(saveState = false, update = null) {
    if (this.isPersonalMode) {
      this.data = this.data.filter(task => TasksService.isTaskOpen(task));
    }
    const data: TaskDO[] = this.data.map(task => TasksService.convertToDataObject(task));

    this.onDataChanged.emit({
      data,
      update,
      saveState
    });
  }

  private handleDateChange(task: Task) {
    const dateInfo = this.prepareDateInfo(task.dueDate);
    task.isLate = dateInfo.isLate;
    task.isIncoming = dateInfo.isIncoming;
    task.daysLeft = dateInfo.daysLeft;
    if (dateInfo.isLate && task.status.name === TaskStatusName.IN_PROGRESS) {
      task.status = this.getStatusByName(TaskStatusName.LATE);
    }
    if (!dateInfo.isLate && task.status.name === TaskStatusName.LATE) {
      task.status = this.getStatusByName(TaskStatusName.IN_PROGRESS);
    }
  }

  public handleAddTask() {
    const dueDate = getTodaysDate(this.todayDate);
    const dateInfo = this.prepareDateInfo(dueDate);
    this.data.unshift({
      name: '',
      dueDate,
      owner: this.defaultOwner,
      status: this.defaultStatus,
      ...dateInfo,
    });
    this.syncChanges();
  }

  public handleValueChanged(value, key, index) {
    const targetTask = this.data[index];
    if (!targetTask) {
      return;
    }

    targetTask[key] = value;

    if (key === 'dueDate') {
      this.handleDateChange(targetTask);
    }
    this.syncChanges(false, {
      key,
      index,
      value,
      obj: targetTask
    });
  }

  public handleDelete(i) {
    if (this.data[i]) {
      this.data.splice(i, 1);
      this.syncChanges();
    }
  }

  public reset() {
    this.dataInitialized = false;
  }

  public openCampaignDetails(id: number) {
    this.appRoutingService.openCampaignDetails(id);
  }

  public openProgramDetails(id: number) {
    this.appRoutingService.openProgramDetails(id);
  }

  public handleBodyScrolledChange($event: boolean) {
    this.isBodyScrolled = $event;
  }

  public trackByFn(index, item) {
    return `${item.id}_${index}_${item.name}`;
  }
}
