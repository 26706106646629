import { Component, Input } from '@angular/core';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
@Component({
  selector: 'app-calendar-placeholder',
  templateUrl: './calendar-placeholder.component.html',
  styleUrls: ['./calendar-placeholder.component.scss']
})
export class CalendarPlaceholderComponent {
  constructor(private readonly routingService: AppRoutingService) {}
  @Input() editPermission = false;
  public addCampaign = () => {
    this.routingService.openCampaignCreation();
  }
}
