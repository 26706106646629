import { Component, EventEmitter, Input, Output } from '@angular/core';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { UntypedFormBuilder } from '@angular/forms';
import { AutocompleteItem } from '../../types/autocomplete-item.type';

export interface AutocompleteControlItem extends AutocompleteItem {
  isNew?: boolean;
}

@Component({
  selector: 'autocomplete-control',
  templateUrl: './autocomplete-control.component.html',
  styleUrls: ['./autocomplete-control.component.scss']
})
export class AutocompleteControlComponent {
  @Input() items: AutocompleteControlItem[];
  @Input() maxItemLength = 128;
  @Output() onItemChanged = new EventEmitter<AutocompleteControlItem>();
  private value;
  public autocompleteItems;
  public formData;

  constructor(private readonly fb: UntypedFormBuilder) {
    const formData = { input: '' };

    this.formData = this.fb.group(formData);
    this.autocompleteItems = this.formData.controls.input.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        map(value => this.filterItems(value))
      );
  }

  private getInputValue() {
    return this.formData.get('input') && this.formData.get('input').value;
  }

  private createNewItem(name: string) {
    return {
      id: null,
      name,
      isNew: true
    };
  }

  private filterItems(value): AutocompleteControlItem[] {
    if (!value) {
      return this.items;
    }

    const filterName = this.getName(value);
    const filteredItems = this.items.filter(
      item => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );

    if (filteredItems.length === 0) {
      return [ this.createNewItem(filterName) ];
    }
    return filteredItems;
  }

  public handleChange() {
    const inputValue = this.getInputValue();
    const value = typeof inputValue === 'string' ?
      this.createNewItem(inputValue) :
      inputValue;

    this.value = value;
    this.onItemChanged.emit(value);
  }

  public getName = (item: AutocompleteControlItem | string): string => {
    if (!item) {
      return '';
    }

    return typeof item === 'string' ? item : item.name;
  }
}
