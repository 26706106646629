import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter, inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayPositionBuilder, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';
import { BudgetAllocationActionTooltipContext } from 'app/budget-allocation/components/budget-allocation-action-tooltip/budget-allocation-action-tooltip.component';
import { BudgetAllocationCellGesturesEvent } from 'app/budget-allocation/components/budget-allocation-cell/budget-allocation-cell.types';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { SortParams } from 'app/shared/types/sort-params.interface';
import {
  CreateItemTemplateEvent,
  ManageTableActionDataSource,
  ManageTableActionEvent,
  ManageTableData,
  ManageTableFullRowValues,
  ManageTableRow,
} from './manage-table.types';
import { ManageTableRecordInteractionsService } from '../../services/manage-table-record-interactions.service';
import { FullHierarchyTreeComponent } from '@shared/components/full-hierarchy-tree/full-hierarchy-tree.component';
import { ManageTableMenuAction } from '../../types/manage-table-menu-action.type';
import { ManageTableDataValidationService } from '../../services/manage-table-data-validation.service';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { ManageTableHelpers } from '../../services/manage-table-helpers';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { LAST_CREATED_OBJECT_ID } from 'app/shared/constants/storage.constants';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { ManagePageService } from '../../services/manage-page.service';
import { ManageTableDataMode } from '../../types/manage-table-data-mode.type';
import { SpendingModeFlag, SpendingModeFlags } from '../../types/spending-mode-flags.type';
import { Configuration } from 'app/app.constants';
import { PerformanceColumnData } from '../../types/performance-column-data.type';
import { ManageTableSelectionState } from '../../types/manage-table-selection-state.types';
import { HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { ManagePageModeService } from '../../services/manage-page-mode.service';
import { ManageTableViewMode } from '../../types/manage-table-view-mode.type';
import { utClasses, utClassesByType } from '../../constants/ut-classes.constants';
import { ManageTableRowTypeIcon, ManageTableRowTypeLabel } from './manage-table.constants';
import { AllocationModeFlags, AllocationModeFlag } from '../../types/allocation-mode-flags.type';
import { ShadowState } from 'app/shared/directives/table-content-shadows.directive';
import { DropState } from '@shared/types/droppable-state.type';
import { FilterManagementService } from '../../../header-navigation/components/filters/filter-services/filter-management.service';
import { ManagePageHistoryModeService } from '../../services/manage-page-history-mode.service';
import { HierarchyViewMode } from '@spending/types/expense-page.type';
import { TimeframeProcessingComponent } from '@shared/components/abstract/timeframe-processing.component';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Component({
  selector: 'manage-table',
  templateUrl: './manage-table.component.html',
  styleUrls: ['./manage-table.component.scss']
})
export class ManageTableComponent extends TimeframeProcessingComponent implements OnInit, OnChanges, OnDestroy {
  private readonly recordInteractionsService = inject(ManageTableRecordInteractionsService);
  private readonly dataValidationService = inject(ManageTableDataValidationService);
  private readonly routingService = inject(AppRoutingService);
  private readonly managePageService = inject(ManagePageService);
  private readonly overlayPositionBuilder = inject(OverlayPositionBuilder);
  private readonly overlay = inject(Overlay);
  private readonly configuration = inject(Configuration);
  private readonly managePageModeService = inject(ManagePageModeService);
  private readonly zone = inject(NgZone);
  private readonly historyService = inject(ManagePageHistoryModeService);

  @Input() showExpenses = false;
  @Input() data: ManageTableData = [];
  @Input() performanceColumnData: PerformanceColumnData = {};
  @Input() dataMode = ManageTableDataMode.Allocation;
  @Input() spendingModeFlags: SpendingModeFlags;
  @Input() grandTotal: ManageTableFullRowValues;
  @Input() isLoading = true;
  @Input() isFilteredMode = false;
  @Input() hasHiddenHierarchy = false;
  @Input() appliedSorting: SortParams;
  @Input() segments: BudgetSegmentAccess[];
  @Input() sharedCostRules: SharedCostRule[];
  @Input() editPermission = false;
  @Input() isAdmin = false;
  @Input() allocationModeFlags: AllocationModeFlags;
  @Input() segmentBreakdownAllowed = false;

  @Output() onSortingChanged = new EventEmitter<string>();
  @Output() onAllocationChanged = new EventEmitter<ManageTableActionEvent>();
  @Output() onSegmentAllocationChanged = new EventEmitter<any>();
  @Output() onDoubleClick = new EventEmitter<ManageTableActionEvent>();
  @Output() onDrop = new EventEmitter<ManageTableActionEvent>();
  @Output() onDragStart = new EventEmitter<ManageTableActionEvent>();
  @Output() onDragEnd = new EventEmitter<void>();
  @Output() onClone = new EventEmitter<ManageTableRow>();
  @Output() onCreateNewItemTemplate = new EventEmitter<CreateItemTemplateEvent>();
  @Output() onCreateNewItemFromTemplate = new EventEmitter<string>();
  @Output() onMoveTo = new EventEmitter<ManageTableRow>();
  @Output() onClose = new EventEmitter<ManageTableRow>();
  @Output() onReopen = new EventEmitter<ManageTableRow>();
  @Output() onDelete = new EventEmitter<ManageTableRow>();
  @Output() onTableWidthChange = new EventEmitter<number>();

  private outputEmitterByMenuAction = {
    [ManageTableMenuAction.Duplicate]: this.onClone,
    [ManageTableMenuAction.MoveTo]: this.onMoveTo,
    [ManageTableMenuAction.Close]: this.onClose,
    [ManageTableMenuAction.Reopen]: this.onReopen,
    [ManageTableMenuAction.Delete]: this.onDelete,
  };
  public decimalPipeFormat = '1.2-2';
  public tooltipContext = BudgetAllocationActionTooltipContext.Default;
  public segmentTooltipContext = BudgetAllocationActionTooltipContext.BudgetPage;
  public readonly iconByRowType = ManageTableRowTypeIcon;
  public readonly labelByRowType = ManageTableRowTypeLabel;
  public routeActionByRowType: Record<string, Function> = {
    [ManageTableRowType.Goal]: (id: number) => this.routingService.openGoalDetails(id),
    [ManageTableRowType.Campaign]: (id: number) => this.routingService.openCampaignDetails(id),
    [ManageTableRowType.ExpenseGroup]: (id: number) => this.routingService.openProgramDetails(id),
  };
  public readonly utClassesByType = utClassesByType;
  public readonly utClasses = utClasses;
  private readonly filterNameByRowType = {
    [ManageTableRowType.Segment]: FilterName.Segments,
    [ManageTableRowType.Goal]: FilterName.Goals,
    [ManageTableRowType.Campaign]: FilterName.Campaigns,
    [ManageTableRowType.ExpenseGroup]: FilterName.ExpenseBuckets,
  };
  public readonly RowType = ManageTableRowType;
  public readonly SelectionValue = CheckboxValue;
  public readonly SpendingModeFlag = SpendingModeFlag;
  public readonly AllocationModeFlag = AllocationModeFlag;
  public readonly statusFields = this.configuration.statusFields;
  public lastCreatedObjectId = '';
  public dragEnter = null;
  private readonly fullHierarchyHovered$ = new BehaviorSubject<boolean>(false);
  private readonly destroy$ = new Subject<void>();
  public readonly identifyRecord = ManageTableHelpers.identifyRecord;
  public readonly identifyTimeframe = ManageTableHelpers.identifyTimeframe;
  public readonly statusNames = this.configuration.statusNames;
  public selectedRecords: number[] = [];
  public draggedEntity: ManageTableRow;
  public droppableItem: {[key: number]: DropState} = {};
  public segmentBreakdown = false;
  public shadowState: ShadowState = {};
  public dropState = DropState;
  private overlayRef: OverlayRef;
  private viewMode: ManageTableViewMode;

  @ViewChild('lastCreatedElementAnchor') private readonly lastCreatedElementAnchor: ElementRef;

  public get togglingState(): Record<string, boolean> {
    return this.recordInteractionsService.togglingState;
  }

  public get selectionState(): ManageTableSelectionState {
    return this.recordInteractionsService.selectionState;
  }

  public get restrictedFromDrop(): string[] {
    return this.dataValidationService.restrictedFromDrop;
  }

  public get allocationDataMode(): boolean {
    return this.dataMode === ManageTableDataMode.Allocation;
  }

  public get spendingDataMode(): boolean {
    return this.dataMode === ManageTableDataMode.Spending;
  }

  public get objectTypeNameMap(): Record<string, Record<number, string>> {
    return this.managePageService.objectTypeNameMap;
  }

  public get remainingBudget(): number {
    return this.managePageService.remainingBudget;
  }

  ngOnInit(): void {
    this.fullHierarchyHovered$.pipe(
      takeUntil(this.destroy$),
    ).pipe(
      filter(r => !r)
    ).subscribe(() => {
      this.resetHierarchyTooltip(true);
    });

    this.recordInteractionsService.selectionChanged$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(selection => this.countSelectedRecords(selection));

    this.managePageModeService.viewModeChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(view => {
      this.viewMode = view.value;
      this.historyService.saveViewMode(this.viewMode);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.lastCreatedObjectId = LocalStorageService.getFromStorage(LAST_CREATED_OBJECT_ID);

    if (changes.data && this.data?.length) {
      this.scrollToLastCreatedObject();
    }

    if (changes.segmentBreakdownAllowed || changes.data) {
      this.defineSegmentBreakdownMode();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get newItemCreationActive(): boolean {
    return this.managePageService.newItemCreationActive;
  }

  public createNewItemTemplate(record: ManageTableRow, index: number): void {
    this.onCreateNewItemTemplate.next({
      contextRow: record,
      position: index,
    });
  }

  public createItemFromTemplate(name: string): void {
    this.onCreateNewItemFromTemplate.next(name);
  }

  private defineSegmentBreakdownMode(): void {
    this.segmentBreakdown = this.viewMode === ManageTableViewMode.Segments && this.segmentBreakdownAllowed;
  }

  private countSelectedRecords(selection: ManageTableSelectionState): void {
    const { campaigns, expGroups, goals } = ManageTableHelpers.getBulkTargetsFromSelection(selection);
    this.selectedRecords = [...campaigns, ...expGroups, ...goals];
  }

  private checkChildrenType(item: ManageTableRow): boolean {
    return item?.children.some(child => child.type === ManageTableRowType.Campaign);
  }

  private countMovedItems(movedItems: ManageTableRow): number {
    return (movedItems.children || []).length + 1;
  }

  private forbidDrop(parentItem: ManageTableRow, childItem: ManageTableRow): boolean {
    if (!childItem) {
      return;
    }

    switch (this.viewMode) {
      case ManageTableViewMode.Goals:
        return (this.checkChildrenType(childItem) && parentItem.type === ManageTableRowType.Campaign)
          || parentItem.type === ManageTableRowType.ExpenseGroup
          || ( parentItem.type === ManageTableRowType.Campaign
            && childItem.type === ManageTableRowType.Campaign
            && parentItem.parentId?.includes(ManageTableRowType.Campaign.toLowerCase())
          )
          || parentItem.id === childItem.parentId
          || childItem.isClosed
          || (ManageTableHelpers.isSegmentlessObject(childItem) && parentItem.type !== ManageTableRowType.Goal);
      case ManageTableViewMode.Segments:
        return (this.checkChildrenType(childItem) && parentItem.type !== ManageTableRowType.Segment)
          || parentItem.type === ManageTableRowType.SegmentGroup
          || (
            parentItem.type === ManageTableRowType.Campaign
            && parentItem.parentId?.includes(ManageTableRowType.Campaign.toLowerCase())
            && childItem.type !== ManageTableRowType.ExpenseGroup
          )
          || parentItem?.objectId === childItem?.segmentId
          || childItem.isClosed
          || parentItem?.id === childItem?.parentId;
      default:
        return this.checkChildrenType(childItem)
          || parentItem?.parentId && childItem.type !== ManageTableRowType.ExpenseGroup
          || childItem.isClosed
          || parentItem?.id === childItem?.parentId
          || ManageTableHelpers.isSegmentlessObject(childItem);
    }
  }

  private isParent(item: ManageTableRow, childId: number): boolean {
    return item?.children.some(child => child.objectId === childId);
  }

  private scrollToLastCreatedObject(): void {
    setTimeout(() => {
      if (this.lastCreatedElementAnchor) {
        this.lastCreatedElementAnchor.nativeElement.scrollIntoView(
          {
            behavior: 'smooth',
            block: 'end'
          }
        );
        LocalStorageService.removeFromStorage(LAST_CREATED_OBJECT_ID);
      }
    }, 100);
  }

  public hideHierarchyTooltip(): void {
    setTimeout(() => {
      if (!this.fullHierarchyHovered$.getValue()) {
        this.resetHierarchyTooltip(true);
      }
    }, 250);
  }

  public showHierarchyTooltip(record: ManageTableRow, event: MouseEvent): void {
    this.resetHierarchyTooltip(false);
    this.overlayRef = this.overlay.create({
      positionStrategy: this.createPositionStrategy(event.target as HTMLElement),
    });

    const tooltipPortal = new ComponentPortal(FullHierarchyTreeComponent);
    const tooltipRef: ComponentRef<FullHierarchyTreeComponent> = this.overlayRef.attach(tooltipPortal);

    tooltipRef.instance.items = record.hierarchyInfo;
    tooltipRef.instance.hoverState$ = this.fullHierarchyHovered$;
  }

  private resetHierarchyTooltip(delay: boolean): void {
    if (!this.overlayRef) {
      return;
    }
    const clearContainer = () => {
      this.overlayRef?.dispose();
      this.overlayRef = null;
    };
    this.overlayRef.detach();
    if (delay) {
      setTimeout(clearContainer, 100);
    } else {
      clearContainer();
    }
  }

  private createPositionStrategy(elementRef: HTMLElement): PositionStrategy {
    return this.overlayPositionBuilder
      .flexibleConnectedTo(elementRef)
      .withPositions([{
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      }]);
  }

  public handleToggleChange(value: boolean, record: ManageTableRow): void {
    this.recordInteractionsService.handleToggleChange(record, value);
  }

  public handleSelection($event: MatCheckboxChange, record: ManageTableRow): void {
    this.recordInteractionsService.handleSelection(record, $event.checked);
  }

  public handleSelectAllChange($event: MatCheckboxChange): void {
    this.recordInteractionsService.handleSelectAllChange(this.data, $event.checked);
  }

  public handleNameClick(record: ManageTableRow): void {
    this.routeActionByRowType[record.type]?.(
      ManageTableHelpers.getObjectId(record.itemId)
    );
  }

  public applySorting(columnName: string): void {
    this.onSortingChanged.emit(columnName);
  }

  /* DATA EVENTS HANDLERS */
  public handleAllocationChange(amount: number, dataSource: ManageTableActionDataSource): void {
    const { timeframe, record } = dataSource;
    const prevValue = record.values[timeframe.id]?.allocated;

    if (prevValue !== amount) {
      this.onAllocationChanged.emit({
        amount,
        dataSource
      });
    }
  }

  public handleDoubleClick($event: BudgetAllocationCellGesturesEvent, dataSource: ManageTableActionDataSource): void {
    this.onDoubleClick.emit({
      gestureEvent: $event,
      dataSource
    });
  }

  public handleOnDragStart($event: BudgetAllocationCellGesturesEvent, dataSource: ManageTableActionDataSource): void {
    this.onDragStart.emit({
      gestureEvent: $event,
      dataSource
    });
  }

  public handleOnDrop($event: BudgetAllocationCellGesturesEvent, dataSource: ManageTableActionDataSource): void {
    this.onDrop.emit({
      gestureEvent: $event,
      dataSource
    });
  }

  public handleOnDragEnd(): void {
    this.onDragEnd.emit();
    this.droppableItem = {};
  }

  public handleTotalAllocationChange(amount: number, record: ManageTableRow): void {
    const prevValue = record.total?.allocated;

    if (prevValue !== amount) {
      this.onAllocationChanged.emit({
        amount,
        dataSource: {
          record,
          timeframe: null
        }
      });
    }
  }

  public handleRecordActionClick(action: ManageTableMenuAction, record: ManageTableRow): void {
    this.outputEmitterByMenuAction[action]?.emit(record);
  }

  public onExpenseClick(record: ManageTableRow, timeframe?, noParentFilter = false) {
    const filtersList = {};
    const viewMode = {
      [ManageTableRowType.SegmentGroup]: HierarchyViewMode.Segment,
      [ManageTableRowType.Segment]: HierarchyViewMode.Segment,
      [ManageTableRowType.Campaign]: HierarchyViewMode.Campaign,
      [ManageTableRowType.ExpenseGroup]: HierarchyViewMode.Campaign
    };
    if (timeframe) {
      filtersList[FilterName.Timeframes] = [timeframe.id];
    }

    if (record.type === ManageTableRowType.SegmentGroup) {
      filtersList[FilterName.Segments] = record.children.reduce((idsArr, segment) => {
        idsArr.push(segment.objectId);
        return idsArr;
      }, []);
    } else {
      const filterName = this.filterNameByRowType[record.type];

      if (filterName) {
        filtersList[filterName] = [record.objectId];
      }
    }

    if (noParentFilter) {
      filtersList[FilterName.Campaigns] = [FilterManagementService.NOT_SPECIFIED_FILTER_VALUE];
      filtersList[FilterName.ExpenseBuckets] = [FilterManagementService.NOT_SPECIFIED_FILTER_VALUE];
    }

    this.managePageService.openExpenseList(filtersList, this.togglingState, record.id, viewMode[record.type]);
  }

  public handleTableResize($event: DOMRectReadOnly): void {
    // We need to add 1px to resize rect width to avoid scrolling artifacts
    this.onTableWidthChange.emit($event.width + 1);
  }

  public handleEntityOnDrop(record: ManageTableRow): void {
    if (!this.draggedEntity) {
      return;
    }
    this.zone.run(() => {
      if (this.draggedEntity?.objectId === record?.objectId
        || this.selectedRecords.includes(record.objectId)
        || this.forbidDrop(record, this.draggedEntity)
      ) {
        return;
      }

      const parentEntity: HierarchySelectItem = {
        id: String(record.itemId),
        title: record.name,
        objectType: record.type,
        objectId: record.objectId,
        segmentData: {
          budgetSegmentId: record.segmentId,
          sharedCostRuleId: record.sharedCostRuleId
        }
      };

      if (this.selectedRecords.length > 1) {
        this.managePageService.moveSelectedItems(parentEntity, this.selectedRecords.length);
      } else {
        const countDropItems = this.countMovedItems(this.draggedEntity);
        this.managePageService.moveItem(this.draggedEntity, parentEntity, countDropItems);
      }

      this.draggedEntity = null;
      this.dragEnter = null;
    });
  }

  public handleEntityOnDragStart(record: ManageTableRow): void {
    this.zone.run(() => {
      this.draggedEntity = record;
    });
  }

  public handleEntityOnDragEnd(): void {
    this.zone.run(() => {
      this.dragEnter = null;
      this.draggedEntity = null;
    });
  }

  public allowDrag(item: ManageTableRow): boolean {
    return item.type !== ManageTableRowType.Goal
      && item.type !== ManageTableRowType.Segment
      && item.type !== ManageTableRowType.SegmentGroup
      && !!item.objectId
      && !item.isClosed;
  }

  public handleDragOver(event: DragEvent, item: ManageTableRow): void {
    this.zone.run(() => {
      if (!this.draggedEntity) {
        return;
      }
      const dragOverId = item.objectId || item.id;
      const draggedId = this.draggedEntity.objectId || this.draggedEntity.id;
      this.droppableItem = {};
      this.dragEnter = this.selectedRecords.includes(Number(item.objectId)) ? null : dragOverId;
      if (item?.children.length) {
        this.togglingState[item.id] = true;
      }

      if (dragOverId === draggedId || this.isParent(item, this.draggedEntity.objectId)) {
        this.droppableItem[dragOverId] = this.dropState.EMPTY;
        return;
      }

      if (item.isClosed || item.type === ManageTableRowType.ExpenseGroup) {
        this.droppableItem[dragOverId] = this.dropState.FORBID;
        return;
      }

      this.droppableItem[this.dragEnter] = this.forbidDrop(item, this.draggedEntity) ? this.dropState.FORBID : this.dropState.ALLOW;
    });
  }

  public checkDragEntity(dragEntityId: number): boolean {
    return !!dragEntityId && this.dragEnter === dragEntityId;
  }

  public handlePerformanceClick(mappingId: number): void {
    if (mappingId) {
      this.routingService.openCampaignMetricDetails(mappingId);
    }
  }

  public handleExpenseStatusClick(record: ManageTableRow, statusName: string): void {
    const filtersList: FilterSet = {
      [FilterName.Statuses]: [ statusName ]
    };
    const filterName = this.filterNameByRowType[record.type];

    if (filterName) {
      filtersList[filterName] = [record.objectId];
    }

    this.managePageService.openExpenseList(filtersList, this.togglingState, record.id);
  }

  public handleSegmentAllocationChange(amount: number, dataSource: ManageTableActionDataSource): void {
    const { timeframe, record } = dataSource;
    const prevValue = record.segment.values[timeframe.id]?.allocated;

    if (prevValue !== amount) {
      this.onSegmentAllocationChanged.emit({
        amount,
        dataSource
      });
    }
  }

  public handleShadowUpdate(data): void {
    this.shadowState = data;
  }
}
