<widget-card [isLoading]="state === widgetState.LOADING">
  <div class="metrics-progress">
    <details-widget-title [showCount]="false" text="Plan metrics progress">
      <fa-duotone-icon slot="icon" [fixedWidth]="true" [icon]="['fad', 'chart-line']"></fa-duotone-icon>
    </details-widget-title>
    <div class="content" [ngClass]="{
      'is-ready': state === widgetState.READY
    }">
      <widget-card-placeholder *ngIf="state === widgetState.EMPTY">
        <fa-duotone-icon [icon]="['fad', 'chart-line']" slot="icon"></fa-duotone-icon>
        <ng-container slot="content">
          Metrics matter!<br>
          Set up campaign metrics and track your progress.
        </ng-container>
      </widget-card-placeholder>
      <metrics-progress-chart
        *ngIf="state === widgetState.READY"
        [data]="chartData"
        (onCategoryClicked)="handleChartCategoryClicked($event)"
      ></metrics-progress-chart>
      <widget-chart-legend [items]="legendItems"></widget-chart-legend>
    </div>
  </div>
</widget-card>
