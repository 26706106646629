import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WidgetConfig, WidgetState, WidgetType } from '../../types/widget.interface';
import { WidgetConfigService } from '../../services/widget-config.service';
import { WidgetStateService } from '../../services/widget-state.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';

@Component({
  selector: 'priority-actions',
  styleUrls: ['./priority-actions.component.scss'],
  templateUrl: './priority-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriorityActionsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly budgetDataService = inject(BudgetDataService);
  private budgetCegStatusEnabled: boolean;
  public displayTitle = true;
  public title = 'My Actions';
  public widgets: WidgetConfig[] = [
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.OPEN_TASKS],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.OVERDUE_EXPENSES],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.UPDATE_METRICS],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.ASSIGN_SEGMENTS_GOOGLE_CAMPAIGNS]
  ];

  constructor(
    private readonly widgetConfig: WidgetConfigService,
    private readonly widgetStateService: WidgetStateService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.widgetStateService.stateChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.budgetCegStatusEnabled = this.budgetDataService.selectedBudgetSnapshot?.new_campaigns_programs_structure;
        const { values } = data;
        if (this.budgetCegStatusEnabled) {
          this.filterWidgets(values[WidgetType.OVERDUE_EXPENSES], WidgetType.OVERDUE_EXPENSES);
        }
        const notAvailableStates = [WidgetState.EMPTY, WidgetState.HIDDEN];
        const noTasksAndExpensesAvailable = (
          notAvailableStates.includes(values[WidgetType.OPEN_TASKS]) &&
          notAvailableStates.includes(values[WidgetType.OVERDUE_EXPENSES])
        );
        this.displayTitle = !noTasksAndExpensesAvailable;
        this.cdRef.detectChanges();
      });
  }

  private filterWidgetsCallback(widgetType: WidgetType, widget: WidgetConfig): boolean {
    const isOverdueExpenses = widget.type === widgetType;
    return !(isOverdueExpenses && this.budgetCegStatusEnabled);
  }

  private filterWidgets(readyStatus: WidgetState, widgetType: WidgetType): void {
    if (readyStatus === WidgetState.READY) {
      this.widgets = [...this.widgets].filter(this.filterWidgetsCallback.bind(this, widgetType));
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}


