<confirmation-dialog>
  <div class="content">
    <p>In order to set up federated login you need to upload your SAML 2.0 metadata document. Your IT team can provide this XML file for you.</p>
    <div class="file-group">
      <button mat-stroked-button color="primary" type="button" (click)="file.click()">
        Browse Your Computer
      </button>
      <input type="file" (change)="handleFileChange($event)" accept=".xml,text/xml" #file>
      <div class="file-name" *ngIf="selectedFile">
        [ {{ selectedFile?.name }} ]
      </div>
    </div>
    <p>To finalize this setup, your IT team will need to update their federated login settings with Planful
      information for relying party trust and specify Planful as an end point.
      Your Planful Customer Success Manager can provide more specific details.</p>
    <p>After completing setup you can log in with your single sign on credentials from:</p>
    <p>{{ protocol }}<b>{{ clientDomain }}</b>.{{ regionDomain }}</p>
  </div>
</confirmation-dialog>
