<div class="bar-graph">
  <p *ngIf="graph.length === 0 else barGraph" class="empty-textCon">Add budget amounts to view spend graph.</p>
  <ng-template #barGraph>
    <span *ngFor="let graphCell of graph" class="bar-graph-cell" [ngClass]="graphCell.className"  role="progressbar" [ngStyle]="{'flex-basis' : graphCell.percentage+'%'}">
      <span [ngClass]="{'two-letterCurr': currencySymbol?.length == 2, 'three-letterCurr': currencySymbol?.length == 2, 'hide-text': graphCell.percentage < 20}">
        {{currencySymbol}} {{ graphCell.amount | smallNumber }}
      </span>
    </span>
  </ng-template>
</div>
