import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MalwareDetectedDialogContext } from './malware-detected-dialog.type';

@Component({
  selector: 'malware-detected-dialog',
  templateUrl: './malware-detected-dialog.component.html',
  styleUrls: ['./malware-detected-dialog.component.scss']
})
export class MalwareDetectedDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public readonly context: MalwareDetectedDialogContext) { }

}
