<div class="business-value-table"
   [ngClass]="{
    'is-scrollable': data?.campaigns?.length > VISIBLE_ROWS_LIMIT,
    'is-scrolled': isBodyScrolled,
    'odd-records-count': data?.campaigns?.length % 2 !== 0
  }"
>
  <div class="table-head">
    <div class="table-row">
      <div class="table-col left-aligned name-col">
        <div class="table-cell">
          Campaign<br/>Name
          <sort-button
            [columnName]="ColumnName.Name"
            [appliedSorting]="appliedSorting"
            (onClick)="applySorting(ColumnName.Name)"
          >
          </sort-button>
        </div>
      </div>
      <div class="table-col value-col">
        <div class="table-cell">
          Target<br/>Return
          <sort-button
            [columnName]="ColumnName.TargetReturn"
            [appliedSorting]="appliedSorting"
            (onClick)="applySorting(ColumnName.TargetReturn)"
          >
          </sort-button>
        </div>
      </div>
      <div class="table-col value-col">
        <div class="table-cell">
          Current<br/>Return
          <sort-button
            [columnName]="ColumnName.CurrentReturn"
            [appliedSorting]="appliedSorting"
            (onClick)="applySorting(ColumnName.CurrentReturn)"
          >
          </sort-button>
        </div>
      </div>
      <div class="table-col value-col">
        <div class="table-cell">
          Low<br/>Forecast
          <sort-button
            [columnName]="ColumnName.LowForecast"
            [appliedSorting]="appliedSorting"
            (onClick)="applySorting(ColumnName.LowForecast)"
          >
          </sort-button>
        </div>
      </div>
      <div class="table-col value-col">
        <div class="table-cell">
          High<br/>Forecast
          <sort-button
            [columnName]="ColumnName.HighForecast"
            [appliedSorting]="appliedSorting"
            (onClick)="applySorting(ColumnName.HighForecast)"
          >
          </sort-button>
        </div>
      </div>
      <div class="table-col target-roi-col">
        <div class="table-cell">
          Target<br/>ROI
          <sort-button
            [columnName]="ColumnName.TargetROI"
            [appliedSorting]="appliedSorting"
            (onClick)="applySorting(ColumnName.TargetROI)"
          >
          </sort-button>
        </div>
      </div>
      <div class="table-col current-roi-col">
        <div class="table-cell">
          Current<br/>ROI
          <sort-button
            [columnName]="ColumnName.CurrentROI"
            [appliedSorting]="appliedSorting"
            (onClick)="applySorting(ColumnName.CurrentROI)"
          >
          </sort-button>
        </div>
      </div>
      <div class="table-col owner-col">
        <div class="table-cell">
          Campaign<br/>Owner
          <sort-button
            [columnName]="ColumnName.Owner"
            [appliedSorting]="appliedSorting"
            (onClick)="applySorting(ColumnName.Owner)"
          >
          </sort-button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="table-body"
    isScrolled
    (isScrolledChanged)="handleBodyScrolledChange($event)"
  >
    <div class="table-row" *ngFor="let row of data?.campaigns; trackBy: identifyRecord">
      <ng-container
        [ngTemplateOutlet]="businessValueRow"
        [ngTemplateOutletContext]="{ $implicit: row, campaignRow: true }"
      ></ng-container>
    </div>
  </div>

  <div class="table-footer">
    <div class="table-row" *ngIf="data?.total">
      <ng-container
        [ngTemplateOutlet]="businessValueRow"
        [ngTemplateOutletContext]="{ $implicit: data.total, campaignRow: false }"
      ></ng-container>
    </div>
    <div class="table-row" *ngIf="data?.summary">
      <ng-container
        [ngTemplateOutlet]="businessValueRow"
        [ngTemplateOutletContext]="{ $implicit: data.summary, campaignRow: false }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #businessValueCell let-value>
  <div class="numeric-content">
    <ng-container *ngIf="value != undefined; else noValue;">
      {{ currency }} {{ value | number: decimalFormat }}
    </ng-container>
    <ng-template #noValue>-</ng-template>
  </div>
</ng-template>

<ng-template #businessRoiCell let-value>
  <div class="numeric-content" [class.is-negative-roi]="value < 0">
    <ng-container *ngIf="value != undefined; else noValue;">
      {{ (value < 0 ? -value : value) | precisionNumberFormat }}
    </ng-container>
    <ng-template #noValue>-</ng-template>
  </div>
</ng-template>

<ng-template #businessValueRow let-record let-campaignRow="campaignRow">
  <div class="table-col left-aligned name-col">
    <div class="table-cell">
      <div
        class="name-content wrapping-content"
        [matTooltip]="record.name"
        [matTooltipDisabled]="!campaignRow"
        matTooltipClass="dark-theme-tooltip above multi-line"
        matTooltipPosition="above"
        (click)="campaignRow ? handleNameClick(record) : null"
      >
        {{ record.name }}
      </div>
    </div>
  </div>
  <div class="table-col value-col">
    <div class="table-cell">
      <ng-container
        [ngTemplateOutlet]="businessValueCell"
        [ngTemplateOutletContext]="{ $implicit: record.targetReturn }"
      ></ng-container>
    </div>
  </div>
  <div class="table-col value-col">
    <div class="table-cell">
      <ng-container
        [ngTemplateOutlet]="businessValueCell"
        [ngTemplateOutletContext]="{ $implicit: record.currentReturn }"
      ></ng-container>
    </div>
  </div>
  <div class="table-col value-col">
    <div class="table-cell">
      <ng-container
        [ngTemplateOutlet]="businessValueCell"
        [ngTemplateOutletContext]="{ $implicit: record.lowForecast }"
      ></ng-container>
    </div>
  </div>
  <div class="table-col value-col">
    <div class="table-cell">
      <ng-container
        [ngTemplateOutlet]="businessValueCell"
        [ngTemplateOutletContext]="{ $implicit: record.highForecast }"
      ></ng-container>
    </div>
  </div>
  <div class="table-col target-roi-col">
    <div class="table-cell">
      <ng-container
        [ngTemplateOutlet]="businessRoiCell"
        [ngTemplateOutletContext]="{ $implicit: record.targetROI }"
      ></ng-container>
    </div>
  </div>
  <div class="table-col current-roi-col">
    <div class="table-cell">
      <ng-container
        [ngTemplateOutlet]="businessRoiCell"
        [ngTemplateOutletContext]="{ $implicit: record.currentROI }"
      ></ng-container>
    </div>
  </div>
  <div class="table-col owner-col">
    <div class="table-cell">
      <div
        *ngIf="record.owner?.length > OWNER_NAME_TRUNCATE_LIMIT"
        class="cell-content"
        [matTooltip]="record.owner"
        matTooltipClass="dark-theme-tooltip above multi-line"
        matTooltipPosition="above"
        [stringTruncate]="record.owner"
      >
      </div>
      <div
        *ngIf="record.owner?.length < OWNER_NAME_TRUNCATE_LIMIT"
        class="cell-content wrapping-content"
        [matTooltip]="record.owner"
        matTooltipClass="dark-theme-tooltip above multi-line"
        matTooltipPosition="above"
      >
        {{ record.owner }}
      </div>
    </div>
  </div>
</ng-template>
