import { Directive, ElementRef, Inject, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[plClickOutside]',
})
export class PlClickOutsideDirective implements OnInit, OnDestroy {
  @Output('clickOutsideTrigger') trigger = new EventEmitter<MouseEvent>();
  private subscription: Subscription;

  constructor(private readonly element: ElementRef, @Inject(DOCUMENT) private readonly document: Document) {}

  ngOnInit() {
    this.subscription = fromEvent<MouseEvent>(this.document, 'click')
      .pipe(
        filter(event => {
          const target = event.target as HTMLElement;
          const host = this.element.nativeElement as HTMLElement;

          return !host.contains(target);
        }),
      )
      .subscribe(event => this.trigger.emit(event));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
