import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ObjectTimeframe } from 'app/shared/types/object-timeframe.interface';
export interface AllocationsTableEvent {
  rowName: string;
  data: AllocationsTableDataItem;
}

export type AllocationsTableSubtitleChangeEvent = Record<string, number>;

export interface AllocationsTableRow {
  name: string;
  title: string;
}

export interface AllocationsTableColumns {
  [name: string]: AllocationsTableColumn;
}

export interface AllocationsTableColumn {
  title: string;
  type: AllocationsTableColumnType;
  className: string;
  options?: AllocationsTableSelectOption[];
  optionsProvider?: (
    data: AllocationsTableData,
    updater: (options: AllocationsTableSelectOption[]) => void
  ) => void;
}

export interface AllocationsTableSelectOption {
  title: string;
  value: string | number;
  hidden?: boolean;
}

export interface AllocationsTableData {
  [allocation: string]: {
    allocationId: number;
    data: AllocationsTableDataItem[];
  }
}

export interface AllocationsTableDataItem {
  column: string;
  value: string | number;
  spentValue?: number;
  disabled: boolean;
  providedOptions?: AllocationsTableSelectOption[];
  tooltip?: string;
  hidden?: boolean;
  utClassName?: string;
}

export type AllocationsTableDataItemProvider = (
    objectAllocation: ObjectTimeframe,
    budgetTimeframe: BudgetTimeframe
  ) => AllocationsTableDataItem[];

export enum AllocationsTableColumnType {
  PlainText = 'plain_text',
  InputText = 'input_text',
  Money = 'money',
  Select = 'select',
  AllocatedBudget = 'allocated_budget'
}

export interface AllocationsTableSubtitle {
  title?: string;
  data: {
    [column: string]: {
      isEditable: boolean;
      value: number;
      utClassName?: string;
      spentValue?: number;
      tooltip?: string;
    };
  };
}

export interface AllocationsTableGesturesDataSource {
  rowName: string;
  cell: AllocationsTableDataItem;
}
