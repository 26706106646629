import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, Router } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { DetailsComponent } from '../types/details-component.interface';
import { Configuration } from 'app/app.constants';

/**
 * CanDeactivate guard that checks that there are unsaved changes in current budget object details view
 */
@Injectable()
export class UnsavedChangesGuard<TComp extends DetailsComponent> implements CanDeactivate<TComp> {
  constructor (
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly router: Router,
    private readonly configuration: Configuration,
  ) {}

  canDeactivate(
    component: TComp,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
      const nextUrl = nextState.url.replace('/', '');
      if (nextUrl === this.configuration.ROUTING_CONSTANTS.LOGIN || !component.hasUnsavedChanges() || component.isReadOnlyMode) {
        return true;
      }

      const saveFinished = new Subject<void>();
      const changesDiscarded = new Subject<void>();
      const changesAreNotValid = new Subject<void>();
      const { ROUTER_OUTLETS } = this.configuration;

      this.dialogManager.openUnsavedChangesDialog(
        () => {
          const isValid = component.validateChanges();
          if (isValid) {
            component.saveChanges(() => saveFinished.next());
          } else {
            changesAreNotValid.next();
          }
        },
        () => changesDiscarded.next()
      );

      return merge(
        changesAreNotValid.pipe(map(() => false)),
        changesDiscarded.pipe(map(() => true)),
        saveFinished.pipe(map(() => {
          const createObjectRouterState =
            nextState.root.children.find(
              child => child.outlet === ROUTER_OUTLETS.DETAILS &&
                child.routeConfig && child.routeConfig.path.includes('/create')
            );

          if (createObjectRouterState) {
            const contextData = component.getContextForChildObjectCreation();
            setTimeout(() => {
              this.router.navigate(
                [{ outlets: { [ROUTER_OUTLETS.DETAILS]: createObjectRouterState.routeConfig.path } }],
                { state: { data: contextData }, queryParamsHandling: 'preserve' }
              );
            });

            return false;
          }

          return true;
        }))
      ).pipe(
        first()
      );
    }
}
