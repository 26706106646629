<div class="exchange-rate-dialog">
  <header mat-dialog-title>Currency exchange rate notification</header>
  <div mat-dialog-content class="dialog-info">
    <p>
      {{ data?.currencyName }} was added using current <b>Today's Rate ({{ data?.currencyRate | number:'1.2-11' }})</b>
      as default.
    </p>
    <p>
      You can also customize the Exchange Rate for each month as needed by just
      clicking on it.
    </p>
  </div>
  <div mat-dialog-actions align="end" class="dialog-actions">
    <button type="button" mat-button color="primary" (click)="handleDontShowMessageClick()">
      DON'T SHOW ME THIS MESSAGE NEXT TIME
    </button>
    <button type="button" mat-flat-button mat-dialog-close color="primary">
      Ok
    </button>
  </div>
</div>
