import { Injectable } from '@angular/core';
import { SortByLabel, SortByValue } from '../types/expense-page.type';
import { SpendingPageSortByBaseService } from '@spending/services/spending-page-sort-by-base-service';
import { INVOICE_PAGE_SORT_BY } from '@shared/constants/modes.constant';

@Injectable()
export class InvoicePageSortByService extends SpendingPageSortByBaseService {

  protected menuValues = {
    [SortByLabel.Ascending]: SortByValue.Ascending,
    [SortByLabel.Descending]: SortByValue.Descending,
    [SortByLabel.InvoiceName]: SortByValue.Name,
    [SortByLabel.InvoiceNum]: SortByValue.InvoiceNumber,
    [SortByLabel.Amount]: SortByValue.ActualAmount,
    [SortByLabel.Month]: SortByValue.Timeframe,
    [SortByLabel.Submitted]: SortByValue.CreatedDate,
    [SortByLabel.By]: SortByValue.Owner,
  };

  protected readonly DEFAULT_SORT_FIELDS = {
    [SortByLabel.Ascending]: SortByValue.Ascending,
    [SortByLabel.Submitted]: SortByValue.UpdatedDate
  };
  protected readonly STORAGE_KEY = INVOICE_PAGE_SORT_BY;

  constructor() {
    super();

    this.saveDefaultSortFields();
  }

  showLatest(): void {
    const directionItem = this.menuItems.find(item => item.value === SortByValue.Descending);
    const fieldItem = this.menuItems.find(item => item.value === SortByValue.CreatedDate);
    this.onHandleSortClick(directionItem);
    this.onHandleSortClick(fieldItem);
  }
}
