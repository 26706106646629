<article class="campaign-details details-page-wrapper">
  <details-header
    [actions]="menuActions"
    [budgetList]="budgets"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedChangesFlag"
    (budgetToMoveSelected)="handleBudgetToMoveSelected($event)"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAction.bind(this))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
    [objectHierarchy]="hierarchy"
    [objectDisplayType]="objectType"
  ></details-header>
  <div *ngIf="!isLoading" class="details-content">
    <div class="details-page-title">
      <page-title
        [nameCtrl]="goalFormData.get('name')"
        [objectType]="objectType?.toLowerCase()"
        [maxLength]="budgetObjectDetailsManager.maxObjectNameLength"
      ></page-title>
      <business-value
        [currencySymbol]="companyCurrency?.symbol"
        [objectType]="objectType"
        [showTarget]="false"
        [actualBusinessValue]="totalCampaignsBusinessValue"
      ></business-value>
    </div>
    <div class="details-page-content" [formGroup]="goalFormData">
      <section class="left-side">
        <div class="details-sections">
          <div class="details-form-field goal-type">
            <fa-duotone-icon [icon]="['fad', 'bullseye-arrow']" flip="horizontal"></fa-duotone-icon>
            <mat-form-field *ngIf="!isCustomTypeEntering" appearance="fill" class="auto-width">
              <mat-label>Goal Type</mat-label>
              <mat-select disableOptionCentering formControlName="typeId" required panelClass="mat-select-fixed-position" (closed)="handleTypeChange()" [placeholder]="'Goal Type *'">
                <mat-option *ngFor="let item of goalTypes" [value]="item.id">
                  {{item.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="goalFormData.controls['typeId'].invalid">
                Goal type is required
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="isCustomTypeEntering" appearance="fill" class="no-validate">
              <mat-label>Goal Type</mat-label>
              <input matInput autoFocus formControlName="customType" (blur)="handleCustomTypeChange()" />
            </mat-form-field>
          </div>
        </div>
        <div class="details-sections">
          <div class="details-form-field w-100 attachments">
            <fa-icon [icon]="['far', 'paperclip']"></fa-icon>
            <attachments-list
              [disableInput]="!currentState?.objectId"
              [isReadOnlyMode]="isReadOnlyMode"
              [objectTypeName]="objectType"
              [attachments]="attachmentsManager.attachments"
              (onAttachFiles)="handleFileAttached($event)"
              (onDeleteAttachment)="handleFileDelete($event)"
              (onDownloadAttachment)="handleFileDownload($event)"
            ></attachments-list>
          </div>
        </div>
        <div class="details-sections">
          <div class="details-form-field">
            <fa-duotone-icon [icon]="['fad', 'tags']"></fa-duotone-icon>
            <tags-control
              #tagsControl
              [tagsList]="currentState?.tagMappings"
              [autocompleteItems]="tagsManager.autocompleteItems"
              [editOnly]="true"
              (onAddTag)="addTag($event)"
              (onCreateTag)="createTag($event)"
              (onRemoveTag)="removeTag($event)">
            </tags-control>
          </div>
        </div>
        <div class="details-sections">
          <div class="details-form-field w-100">
            <fa-duotone-icon [icon]="['fad', 'clipboard']"></fa-duotone-icon>
            <linkified-text formControlName="notes" label="Notes"></linkified-text>
          </div>
        </div>
      </section>
      <section class="right-side">
        <div class="details-sections">
          <details-metrics
            [defaultStartDate]="budget?.budget_from"
            [defaultEndDate]="budget?.budget_to"
            [objectType]="objectType"
            [objectId]="currentState?.objectId"
            [contextCurrency]="companyCurrency?.symbol"
            [objectTotals]="currentState?.statusTotals"
            [metricTypes]="metricsManager.types"
            [savedMetricMappings]="currentState?.metricMappings"
            [isReadOnlyMode]="isReadOnlyMode"
            [products]="metricsManager.products"
            [isTotalCurrentMode]="true"
            [totalMetricData]="currentState?.metricData"
            [isPowerUser]="isPowerUser"
            (onAddMapping)="addMetricMappings($event)">
          </details-metrics>
        </div>
        <div class="details-sections">
          <details-status-bar
            [graphData]="currentState?.statusTotals"
            [objectDisplayType]="objectType"
            [objectName]="currentState?.name"
            [objectId]="currentState?.objectId"
            [currency]="companyCurrency?.symbol"
            [isGoal]="true"
          ></details-status-bar>
        </div>
        <div class="details-sections">
          <child-objects
            [objects]="currentState?.campaigns"
            [objectType]="configuration.OBJECT_TYPES.campaign"
            [users]="companyUsers"
            [currency]="companyCurrency?.symbol"
            [company]="company"
            [sharedCostRules]="sharedCostRules"
            [segmentNameById]="segmentNameById"
            [segmentList]="segments"
            [isReadOnlyMode]="isReadOnlyMode"
            [showSegment]="false"
            [showBusinessValue]="true"
            [products]="metricsManager.products"
            [metricTypes]="metricsManager.types"
            (addNewObject)="addNewCampaign()"
            (updateTotalBusinessValue)="totalCampaignsBusinessValue = $event"
          ></child-objects>
        </div>
        <div class="details-sections">
          <child-objects
            [objects]="currentState?.programs"
            [objectType]="configuration.OBJECT_TYPES.program"
            [users]="companyUsers"
            [currency]="companyCurrency?.symbol"
            [company]="company"
            [sharedCostRules]="sharedCostRules"
            [segmentNameById]="segmentNameById"
            [segmentList]="segments"
            [isReadOnlyMode]="isReadOnlyMode"
            (addNewObject)="addNewProgram()"
          ></child-objects>
        </div>
        <div class="details-sections">
          <details-expenses
            [withBreakdown]="false"
            [isAddNewExpenseAllowed]="false"
            [expensesTotals]="detailsExpensesTotals"
            (addExpense)="addNewExpense()"
            (viewExpenses)="viewExpenses()"
          ></details-expenses>
        </div>
      </section>
    </div>
  </div>
</article>
