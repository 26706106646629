import {Component, EventEmitter, Input, Output, ViewChildren, ViewChild, AfterContentChecked} from '@angular/core';

export interface ActionMenuItem {
  cssClass?: string,
  text: string,
  value: string,
  selected?: boolean,
  active?: boolean,
  menuLabel?: string;
  isUnselectable?: boolean,
  isIndented?: boolean,
  onClick?: () => any,
  forTableView?: boolean,
  forChartView?: boolean,
  tooltipText?: string,
}

const TOP_OFFSET = 10;

@Component({
  selector: 'actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss']
})
/**
 * @class - WidgetContainer
 */
export class ActionsMenuComponent implements AfterContentChecked {
  @Input() actionsMenuItems: ActionMenuItem[];
  @Input() selectedMenuItemValue = '';
  @Output() onActionsMenuStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSelectValue: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren('opt') options;
  @ViewChild('scrollContainer') scrollContainer;

  isActionsMenuActivated = false;
  isOpenActionsMenuClicked = false;
  isDeferredScrolling = false;

  constructor() {}

  ngAfterContentChecked() {
    if (this.isDeferredScrolling) {
      this.scrollToActiveElement();
    }
  }

  activateAction(menuItem: ActionMenuItem) {
    if (menuItem.isUnselectable) {
      return;
    }

    if (typeof menuItem.onClick === 'function') {
      menuItem.onClick();
    }
    this.onSelectValue.next(menuItem.value);
    this.closeActionsMenu();
  }

  closeActionsMenu() {
    // Check isOpenActionsMenuClicked to make sure that clickOutside directive doesn't close the menu incorrectly
    if (!this.isOpenActionsMenuClicked) {
      this.isActionsMenuActivated = false;
      this.onActionsMenuStateChange.next(false);
    }
  }

  openActionsMenu() {
    this.isActionsMenuActivated = true;
    this.isOpenActionsMenuClicked = true;
    window.setTimeout(() => { this.isOpenActionsMenuClicked = false; }, 200);
    this.onActionsMenuStateChange.next(true);
    this.isDeferredScrolling = true;
  }

  toggleMenuState() {
    this.isActionsMenuActivated ? this.closeActionsMenu() : this.openActionsMenu();
  }

  scrollToActiveElement() {
    const activeItemIndex: number = this.actionsMenuItems.findIndex((item: ActionMenuItem) => item.active);
    const activeItem = this.options._results[activeItemIndex];
    if (!activeItem) {
      return;
    }

    const activeElement = activeItem.nativeElement;
    const activeElementTopPosition = activeElement.offsetTop - TOP_OFFSET;
    this.scrollContainer.nativeElement.scrollTop = activeElementTopPosition;
    this.isDeferredScrolling = false;
  }
}
