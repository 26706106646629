import { Component } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-fa-arrow-down-right',
  templateUrl: './icon-fa-arrow-down-right.component.html'
})
export class IconFaArrowDownRightComponent extends IconBaseComponent {
  constructor() {
    super();
  }
}
