import { CheckboxValue } from '../enums/checkbox-value.enum';
import { SelectItem, SelectedValue, SelectGroupsCollapseState } from '../types/select-groups.interface';
import { Component } from '@angular/core';
import { createRegExpFromString } from '../utils/common.utils';

@Component({
  template: ''
})
export abstract class SelectGroupsComponent {
  public CheckboxValue = CheckboxValue;
  public hasGroups: boolean;
  public groupsCollapseState: SelectGroupsCollapseState = {
    collapsed: {} as { [groupId: number]: boolean },
    allCollapsed: false,
  };

  protected static filterItemsByText(
    filterText: string,
    showedOptions: SelectItem[],
    skipGroupsSelection: boolean,
    nestedParentNames = false,
  ) {
    if (filterText.length > 2) {
      const regEx = createRegExpFromString(filterText, 'i');
      const satisfiesSearch = (item: SelectItem) => {
        return String(item.title).search(regEx) >= 0;
      };
      showedOptions.forEach(item => {
        const isTopLevelHidden = !satisfiesSearch(item);
        if (item.children?.length) {
          item.children.forEach(child => {
            if (!nestedParentNames) {
              child.hidden = !satisfiesSearch(child);
            } else {
              child.hidden = !satisfiesSearch(child) && isTopLevelHidden;
            }
          })
          const everyChildrenHidden = item.children.every(child => child.hidden);
          const someChildVisible = item.children.some(child => !child.hidden);

          item.hidden = (everyChildrenHidden && skipGroupsSelection) ||
            (isTopLevelHidden && !someChildVisible);
        } else {
          item.hidden = isTopLevelHidden;
        }
      });
    } else {
      showedOptions.forEach(option => {
        option.hidden = false;
        if (option.children?.length) {
          option.children.forEach(child => child.hidden = false);
        }
      })
    }
  }

  private static resetAllCollapsedState(groupsCollapseState: SelectGroupsCollapseState) {
    const collapsedCount = Object.keys(groupsCollapseState.collapsed).length
    groupsCollapseState.allCollapsed = collapsedCount !== 0;
  }

  private static toggleGroupCollapse(collapsed: boolean, groupId: SelectedValue, groupsCollapseState: SelectGroupsCollapseState) {
    if (collapsed) {
      groupsCollapseState.collapsed[groupId] = true;
    } else {
      delete groupsCollapseState.collapsed[groupId];
    }
    SelectGroupsComponent.resetAllCollapsedState(groupsCollapseState);
  }

  public static getVisibleOptionsIds(optionsArr: SelectItem[], includeGroups: boolean): SelectedValue[] {
    return optionsArr.reduce((arr, option) => {
      if (!option.hidden) {
        if (includeGroups || !option.children?.length) {
          arr.push(option.value);
        }
        if (option.children?.length) {
          const visibleChildrenIds = option.children
            .filter(item => !item.hidden)
            .map(item => item.value)
          arr.push(...visibleChildrenIds);
        }
      }
      return arr;
    }, []);
  }

  public static getParentSelectionState(selectedOptionsLength, showedOptionsLength): CheckboxValue {
    return selectedOptionsLength === showedOptionsLength ?
      CheckboxValue.Active : selectedOptionsLength === 0 ? CheckboxValue.NotActive : CheckboxValue.Indeterminate;
  }

  public toggleAllGroupsCollapse(collapsed: boolean, options: SelectItem[]) {
    if (collapsed) {
      const groupsIds = options
        .filter(opt => opt.children?.length)
        .map(opt => opt.value);
      groupsIds.forEach(
        groupId => this.groupsCollapseState.collapsed[groupId] = true
      )
    } else {
      this.groupsCollapseState.collapsed = {};
    }
    SelectGroupsComponent.resetAllCollapsedState(this.groupsCollapseState);
  }

  public toggleGroupCollapse(currentState: boolean, groupId: SelectedValue) {
    SelectGroupsComponent.toggleGroupCollapse(!currentState, groupId, this.groupsCollapseState);
  }
}
