import { ManageCegTableDataService } from '../manage-ceg-table-data.service';
import { Configuration } from 'app/app.constants';
import { BudgetObjectEventContext } from 'app/budget-object-details/types/budget-object-event.interface';
import { Observable, of, switchMap } from 'rxjs';
import { BaseBudgetObjectEventHandler } from './base-budget-object-event-handler';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { LightProgram } from '@shared/types/program.interface';
import { map, tap } from 'rxjs/operators';
import { BudgetPlanObjects } from '@shared/types/budget-plan-objects.type';
import { ManageCegTableRow } from '@manage-ceg/types/manage-ceg-page.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { getParentObject } from 'app/budget-object-details/utils/object-details.utils';
import { sortObjectsByName } from '@shared/utils/budget.utils';
import { CEGStatus } from '@shared/enums/ceg-status.enum';

export class ProgramEventHandler extends BaseBudgetObjectEventHandler {
  private readonly budgetDataService: BudgetDataService;
  private readonly planObjectsLoader: () => Observable<BudgetPlanObjects>;

  public constructor(
    manageCegTableDataService: ManageCegTableDataService,
    config: Configuration,
    budgetDataService: BudgetDataService,
    planObjectsLoader: () => Observable<BudgetPlanObjects>
  ) {
    super(manageCegTableDataService, config);
    this.budgetDataService = budgetDataService;
    this.planObjectsLoader = planObjectsLoader;
  }

  protected onCreate(data: BudgetObjectEventContext): Observable<boolean> {
    this.notifyProgramsChanged();
    const createdProgram = this.budgetDataService.lightProgramsSnapshot.find(program => program.id === data.objectId);

    if (!createdProgram) {
      return of(false);
    }

    return this.addProgramsToTableDataInputs([createdProgram]).pipe(
      switchMap(() => {
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate = this.getRowsToUpdateForAddedProgram(createdProgram.id, createdProgram.splitRuleId);
        return this.updateParentRows(rowsToUpdate).pipe(
          map(() => true)
        );
      })
    );
  }

  protected onUpdate(data: BudgetObjectEventContext): Observable<boolean> {
    return this.applyUpdatedProgram(data).pipe(
      switchMap(({ updatedProgram, prevProgram}) => {
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate = this.getRowsToUpdateForUpdatedPrograms([updatedProgram], [prevProgram]);
        return this.updateParentRows(rowsToUpdate).pipe(
          map(() => true)
        );
      })
    );
  }

  protected onDelete(data: BudgetObjectEventContext): Observable<boolean> {
    return this.onProgramRemoved(data);
  }

  protected onMoved(data: BudgetObjectEventContext): Observable<boolean> {
    return this.onProgramRemoved(data);
  }

  public handleProgramCreated(program: LightProgram): Observable<boolean> {
    return this.applyCreatedProgram(program).pipe(
      switchMap(() => {
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate = this.getRowsToUpdateForAddedProgram(program.id, program.splitRuleId);
        return this.updateParentRows(rowsToUpdate).pipe(
          map(() => true)
        );
      })
    );
  }

  public handleBulkDelete(programIds: number[]): Observable<boolean> {
    return of(null).pipe(
      switchMap(() => {
        const removedPrograms = this.applyRemovedPrograms(programIds);
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate =
          this.getRowsToUpdateForRemovedObjects(
            removedPrograms,
            campaign => this.getParentObject(campaign?.campaignId, campaign?.goalId)
          );
        return this.updateParentRows(rowsToUpdate).pipe(map(() => true));
      })
    );
  }

  public handleBulkUpdate(updatedPrograms: LightProgram[]): Observable<boolean> {
    return this.applyUpdatedPrograms(updatedPrograms).pipe(
      switchMap(({ prevPrograms}) => {
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate = this.getRowsToUpdateForUpdatedPrograms(updatedPrograms, prevPrograms);
        return this.updateParentRows(rowsToUpdate).pipe(map(() => true));
      })
    );
  }

  public handleBulkAmountStatusChanged(programIds: number[], newAmountStatus: CEGStatus): Observable<boolean> {
    const programs = this.budgetDataService.lightProgramsSnapshot.filter(program => programIds.includes(program.id));
    programs.forEach(program => program.amountStatus = newAmountStatus);

    programIds.forEach(programId => {
      const dataInputsPrograms =
        this.manageCegTableDataService.tableDataInputs.expGroups.filter(
          inputsProgram => [inputsProgram.id, inputsProgram.subProgramId].includes(programId)
        );
      dataInputsPrograms.forEach(campaign => campaign.amountStatus = newAmountStatus);
    });

    this.manageCegTableDataService.updateTable();

    return this.updateParentRows(this.getRowsToUpdateForUpdatedPrograms(programs, [])).pipe(
      map(() => true)
    );
  }

  private applyCreatedProgram(createdProgram: LightProgram): Observable<any> {
    this.budgetDataService.lightProgramsSnapshot.push(createdProgram);
    sortObjectsByName(this.budgetDataService.lightProgramsSnapshot);
    this.notifyProgramsChanged();
    return this.addProgramsToTableDataInputs([createdProgram]);
  }

  private onProgramRemoved(data: BudgetObjectEventContext): Observable<boolean> {
    return of(null).pipe(
      switchMap(() => {
        this.applyRemovedProgram(data);
        this.manageCegTableDataService.updateTable();
        const rowsToUpdate = this.getRowsToUpdateForRemovedProgram(data);
        return this.updateParentRows(rowsToUpdate).pipe(
          map(() => true)
        );
      })
    );
  }

  private applyRemovedProgram(data: BudgetObjectEventContext): void {
    this.notifyProgramsChanged();
    const removedProgram = data.extras?.prevObject as LightProgram;
    if (removedProgram) {
      this.removeProgramFromTableDataInputs(data.extras?.prevObject as LightProgram);
    }
  }

  private applyUpdatedPrograms(
    updatedPrograms: LightProgram[]
  ): Observable<{ updatedPrograms: LightProgram[]; prevPrograms: LightProgram[] }> {
    const currentAllLightPrograms = this.budgetDataService.lightProgramsSnapshot;
    const prevPrograms: LightProgram[] = [];

    for (const updatedProgram of updatedPrograms) {
      const targetProgramIndex = currentAllLightPrograms.findIndex(program => program.id === updatedProgram.id);
      prevPrograms.push(currentAllLightPrograms[targetProgramIndex]);
      currentAllLightPrograms[targetProgramIndex] = updatedProgram;
    }

    this.budgetDataService.setLightPrograms(currentAllLightPrograms);

    this.removeProgramsFromTableDataInputs(prevPrograms);
    return this.addProgramsToTableDataInputs(updatedPrograms).pipe(
      map(() => ({ updatedPrograms, prevPrograms }))
    );
  }

  private applyUpdatedProgram(data: BudgetObjectEventContext): Observable<{ updatedProgram: LightProgram; prevProgram: LightProgram }> {
    const updatedProgram = this.budgetDataService.lightProgramsSnapshot.find(program => program.id === data.objectId);
    const prevProgram = data.extras?.prevObject as LightProgram;
    this.notifyProgramsChanged();
    this.removeProgramFromTableDataInputs(prevProgram);
    return this.addProgramsToTableDataInputs([updatedProgram]).pipe(
      map(() => ({ updatedProgram, prevProgram }))
    );
  }

  private updateTableDataInputPrograms(targetPrograms: LightProgram[]): Observable<any> {
    return this.updateTableDataInputObjects(
      targetPrograms,
      this.manageCegTableDataService.tableDataInputs.expGroups,
      this.budgetDataService.getLightPrograms.bind(this.budgetDataService),
      this.config.programStatusNames.active,
      this.budgetDataService.selectedBudgetSnapshot
    );
  }

  private addProgramsToTableDataInputs(targetPrograms: LightProgram[]): Observable<any> {
    const { campaigns, expGroups } = this.manageCegTableDataService.tableDataInputs;
    return this.updateTableDataInputPrograms(targetPrograms).pipe(
      switchMap(() =>
        this.manageCegTableDataService.isFilteredMode ? this.planObjectsLoader() : of ({ campaigns, expGroups })
      ),
      tap(planObjects => this.manageCegTableDataService.updatePlanObjects(planObjects))
    );
  }

  private getRowsToUpdateForAddedProgram(programId: number, sharedCostRuleId: number | null): ManageCegTableRow[] {
    return this.getRowsToUpdateForAddedObject(programId, sharedCostRuleId, ManageTableRowType.ExpenseGroup);
  }

  private getRowsToUpdateForRemovedProgram(data: BudgetObjectEventContext): ManageCegTableRow[] {
    return this.getNearestLoadedParentRows(data.parentObject, data.segmentId, data.sharedCostRuleId);
  }

  private applyRemovedPrograms(programIds: number[]): LightProgram[] {
    const currentAllLightPrograms = this.budgetDataService.lightProgramsSnapshot;
    const removedCampaigns = this.applyRemovedObjects(programIds, currentAllLightPrograms);
    this.budgetDataService.setLightPrograms(currentAllLightPrograms);
    this.removeProgramsFromTableDataInputs(removedCampaigns);
    return removedCampaigns;
  }

  private removeProgramsFromTableDataInputs(removedPrograms: LightProgram[]): void {
    for (const removedProgram of removedPrograms) {
      this.removeObjectFromTableDataInputs(removedProgram, this.manageCegTableDataService.tableDataInputs.expGroups);
    }
  }

  private removeProgramFromTableDataInputs(targetProgram: LightProgram): void {
    this.removeProgramsFromTableDataInputs([targetProgram]);
  }

  private getRowsToUpdateForUpdatedPrograms(updatedPrograms: LightProgram[], prevPrograms: LightProgram[]): ManageCegTableRow[] {
    return this.getRowsToUpdateForUpdatedObjects(
      updatedPrograms,
      prevPrograms,
      ManageTableRowType.ExpenseGroup,
      (program: LightProgram) => getParentObject(this.config, program.campaignId, program.goalId)
    );
  }

  private notifyProgramsChanged() {
    this.budgetDataService.setLightPrograms(this.budgetDataService.lightProgramsSnapshot);
  }
}
