import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { SelectGroupsComponent } from 'app/shared/services/select-groups';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { GroupsSelectionState, SelectedValue, SelectItem } from 'app/shared/types/select-groups.interface';
import { getDiff } from 'recursive-diff';
import { Filter, FilterType, OnFilterChangeEvent } from '../filters.interface';
import { UntypedFormControl } from '@angular/forms';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';

@Component({
  selector: 'filter-control',
  templateUrl: './filter-control.component.html',
  styleUrls: ['./filter-control.component.scss']
})
export class FilterControlComponent extends SelectGroupsComponent implements OnInit, OnChanges {
  protected readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  @Input() filter: Filter;
  @Input() selectedOptions: SelectItem[];
  @Output() onChange = new EventEmitter<OnFilterChangeEvent>();
  public selectControl = new UntypedFormControl([]);
  public showedOptions: SelectItem[] = [];
  public filterOptionsStore: Record<string, SelectItem> = {};
  public allSelectionState: CheckboxValue;
  public groupsSelectionState: GroupsSelectionState = {};
  public selectedGroupsLength = 0;
  public CEGStatusEnabled: boolean;
  noOptionSelected: boolean = false;
  public FilterType = FilterType; 
  searchFilteredOptions: SelectItem[] = [];
 
  ngOnInit(): void {
    this.budgetObjectDetailsManager.getCurrentBudget().subscribe(data => {
      this.CEGStatusEnabled = data.new_campaigns_programs_structure
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter) {
      this.showedOptions = createDeepCopy(this.filter.availableItems);
      if(this.showedOptions[this.showedOptions.length - 1]?.alwaysOnTop) {
        // Move "Not Specified" option to the top of shpwedOptions
        this.showedOptions.unshift(this.showedOptions.pop()); 
      }
      // create a copy of filteredSearchOptions to avoid changing the original array
      this.searchFilteredOptions = this.showedOptions.concat();
      this.hasGroups = this.showedOptions.some(opt => opt.children?.length);
      FilterControlComponent.fillFiltersStore(this.showedOptions, this.filterOptionsStore);
      this.setFilterControlValue();
    }
    if (changes.selectedOptions) {
      if (this.showedOptions.length) {
        this.setFilterControlValue();
      }
    }
  }

  public static fillFiltersStore(optionsArr: SelectItem[], store): void {
    optionsArr.forEach(item => {
      store[item.value] = item;
      if (item.children?.length) {
        this.fillFiltersStore(item.children, store);
      }
    })
  }

  public setFilterControlValue(): void {
    const selectedIds = this.selectedOptions.reduce((idsList, opt: SelectItem) => {
      const item = this.filterOptionsStore[opt.value];
      if(!item) { return idsList; } // Return if filter control option is not found
      item?.children?.length ? idsList.groups.push(opt.value) : idsList.single.push(opt.value);
      return idsList;
    }, { single: [], groups: [] });

    this.showedOptions
      .filter(item => item.children?.length)
      .forEach(option => {
        if (!this.filter.skipGroupsSelection) {
          this.groupsSelectionState[option.value] = {
            allSelected: selectedIds.groups.includes(option.value),
            partialSelected: false
          };
        } else {
            this.updateGroupSelectionState(option);
        }
      })
    this.updateSelectedGroupsLength();
    this.patchValues(selectedIds.single, true, true);
  }

  filterItemsByText(filterText: string, skipGroupsSelection) {
    FilterControlComponent.filterItemsByText(
      filterText,
      this.showedOptions,
      skipGroupsSelection,
    );
    // Only render visible options (instead of hiding it in DOM and processing it in every search)
    // This will significantly improve rendering performance for large lists 
    
    const selectedValues = new Set(this.selectControl.value);
    let filteredOptions = this.showedOptions.filter(option => !option.hidden);
    const selectedOptions = this.showedOptions.filter(option => selectedValues.has(option.value) || option.children?.some(child => selectedValues.has(child.value)));
    this.searchFilteredOptions = [...new Set([...filteredOptions, ...selectedOptions])];
    this.updateAllSelectionState();
  }

  updateSelectedGroupsLength(): void {
    this.selectedGroupsLength = Object.values(this.groupsSelectionState)
      .filter(groupState => groupState.allSelected && !groupState.partialSelected).length;
  }

  handleSelectionChange() {
    setTimeout(() => {
      this.updateAllSelectionState();
    }, 0);
  }

  updateGroupSelectionState(group: SelectItem) {
    if (!this.filter.skipGroupsSelection) {
      return;
    }
    const selectedChildren = group.children.filter(child => this.selectControl.value.includes(child.value));
    const allSelected = selectedChildren.length === group.children.length;
    this.groupsSelectionState[group.value] = {
      allSelected,
      partialSelected: !!selectedChildren.length && !allSelected,
    }
    this.updateSelectedGroupsLength();
  }

  updateSelectedOptions() {
    const getAllSelectedIds = (): SelectedValue[] => {
      const singleSelected = this.selectControl.value;
      const selectedGroups = [];

      if (!this.filter.skipGroupsSelection) {
        Object.entries(this.groupsSelectionState)
          .forEach(([key, group]) => {
            if (group.allSelected) {
              selectedGroups.push(+key);
            }
          });
      }

      return [...singleSelected, ...selectedGroups];
    };

    const previousSelectedIds = this.selectedOptions.map(opt => opt.value);
    const newSelectedIds = getAllSelectedIds();
    const changed = getDiff(previousSelectedIds, newSelectedIds).length > 0;

    if (changed) {
      this.onChange.emit({
        fieldName: this.filter.fieldName,
        values: newSelectedIds.map(id => this.filterOptionsStore[id])
      });
    }
  }

  updateAllSelectionState(): void {
    const showedOptionsIds = FilterControlComponent.getVisibleOptionsIds(this.showedOptions, false);
    let single = 0;

    // This Fixes the issue of Select ALl state not being transitioned from Active to Intermediate on deselecting a single option
    this.noOptionSelected = this.selectControl.value.includes(0);
    single = this.selectControl.value.filter(val => showedOptionsIds.includes(val)).length;
    
    const getSelectedLength = (): number => {
      const parent = Object.keys(this.groupsSelectionState)
        .filter(groupId => showedOptionsIds.includes(+groupId))
        .reduce((count, groupId) => {
          count += this.groupsSelectionState[groupId].allSelected ? 1 : 0;
          return count;
        }, 0);
      return single + parent;
    };

    const showedOptionsLength =  showedOptionsIds.length;

    this.allSelectionState = SelectGroupsComponent
      .getParentSelectionState(getSelectedLength(), showedOptionsLength);
  }

  toggleAllSelection(checked: boolean) {
    const visibleOptionsIds = FilterControlComponent.getVisibleOptionsIds(this.showedOptions, true);
    this.setGroupsState(checked);
    this.patchValues(visibleOptionsIds, checked);
  }

  toggleGroupSelection(checked: boolean, group: SelectItem) {
    const childrenVisibleIds = group.children
      .filter(child => !child.hidden)
      .map(child => child.value);
    this.setGroupsState(checked, group.value);
    this.patchValues(childrenVisibleIds, checked);
  }

  setGroupsState(state: boolean, groupId: SelectedValue = null): void {
    const setStateForGroup = (id: SelectedValue, checked: boolean) => {
      this.groupsSelectionState[id] = {
        allSelected: checked,
        partialSelected: false,
      }
    }
    if (groupId) {
      setStateForGroup(groupId, state);
      return;
    }
    this.showedOptions
      .filter(option => option.children?.length)
      .forEach((option) => {
        if (!option.hidden) {
          if (option.children?.length) {
            setStateForGroup(option.value, state);
          }
        }
      });
    this.updateSelectedGroupsLength();
  }

  patchValues(optionIds: SelectedValue[], state: boolean, isReset = false): void {
    const selectedOptions = new Set(!isReset ? this.selectControl.value : []);
    optionIds.forEach(item => state ? selectedOptions.add(item) : selectedOptions.delete(item));
    this.selectControl.patchValue(Array.from(selectedOptions));
    this.updateAllSelectionState();
  }

  checkDisabledTag(title: string) {
    let customTitleTag;
    if (title && title.search("(Disabled)") >= 0 && !this.CEGStatusEnabled) {
      customTitleTag = title.split(" (Disabled)")[0];
    } else {
      customTitleTag = title;
    }
    return customTitleTag;
  }
}
