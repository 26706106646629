<div class="indicator" #scrollIndicator *ngIf="isSticky"></div>
<div
  class="hierarchy-option"
  [ngClass]="{
    'hidden': isHidden,
    'selected': isSelected,
    'default': option.isDefault,
    'selected-child': isMarkedAsSelected,
    'closed': option.closed,
    'sticky-option': isSticky,
    'is-stuck': isOptionStuck,
    'segment-option': option.objectType === SidebarObjectTypes.Segment || option.objectType === SidebarObjectTypes.SegmentGroup
  }"
  (click)="toggleSelectedState()"
>
  <span class="spacer hierarchy-marker" *ngFor="let space of spaces"></span>
  <arrow-toggler
    class="toggler hierarchy-marker"
    *ngIf="option.hasChildren; else dot"
    [active]="option.collapsed"
    [disabled]="isSearchActive"
    (change)="toggleCollapsedState()"
    (click)="$event.stopPropagation()"
  ></arrow-toggler>
  <ng-template #dot>
    <icon-dot class="dot-icon hierarchy-marker" color="#061F38"></icon-dot>
  </ng-template>

  <icon-by-object-type
    [objectType]="sidebarToGlobalMap[option.objectType]"
    [isClosed]="option.closed"
    [size]="16"
  ></icon-by-object-type>
  <span
    class="option-title"
    [style.max-width.%]="(option.sharedCostRuleId ? 83 : 90) - option.level * 5"
    [innerHTML]="(option.title + closedLabel) | highlight: searchQuery"
  ></span>

  <span *ngIf="hasCounter && !hideGroupCount" class="counter">
    {{ expenseCount | number: numberFormat }}
  </span>
  <icon-shared
    *ngIf="option.sharedCostRuleId"
    class="icon-shared"
    sharedCostRulesTooltip
    [actionTooltipContext]="option.sharedCostRuleId"
    [segments]="segments"
    [sharedCostRules]="sharedCostRules"
    [state]="2"
    [showTooltip]="true">
  </icon-shared>
</div>

