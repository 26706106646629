<div class="table-grid-toggle">
  <button
    class="view-button"
    mat-icon-button
    [disableRipple]="true"
    (click)="onShowClassicView.emit()">
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 6.375C8.25 7.42969 7.39062 8.25 6.375 8.25H2.625C1.57031 8.25 0.75 7.42969 0.75 6.375V2.625C0.75 1.60938 1.57031 0.75 2.625 0.75H6.375C7.39062 0.75 8.25 1.60938 8.25 2.625V6.375ZM8.25 16.375C8.25 17.4297 7.39062 18.25 6.375 18.25H2.625C1.57031 18.25 0.75 17.4297 0.75 16.375V12.625C0.75 11.6094 1.57031 10.75 2.625 10.75H6.375C7.39062 10.75 8.25 11.6094 8.25 12.625V16.375ZM10.75 2.625C10.75 1.60938 11.5703 0.75 12.625 0.75H16.375C17.3906 0.75 18.25 1.60938 18.25 2.625V6.375C18.25 7.42969 17.3906 8.25 16.375 8.25H12.625C11.5703 8.25 10.75 7.42969 10.75 6.375V2.625ZM18.25 16.375C18.25 17.4297 17.3906 18.25 16.375 18.25H12.625C11.5703 18.25 10.75 17.4297 10.75 16.375V12.625C10.75 11.6094 11.5703 10.75 12.625 10.75H16.375C17.3906 10.75 18.25 11.6094 18.25 12.625V16.375Z" fill="currentColor"/>
    </svg>
    Classic View
  </button>
</div>
