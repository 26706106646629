import { Injectable } from '@angular/core';
import { ExpensesService } from './backend/expenses.service';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { UserPermissionLevel } from '@shared/utils/common.utils';
import { UserManager } from '../../user/services/user-manager.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceLiveTracking {
  private intervalMs = 1000 * 60 * 5;
  private stopTracking$ = new Subject<void>();
  private _invoiceTotalCount$ = new BehaviorSubject<number>(null);
  private _hasInvoiceAccess$ = new BehaviorSubject<boolean>(null);
  private _isLastInvoice$ = new BehaviorSubject<boolean>(false);
  public invoiceTotalCount$ = this._invoiceTotalCount$.asObservable();
  public hasInvoiceAccess$ = this._hasInvoiceAccess$.asObservable().pipe(filter(hasAccess => hasAccess !== null));
  public isLastInvoice$ = this._isLastInvoice$.asObservable();

  constructor(
    public readonly expensesService: ExpensesService,
    private readonly budgetDataService: BudgetDataService,
    private readonly userManager: UserManager,
  ) {
    this.userManager.userPermissionLevel$.subscribe(level => {
      const hasInvoiceAccess = level >= UserPermissionLevel.PartialSomeSegment;
      this._hasInvoiceAccess$.next(hasInvoiceAccess);

      if (hasInvoiceAccess) {
        this.restartTracking();
      } else {
        this.stopTracking$.next();
        this._invoiceTotalCount$.next(null);
      }
    });
  }

  public restartTracking(): void {
    this.stopTracking$.next();
    timer(0, this.intervalMs).pipe(
      takeUntil(this.stopTracking$)
    ).subscribe(() => {
      this.refreshCount();
    })
  }

  public setIsLastInvoice (isLast: boolean): void {
    this._isLastInvoice$.next(isLast);
  }

  public get hasInvoices(): boolean {
    return this._invoiceTotalCount$.getValue() > 0;
  }

  public get hasInvoiceAccess(): boolean {
    return this._hasInvoiceAccess$.getValue();
  }

  public get invoiceCount (): number {
    return this._invoiceTotalCount$.getValue();
  }

  private refreshCount(): void {
    const budget = this.budgetDataService.selectedBudgetSnapshot;
    if (!budget) {
      return;
    }
    const params = {
      company: budget.company.toString(),
      budget_id: budget.id.toString(),
      is_verified: false,
    };
    this.expensesService.getExpensesTotalAmount(params)
      .pipe(
        takeUntil(this.stopTracking$)
      ).subscribe(resp => {
        this._invoiceTotalCount$.next(resp.total_count);
    })
  }
}
