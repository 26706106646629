import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ExternalIntegrationObjectTypes } from 'app/shared/constants/external-integration-object-types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Component({
  selector: 'object-name-cell',
  templateUrl: './object-name-cell.component.html',
  styleUrls: ['./object-name-cell.component.scss']
})
export class ObjectNameCellComponent {
  @Input() icon: IconProp;
  @Input() name: string;
  @Input() withTooltip = true;
  @Input() isClickable = false;
  @Input() dragging = false;
  @Input() isClosed = false;
  @Input() objectTypeName: string;
  @Input() rowType: ManageTableRowType;
  @Output() onClick = new EventEmitter<void>();

  public externalTypes = ExternalIntegrationObjectTypes;
  public manageTableRowType = ManageTableRowType;

  public handleClick() {
    if (this.isClickable) {
      this.onClick.emit();
    }
  }

}
