import { Component, Input } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { messages, objectPlaceholderName } from 'app/budget-object-details/messages';

@Component({
  selector: 'unique-id',
  templateUrl: './unique-id.component.html',
  styleUrls: [ './unique-id.component.scss']
})
export class UniqueIdComponent {
  @Input() objectType = '';
  @Input() uniqueId = '';
  @Input() label: string;
  @Input() tooltipText = 'Click to copy';
  @Input() id: string;
  public readonly objectTypes = {
    [this.configuration.OBJECT_TYPES.campaign]: 'Campaign',
    [this.configuration.OBJECT_TYPES.program]: 'Expense Group'
  }

  constructor(
    private readonly configuration: Configuration,
    private readonly utilityService: UtilityService
  ) {}

  get getObjectName(): string {
    return this.label ? this.label : (this.objectTypes[this.objectType] + ' ID');
  }

  copy(): void {
    if (!this.uniqueId) {
      return;
    }
    navigator.clipboard.writeText(this.uniqueId).then(() => {
      this.utilityService.showToast(
        {
          Message: messages.COPY_OBJECT_ID_SUCCESS_MSG.replace(objectPlaceholderName, this.getObjectName)
        }
      );
    },
    () => {
      this.utilityService.showToast(
        { Message: messages.COPY_OBJECT_ID_ERROR_MSG }
      );
    });
  }
}
