import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MetricMaster } from '@common-lib/lib/corporate-page/metric-funnels.types';
import { Validators } from '@angular/forms';
import { EditableContentComponent } from '@common-lib/lib/utils/editable-content';
import { MetricFunnelsValidationService } from '@common-lib/lib/utils/metric-funnels-validation.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'plc-editable-value',
  templateUrl: './editable-value.component.html',
  styleUrls: ['./editable-value.component.scss'],
})
export class EditableValueComponent extends EditableContentComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() autoFocus: boolean;
  @Input() align: 'left' | 'right' = 'right';
  @Input() precision: number;
  @Input() metric: MetricMaster;
  @Input() prefix: string;
  @Input() highlighted: boolean;
  @Input() checkValidity$: Subject<void>;

  // We need this one to make 'currencyMask' applicable to reactive input type
  public modelValue = '';
  public currencyMaskOptions = {
    prefix: '',
    decimal: '.',
    precision: 2,
    align: 'right',
    allowNegative: false,
  };
  public utClasses = {
    'ARPO': 'ut-rpo',
    'Target': 'ut-target'
  };

  constructor(
    private metricFunnelsValidationService: MetricFunnelsValidationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.valueChangesSubscribe();
    if (this.checkValidity$) {
      this.checkValidity$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.validateControl();
        });
    }
  }

  ngAfterViewInit() {
    if (this.autoFocus && this.controlInput) {
      setTimeout(() => {
        this.handleFocus();
      }, 100)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.controlValue) {
      this.control.setValue(this.value);
      this.modelValue = this.value || '0';
    }

    if (changes.align && this.align) {
      this.currencyMaskOptions.align = this.align;
    }

    if (changes.precision && this.precision != null) {
      this.currencyMaskOptions.precision = this.precision;
    }

    if (changes.metric && this.metric) {
      if (!this.isNumber) {
        this.control.setValidators([
          Validators.required,
          this.metricFunnelsValidationService.uniqueMetricNameValidator(this.metric),
        ]);
      }
      this.control.updateValueAndValidity();
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
