<div [class]="'panel ' + panelCssClass">
  <hierarchy-head
    [config]="selectConfig"
    [allSelected]="false"
    (searchChanged)="updateActiveItems($event)"
  ></hierarchy-head>
  <div class="options-list-wrap">
    <hierarchy-options-list
      [items]="activeItems"
      [selectedId]="selectedOption?.id"
      [groupsOpenState]="groupsOpenState"
      [allowGroupSelection]="allowGroupSelection"
      (selectOption)="onSelectOption($event)"
      (toggleGroupState)="toggleGroup($event)">
    </hierarchy-options-list>
  </div>
</div>

<div *ngIf="showBackdrop" class="backdrop" (click)="onBackdropClick()"></div>
