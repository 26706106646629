import { inject, Injectable, OnDestroy } from '@angular/core';
import { ManageTableRowDataManager } from '../manage-table-row-data-manager/manage-table-row-data-manager.service';
import { forkJoin, Observable, of, Subject, switchMap } from 'rxjs';
import { ManageTableDataService } from '../manage-table-data.service';
import { ManagePageExpensesService } from '../manage-page-expenses.service';
import { ManageTableRow, ManageTableRowValues } from '../../components/manage-table/manage-table.types';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { PlanObjectExpenses } from '../../types/plan-object-expenses.interface';
import { TableData } from '../manage-page.service';
import { ManageTableViewMode } from '../../types/manage-table-view-mode.type';
import { ManageTableSpendingHelpers } from '../manage-table-spending-helpers';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { RowDataChunk } from './table-row-data-chunk';
import { CompanyDataService } from '@shared/services/company-data.service';
import { Configuration } from 'app/app.constants';
import { Budget } from '@shared/types/budget.interface';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { LightProgram, Program } from '@shared/types/program.interface';
import { Campaign, LightCampaign } from '@shared/types/campaign.interface';
import { SegmentExpensesData } from '@shared/types/plan-object-expenses-data.type';
import { BudgetSegmentDO } from '@shared/types/segment.interface';
import { MetricMappingDO } from '@shared/services/backend/metric.service';
import { ExpenseGroupDataApplier } from '../manage-table-row-data-manager/expense-group-data-applier';
import { CampaignDataApplier } from '../manage-table-row-data-manager/campaign-data-applier';
import { ObjectDataApplier } from '../manage-table-row-data-manager/object-data-applier.interface';
import { ManageTableHelpers } from '../manage-table-helpers';
import { BudgetPlanObjects } from '@shared/types/budget-plan-objects.type';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Injectable()
export class ManageTableDataLoader implements OnDestroy {
  private readonly rowDataManager = inject(ManageTableRowDataManager);
  private readonly tableDataService = inject(ManageTableDataService);
  private readonly managePageExpensesService = inject(ManagePageExpensesService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly configuration = inject(Configuration);
  private readonly metricMappingDetailsService = inject(MetricMappingDetailsService);

  private readonly destroy$ = new Subject<void>();

  public loadFullPlanObjects$(
    budget: Budget,
    filteredTimeframes: BudgetTimeframe[],
    data: TableData,
    planObjects: BudgetPlanObjects,
    allPrograms: LightProgram[],
    allCampaigns: LightCampaign[]
  ): Observable<[PlanObjectExpenses, SegmentExpensesData]> {
    return forkJoin([
      this.managePageExpensesService.getPlanObjectExpenses$(budget.id, planObjects, allPrograms, allCampaigns),
      this.loadSegmentExpenses(budget, data.viewModeChange.value, filteredTimeframes, data.budgetSegments)
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(
        ([planObjectExpenses, segmentExpenses]) =>
          this.loadRowDataByChunks$(budget, filteredTimeframes, planObjectExpenses, segmentExpenses, data).pipe(
            map(() => [planObjectExpenses, segmentExpenses] as [PlanObjectExpenses, SegmentExpensesData])
          )
      )
    );
  }

  public handleGoalRows(filteredTimeframes: BudgetTimeframe[]): void {
    const emptyGoalRows = this.getEmptyRows(this.tableDataService.data, ManageTableRowType.Goal);
    this.initEmptyRows(emptyGoalRows, filteredTimeframes);
  }

  private loadRowDataByChunks$(
    budget: Budget,
    filteredTimeframes: BudgetTimeframe[],
    planObjectExpenses: PlanObjectExpenses,
    segmentExpenses: SegmentExpensesData,
    contextData: TableData
  ): Observable<any> {
    const chunks = this.getRowDataChunks();

    if (!chunks.length) {
      return of(null);
    }

    return chunks.reduce(
      (chain$, chunk) =>
        chain$.pipe(
          takeUntil(this.destroy$),
          switchMap(() => this.loadChunkData$(budget.id, chunk)),
          tap(([campaigns, programs]) =>
            this.applyLoadedChunkData(filteredTimeframes, campaigns, programs, planObjectExpenses, segmentExpenses, budget, contextData)
          ),
          switchMap(([campaigns]) => this.loadPerformanceData$(campaigns, budget.company)),
          tap(([metricMappings, campaignsWithKeyMetrics]) =>
            this.applyPerformanceData(budget, metricMappings, campaignsWithKeyMetrics)
          )
        ),
      of(null)
    );
  }

  private applyLoadedChunkData(
    filteredTimeframes: BudgetTimeframe[],
    campaigns: Campaign[],
    programs: Program[],
    planObjectExpenses: PlanObjectExpenses,
    segmentExpenses: SegmentExpensesData,
    budget: Budget,
    contextData: TableData
  ): void {
    programs.forEach(
      program => this.applyLoadedChunkObject(
        filteredTimeframes,
        program,
        ManageTableRowType.ExpenseGroup,
        planObjectExpenses,
        segmentExpenses,
        contextData,
        budget,
        (rowValues: ManageTableRowValues) => new ExpenseGroupDataApplier(program, rowValues))
    );

    campaigns.forEach(
      campaign => this.applyLoadedChunkObject(
        filteredTimeframes,
        campaign,
        ManageTableRowType.Campaign,
        planObjectExpenses,
        segmentExpenses,
        contextData,
        budget,
        (rowValues: ManageTableRowValues) => new CampaignDataApplier(campaign, rowValues)
      )
    );

    this.tableDataService.setSourceObjects({ campaigns, expenseGroups: programs });
  }

  private loadSegmentExpenses(
    budget: Budget,
    viewMode: ManageTableViewMode,
    filteredTimeframes: BudgetTimeframe[],
    budgetSegments: BudgetSegmentDO[]
  ): Observable<SegmentExpensesData> {
    return viewMode === ManageTableViewMode.Segments ?
      this.managePageExpensesService.loadSegmentExpensesData(budget.id).pipe(
        tap(segmentExpenses => this.applySegments(segmentExpenses, filteredTimeframes, budget, budgetSegments))
      ) :
      of(null);
  }

  private applyLoadedChunkObject(
    filteredTimeframes: BudgetTimeframe[],
    targetObject: Campaign | Program,
    rowType: ManageTableRowType.Campaign | ManageTableRowType.ExpenseGroup,
    planObjectExpenses: PlanObjectExpenses,
    segmentExpenses: SegmentExpensesData,
    contextData: TableData,
    budget: Budget,
    dataApplierFactory: (rowValues: ManageTableRowValues) => ObjectDataApplier<Campaign | Program>
  ): void {
    const rowId = ManageTableHelpers.getRowObjectId(rowType, targetObject.objectId);
    const row = this.tableDataService.flatDataMap[rowId];
    const expenses = ManageTableHelpers.getExpensesData(planObjectExpenses, rowType);

    const sharedCostRulePercent =
      targetObject.isPseudoObject ?
        this.tableDataService.getRulesSegmentPercentage(
          contextData.sharedCostRules?.find(scr => scr.id === targetObject.splitRuleId),
          targetObject.budgetSegmentId
        ) :
        null;

    const rowValues =
      ManageTableHelpers.mapObjectValues(
        targetObject,
        filteredTimeframes,
        this.budgetDataService.selectedBudgetSnapshot,
        expenses,
        contextData.viewModeChange.value,
        sharedCostRulePercent,
        filteredTimeframes
      );

    const dataApplier = dataApplierFactory(rowValues);
    this.rowDataManager.applyObjectDataToRow(row, dataApplier, segmentExpenses, budget, filteredTimeframes, contextData);
  }

  private loadChunkData$(budgetId: number, chunk: RowDataChunk): Observable<[Campaign[], Program[]]> {
    const campaignRows = chunk?.campaignRows || [];
    const programRows = chunk?.programRows || [];
    const companyId = this.companyDataService.selectedCompanySnapshot.id;

    const pseudoCampaignRows = campaignRows.filter(row => row.sharedCostRuleId && row.segmentId);
    const regularCampaignRows = campaignRows.filter(row => !row.sharedCostRuleId || !row.segmentId);

    const pseudoProgramRows = programRows.filter(row => row.sharedCostRuleId && row.segmentId);
    const regularProgramRows = programRows.filter(row => !row.sharedCostRuleId || !row.segmentId);

    const loadCampaigns$ =
        params =>
          this.budgetDataService.getCampaigns(
            companyId,
            budgetId,
            this.configuration.campaignStatusNames.active,
            params
          );

    const loadPrograms$ =
      params =>
        this.budgetDataService.getPrograms(
          companyId,
          budgetId,
          this.configuration.programStatusNames.active,
          params
        );

    const loadPseudoCampaigns$ = this.loadPseudoObjects$(pseudoCampaignRows, loadCampaigns$);
    const loadPseudoPrograms$ = this.loadPseudoObjects$(pseudoProgramRows, loadPrograms$);

    const loadRegularCampaigns$ =
      regularCampaignRows.length ?
        loadCampaigns$({ ids: regularCampaignRows.map(row => row.objectId).join(',') }) :
        of([]);

    const loadRegularPrograms$ =
      regularProgramRows.length ?
        loadPrograms$({ ids: regularProgramRows.map(row => row.objectId).join(',') }) :
        of([]);

    return forkJoin([
      forkJoin([loadPseudoCampaigns$, loadRegularCampaigns$]).pipe(
        map(([pseudoCampaigns, regularCampaigns]) => [...pseudoCampaigns, ...regularCampaigns])
      ),
      forkJoin([loadPseudoPrograms$, loadRegularPrograms$]).pipe(
        map(([pseudoPrograms, regularPrograms]) => [...pseudoPrograms, ...regularPrograms])
      )
    ]);
  }

  private loadPseudoObjects$ <T extends Campaign | Program> (
      rows: ManageTableRow[],
      objectLoader: (params: object) => Observable<T[]>
    ): Observable<T[]> {
    return rows.length ?
      forkJoin(
        Object.entries(
          this.groupObjectIdsBySegment(rows)
        ).map(
          ([segmentId, campaignIds]) =>
            objectLoader({ company_budget_segment1_ids: segmentId, ids: campaignIds.join(','), include_pseudo_objects: true })
        )
      ).pipe(
        map(responses => responses.flat())
      ) :
      of([]) as Observable<T[]>;
  }

  private groupObjectIdsBySegment(rows: ManageTableRow[]): Record<number, number[]> {
    return rows.reduce(
      (res, row) => {
        if (!res[row.segmentId]) {
          res[row.segmentId] = [];
        }
        const objectId = ManageTableHelpers.getObjectId(row.itemId);
        res[row.segmentId].push(objectId);
        return res;
      },
      {}
    );
  }

  private getRowDataChunks(chunkSize = 50): RowDataChunk[] {
    const chunks: RowDataChunk[] = [];
    let currentChunk = new RowDataChunk();

    const addItemToChunk = (item: ManageTableRow) => {
      if (item.type === ManageTableRowType.Campaign) {
        currentChunk.addCampaignRow(item);
      } else if (item.type === ManageTableRowType.ExpenseGroup) {
        currentChunk.addProgramRow(item);
      }
      if (currentChunk.objectsCount === chunkSize) {
        chunks.push(currentChunk);
        currentChunk = new RowDataChunk();
      }
    }

    const scanRows = (rows: ManageTableRow[]) => {
      rows.forEach(row => {
        addItemToChunk(row);
        if (row.children?.length) {
          scanRows(row.children);
        }
      });
    }

    scanRows(this.tableDataService.data);
    if (currentChunk.objectsCount > 0) {
      chunks.push(currentChunk);
    }
    return chunks;
  }

  private applySegments(
    segmentExpenses: SegmentExpensesData,
    filteredTimeframes: BudgetTimeframe[],
    budget: Budget,
    budgetSegments: BudgetSegmentDO[]
  ): void {
    const initEmptySegmentRows = (rows: ManageTableRow[]) => {
      const emptySegmentRows = this.getEmptyRows(rows, ManageTableRowType.Segment);
      this.initEmptyRows(emptySegmentRows, filteredTimeframes);

      emptySegmentRows.forEach(row => {
        row.spending = ManageTableSpendingHelpers.initSpendingValues();
        ManageTableHelpers.calcBreakdownValues(
          row,
          (budgetSegments || []).find(bs => bs.id === row.objectId),
          segmentExpenses[row.objectId],
          budget.suppress_timeframe_allocations,
          filteredTimeframes
        );
      });
    };

    initEmptySegmentRows(this.tableDataService.data);

    (this.tableDataService.data || [])
      .filter(row => row.type === ManageTableRowType.SegmentGroup)
      .forEach(row => {
        initEmptySegmentRows(row.children);

        row.isChildDataReady = (row.children || []).every(childRow => childRow.isChildDataReady && childRow.isOwnDataReady);
        if (row.isOwnDataReady && row.isChildDataReady) {
          const { segment, unallocated } = ManageTableHelpers.sumUpChildBreakdownValues(row);
          row.segment = segment;
          row.unallocated = unallocated;
          row.spending = ManageTableSpendingHelpers.initSpendingValues();
          const { values, total } = ManageTableHelpers.sumUpChildValues(row);
          row.values = values;
          row.total = total;
          ManageTableSpendingHelpers.syncSegmentSpending(row);
        }
      });
  }

  private getEmptyRows(rows: ManageTableRow[], rowType: ManageTableRowType): ManageTableRow[] {
    return (rows || []).filter(row => row.type === rowType && !row.children?.length);
  }

  private initEmptyRows(rows: ManageTableRow[], filteredTimeframes: BudgetTimeframe[]): void {
    rows.forEach(row => {
      row.isChildDataReady = true;
      row.values = ManageTableHelpers.initTimeframeValues(filteredTimeframes);
      row.total = { allocated: 0, spent: 0 };
    });
  }

  private loadPerformanceData$(campaigns: Campaign[], companyId: number): Observable<[MetricMappingDO[], Campaign[]]> {
    const campaignsWithKeyMetrics = (campaigns || []).filter(campaign => campaign.keyMetric);
    const keyMetricIds = campaignsWithKeyMetrics.map(campaign => campaign.keyMetric);

    return keyMetricIds.length ?
      this.metricMappingDetailsService.getMetricMappings(
        companyId,
        {
          ids: keyMetricIds.join(','),
          mapping_type: this.configuration.OBJECT_TYPES.campaign
        }
      ).pipe(
        map(metricMappings => [metricMappings, campaignsWithKeyMetrics])
      ) :
      of([[], []]);
  }

  private applyPerformanceData(
    budget: Budget,
    metricMappings: MetricMappingDO[],
    campaignsWithKeyMetrics: Campaign[]
  ): void {
    if (metricMappings?.length && campaignsWithKeyMetrics?.length) {
      this.tableDataService.setPerformanceColumnData(metricMappings, campaignsWithKeyMetrics, budget, false);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
