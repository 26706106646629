import { Component, Input } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-linkedin',
  templateUrl: './icon-linkedin.component.html',
})
export class IconLinkedinComponent extends IconBaseComponent {
  @Input() isMonoChrome: boolean = true;
  constructor() {
    super();
  }
}
