export enum IntegrationMappingState {
  NotMapped = 'notMapped',
  Mapped = 'mapped',
}

export enum IntegrationChildrenMappingState {
  NotMapped = 'notMapped',
  MappedAll = 'mappedAll',
  MappedSome = 'mappedSome',
}

export enum MetricIntegrationName {
  Salesforce = 'salesforce',
  Hubspot = 'hubspot',
  GoogleAds = 'google_ads',
  LinkedinAds = 'linkedin_ads',
  FacebookAds = 'facebook_ads',
  Plannuh = 'plannuh',
}

export const MetricIntegrationDisplayName = {
  [MetricIntegrationName.Salesforce]: 'Salesforce',
  [MetricIntegrationName.Hubspot]: 'HubSpot',
  [MetricIntegrationName.GoogleAds]: 'Google Ads',
  [MetricIntegrationName.LinkedinAds]: 'LinkedIn Ads',
  [MetricIntegrationName.FacebookAds]: 'Facebook Ads',
  [MetricIntegrationName.Plannuh]: 'Planful Marketing',
};

export const AdsIntegrations = [
  MetricIntegrationName.GoogleAds,
  MetricIntegrationName.LinkedinAds,
  MetricIntegrationName.FacebookAds,
];

export const CampaignMappedIntegrations = [
  MetricIntegrationName.Salesforce,
];

export interface CampaignBrief {
  id: number;
  name: string;
}

export interface MetricListItem {
  name: string;
  productName: string;
  value: number;
  order: number;
  productOrder: number;
}

export interface PlannuhCampaignMetric {
  metricId: number;
  name: string
  productName: string
  order: number;
  productOrder: number;
}

export interface CampaignMappingData {
  externalId: string;
  internalId: number;
  unmappedChildrenIds: string[];
}
