<div class="metric-milestones">
  <div class="metric-milestones-title">
    Manage Target and Milestones
  </div>
  <ul class="metric-milestones-list" *ngIf="formData?.length">
    <li *ngFor="let milestoneForm of formData; let i = index">
      <div>
        <metric-milestone
          [index]="i"
          [readOnly]="readOnly"
          [milestoneForm]="milestoneForm"
          [milestonesCount]="formData?.length"
          [displayDecimal]="displayDecimal"
          (patchMilestone)="pushMilestonesUpdates($event)"
          (removeMilestone)="onRemoveMilestone($event)"
        ></metric-milestone>
      </div>
    </li>
  </ul>
  <div *ngIf="!readOnly">
    <add-object-link text="Add Milestone" [handler]="addMilestoneField.bind(this)"></add-object-link>
  </div>
</div>
