import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { CalendarEventComponent } from './components/calendar-event/calendar-event.component';
import { CalendarHeaderComponent } from './components/calendar-header/calendar-header.component';
import { CalendarPlaceholderComponent } from './components/calendar-placeholder/calendar-placeholder.component';
import { EventTooltipComponent } from './components/event-tooltip/event-tooltip.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    CalendarEventComponent,
    CalendarHeaderComponent,
    CalendarPlaceholderComponent,
    EventTooltipComponent
  ],
  providers: [
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    MatSelectModule,
    MatFormFieldModule
  ],
  exports: [
    CalendarEventComponent,
    CalendarHeaderComponent,
    CalendarPlaceholderComponent,
    EventTooltipComponent,
    MatSelectModule,
    MatFormFieldModule
  ]
})
export class MarketingCalendarModule {}
