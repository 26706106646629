import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Configuration } from 'app/app.constants';
import { UserManager } from '../../user/services/user-manager.service';
import { filter, map, take } from 'rxjs/operators';
import { UserDO } from '@shared/types/user-do.interface';

@Injectable()
export class SuperAdminGuard implements CanActivate {

  constructor(
    private userManager: UserManager,
    private router: Router,
    private configuration: Configuration
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { DEFAULT_ROUTE, ROUTER_OUTLETS } = this.configuration;

    return this.userManager.currentUser$.pipe(
      filter(user => user != null),
      take(1),
      map((user: UserDO) => {
        const guardData = route.data?.superAdminGuard || {};
        const { superAdminAccess = true, redirectTo = DEFAULT_ROUTE, closeDetails = false } = guardData;

        if (superAdminAccess && user.is_superadmin || !superAdminAccess && !user.is_superadmin) {
          return true;
        }

        const stateChildren = state.root.children;
        const detailsRouteSnapshot: ActivatedRouteSnapshot = stateChildren.find(child => child.outlet === ROUTER_OUTLETS.DETAILS);

        // Details outlet is not activated
        if (stateChildren.length < 2 || !detailsRouteSnapshot || closeDetails) {
          return this.router.createUrlTree([ redirectTo ]);
        }
        const { outlet, params, routeConfig } = detailsRouteSnapshot;
        const outletPath = routeConfig.path.replace(':id', params.id);

        this.router.navigate([{
          outlets: {
            primary: redirectTo,
            [outlet]: outletPath
          }
        }], { queryParamsHandling: 'preserve' });

        return false;
      })
    );
  }
}
