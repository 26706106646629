<h3 class="page-title" *ngIf="currentMetricId">
  <div class="icon-box">
    <span class="subtitle">
      Metrics
    </span>
    <span class="icon-content">
      <fa-duotone-icon [icon]="['fad', 'chart-line']"></fa-duotone-icon>
    </span>
  </div>
  <p class="metric-type object-name-input"
     [ngClass]="{ 'is-disabled': isReadonly }"
     [matMenuTriggerFor]="metricsDropdown">
    <span *ngIf="productName">{{ productName }}</span>
    <span>{{ metricName }}</span>
  </p>
</h3>

<mat-menu #metricsDropdown="matMenu" [xPosition]="'before'" [class]="'metric-masters-list'">
  <metric-masters-list
    [options]="masterMetricsSelectItems"
    [selectedOptionId]="currentMetricId"
    [singleSelectMode]="true"
    (selectMetric)="onSelectMetric($event)"
  ></metric-masters-list>
</mat-menu>

