import { Pipe, PipeTransform } from '@angular/core';
import { TimeZone } from '@vvo/tzdb';
import { padStart } from 'app/shared/utils/common.utils';
import { getParsedTime } from 'app/shared/utils/time.utils';

interface PipeConfig {
  gmtOnly: boolean;
}

const DEFAULT_CONFIG: PipeConfig = {
  gmtOnly: false
};

@Pipe({
  name: 'tmzStringify'
})
export class TimezoneStringifyPipe implements PipeTransform {
  transform(value: TimeZone, config: PipeConfig = DEFAULT_CONFIG): string | number {
    if (!value) {
      return '';
    }

    const parsedTime = getParsedTime(value.rawOffsetInMinutes);
    const gmtSign = parsedTime.hours >= 0 ? '+' : '-';
    const gmtHours = padStart(Math.abs(parsedTime.hours).toString(10), 2);
    const gmtMinutes = padStart(parsedTime.minutes.toString(10), 2);

    if (config.gmtOnly) {
      return `GMT ${gmtSign}${gmtHours}:${gmtMinutes}`;
    }

    return `(GMT${gmtSign}${gmtHours}:${gmtMinutes}) ${value.alternativeName}`;
  }
}
