import { Component, ElementRef, inject, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { Configuration } from 'app/app.constants';
import { ManageTableViewMode } from '../../types/manage-table-view-mode.type';
import { ManagePageModeService } from '../../services/manage-page-mode.service';
import { ManageTableDataService } from '../../services/manage-table-data.service';
import { Currency } from 'app/shared/types/currency.interface';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { BudgetAllocationTopupAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-topup-action';
import { ManageTableUpdateAction } from '@shared/types/manage-table-update-action';
import { BudgetAllocationMoveAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-move-action';
import {
  CreateItemTemplateEvent,
  ManageTableActionDataSource,
  ManageTableActionEvent,
  ManageTableAllocationsUpdatePayload,
  ManageTableRow,
} from '../manage-table/manage-table.types';
import { ManageTableDataMutationService } from '../../services/manage-table-data-mutation.service';
import { ManagePageService } from '../../services/manage-page.service';
import { ManageTableDataValidationService } from '../../services/manage-table-data-validation.service';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { BudgetObjectChange } from 'app/budget-object-details/types/budget-object-change.interface';
import { PlanPageViewMode } from 'app/shared/enums/plan-page-view-mode.enum';
import { SummaryBarCalculationItem, SummaryBarItem } from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { SpendingModeFlag, SpendingModeFlags } from '../../types/spending-mode-flags.type';
import { ManageTableRecordInteractionsService } from '../../services/manage-table-record-interactions.service';
import { AllocationModeFlags } from '../../types/allocation-mode-flags.type';
import { ManageTableActionHistoryService } from '@shared/services/manage-table-action-history.service';
import { BudgetAllocationAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';
import { ManageTableDataMode } from '../../types/manage-table-data-mode.type';
import { MiniDashCalculationService } from '../../services/mini-dash-calculation.service';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Component({
  selector: 'manage-table-container',
  templateUrl: './manage-table-container.component.html',
  styleUrls: ['./manage-table-container.component.scss']
})
export class ManageTableContainerComponent implements OnInit, OnChanges, OnDestroy {
  private readonly appRouting = inject(AppRoutingService);
  private readonly modeService = inject(ManagePageModeService);
  private readonly managePageService = inject(ManagePageService);
  private readonly miniDashCalculationService = inject(MiniDashCalculationService);
  private readonly tableDataService = inject(ManageTableDataService);
  private readonly dataMutationService = inject(ManageTableDataMutationService);
  private readonly dataValidationService = inject(ManageTableDataValidationService);
  private readonly actionsManager: BudgetAllocationActionsService<ManageTableActionDataSource> = inject(BudgetAllocationActionsService);
  private readonly renderer = inject(Renderer2);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly configuration = inject(Configuration);
  private readonly managePageInteractionService = inject(ManageTableRecordInteractionsService);
  private readonly historyManager: ManageTableActionHistoryService<BudgetAllocationAction<any>> = inject(ManageTableActionHistoryService);

  @Input() timeframes: BudgetTimeframe[] = [];
  @Input() budget: Budget;
  @Input() currency: Currency;
  @ViewChild('tableContainer') tableContainerRef: ElementRef;

  spendingModeFlag = SpendingModeFlag;
  manageTableViewMode = ManageTableViewMode;
  manageTableDataMode = ManageTableDataMode;

  private readonly destroy$ = new Subject<void>();

  public readonly createMenuOptions = [
    {
      label: this.configuration.OBJECT_TYPES.campaign,
      action: () => this.appRouting.openCampaignCreation(),
      icon: ['fad', 'rocket-launch']
    }, {
      label: 'Expense Group',
      action: () => this.appRouting.openProgramCreation(),
      icon: ['fad', 'briefcase']
    }, {
      label: this.configuration.OBJECT_TYPES.goal,
      action: () => this.appRouting.openGoalCreation(),
      icon: ['fad', 'bullseye-arrow']
    }
  ];

  public get viewMode() {
    return this.modeService.viewMode;
  }

  public get dataMode() {
    return this.modeService.dataMode;
  }

  public get showExpenses() {
    return this.modeService.showExpenses;
  }

  public get spendingModeFlags() {
    return this.modeService.spendingModeFlags;
  }

  public get tableData() {
    return this.tableDataService.data;
  }

  public get performanceColumnData() {
    return this.tableDataService.performanceColumnData;
  }

  public get grandTotal() {
    return this.tableDataService.grandTotal;
  }

  public get isDataLoading() {
    return this.tableDataService.isLoading;
  }

  public get isFilteredMode() {
    return this.tableDataService.isFilteredMode;
  }

  public get hasHiddenHierarchy() {
    return this.tableDataService.hasHiddenHierarchy;
  }

  public get appliedSorting() {
    return this.tableDataService.appliedSorting;
  }

  public get segments(): BudgetSegmentAccess[] {
    return this.managePageService.segments;
  }

  public get sharedCostRules(): SharedCostRule[] {
    return this.managePageService.sharedCostRules;
  }

  public get editPermission(): boolean {
    return this.managePageService.editPermission;
  }

  public get isAdmin(): boolean {
    return this.managePageService.isAdmin;
  }

  public get allocationModeFlags() {
    return this.modeService.allocationModeFlags;
  }

  public get historySize() {
    return this.historyManager.historySize;
  }

  public get segmentBreakdownRestrictedByFilters() {
    return this.managePageService.segmentBreakdownRestrictedByFilters;
  }

  public get campaignTypes() {
    return this.managePageService.campaignTypes;
  }

  public get expGroupTypes() {
    return this.managePageService.expGroupTypes;
  }

  ngOnInit(): void {
    const { OBJECT_TYPES } = this.configuration;

    this.budgetObjectDetailsManager.budgetObjectChanged$
      .pipe(
        takeUntil(this.destroy$),
        filter((data: BudgetObjectChange) => data.objectType === OBJECT_TYPES.goal)
      )
      .subscribe(
        () => {
          this.managePageService.externalGoalChangeActive = true;
          this.handleViewModeChange(ManageTableViewMode.Goals);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.budget?.currentValue) {
      const { suppress_timeframe_allocations } = changes.budget.currentValue;
      if (suppress_timeframe_allocations) {
        this.handleShowExpensesChange(suppress_timeframe_allocations);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handleViewModeChange(mode: ManageTableViewMode) {
    this.modeService.setViewMode(mode);
  }

  public handleShowExpensesChange(showExpenses: boolean) {
    this.modeService.setShowExpenses(showExpenses);
  }

  public handleSpendingModeFlagsChange(flags: SpendingModeFlags) {
    this.modeService.setSpendingModeFlags(flags);
  }

  public handleAllocationModeFlagsChange(flags: AllocationModeFlags) {
    this.modeService.setAllocationModeFlags(flags);
  }

  public switchClassicView(): void {
    const { goal, campaign } = this.configuration.OBJECT_TYPES;
    const objectType = this.modeService.viewMode === ManageTableViewMode.Goals ? goal : campaign;
    this.appRouting.openPlanDetail([objectType], {}, PlanPageViewMode.CARD);
  }

  public applySorting(columnName: string) {
    this.tableDataService.applySorting(columnName);
  }

  public handleSegmentAllocationChange($event: ManageTableActionEvent) {
    this.managePageService.updateSegmentAllocation($event);
  }

  public handleAllocationChange($event: ManageTableActionEvent) {
    if (!this.editPermission) {
      return;
    }
    const { amount, dataSource } = $event;
    const undoCallbacks = [];
    const action = new ManageTableUpdateAction<ManageTableActionDataSource>({
      context: {
        dataSource,
        amount
      },
      setAmount: (value, ds) => {
        const payload = ManageTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, {});
        return this.dataMutationService.updateAllocations(
          payload,
          this.managePageService.budget.suppress_timeframe_allocations,
          undoCallbacks
        );
      },
      getAmount: this.tableDataService.getDataSourceValue,
      currency: this.currency.symbol,
      undoCallbacks
    });

    this.actionsManager.executeAction(action, () => this.historyManager.pushAction(action));
  }

  public handleDoubleClickAction($event: ManageTableActionEvent): void {
    if (!this.editPermission) {
      return;
    }
    const { gestureEvent, dataSource } = $event;
    if (dataSource?.record?.type === ManageTableRowType.Segment) {
      this.managePageService.handleSegmentDoubleClick($event);
      return;
    }

    const actionMessageTrigger = new Subject<void>();
    const undoCallbacks = [];
    const action = new BudgetAllocationTopupAction<ManageTableActionDataSource>({
      actionTarget: {
        dataSource,
        difference: gestureEvent.difference,
        surplus: gestureEvent.surplus,
        overspend: gestureEvent.overspend
      },
      setAmount: (value, ds) => {
        const payload = ManageTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, {});
        return this.dataMutationService.updateAllocations(
          payload,
          this.managePageService.budget.suppress_timeframe_allocations,
          undoCallbacks
        )
          .pipe(
            filter(result => result != null),
            takeUntil(this.destroy$),
            tap(result => {
              if (result) {
                actionMessageTrigger.next();
              }
            })
          );
      },
      getAmount: this.tableDataService.getDataSourceValue,
      currency: this.currency.symbol,
      undoCallbacks
    });

    this.actionsManager.executeAction(
      action,
      () => this.historyManager.pushAction(action),
      viaSnackbar => {
        if (viaSnackbar) {
          this.historyManager.popAction();
        }
      },
      actionMessageTrigger
    );
  }

  public handleDragStartAction($event: ManageTableActionEvent) {
    if (!this.editPermission) {
      return;
    }
    this.actionsManager.trackDragStart($event.dataSource, $event.gestureEvent);
    this.dataValidationService.setDraggedRecord($event.dataSource.record);
  }

  public handleDropAction($event: ManageTableActionEvent) {
    if (!this.editPermission) {
      return;
    }
    if ($event.dataSource?.record?.type === ManageTableRowType.Segment) {
      this.managePageService.handleSegmentDrop($event);
      return;
    }

    let payload: ManageTableAllocationsUpdatePayload = {};
    const actionMessageTrigger = new Subject<void>();
    const undoCallbacks = [];
    const onExecutedTrigger = new BehaviorSubject<boolean>(null);
    this.actionsManager.trackDrop($event.dataSource, $event.gestureEvent);

    const action = new BudgetAllocationMoveAction<ManageTableActionDataSource>({
      actionTarget: this.actionsManager.getDndTargets(),
      setAmount: (value, ds, isLast = false) => {
        payload = ManageTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, payload);
        if (isLast) {
          this.dataMutationService.updateAllocations(
            payload,
            this.managePageService.budget.suppress_timeframe_allocations,
            undoCallbacks
          )
            .pipe(
              filter(result => result != null),
              takeUntil(this.destroy$)
            )
            .subscribe(
              res => {
                if (res) {
                  actionMessageTrigger.next();
                }
                onExecutedTrigger.next(res);
              }
            );
          payload = {};
        }
      },
      getAmount: this.tableDataService.getDataSourceValue,
      currency: this.currency?.symbol,
      undoCallbacks,
      onExecutedTrigger
    });
    this.actionsManager.executeAction(
      action,
      () => this.historyManager.pushAction(action),
      (viaSnackbar: boolean) => {
        if (viaSnackbar) {
          this.historyManager.popAction();
        }
      },
      actionMessageTrigger
    );
  }

  public handleDragEndAction() {
    this.actionsManager.trackDragEnd();
  }

  public handleCloneAction(record: ManageTableRow) {
    this.managePageService.duplicateItem(record);
  }

  public handleCreateNewItemTemplate(createItemTemplateEvent: CreateItemTemplateEvent): void {
    this.managePageService.createNewItemTemplate(createItemTemplateEvent);
  }

  public handleCreateNewItemFromTemplate(name: string): void {
    this.managePageService.saveNewItemTemplateWithName(name);
  }

  public handleCloseAction(record: ManageTableRow) {
    this.managePageService.closeItem(record);
  }

  public handleReopenAction(record: ManageTableRow) {
    this.managePageService.reopenItem(record);
  }

  public handleDeleteAction(record: ManageTableRow) {
    this.managePageService.deleteItem(record);
  }

  public handleTableWidthChange(width: number) {
    const containerMaxWidth = width ? `${width}px` : 'auto';
    this.renderer.setStyle(this.tableContainerRef.nativeElement, 'max-width', containerMaxWidth);
  }

  public handleMoveToAction(record: ManageTableRow) {
    this.managePageInteractionService.implicitSelect(record);
    this.managePageService.openHierarchySelection(record);
  }

  public get summaryBarItems(): SummaryBarItem[][] {
    return this.managePageService.summaryBarItems;
  }

  public get calculationItems(): SummaryBarCalculationItem[] {
    return this.miniDashCalculationService.calculationItems;
  }

  public get summaryBarLoading(): BehaviorSubject<boolean> {
    return this.managePageService.summaryBarLoading$;
  }

  public handleUndoClick() {
    this.historyManager.undo();
  }
}
