import { Component, Input } from '@angular/core';
import { Configuration } from 'app/app.constants';

export interface BudgetAmountsSummaryValues {
  total: number;
  available: number;
  overBudget: number;
  underBudget: number;
  reserved: number;
  expenses: number;
  planned: number;
  committed: number;
  closed: number;
  remainingAllocated?: number;
  overSpend?: number;
  actual?:number;
}

export interface BudgetAmountsSummaryData {
  title: string;
  currency: string;
  isBudgetView: boolean;
  cegFlagEnabled: boolean;
  values: BudgetAmountsSummaryValues
}

const defaultData: BudgetAmountsSummaryData = {
  title: '',
  currency: '',
  isBudgetView: false,
  cegFlagEnabled: false,
  values: {
    total: 0,
    available: 0,
    overBudget: 0,
    underBudget: 0,
    reserved: 0,
    expenses: 0,
    planned: 0,
    actual: 0,
    committed: 0,
    closed: 0,
    remainingAllocated: 0,
    overSpend: 0
  }
};

@Component({
  selector: 'budget-amounts-summary',
  templateUrl: './budget-amounts-summary.component.html',
  styleUrls: ['./budget-amounts-summary.component.scss'],
})
export class BudgetAmountsSummaryComponent {
  @Input() data: BudgetAmountsSummaryData = defaultData;
  @Input() containerWidth = 320;

  public decimalPipeFormat = '1.2-2';

  constructor(public configuration: Configuration) {
  }
}
