import { Component, Input, EventEmitter, Output } from '@angular/core';
import { DrawerType } from '../../services/drawer-stack.service';

@Component({
  selector: 'create-object-toolbar',
  templateUrl: './create-object-toolbar.component.html',
  styleUrls: ['./create-object-toolbar.component.scss']
})
export class CreateObjectToolbarComponent {
  @Input() objectId: number;
  @Input() parentObjectLabel: string;
  @Input() objectTypes: DrawerType[];
  @Output() createObject = new EventEmitter<DrawerType>();

  protected creationAllowed: boolean;
  protected buttonConfig = {
    [DrawerType.Campaign]: {
      icon: ['far', 'rocket-launch'],
      tooltip: 'Add Child Campaign'
    },
    [DrawerType.Program]: {
      icon: ['fas', 'briefcase'],
      tooltip: 'Add Expense Group'
    },
    [DrawerType.Expense]: {
      icon: ['fas', 'coins'],
      tooltip: 'Add Expense'
    }
  };
}
