<manage-expense-table
  [columns]="tableColumns"
  [rowsData]="tableRows"
  [timeframes]="timeframesList"
  [subtotalData]="subtotalData"
  [readOnlyMode]="readOnlyMode"
  [openedInDrawerId]="openedInDrawerId$ | async"
  (openDetails)="openExpenseDetails($event)"
  (patchData)="patchExpense($event)"
  (setRelatedExpensesMode)="activateRelatedExpensesMode($event)"
></manage-expense-table>

<no-expenses-placeholder
  *ngIf="sidebarHasSelection && tableRows && !tableRows.length"
  [@fadeAndScale]
  [text]="noExpenseText"
  [icon]="noExpenseIcon"
></no-expenses-placeholder>
