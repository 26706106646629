import { Directive, ElementRef, HostListener } from '@angular/core';

const BOTTOM_OFFSET = 50;
const TOP_OFFSET = 100;

@Directive({
  selector: '[scrollOnDrag]'
})
export class ScrollOnDragDirective {

  constructor(
    private elementRef: ElementRef
  ) {}

  @HostListener('dragenter', ['$event'])
  onDragStart($event) {
    const { top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();
    /**
      For the horizontal scroll you can add anchor classes, dragover on which, browser run scroll.
      Therefore you can add a frame between which elements scroll should work.
      It added to avoid intersection between vertical and horizontal scroll handle.
    **/
    const leftAnchor = $event.target.classList.contains('left-scroll-anchor')
      || $event.target.parentElement.classList.contains('left-scroll-anchor');
    const rightAnchor = $event.target.classList.contains('right-scroll-anchor')
      || $event.target.parentElement.classList.contains('right-scroll-anchor');

    if ($event.clientY > bottom - BOTTOM_OFFSET) {
      this.elementRef.nativeElement.scroll({top: this.elementRef.nativeElement.scrollTop + 100, behavior: 'smooth'});
    }

    if ($event.clientY < top + TOP_OFFSET) {
      this.elementRef.nativeElement.scroll({top: this.elementRef.nativeElement.scrollTop - 100, behavior: 'smooth'});
    }

    if (rightAnchor) {
      this.elementRef.nativeElement.scroll({left: this.elementRef.nativeElement.scrollLeft + 600, behavior: 'smooth'});
    }

    if (leftAnchor) {
      this.elementRef.nativeElement.scroll({left: this.elementRef.nativeElement.scrollLeft - 600, behavior: 'smooth'});
    }
  }

}
