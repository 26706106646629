import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Metric } from './details-metrics.type';
import { BudgetObjectTotals } from 'app/shared/types/budget-object-totals.interface';
import { MetricDataDO, MetricMappingDO, MetricService } from 'app/shared/services/backend/metric.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { Configuration } from 'app/app.constants';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { parseDateString } from '../containers/campaign-details/date-operations';
import { CPOTooltipTemplate, getMetricExplanationTooltip, keyMetricTooltip, ROITooltipTemplate, RROITooltipTemplate } from '../metrics/constants';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { MetricsUtilsService } from '../../services/metrics-utils.service';
import { PerformanceColumnData } from 'app/manage-table/types/performance-column-data.type';
import { roundDecimal, sortMetricsList } from 'app/shared/utils/common.utils';
import { messages } from '../../messages';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { SelectedValue, SelectItem } from 'app/shared/types/select-groups.interface';
import { MatMenuTrigger } from '@angular/material/menu';
import { getMetricSelectItems } from './metric-masters-list/metric-masters-list.component';

import { BudgetObjectMetricsService } from '../../services/budget-object-metrics.service';
import { MetricMappingDetailsService } from '../../services/metric-mapping-details.service';
import { MetricProgressState } from '@shared/types/metric-progress-state.type';
import { getTodaysDate } from '@shared/utils/date.utils';

interface CalculatedMetricData {
  targetCPO?: number;
  currentCPO?: number;
  targetROI?: number;
  currentROI?: number;
  targetValue?: number;
  currentValue?: number;
}

@Component({
  selector: 'details-metrics',
  templateUrl: './details-metrics.component.html',
  styleUrls: ['./details-metrics.component.scss']
})
export class DetailsMetricsComponent implements OnChanges {
  private readonly router = inject(Router);
  public readonly configuration = inject(Configuration);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly metricsService = inject(MetricService);
  private readonly metricsUtilsService = inject(MetricsUtilsService);
  private readonly metricMappingDetailsService = inject(MetricMappingDetailsService);
  public readonly budgetObjectMetricsService = inject(BudgetObjectMetricsService);

  @Input() defaultStartDate: string;
  @Input() defaultEndDate: string;
  @Input() savedMetricMappings: Metric[] = [];
  @Input() metricTypes: MetricType[] = [];
  @Input() products: ProductDO[] = [];
  @Input() objectTotals: BudgetObjectTotals;
  @Input() contextCurrency = '';
  @Input() isReadOnlyMode = false;
  @Input() objectType: string;
  @Input() objectId: number;
  @Input() keyMetricSelection = false;
  @Input() keyMetricId: number | string;
  /* Mode in which current is readonly and based on child objects sum (for Goals only) */
  @Input() isTotalCurrentMode = false;
  @Input() totalMetricData: MetricDataDO[] = [];
  @Input() isPowerUser: boolean;
  @Input() todayDate: Date;

  @Output() onAddMapping = new EventEmitter<MetricMappingDO[]>();
  @Output() setKeyMetric = new EventEmitter<Metric>();
  @ViewChild('addMetricsDropdownTrigger') addMetricsDropdownTrigger: MatMenuTrigger;

  contextTotal = 0;
  contextTotalSpent = 0;
  metrics: Metric[] = [];
  masterMetricsSelectItems: SelectItem[] = [];

  public numberDisplayFormat = '1.0-2';
  public decimalDisplayFormat = '1.2-2';
  public calculatedData = new Map<number, CalculatedMetricData>();
  public utClasses = {
    targetCPO: 'ut-target-cpo',
    currentCPO: 'ut-current-cpo',
    targetROI: 'ut-target-roi',
    currentROI: 'ut-current-roi',
  };

  protected CPOTooltip = getMetricExplanationTooltip(CPOTooltipTemplate, false);
  protected RROITooltip = getMetricExplanationTooltip(RROITooltipTemplate, false);
  protected ROITooltip = getMetricExplanationTooltip(ROITooltipTemplate, false);

  public keyMetricTooltip = keyMetricTooltip;
  public keyMetricTooltipNoRPO = messages.KEY_METRIC_TOOLTIP_NO_RPO;
  public performanceColumnData: PerformanceColumnData = {};
  public allMetricsHaveRevenueToProfit = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metricTypes) {
      if (this.metricTypes?.length && this.savedMetricMappings?.length) {
        this.processMasterMetrics(this.savedMetricMappings);
      }
    }

    if (changes.savedMetricMappings) {
      const updatedMetrics = changes.savedMetricMappings.currentValue || [];
      if (updatedMetrics && this.metricTypes?.length) {
        this.processMasterMetrics(updatedMetrics);
      }
      this.syncMetrics(updatedMetrics);
    }

    if (this.objectTotals && changes.objectTotals) {
      this.updateContextTotals();
      this.calculateTargetValues();
      this.updateMetricsOrder();
    }
  }

  private processMasterMetrics(savedMetrics: Metric[]) {
    const savedTypeIds: SelectedValue[] = savedMetrics.map(metric => metric.typeId);
    const unusedMetrics = this.metricTypes
      .filter(metric => !savedTypeIds.includes(metric.id));

    this.masterMetricsSelectItems = getMetricSelectItems(this.products, unusedMetrics);
  }

  private updateContextTotals() {
    const { total = 0, committed = 0, closed = 0 } = this.objectTotals;
    this.contextTotal = total;
    this.contextTotalSpent = committed + closed;
  }

  private calculateTargetValuesForMetric$(metric: Metric) {
    const { revenuePerOutcome, revenueToProfit } =
      MetricsUtilsService.getMetricRPOAndRevenueToProfit(metric.typeId, this.metricTypes, this.products);

    if (!revenueToProfit) {
      this.allMetricsHaveRevenueToProfit = false;
    }

    const calcMetricValues$ =
      this.metricsService.calculateValues([
        {
          rpo: revenuePerOutcome,
          cost: this.contextTotal,
          value: metric.legacyTarget,
          revenue_to_profit: revenuePerOutcome && revenueToProfit
        },
        {
          rpo: revenuePerOutcome,
          cost: this.contextTotalSpent,
          value: this.getMetricCurrent(metric),
          revenue_to_profit: revenuePerOutcome && revenueToProfit
        }
      ]);

    return calcMetricValues$.pipe(
      tap(([ targetRes, currentRes ]) => {
        const dataItem = this.calculatedData.has(metric.id) ? this.calculatedData.get(metric.id) : {};

        this.calculatedData.set(metric.id, {
          ...dataItem,
          targetCPO: targetRes.CPO || 0,
          targetROI: targetRes.ROI || 0,
          currentCPO: currentRes.CPO || 0,
          currentROI: currentRes.ROI || 0
        });
      })
    );
  }

  private calculateTargetValues() {
    this.allMetricsHaveRevenueToProfit = true;
    forkJoin(
      this.metrics.map(metric => this.calculateTargetValuesForMetric$(metric))
    ).subscribe(
      { error: () => console.error('Failed to update target values') }
    );
  }

  private getTotalDataItem(targetId: number): MetricDataDO {
    if (!Array.isArray(this.totalMetricData)) {
      return null;
    }

    return this.totalMetricData.find(item => item.metric_mapping_id === targetId);
  }

  private getMetricCurrent(metric: Metric) {
    const totalDataItem = this.getTotalDataItem(metric.id);
    return totalDataItem ? totalDataItem.total_metric_actual : metric.current;
  }

  private patchMetricWithDefaults(metric: Metric) {
    if (!this.defaultStartDate || !this.defaultEndDate) {
      return;
    }

    if (!metric.startDate) {
      metric.startDate = this.defaultStartDate;
    }

    if (!metric.milestones || !metric.milestones.length) {
      metric.milestones = [
        {
          targetValue: metric.legacyTarget,
          date: parseDateString(this.defaultEndDate)
        }
      ];
    }
  }

  private applyProductsData() {
    if (!this.metrics?.length || !this.products?.length) {
      return;
    }
    this.metrics.forEach(metric => {
      if (metric.productId) {
        const product = this.products.find(prod => prod.id === metric.productId);
        metric.productName = product?.name;
        metric.productOrder = product?.order;
      }
    });
  }

  private updateMetricsOrder() {
    if (!this.metrics || !this.metrics.length) {
      return;
    }
    const keyMetricIndex = this.metrics.findIndex(m => m.id === this.keyMetricId);
    const [keyMetric] = this.metrics.splice(keyMetricIndex, keyMetricIndex < 0 ? 0 : 1);
    sortMetricsList(this.metrics);
    if (keyMetric) {
      this.metrics.unshift(keyMetric);
    }
  }

  private syncMetrics(metrics: Metric[]) {
    this.metrics = metrics;
    this.applyProductsData();
    this.updateMetricsOrder();

    this.metrics.forEach((metric) => {
      const dataItem: CalculatedMetricData = {
        targetCPO: 0,
        currentCPO: 0,
        targetROI: 0,
        currentROI: 0,
        targetValue: metric.legacyTarget,
        currentValue: this.getMetricCurrent(metric)
      };
      this.calculatedData.set(metric.id, dataItem);
      this.patchMetricWithDefaults(metric);
      this.preparePerformanceColumnData(metric);
    });
  }

  public addMetrics(metricIds: SelectedValue[]) {
    if (!this.objectId) {
      return;
    }
    this.addMetricsDropdownTrigger.closeMenu();

    forkJoin(
      metricIds.map(
        metricId => this.metricsService.createMetricMapping({
          mapping_type: this.objectType,
          map_id: this.objectId,
          metric_master: metricId as number,
        })
      )
    ).subscribe(mappings => {
        this.onAddMapping.emit(mappings);
        this.metricMappingDetailsService.reportMappingsListChange();
      }
    );
  }

  navigateToFunnelsPage() {
    this.addMetricsDropdownTrigger.closeMenu();
    this.appRoutingService.closeAllDetailsWindows();
    this.appRoutingService.safeNavigateTo([this.configuration.ROUTING_CONSTANTS.METRIC_FUNNELS]);
  }

  public openMetricDetails(id) {
    const navigate = this.appRoutingService.metricDetailsByObjectType[this.objectType];
    if (navigate) {
      navigate(id, this.router.url);
    }
  }

  public closeAddMetricsDropdown() {
    this.addMetricsDropdownTrigger.closeMenu();
  }

  onSetKeyMetric(metric: Metric, e) {
    e.stopPropagation();
    this.setKeyMetric.emit(metric);
  }

  hasRPO(metric: Metric) {
    return this.metricTypes.find(mType => mType.id === metric.id)?.revenuePerOutcome != null;
  }

  preparePerformanceColumnData(metric: Metric) {
    const currentValue = this.calculatedData.get(metric.id)?.currentValue;
    const { progressState, diffShare, estimatedTarget } = this.defineState(metric, currentValue, this.todayDate);

    this.performanceColumnData[metric.id] = {
      mappingId: null,
      current: currentValue,
      target: metric.target,
      milestones: metric.milestones,
      name: metric.name,
      estimatedTarget,
      estimatedDiffPercentage: roundDecimal(diffShare * 100, 2),
      startDate: metric.startDate,
      progressState
    };
  }

  defineState(metric: Metric, currentValue: number, todayDate?: Date): {
    progressState: MetricProgressState;
    diffShare: number;
    estimatedTarget: number
  } {
    if (!metric.milestones || !metric.startDate) {
      return;
    }

    const estimatedTarget = this.metricsUtilsService.getEstimatedTarget(
      getTodaysDate(todayDate),
      parseDateString(metric.startDate),
      metric.milestones
    );
    const { state: progressState, diffShare } = this.metricsUtilsService.getProgressState(estimatedTarget, currentValue);

    return { progressState, diffShare, estimatedTarget };
  }

  public trackByFn(_index, item) {
    return item.id;
  }
}
