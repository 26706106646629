import { Injectable, OnDestroy } from '@angular/core';
import { BaseManagePageTagsService } from '@shared/services/base-manage-page-tags.service';
import { ManageCegPageApiService } from './manage-ceg-page-api.service';

@Injectable({
  providedIn: 'root'
})
export class ManageCegPageTagsService extends BaseManagePageTagsService implements OnDestroy {

  constructor(
    protected readonly apiService: ManageCegPageApiService
  ) {
    super(apiService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.tagMappingsLoading$.complete();
  }
}
