import { Component } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-fa-duotone-list-tree',
  templateUrl: './icon-fa-duotone-list-tree.component.html'
})
export class IconFaDuotoneListTreeComponent extends IconBaseComponent {
  constructor() {
    super();
  }
}

