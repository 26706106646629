import { Injectable } from '@angular/core';
import { Configuration } from 'app/app.constants';
import {
  BudgetAmountsSummaryData,
  BudgetAmountsSummaryValues
} from 'app/dashboard/budget-amounts-summary/budget-amounts-summary.component';
import { PlanCampaign, PlanObject, PlanProgram } from 'app/dashboard/dashboard.types';
import { PlanObjectDO } from '../types/plan.type';

interface TooltipPositionConfig {
  containerWidth: number;
  thresholdWidth: number;
  offsetX?: number;
  offsetY?: number;
}
@Injectable({
  providedIn: 'root'
})
export class BudgetAmountsSummaryService {

  constructor(private readonly configuration: Configuration) {}

  patchTimelineGraphData(data: any[], currency) {
    if (!Array.isArray(data)) {
      return;
    }

    data.forEach(item => {
      item.tooltipData = this.createTooltipData({
        item,
        title: item.company_budget_alloc_name,
        currency
      });
    });
  }

  public provideWithTooltipData(planObject: PlanObjectDO & PlanObject, currency: string): void {
    planObject.tooltipData = this.createTooltipData({
      item: planObject.status_totals,
      title: planObject.name,
      currency
    });
  }

  public createTooltipData({
    item,
    title,
    currency
  }, isBudgetView = false, cegFlagEnabled = false): BudgetAmountsSummaryData {
    const fieldNames = this.configuration.statusFields;
    const expensesTotal = item[fieldNames.planned] + item[fieldNames.committed] + (item[fieldNames.closed] ?? 0);
    const values: BudgetAmountsSummaryValues = {
      total: item.total,
      available: item[fieldNames.available],
      overBudget: item[fieldNames.overBudget],
      underBudget: item[fieldNames.underBudget],
      reserved: item[fieldNames.reserved],
      planned: item[fieldNames.planned],
      committed: item[fieldNames.committed],
      closed: item[fieldNames.closed],
      actual: item[fieldNames.actual],
      remainingAllocated: item[fieldNames.remainingAllocated],
      overSpend: item[fieldNames.overSpend],
      expenses: expensesTotal,
    };

    return {
      title,
      currency,
      isBudgetView,
      cegFlagEnabled,
      values
    };
  }

  /**
   * Calculate fixed position for tooltip based on eventTarget and container's width
   * @param event
   * @param config
   *   containerWidth - width of the area for displaying tooltip
   *   thresholdWidth - minimal width required to fit tooltip on the right side, otherwise - display on the left
   *   offsetX - horizontal offset for the 'tip'
   */
  calculateTooltipPosition(event: MouseEvent, config: TooltipPositionConfig) {
    const result = {
      styles: {
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: 'auto',
      },
      position: 'right'
    };
    const targetRect = (event.target as Element).getBoundingClientRect();
    const { containerWidth, thresholdWidth, offsetX = 0, offsetY = 0 } = config;

    result.styles.top = `${Math.round(targetRect.bottom - targetRect.height / 2 + offsetY)}px`;
    if (targetRect.right > containerWidth - thresholdWidth) {
      result.styles.right = `${containerWidth - targetRect.left + offsetX}px`;
      result.position = 'left';
    } else {
      result.styles.left = `${targetRect.right + offsetX}px`;
      result.position = 'right';
    }

    return result;
  }
}
