import { Component, Input } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-google-ads',
  templateUrl: './icon-google-ads.component.html',
})
export class IconGoogleAdsComponent extends IconBaseComponent {
  @Input() isMonoChrome: boolean = true;
  constructor() {
    super();
  }
}
