import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortParams } from 'app/shared/types/sort-params.interface';

@Component({
  selector: 'sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.scss']
})
export class SortButtonComponent {
  @Input() appliedSorting: SortParams;
  @Input() columnName = '';
  @Output() onClick = new EventEmitter<string>();
}
