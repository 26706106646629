import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { MetricBreakdownListRow } from './metric-breakdown-list.type';
import { RotateProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'metric-breakdown-list',
  templateUrl: './metric-breakdown-list.component.html',
  styleUrls: ['./metric-breakdown-list.component.scss']
})
export class MetricBreakdownListComponent implements OnChanges {
  @Input() rows: MetricBreakdownListRow[] = [];
  @Input() nameColumnTitle = '';
  @Input() icon: IconDefinition;
  @Input() showTotal = true;
  @Input() grandTotal: MetricBreakdownListRow;
  @Input() displayDecimal = false;
  @Input() iconRotate: RotateProp;
  @Input() withGrandTotalTarget = true;
  @Input() withTarget = true;

  @Output() rowClick = new EventEmitter<MetricBreakdownListRow>();

  decimalDisplayFormat = '1.0-0';
  totalTarget = 0;
  totalCurrent = 0;

  ngOnChanges(changes: SimpleChanges) {
    this.totalTarget = this.rows.reduce((result, row) => result + row.target, 0);
    this.totalCurrent = this.rows.reduce((result, row) => result + row.current, 0);

    if (changes.displayDecimal) {
      this.decimalDisplayFormat = this.displayDecimal ? '1.2-2' : '1.0-0';
    }
  }

  handleRowClick(row: MetricBreakdownListRow) {
    this.rowClick.emit(row);
  }
}
