import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'metric-editor-notes',
  templateUrl: './metric-editor-notes.component.html',
  styleUrls: ['./metric-editor-notes.component.scss']
})
export class MetricEditorNotesComponent {
  @Input() notesControl: UntypedFormControl;
  @Output() onBlur = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @ViewChild('textfield') textfield: ElementRef;
  public notesShown = false;
  public overlayCoords = [{
      originX: 'start',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top'
  }];

  showNotes() {
    this.notesShown = true;
  }

  onTextareaBlur($event) {
    this.notesShown = false;
    this.onBlur.emit($event);
  }

  onAttach() {
    setTimeout(() =>
      this.textfield.nativeElement.focus(), 100
    )
  }
}
