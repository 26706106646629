import { Component, Input } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { AppRoutingService } from 'app/shared/services/app-routing.service';

@Component({
  selector: 'object-link',
  templateUrl: './object-link.component.html',
  styleUrls: ['./object-link.component.scss']
})
export class ObjectLinkComponent {
  @Input() objectType: string;
  @Input() metricType: string;
  @Input() objectName: string;
  @Input() objectId: number;
  @Input() withIcon = true;
  @Input() actionHandler: Function = null;
  @Input() isClosed = false;
  @Input() productName: string;

  public iconByObjectType = this.configuration.iconsConfig;
  public routeActionByObjectType = {
    [this.configuration.OBJECT_TYPES.campaign]: (id: number) => this.appRoutingService.openCampaignDetails(id),
    [this.configuration.OBJECT_TYPES.program]: (id: number) => this.appRoutingService.openProgramDetails(id),
    [this.configuration.OBJECT_TYPES.expense]: (id: number) => this.appRoutingService.openExpenseDetails(id),
    [this.configuration.OBJECT_TYPES.goal]: (id: number) => this.appRoutingService.openGoalDetails(id),
  };

  constructor(
    private readonly configuration: Configuration,
    private readonly appRoutingService: AppRoutingService,
  ) {
  }

  public handleClick($event) {
    $event.stopPropagation();
    if (typeof this.actionHandler === 'function') {
      this.actionHandler();
      return;
    }

    const action = this.routeActionByObjectType[this.objectType];
    if (typeof action === 'function' && this.objectId) {
      action(this.objectId);
    }
  }
}
