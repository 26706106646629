import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions, withAPIV0ErrorHandling } from 'app/shared/utils/http-request.utils';
import { API_V0_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { Observable } from 'rxjs';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';

export interface SearchResponse extends ApiV0Response<object[]> {
  next: number | null;
  previous: number | null;
  count: number;
  num_pages: number;
}

@Injectable()
export class SearchService {
  private readonly actionUrl: string = inject(API_V0_URL);
  private readonly http = inject(HttpClient);

  getSearchResult(companyId: number, budgetId: number, data: object): Observable<SearchResponse> {
    const requestUrl = this.actionUrl + 'basic/object/search/' + companyId + '/' + budgetId + '/';
    return withAPIV0ErrorHandling(
      this.http.get<SearchResponse>(requestUrl, getRequestOptions(data))
    );
  }
}
