export interface Company {
  id: number;
  name: string;
  status: string;
}

export interface CompanyIntegrationFlags {
  salesforce: boolean;
  google_ads: boolean;
  linkedin_ads: boolean;
  facebook_ads: boolean;
  sso: boolean;
  hubspot: boolean;
  budgie: boolean;
}

export enum CompanyIntegrationFlag {
  Salesforce = 'salesforce',
  GoogleAds = 'google_ads',
  LinkedinAds = 'linkedin_ads',
  FacebookAds = 'facebook_ads',
  Budgie = 'budgie',
  Adopted = 'adopted',
  SSO = 'sso',
  Hubspot = 'hubspot',
  SaasOpticsCustomerId = 'saas_optics_customer_id',
}

export enum CompanyProperties {
  Status = 'status',
  DefaultForSuppressTimeframeAllocations = 'default_for_suppress_timeframe_allocations',
  SaasOpticsId = 'saasOpticsId',
  NonReporting = 'non_reporting',
  SalesforceTenantId = 'salesforce_tenant_id'
}

export interface SuperAdminCompaniesResponse {
  data: CompanyDO[];
  message: string;
  status: number;
}

export interface CompanyDO extends CompanyIntegrationFlags {
  id: number;
  name: string;
  owner: string;
  planning_for: string;
  company_code: string;
  crd: string;
  upd: string;
  status: string;
  non_reporting: boolean;
  hubspot_company_id: string;
  goal_detail_transparency: boolean;
  company_size: number;
  company_industry: number;
  company_revenue: number;
  created_by: number;
  currency: string;
  currency_symbol: string;
  sso_domain?: string
  sso_identity_provider?: string;
  budget_count: number;
  unlimited: boolean;
  default_for_suppress_timeframe_allocations: boolean;
  saas_optics_customer_id?: string;
  salesforce_tenant_id?: string;
  default_for_new_campaigns_programs_structure: boolean;
}

export interface CompanyFilterItem {
  type: 'plan_code' | 'status' | 'non_reporting' | '';
  value: string;
  title: string;
}

