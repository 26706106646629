import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ManageTableViewMode } from 'app/manage-table/types/manage-table-view-mode.type';
import { ManageTableDataMode } from 'app/manage-table/types/manage-table-data-mode.type';
import { SpendingModeFlag, SpendingModeFlags } from 'app/manage-table/types/spending-mode-flags.type';
import { AllocationModeFlag, AllocationModeFlags } from '../../types/allocation-mode-flags.type';

interface SwitchOption {
  viewMode: ManageTableViewMode;
  icon: IconProp;
  label: string;
}

@Component({
  selector: 'table-mode-switch',
  templateUrl: './table-mode-switch.component.html',
  styleUrls: ['./table-mode-switch.component.scss']
})
export class TableModeSwitchComponent {
  @Input() viewMode: ManageTableViewMode;
  @Input() dataMode: ManageTableDataMode;
  @Input() showExpenses = false;
  @Input() spendingModeFlags: SpendingModeFlags;
  @Input() allocationModeFlags: AllocationModeFlags;
  @Input() disableExpenseToggle = false;
  @Output() onViewModeChange = new EventEmitter<ManageTableViewMode>();
  @Output() onShowExpensesChange = new EventEmitter<boolean>();
  @Output() onSpendingModeFlagsChange = new EventEmitter<SpendingModeFlags>();
  @Output() onAllocationModeFlagsChange = new EventEmitter<AllocationModeFlags>();

  private _spendingModeFlags: SpendingModeFlags;
  private _allocationModeFlags: AllocationModeFlags;
  public readonly ManageTableDataMode = ManageTableDataMode;
  public readonly SpendingModeFlag = SpendingModeFlag;
  public readonly switchOptions: SwitchOption[] = [
    {
      viewMode: ManageTableViewMode.Segments,
      icon: ['far', 'chart-pie-simple'] as IconProp,
      label: 'Segment'
    },
    {
      viewMode: ManageTableViewMode.Goals,
      icon: ['fas', 'bullseye-arrow'] as IconProp,
      label: 'Goal'
    },
    {
      viewMode: ManageTableViewMode.Campaigns,
      icon: ['far', 'rocket-launch'] as IconProp,
      label: 'Campaign'
    }
  ];
  public readonly AllocationModeFlag = AllocationModeFlag;

  public setViewMode(mode: ManageTableViewMode) {
    if (mode !== this.viewMode) {
      this.viewMode = mode;
      this.onViewModeChange.emit(mode);
    }
  }

  public toggleExpenses(value: boolean) {
    this.showExpenses = value;
    this.onShowExpensesChange.emit(this.showExpenses);
  }

  public toggleSpendingModeFlag(value: boolean, flag: SpendingModeFlag) {
    this.onSpendingModeFlagsChange.emit({
      ...this._spendingModeFlags,
      [flag]: value
    });
  }

  public toggleAllocationModeFlag(value: boolean, flag: AllocationModeFlag) {
    this.onAllocationModeFlagsChange.emit({
      ...this._allocationModeFlags,
      [flag]: value
    });
  }
}
