import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'manage-table-segment-breakdown',
  templateUrl: './manage-table-segment-breakdown.component.html',
  styleUrls: ['./manage-table-segment-breakdown.component.scss']
})
export class ManageTableSegmentBreakdownComponent {
  @ContentChild('segment') segment: TemplateRef<any>;
  @ContentChild('allocated') allocated: TemplateRef<any>;
  @ContentChild('unallocated') unallocated: TemplateRef<any>;

  @Input() allocatedOnly = true;
  @Input() withSubtitles = true;
}
