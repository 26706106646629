import { Component, Input } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { LegendItem } from '../dashboard.types';

@Component({
  selector: 'statuses-legend',
  templateUrl: './statuses-legend.component.html',
  styleUrls: ['./statuses-legend.component.scss']
})
export class StatusesLegendComponent {

  @Input() cegFlagEnabled = false;
  @Input() isManageTableTimeline = false;
  legendData: LegendItem[][];
  legendDataCeg: LegendItem[];
  constructor(private config: Configuration) {
    const { closed, committed, planned } = config.expenseStatusesDescriptions;
    const { remainingBudget, remainingAllocated, underBudget, overBudget } = config.budgetStatusesDescriptions;
    const { statusFields } = config;

    this.legendData = [
      // expense-related
      [
        {
          title: 'Closed',
          tooltipDesc: closed,
          status: statusFields.closed
        }, {
          title: 'Committed',
          tooltipDesc: committed,
          status: statusFields.committed
        }, {
          title: 'Planned',
          tooltipDesc: planned,
          status: statusFields.planned
        },
      ],
      // budget-related
      [
        {
          title: 'Remaining Budget',
          tooltipTitle: 'Remaining Budget',
          tooltipDesc: remainingBudget,
          status: statusFields.budgetRemainingAmount
        },
        {
          title: 'Remaining Allocated',
          tooltipTitle: 'Remaining Allocated',
          tooltipDesc: remainingAllocated,
          status: statusFields.remainingAllocated
        },
        {
          title: 'Under Budget',
          tooltipDesc: underBudget,
          status: statusFields.underBudget
        },
        {
          title: 'Over Budget',
          tooltipDesc: overBudget,
          status: statusFields.overBudget
        },
      ]
    ];

    this.legendDataCeg = [
      {
        title: 'Actual',
        status: statusFields.actual
      },
      {
        title: 'Rmng Committed',
        status: statusFields.committed
      }, {
        title: 'Rmng Planned',
        status: statusFields.planned
      },
      {
        title: 'Available',
        status: statusFields.budgetRemainingAmount
      },
      {
        title: 'Over Budget',
        status: statusFields.overBudget
      },
    ];
  }
}
