import { inject, Injectable } from '@angular/core';
import { MetricMappingDO } from '@shared/services/backend/metric.service';
import { Campaign, LightCampaign } from '@shared/types/campaign.interface';
import { Budget } from '@shared/types/budget.interface';
import { MetricDetailsStateMapper } from 'app/budget-object-details/services/state-mappers/metric-mapping-state-mapper.service';
import { getTodayFixedDate } from '@shared/utils/budget.utils';
import { getTodaysDate } from '@shared/utils/date.utils';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { roundDecimal } from '@shared/utils/common.utils';
import { MetricsUtilsService } from 'app/budget-object-details/services/metrics-utils.service';
import { PerformanceColumnData, PerformanceColumnDataItem } from '../types/manage-ceg-page.types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { Configuration } from 'app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class CampaignPerformanceService {
  private readonly metricsUtilsService = inject(MetricsUtilsService);
  private readonly metricMappingDetailsService = inject(MetricMappingDetailsService);
  private readonly configuration = inject(Configuration);


  public loadCampaignPerformanceData$(campaigns: LightCampaign[], companyId: number): Observable<[MetricMappingDO[], Campaign[]]> {
    const campaignsWithKeyMetrics = (campaigns || []).filter(campaign => campaign.keyMetric);
    const keyMetricIds = campaignsWithKeyMetrics.map(campaign => campaign.keyMetric);

    return keyMetricIds.length ?
      this.metricMappingDetailsService.getMetricMappings(
        companyId,
        {
          ids: keyMetricIds.join(','),
          mapping_type: this.configuration.OBJECT_TYPES.campaign
        }
      ).pipe(
        map(metricMappings => [metricMappings, campaignsWithKeyMetrics])
      ) :
      of([[], []]);
  }

  public getCampaignPerformanceColumnData(
    metricMappings: MetricMappingDO[],
    metricCampaigns: LightCampaign[],
    budget: Budget
  ): PerformanceColumnData {
   return metricMappings.reduce((data, mapping) => {
     const dataItem = this.buildPerformanceColumnDataItem(mapping, metricCampaigns, budget);
     return { ...data, [mapping.map_id]: dataItem };
   }, {});
  }

  private buildPerformanceColumnDataItem(
    mapping: MetricMappingDO,
    metricCampaigns: LightCampaign[],
    budget: Budget
  ): PerformanceColumnDataItem {
    const { value: metricValues } = MetricDetailsStateMapper.convertMetricCalculations(mapping.metric_calculations);
    const current = metricValues.length ? metricValues[metricValues.length - 1].value : 0;
    const target = mapping.projection_amount;
    const name = mapping.metric_detail.name;
    let milestones = MetricDetailsStateMapper.convertMetricDOMilestones(mapping.milestones);
    let startDate = mapping.start_date;

    if (!startDate || !milestones.length) {
      const targetCampaign = metricCampaigns.find(campaign => campaign.id === mapping.map_id);
      startDate = this.metricsUtilsService.getDefaultStartDate(startDate, targetCampaign, budget);
      milestones = this.metricsUtilsService.getDefaultMilestones(milestones, targetCampaign, mapping, budget);
    }

    const todayDate = getTodayFixedDate(budget);

    const estimatedTarget = this.metricsUtilsService.getEstimatedTarget(
      getTodaysDate(todayDate),
      parseDateString(startDate),
      milestones
    );

    const { state: progressState, diffShare } = this.metricsUtilsService.getProgressState(estimatedTarget, current);
    const estimatedDiffPercentage = roundDecimal(diffShare * 100, 2);

    return {
      mappingId: mapping.id,
      current,
      target,
      milestones,
      name,
      startDate,
      progressState,
      estimatedTarget,
      estimatedDiffPercentage
    };
  }
}
