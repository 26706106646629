import { EventEmitter, Component, OnInit, Output, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface SettingFormOption<KeyType> {
  id: KeyType;
  title: string;
  defaultValue: boolean;
}

export interface GroupSelectionState {
  allChecked: boolean;
  someChecked: boolean;
}

export enum BudgetLevelControls {
  INCLUDE_BUDGETS = 'amounts',
  GOAL_METRIC_TARGETS = 'goal_metric_targets',
  CAMPAIGN_METRIC_MAPPINGS = 'campaign_metric_mappings',
  CAMPAIGN_METRIC_VALUES = 'campaign_metric_values',
}

export enum HierarchyControls {
  GOALS = 'goals',
  CAMPAIGNS = 'campaigns',
  EXPENSE_GROUPS = 'programs',
  EXPENSES = 'expenses',
}

@Component({
  selector: 'export-budget-settings',
  templateUrl: './export-budget-settings.component.html',
  styleUrls: ['./export-budget-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportBudgetSettingsComponent implements OnInit, OnDestroy {
  protected isActive = true;
  protected showContent = false;
  protected BudgetLevelControls = BudgetLevelControls;
  protected enableItemTooltip = {
    campaigns: 'Enable Campaigns to activate',
    campaignMapping: 'Enable Campaign Metrics Mapping to activate',
  };
  protected hierarchyState: GroupSelectionState = {
    allChecked: false,
    someChecked: false,
  };
  protected budgetLevelGroupState: GroupSelectionState = {
    allChecked: false,
    someChecked: false,
  };
  protected hierarchyOptions: SettingFormOption<HierarchyControls>[] = [
    { id: HierarchyControls.GOALS, defaultValue: true, title: 'Goals' },
    { id: HierarchyControls.CAMPAIGNS, defaultValue: true, title: 'Campaigns' },
    { id: HierarchyControls.EXPENSE_GROUPS, defaultValue: true, title: 'Expense Groups' },
    { id: HierarchyControls.EXPENSES, defaultValue: true, title: 'Expenses' },
  ];
  private budgetLevelConfig = {
    [BudgetLevelControls.INCLUDE_BUDGETS]: true,
    [BudgetLevelControls.CAMPAIGN_METRIC_MAPPINGS]: true,
    [BudgetLevelControls.CAMPAIGN_METRIC_VALUES]: true,
  };
  private hierarchyConfig = ExportBudgetSettingsComponent.createFormConfig(this.hierarchyOptions);
  protected settingsForm = this.fb.group({
    budgetLevel: this.fb.group(this.budgetLevelConfig),
    hierarchyGroup: this.fb.group(this.hierarchyConfig),
  });
  private destroy$ = new Subject<void>();
  @Output() close = new EventEmitter<string[]>();

  private static toggleGroupSelection(groupState: GroupSelectionState, controls: AbstractControl[], checked: boolean): void {
    controls.forEach(control => control.setValue(checked, { emitEvent: false }));
    groupState.allChecked = checked;
    groupState.someChecked = false;
  }

  private static createFormConfig(options: SettingFormOption<any>[]): Record<string, boolean> {
    return options.reduce((config, option) => {
      config[option.id] = option.defaultValue;
      return config;
    }, {});
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.setInitialSelectionState();
    this.subscribeToValueChanges();
    setTimeout(() => {
      this.showContent = true;
      this.cdr.markForCheck();
    }, 400);
  }

  private setInitialSelectionState(): void {
    this.updateGroupSelectionState(this.budgetLevelGroupState, { ...this.hierarchyConfig, ...this.budgetLevelConfig });
    this.updateGroupSelectionState(this.hierarchyState, this.hierarchyConfig);
  }

  private subscribeToValueChanges(): void {
    this.campaignsControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.onCampaignsValueChanged(value));

    this.budgetLevelControls.get(BudgetLevelControls.CAMPAIGN_METRIC_MAPPINGS).valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onCampaignMetricsChanged());

    this.budgetLevelControls.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateBudgetLevelSelectionState());

    this.budgetHierarchyGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(values => {
        this.updateGroupSelectionState(this.budgetLevelGroupState, { ...values, ...this.budgetLevelControls.getRawValue() });
        this.updateGroupSelectionState(this.hierarchyState, values);
      });
  }

  private updateBudgetLevelSelectionState(): void {
    const hierarchyValues = this.budgetHierarchyGroup.value;
    const budgetLevelValues = this.budgetLevelControls.getRawValue();
    this.updateGroupSelectionState(this.budgetLevelGroupState, { ...hierarchyValues, ...budgetLevelValues });
  }

  private updateGroupSelectionState(groupState: GroupSelectionState, allValuesObject: Record<string, boolean>): void {
    const allValues = Object.values(allValuesObject);
    const selectedLength = allValues.filter(value => !!value).length;
    groupState.allChecked = selectedLength === allValues.length;
    groupState.someChecked = selectedLength > 0 && !groupState.allChecked;
  }

  protected get budgetLevelControls(): FormGroup {
    return this.settingsForm.controls.budgetLevel;
  }

  protected get budgetHierarchyGroup(): FormGroup {
    return this.settingsForm.controls.hierarchyGroup;
  }

  protected get campaignsControl(): AbstractControl {
    return this.budgetHierarchyGroup.controls[HierarchyControls.CAMPAIGNS];
  }

  private onCampaignsValueChanged(selected: boolean): void {
    const relatedControls = [BudgetLevelControls.CAMPAIGN_METRIC_MAPPINGS];
    this.toggleControlDisabledState(!selected, relatedControls);
    this.onCampaignMetricsChanged();
  }

  private onCampaignMetricsChanged(): void {
    const relatedControls = [
      BudgetLevelControls.CAMPAIGN_METRIC_VALUES
    ];
    this.onMetricControlChanged(BudgetLevelControls.CAMPAIGN_METRIC_MAPPINGS, relatedControls);
  }

  private onMetricControlChanged(controlName: BudgetLevelControls, relatedControls: string[]): void {
    const metricControl = this.budgetLevelControls.get(controlName);
    const metricSelected = metricControl.value;
    const metricDisabled = metricControl.disabled;
    const disableRelatedControls = !metricSelected || metricDisabled;
    this.toggleControlDisabledState(disableRelatedControls, relatedControls);
  }

  private toggleControlDisabledState(isDisabled: boolean, controlNames: string[]): void {
    const controls = controlNames.map(name => this.budgetLevelControls.get(name));
    controls.forEach(control => {
      if (isDisabled) {
        control.setValue(false, { emitEvent: false });
        control.disable({ emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
      }
    });
  }

  protected closeComponent(data: string[]): void {
    this.isActive = this.showContent = false;
    setTimeout(() => {
      this.close.emit(data);
      this.cdr.markForCheck();
    }, 200);
  }

  protected saveData(): void {
    this.isActive = false;
    this.closeComponent(this.getExclusions(this.settingsForm.value));
  }

  private getExclusions(formValue: Record<string, any>): string[] {
    const formRawValue = this.settingsForm.getRawValue();
    const values = { ...formValue.budgetLevel, ...formValue.hierarchyGroup };
    const disabledFields = Object.keys(formRawValue.budgetLevel).filter(key => formValue.budgetLevel[key] === undefined);

    return [
      ...Object.keys(values).filter(key => values[key] === false),
      ...disabledFields
    ];
  }

  protected budgetLevelSelectionChange(checked: boolean): void {
    ExportBudgetSettingsComponent.toggleGroupSelection(
      this.budgetLevelGroupState,
      Object.values(this.budgetLevelControls.controls),
      checked
    );
    this.hierarchySelectionChange(checked);
  }

  protected hierarchySelectionChange(checked: boolean): void {
    ExportBudgetSettingsComponent.toggleGroupSelection(
      this.hierarchyState,
      Object.values(this.budgetHierarchyGroup.controls),
      checked
    );
    this.onCampaignsValueChanged(checked);
    this.updateBudgetLevelSelectionState();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
