import { ActionMenuItem } from 'app/shared/components/actions-menu/actions-menu.component';
import { ExternalCampaign } from '../../types/external-campaign.interface';
import { IntegrationChildrenMappingState, IntegrationMappingState } from '../../types/metric-integration';

export enum CampaignsSorting {
  SORT_BY_NAME = 'sortByName',
  SORT_BY_MAPPING_STATUS = 'sortByMappingStatus',
}

export const sortDropdownMenuItems: ActionMenuItem[] = [
  { text: 'Sort by: Campaign Name (A-Z)', value: CampaignsSorting.SORT_BY_NAME },
  { text: 'Sort by: Mapping type', value: CampaignsSorting.SORT_BY_MAPPING_STATUS },
];

export const compareExternalCampaigns = (sortingType: CampaignsSorting) => {
  return sortingType === CampaignsSorting.SORT_BY_NAME
  ? compareCampaignsByName
  : compareCampaignsByStatus
}

const compareCampaignsByName = (campA: ExternalCampaign, campB: ExternalCampaign) => {
  const nameA = campA.name.toLowerCase();
  const nameB = campB.name.toLowerCase();
  return nameA.localeCompare(nameB);
}

const compareCampaignsByStatus = (campA: ExternalCampaign, campB: ExternalCampaign) => {
  const stateToNum = camp => Number(camp.mappingState === IntegrationMappingState.Mapped);
  return stateToNum(campB) - stateToNum(campA);
}

export function getChildrenMappingState(campaign, mapperFn) {
  if (!campaign.children?.length) {
    return campaign.mappingState === IntegrationMappingState.Mapped ?
      IntegrationChildrenMappingState.MappedAll :
      IntegrationChildrenMappingState.NotMapped;
  }
  const childrenMappingStates = campaign.children.map(mapperFn);

  return childrenMappingStates.every(
    state => state.mappingState === IntegrationMappingState.Mapped &&
      state.mappingChildrenState === IntegrationChildrenMappingState.MappedAll
  ) ?
    IntegrationChildrenMappingState.MappedAll :
    childrenMappingStates.every(
      state => state.mappingState === IntegrationMappingState.NotMapped &&
        state.mappingChildrenState === IntegrationChildrenMappingState.NotMapped &&
        !state.existingMapping
    ) ?
      IntegrationChildrenMappingState.NotMapped :
      IntegrationChildrenMappingState.MappedSome;
}

export function updateChildrenMappingStates(campaign: ExternalCampaign, children: ExternalCampaign[]) {
  // for now we can ignore "IntegrationChildrenMappingState.MappedAll" state for children
  const someChildMapped = children.some(child => {
    return child.mappingState === IntegrationMappingState.Mapped ||
      child.mappingChildrenState === IntegrationChildrenMappingState.MappedSome;
  });
  campaign.mappingChildrenState = someChildMapped ?
    IntegrationChildrenMappingState.MappedSome : IntegrationChildrenMappingState.NotMapped;
}
