<widget-card [isLoading]="state === widgetState.LOADING">
  <div class="estimated-business-value-widget">
    <details-widget-title [showCount]="false" text="Estimated Campaign Value">
      <fa-duotone-icon slot="icon" [fixedWidth]="true" [icon]="['fad', 'heart']"></fa-duotone-icon>
      <fa-duotone-icon
        slot="title-content"
        [icon]="['fad', 'graduation-cap']"
        [matTooltip]="tooltipText"
        matTooltipClass="dark-theme-tooltip multi-line right text-left bv-widget-tooltip"
        matTooltipPosition="right"
        class="cap-icon"
      ></fa-duotone-icon>
    </details-widget-title>
    <div class="content">
      <widget-card-placeholder *ngIf="state === widgetState.EMPTY">
        <fa-duotone-icon [icon]="['fad', 'chart-line']" slot="icon"></fa-duotone-icon>
        <ng-container slot="content">
          Campaign value estimates the impact of marketing in financial terms.
          For each campaign this is calculated by <b>multiplying the value of the key metric by its average revenue per outcome.</b>
          For a goal this is the sum of the campaign value of all related campaigns.
          <br>
          <br>
          Therefore, you first need to create some metrics and define your key metrics to start generating Estimated Campaign Values.
        </ng-container>
      </widget-card-placeholder>
      <business-value-table
        *ngIf="state === widgetState.READY"
        [data]="tableData"
        [currency]="company?.currency_symbol"
      ></business-value-table>
    </div>
  </div>
</widget-card>
