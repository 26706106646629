<div class="overdue-expenses-table">
  <div class="view-mode-control" *ngIf="plannedExpenses.length && committedExpenses.length">
    <group-toggle-control
      [currentValue]="currentViewMode"
      [options]="viewModeOptions"
      (onChange)="handleToggleChange($event)"
    ></group-toggle-control>
  </div>

  <mat-menu #closeItemMenu="matMenu" class="mat-pl-menu align-content-left">
    <button mat-menu-item (click)="handleCloseAction()">Close at {{ currentViewMode }}</button>
    <button mat-menu-item (click)="handleCloseAction(true)">Close at 0.00</button>
  </mat-menu>

  <mat-menu #closeAllMenu="matMenu" class="mat-pl-menu align-content-left">
    <button mat-menu-item (click)="handleCloseAllAction()">Close All at {{ currentViewMode }}</button>
    <button mat-menu-item (click)="handleCloseAllAction(true)">Close All at 0.00</button>
  </mat-menu>

  <div
    class="overdue-table"
    *ngIf="currentList?.length"
    [ngClass]="{
      'is-scrollable': currentList.length >= VISIBLE_ROWS_LIMIT,
      'is-scrolled': isBodyScrolled
    }"
  >
    <div class="table-head">
      <div class="table-row">
        <div class="table-cell name-cell">{{ currentViewMode }}</div>
        <div class="table-cell stuck-cell">Stuck in</div>
        <div class="table-cell amount-cell">Amount</div>
        <div class="table-cell close-cell">
          <div *ngIf="isReadOnly" class="action disabled">Close All</div>
          <div *ngIf="!isReadOnly" class="action" [matMenuTriggerFor]="closeAllMenu">Close All</div>
        </div>
        <div class="table-cell move-cell">
          <div class="action" [class.disabled]="!timeframeToMoveAll" (click)="handleMoveAllAction()">
            Move All
            <ng-container *ngIf="timeframeToMoveAll">to {{ timeframeToMoveAll?.name | slice:0:3 }}</ng-container>
          </div>
        </div>
        <div class="table-cell tf-cell">
          <div class="tf-control"
            (click)="toggleTimeframePicker(HEADER_ROW_ID)"
            [class.is-active]="timeframePickerOpenId === HEADER_ROW_ID"
            [class.disabled]="isReadOnly"
          >
            <fa-duotone-icon [icon]="['fad', 'calendar-alt']"></fa-duotone-icon>
            <timeframe-picker
              [timeframes]="timeframes"
              [overdueTimeframes]="overdueTimeframeIds"
              [selectedTimeframe]="selectedMoveAllTf?.id"
              (onSelect)="handleSelectedTF($event, HEADER_ROW_ID)"
            ></timeframe-picker>
          </div>
        </div>
      </div>
    </div>

    <div
      class="table-body"
      isScrolled
      (isScrolledChanged)="handleBodyScrolledChange($event)"
    >
      <div
        class="table-row"
        [class.is-immovable]="selectedMoveMap[currentViewMode]?.get(item.id) == null"
        *ngFor="let item of currentList; let idx = index; trackBy: trackFn"
      >
        <div class="table-cell name-cell">
          <div class="icon" (click)="openDetails(item.expenseId)">
            <fa-duotone-icon [icon]="['fad', 'info-circle']"></fa-duotone-icon>
          </div>
          <div class="value" [stringTruncate]="item.name"></div>
        </div>
        <div class="table-cell stuck-cell">{{ item.allocationName | slice:0:3 }}</div>
        <div class="table-cell amount-cell">
          <amount-control
            [value]="item.mode === allocationMode.Committed ? item.sourceActualAmount : item.sourceAmount"
            [currency]="currencyMap?.get(item.currency)?.symbol"
            [disabled]="item.isReadOnly || isReadOnly"
            (onChange)="handleAmountChange($event, item)"
          ></amount-control>
        </div>
        <div class="table-cell close-cell">
          <div *ngIf="item.isReadOnly || isReadOnly; else defaultCloseCell" class="action disabled">
            Close
          </div>
          <ng-template #defaultCloseCell>
            <div class="action" [matMenuTriggerFor]="closeItemMenu" (click)="setCloseItem(item)">
              Close
            </div>
          </ng-template>
        </div>
        <div class="table-cell move-cell">
          <div
            *ngIf="selectedMoveMap[currentViewMode]?.get(item.id)"
            class="action"
            [class.disabled]="item.isReadOnly || isReadOnly"
            (click)="handleMoveAction(item, selectedMoveMap[currentViewMode]?.get(item.id))"
          >
            Move to {{ selectedMoveMap[currentViewMode]?.get(item.id)?.name | slice:0:3 }}
          </div>
          <div class="action" *ngIf="selectedMoveMap[currentViewMode]?.get(item.id) == null" (click)="openDetails(item.expenseId)">
            View details
          </div>
        </div>
        <div class="table-cell tf-cell">
          <div class="tf-control"
            (click)="toggleTimeframePicker(item.id)"
            [ngClass]="{
              'is-active': timeframePickerOpenId === item.id,
              'picker-at-top': idx > 4,
              'disabled': item.isReadOnly || isReadOnly
            }"
          >
            <fa-duotone-icon [icon]="['fad', 'calendar-alt']"></fa-duotone-icon>
            <timeframe-picker
              [timeframes]="timeframes"
              [overdueTimeframes]="overdueTimeframeIds"
              [selectedTimeframe]="selectedMoveMap[currentViewMode]?.get(item.id)?.id"
              (onSelect)="handleSelectedTF($event, item.id)"
            ></timeframe-picker>
          </div>
          <div
            class="tf-warning"
            [matTooltip]="tfWarningTooltip"
            matTooltipPosition="above"
            matTooltipClass="dark-theme-tooltip above multi-line"
            (click)="openDetails(item.expenseId)"
          >
            <fa-duotone-icon [icon]="['fad', 'exclamation-triangle']"></fa-duotone-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
