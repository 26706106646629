<div
  *ngIf="tableRows"
  class="table-wrap"
  scrollOnDrag
  tableContentShadows
  [updateShadows$]="updateShadows$"
  (onShadowChanged)="handleShadowUpdate($event)"
>
  <table elementResizeObserver (onElementResize)="handleTableResize()">
    <thead>
    <tr>
      <th></th>
      <th *ngFor="let column of tableColumns">
        <div class="label">
          <span *ngFor="let labelPart of column.label">{{ labelPart }}</span>
        </div>
      </th>
    </tr>
    <tr class="shadow-tr">
      <td [colSpan]="tableColumns.length + 1"></td>
    </tr>
    </thead>
    <tbody>
      <ng-container  *ngFor="let row of tableRows; let rowIndex = index" >
    <tr [ngClass]="{ 'locked': row.locked }">
      <th>
        <span class="name-cell">
          {{ row.shortName }}
          <fa-icon [icon]="['fas', 'lock-keyhole']" *ngIf="row.locked" class="lock-icon"></fa-icon>
        </span>
      </th>
      <td *ngFor="let column of tableColumns; let columnIndex = index"
      (click)="highlightCell(rowIndex, columnIndex, row, column)"
            [ngClass]="{ 'selected': selectedCell && selectedCell.row === rowIndex && selectedCell.column === columnIndex, 'clickable' : column.id !== manageTableBudgetColumnName.Budget && row.values[column.id] && objectType !== 'Program' }">
        <div
          *ngIf="column.id === manageTableBudgetColumnName.Budget"
          [matTooltip]="segmentlessAllocationTooltip"
          [matTooltipDisabled]="!isSegmentlessCampaign"
          matTooltipClass="dark-theme-tooltip above border-rounded segment-less-allocation"
          matTooltipPosition="above">
          <budget-allocation-cell
            [ngClass]="{'display-inline-table': (allocationTotals[manageTableBudgetColumnName.Budget]<0)}"
            [allocated]="row.values[manageTableBudgetColumnName.Budget]"
            [spent]="null"
            [remaining]="row.values[manageTableBudgetColumnName.Available]"
            [disabled]="campaignIsClosed || row.locked"
            [editable]="!isReadOnlyMode && !isSegmentlessCampaign"
            [showDifference]="true"
            [allowGestures]="!campaignIsClosed && !row.locked"
            [hasRemainingBudget]="true"
            [droppable]="!restrictedFromDrop[row.id]"
            (onFocus)="handleOnFocus($event)"
            (onChange)="handleAllocationChange($event, row)"
            (onDoubleClick)="handleDoubleClick($event, row)"
            (onDrop)="handleOnDrop($event, row)"
            (onDragStart)="handleOnDragStart($event, row)"
          ></budget-allocation-cell>
        </div>

        <ng-container *ngIf="column.id !== manageTableBudgetColumnName.Budget  && objectType !== 'Program'">
          <div>{{row.values[column.id]  | absoluteNumber}}</div>
        </ng-container>

        <ng-container *ngIf="column.id !== manageTableBudgetColumnName.Budget && objectType === 'Program'">
          <ng-container *ngTemplateOutlet="clickableValue; context: {
          $implicit: row.values[column.id],
          timeframeId: row.timeframeId,
          columnId: column.id
         }"></ng-container>
        </ng-container>

      </td>
    </tr>
    <tr *ngIf="selectedCell && selectedCell.row === rowIndex" class="accordion-cell" [class.expanded]="selectedCell">
      <td  [colSpan]="tableColumns.length + 1" class="accordion-col">
        <div class="accordion-box">

          <div class="loading-area" *ngIf="isLoading">
            <loader [isVisible]="isLoading"></loader>
          </div>

          <ng-container *ngIf="!isLoading">
            <header>
              <span  *ngIf="selectedColumn == manageTableBudgetColumnName.Actual" class="text" (click)="selectedRowValue && onCellClick(selectedRowTimeframe, selectedColumn)">View all on the Expense page</span>
              <span *ngIf="selectedColumn !== manageTableBudgetColumnName.Actual" class="text" (click)="selectedRowValue && onCellClick(selectedRowTimeframe, selectedColumn)">View all {{accData.length}} on the Manage page</span>
              <span class="close-acc" (click)="clearSelection()">Close</span>
            </header>
            <aside [ngClass]="['accordion-content', selectedColumn]">
              <ul>
                <li *ngFor="let data of accData">
                  <span class="content-icon">
                    <fa-icon [icon]="iconByObjectType[data.type]"></fa-icon>
                  </span>
                  <span class="content-label">{{data.label}}</span>
                  <span class="content-value">{{data.value | number : '1.2-2'}}</span>
                </li>
              </ul>
            </aside>
          </ng-container>

        </div>
      </td>
    </tr>
  </ng-container>
    </tbody>
    <tfoot>
    <tr class="shadow-tr">
      <td [colSpan]="tableColumns.length + 1"></td>
    </tr>
    <tr *ngIf="allocationTotals">
      <th>FY</th>
      <td *ngFor="let column of tableColumns">
        <budget-allocation-cell
          [ngClass]="{'display-inline-table': (allocationTotals[manageTableBudgetColumnName.Budget]<0)}"
          *ngIf="column.id === manageTableBudgetColumnName.Budget"
          [allocated]="allocationTotals[manageTableBudgetColumnName.Budget]"
          [spent]="null"
          [remaining]="allocationTotals[manageTableBudgetColumnName.Available]"
          [disabled]="campaignIsClosed"
          [showDifference]="true"
          [hasRemainingBudget]="true"
          [droppable]="false"
          ></budget-allocation-cell>
        <ng-container *ngIf="column.id !== manageTableBudgetColumnName.Budget">
          <ng-container *ngTemplateOutlet="clickableValue; context: {
          $implicit: allocationTotals[column.id],
          timeframeId: null,
          columnId: column.id
         }"></ng-container>
        </ng-container>
      </td>
    </tr>
    </tfoot>
  </table>
</div>

<ng-template #clickableValue let-value let-timeframeId="timeframeId" let-columnId="columnId">
  <span class="default-value"
        [ngClass]="{ 'is-clickable': objectId && value }"
        (click)="value && onCellClick(timeframeId, columnId)">{{ value | absoluteNumber }}</span>
</ng-template>
