import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ChooseBudgetDialogContext {
  currentBudgetId: number;
  groups: MetricMappingGroup[];
}

export interface MetricMappingGroup {
  budgetId: number;
  budgetName: string;
  campaignIds: number[];
}

@Component({
  selector: 'choose-budget-dialog',
  templateUrl: './choose-budget-dialog.component.html',
  styleUrls: ['./choose-budget-dialog.component.scss']
})
export class ChooseBudgetDialogComponent {
  singleExternalBudget = false;
  selectedBudget: MetricMappingGroup;

  constructor(
    public dialogRef: MatDialogRef<ChooseBudgetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: ChooseBudgetDialogContext,
  ) {
    if (this.context.groups.length === 1) {
      this.selectedBudget = this.context.groups[0];
      this.singleExternalBudget = this.context.currentBudgetId !== this.selectedBudget.budgetId;
    } else {
      this.selectedBudget = this.context.groups.find(group => group.budgetId === this.context.currentBudgetId)
    }
  }

}
