<div class="table-container">
  <div *ngIf="data.currencies.length" class="exchanges-rates-table">
    <!-- currency names section -->
    <table class="currency-names">
      <tr class="header">
        <td colspan="2"></td>
        <td class="daily-rates">
          <div *ngIf="showLeftGradient" class="gradient"></div>
          Today's Rates
        </td>
      </tr>
      <tr *ngFor="let currency of data.currencies" class="currency-row" [ngClass]="{'base': currency.isBase, 'edit-mode': currency.isEditMode, 'new': currency.isNew}">
        <td>{{currency.name}}</td>
        <td>
            {{currency.code}}
            <div *ngIf="currency.isNew" class="new-currency-badge-wrap">
              <span  class="badge new-currency">
                <span class="text">New</span>
                <span class="badge-close-btn" (click)="removeCurrencyNewStatus(currency.id)"></span>
              </span>
              <div class="graph-tooltip">
                  Currency added using current Today’s Rate as default. Update Exchange Rates rates as needed or keep current rate. Click X to clear.
              </div>
            </div>
        </td>
        <td class="daily-rates">
          <div *ngIf="showLeftGradient" class="gradient"></div>
          {{currency.dailyRate && (currency.dailyRate | number:'1.2-10')}}
        </td>
      </tr>
      <tr *ngIf="isAddCurrencyRowShown" class="add-currency-row">
        <td colspan="3">
          <div *ngIf="showLeftGradient" class="gradient"></div>
          <autocomplete
            [useAutofocus]="true"
            inputPlaceholder="Type to search a currency"
            [suggestions]='currencySuggestions'
            [maxItems]="6"
            (onSelect)="handleNewCurrencySelect($event);"
            (keydown.esc)="handleAddCurrencyCancel();"
          >
          </autocomplete>
        </td>
      </tr>
    </table>

    <!-- currency rates section -->
    <div class="currency-rates-wrapper" #scrollContainer scrollDetector (scrolled)="handleScroll();" [scrollContainerElement]="scrollContainer">
      <table class="currency-rates">
        <tr class="header">
          <td
            *ngFor="let month of data.months; let i = index"
            class="month"
            [ngClass]="{'current': month.isCurrent, 'show-year-border': month.isFirstMonthOfYear && i}">
            <span>{{month.name}}</span>
            <span *ngIf="month.isFirstMonthOfYear" class="year">{{month.year}}</span>
          </td>
        </tr>
        <tr
          *ngFor="let currency of data.currencies"
          class="currency-row"
        [ngClass]="{'base': currency.isBase, 'edit-mode': currency.isEditMode, 'new': currency.isNew}">
          <td
            *ngFor="let rateData of currency.ratesPerMonth; let i = index"
            [ngClass]="{'current': rateData.isCurrent, 'show-year-border': rateData.isFirstMonthOfYear && i}">

            <!-- view mode -->
            <div
              class="currency-rate"
              [ngClass]="{'editable': rateData.isEditAllowed, 'edit-mode': rateData.isEditMode}"
              (click)="handleCellClick(rateData, currency);">
              <span *ngIf="!rateData.isEditMode">
                {{rateData.rate && (rateData.rate | number:'1.2-10')}}
              </span>
              <span *ngIf="rateData.isEditMode">
                {{rateData.editableValue}}
              </span>
            </div>

            <!-- edit mode -->
            <input
              *ngIf="rateData.isEditMode"
              class="currency-rate-input"
              type="text"
              [(ngModel)]="rateData.editableValue"
              autoFocus
              decimalInput
              (keydown.enter)="handleRatePerMonthChange();"
              (clickOutside)="handleRatePerMonthChange();"
              (keydown.enter)="handleRatePerMonthChange();"
              (keydown.esc)="cancelEditMode();"
              (keydown.arrowleft)="handleLeftArrowClick();"
              (keydown.arrowright)="handleRightArrowClick();">

          </td>
        </tr>
        <tr *ngIf="isAddCurrencyRowShown" class="add-currency-row">
          <td *ngFor="let rateData of data.currencies[0].ratesPerMonth" [ngClass]="{current: rateData.isCurrent}">
            <div class="currency-rate">
              {{1 | number:'1.2-10'}}
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- remove icons section -->
    <div class="remove-icons">
      <div>
        <div *ngIf="showRightGradient" class="gradient"></div>
      </div>
      <div *ngFor="let currency of data.currencies" class="icon currency-row" [ngClass]="{'edit-mode': currency.isEditMode, 'new': currency.isNew}">
        <div *ngIf="showRightGradient" class="gradient"></div>
        <icon-trash *ngIf="!currency.isBase" color="#C1C1C1" (click)="handleRemoveCurrency(currency)"></icon-trash>
      </div>
      <div *ngIf="isAddCurrencyRowShown" class="icon add-currency-row">
        <div *ngIf="showRightGradient" class="gradient"></div>
        <icon-trash color="#C1C1C1" (click)="handleAddCurrencyCancel();"></icon-trash>
      </div>
    </div>
  </div>

  <button *ngIf="!isAddCurrencyRowShown && !!data.currencies.length" class="add-currency-btn" (click)="handleAddCurrencyClick();">
    <icon-add-objects textTooltip="Add Currency"></icon-add-objects>
    <span>Add currency</span>
  </button>
</div>
