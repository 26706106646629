import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-death-star',
  templateUrl: './icon-death-star.component.html',
  styleUrls: ['./icon-death-star.component.scss']
})
export class IconDeathStarComponent {
  @Input() size = '40px';
}
