/**
 * Constant of last active plan page view mode stored in local storage
 */
export const CARD_TABLE_VIEW_MODE = 'view_mode';
export const MANAGE_PAGE_VIEW_STATE = 'view_state';
export const MANAGE_PAGE_VIEW_MODE = 'mp_view_mode';
export const EXPENSE_PAGE_TABLE_COLUMNS = 'ep_table_columns';
export const EXPENSE_PAGE_SORT_BY = 'ep_sort_by';
export const INVOICE_PAGE_SORT_BY = 'ip_sort_by';
export const EXPENSE_PAGE_SIDEBAR_VIEW_MODE = 'ep_sidebar_view_mode';
export const CEG_TABLE_CONFIG = 'ceg_table_config';
export const CEG_TIMEFRAME_CONFIG = 'ceg_timeframe_config';
