import { Component, Input } from '@angular/core';
import { SpendingTimelineDataItem } from '../spending-timeline/spending-timeline.types';
import { decreaseMaxValue } from '../widget.utils';

@Component({
  selector: 'spending-bar',
  templateUrl: './spending-bar.component.html',
  styleUrls: ['./spending-bar.component.scss'],
})
export class SpendingBarComponent {
  // Expressed as a percent from max height (max height corresponds to the largest total spend)
  barPartsData: SpendingTimelineDataItem;
  minHeightForLabels = 6;
  overspendPercentHeight = 0;
  barPartsHeight = {
    closed: 0,
    committed: 0,
    planned: 0,
    remaining: 0,
    budget: 0
  };

  // minimal height ~1px
  // we can't set more, as it cause issue for bars with very small height (< 2px)
  minSegmentHeightPercent = .25;
  @Input() cegFlagEnabled = false;
  @Input() positionInChart;
  @Input() totalColumns;
  @Input() summaryTooltipData;

  private static heightPercent(value, total) {
    return +((value || 0) * 100 / total).toFixed(2);
  }

  @Input() set spendingDataItem(item: SpendingTimelineDataItem) {
    this.barPartsData = item;
    this.calcBarPartsHeight(item);
    this.adjustHeight();
  }

  adjustHeight() {
    Object.keys(this.barPartsHeight).forEach(k => {
      const val = this.barPartsHeight[k];
      if (val > 0 && val < this.minSegmentHeightPercent) {
        const diff = this.minSegmentHeightPercent - val;
        decreaseMaxValue(this.barPartsHeight, diff, this.minSegmentHeightPercent)
        this.barPartsHeight[k] = this.minSegmentHeightPercent;
      }
    })
  }

  calcBarPartsHeight(item: SpendingTimelineDataItem): void {
    const maxTotal = item.maxAmount;
    this.overspendPercentHeight = SpendingBarComponent.heightPercent(item.overSpend, maxTotal);
    this.barPartsHeight.budget = SpendingBarComponent.heightPercent(item.budget, maxTotal);
    item.spending.forEach(s => {
      this.barPartsHeight[s.id] = SpendingBarComponent.heightPercent(s.val, maxTotal);
    })
  }

}
