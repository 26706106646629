<div class="cue-wrap" *ngIf="isVisible" [@inOutAnimation]>
  <div class="cue-content">
    <icon-plannuh class="bg-image" [size]="'270px'"></icon-plannuh>
    <h1 class="title">Start Drafting Your Plan Budget</h1>
    <p class="text">
      When you’re ready, you can fill in per-timeframe amounts of budget per segment.
    </p>
    <p class="text">
      The <b>“Projected Budget”</b> feature lets you capture how much you want to allocate for each segment,
      independent of the numbers that you go ahead and enter (or update) per timeframe.
      It can come in handy later in the fiscal year to compare how you originally planned to
      distribute funds vs current budgeting.
      You can leave Projected Budget blank and fill it in later.
    </p>

    <div class="actions">
      <button class="mat-pl-white-button" mat-flat-button (click)="hideCue()">Got it !</button>
    </div>

  </div>

  <svg class="arrow arrow-left" xmlns="http://www.w3.org/2000/svg" width="152" height="243" viewBox="0 0 152 243" fill="none">
    <g filter="url(#filter0_dl)">
      <path d="M137 158.078C127.926 161.182 119.041 160.788 112.602 157.47C70.2814 135.661 54.1363 50.3141 54.1363 50.3141C54.1363 50.3141 70.7772 68.0539 77.2499 58.6161C83.7227 49.1782 49.8647 3.93921e-07 36.4211 0C22.9774 -3.93921e-07 -5.90127 54.6416 1.06942 62.59C8.0401 70.5384 26.4629 50.3141 26.4629 50.3141C26.4629 50.3141 35.1247 194.227 90.1951 219.067C102.565 224.646 119.115 228.277 137 227.983V158.078Z" fill="url(#linear_left)"/>
    </g>
    <defs>
      <filter id="filter0_dl" x="0" y="0" width="152" height="243" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="10" dy="10"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0235294 0 0 0 0 0.121569 0 0 0 0 0.219608 0 0 0 0.2 0"/>
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <linearGradient id="linear_left" x1="0" y1="0" x2="137" y2="0" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E82987"/>
        <stop offset="1" stop-color="#BC2D89"/>
      </linearGradient>
    </defs>
  </svg>
  <svg class="arrow arrow-right" xmlns="http://www.w3.org/2000/svg" width="161" height="240" viewBox="0 0 161 240" fill="none">
    <g filter="url(#filter0_dr)">
      <path d="M0 158.863V224.75C11.7593 224.722 22.6487 223.055 31.7107 220.251C92.0503 201.581 119.473 54.0295 119.473 54.0295C119.473 54.0295 136.004 77.2568 144.257 69.9312C152.51 62.6055 129.535 2.50137 115.582 0.795738C101.629 -0.909898 60.1885 45.5914 65.6978 56.1611C71.2071 66.7309 90.751 50.5185 90.751 50.5185C90.751 50.5185 63.0623 136.626 16.3446 153.783C12.5362 155.182 6.75094 157.127 0 158.863Z" fill="url(#linear_right)"/>
    </g>
    <defs>
      <filter id="filter0_dr" x="0" y="0.75" width="161" height="239" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="10" dy="10"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0235294 0 0 0 0 0.121569 0 0 0 0 0.219608 0 0 0 0.2 0"/>
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      <linearGradient id="linear_right" x1="0" y1="0.75" x2="146" y2="0.75" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1C6DB5"/>
        <stop offset="1" stop-color="#008BCB"/>
      </linearGradient>
    </defs>
  </svg>

</div>
