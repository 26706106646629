import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { startWith, map, filter } from 'rxjs/operators';
import { TagMapping } from 'app/shared/types/tag-mapping.interface';
import { AutocompleteItem } from '../../types/autocomplete-item.type';
import { MatFormFieldAppearance } from '@angular/material/form-field';

export interface TagControlEvent { id: number, title: string, inherit: boolean }
export const MAX_TAG_NAME_LENGTH = 45;

@Component({
  selector: 'tags-control',
  templateUrl: './tags-control.component.html',
  styleUrls: ['./tags-control.component.scss']
})
export class TagsControlComponent implements OnChanges {
  @Input() tagsList: TagMapping[];
  @Input() autocompleteItems: AutocompleteItem[];
  @Input() editOnly: boolean;
  @Input() removeOnly: boolean;
  @Input() editForbidden = false;
  @Input() fieldAppearance: MatFormFieldAppearance;

  @Output() onRemoveTag = new EventEmitter<TagControlEvent>();
  @Output() onAddTag = new EventEmitter<TagControlEvent>();
  @Output() onCreateTag = new EventEmitter<TagControlEvent>();

  inheritTags = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new UntypedFormControl();
  filteredTags: Observable<AutocompleteItem[]>;
  tagNameLength = MAX_TAG_NAME_LENGTH;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(''),
      filter(tag => tag != null),
      map((text: string) => this._filter(text)));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editForbidden || changes.removeOnly) {
      if (this.editForbidden || this.removeOnly) {
        this.tagCtrl.disable();
      } else {
        this.tagCtrl.enable();
      }
    }
  }

  createNewTag(title: string) {
    const existingTag = this.autocompleteItems.find(attachedTag => attachedTag.name === title);
    const alreadyAttached = this.tagsList.some(attachedTag => attachedTag.name === title);
    if (alreadyAttached) {
      return;
    }

    if (!existingTag) {
      this.onCreateTag.emit({ id: null, title, inherit: this.inheritTags });
    } else {
      this.addTagFromList(existingTag);
    }
  }

  addTagFromList(tag: AutocompleteItem) {
    this.onAddTag.emit({ id: tag.id, title: tag.name, inherit: this.inheritTags })
  }

  onRemoveTagClick(tm: TagMapping) {
    if (!this.editForbidden) {
      this.onRemoveTag.emit({
        id: tm.tagId,
        inherit: this.inheritTags,
        title: tm.name
      });
    }
  }

  onTagNameEntered(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();
    if (!value.length) {
      return;
    }
    this.createNewTag(value);
    if (input) {
      input.value = '';
    }
    this.tagCtrl.setValue('');
  }

  onSelectTagFromList(event: MatAutocompleteSelectedEvent): void {
    this.addTagFromList(event.option.value);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue('');
  }

  displayTag(tag?: AutocompleteItem | string): string | undefined {
    return typeof tag === 'string' ? tag : tag?.name;
  }

  private _filter(tag?: AutocompleteItem | string): AutocompleteItem[] {
    const filterValue = this.displayTag(tag).toLowerCase();

    return filterValue
      ? this.autocompleteItems.filter(option => {
          const attached = this.tagsList.some(attachedTag => attachedTag.tagId === option.id);
          let matched = true;
          if (filterValue) {
            matched = option.name?.toLowerCase().indexOf(filterValue) === 0;
          }
          return !attached && matched
        })
      : [];
  }
}
