import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { Configuration, ObjectsIconConfig } from 'app/app.constants';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { BehaviorSubject } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';

export interface ListTreeItem {
  id: number;
  name: string;
  type: string;
  highlighted: boolean;
}

@Component({
  selector: 'full-hierarchy-tree',
  templateUrl: './full-hierarchy-tree.component.html',
  styleUrls: ['./full-hierarchy-tree.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-10px)' }),
            animate('200ms ease-out',
              style({ opacity: 1, transform: 'translateY(0)' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, transform: 'scale(1)', transformOrigin: '20% 100%' }),
            animate('100ms ease-in',
              style({ opacity: 0, transform: 'scale(.5)' }))
          ]
        )
      ]
    )
  ]
})
export class FullHierarchyTreeComponent {
  @Input() items: ListTreeItem[] = [];
  @Input() hoverState$: BehaviorSubject<boolean>;
  @Output() closeTooltip = new EventEmitter<void>();
  public readonly iconsConfig: ObjectsIconConfig = this.configuration.iconsConfig;

  @HostListener('mouseenter')
  hover() {
    this.hoverState$.next(true);
  }
  @HostListener('mouseleave')
  mouseLeave() {
    this.hoverState$.next(false);
  }
  @HostBinding('@inOutAnimation') get slideIn() {
    return true;
  }

  constructor(
    private readonly configuration: Configuration,
    public readonly routingService: AppRoutingService,
  ) { }

  handleNameClick(item: ListTreeItem): void {
    this.closeTooltip.emit();
    this.routingService.routeActionByObjectType[item.type]?.(item.id);
  }
}
