import { BudgetServiceActionType, CompanyServiceActionType } from '@shared/enums/interceptor-action-type.enum';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SpendingModeService } from '@spending/services/spending-mode.service';
import { SpendingManagementMode } from '@spending/types/expense-page.type';
import { MenuItem, SpendingManageMenuService } from '@spending/services/spending-manage-menu.service';
import { MenuPanelItem } from '../../../../header-navigation/components/menu-panel/menu-panel.type';
import { SpendingChildComponent } from '@spending/components/spending/spending-child.component';
import { SpendingService } from '@spending/services/spending.service';
import { ExpensePageSelectionService } from '@spending/services/expense-page-selection.service';
import { SpendingMiniDashSummaryService } from '@spending/services/spending-mini-dash-summary.service';
import { SpendingLocalFiltersService } from '@spending/services/spending-local-filters.service';
import { InvoiceRowData, InvoiceTableDataService } from '@spending/services/invoice-table-data.service';
import { InvoicePageService } from '@spending/services/invoice-page-service';
import { InvoicePageSortByService } from '@spending/services/invoice-page-sort-by.service';
import { InvoiceUploadManagerService } from '@spending/services/invoice-upload-manager.service';
import { InvoiceLiveTracking } from '@shared/services/invoice-live-tracking';
import { ActionInterceptorDestructor, ActionInterceptorsService } from '@shared/services/action-interceptors.service';

@Component({
  selector: 'invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [
    InvoicePageService,
  ]
})
export class InvoicesComponent extends SpendingChildComponent implements OnDestroy, OnInit {
  private spendingManagementMode = SpendingManagementMode.Invoices;
  private manageMenuActionsMap: Partial<Record<MenuItem, MenuPanelItem>> = {
    [MenuItem.ACTIONS]: {
      label: 'Actions',
      faIcon: ['fas', 'pencil-ruler'],
      panelClass: 'expenses-manage-menu-panel',
      hasCustomTemplate: true,
      disabled: true,
    },
    [MenuItem.SORT]: {
      label: 'Sort by',
      faIcon: ['fas', 'sort'],
      panelClass: 'expenses-manage-menu-panel sorting-panel has-sticky',
      children: this.invoicePageSortByService.prepareSortByMenuItems(),
    },
    [MenuItem.EXPORT]: {
      label: 'Export data',
      faIcon: ['fas', 'cloud-download'],
      action: () => this.invoicePageService.exportExpensesToCSV(true),
    },
  };
  private interceptorDestructors: ActionInterceptorDestructor[] = [];

  protected budgiEmail = ''; // Fetch a real email once provided

  constructor(
    public readonly spendingService: SpendingService,
    private readonly invoiceLiveTracking: InvoiceLiveTracking,
    private readonly spendingModeService: SpendingModeService,
    private readonly expensePageSelectionService: ExpensePageSelectionService,
    private readonly spendingManageMenuService: SpendingManageMenuService,
    private readonly spendingMiniDashSummaryService: SpendingMiniDashSummaryService,
    private readonly spendingLocalFiltersService: SpendingLocalFiltersService,
    private readonly invoiceTableDataService: InvoiceTableDataService,
    private readonly invoicePageService: InvoicePageService,
    private readonly invoicePageSortByService: InvoicePageSortByService,
    private readonly actionInterceptorsService: ActionInterceptorsService,
    protected readonly invoiceUploadManager: InvoiceUploadManagerService
  ) {
    super(
      spendingService,
      expensePageSelectionService,
      spendingLocalFiltersService,
      spendingMiniDashSummaryService,
      invoicePageService
    );
    this.spendingModeService.spendingManagementMode = this.spendingManagementMode;
    this.spendingManageMenuService.setMenuMap = this.manageMenuActionsMap;
    this.spendingLocalFiltersService.sortBy = this.invoicePageSortByService.sortByFieldValue;
  }

  ngOnInit(): void {
    this.interceptorDestructors.push(
      this.actionInterceptorsService.registerInterceptor({
        actionType: BudgetServiceActionType.OnChangingSelectedBudget,
        handler: () => this.canDeactivate()
      })
    );

    this.interceptorDestructors.push(
      this.actionInterceptorsService.registerInterceptor({
        actionType: CompanyServiceActionType.OnChangingSelectedCompany,
        handler: () => this.canDeactivate()
      })
    );
  }

  protected get tableRows(): InvoiceRowData[] {
    return this.invoiceTableDataService.rows;
  }

  protected changeMode(): void {
    this.spendingModeService.openSpendingManagementMode(SpendingManagementMode.Expenses);
  }

  protected openReviewDialog(id: number): void {
    this.checkIsCongratsEnabled();
    this.spendingService.openInvoiceReview(id);
  }

  canDeactivate(): Observable<boolean> {
    return this.invoiceUploadManager.canLeavePage();
  }

  private checkIsCongratsEnabled(): void {
    const currentCount = this.invoiceLiveTracking.invoiceCount;
    const isLast = !(currentCount - 1);
    this.invoiceLiveTracking.setIsLastInvoice(isLast);
  }

  ngOnDestroy() {
    this.invoiceLiveTracking.setIsLastInvoice(false);
    this.invoiceTableDataService.createRows(null);
    this.destroy$.next();
    this.destroy$.complete();
    this.interceptorDestructors.forEach(interceptorDestructor => interceptorDestructor?.());
  }
}
