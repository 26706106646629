import { Component, OnInit } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { UserManager } from 'app/user/services/user-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  private readonly devModeHostName = 'localhost';
  protocol = 'https://';
  readonly envDomain: string;
  domain: any;
  localDev: boolean;

  constructor(
    private readonly config: Configuration,
    private readonly userManager: UserManager,
    private readonly router: Router
  ) {
    this.envDomain = this.config.envDomain;
  }

  ngOnInit(): void {
    this.protocol = window.location.protocol + '//';
    this.localDev = window.location.hostname === this.devModeHostName;
    this.domain = this.localDev ? 'test' : this.userManager.getCurrentDomainPrefix();

    const navToLogin = localStorage.getItem(this.config.LS_KEY_NAVIGATE_TO_LOGIN);
    if (navToLogin) {
      localStorage.removeItem(this.config.LS_KEY_NAVIGATE_TO_LOGIN);
      this.router.navigate([this.config.ROUTING_CONSTANTS.LOGIN]);
    }
  }

  navigateToLogin() {
    const cleanedHost = !this.domain || this.localDev ?
      window.location.host :
      window.location.hostname.replace(this.domain + '.', '');

    window.location.href = this.protocol +
      (this.config.region && !this.localDev ? this.config.region + '.' : '') +    // region prefix should be added for PROD only
      cleanedHost + '/' + this.config.ROUTING_CONSTANTS.LOGIN;
  }
}
