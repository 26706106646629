export interface SharedCostRule {
  id: number;
  name: string;
  budgetId: number;
  isActive: boolean;
  instancesNumber: number;
  segments: SharedCostRuleSegment[]
}

export interface SharedCostRuleSegment {
  id?: number;
  cost?: number;
}

export interface SharedCostRuleChangeSegmentEvent {
  segment: SharedCostRuleSegment;
  value: number;
}

export enum SharedCostRuleStatus {
  Active = 'Activated',
  Inactive = 'Inactive'
}

export interface SharedCostRuleSummary {
  isTotalCorrect: boolean,
  total: number,
  totalText: string,
  overflow: number,
  overflowText: string,
  activeSegments: number
}

export interface SharedCostRulePermissions {
  editSegments: boolean,
  addSegments: boolean,
  deleteSegments: boolean,
  deleteRule: boolean,
  updateStatus: boolean,
  editRule: boolean
}

export interface SharedCostRuleActiveField {
  type?: string,
  segment?: SharedCostRuleSegment
}

export enum SharedCostRuleFieldType {
  Cost = 'Cost',
  Budget = 'Budget',
  Status = 'Status',
  SegmentId = 'SegmentId',
  Name = 'Name',
  AddSegment = 'AddSegment'
}
