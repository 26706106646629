import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[getInitials]'
})
export class GetInitials {
    @Input('firstName') firstName;
    @Input('lastName') lastName;
    @Input('fullName') fullName;

    constructor( private el: ElementRef) {}

    private prepareNameValue(value: string): string {
      return value
        .trim()
        .replace(/\s\s+/g, ' ');
    }

    private getPreparedNames() {
      const fullNameDelimiter = ' ';
      let firstName = this.firstName;
      let lastName = this.lastName;

      if (this.fullName) {
        const fullNameChunks = this.fullName.split(fullNameDelimiter);
        firstName = fullNameChunks.shift();
        lastName = fullNameChunks.join(fullNameDelimiter);
      }

      return {
        firstName: this.prepareNameValue(firstName || ''),
        lastName: this.prepareNameValue(lastName || ''),
      };
    }


    ngOnChanges() {
      const { firstName, lastName } = this.getPreparedNames();
      const initials = firstName.charAt(0) + lastName.charAt(0);

      setTimeout(() => {
        this.el.nativeElement.innerHTML = initials.toUpperCase();
      }, 200);
    }
}
