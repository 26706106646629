import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { roundDecimal } from 'app/shared/utils/common.utils';
import { BudgetAllocationActionsService } from '../../services/budget-allocation-actions.service';
import { BudgetAllocationCellGesturesEvent } from './budget-allocation-cell.types';
import { BudgetAllocationActionTooltipContext } from '../budget-allocation-action-tooltip/budget-allocation-action-tooltip.component';


@Component({
  selector: 'budget-allocation-cell',
  templateUrl: './budget-allocation-cell.component.html',
  styleUrls: ['./budget-allocation-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetAllocationCellComponent implements OnInit, OnChanges, OnDestroy {
  @Input() globalDragStarted = false;
  @Input() allocated: number;
  @Input() spent: number;
  @Input() remaining: number = null;
  @Input() allowGestures = false;
  @Input() showDifference = false;
  @Input() editable = false;
  @Input() disabled = false;
  @Input() maxLength = 14;
  @Input() allowDnd = true;
  @Input() droppable = true;
  @Input() hasRemainingBudget = false;
  @Input() allowNegative = false;
  @Input() inputAnimationDisabled = false;
  @Input() actionTooltipContext = BudgetAllocationActionTooltipContext.Default;
  @Input() id: string;
  @Output() onChange = new EventEmitter<number>();
  @Output() onDoubleClick = new EventEmitter<BudgetAllocationCellGesturesEvent>();
  @Output() onDrop = new EventEmitter<BudgetAllocationCellGesturesEvent>();
  @Output() onDragStart = new EventEmitter<BudgetAllocationCellGesturesEvent>();
  @Output() onDragEnd = new EventEmitter();
  @Output() onFocus = new EventEmitter<boolean>();

  private readonly destroy$ = new Subject<void>();

  public currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', allowNegative: false, prefix: '' };
  public billionCapLimit = Math.pow(10, 9);
  public decimalPipeFormat = '1.2-2';
  public allocatedValue = 0;
  public differenceValue = 0;
  public disabledDiffTooltip = '';
  public isSurplus = false;
  public isOverspend = false;
  public isFocused = false;
  public isDragged = false;
  public isDragOver = false;

  constructor(
    private readonly zone: NgZone,
    private readonly gesturesManager: BudgetAllocationActionsService<any>,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.gesturesManager.onDrop$
      .pipe(
        filter(event => event != null),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.resetGesturesFlags();
        }
      )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.spent || changes.allocated || changes.remaining) {
      this.prepareData();
    }

    if (changes.allowNegative) {
      this.currencyMaskOptions.allowNegative = this.allowNegative;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getValue(value: number): number {
    return value || 0;
  }

  private updateValues() {
    const allocated = this.getValue(this.allocated);
    const spent = this.getValue(this.spent);
    const difference = this.remaining != null
      ? this.getValue(this.remaining)
      : allocated - spent;

    this.allocatedValue = allocated;
    this.differenceValue = roundDecimal(difference, 2);
  }

  private prepareData() {
    this.updateValues();
    this.isSurplus = false;
    this.isOverspend = false;
    if (this.differenceValue > 0) {
      this.isSurplus = true;
      this.disabledDiffTooltip = 'Under Budget';
    }
    if (this.differenceValue < 0) {
      this.isOverspend = true;
      this.disabledDiffTooltip = 'Over Budget';
    }
  }

  private resetGesturesFlags() {
    this.isDragged = false;
    this.isDragOver = false;
    this.isFocused = false;
    this.cdRef.detectChanges();
  }

  public getGesturesEvent(): BudgetAllocationCellGesturesEvent {
    return {
      difference: Math.abs(this.differenceValue),
      surplus: this.isSurplus,
      overspend: this.isOverspend
    };
  }

  public get isDoubleClickAllowed(): boolean {
    return !this.disabled && this.editable && (this.isSurplus || this.hasRemainingBudget);
  }

  public get isDragAllowed(): boolean {
    return this.allowGestures && this.allowDnd && !this.disabled && this.showDifference && this.isSurplus && this.editable;
  }

  public get isDropAllowed(): boolean {
    return this.droppable && this.allowGestures && this.allowDnd && !this.disabled &&
      this.showDifference && !this.isDragged && this.editable;
  }

  public setFocus(focus: boolean) {
    this.isFocused = focus;
    this.onFocus.next(this.isFocused);
  }

  public handleBlur() {
    this.setFocus(false);
    this.onChange.emit(this.allocatedValue);
  }

  public handleDoubleClick() {
    if (!this.isDoubleClickAllowed) {
      return;
    }
    this.onDoubleClick.emit(this.getGesturesEvent())
  }

  public handleDragStart() {
    this.zone.run(() => {
      this.isDragged = true;
      this.onDragStart.emit(this.getGesturesEvent());
    });
  }

  public handleDragEnd() {
    this.zone.run(() => {
      this.isDragged = false;
      this.onDragEnd.emit();
    });
  }

  public handleDropOn() {
    this.zone.run(() => {
      this.isDragOver = false;
      this.onDrop.emit(this.getGesturesEvent());
    });
  }

  public handleDragEnter() {
    if (this.isDragged) {
      return;
    }

    this.zone.run(() => {
      this.isDragOver = true;
    });
  }

  public handleDragLeave() {
    if (this.isDragged) {
      return;
    }
    this.zone.run(() => {
      this.isDragOver = false;
    });
  }
}

