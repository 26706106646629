import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions, withAPIV0ErrorHandling } from 'app/shared/utils/http-request.utils';
import { API_V0_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { Observable } from 'rxjs';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';

export interface SetupCompanyPayload {
  planning_for: string;
  company_size: string;
  company_industry: string;
  company_revenue: string;
}

@Injectable()
export class PlanSettingService {
  private readonly actionUrl = inject(API_V0_URL);
  private readonly http = inject(HttpClient);

  getEmployeesStrength(): Observable<ApiV0Response<any[]>> {
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<any[]>>(this.actionUrl + 'company/size/')
    );
  }

  getRevenues(companyId: number): Observable<ApiV0Response<any[]>> {
    const data = {'fields': 'revenues'};
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<any[]>>(this.actionUrl + 'companies/' + companyId + '/', getRequestOptions(data))
    );
  }

  getIndustries(companyId: number): Observable<ApiV0Response<any[]>> {
    const data = {'fields': 'industries'};
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<any[]>>(this.actionUrl + 'companies/' + companyId + '/',  getRequestOptions(data))
    );
  }

  setupCompany(data: SetupCompanyPayload): Observable<ApiV0Response<any>> {
    return withAPIV0ErrorHandling(
      this.http.put<any>(this.actionUrl + 'onboard/company/setup/',  JSON.stringify(data))
    );
  }
}
