import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { UtilityService } from './shared/services/utility.service';
import { CurrencyService } from './shared/services/backend/currency.service';
import { Configuration } from './app.constants';
import { Currency } from './shared/types/currency.interface';
import { GoogleTagManagerService } from './shared/services/google-tag-manager.service';
import { UserManager } from './user/services/user-manager.service';
import { forkJoin, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FontAwesomeService } from '@common-lib/lib/utils/font-awesome.service';
import { CUSTOM_DATE_FORMATS } from 'app/shared/constants/date-picker-format';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { InactivityManagerService } from './shared/services/inactivity-manager.service';
import { routeAnimation } from './shared/utils/route-animation.utils';
import { AppInit } from './app-init.service';
import { Location } from '@angular/common';
import { CompanySettingsStorageService } from './company-settings-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ routeAnimation ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  private static readonly currencyStylesElementId = 'currencyStyles';

  private readonly destroy$ = new Subject<void>();
  isLoggedIn = false;
  isSuperAdmin = false;
  showAlert = false;
  currentUserId: number;
  activeRoute = '';
  routeUrl: string;

  static appendDymamicCurrencySymbolsCss(currencyList: Currency[]) {
    if (!currencyList || !currencyList.length) {
      return;
    }

    const currencyStylesText =
      currencyList
        .filter(curr => curr.symbol)
        .map(curr => `.currency-${curr.code.toLowerCase()}:after { content: '${curr.symbol}'; }`)
        .join('\n');

    let stylesEl = <HTMLStyleElement>window.document.getElementById(AppComponent.currencyStylesElementId);
    if (!stylesEl) {
      stylesEl = <HTMLStyleElement>document.createElement('style');
      stylesEl.id = AppComponent.currencyStylesElementId;
      stylesEl.type = 'text/libs';
      document.getElementsByTagName('head')[0].appendChild(stylesEl);
    }
    stylesEl.innerHTML = currencyStylesText;
  }

  public constructor(
    public configuration: Configuration,
    private _utilityService: UtilityService,
    private router: Router,
    private currencyService: CurrencyService,
    private gtmService: GoogleTagManagerService,
    private userManager: UserManager,
    public iconLib: FaIconLibrary,
    public fontAwesomeService: FontAwesomeService,
    private readonly inactivityManager: InactivityManagerService,
    private readonly appInit: AppInit,
    private location: Location
  ) {
    this.fontAwesomeService.initIcons(iconLib);
    this.routeUrl = location.path();
  }

  ngOnInit() {
    this.appInit.activate();

    this.userManager.currentUser$.pipe(
      filter(user => user?.id != null && user?.id !== this.currentUserId),
      takeUntil(this.destroy$)
    ).subscribe(
      user => {
        this.gtmService.sendUserId(user.id);
        this.currentUserId = user.id;
        this.inactivityManager.startTracking(() => this.userManager.logout());
        this.isSuperAdmin = user.is_superadmin;
      }
    );

    if (localStorage.getItem('show_pro_plan_alert')) {
      this.showAlert = true;
    }

    this.currencyService.currencyList$.subscribe(currencyList => AppComponent.appendDymamicCurrencySymbolsCss(currencyList));

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.gtmService.sendPageView(this.router.url);
        this.activeRoute = this.router.url;
      });

    this._utilityService.proPlanAlertPropertyChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.showAlert = value);

    this.userManager.afterLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.inactivityManager.startTracking(() => this.userManager.logout());
        this.currencyService.fetchCurrencyList();
        this.isLoggedIn = true;
      });

    this.userManager.afterLoggedOut$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.isLoggedIn = false);

    // If on initial app loading we are already logged in (won't be redirected to sign in page) - fire the event
    //    to continue app loading.
    this.userManager.isLoggedIn()
      .pipe(
        tap(isLoggedIn => !isLoggedIn && this.currencyService.fetchCurrencyList()),
        filter(isLoggedIn => isLoggedIn),
        switchMap(() => forkJoin([this.userManager.isSSOUser$, this.userManager.currentUserId$])),
        filter(([isSSO, userId]) => !isSSO || userId != null),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.userManager.setLoggedIn());

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.inactivityManager.stopTracking();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.animation;
  }
}
