<!-- New navigation stub -->
<a *ngIf="!routeUrl.includes(configuration.ROUTING_CONSTANTS.SSO)">
  <header-navigation *ngIf="isLoggedIn && !isSuperAdmin"></header-navigation>
</a>
<!--  End navigation stub -->
<router-outlet></router-outlet>
<div [@routeAnimations]="prepareRoute(drawerOutlet)">
  <router-outlet #drawerOutlet="outlet" name="drawer"></router-outlet>
</div>
<router-outlet name="drawerStack"></router-outlet>
<div [@routeAnimations]="prepareRoute(detailsOutlet)">
  <router-outlet #detailsOutlet="outlet" name="details"></router-outlet>
</div>
<root-loader></root-loader>

