<div class="autocomplete-component">
  <div class="text-input-field">
    <span><icon-search></icon-search></span>
    <input #inputEl [attr.autoFocus]="useAutofocus ? '' : null" type="text" [placeholder]="inputPlaceholder" [(ngModel)]='filterInput' (focus)="toggleSuggestionsList(true)" (blur)="toggleSuggestionsList(false)" (input)="updateFilteredSuggestions()"/>
    <p class="outline-wrap"></p>
  </div>
  <div class="suggestions-wrapper">
    <ul *ngIf="suggestionsIsOpened && filterInput" class="suggestions">
      <li *ngIf="filteredSuggestions.length === 0" class="idle-item" (mousedown)="$event.preventDefault();">No match</li>
      <li *ngFor="let item of filteredSuggestions; let i = index" [class.active]="i === keyboardNavIndex" (mousedown)="onSuggesctionClick($event, item)">
        <span class="title">{{item.title}}</span>
        <span class="subtitle">{{item.subtitle}}</span>
      </li>
    </ul>
  </div>
</div>
