<grid-with-search
  [gridData]="expenseTypeList"
  [gridColumns]="gridColumns"
  [SearchPlaceHolder]="'Search Expense Types'"
  (deleteGridItem)="deleteExpenseType($event)"
  (addNewGridItem)="addExpenseType($event)"
  (editGridItem)="updateExpenseType($event)"
  [enableSwitchTooltip]="enableSwitchTooltip"
  (updateStatusChange)="updateStatusChange($event)"
  [customActions]="true"
  [customActionLabel]="'is_custom'"
  [NoDataText]="NoDataText"
  [actionOnCount]="true"
  (actionOnCountEvent)="actionOnCountEvent($event)"
></grid-with-search>