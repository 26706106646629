<button
  mat-button
  class="header-action-item ut-create"
  [matMenuTriggerFor]="panel.menu"
  [disabled]="disabled"
>
  <span>Create</span>
  <fa-icon class="chevron" [icon]="['far', 'chevron-down']"></fa-icon>
</button>
<menu-panel #panel [items]="items"></menu-panel>
