import { inject, Injectable } from '@angular/core';
import { HttpEventType, HttpRequest, HttpResponse, HttpXhrBackend } from '@angular/common/http';
import { catchError, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService<T> {
  private readonly httpBackend = inject(HttpXhrBackend);
  protected appConfig: T;

  public loadAppConfig(configFilePath: string) {
    if (!configFilePath) {
      throw new Error('Failed to init the application: app config file path is not provided');
    }

    const req = new HttpRequest<null>('GET', configFilePath);

    return this.httpBackend.handle(req)
      .pipe(
        filter(res => res.type === HttpEventType.Response),
        map((res: HttpResponse<T>) => res.body),
        tap(configData => this.appConfig = configData),
        catchError(error => {
          console.log('Failed to load the app config from the file: ' + configFilePath);
          throw error;
        })
      );
  }

  public get config(): T {
    return this.appConfig;
  }
}
