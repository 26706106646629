<div class="autocomplete-control" [formGroup]="formData">
  <mat-form-field appearance="outline" class="p-form-field-outline no-validate">
    <input
      matInput
      matAutocompletePosition="below"
      formControlName="input"
      [maxLength]="maxItemLength"
      [matAutocomplete]="autocompleteInput"
      (blur)="handleChange()"
    />
  </mat-form-field>
  <mat-autocomplete
    #autocompleteInput="matAutocomplete"
    autoActiveFirstOption
    [panelWidth]="'auto'"
    [displayWith]="getName"
  >
    <mat-option *ngFor="let item of autocompleteItems | async"
                [value]="item"
                [disabled]="item.isNew && item.name.length > maxItemLength">
      <ng-container *ngIf="item.isNew">Add "{{ item.name }}"<span class="max-length-counter">{{ item.name.length + '/' + maxItemLength }}</span></ng-container>
      <ng-container *ngIf="!item.isNew">{{ item.name }}</ng-container>
    </mat-option>
  </mat-autocomplete>
</div>
