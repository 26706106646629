import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TableViewRow } from 'app/shared/components/table-view/table-data.type';
import { TableDataBuilderService } from 'app/shared/components/table-view/table-data-builder.service';
import { Configuration } from 'app/app.constants';

interface SpendByTypeData {
  id: number;
  name: string;
  data: {
    [key: string]: number;
  }
}

@Component({
  selector: 'spend-by-type-table',
  templateUrl: './spend-by-type-table.component.html',
  styleUrls: ['./spend-by-type-table.component.scss']
})

/**
* @class - SpendByTypeTableComponent
*/
export class SpendByTypeTableComponent implements OnChanges {
  private readonly _configuration = inject(Configuration);
  private readonly tableDataBuilder =  inject(TableDataBuilderService);

  @Input() spendByTypeData: SpendByTypeData[];
  @Input() selectedStatusNames: string[] = [];

  columnTitles: string[];
  columnTitleByFieldName: {[key: string]: string};
  columnsFields: string[] = [];
  rows: TableViewRow[] = [];

  @Input() set isCEGBudget(value: boolean) {
    const { statusNames, statusFields } = this._configuration;
    this.columnTitleByFieldName = {
      ...(value && { [statusFields.actual]: statusNames.actual }),
      ...(!value && { [statusFields.closed]: statusNames.closed, [statusFields.committed]: statusNames.committed, [statusFields.planned]: statusNames.planned })
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if ('spendByTypeData' in changes || 'selectedStatusNames' in changes) {
      this.initTableData();
    }
  }

  initTableData() {
    this.columnsFields = this.getColumnsFields();
    this.columnTitles = ['Expense Type', ...this.columnsFields.map(fieldName => this.columnTitleByFieldName[fieldName])];
    this.rows = this.tableDataBuilder
      .create()
      .setTableColumns(this.columnsFields)
      .setTableData(this.spendByTypeData)
      .getTableRows(true, false, false);
  }

  getColumnsFields() {
    const definedColumnFields = Object.keys(this.columnTitleByFieldName);
    return this.selectedStatusNames.length
      ? this.selectedStatusNames.filter(status => definedColumnFields.includes(status))
      : definedColumnFields;
  }
}
