import { MetricFunnelRow, MetricMaster, MetricValueType, ProductMetricFunnel } from '@common-lib/lib/corporate-page/metric-funnels.types';

export function createFunnelExample(defaultId, fillData: boolean): ProductMetricFunnel {
  return {
    id: defaultId,
    order: 0,
    activated: false,
    productName: 'Funnel ' + new Date().getTime(),
    revenueToProfit: fillData ? 80 : null,
    metricFunnelRows: [
      {
        countMetric: {
          id: null,
          name: 'Leads',
          target: fillData ? 2000 : null,
          isHidden: false,
          revenuePerOutcome: fillData ? 50 : null,
          valueType: MetricValueType.Count,
          rowIndex: 0,
          conversionRate: fillData ? 5 : null,
        },
        currencyMetric: null,
        conversionRate: fillData ? 5 : null,
      },
      {
        countMetric: {
          id: null,
          name: 'Opportunities',
          target: fillData ? 100 : null,
          isHidden: false,
          revenuePerOutcome: fillData ? 1000 : null,
          valueType: MetricValueType.Count,
          usageCount: 0,
          rowIndex: 1,
          conversionRate: fillData ? 10 : null,
        },
        currencyMetric: {
          id: null,
          name: 'Pipeline',
          target: fillData ? 1000000 : null,
          isHidden: false,
          valueType: MetricValueType.Currency,
          usageCount: 0,
          rowIndex: 1,
          conversionRate: fillData ? 10 : null,
        },
        conversionRate: fillData ? 10 : null,
      },
      {
        isBaseRow: true,
        countMetric: {
          id: null,
          name: 'Deals',
          target: fillData ? 10 : null,
          isHidden: false,
          revenuePerOutcome: fillData ? 10000 : null,
          valueType: MetricValueType.Count,
          usageCount: 0,
          rowIndex: 2,
          conversionRate: 100,
        },
        currencyMetric: {
          id: null,
          name: 'Revenue',
          target: fillData ? 100000 : null,
          isHidden: false,
          valueType: MetricValueType.Currency,
          usageCount: 0,
          rowIndex: 2,
          conversionRate: 100,
        },
        conversionRate: 100,
      },
    ]
  };
}

export function createSampleMetricFunnelRows(
  metricTypeIdByName: Record<string, number> = {}
): MetricFunnelRow[] {
  return [
    {
      countMetric: {
        id: metricTypeIdByName['Leads'] || null,
        name: 'Leads',
        target: 0,
        isHidden: false,
        revenuePerOutcome: 0,
        valueType: MetricValueType.Count,
        rowIndex: 0,
        conversionRate: 0,
      },
      currencyMetric: null,
      conversionRate: 0,
    },
    {
      countMetric: {
        id: metricTypeIdByName['Opportunities'] || null,
        name: 'Opportunities',
        target: 0,
        isHidden: false,
        revenuePerOutcome: 0,
        valueType: MetricValueType.Count,
        usageCount: 0,
        rowIndex: 1,
        conversionRate: 0,
      },
      currencyMetric: {
        id: metricTypeIdByName['Pipeline'] || null,
        name: 'Pipeline',
        target: 0,
        isHidden: false,
        valueType: MetricValueType.Currency,
        usageCount: 0,
        rowIndex: 1,
        conversionRate: 0,
      },
      conversionRate: 0,
    },
    {
      isBaseRow: true,
      countMetric: {
        id: metricTypeIdByName['Deals'] || null,
        name: 'Deals',
        target: 0,
        isHidden: false,
        revenuePerOutcome: 0,
        valueType: MetricValueType.Count,
        usageCount: 0,
        rowIndex: 2,
        conversionRate: 100,
      },
      currencyMetric: {
        id: metricTypeIdByName['Revenue'] || null,
        name: 'Revenue',
        target: 0,
        isHidden: false,
        valueType: MetricValueType.Currency,
        usageCount: 0,
        rowIndex: 2,
        conversionRate: 100,
      },
      conversionRate: 100,
    },
  ];
}

export function createEmptyFunnelRow(): MetricFunnelRow {
  return {
    isBaseRow: false,
    conversionRate: 100,
  }
}

export function createEmptyMetric(
  metricType: MetricValueType,
  rowIndex: number,
  conversionRate: number,
  id: number = null,
  name = ''
): MetricMaster {
  return {
    id,
    name,
    target: 0,
    isHidden: false,
    valueType: metricType,
    rowIndex: rowIndex,
    conversionRate: conversionRate,
  };
}

export function defaultCurrencyMetricName(targetInd, baseRowInd): string {
  return targetInd === (baseRowInd + 1) ? 'Lifetime Value' : ''
}
