import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Configuration } from '../../app.constants';
import { UserManager } from '../../user/services/user-manager.service';
import { switchMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

/**
 * CanActivate guard that checks that user is authenticated and user info is available
 */
@Injectable()
export class AuthRequiredGuard implements CanActivate {
  constructor (
    private userManager: UserManager,
    private router: Router,
    private configuration: Configuration
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userManager.isLoggedIn().pipe(
      switchMap(authenticated =>
        authenticated ?
          this.authenticatedNavigate(route) :
          of (this.router.createUrlTree(
          [this.configuration.ROUTING_CONSTANTS.LOGIN],
            { queryParams: { [this.configuration.QUERY_PARAMS.RETURN_URL]: state.url } })
          )
      )
    );
  }

  private authenticatedNavigate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return forkJoin([this.userManager.isSSOUser$, this.userManager.currentUserId$]).pipe(
      switchMap(([isSSO, userId]) =>
        isSSO && !userId ?
          of(
            route.routeConfig.path === this.configuration.ROUTING_CONSTANTS.SSO_NO_ACCESS ?
              true :
              this.router.createUrlTree([this.configuration.ROUTING_CONSTANTS.SSO_NO_ACCESS])
          ) :
          this.userManager.afterAuthNavigate(route.routeConfig.path)
      )
    )
  }
}
