<section class="calendar-placeholder">
  <div class="placeholder-icon">
    <fa-duotone-icon [icon]="['fad', 'calendar-alt']"></fa-duotone-icon>
  </div>
  <div class="placeholder-title">Nothing to see here ...</div>
  <ng-container *ngIf="editPermission">
    <div  class="placeholder-description">
      You may easily add a Campaign by clicking
    </div>
    <div class="placeholder-link">
      <add-object-link text="Add Campaign" [handler]="addCampaign"></add-object-link>
    </div>
    <div class="placeholder-description">Happy planning!</div>
  </ng-container>
</section>
