import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { TagService } from './backend/tag.service';
import { TagMappingDO } from 'app/shared/services/backend/tag.service';
import { Configuration } from 'app/app.constants';
import { map, switchMap } from 'rxjs/operators';
import { ExternalIntegrationObjectTypes } from '../constants/external-integration-object-types';
import { BudgetObjectType } from '../types/budget-object-type.interface';
import { ObjectMode } from '../enums/object-mode.enum';
import { ExpenseDO } from '@shared/types/expense.interface';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { CommonObject } from '@shared/types/budget-object.interface';
import { SegmentedObject } from '@shared/types/segmented-budget-object';

@Injectable({
  providedIn: 'root'
})
export class BudgetObjectService {
  private readonly OBJECT_TYPES = this.configuration.OBJECT_TYPES;
  /**
   * Checks and filters out 'integration' object types,
   * Detects if {selectedTypeId} is an 'integration' one.
   */
  public static processIntegrationObjectTypes(objectTypes: BudgetObjectType[], selectedTypeId = null): {
    integrationTypeIds: number[];
    integrationTypeSelected: boolean;
    filteredObjectTypes: BudgetObjectType[];
  } {
    const integrationTypeIds = BudgetObjectService.getExternalIntegrationTypeIds(objectTypes);

    if (!integrationTypeIds.length) {
      return {
        integrationTypeIds,
        filteredObjectTypes: objectTypes,
        integrationTypeSelected: false
      };
    }

    const integrationTypeSelected = integrationTypeIds.includes(selectedTypeId);
    const filteredObjectTypes = objectTypes
      .filter(objectType => objectType.id === selectedTypeId || !integrationTypeIds.includes(objectType.id));

    return {
      integrationTypeIds,
      filteredObjectTypes,
      integrationTypeSelected
    };
  }

  public static isSegmentlessObject(target: SegmentedObject): boolean {
    if (!target) {
      return false;
    }

    return !target.budgetSegmentId && !target.splitRuleId;
  }


  public static getExternalIntegrationTypeIds(objectTypes: BudgetObjectType[] = []): number[] {
    const externalIntegrationTypes = Object.values(ExternalIntegrationObjectTypes);

    return objectTypes
      .filter(objectType => externalIntegrationTypes.includes(objectType.name) && !objectType.isCustom)
      .map(objectType => objectType.id);
  }

  private static getObjectAllocations(obj) {
    return obj ? obj.allocations || obj.campaign_allocations || obj.program_allocations : null;
  }

  constructor(private tagService: TagService, private configuration: Configuration) {}

  public attachDynamicTag(budgetObjectId, tagMapping, mappingType): Observable<TagMappingDO> {
    return this.tagService.createTagMapping({
      is_dynamic: true,
      tags: tagMapping.tags,
      status: tagMapping.status,
      mapping_type: mappingType,
      map_id: budgetObjectId,
      value: null
    });
  }

  private detachDynamicTag(tagMappingId) {
    return this.tagService.deleteTagMapping(tagMappingId, true)
      .pipe(map(() => tagMappingId));
  }

  public getDynamicTagMappingsForBudgetObject(companyId, objectId, mappingType?): Observable<TagMappingDO[]> {
    return this.tagService.getTagMappings(companyId, {map_id: objectId, mapping_type: mappingType});
  }

  public processDynamicTags(
      objectId: number,
      mappingType: string,
      objectDynamicTagMappings: any[],
      prevParentDynamicTagsMappings: any[],
      newParentDynamicTagsMappings: any[]) {
    return this.detachDynamicTagsFromChildObjects(objectDynamicTagMappings, prevParentDynamicTagsMappings).pipe(
      switchMap((detachedMappingIdList: number[]) =>
        this.attachDynamicTagsToChildObjects(
          objectId,
          mappingType,
          objectDynamicTagMappings.filter(mapping => // Excluding recently detached mappings!!!
            !detachedMappingIdList || !detachedMappingIdList.some(detachedMappingId => detachedMappingId === mapping.id)),
          newParentDynamicTagsMappings
        ).pipe(
          map((attachedMappingList: TagMappingDO[]) => ({
            detached: detachedMappingIdList,
            attached: attachedMappingList
          }))
        )
      )
    );
  }

  private detachDynamicTagsFromChildObjects(objectDynamicTagMappings, prevParentDynamicTagsMappings) {
    const mappings = this.getTagMappingsToDetach(objectDynamicTagMappings, prevParentDynamicTagsMappings);
    return mappings.length ? this.detachTagMappings(mappings) : of(null);
  }

  private getTagMappingsToDetach(objectDynamicTagMappings, prevParentDynamicTagMappings) {
    return (objectDynamicTagMappings || []).filter(
      tm => prevParentDynamicTagMappings && prevParentDynamicTagMappings.some(pTm => pTm.tags === (tm.tags || tm.tag_id)));
  }

  private detachTagMappings(tagMappingList) {
    return forkJoin(tagMappingList.map(td => this.detachDynamicTag(td.id)));
  }

  public attachDynamicTagsToChildObjects(objectId, mappingType, objectDynamicTagMappings, newParentDynamicTagsMappings)
    : Observable<TagMappingDO[]> {
    const mappingsToAttach = this.getTagMappingsToAttach(objectDynamicTagMappings, newParentDynamicTagsMappings);
    return mappingsToAttach.length ?
      this.attachTagMappings(objectId, mappingsToAttach, mappingType) :
      of(null);
  }

  private getTagMappingsToAttach(objectDynamicTagMappings, newParentDynamicTagMappings) {
    return (newParentDynamicTagMappings || []).filter(
      pTm => !objectDynamicTagMappings || !objectDynamicTagMappings.some(tm => (tm.tags || tm.tag_id) === pTm.tags));
  }

  private attachTagMappings(objectId, tagMappings, mappingType): Observable<TagMappingDO[]> {
    return forkJoin([
      ...tagMappings.map(td => this.attachDynamicTag(objectId, td, mappingType))
    ]);
  }

  public prepareDataForUpdatingParent(expenses: ExpenseDO[], value: HierarchySelectItem): Record<string, any> {
    const parentPayload = {
      goal: value?.objectType === this.OBJECT_TYPES.goal ? value.objectId : null,
      campaign: value?.objectType === this.OBJECT_TYPES.campaign ? value.objectId : null,
      program: value?.objectType === this.OBJECT_TYPES.program ? value.objectId : null
    };
    const shouldOverrideSegment = expenses.some(exp =>
      exp.company_budget_segment1 !== value?.segmentData?.budgetSegmentId || exp.split_rule !== value?.segmentData?.sharedCostRuleId
    );
    let segmentPayload = {};
    if (shouldOverrideSegment) {
      segmentPayload = {
        company_budget_segment1: value?.segmentData?.budgetSegmentId,
        split_rule: value?.segmentData?.sharedCostRuleId
      };
    }

    return {
      ids: expenses.map(expense => expense.id),
      ...parentPayload,
      ...segmentPayload
    };
  }

  public prepareDataForUpdatingSegment(
    expenses: ExpenseDO[],
    selectedType: string,
    selectedId: number,
    isCurrentBudgetWithNewCEGStructure: boolean
  ): Record<string, any> {
    const isSegmentSelected = selectedType === this.OBJECT_TYPES.segment;
    const shouldOverrideParent = expenses.some(exp => {
      const segment = isSegmentSelected ? exp.company_budget_segment1 : exp.split_rule;
      return segment !== selectedId;
    });
    let parentPayload = {};
    if (shouldOverrideParent && isCurrentBudgetWithNewCEGStructure) {
      parentPayload = {
        campaign: null,
        program: null
      };
    }
    return {
      ids: expenses.map(expense => expense.id),
      split_rule: isSegmentSelected ? null : selectedId,
      company_budget_segment1: isSegmentSelected ? selectedId : null,
      ...parentPayload
    };
  }

  public prepareUndoPayloads(expenses: ExpenseDO[]): Record<string, string[] | number>[] {
    const parentMap = {};
    for (const expense of expenses) {
      const key = [expense.company_budget_segment1, expense.split_rule, expense.campaign, expense.program].join('_');
      if (parentMap[key]) {
        parentMap[key].push(expense.id);
      } else {
        parentMap[key] = [expense.id];
      }
    }

    const payloads = [];
    for (const key in parentMap) {
      if (parentMap.hasOwnProperty(key)) {
        const [segmentId, splitRuleId, campaignId, programId] = key.split('_');
        payloads.push({
          ids: parentMap[key],
          company_budget_segment1: segmentId,
          split_rule: splitRuleId || null,
          campaign: campaignId || null,
          program: programId || null
        });
      }
    }
    return payloads;
  }

  public doesObjectHaveClosedParent(expense: ExpenseDO, programList, campaignList) {
    const expenseBucket = programList ? programList.find(p => p.id === expense.program) : null;
    if (expenseBucket) {
      return expenseBucket.timeframes.some(alloc => alloc.mode === 'Closed');
    }

    const campaign = campaignList ? campaignList.find(c => c.id === expense.campaign) : null;
    if (campaign) {
      return campaign.timeframes.some(alloc => alloc.mode === 'Closed');
    }

    return false;
  }

  public getOpenedObjects<TObject extends CommonObject>(objectsList: TObject[], keepObjectId?: number): TObject[] {
    return objectsList.filter(obj => obj.id === keepObjectId || obj.mode !== ObjectMode.Closed);
  }

  public getObjectTypeName(objectType: string): string {
    switch (objectType) {
      case this.configuration.OBJECT_TYPES.goal:
        return 'goal';

      case this.configuration.OBJECT_TYPES.campaign:
        return 'campaign';

      case this.configuration.OBJECT_TYPES.program:
        return 'expense bucket';

      default:
        return objectType;
    }
  }
}
