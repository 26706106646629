import { BudgetObjectEventContext, BudgetObjectEventType } from '../../../budget-object-details/types/budget-object-event.interface';
import { ManageCegTableDataService } from '../manage-ceg-table-data.service';
import { Configuration } from 'app/app.constants';
import { forkJoin, Observable, of } from 'rxjs';
import { ManageCegDataMode, ManageCegTableRow, ManageCegViewMode } from '../../types/manage-ceg-page.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { TableRowAmountsLoader } from '@manage-ceg/types/manage-ceg-table-row-data.types';
import { map, tap } from 'rxjs/operators';
import { getPseudoObjectId } from '@shared/utils/common.utils';
import { BudgetObjectParent } from 'app/budget-object-details/types/budget-object-details-state.interface';
import { LightProgram } from '@shared/types/program.interface';
import { LightCampaign } from '@shared/types/campaign.interface';
import { Budget } from '@shared/types/budget.interface';
import { BudgetObjectEventHandler } from '@manage-ceg/types/budget-object-event-handler.types';
import { getParentObject } from 'app/budget-object-details/utils/object-details.utils';
import { LightObject } from '@shared/types/segmented-budget-object';
import { sortObjectsByName } from '@shared/utils/budget.utils';

export abstract class BaseBudgetObjectEventHandler implements BudgetObjectEventHandler {
  private readonly eventTypeHandlers = {
    [BudgetObjectEventType.Created]: this.onCreate.bind(this),
    [BudgetObjectEventType.Updated]: this.onUpdate.bind(this),
    [BudgetObjectEventType.Deleted]: this.onDelete.bind(this),
    [BudgetObjectEventType.Moved]: this.onMoved.bind(this)
  };

  protected readonly manageCegTableDataService: ManageCegTableDataService;
  protected readonly config: Configuration;

  protected objectToRowTypeMap: Record<string, ManageTableRowType>;
  protected getFilterParams: (row: ManageCegTableRow) => object;
  private readonly budgetModeRowDataLoaders: Record<string, TableRowAmountsLoader>;
  private readonly performanceModeRowDataLoaders: Record<string, TableRowAmountsLoader>;

  protected constructor(
    manageCegTableDataService: ManageCegTableDataService,
    config: Configuration
  ) {
    this.manageCegTableDataService = manageCegTableDataService;
    this.config = config;
    this.objectToRowTypeMap = this.getObjectToRowTypeMap();
    this.budgetModeRowDataLoaders = manageCegTableDataService.getBudgetModeAmountsLoaders();
    this.performanceModeRowDataLoaders = manageCegTableDataService.getPerformanceModeAmountsLoaders();
  }

  protected abstract onCreate(data: BudgetObjectEventContext): Observable<boolean>;
  protected abstract onUpdate(data: BudgetObjectEventContext): Observable<boolean>;
  protected abstract onDelete(data: BudgetObjectEventContext): Observable<boolean>;
  protected abstract onMoved(data: BudgetObjectEventContext): Observable<boolean>;

  public handle(eventType: BudgetObjectEventType, data: BudgetObjectEventContext): Observable<boolean> {
    return this.eventTypeHandlers[eventType]?.(data) || of(false);
  }

  public handleAllocationUpdate(rowsToUpdate: ManageCegTableRow[]): Observable<boolean> {
    return this.updateParentRows(rowsToUpdate).pipe(
      map(() => true)
    );
  }

  protected get viewMode(): ManageCegViewMode {
    return this.manageCegTableDataService?.tableDataInputs?.viewMode;
  }

  protected get isSegmentViewMode() {
    return this.viewMode === ManageCegViewMode.Segments;
  }

  protected get isGoalViewMode() {
    return this.viewMode === ManageCegViewMode.Goals;
  }

  protected get dataMode(): ManageCegDataMode {
    return this.manageCegTableDataService?.tableDataInputs?.dataMode;
  }

  protected getTableRowByRowTypeAndObjectId(rowType: ManageTableRowType, objectId: number | string): ManageCegTableRow {
    return this.manageCegTableDataService?.getRecordByIdAndType(rowType, objectId);
  }

  protected updateParentRows(rows: ManageCegTableRow[]): Observable<boolean> {
    const rowsToUpdateMap =
      rows.reduce(
        (resultRowsMap, row) => {
          let currentRow = row;
          while (currentRow && !resultRowsMap[currentRow.id]) {
            resultRowsMap[currentRow.id] = currentRow;
            currentRow = currentRow.parentId ? this.manageCegTableDataService.getRecordById(currentRow.parentId) : null;
          }
          return resultRowsMap;
        },
        {}
      );

    const rowsToUpdate: ManageCegTableRow[] = Object.values(rowsToUpdateMap);
    return this.updateRows(rowsToUpdate);
  }

  protected updateRows(rows: ManageCegTableRow[]): Observable<boolean> {
    if (!rows?.length) {
      return of(false);
    }

    return forkJoin(
      rows.map(row => this.updateRow(row))
    ).pipe(
      map(updates => updates.some(updated => updated)) // At least one row has been updated
    );
  }

  protected getRowAmountsLoader(rowType: ManageTableRowType): TableRowAmountsLoader {
    const amountLoaders =
      this.dataMode === ManageCegDataMode.Budget ?
        this.budgetModeRowDataLoaders :
        this.performanceModeRowDataLoaders;

    return amountLoaders?.[rowType];
  }

  protected updateRow(row: ManageCegTableRow): Observable<boolean> {
    const rowAmountsLoader = this.getRowAmountsLoader(row.type);
    if (!rowAmountsLoader) {
      return of(false);
    }

    const tableDataInputs = this.manageCegTableDataService.tableDataInputs;
    const parentRow = this.manageCegTableDataService.getRecordById(row.parentId);
    const { campaigns, segments } = this.manageCegTableDataService.tableDataInputs;

    return rowAmountsLoader.fillRowAmounts(
      tableDataInputs?.budget?.id,
      [row],
      this.manageCegTableDataService.getParamsFromFilters(this.viewMode, campaigns, segments, parentRow),
      this.manageCegTableDataService.timeframesAll
    ).pipe(
      map(() => {
        row.processed = true;
        if (parentRow) {
          if (!parentRow.children.find(childRow => row.id === childRow.id)) {
            parentRow.children.push(row);
          }
          this.manageCegTableDataService.refreshRowChildrenLoadingState(parentRow);
        } else {
          this.manageCegTableDataService.updateRootRow(row);
        }
        return true;
      })
    );
  }

  protected segmentIdsForSharedCostRule(scrId: number): number[] {
    return this.manageCegTableDataService.tableDataInputs?.sharedCostRules
      .find(scr => scr.id === scrId)?.segments
      .map(segment => segment.id);
  }

  protected getRowTypeByParentObjectType(objectType: string): ManageTableRowType {
    return this.objectToRowTypeMap[objectType];
  }

  protected getNearestLoadedParentRow(startRow: ManageCegTableRow): ManageCegTableRow {
    if (startRow) {
      return startRow.processed ?
        startRow :
        this.getNearestLoadedParentRow(this.manageCegTableDataService.getRecordById(startRow.parentId));
    }
  }

  private getObjectToRowTypeMap(): Record<string, ManageTableRowType> {
    const { OBJECT_TYPES: objectTypes } = this.config;
    return {
      [objectTypes.program]: ManageTableRowType.ExpenseGroup,
      [objectTypes.campaign]: ManageTableRowType.Campaign,
      [objectTypes.goal]: ManageTableRowType.Goal
    };
  }

  protected updateTableDataInputObjects<T extends LightProgram | LightCampaign>(
    addedObjects: T[],
    targetObjectsContainer: T[],
    objectsLoader: (companyId: number, budgetId: number, status: string, params: object) => Observable<T>,
    objectStatus: string,
    budget: Budget
  ): Observable<any> {
    const addNewObjects = (newObjects: T[]) => {
      targetObjectsContainer.push(...newObjects);
      sortObjectsByName(targetObjectsContainer);
    };

    const scrObjects = addedObjects.filter(obj => obj.splitRuleId);

    if (!this.isSegmentViewMode || !scrObjects.length) {
      return  of(null).pipe(
        tap(() => addNewObjects(addedObjects))
      );
    }

    const { company: companyId, id: budgetId } = budget;
    const segmentIds =
      scrObjects.reduce(
        (uniqueSegmentIds, scrObject) => {
          this.segmentIdsForSharedCostRule(scrObject.splitRuleId).forEach(segmentId => uniqueSegmentIds.add(segmentId));
          return uniqueSegmentIds;
        },
        new Set<number>()
      );

    const params = { ids: scrObjects.map(obj => obj.id), company_budget_segment1_ids: segmentIds, include_pseudo_objects: true };
    return objectsLoader(companyId, budgetId, objectStatus, params).pipe(
      tap(pseudoObjects => {
        const regularObjects = addedObjects.filter(obj => !obj.splitRuleId);
        addNewObjects([...regularObjects, ...pseudoObjects]);
      })
    );
  }

  protected getNearestLoadedParentRows(
    parentObject: BudgetObjectParent,
    segId: number,
    sharedCostRuleId: number
  ): ManageCegTableRow[] {
    if (parentObject?.type === this.config.OBJECT_TYPES.goal && this.isGoalViewMode) {
      const goalRow = this.getTableRowByRowTypeAndObjectId(ManageTableRowType.Goal, parentObject.id);
      if (goalRow?.processed) {
        return [goalRow];
      }
    }

    if (this.isSegmentViewMode) {
      return this.getNearestLoadedParentRowsInSegmentMode(parentObject, segId, sharedCostRuleId);
    }

    if (parentObject) {
      const parentRow =
        this.dataMode === ManageCegDataMode.Budget ?
          this.getRowForParentObjectInBudgetMode(parentObject) :
          this.getRowForParentObjectInPerformanceMode(parentObject);

      const nearestLoadedRow = this.getNearestLoadedParentRow(parentRow);
      if (nearestLoadedRow) {
        return [nearestLoadedRow];
      }
    }

    return [];
  }

  private getRowForParentObjectInBudgetMode(parentObject: BudgetObjectParent): ManageCegTableRow {
    const rowType = this.getRowTypeByParentObjectType(parentObject.type);
    return this.getTableRowByRowTypeAndObjectId(rowType, parentObject.id);
  }

  private getRowForParentObjectInPerformanceMode(parentObject: BudgetObjectParent): ManageCegTableRow {
    if (parentObject?.type === this.config.OBJECT_TYPES.program) {
      const programObject =
        this.manageCegTableDataService.tableDataInputs.expGroups.find(program => program.id === parentObject.id);
      if (programObject?.campaignId != null) {
        return this.getRowForParentObjectInBudgetMode({ id: programObject?.campaignId, type: this.config.OBJECT_TYPES.campaign });
      }
    } else {
      return this.getRowForParentObjectInBudgetMode(parentObject);
    }
  }

  private getNearestLoadedParentRowsInSegmentMode(
    parentObject: BudgetObjectParent,
    segId: number,
    sharedCostRuleId: number
  ): ManageCegTableRow[] {
    if (!parentObject) {
      return [];
    }

    const objectSegmentIds = segId ? [segId] : this.segmentIdsForSharedCostRule(sharedCostRuleId);

    return objectSegmentIds.reduce(
      (resultParentRows, segmentId) => {
        let parentRow: ManageCegTableRow;

        if (parentObject.type !== this.config.OBJECT_TYPES.goal) {
          const rowType = this.getRowTypeByParentObjectType(parentObject.type);
          // Try to find parent as a regular object:
          parentRow = this.getTableRowByRowTypeAndObjectId(rowType, parentObject.id);
          if (!parentRow) {
            // Try to find parent as a pseudo object:
            const parentObjectId = getPseudoObjectId(parentObject.id, segmentId);
            parentRow = this.getTableRowByRowTypeAndObjectId(rowType, parentObjectId);
          }
        }

        if (!parentRow) {
          parentRow = this.getTableRowByRowTypeAndObjectId(ManageTableRowType.Segment, segmentId);
        }

        const nearestLoadedRow = this.getNearestLoadedParentRow(parentRow);
        if (nearestLoadedRow) {
          resultParentRows.push(nearestLoadedRow);
        }
        return resultParentRows;
      },
      []
    );
  }

  protected getRowsToUpdateForAddedObject(
    objectId: number,
    sharedCostRuleId: number | null,
    addedRowType: ManageTableRowType
  ): ManageCegTableRow[] {
    const addedObjectRows =
      this.isSegmentViewMode && sharedCostRuleId ?
        this.segmentIdsForSharedCostRule(sharedCostRuleId)
          .map(segmentId => this.getTableRowByRowTypeAndObjectId(addedRowType, getPseudoObjectId(objectId, segmentId)))
          .filter(row => row) :
        [this.getTableRowByRowTypeAndObjectId(addedRowType, objectId)];

    return addedObjectRows
      .map(
        objectRow => {
          const directParentRow = this.manageCegTableDataService.getRecordById(objectRow.parentId);
          const shouldUpdateAddedObject =
            !directParentRow ||
            (
              directParentRow.processed &&
              (directParentRow.loadedChildren?.length || directParentRow.children?.length === 1)
            );

          return shouldUpdateAddedObject ?
            objectRow :
            directParentRow && this.getNearestLoadedParentRow(directParentRow);
        }
      )
      .filter(row => row);
  }

  protected removeObjectFromTableDataInputs<T extends LightProgram | LightCampaign>(
    removedObject: T,
    objectsContainer: T[]
  ): void {
    if (!this.isSegmentViewMode || !removedObject.splitRuleId) {
      const removedFromTableObjectIndex = objectsContainer.findIndex(obj => obj.id === removedObject.id);
      if (removedFromTableObjectIndex !== -1) {
        objectsContainer.splice(removedFromTableObjectIndex, 1);
      }
    } else {
      const segmentIds = this.segmentIdsForSharedCostRule(removedObject.splitRuleId);
      for (const segmentId of segmentIds) {
        const pseudoObjectId = getPseudoObjectId(removedObject.id, segmentId);
        const removedFromTableObjectIndex = objectsContainer.findIndex(obj => obj.objectId === pseudoObjectId);
        if (removedFromTableObjectIndex !== -1) {
          objectsContainer.splice(removedFromTableObjectIndex, 1);
        }
      }
    }
  }

  protected getRowsToUpdateForUpdatedObject(
    rowsToUpdateOnAdd: ManageCegTableRow[],
    rowsToUpdateOnDelete: ManageCegTableRow[]
  ): ManageCegTableRow[] {
    return [
      ...rowsToUpdateOnAdd,
      ...rowsToUpdateOnDelete.filter(
        rowToUpdateOnDelete =>
          !rowsToUpdateOnAdd.some(
            rowToUpdateOnAdd =>
              rowToUpdateOnAdd.id === rowToUpdateOnDelete.id ||
              rowToUpdateOnAdd.parentId === rowToUpdateOnDelete.id
          )
      )
    ];
  }

  protected getRowsToUpdateForUpdatedObjects(
    updatedObjects: LightObject[],
    prevObjects: LightObject[],
    addedRowType: ManageTableRowType,
    parentObjectGetter: (obj: LightObject) => BudgetObjectParent
  ): ManageCegTableRow[] {
    const rowsToUpdateOnAddMap =
      updatedObjects.reduce(
        (rowsMap, updatedObject) => {
          this.getRowsToUpdateForAddedObject(updatedObject.id, updatedObject.splitRuleId, addedRowType)
            .filter(row => !rowsMap[row.id])
            .forEach(row => rowsMap[row.id] = row)
          return rowsMap;
        },
        {}
      );

    const allRowsToUpdateMap =
      prevObjects.reduce(
        (rowsMap, prevObject) => {
          this.getNearestLoadedParentRows(parentObjectGetter(prevObject), prevObject.budgetSegmentId, prevObject.splitRuleId)
            .filter(row => !rowsMap[row.id])
            .forEach(row => rowsMap[row.id] = row)
          return rowsMap;
        },
        rowsToUpdateOnAddMap
      );

    return Object.values(allRowsToUpdateMap);
  }

  protected applyRemovedObjects<T extends LightCampaign | LightProgram>(objectIds: number[], currentAllObjects: T[]): T[]  {
    return objectIds.reduce(
      (removedObjects, objectId) => {
        const removedObjectIndex = currentAllObjects.findIndex(obj => obj.id === objectId);
        if (removedObjectIndex !== -1) {
          const removedObject = currentAllObjects[removedObjectIndex];
          currentAllObjects.splice(removedObjectIndex, 1);
          removedObjects.push(removedObject);
        }
        return removedObjects;
      },
      []
    );
  }

  protected getRowsToUpdateForRemovedObjects<T extends LightProgram | LightCampaign>(
    removedObjects: T[],
    parentObjectGetter: (obj: T) => BudgetObjectParent
  ): ManageCegTableRow[] {
    const uniqueRows = removedObjects.reduce(
      (rowsToUpdate, removedObject) => {
        const rows =
          this.getNearestLoadedParentRows(
            parentObjectGetter(removedObject),
            removedObject.budgetSegmentId,
            removedObject.splitRuleId
          );
        rows.filter(row => !rowsToUpdate[row.id]).forEach(row => rowsToUpdate[row.id] = row);
        return rowsToUpdate;
      },
      {}
    );
    return Object.values(uniqueRows);
  }

  protected getParentObject(parentCampaignId?: number, parentGoalId?: number): BudgetObjectParent {
    return getParentObject(this.config, parentGoalId, parentCampaignId);
  }
}
