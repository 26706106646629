import { Component, HostBinding, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PdfViewerService } from '@shared/services/pdf-viewer.service';
import { UtilityService } from '@shared/services/utility.service';
import { skip, Subject, takeUntil } from 'rxjs';

const INCREASE_VALUE = 100;
@Component({
  selector: 'attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.scss']
})
export class AttachmentViewerComponent implements OnInit, OnDestroy {
  @HostBinding('style.width') width: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    public readonly pdfViewerService: PdfViewerService,
    private readonly utilityService: UtilityService,
    private dialogRef: MatDialogRef<AttachmentViewerComponent>
  ) { }

  ngOnInit(): void {
    this.pdfViewerService.calculatedContainerWidth$
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe(width => this.width = `${width + INCREASE_VALUE}px`);

    this.pdfViewerService.isPageRendered$
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.utilityService.showLoading(!value));
  }

  public closeViewer(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
