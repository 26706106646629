<div class="child-objects" *ngIf="data">
  <div class="header">
    <details-widget-title [count]="data.rows ? data.rows.length : 0" [text]="titleByType[objectType]">
      <fa-duotone-icon [icon]="iconByType[objectType]" slot="icon"></fa-duotone-icon>
    </details-widget-title>
    <add-object-link
      *ngIf="!isReadOnlyMode"
      [text]="addBtnTitleByType[objectType]"
      [handler]="navigateToCreationPage"
    ></add-object-link>
  </div>
  <div class="table">
    <table *ngIf="data.rows && data.rows.length; else elseBlock">
      <thead>
        <tr>
          <th class="name">Name</th>
          <th class="owner">Owner</th>
          <th></th>
          <th class="allocated">Allocated</th>
          <th class="segment" *ngIf="showSegment">Segment</th>
          <th class="business-value" *ngIf="showBusinessValue">Campaign Value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.rows" (click)="navigateToDetailsPage(row)" [class.prohibited]="row.isProhibited"
          matTooltip="Sorry, you don’t have permission to open this"
          matTooltipClass="dark-theme-tooltip above"
          matTooltipPosition="above"
          [matTooltipDisabled]="!row.isProhibited"
        >
          <td class="name">{{row.name}}</td>
          <td class="owner">{{row.owner}}</td>
          <td class="is-ended">{{row.isEnded ? 'Ended' : ''}}</td>
          <td class="allocated">{{row.allocated | number : decimalPipeFormat}}</td>
          <td class="segment" *ngIf="showSegment">
            <div *ngIf="row.segmentName">
              {{ row.segmentName }}
            </div>
            <div *ngIf="row.sharedCostRule" (mouseenter)="showTooltip($event, row.sharedCostRule)" (mouseleave)="hideTooltip()">
              <icon-shared [state]="SharedIconState.Exception"></icon-shared>
              <span>Shared</span>
            </div>
          </td>
          <td class="business-value" *ngIf="showBusinessValue">{{row.businessValue != null ? (row.businessValue | number : '1.0-0') : 'N/A'}}</td>
        </tr>
        <tr class="total-row">
          <td>Total</td>
          <td class="owner"></td>
          <td></td>
          <td class="allocated">{{ currency }} {{ data.totalAllocated | number : decimalPipeFormat }}</td>
          <td class="segment" *ngIf="showSegment"></td>
          <td class="business-value" *ngIf="showBusinessValue"></td>
        </tr>
      </tbody>
    </table>
    <ng-template #elseBlock>
      <details-data-placeholder>
        {{zeroMessageByType[objectType]}}
      </details-data-placeholder>
    </ng-template>
  </div>
</div>
<shared-rule-tooltip *ngIf="isTooltipShown" className="fixed-position arrow-right" [name]="tooltipData.name" [segments]="tooltipData.segments" [coords]="tooltipData.coords"></shared-rule-tooltip>
