import { inject, Injectable } from '@angular/core';
import { FilterName, FilterSet } from '../../header-navigation/components/filters/filters.interface';
import { forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { CompanyDataService, Tag } from './company-data.service';
import { TagMappingDO, TagService } from './backend/tag.service';
import { Configuration } from '../../app.constants';
import { UtilityService } from './utility.service';
import { TagsControlService } from './tags-control.service';
import { BulkOperationResponse } from '../types/bulk-operation-response.interface';
import { ManageTableHelpers } from '../../manage-table/services/manage-table-helpers';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TagMapping } from '../types/tag-mapping.interface';
import { TagDO } from '../types/tag-do.interface';
import { ManageTableSelectionState } from '../../manage-table/types/manage-table-selection-state.types';
import { BudgetObjectTagsService } from '../../budget-object-details/services/budget-object-tags.service';

interface GetTagMappingsParams {
  companyId: number;
  mappingType: string;
  filters?: FilterSet;
  mapIds?: number[];
}

@Injectable()
export abstract class BaseManagePageTagsService {
  private readonly tagService = inject(TagService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly configuration = inject(Configuration);
  private readonly utilityService = inject(UtilityService);
  private readonly tagsControlService = inject(TagsControlService);

  protected readonly destroy$ = new Subject<void>();
  protected readonly tagMappingsLoading$ = new Subject<void>();

  private TOASTR_DURATION = 3000;
  private filterNameByMappingType = {
    [this.configuration.OBJECT_TYPES.goal]: FilterName.Goals,
    [this.configuration.OBJECT_TYPES.campaign]: FilterName.Campaigns,
    [this.configuration.OBJECT_TYPES.expenseGroup]: FilterName.ExpenseBuckets,
  };
  private _tags: Tag[] = [];
  private _tagMappings: Record<string, Record<string, TagMappingDO[]>> = {
    [this.configuration.OBJECT_TYPES.goal]: {},
    [this.configuration.OBJECT_TYPES.campaign]: {},
    [this.configuration.OBJECT_TYPES.program]: {},
  };
  private readonly notificationEndings = {
    DELETED: 'deleted.',
    FAILED_TO_DELETE: 'failed to delete.',
    CREATED: 'created.',
    FAILED_TO_CREATE: 'failed to create.',
  };

  protected constructor(protected readonly apiService: any) {}

  private getTagList(): Observable<Tag[]> {
    return this.companyDataService.tagsSnapshot
      ? of(this.companyDataService.tagsSnapshot)
      : this.companyDataService.tagList$;
  }

  private showBulkResultsNotification(
    results: BulkOperationResponse<any>[],
    successEnding = '',
    errorEnding = '',
  ): void {
    const resultsCount = this.apiService.sumUpBulkOperationResults(results);
    const message = ManageTableHelpers.getBulkOperationMessage(
      resultsCount,
      successEnding,
      errorEnding,
      null,
      this.configuration.OBJECT_TYPES.tag
    );

    this.utilityService.showCustomToastr(message, null, { timeOut: this.TOASTR_DURATION });
  }

  private buildTagMappingsMap(data: TagMappingDO[]): Record<string, TagMappingDO[]> {
    const resultMap = {};

    for (const tagMapping of data) {
      if (!resultMap[tagMapping.map_id]) {
        resultMap[tagMapping.map_id] = [];
      }
      resultMap[tagMapping.map_id].push(tagMapping);
    }

    return resultMap;
  }

  private prepareTagMappingsFilterParams(
    filters: FilterSet,
    mappingType: string
  ): any {
    const filterParams: any = {};
    const mappingTypeFilter = this.filterNameByMappingType[mappingType];

    if (filters[FilterName.Tags]?.length) {
      filterParams.tags = filters[FilterName.Tags].join(',');
    }
    if (mappingTypeFilter && filters[mappingTypeFilter]?.length) {
      filterParams.map_ids = filters[mappingTypeFilter].join(',');
    }

    return filterParams;
  }

  private prepareTagMappingsGetRequest(params: GetTagMappingsParams): Observable<TagMappingDO[]> {
    const { companyId, mappingType, mapIds = null, filters = {} } = params;

    if (mapIds) {
      return mapIds.length ?
        this.tagService.getTagMappings(companyId, {
          mapping_type: mappingType,
          map_ids: mapIds.join(',')
        }) :
        of([]);
    }

    const filterParams = this.prepareTagMappingsFilterParams(filters, mappingType);

    return this.tagService.getTagMappings(companyId, {
      mapping_type: mappingType,
      ...filterParams
    });
  }

  private processLoadedTagMappings(
    mappings: TagMappingDO[],
    mapIds: number[],
    mappingType: string
  ): void {
    const mappingsMap = this.buildTagMappingsMap(mappings);

    if (mapIds) {
      mapIds.forEach(id => {
        this._tagMappings[mappingType][id] = mappingsMap[id] || [];
      });
    } else {
      this._tagMappings[mappingType] = mappingsMap;
    }
  }

  private getTagMappings(params: GetTagMappingsParams): Observable<TagMappingDO[]> {
    const { mappingType, mapIds = null } = params;
    const request$ = this.prepareTagMappingsGetRequest(params);

    return request$.pipe(
      tap(mappings => this.processLoadedTagMappings(mappings, mapIds, mappingType))
    );
  }

  private deleteTagMappings(
    mapIds: number[],
    mappingType: string,
    tagIds: number[]
  ): Observable<BulkOperationResponse<number>> {
    return mapIds?.length ?
      this.tagService.multiDeleteTagMapping(
        mapIds,
        mappingType,
        tagIds
      ) :
      of(null);
  };

  private processCreatedTagMappings(
    results: BulkOperationResponse<TagMappingDO>,
    mappingType: string
  ): void {
    results?.success.forEach(tagMapping => {
      const { map_id: objectID } = tagMapping;
      const mappings = this._tagMappings[mappingType];

      if (!mappings[objectID]) {
        mappings[objectID] = [];
      }
      mappings[objectID]?.push(tagMapping);
    });
  }

  private createTagMappings(
    mapIds: number[],
    tagIds: number[],
    mappingType: string
  ): Observable<BulkOperationResponse<TagMappingDO>> {
    const request$ = mapIds.length ?
      this.tagService.multiCreateTagMapping(
        mapIds,
        tagIds,
        mappingType
      ) :
      of(null);

    return request$.pipe(
      tap(results => this.processCreatedTagMappings(results, mappingType))
    )
  }

  private createTags(
    items: TagMapping[],
    companyId: number
  ): Observable<TagDO[]> {
    const getPayload = (item: TagMapping): Partial<TagDO> => ({
      name: item.name,
      is_custom: true,
      company: companyId
    });

    return items.length ?
      forkJoin(
        items.map(item => this.tagService.createTag(getPayload(item)))
      ) :
      of([]);
  }

  private removeTagsForSelection(
    selection: ManageTableSelectionState,
    items: TagMapping[],
    companyId: number
  ): Observable<any> {
    const tagIds = items.map(item => item.tagId);
    const goalIds = [...selection.goals.values()];
    const campaignIds = [...selection.campaigns.values()];
    const expGroupIds = [...selection.expGroups.values()];
    const { OBJECT_TYPES } = this.configuration;
    const { DELETED, FAILED_TO_DELETE } = this.notificationEndings;

    return forkJoin([
      this.deleteTagMappings(goalIds, OBJECT_TYPES.goal, tagIds),
      this.deleteTagMappings(campaignIds, OBJECT_TYPES.campaign, tagIds),
      this.deleteTagMappings(expGroupIds, OBJECT_TYPES.program, tagIds)
    ])
      .pipe(
        tap(results => this.showBulkResultsNotification(results, DELETED, FAILED_TO_DELETE)),
        switchMap(([ goalResult, campaignResult, expGroupResult ]) => {
          const goalTagMappings$ = this.getTagMappings({
            companyId,
            mappingType: OBJECT_TYPES.goal,
            mapIds: goalResult?.success?.length ? goalIds : []
          });
          const campaignTagMappings$ = this.getTagMappings({
            companyId,
            mappingType: OBJECT_TYPES.campaign,
            mapIds: campaignResult?.success?.length ? campaignIds : []
          });
          const expGroupTagMappings$ = this.getTagMappings({
            companyId,
            mappingType: OBJECT_TYPES.program,
            mapIds: expGroupResult?.success?.length ? expGroupIds : []
          });

          return forkJoin([
            goalTagMappings$,
            campaignTagMappings$,
            expGroupTagMappings$
          ]);
        })
      );
  }

  private addTagsForSelection(
    selection: ManageTableSelectionState,
    items: TagMapping[],
    companyId: number
  ): Observable<any> {
    const newItems = items.filter(tag => !tag.existed);
    const existingItems = items.filter(tag => tag.existed);
    const goalIds = [...selection.goals.values()];
    const campaignIds = [...selection.campaigns.values()];
    const expGroupIds = [...selection.expGroups.values()];
    const { OBJECT_TYPES } = this.configuration;
    const { CREATED, FAILED_TO_CREATE } = this.notificationEndings;

    return this.createTags(newItems, companyId).pipe(
      tap(createdTags => this._tags.push(...createdTags)),
      switchMap(createdTags => {
        const tagIdsToAdd = [
          ...existingItems.map(item => item.tagId),
          ...createdTags.map(item => item.id)
        ];

        return tagIdsToAdd.length ?
          forkJoin([
            this.createTagMappings(goalIds, tagIdsToAdd, OBJECT_TYPES.goal),
            this.createTagMappings(campaignIds, tagIdsToAdd, OBJECT_TYPES.campaign),
            this.createTagMappings(expGroupIds, tagIdsToAdd, OBJECT_TYPES.program),
          ]) :
          of([]);
      }),
      tap(results => this.showBulkResultsNotification(results, CREATED, FAILED_TO_CREATE))
    );
  }

  public get tags(): Tag[] {
    return this._tags;
  }

  public get tagMappings(): Record<string, Record<string, TagMappingDO[]>> {
    return this._tagMappings;
  }

  public loadTags(): void {
    this.tagMappingsLoading$.next();
    this.getTagList()
      .pipe(
        takeUntil(merge(this.destroy$, this.tagMappingsLoading$)),
        tap(data => this._tags = data)
      )
      .subscribe({
        error: error => this.utilityService.handleError(error)
      });
  }

  public getUniqueTagMappingsForSelection(selection: ManageTableSelectionState, companyId: number): Observable<TagMapping[]> {
    const uniqueTagMappings: Record<number, TagMapping> = {};
    const { OBJECT_TYPES } = this.configuration;
    const processTagMapping = (mapping: TagMappingDO) => {
      if (!uniqueTagMappings[mapping.tags]) {
        uniqueTagMappings[mapping.tags] = BudgetObjectTagsService.fromTagMappingDO(mapping);
      }
    };

    return forkJoin([
      this.getTagMappings({
        companyId,
        mappingType: OBJECT_TYPES.goal,
        mapIds: [...selection.goals]
      }),
      this.getTagMappings({
        companyId,
        mappingType: OBJECT_TYPES.campaign,
        mapIds: [...selection.campaigns]
      }),
      this.getTagMappings({
        companyId,
        mappingType: OBJECT_TYPES.program,
        mapIds: [...selection.expGroups]
      })
    ])
      .pipe(
        tap(([goal, campaign, program]) => {
          goal.forEach(processTagMapping);
          campaign.forEach(processTagMapping);
          program.forEach(processTagMapping);
        }),
        catchError(error => {
          this.utilityService.handleError(error);
          return of([]);
        }),
        map(() => Object.values(uniqueTagMappings)),
      );
  }

  public openRemoveTagsDialog(
    selection: ManageTableSelectionState,
    companyId: number
  ): void {
    const submitHandler = (items: TagMapping[]) => {
      if (!items.length) {
        return;
      }

      this.removeTagsForSelection(
        selection,
        items,
        companyId
      ).subscribe({
        error: error => this.utilityService.handleError(error)
      })
    };
    const tagMappings$ = this.getUniqueTagMappingsForSelection(selection, companyId);

    this.tagsControlService.openRemoveTagsDialog(
      tagMappings$,
      submitHandler
    ).subscribe();
  }

  public openAddTagsDialog(
    selection: ManageTableSelectionState,
    companyId: number
  ): void {
    const submitHandler = (items: TagMapping[]) => {
      if (!items.length) {
        return;
      }

      this.addTagsForSelection(
        selection,
        items,
        companyId
      ).subscribe({
        error: error => this.utilityService.handleError(error)
      })
    };

    this.tagsControlService.openAddTagsDialog(this.tags, submitHandler)
      .subscribe();
  }
}
