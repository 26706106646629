<mat-expansion-panel
  #panel
  hideToggle
  [expanded]="panelExpanded"
  [disabled]="disabled"
  class="metrics-panel standalone-panel">
  <mat-expansion-panel-header>
  <div class="d-flex ai-center">
    <fa-icon class="expansion-panel-chevron" [class.expanded]="panel.expanded" [icon]="['fas', 'chevron-down']"></fa-icon>
    <div class="d-flex ai-center">
      <h4 class="header-title">Standalone Metrics ({{ metricsLength }})</h4>
      <fa-icon
        class="tooltip-icon"
        dynamicPortalTooltip
        [tooltipContext]="tooltipContext"
        [tooltipPosition]="tooltipPosition"
        [tooltipPositionCssClass]="'right'"
        [icon]="['fas', 'graduation-cap']">
      </fa-icon>
    </div>
  </div>
    <div *ngIf="editMode">
      <button type="button"
        mat-button color="primary"
        (click)="cancel($event)">Cancel</button>
      <button mat-flat-button
        color="primary"
        [disabled]="!isMetricChanged || !isFunnelValid"
        (click)="save($event)">Save</button>
    </div>
    <button *ngIf="!editMode"
      mat-icon-button
      class="mat-button standalone"
      [disabled]="disabled"
      [matTooltip]="'Edit'"
      [matTooltipShowDelay]="1000"
      matTooltipClass="dark-theme-tooltip simple"
      matTooltipPosition="above"
      (click)="edit($event, panel)">
      <fa-duotone-icon [icon]="['fad', 'pencil-alt']"></fa-duotone-icon>
    </button>
  </mat-expansion-panel-header>

  <div class="scroll-container"
     cdkDropList
     [cdkDropListData]="newState"
     (cdkDropListDropped)="dropMetric($event)">
    <div class="metrics-container"
      [class.edit-mode]="editMode"
    >
      <div *ngIf="newState?.length || editMode" class="metric-header">
        <span>Metrics</span>
        <span>Target</span>
      </div>
      <div class="metric-row"
           *ngFor="let metric of newState; index as i">
        <div
          class="drag-wrapper"
          cdkDrag
          [cdkDragData]="metric"
        >
          <plc-metric-master
            [metric]="metric"
            [editMode]="editMode"
            [selectTargetOnFocus]="true"
            [precision]="metric.valueType === metricValueType.Currency ? 2 : 0"
            (nameChanged)="onMetricChanged($event, i, 'name')"
            (targetChanged)="onMetricChanged($event, i, 'target')"
            (deleteMetric)="onDeleteMetricClick(metric, i)"
            (errorStateChanged)="metricErrorStateChanged($event, metric)"
            (usageCountClick)="openObjectsUsingMetric.emit($event)"
          >
          </plc-metric-master>
          <div class="drag-icon" cdkDragHandle>
            <span>&#8214;</span>
          </div>
        </div>
        <add-new-metrics-row
          [editMode]="editMode"
          [index]="i"
          (addRowAtIndex)="addMetricRow($event)"></add-new-metrics-row>
      </div>
      <add-metric-btn
        *ngIf="!newState?.length && editMode"
        class="add-metric-btn"
        (addNewMetric)="addMetricRow(0)"
      ></add-metric-btn>
    </div>
  </div>
</mat-expansion-panel>
