import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HierarchySelectItem } from '../hierarchy-select.types';
import { Configuration } from 'app/app.constants';

export interface GroupToggleEvent {
  id: string;
  collapsed: boolean;
}

@Component({
  selector: 'hierarchy-options-list',
  templateUrl: './hierarchy-options-list.component.html',
  styleUrls: ['./hierarchy-options-list.component.scss']
})
export class HierarchyOptionsListComponent {
  @Input() items: HierarchySelectItem[];
  @Input() selectedId: string;
  @Input() allowGroupSelection: boolean;
  @Input() groupsOpenState: Record<string, boolean>;
  @Output() toggleGroupState = new EventEmitter<GroupToggleEvent>();
  @Output() selectOption = new EventEmitter<HierarchySelectItem>();
  public OBJECT_TYPES = this.configuration.OBJECT_TYPES;

  constructor(
    private configuration: Configuration,
  ) {
  }

  toggleGroup(collapsed: boolean, id: string) {
    this.toggleGroupState.emit({ collapsed, id });
  }

  selectId(option: HierarchySelectItem) {
    if (!this.allowGroupSelection && option.children?.length) {
      this.toggleGroup(this.groupsOpenState[option.id], option.id);
      return;
    }
    this.selectOption.emit(option);
  }
}
