<div class="metric-details-container" *ngIf="{ selectedMetric: selectedMetric$ | async } as data">
  <ng-container *ngIf="!isLoading else loader">
    <div class="selected-metric" *ngIf="data.selectedMetric; else keyMetricHint">
      <details-metric-control-card
        [isKeyMetric]="true"
        [keyMetricSelection]="keyMetricSelection"
        [estimatedCampaignValue]="actualBusinessValue"
        [targetCampaignValue]="targetBusinessValue"
        [metric]="data.selectedMetric"
        [targetValue]="calculatedData.get(data.selectedMetric.id)?.targetValue"
        [currentValue]="calculatedData.get(data.selectedMetric.id)?.currentValue"
        [currentCPO]="calculatedData.get(data.selectedMetric.id)?.currentCPO"
        [targetCPO]="calculatedData.get(data.selectedMetric.id)?.targetCPO"
        [currency]="companyCurrency"
        [targetROI]="calculatedData.get(data.selectedMetric.id)?.targetROI"
        [currentROI]="calculatedData.get(data.selectedMetric.id)?.currentROI"
        [progressState]="performanceMetricData[data.selectedMetric.id]?.progressState"
        [estimatedDiffPercentage]="performanceMetricData[data.selectedMetric.id]?.estimatedDiffPercentage"
        [actualSpend]="totalSpend"
        [objectBudget]="objectBudget"
        (click)="openMetricDetails(data.selectedMetric.id, $event)"
        (onKeyMetricChange)="handleKeyMetricChange($event, data.selectedMetric)">
      </details-metric-control-card>
    </div>
    <div class="metrics-list">
      <ng-container *ngFor="let metric of metrics">
        <div class="metrics-list-item" *ngIf="!metric.isKeyMetric && data.selectedMetric?.id !== metric.id">
          <details-metric-control-card
            [isKeyMetric]="false"
            [keyMetricSelection]="keyMetricSelection"
            [metric]="metric"
            [targetValue]="calculatedData.get(metric.id)?.targetValue"
            [currentValue]="calculatedData.get(metric.id)?.currentValue"
            [currentCPO]="calculatedData.get(metric.id)?.currentCPO"
            [currency]="companyCurrency"
            [currentROI]="calculatedData.get(metric.id)?.currentROI"
            [progressState]="performanceMetricData[metric.id]?.progressState"
            [estimatedDiffPercentage]="performanceMetricData[metric.id]?.estimatedDiffPercentage"
            [actualSpend]="totalSpend"
            (click)="openMetricDetails(metric.id, $event)"
            (onKeyMetricChange)="handleKeyMetricChange($event, metric)"
            >
            </details-metric-control-card>
        </div>
      </ng-container>
      <div class="add-metric-list-item-button"
          [matTooltip]="!objectId | disabledAddToObjectTooltip: objectType : 'adding a Metric'"
          matTooltipClass="dark-theme-tooltip simple mb-1"
          matTooltipPosition="above">
        <button class="add-metric-btn"
          #addMetricsDropdownTrigger="matMenuTrigger"
          [matMenuTriggerFor]="metricsDropdown"
          [disabled]="isReadOnlyMode || !objectId">
          <span class="add-metric-btn-icon">
            <fa-icon class="icon default" [icon]="['fas', 'plus']"></fa-icon>
          </span>
          <span class="add-metric-btn-text">Add Metric</span>
        </button>
        <mat-menu #metricsDropdown="matMenu" [class]="'metric-masters-list'">
          <metric-masters-list
            [options]="masterMetricsSelectItems"
            [isPowerUser]="isPowerUser"
            (click)="$event.stopPropagation();"
            (closeDropdown)="closeAddMetricsDropdown()"
            (addMetrics)="addMetrics($event)"
            (navigateToFunnelsPage)="navigateToFunnelsPage()"
          ></metric-masters-list>
        </mat-menu>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #keyMetricHint>
  <div class="key-metric-hint" *ngIf="keyMetricSelection && objectId && !isReadOnlyMode">
    <span class="heart">
      <fa-icon class="icon default" [icon]="['far', 'heart']"></fa-icon>
    </span>
    <span class="hint-text">Select a Key metric to see the Estimated Campaign value</span>
  </div>
</ng-template>

<ng-template #loader>
  <loader size="22px" [isVisible]="true"></loader>
</ng-template>
