import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { MetricMappingCalculationDO } from './metric-calculation.service';
import { MetricsProgressDO } from '../../types/metrics-progress.interface';
import { UpdateMetricsSummaryDO } from 'app/home/types/update-metrics-summary-do.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { MetricProgressState } from '@shared/types/metric-progress-state.type';

export interface MetricDO {
  id?: number;
  company: number;
  name: string;
  is_custom?: boolean;
  with_currency?: boolean;
  type?: string;
  crd?: string;
  upd?: string;
  product?: number;
  revenue_per_outcome?: number;
  order?: number;
  conversion?: number;
  usage_count?: number;
  target?: number;
  is_hidden?: boolean;
}

export interface MetricDataDO {
  is_custom: boolean;
  metric_actual: number;
  metric_mapping_id: number;
  metric_name: string;
  metric_projection: number;
  total_metric_actual: number;
  total_metric_projection: number;
  order?: number;
}

export interface MetricMappingThirdPartyAmountsDO {
  salesforce?: number;
  hubspot?: number;
}

export interface MetricMappingDO {
  id: number;
  budget?: number;
  map_id: number;
  mapping_type: string;
  projection_amount: number;
  actual_amount: number;
  lastCalculationData?: MetricMappingCalculationDO;
  start_date?: string;
  milestones?: object;
  notes: string;
  crd?: string;
  upd?: string;
  metric_master: number;
  metric_detail: MetricDO;
  metric_calculations: MetricMappingCalculationDO[];
  is_inherited: boolean;
  third_party_amounts: MetricMappingThirdPartyAmountsDO;
  third_party_total_amount: number;
  order: number;
}

export interface MetricMappingGoalUpdateHistoryDO {
  campaign: string;
  change_in_value: number;
  date: string;
  upd: string;
  notes?: string;
}

export interface MetricParentChildHierarchyDO {
  goal: MetricHierarchyDO | ' ';
  campaign_details: (MetricHierarchyDO & { child_campaign ?: MetricHierarchyDO[] }) [];
}

export interface MetricHierarchyDO {
  name: string;
  map_id: number;
  metric_map_id: number;
}


export interface MetricCalculationsDO {
  ROI: number;
  CPO: number;
}

export interface MetricCalculationRequestData {
  rpo?: number;
  value: number;
  cost: number;
  revenue_to_profit?: number;
}

export interface MetricProgressTowardsTargetDO {
  cumulative_value: { [date: string]: number };
  monthly_value: { [date: string]: number };
  monthly_cpo?: { [date: string]: number };
  monthly_roi?: { [date: string]: number };
  quarterly_cpo?: { [date: string]: number };
  quarterly_roi?: { [date: string]: number };
}

export interface MetricEstimatedPerformanceData {
  estimatedDiffPercentage: number;
  progressState: MetricProgressState
}

export interface MetricMultiAssignDO {
  campaigns: string[];
  metric_masters: string[];
}

@Injectable({
  providedIn: 'root'
})
export class MetricService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    metric: 'metric',
    metricMapping: 'metric_mapping',
    metricsProgress: 'metrics_progress',
    metricsForUpdate: 'metrics_for_update',
    metricsProgressTowardsTarget: 'progress_towards_metric_target',
    metricMultiCreate: 'multi_create',
    metricMultiUpdate: 'multi_update',
    metricMultiDelete: 'multi_delete',
    metricMultiAssignCampaign: 'multi_assign_campaigns',
    metricHistoryGoal: 'update_history_goals',
    metricHierarchy: 'metric_hierarchy'
  };

  getMetrics(companyId: number, params = null): Observable<MetricDO[]> {
    return this.http.get<MetricDO[]>(
      this.apiV2Url + this.apiPaths.metric + '/',
      getRequestOptions({
        company: companyId,
        ...params
      })
    );
  }

  getMetricMappings(companyId: number, params?: object): Observable<MetricMappingDO[]> {
    return this.http.get<MetricMappingDO[]>(
      this.apiV2Url + this.apiPaths.metricMapping + '/',
      getRequestOptions({
        company: companyId,
        ...params
      })
    );
  }

  getMetricMapping(metricMappingId: number): Observable<MetricMappingDO> {
    return this.http.get<MetricMappingDO>(
      this.apiV2Url + this.apiPaths.metricMapping + '/' + metricMappingId + '/'
    );
  }

  getMetricHierarchy(metricMappingId: number): Observable<MetricParentChildHierarchyDO> {
    return this.http.get<any>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${this.apiPaths.metricHierarchy}/`,
      getRequestOptions({
        map_id: metricMappingId
      })
    );
  }

  getUpdateHistoryForGoalMetricMapping(metricMappingId: number): Observable<MetricMappingGoalUpdateHistoryDO[]> {
    return this.http.get<MetricMappingGoalUpdateHistoryDO[]>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${metricMappingId}/${this.apiPaths.metricHistoryGoal}/`
    );
  }

  createMetricMapping(mapping: Partial<MetricMappingDO>): Observable<MetricMappingDO> {
    return this.http.post<MetricMappingDO>(
      this.apiV2Url + this.apiPaths.metricMapping + '/',
      { ...mapping }
    );
  }

  updateMetricMapping(mappingId: number, mapping: Partial<MetricMappingDO>): Observable<MetricMappingDO> {
    return this.http.patch<MetricMappingDO>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${mappingId}/`,
      { ...mapping }
    );
  }

  deleteMetricMapping(mappingId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${mappingId}/`
    );
  }

  calculateValues(requestData: MetricCalculationRequestData[]): Observable<MetricCalculationsDO[]> {
    return this.http.post<MetricCalculationsDO[]>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/calculate/`,
      (requestData || []).map(
        request => ({
          rpo: request.rpo ? request.rpo : null,
          cost: request.cost,
          total_value: request.value,
          revenue_to_profit: request.revenue_to_profit ? request.revenue_to_profit : null,
          with_currency: false
        })
      )
    );
  }

  logMetricMappingView(metricMappingId: number): Observable<void> {
    return this.http.post<void>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${metricMappingId}/log_view/`,
      null
    );
  }

  getMetricMappingsProgress(budgetId: number): Observable<MetricsProgressDO> {
    return this.http.get<MetricsProgressDO>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${this.apiPaths.metricsProgress}/`,
      getRequestOptions({
        budget_id: budgetId
      })
    );
  }

  getMetricsForUpdateSummary(budgetId: number): Observable<UpdateMetricsSummaryDO> {
    return this.http.get<UpdateMetricsSummaryDO>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${this.apiPaths.metricsForUpdate}/`,
      getRequestOptions({
        budget_id: budgetId
      })
    );
  }

  getMetricProgressTowardsTarget(mappingId: number): Observable<MetricProgressTowardsTargetDO> {
    return this.http.get<MetricProgressTowardsTargetDO>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${mappingId}/${this.apiPaths.metricsProgressTowardsTarget}/`
    );
  }

  deleteMultiMetrics(ids: number[]): Observable<void> {
    return this.http.delete<void>(
      `${this.apiV2Url}${this.apiPaths.metric}/${this.apiPaths.metricMultiDelete}/`, { body: { ids } }
    );
  }

  createMultiMetrics(body: Partial<MetricDO>[]): Observable<MetricDO[]> {
    return this.http.post<Partial<MetricDO[]>>(
      `${this.apiV2Url}${this.apiPaths.metric}/${this.apiPaths.metricMultiCreate}/`,
      body
    );
  }

  updateMultiMetrics(body: Partial<MetricDO>[]): Observable<MetricDO[]> {
    return this.http.patch<MetricDO[]>(
      `${this.apiV2Url}${this.apiPaths.metric}/${this.apiPaths.metricMultiUpdate}/`,
      body
    );
  }

  bulkAssignMetricToCampaign(body: MetricMultiAssignDO): Observable<number[]> {
    return this.http.post<number[]>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${this.apiPaths.metricMultiAssignCampaign}/`,
      body
    );
  }

  bulkDeleteMetricMapping(ids: number[]): Observable<void> {
    return this.http.delete<void>(
      `${this.apiV2Url}${this.apiPaths.metricMapping}/${this.apiPaths.metricMultiDelete}/`,
      { body: { ids } }
    );
  }
}
