import { ChangeDetectionStrategy, Component, EventEmitter, NgZone, Output } from '@angular/core';
import { UtilityService } from '@shared/services/utility.service';
import {
  ACCEPTED_EXTENSIONS,
  ERROR_MESSAGES,
  InvoiceUploadManagerService
} from '@spending/services/invoice-upload-manager.service';

@Component({
  selector: 'invoice-upload-overlay',
  templateUrl: './invoice-upload-overlay.component.html',
  styleUrls: ['./invoice-upload-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceUploadOverlayComponent {
  @Output() filesDrop = new EventEmitter<File[]>();

  constructor(
    private readonly invoiceUploadManager: InvoiceUploadManagerService,
    private readonly utilityService: UtilityService,
    private readonly zone: NgZone
  ) { }

  onDrop(event: DragEvent): void {
    const droppedFiles = this.getFilesFromEvent(event);
    const validFiles = droppedFiles.filter(file => {
      const extension = '.' + file.name.split('.').pop().toLowerCase();
      return ACCEPTED_EXTENSIONS.includes(extension);
    });

    this.zone.run(() => {
      if (!validFiles.length) {
        this.utilityService.showToast({ Message: ERROR_MESSAGES.UNSUPPORTED_FORMAT });
        return;
      }
      if (validFiles.length < droppedFiles.length) {
        this.utilityService.showToast({ Message: ERROR_MESSAGES.UNSUPPORTED_FORMAT_PARTIALLY });
      }

      this.invoiceUploadManager.uploadFiles(validFiles, true)
        .subscribe({
          error: error => {
            this.utilityService.showToast({ Message: error.message });
          }
        });
    });
  }

  private getFilesFromEvent(event: DragEvent): File[] {
    let droppedFiles = [];
    if (event.dataTransfer?.items) {
      Array.from(event.dataTransfer.items).forEach((item, i) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          droppedFiles.push(file);
        }
      });
    } else {
      droppedFiles = Array.from(event.dataTransfer?.files);
    }

    return droppedFiles;
  }
}
