import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'rights-reserved',
  templateUrl: './rights-reserved.component.html',
  styleUrls: ['./rights-reserved.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightsReservedComponent {

  currentYear = new Date().getFullYear();

}
