<span>
  All {{
    isTotalSelected ? totalSelectionNumber : pageSelectionNumber
  }} {{ viewMode === spendingModes.Expenses ? 'Expenses' : 'Invoices' }} on {{ isTotalSelected ? 'all pages' : 'this page'}} are selected.
</span>
<link-button class="selection-toggle" (onClick)="toggleSelected()">
  <fa-icon [icon]=pageIcon[viewMode]></fa-icon>
  <ng-container *ngIf="!isTotalSelected; else clearText">
    Select all {{ totalSelectionNumber }} {{ viewMode === spendingModes.Expenses ? 'expenses' : 'invoices' }} on all pages
  </ng-container>
  <ng-template #clearText>Clear selection</ng-template>
</link-button>
