import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ManageCegPageRowIntersectionService implements OnDestroy {
  private tableBody: ElementRef;
  private readonly destroy$ = new Subject<void>();
  private readonly _refreshTable$ = new Subject<void>();
  public readonly refreshTable$ = this._refreshTable$.asObservable();
  private _cellsVisibilityState: Record<string, boolean> = {};
  private rowObserver: IntersectionObserver;

  public destroyRowsListener(): void {
    this.rowObserver?.disconnect();
    this.rowObserver = null;
  }

  public initRowsListener(container: ElementRef, tableBody: ElementRef): void {
    this.tableBody = tableBody;
    this.destroyRowsListener();

    const observerCallback = (entries: IntersectionObserverEntry[]): void => {
      entries.forEach(elem => {
        const targetRow = elem.target.parentElement;
        const isRowVisible = elem.isIntersecting;
        setTimeout(() => {
          this._cellsVisibilityState[targetRow.dataset.id] = isRowVisible;
          this._refreshTable$.next();
        }, 0);
      });
    }
    const options = { root: container.nativeElement, rootMargin: '400px' };
    this.rowObserver = new IntersectionObserver(observerCallback, options);

    const tableRows = this.tableBody.nativeElement.children; // get all visible rows (includes expanded children)
    this.registerRowIntersectionListener(tableRows);
  }

  private registerRowIntersectionListener(tableRows: HTMLCollection): void {
    if (!tableRows) {
      console.warn('Can\'t find table rows!');
      return;
    }
    const triggerRowArray = [];
    for (const row of tableRows) {
      triggerRowArray.push(row.querySelectorAll('td')[0]); // get first <td> (name column) as it is always visible on the screen
    }
    triggerRowArray.forEach(el => {
      this.rowObserver.observe(el)
    });
  }

  public setCellsVisibilityState(recordId: string, state: boolean): void {
    this._cellsVisibilityState[recordId] = state;
  }

  public get cellsVisibilityState(): Record<string, boolean> {
    return this._cellsVisibilityState;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
