import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpenseAllocationMode } from '../../types/expense-allocation-mode.type';
import { AbstractSelectOption } from '../../types/select-option.interface';
import { DecimalPipe } from '@angular/common';
import { ContextStatusListOptions, ExpenseStatusSelectOptions, StatusSelectEvent } from 'app/expenses/types/expense-page.type';


@Component({
  selector: 'expense-status-select',
  templateUrl: './expense-status-select.component.html',
  styleUrls: ['./expense-status-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseStatusSelectComponent {
  public statusList: AbstractSelectOption<string>[] = [];
  public contextOptions: ExpenseStatusSelectOptions;

  @Output() statusUpdate = new EventEmitter<StatusSelectEvent>();
  @Input() set statusSelectOptions (options: ExpenseStatusSelectOptions) {
    if (!options) {
      return;
    }
    this.contextOptions = options;
    this.statusList = ExpenseStatusSelectComponent.getContextStatusList(options, this.decimalPipe);
  };

  constructor(
    private readonly decimalPipe: DecimalPipe,
  ) {
  }

  public select(e: MouseEvent, statusValue: string) {
    if (this.contextOptions.status === statusValue) {
      e.stopPropagation();
      return;
    }
    this.handleStatusUpdate(statusValue);
  }

  handleStatusUpdate(statusValue: string) {
    const splitStatusValue = statusValue.split('_');
    const newStatus = splitStatusValue[0] as ExpenseAllocationMode;
    const eventData: StatusSelectEvent = {
      expenseId: this.contextOptions.expenseId,
      status: newStatus,
    };
    const shouldResetActual = this.contextOptions.status !== ExpenseAllocationMode.Planned &&
      newStatus === ExpenseAllocationMode.Planned &&
      !this.contextOptions.isTimeframeLocked;
    if (splitStatusValue.length > 1 || shouldResetActual) {
      eventData.setActual = shouldResetActual ? 0 : this.contextOptions.planned;
    }
    this.statusUpdate.emit(eventData);
  }

  private static getContextStatusList(options: ContextStatusListOptions, decimalPipe: DecimalPipe): AbstractSelectOption<string>[] {
    const transformNum = (num: number) => decimalPipe.transform(num, '1.2-2');
    let expenseStatusList: AbstractSelectOption<string>[] = Object.values(ExpenseAllocationMode)
      .map(mode => ({ id: mode, name: mode, iconCssClass: mode.toLowerCase()}));
    if (!options.isTimeframeLocked && options.status !== ExpenseAllocationMode.Planned && options.actual) {
      const plannedItem = expenseStatusList.find(item => item.id === ExpenseAllocationMode.Planned);
      plannedItem.name += ` (set Actual to ${transformNum(0)})`;
    }
    if (
      options.isTimeframeLocked ||
      options.status === ExpenseAllocationMode.Closed ||
      options.actual ||
      !options.planned
    ) {
      return expenseStatusList;
    } else {
      expenseStatusList = expenseStatusList.flatMap(
        status => {
            if (status.id === ExpenseAllocationMode.Closed ||
              (status.id === ExpenseAllocationMode.Committed && options.status === ExpenseAllocationMode.Planned)
            ) {
              const name = status.id === ExpenseAllocationMode.Closed ? 'Close' : 'Commit';

              const withPlannedAmount = {
                iconCssClass: status.id.toLowerCase(),
                name: `${name} at ${options.sourceCurrency}`,
                nameSuffix: transformNum(options.planned),
                id: status.id + '_at_planned',
              };
              const withNullValue = {
                iconCssClass: status.id.toLowerCase(),
                name: `${name} at ${options.sourceCurrency}`,
                nameSuffix: transformNum(0),
                id: status.id,
              };
              return [withPlannedAmount, withNullValue];
            } else {
              return [status];
            }
          }
        );
    }
    return expenseStatusList;
  }
}
