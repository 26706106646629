import { Component, HostBinding, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss']
})
export class SelectOptionComponent {
  @Input() title: string;
  @Input() prefix: string;
  @Input() isGroupOption = false;
  @Input() highlightedOption = false;
  @Input() isCollapsed = false;
  @Input() isChecked = false;
  @Input() indeterminate = false;
  @Input() disabled = false;

  @Input()
  @HostBinding('class.visually-hidden') isHidden = false;

  @Input()
  @HostBinding('class.single-select-mode') singleSelectMode = false;

  @Output() toggleGroupCollapse = new EventEmitter<boolean>();
  @Output() selectionChanged = new EventEmitter<boolean>();

  @HostListener('click', ['$event']) onItemClick(event) {
    if (this.singleSelectMode) {
      if (this.isGroupOption) {
        event.stopPropagation();
        this.toggleGroup();
      } else {
        this.selectionChanged.emit();
      }
    }
  }

  toggleGroup() {
    this.toggleGroupCollapse.emit(this.isCollapsed);
  }

  onSelectionChange(e: boolean) {
    this.selectionChanged.emit(e);
  }
}
