import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Budget } from '../../types/budget.interface';
import { BudgetTimeframe } from '../../types/timeframe.interface';
import { defineTimeframe } from '../../utils/budget.utils';

@Component({
  template: ''
})
export abstract class TimeframeProcessingComponent implements OnChanges {
  @Input() budget: Budget;
  @Input() timeframes: BudgetTimeframe[] = [];

  currentTimeframe: BudgetTimeframe;
  highlightedTimeframes: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    const requiredTimeframeChanges = changes.timeframes || changes.budget;
    const requiredTimeframeData = this.timeframes && this.budget;
    if (requiredTimeframeChanges && requiredTimeframeData) {
      this.processTimeframesData();
    }
  }

  private processTimeframesData(): void {
    const timeframesCount = this.timeframes.length;
    const highlightGroupSize = Math.floor(timeframesCount / 4);

    this.timeframes.forEach((tf, index) => {
      const groupIndex = Math.floor(index / highlightGroupSize);
      if (groupIndex % 2 === 0) {
        this.highlightedTimeframes.push(tf.id);
      }
    });
    this.currentTimeframe = defineTimeframe(this.timeframes, this.budget);
  }
}
