export const baseCurrencyMetricName = 'Revenue';
export const objectPlaceholderName = '{object}';

export enum MetricUnit {
  METRIC = 'Metric',
  FUNNEL = 'Funnel'
}

export const DELETE_OBJECT = {
  TITLE: `Delete ${objectPlaceholderName}`,
  PRODUCT_CONTENT: 'Metrics from this Funnel are in use in your Campaigns. Are you sure, you want to proceed? Because you will loose everything.',
  SUBMIT_BUTTON_LABEL: `Delete ${objectPlaceholderName}`,
  METRIC_CONTENT: 'This Metric is in use. Deleting it will change your calculations. This action is irreversible. Are you sure, you want to proceed?',
  CHECK_WORD: 'DELETE',
  CONFIRM_QUESTION: `Are you sure, you want to delete this ${objectPlaceholderName}?`
}
