<div *ngIf="recordData" class="metric-value-record record-grid" [class.short-record-grid]="showHistoryExpanded" [class.end-of-month]="isMonthTotal" [formGroup]="recordFormData"  [ngStyle]="{'column-gap': showHistoryExpanded ? '0' : '16px'}">
  <span class="iconic" *ngIf="showHistoryExpanded">
    <button *ngIf="recordData.monthChildItems?.length" class="mat-button" (click)="toggleMonth.emit()" mat-icon-button>
      <fa-icon [icon]="['far', 'chevron-down']" [flip]="monthExpanded && 'vertical'"></fa-icon>
    </button>
  </span>
  <span class="iconic"
        [matTooltip]="tooltipText"
        [ngStyle]="{ color: isOriginObjectRecord ? '#061F38' : '#0063B7'}"
        matTooltipClass="dark-theme-tooltip left"
        matTooltipPosition="left">
    <icon-facebook *ngIf="recordData.type === MetricValueUpdateType.facebookAds"></icon-facebook>
    <icon-linkedin *ngIf="recordData.type === MetricValueUpdateType.linkedinAds"></icon-linkedin>
    <icon-google-ads *ngIf="recordData.type === MetricValueUpdateType.googleAds"></icon-google-ads>
    <icon-salesforce *ngIf="recordData.type === MetricValueUpdateType.salesForce"></icon-salesforce>
    <fa-icon *ngIf="recordData.type === MetricValueUpdateType.hubSpot" class="navy-blue-icon" [icon]="faActualIcon"></fa-icon>
    <button *ngIf="useDuotoneIcon"
      mat-icon-button
      class="mat-button nav-item"
      [disabled]="isCreation || !hasClickAction"
      (click)="openObjectDetailsClick()"
      >
      <fa-duotone-icon [icon]="faActualIcon" [class.end-of-month-icon]="isMonthTotal"></fa-duotone-icon>
    </button>
  </span>
  <span class="details-form-field">
    <span class="month-name" *ngIf="isMonthTotal">{{ nameOfEndedMonth }} Total</span>
    <span class="update-on-date" *ngIf="!isCreation && !isMonthTotal">
      <ng-template [ngIf]="recordFormData.get('date').value" [ngIfElse]="datePlaceholder">
        {{ recordFormData.get('date').value | date: 'MMM d, yyyy' }}
      </ng-template>
      <ng-template #datePlaceholder>-</ng-template>
    </span>
    <mat-form-field *ngIf="isCreation" appearance="fill" class="date-picker">
      <span class="date-input" (click)="datePicker.open()" >
        <input
          matInput
          disabled
          [matDatepicker]="datePicker"
          autocomplete="off"
          formControlName="date"
          [max]="todayDate"
          [matDatepickerFilter]="checkIfDateAvailable"
          placeholder="Choose a date"
        />
      </span>
      <mat-datepicker-toggle matSuffix [for]="datePicker">
        <fa-duotone-icon [icon]="['fad', 'calendar-day']" matDatepickerToggleIcon></fa-duotone-icon>
      </mat-datepicker-toggle>
      <mat-datepicker
        [disabled]="false"
        [startAt]="todayDate"
        #datePicker
        panelClass="metric-editor-date-picker"
        [dateClass]="datepickerClassNames"
        (opened)="refreshActiveCell('date', true)"
        (closed)="refreshActiveCell('date', false)"
      ></mat-datepicker>
    </mat-form-field>
  </span>
  <span class="details-form-field numeric" [class.highlight-negative]="changeInValue.value < 0">
    <mat-form-field appearance="fill" class="no-validate">
      <!-- Trick to have a '-' placeholder  for field -->
      <input *ngIf="changeInValue.value == null && activeCell !== 'changeInValue'" matInput [selectTextOnFocus]="true" formControlName="changeInValue" placeholder="-" (focus)="refreshActiveCell('changeInValue', true)"/>
      <input *ngIf="changeInValue.value != null || activeCell === 'changeInValue'" matInput [selectTextOnFocus]="true" blurOnEnterKeydown formControlName="changeInValue" [autoFocus]="isCreation" currencyInput currencyMask [options]="amountFieldOpt" (focus)="onFocusAmountField('changeInValue')" (blur)="refreshActiveCell('changeInValue', false)" />
    </mat-form-field>
  </span>
  <span class="details-form-field numeric" [class.highlight-negative]="runningTotal.value < 0">
    <mat-form-field appearance="fill" class="no-validate">
      <!-- Trick to have a '-' placeholder  for field -->
      <input *ngIf="runningTotal.value == null && activeCell !== 'runningTotal'" matInput [selectTextOnFocus]="true" formControlName="runningTotal" placeholder="-" (focus)="refreshActiveCell('runningTotal', true)"/>
      <input *ngIf="runningTotal.value != null || activeCell === 'runningTotal'" matInput [selectTextOnFocus]="true" blurOnEnterKeydown formControlName="runningTotal" [autoFocus]="isCreation" currencyInput currencyMask [options]="amountFieldOpt" (focus)="onFocusAmountField('runningTotal')" (blur)="refreshActiveCell('runningTotal', false)" />
    </mat-form-field>
  </span>
  <span class="update-on-date">
    <ng-template [ngIf]="recordData.updatedDate" [ngIfElse]="datePlaceholder">
      {{ recordData.updatedDate | date: 'MMM d, yyyy'}}
    </ng-template>
    <ng-template #datePlaceholder>-</ng-template>
  </span>
  <ng-container *ngIf="isMonthTotal">
    <span class="iconic"></span>
    <span class="iconic" *ngIf="!showHistoryExpanded">
      <button *ngIf="recordData.monthChildItems?.length" class="mat-button" (click)="toggleMonth.emit()" mat-icon-button>
        <fa-icon [icon]="['far', 'chevron-down']" [flip]="monthExpanded && 'vertical'"></fa-icon>
      </button>
    </span>
  </ng-container>
  <ng-container *ngIf="!isMonthTotal">
    <span class="iconic" *ngIf="recordFormData.get('date').value && !isReadOnly && recordData.type === MetricValueUpdateType.campaign">
      <metric-editor-notes [notesControl]="recordFormData.get('notes')" (onFocus)="refreshActiveCell('notes', true)" (onBlur)="refreshActiveCell('notes', false)"></metric-editor-notes>
    </span>
    <span class="iconic" *ngIf="!isCreation">
      <button *ngIf="!isMonthTotal && recordData.isRemovable && recordData.date && !isReadOnly" class="mat-button delete" mat-icon-button (click)="removeRecord.emit()">
        <fa-duotone-icon [icon]="['fad', 'trash-alt']"></fa-duotone-icon>
      </button>
    </span>
  </ng-container>
</div>
