import { Component, Input, OnChanges, OnInit, SimpleChanges  } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { graphCellData } from './bar-graph-UI-data';

@Component({
  selector: 'bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.scss']
})
export class BarGraphComponent implements OnInit, OnChanges {
    @Input() graphData;
    @Input() currencySymbol: string;
    @Input() selectedStatusValues;
    @Input() excludedValues: string[] = [];
    public graph = [];

    constructor(
      private readonly configuration: Configuration,
    ) {}

    ngOnInit() {
      if (this.graphData) {
        this.graph = this.generateGraph();
      }
    }

    ngOnChanges(changes: SimpleChanges) {
      if ('graphData' in changes || 'selectedStatusValues' in changes) {
        this.graph = this.generateGraph();
      }
    }

    generateGraph() {
      if (!this.graphData) {
        return [];
      }
      const totalAmount: number = this.calcTotalAmount();
      return Object.keys(graphCellData).reduce((graph, status) => {
        const excluded = this.excludedValues.includes(status);
        if (status !== 'total' && this.graphData[status] > 0 && !excluded) {
          const { className: barCellClass, camelCaseName } = graphCellData[status];
          const title = this.configuration.statusNames[camelCaseName];
          const amount = parseFloat(this.graphData[status]).toFixed(2);
          const className = `${barCellClass} ${this.getHighlightClass(title)}`;
          const percentage = this.getGraphPercentage(status, totalAmount);
          graph = [
            ...graph,
            { status, className, title, percentage, amount }
          ]
        }
          return graph;
      }, []);
    }

    calcTotalAmount() {
      const { statusFields: { closed, committed, planned, underBudget, remainingAllocated } } = this.configuration;
      const totalCalcKeys = [closed, committed, planned, remainingAllocated, underBudget];
      return totalCalcKeys.reduce((sum, key) => {
          const val = this.graphData[key] ? parseFloat(this.graphData[key]) : 0;
          return sum + val;
      }, 0);
    }

    getGraphPercentage(status, totalAmount) {
      const actualAmount: number = this.graphData[status];
      if (totalAmount === 0 && actualAmount === 0) {
          return 0;
      }
      return Math.round(parseFloat('' + actualAmount) / parseFloat('' + totalAmount) * 100);
    }

    getHighlightClass(status: string) {
      const { statusNames } = this.configuration;
      status = [statusNames.overBudget, statusNames.underBudget, statusNames.overSpend].includes(status) ? '' : status;
      const selectedValuesExist = this.selectedStatusValues && this.selectedStatusValues.length;
      const isHighlighted = selectedValuesExist && !this.selectedStatusValues.includes(status);
      return isHighlighted ? 'lowest-opacity' : '';
  }
}
