import { Component, Input } from '@angular/core';

@Component({
  selector: 'budget-settings-summary-chart',
  templateUrl: './budget-settings-summary-chart.component.html',
  styleUrls: ['./budget-settings-summary-chart.component.scss']
})
export class BudgetSettingsSummaryChartComponent {
  @Input() isOverBudget = false;
  @Input() allocatedPercentage = 0;
}
