<div class="task-name">
  <div class="control"
    (click)="handleFocus();"
    [ngClass]="{
      'edit-mode': editMode,
      'is-readonly': isReadOnly
    }"
  >
    <textarea
      placeholder="Task name"
      textFieldAutosize
      maxlength="150"
      minRows="1"
      [maxHeight]="28"
      [minHeight]="14"
      [ngModel]="value"
      (change)="handleValueChanged($event)"
      (blur)="handleBlur()"
      #textarea
      *ngIf="!isReadOnly && (editMode || !value)"
    ></textarea>
    <div
      class="read-value"
      *ngIf="isReadOnly || (!editMode && value)"
      [matTooltip]="value"
      [matTooltipDisabled]="!isReadOnly"
      matTooltipClass="dark-theme-tooltip above multi-line"
      matTooltipPosition="above"
    >
      {{ value }}
    </div>
  </div>
</div>
