import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ExtraExpenseEmailBody } from '@shared/types/expense.interface';

@Component({
  selector: 'invoice-email',
  templateUrl: './invoice-email.component.html',
  styleUrls: ['./invoice-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceEmailComponent {
  @Input() emailBody: ExtraExpenseEmailBody;

  getEmailBody(content: string): SafeHtml {
    return content;
  }

}
