export enum CampaignTypeNames {
  ACCOUNT_BASED_MARKETING = 'Account Based Marketing',
  ADVERTISING_DIGITAL = 'Advertising | Digital',
  ADVERTISING_OUT_OF_HOME = 'Advertising | Out of Home',
  ADVERTISING_RADIO = 'Advertising | Radio',
  ADVERTISING_TV = 'Advertising | TV',
  BRANDING = 'Branding',
  CONTENT_MARKETING = 'Content Marketing',
  CUSTOMER_ADVOCACY = 'Customer Advocacy',
  CUSTOMER_MARKETING = 'Customer Marketing',
  DIRECT_MAIL = 'Direct Mail',
  EVENT_DIGITAL = 'Event | Digital',
  EVENT_SEMINAR = 'Event | Seminar',
  EVENT_TRADE_SHOW = 'Event | Trade Show',
  GOOGLE_ADS = 'Google Ads',
  GUERRILLA_MARKETING = 'Guerrilla Marketing',
  INFLUENCER_MARKETING = 'Influencer Marketing',
  INTEGRATED_CAMPAIGN = 'Integrated Campaign',
  OTHER = 'Other',
  PARTNER = 'Partner',
  PR = 'PR',
  PRODUCT_LAUNCH = 'Product Launch',
  SALES_CONTEST = 'Sales Contest',
  SEARCH_ENGINE_MARKETING = 'Search Engine Marketing',
  SOCIAL_MEDIA = 'Social Media',
  SWEEPSTAKES = 'Sweepstakes',
  TELEMARKETING = 'Telemarketing',
  LINKEDIN_ADS = 'LinkedIn Ads'
}
