import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CustomFilterMode, CustomFilterModeType, FilterManagementService } from '../filter-services/filter-management.service';
import { faLink } from '@fortawesome/pro-duotone-svg-icons';

export interface CustomModeConfig {
  icon: IconProp;
  name: string;
}

@Component({
  selector: 'custom-filter-mode',
  templateUrl: './custom-filter-mode.component.html',
  styleUrls: ['./custom-filter-mode.component.scss']
})
export class CustomFilterModeComponent {
  private readonly customModes: { [modeType in CustomFilterModeType]: CustomModeConfig } =  {
    [CustomFilterModeType.RelatedExpenses]: {
      icon: faLink,
      name: 'Related Expenses Mode'
    }
  };

  private _currentMode: CustomFilterMode;
  public currentModeConfig: CustomModeConfig;

  @Input() set mode(mode: CustomFilterMode) {
    this._currentMode = mode;
    this.currentModeConfig = this.customModes[mode?.modeType];
  }

  constructor(public readonly filterManagementService: FilterManagementService) {}

  exitMode() {
    this.filterManagementService.customFilterMode = null;
  }
}
