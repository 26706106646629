<div class="details-status-bar">
  <div class="header">
    <details-budget-amount
      [currency]="currency"
      [amount]="graphData?.total || 0"
      [title]="allocationAmountTitle"
    ></details-budget-amount>
    <details-budget-amount
      [currency]="currency"
      [amount]="totalSpend || 0"
      [title]="isGoal ? 'Sum of Spend' : 'Total Spend'"
    ></details-budget-amount>
    <details-budget-amount
      [currency]="currency"
      [amount]="preparedGraphData?.overspend"
      [isOverspend]="true"
      *ngIf="!isGoal && preparedGraphData?.overspend > 0; else remaining"
      title="Overspend"
    ></details-budget-amount>
    <ng-template #remaining>
      <details-budget-amount
        [currency]="currency"
        [amount]="preparedGraphData?.remaining_allocated || 0"
        *ngIf="preparedGraphData?.remaining_allocated !== null"
        [title]="isGoal ? 'Sum of Remaining Allocated' : 'Remaining Allocated'"
      ></details-budget-amount>
    </ng-template>
  </div>
  <div class="bar">
    <bar-graph [graphData]="preparedGraphData" [currencySymbol]="currency" [selectedStatusValues]="selectedStatusValues" [excludedValues]="excludedGraphValues"></bar-graph>
    <div class="amounts-tooltip arrow-down" *ngIf="objectId">
      <budget-amounts-summary [data]="summaryTooltipData"></budget-amounts-summary>
    </div>
  </div>
</div>
