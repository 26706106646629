<div
  class="object-name-cell"
  [matTooltip]="name"
  [matTooltipDisabled]="!withTooltip || dragging"
  matTooltipClass="dark-theme-tooltip above multi-line"
  matTooltipPosition="above"
  [matTooltipShowDelay]="2000"
  (click)="handleClick()"
  [class.is-clickable]="isClickable"
  [class.closed]="isClosed"
>
  <div [ngSwitch]="objectTypeName" class="icon-wrapper">
    <icon-facebook *ngSwitchCase="externalTypes.FacebookAds" class="custom-integration-icon icon"></icon-facebook>
    <icon-linkedin *ngSwitchCase="externalTypes.LinkedinAds" class="custom-integration-icon icon"></icon-linkedin>
    <icon-google-ads *ngSwitchCase="externalTypes.GoogleAds" class="custom-integration-icon icon"></icon-google-ads>
    <div class="icon" *ngSwitchDefault [class.closed]="isClosed && rowType !== manageTableRowType.ExpenseGroup">
      <fa-icon [icon]="icon" *ngIf="!isClosed else closedIcon"></fa-icon>
      <ng-template #closedIcon>
        <fa-icon [icon]="rowType === manageTableRowType.ExpenseGroup ? icon : [ 'far', 'rocket' ]"></fa-icon>
      </ng-template>
    </div>
  </div>

  <div class="name">
    {{ name }}
  </div>
</div>
<ng-content></ng-content>
