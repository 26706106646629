<div class="modal-container" [ngClass]="panelClass">
  <header>
    <i class="top-row" [class.redHeader]="redHeader"></i>
    <span class="center-icon">
      <fa-icon class="primary-icon" [class.redHeader]="redHeader" [icon]="[data.icon.prefix, data.icon.name]"></fa-icon>      
      <fa-icon *ngIf="secondaryIcon" class="secondary-icon" [icon]="['fas', 'hand-pointer']"></fa-icon>
    </span>
  </header>
  <div class="modal-content">
    <h1 class="modal-title">{{data.title}}</h1>
    <p class="modal-message" [innerHTML]="data.content"></p>
    <div *ngIf="customContent" class="custom-content">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </div>
  <footer>
    <button mat-button color="primary" (click)="onCancel()">Cancel</button>
    <button *ngFor="let button of data.buttons" mat-flat-button [color]="button.color" [disabled]="button.disabled"
      (click)="onSubmit(button.text)">
      {{ button.text }}
    </button>
  </footer>
</div>