<form id="form-addGoalDetail" class="row-addGoal modal-form" [formGroup]='superadminForm' (ngSubmit)="saveSuperadmin()" novalidate>
  <header mat-dialog-title>{{ title }}</header>
  <div mat-dialog-content class="dialog-info">
    <div class="row-holder">
      <div class="row-cell">
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>First Name</mat-label>
          <input matInput
           formControlName="first_name"
           class="text-left"
           required
           maxlength="128"/>
          <mat-error *ngIf="first_name.touched && !first_name.valid && first_name.errors?.required">
            {{ validations.ValiditionMessages.FIRST_NAME_REQUIRED }}
          </mat-error>
          <mat-error *ngIf="first_name.errors && first_name.errors.trim">
            {{ validations.ValiditionMessages.FIRST_NAME_REQUIRED }}
          </mat-error>
          <mat-error *ngIf="first_name.dirty && first_name.errors?.pattern">
            {{ validations.ValiditionMessages.FIRST_NAME_ONLY_CHARS }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row-cell">
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>Last Name</mat-label>
          <input matInput
           formControlName="last_name"
           class="text-left"
           required
           maxlength="128"/>
          <mat-error *ngIf="last_name.touched && !last_name.valid && last_name.errors?.required">
            {{ validations.ValiditionMessages.LAST_NAME_REQUIRED }}
          </mat-error>
          <mat-error *ngIf="last_name.errors && last_name.errors.trim">
            {{ validations.ValiditionMessages.LAST_NAME_REQUIRED }}
          </mat-error>
          <mat-error *ngIf="last_name.dirty && last_name.errors?.pattern">
            {{ validations.ValiditionMessages.LAST_NAME_ONLY_CHARS }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row-holder">
      <div class="row-cell">
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>Email</mat-label>
          <input matInput
            formControlName="email"
            class="text-left"
            required
            maxlength="128"
            readonly="{{ !!superadminDetails.id }}"/>
          <mat-error *ngIf="email.touched && !email.valid && email.errors?.required">
            {{ validations.ValiditionMessages.EMAIL_ID_REQUIRED }}
          </mat-error>
          <mat-error *ngIf="email.dirty && email.errors?.pattern">
            {{ validations.ValiditionMessages.EMAIL_INCORRECT_FORMAT }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row-cell">
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>Select Type</mat-label>
          <mat-select class="currency-select"
            formControlName="user_type"
            required>
            <mat-option *ngFor="let item of superAdminTypes" [value]="item.value">
              {{item.label}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="user_type.touched && !user_type.valid && user_type.errors?.required">
            {{ validations.ValiditionMessages.USER_TYPE_REQUIRED }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end" class="dialog-actions">
    <button type="button" mat-button color="primary" mat-dialog-close>Close</button>
    <button type="submit" mat-flat-button color="primary" [disabled]="!superadminForm.valid">Finish</button>
  </div>
</form>
