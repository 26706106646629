import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, inject, ViewChild } from '@angular/core';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { BudgetObjectService } from '@shared/services/budget-object.service';
import { TagsControlComponent } from '@shared/components/tags-control/tags-control.component';
import { DataValidationService } from 'app/budget-object-details/services/data-validation.service';

export enum DrawerFormFields {
  name = 'name',
  segment = 'segment',
  ownerId = 'ownerId',
  startDate = 'startDate',
  endDate = 'endDate',
  typeId = 'typeId',
  glCode = 'glCode',
  poNumber = 'poNumber',
  invoiceNumber = 'invoiceNumber',
  vendorId = 'vendorId',
  vendorName = 'vendorName',
  currencyCode = 'currencyCode',
  location = 'location',
  notes = 'notes',
  deliveryDate = 'deliveryDate',
  amount = 'amount',
  sourceAmount = 'sourceAmount',
  amountStatus = 'amountStatus',
  sourceActualAmount = 'sourceActualAmount',
  customType = 'customType',
  targetAudience = 'targetAudience',
  messaging = 'messaging',
  status = 'status',
  budgetAllocationId = 'budgetAllocationId',
  isVerified = 'isVerified',
  revenuePerOutcome = 'revenuePerOutcome',
  milestones = 'milestones',
  updated = 'updated',
  funnelName = 'funnelName',
  isKeyMetric = 'isKeyMetric',
  metricUnit = 'metricUnit',
  mappingType = 'mappingType'
}

@Component({
  template: '',
})
export abstract class DetailsDrawerFormComponent {
  protected readonly fb = inject(UntypedFormBuilder);
  protected readonly dataValidationService = inject(DataValidationService);

  protected abstract formConfig: Record<string, any>;
  public formData: UntypedFormGroup;
  @ViewChild('tagsControl') tagsControl: TagsControlComponent;

  protected createForm() {
    if (!this.formConfig) {
      console.warn('Missing config for the drawer\'s form.');
      return;
    }
    this.formData = this.fb.group(this.formConfig);
  }

  protected get fdStartDateControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.startDate);
  }

  protected get fdEndDateControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.endDate);
  }

  protected get fdNameControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.name);
  }

  protected get fdCurrencyControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.currencyCode);
  }

  protected get fdCurrency(): string {
    return this.fdCurrencyControl?.value;
  }

  protected get fdSegmentControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.segment);
  }

  protected get fdSegment(): string {
    return this.fdSegmentControl?.value;
  }

  protected get fdPlannedAmount(): number {
    return this.formData?.get(DrawerFormFields.sourceAmount)?.value;
  }

  protected get fdSourceActualAmountControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.sourceActualAmount);
  }

  protected get fdSourceActualAmount(): number {
    return this.fdSourceActualAmountControl?.value;
  }

  protected get fdBudgetAllocationId(): number {
    return this.formData?.get(DrawerFormFields.budgetAllocationId)?.value;
  }

  protected get fdStatusControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.status);
  }

  protected get fdStatus(): string {
    return this.fdStatusControl?.value;
  }

  protected get fdGlCode(): number {
    return this.formData?.get(DrawerFormFields.glCode)?.value;
  }

  protected get fdPoNumber(): string {
    return this.formData?.get(DrawerFormFields.poNumber)?.value;
  }

  protected get fdVendorName(): string {
    return this.formData?.get(DrawerFormFields.vendorName)?.value;
  }

  protected get fdInvoiceNumber(): string {
    return this.formData?.get(DrawerFormFields.invoiceNumber)?.value;
  }

  protected get fdOwnerIdControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.ownerId);
  }

  protected get fdOwnerId(): number {
    return this.fdOwnerIdControl?.value;
  }

  protected get fdTypeIdControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.typeId);
  }

  protected get fdTypeId(): number {
    return this.fdTypeIdControl?.value;
  }

  protected get fdDeliveryDate(): string {
    return this.formData?.get(DrawerFormFields.deliveryDate)?.value;
  }

  protected get fdNotes(): string {
    return this.formData?.get(DrawerFormFields.notes)?.value;
  }

  protected get fdLocation(): string {
    return this.formData?.get(DrawerFormFields.location)?.value;
  }

  protected get fdLocationControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.location);
  }

  protected get fdAmountStatusControl(): AbstractControl {
    return this.formData?.get(DrawerFormFields.amountStatus);
  }

  protected get fdAmountStatus(): string {
    return this.fdAmountStatusControl?.value;
  }

  protected applyExternalIntegrationToForm(fieldsToDisable: DrawerFormFields[], objectTypes: BudgetObjectType[], currentTypeId: number): {
    integrationTypeIds: number[];
    integrationTypeSelected: boolean;
    filteredObjectTypes: BudgetObjectType[];
  } {
    const resp = BudgetObjectService.processIntegrationObjectTypes(objectTypes, currentTypeId);
    if (resp.integrationTypeSelected) {
      fieldsToDisable.forEach(fieldName => this.formData?.get(fieldName)?.disable({ emitEvent: false }));
    }
    return resp;
  }

  public validateChanges(): boolean {
    this.updateControlsValidity();
    if (this.formData.valid) {
      return true;
    }
    this.dataValidationService.validateFormFields(this.formData);
    return false;
  }

  // updateValueAndValidity for controls with dynamic validation rules (like Segment for Campaigns)
  protected updateControlsValidity(): void {
    // default implementation
  }

}
