import { Component, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { HierarchySelectItem } from '../hierarchy-select.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'hierarchy-option-content',
  templateUrl: './hierarchy-option-content.component.html',
  styleUrls: ['./hierarchy-option-content.component.scss']
})
export class HierarchyOptionContentComponent {
  @Input() isSingleItem: boolean;
  @Input() groupsOpenState: Record<string, boolean>;
  @Input() option: HierarchySelectItem;
  @Output() toggleGroup = new EventEmitter<boolean>()

  toggleGroupView(state: boolean): void {
    this.toggleGroup.emit(state);
  }
}
