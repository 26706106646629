import { Injectable } from '@angular/core';
import { CompareDirection } from '../types/compare-direction.type';
import { CompareType } from '../types/compare-type.type';

type ComparerFn<T> = (itemA: T, itemB: T, dir: CompareDirection) => number

/**
 * This class provides the comparators for sorting
 */
@Injectable({
  providedIn: 'root'
})
export class CompareService {
  public static comparerByType: { [key: string]: ComparerFn<any> } = {
    [CompareType.Alphabetical]: CompareService.compareStrings,
    [CompareType.Numerical]: CompareService.compareNumbers,
  };

  public static compareNumbers(a: number, b: number, dir = CompareDirection.Asc): number {
    return dir === CompareDirection.Asc ? a - b : b - a;
  }

  public static compareStrings(a: string, b: string, dir = CompareDirection.Asc): number {
    return dir === CompareDirection.Asc ? a.localeCompare(b) : b.localeCompare(a);
  }

  public compareTimeframes(timeframesIdsA: number[], timeframesIdsB: number[]) {
    if (!timeframesIdsA || !timeframesIdsA.length) {
      return -1;
    }
    if (!timeframesIdsB || !timeframesIdsB.length) {
      return 1;
    }

    const startDateAId = timeframesIdsA[0];
    const startDateBId = timeframesIdsB[0];

    // compare start dates
    if (startDateAId > startDateBId) {
      return 1;
    } else if (startDateAId < startDateBId) {
      return -1;
    }

    // if start dates are equal then compare end dates
    const endDateAId = timeframesIdsA[timeframesIdsA.length - 1];
    const endDateBId = timeframesIdsB[timeframesIdsB.length - 1];

    return endDateAId - endDateBId;
  }
}
