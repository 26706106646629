import { Component, Input } from '@angular/core';
import { SimpleTooltipPosition } from './simple-tooltip.type';

@Component({
  selector: 'simple-tooltip',
  templateUrl: './simple-tooltip.component.html',
  styleUrls: ['./simple-tooltip.component.scss']
})
export class SimpleTooltipComponent {
  @Input() visible = false;
  @Input() customClassNames = '';
  @Input() position = SimpleTooltipPosition.Top;
  @Input() offsetX = 0;
  @Input() offsetY = 0;

  TooltipPosition = SimpleTooltipPosition;
}
