import { Injectable } from '@angular/core';
import { ExpenseDO } from 'app/shared/types/expense.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ExpenseFilters } from 'app/shared/types/expense-filters.interface';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BudgetObjectDetailsManager } from './budget-object-details-manager.service';
import { Configuration } from 'app/app.constants';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';

export interface ExpenseData {
  expenseId: number;
  relationGroupId?: string;
  companyId: number;
  budgetId: number;
  budgetTimeframes: BudgetTimeframe[];
}

@Injectable()
export class RelatedExpensesService {
  private _relationGroupId: string;
  private _currentExpenseId: number;
  private _relatedExpenses: ExpenseDO[] = [];
  private _allBudgetTimeframes: BudgetTimeframe[] = [];
  private _allowedAddingRelatedExpenses = true;

  constructor(
    private readonly expenseService: ExpensesService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly configuration: Configuration,
    private readonly appRouting: AppRoutingService
  ) {}

  public get relatedExpenses (): ExpenseDO[] {
    return this._relatedExpenses;
  }

  public get allowedAddingRelatedExpenses (): boolean {
    return this._allowedAddingRelatedExpenses;
  }

  public get relationGroupId (): string {
    return this._relationGroupId;
  }

  public async viewAllRelatedExpenses () {
    return this.appRouting.openExpenseListInRelatedExpensesMode(this._relationGroupId);
  }

  public addRelatedExpenses$(timeframeIdsForRelatedExpenses: number[]): Observable<ExpenseDO> {
    return this.expenseService.addRelatedExpenses(this._currentExpenseId, timeframeIdsForRelatedExpenses).pipe(
      map(result => {
        const createdExpenses = result?.created_expenses || [];
        this._relationGroupId = result.relation_group_id;
        return createdExpenses;
      }),
      switchMap(
        createdExpenses => {
          // Always load current expense, as name will be changed on backend side. We also should update name in state
          return this.expenseService.getExpenseById(this._currentExpenseId).pipe(
            tap(expense => {
              const createdExpensesList = this._relatedExpenses.find(exp => exp.id === this._currentExpenseId) ?
                createdExpenses :
                [expense, ...createdExpenses];
              this.relatedExpensesLoaded(createdExpensesList);
              this.budgetObjectDetailsManager.reportObjectChange(this.configuration.OBJECT_TYPES.expense);
            })
          )
        }
      )
    );
  }

  public reset() {
    this._relationGroupId = null;
    this._currentExpenseId = null;
    this._allBudgetTimeframes = null;
    this._relatedExpenses = [];
    this._allowedAddingRelatedExpenses = true;
  }

  public initForExpense(expenseData: ExpenseData) {
    this._relatedExpenses = [];
    this._allowedAddingRelatedExpenses = true;
    const { expenseId, relationGroupId, budgetTimeframes, budgetId, companyId } = expenseData;
    this._currentExpenseId = expenseId;
    this._relationGroupId = relationGroupId;
    this._allBudgetTimeframes = budgetTimeframes || [];
    if (relationGroupId) {
      const requestData: ExpenseFilters = {
        company: companyId,
        budget: budgetId,
        relation_group: relationGroupId
      };
      this.expenseService.getExpenses(requestData).subscribe(
        expenses => this.relatedExpensesLoaded(expenses)
      );
    } else {
      this.updateAllowedAddingRelatedExpenses();
    }
  }

  private relatedExpensesLoaded(expenses: ExpenseDO[]) {
    const expensesToAdd = expenses.filter(exp => !this._relatedExpenses.some(relExp => relExp.id === exp.id));
    this._relatedExpenses = [...this._relatedExpenses, ...expensesToAdd];
    this.updateAllowedAddingRelatedExpenses();
  }

  private updateAllowedAddingRelatedExpenses() {
    this._allowedAddingRelatedExpenses =
      this._allBudgetTimeframes.some(tf =>
        !tf.locked && (this._relatedExpenses.length === 0 || !this._relatedExpenses.some(expense => expense.company_budget_alloc === tf.id))
      );
  }
}
