import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { calculateShareValue, roundDecimal } from 'app/shared/utils/common.utils';
import { COLORS } from 'app/shared/constants/colors.constants';
import { Currency } from 'app/shared/types/currency.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'budget-settings-summary-bar',
  templateUrl: './budget-settings-summary-bar.component.html',
  styleUrls: ['./budget-settings-summary-bar.component.scss']
})
export class BudgetSettingsSummaryBarComponent implements OnChanges {
  @Input() currency: Currency;
  @Input() budgetTotal: number;
  @Input() allocatedTotal: number;
  @Input() minimized = false;
  @Input() clickable = true;
  @Input() amountChanged: boolean;
  @Input() withConfetti = true;
  @Output() onEditClicked = new EventEmitter<void>();
  @Output() onRemainingChanged = new EventEmitter<number>();

  public decimalPipeFormat = '1.2-2';
  public isOverBudget = false;
  public allocatedPercentage = 0;
  public remainingAmount = 0;
  public rippleColor = `${COLORS.PRIMARY}40`;
  confettiDefaultOptions = {
    spread: 160,
    decay: .85,
    scalar: .6,
    gravity: .7
  };

  animateRemainingValue$ = new Subject<void>();
  private previousRemaining: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.budgetTotal || changes.allocatedTotal) {
      this.processBudgetTotals();
    }
  }

  private processBudgetTotals() {
    const remaining = roundDecimal(this.budgetTotal - this.allocatedTotal, 2);
    this.onRemainingChanged.emit(remaining);
    this.isOverBudget = remaining < 0;
    this.remainingAmount = Math.abs(remaining);
    if (this.isOverBudget) {
      this.allocatedPercentage = calculateShareValue(this.budgetTotal, this.allocatedTotal, 2);
    } else {
      this.allocatedPercentage = calculateShareValue(this.allocatedTotal, this.budgetTotal, 2);
    }
    this.showConfetti(remaining);
  }

  private showConfetti(remaining: number): void {
    if (this.amountChanged && remaining >= this.previousRemaining && remaining >= 0) {
      this.animateRemainingValue$.next();
    }
    this.previousRemaining = remaining;
  }
}
