import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Configuration } from '../../app.constants';
import { UserManager } from '../../user/services/user-manager.service';
import { filter, map, take } from 'rxjs/operators';
import { UserRole } from '../types/user-role.interface';

/**
 * CanActivate guard that checks current user object
 * for matching any of route data 'roles'
 */
@Injectable()
export class UserRolesGuard implements CanActivate {
  private readonly userRoleToKey = {
    [UserRole.ADMIN]: 'is_admin',
    [UserRole.OWNER]: 'is_account_owner'
  };

  constructor(
    private userManager: UserManager,
    private router: Router,
    private configuration: Configuration
  ) {}

  private isRoleMatching(user, role: UserRole) {
    const roleKey = this.userRoleToKey[role];
    return roleKey && Boolean(user[roleKey]);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { DEFAULT_ROUTE, ROUTER_OUTLETS } = this.configuration;

    return this.userManager.currentCompanyUser$.pipe(
      filter(user => user != null),
      take(1),
      map(user => {
        const guardData = route.data?.userRolesGuard || {};
        const { roles = [], forbiddenRoles = [], redirectTo = DEFAULT_ROUTE } = guardData;
        const containsForbiddenRoles = forbiddenRoles.some((role: UserRole) => this.isRoleMatching(user, role));
        const isMatchingRequiredRoles = !roles.length || roles.some((role: UserRole) => this.isRoleMatching(user, role));

        if (isMatchingRequiredRoles && !containsForbiddenRoles) {
          return true;
        }

        const stateChildren = state.root.children;
        const detailsRouteSnapshot: ActivatedRouteSnapshot = stateChildren.find(child => child.outlet === ROUTER_OUTLETS.DETAILS);

        // Details outlet is not activated
        if (stateChildren.length < 2 || !detailsRouteSnapshot) {
          return this.router.createUrlTree([ redirectTo ]);
        }
        const { outlet, params, routeConfig } = detailsRouteSnapshot;
        const outletPath = routeConfig.path.replace(':id', params.id);

        this.router.navigate([{
          outlets: {
            primary: redirectTo,
            [outlet]: outletPath
          }
        }], { queryParamsHandling: 'preserve' });

        return false;
      })
    );
  }
}
