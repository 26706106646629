import { Component, HostBinding } from '@angular/core';
import { ShadowState } from './table-content-shadows.directive';

@Component({
  template: ''
})

export abstract class TableContentShadowsBaseComponent {
  @HostBinding('class.shadow-right') hasRightShadow = false;
  @HostBinding('class.shadow-bottom') hasBottomShadow = false;
  @HostBinding('class.shadow-left') hasLeftShadow = false;
  @HostBinding('class.shadow-top') hasTopShadow = false;
  @HostBinding('class.table-with-shadows-container') tableWithShadows = true;
  @HostBinding('class.custom-scrollbar') customScrollbar = true;

  public handleShadowUpdate(state: ShadowState): void {
    this.hasRightShadow = state.right;
    this.hasBottomShadow = state.bottom;
    this.hasLeftShadow = state.left;
    this.hasTopShadow = state.top;
  }
}
