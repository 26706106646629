import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AuditLogDO } from '@shared/services/backend/audit-log.service';

@Component({
  selector: 'audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent {
  @Input() data: AuditLogDO[];
  @Output() loadNewChunk: EventEmitter<void> = new EventEmitter<void>();
}
