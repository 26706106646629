import { Component, Input } from '@angular/core';

@Component({
  selector: 'simple-widget-header',
  styleUrls: ['./simple-widget-header.component.scss'],
  templateUrl: './simple-widget-header.component.html'
})
export class SimpleWidgetHeaderComponent {
  @Input() text;
  @Input() color = '#362370';
}
