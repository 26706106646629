import { Injectable } from '@angular/core';
import { MetricValueUpdateItem, MetricValueUpdatesData, MetricValueUpdateType } from '../types/metric-value-update-item.interface';
import { MetricCalculationsDO, MetricMappingDO, MetricProgressTowardsTargetDO } from 'app/shared/services/backend/metric.service';
import { Configuration } from 'app/app.constants';
import { MetricMappingCalculationDO } from 'app/shared/services/backend/metric-calculation.service';
import { createDateString } from '../components/containers/campaign-details/date-operations';
import { MetricMappingDetailsState } from '../types/metric-mapping-details-state.interface';
import { Observable, of } from 'rxjs';
import { MetricMappingDetailsService } from './metric-mapping-details.service';
import { map, tap } from 'rxjs/operators';
import { MetricValueRecord, MetricValueRecords } from '../types/metric-value-records.interface';
import { addMonthsToDate, getDiffInMonth } from 'app/shared/utils/date.utils';

interface ExtendedMetricCalculation {
  childObjectId?: number;
  mappingType: string;
  metricMapping: number;
  rawCalculation: MetricMappingCalculationDO;
  date: Date;
  updateDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class MetricMappingCalculationService {
  private readonly metricValueUpdateTypeByMetricMappingType: {[key: string]: MetricValueUpdateType} = {
    [this.configuration.OBJECT_TYPES.program]: MetricValueUpdateType.program,
    [this.configuration.OBJECT_TYPES.campaign]: MetricValueUpdateType.campaign
  };

  public static doesUpdateItemMatchOriginObject(
    dataItem: MetricValueUpdateItem,
    originObject: { id: number; updateType: MetricValueUpdateType }
  ) {
    return dataItem?.objectId === originObject?.id && dataItem?.type === originObject?.updateType;
  }

  constructor(
    private readonly configuration: Configuration,
    private readonly metricMappingDetailsService: MetricMappingDetailsService
  ) {}

  public buildMetricValueUpdatesData(
    metricMapping: { objId: number, type: string },
    ownMetricCalculations: MetricMappingCalculationDO[],
    childMetricMappings: MetricMappingDO[],
    isReadonly = false
  ): MetricValueUpdatesData {
    const extOwnCalcs: ExtendedMetricCalculation[] =
      (ownMetricCalculations || []).map(calc => ({
        mappingType: metricMapping.type,
        childObjectId: metricMapping.objId,
        metricMapping: calc.metric_mapping,
        rawCalculation: calc,
        date: new Date(calc.date),
        updateDate: new Date(calc.upd),
      }));

    const extChildMetricCalcs: ExtendedMetricCalculation[] =
      (childMetricMappings || []).reduce(
        (calcs, metricMappingDO) => [
          ...calcs,
          ...metricMappingDO.metric_calculations.map(calc => ({
            mappingType: metricMappingDO.mapping_type,
            childObjectId: metricMappingDO.map_id,
            metricMapping: calc.metric_mapping,
            rawCalculation: calc,
            date: new Date(calc.date),
            updateDate: new Date(calc.upd),
          }))
        ],
        [] as ExtendedMetricCalculation[]
      );

    const mergedCalculations: ExtendedMetricCalculation[] = [
      ...extChildMetricCalcs,
      ...extOwnCalcs,
    ];

    mergedCalculations.sort(this.sortByDateAndEditable);

    const metricValueUpdateItems =
      mergedCalculations.flatMap(
        calculation => this.createMetricValueUpdateItems(calculation, metricMapping, isReadonly)
      );

    this.updateRunningTotal(metricValueUpdateItems);
    return metricValueUpdateItems;
  }

  public updateRunningTotal(metricValueUpdateItems: MetricValueUpdatesData = [], initRunningTotal = 0): void {
    let currentTotal = initRunningTotal;
    metricValueUpdateItems.forEach(
      item => item.runningTotal = (currentTotal = currentTotal + item.changeInValue)
    );
  }

  private createMetricValueUpdateItems(
    calc: ExtendedMetricCalculation,
    metricMapping: { objId: number, type: string },
    isReadonly = false
  ): MetricValueUpdateItem[] {
    const valueUpdateItems: MetricValueUpdateItem[] = [];
    const valueUpdateItemBase: Partial<MetricValueUpdateItem> = {
      id: calc.rawCalculation.id,
      objectId: calc.childObjectId,
      metricMapping: calc.metricMapping,
      date: calc.rawCalculation.date,
      runningTotal: 0, // will be set separately
      notes: calc.rawCalculation.notes,
      monthChildItems: []
    };

    if (calc.rawCalculation.third_party_values) {
      valueUpdateItems.push(
        ...Object.entries(calc.rawCalculation.third_party_values)
          .filter(([, value]) => !!value.change_in_value)
          .map(([integration, value]) => {
            const updateType = MetricMappingDetailsService.metricValueUpdateTypeByIntegrationName[integration];
            return {
              ...valueUpdateItemBase,
              type: updateType,
              isRemovable: false,
              isEditable: false,
              changeInValue: value.change_in_value,
              updatedDate: calc.rawCalculation.date
            } as MetricValueUpdateItem;
          })
      );
    }

    if (calc.rawCalculation.change_in_value) {
      const updateType = this.metricValueUpdateTypeByMetricMappingType[calc.mappingType];
      const allowedToModify = metricMapping.type === calc.mappingType &&
        metricMapping.objId === calc.childObjectId &&
        !isReadonly;

      valueUpdateItems.push({
        ...valueUpdateItemBase,
        type: updateType,
        isRemovable: allowedToModify,
        isEditable: allowedToModify,
        changeInValue: calc.rawCalculation.change_in_value,
        updatedDate: createDateString(new Date(calc.rawCalculation.upd))
      } as MetricValueUpdateItem);
    }

    return valueUpdateItems;
  }

  public insertNewMetricValueUpdateItem(
    targetMetricValueUpdatesData: MetricValueUpdatesData,
    itemToAdd: MetricValueUpdateItem
  ): MetricValueUpdatesData {
    const recordDate = new Date(itemToAdd.date);
    let insertIndex = targetMetricValueUpdatesData.findIndex(item => new Date(item.date) > recordDate);
    insertIndex = insertIndex < 0 ? targetMetricValueUpdatesData.length : insertIndex;
    if (!itemToAdd.changeInValue) {
      const prevItem = targetMetricValueUpdatesData[insertIndex - 1];
      const prevRunningTotal = prevItem && prevItem.runningTotal || 0;
      itemToAdd.changeInValue = itemToAdd.runningTotal - prevRunningTotal;
    }

    const newTargetMetricValueUpdatesData = [...targetMetricValueUpdatesData];
    newTargetMetricValueUpdatesData.splice(insertIndex, 0, itemToAdd);

    this.updateRunningTotal(newTargetMetricValueUpdatesData);
    return newTargetMetricValueUpdatesData;
  }

  sortByDateAndEditable = (calc1, calc2) => {
    let comparedResult = +calc1.date - +calc2.date;
    if (comparedResult === 0) {
      comparedResult = calc1.isEditable === calc2.isEditable ? 0 : calc1.isEditable ? 1 : -1;
    }
    return comparedResult;
  }

  public applyAddedValueUpdateItemToCalculations(
    metricMappingId: number,
    metricCalculations: MetricMappingCalculationDO[],
    addedItem: MetricValueUpdateItem,
    currentCost: number,
    revenuePerOutcome?: number,
    revenueToProfit?: number
  ): Observable<boolean> {
    const affectedCalculation = metricCalculations.find(calc => calc.date === addedItem.date);
    const nextCalcs = this.getCalculationsForDateAndNewer(metricCalculations, addedItem.date);

    if (affectedCalculation) {
      affectedCalculation.change_in_value = addedItem.changeInValue;
      affectedCalculation.upd = addedItem.updatedDate;
      return this.updateTotalsForCalculations(nextCalcs, addedItem.changeInValue, revenuePerOutcome, revenueToProfit);
    }

    const prevCalcs = this.getCalculationsOlderThenDate(metricCalculations, addedItem.date);
    const isRecent = !nextCalcs.length;
    // 'nextCalcs' are ordered by recent dates first, so the closest 'next' record is the last one
    const closestNextCost = nextCalcs.length ? nextCalcs[nextCalcs.length - 1].cost_value : currentCost;
    const cost = isRecent ?
      currentCost :
      prevCalcs.length ?
        prevCalcs[0].cost_value :
        closestNextCost;

    const addedCalc: MetricMappingCalculationDO = {
      metric_mapping: metricMappingId,
      date: addedItem.date,
      change_in_value: addedItem.changeInValue,
      cost_value: cost,
      total_value: prevCalcs.length ? prevCalcs[0].total_value : 0,
      notes: addedItem.notes,
      upd: addedItem.updatedDate
    };
    metricCalculations.push(addedCalc);
    return this.updateTotalsForCalculations([addedCalc, ...nextCalcs], addedItem.changeInValue, revenuePerOutcome, revenueToProfit);
  }

  public removeMetricValueUpdateItem(
    targetMetricValueUpdatesData: MetricValueUpdatesData,
    itemToDelete: MetricValueUpdateItem
  ): MetricValueUpdatesData {
    const newTargetMetricValueUpdatesData = [...targetMetricValueUpdatesData];
    const itemIndex =
      newTargetMetricValueUpdatesData.findIndex(
        item => item.date === itemToDelete.date && item.type === itemToDelete.type && item.objectId === itemToDelete.objectId
      );
    newTargetMetricValueUpdatesData.splice(itemIndex, 1);
    this.updateRunningTotal(newTargetMetricValueUpdatesData);
    return newTargetMetricValueUpdatesData;
  }

  public applyRemovedValueUpdateItemToCalculations(
    metricValueUpdatesData: MetricValueUpdatesData,
    metricCalculations: MetricMappingCalculationDO[],
    itemToDelete: MetricValueUpdateItem,
    revenuePerOutcome?: number,
    revenueToProfit?: number
  ): Observable<boolean> {
    const affectedCalcIndex = metricCalculations.findIndex(calc => calc.date === itemToDelete.date);
    if (affectedCalcIndex >= 0) {
      const otherUpdatesForDate =
        metricValueUpdatesData.filter(item => item.date === itemToDelete.date && item.type !== itemToDelete.type);
      if (otherUpdatesForDate.length) {
        metricCalculations[affectedCalcIndex].change_in_value = 0;
      } else {
        metricCalculations.splice(affectedCalcIndex, 1);
      }
      const nextCalcs = this.getCalculationsForDateAndNewer(metricCalculations, itemToDelete.date);
      return this.updateTotalsForCalculations(nextCalcs, -itemToDelete.changeInValue, revenuePerOutcome, revenueToProfit);
    } else {
      return of(false);
    }
  }

  public alterMetricValueUpdateItem(
    targetMetricValueUpdatesData: MetricValueUpdatesData,
    updatedItem: MetricValueUpdateItem
  ): MetricValueUpdatesData {
    const newTargetMetricValueUpdatesData = [...targetMetricValueUpdatesData];
    const targetItem =
      newTargetMetricValueUpdatesData.find(
        item => item.date === updatedItem.date && item.type === updatedItem.type && item.objectId === updatedItem.objectId
      );
    targetItem.notes = updatedItem.notes;
    const diff = updatedItem.changeInValue - targetItem.changeInValue || updatedItem.runningTotal - targetItem.runningTotal;
    if (diff) {
      targetItem.changeInValue += diff;
      targetItem.updatedDate = createDateString(new Date());
      this.updateRunningTotal(newTargetMetricValueUpdatesData);
    }
    return newTargetMetricValueUpdatesData;
  }

  public applyAlteredValueUpdateItemToCalculations(
    metricCalculations: MetricMappingCalculationDO[],
    updatedItem: MetricValueUpdateItem,
    updateType: MetricValueUpdateType,
    revenuePerOutcome?: number,
    revenueToProfit?: number
  ): Observable<boolean> {
    const affectedCalc = metricCalculations.find(calc => calc.date === updatedItem.date);
    if (affectedCalc) {
      affectedCalc.notes = updatedItem.notes;
      const diff = updatedItem.changeInValue - affectedCalc.change_in_value || updatedItem.runningTotal - affectedCalc.total_value;
      if (diff && updatedItem.type === updateType) {
        affectedCalc.change_in_value += diff;
        affectedCalc.upd = updatedItem.updatedDate;
        const nextCalcs = this.getCalculationsForDateAndNewer(metricCalculations, updatedItem.date);
        return this.updateTotalsForCalculations(nextCalcs, diff, revenuePerOutcome, revenueToProfit);
      }
    }

    return of(false);
  }

  public updateTotalsForCalculations(
    calcs: MetricMappingCalculationDO[],
    totalDiff: number,
    revenuePerOutcome?: number,
    revenueToProfit?: number
  ): Observable<boolean> {
    if (!calcs || !calcs.length) {
      return of(false);
    }

    calcs.forEach(calc => calc.total_value = calc.total_value + totalDiff);
    return this.reCalculateROIandCPO(calcs, revenuePerOutcome, revenueToProfit);
  }

  public reCalculateROIandCPO(
    calcs: MetricMappingCalculationDO[],
    revenuePerOutcome?: number,
    revenueToProfit?: number
  ): Observable<boolean> {
    return this.metricMappingDetailsService.calculateMetricValues(
      calcs.map(calc => ({
        rpo: revenuePerOutcome,
        value: calc.total_value,
        cost: calc.cost_value,
        revenue_to_profit: revenuePerOutcome && revenueToProfit
      }))
    ).pipe(
      tap(
        (metricValues: MetricCalculationsDO[]) =>
          metricValues.forEach((value, index) => {
            calcs[index].cpo_value = value.CPO || 0;
            calcs[index].roi_value = value.ROI || 0;
          })
      ),
      map(() => true)
    );
  }

  private getCalculationsOlderThenDate(calcs: MetricMappingCalculationDO[], date: string): MetricMappingCalculationDO[] {
    return calcs
      .filter(calc => new Date(calc.date) < new Date(date))
      .sort((c1, c2) => new Date(c2.date).getTime() - new Date(c1.date).getTime())
  }

  private getCalculationsForDateAndNewer(calcs: MetricMappingCalculationDO[], date: string): MetricMappingCalculationDO[] {
    return calcs
      .filter(calc => new Date(calc.date) >= new Date(date))
      .sort((c1, c2) => new Date(c2.date).getTime() - new Date(c1.date).getTime());
  }

  public updateMetricStateRecords(state: MetricMappingDetailsState) {
    state.metricValueRecords = this.metricMappingDetailsService.getMetricValueRecords(state.metricCalculations);
    state.CPORecords = this.metricMappingDetailsService.getCPORecords(state.metricCalculations);
    state.ROIRecords = this.metricMappingDetailsService.getROIRecords(state.metricCalculations);
  }

  public refreshCalculations(state: MetricMappingDetailsState) {
      state.metricCalculations.sort(
        (calc1, calc2) => new Date(calc1.date).getTime() - new Date(calc2.date).getTime()
      );
    this.updateMetricStateRecords(state);
  }

  public addNewValueUpdateItem(
    state: MetricMappingDetailsState,
    updateType: MetricValueUpdateType,
    date: string,
    currentCost: number,
    changeInValue?: number,
    runningTotal?: number,
    notes?: string
  ): Observable<boolean> {
    const itemToAdd: MetricValueUpdateItem = {
      type: updateType,
      objectId: state.parentId,
      date,
      changeInValue,
      runningTotal,
      updatedDate: new Date().toString(),
      isEditable: true,
      isRemovable: true,
      notes
    };
    state.metricValueUpdates = this.insertNewMetricValueUpdateItem(state.metricValueUpdates, itemToAdd);
    state.currentValue = this.getMetricCurrentValue(state.metricValueUpdates, updateType);

    return this.applyAddedValueUpdateItemToCalculations(
      state.objectId,
      state.metricCalculations,
      itemToAdd,
      currentCost,
      state.revenuePerOutcome,
      state.revenueToProfit
    ).pipe(
      tap(() => this.refreshCalculations(state))
    );
  }

  public removeValueUpdateItem(state: MetricMappingDetailsState, itemToDelete: MetricValueUpdateItem): Observable<boolean> {
    state.metricValueUpdates = this.removeMetricValueUpdateItem(state.metricValueUpdates, itemToDelete);
    state.currentValue = this.getMetricCurrentValue(state.metricValueUpdates, itemToDelete.type);

    return this.applyRemovedValueUpdateItemToCalculations(
      state.metricValueUpdates,
      state.metricCalculations,
      itemToDelete,
      state.revenuePerOutcome,
      state.revenueToProfit
    ).pipe(
      tap(() => this.refreshCalculations(state))
    );
  }

  public changeValueUpdateItem(
    state: MetricMappingDetailsState,
    updatedItem: MetricValueUpdateItem,
    updateType: MetricValueUpdateType
  ): Observable<boolean> {
    state.metricValueUpdates = this.alterMetricValueUpdateItem(state.metricValueUpdates, updatedItem);
    state.currentValue = this.getMetricCurrentValue(state.metricValueUpdates, updatedItem.type);

    return this.applyAlteredValueUpdateItemToCalculations(
      state.metricCalculations,
      updatedItem,
      updateType,
      state.revenuePerOutcome,
      state.revenueToProfit
    ).pipe(
      tap(() => this.refreshCalculations(state))
    );
  }

  public getMetricCurrentValue(metricValueUpdatesData: MetricValueUpdatesData, updateType: MetricValueUpdateType) {
    return metricValueUpdatesData
      .filter(item => item.type === updateType)
      .reduce((sum, item) => sum + item.changeInValue, 0);
  }

  public createOwnCalculations(
    state: MetricMappingDetailsState,
    cost: number,
    ownMetricValueUpdateType?: MetricValueUpdateType
  ): Observable<any> {
    const revenueToProfit = state.revenueToProfit;

    const getChangeInValue = (updateItem: MetricValueUpdateItem) =>
      updateItem.type === ownMetricValueUpdateType ? updateItem.changeInValue : 0;

    const groupsByDate =
      state.metricValueUpdates.reduce(
        (groups, updateItem) => {
          const group = groups.find(g => g.date === updateItem.date);
          if (group) {
            group.maxRunningTotal = Math.max(group.maxRunningTotal, updateItem.runningTotal);
            if (new Date(updateItem.updatedDate).getTime() > new Date(group.mostRecentUpdateDate).getTime()) {
              group.mostRecentUpdateDate = updateItem.updatedDate;
            }
            group.changeInValue = getChangeInValue(updateItem)
          } else {
            groups.push({
              date: updateItem.date,
              maxRunningTotal: updateItem.runningTotal,
              mostRecentUpdateDate: updateItem.updatedDate,
              changeInValue: getChangeInValue(updateItem)
            })
          }
          return groups;
        },
        [] as { date: string; maxRunningTotal: number; mostRecentUpdateDate: string; changeInValue: number; }[]
      );

    state.metricCalculations =
      groupsByDate.map(group => ({
        date: group.date,
        change_in_value: group.changeInValue,
        cost_value: cost,
        total_value: group.maxRunningTotal,
        upd: group.mostRecentUpdateDate,
        metric_mapping: state.objectId
      }));

    return this.reCalculateROIandCPO(state.metricCalculations, state.revenuePerOutcome, revenueToProfit).pipe(
      tap(() => this.refreshCalculations(state))
    );
  }

  calcMonthlyAndCumulativeValues(records: MetricValueRecords<number>, fixMonthNumber = false): Partial<MetricProgressTowardsTargetDO> {
    let prevMonthLastValue = 0;
    return (records || []).reduce((store, record, index) => {
      const isLastItem = index === records.length - 1;
      const monthNumber = record.timestamp.getMonth();
      const year = record.timestamp.getFullYear();
      const switchMonth = isLastItem || monthNumber !== records[index + 1].timestamp.getMonth();
      const switchYear = !isLastItem && year !== records[index + 1].timestamp.getFullYear();
      if (switchMonth || switchYear) {
        const dateKey = this.getDateObjectKey(record.timestamp, fixMonthNumber);
        const value = prevMonthLastValue ? record.value - prevMonthLastValue : record.value;
        store.cumulative_value[dateKey] = record.value;
        store.monthly_value[dateKey] = value;
        this.fullfilEmptyDates(record, records[index + 1], store, fixMonthNumber);
        prevMonthLastValue = record.value;
      }
      return store;
    }, { cumulative_value: {}, monthly_value: {}});
  }

  fullfilEmptyDates(record: MetricValueRecord<number>, nextRecord: MetricValueRecord<number>, store: Partial<MetricProgressTowardsTargetDO>, fixMonthNumber: boolean) {
    if (nextRecord) {
      const monthsDiff = getDiffInMonth(record.timestamp, nextRecord.timestamp)
      let missedMonthsCount = monthsDiff ? monthsDiff - 1 : 0;
      if (missedMonthsCount > 0) {
        for (let i = 1; i <= missedMonthsCount; i++) {
          const missedDate = addMonthsToDate(record.timestamp, i);
          const dateKey = this.getDateObjectKey(missedDate, fixMonthNumber);
          store.cumulative_value[dateKey] = record.value;
          store.monthly_value[dateKey] = 0;
        }
      }
    }
  }

  getDateObjectKey(date: Date, fixMonthNumber): string {
    const monthNumber = date.getMonth() + 1;
    const year = date.getFullYear();
    const monthKey = monthNumber.toString().padStart(2, '0');
    return year + '-' + (fixMonthNumber ? monthKey : monthNumber);
  }
}
