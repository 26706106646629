import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'dynamic-icon',
  template: `
    <div class="icon">
      <ng-container *ngTemplateOutlet="icon ? faIcon : image"></ng-container>
      <ng-template #faIcon>
        <fa-icon [icon]="icon"></fa-icon>
      </ng-template>
      <ng-template #image>
        <img src="assets/images/planful-logo-simple-colored.svg" alt="Planful" />
      </ng-template>
    </div>`
})
export class DynamicIconComponent {
  @Input() icon: IconProp;
}
