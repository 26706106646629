<div class="save-budget-button">
  <button class="action" mat-flat-button color="primary" (click)="save()" [disabled]="disabled">
    <span class="action-icon" *ngIf="iconConfigByStatus[selectedStatus]">
      <fa-icon [icon]="iconConfigByStatus[selectedStatus]"></fa-icon>
    </span>
    Save as {{ statusLabel[selectedStatus] }}
  </button>
  <button
    #trigger="matMenuTrigger"
    class="toggle"
    [class.is-active]="trigger.menuOpen"
    [matMenuTriggerFor]="menu"
    mat-flat-button
    color="primary"
  >
    <span class="toggle-icon">
      <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
    </span>
  </button>
</div>

<mat-menu #menu="matMenu" class="status-menu mat-pl-menu no-padding">
  <button mat-menu-item *ngFor="let status of statuses" (click)="selectStatus(status)" class="status-item">
    <span class="status-icon" *ngIf="iconConfigByStatus[status]">
      <fa-icon [icon]="iconConfigByStatus[status]"></fa-icon>
    </span>
    {{ statusLabel[status] }}
  </button>
</mat-menu>
