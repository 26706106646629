import { ManageCegTableDataService } from '../manage-ceg-table-data.service';
import { Configuration } from 'app/app.constants';
import { BudgetObjectEventContext } from 'app/budget-object-details/types/budget-object-event.interface';
import { Observable, of } from 'rxjs';
import { BaseBudgetObjectEventHandler } from './base-budget-object-event-handler';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { Goal } from '@shared/types/goal.interface';
import { ManageCegTableRow, ManageCegViewMode } from '@manage-ceg/types/manage-ceg-page.types';
import { map } from 'rxjs/operators';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { resetParentForObjects } from 'app/budget-object-details/utils/object-details.utils';
import { sortObjectsByName } from '@shared/utils/budget.utils';

export class GoalEventHandler extends BaseBudgetObjectEventHandler {
  private readonly budgetDataService: BudgetDataService;

  public constructor(
    manageCegTableDataService: ManageCegTableDataService,
    config: Configuration,
    budgetDataService: BudgetDataService
  ) {
    super(manageCegTableDataService, config);
    this.budgetDataService = budgetDataService;
  }

  protected onCreate(data: BudgetObjectEventContext): Observable<boolean> {
    const createdGoal = this.budgetDataService.goalsSnapshot.find(goal => goal.id === data.objectId);
    return createdGoal ? this.handleGoalCreated(createdGoal, false) : of(false);
  }

  protected onDelete(data: BudgetObjectEventContext): Observable<boolean> {
    return this.handleGoalDeleted(data);
  }

  protected onMoved(data: BudgetObjectEventContext): Observable<boolean> {
    return this.handleGoalDeleted(data);
  }

  protected onUpdate(data: BudgetObjectEventContext): Observable<boolean> {
    const updatedGoal = this.budgetDataService.goalsSnapshot.find(goal => goal.id === data.objectId);
    return updatedGoal ? this.handleBulkUpdate([updatedGoal], false) : of(false);
  }

  public handleBulkDelete(goalIds: number[]): Observable<boolean> {
    this.applyRemovedGoals(goalIds);

    if (this.viewMode === ManageCegViewMode.Goals) {
      this.manageCegTableDataService.updateTable();
      return of(true);
    }

    return of(false);
  }

  public handleBulkUpdate(updatedGoals: Goal[], updateInBudgetData = true): Observable<boolean> {
    this.applyUpdatedGoals(updatedGoals, updateInBudgetData);
    if (this.viewMode === ManageCegViewMode.Goals) {
      this.manageCegTableDataService.updateTable();
      return of(true);
    }
    return of(false);
  }

  public handleGoalCreated(createdGoal: Goal, applyToBudgetData = true): Observable<boolean> {
    this.applyCreatedGoal(createdGoal, applyToBudgetData);

    if (this.viewMode === ManageCegViewMode.Goals) {
      this.manageCegTableDataService.updateTable();
      const rowsToUpdate = this.getRowsToUpdateForAddedGoal(createdGoal.id);
      return this.updateParentRows(rowsToUpdate).pipe(
        map(() => true)
      );
    }

    return of(false);
  }

  private applyCreatedGoal(createdGoal: Goal, applyToBudgetData = true): void {
    if (applyToBudgetData) {
      this.budgetDataService.goalsSnapshot.push(createdGoal);
      sortObjectsByName(this.budgetDataService.goalsSnapshot);
    }
    this.budgetDataService.setGoals(this.budgetDataService.goalsSnapshot);
    this.syncTableDataInputs();
  }

  private syncTableDataInputs(): void {
    this.manageCegTableDataService.tableDataInputs.goals = this.budgetDataService.goalsSnapshot;
  }

  private getRowsToUpdateForAddedGoal(objectId: number): ManageCegTableRow[] {
    const addedGoalRow = this.manageCegTableDataService.getRecordByIdAndType(ManageTableRowType.Goal, objectId);
    return addedGoalRow ? [addedGoalRow] : [];
  }

  private handleGoalDeleted(data: BudgetObjectEventContext): Observable<boolean> {
    this.budgetDataService.setGoals(this.budgetDataService.goalsSnapshot);
    this.budgetDataService.setLightCampaigns(this.budgetDataService.lightCampaignsSnapshot);
    this.budgetDataService.setLightPrograms(this.budgetDataService.lightProgramsSnapshot);

    const removedGoal = data.extras?.prevObject as Goal;
    if (removedGoal) {
      this.removeGoalFromTableDataInputs([removedGoal])
    }

    if (this.viewMode === ManageCegViewMode.Goals) {
      this.manageCegTableDataService.updateTable();
      return of(true);
    }

    return of(false);
  }

  private applyRemovedGoals(goalIds: number[]): void {
    const currentGoals = this.budgetDataService.goalsSnapshot;
    let affectedCampaigns = false;
    let affectedPrograms = false;
    const removedGoals: Goal[] = [];

    for (const goalId of goalIds) {
      const removedGoalIndex = currentGoals.findIndex(goal => goal.id === goalId);
      removedGoals.push(currentGoals[removedGoalIndex]);
      currentGoals.splice(removedGoalIndex, 1);

      resetParentForObjects(
        this.budgetDataService.lightCampaignsSnapshot,
        campaign => campaign.goalId === goalId,
        campaign => campaign.goalId = null,
        () => affectedCampaigns = true
      );

      resetParentForObjects(
        this.budgetDataService.lightProgramsSnapshot,
        program => program.goalId === goalId,
        program => program.goalId = null,
        () => affectedPrograms = true
      );
    }

    this.budgetDataService.setGoals(currentGoals);
    if (affectedCampaigns) {
      this.budgetDataService.setLightCampaigns(this.budgetDataService.lightCampaignsSnapshot);
    }
    if (affectedPrograms) {
      this.budgetDataService.setLightPrograms(this.budgetDataService.lightProgramsSnapshot);
    }

    this.removeGoalFromTableDataInputs(removedGoals);
  }

  private removeGoalFromTableDataInputs(removedGoals: Goal[], resetParentForChildDataInputObjects = true): void {
    const dataInputGoals = this.manageCegTableDataService.tableDataInputs.goals;
    for (const removedGoal of removedGoals) {
      const removedFromTableGoalIndex = dataInputGoals.findIndex(obj => obj.id === removedGoal.id);
      if (removedFromTableGoalIndex !== -1) {
        dataInputGoals.splice(removedFromTableGoalIndex, 1);
      }

      if (resetParentForChildDataInputObjects) {
        this.resetParentForAffectedDataInputsObjects(removedGoal.id);
      }
    }
  }

  private resetParentForAffectedDataInputsObjects(goalId: number): void {
    const { campaigns: dataInputsCampaigns, expGroups: dataInputsPrograms } = this.manageCegTableDataService.tableDataInputs;

    resetParentForObjects(
      dataInputsCampaigns,
      campaign => campaign.goalId === goalId,
      campaign => campaign.goalId = null
    );

    resetParentForObjects(
      dataInputsPrograms,
      program => program.goalId === goalId,
      program => program.goalId = null
    );
  }

  private applyUpdatedGoals(updatedGoals: Goal[], updateInBudgetData = true): void {
    const currentGoals = this.budgetDataService.goalsSnapshot;
    for (const goal of updatedGoals) {
      if (updateInBudgetData) {
        this.updateGoalInList(currentGoals, goal);
      }
      this.updateGoalInList(this.manageCegTableDataService.tableDataInputs.goals, goal);
    }

    this.budgetDataService.setGoals(currentGoals);
  }

  private updateGoalInList(goalList: Goal[], targetGoal: Goal): void {
    const targetGoalsIndex = goalList.findIndex(goal => goal.id === targetGoal.id);
    goalList[targetGoalsIndex] = targetGoal;
  }
}
