import { Injectable } from '@angular/core';
import { getDiffInDays } from 'app/shared/utils/date.utils';
import { MetricMilestone, MetricMilestones } from '../types/metric-milestone.interface';
import { MetricUnit } from '../types/metric-unit.interface';
import { MetricValueRecords } from '../types/metric-value-records.interface';
import { CurrencyMetricType } from '../types/currency-metric-type.interface';
import { createDateString, parseDateString } from '../components/containers/campaign-details/date-operations';
import { MetricProgressState } from 'app/shared/types/metric-progress-state.type';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { MetricMappingDO } from 'app/shared/services/backend/metric.service';
import { Budget } from 'app/shared/types/budget.interface';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { Metric } from '../components/details-metrics/details-metrics.type';


@Injectable({
  providedIn: 'root'
})
export class MetricsUtilsService {

  public static getMetricCurrentValue(metric: Metric): number {
    const lastTotal = metric.value?.length ? metric.value[metric.value.length - 1].value : null;
    return lastTotal || metric.current;
  }

  public static getMetricRPOAndRevenueToProfit(
    metricTypeId: number,
    metricTypes: MetricType[],
    products: ProductDO[]
  ): { revenuePerOutcome: number; revenueToProfit: number } {
    const metricType = metricTypes.find(mt => mt.id === metricTypeId);
    const revenuePerOutcome = metricType.revenuePerOutcome;
    const revenueToProfit = products.find(prd => prd.id === metricType?.productId)?.revenue_to_profit;
    return { revenuePerOutcome, revenueToProfit };
  }
  /**
   * Get 'endDate' for metric based on its milestones
   */
  public getEndDate(milestones: MetricMilestones): Date {
    if (!milestones || !milestones.length) {
      return null;
    }

    const lastMilestone = milestones[milestones.length - 1];
    return lastMilestone.date;
  }

  public getChartStartDate(data: MetricValueRecords<number>, objectStartDate: Date = null): Date {
    const oldestDataPoint = data && data[0];
    const today = new Date();

    if (!oldestDataPoint && !objectStartDate) {
      return today;
    }

    let oldestDateAvailable = today.getTime();
    if (oldestDataPoint) {
      oldestDateAvailable = Math.min(oldestDataPoint.timestamp.getTime(), oldestDateAvailable);
    }

    if (objectStartDate) {
      oldestDateAvailable = Math.min(objectStartDate.getTime(), oldestDateAvailable);
    }

    return new Date(oldestDateAvailable);
  }

  public getTargetValue(milestones: MetricMilestones): number {
    if (!milestones || !milestones.length) {
      return 0;
    }

    // Added type check in case milestone default dates are string from Metric Drawer.
    const sortByDate = (ms1: MetricMilestone, ms2: MetricMilestone) => (ms1.date?.getTime?.() || 0) - (ms2.date?.getTime?.() || 0);
    milestones.sort(sortByDate);
    const lastMilestone = milestones[milestones.length - 1];
    return lastMilestone.targetValue || 0;
  }

  public getMinValue(data: MetricValueRecords<number> = [], initialValue = 0): number {
    if (!Array.isArray(data)) {
      return initialValue;
    }

    return data.reduce((min, record) => Math.min(record.value, min), initialValue);
  }

  /**
   * Calculate estimated target value
   * for the {targetDate} based on milestones and startDate
   */
  public getEstimatedTarget(targetDate: Date, startDate: Date, milestones: MetricMilestones): number {
    if (!targetDate || !startDate || !milestones.length || startDate > targetDate) {
      return 0;
    }

    let rangeStartDate = startDate;
    let rangeEndDate = null;
    let rangeStartValue = 0;
    let rangeEndValue = 0;

    for (const milestone of milestones) {
      const milestoneDate = milestone.date;

      if (milestoneDate < targetDate) {
        rangeStartDate = milestoneDate;
        rangeStartValue = milestone.targetValue || 0;
      } else {
        rangeEndDate = milestoneDate;
        rangeEndValue = milestone.targetValue || 0;
        break;
      }
    }

    // If target is after last milestone - return milestone's value
    if (rangeEndDate == null) {
      return rangeStartValue;
    }

    const rangeDiffInDays = getDiffInDays(rangeEndDate, rangeStartDate);
    const diffFromLastMilestone = getDiffInDays(rangeStartDate, targetDate);
    const valuePerDay = (rangeEndValue - rangeStartValue) / rangeDiffInDays;

    return rangeStartValue + Math.round(diffFromLastMilestone * valuePerDay);
  }

  /**
   * Get 'progress state' based on values percentage difference.
   * @param {number} targetValue
   * @param {number} currentValue
   * @param {boolean} reverseLogic - For some values we should apply 'reversed' logic
   *        For ex.:
   *          - ROI shouldn't be reversed, since the bigger ROI the better
   *          - CPO should be reversed, since the smaller CPO the better
   */
  public getProgressState(targetValue: number, currentValue: number, reverseLogic: boolean = false): {
    state: MetricProgressState,
    diffShare: number
  } {
    const IN_TARGET_THRESHOLD = 0.1;
    const BELOW_TARGET_THRESHOLD = 0.25;
    const diff = currentValue - targetValue;
    const diffMultiplier = reverseLogic ? -1 : 1;
    const diffShare = targetValue ? diffMultiplier * (diff / Math.abs(targetValue)) : 0;

    if (Math.abs(diffShare) <= IN_TARGET_THRESHOLD) {
      return {
        state: MetricProgressState.InTarget,
        diffShare
      }
    }

    if (diffShare > IN_TARGET_THRESHOLD) {
      return {
        state: MetricProgressState.AboveTarget,
        diffShare
      }
    }

    if (diffShare < -(IN_TARGET_THRESHOLD) && diffShare > -(BELOW_TARGET_THRESHOLD)) {
      return {
        state: MetricProgressState.BelowTarget,
        diffShare
      }
    }

    return {
      state: MetricProgressState.FarBelowTarget,
      diffShare
    };
  }

  getMetricUnit(isWithCurrency: boolean, currencyCode: string) {
    return isWithCurrency ? `${MetricUnit.Amount} (${currencyCode})` : MetricUnit.Quantity;
  }

  isMetricTypeDecimal(metric: MetricType): boolean {
    const notDecimalTypes = [
      CurrencyMetricType.Bookings as string,
      CurrencyMetricType.Pipeline as string,
      CurrencyMetricType.Revenue as string
    ];

    return metric?.withCurrency && !notDecimalTypes.includes(metric.type);
  }

  /**
   * Returns a date string for metric's start date with a default value defined by
   * <campaign start date> if any or <budget start date>
   */
  public getDefaultStartDate(
    startDate: string,
    campaign: LightCampaign,
    budget: Budget
  ): string {
    if (startDate || !campaign) {
      return startDate;
    }
    return createDateString(campaign.startDate) || budget?.budget_from;
  }

  /**
   * Returns a metric milestones with a default milestone for legacy metrics
   * Default milestone's date is defined by <campaign end date> if any or <budget end date>
   */
  public getDefaultMilestones(
    milestones: MetricMilestones,
    campaign: LightCampaign,
    mapping: MetricMappingDO,
    budget: Budget
  ): MetricMilestones {
    if (milestones.length || !campaign) {
      return milestones;
    }
    return [{
      targetValue: mapping.projection_amount,
      date: campaign.endDate || parseDateString(budget.budget_to)
    }];
  }
}
