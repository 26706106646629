import { GoalDetailsState } from '../../types/budget-object-details-state.interface';
import { DetailsObjectStateMapper } from '../../types/budget-object-state-mapper.interface';
import { Injectable } from '@angular/core';
import { GoalDO } from 'app/shared/types/goal.interface';

@Injectable()
export class GoalStateMapper implements DetailsObjectStateMapper {
  stateToDataObject(state: Partial<GoalDetailsState>): Partial<GoalDO> {
    const dataObject: Partial<GoalDO> = {};

    if (state.name) {
      dataObject.name = state.name;
    }
    if (state.hasOwnProperty('notes')) {
      dataObject.note = state.notes;
    }
    if (state.budgetId) {
      dataObject.budget = state.budgetId;
    }
    if (state.companyId) {
      dataObject.company = state.companyId;
    }
    if (state.createdBy) {
      dataObject.created_by = state.createdBy;
    }
    if (state.typeId) {
      dataObject.goal_type = state.typeId;
    }

    return dataObject;
  }

  dataObjectToState(dataObject: Partial<GoalDO>): Partial<GoalDetailsState> {
    return {
      objectId: dataObject.id || null,
      name: dataObject.name || null,
      budgetId: dataObject.budget || null,
      companyId: dataObject.company || null,
      createdBy: dataObject.created_by || null,
      notes: dataObject.note || '',
      typeId: dataObject.goal_type || null,
      statusTotals: { ...dataObject.status_totals },
      metricData: dataObject.metric_data || null,
      attachmentMappings: dataObject.attachments || [],
      created: dataObject.crd || '',
      updated: dataObject.upd || ''
    };
  }
}
