<div class="timeline-container" id="budgetTimeLine">
  <div class="timeline-header">
    <div class="timeline-actions">
      <budget-chart-actions-menu [widgetActions]="timelineWidgetActionItems"></budget-chart-actions-menu>
    </div>
    <div class="timeline-heading-text">
      <h2>Spending Timeline</h2>
      <div class="timeline-subtitle">
        <span *ngIf="budgetDates">{{ budgetDates.from | date : 'd MMM, y' }} - {{ budgetDates.to | date : 'd MMM, y'
          }}</span>
        <b *ngIf="companyCurrency">{{ companyCurrency.symbol + ' ' + companyCurrency.code }}</b>
      </div>
    </div>
  </div>
  <div class="timeline-content widget-content-graph">
    <spending-timeline
      [cegFlagEnabled]="true"
      [data]="data"
      [companyCurrency]="companyCurrency.code"
      [selectedTimeframeIds]="currentFilters[FilterName.Timeframes]"
      [isManageTableTimeline]="true"      
      ></spending-timeline>
  </div>
</div>