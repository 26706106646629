<article class="widget-page app-page box-newTopNav new-nav">
  <page-header pageName="Dashboard">
    <fa-icon slot="icon" [icon]="['fas', 'tachometer-alt']"></fa-icon>
    <div slot="status" *ngIf="selectedBudget?.status">[{{selectedBudget?.status}}]</div>
    <div slot="name">{{selectedBudget?.name}}</div>
  </page-header>
  <section class="page-content">
    <!-- We should keep 'fullWidth-*' classes for some legacy styles defined in default.css yet -->
    <div
      class="page-container fullWidth-timeLine fullWidthH-hierarchy fullWidth-spendByType fullWidth-spendByGoal"
      [class.no-data]="!budgetTimeframeList.length && showEmptyMsg"
    >
      <div class="no-widgets-placeholder" *ngIf="!budgetTimeframeList.length && showEmptyMsg">
        <div class="box-planSettingMSG">
          <p>{{validations.ValiditionMessages.CREATE_BUDGET_FIRST}}</p>
        </div>
      </div>

      <section class="widgets-list" *ngIf="budgetTimeframeList.length > 0 && !showEmptyMsg">
        <!-- SPENDING TIMELINE -->
        <widget-container
          id="budgetTimeLineGraph"
          class="box-topTimeLine"
          [actionItems]="timelineWidgetActionItems"
          [allowSwitchViews]="!cegStatusEnabled"
          [tableView]="widgetViewModeByType[WidgetType.SpendingTimeline] === WidgetViewMode.Table"
          (isTableViewChanged)="handleSpendingTimelineViewChange($event)"
        >
          <div class="widget-heading text-center">
            <h2 class="widget-title">Spending Timeline</h2>
            <p class="widget-subtitle">
              <span *ngIf="budgetDates">{{ budgetDates.from | date : 'd MMM, y' }} - {{ budgetDates.to | date : 'd MMM, y' }}</span><br>
              <b *ngIf="selectedBudget">{{ companyCurrency.symbol + ' ' + companyCurrency.code }}</b>
            </p>
          </div>
          <div class="widget-content-graph timeline-content">
            <spending-timeline
              [data]="timeframeGraphData"
              [companyCurrency]="companyCurrency.code"
              [selectedTimeframeIds]="currentFilters[FilterName.Timeframes]"
              [selectedStatusNames]="selectedStatusNames"
              [cegFlagEnabled]="cegStatusEnabled"
            ></spending-timeline>
          </div>
          <div class="widget-content-table">
            <budget-timeline-table
              [bars]="bars"
              [timeframeGraphData]="timeframeGraphData"
              [selectedTimeframeNames]="selectedTimeframeNames"
              [selectedStatusNames]="selectedStatusNames"
            ></budget-timeline-table>
          </div>
        </widget-container>

        <!-- BUDGET HIERARCHY -->
        <widget-container
          id="budgetHierarchyGraph"
          class="box-topHierarchy budget-hierarchy-widget"
          [actionItems]="hierarchyWidgetActionItems"
          [allowSwitchViews]="false"
          [tableView]="true"
          *ngIf="!cegStatusEnabled"
        >
          <div class="widget-heading text-center">
            <h2 class="widget-title">Budget Hierarchy</h2>
            <p class="widget-subtitle">
              <span *ngIf="budgetDates">{{ budgetDates.from | date : 'd MMM, y' }} - {{ budgetDates.to | date : 'd MMM, y' }}</span><br>
              <b *ngIf="selectedBudget">{{ companyCurrency.symbol + ' ' + companyCurrency.code }}</b>
            </p>
          </div>
          <div class="widget-content-table">
            <budget-hierarchy-table
              [budgetGraphData]="budgetGraphData"
              [selectedStatusNames]="selectedStatusNames"
              [selectedSegment1Names]="selectedSegmentNames"
            ></budget-hierarchy-table>
          </div>
        </widget-container>

        <!-- SPEND BY TYPE -->
        <widget-container
          id="spendByTypeGraph"
          class="spend-byType spend-by-type-widget"
          [actionItems]="spendByTypeWidgetActionItems"
          [allowSwitchViews]="true"
          [tableView]="widgetViewModeByType[WidgetType.SpendByType] === WidgetViewMode.Table"
          (isTableViewChanged)="handleSpendByTypeViewChange($event)"
        >
          <div class="widget-heading text-center">
            <h2 class="widget-title">Spend by type</h2>
            <p class="widget-subtitle">
              <span *ngIf="budgetDates">{{ budgetDates.from | date : 'd MMM, y' }} - {{ budgetDates.to | date : 'd MMM, y' }}</span><br>
              <b *ngIf="selectedBudget">{{ companyCurrency.symbol + ' ' + companyCurrency.code }}</b>
            </p>
          </div>
          <div class="widget-content-graph box-spendByTypeChart" id="box-spendByTypeChart" [hidden]="spendTypeGraphData.length <= 0">
            <google-chart #spendByType [data]="columnChartData" (chartSelect)='selectSpendTypeChart($event)'></google-chart>
          </div>
          <div class="widget-content-graph box-UnallocatedError" *ngIf="spendTypeGraphData.length <= 0">
            <p>You don’t have any Expenses.</p>
          </div>
          <div class="widget-content-table">
            <spend-by-type-table
              [spendByTypeData]="spendTypeGraphData"
              [selectedStatusNames]="selectedStatusNames"
              [isCEGBudget]="isNewCampaignsProgramsStructure"
            >
            </spend-by-type-table>
          </div>
        </widget-container>
      </section>

      <statuses-legend [cegFlagEnabled]="cegStatusEnabled" *ngIf="selectedBudget"></statuses-legend>
    </div>
  </section>
</article>
<div class="budget-amounts-tooltip" [ngStyle]="budgetAmountsTooltipStyles" [ngClass]="budgetAmountsTooltipClass">
  <ng-template #budgetAmountsTooltip></ng-template>
</div>
