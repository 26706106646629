import { Injectable, OnDestroy } from '@angular/core';
import { UtilityService } from './utility.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserManager } from '../../user/services/user-manager.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { BudgetSegmentAccess } from '../types/segment.interface';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { BudgetStatus } from '../types/budget-status.type';
import { Budget } from '../types/budget.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { CompanyUserService } from '@shared/services/backend/company-user.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { getCurrentBudget$, getUserPermissionLevel } from '@shared/utils/common.utils';

export interface CompanyUserType {
  is_account_owner: boolean;
  is_admin: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserDataService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly editPermission = new BehaviorSubject<boolean>(null);
  public editPermission$ = this.editPermission.asObservable().pipe(
    filter(v => v !== null)
  );
  private currentFilters: FilterSet = {};

  constructor(
    private readonly companyUserService: CompanyUserService,
    private readonly utilityService: UtilityService,
    private readonly budgetDataService: BudgetDataService,
    private readonly userManager: UserManager,
    private readonly filterManagementService: FilterManagementService
  ) {
      this.filterManagementService.budgetFiltersInit$.pipe(
        switchMap(() =>
          this.filterManagementService.currentFilterSet$.pipe(skip(1))
        ),
        tap(filterSet => this.currentFilters = {...filterSet})
      );
    combineLatest([
      this.userManager.currentCompanyUser$.pipe(filter(user => !!user)),
      this.budgetDataService.selectedBudget$.pipe(filter(budget => !!budget)),
      this.budgetDataService.segmentList$.pipe(map(segments => this.getSegmentsForPermission(segments)))
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([currentUser, budget, segments]) => {
        const editPermission = this.defineUserEditPermission(currentUser, budget, segments);
        this.setEditPermission(editPermission);
      })

    combineLatest([
      this.userManager.currentUser$,
      this.userManager.currentCompanyUser$,
      getCurrentBudget$(this.budgetDataService)
    ]).pipe(
      filter(([user, userData, budget]) => user && budget && userData?.company === budget.company),
    ).subscribe(([user, userData, budget]) => {
      const level = getUserPermissionLevel(user, userData, budget.id);
      this.userManager.setCurrentUserPermissionLevel(level);
    });
  }

  public loadCurrentCompanyUser(companyId: number, userId: number): Observable<CompanyUserDO> {
    return this.companyUserService.getCompanyUsers({ user: userId, company: companyId,  status: 'Active' })
      .pipe(
        map(companyUsers => companyUsers?.[0]),
        tap(companyUser => this.onCompanyUserLoaded(companyUser))
      );
  }

  private setEditPermission(permission: boolean) {
    // Timeout is used to make sure that unidirectional data change flow is not broken
    // for change detection cycle. TODO: this should be re-worked when working on: PUP-1281
    setTimeout(() => this.editPermission.next(permission));
  }

  public isRegularUser(userFlags: CompanyUserType) {
    return !this.isPowerUser(userFlags);
  }

  /**
   * 'powerUser' is an admin or accountOwner
   */
  public isPowerUser(userFlags: CompanyUserType): boolean {
    return this.isAdmin(userFlags) || userFlags?.is_account_owner;
  }

  public isAdmin(userFlags: CompanyUserType): boolean {
    return userFlags?.is_admin;
  }

  public get userEditPermission(): boolean {
    return this.editPermission.getValue();
  }

  private onCompanyUserLoaded(companyUser: CompanyUserDO) {
    this.userManager.setCurrentCompanyUser(companyUser);
  }

  private getSegmentsForPermission(segments: BudgetSegmentAccess[]) {
    const selectedFirstSegments =
      this.currentFilters[FilterName.Segments] != null
        ? (segments || []).filter(seg =>
            this.currentFilters[FilterName.Segments].includes(seg.id)
          )
        : [];

    return selectedFirstSegments.length ? selectedFirstSegments : segments
  }

  private hasEditSegmentPermission(segments: BudgetSegmentAccess[], initEditPermission = false) {
    let editPermission = initEditPermission;
    segments.forEach((o) => {
      if (o.read_write === true) {
        editPermission = true;
      } else if (o.read === true) {
        editPermission = false;
      }
    });
    return editPermission;
  }

  private defineUserEditPermission(currentUser: CompanyUserDO, budget: Budget, segments: BudgetSegmentAccess[]) {
    if (budget.status === BudgetStatus.Reference) {
      return false;
    }

    if (currentUser.is_admin) {
      return true;
    }

    if (segments.length) {
      return this.hasEditSegmentPermission(segments);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
