<arrow-toggler
  *ngIf="isGroupOption"
  [active]="!isCollapsed"
  (change)="toggleGroup()"></arrow-toggler>

<mat-checkbox
  *ngIf="!singleSelectMode; else label"
  color="primary"
  class="pl-mat-checkbox primary-ripple"
  [checked]="isChecked"
  [indeterminate]="indeterminate"
  (change)="onSelectionChange($event.checked)"
  [disableRipple]="true"
  [disabled]="disabled"
>
  <ng-container [ngTemplateOutlet]="label"></ng-container>
</mat-checkbox>

<ng-template #label>
  <div class="option-text"
       [ngClass]="{ 'highlighted': highlightedOption }">
    <span *ngIf="prefix" class="prefix">{{ prefix }}</span>
    <span>{{ title }}</span>
  </div>
</ng-template>
