<th class="table-col object-controls-col">
  <div class="table-cell">
    <div class="selection">
      <mat-checkbox
        color="primary"
        class="control checkbox-control pl-mat-checkbox"
        (change)="handleSelectAllChange($event)"
        [checked]="selectAllValue === CheckboxValue.Active"
        [indeterminate]="selectAllValue === CheckboxValue.Indeterminate"
      ></mat-checkbox>
    </div>
    <span class="column-name">
      Name
      <sort-button
        [columnName]="SortableFields.SegmentName"
        [appliedSorting]="appliedSorting"
        (onClick)="applySorting(SortableFields.SegmentName)"
      >
      </sort-button>
    </span>
  </div>
</th>

<th class="table-col segment-breakdown-col">
  <div class="table-cell">
  </div>
</th>

<th class="table-col performance-col">
  <div class="table-cell">
    Perf.
  </div>
</th>

<ng-container *ngIf="allocationMode">
  <th
    class="table-col timeframe-col"
    *ngFor="let timeframe of timeframes; trackBy: identifyTimeframe"
    [class.highlighted-timeframe]="highlightedTimeframes.includes(timeframe.id)"
    [class.locked-timeframe]="timeframe.locked"
    [class.only-expense]="budget?.suppress_timeframe_allocations"
  >
    <div class="table-cell">
      <div class="timeframe-cell-header">
        <timeframe-lock-button
          [timeframe]="timeframe"
          [disabled]="false"
          [isCurrent]="timeframe.id === currentTimeframe?.id"
          [budget]="budget"
          [editable]="false"
          (onLockChange)="null"
        ></timeframe-lock-button>
      </div>
      <div class="column-cell-header">
        <div *ngIf="!budget?.suppress_timeframe_allocations">Budget</div>
        <div>Expenses</div>
      </div>
    </div>
  </th>
  <th class="table-col fy-total-col">
    <div class="table-cell">
      <div class="timeframe-cell-header">
        Total
      </div>
      <div class="column-cell-header">
        <div>Budget</div>
        <div>Expenses</div>
      </div>
    </div>
  </th>
</ng-container>

<ng-container *ngIf="spendingMode">
  <th class="table-col spending-col allocated-col">
    <div class="table-cell">
      Budget
    </div>
  </th>
  <th class="table-col spending-col total-expenses-col level-0">
    <div class="table-cell">
      <div class="formula-char operand"></div>
      Total Expenses<br>
      ({{ spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal] ? 'incl' : 'excl' }}. Planned)
    </div>
  </th>
  <th class="table-col spending-col closed-col level-0">
    <div class="table-cell">
      <div class="formula-char operand"></div>
      <div class="formula-char left-bracket"></div>
      <budget-status-icon
        [size]="10"
        [status]="statusFields?.closed"
      ></budget-status-icon>
      Closed
    </div>
  </th>
  <th class="table-col spending-col committed-col level-0">
    <div class="table-cell">
      <div class="formula-char operand"></div>
      <div class="formula-char right-bracket"></div>
      <budget-status-icon
        [size]="10"
        [status]="statusFields?.committed"
      ></budget-status-icon>
      Committed
    </div>
  </th>
  <th class="table-col spending-col total-remaining-col">
    <div class="table-cell">
      <div class="formula-char operand"></div>
      Total<br>
      Remaining
    </div>
  </th>
  <th class="table-col spending-col planned-col level-0">
    <div class="table-cell">
      <div class="formula-char operand"></div>
      <div class="formula-char left-bracket"></div>
      <div class="formula-char right-bracket"></div>
      <budget-status-icon
        [size]="10"
        [status]="statusFields?.planned"
      ></budget-status-icon>
      Planned
    </div>
  </th>
  <th class="table-col spending-col children-allocated-col">
    <div class="table-cell">
      <div class="formula-char operand"></div>
      <div class="formula-char right-bracket"></div>
      <budget-status-icon
        [size]="10"
        [status]="statusFields?.remainingAllocated"
      ></budget-status-icon>
      Remaining<br>
      Child Budget
    </div>
  </th>
  <th class="table-col spending-col total-available-col">
    <div class="table-cell">
      <div class="formula-char operand"></div>
      Total<br>
      Available
    </div>
  </th>
</ng-container>
