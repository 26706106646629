import { Component, Input } from '@angular/core';

@Component({
  selector: 'plc-page-header',
  templateUrl: './plc-page-header.component.html',
  styleUrls: ['./plc-page-header.component.scss']
})
export class PlcPageHeaderComponent {
  @Input() containerClassName = 'page-container';
  @Input() logoLink: string;
  @Input() withGradient = true;
}
