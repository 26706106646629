<ng-container *ngIf="currentUser">
  <header class="header-navigation">
    <div class="home-page-link navigation-element" [class.active]="activeRoute === routes.HOME">
      <a href="javascript:;" [routerLink] = "[routes.HOME]" queryParamsHandling="preserve">
        <span><img src="assets/images/planful-logo-colored.svg"/></span>
      </a>
    </div>
    <div class="navigation-panel">
      <creation-menu
        *ngIf="editPermission"
        class="navigation-element"
        [disabled]="!currentBudget?.id"
        [class.active]="activeRoute === routes.IMPORT_DATA"
      ></creation-menu>
      <managing-menu
        class="navigation-element"
        [editPermission]="editPermission"
        [disabled]="!currentBudget?.id"
        [class.active]="activeRoute === routes.PLAN_DETAIL"
      ></managing-menu>
      <span class="divider"></span>
      <div class="navigation-element" [class.active]="activeRoute === routes.DASHBOARD">
        <button
          mat-button
          class="header-action-item dashboard-link with-icon"
          (click)="navigateToDashboard()"
          [disabled]="!currentBudget?.id"
        >
          <fa-icon [icon]="['fas', 'tachometer-alt']"></fa-icon>
          <span class="btn-text">Dashboard</span>
        </button>
      </div>
      <span class="divider"></span>
      <history-menu class="navigation-element" [disabled]="!currentBudget?.id"></history-menu>
      <span class="divider"></span>
      <div class="navigation-element" [class.active]="activeRoute === routes.CALENDAR">
        <button
          mat-icon-button
          class="header-action-item with-icon"
          (click)="navigateToCalendar()"
          [disabled]="!currentBudget?.id"
        >
          <fa-icon [icon]="['fas', 'calendar-days']"></fa-icon>
        </button>
      </div>
      <span class="divider"></span>
      <filters-management *ngIf="!filterManagementService.customFilterMode && !hideFilters" [disabled]="!currentBudget?.id"></filters-management>
      <custom-filter-mode *ngIf="filterManagementService.customFilterMode" [mode]="filterManagementService.customFilterMode"></custom-filter-mode>
      <span class="divider"></span>
      <search-field></search-field>
      <span class="divider"></span>
      <div class="selection-container">
        <company-select></company-select>
        <budget-select></budget-select>
      </div>
      <settings-menu [ngClass]="{'hideSettings' : !currentUser.is_admin}" [showSettings]="currentUser.is_admin" [disabled]="!currentBudget?.id"></settings-menu>
      <profile-menu></profile-menu>
    </div>
  </header>
</ng-container>
