import { Campaign } from '../../../shared/types/campaign.interface';
import { ManageTableRow, ManageTableRowValues } from '../../components/manage-table/manage-table.types';
import { PlanObjectDataApplier } from './plan-object-data-applier';

export class CampaignDataApplier extends PlanObjectDataApplier {
  private readonly _campaign: Campaign;

  constructor(campaign: Campaign, rowValues: ManageTableRowValues) {
    super(rowValues);
    this._campaign = campaign;
  }

  get sourceObject(): Campaign {
    return this._campaign;
  }

  applyObjectData(row: ManageTableRow): void {
    super.applyObjectData(row);
    row.objectTypeId = this._campaign.campaignTypeId;
  }
}
