import { inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CampaignService } from '@shared/services/backend/campaign.service';
import { ProgramService } from '@shared/services/backend/program.service';
import { BudgetObjectAllocation, ProgramAllocation } from '@shared/types/budget-object-allocation.interface';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { Campaign, CampaignAllocationDO, CampaignDO } from '@shared/types/campaign.interface';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { DeepPartial } from '@shared/types/deep-partial.type';
import { Program, ProgramDO } from '@shared/types/program.interface';
import { BudgetSegmentAmountDO } from '@shared/types/segment.interface';
import {
  ManageCegTableRow,
  ManageTableBudgetColumnName,
  ManageTableTotalValues, ObjectStatusPayload,
  PresentationTimeframe
} from '@manage-ceg/types/manage-ceg-page.types';
import { BudgetSegmentService } from '@shared/services/backend/budget-segment.service';
import { BudgetAllocationService } from '@shared/services/backend/budget-allocation.service';
import { Goal } from '@shared/types/goal.interface';
import { GoalsService } from '@shared/services/backend/goals.service';
import { BulkActionTargets } from '@shared/types/bulk-action-targets.type';
import { BulkDeleteResponse, BulkOperationResponse } from '@shared/types/bulk-operation-response.interface';
import { ExternalIntegrationObjectTypes } from '@shared/constants/external-integration-object-types';
import { adsExpenseSources } from '@shared/types/expense.interface';
import { CompanyDataService } from '@shared/services/company-data.service';
import { IntegratedObjectsService } from '../../metric-integrations/services/integrated-objects.service';
import { ExpensesService } from '@shared/services/backend/expenses.service';
import { BulkActionPayloads } from '../../manage-table/types/bulk-action-payloads.type';
import { getNumericValue } from '@shared/utils/common.utils';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { CEGStatus } from '@shared/enums/ceg-status.enum';


export interface UpdatedSegmentAmountsData {
  amounts: BudgetSegmentAmountDO[];
  timeframe: BudgetTimeframe;
}

export interface DeleteObjectsResult {
  goals?: BulkDeleteResponse;
  campaigns?: BulkDeleteResponse;
  expGroups?: BulkDeleteResponse;
}

export interface UpdateObjectsResult {
  campaigns?: BulkOperationResponse<Partial<CampaignDO>>;
  expGroups?: BulkOperationResponse<Partial<ProgramDO>>;
}

export interface UpdateObjectsStatusResult {
  campaigns?: BulkOperationResponse<number>;
  expGroups?: BulkOperationResponse<number>;
}

@Injectable({
  providedIn: 'root'
})
export class ManageCegPageApiService {
  private readonly campaignService = inject(CampaignService);
  private readonly programService = inject(ProgramService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly budgetSegmentService = inject(BudgetSegmentService);
  private readonly budgetAllocationService = inject(BudgetAllocationService);
  private readonly goalService = inject(GoalsService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly integratedObjectsService = inject(IntegratedObjectsService);
  private readonly expenseService = inject(ExpensesService);

  private readonly ERROR_MESSAGE = {
    UNSUPPORTED_RECORD_TYPE: `Can't update this record type`,
    FAILED_TO_UPDATE_SEGMENT_ALLOCATION: 'Failed to update segment allocation',
  };

  private getIntegratedExpenseIds$(campaignIds: number[]): Observable<number[]> {
    const integrationCampaignTypeNames = Object.values(ExternalIntegrationObjectTypes);

    const adsTypeIds =
      this.companyDataService.campaignTypesSnapshot.filter(
        type => integrationCampaignTypeNames.includes(type.name)
      ).map(
        type => type.id
      );

    const integratedCampaignIds =
      campaignIds?.filter(
        campaignId => {
          const campaign =
            (this.budgetDataService.campaignsSnapshot || this.budgetDataService.lightCampaignsSnapshot)?.find(
              camp => camp.id === campaignId
            );
          return adsTypeIds.includes(campaign.campaignTypeId);
        }
      );

    return this.integratedObjectsService.getExpenseIds$(
      integratedCampaignIds,
      null,
      {
        company: this.companyDataService.selectedCompanySnapshot.id,
        budget: this.budgetDataService.selectedBudgetSnapshot.id,
        source: adsExpenseSources.join(',')
      }
    );
  }

  private runBulkOperationRequest(
    data: any[] | ObjectStatusPayload,
    resultItem: BulkOperationResponse<any>,
    apiMethod: Function
  ): Observable<BulkDeleteResponse> {
    if (!(data as ObjectStatusPayload)?.ids?.length && !(data as any).length) {
      return of(null);
    }

    return apiMethod(data).pipe(
      tap((response: BulkOperationResponse<any>) => {
        resultItem.success = response.success;
        resultItem.error = response.error;
      })
    );
  }

  public updateObjectAllocation(
    record: ManageCegTableRow,
    payload: Partial<BudgetObjectAllocation>
  ): Observable<ProgramAllocation | CampaignAllocationDO> {
    switch (record.type) {
      case ManageTableRowType.Campaign:
        return this.campaignService.updateAmountByTimeframe(record.objectId, payload);

      case ManageTableRowType.ExpenseGroup:
        return this.programService.updateAmountByTimeframe(record.objectId, payload);

      default:
        throw new Error(this.ERROR_MESSAGE.UNSUPPORTED_RECORD_TYPE);
    }
  }

  public updateSegmentAllocation(params: {
    objectId: number,
    timeframeId: number,
    segmentAmount: number,
    grandTotal: Record<PresentationTimeframe, ManageTableTotalValues>
  }): Observable<UpdatedSegmentAmountsData> {
    const { objectId, timeframeId, grandTotal, segmentAmount } = params;
    const timeframeValue = grandTotal[timeframeId][ManageTableBudgetColumnName.Budget];

    if (timeframeValue == null) {
      return throwError(() => new Error(this.ERROR_MESSAGE.FAILED_TO_UPDATE_SEGMENT_ALLOCATION));
    }

    const updateAmountsPayload: Partial<BudgetSegmentAmountDO>[] = [{
      company_budget_alloc: timeframeId,
      amount: segmentAmount
    }];
    const updateTimeframePayload: Partial<BudgetTimeframe> = { amount: timeframeValue };

    return this.budgetSegmentService.updateBudgetSegmentAmountsByTimeframe(objectId, updateAmountsPayload)
      .pipe(
        switchMap(updatedAmounts => this.budgetAllocationService.updateBudgetAllocation(timeframeId, updateTimeframePayload).pipe(
          map(updatedTimeframe => ({ amounts: updatedAmounts, timeframe: updatedTimeframe}))
        ))
      );
  }

  public checkObjectShareCostRule(record: ManageCegTableRow): Observable<void> {
    switch (record.type) {
      case ManageTableRowType.Campaign:
        return (record.sharedCostRuleId ? this.campaignService.split(record.objectId) : of(null));

      case ManageTableRowType.ExpenseGroup:
        return (record.sharedCostRuleId ? this.programService.split(record.objectId) : of(null));

      default:
        throw new Error(this.ERROR_MESSAGE.UNSUPPORTED_RECORD_TYPE);
    }
  }

  public createObjectFromTemplate(targetType: ManageTableRowType, template: DeepPartial<CampaignDO | ProgramDO>): Observable<Campaign | Program> {
    switch (targetType) {
      case ManageTableRowType.Campaign:
        return this.campaignService.createCampaign(template)
          .pipe(
            map(campaignDO => this.budgetDataService.convertCampaignDO(campaignDO))
          );

      case ManageTableRowType.ExpenseGroup:
        return this.programService.createProgram(template)
          .pipe(
            map(expGroupDO => this.budgetDataService.convertProgram(expGroupDO))
          );

      default:
        throw new Error(this.ERROR_MESSAGE.UNSUPPORTED_RECORD_TYPE);
    }
  }

  public duplicateAndFetchObject(target: ManageCegTableRow): Observable<Goal | Campaign | Program > {
    switch (target.type) {
      case ManageTableRowType.Goal:
        return this.goalService.cloneGoal(target.objectId)
          .pipe(
            switchMap(result => this.goalService.getGoal(result.id)),
            map(goal => this.budgetDataService.convertGoal(goal))
          );

      case ManageTableRowType.Campaign:
        return this.campaignService.cloneCampaign(target.objectId)
          .pipe(
            switchMap(result => this.campaignService.getCampaign(result.id)),
            map(campaign => this.budgetDataService.convertCampaignDO(campaign))
          );

      case ManageTableRowType.ExpenseGroup:
        return this.programService.cloneProgram(target.objectId)
          .pipe(
            switchMap(result => this.programService.getProgram(result.id)),
            map(expGroup => this.budgetDataService.convertProgram(expGroup))
          );

      default:
        throw new Error(this.ERROR_MESSAGE.UNSUPPORTED_RECORD_TYPE);
    }
  }

  public deleteObjects(targets: BulkActionTargets): Observable<DeleteObjectsResult> {
    const { goals = [], campaigns = [], expGroups = [] } = targets;
    const result: DeleteObjectsResult = {
      goals: {
        success: [],
        error: []
      },
      campaigns: {
        success: [],
        error: []
      },
      expGroups: {
        success: [],
        error: []
      }
    };

    return this.getIntegratedExpenseIds$(campaigns).pipe(
      switchMap(expenseIds =>
        expenseIds?.length ?
          this.expenseService.deleteMultiExpenses(expenseIds) :
          of(null)
      ),
      switchMap(() => this.runBulkOperationRequest(
        goals,
        result.goals,
        (ids: number[]) => this.goalService.deleteMultiGoals(ids)
      )),
      switchMap(() => this.runBulkOperationRequest(
        campaigns,
        result.campaigns,
        (ids: number[]) => this.campaignService.deleteMultiCampaigns(ids)
      )),
      switchMap(() => this.runBulkOperationRequest(
        expGroups,
        result.expGroups,
        (ids: number[]) => this.programService.deleteMultiPrograms(ids)
      )),
      map(() => result)
    );
  }

  public sumUpBulkOperationResults(results: BulkOperationResponse<any>[]): { success: number; error: number } {
    return results.reduce((count, item) => ({
      success: count.success + getNumericValue(item?.success?.length),
      error: count.error + getNumericValue(item?.error?.length),
    }), { success: 0, error: 0 });
  }

  public updateObjects(payloads: BulkActionPayloads<any>): Observable<UpdateObjectsResult> {
    const { campaigns = [], expGroups = [] } = payloads;
    const result: UpdateObjectsResult = {
      campaigns: {
        success: [],
        error: []
      },
      expGroups: {
        success: [],
        error: []
      }
    };

    return this.runBulkOperationRequest(campaigns, result.campaigns, (data) => this.campaignService.updateMultiCampaigns(data))
      .pipe(
        switchMap(() => this.runBulkOperationRequest(
          expGroups,
          result.expGroups,
          data => this.programService.updateMultiPrograms(data)
        )),
        map(() => result)
      );
  }

  public changeObjectAmountStatus(payloads: { [key: string]: ObjectStatusPayload }, status: CEGStatus): Observable<UpdateObjectsStatusResult> {
    const { campaigns, expGroups } = payloads;
    const result: UpdateObjectsStatusResult = {
      campaigns: {
        success: [],
        error: []
      },
      expGroups: {
        success: [],
        error: []
      }
    };

    if (status === CEGStatus.PLANNED) {
      return this.runBulkOperationRequest(campaigns, result.campaigns, (data) => this.campaignService.updateAmountStatusForCampaigns(data))
        .pipe(
          switchMap(() => this.runBulkOperationRequest(
            expGroups,
            result.expGroups,
            data => this.programService.updateAmountStatusForPrograms(data)
          )),
          map(() => result)
        );
    }

    return this.runBulkOperationRequest(expGroups, result.expGroups, (data) => this.programService.updateAmountStatusForPrograms(data))
      .pipe(
        switchMap(() => this.runBulkOperationRequest(
          campaigns,
          result.campaigns,
          data => this.campaignService.updateAmountStatusForCampaigns(data)
        )),
        map(() => result)
      );
  }

}
