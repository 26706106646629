<div class="details-content" [formGroup]="formData">
  <mat-form-field
    appearance="outline"
    class="p-form-field-outline"
    *ngIf="disabledItemData"
    [id]="id"
  >
    <mat-label *ngIf="disabledItemData.itemValue === null">{{
      labelName
    }}</mat-label>
    <div class="label-container">
      <div *ngIf="disabledItemData.itemValue" class="label">
        {{ labelName }} {{ isRequired ? "*" : " " }}
      </div>
    </div>
    <mat-select
      class="select"
      [placeholder]="disabledItemData.placeholderText"
      [required]="isRequired"
      [panelClass]="'reset-panel-width'"
      (selectionChange)="valueChanged($event)"
      [formControlName]="control"
    >
      <mat-select-trigger> {{ triggerName }}</mat-select-trigger>
      <mat-option *ngIf="addNullOption" [value]="null">{{
        nullOptionTitle
      }}</mat-option>
      <mat-option *ngFor="let item of optionsList" [value]="item.id">
        {{ buildOptionTitle(item) }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="hasError" class="no-wrap">
      {{ errorString }}
    </mat-error>
  </mat-form-field>
</div>
