import { Component, OnInit, Input, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import {
  AllocationsTableColumns,
  AllocationsTableColumnType,
  AllocationsTableData,
  AllocationsTableSubtitle,
  AllocationsTableDataItem,
  AllocationsTableEvent,
  AllocationsTableSubtitleChangeEvent,
  AllocationsTableGesturesDataSource
} from './allocations-table.type';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { BudgetAllocationTopupAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-topup-action';
import { BudgetAllocationMoveAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-move-action';
import { BudgetAllocationCellGesturesEvent } from 'app/budget-allocation/components/budget-allocation-cell/budget-allocation-cell.types';
import { MatDialog } from '@angular/material/dialog';
import { CampaignDetailsService } from '../../services/campaign-details.service';

@Component({
  selector: 'allocations-table',
  templateUrl: './allocations-table.component.html',
  styleUrls: ['./allocations-table.component.scss'],
})
export class AllocationsTableComponent implements OnInit, OnChanges {
  @Input() allowNegative = false;
  @Input() columns: AllocationsTableColumns = {};
  @Input() rows: string[];
  @Input() allocationsData: AllocationsTableData = {};
  @Input() subtitles: AllocationsTableSubtitle[] = [];
  @Input() currency: string;
  @Input() externalIntegrationType: { isExternal: boolean, integrationName: string };
  @Output() onChange = new EventEmitter<AllocationsTableEvent>();
  @Output() onSubtitleValueChange = new EventEmitter<AllocationsTableSubtitleChangeEvent>();

  columnsOrdering: string[];
  currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', allowNegative: false, prefix: '' };
  columnTypes = AllocationsTableColumnType;

  private allocationDialogShowed = false;
  private static getDataSourceAmount(dataSource: AllocationsTableGesturesDataSource) {
    return Number(dataSource.cell?.value || 0);
  }

  constructor(
    private elementRef: ElementRef,
    private readonly gesturesManager: BudgetAllocationActionsService<AllocationsTableGesturesDataSource>,
    private readonly decimalPipe: DecimalPipe,
    private dialog: MatDialog,
  ) {}

  get isGlobalDragStarted() {
    return this.gesturesManager.isDragging;
  }

  ngOnInit() {
    this.initTableColumns();
    this.currencyMaskOptions = { ...this.currencyMaskOptions, allowNegative: this.allowNegative };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allocationsData) {
      this.initTableColumns();
      this.refreshSelectOptions();
    }

    if (changes.allowNegative) {
      this.currencyMaskOptions = {
        ...this.currencyMaskOptions,
        allowNegative: this.allowNegative
      };
    }
  }

  trackByColumnName(index: number, item: AllocationsTableDataItem) {
    return item.column;
  }

  initTableColumns() {
    if (this.columns && !this.columnsOrdering) {
      this.setColumnsOrdering();
      const columnsNumber = Object.keys(this.columns).length ;
      this.elementRef.nativeElement.style.setProperty('--columns-number', columnsNumber);
    }
  }

  setColumnsOrdering() {
    if (this.rows && this.rows.length) {
      const allocationKey = Object.keys(this.allocationsData)[0];
      this.columnsOrdering = this.allocationsData[allocationKey].data.map(item => item.column);
    }
  }

  onDataChange(rowName: string, data: AllocationsTableDataItem) {
    this.onChange.emit({ rowName, data })
  }

  public handleSubtitleValueChange(column: string, value: number) {
    this.onSubtitleValueChange.emit({
      [column]: value
    });
  }

  refreshSelectOptions() {
    if (this.allocationsData) {
      Object.keys(this.allocationsData).forEach(key => {
        this.allocationsData[key].data.forEach(cell => {
          if (this.columns[cell.column]?.type === this.columnTypes.Select) {
            this.updateCellAvailableValues(this.allocationsData[key], cell)
          }
        })
      })
    }
  }

  updateCellAvailableValues(rowData, cell: AllocationsTableDataItem) {
    const column = this.columns[cell.column];
    if (column.optionsProvider) {
      cell.providedOptions = [];
      column.optionsProvider(rowData, options => {
        cell.providedOptions = options;
      })
    }
  }

  trackByAllocationName(allocationName) {
    return allocationName;
  }

  public handleAllocatedAmountChange(value: number, dataSource: AllocationsTableGesturesDataSource) {
    dataSource.cell.value = value;
    this.onChange.emit({
      rowName: dataSource.rowName,
      data: dataSource.cell
    })
  }

  public handleDoubleClick(dataSource: AllocationsTableGesturesDataSource, $event: BudgetAllocationCellGesturesEvent) {
    this.gesturesManager.executeAction(
      new BudgetAllocationTopupAction<AllocationsTableGesturesDataSource>({
        actionTarget: {
          dataSource,
          ...$event
        },
        setAmount: (value, ds) => {
          this.handleAllocatedAmountChange(value, ds);
        },
        getAmount: AllocationsTableComponent.getDataSourceAmount,
        currency: this.currency,
        decimalPipe: this.decimalPipe
      })
    )
  }

  public handleSubtitleDoubleClick(colName: string, value: number, $event: BudgetAllocationCellGesturesEvent) {
    this.gesturesManager.executeAction(
      new BudgetAllocationTopupAction<number>({
        actionTarget: {
          dataSource: value,
          ...$event
        },
        setAmount: (newValue) => {
          this.handleSubtitleValueChange(colName, newValue);
        },
        getAmount: (dataSource) => dataSource,
        currency: this.currency,
        decimalPipe: this.decimalPipe
      })
    )
  }

  public handleOnDrop(dataSource: AllocationsTableGesturesDataSource, $event: BudgetAllocationCellGesturesEvent) {
    this.gesturesManager.trackDrop(dataSource, $event);
    this.gesturesManager.executeAction(
      new BudgetAllocationMoveAction<AllocationsTableGesturesDataSource>({
        actionTarget: this.gesturesManager.getDndTargets(),
        setAmount: (value, ds) => {
          this.handleAllocatedAmountChange(value, ds);
        },
        getAmount: AllocationsTableComponent.getDataSourceAmount,
        currency: this.currency,
        decimalPipe: this.decimalPipe
      })
    )
  }

  public handleOnDragStart(dataSource: AllocationsTableGesturesDataSource, $event: BudgetAllocationCellGesturesEvent) {
    this.gesturesManager.trackDragStart(dataSource, $event);
  }

  public handleOnDragEnd() {
    this.gesturesManager.trackDragEnd();
  }

  public handleOnFocus(event: boolean): void {
    if (this.externalIntegrationType?.isExternal && event && !this.allocationDialogShowed) {
      this.allocationDialogShowed = true;
      CampaignDetailsService.showManualChangeConfirmDialog(this.externalIntegrationType.integrationName, this.dialog);
    }
  }
}
