import { Injectable } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BudgetTableService } from './budget-table.service';
import { BudgetTableRecord, BudgetTableRecordType } from '../components/budget-table/budget-table.types';
import {
  BudgetTableSelectedRecords,
  BudgetTableGroupSelection,
  BudgetTableSelectionState
} from '../types/budget-table-selection.types';
import { BudgetTableTogglingState } from '../types/budget-table-toggling-state.interface';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';


@Injectable({
  providedIn: 'root'
})
export class BudgetTableRecordInteractionsService {
  private _selectionState: BudgetTableSelectionState = {
    records: {},
    segments: {},
    groups: {}
  };
  public _togglingState: BudgetTableTogglingState = {
    global: {
      active: false,
    },
    groups: {}
  };

  constructor(
    private readonly budgetTableService: BudgetTableService,
  ) {}

  private initGroupSelection(groupKey: string): BudgetTableGroupSelection {
    if (!this.selectionState.groups[groupKey]) {
      const groupRecord = this.budgetTableService.getGroupRecordByKey(groupKey);

      this.selectionState.groups[groupKey] = {
        record: groupRecord,
        nestedValues: new Set<string>(),
        value: null
      };
    }

    return this.selectionState.groups[groupKey];
  }

  private selectSegmentInGroup(groupKey: string, recordKey: string) {
    const groupSelection = this.initGroupSelection(groupKey);
    const groupRecord = groupSelection?.record;

    groupSelection.nestedValues.add(recordKey);

    const groupBudgetTableSelectionValue = groupRecord?.nestedRecords?.length === groupSelection.nestedValues.size
      ?  CheckboxValue.Active
      :  CheckboxValue.Indeterminate;

    this.selectionState.records[groupKey] = groupBudgetTableSelectionValue;
    groupSelection.value = groupBudgetTableSelectionValue;
  }

  private deselectSegmentInGroup(groupKey: string, recordKey: string) {
    const groupSelection = this.selectionState.groups[groupKey];
    const group = groupSelection?.record;

    groupSelection.nestedValues.delete(recordKey);

    if (groupSelection.nestedValues.size === 0) {
      delete this.selectionState.records[groupKey];
      delete this.selectionState.groups[groupKey];
    } else {
      this.selectionState.records[group.key] = CheckboxValue.Indeterminate;
      groupSelection.value = CheckboxValue.Indeterminate;
    }
  }

  private setSegmentSelection(record: BudgetTableRecord) {
    const hasGroup = record.segmentGroupKey != null;
    const groupKey = record.segmentGroupKey;

    this.selectionState.records[record.key] = CheckboxValue.Active;
    this.selectionState.segments[record.key] = CheckboxValue.Active;
    if (hasGroup) {
      this.selectSegmentInGroup(groupKey, record.key);
    }
  }

  private resetSegmentSelection(record: BudgetTableRecord) {
    const hasGroup = record.segmentGroupKey != null;
    const groupKey = record.segmentGroupKey;

    Reflect.deleteProperty(this.selectionState.records, record.key);
    Reflect.deleteProperty(this.selectionState.segments, record.key);

    if (hasGroup) {
      this.deselectSegmentInGroup(groupKey, record.key);
    }
  }

  private setGroupSelection(groupRecord: BudgetTableRecord) {
    const groupSelection = this.initGroupSelection(groupRecord.key);

    this.selectionState.records[groupRecord.key] = CheckboxValue.Active;
    groupSelection.value = CheckboxValue.Active;
    groupRecord.nestedRecords.forEach(nestedRecord => {
      this.selectionState.groups[groupRecord.key].nestedValues.add(nestedRecord.key);
      this.selectionState.records[nestedRecord.key] = CheckboxValue.Active;
      this.selectionState.segments[nestedRecord.key] = CheckboxValue.Active;
    });
  }

  private resetGroupSelection(groupRecord: BudgetTableRecord) {
    Reflect.deleteProperty(this.selectionState.records, groupRecord.key);
    Reflect.deleteProperty(this.selectionState.groups, groupRecord.key);
    groupRecord.nestedRecords.forEach(nestedRecord => {
      Reflect.deleteProperty(this.selectionState.records, nestedRecord.key);
      Reflect.deleteProperty(this.selectionState.segments, nestedRecord.key);
    });
  }

  private handleSegmentSelection(checked: boolean, record: BudgetTableRecord) {
    if (checked) {
      this.setSegmentSelection(record);
    } else {
      this.resetSegmentSelection(record);
    }
  }

  private handleGroupSelection(checked: boolean, record: BudgetTableRecord) {
    if (checked) {
      this.setGroupSelection(record);
    } else {
      this.resetGroupSelection(record);
    }
  }

  public get selectionState() {
    return this._selectionState;
  }

  public resetSelection() {
    this._selectionState = {
      records: {},
      segments: {},
      groups: {}
    };
  }

  public get togglingState() {
    return this._togglingState;
  }

  public resetToggling() {
    this._togglingState = {
      global: {
        active: false,
      },
      groups: {}
    };
  }

  public getSelectedRecords(): BudgetTableSelectedRecords {
    const groupsSelectionState = this.selectionState.groups;
    const selectedGroups = Object.keys(groupsSelectionState)
      .filter(key => groupsSelectionState[key].value === CheckboxValue.Active);

    return {
      segments: Object.keys(this.selectionState.segments),
      groups: selectedGroups
    };
  }

  public handleSelection(value: MatCheckboxChange, record: BudgetTableRecord) {
    const { checked } = value;

    if (record.type === BudgetTableRecordType.Segment) {
      this.handleSegmentSelection(checked, record);
    } else {
      this.handleGroupSelection(checked, record);
    }
  }

  public handleToggleChange(record: BudgetTableRecord, value: boolean) {
    if (value) {
      this.togglingState.groups[record.key] = value;
    } else {
      Reflect.deleteProperty(this.togglingState.groups, record.key);
    }

    const activeGroupsCount = Object.keys(this.togglingState.groups).length;
    const groupsCount = this.budgetTableService.groupsList.length;

    this._togglingState.global.active = activeGroupsCount === groupsCount;
  }

  public handleToggleAllChange(value: boolean) {
    this._togglingState = {
      global: {
        active: value
      },
      groups: {}
    };

    if (value) {
      this.budgetTableService.groupsList.forEach(group => {
        this.togglingState.groups[group.key] = value;
      });
    }
  }
}
