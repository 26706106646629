import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectId'
})
export class ObjectIdPipe implements PipeTransform {
  transform(objectnName: string, objectType: string): number | null {
    if (!objectnName) {
      return null;
    }
    const [ objType, objId ] = objectnName.split('_');
    return objType === objectType ? Number(objId)  : null;
  }
}