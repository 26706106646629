import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'app/app.constants';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface ExchangeRateDO {
  date: string;
  rate: number;
}

interface ExchangeRate {
  date: string,
  rates: {
    [currency: string]: number;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MasterExchangeRateService {
  public exchangeRateServiceUrl: string;

  constructor(public http: HttpClient, public configuration: Configuration) {
    this.exchangeRateServiceUrl = this.configuration.exchange_rate_service_url;
  }

  getDailyRates(targetCurrency: string, sourceCurrencies?: string[]): Observable<ExchangeRate> {
    const requests = sourceCurrencies.map(currency => this.http.get(
      `${this.exchangeRateServiceUrl}${currency}/${targetCurrency}`
    ));

    if (!requests.length) {
      return of(null);
    }

    return forkJoin(requests).pipe(
      map((responses: ExchangeRateDO[]) =>
        ({
          date: responses[0].date,
          rates: sourceCurrencies.reduce((acc, el, i) => {
            const key = `${el}>${targetCurrency}`;
            acc[key] = responses[i].rate;
            return acc;
          }, {})
        })
      )
    );
  }
}
