import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WidgetConfig, WidgetState, WidgetType } from '../types/widget.interface';

interface WidgetStateValues {
  [key: string]: WidgetState
}

interface WidgetClassData {
  [key: string]: boolean;
}

export interface StateChangedEvent {
  values: WidgetStateValues;
  target: {
    type: WidgetType;
    state: WidgetState;
    classData: WidgetClassData;
  }
}

enum ScreenSize {
  Medium = 'md'
}

@Injectable({
  providedIn: 'root'
})
export class WidgetStateService {
  private readonly stateChanged = new Subject<StateChangedEvent>();
  private registeredWidgets = new Map<string, WidgetConfig>();
  public widgetStates = new Map<string, WidgetState>();
  public readonly stateChanged$ = this.stateChanged.asObservable();

  private getStateValues(): WidgetStateValues {
    const values = {};
    for (const [type, state] of this.widgetStates.entries()) {
      values[type] = state;
    }

    return values;
  }

  private getSizeClassName(width: number, height: number): string {
    return `w-size-${width}-${height}`;
  }

  private getOffsetClassName(offset: number, xAxis: boolean, screenSize = null): string {
    const screenSizePrefix = screenSize ? `-${screenSize}` : '';
    return `w${screenSizePrefix}-${ xAxis ? 'col' : 'row' }-offset-${offset}`;
  }

  public registerWidgets(widgets: WidgetConfig[]) {
    if (!widgets && !widgets.length) {
      return;
    }

    widgets.forEach((widget) => {
      this.registeredWidgets.set(widget.type, widget);
    });
  }

  /**
   * Prepares classData map for ngClass directive
   * based on widget's config and current state
   */
  public getClassData(ws: WidgetState, config: WidgetConfig): WidgetClassData {
    const { grid, gridMd, hideIfEmpty, hideIfFailed } = config;
    const classData: WidgetClassData = {
      processed: true
    };
    const sizeClass = this.getSizeClassName(grid.width, grid.height);
    const minSizeClass = this.getSizeClassName(grid.minWidth || grid.width, grid.minHeight || grid.height);

    if (grid.offsetX) {
      classData[this.getOffsetClassName(grid.offsetX, true)] = true;
    }
    if (gridMd && gridMd.offsetX) {
      classData[this.getOffsetClassName(gridMd.offsetX, true, ScreenSize.Medium)] = true;
    }

    if (grid.offsetY) {
      classData[this.getOffsetClassName(grid.offsetY, false)] = true;
    }
    if (gridMd && gridMd.offsetY) {
      classData[this.getOffsetClassName(gridMd.offsetY, false, ScreenSize.Medium)] = true;
    }

    switch (ws) {
      case WidgetState.READY:
        classData[sizeClass] = true;
      break;
      case WidgetState.FAILED:
        classData[sizeClass] = true;
        classData.hidden = hideIfFailed;
      break;
      case WidgetState.EMPTY:
        classData[minSizeClass] = true;
        classData.hidden = hideIfEmpty;
      break;
      case WidgetState.LOADING:
        classData[sizeClass] = true;
      break;
    }

    classData[ws] = true;

    return classData;
  }

  public setState(state: WidgetState, config: WidgetConfig) {
    if (!config || !this.registeredWidgets.get(config.type)) {
      return;
    }

    const classData = this.getClassData(state, config);
    this.widgetStates.set(config.type, state);
    this.stateChanged.next({
      values: this.getStateValues(),
      target: {
        type: config.type,
        state,
        classData
      }
    });
  }
}
