import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  HostListener,
  ViewChild,
  ViewChildren,
  ElementRef
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { debounce } from 'underscore';
import { OptionsSelectItem } from 'app/shared/components/options-select/options-select.component';
import {
  SharedCostRule,
  SharedCostRuleChangeSegmentEvent,
  SharedCostRuleSegment,
  SharedCostRuleStatus,
  SharedCostRuleSummary,
  SharedCostRulePermissions,
  SharedCostRuleActiveField,
  SharedCostRuleFieldType
} from 'app/shared/types/shared-cost-rule.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { transition, trigger, style, animate, group } from '@angular/animations';
import { SimpleTooltipPosition } from 'app/shared/components/simple-tooltip/simple-tooltip.type';
import { fromEvent } from 'rxjs';
import { KeyCodesConstants } from 'app/shared/constants/key-codes.constants';
import {
  SCRFocusStrategy,
  SCRInitStateFocusStrategy,
  SCRBudgetFocusStrategy,
  SCRNameFocusStrategy,
  SCRAddSegmentFocusStrategy,
  SCRSegmentIdFocusStrategy,
  SCRCostFocusStrategy,
  SCRStatusFocusStrategy
} from './scr-focus-strategies';
import { SharedCostRulesManagementComponent } from '../shared-cost-rules-management/shared-cost-rules-management.component';

const MAX_SEGMENT_SHARE_VALUE = 99.99;
const CORRECT_TOTAL_VALUE = 100;
const DEBOUNCE_INTERVAL = 1000;
const DELETE_RULE_CONFIRMATION_MESSAGE = 'Are you sure you want to delete this rule?';
const ACTIVATE_RULE_CONFIRMATION_MESSAGE = 'Are you sure you want to activate this rule?';
const DEACTIVATE_RULE_CONFIRMATION_MESSAGE = 'Are you sure you want to retire this Shared Cost rule? This cannot be undone.';

@Component({
  selector: 'shared-cost-rule',
  templateUrl: './shared-cost-rule.component.html',
  styleUrls: ['./shared-cost-rule.component.scss'],
  animations: [
    trigger('rowTransition', [
      transition(':enter', [
        style({ opacity: '0', height: '0', transformOrigin: '50% 0', transform: 'scale(0.9)' }),
        group([
          animate('.15s ease-out', style({ opacity: '1' })),
          animate('.3s ease-out', style({ height: '*' })),
          animate('.3s ease-out', style({ transform: '*' }))
        ])
      ]),
      transition(':leave', [
        style({ opacity: '1', transformOrigin: '50% 0', }),
        group([
          animate('.15s .15s ease-out', style({ opacity: '0' })),
          animate('.3s ease-out', style({ transform: 'scale(0.9)' })),
          animate('.3s ease-out', style({ height: '0' })),
        ])
      ]),
    ]),
    trigger('blockInitialTransition', [ transition( ':enter', [] ) ])
  ],
})
export class SharedCostRuleComponent implements OnChanges {
  @Input() rule: SharedCostRule;
  @Input() budgetList: Budget[];
  @Input() segmentList: BudgetSegmentAccess[];
  @Input() canEdit = false;
  @Output() updateBudget = new EventEmitter<number>();
  @Output() updateSegment = new EventEmitter<SharedCostRuleChangeSegmentEvent>();
  @Output() updateSegmentCost = new EventEmitter<SharedCostRuleChangeSegmentEvent>();
  @Output() updateRuleName = new EventEmitter<string>();
  @Output() deleteRule = new EventEmitter<void>();
  @Output() updateStatus = new EventEmitter<boolean>();
  @Output() addNewSegment = new EventEmitter<void>();
  @Output() deleteSegment = new EventEmitter<SharedCostRuleSegment>();

  @ViewChild('budgetElement', { read: ElementRef, static: true }) budgetElement;
  @ViewChild('nameElement', { read: ElementRef, static: true }) nameElement;
  @ViewChild('addSegmentElement', { read: ElementRef, static: true }) addSegmentElement;
  @ViewChild('statusElement', { read: ElementRef, static: true }) statusElement;
  @ViewChildren('segmentIdElement') segmentIdElements;
  @ViewChildren('costElement') costElements;

  TooltipPosition = SimpleTooltipPosition;
  currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', prefix: '' };
  decimalPipeMask = '1.2-2';
  maxVisibleSegments = 12;
  fieldTypes = SharedCostRuleFieldType;
  focusedField: SharedCostRuleActiveField = null;
  activeDropdown: SharedCostRuleActiveField = null;
  statusTypes = SharedCostRuleStatus;
  permissions: SharedCostRulePermissions = {
    editSegments: false,
    addSegments: false,
    deleteSegments: false,
    deleteRule: false,
    updateStatus: false,
    editRule: false
  };
  currentBudgetName = '';
  currentStatus = SharedCostRuleStatus.Inactive;
  summary: SharedCostRuleSummary = {
    isTotalCorrect: true,
    total: 0,
    totalText: '0.00',
    overflow: 0,
    overflowText: '0.00',
    activeSegments: 0
  };

  /* Dropdown options */
  budgetOptions: OptionsSelectItem[] = [];
  segmentOptions: OptionsSelectItem[] = [];
  statusOptions: OptionsSelectItem[] = [
    {
      value: SharedCostRuleStatus.Active,
      title: 'Activate',
      isIndented: false,
      isUnselectable: false
    },
    {
      value: SharedCostRuleStatus.Inactive,
      title: 'Retire',
      isIndented: false,
      isUnselectable: false,
      isDivider: true
    }
  ];
  segmentNamesMap = {};
  // Set of selected segment IDs
  selectedSegments = new Set<number>();
  tooltipTypes = {
    name: 'name',
    addSegment: 'add-segment',
    selectAllSegments: 'select-all-segments',
    total: 'total',
    retire: 'retire',
    budget: 'budget',
    ruleActivated: 'rule-activated'
  };
  activeTooltip = null;
  activeTooltipCoords = { x: 0, y: 0 };
  outsideClickSubscription = null;
  focusedFieldType = null;
  focusedSegmentIndex = -1;
  focusedElement = null;
  initStateFocusStrategy: SCRFocusStrategy = new SCRInitStateFocusStrategy(this);
  focusStrategies: Partial<Record<SharedCostRuleFieldType, SCRFocusStrategy>> = {
    [SharedCostRuleFieldType.Budget]: new SCRBudgetFocusStrategy(this),
    [SharedCostRuleFieldType.Name]: new SCRNameFocusStrategy(this),
    [SharedCostRuleFieldType.AddSegment]: new SCRAddSegmentFocusStrategy(this),
    [SharedCostRuleFieldType.SegmentId]: new SCRSegmentIdFocusStrategy(this),
    [SharedCostRuleFieldType.Cost]: new SCRCostFocusStrategy(this),
    [SharedCostRuleFieldType.Status]: new SCRStatusFocusStrategy(this),
  };
  currentFocusStrategy: SCRFocusStrategy = this.initStateFocusStrategy;

  costUpdateHandler = ({ segment, value }) => {
    this.summary = this.calculateSummary();
    this.updateSegmentCost.next({ segment, value });
    this.updatePermissions();
  };

  constructor(private ref: ChangeDetectorRef, private decimalPipe: DecimalPipe, private keyCodesConstants: KeyCodesConstants) {
    this.costUpdateHandler = debounce(this.costUpdateHandler, DEBOUNCE_INTERVAL);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('budgetList' in changes) {
      this.budgetOptions = this.mapBudgetOptions(this.budgetList);
      this.currentBudgetName = this.getCurrentBudgetName();
    }

    if ('rule' in changes) {
      this.currentBudgetName = this.getCurrentBudgetName();
      this.currentStatus = this.getCurrentStatus();
      this.initSegmentMetaData();
      this.updatePermissions();
      this.summary = this.calculateSummary();
    }

    if ('segmentList' in changes) {
      this.segmentOptions = this.mapSegmentOptions(this.segmentList);
      this.initSegmentMetaData();
      this.updateSelectedSegments();
      this.updatePermissions();
    }
  }

  isRuleInactive(): boolean {
    return this.rule && this.rule.isActive === false;
  }

  isTotalCorrect(total: number): boolean {
    return total === CORRECT_TOTAL_VALUE;
  }

  areAllSegmentsSelected(): boolean {
    if (!this.rule.segments) {
      return false;
    }

    return !this.rule.segments.some((segment: SharedCostRuleSegment) => !segment.id && !!segment.cost);
  }

  /**
   * Transform cost value to formatted string 'dd.dd%'
   */
  formatCostValue(value: number): string {
    return this.decimalPipe.transform(value, this.decimalPipeMask);
  }

  /**
   * Display overflow value in 'dd.dd%' format.
   * Add '+' sign if overflow is positive.
   */
  formatOverflowValue(overflow: number): string {
    const sign = overflow > 0 ? '+' : '';
    const pipedValue = this.formatCostValue(Math.abs(overflow));

    return `${sign}${pipedValue}`;
  }

  /**
   * Check if there are unassigned segments
   */
  hasUnassignedSegments(): boolean {
    if (!this.segmentList || !this.segmentList.length) {
      return true;
    }

    return this.selectedSegments.size !== this.segmentList.length;
  }

  /**
   * Check if there are more defined segments in the rule than segments list contains.
   */
  hasEnoughSegments(): boolean {
    if (!this.rule || !this.segmentList || !this.segmentList.length) {
      return false;
    }

    return this.rule.segments.length >= this.segmentList.length;
  }

  /**
   * Retrieve selected budget name from the rule
   */
  getCurrentBudgetName(): string {
    const budgetId = this.rule && this.rule.budgetId;
    const currentBudget = (this.budgetList || []).find(item => item.id === budgetId);

    return currentBudget ? currentBudget.name : '';
  }

  /**
   * Retrieve selected SCR status from the rule
   */
  getCurrentStatus(): SharedCostRuleStatus {
    const isActive = this.rule && this.rule.isActive;

    return isActive ? SharedCostRuleStatus.Active : SharedCostRuleStatus.Inactive;
  }

  calculateSummary(): SharedCostRuleSummary {
    const total = this.calculateTotalValue();
    const isTotalCorrect = this.isTotalCorrect(total);
    const totalText = isTotalCorrect ? String(total) : this.formatCostValue(total);
    const overflow = this.calculateOverflowValue(total);
    const overflowText = this.formatOverflowValue(overflow);
    const activeSegments = this.calculateActiveSegments();

    return {
      isTotalCorrect,
      total,
      totalText,
      overflow,
      overflowText,
      activeSegments
    }
  }

  /**
   * Calculate diff between total and 'correct' value
   */
  calculateOverflowValue(value: number): number {
    return value - CORRECT_TOTAL_VALUE;
  }

  calculateTotalValue(): number {
    if (!this.rule) {
      return 0;
    }

    const totalValue = this.rule.segments.reduce((acc, item) => (acc + item.cost), 0);

    return SharedCostRulesManagementComponent.roundPercentage(totalValue);
  }

  /**
   * Calculate amount of 'active' segments
   * Segment is active when segment ID is selected and cost is not equal to 0
   */
  calculateActiveSegments(): number {
    if (!this.rule) {
      return 0;
    }

    return this.rule.segments.reduce((acc, item) => {
      const isActive = item.id && item.cost > 0;

      return isActive ? acc + 1 : acc;
    }, 0);
  }

  /**
   * Initialize segments meta data (retrieve segment names from IDs)
   */
  initSegmentMetaData() {
    if (!this.segmentList || !this.segmentList.length) {
      return;
    }

    this.segmentList.forEach(segment => {
      this.segmentNamesMap[segment.id] = segment.name;
    });
  }

  /**
   * Checks selected segments
   */
  updateSelectedSegments() {
    if (!this.rule) {
      return;
    }

    this.selectedSegments.clear();
    this.rule.segments.forEach(item => {
      if (item.id) {
        this.selectedSegments.add(item.id);
      }
    });
    this.segmentOptions.forEach(item => {
      item.isUnselectable = this.selectedSegments.has(item.value);
    })
  }

  updateStatusOptions() {
    const isInactive = this.isRuleInactive();
    this.statusOptions.forEach(item => {
      if (!this.permissions.updateStatus) {
        return;
      }

      switch (item.value) {
        case this.statusTypes.Active:
          item.isUnselectable = !isInactive;
          break;

        case this.statusTypes.Inactive:
          item.isUnselectable = isInactive;
          break;
      }
    });
  }

  updatePermissions() {
    this.permissions.editSegments = this.canEditSegments();
    this.permissions.addSegments = this.canAddSegments();
    this.permissions.deleteSegments = this.canDeleteSegments();
    this.permissions.deleteRule = this.canDeleteRule();
    this.permissions.updateStatus = this.canUpdateStatus();
    this.permissions.editRule = this.canEditRule();

    this.updateStatusOptions();
  }

  canEditRule(): boolean {
    return this.isRuleInactive();
  }

  canUpdateStatus(): boolean {
    if (this.isRuleInactive()) {
      return this.canActivateRule();
    }

    return this.canDeactivateRule();
  }

  canActivateRule(): boolean {
    return this.rule.name && this.rule.budgetId
      && this.isTotalCorrect(this.calculateTotalValue()) && this.areAllSegmentsSelected();
  }

  canDeactivateRule(): boolean {
    return this.rule.instancesNumber === 0;
  }

  canEditSegments(): boolean {
    return !!this.rule.budgetId;
  }

  canAddSegments(): boolean {
    return this.isRuleInactive() && !!this.rule.budgetId && this.hasUnassignedSegments() && !this.hasEnoughSegments();
  }

  canDeleteSegments(): boolean {
    const segments = this.rule && this.rule.segments;
    const moreThanTwoSegments = segments.length > 2;

    return this.isRuleInactive() && this.canEditSegments() && moreThanTwoSegments;
  }

  canDeleteRule(): boolean {
    return this.isRuleInactive();
  }

  /**
   * Map budget list from Input to dropdown options
   */
  mapBudgetOptions(budgetList: Budget[] = []): OptionsSelectItem[] {
    return budgetList.map(item => ({
      value: item.id,
      title: item.name,
      isIndented: false,
      isUnselectable: false
    }));
  }

  /**
   * Map segment list from Input to dropdown options
   */
  mapSegmentOptions(segmentList: BudgetSegmentAccess[] = []): OptionsSelectItem[] {
    return segmentList.map(item => ({
      value: item.id,
      title: item.name,
      isIndented: false,
      isUnselectable: false
    }));
  }

  handleStatusSelect(selectedItem: OptionsSelectItem) {
    const isActive = selectedItem.value === SharedCostRuleStatus.Active;
    const confirmation = confirm(isActive ? ACTIVATE_RULE_CONFIRMATION_MESSAGE : DEACTIVATE_RULE_CONFIRMATION_MESSAGE);

    this.resetActiveDropdown();
    if (!confirmation) {
      return;
    }
    this.currentStatus = selectedItem.value;
    this.updateStatus.next(isActive);
    this.updatePermissions();
  }

  handleBudgetSelect(selectedItem: OptionsSelectItem) {
    this.resetActiveDropdown();
    this.updateBudget.next(selectedItem.value);
    this.currentBudgetName = this.getCurrentBudgetName();
    this.updatePermissions();
    this.summary = this.calculateSummary();
  }

  setActiveDropdown(type, segment = null) {
    this.activeDropdown = { type, segment };
    this.outsideClickSubscription = fromEvent(document, 'click')
      .subscribe((event: any) => {
        // Click outside open dropdown
        if (event.target.closest('.dropdown') === null) {
          this.resetActiveDropdown();
        }
      });
  }

  resetActiveDropdown() {
    this.activeDropdown = null;
    // Unsubscribe from outside click event, if subscription exists
    if (this.outsideClickSubscription) {
      this.outsideClickSubscription.unsubscribe();
    }
  }

  isActiveDropdown(type, segment = null): boolean {
    return (
      this.activeDropdown &&
      this.activeDropdown.type === type &&
      this.activeDropdown.segment === segment
    );
  }

  activateFocusStrategy(type, segmentIndex) {
    const focusStrategy = this.focusStrategies[type];
    if (!focusStrategy) {
      return;
    }

    focusStrategy.activate(segmentIndex);
  }

  toggleActiveDropdown(type, segment = null) {
    if (this.isActiveDropdown(type, segment)) {
      this.resetActiveDropdown();
      return;
    }

    this.setActiveDropdown(type, segment);
    const segmentIndex = segment ? this.rule.segments.indexOf(segment) : -1;
    this.activateFocusStrategy(type, segmentIndex);
  }

  handleSegmentSelect(selectedItem: OptionsSelectItem, segment: SharedCostRuleSegment) {
    if (!this.permissions.editRule || !this.permissions.editSegments) {
      return;
    }

    this.resetActiveDropdown();
    this.updateSegment.next({ segment, value: selectedItem.value });
    this.updateSelectedSegments();
    this.updatePermissions();
    this.summary.activeSegments = this.calculateActiveSegments();
  }

  handleSegmentValueChange(value, segment) {
    if (!this.permissions.editRule || !this.permissions.editSegments) {
      return;
    }

    let newValue = value;

    if (value > MAX_SEGMENT_SHARE_VALUE) {
      newValue = MAX_SEGMENT_SHARE_VALUE;
    }

    this.ref.detectChanges();
    this.costUpdateHandler({ segment, value: newValue });
    segment.cost = newValue;
  }

  handleFieldFocus({ focus, type, segment }) {
    if (!focus) {
      this.focusedField = null;
      return;
    }

    this.focusedField = { type, segment };
    const segmentIndex = this.rule.segments.indexOf(segment);
    this.focusStrategies[type].activate(segmentIndex);
  }

  handleRuleNameFocus() {
    this.focusStrategies[this.fieldTypes.Name].activate();
  }

  handleRuleNameUpdate($event) {
    if (!this.permissions.editRule) {
      return;
    }

     this.updateRuleName.next($event.target.value);
     this.updatePermissions();
  }

  handleSegmentAdd() {
    if (!this.permissions.editRule || !this.permissions.addSegments) {
      return;
    }

    this.addNewSegment.next();
    this.resetActiveDropdown();
    this.updatePermissions();
    this.summary = this.calculateSummary();
  }

  handleSegmentDelete(segment: SharedCostRuleSegment) {
    if (!this.permissions.editRule || !this.permissions.deleteSegments) {
      return;
    }

    this.deleteSegment.next(segment);
    this.summary = this.calculateSummary();
    this.updateSelectedSegments();
    this.updatePermissions();
  }

  handleRuleDelete() {
    if (!this.permissions.editRule || !this.permissions.deleteRule) {
      return;
    }

    const deleteConfirmed = confirm(DELETE_RULE_CONFIRMATION_MESSAGE);
    if (!deleteConfirmed) {
      return;
    }

    this.deleteRule.next();
  }

  /**
   * Check tooltip activation requirements
   */
  triggerTooltip(tooltip: string): boolean {
    switch (tooltip) {
      case this.tooltipTypes.name:
        return this.triggerNameTooltip();

      case this.tooltipTypes.budget:
        return this.triggerBudgetTooltip();

      case this.tooltipTypes.selectAllSegments:
        return this.triggerSelectAllSegmentsTooltip();

      case this.tooltipTypes.total:
        return this.triggerTotalTooltip();

      case this.tooltipTypes.retire:
        return this.triggerRetireTooltip();

      case this.tooltipTypes.ruleActivated:
        return this.triggerRuleActivatedTooltip();

      // Display always
      case this.tooltipTypes.addSegment:
        return true;

      default:
        return false;
    }
  }

  /**
   * Name tooltip is shown when rule is inactive and no name is defined
   */
  triggerNameTooltip(): boolean {
    return this.isRuleInactive() && !this.rule.name;
  }

  /**
   * Budget tooltip is shown when rule is inactive and no budget is selected
   */
  triggerBudgetTooltip(): boolean {
    return this.isRuleInactive() && !this.rule.budgetId;
  }

  /**
   * Select All Segments tooltip is shown when rule is inactive and not all segments are selected
   */
  triggerSelectAllSegmentsTooltip(): boolean {
    return this.isRuleInactive() && !this.areAllSegmentsSelected();
  }

  /**
   * Total tooltip is shown when rule is inactive and total % value is incorrect
   */
  triggerTotalTooltip(): boolean {
    return this.isRuleInactive() && !this.isTotalCorrect(this.calculateTotalValue());
  }

  /**
   * Retire tooltip is shown when rule is active but has attached instances.
   */
  triggerRetireTooltip(): boolean {
    return !this.isRuleInactive() && this.rule.instancesNumber !== 0;
  }

  /**
   * Tooltip is shown for any inputs of activated rule
   */
  triggerRuleActivatedTooltip(): boolean {
    return !this.isRuleInactive();
  }

  calculateTooltipPosition(target: Element) {
    const rect = target && target.getBoundingClientRect();
    const offsetY = 16;

    if (!rect) {
      return;
    }

    this.activeTooltipCoords = {
      x: rect.right,
      y: rect.top + offsetY,
    };
  }

  /**
   * Display first tooltip that matches 'trigger' requirements
   */
  displayTooltip(allowedTooltips: string[] = [], event = null) {
    for (const tooltip of allowedTooltips) {
      if (this.triggerTooltip(tooltip)) {
        this.activeTooltip = tooltip;
        if (tooltip === this.tooltipTypes.ruleActivated) {
          this.calculateTooltipPosition(event && event.target);
        }

        return;
      }
    }
  }

  hideTooltip() {
    this.activeTooltip = null;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e) {
    const { tab, arrowLeft, arrowRight } = this.keyCodesConstants;
    if (e.keyCode !== tab && e.keyCode !== arrowLeft && e.keyCode !== arrowRight) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    const moveForward = (e.keyCode === tab && !e.shiftKey) || e.keyCode === arrowRight;
    this.currentFocusStrategy.moveFocus(moveForward);
  }

  @HostListener('keydown.enter', ['$event'])
  onEnterClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.currentFocusStrategy.selectElement();
  }

  @HostListener('keydown.esc', ['$event'])
  onEscapeClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.currentFocusStrategy.unselectElement();
  }

  handleClickOutsideRule() {
    this.initStateFocusStrategy.activate();
  }

  focusElement(viewChild) {
    if (!viewChild) {
      return;
    }
    this.focusedElement = viewChild.nativeElement;
    this.focusedElement.focus();
  }
}
