<button
  mat-button
  class="select-all-btn"
  [class.is-active]="isAllSelected"
  [matTooltip]="tooltipTitle"
  [matTooltipShowDelay]="1000"
  [disabled]="disabled"
  #tooltip="matTooltip"
  matTooltipClass="dark-theme-tooltip simple above"
  matTooltipPosition="above"
  (click)="tooltip.hide(); toggleSelectAll()"
>
  <fa-icon [icon]="['fas', 'check-square']" class="navy-blue-icon"></fa-icon>
  <span>All</span>
</button>
