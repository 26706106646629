import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'add-metric-btn',
  templateUrl: './add-metric-btn.component.html',
  styleUrls: ['./add-metric-btn.component.scss']
})
export class AddMetricBtnComponent {
  @Input() extraClasses = '';
  @Output() addNewMetric: EventEmitter<boolean> = new EventEmitter<boolean>();
}
