import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'expense-verified-icon',
  templateUrl: './expense-verified-icon.component.html',
  styleUrls: ['./expense-verified-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpenseVerifiedIconComponent {
  @Input() size = 20;
}
