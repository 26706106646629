<div class="toggler"
     [matTooltip]="textTooltip"
     [matTooltipDisabled]="disabled || !isHidden"
     matTooltipShowDelay="800"
     matTooltipPosition="above"
     matTooltipClass="dark-theme-tooltip simple"
     [ngClass]="{ 'hidden-state': isHidden, 'disabled': disabled }"
     (click)="toggleState()"
>
  <svg class="icon" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 1.5625C2.25 1.13086 1.86914 0.75 1.4375 0.75C0.980469 0.75 0.625 1.13086 0.625 1.5625V9.6875C0.625 10.1445 0.980469 10.5 1.4375 10.5C1.86914 10.5 2.25 10.1445 2.25 9.6875V1.5625ZM11.7461 6.20898C12.0762 5.9043 12.0762 5.37109 11.7461 5.06641L8.49609 1.81641C8.19141 1.48633 7.6582 1.48633 7.35352 1.81641C7.02344 2.12109 7.02344 2.6543 7.35352 2.95898L9.20703 4.8125H4.6875C4.23047 4.8125 3.875 5.19336 3.875 5.625C3.875 6.08203 4.23047 6.4375 4.6875 6.4375H9.20703L7.35352 8.31641C7.02344 8.62109 7.02344 9.1543 7.35352 9.45898C7.6582 9.78906 8.19141 9.78906 8.49609 9.45898L11.7461 6.20898Z" fill="white"/>
  </svg>
</div>

