import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  HostListener,
  ViewChildren,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';

export interface OptionsSelectItem {
  value: any;
  title: string;
  context?: any;
  disabled?: boolean;
  isIndented: boolean;
  isUnselectable: boolean;
  isDivider?: boolean;
}

const TOP_OFFSET = 10;

@Component({
  selector: 'options-select',
  templateUrl: './options-select.component.html',
  styleUrls: ['./options-select.component.scss']
})
export class OptionsSelectComponent implements OnChanges, AfterViewInit {
  @Input() isMultipleSelect = false;
  @Input() showSelectAll = false;
  @Input() availableOptions: OptionsSelectItem[];
  @Input() selectedOptions: any[];
  @Input() lockedItemTooltip: string;
  @Input() disableKeyboard = false;
  @Input() useAutofocus = true;

  @Output() selectValue: EventEmitter<OptionsSelectItem> = new EventEmitter<OptionsSelectItem>();
  @Output() selectValueWithKeyboard: EventEmitter<OptionsSelectItem> = new EventEmitter<OptionsSelectItem>();
  @Output() multipleSelectionChanges: EventEmitter<OptionsSelectItem[]> = new EventEmitter<OptionsSelectItem[]>();
  @Output() multipleSelectionChangesWithKeyboard: EventEmitter<OptionsSelectItem[]> = new EventEmitter<OptionsSelectItem[]>();
  @Output() onUpdateSelection: EventEmitter<{value: boolean, option: OptionsSelectItem}[]> = new EventEmitter<{value: boolean, option: OptionsSelectItem}[]>();

  @ViewChildren('opt') options;
  @ViewChild('scrollContainer', { static: true }) scrollContainer;

  valueItems = [];
  focusItemIndex = -1;

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnChanges() {
    this.initSelection();
  }

  ngAfterViewInit() {
    this.initFocusedItem();
    this.scrollToFocusedElement();
    this.ref.detectChanges();
  }

  isFilterValueSelected(filterValue: OptionsSelectItem) {
    if (!this.selectedOptions) {
      return false;
    }
    return this.selectedOptions.some(fv => fv === filterValue.value);
  }

  updateSelection(option) {
    this.onUpdateSelection.emit([option]);
  }

  handleSelectValue(valueItem) {
    if (!this.isMultipleSelect && !valueItem.isUnselectable) {
      this.selectValue.next(valueItem);
    }
  }

  get allSelected () {
    return this.valueItems.every(v => v.isSelected);
  }

  set allSelected (value: boolean) {
    const updates = [];
    this.valueItems.forEach(v => {
      if (!v.disabled) {
        v.isSelected !== value && updates.push(v);
        v.isSelected = value;
      }
    });
    this.onUpdateSelection.emit(updates);
  }

  get selectedValueItem() {
    return this.valueItems.find(v => v.isSelected);
  }

  get filteredValues() {
    return this.valueItems.filter(v => v.isSelected && v.value).map(v => v.value);
  }

  get isApplyButtonAvailable(): boolean {
    return this.filteredValues && !!this.filteredValues.length;
  }

  handleApplySelectionChanges() {
    if (!this.isApplyButtonAvailable) {
      return;
    }

    this.multipleSelectionChanges.emit(this.filteredValues);
  }

  hasSelectedFilterValues() {
    return this.valueItems.some(v => v.isSelected);
  }

  clearSelection() {
    this.valueItems.filter(v => v.isSelected).forEach(v => {v.isSelected = false;});
  }

  initSelection() {
    if (!this.availableOptions) {
      return;
    }

    this.valueItems =
      this.availableOptions.map(v => ({
        value: v.value,
        title: v.title,
        context: v.context,
        disabled: v.disabled || false,
        isIndented: v.isIndented,
        isUnselectable: v.isUnselectable,
        isDivider: v.isDivider,
        isSelected: this.isFilterValueSelected(v)
      }));
  }

  initFocusedItem() {
    if(!this.disableKeyboard) {
      this.focusItemIndex = this.valueItems.indexOf(this.selectedValueItem);
    }
  }

  scrollToFocusedElement() {
    const focusedItem = this.options._results[this.focusItemIndex];
    if (!focusedItem) {
      return;
    }

    const focusedElement = focusedItem.nativeElement;
    const focusedElementTopPosition = focusedElement.offsetTop - TOP_OFFSET;
    this.scrollContainer.nativeElement.scrollTop = focusedElementTopPosition;
  }

  getItemIdentity(index, item) {
    return item.value;
  }

  @HostListener('document:keydown.arrowup', ['$event'])
  moveFocusUp(e: Event) {
    if (this.disableKeyboard) {
      return;
    }

    e.preventDefault();
    if (this.focusItemIndex <= 0) {
      this.focusItemIndex = this.valueItems.length - 1;
    } else {
      this.focusItemIndex--;
    }

    this.scrollToFocusedElement();
  }

  @HostListener('document:keydown.arrowdown', ['$event'])
  moveFocusDown(e: Event) {
    if (this.disableKeyboard) {
      return;
    }

    e.preventDefault();
    if (this.focusItemIndex < 0 || this.focusItemIndex >= this.valueItems.length - 1) {
      this.focusItemIndex = 0;
    } else {
      this.focusItemIndex++;
    }

    this.scrollToFocusedElement();
  }

  @HostListener('document:keydown.space', ['$event'])
  handleSelectValueWithKeyboard(e: Event) {
    if (this.disableKeyboard) {
      return;
    }

    e.preventDefault();
    const valueItem = this.valueItems[this.focusItemIndex] || this.selectedValueItem;
    if (!valueItem) {
      return;
    }

    if (!this.isMultipleSelect) {
      this.selectValueWithKeyboard.next(valueItem);
    } else {
      valueItem.isSelected = !valueItem.isSelected;
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleApplySelectionChangesWithKeyboard(e: Event) {
    if (this.disableKeyboard) {
      return;
    }

    e.preventDefault();
    if (this.isMultipleSelect) {
      this.multipleSelectionChangesWithKeyboard.emit(this.filteredValues);
    }
  }
}
