import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SpendingManagementMode } from '@spending/types/expense-page.type';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'select-all-banner',
  templateUrl: './select-all-banner.component.html',
  styleUrls: ['./select-all-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectAllBannerComponent {
  @Input() viewMode: SpendingManagementMode;
  @Input() isTotalSelected: boolean;
  @Input() pageSelectionNumber: number;
  @Input() totalSelectionNumber: number;

  @Output() toggleTotalSelected = new EventEmitter<boolean>();

  protected pageIcon: Record<SpendingManagementMode, IconProp> = {
    [SpendingManagementMode.Expenses]: ['fas', 'coins'],
    [SpendingManagementMode.Invoices]: ['fas', 'file-invoice-dollar'],
  };

  protected readonly spendingModes = SpendingManagementMode;

  toggleSelected(): void {
    this.toggleTotalSelected.emit(!this.isTotalSelected);
  }
}
