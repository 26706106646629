<div class="statuses-legend" [ngClass]="{'manage-table-timeline-legend': isManageTableTimeline}">
  <ng-container *ngIf="!cegFlagEnabled else legendCeg">
    <ul *ngFor="let dataItems of legendData">
      <li *ngFor="let listItem of dataItems" class="legend-item">
        <budget-status-icon [status]="listItem.status"></budget-status-icon>
        {{ listItem.title }}
        <title-description-tooltip
          [title]="listItem.tooltipTitle || listItem.title"
          [description]="listItem.tooltipDesc"
        >
          <ng-template #titleTemplate let-title>
            <span class="custom-title">
              <budget-status-icon [status]="listItem.status"></budget-status-icon>
              {{ title }}
            </span>
          </ng-template>
        </title-description-tooltip>
      </li>
    </ul>
  </ng-container>
</div>

<ng-template #legendCeg>
  <ul>
    <li *ngFor="let listItem of legendDataCeg" class="legend-item">
      <budget-status-icon [status]="listItem.status"></budget-status-icon>
      {{ listItem.title }}
    </li>
  </ul>
</ng-template>
