import { Injectable } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { HttpClient } from '@angular/common/http';
import { SalesforceCampaignMapping } from './salesforce-campaign-mapping.interface';
import { MetricsProviderDataService } from '../services/metrics-provider-data.service';

export interface CurrentMappingTargets {
  [externalCampaignId: string]: number;
}

@Injectable({
  providedIn: 'root'
})
export class SalesforceDataService extends MetricsProviderDataService<SalesforceCampaignMapping> {

  constructor(
    private readonly configuration: Configuration,
    private readonly httpClient: HttpClient
  ) {
    super(httpClient, configuration.salesforce_service_url);
  }

  public getProxyData(companyId: number, integrationId: string, endpoint: string, params?: object) {
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.proxy}${companyId}/${integrationId}`;
    return this.http.post(requestUrl, JSON.stringify({
      endpoint,
      params
    }));
  }

  public getExternalCampaignsPayload(records: any[]): any {
    const uniqueSFCampaignIds = new Set<string>();
    records.forEach(record => uniqueSFCampaignIds.add(record.Id));
    return Array.from(uniqueSFCampaignIds);
  }
}
