import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilityService } from 'app/shared/services/utility.service';
import { Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { AppDataLoader } from 'app/app-data-loader.service';
import { Budget } from 'app/shared/types/budget.interface';
import { ProgramTypeDO } from 'app/shared/types/program.interface';
import { TabSwitchOption } from '../../shared/components/tab-switch/tab-switch.types';
import { Configuration } from 'app/app.constants';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'attributes-and-tags',
  templateUrl: './attributes-tags.component.html',
  styleUrls: ['./attributes-tags.component.scss'],
  providers: [AppDataLoader]
})
export class AttributesAndTagsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private routes = this.config.ROUTING_CONSTANTS;
  private selectedBudget: Budget = null;
  companyId: number;

  public dataModeOptions: TabSwitchOption[] = [
        {
      title: 'Campaign / Expense Group Types',
      value: 'groupTypes',
      icon: ['far', 'rocket-launch']
    },
    {
      title: 'Expense Types',
      value: 'expenseTypes',
      icon: ['fas', 'coins']
    },
    {
      title: this.config.attributesAndTagsLabel.GOAL_TYPES,
      value: 'goalTypes',
      icon: ['fas', 'bullseye-arrow']
    },
    {
      title: 'Vendors',
      value: 'vendors',
      icon: ['fas', 'user-helmet-safety']
    },
    {
      title: 'GL Codes',
      value: 'glCodes',
      icon: ['fas', 'rectangle-barcode']
    },
    {
      title: 'Tags',
      value: 'tags',
      icon: ['fas', 'tags']
    }
  ];
  public dataMode = 'expenseTypes';

  enableSwitchTooltip = this.config.expenseTypesDescriptions.enableTooltip;
  routeSubscription: Subscription = null;
  isNavigationInProgress = false

  constructor(
    private readonly utilityService: UtilityService,
    private readonly companyDataService: CompanyDataService,
    public readonly modal: Modal,
    private readonly appDataLoader: AppDataLoader,
    private readonly budgetDataService: BudgetDataService,
    private config: Configuration,
    private route: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      this.setDataMode(params['mode']);
    })
  }

  ngOnInit(): void {
    this.companyDataService.selectedCompany$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null),
        switchMap(cmp => this.utilityService.expenseTypeListPropertyChanged$.pipe(map(() => cmp.id)))
      )
      .subscribe(companyId => {
        this.companyId = companyId;
      });

    this.companyDataService.selectedCompany$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null)
      )
      .subscribe(cmp => {
        this.companyId = cmp.id;
        this.companyDataService.loadCompanyData(this.companyId);
      });

    this.budgetDataService.selectedBudget$
      .pipe(
        tap(newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: (error) => this.utilityService.handleError(error)
      });

    this.appDataLoader.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    // Clearing up any existing route subscriptions
    this.routeSubscription?.unsubscribe();
  }

  setDataMode(eve: string) {
    this.dataMode = eve;
    const filters = {
      companyId: this.companyId,
      budgetId: this.selectedBudget?.id
    };
    const filtersString = JSON.stringify(filters);
    if(!this.isNavigationInProgress) {
      this.isNavigationInProgress = true; // Prevent further navigations until current one completes
      this.router.navigate(
        [this.routes.ATTRIBUTES_TAGS],
        { 
          queryParams: { 
            mode: eve,
            filter: filtersString
          },
        })
        .then(() => {
          this.isNavigationInProgress = false; // Reset flag after navigation completes
        })
        .catch(() => {
          this.isNavigationInProgress = false; // Reset flag even if navigation fails
        })
    }
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    this.selectedBudget = newSelectedBudget;
    if (newSelectedBudget != null) {
      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.config.campaignStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.config.programStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }
  }
}
