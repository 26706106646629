export type AllocationMode = 'Open' | 'Closed' | 'Planned' | 'Committed';

export interface BudgetObjectAllocation {
  id?: number;
  mode?: AllocationMode;
  amount?: number;
  actual_amount?: number;
  source_amount?: number;
  source_actual_amount?: number;
  company_budget_alloc: number;
  company: number;
  crd?: string;
  upd?: string;
  lock_for_integrations?: boolean;
  // CEG only fields
  source_actual?: number;
  source_remaining_committed?: number;
  source_remaining_planned?: number;
  available?: number;
}

export interface ProgramAllocation extends BudgetObjectAllocation {
  program?: number;
}

export interface CampaignAllocation extends BudgetObjectAllocation {
  campaign?: number;
}
