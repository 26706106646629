import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderNavigationComponent } from './header-navigation.component';
import { BudgetSelectComponent } from './components/budget-select/budget-select.component';
import { FiltersManagementComponent } from './components/filters/filters-management/filters-management.component';
import { SharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { CompanySelectComponent } from './components/company-select/company-select.component'
import { FiltersContainerComponent } from './components/filters/filters-container/filters-container.component';
import { FilterControlComponent } from './components/filters/filter-control/filter-control.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreationMenuComponent } from './components/creation-menu/creation-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { ManagingMenuComponent } from './components/managing-menu/managing-menu.component';
import { MatSelectModule } from '@angular/material/select';
import { SaveFilterModalComponent } from './components/filters/save-filter-modal/save-filter-modal.component';
import { FilterService } from './components/filters/filter-services/filter.service';
import { FilterManagementService } from './components/filters/filter-services/filter-management.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SettingsMenuComponent } from './components/settings-menu/settings-menu.component';
import { HistoryMenuComponent } from './components/history-menu/history-menu.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FiltersContainerOverlayDirective } from './components/filters/filters-container/filters-container-overlay.directive';
import { CustomFilterModeComponent } from './components/filters/custom-filter-mode/custom-filter-mode.component';


@NgModule({
  declarations: [
    HeaderNavigationComponent,
    BudgetSelectComponent,
    FiltersManagementComponent,
    CompanySelectComponent,
    FiltersContainerComponent,
    FilterControlComponent,
    CreationMenuComponent,
    ManagingMenuComponent,
    SaveFilterModalComponent,
    SearchFieldComponent,
    SettingsMenuComponent,
    HistoryMenuComponent,
    FiltersContainerOverlayDirective,
    CustomFilterModeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    SharedModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  exports: [
    HeaderNavigationComponent,
    CompanySelectComponent,
    FiltersContainerOverlayDirective
  ],
  providers: [
    FilterService,
    FilterManagementService,
  ]
})
export class HeaderNavigationModule { }
