import { Injectable } from '@angular/core';
import { ManageTableDataMode } from '../types/manage-table-data-mode.type';
import { SpendingModeFlag, SpendingModeFlags } from '../types/spending-mode-flags.type';
import { AllocationModeFlag, AllocationModeFlags } from '../types/allocation-mode-flags.type';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { MANAGE_PAGE_VIEW_MODE, MANAGE_PAGE_VIEW_STATE } from 'app/shared/constants/modes.constant';
import { ManageTableViewMode } from '../types/manage-table-view-mode.type';

@Injectable()
export class ManagePageHistoryModeService {
  private state: { [key: string]: Object } = {
    [ManageTableDataMode.Allocation]: {
      [AllocationModeFlag.Expenses]: false,
      [AllocationModeFlag.Formula]: false
    },
    [ManageTableDataMode.Spending]: {
      [SpendingModeFlag.PlannedExpensesInTotal]: false,
      [SpendingModeFlag.ExpensesAsTotal]: false,
      [SpendingModeFlag.Formula]: false
    },
    [ManageTableDataMode.Performance]: {},
    dataMode: ManageTableDataMode.Allocation
  }

  public getState(): string {
    return LocalStorageService.getFromStorage(MANAGE_PAGE_VIEW_STATE);
  }

  public saveDefaultState(state: { [key: string]: Object }): void {
    LocalStorageService.addToStorage(MANAGE_PAGE_VIEW_STATE, state);
  }

  public saveActualState(mode: ManageTableDataMode, value: SpendingModeFlags | AllocationModeFlags, dataMode: ManageTableDataMode): void {
    this.state = {
      ...this.state,
      [mode]: value,
      dataMode
    }
    LocalStorageService.addToStorage(MANAGE_PAGE_VIEW_STATE, this.state);
  }

  public saveDataMode(dataMode: ManageTableDataMode): void {
    this.state = {
      ...this.state,
      dataMode
    }
    LocalStorageService.addToStorage(MANAGE_PAGE_VIEW_STATE, this.state);
  }

  public saveViewMode(viewMode: ManageTableViewMode): void {
    LocalStorageService.addToStorage(MANAGE_PAGE_VIEW_MODE, viewMode);
  }

}
