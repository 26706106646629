import { Injectable } from '@angular/core';
import { UserManager } from './user/services/user-manager.service';
import { CompanyDataService } from '@shared/services/company-data.service';
import { BehaviorSubject, combineLatest, Subject, switchMap } from 'rxjs';
import { filter, map, skipWhile, take, takeUntil, tap } from 'rxjs/operators';
import { Company, CompanyDO } from '@shared/types/company.interface';
import { UserDataService } from '@shared/services/user-data.service';
import { BudgetDataService } from './dashboard/budget-data/budget-data.service';
import { Router, RoutesRecognized } from '@angular/router';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { ChurnZeroService, EventName } from '@shared/services/churn-zero.service';
import { Configuration } from './app.constants';
import { Budget } from '@shared/types/budget.interface';
import { IntegrationSyncProgressService } from './metric-integrations/services/integration-sync-process.service';
import { BudgetStatus } from '@shared/types/budget-status.type';
import { InvoiceUploadManagerService } from '@spending/services/invoice-upload-manager.service';
import { PendoManagerService } from '@shared/services/pendo-manager.service';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { CEG_TIMEFRAME_CONFIG } from '@shared/constants/modes.constant';

@Injectable({
  providedIn: 'root'
})
export class AppInit {
  private subscription;
  private loggedOut = new Subject<void>();
  private navigationRoutesRecognized = new BehaviorSubject<RoutesRecognized>(null);
  private loggedOut$ = this.loggedOut.asObservable();
  private navigationRoutesRecognized$ = this.navigationRoutesRecognized.asObservable();
  private lastBudget: Budget;

  constructor(
    private readonly userManager: UserManager,
    private readonly companyDataService: CompanyDataService,
    private readonly userDataService: UserDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly router: Router,
    private readonly appRoutingService: AppRoutingService,
    private readonly churnZeroService: ChurnZeroService,
    private readonly config: Configuration,
    private readonly integrationSyncProcessService: IntegrationSyncProgressService,
    private readonly invoiceUploadManager: InvoiceUploadManagerService,
    private readonly pendoManager: PendoManagerService
  ) {}

  private onLoggedIn() {
    combineLatest([
      this.companyDataService.companyList$.pipe(
        skipWhile(list => list == null)
      ),
      this.navigationRoutesRecognized$
        .pipe(
          filter(event => event != null),
          take(1),
          tap(event => this.appRoutingService.setInitDataFromQueryParams(event.state.root.queryParams))
        )
    ]).pipe(takeUntil(this.loggedOut$))
      .subscribe(([companyList, ]) => this.onCompanyListLoaded(companyList));

    this.companyDataService.selectedCompany$
      .pipe(takeUntil(this.loggedOut$))
      .subscribe(company => this.onSelectedCompanyChanged(company));

    this.companyDataService.selectedCompanyDO$
      .pipe(takeUntil(this.loggedOut$))
      .subscribe(companyDO => this.onSelectedCompanyDOChanged(companyDO));

    this.budgetDataService.selectedBudget$
      .pipe(
        takeUntil(this.loggedOut$),
        switchMap(
          budget => this.companyDataService.selectedCompanyDO$.pipe(
            map(companyDO => [budget, companyDO])
          )
        )
      )
      .subscribe(([budget, companyDO]: [Budget, CompanyDO]) => {
        if (this.shouldUpdateIntegrations(budget)) {
          this.companyDataService.getMetricIntegrations(budget, companyDO);
        }
        this.lastBudget = budget;
      });

    this.integrationSyncProcessService.init();

    this.companyDataService.loadCompanyList(
      () => console.log('Failed to load user companies')
    );

    this.userManager.loadCurrentUser(() => console.log('Failed to load current user'));

    if (this.config.pendoApiKey) {
      this.pendoManager.initSubscriptions();
    }
  }

  shouldUpdateIntegrations(newSelectedBudget: Budget): boolean {
    const isReadOnly = (budget: Budget): boolean => budget?.status === BudgetStatus.Reference;
    return this.lastBudget?.id !== newSelectedBudget?.id || isReadOnly(this.lastBudget) !== !isReadOnly(newSelectedBudget);
  }

  public activate() {
    if (!this.subscription) {
      this.subscription =
        this.userManager.afterLoggedIn$
          .subscribe(
            () => this.onLoggedIn()
          );

      this.userManager.afterLoggedOut$.subscribe(
        () => {
          this.resetLastNavigationRoutesRecognizedEvent();
          this.loggedOut.next();
          this.companyDataService.resetData();
          this.churnZeroService.stop();
          this.integrationSyncProcessService.reset();
          this.invoiceUploadManager.resetState();
          this.pendoManager.stopTracking();
          this.cleanCegConfig();
        }
      );

      this.router.events
        .pipe(filter(event => event instanceof RoutesRecognized))
        .subscribe(event => this.navigationRoutesRecognized.next(event as RoutesRecognized));
    }
  }

  public resetLastNavigationRoutesRecognizedEvent() {
    this.navigationRoutesRecognized.next(null);
  }

  private onCompanyListLoaded(companyList: Company[]) {
    if (companyList?.length > 0) {
      this.userManager.currentUserId$.subscribe(userId => {
        const companyObj = this.companyDataService.getInitialCompanyToSelect(userId);
        this.companyDataService.updateSelectedCompany(companyObj.id);
      });
    } else {
      console.log('No companies found for current user');
    }
  }

  private onSelectedCompanyChanged(company: Company) {
    if (company) {
      this.companyDataService.loadSelectedCompanyDetails(company.id);
      this.userManager.currentUserId$.subscribe(userId => {
        this.companyDataService.selectedCompanyStorage.save(userId, company.id);
        this.userDataService.loadCurrentCompanyUser(company.id, userId).subscribe(
          {
            next: companyUser => this.userManager.trackFirstLogin(companyUser),
            error: () => console.log('Failed to load company budgets')
          }
        );
      });
    }
  }

  private onSelectedCompanyDOChanged(companyDO: CompanyDO) {
    this.churnZeroService.stop(); // If there is an active ChurnZero session for prev company - deactivate it
    if (companyDO?.saas_optics_customer_id && this.config.churnZeroAppKey) {
      this.userManager.currentUser$
        .pipe(
          filter(user => user != null && !user.is_superadmin),
          take(1)
        )
        .subscribe(
          user => this.initChurnZeroForUser(companyDO.saas_optics_customer_id, user.email)
        );
    }
  }

  private initChurnZeroForUser(saasOpticsCustomerId: string, userEmail: string) {
    this.churnZeroService.setAppKey(this.config.churnZeroAppKey);
    this.churnZeroService.setContact(saasOpticsCustomerId, userEmail);
    this.churnZeroService.trackEvent(EventName.Login);
  }

  private cleanCegConfig(): void {
    LocalStorageService.removeFromStorage(CEG_TIMEFRAME_CONFIG);
  }
}
