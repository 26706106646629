import { BudgetObjectParent } from './budget-object-details-state.interface';
import { LightCampaign } from '@shared/types/campaign.interface';
import { LightProgram } from '@shared/types/program.interface';
import { Goal } from '@shared/types/goal.interface';
import { CEGStatus } from '@shared/enums/ceg-status.enum';

export type MainBudgetObject = LightCampaign | LightProgram | Goal;

export enum BudgetObjectEventType {
  Created,
  Updated,
  Deleted,
  Moved
}

export enum BudgetObject {
  Expense = 'Expense',
  Program = 'Program',
  Campaign = 'Campaign',
  Goal = 'Goal'
}

export interface BudgetObjectEventContext {
  objectId: number;
  segmentId?: number;
  sharedCostRuleId?: number;
  parentObject?: BudgetObjectParent;

  prevSegmentId?: number;
  prevSharedCostRuleId?: number;
  prevParentObject?: BudgetObjectParent;
  prevAmountStatus?: CEGStatus;

  objectTypeId?: number;
  objectName?: string;
  objectMode?: string;
  amountStatus?: CEGStatus;

  startDate?: string;
  endDate?: string;
  keyMetricId?: number;
  createdDate?: Date;
  externalId?: string;
  currencyCode?: string;

  extras?: {
    prevObject: MainBudgetObject
  };
}

export interface BudgetObjectEvent {
  eventType: BudgetObjectEventType;
  targetObject: BudgetObject;
  context: BudgetObjectEventContext;
}
