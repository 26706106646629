import { Component } from '@angular/core';

@Component({
  selector: 'details-data-placeholder',
  templateUrl: './details-data-placeholder.component.html',
  styleUrls: ['./details-data-placeholder.component.scss']
})
export class DetailsDataPlaceholderComponent {

}
