<new-entity-label [createdDate]="createdDate" leftSide="true"></new-entity-label>
<div *ngIf="!isReadOnlyMode" class="icons">
  <mat-checkbox
    *ngIf="!timeframeLocked; else lock"
    color="primary"
    [checked]="itemSelected"
    [disabled]="selectionDisabled"
    (click)="onCheckboxClick($event)"
    (change)="onSelect($event)">
  </mat-checkbox>
  <icon-grip size="13px" class="drag-handle" *ngIf="dragAllowed"></icon-grip>
</div>

<div class="name"
     [ngClass]="{ 'leading-icon': !isReadOnlyMode }"
     (click)="onNameClick()"
     [stringTruncate]="name"></div>

<ng-template #lock>
  <fa-icon [icon]="['fad', 'lock-alt']" class="lock-icon"></fa-icon>
</ng-template>
