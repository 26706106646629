<div class="title-description-tooltip">
  <tooltip-container [ngClass]="tooltipContainerClass">
    <div class="tooltip-content">
      <div class="tooltip-title">
        <ng-container
          [ngTemplateOutlet]="titleTemplate || defaultTitle"
          [ngTemplateOutletContext]="{ $implicit: title }"
        ></ng-container>
      </div>
      <div class="tooltip-description">
        <ng-container
          [ngTemplateOutlet]="descriptionTemplate || defaultDescription"
          [ngTemplateOutletContext]="{ $implicit: description }"
        ></ng-container>
      </div>
    </div>
  </tooltip-container>
</div>

<ng-template #defaultTitle let-title>
  {{ title }}
</ng-template>

<ng-template #defaultDescription let-description>
  {{ description }}
</ng-template>
