import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  BudgetAllocationAction,
  BudgetAllocationActionParams,
  UndoCallback
} from '../../budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';

interface UpdateActionParams<TActionDataSource> extends BudgetAllocationActionParams {
  setAmount: (value: number, dataSource: TActionDataSource) => Observable<boolean>;
  getAmount: (dataSource: TActionDataSource) => number;
  context: {
    amount: number;
    dataSource: TActionDataSource;
  };
  undoCallbacks?: UndoCallback[];
}

export class ManageTableUpdateAction<TActionDataSource> extends BudgetAllocationAction<UpdateActionParams<TActionDataSource>> {
  private initialValue: number;
  private resultValue: number;

  constructor(params: UpdateActionParams<TActionDataSource>) {
    super();
    this.actionParams = params;
    this.processContextData();
  }

  private processContextData(): void {
    const { context: { dataSource, amount }, getAmount } = this.actionParams;

    this.initialValue = getAmount(dataSource);
    this.resultValue = amount;
  }

  public execute(): void {
    const { setAmount, context: { dataSource } } = this.actionParams;

    if (!setAmount || dataSource == null) {
      return;
    }

    setAmount(this.resultValue, dataSource)
      .pipe(
        filter(value => value != null),
        take(1)
      ).subscribe(
        result => {
          if (!result) {
            return;
          }
          this.onExecuted?.(
            this.onExecutedMessage,
            this.undo.bind(this)
          );
        }
      );
  }

  public undo(): void {
    const { setAmount, context: { dataSource }, undoCallbacks } = this.actionParams;

    if (!setAmount || dataSource == null) {
      return;
    }
    setAmount(this.initialValue, dataSource)
      .pipe(
        filter(value => value != null),
        take(1)
      ).subscribe(
        result => {
          if (!result) {
            return;
          }
          if (this.onUndone) {
            this.onUndone();
          }
          this.runCallbacks(undoCallbacks).subscribe();
        }
      );
  }
}
