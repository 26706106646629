import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';

@Component({
  selector: 'timeframe-picker',
  styleUrls: ['./timeframe-picker.component.scss'],
  templateUrl: './timeframe-picker.component.html'
})
export class TimeframePickerComponent implements OnChanges {
  @Input() timeframes: BudgetTimeframe[];
  @Input() overdueTimeframes: number[] = [];
  @Input() selectedTimeframe: number;
  @Output() onSelect = new EventEmitter<BudgetTimeframe>();

  public innerSelectedTimeframe: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTimeframe && this.selectedTimeframe) {
      this.innerSelectedTimeframe = this.selectedTimeframe;
    }
  }

  public select($event, tf: BudgetTimeframe) {
    $event.stopPropagation();
    if (this.overdueTimeframes.includes(tf.id)) {
      return;
    }
    this.innerSelectedTimeframe = tf.id;
    this.onSelect.emit(tf);
  }
}
