import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { ExpenseUpdateField, SidebarHierarchyOption, SidebarObjectTypes } from '@spending/types/expense-page.type';
import { SpendingSidebarService } from '@spending/services/spending-sidebar.service';
import { DropState } from '@shared/types/droppable-state.type';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { SpendingService } from '@spending/services/spending.service';
import { SelectableSpendingRow } from '@spending/services/expense-page-selection.service';
import { take, takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';

@Component({
  selector: 'sidebar-hierarchy-options-list',
  templateUrl: './sidebar-hierarchy-options-list.component.html',
  styleUrls: ['./sidebar-hierarchy-options-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarHierarchyOptionsListComponent implements OnDestroy {
  @Input() segments: BudgetSegmentAccess[];
  @Input() sharedCostRules: SharedCostRule[];
  @Input() hierarchyOptions: SidebarHierarchyOption[];
  @Input() searchQuery: string;
  @Input() isAllSelected: boolean;
  @Input() expenseCountsMap: Record<number, number>;
  @Input() hideGroupCount: boolean = false; 

  @Output() onDrop: EventEmitter<SidebarHierarchyOption> = new EventEmitter<SidebarHierarchyOption>();

  public onDragOverItem: {[key: number]: DropState} = {};
  public dropState = DropState;
  private readonly SidebarObjectTypes = SidebarObjectTypes;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly spendingSidebarService: SpendingSidebarService,
    private readonly zone: NgZone,
    private readonly spendingService: SpendingService,
  ) {
    const dragEndAction$ =  this.spendingSidebarService.dragEndAction$.pipe(takeUntil(this.destroy$));
    const remoteSelect$ = this.spendingSidebarService.remoteSelect$.pipe(take(1), takeUntil(this.destroy$));

    const sidebarOptionActions$ = merge(dragEndAction$, remoteSelect$);
    sidebarOptionActions$.subscribe((option: null | SidebarHierarchyOption) => {
      if (option && option.id) {
        this.onToggleSelected(option, 0);
        this.onToggleCollapsed(0);
        this.cdr.detectChanges();
      } else {
        this.onDragOverItem = {};
      }
    });
  }

  getOptionExpenseCount(option: SidebarHierarchyOption): number {
    if (option.objectType === SidebarObjectTypes.SegmentGroup) {
      return option.groupSegmentIds
        .map(segmentId => this.expenseCountsMap[segmentId] || 0)
        .reduce((sum, currentValue) => sum + currentValue, 0);
    }
    if (option.id === 0) {
      return this.expenseCountsMap['null'] || 0;
    }

    return this.expenseCountsMap[option.id] || 0;
  }

  onToggleCollapsed(optionIndex: number): void {
    this.spendingSidebarService.toggleCollapsed(optionIndex, false, null);
  }

  onToggleSelected(option: SidebarHierarchyOption, optionIndex: number): void {
    this.spendingSidebarService.toggleSelected(option, optionIndex);
    this.cdr.detectChanges();
  }

  onHandleDragOver(event, option: SidebarHierarchyOption, index: number): void {
    this.zone.run(() => {
      this.spendingSidebarService.toggleCollapsed(index, true, null);
      const expenses = this.spendingService.dragExpenses;
      this.onDragOverItem = {};
      if (this.isDropForbidden(option, expenses)) {
        this.onDragOverItem[option.id] = this.dropState.FORBID;
        return;
      }
      this.onDragOverItem[option.id] = this.dropState.ALLOW;
    });
  }

  onHandleDragLeave(event, optionId: number): void {
    if (this.onDragOverItem[optionId]) {
      this.onDragOverItem = {};
    }
  }

  onHandleDrop(option: SidebarHierarchyOption): void {
    this.zone.run(() => {
      const expenses = this.spendingService.dragExpenses;
      if (this.isDropForbidden(option, expenses)) {
        this.onDragOverItem = {};
        return;
      }
      this.onDrop.next(option);
    });
  }

  private isDropForbidden(option: SidebarHierarchyOption, expenses: SelectableSpendingRow[]): boolean {
    return option.objectType === this.SidebarObjectTypes.SegmentGroup
      || option.objectType === this.SidebarObjectTypes.Goal
      || option?.closed
      || (option.objectType === this.SidebarObjectTypes.Campaign && !option?.segmentId && !option?.sharedCostRuleId)
      || expenses.every(expense => expense.expenseObject[ExpenseUpdateField[option.objectType]] === option.id);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
