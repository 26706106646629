import { Injectable } from '@angular/core';
import { MetricsProviderDataService } from './metrics-provider-data.service';
import { Observable } from 'rxjs';
import {
  BudgetIntegrationCampaignMappingsResponse,
  IntegrationData,
  SyncStatusResponse
} from '../types/metrics-provider-data-service.types';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export abstract class MetricsProviderWithImplicitMappingsDataService extends MetricsProviderDataService<void> {

  protected constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
  ) {
    super(httpClient, url);
  }

  getCompanyRunningSynchronizations(companyId: number): Observable<SyncStatusResponse[]> {
    return this.http.get<SyncStatusResponse[]>(
      `${this.serviceBaseUrl}company-sync-status/${companyId}`
    ).pipe(
      catchError(error => MetricsProviderDataService.handleGetError(error))
    );
  }

  public getBudgetIntegrationCampaignMappings(
    companyId: number,
    integrationId: string,
    budgetId: number
  ): Observable<BudgetIntegrationCampaignMappingsResponse> {
    return this.httpClient.get<BudgetIntegrationCampaignMappingsResponse>(
      `${this.serviceBaseUrl}${this.apiPath.campaignMetricMapping}${companyId}/${integrationId}/${budgetId}`
    );
  }

  public deleteCampaignsMappings(companyId: number, campaignIds: number[]): Observable<any> {
    // only for Google ADS and similar services mappings should be disabled (not deleted)
    const url = `${this.serviceBaseUrl}${
      this.apiPath.campaignsMappingMultiDisable
        .replace(this.apiPathParam.companyId, String(companyId))
    }`;
    return this.http.post(url, campaignIds);
  }

  public setAuthData(companyId: number, integrationId: string, data: IntegrationData): Observable<any> {
    return this.http.put<IntegrationData>(this.getAuthDataUrl(companyId, integrationId), data);
  }

  public getAuthData(companyId: number, integrationId: string): Observable<IntegrationData> {
    return this.http.get<IntegrationData>(this.getAuthDataUrl(companyId, integrationId));
  }

  private getAuthDataUrl(companyId: number, integrationId: string): string {
    return `${this.serviceBaseUrl}${
      this.apiPath.authData
        .replace(this.apiPathParam.companyId, String(companyId))
        .replace(this.apiPathParam.integrationId, integrationId)
    }`;
  }

  public getDataSyncProgressStatus(companyId, integrationId): Observable<SyncStatusResponse> {
    return this.http.get<SyncStatusResponse>(`${this.serviceBaseUrl}${this.apiPath.syncStatus}${companyId}/${integrationId}`);
  }

  public deleteDataSyncProgressStatus(companyId, integrationId): Observable<any> {
    return this.http.delete(`${this.serviceBaseUrl}${this.apiPath.syncStatus}${companyId}/${integrationId}`);
  }

  public lockTimeframesForIntegration(orders: number[], companyId: number, campaignId: number | string): Observable<void> {
    return this.http.post<void>(
    `${this.serviceBaseUrl}lock-timeframe-orders/${companyId}/${campaignId}`,
      JSON.stringify((orders || []).map(order => order.toString()))
    );
  }

  public removeIntegrationsForBudget(companyId: number, budgetId: number): Observable<any> {
    return this.http.delete(`${this.serviceBaseUrl}${this.apiPath.authBudget}${companyId}/${budgetId}`);
  }
}
