import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'plus-toggler',
  templateUrl: './plus-toggler.component.html',
  styleUrls: ['./plus-toggler.component.scss']
})
export class PlusTogglerComponent {
  @Input() active: boolean;
  @Input() indeterminate: boolean;
  @Output() change = new EventEmitter();

  onClick() {
    const newState = this.indeterminate ? false : !this.active;
    this.change.emit(newState)
  }
}
