import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { revenuePerOutcomePredefined, revenuePerOutcomeTooltip } from '../constants';

@Component({
  selector: 'metric-rpo-input',
  templateUrl: './metric-rpo-input.component.html',
  styleUrls: ['./metric-rpo-input.component.scss']
})
export class MetricRpoInputComponent implements OnChanges {
  @Input() rpoFormControl: UntypedFormControl;
  @Input() currencySymbol = '$';

  rpoDescription = revenuePerOutcomeTooltip;
  rpoPredefined = revenuePerOutcomePredefined;

  currencyMaskOpt = { align: 'right', prefix: '$', decimal: '.', precision: 2 };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currencySymbol) {
      this.currencyMaskOpt.prefix = this.currencySymbol;
    }
  }

  get isDisabled() {
    return this.rpoFormControl.disabled;
  }
}
