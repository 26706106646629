import { Injectable } from '@angular/core';
import { CurrentMappingTargets, SalesforceDataService } from './salesforce-data.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { ExternalMetric, ExternalMetricName, ExternalMetrics } from '../types/external-metrics.types';
import { ExternalMetricTypesMapping } from '../types/external-metric-types-mapping.interface';
import { ExternalCampaign, ExternalCampaignData } from '../types/external-campaign.interface';
import {
  CampaignBrief,
  IntegrationChildrenMappingState,
  IntegrationMappingState,
  PlannuhCampaignMetric
} from '../types/metric-integration';
import { ExternalMetricProviderApiService } from '../types/external-metric-provider-api.service';
import { SalesforceCampaignMapping } from './salesforce-campaign-mapping.interface';
import { Campaign } from 'app/shared/types/campaign.interface';
import { createRecordsTree } from 'app/shared/utils/common.utils';

enum SalesforceObjectFieldTypes {
  currency = 'currency',
  int = 'int',
  double = 'double'
}

@Injectable()
export class SalesforceApiService extends ExternalMetricProviderApiService<SalesforceCampaignMapping> {
  private static readonly salesforceAPIVersion = 'v48.0';
  private static readonly supportedSFCampaignFieldTypes = [
    SalesforceObjectFieldTypes.currency,
    SalesforceObjectFieldTypes.int,
    SalesforceObjectFieldTypes.double
  ];
  private static readonly SFAPIPath = {
    campaignFields: `/services/data/${SalesforceApiService.salesforceAPIVersion}/sobjects/campaign/describe/`,
    query: `/services/data/${SalesforceApiService.salesforceAPIVersion}/query/`
  };

  private static getParamsForSFCampaignsRequest(sfMetricNames: string[], dateFrom: string) {
    return {
      'q': SalesforceApiService.createSOQLForLoadingSFCampaigns(sfMetricNames, dateFrom)
    };
  }

  private static createSOQLForLoadingSFCampaigns(sfMetricNames: string[], dateFrom: string): string {
    const campaignFields = [
      'Id',
      'ParentId',
      'Name',
      'OwnerId',
      'Owner.Name',
      'Description',
      'Type',
      'Status',
      'StartDate',
      'EndDate'
    ];
    const queryFields = [...campaignFields, ...sfMetricNames].join(',');

    return `SELECT ${queryFields} FROM Campaign WHERE (EndDate>=${dateFrom} OR EndDate=null) AND IsDeleted=false AND IsActive=true ORDER BY Name`;
  }

  private static createSalesforceCampaignsTree(
    campaignId: number,
    rawRecords: any[],
    sfMetricNames: ExternalMetricName[],
    plannuhCampaignMetrics: PlannuhCampaignMetric[],
    metricTypesMapping: ExternalMetricTypesMapping,
    currentMappingTargets: CurrentMappingTargets,
    allCampaigns: Campaign[]
  ): ExternalCampaign[] {
    const getExistingMapping = (extCampaignId: string): CampaignBrief => {
      if (!currentMappingTargets) {
        return null;
      }
      const mappingTarget = currentMappingTargets[extCampaignId];
      const campaign = mappingTarget && allCampaigns.find(c => c.id === mappingTarget && c.id !== campaignId);
      return campaign ? { id: campaign.id, name: campaign.name } : null;
    };

    const lookupItemMapper = (record: any): ExternalCampaign => {
      return {
        ...SalesforceApiService.convertCampaignRecord(record),
        metrics: (plannuhCampaignMetrics || []).map(plannuhMetric => ({
          name: plannuhMetric.name,
          productName: plannuhMetric.productName,
          order: plannuhMetric.order,
          productOrder: plannuhMetric.productOrder,
          value: sfMetricNames
            .filter(sfName => metricTypesMapping[sfName.name].includes(plannuhMetric.metricId))
            .map(sfName => record[sfName.name] || 0)
            .reduce((sum, value) => sum + value, 0)
        })),
        existingMapping: getExistingMapping(record.Id)
      }
    }

    return createRecordsTree<ExternalCampaign>(rawRecords, lookupItemMapper, 'Id', 'ParentId');
  }

  private static convertCampaignRecord(rec: any): ExternalCampaign {
    return {
      id: rec.Id,
      parentId: rec.ParentId,
      name: rec.Name,
      owner: rec.Owner?.Name,
      description: rec.Description,
      type: rec.Type,
      status: rec.Status,
      startDate: rec.StartDate,
      endDate: rec.EndDate,
      currencyIsoCode: rec.currencyIsoCode,
      mappingState: IntegrationMappingState.NotMapped,
      mappingChildrenState: IntegrationChildrenMappingState.NotMapped,
      children: []
    };
  }

  constructor(private readonly salesforceDataService: SalesforceDataService) {
    super();
  }

  public getExternalMetrics(companyId: number, integrationId: string): Observable<ExternalMetrics> {
    return this.salesforceDataService.getProxyData(companyId, integrationId, SalesforceApiService.SFAPIPath.campaignFields).pipe(
      map((response: any) => {
        if (!response || !response.data || !response.data.fields) {
          return null;
        }
        return response.data.fields
          .filter(field => SalesforceApiService.supportedSFCampaignFieldTypes.includes(field.type))
          .map(field => <ExternalMetric>{
            type: field.type,
            name: field.name,
            description: field.inlineHelpText,
            label: field.label
          });
      })
    );
  }

  public getRawExternalCampaigns(companyId, integrationId, dateFrom): Observable<any> {
    return this.getExternalMetrics(companyId, integrationId).pipe(
      map(sfMetrics => sfMetrics.map(sfMetric => sfMetric.name)),
      switchMap(sfMetricNames => {
        return this.salesforceDataService.getProxyData(
          companyId,
          integrationId,
          SalesforceApiService.SFAPIPath.query,
          SalesforceApiService.getParamsForSFCampaignsRequest(sfMetricNames, dateFrom)
        );
      }),
      map((proxyData: any) => proxyData?.data?.records || [])
    );
  }

  public getExternalCampaigns(params): Observable<ExternalCampaignData> {
    const { companyId, campaignId, plannuhCampaignMetrics, dateFrom, allCampaigns, integrationId, budget } = params;
    let metricTypeMappings = [];
    let relevantPlannuhMetrics = [];
    const sfMetrics$ = this.getExternalMetrics(companyId, integrationId);
    const sfMetricMapping$ = this.salesforceDataService.getExternalMetricTypesMapping(companyId, integrationId)
      .pipe(
        map(mapping => mapping || {}),
        tap((mapping) => {
          metricTypeMappings = this.getMetricTypeMappings(mapping, (plannuhCampaignMetrics || []).map(m => m.metricId));
          const mappingValues = Object.values(mapping);
          relevantPlannuhMetrics = (plannuhCampaignMetrics || [])
            .filter(plMetric => mappingValues.some(values => values.includes(plMetric.metricId)));
        }),
      );

    return forkJoin([sfMetrics$, sfMetricMapping$])
    .pipe(
      map(([sfMetrics, mapping]) => [
        this.getSFCampaignMetricNames(
          sfMetrics,
          Object.keys(mapping).filter(
            metricTypeName => (relevantPlannuhMetrics || []).find(plCampMetric => mapping[metricTypeName]?.includes(plCampMetric.metricId))
          )
        ),
        mapping
      ]),
      switchMap(([sfMetricNames, externalMetricTypesMapping]: [ExternalMetricName[], ExternalMetricTypesMapping]) =>
        this.salesforceDataService.getProxyData(
          companyId,
          integrationId,
          SalesforceApiService.SFAPIPath.query,
          SalesforceApiService.getParamsForSFCampaignsRequest(sfMetricNames.map(mName => mName.name), dateFrom)
        ).pipe(
          switchMap((response: any) => {
            const records = response?.data?.records || [];
            const uniqueSFCampaignIds = this.salesforceDataService.getExternalCampaignsPayload(records);
            return this.salesforceDataService.getCurrentMappingTargets<string[], CurrentMappingTargets>(
              companyId,
              integrationId,
              budget.id,
              [...uniqueSFCampaignIds]
            ).pipe(
              map(currentMappingTargets => [records, currentMappingTargets])
            );
          }),
          map(([sfCampaigns, currentMappingTargets]) => ({
            campaigns: SalesforceApiService.createSalesforceCampaignsTree(
              campaignId,
              sfCampaigns,
              sfMetricNames,
              relevantPlannuhMetrics,
              externalMetricTypesMapping,
              currentMappingTargets,
              allCampaigns
            ),
            metricTypeMappings
          }))
        )
      )
    );
  }

  private getSFCampaignMetricNames(
    sfMetrics: ExternalMetrics,
    metricTypeNames: string[]
  ): ExternalMetricName[] {
    return metricTypeNames && sfMetrics?.length ?
      metricTypeNames
        .map(sfMetricName => {
          const sfMetric = sfMetrics.find(m => m.name === sfMetricName);
          return sfMetric && { name: sfMetric.name, label: sfMetric.label };
        })
        .filter(sfMetricName => !!sfMetricName) :
      [];
  }
}
