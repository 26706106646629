import { Component, Input } from '@angular/core';
import {
  DetailsExpensesData,
  DetailsExpensesTotalRow,
  DetailsExpensesObjectRow
} from './details-expenses-table.type';
import { Configuration } from 'app/app.constants';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { ExternalIntegrationObjectTypes } from 'app/shared/constants/external-integration-object-types';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';


@Component({
  selector: 'details-expenses-table',
  templateUrl: './details-expenses-table.component.html',
  styleUrls: ['./details-expenses-table.component.scss']
})
export class DetailsExpensesTableComponent {
  @Input() withBreakdown: boolean;
  @Input() withChildCampaigns: boolean;
  @Input() expensesTotals: DetailsExpensesTotalRow;
  @Input() data: DetailsExpensesData;

  private routeActionByObjectType = {
    [HistoryObjectLogTypeNames.campaign]: (id: number) => this.appRoutingService.openCampaignDetails(id),
    [HistoryObjectLogTypeNames.program]: (id: number) => this.appRoutingService.openProgramDetails(id),
    [HistoryObjectLogTypeNames.expense]: (id: number) => this.appRoutingService.openExpenseDetails(id),
  };
  public iconByObjectType = {
    [this.configuration.OBJECT_TYPES.campaign]: ['far', 'rocket-launch'],
    [this.configuration.OBJECT_TYPES.program]: ['fas', 'briefcase'],
    [this.configuration.OBJECT_TYPES.expense]: ['fas', 'coins'],
  };
  public decimalPipeFormat = '1.2-2';
  public statusFields = this.configuration.statusFields;
  public objectTypes = this.configuration.OBJECT_TYPES;
  public groupsOpenState = {};
  public ExternalIntegrationObjectTypes = ExternalIntegrationObjectTypes;
  public readonly objectMode = ObjectMode;

  constructor(
    private readonly configuration: Configuration,
    private readonly appRoutingService: AppRoutingService,
  ) {}

  public handleGroupToggleChange(collapsed: boolean, groupId) {
    if (collapsed) {
      delete this.groupsOpenState[groupId];
    } else {
      this.groupsOpenState[groupId] = true;
    }
  }

  public openObjectDetails(objectId: number, objectType: string) {
    if (!objectId) {
      return;
    }

    const routeAction = this.routeActionByObjectType[objectType];
    if (typeof routeAction === 'function') {
      routeAction(objectId);
    }
  }

  public identifyObjectRow(index, row: DetailsExpensesObjectRow): number {
    return row.id;
  }
}
