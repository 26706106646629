<p class="description"
   (click)="onLabelClick($event)">Revenue to Profit <br> conversion</p>

<div class="control"
[ngClass]="{ 'has-error': control.errors }">
  <input #input
         [step]="step"
         [min]="minVal"
         type="number"
         class="hide-arrows font-monospace ut-rtp"
         [formControl]="control"
         (focus)="focusChanged.emit(true)"
         (blur)="updateValue()"
         (click)="$event.stopPropagation()">
  <span class="outline"></span>
  <span class="suffix">%</span>
  <float-error-message *ngIf="control.errors" [errorMessage]="'must be between ' + minVal +'% and ' + maxVal + '%'"></float-error-message>
</div>
