<form id="form-addGoalDetail" class="row-addGoal modal-form">
  <header mat-dialog-title>{{ pageTitle }}</header>
  <div mat-dialog-content class="dialog-info">
    <mat-form-field appearance="outline" class="p-form-field-outline">
      <mat-label>Name <span class="required-field">&#42;</span></mat-label>
      <input type="text" matInput [formControl]="expenseTypeControl" maxlength="128" trim>
    </mat-form-field>
    <mat-error *ngIf="expenseTypeControl.touched && (expenseTypeControl.errors?.required || expenseTypeControl.errors?.trim)">
      {{ validations.ValiditionMessages.EXPENSE_TYPE_NAME_REQUIRED }}
    </mat-error>
    <mat-error *ngIf="expenseTypeControl.errors?.maxlength">
      {{ validations.ValiditionMessages.COMMON_MAX_LENGTH(MAX_TEXT_INPUT_LENGTH) }}
    </mat-error>
    <mat-error *ngIf="expenseTypeControl.touched && expenseTypeControl.errors?.notUniqueName">
      {{ validations.ValiditionMessages.EXPENSE_TYPE_ALREADY_EXIST }}
    </mat-error>
  </div>

  <div mat-dialog-actions align="end" class="dialog-actions">
    <button type="button" mat-button color="primary" mat-dialog-close>Close</button>
    <button type="submit" mat-flat-button color="primary" (click)="onSubmit()" [disabled]="expenseTypeControl.invalid">Finish</button>
  </div>
</form>
