<confirmation-dialog>
  <fa-duotone-icon class="virus-icon" [icon]="['fad', 'virus']"></fa-duotone-icon>
  <div class="dialog-info">
    {{ context?.fileNames?.length }} {{ context?.fileNames?.length > 1 ? 'files were' : 'file was' }}
    not uploaded because we detected a virus in {{ context?.fileNames?.length > 1 ? 'them' : 'it' }}.
    <br>
    <br>
    <ul>
      <li *ngFor="let fileName of context?.fileNames">{{ fileName }}</li>
    </ul>
  </div>
</confirmation-dialog>
