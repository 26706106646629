<svg width="13px" height="13px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
  <g id="Layer_1">
    <path class="st1" [ngClass]="{hasProblem: hasProblem}" d="M8.9,23.9c0,0.4-5.4-0.8-6.1-7c0-2.1,0-2.9,1-4.8S6,8.7,7.7,7c1.7-2.3,1.9-2.5,2.1-3.4c0.2-1,0.2-1.5,0.1-1.8
      c0-0.3-0.4-1.3-0.3-1.6C9.7,0.1,9.8,0,10,0c1.1-0.2,3.1,1.3,3.8,1.8c1.6,1.3,1.5,2.4,1.6,3.3c0.1,1.1-0.1,3,0.1,3.5
      c0.2,0.5,0.7,0.9,1.1,1c0.4,0,0.8-0.5,0.9-1c0.1-0.5-0.4-1.4,0.2-1.6c0.8-0.2,2,2.8,2.3,3.6c0.8,1.4,1.1,3.5,1.2,4.4
      c0.3,2.9-0.4,4.4-0.8,5.1c-0.8,1.3-1.5,2-2.1,2.5c-0.6,0.5-1.5,0.9-2.2,1.2c-0.5,0.3-0.8,0.1-0.9-0.1c-0.1-0.2,0-0.3,0.3-0.6
      c0.1-0.1,1.1-1,1.2-2.6c0-1.6-0.7-2-1-2.3s-1.2-1-1.8-1.7c-1-0.9-1.4-3.1-1.4-3.1s-2,2.6-2.3,2.9c-0.5,0.5-1.9,2.4-1.9,3.2
      c-0.2,2.1,0.4,3.1,0.6,3.4C8.9,23.2,9,23.7,8.9,23.9z"></path>
  </g>
</svg>
