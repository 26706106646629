import { Component } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-dot',
  templateUrl: './icon-dot.component.html'
})
export class IconDotComponent extends IconBaseComponent {
  constructor() {
    super();
  }
}
