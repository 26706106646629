import { Component, Input } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-salesforce',
  templateUrl: './icon-salesforce.component.html'
})

/**
* @class - IconSalesforceComponent
*/
export class IconSalesforceComponent extends IconBaseComponent {
  @Input() isMonoChrome: boolean = true;
  constructor() {
    super();
  }
}

