<div class="metric-breakdown-list">
  <table>
    <thead>
      <tr>
        <th class="name">
          <div *ngIf="icon" class="icon">
            <fa-duotone-icon primaryColor="#061F38" secondaryColor="#061F38" [icon]="icon" [rotate]="iconRotate" slot="icon"></fa-duotone-icon>
          </div>
          <div>{{nameColumnTitle}}</div>
        </th>
        <th class="last-updated"><div>Last Updated</div></th>
        <th class="target"><div *ngIf="withTarget">Target</div></th>
        <th class="current-value-field"><div>Current</div></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows" class="clickable record-row" (click)="handleRowClick(row)">
        <td class="name">
          <div>
            <span class="name-icon">
              <icon-salesforce *ngIf="row.customIcon ==='salesforce'" color="#000000" size="16px"></icon-salesforce>
              <fa-duotone-icon *ngIf="row.faIcon && row.faDuotone" [icon]="row.faIcon"></fa-duotone-icon>
              <fa-icon *ngIf="row.faIcon && !row.faDuotone" [icon]="row.faIcon"></fa-icon>
            </span>
            <span>{{row.name}}</span>
          </div>
        </td>
        <td class="last-updated record-value"><div>{{ row.lastUpdated | date: 'M/d/yy' }}</div></td>
        <td class="target record-value">
          <div *ngIf="withTarget">{{row.target | number: decimalDisplayFormat }}</div>
        </td>
        <td class="current-value-field record-value">
          <div>{{row.current | number: decimalDisplayFormat }}</div>
        </td>
      </tr>
      <tr *ngIf="showTotal" class="total-row">
        <td class="name"><div>Total {{ nameColumnTitle }}</div></td>
        <td></td>
        <td class="target record-value"><div *ngIf="withTarget">{{totalTarget | number: decimalDisplayFormat }}</div></td>
        <td class="current record-value"><div>{{totalCurrent | number: decimalDisplayFormat }}</div></td>
      </tr>
      <ng-container *ngIf="grandTotal">
        <tr class="margin-row"></tr>
        <tr class="total-row grand-total-row">
          <td class="name"><div>Grand Total</div></td>
          <td></td>
          <td class="target record-value"><div *ngIf="withGrandTotalTarget">{{grandTotal.target | number: decimalDisplayFormat}}</div></td>
          <td class="current record-value"><div>{{grandTotal.current | number: decimalDisplayFormat}}</div></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
