import { SharedCostRuleComponent } from '../shared-cost-rule.component';
import { SCRFocusStrategy } from './scr-focus-strategy.interface';
import { SharedCostRuleFieldType } from 'app/shared/types/shared-cost-rule.interface';

export class SCRNameFocusStrategy implements SCRFocusStrategy {
  componentInstance: SharedCostRuleComponent;
  currentValue = '';

  constructor(componentInstance: SharedCostRuleComponent) {
    this.componentInstance = componentInstance;
  }

  activate() {
    this.componentInstance.currentFocusStrategy = this;
    this.componentInstance.focusedFieldType = SharedCostRuleFieldType.Name;
    this.componentInstance.focusedSegmentIndex = -1;
    this.componentInstance.resetActiveDropdown();
    this.currentValue = this.componentInstance.nameElement.nativeElement.value;
  }

  moveFocus(isForward: boolean) {
    const { focusStrategies } = this.componentInstance;
    if (isForward) {
      focusStrategies[SharedCostRuleFieldType.AddSegment].activate();
    } else {
      focusStrategies[SharedCostRuleFieldType.Budget].activate();
    }
  }

  selectElement() {
    this.componentInstance.focusElement(this.componentInstance.nameElement);
  }

  unselectElement() {
    this.componentInstance.nameElement.nativeElement.value = this.currentValue;
    this.componentInstance.focusElement(this.componentInstance.budgetElement);
  }
}
