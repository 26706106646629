import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tag } from 'app/shared/types/tag.interface';
import { TagControlEvent, TagsControlComponent } from 'app/shared/components/tags-control/tags-control.component';
import { TagMapping } from 'app/shared/types/tag-mapping.interface';
import { TagMappingDO, TagService} from 'app/shared/services/backend/tag.service';
import { AutocompleteItem } from 'app/shared/types/autocomplete-item.type';


interface TagIncrement {
  created: TagMapping[];
  updated: TagMapping[];
  deleted: Partial<TagMapping>[];
}

@Injectable({
  providedIn: 'root'
})
export class BudgetObjectTagsService {
  private _tags: Tag[] = [];
  private _autocompleteItems: AutocompleteItem[] = [];

  constructor(private readonly tagService: TagService) {}

  get tags() {
    return this._tags;
  }

  get autocompleteItems() {
    return this._autocompleteItems;
  }

  public setTags(tags: Tag[]) {
    this._tags = tags;
    this._autocompleteItems = tags.map(tag => ({
      id: tag.id,
      name: tag.title
    }));
  }

  private findMappingByTag(targetTagId: number, mappings: TagMapping[]) {
    return mappings.find((mapping) => (
      mapping.tagId === targetTagId
    ));
  }

  public getLocalTags() {
    return this._tags.filter(tag => tag.isCustom && !tag.id)
  }

  public addLocalTag(tagEvent: TagControlEvent) {
    this._tags.push({
      id: tagEvent.id,
      title: tagEvent.title,
      isCustom: true
    });
  }

  public deleteLocalTag(tagEvent: TagControlEvent) {
    const targetIndex = this._tags.findIndex(tag => tag.title === tagEvent.title);

    if (targetIndex >= 0) {
      this._tags.splice(targetIndex, 1);
    }
  }

  public addLocalMapping(tagEvent: TagControlEvent, mappings: TagMapping[]) {
    if (!Array.isArray(mappings)) {
      return;
    }

    if(mappings.some(mapping => mapping.tagId && (mapping.tagId === tagEvent.id))) {
      return; // Tag mappings already exists so we can early exit.
    }

    mappings.push({
      id: null,
      display: tagEvent.title,
      name: tagEvent.title,
      tagId: tagEvent.id
    });
  }

  public deleteLocalMapping(tagEvent: TagControlEvent, mappings: TagMapping[]) {
    if (!Array.isArray(mappings)) {
      return;
    }
    const targetIndex = mappings.findIndex(tm => tm.name === tagEvent.title);

    if (targetIndex >= 0) {
      mappings.splice(targetIndex, 1);
    }
  }

  public applyAddedTags(tags: Tag[] = [], mappings: TagMapping[] = []) {
    if (!Array.isArray(tags)) {
      return;
    }

    tags.forEach(tag => {
      const targetTag = this._tags.find(_tag => _tag.title === tag.title);
      if (targetTag) {
        targetTag.id = tag.id;
      }

      const targetMapping = mappings.find(mapping => mapping.name === tag.title);
      if (targetMapping) {
        targetMapping.tagId = tag.id;
      }
    });
  }

  public getTagsIncrement(prevState: TagMapping[] = [], nextState: TagMapping[] = []): TagIncrement {
    const increment: TagIncrement = { created: [], updated: [], deleted: [] };

    if (Array.isArray(prevState)) {
      prevState
        .filter(tm => tm && tm.id)
        .forEach((tm) => {
          const currentTm = nextState.find((nextTm) => nextTm.id === tm.id);
          if (currentTm) { return; }
          // TM was deleted
          increment.deleted.push({
            id: tm.id,
            name: tm.name
          });
        });
    }

    if (Array.isArray(nextState)) {
      nextState
        .filter(tm => !tm.id)
        .forEach((tm) => {
          // Locally created TM
          const existingTagNames = nextState.filter(t => t.id).map(nextTm => nextTm.name)
          const toBeCreatedTags = increment.created.map(createdTm => createdTm.name)
          
          if (tm.tagId && tm.name && ![...existingTagNames, ...toBeCreatedTags].includes(tm.name)){
            increment.created.push({ ...tm });
          }
        });
    }

    return increment;
  }

  public applyCreatedMappings(tagMappings: TagMapping[], updatedMappings: TagMapping[] = []) {
    if (!Array.isArray(updatedMappings)) {
      return;
    }
    const localMappings = tagMappings.filter(mapping => !mapping.id);

    updatedMappings.forEach((mapping) => {
      const tagId = mapping.tagId;
      const tagMapping = this.findMappingByTag(tagId, localMappings);

      if (tagMapping) {
        tagMapping.id = mapping.id;
      }
    });
  }

  public applyAttachedDynamicMappings(tagMappings: TagMapping[], attachedMappings: TagMappingDO[]) {
    if (attachedMappings && attachedMappings.length) {
      const mappingsToAdd =
        attachedMappings
          .map(tm => BudgetObjectTagsService.fromTagMappingDO(tm))
          .filter(mapping => !tagMappings.some(currentMapping => currentMapping.id === mapping.id));
        tagMappings.push(...mappingsToAdd);
    }
  }

  public applyDeletedMappings(tagMappings: TagMapping[], mappingIds: number[]) {
    if (mappingIds) {
      mappingIds.forEach(id => {
        const index = tagMappings.findIndex(tm => tm.id === id);
        if (index != null) {
          tagMappings.splice(index, 1);
        }
      });
    }
  }

  public checkInputLeftover(tagsControl: TagsControlComponent, tagMappings: TagMapping[]): void {
    const inputValue = tagsControl.tagCtrl?.value;
    if (!inputValue) { return; }

    tagsControl.tagInput.nativeElement.value = '';

    const tagName = inputValue.trim();
    if (!tagName) { return; }

    const isApplied = this.getLocalTags().find(tag => tag.title === tagName);
    if (isApplied) { return; }

    const existingTag = this.autocompleteItems.find(tag => tag.name === tagName);
    if (existingTag) {
      this.addLocalMapping({ id: existingTag.id, title: existingTag.name, inherit: true }, tagMappings);
    } else {
      const newTag = { id: null, title: tagName, inherit: true };
      this.addLocalTag(newTag);
      this.addLocalMapping(newTag, tagMappings);
    }
  }

  /* API CALLS */
  public createTags(companyId: number, tags: Tag[]): Observable<Tag[]> {
    return tags && tags.length ?
      forkJoin(
        tags.map(tag =>
          this.tagService.createTag({
            company: companyId,
            name: tag.title,
            is_custom: tag.isCustom
          }).pipe(
            map(tagDO => {
              return {
                id: tagDO.id,
                title: tagDO.name,
                isCustom: tagDO.is_custom
              }
            })
          )
        )
      ) :
      of(null);
  }

  public getTagMappings(companyId: number, objectId: number, mappingType: string): Observable<TagMapping[]> {
    return this.tagService.getTagMappings(companyId, {
      map_id: objectId,
      mapping_type: mappingType
    }).pipe(
      map(tagMappings => tagMappings && tagMappings.map(
        tagMap => BudgetObjectTagsService.fromTagMappingDO(tagMap)
      ))
    )
  }

  public static fromTagMappingDO(sourceTagMapping: TagMappingDO): TagMapping {
    return {
      id: sourceTagMapping.id,
      tagId: sourceTagMapping.tags_detail.id,
      name: sourceTagMapping.tags_detail.name,
      display: sourceTagMapping.tags_detail.name,
    }
  }

  public createTagMappings(objectId: number, objectType: string, tagMappings: TagMapping[]): Observable<TagMapping[]> {
    return tagMappings && tagMappings.length ?
      forkJoin(
        tagMappings.map(mt =>
          this.tagService.createTagMapping({
            map_id: objectId,
            mapping_type: objectType,
            is_dynamic: true,
            tags: mt.tagId
          }).pipe(
            map(result => {
              return { ...mt, id: result.id };
            })
          )
        )
      ) :
      of(null);
  }

  public deleteTagMappings(tags: Partial<TagMapping>[] = []) {
    return tags && tags.length ?
      forkJoin(
        tags.map((mt) => this.tagService.deleteTagMapping(mt.id))
      ) :
      of(null);
  }
}
