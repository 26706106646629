import { inject, Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BudgetSummaryBarHelpers } from '@shared/components/budget-summary-bar/budget-summary-bar.helpers';
import { SummaryBarCalculationItem, SummaryBarCegCalculationItemList } from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageCegTableConfigurationService } from '@manage-ceg/services/manage-ceg-table-configuration.service';
import { ManageTableBudgetColumnName, ManageTableBudgetColumnsVisibility, ManageTableTotalValues } from '@manage-ceg/types/manage-ceg-page.types';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';
import { Configuration } from 'app/app.constants';

@Injectable()
export class MiniDashCegCalculationService implements OnDestroy {
  private readonly configuration = inject(Configuration);
  private readonly tableDataService = inject(ManageCegTableDataService);
  private readonly manageCegPageService = inject(ManageCegPageService);
  private readonly tableConfigurationService = inject(ManageCegTableConfigurationService);

  private confetti = {
    trigger: new Subject<void>(),
    options: { spread: 110, decay: .85 },
  };
  private calculationItemsDefault: SummaryBarCegCalculationItemList = BudgetSummaryBarHelpers.summaryBarCegDefaultCalculationItems(this.configuration.managePageKnowledgeLink);
  private _calculationItems: SummaryBarCegCalculationItemList = {
    ...this.calculationItemsDefault,
    available: {
      ...this.calculationItemsDefault.available,
      confetti: this.confetti
    }
  };
  public readonly calculationItems: SummaryBarCalculationItem[] = Object.values(this._calculationItems);
  private columnsVisibility: ManageTableBudgetColumnsVisibility;
  private totalValues: ManageTableTotalValues;
  private lastCalculatedAvailable: number;
  private destroy$ = new Subject<void>();

  constructor(
  ) {
    combineLatest([
      this.tableConfigurationService.tableViewConfiguration$.pipe(
        tap(tableConfig => this.columnsVisibility = tableConfig.columns),
      ),
      this.tableDataService.grandTotalForActiveTimeframes$.pipe(
        tap(grandTotalForActiveTimeframes => this.totalValues = grandTotalForActiveTimeframes),
      )
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateCalculationItems());
  }

  private updateCalculationItems(): void {
    const { budget, actual, committed, planned, committedAndPlanned, available } = this.totalValues;
    if (this.showAnimation(available)) {
      this.confetti.trigger.next();
    }
    this.lastCalculatedAvailable = available;
    BudgetSummaryBarHelpers.updateCegCalculationItems(this._calculationItems, {
      budget,
      actual,
      committed,
      planned,
      committedAndPlanned,
      available,
      separateCommittedAndPlanned: !this.columnsVisibility[ManageTableBudgetColumnName.CommittedAndPlanned],
      companyCurrencyCode: this.manageCegPageService.budgetCurrency.code,
    });
  }

  private showAnimation(newAvailable: number): boolean {
    return !this.manageCegPageService.summaryBarLoading$.getValue() &&
      ((this.lastCalculatedAvailable < 0 && newAvailable > 0) || (this.lastCalculatedAvailable !== 0 && newAvailable === 0));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
