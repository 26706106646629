import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'app/app.constants';
import { MetricsProviderDataService } from '../services/metrics-provider-data.service';
import { HubspotCampaignMapping, HubspotMappingTypeName } from './hubspot-campaign-mapping.interface';

export interface CurrentMappingTargetsResponse {
    [externalCampaignId: string]: number;
}

export interface CurrentMappingTargetsRequest {
  mappingGroupType: HubspotMappingTypeName;
  extCampaignIds: string[];
}

@Injectable({
  providedIn: 'root'
})
export class HubspotDataService extends MetricsProviderDataService<HubspotCampaignMapping> {
  constructor(
    private readonly configuration: Configuration,
    private readonly httpClient: HttpClient
  ) {
    super(httpClient, configuration.hubspot_service_url);
  }

  public fetchData<T>(companyId: number, integrationId: string, action: string, params?: object): Observable<{ objects: T[] }> {
    const requestUrl = `${this.serviceBaseUrl}${this.apiPath.fetchData}${companyId}/${integrationId}`;
    return this.http.post<{ objects: T[] }>(requestUrl, JSON.stringify({
      action,
      params
    }));
  }
}
