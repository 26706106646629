import { TagMapping } from '../shared/types/tag-mapping.interface';
import { PlanGoalDO } from '../shared/types/goal.interface';
import { PlanCampaignDO } from '../shared/types/campaign.interface';
import { PlanProgramDO } from '../shared/types/program.interface';
import { Observable } from 'rxjs';
import { BudgetAmountsSummaryData } from './budget-amounts-summary/budget-amounts-summary.component';
import { CategorisedObject } from '../shared/types/plan.type';

export enum ViewSectionName {
  goals = 'goals',
  campaigns = 'campaigns',
  buckets = 'buckets',
  segments = 'segments',
  campaignsExpenses = 'campaigns_expenses'
}

export interface LegendItem {
  title: string;
  tooltipTitle?: string;
  tooltipDesc?: string;
  status: string;
}

export interface TaggedPlanObject {
  parsedTagsData?: TagMapping[];
}

export interface ObjectOwnerData {
  ownerProfileImage?: string;
  ownerFirstName?: string;
  ownerLastName?: string;
  ownerFullName?: string;
}

export interface PlanObject extends TaggedPlanObject, CategorisedObject, ObjectOwnerData {
  userImageCssUrl?: Observable<string>;
  tooltipData?: BudgetAmountsSummaryData;
  typeName?: string; // CampaignType for Campaign and ProgramType for Program
}

export interface PlanGoal extends PlanGoalDO, TaggedPlanObject {
  goalTypeName?: string;
}

export interface PlanCampaign extends PlanCampaignDO, PlanObject {}

export interface PlanProgram extends PlanProgramDO, PlanObject {}
