<article class="auth-page quick-start-screen">
  <h1 class="logo">
      <a href="javascript:;" title="Planful" [routerLink]="['/']">
        <img src="assets/images/planful-logo-colored.svg" title="" alt="" />
      </a>
  </h1>
	<a class="LogoutLink-removable" href="javascript:;" (click)="userManager.logout()"><img src="assets/images/icon-signOutHover.png" title="" alt=""> Logout</a>
  <section class="auth-page-content ">
    <h3 class="auth-page-content-title">Welcome to Planful, {{userName || ''}}.</h3>
    <div class="quick-start-container">
      <div class="quick-start-form-container">
        <form class="quick-start-form" [formGroup]="companyInfo" novalidate>
          <div class="form-wrapper">
            <h4 class="auth-page-content-subtitle">Tell us a little about your company</h4>
            <div class="form-field">
              <label class="form-field-label">Who are you planning for?</label>
              <div class="form-field-radiogroup" >
                <label class="form-field-radiogroup-item">
                  <input type="radio" name="planning_for" formControlName="planning_for" value="Entire Company">
                  <span></span>
                  Entire company
                </label>
                <label class="form-field-radiogroup-item">
                  <input type="radio" name="planning_for" formControlName="planning_for" value="Business Unit" >
                  <span></span>
                  A business unit
                </label>
              </div>
            </div>
            <div class="form-field">
              <label class="form-field-label">How many employees are in your company or business unit?</label>
              <div class="select-wrapper">
                <select class="form-field-input" formControlName="company_size_id" required>
                  <option value="">Select</option>
                  <option *ngFor="let s of employeeStrength" [ngValue]="s.id">{{s.name}}</option>
                </select>
              </div>
              <div class="md-errors-spacer error" [hidden]="companyInfo.controls['company_size_id'].valid || companyInfo.controls['company_size_id'].pristine">
                <div *ngIf="companyInfo.controls['company_size_id'].errors && companyInfo.controls['company_size_id'].errors.required">
                  {{validations.ValiditionMessages.INVALID_COMPANY_STRENGTH}}
                </div>
              </div>
            </div>
            <div class="form-field">
              <label class="form-field-label">Which industry are you in?</label>
              <div class="select-wrapper">
                <select class="form-field-input" formControlName="company_industry_id" required (change)="setGraph('industry_type', $event.target.value)">
                  <option value="">Select</option>
                  <option *ngFor="let industry of industries" value="{{industry.id}}">{{industry.name}}</option>
                </select>
              </div>
              <div class="md-errors-spacer error" [hidden]="companyInfo.controls['company_industry_id'].valid || companyInfo.controls['company_industry_id'].pristine">
                <div *ngIf="companyInfo.controls['company_industry_id'].errors && companyInfo.controls['company_industry_id'].errors.required">
                  {{validations.ValiditionMessages.INVALID_INDUSTRY_TYPE}}
                </div>
              </div>
            </div>
            <div class="form-field">
              <label class="form-field-label">How much is your sales revenue?</label>
              <div class="select-wrapper">
                <select class="form-field-input" formControlName="company_revenue_id" required (change)="setGraph('revenue_type', $event.target.value)">
                  <option value="">Select</option>
                  <option *ngFor="let revenue of revenues" value="{{revenue.id}}">{{revenue.name}}</option>
                </select>
              </div>
              <div class="md-errors-spacer error" [hidden]="companyInfo.controls['company_revenue_id'].valid || companyInfo.controls['company_revenue_id'].pristine">
                <div *ngIf="companyInfo.controls['company_revenue_id'].errors && companyInfo.controls['company_revenue_id'].errors.required">
                  {{validations.ValiditionMessages.INVALID_REVENUE_TYPE}}
                </div>
                </div>
            </div>
            <p class="auth-page-text">Perfect. Let's get you started!</p>
            <div class="form-actions">
              <button mat-flat-button color="primary" type="button" (click)="setupCompany();" [disabled]="companyInfo.status == 'INVALID'" >
                Take me to Planful!
              </button>
            </div>
          </div>
				</form>
      </div>
			<div class="quick-start-chart-container">
        <figure id="box-loader" class="new-loader" [hidden]="!isGraphLoading"><span></span></figure>
				<div class="chart-wrapper">
          <h4 class="auth-page-content-subtitle">How much do companies like yours <span>spend on marketing?</span></h4>
          <google-chart #infographic [data]="columnChartData"></google-chart>
				</div>
			</div>
		</div>
	</section>
</article>
