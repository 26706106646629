import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckboxValue } from '@shared/enums/checkbox-value.enum';
import { createDeepCopy } from '@shared/utils/common.utils';
import { BudgetData, BudgetDataSpecification } from '../../types/budget-data-specification.interface';
import { DuplicateBudgetContext } from '@shared/types/dialog-context.interface';

enum HierarchyControls {
  SEGMENTS = 'segments',
  SHARED_COST_RULES = 'split_rules',
  GOALS = 'goals',
  CAMPAIGNS = 'campaigns',
  EXPENSE_GROUPS = 'programs',
  EXPENSES = 'expenses',
}

enum BudgetLevelControls {
  ATTACHMENTS = 'attachments'
}

@Component({
  selector: 'budget-data-duplicate-specification-dialog',
  templateUrl: './budget-data-duplicate-specification-dialog.component.html',
  styleUrls: ['./budget-data-duplicate-specification-dialog.component.scss']
})
export class BudgetDataDuplicateSpecificationDialogComponent implements OnInit {
  private totalOptions = 0;
  private budgetData: BudgetData[];
  private hierarchyParent: BudgetData[];
  public allSelectionState: CheckboxValue = CheckboxValue.NotActive;
  public allHierarchySelectionState: CheckboxValue = CheckboxValue.NotActive;
  public readonly CheckboxValue = CheckboxValue;
  public specificationData: BudgetDataSpecification;
  public selectedOptionsCount = 0;
  public budgetPermission = false;

  constructor(
    public dialogRef: MatDialogRef<BudgetDataDuplicateSpecificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DuplicateBudgetContext
  ) {}

  ngOnInit(): void {
    this.specificationData = createDeepCopy(this.data.context);
    this.budgetData = [...this.specificationData.budgetData, ...this.specificationData.hierarchyGroup];
    this.hierarchyParent = [
      ...(this.specificationData.budgetData.find(item => item.children)?.children || []),
      ...this.specificationData.hierarchyGroup];
    this.changeAttachmentsControlState(this.hierarchyParent);
    this.totalOptions = this.getAllOptions('id');
  }

  private toggleSelection(options: BudgetData[], event: boolean): void {
    options.forEach(option => {
      if (option.id) {
        option.value = event;
      }
      if (option.children?.length) {
        this.toggleSelection(option.children, event);
      }
    })
  }

  private checkAllSelection(): void {
    this.selectedOptionsCount = this.getAllOptions('value');
    if (!this.selectedOptionsCount) {
      this.allSelectionState = this.allHierarchySelectionState = CheckboxValue.NotActive;
    } else if (this.selectedOptionsCount && this.selectedOptionsCount < this.totalOptions) {
      this.allSelectionState = CheckboxValue.Indeterminate;
    } else {
      this.allSelectionState = this.allHierarchySelectionState = CheckboxValue.Active;
    }
  }

  private checkHierarchySelection(group: BudgetData[]): void {
    const allChildren = group.length;
    const selectedOptions = group.filter(item => item.value).length;

    this.allHierarchySelectionState = !selectedOptions
      ? CheckboxValue.NotActive
      : selectedOptions && selectedOptions < allChildren
        ? CheckboxValue.Indeterminate
        : CheckboxValue.Active
  }

  private getAllOptions(key: string): number {
    return this.budgetData.reduce((sum, option: BudgetData) => {
      if (option[key]) {
        sum++;
      }
      if (option.children?.length) {
        const childrenCount = option.children
          .filter(item => item[key]).length
        sum += childrenCount;
      }
      return sum;
    }, 0);
  }

  private uncheckGroup(key: string, value: boolean): void {
    this.specificationData.hierarchyGroup.filter(item => item?.parent === key).forEach(item => {
      item.value = value;
      this.uncheckGroup(item.id, value)
    });
  }

  private changeAttachmentsControlState(group: BudgetData[]): void {
    // Define the hierarchy controls to be checked
    const hierarchyControlsToBeChecked: HierarchyControls[] = [
      HierarchyControls.GOALS,
      HierarchyControls.EXPENSES,
      HierarchyControls.CAMPAIGNS,
      HierarchyControls.EXPENSE_GROUPS
    ];

    this.сhangeControlState(group, this.specificationData.budgetData, hierarchyControlsToBeChecked, BudgetLevelControls.ATTACHMENTS);
  }

  private сhangeControlState<T extends BudgetData>(group: T[], budgetData: BudgetData[], hierarchyControlsToBeChecked: string[], controlId: string): void {
    const controlData = budgetData.find(item => item.id === controlId);
    const isSomeOptionSelected: boolean = group.some(item => item.value && hierarchyControlsToBeChecked.includes(item.id));

    controlData.disabled = !isSomeOptionSelected;
    if (controlData.disabled) {
      controlData.value = false;
    }
  }

  public toggleAllSelection(event: boolean): void {
    this.toggleSelection(this.budgetData, event);
    this.checkAllSelection();
    this.changeAttachmentsControlState(this.hierarchyParent);
  }

  public toggleGroupSelection(checked: boolean, group: BudgetData[]): void {
    group.forEach(item => {
      item.value = checked;
      if (!checked && item?.hasGroup) {
        this.uncheckGroup(item.id, checked);
      }
    });
    this.checkHierarchySelection(group);
    this.checkAllSelection();
  }

  public toggleItemSelection(checked: boolean, option: BudgetData, group?: BudgetData[]): void {
    option.value = checked;

    if (group) {
      this.checkHierarchySelection(group);
      this.changeAttachmentsControlState(group);
    }
    if (!checked) {
      this.uncheckGroup(option.id, checked);
    }
    this.checkAllSelection();
  }

  public getSelectedOptions(): {[key: string]: boolean} {
    return this.budgetData.reduce((obj, option: BudgetData) => {
      if (option.value) {
        obj[option.id] = option.value;
      }
      if (option.children?.length) {
        option.children
          .filter(item => item.value)
          .forEach(item => obj[item.id] = item.value)
      }
      return {...obj, ...{restrict_permissions: this.budgetPermission}};
    }, {})
  }

  public isParentChecked(parent: string): boolean {
    if (!parent) {
      return false;
    }
    return !this.hierarchyParent.find(item => item.id === parent)?.value;
  }

}
