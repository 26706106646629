import { Component, ElementRef, HostBinding, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'plc-conversion-rate',
  templateUrl: './conversion-rate.component.html',
  styleUrls: ['./conversion-rate.component.scss']
})
export class ConversionRateComponent {
  longNumber = false;
  prevValueStr: string;
  rateControl = new UntypedFormControl();

  @Output() rateChanged = new EventEmitter<number>();
  @HostBinding('class.focused') isActive = false;
  @HostBinding('class.edit-mode')
  @Input() editModeActive = false;
  @Input() outlineStyle: number;
  @Input() selectOnFocus = false;
  @Output() focusChanged = new EventEmitter<boolean>();
  @ViewChild('input') input: ElementRef;
  @Input() set rate(val: number) {
    this.rateControl.setValue(val);
    this.setLongNumberFlag(val);
  };
  @Input() set setFocus(state: boolean) {
    if (state) {
      setTimeout(() => {
        this.setInputFocus();
      })
    }
  };

  public setLongNumberFlag(value: number) {
    const valueStr = value?.toString() || '';
    const fontSizeLimit = 3;
    this.longNumber = valueStr.length > fontSizeLimit;
  }

  setInputFocus() {
    if (this.editModeActive) {
      this.input?.nativeElement.focus();
    }
  }

  onFocus() {
    if (!this.editModeActive) {
      return;
    }
    this.isActive = true;
    this.focusChanged.emit(true);
  }

  onBlur() {
    if (!this.editModeActive) {
      return;
    }
    this.isActive = false;
    this.focusChanged.emit(false);
    this.checkNegative();
    this.clearLeadingZero();
    this.rateChanged.next(this.rateControl.value);
  }

  checkNegative() {
    const value = this.rateControl.value;
    if (value < 0) {
      this.rateControl.setValue(value * -1);
    }
  }

  clearLeadingZero() {
    const tempValue = this.rateControl.value?.toString();
    this.rateControl.setValue(tempValue ? +tempValue : null);
  }
}
