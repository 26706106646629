<div class="tasks-list"
[id]="id"
  [ngClass]="{
    'personal-mode': isPersonalMode,
    'is-loading': isLoading,
    'drawer': isDrawer
  }">
  <div class="header">
    <details-widget-title [count]="data?.length" [text]="title" [showCount]="!isLoading">
      <fa-duotone-icon [icon]="['fad', 'tasks']" slot="icon"></fa-duotone-icon>
    </details-widget-title>
    <add-object-link
      *ngIf="!isReadOnlyMode && !isPersonalMode"
      text="Add task"
      [handler]="handleAddTask.bind(this)"
      [id]="id + '-add-task'"
    ></add-object-link>
  </div>
  <div class="content">
    <div class="grid-table" [id]="id + '-table'" [class.is-scrollable]="data?.length >= 5" [class.is-scrolled]="isBodyScrolled">
      <div class="grid-row grid-header">
        <div class="grid-cell grid-title">Name</div>
        <div class="grid-cell grid-title" *ngIf="!isPersonalMode">Owner</div>
        <div class="grid-cell grid-title" *ngIf="isPersonalMode">Relates to</div>
        <div class="grid-cell grid-title">Due Date</div>
        <div class="grid-cell grid-title">Status</div>
      </div>
      <div
        class="grid-body"
        *ngIf="data?.length"
        isScrolled
        (isScrolledChanged)="handleBodyScrolledChange($event)"
      >
        <div class="grid-row" *ngFor="let task of data; let i = index; trackBy: trackByFn" [id]="id + '_row_' + i">
          <div class="grid-cell name-cell">
            <div class="control">
              <task-name
                [value]="task.name"
                [isReadOnly]="isReadOnlyMode || isPersonalMode"
                (onNameChanged)="handleValueChanged($event, 'name', i)"
              ></task-name>
            </div>
          </div>
          <div class="grid-cell relation-cell" *ngIf="isPersonalMode">
            <div class="control">
              <div class="relation-link" *ngIf="task.program?.id" (click)="openProgramDetails(task.program?.id)">
                <fa-duotone-icon [icon]="['fad', 'briefcase']"></fa-duotone-icon>
                <div
                  class="name"
                  [matTooltip]="task.program?.name"
                  matTooltipClass="dark-theme-tooltip multi-line above"
                  matTooltipPosition="above"
                >{{ task.program?.name }}</div>
              </div>
              <div class="relation-link" *ngIf="task.campaign?.id" (click)="openCampaignDetails(task.campaign?.id)">
                <fa-duotone-icon [icon]="['fad', 'rocket-launch']"></fa-duotone-icon>
                <div
                  class="name"
                  [matTooltip]="task.campaign?.name"
                  matTooltipClass="dark-theme-tooltip multi-line above"
                  matTooltipPosition="above"
                >{{ task.campaign?.name }}</div>
              </div>
            </div>
          </div>
          <div class="grid-cell owner-cell" *ngIf="!isPersonalMode">
            <div class="control">
              <flat-select
                [options]="owners"
                [value]="task.owner"
                [isReadOnly]="isReadOnlyMode"
                (onValueChanged)="handleValueChanged($event, 'owner', i)"
              >
              </flat-select>
            </div>
          </div>
          <div class="grid-cell date-cell">
            <div class="control">
              <task-date
                [value]="task.dueDate"
                (onStatusChanged)="handleValueChanged($event, 'dueDate', i)"
                [isIncoming]="task.isIncoming"
                [isLate]="task.isLate && task.status?.name === STATUS_NAMES.LATE"
                [isReadOnly]="isReadOnlyMode"
                [daysLeft]="task.daysLeft"
                [todayDate]="todayDate"
              ></task-date>
            </div>
          </div>
          <div class="grid-cell status-cell">
            <div class="control">
              <task-status
                [options]="statuses"
                [value]="task.status"
                [isReadOnly]="isReadOnlyMode"
                (onStatusChanged)="handleValueChanged($event, 'status', i)"
              ></task-status>
              <div class="delete-icon" *ngIf="!isReadOnlyMode && !isPersonalMode">
                <button class="mat-button" mat-icon-button (click)="handleDelete(i)">
                  <fa-duotone-icon
                    *ngIf="!isDrawer else fasIcon"
                    [icon]="['fad', 'trash-alt']"></fa-duotone-icon>
                  <ng-template #fasIcon>
                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <details-data-placeholder *ngIf="!data?.length">
      No tasks
    </details-data-placeholder>
  </div>
</div>
