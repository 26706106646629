import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'add-metric-dialog',
  templateUrl: './add-metric-dialog.component.html',
  styleUrls: ['./add-metric-dialog.component.scss']
})
export class AddMetricDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddMetricDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any
  ) {}
}
