import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { ExpenseUpdateField } from 'app/expenses/types/expense-page.type';
import { HierarchyViewMode, SidebarHierarchyOption, ViewModeCustomIcons } from '@spending/types/expense-page.type';
import { DropState } from '@shared/types/droppable-state.type';
import { SpendingSidebarService } from '@spending/services/spending-sidebar.service';
import { SelectableSpendingRow } from '@spending/services/expense-page-selection.service';
import { SpendingService } from '@spending/services/spending.service';

@Component({
  selector: 'sidebar-flat-options-list',
  templateUrl: './sidebar-flat-options-list.component.html',
  styleUrls: ['./sidebar-flat-options-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarFlatOptionsListComponent {

  @Input() flatOptions: SidebarHierarchyOption[];
  @Input() viewMode: HierarchyViewMode;
  @Input() searchQuery: string;
  @Input() isAllSelected;
  @Input() expenseCountsMap: Record<number, number>;

  @Output() onDrop: EventEmitter<SidebarHierarchyOption> = new EventEmitter<SidebarHierarchyOption>();

  public onDragOverItem: {[key: number]: DropState} = {};
  public dropState = DropState;

  readonly hierarchyViewMode = HierarchyViewMode;
  readonly viewModeCustomIcons = ViewModeCustomIcons;
  readonly numberFormat = '1.0-10';

  defaultOptionTitle = {
    [HierarchyViewMode.GlCode]: 'GL Code',
    [HierarchyViewMode.Vendor]: 'Vendor',
  };

  constructor(
    private readonly spendingSidebarService: SpendingSidebarService,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
    private readonly spendingService: SpendingService,
  ) {
    this.spendingSidebarService.dragEndAction$
      .subscribe(() => {
        this.onDragOverItem = {};
        this.cdr.detectChanges();
      });
  }

  get isSearchActive(): boolean {
    return this.searchQuery.length > 2;
  }

  getOptionExpenseCount(option: SidebarHierarchyOption): number {
    const key = option.isDefault ? 'null' : option.id;
    return this.expenseCountsMap[key] || 0;
  }

  toggleSelectedState(option: SidebarHierarchyOption): void {
    this.spendingSidebarService.toggleSelected(option);
  }

  isOptionSelected(option: SidebarHierarchyOption): boolean {
    const selectedOptionValue = this.spendingSidebarService.selectedOptionsMapValue;
    return !!selectedOptionValue[option.id] || this.isAllSelected;
  }

  onHandleDragOver(event, option: SidebarHierarchyOption): void {
    this.zone.run(() => {
      this.onDragOverItem = {};
      const expenses = this.spendingService.dragExpenses;
      if (this.isItemDroppable(option, expenses)) {
        this.onDragOverItem[option.id] = this.dropState.FORBID;
        return;
      }
      this.onDragOverItem[option.id] = this.dropState.ALLOW;
    });
  }

  onHandleDrop(option: SidebarHierarchyOption): void {
    this.zone.run(() => {
      const expenses = this.spendingService.dragExpenses;
      if (this.isItemDroppable(option, expenses)) {
        return;
      }
      this.onDrop.emit(option);
    });
  }

  onHandleDragLeave(event, optionId: number): void {
    if (this.onDragOverItem[optionId]) {
      this.onDragOverItem = {};
    }
  }

  private isItemDroppable(item: SidebarHierarchyOption, expenses: SelectableSpendingRow[]): boolean {
    return item?.locked
      || !item.id
      || expenses.every(expense => expense.expenseObject[ExpenseUpdateField[item.objectType]] === item.id);
  }
}
