import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ManagePageApiService, UpdatedSegmentAmountsData, UpdateObjectsResult } from './manage-page-api.service';
import { ManageTableDataService } from './manage-table-data.service';
import {
  CreateItemTemplateEvent,
  ManageTableActionDataSource,
  ManageTableAllocationsUpdatePayload,
  ManageTableBudgetAllocationValue,
  ManageTableFullRowValues,
  ManageTableRow,
  ManageTableRowValues, NewManageTableRowTemplate,
} from '../components/manage-table/manage-table.types';
import { SegmentedObjectTimeframe } from 'app/shared/types/object-timeframe.interface';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { Program, ProgramDO } from 'app/shared/types/program.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ManageTableHelpers } from './manage-table-helpers';
import { BulkActionTargets } from '@shared/types/bulk-action-targets.type';
import { AllocatableRowTypes, GroupingRowTypes, ManageTableRowTypeLabel } from '../components/manage-table/manage-table.constants';
import { ManageTableFastDataBuilder } from './manage-table-fast-data-builder';
import { ManagePageModeService } from './manage-page-mode.service';
import { BudgetObjectSegmentData } from 'app/shared/types/budget-object-segment-data.interface';
import { SegmentDataInheritanceService } from 'app/shared/services/segment-data-inheritance.service';
import { SegmentDataInheritanceAction } from 'app/shared/types/segment-data-inheritance.interface';
import { BulkActionPayloads } from '../types/bulk-action-payloads.type';
import { LocationService } from 'app/budget-object-details/services/location.service';
import { Configuration } from 'app/app.constants';
import { PlanObjectExpensesData } from 'app/shared/types/plan-object-expenses-data.type';
import {
  AllocationAmountsMap,
  AllocationCheckResult,
  AllocationCheckResultData,
  BudgetObjectAllocationService,
  ParentDialogActionSource
} from 'app/budget-object-details/services/budget-object-allocation.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { ManageTableDataValidationService } from './manage-table-data-validation.service';
import { createDeepCopy, getNumericValue, roundDecimal, sumAndRound } from 'app/shared/utils/common.utils';
import { SegmentedBudgetObject } from 'app/shared/types/segmented-budget-object';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { MetricIntegrationsProviderService } from 'app/metric-integrations/services/metric-integrations-provider.service';
import { Campaign, CampaignDO } from 'app/shared/types/campaign.interface';
import { UndoCallback } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';
import { ManageTableBulkDataItem, ManageTableBulkParentData, ManageTableBulkSegmentData } from '../types/manage-table-bulk-data-map.types';
import { ManageTableParentContext } from '../types/manage-table-parent-context.interface';
import { ManageTableSpendingHelpers } from './manage-table-spending-helpers';
import { BudgetSegmentDO } from 'app/shared/types/segment.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { ExpenseCostAdjustmentDataService } from 'app/metric-integrations/expense-cost-adjustment/expense-cost-adjustment-data.service';
import { CampaignTypeNames } from '@shared/enums/campaign-types.enum';
import { ManageTableViewMode } from '../types/manage-table-view-mode.type';
import { ProgramAllocation } from '@shared/types/budget-object-allocation.interface';
import { Goal } from '@shared/types/goal.interface';
import { getObjectTypeKey } from '@shared/utils/budget.utils';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

interface UpdateAllocationContext {
  prevValue?: number;
  diffValue?: number;
  value: number;
  valueToSave?: number;
  timeframeId: number;
  record: ManageTableRow;
  targetAlloc?: SegmentedObjectTimeframe;
  targetRecordValue?: ManageTableBudgetAllocationValue;
}

interface UpdateSegmentAllocationContext {
  amountDiff: number;
  amount: number;
  timeframeId: number;
  record: ManageTableRow;
}

@Injectable()
export class ManageTableDataMutationService implements OnDestroy {
  private readonly apiService = inject(ManagePageApiService);
  private readonly tableDataService = inject(ManageTableDataService);
  private readonly modeService = inject(ManagePageModeService);
  private readonly utilityService = inject(UtilityService);
  private readonly segmentDataInheritanceService = inject(SegmentDataInheritanceService);
  private readonly locationService = inject(LocationService);
  private readonly configuration = inject(Configuration);
  private readonly budgetObjectAllocationService = inject(BudgetObjectAllocationService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly dataValidationService = inject(ManageTableDataValidationService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly integrationsProviderService = inject(MetricIntegrationsProviderService);
  private readonly expenseCostAdjustmentDataService = inject(ExpenseCostAdjustmentDataService);

  private readonly TOASTR_DURATION = 3000;
  private readonly ERROR_MESSAGE = {
    FAILED_TO_UPDATE_ALLOCATION: 'Failed to update allocation',
    FAILED_TO_UPDATE_SEGMENT_ALLOCATION: 'Failed to update segment allocation',
    FAILED_TO_UPDATE_ALLOCATIONS: 'Failed to update allocations',
    FAILED_TO_UPDATE_OBJECT_AMOUNT: 'Failed to update object amount',
    FAILED_TO_DUPLICATE_OBJECT: 'Failed to duplicate object',
  };
  private readonly OBJECT_TYPES = this.configuration.OBJECT_TYPES;
  private readonly destroy$ = new Subject<void>();
  private allocationRestrictionsConflict = false;
  public readonly grandTotalUpdateTrigger = new Subject<void>();
  public newItemTemplate: NewManageTableRowTemplate;

  public static prepareAllocationsUpdatePayload(
    dataSource: ManageTableActionDataSource,
    amount: number,
    payload: ManageTableAllocationsUpdatePayload = {}
  ): ManageTableAllocationsUpdatePayload {
    const { timeframe, record } = dataSource;

    if (!payload[record.id]) {
      payload[record.id] = {
        record,
        updates: []
      };
    }

    payload[record.id].updates.push({
      timeframeId: timeframe?.id,
      amount
    });

    return payload;
  }

  private static isGroupingRecord(record: ManageTableRow): boolean {
    return record != null && (GroupingRowTypes.includes(record.type) || ManageTableDataMutationService.isSegmentlessCampaign(record));
  }

  private static isSegmentlessCampaign(record: ManageTableRow) {
    return record != null && record.type === ManageTableRowType.Campaign && ManageTableHelpers.isSegmentlessObject(record);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private get grandTotal() {
    return this.tableDataService.grandTotal;
  }

  private showMessage(message: string) {
    this.utilityService.showCustomToastr(message, null, { timeOut: this.TOASTR_DURATION });
  }

  private handleError(error: Error, message: string) {
    console.warn(`[ManageTableDataService ERROR]: ${error.message}`);
    this.showMessage(message);
  }

  private updateSpendingValuesDiff(target: ManageTableRowValues, diffValue: number) {
    if (target?.spending) {
      target.spending.allocated += diffValue;
      target.spending.totalRemaining += diffValue;
      target.spending.totalRemainingWithPlanned += diffValue;
      target.spending.totalAvailable += diffValue;
      target.spending.totalAvailableWithPlanned += diffValue;
    }
  }

  private updateUnallocatedValuesDiff(
    record: ManageTableRow,
    timeframeId: number,
    diffValue: number
  ): number {
    if (!record?.unallocated) {
      return;
    }

    if (timeframeId) {
      record.unallocated.values[timeframeId].allocated -= diffValue;
    }
    record.unallocated.total.allocated -= diffValue;
  }

  private getGroupingParent(record: ManageTableRow): ManageTableRow {
    const parentRecord = this.tableDataService.getRecordById(record.parentId);
    if (ManageTableDataMutationService.isGroupingRecord(parentRecord)) {
      return parentRecord;
    }

    const parentSegmentId = record.segmentId;
    const parentSegmentRow = this.tableDataService.getRecordByIdAndType(ManageTableRowType.Segment, parentSegmentId);
    // For segment view: Segment row might be considered as a grouping parent, due to the record's direct parent being in another segment
    if (parentSegmentRow && parentRecord && parentSegmentId !== parentRecord.segmentId) {
      return parentSegmentRow;
    }

    return null;
  }

  private updateAllocatedToChildrenParentsSpendingDiff(parentId: string, diffValue: number) {
    const parentRecord = this.tableDataService.getRecordById(parentId);
    // Update direct parent's 'Remaining child budget' and 'Total available' with diff
    ManageTableSpendingHelpers.updateAllocatedToChildrenSpendingValuesDiff(parentRecord, diffValue);

    const iterationsLimit = 2;
    let grandParentRecord = this.getGroupingParent(parentRecord);
    let iteration = 0;

    // While grandparent is a grouping record iterate up the hierarchy to update 'Remaining child budget' and 'Total available' with diff
    // For ex.: Segment -> SegmentGroup
    while (grandParentRecord && iteration < iterationsLimit) {
      iteration++;
      ManageTableSpendingHelpers.updateAllocatedToChildrenSpendingValuesDiff(grandParentRecord, diffValue);
      grandParentRecord = this.getGroupingParent(grandParentRecord);
    }
  }

  private updateParentsDiff(
    record: ManageTableRow,
    diffValue: number,
    timeframeId: number
  ) {
    const groupingParentRecord = this.getGroupingParent(record);

    if (groupingParentRecord) {
      if (timeframeId) {
        groupingParentRecord.values[timeframeId].allocated += diffValue;
        groupingParentRecord.values[timeframeId].remainingAllocated += diffValue;
      }
      groupingParentRecord.total.allocated += diffValue;
      groupingParentRecord.total.remainingAllocated += diffValue;

      this.updateUnallocatedValuesDiff(groupingParentRecord, timeframeId, diffValue);
      this.updateSpendingValuesDiff(groupingParentRecord, diffValue);
      ManageTableSpendingHelpers.syncSegmentSpending(groupingParentRecord);
      ManageTableSpendingHelpers.syncUnallocatedSpending(groupingParentRecord.unallocated);
      this.updateParentsDiff(groupingParentRecord, diffValue, timeframeId);
    } else if (record.parentId) {
      // If record's got a non-grouping parent -> update its allocated to children values
      this.updateAllocatedToChildrenParentsSpendingDiff(record.parentId, diffValue);
    }
  }

  private updateAllocationState(context: UpdateAllocationContext) {
    const { targetRecordValue, record, value, diffValue, timeframeId } = context;

    if (targetRecordValue) {
      targetRecordValue.allocated = value;
      targetRecordValue.remainingAllocated += diffValue;
    }
    if (timeframeId && record.total) {
      record.total.allocated += diffValue;
      record.total.remainingAllocated += diffValue;
    }
    this.updateSpendingValuesDiff(record, diffValue);
    this.updateParentsDiff(record, diffValue, timeframeId);

    const groupingParentRecord = this.getGroupingParent(record);
    if (groupingParentRecord) {
      this.grandTotalUpdateTrigger.next();
    } else if (!record.parentId) {
      this.grandTotalUpdateTrigger.next();
    }
  }

  private updateSegmentAllocationDependantValues(
    context: UpdateSegmentAllocationContext,
    targetValues: ManageTableFullRowValues,
    isGrouping = false
  ) {
    const { amount, amountDiff, timeframeId } = context;

    if (!targetValues?.segment || !targetValues?.unallocated) {
      return;
    }
    if (isGrouping) {
      targetValues.segment.values[timeframeId].allocated += amountDiff;
    } else {
      targetValues.segment.values[timeframeId].allocated = amount;
    }
    targetValues.segment.total.allocated += amountDiff;
    targetValues.unallocated.values[timeframeId].allocated += amountDiff;
    targetValues.unallocated.total.allocated += amountDiff;
    this.updateSpendingValuesDiff(targetValues.segment, amountDiff);
    this.updateSpendingValuesDiff(targetValues.unallocated, amountDiff);
  }

  private updateSegmentAllocationState(context: UpdateSegmentAllocationContext) {
    const groupingParentRecord = this.getGroupingParent(context.record);

    this.updateSegmentAllocationDependantValues(context, context.record);
    this.updateSegmentAllocationDependantValues(context, this.grandTotal, true);
    this.tableDataService.refreshGrandTotalData();
    if (groupingParentRecord) {
      this.updateSegmentAllocationDependantValues(context, groupingParentRecord, true);
    }
  }

  private updateAllocation(updateContext: UpdateAllocationContext): Observable<any> {
    const { value, valueToSave, record, targetAlloc } = updateContext;

    this.updateAllocationState(updateContext);
    if (targetAlloc) {
      targetAlloc.amount = value;
      return this.apiService.updateObjectAllocation(record, targetAlloc.id, {
        source_amount: valueToSave,
        lock_for_integrations: true
      });
    }

    return of(null);
  }

  private rollbackAllocation(updateContext: UpdateAllocationContext) {
    const { targetAlloc } = updateContext;
    const newContext: UpdateAllocationContext = {
      ...updateContext,
      value: updateContext.prevValue,
      diffValue: -updateContext.diffValue,
    };

    this.updateAllocationState(newContext);
    if (targetAlloc) {
      targetAlloc.amount = updateContext.prevValue;
    }
  }

  private processAllocationUpdateContext(
    context: UpdateAllocationContext
  ): UpdateAllocationContext {
    const { timeframeId, record, value, valueToSave } = context;
    const targetRecordValue = timeframeId ? record.values[timeframeId] : record.total;
    const prevValue = targetRecordValue?.allocated;
    const diffValue = roundDecimal(value - prevValue, 2);
    const targetAlloc = record.allocations?.find(tf => tf.company_budget_alloc === timeframeId);

    return {
      ...context,
      targetRecordValue,
      targetAlloc,
      prevValue,
      diffValue,
      valueToSave: valueToSave != null ? valueToSave : value
    };
  }

  private prepareAllocationUpdate(
    context: UpdateAllocationContext
  ): { rollback: Function, request$: Observable<any> } {
    const processedContext = this.processAllocationUpdateContext(context);
    const onError = (err: Error) => {
      this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_UPDATE_ALLOCATION);
      rollback();
      return of(null);
    };
    const rollback = () => {
      this.rollbackAllocation(processedContext);
    };

    try {
      return {
        request$: this.updateAllocation(processedContext).pipe(
          catchError(err => onError(err))
        ),
        rollback
      }
    } catch (err) {
      onError(err);
      return null;
    }
  }

  private updateClosedFlag<T extends { id?: number }>(
    data: T[],
    recordType: ManageTableRowType,
    isClosed: boolean
  ) {
    data.forEach(item => {
      const record = this.tableDataService.getRecordByIdAndType(recordType, item.id);
      if (record) {
        record.isClosed = isClosed;
      }
    });
  }

  private getBulkActionPayloads(context: {
    targets: BulkActionTargets;
    processNested: boolean;
    segmentData?: BudgetObjectSegmentData;
    replaceSegmentWithParents?: boolean;
    parentData?: ManageTableParentContext;
  }): BulkActionPayloads<Partial<CampaignDO> | Partial<ProgramDO>> {
    const { targets, segmentData, parentData, replaceSegmentWithParents, processNested } = context;
    const getPayload = (id: number, objectType: string) => {
      const payload: any = {
        id,
        process_nested: processNested
      };

      if (segmentData) {
        payload.split_rule = segmentData.sharedCostRuleId;
        payload.company_budget_segment1 = segmentData.budgetSegmentId;
      }
      if (parentData) {
        const { segmentData: parentSegmentData, objectType: parentType, objectId: parentId } = parentData;

        if (replaceSegmentWithParents) {
          payload.split_rule = parentSegmentData.sharedCostRuleId;
          payload.company_budget_segment1 = parentSegmentData.budgetSegmentId;
        }
        if (objectType === this.OBJECT_TYPES.campaign) {
          payload.parent_campaign = parentType === this.OBJECT_TYPES.campaign ? parentId : null;
        }
        if (objectType === this.OBJECT_TYPES.program) {
          payload.campaign = parentType === this.OBJECT_TYPES.campaign ? parentId : null;
        }
        payload.goal = parentType === this.OBJECT_TYPES.goal ? parentId : null;
      }

      return payload;
    };

    return {
      campaigns: targets.campaigns.map(id => getPayload(id, this.OBJECT_TYPES.campaign)),
      expGroups: targets.expGroups.map(id => getPayload(id, this.OBJECT_TYPES.program))
    };
  }

  private insertDataRecord(record: ManageTableRow, timeframes: BudgetTimeframe[]) {
    this.tableDataService.flatDataMap[record.id] = record;

    if (record.parentId) {
      const parentRecord = this.tableDataService.getRecordById(record.parentId);

      parentRecord.children?.push(record);
      if (ManageTableDataMutationService.isGroupingRecord(parentRecord)) {
        ManageTableHelpers.calcParentRecordValues(parentRecord, timeframes);
      }
    } else {
      this.tableDataService.data.push(record);
    }
  }

  private findFirstOpenAllocation(allocations: SegmentedObjectTimeframe[], budgetTimeframes: BudgetTimeframe[]) {
    return allocations.find(alloc => {
      const targetBudgetTimeframe = budgetTimeframes.find(tf => tf.id === alloc.company_budget_alloc);

      return targetBudgetTimeframe
        ? !targetBudgetTimeframe.locked
        : false;
    });
  }

  public updateParentWithAmountDiff(
    parentRecord: ManageTableRow,
    amountDiff: number,
    suppressTimeframeAllocations: boolean,
    parentTimeframeId?: number,
    budgetTimeframes?: BudgetTimeframe[],
    parentAllocationsDiff?: AllocationAmountsMap,
    rollback = false
  ): Observable<SegmentedBudgetObject> {
    const noAllocationsUpdate = !parentRecord.allocations.length;
    const parentSourceObject = this.tableDataService.getSourceObjectForRecord(parentRecord);
    const objectId = parentRecord.objectId || parentSourceObject?.id;
    const recordAllocations = parentRecord.allocations;
    const totalAllocated = this.tableDataService.getRecordTotalAllocated(parentRecord, parentSourceObject);
    const amountUpdate$ =
      this.apiService.updateObject(
        { ...parentRecord, objectId },
        { amount: sumAndRound(totalAllocated, -amountDiff) },
        suppressTimeframeAllocations
      );
    const prepareAllocationUpdateRequest = (timeframeDiff: number, timeframeId?: number) => {
      const recordTargetAlloc = timeframeId
        ? recordAllocations.find(alloc => alloc.company_budget_alloc === timeframeId)
        : this.findFirstOpenAllocation(recordAllocations, budgetTimeframes);

      const allocationAmount = noAllocationsUpdate
        ? totalAllocated
        : getNumericValue(recordTargetAlloc?.amount);

      let sharedAmountDiff = timeframeDiff;
      let fullAllocationValue = null;
      if (parentSourceObject) {
        const rulesSegmentPercentage =
          this.tableDataService.getRulesSegmentPercentage(
            this.tableDataService.getSharedCostRule(parentRecord.sharedCostRuleId),
            parentRecord.segmentId
          );
        const sourceObjectAllocations = parentSourceObject.timeframes;
        const parentSourceAlloc = timeframeId
          ? sourceObjectAllocations.find(alloc => alloc.company_budget_alloc === timeframeId)
          : this.findFirstOpenAllocation(sourceObjectAllocations, budgetTimeframes);
        const sourceAllocAmount = noAllocationsUpdate
          ? totalAllocated
          : getNumericValue(parentSourceAlloc?.amount);

        if (rulesSegmentPercentage != null) {
          sharedAmountDiff = roundDecimal(rulesSegmentPercentage * timeframeDiff, 2);
        }
        fullAllocationValue = sumAndRound(sourceAllocAmount, -timeframeDiff);
      }

      const sharedAllocationValue = sumAndRound(allocationAmount, -sharedAmountDiff);
      const parentAllocUpdate = this.prepareAllocationUpdate({
        record: parentRecord,
        timeframeId: recordTargetAlloc?.company_budget_alloc,
        value: sharedAllocationValue,
        valueToSave: fullAllocationValue,
      });

      return parentAllocUpdate.request$;
    };

    let allocationUpdates: Observable<any>[] = [];
    if (parentAllocationsDiff && !noAllocationsUpdate) {
      allocationUpdates = Object.entries(parentAllocationsDiff).map(
        ([ timeframeId, timeframeDiff ]) => prepareAllocationUpdateRequest(
          rollback ? -timeframeDiff : timeframeDiff,
          Number(timeframeId)
        )
      );
    } else {
      allocationUpdates.push(
        prepareAllocationUpdateRequest(amountDiff, parentTimeframeId)
      );
    }

    const allocationUpdates$ = allocationUpdates.length
      ? forkJoin(allocationUpdates)
      : of(null);

    return allocationUpdates$
      .pipe(
        switchMap(() => amountUpdate$),
        tap((updatedObject: SegmentedBudgetObject) => {
          this.tableDataService.patchSourceObject(parentRecord, updatedObject);
        })
      )
  }

  private getParentRecords(
    parentId: number | string,
    deepCopy = true
  ): {
    parentRecord: ManageTableRow | null;
    grandParentRecord: ManageTableRow | null;
  } {
    const parentRecord = (typeof parentId === 'string'
      ? this.tableDataService.getRecordById(parentId)
      : this.tableDataService.getRecordByIdAndType(ManageTableRowType.Campaign, parentId)
    ) || null;
    const grandParentRecord = parentRecord?.parentId ? this.tableDataService.getRecordById(parentRecord.parentId) : null;

    return {
      parentRecord: deepCopy ? createDeepCopy(parentRecord) : parentRecord,
      grandParentRecord: deepCopy ? createDeepCopy(grandParentRecord) : grandParentRecord
    }
  }

  private addChildrenFromBulkActionTargets(parentRecord: ManageTableRow, targets: BulkActionTargets) {
    const { campaigns, expGroups } = targets;

    campaigns.forEach(campaignId => {
      const record = this.tableDataService.getRecordByIdAndType(ManageTableRowType.Campaign, campaignId);
      parentRecord.children.push(record);
    });
    expGroups.forEach(expGroupId => {
      const record = this.tableDataService.getRecordByIdAndType(ManageTableRowType.ExpenseGroup, expGroupId);
      parentRecord.children.push(record);
    });
  }

  private skipParentsRestrictionUpdate(checkResultData: AllocationCheckResultData): boolean {
    if (checkResultData.result === AllocationCheckResult.NeedOwnAllocationUpdate) {
      if (checkResultData.message) {
        this.budgetObjectAllocationService.openParentAmountShortageDialog(checkResultData.message);
      }
      this.allocationRestrictionsConflict = true;
      return true;
    }

    return false;
  }

  private buildParentsRestrictionUpdateChain(
    checkResultData: AllocationCheckResultData,
    parentRecord: ManageTableRow,
    grandParentRecord: ManageTableRow,
    parentTimeframeId: number,
    suppressTimeframeAllocations: boolean,
    undoCallbacks?: UndoCallback[]
  ) {
    let parentsUpdate$: Observable<SegmentedBudgetObject> = of(null);

    if (
      checkResultData.result === AllocationCheckResult.NeedParentAllocationUpdate ||
      checkResultData.result === AllocationCheckResult.NeedParentAndGrandParentAllocationUpdate
    ) {
      if (undoCallbacks) {
        undoCallbacks.push(
          () => this.updateParentWithAmountDiff(
            parentRecord,
            -checkResultData.allocationDiff,
            suppressTimeframeAllocations,
            parentTimeframeId
          )
        );
      }
      parentsUpdate$ = parentsUpdate$.pipe(
        switchMap(() => this.updateParentWithAmountDiff(
          parentRecord,
          checkResultData.allocationDiff,
          suppressTimeframeAllocations,
          parentTimeframeId
        ))
      );
    }

    if (checkResultData.result === AllocationCheckResult.NeedParentAndGrandParentAllocationUpdate) {
      if (undoCallbacks) {
        undoCallbacks.push(
          () => this.updateParentWithAmountDiff(
            grandParentRecord,
            -checkResultData.grandParentAllocationDiff,
            suppressTimeframeAllocations,
            parentTimeframeId
          )
        );
      }
      parentsUpdate$ = parentsUpdate$.pipe(
        switchMap(() => this.updateParentWithAmountDiff(
          grandParentRecord,
          checkResultData.grandParentAllocationDiff,
          suppressTimeframeAllocations,
          parentTimeframeId
        ))
      );
    }

    return parentsUpdate$;
  }

  private checkObjectSegmentsMatching(
    objectIds: number[],
    targetSegmentData: BudgetObjectSegmentData,
    recordGetter: (objectId: number) => ManageTableRow
  ): boolean {
    return !objectIds.length || objectIds.every(objectId => {
      const record = recordGetter(objectId);

      return record &&
        record.segmentId === targetSegmentData.budgetSegmentId &&
        record.sharedCostRuleId === targetSegmentData.sharedCostRuleId;
    });
  }

  public updateAllocations(
    payload: ManageTableAllocationsUpdatePayload,
    suppressTimeframeAllocations: boolean,
    undoCallbacks?: UndoCallback[]
  ): Observable<boolean> {
    this.allocationRestrictionsConflict = false;
    let requestsChain$: Observable<any> = of(null);
    const result$ = new BehaviorSubject<boolean>(null);
    const rollbacks: Function[] = [];

    const globalRollback = () => {
      setTimeout(() => {
        rollbacks.forEach(rollback => rollback?.());
      });
    };

    const getProcessedPayloadForRecord$ = (payloadRecord: {
      record: ManageTableRow,
      updates: { timeframeId: number; amount: number; }[];
    }) => {
      if (!payloadRecord.updates.length || this.allocationRestrictionsConflict) {
        return of(null);
      }
      const addRequestChain = newChain$ =>
        requestsChain$ = requestsChain$.pipe(
          switchMap(() => newChain$)
        );
      const payloadProcess =
        this.getUpdateAllocationsPayloadProcess(payloadRecord, rollbacks, suppressTimeframeAllocations, undoCallbacks, addRequestChain);
      return this.allocationRestrictionsConflict ? of(null) : payloadProcess;
    };

    const processedPayloads$: Observable<boolean | null>[] =
      Object.values(payload).map(payloadRecord => getProcessedPayloadForRecord$(payloadRecord));

    if (!processedPayloads$.length) {
      result$.next(false);
      return result$.asObservable();
    }

    this.executeUpdateAllocationPayloadProcessing(processedPayloads$, () => requestsChain$, globalRollback, result$);
    return result$.asObservable();
  }

  private executeUpdateAllocationPayloadProcessing(
    processedPayloads$: Observable<boolean | null>[],
    requestsChainProvider$: () => Observable<any>,
    globalRollback: () => void,
    result$: BehaviorSubject<boolean>
  ): void {
    forkJoin(processedPayloads$)
      .pipe(
        tap(() => this.tableDataService.setLoading(true)),
        switchMap(() => {
          if (this.allocationRestrictionsConflict) {
            globalRollback();
            result$.next(false);
            return of(null);
          }

          return requestsChainProvider$().pipe(
            tap(() => result$.next(true))
          );
        }),
        catchError(err => {
          this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_UPDATE_OBJECT_AMOUNT);
          return of(null);
        })
      )
      .subscribe({
        error: err => {
          this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_UPDATE_ALLOCATIONS);
          this.tableDataService.setLoading(false);
          result$.next(false);
        },
        complete: () => this.tableDataService.setLoading(false)
      });
  }

  private getUpdateAllocationsPayloadProcess(
    payloadRecord: { record: ManageTableRow; updates: { timeframeId: number; amount: number }[] },
    rollbacks: Function[],
    suppressTimeframeAllocations: boolean,
    undoCallbacks: UndoCallback[],
    addRequestChain: (requestChain$: Observable<any>) => void
  ): Observable<any> {
    const { updates } = payloadRecord;

    const record = this.tableDataService.getRecordById(payloadRecord.record.id);
    const { parentRecord, grandParentRecord } = this.getParentRecords(record.parentId, false);

    // Yearly or suppressed budget
    const noAllocationsUpdate = !record.allocations.length;
    const totalAmount = updates[0] && updates[0].amount;
    const prevTotalAmount = this.tableDataService.getRecordTotalAllocated(record);
    const allocationUpdates$ = [];

    updates.forEach(({timeframeId, amount}) => {
      const result = this.prepareAllocationUpdate({
        record,
        timeframeId,
        value: amount
      });
      if (result) {
        allocationUpdates$.push(result.request$);
        rollbacks.push(result.rollback);
      }
    });

    const addAllocationsUpdateToRequestChain = (checkResultData: AllocationCheckResultData) => {
      const parentTimeframeId = updates[0].timeframeId;

      const requestsChain$ =
        forkJoin(allocationUpdates$).pipe(
          switchMap(() => {
            const newTotalAmount =
              noAllocationsUpdate ?
                totalAmount :
                this.tableDataService.getRecordAllocationsTotal(record);

            return prevTotalAmount !== newTotalAmount ?
              this.apiService.updateObject(record, { amount: newTotalAmount }, suppressTimeframeAllocations).pipe(
                tap(updatedObject => this.tableDataService.patchSourceObject(record, updatedObject))
              ) :
              of(null);
          }),
          switchMap(() => this.buildParentsRestrictionUpdateChain(
            checkResultData,
            parentRecord,
            grandParentRecord,
            parentTimeframeId,
            suppressTimeframeAllocations,
            undoCallbacks
          ))
        );

      addRequestChain(requestsChain$);
    }

    return this.dataValidationService.validateRecordAllocationLimits(record, parentRecord, grandParentRecord).pipe(
      tap((checkResultData: AllocationCheckResultData) => {
        if (!this.skipParentsRestrictionUpdate(checkResultData)) {
          addAllocationsUpdateToRequestChain(checkResultData);
        }
      })
    );
  }

  public deleteObjects(targets: BulkActionTargets, onSuccess?: Function) {
    const deletedCampaigns: number[] = [];
    const companyId = this.companyDataService.selectedCompanySnapshot.id;

    this.apiService.deleteObjects(targets)
      .pipe(
        tap(result => {
          const { campaigns, expGroups } = result;
          const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(result));
          const message = ManageTableHelpers.getBulkOperationMessage(resultsCount, 'deleted.', 'failed to delete.');

          if (campaigns?.success?.length) {
            deletedCampaigns.push(...campaigns.success);
          }
          if (expGroups?.success?.length) {
            this.expenseCostAdjustmentDataService.disableExpenseCostAdjustment(expGroups.success, companyId).subscribe();
          }
          this.showMessage(message);
        }),
        switchMap(() => {
          if (!deletedCampaigns.length) {
            return of(null);
          }

          const enabledMetricIntegrationsNames = this.companyDataService.enabledMetricIntegrationsNames;
          return !enabledMetricIntegrationsNames.length ? of(null) : forkJoin(
            enabledMetricIntegrationsNames.map(name => {
              return this.integrationsProviderService.metricIntegrationProviderByType(name)
                ?.deleteCampaignsMappings(companyId, deletedCampaigns)
                .pipe(
                  catchError(() => of(null))
                ) || of(null);
            })
          );
        })
      )
      .subscribe(
        res => onSuccess?.(res)
      );
  }

  public updateObjectsMode(targets: BulkActionTargets, mode: ObjectMode, onSuccess: Function) {
    const getPayload = (id: number) => ({ id, mode });
    const childObjectsData = {
      childExpenseGroups: targets.childExpGroups?.map(getPayload) ?? [],
      childCampaigns: targets.childCampaigns?.map(getPayload) ?? []
    }
    const payloads: BulkActionPayloads<any> = {
      campaigns: targets.campaigns.map(getPayload),
      expGroups: targets.expGroups.map(getPayload),
    };

    this.apiService.updateObjects(payloads)
      .pipe(
        tap(result => {
          const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(result));
          const message = mode === ObjectMode.Closed
            ? ManageTableHelpers.getBulkOperationMessage(resultsCount, 'closed.', 'failed to close.')
            : ManageTableHelpers.getBulkOperationMessage(resultsCount, 'reopened.', 'failed to reopen.');

          this.showMessage(message);
        }),
      )
      .subscribe(
        res => {
          const { campaigns, expGroups } = res;

          this.updateClosedFlag<Partial<CampaignDO>>(campaigns.success, ManageTableRowType.Campaign, mode === ObjectMode.Closed);
          this.updateClosedFlag<Partial<ProgramDO>>(expGroups.success, ManageTableRowType.ExpenseGroup, mode === ObjectMode.Closed);
          this.updateClosedFlag<Partial<ProgramDO>>(childObjectsData.childCampaigns, ManageTableRowType.Campaign, mode === ObjectMode.Closed);
          this.updateClosedFlag<Partial<CampaignDO>>(childObjectsData.childExpenseGroups, ManageTableRowType.ExpenseGroup, mode === ObjectMode.Closed);

          onSuccess?.(res);
        }
      );
  }

  public updateObjectsType(
    targets: BulkActionTargets,
    objectTypeId: number,
    onSuccess: Function
  ) {
    const payloads: BulkActionPayloads<any> = {
      campaigns: targets.campaigns.map(id => (
        { id, [getObjectTypeKey(this.budgetDataService.selectedBudgetSnapshot, 'campaign_type')]: objectTypeId }
      )),
      expGroups: targets.expGroups.map(id => (
        { id, [getObjectTypeKey(this.budgetDataService.selectedBudgetSnapshot, 'program_type')]: objectTypeId }
      )),
    };

    this.apiService.updateObjects(payloads)
      .pipe(
        tap(result => {
          const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(result));
          const message = ManageTableHelpers.getBulkOperationMessage(resultsCount, 'updated.', 'failed to update.');

          this.showMessage(message);
        }),
      )
      .subscribe(
        res => onSuccess?.(res)
      );
  }

  public duplicateObject(
    target: ManageTableRow,
    budget: Budget,
    timeframes: BudgetTimeframe[],
    objectExists: (objType: ManageTableRowType, objId: string | number) => boolean,
    onSuccess: Function,
    expensesData?: PlanObjectExpensesData,
  ) {
    const message = `${ManageTableRowTypeLabel[target.type]} duplicated`;
    const viewMode = this.modeService.viewMode;
    let duplicateObjectChain$: Observable<AllocationCheckResultData> = of(null);
    let parentRecord: ManageTableRow = null;
    let grandParentRecord: ManageTableRow = null;

    if (AllocatableRowTypes.includes(target.type) && target.parentId) {
      const targetCopy = createDeepCopy(target);
      const targetParents = this.getParentRecords(target.parentId, false);

      parentRecord = targetParents.parentRecord;
      grandParentRecord = targetParents.grandParentRecord;
      if (parentRecord) {
        const parentRecordCopy: ManageTableRow = createDeepCopy(parentRecord);

        parentRecordCopy.children.push(targetCopy);
        duplicateObjectChain$ = this.dataValidationService.validateParentAllocationLimits(
          parentRecordCopy,
          grandParentRecord,
          ParentDialogActionSource.Clone
        );
      }
    }

    try {
      duplicateObjectChain$
        .pipe(
          switchMap((allocationCheckResultData: AllocationCheckResultData) => {
            if (allocationCheckResultData?.result === AllocationCheckResult.NeedOwnAllocationUpdate) {
              return of(null);
            }

            const parentAllocationDiff = allocationCheckResultData?.allocationDiff || 0;
            const grandParentAllocationDiff = allocationCheckResultData?.grandParentAllocationDiff || 0;
            const parentAllocationsDiff = allocationCheckResultData?.parentAllocationsDiff;
            const grandParentAllocationsDiff = allocationCheckResultData?.grandParentAllocationsDiff;

            this.tableDataService.setLoading(true);

            return this.apiService.duplicateAndFetchObject(target)
              .pipe(
                tap((res: Goal | Campaign | Program) => {
                  const sharedCostRulePercent = this.getSharedCostPercent(res);
                  const record =
                    ManageTableFastDataBuilder.createRow(
                      res,
                      target.type,
                      timeframes,
                      budget,
                      viewMode,
                      objectExists,
                      expensesData,
                      sharedCostRulePercent
                    );

                  if (!record) {
                    return;
                  }

                  this.showMessage(message);
                  this.insertDataRecord(record, timeframes);
                  this.tableDataService.initGrandTotal(timeframes);
                }),
                switchMap(clonedObject => {
                  if (!parentRecord || !clonedObject || !parentAllocationDiff) {
                    return of(clonedObject);
                  }

                  return this.updateParentWithAmountDiff(
                    parentRecord,
                    parentAllocationDiff,
                    Boolean(budget.suppress_timeframe_allocations),
                    null,
                    timeframes,
                    parentAllocationsDiff
                  ).pipe(
                    map(() => clonedObject)
                  )
                }),
                switchMap(clonedObject => {
                  if (!grandParentRecord || !clonedObject || !grandParentAllocationDiff) {
                    return of(clonedObject);
                  }

                  return this.updateParentWithAmountDiff(
                    grandParentRecord,
                    grandParentAllocationDiff,
                    Boolean(budget.suppress_timeframe_allocations),
                    null,
                    timeframes,
                    grandParentAllocationsDiff
                  ).pipe(
                    map(() => clonedObject)
                  )
                })
              );
          }),
        )
        .subscribe(
          res => onSuccess?.(res),
          err => this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_DUPLICATE_OBJECT)
        );
    } catch (err) {
      this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_DUPLICATE_OBJECT);
    }
  }

  private getSharedCostPercent(
    obj: Goal & { isPseudoObject?: boolean; splitRuleId?: number; budgetSegmentId?: number } | Campaign | Program
  ): number {
    const sharedCostRules = this.budgetDataService.sharedCostRulesSnapshot;

    return obj.isPseudoObject ?
      this.tableDataService.getRulesSegmentPercentage(
        sharedCostRules?.find(scr => scr.id === obj.splitRuleId),
        obj.budgetSegmentId
      ) :
      null;
  }

  public createNewItemTemplate(
    createItemTemplateEvent: CreateItemTemplateEvent
  ): void {
    const isSegmentView = this.modeService.viewMode === ManageTableViewMode.Segments;
    const recordContext = createItemTemplateEvent.contextRow;
    const recordIndex = createItemTemplateEvent.position;
    const parent = recordContext.parentId ? this.tableDataService.flatDataMap[recordContext.parentId] : null;

    const emptyAllocationValue = {
      allocated: 0,
      spent: 0,
      remainingAllocated: 0,
      remainingAllocatedAbs: 0,
    };

    const inheritSegmentFromObject =
      parent && !isSegmentView && (parent.sharedCostRuleId || parent.segmentId) ?
        parent :
        recordContext;

    const rowTemplate: ManageTableRow = {
      id: null,
      objectId: null,
      itemId: null,
      type: recordContext.type,
      children: [],
      name: null,
      values: Object.keys(recordContext.values).reduce((values, allocationId) => {
        values[allocationId] = { ...emptyAllocationValue };
        return values;
      }, {}),
      total: { ...emptyAllocationValue },
      isEditable: false,
      isSelectable: false,
      segmentId: inheritSegmentFromObject.segmentId,
      sharedCostRuleId: isSegmentView ? null : inheritSegmentFromObject.sharedCostRuleId,
      isChildDataReady: true,
      isOwnDataReady: true
    };

    const allowedParentType = [ManageTableRowType.Campaign, ManageTableRowType.Goal];
    this.newItemTemplate = {
      rowTemplate,
      parent: parent && allowedParentType.includes(parent.type) ? parent : null,
      recordsArray: this.getTargetContainer(isSegmentView, parent, recordContext),
      index: recordIndex,
    }
    this.newItemTemplate.recordsArray.splice(recordIndex, 0, rowTemplate);
  }

  private getTargetContainer(isSegmentView: boolean, parent: ManageTableRow, recordContext: ManageTableRow): ManageTableRow[] {
    let recordsArray: ManageTableRow[] = parent ? parent.children : this.tableDataService.data;
    if (isSegmentView && parent.type === ManageTableRowType.Campaign && parent.segmentId !== recordContext.segmentId) {
      // has parent in different segment
      const parentSegment = this.tableDataService.flatDataMap[ManageTableRowType.Segment.toLowerCase() + '_' + recordContext.segmentId];
      if (parentSegment) {
        recordsArray = parentSegment.children;
      } else {
        console.error('Wrong segment ID - ', recordContext.segmentId);
      }
    }
    return recordsArray;
  }

  public saveNewItemTemplate(
    name: string,
    userId: number,
    companyId: number,
    budget: Budget,
    timeframes: BudgetTimeframe[],
    objectExists: (objType: ManageTableRowType, objId: string | number) => boolean,
  ) {
    const resetNewItemTemplate = () => {
      this.newItemTemplate.recordsArray.splice(this.newItemTemplate.index, 1);
      this.newItemTemplate = null;
    };
    const getIdFromItemId = (subId: string): number => {
      const chunks = subId.split('_');
      return +chunks[1];
    };
    const createAllocations = (): ProgramAllocation[] => {
      return timeframes.map((tf: BudgetTimeframe) => ({
        source_amount: 0,
        company: companyId,
        company_budget_alloc: tf.id,
      }));
    };
    if (!name) {
      resetNewItemTemplate();
      return;
    }
    const rowTemplate = this.newItemTemplate.rowTemplate;
    rowTemplate.name = name;
    const parent = this.newItemTemplate.parent;
    const parentIsGoal = parent?.type === ManageTableRowType.Goal;
    const parentGoalId = parent && parentIsGoal ? parent.objectId : null;
    const parentCampaignId = parent && !parentIsGoal ? (parent.objectId || getIdFromItemId(parent.itemId.toString())) : null;
    const objectTypeId = this.getDefaultObjectType(rowTemplate.type) || rowTemplate.objectTypeId;
    const allocations = createAllocations();

    const payload: Record<string, string | number | ProgramAllocation[]> = {
      name: rowTemplate.name,
      budget: budget.id,
      company: companyId,
      split_rule: rowTemplate.sharedCostRuleId,
      company_budget_segment1: rowTemplate.segmentId,
      owner: userId,
      created_by: userId,
      goal: parentGoalId,
    };

    if (rowTemplate.type === ManageTableRowType.Campaign) {
      payload[getObjectTypeKey(this.budgetDataService.selectedBudgetSnapshot, 'campaign_type')] = objectTypeId;
      payload.parent_campaign = parentCampaignId;
      payload.start_date = null;
      payload.end_date = null;
      payload.campaign_allocations = allocations;
    }
    if (rowTemplate.type === ManageTableRowType.ExpenseGroup) {
      payload[getObjectTypeKey(this.budgetDataService.selectedBudgetSnapshot, 'program_type')] = objectTypeId;
      payload.campaign = parentCampaignId;
      payload.program_allocations = allocations;
    }

    let completeMsg: string;
    this.tableDataService.setLoading(true);
    this.apiService.createObjectFromTemplate(rowTemplate.type, payload).pipe(
      catchError(err => {
        completeMsg = 'Some error has occurred';
        resetNewItemTemplate();
        return throwError(err);
      }),
      finalize(() => {
        this.tableDataService.setLoading(false);
        this.showMessage(completeMsg);
      }),
      takeUntil(this.destroy$)
    ).subscribe(newlyCreatedItem => {
      completeMsg = `${ManageTableRowTypeLabel[rowTemplate.type]} created`;
      const viewMode = this.modeService.viewMode;
      const sharedCostRulePercent = this.getSharedCostPercent(newlyCreatedItem);
      const record = {
        ...ManageTableFastDataBuilder.createRow(
          newlyCreatedItem,
          rowTemplate.type,
          timeframes,
          budget,
          viewMode,
          objectExists,
          {},
          sharedCostRulePercent
        ),
        isOwnDataReady: true,
        isChildDataReady: true
      };
      this.newItemTemplate.recordsArray[this.newItemTemplate.index] = record;
      this.tableDataService.flatDataMap[record.id] = record;
      this.newItemTemplate = null;
      this.addNewObjectToSnapshot(rowTemplate.type, newlyCreatedItem);
    })
  }

  private getDefaultObjectType(targetType: ManageTableRowType): number {
    const snapshot = {
      [ManageTableRowType.Campaign]: this.companyDataService.campaignTypesSnapshot,
      [ManageTableRowType.ExpenseGroup]: this.companyDataService.programTypesSnapshot,
    };
    const defaultItem = snapshot[targetType].find(objectType => objectType.name === CampaignTypeNames.OTHER);
    return defaultItem?.id || null;
  }

  private addNewObjectToSnapshot(targetType: ManageTableRowType, createdObject: Campaign | Program): void {
    switch (targetType) {
      case ManageTableRowType.Campaign:
        this.budgetDataService.addCampaignToSnapshot(createdObject as Campaign);
        break;
      case ManageTableRowType.ExpenseGroup:
        this.budgetDataService.addProgramToSnapshot(createdObject as Program);
        break;
    }
  }

  public updateSegmentData(
    targets: BulkActionTargets,
    segmentData: BudgetObjectSegmentData,
    onSuccess?: (res: UpdateObjectsResult, childrenSegmentInheritance: boolean) => void
  ): void {
    const targetsCount = targets.campaigns.length + targets.expGroups.length;
    let processNested = false;
    let childrenSegmentInheritance = false;

    this.segmentDataInheritanceService
      .confirmSegmentChange(null, true, false, true, targetsCount > 1)
      .pipe(
        switchMap(action => {
          if (action === SegmentDataInheritanceAction.None) {
            return of(null);
          }
          childrenSegmentInheritance = true;
          processNested = action === SegmentDataInheritanceAction.Replace;
          this.tableDataService.setLoading(true);

          return this.apiService.updateObjects(this.getBulkActionPayloads({
            targets,
            segmentData,
            processNested,
          }));
        }),
        tap(results => {
          if (results) {
            const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(results));
            const message = ManageTableHelpers.getBulkOperationMessage(resultsCount, 'moved successfully.', 'failed to move.');

            this.showMessage(message);
          }
        })
      )
      .subscribe(
        res => onSuccess?.(res, childrenSegmentInheritance)
      )
  }

  public undoSegmentDataUpdate(
    initialSegmentData: ManageTableBulkSegmentData,
    onSuccess: (res: UpdateObjectsResult) => void
  ) {
    const getPayload = (item: ManageTableBulkDataItem<BudgetObjectSegmentData>) => ({
      id: item.id,
      split_rule: item.data.sharedCostRuleId,
      company_budget_segment1: item.data.budgetSegmentId
    });
    const bulkPayload = {
      campaigns: initialSegmentData.campaigns.map(item => getPayload(item)),
      expGroups: initialSegmentData.expGroups.map(item => getPayload(item)),
    };

    this.tableDataService.setLoading(true);
    this.apiService.updateObjects(bulkPayload)
      .pipe(
        tap(results => {
          if (results) {
            const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(results));
            const message = ManageTableHelpers.getBulkOperationMessage(resultsCount, 'moved successfully.', 'failed to move.');

            this.showMessage(message);
          }
        })
      )
      .subscribe(
        res => onSuccess?.(res)
      )
  }

  public updateParentData(
    targets: BulkActionTargets,
    parentData: ManageTableParentContext,
    budgetTimeframes: BudgetTimeframe[],
    suppressTfAllocations: boolean,
    onSuccess: (res: UpdateObjectsResult, childrenSegmentInheritance: boolean) => void,
    movedItemsCount?: number,
    undoCallbacks?: UndoCallback[]
  ) {
    const { objectType: parentType, segmentData: parentSegmentData } = parentData;
    const { campaigns, expGroups } = targets;
    const targetsCount = campaigns.length + expGroups.length;
    const allExpGroupsSegmentsMatch = this.checkObjectSegmentsMatching(
      expGroups,
      parentSegmentData,
      id => this.tableDataService.getRecordByIdAndType(ManageTableRowType.ExpenseGroup, id)
    );
    const allCampaignsSegmentsMatch = this.checkObjectSegmentsMatching(
      campaigns,
      parentSegmentData,
      id => this.tableDataService.getRecordByIdAndType(ManageTableRowType.Campaign, id)
    );
    const isSegmentlessParent = !parentSegmentData.budgetSegmentId && !parentSegmentData.sharedCostRuleId;
    let updateParentChain$: Observable<AllocationCheckResultData> = of(null);
    let changeLocationChoice$: Observable<SegmentDataInheritanceAction> = of(null);
    let replaceSegmentWithParents = false;
    let processNested = false;
    let parentRecord: ManageTableRow = null;
    let grandParentRecord: ManageTableRow = null;
    let childrenSegmentInheritance = false;

    if (!parentData.objectId) {
      return;
    }

    // Moving to campaign, should check parent limits
    if (parentData.objectType === this.OBJECT_TYPES.campaign) {
      const campaignParents = this.getParentRecords(parentData.objectId);

      parentRecord = campaignParents.parentRecord;
      grandParentRecord = campaignParents.grandParentRecord;
      if (parentRecord) {
        this.addChildrenFromBulkActionTargets(parentRecord, targets);
      }
      updateParentChain$ = this.dataValidationService.validateParentAllocationLimits(
        parentRecord,
        grandParentRecord,
        ParentDialogActionSource.Move
      );
    }

    const updateParentsAndNotify = () => {
      return this.apiService.updateObjects(this.getBulkActionPayloads({
        targets,
        processNested,
        replaceSegmentWithParents,
        parentData
      })).pipe(
        tap(results => {
          if (results) {
            const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(results));
            const message = ManageTableHelpers.getBulkOperationMessage(
              resultsCount,
              'moved successfully.',
              'failed to move.',
              movedItemsCount
            );

            this.showMessage(message);
          }
        })
      );
    };

    // We need no parent allocation restrictions or segment inheritance checks when moving to a goal
    if (parentData.objectType === this.OBJECT_TYPES.goal) {
      updateParentsAndNotify().subscribe(
        res => onSuccess?.(res, childrenSegmentInheritance)
      );
      return;
    }

    if (!(allExpGroupsSegmentsMatch && allCampaignsSegmentsMatch) && parentType !== this.OBJECT_TYPES.goal && !isSegmentlessParent) {
      changeLocationChoice$ = this.locationService.requestSegmentConfirmation({
        title: 'Change Location',
        keepAllowed: true,
        multiUpdate: true,
        parentName: parentType.toLowerCase(),
        objectName: 'selected object'
      }).pipe(
        switchMap(changeLocationAction => {
          if (changeLocationAction === SegmentDataInheritanceAction.Replace) {
            replaceSegmentWithParents = true;
            return this.segmentDataInheritanceService.confirmSegmentChange(
              null,
              true,
              false,
              true,
              targetsCount > 1
            ).pipe(
              tap(changeSegmentAction => {
                if (changeSegmentAction !== SegmentDataInheritanceAction.None) {
                  childrenSegmentInheritance = true;
                }
              })
            );
          }

          return of(changeLocationAction);
        })
      )
    }

    updateParentChain$
      .pipe(
        switchMap((allocationCheckResultData: AllocationCheckResultData) => {
          if (!allocationCheckResultData || allocationCheckResultData?.result === AllocationCheckResult.NeedOwnAllocationUpdate) {
            return of(null);
          }

          const {
            allocationDiff: parentAllocationDiff,
            grandParentAllocationDiff,
            parentAllocationsDiff,
            grandParentAllocationsDiff
          } = allocationCheckResultData;

          return changeLocationChoice$
            .pipe(
              tap(changeSegmentAction => {
                processNested = changeSegmentAction === SegmentDataInheritanceAction.Replace;
              }),
              switchMap(action => {
                if (action === SegmentDataInheritanceAction.None) {
                  return of(null);
                }
                this.tableDataService.setLoading(true);

                return updateParentsAndNotify();
              }),
              switchMap(results => {
                if (!parentRecord || !results || !parentAllocationDiff) {
                  return of(results);
                }
                if (undoCallbacks) {
                  undoCallbacks.push(
                    () => this.updateParentWithAmountDiff(
                      parentRecord,
                      -parentAllocationDiff,
                      suppressTfAllocations,
                      null,
                      budgetTimeframes,
                      parentAllocationsDiff,
                      true
                    )
                  );
                }

                return this.updateParentWithAmountDiff(
                  parentRecord,
                  parentAllocationDiff,
                  suppressTfAllocations,
                  null,
                  budgetTimeframes,
                  parentAllocationsDiff
                ).pipe(map(() => results));
              }),
              switchMap((results) => {
                if (!grandParentRecord || !results || !grandParentAllocationDiff) {
                  return of(results);
                }
                if (undoCallbacks) {
                  undoCallbacks.push(
                    () => this.updateParentWithAmountDiff(
                      grandParentRecord,
                      -grandParentAllocationDiff,
                      suppressTfAllocations,
                      null,
                      budgetTimeframes,
                      grandParentAllocationsDiff,
                      true
                    )
                  );
                }

                return this.updateParentWithAmountDiff(
                  grandParentRecord,
                  grandParentAllocationDiff,
                  suppressTfAllocations,
                  null,
                  budgetTimeframes,
                  grandParentAllocationsDiff
                ).pipe(map(() => results));
              })
            );
        }),
      )
      .subscribe(
        res => onSuccess?.(res, childrenSegmentInheritance)
      );
  }

  public undoParentDataUpdate(
    parentData: ManageTableBulkParentData,
    onSuccess: Function,
    movedItemsCount?: number
  ) {
    const getPayload = (
      item: ManageTableBulkDataItem<ManageTableParentContext>,
      objectType: string
    ) => {
      const { objectType: parentType, objectId: parentId } = item.data;
      const payload: any = {
        id: item.id,
        goal: parentType === this.OBJECT_TYPES.goal ? parentId : null
      };

      if (objectType === this.OBJECT_TYPES.campaign) {
        payload.parent_campaign = parentType === this.OBJECT_TYPES.campaign ? parentId : null;
      }
      if (objectType === this.OBJECT_TYPES.program) {
        payload.campaign = parentType === this.OBJECT_TYPES.campaign ? parentId : null;
      }

      return payload;
    };
    const bulkPayload = {
      campaigns: parentData.campaigns.map(item => getPayload(item, this.OBJECT_TYPES.campaign)),
      expGroups: parentData.expGroups.map(item => getPayload(item, this.OBJECT_TYPES.program)),
    };

    this.tableDataService.setLoading(true);
    this.apiService.updateObjects(bulkPayload)
      .pipe(
        tap(results => {
          if (results) {
            const resultsCount = this.apiService.sumUpBulkOperationResults(Object.values(results));
            const message = ManageTableHelpers.getBulkOperationMessage(
              resultsCount,
              'moved successfully.',
              'failed to move.',
              movedItemsCount
            );

            this.showMessage(message);
          }
        })
      )
      .subscribe(
        res => onSuccess?.(res)
      );
  }

  public updateSegmentAllocation(
    payload: ManageTableAllocationsUpdatePayload,
    suppressTimeframeAllocations: boolean,
    budgetSegments: BudgetSegmentDO[] = [],
    timeframes: BudgetTimeframe[] = []
  ): Observable<boolean> {
    const rollbacks: Function[] = [];
    const globalRollback = () => {
      setTimeout(() => {
        rollbacks.forEach(rollback => rollback?.());
      })
    };
    const result$ = new BehaviorSubject<boolean>(null);

    if (suppressTimeframeAllocations) {
      result$.next(false);
      return result$;
    }

    const processedPayloads$ = Object.values(payload)
      .map(payloadRecord => {
        const { updates } = payloadRecord;
        const record = this.tableDataService.getRecordById(payloadRecord.record.id);
        if (!updates.length || record?.type !== ManageTableRowType.Segment) {
          return of(null);
        }
        const updates$: Observable<UpdatedSegmentAmountsData>[] = [];

        updates.forEach(({ timeframeId, amount }) => {
          const prevAmount = record.segment?.values[timeframeId]?.allocated;
          const amountDiff = sumAndRound(amount, -prevAmount);
          const updateContext: UpdateSegmentAllocationContext = {
            record,
            amount,
            amountDiff,
            timeframeId
          };
          const rollbackContext: UpdateSegmentAllocationContext = {
            record,
            amount: prevAmount,
            amountDiff: -amountDiff,
            timeframeId
          };

          this.updateSegmentAllocationState(updateContext);
          rollbacks.push(() => this.updateSegmentAllocationState(rollbackContext));
          updates$.push(
            this.apiService.updateSegmentAllocation({
              budgetSegments,
              timeframes,
              timeframeId,
              segmentAmount: amount,
              grandTotal: this.grandTotal,
              objectId: record.objectId
            })
          );
        });

        return forkJoin(updates$);
      });

    if (!processedPayloads$.length) {
      result$.next(false);
      return result$.asObservable();
    }

    this.tableDataService.setLoading(true);
    forkJoin(processedPayloads$)
      .pipe(
        tap(() => result$.next(true)),
        finalize(() => this.tableDataService.setLoading(false))
      )
      .subscribe({
        error: err => {
          globalRollback();
          this.handleError(err, this.ERROR_MESSAGE.FAILED_TO_UPDATE_SEGMENT_ALLOCATION);
          result$.next(false);
          this.tableDataService.setLoading(false);
        }
      });

    return result$.asObservable();
  }
}
