import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

@Injectable({
  providedIn: 'root'
})
export class CompanyExchangeRateService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    companyExchangeRate: 'company_exchange_rate/',
    exchangeRatesByAllocation: 'exchange_rate_by_allocation/'
  };

  getRates(companyId: number, currencyCode?: string): Observable<any[]> {
    const paramsObj = { company: companyId };
    if (currencyCode) {
      paramsObj['currency'] = currencyCode;
    }

    return this.http.get<any[]>(
      `${this.apiV2Url}${this.apiPaths.companyExchangeRate}`,
      getRequestOptions(paramsObj));

  }

  addRate(company: number, currency: string, rates: any) {
    const dataForRequest = {
      currency,
      company,
      rates
    };

    return this.http.post(
      `${this.apiV2Url}${this.apiPaths.companyExchangeRate}`,
      JSON.stringify(dataForRequest));
  }

  updateRate(id: number, currency: string, company: number, rates: any) {
    const dataForRequest = {
      currency,
      company,
      rates
    };

    return this.http.patch(
      `${this.apiV2Url}${this.apiPaths.companyExchangeRate}${id}/`,
      JSON.stringify(dataForRequest));
  }

  removeRates(rateId: number) {
    return this.http.delete(`${this.apiV2Url}${this.apiPaths.companyExchangeRate}${rateId}/`);
  }

  getCompanyBudgetTimeframeCurrencyRates(
    companyId: number,
    currencyCode: string,
    budgetAllocIds: number[]
  ): Observable<Record<number, number>> {
    return this.http.get(
      this.apiV2Url + this.apiPaths.exchangeRatesByAllocation,
      getRequestOptions( {
        company: companyId,
        currency: currencyCode,
        company_budget_allocation_ids: budgetAllocIds.join(',')
      })
    ).pipe(
      map((rawRates: any[]) => rawRates.reduce((store, rate) => {
        store[rate.company_budget_allocation_id] = rate.exchange_rate
        return store;
      }, {}))
    );
  }
}
