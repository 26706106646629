import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'funnel-page-controls',
  templateUrl: './funnel-page-controls.component.html',
  styleUrls: ['./funnel-page-controls.component.scss']
})
export class FunnelPageControlsComponent {
  @Input() disabled: boolean;
  @Output() addFunnel = new EventEmitter<boolean>();

  addNewFunnel(addExampleValues = false): void {
    this.addFunnel.emit(addExampleValues);
  }

}
