export enum UserPermission {
  Admin = 'admin',
  ReadOnly = 'readOnly',
  ReadWrite = 'readWrite'
}

export interface BudgetPermission {
  permission: UserPermission;
  budget: { id: number; name: string; };
  segments: { id: number; name: string; }[];
}

export interface BudgetPermissionDO {
  budget_id: number;
  budget_name: string;
  read: boolean;
  read_write: boolean;
  segments: {
    id: number;
    name: string;
    user_access_permissions_id: number;
  }[]
}
