import { Component, Input } from '@angular/core';
import { Configuration } from 'app/app.constants';

@Component({
  selector: 'business-value',
  templateUrl: './business-value.component.html',
  styleUrls: ['./business-value.component.scss']
})
export class BusinessValueComponent {
  @Input() currencySymbol: string;
  @Input() actualBusinessValue: number;
  @Input() targetBusinessValue: number;
  @Input() showTarget: boolean;
  @Input() set objectType(value: string) {
    this.bvObjectType = value;
    this.tooltipText = this.businessValueTooltipTextByObjectType[value];
  }

  bvObjectType: string;
  tooltipText: string;
  decimalDisplayFormat = '1.0-0';

  private businessValueTooltipTextByObjectType = {
    [this.config.OBJECT_TYPES.goal]:
      `Estimated Campaign Value\n
      Campaign value quantifies the impact of marketing in financial terms.
      For each campaign this is calculated by multiplying the current value of a key metric by its average revenue (or profit) per outcome. For a goal this is the sum of the campaign value of all related campaigns.`,

    [this.config.OBJECT_TYPES.campaign]:
      `Estimated Campaign Value\n
      Campaign value quantifies the impact of marketing in financial terms.
      For a campaign this is calculated by multiplying the current value of a key metric by its average revenue (or profit) per outcome.`
  };

  constructor(private readonly config: Configuration) {}
}
