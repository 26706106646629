import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'app/app.constants';
import { MetricsProviderWithImplicitMappingsDataService } from '../services/metrics-provider-with-implicit-mappings-data.service';

@Injectable({
  providedIn: 'root'
})
export class FacebookDataService extends MetricsProviderWithImplicitMappingsDataService {
  constructor(
    private readonly configuration: Configuration,
    public readonly client: HttpClient
  ) {
    super(client, configuration.facebook_ads_service_url);
  }
}
