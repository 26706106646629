import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FlatSelectOption } from 'app/shared/components/flat-select/flat-select.component';
import { TaskStatusName } from 'app/shared/types/task.interface';

@Component({
  selector: 'task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss']
})
export class TaskStatusComponent implements OnChanges {
  @Input() value;
  @Input() options: any[];
  @Input() isReadOnly = false;
  @Output() onStatusChanged = new EventEmitter();

  public processedOptions: FlatSelectOption[] = [];
  public colorMap = {
    [TaskStatusName.IN_PROGRESS]: '255,153,1',
    [TaskStatusName.COMPLETED]: '76,176,80',
    [TaskStatusName.BLOCKED]: '255,0,0',
    [TaskStatusName.LATE]: '255,0,0',
    [TaskStatusName.POSTPONED]: '112,112,112',
    [TaskStatusName.CANCELED]: '112,112,112',
  };
  public labelMap = {
    [TaskStatusName.IN_PROGRESS]: 'In Progress',
    [TaskStatusName.COMPLETED]: 'Completed',
    [TaskStatusName.BLOCKED]: 'Blocked',
    [TaskStatusName.LATE]: 'Late',
    [TaskStatusName.POSTPONED]: 'Postponed',
    [TaskStatusName.CANCELED]: 'Canceled',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && this.options) {
      this.processedOptions = this.processOptions(this.options);
    }
  }

  public processOptions(options: FlatSelectOption[]) {
    return options.map(option => ({
      ...option,
      label: this.labelMap[option.name],
      indicator: this.colorMap[option.name]
    }));
  }

  public handleValueChanged($event) {
    this.onStatusChanged.emit($event);
    this.value = $event;
  }
}
