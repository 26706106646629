import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router} from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';
import { UtilityService } from 'app/shared/services/utility.service';
import { PlanSettingService } from './plansetting.service';
import { Configuration } from 'app/app.constants';
import { Validations } from 'app/app.validations';
import { UserManager } from 'app/user/services/user-manager.service';
import { UserService } from 'app/shared/services/backend/user.service';
import { Subscription } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';

@Component({
  selector: 'app-root',
  templateUrl: './plansetting.component.html',
  styleUrls: ['./plansetting.component.scss']
})
export class PlanSetting implements OnInit, OnDestroy {
  @ViewChild('infographic', { static: true }) infographic;

  subscriptions: Subscription[] = [];
  employeeStrength: any[] = [];
  revenues: any[] = [];
  industries: any[] = [];
  companyInfo: UntypedFormGroup;
  isGraphLoading = false;
  color_first = '#3d83cf';
  color_second = '#26cfe5';
  industryTitle = 'Industry Name';
  revenueTitle = 'Revenue';
  columnChartData: any =  {
    chartType: 'ColumnChart',
    dataTable: [
    ['Type', 'Percent', { role: 'style' }],
    [this.industryTitle, 0, this.color_first],
    [this.revenueTitle, 0, this.color_second]
    ],
    options: {
      title: 'Your Budget Statistics',
      backgroundColor: { fill: 'transparent' },
      legend: { position: 'none' },
      width: 534,
      height: 360,
      vAxis: {
        title: 'Percent of Revenue spent on Marketing.',
        textStyle: {color: '#000000', fontSize: '12', paddingRight: '100', marginRight: '100'},
        minValue: 0,
        ticks: [0, 10, 20, 30, 40, 50],
        gridlines: { color: '#e4e7ee' }
      },
      animation: {
        duration: 700,
        easing: 'out',
        startup: true
      },
      hAxis: { title: 'Company information', textStyle: { color: '#000000', fontSize: '12', paddingRight: '100', marginRight: '100'} }
    }
  };

  graphArray = [];
  industryObj = <any>{};
  revenueObj = <any>{};
  userName: string;

  constructor(
    private readonly configuration: Configuration,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly planSettingService: PlanSettingService,
    private readonly formBuilder: UntypedFormBuilder,
    protected readonly validations: Validations,
    protected readonly userManager: UserManager,
    private readonly userService: UserService
  ) {
    this.userManager.setLoggedOut();

    this.createForm(); // creating form elements

    // Show popup loader
    this.subscriptions.push(this.utilityService.graphLoadingPropertyChanged$.subscribe(
      value => this.isGraphLoading = value
    ));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.loadUserDetails$().subscribe(
        user => {
          this.getRevenues(user && user.companies && user.companies[0]); // Get revenues list
          this.getIndustries(user && user.companies && user.companies[0]);   // Get industries list
        })
    );
    this.getEmployeesStrength();  // Get employees strength list
  }

  loadUserDetails$() {
    return this.userManager.currentUserId$.pipe(
      switchMap(userId => this.userService.getUser(userId)),
      tap(user => this.userName = user && user.user_profile_detail && user.user_profile_detail.first_name)
    );
  }

  createForm() {
    this.companyInfo = this.formBuilder.group({
      planning_for : ['Entire Company', Validators.required ],
      company_size_id: ['', Validators.required ],
      company_industry_id: ['', Validators.required ],
      company_revenue_id: ['', Validators.required ],
    });
  }

  setGraph(type: string, value) {
    if (value) {
      this.utilityService.showGraphLoading(true);

      if (type === 'industry_type') {
        this.industryObj = this.industries.find(item => item.id === value);
      }

      if (type === 'revenue_type') {
        this.revenueObj = this.revenues.find(item => item.id === value);
      }

      const industryVal = this.industryObj != null ? this.industryObj['graph_percent'] : 0;
      this.industryTitle = this.industryObj != null ? this.industryObj['name'] : 'Industry Name';

      const revenueVal = (this.revenueObj != null) ? this.revenueObj['graph_percent'] : 0;
      this.revenueTitle = (this.revenueObj != null) ? this.revenueObj['name'] : 'Revenue';

      this.graphArray = [
        ['Type', 'Percent', { role: 'style' }],
        [this.industryTitle, industryVal, this.color_first],
        [this.revenueTitle, revenueVal, this.color_second]
      ];

      this.columnChartData['dataTable'] = this.graphArray;
      const newObj = {};
      newObj['data'] = this.columnChartData;
      this.infographic.ngOnChanges(newObj);
      setTimeout(() => this.utilityService.showGraphLoading(false), 1000);
    }
  }

  getEmployeesStrength() {
    this.subscriptions.push(
      this.planSettingService.getEmployeesStrength().subscribe(
        response => this.handleSuccessResponse(response, data => this.employeeStrength = data),
        error => this.handleError(error)
      )
    );
  }

  getRevenues(companyId: number) {
    if (companyId) {
      const subscription =
        this.planSettingService.getRevenues(companyId)
          .subscribe(
            response => this.handleSuccessResponse(response, data => this.revenues = data),
            error => this.handleError(error)
          );
      this.subscriptions.push(subscription);
    }
  }

  getIndustries(companyId) {
    const subscription =
      this.planSettingService.getIndustries(companyId)
        .subscribe(
          response => this.handleSuccessResponse(response, data => this.industries = data),
          error => this.handleError(error)
        );
    this.subscriptions.push(subscription);
  }

  setupCompany() {
    const payload = {
      planning_for: this.companyInfo.value.planning_for.trim(),
      company_size: this.companyInfo.value.company_size_id,
      company_industry: this.companyInfo.value.company_industry_id,
      company_revenue: this.companyInfo.value.company_revenue_id
    };

    this.utilityService.showLoading(true);

    this.subscriptions.push(
      this.planSettingService.setupCompany(payload).subscribe(
        response => this.handleSuccessResponse(response, () => {
          this.utilityService.showToast({ Title: '', Message: response.message, Type: 'success' });
          this.userManager.setLoggedIn();
          this.router.navigate([this.configuration.DEFAULT_ROUTE]);
        }),
        error => this.handleError(error)
      )
    );
  }

  success(data, type) {
    if (!data) {
      return;
    }

    if (Number(data?.status) === HttpStatusCode.Ok) {
      if (type === 'getEmployee') {
        this.employeeStrength = data.data;
      } else if (type === 'getIndustries') {
        this.industries = data.data;
      } else if (type === 'getRevenues') {
        this.revenues = data.data;
      } else if (type === 'setupCompany') {
        this.utilityService.showToast({ Title: '', Message: data.message, Type: 'success' });
        this.userManager.setLoggedIn();
        this.router.navigate([this.configuration.DEFAULT_ROUTE]);
      }
      this.utilityService.showLoading(false);
    } else {
      this.utilityService.showToast({ Title: '', Message: data.message, Type: 'error' });
      this.utilityService.showLoading(false);
    }
  }

  handleSuccessResponse<T>(response: ApiV0Response<T>, dataHandler: (data: T) => void): void {
    if (!response) {
      return;
    }

    if (Number(response.status) === HttpStatusCode.Ok) {
      dataHandler?.(response.data)
    } else {
      this.utilityService.showToast({ Title: '', Message: response.message, Type: 'error' });
    }

    this.utilityService.showLoading(false);
  }

  handleError(error) {
    const message = error?.message || error?.detail;
    if (message) {
      this.utilityService.showToast({ Title: '', Message: message, Type: 'error' });
    }
    this.utilityService.showLoading(false);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
