import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { createDeepCopy } from '@shared/utils/common.utils';

export interface DrawerConfig {
  id: number;
  level: number;
}

export enum DrawerType {
  Goal = 'goal',
  Campaign = 'campaign',
  ChildCampaign = 'childCampaign',
  Program = 'program',
  Expense = 'expense',
  Metric = 'metric'
}

type DrawerStackConfig = Partial<Record<DrawerType, DrawerConfig>>;

@Injectable({
  providedIn: 'root'
})
export class DrawerStackService {
  private _stackConfig: DrawerStackConfig = {};

  public setStackConfig(config: DrawerStackConfig): void {
    this._stackConfig = config;
  }

  public updateStackConfig(type: DrawerType, configUpdates: Partial<DrawerConfig>): void {
    this._stackConfig[type] = { ...this._stackConfig[type], ...configUpdates };
  }

  public get stackConfig(): DrawerStackConfig {
    return this._stackConfig;
  }

  public getCloseActiveDrawerParam(): string {
    const stackConfig = createDeepCopy(this.stackConfig) || {};
    const drawerConfigs = Object.entries(stackConfig);
    if (drawerConfigs.length <= 1) {
      return null;
    }

    const [ activeDrawerType, activeDrawerConfig ] = drawerConfigs.find(
      ([ _, config ]) => config.level === drawerConfigs.length
    );
    delete stackConfig[activeDrawerType];
    return this.getRouteParamFromConfig(stackConfig);
  }

  public getAddActiveDrawerParam(type: DrawerType, objectId?: number): string {
    const stackConfig = createDeepCopy(this.stackConfig);
    const drawerType = type === DrawerType.Campaign && stackConfig[type] ? DrawerType.ChildCampaign : type;

    stackConfig[drawerType] = {
      id: objectId,
      level: Object.entries(stackConfig).length + 1
    };

    return this.getRouteParamFromConfig(stackConfig);
  }

  public getGoToParentDrawerParam(type: DrawerType, objectId: number): string {
    const stackConfig = createDeepCopy(this.stackConfig);
    const drawerConfig = stackConfig[type];
    if (drawerConfig.id !== objectId) {
      console.warn('Unexpected id - close stack');
      return this.getRouteParamFromConfig({});
    }
    for (const drawerType of Object.values(DrawerType)) {
      if (stackConfig[drawerType]?.level > drawerConfig.level) {
        delete stackConfig[drawerType];
      }
    }

    return this.getRouteParamFromConfig(stackConfig);
  }

  getConfigFromRouteParam(routeParam: string): DrawerStackConfig {
    const keyMapper = {
      g: DrawerType.Goal,
      c: DrawerType.Campaign,
      cc: DrawerType.ChildCampaign,
      p: DrawerType.Program,
      e: DrawerType.Expense,
      m: DrawerType.Metric
    };

    return atob(routeParam).split('_').reduce((acc, item, index) => {
      const [key, id] = item.split('-');
      return {
        ...acc,
        [keyMapper[key]]: {
          id: Number(id) || null,
          level: index + 1
        } as DrawerConfig
      };
    }, {});
  }

  getRouteParamFromConfig(stackConfig: DrawerStackConfig): string {
    const typeToPathMapper = {
      [DrawerType.Goal]: 'g',
      [DrawerType.Campaign]: 'c',
      [DrawerType.ChildCampaign]: 'cc',
      [DrawerType.Program]: 'p',
      [DrawerType.Expense]: 'e',
      [DrawerType.Metric]: 'm'
    };

    return btoa(Object.entries(stackConfig)
      .sort((a, b) => a[1].level - b[1].level)
      .map(([type, config]) =>
        config.id ? `${typeToPathMapper[type]}-${config.id}` : typeToPathMapper[type]
      )
      .join('_'));
  }
}
