<modal-window title="OOPS - WE FOUND ISSUES!" (close)="onClose()" class="errors-modal">
  <div class="errors-modal-content">
    <div class="message">{{context?.message}}</div>
    <div class="errors-container">
      <div *ngFor="let fail of context?.errors; let i = index;">
        <div class="number">{{(i + 1) + '. '}}</div>
        <div class="name">{{fail.name}}</div>
        <div class="details">{{fail.details}}</div>
      </div>
    </div>
    <div class="controls">
      <button type="button" (click)="onClose()" mat-flat-button color="primary">Close</button>
    </div>
  </div>
</modal-window>
