import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import { CalendarEvent } from './calendar-event.type';
import { MetricMappingDO } from 'app/shared/services/backend/metric.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { getPercentage, roundDecimal } from 'app/shared/utils/common.utils';

const FULL_SHARE = 100;
const MONTH_COUNT = 12;
const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss']
})
export class CalendarEventComponent implements AfterViewInit, OnChanges {
  @Input() eventObject: CalendarEvent;
  @Input() budgetStartDate: Date;
  @Input() budgetEndDate: Date;
  @Input() eventMetrics: MetricMappingDO[] = [];
  @Input() isNew = false;

  @ViewChild('eventRef') eventRef: ElementRef;
  @ViewChild('eventHeaderRef') eventHeaderRef: ElementRef;
  @ViewChild('eventDateRef') eventDateRef: ElementRef;

  datePipeFormat = 'LLL ORD, yyyy';
  eventStyles: any = {
    width: 0
  };
  eventHeaderStyles: any = {};
  headerAreaStyles: any = {};
  eventStartOverflow = false;
  eventEndOverflow = false;

  // Default border offset in pixels
  private readonly borderOffsetWidth = 4;
  private readonly titleOffsetWidth = 5;
  // Fixed width of the calendar's area in print mode for the offsets consistency
  private readonly printModeEventWidth = 743;

  constructor(private readonly routingService: AppRoutingService) {}

  ngAfterViewInit(): void {
    if (this.eventObject) {
      this.renderEvent();
    }
  }

  ngOnChanges(): void {
    if (this.eventObject) {
      this.renderEvent();
    }
  }

  private renderEvent() {
    this.defineBarPosition();
    this.defineTitlePosition();
  }

  public defineTitlePosition() {
    if (!this.eventRef || !this.eventDateRef || !this.eventHeaderRef) {
      return;
    }

    const eventRect = this.eventRef.nativeElement.getBoundingClientRect();
    const dateRect = this.eventDateRef.nativeElement.getBoundingClientRect();
    const headerRect = this.eventHeaderRef.nativeElement.getBoundingClientRect();

    const eventLeftOffsetPercentage = this.eventStyles.left ? parseFloat(this.eventStyles.left) : 0;
    const eventWidth = eventRect.width;

    const printModeHeaderWidthPercentage = getPercentage(headerRect.width, this.printModeEventWidth);
    const headerWidthPercentage = getPercentage(headerRect.width, eventWidth);

    const printModeDateOffsetPercentage = -getPercentage(dateRect.width + this.titleOffsetWidth, this.printModeEventWidth);
    const dateOffsetPercentage = -getPercentage(dateRect.width + this.titleOffsetWidth, eventWidth);
    // Bare minimum event offset
    const offsetThresholdPercentage = 0.3;
    const maxHeaderOffsetPercentage = 100 - printModeHeaderWidthPercentage - offsetThresholdPercentage;

    let headerOffsetDelta = Math.abs(printModeDateOffsetPercentage - dateOffsetPercentage);
    let headerLeftOffsetPercentage = Math.max(offsetThresholdPercentage, eventLeftOffsetPercentage + printModeDateOffsetPercentage);

    if (headerLeftOffsetPercentage > maxHeaderOffsetPercentage) {
      headerLeftOffsetPercentage = maxHeaderOffsetPercentage;
      headerOffsetDelta = printModeHeaderWidthPercentage - headerWidthPercentage;
    }

    this.eventHeaderStyles.left = `${headerLeftOffsetPercentage}%`;
    this.headerAreaStyles.transform = headerLeftOffsetPercentage > offsetThresholdPercentage ? `translateX(${headerOffsetDelta}%)` : 0;
  }

  private daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }

  private getStartDate() {
    return (this.eventObject && this.eventObject.startDate);
  }

  private getEndDate() {
    return (this.eventObject && this.eventObject.endDate);
  }

  private getCalendarStartDate() {
    // TODO: Reset start date to '1' because grid won't support FY to start 'in the middle' of the month for MVP;
    const utcTime = Date.UTC(
      this.budgetStartDate.getUTCFullYear(),
      this.budgetStartDate.getUTCMonth(),
      1
    );

    return new Date(utcTime);
  }

  private getCalendarEndDate() {
    const startDate = this.getCalendarStartDate();
    const utcTime = Date.UTC(
      this.budgetEndDate.getUTCFullYear(),
      this.budgetEndDate.getUTCMonth(),
      this.budgetEndDate.getUTCDate()
    );
    const endDate = new Date(utcTime);
    // TODO: Reset end date because grid won't support FY to end 'in the middle' of the month for MVP;
    if (startDate.getUTCMonth() === endDate.getUTCMonth()) {
      endDate.setUTCDate(1);
      endDate.setTime(endDate.getTime() - ONE_DAY_IN_MS);
    }

    return endDate;
  }

  /**
   * Get month index for the target relatively to calendar start date.
   */
  private getTargetMonth(targetYear: number, targetMonth: number): number {
    const budgetMonth = this.getCalendarStartDate().getUTCMonth();
    const budgetYear = this.getCalendarStartDate().getUTCFullYear();
    const yearDiff = targetYear - budgetYear;

    return (yearDiff * MONTH_COUNT) + targetMonth - budgetMonth;
  }

  /**
   * Get percentage share per one month, if we render a full year only.
   */
  private getMonthlyShare(): number {
    return FULL_SHARE / MONTH_COUNT;
  }

  /**
   * Get absolute percentage share per one day, based on total days amount in the month.
   */
  private getDailyShare(daysInMonth: number): number {
    return this.getMonthlyShare() / daysInMonth;
  }

  private getOffsetFromStart(target: Date): number {
    const month = target.getMonth();
    const day = target.getDate();
    const year = target.getFullYear();
    const daysInMonth = this.daysInMonth(month, year);

    const monthOffset = this.getMonthlyShare() * this.getTargetMonth(year, month);
    const dayOffset = this.getDailyShare(daysInMonth) * (day - 1);

    return roundDecimal(monthOffset + dayOffset, 3);
  }

  /**
   * Get borders offset for bars rendering in percentage
   */
  private getBorderOffset(): number {
    let offsetShare = 0.2;
    if (this.eventRef && this.eventRef.nativeElement) {
      const eventRefWidth = this.eventRef.nativeElement.offsetWidth;
      offsetShare = roundDecimal(this.borderOffsetWidth / eventRefWidth * FULL_SHARE, 3);
    }

    return offsetShare;
  }

  private defineBarPosition() {
    const calendarStartDate = this.getCalendarStartDate();
    const calendarEndDate = this.getCalendarEndDate();
    const MIN_WIDTH = 0;
    let startDate = this.getStartDate();
    let endDate = this.getEndDate();
    let eventWidth;

    if (!startDate) {
      this.eventStartOverflow = true;
      startDate = calendarStartDate;
    }
    if (!endDate) {
      this.eventEndOverflow = true;
      endDate = calendarEndDate;
    }

    let eventStartOffset = this.getOffsetFromStart(startDate);
    const eventEndOffset = this.getOffsetFromStart(endDate);
    const borderOffset = this.getBorderOffset();

    if (endDate < calendarStartDate) {
      // Event ends before FY
      eventWidth = MIN_WIDTH;
      eventStartOffset = -10;
    } else if (startDate < calendarStartDate) {
      // Event starts before FY
      this.eventStartOverflow = true;
      eventStartOffset = borderOffset;
      eventWidth = eventEndOffset - borderOffset;
    } else if (endDate > calendarEndDate) {
      // Event ends after FY
      this.eventEndOverflow = true;
      eventWidth = FULL_SHARE - eventStartOffset - borderOffset;
    } else {
      // Event is within FY range
      eventWidth = eventEndOffset - eventStartOffset;
    }

    if (startDate.getTime() === calendarStartDate.getTime()) {
      eventStartOffset = borderOffset;
      eventWidth -= borderOffset;
    }

    if (endDate.getTime() === calendarEndDate.getTime()) {
      eventWidth = FULL_SHARE - eventStartOffset - borderOffset;
    }

    this.eventStyles.width = `${Math.max(eventWidth, MIN_WIDTH)}%`;
    this.eventStyles.left = `${Math.min(eventStartOffset, 100)}%`;
  }

  public openCampaign() {
    this.routingService.openCampaignDetails(this.eventObject.id);
  }
}
