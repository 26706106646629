import { Injectable } from '@angular/core';
import { ExternalMetricTypesService } from '../services/external-metric-types-service';
import { AdsMetricTypeNames, ExternalMetricType } from '../types/external-metrics.types';
import { FacebookDataService } from './facebook-data.service';

@Injectable({
  providedIn: 'root'
})
export class FacebookMetricTypesService extends ExternalMetricTypesService {
  externalMetricTypes = [
    {
      type: ExternalMetricType.int,
      name: AdsMetricTypeNames.IMPRESSIONS,
      label: 'Impressions',
      description: 'How often your ads were shown'
    },
    {
      type: ExternalMetricType.int,
      name: AdsMetricTypeNames.CLICKS,
      label: 'Clicks',
      description: 'Actions on your ads'
    },
    {
      type: ExternalMetricType.int,
      name: AdsMetricTypeNames.CONVERSIONS,
      label: 'Conversions',
      description: 'Actions on your website from ads'
    },
    {
      type: ExternalMetricType.int,
      name: AdsMetricTypeNames.REACH,
      label: 'Reach',
      description: 'Number of people who were exposed to your message during an ad campaign'
    },
  ];
  defaultMapping = {
    [AdsMetricTypeNames.IMPRESSIONS]: 'Impressions',
    [AdsMetricTypeNames.CLICKS]: 'Clicks',
    [AdsMetricTypeNames.CONVERSIONS]: 'Conversions',
    [AdsMetricTypeNames.REACH]: 'Reach',
  };

  constructor(
    private readonly facebookDataService: FacebookDataService
  ) {
    super(facebookDataService);
  }
}
