import { Injectable } from '@angular/core';
import { ExternalMetricProviderApiService } from '../../types/external-metric-provider-api.service';
import { switchMap, tap } from 'rxjs/operators';
import { UtilityService } from 'app/shared/services/utility.service';
import { MetricsProviderDataService } from '../../services/metrics-provider-data.service';
import { ExternalCampaign, ExternalCampaignData } from '../../types/external-campaign.interface';
import { CampaignBrief, IntegrationMappingState, PlannuhCampaignMetric } from '../../types/metric-integration';
import { Budget } from 'app/shared/types/budget.interface';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { getChildrenMappingState } from './utils';

@Injectable()
export abstract class MetricMappingDialogService<TObjMapping> {
  protected _campaignMapping: TObjMapping;
  protected _externalCampaigns: ExternalCampaign[] = [];
  private plannuhCampaignsForSync: number[];

  constructor(
    private readonly utilityService: UtilityService,
    private readonly metricProviderApiService: ExternalMetricProviderApiService<TObjMapping>,
    private readonly metricProviderDataService: MetricsProviderDataService<TObjMapping>
  ) {}

  protected abstract isCampaignMetricMapped(campaign: ExternalCampaign, campaignMapping: TObjMapping): boolean;
  protected abstract addCampaignMapping(campaign: ExternalCampaign, contextData: any): void;
  protected abstract deleteCampaignMapping(campaign: ExternalCampaign): void;
  protected abstract filterIrrelevantMappings(campaignMapping: TObjMapping): void;
  protected abstract get failedToLoadCampaignsErrorMsg(): string;

  private isCampaignMapped(campaign: ExternalCampaign): boolean {
    return campaign.mappingState === IntegrationMappingState.Mapped;
  }

  public loadExternalCampaigns(
    params: {
      companyId: number;
      campaignMetrics: PlannuhCampaignMetric[];
      campaignId: number;
      integrationId: string;
      dateFrom: string;
      budget: Budget;
      allCampaigns: LightCampaign[]
    },
    loadedCb?: (campaigns: ExternalCampaign[], typeMappings: string[]) => void,
    errorCb?: (errorMessage?: string) => void,
  )  {
    this.utilityService.showLoading(true);
    const { companyId, campaignId, campaignMetrics, dateFrom, budget, allCampaigns, integrationId } = params;
    const campaignMapping$ =
      this.metricProviderDataService.getCampaignMapping(companyId, budget.id, campaignId, integrationId).pipe(
        tap(mapping => this._campaignMapping = mapping)
      );

    return campaignMapping$
      .pipe(
        switchMap(
          mapping =>  this.metricProviderApiService.getExternalCampaigns({
            companyId,
            campaignId,
            plannuhCampaignMetrics: campaignMetrics,
            dateFrom,
            budget,
            mapping,
            allCampaigns,
            integrationId
          })
        )
      )
      .subscribe(
      (data) => this.applyLoadedExternalCampaigns([data, this._campaignMapping], loadedCb),
      (error) => {
          this.handleError(this.failedToLoadCampaignsErrorMsg, error);
          if (typeof errorCb === 'function') {
            errorCb(this.failedToLoadCampaignsErrorMsg);
          }
        }
      )
  }

  private applyLoadedExternalCampaigns(
    [campaignsData, campaignMapping]: [ExternalCampaignData, TObjMapping],
    loadedCb?: (campaigns: ExternalCampaign[], typeMappings: string[]) => void
  ) {
    const { metricTypeMappings, campaigns } = campaignsData;
    this.prepareCampaignsData(campaigns, campaignMapping);
    if (loadedCb != null) {
      loadedCb(this._externalCampaigns, metricTypeMappings);
    }
    this.utilityService.showLoading(false);
  }

  public saveCampaignMapping(companyId, budgetId, campaignId, integrationId, onSavedCb: Function) {
    if (this._campaignMapping) {
      this.utilityService.showLoading(true);
      this.prepareCampaignsData(this._externalCampaigns, this._campaignMapping);
      this.filterIrrelevantMappings(this._campaignMapping);
      this.metricProviderDataService.setCampaignMapping(
        companyId,
        budgetId,
        campaignId,
        integrationId,
        this._campaignMapping
      ).subscribe(
        () => {
          this.utilityService.showToast({ Title: '', Message: 'Mappings have been saved successfully', Type: 'success'});
          if (onSavedCb) {
            onSavedCb(this._campaignMapping, this.plannuhCampaignsForSync);
          }
        },
        error => this.handleError('Failed to save mappings', error)
      )
    }
  }

  private prepareCampaignsData(externalCampaigns: ExternalCampaign[], campaignMapping: TObjMapping) {
    const campaigns = externalCampaigns ? [...externalCampaigns] : [];
    campaigns.forEach(campaign => this.assignMappingsState(campaign, campaignMapping));
    this._externalCampaigns = campaigns;
  }

  private assignMappingsState (campaign: ExternalCampaign, campaignMapping: TObjMapping) {
    const campaignMappingState: IntegrationMappingState =
      this.isCampaignMetricMapped(campaign, campaignMapping) ?
        IntegrationMappingState.Mapped :
        IntegrationMappingState.NotMapped;

    const mapperFn = (childCampaign) => {
      return this.assignMappingsState(childCampaign, campaignMapping);
    }

    campaign.mappingState = campaignMappingState;
    campaign.mappingChildrenState = getChildrenMappingState(campaign, mapperFn);
    return {
      existingMapping: campaign.existingMapping,
      mappingState: campaign.mappingState,
      mappingChildrenState: campaign.mappingChildrenState,
    };
  }

  public updateCampaignsMapping(campaigns: ExternalCampaign[], contextData: any) {
    this.plannuhCampaignsForSync = [];
    campaigns.forEach(campaign => {
      this.updateCampaignMappingState(campaign, contextData);
    })
  }

  public updateCampaignMappingState(campaign: ExternalCampaign, contextData: any) {
    const isMapped = this.isCampaignMapped(campaign);

    if (isMapped && campaign.existingMapping) {
      this.plannuhCampaignsForSync.push((campaign.existingMapping as CampaignBrief).id);
    }
    isMapped ? this.addCampaignMapping(campaign, contextData) : this.deleteCampaignMapping(campaign);
    (campaign.children || []).forEach(
      childCampaign => this.updateCampaignMappingState(childCampaign, contextData)
    );
  }

  private handleError(message: string, logError?: any) {
    if (logError) {
      console.log(logError);
    }
    if (message) {
      this.utilityService.handleError({ message });
    }
    this.utilityService.showLoading(false);
  }
}
