<widget-card [isLoading]="state === widgetState.LOADING">
  <div class="budget-minus-expenses">
    <simple-widget-header>
      <h2 class="header-title">Budget minus expenses</h2>
    </simple-widget-header>
    <div class="content" *ngIf="state === widgetState.READY">
      <div class="main-table">
        <div class="table-row header" [class.no-current-tf]="tableHeaderCells.length < 4">
          <ng-container *ngFor="let cell of tableHeaderCells">
            <div class="cell">{{cell}}</div>
          </ng-container>
        </div>
        <ng-container *ngFor="let row of tableRows">
          <div class="table-row" [ngClass]="row.rowType" [class.no-current-tf]="!row.currentTFAmountCell">
            <div class="cell title">{{row.title}}</div>
            <div *ngIf="row.currentTFAmountCell" class="cell current-tf amount"
                 (click)="row.currentTFAmountCell.onClick && row.currentTFAmountCell.onClick()"
                 [class.clickable]="row.currentTFAmountCell.onClick"
                 [class.problem]="row.currentTFAmountCell.hasProblem"
                 [class.highlighted]="row.currentTFAmountCell.highlighted">
              <div class="value-wrapper">
                <span>{{currencySymbol}}&nbsp;{{row.currentTFAmountCell.value | number: '1.2-2'}}</span>
              </div>
            </div>
            <div class="cell year amount"
                 (click)="row.FYAmountCell.onClick && row.FYAmountCell.onClick()"
                 [class.clickable]="row.FYAmountCell.onClick"
                 [class.problem]="row.FYAmountCell.hasProblem"
                 [class.highlighted]="row.FYAmountCell.highlighted"
                 [class.warning] = "row.FYAmountCell.warning">
              <div class="value-wrapper">
                <span>{{currencySymbol}}&nbsp;{{row.FYAmountCell.value | number: '1.2-2'}}</span>
              </div>
            </div>
            <div class="cell icon">
              <div  *ngIf="row.FYAmountCell.warning"
                    class="tf-warning"
                    [matTooltip]="row.FYAmountCell.warning.text"
                    matTooltipPosition="above"
                    matTooltipClass="dark-theme-tooltip above multi-line">
                <fa-duotone-icon
                  [icon]="['fad', 'exclamation-triangle']"
                  (click)="row.FYAmountCell.warning.action && row.FYAmountCell.warning.action()">
                </fa-duotone-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</widget-card>
