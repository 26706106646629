import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MetricMilestones } from 'app/budget-object-details/types/metric-milestone.interface';

@Component({
  selector: 'metric-details-milestones',
  templateUrl: './metric-details-milestones.component.html',
  styleUrls: ['./metric-details-milestones.component.scss']
})
export class MetricDetailsMilestonesComponent implements OnChanges {
  @Input() milestonesData;
  @Input() metricFormData: UntypedFormGroup[] = [];
  @Input() displayDecimal: boolean = false;
  @Output() patchMilestonesList = new EventEmitter<Number>();
  @Output() handleMilestoneValueUpdate = new EventEmitter<Number>();
  @Output() deleteMilestone = new EventEmitter();
  newAmountFieldOpt = { prefix: '', precision: 0, decimal: '.', align: 'left', allowNegative: false };
  milestones: MetricMilestones;

  ngOnChanges(changes: SimpleChanges) {
    if (this.displayDecimal) {
      this.newAmountFieldOpt.precision = 2;
    }
  }

  getMilestoneTitle(index: number) {
    const title = index === this.milestonesData.length ? 'Reach your Target of' : `Milestone ${index}`
    return title;
  }

  deleteMilestoneByIndex(event, index: number) {
    this.deleteMilestone.emit({ event, index });
  }

  setMilestoneValue(value: number, index: number) {
    const dateField = this.metricFormData[index].controls['date'];
    if (dateField.value && !dateField.valid) {
      dateField.updateValueAndValidity();
    }
    this.patchMilestonesList.emit(index)
    this.handleMilestoneValueUpdate.emit(index)
  }

  onHandleTargetDateChange(value, index: number) {
    const amountField = this.metricFormData[index].controls['amount'];
    if (!amountField.touched) {
      amountField.markAsTouched()
    }
    if (amountField.value > 0 || (this.metricFormData.length === 1 && index === 0)) {
      this.handleMilestoneValueUpdate.emit(index)
    }
    amountField.updateValueAndValidity();
    this.patchMilestonesList.emit(index);
  }

  openDateicker(event, datePicker) {
    event.stopPropagation();
    datePicker.open();
  }

  clearDate(event, index, datePicker) {
    event.stopPropagation();
    datePicker.open();
    this.milestonesData[index]['date'] = null;
    const dateField = this.metricFormData[index].controls['date'];
    dateField.setValue(null);
    dateField.updateValueAndValidity();
    this.patchMilestonesList.emit(index);
  }

}
