import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SelectedValue, SelectItem } from 'app/shared/types/select-groups.interface';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';
import { SelectGroupsComponent } from 'app/shared/services/select-groups';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { defaultMetricSorting } from 'app/shared/utils/common.utils';


export function getMetricSelectItems(products: ProductDO[], unusedMetrics: MetricType[], addGroupName = false): SelectItem[] {
  const store = [];
  const standalone = [];
  const groupedByProduct = [];

  const createSelectItem = (data: MetricType, groupName: string): SelectItem => {
    const item: SelectItem = {
      title: data.name,
      value: data.id,
    };
    if (addGroupName && groupName) {
      item.groupTitle = groupName;
    }
    return item;
  };

  const addMetricToProductGroup = (productId: number, metric: MetricType, product: ProductDO) => {
    const createdGroup = groupedByProduct.find(group => group.id === productId);
    if (createdGroup) {
      createdGroup.children.push(metric);
    } else {
      groupedByProduct.push({
        id: product.id,
        order: product.order,
        name: product.name,
        children: [metric],
      });
    }
  };

  unusedMetrics
    .filter(metric => !metric.isHidden)
    .forEach(metric => {
      const productId = metric.productId;
      const product = products.find(prod => prod.id === productId);

      if (productId && !product) {
        console.error('Missing Product for Metric', metric);
      }

      if (!product) {
        standalone.push(metric);
      } else if (product.activated) {
        addMetricToProductGroup(productId, metric, product);
      }
  });

  if (standalone.length) {
    standalone.sort(defaultMetricSorting);
    store.push(...standalone.map(item => createSelectItem(item, null)));
  }

  if (groupedByProduct.length) {
    groupedByProduct.sort(defaultMetricSorting);
    groupedByProduct.forEach(group => {
      const children = group.children;
      children.sort(defaultMetricSorting);
      store.push({
        value: group.id,
        title: group.name,
        children: children.map(item => createSelectItem(item, group.name)),
      });
    })
  }

  return store;
}

@Component({
  selector: 'metric-masters-list',
  templateUrl: './metric-masters-list.component.html',
  styleUrls: ['./metric-masters-list.component.scss']
})
export class MetricMastersListComponent extends SelectGroupsComponent implements OnChanges {
  @Input() options: SelectItem[];
  @Input() selectedOptionId: number;
  @Input() isPowerUser: boolean;
  @Input() singleSelectMode = false;

  @Output() closeDropdown = new EventEmitter<void>();
  @Output() addMetrics = new EventEmitter<SelectedValue[]>();
  @Output() selectMetric = new EventEmitter<SelectedValue>();
  @Output() navigateToFunnelsPage = new EventEmitter<void>();

  public allSelectionState: CheckboxValue = CheckboxValue.NotActive;
  public selectionState: Record<string, CheckboxValue> = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && this.options?.length) {
      this.options = this.options.reverse();
      this.hasGroups = this.options.some(option => option.children);
    }
  }

  filterItemsByText(filterText: string) {
    MetricMastersListComponent.filterItemsByText(
      filterText,
      this.options,
      true,
      true
    );
    this.updateAllGroupsSelectionState();
    this.updateAllSelectionState();
  }

  toggleAllSelection(checked: boolean) {
    const state = this.boolToCheckboxState(checked);
    this.toggleSelection(this.options, state);
    this.allSelectionState = state;
  }

  toggleSelection(options: SelectItem[], state: CheckboxValue) {
    options.forEach(option => {
      if (!option.hidden) {
        this.selectionState[option.value] = state;
      }
      if (option.children?.length) {
        this.toggleSelection(option.children, state);
      }
    })
  }

  updateAllSelectionState(): void {
    const showedOptionsIds: SelectedValue[] = MetricMastersListComponent.getVisibleOptionsIds(this.options, false);
    if (!showedOptionsIds.length) {
      this.allSelectionState = CheckboxValue.NotActive;
      return;
    }
    const selectedOptions = showedOptionsIds.reduce((sum: number, optionId) => {
      if (this.selectionState[optionId] === CheckboxValue.Active) {
        sum += 1;
      }
      return sum;
    }, 0);
    this.allSelectionState = MetricMastersListComponent.getParentSelectionState(selectedOptions, showedOptionsIds.length);
  }

  toggleGroupSelection(checked: boolean, group: SelectItem) {
    if (!this.singleSelectMode) {
      this.toggleSelection([group], this.boolToCheckboxState(checked));
      this.updateAllSelectionState();
    } else {
      this.toggleGroupCollapse(this.groupsCollapseState.collapsed[group.value], group.value);
    }
  }

  toggleItemSelection(checked: boolean, option: SelectItem, parent: SelectItem = null) {
    if (option.hidden) {
      return;
    }
    if (!this.singleSelectMode) {
      this.selectionState[option.value] = this.boolToCheckboxState(checked);

      if (parent) {
        this.updateOneGroupSelectionState(parent);
      }
      this.updateAllSelectionState();
    } else {
      this.selectMetric.emit(option.value);
    }
  }

  updateAllGroupsSelectionState() {
    this.options
      .filter(option => !option.hidden && option.children?.length)
      .forEach(group => this.updateOneGroupSelectionState(group));
  }

  updateOneGroupSelectionState(parent: SelectItem) {
    const selectedLength = parent.children.reduce((sum, child) => {
      if (this.isSelected(child.value)) {
        sum += 1;
      }
      return sum;
    }, 0);
    this.selectionState[parent.value] = MetricMastersListComponent.getParentSelectionState(selectedLength, parent.children.length);
  }

  boolToCheckboxState(checked: boolean): CheckboxValue {
    return checked ? CheckboxValue.Active : CheckboxValue.NotActive;
  }

  isSelected(id: SelectedValue): boolean {
    return this.selectionState[id] === CheckboxValue.Active;
  }

  onConfirm(): void {
    const selectedIds = MetricMastersListComponent.getVisibleOptionsIds(this.options, false)
      .filter(id => this.isSelected(id));
    this.addMetrics.emit(selectedIds);
  }

  onCancel(): void {
    this.closeDropdown.emit();
  }

  navigateToFunnels(): void {
    this.navigateToFunnelsPage.emit();
  }
}
