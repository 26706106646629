import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { BudgetCalculatorData } from '@common-lib/lib/corporate-page/metric-funnels.types';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

export interface ProductDO {
  id: number;
  company: number;
  name: string;
  activated: boolean;
  revenue_to_profit?: number;
  budget_calculator_data?: BudgetCalculatorData;
  order: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  public apiPaths = {
    product: 'product'
  };

  getProducts(companyId: number, params = null): Observable<ProductDO[]> {
    return this.http.get<ProductDO[]>(
      `${this.apiV2Url}${this.apiPaths.product}/`,
      getRequestOptions({ ...params, company: companyId })
    );
  }

  getProduct(productId: number): Observable<ProductDO> {
    return this.http.get<ProductDO>(`${this.apiV2Url}${this.apiPaths.product}/${productId}/`);
  }

  createProduct(data: Omit<ProductDO, 'id'>): Observable<ProductDO> {
    return this.http.post<ProductDO>(
      `${this.apiV2Url}${this.apiPaths.product}/`,
      data
    );
  }

  updateProduct(productId: number, product: Partial<ProductDO>): Observable<ProductDO> {
    return this.http.patch<ProductDO>(
      `${this.apiV2Url}${this.apiPaths.product}/${productId}/`,
      { ...product }
    );
  }

  deleteProduct(productId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiV2Url}${this.apiPaths.product}/${productId}/`);
  }
}
