<header mat-dialog-title>
  <fa-duotone-icon [icon]="['fad', 'route']" class="icon icon-warn"></fa-duotone-icon>
  <span>Remap Campaign{{ context.isMultiple ? 's' : '' }}?</span>
</header>
<div>
  <p class="text mt-2">
    {{ context.isMultiple ? 'These campaigns are' : 'This campaign is' }} already mapped elsewhere. Are you sure you want to remap {{ context.isMultiple ? 'them' : 'it' }}?
  </p>
  <div class="campaigns-relation">
    <div class="external">
      <campaign-name [name]="context.campaignExternalName" [type]="context.integrationType"></campaign-name>
    </div>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4"
            d="M37.5 13.75C37.5 13.4185 37.3683 13.1005 37.1339 12.8661C36.8995 12.6317 36.5815 12.5 36.25 12.5L31.25 12.5L31.25 15L36.25 15C36.5815 15 36.8995 14.8683 37.1339 14.6339C37.3683 14.3995 37.5 14.0815 37.5 13.75V13.75ZM37.5 6.25C37.5 5.91848 37.3683 5.60054 37.1339 5.36612C36.8995 5.1317 36.5815 5 36.25 5L31.25 5L31.25 7.5L36.25 7.5C36.5815 7.5 36.8995 7.3683 37.1339 7.13388C37.3683 6.89946 37.5 6.58152 37.5 6.25V6.25Z"
            fill="#008BCB"/>
      <path
        d="M30.625 17.5L29.375 17.5C29.2092 17.5 29.0503 17.4342 28.9331 17.3169C28.8158 17.1997 28.75 17.0408 28.75 16.875L28.75 16.25L27.5 16.25C26.0593 16.2498 24.6629 15.7521 23.5469 14.841C22.4308 13.93 21.6636 12.6615 21.375 11.25L17.5 11.25L17.5 8.75L21.375 8.75C21.6636 7.33853 22.4308 6.07004 23.5469 5.15898C24.6629 4.24793 26.0593 3.75022 27.5 3.75L28.75 3.75L28.75 3.125C28.75 2.95924 28.8158 2.80027 28.9331 2.68306C29.0503 2.56585 29.2092 2.5 29.375 2.5L30.625 2.5C30.7908 2.5 30.9497 2.56585 31.0669 2.68306C31.1842 2.80027 31.25 2.95924 31.25 3.125L31.25 16.875C31.25 17.0408 31.1842 17.1997 31.0669 17.3169C30.9497 17.4342 30.7908 17.5 30.625 17.5Z"
        fill="#008BCB"/>
      <path opacity="0.4"
            d="M56.5 53.75C56.5 53.4185 56.3683 53.1005 56.1339 52.8661C55.8995 52.6317 55.5815 52.5 55.25 52.5L50.25 52.5L50.25 55L55.25 55C55.5815 55 55.8995 54.8683 56.1339 54.6339C56.3683 54.3995 56.5 54.0815 56.5 53.75V53.75ZM56.5 46.25C56.5 45.9185 56.3683 45.6005 56.1339 45.3661C55.8995 45.1317 55.5815 45 55.25 45L50.25 45L50.25 47.5L55.25 47.5C55.5815 47.5 55.8995 47.3683 56.1339 47.1339C56.3683 46.8995 56.5 46.5815 56.5 46.25V46.25Z"
            fill="#E82987"/>
      <path
        d="M49.625 57.5L48.375 57.5C48.2092 57.5 48.0503 57.4342 47.9331 57.3169C47.8158 57.1997 47.75 57.0408 47.75 56.875L47.75 56.25L46.5 56.25C45.0593 56.2498 43.6629 55.7521 42.5469 54.841C41.4308 53.93 40.6636 52.6615 40.375 51.25L36.5 51.25L36.5 48.75L40.375 48.75C40.6636 47.3385 41.4308 46.07 42.5469 45.159C43.6629 44.2479 45.0593 43.7502 46.5 43.75L47.75 43.75L47.75 43.125C47.75 42.9592 47.8158 42.8003 47.9331 42.6831C48.0503 42.5658 48.2092 42.5 48.375 42.5L49.625 42.5C49.7908 42.5 49.9497 42.5658 50.0669 42.6831C50.1842 42.8003 50.25 42.9592 50.25 43.125L50.25 56.875C50.25 57.0408 50.1842 57.1997 50.0669 57.3169C49.9497 57.4342 49.7908 57.5 49.625 57.5Z"
        fill="#E82987"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.20002 14.9998C7.20002 12.9011 8.90134 11.1998 11 11.1998H15V8.7998H11C7.57585 8.7998 4.80002 11.5756 4.80002 14.9998V29.7444L3.27779 28.2222L2.49997 27.4444L0.944336 29L1.72215 29.7778L4.80002 32.8557V44.9998C4.80002 48.424 7.57585 51.1998 11 51.1998H37V48.7998H11C8.90134 48.7998 7.20002 47.0985 7.20002 44.9998V32.8556L10.2778 29.7778L11.0556 29L9.49997 27.4444L8.72215 28.2222L7.20002 29.7443V14.9998Z"
            fill="url(#paint0_linear_1116_3574)"/>
      <defs>
        <linearGradient id="paint0_linear_1116_3574" x1="37.5916" y1="49.9998" x2="18.1976" y2="6.62501"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#E82987"/>
          <stop offset="1" stop-color="#008BCB"/>
        </linearGradient>
      </defs>
    </svg>
    <div class="internal">
      <campaign-name [name]="context.campaignCurrentName"></campaign-name>
      <campaign-name [name]="context.campaignTargetName"></campaign-name>
    </div>
  </div>

  <p class="text mt-2">
    You will lose all contribution to
    <ng-container *ngIf="context.isMultiple; else singularText">those campaigns</ng-container>
    <ng-template #singularText><b>{{ context.campaignCurrentName }}</b></ng-template> from the following metrics:
  </p>

  <metrics-list [metrics]="context.metrics"></metrics-list>
</div>
<footer mat-dialog-actions>
  <button mat-button color="primary" (click)="dialogRef.close()">Cancel</button>
  <button mat-flat-button color="primary" (click)="dialogRef.close(true)">Remap</button>
</footer>
