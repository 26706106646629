<td class="table-col object-controls-col" [ngClass]="utClasses.objectControls">
  <div class="table-cell">Grand Total</div>
</td>
<td class="table-col segment-breakdown-col">
  <div class="table-cell">
    <manage-table-segment-breakdown
      [allocatedOnly]="!segmentBreakdown"
      [withSubtitles]="allocationMode"
    ></manage-table-segment-breakdown>
  </div>
</td>
<td class="performance-col">
  <div class="table-cell"></div>
</td>
<ng-container *ngIf="allocationMode">
  <td
    *ngFor="let timeframe of timeframes; trackBy: identifyTimeframe"
    class="table-col timeframe-col"
    [class.highlighted-timeframe]="highlightedTimeframes.includes(timeframe.id)"
    [ngClass]="utClasses.timeframe"
    [class.only-expense]="budget?.suppress_timeframe_allocations"
  >
    <div class="table-cell difference-amount-cell total-amount-cell">
      <manage-table-segment-breakdown [allocatedOnly]="!segmentBreakdown">
        <ng-template #segment>
          <budget-allocation-cell
            *ngIf="!budget?.suppress_timeframe_allocations"
            [allocated]="data?.segment?.values[timeframe.id]?.allocated"
            [actionTooltipContext]="tooltipContext"
            [disabled]="timeframe.locked"
            [editable]="false"
            [showDifference]="false"
          >
          </budget-allocation-cell>
          <manage-table-plain-value
            [value]="data?.segment?.values[timeframe.id]?.spent"
            [isDisabled]="timeframe.locked"
          ></manage-table-plain-value>
        </ng-template>
        <ng-template #allocated>
          <budget-allocation-cell
            *ngIf="!budget?.suppress_timeframe_allocations"
            [allocated]="data?.values[timeframe.id]?.allocated"
            [spent]="null"
            [remaining]="data?.values[timeframe.id]?.remainingAllocated"
            [actionTooltipContext]="tooltipContext"
            [disabled]="timeframe.locked"
            [editable]="false"
            [showDifference]="true"
          >
          </budget-allocation-cell>
          <manage-table-plain-value
            [value]="data?.values[timeframe.id]?.spent"
            [isDisabled]="timeframe.locked"
          ></manage-table-plain-value>
        </ng-template>
        <ng-template #unallocated>
          <budget-allocation-cell
            *ngIf="!budget?.suppress_timeframe_allocations"
            [allocated]="data?.unallocated?.values[timeframe.id]?.allocated"
            [spent]="data?.unallocated?.values[timeframe.id]?.spent"
            [actionTooltipContext]="tooltipContext"
            [disabled]="timeframe.locked"
            [editable]="false"
            [showDifference]="true"
            [allowNegative]="true"
          >
          </budget-allocation-cell>
          <manage-table-plain-value
            [value]="data?.unallocated?.values[timeframe.id]?.spent"
            [isDisabled]="timeframe.locked"
          ></manage-table-plain-value>
        </ng-template>
      </manage-table-segment-breakdown>
    </div>
  </td>
  <td class="table-col fy-total-col">
    <div class="table-cell difference-amount-cell total-amount-cell">
      <manage-table-segment-breakdown [allocatedOnly]="!segmentBreakdown">
        <ng-template #segment>
          <budget-allocation-cell
            [allocated]="data?.segment?.total?.allocated"
            [actionTooltipContext]="tooltipContext"
            [editable]="false"
            [showDifference]="false"
          >
          </budget-allocation-cell>
          <manage-table-plain-value
            [value]="data?.segment?.total?.spent"
          ></manage-table-plain-value>
        </ng-template>
        <ng-template #allocated>
          <budget-allocation-cell
            [allocated]="data?.total?.allocated"
            [spent]="null"
            [remaining]="data?.total?.remainingAllocated"
            [actionTooltipContext]="tooltipContext"
            [editable]="false"
            [showDifference]="true"
          >
          </budget-allocation-cell>
          <manage-table-plain-value
            [value]="data?.total?.spent"
          ></manage-table-plain-value>
        </ng-template>
        <ng-template #unallocated>
          <budget-allocation-cell
            [allocated]="data?.unallocated?.total?.allocated"
            [spent]="data?.unallocated?.total?.spent"
            [actionTooltipContext]="tooltipContext"
            [editable]="false"
            [showDifference]="true"
            [allowNegative]="true"
          >
          </budget-allocation-cell>
          <manage-table-plain-value
            [value]="data?.unallocated?.total?.spent"
          ></manage-table-plain-value>
        </ng-template>
      </manage-table-segment-breakdown>
    </div>
  </td>
</ng-container>

<ng-container *ngIf="spendingMode">
  <td class="table-col allocated-col spending-col" [ngClass]="utClasses.allocated">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!segmentBreakdown"
        [segmentValue]="data?.segment?.spending?.allocated"
        [allocatedValue]="data?.spending?.allocated"
        [unallocatedValue]="data?.unallocated?.spending?.allocated"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <th class="table-col spending-col total-expenses-col" [ngClass]="utClasses.totalExpenses">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!segmentBreakdown"
        [segmentValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? data?.segment?.spending?.totalExpensesWithPlanned
          : data?.segment?.spending?.totalExpenses"
        [allocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? data?.spending?.totalExpensesWithPlanned
          : data?.spending?.totalExpenses"
        [unallocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? data?.unallocated?.spending?.totalExpensesWithPlanned
          : data?.unallocated?.spending?.totalExpenses"
      >
      </plain-breakdown-value>
    </div>
  </th>
  <td class="table-col closed-col spending-col" [ngClass]="utClasses.closedExpenses">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!segmentBreakdown"
        [segmentValue]="data?.segment?.spending?.closed"
        [allocatedValue]="data?.spending?.closed"
        [unallocatedValue]="data?.unallocated?.spending?.closed"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col committed-col spending-col" [ngClass]="utClasses.committedExpenses">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!segmentBreakdown"
        [segmentValue]="data?.segment?.spending?.committed"
        [allocatedValue]="data?.spending?.committed"
        [unallocatedValue]="data?.unallocated?.spending?.committed"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col total-remaining-col spending-col" [ngClass]="utClasses.totalRemaining">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!segmentBreakdown"
        [segmentValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? data?.segment?.spending?.totalRemainingWithPlanned
          : data?.segment?.spending?.totalRemaining"
        [allocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? data?.spending?.totalRemainingWithPlanned
          : data?.spending?.totalRemaining"
        [unallocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? data?.unallocated?.spending?.totalRemainingWithPlanned
          : data?.unallocated?.spending?.totalRemaining"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col planned-col spending-col" [ngClass]="utClasses.plannedExpenses">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!segmentBreakdown"
        [segmentValue]="data?.segment?.spending?.planned"
        [allocatedValue]="data?.spending?.planned"
        [unallocatedValue]="data?.unallocated?.spending?.planned"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col children-allocated-col spending-col" [ngClass]="utClasses.allocatedToChildren">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!segmentBreakdown"
        [segmentValue]="data?.segment?.spending?.allocatedToChildren"
        [allocatedValue]="data?.spending?.allocatedToChildren"
        [unallocatedValue]="null"
        [withNullishPlaceholder]="true"
      >
      </plain-breakdown-value>
    </div>
  </td>
  <td class="table-col total-available-col spending-col" [ngClass]="utClasses.totalAvailable">
    <div class="table-cell">
      <plain-breakdown-value
        [allocatedOnly]="!segmentBreakdown"
        [segmentValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? data?.segment?.spending?.totalAvailableWithPlanned
          : data?.segment?.spending?.totalAvailable"
        [allocatedValue]="spendingModeFlags[SpendingModeFlag.PlannedExpensesInTotal]
          ? data?.spending?.totalAvailableWithPlanned
          : data?.spending?.totalAvailable"
        [unallocatedValue]="null"
        [withNullishPlaceholder]="true"
      >
      </plain-breakdown-value>
    </div>
  </td>
</ng-container>
