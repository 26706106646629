<ng-container *ngIf="showSettings">
  <button mat-icon-button [matMenuTriggerFor]="panel.menu" [disabled]="!budget" class="header-action-item with-icon">
    <fa-icon [icon]="['fas', 'gear']" [ngClass]="reauthRequiredForExternalIntegrations ? 'auth-expiry-notifier': ''"></fa-icon>
  </button>
  <menu-panel #panel [items]="items">
    <ng-template #iconsTemplate let-item>
      <icon-facebook *ngIf="item.customIcon === IntegrationSource.FacebookAds" class="custom-integration-icon" [ngClass]="item.customCssClass"></icon-facebook>
      <icon-linkedin *ngIf="item.customIcon === IntegrationSource.LinkedinAds" class="custom-integration-icon" [ngClass]="item.customCssClass"></icon-linkedin>
      <icon-google-ads *ngIf="item.customIcon === IntegrationSource.GoogleAds" class="custom-integration-icon" [ngClass]="item.customCssClass"></icon-google-ads>
      <icon-salesforce *ngIf="item.customIcon === IntegrationSource.Salesforce" class="custom-integration-icon"></icon-salesforce>
      <fa-icon *ngIf="item.customIcon === IntegrationSource.Hubspot" [icon]="['fab', 'hubspot']" class="hubspot-icon"></fa-icon>
      <fa-icon *ngIf="item.customIcon === 'sso'" [icon]="['fas', 'key']" class="sso-icon"></fa-icon>
      <icon-shared *ngIf="item.customIcon === 'shared-cost'" [state]="sharedIconState"></icon-shared>
      <icon-attributes *ngIf="item.customIcon === 'attributes'"></icon-attributes>
      <fa-icon *ngIf="item.customIcon === 'integrations'" [icon]="['fas', 'plug']" [rotate]="90" class="integrations-icon"></fa-icon>
    </ng-template>
  </menu-panel>  
</ng-container>