import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modal-window',
  styleUrls: ['./modal-window.component.scss'],
  templateUrl: './modal-window.component.html'
})

export class ModalWindowComponent {
  @Input() title: string;
  @Output() close: EventEmitter<any> = new EventEmitter();
}
