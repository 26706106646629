import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
 name: 'smallNumber'
})
export class SmallNumber implements PipeTransform {
  /**
   * Define if we should display fractional digits for rounded values
   * So that:
   *  - 1,050,000 should become 1.1M (with fractional part)
   *  - 1,049,000 should become 1M (no fractional part)
   *  - 999,500 should become 1M (no fractional part)
   *  - 999,499 should become 999.5K (with fractional part)
   */
  private shouldShowFractional(value: number): boolean {
    if (!value) {
      return false;
    }

    const fractional = value % 1;
    return fractional >= 0.05 && fractional < 0.95;
  }

  transform(number: any, decimal: 0 | 1 | 2 = 1) {
    if ( number ) {
      if (number != Math.floor(number)) {
        number = parseFloat(number).toFixed(2);
      }
      let abs = Math.abs( number );
      if ( abs >= Math.pow( 10, 12 ) ) {                                         // trillion
        number = ( number / Math.pow( 10, 12 ) );
        if (this.shouldShowFractional(number)) {
          number = number.toFixed(decimal) + "T";
        } else {
          number = number.toFixed(0) + "T";
        }
      } else if ( abs < Math.pow( 10, 12 ) && abs >= Math.pow( 10, 9 ) ) {        // billion
        number = ( number / Math.pow( 10, 9 ) );
        if (this.shouldShowFractional(number)) {
          number = number.toFixed(decimal) + "B";
        } else {
          number = number.toFixed(0) + "B";
        }
      } else if ( abs < Math.pow( 10, 9 ) && abs >= Math.pow( 10, 6 ) ) {        // million
        number = ( number / Math.pow( 10, 6 ) );
        if (this.shouldShowFractional(number)) {
          number = number.toFixed(decimal) + "M";
        } else {
          number = number.toFixed(0) + "M";
        }
      } else if ( abs < Math.pow( 10, 6 ) && abs >= Math.pow( 10, 3 ) ) {        // thousand
        number = ( number / Math.pow( 10, 3 ) );
        if (this.shouldShowFractional(number)) {
          number = number.toFixed(decimal) + "K";
        } else {
          number = number.toFixed(0) + "K";
        }
      }
      return number;
    }
    else {
      return number;
    }
  }
}
