<div class="items-wrap" [@animateScale]>
  <ng-container *ngFor="let action of actions">
    <button
      mat-menu-item
      class="action"
      *ngIf="!action?.hidden"
      [ngClass]="action.customCssClass"
      [class.is-disabled]="action.disabled"
      [disabled]="action.disabled"
      (click)="!action.disabled && action.handler()"
    >
      <fa-icon [icon]="action.icon"></fa-icon>
      <span class="text">{{ action.title }}</span>
    </button>
  </ng-container>
</div>
<div class="backdrop" (click)="closeActions.emit()"></div>
