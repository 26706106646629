import { BudgetTimeframeDataItem } from '../../../dashboard/widget.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { Component, inject, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Budget } from 'app/shared/types/budget.interface';
import { ActionMenuItem } from '@shared/components/actions-menu/actions-menu.component';
import { Router } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { ExportDataService } from 'app/dashboard/export-data.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { DatePipe } from '@angular/common';
import { ManageCegViewMode } from '@manage-ceg/types/manage-ceg-page.types';
import { BudgetCegTimelineService } from '@manage-ceg/services/budget-ceg-timeline.service';
import { FilterName, FilterSet } from '../../../header-navigation/components/filters/filters.interface';
import { FilterManagementService } from '../../../header-navigation/components/filters/filter-services/filter-management.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'budget-ceg-timeline-container',
  templateUrl: './budget-ceg-timeline-container.component.html',
  styleUrls: ['./budget-ceg-timeline-container.component.scss'],
  providers: [
    BudgetCegTimelineService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetCegTimelineContainerComponent implements OnInit {

  @Input() set selectedBudget (budget: Budget | null) {
      this.onSelectBudget(budget);
  };
  @Input() data: BudgetTimeframeDataItem[];
  @Input() companyCurrency: { code: string; symbol: string };
  @Input() viewMode: ManageCegViewMode;

  public budgetDates: {from: string, to: string};

  private readonly utilityService = inject(UtilityService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly router = inject( Router);
  private readonly configuration = inject(Configuration);
  private _selectedBudget: Budget;
  FilterName = FilterName;
  currentFilters: FilterSet;

  ngOnInit(): void {
    this.filterManagementService.currentFilterSet$.pipe(
        tap(filterSet => this.updateFilters(filterSet))
      ).subscribe();
  }

  private updateFilters(filterSet: FilterSet): void {
    this.currentFilters = filterSet;
  }

  public timelineWidgetActionItems: ActionMenuItem[] = [
    {
      text: 'Edit Budget',
      value: 'editBudget',
      cssClass: 'edit',
      onClick: this.redirectToSetting.bind(this)
    },
    {
      text: 'Download Image',
      value: 'downloadImage',
      cssClass: 'export',
      onClick: this.downloadBudgetTimelineImage.bind(this)
    },
  ];

  private  downloadBudgetTimelineImage(): void {
    this.utilityService.showLoading(true);
    window.setTimeout(() => { // To allow the menu to be closed
      ExportDataService.downloadWidgetGraphAsImage(
        'budgetTimeLine',
        { width: 2000, height: 1500 },
        'image/png',
        'spending_timeline.png',
        'SPENDING TIMELINE',
        this.companyDataService.selectedCompanySnapshot.name + ' | ' + this._selectedBudget.name,
        this.getOrganizeByText(),
        this.getTimeframeText(),
        ExportDataService.showHiddenTexts, false).then(
          () => {
            this.utilityService.showLoading(false);
          });
    }, 50);
  }

  private redirectToSetting(): void {
    this.router.navigate(
      [this.configuration.ROUTING_CONSTANTS.BUDGET_SETTINGS, {budget_id: this._selectedBudget.id}],
      { skipLocationChange: true }
    );
  }

  private onSelectBudget(budget: Budget): void {
    if (!budget) return;
    this._selectedBudget = budget;
    this.budgetDates = {
      from: this._selectedBudget.budget_from,
      to: this._selectedBudget.budget_to
    };
  }

  private getOrganizeByText(): string {
    return `Organize by: ${this.viewMode.charAt(0).toUpperCase() + this.viewMode.slice(1)}`
  }

  private getTimeframeText(): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(this.budgetDates['from'], 'MMMM yyyy') + ' - ' +
      datePipe.transform(this.budgetDates['to'], 'MMMM yyyy');
  }
}
