import { Component, Input, inject } from '@angular/core';
import { SpendingTimelineDataItem } from './spending-timeline.types';
import { SpendingTimelineService } from './spending-timeline.service';
import { BudgetTimeframeDataItem } from '../widget.service';
import { SelectedValue } from 'app/shared/types/select-groups.interface';
import { BudgetAmountsSummaryData } from '../budget-amounts-summary/budget-amounts-summary.component';
import { BudgetAmountsSummaryService } from 'app/shared/services/budget-amounts-summary.service';
import { prepareBudgetObjectTotals } from 'app/shared/utils/budget.utils';
import { BudgetObjectTotals } from 'app/shared/types/budget-object-totals.interface';
import { BudgetCegTimelineService } from '@manage-ceg/services/budget-ceg-timeline.service';

@Component({
  selector: 'spending-timeline',
  templateUrl: './spending-timeline.component.html',
  styleUrls: ['./spending-timeline.component.scss'],
  providers: [SpendingTimelineService]
})
export class SpendingTimelineComponent {
  timelineItems: SpendingTimelineDataItem[];
  selectedStatuses: string[];
  summaryTooltipData: BudgetAmountsSummaryData[];
  private readonly budgetCegTimelineService = inject(BudgetCegTimelineService);

  @Input() cegFlagEnabled = false;
  @Input() isManageTableTimeline = false;
  @Input() companyCurrency: string;
  @Input() selectedTimeframeIds: SelectedValue[] = [];
  @Input() set selectedStatusNames(selectedStatuses: string[]) {
    this.selectedStatuses = selectedStatuses;
    if (!this.timelineItems || !this.timelineItems.length) {
      return;
    }
    this.updateDataForDisplay();
  };

  @Input() set data(dataItems: BudgetTimeframeDataItem[]) {
    this.summaryTooltipData = dataItems.map(item => {
      const budgetObjectTotals: BudgetObjectTotals = {
        remaining_allocated: item.budget_remaining_amount,
        available: item.budget_remaining_amount,
        closed: item.closed,
        committed: item.committed,
        overdue: item.overdue,
        planned: item.planned,
        total: item.total,
        under_budget: item.under_budget,
        actual: item?.spent
      };
      let currencyCode: string;
      this.budgetCegTimelineService.companyCurrency$.subscribe(value => currencyCode = value['code']);
      this.companyCurrency = currencyCode;
      return this.amountsSummaryService.createTooltipData({
        item: prepareBudgetObjectTotals(budgetObjectTotals, item.locked),
        title: item.company_budget_alloc_name,
        currency: this.companyCurrency,
      }, true, this.cegFlagEnabled)
    });
    this.timelineItems = this.spendingTimelineService.convertRawDataToToSpendingTimelineData(dataItems);
    this.updateDataForDisplay();
  };

  constructor(
    private readonly amountsSummaryService: BudgetAmountsSummaryService,
    private readonly spendingTimelineService: SpendingTimelineService
  ) {
  }

  updateDataForDisplay() {
    if (!this.selectedStatuses || !this.selectedStatuses.length) {
      return;
    }
    this.timelineItems.forEach(item => {
      item.spending = item.spending.filter(sp => this.selectedStatuses.includes(sp.id));
      item.totalSpent = item.spending.reduce((total, sp) => total + sp.val, 0);
      item.overSpend = item.totalSpent > item.budget ? item.totalSpent - item.budget : 0;
    })
  }

}
