import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import * as _ from 'underscore';

@Directive({
  selector: '[multiTitle]'
})
export class MultiTitleDirective implements OnChanges {
  @Input('selectedData') selectedData;
  @Input('defaultSelectText') defaultSelectText;
  @Input('changeTrigger') changeTrigger;
  constructor( private renderer: Renderer2, private el: ElementRef) {}

  ngOnChanges() {
    let self = this;
    setTimeout(function() {
      const title = (self.selectedData.length > 0) ? _.pluck(self.selectedData, 'itemName').join(', ') : self.defaultSelectText;
      self.renderer.setAttribute(self.el.nativeElement, 'title', title);
    }, 200);
  }

}
