import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-favorite',
  templateUrl: './icon-favorite.component.html',
  styleUrls: ['./icon-favorite.component.scss']
})
export class IconFavoriteComponent {
  @Input() size = 24;
  @Input() color = '#e5e5e5';
  @Input() textTooltip = '';
}
