import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { getDiff } from 'recursive-diff';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TooltipContext } from '@shared/directives/dynamic-portal-tooltip.directive';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { MetricFunnelsPageService } from '../../services/metric-funnels-page.service';
import { MetricFunnelRow, MetricMaster, MetricRowsDifferences, MetricValueType } from '@common-lib/lib/corporate-page/metric-funnels.types';
import { MetricFunnelsValidationService } from '@common-lib/lib/utils/metric-funnels-validation.service';

@Component({
  selector: 'standalone-metrics',
  templateUrl: './standalone-metrics.component.html',
  styleUrls: ['./standalone-metrics.component.scss']
})
export class StandaloneMetricsComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() panelExpanded: boolean;
  @Output() setEditMode = new EventEmitter<boolean>();
  @Output() saveData: EventEmitter<MetricRowsDifferences> = new EventEmitter<MetricRowsDifferences>();
  @Output() openObjectsUsingMetric = new EventEmitter<MetricMaster>();
  public metricsLength = 0;
  public prevState: MetricMaster[];
  public newState: MetricMaster[];
  public isMetricChanged = false;
  public tooltipPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
  }
  public tooltipContext: TooltipContext = {
    header: 'Standalone Metrics',
    body: `These count metrics track activities (such as impressions or blog views)
      which happen before things tracked inside of a product metric funnel (such as leads or deals).
      Standalone metrics are not connected directly to currency metrics.`,
    icon: ['fas', 'graduation-cap']
  };
  public isFunnelValid = true;
  private metricChanges: MetricRowsDifferences = {
    create: [],
    delete: [],
    update: []
  }
  public metricValueType = MetricValueType;

  @Input() set metrics(metrics: MetricMaster[]) {
    this.prevState = createDeepCopy(metrics);
    this.newState = createDeepCopy(metrics);
    this.metricsLength = metrics?.length || 0;
  };

  constructor(
    private readonly metricFunnelsPageService: MetricFunnelsPageService,
    public readonly funnelsValidationService: MetricFunnelsValidationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editMode) {
      if (!changes.editMode.previousValue && changes.editMode.currentValue) {
        this.funnelsValidationService.updateFlatMetrics(this.createMetricRows(this.newState));
      }
      if (changes.editMode.previousValue && !changes.editMode.currentValue) {
        this.funnelsValidationService.resetFunnelErrors();
      }
    }
  }

  edit(e: MouseEvent, panel) {
    this.setEditMode.emit(true);
    if (panel.expanded) {
      e.stopPropagation();
    }
  }

  save(e: MouseEvent) {
    e.stopPropagation();
    this.metricChanges.create = this.newState.filter(metric => !metric.id);
    this.reorderMetrics();
    this.checkMetricUpdated();
    this.isMetricChanged = false;
    this.saveData.emit(this.metricChanges);
    this.metricChanges = { create: [], delete: [], update: [] };
  }

  cancel(e: MouseEvent) {
    e.stopPropagation();
    this.newState = createDeepCopy(this.prevState);
    this.isMetricChanged = this.checkMetricUpdated();
    this.setEditMode.emit(false);
  }

  onMetricChanged(value: string | number, index: number, field: string) {
    this.newState[index][field] = value;
    this.isMetricChanged = this.checkMetricUpdated();
    this.isFunnelValid = this.funnelsValidationService.isFunnelValid();

    if (!!this.newState[index]?.id && !this.metricChanges.update.some(item => item.id === this.newState[index].id)) {
      this.metricChanges.update.push(this.newState[index]);
    }
  }

  addMetricRow(index: number) {
    const metric = {
      id: null,
      name: '',
      target: 0,
      usageCount: 0,
      valueType: MetricValueType.Count,
      isHidden: false,
      rowIndex: index
    };
    this.newState.splice(index, 0, metric);
    this.funnelsValidationService.pushNewMetric(metric);
    this.isMetricChanged = this.checkMetricUpdated();
  }

  deleteMetric(metric: MetricMaster, index: number): void {
    this.newState.splice(index, 1);
    if (!!metric.id) {
      this.metricChanges.delete.push(metric.id);
    }
    this.checkDeletedMetric(metric);
    this.isMetricChanged = this.checkMetricUpdated();
    this.funnelsValidationService.updateFlatMetrics(this.createMetricRows(this.newState));
  }

  checkMetricUpdated(): boolean {
    const diffResults = getDiff(this.prevState, this.newState);
    return !!diffResults.length;
  }

  dropMetric(event: CdkDragDrop<MetricMaster[], any>): void {
    moveItemInArray(this.newState, event.previousIndex, event.currentIndex);
    this.isMetricChanged = this.checkMetricUpdated();
  }

  checkDeletedMetric(metric: MetricMaster): void {
    const index = this.metricChanges.update.findIndex(item => item.id === metric.id);
    if (index > -1) {
      this.metricChanges.update.splice(index, 1);
    }
  }

  addMetricForUpdate(metric: MetricMaster): void {
    const hasMetric = this.metricChanges.update.some(item => item.id === metric.id);
    if (!hasMetric && !!metric.id) {
      this.metricChanges.update.push(metric);
    }
  }

  reorderMetrics(): void {
    this.newState = this.newState.map((metric, index) => {
      if (metric.rowIndex !== index) {
        metric.rowIndex = index;
        this.addMetricForUpdate(metric);
      }
      return metric;
    });
  }

  onDeleteMetricClick(metric: MetricMaster, index: number) {
    const isUsed = !!metric.usageCount;
    if (isUsed) {
      this.metricFunnelsPageService.showMetricConfirmDialog(isUsed, () => this.deleteMetric(metric, index));
      return;
    }
    this.deleteMetric(metric, index);
  }

  metricErrorStateChanged(hasError: boolean, metric: MetricMaster) {
    this.funnelsValidationService.setMetricNameErrorState(hasError, metric)
  }

  createMetricRows(rows: MetricMaster[]): MetricFunnelRow[] {
    return (rows || []).map(row => {
      const metric = {};
      metric[row.valueType === 'count' ? 'countMetric' : 'currencyMetric'] = row;
      return metric;
    });
  }

}
