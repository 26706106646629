<section class="home-header">
  <div class="user-block">
    <div class="user" *ngIf="(userManager.currentUser$ | async).first_name as userFirstName">{{ userFirstName }}'s home</div>
    <div class="date" *ngIf="today">{{ today | ordinalDate: 'LLLL ORD, yyyy' }}</div>
  </div>
  <div class="budget">
    <ng-container *ngIf="budget?.id">
      <div class="budget-status">
        <span *ngIf="budget?.status">[{{ budget?.status }}]</span>
        {{ budget?.name }}
      </div>
      <div class="budget-dates">{{ startDate | date: budgetDateFormat }} - {{ endDate | date: budgetDateFormat }}</div>
    </ng-container>
    <ng-container *ngIf="!isLoading && !budget?.id">
      <div class="budget-status">No Budget Yet</div>
      <div class="budget-notice" *ngIf="userManager.currentCompanyUser$ | async as companyUser">
        <ng-container *ngIf="!companyUser.is_admin">Please, reach out to your Admin</ng-container>
        <button
          *ngIf="companyUser.is_admin"
          mat-flat-button
          (click)="setupBudget()"
        >
          Set Up a Budget
        </button>
      </div>
    </ng-container>
  </div>
</section>
