<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 216 79.2" enable-background="new 0 0 216 79.2" xml:space="preserve">
<g>
<g>
 <g>
   <g>
     <g>
       <defs>
         <path id="SVGID_1_" d="M27.711,4.331C14.315,3.229,2.383,13.171,0.907,26.537c0,0-0.05,0.494-0.057,0.58
           c-0.271,4.322,2.959,8.147,7.3,8.571c4.31,0.421,8.247-2.827,8.858-7.071c0.006-0.124,0.015-0.248,0.027-0.373
           c0.012-0.123,0.027-0.248,0.045-0.37c0.614-4.379,4.454-7.588,8.852-7.457c0.042-0.003,0.088-0.004,0.137-0.005l0.007-0.001
           c-0.017,0-0.031-0.001,0.003-0.001c4.485,0,8.122-3.636,8.122-8.122C34.202,8.362,31.415,5.087,27.711,4.331z"/>
       </defs>
       <clipPath id="SVGID_00000171694058378119097440000012008694763072748683_">
         <use xlink:href="#SVGID_1_"  overflow="visible"/>
       </clipPath>
       <g clip-path="url(#SVGID_00000171694058378119097440000012008694763072748683_)">
         <g>
           <defs>

               <rect id="SVGID_00000181785491717789306000000000817742774002991530_" x="-4.166" y="-0.752" width="43.368" height="41.477"/>
           </defs>
           <clipPath id="SVGID_00000101786383132556790090000005410653714454794131_">
             <use xlink:href="#SVGID_00000181785491717789306000000000817742774002991530_"  overflow="visible"/>
           </clipPath>
           <g clip-path="url(#SVGID_00000101786383132556790090000005410653714454794131_)">
             <defs>

                 <rect id="SVGID_00000088103322698908771490000011998113525559686065_" x="-4.166" y="-0.752" width="43.368" height="41.477"/>
             </defs>
             <clipPath id="SVGID_00000148625361022599886040000007581805741188828294_">
               <use xlink:href="#SVGID_00000088103322698908771490000011998113525559686065_"  overflow="visible"/>
             </clipPath>
             <g clip-path="url(#SVGID_00000148625361022599886040000007581805741188828294_)">

                 <image overflow="visible" width="92" height="87" xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAALlAAADzgAABi3/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAFoAXgMBIgACEQEDEQH/
xAC6AAABBQEBAAAAAAAAAAAAAAAAAgMEBQYBBwEAAwEBAQAAAAAAAAAAAAAAAwQFAAIBEAABBAIB
BQADAQAAAAAAAAADAAECBBIFERAgExQGQDEVIhEAAgEBAwYKCAcAAAAAAAAAAQIDEQBBEiAhMVEi
BDBhcZGh0eETIxQQgcEyQmKSM0CxUnLSkwUSAAEDAgIHBQkBAAAAAAAAAAEAEQISAyFBECAxYYGR
oXGxwSJy4TJCUmKCkhMjU//aAAwDAQACEQMRAAAA9AA2DkDriXUwYb8x2Elh9N6xo09k9Gf893km
m9zsBQ6YINyY8KfAaXhsPMuibQtBCcvKOwGfd52wpI5JZE4dKTD60bMMPstctJWghE3dfvFXKGuk
wVkF8RwoVo5zvpLbmv5ZxtlsxSnFlAk5l6nZY5+EnhxlMDnvru8o72dcAFXgDYgTzsWOh70ow8Re
3QNgAn2QDYA2/9oACAECAAEFAFCEpOGqNQENmnXDNjU/GeMXk8ItFhOopkeLSjXH/loKEeFF0ytH
ZpijwNmTI1hgxnsiO2cnlWI0oJlcK059B55R8/B/Yx6//9oACAEDAAEFAEQsBxNeK6cxJOM5YOK1
mIpIjhMsiSImTIMnZ9ifkrETz5TJlXE7wsydzplVryPMeuHF8I47ADjN01wHGLofxeObVOanpZ9f
/9oACAEBAAEFAOw9kFeNn6LFH3mzI8tlseW2uyhKl9XbE9S5Wuh7LexxR3lORWRFJOnWr2ZtbZ9o
Hq9LdvNSZFZGRHUk6fpVtOX5dbG1gzSTui/o6J+5J0/Sjn/LMWIRTPIk2IvIiE5Yr8oiknTp1Uqu
P5jfWMBZryLyqRFOXKm6knTrX0DbC16Vf0t8bK/knksk8k7qSkoVrBVS+Yv2Ho6+rrwrcvL+lyuV
yuU7p1pdaCrV7PowOO7yuVz1q15WbEWaLdm0oteqkhMU+z53WSFHu2Wpr32s6TY139ewq+r2Fha3
54deX4X/2gAIAQICBj8ATBPPznosIRHBNKEeTHmrcHJt3ZiIOeJxCYJhqRJ+G7bkPyCq+bu1bNob
Z3YGXYJKPpGkSIqJwATQjGG/3iqyTU71ZugM4YcMtIiNkO/PSKHq3LEWzxI8EWppzo26n//aAAgB
AwIGPwBPI8Mymh/McysZzP3FPGcubjkpyYCduJJGSM5ZdSjKR9mpL6rcwfxQtg4Wxj2nVu3Dsjbm
B2kK6T/pLodJiDSIhyU8pGe7YqGFLM2TIzby3TUDvzGkzkGlcx+0bNJ/c1GbrySvAekHxCDmZll+
wARfg/XU/9oACAEBAQY/AMjFM4UXC88gsRu0Vfmk/ivXbNNgGpAB06bV81N/Y3XbEN7mqNbsRzE2
C74onjvYUVxzZjYTbs4dDp1qdTC45JSDOb36rFnJZjpJznKE0e0hzSR1oGXrF1vN4vAwd5i+WmL0
mOM7HxNr7OB33djp3do8P7ZJFb8wfR3CHab3+IauC/0wK4MMNdVe9FPbZ5W0ICeyzSOasxqeC37e
WBHfvGF41jdRUeskWjgBzucTci9vBJu8QzHO7XKt5NvI4fAwd3S/DSnPYpXNGqrTjO17eAHdRO+L
RhUmvJSwaem7RnTizv8ASPbbut2WgPvMc7MdbH0T4tNRzYRToy45sNd4lUMzsNpcQrhGrJE3wzKD
X5l2T0Uyo4E0yMByC8+oWCqKACgHEMlohQSLtRsbm7bNHIMLoSrA3EZPnZhR3FIlNynS3ry8R8OY
DZkF/E2sW+0ZVuaPa6NNvtP9Jt4UDU/UwwjnalhNvZEsg0R0qg5a6fwf/9k=" transform="matrix(0.48 0 0 0.48 -4.5948 -0.7789)">
               </image>
             </g>
           </g>
         </g>
       </g>
     </g>
   </g>
   <g>
     <g>
       <defs>
         <path id="SVGID_00000048461533679738312470000006969812104303183806_" d="M26.08,20.411l-0.003,0.001
           C26.097,20.412,26.121,20.412,26.08,20.411z"/>
       </defs>
       <clipPath id="SVGID_00000011000263088755400100000003316013343723304081_">
         <use xlink:href="#SVGID_00000048461533679738312470000006969812104303183806_"  overflow="visible"/>
       </clipPath>
       <g clip-path="url(#SVGID_00000011000263088755400100000003316013343723304081_)">
         <g>
           <defs>

               <rect id="SVGID_00000172423362283997440490000014932627110583203200_" x="21.077" y="15.411" width="10.025" height="10.001"/>
           </defs>
           <clipPath id="SVGID_00000124120411293659287120000014501546457570377113_">
             <use xlink:href="#SVGID_00000172423362283997440490000014932627110583203200_"  overflow="visible"/>
           </clipPath>
           <g clip-path="url(#SVGID_00000124120411293659287120000014501546457570377113_)">
             <defs>

                 <rect id="SVGID_00000109029342053765129080000001560277808392668067_" x="21.077" y="15.411" width="10.025" height="10.001"/>
             </defs>
             <clipPath id="SVGID_00000072265178280089655550000004446378471810888595_">
               <use xlink:href="#SVGID_00000109029342053765129080000001560277808392668067_"  overflow="visible"/>
             </clipPath>
             <g clip-path="url(#SVGID_00000072265178280089655550000004446378471810888595_)">

                 <image overflow="visible" width="22" height="22" xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAAG9AAAB5gAAAof/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABcAGQMBIgACEQEDEQH/
xACQAAEBAQEAAAAAAAAAAAAAAAAFAAYDAQEAAwAAAAAAAAAAAAAAAAACAQMFEAABAwUBAQAAAAAA
AAAAAAAEAgMFAAESExQjJBEAAgADBgUFAAAAAAAAAAAAAQIREgMAEFFhEwQhMXGRMkHxIlIzEgAB
AwMCBwEAAAAAAAAAAAABAAISESFBMWGBkdEiQoITA//aAAwDAQACEQMRAAAA2QfU7Qwn18dsYRYF
FqvULf/aAAgBAgABBQBhpGGhG4bbj77v/9oACAEDAAEFADCnrvWMe5ZDm2fFyf/aAAgBAQABBQCT
kUhN3l5G6ouXUQup3LvoXLqqYFFIpTK0qiQ2G3q//9oACAECAgY/AASA4uFb3QtYtLo7rEcV1WJU
9Yr/2gAIAQMCBj8Ac0OcxrDEBppplHuMh+gZPNCCedl5fS04acd15fOXvPqv/9oACAEBAQY/AAFA
as8ZVPoPsbBtYiHIAKB2hYbfcQ1D4OOE2RGNxm5SLL097qMnnqLL1iIXAtWSjuFHxnYLMuBjnaUl
I5OhHcNCy1a9akag/Kkrqxmx4G7/2Q==" transform="matrix(0.48 0 0 0.48 20.8452 15.0611)">
               </image>
             </g>
           </g>
         </g>
       </g>
     </g>
   </g>
 </g>
 <g>
   <g>
     <defs>
       <path id="SVGID_00000055665096776161698610000002114507715974974393_" d="M34.351,28.917c0.004,0.187,0.01,0.373,0.002,0.562
         c0.005-0.035,0.015-0.069,0.021-0.104C34.364,29.223,34.353,29.071,34.351,28.917z"/>
     </defs>
     <clipPath id="SVGID_00000065038550745194508670000010837883801597897106_">
       <use xlink:href="#SVGID_00000055665096776161698610000002114507715974974393_"  overflow="visible"/>
     </clipPath>
     <g clip-path="url(#SVGID_00000065038550745194508670000010837883801597897106_)">
       <g>
         <defs>

             <rect id="SVGID_00000168807598555376652400000004320289278246704042_" x="29.351" y="23.916" width="10.02" height="10.563"/>
         </defs>
         <clipPath id="SVGID_00000013159899330746662790000009033216642986907067_">
           <use xlink:href="#SVGID_00000168807598555376652400000004320289278246704042_"  overflow="visible"/>
         </clipPath>
         <g clip-path="url(#SVGID_00000013159899330746662790000009033216642986907067_)">
           <defs>

               <rect id="SVGID_00000045582243297262622870000015262958702101157791_" x="29.351" y="23.916" width="10.02" height="10.563"/>
           </defs>
           <clipPath id="SVGID_00000104692104145787395490000003833490968696815008_">
             <use xlink:href="#SVGID_00000045582243297262622870000015262958702101157791_"  overflow="visible"/>
           </clipPath>
           <g clip-path="url(#SVGID_00000104692104145787395490000003833490968696815008_)">

               <image overflow="visible" width="22" height="23" xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAAHJAAAB/AAAAqb/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABkAGQMBIgACEQEDEQH/
xACYAAADAQEAAAAAAAAAAAAAAAAEBQYABwEAAQUAAAAAAAAAAAAAAAAAAgABAwQFEAAABQMDBQAA
AAAAAAAAAAABAgMEBQATBhASFCIjMyQVEQACAgEBBgcBAAAAAAAAAAABAhEDABAhQVFxYgQxgZHB
EiIzFBIAAQQBAQgDAAAAAAAAAAAAAQARIQISMVFhcYGRodFCAyMz/9oADAMBAAIRAxEAAACuhnqP
aoUdPzroopeC+FxIAHOxv//aAAgBAgABBQBgVkRKyy5Sm6uvb//aAAgBAwABBQBZ4BT8ztvLF31u
N//aAAgBAQABBQCcmkIZqrnWQmVxrLvqKVlLUzqRHH1D01jVGzipJleECAANI+6tor5tP//aAAgB
AgIGPwAH5q0ve0nOW3BCzfUaG2DxkCO0oM69tF//2gAIAQMCBj8AtUlhUsjbZYB+L+EZsLe0OH6o
zf8AQOWD5NEPpzX/2gAIAQEBBj8AF1imy2yVpqEAswEyekbzhZGqrWTFYrBEc2k5/J3yJR3TfkyS
K36fsSQfPboJGxK1UerN758gNmVWICXrdWWOKmRotyCWUQw4jxyIxbGWK1Myd8bhqeY1/9k=" transform="matrix(0.48 0 0 0.48 29.0052 23.7011)">
             </image>
           </g>
         </g>
       </g>
     </g>
   </g>
 </g>
 <g>
   <g>
     <defs>
       <polygon id="SVGID_00000137114353181723497280000006281062558279343529_" points="50.459,30.908 50.459,30.912 50.459,30.911
                   "/>
     </defs>
     <clipPath id="SVGID_00000095335910897869692970000005658437806800768654_">
       <use xlink:href="#SVGID_00000137114353181723497280000006281062558279343529_"  overflow="visible"/>
     </clipPath>
     <g clip-path="url(#SVGID_00000095335910897869692970000005658437806800768654_)">
       <g>
         <defs>
           <rect id="SVGID_00000088813847733610499400000002046032367155643570_" x="45.459" y="25.908" width="10" height="10.002"/>
         </defs>
         <clipPath id="SVGID_00000117634961603993979430000006043517919230151347_">
           <use xlink:href="#SVGID_00000088813847733610499400000002046032367155643570_"  overflow="visible"/>
         </clipPath>
         <g clip-path="url(#SVGID_00000117634961603993979430000006043517919230151347_)">
           <defs>
             <rect id="SVGID_00000057866567528820743540000017632878520131803069_" x="45.459" y="25.908" width="10" height="10.002"/>
           </defs>
           <clipPath id="SVGID_00000106137564173101122710000000723817118471184020_">
             <use xlink:href="#SVGID_00000057866567528820743540000017632878520131803069_"  overflow="visible"/>
           </clipPath>
           <g clip-path="url(#SVGID_00000106137564173101122710000000723817118471184020_)">

               <image overflow="visible" width="22" height="22" xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAAHFAAAB7gAAApX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABkAFwMBIgACEQEDEQH/
xACXAAADAQAAAAAAAAAAAAAAAAAABQYEAQADAQAAAAAAAAAAAAAAAAADBAUCEAACAQQBBQAAAAAA
AAAAAAADBAIBExQFEgAQIyQVEQABAwMBAw0AAAAAAAAAAAABAhIDABExIWFSBBAgQVFxkaGx8UIT
IzMSAAEEAQIEBwAAAAAAAAAAAAEAEQISITFBUWHRE8EiQoKiA0P/2gAMAwEAAhEDEQAAALFVlz0I
NKIAbLZPZGwIx4Bb/9oACAECAAEFABAHSGPG8G9w81//2gAIAQMAAQUAYdPUueTEbope9TC//9oA
CAEBAAEFANltIJU++9yW20GFdjWcnulJSiTcaohJ4rPJLUlEt3//2gAIAQICBj8ADgSJDklV9NbN
4IYiRtY5ZbW+LdF//9oACAEDAgY/AJVkYRjIgAcuKv8Apft2bGjvwUs/YC/moAYvvqQt+2/vv1X/
2gAIAQEBBj8ACEh8yhcJ6AOs06yLbrTbzvU0qUtmhQVqjJzYXuNlTleXkDsGifDkW3V0UoUMaMV6
0eK4ZLif0QM6e4Uz4Vu3Wm/dap5ZU/euJaYoxkOSc7TzP//Z" transform="matrix(0.48 0 0 0.48 45.3252 25.6211)">
             </image>
           </g>
         </g>
       </g>
     </g>
   </g>
 </g>
</g>
<path fill="#5E40F2" d="M87.68,27.09c-3.389,0-6.435,1.416-8.451,4.29h-0.086v-3.646h-4.076v31.703h4.462V48.069h0.086
 c1.545,2.574,4.762,4.032,8.365,4.032c6.821,0,12.012-5.491,12.012-12.183C99.993,32.582,94.759,27.09,87.68,27.09z M87.509,48.026
 c-4.633,0-8.066-3.818-8.066-8.451c0-4.419,3.432-8.408,7.894-8.408c4.762,0,8.194,4.161,8.194,8.494
 C95.531,44.208,92.1,48.026,87.509,48.026z"/>
<rect x="103.636" y="19.755" fill="#5E40F2" width="4.462" height="31.746"/>
<path fill="#5E40F2" d="M133.365,31.08h-0.086c-1.844-2.702-4.762-3.99-8.237-3.99c-6.992,0-12.441,5.32-12.441,12.913
 c0,6.864,5.663,12.098,12.613,12.098c3.389,0,6.392-1.458,8.065-4.29h0.086v3.689h4.461V27.734h-4.461V31.08z M125.171,48.026
 c-4.462,0-8.109-3.904-8.109-8.365c0-4.676,3.646-8.494,8.109-8.494c4.933,0,8.28,3.861,8.28,8.494
 C133.45,44.165,129.804,48.026,125.171,48.026z"/>
<path fill="#5E40F2" d="M154.726,27.09c-2.746,0-5.191,0.901-6.949,3.175h-0.086v-2.531h-4.161v23.767h4.462V38.502
 c0-4.461,2.359-7.336,6.263-7.336c3.947,0,5.921,2.359,5.921,7.379v12.956h4.461V37.258
 C164.635,30.694,160.431,27.09,154.726,27.09z"/>
<path fill="#5E40F2" d="M171.326,26.661v1.073h-2.102v4.076h2.102v19.691h4.461V31.81h4.161v-4.076h-4.161v-1.115
 c0-1.759,1.244-2.789,4.161-2.789v-4.075h-0.986C174.543,19.755,171.326,22.114,171.326,26.661z"/>
<path fill="#5E40F2" d="M200.497,40.561c0,4.591-2.102,7.465-6.221,7.465c-4.076,0-5.92-2.403-5.92-7.379V27.734h-4.462V40.99
 c0,8.365,4.805,11.111,9.91,11.111c3.132,0,5.835-1.158,6.993-3.175h0.086v2.574h4.076V27.734h-4.461V40.561z"/>
<rect x="210.703" y="19.755" fill="#5E40F2" width="4.462" height="31.746"/>
<g>
 <g>
   <defs>
     <path id="SVGID_00000013873532906792644280000005046638846810913980_" d="M50.502,29.71c-0.639,3.847-4.049,7.073-8.077,7.073
       c-0.275,0-0.546-0.014-0.813-0.039c-0.097-0.009-0.19-0.028-0.286-0.04c-0.168-0.022-0.338-0.042-0.502-0.073
       c-0.117-0.022-0.23-0.054-0.345-0.081c-0.138-0.033-0.278-0.062-0.413-0.102c-0.128-0.037-0.251-0.084-0.376-0.127
       c-0.116-0.04-0.234-0.077-0.348-0.122c-0.135-0.053-0.264-0.115-0.395-0.175c-0.098-0.045-0.198-0.087-0.293-0.136
       c-0.138-0.07-0.271-0.149-0.405-0.227c-0.081-0.047-0.164-0.093-0.243-0.143c-0.141-0.089-0.276-0.186-0.411-0.284
       c-0.064-0.046-0.129-0.091-0.192-0.139c-0.143-0.11-0.279-0.227-0.413-0.346c-0.048-0.042-0.097-0.083-0.143-0.127
       c-0.141-0.131-0.274-0.268-0.405-0.409c-0.034-0.037-0.07-0.073-0.103-0.111c-0.135-0.152-0.263-0.309-0.386-0.471
       c-0.024-0.031-0.048-0.062-0.071-0.093c-0.127-0.172-0.246-0.35-0.359-0.533c-0.015-0.024-0.03-0.048-0.044-0.072
       c-0.116-0.192-0.222-0.389-0.321-0.592c-0.008-0.017-0.017-0.035-0.025-0.052c-0.101-0.21-0.192-0.426-0.275-0.647
       c-0.004-0.011-0.008-0.022-0.012-0.032c-0.084-0.227-0.157-0.459-0.22-0.697c-0.002-0.006-0.003-0.011-0.004-0.017
       c-0.064-0.242-0.116-0.489-0.157-0.74c0-0.002,0-0.003-0.001-0.005c-0.041-0.255-0.071-0.513-0.088-0.776
       c-0.006,0.035-0.016,0.069-0.021,0.104c-0.007,0.15-0.017,0.3-0.032,0.452c-0.015,0.152-0.034,0.302-0.056,0.451
       c-0.68,4.533-4.816,7.799-9.429,7.349c-3.644-0.356-6.54-2.929-7.495-6.251c-0.224-0.781-0.287-1.599-0.287-2.411v-0.041
       c0-2.225-0.91-4.247-2.377-5.714c-1.465-1.465-3.488-2.375-5.712-2.375c-4.118,0-7.627,3.125-8.112,7.119
       c-0.008,0.053-0.049,1.353,0.024,2.564v36.243c0,2.224,0.91,4.246,2.376,5.712c1.451,1.45,3.446,2.356,5.642,2.375
       c4.489,0.039,8.16-3.898,8.16-8.387l0-14.171c1.957,0.723,4.038,1.212,6.212,1.424c13.489,1.316,25.508-8.413,27.088-21.813
       c0.019-0.168,0.037-0.336,0.054-0.504c0.019-0.196,0.035-0.393,0.051-0.588c0-0.001,0-0.002,0-0.003
       C50.468,30.602,50.482,30.158,50.502,29.71z"/>
   </defs>
   <clipPath id="SVGID_00000054230597468903671260000013352928950413020824_">
     <use xlink:href="#SVGID_00000013873532906792644280000005046638846810913980_"  overflow="visible"/>
   </clipPath>
   <g clip-path="url(#SVGID_00000054230597468903671260000013352928950413020824_)">
     <g>
       <defs>

           <rect id="SVGID_00000096036429738519786780000015886608930410955924_" x="-4.165" y="15.938" width="59.667" height="64.014"/>
       </defs>
       <clipPath id="SVGID_00000062902300187389477270000015311721844590477969_">
         <use xlink:href="#SVGID_00000096036429738519786780000015886608930410955924_"  overflow="visible"/>
       </clipPath>
       <g clip-path="url(#SVGID_00000062902300187389477270000015311721844590477969_)">
         <defs>

             <rect id="SVGID_00000179634198174669571300000000783289795517210272_" x="-4.165" y="15.938" width="59.667" height="64.014"/>
         </defs>
         <clipPath id="SVGID_00000101066004844702645220000005436891167163991968_">
           <use xlink:href="#SVGID_00000179634198174669571300000000783289795517210272_"  overflow="visible"/>
         </clipPath>
         <g transform="matrix(1 0 0 1 0 9.536743e-07)" clip-path="url(#SVGID_00000101066004844702645220000005436891167163991968_)">

             <image overflow="visible" width="126" height="135" xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAANUAAAFAQAACOP/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAIoAfwMBIgACEQEDEQH/
xAC9AAABBQEBAAAAAAAAAAAAAAAAAQIDBQYHBAEBAQADAQEAAAAAAAAAAAAAAAEDBAUCBhAAAQQC
AQMEAgMBAAAAAAAAAQACAwQRBQYQIDASEzM1MiVAITQVEQACAQICBAsFBwUAAAAAAAABAgMRBAAS
ITGBExAgMEFRYXGxIjJzkaFyIwXRQlJighQ0waKyQ2MSAAECAwUGBQUAAAAAAAAAAAERAgASAxAh
MUGxIFFhcSJykaEyYhNggcHRUv/aAAwDAQACEQMRAAAA6BC3mmy01Xn16s1Wn5dJidcKe45FAPIA
AAADE5W2q/oI1VXMRVce3pvJupctPV2PLcN12j5Rqs82Pl9XLdS6u849Z7k91dZ12/WuVckRyuE0
+bXE0eWmaQPIsku8xOzxYfX57rwlr7GvyUcjshXI+Bw6RrZUTzxemM8rPRGQbfNdN51554PfX79c
5jvZ72OJHNdI5FJI45mHnb6dhhs9sHCvN6/3130R7o3e0j4nSTPh0eNSrferCythq/bqvB7w59AP
LmldZVn0ccrF9pHRKS9P5Z1PmpgOXQAAAAMDnulc17cFabccrVPT1PKa3jUA0qAAAAAUV6ZnLfB2
Gv7U5tq9Y7ARQ41AAAAAAD//2gAIAQIAAQUAq1XTFtOBoloRPEjHMd2VmBkHTZsGaVZszrdKNkdW
v7z56DBHD8XSWFkrYoWRNIBEcUcYsu9MMPxd2xmw2H4u2xYZC173PdD8XSSaONOu1wpdiU57nlQ/
F02f49lGYPh6bCYPk7K/u+4w2MWTb9Hb/9oACAEDAAEFAFlZ8IRWemf4A8uFjuHaeo7/AOu7/9oA
CAEBAAEFAFZtV6kN/ncTHP5tunOq88tNOs3Wv2jfFeuwUKu221va2emFDLLBJxzdja1fDzq859nC
wsLCAWkvHX7LpteQ6/Vuh5xTc+CeGxCr+xpa2Ac/0hkh2lCejyV5k3uFhYQCAQCpSe7TlkEUVmeW
zOVwe68TLlexkv7jC0uwkqLkI/dYWEAgEAg1aPlDK1fecojs1yEQq1uxSsXObbaeuQiFrIhJZ5AP
3WFhAIBAIBYRCLU4JwTgi1ELjdUyR8g+5wgEAgEAgFhelFqc1OanNRai1aXXGnxHf/coIIBAIBAL
CLU5qc1Oai1afVv2mw9mL2d/9yggggggOhCcE5qc1NhfK/j+mZqqi3/3KCCCCCHUhOCZDJNJoeOs
1/Xf/c5QKBQKBQKBQ6FUdBfurW6enrm9eQH91lAoFAoFByg4pZkibxKfMPE6zVW1Guq93IT+6yso
FAoOXqVT/L4OSsdHvMrKygUCsqp/l8HNaph2+VlZWVlUq77ltrWsb4ORaf8A61BzXMd1yuGaZ7PH
veL1dor+j2mvcqtG5cdpeGFjgAB5L/zM/Dx//9oACAECAgY/ACVlY3E/gQkgPF18dA+N3DDwgscE
LdmmB/IJ5m+1j8yrT9sIcXrKzdmTBqU1bLiFW6JSUa0KTBdTmmaFQ3rDOxulsrwuoiVgTM7zBBCg
wQxoauKRUPsI8bop9jdNsUhiep3LKKfY3TaU3uPpbvgvcVLoZ2N0tE7pVwj1ryBhKTU9zv1Bc4lx
OZsp9jdLafN2yGr1U+k8srQxt4p6nHZHwrNw3ceEdbaRPBxHkkGQMAzkJLvMDa//2gAIAQMCBj8A
+iP/2gAIAQEBBj8Aw09zIsUS62Y+7DJ9Pt95TVLKcq1+AaabRiq7lB+FUNP7mJwBd2yOvOYiUPsY
titrJ8wCrRP4XXZz7K8nJdXBpHGKnpJ1ADtODPOaINEcQPhRegdfSeIssLtHIhqrqaEbRgrLQXcI
AmAFAwOp17efr2clDYKflxLvXHS7VC+xe/jQ3NaRg5ZQOdG0N7NfDupSZJ6V3UYqRXVmJoBik9tJ
GtaBlIfRzEjw4SeBxJFIKqw1EcH7i9lEUdaCuksehQNJOMm7uAtaZ8i5e3z192G+oQzB7SNWd5FB
OUIMzVWmaoHNSuLxjSocLo6FVVHdx7eWpO8iRqnWcyg6cPK2qNSx7FFcSXEprJKxdj1nguLFiSjL
vUB1AqQrU7cw9nBP480FuxihUHwgLoYj4iOC8t8x3F3azxuldGbdOUanTUU24vPUPcOOtpfBikYy
xSqKkKNSsOrmw9pYqwWQZZJW0HKdaqOvUeFLm2cpKhqrD3g4MMSx27sKPLGDm/TmJy8MgIJC29y2
jpWCRh78XnqHuHL/AFW4I8MFjOK/mkUge4Ni79Q9w5e+mkWkt3BNL15N2wT7duLv1D3Dlo7VdCee
Zvwxr5vsHXjcZRusuTJzZaZcvsxd+oe4cqscal3c0VQKkk8wxRwDdTUaZujoQfDXgu/UPcOUWKJC
8jaFVRUnYMC5uaPdkeEaxHUaadfXw3fqHuHJhsu5hP8AsfRo6l1nHyVzSkUaZtLHqHQOziXnqHuH
HSQzoudQ2Wh0VFaYFbhAOchScfOnd/hATvzYBigXMPvt42r2tWmzjXnqHuHHh9Ne4cjeK2suG2Mq
sPcePD6af4jkRcCuW5jVq/mTwEewDjQ20YJaVgujmB1nYMBVFFUUA6AORMaUFxES8J6TTyfqwUcF
WUkMpFCCNYI4p+qXClcwK2ynRoPmk26ht6uTM8JEF5zyfden4x/Xvw37i3bIuneoC8dOnMNW3gy2
sDzHnyKSB2nUNeFuPqpBKmq2ykEV/wCjaj2D282KDQBqHKj+L5R/I8+s6urC6tQ8urZyn//Z" transform="matrix(0.48 0 0 0.48 -4.5948 15.5411)">
           </image>
         </g>
       </g>
     </g>
   </g>
 </g>
</g>
<g>
 <g>
   <g>
     <defs>
       <path id="SVGID_00000023246612906560838000000006420539176213272723_" d="M50.483,27.688
         C49.947,15.643,40.462,5.566,28.092,4.359c-0.128-0.012-0.255-0.017-0.383-0.028c3.705,0.755,6.492,4.03,6.492,7.958
         c0,4.485-3.636,8.122-8.122,8.122c-0.076,0-0.15-0.009-0.225-0.011v0.014c0.222,0.005,0.443,0.01,0.668,0.032
         c4.22,0.412,7.439,3.801,7.797,7.881c0.012,0.251,0.028,0.405,0.028,0.517c0,0.18,0.015,0.354,0.027,0.531v0
         c0.017,0.263,0.046,0.522,0.088,0.776c0,0.002,0,0.003,0.001,0.005c0.041,0.251,0.093,0.498,0.157,0.74
         c0.001,0.004,0.002,0.008,0.003,0.011c0.001,0.002,0.001,0.004,0.002,0.006c0.063,0.237,0.136,0.469,0.22,0.697
         c0.004,0.011,0.008,0.022,0.012,0.032c0.082,0.221,0.174,0.436,0.275,0.646c0.008,0.018,0.017,0.035,0.026,0.053
         c0.099,0.202,0.206,0.4,0.321,0.591c0.015,0.024,0.03,0.048,0.044,0.072c0.113,0.183,0.232,0.36,0.359,0.533
         c0.023,0.031,0.047,0.062,0.071,0.093c0.123,0.162,0.251,0.319,0.386,0.471c0.034,0.038,0.069,0.074,0.104,0.112
         c0.131,0.141,0.264,0.278,0.405,0.409c0.024,0.022,0.049,0.043,0.073,0.064c0.024,0.021,0.047,0.042,0.071,0.063
         c0.135,0.119,0.271,0.236,0.413,0.346c0.014,0.011,0.029,0.021,0.043,0.031c0.049,0.036,0.099,0.072,0.148,0.108
         c0.135,0.098,0.27,0.194,0.411,0.284c0.079,0.05,0.162,0.095,0.243,0.143c0.133,0.078,0.266,0.156,0.404,0.227
         c0.024,0.012,0.05,0.022,0.074,0.035c0.073,0.034,0.146,0.068,0.219,0.102c0.131,0.06,0.26,0.122,0.395,0.175
         c0.114,0.045,0.232,0.082,0.348,0.122c0.125,0.043,0.248,0.09,0.376,0.127c0.135,0.039,0.275,0.069,0.413,0.102
         c0.115,0.027,0.228,0.059,0.344,0.081c0.166,0.032,0.336,0.052,0.506,0.074c0.094,0.012,0.187,0.03,0.282,0.039
         c0.267,0.025,0.538,0.039,0.813,0.039c4.002,0,7.399-3.085,8.071-6.897c0.006-0.235,0.024-0.472,0.025-0.706
         C50.531,29.007,50.498,28.029,50.483,27.688z"/>
     </defs>
     <clipPath id="SVGID_00000093888529730076984180000004207939133926315955_">
       <use xlink:href="#SVGID_00000023246612906560838000000006420539176213272723_"  overflow="visible"/>
     </clipPath>
     <g clip-path="url(#SVGID_00000093888529730076984180000004207939133926315955_)">
       <g>
         <defs>

             <rect id="SVGID_00000083083365400233270740000002806615348185089201_" x="20.855" y="-0.669" width="34.666" height="42.452"/>
         </defs>
         <clipPath id="SVGID_00000172416636801405908090000016288380744972998333_">
           <use xlink:href="#SVGID_00000083083365400233270740000002806615348185089201_"  overflow="visible"/>
         </clipPath>
         <g clip-path="url(#SVGID_00000172416636801405908090000016288380744972998333_)">
           <defs>

               <rect id="SVGID_00000175321365405341472960000008318606829291794587_" x="20.855" y="-0.669" width="34.666" height="42.452"/>
           </defs>
           <clipPath id="SVGID_00000103957042176914302850000017615289921535441855_">
             <use xlink:href="#SVGID_00000175321365405341472960000008318606829291794587_"  overflow="visible"/>
           </clipPath>
           <g clip-path="url(#SVGID_00000103957042176914302850000017615289921535441855_)">

               <image overflow="visible" width="73" height="89" xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAALMAAADtQAABpz/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAFwASwMBIgACEQEDEQH/
xAC6AAABBQEBAAAAAAAAAAAAAAAAAQMFBgcCBAEAAwEBAQEAAAAAAAAAAAAAAwQFAQIABhAAAQQC
AQUBAQEAAAAAAAAAAgABAwQFBhEQIBITFCMWMREAAgEABwQHBAkFAQAAAAAAAQIDABEhMRITBCBR
MhQQQWGBsUJScZEigjDwwdFickODBaGiI1NzNRIAAQMBBAYKAwAAAAAAAAAAAQARAgMQIUESMVFh
sYITIIGRocHRIjJCUmKSI//aAAwDAQACEQMRAAAAvec3HMXV5KVrPTQNInMdlwF0w8PuQZAM1uga
GE4xon4Gql12jjAntFzb2DJpwy9DoQ9Nag3F3um3XVnHUcbEiqE5s9uzDSUGqzRdm5mNY69NRVdD
pznpwKCm+Sw173B70YD5qvVanpOdWp6KLQUQVN8kt59BVM4B89VIWaDDzRnTIO5Op8hZprnfJ7Ai
0P/aAAgBAgABBQCpGLs8MZKSjyiFxdRTFG8ReQiys1WmDxfyhiDxjZAK4UkDDejsODQcEI/4pB5N
Y6RiibpJM30Kv7vbHLY4lltO37e7/9oACAEDAAEFAJzdnaQ2QWUzs7I4xNG3i7uoZnjLluJJC5J0
7rlDI71jhYnk5En6A/Aq2LsfQAf1KX1+BBFyAQ8/n6//2gAIAQEAAQUAs2YalfKbdlr0sOw5yF6e
9ZaJ8dt+JvOzs7dm4DIWvdGTLEbFkcW+Ky9PKwdZoYrEOV0nJVpSAgJkzJmVS1Ypz4bMQZWr2buN
cc6KFkzJmWMvy465Vsw2662DY6uEim3nPTMcsk0goWTMuFwtNyPia3WQz2NkLIGQCmZcdKdgqtr6
oFuOsz5A5IJoDEUAoG7fp/BSRxyhs+IhxuREULdvtP5Om71ncGZN2/AXzdMtjxyNE4ziNuzEY2TJ
XPRD2Z3X48krNK1Tk6Y3B3siWNxtbG1+2x8/qs/x6pfyvLccdP/aAAgBAgIGPwAyIcu16vjHsT0z
wy80YyDEYGz046QVGTNmD2OLpx9p8CsrHM7Ni+pAkOSHv6FKYF1TMeKMUARmAUZjRIPbSl9Znvib
MnypnuNtGkDe8pS/UtYOS+fw27F/SgQfwnE7ympUGP2nKO518udm4nX/2gAIAQMCBj8AEQWudXSK
aY6wnBcGy9EarL/adPmne7S6IBZruhOOMG7CU4LIxOFsxriN4szYS3i2pM7AO0WHme1emqOKJ8k8
6j7Ig72Xx5bdTL//2gAIAQEBBj8Ak1M7YYolLO19g9lDkStpIPJHExVqvxOKiTStNdMbQfjYyXf9
MVANSkepXrJGW570+H+2ixyMdLMfLLUFr7HFnvqpWLQbjs6rLJFWAsAK61xrX9+yEjfN09dsElq/
Kb1+tlM3TtU4AzIjxoTv+/YeCZQ8UilXU9akVEUeTQjmtNWSgBAlVdxU31dl+6hRwVdSQykVEEXg
jYXUaZzHKlxHgd4oJEIWdABPF1q28fhPVstk1YzEhnA/2W3/ACYdmPVReWx19SHiWkepgbFFIK1P
2HtHQuNTNqZQTFCDVYPM56h4++jBXjhxXZacPsxlqNLKxeRyWd2NZJN5J2pP42Q2PXJDX6gPjXvF
vcejUqxrEYjVBuGWrVe9j9BDqVvicPVvqNo76cX6ebceDfdRf5HQLj1CgJNCLC4FzDtHhTLnjaJx
ejqVbdcfoM3EP/Iyq/3smr7OgxyKHRuJWAIPtBoOXAWCdcaIPKbmFvv28HVy2Du5nM8enS6kCxS0
bn81TL4Nt1W4+Q5irs5jFu9H1q6ZNMeIjFGdzjho0cilHQ1MpsII37KQKP8AGPimf0oL+83CnAOD
L+T07HMQkR6tQBWeFwPV29tMrVRNE/ViFh/Kbj3dIMaZcBvmexauz1d1MmAVk2ySHic9u03NYMnz
ZlWCrtxWUOZlcX6WO/8Aa6v6UXl+XxWYczirrsqzra6DDd1VXVdP/9k=" transform="matrix(0.48 0 0 0.48 20.8452 -0.7789)">
             </image>
           </g>
         </g>
       </g>
     </g>
   </g>
 </g>
</g>
</g>
</svg>