<article class="root-layout">
	<navigation></navigation>
  <div class="right-side">
    <div class="box-topBar">
      <h4 class="box-pageHeadingBar">Planful | Superadmin Management</h4>
      <a class="profile-component ml-a" [routerLink]="['', { outlets: { primary: 'user', details: null } }]" queryParamsHandling="preserve">
        <profile-menu theme='blue' [disabled]="true" ></profile-menu>
      </a>
    </div>
    <div class="right-side-content">
      <div class="box-manageTeam">
        <ul class="list-assOwner">
          <li>
            <span>God Mode:</span> <em title="{{ godModeUserName }}">{{ godModeUserName }}</em>
          </li>
        </ul>
      </div>
      <div class="box-tableManageExpense">
        <div class="box-tableManageExpenseInn">
          <table class="table-manageTeam">
            <thead>
            <tr class="table-main-tr">
              <th class="user-header">User Name</th>
              <th class="email-header">Email</th>
              <th class="permission-header">Permission</th>
              <th class="login-header">Last Login</th>
              <th class="options-header">Options</th>
            </tr>
            </thead>
            <tbody>
            <tr class="table-main-tr" *ngFor="let admin of superadminsList">
              <td>
                <div class="frow-manageTeam">
                  <figure class="frow-manageTeamImg box-inputAnimationTooltip">
                    <span *ngIf="admin.user_image != null">
                      <img id="myimage" [src]="(admin.profilePicUrl | async) || ''" onerror="this.src='assets/images/dummy-userBlueBG.png'" alt="">
                    </span>
                    <span *ngIf="admin.user_image == null" getInitials [firstName]="admin.first_name" [lastName]="admin.last_name"></span>
                    <div class="graph-tooltip profile-tooltip"><span>{{ admin.first_name }} {{ admin.last_name }}</span> <i></i></div>
                  </figure>
                  <div class="frow-manageTeamName">
                    <span class="table-contOverflow" title="{{ admin.first_name }} {{ admin.last_name }}">{{ admin.first_name }} {{ admin.last_name }}</span>
                  </div>
                </div>
              </td>
              <td>{{admin.email}}</td>
              <td>{{ (admin.user_type == 'super_admin_user') ? 'Superadmin' : 'God Mode' }}</td>
              <td>{{admin.last_login | date : 'MM-dd-yyyy, h:mm a'}}</td>
              <td>
                <ul class="list-btnTable">
                  <li><button class="btn-tableEdit" type="button" (click)="editSuperadmin(admin.id)" *ngIf="isGodMode && currentUserDetails?.id != admin.id"></button></li>
                  <li><button class="btn-tableDelete" type="button" (click)="deleteSuperadmin(admin.id)" *ngIf="isGodMode && currentUserDetails?.id != admin.id"></button></li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="box-buttonQue" *ngIf="isGodMode">
        <button class="btn-navBlue btn-addUser btnBG-navBlue" type="button" (click)="addSuperadmin()">ADD NEW</button>
        <button class="btn-navBlue btn-addUser btnBG-navBlue" type="button" (click)="openSuperadminLogs()">View Logs</button>
      </div>
    </div>
  </div>
</article>
