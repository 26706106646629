import { Component, Input } from '@angular/core';
import { SharedIconState } from 'app/shared/components/icons/icon-shared/shared.type';
import { SharedCostRule, SharedCostRuleSegment } from 'app/shared/types/shared-cost-rule.interface';

@Component({
  selector: 'shared-cost-rules-tooltip',
  templateUrl: './shared-cost-rules-tooltip.component.html',
  styleUrls: ['./shared-cost-rules-tooltip.component.scss']
})
export class SharedCostRulesTooltipComponent {
  @Input() tooltipContext: {id: number, name: string, segments: Array<SharedCostRule | SharedCostRuleSegment>};
  @Input() iconState: SharedIconState;

}
