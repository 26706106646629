import { Component, inject, Input } from '@angular/core';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';

@Component({
  selector: 'budget-status-icon',
  templateUrl: './budget-status-icon.component.html',
  styleUrls: ['./budget-status-icon.component.scss']
})
export class BudgetStatusIconComponent {
  private readonly budgetDataService = inject(BudgetDataService);
  public cegFlagEnabled = this.budgetDataService.selectedBudgetSnapshot?.new_campaigns_programs_structure;
  @Input() status = 'default';
  @Input() size = 14;
}
