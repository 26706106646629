<svg
  [attr.width]="width || size"
  [attr.height]="height || size"
  [attr.fill]="color"
  [style.display]="'block'"
  viewBox="0 0 14 13"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M13.5 2.75V11.5C13.5 12.2031 12.9141 12.75 12.25 12.75H3.5C2.79688 12.75 2.25 12.2031 2.25 11.5C2.25 10.8359 2.79688 10.25 3.5 10.25H9.20312L1.35156 2.39844C0.84375 1.92969 0.84375 1.10938 1.35156 0.640625C1.82031 0.132812 2.64062 0.132812 3.10938 0.640625L11 8.49219V2.75C11 2.08594 11.5469 1.5 12.25 1.5C12.9141 1.5 13.5 2.08594 13.5 2.75Z"/>
</svg>
