import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TotalsSidebarState } from '@manage-ceg/types/manage-ceg-page.types';

@Component({
  selector: 'expand-toggler',
  templateUrl: './expand-toggler.component.html',
  styleUrls: ['./expand-toggler.component.scss']
})
export class ExpandTogglerComponent {
  @Input() totalsSidebarState: TotalsSidebarState;
  @Output() toggleTotalsState = new EventEmitter<TotalsSidebarState>();
  protected sidebarState = TotalsSidebarState;

  protected toggleState(): void {
    const state = this.totalsSidebarState === TotalsSidebarState.Preview ? TotalsSidebarState.FullWidth : TotalsSidebarState.Preview;
    this.toggleTotalsState.emit(state);
  }
}
