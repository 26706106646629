import { inject, Injectable } from '@angular/core';
import {
  ManageTableActionDataSource,
  ManageTableData,
  ManageTableFullRowValues,
  ManageTableRow
} from '../components/manage-table/manage-table.types';
import { ManageTableDataBuilderInputs } from '../types/manage-table-data-inputs.type';
import { SortParams } from 'app/shared/types/sort-params.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { DefaultSorting } from '../constants/sorting.constants';
import { ManageTableHelpers } from './manage-table-helpers';
import { createDeepCopy, getNumericValue, roundDecimal, sumUpByNumericKey } from 'app/shared/utils/common.utils';
import { SegmentedObjectTimeframe } from 'app/shared/types/object-timeframe.interface';
import { Campaign, LightCampaign } from 'app/shared/types/campaign.interface';
import { Program } from 'app/shared/types/program.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { SegmentedBudgetObject } from 'app/shared/types/segmented-budget-object';
import { MetricMappingDO } from 'app/shared/services/backend/metric.service';
import { MetricsUtilsService } from 'app/budget-object-details/services/metrics-utils.service';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { MetricDetailsStateMapper } from 'app/budget-object-details/services/state-mappers/metric-mapping-state-mapper.service';
import { PerformanceColumnData } from '../types/performance-column-data.type';
import { Budget } from 'app/shared/types/budget.interface';
import { getTodaysDate } from 'app/shared/utils/date.utils';
import { ManageTableSpendingHelpers } from './manage-table-spending-helpers';
import { getTodayFixedDate } from '@shared/utils/budget.utils';
import { ManageTableFastDataBuilder } from './manage-table-fast-data-builder';
import { Subject } from 'rxjs';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

interface SourceObjects {
  campaigns: Campaign[];
  expenseGroups: Program[];
  sharedCostRules?: SharedCostRule[];
}

@Injectable()
export class ManageTableDataService {
  private readonly metricsUtilsService = inject(MetricsUtilsService);

  private _appliedSorting: SortParams = DefaultSorting;
  private _isLoading = true;
  private _isFilteredMode = false;
  private _currentDataBuilder: ManageTableFastDataBuilder;
  private _sourceObjects: SourceObjects = { campaigns: [], expenseGroups: [], sharedCostRules: [] };
  // Flat table data map to provide O(1) access by ID
  public flatDataMap: Record<string, ManageTableRow> = {};
  // Tree-like table data considering hierarchy
  public data: ManageTableData = [];
  public performanceColumnData: PerformanceColumnData = {};
  public grandTotal: ManageTableFullRowValues;
  public grandTotal$ = new Subject<ManageTableFullRowValues>();
  public hasHiddenHierarchy: boolean;

  private static shouldIncludeSourceChild(
    childId: number,
    childParentId: number,
    idList: number[],
    targetParentId: number,
    targetObjectId: number
  ): boolean {
    return !idList.includes(childId) && childParentId === targetParentId && childId !== targetObjectId;
  }

  private static someItemHasHierarchyInfo(rows: ManageTableRow[]): boolean {
    const hasHierarchyInfo = rows.some(row => !row.isFilteredOut && !!row.hierarchyInfo);
    if (hasHierarchyInfo) {
      return true;
    } else {
      for (const row of rows) {
        if (row?.children.length) {
          const childHasHierarchyInfo = ManageTableDataService.someItemHasHierarchyInfo(row.children);
          if (childHasHierarchyInfo) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public get isLoading() { return this._isLoading; }
  public get appliedSorting() { return this._appliedSorting; }
  public get isFilteredMode() { return this._isFilteredMode; }

  public initGrandTotal(timeframes: BudgetTimeframe[] = []) {
    const grandTotal: ManageTableFullRowValues = {
      values: ManageTableHelpers.initTimeframeValues(timeframes),
      total: {
        allocated: 0,
        spent: 0
      },
      spending: ManageTableSpendingHelpers.initSpendingValues(),
      ...ManageTableHelpers.initBreakdownValues()
    };

    const sumGrandTotalValues = (grandTotalStore: ManageTableFullRowValues, item: ManageTableRow | ManageTableFullRowValues) => {
      return {
        values: ManageTableHelpers.sumRecordValues(grandTotalStore.values, item.values),
        total: ManageTableHelpers.sumBudgetAllocationValues(grandTotalStore.total, item.total),
        spending: ManageTableSpendingHelpers.sumSpendingValues(grandTotalStore.spending, item.spending),
        segment: {
          values: ManageTableHelpers.sumRecordValues(grandTotalStore.segment.values, item.segment?.values),
          total: ManageTableHelpers.sumBudgetAllocationValues(grandTotalStore.segment.total, item.segment?.total),
          spending: ManageTableSpendingHelpers.sumSpendingValues(grandTotalStore.segment.spending, item.segment?.spending)
        },
        unallocated: {
          values: ManageTableHelpers.sumRecordValues(grandTotalStore.unallocated.values, item.unallocated?.values),
          total: ManageTableHelpers.sumBudgetAllocationValues(grandTotalStore.unallocated.total, item.unallocated?.total),
          spending: ManageTableSpendingHelpers.sumSpendingValues(grandTotalStore.unallocated.spending, item.unallocated?.spending)
        }
      };
    };

    const sumValuesFromVisibleChildren = (children: ManageTableRow[]) => {
      return children.reduce((reducedValues: ManageTableFullRowValues, child) => {
        if (child.isFilteredOut) {
          if (!child.children?.length) {
            return reducedValues;
          }
          const childrenSum = sumValuesFromVisibleChildren(child.children);
          return sumGrandTotalValues(reducedValues, childrenSum);
        }
        return sumGrandTotalValues(reducedValues, child);
      }, createDeepCopy(grandTotal));
    };

    const grandTotalData = this.data
      .reduce((result, row) => {
        if (row.type === ManageTableRowType.UnassignedExpenses) {
          return result;
        }
        const rowData = !row.isFilteredOut ? row : sumValuesFromVisibleChildren(row.children);

        return sumGrandTotalValues(result, rowData);
      }, grandTotal);
    this.refreshGrandTotalData(grandTotalData);
  }

  public refreshGrandTotalData(data?: ManageTableFullRowValues): void {
    this.grandTotal = data ? data : { ...this.grandTotal };
    this.grandTotal$.next(this.grandTotal);
  }

  private initTableData(dataInputs: ManageTableDataBuilderInputs) {
    this._currentDataBuilder = new ManageTableFastDataBuilder();
    const builderResult = this._currentDataBuilder.buildHierarchyData(dataInputs);

    this.data = builderResult.data;
    this.flatDataMap = builderResult.flatDataMap;
    this.hasHiddenHierarchy = ManageTableDataService.someItemHasHierarchyInfo(this.data);
  }

  private getExplicitChildrenIds(record: ManageTableRow): {
    explicitChildCampaignIds: number[];
    explicitChildExpGroupIds: number[];
  } {
    const explicitChildCampaignIds: number[] = [];
    const explicitChildExpGroupIds: number[] = [];

    record.children.forEach(child => {
      const childId = ManageTableHelpers.getObjectId(child.itemId);
      if (child.type === ManageTableRowType.Campaign) {
        explicitChildCampaignIds.push(childId);
      }
      if (child.type === ManageTableRowType.ExpenseGroup) {
        explicitChildExpGroupIds.push(childId);
      }
    });

    return {
      explicitChildCampaignIds,
      explicitChildExpGroupIds
    };
  }

  public setSourceObjects(sourceObjects: SourceObjects) {
    Object.keys(sourceObjects).forEach(key =>
      this._sourceObjects[key] = [...this._sourceObjects[key], ...sourceObjects[key]]
    );
  }

  public clearSourceObjects(): void {
    this._sourceObjects = {
      campaigns: [],
      expenseGroups: [],
      sharedCostRules: []
    };
  }

  public getSourceObjectForRecord(record: ManageTableRow): Campaign | Program {
    if (!this._sourceObjects || !record || record?.objectId) {
      return null;
    }

    const objectId = ManageTableHelpers.getObjectId(record.itemId);
    const { campaigns, expenseGroups } = this._sourceObjects;

    if (record.type === ManageTableRowType.Campaign) {
      return campaigns.find(item => item.id === objectId);
    }
    if (record.type === ManageTableRowType.ExpenseGroup) {
      return expenseGroups.find(item => item.id === objectId);
    }

    return null;
  }

  /**
   * Returns a list of record children that are not present in its own 'children' list
   * might due to filtering, segment mode, etc..
   */
  public getImplicitRecordChildren(parentRecord: ManageTableRow, targetRecord?: ManageTableRow): SegmentedBudgetObject[] {
    if (!this._sourceObjects || !parentRecord) {
      return null;
    }

    const objectId = ManageTableHelpers.getObjectId(parentRecord.itemId);
    const targetObjectId = targetRecord ? ManageTableHelpers.getObjectId(targetRecord.itemId) : null;
    const sourceChildren = [];
    const { explicitChildExpGroupIds, explicitChildCampaignIds } = this.getExplicitChildrenIds(parentRecord);
    const { campaigns, expenseGroups } = this._sourceObjects;

    if (parentRecord.type === ManageTableRowType.Campaign) {
      for (const item of campaigns) {
        if (ManageTableDataService.shouldIncludeSourceChild(item.id, item.parentCampaign, explicitChildCampaignIds, objectId, targetObjectId)) {
          sourceChildren.push(item);
        }
      }
      for (const item of expenseGroups) {
        if (ManageTableDataService.shouldIncludeSourceChild(item.id, item.campaignId, explicitChildExpGroupIds, objectId, targetObjectId)) {
          sourceChildren.push(item);
        }
      }
    }

    return sourceChildren;
  }

  public patchSourceObject(record: ManageTableRow, updatedObject: SegmentedBudgetObject) {
    const { campaigns, expenseGroups } = this._sourceObjects;
    const patchAmounts = (existingObject: SegmentedBudgetObject) => {
      if (!existingObject || !updatedObject) {
        return;
      }
      existingObject.amount = updatedObject.amount;
      existingObject.timeframes.forEach(tf => {
        const updatedTf = updatedObject.timeframes.find(item => item.id === tf.id);
        if (updatedTf) {
          tf.amount = updatedTf.amount;
        }
      });
    };

    let targetObject: SegmentedBudgetObject = null;
    if (record.type === ManageTableRowType.Campaign) {
      targetObject = campaigns.find(item => item.id === updatedObject.id);
    }
    if (record.type === ManageTableRowType.ExpenseGroup) {
      targetObject = expenseGroups.find(item => item.id === updatedObject.id);
    }
    if (targetObject) {
      patchAmounts(targetObject);
    }
  }

  public getSharedCostRule(ruleId: number): SharedCostRule {
    return this._sourceObjects.sharedCostRules?.find(rule => rule.id === ruleId);
  }

  public getRulesSegmentPercentage(scr: SharedCostRule, segmentId: number): number | null {
    const scrSegment = scr?.segments?.find(item => item.id === segmentId);
    if (!scrSegment) {
      return null;
    }

    return scrSegment.cost / 100;
  }

  public setLoading(value: boolean) {
    this._isLoading = value;
  }

  public setFilteredMode(value: boolean) {
    this._isFilteredMode = value;
  }

  public prepareData(dataInputs: ManageTableDataBuilderInputs) {
    this.initTableData(dataInputs);
    this.sortData();
    this.setLoading(false);
  }

  public sortData() {
    ManageTableHelpers.sortData(this.appliedSorting, this.data);
  }

  public applySorting(columnName: string) {
    if (this.appliedSorting.column === columnName) {
      this.appliedSorting.reverse = !this.appliedSorting.reverse;
    } else {
      this.appliedSorting.column = columnName;
      this.appliedSorting.reverse = false;
    }
    this.sortData();
  }

  public getDataSourceValue(dataSource: ManageTableActionDataSource, segmentValue = false): number {
    const { record, timeframe } = dataSource;
    const valuesSource = segmentValue ? record.segment : record;
    const valueObject = timeframe ? valuesSource.values[timeframe?.id] : valuesSource.total;

    return valueObject?.allocated || 0;
  }

  public getRecordAllocationsTotal(record: ManageTableRow): number {
    return roundDecimal(sumUpByNumericKey<SegmentedObjectTimeframe>(record.allocations, 'amount'), 2);
  }

  public getRecordTotalAllocated(record: ManageTableRow, sourceObject?: SegmentedBudgetObject): number {
    if (sourceObject) {
      return getNumericValue(sourceObject.amount);
    }

    return record.allocations?.length
      ? this.getRecordAllocationsTotal(record)
      : getNumericValue(record.total.allocated);
  }

  public getRecordById(id: string): ManageTableRow {
    return this.flatDataMap[id];
  }

  public getRecordByIdAndType(type: ManageTableRowType, id: number): ManageTableRow {
    const objectId = ManageTableHelpers.getRowObjectId(type, id);
    return this.getRecordById(objectId);
  }
  public objectExists(objType: ManageTableRowType, objId: number | string): boolean {
    return  this._currentDataBuilder?.objectExists(objType, objId);
  }

  public removePerformanceColumnEntry(campaignId: number) {
    delete this.performanceColumnData[campaignId];
  }

  public setPerformanceColumnData(
    metricMappings: MetricMappingDO[],
    metricCampaigns: LightCampaign[],
    budget: Budget,
    overwrite = true
  ) {
    const performanceData: PerformanceColumnData = metricMappings.reduce((data, mapping) => {
      const { value: metricValues } = MetricDetailsStateMapper.convertMetricCalculations(mapping.metric_calculations);
      const current = metricValues.length ? metricValues[metricValues.length - 1].value : 0;
      const target = mapping.projection_amount;
      const name = mapping.metric_detail.name;
      let milestones = MetricDetailsStateMapper.convertMetricDOMilestones(mapping.milestones);
      let startDate = mapping.start_date;

      if (!startDate || !milestones.length) {
        const targetCampaign = metricCampaigns.find(campaign => campaign.id === mapping.map_id);
        startDate = this.metricsUtilsService.getDefaultStartDate(startDate, targetCampaign, budget);
        milestones = this.metricsUtilsService.getDefaultMilestones(milestones, targetCampaign, mapping, budget);
      }

      const todayDate = getTodayFixedDate(budget);

      const estimatedTarget = this.metricsUtilsService.getEstimatedTarget(
        getTodaysDate(todayDate),
        parseDateString(startDate),
        milestones
      );
      const { state: progressState, diffShare } = this.metricsUtilsService.getProgressState(estimatedTarget, current);
      const estimatedDiffPercentage = roundDecimal(diffShare * 100, 2);

      return {
        ...data,
        [mapping.map_id]: {
          mappingId: mapping.id,
          current,
          target,
          milestones,
          name,
          startDate,
          progressState,
          estimatedTarget,
          estimatedDiffPercentage
        }
      };
    }, {});

    this.performanceColumnData = {
      ...(overwrite ? {} : this.performanceColumnData),
      ...performanceData
    };
  }
}
