import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  HostListener,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges, OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchControlComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder = 'Search';
  @Input() debounceTime = 350;
  @Output() textChanged = new EventEmitter<string>();

  public searchFilterControl = new UntypedFormControl('');
  private destroy$ = new Subject<void>();

  @Input() set currentSearch(val: string) {
    this.searchFilterControl.setValue(val, { emitEvent: false });
  };

  @Input() set disabled (isDisabled: boolean) {
    if (isDisabled) {
      this.searchFilterControl.disable({ emitEvent: false });
    } else {
      this.searchFilterControl.enable({ emitEvent: false });
    }
  }

  constructor() {
  }

  ngOnInit() {
    this.searchFilterControl.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe(value => this.textChanged.emit(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.placeholder && !changes.placeholder.firstChange) {
      this.clearSearchText();
    }
  }

  @HostListener('click', ['$event'])
  onHostClick(e) {
    e.stopPropagation();
  }

  clearSearchText(e?: MouseEvent) {
    e?.stopPropagation();
    this.searchFilterControl.setValue('');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
