<table>
  <thead>
    <tr>
      <th>
        <div class="d-flex ai-center">
          <ng-container [ngSwitch]="settings.integrationSource">
            <icon-google-ads class="d-flex" *ngSwitchCase="metricNames.GoogleAds"></icon-google-ads>
            <icon-linkedin class="d-flex" *ngSwitchCase="metricNames.LinkedinAds"></icon-linkedin>
            <icon-facebook class="d-flex" *ngSwitchCase="metricNames.FacebookAds"></icon-facebook>
            <icon-salesforce class="d-flex" *ngSwitchCase="metricNames.Salesforce" size="24px" color="#061F38"></icon-salesforce>
            <fa-icon class="d-flex" *ngSwitchCase="metricNames.Hubspot" [icon]="['fab', 'hubspot']"></fa-icon>
          </ng-container>
          <div class="ml-05 no-wrap">{{settings.integrationName}} Metric Name</div>
        </div>
      </th>
      <th>{{ settings?.descriptionColumnTitle || 'Description'}}</th>
      <th>Metric Unit Type</th>
      <th>
        <div class="d-flex ai-center">
          <icon-plannuh class="d-flex" size="24px" color="#061F38"></icon-plannuh>
          <div class="no-wrap">Mapped to Planful Metric Type</div>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of rows">
      <td>
        <b>{{ row.label }}</b>
      </td>
      <td>{{ row.description }}</td>
      <td class="no-wrap">{{ row.unitType }}</td>
      <td [ngClass]="{ 'not-mapped': !row.mappedPlannuhMetricTypes.length }">
        <mat-chip-list *ngIf="row.mappedPlannuhMetricTypes.length; else notMapped">
          <mat-chip *ngFor="let metric of row.mappedPlannuhMetricTypes"
                    [selectable]="false"
                    [disabled]="disabled"
                    (removed)="onRemoveMapping(metric, row)">
            <span *ngIf="metric.productName" class="mr-025 text-light">{{ metric.productName }}</span>
            <span>{{ metric.name }}</span>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>

        <icon-add-objects
          [isDisabled]="disabled"
          textTooltip="Map Metric"
          tooltipPosition="above"
          #dropdownTrigger="matMenuTrigger"
          [matMenuTriggerFor]="metricsDropdown"
        >
        </icon-add-objects>

        <mat-menu #metricsDropdown="matMenu" [class]="'metric-masters-list'">
          <metric-masters-list
            [options]="masterMetricsSelectItems[row.name]"
            [isPowerUser]="isPowerUser"
            (click)="$event.stopPropagation();"
            (closeDropdown)="closeAddMappingsDropdown(dropdownTrigger)"
            (addMetrics)="addMappings($event, dropdownTrigger, row)"
            (navigateToFunnelsPage)="navigateToFunnelsPage(dropdownTrigger)"
          ></metric-masters-list>
        </mat-menu>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #notMapped>
  <div class="placeholder">Not mapped.</div>
</ng-template>
