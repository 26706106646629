import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'select-hierarchy-item-banner',
  templateUrl: './select-hierarchy-item-banner.component.html',
  styleUrls: ['./select-hierarchy-item-banner.component.scss']
})
export class SelectHierarchyItemBannerComponent {
  @HostBinding('class.blurred')
  @Input() blurred: boolean;
  @Input() itemType = 'items';
}
