<div class="tiles-wrap" [ngClass]="{ 'quarter-view': !showLegend, 'range-mode': isRange }">
  <div class="legend" *ngIf="showLegend">
    <ng-container *ngIf="isRange; else labels">
      <ng-container *ngFor="let id of quarterIds">
        <link-button (onClick)="onQuarterClick(id)">Q{{ id }}</link-button>
      </ng-container>
    </ng-container>
    <ng-template #labels>
      <span>Q1</span>
      <span>Q2</span>
      <span>Q3</span>
      <span>Q4</span>
    </ng-template>
  </div>
  <div class="tiles" [@tilesAnimation]="timeframes.length">
    <label *ngFor="let timeframe of timeframes">
      <input
        class="tf-checkbox"
        [class.within-range]="isWithinRange(timeframe.id)"
        type="checkbox"
        [disabled]="timeframe.locked"
        [readOnly]="timeframe.selected"
        [checked]="selectedTimeframes.includes(timeframe.id)"
        (change)="onSelectionChange($event.target.checked, timeframe)"
      >
      <span class="tf-checkbox-label">{{ timeframe.title }}</span>
    </label>
  </div>
</div>
