import { Injectable } from '@angular/core';
import { ExpenseDetailsState } from '../../types/budget-object-details-state.interface';
import { DetailsObjectStateMapper } from '../../types/budget-object-state-mapper.interface';
import { ExpenseDO } from 'app/shared/types/expense.interface';
import { Configuration } from 'app/app.constants';

@Injectable()
export class ExpenseStateMapper implements DetailsObjectStateMapper {
  constructor(private readonly configuration: Configuration) {}

  stateToDataObject(state: Partial<ExpenseDetailsState>): Partial<ExpenseDO> {
    const dataObject: Partial<ExpenseDO> = {};

    if (state.hasOwnProperty('sourceAmount')) {
      dataObject.source_amount = state.sourceAmount;
    }
    if (state.hasOwnProperty('sourceActualAmount')) {
      dataObject.source_actual_amount = state.sourceActualAmount;
    }
    if (state.budgetAllocationId) {
      dataObject.company_budget_alloc = state.budgetAllocationId;
    }
    if (state.mode) {
      dataObject.mode = state.mode;
    }
    if (state.budgetId) {
      dataObject.budget = state.budgetId;
    }
    if (state.companyId) {
      dataObject.company = state.companyId;
    }
    if (state.segment && state.segment.segmentId) {
      dataObject.company_budget_segment1 = state.segment.segmentId;
      dataObject.split_rule = null;
    }
    if (state.segment && state.segment.sharedCostRuleId) {
      dataObject.split_rule = state.segment.sharedCostRuleId;
      dataObject.company_budget_segment1 = null;
    }

    if (state.hasOwnProperty('parentObject')) {
      dataObject.goal =
        state.parentObject && state.parentObject.type === this.configuration.OBJECT_TYPES.goal ?
          state.parentObject.id :
          null;

      dataObject.campaign =
        state.parentObject && state.parentObject.type === this.configuration.OBJECT_TYPES.campaign ?
          state.parentObject.id :
          null;

      dataObject.program =
        state.parentObject && state.parentObject.type === this.configuration.OBJECT_TYPES.program ?
          state.parentObject.id :
          null;
    }

    if (state.createdBy) {
      dataObject.created_by = state.createdBy;
    }
    if (state.ownerId) {
      dataObject.owner = state.ownerId;
    }
    if (state.typeId) {
      dataObject.expense_type = state.typeId;
    }
    if (state.name) {
      dataObject.name = state.name;
    }
    if (state.hasOwnProperty('notes')) {
      dataObject.note = state.notes;
    }
    if (state.hasOwnProperty('glCode')) {
      dataObject.gl_code = state.glCode;
    }
    if (state.hasOwnProperty('poNumber')) {
      dataObject.expense_po_no = state.poNumber;
    }
    if (state.hasOwnProperty('invoiceNumber')) {
      dataObject.invoice = state.invoiceNumber;
    }
    if (state.hasOwnProperty('vendor')) {
      dataObject.vendor = state.vendor;
    }
    if (state.currencyCode) {
      dataObject.source_currency = state.currencyCode;
    }
    if (state.deliveryDate) {
      dataObject.expense_delivery_date = state.deliveryDate;
    } else if (state.deliveryDate === '') {
      dataObject.expense_delivery_date = null;
    }
    if(state.isVerified) {
      dataObject.is_verified = state.isVerified
    }

    return dataObject;
  }

  dataObjectToState(dataObject: Partial<ExpenseDO>): Partial<ExpenseDetailsState> {
    return {
      objectId: dataObject.id || null,
      programId: dataObject.program || null,
      campaignId: dataObject.campaign || null,
      goalId: dataObject.goal || null,
      name: dataObject.name || null,
      amount: dataObject.amount || 0,
      actualAmount: dataObject.actual_amount || 0,
      sourceAmount: dataObject.source_amount || 0,
      sourceActualAmount: dataObject.source_actual_amount || 0,
      mode: dataObject.mode,
      budgetAllocationId: dataObject.company_budget_alloc,
      budgetId: dataObject.budget || null,
      companyId: dataObject.company || null,
      typeId: dataObject.expense_type || null,
      createdBy: dataObject.created_by || null,
      ownerId: dataObject.owner || null,
      glCode: dataObject.gl_code || null,
      poNumber: dataObject.expense_po_no || '',
      invoiceNumber: dataObject.invoice || '',
      notes: dataObject.note || '',
      segment: {
        segmentId: dataObject.company_budget_segment1 || null,
        sharedCostRuleId: dataObject.split_rule || null
      },
      vendor: dataObject.vendor || null,
      created: dataObject.crd,
      updated: dataObject.upd,
      currencyCode: dataObject.source_currency,
      attachmentMappings: dataObject.attachments || [],
      deliveryDate: dataObject.expense_delivery_date || '',
      source: dataObject.source || '',
      relationGroupId: dataObject.relation_group || null,
      externalId: dataObject.external_id || '',
      isVerified: dataObject.is_verified,
      recognizedFields: dataObject.extra_expense?.recognized_fields || {},
      pdfUrl: dataObject.extra_expense?.pdf_highlighted_file_url,
      bodyEmailContents: dataObject.extra_expense?.body_of_email_contents || null
    }
  }
}
