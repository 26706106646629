<div class="la-ball-spin-clockwise-fade" [ngStyle]="{ 'color': color }">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
