import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent {
  @Input() disabled = false;
  @Output() onClick = new EventEmitter();
}
