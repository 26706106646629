import { Injectable } from '@angular/core';
import {
  CustomFilterModeType,
  FilterManagementService,
  ParamsDef
} from '../../header-navigation/components/filters/filter-services/filter-management.service';
import { FilterName } from '../../header-navigation/components/filters/filters.interface';
import { LocalSpendingPageFilters } from '../types/expense-page.type';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { LightCampaign } from '@shared/types/campaign.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';

@Injectable()
export class ExpensesQueryService {
  constructor(
    private filterManagementService: FilterManagementService,
    private budgetDataService: BudgetDataService,
  ) {}

  public getExpenseBasePayload(
    localParams: LocalSpendingPageFilters,
    includeNested: boolean,
    includePseudoObjects: boolean,
    invoicesOnly: boolean,
  ): Record<string, string | boolean> {
    const isRelatedExpensesModeActive = this.filterManagementService.customFilterMode?.modeType === CustomFilterModeType.RelatedExpenses;

    const budget = this.budgetDataService.selectedBudgetSnapshot;
    if (!budget) {
      return;
    }
    const allCampaigns = this.budgetDataService.lightCampaignsSnapshot || this.budgetDataService.campaignsSnapshot;
    const budgetSegmentList = this.budgetDataService.segmentsSnapshot;

    const payloadDefault: Record<string, string | boolean> = {
      company: budget.company.toString(),
      budget: budget.id.toString(),
      include_nested: includeNested,
      include_pseudo_objects: includePseudoObjects,
      ordering: localParams?.ordering || null,
      search: localParams?.search || null,
    };

    // clear from empty values
    Object.keys(payloadDefault).forEach((k) => payloadDefault[k] == null && delete payloadDefault[k]);
    const filters = this.getQueryFromHeaderFilters(allCampaigns, budgetSegmentList);

    if (isRelatedExpensesModeActive) {
      payloadDefault.relation_group = this.filterManagementService.customFilterMode?.context?.relationGroupId;
    }

    if (invoicesOnly) {
      payloadDefault.is_verified = false;
    }
    return { ...filters, ...payloadDefault };
  }

  private getQueryFromHeaderFilters(allCampaigns: LightCampaign[], budgetSegmentList: BudgetSegmentAccess[]) {
    const queryObj: {[key: string]: string} = {};

    const requestParamsDef: ParamsDef = {
      'company_budget_allocation_ids': { filterName: FilterName.Timeframes },
      'goal_ids': { filterName: FilterName.Goals },
      'tag_ids': { filterName: FilterName.Tags },
      'campaign_ids': {
        filterName: FilterName.Campaigns,
        defaultValue: () => this.filterManagementService.getFilterCampaignIds(allCampaigns)
      },
      'campaign_type_ids': { filterName: FilterName.CampaignTypes },
      'program_ids': { filterName: FilterName.ExpenseBuckets },
      'gl_code_ids': { filterName: FilterName.GlCodes },
      'vendor_ids': { filterName: FilterName.Vendors },
      'split_rule_ids': { filterName: FilterName.SharedCostRules },
      'expense_type_ids': { filterName: FilterName.ExpenseTypes },
      'source': { filterName: FilterName.ExpenseSource },
      'owner_ids': { filterName: FilterName.Owners },
      'company_budget_segment1_ids': {
        filterName: FilterName.Segments,
        defaultValue: () => this.filterManagementService.getDefaultSegments(budgetSegmentList)
      },
      'modes': { filterName: FilterName.Statuses },
    };

    this.filterManagementService.setParamsFromFilters(queryObj, requestParamsDef, true);
    return queryObj;
  }

}
