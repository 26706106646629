import { inject, Injectable } from '@angular/core';
import { ArrayStack } from '../utils/array-stack';
import { UtilityService } from './utility.service';

@Injectable()
export class ManageTableActionHistoryService<T extends { undo: Function }> {
  private readonly utilityService = inject(UtilityService);

  private undoSnackbarConfig = {
    message: 'Last action is undone',
    action: 'Close',
    duration: 3000
  };
  private history: ArrayStack<T>;
  public historySize = 0;

  constructor() {
    this.initStack();
  }

  private initStack(): void {
    this.history = new ArrayStack<T>({
      capacity: 10,
      overwriteOnLimit: true
    });
    this.historySize = 0;
  }

  reset(): void {
    if (this.historySize) {
      this.initStack();
    }
  }

  pushAction(action: T): void {
    this.history.push(action);
    this.historySize = this.history.size();
  }

  popAction(): T {
    const action = this.history.pop();
    this.historySize = this.history.size();

    return action;
  }

  undo(): void {
    if (!this.historySize) {
      return;
    }

    const action = this.popAction();
    if (action) {
      action.undo();
      this.utilityService.showCustomToastr(
        this.undoSnackbarConfig.message,
        this.undoSnackbarConfig.action,
        { timeOut: this.undoSnackbarConfig.duration }
      );
    }
  }
}
