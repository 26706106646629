<div class="team-page">
  <page-header pageName="My Team" [isAdminPage]="true">
    <fa-icon slot="icon" [icon]="['fas', 'users']"></fa-icon>
    <p *ngIf="ssoEnabled" slot="name" class="domain-name">{{ companyDomainName }}</p>
    <p slot="custom-items" class="account-owner">
      <span class="owner-label">Account Owner</span><br/>
      <button [matMenuTriggerFor]="userMenu">
        {{ accountOwnerName }}
        <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
      </button>
    </p>
  </page-header>
  <section class="manage-team-panel">
    <!-- <button class="manage-users" mat-stroked-button [matMenuTriggerFor]="objectMenu">
      Manage Users
      <span class="mat-select-arrow-wrapper">
        <span class="mat-select-arrow"></span>
      </span>
    </button> -->
    <!-- <mat-menu #objectMenu="matMenu" class="mat-pl-menu no-padding"></mat-menu> -->
    <button (click)="addNewUser()" class="add-new-user" mat-button color="primary">
      <fa-duotone-icon [icon]="['fad', 'plus-circle']"></fa-duotone-icon>
      Add New User
    </button>
    <search-control
      placeholder="Search Users"
      (textChanged)="filterUsers($event)"
    ></search-control>
  </section>
  <section class="manage-team-table">
    <div class="gradient">
      <div class="manage-team-table-wrapper">
        <div class="table-headers">
          <div class="sticky-container">
            <div class="user-name d-flex-row">
              <!-- <div class="table-header selection">
                <span>
                  <mat-checkbox color="primary"></mat-checkbox>
                </span>
              </div> -->
              <!-- [class.active]="appliedSorting?.column === 'rowTitle'" class="icon-sorting"
[flip]="appliedSorting?.column === 'rowTitle' && appliedSorting?.reverse && 'vertical'" -->
              <div class="table-header first-name">
                <span>
                  <span>First<br />Name</span>
                  <sort-button columnName="firstName" [appliedSorting]="appliedSorting" (onClick)="sortTeamMembers('firstName')"></sort-button>
                </span>
              </div>
              <div class="table-header last-name">
                <span>
                  <span>Last<br />Name</span>
                  <sort-button columnName="lastName" [appliedSorting]="appliedSorting" (onClick)="sortTeamMembers('lastName')"></sort-button>
                </span>
              </div>
            </div>
          </div>
          <div class="scroll-container d-flex-row">
            <div class="user-info d-flex-row">
              <div class="table-header email">
                <span>
                  <span>Email</span>
                  <sort-button columnName="email" [appliedSorting]="appliedSorting" (onClick)="sortTeamMembers('email')"></sort-button>
                </span>
              </div>
            </div>
            <div class="user-permissions d-flex-row">
              <div class="table-header action">
              </div>
              <div class="table-header permission">
                <span>Permissions</span>
              </div>
              <div class="table-header budget">
                <span>Budgets</span>
              </div>
              <div class="table-header segments">
                <span>Segments</span>
              </div>
            </div>
            <div class="user-actions d-flex-row">
              <div class="table-header weekly-email">
                <span>
                  Weekly<br />Email
                  <fa-icon [icon]="['fas', 'info-circle']" [matTooltip]="weeklyEmailsTooltip" matTooltipClass="dark-theme-tooltip above multi-line" matTooltipPosition="above"></fa-icon>
                </span>
              </div>
              <div class="table-header activity-status">
                <span>Profile Status</span>
              </div>
              <div class="table-header action">
                <span></span>
              </div>
              <div class="table-header action">
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <ul class="table-rows">
          <li *ngFor="let user of teamMembers$ | async | teamFilter: searchFilterText" class="user-data-row">
            <div class="sticky-container">
              <div class="user-name d-flex-row">
                <div class="table-data first-name">
                  <span *ngIf="user?.companyUserId" class="plain-text"
                        [matTooltip]="user.profileForm.get('firstName').value"
                        matTooltipPosition="above"
                        matTooltipClass="dark-theme-tooltip above"
                        matTooltipShowDelay="800">
                    {{ user.profileForm.get('firstName').value }}
                  </span>
                  <mat-form-field *ngIf="!user.companyUserId" appearance="outline" class="outline no-label no-validate">
                    <input matInput type="text" placeholder="First Name *" [formControl]="user.profileForm.get('firstName')">
                  </mat-form-field>
                </div>
                <div class="table-data last-name">
                  <span *ngIf="user?.companyUserId" class="plain-text"
                        [matTooltip]="user.profileForm.get('lastName').value"
                        matTooltipPosition="above"
                        matTooltipClass="dark-theme-tooltip above"
                        matTooltipShowDelay="800">
                    {{ user.profileForm.get('lastName').value }}
                  </span>
                  <mat-form-field *ngIf="!user.companyUserId" appearance="outline" class="outline no-label no-validate">
                    <input matInput type="text" placeholder="Last Name *" [formControl]="user.profileForm.get('lastName')">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="scroll-container d-flex-row">
              <div class="user-info d-flex-row">
                <div class="table-data email">
                  <span *ngIf="user.companyUserId" class="plain-text"
                        [matTooltip]="user.profileForm.get('email').value"
                        matTooltipPosition="above"
                        matTooltipClass="dark-theme-tooltip above"
                        matTooltipShowDelay="800">
                    {{ user.profileForm.get('email').value }}
                  </span>
                  <mat-form-field *ngIf="!user.companyUserId" appearance="outline" class="outline no-label">
                    <input matInput type="email" placeholder="User Email *" [formControl]="user.profileForm.get('email')">
                    <mat-error *ngIf="user.profileForm.get('email').hasError('email')">
                      Not a valid email address
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="user-permissions" [formGroup]="user.permissionsForm">
                <ng-container formArrayName="permissions">
                  <ng-container *ngFor="let permissionForm of user.permissionsForm.get('permissions').controls; let permissionIndex = index;">
                    <div class="permission-row d-flex-row" [formGroup]="permissionForm">
                      <div class="table-data action d-flex-row">
                        <ng-container *ngIf="permissionForm.controls.permission.value !== UserPermission.Admin && user.editable">
                          <button mat-icon-button (click)="addNewPermission(user)"
                                  matTooltip="Add Permission"
                                  matTooltipPosition="above"
                                  matTooltipClass="dark-theme-tooltip above"
                                  matTooltipShowDelay="800">
                            <fa-duotone-icon [icon]="['fad', 'plus-circle']"></fa-duotone-icon>
                          </button>
                          <button *ngIf="user.permissionsForm.get('permissions').controls.length > 1 && user.editable"
                                  mat-icon-button
                                  (click)="revokePermission(user, permissionIndex)" class="revoke-permission"
                                  matTooltip="Revoke Permission"
                                  matTooltipPosition="above"
                                  matTooltipClass="dark-theme-tooltip above"
                                  matTooltipShowDelay="800">
                            <fa-duotone-icon [icon]="['fad', 'minus-circle']"></fa-duotone-icon>
                          </button>
                        </ng-container>
                      </div>
                      <div class="table-data permission">
                        <span *ngIf="!user.editable" class="plain-text">
                          {{ permissionLabelByType[permissionForm.controls['permission'].value] }}
                        </span>
                        <mat-form-field *ngIf="user.editable" appearance="outline" class="outline no-label no-validate">
                          <mat-select placeholder="Choose a Permission" formControlName="permission" disableOptionCentering panelClass="mat-alloc-select-fixed-position" (openedChange)="$event && addRollBackPermission(permissionForm)">
                            <mat-option *ngFor="let permission of permissionOptions" [value]="permission.value">
                              {{ permission.label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="table-data budget">
                        <span *ngIf="permissionForm.controls['permission'].value === UserPermission.Admin" class="plain-text">All</span>
                        <mat-form-field *ngIf="permissionForm.controls['permission'].value !== UserPermission.Admin" appearance="outline" class="outline no-label no-validate">
                          <mat-select placeholder="Choose a Budget *" formControlName="budget" [compareWith]="objectComparisonFunction" disableOptionCentering panelClass="mat-alloc-select-fixed-position" (openedChange)="$event && addRollBackBudget(permissionForm)">
                            <mat-option *ngFor="let budget of budgets" [value]="budget">
                              {{ budget.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="table-data segments">
                        <span *ngIf="permissionForm.controls['permission'].value === UserPermission.Admin" class="plain-text">All</span>
                        <mat-form-field *ngIf="permissionForm.controls['permission'].value !== UserPermission.Admin" appearance="outline" class="outline no-label no-validate">
                          <mat-select placeholder="Choose Segments"
                                      formControlName="segments"
                                      [compareWith]="objectComparisonFunction"
                                      (closed)="onSelectClosed(permissionForm.controls['segments'])"
                                      multiple
                                      disableOptionCentering
                                      panelClass="mat-alloc-select-fixed-position"
                                      [matTooltip]="permissionForm.controls['segments']?.value | segmentsTooltip"
                                      matTooltipPosition="above"
                                      matTooltipClass="dark-theme-tooltip above"
                                      matTooltipShowDelay="800">
                            <mat-select-trigger>
                              <ng-container *ngIf="permissionForm.controls['segments'].value?.length">
                                {{
                                  permissionForm.controls['segments'].value.length > 1
                                    ? permissionForm.controls['segments'].value.length + ' Segments' : permissionForm.controls['segments'].value[0]?.name
                                }}
                              </ng-container>
                            </mat-select-trigger>
                            <ng-container *ngIf="permissionForm.controls['budget'].value">
                              <div *ngIf="permissionForm.controls['budget'].value.id && segmentsByBudgetId[permissionForm.controls['budget'].value.id]?.length > 1"
                                   class="mat-option with-divider">
                                <mat-checkbox class="color-primary"
                                              [checked]="segmentsByBudgetId[permissionForm.controls['budget'].value.id]?.length === permissionForm.controls['segments'].value?.length"
                                              [color]="'primary'"
                                              [indeterminate]="
                                                segmentsByBudgetId[permissionForm.controls['budget'].value.id]?.length !== permissionForm.controls['segments'].value?.length &&
                                                !!permissionForm.controls['segments'].value?.length"
                                              (change)="setAll(
                                                  permissionForm.controls['segments'],
                                                  $event.checked,
                                                  segmentsByBudgetId[permissionForm.controls['budget'].value.id]
                                              )">All segments</mat-checkbox>
                              </div>
                              <mat-option *ngFor="let segment of segmentsByBudgetId[permissionForm.controls['budget'].value.id]"
                                          (click)="onSegmentClick(permissionForm.controls['segments'], segment)"
                                          [value]="segment">
                                {{ segment.name }}
                              </mat-option>
                            </ng-container>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <div class="user-actions d-flex-row">
                <div class="table-data weekly-email">
                  <mat-slide-toggle [checked]="user.weeklyEmails" (change)="toggleUserEmailSubscription(user.companyUserId, $event.checked)" labelPosition="before" disableRipple="true">
                  </mat-slide-toggle>
                </div>
                <div class="table-data activity-status">
                  <invitation-button
                    [isActive]="user.isActive"
                    [invitationDate]="user.invitationDate"
                    [inviteAccepted]="user.inviteAccepted"
                    [disabled]="!user.withPermission"
                    (sendInvite)="sendInvite(user)"
                  ></invitation-button>
                </div>
                <div class="table-data action">
                  <button mat-icon-button (click)="duplicatePermissions(user)">
                    <fa-duotone-icon [icon]="['fad', 'copy']"></fa-duotone-icon>
                  </button>
                </div>
                <div class="table-data action">
                  <button
                    mat-icon-button
                    *ngIf="user.editable"
                    [disabled]="user.is_account_owner"
                    (click)="deleteTeamMember(user)">
                    <fa-duotone-icon [icon]="['fad', 'trash-alt']"></fa-duotone-icon>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>
<mat-menu #userMenu="matMenu">
  <ng-container *ngFor="let user of adminTeamMembers">
    <button mat-menu-item
      class="user-item"
      [ngClass]="{active: user.is_account_owner}"
      (click)="changeAccountOwner(user)"
    >
      <span class="text">
        {{ user.profileForm.get('firstName').value }} {{ user.profileForm.get('lastName').value }}
      </span>
    </button>
  </ng-container>
</mat-menu>
