import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MetricFunnelRow, MetricMaster, ProductMetricFunnel } from '@common-lib/lib/corporate-page/metric-funnels.types';

export class MetricFunnelsValidationService {
  private hasFunnelNameErrors = false;
  private metricsWithNameErrors = [];

  private flatMetricsList: MetricMaster[] = [];
  private funnelsList: ProductMetricFunnel[] = [];

  public updateFlatMetrics(rows: MetricFunnelRow[]) {
    this.flatMetricsList = this.createFlatMetricsList(rows);
    this.metricsWithNameErrors = this.metricsWithNameErrors.filter(metric => this.flatMetricsList.includes(metric));
  }

  public updateFunnelsList(funnels: ProductMetricFunnel[]) {
    this.funnelsList = funnels;
  }

  public createFlatMetricsList(rows: MetricFunnelRow[]): MetricMaster[] {
    return rows.reduce((list, row) => {
      list.push(row.countMetric, row.currencyMetric);
      return list;
    }, []).filter(metric => !!metric);
  }

  public pushNewMetric(metric: MetricMaster) {
    this.flatMetricsList.push(metric);
  }

  public uniqueMetricNameValidator(record: MetricMaster): ValidatorFn {
    const validator: ValidatorFn = (control: AbstractControl): { unique: boolean } => {
      const existingRecord = this.flatMetricsList
        .find(metric => metric.name === control.value && metric !== record);
      return !control.value || !existingRecord ? null : { unique: true };
    };

    return validator;
  };

  public uniqueFunnelNameValidator(funnelId: number): ValidatorFn {
    const validator: ValidatorFn = (control: AbstractControl): { unique: boolean } => {
      const existingRecord = this.funnelsList
        .find(funnel => funnel.productName === control.value && funnel.id !== funnelId);
      return existingRecord ? { unique: true } : null;
    };

    return validator;
  };

  public resetFunnelErrors() {
    this.hasFunnelNameErrors = false;
    this.metricsWithNameErrors = [];
  }

  public setMetricNameErrorState(hasError: boolean, record: MetricMaster) {
    if (hasError && !this.metricsWithNameErrors.includes(record)) {
      this.metricsWithNameErrors.push(record);
    }
    if (!hasError && this.metricsWithNameErrors.includes(record)) {
      this.metricsWithNameErrors = this.metricsWithNameErrors.filter(metric => metric !== record);
    }
  }

  public set funnelNameErrors(state: boolean) {
    this.hasFunnelNameErrors = state;
  }

  public isFunnelValid(): boolean {
    return !this.hasFunnelNameErrors && !this.metricsWithNameErrors.length;
  }
}
