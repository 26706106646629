import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TasksService } from 'app/shared/services/backend/tasks.service';
import { Task, TaskDO, TaskStatus, TaskStatusName } from 'app/shared/types/task.interface';
import { Configuration } from 'app/app.constants';

interface TaskIncrement {
  created: TaskDO[];
  updated: TaskDO[];
  deleted: Partial<TaskDO>[];
}

@Injectable({
  providedIn: 'root'
})
export class BudgetObjectTasksService {
  private statuses: TaskStatus[] = [];
  private propByObjectType = {
    [this.configuration.OBJECT_TYPES.campaign]: 'campaign',
    [this.configuration.OBJECT_TYPES.program]: 'program'
  };
  public static areEqual(taskA: TaskDO, taskB: TaskDO) {
    return (
      taskA.name === taskB.name &&
      taskA.owner === taskB.owner &&
      taskA.due_date === taskB.due_date &&
      taskA.status === taskB.status
    );
  }

  public static isValid(task: TaskDO) {
    return task && (
      task.name &&
      task.status &&
      task.due_date &&
      task.owner
    )
  }

  constructor(
    private readonly tasksService: TasksService,
    private readonly configuration: Configuration,
  ) {
    this.statuses = tasksService.getStatusList();
  }

  private applyCreatedTasks(tasks: TaskDO[], createdTasks: TaskDO[] = []) {
    if (!Array.isArray(createdTasks)) {
      return;
    }
    const localTasks = tasks.filter(task => !task.id);

    createdTasks.forEach((task) => {
      const taskToUpdate = localTasks.find(localTask => (
        localTask.id == null &&
        BudgetObjectTasksService.areEqual(task, localTask)
      ));

      if (taskToUpdate) {
        taskToUpdate.id = task.id;
      }
    });
  }

  private getIncrement(prevState: TaskDO[] = [], nextState: TaskDO[] = []): TaskIncrement {
    const increment: TaskIncrement = { created: [], updated: [], deleted: [] };

    if (Array.isArray(prevState)) {
      prevState
        .filter(task => task && task.id)
        .forEach((task) => {
          const currentTask = nextState.find((nextMetric) => nextMetric.id === task.id);

          if (currentTask) {
            if (!BudgetObjectTasksService.areEqual(task, currentTask)) {
              increment.updated.push({ ...currentTask });
            }
            return;
          }

          increment.deleted.push({
            id: task.id,
            name: task.name
          });
        });
    }

    if (Array.isArray(nextState)) {
      nextState
        .filter(task => !task.id)
        .forEach((task) => {
          if (BudgetObjectTasksService.isValid(task)) {
            increment.created.push({...task});
          }
        });
    }

    return increment;
  }

  public createTasks(options: {
    objectId: number;
    objectType: string;
    tasks: TaskDO[];
    companyId: number;
  }): Observable<TaskDO[]> {
    const { objectId, objectType, tasks, companyId } = options;
    const objectPropName = this.propByObjectType[objectType];
    if (!objectPropName) {
      return of(null);
    }

    return tasks && tasks.length ?
      forkJoin(
        tasks.map(task =>
          this.tasksService.createTask({
            name: task.name,
            owner: task.owner,
            due_date: task.due_date,
            status: task.status,
            company: companyId,
            [objectPropName]: objectId
          }).pipe(
            map(result => ({ ...task, id: result.id }))
          )
        )
      ) :
      of(null);
  }

  public deleteTasks(tasks: Partial<TaskDO>[] = []) {
    return tasks && tasks.length ?
      forkJoin(
        tasks.map((task) => this.tasksService.deleteTask(task.id))
      ) :
      of(null);
  }

  public updateTasks(tasks: TaskDO[] = []) {
    return tasks && tasks.length ?
      forkJoin(
        tasks.map((task) =>
          this.tasksService.updateTask(task.id, {
            name: task.name,
            status: task.status,
            owner: task.owner,
            due_date: task.due_date
          })
        )
      ) :
      of (null);
  }

  public saveTasks(options: {
    prevState: TaskDO[];
    nextState: TaskDO[];
    objectId: number;
    objectType: string;
    companyId: number;
  }) {
    const { prevState, nextState, objectId, objectType, companyId } = options;
    const increment = this.getIncrement(
      prevState,
      nextState
    );

    return forkJoin([
      this.createTasks({
        objectId,
        objectType,
        companyId,
        tasks: increment.created
      }),
      this.deleteTasks(increment.deleted),
      this.updateTasks(increment.updated)
    ]).pipe(
      tap(([createdTasks ]) => {
        this.applyCreatedTasks(nextState, createdTasks);
      })
    )
  }

  /**
   * Set late status for 'expired' tasks
   */
  public syncExpiredTasks(tasks: Task[]) {
    const lateStatus = this.statuses.find(status => status.name === TaskStatusName.LATE);
    const expiredTasks = tasks.filter(item => {
      return item.id && item.isLate && (item.status && item.status.name === TaskStatusName.IN_PROGRESS)
    });

    return forkJoin(
      expiredTasks.map(task =>
        this.tasksService.updateTask(task.id, { status: TaskStatusName.LATE })
      )
    ).pipe(
      tap(results => {
        results.forEach(result => {
          const target = tasks.find(task => task.id === result.id);
          if (target) {
            target.status = lateStatus;
          }
        });
      })
    );
  }
}
