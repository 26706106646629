import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UtilityService } from '@shared/services/utility.service';
import { ACCEPTED_EXTENSIONS, InvoiceUploadManagerService } from '@spending/services/invoice-upload-manager.service';

@Component({
  selector: 'invoice-upload-btn',
  templateUrl: './invoice-upload-btn.component.html',
  styleUrls: ['./invoice-upload-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceUploadBtnComponent {
  @Input() type: 'flat' | 'basic';
  @Input() disabled: boolean;

  @ViewChild('fileInput') fileInput: ElementRef;

  protected readonly acceptedExtensions = ACCEPTED_EXTENSIONS;

  constructor(
    private readonly invoiceUploadManager: InvoiceUploadManagerService,
    private readonly utilityService: UtilityService
  ) { }

  fileInputClick(): void {
    this.fileInput.nativeElement.click();
  }

  onFileUpload(event: Event): void {
    this.invoiceUploadManager.uploadFiles(Array.from((event.target as HTMLInputElement).files), true)
      .subscribe({
        error: error => {
          this.clearInput();
          this.utilityService.showToast({ Message: error.message });
        },
        complete: () => this.clearInput()
      });
  }

  private clearInput(): void {
    this.fileInput.nativeElement.value = null;
  }
}
