import { Component, Input } from '@angular/core';
import { MetricIntegrationName } from '../../types/metric-integration';

@Component({
  selector: 'integration-setup-logo',
  templateUrl: './integration-setup-logo.component.html',
  styleUrls: ['./integration-setup-logo.component.scss']
})
export class IntegrationSetupLogoComponent {
  @Input() source: MetricIntegrationName;

  public imageSourceBySource = {
    [MetricIntegrationName.Hubspot]: 'assets/images/integrations/hubspot-logo.svg',
    [MetricIntegrationName.Salesforce]: 'assets/images/integrations/salesforce-logo.svg',
    [MetricIntegrationName.GoogleAds]: 'assets/images/integrations/google-ads-logo.svg',
    [MetricIntegrationName.LinkedinAds]: 'assets/images/integrations/linkedin-ads-logo.svg',
    [MetricIntegrationName.FacebookAds]: 'assets/images/integrations/facebook-ads-logo.png',
  };
}
