import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-metrics',
  templateUrl: './icon-metrics.component.html',
  styleUrls: ['./icon-metrics.component.scss']
})
export class IconMetricsComponent {
  @Input() size = '30px';
  @Input() color = '#4C368D';
}
