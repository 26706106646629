import { Injectable } from '@angular/core';
import { LocalSpendingPageFilters } from '../types/expense-page.type';
import { BehaviorSubject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

@Injectable()
export class SpendingLocalFiltersService {
  public totalRecordsCount: number;
  private initialFilters: LocalSpendingPageFilters = {
    limit: 100,
    offset: 0,
    page: 0,
    search: '',
    ordering: '',
  };
  private _localFilters$ = new BehaviorSubject<LocalSpendingPageFilters>(this.initialFilters);
  public localFilters$ = this._localFilters$.asObservable();

  public set sortBy(value: string) {
    this.updateParams({ ordering: value, page: 0, offset: 0 });
  }

  public set searchStr(value: string) {
    this.updateParams({ search: value, page: 0, offset: 0 });
  }

  public changePage(e: PageEvent): void {
    const { offset, limit } = this.localFilters;
    const updatedOffset = e.previousPageIndex < e.pageIndex ? e.pageIndex * limit : offset - limit;
    this.updateParams({ offset: updatedOffset, page: e.pageIndex });
  }

  public get localFilters(): LocalSpendingPageFilters {
    return this._localFilters$.getValue();
  }

  public updateParams(updatedValues: Partial<LocalSpendingPageFilters>) {
    this._localFilters$.next({ ...this.localFilters, ...updatedValues });
  }

  public resetPagination(): void {
    this.updateParams({ page: 0, offset: 0 });
  }
}
