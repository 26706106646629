import { Component, Input } from '@angular/core';
import { ExternalMetricTypesMapping } from '../../types/external-metric-types-mapping.interface';
import { ExternalMetric, ExternalMetricType } from '../../types/external-metrics.types';
import { MetricDO, MetricService } from 'app/shared/services/backend/metric.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { forkJoin, Observable, of } from 'rxjs';
import { UtilityService } from 'app/shared/services/utility.service';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  ExternalMetricTypesMappingTableRow,
  ExternalMetricTypesMappingTableChangeEvent,
  ExternalMetricTypesMappingTableSettings, MappedMetricType
} from '../external-metric-types-mapping-table/external-metric-types-mapping-table.type';
import { ExternalMetricTypesService } from '../../services/external-metric-types-service';
import { Integration } from '../../types/metrics-provider-data-service.types';
import { MetricIntegrationName } from '../../types/metric-integration';
import { ProductDO, ProductService } from 'app/shared/services/backend/product.service';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { Configuration } from 'app/app.constants';
import { ExternalMetricTypesProviderService } from '../../services/external-metric-types-provider.service';

@Component({
  selector: 'external-metric-types-mapping',
  templateUrl: './external-metric-types-mapping.component.html',
  styleUrls: []
})
export class ExternalMetricTypesMappingComponent {
  externalMetricTypes: ExternalMetricTypesMappingTableRow[] = [];
  plannuhMetrics: MetricDO[] = [];
  products: ProductDO[];
  metricsMapping: ExternalMetricTypesMapping;
  externalMetrics: ExternalMetric[] = [];
  isExternalServiceAvailable = false;
  integrationId: string;
  private metricUnitTypes = {
    [ExternalMetricType.int]: 'Quantity (count)',
    [ExternalMetricType.double]: 'Quantity (count)',
    [ExternalMetricType.currency]: 'Currency'
  };
  externalMetricTypesService: ExternalMetricTypesService;
  public loading: boolean;

  @Input() company: CompanyDO;
  @Input() isPowerUser: boolean;
  @Input() mappingTableSettings: ExternalMetricTypesMappingTableSettings;
  @Input() integrationSource: MetricIntegrationName;
  @Input() disabled: boolean;
  @Input() set integrations(integrations: Integration[]) {
    this.isExternalServiceAvailable = !!integrations?.length;
    this.integrationId = null;
    if (!this.integrationSource || !integrations?.length) {
      return;
    }
    if (this.integrationSource === MetricIntegrationName.Salesforce) {
      this.integrationId = integrations[0].integrationId;
    }
    this.externalMetricTypesService =
      this.externalMetricTypesProvider.getExternalMetricTypesServiceByIntegrationSource(this.integrationSource);
    if (!this.externalMetricTypesService) {
      console.error('Error on externalMetricTypesService initialisation');
      return;
    }
    this.loadComponentData();
  }

  constructor(
    private readonly utilityService: UtilityService,
    private readonly metricService: MetricService,
    private readonly externalMetricTypesProvider: ExternalMetricTypesProviderService,
    private readonly productService: ProductService,
    private readonly appRoutingService: AppRoutingService,
    public readonly configuration: Configuration,
  ) {}

  private loadComponentData() {
    this.externalMetricTypes = [];
    this.metricsMapping = null;
    const integrationId = this.integrationId;

    if (this.company) {
      this.utilityService.showLoading(true);
      forkJoin([
        this.externalMetricTypesService.getMetricTypes(this.company.id, integrationId)
          .pipe(tap(metrics => this.externalMetrics = metrics)),
        this.metricService.getMetrics(this.company.id)
          .pipe(tap(metrics => this.plannuhMetrics = metrics)),
        this.externalMetricTypesService.getExternalMetricTypesMapping(this.company.id, integrationId)
          .pipe(tap(mapping => this.metricsMapping = mapping)),
        this.productService.getProducts(this.company.id)
          .pipe(tap(products => this.products = products)),
      ])
        .pipe(
          switchMap((data) => this.defineExternalMetricMapping(data, integrationId))
        )
        .subscribe(
          ([externalMetrics, plannuhMetrics, mapping]) => {
            this.metricsMapping = mapping;
            this.prepareMetricMappingTableData(externalMetrics, this.metricsMapping);
            this.utilityService.showLoading(false);
          },
          error => this.handleError('Failed to load metric data', error)
        );
    }
  }

  private defineExternalMetricMapping(
    data: [ExternalMetric[], MetricDO[], ExternalMetricTypesMapping, ProductDO[]],
    integrationId: string
  ): Observable<[ExternalMetric[], MetricDO[], ExternalMetricTypesMapping, ProductDO[]]> {
    const [externalMetrics, plannuhMetrics, metricMapping, products] = data;

    const setUpdatedMappings = (newMappings): Observable<[ExternalMetric[], MetricDO[], ExternalMetricTypesMapping, ProductDO[]]> => {
      return this.externalMetricTypesService.setExternalMetricTypesMapping(this.company.id, newMappings, integrationId).pipe(
        map(() => [externalMetrics, plannuhMetrics, newMappings, products])
      );
    };

    if (metricMapping) {
      let updatingRequired = false;
      Object.keys(metricMapping).forEach(key => {
        const checkedIds = metricMapping[key].filter(mappedId => plannuhMetrics.find(metric => metric.id === mappedId));
        if (checkedIds.length !== metricMapping[key].length) {
          updatingRequired = true;
          if (checkedIds.length) {
            metricMapping[key] = checkedIds;
          } else {
            delete metricMapping[key];
          }
        }
      })
      if (updatingRequired) {
        return setUpdatedMappings(metricMapping);
      }
      return of(data);
    }
    const defaultMapping =
      this.externalMetricTypesProvider.getDefaultInitialMapping(this.externalMetricTypesService, externalMetrics, plannuhMetrics);
    if (!defaultMapping) {
      return of(data);
    }
    return setUpdatedMappings(defaultMapping);
  }

  private handleError(message: string, logError?: any) {
    if (logError) {
      console.log(logError);
    }
    if (message) {
      this.utilityService.handleError({ message });
    }
    this.utilityService.showLoading(false);
  }

  private prepareMetricMappingTableData(
    externalMetrics: ExternalMetric[],
    metricMapping: ExternalMetricTypesMapping
  ) {
    this.externalMetricTypes = (externalMetrics || []).map((externalMetric: ExternalMetric) => {
      return {
        name: externalMetric.name,
        label: externalMetric.label,
        description: externalMetric.description,
        unitType: this.getMetricUnitType(this.company, externalMetric.type),
        withCurrency: externalMetric.type === ExternalMetricType.currency,
        mappedPlannuhMetricTypes: this.createMappedMetricType(
          metricMapping?.[externalMetric.name] || [],
          this.plannuhMetrics,
          this.products
        ),
      }
    });
  }

  createMappedMetricType(mappingIds: number[], metrics: MetricDO[], products: ProductDO[]): MappedMetricType[] {
    return mappingIds.map((metricId: number): MappedMetricType => {
      const targetMetric = metrics.find(metric => metric.id === metricId);
      return {
        id: metricId,
        name: targetMetric.name,
        productName: targetMetric.product ? products.find(product => product.id === targetMetric.product).name : null,
      };
    });
  }

  private getMetricUnitType(company: any, rawType: ExternalMetricType): string {
    let unitType = this.metricUnitTypes[rawType];
    if (rawType === ExternalMetricType.currency) {
      unitType += ` (${company.currency})`;
    }
    return unitType;
  }

  public handleMetricMappingChange(mappingChangeData: ExternalMetricTypesMappingTableChangeEvent) {
    this.loading = true;
    const newMetricsMapping = this.metricsMapping ? {...this.metricsMapping} : {};
    const { externalMetricTypeName, plannuhMetricIds } = mappingChangeData;

    if (!plannuhMetricIds.length && newMetricsMapping[externalMetricTypeName]) {
      delete newMetricsMapping[externalMetricTypeName];
    } else if (plannuhMetricIds.length) {
      newMetricsMapping[externalMetricTypeName] = plannuhMetricIds as number[];
    }
    this.externalMetricTypesService.setExternalMetricTypesMapping(this.company.id, newMetricsMapping, this.integrationId)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
      () => {
        this.metricsMapping = newMetricsMapping;
        this.prepareMetricMappingTableData(this.externalMetrics, this.metricsMapping);
      },
      error => this.handleError('Failed to save metric mapping change', error)
    );
  }

  goToFunnelsPage() {
    this.appRoutingService.closeAllDetailsWindows();
    this.appRoutingService.safeNavigateTo([this.configuration.ROUTING_CONSTANTS.METRIC_FUNNELS]);
  }
}
