import { Component, Input, Output, EventEmitter } from '@angular/core';

import { SortDirectionsConstants } from '../../../constants/sort-directions.constants';

@Component({
  selector: 'icon-sorting',
  templateUrl: './icon-sorting.component.html',
  styleUrls: ['./icon-sorting.component.scss']
})

/**
* @class - IconSortingComponent
*/
export class IconSortingComponent {
  @Input() isSorted = false;
  @Input() direction = this.sortDirectionsConstants.desc;

  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  opppositeDirections = {
    [this.sortDirectionsConstants.desc]: this.sortDirectionsConstants.asc,
    [this.sortDirectionsConstants.asc]: this.sortDirectionsConstants.desc,
  };

  constructor(
    public sortDirectionsConstants: SortDirectionsConstants
  ) {}

  handleClick = () => {
    if (!this.isSorted) {
      this.change.next(this.sortDirectionsConstants.asc)
      return;
    }

    this.change.next(this.opppositeDirections[this.direction]);
  }
}
