import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  ExpenseStatusSelectOptions,
  ExpenseTableColumn,
  ExpenseTableColumnItem,
  PatchExpenseData,
  StatusSelectEvent,
  TimeframeMapItem
} from '@spending/types/expense-page.type';
import { ExpenseRowData, ExpenseSubtotalData } from '@spending/services/expense-table-data.service';
import { ExpensePageSelectionService } from '@spending/services/expense-page-selection.service';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { ExpenseAllocationMode } from '@shared/types/expense-allocation-mode.type';
import { Router } from '@angular/router';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { interval, merge, Subject } from 'rxjs';
import { SpendingSidebarService } from '@spending/services/spending-sidebar.service';
import { SpendingService } from '@spending/services/spending.service';
import { BudgetObjectSource } from 'app/budget-object-details/types/budget-object-details-state.interface';
import { ExternalIntegrationExpenseSource } from '@shared/constants/external-integration-object-types';
import { SpendingTableBaseComponent } from '@spending/components/spending/spending-table-base';
import { ExpenseDO } from '@shared/types/expense.interface';
import { AppRoutingService } from '@shared/services/app-routing.service';


@Component({
  selector: 'manage-expense-table',
  templateUrl: './expense-table.component.html',
  styleUrls: ['./expense-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('cellContent', [
      transition(':enter', [
        style({ transform: 'scaleX(.6)', opacity: 0 }),
        animate('150ms 200ms ease-out', style({ transform: 'scaleX(1)', opacity: 1 }))
      ]),
    ])
  ]
})
export class ManageExpenseTableComponent extends SpendingTableBaseComponent<ExpenseRowData> implements OnDestroy {
  private static isIntegratedExpense(source: BudgetObjectSource): boolean {
    const externalIntegrationSources = Object.values(ExternalIntegrationExpenseSource);
    return externalIntegrationSources.includes(source);
  }
  @Input() set rowsData(rows: ExpenseRowData[]) {
    this.resetRowsRendering$.next();
    if (this.rowContainer) {
      this.rowContainer.clear();
    }
    this.rows = rows || [];
    this.selectableRows = this.rows.filter(row => this.isRowSelectable(row));
    if (this.rows.length && this.rowContainer) {
      this.renderRows();
    }
  }
  @Input() subtotalData: ExpenseSubtotalData;
  @Input() budgetCurrency: string;

  @Output() setRelatedExpensesMode = new EventEmitter<string>();
  @Output() patchData = new EventEmitter<PatchExpenseData>();

  public hierarchySelectItems: HierarchySelectItem[] = [];
  public activeTimeframe: number;

  public statusSelectOptions: ExpenseStatusSelectOptions;
  public timeframeSelectOptions: {
    expenseId: number;
    timeframeId: number;
  };

  public timeframeList: BudgetTimeframe[];
  public timeframeMap: Record<string, TimeframeMapItem> = {};

  public readonly isDefaultCell = {
    [ExpenseTableColumn.CURRENCY]: true,
    [ExpenseTableColumn.SOURCE]: true,
    [ExpenseTableColumn.GL_CODE]: true,
    [ExpenseTableColumn.VENDOR]: true,
    [ExpenseTableColumn.CREATED_DATE]: true,
    [ExpenseTableColumn.UPDATED_DATE]: true,
    [ExpenseTableColumn.DELIVERY_DATE]: true,
    [ExpenseTableColumn.TYPE]: true,
    [ExpenseTableColumn.INVOICE]: true,
    [ExpenseTableColumn.PO_NUMBER]: true,
  };

  @Input() set columns(value: ExpenseTableColumnItem[]) {
    this.visibleColumns = (value || []).filter(item => item.visible);
  }
  @Input() set timeframes(tfList: BudgetTimeframe[]) {
    if (!tfList) {
      return;
    }
    this.timeframeList = tfList;
    tfList.forEach(tf => {
      this.timeframeMap[tf.id] = {
        name: tf.year ? `${tf.shortName}, ${tf.year}` : tf.shortName,
        locked: tf.locked,
      };
    });
  }
  @ViewChild('rowContainer', { read: ViewContainerRef }) rowContainer: ViewContainerRef;
  @ViewChild('rowTemplate', { read: TemplateRef }) rowTemplate: TemplateRef<any>;

  public expenseAllocationMode = ExpenseAllocationMode;
  private resetRowsRendering$ = new Subject<void>();
  private readonly appRoutingService = inject(AppRoutingService);

  constructor(
    private readonly expensePageSelectionService: ExpensePageSelectionService,
    private readonly spendingSidebarService: SpendingSidebarService,
    private readonly spendingService: SpendingService,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
    ) {
      super(
        router,
        zone,
        cdr,
        spendingService,
        spendingSidebarService,
        expensePageSelectionService,
      );
  }

  private renderRows(): void {
    const rowsLength = this.rows.length;
    const delayMs = 20;
    const rowsRenderedInitially = 20;
    const embedRow = rowIndex => this.rowContainer.createEmbeddedView(this.rowTemplate, { $implicit: this.rows[rowIndex] });

    let index = rowsLength > rowsRenderedInitially ? rowsRenderedInitially : rowsLength;
    for (let n = 0; n < index; n++) {
      embedRow(n);
    }

    interval(delayMs)
      .pipe(
        takeWhile(() => index < rowsLength),
        takeUntil(merge(this.destroy$, this.resetRowsRendering$)),
      )
      .subscribe(() => {
        embedRow(index);
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        index++;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public isExpenseUnverified(row: ExpenseRowData): boolean {
    return row?.expenseObject?.is_verified != null && !row?.expenseObject?.is_verified
  }

  public timeframeIsLocked(row: ExpenseRowData): boolean {
    if (!row[ExpenseTableColumn.TIMEFRAME]) {
      return false;
    }
    const timeframeId = row[ExpenseTableColumn.TIMEFRAME].value;
    return this.timeframeMap[timeframeId]?.locked;
  }

  public disabledCellEdit(row: ExpenseRowData, columnId: number): boolean {
    return this.disabledRowEdit(row) || row[columnId].disabled;
  }

  public disabledRowEdit(row: ExpenseRowData): boolean {
    return this.readOnlyMode ||
      row.isPseudoObject ||
      this.openedInDrawerId === row.expenseId ||
      this.timeframeIsLocked(row) ||
      ManageExpenseTableComponent.isIntegratedExpense(row.expenseObject.source) ||
      this.isRowSelected(row);
  }

  public onAttachmentsClick(id: number) {
    this.onNameClick(id);
  }

  public setTimeframeSelectOptions(row: ExpenseRowData, timeframeId: number): void {
    const expenseId = row.expenseId;
    if (this.disabledRowEdit(row)) {
      this.timeframeSelectOptions = null;
      return;
    }
    this.timeframeSelectOptions = {
      expenseId: expenseId,
      timeframeId : timeframeId
    };
  }

  public selectTimeframe(selectedTimeframeId: number) {
    if (selectedTimeframeId === this.timeframeSelectOptions.timeframeId) {
      return;
    }
    const patchData = ManageExpenseTableComponent.createPatchDataTemplate(this.timeframeSelectOptions.expenseId);
    patchData.body.company_budget_alloc = selectedTimeframeId;
    this.patchData.emit(patchData);
  }

  public setStatusSelectOptions(rowData: ExpenseRowData) {
    if (this.disabledRowEdit(rowData)) {
      this.statusSelectOptions = null;
      return;
    }
    this.statusSelectOptions = {
      expenseId: rowData.expenseId,
      planned: rowData.expenseObject.source_amount,
      actual: rowData.expenseObject.source_actual_amount,
      sourceCurrency: rowData.expenseObject.source_currency,
      status: rowData.expenseObject.mode as ExpenseAllocationMode,
      isTimeframeLocked: this.timeframeMap[rowData.expenseObject.company_budget_alloc]?.locked,
    };
  }

  public onStatusUpdate(statusUpdates: StatusSelectEvent): void {
    const patchData = ManageExpenseTableComponent.createPatchDataTemplate(statusUpdates.expenseId);
    patchData.body.mode = statusUpdates.status;
    if (statusUpdates.setActual !== undefined) {
      patchData.body.source_actual_amount = statusUpdates.setActual;
    }
    this.patchData.emit(patchData);
  }

  public onAmountUpdate(cellType: ExpenseTableColumn, rowData: ExpenseRowData, amount: number) {
    if (rowData.isPseudoObject) {
      return;
    }
    switch (cellType) {
      case ExpenseTableColumn.ACTUAL: this.onActualAmountUpdate(rowData, amount);
      break;
      case ExpenseTableColumn.PLANNED: this.onPlannedAmountUpdate(rowData, amount);
      break;
    }
  }

  public onPlannedAmountUpdate(rowData: ExpenseRowData, plannedAmount: number) {
    const patchData = ManageExpenseTableComponent.createPatchDataTemplate(rowData.expenseId);
    patchData.body.source_amount = plannedAmount;
    this.patchData.emit(patchData);
  }

  public onActualAmountUpdate(rowData: ExpenseRowData, actualAmount: number) {
    const patchData = ManageExpenseTableComponent.createPatchDataTemplate(rowData.expenseId);
    patchData.body.source_actual_amount = actualAmount;

    const prevValue = rowData.expenseObject.source_actual_amount;
    const status = rowData.expenseObject.mode;
    if (!prevValue && actualAmount && status === ExpenseAllocationMode.Planned) {
      patchData.body.mode = ExpenseAllocationMode.Committed;
    }
    this.patchData.emit(patchData);
  }

  public onParentNameClick(valueType: string, expense: ExpenseDO): void {
    if (!expense.campaign && !expense.program || valueType === 'Segment') return;
    if (expense.campaign) {
      this.appRoutingService.openCampaignDetails(expense.campaign);
    }
    if (expense.program) {
      this.appRoutingService.openProgramDetails(expense.program);
    }
  }

  private static createPatchDataTemplate(id: number): PatchExpenseData {
    return {
      id,
      body: {}
    };
  }

  public handleRelatedClick(relationGroupId: string) {
    this.setRelatedExpensesMode.emit(relationGroupId);
  }

  protected isRowSelectable(row: ExpenseRowData): boolean {
    return !row.isPseudoObject && !this.timeframeIsLocked(row);
  }
}
