import { Component, inject, Input } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { ObjectHierarchy } from '../object-hierarchy-nav/object-hierarchy-nav.type';
import { ObjectHierarchyService } from '../../services/object-hierarchy.service';

export interface HierarchyItem {
  id: number;
  name: string;
  objectType: string;
  onClick?: (id: number) => void;
  flipIcon?: boolean;
  isClosed: boolean;
}

@Component({
  selector: 'object-hierarchy-menu',
  templateUrl: './object-hierarchy-menu.component.html',
  styleUrls: ['./object-hierarchy-menu.component.scss']
})
export class ObjectHierarchyMenuComponent {
  private readonly configuration = inject(Configuration);
  private readonly objectHierarchyService = inject(ObjectHierarchyService);

  public isOpened = false;
  public parent: HierarchyItem;
  public hierarchyItems: HierarchyItem[];
  private OBJECT_TYPES = this.configuration.OBJECT_TYPES;

  @Input() set objectHierarchy(items: ObjectHierarchy) {
    this.hierarchyItems = this.objectHierarchyService.createMenuHierarchy(items);
    this.setParent(this.hierarchyItems);
  };

  setOpenState(state: boolean): void {
    this.isOpened = state;
  }

  setParent(items: HierarchyItem[]) {
    const parentTypes = [this.OBJECT_TYPES.program, this.OBJECT_TYPES.campaign, this.OBJECT_TYPES.segment, this.OBJECT_TYPES.sharedCostRule];
    this.parent = [...items].reverse().find(el => parentTypes.includes(el.objectType));
  }
}
