import {
  ChangeDetectorRef,
  Directive,
  ElementRef, Inject,
  Input,
  OnChanges, OnDestroy,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[stringTruncate]'
})
export class StringTruncateDirective implements OnChanges, OnDestroy {
  @Input('stringTruncate') sourceString;
  @Input() prefix: string;
  @Input() linesTotal = 2;

  sizeObserver: ResizeObserver;
  wrapper: HTMLElement;
  prefixNode: HTMLElement;
  dots = this.renderer.createText('...');
  lineHeight: number;

  lastIndex = 0;
  wordsArr: string[];
  readyStrings = [];
  currentWidth: number;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.sizeObserver = new ResizeObserver(entries => {
      if (this.currentWidth !== entries[0].contentRect.width) {
        this.currentWidth = entries[0].contentRect.width;
        this.updateString();
      }
    });

    this.sizeObserver.observe(this.el.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateString();
  }

  updateString() {
    this.lastIndex = 0;
    this.readyStrings = [];
    if (this.wrapper) {
      this.wrapper.innerText = ''; // reset innerText first, as removeChild works with delay
      this.renderer.removeChild(this.el.nativeElement, this.wrapper);
    }
    this.wrapper = this.renderer.createElement('div');
    this.renderer.appendChild(this.el.nativeElement, this.wrapper);

    this.setStyles();
    this.setLineHeight();

    if (this.prefix) {
      this.prefixNode = this.renderer.createElement('b');
      const prefixText = this.renderer.createText(this.prefix + ' ');
      this.renderer.appendChild(this.prefixNode, prefixText);
      this.renderer.appendChild(this.wrapper, this.prefixNode);
    }

    const wholeString = this.renderer.createText(this.sourceString);
    this.renderer.appendChild(this.wrapper, wholeString);

    if (this.wrapper.offsetHeight > this.lineHeight * this.linesTotal) {
      this.renderer.removeChild(this.wrapper, wholeString);
      if (this.prefix) {
        this.renderer.removeChild(this.wrapper, this.prefixNode);
      }
      this.fillByWords();
    }
  }

  fillByWords() {
    this.wordsArr = StringTruncateDirective.createWordsArray(this.sourceString);
    const str = this.renderer.createElement('span');

    if (this.prefix) {
      this.renderer.appendChild(str, this.prefixNode);
    }
    this.addOneWordToString(str);
  }

  private static createWordsArray(src: string): string[] {
    const splitBySpace = src.split(' ');
    const chunks = [];
    splitBySpace.forEach((word, i) => {
      let replaced: string;
      const isFirstWord = i === 0;
      if (word.includes('-') || word.includes('_')) {
        replaced = word.replace(/-/g, ' -');
        replaced = replaced.replace(/_/g, ' _');
        let oneWordArr = replaced.split(' ');
        oneWordArr = oneWordArr.filter(w => w.length);
        if (!isFirstWord) {
          oneWordArr[0] = ' ' + oneWordArr[0];
        }
        chunks.push(...oneWordArr);
      } else {
        const space = isFirstWord ? '' : ' ';
        chunks.push(space + word);
      }
    })
    return chunks;
  }

  addOneWordToString(str) {
    const w = this.renderer.createText(this.wordsArr[this.lastIndex]);
    this.renderer.appendChild(str, w);
    this.renderer.appendChild(this.wrapper, str);

    if (this.wrapper.offsetHeight > this.lineHeight + (this.lineHeight * this.readyStrings.length)) {
      // string is full, start new with current index
      if (this.readyStrings.length < this.linesTotal - 1) {
        this.renderer.removeChild(str, w);
        this.readyStrings.push(str);
        const nextStr = this.renderer.createElement('span');
        this.addOneWordToString(nextStr);
      } else {
        // was last string, try to add dots
        if (this.lastIndex !== 0) {
          this.renderer.removeChild(str, w);
        }
        this.endLine(str);
      }
    } else {
      // still one line, try to add one more word
      this.lastIndex++;
      this.addOneWordToString(str);
    }
  }

  endLine(strNode) {
    this.renderer.setStyle(strNode, 'white-space', 'nowrap');
    this.renderer.appendChild(strNode, this.dots);

    if (strNode.offsetWidth + 1 > this.wrapper.offsetWidth) { // +1 for avoid blinking
      this.renderer.removeChild(strNode, this.dots);
      const charactersForDelete = 4;
      const newText = this.renderer.createText(strNode.innerText.substring(0, strNode.innerText.length - charactersForDelete));
      const newString = this.renderer.createElement('span');
      this.renderer.appendChild(newString, newText);

      this.renderer.removeChild(this.wrapper, strNode);
      this.renderer.appendChild(this.wrapper, newString);
      this.endLine(newString);
    } else {
      this.cdr.detectChanges();
    }
  }

  setStyles() {
    this.renderer.setStyle(this.wrapper, 'margin', 0);
    this.renderer.setStyle(this.wrapper, 'padding', 0);
    this.renderer.setStyle(this.wrapper, 'font-family', 'inherit');
    this.renderer.setStyle(this.wrapper, 'font-size', 'inherit');
    this.renderer.setStyle(this.wrapper, 'line-height', 'inherit');
    this.renderer.setStyle(this.wrapper, 'white-space', 'initial');
    this.renderer.setStyle(this.wrapper, 'word-break', 'normal');
    this.renderer.setStyle(this.wrapper, 'overflow', 'hidden');
    this.renderer.setStyle(this.wrapper, 'text-overflow', 'ellipsis');
  }

  setLineHeight() {
    const testChar = this.renderer.createText('A');
    this.renderer.appendChild(this.wrapper, testChar);
    this.lineHeight = this.wrapper.offsetHeight;
    this.renderer.removeChild(this.wrapper, testChar);
  }

  ngOnDestroy() {
    this.sizeObserver.unobserve(this.el.nativeElement);
  }
}
