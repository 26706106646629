<header mat-dialog-title>Upload status</header>
<div mat-dialog-content>
  <ng-container *ngIf="invoiceUploadManager.uploadStatusMap$ | async as statusMap">
    <table *ngIf="invoiceUploadManager.watchedFiles$ | async as watchedFiles">
      <tr *ngFor="let watchedFile of watchedFiles">
        <td>
          <div class="file-name-container">
            <loader size="16px" [isInline]="true" class="mr-05" [isVisible]="isProcessing(watchedFile, statusMap) || isUploading(watchedFile, statusMap)"></loader>
            <fa-icon
              *ngIf="statusMap[watchedFile.trackId] === uploadStatuses.SUCCEEDED"
              class="success"
              [icon]="['fas', 'circle-check']"
            ></fa-icon>
            <fa-icon
              *ngIf="isFailed(watchedFile, statusMap)"
              class="error"
              [icon]="['fas', 'circle-exclamation']"
            ></fa-icon>
            <span class="file-name">{{ watchedFile.file?.name }}</span>
          </div>
        </td>
        <td>
          <div [ngSwitch]="statusMap[watchedFile.trackId]" class="file-status">
            <span *ngSwitchCase="uploadStatuses.SUCCEEDED" class="success">Success!</span>
            <span *ngSwitchCase="uploadStatuses.FAILED" class="error">{{ watchedFile.errorMessage || 'Error' }}</span>
            <span *ngSwitchCase="uploadStatuses.UPLOADING">Uploading...</span>
            <span *ngSwitchCase="uploadStatuses.RUNNING">Processing...</span>
          </div>
        </td>
        <td class="actions">
          <ng-container *ngIf="isFailed(watchedFile, statusMap)">
            <link-button (onClick)="retryProcessing(watchedFile)">
              <span class="link-button-content">Retry</span>
            </link-button>
            <link-button (onClick)="clearError(watchedFile.trackId)">
              <span class="link-button-content">Clear</span>
            </link-button>
          </ng-container>
        </td>
      </tr>
    </table>
  </ng-container>
</div>
<div mat-dialog-actions align="end" class="dialog-actions">
  <button
    color="primary"
    mat-button
    matTooltip="Overrides your sorting"
    [disabled]="invoiceUploadManager.isUploadInProgress$ | async"
    [matTooltipShowDelay]="1000"
    matTooltipClass="dark-theme-tooltip simple"
    matTooltipPosition="above"
    [mat-dialog-close]="true"
    (click)="data.submitAction?.handler()"
  >
    View new invoices
  </button>
  <button
    mat-flat-button
    color="primary"
    class="ml-1"
    [mat-dialog-close]="true"
  >
    Close
  </button>
</div>

