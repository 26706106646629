import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TagsManagementService } from './tags-management.service';
import { CreateTagModalComponent, CreateTagModalContext } from './create-tag-modal/create-tag-modal.component';
import { Validations } from 'app/app.validations';
import { Configuration } from 'app/app.constants';
import { UtilityService } from 'app/shared/services/utility.service';
import { TagService } from 'app/shared/services/backend/tag.service';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { filter, tap } from 'rxjs/operators';
import { AppDataLoader } from 'app/app-data-loader.service';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'tags-management',
    templateUrl: './tags-management.component.html',
    styleUrls: ['./tags-management.component.scss'],
    providers: [
        TagsManagementService,
        AppDataLoader
    ],
})
export class TagsManagementComponent implements OnInit, OnDestroy {
    private selectedBudget: Budget = null;
    companyId: number;
    subscriptions = [];
    tagsList: any[];
    selectedTag = null;
    MAX_TAG_NAME_LENGTH = 45;

    constructor(
        public tagsManagementService: TagsManagementService,
        public tagService: TagService,
        public validations: Validations,
        public router: Router,
        public configuration: Configuration,
        public _utilityService: UtilityService,
        private readonly dialogManager: BudgetObjectDialogService,
        private readonly companyDataService: CompanyDataService,
        private readonly budgetDataService: BudgetDataService,
        private readonly appDataLoader: AppDataLoader,
        private readonly dialog: MatDialog
    ) { }

    ngOnInit() {
        this.subscriptions.push(
            this.companyDataService.selectedCompany$
                .pipe(filter(cmp => cmp != null))
                .subscribe(
                    cmp => {
                        this.tagsManagementService.getChunkOfTags(this.companyId = cmp.id);
                        this.companyDataService.loadCompanyData(this.companyId);
                    }
                ),
            this.tagsManagementService.tagsList$
                .subscribe(tagsList => {
                    this.tagsList = tagsList.map(tag => ({ ...tag, value: tag.name }))
                })
        );

        this.subscriptions.push(
            this.budgetDataService.selectedBudget$
                .pipe(
                    tap(newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)),
                )
                .subscribe({
                    error: (error) => this._utilityService.handleError(error)
                })
        );

        this.appDataLoader.init();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
    }

    private onSelectNewBudget(newSelectedBudget: Budget) {
        this.selectedBudget = newSelectedBudget;
        if (newSelectedBudget != null) {
            this.budgetDataService.loadLightCampaigns(
                this.companyId,
                this.selectedBudget.id,
                this.configuration.campaignStatusNames.active,
                error => this._utilityService.handleError(error)
            );

            this.budgetDataService.loadLightPrograms(
                this.companyId,
                this.selectedBudget.id,
                this.configuration.programStatusNames.active,
                error => this._utilityService.handleError(error)
            );
        }
    }

    changeSorting(sortBy: string) {
        this.tagsManagementService.changeListOrdering(sortBy);
        this.tagsManagementService.getChunkOfTags(this.companyId);
    }

    onFocusTagNameField(tagId: number) {
        // call focus handler after prev input blur event fired
        setTimeout(() => {
            this.selectedTag = tagId;
        }, 0);
    }

    onBlurTagNameField(event: FocusEvent, tagId: number) {
        const tagObj = this.tagsList.find(tag => tag.id === tagId);
        const value = tagObj?.value.trim() || '';
        const isValidNameLength = value.length && !(value.length > this.MAX_TAG_NAME_LENGTH);
        const updated = value && value !== tagObj.name;
        const isSelectedTag = this.selectedTag === tagId;
        if (!isValidNameLength) {
            this.onTagValidationFailed(value);
            tagObj.value = tagObj.name;
        } else {
            if (isSelectedTag && updated) {
                this.tagService.validateUniqueTagName(
                    tagObj.value,
                    () => this.tagsManagementService.updateTag(tagObj.value, tagId, this.companyId),
                    () => {
                        this.onTagValidationFailed(value, false);
                        tagObj.value = tagObj.name;
                    }
                )
            }
        }
        this.selectedTag = null;
    }

    onTagValidationFailed(name, isUnique?) {
        const errorMessage = { Title: 'Validation Error', Message: '', Type: 'error' };
        if (name.length === 0) {
            errorMessage['Message'] = this.validations.ValiditionMessages.TAG_NAME_REQUIRED;
        }
        if (name.length > this.MAX_TAG_NAME_LENGTH) {
            errorMessage['Message'] = this.validations.ValiditionMessages.TAG_NAME_MAX_LENGTH_REACHED;
        }
        if (isUnique === false) {
            errorMessage['Message'] = this.validations.ValiditionMessages.TAG_EXIST;
        }
        this._utilityService.showToast(errorMessage);
    }

    openCreateTagModal() {
        const dialogData: CreateTagModalContext = {
            onSubmit: (tagName, cb) => this.createNewTag(tagName, cb)
        };

        this.dialog.open(CreateTagModalComponent, {
            width: '700px',
            data: dialogData,
            autoFocus: false
        });
    }

    createNewTag(tagName, cb): any {
        return this.tagsManagementService.createNewTag(tagName, this.companyId, cb);
    }

    onRemoveTag(tag) {
        if (!tag.usageCount) {
            this.tagsManagementService.removeTagById(tag.id);
        } else {
            const content = `
      This will delete the tag '${tag.name}' and all of its active references.
      This action cannot be undone.<br/><br/>Do you want to delete this tag?
      `;
            this.dialogManager.openConfirmationDialog({
                content,
                cancelAction: {
                    label: 'Cancel',
                    handler: () => null
                },
                submitAction: {
                    label: 'Delete',
                    handler: () => this.tagsManagementService.removeTagById(tag.id)
                }
            });
        }
    }

    redirectToSearchByTag(tagName: string) {
        const searchTerm = this.configuration.SEARCH_PREFIXES.TAG + tagName;
        localStorage.setItem('searchTerm', searchTerm);
        this.router.navigate([this.configuration.ROUTING_CONSTANTS.SEARCH]);
    }
}

