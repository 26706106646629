import {
  BudgetAllocationAction,
  BudgetAllocationActionParams,
  UndoCallback
} from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';


interface BasicActionParams extends BudgetAllocationActionParams {
  execute: Function;
  undo: Function;
  undoCallbacks?: UndoCallback[];
}

export class ManageTableBasicAction extends BudgetAllocationAction<BasicActionParams> {
  constructor(params: BasicActionParams) {
    super();
    this.actionParams = params;
  }

  public execute(): void {
    this.actionParams?.execute();
    this.onExecuted?.(
      this.onExecutedMessage,
      this.undo.bind(this)
    );
  }

  public undo(): void {
    const { undo, undoCallbacks } = this.actionParams;

    if (undo) {
      undo();
      this.runCallbacks(undoCallbacks).subscribe();
    }
  }
}
