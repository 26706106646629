<div class="history-widget-table">
  <div
    class="history-table"
    *ngIf="data?.length"
    [ngClass]="{
      'is-scrollable': data.length >= VISIBLE_ROWS_LIMIT,
      'is-scrolled': isBodyScrolled
    }"
  >
    <div class="table-head">
      <div class="table-row">
        <div class="table-cell name-cell">Name</div>
        <div class="table-cell updated-cell">Last Updated</div>
        <div class="table-cell visited-cell">Last Visited</div>
      </div>
    </div>
    <div
      class="table-body"
      isScrolled
      (isScrolledChanged)="handleBodyScrolledChange($event)"
    >
      <div
        class="table-row"
        *ngFor="let item of tableData; trackBy: trackFn"
      >
        <div class="table-cell name-cell">
          <div
            class="object-link"
            (click)="openDetails(item)"
            [matTooltip]="item.name + (item.metricOrigin ? (' from ' + item.metricOrigin?.name) : '')"
            matTooltipPosition="above"
            matTooltipClass="dark-theme-tooltip above"
          >
            <div class="object-title">
              <div class="title-icon">
                <fa-icon class="icon" [icon]="iconByObjectType[item.type]"></fa-icon>
              </div>
              <div *ngIf="!item.metricOrigin" class="title-name">
                {{ item.name }}
              </div>
              <div *ngIf="item.metricOrigin" class="title-name">
                <span>{{ item.name | truncate:65:'...' }}</span>
                <i>from</i>
                <span>{{ item.metricOrigin.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="table-cell updated-cell">
          <ng-container *ngIf="this.updatedDates?.get(item.type + '_' + item.objectId)">
            {{ this.updatedDates?.get(item.type + '_' + item.objectId) | date: 'mediumDate' }}
          </ng-container>
        </div>
        <div class="table-cell visited-cell">
          {{ item.createdDate | date: 'mediumDate' }}
        </div>
      </div>
    </div>
  </div>
</div>
