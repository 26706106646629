import { ObjectLocalFilter } from '../filters.interface';

export class ObjectLocalFilterGroup<T> implements ObjectLocalFilter<T> {
  private filters: ObjectLocalFilter<T>[] = [];

  constructor(filters: ObjectLocalFilter<T>[]) {
    this.filters = filters;
  }

  satisfies(object: T): boolean {
    return this.filters.length ? this.filters.every(filter => filter.satisfies(object)) : true;
  }
}
