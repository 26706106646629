import { UserProfileDO } from '../types/user-profile.interface';
import { BudgetPermission, BudgetPermissionDO, UserPermission } from '../types/user-permission.type';

const NAME_SEPARATOR = ' ';

export const defineUserPermission = (budgetPermission, isAdmin): UserPermission => {
  const { Admin, ReadWrite, ReadOnly } = UserPermission;
  let permission = ReadOnly;
  if (isAdmin) {
    permission = Admin;
  } else if (budgetPermission.read_write) {
    permission = ReadWrite;
  }
  return permission;
};

export const adaptBudgetPermission = (budgetPermission: BudgetPermissionDO, isAdmin: boolean): BudgetPermission => {
  return {
    permission: defineUserPermission(budgetPermission, isAdmin),
    budget: { id: budgetPermission.budget_id, name: budgetPermission.budget_name },
    segments: budgetPermission.segments.map(segm => ({ id: segm.id, name: segm.name }))
  }
};

export const createUsersProfilesMap = (usersProfiles: UserProfileDO[]) => {
  return usersProfiles.reduce((res, user) => {
    res[user.user] = { ...user };
    return res;
  }, {})
};

export const getInitialsFromName = (name: string) => {
  const [ firstName = '', lastName = '' ] = (name || '').split(NAME_SEPARATOR);
  const firstNameSymbol = firstName[0] ? firstName[0].toUpperCase() : '';
  const lastNameSymbol = lastName[0] ? lastName[0].toUpperCase() : '';
  return firstNameSymbol + lastNameSymbol;
};
