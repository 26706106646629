import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExternalMetrics } from './external-metrics.types';
import { ExternalCampaignData } from './external-campaign.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { PlannuhCampaignMetric } from './metric-integration';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { ExternalMetricTypesMapping } from './external-metric-types-mapping.interface';

interface GetExternalCampaignsParams<TObjMapping> {
  companyId: number;
  campaignId: number;
  plannuhCampaignMetrics: PlannuhCampaignMetric[];
  dateFrom: string;
  budget?: Budget;
  mapping?: TObjMapping;
  allCampaigns: LightCampaign[];
  integrationId: string;
}

@Injectable()
export abstract class ExternalMetricProviderApiService<TObjMapping> {
  abstract getExternalMetrics(companyId: number, integrationId: string): Observable<ExternalMetrics>;
  abstract getExternalCampaigns(params: GetExternalCampaignsParams<TObjMapping>): Observable<ExternalCampaignData>;
  abstract getRawExternalCampaigns(companyId, integrationId, dateFrom): Observable<any>;

  protected getMetricTypeMappings(mapping: ExternalMetricTypesMapping, plannuhMetricIds: number[]): string[] {
    if (!mapping || !plannuhMetricIds?.length) {
      return [];
    }

    Object.keys(mapping).forEach(externalMetricName => {
      mapping[externalMetricName] = mapping[externalMetricName].filter(id => plannuhMetricIds.includes(id));
    })

    return Object.keys(mapping).filter(externalMetricName => mapping[externalMetricName].length);
  }
}
