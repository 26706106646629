<div class="list-item" [class.selected]="selected">
  <div class="list-content">
    <h3>{{listTitle}}</h3>
    <div class="list-configs">
      <span class="selection">{{selectionTypes[selectionType]}}</span>
      <span class="label"> <span class="dot"></span> Applied for</span>
      <ul class="applied-list">
        <li *ngIf="for_campaign">
          <span>
            <fa-icon [icon]="['far', 'rocket-launch']"></fa-icon>
          </span>
        </li>
        <li *ngIf="for_program">
          <span>
            <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>
          </span>
        </li>
        <li *ngIf="for_expense">
          <span>
            <fa-icon [icon]="['fas', 'coins']"></fa-icon>
          </span>
        </li>
      </ul>
    </div>
  </div>
  <span class="draft-state" *ngIf="is_draft">
    Draft
  </span>
  <span class="action-item" *ngIf="showActions">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
      <fa-icon [icon]="['far', 'ellipsis-v']"></fa-icon>
    </button>
    <mat-menu #menu="matMenu" class="mat-pl-menu mat-menu-panel" xPosition="before">
      <button mat-menu-item (click)="deleteEvent()">
        <fa-icon class="icon red-delete-icon" [icon]="['fas', 'trash']"></fa-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </span>
</div>

<ng-template #captchaTemplate>
  <div class="captcha-content">
    <p>Enter the word ‘DELETE’ below to proceed</p>
    <input type="text" placeholder="DELETE" [(ngModel)]="captchaInput" (input)="onCaptchaInputChange()" />
  </div>
</ng-template>