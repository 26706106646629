import { Component, Input } from '@angular/core';
import { MenuPanelItem } from 'app/header-navigation/components/menu-panel/menu-panel.type';
import { BudgetSettingsPageService } from '../budget-settings-page/budget-settings-page.service';
import { BudgetTableService } from '../../services/budget-table.service';
import { BudgetTableContextMenuService } from '../../services/budget-table-context-menu.service';
import { BudgetTableActionType } from '../budget-table/budget-table.types';


@Component({
  selector: 'budget-manage-menu',
  templateUrl: './budget-manage-menu.component.html',
  styleUrls: ['./budget-manage-menu.component.scss']
})
export class BudgetManageMenuComponent {
  private segmentMenuHandlers = {
    [BudgetTableActionType.SegmentsGroup]: () => this.budgetSettingsPageService.groupSelectedSegments(),
    [BudgetTableActionType.SegmentsUngroup]: () => this.budgetSettingsPageService.ungroupSelectedSegments(),
    [BudgetTableActionType.SegmentsMove]: (group) => this.budgetSettingsPageService.moveSelectedSegments(group),
    [BudgetTableActionType.GroupDuplicate]: () => this.budgetSettingsPageService.duplicateSelectedGroups(),
    [BudgetTableActionType.SegmentDuplicate]: () => this.budgetSettingsPageService.duplicateSelectedSegments(),
    [BudgetTableActionType.SegmentDelete]: () => this.budgetSettingsPageService.removeSelectedSegments(),
  };
  private createBudgetMenuItem: MenuPanelItem = {
    label: 'Create New Budget',
    faIcon: ['fad', 'sack-dollar'],
    action: () => this.budgetSettingsPageService.openBudgetSettingsModal(),
  };

  items: MenuPanelItem[] = [this.createBudgetMenuItem];

  @Input() set hasCurrentBudget(value: boolean) {
    this.items = value ? this.generateMenu() : [this.createBudgetMenuItem];
  }

  constructor(
    private readonly budgetSettingsPageService: BudgetSettingsPageService,
    private readonly budgetTableService: BudgetTableService,
    private readonly budgetTableContextMenuService: BudgetTableContextMenuService,
  ) {}

  private generateMenu(): MenuPanelItem[] {
    const segmentsMenu = this.budgetTableContextMenuService.generateManageMenu(this.segmentMenuHandlers);

    return [
      this.createBudgetMenuItem,
      {
        label: 'Duplicate Budget',
        faIcon: ['fad', 'copy'],
        action: () => this.budgetSettingsPageService.duplicateBudget(),
      },
      {
        label: 'Edit Budget Details',
        faIcon: ['fad', 'pen'],
        isDivider: true,
        action: () => this.budgetSettingsPageService.editBudget(),
      },
      {
        label: 'Segments:',
        faIcon: null,
        isDivider: true,
        customCssClass: 'group-title',
      },
      ...segmentsMenu,
      {
        label: 'Delete Budget',
        faIcon: ['fad', 'trash-alt'],
        action: () => this.budgetSettingsPageService.deleteBudget(),
        isDivider: true,
        customCssClass: 'item-danger'
      },
    ];
  }
}
