import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'isoDate'})
export class IsoDatePipe implements PipeTransform {
  transform(date: any) {
    const dateTime = date.split('T');
    const datePart = dateTime[0].split('-');
    const time = dateTime[1].split(':');
    const month = datePart[1] - 1;
    return new Date(datePart[0], month, datePart[2], time[0], time[1], Number.parseInt(time[2], 10));
  }
}
