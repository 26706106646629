import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MetricMappingDetailsComponent } from '../../../types/metric-mapping-details-component.interface';
import { MetricMappingDetailsState } from '../../../types/metric-mapping-details-state.interface';
import { DetailsCreationContext, MetricMappingCreationContext } from '../../../types/details-creation-context.interface';
import { combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { messages, objectPlaceholderName } from '../../../messages';
import { UtilityService } from 'app/shared/services/utility.service';
import { BudgetObjectDetailsManager } from '../../../services/budget-object-details-manager.service';
import { BudgetObjectActionsBuilder } from '../../../services/budget-object-actions-builder.service';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { UserDataService } from 'app/shared/services/user-data.service';
import { ObjectHierarchy } from '../../object-hierarchy-nav/object-hierarchy-nav.type';
import { faRocketLaunch } from '@fortawesome/pro-duotone-svg-icons';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { MetricMappingDetailsService } from '../../../services/metric-mapping-details.service';
import { Goal, GoalDO } from 'app/shared/types/goal.interface';
import { MetricMappingDO, MetricProgressTowardsTargetDO } from 'app/shared/services/backend/metric.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { MetricBreakdown } from '../../../types/metric-breakdown-data.interface';
import { DetailsAction } from '../../details-header/details-header.type';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Budget } from 'app/shared/types/budget.interface';
import { MetricMilestones } from '../../../types/metric-milestone.interface';
import { MetricsUtilsService } from '../../../services/metrics-utils.service';
import { ObjectHierarchyService } from '../../../services/object-hierarchy.service';
import { MetricMilestonesListComponent } from '../../metric-milestones-list/metric-milestones-list.component';
import { DataValidationService } from 'app/budget-object-details/services/data-validation.service';
import { createDateString, parseDateString } from '../campaign-details/date-operations';
import { CompanyDO } from 'app/shared/types/company.interface';
import { createDeepCopy } from 'app/shared/utils/common.utils';
import { MetricGraphData } from '../../../types/metric-graph-data.interface';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { MetricMappingCalculationService } from '../../../services/metric-mapping-calculation.service';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { CampaignDO } from 'app/shared/types/campaign.interface';
import { SelectItem } from 'app/shared/types/select-groups.interface';
import { getMetricSelectItems } from '../../details-metrics/metric-masters-list/metric-masters-list.component';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { UserDO } from '@shared/types/user-do.interface';
import { MetricUpdateService } from 'app/budget-object-details/services/metric-update.service';
import { MetricUpdateAction } from 'app/budget-object-details/types/metric-update-action.enum';

const UPDATE_SUMMARY_ERROR_MSG = 'Failed to update goal metric summary';

@Component({
  selector: 'goal-metric-details',
  templateUrl: './goal-metric-details.component.html',
  styleUrls: ['../details-container.scss', './goal-metric-details.component.scss']
})
export class GoalMetricDetailsComponent implements MetricMappingDetailsComponent, OnInit, OnDestroy {
  public readonly mappingType = this.configuration.OBJECT_TYPES.goal;
  public isReadOnlyMode: boolean;
  public currentState: MetricMappingDetailsState;
  private prevState: MetricMappingDetailsState = null;

  private readonly destroy$ = new Subject<void>();
  private readonly reset$ = new Subject<void>();
  private isLoading = false;

  public currentUser: UserDO;
  public editPermission = false;
  public unsavedChangesFlag = false;

  public companyId: number;
  public budget: Budget;

  public hierarchy: ObjectHierarchy = {
    Goal: null,
    Program: null,
    Campaign: null,
    Expense: null,
    Metric: null
  };

  private goal: GoalDO;
  private goalCampaignsMap = new Map<number, CampaignDO>();
  private goalChildCampaignsMap = new Map<number, CampaignDO>();
  private goalMetricMappings: MetricMappingDO[] = [];

  public company: CompanyDO;
  public metrics: MetricType[] = [];
  public masterMetricsSelectItems: SelectItem[] = [];
  private products: ProductDO[];

  public goals: Goal[] = [];
  public metricGoal: Goal;
  public menuActions: DetailsAction[] = [];

  public readonly objectType = this.configuration.OBJECT_TYPES.metric;
  public readonly parentObjectType = this.configuration.OBJECT_TYPES.goal;
  public metricProgressTowardsTarget: MetricProgressTowardsTargetDO;

  private metricBreakdownDataTemplate: MetricBreakdown = {
    campaignMetrics: {
      title: 'Campaign Metrics',
      icon: faRocketLaunch,
      data: [],
      totalRow: null
    },
    grandTotal: null
  };

  public metricBreakdownData: MetricBreakdown = this.metricBreakdownDataTemplate;
  public graphData: MetricGraphData;
  public budgetCurrencySymbol = '';
  public displayDecimal = false;
  public metricValueLastUpdated = '';

  formData: UntypedFormGroup;

  private readonly backUrl: string;
  private readonly metricUpdateService = inject(MetricUpdateService);

  @ViewChild('milestonesControl') milestonesControl: MetricMilestonesListComponent;

  private static getStartDateStr (formStartDate: Date | string) {
    return (typeof formStartDate === 'object' ? createDateString(formStartDate) : formStartDate) || null;
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly configuration: Configuration,
    private readonly utilityService: UtilityService,
    public readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly userDataService: UserDataService,
    public readonly appRoutingService: AppRoutingService,
    private readonly companyDataService: CompanyDataService,
    private readonly metricMappingDetailsService: MetricMappingDetailsService,
    private readonly userManager: UserManager,
    private readonly menuActionsBuilder: BudgetObjectActionsBuilder,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly metricsUtilsService: MetricsUtilsService,
    private readonly hierarchyService: ObjectHierarchyService,
    private readonly fb: UntypedFormBuilder,
    private readonly dataValidation: DataValidationService,
    private readonly metricMappingCalculationService: MetricMappingCalculationService
  ) {
    this._createForm();

    this.backUrl = this.router.getCurrentNavigation().extras?.state?.data?.backUrl;

    this.companyDataService.selectedCompanyDO$
      .pipe(takeUntil(this.destroy$))
      .subscribe(company => {
        this.company = company;
        this.budgetCurrencySymbol = this.company?.currency_symbol;
      });
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => this.init(params))
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get hasChildCampaigns(): boolean {
    return !!this.goalCampaignsMap.size;
  }

  hasUnsavedChanges(): boolean {
    if (!this.currentState) {
      return false;
    }
    this.saveFormData();
    return this.metricMappingDetailsService.hasChanges(this.prevState, this.currentState);
  }

  saveChanges(onSavedCb?: Function): void {
    this.showLoader();

    this.metricMappingDetailsService.saveDetails(this.prevState, this.currentState).pipe(
     switchMap(() =>
       this.metricMappingDetailsService.getMetricProgressTowardsTarget(this.currentState.objectId)
         .pipe(tap(updates => this.metricProgressTowardsTarget = updates))
     )
   ).subscribe(
     () => {
       this.onSavedSuccessfully();
       onSavedCb?.();
     },
     error => this.onError(
       error,
       messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric')
     )
   );
  }

  getContextForChildObjectCreation(): DetailsCreationContext {
    return null;
  }

  private buildHierarchy(state) {
    const targetObject = {
      id: this.goal.id,
      name: this.goal.name
    };
    this.hierarchy = this.hierarchyService.buildMetricHierarchy(
      state.objectId,
      MetricMappingDetailsService.getFullMetricName(state),
      targetObject,
      this.mappingType,
      {}
    );
  }

  init(routeParams: Params) {
    this.reset$.next();

    this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot) ?
      this.initGoalMetricCreation() :
      this.initGoalMetricLoading(routeParams);

    this.userDataService.editPermission$
      .pipe(takeUntil(merge(this.destroy$, this.reset$)))
      .subscribe((editPermission: boolean) => {
        this.editPermission = editPermission;
        this.updateReadOnlyModeState();
      });
  }

  private initGoalMetricLoading(routeParams: Params) {
    const metricMappingIdParam = routeParams && routeParams[AppRoutingService.DETAILS_OBJECT_ID_PARAM_NAME];
    const metricMappingId = metricMappingIdParam && Number.parseInt(metricMappingIdParam, 10);
    if (metricMappingId) {
      this.loadGoalMetric(metricMappingId);
    } else {
      this.onError(
        '[Goal Metric Details]: init(): incorrect metric mapping id provided: ' + metricMappingId,
        messages.UNABLE_TO_LOAD_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric'),
        true
      )
    }
  }

  private initGoalMetricCreation(reset: boolean = false) {
    this.showLoader();
    const contextData = AppRoutingService.getHistoryStateProperty<MetricMappingCreationContext>('data');
    this.metricMappingDetailsService.checkMetricCreationPossible$(contextData, this.mappingType).pipe(
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      () => this.prepareDataForGoalMetricCreation(reset, contextData),
      error => this.onError(error.error, error.message, true)
    )
  }

  private prepareDataForGoalMetricCreation(reset: boolean, contextData: MetricMappingCreationContext): void {
    const budgetData$: Observable<[number, Budget]> = reset ? of([this.companyId, this.budget]) : this.loadInitialBudgetData();

    budgetData$.pipe(
      switchMap(([companyId]) =>
        combineLatest([
          this.metricMappingDetailsService.initDetails(
            contextData,
            {
              mapping_type: this.mappingType,
              start_date: this.metricMappingDetailsService.getDefaultStartDate(this.budget?.budget_from)
            }
          ).pipe(
            switchMap(state => this.loadChildObjectsAndMetricMappings(state))
          ),
          this.loadDetailsContextData(companyId)
        ])
      ),
      switchMap(([state]) => this.applyMetricType$(state)),
      map(state => {
        this.buildHierarchy(state);
        state.milestones =
          this.metricMappingDetailsService.initMetricMilestones(this.budget?.budget_to);
        return state;
      }),
      switchMap(state =>
        this.metricMappingDetailsService.loadMetricGoal(state.parentId).pipe(
          tap(goal => this.metricGoal = goal),
          map(() => state)
        )
      ),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => this.onGoalMetricLoaded(state, false),
      error => this.onError(
        error,
        messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric'),
        true
      )
    );
  }

  private loadGoalMetric(metricMappingId: number) {
    this.showLoader();
    this.loadInitialBudgetData().pipe(
      switchMap(([companyId, budget]) =>
        combineLatest([
          this.metricMappingDetailsService.loadDetails(companyId, budget.id, metricMappingId).pipe(
            switchMap(state => this.loadChildObjectsAndMetricMappings(state))
          ),
          this.metricMappingDetailsService.getMetricProgressTowardsTarget(metricMappingId),
          this.loadDetailsContextData(companyId)
        ])
      ),
      switchMap(([state, progressData]) =>
        this.metricMappingDetailsService.loadMetricGoal(state.parentId).pipe(
          tap(goal => this.metricGoal = goal),
          map(() => ({state, progressData}))
        )
      ),
      switchMap(({state, progressData}) => this.applyMetricType$(state).pipe(map(() => ({state, progressData})))),
      map(({state, progressData}) => {
        this.metricProgressTowardsTarget = progressData;
        this.buildHierarchy(state);
        return state;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe(
      state => this.onDataForMetricLoadingPrepared(state),
      error => this.onError(
        error,
        messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric'),
        true
      )
    );
  }

  private onDataForMetricLoadingPrepared(state) {
    this.currentState = state;
    this.addMissingData();

    const alreadyMappedIds = this.goalMetricMappings.map(mapping => mapping.metric_detail.id);
    const availableMetrics = this.metrics
      .filter(metric => metric.id === this.currentState.metricId || !alreadyMappedIds.includes(metric.id));
    this.masterMetricsSelectItems = getMetricSelectItems(this.products, availableMetrics);

    this.onGoalMetricLoaded(state);
    this.budgetObjectDetailsManager.logObjectView(
      this.currentState.objectId,
      this.companyId,
      this.budget.id,
      this.currentUser.id,
      HistoryObjectLogTypeNames.metricMapping,
      this.metricMappingDetailsService);
  }

  selectMetric(metricId: number) {
    this.currentState.metricId = metricId;
    const prevRevenuePerOutcome = this.currentState.revenuePerOutcome;
    const prevRevenueToProfit = this.currentState.revenueToProfit;

    this.applyMetricType$(this.currentState).subscribe(
      () => {
        if (prevRevenuePerOutcome !== this.currentState.revenuePerOutcome || prevRevenueToProfit !== this.currentState.revenueToProfit) {
          forkJoin([
            this.metricMappingDetailsService.updateTargetValue$(this.currentState, this.getTargetCost()),
            this.metricMappingDetailsService.updateROIForMetricCalculations$(this.currentState)
          ]).subscribe(() => this.setGraphDataRecords());
        }
      }
    );

    this.syncUnsavedChangesFlag();
  }

  updateCurrentSummary() {
    this.metricMappingDetailsService.updateCurrentSummary(this.currentState);
    this.currentState.currentValue = this.currentState?.summary?.totalValue || 0;
  }

  updateTargetSummary(onReady?: (isError?: boolean) => void) {
    this.metricMappingDetailsService.updateTargetValue$(this.currentState, this.getTargetCost()).subscribe(
      () => onReady?.(false),
      () => {
        onReady?.(true);
        console.log(UPDATE_SUMMARY_ERROR_MSG);
      }
    );
  }

  updateSummary(onReady?: (isError?: boolean) => void) {
    forkJoin([
      this.metricMappingDetailsService.updateTargetValue$(this.currentState, this.getTargetCost()),
      this.metricMappingDetailsService.setActualMetricCalculations$(this.currentState, this.getCurrentSpent()).pipe(
        tap(
          () => this.updateCurrentSummary()
        )
      )
    ]).subscribe(
      () => onReady?.(false),
      () => {
        onReady?.(true);
        console.log(UPDATE_SUMMARY_ERROR_MSG);
      }
    );
  }

  private getCurrentSpent(): number {
    return this.metricGoal && this.metricGoal.statusTotals &&
      (this.metricGoal.statusTotals.committed + this.metricGoal.statusTotals.closed) || 0;
  }

  private getTargetCost() {
    return this.metricGoal && this.metricGoal.statusTotals && this.metricGoal.statusTotals.total || 0;
  }

  private loadChildObjectsAndMetricMappings(metricMappingState: MetricMappingDetailsState): Observable<MetricMappingDetailsState> {
    return this.loadGoalCampaigns$(metricMappingState).pipe(
      switchMap(([goalCampaigns, goalChildCampaigns]) =>
        forkJoin([
          this.metricMappingDetailsService.getMetricMappings(
            this.companyId,
            { map_id: this.goal.id, mapping_type: this.mappingType }
          ).pipe(
            tap(mappings => this.goalMetricMappings = mappings)
          ),
          this.loadChildMetricMappings$(metricMappingState, goalCampaigns, goalChildCampaigns),
          this.budgetObjectDetailsManager.getProducts$().pipe(
            take(1),
            tap(products => this.products = products)
          ),
        ]).pipe(map(() => metricMappingState))
      )
    );
  }

  private onError(error, message, close = false) {
    this.hideLoader();
    this.budgetObjectDetailsManager.handleError(error, message, close);
  }

  private showLoader() {
    this.isLoading = true;
    this.utilityService.showLoading(true);
  }

  private hideLoader() {
    // A workaround needed for e2e tests driver to catch loader's disappearing
    setTimeout(() => {
      this.isLoading = false;
      this.utilityService.showLoading(false);
    });
  }

  private updateReadOnlyModeState() {
    this.isReadOnlyMode = !this.editPermission;
    this.updateMenuActions();
  }

  private syncUnsavedChangesFlag(flag?: boolean) {
    this.unsavedChangesFlag = typeof flag === 'boolean' ? flag : this.hasUnsavedChanges();
  }

  private onGoalMetricLoaded(state: MetricMappingDetailsState, syncState = true) {
    this.currentState = state;
    if (syncState) {
      this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(state);
    }
    this.buildMetricBreakDownData();
    this.setFormData();
    this.updateMenuActions();
    this.updateCurrentSummary();
    this.metricValueLastUpdated =
      this.metricMappingDetailsService.getLastUpdatedDateInfo(
        this.currentState.metricCalculations
      );

    this.updateSummary(isError => {
      if (!isError) {
        this.hideLoader();
        this.initGraphData();
      }
    });

    this.formData.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.syncUnsavedChangesFlag());
  }

  private initGraphData() {
    this.graphData = {
      startDate: this.currentState.startDate,
      milestones: this.currentState.milestones
    };
    this.setGraphDataRecords();
  }

  private setGraphDataRecords() {
    this.graphData.CPORecords = this.currentState.CPORecords;
    this.graphData.ROIRecords = this.currentState.ROIRecords;
    this.graphData.metricValueRecords = this.currentState.metricValueRecords;
    this.graphData.targetROI = this.currentState.summary.targetROI;
    this.graphData.targetCPO = this.currentState.summary.targetCPO;
  }

  public submitChanges(submitCallback) {
    const isFormValid = this.validateChanges();
    if (isFormValid && submitCallback) {
      submitCallback();
    }
  }

  public handleCancelAction() {
    this.appRoutingService.closeDetailsPage();
  }

  public handleSaveAction() {
    if (this.hasUnsavedChanges()) {
      this.saveChanges();
    }
  }

  public handleSaveAndNewAction() {
    const onSaved = this.appRoutingService.isCreateDetailsRoute(this.activatedRoute?.snapshot)
      ? this.resetDetails.bind(this)
      : () => this.appRoutingService.openGoalMetricCreation(this.getContextForNewMappingCreation());

    if (this.hasUnsavedChanges()) {
      this.saveChanges(onSaved);
    } else {
      onSaved();
    }
  }

  private getContextForNewMappingCreation(): DetailsCreationContext {
    return {
      parent: {
        id: this.currentState.parentId,
        type: this.mappingType
      }
    }
  }

  public handleSaveAndCloseAction() {
    if (this.hasUnsavedChanges()) {
      const onSaved = this.appRoutingService.closeDetailsPage.bind(this.appRoutingService);
      this.saveChanges(onSaved);
      return;
    }
    this.appRoutingService.closeDetailsPage();
  }

  public handleDelete() {
    if (!(this.currentState && this.currentState.objectId)) {
      return;
    }

    this.dialogManager.openDeleteEntityDialog(() => {
      this.metricMappingDetailsService.deleteObject(this.currentState.objectId).subscribe(
        () => {
          this.onSuccess(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, this.mappingType + ' metric'), true);
          this.metricMappingDetailsService.reportChange(this);
          this.metricUpdateService.triggerMetricUpdate({
            action: MetricUpdateAction.DELETE,
            objectId: this.goal.id,
            objectType: this.mappingType,
            metricMappingId: this.currentState.objectId,
            metricId: this.currentState.metricId,
            productId: this.metricMappingDetailsService.getProductByName(this.currentState.productName, this.products)?.id
          });
        },
        error => this.onError(
          error,
          messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.mappingType.toLowerCase() + ' metric'),
          true
        )
      );
    }, this.objectType.toLowerCase());
  }

  private resetDetails() {
    this.reset$.next();
    this.prevState = null;
    this.currentState = null;
    this.initGoalMetricCreation(true);
  }

  private loadInitialBudgetData() {
    return combineLatest([
      this.budgetObjectDetailsManager.getCompanyId().pipe(tap(companyId => this.companyId = companyId)),
      this.budgetObjectDetailsManager.getCurrentBudget().pipe(tap(budget => this.budget = budget))
    ]);
  }

  private loadDetailsContextData(companyId: number) {
    this.companyDataService.loadMetrics(
      companyId,
      error => this.onError(error, messages.UNABLE_TO_LOAD_METRICS_ERROR_MSG, true)
    );
    return combineLatest([
      this.companyDataService.selectedCompanyDO$,
      this.budgetObjectDetailsManager.getMetricTypes().pipe(tap(mTypes => this.metrics = mTypes)),
      this.budgetObjectDetailsManager.getGoals().pipe(tap(goals => this.goals = goals)),
      this.userManager.currentUser$.pipe(
        filter(user => !!user),
        tap(user => this.currentUser = user)
      )
    ]);
  }

  private applyMetricType$(state: MetricMappingDetailsState): Observable<MetricMappingDetailsState> {
    return this.metricMappingDetailsService.applyMetricType$(state, this.company, this.metrics, this.products).pipe(
      map(([metricMappingState, displayDecimal]) => {
        this.displayDecimal = displayDecimal;
        return metricMappingState;
      })
    );
  }

  private loadGoalCampaigns$(metricMappingState: MetricMappingDetailsState): Observable<[CampaignDO[], CampaignDO[]]> {
    return forkJoin([
      this.metricMappingDetailsService.loadGoalCampaigns(metricMappingState.parentId, this.budget.id).pipe(
        tap(campaigns =>
          campaigns.forEach(campaign => this.goalCampaignsMap.set(campaign.id, campaign))
        ),
        switchMap(campaigns => this.loadChildCampaigns$(campaigns))
      ),
      this.metricMappingDetailsService.loadGoal(metricMappingState.parentId).pipe(
        tap(goal => this.goal = goal)
      )
    ]).pipe(
      map(([[goalCampaigns, childCampaigns]]) => [goalCampaigns, childCampaigns])
    );
  }

  private loadChildCampaigns$(campaigns: CampaignDO[]): Observable<[CampaignDO[], CampaignDO[]]> {
    const childCampaigns$ = campaigns && campaigns.length
      ? this.metricMappingDetailsService.loadChildCampaigns(
        campaigns.map(campaign => campaign.id),
        this.budget.id
      )
      : of([]);

    return childCampaigns$.pipe(
      tap(childCampaigns =>
        childCampaigns.forEach(campaign => this.goalChildCampaignsMap.set(campaign.id, campaign))
      ),
      map(childCampaigns => [campaigns, childCampaigns])
    );
  }

  private loadChildMetricMappings$(
    metricMappingState: MetricMappingDetailsState,
    goalCampaigns: CampaignDO[],
    goalChildCampaigns: CampaignDO[],
  ): Observable<MetricMappingDetailsState> {
    if (!metricMappingState.metricId) {
      return of(metricMappingState);
    }
    return forkJoin([
      this.metricMappingDetailsService.loadMetricMappings(
        metricMappingState.metricId,
        goalCampaigns.map(campaign => campaign.id),
        this.companyId,
        this.configuration.OBJECT_TYPES.campaign
      ),
      this.metricMappingDetailsService.loadMetricMappings(
        metricMappingState.metricId,
        goalChildCampaigns.map(campaign => campaign.id),
        this.companyId,
        this.configuration.OBJECT_TYPES.campaign
      )
    ]).pipe(
      map( ([campaignMetrics, childCampaignMetrics]) => {
        metricMappingState.childMetricMappings = [...campaignMetrics, ...childCampaignMetrics];
        return metricMappingState;
      })
    )
  }

  private buildMetricBreakDownData() {
    this.metricBreakdownData =
      (this.currentState.childMetricMappings || []).reduce(
        (metricBreakdownData, childMetricMapping) =>
          this.addChildMetricMappingToBreakdown(metricBreakdownData, childMetricMapping),
        createDeepCopy(this.metricBreakdownDataTemplate)
      );
  }

  private addChildMetricMappingToBreakdown(metricBreakdownData: MetricBreakdown, childMetricMapping: MetricMappingDO) {
    const { OBJECT_TYPES } = this.configuration;
    const isCampaignMapping = childMetricMapping.mapping_type === OBJECT_TYPES.campaign;
    const regularCampaign = this.goalCampaignsMap.get(childMetricMapping.map_id);
    const childCampaign = this.goalChildCampaignsMap.get(childMetricMapping.map_id);

    if (isCampaignMapping && regularCampaign) {
      this.addChildCampaignMetricToBreakdown(metricBreakdownData, childMetricMapping, regularCampaign);
    } else if (isCampaignMapping && childCampaign) {
      this.rollupChildCampaignMetricMapping(metricBreakdownData, childMetricMapping, childCampaign);
    }

    return metricBreakdownData;
  }

  private addChildCampaignMetricToBreakdown(
    metricBreakdownData: MetricBreakdown,
    childMetricMapping: MetricMappingDO,
    campaign: CampaignDO
  ) {
    this.metricMappingDetailsService.addMetricToBreakdownSection(
      campaign,
      metricBreakdownData,
      metricBreakdownData.campaignMetrics,
      childMetricMapping,
      this.currentState.metricName,
      false
    );
  }

  private rollupChildCampaignMetricMapping(
    metricBreakdownData: MetricBreakdown,
    childMetricMapping: MetricMappingDO,
    campaign: CampaignDO
  ) {
    const parentCampaignId = campaign.parent_campaign;
    const breakdownDataItem = metricBreakdownData.campaignMetrics.data.find(item => item.objectId === parentCampaignId);
    const totalValue = MetricMappingDetailsService.getTotalMappingCurrentValue(childMetricMapping, false);

    if (breakdownDataItem && totalValue > 0) {
      // We should not sum up target for rolled up objects
      const targetValue = 0;

      breakdownDataItem.current += totalValue;
      MetricMappingDetailsService.updateMetricBreakdownGrandTotal(
        metricBreakdownData,
        totalValue,
        targetValue
      );
    }
  }

  /* ACTIONS MENU */
  private updateMenuActions() {
    const isDisabled = !(this.currentState && this.currentState.objectId) || !this.editPermission;
    this.menuActionsBuilder
      .reset()
      .addDeleteAction(this.objectType, this.handleDelete.bind(this), isDisabled);

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  updateMilestones(milestones: MetricMilestones) {
    const prevTargetValue = this.metricsUtilsService.getTargetValue(this.currentState.milestones);
    this.currentState.milestones = milestones;
    const newTargetValue = this.metricsUtilsService.getTargetValue(milestones);
    if (prevTargetValue !== newTargetValue) {
      this.buildMetricBreakDownData();
      this.updateTargetSummary(isError => {
        if (!isError && this.graphData) {
          this.graphData.milestones = this.currentState.milestones;
          this.setGraphDataRecords();
        }
      });
    } else {
      if (this.graphData) {
        this.graphData.milestones = this.currentState.milestones;
      }
    }
    this.syncUnsavedChangesFlag();
  }

  onSavedSuccessfully() {
    if (!this.prevState) {
      this.budgetObjectDetailsManager.logObjectView(
        this.currentState.objectId,
        this.companyId,
        this.budget.id,
        this.currentUser.id,
        HistoryObjectLogTypeNames.metricMapping,
        this.metricMappingDetailsService
      );
      this.updateMenuActions();
    }
    this.hierarchyService.setHierarchyObjectName(
      this.hierarchy,
      this.configuration.OBJECT_TYPES.metric,
      MetricMappingDetailsService.getFullMetricName(this.currentState)
    );
    this.metricUpdateService.triggerMetricUpdate({
      action: MetricUpdateAction.UPDATE,
      objectId: this.goal.id,
      objectType: this.mappingType,
      metricMappingId: this.currentState.objectId,
      metricId: this.currentState.metricId,
      productId: this.metricMappingDetailsService.getProductByName(this.currentState.productName, this.products)?.id
    });
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    this.onSuccess(messages.SAVE_CHANGES_SUCCESS_MSG);
    this.metricMappingDetailsService.reportChange(this);
    this.syncUnsavedChangesFlag(false);
  }

  private onSuccess(message: string, close = false): void {
    this.hideLoader();
    this.utilityService.showToast({ Type: 'success', Message: message });
    if (close) {
      if (this.backUrl) {
        this.router.navigateByUrl(this.backUrl);
      } else {
        this.appRoutingService.closeDetailsPage();
      }
    }
  }

  /* FORM DATA */
  private _createForm() {
    const formData = {
      startDate: null,
      revenuePerOutcome: null,
      milestones: [null],
      notes: ''
    };
    this.formData = this.fb.group(formData);
    this.formData.controls['revenuePerOutcome'].disable();
    this.formData.controls['startDate'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.onChangedStartDate(value));
  }

  private setFormData() {
    const { notes, startDate, revenuePerOutcome } = this.currentState;
    const formData = { notes, revenuePerOutcome: revenuePerOutcome || 0, startDate: parseDateString(startDate) };
    this.formData.patchValue(formData);
    if (this.isReadOnlyMode) {
      this.formData.disable();
    }
  }

  validateChanges(): boolean {
    const milestonesValid = this.milestonesControl.validate();
    if (this.formData.valid && milestonesValid) {
      return true;
    }

    this.dataValidation.validateFormFields(this.formData);
    return false;
  }

  private addMissingData() {
    this.metricMappingDetailsService.addMissingDataForLegacyMetricMapping(
      this.currentState,
      this.metricMappingDetailsService.getDefaultStartDate.bind(
        this.metricMappingDetailsService,
        this.budget && this.budget.budget_from
      ),
      this.metricMappingDetailsService.getDefaultTargetDate.bind(
        this.metricMappingDetailsService,
        this.budget && this.budget.budget_to
      )
    );
  }

  private saveFormData() {
    const formDataState = this.formDataToState();
    Object.keys(formDataState).forEach(key => {
      this.currentState[key] = formDataState[key];
    });
  }

  private formDataToState() {
    const formData = this.formData.value;
    const startDate = formData.startDate;

    return {
      startDate: GoalMetricDetailsComponent.getStartDateStr(startDate),
      notes: formData.notes
    };
  }

  navigateToCampaignMetricDetails(id: number) {
    this.appRoutingService.openCampaignMetricDetails(id);
  }

  private onChangedStartDate(startDate: Date | string) {
    if (this.graphData) {
      this.graphData.startDate = GoalMetricDetailsComponent.getStartDateStr(startDate);
    }
  }
}
