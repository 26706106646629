export enum SpendingModeFlag {
  PlannedExpensesInTotal = 'PlannedExpensesInTotal',
  ExpensesAsTotal = 'ExpensesAsTotal',
  Formula = 'Formula',
}

export interface SpendingModeFlags {
  [SpendingModeFlag.PlannedExpensesInTotal]: boolean;
  [SpendingModeFlag.ExpensesAsTotal]: boolean;
  [SpendingModeFlag.Formula]: boolean;
}
