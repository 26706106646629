import { Injectable } from '@angular/core';
import { UntypedFormGroup, AsyncValidatorFn, ValidationErrors, AbstractControl, ValidatorFn, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { ProgramService } from 'app/shared/services/backend/program.service';
import { GoalsService } from 'app/shared/services/backend/goals.service';

@Injectable({
  providedIn: 'root'
})
export class DataValidationService {
  constructor(
    private readonly goalsService: GoalsService,
    private readonly campaignService: CampaignService,
    private readonly programService: ProgramService,
    private config: Configuration,
  ) { }

  validateFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateFormFields(control);
      }
    });
  }

  uniqueNameValidator(companyId, budgetId, name, objectType): AsyncValidatorFn {
    const serviceByObjectType = {
      [this.config.OBJECT_TYPES.goal]: this.goalsService,
      [this.config.OBJECT_TYPES.campaign]: this.campaignService,
      [this.config.OBJECT_TYPES.program]: this.programService,
    }
    const apiCallService = serviceByObjectType[objectType];
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return name === control.value
        ? of(null)
        : apiCallService.validateUniqueName(companyId, budgetId, control.value).pipe(
            catchError(() => of(null)),
            map(res => res ? { nameExist: true } : null)
          );
    };
  }

  segmentValidator(): ValidatorFn {
    const validator: ValidatorFn = (control: AbstractControl) =>
      control.value && control.value.id ? null : { required: true };
    return validator;
  }

  campaignSegmentValidator(isIntegratedObject: () => boolean): ValidatorFn {
    const validator: ValidatorFn = (control: AbstractControl) => {
      const [ parentType ] = (control.parent?.controls['location'].value || '').split('_');
      const segmentIsOptional = (parentType !== this.config.OBJECT_TYPES.campaign) || control.value?.id || !isIntegratedObject();
      return segmentIsOptional ? null : { required: true };
    }
    return validator;
  }

  checkboxValidator(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + next : prev, 0);
      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  minLengthFieldValidator(min = 1, fieldName) {
    const validator: ValidatorFn = (control: AbstractControl) => {
      const value = control.value && control.value[fieldName] && control.value[fieldName] || '';
      const isValid = value.length === 0 || value.length >= min;
      return isValid ? null : { minLength: true };
    }
    return validator;
  }
}
