import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'metric-progress-section',
  templateUrl: './metric-progress-section.component.html',
  styleUrls: ['./metric-progress-section.component.scss']
})
export class MetricProgressSectionComponent implements OnChanges {
  public sectionTitle = 'Progress Toward Target';
  public chartViewActive = true;

  @Input() metricName;
  @Input() lastUpdatedInfo: string;
  @Input() hideIcon: boolean = false;
  @Input() type: string = '';
  @Output() viewModeChanged = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.metricName) {
      this.sectionTitle = `Progress Toward ${this.metricName || ''} Target`;
    }
  }

  public toggleView() {
    this.chartViewActive = !this.chartViewActive;
    this.viewModeChanged.emit(this.chartViewActive);
  }
}
