<div
  scrollOnDrag
  tableContentShadows
  (onShadowChanged)="handleShadowUpdate($event)"
  class="table-wrap"
>
  <table>
    <thead>
    <tr>
      <th *ngFor="let column of visibleColumns; let i = index">
        <div class="cell-content" [expenseTableCellAlignment]="column.align">
          <mat-checkbox
            *ngIf="i === 0 && rows?.length > 1"
            class="mr-05"
            color="primary"
            [checked]="isAllSelected"
            (change)="onSelectAllChange($event)"
          >
          </mat-checkbox>
          {{ column.title }}
        </div>
      </th>
      <th>
        <div class="cell-content" [expenseTableCellAlignment]="'center'">Actions</div>
      </th>
    </tr>
    <tr class="shadow-tr">
      <td [colSpan]="visibleColumns.length"></td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of rows"
      [ngClass]="{
        'opened-in-drawer': openedInDrawerId === row.rowId,
        'is-selected': isRowSelected(row)
      }"
      dragImage
      [plDraggable]="dragAllowed"
      [imageName]="row[ExpenseTableColumn.NAME]"
      [icon]="dragIcon"
      [groupIcon]="dragIcon"
      [selectedRecordsCount]="rowSelectedNumber"
      (dragStart)="onHandleDragStart(row)"
      (dragEnd)="onHandleDragEnd()"
      (click)="onReviewInvoice(row)"
    >
      <td class="drag-handle" *ngFor="let column of visibleColumns">
        <div class="cell-content p-0"
             *ngIf="column.id === ExpenseTableColumn.NAME"
             [expenseTableCellAlignment]="column.align"
        >
          <expense-name-cell
            [name]="row[ExpenseTableColumn.NAME]"
            [isReadOnlyMode]="readOnlyMode"
            [itemSelected]="isRowSelected(row)"
            [dragAllowed]="dragAllowed"
            (select)="onRowSelectionChange($event, row)"
          ></expense-name-cell>
        </div>

        <div class="cell-content"
             *ngIf="column.id === ExpenseTableColumn.ACTUAL"
             [expenseTableCellAlignment]="column.align">
          <expense-amount
            [disabled]="row[column.id].disabled"
            [convertedValue]="row[column.id].convertedAmount"
            [sourceValue]="row[column.id].sourceAmount"
            [numberFormat]="numberFormatLong"
          ></expense-amount>
        </div>

        <div class="cell-content"
             *ngIf="isDefaultCell[column.id]"
             [expenseTableCellAlignment]="column.align">
          <span class="cell-content-default">
            <span>{{ row[column.id] }}</span>
          </span>
        </div>

      </td>
      <td>
        <div class="cell-content">
          <button mat-button color="primary" class="tertiary-btn" (click)="onReviewBtnClick(row, $event)">Review</button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
