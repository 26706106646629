import { Injectable } from '@angular/core';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { BudgetObjectParent } from '../types/budget-object-details-state.interface';
import { Configuration } from 'app/app.constants';
import { Observable } from 'rxjs';
import { SegmentDataInheritanceAction } from 'app/shared/types/segment-data-inheritance.interface';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { DIALOG_ACTION_TYPE, DialogAction } from 'app/shared/types/dialog-context.interface';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';
import { Goal } from 'app/shared/types/goal.interface';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { LightProgram } from 'app/shared/types/program.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { LocationItemsBuilder } from './location-items-builder';
import { LightObject } from '@shared/types/segmented-budget-object';

export interface SegmentChangeConfirmationContext {
  title: string;
  keepAllowed: boolean;
  multiUpdate: boolean;
  parentName: string;
  objectName: string;
}

export interface LocationHierarchyObject {
  flatItemIdsList: string[];
  items: HierarchySelectItem[];
}

export interface LocationHierarchyParams {
  goals: Goal[];
  campaigns: LightCampaign[];
  programs: LightProgram[];
  currentLocation?: string;
  segments: BudgetSegmentAccess[];
  rules: SharedCostRule[];
  isPowerUser: boolean;
}

@Injectable()
export class LocationService {
  constructor(
    private readonly budgetObjectService: BudgetObjectService,
    private readonly configuration: Configuration,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly objectAccessManager: ObjectAccessManagerService,
  ) {}

  public defineLocationValue(parentObject?: BudgetObjectParent): string {
    return parentObject ? `${parentObject.type}_${parentObject.id}` : '';
  }

  public filterOptionBySegmentAccess(params: {
    targetOption: LightObject;
    targetType: string;
    isPowerUser: boolean;
    selectedLocationType: string;
    selectedLocationID?: number;
    segments: BudgetSegmentAccess[];
    rules: SharedCostRule[];
  }): boolean {
    const { targetOption, targetType, isPowerUser, selectedLocationID, selectedLocationType, segments, rules } = params;
    if (isPowerUser) {
      return true;
    }
    if (selectedLocationType === targetType && selectedLocationID === targetOption.id) {
      return true;
    }

    return this.objectAccessManager.hasAccessBySegmentData({
      split_rule: targetOption.splitRuleId,
      company_budget_segment1: targetOption.budgetSegmentId
    }, segments, rules);
  }

  private filterLocationSources(
    params: {
      goals: Goal[];
      campaigns: LightCampaign[];
      programs: LightProgram[];
      currentLocation?: string;
      segments: BudgetSegmentAccess[];
      rules: SharedCostRule[];
      isPowerUser: boolean;
    }
  ): {
    filteredCampaigns: LightCampaign[];
    filteredPrograms: LightProgram[];
    filteredGoals: Goal[];
  } {
    const { goals, campaigns, programs, currentLocation, segments = [], rules = [], isPowerUser = false } = params;
    const [ locationType = null, locationId = null ] = (currentLocation ? currentLocation.split('_') : []);
    const { OBJECT_TYPES } = this.configuration;

    const filteredCampaigns = this.budgetObjectService.getOpenedObjects(
      campaigns,
      locationType === OBJECT_TYPES.campaign ? Number(locationId) : null
    )
      .filter(campaign => this.filterOptionBySegmentAccess({
        targetOption: campaign,
        targetType: OBJECT_TYPES.campaign,
        selectedLocationID: Number(locationId),
        selectedLocationType: locationType,
        isPowerUser,
        rules,
        segments
      }));

    const filteredPrograms = this.budgetObjectService.getOpenedObjects(
      programs,
      locationType === OBJECT_TYPES.program ? Number(locationId) : null
    )
      .filter(program => this.filterOptionBySegmentAccess({
        targetOption: program,
        targetType: OBJECT_TYPES.program,
        selectedLocationID: Number(locationId),
        selectedLocationType: locationType,
        isPowerUser,
        rules,
        segments
      }));

    return {
      filteredCampaigns,
      filteredPrograms,
      filteredGoals: goals
    };
  }

  public createLocationHierarchyItems(params: LocationHierarchyParams, singleLast = false, disableSegmentlessCampaigns = false): LocationHierarchyObject {
    const { filteredPrograms, filteredCampaigns, filteredGoals } = this.filterLocationSources(params);
    return this.createHierarchy(filteredGoals, filteredCampaigns, filteredPrograms, singleLast, disableSegmentlessCampaigns);
  }

  public createHierarchy(
    goals: Goal[],
    campaigns: LightCampaign[],
    programs: LightProgram[],
    singleLast = false,
    disableSegmentlessCampaigns = false
  ) {
    const itemsBuilder = new LocationItemsBuilder(this.configuration);
    return itemsBuilder.prepareItems({
      campaigns,
      programs,
      goals
    }, singleLast, disableSegmentlessCampaigns);
  }

  public requestSegmentConfirmation(context: SegmentChangeConfirmationContext): Observable<SegmentDataInheritanceAction> {
    return new Observable(observer => {
      const cancelAction: DialogAction = {
        label: 'Cancel',
        handler: () => {
          observer.next(SegmentDataInheritanceAction.None);
          observer.complete();
        },
        type: DIALOG_ACTION_TYPE.STROKED
      };
      const keepAction: DialogAction = {
        label: 'Keep',
        handler: () => {
          observer.next(SegmentDataInheritanceAction.Keep);
          observer.complete();
        },
        type: DIALOG_ACTION_TYPE.FLAT
      };
      const replaceAction: DialogAction = {
        label: 'Replace',
        handler: () => {
          observer.next(SegmentDataInheritanceAction.Replace);
          observer.complete();
        },
        type: DIALOG_ACTION_TYPE.FLAT
      };

      const modalActions = context.keepAllowed ? [cancelAction, keepAction, replaceAction] : [cancelAction, replaceAction];
      const modalText = this.getConfirmationMessage(context);

      this.dialogManager.openConfirmationDialog({
        title: context.title,
        content: modalText,
        actions: modalActions
      });
    });
  }

  private getConfirmationMessage(context: SegmentChangeConfirmationContext) {
    const isPlural = context.multiUpdate;
    const nameSuffix = (isPlural ? 's' : '');
    const posNameSuffix = (isPlural ? `s'` : `'s`);
    const rawObjectName = this.budgetObjectService.getObjectTypeName(context.objectName);
    const objectName = rawObjectName + nameSuffix;
    const posObjectName = rawObjectName + posNameSuffix;
    const thisObjectText = `${isPlural ? '' : 'this'} ${objectName}`;

    const differentSegmentText =
      `The selected ${context.parentName} has a different segment than ${thisObjectText}.`;

    const optionsQuestionText =
      context.keepAllowed ?
        `Would you like to <b>keep</b> the ${posObjectName} current segment${isPlural ? 's' : ''} or <b>replace</b> ${isPlural ? 'them' : 'it'} with the different segment?` :
        `Would you like to <b>update</b> the ${objectName} or <b>cancel</b>?`;

    return `${differentSegmentText}<br><br>${optionsQuestionText}`;
  }

  public syncSegmentsOnLocationUpdate(
    objectType: string,
    location: string,
    currentSegment: { segmentId?: number, sharedCostRuleId?: number },
    parentLists: { [parentType: string]: { name: string; objectList: LightObject[] } },
    onCancel: () => void,
    onReplace: (parentSegmentData: { segmentId?: number; sharedCostRuleId?: number; objectName: string }) => void
  ) {
    if (!location) {
      return;
    }

    const [locationType, locationId] = location.split('_');
    const parentSegmentData = this.getParentSegmentData(parentLists, locationType.toLowerCase(), Number(locationId));
    const noConfirmationRequired =
      !currentSegment || (!parentSegmentData?.segmentId && !parentSegmentData?.sharedCostRuleId) ||
      (currentSegment.segmentId === parentSegmentData.segmentId &&
        currentSegment.sharedCostRuleId === parentSegmentData.sharedCostRuleId);

    if (noConfirmationRequired) {
      return;
    }

    const actions = {
      [SegmentDataInheritanceAction.Keep]: () => {},
      [SegmentDataInheritanceAction.None]: () => onCancel(),
      [SegmentDataInheritanceAction.Replace]: () => onReplace(parentSegmentData)
    };

    this.syncSegments(false, objectType, parentSegmentData, actions);
  }

  private syncSegments(
    isMultiUpdate: boolean,
    objectType: string,
    parentSegmentData: { segmentId?: number; sharedCostRuleId?: number; objectName: string },
    actions: {[key: number]: () => void}
  ): void {
    const confirm =
      this.requestSegmentConfirmation({
        title: 'Change Location',
        keepAllowed: true,
        multiUpdate: isMultiUpdate,
        parentName: parentSegmentData.objectName,
        objectName: objectType
      });

    confirm.subscribe(action => actions[action]());
  }

  public getParentSegmentData(
    parentLists: { [parentType: string]: { name: string; objectList: LightObject[] } },
    parentType: string,
    parentId: number
  ): { segmentId?: number; sharedCostRuleId?: number; objectName: string } {
    const parents = parentLists ? parentLists[parentType] : null;
    const parent = parents ? parents.objectList.find(obj => obj.id === parentId) : null;
    return parent ? { segmentId: parent.budgetSegmentId, sharedCostRuleId: parent.splitRuleId, objectName: parents.name } : null;
  }
}
