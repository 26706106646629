<section class="details-expenses" [class.empty-data]="!expensesTotals?.count">
  <div class="header">
    <details-widget-title [count]="expensesTotals?.count || 0" text="Expenses">
      <fa-icon slot="icon" [icon]="['fas', 'coins']"></fa-icon>
    </details-widget-title>
    <a class="link" (click)="viewExpenses.emit()">View Expenses</a>
    <add-object-link
      *ngIf="isAddNewExpenseAllowed"
      text="Add Expense"
      [handler]="addNewExpense"
    ></add-object-link>
  </div>
  <div class="data">
    <details-expenses-table
      [expensesTotals]="expensesTotals"
      [withBreakdown]="withBreakdown"
      [withChildCampaigns]="withChildCampaigns"
      [data]="data"
    ></details-expenses-table>
  </div>
</section>
