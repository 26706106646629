import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-metrics-delete',
  templateUrl: './icon-metrics-delete.component.html',
  styleUrls: ['./icon-metrics-delete.component.scss']
})
export class IconMetricsDeleteComponent {
  @Input() size = '24px';
}
