import { inject, Injectable } from '@angular/core';
import { CampaignService } from '@shared/services/backend/campaign.service';
import { BaseBudgetObjectAmountsLoader } from './base-budget-object-amounts-loader';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Injectable()
export class CampaignAmountsLoader extends BaseBudgetObjectAmountsLoader {
  readonly rowType = ManageTableRowType.Campaign;

  constructor() {
    super(inject(CampaignService));
  }
}
