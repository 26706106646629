<div [tabindex]="!disabled ? 0 : -1" class="dropdownMenuComponent" [ngClass]="{opened: isActionsMenuOpened, disabled: disabled}" >
  <div class="selectedValue" (click)="handleDropdownMenuClick()">
    <div class="title">{{selectedValueText}}</div>
    <div class="downArrow"></div>
  </div>
  <actions-menu
    #actionsMenu
    [actionsMenuItems]="items"
    [selectedMenuItemValue]="selectedValue"
    (onActionsMenuStateChange)="handleActionsMenuStateChange($event)"
    (onSelectValue)="handleSelectValue($event)"
  ></actions-menu>
</div>
