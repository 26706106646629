import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TotalsSidebarState } from '@manage-ceg/types/manage-ceg-page.types';

@Component({
  selector: 'totals-toggler',
  templateUrl: './totals-toggler.component.html',
  styleUrls: ['./totals-toggler.component.scss']
})
export class TotalsTogglerComponent {
  @Input() disabled: boolean;
  @Input() openingState: TotalsSidebarState;
  @Output() openingStateChange = new EventEmitter<TotalsSidebarState>();
  protected textTooltip = 'Show Totals';

  protected toggleState(): void {
    this.openingStateChange.emit(this.isHidden ? TotalsSidebarState.Preview : TotalsSidebarState.Hidden);
  }

  protected get isHidden(): boolean {
    return this.openingState === TotalsSidebarState.Hidden;
  }
}
