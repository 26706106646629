import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { SidebarHierarchyOption, SidebarObjectTypes } from '@spending/types/expense-page.type';
import { SpendingSidebarService } from '@spending/services/spending-sidebar.service';
import { Configuration } from 'app/app.constants';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';

@Component({
  selector: 'sidebar-hierarchy-option',
  templateUrl: './sidebar-hierarchy-option.component.html',
  styleUrls: ['./sidebar-hierarchy-option.component.scss']
})
export class SidebarHierarchyOptionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() segments: BudgetSegmentAccess[];
  @Input() sharedCostRules: SharedCostRule[];
  @Input() option: SidebarHierarchyOption;
  @Input() searchQuery: string;
  @Input() isAllSelected: boolean;
  @Input() expenseCount: number;
  @Input() hideGroupCount: boolean = false;

  @Output() toggleSelected = new EventEmitter<SidebarHierarchyOption>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  @ViewChild('scrollIndicator') private scrollIndicator: ElementRef<HTMLElement>;

  spaces: string[] = [];
  observer: IntersectionObserver;
  isOptionStuck: boolean;

  readonly SidebarObjectTypes = SidebarObjectTypes;
  readonly numberFormat = '1.0-10';

  private readonly globalObjectTypes = this.configuration.OBJECT_TYPES;
  readonly sidebarToGlobalMap = {
    [SidebarObjectTypes.Segment]: this.globalObjectTypes.segment,
    [SidebarObjectTypes.SegmentGroup]: this.globalObjectTypes.segmentsGroup,
    [SidebarObjectTypes.Campaign]: this.globalObjectTypes.campaign,
    [SidebarObjectTypes.ExpenseGroup]: this.globalObjectTypes.expenseGroup,
    [SidebarObjectTypes.Goal]: this.globalObjectTypes.goal,
  };

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly configuration: Configuration,
    private readonly spendingSidebarService: SpendingSidebarService
  ) {}

  ngOnInit(): void {
    this.spaces = Array(this.option?.level > 0 ? this.option.level - 1 : 0).fill('');
  }

  ngAfterViewInit(): void {
    if (this.scrollIndicator) {
      this.observer = this.createPositionObserver();
      this.observer.observe(this.scrollIndicator.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  toggleCollapsedState(): void {
    this.toggleCollapsed.emit();
  }

  toggleSelectedState(): void {
    this.toggleSelected.emit(this.option);
  }

  get isSticky(): boolean {
    const hasNoOption = this.option.objectType === SidebarObjectTypes.Campaign || this.option.objectType === SidebarObjectTypes.Goal;
    return this.option.objectType === SidebarObjectTypes.Segment || (hasNoOption && this.option.isDefault);
  }

  get isSearchActive(): boolean {
    return this.searchQuery?.length > 2;
  }

  get isHidden(): boolean {
    return this.isSearchActive ? this.option.searchHidden : !this.option.visible;
  }

  get isSelected(): boolean {
    const selectedOptionsMapValue = this.spendingSidebarService.selectedOptionsMapValue;
    return !!selectedOptionsMapValue[this.option.id] || (this.option.level === 1 && this.isAllSelected);
  }

  get isMarkedAsSelected(): boolean {
    return this.spendingSidebarService.isMarkedAsSelected(this.option.id) || (this.option.level !== 1 && this.isAllSelected);
  }

  get closedLabel(): SafeHtml {
    return this.option.closed ? '<span class="closed-label">[Closed]</span>' : '';
  }

  get hasCounter(): boolean {
    const objectType = this.option.objectType;
    return objectType === SidebarObjectTypes.Segment
      || objectType === SidebarObjectTypes.SegmentGroup
      || objectType === SidebarObjectTypes.Goal;
  }

  private createPositionObserver(): IntersectionObserver {
    return new IntersectionObserver(entries => {
      if (entries[0].intersectionRatio === 0) {
        this.isOptionStuck = this.option.visible;
        this.cdr.detectChanges();
      } else if (entries[0].intersectionRatio === 1) {
        this.isOptionStuck = false;
        this.cdr.detectChanges();
      }
    }, { threshold: [0, 1] });
  }
}
