import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'app/shared/shared.module';
import { ManageTableComponent } from './components/manage-table/manage-table.component';
import { ManageTableContainerComponent } from './components/manage-table-container/manage-table-container.component';
import { BudgetSettingsModule } from '../budget-settings/budget-settings.module';
import { BudgetAllocationModule } from '../budget-allocation/budget-allocation.module';
import { TableModeSwitchComponent } from './components/table-mode-switch/table-mode-switch.component';
import { ManagePageComponent } from './components/manage-page/manage-page.component';
import { ManagePlanMenuComponent } from './components/manage-plan-menu/manage-plan-menu.component';
import { TableViewModeSwitchComponent } from './components/table-view-mode-switch/table-view-mode-switch.component';
import { HierarchySelectModalComponent } from './components/hierarchy-select-modal/hierarchy-select-modal.component';
import { ManageTableRecordActionsComponent } from './components/manage-table-record-actions/manage-table-record-actions.component';
import { ManageTableHeadRowComponent } from './components/manage-table-head-row/manage-table-head-row.component';
import { ManageTableFootRowComponent } from './components/manage-table-foot-row/manage-table-foot-row.component';
import { ManageTablePlainValueComponent } from './components/manage-table-plain-value/manage-table-plain-value.component';
import { ManageTableSegmentBreakdownComponent } from './components/manage-table-segment-breakdown/manage-table-segment-breakdown.component';
import { PlainBreakdownValueComponent } from './components/plain-breakdown-value/plain-breakdown-value.component';
import { AddMetricDialogComponent } from './components/add-metric-dialog/add-metric-dialog.component';


@NgModule({
  declarations: [
    ManageTableComponent,
    ManageTableContainerComponent,
    TableModeSwitchComponent,
    ManagePageComponent,
    ManagePlanMenuComponent,
    TableViewModeSwitchComponent,
    HierarchySelectModalComponent,
    ManageTableRecordActionsComponent,
    ManageTableHeadRowComponent,
    ManageTableFootRowComponent,
    ManageTablePlainValueComponent,
    ManageTableSegmentBreakdownComponent,
    PlainBreakdownValueComponent,
    AddMetricDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BudgetSettingsModule,
    BudgetAllocationModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  exports: [
    ManageTableComponent,
    ManageTableContainerComponent,
    TableModeSwitchComponent,
  ]
})
export class ManageTableModule {}
