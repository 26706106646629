import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SpendingManagementMode, LocalSpendingPageFilters } from '@spending/types/expense-page.type';
import { SpendingModeService } from '@spending/services/spending-mode.service';
import { AppDataLoader } from 'app/app-data-loader.service';
import { SpendingService } from '@spending/services/spending.service';
import { SummaryBarItem } from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { CustomPaginatorIntl } from '@spending/services/custom-paginator';
import { SpendingManageMenuService } from '@spending/services/spending-manage-menu.service';
import { ExpenseTableColumnsService } from '@spending/services/expense-table-columns.service';
import { ExpenseTableDataService } from '@spending/services/expense-table-data.service';
import { SpendingSidebarService } from '@spending/services/spending-sidebar.service';
import { SpendingLocalFiltersService } from '@spending/services/spending-local-filters.service';
import { SpendingMiniDashSummaryService } from '@spending/services/spending-mini-dash-summary.service';
import { TabSwitchOption } from 'app/shared/components/tab-switch/tab-switch.types';
import { ExpenseActionsService } from '@spending/services/expense-actions.service';
import { ExpensesQueryService } from '@spending/services/expenses-query.service';
import { ExpensePageSelectionService } from '@spending/services/expense-page-selection.service';
import { InvoiceTableDataService } from '@spending/services/invoice-table-data.service';
import { InvoiceUploadManagerService } from '@spending/services/invoice-upload-manager.service';
import { InvoicePageSortByService } from '@spending/services/invoice-page-sort-by.service';

export const EXPENSES_SIDEBAR_STATE = 'spending-sidebar-state';

@Component({
  selector: 'spending',
  templateUrl: './spending.component.html',
  styleUrls: ['./spending.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.4s ease-out', style({ opacity: 0 }))
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-out', style({ opacity: 1 })),
      ])
    ]),
    trigger('infoBanner', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.4s ease-out', style({ opacity: 0 }))
      ]),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(10px)' }),
        animate('0.3s ease-out', style({ opacity: 1, transform: 'translateX(0)' })),
      ])
    ]),
  ],
  providers: [
    AppDataLoader,
    SpendingService,
    SpendingModeService,
    ExpenseTableColumnsService,
    ExpenseTableDataService,
    InvoiceTableDataService,
    SpendingManageMenuService,
    SpendingSidebarService,
    SpendingLocalFiltersService,
    SpendingMiniDashSummaryService,
    ExpenseActionsService,
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    ExpensesQueryService,
  ],
})

export class SpendingComponent implements OnInit, OnDestroy {
  private readonly appDataLoader = inject(AppDataLoader);
  private readonly spendingModeService = inject(SpendingModeService);
  public readonly spendingService = inject(SpendingService);
  private readonly spendingLocalFiltersService = inject(SpendingLocalFiltersService);
  private readonly spendingMiniDashSummaryService = inject(SpendingMiniDashSummaryService);
  public readonly expensePageSelection = inject(ExpensePageSelectionService);
  public readonly invoiceUploadManager = inject(InvoiceUploadManagerService);
  private readonly invoicePageSortByService = inject(InvoicePageSortByService);

  protected sidebarLoading$ = this.spendingService.sidebarLoading$;
  protected tableDataLoading$ = this.spendingService.tableDataLoading$;
  protected isActionInProgress$ = this.spendingService.isActionInProgress$;
  public sidebarCollapsed = this.sidebarInitialCollapsingState;
  public sidebarStateInited = false;
  public selectedHierarchyIds: (number | string)[] = [];

  protected readonly spendingManagementModes = SpendingManagementMode;

  public pageTitle = {
    [SpendingManagementMode.Expenses]: 'Expenses',
    [SpendingManagementMode.Invoices]: 'New Invoices',
  };
  public itemName = {
    [SpendingManagementMode.Expenses]: 'expenses',
    [SpendingManagementMode.Invoices]: 'invoices',
  };
  public pageIcon: Record<SpendingManagementMode, IconProp> = {
    [SpendingManagementMode.Expenses]: ['fas', 'coins'],
    [SpendingManagementMode.Invoices]: ['fas', 'file-invoice-dollar'],
  };
  public selectedExpenses: number[] = [];
  public summaryBarViewMode: SpendingManagementMode;
  private destroy$ = new Subject<void>();

  private dragCounter = 0;
  protected dragActive = false;

  ngOnInit(): void {
    this.appDataLoader.init(false);
    this.summaryBarViewMode = this.spendingModeService.spendingManagementMode;
  }

  public get totalRecordsCount(): number {
    return this.spendingLocalFiltersService.totalRecordsCount;
  }

  public get paginationParams(): LocalSpendingPageFilters {
    return this.spendingLocalFiltersService.localFilters;
  }

  private get sidebarInitialCollapsingState(): boolean {
    setTimeout(() => {
      this.sidebarStateInited = true;
    }, 100);
    const storedState = localStorage.getItem(EXPENSES_SIDEBAR_STATE);
    return typeof storedState === 'string' ? storedState === 'true' : false;
  }

  public setSidebarCollapsingState(): void {
    this.sidebarCollapsed = !this.sidebarCollapsed;
    localStorage.setItem(EXPENSES_SIDEBAR_STATE, String(this.sidebarCollapsed));
  }

  public get readOnlyMode(): boolean {
    return this.spendingService.readOnlyMode;
  }

  public get spendingManagementModeOptions(): TabSwitchOption[] {
    return this.spendingModeService.dataModeOptions;
  }

  public get spendingManagementMode(): SpendingManagementMode {
    return this.spendingModeService.spendingManagementMode;
  }

  public get isSelectAllBannerShown(): boolean {
    return this.totalRecordsCount > this.paginationParams.limit
      && this.expensePageSelection.isAllSelectedValue
      && !this.isTotalSelectionDisabled;
  }

  public handleSpendingManagementModeChange(dataMode: string): void {
    if (this.spendingManagementMode === SpendingManagementMode.Invoices) {
      const canLeave$ = this.invoiceUploadManager.canLeavePage();
      canLeave$.pipe(takeUntil(this.destroy$)).subscribe(value => {
        if (value) {
          this.handleModeChange(dataMode as SpendingManagementMode);
        }
      });
    } else {
      this.handleModeChange(dataMode as SpendingManagementMode);
    }
  }

  public viewNewInvoices(): void {
    this.spendingService.updateInvoiceCount();
    this.invoicePageSortByService.showLatest();
    this.spendingService.refreshTableDataAndTotals();
  }

  public get isAddExpenseAllow(): boolean {
    return this.spendingService.isAddingExpenseAllowed;
  }

  protected get hasInvoiceAccess(): boolean {
    return this.spendingService.hasInvoiceAccess;
  }

  public openExpenseDrawer(): void {
    this.spendingService.openExpenseCreation();
  }

  public get summaryBarLoading(): BehaviorSubject<boolean> {
    return this.spendingMiniDashSummaryService.summaryBarLoading$;
  }

  public get summaryBarItems(): SummaryBarItem[][] {
    return this.spendingMiniDashSummaryService.summaryBarItems;
  }

  public changePage(e: PageEvent): void {
    this.spendingLocalFiltersService.changePage(e);
  }

  public get currentTableSearch(): string {
    return this.spendingLocalFiltersService.localFilters.search;
  }

  public tableSearchChange(search: string): void {
    this.spendingLocalFiltersService.searchStr = search;
    this.spendingService.updateSidebarCounts();
  }

  public get searchDisabled(): boolean {
    return this.spendingService.searchDisabled;
  }

  public get searchHidden(): boolean {
    return this.spendingService.searchHidden;
  }

  public get hasSelection(): boolean {
    return !!Object.keys(this.expensePageSelection.itemSelectionValue).length;
  }

  public get sidebarHasSelection(): boolean {
    return !!this.spendingService.sidebarSelection.length;
  }

  private get isTotalSelectionDisabled(): boolean {
    return !!this.spendingService.companySharedCostRules.filter(rule => rule.instancesNumber > 0).length
     && this.spendingService.isFilteredBySegment;
  }

  protected onDragEnter(event: DragEvent): void {
    event.preventDefault();

    if (event.dataTransfer.items && event.dataTransfer.items[0]?.kind === 'file') {
      this.dragCounter++;
      this.dragActive = true;
    }
  }

  protected onDragLeave(event: DragEvent): void {
    event.preventDefault();

    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.dragActive = false;
    }
  }

  protected onDrop(event: DragEvent): void {
    event.preventDefault();

    this.dragCounter = 0;
    this.dragActive = false;
  }

  protected get selectedItemsNumber(): number {
    return Object.keys(this.expensePageSelection.itemSelectionValue).length;
  }

  protected get total(): number {
    return this.spendingLocalFiltersService.localFilters.limit;
  }

  protected toggleTotalSelected(isSelected: boolean): void {
    this.expensePageSelection.toggleTotalSelection(isSelected);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleModeChange(dataMode: SpendingManagementMode): void {
    this.expensePageSelection.clearSelection();
    this.spendingLocalFiltersService.searchStr = '';
    this.spendingModeService.openSpendingManagementMode(dataMode);
    this.summaryBarViewMode = dataMode;
  }
}
