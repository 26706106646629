import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  template: ''
})
export abstract class EditableContentComponent {
  value: string;
  @Input() isNumber;
  @Input() selectOnFocus = false;
  @Input() numberFormat;
  @Input() isReadOnly = false;
  @Input() maxLength = 150;
  @Input() placeholder = 'Enter your value';

  @Output() onNameChanged = new EventEmitter();
  @Output() focusIn = new EventEmitter<void>();
  @Output() focusOut = new EventEmitter<void>();
  @Output() onErrorStateChanged = new EventEmitter<string>();
  @ViewChild('controlInput') controlInput: ElementRef;

  public editMode = false;
  public isHovered = false;
  public errorMessage = '';
  protected errorMessageByType = {
    required: 'Name is required',
    unique: 'This name is already used',
    reserved: 'Please choose a different name',
  };
  public control = new UntypedFormControl();
  protected readonly destroy$ = new Subject<void>();
  @Input() set controlValue(val) {
    this.value = val?.toString() || null;
    this.control.setValue(this.value);
  }

  valueChangesSubscribe() {
    this.control.valueChanges
      .pipe(
        debounceTime(250),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.updateErrorState());
  }

  validateControl() {
    this.control.markAsDirty();
    this.control.updateValueAndValidity();
    this.updateErrorState();
  }

  protected updateErrorState() {
    const {errors} = this.control;
    if (errors) {
      const errorKeys = Object.keys(errors);
      this.errorMessage = this.errorMessageByType[errorKeys[errorKeys.length - 1]] || '';
    } else {
      this.errorMessage = null;
    }

    this.onErrorStateChanged.emit(this.errorMessage);
  }

  private sanitizeValue(value: string): string {
    if (!value) {
      return '';
    }
    return value.replace(/\s+/g, ' ').trim();
  }

  public handleFocus() {
    if (this.isReadOnly) {
      return;
    }

    this.editMode = true;
    this.focusIn.emit();
    setTimeout(() => {
      if (this.controlInput) {
        this.controlInput.nativeElement.focus();
      }
    });
  }

  public handleBlur() {
    let validatedValue;
    this.focusOut.emit();

    if (!this.isNumber) {
      const rawValue = this.control.value;
      validatedValue = this.sanitizeValue(rawValue);

      this.control.setValue(validatedValue);
      this.value = validatedValue;
    } else {
      // TODO: check negative???
      validatedValue = +this.control.value;
    }

    this.onNameChanged.emit(validatedValue);
    this.editMode = false;
  }

  public handleMouseEnter() {
    this.isHovered = true;
  }

  public handleMouseLeave() {
    this.isHovered = false;
  }

  public get hasErrors() {
    return this.control.errors && (this.control.dirty || this.control.touched);
  }
}
