import { Injectable, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { SsoFormInput } from './sso-form.interfaces';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { UtilityService } from 'app/shared/services/utility.service';
import { BudgetDataService } from '../../../dashboard/budget-data/budget-data.service';
import { UserManager } from '../../../user/services/user-manager.service';

@Injectable()
export class SsoFormService {
  private readonly http = inject(HttpClient);
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly utilityService: UtilityService = inject(UtilityService);;

  constructor(
    private readonly userManager: UserManager,
  ) {

  }

  toFormGroup(inputs: SsoFormInput[] ) {
    const group: any = {};

    inputs.forEach(input => {
      group[input.key] = new FormControl(input.value || '');
    });
    return new FormGroup(group);
  }

  getSkilljarAuthDetails(){
    const userInfo = this.userManager.getCurrentUser();
    const companyId =
      this.budgetDataService
      .selectedBudgetStorage
      .getSelectedCompany(userInfo.id);
    return this.http.post(`${this.apiV2Url}fim/`, {
      service_provider: "skilljar",
      company_id: companyId
    })
    .pipe(
      catchError(async error => this.utilityService.handleError(error))
    );
  }
}
