import { Component, Input } from '@angular/core';
import { IconBaseComponent } from '../icon-base.component';

@Component({
  selector: 'icon-grip',
  styleUrls: ['./icon-grip.component.scss'],
  templateUrl: './icon-grip.component.html',
})
export class IconGripComponent extends IconBaseComponent {
  @Input() color = 'rgba(112, 112, 112, .3)';
  @Input() title = '';
}
