import { AttachmentMappingDO } from './attachment.interface';
import { BudgetObjectSource } from '../../budget-object-details/types/budget-object-details-state.interface';

export interface ExpenseDO {
  id: number;
  expense_id?: number;
  amount: number;
  source_amount: number;
  actual_amount: number;
  source_actual_amount: number;
  budget: number;
  company: number;
  expense_type: number;
  mode: string;
  company_budget_alloc: number;
  company_budget_segment1?: number;
  split_rule?: number;
  created_by: number;
  owner: number;
  name: string;
  note: string;
  crd?: string;
  upd?: string;
  expense_delivery_date: string;
  expense_po_no: string;
  invoice: string;
  gl_code: number;
  source_currency: string;
  vendor: number;
  attachments?: AttachmentMappingDO[];
  attachments_number?: number;
  goal?: number;
  campaign?: number;
  program?: number;
  source?: BudgetObjectSource;
  relation_group?: string;
  external_id?: string;
  is_verified?: boolean;
  extra_expense?: ExtraExpense;
  custom_fields?: Record<number, string[]>;
}

export interface ExpenseTotalsDO {
  total_count: number;
  total_amount: number;
  total_count_with_pseudo_objects: number;
}

export interface ExpenseMiniDashTotalsDO {
  allocated: number;
  expenses: number;
  remaining: number;
}

export interface ExtraExpense {
  pdf_highlighted_file: string;
  pdf_highlighted_file_url?: string;
  body_of_email?: string;
  recognized_fields: {[key: string]: string};
  body_of_email_contents: ExtraExpenseEmailBody;
}

export interface ExtraExpenseEmailBody {
  body: string;
  from: Array<string[]>;
  to: Array<string[]>;
  subject: string;
  date: string;
}

export const expenseSources = ['google_ads', 'plannuh_automation', 'import', 'linkedin_ads', 'facebook_ads', 'manual_entry', 'invoice_processing'];
export const adsExpenseSources = ['linkedin_ads', 'google_ads', 'facebook_ads'];
