import { MetricProgressState } from '@shared/types/metric-progress-state.type';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum ObjectDetailsTabControl {
  Details = 'details',
  Allocation = 'allocation',
  Performance = 'performance',
  Update = 'update',
  UpdateHistory = 'Update History'
}

export interface DetailsTabData {
  simpleText: string;
}

export interface AllocationTabData {
  budget: {
    currency: string
    allocated: number;
    available: number;
  }
}

export interface PerformanceTabData {
  performance: {
    estimatedDiffPercentage: number;
    progressState: MetricProgressState;
  }
}

export interface DrawerTabItem {
  id: ObjectDetailsTabControl;
  title: string;
  icon: IconProp;
  data: DetailsTabData | AllocationTabData | PerformanceTabData;
  disabled?: boolean;
}
