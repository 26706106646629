import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Currency } from 'app/shared/types/currency.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  readonly currencyList$ = new BehaviorSubject<Currency[]>([]);

  fetchCurrencyList() {
    return this.http.get(this.apiV2Url + 'currency/')
      .pipe(
        map(rawList => this.prepareCurrencyList(rawList))
      )
      .subscribe(list => this.currencyList$.next(list));
  }

  private prepareCurrencyList(list): Currency[] {
    return list.map(item => ({
      code: item.name,
      name: item.currency_name,
      symbol: item.symbol
    }));
  }
}
