import { Component, Input } from '@angular/core';
import { BudgetTimeframeBrief, ManageTableBudgetColumn, ManageTableBudgetColumnName, ManageTableBudgetColumnsVisibility, ManageTablePerformanceColumn, PresentationTimeframe, TotalsSidebarState } from '@manage-ceg/types/manage-ceg-page.types';
import { CheckboxValue } from '@shared/enums/checkbox-value.enum';

@Component({
  template: ''
})
export abstract class ManageTableCegRowBaseComponent {
  @Input() timeframes: BudgetTimeframeBrief[];
  @Input() budgetColumns: ManageTableBudgetColumn[];
  @Input() budgetColumnsVisibility: ManageTableBudgetColumnsVisibility;
  @Input() performanceColumns: ManageTablePerformanceColumn[];
  @Input() totalsSidebarState: TotalsSidebarState;
  @Input() budgetMode: boolean;
  @Input() performanceMode: boolean;
  protected readonly sidebarState = TotalsSidebarState;
  protected readonly budgetColumnName = ManageTableBudgetColumnName;
  protected readonly presentationTimeframe = PresentationTimeframe;
  protected readonly SelectionValue = CheckboxValue;
  protected identifyTimeframe(index: number, item: BudgetTimeframeBrief): number | string {
    return item.id;
  }
  protected identifyColumn(index: number, item: ManageTableBudgetColumn): number | string {
    return item.id;
  }
}
