import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { Budget, BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';


@Component({
  selector: 'timeframe-lock-button',
  templateUrl: './timeframe-lock-button.component.html',
  styleUrls: ['./timeframe-lock-button.component.scss']
})
export class TimeframeLockButtonComponent implements OnChanges {
  @Input() budget: Budget;
  @Input() timeframe: BudgetTimeframe;
  @Input() isCurrent = false;
  @Input() disabled = false;
  @Input() editable = true;

  @Output() onLockChange = new EventEmitter<boolean>();

  public isYearlyBudget = false;
  public fyColumnName = null;
  public unlockedTooltip = '';
  public lockedTooltip = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.budget && this.budget) {
      this.setBudgetRelatedData();
    }
  }

  private setBudgetRelatedData() {
    const { type: budgetType, budget_to } = this.budget;
    const budgetEndDate = parseDateString(budget_to);

    this.unlockedTooltip = `Click to lock this ${budgetType.toLowerCase()}
      and prevent any changes to expenses of other allocations in this timeframe.
      Any remaining allocated budget will be stranded as 'Under Budget' and all expenses will be Closed.
    `;

    this.lockedTooltip = `Click to unlock this ${budgetType.toLowerCase()},
      once again allowing changes to expenses or allocations in this timeframe
    `;

    if (budgetType === BudgetTimeframesType.Year) {
      this.isYearlyBudget = true;
      this.fyColumnName = `FY ${budgetEndDate.getUTCFullYear().toString().substring(2)}`;
    }
  }

  public toggleLock() {
    if (this.isYearlyBudget || this.disabled) {
      return;
    }
    this.onLockChange.emit(!this.timeframe.locked);
  }
}
