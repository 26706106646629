import { Component, Input } from '@angular/core';

@Component({
  selector: 'add-object-link',
  templateUrl: './add-object-link.component.html',
  styleUrls: ['./add-object-link.component.scss']
})
export class AddObjectLinkComponent {
  @Input() text = '';
  @Input() route: string;
  @Input() handler: Function;
  @Input() skipHandler: boolean;
  @Input() disabled = false;
}
