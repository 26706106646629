import { Component, Input } from '@angular/core';

@Component({
  selector: 'widget-toggle-button',
  styleUrls: ['./widget-toggle-button.component.scss'],
  templateUrl: './widget-toggle-button.component.html'
})
export class WidgetToggleButtonComponent {
  @Input() isActive = false;
}
