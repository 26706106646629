import { ManageTableRow } from '../../components/manage-table/manage-table.types';

export class RowDataChunk {
  private readonly _campaignRows: ManageTableRow[] = [];
  private readonly _programRows: ManageTableRow[] = [];

  addCampaignRow(row: ManageTableRow): void {
    this._campaignRows.push(row);
  }

  addProgramRow(row: ManageTableRow): void {
    this._programRows.push(row);
  }

  get objectsCount (): number {
    return this._campaignRows.length + this._programRows.length;
  }

  get campaignRows (): ManageTableRow[] {
    return this._campaignRows;
  }

  get programRows (): ManageTableRow[] {
    return this._programRows;
  }
}
