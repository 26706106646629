import { Injectable, inject } from "@angular/core";
import { BudgetObjectDetailsManager } from "app/budget-object-details/services/budget-object-details-manager.service";
import { MetricMappingDetailsService } from "app/budget-object-details/services/metric-mapping-details.service";
import { MetricMappingDO, MetricParentChildHierarchyDO, MetricService } from 'app/shared/services/backend/metric.service'
import { map, switchMap, take, tap } from "rxjs/operators";
import { Observable, combineLatest, forkJoin, of } from "rxjs";
import { CampaignDO } from "@shared/types/campaign.interface";
import { BudgetSegmentAccess } from "@shared/types/segment.interface";
import { SharedCostRule } from "@shared/types/shared-cost-rule.interface";
import { MetricMappingDetailsState } from "app/budget-object-details/types/metric-mapping-details-state.interface";
import { MetricValueUpdateType, MetricValueUpdatesData } from "app/budget-object-details/types/metric-value-update-item.interface";
import { Configuration } from "app/app.constants";


@Injectable({
    providedIn: 'root'
})
export class MetricDrawerService {

    protected readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
    private readonly configuration = inject(Configuration);
    private OBJECT_TYPES = this.configuration.OBJECT_TYPES;
    
    constructor(
        private readonly metricService: MetricService,
        private readonly metricMappingDetailsService: MetricMappingDetailsService 
    ){}

    getAllProductsDetails(){
      return this.budgetObjectDetailsManager.getProducts$().pipe(take(1))
    }

    getProgressTowardsMetricTarget(mappingId: number){
      return this.metricService.getMetricProgressTowardsTarget(mappingId)
    }

    getLastUpdatedDate(metricValueUpdates: MetricValueUpdatesData, updateType: MetricValueUpdateType, objectId: number) {
      return MetricMappingDetailsService.getLastUpdatedDate(metricValueUpdates, updateType, objectId )
    }

    getMetricHierarchy(id: number) {
      return this.metricService.getMetricHierarchy(id)
    }

    prepareParentAndChildrenMetricHierarchy(id: number, metricName: string, hierarchy: MetricParentChildHierarchyDO) {
      let showParentHierarchy = []   , showChildrenHierarchy= [], hierarchyMenuList = [];
      let parentHierarchyLoaded = false; let level = 0;

      if(hierarchy.goal !== ' ') {
          showParentHierarchy.push(...[
            {
              name: hierarchy.goal.name,
              id: Number(hierarchy.goal.map_id),
              objectType: this.OBJECT_TYPES.goal,
              onClick: null,
              level: level
            },
            {
              name: metricName,
              id: Number(hierarchy.goal.metric_map_id),
              objectType: this.OBJECT_TYPES.metric,
              onClick: null,
              level: level + 1
            }
         ])

        if(Number(hierarchy.goal.metric_map_id) === id) {
          parentHierarchyLoaded = true;
          level = 0;
        }
      }

      (hierarchy.campaign_details || []).forEach((cd,i) => {
      if(cd.map_id) {    
        hierarchyMenuList = parentHierarchyLoaded ? showChildrenHierarchy : showParentHierarchy
        hierarchyMenuList.push(...[
          {
            name: cd.name,
            id: Number(cd.map_id),
            objectType: this.OBJECT_TYPES.campaign,
            onClick: null,
            level
          },
          {
            name: metricName,
            id: Number(cd.metric_map_id),
            objectType: this.OBJECT_TYPES.metric,
            onClick: null,
            level: ++level
          }
      ]);

      if(Number(cd.metric_map_id) === id) {
        parentHierarchyLoaded = true;
        level = 0;
      }
    
      (cd.child_campaign || []).forEach(ccd => {
        hierarchyMenuList = parentHierarchyLoaded ? showChildrenHierarchy : showParentHierarchy
        hierarchyMenuList.push(...[
          {
            name: ccd.name,
            id: Number(ccd.map_id),
            objectType: this.OBJECT_TYPES.campaign,
            onClick: null,
            level: level + 1
          },
          {
            name: metricName,
            id: Number(ccd.metric_map_id),
            objectType: this.OBJECT_TYPES.metric,
            onClick: null,
            level: level + 2
          }
        ]);

        if(Number(ccd.metric_map_id) === id) {
          parentHierarchyLoaded = true;
          level = 0;
        }
      })

      level = 0;
    }else {
      // In case there is disconnect between Goal, parent and child metric levels, we flush out prev hierarchy
      (cd.child_campaign || []).forEach(ccd => {
        if(parentHierarchyLoaded){
          hierarchyMenuList = [];
        }else {
          hierarchyMenuList = showParentHierarchy = [];
        }
        hierarchyMenuList.push(...[
          {
            name: ccd.name,
            id: Number(ccd.map_id),
            objectType: this.OBJECT_TYPES.campaign,
            onClick: null,
            level: level + 1
          },
          {
            name: metricName,
            id: Number(ccd.metric_map_id),
            objectType: this.OBJECT_TYPES.metric,
            onClick: null,
            level: level + 2
          }
        ]);

        if(Number(ccd.metric_map_id) === id) {
          parentHierarchyLoaded = true;
          level = 0;
        }
      })
    }
    })

    return [showParentHierarchy, showChildrenHierarchy]
    }

    loadMetricDetails(id: number) {
     return this.metricService.getMetricMapping(id)
    }

    loadMetricMappingsForChildCampaigns(metricMappingState: MetricMappingDetailsState, companyId: number, childCampaigns: CampaignDO[], mappingType: string, goalCampaigns: CampaignDO[]){
      if (!metricMappingState.metricId) {
          return of([]);
      }

      if(mappingType === "Campaign") {
        
        return this.metricMappingDetailsService.loadMetricMappings(
          metricMappingState.metricId,
          childCampaigns.map(campaign => campaign.id),
          companyId,
          mappingType
        ).pipe(
          map(campaignsMetrics => {
            metricMappingState.childMetricMappings = campaignsMetrics;
            return metricMappingState;
          })
        );
      }else {
        const goalChildCampaigns = childCampaigns;
        
        return forkJoin([
          this.metricMappingDetailsService.loadMetricMappings(
            metricMappingState.metricId,
            goalCampaigns.map(campaign => campaign.id),
            companyId,
            "Campaign"
          ),
          this.metricMappingDetailsService.loadMetricMappings(
            metricMappingState.metricId,
            goalChildCampaigns.map(campaign => campaign.id),
            companyId,
            "Campaign"
          )
        ]).pipe(
          map( ([campaignMetrics, childCampaignMetrics]) => {
            metricMappingState.childMetricMappings = [...campaignMetrics, ...childCampaignMetrics];
            return metricMappingState;
          })
        )
      }

    }

    loadGoal(goalId: number) {
      return this.metricMappingDetailsService.loadGoal(goalId)
    }
    
    loadCampaignAndChildCampaigns$(campaignId: number, budgetId: number, segments: BudgetSegmentAccess[], sharedCostRules: SharedCostRule[]): Observable<[CampaignDO, CampaignDO[]]> {
      const childCampaigns$ =
        this.metricMappingDetailsService.loadChildCampaigns([campaignId], budgetId).pipe();
  
      const campaign$ = this.metricMappingDetailsService.loadCampaign(campaignId);
    
      return forkJoin([campaign$, childCampaigns$]);
    }

    loadGoalCampaignsAndChildCampaigns$(goalId: number, budgetId: number) {
        return this.metricMappingDetailsService.loadGoalCampaigns(goalId, budgetId).pipe(   
          switchMap(campaigns => this.loadChildCampaignsForGoalMetric$(campaigns, budgetId))
        )
    }

    loadChildCampaignsForGoalMetric$(campaigns: CampaignDO[], budgetId: number): Observable<[CampaignDO[], CampaignDO[]]> {
      const childCampaigns$ = campaigns && campaigns.length
        ? this.metricMappingDetailsService.loadChildCampaigns(
          campaigns.map(campaign => campaign.id),
          budgetId
        )
        : of([]);

        return childCampaigns$.pipe(
          map(childCampaigns => [campaigns, childCampaigns])
        );
    }

    loadUpdateHistoryForGoalMetric(metricId: number) {
      return this.metricService.getUpdateHistoryForGoalMetricMapping(metricId);
    }

    createMetricMappingDetailsState(metricMapping: Partial<MetricMappingDO>, includeDependentMetricValues = true){
      return this.metricMappingDetailsService.createMetricMappingDetailsState(metricMapping, includeDependentMetricValues)
    }

    updateMetricDetails(metricId: number, metricMappingPayload: Partial<MetricMappingDO>) {
        return this.metricService.updateMetricMapping(metricId, metricMappingPayload)
    }
    
}