<div class="task-date" [ngClass]="{
  'is-late': isLate,
  'is-incoming': isIncoming,
  'is-readonly': isReadOnly
}">
  <div
    (click)="!isReadOnly && datePicker.open()"
    class="input"
    [matTooltip]="isIncoming ? tooltipText : null"
    matTooltipClass="dark-theme-tooltip multi-line above"
    matTooltipPosition="above"
  >
    {{ value | date: 'd MMM, yyyy' }}
    <input
      matInput
      [matDatepicker]="datePicker"
      [ngModel]="value"
      (dateChange)="handleValueChanged($event)"
      autocomplete="off"
      readonly
    />
  </div>
  <fa-icon matSuffix [icon]="['fad', 'calendar-day']"></fa-icon>
  <mat-datepicker
    #datePicker
    [dateClass]="datepickerClassNames"
    panelClass="task-due-date-picker"
  ></mat-datepicker>
</div>
