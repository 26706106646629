import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'metric-milestone',
  templateUrl: './metric-milestone.component.html',
  styleUrls: ['./metric-milestone.component.scss']
})
export class MetricMilestoneComponent implements OnInit, OnChanges, OnDestroy {
  @Input() milestoneForm: UntypedFormGroup;
  @Input() index = 0;
  @Input() milestonesCount = 0;
  @Input() readOnly = true;
  @Input() displayDecimal = false;
  @Output() removeMilestone = new EventEmitter();
  @Output() patchMilestone = new EventEmitter();
  statusSubscription: Subscription;
  amountFieldOpt = { prefix: '', precision: 2, decimal: '.' };
  ngOnInit() {
    this.statusSubscription = this.milestoneForm.statusChanges.subscribe(
      status => {
        if (status === 'VALID') {
          this.patchMilestone.emit(this.index);
        }
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.displayDecimal) {
      this.amountFieldOpt.precision = this.displayDecimal ? 2 : 0;
    }
  }

  amountChanged() {
    const dateField = this.milestoneForm.controls['date'];
    if (dateField.value && !dateField.valid) {
      dateField.updateValueAndValidity()
    }
  }

  dateChanged() {
    const amountField = this.milestoneForm.controls['amount'];
    amountField.updateValueAndValidity();
    if (!amountField.touched) {
      amountField.markAsTouched()
    }
  }

  ngOnDestroy() {
    this.statusSubscription.unsubscribe();
  }
}
