import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disabledAddToObjectTooltip'
})
export class DisabledAddToObjectTooltipPipe implements PipeTransform {
  transform(disabled: boolean, objectTypeName: string, actionDescription: string = ''): string {
    return disabled
      ? `Please save this ${objectTypeName.toLowerCase()} before ${actionDescription}`
      : '';
  }
}
