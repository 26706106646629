<div>
  <div class="tags-icon" [ngStyle]="{'height': size, 'width': size}">
    <svg [attr.fill]="color" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 17.1 17.1" style="enable-background:new 0 0 17.1 17.1;" xml:space="preserve">
      <path fill="none" d="M-3.5-3.5h24v24h-24V-3.5z"/>
      <path d="M1.5,8.8l6.8,6.8C8.5,15.9,8.9,16,9.3,16h5.2c0.8,0,1.5-0.7,1.5-1.5V9.3c0-0.4-0.2-0.8-0.4-1.1L8.8,1.5C8.6,1.2,8.2,1,7.8,1
        S7,1.2,6.7,1.5L1.5,6.7C1.2,7,1,7.4,1,7.8S1.2,8.6,1.5,8.8z M13.4,12.3c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
        c-0.6,0-1.1-0.5-1.1-1.1C12.3,12.8,12.8,12.3,13.4,12.3z"/>
    </svg>
  </div>
  <div class="tags-count" [ngStyle]="{'color': color}">{{tagsCount}}</div>
</div>