<article class="box-wapper box-wapperWidget box-newTopNav box-searchTopNav new-nav">
	<div class="box-rightSide">
		<div class="box-rightSideInn box-dashboardRightInn box-searchMain">
			<div class="box-searchValue">
				<h4>
					<div *ngIf="searchedResultList.length > 0">
						<strong>{{resultCount}}</strong> Results for: <strong>" {{searched_item}} ".</strong> Click rows for details.
					</div>
					<div *ngIf="searchedResultList.length <= 0">
						<div *ngIf="!noResultFound && search_value == undefined">Enter search term above.</div>
						<div *ngIf="noResultFound">No result found.</div>
					</div>
				</h4>
			</div>
			<div class="box-tableSearch" *ngIf="searchedResultList.length > 0" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="3000" (scrolled)="onSearchResultScroll()"  [scrollWindow]="false" debounce>
				<table class="table-search" width="100%" cellspacing="0" cellpadding="0" border="0" style="border-spacing:0px;">
					<tr class="table-accordionHead thead-sorting">
						<td><span class="link-expenseTbl table-contOverflow">Name <span class="icon-sortingTable" [ngClass]="{'asc': searchResultSortBy == 'desc' && searchResultOrderBy == 'name', 'desc': searchResultSortBy == 'asc' && searchResultOrderBy == 'name'}" (click)="searchResultSort('name')"></span></span>
						</td>
						<td><span class="link-expenseTbl table-contOverflow">Type <span class="icon-sortingTable" [ngClass]="{'asc': searchResultSortBy == 'desc' && searchResultOrderBy == 'object_type', 'desc': searchResultSortBy == 'asc' && searchResultOrderBy == 'object_type'}" (click)="searchResultSort('object_type')"></span></span>
						</td>
						<td><span class="link-expenseTbl table-contOverflow">Owner <span class="icon-sortingTable" [ngClass]="{'asc': searchResultSortBy == 'desc' && searchResultOrderBy == 'owner_name', 'desc': searchResultSortBy == 'asc' && searchResultOrderBy == 'owner_name'}" (click)="searchResultSort('owner_name')"></span></span>
						</td>
						<td><span class="link-expenseTbl table-contOverflow">Segment <span class="icon-sortingTable" [ngClass]="{'asc': searchResultSortBy == 'desc' && searchResultOrderBy == 'segments', 'desc': searchResultSortBy == 'asc' && searchResultOrderBy == 'segments'}" (click)="searchResultSort('segments')"></span></span>
						</td>
						<td *ngIf="!isCegFlagEnabled"><span class="link-expenseTbl table-contOverflow">Planned <span class="icon-sortingTable" [ngClass]="{'asc': searchResultSortBy == 'desc' && searchResultOrderBy == 'budgeted', 'desc': searchResultSortBy == 'asc' && searchResultOrderBy == 'budgeted'}" (click)="searchResultSort('budgeted')"></span></span>
						</td>
						<td><span class="link-expenseTbl table-contOverflow">{{ isCegFlagEnabled ? 'Amount' : 'Current' }}<span class="icon-sortingTable" [ngClass]="{'asc': searchResultSortBy == 'desc' && searchResultOrderBy == 'spent', 'desc': searchResultSortBy == 'asc' && searchResultOrderBy == 'spent'}" (click)="searchResultSort('spent')"></span></span>
						</td>
						<td *ngIf="!isCegFlagEnabled"><span class="link-expenseTbl table-contOverflow">Difference <span class="icon-sortingTable" [ngClass]="{'asc': searchResultSortBy == 'desc' && searchResultOrderBy == 'balanced', 'desc': searchResultSortBy == 'asc' && searchResultOrderBy == 'balanced'}" (click)="searchResultSort('balanced')"></span></span>
						</td>
						<td><span class="link-expenseTbl table-contOverflow">Timeframe</span></td>
						<td><span class="link-expenseTbl table-contOverflow">Tags</span></td>
					</tr>
					<tr class="table-accordionInnHead" *ngFor="let result of searchedResultList; let i = index;" (click)="openViewModal(result)">
						<td>
							<span class="link-expenseTbl table-contOverflow"  title="{{result.name}}">{{result.name}}</span>
						</td>
						<td>
							<span class="link-expenseTbl table-contOverflow">{{result.object_type}}</span>
						</td>
						<td>
							<span class="link-expenseTbl table-contOverflow" title="{{result.owner}}">{{result.owner}}</span>
						</td>
						<td>
							<span class="link-expenseTbl table-contOverflow" title="{{result.segments}}">{{result.segments}}</span>
						</td>
						<td *ngIf="!isCegFlagEnabled">
							<span class="link-expenseTbl table-contOverflow">{{result.budgeted}}</span>
						</td>
						<td>
							<span class="link-expenseTbl table-contOverflow">{{result.spent}}</span>
						</td>
						<td *ngIf="!isCegFlagEnabled">
							<span class="link-expenseTbl table-contOverflow" [ngClass]="{'red-text': balanceAmtClassArray[i] == true}">{{result.balance}}</span>
						</td>
						<td>
							<ul class="list-timeFrameDisk" [ngClass]="{'disk12': result.timeframe?.length == 12}">
								<li *ngFor="let t of result.timeframe">
									<span [ngClass]="{'filled': t['assigned'] == true}" title="{{t.name}}"></span>
								</li>
							</ul>
						</td>
						<td>
							<span class="link-expenseTbl table-contOverflow" title="{{result.tags_data.join(', ')}}">{{result.tags_data.join(', ')}}</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</article>
