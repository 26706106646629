export function updateTooltipState(containerRect: ClientRect, targetRect: ClientRect, tooltipState) {
  const screenWidthLeft = Math.floor(window.innerWidth - targetRect.left + containerRect.width / 2);
  const rightSideOverflow = screenWidthLeft < containerRect.width;
  let className = '';
  let position = {};

  if (rightSideOverflow) {
    position = {
      top: targetRect.top + 'px',
      right: 0,
      left: 'auto',
      transform: 'translate(0, -100%)'
    };
    className = 'right-overflow'
  } else {
    position = {
      top: targetRect.top + 'px',
      left: targetRect.left + 'px',
      right: 'auto',
      transform: 'translate(-50%, -100%)'
    }
  }

  tooltipState.className = className;
  tooltipState.styles = {
    ...tooltipState.styles,
    ...position
  };
}

export function hideTooltip(tooltipState) {
  tooltipState.isVisible = false;
  tooltipState.styles = {};
}
