import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-trash',
  templateUrl: './icon-trash.component.html',
  styleUrls: ['./icon-trash.component.scss']
})

/**
* @class - IconTrashComponent
*/
export class IconTrashComponent {
  @Input() size = 24;
  @Input() color = '#0063B7';
  @Input() textTooltip = '';
}
