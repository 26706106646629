<h3 class="title">
  <icon-fa-duotone-list-tree class="mr-05" [size]="'16'"></icon-fa-duotone-list-tree>
  <span>Hierarchy</span>
</h3>

<div *ngFor="let item of items; let i = index;"
     class="list-item"
     [ngStyle]="{'margin-left': 25 * i + 'px'}"
     [ngClass]="{
      'highlighted': item.highlighted,
      'isClickable': routingService.routeActionByObjectType[item.type]
     }">
  <fa-icon
    *ngIf="iconsConfig[item.type]"
    class="icon"
    [fixedWidth]="true"
    [icon]="iconsConfig[item.type]">
  </fa-icon>
  <a class="link"
     [stringTruncate]="item.name"
     (click)="handleNameClick(item)"
  ></a>
</div>
