<div
  [matTooltip]="!objectId | disabledAddToObjectTooltip: parentObjectLabel : 'adding children'"
  matTooltipClass="dark-theme-tooltip simple"
  matTooltipPosition="left">
  <add-object-button
    *ngFor="let objectType of objectTypes"
    [matTooltip]="buttonConfig[objectType]?.tooltip"
    [matTooltipDisabled]="!objectId"
    matTooltipShowDelay="800"
    matTooltipClass="dark-theme-tooltip simple mb-1"
    matTooltipPosition="above"
    [iconConfig]="buttonConfig[objectType]?.icon"
    [isDisabled]="!objectId"
    (createObjectClick)="createObject.emit(objectType)">
  </add-object-button>
</div>

