<div class="event-tooltip"
 [ngStyle]="tooltipStyles"
 [class.is-visible]="isVisible"
 [class.is-closed]="event?.mode === ObjectMode.Closed"
 #tooltipRef
>
  <div class="tooltip-container" [ngClass]="tooltipContainerClass">
    <div class="tooltip-tip" [ngStyle]="tipStyles"></div>
    <div class="tooltip-content">
      <header class="tooltip-header">
        <div class="tooltip-header-bar">
          <div class="event-amount">{{ currencySymbol }} {{ campaignAmount | number : numberFormat }}</div>
          <div class="event-status" *ngIf="event?.mode === ObjectMode.Closed">
            <span class="icon"></span>
            <span class="status">{{ ObjectMode.Closed }}</span>
          </div>
        </div>
        <div class="event-name">{{ event?.name }}</div>
        <div class="event-type"><span>{{ objectType }} Type:</span> {{ campaignType || 'N/A' }}</div>
      </header>
      <div class="event-metrics">
        <ng-container *ngFor="let metric of metricsList; let index = index;">
          <div *ngIf="index < metricsDisplayThreshold" class="event-metric">
            <div class="metric-outcome">
              <span class="key" [class.standalone]="!metric.productId">{{ metric.productName || 'Standalone' }}</span>
              <span class="value">{{ metric.name }}</span>
            </div>
            <div class="metric-values">
              <div class="target">Target: <span>{{ withCurrencySet.has(metric.typeId) ? (currencySymbol + ' ') : '' }}{{ (metric.target || metric.legacyTarget) | number: numberFormat }}</span></div>
              <div class="current">Current: <span>{{ withCurrencySet.has(metric.typeId) ? (currencySymbol + ' ') : '' }}{{ metric.current | number: numberFormat }}</span></div>
            </div>
          </div>
        </ng-container>
        <div class="metrics-threshold-tip" *ngIf="metricsList.length > metricsDisplayThreshold">
          {{ metricsList.length - metricsDisplayThreshold }} more Metrics on the {{ objectType }} Detail Page
        </div>
      </div>
      <div class="event-dates">
        <span class="start-date">{{ event?.startDate ? (event?.startDate | ordinalDate : dateFormat) : 'Undefined Start Date' }}</span>
        <span class="end-date">{{ event?.endDate ? (event?.endDate | ordinalDate : dateFormat) : 'Undefined End Date' }}</span>
      </div>
    </div>
  </div>
</div>
