import { Component, Input } from '@angular/core';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetAllocationActionTooltipContext } from 'app/budget-allocation/components/budget-allocation-action-tooltip/budget-allocation-action-tooltip.component';
import { ManageTableHelpers } from '../../services/manage-table-helpers';
import { ManageTableFullRowValues } from '../manage-table/manage-table.types';
import { SpendingModeFlag, SpendingModeFlags } from '../../types/spending-mode-flags.type';
import { utClasses } from '../../constants/ut-classes.constants';

@Component({
  selector: '[manage-table-foot-row]',
  templateUrl: './manage-table-foot-row.component.html',
  styleUrls: ['./manage-table-foot-row.component.scss']
})
export class ManageTableFootRowComponent {
  @Input() data: ManageTableFullRowValues;
  @Input() decimalPipeFormat: string;
  @Input() tooltipContext: BudgetAllocationActionTooltipContext;
  @Input() spendingMode = false;
  @Input() allocationMode = false;
  @Input() budget: Budget;
  @Input() timeframes: BudgetTimeframe[];
  @Input() highlightedTimeframes: number[] = [];
  @Input() spendingModeFlags: SpendingModeFlags;
  @Input() segmentBreakdown = false;

  public readonly SpendingModeFlag = SpendingModeFlag;
  public readonly utClasses = utClasses;
  public readonly identifyTimeframe = ManageTableHelpers.identifyTimeframe;
}
