
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

export const ManageTableRowTypeLabel = {
  [ManageTableRowType.Segment]: 'Segment',
  [ManageTableRowType.SegmentGroup]: 'Segment Group',
  [ManageTableRowType.Goal]: 'Goal',
  [ManageTableRowType.Campaign]: 'Campaign',
  [ManageTableRowType.ExpenseGroup]: 'Expense Group',
  [ManageTableRowType.UnassignedExpenses]: 'Unassigned Expenses'
};

export const CloneableRowTypes = [
  ManageTableRowType.Goal,
  ManageTableRowType.Campaign,
  ManageTableRowType.ExpenseGroup,
];

export const GroupingRowTypes = [
  ManageTableRowType.Goal,
  ManageTableRowType.Segment,
  ManageTableRowType.SegmentGroup,
];

export const AllocatableRowTypes = [
  ManageTableRowType.Campaign,
  ManageTableRowType.ExpenseGroup,
];

export const ManageTableRowTypeIcon: Record<string, IconProp> = {
  [ManageTableRowType.Goal]: [ 'fas', 'bullseye-arrow' ],
  [ManageTableRowType.Campaign]: [ 'far', 'rocket-launch' ],
  [ManageTableRowType.ExpenseGroup]: [ 'fas', 'briefcase' ],
  [ManageTableRowType.UnassignedExpenses]: [ 'fas', 'coins' ],
  [ManageTableRowType.Segment]: [ 'far', 'chart-pie-simple' ],
  [ManageTableRowType.SegmentGroup]: [ 'far', 'chart-pie' ],
};
