import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[currencyInput]'
})
export class CurrencyInputDirective {
  @Input() public appAutoFocus: boolean;

  public constructor(private el: ElementRef) {
    this.el.nativeElement.onkeypress = this.handleKeyPress;
  }

  handleKeyPress(e) {
    const { keyCode } = e;
    return keyCode <= 31 || (keyCode >= 48 && keyCode <= 57);
  }
}
