<ng-container *ngFor="let column of budgetColumns">
  <div *ngIf="column.id === budgetColumnName.Budget || (budgetColumnsVisibility[column.id] && totalsSidebarState === sidebarState.FullWidth)">
    <div class="segment-breakdown" *ngIf="recordTotals">
      <div class="sub-row-item" [class.p-0]="column.id === budgetColumnName.Budget">
        <budget-allocation-cell
          *ngIf="column.id === budgetColumnName.Budget; else totalValue"
          [allocated]="recordTotals[column.id].ownAmount"
          [remaining]="recordTotals[budgetColumnName.Available].ownAmount"
          [disabled]="isClosed"
          [showDifference]="true"
        >
        </budget-allocation-cell>
        <ng-template #totalValue>
          {{ recordTotals[column.id].ownAmount | absoluteNumber }}
        </ng-template>
      </div>
      <ng-container *ngIf="segmentRelated">
        <div class="sub-row-item" *ngIf="segmentBreakdownConfig.campaignsAndPrograms">
          {{ recordTotals[column.id].campaignsAndPrograms | absoluteNumber }}
        </div>
        <div class="sub-row-item" *ngIf="segmentBreakdownConfig.unallocated">
          {{ recordTotals[column.id].unallocated | absoluteNumber }}
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
