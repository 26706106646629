import { Injectable } from '@angular/core';

@Injectable()
export class KeyCodesConstants {
  public backspace = 8;
  public tab = 9;
  public enter = 13;
  public esc = 27;
  public end = 35;
  public arrowLeft = 37;
  public arrowRight = 39;
  public remove = 46;
  public digit0 = 48;
  public digit9 = 57;
  public charA = 65;
  public charC = 67;
  public charV = 86;
  public charX = 88;
  public digit0NumLock = 96;
  public digit9NumLock = 105;
  public charDotNumLock = 110;
  public charComma = 188;
  public charDot = 190;
}
