import { Injectable } from '@angular/core';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ObjectTimeframe } from 'app/shared/types/object-timeframe.interface';
import { AllocationsTableData, AllocationsTableDataItemProvider } from './allocations-table.type';
import { TimeframeShortNamePipe } from 'app/shared/pipes/timeframe-short-name.pipe';

@Injectable({
  providedIn: 'root'
})
export class AllocationsTableService {

  constructor(private timeframeShortName: TimeframeShortNamePipe) { }

  calcTotalRow(tableData: AllocationsTableData, columns: string[]) {
    const summ = {};
    Object.keys(tableData).forEach(key => {
      const rowData = tableData[key].data;
      rowData.forEach(data => {
        if (columns.includes(data.column)) {
          summ[data.column] = (isFinite(summ[data.column]) ? summ[data.column] : 0) + data.value;
        }
      })
    })
  }

  createTableData(
    budgetTimeframes: BudgetTimeframe[], objectAllocations: ObjectTimeframe[], dataProvider: AllocationsTableDataItemProvider
  ): AllocationsTableData {
    return budgetTimeframes.reduce(
      (result, budgetTimeframe) => {
        const objectAllocation = objectAllocations.find(tf => tf.company_budget_alloc === budgetTimeframe.id);
        const timeframeKey = this.timeframeShortName.transform(budgetTimeframe);
        const allocationId = budgetTimeframe ? budgetTimeframe.id : null;
          result[timeframeKey] = {
            allocationId,
            data: dataProvider(objectAllocation, budgetTimeframe)
          }
        return result
      }, {}
    )
  }

  createTimeframesNames(budgetTimeframes: BudgetTimeframe[]): string[] {
    return budgetTimeframes.map(
      tf => this.timeframeShortName.transform(tf)
    )
  }
}
