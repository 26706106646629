import { SortParams } from 'app/shared/types/sort-params.interface';

export const SortableFields = {
  SegmentName: 'name'
};

export const DefaultSorting: SortParams = {
  column: SortableFields.SegmentName,
  reverse: false
};
