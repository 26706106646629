import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'select-all-btn',
  templateUrl: './select-all-btn.component.html',
  styleUrls: ['./select-all-btn.component.scss']
})
export class SelectAllBtnComponent {
  @Input() isAllSelected: boolean;
  @Input() viewModeTitle: string;
  @Input() disabled: boolean;

  @Output() onAllSelectedToggle = new EventEmitter<boolean>();

  public isAllSelectedButtonOn: boolean;

  get tooltipTitle(): string {
    return (this.isAllSelected ? 'Deselect' : 'Select') + ' all ' + this.viewModeTitle;
  }

  toggleSelectAll(): void {
    // Timeout is added to prevent tooltip blinking
    setTimeout(() => {
      this.onAllSelectedToggle.emit(!this.isAllSelected);
    }, 100);
  }
}
