import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'metricCPO'
})
export class MetricCPOPipe implements PipeTransform {
  private static round(value) {
    return Math.round(value * 100) / 100;
  }

  public calculate(value, total): number {
    if (!value) {
      return 0;
    }

    const CPO = total / value;
    return CPO < 1 ? CPO : MetricCPOPipe.round(CPO);
  }

  private stringify(value, total, currency): string {
    if (!value || !total) {
      return 'N/A';
    }

    const CPO = this.calculate(value, total);
    const formattedCPO = CPO < 1 ?
      this.decimalPipe.transform(CPO, '1.2-4') :
      this.decimalPipe.transform(CPO, '1.2-2');

    return `${currency} ${formattedCPO}`;
  }

  constructor(private decimalPipe: DecimalPipe) {
  }

  transform(value, params: { total: number, currency: string, raw?: boolean }): string | number {
    const { total, raw, currency } = params;

    if (raw) {
      return this.calculate(value, total)
    }

    return this.stringify(value, total, currency);
  }
}
