<div class="app-page">
  <page-header pageName="Manage">
    <fa-icon slot="icon" [icon]="['far', 'rocket-launch']"></fa-icon>
    <div slot="name">
      <tab-switch
        *ngIf="managePageModeService.showDataModeSwitch$ | async"
        [options]="dataModeOptions"
        [selectedValue]="dataMode"
        (onChange)="setDataMode($event)"
      ></tab-switch>
    </div>
  </page-header>
  <section class="page-content">
    <div class="page-container">
      <manage-ceg-table-container [dataMode]="dataMode"></manage-ceg-table-container>
    </div>

    <budget-summary-bar
      [summaryItems]="summaryBarItems"
      [calculationItems]="calculationItems"
      [summaryBarLoading$]="summaryBarLoading"
    ></budget-summary-bar>
  </section>
</div>
